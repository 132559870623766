import RaisedButton from '../../common/RaisedButton';
import React, { Component, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Checkbox, SelectField, MenuItem } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { FaIndustry, FaEdit, FaClose, FaTrash } from 'react-icons/lib/fa';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import DialogConfirm from '../../common/DialogConfirm';
import moment from 'moment';
import { TextField, Card, Chip } from '@material-ui/core';
import CommonPage from '@common/CommonPage';
import Button from '@common/Button';
import Constants from '../../data/Constants';

const AddTaskModal = ({
  action,
  addTask,
  categories,
  companyId,
  endpoints,
  handleModal,
  language,
  listTask,
}) => {
  const [category, setCategory] = useState(null);
  const [catalog, setCatalog] = useState(null);

  const [catalogs, setCatalogs] = useState([]);

  const [catalogOptions, setCatalogOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);


  const catalogCompany = (v) => {
    action
      .execute('get', `${endpoints.CATALOG}/company/${0}/${v}`)
      .then((b) => {
        setCatalogs(b.data);
        setCategory(v);
      });
  };

  const onChangeField = (field, value) => {
    if (field.name === 'fk_id_category') {
      catalogCompany(value);
    } else if (field.name === 'fk_id_catalog_service') {
      setCatalog(value);
    }
  };

  let fields = [
    {
      col: 6,
      type: 'autocomplete',
      name: 'fk_id_category',
      label: language.CATEGORY,
      textlabel: language.CATEGORY,
      method: 'POST',
      route: `${Constants.APIEndpoints.CATEGORY}/list`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_category',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${Constants.APIEndpoints.CATEGORY}/list?`,
      methodGetAll: 'POST',
      required: true,
      detail: true,
    },
    {
      col: 6,
      type: 'autocomplete',
      name: 'fk_id_catalog_service',
      label: language.SERVICE_CATALOG,
      textlabel: language.SERVICE_CATALOG,
      method: 'POST',
      route: `${Constants.APIEndpoints.CATALOG}/category/all`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_catalog_service',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${Constants.APIEndpoints.CATALOG}/category/all?`,
      methodGetAll: 'POST',
      detail: true,
      required: true,
      filterAll: { id_category: category },
    },
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_catalog_task',
      label: language.TASK,
      textlabel: language.TASK,
      method: 'POST',
      route: `${Constants.APIEndpoints.TASK}/list`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_catalog_task',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${Constants.APIEndpoints.TASK}/list?`,
      methodGetAll: 'POST',
      detail: true,
      required: true,
      filterAll: { fk_id_catalog_service: catalog },
      isMulti : true
    },
  ]; 

  return (
    <Row>
      <Dialog
        open={true}
        onClose={() => handleModal()}
        miniVersion = {true}
        PaperProps={{
          style: {
            height: '100%',
          },
        }}
        title={language.ADD}
        maxWidth="md"
      >
        <Card className="content">
          <CommonForm
            fields={fields}
            button={{ md: 12, label: language.SAVE, primary: true }}
            onSubmit={(v) => addTask({ id_company: companyId, ...v })}
            onChangeField={onChangeField}
          />
        </Card>
      </Dialog>
    </Row>
  );
};

@observer
export default class CompanyAddTaskForm extends CommonPage {
  constructor() {
    super();

    this.state = {
      departments: [],
      open: false,
      category: null,
      catalog: null,
      catalog_task: null,
      categories: [],
      listTask: [],
      pageDelete: {
        open: false,
        values: undefined,
      },
    };

    this.addTask = this.addTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handlePageDelete = this.handlePageDelete.bind(this);
  }

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  componentWillMount() {
    this.action
      .execute('get', this.APIEndpoints.COMPANY + '/task', 'listTask')
      .then((t) => {
        this.setState({
          listTask: t.data.filter(
            (lt) => lt.fk_id_company === this.props.values.id_company
          ),
        });
      });
    this.action
      .execute('get', this.APIEndpoints.CATEGORY, 'categories')
      .then((h) => {
        this.setState({ categories: h.data });
      });
  }

  addTask(values) {
    const { id_company } = this.props.values;
    this.action
      .execute('put', this.APIEndpoints.COMPANY + '/task', 'listTask', values)
      .then((e) => {
        this.setState({
          listTask: e.data.filter((d) => d.fk_id_company == id_company),
        });
        this.handleModal();
      });
  }

  deleteTask() {
    const { listTask, pageDelete } = this.state;
    const { values } = pageDelete;
    this.action
      .execute(
        'delete',
        this.APIEndpoints.COMPANY +
          '/task/' +
          values.id_rel_catalog_task_company,
        'listTask',
        values,
        'id_rel_catalog_task_company',
        { btn_loading: 'del-task-list-' + values.id_rel_catalog_task_company }
      )
      .then((c) => {
        this.setState({
          listTask: listTask.filter(
            (lt) =>
              lt.id_rel_catalog_task_company !=
              c.data.id_rel_catalog_task_company
          ),
          pageDelete: {
            open: false,
            values: undefined,
          },
        });
      });
  }

  handlePageDelete(open = false, values) {
    this.setState({ pageDelete: { open, values } });
  }

  render() {
    let { listTask, open, pageDelete } = this.state;

    let {
      categories,
      catalogs,
      language,
      handlePage,
      values,
      departments,
      changeDepartmentBoss,
      users,
    } = this.props;

    let col = [
      {
        key: 'dtcad',
        label: language.ID,
        style: { width: 10, textAlign: 'center' },
      },
      {
        key: 'categoria',
        label: language.CATEGORY,
        style: { width: 50, textAlign: 'center' },
      },
      {
        key: 'subcategoria',
        label: language.SERVICE_CATALOG,
        style: { width: 120, textAlign: 'center' },
      },
      {
        key: 'subcategorianivel',
        label: language.TASK,
        style: { width: 50, textAlign: 'center' },
      },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 90, textAlign: 'center' },
      },
    ];

    let data = listTask.map((obj) => {
      return {
        dtcad: moment(obj.dt_cad).format('L'),
        categoria: obj.categoria,
        subcategoria: obj.catalogservice,
        subcategorianivel: obj.subcategoria.replace(/<[^>]*>?/gm, ''),
        action: (
          <Button
            variant="circle"
            size={0.7}
            icon="far fa-trash-alt"
            label={language.DELETE}
            color="danger"
            _loading={'del-task-list-' + obj.id_rel_catalog_task_company}
            style={{ fontSize: 14 }}
            onClick={() => this.handlePageDelete(true, obj)}
          />
        ),
      };
    });

    return (
      <div>
        <Card className="pb-2 mb-3">
          <Container fluid>
            <Row>
              <Col md={10}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  value={values.name}
                />
              </Col>
            </Row>
          </Container>
        </Card>

        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index="table-4"
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={this.handleModal}
            />
          }
          index={'CompanyAddTaskForm'}
        />
        {open && (
          <AddTaskModal
            handleModal={this.handleModal}
            addTask={this.addTask}
            categories={categories}
            catalogs={catalogs}
            companyId={values.id_company}
            language={language}
            listTask={listTask}
            action={this.action}
            endpoints={this.APIEndpoints}
          />
        )}
        {pageDelete.open && (
          <DialogConfirm
            open={pageDelete.open}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={this.deleteTask}
            onClose={() => this.handlePageDelete()}
            title={language.WARNING_DELETE_ITEM_SELECTED}
            message={pageDelete.values.subcategoria.replace(/<[^>]*>?/gm, '')}
          />
        )}
      </div>
    );
  }
}
