import React, { useEffect, useState, useReducer } from 'react';
import moment from 'moment';
import MemoryIcon from '@material-ui/icons/Memory';
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import Button from '@common/Button';
import Details from './DetailsAlerts';
import Link from './LinkGI'
 import './index.css'
import { Checkbox } from '@material-ui/core';
 import './GroupAlerts.css'
import { company } from '../dashboard/Graph';
import Status from './Status';
import Constants from '@data/Constants';
import Store from '@data/Store';

const { language } = Store; 

const tenant = Constants.Tenant;

const TableType = ({
    countOk,
    countYellow,
    countRed,
    monitorGroups,
    companyId,
    countTotal,
    devices,
    ioLinks,
    action ,
    APIEndpoints ,
    companies,
    ioServers,
    alerts,
    page,
    refresh_ ,
    listdevice
}) => {
    const [groupType, setGroupType] = useState([]);
    const  [monitor,setMonitores] =useState([]);
    const  [devicea,setDevicea] =useState([]);
    const  [groups,setGroups] =useState([]);
    let [filter, setFilter] = useState([0]);
    const [grupos,setGrupos]=useState([]);
    const [dataAlert,setdataAlert]=useState([]);
    const [valor,setValor]=useState([]);
    const [c,setc]=useState(0);
  
 
    const [cpu,setCpu]= useState(0);
    const [men,setMen]= useState(0);
    const [hd,setHd]= useState(0);
    const [temp_,setTemp]= useState(0);
useEffect(() => {

 
 

let cont=c;
let vv=valor;


  alerts.on(`${tenant}_alerts`, (data) => {
    //: {key: "mem-info", id_device: 2, id_company: 1}
    
    let cpu=0;
   let memoria=0;
   let hd=0;
  let temp = 0 ;
   
     cont++
   
if(cont==30||cont==1){
      if(cont==30){
        setc(0)
      }    
  data.alertsall.countYellow.filter(c=>companyId >0 ?c.id_company== companyId : c.id_company >0 &&
    listdevice != null ? listdevice.includes(c.id_device) ==true : c.id_device>0
     ).map(c=>{
 
    if(c.key=="mem-info"){
memoria++
    }
    if(c.key=="cpu-stats"){

     cpu++   
    }
    if(c.key=="fs-list"){
       hd++ 
    }
    if(c.key=="temp"){
      temp++ 
    }


  })
  data.alertsall.countRed.filter(c=>companyId >0 ?c.id_company== companyId : c.id_company >0  &&
    listdevice != null ? listdevice.includes(c.id_device) ==true : c.id_device>0
    ).map(c=>{
    
    
    if(c.key=="mem-info"){
        memoria++
            }
            if(c.key=="cpu-stats"){
        
             cpu++   
            }
            if(c.key=="fs-list"){
               hd++ 
            }
            if(c.key=="temp"){
              temp++ 
            }
        })
    
    
        setCpu(cpu)
        setHd(hd)
        setMen(memoria)
        setTemp(temp)
    }

 
     })


},[alerts,companyId])

 window._groupType = groupType;
  
  return (
     
 <div id="Gi2_alerts_group" className="Item d-flex align-items-center justify-content-start content-min m-auto">

 <div className="_div">
    <span>CPU</span>  <br></br>
 <i class="fas fa-microchip"></i><br></br>
     {cpu}</div>
     
     <div className="_div" >
     <span>MEM</span> <br></br>
       <i class="fas fa-memory"></i><br></br>{men}</div>
       
     <div className="_div">
      <span>{language.DISK}</span> <br></br>
     <i class="fas fa-hdd"></i><br></br>{hd}</div>

     <div className="_div">
     <span>°C</span> <br></br>
     <i class="fas fa-thermometer-three-quarters"></i><br></br>{temp_}</div>
     

</div>)
}

export default TableType;
