import React, {memo, useState, useEffect} from 'react';
import { Tooltip } from 'react-tippy';
/*------------------------------common-------------------------------------*/
import Button from '@common/Button';
/*------------------------------material-ui-------------------------------------*/
import {Checkbox, Avatar} from '@material-ui/core';
import { Droppable ,Draggable} from 'react-drag-and-drop';
import Actions from '@data/Actions'
import Constants from '@data/Constants'
import Store from '@data/Store';

const { language } = Store;
const st = {padding:0}

const ListItem = memo(({
   onActionBtn=()=>null,
   data,
   onChange,
   index,
})=>{

   let [generalParams, setGeneralParams] = useState([])
   useEffect(() => {
     const action  = new Actions();
           action.execute('get', Constants.APIEndpoints.GENERAL_PARAMETERS, 'general_params').then(res => {
         setGeneralParams(res.data)
       }).catch(error =>{
       })
     
   }, []);

	return(
		<li className='mb-3'>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center'>
               <Checkbox 
                  style={st} 
                  checked={data.selected} 
                  disabled={data.selected}
                  onChange={evt => {
                     onChange(evt.target.checked, data)
                  }}
               />
					<span className='text ml-2 ellipsis'><b>{index}-</b> <span>{data.text}</span></span>
				</div>
            {!data.selected?(
               <div>
                  <Button
                     icon='fas fa-pencil-alt'
                     variant='circle'
                     color='success'
                     label={language.EDIT}
                     outlined
                     className='btn-control-outlined'
                     onClick={()=>onActionBtn('edit', data)}
                  />
                  <Button
                     icon='fas fa-trash-alt'
                     variant='circle'
                     color='danger'
                     label={language.ERASE}
                     outlined
                     className='btn-control-outlined'
                     onClick={()=>onActionBtn('delete', data)}
                  />
               </div>
            ):null}
			</div>
			<div>
				<div className='d-flex align-items-center mt-1 pl-2 ml-4'>
					<Button
						icon={data.initialized?'fas fa-pause':data.selected?'fas fa-stop':'fas fa-play'}
						variant='circle'
						color='success'
                  label={language.START}
						className='btn-control'
						size={.6}
						onClick={()=> !data.selected ? onActionBtn('start', data) : null}
					/>
					<span className='time'>{data.time_in_days && generalParams.hours_day ? data.time.split(':')[0] / generalParams.hours_day : data.time}{data.time_in_days ? 'd' : 'h'}</span>
					<span className='status'>{data.status}</span>
					<span className='divisor'>|</span>
					<div className='content-username'>
						{data.username}
					</div>


				</div>
			</div>
		</li>
	)
})

export default memo(({
   data=[], 
   onActionBtn, 
   setFormRuntime,
   action,
   APIEndpoints
})=>{

   function onChange(checked, data){
      setFormRuntime({...data, selected:true, update:true});  
   }
   const onDrop =(drop, index)=>{
      // console.log(drop.milestone)
       drop=   JSON.parse(drop.milestone)
      
       drop.order = index
     
       action
       .execute(
         'post',
         APIEndpoints.PROJECT + '/activity/subactivity',
         '',
         drop,
         null,
         {btn_loading:''}
     ).then(e => {
      action.execute(
         'get', 
         `${APIEndpoints.PROJECT}/activity/subactivity?fk_id_activity=${drop.fk_id_activity}`,
         'subActivities'
      );
     });
      
   };
//  data.reverse();

	return(
      



      
      <div className='Block_3 mb-4'>
         <span className='label-items'>{language.SUBACTIVITIES}</span>
         <ul>	
	
            {data.map((e,i)=>(
               <Droppable types={['milestone']} 
                onDrop={e => onDrop(e, i+1)}
                 className='border-radius-10 KanbanCardColumn pb-2'
                >
              <Draggable type="milestone" data={(JSON.stringify(e))} className='Card d-flex w-100'>
		   
               <ListItem 
                  key={i} 
                  onChange={onChange} 
                  index={i+1} 
                  data={e} 
                  onActionBtn={onActionBtn}
               />
               </Draggable>
               </Droppable>
            ))}
         
         </ul>
      </div>
      
	)
})