import {
    MuiThemeProvider,
    createTheme,
    makeStyles,
    withStyles,
    styled,
  } from '@material-ui/core/styles';
  import randomcolor from 'randomcolor';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      border: '1px solid #DEDEDE',
      height: '100%',
    },
    textSubTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#a2a2a2',
      marginBottom: 25,
    },
    textTitle: {
      color: '#33ACA0',
      fontSize: 14,
      fontWeight: 'bold',
    },
    description: {
      color: '#707070',
      fontSize: 12,
    },
    spacing: {
      padding: 5,
    },
    icon: {
      color: (props) => props.color || '#019FC2',
    },
    text: {
      color: '#707070',
      textAlign: 'justify',
      marginLeft: 45,
    },
    fullHeightCard: {
      height: '100%',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemTask: {
      padding: 13,
      border: `1px solid ${randomcolor()}`,
    },
    textDescription: {
      fontWeight: 700,
      color: 'var(--success)',
      fontSize: 16,
    },
    subtitle_solicitation: {
      color: '#019FC2',
      fontWeight: 'bold',
    },
    button: {
      color: '#FFF',
      background: '#58A9A0',
      padding: '5px 73px',
      float: 'right',
      '&:hover:': {
        background: '#58A9A0',
      },
    },
    atend: {
      display: 'flex',
    },
  }));
  export default useStyles