import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

// ----------------------------------------------------------------------

import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import Store from '@data/Store';

import { getCoinsService, getCoinByIdService } from '../coin.services';
import { columns } from './coin.columns';

// ----------------------------------------------------------------------

export const CoinList = ({ page, setCoinSelected, setEditCoin, setPage }) => {
  const [coins, setCoins] = useState([]);
  const { language } = Store;

  const handleAddCoin = () => {
    setPage('add');
  };

  const handleEditCoin = ({ id }) => {
    const getCoin = async () => {
      getCoinByIdService({ id }).then((result) => {
        setEditCoin(result);
        setPage('edit');
      });
    };
    getCoin();
  };

  const handleDelete = ({ id_coin, name }) => {
    setPage('delete');
    setCoinSelected({ id_coin, name });
  };

  useEffect(() => {
    const getCoins = async () => {
      const result = await getCoinsService();
      const normalizedResult = result.map((coin) => ({
        ...coin,
        action: (
          <Chip
            className="mx-2 muit-chip"
            label={
              <>
                <Button
                  variant="circle"
                  icon="fas fa-edit"
                  label={language.EDIT}
                  color="default"
                  style={{ fontSize: 12 }}
                  size={0.7}
                  className="mr-2"
                  onClick={() => handleEditCoin({ id: coin.id_coin })}
                />
                <Button
                  variant="circle"
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13 }}
                  size={0.7}
                  onClick={() =>
                    handleDelete({
                      id_coin: coin.id_coin,
                      name: coin.name,
                    })
                  }
                />
              </>
            }
          />
        ),
      }));

      setCoins(normalizedResult);
    };

    page === null && getCoins();
  }, [page]);

  return (
    <CommonTable
      paginationTop={false}
      beforeBar={
        <RaisedButton
          circleButton
          icon="fas fa-plus"
          style={{ zoom: 0.8 }}
          zoom={0.8}
          color="primary"
          className="mr-3"
          label={`${language.ADD} ${language.COIN}`}
          onClick={handleAddCoin}
        />
      }
      col={columns}
      data={coins}
      index="coin-list"
      rowsPerPageOptions={[100, 250]}
      searchColumn
      orderColumn
    />
  );
};
