import React from 'react';
import { Tooltip } from 'react-tippy';
import {Avatar} from '@material-ui/core';
import classnames from 'classnames';

export default function({
	avatares=[]
}){
	return(
		<div className='content-avatar d-flex align-items-center'>
			{avatares.slice(0,3).map((e, i)=>(
				<Avatar src={e.src} key={e.name+'-'+i} className={classnames('avatar', {'ml-0':i==0})}>
					{e.name.slice(0,2)}
				</Avatar>
			))}
			
			{avatares.length>3&&
				<Tooltip
				  html={
				    <div style={{ color: '#fff', fontSize: 11 }}>
				      {avatares.slice(3).map((u, i) => (
				        <div
				          style={{ marginLeft: '5px', marginTop: '2px' }}
				          className="d-flex align-items-center"
				          key={u.name+'-'+i}
				        >
				          <Avatar
				            className={'avatar'}
				            key={i}
				            src={u.src}
				            style={{
				              fontSize: '10px',
				              width: '17px',
				              height: '17px',
				              
				            }}
				          >
				            {u.name.slice(0,2)}
				          </Avatar>
				          <span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
				        </div>
				      ))}
				    </div>
				  }
				  position="right"
				  theme="light"
				  interactive
				>
				    <Avatar className='avatar more'>
				    	+{avatares.length-3}
				    </Avatar>
				</Tooltip>
			}
		</div>
	)
}