import RaisedButton from '../../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import lodash from 'lodash';
import {

  SelectField,
  MenuItem,

  RadioButton,
  Button,
  CircularProgress
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../../graphs/SimplesBarGrap';
import CommonTable from '@common/CommonTable';
import { Grid, Card, Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';

export default class BarTableService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      valueCompanie: null,
      stripedRows: false,
      showRowHover: true,
      enableSelectAll: false,
      height: '300px',
      inputLinkClicked: false,
      inputLinkClickedDetails: false,
      open: false,
      showResultsReport: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(nameService) {
    var objReportDialog = this.props.reportCall;
    var contractValue = this.props.resultContract;

    var tableDetailList = [];
    Object.keys(objReportDialog).forEach(function (key) {
      if (
        objReportDialog[key].name == nameService &&
        objReportDialog[key].fk_id_contract == contractValue
      ) {
        tableDetailList.push(objReportDialog[key]);
      }
    });
    //console.log(tableDetailList);

    this.tableDetailList = tableDetailList;

    // console.log(tableDetailList);
    this.setState({ open: true, inputLinkClickedDetails: true });
  }

  closeModal() {
    this.setState({ open: false, inputLinkClickedDetails: false });
  }

  render() {
    var objReport = this.props.reportCall;
    var contractValue = this.props.resultContract;

    // Pega todos os servirços que foi prestadado para esse contrato;
    var tableDetailList = [];
    Object.keys(objReport).forEach(function (key) {
      if (objReport[key].fk_id_contract == contractValue) {
        tableDetailList.push(objReport[key]);
      }
    });

    // Pegando referencia de cada tipo de serviço;
    var tableDetailUniqueService = lodash.uniqBy(tableDetailList, 'name');
    this.allServiceContract = tableDetailList;
    this.detailUniqueService = tableDetailUniqueService;

    // { name: 'Page A', Resolved: 4000, Closed: 2400, Open: 2400, InProgress: 2400 },
    // Array quantidade de status Resolved
    var arrayResolvedLength = [];
    var arrayClosed = [];
    var arrayOpen = [];
    var arrayProgress = [];
    var resolvedVar = 0;
    var closedVar = 0;
    var openVar = 0;
    var inProgressVar = 0;
    Object.keys(tableDetailUniqueService).forEach(function (key) {
      resolvedVar = 0;
      closedVar = 0;
      openVar = 0;
      inProgressVar = 0;
      Object.keys(tableDetailList).forEach(function (key2) {
        if (tableDetailUniqueService[key].name == tableDetailList[key2].name) {
          if (tableDetailList[key2].status == 'Resolved') {
            arrayResolvedLength.push({
              name: tableDetailUniqueService[key].name,
              Resolved: (resolvedVar = resolvedVar + 1)
            });
          } else {
            arrayResolvedLength.push({
              name: tableDetailUniqueService[key].name,
              Resolved: 0
            });
          }
          if (tableDetailList[key2].status == 'Closed') {
            arrayClosed.push({
              name: tableDetailUniqueService[key].name,
              Closed: (closedVar = closedVar + 1)
            });
          } else {
            arrayClosed.push({
              name: tableDetailUniqueService[key].name,
              Closed: 0
            });
          }
          if (tableDetailList[key2].status == 'Open') {
            arrayOpen.push({
              name: tableDetailUniqueService[key].name,
              Open: (openVar = openVar + 1)
            });
          } else {
            arrayOpen.push({
              name: tableDetailUniqueService[key].name,
              Open: 0
            });
          }
          if (tableDetailList[key2].status == 'In Progress') {
            arrayProgress.push({
              name: tableDetailUniqueService[key].name,
              InProgress: (inProgressVar = inProgressVar + 1)
            });
          } else {
            arrayProgress.push({
              name: tableDetailUniqueService[key].name,
              InProgress: 0
            });
          }
        }
      });
    });

    var arrayResolvedSum = lodash.uniqBy(
      lodash.orderBy(
        arrayResolvedLength,
        ['name', 'Resolved'],
        ['asc', 'desc']
      ),
      'name'
    );
    var arrayClosedSum = lodash.uniqBy(
      lodash.orderBy(arrayClosed, ['name', 'Closed'], ['asc', 'desc']),
      'name'
    );
    var arrayOpenSum = lodash.uniqBy(
      lodash.orderBy(arrayOpen, ['name', 'Open'], ['asc', 'desc']),
      'name'
    );
    var arrayProgressSum = lodash.uniqBy(
      lodash.orderBy(arrayProgress, ['name', 'InProgress'], ['asc', 'desc']),
      'name'
    );

    var arrayFinal = [];
    Object.keys(tableDetailUniqueService).forEach(function (key) {
      Object.keys(arrayResolvedSum).forEach(function (key2) {
        Object.keys(arrayClosedSum).forEach(function (key3) {
          Object.keys(arrayOpenSum).forEach(function (key4) {
            Object.keys(arrayProgressSum).forEach(function (key5) {
              if (
                tableDetailUniqueService[key].name ==
                arrayResolvedSum[key2].name &&
                tableDetailUniqueService[key].name ==
                arrayClosedSum[key3].name &&
                tableDetailUniqueService[key].name == arrayOpenSum[key4].name &&
                tableDetailUniqueService[key].name ==
                arrayProgressSum[key5].name
              ) {
                arrayFinal.push({
                  name: tableDetailUniqueService[key].name,
                  Resolved: arrayResolvedSum[key2].Resolved,
                  Closed: arrayClosedSum[key3].Closed,
                  Open: arrayOpenSum[key4].Open,
                  InProgress: arrayProgressSum[key5].InProgress
                });
              }
            });
          });
        });
      });
    });

    this.dataGrph = arrayFinal;

    const actions = [
      <Button label="Fechar" primary={true} onClick={this.closeModal} />
    ];
    let { language } = this.props;
    var dataGrph = {
      r: this.dataGrph.map(e => ({ name: e.name, y: e.Resolved })),
      c: this.dataGrph.map(e => ({ name: e.name, y: e.Closed })),
      o: this.dataGrph.map(e => ({ name: e.name, y: e.Open })),
      i: this.dataGrph.map(e => ({ name: e.name, y: e.InProgress })),
    }


    return (
      <div>
        <Card className="px-4 my-4">
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ paddingTop: 40 }}>

              <ReactHighcharts config={ContainerChart.container0({
                title: language.STATUS_CHART_BY_SERVICE,
                subtitle: language.HOVER_OVER_COLUMN,
                yTitle: '',
                series: [
                  {
                    type: 'column',
                    name: language.TICKET_STATUS.RESOLVED,
                    data: dataGrph.r,
                    color: 'var(--success)'
                  },
                  {
                    type: 'column',
                    name: language.TICKET_STATUS.CLOSED,
                    data: dataGrph.c,
                    color: 'var(--danger)'
                  },
                  {
                    type: 'column',
                    name: language.TICKET_STATUS.OPEN,
                    data: dataGrph.o,
                    color: 'var(--primary)'
                  },
                  {
                    type: 'column',
                    name: language.TICKET_STATUS.IN_PROGRESS,
                    data: dataGrph.i,
                    color: 'var(--warning)'
                  },
                ],
              })}
              />

            </Grid>
            <Grid item xs={6} className='pb-4'>
              <CommonTable
                col={[
                  { key: 'name', label: language.NAME },
                  { key: 'details', label: language.DETAILS },
                ]}
                fluid
                maxHeight={300}
                title={language.SERVICE_TABLE}
                data={
                  this.props.inputLinkClicked ? (
                    this.detailUniqueService.map((row, index) => ({
                      name: row.name,
                      details: (
                        <RaisedButton
                          circleButton
                          icon='fas fa-info'
                          color='primaryGradient'
                          label={language.CLICK_ICON_SEE_ATTENDANCE}
                          onClick={() =>
                            this.openModal(row.name)
                          }
                          style={{
                            width: 23,
                            height: 23,
                            fontSize: 15
                          }}
                        />
                      )
                    }))
                  ) : []
                }
              />
            </Grid>
          </Grid>

        </Card>
        <Dialog

          open={this.state.open}
          onClose={() => this.closeModal()}
          title={language.DETAILS_OF_THE_CALLS_MADE}
          maxWidth='xl'

        >
          <CommonTable
            col={[
              {
                key: 'name',
                label: language.DEVICE_NAME
              },
              {
                key: 'subject',
                label: language.SUBJECT_OF_THE_CALL
              },
              {
                key: 'device',
                label: language.DEVICE_TYPE
              },
              {
                key: 'status',
                label: language.STATE
              },
              {
                key: 'resolution_time',
                label: language.RESOLUTION_TIME
              },
            ]}

            data={
              this.state.inputLinkClickedDetails ? (
                this.tableDetailList.map((row, index) => ({
                  name: row.name,
                  subject: row.subject,
                  type: row.type,
                  status: row.status,
                  time_resolution: row.time_resolution
                }))
              ) : []
            }
          />
        </Dialog>
      </div>
    );
  }
}
