import React from 'react';
import CommonPage from '../../../common/CommonPage';
import PageLayout from '../../../common/PageLayout';
import Store from '../../../data/Store';
import Constants from '../../../data/Constants';
import ConsumerList from './ConsumerList';

export default function () {
  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title="Lista de Clientes"
        subTitle={Store.language.CONSUMER_SERVICE_SYSTEM}
      >
        <ConsumerList
          APIEndpoints={Constants.APIEndpoints}
          language={Store.language}
        />
      </PageLayout>
    </CommonPage>
  );
}
