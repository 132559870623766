import React, { Component } from 'react'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'

export default class GestorDeProjeto extends Component {
  render() {
    const { data } = this.props
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid stroke="#d9d9d9" strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            name="Gestor 1"
            dataKey="pv"
            stroke="#8884d8"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            name="Gestor 2"
            dataKey="uv"
            stroke="#82ca9d"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            name="Gestor 3"
            dataKey="mv"
            stroke="#ff7300"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }
}
