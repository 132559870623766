import React, { useEffect, useState } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';

export default function MailForm({
  language,
  handlePage,
  onFormSubmit,
  values,
}) {
  const [onlySend, setOnlySend] = useState(false);

  useEffect(() => {
    if (values && values?.only_send == true) {
      setOnlySend(true);
    }
  }, []);

  let fields;

  if (onlySend) {
    fields = [
      {
        col: 4,
        name: 'mail',
        label: language.USERNAME,
        type: 'text',
        required: true,
      },
      {
        col: 4,
        name: 'password',
        label: language.MAIL_PASSWORD,
        type: 'password',
        required: values ? false : true,
      },
      {
        col: 4,
        name: 'smtp',
        label: language.MAIL_SMTP,
        type: 'text',
        required: true,
      },
      {
        col: 4,
        name: 'smtp_port',
        label: language.SMTP_PORT,
        type: 'number',
        required: true,
      },
    ];
  } else {
    fields = [
      {
        col: 4,
        name: 'mail',
        label: language.MAIL_MAIL,
        type: 'text',
        required: true,
      },
      {
        col: 4,
        name: 'password',
        label: language.MAIL_PASSWORD,
        type: 'password',
        required: values ? false : true,
      },
      {
        col: 4,
        name: 'pop',
        label: language.MAIL_POP,
        type: 'text',
        required: true,
      },
      { col: 4, name: 'pop_port', label: language.POP_PORT, type: 'number' },
      { col: 4, name: 'smtp', label: language.MAIL_SMTP, type: 'text' },
      { col: 4, name: 'smtp_port', label: language.SMTP_PORT, type: 'number' },
    ];
  }

  fields.push(
    {
      col: 4,
      name: 'tls',
      label: language.MAIL_TLS,
      type: 'select',
      options: [
        { value: 'S', label: language.YES },
        { value: 'N', label: language.NOT },
      ],
    },
    {
      label: language.ONLY_SEND,
      col: 12,
      name: 'only_send',
      type: 'switch',
    }
  );

  let button = { label: language.SAVE };

  return (
    <Dialog
      monitor_scroll
      miniVersion={true}
      title={values ? language.EDIT : language.ADD}
      modal={false}
      open={true}
      onClose={() => handlePage('list')}
    >
      <CommonForm
        values={values}
        fields={fields}
        onSubmit={onFormSubmit}
        button={button}
        onChangeField={(f, v) => {
          if (f && f.name == 'only_send') {
            onlySend ? setOnlySend(false) : setOnlySend(true);
          }
        }}
      />
    </Dialog>
  );
}
