import React from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import ConfigurationGroupOption from './ConfigurationGroupOption';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card } from '@material-ui/core';
import Header from '@common/Header';
import PageLayout from '@common/PageLayout';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
import classnames from 'classnames';
//-----------------------icons----------------------------------
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DevicesIcon from '@material-ui/icons/Devices';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
//-----------------------img----------------------------------
import configurationApresentationPT from '@images/diagrama2024.png';
import configurationApresentationES from '@images/diagrama2024-es.png';
import configurationApresentationEN from '@images/diagrama2024-en.png';
import ChiefAlternative from '@components/chiefAlternative/ChiefContainer';
import './index.css';

// ###################################-PÁGINAS-#############################################################
// ------------------CONFIGURAÇÕES GERAIS-----------------------------
import PerfilContainer from '@components/perfil/PerfilContainer.js';
import UserContainer from '@components/user/UserContainer';
import UserPermissionsContainer from '@components/userPermissions/UserPermissionsContainer';
import GeneralParametersContainer from '@components/GeneralParameters/GeneralParametersContainer';
import ExtensionsContainer from '@components/extensionsFile/ExtensionContainer';
import HelpContainer from '@components/help/HelpContainer';
import PasswordContainer from '@components/PasswordSafe/PasswordContainer';
import NotificationCenter from '@components/notificationCenter/';
import NotificationCenterTags from '@components/notificationCenter/notificationCenterTags/';
import SlaWork from '@components/slaWork/';
import SlaConfig from '@components/slaConfig/';
import SlaReprocessing from '@components/slaReprocessing/';
// -------------------------EMPRESAS----------------------------------
import CompanyContainer from '@components/company/CompanyContainer';
import LocationContainer from '@components/location/LocationContainer';
import OccupationContainer from '@components/occupation/OccupationContainer';
import DepartmentContainer from '@components/department/DepartmentContainer';
import MailContainer from '@components/mail/MailContainer';
import CompanyTimeContainer from '@components/companytime/CompanyTimeContainer';
import Ad from '@components/ad/AdContainer';
import Logo from './Logo';
// -------------------------GESTÃO DE CONTRATO----------------------------------
import SlaContainer from '@components/sla/SlaContainer';
import ContractContainer from '@components/contract/ContractContainer';
// -------------------------GESTÃO DE DISPONIBILIDADE----------------------------------
import DeviceContainer from '@components/device/DeviceContainer';
import SnmpContainer from '@components/snmp/snmpContainer';
import DeviceType from '@components/devicetype/DeviceTypeContainer';
import DeviceIC from '@components/deviceIC/DeviceICContainer';
// -------------------------GESTÃO DE DEMAND----------------------------------
import AutomationContainer from '@components/Automation/AutomationContainer';
import TaskContainer from '@components/task/TaskContainer';
import CatalogContainer from '@components/catalog/CatalogContainer';
import CategoryContainer from '@components/category/CategoryContainer';
import GroupContainer from '@components/group/GroupContainer';
import FormBuilderContainer from '@components/FormBuilder/FormBuilderContainer';
import EventCab from '@components/EventCab/EventCabContainer';
import ChangeCabContainer from '@components/ChangeCab/ChangeCabContainer';
import ChangeRisk from '@components/ChangeRisk/ChangeRiskContainer';
import AnswerContainer from '@components/answer/AnswerContainer';
import StatusAwatingContainer from '@components/statusAwaiting/StatusContainer';
import TechEvaluationRegistrationContainer from '@components/techEvaluationRegistration/TechEvaluationContainer';
import EffortContainer from '@components/EffortValue/EffortContainer';

import ChangeManagementContainer from '../changemanagement/ChangeManagementContainer';
import ApprovationWorkflow from '../changemanagement/approvationWorkflow';
import GroupChangeConfigurationContainer from '../changemanagement/GroupChangeConfiguration/GroupChangeConfigurationContainer';
import ChannelManagement from '@components/ChannelManagement';
import { CoinManagementList } from '@components/CoinManagement';
// -------------------------GESTÃO DE PROJETOS----------------------------------
// import Project from '../projectmanagementTemplate/ProjectManagementContainer';
import Project from '../ProjectsListTemplate';
//import ProjectStatusContainer from '../ProjectStatus/ProjectStatusContainer';
import Allowance from '../allowance/Allowance';
import SlaStatusAllowance from '../slaStatusAllowance';
import CompanyHoliday from '../companytime/CompanyHoliday';
import ApprovalCenterHistoric from '../ApprovalCenter/ApprovalCenterHistoric';
import ResolutionCategoryContainer from '../ResolutionCategory/ResolutionCategoryContainer';
import ResolutionCodeContainer from '../ResolutionCategory/ResolutionCodeContainer';
import OutageContainer from '../Outage/OutageContainer';
import PasswordQuestionContainer from '../PasswordQuestions/PasswordQuestionContainer';
import AuditConfigContainer from '../auditConfig/AuditConfigContainer';
import { SquadManagementList } from '../SquadManagement';
//-------------SAC CONFIGURATIONS--------------------
//import {ThemeSacConfigurationContainer} from '@components/sac/ThemeSacConfigurationContainer'

@observer
export default class ConfigurationContainer extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      logo: false,
      general_params: [],
    };
    this.ScrollRef = React.createRef();
  }

  componentWillMount() {
    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, 'general_params')
      .then((result) => {
        this.setState({ general_params: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handlePage = (route, params) => {
    if (this.ScrollRef.current !== null) {
      this.ScrollRef.scrollTop = 0;
    }
    this.redirect2(route, params);
  };
  render() {
    let { language } = this.store;
    let { general_params } = this.state;
    let history = this.history2;
    let { pathname } = history.location;
    let user = this.store.getUserLoged();
    let translate = language;
    let p = user.perfil;
    return (
      <CommonPage
        loading={false}
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
      >
        <style>
          {
            '\
                    html{\
                        zoom: 1;\
                    }\
                    '
          }
        </style>
        <Animate>
          <PageLayout
            icon="configurationsIcon"
            title={language.CONFIG}
            rightElements={[]}
          >
            <div id="ConfigurationContainer">
              <Header />
              <Card
                style={{
                  borderRadius: '0px 0px 4px 4px',
                  height: 'calc(100vh - 118px)',
                }}
                className={classnames('content pl-0', {
                  'pr-0': !testRoute('/dash/configuration', pathname),
                })}
              >
                <div className="d-flex h-100">
                  {/*#################################################################-MENU-##############################################################################*/}
                  <PerfectScrollbar
                    className="scrollVisible content-ConfigurationGroupOption content pt-0 pb-0"
                    style={{
                      maxHeight: '100%',
                    }}
                    option={{
                      scrollXMarginOffset: 4,
                      suppressScrollX: true,
                    }}
                  >
                    {[
                      // ---------------------------------------CONFIGURAÇÕES GERAIS----------------------------------------------------------
                      {
                        title: translate.GENERAL_SETTINGS,
                        icon: <i className="fas fa-cogs" />,
                        visible:
                          user.role != 'solicitant' &&
                          (
                            (
                              (p.perfil == null && user.role != 'solicitant') ||
                              p.perfil
                            ) ||
                            (
                              ((p.user_create == null &&
                                user.role != 'solicitant') ||
                                p.user_create) == true
                            ) ||
                            (
                              (p.user_create == null &&
                                user.role != 'solicitant') ||
                              p.file_extensions
                            ) ||
                            (
                              window.location.hostname.indexOf(
                                `cs.sensrit.com.br`
                              ) > -1
                                ? true
                                : false
                            ) ||
                            (
                              (p.password_vault == null &&
                                user.role != 'solicitant' &&
                                user.id_company == 1) ||
                              p.password_vault
                            ) ||
                            (
                              (p.password_questions == null &&
                                user.role != 'solicitant') ||
                              (p.password_questions)
                            )
                          ),
                        items: [
                          {
                            title: translate.PROFILE,
                            body: translate.CONFIG_PROFILE_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/perfil'),
                            active: testRoute(
                              '/dash/configuration/perfil',
                              pathname
                            ),
                            visible:
                              (p.perfil == null && user.role != 'solicitant') ||
                              p.perfil,
                          },
                          {
                            title: translate.USERS,
                            body: translate.CONFIG_USERS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/users'),
                            active: testRoute(
                              '/dash/configuration/users',
                              pathname
                            ),
                            visible:
                              ((p.user_create == null &&
                                user.role != 'solicitant') ||
                                p.user_create) == true,
                          },
                          {
                            title: translate.USERS_PERMISSIONS,
                            body: translate.CONFIG_USERS_PERMISSIONS,
                            onClick: () =>
                              this.handlePage('/dash/configuration/userspermissions'),
                            active: testRoute(
                              '/dash/configuration/userspermissions',
                              pathname
                            ),
                            visible:
                              ((p.user_create == null &&
                                user.role != 'solicitant') ||
                                p.user_create) == true,
                          },
                          {
                            title: translate.GENERAL_PARAMETERS,
                            body: translate.CONFIG_GENERAL_PARAMETERS_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/generalparameters'
                              ),
                            active: testRoute(
                              '/dash/configuration/generalparameters',
                              pathname
                            ),
                            visible:
                              (p.user_create == null &&
                                user.role != 'solicitant') ||
                              p.user_create,
                          },
                          {
                            title: translate.FILE_EXTENSIONS,
                            body: translate.CONFIG_FILE_EXTENSIONS_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/extensionsfile'
                              ),
                            active: testRoute(
                              '/dash/configuration/extensionsfile',
                              pathname
                            ),
                            visible:
                              (p.user_create == null &&
                                user.role != 'solicitant') ||
                              p.file_extensions,
                          },
                          {
                            title: translate.HELP_DESK,
                            body: translate.CONFIG_HELP_DESK_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/centralhelp'
                              ),
                            active: testRoute(
                              '/dash/configuration/centralhelp',
                              pathname
                            ),
                            visible:
                              window.location.hostname.indexOf(
                                `cs.sensrit.com.br`
                              ) > -1
                                ? true
                                : false,

                            //  visible: ((p.user_create == null && user.role != 'solicitant' && user.id_company == 1) || p.user_create)
                          },
                          {
                            title: translate.PASSWORD_VAULT,
                            body: translate.CONFIG_PASSWORD_VAULT_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/passwordsafe'
                              ),
                            active: testRoute(
                              '/dash/configuration/passwordsafe',
                              pathname
                            ),
                            visible:
                              (p.password_vault == null &&
                                user.role != 'solicitant' &&
                                user.id_company == 1) ||
                              p.password_vault,
                          },
                          {
                            title: translate.EMAIL_TEMPLATES,
                            body: translate.CONFIG_EMAIL_TEMPLATES_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/templateemail'
                              ),
                            active: testRoute(
                              '/dash/configuration/templateemail',
                              pathname
                            ),
                            visible:
                              window.location.hostname.indexOf(
                                `cs.sensrit.com.br`
                              ) > -1
                                ? true
                                : false,
                          },
                          {
                            title: translate.EMAIL_TEMPLATES,
                            body: translate.CONFIG_EMAIL_TEMPLATES_2_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/templateemail'
                              ),
                            active: testRoute(
                              '/dash/configuration/templateemail',
                              pathname
                            ),
                            visible:
                              window.location.hostname.indexOf(
                                `cs.sensrit.com.br`
                              ) > -1
                                ? true
                                : false,
                          },
                          {
                            title: translate.PASSWORD_QUESTION,
                            body: translate.PASSWORD_QUESTION_DESCRIPTION,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/passwordquestions'
                              ),
                            active: testRoute(
                              '/dash/configuration/passwordquestions',
                              pathname
                            ),
                            visible:
                              (p.password_questions == null &&
                                user.role != 'solicitant') ||
                              (p.password_questions),
                          },
                        ],
                      },

                      // ---------------------------------------CENTRAL DE NOTIFICAÇÕES----------------------------------------------------------
                      {
                        title: translate.NOTIFICATION_CENTER,
                        icon: <i className="fas fa-bell" />,
                        visible:
                          user.role != 'solicitant' &&
                          (
                            (p.notifications_center == null &&
                              user.role != 'solicitant') ||
                            (p.notifications_center)
                          ),
                        items: [
                          {
                            title: translate.NOTIFICATION_CENTER,
                            body: translate.NOTIFICATION_CENTER_DESCRIPTION,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/notificationcenter'
                              ),
                            active: testRoute(
                              '/dash/configuration/notificationcenter',
                              pathname
                            ),
                            visible:
                              (p.notifications_center == null &&
                                user.role != 'solicitant') ||
                              (p.notifications_center),
                          },
                          {
                            title: translate.NOTIFICATION_CENTER_TAGS,
                            body: translate.NOTIFICATION_CENTER_TAGS_DESCRIPTION,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/notificationcentertags'
                              ),
                            active: testRoute(
                              '/dash/configuration/notificationcentertags',
                              pathname
                            ),
                            visible:
                              user.id === 1 &&
                              user.email === "admin@sensrit.com.br" &&
                              (
                                 (p.notifications_center == null &&
                                    user.role != 'solicitant') ||
                                 (p.notifications_center)
                              ),
                          },
                        ],
                      },

                      // ---------------------------------------EMPRESAS----------------------------------------------------------
                      {
                        title: translate.COMPANIES,
                        visible: user.role != 'solicitant',
                        icon: <BusinessCenterIcon />,
                        /*visible:
                          (
                            (p.company_department == null &&
                              user.role != 'solicitant') ||
                            p.company_department
                          ) ||
                          (
                            (p.company_department == null &&
                              user.role != 'solicitant') ||
                            p.company_department
                          ) ||
                          (
                            (p.company_subdepartment == null &&
                              user.role != 'solicitant') ||
                            p.company_subdepartment
                          ) ||
                          (
                            (p.positions_users == null &&
                              user.role != 'solicitant') ||
                            p.positions_users
                          ) ||
                          (
                            (p.company_ldpa == null &&
                              user.role != 'solicitant') ||
                            p.company_ldpa
                          ) ||
                          (
                            (p.company_company_hour == null &&
                              user.role != 'solicitant') ||
                            p.company_company_hour
                          ) ||
                          (
                            (p.company_mail_policy == null &&
                              user.role != 'solicitant') ||
                            p.company_mail_policy
                          ) ||
                          (
                            (p.company_company_logo == null &&
                              user.role != 'solicitant') ||
                            p.company_company_logo
                          ),*/
                        items: [
                          {
                            title: translate.COMPANIES,
                            body: translate.CONFIG_COMPANIES_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/company'),
                            active: testRoute(
                              '/dash/configuration/company',
                              pathname
                            ),
                            visible:
                              (p.company_company_list == null &&
                                user.role != 'solicitant') ||
                              p.company_company_list,
                          },
                          {
                            title: translate.DEPARTMENT,
                            body: translate.CONFIG_DEPARTMENT_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/department'),
                            active: testRoute(
                              '/dash/configuration/department',
                              pathname
                            ),
                            visible:
                              (p.company_department == null &&
                                user.role != 'solicitant') ||
                              p.company_department,
                          },
                          {
                            title: translate.SUBDEPARTMENT,
                            body: translate.CONFIG_SUBDEPARTMENT_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/subdepartment'
                              ),
                            active: testRoute(
                              '/dash/configuration/subdepartment',
                              pathname
                            ),
                            visible:
                              (p.company_subdepartment == null &&
                                user.role != 'solicitant') ||
                              p.company_subdepartment,
                          },
                          {
                            title: translate.POSITIONS,
                            body: translate.CONFIG_POSITIONS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/occupation'),
                            active: testRoute(
                              '/dash/configuration/occupation',
                              pathname
                            ),
                            visible:
                              (p.positions_users == null &&
                                user.role != 'solicitant') ||
                              p.positions_users,
                          },
                          {
                            title: translate.LDAP,
                            body: translate.CONFIG_LDAP_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/ldap'),
                            active: testRoute(
                              '/dash/configuration/ldap',
                              pathname
                            ),
                            visible:
                              (p.company_ldpa == null &&
                                user.role != 'solicitant') ||
                              p.company_ldpa,
                          },
                          {
                            title: translate.OPENING_HOURS,
                            body: translate.CONFIG_OPENING_HOURS_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/companytime'
                              ),
                            active: testRoute(
                              '/dash/configuration/companytime',
                              pathname
                            ),
                            visible:
                              (p.company_company_hour == null &&
                                user.role != 'solicitant') ||
                              p.company_company_hour,
                          },
                          {
                            title: translate.MAIL_POLICY,
                            body: translate.CONFIG_MAIL_POLICY_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/email'),
                            active: testRoute(
                              '/dash/configuration/email',
                              pathname
                            ),
                            visible:
                              (p.company_mail_policy == null &&
                                user.role != 'solicitant') ||
                              p.company_mail_policy,
                          },
                          {
                            title: translate.COMPANY_LOGO,
                            body: translate.CONFIG_COMPANY_LOGO_BODY,
                            onClick: () => {
                              this.setState({ logo: true });
                            },
                            active: this.state.logo,
                            visible:
                              (p.company_company_logo == null &&
                                user.role != 'solicitant') ||
                              p.company_company_logo,
                          },
                          {
                            title: translate.ALTERNATE_BOSS,
                            body: translate.CONFIG_ALTERNATE_BOSS_BODY,
                            visible:
                              (p.chief_alt == null &&
                                user.role != 'solicitant') ||
                              p.chief_alt,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/cheifAlternative'
                              ),
                            active: testRoute(
                              '/dash/configuration/cheifAlternative',
                              pathname
                            ),
                          },
                          {
                            title: translate.LOCATION,
                            body: translate.CONFIG_LOCATION_BODY,
                            visible:
                            (p.location == null &&
                              user.role != 'solicitant') ||
                            p.location,
                              onClick: () =>
                              this.handlePage('/dash/configuration/location'),
                            active: testRoute(
                              '/dash/configuration/location',
                              pathname
                            ),
                          },
                          {
                            title: 'Squads',
                            body: translate.CONFIG_SQUAD_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/squad'),
                            active: testRoute(
                              '/dash/configuration/squad',
                              pathname
                            ),
                          },
                        ],
                      },
                      // ------------------------------------GESTÃO DE CONTRATOS E FORNECEDORES-------------------------------------------------------

                      {
                        title: translate.DASHBOARD_PAGE.GCF,
                        icon: <i className="fas fa-file-signature" />,
                        visible: user.role != 'solicitant' &&
                          (
                            (
                              (p.gcf_contract == null &&
                                user.role != 'solicitant') ||
                              p.gcf_contract
                            ) ||
                            (
                              (p.gcf_sla_policy == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy
                            )
                          ),
                        items: [
                          {
                            title: translate.CONTRACT,
                            body: translate.CONFIG_CONTRACT_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/contracts'),
                            active: testRoute(
                              '/dash/configuration/contracts',
                              pathname
                            ),
                            visible:
                              (p.gcf_contract == null &&
                                user.role != 'solicitant') ||
                              p.gcf_contract,
                          },
                          {
                            title: translate.SLA_POLICY,
                            body: translate.CONFIG_SLA_POLICY_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/sla'),
                            active: testRoute(
                              '/dash/configuration/sla',
                              pathname
                            ),
                            visible:
                              (p.gcf_sla_policy == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy,
                          },
                        ],
                      },
                      // ------------------------------------GESTÃO DE DISPONIBILIDADE-------------------------------------------------------
                      {
                        title: translate.AVAILABILITY_MANAGEMENT,
                        icon: <DevicesIcon />,
                        visible: user.role != 'solicitant' &&
                          (
                            (
                              (p.gi_device == null &&
                                user.role != 'solicitant') ||
                              p.gi_device
                            ) ||
                            (
                              (p.gi_snmp == null &&
                                user.role != 'solicitant') ||
                              p.gi_snmp
                            ) ||
                            (
                              (p.gi_device_type == null &&
                                user.role != 'solicitant') ||
                              p.gi_device_type
                            ) ||
                            (
                              (p.ic_relationship == null &&
                                user.role != 'solicitant') ||
                              p.ic_relationship
                            )
                          ),
                        items: [
                          {
                            title: translate.DEVICE,
                            body: translate.CONFIG_DEVICE_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/device'),
                            active: testRoute(
                              '/dash/configuration/device',
                              pathname
                            ),
                            visible:
                              (p.gi_device == null &&
                                user.role != 'solicitant') ||
                              p.gi_device,
                          },
                          {
                            title: translate.SNMP,
                            body: translate.CONFIG_SNMP_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/snmp'),
                            active: testRoute(
                              '/dash/configuration/snmp',
                              pathname
                            ),
                            visible:
                              (p.gi_snmp == null &&
                                user.role != 'solicitant') ||
                              p.gi_snmp,
                          },
                          {
                            title: translate.DEVICE_TYPE,
                            body: translate.CONFIG_DEVICE_TYPE_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/devicetype'),
                            active: testRoute(
                              '/dash/configuration/devicetype',
                              pathname
                            ),
                            visible:
                              (p.gi_device_type == null &&
                                user.role != 'solicitant') ||
                              p.gi_device_type,
                          },
                          {
                            title: `${language.RELATIONSHIP} IC`,
                            body: `${language.CREATE_EDIT_AND_OR_EXCLUDE_RELATIONSHIP_TYPES} IC`,
                            onClick: () =>
                              this.handlePage('/dash/configuration/deviceIC'),
                            active: testRoute(
                              '/dash/configuration/deviceIC',
                              pathname
                            ),
                            visible:
                              (p.ic_relationship == null &&
                                user.role != 'solicitant') ||
                              p.ic_relationship,
                          },
                        ],
                      },
                      {
                        title: language.SLA_MANAGEMENT,
                        icon: <DevicesIcon />,
                        visible: user.role != 'solicitant' &&
                          (
                            (
                              general_params.sla_params == true &&
                              ((p.gsd_workday == null &&
                                user.role != 'solicitant') ||
                                p.gsd_workday)
                            ) ||
                            (
                              (p.gcf_sla_policy_allowance == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy_allowance
                            ) ||
                            (
                              general_params.sla_params == true &&
                              ((p.gsd_slaparam == null &&
                                user.role != 'solicitant') ||
                                p.gsd_slaparam)
                            ) ||
                            (
                              (p.gcf_sla_policy_allowance == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy_allowance
                            ) ||
                            (
                              (p.gcf_sla_policy_allowance == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy_allowance
                            )
                          ),
                        items: [
                          {
                            title: translate.SLA_WORKDAY,
                            body: translate.CONFIG_SLA_WORKDAY_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/slawork'),
                            active: testRoute(
                              '/dash/configuration/slacalendar',
                              pathname
                            ),
                            visible:
                              general_params.sla_params == true &&
                              ((p.gsd_workday == null &&
                                user.role != 'solicitant') ||
                                p.gsd_workday),
                          },
                          {
                            title: translate.SLA_HOLIDAY,
                            body: translate.REGISTER_HOLIDAYS,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/slaholidays'
                              ),
                            active: testRoute(
                              '/dash/configuration/slaholidays',
                              pathname
                            ),
                            visible:
                              (p.gcf_sla_policy_allowance == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy_allowance,
                          },
                          {
                            title: translate.SLA_PARAMS,
                            body: translate.CONFIG_SLA_PARAMS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/slaconfig'),
                            active: testRoute(
                              '/dash/configuration/slaconfig',
                              pathname
                            ),
                            visible:
                              general_params.sla_params == true &&
                              ((p.gsd_slaparam == null &&
                                user.role != 'solicitant') ||
                                p.gsd_slaparam),
                          },
                          {
                            title: translate.MANAGEMENT_ALLOWANCE,
                            body: translate.LIST_ALLOWANCE,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/ticketallawanse'
                              ),
                            active: testRoute(
                              '/dash/configuration/ticketallawanse',
                              pathname
                            ),
                            visible:
                              (p.gcf_sla_policy_allowance == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy_allowance,
                          },
                          {
                            title: translate.STATUS_TICKET_ALLOWANCE,
                            body: translate.REGISTER_STATUS_ALLOWANCE,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/statusticketallawanse'
                              ),
                            active: testRoute(
                              '/dash/configuration/statusticketallawanse',
                              pathname
                            ),
                            visible:
                              (p.gcf_sla_policy_allowance == null &&
                                user.role != 'solicitant') ||
                              p.gcf_sla_policy_allowance,
                          },
                          {
                            title: translate.SLA_REPROCESSING,
                            body: translate.SLA_REPROCESSING_DESCRIPTION,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/slareprocessing'
                              ),
                            active: testRoute(
                              '/dash/configuration/slareprocessing',
                              pathname
                            ),
                            visible:
                              user.id === 1 &&
                              user.email === "admin@sensrit.com.br" &&
                              (
                                (p.notifications_center == null &&
                                    user.role != 'solicitant') ||
                                (p.notifications_center)
                              ),
                          },
                        ],
                      },
                      // ------------------------------------------GESTÃO DE DEMANDA-------------------------------------------------------------
                      // {
                      //   title: 'Gestão de Demanda',
                      //   icon: <ContactPhoneIcon />,
                      //   visible: user.role != 'solicitant',
                      //   items: [
                      //     {
                      //       title: 'Automação',
                      //       body: 'Automação das demandas',
                      //       onClick: () =>
                      //         this.handlePage('/dash/configuration/automation'),
                      //       active: testRoute(
                      //         '/dash/configuration/automation',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.automation == null &&
                      //           user.role != 'solicitant') ||
                      //         p.automation,
                      //     },
                      //     {
                      //       title: 'Categoria de terceiro nível',
                      //       body: 'Cadastre todas as atividades para atendimento',
                      //       onClick: () =>
                      //         this.handlePage('/dash/configuration/task'),
                      //       active: testRoute(
                      //         '/dash/configuration/task',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.gsd_task == null &&
                      //           user.role != 'solicitant') ||
                      //         p.gsd_task,
                      //     },
                      //     {
                      //       title: 'Subcategorias',
                      //       body: 'Organize em grupos as atividades para atendimento',
                      //       onClick: () =>
                      //         this.handlePage(
                      //           '/dash/configuration/subcategories'
                      //         ),
                      //       active: testRoute(
                      //         '/dash/configuration/subcategories',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.gsd_service_catalog == null &&
                      //           user.role != 'solicitant') ||
                      //         p.gsd_service_catalog,
                      //     },
                      //     {
                      //       title: 'Categorias',
                      //       body: 'Direcione seu catálogo de serviço',
                      //       onClick: () =>
                      //         this.handlePage('/dash/configuration/categories'),
                      //       active: testRoute(
                      //         '/dash/configuration/categories',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.gsd_category == null &&
                      //           user.role != 'solicitant') ||
                      //         p.gsd_category,
                      //     },
                      //     {
                      //       title: 'Grupos',
                      //       body: 'Crie o(s) grupo(s) de atendimento para sua empresa',
                      //       onClick: () =>
                      //         this.handlePage('/dash/configuration/techgroup'),
                      //       active: testRoute(
                      //         '/dash/configuration/techgroup',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.gsd_requester == null &&
                      //           user.role != 'solicitant') ||
                      //         p.gsd_requester,
                      //     },
                      //     {
                      //       title: 'Formulários',
                      //       body: 'Crie formulários customizados para atender o ser negócio',
                      //       onClick: () =>
                      //         this.handlePage('/dash/configuration/template'),
                      //       active: testRoute(
                      //         '/dash/configuration/template',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.gsd_template == null &&
                      //           user.role != 'solicitant') ||
                      //         p.gsd_template,
                      //     },
                      //     {
                      //       title: 'Resposta',
                      //       body: 'Crie respostas automáticas e facilite o service desk',
                      //       onClick: () =>
                      //         this.handlePage('/dash/configuration/answer'),
                      //       active: testRoute(
                      //         '/dash/configuration/answer',
                      //         pathname
                      //       ),
                      //       visible:
                      //         (p.gsd_answer == null &&
                      //           user.role != 'solicitant') ||
                      //         p.gsd_answer,
                      //     },
                      //   ],
                      // },
                      // ------------------------------------------GESTÃO DE DEMANDA-------------------------------------------------------------
                      {
                        title: translate.DASHBOARD_PAGE.DM,
                        icon: <ContactPhoneIcon />,
                        visible:
                          user.role != 'solicitant' &&
                          (
                            (
                              (p.automation == null &&
                                user.role != 'solicitant') ||
                              p.automation
                            ) ||
                            (
                              (p.gsd_task == null &&
                                user.role != 'solicitant') ||
                              p.gsd_task
                            ) ||
                            (
                              (p.gsd_service_catalog == null &&
                                user.role != 'solicitant') ||
                              p.gsd_service_catalog
                            ) ||
                            (
                              (p.gsd_category == null &&
                                user.role != 'solicitant') ||
                              p.gsd_category
                            ) ||
                            (
                              (p.gsd_requester == null &&
                                user.role != 'solicitant') ||
                              p.gsd_requester
                            ) ||
                            (
                              (p.gsd_template == null &&
                                user.role != 'solicitant') ||
                              p.gsd_template
                            ) ||
                            (
                              (p.change_management_workflow == null &&
                                user.role != 'solicitant') ||
                              (p.change_management_workflow)
                            ) ||
                            (
                              (p.change_management_group == null && p.gsd_is_change_manager == null &&
                                user.role != 'solicitant') ||
                              (p.change_management_group && p.gsd_is_change_manager)
                            ) ||
                            (
                              (p.change_template == null && p.gsd_is_change_manager == null &&
                                user.role != 'solicitant') ||
                              (p.change_template && p.gsd_is_change_manager)
                            ) ||
                            (
                              (p.risk_change == null && p.gsd_changerisk == null &&
                                user.role != 'solicitant') ||
                              (p.risk_change && p.gsd_changerisk)
                            ) ||
                            (
                              (p.cab_events == null &&
                                user.role != 'solicitant') ||
                              p.cab_events
                            ) ||
                            (
                              (p.gsd_answer == null &&
                                user.role != 'solicitant') ||
                              p.gsd_answer
                            ) ||
                            (
                              (p.gsd_answer == null &&
                                user.role != 'solicitant') ||
                              p.gsd_answer
                            ) ||
                            (
                              (p.outage == null && user.role != 'solicitant') ||
                              p.outage
                            ) ||
                            (
                              (p.approvals == null &&
                                user.role != 'solicitant') ||
                              p.approvals
                            ) ||
                            (
                              general_params.use_resolution_category == true &&
                              ((p.resolution_category == null &&
                                user.role != 'solicitant') ||
                                p.resolution_category)
                            ) ||
                            (
                              general_params.use_resolution_category == true &&
                              ((p.resolution_code == null &&
                                user.role != 'solicitant') ||
                                p.resolution_code)
                            ) ||
                            (
                              (p.channel_management == null &&
                                user.role != 'solicitant') ||
                              p.channel_management
                            )
                          ),
                        items: [
                          {
                            title: translate.AUTOMATION,
                            body: translate.CONFIG_AUTOMATION_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/automation'),
                            active: testRoute(
                              '/dash/configuration/automation',
                              pathname
                            ),
                            visible:
                              (p.automation == null &&
                                user.role != 'solicitant') ||
                              p.automation,
                          },
                          {
                            title: translate.THIRD_LEVEL_CATEGORY,
                            body: translate.CONFIG_THIRD_LEVEL_CATEGORY_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/task'),
                            active: testRoute(
                              '/dash/configuration/task',
                              pathname
                            ),
                            visible:
                              (p.gsd_task == null &&
                                user.role != 'solicitant') ||
                              p.gsd_task,
                          },
                          {
                            title: translate.SUBCATEGORIES,
                            body: translate.CONFIG_SUBCATEGORIES_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/subcategories'
                              ),
                            active: testRoute(
                              '/dash/configuration/subcategories',
                              pathname
                            ),
                            visible:
                              (p.gsd_service_catalog == null &&
                                user.role != 'solicitant') ||
                              p.gsd_service_catalog,
                          },
                          {
                            title: translate.CATEGORIES,
                            body: translate.CONFIG_CATEGORIES_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/categories'),
                            active: testRoute(
                              '/dash/configuration/categories',
                              pathname
                            ),
                            visible:
                              (p.gsd_category == null &&
                                user.role != 'solicitant') ||
                              p.gsd_category,
                          },
                          {
                            title: translate.GROUPS,
                            body: translate.CONFIG_GROUPS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/techgroup'),
                            active: testRoute(
                              '/dash/configuration/techgroup',
                              pathname
                            ),
                            visible:
                              (p.gsd_requester == null &&
                                user.role != 'solicitant') ||
                              p.gsd_requester,
                          },
                          {
                            title: translate.FORMS,
                            body: translate.CONFIG_FORMS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/template'),
                            active: testRoute(
                              '/dash/configuration/template',
                              pathname
                            ),
                            visible:
                              (p.gsd_template == null &&
                                user.role != 'solicitant') ||
                              p.gsd_template,
                          },
                          {
                            title: translate.GMUD_FLOW,
                            body: language.GMUD_FLOW_INFOS,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/changemanagementworkflow'
                              ),
                            visible:
                              (p.change_management_workflow == null &&
                                user.role != 'solicitant') ||
                              (p.change_management_workflow),
                            active: testRoute(
                              '/dash/configuration/changemanagementworkflow',
                              pathname
                            ),
                          },
                          {
                            title: translate.GROUP_GMUD,
                            body: language.MANAGE_CHANGE_GROUPS,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/changemanagementgroup'
                              ),
                            visible:
                              (p.change_management_group == null && p.gsd_is_change_manager == null &&
                                user.role != 'solicitant') ||
                              (p.change_management_group && p.gsd_is_change_manager),
                            active: testRoute(
                              '/dash/configuration/changemanagementgroup',
                              pathname
                            ),
                          },
                          {
                            title: translate.CHANGE_TEMPLATE,
                            body: translate.CONFIG_CHANGE_TEMPLATE_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/changemanagement'
                              ),
                            visible:
                              (p.change_template == null && p.gsd_is_change_manager == null &&
                                user.role != 'solicitant') ||
                              (p.change_template && p.gsd_is_change_manager),
                            active: testRoute(
                              '/dash/configuration/changemanagement',
                              pathname
                            ),
                          },
                          {
                            title: translate.WEIGHT_CAB,
                            body: translate.CONFIG_RISK_CHANGE_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/changecab'),
                            active: testRoute(
                              '/dash/configuration/changecab',
                              pathname
                            ),
                            visible:
                              (p.risk_change == null && p.gsd_changerisk == null &&
                                user.role != 'solicitant') ||
                              (p.risk_change && p.gsd_changerisk),
                          },
                          {
                            title: translate.RISK_CHANGE,
                            body: translate.CONFIG_RISK_CHANGE_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/changerisk'),
                            active: testRoute(
                              '/dash/configuration/changerisk',
                              pathname
                            ),
                            visible:
                              (p.risk_change == null && p.gsd_changerisk == null &&
                                user.role != 'solicitant') ||
                              (p.risk_change && p.gsd_changerisk),
                          },
                          {
                            title: translate.CAB_EVENTS,
                            body: translate.CONFIG_CAB_EVENTS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/eventcab'),
                            active: testRoute(
                              '/dash/configuration/eventcab',
                              pathname
                            ),
                            visible:
                              (p.cab_events == null &&
                                user.role != 'solicitant') ||
                              p.cab_events,
                          },
                          {
                            title: translate.ANSWERS,
                            body: translate.CONFIG_ANSWERS_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/answer'),
                            active: testRoute(
                              '/dash/configuration/answer',
                              pathname
                            ),
                            visible:
                              (p.gsd_answer == null &&
                                user.role != 'solicitant') ||
                              p.gsd_answer,
                          },
                          {
                            title: translate.PROGRESS_STATUS,
                            body: translate.CONFIG_PROGRESS_STATUS_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/statusawaiting'
                              ),
                            active: testRoute(
                              '/dash/configuration/statusawaiting',
                              pathname
                            ),
                            visible:
                              (p.gsd_answer == null &&
                                user.role != 'solicitant') ||
                              p.gsd_answer,
                          },
                          // {
                          //     title: 'Cadastro de avaliação de chamados',
                          //     body: 'Crie suas avaliações para o chamado',
                          //     onClick: () => this.handlePage('/dash/configuration/techEvaluationRegistration'),
                          //     active: testRoute('/dash/configuration/techEvaluationRegistration', pathname),
                          //     visible: ((p.gsd_answer == null && user.role != 'solicitant'  && general_params.technical_evaluation_registration == true) || p.gsd_answer)
                          // },
                          {
                            title: translate.OUTAGE,
                            body: translate.CONFIG_OUTAGE_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/outagerecurrence'
                              ),
                            active: testRoute(
                              '/dash/configuration/outagerecurrence',
                              pathname
                            ),
                            visible:
                              (p.outage == null && user.role != 'solicitant') ||
                              p.outage,
                          },
                          {
                            title: translate.APPROVALS,
                            body: translate.HISTORIC_APPROVAL_CENTER,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/approvalshistoric'
                              ),
                            active: testRoute(
                              '/dash/configuration/approvalshistoric',
                              pathname
                            ),
                            visible:
                              (p.approvals == null &&
                                user.role != 'solicitant') ||
                              p.approvals,
                          },
                          {
                            title: translate.RESOLUTION_CATEGORY,
                            body: translate.CONFIGURE_RESOLUTION_CATEGORY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/resolutioncategory'
                              ),
                            active: testRoute(
                              '/dash/configuration/resolutioncategory',
                              pathname
                            ),
                            visible:
                              general_params.use_resolution_category == true &&
                              ((p.resolution_category == null &&
                                user.role != 'solicitant') ||
                                p.resolution_category),
                          },
                          {
                            title: translate.RESOLUTION_CODE,
                            body: translate.CONFIGURE_RESOLUTION_CODE,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/resolutioncode'
                              ),
                            active: testRoute(
                              '/dash/configuration/resolutioncode',
                              pathname
                            ),
                            visible:
                              general_params.use_resolution_category == true &&
                              ((p.resolution_code == null &&
                                user.role != 'solicitant') ||
                                p.resolution_code),
                          },
                          {
                            title: translate.CHANNELS,
                            body: translate.CONFIG_CHANNELS_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/channel-management'
                              ),
                            active: testRoute(
                              '/dash/configuration/channel-management',
                              pathname
                            ),
                            visible:
                              (p.channel_management == null &&
                                user.role != 'solicitant') ||
                              p.channel_management,
                          },
                        ],
                      },
                      // ------------------------------------------GESTÃO DE PROJETOS-------------------------------------------------------------
                      {
                        title: translate.PROJECT_MANAGEMENT,
                        icon: <ContactPhoneIcon />,
                        visible: user.role != 'solicitant' &&
                          (
                            (
                              p.gp_template == null || p.gp_template
                            ) ||
                            (
                              (p.user_create == null &&
                                user.role != 'solicitant') ||
                              p.sprint_activity_effort
                            ) ||
                            (
                              (p.coin_management === null &&
                                user.role !== 'solicitant') ||
                              p.coin_management
                            )
                          ),
                        items: [
                          {
                            title: translate.PROJECT_TEMPLATE,
                            body: translate.CONFIG_PROJECT_TEMPLATE_BODY,
                            onClick: () =>
                              this.handlePage('/dash/configuration/project'),
                            active: testRoute(
                              '/dash/configuration/project',
                              pathname
                            ),
                            visible: p.gp_template == null || p.gp_template,
                          },
                          {
                            title: translate.SPRINT_ACTIVITY_EFFORT,
                            body: translate.CONFIG_SPRINT_ACTIVITY_EFFORT_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/effortvalue'
                              ),
                            active: testRoute(
                              '/dash/configuration/effortvalue',
                              pathname
                            ),
                            visible:
                              (p.user_create == null &&
                                user.role != 'solicitant') ||
                              p.sprint_activity_effort,
                          },
                          {
                            title: translate.COIN_MANAGEMENT,
                            body: translate.CONFIG_COIN_MANAGEMENT_BODY,
                            onClick: () =>
                              this.handlePage(
                                '/dash/configuration/coin-management'
                              ),
                            active: testRoute(
                              '/dash/configuration/coin-management',
                              pathname
                            ),
                            visible:
                              (p.coin_management === null &&
                                user.role !== 'solicitant') ||
                              p.coin_management,
                          },
                        ],
                      },
                      // ---------------------AUDITORIA-------------------------------
                      {
                        title: translate.AUDIT,
                        icon: <LibraryBooksIcon />,
                        visible: user.role != 'solicitant' &&
                          (
                            (
                              p.gp_template == null || p.gp_template
                            ) ||
                            (
                              (p.user_create == null &&
                                user.role != 'solicitant') ||
                              p.sprint_activity_effort
                            ) ||
                            (
                              (p.coin_management === null &&
                                user.role !== 'solicitant') ||
                              p.coin_management
                            )
                          ),
                        items: [
                          {
                            title: translate.USERS,
                            body: translate.AUDIT_USERS_MANAGEMENT_BODY,
                            onClick: () =>
                              this.handlePage('/dash/audit/users'),
                            active: testRoute('/dash/audit/users', pathname),
                          },
                          {
                            title: translate.COMPANY,
                            body: translate.AUDIT_COMPANY_MANAGEMENT_BODY,
                            onClick: () =>
                              this.handlePage('/dash/audit/company'),
                            active: testRoute('/dash/audit/company', pathname),
                          },
                          /*{
                            title: translate.CATALOG,
                            body: translate.AUDIT_CATALOG_MANAGEMENT_BODY,
                            onClick: () =>
                              this.handlePage('/dash/audit/catalog'),
                            active: testRoute('/dash/audit/catalog', pathname),
                          },
                          {
                            title: translate.GMUD,
                            body: translate.AUDIT_FLOW_APPROBATION_GMUD,
                            onClick: () =>
                              this.handlePage('/dash/audit/flow-approve-gmud'),
                            active: testRoute('/dash/audit/flow-approve-gmud', pathname),
                          },
                          {
                            title: translate.FORMS,
                            body: translate.AUDIT_FORM,
                            onClick: () =>
                              this.handlePage('/dash/audit/form'),
                            active: testRoute('/dash/audit/form', pathname),
                          },*/
                        ],
                      },

                      // ---------------------SAC CONFIGURATIONS-------------------------------

                      //---------------------END SAC---------------------------------------------------
                    ]
                      .filter((e) => [true, undefined].includes(e.visible))
                      .map((e, i) => (
                        <ConfigurationGroupOption
                          key={i}
                          title={e.title}
                          icon={e.icon}
                          items={e.items}
                        />
                      ))}
                  </PerfectScrollbar>
                  {/*########################-PÁGINAS-##############################################################################*/}
                  {testRoute('/dash/configuration', pathname) && (
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: '#2b9cac',
                        flexGrow: 1,
                        height: '100%',
                      }}
                    >
                      <img
                        id="configurationApresentation"
                        src={sessionStorage.getItem('sensr_lang') == 'PT_BR' ? configurationApresentationPT : sessionStorage.getItem('sensr_lang') == 'ES' ? configurationApresentationES : configurationApresentationEN}
                      />
                    </div>
                  )}
                  {!testRoute('/dash/configuration', pathname) && (
                    <PerfectScrollbar
                      className="scrollVisible content-ConfigurationGroupOption content pt-1"
                      style={{
                        maxHeight: '100%',
                        flexGrow: 1,
                      }}
                      containerRef={(ref) => {
                        this.ScrollRef = ref;
                      }}
                      option={{
                        scrollXMarginOffset: 4,
                        suppressScrollX: true,
                      }}
                    >
                      {/*---------------------------------------CONFIGURAÇÕES GERAIS---------------------------------------------------*/}
                      {testRoute('/dash/configuration/perfil', pathname) && (
                        <PerfilContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/outagerecurrence',
                        pathname
                      ) && (
                        <OutageContainer
                          action={this.action}
                          APIEndpoints={this.APIEndpoints}
                        ></OutageContainer>
                      )}
                      {testRoute(
                        '/dash/configuration/approvalshistoric',
                        pathname
                      ) && <ApprovalCenterHistoric single={false} />}

                      {testRoute('/dash/configuration/users', pathname) && (
                        <UserContainer type_config="/dash/generaluser" />
                      )}
                      {testRoute('/dash/configuration/userspermissions', pathname) && (
                        <UserPermissionsContainer type_config="/dash/generaluser" />
                      )}
                      {testRoute(
                        '/dash/configuration/generalparameters',
                        pathname
                      ) && <GeneralParametersContainer />}

                      {testRoute(
                        '/dash/configuration/effortvalue',
                        pathname
                      ) && <EffortContainer />}
                      {testRoute(
                        '/dash/configuration/extensionsfile',
                        pathname
                      ) && <ExtensionsContainer />}
                      {testRoute(
                        '/dash/configuration/centralhelp',
                        pathname
                      ) && <HelpContainer />}

                      {testRoute(
                        '/dash/configuration/resolutioncategory',
                        pathname
                      ) && <ResolutionCategoryContainer />}
                      {testRoute(
                        '/dash/configuration/resolutioncode',
                        pathname
                      ) && <ResolutionCodeContainer />}
                      {testRoute(
                        '/dash/configuration/passwordsafe',
                        pathname
                      ) && <PasswordContainer />}
                      {testRoute('/dash/configuration/passwordquestions', pathname) && (
                        <PasswordQuestionContainer />
                      )}

                      {/*-----------------CENTRAL DE NOTIFICAÇÕES------------------------------------------*/}
                      {testRoute('/dash/configuration/notificationcenter', pathname) && (
                        <NotificationCenter />
                      )}
                      {testRoute('/dash/configuration/notificationcentertags', pathname) && (
                        <NotificationCenterTags />
                      )}

                      {/*-----------------EMPRESAS---------------------------------------------------------*/}
                      {testRoute('/dash/configuration/company', pathname) && (
                        <CompanyContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/department',
                        pathname
                      ) && <DepartmentContainer type_config="department" />}
                      {testRoute(
                        '/dash/configuration/subdepartment',
                        pathname
                      ) && <DepartmentContainer type_config="subdepartment" />}
                      {testRoute(
                        '/dash/configuration/occupation',
                        pathname
                      ) && <OccupationContainer type_config="occupation" />}
                      {testRoute('/dash/configuration/ldap', pathname) && (
                        <Ad />
                      )}
                      {testRoute(
                        '/dash/configuration/companytime',
                        pathname
                      ) && <CompanyTimeContainer />}
                      {testRoute('/dash/configuration/email', pathname) && (
                        <MailContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/cheifAlternative',
                        pathname
                      ) && <ChiefAlternative />}
                      {testRoute('/dash/configuration/location', pathname) && (
                        <LocationContainer />
                      )}
                      {testRoute('/dash/configuration/squad', pathname) && (
                        <SquadManagementList />
                      )}
                      {/*---------------------------------------------GESTÃO DE CONTRATO---------------------------------------------------------*/}
                      {testRoute('/dash/configuration/contracts', pathname) && (
                        <ContractContainer fromConfiguration sub={true} />
                      )}
                      {testRoute('/dash/configuration/sla', pathname) && (
                        <SlaContainer />
                      )}
                      {/*---------------------------------------------GESTÃO DE DISPONIBILIDADE---------------------------------------------------------*/}
                      {testRoute('/dash/configuration/device', pathname) && (
                        <DeviceContainer />
                      )}
                      {testRoute('/dash/configuration/snmp', pathname) && (
                        <SnmpContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/devicetype',
                        pathname
                      ) && <DeviceType />}
                      {testRoute('/dash/configuration/deviceIC', pathname) && (
                        <DeviceIC />
                      )}
                      {/*---------------------------------------------GESTÃO DE DEMANDA---------------------------------------------------------*/}
                      {testRoute(
                        '/dash/configuration/automation',
                        pathname
                      ) && <AutomationContainer />}
                      {testRoute('/dash/configuration/task', pathname) && (
                        <TaskContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/subcategories',
                        pathname
                      ) && <CatalogContainer />}
                      {testRoute(
                        '/dash/configuration/categories',
                        pathname
                      ) && <CategoryContainer />}
                      {testRoute('/dash/configuration/techgroup', pathname) && (
                        <GroupContainer />
                      )}
                      {testRoute('/dash/configuration/template', pathname) && (
                        <FormBuilderContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/changecab',
                        pathname
                      ) && <ChangeCabContainer store={this.store} action={this.action} APIEndpoints={this.APIEndpoints} />}
                      {testRoute(
                        '/dash/configuration/changerisk',
                        pathname
                      ) && <ChangeRisk store={this.store} action={this.action} APIEndpoints={this.APIEndpoints} />}
                      {testRoute('/dash/configuration/eventcab', pathname) && (
                        <EventCab />
                      )}
                      {testRoute('/dash/configuration/answer', pathname) && (
                        <AnswerContainer />
                      )}
                      {testRoute(
                        '/dash/configuration/statusawaiting',
                        pathname
                      ) && <StatusAwatingContainer />}
                      {testRoute(
                        '/dash/configuration/techEvaluationRegistration',
                        pathname
                      ) && <TechEvaluationRegistrationContainer />}
                      {testRoute('/dash/configuration/slaconfig', pathname) && (
                        <SlaConfig />
                      )}{testRoute('/dash/configuration/slareprocessing', pathname) && (
                        <SlaReprocessing />
                      )}
                      {testRoute('/dash/configuration/slawork', pathname) && (
                        <SlaWork />
                      )}
                      {testRoute(
                        '/dash/configuration/ticketallawanse',
                        pathname
                      ) && <Allowance />}
                      {testRoute(
                        '/dash/configuration/statusticketallawanse',
                        pathname
                      ) && <SlaStatusAllowance />}
                      {testRoute(
                        '/dash/configuration/slaholidays',
                        pathname
                      ) && <CompanyHoliday />}
                      {testRoute(
                        '/dash/configuration/changemanagement',
                        pathname
                      ) && <ChangeManagementContainer />}

                      {testRoute(
                        '/dash/configuration/changemanagementworkflow',
                        pathname
                      ) && <ApprovationWorkflow />}

                      {testRoute(
                        '/dash/configuration/changemanagementgroup',
                        pathname
                      ) && <GroupChangeConfigurationContainer />}

                      {testRoute('/dash/configuration/project', pathname) ||
                      testRoute('/dash/configuration/list/detail', pathname) ? (
                        <Project />
                      ) : null}

                      {testRoute(
                        '/dash/configuration/channel-management',
                        pathname
                      ) && <ChannelManagement />}
                      {testRoute(
                        '/dash/configuration/coin-management',
                        pathname
                      ) && <CoinManagementList />}
                      {testRoute(
                        '/dash/audit/users',
                        pathname
                      ) && <AuditConfigContainer />}
                      {testRoute(
                        '/dash/audit/company',
                        pathname
                      ) && <AuditConfigContainer />}
                      {testRoute(
                        '/dash/audit/catalog',
                        pathname
                      ) && <AuditConfigContainer />}
                      {testRoute(
                        '/dash/audit/flow-approve-gmud',
                        pathname
                      ) && <AuditConfigContainer />}
                      {testRoute(
                        '/dash/audit/form',
                        pathname
                      ) && <AuditConfigContainer />}
                    </PerfectScrollbar>
                  )}
                </div>
              </Card>
            </div>
            <Logo
              action={this.action}
              open={this.state.logo}
              state={this.state}
              APIEndpoints={this.APIEndpoints}
              language={language}
              onClose={() => {
                this.setState({ logo: false });
              }}
            />
          </PageLayout>
        </Animate>
      </CommonPage>
    );
  }
}
