import { Checkbox, Chip } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import CommonTable from '../../common/CommonTable';

export default function CompanyCabRisk({
  company,
  store,
  action,
  APIEndpoints,
}) {
  const [dataTable, setDataTable] = useState([]);

  let { toggleAlert, language } = store;

  useEffect(() => {
    onLoadTable();
  }, []);

  function onLoadTable() {
    store.spinner = true;
    action
      .execute(
        'get',
        APIEndpoints.CHANGE_CAB_RISK +
          `/all/relationship-to-company/${company.id_company}`,
        '',
      )
      .then((response1) => {
        //
        const selectedQuestions = response1.data;

        action
          .execute('get', APIEndpoints.CHANGE_CAB_RISK + '/all?type=custom', '')
          .then((response2) => {
            //
            const unselectedQuestions = response2.data;

            let newDataTable = [];

            for (let question of unselectedQuestions) {
              const foundQuestion = selectedQuestions.filter(
                (item) => item.ccr_id === question.ccr_id,
              );

              newDataTable.push({
                ...question,
                selected: foundQuestion.length > 0 ? true : false,
              });
            }

            newDataTable.forEach((item, i) => {
              if (item.selected === true) {
                newDataTable.splice(i, 1);
                newDataTable.unshift(item);
              }
            });

            setDataTable(newDataTable);

            store.spinner = false;
          })
          .catch(() => {
            store.spinner = false;
            toggleAlert(true, language.ERROR_REQUEST, 'error');
          });
      })
      .catch(() => {
        store.spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  function onRelationshipCabRiskToCompany(actionMethod, ccr) {
    store.spinner = true;
    const method = actionMethod === 'create' ? 'post' : 'delete';
    action
      .execute(
        method,
        APIEndpoints.CHANGE_CAB_RISK +
          `/${ccr.ccr_id}/relationship-to-company/${company.id_company}`,
        '',
      )
      .then(() => {
        store.spinner = false;
        toggleAlert(true, language.ALERT_UPDATE_SUCCESS, 'success');
        onLoadTable();
      })
      .catch(() => {
        store.spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  const col = [
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'weight',
      label: language.WEIGHT,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'order',
      label: language.ORDER,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'required',
      label: language.REQUIRED,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 10, overflow: 'auto', textAlign: 'center' },
    },
  ];

  const data = dataTable.map((ccr) => ({
    name: ccr.ccr_name,
    weight: ccr.ccr_weight,
    order: ccr.ccr_order,
    required: ccr.ccr_required ? language.YES : language.NOT,
    action: (
      <Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Checkbox
                key={ccr.ccr_id}
                checked={!!ccr.selected}
                onChange={(evt) =>
                  onRelationshipCabRiskToCompany(
                    !!ccr.selected ? 'delete' : 'create',
                    ccr,
                  )
                }
              />
            </div>
          }
        />
      </Fragment>
    ),
  }));

  return (
    <CommonTable
      data={data}
      columns={col}
      language={language}
      searchColumn
      orderColumn
    />
  );
}
