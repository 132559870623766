import React, { Fragment, useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';

let delayTimer;
export default function ({
  classes,
  placeholderProps,
  searchProps,
  searchWidth,
  language,
  _value,
  sh,
  clearSeach,
  _handleValue,
}) {
  let [value, setValue] = useState('');

  function handleValue(val) {
    setValue(val);

    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
      _handleValue(val);
    }, 400);
  }
  useEffect(() => {
    setValue(_value);
  }, [_value]);
  return (
    <div className={classes.root} {...searchProps}>
      <Paper
        className={classes.paper + ' search-paper'}
        style={{ width: searchWidth || 300 }}
        elevation={1}
      >
        <IconButton
          size="small"
          className={classes.iconButton + ' icon-search'}
          aria-label="Menu"
        >
          <SearchIcon />
        </IconButton>

        <InputBase
          style={{
            width: !!value ? '50%' : '100%',
          }}
          className={classes.input}
          placeholder={placeholderProps || language.SEARCH + '...'}
          value={value}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              sh ? sh(ev.target.value) : null;
            }
          }}
          onChange={(evt) => (sh ? null : handleValue(evt.target.value))}
        />

        {!!value && (
          <Fragment>
            <Divider className={classes.divider} />
            <IconButton
              color="primary"
              onClick={clearSeach}
              className={classes.iconButton + ' icon-remove-search'}
              aria-label="Directions"
            >
              {/*<CloseIcon />*/}
              <Icon color="primary" style={{ transform: 'rotate(-45deg)' }}>
                add_circle
              </Icon>
            </IconButton>
          </Fragment>
        )}
      </Paper>
    </div>
  );
}
