import React from 'react';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';
import ServicePortalTicketDetails from './../ServicePortalTicketDetails';

export default function ServicePortalModalApprovalTicketDetails({
  isOpen = false,
  language,
  onClose,
  onConfirm,
  principalId,
  params,
}) {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <>
      <ServicePortalModalWrapper
        maxWidth="xl"
        isOpen={isOpen}
        title={language.APPROVAL_DETAILS}
        onClose={handleClose}
        buttonCloseLabel={language.CLOSE}
        disableConfirmButton={true}
      >
        <div className="tw-w-full tw-border-2 tw-rounded">
          <ServicePortalTicketDetails
            params={params}
            forceTicketId={{ id: principalId }}
          />
        </div>
      </ServicePortalModalWrapper>
    </>
  );
}
