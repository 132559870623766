import React from 'react';
/* ----------------------------------common----------------------------------- */
/* ----------------------------------material-ui----------------------------------- */
import { Card, Grid } from '@material-ui/core';

/* ----------------------------------pages----------------------------------- */
import ItemsWaitingApprovals from './ItemsWaitingApprovals';
import AprovationItem from './AprovationItem';

import { ItemsWaitingApprovals as ItemsWaitingApprovalsJson, AprovationItem as AprovationItemJson } from '../jsonFormat';

export default function ({ requests, redirect, users, action, risks, matrizplanning }) {

   //----as regras de negócio de cada módulo fazer dentro do jsonFormat----
   let itemsWaitingApprovals = ItemsWaitingApprovalsJson({ requests, risks, matrizplanning });
   let aprovationItem = AprovationItemJson({ requests, risks, matrizplanning });


   // approve
   return (
      <Card id='view2' className='content'>
         <Grid container spacing={2}>
            <Grid item xs={6}>
               <ItemsWaitingApprovals matrizplanning={matrizplanning} {...itemsWaitingApprovals} />
            </Grid>
            <Grid item xs={6}>
               <AprovationItem matrizplanning={matrizplanning} action={action} users={users} redirect={redirect} data={aprovationItem} />
            </Grid>
         </Grid>
      </Card>
   )
}