import React, { useEffect, useState } from 'react';
import Dialog from '../../common/Dialog';
import DialogConfirm from '../../common/DialogConfirm';
import ChangeRiskForm from './ChangeRiskForm';
import ChangeRiskList from './ChangeRiskList';
import './index.css';

export default function ChangeRiskContainer({ store, action, APIEndpoints }) {
  const [page, setPage] = useState('list');
  const [selectedItem, setSelectedItem] = useState({});
  const [dataTable, setDataTable] = useState([]);
  
  let { toggleAlert, language } = store;

  useEffect(() => {
    onLoadTable();
  }, []);

  function onLoadTable() {
    store.spinner = true;
    action
      .execute('get', APIEndpoints.CHANGE_CAB_RISK + '/all', '')
      .then((response) => {
        store.spinner = false;
        setDataTable(response.data);
      })
      .catch(() => {
        store.spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  function onDelete(id) {
    store.spinner = true;
    action
      .execute('delete', `${APIEndpoints.CHANGE_CAB_RISK}/${id}`, '')
      .then(() => {
        store.spinner = false;
        toggleAlert(true, language.CAB_ALERT, 'success');
        onLoadTable();
        handlePage('list');
      })
      .catch(() => {
        store.spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
        handlePage('list');
      });
  }

  function handlePage(pageAction, item = {}) {
    setPage(pageAction);
    setSelectedItem(item);
  }

  return (
    <div>
      <ChangeRiskList
        dataTable={dataTable}
        language={language}
        handlePage={handlePage}
      />
      <Dialog
        title={page === 'create' ? language.ADD : language.EDIT}
        open={page === 'create' || page === 'edit'}
        onClose={() => handlePage('list')}
        maxWidth="md"
      >
        <ChangeRiskForm
          values={selectedItem}
          store={store}
          action={action}
          APIEndpoints={APIEndpoints}
          onSuccess={() => {
            handlePage('list');
            onLoadTable();
          }}
        />
      </Dialog>
      <DialogConfirm
        open={page === 'delete'}
        language={language}
        confirmProps={{ color: 'danger' }}
        onConfirm={() => onDelete(selectedItem.ccr_id)}
        onClose={() => handlePage('list')}
        title={language.CONFIRM_DELETE_ITEM}
        message={selectedItem.ccr_name}
      />
    </div>
  );
}
