import React from 'react';
import { Card, Grid } from '@material-ui/core';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import { ProjectDeliveryGraph, ProjectsItemGraph } from './Graphs';
import ReactHighcharts from 'react-highcharts';
import './ProjectDelivery.css';


export default function (props) {
	let { language, projects } = props;

	let total = projects.finalized.length
	let delay = projects.finalized.filter(f => f.delay).length
	let nodelay = projects.finalized.filter(f => !f.delay).length
//console.log("verificar projetos")
//console.log(total)
//console.log(projects.finalized)
//console.log(delay)
//console.log(nodelay)
	return (
		<Card id='ProjectDelivery' className='border-radius-10 mt-3'>
			<AppBar title={language.PROJECTENT} />
			<div className='content-min pt-0'>
				<Card>
					<ReactHighcharts
						config={ProjectDeliveryGraph({
							language, total,  delay ,  nodelay 
						})}
					/>
				</Card>
				<Card className='mt-2 content'>

					<Grid container spacing={2}>
						<Grid item xs={6}>
							<h2 className='satisfation-title'>{language.SAT}</h2>
							{/*<Grid container spacing={2}  className='satisfation-content'>
								{[
									{value:12, label:'Não resolveu o problema'},
									{value:61, label:'Problema resolvido'},
									{value:null, label:'Ver feedbacks dos clientes'},
								].map((e,i)=>(
									<Grid key={i} item xs={4}>
										<div className='satisfation-item content-medium py-2'>
											<h2>
												{i==2?(
													<i className='fas fa-comment-dots'/>
												):e.value}
											</h2>
											<p>{e.label}</p>
										</div>
									</Grid>
								))}
												</Grid> */}
						</Grid>
						<Grid item xs={6} className='satisfation-content-2'>
							<div>
								<ReactHighcharts
									config={ProjectsItemGraph({
										language
									})}
								/>
								<span className='status-satisfation-setor'>Aceitável</span>
							</div>
						</Grid>
					</Grid>
				</Card>
			</div>
		</Card>
	)
}