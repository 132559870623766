import React, { useEffect, useState } from 'react';

import { Tooltip } from 'react-tippy';

function Progress({ color, colorEmpty, value, onChange = () => null }) {
  let [_value, setValue] = useState(value);

  return (
    <div className="d-flex align-items-center" style={{opacity:!!parseInt(_value) ? 1 : .5}}>
      <div className="content-stage-progress">
        {[1, 2, 3, 4, 5].map((e) => (
          <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 15 }}>
                <strong>
                  {e == 1
                    ? 'Muito baixo'
                    : e == 2
                    ? 'Baixo'
                    : e == 3
                    ? 'Médio'
                    : e == 4
                    ? 'Alto'
                    : 'Muito Alto'}
                </strong>
              </div>
            }
            arrow={true}
            position={'right'}
            theme="dark"
          >
            <div
              style={{
                backgroundColor: color,
                cursor: 'pointer',
                textAlign: 'center',
              }}
              className={(e==1?'first':'')+(e==5?'last':'')}
              key={e}
              onClick={() => {
                setValue(e);
                onChange(e);
              }}
            >
              {parseInt(e) === parseInt(value) ? (
                <i
                  className="fas fa-caret-down"
                  style={{
                    fontSize: '26px',
                    marginTop: '-13px',
                    color: 'rgb(115 103 103)',
                    color: '#D9DDE5',
                    transform: 'scaleX(.7)',
                  }}
                />
              ) : null}
            </div>
          </Tooltip>
        ))}
      </div>
      <span style={{ fontSize: 11, color: '#707070' }} className="ml-1">
        {value}
      </span>
    </div>
  );
}

export default React.memo(Progress);
