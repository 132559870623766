import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import classnames from 'classnames';
import Animate from '../Animate';
const Color = ({
  f,
  statusError,
  _value,
  _handleValue,
  errors,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorSelected, setColorSelected] = useState('');
    useEffect(() => {
        if (_value){
            setColorSelected(_value)
        }
    }, [_value])

  return (
    <div
      onMouseEnter={() => setShowColorPicker(true)}
      onMouseLeave={() => setShowColorPicker(false)}
    >
      <span
        className={classnames('font label-text-editor', {
          error: statusError[errors[f.name]],
        })}
      >
        {f.label + (f.required ? ' *' : '')}
      </span>
      <div
        style={{
          width: '50px',
          height: '50px',
          borderRadius: 50,
          backgroundColor: colorSelected ?? 'rgb(1, 159, 194)',
        }}
      ></div>

      {showColorPicker && (
        <Animate anime="zoomIn">
          <SketchPicker
            color={_value}
            name={f.name}
            id={f.name}
            onChange={(evt) => {
              _handleValue(evt.hex, f.name);
              setColorSelected(evt.hex);
            }}
          />
        </Animate>
      )}
    </div>
  );
};

export default Color;
