import React, { useState } from 'react';
import { Card, Grid } from '@material-ui/core';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import { TeamAllocationGraph } from './Graphs';
import ReactHighcharts from 'react-highcharts';
import './TeamAllocation.css';
import moment from 'moment'

let listUsers = [];

export default function (props) {
	let { language, projects, users } = props;

	let usersItem = [];
let [idpg,SetIdpg] = useState(0);
 
       

	const [u, Setu] = useState(0);
	//fk_id_user: 1
	let month = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	 
	if (u == 0) {
		projects.arrayConsumed.map(g => {
			usersItem.push(g.fk_id_user)


			month[moment(g.dt_cad).format('M') - 1] +=
				moment.duration(g.hours_consumed).asSeconds()

		})
	} else {
		projects.arrayConsumed.filter(p => p.fk_id_user == u &&
			(idpg ==0 ? p.fk_id_project > 0 :  p.fk_id_project== idpg)
			).map(g => {
			usersItem.push(g.fk_id_user)
			month[moment(g.dt_cad).format('M') - 1] +=
				moment.duration(g.hours_consumed).asSeconds()

		})
	}



	 




	let mes = month.map(h => parseInt(h / 3600))

	let sum = 0;

	if (u == 0) {
		users.filter(h => usersItem.includes(h.id_user)).map(h => {


			listUsers.push({
				value: h.id_user,
				label: h.name
			});

		})
	}
	mes && mes.map(g => {
		sum = sum + g

	})

	return (
		<Card id='TeamAllocation' className='border-radius-10'>
			<AppBar
				title={language.EQUIPELOCATION}
			>
				<div style={{ width: 350, zoom: .8 }} className='mb-2'>
					<CommonForm
						individual
						fields={[
							
							{
								col: 6,
								name: 'solicitant',
								label: language.TECHS,
								type: 'select',
								options: listUsers
							},
							{
								col: 6,
								name: 'project',
								label: 'Projetos',
								type: 'select',
								options: projects.projetos.filter(b=>
									b.users.includes(u)==true
									).map(v=>({
									 value:v.id_project,
									 label:v.name
								}))
							}
						]}
						onChangeField={(f, v) => {
                           if(f.name === 'project'){
							SetIdpg(v);
						   }else{
							Setu(v);
						   }
							
						}}
					/>
				</div>
			</AppBar>
			<div className='content py-0'>
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<ReactHighcharts
							config={TeamAllocationGraph({
								language, mes
							})}
						/>
					</Grid>
					<Grid item xs={4}>
						<Card className='border-radius-10 TeamAllocation-items content pb-2 mb-3'>
							<h4>{language.AVERAGE}</h4>
							<h4>{parseInt(sum / 12)} h</h4>
						</Card>
						<Card className='border-radius-10 TeamAllocation-items content pb-2'>
							<h4>{language.ACCUMULATED}</h4>
							<h4>{sum} h</h4>
						</Card>
					</Grid>
				</Grid>
			</div>
		</Card>
	)
}