import React, { Fragment, useState } from 'react';
import './index.css';
// -------------------------common----------------------------------------
import CommonPage from '@common/CommonPage';
import SearchInput from '@common/SearchInput';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import { observer } from 'mobx-react';

//----------------------------material-ui--------------------------------------
import { Card } from '@material-ui/core';
//----------------------------pages--------------------------------------
import CP from '../CP';
import Header from './Header';
import Sectors from './Sectors';
import Ranking from './Ranking';
import PageLayout from '@common/PageLayout';
import crypto from 'crypto';
@observer
export default class extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      companiesOptions: [],
      companyId: -1,
      sponsorOptions: [],
      sponsorId: -1,
      status: -1,
    };
  }

  decryp(aMsg) {
    try {
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }

  componentWillMount() {
    this.store.projects = [];
    this.action.execute('get', this.APIEndpoints.PROJECT, 'projects');
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/prioritization',
      'prioritization'
    );

    let sponsors = [];
    this.action
      .execute('get', this.APIEndpoints.CONTRACT + 's/getAllSponsor')
      .then((result) => {
        result.data &&
          result.data.map((sponsor) => {
            sponsors.push({
              value: sponsor.fk_id_sponsor,
              label: sponsor.name,
            });
          });
        this.setState({ sponsorOptions: sponsors });
      });

    let companies = [];
    this.action.execute('get', this.APIEndpoints.COMPANY).then((h) => {
      h.data.map((c) => {
        companies.push({ value: c.id_company, label: c.name });
      });
      this.setState({ companiesOptions: companies });
    });

    //this.setState({companiesOptions : newCompanies})
  }

  search = (evt) => {
    this.setState({ search: evt.target.value });
  };

  render() {
    const { language, prioritization, users = [] } = this.store;
    let { COMMON_PAGE } = this.store.theme;
    let { search } = this.state;
    // window._prioritization = prioritization;
    //window._projects = projects;

    let urgency = {
      1: language.PRIORITIZATION.VERY_SMALL,
      2: language.PRIORITIZATION.SMALL,
      3: language.PRIORITIZATION.MEDIUM,
      4: language.PRIORITIZATION.HIGH,
      5: language.PRIORITIZATION.VERY_HIGH,
    };

    let projects = this.store.projects.map((e) => {
      let _users = users
        .filter((u) => e.users.includes(u.id_user))
        .map((e) => e.name);
      return { ...e, _users };
    });
    if (this.state.companyId != -1) {
      projects = projects.filter(
        (project) => project.fk_id_company == this.state.companyId
      );
    }
    if (this.state.sponsorId != -1) {
      projects = projects.filter(
        (project) => project.fk_id_sponsor == this.state.sponsorId
      );
    }
    if (this.state.status != -1) {
      if (this.state.status == 0) {
        projects = projects.filter((project) => project.percent == '100');
      }
      if (this.state.status == 1) {
        projects = projects.filter((project) => project.percent != '100');
      }
    }

    /*-----------------------------Dados do componente Ranking--------------------------------------------------*/
    console.log(projects);
    let dataRanking = projects
      .filter((e) => {
        return (
          JSON.stringify(e).toLowerCase().indexOf(search.toLowerCase()) != -1
        );
      })
      .map((e) => [
        e.id_project,
        e.real_id,
        e.name,
        e.goal.replace(/(<([^>]+)>)/gi, ''),
        e.scope.replace(/(<([^>]+)>)/gi, ''),
        language.AVERAGE_TIME,
        parseInt(e.cust_project).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        urgency[e.urgency],
        (
          (e.desire_directors +
            e.strategic_alignment +
            e.benefit_amount +
            e.processes_improvement +
            e.financial_feedback) /
          5
        )
          .toString()
          .indexOf('.') == -1
          ? (e.desire_directors +
              e.strategic_alignment +
              e.benefit_amount +
              e.processes_improvement +
              e.financial_feedback) /
              5 +
            '.' +
            (
              (e.generates_costs +
                e.risk_if_not_done +
                e.urgency +
                e.complexity +
                e.involved_areas) /
              5
            ).toFixed(0)
          : (e.desire_directors +
              e.strategic_alignment +
              e.benefit_amount +
              e.processes_improvement +
              e.financial_feedback) /
              5 +
            (
              (e.generates_costs +
                e.risk_if_not_done +
                e.urgency +
                e.complexity +
                e.involved_areas) /
              5
            ).toFixed(0),
      ]);

    return (
      <CommonPage>
        <PageLayout
          title={language.PROJECT_PRIORITIZATION_MAP}
          subTitle={`${language.PROJECT_MANAGEMENT} /`}
          rightElements={[
            <div
              style={{
                zoom: 0.8,
                width: 800,
                marginTop: '1rem',
                marginBottom: '-4rem',
                marginRight: '-0.5rem',
                display: 'inline',
              }}
            >
              <CommonForm
                individual
                fields={[
                  {
                    col: 4,
                    type: 'select',
                    name: 'status',
                    label: language.STATUS,
                    options: [
                      { value: 0, label: language.FINISHED },
                      { value: 1, label: language.NOT_FINISHED },
                      { value: -1, label: language.ALL },
                    ],
                  },
                  {
                    col: 4,
                    type: 'select',
                    name: 'company',
                    label: language.COMPANY,
                    options: this.state.companiesOptions,
                  },

                  {
                    col: 4,
                    type: 'select',
                    name: 'sponsor',
                    label: language.SPONSOR,
                    options: this.state.sponsorOptions,
                  },
                ]}
                onChangeField={(f, v) => {
                  if (f.name == 'company') {
                    this.setState({ companyId: v });
                  } else if (f.name == 'sponsor') {
                    this.setState({ sponsorId: v });
                  } else if (f.name == 'status') {
                    this.setState({ status: v });
                  }

                  // this.setState({ [f.name]: v });
                }}
              />
            </div>,
          ]}
        >
          <div id="PrioritizationHome" style={{ '--bar': COMMON_PAGE.bar }}>
            <Block1
              search={this.search}
              language={language}
              projects={projects.filter(
                (e) =>
                  JSON.stringify(e)
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) != -1
              )}
              prioritization={prioritization}
            />
            <Ranking data={dataRanking} redirect2={this.redirect2} />
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}

function Block1({ language, projects, prioritization, search }) {
  let [open, setOpen] = useState(true);
  return (
    <div className="mb-3">
      <Header
        search={search}
        language={language}
        open={open}
        setOpen={setOpen}
      />
      {open ? (
        <Card className="content" style={{ height: '69vh' }}>
          <Sectors
            projects={projects}
            prioritization={prioritization}
            language={language}
          />
        </Card>
      ) : null}
    </div>
  );
}
