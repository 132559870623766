import React, { Component } from 'react';
import { get } from 'fetch';
import moment from 'moment';
import { observer } from 'mobx-react';
import randomcolor from 'randomcolor';
import { TextField, MenuItem, Paper, Grid, Card } from '@material-ui/core';

import Animate from '../../common/Animate';
import Progress from '../../common/Progress';
import PageLayout from '../../common/PageLayout';
import CommonPage from '../../common/CommonPage';
import CommonForm from '../../common/CommonForm2';

import Block_1 from './Block_1';
import Block_2 from './Block_2';
import Options from './Block_2/options';

import { _foreseen, _realized, _dataPerMonth } from './utils';

@observer
export default class BudgetControlContainer extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      month: 0,
      monthGraph: '',
      company: null,
      currentYear: moment().year(),
      contracts: [],
    };

    this.changeMonth = this.changeMonth.bind(this);
    this.submitMonth = this.submitMonth.bind(this);
    this.submitCust = this.submitCust.bind(this);
    this.submitValue = this.submitValue.bind(this);
    this.submitTypeContract = this.submitTypeContract.bind(this);
    this.updateItens = this.updateItens.bind(this);
    this.getBudget = this.getBudget.bind(this);
  }

  getBudget() {
    this.action.execute('get', this.APIEndpoints.BUGET, 'budgetcontrols');
  }
  submitValue(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.CONTRACT + '/attachvalue',
      'contracts',
      values
    );
    this.setState({ PAGE: 'LIST' });
  }

  submitTypeContract(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.CONTRACT + '/AttachType',
      'contracts',
      values
    );
    this.setState({ PAGE: 'LIST' });
  }

  submitMonth(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.CONTRACT + '/attachmonth',
      'contracts',
      values
    );
  }
  submitCust(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.CONTRACT + '/attachcust',
      'contracts',
      values
    );
    this.setState({ PAGE: 'LIST' });
  }

  updateItens(id, data, valor, tipo) {
    if (tipo == 'cust') {
      let value = { fk_id_contract: id, cust: valor, year: data };
      this.submitCust(value);
    } else if (tipo == 'value') {
      let value = { fk_id_contract: id, value: valor, year: data };
      this.submitValue(value);
    } else if (tipo == 'month') {
      let value = { fk_id_contract: id, cust: valor, period: data };
      this.submitMonth(value);
    } else if (tipo == 'ca') {
      let value = { fk_id_contract: id, type_contract: valor };
      this.submitTypeContract(value);
    }
  }

  changeMonth() {
    setTimeout(() => {
      this.clearall();
    }, 2000);
    this.setState({ month: value });
  }
  clearall() {
    $('input').val(null);
  }
  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.BUGET, 'budgetcontrols');
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
    this.setState({ PAGE: 'LIST' });
    this.action
      .execute('get', this.APIEndpoints.CONTRACT + 'painel', '')
      .then((response) => {
        this.setState({ contracts: response.data });
      });
  }

  filterMonthsData = (e) => {
    let { currentYear } = this.state;
    return e.filter((e) => e.year == currentYear);
  };

  filterBudgetData = (e) => {
    let { currentYear, company } = this.state;
    return e.filter((e) => e.year == currentYear);
  };

  render() {
    let { language, contracts, companies } = this.store;
    let { BUDGETCONTROL } = this.store.theme;
    let { total_acumulated, acumulated } = BUDGETCONTROL;
    let { currentYear, company } = this.state;
    let budgetcontrols = this.store.budgetcontrols.filter((e) => {
      let filter = [];
      //filter.push(moment(e.dt_start).year() <= currentYear && currentYear <= moment(e.dt_end).year())
      if (company) {
        filter.push(e.fk_id_company == company);
      }

      return filter.every(Boolean);
    });
    let ContractBilling = budgetcontrols.map((e) => {
      return {
        name: e.name,
        data: this.filterMonthsData(e.months).reduce(
          (arr, e) => {
            arr[e.month - 1] += e.realized_cost;
            return arr;
          },
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ),
        stack: 'male',
        color: randomcolor(),
      };
    });
    let ContractValue_data = budgetcontrols.reduce(
      (arr, e) => {
        arr[e.type - 1] += this.filterMonthsData(e.months).reduce(
          (n, e) => n + e.realized_cost,
          0
        );
        return arr;
      },
      [0, 0, 0]
    );
    let ContractValue_data_ago = [];
    let totalValue = budgetcontrols.reduce(
      (n, e) =>
        n +
        this.filterMonthsData(e.months).reduce(
          (n, e) => n + e.realized_cost,
          0
        ),
      0
    );
    let totalContracts = budgetcontrols.length;
    let foreseen_vs_realized = budgetcontrols.reduce(
      (obj, e) => {
        obj.foreseen += e.expected_cost;
        obj.realized += this.filterMonthsData(e.months).reduce(
          (n, e) => n + e.realized_cost,
          0
        );
        return obj;
      },
      { foreseen: 0, realized: 0 }
    );
    let capex_vs_opex = budgetcontrols.reduce(
      (obj, e) => {
        obj[e.type_capital_expenditure == 1 ? 'capex' : 'opex'] +=
          this.filterMonthsData(e.months).reduce(
            (n, e) => n + e.realized_cost,
            0
          );
        return obj;
      },
      { capex: 0, opex: 0 }
    );
    let contractsList = [];
    let CarouselList = [];

    let options = Options({
      company: companies,
    });

    return (
      <CommonPage loading={false}>
        <Animate>
          <PageLayout
            title={language.IT_BUDGET_CONTROL}
            subTitle={language.DASHBOARD_PAGE.GCF + '/'}
            rightElements={[
              <div
                style={{ width: 400, zoom: 0.8, marginTop: '1rem' }}
                zoom={0.8}
              >
                <CommonForm
                  individual
                  onChangeField={(f, v) => {
                    this.setState({ [f.name]: v });
                  }}
                  values={{
                    currentYear,
                  }}
                  fields={[
                    {
                      col: 6,
                      type: 'select',
                      name: 'currentYear',
                      label: language.YEAR,
                      options: [
                        {
                          value: moment().year() - 2,
                          label: moment().year() - 2,
                        },
                        {
                          value: moment().year() - 1,
                          label: moment().year() - 1,
                        },
                        { value: moment().year(), label: moment().year() },
                        {
                          value: moment().year() + 1,
                          label: moment().year() + 1,
                        },
                        {
                          value: moment().year() + 2,
                          label: moment().year() + 2,
                        },
                        {
                          value: moment().year() + 3,
                          label: moment().year() + 3,
                        },
                        {
                          value: moment().year() + 4,
                          label: moment().year() + 4,
                        },
                        {
                          value: moment().year() + 5,
                          label: moment().year() + 5,
                        },
                      ],
                    },
                    {
                      col: 6,
                      type: 'select',
                      name: 'company',
                      label: language.COMPANY,
                      options: [{ value: null, label: language.ALL }].concat(
                        options.company()
                      ),
                    },
                  ]}
                />
              </div>,
            ]}
          >
            <div id="BudgetControl2">
              <Block_1
                language={language}
                contracts={contracts}
                ContractBilling_data={ContractBilling}
                ContractValue_data={ContractValue_data}
                ContractValue_data_ago={ContractValue_data_ago}
                Totals_data={{
                  acumulated: totalValue,
                  total: totalContracts,
                }}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                currentYear={currentYear}
                budgetcontrols={budgetcontrols}
                filterMonthsData={this.filterMonthsData}
                AccumulatedTotal_data={foreseen_vs_realized}
                CAPEX_X_OPEX_data={capex_vs_opex}
                total_acumulatedTheme={total_acumulated}
                contracts={this.state.contracts}
              />
              <Block_2
                budgetcontrols={budgetcontrols}
                getBudget={this.getBudget}
                language={language}
                contracts={this.state.contracts}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                currentYear={currentYear}
                filterMonthsData={this.filterMonthsData}
                updateItens={this.updateItens}
                contractsList={contractsList}
                CarouselList={CarouselList}
                acumulatedTheme={acumulated}
              />
            </div>
          </PageLayout>
        </Animate>
      </CommonPage>
    );
  }
}

function Pgrss({ current }) {
  return (
    <div className="content-progress">
      <Progress hiddenText current={current} background="var(--primary)" />
      <span>{current}%</span>
    </div>
  );
}
