import React, { Component, Fragment, useEffect, useState } from 'react';

import Header from '@common/Header';
import Button from '@common/Button';
import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import CommonForm from '@common/CommonForm2';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import './index.css';
import Store from '@data/Store';
const action = new Actions();
const { language_type } = Store;

const index = (props) => {
  const { language } = props;
  const [data, setData] = useState(null);
  const [values, setValues] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRelated, setRelated] = useState(false);

  const loadTag = (id) => {
    Store.spinner = true;
    action
      .execute(
        'get',
        `${Constants.APIEndpoints.NOTIFICATION}/tag/${id}`
      )
      .then((res) => {
        Store.spinner = false;
        setData(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
        let messageError = language.ERROR_REQUEST;
        Store.spinner = false;
        Store.toggleAlert(true, messageError, 'error');
      });
  };

  useEffect(() => {
    if (props.value?.id) {
      loadTag(props.value.id);
    }
  }, [props.value]);

  const onChangeField = (field, value, selected, handle, fields, values) => {
    setData({ ...data, [field.name]: value });
  };

  const saveTag = (obj) => {
    if( obj.is_related_value === false ) {
      obj.related_table = null;
      obj.related_key = null;
      obj.related_field = null;
    }

    let requestType = 'post';
    if (props.value?.id) {
      requestType = 'put';
    }
    Store.spinner = true;
    action
      .execute(requestType, `${Constants.APIEndpoints.NOTIFICATION}/tag`, '', obj).then(
      (res) => {
        Store.spinner = false;
        Store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
        props.loadTags();
        props.onClose();
      },
      (error) => {
        console.log(error);
        let messageError = language.ERROR_REQUEST;
        Store.spinner = false;
        Store.toggleAlert(true, messageError, 'error');
      },
    );
  };

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <CommonForm
        values={data}
        fields={[
          {
            col: 6,
            name: 'name',
            label: language.NAME,
            type: 'text',
            required: true,
            disabled: !!props.value?.id,
          },
          {
            col: 6,
            name: 'value',
            label: language.VALUE,
            type: 'text',
            required: true,
            disabled: !!props.value?.id,
          },
          {
            col: 4,
            name: 'description',
            label: language.DESCRIPTION,
            type: 'text',
            required: true,
          },
          {
            col: 4,
            name: 'es_description',
            label: language.DESCRIPTION + ' (ES)',
            type: 'text',
            required: true,
          },
          {
            col: 4,
            name: 'en_description',
            label: language.DESCRIPTION + ' (EN)',
            type: 'text',
            required: true,
          },
          {
            col: 12,
            name: 'is_related_value',
            label: language.RELATIONSHIP,
            type: 'switch',
          },
          {
            col: 4,
            name: 'related_table',
            label: language.TABLE,
            type: 'text',
            required: (data && data['is_related_value'] === true) ? true : false,
            visible: (data && data['is_related_value'] === true) ? true : false
          },
          {
            col: 4,
            name: 'related_key',
            label: language.KEY,
            type: 'text',
            required: (data && data['is_related_value'] === true) ? true : false,
            visible: (data && data['is_related_value'] === true) ? true : false
          },
          {
            col: 4,
            name: 'related_field',
            label: language.FIELD,
            type: 'text',
            required: (data && data['is_related_value'] === true) ? true : false,
            visible: (data && data['is_related_value'] === true) ? true : false
          }
        ]}
        onChangeField={onChangeField}
        onSubmit={(v) => saveTag(v)}
      />
    </CommonPage>
  );
};

export default index;
