import React, { useEffect, useState, Fragment, memo, useReducer } from 'react';
import './index.css';
import Dialog from '@common/Dialog';

import { Grid } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@common/Button';
import classnames from 'classnames';
/*-----------------------components-------------------------------*/
import Table_1 from './Table_1';
import Table_2 from './Table_2';
import Form from './Form';
import Result from './Result';
import AssociateTicketTable from '../AssociateTicketTable';
import AssociateActivityTable from '../AssociateActivityTable';
import st from '../../../../data/Store';
const crypto = require('crypto');
import TicketManagement from '../../../TicketManagement/index';
import Store from '@data/Store';
import { concatDateHour } from '../../../../util/dateTime';

const { language } = Store;

var CryptoJS = require('crypto-js')
function decryp(aMsg) {
  try {
    var aSecret = 'Orch3str0!@forever!';
    aMsg = aMsg.replace(/\s/g, "");
    let decipher, tRet;
    decipher = crypto.createDecipher("aes-256-cbc", aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
    tRet += decipher.final("utf8");
    return tRet;
  } catch (e) {
    return {}
  }

}
/*
  --TODOS OS DADOS PARA ESSE COMPONENTE POR FAVOR, FAZER AS REQUISIÇÕES, PUTs NO PRÓPRIO, E NÃO NO COMPONENTE PAI--
*/

export default memo(
  ({
    open,
    onClose,
    result = false, // essa props controla se esse modal vai mostrar os resultados ou não, caso for false, o formulário será exibido caso true, o resultado será exibido
    onOpenModal = () => null,
    action,
    APIEndpoints,
    users,
    companies,
    redirect,
    sprintpjs,
    sprintkaban,
    editItens,
    template,
    project
  }) => {
    let [nameRef, setNameRef] = useState(null);
    let [modal, setModal] = useState(0);
    let [itens, setItens] = useState(sprintpjs);
    let [sprint, setSprint] = useState([]);
    let [idTicket, setIdTicket] = useState(0);
    let [selectedTickets, setSelectedTickets] = useState([]);
    let [newTickets, setNewTickets] = useState([]);
    let [effortValue, setEffortValue] = useState([])
    let [isBacklog, setIsBacklog] = useState(false);

    const [ticketsChecked, setTicketsChecked] = useState([])
    const [ticketsSprint, setTicketsSprint] = useState([])

    //let [form, setForm] = useReducer((state, data)=> ({...state, ...data}),{}) // tudo que é digitado no formulário dessa página vai parar aqui
    const reducer = (state, value) => ({ ...state, ...value });
    let [form, setForm] = useReducer(reducer, {
      fk_id_user: null,
      dt_start: null,
      name: null,
      hour_start: null,
      dt_end: null,
      hour_end: null,
      tickets: [],
      activities: [],
      id_project_sprint: null,
      edit: false,
      t: [],
      a: [],
      user_solicitant:'',
      name_company:''
    });

    if (sprintkaban) {

      form.id_project_sprint = sprintkaban[0].sprint.id_project_sprint;
      form.id_project_sprint = sprintkaban[0].sprint.id_project_sprint;
      form.fk_id_company = sprintkaban[0].sprint.fk_id_company;
      form.fk_id_user = sprintkaban[0].sprint.fk_id_user;
      form.dt_start = sprintkaban[0].sprint.dt_start;
      form.dt_end = sprintkaban[0].sprint.dt_end;
      form.hour_start = sprintkaban[0].sprint.dt_start;
      form.hour_end = sprintkaban[0].sprint.dt_end;
      form.name = sprintkaban[0].sprint.name;
      form.t = sprintkaban[0].sprint.tickets;
      form.a = sprintkaban[0].sprint.project_sprints_activities;

    }


    useEffect(() => {
       if(open == false){
        setTicketsSprint([]);
        setForm({ tickets: [] });
        setForm({activities:[] })
       }
        
    },[open])

    useEffect(() => {
      action
        .execute(
          'get',
          APIEndpoints.EFFORT_VALUE,
          'effort_value'
        )
        .then(res => {
          setEffortValue(res.data);
        });

    }, [])


    useEffect(() => {
      if (editItens && editItens.id_project_sprint > 0) {
        action
          .execute(
            'post',
            APIEndpoints.PROJECT + '/sprint/kaban',
            'sprintkaban',
            { id_project_sprint: editItens.id_project_sprint }
          )
          .then(v => {
            setForm({ tickets: v.data.tickets });
              setTicketsSprint(v.data.assotickets)
              setForm({ activities: v.data.ac });
          });





        form.id_project_sprint = editItens.id_project_sprint;
        form.fk_id_company = editItens.fk_id_company;
        form.fk_id_user = editItens.fk_id_user;
        form.dt_start = editItens.dt_start;
        form.dt_end = editItens.dt_end;
        form.hour_start = editItens.dt_start;
        form.hour_end = editItens.dt_end;
        form.name = editItens.name;
        form.user_solicitant = editItens.user_solicitant;
        form.name_company = editItens.name_company;
        form.edit = true;




      } else {
        setForm({
          fk_id_user: null,
          dt_start: null,
          name: null,
          hour_start: null,
          dt_end: null,
          hour_end: null,
          tickets: [],
          activities: [],
          id_project_sprint: null,
          edit: false
        });
      }
      if (template) {
        action.execute('post', APIEndpoints.PROJECT + '/sprintpjs/template', '', { id_project: project.id_project }).then(v => {
          setItens(v.data);
        }).catch(err => {
          console.log(err)
        });


        if (editItens && editItens.id_project_sprint_template > 0) {
          setForm({
            id_project_sprint: editItens.id_project_sprint_template,
            fk_id_company: editItens.fk_id_company,
            fk_id_user: editItens.fk_id_user,
            dt_start: editItens.dt_start,
            dt_end: editItens.dt_end,
            hour_start: editItens.dt_start,
            hour_end: editItens.dt_end,
            name: editItens.name,
            edit: true,
          })

          action
            .execute(
              'post',
              APIEndpoints.PROJECT + '/sprintpjs/kaban/template',
              'sprintkaban',
              { id_project_sprint_template: editItens.id_project_sprint_template }
            )
            .then(v => {
              setForm({ tickets: v.data.assotickets });
          
              setForm({ activities: v.data.assoac });
            });

        }



      } else {
        action.execute('get', APIEndpoints.PROJECT + '/sprintpjs', '').then(v => {
          setItens(v.data);
        });
      }


    }, [editItens]);

    const addTicket = item => {
      let ticket = form.tickets;
      let index = ticket.findIndex(ticket => ticket.id_tickets == item.id_tickets)

      if (index == -1) {
        ticket.push(item)
      } else {
        ticket.splice(index, 1)
      }
      setForm({ tickets: ticket });

      let ticketsChecked_ =ticketsChecked
      let indexChecked = ticketsChecked_.findIndex(ticket => ticket.id_tickets == item.id_tickets)
      if (indexChecked == -1) {
        ticketsChecked_.push(item)
      } else {
        ticketsChecked_.splice(indexChecked, 1)
      }

      setTicketsChecked([...ticketsChecked_])
      setNewTickets([...ticketsChecked_])
    };

    const closeModal = () => {
      setSelectedTickets(newTickets);

      setModal(0);
    }

    const addActivities = item => {
      let activities = form.activities;
      activities.push(item);

      setForm({ activities: activities });
    };

    const setId = item => {
      setIdTicket(item)
    }

   

    const saveSprint = () => {
      //validações
      var msgs = [];
      if (form.dt_start == null && !template)
        msgs.push(language.ENTER_THE_START_DATE_OF_THE_SPRINT);
      if (form.hour_start == null && !template)
        msgs.push(language.ENTER_THE_START_TIME_OF_THE_SPRINT);
      if (form.dt_end == null && !template)
        msgs.push(language.ENTER_THE_END_DATE_OF_THE_SPRINT);
      if (form.hour_end == null && !template)
        msgs.push(language.ENTER_THE_END_TIME_OF_THE_SPRINT);
      if (form.name == null && !result)
        msgs.push(language.ENTER_THE_NAME_OF_THE_SPRINT);
      if (form.fk_id_company == null && !result)
        msgs.push(language.INFORM_THE_SPRINT_COMPANY);
      if (form.fk_id_user == null && !result)
        msgs.push(language.INFORM_THE_SPRINT_REQUESTER);
      if (form.activities.findIndex(e => ![1, 2, 3].includes(parseInt(e.priority))) != -1 && !template)
        msgs.push(language.INFORM_THE_PRIORITY_OF_THE_ACTIVITY);
      if (form.tickets.findIndex(e => ![1, 2, 3].includes(parseInt(e.priority))) != -1 && !template)
        msgs.push(language.ENTER_TICKET_PRIORITY);
      if (form.tickets.findIndex(e => ![1, 2, 3, 5, 8, 13, 21].includes(parseInt(e.fk_id_effort_value))) != -1 && !template)
        msgs.push(language.ENTER_THE_CORRECT_TICKET_EFFORT_VALUE);
      if (form.activities.findIndex(e => ![1, 2, 3, 5, 8, 13, 21].includes(parseInt(e.fk_id_effort_value))) != -1 && !template)
        msgs.push(language.ENTER_THE_CORRECT_AMOUNT_OF_ACTIVITY_EFFORT);

      if (msgs.length > 0) {
        for (let i = 0; i < msgs.length; i++) {
          st.toggleAlert(true, msgs[i], 'error');
        }

        return;
      }


      if (!template) {
        form.dt_start = concatDateHour(form.dt_start, form.hour_start);
        form.dt_end = concatDateHour(form.dt_end, form.hour_end);
        
        action
          .execute('post', APIEndpoints.PROJECT + '/sprint', '', form)
          .then(h => {
               
              if(h.data.assoc == true){

                let valores = h.data.itens.map(v=>v.id + ' '+ v.name  ).join(' ')
                st.toggleAlert(true, `${language.ITEMS_ASSOCIATED_WITH_ANOTHER_SPRINT}: ${valores} `,"error")


              }else{ 


        
        if (sprintkaban) {
           redirect(`/dash/projectmanagement/sprint/${form.id_project_sprint}/kanban`);
        } else {
          redirect('/dash/projectmanagement/sprint');
        }   }});

      } else {

        form.fk_id_project_template = project.id_project
        action
          .execute('post', APIEndpoints.PROJECT + '/sprint/template', '', form)
          .then(h => {
            window.location.reload();
          });

      }

    };
    let ts = [];
    //--------------------------------------------------------------DADOS DA TABELA-1--------------------------------------------------------------------
    let users_ = [] 
    try{
      users_ = JSON.parse(decryp(sessionStorage.getItem('users')));
    }catch (e){}
    let useroptions = [];
    users_.map(tec => {

      useroptions.push({
        value: tec.id_user,
        label: tec.name + ' - ' + tec.email
      })
    })

    let options = effortValue.map(ef => ({
      value: ef.id,
      label: ef.value
    }))
    let dataTable1 = [];
    let dataTable2 = [];
    form.tickets &&
      form.tickets.map(h => {
        if (dataTable1.filter(b => b.id == h.real_id).length == 0)
          dataTable1.push({
            id: h.real_id,
            subject: h.subject,
            tech:
              users.filter(v => v.id_user == h.fk_id_user)[0] &&
              users.filter(v => v.id_user == h.fk_id_user)[0].name,
            effort: h.sla_task,

            effort_value: (

              <div style={{ width: 300, zoom: .8, }}>
                <CommonForm
                  individual
                  values={{
                    effort_value: h.fk_id_effort_value
                  }}
                  alert={false}
                  fields={[
                    {
                      span: 2,
                      type: 'text',
                      name: 'effort_value',
                      label: `${language.EFFORT_VALUE} (1, 2, 3, 5, 8, 13, 21)`,
                      className: 'subject-ticket',
                      required: !template,
                    }
                  ]}

                  onChangeField={(f, v) => {
                    let tic = form.activities.findIndex(
                      g => g.id_project_activity == h.id_project_activity
                    );
                    h.fk_id_effort_value = v;
                    
                    form.tickets[tic] = h;
                  }}
                />
              </div>
            ),
            priority: [1, 2, 3].map(e => (
              <i
                onClick={(f, v) => {
                  let tic = form.tickets.findIndex(
                    g => g.id_tickets == h.id_tickets
                  );

                  h.priority = e;
                  form.tickets[tic] = h;

                  setForm(form);
                }}
                style={{ cursor: 'pointer' }}
                className={classnames(
                  e <= h.priority ? 'fas fa-star' : 'far fa-star',
                  'star-icon',
                  { 'mx-1': e == 2 }
                )}
              />
            )),
            delivery_date: (
              <div style={{ width: 200, zoom: .8 }}>
                <CommonForm
                  individual
                  values={{
                    data: h.dt_cad
                  }}
                  alert={false}
                  fields={[
                    {
                      span: 4,
                      type: 'date',
                      name: 'data',
                      label: language.DATE,
                      className: 'subject-ticket',
                      required: !template
                    }
                  ]}
                  onChangeField={(f, v) => {
                    let tic = form.tickets.findIndex(
                      g => g.id_tickets == h.id_tickets
                    );

                    h.dt_cad = v;
                    form.tickets[tic] = h;
                  }}
                />
              </div>
            ),
            tech_sprint: (

              <div style={{ width: 200, zoom: .8 }}>
                <CommonForm
                  individual
                  values={{
                    fk_id_tech: h.fk_id_tech
                  }}
                  alert={false}
                  fields={[
                    {
                    span: 4,
                    type: 'autocomplete',
                    name: 'fk_id_tech',
                    label: language.TECH,
                    className: 'subject-ticket',
                    required: !template,
                    method: "POST",
                    route: `${APIEndpoints.USER}/filter`,
                    routeGetCount: `${APIEndpoints.USER}/active/count/tech`,
                    filterAll: { role: `tech` },
                    fieldquery: 'name',
                    textinit: h.tech_start || language.TECH,
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    idinit:  h.fk_id_tech,
                    routeAll: `${APIEndpoints.USER}/filter/tech?`,
                    detail: true,
                    methodGetAll: 'POST',

                    }
                  ]}



                  onChangeField={(f, v) => {
                    let tic = form.tickets.findIndex(
                      g => g.id_tickets == h.id_tickets
                    );

                    h.fk_id_tech = v;
                    form.tickets[tic] = h;
                  }}
                />
              </div>
            ),
            company_name: h.namecompany ? h.namecompany : companies && companies.filter(c => c.id_company == h.fk_id_company)[0] ? companies.filter(c => c.id_company == h.fk_id_company)[0].name : '',
            del: (
              <i
                class="fas fa-trash"
                style={{ cursor: 'pointer', color: 'red', fontSize: '14px' }}
                onClick={() =>
                  setForm({
                    tickets: form.tickets.filter(v => v.id_tickets != h.id_tickets)
                  })
                }
              ></i>
            )
          })

      });
    //--------------------------------------------------------------DADOS DA TABELA-2--------------------------------------------------------------------
    form.activities &&
      form.activities.map(h => {
        if (dataTable2.filter(b => b.id == h.id_project_activity).length == 0) {
          dataTable2.push({
            project: h.project || h.nameprojeto,

            activity: h.id_project_activity + ' - ' + h.name,
            effort: (
              <div style={{ width: 100, zoom: .8 }}>
                <CommonForm
                  individual
                  values={{
                    hours: h.hours
                  }}
                  alert={false}
                  fields={[
                    {
                      span: 2,
                      type: 'text',
                      name: 'hours',
                      label: language.HOURS,
                      className: 'subject-ticket',
                      required: !template
                    }
                  ]}
                  onChangeField={(f, v) => {
                    let tic = form.activities.findIndex(
                      g => g.id_project_activity == h.id_project_activity
                    );

                    h.hours = v;
                    form.activities[tic] = h;
                  }}
                />
              </div>
            ),
            effort_value: (

              <div style={{ width: 300, zoom: .8 }}>
                <CommonForm
                  individual

                  values={{
                    effort_value: h.fk_id_effort_value
                  }}
                  alert={false}
                  fields={[
                    {
                      span: 4,
                      type: 'text',
                      name: 'effort_value',
                      label: `${language.EFFORT_VALUE} (1, 2, 3, 5, 8, 13, 21)`,

                      className: 'subject-ticket',
                      required: !template
                    }
                  ]}
                  onChangeField={(f, v) => {

                    //let eff = effortValue.filter(e => e.value == v)
                    let tic = form.activities.findIndex(
                      g => g.id_project_activity == h.id_project_activity
                    );
                    h.fk_id_effort_value = v;
                    form.activities[tic] = h;
                  }}
                />
              </div>
            ),

            priority: [1, 2, 3].map(e => (
              <i
                onClick={(f, v) => {
                  let tic = form.activities.findIndex(
                    g => g.id_project_activity == h.id_project_activity
                  );

                  h.priority = e;

                  form.activities[tic] = h;
                  setForm(form);
                }}
                style={{ cursor: 'pointer' }}
                className={classnames(
                  e <= h.priority ? 'fas fa-star' : 'far fa-star',
                  'star-icon',
                  { 'mx-1': e == 2 }
                )}
              />
            )),
            delivery_date: (
              <div style={{ width: 200, zoom: .8 }}>
                <CommonForm
                  individual
                  values={{
                    data: h.dt_start
                  }}
                  alert={false}
                  fields={[
                    {
                      span: 4,
                      type: 'date',
                      name: 'data',
                      label: language.DATE,
                      className: 'subject-ticket',
                      required: !template
                    }
                  ]}
                  onChangeField={(f, v) => {
                    let tic = form.activities.findIndex(
                      g => g.id_project_activity == h.id_project_activity
                    );

                    h.dt_start = v;
                    form.activities[tic] = h;
                  }}
                />
              </div>
            ),
            company_name: companies && h.fk_id_company ? companies.filter(c => c.id_company == h.fk_id_company)[0].name : h.company,
            sponsor: h.sponsor  ? h.sponsor : itens.actitivies.length > 0 && itens.actitivies.filter(ac => ac.id_project_activity == h.id_project_activity)[0] ? itens.actitivies.filter(ac => ac.id_project_activity == h.id_project_activity)[0].sponsor : '',
            del: (
              <i
                class="fas fa-trash"
                style={{ cursor: 'pointer', fontSize: '14px', color: 'red' }}
                onClick={() =>
                  setForm({
                    activities: form.activities.filter(
                      v => v.id_project_activity != h.id_project_activity
                    )
                  })
                }
              ></i>
            )
          })
        }
      });
    try {
      itens.tickets = (itens.tickets || []).filter(v => !form.t.includes(v.id_tickets))
      itens.actitivies = itens.actitivies &&
        itens.actitivies.filter(v => !form.a.includes(v.id_project_activity))


      itens.tickets = (itens.tickets || []).filter(v => !form.t.includes(v.id_tickets))
      itens.actitivies = itens.actitivies &&
        itens.actitivies.filter(v => !form.a.includes(v.id_project_activity))


      itens.tickets = (itens.tickets || []).filter(v => !form.t.includes(v.id_tickets))
      itens.actitivies = itens.actitivies &&
        itens.actitivies.filter(v => !form.a.includes(v.id_project_activity))

      if (modal == 0) {
        let b = form.activities.map(g => g.id_project_activity)

        itens.actitivies = itens.actitivies &&
          itens.actitivies.filter(v => !b.includes(v.id_project_activity))
      }
    } catch (e) {
      console.log(e)
    }
    return (
      <Fragment>
        {modal == 1 &&

          <AssociateTicketTable
            users={users}
            itens={itens}
            addItem={addTicket}
            open={modal == 1}
            onClose={closeModal}
            setId={setId}
            selectedTickets={selectedTickets}
            ticketsChecked = {ticketsChecked}
            ticketsSprint = {ticketsSprint}
            />

        }

        <AssociateActivityTable
          users={users}
          itens={itens}
          addItem={addActivities}
          open={modal == 2}
          onClose={() => setModal(0)}
          isBacklog={isBacklog}
          setIsBacklog={setIsBacklog}
        />

        <Dialog
          PaperProps={{
            id: 'DialogFormSprint'
          }}
          maxWidth="xl"
          open={open}
          onClose={onClose}
          contentTitle={{
            style: { width: '100%' }
          }}
          titleContentProps={{
            style: {
              width: 'calc(100% - 60px)'
            }
          }}
          title={result ? language.LIST_OF_ASSOCIATED_ITEMS : (<div className="w-100 bar" style={{ zoom: 0.9 }}>
            <CommonForm
              values={form}
              alert={false}
              individual
              fields={[
                {
                  type: 'text',
                  name: 'name',
                  label: language.SPRINT_NAME,
                  className: 'subject-ticket',
                  required: !template
                }
              ]}
              onRef={r => setNameRef(r)}
              onChangeField={(f, v) => setForm({ [f.name]: v })}
            />
          </div>)}
          maxWidth="lg"
        >
          {console.log(form)}
          <div>
            {/*-----------------------------------------form-----------------------------------------*/}
            {!result && (
              <Fragment>
                <Form
                  users={users}
                  form={form}
                  companies={companies}
                  setForm={setForm}
                  saveSprint={saveSprint}
                  onChangeField={(f, v) => setForm({ [f.name]: v })}
                  template={template}
                  project={project}
                />
                <hr />
              </Fragment>
            )}
            {/*-----------------------------------------table-1-----------------------------------------*/}
            {template ? null : (
              <div>
                <span className="title mb-2">{language.ASSOCIATE_TICKET}</span>
                <Table_1 data={dataTable1} onOpenModal={() => setModal(1)} />
                <hr />
              </div>
            )}
            {/*-----------------------------------------table-2-----------------------------------------*/}
            <span className="title mb-2">{language.ASSOCIATE_PROJECT_ACTIVITIES}</span>
            <Table_2 data={dataTable2} onOpenModal={(isBacklog = false) => { setModal(2); setIsBacklog(isBacklog) }} />
            <hr />
            {/*-----------------------------------------buttons-----------------------------------------*/}
            <div className="d-flex align-items-center justify-content-end">
              <Button
                label={language.CANCEL}
                color="danger"
                variant="normal"
                className="mr-3"
                onClick={onClose}
              />
              <Button
                label={language.SAVE}
                color="success"
                variant="normal"
                onClick={() => saveSprint()}
              />
            </div>
          </div>
        </Dialog>
        {idTicket == 0 ? null : (
          <Dialog
            title="Ticket"
            open={idTicket == 0 ? false : true}
            maxWidth="xl"
            onClose={() => {
              setIdTicket(0);
            }}
          >
            <TicketManagement
              detail={true}
              id_ticket_detail={idTicket}
            ></TicketManagement>
          </Dialog>
        )}
      </Fragment>
    )
  }
)
