import React from 'react';
/*-------------------common---------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import { flexBasis } from '@common/Func';
/*-------------------material-ui---------------------------*/
import { Card, Grid } from '@material-ui/core';
/*-------------------pages---------------------------*/
import { TicketEvolution } from './Graph';
import CardIndicatorB3B6 from '../CardIndicatorB3B6';
export default function ({
  data = [],
  openModalDetail,
  ticketsGSDTop,
  language,
}) {
  var dataTotal = data.reduce((o, e) => o + e.value, 0);
  let array = ['#92d050', '#fdde8a', '#ea381c'];

  return (
    <Card id="Priority" className="mt-3">
      <AppBar title={language.PRIORITY} />
      <div className="content-medium pt-0">
        <Grid container spacing={2}>
          {data.map((e, i) => (
            <Grid
              item
              xs={3}
              style={{ ...flexBasis(3), cursor: 'pointer' }}
            
              className="content-indicators"
            >
              <CardIndicatorB3B6
                percent={
                  isNaN(parseFloat((e.value / dataTotal) * 100))
                    ? 0
                    : parseFloat((e.value / dataTotal) * 100).toFixed(0)
                }
                {...e}
                language={language}
              />
            </Grid>
          ))}
          {data.length && (
            <Grid
              item
              xs={3}
              style={{ ...flexBasis(3), cursor: 'pointer' }}
              className="content-indicators"
            >
              <CardIndicatorB3B6
                label={language.TOTAL}
                value={dataTotal}
                language={language}
              />
            </Grid>
          )}
        </Grid>

        <div className="content-progress-sector mt-3">
          <span className="ml-3">(%) {language.PRIORITY}</span>
          <div className="progress-sector">
            {data.map((e, i) => (
              <div
                style={{
                  width: isNaN((e.value / dataTotal) * 100)
                    ? 0 + '%'
                    : (e.value / dataTotal) * 100 + '%',
                  backgroundColor: array[i],
                  color: 'white',
                  fontWeight: 600,
                  textAlign: 'center',
                }}
              >
                {isNaN(parseFloat(e.value / dataTotal))
                  ? 0 + '%'
                  : parseFloat((e.value / dataTotal) * 100).toFixed(0) + '%'}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
