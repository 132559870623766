import { Button } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useEffect, useState } from 'react';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import ServicePortalCatalogForm from './ServicePortalCatalogForm';
import ServicePortalCatalogItem from './ServicePortalCatalogItem';
import { useServicePortalStyles } from './helpers';

export default function ServicePortalCatalogMostUsed({
  items,
  language,
  allowCustomizePriority,
  isIntegrationEnabled,
}) {
  const SUFIX = ': ';
  const CATEGORY = language['SERVICE_PORTAL']['CATEGORY'];
  const SUBCATEGORY = language['SERVICE_PORTAL']['SUBCATEGORY'];
  const TASK = language['SERVICE_PORTAL']['TASK'];

  const styles = useServicePortalStyles();

  const [formSchema, setFormSchema] = useState();
  const [catalogTree, setCatalogTree] = useState();

  useEffect(async () => {
    if (catalogTree?.task) {
      const urlForm = `${Constants.APIEndpoints.TASK}/getformticket/${catalogTree.task.id}`;
      const fetch = new Actions();
      await fetch
        .execute('get', urlForm, '')
        .then(async ({ data }) => setFormSchema(data));
    } else formSchema && setFormSchema(null);
  }, [catalogTree?.task]);

  function handleSelectCatalog(item) {
    // console.log('handleSelectCatalog', item)
    setCatalogTree({
      category: {
        id: item.fk_id_category,
        name: item.category_name,
      },
      catalog: {
        id: item.fk_id_catalog_service,
        name: item.catalog_name,
      },
      task: null,
      tasks: item.tasks,
    });
  }

  function handelSelectTask(item) {
    setCatalogTree({
      ...catalogTree,
      task: {
        id: item.id_catalog_task,
        name: item.name,
        description: item.description,
        disable_fields: item.disable_fields,
      },
    });
  }

  function handlePreviousLevel() {
    const hasTask = catalogTree && catalogTree.task;
    setCatalogTree(hasTask ? { ...catalogTree, task: null } : null);
  }

  let breadcrumbs = [];
  catalogTree && breadcrumbs.push(CATEGORY + SUFIX + catalogTree.category.name);
  catalogTree &&
    breadcrumbs.push(SUBCATEGORY + SUFIX + catalogTree.catalog.name);
  catalogTree &&
    catalogTree.task &&
    breadcrumbs.push(TASK + SUFIX + catalogTree.task.name);

  if (!items || items.length === 0) return '';

  return (
    <div className="tw-w-full">
      {catalogTree && (
        <div className="tw-flex tw-justify-between tw-items-center">
          <Breadcrumbs
            color="inherit"
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs.map((item, index) => {
              return (
                <Typography key={item} component="span" className={styles.text}>
                  {item}
                </Typography>
              );
            })}
          </Breadcrumbs>
          <div>
            <Button
              size="small"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={handlePreviousLevel}
              disabled={!catalogTree}
            >
              {language.BACK}
            </Button>
          </div>
        </div>
      )}

      {!catalogTree && (
        <>
          <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div>
              <Typography variant="h5" component="h2">
              {language.SERVICE_PORTAL.SERVICE_PORTAL}
              </Typography>
            </div>

            <div className="tw-mt-5">
              <Typography variant="h6" component="h3">
                {language.SERVICE_PORTAL.MOST_USED}
              </Typography>
            </div>
          </div>
        </>
      )}

      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-py-4">
        {!catalogTree &&
          items.map((subcategoryItem) => (
            <>
              <ServicePortalCatalogItem
                key={'musc_' + subcategoryItem.fk_id_catalog_service}
                title={SUBCATEGORY}
                item={{
                  name: subcategoryItem.catalog_name,
                  description: subcategoryItem.catalog_description,
                }}
                titleRoot={CATEGORY}
                itemRoot={{ name: subcategoryItem.category_name }}
                onSelectItem={() => handleSelectCatalog(subcategoryItem)}
              />
            </>
          ))}

        {!catalogTree?.task &&
          catalogTree?.tasks.map((taskItem) => (
            <ServicePortalCatalogItem
              key={'mut_' + taskItem.id}
              title={TASK}
              item={taskItem}
              onSelectItem={() => handelSelectTask(taskItem)}
            />
          ))}

        {catalogTree?.task && formSchema && (
          <ServicePortalCatalogForm
            key={formSchema.fk_id_catalog_task}
            task={catalogTree?.task}
            formSchema={formSchema}
            catalogSelected={{
              thirdLevel: catalogTree.category.id,
              subcategory: catalogTree.catalog.id,
              task: catalogTree.task.id,
            }}
            allowCustomizePriority={allowCustomizePriority}
            isIntegrationEnabled={isIntegrationEnabled}
          />
        )}
      </div>
    </div>
  );
}
