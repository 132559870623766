import React, { useEffect, useState } from 'react';
import { Chip, Card } from '@material-ui/core';
import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import ConsumerForm from './ConsumerForm';
import CommonTable from '../../../common/CommonTable';
import Actions from '@data/Actions';

const action = new Actions();

const ConsumerList = ({ APIEndpoints, language }) => {
  const [consumers, setConsumers] = useState([]);
  const [countConsumers, setCountConsumers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [form, setForm] = useState(false);
  const [consumerSelected, setConsumerSelected] = useState([]);

  const callPageSelectFilter = (page = 0, values = {}, limit = 75) => {
    setCurrentPage(page);

    action
      .execute('post', `${APIEndpoints.SAC_CONSUMERS}?limit=${limit}&offset=${page * limit}`, '', values)
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        setConsumers(Object.keys(data).length > 0 ? data : []);
        setCountConsumers(data && data[0] ? data[0].total_count : 0);
      });
  };

  const handlePage = (page, obj) => {
    if (page === 'edit') {
      setConsumerSelected(obj);
      setForm(true);
    }
  };

  useEffect(() => {
    callPageSelectFilter();
  }, []);


  const data = consumers.length > 0 && consumers.map((obj) => ({
    desc_status: obj.desc_status,
    name: obj.name,
    cpf_cnpj: obj.cpf_cnpj,
    type: obj.type,
    address: obj.address,
    number_address: obj.number_address,
    neighborhood: obj.neighborhood,
    email: obj.email,
    phone: obj.phone,
    actions: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
              icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 12 }}
                size={0.7}
                className="mr-2"
                onClick={() => {
                  handlePage('edit', obj);
                }}
                onClose={() => setForm(true)}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  const col = [
    {
      key: 'desc_status',
      label: language.STATUS,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'name',
      label: language.NAME,
      style: { textAlign: 'left' },
      thConfig: true,
    },

    {
      key: 'cpf_cnpj',
      label: 'CPF/CNPJ',
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'type',
      label: language.TYPE,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'address',
      label: language.ADDRESS,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'number_address',
      label: language.NUMBER,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'neighborhood',
      label: language.NEIGHBORHOOD,
      style: { textAlign: 'left' },
      thConfig: true,
    },

    {
      key: 'email',
      label: language.EMAIL,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'phone',
      label: language.PHONE,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'actions',
      label: language.ACTIONS,
      style: { textAlign: 'left' },
      thConfig: false,
    },
  ];

  return (
    <Card className="content-min px-3">
      <Dialog
        title="Adicionar Cliente"
        open={form}
        onClose={() => {
          setConsumerSelected('');
          setForm(false);
        }}
        maxWidth="lg"
      >
        <ConsumerForm
          APIEndpoints={APIEndpoints}
          language={language}
          setForm={setForm}
          values={consumerSelected}
          callPageSelectFilter={() => callPageSelectFilter()}
        />
      </Dialog>
      <CommonTable
        title={''}
        language={language}
        columns={col}
        data={data}
        index="ConsumerList"
        showHeaderToolbar={false}
        orderColumn
        countTotal={countConsumers}
        isAutomaticPagination={false}
        beginWithNoFilter={true}
        paginationTop={false}
        searchColumn={true}
        loadSearch={true}
        currentPage={currentPage}
        searchColumnEvent={(v) => {
          // callPageSelectFilter(0, {ticketOrreuest :v.search}, 75)
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
        beforeBar={
          <Button
            variant="circle"
            icon="fas fa-plus"
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            label="Adicionar Cliente"
            primary={true}
            onClick={() => {
              setConsumerSelected('');
              setForm(true);
            }}
          />
        }
      />
    </Card>
  );
};

export default ConsumerList;
