import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import classNames from "classnames";

const defaultChatMsgStyles = ({ palette, spacing }) => {
    const radius = spacing(2.5);
    const size = spacing(4);
    const rightBgColor = palette.primary.main;
    // if you want the same as facebook messenger, use this color '#09f'
    return {
        avatar: {
            width: size,
            height: size,
            lineHeight: 'unset'
        },
        leftRow: {
            textAlign: 'left',
        },
        rightRow: {
            textAlign: 'right',
        },
        text: {
            fontSize: '12px',
        },
        textTime: {
            fontSize: '9px',
        },
        msg: {
            padding: spacing(1, 2),
            borderRadius: 4,
            marginBottom: 4,
            display: 'inline-block',
            wordBreak: 'break-word',
            fontFamily: 'inherit',
            fontSize: '12px',
        },
        left: {
            borderTopRightRadius: radius,
            borderBottomRightRadius: radius,
            backgroundColor: palette.grey[100],
        },
        right: {
            borderTopLeftRadius: radius,
            borderBottomLeftRadius: radius,
            backgroundColor: rightBgColor,
            color: palette.common.white,
        },
        leftFirst: {
            borderTopLeftRadius: radius,
        },
        leftLast: {
            borderBottomLeftRadius: radius,
        },
        rightFirst: {
            borderTopRightRadius: radius,
        },
        rightLast: {
            borderBottomRightRadius: radius,
        },
    };
};

const ChatMessage = withStyles(defaultChatMsgStyles, { name: 'ChatMessage' })(props => {
    const {
        classes,
        avatar,
        messages,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps,
        displayName,
        timestamp
    } = props;
    const attachClass = index => {
        if (index === 0) {
            return classes[`${side}First`];
        }
        if (index === messages.length - 1) {
            return classes[`${side}Last`];
        }
        return '';
    };
    return (
        <Grid
            container
            spacing={2}
            justifyContent={side === 'right' ? 'flex-end' : 'flex-start'}
            {...GridContainerProps}
        >
            {side === 'left' && (
                <Grid item {...GridItemProps}>
                    <Tooltip title={displayName}>
                        <Avatar
                            alt={displayName}
                            //src={avatar}
                            //{...AvatarProps}
                            className={classNames(classes.avatar, AvatarProps.className)}
                        >
                            {(displayName[0] ?? '').toLocaleUpperCase()}
                        </Avatar>
                    </Tooltip>
                </Grid>
            )}
            <Grid item xs={8}>

                {Array.isArray(messages) && messages.map((msg, i) => {
                    const TypographyProps = getTypographyProps(msg, i, props);
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={msg.id || i} className={classes[`${side}Row`]}>
                            <Typography
                                align={'left'}
                                {...TypographyProps}
                                className={classNames(
                                    classes.msg,
                                    classes[side],
                                    attachClass(i),
                                    TypographyProps.className
                                )}
                            >
                                <span dangerouslySetInnerHTML={{ __html: msg }} />
                            </Typography>
                        </div>
                    );
                })}

                {!Array.isArray(messages) && (
                    <>
                        <div className={classes[`${side}Row`]}>
                            <Typography
                                align={'left'}
                                //{...TypographyProps}
                                className={classNames(
                                    classes.msg,
                                    classes[side],
                                    classes[`${side}First`]
                                )}
                            >
                                <span dangerouslySetInnerHTML={{ __html: messages }} />
                            </Typography>
                        </div>
                        <div className={classNames(classes[`${side}Row`])}>
                            <Typography className={classes.textTime}>
                                {timestamp}
                            </Typography>
                        </div>
                    </>
                )}
            </Grid>
        </Grid >
    );
});
ChatMessage.propTypes = {
    avatar: PropTypes.string,
    messages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    side: PropTypes.oneOf(['left', 'right']),
    GridContainerProps: PropTypes.shape({}),
    GridItemProps: PropTypes.shape({}),
    AvatarProps: PropTypes.shape({}),
    getTypographyProps: PropTypes.func,
};
ChatMessage.defaultProps = {
    avatar: '',
    messages: [],
    side: 'left',
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({}),
};

export { ChatMessage };