import { Grid } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tippy';

// ------------------------------------------------------------

import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import RaisedButton from '@common/RaisedButton';
import { translateFromPtBR } from '../../common/languages/Dictionary';

// ------------------------------------------------------------

//moment.tz.setDefault('Etc/UTC');

const buttonView = (props) => {
  let [data, setData] = useState([]);
  let [load, setLoad] = useState(false);

  let language = props.language;
  let col = [
    {
      label: 'Id',
      key: 'id',
      style: { width: 70, paddingRight: 0, textAlign: 'left' },
      thConfig: true,
    },
    {
      label: language.STATUS,
      key: 'status',
      style: { width: 100, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.ACTION,
      key: 'detail',
      style: { width: 10, paddingRight: 5, paddingLeft: 5 },
      thConfig: true,
    },
    { label: language.DATE_CAD, key: 'dt_cad', style: { width: 100 } },
    {
      label: language.HOUR,
      key: 'dt_hr',
      style: { width: 80, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.CLOSING,
      key: 'dt_up',
      style: { width: 150, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.PROGRESS_,
      key: 'progress',
      style: { width: 10, paddingRight: 5, paddingLeft: 5 },
      thConfig: true,
    },
    {
      label: language.COMPANY,
      key: 'company',
      style: { width: 100, paddingRight: 0 },
    },
    {
      label: language.SUBJECT,
      key: 'subject',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.TYPE,
      key: 'tipo',
      style: { width: 100, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.USER,
      key: 'user',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.LAST_UPDATE,
      key: 'userac',
      style: { width: 150, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.TECH,
      key: 'nametech',
      style: { width: 150, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.TECH_GROUP,
      key: 'group_tech',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.CATEGORY,
      key: 'categoria',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.SUB_CATEGORY,
      key: 'subcategoria',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.THIRD_LEVEL,
      key: 'terceironivel',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.PROVIDER2,
      key: 'name_company_provider',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.CALLED_PROVIDER,
      key: 'ticket_for',
      style: { width: 50, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.COMMENT,
      key: 'comments',
      style: { width: 150, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.OBSERVATION,
      key: 'observation',
      style: { width: 150, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },
    {
      label: language.EXPIRY_DATE,
      key: 'sla',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.SLA_BROKEN_OUT,
      key: 'sla_broken',
      style: { width: 100, paddingRight: 0 },
      thConfig: true,
    },
  ];

  useEffect(() => {
    setLoad(true);
    let d = props.tickets.map((t) => {
      t.id_request = undefined;
      let notes = null;

      notes =
        t.suggestions && t.suggestions.length > 40
          ? t.suggestions.substr(0, 40)
          : t.suggestions;

      let notesObservation = null;

      notesObservation =
        t.observation && t.observation.length > 40
          ? t.observation.substr(0, 40)
          : t.observation;

      return {
        id:
          t.realid +
          ' ' +
          (typeof t.id_integration == 'string' ? t.id_integration : ' '),
        status: translateFromPtBR(t.status, language),
        detail:
          t.id_tickets > 0 ? (
            <div className="col-action">
              <div>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_DETAIL}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    onClick={() =>
                      props.openTicket({
                        id_tickets: t.id_tickets,
                        real_id: t.realid,
                        historico: true,
                      })
                    }
                    className="detailIcon pointer"
                    style={{ width: 25, height: 25 }}
                  />
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="col-action">
              <div>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_DETAIL}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    onClick={() => props.RequestView_(t)}
                    className="detailIcon pointer"
                    style={{ width: 25, height: 25 }}
                  />
                </Tooltip>
              </div>
            </div>
          ),
        dt_cad: t.dtcadrequest
          ? moment(t.dtcadrequest).format('DD/MM/YYYY')
          : moment(t.dtcadr).format('DD/MM/YYYY'),
        dt_hr: t.dtcadrequest
          ? moment(t.dtcadrequest).format('HH:mm')
          : moment(t.dtcadr).format('HH:mm'),

        dt_up:
          t.dt_resol &&
          t.dt_resol !== undefined &&
          t.dt_resol !== null &&
          (t.status_st == 'Resolved' ||
            t.status_st == 'Closed' ||
            t.status_st == 'Reject' ||
            t.status_st == 'Cancelled')
            ? moment(t.dt_resol).format('DD/MM/YY HH:mm')
            : ' ',

        progress: (
          <div className="d-flex align-items-center" style={{ width: 75 }}>
            <Progress
              current={t.progress}
              hiddenText
              background="var(--primary)"
              style={{ height: 7, width: '100%' }}
            />
            <span className="ml-2 status" style={{ fontSize: 10 }}>
              {t.progress}%
            </span>
          </div>
        ),
        dt_cad: t.dtcadrequest
          ? moment(t.dtcadrequest).format('DD/MM/YYYY')
          : moment(t.dtcadr).format('DD/MM/YYYY'),
        dt_hr: t.dtcadrequest
          ? moment(t.dtcadrequest).format('HH:mm')
          : moment(t.dtcadr).format('HH:mm'),

        dt_up:
          ((t.dt_resol && t.dt_resol !== undefined && t.dt_resol !== null) ||
            t.dt_closed) &&
          (t.status_st == 'Resolved' ||
            t.status_st == 'Closed' ||
            t.status_st == 'Reject' ||
            t.status_st == 'Cancelled')
            ? moment(t.dt_resol || t.dt_closed).format('DD/MM/YY HH:mm')
            : ' ',

        progress: (
          <div className="d-flex align-items-center" style={{ width: 75 }}>
            <Progress
              current={t.progress}
              hiddenText
              background="var(--primary)"
              style={{ height: 7, width: '100%' }}
            />
            <span className="ml-2 status" style={{ fontSize: 10 }}>
              {t.progress}%
            </span>
          </div>
        ),
        company: t.name_company,
        subject: t.subject,
        tipo: t.name_status_tickets,
        user: t.name_person,
        userac: t.usernameup,
        nametech: t.tech_start,
        group_tech: t.group_tech,
        categoria: t.nivel1,
        subcategoria: t.nivel2,
        terceironivel: t.nivel3,
        name_company_provider: t.name_company_provider,
        ticket_for: t.ticket_for,
        comments: (
          <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 15 }}>
                <strong>
                  {
                    <p
                      style={{ color: '#888080' }}
                      dangerouslySetInnerHTML={{ __html: t.suggestions }}
                    />
                  }
                </strong>
              </div>
            }
            position="right"
            theme="dark"
          >
            <span>
              <p
                style={{ color: '#888080' }}
                dangerouslySetInnerHTML={{ __html: notes }}
              />
            </span>
          </Tooltip>
        ),
        observation: (
          <p
            style={{ color: '#888080' }}
            dangerouslySetInnerHTML={{ __html: notesObservation }}
          />
        ),
        sla_next: t.sla_next ? moment(t.sla_next).format('DD/MM/YYYY') : '',
        sla_broken: t.sla_broken ? language.YES : language.NOT,
      };
    });

    setData(d);
    setLoad(false);
  }, [
    props.tickets.length > 0 ? props.tickets[0].total_count : 0,
    props.currentPage,
    props.tickets.length > 0 ? props.tickets[0].real_id : 0,
  ]);

  const cleanFilters = () => {
    props.callPageSelectFilter();
  };

  return (
    <MuiThemeProvider theme={props.theme}>
      <div style={{ marginTop: '-1.5rem' }} id="" className="dev">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {load == true ? (
              <div></div>
            ) : (
              <CommonTable
                data={data}
                col={col}
                search={false}
                onClearAllFilter={() => cleanFilters()}
                beginWithNoFilter={true}
                currentPage={props.currentPage}
                isAutomaticPagination={false}
                loadSearch={true}
                countTotal={props.totalTickets}
                callPageSelectFilter={(page, values, rowsPerPage, type) =>
                  props.callPageSelectFilter(page, values, rowsPerPage, type)
                }
                beforeBar={[
                  <RaisedButton
                    circleButton
                    icon="fas fa-file-export"
                    label={'Export Tickets'}
                    onClick={() => props.sendvalues(1)}
                    primary
                    style={{
                      fontSize: 15,
                      paddingLeft: 4,
                      marginRight: 5,
                    }}
                  />,
                  <RaisedButton
                    circleButton
                    icon="fas fa-download"
                    label={language.EXPORT_TASKS}
                    onClick={() => props.exportTasks()}
                    primary
                    style={{
                      fontSize: 15,
                      paddingLeft: 0,
                      marginRight: 5,
                    }}
                  />,
                  <Button
                    variant="circle"
                    icon="fas fa-search"
                    color="warning"
                    label={language.ADVANCED_SEARCH}
                    className="mr-3"
                    style={{ marginLeft: 5 }}
                    onClick={() => props.advancedSearchModalOpen_()}
                  />,
                ]}
                onSortItem={(item, sortByStatus) => {
                  props.sortBy(item,sortByStatus);

                }}
                index="TicketHistoric"
                width="120%"
                rowsPerPageOptions={[100, 250]}
                searchColumn
                orderColumn
              />
            )}
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
};

export default buttonView;
