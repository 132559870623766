import React from 'react';
import CommonPage from '@common/CommonPage';
import { observer } from 'mobx-react';
import { testRoute } from '@common/Func';
import io from 'socket.io-client';
import Constants from '../../data/Constants';
import Animate from '@common/Animate';
import Summary from '../monitor/Summary';
import Summarylink from '../monitor/Summarylink';
import DeviceConf from '../monitor/DeviceConf';
import MonitorPage from '../monitor/MonitorPage';
import InventoryListService from '../inventory/InventoryListService';
import GroupModal from '../monitor/GroupModal';
import Home from './Home/indexEdit';

const socket = columnName => eventName => (ioServers) => (value, deviceId) =>
  ioServers.emit(eventName, { [columnName]: value, deviceId }, console.log)
const socketService = socket('name')

@observer
export default class Monitor extends CommonPage {
  constructor() {
    super();
    // query: 'user=1&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOnsiaWQiOjEsInJvbGUiOiJjbGllbnQiLCJjb21wYW55Ijp7ImlkIjoxfX0sImRhdGUiOjE1NTg2NDgyODgsImV4cCI6MTU1ODY2Njc5OTk5OX0.TVr4W8kgMO79HUy0CaOnq656GpRdYBOh5RMByDZ-Rks'})
    this.state = {
      ioLinks: io.connect(Constants.AGENT_LINKS, {
        path: '/icmp',
        query:
          'user=' +
          this.store.getUserLoged().id +
          '&token=' +
          this.store.getToken(),
      }),
      ioServers: io.connect(Constants.AGENT_SERVERS, {
        path: '/server',
        query:
          'user=' +
          this.store.getUserLoged().id +
          '&token=' +
          this.store.getToken(),
      }),
      ioService: null,
      /*ioSNMP: io.connect(Constants.DISCOVERY, { 
        path: '/snmp',
        query:'user='+this.store.getUserLoged().id+'&token='+ this.store.getToken()  
      }),*/
      // ioAlertsLinks: io.connect(Constants.ALERT_LINKS, { path: '/icmp/alert' }),
      //  ioAlertsServers: io.connect(Constants.ALERT_SERVERS, { path: '/server/alert',
      // query:'user='+this.store.getUserLoged().id+'&token='+ this.store.getToken()  }),
      page: 'dash',
      company: '',
      groupDevices: [],
      itens: '',
      id_group: 0,
      id_device: 0,
      id_brands: 0,
    };
    this.handlePage = this.handlePage.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.onConfSubmit = this.onConfSubmit.bind(this);
    this.onGroupSubmit = this.onGroupSubmit.bind(this);
    this.onRestartAgent = this.onRestartAgent.bind(this);
    this.onDettachDevice = this.onDettachDevice.bind(this);
    this.setService = this.setService.bind(this);
    this.serviceCmd = this.serviceCmd.bind(this);
    this.serviceDiscovery = this.serviceDiscovery.bind(this);
    this.upGroup = this.upGroup.bind(this);
    this.openUpdateHandle = this.openUpdateHandle.bind(this);
    this.onUpdateItens = this.onUpdateItens.bind(this);
  }
  componentDidMount() {
    let { ioAlertsLinks, ioAlertsServers } = this.state;

    // ioAlertsLinks.on('alerts', (link) => this.store.pushAlert(link));
    // ioAlertsServers.on('alerts', (server) => this.store.pushAlert(server, true));
  }

  componentWillMount() {
    this.store.loading = false;
    // this.action.execute('get', this.APIEndpoints.MONITOR, 'monitor');
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id },
      null,
      null,
      null
    );
    //app.get("/api/monitordevicecon", (req, res, next) =>
    this.action.execute(
      'get',
      this.APIEndpoints.MONITOR + '/devicecon',
      'monitordevicecompany'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.MONITOR + '/companies',
      'companies'
    );
    this.action.execute('get', this.APIEndpoints.ITEM, 'items');
  }

  serviceDiscovery(id_device) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/services/discovery',
      '',
      { id_device: id_device }
    );
  }

  onUpdateItens(values) {
    this.upGroup(this.state.id_group, 'up', values);
  }

  setService(id, data) {
    this.action.execute(
      'put',
      this.APIEndpoints.MONITOR + '/services/' + id,
      'services',
      data,
      'id_rel_device_service'
    );
  }

  serviceCmd(cmd, service) {
    this.action.execute('post', this.APIEndpoints.MONITOR + '/services/', '', {
      cmd: cmd,
      service: service
    });
  }

  getUsers(device) {
  }

  onConfSubmit(conf, confTrigger, users, edit) {
   // console.log("")
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/device',
      'monitor',
      { conf: conf, confTrigger: confTrigger, users: users, edit: edit }
    ).then(c => {
      this.redirect2('/dash/gi')
      this.handlePage('dash');
    })
    //this.handlePage('dash');
  }

  openUpdateHandle(valor) {
    let values = {
      page: 'deviceModal',
      itens: valor,
      id_group: valor.id_group,
    };
    this.setState(values);
  }
  onClose() {
    this.setState({
      del: false,
      up: false,
      group: ''
    });
  }

  onDettachDevice(id_device) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/device/' + id_device,
      'monitor',
      {}
    );
  }

  upGroup(groupId, action, data) {
    if (action === 'del')
      this.action.execute(
        'delete',
        `${this.APIEndpoints.MONITOR}/group/${groupId}`,
        'monitorGroups',
        null
      );
    if (action === 'up')
      this.action.execute(
        'put',
        `${this.APIEndpoints.MONITOR}/group/${groupId}`,
        'monitorGroups',
        data
      );
    this.setState({ page: 'dash', company: '' });
    this.redirect2('/dash/monitor');
    this.handlePage('dash')
  }

  onRestartAgent(id_device) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/device/' + id_device + '/restart',
      '',
      {}
    );
  }

  handlePage(page, device) {
    this.store.loading = false;
    if (device && page == 'deviceConf' && device.fk_id_brands) {
      let brand = {
        id_brands: device.fk_id_brands,
        id_device: device.id_device,
      };
      this.action.execute(
        'post',
        this.APIEndpoints.SNMP + '/getbrandsid/',
        'oids',
        brand
      );
      this.setState({
        page: 'oid',
        id_device: device.id_device,
        id_brands: device.fk_id_brands,
      });

      //this.redirect2('/dash/snmp?id_brands='+device.fk_id_brands+'&id_device='+device.id_device)
    } else {
      let values = { page: page, device: device };
      if (page === 'services');
      else if (page === 'deviceConf' && device)
        if (page === 'groupDetail') values['company'] = device;
      if (page === 'monitor' && device) values['groupDevices'] = device;
      this.setState(values);
    }
  }

  onGroupSubmit(values) {
    this.action.execute(
      'post',
      `${this.APIEndpoints.MONITOR}/group`,
      'monitorGroups',
      values
    ).then(res => {
      this.redirect('/dash/gi')
    })
  }

  stopService = socketService('stop service')
  startService = socketService('start service')
  restartService = socketService('restart service')
  render() {
    let {
      language,
      danger,
      devices,
      monitor,
      services,
      companies,
      warning,
      monitordevicecompany
    } = this.store;
    let {
      monitorGroups,getAvailability
    } = this.props;
    let {
      page,
      device,
      company,
      groupDevices,
      ioAlertsLinks,
      ioAlertsServers,
      ioServers
    } = this.state;
    let devicesMonitor = groupDevices.map(gd => {
      let getDevice = monitor.find(m => m.id_device === gd.id_device);
      if (getDevice) gd = getDevice;
      return gd
    });


    let monitorGroups_ = [];
    if (this.store.getUserLoged().role == 'client') {
      monitorGroups_ = monitorGroups.map(g => {
        return {
          company: g.company,
          groups: g.groups
          , id_company: g.id_company,

          user_cad: this.store.getUserLoged().id
        }
      })
    } else {
      monitorGroups_ = monitorGroups.map(g => {
        return {
          company: g.company,
          groups: g.groups
          , id_company: g.id_company,
          user_cad: this.store.getUserLoged().id
        }
      })
    }

    let history = this.history2;
    let { state, pathname } = history.location;
    return (
      <div id="MonitorContainer">
        {
          (page === 'dash' || page === 'dashall') ? (
            <Animate>
              <Home
                language={language}
                all={page === 'dashall'}
                ioAlertsLinks={ioAlertsLinks}
                openUpdateHandle={this.openUpdateHandle}
                ioLinks={this.state.ioLinks}
                onDettachDevice={this.onDettachDevice}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                ioAlertsServers={ioAlertsServers}
                ioServers={this.state.ioServers}
                devices={devices}
                monitor={monitor}
                monitordevicecompany={monitordevicecompany}
                companies={companies}
                language={language}
                upGroup={this.upGroup}
                {...this.props}
                onRestartAgent={this.onRestartAgent}
                handlePage={this.handlePage}
                monitorGroups={monitorGroups_}
                onGroupSubmit={this.onGroupSubmit}
                getAvailability={getAvailability}
                danger={danger}
                warning={warning}
                ioSNMP={this.state.ioSNMP}
              />
            </Animate>
          )
            : page === 'deviceConf' ? (
              <DeviceConf
                monitordevicecompany={this.store.monitordevicecompany}
                key="deviceConf"
                language={language}
                handlePage={this.handlePage}
                onConfSubmit={this.onConfSubmit}
                devices={devicesMonitor}
                editConf={device}
                store={this.store}
                getUsers={this.getUsers}
              />
            )
              : page === 'deviceModal' ? (
                <GroupModal
                  monitordevicecompany={this.store.monitordevicecompany}
                  closeModal={() => this.handlePage('dash')}
                  companies={this.state.itens.companies}
                  comp={this.state.itens.comp}
                  company={this.state.itens.company}
                  group={this.state.itens.group}
                  devices={this.state.itens.devices}
                  user={this.store.getUserLoged()}
                  open={this.state.itens.open}
                  language={language}
                  onFormSubmit={this.onUpdateItens}
                  usercon_company={this.store.usercon_company
                  }
                />
              )
                : null
        }
      </div>
    );
  }
}