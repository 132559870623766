import React, {memo, Fragment} from 'react';
import classnames from 'classnames';
/*---------------------------common-------------------------------*/
import AppBar from '@common/AppBar2';
import List from '@common/List';
/*---------------------------material-ui-------------------------------*/
import {Card, Badge} from '@material-ui/core';
/*---------------------------components-------------------------------*/
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Store from '@data/Store';

const { language } = Store;

const ListItem = memo(({title, name, date, points, lost})=>(
	<Fragment>
		<i className='fas fa-check icon'/>
		<div className='ml-3 w-100'>
			<span className='title'>{title}</span>
			<div className='d-flex align-items-center justify-content-between'>
				<span className={classnames('action', {lost})}>
					{name} {lost ? language.LOST : language.RECEIVED} <span>{lost?'-':'+'}{points}pts</span>
				</span>
				<span className='date'>
					{date}
				</span>
			</div>
		</div>
	</Fragment>
))
export default function({data=[]}){
	/*
		-----------------------------------Formato-----------------------------------
		{
			title:'KR 2 Concluida',
			name:'Fábio Varriccio',
			date:'12/05/2020 as 18:20',
			points:5,
			lost:false
		},
	*/
	return(
		<Card id='AlertCenter' className='h-100'>
			<AppBar title={language.ALERT_CENTER}>
				<Badge badgeContent={data.length} color='secondary'>
					<ErrorOutlineIcon style={{color:'#A3A3A3'}}/>
				</Badge>
			</AppBar>

			<div className='content-medium pt-0'>
				<List
					mh={204}
					items={data.map((e,i)=>(
						{
							content:<ListItem {...e} key={i}/>
						}
					))}
				/>
			</div>
		</Card>
	)
}