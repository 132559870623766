export function Graph_1({ categories, data = [], language }) {
	return {
		chart: {
			height: 200,
			type: 'column'
		},
		title: null,
		xAxis: {
			categories,
			labels: {
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				}
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: language.VALUES,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			stackLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					color: 'var(--primary)',
					fontSize: 8,
				}
			},
			labels: {
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				},
				formatter() {
					return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
				},
			}
		},

		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			formatter() {
				return `${this.series.name}: ${this.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <br/>${language.TOTAL}: ${this.point.stackTotal}`;
			},
		},
		credits: { enabled: false },
		plotOptions: {
			column: {

				dataLabels: {
					enabled: true,
					formatter() {
						return this.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
					},
					style: {
						textOutline: 0,
						color: 'var(--primary)',
						fontSize: 8
					},
				}
			},
			series: {
				borderRadius: 3,

				point: {
					events: {
						click: (e) => {

						}
					}
				},

				marker: {
					lineColor: '#333'
				}
			},
		},
		legend: {
			itemStyle: {
				fontSize: 8,
				color: 'var(--primary)'
			},
			enabled: false
		},
		series: [
			{
				name: language.FORESEEN,
				color: 'var(--primary)',
				data: data[0]
			},
			{
				name: language.REALIZED,
				color: 'var(--success)',
				data: data[1]
			}
		]
	}
}
export function Graph_2({ foreseen = 0, realized = 0, language }) {
	return {
		colors: ['#F0B02B', '#33ACA0'],
		chart: {
			type: 'column',
			inverted: true,
			polar: true,
			height: 220
		},
		title: null,
		tooltip: {
			outside: true,
			formatter() {
				return `
						${this.point.name} <br/>
						${this.series.name}: ${this.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
					`;
			},
		},
		pane: {
			size: '80%',
			innerSize: '50%',
			endAngle: 270
		},
		xAxis: {
			tickInterval: 1,
			labels: {
				align: 'right',
				useHTML: true,
				allowOverlap: true,
				step: 1,
				y: 3,
				style: {
					color: '#BABABA',
					fontSize: '8px'
				}
			},
			lineWidth: 0,
			categories: [
				`${language.TOTAL} ${language.FORESEEN}`,
				`${language.TOTAL} ${language.REALIZED}`
			]
		},
		yAxis: {
			min: 0,
			labels: {
				formatter() {
					return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
				},
				style: {
					color: '#BABABA',
					fontSize: '8px'
				}
			},
			crosshair: {
				enabled: true,
				color: '#333'
			},
			lineWidth: 0,
			tickInterval: 5,
			reversedStacks: false,
			endOnTick: true,
			showLastLabel: true
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0.15
			}
		},
		credits: { enabled: false },
		legend: { enabled: false },
		series: [{
			name: language.VALUE,
			data: [
				{ name: `${language.TOTAL} ${language.REALIZED}`, y: realized, color: '#33ACA0' },
				{ name: `${language.TOTAL} ${language.FORESEEN}`, y: foreseen, color: '#F0B02B' }
			]
		}]
	}
}
export function Graph_3({ capex = 0, opex = 0, language }) {
	return {
		colors: ['#F0B02B', '#33ACA0'],
		chart: {
			type: 'column',
			inverted: true,
			polar: true,
			height: 220
		},
		title: null,
		tooltip: {
			outside: true,
			formatter() {
				return `
						${this.point.name} <br/>
						${this.series.name}: ${this.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
					`;
			},
		},
		pane: {
			size: '80%',
			innerSize: '50%',
			endAngle: 270
		},
		xAxis: {
			tickInterval: 1,
			labels: {
				align: 'right',
				useHTML: true,
				allowOverlap: true,
				step: 1,
				y: 3,
				style: {
					color: '#BABABA',
					fontSize: '8px'
				}
			},
			lineWidth: 0,
			categories: [
				'Capex',
				'Opex',
			]
		},
		yAxis: {
			min: 0,
			labels: {
				formatter() {
					return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
				},
				style: {
					color: '#BABABA',
					fontSize: '8px'
				}
			},
			crosshair: {
				enabled: true,
				color: '#333'
			},
			lineWidth: 0,
			tickInterval: 5,
			reversedStacks: false,
			endOnTick: true,
			showLastLabel: true
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0.15
			}
		},
		credits: { enabled: false },
		legend: { enabled: false },
		series: [{
			name: language.VALUE,
			data: [
				{ name: 'capex', y: capex, color: '#33ACA0' },
				{ name: 'opex', y: opex, color: '#F0B02B' }
			]
		}]
	}
}
