import React, { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

import CommonForm from '@common/CommonForm';
import Dialog from '@common/Dialog';
import Store from '@data/Store';

import {
  addChannelService,
  editChannelService,
  getChannelByIdService,
} from '../Channel.services';

// ----------------------------------------------------------------------

export const ChannelAddOrEdit = ({ closeModal, editChannel, page }) => {
  const { language } = Store;
  const [values, setValues] = useState({});

  const submitForm = async (fields) => {
    const values = {
      is_attachment_required: false,
      ...fields
    };

    page === 'add'
      ? await addChannelService({ values }).then(() => {
          closeModal();
        })
      : await editChannelService({ id: editChannel.id_channel, values }).then(
          () => {
            closeModal();
          }
        );
  };

  useEffect(() => {
    page === 'edit' && setValues(editChannel);
  }, []);

  return (
    <Dialog
      title={
        page === 'add'
          ? `${language.ADD} ${language.CHANNEL}`
          : `${language.EDITION} ${language.CHANNEL} ${values.name}`
      }
      modal={false}
      open
      onClose={closeModal}
    >
      <CommonForm
        language={language}
        fields={[
          {
            span: 3,
            name: 'name',
            label: language.NAME,
            type: 'text',
            required: true,
          },
          {
            span: 3,
            name: 'is_attachment_required',
            label: `${language.ATTACHMENT} ${language.REQUIRED}`,
            type: 'check',
          },
        ]}
        values={values}
        onFormSubmit={submitForm}
        button={{
          md: 12,
          label: language.SAVE,
          color: 'success',
        }}
      />
    </Dialog>
  );
};

export default ChannelAddOrEdit;
