import Dialog from '@common/Dialog';
import { Card } from '@material-ui/core';
import React from 'react';
import CommonForm from '../../common/CommonForm2';
import { Constants, Store } from '../../data';
import GenericActions from '../../data/Actions';

const PasswordQuestionForm = ({ data, onChangePageType, onAlert }) => {
  const { language } = Store;
  const isEdit = data ? true : false;
  const dataValues = { lang: '', question_text: '', ...data };

  const handleFormSubtmit = (formValues) => {
    const id = isEdit ? data.id_password_question : '';
    const method = isEdit ? 'put' : 'post';
    const url = `${Constants.APIEndpoints.PASSWORD_QUESTIONS}/${id}`;
    const payload = {
      question_text: formValues.question_text,
      lang: formValues.lang,
    };
    const fetch = new GenericActions();

    fetch
      .execute(method, url, null, payload)
      .then((response) => {
        onAlert(language.REGISTER_SAVE);
        handleChangePageType({ reloadId: new Date().getTime() });
      })
      .catch((error) => {
        onAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const handleChangePageType = (reload) => {
    onChangePageType({ type: 'list', ...reload });
  };

  const fields = [
    {
      col: 4,
      name: 'lang',
      label: language.LANGUAGE,
      type: 'select',
      options: Object.keys(language.LANG).map((flagKey) => ({
        value: flagKey.toLocaleLowerCase(),
        label: language.LANG[flagKey],
      })),
      required: true,
      disabled: isEdit,
    },
    {
      col: 8,
      type: 'element',
      content: <></>,
    },
    {
      col: 8,
      name: 'question_text',
      label: language.ASK,
      type: 'text',
      required: true,
    },
  ];

  const saveButton = {
    md: 2,
    primary: true,
    offset: { md: 10 },
    label: language.SAVE,
    style: { marginBottom: 10, float: 'right' },
  };

  return (
    <div>
      <Dialog
        open={true}
        maxWidth="xl"
        title={`${language.NEW} ${language.ASK}`}
        onClose={() => handleChangePageType()}
      >
        <Card className="content">
          <CommonForm
            fields={fields}
            values={dataValues}
            onSubmit={handleFormSubtmit}
            button={saveButton}
          />
        </Card>
      </Dialog>
    </div>
  );
};

export default PasswordQuestionForm;
