import React, {Fragment, memo} from 'react';
/*------------------------------common-------------------------------------*/
import CommonForm from '@common/CommonForm2';
/*------------------------------material-ui-------------------------------------*/

import Store from '@data/Store';

const { language } = Store;

export default memo(({id_project_activity, fk_id_project, onSubmit, values, options,project})=>{

   let activitiesOptions = []
	options.activities_predecessor(e => e.id_project_activity != id_project_activity).map((options) => {
		let act = { value : options.value, label : options.value + ' - ' + options.label}
		activitiesOptions.push(act)
	})
	
	return(
		<div className='PredecessorActivitiesForm mb-4'>
			{/*<span className='label-items mb-4 d-block'>Atividades Antecessoras</span>*/}
			<CommonForm
            values={values}
            fixedValue={{
               id_project_activity,
               fk_id_project
            }}
				fields={[
					{
						col:12,
                  type:'select',
                  isMulti:true,
						name:'activityprd',
						label: language.PREDECESSOR_ACTIVITIES,
						options: activitiesOptions
					}
				]}
				onSubmit={project.pause_project == false ? onSubmit : ''}
				button={{
					style:{
						marginTop:-62
					}
				}}
			/>
		</div>
	)
})
