import React from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import ProjectManagementPage from './ProjectManagementPage';
import ProjectManagementList from './ProjectManagementList';
import ProjectManagementPrioritizationList from './ProjectManagementPrioritization/ProjectManagementPrioritizationList';
import ProjectManagementPrioritization from './ProjectManagementPrioritization/ProjectManagementPrioritization';
import PagePMO from './PagePMO';
import ProjectCalendar from './ProjectCalendar';
import { testRoute, filterObj } from '@common/Func';
import Animate from '@common/Animate';
import ProjectForm from './ProjectForm';
import DownloadFiles from '../../util/DownloadFiles';

@observer
export default class ProjectManagementContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: '',
      values: '',
      valueOut: '',
      idMilestone: '',
      stage: '',
      open: false,
      valueStatus: '',
      openModalActivity: null,
      route: '',
      emp: 0,
      gestor: 0,
      projectSelect: null,
      valuesActivity: '',
      activeCalendar: null,
      ticket: null,
      stepIndex: 0,
      files: [],
      disabled: true,
      fileError: '',
      projectdash: null,
      pmoactivity: 1,
      modalDetail: false,
      loadingOpenModal: null,
      valuesFilter: [],
    };

    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitAcitivityDesc = this.onFormSubmitAcitivityDesc.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.onFormSubmitActivityStatus =
      this.onFormSubmitActivityStatus.bind(this);

    this.onFormSubmitComments = this.onFormSubmitComments.bind(this);

    this.handleModal = this.handleModal.bind(this);
    this.handleValues = this.handleValues.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.onFormSubmitActivityCalendar =
      this.onFormSubmitActivityCalendar.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onDrops = this.onDrops.bind(this);
    this.UpdateFiles = this.UpdateFiles.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.ondeletefile = this.ondeletefile.bind(this);
    this.onFormSubmitClose = this.onFormSubmitClose.bind(this);
    this.handleActivity = this.handleActivity.bind(this);
    this.UpPrioritization = this.UpPrioritization.bind(this);
    this.searchPmo = this.searchPmo.bind(this);
    this.getTickets = this.getTickets.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
  }
  handleNext(p) {
    this.setState({ stepIndex: 1 });

    let pr = this.store.projects.filter((x) => x.id_project == parseInt(p));
    // this.redirect2('/dash/gp/list/form', filterObj(pr[0]));
    this.handlePage('form', pr[0]);
  }

  ondonwFile(id, file) {
    DownloadFiles(
      this.APIEndpoints.REQUESTS + '/downloadgp/' + id + '&' + file,
      file
    );
  }

  UpdateFiles(ac) {
    Array.from(ac).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then(
        (result) => {
          let upload = {
            id_project_activity: ac.id_project_activity,
            deleted: false,
            file: result,
            type: file.type,
            namefile: file.name,
            fk_id_company: 1,
          };
          this.action.execute(
            'post',
            this.APIEndpoints.PROJECT + '/upload',
            '',
            upload
          );

          this.state.files = [];
          //setTimeout(function() {   this.redirect('/tech/tickets/detail', { ticket: this.props.location.state.ticket}) }.bind(this), 1000);
        },
        (err) => {}
      );
    });
  }

  onDrops(files) {
    this.setState({
      files: files,
    });
  }
  removeFile(item) {
    var f = this.state.files.filter(function (f) {
      return f.name != item.name;
    });
    this.setState({ files: f });
  }
  valuesset() {
    this.setState({ disabled: !this.state.disabled });
  }
  componentDidUpdate(prevProps) {
    try {
      if (prevProps.location.state.route != this.props.location.state.route) {
        this.setState({ route: this.props.location.state.route });
      }
    } catch (e) {}
  }
  componentWillMount() {
    this.store.projects = [];
    let { state = {} } = this.props.location;
    if (state && state.ticket != undefined) {
      this.handlePage('new-project');
    }
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectlist',
      'pmo',
      null,
      null,
      null
    );

    try {
      this.action.execute(
        'get',
        this.APIEndpoints.PROJECT + '/pmoList',
        'pmoList'
      );
      this.setState({ route: this.props.location.state.route });
    } catch (e) {}
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id },
      null,
      null,
      null
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/calendaractivity/12',
      'calendar'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/calendaractivityUser',
      'calendarUser'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/getDetailAll',
      'statusProject'
    );

    this.action.execute('get', this.APIEndpoints.CONTRACT + 'low', 'contracts');

    this.action.execute('get', this.APIEndpoints.USER + 'prd', 'usersproject');
    ///api/project/projectlist

    this.action.execute('get', this.APIEndpoints.PROJECT, 'projects');

    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/listMilestoneSt',
      'milestone',
      {},
      undefined,
      undefined,
      false
    );
    if (!state.from_demandmanagement) {
      this.action.execute(
        'get',
        this.APIEndpoints.PROJECT + '/activityall',
        'activity'
      );
    }
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/prioritization',
      'prioritization'
    );

    if (state.byBsc && state.fk_id_project) {
      this.redirect2('/dash/gp/list/detail', {
        id_project: state.fk_id_project,
        byBsc: true,
      });
    }

    if (state.panel && state.id_project) {
      this.redirect2('/dash/gp/list/detail', {
        id_project: state.id_project,
        panel: true,
      });
    }
  }

  saveActivity(values) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.PROJECT + '/activity',
        'activity',
        values
      )
      .then((e) => {
        this.action.execute(
          'post',
          this.APIEndpoints.PROJECT + '/projectlist',
          'pmo',
          { idproject: values.fk_id_project }
        );
      });
  }

  searchPmo(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectlist',
      'pmo',
      values,
      null,
      null
    );
    this.setState({ valuesFilter: values });
  }

  handleValues(values, description) {
    if (description === 'client') {
      this.setState({ emp: values });
    } else if (description === 'gestor') {
      this.setState({ gestor: values });
    } else if (description == '') {
      if (values == 1) {
      } else {
      }
    } else {
    }
  }
  handleRedirect(label, value) {
    if (value != '' && value != null) {
      this.redirect2('/dash/gp/list', {
        labelType: label,
        projectSelect: value,
      });
      //this.setState({ page: 'list', projectSelect: value, route: '' });
    }
  }
  toggleModalDetail = (open, values = null) => {
    var modalDetail = open;

    if (open) {
      this.setState({ loadingOpenModal: values });
      this.action
        .execute(
          'get',
          this.APIEndpoints.PROJECT + '/activity/' + values,
          'activity'
        )
        .then((e) => {
          this.setState({ loadingOpenModal: null });
          this.setState({ modalDetail });
        });
    } else {
      this.setState({ modalDetail });
      if (testRoute('/dash/gp/pmo', this.history2.location.pathname)) {
        this.action.execute(
          'get',
          this.APIEndpoints.PROJECT + '/activityall',
          'activity'
        );
      } else {
        this.action.execute(
          'get',
          this.APIEndpoints.PROJECT + '/activityallPro/' + values,
          'activity'
        );
      }
    }
  };

  handlePage(page, values, valuesDetail) {
    try {
      if (valuesDetail.name != undefined) {
        this.setState({ projectSelect: valuesDetail });
      }
    } catch (e) {}

    if (page == 'dash') {
      this.action.execute(
        'post',
        this.APIEndpoints.PROJECT + '/listMilestoneSt',
        'milestone',
        {},
        undefined,
        undefined,
        false
      );
      this.setState({ stepIndex: 0 });
    }

    this.setState({ page, values });
  }

  handleModal(value) {
    this.setState({ openModalActivity: value });
  }
  handleActivity(id_project) {
    this.redirect('/dash/gp/list/detail', {
      id_project: id_project,
      projectSelect: filterObj({ id_project: id_project }),
    });
  }
  onFormSubmitActivityCalendar(values) {
    this.action.execute(
      'post',
      `${this.APIEndpoints.PROJECT}/activityCalendar`,
      'calendar',
      values,
      'id_project_activity'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/calendaractivityUser',
      'calendarUser'
    );

    setTimeout(
      function () {
        this.setState({ route: 'calendar' });
      }.bind(this),
      1000
    );
  }

  onFormSubmit(values, company) {
    //console.log(values)
    this.action
      .execute(
        'post',
        `${this.APIEndpoints.PROJECT}`,
        null,
        values,
        'id_project'
      )
      .then((data) => {});
    setTimeout(() => {
      this.redirect('/dash/gp/list');
    }, 2000);
  }
  onFormSubmitClose(values) {
    values.status = 'close';

    this.action
      .execute(
        'post',
        `${this.APIEndpoints.PROJECT}`,
        'proj',
        values,
        'id_project'
      )
      .then((e) => {
        this.action.execute('get', this.APIEndpoints.PROJECT, 'projects');
        this.redirect2('/dash/gp/list');
      });
  }

  onDrop(data, stageId, project) {
    var { pmo } = this.store;
    let values = {
      fk_id_milestone: data,
      status: stageId,
      description: ' ',
      idproject: project,
    };
    this.action
      .execute(
        'post',
        this.APIEndpoints.PROJECT + '/createStMil',
        '',
        values,
        'id_project'
      )
      .then((e) => {
        this.action.execute(
          'post',
          this.APIEndpoints.PROJECT + '/projectlist',
          'pmo',
          { idproject: project },
          null,
          null
        );
      });
  }
  closeOndrop = () => {
    this.setState({ open: false });
  };

  onFormSubmitComments(values) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.PROJECT + '/ReplyProject',
        '',
        values,
        'id_project'
      )
      .then((e) => {
        this.action.execute(
          'post',
          this.APIEndpoints.PROJECT + '/projectlist',
          'pmo',
          { idproject: values.fk_id_project },
          null,
          null
        );
      });
  }

  onFormSubmitActivityStatus(valueStatus) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.PROJECT + '/activityStatus',
        'milestone',
        valueStatus,
        'id_project'
      )
      .then((e) => {
        this.action.execute(
          'post',
          this.APIEndpoints.PROJECT + '/projectlist',
          'pmo',
          { idproject: valueStatus.id_project },
          null,
          null
        );
      })
      .catch((err) => {
        if (err.data.code == 'E019') {
          this.store.toggleAlert(
            true,
            'Conclua as atividades antecessoras à esta',
            'error'
          );
        }
      });
  }

  ondeletefile(id) {
    this.action.execute(
      'get',
      this.APIEndpoints.REQUESTS +
        '/deletefileproject/' +
        id.id_project_activity +
        '&' +
        id.id_gp_file +
        '',
      ''
    );
  }
  onFormSubmitAcitivityDesc(valueOut) {
    let data = {
      fk_id_milestone: this.state.idMilestone,
      status: this.state.stage,
      description: valueOut.observacao,
    };

    this.action
      .execute(
        'post',
        this.APIEndpoints.PROJECT + '/createStMil',
        'milestone',
        data,
        'id_project'
      )
      .then((e) => {
        // this.redirect2('/dash/gp/list');
      });

    // this.setState({ page: 'dash' });
    this.setState({ open: false });
  }

  updateProject(idproject, col, value) {
    let values = { col: col, id_project: idproject, value: value };
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectpr',
      'projects',
      values,
      'id_project'
    );
  }
  UpPrioritization(idscale, col, value) {
    let values = { col: col, id_scale: idscale, value: value };
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectprioritization',
      'prioritization',
      values,
      'id_project'
    );
  }

  getTickets(idcontract) {
    this.action.execute(
      'post',
      this.APIEndpoints.REQUESTS + '/api/ticketchange',
      'tickets',
      { id_contract: idcontract }
    );
  }

  render() {
    let {
      language,
      users,
      companies,
      projects,
      milestone,
      activity,
      listmilestoneActivity,
      statusProject,
      pmo,
      pmoList,
      calendar,
      calendarUser,
      activities_project,
      groups,
      analyst,
      usersproject,
      subActivities,
    } = this.store;
    let {
      page,

      valueOut,
      open,
      valueStatus,
      openModalActivity,
      route,

      valuesActivity,

      stepIndex,
      disabled,
      files,
      fileup,
    } = this.state;

    var ticket;
    var projectSelect;
    var values;

    let history = this.history2;
    let redirect = this.redirect2;
    let { pathname } = history.location;
    let { state } = this.props.location;

    if (state && state.ticket != undefined) {
      ticket = state.ticket;
      //   this.getTickets(ticket.fk_id_contract)
    }

    if (state && testRoute(/\/dash\/gp\/list\/(edit)/, pathname)) {
      values = state;
    } else if (
      state &&
      testRoute(/\/dash\/gp\/list\/(edit|detail)/, pathname)
    ) {
      if (state.id_project) {
        values = state.id_project;
      } else {
        values = state;
      }
    } else if (
      !state &&
      testRoute(/\/dash\/gp\/list\/(edit|detail)/, pathname)
    ) {
      history.goBack();
      return null;
    }

    if ((state || {}).byBsc && !pmo) {
      return null;
    } else if ((state || {}).panel && !pmo) {
      return null;
    }

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        {testRoute('/dash/gp/prioritization', pathname) && (
          <Animate>
            <ProjectManagementPrioritization
              redirect={redirect}
              language={language}
              prioritization={this.store.prioritization}
              projects={this.store.projects}
            />
          </Animate>
        )}
        {testRoute('/dash/gp/prioritization/list', pathname) && (
          <Animate>
            <ProjectManagementPrioritizationList
              projects={this.store.projects}
              redirect={redirect}
              prioritization={this.store.prioritization}
              UpPrioritization={this.UpPrioritization}
              updateProject={this.updateProject}
              language={language}
            />
          </Animate>
        )}
        {this.store.pmo && testRoute('/dash/gp/pmo', pathname) && (
          <Animate>
            <PagePMO
              groups={groups}
              redirect={redirect}
              analyst={analyst}
              activity={activity}
              usercon_company={this.store.usercon_company}
              statusProject={statusProject}
              handleRedirect={this.handleRedirect}
              language={language}
              filterObj={filterObj}
              searchPmo={this.searchPmo}
              projects={pmo}
              pmoactivitylist={this.store.pmoactivity}
              pmoactivity={this.state.pmoactivity}
              {...this.state}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              companies={companies}
              users={users}
              handleValues={this.handleValues}
              milestone={milestone}
              toggleModalDetail={this.toggleModalDetail}
              loadingOpenModal={this.state.loadingOpenModal}
            />
          </Animate>
        )}
        {testRoute('/dash/gp/calendar', pathname) && (
          <Animate>
            <ProjectCalendar
              pmoList={pmoList}
              filterObj={filterObj}
              redirect={redirect}
              onFormSubmitActivityCalendar={this.onFormSubmitActivityCalendar}
              onFormSubmit={this.onFormSubmit}
              usercon_company={this.store.usercon_company}
              activity={activity}
              users={users}
              companies={companies}
              projects={projects}
              handleActivity={this.handleActivity}
              project={values}
              milestone={milestone}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              language={language}
              calendarUser={calendarUser}
              calendar={calendar}
              handleRedirect={this.handleRedirect}
              handleValues={this.handleValues}
            />
          </Animate>
        )}
        {pmo && testRoute(['/dash/gp', '/dash/gp/list'], pathname) && (
          <Animate>
            <ProjectManagementList
              projectSelect={projectSelect}
              filterObj={filterObj}
              statusProject={statusProject}
              projects={projects}
              valuesFilter={this.state.valuesFilter}
              state={state}
              handleValues={this.handleValues}
              searchPmo={this.searchPmo}
              companies={companies}
              departments={this.store.departments}
              pmo={pmo}
              usercon_company={this.store.usercon_company}
              redirect={redirect}
              emp={this.state.emp}
              users={users}
              gestor={this.state.gestor}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              handlePage={this.handlePage}
              language={language}
              milestone={milestone}
            />
          </Animate>
        )}
        {this.store.pmo && testRoute('/dash/gp/list/detail', pathname) && (
          <Animate>
            {
              <ProjectManagementPage
                idroutac={state.id_ac}
                toggleModalDetail={this.toggleModalDetail}
                modalDetail={this.state.modalDetail}
                onFormSubmitComments={this.onFormSubmitComments}
                ondonwFile={this.ondonwFile}
                ondeletefile={this.ondeletefile}
                UpdateFiles={this.UpdateFiles}
                closeOndrop={this.closeOndrop}
                valuesset={this.valuesset}
                filterObj={filterObj}
                fileup={fileup}
                redirect={redirect}
                usercon_company={this.store.usercon_company}
                files={files}
                saveActivity={this.saveActivity}
                projectSelect={projectSelect}
                removeFile={this.removeFile}
                disabled={disabled}
                onDrops={this.onDrops}
                handleNext={this.handleNext}
                handlePage={this.handlePage}
                onFormSubmitActivityStatus={this.onFormSubmitActivityStatus}
                valueStatus={valueStatus}
                page={page}
                openModalActivity={openModalActivity}
                activity={activity}
                listmilestoneActivity={listmilestoneActivity}
                open={open}
                pmo={pmo}
                onDrop={this.onDrop}
                values={values}
                valueOut={valueOut}
                milestone={milestone}
                subActivities={subActivities}
                onFormSubmitAcitivityDesc={this.onFormSubmitAcitivityDesc}
                language={language}
                state={state}
                users={users}
                loadingOpenModal={this.state.loadingOpenModal}
              />
            }
          </Animate>
        )}
        {['new-project', 'edit-project'].includes(page) && (
          <ProjectForm
            language={language}
            ticketparam={ticket}
            users={users}
            companies={this.store.companies}
            contracts={this.store.contracts}
            usercon_company={this.store.usercon_company}
            values={
              ticket == undefined
                ? this.state.values
                : {
                    fk_id_company: ticket.fk_id_company,
                    fk_id_contract: ticket.fk_id_contract,
                    tickets: [ticket.id_tickets],
                  }
            }
            store={this.store}
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            onFormSubmit={this.onFormSubmit}
            open={true}
            onClose={() => this.handlePage('list')}
          />
        )}
      </CommonPage>
    );
  }
}
