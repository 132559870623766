import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '@common/CommonPage';
import GeneralParametersConfig from './GeneralParametersConfig';
import Animate from '@common/Animate';
import crypt from '../../util/crypt';
@observer
export default class GeneralParametersContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      values: null,
      general_params: [],
      channels: []
    };
  }
  handlePage = (page, values = null) => {
    this.setState({ page, values });
  };
  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');

    this.action.execute(
      'get',
      this.APIRoot + '/api/generalparameters',
      'general_parameters'
    );
    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, 'general_params')
      .then((result) => {
        this.setState({ general_params: result.data });
      })
      .catch((error) => {
        console.log(error);
      });

    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/120',
      'channel'

    ).then((item) => {

    this.setState({channels: item.data});

    });
  }
  onFormSubmit = (e) => {
    this.action
      .execute(
        'put',
        this.APIRoot + '/api/generalparameters',
        'general_parameters',
        e
      )
      .then((g) => {
        this.action
          .execute('get', this.APIEndpoints.TICKET + '/params', '')
          .then((b) => {
            sessionStorage.setItem('params', crypt(JSON.stringify(b.data)));
          });
        this.setState({ general_params: g.data[0] });
      });
  };

  onFormCalls = (e) => {
    this.action
      .execute('put', this.APIEndpoints.GENERAL_PARAMETERS, 'general_params', e)
      .then((result) => {});
  };

  render() {
    let { language, companies, general_parameters } = this.store;
    let { page, general_params, channels } = this.state;
    // -----------------------------CONFIGRATION PROPS------------------------------------------------
    let { configurationProps } = this.props;
    let CP = !configurationProps ? CommonPage : 'div';
    return (
      <Fragment>
        <Animate active={!configurationProps}>
          <GeneralParametersConfig
            language={language}
            configurationProps={configurationProps}
            redirect={this.redirect2}
            general_parameters={general_params}
            onFormSubmit={this.onFormSubmit}
            onFormCalls={this.onFormCalls}
            channels={channels}
            params={general_params}
          />
        </Animate>
      </Fragment>
    );
  }
}
