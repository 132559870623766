import React, {memo} from 'react';
import {Avatar} from '@material-ui/core';
import { Tooltip } from 'react-tippy';

export default memo(({data=[], maxItem=3, star=true, ...other})=>{
   
	return(
		<div {...other} className={'list-avatar px-0 py-2 ml-1 '+other.className}>
			{data.slice(0,maxItem).map((e, i)=>(
				<Tooltip
				  html={
				    <div style={{ color: '#fff', fontSize: 11 }}>
				      {e.name}
				    </div>
				  }
				  position="right"
				  arrow
				>
               <div className='content-avatar'>
                  <Avatar key={e.name+'-'+i} src={e.src} className='avatar'>
										{e.name ? e.name.slice(0,2) : ""}
                  </Avatar>
                  {i==0&&star?<i className='fas fa-star'/>:null}
               </div>
				</Tooltip>
			))}
			
			{data.length>maxItem&&
				<Tooltip
				  html={
				    <div style={{ color: '#fff', fontSize: 11 }}>
				      {data.slice(maxItem).map((u, i) => (
				        <div
				          style={{ marginLeft: '5px', marginTop: '2px' }}
				          className="d-flex align-items-center"
				          key={u.name+'-'+i}
				        >
				          <Avatar
				          	src={u.src}
				            className={'avatar'}
				            key={i}
				            style={{
				              fontSize: '10px',
				              width: '17px',
				              height: '17px',
				              
				            }}
				          >
				            {u.name.slice(0,2)}
				          </Avatar>
				          <span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
				        </div>
				      ))}
				    </div>
				  }
				  position="right"
				  theme="light"
				  interactive
				  arrow
				>
				  
				    <span className='more'>+{data.length-maxItem}</span>
				  
				</Tooltip>
			}
		</div>
	)
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
})