import React, { useState, useEffect, useContext ,Fragment} from 'react';
import { Row } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactHighcharts from 'react-highcharts';
import {Card, Grid, Checkbox, TextField, MenuItem} from '@material-ui/core';
import {percentualIc} from './Graph';
import List from '@common/List';
import PieChart from '@common/PieChart';
import classnames from 'classnames';
import Constants from '@data/Constants';

const tenant = Constants.Tenant;

export default function ({  alerts,companyId ,findAlerts, listdevice }) {
 
   let  [listICs, setlistICs]=useState([]);
      let  [giWarning, setgiWarning]=useState([]);
      let  [giDanger, setgiDanger]=useState([]);
      let  [countRed, setcountRed]=useState([]);
      let  [countYellow, setcountYellow]=useState([]);
      let  [countOk, setcountOk]=useState([]);
      let  [countTotal, setcountTotal]=useState([]);
      let  [countRedList, setcountRedList]=useState([]);
      let  [countYellowList, setcountYellowList]=useState([]);
      let  [countOkList, setcountOkList]=useState([]);
      let  [graficPercent, setgraficPercent]=useState([0,0,0]);
      let  [c, setc]=useState(0);
      const graficPizza=(ok, warning, danger)=> {
       
    
        let total =  warning + danger;
        
        setgraficPercent([(warning /total)*100, (danger/total)*100])
      }
    
  useEffect(() => {
let cont=c;
     alerts.on(`${tenant}_alerts`, (data) => {
      cont++
     if(cont==30||cont==1){
       if(cont==30){
         setc(0)
       }
      //  console.log(data.alertsall[1],"dashhhhhhboardddd")
       setcountOk(data.alertsall.countOk)
        setcountYellow(data.alertsall.countYellow)
      
       setcountTotal(data.alertsall.countTotal)

      setcountRed(data.alertsall.countRed);
      

       

       graficPizza(
          data.alertsall.countOk.length,
          data.alertsall.countYellow.filter(c=>c.id_company >0).length,
          data.alertsall.countRed.filter(c=>c.id_company >0).length
        )
      }
      })
  



  },[alerts,companyId])
 
 
return <Grid container spacing={2}>
<Grid item xs={6}>
  <ReactHighcharts config={percentualIc(...graficPercent)}/>
</Grid>
<Grid  onClick={()=>findAlerts(countYellow,countRed)}   item xs={6}>
  <List
    className='list-category-items'
    items={[
     // {value: countOk.filter(h=>companyId>0?h.id_company == companyId: h.id_company>0).length, label:'Ok', color:'var(--success)'},
      {value: countYellow.filter(h=>companyId>0?h.id_company == companyId: h.id_company>0 &&
        
        listdevice != null ? listdevice.includes(h.id_device) ==true : h.id_device>0
        
        ).length, label:'Warning', color:'var(--warning)'},
      {value:countRed.filter(h=>companyId>0?h.id_company == companyId: h.id_company>0
        &&  listdevice != null ? listdevice.includes(h.id_device) ==true : h.id_device>0
        
        ).length, label:'Danger', color:'var(--danger)'},
      //{value:countTotal.filter(h=>h.idcompany == companyId).length, label:'Total', color:'#A3A3A3'},
       
    ].map((e,i)=>(
      {
        className:classnames('d-flex align-items-center justify-content-between items', {Total:i==3}),
        content:(
          <Fragment>
            <div className='d-flex align-items-center content-label'>
              {i!=3&&<div className='mr-2' style={{backgroundColor:e.color}}/>}
              <span style={{color:e.color}}>{e.label}</span>
            </div>
            <span className='value'>{e.value}</span>
          </Fragment>
        )
      }
    ))}
  />
</Grid>
</Grid>
}