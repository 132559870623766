import React from 'react';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Progress from '@common/Progress';
import { Card, Avatar } from '@material-ui/core';
import './ProjectPortfolio.css';
import Dialog from '@common/Dialog';
export default function (props) {
	let { language, projects } = props;

	let riskColor = ['var(--success)', 'var(--warning)',
		'var(--danger)'];
	//let riskType = ['Aceitável', 'Crítico','Intolerável'];
	let riskType = ['Baixo', 'Médio', 'Alto'];
	let data =
		projects.map(g => (

			{
				ConfigCell: {
					className: 'tr-principal',

				},
				'#': (
					<Button
						icon='fas fa-caret-right'
						variant='circle'
						outlined
						style={{ border: 'none' }}
					/>
				),
				project: g.name,
				progress: (
					<div className='d-flex align-items-center' style={{ width: 80 }}>
						<Progress hiddenText className='w-100' style={{ height: 6 }} current={g.statusproject.percentConcluido} />
						<span className='ml-2'>{g.statusproject.percentConcluido}%</span>
					</div>
				),
				risk: <span style={{ backgroundColor: g.type_risk ? riskColor[g.type_risk - 1] : '' }}>{riskType && riskType[g.type_risk - 1]}</span>,
				type_project: g.type_project && language.TYPE_PROJECT_ITEMS[g.type_project - 1].label,
				expected_cost: g.cust_project,
				realized_cost: g.realized_cost,
				variation: '50%',
				activites: (
					<div className='d-flex align-items-center justify-content-between'>
						{[0, 1, 0, 0].map((e, i) => (
							<Button
								key={i}
								size={.7}
								label={

									i == 0 ? g.statusproject["TODO"].length :
										i == 1 ? g.statusproject["DOING"].length :
											i == 2 ? g.statusproject["STOPPED"].length :
												i == 3 ? g.statusproject["DONE"].length :
													0

								}
								color={
									i == 0 ? 'primary' :
										i == 1 ? 'success' :
											i == 2 ? 'warning' :
												i == 3 ? 'danger' :
													null
								}
								style={{ borderRadius: 5 }}
							/>
						))}
					</div>
				)
			}


		))



	return (
		<Dialog
			title='Projetos'
			open={true}
			onClose={() => this.setOpen(false)}
			maxWidth={'xl'}
			contentProps={{
				className: 'pt-4'
			}}
		>
			<div id='ProjectPortfolio'>
				<CommonTable
					title='Portfólio do Projeto'
					searchWidth={200}
					paginationTop={false}

					mini
					col={[
						{ key: '#', label: '', thConfig: true, style: { paddingRight: 5 } },
						{ key: 'project', label: 'Projeto', thConfig: true, style: { paddingLeft: 0 } },
						{ key: 'progress', label: 'Progresso' },
						{ key: 'risk', label: 'Risco' },
						{ key: 'type_project', label: 'Tipo de Projeto' },
						{ key: 'expected_cost', label: 'Custo Previsto' },
						{ key: 'realized_cost', label: 'Custo Realizado' },
						{ key: 'variation', label: 'Variação' },
						{ key: 'activites', label: 'Atividades' },
					]}
					data={data}
				/>
			</div>
		</Dialog>
	)

}