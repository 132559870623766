import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import DemandmanagementList from './DemandmanagementList';
import Animate from '@common/Animate';
import { TextField, MenuItem, Paper } from '@material-ui/core';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import PageLayout from '../../common/PageLayout';
import classnames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DemandGraphs from './DemandGraphs';
import CommonForm from '@common/CommonForm2';
import './Demand.css';
import CommunicationDialerSip from 'material-ui/svg-icons/communication/dialer-sip';

const styles = (theme) => ({
  label: {
    color: 'orange',
  },
  textColor: {
    color: 'orange',
  },
  indicator: {
    backgroundColor: 'orange',
  },
});
@observer
class RequestContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      tab: 0,
      FixedBar: false,
      dashboard: false,
    };
  }

  componentDidMount() {
    var _this = this;
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      var p = _this.state.dashboard ? 650 : 280;
      if (
        document.body.scrollTop > p ||
        document.documentElement.scrollTop > p
      ) {
        if (_this.state.FixedBar == false) {
          _this.setState({ FixedBar: true });
        }
      } else {
        if (_this.state.FixedBar == true) {
          _this.setState({ FixedBar: false });
        }
      }
    }
    //activity
    this.action.execute(
      'get',
      this.APIEndpoints.DEMAND + '/tickets/0',
      'tickets'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.DEMAND + '/activity/0',
      'activities'
    );
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute('get', this.APIEndpoints.USER + '/tech', 'techs');
    this.action.execute(
      'get',
      this.APIEndpoints.SERVICE_ORDER,
      'serviceOrders'
    );
    this.action.execute('get', this.APIEndpoints.CHANGE, 'changes');
    this.action.execute('get', this.APIEndpoints.TICKET, 'ticketsCategories');
  }
  handleTab = () => {};
  onChangeField = (f, v) => this.setState({ [f.name]: v });

  render() {
    let {
      language,
      companies,
      techs,
      tickets,
      activities,
      serviceOrders,
      ticketsCategories,
      changes,
    } = this.store;
    let { classes } = this.props;
    /*  window.tickets = tickets.filter(Boolean);
      window.techs = techs.filter(Boolean);
      window.activities = activities.filter(Boolean);
      window.ticketsCategories = ticketsCategories;*/

    let listModules = ['GCF', 'GI', 'GSD', 'GR', 'GP'].map((e, i) => (
      <MenuItem value={e} key={i}>
        {e}
      </MenuItem>
    ));
    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        <PageLayout
          icon="imgGsd"
          title={language.DASHBOARD_PAGE.DM}
          subTitle={language.DASHBOARD_PAGE.DM + '/'}
          rightElements={[
            <div
              style={{ width: 350, zoom: 0.8, marginTop: '1rem' }}
              zoom={0.8}
              className="d-flex align-items-center justify-content-end"
            >
              <div style={{ width: 200 }}>
                <CommonForm
                  values={{
                    module,
                  }}
                  fields={[
                    {
                      type: 'select',
                      name: 'module',
                      label: 'Módulo',
                      options: listModules.map((e) => ({
                        value: e.props.value,
                        label: e.props.value,
                      })),
                    },
                  ]}
                  onChangeField={(key) =>
                    this.setState({ [key.name]: 'module' })
                  }
                />
              </div>
            </div>,

            /*
            <TextField
                   select
                   label={language.MODULE}
                   style={{ minWidth: 200, borderColor: 'pink' }}
                   value={this.state.module}
                   onChange={(evt)=>this.changeTextfield(evt.target.value, 'module')}
                   margin="dense"
                   variant="outlined"
                 >
                   {listModules}
                 </TextField>
      */
          ]}
        >
          <div
            id="DemandmanagementContainer"
            className={classnames({ fixed: this.state.FixedBar })}
          >
            {this.state.dashboard && (
              <Fragment>
                <DemandGraphs
                  language={language}
                  tickets={tickets}
                  activities={activities}
                  serviceOrders={serviceOrders}
                  ticketsCategories={ticketsCategories}
                  techs={techs}
                  changes={changes}
                />
                <br />
              </Fragment>
            )}
            {/* <div style={{ marginBottom: 50 }}>
                <AppBar
                   className="AppBar-23-sensr FixedBar"
                   style={{
                     paddingTop: 0,
                     paddingBottom: 0,
                     position: 'relative',
                     zIndex: 5
                   }}
                 >
                   <Tabs
                     indicatorColor="orange"
                     classes={{ indicator: classes.indicator }}
                     value={this.state.tab}
                     onChange={this.handleTab}
                     variant="fullWidth"
                   >
                     <Tab label="List Ticket"/>
                   </Tabs>
                </AppBar>
              </div> */}
            <Animate>
              <DemandmanagementList
                activities={activities}
                tickets={tickets}
                language={language}
                companies={companies}
                techs={techs}
              />
            </Animate>
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}

export default withStyles(styles)(RequestContainer);
