import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppBar from '@common/AppBar';
import { Card } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import AttachFile2 from '@common/AttachFile2';
import CommonPage from '../../common/CommonPage';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';

@observer
export default class TicketForm extends CommonPage {
  constructor() {
    super();
    this.state = {
      company: '',
      contract: '',
      category: '',
      catalog: '',
      user: '',
      device: '',
      catalog_task: '',
      id_grouptech: 0,
      devicesUser: [],
      categoryOptions: [{ value: 0, text: '' }],
      idrecurrent: 1
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  componentWillMount() {
    this.props.action.execute(
      'get',
      `${this.props.APIEndpoints.CATEGORY}/type_ticket/1`,
      'tickets_types_cat'
    );
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }
    )


    let { values } = this.props;
    if (values && values['fk_id_user']) {
      this.props.action
        .execute('get', `${this.APIEndpoints.DEVICE + 'user/' + values.fk_id_user}`, '')
        .then(res => {
          this.setState({ devicesUser: res.data[0] });
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_company') {
      this.setState({
        company: value
      });
    } else if (field.name === 'fk_id_contract') {
      this.setState({
        contract: value
      });
    } else if (field.name === 'fk_id_device') {
      this.setState({
        device: value
      });
    } else if (field.name === 'fk_id_category') {
      this.setState({
        category: value
      });
    } else if (field.name === 'fk_id_catalog_service') {
      this.setState({
        catalog: value
      });
    } else if (field.name === 'fk_id_user') {
      this.props.action
        .execute('get', `${this.APIEndpoints.DEVICE}user/${value}`, '')
        .then(res => {
          this.setState({ devicesUser: res.data[0] });
        });

      this.setState({
        user: value
      });
    }
    else if (field.name === 'recurrent') {
      this.setState({
        idrecurrent: value
      });
    }
    else if (field.name === 'fk_id_catalog_task') {
      this.setState({
        catalog_task: value
      });

    } else if (field.name === 'fk_id_group_tech') {
      this.setState({
        id_grouptech: value
      });
    }
    else if (field.name === 'type_tickets') {
      this.setState({
        categoryOptions: this.store.tickets_types_cat
          .filter(c => c.type_ticket == value)
          .map(ctr => ({ value: ctr.id_category, text: ctr.name }))
      });
    }
  }

  //http://localhost:3000/api/deviceuser/1



  render() {
    let {
      impacts,
      type_tickets,
      language,
      handlePage,
      values,
      onFormSubmit,
      contracts,
      categories,
      groups,
      priorities,
      urgencies,
      users,
      onDrop,
      companies,
      catalogs,
      chiefs,
      route,
      slas,
      taskcatalog,
      redirect2
    } = this.props;

    let { contract, device } = this.state;
   // console.log(impacts)


    let company = values ? values.fk_id_company : this.state.company;

    values.recurrent = values.recurrent ? values.recurrent : this.state.idrecurrent;

    contract = values ? values.fk_id_contract : this.state.contract;
    device = values ? values.fk_id_device : this.state.device;
    let user = values ? values.fk_id_user : this.state.user;

    let category = values ? values.fk_id_category : this.state.category;

    let catalog = values ? values.fk_id_catalog_service : this.state.catalog;

    let groutechfk = values ? values.fk_id_group_tech : this.state.id_grouptech

    let catalog_task = values
      ? values.fk_id_catalog_task
      : this.state.catalog_task;

    let typeTicket =
      route && route === 'incident'
        ? 1
        : route && route === 'problem'
          ? 2
          : null;

    let userSelect = users.filter(c => c.id_user === user)[0];


    let typeOptions;/*= type_tickets.map(tk => ({
      value: tk.item_table,
      text: tk.description
    })); */
    let br = sessionStorage.getItem('sensr_lang') == 'PT_BR'
    if (sessionStorage.getItem('sensr_lang') == 'PT_BR' || sessionStorage.getItem('sensr_lang') == null) {

      typeOptions = type_tickets.map(tk => ({
        value: tk.item_table,
        text: tk.description == 'Incident' ? 'Incidente' :
          tk.description == 'Problem' ? 'Problema' :
            tk.description == 'Liberation' ? 'Liberação' : 'Solicitação'
      }))
    } else {
      typeOptions = type_tickets.map(tk => ({
        value: tk.item_table,
        text: tk.description
      }))
    }





    let contractOptions = [];




    let companyOptions =


      companies.map(c => ({
        value: c.id_company,
        text: c.name
      }));

    if (this.store.getUserLoged().role == 'tech') {

      try {
        companyOptions =
          companies.filter(g => this.store.usercon_company[0].company.includes(g.id_company)).map(c => ({
            value: c.id_company,
            text: c.name
          }));
      } catch (t) {
        companyOptions = [];
      }
    }


    //retirei aqui
    if (values.fk_id_company == 1) {

      contractOptions = contracts
        .filter(c => c.fk_id_company === company)
        .map(ctr => ({ value: ctr.id_contract, text: ctr.name }));
    } else {
      contractOptions = contracts
        .filter(c => c.fk_id_out_company === company)
        .map(ctr => ({ value: ctr.id_contract, text: ctr.name }));
    }

    let solicitantOptions = users
      .filter(c => c.id_company === company)
      .map(u => ({ value: u.id_user, text: u.name }));


    let techgroups = [];
    this.props.groupstech
      .filter(g => g.fk_id_group_users == groutechfk)
      .map(tec => techgroups.push({ value: tec.id_user, text: tec.name }));

    // this.state.categoryOptions = categories.map(c => ({ value: c.id_category, text: c.name }));

    // console.log("this.state.categoryOptions");
    // console.log(this.state.categoryOptions);

    let tasksOptions = [];

    let techGroupOptions = [];
    let t = [];

    if (catalog === null) {
    } else {
      t = catalogs
        .filter(c => c.id_catalog_service === catalog)
        .map(t => t.id_group_users);

      //  techGroupOptions = groups.filter(g => g.type == 1 && g.type_tech == 1 && g.id_group_users == t[0]).map(tec => ({ value: tec.id_group_users, text: tec.name }));
      groups
        .filter(
          g => g.type == 1 && g.type_tech == 1 && g.id_group_users == t[0]
        )
        .map(tec =>
          techGroupOptions.push({ value: tec.id_group_users, text: tec.name })
        );

      groups
        .filter(
          g => g.type == 1 && g.type_tech == 1 && g.id_group_users != t[0]
        )
        .map(tec =>
          techGroupOptions.push({ value: tec.id_group_users, text: tec.name })
        );
    }

    let deviceOptions = [];
    let serviceCatalogOptions = [];
    let departmentOptions = [];
    let responsibleOptions = [];
    let responsible = null;
    let priorityOptions = [];
    let urgencyOptions = [];

    let slaOptions = [];

    let impactsOptions = [];
    let idsla = [];
    let idpriority = [];
    let idurgency = [];
    let idimpact = [];
    try {
      /* if (contract && contracts != []) {
         deviceOptions = contracts
           .filter(c => c.id_contract === contract)[0]
           .device.map(d => ({ value: d.id_device, text: d.name }));
       } */


      if (values.fk_id_user) {


        contracts.filter(c => c.fk_id_out_company === company).map(x => {

          x.device.map(x => {
            if (this.state.devicesUser.devices.includes(x.id_device))
              deviceOptions.push({ value: x.id_device, text: x.name });
          })
        })


        if (deviceOptions.length == 1) {

          values.fk_id_device = deviceOptions[0].value
        }
        if (deviceOptions.length == 0) {

          contracts.filter(c => c.fk_id_out_company === company).map(x => {
            contractOptions.push({ value: x.id_contract, text: x.name });

          })
        }



      } else {
        contracts.filter(c => c.fk_id_out_company === company).map(x => {
          contractOptions.push({ value: x.id_contract, text: x.name });

        })
      }

      if (this.state.device != '') {
        contractOptions = [];

        if (values.fk_id_company == 1) {

          contracts.filter(c => c.fk_id_company === company).map(x => {

            if (x.device.filter(x => x.id_device === this.state.device).length > 0) {
              if (contractOptions.filter(g => g.value == x.id_contract).length == 0)
                contractOptions.push({ value: x.id_contract, text: x.name });

            }
          })
        } else {
          contracts.filter(c => c.fk_id_out_company === company).map(x => {

            if (x.device.filter(x => x.id_device === this.state.device).length > 0) {
              if (contractOptions.filter(g => g.value == x.id_contract).length == 0)
                contractOptions.push({ value: x.id_contract, text: x.name });

            }
          })
        }


      }


      if (company && userSelect) {
        companies
          .filter(c => c.id_company == company)[0]
          .department.map(obj => {
            departmentOptions.push({
              value: obj.id_department,
              text: obj.name
            });
            if (obj.sub_dep.length) {
              obj.sub_dep.map(sd => {
                departmentOptions.push({
                  value: sd.id_department,
                  text: sd.name
                });
              });
            }
          });

        //responsibleOptions = chiefs.filter(cf => cf.fk_id_company === company && cf.fk_id_department === userSelect.fk_id_department).map(c => ({ value: c.id_user, text: c.name }));
        //responsible = responsibleOptions.length ? responsibleOptions[0].value : null;
      }

      responsibleOptions = chiefs.filter(cf => cf.fk_id_company === company && cf.fk_id_department ===
        userSelect.fk_id_department).map(c => ({ value: c.id_user, text: c.name }));


      /*  responsibleOptions = chiefs
           .filter(cf => cf.fk_id_company == company)
           .map(c => ({ value: c.id_user, text: c.name }));
         responsible = responsibleOptions.length
           ? responsibleOptions[0].value
           : null; */

      // console.log("company")
      // console.log(company)

      // console.log("chiefs")
      // console.log(chiefs)

      // console.log("responsibleOptions");
      // console.log(responsibleOptions);

      // console.log("responsible");
      // console.log(responsible);
      //console.log(impacts)
      if (category) {
        serviceCatalogOptions = categories
          .filter(c => c.id_category === category)[0]
          .catalog.filter(c => c.name != null).map(cat => ({
            value: cat.id_catalog_service,
            text: cat.name
          }));

        serviceCatalogOptions = serviceCatalogOptions.sort(function (a, b) {
          return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
        });

        if (catalog) {
          tasksOptions = catalogs
            .filter(c => c.id_catalog_service === catalog)[0]
            .task.filter(c => c.name != null).map(t => ({ value: t.id_catalog_task, text: t.name }));
          tasksOptions = tasksOptions.sort(function (a, b) {
            return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
          });



          if (catalog_task === null || catalog_task == undefined) {
          } else {
            idurgency = taskcatalog
              .filter(c => c.id_catalog_task === catalog_task.toString())
              .map(t => t.id_urgency);

            idimpact = taskcatalog
              .filter(c => c.id_catalog_task === catalog_task.toString())
              .map(t => t.id_impact);

            idpriority = taskcatalog
              .filter(c => c.id_catalog_task === catalog_task.toString())
              .map(t => t.id_priority);

            priorityOptions = priorities
              .filter(c => c.id_table === idpriority[0])
              .map(pri => ({ value: pri.id_table, text: pri.description }));

            urgencyOptions = urgencies
              .filter(c => c.id_table === idurgency[0])
              .map(ur => ({ value: ur.id_table, text: ur.description }));

            idsla = taskcatalog.map(t => t.time_sla)[0];

            // slaOptions = slas.filter(c => c.id_sla === idsla).map(sl => ({ value: sl.id_sla, text: sl.name }));
            slaOptions = slas.map(sl => ({ value: sl.id_sla, text: sl.name }));


            impactsOptions = impacts
              .filter(c => c.id_table === idimpact[0])
              .map(i => ({ value: i.id_table, text: i.description }));
          }
        }
      }

      if (values) {
        values.fk_id_department = userSelect
          ? userSelect.fk_id_department
          : null;

       // console.log(values.fk_id_department)
        values.fk_id_user_sponsor = responsibleOptions[0].value;
      }

      if (idpriority) {
        values.fk_id_priority = idpriority[0];
      }
      if (idurgency) {
        values.fk_id_urgency = idurgency[0];
      }

      if (idimpact) {
        values.fk_id_impact = idimpact[0];
      }

      if (catalog) {
        values.fk_id_group_tech = this.state.id_grouptech > 0 ?
          this.state.id_grouptech :
          t[0];
      }

      if (idsla) {
        values.fk_id_sla = idsla;
      }
    } catch (e) { }



    let recurrentOptions = [
      { value: 0, text: '' },
      { value: 1, text: language.RECURRENCE_TYPE.NO_RECURRENCE },
      { value: 2, text: language.RECURRENCE_TYPE.DAILY },
      { value: 3, text: language.RECURRENCE_TYPE.WEEKLY },
      { value: 4, text: language.RECURRENCE_TYPE.QUARTERLY },
      { value: 5, text: language.RECURRENCE_TYPE.SEMESTER },
      { value: 6, text: language.RECURRENCE_TYPE.MONTHLY },
      { value: 7, text: language.RECURRENCE_TYPE.YEARLY }
    ];
    let fields = [];
    try {
      if (values.ticketArray.length > 1) {
        fields = [
          {
            span: 12,
            name: 'ticketArray',
            label: 'Requests',
            type: 'text',
            disabled: true
          }
        ];
      }
    } catch (e) { }

    //fk_id_catalog_service ligado com fk_id_group_tech
    //  console.log(values.fk_id_device);
    fields = fields.concat([
      {
        span: 3,
        name: 'type_tickets',
        label: language.TYPE,
        type: 'select',
        options: typeOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_company',
        label: language.COMPANY,
        type: 'select',
        options: companyOptions,
        required: true,
        disabled: !!(values && values.fk_id_request)
      },
      {
        span: 3,
        name: 'fk_id_device',
        label: language.DEVICE,
        type: 'select',
        options: deviceOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_contract',
        label: language.CONTRACT,
        type: 'select',
        options: contractOptions,
        required: true
      },

      {
        span: 3,
        name: 'fk_id_user',
        label: language.SOLICITANT,
        type: 'select',
        options: solicitantOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_category',
        label: language.CATEGORY,
        type: 'select',
        options: this.state.categoryOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_catalog_service',
        label: language.SERVICE_CATALOG,
        type: 'select',
        options: serviceCatalogOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_catalog_task',
        label: language.TASK,
        type: 'select',
        options: tasksOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_group_tech',
        label: language.GROUP_TECH,
        type: 'select',
        options: techGroupOptions,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_tech',
        label: language.TECH,
        type: 'select',
        options: techgroups

      },

      {
        span: 3,
        name: 'fk_id_department',
        label: language.DEPARTMENT,
        type: 'select',
        options: departmentOptions
      },
      {
        span: 3,
        name: 'fk_id_user_sponsor',
        label: language.RESPONSIBLE,
        type: 'select',
        options: responsibleOptions,
        disabled: true,
        required: true
      },
      {
        span: 3,
        name: 'fk_id_priority',
        label: language.PRIORITY,
        type: 'select',
        options: priorityOptions,
        required: true,
        disabled: !!(values && values.fk_id_priority)
      },
      {
        span: 3,
        name: 'fk_id_urgency',
        label: language.URGENCY,
        type: 'select',
        options: urgencyOptions,
        required: true,
        disabled: !!(values && values.fk_id_urgency)
      },
      {
        span: 3,
        name: 'subject',
        label: language.SUBJECT,
        type: 'text',
        multiLine: true,
        required: true,
        disabled: !!(values && values.fk_id_request)
      },
      {
        span: 3,
        name: 'fk_id_impact',
        label: 'Impact',
        type: 'select',
        options: impactsOptions,
        required: true,
        disabled: !!(values && values.fk_id_impact)
      },
      {
        span: 3,
        name: 'recurrent',
        label: language.RECURRENCE,
        type: 'select',
        options: recurrentOptions,
        required: true
      },
      {
        span: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'textEditor',
        multiLine: true,
        required: true,
        disabled: !!(values && values.fk_id_request)
      }
    ]);

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10 }
    };

    let colors = [
      '#F44336',
      '#DAA520',
      'Yellow',
      '#33ABA0',
      'Blue',
      'Purple',
      'Black',
      'White'
    ];

    return (
      <PageLayout
        icon={
          <div
            className="incidentManagementIcon"
            style={{ width: 44, height: 44 }}
          />
        }
        title={
          values && values.fk_id_request ?
            'R' + values.fk_id_request + ' - ' + values.subject : language.TICKET}
        subtitle={language.NEW_TICKET}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            label={language.BACK}
            color='primaryGradient'
            style={{ marginRight: 10 }}
            onClick={() => this.props.categorize ? redirect2('/dash/request') : this.redirect('/dash/gsd')}
          />
        ]}
      >
        {values.files != undefined ?

          <Card style={{ height: '200px', marginTop: 35, overflow: 'visible' }}>
            <AppBar light>
              <div className="AppBarCustomContent">
                <div className="bg-icon">
                  <div
                    className="attachmentIcon"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <span style={{ marginLeft: 8, fontSize: 18 }}>{language.ATTACHMENT + 's'}</span>
              </div>
            </AppBar>
            <div className="content">
              {values.files != undefined
                ? values.files.map(f => (
                  <Row>
                    <Col md={1}>
                      {' '}
                      <div
                        className="attachmentIcon"
                        style={{ marginTop: 12 }}
                      />{' '}
                    </Col>
                    <Col md={7}>
                      <p
                        className="pointer "
                        onClick={() =>
                          this.props.ondonwFile(f.split('|')[2], f.split('|')[0])
                        }
                      >
                        {f.split('|')[0] + " - " + moment(f.split('|')[3]).format('L')}
                      </p>
                    </Col>{' '}
                  </Row>
                ))
                : null}
            </div>

          </Card> : null}
        <Card style={{ marginTop: 10, padding: 10 }}>
          {this.props.atach == true ? <AttachFile2
            files={Array.from(this.props.files)}
            onDrop={onDrop}
            accept=".zip, .7Z, .json, .VBS, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar , audio/*, image/*"

            label={language.ATTACH}
            onRemove={(f) => this.props.removeFile(f)}
            style={{ maxWidth: '100%' }}
          /> : null}
          <CommonForm
            language={language}
            button={button}
            onFormSubmit={onFormSubmit}
            fields={fields}
            values={
              values || {
                type_tickets: typeTicket,
                fk_id_department: userSelect
                  ? userSelect.fk_id_department
                  : null,
                fk_id_user_sponsor: responsible
              }
            }
            onChangeField={this.onChangeField}
          />


        </Card>
      </PageLayout>
    );
  }
}
