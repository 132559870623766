import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as Icons from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';

import Dialog from '../../common/Dialog';
import Animate from '../../common/Animate';
import { testRoute } from '../../common/Func';
import CommonPage from '../../common/CommonPage';
import DownloadFiles from '../../util/DownloadFiles';
import DialogConfirm from '../../common/DialogConfirm';

import CategoryList from './CategoryList';
import CategoryForm from './CategoryForm';
import CategoryAddCatalogForm from './CategoryAddCatalogForm';

@observer
export default class CategoryContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      allIcons: [],
      categories: [],
      page: 'categoryList',
      pageDelete: {
        open: false,
        values: undefined,
      },
    };
    this.onDelete = this.onDelete.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.sendvalues = this.sendvalues.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.refreshCategory = this.refreshCategory.bind(this);
    this.handlePageDelete = this.handlePageDelete.bind(this);
  }

  sendvalues() {
    this.action
      .execute('get', this.APIEndpoints.CATALOG + '/exportcatalog', '')
      .then((v) => {
        setTimeout(() => {
          DownloadFiles(
            this.APIEndpoints.REQUESTS + '/downloadcatelog',
            'ExportCatalog.xlsx'
          );
        }, 4000);
      });
  }

  componentWillMount() {
    this.getCategories();
  }

  componentDidMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.TICKET + '/types/70',
      'tickets_types'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.CATEGORY + '/type_ticket/1',
      'tickets_types_cat'
    );

    let icons_ = Object.keys(Icons)
      .filter(
        (i) =>
          !i.toString().includes('Outlined') &&
          !i.toString().includes('Rounded') &&
          !i.toString().includes('Sharp') &&
          !i.toString().includes('TwoTone') &&
          !i.toString().includes('Forward') &&
          !i.toString().includes('Extra') &&
          !i.toString().includes('Reduced')
      )
      .map((str) => ({
        value: str.replace(/[A-Z]/g, (letter, index) => {
          return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
        }),
        label: (
          <div>
            <Icon>
              {str.replace(/[A-Z]/g, (letter, index) => {
                return index == 0
                  ? letter.toLowerCase()
                  : '_' + letter.toLowerCase();
              })}
            </Icon>
            {str}
          </div>
        ),
        field: str,
      }));
    this.setState({ allIcons: icons_ });
  }

  getCategories() {
    this.action
      .execute('get', `${this.APIEndpoints.CATEGORY}`, '')
      .then((res) => {
        this.setState({
          categories: res.data,
        });
      });
  }

  onFormSubmit(values) {
    let method = values.hasOwnProperty('id_category') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_category')
      ? this.APIEndpoints.CATEGORY + '/' + values.id_category
      : this.APIEndpoints.CATEGORY;
    this.action
      .execute(
        method,
        url,
        `$${method}-categories,$get-tickets_types,$get-tickets_types_cat`,
        values,
        'id_category',
        { btn_loading: 'post-put-category' }
      )
      .then((e) => {
        this.getCategories();
      });
    this.handlePage('list');
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  handlePageDelete(open = false, values) {
    this.setState({ pageDelete: { open, values } });
  }

  onDelete() {
    const { categories, pageDelete } = this.state;
    let { values } = pageDelete;
    let method = 'delete';
    let url = this.APIEndpoints.CATEGORY + '/' + values.id_category;
    let body = null;
    var vt = 1;

    this.handlePageDelete();
    this.action
      .execute(method, url, 'categories', body, 'id_category', {
        btn_loading: 'del-category-item-' + vt + values.id_category,
      })
      .then((res) => {
        this.setState({
          categories: categories.filter(
            (category) => category.id_category != res.data.id_category
          ),
        });
      });
  }

  refreshCategory(newCategory) {
    const { categories } = this.state;
    this.setState({
      categories: categories.map((category) => {
        if (category.id_category === newCategory.id_category) {
          category = newCategory;
        }
        return category;
      }),
    });
  }

  render() {
    let { language, tickets_types, tickets_types_cat } = this.store;
    let { categories, page, values } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    let { pathname, state } = history.location;

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <CategoryList
            language={language}
            redirect={redirect}
            onFormSubmit={this.onFormSubmit}
            handlePage={this.handlePage}
            categories={categories}
            values={values}
            sendvalues={this.sendvalues}
            configurationProps={configurationProps}
            handlePageDelete={this.handlePageDelete}
          />
        </Animate>
        {['new', 'edit'].includes(page) && (
          <Dialog
            title={!values ? language.ADD_CATEGORY : language.EDIT_CATEGORY}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xs"
          >
            <CategoryForm
              language={language}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              values={values}
              redirect={redirect}
              categories={categories}
              tickets_types={tickets_types}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              CatType={tickets_types_cat}
              {...this.state}
            />
          </Dialog>
        )}
        {page == 'add/catalog' && (
          <Dialog
            title={language.ADD_CATALOG_TO_CATEGORY}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="md"
          >
            <CategoryAddCatalogForm
              language={language}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              refreshCategory={this.refreshCategory}
              categories={categories}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              values={
                categories.filter(
                  (c) => c.id_category === values.id_category
                )[0]
              }
            />
          </Dialog>
        )}

        {this.state.pageDelete.open && (
          <DialogConfirm
            open={this.state.pageDelete.open}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={this.onDelete}
            onClose={() => this.handlePageDelete()}
            title={language.CONFIRM_DELETE}
            message={this.state.pageDelete.values.name}
          />
        )}
      </Fragment>
    );
  }
}
