import React, {useState, Fragment, useCallback} from 'react';
import classnames from 'classnames';
import moment from 'moment';
/*-------------------------------common---------------------------------------*/
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';

import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@common/Button';
import Store from '@data/Store';

const { language } = Store;

/*-------------------------------material-ui---------------------------------------*/
/*-------------------------------components---------------------------------------*/

import FormExpectedResult from './FormExpectedResult';
import FormSubActivities from './FormSubActivities';
import Form from './Form';
import List from './List';

export default function({open, onClose, result, departments, projects, users, action, APIEndpoints, companyId}){
	/*--------------------------------------state------------------------------------------------------------*/
	let [name, setName] = useState(null);
	let [nameRef, setNameRef] = useState(null);
	let [values, setValues] = useState({});
	let [results, setResults] = useState([]);
	let [dialogFormExpectedResult, setDialogFormExpectedResult] = useState(false);

	//--- aqui nessa state abaixo vai o index do resultado esperado, console.log(dataList[dialogFormSubActivities]); significa q quero adicionar uma subatividade a esse cara do index
	let [dialogFormSubActivities, setDialogFormSubActivities] = useState(undefined); 

	let [dialog, setDialog] = useState(1);

	/*--------------------------------------data------------------------------------------------------------*/
	let dataList = results.map(res => {
		return {
			result_expected: res.result_expected,
			priorization: res.priorization_result,
			key_result: res.key_result,
			deadline: res.deadline,
			period: res.period_result,
			users: res.responsibles,
			subactivities: !!res.subactivities ? res.subactivities.map(sub => ({
				deadline_subactivity: sub.deadline_subactivity,
				result_subactivity: sub.result_subactivity,
				responsibles: sub.responsibles,
				description: sub.description
			})) : []
		}
	})/*[
		{
			name:'Lorem ipsum dolor sit amet, consecteta',
			priorization:3,
			key:'KR 3',
			deadline:Date.now(),
			period:3,
			users:[1,2,3],
			dataNivel:[
				{
					id:2,
					deadline:Date.now(),
					expected_result:'Lorem ipsum dolor sit amet, consecteta',
					users:[1,2,3],
					name:'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
				},
				{
					id:3,
					deadline:Date.now(),
					expected_result:'Lorem ipsum dolor sit amet, consecteta',
					users:[1,2,3],
					name:'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
				},
			]
		}
	] */
	const handleSubmit = async () => {
		let obj = {
			...values,
			fk_id_company: companyId,
			description_objective: name,
			okrResults: results,
		}
		try {
			const result = await action.execute('post', APIEndpoints.OKR, null, obj, null, 'Salvo com sucesso', true)

			if (result.data.ok) {

				window.location.reload()
				onClose()
			}
		} catch (error) {
			console.log('error', error);

		} 
	}

	const handleValues = useCallback((f, v) => {
		const { name } = f
		setValues(value => ({ ...value, [name]: v}))
	}, []);

	const handleAddResults = useCallback(v => {
		setResults(prev => [...prev, v])
		setDialogFormExpectedResult(false)
	}, [])

	const handleAddSubactivities = useCallback((v) => {
		setResults(prev => {
			let subactivities = []

			if (!!prev[dialogFormSubActivities].subactivities) {
				subactivities = [...prev[dialogFormSubActivities].subactivities, v];
			} else {
				subactivities.push(v)
			}
			
			const result = prev.map((value, i) => {
				if (i == dialogFormSubActivities) {
					value.subactivities = subactivities;
					return value;
				}

				return value;
			})

			return result
		})
		setDialogFormSubActivities(undefined)
	}, [dialogFormSubActivities])



	return(
		<Fragment>
			{/*-------------------------FORMULÁRIO PRA ADICIONAR UM NOVO RESULTADO ESPERADO----------------------------------------*/}
			<FormExpectedResult 
				open={dialogFormExpectedResult} 
				onClose={() => setDialogFormExpectedResult(false)}
				onSubmit={handleAddResults}
				users={users}
				companyId={companyId}
			/>
			{/*------------FORMULÁRIO PRA ADICIONAR UMA SUBATIVIDADE DE UM DETERMINADO RESULTADO ESPERADO---------------------------*/}
			<FormSubActivities 
				open={dialogFormSubActivities!==undefined} 
				onClose={() => setDialogFormSubActivities(undefined)}
				onSubmit={handleAddSubactivities}
				users={users}
			/>

			{/*-------------------------------------------DIALOG----------------------------------------------------------*/}
			<Dialog
			  PaperProps={{
			    id: 'DialogFormOkr'
			  }}
			  open={open}
			  onClose={onClose}
			  maxWidth='md'
			  contentTitle={{
				style:{width:'100%'}
			  }}
			  titleContentProps={{
				style:{
				  width:'calc(100% - 60px)'
				}
			  }}
			  title={result?'Assunto será exibido aqui':(
				<div className="w-100 bar" style={{zoom:.9}}>
					<CommonForm
					individual
						values={{
						subject: undefined
						}}
						alert={false}
						fields={[
						{
							type: 'text',
							name: 'name',
							label: 'Objetivo',
							required:true
						}
						]}
						onRef={r => setNameRef(r)}
						onChangeField={(f, v) => setName(v)}
					/>
				</div>)
			  }
			  
			>
				{/*------------FORMULÁRIO PRINCIPAL, PRA ADICIONAR UM OKR/OBJETIVOS---------------------------*/}
				<Form onChangeField={handleValues} departments={departments} projects={projects}/>
				<hr/>
				{/*-----------------LISTA DE RESULTADO ESPERADO E SUAS SUBATIVIDADES-------------------------*/}
				<List data={dataList} onAdd={(v)=>{

					if(v!==undefined){
						setDialogFormSubActivities(v)
					}else{
						setDialogFormExpectedResult(true)
					}
				}}/>
				{/*-----------------BOTÃO SALVAR OU CANCELAR-------------------------*/}
				<div className='d-flex align-items-center justify-content-end mt-3'>
					<Button
						icon='fas fa-times'
						color='danger'
						variant='normal'
						label={language.CANCEL}
						className='mr-2'
						onClick={onClose}
					/>
					<Button
						icon='fas fa-save'
						color='success'
						variant='normal'
						label={language.SAVE}
						onClick={() => handleSubmit()}
					/>
				</div>
			</Dialog>
		</Fragment>
	)
}