import React, {Fragment, memo} from 'react';
import {Card} from '@material-ui/core';
import AppBar from '@common/AppBar2';
import Store from '@data/Store';

const { language } = Store;

export default memo(({
	notPlanned,
	planned,
	total_effort_value,
	total_effort_hour
})=>{
	
	return(
		<Fragment>
			<Card className='card-activity'>
				<AppBar title={language.UNPLANNED_ACTIVITIES} titleProps={{style:{color:'white'}}} subTitle='(total)' subTitleProps={{style:{color:'white'}}}/>
				<div className='content-medium content-info pt-0'>
					<h2 className='mr-3'>{notPlanned}</h2>
					<span>{language.ACTIVITIES}</span>
				</div>
			</Card>
			<Card className='card-activity'>
				<AppBar title={language.PLANNED_ACTIVITIES} titleProps={{style:{color:'white'}}} subTitle='(total)' subTitleProps={{style:{color:'white'}}}/>
				<div className='content-medium content-info pt-0'>
					<h2 className='mr-3'>{planned}</h2>
					<span>{language.ACTIVITIES}</span>
				</div>
			</Card>
			<Card className='card-activity'>
				<AppBar title={language.SPRINT_EFFORT} titleProps={{style:{color:'white'}}} subTitle='(total)' subTitleProps={{style:{color:'white'}}}/>
				<div className='content-medium content-info pt-0'>
					<h3 className='mr-3'>{total_effort_value}</h3>
					<span>{language.TOTAL}</span>
					<h3 className='mr-3'>{total_effort_hour}</h3>
					<span>{language.HOURS}</span>
			
				</div>
			</Card>
		</Fragment>
	)
})