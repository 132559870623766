import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
// -------------------------common----------------------------------------
import CommonPage from '@common/CommonPage';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import PageLayout from '@common/PageLayout';
import crypto from 'crypto';

//----------------------------pages--------------------------------------
import CP from '../CP';
import Progress from './Progress';
import Constants from '@data/Constants';
export default class extends CommonPage {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      groupTech: [],
      companiesOptions: [],
      companyId: -1,
      status: null,
      manager: null,
    };

    this.updateProject = this.updateProject.bind(this);
  }
  decryp(aMsg) {
    try {
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');

      return tRet;
    } catch (e) {
      return {};
    }
  }

  componentDidMount() {
    this.store.projects = [];
    this.action.execute('get', this.APIEndpoints.PROJECT, 'projects');
    this.action
      .execute('get', this.APIEndpoints.GROUP, 'groups')
      .then((result) => {
        this.setState({ groupTech: result.data });
      });

    this.action.execute('get', this.APIEndpoints.COMPANY).then((h) => {
      let companies = h.data;
      let newCompanies = [];
      //	newCompanies.push({value: -1, label: 'Todos'})
      companies.map((company) => {
        newCompanies.push({ value: company.id_company, label: company.name });
      });
      this.setState({ companiesOptions: newCompanies });
    });
  }

  updateProject(idproject, col, value) {
    let values = { col: col, id_project: idproject, value: value };
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectpr',
      'projects',
      values,
      'id_project'
    );
  }

  render() {
    let { language, projects } = this.store;
    let { manager } = this.state;

    if (manager) {
      projects = projects.filter(
        (project) => project.user_cad == manager
      );
    }

    if (this.state.companyId != -1) {
      projects = projects.filter(
        (project) => project.fk_id_company == this.state.companyId
      );
    }

    if (this.state.status == 0) {
      projects = projects.filter((item) => item.percent != '100.00');
    } else if (this.state.status == 1) {
      projects = projects.filter((item) => item.percent == '100.00');
    }
    let data_ = [];
    if (projects && projects.length > 0) {
      data_ = projects.map((project) => {
        return {
          id: project.id_project,
          project: project.name,
          wish_board: (
            <Progress
              color="#019FC2"
              colorEmpty="#025163"
              value={!project.desire_directors ? 0 : project.desire_directors}
              onChange={(e) =>
                this.updateProject(project.id_project, 'desire_directors', e)
              }
            />
          ),

          strategic_alignment: (
            <Progress
              color="#019FC2"
              colorEmpty="#025163"
              value={
                !project.strategic_alignment ? 0 : project.strategic_alignment
              }
              onChange={(e) =>
                this.updateProject(project.id_project, 'strategic_alignment', e)
              }
            />
          ),

          benefit_amount: (
            <Progress
              color="#019FC2"
              colorEmpty="#025163"
              value={!project.benefit_amount ? 0 : project.benefit_amount}
              onChange={(e) =>
                this.updateProject(project.id_project, 'benefit_amount', e)
              }
            />
          ),

          processes_improvement: (
            <Progress
              color="#019FC2"
              colorEmpty="#025163"
              value={
                !project.processes_improvement
                  ? 0
                  : project.processes_improvement
              }
              onChange={(e) =>
                this.updateProject(
                  project.id_project,
                  'processes_improvement',
                  e
                )
              }
            />
          ),

          financial_feedback: (
            <Progress
              color="#019FC2"
              colorEmpty="#025163"
              value={
                !project.financial_feedback ? 0 : project.financial_feedback
              }
              onChange={(e) =>
                this.updateProject(project.id_project, 'financial_feedback', e)
              }
            />
          ),

          generates: (
            <Progress
              color="#92D050"
              colorEmpty="#467911"
              value={!project.generates_costs ? 0 : project.generates_costs}
              onChange={(e) =>
                this.updateProject(project.id_project, 'generates_costs', e)
              }
            />
          ),

          risk_not_done: (
            <Progress
              color="#92D050"
              colorEmpty="#467911"
              value={!project.risk_if_not_done ? 0 : project.risk_if_not_done}
              onChange={(e) =>
                this.updateProject(project.id_project, 'risk_if_not_done', e)
              }
            />
          ),

          urgency: (
            <Progress
              color="#92D050"
              colorEmpty="#467911"
              value={!project.urgency ? 0 : project.urgency}
              onChange={(e) =>
                this.updateProject(project.id_project, 'urgency', e)
              }
            />
          ),

          complexity: (
            <Progress
              color="#92D050"
              colorEmpty="#467911"
              value={!project.complexity ? 0 : project.complexity}
              onChange={(e) =>
                this.updateProject(project.id_project, 'complexity', e)
              }
            />
          ),

          involved_areas: (
            <Progress
              color="#92D050"
              colorEmpty="#467911"
              value={!project.involved_areas ? 0 : project.involved_areas}
              onChange={(e) =>
                this.updateProject(project.id_project, 'involved_areas', e)
              }
            />
          ),

          innovation: (
            <Progress
              color="#F09702"
              colorEmpty="#9b6306"
              value={!project.innovation ? 0 : project.innovation}
              onChange={(e) =>
                this.updateProject(project.id_project, 'innovation', e)
              }
            />
          ),
        };
      });
    }

    /* data = [
      {
        id:10,
        project:'Criando um projeto',
        // pra cada componente progress, recomendo não mudar o valor dinamicamente sempre que o onChange for chamado.
        // dentro do próprio componente ele já muda o valor pra vc.
        wish_board:<Progress color='#019FC2' value={3} onChange={()=>null}/>,
        strategic_alignment:<Progress color='#019FC2' value={4}/>,
        benefit_amount:<Progress color='#019FC2' value={1}/>,
        processes_improvement:<Progress color='#019FC2' value={2}/>,
        financial_feedback:<Progress color='#019FC2' value={5}/>,

        generates:<Progress color='#92D050' value={3}/>,
        risk_not_done:<Progress color='#92D050' value={2}/>,
        urgency:<Progress color='#92D050' value={1}/>,
        complexity:<Progress color='#92D050' value={3}/>,

        inovation:<Progress color='#F09702' value={4}/>,
      }
    ] */

    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        <PageLayout
          title={language.EVALUTION}
          subTitle={`${language.PROJECT_MANAGEMENT} / ${language.PROJECT_PRIORITIZATION_MAP}`}
          rightElements={[
            <div
              style={{
                zoom: 0.8,
                width: 600,
                marginTop: '1rem',
                marginBottom: '-4rem',
                marginRight: '-0.5rem',
              }}
            >
              <CommonForm
                individual
                values={{
                  manager,
                }}
                fields={[
                  {
                    col: 3.5,
                    type: 'select',
                    name: 'status',
                    label: language.STATUS,
                    options: [
                      { value: 0, label: language.TICKET_STATUS.OPEN },
                      { value: 1, label: language.CONCLUDED },
                    ],
                  },
                  {
                    col: 3.5,
                    type: 'select',
                    name: 'company',
                    label: language.COMPANY,
                    options: this.state.companiesOptions,
                  },

                  {
                    col: 4,
                    type: 'autocomplete',
                    name: 'manager',
                    label: language.RESPONSIBLE,
                    className: 'tech-group-ticket',
                    method: 'POST',
                    route: `${Constants.APIEndpoints.USER}/filter`,
                    routeGetCount: `${Constants.APIEndpoints.USER}/active/count`,
                    fieldquery: 'search',
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    idinit: manager,
                    routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                    detail: true,
                    methodGetAll: 'POST',
                    textlabel: `Gestor`,
                    filterAll: { manager: true },
                  },

                  {
                    col: 0.5,
                    type: 'element',
                    content: (
                      <Button
                        icon="fas fa-eraser"
                        label={language.RESET}
                        color="primary"
                        variant="circle"
                        style={{ marginTop: '1rem' }}
                        onClick={() => {
                          this.setState({
                            companyId: -1,
                            manager: -1,
                            status: null,
                          });
                        }}
                      />
                    ),
                  },
                ]}
                onChangeField={(f, v) => {
                  if (f.name == 'status') {
                    this.setState({ status: v });
                  } else if (f.name == 'manager') {
                    this.setState({ manager: v });
                  } else if (f.name == 'company') {
                    this.setState({ companyId: v });
                  }
                  // this.setState({ [f.name]: v });
                }}
              />
            </div>,
          ]}
        >
          <div id="PrioritizationAvaliation">
            <CommonTable
              afterBar={
                <div
                  onClick={() =>
                    this.redirect(
                      '/dash/gp/prioritization/avaliation/configuration'
                    )
                  }
                >
                  <Button icon="fas fa-cog" label={language.CONFIG} />
                </div>
              }
              col={[
                { key: 'id', label: language.ID },
                { key: 'project', label: language.PROJECTS },
                { key: 'wish_board', label: language.BOARD_DESIRE },
                {
                  key: 'strategic_alignment',
                  label: language.STRATEGIC_ALIGMENT,
                },
                { key: 'benefit_amount', label: language.VALUE_OF_BENEFITS },
                {
                  key: 'processes_improvement',
                  label: language.PROCESSES_IMPROVEMENT,
                },
                {
                  key: 'financial_feedback',
                  label: language.FINANCIAL_FEEDBACK,
                },
                { key: 'generates costs', label: language.GENERATES_COSTS },
                { key: 'risk_not_done', label: language.RISK_IF_NOT_DONE },
                { key: 'urgency', label: language.URGENCY },
                { key: 'complexity', label: language.COMPLEXITY },
                {
                  key: 'involved_areas',
                  label: language.INVOLVED_AREAS.substring(1),
                },
                { key: 'innovation', label: language.INNOVATION },
              ]}
              beforeColumns={[
                { label: '', ColSpan: 2 },
                { label: language.BENEFITS, ColSpan: 5 },
                { label: language.COMPLEXITY_AND_COST, ColSpan: 5 },
                { label: '' },
              ]}
              data={data_}
            />
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}
