import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './fullcalendar.min.css';
import CommonPage from '../../common/CommonPage';
import { Container, Row, Col } from 'react-grid-system';
import SelectField from 'material-ui/SelectField';
import RaisedButton from '@common/RaisedButton';
import { FaCircle, FaCaretDown } from 'react-icons/lib/fa';

import {
  MenuItem,
  TextField,
  Card,
  Chip,
  Avatar,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import { Draggable, Droppable } from 'react-drag-and-drop';
import ReactGantt, { GanttRow } from 'react-gantt';
import $ from 'jquery';
import CommonForm from '../../common/CommonForm';
// import 'fullcalendar/dist/fullcalendar.js'; // TODO verificar esse import
import { FaClose, FaObjectGroup } from 'react-icons/lib/fa';
import DeviceSignalCellularNull from 'material-ui/SvgIcon';
import Dialog from '@common/Dialog';
import PageLayout from '@common/PageLayout';
import { Tooltip } from 'react-tippy';

let arrayac = [];

@observer
export default class ProjectCalendar extends CommonPage {
  constructor() {
    super();
    this.state = {
      empresaCod: 0,
      emp: '',
      pro: 0,
      idUser: 0,
      today: null,
      openEventModal: null,
      valuesActivity: null,
      start_date: null,
      idproject: 0,
      openmodal: false,
    };
    this.handleValues = this.handleValues.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.handleModalActivity = this.handleModalActivity.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.modal = this.modal.bind(this);
  }

  handleModalActivity() {
    this.setState({ openEventModal: false });
  }
  onChangeField(field, value) {
    if (field.name === 'id_project') {
      this.setState({
        idproject: value,
      });
    }
  }

  modal() {
    this.setState({ openmodal: true });
  }

  modalOpen(obj, project) {
    let valor = null;
    if (obj != null && obj != undefined) {
      if (this.state.idUser != 0) {
        for (let userObject of obj) {
          valor = {
            up: 'update',
            id_project_activity: userObject.id_project_activity,
            id_project: project,
            name: userObject.name,
            user_cad: userObject.user_cad,
            description: userObject.description,
            dt_start: userObject.dt_start,
            hr_start: userObject.dt_start,
            dt_end: userObject.dt_end,
            hr_end: userObject.dt_end,
          };
        }

        this.setState({
          openEventModal: true,
          valuesActivity: valor,
          openmodal: false,
        });
      }
    } else {
      if (this.state.idUser != 0) {
        this.state.valuesActivity = null;
        this.setState({ openEventModal: true, openmodal: false });
        //console.log('tetste');
      }
    }
  }

  componentDidMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.SERVICE_ORDER,
      'serviceOrders'
    );
    var _that = this;
    $('#calendar').fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'agendaWeek,agendaDay',
      },
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      eventLimit: true, // allow "more" link when too many events

      defaultDate: moment().format('YYYY-MM-DD'),
      dayClick: function (date, jsEvent, view) {
        _that.setState({ start_date: date.format() });
        _that.modal();

        $(this).css('background-color', '#FFFFCC');
      },
      eventClick: function (calEvent, jsEvent, view) {
        // var title = prompt('Event Title:', calEvent.title, { buttons: { Ok: true, Cancel: false} });

        if (calEvent.id == undefined && calEvent.ac == undefined) {
          _that.modal();
        } else {
          if (calEvent.ac == true) {
            _that.redirect('/dash/serviceorder');
          } else {
            //console.log(calEvent.id.fk_id_project)

            _that.props.handleActivity(calEvent.id[0].fk_id_project);
          }
        }

        //calEvent.title = title;
        //calendar.fullCalendar('updateEvent',calEvent);
      },
      eventRender: function (event, element) {
        if (event.ac == true) {
          element.css('background-color', '#47d147');
          element.css('color', 'black');
          element.css('font-weight', 'bold');
        } else {
          element.css('font-weight', 'bold');
        }
      },
    });

    $('body').on('click', 'button.fc-next-button', function () {
      $('#calendar').fullCalendar('removeEvents');

      let valuesCalendar = [];

      arrayac.map((c) => {
        $('#calendar').fullCalendar('renderEvent', {
          id: c.id,
          idproject: c.id_project,
          title: c.title,
          start: c.start,
          end: c.end,
          ac: c.ac,
        });
      });
    });
    $('body').on('click', 'button.fc-prev-button', function () {
      $('#calendar').fullCalendar('removeEvents');

      arrayac.map((c) => {
        $('#calendar').fullCalendar('renderEvent', {
          id: c.id,
          idproject: c.id_project,
          title: c.title,
          start: c.start,
          end: c.end,
          ac: c.ac,
        });
      });

      let valuesCalendar = [];
    });
  }

  handleValues(obj, value) {
    if (value == 'empresa') {
      this.setState({ empresaCod: obj });
    } else {
      this.setState({ pro: obj });
    }
  }

  updateCalendar() {
    var _that = this;
    if (_that.state.idUser != null) {
      $('#calendar').fullCalendar('removeEvents');

      let valuesCalendar = [];
      _that.props.calendar
        .filter((c) => c.id_user === _that.state.idUser)
        .map((c) => {
          // valuesCalendar.push({title:c.name,start: moment(c.dt_start ).format('YYYY-MM-DD')  ,end:moment(c.dt_end).format('YYYY-MM-DD')})
          $('#calendar').fullCalendar('renderEvent', {
            id: _that.props.activity
              .filter((d) => d.id_project_activity === c.id_project_activity)
              .map((g) => {
                return g;
              }),
            idproject: c.id_project,
            title: c.name,
            start: moment(c.dt_start).format('YYYY-MM-DD'),
            end: moment(c.dt_end).add(1, 'd').format('YYYY-MM-DD'),
          });
        });
    }
  }

  selectUser(value) {
    arrayac = [];
    $('#calendar').fullCalendar('removeEvents');
    this.setState({ idUser: value });
    let valuesCalendar = [];
    this.props.calendar
      .filter((c) => c.user_cad.indexOf(value) > -1)
      .map((c) => {
        // valuesCalendar.push({title:c.name,start: moment(c.dt_start ).format('YYYY-MM-DD')  ,end:moment(c.dt_end).format('YYYY-MM-DD')})

        arrayac.push({
          id: this.props.activity
            .filter((d) => d.id_project_activity === c.id_project_activity)
            .map((g) => {
              return g;
            }),

          idproject: c.id_project,
          title: c.name,
          start: moment(c.dt_start).format('YYYY-MM-DD'),
          end: moment(c.dt_end).add(1, 'd').format('YYYY-MM-DD'),
          ac: false,
        });
      });

    this.store.serviceOrders
      .filter((c) => c.id_user === parseInt(value))
      .map((c) => {
        //console.log(c);
        arrayac.push({
          id: c.id_os,
          idproject: c.id_os,
          title: 'OS #' + c.id_os,
          start: moment(c.start_date).format('YYYY-MM-DD'),
          end: moment(c.end_date).add(1, 'd').format('YYYY-MM-DD'),
          ac: true,
        });
      });

    arrayac.map((c) => {
      $('#calendar').fullCalendar('renderEvent', {
        id: c.id,
        idproject: c.id_project,
        title: c.title,
        start: c.start,
        end: c.end,
        ac: c.ac,
      });
    });
  }

  render() {
    let { openEventModal, company, valuesActivity } = this.state;
    let {
      language,
      users,
      companies,
      values,
      projects,
      onFormSubmitActivityCalendar,
      activities_project,
    } = this.props;

    this.store.serviceOrders;
    /**approver: 3
closed: true
company_name: "Sensr.IT"
contract_name: "Suporte a Banco de Dados ORACLE"
dt_cad: "2019-05-08T23:43:49.852Z"
end_date: "2019-05-09T22:00:03.000Z"
fk_id_company: 1
id_os: 1
interval: "2019-05-08T09:00:08.232Z"
objective: "Levantamento do catalogo de serviços."
start_date: "2019-05-09T12:00:55.000Z"
status: 2
tech_name: "Fabricio Lúcio Ferreira da Silva"
transferred: (...) */
    let { milestones } = this.store;
    try {
      //  this.updateCalendar();
    } catch (e) {}
    company = values ? values.id_company : this.state.company;
    // user = values ? values.id_user : this.state.user;

    let responsibleOpts = users.map((u) => ({
      value: u.id_user,
      text: u.name,
    }));

    let projectsOpts = projects.map((u) => ({
      value: u.id_project,
      text: u.name,
    }));

    let milestoneGroup = [];

    try {
      milestones != undefined &&
        milestones
          .filter((f) => f.fk_id_project === this.state.idproject)
          .map((u) => {
            milestoneGroup.push({
              value: u.id_project_milestone,
              text: u.name,
            });
          });
    } catch (e) {}

    if (valuesActivity === null) {
      valuesActivity = {
        user_cad: [],
        dt_start: this.state.start_date,
      };
    }

    let fieldsAct = [
      {
        span: 8,
        name: 'id_project',
        label: language.PROJECT,
        type: 'select',
        options: projectsOpts,
        required: true,

        disabled: !!(valuesActivity && valuesActivity.id_project),
      },
      { span: 2, name: 'id_activity', type: 'text', disabled: true },
    ];
    let buttonAct = {
      md: 12,
      label: language.ACTIVITY,
      primary: true,
      style: { marginBottom: 10 },
    };

    let addActivityDialog = (
      <Dialog
        modal={openEventModal}
        open={openEventModal}
        title={language.SELECT_PROJECT}
        onClose={() => this.handleModalActivity()}
        maxWidth="700px"
        contentStyle={{ width: '80%', maxWidth: 'none', height: '400px' }}
      >
        <CommonForm
          values={valuesActivity}
          language={language}
          onChangeField={this.onChangeField}
          fields={fieldsAct}
          onFormSubmit={() => this.props.handleActivity(this.state.idproject)}
          button={buttonAct}
        />
      </Dialog>
    );
    let addItem = (
      <div style={{ width: '100px' }}>
        <Dialog
          modal={this.state.openmodal}
          open={this.state.openmodal}
          title={language.REGISTER_PROJECT_OR_OS}
          maxWidth="100px"
        >
          <RaisedButton
            label={language.REGISTER_OS}
            color="primary"
            onClick={() => this.redirect('/dash/serviceorder/new')}
            className="mr-2"
          />{' '}
          <RaisedButton
            label={language.REGISTER_PROJECT}
            color="primary"
            onClick={() => this.modalOpen(null, null)}
            className="mr-2"
          />
        </Dialog>
      </div>
    );

    let listProjetos = [];
    let cliente = [];
    let listUsers = [];

    let itens = [];
    listUsers = [];
    listUsers.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={0}
        value={0}
        primaryText={language.ALL}
      >
        {language.ALL}
      </MenuItem>
    );

    if (this.props.calendarUser != undefined) {
      this.props.calendarUser.map((c) => {
        if (itens.filter((g) => g.id_user == c.id_user).length == 0) {
          itens.push(c);

          listUsers.push(
            <MenuItem
              key={c.id_user}
              value={c.id_user}
              primaryText={c.nomeuser}
            >
              {c.nomeuser}
            </MenuItem>
          );
        }
      });
      let itemcompany = [];
      if (this.props.pmoList != undefined) {
        cliente.push(
          <MenuItem
            style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
            key={0}
            value={0}
            primaryText={language.ALL}
          >
            {language.ALL}
          </MenuItem>
        );

        let arrayItem = [];
        this.props.pmoList.map((g) => {
          //  console.log(arrayItem.filter(t=>t.id_company === g.id_company).length + " dfdfd")
          if (
            arrayItem.filter((t) => t.id_company === g.id_company).length == 0
          ) {
            arrayItem.push(g);
          }
        });
        arrayItem.map((c) => {
          cliente.push(
            <MenuItem
              key={c.id_company}
              value={c.id_company}
              primaryText={c.namecompany}
            >
              {c.namecompany}
            </MenuItem>
          );
        });
      }
    }

    if (this.state.empresaCod != 0) {
      listProjetos.push(
        <MenuItem
          style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
          key={0}
          value={0}
          primaryText={language.ALL}
        >
          {language.ALL}
        </MenuItem>
      );
      this.props.projects
        .filter((c) => c.fk_id_company === this.state.empresaCod)
        .map((c) => {
          listProjetos.push(
            <MenuItem
              key={c.id_project}
              value={c.id_project}
              primaryText={c.name}
            >
              {c.name}
            </MenuItem>
          );
        });

      if (this.state.pro != 0) {
        listUsers = this.props.calendarUser
          .filter((c) => c.id_project === this.state.pro)
          .map((c) => (
            <MenuItem
              key={c.id_user}
              value={c.id_user}
              primaryText={c.nomeuser}
            >
              {c.nomeuser}
            </MenuItem>
          ));
      }
    } else {
      listProjetos = [];
    }

    return (
      <PageLayout
        icon="iconPrincipalPMO"
        title={language.CALENDAR_OF_ACTIVITIES}
        rightElements={[
          <TextField
            select
            label={language.COMPANY}
            style={{ minWidth: 200, marginLeft: '1em', marginRight: '1em' }}
            value={this.state.empresaCod}
            onChange={(event, key, values) =>
              this.handleValues(event.target.value, 'empresa')
            }
            margin="dense"
            variant="outlined"
          >
            {cliente}
          </TextField>,
          <TextField
            select
            label={language.PROJECT}
            style={{ minWidth: 200, marginLeft: '1em', marginRight: '1em' }}
            value={this.state.pro}
            onChange={(event, key, values) =>
              this.handleValues(event.target.value, 'projeto')
            }
            margin="dense"
            variant="outlined"
          >
            {listProjetos === [] ? null : listProjetos}
          </TextField>,
          <TextField
            select
            label={language.USER}
            style={{ minWidth: 200, marginLeft: '1em', marginRight: '1em' }}
            value={this.state.idUser}
            onChange={(event, key, values) =>
              this.selectUser(event.target.value)
            }
            margin="dense"
            variant="outlined"
          >
            {listUsers}
          </TextField>,
        ]}
      >
        <div>
          {this.state.openmodal === true && this.state.idUser != 0
            ? addItem
            : null}
          {this.state.openEventModal === true && this.state.idUser != 0
            ? addActivityDialog
            : null}

          <div>
            <Card className="cardDefault">
              <Grid container spacing={3} className="px-3">
                <Grid item xs={12} className="pt-4">
                  <div id="calendar" />
                </Grid>
              </Grid>
            </Card>
          </div>
        </div>
      </PageLayout>
    );
  }
}
