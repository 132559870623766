import React from 'react';
import CommonForm from '@common/CommonForm2';
import classnames from 'classnames';
import Constants from '../../../data/Constants';
import Store from '@data/Store';

const { language } = Store;

export default function({data, onChangeField, departments, projects}){
	let periods = [
		{ value: '1 semana', label: '1 semana' },
		{ value: '2 semanas', label: '2 semanas' },
		{ value: '3 semanas', label: '3 semanas' },
		{ value: '4 semanas', label: '4 semanas' },
		{ value: '1 mês', label: '1 mês' },
		{ value: 'Bimestral', label: 'Bimestral' },
		{ value: 'Trimestral', label: 'Trimestral' },
		{ value: 'Semestral', label: 'Semestral' },
	]
	return(
		<div style={{zoom:.9}}>
			<CommonForm
				onChangeField={onChangeField}
				fields={[
					{
						col:4,
						name:'fk_id_department',
						textlabel: language.DEPARTMENTS,
						type: 'autocomplete',
						label: language.DEPARTMENTS,
						method: 'POST',
						route: `${Constants.APIEndpoints.DEPARTMENT}/filter`,
						routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/count?`,
						fieldquery: 'search',
						textinit: language.DEPARTMENTS,
						fieldvaluedb: 'id_department',
						fieldlabeldb: 'name',
						idinit: 0,
						routeAll: `${Constants.APIEndpoints.DEPARTMENT}/list?`,
						methodGetAll: 'POST',
						detail: true,
						required: true,
						showOptionAll : false				
					},
					{
						col:4,
						required:true,
						type:'select',
						name:'fk_id_project',
						label: language.PROJECT,
						options:projects
					},
					{
						col:4,
						required:true,
						type:'select',
						name:'period_objective',
						label:'Período da auditoria',
						options: periods
					},
					{
						col:4,
						required:true,
						type:'date',
						name:'date_final',
						label:'Data Final',
					},
					{
						type:'element',
						content: <hr/>
					},
					{

						type:'element',
						required:true,
						name:'priorization',
						content: (v,handleValue)=>(
							<div>
								<span className='title-label'>{language.PRIORIZATION}</span>
								<div className='content-star mt-1'>
									{[1,2,3,4,5].map(e=>(
										<i 
											className={classnames(e>(v||0)?'far fa-star':'fas fa-star', 'star-icon')} 
											onClick={()=>handleValue(e, 'priorization')}
										/>
									))}
								</div>
							</div>
						)
					}
				]}
			/>
		</div>
	)
}