import React from 'react';
import './index.css';
import moment from 'moment';
import { observer } from 'mobx-react';
import Dialog from '@common/Dialog';

// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import Header from '@common/Header';
import CommonForm from '@common/CommonForm2';
import { flexBasis } from '@common/Func';
import Button from '@common/Button';
// --------------------------------material-ui----------------------------------------------------
import { Card, Grid } from '@material-ui/core';
// --------------------------------pages----------------------------------------------------
import SlaAnalysis from './SlaAnalysis';
import SlaAnalysisTrimestral from './SlaAnalysisTrimestral';
import TotalHoursConsumed from './TotalHoursConsumed';
import NewContracts from './NewContracts';
import ButtonControlMonth from './ButtonControlMonth';
import ButtonControlYear from './ButtonControlYear';
import ReportSintect2 from './ReportSintect2';
import { NewContracts_ } from './Graphs';
import ReporFinanceiro from './ReporFinanceiro'
import { checkPrimeSync, Cipher } from 'crypto';
import Store from '@data/Store';
const { language } = Store;

@observer
export default class extends CommonPage {
  constructor() {
    super();
    this.state = {
      idx: 0,
      currentMonth: parseInt(moment().format('M')),
      currentYear: parseInt(moment().format('YYYY')),
      contract: null,
      opensintect: false,
      contractDetail: [],
      contracts: [],
      tickets: [],
      devices: [],
      inventory: [],
      openFinanc: false,
      dadaosFinanc: [],
      budgetcontrols: [],
      companySelected: 0,
      general_params: [],
      jobsConsumedHours : 0,
    };
    this.handleSelectContract = this.handleSelectContract.bind(this);
    this.computNewContracts = this.computNewContracts.bind(this);
    this.funcSintetic = this.funcSintetic.bind(this);
    this.handleCompanies = this.handleCompanies.bind(this);
    this.funcSintectFinanceiro = this.funcSintectFinanceiro.bind(this);
  }
  componentDidMount() {
    this.action.execute('get', this.APIEndpoints.SLA, 'slas');
  }

  componentDidUpdate(prevProps) {
    //console.log('prevProps', prevProps);
  }

  host = () => {
    if (window.location.hostname == 'localhost') {
      return (
        window.location.protocol + '//' + window.location.hostname + ':3000'
      );
    } else {
      return window.location.protocol + '//' + window.location.hostname;
    }
  };

  componentWillMount() {

    this.action.execute('get', this.APIEndpoints.COMPANY_TIME, 'companytimes');
    this.action.execute('get', this.APIEndpoints.BUGET, 'budgetcontrols')
    this.action
      .execute(
        'post',
        this.APIEndpoints.INVENTORY + '/1',
        'devices',
        null,
        null,
        null,
        null
      )
      .then((res) => {
        this.setState({ devices: res.data });
      });
    this.action
      .execute(
        'get',
        this.APIEndpoints.INVENTORY + '/getallinventory',
        'inventories'
      )
      .then((res) => {
        this.setState({ inventory: res.data });
      });
    this.action
      .execute(
        'get',
        this.APIEndpoints.GENERAL_PARAMETERS,
      )
      .then((res) => {
        this.setState({ general_params: res.data });
      });

  }

  handleSelectContract(obj, value, monthSelect = ``, yearSelect = ``) {
    if ((value !== '' && value !== 0) || !!monthSelect || !!yearSelect) {
      if (value) {
        this.setState({ idx: value });
      }

      if (monthSelect) {
        this.setState({ currentMonth: monthSelect });
      }
      if (yearSelect) {
        this.setState({ currentYear: yearSelect });
      }

      let month = monthSelect || this.state.currentMonth;
      let year = yearSelect || this.state.currentYear;

      let month_ini_format = month > 9 ? '' : '0' + month;
      let month_end_format = month > 8 ? '' : '0' + (month + 1);

      let formatDate = moment(
        month.toString().padStart(2, '0') + year,
        'MMYYYY'
      ).format('MMYYYY');

      if ((value > 0 || this.state.idx > 0) && value !== 'todos') {
        let id_contract = value || this.state.idx;

        this.action
          .execute(
            'post',
            this.APIEndpoints.CONTRACT + '/getContractConsumed',
            '',
            { id_contract, dateFilter: formatDate }
          )
          .then((response) => {
            this.setState({ contract: response.data });
            this.setState({ tickets: response.data.tickets });
            let date = moment(
              month.toString().padStart(2, '0') + year,
              'MMYYYY'
            ).format('MMYY');
                 let jobsHours = this.handleTime(
              response.data.jobsConsumed,
              date
            );
    
            this.setState({jobsConsumedHours:jobsHours})
      
          });
      } else if (this.state.idx === 'todos' || value === 'todos') {
        this.action
          .execute(
            'post',
            this.APIEndpoints.CONTRACT + '/getAllContractConsumed',
            '',
            { dateFilter: formatDate }
          )
          .then((response) => {
            this.setState({ contract: response.data });
            this.setState({ tickets: response.data.tickets });
          });
      }
    }
  }

  //filtrar consumido por mmyy e converter hora para inteiro
  handleFilterConsumed(obj, date) {
    let value =
      (obj.filter((v) => v.to_char === date).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHour).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHour)[0]) ||
      0;
    return parseFloat(value);
  }

  calcHour(obj) {
    if (obj.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = obj.sum;
      return days * 24 + hours + minutes / 60 + seconds / 60 / 60;
    } else {
      return 0;
    }
  }

  handleTime(obj, date) {
    let value =
      (obj.filter((v) => v.to_char === date).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHourInTime).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHourInTime)[0]) ||
      0;
    return value;
  }

  calcHourInTime(obj) {
    if (obj.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = obj.sum;
      return days * 24 + hours + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    } else {
      return 0;
    }
  }

  handleAverage12Months(contract) {

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (contract) {
      for (let i = 0; i < 12; i++) {
        let allTime = this.handleAllTime(
          !this.state.general_params.associate_remote_access_ticket ? contract.assessment : [],
          contract.osconsumed,
          contract.changesConsumed,
          contract.projectConsumed,
          contract.ticketconsumed,
          moment().subtract(i, 'month').format('MMYY'))
        let timeArray = allTime.split(':')
        hours = hours + parseInt(timeArray[0])
        minutes = minutes + parseInt(timeArray[1])
        seconds = seconds + parseInt(timeArray[2])

      }
  
      let timeInSecondsAverage = (hours * 60 * 60 + minutes * 60 + seconds)/12

      hours = Math.floor(timeInSecondsAverage / 60 / 60)
      minutes = Math.floor((timeInSecondsAverage % (hours * 60 * 60))/60 || 0)
      seconds = Math.floor((timeInSecondsAverage - hours * 60 * 60) % (minutes *60) || 0)
      if (seconds >= 60) {
        minutes = minutes + Math.floor(seconds / 60);
        seconds = (seconds % 60)
      }
  
      if (minutes >= 60) {
        hours = hours + Math.floor(minutes / 60);
        minutes = (minutes % 60)
      }
    }
    return hours + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
  }

  handleAllTime(assessment, os, gmud, project, tickets, date) {
    let hour_assessment =
      (assessment.filter((v) => v.to_char === date).length > 0 &&
        assessment.filter((v) => v.to_char === date)[0]) ||
      [];

    let hour_os =
      (os.filter((v) => v.to_char === date).length > 0 &&
        os.filter((v) => v.to_char === date)[0]) ||
      [];

    let hour_gmud =
      (gmud.filter((v) => v.to_char === date).length > 0 &&
        gmud.filter((v) => v.to_char === date)[0]) ||
      [];

    let hour_project =
      (project.filter((v) => v.to_char === date).length > 0 &&
        project.filter((v) => v.to_char === date)[0]) ||
      [];

    let hour_tickets =
      (tickets.filter((v) => v.to_char === date).length > 0 &&
        tickets.filter((v) => v.to_char === date)[0]) ||
      [];
    let total = this.calcAllTime(hour_assessment, hour_os, hour_gmud, hour_project, hour_tickets)

    return total
  }
  calcAllTime(assessment, os, gmud, project, tickets) {
    let timeAss, timeOs, timeGmud, timePro, timeTic
    let hoursT = 0, minutesT = 0, secondsT = 0
    if (assessment.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = assessment.sum;
      hoursT = hoursT + days * 24 + hours;
      minutesT = minutesT + minutes;
      secondsT = secondsT + seconds;
    }

    if (project.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = project.sum;
      hoursT = hoursT + days * 24 + hours;
      minutesT = minutesT + minutes;
      secondsT = secondsT + seconds;
    }

    if (os.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = os.sum;
      hoursT = hoursT + days * 24 + hours;
      minutesT = minutesT + minutes;
      secondsT = secondsT + seconds;
    }

    if (gmud.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = gmud.sum;
      hoursT = hoursT + days * 24 + hours;
      minutesT = minutesT + minutes;
      secondsT = secondsT + seconds;
    }

    if (tickets.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = tickets.sum;
      hoursT = hoursT + days * 24 + hours;
      minutesT = minutesT + minutes;
      secondsT = secondsT + seconds;
    }

    if (secondsT >= 60) {
      minutesT = minutesT + Math.floor(secondsT / 60);
      secondsT = (secondsT % 60)
    }

    if (minutesT >= 60) {
      hoursT = hoursT + Math.floor(minutesT / 60);
      minutesT = (minutesT % 60)
    }

    return hoursT + ':' + minutesT.toString().padStart(2, '0') + ':' + secondsT.toString().padStart(2, '0');


  }

  funcSintetic() {
    let id_contract = this.state.idx;
    if (id_contract > 0) {
      this.store.toggleAlert(true, language.WAIT_GENERATING_REPORT, 'success');
      let month = this.state.currentMonth;
      let year = this.state.currentYear;
      let formatDate = moment(
        month.toString().padStart(2, '0') + year,
        'MMYYYY'
      ).format('MMYYYY');

      this.action
        .execute(
          'post',
          this.APIEndpoints.CONTRACT + '/getContractConsumed',
          '',
          { id_contract, dateFilter: formatDate, sintect: true }
        )
        .then((response) => {
          this.setState({ contractDetail: response.data, opensintect: true });
        });
    } else {
      this.store.toggleAlert(true, language.SELECT_A_CONTRACT, 'error');
    }
  }

  funcSintectFinanceiro() {
    let id_contract = this.state.idx;

    this.store.toggleAlert(true, language.WAIT_GENERATING_REPORT, 'success');
    let month = this.state.currentMonth;
    let year = this.state.currentYear;
    let formatDate = moment(
      month.toString().padStart(2, '0') + year,
      'MMYYYY'
    ).format('MMYYYY');


    this.action
      .execute(
        'post',
        this.APIEndpoints.CONTRACT + '/getContractConsumedFinanc',
        '',
        { month, year }
      )
      .then((response) => {
        this.setState({ openFinanc: true, dadaosFinanc: response.data });
      });
  }

  //filtrar por yy/mm realizado
  handleCalcRealized(realizado, mesAno) {

    let realizados_ = realizado.filter((cr) => cr.month + cr.year * 12 == parseInt(mesAno.substring(0, 2)) + parseInt(mesAno.substring(3, 7)) * 12);
    let value = 0;
    if (realizados_.length > 0) {
      value = realizados_[realizados_.length - 1].realized_cost
    }
    //  realizados_.map(r => value += r.realized_cost)
    return value
  }

  handleCompanies(values) {
       const data = {fk_id_out_company : values}
      this.action.execute('get', this.APIEndpoints.CONTRACT + '/company/' + values, '').then(res => {
        this.setState({ contracts: res.data, companySelected: values });
      })


  }

  computNewContracts(contract) {
    let { currentYear, currentMonth } = this.state;
    let year = moment(currentYear, 'YYYY').format('YY');
    if (contract) {
 
      let budget = this.store.budgetcontrols.filter(b => b.name == contract.name && b.expected_value.length > 0)[0]
      let { name, trend } = contract;
      let previstoatual =
        contract.hours != 0
          ? parseInt(contract.hours) * parseInt(contract.value)
          : parseInt(contract.value);

      let valuerealized = [];
      let consumeds = [];
      trend && trend.map((e) => {
        let splited = e[0].split('/');
        splited[0] = parseInt(splited[0]).toString().padStart(2, '0');
        let mmYYYY = splited.join('/');
        try {
          if (
            contract.contract_month != undefined &&
            contract.contract_month != null &&
            contract.contract_month.filter((c) => c.period == mmYYYY)
          ) {
            let valor = contract.contract_month.filter(
              (c) => c.period == mmYYYY
            )[0];
            valuerealized.push(parseFloat(valor.valor));
          } else {
            valuerealized.push(0);
          }
        } catch (r) {
          valuerealized.push(0);
        }
      });

      let contractComput = this.state.contract;
      if (contract.type === 'hour') {
      //  let value_contract = budget && budget.expected_value.filter(b => b.year == this.state.currentYear && b.month == this.state.currentMonth)[0] ? budget.expected_value.filter(b => b.year == this.state.currentYear && b.month == this.state.currentMonth)[0].value / contract.hours : 0;

        let data = Array.from(new Array(12), (_, i) => {
          let date = (i + 1).toString().padStart(2, '0') + year;
          let value_contract = budget && budget.expected_value.filter(b => b.year == this.state.currentYear && parseInt(b.month) == parseInt(i + 1))[0] ? budget.expected_value.filter(b => b.year == this.state.currentYear && parseInt(b.month) == parseInt(i + 1))[0].value / contract.hours : 0;
          let currentConsumed =
            ((!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contractComput.assessment, date) : 0) +
              this.handleFilterConsumed(contractComput.changesConsumed, date) +
              //   this.handleFilterConsumed(contractComput.os, date) +
              this.handleFilterConsumed(contractComput.osconsumed, date) +
              this.handleFilterConsumed(contractComput.projectConsumed, date) +
              this.handleFilterConsumed(contractComput.ticketconsumed, date)) *
            parseFloat(value_contract > 0 ? value_contract : contractComput.contract[0].value);




          currentConsumed = parseFloat(currentConsumed.toFixed(2));
          return currentConsumed;
        });

        consumeds = data;
      } else {
        let data = Array.from(new Array(12), (_, i) => {
          let date = (i + 1).toString().padStart(2, '0') + year;

          let currentConsumed =
            (!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contractComput.assessment, date) : 0) +
            this.handleFilterConsumed(contractComput.changesConsumed, date) +
            // this.handleFilterConsumed(contractComput.os, date) +
            this.handleFilterConsumed(contractComput.osconsumed, date) +
            this.handleFilterConsumed(contractComput.projectConsumed, date) +
            this.handleFilterConsumed(contractComput.ticketconsumed, date);
          currentConsumed = parseFloat(currentConsumed.toFixed(2));

          return currentConsumed;
        });

        consumeds = data;
      }
      return [valuerealized.reverse(), consumeds, previstoatual || 0];
    } else {
      //previsto
      let previstoAtual = this.store.contracts.reduce((prev, actual) => {
        if (actual.hours !== 0) {
          return prev + parseInt(actual.hours) * parseFloat(actual.value);
        }

        return prev + parseFloat(actual.value);
      }, 0);

      //realized
      let valueRealized = {};
      this.store.contracts.forEach((contract) => {
        let { trend } = contract;
        trend.map((t) => {
          let splited = t[0].split('/');
          splited[0] = parseInt(splited[0]).toString().padStart(2, '0');
          let mmYYYY = splited.join('/');
          try {
            if (
              contract.contract_month != undefined &&
              contract.contract_month != null &&
              contract.contract_month.filter((c) => c.period == mmYYYY)
            ) {
              let contractMonth = contract.contract_month.filter(
                (c) => c.period == mmYYYY
              )[0];

              if (
                valueRealized[contractMonth.period] &&
                valueRealized[contractMonth.period].period == mmYYYY
              ) {
                let value = !!contractMonth.valor
                  ? parseFloat(contractMonth.valor)
                  : 0;
                valueRealized[contractMonth.period].valor =
                  parseFloat(valueRealized[contractMonth.period].valor) + value;
              } else {

                valueRealized[contractMonth.period] = contractMonth;
              }
            } else {
              if (!valueRealized[mmYYYY]) {
                valueRealized[mmYYYY] = {
                  valor: 0,
                  period: mmYYYY,
                };
              }
            }
          } catch (r) {
          }
        });
      });
      let valuesRealized = Object.values(valueRealized).map((x) => x.valor);

      //consumeds
      let consumeds;
      let dataConsumeds = Array.from(new Array(12), (_, i) => {
        let date = (i + 1).toString().padStart(2, '0') + year;
        let currentConsumed =
          (!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(this.state.contract.assessment, date) : 0) +
          this.handleFilterConsumed(this.state.contract.changesConsumed, date) +
          //this.handleFilterConsumed(this.state.contract.os, date) +
          this.handleFilterConsumed(this.state.contract.osconsumed, date) +
          this.handleFilterConsumed(this.state.contract.projectConsumed, date) +
          this.handleFilterConsumed(this.state.contract.ticketconsumed, date);

        currentConsumed = parseFloat(currentConsumed.toFixed(2));
        return currentConsumed;
      });
      consumeds = dataConsumeds;
      return [valuesRealized.reverse(), consumeds, previstoAtual || 0];
    }
  }

  render() {
    let { companies, users, companytimes, devices, budgetcontrols } = this.store;

    const { idx, contracts, tickets, currentMonth, contract, currentYear } =
      this.state;
    let selectCompanies =
      companies.length > 0 &&
      companies.map((c, i) => ({
        label: c.name,
        value: c.id_company,
      }));
    let selectContracts =
      contracts.length > 0 &&
      contracts.map((c, i) => ({
        label: c.name,
        value: c.id_contract,
      }));

    if (!!selectContracts) {
      selectContracts.push({
        label: '-------- TODOS --------',
        value: 'todos',
      });
    }
    /*-----------------------------------SlaAnalysisProps---------------------------------------------*/
    let oks = 0;
    let total = 0;
    let percentSlaAn = 0;

    if (contract) {
      oks = contract.sla.slaok;
      total = contract.sla.slaTotal;
      percentSlaAn = Math.floor((oks / total) * 100) || 0;
    }

    let SlaAnalysisProps = {
      percent: percentSlaAn, //---porcentagem
      monthPosition: currentMonth - 1, //----index 6 = JULHO
      slaok: contract ? contract.sla.slaok : 0,
      total: contract ? contract.sla.slaTotal : 0,
    };
    /*-----------------------------------SlaAnalysisTrimestralProps---------------------------------------------*/
    let triSla = [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ];


    let budget = []
    if (contract) {

      let mesAno = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).format('MMYY');
      let mesAnoPrev = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      )
        .add(-1, 'M')
        .format('MMYY');
      let mesAnoPrev2 = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      )
        .add(-2, 'M')
        .format('MMYY');

      let mesAnoRealized = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).format('MM/YYYY');
      let mesAnoRealizedPrev = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      )
        .add(-1, 'M')
        .format('MM/YYYY');
      let mesAnoRealizedPrev2 = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      )
        .add(-2, 'M')
        .format('MM/YYYY');


      let month = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).format('MM');

      let monthPrev = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).add(-1, 'M').format('MM');

      let monthPrev2 = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).add(-2, 'M').format('MM');



      let year = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).format('YYYY');

      let yearPrev = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).add(-1, 'M').format('YYYY');

      let yearPrev2 = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).add(-2, 'M').format('YYYY');

      let prevConsumed = 0;
      let prev2Consumed = 0;
      let currentConsumed = 0;
      budget = budgetcontrols.filter(b => b.name == contract.contract[0].name && b.expected_value.length > 0)[0]
      if (contract.contract[0].type === 'hour' && idx !== 'todos') {
        let value_contract_current = budget && budget.expected_value.filter(b =>  b.year == year &&  b.month == parseInt(month))[0] ? budget.expected_value.filter(b => b.year == year && b.month == parseInt(month))[0].value / contract.contract[0].hours : 0;
        currentConsumed =
          ((!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contract.assessment, mesAno) : 0) +
            this.handleFilterConsumed(contract.changesConsumed, mesAno) +
            this.handleFilterConsumed(contract.osconsumed, mesAno) +
            this.handleFilterConsumed(contract.projectConsumed, mesAno) +
            this.handleFilterConsumed(contract.ticketconsumed, mesAno)) *
          parseFloat(value_contract_current > 0 ? value_contract_current : contract.contract[0].value);

          let value_contract_prev = budget && budget.expected_value.filter(b => b.year == yearPrev && b.month == parseInt(monthPrev))[0] ? budget.expected_value.filter(b => b.year == yearPrev && b.month == parseInt(monthPrev))[0].value / contract.contract[0].hours : 0;
          prevConsumed =
          (
            (!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contract.assessment, mesAnoPrev) : 0) +
            this.handleFilterConsumed(contract.changesConsumed, mesAnoPrev) +
            this.handleFilterConsumed(contract.osconsumed, mesAnoPrev) +
            this.handleFilterConsumed(contract.projectConsumed, mesAnoPrev) +
            this.handleFilterConsumed(contract.ticketconsumed, mesAnoPrev)) *
          parseFloat(value_contract_prev > 0 ? value_contract_prev : contract.contract[0].value);

          let value_contract_prev2 = budget && budget.expected_value.filter(b => b.year == yearPrev2 && b.month == parseInt(monthPrev2))[0] ? budget.expected_value.filter(b => b.year == yearPrev2 && b.month == parseInt(monthPrev2))[0].value / contract.contract[0].hours : 0;

          prev2Consumed =
          ((!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contract.assessment, mesAnoPrev2) : 0) +
            this.handleFilterConsumed(contract.changesConsumed, mesAnoPrev2) +
            this.handleFilterConsumed(contract.osconsumed, mesAnoPrev2) +
            this.handleFilterConsumed(contract.projectConsumed, mesAnoPrev2) +
            this.handleFilterConsumed(contract.ticketconsumed, mesAnoPrev2)) *
          parseFloat(value_contract_prev2 > 0 ? value_contract_prev2 : contract.contract[0].value);


        } else {
        //edit 22/10/2020
        currentConsumed =
          (!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contract.assessment, mesAno) : 0) +
          this.handleFilterConsumed(contract.changesConsumed, mesAno) +
          //this.handleFilterConsumed(contract.os, mesAno) +
          this.handleFilterConsumed(contract.osconsumed, mesAno) +
          this.handleFilterConsumed(contract.projectConsumed, mesAno) +
          this.handleFilterConsumed(contract.realizado, mesAno) +
          this.handleFilterConsumed(contract.ticketconsumed, mesAno);
        prevConsumed =
          (!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contract.assessment, mesAnoPrev) : 0) +
          this.handleFilterConsumed(contract.changesConsumed, mesAnoPrev) +
          //this.handleFilterConsumed(contract.os, mesAnoPrev) +
          this.handleFilterConsumed(contract.osconsumed, mesAnoPrev) +
          this.handleFilterConsumed(contract.projectConsumed, mesAnoPrev) +
          this.handleFilterConsumed(contract.realizado, mesAnoPrev) +
          this.handleFilterConsumed(contract.ticketconsumed, mesAnoPrev);

        prev2Consumed =
          (!this.state.general_params.associate_remote_access_ticket ? this.handleFilterConsumed(contract.assessment, mesAnoPrev2) : 0) +
          this.handleFilterConsumed(contract.changesConsumed, mesAnoPrev2) +
          //this.handleFilterConsumed(contract.os, mesAnoPrev2) +
          this.handleFilterConsumed(contract.osconsumed, mesAnoPrev2) +
          this.handleFilterConsumed(contract.projectConsumed, mesAnoPrev2) +
          this.handleFilterConsumed(contract.realizado, mesAnoPrev2) +
          this.handleFilterConsumed(contract.ticketconsumed, mesAnoPrev2);
      }
      let currentRealized = this.handleCalcRealized(
        contract.realizado,
        mesAnoRealized
      );
      let currentRealizedPrev = this.handleCalcRealized(
        contract.realizado,
        mesAnoRealizedPrev
      );
      let currentRealizedPrev2 = this.handleCalcRealized(
        contract.realizado,
        mesAnoRealizedPrev2
      );

      let previstoCurent;
      let previstoPrev;
      let previstoPrev2;
      if (idx === 'todos') {

        previstoCurent = budgetcontrols
          .reduce((arrs, e) => {
            let _contract = contract.contract.filter(c => c.name == e.name)
            let expected_cost = _contract[0] && _contract[0].type == "hour" ? _contract[0].value * _contract[0].hours : e.expected_cost
            return e.period && e.period.includes(parseInt(month)) && _contract ? (_contract.readjustment_percent > 0 && _contract.reference_month <= (month) ?
              (arrs + expected_cost + (expected_cost * _contract.readjustment_percent / 100)) : (arrs + expected_cost)) : 0;
          }, 0)
        previstoPrev = budgetcontrols
          .reduce((arrs, e) => {
            let _contract = contract.contract.filter(c => c.name == e.name)
            let expected_cost = _contract[0] && _contract[0].type == "hour" ? _contract[0].value * _contract[0].hours : e.expected_cost

            return e.period && e.period.includes(parseInt(monthPrev)) && _contract ? _contract.readjustment_percent > 0 && _contract.reference_month <= (monthPrev) ?
              (arrs + expected_cost + (expected_cost * _contract.readjustment_percent / 100)) : (arrs + expected_cost) : 0;
          }, 0)

        previstoPrev2 = budgetcontrols
          .reduce((arrs, e) => {
            let _contract = contract.contract.filter(c => c.name == e.name)
            let expected_cost = _contract[0] && _contract[0].type == "hour" ? _contract[0].value * _contract[0].hours : e.expected_cost

            return e.period && e.period.includes(parseInt(monthPrev2)) && _contract ? _contract.readjustment_percent > 0 && _contract.reference_month <= (monthPrev2) ?
              (arrs + expected_cost + (expected_cost * _contract.readjustment_percent / 100)) : (arrs + expected_cost) : 0;
          }, 0)

      } else {

        previstoCurent = budget && budget.expected_value.filter(b => b.year == currentYear && b.month == currentMonth)[0] ? budget.expected_value.filter(b => b.year == currentYear && b.month == currentMonth)[0].value : 0;
        previstoPrev = budget && budget.expected_value.filter(b => b.year == (currentMonth == 1 ? currentYear - 1 : currentYear) && b.month == monthPrev)[0] ? budget.expected_value.filter(b => b.year == (currentMonth == 1 ? currentYear - 1 : currentYear) && b.month == monthPrev)[0].value : 0;
        previstoPrev2 = budget && budget.expected_value.filter(b => b.year == (currentMonth == 1 || currentMonth == 2 ? currentYear - 1 : currentYear) && b.month == monthPrev2)[0] ? budget.expected_value.filter(b => b.year == (currentMonth == 1 || currentMonth == 2 ? currentYear - 1 : currentYear) && b.month == monthPrev2)[0].value : 0;

      }
      triSla = [
        [
          parseFloat(previstoPrev2.toFixed(2)),
          parseFloat(currentRealizedPrev2.toFixed(2)),
          parseFloat(prev2Consumed.toFixed(2)),
        ],
        [
          parseFloat(previstoPrev.toFixed(2)),
          parseFloat(currentRealizedPrev.toFixed(2)),
          parseFloat(prevConsumed.toFixed(2)),
        ],
        [
          parseFloat(previstoCurent.toFixed(2)),
          parseFloat(currentRealized.toFixed(2)),
          parseFloat(currentConsumed.toFixed(2)),
        ],
      ];
    }
    let SlaAnalysisTrimestralProps = {
      data: triSla /*[
        [50, 60, 70], //´gáfico 1 --previsto, realizado, consumido
        [90, 80, 20], //´gáfico 2 --previsto, realizado, consumidoC
        [55, 11, 67], //´gáfico 3 --previsto, realizado, consumido
      ]*/,
      monthPosition: currentMonth - 1,
    };
    /*-----------------------------------TotalHoursConsumedProps---------------------------------------------*/
    let obj = contracts.length > 0 && contracts[0];
    let hours_consumer_assessment = 0,
      hours_consumer_os = 0,
      hours_gmud = 0,
      hours_project = 0,
      hours_tickets = 0,
      hours_total_consumer = 0;
    if (contract) {
      let monthYear = moment(
        currentMonth.toString().padStart(2, '0') + currentYear,
        'MMYYYY'
      ).format('MMYY');
      if (!this.state.general_params.associate_remote_access_ticket) {
        hours_consumer_assessment = this.handleTime(
          contract.assessment,
          monthYear
        );
      }
      hours_consumer_os = this.handleTime(
        contract.osconsumed,
        monthYear
      );
      hours_gmud = this.handleTime(
        contract.changesConsumed,
        monthYear
      );
      hours_project = this.handleTime(
        contract.projectConsumed,
        monthYear
      );
      hours_tickets = this.handleTime(
        contract.ticketconsumed,
        monthYear
      );
      hours_total_consumer = this.handleAllTime(
        !this.state.general_params.associate_remote_access_ticket ? contract.assessment : [],
        contract.osconsumed,
        contract.changesConsumed,
        contract.projectConsumed,
        contract.ticketconsumed,
        monthYear)
    }

    let TotalHoursConsumedProps = {
      data: [
        {
          name: language.REMOTE_ACCESS,
          current: !this.state.general_params.associate_remote_access_ticket ? hours_consumer_assessment : 0,//.toFixed(2),
          color: 'var(--primary)',
        },
        {
          name: language.SERVICE_ORDER,
          current: hours_consumer_os,//.toFixed(2),
          color: 'var(--success)',
        },
        {
          name: language.GMUD,
          current: hours_gmud,//.toFixed(2),
          color: 'var(--warning)',
        },
        {
          name: language.PROJECT,
          current: hours_project,//.toFixed(2),
          color: 'var(--danger)',
        },
        {
          name: 'Tickets',
          current: hours_tickets,//.toFixed(2),
          color: 'var(--purple)',
        },
        {
          name: language.TOTAL_HOURS_CONSUMED,
          current: hours_total_consumer,//.toFixed(2),
          color: 'var(--primary)',
        },
        {
          name: language.AVERAGE_OF_THE_LAST_TWELVE_MONTHS,
          current: this.handleAverage12Months(contract),
          color: 'var(--primary)',
        },
      ],
    };
    /*-----------------------------------NewContractsProps---------------------------------------------*/
    let values = [];
    let NewContractsProps = {
      categories: language.DATE_LANG.monthLongList,
      consumed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      realized: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      foreseen: Array.from(new Array(12), (_, i) => 0),
    };
    if (contracts.length > 0 && idx !== 0 && idx !== 'todos' && contract) {
      contracts.map((x) => {
        if (x.id_contract === idx) {
          values = contracts.length > 0 && this.computNewContracts(x);

          NewContractsProps = {
            categories: language.DATE_LANG.monthLongList,
            consumed: values[1],
            realized: values[0],
            foreseen: Array.from(new Array(12), (_, i) => values[2]),
          };
        }
      });
    } else if (contracts.length > 0 && idx === 'todos' && contract) {
      values = this.computNewContracts();
      NewContractsProps = {
        categories: language.DATE_LANG.monthLongList,
        consumed: values[1],
        realized: values[0],
        foreseen: Array.from(new Array(12), (_, i) => values[2]),
      };
    }

    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        <PageLayout
          title="Dashboard"
          subTitle={language.DASHBOARD_PAGE.GCF + '/'}
          rightElements={[
            <div
              style={{ width: 1000, zoom: 0.8, marginTop: '1rem' }}
              zoom={0.8}
              className="d-flex align-items-center justify-content-between"
            >
              {this.store.getUserLoged().role == 'client' && (
              <Button
                variant="normal"
                color="primary"
                label={language.FINANCIAL_SUMMARY}
                style={{ marginTop: '1rem' }}
                onClick={() => this.funcSintectFinanceiro()}
              />
              )}
              <Button
                variant="normal"
                color="primary"
                label={language.SYNTHETIC}
                style={{ marginTop: '1rem', marginLeft: '5px' }}
                onClick={() => this.funcSintetic()}
              />
              <div style={{ marginBottom: '-0.5rem' }}>
                <ButtonControlYear
                  value={currentYear}
                  onChange={(e) =>
                    this.handleSelectContract(null, null, null, e)
                  }
                />
              </div>
              <div style={{ marginBottom: '-0.5rem' }}>
                <ButtonControlMonth
                  value={currentMonth}
                  onChange={(e) => this.handleSelectContract(null, null, e)}
                />

              </div>
              <div
                style={{ width: 600, marginLeft: '20px', marginTop: '20px' }}
              >
                <CommonForm
                  fields={[
                    {
                      col: 5,
                      type: 'autocomplete',
                      name: 'companies',
                      label: language.COMPANY_LIST,
                      method: 'POST',
                      route: `${this.APIEndpoints.COMPANY}/filter`,
                      fieldquery: 'search',
                      textlabel: `Empresa *`,
                      textinit: language.COMPANY,
                      fieldvaluedb: 'id_company',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${this.APIEndpoints.COMPANY}/all?`,
                      methodGetAll: 'POST',
                      routeGetCount: `${this.APIEndpoints.COMPANY}/count`,
                      loadOnFocus : true
                    },
                    {
                      type: 'select',
                      name: 'contracts',
                      label: language.CONTRACT_LIST,
                      options: selectContracts,
                      col: 7,
                    },
                  ]}
                  onChangeField={(f, v) => {
                    if (f.name == 'contracts') {
                      this.handleSelectContract('', v);
                    } else if (f.name == 'companies') {
                      this.handleCompanies(v);
                    }
                  }}
                  // onChangeField={this.handleSelectContract}
                  values={{ contracts: idx }}
                />
              </div>
            </div>,
          ]}
        >
          <div id="GCF">
            <Header />
            <Card className="content mb-3">
              <Grid container spacing={8}>
                <Grid item xs={2} style={{ ...flexBasis(2) }}>
                  <SlaAnalysis
                    {...SlaAnalysisProps}
                    language={language}
                    tickets={this.state.tickets}
                    companies={companies}
                    users={this.store.users}
                  />
                </Grid>
                <Grid item xs={6} style={{ ...flexBasis(6) }}>
                  <SlaAnalysisTrimestral
                    {...SlaAnalysisTrimestralProps}
                    language={language}
                  />
                </Grid>
                <Grid item xs={4} style={{ ...flexBasis(4) }}>
                  <TotalHoursConsumed
                    {...TotalHoursConsumedProps}
                    language={language}
                    month={currentMonth}
                    year={currentYear}
                  />
                </Grid>
              </Grid>
            </Card>
            <Header />
            <Card className="content">
              <NewContracts
                contract={this.state.contract} {...NewContractsProps}
                language={language}
                budgetcontrols={budgetcontrols}
                month={currentMonth}
                year={currentYear}
              />
            </Card>
          </div>

          {this.state.opensintect ? (
            <Dialog
              title={language.SYNTHETIC_DETAILS}
              open={this.state.opensintect}
              maxWidth="xl"
              monitor_scroll
              onClose={() => this.setState({ opensintect: false })}
            >
              <ReportSintect2
                contract={this.state.contractDetail}
                NewContractsProps={NewContractsProps}
                currentMonth={currentMonth}
                currentYear={currentYear}
                hours_total_consumer={hours_total_consumer}
                mes={language.DATE_LANG.monthLongList[currentMonth - 1]}
                language={language}
                currentConsumed={0}
                TotalHoursConsumedProps={TotalHoursConsumedProps}
                currentSla={
                  SlaAnalysisTrimestralProps.data.length > 0
                    ? SlaAnalysisTrimestralProps.data[2]
                    : [0, 0, 0]
                }
                users={this.store.users}
                companies={companies}
                companytimes={companytimes}
                devicesAll={this.state.devices}
                inventoryAll={this.state.inventory}
                budgetcontrols={budgetcontrols}
                jobsConsumedHours = {this.state.jobsConsumedHours}
                companySelected = {this.state.companySelected}
              />
            </Dialog>
          ) : null}

          {this.state.openFinanc ? (
            <Dialog
              title={language.SYNTHETIC_DETAILS}
              open={this.state.openFinanc}
              maxWidth="xl"
              monitor_scroll
              onClose={() => this.setState({ openFinanc: false })}
            >
              <ReporFinanceiro
                {...NewContractsProps}
                language={language}
                dadosFinanc={this.state.dadaosFinanc}
                contract={this.state.contractDetail}
                NewContractsProps={NewContractsProps}
                currentMonth={currentMonth}
                currentYear={currentYear}
                hours_total_consumer={hours_total_consumer}
                mes={language.DATE_LANG.monthLongList[currentMonth - 1]}
                currentConsumed={0}
                TotalHoursConsumedProps={TotalHoursConsumedProps}
                currentSla={
                  SlaAnalysisTrimestralProps.data.length > 0
                    ? SlaAnalysisTrimestralProps.data[2]
                    : [0, 0, 0]
                }
                users={this.store.users}
                companies={companies}
                companytimes={companytimes}
                devicesAll={this.state.devices}
                inventoryAll={this.state.inventory}
              />
            </Dialog>
          ) : null}
        </PageLayout>
      </CommonPage>
    );
  }
}
