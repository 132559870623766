import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import MdDashboard from "react-icons/lib/md/dashboard";

import withLanguage from "../../common/languages/withLanguage";

const styles = {
  Container: {
    display: "flex",
    height: "100%"
  },
  IconContainer: {
    paddingLeft: 20,
    paddingTop: 20
  },
  DescriptionContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "column"
  },
  Title: {
    margin: 0,
    textAlign: "left",
    color: "var(--primary)",
    fontSize: 24,
    fontWeight: 700
  },
  Description: {
    color: "var(--primary)",
    flex: 1
  },
  Button: {
    border: "1px solid var(--primary)",
    backgroundColor: "#fff",
    color: "var(--primary)",
    width: "100%",
    height: 40
  }
};

export const WelcomePagePanelPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

const WelcomePagePanel = ({ title, description, icon, language }) => (
  <Paper zdepth={1} style={{ marginTop: 15, height: 325 }}>
    <div style={styles.Container}>
      <div style={styles.IconContainer}>
        <MdDashboard
          width={100}
          height={100}
          color="var(--primary)"
          style={{ marginTop: -10 }}
        />
      </div>
      <div style={styles.DescriptionContainer}>
        <h1 style={styles.Title}>{title}</h1>
        <p style={styles.Description}>{description}</p>
        <button style={styles.Button}>{`${language.GO_TO} ${title}`}</button>
      </div>
    </div>
  </Paper>
);

export default withLanguage(WelcomePagePanel);
