import Button from '@common/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import CommonForm from '../../common/CommonForm2';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import { CompanyPortalInnerHeader } from './CompanyPortalForm';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});



export default function CompanyPortalFormMenu({ data, language, onClose, menuOptions }) {

    const classes = useStyles();
    const [dataMenu, setDataMenu] = useState();

    useEffect(() => {

        if (Array.isArray(data?.portal?.menu)) {
            let menuOptionsSwap = [...menuOptions];

            data.portal.menu.forEach(element => {
                const indexFound = menuOptionsSwap.findIndex(item => item.name === element.name);

                menuOptionsSwap[indexFound] = {
                    ...menuOptionsSwap[indexFound],
                    index: element.index,
                    label: language['SERVICE_PORTAL'][element.lang_key],
                    lang_key: element.lang_key,
                    checked: element.checked,
                    allowCheck: element.allow_check,
                };
            });

            setDataMenu(menuOptionsSwap)
        }
        else {
            setDataMenu(menuOptions)
        }
    }, [data])

    const handleChangeCheck = (event, item) => {
        if (item.allowCheck) {
            let _data = [...dataMenu];
            const indexFound = _data.findIndex(element => item.name === element.name);

            _data[indexFound].checked = event.target.checked;
            setDataMenu(_data);
        }
    }

    const handleIndex = (item, level) => {
        let _data = [...dataMenu];

        const newIndex = _data.findIndex(el => el.name == item.name);
        const newPosition = item.index + (level);
        const oldIndex = _data.findIndex(item => item.index == newPosition);

        _data[newIndex].index = newPosition;
        _data[oldIndex].index = level == -1 ? _data[oldIndex].index + 1 : _data[oldIndex].index - 1;

        setDataMenu(_data);
    }

    const handleSave = () => {
        const baseUrl = `${Constants.APIEndpoints.COMPANY}/${data.id_company}/portal/${data.portal.id_company_portal}/menu`;

        let payload = dataMenu.map(item => ({ ...item }));
        payload.forEach(item => delete item.Child);

        const dispatcher = new Actions();
        dispatcher
            .execute('post', baseUrl, 'company_portal_menu', { menu: payload }, 'id_company_portal_menu')
            .then(() => { })
            .finally(() => onClose())
    }

    dataMenu?.sort((a, b) => a.index - b.index);
    const hasPortalConfiguration = data?.hasOwnProperty('portal') && data?.portal?.hasOwnProperty('id_company_portal');

    return (
        <div className={classes.root}>
            <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_MENU}</CompanyPortalInnerHeader>
            {
                dataMenu?.map((item, index) => {
                    const hasUp = item.index - 1 >= 0;
                    const hasDown = item.index + 1 < dataMenu.length;
                    return (
                        <Accordion key={index} disabled={!hasPortalConfiguration}>
                            <AccordionSummary
                                expandIcon={item.Child ? <ExpandMoreIcon /> : <></>}
                                aria-label="Expand"
                                aria-controls={`additional-actions${index}-content`}
                                id={`additional-actions${index}-header`}
                                onClick={(event) => !item.Child && event.stopPropagation()}
                            >
                                <div className='tw-flex tw-justify-between tw-items-center tw-max-w-full tw-pr-5'>
                                    <div className='tw-flex tw-gap-4 tw-pr-5'>
                                        <ArrowDownward
                                            color={hasDown ? 'primary' : 'default'}
                                            onClick={(event) => { event.stopPropagation(); hasDown && handleIndex(item, 1) }}
                                        />
                                        <ArrowUpward
                                            color={hasUp ? 'primary' : 'default'}
                                            onClick={(event) => { event.stopPropagation(); hasUp && handleIndex(item, -1) }}
                                        />
                                    </div>
                                    <FormControlLabel
                                        aria-label={item.label}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                color={item.allowCheck ? 'primary' : 'default'}
                                                checked={item.checked}
                                                defaultChecked={!item.allowCheck}
                                                onClick={(event) => handleChangeCheck(event, item)}
                                            />
                                        }
                                        label={item.label}
                                    />
                                </div>
                            </AccordionSummary>
                            {item.Child &&
                                <AccordionDetails>
                                    <div className='tw-pb-5 tw-w-full' style={{ paddingLeft: 86 }}>
                                        {item.Child}
                                    </div>
                                </AccordionDetails>
                            }
                        </Accordion>
                    )
                })
            }
            <div className='tw-py-3 tw-text-right'>
                {!hasPortalConfiguration && <small className='tw-px-3 tw-text-yellow-400'><i className='fas fa-exclamation tw-px-2'></i>{language.SERVICE_PORTAL.MENU_EXCLAMATION}</small>}
                <Button
                    variant="normal"
                    icon={'fas fa-save'}
                    color={'success'}
                    disabled={!hasPortalConfiguration}

                    onClick={handleSave}
                    label={language.SAVE}
                    md={2}
                    offset={{ md: 10 }}
                    primary={true}
                />
            </div>

        </div>
    );
}
