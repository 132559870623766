import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import Help from '../../common/components/Help';
import { Card, Paper, Grid } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import io from 'socket.io-client';
import Constants from '../../data/Constants';
import CommonTable from '@common/CommonTable';
import CommonPage from '../../common/CommonPage';
import Details from '../GI2/DetailsAlerts';
import moment from 'moment';
import ReactTable from 'react-table';
import TicketUtils from '../../util/TicketUtils';
import PageLayout from '../../common/PageLayout';
import FaClose from 'react-icons/lib/fa/close';
import { Container, Row, Col } from 'react-grid-system';
import Server from './Server';
import AppBar2, { Title, SubTitle } from '@common/AppBar2';
import ButtonGroupFilter from '@common/ButtonGroupFilter';
import { bindLifecycle } from 'react-keep-alive';
import Animate from '@common/Animate';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './PanelContainer.css';

import ContainerChart from './graph.js';
import ReactHighcharts from 'react-highcharts';
import Header from '@common/Header';
import { flexBasis } from '@common/Func';
import Progress from '@common/Progress';
import RadialGraph from '@common/RadialGraph';
import List from '@common/List';
import Button from '@common/Button';
import classnames from 'classnames';
import { Menu, MenuItem } from '@material-ui/core';
import Store from '@data/Store';

const tenant = Constants.Tenant;

/***gsd: this.gsd(d.id_company),
        gr: this.gr(d.id_company),
        gp: this.GP(d.id_company) */
let countgreen = [];
let countwarningContract = [];
let countDangerContract = [];

let countgreenGsd = [];
let countwarningGsd = [];
let countDangerGsd = [];

let countgreenGr = [];
let countwarningGr = [];
let countDangerGr = [];

let countGPRed = [];
let countGPYellow = [];
let countGPGreen = [];
// @bindLifecycle
@observer
export default class PanelContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      coluna_: '',
      valores_: '',
      showModal: false,
      name: '',
      totalgreen: [],
      totalred: [],
      totalyellow: [],
      index: 0,
      anchorEl: null,
      monthSelected: parseInt(moment().format('M')),
      alerts: io.connect(Constants.ALERT_SERVERS, {
        path: '/server/alert',
        query: 'user=' + this.store.getUserLoged().id + '&token=' + this.store.getToken()
      })
    };
    this.openModal = this.openModal.bind(this);
    this.close = this.close.bind(this);
    this.setIndex = this.setIndex.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute('post', this.APIEndpoints.CONTRACT+'/list', 'contracts');
    this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
    this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
    this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');

    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/40',
      'urgencies'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/60',
      'priorities'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/70',
      'type_tickets'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/50',
      'impacts'
    );

    this.action.execute(
      'get',
      this.APIEndpoints.TICKET + '/findAll',
      'tickets'
    );
    this.action.execute('get', this.APIEndpoints.SLA, 'slas');
    this.action.execute(
      'get',
      this.APIEndpoints.USER + '/department/chief',
      'chiefs'
    );
    this.action.execute('get', this.APIEndpoints.GROUP, 'requests');
    this.action.execute('get', this.APIEndpoints.TASK, 'taskcatalog');
    this.action.execute(
      'get',
      this.APIEndpoints.COMPANY_TIME + '/time',
      'time'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.RISK + '/dash/0/company/0',
      'risks_test'
    );


    //findAll
    this.action.execute('get', this.APIEndpoints.MONITOR, 'monitor');

    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectlist',
      'pmo',
      null,
      null,
      null
    );
    
  }
  componentDidActivate() { }

  close() {
    this.setState({ showModal: false });
  }
  openModal(type, coluna, valor, idcompany) {
    var name = (
      this.store.companies.find(e => e.id_company == idcompany) || { name: '' }
    ).name;
    if (type === 'GCF') {
      this.setState({
        showModal: true,
        coluna_: coluna,
        valores_: valor,
        name
      });
    }
    if (type === 'GSD') {
      this.setState({
        showModal: true,
        coluna_: coluna,
        valores_: valor,
        name
      });
    }
    if (type === 'GP') {
      this.setState({
        showModal: true,
        coluna_: coluna,
        valores_: valor,
        name
      });

    }
    if (type === 'GI') {
      this.setState({
        showModal: true,
        coluna_: coluna,
        valores_: valor,
        name
      });
    }

  }

  setIndex({ index }) {

  }
  GP(idcompany, projects, language) {
    let { orage, red, green } = '';

    let coluna = [
      { key: 'id', label: '#', style: { width: 10, textAlign: 'left' } },
      {
        key: 'description',
        label: language.NAME,
        style: { width: 200, textAlign: 'left' }
      },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 100, textAlign: 'left' }
      }
    ];

    let execution = 0;
    let month = 0;
    let danger = 0;

    let valoresGreen = [];
    let valoresOrange = [];
    let valoresRed = [];

    try {
      execution = projects.execution.filter(g => g.fk_id_company == idcompany)
        .length;
      projects.execution
        .filter(g => g.fk_id_company == idcompany)
        .map(g => {
          countGPGreen.push(g.id_project);
          valoresGreen.push({
            id: g.id_project,
            nome: g.name,
            action: (
              <div
                onClick={() =>
                  this.redirect2('/dash/gp', {
                    id_project: g.id_project,
                    panel: true
                  })
                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
        });
    } catch (e) { }
    try {
      month = projects.endMonth.filter(g => g.fk_id_company == idcompany)
        .length;

      projects.endMonth
        .filter(g => g.fk_id_company == idcompany)
        .map(g => {
          countGPYellow.push(g.id_project);
          valoresOrange.push({
            id: g.id_project,
            nome: g.name,
            action: (
              <div
                onClick={() =>
                  this.redirect2('/dash/gp', {
                    id_project: g.id_project,
                    panel: true
                  })
                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
        });
    } catch (e) { }
    try {
      danger = projects.delay.filter(g => g.fk_id_company == idcompany).length;
      projects.delay
        .filter(g => g.fk_id_company == idcompany)
        .map(g => {
          countGPRed.push(g.id_project);

          valoresRed.push({
            id: g.id_project,
            nome: g.name,
            action: (
              <div
                onClick={() =>
                  this.redirect2('/dash/gp', {
                    id_project: g.id_project,
                    panel: true
                  })
                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
        });
    } catch (e) { }

    return (
      <div className='content-indicator d-flex align-items-center justify-content-between'>
        <div
          onClick={() => this.openModal('GP', coluna, valoresGreen, idcompany)}
          className={
            red == 'var(--danger)' || orage == 'var(--warning)'
              ? 'primary'
              : green == 'var(--success)'
                ? 'primary'
                : 'primary'
          }
          className='indicator'
          style={{

            opacity: !execution ? 0.5 : 1,

          }}
        >
          {execution}{' '}
        </div>

        <div
          onClick={() => this.openModal('GP', coluna, valoresOrange, idcompany)}
          className={
            red == undefined && orage == 'var(--warning)'
              ? 'warning'
              : 'warning'
          }
          className='indicator'
          style={{

            opacity: !month ? 0.5 : 1,

          }}
        >
          {month}{' '}
        </div>

        <div
          onClick={() => this.openModal('GP', coluna, valoresRed, idcompany)}
          className={red == 'var(--danger)' ? 'danger' : 'danger'}
          className='indicator'
          style={{

            opacity: !danger ? 0.5 : 1,

          }}
        >
          {danger}{' '}
        </div>
      </div>
    );
  }

  gi(idcompany) {
    return <AlertsChange openModal={this.openModal} devices={this.store.devices} id_company={idcompany} alerts={this.state.alerts}></AlertsChange>
  }

  gr(idcompany) {
    let { orage, red, green } = '';

    let risks_test = this.store.risks_test;
    let { language } = this.store;
    let mat = 0;
    let vlg = 0,
      vlo = 0,
      vlr = 0;
    let valores = [];
    let coluna = [
      {
        key: 'description',
        label: language.NAME,
        style: { width: 100, textAlign: 'left' }
      }
    ];
    if (risks_test.length) {
      risks_test = risks_test
        .filter(c => c.fk_id_company === idcompany)
        .map(c => {
          return c;
        });

      let data = risks_test.map((t, k) => {
        if (t.dash) {
          mat = t.dash.reduce(
            (value, obj) => value + (obj.value * 100) / obj.total,
            0
          );
          mat = (mat / t.dash.length).toFixed(2);
        }

        if (parseInt(mat) > 90) {
          valores.push({ description: t.name });
          vlg = vlg + 1;
          if (countgreenGr.filter(g => g == t.name).length == 0) {
            countgreenGr.push(t.name);
          }
          green = 'var(--success)';
        }
        if (parseInt(mat) > 70 && parseInt(mat) < 90) {
          if (countwarningGr.filter(g => g == t.name).length == 0) {
            countwarningGr.push(t.name);
          }
          orage = 'var(--warning)';
          vlo = vlo + 1;
          valores.push({ description: t.name });
        }
        if (parseInt(mat) < 70) {
          if (countDangerGr.filter(g => g == t.name).length == 0) {
            countDangerGr.push(t.name);
          }
          valores.push({ description: t.name });
          vlr = vlr + 1;
          red = 'var(--danger)';
        }
      });
    }

    return (
      <div className='content-indicator d-flex align-items-center justify-content-between'>
        <div
          onClick={() => this.openModal('GCF', coluna, valores, idcompany)}
          className={
            red == 'var(--danger)' || orage == 'var(--warning)'
              ? 'primary'
              : green == 'var(--success)'
                ? 'primary'
                : 'primary'
          }
          className='indicator'
          style={{

            opacity: !vlg ? 0.5 : 1,

          }}
        >
          {vlg}{' '}
        </div>

        <div
          onClick={() => this.openModal('GCF', coluna, valores, idcompany)}
          className={
            red == undefined && orage == 'var(--warning)'
              ? 'warning'
              : 'warning'
          }
          className='indicator'
          style={{

            opacity: !vlo ? 0.5 : 1,

          }}
        >
          {vlo}{' '}
        </div>

        <div
          onClick={() => this.openModal('GCF', coluna, valores, idcompany)}
          className={red == 'var(--danger)' ? 'danger' : 'danger'}
          className='indicator'
          style={{

            opacity: !vlr ? 0.5 : 1,

          }}
        >
          {vlr}{' '}
        </div>
      </div>
    );
  }

  gsd(idcompany) {
    let { orage, red, green } = '';

    let tickets = this.store.tickets || [];
    let { language } = this.store;

    let valores = [];
    let valoresGreen = [];
    let valoresOrange = [];
    let vlr = 0,
      vlo = 0,
      vlg = 0;
    let coluna = [
      { key: 'id', label: '#', style: { width: 10, textAlign: 'left' } },
      {
        key: 'description',
        label: language.TITLE,
        style: { width: 200, textAlign: 'left' }
      },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 100, textAlign: 'left' }
      }
    ];
    try {
      
      tickets = tickets
        .filter(c => c.id_company === idcompany)
        .map(c => {
          return c;
        });
      let gmt = 0;
      tickets.map(t => {
        let open = true;
        let req = [];

        if (t.dt_cad_r != null) {
          req = [
            { dt_up: t.dt_up_r, dt_cad: t.dt_cad_r, id_request: t.id_request }
          ];
        }

        let sla = TicketUtils.calcSla(
          t,
          'ticket',
          language,
          req,
          t.sla_task,
          gmt
        );

        if (sla.color == 'var(--red)') {
          vlr = vlr + 1;
          if (countDangerGsd.filter(g => g == t.id_tickets).length == 0) {
            countDangerGsd.push(t.id_tickets);
          }

          valores.push({
            id: t.id_tickets,
            description: t.subject,
            action: (
              <div
                onClick={() =>
                  this.redirect2('/dash/ticketmanagement', {
                    ticket: t,
                    back: 'panel'
                  })
                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
          red = 'var(--danger)';
        } else if (sla.color == 'var(--secundary)') {
          vlo = vlo + 1;
          if (countwarningGsd.filter(g => g == t.id_tickets).length == 0) {
            countwarningGsd.push(t.id_tickets);
          }
          valoresOrange.push({
            id: t.id_tickets,
            description: t.subject,
            action: (
              <div
                onClick={() =>
                  this.redirect2('/dash/ticketmanagement', {
                    ticket: t,
                    back: 'panel'
                  })
                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
          orage = 'var(--warning)';
        } else if (sla.color == 'var(--green)') {
          vlg = vlg + 1;
          if (countgreenGsd.filter(g => g == t.id_tickets).length == 0) {
            countgreenGsd.push(t.id_tickets);
          }
          valoresGreen.push({
            id: t.id_tickets,
            description: t.subject,
            action: (
              <div
                onClick={() =>
                  this.redirect2('/dash/ticketmanagement', {
                    ticket: t,
                    back: 'panel'
                  })
                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
          green = 'var(--success)';
        }
      });
    } catch (e) {

    }
    
    return (
      <div className='content-indicator d-flex align-items-center justify-content-between'>
        <div
          onClick={() => this.openModal('GCF', coluna, valoresGreen, idcompany)}
          className={
            red == 'var(--danger)' || orage == 'var(--warning)'
              ? 'primary'
              : green == 'var(--success)'
                ? 'primary'
                : 'primary'
          }
          className='indicator'
          style={{

            opacity: !vlg ? 0.5 : 1,

          }}
        >
          {vlg}{' '}
        </div>

        <div
          onClick={() =>
            this.openModal('GCF', coluna, valoresOrange, idcompany)
          }
          className={
            red == undefined && orage == 'var(--warning)'
              ? 'warning'
              : 'warning'
          }
          className='indicator'
          style={{

            opacity: !vlo ? 0.5 : 1,
          }}
        >
          {vlo}{' '}
        </div>

        <div
          onClick={() => this.openModal('GCF', coluna, valores, idcompany)}
          className={red == 'var(--danger)' ? 'danger' : 'danger'}
          className='indicator'
          style={{

            opacity: !vlr ? 0.5 : 1,

          }}
        >
          {vlr}{' '}
        </div>
      </div>
    );
  }

  handleModal() { }

  gcf(idcompany) {
    let { language } = this.store;
    let vlr = 0,
      vlo = 0,
      vlg = 0;
    let { orage, red, green } = '';
    let fields = [];
    let valores = [];
    let valoresOrange = [];
    let valoresGreen = [];

    let coluna = [
      {
        key: 'contract',
        label: language.CONTRACTS,
        style: { width: 100, textAlign: 'left' }
      },
      {
        key: 'date',
        label: language.END_DATE,
        style: { width: 100, textAlign: 'left' }
      },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 100, textAlign: 'left' }
      }
    ];
    this.store.contracts
      .filter(d => {
        if (d.id_company == idcompany && d.fk_id_out_company == idcompany) {
          return true
        }
        if (d.id_company !== idcompany && d.fk_id_out_company == idcompany) {
          return true
        }

        return false
      })
      .map(c => {
        let diffSeconds = moment(c.end_date).diff(moment(), 'seconds');
        //moment(obj.end_date).format("L")

        if (diffSeconds <= 0) {
          vlr = vlr + 1;
          red = 'var(--danger)';
          if (countDangerContract.filter(g => g == c.name).length == 0) {
            countDangerContract.push(c.name);
          }

          valores.push({
            contract: c.name,
            date: moment(c.end_date).format('L'),

            action: (
              <div
                onClick={() => this.redirect('/dash/contracts')}
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
        } else if (diffSeconds < 7776000) {
          vlo = vlo + 1;
          orage = 'var(--warning)';
          if (countwarningContract.filter(g => g == c.name).length == 0) {
            countwarningContract.push(c.name);
          }
          valoresOrange.push({
            contract: c.name,
            date: moment(c.end_date).format('L'),

            action: (
              <div
                onClick={() => this.redirect('/dash/contracts')}
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
        } else {
          vlg = vlg + 1;
          if (countgreen.filter(g => g == c.name).length == 0) {
            countgreen.push(c.name);
          }
          green = 'var(--success)';
          valoresGreen.push({
            contract: c.name,
            date: moment(c.end_date).format('L'),

            action: (
              <div
                onClick={() => this.redirect('/dash/contracts')}
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />
            )
          });
        }
      });

    return (
      <div className='content-indicator d-flex align-items-center justify-content-between'>
        <div
          onClick={() => this.openModal('GCF', coluna, valoresGreen, idcompany)}
          className={
            red == 'var(--danger)' || orage == 'var(--warning)'
              ? 'primary'
              : green == 'var(--success)'
                ? 'primary'
                : 'primary'
          }
          className='indicator'
          style={{

            opacity: !vlg ? 0.5 : 1,

          }}
        >
          {' '}
          {vlg}
        </div>

        <div
          onClick={() =>
            this.openModal('GCF', coluna, valoresOrange, idcompany)
          }
          className={
            red == undefined && orage == 'var(--warning)'
              ? 'warning'
              : 'warning'
          }
          className='indicator'
          style={{

            opacity: !vlo ? 0.5 : 1,

          }}
        >
          {' '}
          {vlo}{' '}
        </div>

        <div
          onClick={() => this.openModal('GCF', coluna, valores, idcompany)}
          className={red == 'var(--danger)' ? 'danger' : 'danger'}
          className='indicator'
          style={{

            opacity: !vlr ? 0.5 : 1,

          }}
        >
          {vlr}{' '}
        </div>
      </div>
    );
  }

  handleMontGraphFilter = (event = {}) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  pgStatusWithDate = () => {
    let { projetos } = this.store.pmo || { projetos: [] };
    let { monthSelected } = this.state;

    let pg = projetos.filter(e => parseInt(moment(e.dt_cad).format('M')) == parseInt(moment().set('month', monthSelected - 1).format('M')));

    let dt_cad;
    let isAfter;
    let obj;
    var day;

    return pg.reduce((arr, e, i) => {

      dt_cad = moment(e.dt_cad);

      isAfter = dt_cad.isAfter(moment());
      day = parseInt(dt_cad.format('D')) - 1;
      obj = arr[day];

      if (isAfter) {
        obj.late = obj.late + 1;
      }
      if (e.statusproject.TODOPER < 100 && e.statusproject.DOINGPER < 100) {
        obj.DOING = obj.DOING + 1;
      }
      arr[day] = obj;

      return arr;
    }, Array.from(new Array(moment().set('month', monthSelected - 1).daysInMonth()), (_, i) => ({ DOING: 0, ALERT: 0, LATE: 0 })))
  }
  pgStatus = (status) => {
    let { index } = this.state;
    let st = {
      doing: (
        (index == 0
          ? parseInt(countgreen.length)
          : index == 1
            ? parseInt(countgreen.length)
            : 0) +
        (index == 0
          ? parseInt(countgreenGsd.length)
          : index == 2
            ? parseInt(countgreenGsd.length)
            : 0) +
        (index == 0
          ? parseInt(countgreenGr.length)
          : index == 3
            ? parseInt(countgreenGr.length)
            : 0) +
        (index == 0
          ? countGPGreen.length
          : index == 4
            ? countGPGreen.length
            : 0)
      ),
      alert: (
        (index == 0
          ? parseInt(countwarningContract.length)
          : index == 1
            ? parseInt(countwarningContract.length)
            : 0) +
        (index == 0
          ? parseInt(countwarningGsd.length)
          : index == 2
            ? parseInt(countwarningGsd.length)
            : 0) +
        (index == 0
          ? parseInt(countwarningGr.length)
          : index == 3
            ? parseInt(countwarningGr.length)
            : 0) +
        (index == 0
          ? countGPYellow.length
          : index == 4
            ? countGPYellow.length
            : 0)
      ),
      late: (
        (index == 0
          ? countDangerContract.length
          : index == 1
            ? countDangerContract.length
            : 0) +
        (index == 0
          ? parseInt(countDangerGsd.length)
          : index == 2
            ? parseInt(countDangerGsd.length)
            : 0) +
        (index == 0
          ? parseInt(countDangerGr.length)
          : index == 3
            ? parseInt(countDangerGr.length)
            : 0) +
        (index == 0
          ? countGPRed.length
          : index == 4
            ? countGPRed.length
            : 0)
      )
    }

    let total = Object.values(st).reduce((n, e) => n + e, 0);
    if (status == 'total') {
      return total;
    } else {
      return { value: st[status] || 0, percent: Math.round((st[status] / total) * 100) || 0 }
    }
  }

  render() {
    let {

      language,

      companies,

    } = this.store;
    let { page, values, showModal, fields, dataFields, index } = this.state;
    let { PANEL } = this.store.theme;

    var pgStatusWithDate = this.pgStatusWithDate();

    let colInfo = (
      <div className='d-flex align-items-center justify-content-between content-colInfo mt-3'>
        <span>{language.RUNNING}</span>
        <span>{language.ON_PROGRESS}</span>
        <span>{language.ON_LATE}</span>
      </div>
    )
    let col = [
      {
        key: 'empresa',
        label: language.COMPANY,
        thConfig: true,
        style: { width: '20%' }
      },
      {
        key: 'gcf',
        label: (
          <div className='py-1 w-100'>
            {language.DASHBOARD_PAGE.GCF}
            {colInfo}
          </div>
        ),
        thConfig: true,
        style: { width: '16%' }
      },
      //  { key: 'gi', label: 'GI',style: {  textAlign:'left' } },
      {
        key: 'gi',

        label: (
          <div className='py-1 w-100'>
            {language.AVAILABILITY_MANAGEMENT}
            {colInfo}
          </div>
        ),
        thConfig: true,
        style: { width: '16%' }
      },
      {
        key: 'gsd',

        label: (
          <div className='py-1 w-100'>
            {language.DASHBOARD_PAGE.DM}
            {colInfo}
          </div>
        ),
        thConfig: true,
        style: { width: '16%' }
      },
      {
        key: 'gr',

        label: (
          <div className='py-1 w-100'>
            {language.RISKS_MANAGEMENT_COMPLIANCE}
            {colInfo}
          </div>
        ),
        thConfig: true,
        style: { width: '16%' }
      },
      {
        key: 'gp',

        label: (
          <div className='py-1 w-100'>
            {language.PROJECT_MANAGEMENT}
            {colInfo}
          </div>
        ),
        thConfig: true,
        style: { width: '16%' }
      }
    ];

    let dataf = [];
    let colf = [];

    //
    //Gestão De Contratos E FornecedoresGestão De DemandasGestão De Riscos E
    //ComplianceGestão De Projetos

    let arrayItem = ['Contratos', 'Demandas', 'Riscos', 'Projetos'];
    let data = [];
    countGPRed = [];
    countGPGreen = [];
    countGPYellow = [];
    
    data = companies.map(d => {
      return {
        empresa: d.name,
        gcf: this.gcf(d.id_company),
        gi: this.gi(d.id_company),
        gsd: this.gsd(d.id_company),
        gr: this.gr(d.id_company),
        gp: this.store.pmo && this.GP(d.id_company, this.store.pmo, language)
      };
    });

    this.state.valores_ != '' ? (dataf = this.state.valores_) : null;
    this.state.coluna_ != '' ? (colf = this.state.coluna_) : null;

    let projects = this.store.pmo;
    let { anchorEl, monthSelected } = this.state;

    var btnFilter = [
      language.ALL,
      language.DASHBOARD_PAGE.GCF,
      language.DASHBOARD_PAGE.DM,
      language.RISKS_MANAGEMENT_COMPLIANCE,
      language.PROJECT_MANAGEMENT
    ]

    var btnFilter2 = [
      '',
      language.CONTRACTS,
      language.MY_DEMANDS.replace(/minhas|my|\s/gi, ''),
      language.RISKS_AND_COMPLIANCE,
      language.PROJECTS
    ]

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        <div id="PanelContainer">
          <Animate>
            <PageLayout
              icon={
                <div
                  className="panelico"
                  style={{ width: 40, height: 40, overflowY: true }}
                />
              }
              title={language.PANEL_DASH}
              subTitle={language.CIO_COCKPIT + '/'}
              rightElements={[]}

            >
              {/*##############################################################-BLOCO-1-#################################################################################*/}
              <div style={{ marginTop: '-1rem' }}>

                <Header
                  btnFilter={btnFilter}
                  active={this.state.index}
                  btnFilterProps={{
                    style: { width: 150 }
                  }}
                  onChange={index => {
                    this.setState({ index })
                  }}
                />
                <Card style={{ borderRadius: '0 0 4px 4px' }} className='content'>
                  <Grid container spacing={2}>
                    {/*---------------------------------------------BLOCO-1 - Coluna 1-----------------------------------------------------*/}
                    <Grid item xs={4.5} style={{ ...flexBasis(4.5) }} className='panel-b1-c1'>

                      <Grid container spacing={3}>
                        {[
                          { value: this.pgStatus('doing').value, percent: this.pgStatus('doing').percent },
                          { value: this.pgStatus('alert').value, percent: this.pgStatus('alert').percent },
                          { value: this.pgStatus('late').value, percent: this.pgStatus('late').percent },
                        ].map((e, i) => (
                          <Grid key={e.value + '-' + i} item xs={4} style={i == 0 ? { '--thisColor': PANEL.total_card_1 } : undefined}>
                            <div className={'cards-panel content-min cp' + (i + 1)}>
                              <div className='header'>
                                <h4>Total {this.state.index == 0 ? '' : 'de'} {btnFilter2[this.state.index]}</h4>
                                <span>
                                  {
                                    i == 0 ? `(${language.RUNNING})` :
                                      i == 1 ? `(${language.ON_ALERT})` :
                                        `(${language.ON_LATE})`
                                  }
                                </span>
                              </div>
                              <div className='content-info-footer'>
                                <div className='d-flex align-items-end justify-content-between my-4'>
                                  <div className='d-flex align-items-center justify-content-end'>
                                    <h2>{e.value}</h2>
                                    <span className='ml-1 mr-2'>{btnFilter2[this.state.index]}</span>
                                  </div>
                                  <i
                                    className={
                                      i == 0 ? 'fas fa-check' :
                                        i == 1 ? 'fas fa-exclamation-triangle' :
                                          'fas fa-times-circle'
                                    }
                                  />
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <Progress
                                    current={e.percent}
                                    hiddenText
                                    className='w-100'
                                    style={{ borderRadius: 7, height: 13 }}
                                    background={
                                      i == 0 ? '#FFD45F' :
                                        i == 1 ? '#FFD45F' :
                                          '#92D050'
                                    }
                                  />
                                  <span style={{ color: 'white', fontSize: 12 }} className='ml-2'>{e.percent}%</span>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>

                    </Grid>
                    {/*---------------------------------------------BLOCO-1 - Coluna 2-----------------------------------------------------*/}
                    <Grid item xs={3} className='panel-b1-c2'>
                      <Card style={{ height: 216 }}>
                        <AppBar2 title={language.PERCENTAGE + (this.state.index == 0 ? ' ' : ' de ') + btnFilter2[this.state.index]} />
                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <RadialGraph

                              data={[
                                { name: language.RUNNING, value: this.pgStatus('doing').value, color: 'var(--success)' },
                                { name: language.ON_ALERT, value: this.pgStatus('alert').value, color: 'var(--warning)' },
                                { name: language.IN_ARREARS, value: this.pgStatus('late').value, color: 'var(--danger)' },
                              ]}
                              outerRadius={50}
                              innerRadius={38}
                              eProps={{ centerValue: true, centerProps: { style: { fontWeight: 'bold', fontSize: 22 } } }}
                              height={130}
                              width={130}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <List
                              className='list-category-items'
                              items={[
                                { value: this.pgStatus('doing').percent, label: language.RUNNING, color: 'var(--success)' },
                                { value: this.pgStatus('alert').percent, label: language.ON_ALERT, color: 'var(--warning)' },
                                { value: this.pgStatus('late').percent, label: language.IN_ARREARS, color: 'var(--danger)' },
                                { value: this.pgStatus('total'), label: `Total ${this.state.index != 0 ? `de ${btnFilter2[this.state.index]}` : ''}`, color: '#A3A3A3' },
                              ].map((e, i) => (
                                {
                                  className: classnames('d-flex align-items-center justify-content-between items', { Total: i == 3 }),
                                  content: (
                                    <Fragment>
                                      <div className='d-flex align-items-center content-label'>
                                        {i != 3 && <div className='mr-2' style={{ backgroundColor: e.color }} />}
                                        <span style={{ color: e.color }}>{e.label}</span>
                                      </div>
                                      <span className='value'>{e.value}{i != 3 ? '%' : ' '}</span>
                                    </Fragment>
                                  )
                                }
                              ))}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    {/*---------------------------------------------BLOCO-1 - Coluna 3-----------------------------------------------------*/}
                    <Grid item xs={4.5} style={{ ...flexBasis(4.5) }} className='panel-b1-c3'>
                      <Card>
                        <AppBar2 title={`${this.state.index != 0 ? `${btnFilter2[this.state.index]}` : language.TOTAL} ${language.CREATED}`} subTitle={language.BY_MONTH}>
                          <div className='d-flex align-items-center'>
                            <Button
                              icon='fas fa-chevron-left'
                              label={language.PREVIOUS}
                              color='success'
                              variant='circle'
                              outlined
                              style={{
                                border: 'none',
                                fontSize: 12
                              }}
                              onClick={() => {
                                this.setState(({ monthSelected }) => ({ monthSelected: monthSelected - 1 }))
                              }}
                            />
                            <h3
                              aria-owns={open ? 'long-menu' : undefined}
                              onClick={this.handleClick}
                              className='label-month-filter px-3'
                              style={{ width: 113, textAlign: 'center' }}
                            >
                              {moment().set('month', monthSelected - 1).format('MMMM')}
                            </h3>
                            <Button
                              icon='fas fa-chevron-right'
                              label={language.NEXT}
                              color='success'
                              variant='circle'
                              outlined
                              style={{
                                border: 'none',
                                fontSize: 12
                              }}
                              onClick={() => {
                                this.setState(({ monthSelected }) => ({ monthSelected: monthSelected + 1 }))
                              }}
                            />
                          </div>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={() => this.handleMontGraphFilter()}
                          >
                            {language.DATE_LANG.monthLongList.map((option, i) => (
                              <MenuItem
                                key={option}
                                onClick={() => {
                                  this.handleMontGraphFilter();
                                  this.setState({ monthSelected: i + 1 })
                                }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Menu>
                        </AppBar2>
                        <div className='content-medium pt-0'>
                          <ReactHighcharts
                            config={ContainerChart.projectsCreated({
                              DOING: pgStatusWithDate.map(e => e.DOING),
                              ALERT: pgStatusWithDate.map(e => e.ALERT),
                              LATE: pgStatusWithDate.map(e => e.LATE),
                              categories: Array.from(new Array(moment().daysInMonth()), (_, i) => i + 1),
                              language
                            })}

                          />
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
                {/*##############################################################-BLOCO-2-#################################################################################*/}

                <div className="mt-3 b2" style={{ '--b2Color': PANEL.b2, '--indicator_1': PANEL.indicator_1, marginTop: 20 }}>
                  <CommonTable AppBarProps={{ dark: true }} data={data} col={col} />
                </div>
                {/*<CommonTable title="" data={data} columns={col} language={language} />*/}

                <Dialog
                  title={this.state.name}
                  open={showModal == true && this.state.valores_ != ''}
                  maxWidth="md"
                  onClose={() => {
                    this.setState({ showModal: false });
                  }}
                  contentProps={{
                    style: {
                      backgroundColor: 'transparent',
                      border: 0
                    }
                  }}

                  bar={false}
                  PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none'
                    }
                  }}
                >
                  <CommonTable
                    title={
                      <div className="AppBar2 -mt-3">
                        <Title style={{ color: 'white' }}>{language.EXECUTION}</Title>
                        <SubTitle style={{ color: 'white' }}>{this.state.name}</SubTitle>
                      </div>
                    }
                    AppBarProps={{
                      style: {
                        borderRadius: '10px 10px 0 0'
                      }
                    }}
                    paginationTop={false}
                    maxHeight={480}
                    afterBar2={
                      <IconButton
                        aria-label="Close"
                        className="ml-3"
                        onClick={() => {
                          this.setState({ showModal: false });
                        }}
                      >
                        <CloseIcon style={{ color: 'white' }} />
                      </IconButton>
                    }
                    language={language}
                    columns={colf}
                    data={dataf}
                  />
                </Dialog>
              </div>
            </PageLayout>
          </Animate>
        </div>
      </CommonPage>
    );
  }
}
class AlertsChange extends Component {
  
  constructor() {
    super();
    let { language } = Store;
    this.state = {
      ok: 0,
      danger: 0,
      warning: 0,
      c: 0,
      listOk: [],
      listDanger: [],
      listWarning: [],
      coluna: [
        {
          key: 'nome',
          label: language.DEVICES,
          style: { width: 100, textAlign: 'left' }
        },
        {
          key: 'alias',
          label: 'Alias',
          style: { width: 100, textAlign: 'left' }
        },
        {
          key: 'ip',
          label: 'Ip',
          style: { width: 100, textAlign: 'left' }
        },
        {
          key: 'status',
          label: ' ',
          style: { width: 200, textAlign: 'left' }
        },
      ]
    };
  }

  componentWillMount() {
    let { listOk, listDanger, listWarning, c } = this.state;

    let cont = c;
    
    this.props.alerts.on(`${tenant}_alerts`, (data) => {

      this.setState({ ok: data.alertsall.countOk.filter(c => c.id_company == this.props.id_company).length });
      this.setState({ warning: data.alertsall.countYellow.filter(c => c.id_company == this.props.id_company).length });
      this.setState({ danger: data.alertsall.countRed.filter(c => c.id_company == this.props.id_company).length });

      cont++

      if (cont == 30 || cont == 1) {
        if (cont == 30) {
          this.setState({ c: 0 });
          this.setState({ listOk: [], listDanger: [], listWarning: [] })
        }

        data.alertsall.countOk.filter(c => c.id_company == this.props.id_company).map(c => {
          let d = this.props.devices.filter(v => v.id_device == c.id_device)
          try {
            listOk.push({
              nome: d[0].name,
              alias: d[0].alias,
              ip: d[0].ip,
              status: <Details items={1} ic={'successes'} percent={c.percent} fs={c.fs} item={c.key == "mem-info" ? 2 : c.key == "cpu-stats" ? 1 :
                c.key == "fs-list" ? 3 : ''}
              ></Details>

            })
          } catch (e) { }
        })
        data.alertsall.countYellow.filter(c => c.id_company == this.props.id_company).map(c => {
          let d = this.props.devices.filter(v => v.id_device == c.id_device)
          try {
            listWarning.push({
              nome: d[0].name,
              alias: d[0].alias,
              ip: d[0].ip,
              status: <Details items={1} ic={'warning'} percent={c.percent} fs={c.fs} item={c.key == "mem-info" ? 2 : c.key == "cpu-stats" ? 1 :
                c.key == "fs-list" ? 3 : ''}
              ></Details>

            })
          } catch (e) { }
        })
        data.alertsall.countRed.filter(c => c.id_company == this.props.id_company).map(c => {
          let d = this.props.devices.filter(v => v.id_device == c.id_device)
          try {
            listDanger.push({
              nome: d[0].name,
              alias: d[0].alias,
              ip: d[0].ip,
              status: <Details items={1} ic={'danger'} percent={c.percent} fs={c.fs} item={c.key == "mem-info" ? 2 : c.key == "cpu-stats" ? 1 :
                c.key == "fs-list" ? 3 : ''}
              ></Details>

            })
          } catch (e) { }
        })
      }
    })
  }

  render() {
    let { ok, danger, warning, listOk, listWarning, listDanger, coluna } = this.state;
    return (
      <div className='content-indicator d-flex align-items-center justify-content-between'>
        <div
          className={'var(--success)'

          }
          className='indicator'
          style={{

            opacity: ok == 0 ? 0.5 : 1,

          }}
          onClick={() => this.props.openModal('GI', coluna, listOk, this.props.id_company)}

        >
          {ok}{' '}
        </div>

        <div

          className={
            'var(--warning)'

          }
          className='indicator'
          style={{

            opacity: warning == 0 ? 0.5 : 1,
          }}
          onClick={() => this.props.openModal('GI', coluna, listWarning, this.props.id_company)}
        >
          {warning}{' '}
        </div>

        <div
          className={'var(--danger)'}
          className='indicator'
          style={{
            opacity: danger == 0 ? 0.5 : 1,
          }}
          onClick={() => this.props.openModal('GI', coluna, listDanger, this.props.id_company)}
        >
          {danger}{' '}
        </div>
      </div>
    );

  }

}
