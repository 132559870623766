import React, { Component } from 'react';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@material-ui/icons/Delete';
import MaskedInput from 'react-text-mask';
import { Chip, Avatar } from '@material-ui/core';
import Dictionary from '../common/languages/Dictionary';
import PropTypes from 'prop-types';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Container, Row, Col } from 'react-grid-system';
import RaisedButton from '@common/RaisedButton';

import AttachFile from '@common/AttachFile';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  SelectField,
  // TimePicker,
} from '@material-ui/core';
import { Autocomplete as AutoComplete } from '@material-ui/lab';
import { Checkbox, Button } from '@material-ui/core';
import ReactQuill from 'react-quill';
import { FaCloudUpload, FaSearch, FaClose } from 'react-icons/lib/fa';
import ChipInput from 'material-ui-chip-input';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { teste } from './styles.css';
import SearchIcon from '@material-ui/icons/Search';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core'; // controls
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AppBar from '@common/AppBar';
import SimplesAlert from '@common/SimplesAlert';
import IntegrationReactSelect from './select.js';
import classnames from 'classnames';
import './CommonForm.css';
const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});
const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    labelPlacement: 'top',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonprimary: {
    margin: theme.spacing(1),
    backgroundColor: '#009dc0',
    color: 'white',
    size: '14px',
    weight: 500,
  },
  TimePickerStyle: {
    marginTop: 2,
    size: '12',
  },
  DatePickerStyle: {
    marginTop: 10,
    size: '12',
  },
};

function getIcon(label) {
  try {
    label = label.toUpperCase();
  } catch (e) {}

  if (label == 'SAVE' || label == 'SALVAR') {
    return { icon: 'fas fa-save', style: { fontSize: 17 } };
  } else {
    return {
      icon: 'fas fa-paper-plane',
      style: { fontSize: 15, paddingRight: 3 },
    };
  }
}

class TextMaskCustom extends React.Component {
  render() {
    const { inputRef, ...other } = this.props;
    var { mask } = other.id;

    if (typeof mask == 'string' && mask == 'tel') {
      mask = [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    } else {
      mask = mask || [];
    }
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={mask}
      />
    );
  }
}

class CommonForm extends React.Component {
  constructor() {
    super();
    this.state = {
      values: {},
      openMessage: false,
      message: '',
      messageType: '',
      alert: {
        open: false,
        variant: '',
        message: '',
      },
    };

    this.onChangeField = this.onChangeField.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.dateTimeFormat = this.dateTimeFormat.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.checkBoxSelectSingle = this.checkBoxSelectSingle.bind(this);
    this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);
  }

  componentWillMount() {
    if (this.props.values) {
      var values = this.props.values;

      var inPutField = this.props.fields
        .filter((e) => e.inPut)
        .map((e) => {
          values[e.name] = e.inPut(values[e.name]);
        });

      this.setState({ values });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.values) {
      let { values } = this.state;
      Object.keys(nextProps.values).map(
        (k) => (values[k] = nextProps.values[k])
      );

      this.setState({ values });
    }
  }

  handleChangeDatePicker = (event, date) => {};
  onChangeField(field, value) {
    let { values } = this.state;

    if (value.key != undefined) {
      value = value.key;
    } else {
      value = value;
    }

    switch (field.type) {
      case 'select':
        values[field.name] = value;
        break;
      default:
        values[field.name] = value;
        break;
    }
    if (this.props.onChangeField)
      this.props.onChangeField(field, values[field.name]);
    this.setState({ values: values });
  }
  toggleAlert = (open, message = '', variant = this.state.alert.variant) => {
    var alert = this.state.alert;
    alert.open = open;
    alert.message = message;
    alert.variant = variant;
    this.setState({ alert });
  };
  onFormSubmit() {
    let next = true;

    this.props.fields.map((f, k) => {
      if (f.addDynamic) {
        f.fields.map((field, i) => {
          if (!this.state.values[f.name]) {
            next = false;
          } else {
            this.state.values[f.name].map((obj, j) => {
              if (field.required && !obj[field.name]) {
                next = false;
              }
            });
          }
        });
      } else if (
        f.required &&
        f.type === 'chipInput' &&
        (!this.state.values[f.name] || this.state.values[f.name].length <= 0)
      ) {
        next = false;
      } else if (
        f.required &&
        f.type === 'textEditor' &&
        this.state.values[f.name] === '<p><br></p>'
      ) {
        next = false;
      } else if (f.required && !this.state.values[f.name]) {
        next = false;
      }
    });

    if (next) {
      var values = this.state.values;
      var outPutField = this.props.fields
        .filter((e) => e.outPut)
        .map((e) => {
          values[e.name] = e.outPut(values[e.name]);
        });

      this.props.onFormSubmit(this.state.values);
    } else {
      /*this.setState({
        message: this.props.language.FILL_REQUIRED,
        messageType: 'error'
      });*/
      this.toggleAlert(true, this.props.language.FILL_REQUIRED, 'error');
    }
  }

  dateTimeFormat(locale, options) {
    let language = sessionStorage.getItem('sensr_lang');
    try {
      language = !language
        ? (navigator.language || navigator.userLanguage)
            .toUpperCase()
            .replace('-', '_') == 'PT_BR'
          ? 'PT_BR'
          : (navigator.language || navigator.userLanguage)
            .toUpperCase()
            .replace('-', '_') == 'EN'
            ? 'EN'
            : 'ES'
        : language;
    } catch (e) {}

    let dayAbbreviation = Dictionary[language].DATE_LANG.dayAbbreviation;
    let dayList = Dictionary[language].DATE_LANG.dayList;
    let monthList = Dictionary[language].DATE_LANG.monthList;
    let monthLongList = Dictionary[language].DATE_LANG.monthLongList;

    this.format = function (date) {
      if (
        options.month === 'short' &&
        options.weekday === 'short' &&
        options.day === '2-digit'
      ) {
        return (
          dayList[date.getDay()] +
          ', ' +
          monthList[date.getMonth()] +
          ' ' +
          date.getDate()
        );
      } else if (
        options.year === 'numeric' &&
        options.month === 'numeric' &&
        options.day === 'numeric'
      ) {
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        );
      } else if (options.year === 'numeric' && options.month === 'long') {
        return monthLongList[date.getMonth()] + ' ' + date.getFullYear();
      } else if (options.weekday === 'narrow') {
        return dayAbbreviation[date.getDay()];
      } else if (options.year === 'numeric') {
        return date.getFullYear().toString();
      } else if (options.day === 'numeric') {
        return date.getDate();
      } else {
        process.env.NODE_ENV !== 'production'
          ? (0, _warning2.default)(
              false,
              'Material-UI: Wrong usage of DateTimeFormat'
            )
          : void 0;
      }
    };
  }

  checkBoxSelect(field, value) {
    let { values } = this.state;

    let array = values[field.name] || [];

    if (array.indexOf(value) === -1) {
      array.push(value);
    } else {
      array = array.filter((id) => id != value);
    }
    values[field.name] = array;

    if (this.props.onChangeField)
      this.props.onChangeField(field, values[field.name]);

    this.setState({ values: values });
  }
  checkBoxSelectAll = (field, value) => {
    let { values } = this.state;

    values[field.name] = value;

    if (this.props.onChangeField)
      this.props.onChangeField(field, values[field.name]);

    this.setState({ values: values });
  };

  checkBoxSelectSingle(field, value) {
    let { values } = this.state;
    values = [];

    let array = values[field.name] || [];
    if (array.indexOf(value) === -1) {
      array.push(value);
    } else {
      array = array.filter((id) => id != value);
    }
    values[field.name] = array;
    if (this.props.onChangeField)
      this.props.onChangeField(field, values[field.name]);
    this.setState({ values: values });
  }

  render() {
    let { language, button } = this.props;
    let floatingLabelStyle = { color: '#030235' };
    const { classes } = this.props;
    let alert = !!this.state.messageType;

    if (alert) {
      setTimeout(() => {
        this.setState({ message: '', messageType: '' });
      }, 5000);
    }

    let fields = this.props.fields.map((f, k) => {
      let component = null;
      let errorText = f.required
        ? !f.disabled
          ? !this.state.values[f.name]
            ? language.REQUIRED
            : ''
          : ''
        : '';

      if (f.divider) {
        component = (
          <div>
            <fieldset>
              <legend>{f.title}</legend>
            </fieldset>
          </div>
        );
      } else if (f.addDynamic) {
        return (
          <AddDynamic
            key={f.name}
            {...f}
            language={language}
            floatingLabelStyle={floatingLabelStyle}
            onChangeField={this.onChangeField}
          />
        );
      } else {
        switch (f.type) {
          case 'select':
            component = (
              <IntegrationReactSelect
                label={f.label}
                value={
                  this.state.values[f.name] === null
                    ? ''
                    : this.state.values[f.name]
                }
                options={f.options.map((e) => ({
                  value: e.value,
                  label: e.text || e.label,
                }))}
                onChange={(event) => this.onChangeField(f, event.value)}
                className="w-100"
                isMulti={false}
                language={language}
                readOnly={f.inputProps ? f.inputProps.readOnly : false}
                textFieldProps={{
                  icon: f.icon,
         //         className: classnames(classes.selectEmpty),
                  disabled: f.disabled,
                  helperText:
                    (!f.options.length
                      ? language.NOTHING_REGISTERED
                      : errorText) ||
                    f.help ||
                    ' ',
                  required: !!f.required,
                  //multiple:f.hasOwnProperty('multiple'),
                  style: { width: '100%', marginTop: 0 },
                  variant: f.variant || 'standard',
                  inputProps: {
                    ...(f.inputProps || {}),
                    style: { height: 'auto' },
                  },
                  error: f.error,
                }}
              />
            );
            break;

          case 'selectnative':
            opts = f.options.map((o, i) => {
              return (
                <option
                  key={i}
                  value={o.value}
                  insetChildren={f.multiple}
                  checked={
                    f.multiple && this.state.values[f.name]
                      ? this.state.values[f.name].indexOf(o.value) !== -1
                      : false
                  }
                >
                  {o.text || o.label}
                </option>
              );
            });
            component = (
              <form className={classes.root} autoComplete="off">
                <FormControl htmlFor="age" className={classes.formControl}>
                  <InputLabel>{f.label}</InputLabel>
                  <NativeSelect
                    input={<Input name="age" id="select-age" />}
                    value={
                      this.state.values[f.name] == null
                        ? 0
                        : this.state.values[f.name]
                    }
                    className={classes.selectEmpty}
                    onChange={(event, index, values) =>
                      this.onChangeField(
                        f,
                        f.multiple ? values : event.target.value
                      )
                    }
                    disabled={f.disabled}
                    multiple={f.hasOwnProperty('multiple')}
                  >
                    {opts}
                  </NativeSelect>
                  <FormHelperText>
                    {!opts.length ? language.NOTHING_REGISTERED : errorText}
                  </FormHelperText>
                </FormControl>
              </form>
            );
            break;
          case 'element':
            component = f.element;
            break;

          case 'addtab':
          case 'tab':
            component = (
              <fieldset
                className="fieldset-linearP"
                style={{ width: '100%', borderColor: 'rgba(0, 0, 0, 0.1)' }}
              >
                <legend>
                  {typeof f.label == 'string' ? (
                    <AppBar
                      light
                      style={{
                        top: 0,
                        margin: 0,
                        width: 135,
                        textTransform: 'uppercase',
                      }}
                    >
                      {f.label}
                    </AppBar>
                  ) : (
                    f.label
                  )}
                </legend>
              </fieldset>
            );
            break;
          case 'addItem':
            component = (
              <Row>
                {[true, undefined].includes(f.visibleTitle) && (
                  <React.Fragment>
                    <Col md={2}>
                      <div>{f.title}</div>
                    </Col>
                    <Col md={12}>
                      <div className="dividerActivity" />
                    </Col>
                    <Col md={3} offset={{ md: 9.5 }}>
                      <div className="addItemButton">
                        <div
                          onClick={f.modalFunction}
                          className="plusIcon titleIconSize pointer"
                        />
                        <h2>{f.titleButton}</h2>
                      </div>
                    </Col>
                  </React.Fragment>
                )}
                <Col md={12}>
                  <div>{f.tableList}</div>
                </Col>
              </Row>
            );
            break;

          case 'autoComplete':
            component = (
              <AutoComplete
                value={this.state.values[f.name]}
                floatingLabelText={f.label}
                floatingLabelStyle={floatingLabelStyle}
                dataSource={f.options}
                filter={(value, key) => key.indexOf(value) !== -1}
                fullWidth
                required={true}
              />
            );
            break;
          case 'switch':
            component = (
              <FormControlLabel
                className="fieldCustom"
                control={
                  <Switch
                    checked={this.state.values[f.name]}
                    onChange={(event) =>
                      this.onChangeField(f, event.target.checked)
                    }
                  />
                }
                label={f.label}
              />
            );
            break;
          case 'check':
            component = (
              <FormControlLabel
                className="fieldCustom"
                control={
                  <Checkbox
                    checked={this.state.values[f.name]}
                    onChange={(event) =>
                      this.onChangeField(f, event.target.checked)
                    }
                  />
                }
                label={f.label}
              />
            );
            break;

          case 'itemdiv':
            component = (
              <ListCheckboxDiv
                values={this.state.values[f.name] || []}
                field={f}
                language={language}
                checkBoxSelectAll={this.checkBoxSelectAll}
                checkBoxSelect={this.checkBoxSelect}
              />
            );
            break;
          case 'listCheckbox':
            component = (
              <ListCheckbox
                values={this.state.values[f.name] || []}
                field={f}
                language={language}
                checkBoxSelect={this.checkBoxSelect}
                checkBoxSelectAll={this.checkBoxSelectAll}
              />
            );
            break;
          case 'listCheckboxSingle':
            component = (
              <ListCheckbox
                values={this.state.values[f.name] || []}
                field={f}
                language={language}
                checkBoxSelect={this.checkBoxSelectSingle}
                checkBoxSelectAll={this.checkBoxSelectAll}
              />
            );
            break;

          case 'uploadSingle':
            component = (
              <Button
                style={{ marginTop: 30 }}
                label={f.label}
                color="primary"
                primary={true}
                className={classes.buttonprimary}
                fullWidth={true}
                type="file"
                labelPosition="before"
                containerElement="label"
                icon={<FaCloudUpload size={34} color={'#fff'} />}
              >
                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept={f.accept || ''}
                  onChange={(e) => this.onChangeField(f, e.target.files[0])}
                />
              </Button>
            );
            break;

          case 'date':
            let value = this.state.values[f.name];
            value = value
              ? typeof this.state.values[f.name] === 'object'
                ? this.state.values[f.name]
                : new Date(this.state.values[f.name])
              : null;
            component = (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={value}
                  label={f.label}
                  floatingLabelStyle={floatingLabelStyle}
                  error={f.error}
                  helperText={errorText || f.help || ' '}
                  fullWidth={true}
                  autoOk={true}
                  format={this.dateTimeFormat}
                  // DateTimeFormat={this.dateTimeFormat}
                  // firstDayOfWeek={0}
                  cancelLabel={language.CANCEL}
                  // className={classes.DatePickerStyle}
                  onChange={(evt, date) => this.onChangeField(f, evt)}
                  disabled={f.disabled}
                  required={!!f.required}
                  InputProps={{
                    ...(f.icon
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <i
                                className={classnames(f.icon, 'color-lead')}
                              ></i>
                            </InputAdornment>
                          ),
                        }
                      : {}),
                  }}
                />
              </MuiPickersUtilsProvider>
            );
            break;

          case 'time':
            let valueH = this.state.values[f.name];
            valueH = valueH
              ? typeof this.state.values[f.name] === 'object'
                ? this.state.values[f.name]
                : new Date(this.state.values[f.name])
              : null;
            component = (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={valueH}
                  label={f.label}
                  floatingLabelStyle={floatingLabelStyle}
                  helperText={errorText || f.help || ' '}
                  error={f.error}
                  fullWidth={true}
                  ampm={false}
                  hintText="24hr Format"
                  onChange={(evt, value) => this.onChangeField(f, evt)}
                  required={!!f.required}
                  cancelLabel={language.CANCEL}
                  inputProps={f.inputProps}
                />
              </MuiPickersUtilsProvider>
            );
            break;

          case 'textEditor':
            component = (
              <div style={{ width: '100%' }}>
                <div
                  className="font"
                  style={{ marginBottom: 6, color: 'rgba(0, 0, 0, 0.54)' }}
                >
                  {f.label}
                </div>
                <ReactQuill
                  value={this.state.values[f.name] || '<span></span>'}
                  /*style={
                    f.disabled
                      ? { border: 'none' }
                      : !!errorText ||
                        this.state.values[f.name] === '<p><br></p>'
                      ? { border: '1px solid red' }
                      : { border: '1px solid white' }
                  }*/
                  onChange={(content, delta, source, editor) =>
                    this.onChangeField(f, content)
                  }
                  readOnly={f.disabled}
                  modules={{
                    toolbar: f.disabled
                      ? null
                      : [
                          [{ header: [1, 2, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                          ],
                          ['link', 'image'],
                          ['clean'],
                        ],
                  }}
                />
              </div>
            );
            break;

          case 'chipInput':
            errorText = f.required
              ? !f.disabled
                ? !this.state.values[f.name] ||
                  this.state.values[f.name].length <= 0
                  ? language.REQUIRED
                  : ''
                : ''
              : '';
            component = (
              <ChipInput
                floatingLabelText={f.label}
                defaultValue={this.state.values[f.name]}
                errorText={errorText}
                clearOnBlur={true}
                onChange={(chip) => this.onChangeField(f, chip)}
                fullWidth
              />
            );
            break;

          default:
            component = (
              <TextField
                error={f.error}
                type={f.type}
                // inputProps={{...f.inputProps, style:{height:'auto'}}}
                InputProps={{
                  ...(f.icon
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            {typeof f.icon == 'string' ? (
                              <i
                                className={classnames(f.icon, 'color-lead')}
                              ></i>
                            ) : (
                              f.icon
                            )}
                          </InputAdornment>
                        ),
                      }
                    : {}),
                  ...(f.mask ? { id: { mask: f.mask } } : {}),
                  ...(f.maskName || f.mask
                    ? { inputComponent: TextMaskCustom }
                    : {}),
                  ...f.InputProps,
                }}
                inputProps={{
                  ...f.inputProps,
                }}
                variant={f.variant || 'standard'}
                value={this.state.values[f.name] || ''}
                label={f.label}
                helperText={errorText || f.help || ' '}
                fullWidth={true}
                onChange={(evt) => this.onChangeField(f, evt.target.value)}
                multiLine={f.multiLine}
                disabled={f.disabled}
                required={!!f.required}
              />
            );
            break;
        }
      }

      return (
        <Col
          key={k}
          xs={12}
          sm={f.divider ? 12 : f.span}
          md={f.divider ? 12 : f.span}
          lg={f.span || 12}
          className={!f.divider && !f.addDynamic ? 'form-field' : 'divider'}
          style={{
            alignItems: 'center',
            float: 'none',
            minHeight: 'auto',
            marginTop: 20 /*top:f.type=='select'?14:f.type=='time'?1:0*/,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              ...f.contentChildren,
            }}
          >
            {f.beforeChildren}
            {component}
            {f.afterChildren}
          </div>
          {f.children}
        </Col>
      );
    });

    return (
      <Container fluid={true} id="CommonForm">
        <Row
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            margin: 0,
          }}
        >
          <MuiThemeProvider theme={theme}>{fields}</MuiThemeProvider>
          <Col md={button.md} offset={button.offset}>
            <div
              style={{ float: 'right' }}
              className="d-flex align-items-center my-2"
            >
              {this.props.buttonProps}
              <RaisedButton
                circleButton
                icon={button.icon || getIcon(button.label).icon}
                color={button.color || 'primaryGradient'}
                disabled={button.disabled}
                onClick={() => (button.disabled ? null : this.onFormSubmit())}
                // style={button.style}
                style={{
                  ...(!button.icon ? getIcon(button.label).style : {}),
                  ...(button.style ? button.style : {}),
                }}
                label={button.label}
                primary={button.primary}
                fullWidth={true}
              />
            </div>
          </Col>
        </Row>

        <SimplesAlert
          float
          open={this.state.alert.open}
          message={this.state.alert.message}
          variant={this.state.alert.variant}
          onClose={() => this.toggleAlert(false)}
        />
      </Container>
    );
  }
}

class AddDynamic extends React.Component {
  constructor() {
    super();
    this.state = {
      values: {},
    };
    this.handleAdd = this.handleAdd.bind(this);
    FaClose;
    this.handleRemove = this.handleRemove.bind(this);
    FaClose;
    this.onChangeField = this.onChangeField.bind(this);
  }

  componentWillMount() {
    this.handleAdd();
  }

  handleAdd() {
    let obj = {};
    let { state } = this;
    this.props.fields.map((f) => (obj[f.name] = ''));

    if (!state.values.hasOwnProperty(this.props.name))
      state.values[this.props.name] = [];

    state.values[this.props.name].push(obj);
    this.props.onChangeField(this.props, state.values[this.props.name]);
    this.setState(state);
  }

  handleRemove(idx) {
    let { state } = this;
    if (state.values[this.props.name].length > 1) {
      state.values[this.props.name].splice(idx, 1);
      this.props.onChangeField(this.props, state.values[this.props.name]);
      this.setState(state);
    }
  }

  onChangeField(index, attr, value) {
    let { values } = this.state;
    values[this.props.name][index][attr] = value;
    this.props.onChangeField(this.props, values[this.props.name]);
    this.setState({
      values: values,
    });
  }

  render() {
    let { language } = this.props;
    let fields = [];
    this.state.values[this.props.name].map((_v, i) => {
      let arrRow = [];
      this.props.fields.map((f, k) => {
        let errorText = f.required
          ? !f.disabled
            ? !this.state.values[this.props.name][i][f.name]
              ? language.REQUIRED
              : ''
            : ''
          : '';
        arrRow.push(
          <Col
            key={this.props.name + '' + i + '' + k}
            xs={12}
            sm={f.span || 12}
            md={f.span || 12}
            lg={f.span || 12}
            className="form-field"
            style={{
              display: 'flex',
              alignItems:
                'center' /*top:f.type=='select'?14:f.type=='time'?1:0*/,
            }}
          >
            {f.type === 'text' ? (
              <TextField
                error={f.error}
                value={this.state.values[this.props.name][i][f.name] || ''}
                inputProps={{ style: { height: 'auto' } }}
                variant={f.variant || 'standard'}
                label={f.label}
                floatingLabelStyle={this.props.floatingLabelStyle}
                helperText={errorText || f.help || ' '}
                fullWidth={true}
                onChange={(evt) =>
                  this.onChangeField(i, f.name, evt.target.value)
                }
                required={!!f.required}
              />
            ) : f.type === 'time' ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={this.state.values[this.props.name][i][f.name]}
                  label={f.label}
                  floatingLabelStyle={this.props.floatingLabelStyle}
                  errorText={errorText || f.help || ' '}
                  error={f.error}
                  fullWidth={true}
                  ampm={false}
                  hintText="24hr Format"
                  onChange={(evt, value) =>
                    this.onChangeField(i, f.name, evt.target.value)
                  }
                  cancelLabel={language.CANCEL}
                  required={!!f.required}
                />
              </MuiPickersUtilsProvider>
            ) : (
              ''
            )}
          </Col>
        );
      });

      fields.push(
        <Row key={i}>
          <Col xs={11} sm={11} md={11} lg={11}>
            {arrRow}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}>
            <FaClose
              color="var(--primary)"
              size={25}
              style={{ marginTop: 40, cursor: 'pointer' }}
              onClick={() => this.handleRemove(i)}
            />
          </Col>
        </Row>
      );
    });

    return (
      <Col md={this.props.span} lg={this.props.span} className="dynamicField">
        <Row>
          <Col md={4}>
            <Button
              buttonStyle={this.props.styleBtn}
              className={classes.buttonprimary}
              color="primary"
              disabled={button.disabled}
              onClick={this.handleAdd}
              label={this.props.labelBtn}
              fullWidth={true}
              color={'#fff'}
            >
              {this.props.labelBtn}{' '}
            </Button>
          </Col>
        </Row>
        <Row>{fields}</Row>
      </Col>
    );
  }
}
class ListCheckboxDiv extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: '',
      options: [],
      gmud: false,
    };
    this.onFilterValueChange = this.onFilterValueChange.bind(this);
  }

  componentWillMount() {
    this.state.options = this.props.field.options || [];
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.field.options,
    });
  }
  handleFilterGmud() {
    if (this.state.gmud == false) {
      this.setState({ gmud: true });
    } else {
      this.setState({ gmud: false });
    }
  }
  onFilterValueChange(val) {
    let res = [];

    if (val) {
      this.props.field.options.map((d) => {
        let keys = Object.keys(d);
        for (let i = 0; i < keys.length; i++) {
          if (
            typeof d[keys[i]] != 'object' &&
            d[keys[i]].toString().toLowerCase().match(val.toLowerCase())
          ) {
            res.push(d);
            break;
          }
        }
      });
    } else {
      res = this.props.field.options;
    }

    this.setState({ options: res });
  }
  getStatusCheckedAll = () => {
    let { values } = this.props;
    let { options } = this.state;
    var valueFilter = options.filter((e) => values.indexOf(e.value) != -1);

    return !(options.length > valueFilter.length);
  };

  checkAll = (e) => {
    let { checkBoxSelectAll, field } = this.props;
    if (e) {
      var value = this.state.options.map((o) => o.value);
      checkBoxSelectAll(field, value);
    } else {
      checkBoxSelectAll(field, []);
    }
  };
  render() {
    let { language, field, checkBoxSelect, values } = this.props;

    let listOpts = [];
    let listSelect = [];

    if (this.state.options) {
      try {
        <div style={{ margin: '5px 0' }}></div>;

        let opt = this.state.options;

        if (this.state.gmud) {
          opt = opt.filter((g) => field.filterarray.includes(g.value));
        } else {
        }

        listOpts = opt.map((o, i) => {
          if (values.includes(o.value)) {
            listSelect.push(
              <Chip
                avatar={
                  <Avatar
                    style={{ cursor: 'pointer' }}
                    onClick={() => checkBoxSelect(field, o.value)}
                  >
                    <i
                      className={'far fa-close '}
                      style={{
                        width: 'auto',
                        height: 'auto',
                        cursor: 'pointer',
                      }}
                    >
                      x
                    </i>
                  </Avatar>
                }
                className={classnames('chip', this.props.className)}
                label={<div className="ellipsis">{o.text || o.label}</div>}
              />
            );
          }

          return (
            <div>
              <div className="listCommonForm" key={i}>
                <FormControlLabel
                  className="fieldCustom"
                  control={
                    <Checkbox
                      checked={values.includes(o.value)}
                      onChange={() => checkBoxSelect(field, o.value)}
                    />
                  }
                  label={o.text || o.label}
                />
              </div>
              <div></div>
            </div>
          );
        });
      } catch (e) {}
    }

    return (
      <div style={{ /*marginTop: 44,*/ width: '100%' }}>
        <h4
          className="titleList font"
          style={{
            fontWeight: 100,
            margin: '4px 0',
            borderWidth: 1.9,
            border: 'none',
          }}
        >
          {field.title || field.label}
        </h4>
        <TextField
          onChange={(evt) => this.onFilterValueChange(evt.target.value)}
          fullWidth
          inputProps={{ style: { height: 'auto' } }}
          placeholder={this.props.language.SEARCH}
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {field.filtrogmud ? (
          <FormControlLabel
            control={
              <Switch
                checked={this.state.gmud}
                onChange={() => this.handleFilterGmud()}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Lista Aguardando GMUD"
          />
        ) : null}

        <div className="listCommonFormMaxHeight">
          {listOpts.length ? (
            <React.Fragment>
              <div className="listCommonForm">
                <FormControlLabel
                  className="fieldCustom"
                  control={
                    <Checkbox
                      checked={this.getStatusCheckedAll()}
                      onChange={(e) => this.checkAll(e.target.checked)}
                    />
                  }
                  label={
                    <span style={{ fontWeight: 500 }}>
                      {language.SELECT_ALL}
                    </span>
                  }
                />
              </div>
              {listOpts}
            </React.Fragment>
          ) : (
            <h2
              className="font color-lead"
              style={{ fontWeight: 100, fontSize: '1em' }}
            >
              {language.NOTHING_REGISTERED}
            </h2>
          )}
        </div>

        <div className="listCommonFormMaxHeight">{listSelect}</div>
      </div>
    );
  }
}

class ListCheckbox extends Component {
  constructor() {
    super();
    this.state = {
      filter: '',
      options: [],
    };
    this.onFilterValueChange = this.onFilterValueChange.bind(this);
  }

  componentWillMount() {
    this.state.options = this.props.field.options || [];
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.filter) {
      this.setState({
        options: nextProps.field.options,
      });
    }
  }

  onFilterValueChange(val) {
    let res = [];

    if (val) {
      this.props.field.options.map((d) => {
        let keys = Object.keys(d);
        for (let i = 0; i < keys.length; i++) {
          if (
            typeof d[keys[i]] != 'object' &&
            d[keys[i]].toString().toLowerCase().match(val.toLowerCase())
          ) {
            res.push(d);
            break;
          }
        }
      });
    } else {
      res = this.props.field.options;
    }

    this.setState({ filter: val, options: res });
  }
  getStatusCheckedAll = () => {
    let { values } = this.props;
    let { options } = this.state;
    var valueFilter = options.filter((e) => values.indexOf(e.value) != -1);

    return !(options.length > valueFilter.length);
  };

  checkAll = (e) => {
    let { checkBoxSelectAll, field } = this.props;
    if (e) {
      var value = this.state.options.map((o) => o.value);
      checkBoxSelectAll(field, value);
    } else {
      checkBoxSelectAll(field, []);
    }
  };

  render() {
    let { language, field, checkBoxSelect, values } = this.props;

    let listOpts = [];
    let listSelect = [];

    if (this.state.options) {
      try {
        <div style={{ margin: '5px 0' }}></div>;
        listOpts = this.state.options.map((o, i) => {
          return (
            <div className="listCommonForm" key={i}>
              <FormControlLabel
                className="fieldCustom"
                control={
                  <Checkbox
                    checked={values.includes(o.value)}
                    onChange={() => checkBoxSelect(field, o.value)}
                  />
                }
                label={o.text || o.label}
              />
            </div>
          );
        });
      } catch (e) {
        listOpts = this.state.options.map((o, i) => {
          return (
            <div className="listCommonFormNew" key={i}>
              <FormControlLabel
                className="fieldCustom"
                control={
                  <Checkbox
                    checked={values == o.value ? o.value : false}
                    onChange={() => checkBoxSelect(field, o.value)}
                  />
                }
                label={o.text || o.label}
              />
            </div>
          );
        });
      }
    }

    return (
      <div style={{ /*marginTop: 44,*/ width: '100%' }}>
        <h4
          className="titleList font"
          style={{
            fontWeight: 100,
            margin: '4px 0',
            borderWidth: 1.9,
            border: 'none',
          }}
        >
          {field.title || field.label}
        </h4>
        <TextField
          onChange={(evt) => this.onFilterValueChange(evt.target.value)}
          fullWidth
          inputProps={{ style: { height: 'auto' } }}
          placeholder={this.props.language.SEARCH}
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <div className="listCommonFormMaxHeight">
          {listOpts.length ? (
            <React.Fragment>
              <div className="listCommonForm">
                <FormControlLabel
                  className="fieldCustom"
                  control={
                    <Checkbox
                      checked={this.getStatusCheckedAll()}
                      onChange={(e) => this.checkAll(e.target.checked)}
                    />
                  }
                  label={
                    <span style={{ fontWeight: 500 }}>
                      {language.SELECT_ALL}
                    </span>
                  }
                />
              </div>
              {listOpts}
            </React.Fragment>
          ) : (
            <h2
              className="font color-lead"
              style={{ fontWeight: 100, fontSize: '1em' }}
            >
              {language.NOTHING_REGISTERED}
            </h2>
          )}
        </div>
      </div>
    );
  }
}

CommonForm.propTypes = {
  button: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  language: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CommonForm;
// export default withStyles(styles)(CommonForm);
