import Store from '@data/Store';
import { Avatar, Chip } from '@material-ui/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Tooltip } from 'react-tippy';

import './style.css';

export default observer((props) => {
  let {
    outlined,
    color = 'primary',
    icon,
    iconProps = {},
    loadingProps = {},
    tooltipProps,
    className,
    label,
    variant,
    size = 0.88,
    style = {},
    src,
    disabled,
    loading,
    _loading,
    tooltipLabel,
    fluid,
    onClick = () => null,
    ...other
  } = props;

  loading = _loading ? Store.$btn_loading(_loading) : loading;

  // console.log('--button--', {color, outlined, variant, size, style, src, disabled, loading, _loading, tooltipLabel, fluid, onClick, other});

  return (
    <div
      className={classnames('sensr-Buttom', { 'w-100': fluid })}
      style={{
        '--contentColor':
          color.indexOf('#') != -1 ? color : 'var(--' + color + ')',
      }}
    >
      <Tooltip
        html={<span style={{ color: 'white' }}>{tooltipLabel || label}</span>}
        arrow={true}
        position="left"
        theme="dark"
        open={
          !tooltipLabel
            ? label && variant == 'circle'
              ? undefined
              : false
            : undefined
        }
        {...tooltipProps}
      >
        {variant == 'circle' ? (
          <Avatar
            onClick={!loading && !disabled ? onClick : () => null}
            className={classnames(
              'avatar-circle',
              color,
              { outlined: outlined, disabled: loading || disabled },
              className
            )}
            zoom={size}
            style={{ ...style, zoom: size }}
            {...other}
          >
            {loading ? (
              <i
                {...loadingProps}
                className={classnames(
                  'fas fa-spinner fa-spin loading-icon',
                  loadingProps.className
                )}
              />
            ) : typeof icon == 'string' ? (
              <i
                {...iconProps}
                className={classnames(icon, iconProps.className)}
              />
            ) : src ? (
              <img src={src} alt={src} />
            ) : (
              icon
            )}
          </Avatar>
        ) : (
          <Chip
            avatar={
              (icon || src) && (
                <Avatar
                  className={classnames('avatar', {
                    'border-gradient': color == 'primaryGradient',
                  })}
                >
                  {loading ? (
                    <i
                      {...loadingProps}
                      className={classnames(
                        'fas fa-spinner fa-spin loading-icon',
                        loadingProps.className
                      )}
                    />
                  ) : typeof icon == 'string' ? (
                    <i
                      {...iconProps}
                      className={classnames(icon, iconProps.className)}
                    />
                  ) : src ? (
                    <img src={src} alt={src} />
                  ) : (
                    icon
                  )}
                </Avatar>
              )
            }
            label={
              <div className="content-label">
                {!icon && !src && loading && (
                  <i
                    {...loadingProps}
                    className={classnames(
                      'fas fa-spinner fa-spin loading-icon',
                      loadingProps.className
                    )}
                  />
                )}
                <span className="ellipsis label">{label}</span>
              </div>
            }
            _variant={variant}
            _color={color}
            _outlined={outlined ? 'outlined' : ''}
            style={{ ...style, zoom: size }}
            coloriconblack={
              color == 'light' && !outlined && variant == 'normal' ? 'yes' : ''
            }
            zoom={size}
            className={classnames(
              'button',
              color,
              {
                'w-100': fluid,
                outlined: outlined,
                disabled: loading || disabled,
              },
              className
            )}
            onClick={!loading && !disabled ? onClick : () => null}
            {...other}
          />
        )}
      </Tooltip>
    </div>
  );
});
