import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import lodash from 'lodash';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonPage from '../../../../common/CommonPage';
import GraphMonitorDevice from './componentsgsd/GraphMonitorDevice';
import {

  Dialog,

  SelectField,

  RadioButton,
  CircularProgress
} from '@material-ui/core';
import { Card, TextField, MenuItem, Paper } from '@material-ui/core';
import AppBar from '@common/AppBar';
const styleH2 = {
  textAlign: 'left'
};

@observer
export default class AnalystReportCalls extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      valueDevice: '',
      valueCompanie: '',
      showGraph: false,
      loadingGraph: false,
      CirucularLoadingGraph: false
    };

    this.generateReportBtn = this.generateReportBtn.bind(this);
  }

  componentWillMount() {
    this.props.action.execute(
      'get',
      `${this.props.APIEndpoints.COMPANY}`,
      'companies'
    );
    this.props.action.execute(
      'get',
      `${this.props.APIEndpoints.DEVICE}`,
      'devices'
    );
  }

  changeCompanies = (evt) => {
    this.setState({ valueCompanie: evt.target.value });
    this.resultCompanie = evt.target.valuee;
    this.deviceItem = this.store.devices.map(device => {
      if (device.fk_id_company == evt.target.value)
        return (
          <MenuItem
            key={device.id_device}
            value={device.id_device}
            primaryText={device.name}
          >
            {device.name}
          </MenuItem>
        );
    });
  };

  changeDevice = (evt) => {
    this.setState({ valueDevice: evt.target.value });
    this.resultDevice = evt.target.value;
  };

  generateReportBtn = () => {
    this.props.action.execute(
      'post',
      `${this.props.APIEndpoints.REPORT}` + '/13',
      'monitordevice'
    );
    this.setState({ CirucularLoadingGraph: true });
    setTimeout(
      function () {
        this.setState({ loadingGraph: true, CirucularLoadingGraph: false });
      }.bind(this),
      5000
    );
  };

  render() {
    let { language, devices, companies, monitordevice } = this.store;
    this.companyItem = companies.map(co => (
      <MenuItem
        key={co.id_company}
        value={co.id_company}
        primaryText={co.name}
      >
        {co.name}
      </MenuItem>
    ));
    var arrayteste = this.store.monitordevice;
    let { handlePage } = this.props;

    return (
      <PageLayout
        icon="imgGsd"
        title={'Monitoramento de Dispositivos'}
        subtitle={'Analise dos monitoramentos de dispositivos'}
        rightElements={[]}
        back={()=>   handlePage('dash')}
      >
        <div className='mt-5'>
          <div>
            <Paper className="pb-2">
              <AppBar light>
                <span className='font titlePattern'>{language.COMPANY_FILTER_DEVICE}</span>
              </AppBar>
              <Container fluid>

                <Row>
                  <Col md={12}>
                    <div>

                      <h3 className='titlePattern' style={{ color: '#616161cf', textAlign: 'left' }}>
                        {language.SELECT_COMPANY_CONTRACT}
                      </h3>
                    </div>
                  </Col>
                </Row>

                <div className='d-flex align-items-center'>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label={language.COMPANY}
                    style={{ minWidth: 200 }}
                    value={this.state.valueCompanie}
                    onChange={this.changeCompanies}
                    margin="dense"
                    variant="outlined"
                  >
                    {this.companyItem}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label={language.COMPANY}
                    style={{ minWidth: 200 }}
                    value={this.state.valueDevice}
                    onChange={this.changeDevice}
                    margin="dense"
                    variant="outlined"
                    className='mx-3'
                  >
                    {this.deviceItem}
                  </TextField>

                  <div>
                    <RaisedButton
                      circleButton
                      icon='fas fa-file-signature'
                      color='primaryGradient'
                      onClick={this.generateReportBtn}
                      label="Gerar Relatório"
                      style={{ fontSize: 15, paddingLeft: 3.5 }}
                      primary={true}
                    />
                  </div>

                </div>
              </Container>
            </Paper>

            {this.state.loadingGraph ? (
              <div>
                <Row>
                  <Col md={6}>
                    <Card
                      className="cardDefault"
                      style={{ paddingTop: '1em', paddingBottom: '1em' }}
                    >
                      <GraphMonitorDevice
                        data={this.store.monitordevice}
                        namegraph={'CPU'}
                      />
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card
                      className="cardDefault"
                      style={{ paddingTop: '1em', paddingBottom: '1em' }}
                    >
                      <GraphMonitorDevice
                        data={this.store.monitordevice}
                        namegraph={'MEMORIA'}
                      />
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Card
                      className="cardDefault"
                      style={{ paddingTop: '1em', paddingBottom: '1em' }}
                    >
                      <GraphMonitorDevice
                        data={this.store.monitordevice}
                        namegraph={'DISCO'}
                      />
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card
                      className="cardDefault"
                      style={{ paddingTop: '1em', paddingBottom: '1em' }}
                    >
                      <GraphMonitorDevice
                        data={this.store.monitordevice}
                        namegraph={'REDES'}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ''
            )}
          </div>

          {this.state.CirucularLoadingGraph ? (
            <div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={60} thickness={7} />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <br />
        <br />
      </PageLayout>
    );
  }
}
