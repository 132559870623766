import React, {memo, useState, useEffect} from 'react';
import classnames from 'classnames';
import IntegrationReactSelect from './select';
import Button from '@common/Button';

let delayTimer;
export default memo(({
	f, 
	statusError,
	_value,
	_handleValue,
	errors,
	individual,
})=>{
	let [value, setValue] = useState(null);

	let myError = statusError[errors[f.name]];

	function handleValue(val, name, selected){
		setValue(val);

		clearTimeout(delayTimer);

		delayTimer = setTimeout(() => {
		  _handleValue(val, f.name, selected)
		}, 50);
	}

	useEffect(()=>{
		setValue(_value);
	}, [_value])
	
	return(
		<div 
		style={{marginTop : -10}}
			className={
				classnames(
					'd-flex', 
					{
						'justify-content-start':f.align=='left',
						'justify-content-center':f.align=='center',
						'justify-content-end':f.align=='right',

						'justify-content-center':f.align==undefined
					}
				)}
		>
		  <div>
		    <span 
		    	className={classnames('label-btn-group', {error:myError})}
		    >
		    	{f.label}{f.required&&' *'}
		    </span>
		    <div className='d-flex align-items-center btn-group'>
		      {f.options.map((e,i)=>(
		        <Button 
		          icon={
		          	(typeof e.icon)=='function'?
		          		e.icon({
	          				value:value, 
	          				selected:value==e.value,
	          				error:myError
		          		})
		          	:e.icon
		          } 
		          label={e.label}
		          className='btn-item' 
		          outlined={
		          	(e.outlined||f.outlined)!=undefined
		          	?
		          		(e.outlined!=undefined?e.outlined:f.outlined)
		          	:
		          		value!=e.value
		          }
		          color={value==e.value?(e.colorActive||f.colorActive||'primary'):(e.colorInactive||f.colorInactive||'primary')}
		          variant='normal'
		          onClick={()=>f.disabled ?'':handleValue(e.value, f.name)}
		        />
		      ))}
		    </div>
		    {!individual&&(statusError[errors[f.name]]||f.help)&&
		    	<span className={classnames({error:myError}, 'span-helper ml-2')}>{!individual?(statusError[errors[f.name]]||f.help||' '):undefined}</span>
		    }
		  </div>
		</div>
	)
})