import React, { useEffect, useState } from 'react';

import Header from '../../../common/Header';
import CommonPage from '../../../common/CommonPage';
import PageLayout from '../../../common/PageLayout';

import Store from '../../../data/Store';
import Constants from '../../../data/Constants';

import ChangeTasks from './ChangeTasks';
import TicketTasks from './TicketTasks';

export default function () {
  const [page, setPage] = useState(0);

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={Store.language.TASKS_LIST}
        subTitle={Store.language.TASK_MANAGEMENT + '/'}
      >
        <Header
          active={page}
          btnFilterProps={{
            style: { width: 217 },
          }}
          btnFilter={[Store.language.TICKETS, Store.language.CHANGE]}
          onChange={(e) => setPage(e)}
        />
        {page === 0 ? (
          <TicketTasks
            APIEndpoints={Constants.APIEndpoints}
            language={Store.language}
          />
        ) : (
          <ChangeTasks
            APIEndpoints={Constants.APIEndpoints}
            language={Store.language}
          />
        )}
      </PageLayout>
    </CommonPage>
  );
}
