import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import Dialog from '@common/Dialog';
import { Paper, Card, Grid } from '@material-ui/core';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonTable from '../../../../common/CommonTable';
import CommonPage from '../../../../common/CommonPage';
import { Radial } from 'sensrit-charts';
import FaClose from 'react-icons/lib/fa/close';
import Boll from '@common/Boll';
import AppBar from '@common/AppBar';

export default class NumberCalls extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      page: '',
      open: false,
      item: '',
      type: '',
    };

    this.handlePage = this.handlePage.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen = (val, type) => {
    this.setState({ open: true, item: val, type: type });
  };

  render() {
    let { page, values, open, item, type } = this.state;
    let { language, handlePage, numberCalls, numberCallsSla, handleClose } =
      this.props;

    return (
      <PageLayout
        icon={
          <div className="imgGsd" style={{ width: '44px', height: '44px' }} />
        }
        back={()=>   handlePage('dash')}
        title={language.REPORTS}
        subtitle={language.NUMBER_CALLS}
        rightElements={[]}
      >
        {open === true ? (
          <CallsList
            language={language}
            handleClose={this.handleClose}
            open={open}
            item={item}
            type={type}
          />
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper>
              <AppBar light>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="font titlePattern mr-2">
                    {language.CALLS}
                  </span>
                  <span className="font">
                    ({language.WEEK} / {language.MONTH} / {language.YEAR})
                  </span>
                </div>
              </AppBar>
              <div className="content d-flex align-items-center justify-content-between">
                <Boll
                  value={numberCalls.map((res) => res.week)[0]}
                  description={language.WEEK}
                  color="var(--primary)"
                />
                <Boll
                  value={numberCalls.map((res) => res.month)[0]}
                  description={language.MONTH}
                  color="var(--success)"
                />
                <Boll
                  value={numberCalls.map((res) => res.year)[0]}
                  description={language.YEAR}
                  color="var(--warning)"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <AppBar light>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="font titlePattern mr-2">
                    {language.SLA_BROKEN_OUT}
                  </span>
                  <span className="font">
                    ({language.WEEK} / {language.MONTH} / {language.YEAR})
                  </span>
                </div>
              </AppBar>
              <div className="content d-flex align-items-center justify-content-between">
                <Boll
                  value={numberCallsSla.map((res) => res.week)[0]}
                  description={language.WEEK}
                  color="var(--danger)"
                />
                <Boll
                  value={numberCallsSla.map((res) => res.month)[0]}
                  description={language.MONTH}
                  color="var(--danger)"
                />
                <Boll
                  value={numberCallsSla.map((res) => res.year)[0]}
                  description={language.YEAR}
                  color="var(--danger)"
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

class RadialGraph extends Component {
  render() {
    return (
      <Card className="cardCall">
        <h2>{this.props.title}</h2>
        <Radial
          style={{ margin: 0 }}
          low={{ color: '#33ABA0' }}
          mid={{ color: '#FFC107', value: 10 }}
          high={{ color: this.props.color, value: 10 }}
          className="host-graph-perc"
          size={140}
          current={this.props.amount}
          total={100}
          thick={20}
        >
          <h2>{this.props.amount}</h2>
        </Radial>
      </Card>
    );
  }
}

class CallsList extends CommonPage {
  componentWillMount() {
    let { language, item, type } = this.props;
    let itemVar = {
      id_company: '1',
      period: item,
      type: type,
    };
    this.action.execute(
      'post',
      this.APIEndpoints.REPORT + '/10',
      'ticket',
      itemVar
    );
  }

  render() {
    let { language, item, type, handleClose, open } = this.props;
    let { ticket } = this.store;

    let col = [
      { key: 'subject', label: language.SUBJECT, style: { width: 100 } },
      {
        key: 'description',
        label: language.DESCRIPTION,
        style: { width: 120 },
      },
      { key: 'dt_cad', label: language.DATE_CAD },
    ];

    let data = ticket.map((obj) => {
      return {
        subject: obj.subject,
        description: obj.description,
        dt_cad: obj.dt_cad,
      };
    });

    return (
      <Dialog open={open} title={language.CALLS} onClose={() => handleClose()}>
        <CommonTable language={language} columns={col} data={data} />
      </Dialog>
    );
  }
}
