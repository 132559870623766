import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import DialogConfirm from '../../common/DialogConfirm';
import CommonDelete from '../../common/CommonDelete';

import DepartmentList from './DepartmentList';
import DepartmentForm from './DepartmentForm';
import { testRoute } from '@common/Func';
import Dialog from '@common/Dialog';
import Animate from '@common/Animate';
import Store from '@data/Store';

const { language } = Store;


@observer
export default class DepartmentContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'dptList',
      department: [],
      load: true,
      totaldepartment: 0,
      currentPage: 0,
      page: 0,
      departments: [],
      duplicate: [],
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.callPagination = this.callPagination.bind(this);
    this.callDataFilter = this.callDataFilter.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
  }

  componentWillMount() {
    this.action
      .execute('get', this.APIEndpoints.DEPARTMENT, '')
      .then((item) => {
        this.setState({ department: item.data });
      });
    this.callPageSelectFilter();
  }

  callDataFilter(items = {}) {}

  callPagination(page) {}

  callPageSelectFilter(page = 0, values, totalPerPage = 75) {

    if( !Number.isInteger(page) ) {
      page = 0;
    }

    this.setState({ currentPage: page });
    let limit = Number.isInteger(totalPerPage) ? totalPerPage : 75;
    let pageCount = Number.isInteger(page) ? page + 1 : 1;
    let offset = Number.isInteger(page) ? page * limit : 0 * limit;
    let route = this.props.type_config;

    if (!values) {
      values = {};
    }
    values.subdepartment = route === 'department' ? false : true;

    this.action
      .execute('post', `${this.APIEndpoints.DEPARTMENT}/list?limit=${totalPerPage}&offset=${offset}`, 'departments', values)
      .then((res) => {
        this.setState({
          departments: res.data,
          totaldepartment: parseInt(res.data[0].total_count),
        });

        return res.data.json();
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }


  duplicate (values) {
    let route = this.props.type_config;

    if (!values) {
      values = {};
    }
    values.subdepartment = route === 'department' ? false : true;

    return new Promise((resolve, reject) => {
      this.action
        .execute('post', `${this.APIEndpoints.DEPARTMENT}/list`, 'departments', values)
        .then((res) => {
          if(res.data.length > 0) {
            resolve(
              this.setState({
              duplicate: res.data
              })
            );
            return res.data;
          }

          reject(this.store.toggleAlert(true, 'Departamento já existe!', 'error'));
        })
        .catch((error) => {
          this.store.toggleAlert(
            true,
            language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
            'success'
          );
        });
    });
  }

  onFormSubmit(values) {
    let route = this.props.type_config;

    let method = values.hasOwnProperty('id_department') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_department')
      ? this.APIEndpoints.DEPARTMENT + '/' + values.id_department
      : this.APIEndpoints.DEPARTMENT;

    values.subdepartment = route === 'department' ? false : true;

    let payload = {'name': values.name ,'subdepartment': values.subdepartment, isDuplicate : true};
    this.action
      .execute('post', `${this.APIEndpoints.DEPARTMENT}/list`, 'departments', payload)
      .then((res) => {
        if(res.data.length > 0 &&  !values.hasOwnProperty('id_department')){
          this.store.toggleAlert(true, (route === 'department' ? 'Departamento ' : 'Subdepartamento ') + 'já existe!', 'error')
        } else {
          this.action
            .execute(method, url, 'departments', values, 'id_department')
            .then((e) => {
              this.handlePage('dptList');
              this.callPageSelectFilter();
            });
        }
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  onDelete() {
    let { values, departments } = this.state;
    this.action
      .execute(
        'delete',
        this.APIEndpoints.DEPARTMENT + '/' + values.id_department,
        'departments',
        null,
        'id_department'
      )
      .then((res) => {
        this.setState({
          departments: departments.filter(
            (dp) => dp.id_department != res.data.id_department
          ),
        });
      });
    this.handlePage('dptList');
  }

  render() {
    let { page, open, values, totaldepartment, departments, department,duplicate } =
      this.state;

    let route = this.props.type_config;
    let history = this.history2;
    let redirect = this.redirect2;

    let PAGE = history.location.pathname;
    let state = history.location.state;

    let { configurationProps = {} } = this.props;

    return (
      <Fragment>
        <Animate>
          <DepartmentList
            handleOpen={this.handleOpen}
            handleClose={this.handleClose}
            onFormSubmit={this.onFormSubmit}
            handlePage={this.handlePage}
            language={language}
            history={history}
            redirect={redirect}
            configurationProps={configurationProps}
            departments={departments}
            department={department}
            route={route}
            totaldepartment={totaldepartment}
            callPagination={this.callPagination}
            callDataFilter={this.callDataFilter}
            callPageSelectFilter={this.callPageSelectFilter}
            APIEndpoints={this.APIEndpoints}
            duplicate={duplicate}
          />
        </Animate>
        {['new', 'edit'].includes(page) && (
          <Dialog
            title={
              values
                ? route === 'department'
                  ? language.EDIT_DEPARTMENT
                  : language.EDIT_SUBDEPARTMENT
                : route === 'department'
                ? language.NEW_DEPARTMENT
                : language.NEW_SUBDEPARTMENT
            }
            open={true}
            onClose={() => this.handlePage('dptList')}
            maxWidth="xs"
            checkScroll
          >
            <DepartmentForm
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              language={language}
              store={this.store}
              history={history}
              values={values}
              redirect={redirect}
              departments={this.state.departments}
              department={this.state.department}
              route={route}
              duplicate={this.state.duplicate}
              APIEndpoints = {this.APIEndpoints}
            />
          </Dialog>
        )}
        {page === 'remove' && (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage(
              this.state.values.detach ? 'dptAddUserForm' : 'dptList'
            )}
            onDelete={this.onDelete}
            message={this.state.values.name}
          />
        )}
      </Fragment>
    );
  }
}
