import PT_BR from './pt-br';
import EN from './en';
import ES from './es';

export default {
  PT_BR: PT_BR,
  EN: EN,
  ES: ES
};

export const translateFromPtBR = (phraseAsPtBr, dictionaryToTranslate) => {
  const keyPhrase = Object.keys(PT_BR).find(key =>
    stringAccentRemover(PT_BR[key]).toLowerCase() === stringAccentRemover(phraseAsPtBr).toLowerCase());
  return keyPhrase ? dictionaryToTranslate[keyPhrase] || phraseAsPtBr : phraseAsPtBr;
}

export const translateFromEn = (phraseAsEn, dictionaryToTranslate) => {
  const keyPhrase = Object.keys(EN).find(key =>
    stringAccentRemover(EN[key]).toLowerCase() === stringAccentRemover(phraseAsEn).toLowerCase());
  return keyPhrase ? dictionaryToTranslate[keyPhrase] || phraseAsEn : phraseAsEn;
}

export const stringAccentRemover = (str) => {
  return String(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
