import React, { Fragment, useState, memo, useEffect } from 'react';
import Store from '@data/Store';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import List from '@common/List';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';

import { radial } from '../../Graphs';
import ButtonControl from './ButtonControl';

/* 
----------------------------format----------------------------
let data = {
      list:[
         {label:'Abertos', value:25, color:'var(--primary)'},
         {label:'Em Atendimento', value:25, color:'var(--warning)'},
         {label:'Resolvidos', value:25, color:'var(--success)'},
         {label:'Fechados', value:25, color:'var(--purple)'},
         {label:'Total', value:25, color:'var(--default)'},
      ],
      openToday:1
   }
*/

function AverageSla({ data = [], execute, APIRoot, tickets, year }) {
  let [simester, setSimester] = useState(1);
  let [monthIndex, setMonthIndex] = useState(0);
  let [info, setInfo] = useState(null);

  let { language } = Store;
  let month = language.DATE_LANG.monthLongList.slice(
    6 * (simester - 1),
    6 * simester
  );

  React.useLayoutEffect(() => {
    //execute('get', `${APIRoot}/api/home/tickets?month=${(monthIndex).toString().padStart(2,0)}&year=${year}`, 'tickets');
    setInfo(data.slice(6 * (simester - 1), 6 * simester));
  }, []);

  return (
    <Card id="AverageSla">
      {info && (
        <>
          <AppBar title={language.PRIORITIES} subTitle={`(${language.BY_MONTH})`} />
          <div className="content-medium pt-0">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <ReactHighcharts
                  config={radial({
                    name: language.CALLS,
                    data: [
                      {
                        name: language.HIGH,
                        y: data[monthIndex].hight,
                        color: 'var(--danger)',
                      },
                      {
                        name: language.MEDIUM,
                        y: data[monthIndex].medium,
                        color: 'var(--warning)',
                      },
                      {
                        name: language.LOW,
                        y: data[monthIndex].slow,
                        color: 'var(--success)',
                      },
                    ],
                    title: {
                      content:
                        data[monthIndex].hight +
                        data[monthIndex].medium +
                        data[monthIndex].slow,
                      style: 'font-size:20',
                    },
                    subTitle: { content: language.THIS_MONTH },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonControl
                  value={simester}
                  options={[
                    { value: 1, label: `1° ${language.SEMESTER}` },
                    { value: 2, label: `2° ${language.SEMESTER}` },
                  ]}
                  onChange={(v) => setSimester(v)}
                />
                <List
                  items={info.map((e, i) => ({
                    className:
                      'd-flex align-items-center justify-content-between',
                    style: {
                      padding: 8,
                      fontWeight: monthIndex == i ? 'bold' : 'normal',
                    },
                    onClick: () => setMonthIndex(i),
                    content: (
                      <Fragment>
                        <span
                          className="label"
                          style={{
                            color: monthIndex == i ? 'black' : undefined,
                          }}
                        >
                          {month[i]}
                        </span>
                        <span
                          className="value"
                          style={{
                            color: monthIndex == i ? 'black' : undefined,
                          }}
                        >
                          {e.hight + e.medium + e.slow}
                        </span>
                      </Fragment>
                    ),
                  }))}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Card>
  );
}
//   function (prev, next) {
//     let omit = (obj) => _.omitBy(obj, (val) => typeof val == 'function');
//     return _.isEqual(omit(prev), omit(next));
//   }
// );

export default memo(AverageSla);
