import React, { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Store from '@data/Store';

import { addSquadService, editSquadService } from '../squad.services';

// ----------------------------------------------------------------------

export const SquadAddOrEdit = ({ closeModal, editSquad, page }) => {
  const { language } = Store;
  const [values, setValues] = useState({});

  const submitForm = async (fields) => {
    const values = {
      ...fields,
    };

    page === 'add'
      ? await addSquadService({ values }).then(() => {
        closeModal();
        Store.toggleAlert(true, language.CREATED, 'success');
      }).catch((e) => {
        Store.toggleAlert(true, `${language.ERROR} - ${e}`, 'error');
      })
      : await editSquadService({ id: editSquad.id_business_line, values }).then(() => {
        closeModal();
        Store.toggleAlert(true, language.UPDATED_FIELDS, 'success');
      }).catch((e) => {
        Store.toggleAlert(true, `${language.ERROR} - ${e}`, 'error');
      });
  };

  useEffect(() => {
    page === 'edit' && setValues(editSquad);
  }, []);

  return (
    <Dialog
      title={
        page === 'add'
          ? `${language.ADD} ${language.COIN}`
          : `${language.EDITION} ${language.COIN} ${values.name}`
      }
      modal={false}
      open
      onClose={closeModal}
    >
      <CommonForm
        language={language}
        fields={[
          {
            col: 3,
            name: 'label',
            label: language.NAME,
            type: 'text',
            required: true,
          },
        ]}
        values={values}
        onSubmit={submitForm}
        button={{ md: 12, label: language.SAVE, primary: true }}
      />
    </Dialog>
  );
};

export default SquadAddOrEdit;
