const ContainerChart = {
  container0(data, language) {
    
    return {
      chart: {
        polar: true,
        type: 'area',

        backgroundColor: 'transparent',
        style: {
          fontFamily: "'Open Sans', 'Unica One', sans-serif"
        },
        plotBorderColor: '#606063',
        height:600
      },
      title: {
        text: ''
        /*x: -100,
        margin: 30*/
      },
      credits: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      pane: {
        size: '60%'
      },

      xAxis: {
        categories: data.map(e => e.name),
        tickmarkPlacement: 'on',
        lineWidth: 0,

        crosshair: false,
        labels:{
          style:{
            fontSize:'10px',
            color:'var(--primary)'
          }
        }
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },
      legend: {
        itemMarginTop: 30,
        backgroundColor: 'transparent',
        itemStyle:{
          fontSize:'8px'
        }
      },

      tooltip: {
        shared: true,
        valueSuffix: '%'
      },


      // data[result.name].current_maturity.push(parseInt((result.current_maturity/result.planned_maturity)*100) || 0);
      //data[result.name].planned_maturity.push(parseInt((result.planned_maturity/result.total)*100) || 0);



      series: [{
        name: language.PLANNED_MATURITY,
        data: data.map(e => parseInt((e.planned_maturity / e.total) * 100)),
        pointPlacement: 'on',
        color: '#02CBF0'
      }, {
        name: language.CURRENT_MATURITY,
        data: data.map(e => parseInt(


          ((e.current_maturity / e.planned_maturity) * 100))
        ),
        pointPlacement: 'on',
        color: '#ffa000'
      }]
    }
  },
  container1(data, language, noGeneralVision=false) {
    
    return {
      chart: {
        type: 'areaspline',
        style: {
          fontFamily: "'Open Sans', 'Unica One', sans-serif"
        },
        height:noGeneralVision?320:290
      },
      title: null,
      legend: {
        itemStyle:{
          color:'var(--primary)',
          fontSize:8
        },
        
        /*layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 150,
        y: 100,
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF'*/

      },
      xAxis: {
        categories:  data && data.to_char.reverse().map(e=>!!e?(e.substring(0,2) + '/' + e.substring(2)):e),
        labels: {
            style: {
                color:'var(--primary)',
                fontSize:10
            }
        }
      },
      yAxis: {
        title: null,
        // max: 100,
        labels: {
            style: {
                color:'var(--primary)',
                fontSize:10
            }
        }
      },
      tooltip: {
        shared: true,
        valueSuffix: '%'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5
        },
        series:{
          marker: {
            radius: 2
          }
        }
      },
      series: [{
        name: language.PLANNED_MATURITY,
        data: data && data.planned_maturity.reverse(),
        color: 'rgba(255, 160, 0, 0.3)'
      }, {
        name: language.CURRENT_MATURITY,
        data: data &&   data.current_maturity.reverse(),
        color: 'rgba(1, 159, 194, 0.3)'
      }]
    }
  },
  container2(data, language){
    return {
      chart:{
        height:320,
        style: {
          fontFamily: "'Open Sans', 'Unica One', sans-serif"
        },
      },
      title: {
          text: null
      },
      
      xAxis: {
          categories: language.DATE_LANG.monthLongList,
          crosshair: true,
          labels:{
            style:{
              color:'var(--primary)',
              fontSize:8
            }
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: null
          },
          labels:{
            style:{
              color:'var(--primary)',
              fontSize:8
            }
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          },
          series:{
            borderRadius:3,
          }
      },
      credits:{
        enabled:false
      },
      legend:{
        itemStyle:{
          color:'var(--primary)',
          fontSize:'8px',
        }
      },
      series: [{
          name: 'Item 1',
          type: 'column',
          color:'#02F0DE',
          data: [49.9, 71.5, 106.4, 13, 56, 67, 88,43,34, 67, 43,22]

      }, {
          name: 'Item 2',
          type: 'column',
          color:'#02F079',
          data: [83.6, 78.8, 98.5, 13, 56, 67, 88,43,34, 67, 43,22]

      }, {
          name: 'Item 3',
          type: 'column',
          color:'#A7F002',
          data: [48.9, 38.8, 39.3, 13, 56, 67, 88,43,34, 67, 43,22]

      }, {
          name: 'Item 4',
          type: 'spline',
          color:'#F0B002',
          data: [42.4, 90.2, 34.5, 13, 56, 67, 88,43,34, 67, 43,22]

      }]
    }
  }
}
export default ContainerChart;