import React, {Fragment, useEffect, useState, useCallback} from 'react';
import moment from 'moment';
import classnames from 'classnames'
import './NpsCompany.css';
/*--------------------------common-------------------------*/
import AppBar from '@common/AppBar2';
import List from '@common/List';
import Progress from '@common/Progress';
import ReactHighcharts, {Highcharts} from 'react-highcharts';
import Button from '@common/Button';
/*--------------------------material-ui-------------------------*/
import {Card, Grid} from '@material-ui/core';
/*--------------------------pages-------------------------*/
import { TelemetryTicket} from './Graph';
import ButtonControlMonth from './ButtonControlMonth';
import { setDate } from 'date-fns';
import { totalmem } from 'os';
import Store from '@data/Store';
const { language } = Store;


const Indicator = ({label, value, hasIcon})=>(
	<div className='content-medium indicator-item content-min mb-1'>
		<span className='ellipsis'>{label}</span>
		<div className='mt-1 d-flex align-items-center justify-content-between'>
			<h3 className={classnames({very:value>1})}>{value}</h3>
			{hasIcon&&<i className="fas fa-thumbs-up"/>}
		</div>
	</div>
)

export default function({ nps}){
	const [dataSelect, setDataSelect] = useState('');
	const [_dataGraph, setDataGraph] = useState([]);
	const [filtered, setFiltered] = useState(false);
	const [value, setValue] = useState(''); 
	const [labels, setLabels] = useState([]); 
	const [list, setList] = useState([]); 
	const [list2, setList2] = useState([]); 
	const [handle, SethandleDate] = useState(0);
	let [labelMonth, setLabelMonth] = useState('');
	let [total, setTotal] = useState('');
	const [dados, setDados] = useState([]); 
	let [pro, setPro] = useState(0);
	let [pass, setPass] = useState(0);
	let [det, setDet] = useState(0);
	let [dataNps, setDataNps] = useState({nps});
	var dataTotal = 0


	const handleDate = useCallback((v, nps) => {

		SethandleDate(v);
		let dt=[];
			let pa=[];
			let pro=[];

		  setTotal(nps.nps.npsDetail.list.reduce((current, next) => {
			return current + 
			v==0?
			next.total.avaliation_level  :
			v==1? 	next.total.experience :
			next.total.resolved;



		  }, 0))
         if(v==0){
          let  tdet= nps.nps.npsDetail.list.reduce((current, next) => {
			return current + next.total1.avaliation_level.detratores
		   }, 0) 
		   let  tpass =  nps.nps.npsDetail.list.reduce((current, next) => {
			return current + next.total1.avaliation_level.pasivos
		  }, 0)
		  let  tpro= nps.nps.npsDetail.list.reduce((current, next) => {
			return current +next.total1.avaliation_level.promotores
		  }, 0) 
		  let  ttotals= nps.nps.npsDetail.list.reduce((current, next) => {
			return current +next.total1.avaliation_level.total
		  }, 0)


		  setList(nps.nps.npsDetail.list.map(g=>({
			name:g.label , det:g.total1.avaliation_level.detratores,
			  pas:g.total1.avaliation_level.pasivos,
			  pro:g.total1.avaliation_level.promotores
			  , color:g.color,
			label:g.label
		  })))
		  
		  setPro((tpro).toFixed(0))
		  setPass((tpass).toFixed(0) )
		  setDet((tdet).toFixed(0) )
		  nps.nps.npsDetail.list.map(g=>{
			//console.log(g.total1.avaliation_level,"avvv")
			dt.push(g.total1.avaliation_level.detratores)
		  pa.push(g.total1.avaliation_level.pasivos)
		  pro.push(g.total1.avaliation_level.promotores)
  
	   })
				setDados([{ name: language.DETRACTORS, data: dt, color: '#F44336' },
					 { name: language.PASSIVES, data: pa, color: '#F0B002' },
					 { name: language.PROMOTERS, data: pro, color: '#92D050' }])



		}else if(v==1){

			let  tdet= nps.nps.npsDetail.list.reduce((current, next) => {
				return current + next.total1.resolved.detratoresre
			   }, 0) 
			   let  tpass =  nps.nps.npsDetail.list.reduce((current, next) => {
				return current + next.total1.resolved.pasivosre
			  }, 0)
			  let  tpro= nps.nps.npsDetail.list.reduce((current, next) => {
				return current +next.total1.resolved.promotoresre
			  }, 0) 
			  let  ttotals= nps.nps.npsDetail.list.reduce((current, next) => {
				return current +next.total1.resolved.total
			  }, 0)
			  setList(nps.nps.npsDetail.list.map(g=>({
				name:g.label , det:g.total1.resolved.detratoresre,
				  pas:g.total1.resolved.pasivosre,
				  pro:g.total1.resolved.promotoresre
				  , color:g.color,
				label:g.label
			  })))
			  setPro((tpro).toFixed(0))
			  setPass((tpass).toFixed(0) )
			  setDet((tdet).toFixed(0) )
			  nps.nps.npsDetail.list.map(g=>{
				dt.push(g.total1.resolved.detratoresre)
			  pa.push(g.total1.resolved.pasivosre)
			  pro.push(g.total1.resolved.promotoresre)
	  
		   })
		   
		   setDados([{name:'Detratores',data:dt,color:'#F44336' } ,
		   {name:'Passivos',data:pa,color:'#F0B002'},
		   {name:'Promotores',data:pro,color:'#92D050'}])
		}else if (v==2){
			let  tdet= nps.nps.npsDetail.list.reduce((current, next) => {
				return current + next.total1.experience.detratorex
			   }, 0) 
			   let  tpass =  nps.nps.npsDetail.list.reduce((current, next) => {
				return current + next.total1.experience.pasivosex
			  }, 0)
			  let  tpro= nps.nps.npsDetail.list.reduce((current, next) => {
				return current +next.total1.experience.promotoresex
			  }, 0) 
			  let  ttotals= nps.nps.npsDetail.list.reduce((current, next) => {
				return current +next.total1.experience.total
			  }, 0)
			  setList(nps.nps.npsDetail.list.map(g=>({
				name:g.label , det:g.total1.experience.detratorex,
				  pas:g.total1.experience.pasivosex,
				  pro:g.total1.experience.promotoresex
				  , color:g.color,
				label:g.label
			  })))
			  setPro((tpro).toFixed(0))
			  setPass((tpass).toFixed(0) )
			  setDet((tdet).toFixed(0) )
			  nps.nps.npsDetail.list.map(g=>{
				dt.push(g.total1.experience.detratorex)
			  pa.push(g.total1.experience.pasivosex)
			  pro.push(g.total1.experience.promotoresex)
	  
		   })

					 setDados([{ name: language.DETRACTORS, data: dt, color: '#F44336' },
					 { name: language.PASSIVES, data: pa, color: '#F0B002' },
					 { name: language.PROMOTERS, data: pro, color: '#92D050' }])
		}
 
				     
			},[]);

	
	useEffect(() => {
		 try{
			let dt=[];
			let pa=[];
			let pro=[];
			 nps.nps.npsDetail.list.map(g=>{
				 dt.push(g.total1.avaliation_level.detratores)
			   pa.push(g.total1.avaliation_level.pasivos)
			   pro.push(g.total1.avaliation_level.promotores)
	   
			})
			
			 setDados([{ name: language.DETRACTORS, data:dt,color:'#F44336' } ,
				 { name: language.PASSIVES, data:pa,color:'#F0B002'},
				 { name: language.PROMOTERS, data:pro,color:'#92D050'}])

			setLabels(nps.nps.npsDetail.list.map(b => b.label))
		setList(nps.nps.npsDetail.list.map(g=>({
		  name:g.label , det:g.total1.avaliation_level.detratores,
			pas:g.total1.avaliation_level.pasivos,
			pro:g.total1.avaliation_level.promotores
		    , color:g.color,
		  label:g.label
		}))) 
	   
	    setTotal(nps.nps.npsDetail.list.reduce((current, next) => {
			return current + next.total.avaliation_level
		  }, 0))

		   let  tdet= nps.nps.npsDetail.list.reduce((current, next) => {
			return current + next.total1.avaliation_level.detratores
		   }, 0) 
		   let  tpass =  nps.nps.npsDetail.list.reduce((current, next) => {
			return current + next.total1.avaliation_level.pasivos
		  }, 0)
		  let  tpro= nps.nps.npsDetail.list.reduce((current, next) => {
			return current +next.total1.avaliation_level.promotores
		  }, 0) 
		  let  ttotals= nps.nps.npsDetail.list.reduce((current, next) => {
			return current +next.total1.avaliation_level.total
		  }, 0)

		  setPro((tpro).toFixed(0))
		  setPass((tpass).toFixed(0) )
		  setDet((tdet).toFixed(0) )
		 /**  let [pro, setPro] = useState(0);
		  let [pass, setPass] = useState(0);
		  let [det, setDet] = useState(0); */
		  setDataNps(nps)

		 }catch(r){}
		  
	}, [nps]);
	
  

	//console.log(`${type} Call texch`, list);
	return(
		<Card className='Call_x_Tech_Nps h-100'>
			<AppBar title={`Nps ${language.COMPANY}`} subTitle={""}>
				{filtered && (
					<Button 
						tooltipLabel={language.RELOAD_ALL}
						variant="circle"
						onClick={() => {
							clearFilter()
						}}
						color='primary'
						className="mr-1"
						icon="fas fa-redo"
						size={.55}
					/>
				)}
				<ButtonControlMonth onChange={(e) => handleDate(e, nps)} value={handle}/>
			</AppBar>
			<div className='content-medium'>
				<Grid container spacing={16}>
					<Grid item xs={9}>
						<ReactHighcharts 
							config={TelemetryTicket( 
								  dados,labels 
							 )}
							 
						/>
					</Grid>  
					<Grid item xs={3} className='content-indicator'>
						<Indicator label={language.PROMOTERS} value={pro}/>
						<Indicator label={language.PASSIVES} value={pass}/>
						<Indicator label={language.DETRACTORS} value={det} />
					</Grid>
				</Grid>
			</div>
			<List
			  className='list-category-items'
			  mh={145}
			  items={(list||[]).map(e=>({
			      className:'d-flex align-items-center justify-content-between items py-1',
			      content:(
			        <Fragment>
			          <div className='d-flex align-items-center content-label w-75' onClick={() => {
						 // handleFilter(e.label); 
						 // setValue(e.label);
						//  setFiltered(true)
						}}>
			            <div className='mr-2' style={{backgroundColor:e.color}}/>
			            <span className='ellipsis d-block'>{e.label}</span>
					  </div>

			          <div className='d-flex align-items-center justify-content-end content-progress-info'  onClick={() => {
						//  handleFilter(e.label); 
						 // setValue(e.label);
						//  setFiltered(true)
						}}>
				  		
			          <div className='d-flex align-items-center'>
							   <span style={{background:'#92D050', padding:'2px 5px',marginLeft:4, borderRadius:4,color:'white'}} 
							     >{e.pro} </span>
							   <span style={{background:'#F0B002', padding:'2px 5px',marginLeft:4,borderRadius:4,color:'white'}}
							   
							    >{e.pas}</span>
							  <span
							  style={{background:'#F44336', padding:'2px 5px',marginLeft:4,borderRadius:4,color:'white'}} 
							 >{e.det} </span>
							
							  
					    	</div>
					 
			          </div>
			        </Fragment>
			      )
			  }))}
			/>
		</Card>
	)
}