import React, { Component } from 'react';
import { Grid, Card, TextField } from '@material-ui/core'; //comum

import {
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FilledInput,
  FormControlLabel,
} from '@material-ui/core'; // controls
const crypto = require('crypto');
import RequestView from './RequestView';
import Dialog from '@common/Dialog';

import TicketManagement from '../TicketManagement/index';
import { AppBar, Toolbar, Typography } from '@material-ui/core'; //header
import moment from 'moment';
import { observer } from 'mobx-react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import RaisedButton from '@common/RaisedButton';
import CommonPage from '../../common/CommonPage';
import PageLayout from '@common/PageLayout';

import CommonTable from '@common/CommonTable';
import { CSVLink, CSVDownload } from 'react-csv';
import Pickers from '@common/Pickers';
import TicketUtils from '../../util/TicketUtils';
import Animate from '@common/Animate';
import CommonForm from '@common/CommonForm2';
import HistoricSearch from './HistoricSearch';
import TicketReport from './TicketReport';
import DownloadFiles from '../../util/DownloadFiles';
import Store from '@data/Store';

const { language } = Store;

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});
@observer
export default class TicketHistoric extends CommonPage {
  constructor() {
    super();

    let PAGE = window.location.href.replace(window.location.origin + '/', '');

    this.state = {
      tickets: [],
      date: {
        ini: moment().date(1),
        end: moment(),
      },
      dtIni: moment().format('MM/01/YYYY'),
      dtEnd: moment().format('MM/DD/YYYY'),

      dialog: false,
      search: null,
      id: null,
      usuario: null,
      tecnico: null,
      idTicket: '',
      companyId: [],
      companies: [],
      request: null,
      tpconsult: 0,
      switch: 1,
      template: '',
      advancedSearchModalOpen: false,
      currentPage: 0,
      totalTickets: 0,
      filter: {},
    };

    this.handleModal = this.handleModal.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.updateSearchid = this.updateSearchid.bind(this);
    this.updateSearchusuario = this.updateSearchusuario.bind(this);
    this.updateSearchTech = this.updateSearchTech.bind(this);
    this.openRequest = this.openRequest.bind(this);
    this.onTemplateSearch = this.onTemplateSearch.bind(this);
    this.openTicket = this.openTicket.bind(this);
    this.RequestView_ = this.RequestView_.bind(this);
    this.sendvalues = this.sendvalues.bind(this);
    this.advancedSearchModalOpen_ = this.advancedSearchModalOpen_.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.exportTasks = this.exportTasks.bind(this);
    this.sortBy = this.sortBy.bind(this);

  }

  advancedSearchModalOpen_() {
    this.setState({ advancedSearchModalOpen: true });
    this.callPageSelectFilter();
  }
  openTicket(id) {
    this.setState({ idTicket: id });
  }
  RequestView_(request) {
    let i =
      request.realid.indexOf('-') > -1
        ? request.realid.split('-')[0]
        : request.realid;
    i = i.replace('R', '');
    this.action
      .execute('get', this.APIEndpoints.REQUEST + 'onehis' + '/' + i, 'request')
      .then((g) => {
        this.setState({ request: g.data[0] });
      });
  }

  onTemplateSearch(v) {
    if (v.type == 1) {
      this.state.id = v.description;
    }

    if (v.type == 2) {
      this.state.usuario = v.description;
    }

    if (v.type == 3) {
      this.state.tecnico = v.description;
    }
    if (v.type == 4) {
      this.state.search = v.description;
    }
    if (v.type == 5) {
      this.state.template = v.description;
    }
    this.sendvalues();
  }

  decryp = (aMsg = '') => {
    try {
      var aSecret = 'Orch3str0!@forever!';

      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(
        (aMsg || '').replace(/\s/g, '+'),
        'base64',
        'utf8'
      );
      tRet += decipher.final('utf8');

      return tRet;
    } catch (e) {
      return {};
    }
  };
  componentDidMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/20',
      'type_solution'
    );

    this.action.execute('get', this.APIEndpoints.ANSWER, 'answer');
    this.action.execute('get', this.APIEndpoints.TICKET + '/params', 'params');
    this.callPageSelectFilter();
  }

  handleModal() {
    this.setState({ dialog: false });
  }
  updateSearch(event) {
    this.setState({ search: event.target.value });
  }
  updateSearchid(event) {
    this.setState({ id: event.target.value });
  }
  updateSearchusuario(event) {
    this.setState({ usuario: event.target.value });
  }
  updateSearchTech(event) {
    this.setState({ tecnico: event.target.value });
  }

  openRequest(id) {
    let i = id.indexOf('-') > -1 ? id.split('-')[0] : id;
    i = i.replace('R', '');
    this.action
      .execute('get', this.APIEndpoints.REQUEST + 'onehis' + '/' + i, 'request')
      .then((g) => {
        this.setState({ request: g.data[0] });
      });
  }

  changeDate = (type, value) => {
    this.setState({ modal: false });
    var date = this.state.date;
    date[type] = value;

    this.setState({ date });
  };

  exportTasks() {
    const values = {
      dataini: moment(this.state.date.ini).format('YYYYMMDD'),
      dataend: moment(this.state.date.end).format('YYYYMMDD'),
      switch: this.state.switch,
      companyId: this.state.companyId,
    };

    this.action
      .execute('post', this.APIEndpoints.TASKS + '/export', '', values)
      .then((res) => {
        DownloadFiles(
          this.APIEndpoints.TASKS + '/download/' + res.data.file,
          res.data.file
        );
      });
  }
  componentWillMount() {
    let values = {
      dataini: moment().format('YYYYMM01'),
      dataend: moment().format('YYYYMMDD'),
      id_user: this.store.getUserLoged().id,
      role: this.store.getUserLoged().role,
      limit: 75,
      offset: 0,
    };
  }

  delayticket(ticket) {
    let proc = false;
    let req = null;
    let sla = null;
    let { language } = this.props;
    if (ticket.solicit) {
      req = [
        {
          dt_up: ticket.dtuprequest,
          dt_cad: ticket.dtcadrequest,
          id_request: 1,
        },
      ];
    }

    if (
      (ticket.tp == 'ticket' && ticket.status == 'Open') ||
      ticket.status == 'In Progress'
    ) {
      sla = TicketUtils.calcSla(
        ticket,
        'ticket',
        language,
        req,
        ticket.sla_task
      );
      if (sla.color == 'var(--red)') {
        return true;
      } else {
        return false;
      }
    } else if (ticket.status == 'Closed' || ticket.status == 'Resolved') {
      sla = TicketUtils.stopSla(ticket, null, language, req, ticket.sla_task);

      if (sla.color == 'var(--red)') {
        return true;
      } else {
        return false;
      }
    } else if (ticket.tp == 'request') {
      sla = TicketUtils.calcSla(ticket, 'request');
      if (sla.color == 'var(--red)') {
        return true;
      } else {
        return false;
      }
    }

    return proc;
  }

  callPageSelectFilter(page = 0, values = {}, totalPerPage = 100,item,order) {

    this.setState({ currentPage: page, filter: values });


    let limit = totalPerPage || 100;
    let pageCount = page + 1;
    let offset = page * limit;

    let id = this.state.id;
    let desc = this.state.search;
    let usuario = this.state.usuario;
    let tecnico = this.state.tecnico;
    let templates = this.state.template;

    let values_ = {
      dataini: moment(this.state.date.ini).format('YYYYMMDD'),
      dataend: moment(this.state.date.end).format('YYYYMMDD'),
      searchtext: desc === '' ? null : desc,
      searchid: id === '' ? null : id,
      searchusuario: usuario === '' ? null : usuario,
      tecnico: tecnico === '' ? null : tecnico,
      companyId: this.state.companyId,
      switch: this.state.switch,
      template: templates === '' ? null : templates,
      id_user: this.store.getUserLoged().id,
      role: this.store.getUserLoged().role,
      limit: limit,
      offset: offset,
      filter: this.state.filter,
      orderItem:item,
      orderDesc:order
    };
    this.setState({ dialog: false });
    this.store.loading = true;

    values_.excel = false;

    this.action
      .execute('post', `${this.APIEndpoints.REPORTEXPORT}/ticket`, '', values_)
      .then((res) => {
        this.setState({
          id: '',
          search: '',
          usuario: '',
          tecnico: '',
          template: '',
        });

        this.store.loading = false;

        this.setState({
          tickets: res.data,
          totalTickets: parseInt(res.data[0] ? res.data[0].total_count : 0),
        });
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }

  sortBy  (item, order)  {
   this.callPageSelectFilter(0, this.state.filter, 100,item,order)
  }

  getReport() {
    this.action
      .execute('post', `${this.APIEndpoints.TICKET}/report`, '', values_)
      .catch((error) => {
        console.error(error);
      });
  }

  sendvalues(item) {
    if (item == 1) {
      this.store.toggleAlert(
        true,
        language.YOUR_REPORT_WILL_BE_GENERATED_IN_A_FEW_SECONDS,
        'success'
      );
    } else {
      this.store.toggleAlert(
        true,
        language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
        'success'
      );
    }

    // When you want to cancel it:

    let id = this.state.id;
    let desc = this.state.search;
    let usuario = this.state.usuario;
    let tecnico = this.state.tecnico;
    let templates = this.state.template;

    let values = {
      dataini: moment(this.state.date.ini).format('YYYYMMDD'),
      dataend: moment(this.state.date.end).format('YYYYMMDD'),
      searchtext: desc === '' ? null : desc,
      searchid: id === '' ? null : id,
      searchusuario: usuario === '' ? null : usuario,
      tecnico: tecnico === '' ? null : tecnico,
      companyId: this.state.companyId,
      switch: this.state.switch,
      template: templates === '' ? null : templates,
      id_user: this.store.getUserLoged().id,
      role: this.store.getUserLoged().role,
      limit: 75,
      offset: 0,
      filter: this.state.filter,
    };
    this.setState({ dialog: false });

    if (item == 1) {
      values.excel = true;
      this.store.loading = false;
    } else {
      this.store.loading = true;
    }

    this.action
      .execute('post', `${this.APIEndpoints.REPORTEXPORT}/ticket`, '', values)
      .then((res) => {
        this.setState({
          id: '',
          search: '',
          usuario: '',
          tecnico: '',
          template: '',
        });

        if (item == 1) {
          // this.store.loading = false;
        } else {
          if (res.data.length > 0) {
            this.setState({ totalTickets: parseInt(res.data[0].total_count) });
            this.setState({ tickets: [...res.data] });
            this.store.toggleAlert(true, language.QUERY_MADE, 'success');
            this.store.loading = false;
          } else {
            this.store.loading = false;
            this.setState({ totalTickets: 0, tickets: [] });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        // this.store.toggleAlert(true, 'Aguarde estamos processando os dados', 'success');
      });
  }

  render() {
    let { language } = this.store;

    const typeOptions = [
      { icon: 'fas fa-tools', label: language.BY_OPENING, value: 1 },
      { icon: 'fas fa-chart-line', label: language.BY_CATEGORIZATION, value: 2 },
      { icon: 'fas fa-shield-alt', label: language.BY_CLOSING, value: 3 },
    ];
    return (
      <CommonPage
        title={<div className="logoWhite" />}
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        {this.state.advancedSearchModalOpen == true ? (
          <Dialog
            open={this.state.advancedSearchModalOpen}
            onClose={() => this.setState({ advancedSearchModalOpen: false })}
          >
            <CommonForm
              fields={[
                {
                  type: 'select',
                  name: 'type',
                  label: language.SELECT_OR_FILTER_TYPE,
                  options: [
                    { value: 1, label: 'ID' },
                    { value: 2, label: language.USER },
                    { value: 3, label: language.TECH },
                    { value: 4, label: language.DESCRIPTION },
                  ],
                },
                {
                  type: 'text',
                  name: 'description',
                  label: language.DESCRIPTION,
                },
              ]}
              onChangeField={(f, v) => {}}
              onSubmit={(v) => {
                this.onTemplateSearch(v);
                this.setState({ advancedSearchModalOpen: false });
              }}
            ></CommonForm>
          </Dialog>
        ) : null}
        {this.state.request != null ? (
          <RequestView
            language={language}
            onClose={() => this.setState({ request: null })}
            store={this.store}
            filedelete={null}
            request={this.state.request}
            ondeletefile={null}
            redirect2={null}
            filesdelete={null}
            ondonwFile={null}
          />
        ) : null}

        {this.state.idTicket != '' ? (
          <Dialog
            title={language.ASSOCIATION_TICKETS}
            onClose={() => this.setState({ idTicket: '' })}
            open={this.state.idTicket != '' ? true : false}
            maxWidth="xl"
          >
            <TicketManagement
              detail={true}
              id_ticket_detail={this.state.idTicket}
            ></TicketManagement>
          </Dialog>
        ) : null}

        {this.state.dialog ? (
          <Dialog open={open} onClose={() => this.handleModal} title={''}>
            <h1>Consulta limite 3 meses</h1>
          </Dialog>
        ) : null}

        <Animate>
          <PageLayout
            icon={
              <div
                className="panelico"
                style={{ width: 40, height: 40, overflowY: true }}
              />
            }
            loading={false}
            title={language.TICKETCONHIST}
            subTitle={language.DASHBOARD_PAGE.DM + '/'}
            //tem um exemplo de campo com select em requestLis.js
            rightElements={[
              <TicketReport
                id_user={this.store.getUserLoged().id}
              ></TicketReport>,
              <div
                style={{
                  width: '100%',
                  marginTop: '1rem',
                }}
              >
                <CommonForm
                  individual
                  hiddenSubmit
                  values={this.state}
                  onChangeField={(f, v) => {
                    if (f.name == 'dtIni') {
                      this.changeDate('ini', v);
                    }
                    if (f.name == 'dtEnd') {
                      this.changeDate('end', v);
                    }

                    if (f.name == 'companyId') {
                      this.setState({ companyId: v });
                    }
                    if (f.name == 'id') {
                      this.setState({ id: v });
                    }

                    if (f.name == 'usuario') {
                      this.setState({ usuario: v });
                    }

                    if (f.name == 'tecnico') {
                      this.setState({ tecnico: v });
                    }
                    if (f.name == 'search') {
                      this.setState({ search: v });
                    }
                    if (f.name == 'switch') {
                      this.setState({ switch: v });
                    }
                  }}
                  fields={[
                    {
                      col: 3,
                      type: 'autocomplete',
                      name: 'companyId',
                      label: language.COMPANY,
                      method: 'POST',
                      route: `${this.APIEndpoints.COMPANY}/filter`,
                      fieldquery: 'search',
                      textlabel: language.COMPANY,
                      textinit: language.COMPANY,
                      fieldvaluedb: 'id_company',
                      fieldlabeldb: 'name',
                      idinit: 0,
                      routeAll: `${this.APIEndpoints.COMPANY}/all?`,
                      methodGetAll: 'POST',
                      routeGetCount: `${this.APIEndpoints.COMPANY}/count`,
                      detail: true,
                      visible: this.store.getUserLoged().role != 'solicitant',
                    },

                    {
                      col: 2,
                      type: 'date',
                      name: 'dtIni',
                      label:
                        this.state.switch == 1
                          ? language.OPENING_DATA_INITIAL
                          : this.state.switch == 2
                            ? language.CATEGORIZATION_DATA_INITIAL
                            : language.DATE_DATE_INITIAL,
                    },
                    {
                      col: 2,
                      type: 'date',
                      name: 'dtEnd',
                      label:
                        this.state.switch == 1
                          ? language.OPENING_DATA_FINAL
                          : this.state.switch == 2
                            ? language.CATEGORIZATION_DATA_FINAL
                            : language.DATE_DATE_FINAL,
                    },
                    {
                      col: 4,
                      name: 'switch',
                      label: language.BY_TYPE,
                      type: 'btnGroup',
                      colorInactive: 'primary',
                      colorActive: 'primary',
                      options: typeOptions,
                    },

                    {
                      col: 1,
                      type: 'element',
                      content: (v, h, submit) => (
                        <RaisedButton
                          onClick={() => this.sendvalues()}
                          circleButton
                          icon="fas fa-search"
                          color="primaryGradient"
                          label={language.SEARCH}
                          style={{ fontSize: 15, marginTop: '0.8rem', marginLeft: 'auto' }}
                        />
                      ),
                    },
                  ]}
                />
              </div>,
            ]}
          >
            <MuiThemeProvider theme={theme}>
              <div style={{ marginTop: '-1.5rem' }} id="" className="dev">
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <HistoricSearch
                      exportTasks={this.exportTasks}
                      sendvalues={this.sendvalues}
                      callPageSelectFilter={this.callPageSelectFilter}

                      sortBy={this.sortBy}
                      openTicket={this.openTicket}
                      currentPage={this.state.currentPage}
                      totalTickets={this.state.totalTickets}
                      RequestView_={this.RequestView_}
                      theme={this.theme}
                      advancedSearchModalOpen_={this.advancedSearchModalOpen_}
                      tickets={this.state.tickets}
                      language={language}
                    ></HistoricSearch>
                  </Grid>
                </Grid>
              </div>
            </MuiThemeProvider>
          </PageLayout>
        </Animate>
      </CommonPage>
    );
  }
}
