import React, {useState} from 'react';
import classnames from 'classnames';
/*-------------------------------common---------------------------------------*/
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import List from '@common/List';

import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@common/Button'; 
/*-------------------------------material-ui---------------------------------------*/

export default function({open, values={}, onClose, onSubmit, users, idResult}){

	if (values.id_okr_results_subactivies) {
		let responsibles = values.responsibles.map(x => x.id_user);
		values = { ...values, responsibles}

	}

	const handleSubmit = (v) => {
		if (!values.id_okr_results_subactivies) {
			v.fk_id_okr_results = idResult
		}

		onSubmit(v);
	}
	
	return(
		<Dialog
		  PaperProps={{
		    id: 'DialogFormOkrSubActivities'
		  }}
		  open={open}
		  onClose={onClose}
		  maxWidth='xs'
		  title={`${values.id_okr_results_subactivies?'Editar':'Adicionar'} subatividade`}
		>
				<CommonForm
					values={values}
					fields={[
						{
							col:12,
							type:'date',
							name:'deadline_subactivity',
							required:true,
							label:'Prazo'
						},
						{
							col:12,
							type:'simplesTextEditor',
							required:true,
							name:'description',
							label:'Subatividade',
						},
						{
							col:12,
							type:'simplesTextEditor',
							name:'result_subactivity',
							label:'Resultado esperado',
							required:true,
						},
						{
							col:12,
							type:'listCheckbox',
							name:'responsibles',
							label:'Responsáveis',
							required:true,
							options:users
						},
						
					]}
					onSubmit={handleSubmit}
					button={
						{ label: values.id_okr_results_subactivies ? 'Atualizar' : 'Salvar'}
					}
				/>
		</Dialog>
	)
}