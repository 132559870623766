import React, { useEffect, useState } from 'react';
import CommonForm from '../../../common/CommonForm2';
import Store from '@data/Store';
import Constants from '../../../data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const TopicSacForm = ({ language, setForm, callPageSelectFilter, values_ }) => {
  const [themes, setThemes] = useState([]);

  const button = {
    label: language.SAVE,
  };

  const onFormSubmit = (values) => {
    const method = values.id_topic ? 'PUT' : 'POST';
    const url = values.id_topic
      ? `${Constants.APIEndpoints.SAC_TOPICS}/updatetopic`
      : Constants.APIEndpoints.SAC_TOPIC;

    action
      .execute(method, url, '', { ...values, user: Store.getUserLoged() })
      .then((res) => {
        setForm(false);
        callPageSelectFilter();
      })
      .catch((e) => {
        console.log('Error:', e);
      });
  };

  useEffect(() => {
    action
      .execute('post', `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?limit=75&offset=${0 * 75}`, '')
      .then((res) => {
        setThemes(res.data);
      });
  }, []);

  const fields = [
    {
      name: 'name',
      label: 'Tópico',
      type: 'text',
      required: true,
      col: 12,
    },
    {
      name: 'fk_id_theme',
      label: 'Tema',
      type: 'select',
      options: themes.map((t) => ({ value: t.id_theme, label: t.name })),
      required: true,
      col: 12,
    }
  ];

  return (
    <div>
      <CommonForm
        button={button}
        fields={fields}
        onSubmit={onFormSubmit}
        values={values_}
      />
    </div>
  );
};

export default TopicSacForm;
