const ContainerChart = {
 
  container0(data){
    return {
      chart: {
        type: 'pie',
        backgroundColor:'transparent'
    },
    credits: {
      enabled: false
    },
    title: {
        text: data.title,
        style: {
            fontSize:25
        },
    },
   
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                format: '{point.name}: {point.y:.1f}'
            }
        },
        pie: {
            size: 257
        }
    },

    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
    },

    series: [
        {
            name: "Browsers",
            colorByPoint: true,
            data:data.data
        }
    ]
    }
  },
  
}
export default ContainerChart;