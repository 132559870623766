import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useServicePortalStyles } from '../helpers';
import { SendOutlined } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

export default function Search({ label, onChange }) {
    const styles = useServicePortalStyles();

    return (
        <Paper className={styles.searchRoot} elevation={0} variant="elevation">
            <InputBase
                className={classNames(styles.searchInput, styles.text)}
                classes={{input: styles.searchInputBase}}
                placeholder={label}
                inputProps={{ 'aria-label': label }}
                onChange={(e) => onChange && onChange(e)}
            />
            <IconButton
                type="submit"
                aria-label={label}
                disableRipple={true}
                className={styles.searchIconButton}
            >
                <SearchIcon fontSize='small' />
            </IconButton>
        </Paper>
    );
}


export function Send({ label, isLoading, cleanSendInput, onClick }) {
    const styles = useServicePortalStyles();
    const [value, setValue] = useState();

    useEffect(() => {
        if (cleanSendInput) setValue('');
    }, [cleanSendInput])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const data = event.target.value;
            data && handleSend(data);
        }
    }

    const handleSend = (valueReceived) => {
        const data = valueReceived ?? value;
        data && onClick(data)
    }

    return (
        <Paper className={classNames(styles.sendRoot, 'tw-rounded-full')} elevation={0} variant="elevation">
            <InputBase
                className={classNames(styles.searchInput, styles.text, 'tw-rounded-full')}
                classes={{input: styles.searchInputBase}}
                placeholder={label}
                inputProps={{ 'aria-label': label }}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                value={value}
            />
            <IconButton
                type="button"
                aria-label={label}
                disableRipple={true}
                className={styles.searchIconButton}
                onClick={() => !isLoading && handleSend()}
            >
                {!isLoading && <SendOutlined fontSize='small' />}
                {isLoading && <CircularProgress color='inherit' size='1.3rem' />}
            </IconButton>
        </Paper>
    );
}
