import React, {Fragment} from 'react';
/*------------------------common----------------------------------*/
import AppBar2 from '@common/AppBar2';
import RadialGraph from '@common/RadialGraph';
import List from '@common/List';
 
/*------------------------material-ui----------------------------------*/
import { Card, Grid } from '@material-ui/core';
import Store from '@data/Store';

const { language } = Store;

export default function({data}){
	return(
		<Card id='CardTotal' className='h-100'>
			<AppBar2 title={language.TOTAL} />
		  <Grid container spacing={2}>
		    <Grid item xs={5}>
                  <RadialGraph 
                  data={data} 
                  outerRadius={40} 
                  innerRadius={30} 
                  eProps={{centerValue:true, centerProps:{style:{fontWeight:'bold', fontSize:22}}}}
                  height={110} 
                  width={110}  
               />
          
		    </Grid>
		    <Grid item xs={7}>
		      <List
		        className='list-category-items'
		        items={data.map(e=>({
		            className:'d-flex align-items-center justify-content-between items py-1',
		            content:(
		              <Fragment>
		                <div className='d-flex align-items-center content-label'>
		                  <div className='mr-2' style={{backgroundColor:e.color}}/>
		                  <span style={{color:e.color}}>{e.name}</span>
		                </div>
		                <span className='value'>
                              {e.value}
                       </span>
		              </Fragment>
		            )
		        }))}
		      />
		    </Grid>
		  </Grid>
		</Card>
	)
}