import React, {useState, useReducer, useCallback, useEffect} from 'react';
// -----------------------------------material-ui--------------------------------------------
import { Draggable, Droppable } from 'react-drag-and-drop';

const Btn = ({label, id, color})=>(
	<Draggable type="config" data={id}>
		<div className='btn' style={{'--color':color}}>
			{label}
		</div>
	</Draggable>
)
const BtnSet = ({onDrop, position})=>(
	<Droppable
		types={['config']} // <= allowed drop types
		onDrop={evt=>onDrop({id:evt.config, type:'set', position})}
	>
		<div className='btn-set'>
			Solte aqui
		</div>
	</Droppable>
)
const BtnEmpty = ({onDrop, position})=>(
	<Droppable
		types={['config']} // <= allowed drop types
		onDrop={evt=>onDrop({id:evt.config, type:'unset', position})}
	>
		<div className='btn-empty'>
			
		</div>
	</Droppable>
)
export default function({data, onChange=()=>null}){

	let [positionData, setPositionData] = useReducer((state, value)=>{
		return state.map(e=>(
			e.id==parseInt(value.id)?
				{
					...e, 
					position:value.type=='set'?value.position:0
				}
			:e
		));

	}, data)

	const find = useCallback((i) => {
		return positionData.find(e=>e.position==i)||positionData[i-1];
	}, [])
	return(
		<ul>
			{[1,2,3,4,5].map(i=>(
				<li key={i} className='mb-1 d-flex align-items-center justify-content-between'>
					<div className='d-flex align-items-center'>
						<div className='indicator-position mr-2'>
							{i}
						</div>

						{find(i).position==i?(
							<Btn label={find(i).label} id={find(i).id} color={find(i).color}/>
						):(
							<BtnSet 
								onDrop={evt=>{
									setPositionData(evt);
									onChange(evt);
								}} 
								position={i}
							/>
						)}
					</div>
					{/* positionData[i-1].position?(
						<BtnEmpty 
							onDrop={evt=>{
								setPositionData(evt);
								onChange(evt);
							}} 
							position={i}
						/>
					):(
						<Btn label={positionData[i-1].label} id={positionData[i-1].id} color={positionData[i-1].color}/>
					) */}
				</li>
			))}
		</ul>
	)
}