import React, { Component } from 'react';
import FaClose from 'react-icons/lib/fa/close';
import { Card, Paper } from '@material-ui/core';

import Dialog from '../../common/Dialog';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import PageLayout from '../../common/PageLayout';

export default class MailForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;

    let fields = [
      {
        col: 4,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
      },
      {
        col: 10,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'textEditor',
        required: true,
        multiLine: true,
      },
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog
        maxWidth="xl"
        monitor_scroll
        title={values ? language.EDIT : language.ADD}
        modal={false}
        miniVersion = {true}
        open={true}
        onClose={() => handlePage('list')}
      >
        <CommonForm
          values={values}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
        />
      </Dialog>
    );
  }
}
