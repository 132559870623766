import React, {useEffect, useState} from "react";

var Highcharts = require('highcharts');
  
var HighchartsMore = require('highcharts/highcharts-more');
var bullet = require("highcharts/modules/bullet.js");
var solidGauge = require("highcharts/modules/solid-gauge.js");
var variablePie = require("highcharts/modules/variable-pie.js");
// Alternatively, this is how to load Highstock. Highmaps is similar.
// import Highcharts from 'highcharts/highstock';
 
// Load the exporting module.
var Exporting = require('highcharts/modules/exporting');
// Initialize exporting module.
Exporting(Highcharts);
bullet(Highcharts);
HighchartsMore(Highcharts);
solidGauge(Highcharts);
variablePie(Highcharts);

if(!global.Highcharts){
  global.Highcharts = Highcharts;
}

export var Highcharts = global.Highcharts;

export default function({config}){
    var chart;

    useEffect(()=>{

        if(chart){
          if(window.Highcharts){
            chart = window.Highcharts.chart(chart, config);
          }
        }

        return function(){
          if(chart){
            chart.destroy();
          }
        }
    },[config])

    return <div ref={ref => chart=ref}/>
    
}