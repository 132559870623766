import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Card, Grid } from '@material-ui/core';

import Capacity from './Capacity';
import Unavailability from './Unavailability';
import { stringToUTF32 } from '../assessment/rdp/Module';

export default function ({
  handleYear,
  selectedYear,
  language,
  id_device,
  action,
  APIEndpoints,
  dat,
  ioServers,
}) {
  const [capacityData, setCapacityData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [MonitoresAva, setMonitoresAva] = useState([]);

  const [data, setData] = useState([]);

  let categories_ = Array.from(new Array(12), (_, i) =>
    moment().subtract(3, 'months').add(i, 'M').format('YYMM')
  );

  let colors = [
    '#019FC2',
    '#02CBF0',
    '#02F0DE',
    '#02F079',
    '#A7F002',
    '#E7F002',
    '#F0B002',
    '#F05E02',
    '#EE0D81',
    '#019FC2',
    '#E7F002',
    '#02CBF0',
    '#02F0DE',
    '#02F079',
    '#A7F002',
  ];

  const getvariation = (itemmm, tef, type) => {
    try {
      let i = tef.substr(2, 2) + tef.substr(0, 2);

      if (type == 'cpu-stats' || type == 'mem-info') {
        return itemmm.filter((x) => x.mes_ano == i && x.type == type)[0].media;
      } else {
        return itemmm.filter((x) => x.mes_ano == i && x.unidade == type)[0]
          .media;
      }
    } catch (e) {
      return 0;
    }
  };
  useEffect(() => {
    setCategories(
      Array.from(new Array(12), (_, i) =>
        moment().subtract(3, 'months').add(i, 'M').format('MM/YY')
      )
    );

    action
      .execute(
        'post',
        APIEndpoints.MONITOR + '/findDeviceAvailavilityall',
        '',
        { id: id_device }
      )
      .then((c) => {
        setMonitoresAva(c.data);
      });

    action
      .execute('post', APIEndpoints.MONITOR + '/historicId', '', {
        fk_id_device: id_device,
      })
      .then((c) => {
        let itemmm = c.data;

        let dadosCasa = itemmm.reduce(function (acc, item) {
          if (item.type == 'fs-list') {
            item.type = item.unidade;
          }

          if (!acc[item.type]) {
            acc[item.type] = parseInt(item.media);
          } else {
            acc[item.type] = parseInt(item.media);
          }

          return acc;
        }, []);

        let data = [];
        Object.keys(dadosCasa).map((v, i) => {
          let primary = 0;
          let valuescapacity = [];
          let variation = 0;
          let is = 0;
          categories_.map((tef, index) => {
            let va = getvariation(itemmm, tef, v);
            if (tef <= moment().format('YYMM')) {
              valuescapacity.push(parseInt(va));
            } else {
              if (is == 0) {
                variation =
                  valuescapacity[valuescapacity.length - 1] -
                  valuescapacity[valuescapacity.length - 2];
                is = 1;
                let init = [];
                let count = 0;
                while (valuescapacity.length >= count) {
                  let val1 = valuescapacity[count];
                  count++;
                  let val2 = valuescapacity[count];

                  let vs = val2 - val1;
                  if (!isNaN(vs)) init.push(vs);
                }

                variation = init.reduce((a, b) => a + b, 0) / init.length;
                if (variation < 0) {
                  variation = 0;
                }
              }

              valuescapacity.push(
                valuescapacity[valuescapacity.length - 1] + parseInt(variation)
              );
            }
          });

          data.push({
            name: v,
            zIndex: 2,
            color: colors[i],
            data: valuescapacity,
          });
        });

        setCapacityData(data);
      });
  }, [id_device]);

  let chaves = MonitoresAva.filter(
    (m) => moment(m).format('YYYY') == selectedYear
  ).reduce(
    function (acc, curr) {
      acc[parseInt(moment(curr.dt_coleta).format('M') - 1)] = parseInt(
        curr.percent
      );
      return acc;
    },
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  );
  let UnavailabilityData = {
    categories: dat.map((e) => e + '/' + selectedYear),
    data: chaves,
    z1: 8,
    z2: 12,
  };

  return (
    <Grid container spacing={2}>
      {
        <Grid item xs={6}>
          <Unavailability
            handleYear={handleYear}
            selectedYear={selectedYear}
            ioServers={ioServers}
            id_device={id_device}
            MonitoresAva={MonitoresAva.filter(
              (m) => moment(m).format('YYYY') == selectedYear
            )}
            UnavailabilityData={UnavailabilityData}
            language={language}
          />
        </Grid>
      }
      <Grid item xs={6}>
        <Capacity
          handleYear={handleYear}
          selectedYear={selectedYear}
          CapacityData={capacityData}
          categories={categories}
          language={language}
        />
      </Grid>
    </Grid>
  );
}
