


import React, { useState, useEffect } from 'react'
import CommonTable from '@common/CommonTable';
import CommonForm from '@common/CommonForm2';
import './Avaliation.css'
import Button from '@common/Button';

const form=(props)=>{
    let {ticket,saveAvaliation} =  props;
    let [avaliation_level,Setavaliation_level]=useState(null)
    let [experience,Setexperience]=useState(null)
    let [resolved,Setresolved]=useState(null)

    const saveAvaliation_=(v,ticket)=>{

      if(avaliation_level == null ||experience ==null || resolved ==null ){
        props.store.toggleAlert(
           true,
           language.REQUIRED_FIELDS,
           'error'
         );
      }else{


      let json={
         id_tech:ticket.fk_id_user,
         id_solicitant : ticket.fk_id_user ,
         avaliation_level:avaliation_level,
         resolved: resolved,
         fk_id_ticket : ticket.id_tickets ,
         experience : experience,
         suggestions : v.suggestions
      }
      saveAvaliation(json)
   }

  // app.post("/avaliation", (req, res, next) =>

      }


    return(
       <div  style={{minHeight:'100px',width:'100%'}} >
             <CommonForm
               individual
               hiddenSubmit

               onSubmit={e => {
                  saveAvaliation_(e,ticket)
               }}
               fields={[
                  {
                     col: 1,
                     type: 'element',
                     content: (v, h, submit) => (
                        <Button
                           icon="fas fa-file-contract"

                           color="primary"
                           label={ticket.real_id }

                           style={{ marginTop: 15 }}
                        />
                     )
                  },
                  {
                     col: 2,
                     type: 'element',
                     content: (v, h, submit) => (

                        <span style={{fontSize: '11px',color:'#616161' }}>  <br></br>{ticket.subject}</span>
                     )
                  },

                  {
                     col: 2,
                     type: 'element',
                     name: 'avaliation_level',

                     content: (v, h, submit) => (
                         <span style={{fontSize: '11px',color:' var(--primary)' }}>
                          avaliação a respeito da qualidade ?

                        <div className='d-flex align-items-center content-avaliation-number'>
                        {[0,1,2,3,4,5,6,7,8,9,10].map((e,i)=>(
                          <div style={{marginTop:avaliation_level==e?-15:0}} onClick={()=> {Setavaliation_level(e)}}>
                            <div key={i}>{e}</div>
                          </div>
                        ))}
                      </div></span>
                    )
                  },
                  {
                     col: 2,
                     type: 'element',
                     name: 'experience',

                     content: (v, h, submit) => (
                        <span style={{fontSize: '11px',color:' var(--primary)' }}>
                               avaliação a respeito do tempo ?

                        <div className='d-flex align-items-center content-avaliation-number'>
                 {[0,1,2,3,4,5,6,7,8,9,10].map((e,i)=>(
                   <div style={{marginTop:experience==e?-15:0}} onClick={()=> {Setexperience(e)}}>
                     <div key={i}>{e}</div>
                   </div>
                 ))}
               </div></span>
                    )
                  },
                  {
                     col:2,
                     type: 'element',
                     name: 'resolved',
                     content: (v, h, submit) => (
                        <span style={{fontSize: '11px',color:' var(--primary)' }}>
              experiência  do atendimento?
                        <div className='d-flex align-items-center content-avaliation-number'>
                        {[0,1,2,3,4,5,6,7,8,9,10].map((e,i)=>(
                       <div style={{marginTop:resolved==e?-15:0}} onClick={()=> {Setresolved(e)}}>
                            <div key={i}>{e}</div>
                          </div>
                        ))}
                      </div>
                      </span>
                    )
                  },
                  {
                     col:2,
                     type: 'textarea',
                     name: 'suggestions',

                     label: 'Sugestão'


                  },

                  {
                     col: 0.5,
                     type: 'element',
                     content: (v, h, submit) => (
                        <Button
                            color="primary"
                           label="Salvar"
                           onClick={() => submit()}
                           style={{ marginTop: 15 }}
                        />
                     )
                  }


               ]}
            />

       </div>

 )
 }

 export default form
