import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { spFetch, useDebounce, useServicePortalStyles } from '../helpers';
import Constants from '../../../data/Constants';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';

export default function ServicePortalModalObservant({ isOpen, language, onClose, onConfirm, ticketData }) {

    const styles = useServicePortalStyles();
    const [options, setOptions] = useState([]);
    const [values, setValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const queryText = useDebounce(searchText, 300)

    useEffect(() => {
        getData(queryText)
    }, [queryText]);

    useEffect(() => {
        if (isOpen === false && values.length > 0)
            setValues([]);
    }, [isOpen]);

    const getData = (searchTextTerm) => {
        if (!searchTextTerm) {
            setOptions([]);
            return;
        }

        const payload = {
            all_fk_id_company: ticketData.fk_id_company,
            search: searchTextTerm
        }
        setLoading(true);
        spFetch(`${Constants.APIEndpoints.USER}/filter/all`, 'POST', payload)
            .then(({ data }) => {
                setOptions(Array.isArray(data) ? data : []);
            })
            .finally(() => setLoading(false));
    }

    const handleConfirm = () => {
        onConfirm && onConfirm(values);
    }

    return (
        <ServicePortalModalWrapper
            isOpen={isOpen}
            title={language.OBSERVER}
            onClose={onClose}
            onConfirm={handleConfirm}
            buttonCloseLabel={language.CLOSE}
            buttonConfirmLabel={language.ADD}
        >
            <div className='tw-w-full'>
                <Autocomplete
                    id="combo-box"
                    value={values}
                    multiple={true}
                    fullWidth={true}
                    loading={loading}
                    options={options}
                    getOptionLabel={(option) => option.name}
                    onInputChange={(event, newValue) => {
                        setSearchText(newValue);
                    }}
                    classes={{ inputRoot: styles.autoCompleteInput }}

                    renderInput={
                        (params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={language.PEOPLE}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}

                    onChange={(event, newValue) => { setValues(newValue) }}
                />
            </div>
        </ServicePortalModalWrapper>
    )
}
