"use strict";

import crypto from 'crypto';
var aSecret = "Orch3str0!@forever!";

const decrypt = (aMsg) => {
  aMsg = aMsg.replace(/\s/g, "");
  let decipher, tRet;
  decipher = crypto.createDecipher("aes-256-cbc", aSecret);
  tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
  tRet += decipher.final("utf8");
  return tRet;
}

if (process.env.NODE_ENV == 'development')
  window.__decrypt = decrypt

export default decrypt;
