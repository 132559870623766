import React from 'react';
import {Card} from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from './TabelaRisco.graph.js';
import moment from 'moment';

export default function({
	language,
	matriz ,
	users,
	companies,
	matrizplanning 
}){
	let array1=[];
	let array2=[];
	let array3=[];
	 
	[1,2,3,4,5,6,7,8,9,10,11,12].map(b=>{
		let v1=0;
		let v2=0;
		let v3=0;
		 matriz.filter(c=>parseInt(moment(c.dt_lanc).format('MM')) == b).map(c=>{

			let p = c.probability + c.impact >=5 && 
			c.probability + c.impact <=6 ? 1
			:c.probability + c.impact >=7&& 
			c.probability + c.impact <=8 ? 2 :0

          if(p==0){
			 v1++
		   }else if(p==1){
			v2++
		}else if(p==1){ 
			v3++  
			 
		  }
			
		})
		  array1.push(v1)
		  array2.push(v2)
		  array3.push(v3)
	})


	return(
		<Card className='content pt-1 mt-2 border-radius-10'>
			<h3
				style={{
					color: 'var(--primary)',
					fontSize: 15,
					fontWeight: 'bold'
				}}
			>
				{language.RISK_MONITORING}
			</h3>
			<ReactHighcharts config={ContainerChart.container3({ array1,array2,array3, language})}/>
		</Card>
	)
}