import React, { useState, memo } from 'react';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';

// -----------------------------------common-----------------------------------------------
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
// -----------------------------------material-ui------------------------------------------
import { Avatar, Checkbox, Grid } from '@material-ui/core';
// -----------------------------------components------------------------------------------

export default memo(
  ({
    open,
    onClose,
    options,
    project = {},
    action,
    APIEndpoints,
    id_project_activity,
    percent,
    onUpdate,
  }) => {
    let [OnlyOneCommit, setOnlyOneCommit] = useState(false);
    const { language } = Store;
    function onSubmit(values) {
      setOnlyOneCommit(true);

      action
        .execute(
          'post',
          APIEndpoints.PROJECT + '/activity/status',
          'project',
          values,
          null,
          { btn_loading: 'evolution' }
        )
        .then((e) => {
          window.$eventBus.emit('evtUpdateStatus', {
            fk_id_project_activity: values.id_project_activity,
            pj: e.data,
          });
          onClose();
          onUpdate();
        })
        .catch((err) => {
          if (err.data.code == 'E019') {
            Store.toggleAlert(true, language.ERROR_CODE.E019, 'error');
          }
        });
    }

    return (
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
        miniVersion = {true}
        title={language.REGISTER_EVOLUTION}
        PaperProps={{
          id: 'EvolutionProject',
        }}
        contentProps={{
          style: {
            background: '#F5F5F5',
          },
        }}
      >
        <div>
          <CommonForm
            fixedValue={{
              id_project: project.id_project,
              id_project_activity: id_project_activity,
            }}
            fields={[
              {
                name: 'status',
                label: language.STATUS,
                type: 'select',
                className: 'detail_activity',
                options: Array.from(Array(20), (_, i) => (i + 1) * 5)
                  .filter((p) => p > percent)
                  .map((y) => ({ value: y, label: y + '%' })),

                required: true,
              },
              {
                name: 'timeextend',
                label: language.TIME_SPENT,
                type: 'text',
                required: true,
                format: '##:##',
                change(v) {
                  return v.formattedValue;
                },
              },
              {
                name: 'local',
                label: language.TYPE_OF_ACTIVITY,
                type: 'select',
                className: 'detail_activity',
                options: options.local(),
                required: false,
              },
              {
                name: 'descriptionhistory',
                label: language.DESCRIPTION,
                type: 'simplesTextEditor',
                className: 'detail_activity',
                required: true,
              },
            ]}
            loading="evolution"
            onSubmit={onSubmit}
            hiddenSubmit={OnlyOneCommit}
          />
        </div>
      </Dialog>
    );
  }
);
