import React from 'react';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';
import CommonPage from '../../common/CommonPage';

let array_value = {};
let leader;
let _group_tech = 0;

@observer
export default class GroupForm extends CommonPage {
  constructor() {
    super();
    this.state = { type: '', type_tech: '', works: [], distribute_ticket: 0 };
    this.onChangeField = this.onChangeField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.action.execute(
      'get',
      `${this.APIEndpoints.SLA_WORK}/workday`,
      ''
    )
      .then((res) => {
        this.setState({ works: res.data })
      })
      .catch((err) => console.error('GroupForm: ', err));

    if (this.props.values != undefined) {
      this.props.action.execute(
        'get',
        `${this.props.APIEndpoints.GROUP}/user/leader/1?query=${this.props.values.id_group_users}`,
        'tasks'
      );
    } else {
      this.props.action.execute(
        'get',
        `${this.props.APIEndpoints.USER}/tech`,
        'techs'
      );
    }
  }

  onChangeField(field, value) {
    if (field.name === 'type') {
      this.setState({
        type: value,
      });
    } else if (field.name === 'type_tech') {
      this.setState({
        type_tech: value,
      });
    } else if (field.name == 'distribute_ticket') {
      this.setState({
        distribute_ticket: value,
      });
    }
  }

  handleSubmit(formData) {

    if (this.state.type == 4 || this.props.values?.type == 4) {
      if (!formData.is_global) {
        //formData.is_global = false; //replace undefined
        if (!formData.fk_id_company) {
          console.log('this.store', this.store)
          this.store.toggleAlert(true, this.props.language.GROUP_REQUIRED_FIELD_CONDITIONAL, 'error');

          return;
        }
      }
    }

    this.props.onFormSubmit(formData);

  }

  render() {
    let {
      language,
      handlePage,
      values,
      title,
      companies,
      redirect,
      integration,
    } = this.props;
    let { tasks } = this.store;

    let type = !!values ? values.type : this.state.type;
    let type_tech = !!values ? values.type_tech : this.state.type_tech;

    let typeOptions = [
      { value: 1, label: language.TECH_GROUP },
      { value: 2, label: language.REQUESTER_GROUP },
      { value: 3, label: language.APPROVERS_GROUP },
      { value: 4, label: language.CRISIS_GROUP },
    ];

    let hourOptions = [
      { value: -1, label: language.NEVER },
      { value: 1, label: language.AN_HOUR },
      { value: 2, label: language.TWO_HOURS },
      { value: 3, label: language.THREE_HOURS },
    ];

    let typeTechOptions = [
      { value: 1, label: language.INTERNAL },
      { value: 2, label: language.EXTERNAL },
    ];

    let companyTechOptions = companies.map((c) => ({
      value: c.id_company,
      label: c.name,
    }));

    let companySolicitantOptions = companies
      .filter((c) => c.type === 1)
      .map((c) => ({ value: c.id_company, label: c.name }));

    let fields = [
      {
        col: 6,
        name: 'type',
        label: language.PROFILE,
        type: 'select',
        required: true,
        options: typeOptions,
        disabled: !!values,
      },
    ];

    if (type === 1) {
      fields = fields.concat([
        {
          col: 6,
          name: 'type_tech',
          label: language.TECH_TYPE,
          type: 'select',
          required: true,
          options: typeTechOptions,
        },

        {
          col: 6,
          name: 'att_automatic',
          label: language.AUTOMATIC_ASSIGN_TICKET_AFTER + ':',
          type: 'select',
          options: hourOptions,
          required: true,
        },
      ]);

      if (type_tech === 2) {
        fields.splice(2, 0, {
          col: 6,
          type: 'autocomplete',
          name: 'fk_id_company',
          label: language.COMPANY,
          method: 'POST',
          route: `${this.props.APIEndpoints.COMPANY}/filter`,
          fieldquery: 'search',
          textlabel: language.COMPANY,
          textinit: (values && values.name_company) || language.COMPANY,
          fieldvaluedb: 'id_company',
          fieldlabeldb: 'name',
          idinit: (values && values.fk_id_company) || 0,
          routeAll: `${this.props.APIEndpoints.COMPANY}/all?`,
          methodGetAll: 'POST',
          routeGetCount: `${this.props.APIEndpoints.COMPANY}/count`,
          required: true,
        });
      }

      if (this.props.values != undefined) {
        array_value = {
          type: values.type,
          id_group_users: values.id_group_users,
          type_tech: values.type_tech,
          name: values.name,
          att_automatic: values.att_automatic,
          leader_group_tech: values.leader_group_tech,
          description: values.description,
          user_integration: values.user_integration,
          sub_tickets: values.sub_tickets,
          sub_tickets_out: values.sub_tickets_out,
          distribute_ticket: values.distribute_ticket,
          distribute_ticket_type: values.distribute_ticket_type,
          email: values.email,
          approve_knowledge: values.approve_knowledge
        };
        if (type_tech === 2) {
          array_value = {
            type: values.type,
            id_group_users: values.id_group_users,
            type_tech: values.type_tech,
            name: values.name,
            att_automatic: values.att_automatic,
            leader_group_tech: values.leader_group_tech,
            description: values.description,
            fk_id_company: values.fk_id_company,
            user_integration: values.user_integration,
            sub_tickets: values.sub_tickets,
            sub_tickets_out: values.sub_tickets_out,
            distribute_ticket: values.distribute_ticket,
            distribute_ticket_type: values.distribute_ticket_type,
            email: values.email,
            approve_knowledge: values.approve_knowledge
          };
        }
      }
    } else if (type === 2) {
      title = language.NEW_GROUP_OF + ' ' + language.REQUESTER;
    } else if (type === 3) {
      title = language.NEW_GROUP_OF + ' ' + language.APPROVERS;
    }

    fields = fields.concat([
      { col: 6, name: 'name', label: 'Nome', type: 'text', required: true },
      {
        col: 6,
        type: 'autocomplete',
        name: 'fk_id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${this.props.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit: (values && values.name_company) || language.COMPANY,
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: (values && values.fk_id_company) || 0,
        routeAll: `${this.props.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${this.props.APIEndpoints.COMPANY}/count`,
        required: (type !== 4),
      },
      {
        col: 6,
        name: 'description',
        label: language.GROUP_DESCRIPTION2,
        type: 'text',
        multiLine: true,
        required: false,
      },
    ]);

    if (type === 3) {
      fields = fields.concat([
        {
          col: 6,
          name: 'all_approvers',
          label: language.REQUIRES_APPROVAL_EVERYONE,
          type: 'switch',
          required: false,
        },
        {
          col: 6,
          name: 'approve_knowledge',
          label: language.APPROVE_KNOWLEDGE,
          type: 'switch',
          required: false,
        },
      ]);
    }

    if (type === 4) {
      fields.push({
        col: 6,
        name: 'documented_crisis',
        label: language.ALLOW_CREATING_DOCUMENTED_CRISIS_TICKET,
        type: 'switch',
        required: false,
      })
    }

    if (type !== 3 && type !== 4) {
      fields.push({
        span: 4,
        type: 'autocomplete',
        name: 'leader_group_tech',
        label: language.TECH,
        className: 'tech-group-ticket',
        required: true,
        method: 'POST',
        route: `${this.props.APIEndpoints.USER}/filter`,
        routeGetCount: `${this.props.APIEndpoints.USER}/active/count/all`,
        filterAll: { role: 'tech' },
        fieldquery: 'search',
        textlabel: language.GROUP_LEADER,
        textinit: (values && values.leader_tech_txt) || '',
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: (values && values.leader_group_tech) || 0,
        routeAll: `${this.props.APIEndpoints.USER}/filter/all?`,
        detail: true,
        methodGetAll: 'POST',
      });
    }

    let button = {
      label: language.SAVE,
    };

    if (integration != undefined) {
      if (integration.integration == true) {
        fields.push({
          col: 6,
          name: 'user_integration',
          label: 'Integration',
          type: 'text',
        });
      }
    }

    if (integration.sla_params == true && type !== 4) {
      fields.push({
        col: 6,
        name: 'fk_id_workday',
        label: language.CALENDAR,
        type: 'autocomplete',
        disablePortal: true,
        textlabel: `${language.CALENDAR} *`,
        method: 'POST',
        route: `${this.props.APIEndpoints.SLA_WORK}/workday/list`,
        fieldquery: 'search',
        textinit: (values && values.wk_name) || '',
        fieldvaluedb: 'id_workday',
        fieldlabeldb: 'name',
        idinit: (values && values.id_workday) || 0,
        routeAll: `${this.props.APIEndpoints.SLA_WORK}/workday/list?`,
        methodGetAll: 'POST',
        detail: true,
        visible: true
      });
    }

    if (type !== 4) {
      fields.push({
        col: 6,
        name: 'sub_tickets',
        label: language.ALLOW_CREATE_SUB_CALLS,
        type: 'switch',
        required: false,
      })
      fields.push({
        col: 6,
        name: 'sub_tickets_out',
        label: language.ACCEPT_SUB_CALLS_OTHER_GROUPS,
        type: 'switch',
        required: false,
      })
    }

    if (this.state.type == 1 || values && values.type == 1) {
      fields.push({
        col: 6,
        name: 'approve_knowledge',
        label: language.APPROVE_KNOWLEDGE,
        type: 'switch',
        required: false,
      })

      fields.push({
        col: 6,
        name: 'distribute_ticket',
        label: language.DISTRIBUTE_TICKETS_AUTOMATICALLY,
        type: 'switch',
        required: false,

      })

      fields.push({
        col: 6,
        name: 'distribute_ticket_type',
        label: language.ASSIGN_CALLS_ONLY_TECH_IS_FREE,
        type: 'switch',
        required: false,
        visible: this.state.distribute_ticket > 0 ? true :


          (array_value.distribute_ticket > 0 ? true : false)
      })
    }

    if (type !== 4) {
      fields.push({
        col: 6,
        name: 'email',
        label: language.EMAIL,
        type: 'text',
      });
    }

    if (type === 4) {
      fields.push({
        col: 6,
        type: 'switch',
        name: 'is_global',
        label: language.GLOBAL,
      });
    }

    return (
      <CommonForm
        key={String(type)}
        fields={fields}
        onChangeField={this.onChangeField}
        onSubmit={this.handleSubmit}
        button={button}
        loading="post-put-group"
        values={
          this.props.values != undefined
            ? type == 1
              ? array_value
              : this.props.values
            : { type: type, type_tech: type_tech }
        }
      />
    );
  }
}
