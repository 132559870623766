import React, { Component } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';

export default class DeviceICForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;

    let fields = [
      {
        col: 12,
        name: 'name',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
      },
      {
        col: 12,
        name: 'validate',
        label: language.VALIDATE_RELATIONSHIP_BETWEEN_CI,
        type: 'check',
      },
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog
        modal={false}
        open={true}
        onClose={() => handlePage('list')}
        maxWidth="xs"
        title={values ? language.EDIT : language.ADD}
      >
        <CommonForm
          values={values}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
        />
      </Dialog>
    );
  }
}
