import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import CommonDelete from '../../common/CommonDelete';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import { Container, Row, Col } from 'react-grid-system';
import ReactTolltip from 'react-tooltip';
import GroupModal from './GroupModal';

export default class DeviceGroupDetail extends Component {
  constructor() {
    super();
    this.state = {
      del: false,
      up: false,
      group: ''
    };
    this.onClose = this.onClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.upGroup = this.upGroup.bind(this);
  }

  onClose() {
    this.setState({
      del: false,
      up: false,
      group: ''
    });
  }

  onDelete() {
    let { group } = this.state;
    this.props.upGroup(group, 'del');
    this.onClose();
  }

  onUpdate(values) {
    let { group } = this.state;
    this.props.upGroup(group, 'up', values);
    this.onClose();
  }

  upGroup(group, action) {
    let obj = { group: group };
    if (action === 'del') obj.del = true;
    if (action === 'up') obj.up = true;
    this.setState(obj);
  }

  render() {
    let {
      danger,
      language,
      handlePage,
      companies,
      company,
      warning
    } = this.props;
    let { del, group, up } = this.state;

    return (
      <PageLayout
        icon={<div className="monitorIcon titleIconSize" />}
        title={language.MONITOR}
        subtitle={language.DEVICES_GROUP_DETAIL}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            onClick={() => handlePage('dash')}
            label={language.BACK}
            color='primaryGradient'
          />
        ]}
      >
        <Card className="cardDetail">
          <h2>{company.company}</h2>
        </Card>
        <Row>
          {company.groups.map((group, i) => (
            <GroupDevices
              key={i}
              group={group}
              toMonitor={handlePage}
              language={language}
              upGroup={this.upGroup}
              danger={danger}
              warning={warning}
            />
          ))}
          <ReactTolltip />
        </Row>
        {del ? (
          <CommonDelete
            language={language}
            closeModal={this.onClose}
            onDelete={this.onDelete}
          />
        ) : up ? (
          <GroupModal
            closeModal={this.onClose}
            companies={companies}
            company={company}
            group={group}
            open={up}
            language={language}
            onFormSubmit={this.onUpdate}
          />
        ) : null}
      </PageLayout>
    );
  }
}

class GroupDevices extends Component {
  render() {
    let { danger, group, language, toMonitor, upGroup, warning } = this.props;
    let { devices, id_group, name_group } = group;
    let defaultStyle = { border: 'solid 1px #ddd' };

    let dangerStyle = { boxShadow: '0px 1px 21px 0px rgba(255,0,0,1)' };
    let warningStyle = { boxShadow: '0px 1px 21px 0px rgba(255,255,0,1)' };

    return (
      <Col md={6}>
        <Card className="cardDefaultGroupDetail">
          <div className="maxHeightGroupDevice">
            <Col md={12}>
              <div className="itemMonitoring" style={defaultStyle}>
                <Container fluid>
                  <Row>
                    <Col md={10}>
                      <h2
                        className="pointer"
                        onClick={() => toMonitor('monitor', devices)}
                      >
                        {name_group}
                      </h2>
                    </Col>
                    <Col md={2}>
                      <div className="actionIcons">
                        <div
                          data-tip={language.EDIT}
                          onClick={() => upGroup(id_group, 'up')}
                          className="editIcon cardIcon pointer"
                        />
                        <div
                          data-tip={language.REMOVE}
                          onClick={() => upGroup(id_group, 'del')}
                          className="trashIcon cardIcon pointer"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="monitorDivider" />
                <div className="bgStriped deviceList">
                  {devices.map(device => (
                    <div key={device.id_device} className="rowItemDevices">
                      <div
                        className={
                          device.type === 'link'
                            ? 'linkIcon cardIcon'
                            : 'serverIcon cardIcon'
                        }
                      />
                      <h3
                        style={
                          danger.includes(device.id_device)
                            ? dangerStyle
                            : warning.includes(device.id_device)
                              ? warningStyle
                              : defaultStyle
                        }
                      >
                        {device.name}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </div>
        </Card>
      </Col>
    );
  }
}
