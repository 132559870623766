export default {
   type:'grey',
   COMMON_PAGE:{
      bar:'var(--default)',
      slideBar:{
         drawn:'var(--default)',
         iconButton:'#ced6db',
         iconButtonHover:'#b1bac3',
         colorIconButton:'var(--default)'
      },
      text:'var(--default)'
   },
   CIO_COCKPIT:{
      demand_management:{
         card_5: 'var(--default)',
         times_medium:{
            color_1:'#96999c'
         },
         tickets:{
            resolved:'var(--default)'
         }
      },
      average_availability:'#6c757d'
   },
   PANEL:{
      total_card_1:'var(--default)',
      indicator_1:'var(--default)',
      b2:'var(--default)'
   },
   ASSESSMENT:{
      form:'var(--default)'
   },
   BUDGETCONTROL:{
      total_acumulated:'var(--default)',
      acumulated:'var(--default)'
   },
   GSD:{
      columns:'var(--default)'
   },
   DASHBOARD:{
      greenTheme:'var(--default)'
   },
   INVENTORY:{
      total_acompany:'var(--default)' 
   },
   RISKMANAGEMENT:{
      config:{
         bar:'var(--default)'
      }
   },
   PMO:{
      cardGreen:'var(--default)',
      alocation:{
         cardGreen:'var(--default)',
         graph:'var(--default)'
      }
   },
   PROJECT:{
      column:'var(--default)',
      barRight:{
         background:'var(--default)',
         button:'warning'
      }
   },
   PROJECTS:{
      done:'var(--default)'
   },
   SPRINT:{
      card:'var(--default)',
      KANBAN:{
         column:'var(--default)'
      }
   },
   CALENDAR:{
      form:'var(--default)',
      card:'var(--default)'
   },
   TICKETS:{
      controlButton:{
         active:'var(--default)',
         normal:'#99a3ac'
      }
   }
}