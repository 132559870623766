import React, { useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';
import { flexBasis } from '@common/Func';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import './styles.css';
import CalendarTask from './Calendar';

export function DayTask({ eventTask, reload, type, dateFilter  }) {
  let events = [];
  useEffect(() => {
    document.querySelector('.fc-button').innerHTML =
      '<select><option>Selecione</option></select>';
  }, []);

  const handleDateClick = () => {};
  return (
    <Card className="content-min px-3">
      <Grid container spacing={3} xs={12}>
        <CalendarTask type={type} eventTask={eventTask} reload={reload}/>
      </Grid>
    </Card>
  );
}
