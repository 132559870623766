import React from 'react';
import Loading from '@common/Loading';
import './index.css';

const Loadings = ({ value }) => (
  <Loading msg={false} loading={false} size={15} color="white" className="py-0">
    {value}
  </Loading>
);
export default function ({ label, percent, value, hasIcon, desc, language }) {
  return (
    <div className="CardIndicator CardIndicatorB3B6 content-min">
      <div className="d-flex align-items-center justify-content-between">
        <span>{label}</span>
        {![false, undefined].includes(percent) ? <span>{percent}%</span> : null}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-end">
          <span>
            <Loadings value={value} />
          </span>
          {![false, undefined].includes(percent) ? (
            <span className="ml-2">{desc || language.TICKETS}</span>
          ) : null}
        </h2>
        {hasIcon && <i className="fas fa-users" />}
      </div>
    </div>
  );
}
