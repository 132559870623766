import React, {Fragment} from 'react';
import classnames from 'classnames';
import './index.css';
export default ({ items, labelProps = {}, valueProps = {} }) => (

  <div className="grid-container">
    {items.map((e, i) => (
      <Fragment>
        <div
          {...labelProps}
          {...e.labelProps}
          className={classnames(
            'grid-columns label',
            labelProps.className,
            (e.labelProps || {}).className
          )}
        >
          {e.label}
        </div>
        <div
          {...valueProps}
          {...e.valueProps}
          className={classnames(
            'grid-columns value',
            valueProps.className,
            (e.valueProps || {}).className
          )}
        >
          {e.value}
        </div>
      </Fragment>
    ))}
  </div>
	
)