import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

// ----------------------------------------------------------------------

import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import Store from '@data/Store';
import { getSquadsService, getSquadByIdService } from '../squad.services';
import { columns } from './squad.columns';

// ----------------------------------------------------------------------

export const SquadList = ({ page, setSquadSelected, setEditSquad, setPage }) => {
  const [squads, setSquads] = useState([]);
  const { language } = Store;

  const handleAddSquad = () => {
    setPage('add');
  };

  const handleEditSquad = ({ id }) => {
    const getSquad = async () => {
      getSquadByIdService({ id }).then((result) => {
        setEditSquad(result);
        setPage('edit');
      });
    };
    getSquad();
  };

  const handleDelete = ({ id_business_line, label }) => {
    setPage('delete');
    setSquadSelected({ id_business_line, label });
  };

  useEffect(() => {
    const getSquads = async () => {
      const result = await getSquadsService();
      const normalizedResult = result.map((squad) => ({
        ...squad,
        action: (
          <Chip
            className="mx-2 muit-chip"
            label={
              <>
                <Button
                  variant="circle"
                  icon="fas fa-edit"
                  label={language.EDIT}
                  color="default"
                  style={{ fontSize: 12 }}
                  size={0.7}
                  className="mr-2"
                  onClick={() => handleEditSquad({ id: squad.id_business_line })}
                />
                <Button
                  variant="circle"
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13 }}
                  size={0.7}
                  onClick={() =>
                    handleDelete({
                      id_business_line: squad.id_business_line,
                      label: squad.label,
                    })
                  }
                />
              </>
            }
          />
        ),
      }));

      setSquads(normalizedResult);
    };

    page === null && getSquads();
  }, [page]);

  return (
    <CommonTable
      paginationTop={false}
      beforeBar={
        <RaisedButton
          circleButton
          icon="fas fa-plus"
          style={{ zoom: 0.8 }}
          zoom={0.8}
          color="primary"
          className="mr-3"
          label={`${language.ADD} ${language.COIN}`}
          onClick={handleAddSquad}
        />
      }
      col={columns}
      data={squads}
      index="squad-list"
      rowsPerPageOptions={[100, 250]}
      searchColumn
      orderColumn
    />
  );
};
