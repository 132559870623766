import classnames from 'classnames';
import './style.css';
export default function BarSquare(props){
	let {title, icon, src, contentProps={}, squareProps={}, titleProps={}, iconProps={}} = props;
	return(
		<div {...contentProps} className={classnames('BarSquare', contentProps.className)}>
		  <div {...squareProps} className={classnames('square', squareProps.className)}>
		  	{(typeof icon)=='string'?(
		    	<i {...iconProps} className={classnames(icon, iconProps.className)}/>
		  	):src?(
		  		<img src={src} alt="Image Icon" {...iconProps}/>
		  	):icon}
		  </div>
		  <h4 {...titleProps} className={classnames('square-title', titleProps.className)}>
		  	{title}
		  </h4>
		</div>
	)
}