import React, { memo, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import classnames from 'classnames';
import JoditEditor from 'jodit-react';
import { Dialog } from '@material-ui/core';
import Actions from '@data/Actions';
import constants from'@data/Constants'

const action = new Actions();
let delayTimer;

export default memo(
  ({ f, statusError, _value, _handleValue, errors, onKeyDown ,handleEmail_Chat }) => {
    let [value, setValue] = useState('');
    let [options, setOptions] = useState([]);
    let [visibleEditor, setvisibleEditor] = useState(false);
    let [body, setBody] = useState([]);
    let [margin, setMargin] = useState('');
    let [name, setName] = useState('');

    const [config, setConfig] = useState({
      readonly: false,
      toolbar: true,
      langusage: 'pt',
    });

    const changeName = (v, e) => {
      handleEmail_Chat(e);
      setName(v);
      setvisibleEditor(false);

       value = value.replace(body, '');
      setValue(value.replace('@@', '<span class="color-icon_chat">' + v + '</span>'));
      setBody('');
    };

    useEffect(() => {
      if (typeof body == 'string'){
        action
          .execute('post', `${constants.APIEndpoints.USER}/filter/all?offset=${0}&limit=${10}`, '', { name: body, ...(f.filter || []) })
          .then((res) => {
            setOptions(res.data);
          });
      }
    }, [body]);
    function handleValue(val, name, selected) {
      setValue(val);
      if (val.indexOf('@@') > -1) {
        setvisibleEditor(true);
        setMargin((val.indexOf('@@') < 10 ? 5 : val.indexOf('@@')) * 10);
        try {
          let v = val.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
          setBody(v.split('@@')[1]);
        } catch (e) {}
      } else {
        setvisibleEditor(false);
      }

      clearTimeout(delayTimer);

      delayTimer = setTimeout(() => {

        _handleValue(val, f.name, selected);

      }, 400);
    }
    useEffect(() => {
      setValue(_value);
    }, [_value]);

    const Diag = (
      <div
        style={{
          width: '300px',
          minHeight: '100px',
          background: 'rgb(245 246 248)',
          cursor: 'pointer',
          zIndex: '1000',
          padding: '10px',
          marginLeft: margin + 'px',
          position: 'absolute',
          marginTop: '-30px',
        }}
      >
        {options.map((item) => (
          <div onClick={() => changeName(item.name, item.email)}>
            {item.name}
          </div>
        ))}
      </div>
    );

    return (
      <div>
        {!!f.label && (
          <span
            className={classnames('font label-text-editor', {
              error: statusError[errors[f.name]],
            })}
          >
            {f.label + (f.required ? ' *' : '')}
          </span>
        )}

        <ReactQuill
          value={value || '<span></span>'}
          className={classnames(
            'commonForm-react-quill',
            {
              error: statusError[errors[f.name]],
              'not-label': !f.label,
            },
            f.className
          )}
          style={{
            maxHeight: f.scroll ? '200px' : '100%',
            overflowY: f.scroll ? 'scroll' : 'auto',
          }}
          onChange={(content, delta, source, editor) =>
            handleValue(content, f.name)
          }
          onKeyDown={(evt) => onKeyDown(evt, value)}
          readOnly={f.disabled}
          placeholder={f.placeholder}
          modules={{
            toolbar: f.disabled
              ? null
              : [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                  [{ align: [] }],
                  ['link', 'image'],
                  ['clean'],
                  [{ color: [] }, { background: [] }],
                ],
          }}
        />

        {visibleEditor == true ? Diag : null}
      </div>
    );
  }
);
