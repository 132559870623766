import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import IconButton from '@material-ui/core/IconButton';
import Pause from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Replay from '@material-ui/icons/Replay';
import Help from '../../common/components/Help';
import CommonTable from '@common/CommonTable';
import crypt from '../../util/crypt';
import CommonPage from '@common/CommonPage';
import ListColumn from '@common/ListColumn';
import Constants from '@data/Constants';

const tenant = Constants.Tenant;
@observer
export default class InventoryListService extends CommonPage {

  constructor(props) {
    super(props);

    const { services, ioServers } = props
    this.state = {
      services: []
      , hold: ''
      , available: false
    }


    this.state.services = services

    this.loadEvents(ioServers)
  }

  changeServiceState = data => {
    const { services } = this.state
    const { value, method } = data

    const filtered = services.map(element => {
      if (element.name != value.name) return element

      const { state } = element

      element.state = (state === 'Running' && method === 'stop') ? 'Stopped' : 'Running'

      return element
    })

    return this.setState({ services: filtered })
  }

  loadEvents = (socket) => {
    socket.on('reflect stop service', this.changeServiceState)
    socket.on('reflect start service', this.changeServiceState)
    socket.on('reflect restart service', this.changeServiceState)
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.services) {

      this.setState({ services: nextProps.services });
    }
  }
  componentDidMount() {
    let { values } = this.props;
    //console.log(values);
    if (values) {
      this.action.execute(
        'get',
        `${this.APIEndpoints.INVENTORY}/${values.id_inventory_collector}/mac`,
        'softwares'
      );
    }
  }
  componentWillMount() {

    const { values ,device} = this.props
   

   
    setInterval( () => {
      

      this.props.ioServers.emit("startservice",device.id_device) 
      this.props.ioServers.on(`_${tenant}_${device.id_device}`,
        (data) => {
   
          if (data.key == 'services') {
  
            this.setState({ services: data.values })
  
          }
          //   id_device: "75", item: "services", offline: false, data: Array(22)}
  
         // clearTimeout(this.state.hold);
      
         // let hold = setTimeout(() => (3000));
          //this.setState({ available: false, hold: hold });
  
        });
  

    },6000)



    


    /*  this.props.action.execute(
        'get',
        `${this.props.APIEndpoints.INVENTORY}/${
          this.props.device.id_inventory_collector
        }/service`,
        'services'
      ); */
  }

  handleSocket = event => value => {
    const { socketEvents, device } = this.props

    const target = Reflect.get(socketEvents, event)

    if (target) {
      return target(value, device.id_device)
    }
  }

  stopService = (value) => {

    this.handleSocket('stopService')(value)
  }

  startService = this.handleSocket('startService')
  restartService = this.handleSocket('restartService')

  render() {
    let { handlePage, language } = this.props;
    const { services } = this.state
    const props = this.props;

    let columns = [
      {
        label: language.NAME,
        key: 'name' // String-based value accessors!
      },
      {
        label: language.LEGEND,
        key: 'caption',
        Cell: props => <span className="number">{props.value}</span>
      },

      {
        label: language.STATE,
        key: 'state',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        label: language.ACTIONS,
        key: 'actions',
        style: { width: 80 }

      }
    ];

    let data = services && services.map(e => {
      return {
        name: e.nome,
        caption: e.display,
        state: e.state,

        actions: (
          <div className='d-flex align-items-center justify-content-between'>
            {e.state.toString().indexOf('STOPPED') > -1 ? <i class="fas fa-play" style={{ cursor: 'pointer', color: "#33ABA0" }}
              onClick={() => this.startService(e.nome)}></i> :
              <i class="fas fa-stop" style={{ cursor: 'pointer', color: 'var(--danger)' }}
                onClick={() => this.stopService(e.nome)}></i>}


            {/*
<i class="fas fa-stop" style={{cursor:'pointer'}}
            onClick={ () => this.restartService(e.nome) }></i>

            <IconButton aria-label="Start" onClick={ () => this.startService(e.nome) }>
              <PlayArrow fontSize="small" />
            </IconButton>
            <IconButton aria-label="Restart" onClick={ () => this.restartService(e.nome) }>
              <Replay fontSize="small" />
</IconButton> */}
          </div>
        )
      }
    })


    this.store.softwares
      .filter(h => h.command != 'software')
      .map(g => {
        if (g.command === 'osInfo') {
          this.state.nome = g.value.hostname;
          this.state.distro = g.value.distro + ' - ' + g.value.codename;
          this.state.versao = g.value.release;
          this.state.kernel = g.value.kernel;
        }
        if (g.command === 'os') {
          if (g.value.csname != undefined) {
            this.state.nome = g.value.csname;
            this.state.distro = g.value.caption;
            this.state.versao = g.value.version;
            this.state.kernel = '';
          }
        }
      });
    return (
      <PageLayout
        icon={
          <div className="inventaryIcon" style={{ width: 40, height: 40 }} />
        }
        title={language.INVENTORY}
        subtitle={'Service list'}
        rightElements={[]}
       
      >

        <Card className="content">
          <ListColumn
            col={3}
            items={[
              {
                col: 4,
                label: language.COMPUTER_NAME,
                value: this.state.nome //osInfo.hostname
              },
              {
                col: 4,
                label: language.DISTRO,
                value: this.state.distro //osInfo.distro
              },
              {
                col: 2,
                label: language.VERSION,
                value: this.state.versao // osInfo.release
              },
              {
                col: 2,
                label: 'Kernel',
                value: this.state.kernel //osInfo.kernel
              }
            ]}
          />
        </Card>
        <br />
        <CommonTable index='InventoryListService' data={data} columns={columns} rowsPerPageOptions={[100, 250]} searchColumn orderColumn />
      </PageLayout>
    );
  }
}
