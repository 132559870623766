import React, { memo, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { Draggable, Droppable } from 'react-drag-and-drop';
import classnames from 'classnames';
/*--------------------------common----------------------------*/
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import MenuTooltip from '@common/MenuTooltip';
import Dialog from '@common/Dialog';
/*--------------------------material-ui----------------------------*/
import { Checkbox } from '@material-ui/core';
/*--------------------------components----------------------------*/
import ListAvatars from '../../ProjectsList/ListItem/ListAvatars';
import moment from 'moment'
export default function ({
	id,
	avatares = [],
	tags = [],
	name,
	dt,
	dt_end,
	commentsTotal,
	filesTotal,
	percent,
	percentColor,
	onDelete,
	onEdit,
	onView,
	project,
	activity,
	sprint
}) {

	let [open, setOpen] = useState(false);
	let DraggableContent = percent!=100?Draggable:'div';

	return (

		<DraggableContent type="milestone" data={JSON.stringify({ id, percent, project })} className='Card d-flex w-100'>
			{moment().isAfter(dt_end) && activity.percent != "100" ? (

				<Tooltip
					title={moment().isAfter(dt_end) ? 'Atrasado' : 'No Prazo'}
					position="right"
					className='content-indicator-delay'
					interactive
					arrow={true}
				>
					<div className={classnames('indicator-delay', { delay: moment().isAfter(dt_end) })}>
						<i className="ml-1 fas fa-exclamation" />
					</div>
				</Tooltip>
			) : null}
			
			<div className='content-users p-1 pt-1'>
				<span className='id'>{id}</span>
				<ListAvatars star={false} maxItem={2} className='list-avatars-in-project' data={avatares} />
			</div>
			<div className='content-min content-desc'>
				<div onClick={() => onView(id)}>
					<div>
						{tags.slice(0, tags.length > 3 ? 2 : 3).map(e => (
							<Tag
								label={e.name}
								color={e.color}
								className='mr-1 ellipis tag'
							/>
						))}
						{tags.length > 3 &&
							<Tooltip
								html={
									<div style={{ color: '#fff', fontSize: 11 }}>
										{tags.slice(2, tags.length).map(e => (
											<Tag
												label={e.name}
												color={e.color}
												className='mr-1 ellipis tag'
											/>
										))}
									</div>
								}
								position="right"
								theme="light"
								interactive
								arrow
							>

								<Tag label={'+' + (tags.length - 3)} style={{ fontWeight: 'bold' }} color='#F05E02' />
							</Tooltip>
						}
					</div>
					<Tooltip
						html={
							<div style={{ color: '#fff', fontSize: 11 }}>
								{name}
							</div>
						}
						position="left"
						interactive
					>
						<p
							className='name ellipsis-line camp-2'
							dangerouslySetInnerHTML={{ __html: name }}
						/>
					</Tooltip>

				</div>
				<div>
					<Progress current={percent} className='w-100' background={percentColor} />
					<div className='content-info -mb-1'>
						<span className='d-block' style={{width:'45%'}}>
							<i className="fas fa-calendar-day" />
							{dt}

						</span>
						<span className='d-block' style={{width:'20%'}}>
							<i className="fas fa-comments" />
							{commentsTotal}
						</span>
						<span className='d-block' style={{width:'20%'}}>
							<i className="fas fa-paperclip" />
							{filesTotal}
						</span>

						<div style={{width:'15%'}}>
							<MenuTooltip

								propsButton={{
									style: {
										padding: 0,
										marginTop: -3
									}
								}}
								options={[									
									percent!=100 && project.pause_project==false ?{ value: 1, label: 'Editar', icon: 'fas fa-pencil-alt', color: 'var(--success)' } : null,
									percent!=100 && project.pause_project==false ?{ value: 2, label: 'Duplicar', icon: 'fas fa-copy', color: 'var(--warning)' } : null,
									project.pause_project==false ? { value: 3, label: 'Excluir', icon: 'fas fa-trash-alt', color: 'var(--danger)' } : null,
								].filter(Boolean)}
								onChange={e => {
									if (e.value == 1) {
										onEdit(id)
									} else if (e.value === 2) {
										onEdit(id, true);
									} else{
										onDelete(id)
									}
								}}
							/>
						</div>
						<Tooltip
							html={
								<div>
									Sprints onde a atividade está:
								<ul>
									{(sprint||[]).map(e=> (
										<li>{e.id+' - '+e.name}</li>
									))}
								</ul>
								</div>
							}
							interactive
							theme="dark"
							arrow
							className='mb-1'
							style={{fontSize:12, display:'inline-block'}}
						>
							<i className="fas fa-cubes" />
							{(sprint||[]).length}
						</Tooltip>
						<Tooltip
							html={
								<div>
									Atividades Antecessoras:
									<ul>
										{(activity.activityprd || []).map((e, i) => (
											<li>{e.id_project_activity+' - '+e.name}</li>
										))}
									</ul>
								</div>
							}
							interactive
							theme="dark"
							arrow
							style={{fontSize:12, display:'inline-block'}}
							className='ml-3 mb-1'
						>
							<i className="fas fa-undo-alt"/>
							{(activity.activityprd || []).length}
						</Tooltip>
					
					
					
					
						<Tooltip
							html={
								<div>
								Sprints
									<ul>
										{(activity.sprint || []).map((e, i) => (
											<li>{1+i+++')'+' - '+ e.name}</li>
										))}
									</ul>
								</div>
							}
							interactive
							theme="dark"
							arrow
							style={{fontSize:12, display:'inline-block'}}
							className='ml-3 mb-1'
						>
						<i class="fas fa-chart-line"></i>
						 {(activity.sprint || []).length}
						</Tooltip>
					
					
					
					
					</div>
				</div>
			</div>
		</DraggableContent>
	)
}