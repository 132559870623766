import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import TechEvaluationList from './TechEvaluationList';
import TechEvaluationForm from './TechEvaluationForm';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

import Animate from '@common/Animate';
@observer
export default class TechEvaluationContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
      status: [],
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    const action = new Actions();

    action
      .execute('get', Constants.APIEndpoints.TECH_EVALUATION_REGISTRATION, 'tech_evaluation_registration')
      .then((e) => {
        this.setState({ status: e.data, });
      })

      
    this.handlePage('list');
  }

  onFormSubmit(values) {
    const action = new Actions();
    let method = values.hasOwnProperty('id_tech_evaluation_registration') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_tech_evaluation_registration')
      ? Constants.APIEndpoints.TECH_EVALUATION_REGISTRATION + '/' + values.id_tech_evaluation_registration
      : Constants.APIEndpoints.TECH_EVALUATION_REGISTRATION;

    action
      .execute(method, url, 'tech_evaluation_registration', values, 'id_tech_evaluation_registration')
      .then((e) => {
        this.setState({ status: e.data });
      })
      .catch((error) => {});

    this.handlePage('list');
  }

  onDelete() {
    let { values } = this.state;
    const action = new Actions();

    action.execute('post', Constants.APIEndpoints.TECH_EVALUATION_REGISTRATION  + '/' +'deletestatus',
     'tech_evaluation_registration', values, 'id_tech_evaluation_registration')
     .then ((e) => {
      this.setState({ status: e.data });

     })


     this.handlePage('list');  

  }

  handlePage(page, edit) {

    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;

    let {
      language,
      mails,
      companies,
      groups,
      contracts,
      mailContract,
    } = this.store;
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <TechEvaluationList
          redirect={this.redirect2}
          language={language}
          handlePage={this.handlePage}
          store={this.state.status}
          configurationProps={configurationProps}
        />

        {page === 'form' ? (
          <TechEvaluationForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.status}
          />
        ) : null}
      </Fragment>
    );
  }
}
