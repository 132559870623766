import React, { useState } from 'react';
import CommonTable from '@common/CommonTable';
import { formatDeadline } from '../../utils/formatDeadline';
import AttachFile from '@common/AttachFile';
import moment from 'moment';
import {
  Card,
  Grid,
  Slider,
  Typography,
  Button as ButtonUI,
  Divider,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Styles from '../../attendance/Styles';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import { getBackStatus, getColorStatus } from '../../utils/colorsStageSteps';
import { formshtml } from '../../utils/formshtml';
import decrypt from '../../../../util/decrypt';
import CommonForm2 from '../../../../common/CommonForm2';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Progress from '@common/Progress';
import DownloadFiles from '../../../../util/DownloadFiles';
import Button from '../../../../common/Button';
import Paper from '@material-ui/core/Paper';
import '../../../TicketManagement/Detail.css';
import { findUserGroup } from '../../utils/findUserGroup';

const MainPageAttendance = ({
  APIEndpoints,
  Attendance,
  setOpenCancelDialog,
  setOpenReclassifyDialog,
  language,
  setOpenGroupTech,
  setOpenUserStart,
  setOpenStepsManagement,
  Store,
  handleOpenTask,
  updateState,
  setSearchHistoric,
  SearchHistoric,
  setOpenObservers,
  filesSolution,
  updateAttendance,
  filesAttendance,
  setSingleAttendance,
  action,
  setOpenFinishDialog,
}) => {
  const classes = Styles();
  const [formPrimaryRef, setFormPrimaryRef] = useState(null);
  const [formRef, setFormRef] = useState(null);

  const dettachObserver = (values) => {
    values.fk_id_attendance = Attendance.id_attendance_sac;
    values.user = Store.getUserLoged();
    action
      .execute('put', APIEndpoints.SAC_OBSERVERS, '', values)
      .then((res) => {
        updateAttendance();
        setOpenObservers(false);
      });
  };

  const startAttendance = () => {
    action
      .execute(
        'post',
        APIEndpoints.SAC_ATTENDANCE + '/start/' + Attendance.id_attendance_sac,
        '',
        { user: Store.getUserLoged() }
      )
      .then((res) => {
        Store.toggleAlert(true, 'Chamado Iniciado com Sucesso', 'success');
        setSingleAttendance(res.data[0]);
      });
  };

  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.padStart(14, '0');

    return `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(5, 3)}/${cnpj.substr(8, 4)}-${cnpj.substr(12)}`;
  }

  function mascaraCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return cpf;
  }

  const saveProgress = (values) => {
    values.user = values.user = Store.getUserLoged();
    values.id_attendance_sac = Attendance.id_attendance_sac;
    action
      .execute('post', APIEndpoints.SAC_ATTENDANCE + '/progress', '', values)
      .then((res) => {
        Store.toggleAlert(true, 'Dados salvos com sucesso', 'success');
        setSingleAttendance(res.data[0]);
      });
  };

  const onDownFileAttendanca = (id_attachment_attendance_sac, file) => {
    DownloadFiles(
      APIEndpoints.SAC_ATTENDANCE +
        '/downloadattendance/' +
        id_attachment_attendance_sac +
        '&' +
        file,
      file
    );
  };



  const onDownFileAttendancaSolution = (id_attachment_attendance_sac, file) => {
    DownloadFiles(
      APIEndpoints.SAC_ATTENDANCE +
        '/downloadattendancesolution/' +
        id_attachment_attendance_sac +
        '&' +
        file,
      file
    );
  };

  const uploadAttachmentAttendance = (values) => {
    let file = values[values.length - 1];
    values.user = Store.getUserLoged();
    values.place = 'sac';
    let files = [];
    let cont = 0;
    let total = 1;
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error('Failed converting to base64'));
        }
      };
    });
    promise.then((result) => {
      files.push({
        file: result,
        type: file.type,
        namefile: file.name,
      });
      values.files = files;
      let tokenApi = Store.getToken();
      const data = {
        files: JSON.stringify(files),
        id_attendance_sac: Attendance.id_attendance_sac,
        place: 'sac',
        user: Store.getUserLoged(),
        insideAttendance: true,
      };

      action
        .execute('post', APIEndpoints.SAC_ATTENDANCE + '/attachment', '', data)
        .then((data) => {
          updateAttendance();
        });
    });
  };

  const onRemoveFileAttendance = (id_attachment_attendance_sac) => {
    action
      .execute('put', APIEndpoints.SAC_ATTENDANCE + '/attachment', '', {
        user: Store.getUserLoged(),
        id_attachment_attendance_sac: id_attachment_attendance_sac,
      })
      .then((res) => {
        updateAttendance();
      });
  };

  return (
    <Card className="content">
      <div className="d-flex align-items-center justify-content-between w-100 mb-4">
        <h3 className="title-aba" style={{ marginTop: 10 }}>
          Informações básicas do atendimento
        </h3>
        <div>
          {(Attendance.status == 'Open' ||
            Attendance.status == 'In Progress') &&
            findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) && (
            <>
              <Button
                icon="fas fa-times"
                variant="normal"
                color="warning"
                label={'Cancelar Atendimento'}
                size={0.9}
                zoom={0.9}
                style={{ fontSize: 14, marginLeft: 5 }}
                onClick={() => setOpenCancelDialog(true)}
              />
              <Button
                icon="fas fa-edit"
                variant="normal"
                color="success"
                label={'Reclassificar Atendimento'}
                size={0.9}
                zoom={0.9}
                style={{ fontSize: 14, marginLeft: 5 }}
                onClick={() => setOpenReclassifyDialog(true)}
              />
            </>
          )}
          {Attendance.status === 'Open' && (
            <Button
              icon="fas fa-play"
              variant="normal"
              color="success"
              label={language.START_ATTENDANCE}
              size={0.9}
              zoom={0.9}
              style={{ fontSize: 14, marginLeft: 5 }}
              onClick={startAttendance}
            />
          )}
        </div>
      </div>
      <Grid container spacing={1} className="block-1">
        <Grid item xs={4} className="col-b1 col-1-b1">
          <div className="content-medium">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h3 className="title">{`${String(Attendance?.cpf_cnpj || '').length > 11 ?
                                        formatCNPJ(Attendance?.cpf_cnpj || '') :
                                        mascaraCPF(Attendance?.cpf_cnpj || '')} - ${Attendance?.name_consumer}`}</h3>
                <div className="ml-4">
                  {[
                    {
                      label: language.EMAIL,
                      value: Attendance?.consumer_email,
                    },
                    {
                      label: language.TYPE,
                      value: Attendance?.consumer_type,
                    },
                    {
                      label: language.ADDRESS,
                      value: Attendance?.consumer_address,
                    },
                  ].map((e, i) => (
                    <div key={i} className="content-items">
                      <span className="label-item">{e.label}</span>
                      <span className="value-item">{e.value}</span>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4} className="col-b1 col-1-b1">
          <div className="content-medium">
            <Grid container spacing={0} className="block-1">
              <Grid item xs={12}>
                <h3 className="title">MANIFESTO</h3>
                <div className="ml-4">
                  {[
                    {
                      label: 'Contato',
                      value: Attendance?.name_channel,
                    },
                    {
                      label: 'Tipo',
                      value: Attendance?.type,
                    },
                    {
                      label:
                        Attendance?.status == 'Closed' ? '' : 'Em progresso:',
                      value: (
                        <div className="d-flex align-items-center w-100">
                          <Progress
                            hiddenText
                            current={Attendance?.progress || 0}
                            style={{ height: 7, width: '100%' }}
                            background="var(--danger)"
                          />
                          <span
                            className="ml-1"
                            style={{ fontSize: 10, width: 38 }}
                          >
                            {Attendance?.progress || 0} %
                          </span>
                        </div>
                      ),
                    },
                  ].map((e, i) => (
                    <div key={i} className="content-items">
                      <span className="label-item">{e.label}</span>
                      <span className="value-item">{e.value}</span>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4} className="col-b1 col-1-b1">
          <div className="content-medium">
            <Grid container spacing={0} className="block-1">
              <Grid item xs={12}>
                <h3 className="title">{language.CLASSIFICATION}</h3>
                <div className="ml-4">
                  {[
                    {
                      label: language.THEME,
                      value: Attendance?.name_theme,
                    },
                    {
                      label: language.TOPIC,
                      value: Attendance?.name_topic,
                    },
                    {
                      label: language.SUBJECT,
                      value: Attendance?.name_subject,
                    },
                  ].map((e, i) => (
                    <div key={i} className="content-items">
                      <span className="label-item">{e.label}</span>
                      <span className="value-item">{e.value}</span>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="block-1 mt-2">
        <Grid
          item
          xs={8}
          className="col-b1 col-1-b1"
          style={{ maxHeight: 560 }}
        >
          <div className="content-medium" style={{ height: '100%' }}>
            <h3 className="title">
              <FormatAlignLeftIcon
                className="icon-title"
                style={{ color: 'var(--warning)' }}
              />
              {language.DESCRIPTION}
            </h3>
            <div
              id="desccc"
              className="ml-4 content-desc detail-description"
              style={{ height: 'calc(100% - 40px)' }}
            >
              <PerfectScrollbar
                className="scrollVisible"
                style={{
                  height: '100%',
                  wordBreak: 'break-word',
                }}
                option={{
                  suppressScrollX: true,
                }}
              >
                <p
                  style={{ fontSize: 14 }}
                  className="pr-3"
                  dangerouslySetInnerHTML={{
                    __html: Attendance?.description,
                  }}
                />
              </PerfectScrollbar>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          className="col-b1 col-1-b1"
          style={{ maxHeight: 560, minHeight: 300 }}
        >
          <div className="content-medium" style={{ height: '100%' }}>
            <h3 className="title">OUTROS DADOS</h3>
            <div className="ml-4">
              {[
                {
                  label: language.TECH_GROUP,
                  value: (
                    <span>
                      {Attendance.name_group_attend}
                      {(Attendance.status == 'Open' ||
                        Attendance.status == 'In Progress') && (
                        <Button
                          variant="circle"
                          icon="fas fa-pencil-alt"
                          color="success"
                          outlined
                          onClick={(evt) => {
                            setOpenGroupTech(true);
                          }}
                          style={{ border: 'none', fontSize: 14 }}
                        />
                      )}
                    </span>
                  ),
                },
                {
                  label: language.STATE,
                  value:
                    Attendance.status == 'Open'
                      ? 'Aberto'
                      : Attendance.status == 'In Progress'
                      ? 'Em atendimento'
                      : Attendance.status == 'Resolved'
                      ? 'Resolvido'
                      : Attendance.status == 'Closed'
                      ? 'Fechado'
                      : Attendance.status == 'Canceled'
                      ? 'Cancelado'
                      : Attendance.status,
                },
                {
                  label: '',
                  value: (
                    <span style={{ marginTop: '10px' }}>
                      Observadores
                      {(Attendance.status == 'Open' ||
                        Attendance.status == 'In Progress') && (
                        <Button
                          variant="circle"
                          icon="fas fa-pencil-alt"
                          color="success"
                          outlined
                          onClick={(evt) => {
                            setOpenObservers(true);
                          }}
                          style={{ border: 'none', fontSize: 14 }}
                        />
                      )}
                      {Attendance.observers &&
                        Attendance.observers.map((observer) => (
                          <div>
                            {observer.name}{' '}
                            <i
                              style={{
                                color: 'red',
                                cursor: 'pointer',
                              }}
                              onClick={() => dettachObserver(observer)}
                              class="far fa-trash-alt"
                            ></i>{' '}
                          </div>
                        ))}
                    </span>
                  ),
                },
                {
                  label: language.TECH,
                  value: (
                    <span>
                      {Attendance.name_user_start
                        ? Attendance.name_user_start
                        : 'N/A'}
                      {Attendance.name_user_start &&
                        (Attendance.status == 'Open' ||
                          Attendance.status == 'In Progress') && (
                          <Button
                            variant="circle"
                            icon="fas fa-pencil-alt"
                            color="success"
                            outlined
                            onClick={(evt) => {
                              setOpenUserStart(true);
                            }}
                            style={{ border: 'none', fontSize: 14 }}
                          />
                        )}
                    </span>
                  ),
                },
                {
                  label: 'Satisfação',
                  value: (
                    <span>
                      {Attendance.satisfation == 'sim' ? (
                        <ThumbUpIcon />
                      ) : Attendance.satisfation == 'nao' ? (
                        <ThumbDownAltIcon />
                      ) : Attendance.satisfation == 'n/a' ? (
                        <ThumbsUpDownIcon />
                      ) : (
                        ''
                      )}
                    </span>
                  ),
                },
              ].map((e, i) => (
                <div key={i} className="content-items">
                  <span className="label-item">{e.label}</span>
                  <span className="value-item">{e.value}</span>
                </div>
              ))}
            </div>

            <div style={{ marginTop: 100 }}>
              <CommonForm2
                clean
                values={{ files: filesAttendance }}
                fields={[
                  {
                    col: 12,
                    type: 'file',
                    name: 'files',
                    label: language.ATTACH_FILE,
                    showFiles: false,
                  },
                ]}
                onChangeField={(f, v) => {
                  uploadAttachmentAttendance(v);
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              {filesAttendance.map((f) => (
                <AttachFile
                  label={f.file}
                  onClick={() =>
                    onDownFileAttendanca(f.id_attachment_attendance_sac, f.file)
                  }
                  onDelete={() =>
                    onRemoveFileAttendance(f.id_attachment_attendance_sac)
                  }
                  style={{ maxWidth: '100%' }}
                />
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
      {findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) ? (
        <Grid container spacing={1} className="block-1 mt-2">
          <Grid
            item
            xs={12}
            className="col-b1 col-1-b1"
            style={{ maxHeight: 560, minHeight: 200 }}
          >
            <div className="content-medium" style={{ height: '100%' }}>
              <h3 className="title">
                <FormatAlignLeftIcon
                  className="icon-title"
                  style={{ color: 'var(--warning)' }}
                />
                {language.INSTRUCTIONS}
              </h3>
              <div
                id="desccc"
                className="ml-4 content-desc detail-description"
                style={{ height: 'calc(100% - 40px)' }}
              >
                <PerfectScrollbar
                  className="scrollVisible"
                  style={{
                    height: '100%',
                    wordBreak: 'break-word',
                  }}
                  option={{
                    suppressScrollX: true,
                  }}
                >
                  <p
                    style={{ fontSize: 14 }}
                    className="pr-3"
                    dangerouslySetInnerHTML={{
                      __html: Attendance?.instructions,
                    }}
                  />
                </PerfectScrollbar>
              </div>
            </div>
          </Grid>
        </Grid>
        ) : null }
      {Attendance.status == 'Resolved' || Attendance.status == 'Closed' ? (
        <Grid container spacing={1} className="block-1 mt-2">
          <Grid
            item
            xs={12}
            className="col-b1 col-1-b1"
            style={{ maxHeight: 560, minHeight: 200 }}
          >
            <div className="content-medium" style={{ height: '100%' }}>
              <h3 className="title" style={{ textTransform: 'none' }}>
                <FormatAlignLeftIcon
                  className="icon-title"
                  style={{ color: 'var(--warning)' }}
                />
                Anexos da resolução
              </h3>
              <div
                id="desccc"
                className="ml-4 content-desc detail-description"
                style={{ height: 'calc(100% - 40px)' }}
              >
                <PerfectScrollbar
                  className="scrollVisible"
                  style={{
                    height: '100%',
                    wordBreak: 'break-word',
                  }}
                  option={{
                    suppressScrollX: true,
                  }}
                >
                  {filesSolution.map((f) => (
                    <AttachFile
                      label={f.file}
                      onClick={() =>
                        onDownFileAttendancaSolution(
                          f.id_attachment_attendance_sac,
                          f.file
                        )
                      }
                      style={{ maxWidth: '100%' }}
                    />
                  ))}
                </PerfectScrollbar>
              </div>
            </div>
          </Grid>
         </Grid>
      ) : null }
      {Attendance.status == 'Resolved' || Attendance.status == 'Closed' ? (
        <Grid container spacing={1} className="block-1 mt-2">
          <Grid
            item
            xs={8}
            className="col-b1 col-1-b1"
            style={{ maxHeight: 560, minHeight: 200 }}
          >
            <div className="content-medium" style={{ height: '100%' }}>
              <h3 className="title">
                <FormatAlignLeftIcon
                  className="icon-title"
                  style={{ color: 'var(--warning)' }}
                />
                Parecer
              </h3>
              <div
                id="desccc"
                className="ml-4 content-desc detail-description"
                style={{ height: 'calc(100% - 40px)' }}
              >
                <PerfectScrollbar
                  className="scrollVisible"
                  style={{
                    height: '100%',
                    wordBreak: 'break-word',
                  }}
                  option={{
                    suppressScrollX: true,
                  }}
                >
                  <p
                    style={{ fontSize: 14 }}
                    className="pr-3"
                    dangerouslySetInnerHTML={{
                      __html: Attendance?.opinion,
                    }}
                  />
                </PerfectScrollbar>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            className="col-b1 col-1-b1"
            style={{ maxHeight: 560, minHeight: 200 }}
          >
            <div className="content-medium" style={{ height: '100%' }}>
              <h3 className="title">
                <FormatAlignLeftIcon
                  className="icon-title"
                  style={{ color: 'var(--warning)' }}
                />
                Solução
              </h3>
              <div
                id="desccc"
                className="ml-4 content-desc detail-description"
                style={{ height: 'calc(100% - 40px)' }}
              >
                <PerfectScrollbar
                  className="scrollVisible"
                  style={{
                    height: '100%',
                    wordBreak: 'break-word',
                  }}
                  option={{
                    suppressScrollX: true,
                  }}
                >
                  <p
                    style={{ fontSize: 14 }}
                    className="pr-3"
                    dangerouslySetInnerHTML={{
                      __html: Attendance?.solution,
                    }}
                  />
                </PerfectScrollbar>
              </div>
            </div>
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={1} className="block-1 mt-2">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            right: 40,
          }}
        >
          {Store.getUserLoged().role !== 'solicitant' &&
          Attendance?.status != 'Conclued' &&
          Attendance?.status != 'Resolved' ? (
            <div>
              <Button
                icon="fas fa-sync"
                variant="normal"
                color="warning"
                size={0.9}
                zoom={0.9}
                onClick={() => updateAttendance()}
                label={'Atualizar'}
                style={{ marginRight: 5}}
              />
              {findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) && (
                <Button
                  variant="normal"
                  color="success"
                  label={'Fluxo de Etapas'}
                  icon="fas fa-pencil-alt"
                  onClick={() => {
                    if (Attendance?.status !== 'In Progress') {
                      Store.toggleAlert(true, 'Necessário iniciar o atendimento, antes de incluir tarefas', 'error');
                    } else {
                      setOpenStepsManagement(true)
                    }
                  }}
                />
                )}

            </div>
          ) : null}
        </div>
        <h3 className="title-aba" style={{ marginTop: 10 }}>
          Etapas
        </h3>

        <Grid
          xs={12}
          style={{
            overflowX: 'scroll',
            display: 'flex',
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          {Attendance?.steps &&
            Attendance.steps
              .filter((a) => a.stage !== 'Conclued' && a.stage !== 'Canceled' &&
              (findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) ||
              a.fk_id_user === Store.getUserLoged().id))
              .map((step, index) => (
                <Grid
                  xs={3}
                  spacing={1}
                  key={Math.random()}
                  style={{
                    marginRight: 10,
                    maxHeight: 375,
                    height: 305,
                    overflowY: 'auto',
                  }}
                >
                  <Paper
                    className={classes.itemTask}
                    style={{
                      borderColor: step.level_sla === 3 ? "red" : '#019fc2',
                      minHeight: 300,
                      minWidth: 350,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOpenTask(true, step)}
                  >
                    <Typography
                      className={classes.textDescription}
                      style={{ marginTop: 6, fontSize: 12 }}
                    >
                      Nome da Etapa
                    </Typography>
                    <Typography style={{ marginTop: 2, fontSize: 16 }}>
                      {step.name}
                    </Typography>
                    <Divider />
                    <ButtonUI
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '5px 5px 5px 0px',
                        borderRadius: 10,
                        background: getBackStatus(step.stage),
                        color: getColorStatus(step.stage),
                      }}
                      onClick={() => handleOpenTask(true, step)}
                    >
                      <Typography
                        style={{
                          color: '#B5B5B5',
                          fontSize: 14,
                          fontWeight: '500',
                        }}
                      >
                        {step.stage == 'Open'
                          ? 'ABERTO'
                          : step.stage == 'Pending'
                          ? 'PENDENTE'
                          : step.stage == 'In Progress'
                          ? 'Em atendimento / Parecer'
                          : step.stage == 'Conclued'
                          ? 'CONCLUÍDO'
                          : step.stage == 'Canceled'
                          ? 'CANCELADO'
                          : ''}
                      </Typography>
                    </ButtonUI>
                    <Divider />
                    <Typography
                      className={classes.textDescription}
                      style={{ marginTop: 2, fontSize: 12 }}
                    >
                      Departamento responsável
                    </Typography>
                    <Typography style={{ marginTop: 2, fontSize: 16 }}>
                      {step.name_department}
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.textDescription}
                      style={{ marginTop: 2, fontSize: 12 }}
                    >
                      Usuário responsável
                    </Typography>
                    <Typography style={{ marginTop: 2, fontSize: 16 }}>
                      {step.responsible ? step.responsible : ''}
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.textDescription}
                      style={{ marginTop: 2, fontSize: 12 }}
                    >
                      Prazo
                    </Typography>
                    <div
                      style={{
                        color: step.level_sla === 3 ? 'red' :'#B5B5B5',
                        fontWeight: 400,
                        fontSize: 15,
                      }}
                    >
                      {step?.sla_time
                        ? moment(step.sla_time).format('DD/MM/YYYY HH:mm')
                        : formatDeadline(step.deadline)}
                    </div>
                    <Divider />
                    <Typography style={{ marginTop: 2, fontSize: 12 }}>
                      Início da atividade:{' '}
                      {step?.dt_cad
                        ? `${moment(step.dt_cad).format(
                            'DD/MM/YYYY HH:mm'
                          )} + ${formatDeadline(step.deadline)} hrs`
                        : ''}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </Grid>
      {Attendance?.template && (
        <div className="template template-dinamic">
          <h3 className="title-aba" style={{ marginTop: 10 }}>
            Formulário Técnico
          </h3>

          <p
            style={{ color: '#888080', pointerEvents: 'none' }}
            id={'formavaliationAttendance'}
          >
            {formshtml(decrypt(Attendance.template), updateState)}
          </p>
        </div>
      )}
      {Attendance?.status == 'Resolved' && Attendance?.template_final && (
        <div className="template template-dinamic">
          <h3 className="title-aba" style={{ marginTop: 10 }}>
            Formulário Finalização Atendimento
          </h3>
          <p
            style={{ color: '#888080', pointerEvents: 'none' }}
            id={'formavaliationAttendance'}
          >
            {formshtml(decrypt(Attendance.template_final), updateState)}
          </p>
        </div>
      )}
      {(Attendance.status == 'Open' || Attendance.status == 'In Progress') &&
      findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) && (
        <Grid container spacing={1} id="block-2" style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <h3 className="title-aba mb-4">Resolução do atendimento</h3>
            <span id="descriptionTicket___">
              <CommonForm2
                onChangeField={(f, v) => {}}
                onSubmit={saveProgress}
                onRef={(r) => setFormPrimaryRef(r)}
                childFields={
                  (formRef || {}).getFields
                    ? formRef.getFields().map((e) => ({
                        name: e.name,
                        required: e.required,
                      }))
                    : []
                }
                clean
                fields={[
                  {
                    col: 3,
                    type: 'element',
                    name: 'group-field',
                    content: (v, handleValue) => (
                      <div className="mt-2">
                        <CommonForm2
                          onRef={(r) => setFormRef(r)}
                          individual
                          onChangeField={(f, v) => {
                            handleValue(v, f.name);
                            if (f.name == 're') {
                              formPrimaryRef.handleValue(
                                answer.filter((g) => g.id_answer === v)[0]
                                  .description,
                                'notes'
                              );
                            } else if (f.name == 'notes') {
                              formPrimaryRef.handleValue(undefined, 'notes');
                            }
                          }}
                          clean
                          fields={[
                            {
                              type: 'element',
                              name: 'progress',
                              required: true,
                              content: (value, handleValue) => (
                                <div className="py-3 d-flex aling-items-center">
                                  <MuiThemeProvider>
                                    <Slider
                                      value={value ? value : ''}
                                      min={Attendance?.progress}
                                      max={100}
                                      step={5}
                                      aria-labelledby="label"
                                      onChange={(evt, v) => {
                                        handleValue(v, 'progress');
                                        if (v == 100) {
                                          setOpenFinishDialog(true);
                                        }
                                        //setPercent(v);
                                      }}
                                    />
                                  </MuiThemeProvider>
                                  <span
                                    className="ml-1"
                                    style={{
                                      marginTop: -9,
                                      fontSize: 13,
                                    }}
                                  >
                                    {parseInt(value) || Attendance?.progress}%
                                  </span>
                                </div>
                              ),
                            },
                            {
                              name: 'time',
                              label: 'Tempo Gasto',
                              type: 'text',
                              required: true,
                              format: '##:##',
                              change(v) {
                                return v.formattedValue;
                              },
                            },
                          ]}
                          button={{
                            md: 2,
                            offset: { md: 10 },
                            label: language.SAVE,
                            primary: true,
                            style: { margin: 10 },
                          }}
                        />
                      </div>
                    ),
                  },
                  {
                    col: 9,
                    type: 'textEditor',
                    contentProps: { style: { marginTop: -29 } },
                    className: 't1-description',
                    name: 'notes',
                    label: language.RESOLUTION,
                    required: true,
                  },
                ]}
              />
            </span>
          </Grid>
        </Grid>
      )}
      <div className="mt-5 _table">
        <CommonTable
          paper={false}
          mini
          index={'ticket-detail-history'}
          bar={false}
          orderColumn={false}
          col={[
            {
              key: 'description',
              label: (
                <div className="d-flex align-items-center">
                  <h3
                    className="title-aba mr-3"
                    style={{ color: 'var(--success)' }}
                  >
                    Histórico
                  </h3>
                  <div
                    style={{ zoom: 0.8, width: 300 }}
                    zoom={0.8}
                    className="mb-1"
                  >
                    <CommonForm2
                      individual
                      fields={[
                        {
                          type: 'search',
                          startIcon: 'fas fa-search',
                          name: 'search',
                          placeholder: language.SEARCH + '...',
                          className: 'darks',
                        },
                      ]}
                      onChangeField={(f, v) => setSearchHistoric(v)}
                    />
                  </div>
                </div>
              ),
            },
          ]}
          data={[
            {
              description: (
                <p
                  style={{ color: '#888080', fontSize: 16 }}
                  dangerouslySetInnerHTML={{
                    __html:
                      Attendance?.notes &&
                      Attendance.notes
                        .split('<hr/></p>')
                        .filter(Boolean)
                        .filter(
                          (e) =>
                            e
                              .toLocaleLowerCase()
                              .indexOf(SearchHistoric.toLocaleLowerCase()) > -1
                        )
                        .join('<hr/></p>'),
                  }}
                />
              ),
            },
          ]}
        />
      </div>
    </Card>
  );
};

export default MainPageAttendance;
