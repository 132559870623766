import 'xterm/dist/xterm.css';

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import socketIo from 'socket.io-client';
import { Checkbox } from '@material-ui/core';
import { FaExpand } from 'react-icons/lib/fa';
import FaClose from 'react-icons/lib/fa/close';
import { withStyles } from '@material-ui/core/styles';
import { Container, Row, Col } from 'react-grid-system';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import FaStickyNote from 'react-icons/lib/fa/sticky-note';
import FaTimesCircle from 'react-icons/lib/fa/times-circle';
import { Card, SelectField, MenuItem, Paper } from '@material-ui/core';

import Constants from '../../data/Constants';
import CommonForm from '../../common/CommonForm';
import RaisedButton from '../../common/RaisedButton';

import RdpPage from './RdpPage';
import TerminalTab from './TerminalTab';

const styles = (theme) => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '40vh',
    minWidth: '1000px',
  },
  dialogPaperAlert: {
    minHeight: '25vh',
    maxHeight: '25vh',
    minWidth: '700px',
  },
  dialogPaper_: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '2000px',
  },
});
class AssessmentTerminal extends Component {
  constructor() {
    super();
    this.state = {
      io: socketIo.connect(Constants.IOTerminal, {
        path: '/terminal',
        reconnection: false,
      }),
      qtdTabs: 1,
      tabActive: 0,
      labelTab: ['Terminal 1'],
      ticketsFinalized: [],
      anotation: '',
    };
    this.handleTabs = this.handleTabs.bind(this);
    this.finishAssessment = this.finishAssessment.bind(this);
  }

  handleTabs(type, idx) {
    let { qtdTabs, tabActive, labelTab } = this.state;
    if (type === 'plus') {
      if (qtdTabs < 5) qtdTabs++;
      labelTab.push(
        'Terminal ' +
          (parseInt(labelTab[labelTab.length - 1].split(' ')[1]) + 1)
      );
      tabActive = idx;
    } else if (type === 'minus') {
      qtdTabs--;
      labelTab = labelTab.filter((l) => l !== 'Terminal ' + (idx + 1));
      tabActive = 0;
    } else if (type === 'change') {
      tabActive = idx;
    }
    this.setState({
      qtdTabs: qtdTabs,
      tabActive: tabActive,
      labelTab: labelTab,
    });
  }

  onConfirmTicket(ticket) {
    let { ticketsFinalized } = this.state;
    if (ticketsFinalized.includes(ticket)) {
      ticketsFinalized = ticketsFinalized.filter((t) => t !== ticket);
    } else {
      ticketsFinalized.push(ticket);
    }
    this.state.ticketsFinalized = ticketsFinalized;
  }

  render() {
    const { classes } = this.props;
    let { connection } = this.props;
    if (connection === 'ssh') return this.renderSSH();
    else return this.renderRDP();
  }

  finishAssessment(type) {
    let { anotation, io, ticketsFinalized } = this.state;
    if (type === 'rdp')
      io.emit('close_' + this.props.store.assessment[0].path + '_r', true);
    if (type === 'ssh')
      io.emit('close_' + this.props.store.assessment[0].path, true);
    this.props.finishAssessment(ticketsFinalized, anotation);
  }

  renderRDP() {
    const { classes } = this.props;
    let { language, store, ticketsSelected, finishAssessment } = this.props;
    let { io, modal, ticketsFinalized, notes, anotation, onCloseButton } =
      this.state;
    let list = [];
    try {
      list = (
        <Row key={k}>
          <Col md={1}>
            <Checkbox
              onCheck={() => this.onConfirmTicket(ticketsSelected.id_tickets)}
            />
          </Col>
          <Col md={2}>{ticketsSelected.realid}</Col>
          <Col md={9} className="text-left">
            {ticketsSelected.subject}
          </Col>
        </Row>
      );
    } catch (e) {}

    let closeStyle = {
      position: 'fixed',
      zIndex: 1300,
      top: 90,
      left: 50,
      color: 'orange',
    };

    return (
      <Card className="cardContainer admin-graphs-contracts" ref="container">
        <h3 style={{ marginBottom: 0 }}>
          <span>{language.RDP}</span>
        </h3>
        <span className="icons" style={closeStyle}>
          <FaTimesCircle
            data-tip={language.CLOSE}
            size={30}
            className="pointer"
            onClick={() => this.setState({ modal: true, notes: false })}
          />
          <ReactTooltip />
        </span>

        <Dialog
          PaperProps={{ classes: { root: classes.dialogPaper } }}
          modal={true}
          open={!!modal}
          maxWidth="lg"
          autoScrollBodyContent={true}
        >
          <div className="container" style={{ padding: '20px 0' }}>
            <Card className="modalCard">
              <h3>
                {notes ? language.ANNOTATION : language.ATTENTION}{' '}
                <FaClose
                  onClick={() => this.setState({ modal: false })}
                  className="closeModal"
                />
              </h3>
              {notes ? (
                <Paper zdepth={0}>
                  <Row>
                    <Col md={12}>
                      <CommonForm
                        language={language}
                        fields={[
                          { span: 12, name: 'note', type: 'textEditor' },
                        ]}
                        button={{
                          md: 12,
                          label: language.SAVE,
                          primary: true,
                          style: { margin: '20px 0' },
                        }}
                        onFormSubmit={(values) =>
                          this.setState({
                            anotation: values.note,
                            modal: false,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Paper>
              ) : (
                <Paper zdepth={0} className="text-center">
                  {list.length ? (
                    <Container>
                      <Row>
                        <Col md={12}>
                          <h2>{language.CONFIRM_TICKETS_ASSESSMENT}</h2>
                        </Col>
                      </Row>
                      {list}
                    </Container>
                  ) : (
                    <h2>{language.CONFIRM_CLOSE_ASSESSMENT}</h2>
                  )}
                  <Row className="row" style={{ padding: '20px 0px' }}>
                    <Col md={4} offset={{ md: 8 }}>
                      <RaisedButton
                        primary={true}
                        label={language.CONFIRM}
                        onClick={() => this.finishAssessment('rdp')}
                      />
                    </Col>
                  </Row>
                </Paper>
              )}
            </Card>
          </div>
        </Dialog>
      </Card>
    );
  }

  renderSSH() {
    let { language, store, ticketsSelected } = this.props;
    let {
      qtdTabs,
      tabActive,
      labelTab,
      io,
      modal,
      ticketsFinalized,
      notes,
      anotation,
    } = this.state;
    let tabs = [];
    let terms = [];

    if (store.assessment[0]) {
      for (let i = 0; i < qtdTabs; i++) {
        let path = store.assessment[0].path;

        terms.push(
          <TerminalTab
            key={i}
            io={io}
            path={path}
            active={i === tabActive}
            store={store}
          />
        );
        tabs.push(
          <div
            className={
              i === tabActive
                ? 'terminal-tab terminal-tab-active'
                : 'terminal-tab'
            }
            key={i}
          >
            <span
              className="pointer"
              onClick={() => this.handleTabs('change', i)}
            >
              {labelTab[i]}
            </span>
          </div>
        );
      }
    }
    let list = [];

    try {
      list = (
        <Row key={k}>
          <Col md={1}>
            <Checkbox
              onCheck={() => this.onConfirmTicket(ticketsSelected.id_tickets)}
            />
          </Col>
          <Col md={2}>{ticketsSelected.realid}</Col>
          <Col md={9} className="text-left">
            {ticketsSelected.subject}
          </Col>
        </Row>
      );
    } catch (r) {}

    return (
      <Card className="cardContainer admin-graphs-contracts" ref="container">
        <h3 style={{ padding: 0, marginBottom: 0 }}>
          <span>{tabs}</span>
          <span className="icons">
            <FaStickyNote
              size={25}
              className="pointer"
              onClick={() => this.setState({ modal: true, notes: true })}
            />
            <FaTimesCircle
              size={30}
              className="pointer"
              onClick={() => this.setState({ modal: true, notes: false })}
            />
          </span>
        </h3>
        <Container fluid={true}>
          <Row>
            <Col
              md={12}
              style={{ background: '#000', paddingTop: 10, paddingBottom: 10 }}
            >
              {terms}
            </Col>
          </Row>
        </Container>
        <Dialog
          modal={false}
          open={!!modal}
          bodyStyle={{ padding: 0, overflowY: 'none' }}
        >
          <Card className="modalCard">
            <h3>
              {notes ? language.ANNOTATION : language.ATTENTION}{' '}
              <FaClose
                onClick={() => this.setState({ modal: false })}
                className="closeModal"
              />
            </h3>
            {notes ? (
              <Paper zdepth={0}>
                <Row>
                  <Col md={12}>
                    <CommonForm
                      language={language}
                      fields={[{ span: 12, name: 'note', type: 'textEditor' }]}
                      button={{
                        md: 12,
                        label: language.SAVE,
                        primary: true,
                        style: { margin: '20px 0' },
                      }}
                      onFormSubmit={(values) =>
                        this.setState({ anotation: values.note, modal: false })
                      }
                    />
                  </Col>
                </Row>
              </Paper>
            ) : (
              <Paper zdepth={0} className="text-center">
                {list.length ? (
                  <Container>
                    <Row>
                      <Col md={12}>
                        <h2>{language.CONFIRM_TICKETS_ASSESSMENT}</h2>
                      </Col>
                    </Row>
                    {list}
                  </Container>
                ) : (
                  <h2>{language.CONFIRM_CLOSE_ASSESSMENT}</h2>
                )}
                <Row>
                  <div
                    style={{
                      textAlign: 'center',
                      height: '130px',
                      padding: '10px',
                    }}
                  >
                    <RaisedButton
                      primary={true}
                      label={language.CONFIRM}
                      onClick={() => this.finishAssessment('ssh')}
                    />

                    <RaisedButton
                      primary={false}
                      label={language.CANCEL}
                      onClick={() => this.setState({ modal: false })}
                      style={{ marginLeft: 4 }}
                    />
                  </div>
                </Row>
              </Paper>
            )}
          </Card>
        </Dialog>
      </Card>
    );
  }
}

AssessmentTerminal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssessmentTerminal);
