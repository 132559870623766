import React from 'react';
/*--------------------------------------common-------------------------------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
/*--------------------------------------material-ui-------------------------------------------------------*/
import { Card } from '@material-ui/core';
/*--------------------------------------components-------------------------------------------------------*/
import { Graph_3 } from './Graph';

export default function ({ capex, opex, language }) {
  return (
    <Card>
      <AppBar title="CAPEX x OPEX" subTitle={`(${language.CONTRACTS})`} />
      <div className="content-medium pt-0">
        <ReactHighcharts config={Graph_3({ capex, opex, language })} />
      </div>
    </Card>
  );
}
