import React from 'react';

export const ServicePortalHeader = ({ children, customStyles }) => {
    return (
        <div style={customStyles} className='sp-header tw-max-w-full tw-py-2'>
            <div className='sp-header-container tw-container tw-m-auto tw-flex tw-justify-between tw-items-center'>
                {children}
            </div>
        </div>
    )
}
