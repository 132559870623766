import Actions from '@data/Actions';
import Constants from '@data/Constants';

// ----------------------------------------------------------------------

export const getCoinsService = async () => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute('get', `${APIEndpoints}/coin`, '');

  const normalizedCoins =
    data.map((coin) => {
      const { id_coin, code, name, decimal_places } = coin;

      return { id_coin, code, name, decimal_places };
    }) || [];

  return normalizedCoins;
};

export const getCoinByIdService = async ({ id }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute(
    'get',
    `${APIEndpoints}/coin/${id}`,
    ''
  );

  return data;
};

export const deleteCoinByIdService = async ({ id }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'delete',
    `${APIEndpoints}/coin/${id}`,
    ''
  );

  return result;
};

export const addCoinService = async ({ values }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;

  const normalizedValues = {
    name: values.name,
    number: Number(values.number),
    code: values.code,
    decimal_places: Number(values.decimal_places),
  };

  const result = await action.execute(
    'post',
    `${APIEndpoints}/coin`,
    '',
    normalizedValues
  );

  return result;
};

export const editCoinService = async ({ values }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'put',
    `${APIEndpoints}/coin`,
    '',
    values
  );

  return result;
};
