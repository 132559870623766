import React, { memo, useState } from 'react';
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
/*--------------------------common----------------------------*/
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import MenuTooltip from '@common/MenuTooltip';
import Dialog from '@common/Dialog';
/*--------------------------material-ui----------------------------*/
import { Checkbox } from '@material-ui/core';
/*--------------------------components----------------------------*/
import moment from 'moment'
export default function ({
	id,
	avatares = [],
	tags = [],
	name,
	dt,
	dt_end,
	commentsTotal,
	filesTotal,
	percent,
	percentColor,
	onDelete,
	onEdit,
	onView,
	project,
	activity,
	setViewOpen
}) {

	let [open, setOpen] = useState(false);
	return (

		<div type="milestone" data={JSON.stringify({ id, percent, project })} className='Card d-flex w-100'>
			{moment().isAfter(dt_end) ? (

				<Tooltip
					title={moment().isAfter(dt_end) ? 'Atrasado' : 'No Prazo'}
					position="right"
					className='content-indicator-delay'
					interactive
					arrow={true}
				>
					<div className={classnames('indicator-delay', { delay: moment().isAfter(dt_end) })}>
						<i className="ml-1 fas fa-exclamation" />
					</div>
				</Tooltip>
			) : null}
			<Dialog
				title='ATIVIDADES PREDECESSORAS '
				open={open}
				onClose={() => setOpen(false)}
			>
				{(activity.activity_antecessor || []).map((e, i) => <div style={{
					borderRadius: '10px',
					background: '#d5d5d5', padding: '4px', marginBottom: '3px', fontFamily: 'Open Sans', fontSize: '14px',
					color: '#87868a'
				}}>{i + 1 + ' - ' + e.name}</div>)}
			</Dialog>
			
			<div className='content-medium content-desc pt-2'>
				<div onClick={() => onView(id)}>
					<div>
						{tags.slice(0, tags.length > 3 ? 2 : 3).map(e => (
							<Tag
								label={e.name}
								color={e.color}
								className='mr-1 ellipis tag'
							/>
						))}
						{tags.length > 3 &&
							<Tooltip
								html={
									<div style={{ color: '#fff', fontSize: 11 }}>
										{tags.slice(2, tags.length).map(e => (
											<Tag
												label={e.name}
												color={e.color}
												className='mr-1 ellipis tag'
											/>
										))}
									</div>
								}
								position="right"
								theme="light"
								interactive
								arrow
							>

								<Tag label={'+' + (tags.length - 3)} style={{ fontWeight: 'bold' }} color='#F05E02' />
							</Tooltip>
						}
					</div>
					<Tooltip
						html={
							<div style={{ color: '#fff', fontSize: 11 }}>
								{name}
							</div>
						}
						position="left"
						interactive
					>
						<p
							className='name ellipsis-line camp-2'
							dangerouslySetInnerHTML={{ __html: '#'+id+' - '+name }}
							onClick={() => setViewOpen({ activity, project: project })
						}
						/>
					</Tooltip>

				</div>
				<div>
					<Progress current={percent} className='w-100' background={percentColor} />
					<div className='content-info -mb-1 d-flex alig-items-center justify-content-between'>
						<span>
							<i className="fas fa-calendar-day" />
							{dt}

						</span>
						<div className='d-flex alig-items-center'>
							<span onClick={()=>onEdit(id)}>
								<i className="fas fa-pencil-alt mr-3" style={{color:'var(--success)'}}/>
							</span>
							<span onClick={()=>onDelete(id)}>
								<i className="fas fa-trash-alt" style={{color:'var(--danger)'}}/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}