//https://tableless.com.br/animation-css3-criar-animacoes-css3-nunca-foi-tao-facil/

//----------default-----------------------
//https://daneden.github.io/animate.css/
import React, { Component } from 'react';
import '@common/animate.css';
import classnames from 'classnames';
export default class Animate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: true
		};
	}

	render() {
		let { anime = 'bounceInUp', className, active = true, style = {}, ...other } = this.props;
		return (
			active ? (
				<div {...other} style={{ animationFillMode: 'none', ...style }} className={classnames('animated', anime, className)}>
					{this.props.children}
				</div>
			) : this.props.children
		)
	}
}