import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Paper, MenuItem, SelectField, RadioButton, RadioButtonGroup } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import CommonPage from '@common/CommonPage';
import './AvaliationOrder.css';
import Button from '@common/Button';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Card } from '@material-ui/core';
import Constants from '@data/Constants'
import Actions from '@data/Actions'

const styles = {
  block: {
    maxWidth: 250,
  },
  radioButton: {
    marginBottom: 16,
  },
  customWidth: {
    width: 150,
  },
};

var token = '';
var avaliationObj = '';

@observer
export default class AvaliationOrder extends CommonPage {

  constructor() {
    super();
    this.sendAvaliation = this.sendAvaliation.bind(this);
    this.clickRadio = this.clickRadio.bind(this);
    
    this.state = {
      valueCall: 1,
      msgSend: false,

      valueResolved: null,
      avaliation: null,
      experience: null,
      suggestions: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }



  componentWillMount = () => {
    let url = new URL(window.location.href);
    token = url.searchParams.get('t');

    //avaliationObj= atob(token)

  }
  handleChange(valor) {

    this.setState({ valueCall: valor });
  }

  sendAvaliation = () => {
    const action = new Actions();
    let { valueResolved, avaliation, experience, suggestions } = this.state;
    var body = {
      "fk_id_order": avaliationObj.id_order,
      "resolved": valueResolved,
      "avaliation_level": avaliation,
      "fk_id_solicitant": avaliationObj.fk_id_solicitant,
      "fk_id_tech": avaliationObj.fk_id_user_tech,
      experience,
      suggestions
    }


    action.execute('post', Constants.APIRoot + '/api/orderavaliation', 'avaliation', body).then((e) => {
      setTimeout(function () { this.setState({ msgSend: true }) }.bind(this), 2000);
    }).catch(e => {
      setTimeout(function () {

        this.store.avaliation = null
        this.setState({ msgSend: true })
      }.bind(this), 2000);


    })

  }

  clickRadio = (status) => {
    this.setState({ valueResolved: status })
  }

  render() {
    let { handlePage, language, onOpenSolicitation } = this.props;
    let { avaliation, valueResolved, experience } = this.state;

    if (token) {
      //var decoded = atob(token);
      let decoded = ''
      try {
        decoded = decodeURIComponent(escape(atob(token)));
      }catch (e){
        decoded = decodeURIComponent(atob(token));  
      }
      avaliationObj = JSON.parse(decoded);
    }

    return (
      <div fluid id='AvaliationOrder'>

        <div className='my-4 d-flex align-items-center justify-content-center w-100'>
          <img
            src='/newLogoSensr.png'
            alt=""
            style={{
              width: '20%',
              minWidth: 250
            }}
          />
        </div>

        {
          this.state.msgSend == false ?

            <Card style={{ marginBottom: 20, width: 503, margin: '0 auto' }}>
              <h2 style={{ padding: 20, color: '#D79900', textAlign: 'center', marginBottom: 0, color: 'var(--primary)' }}>Avaliação da ordem</h2>
              <Row style={{ paddingLeft: '2em', paddingBottom: '2em' }}>
                <Col md={12}>
                  <p><strong style={{ color: 'var(--primary)' }}>Solicitante:</strong> <span style={{ color: 'var(--warning)' }}>{avaliationObj.solicitant}</span></p>
                  <p><strong style={{ color: 'var(--primary)' }}>Companhia:</strong> <span style={{ color: 'var(--warning)' }}>{avaliationObj.company}</span></p>
                  <p><strong style={{ color: 'var(--primary)' }}>Técnico:</strong> <span style={{ color: 'var(--warning)' }}>{avaliationObj.tech}</span></p>
                  <p><strong style={{ color: 'var(--primary)' }}>Objetivo da ordem: </strong> <span style={{ color: 'var(--warning)' }}>{avaliationObj.objective}</span></p>
                  <hr style={{ marginLeft: -32 }} />
                  <h3 style={{ color: 'var(--primary)' }} className='pr-3'>de 0 a 10, qual a sua avaliação a respeito da qualidade de solução da ordem?</h3>
                  <div className='d-flex align-items-center content-avaliation-number'>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                      <div style={{ marginTop: valueResolved == e ? -15 : 0 }} onClick={() => { this.setState({ valueResolved: e }) }}>
                        <div key={i}>{e}</div>
                      </div>
                    ))}
                  </div>

                  <h3 style={{ color: 'var(--primary)' }}>de 0 a 10, qual a sua avaliação a respeito do tempo de solução da ordem?</h3>
                  <div className='d-flex align-items-center content-avaliation-number'>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                      <div style={{ marginTop: avaliation == e ? -15 : 0 }} onClick={() => { this.setState({ avaliation: e }) }}>
                        <div key={i}>{e}</div>
                      </div>
                    ))}
                  </div>

                  <h3 style={{ color: 'var(--primary)' }}>de 0 a 10, qual foi a sua experiência com a nossa equipe de atendimento?</h3>
                  <div className='d-flex align-items-center content-avaliation-number'>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                      <div style={{ marginTop: experience == e ? -15 : 0 }} onClick={() => { this.setState({ experience: e }) }}>
                        <div key={i}>{e}</div>
                      </div>
                    ))}
                  </div>

                  <h3 style={{ color: 'var(--primary)' }}>Sugestões?</h3>
                  <textarea rows="7" placeholder='Escreva sua sugestão' onChange={e => { this.setState({ suggestions: e.target.value }) }} />

                  <div className='d-flex justify-content-end mt-3 mr-4 pr-2'>
                    <Button label="Enviar" onClick={() => this.sendAvaliation()} color = "primary" />
                  </div>

                </Col>
              </Row>
            </Card>

            :
            this.store.avaliation ?
              <div>
                <Row><p style={{ background: "rgb(131, 199, 137)", padding: "1em", color: "#fff" }} >Obrigado, a pesquisa de satisfação foi enviada com sucesso!</p></Row>
              </div> :
              <div>
                <Row><p style={{ background: "red", padding: "1em", color: "#fff" }} >Obrigado,  Operação não foi realizada, avaliação já foi efetuada!</p></Row>
              </div>

        }
        <br />

      </div>
    );
  }
}
