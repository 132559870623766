import React, { Fragment, useEffect, useState } from 'react';
import Button from '@common/Button';
import classnames from 'classnames';
import { Menu, MenuItem } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';

const { language } = Store;

export default function ({
   onChange,
   value: _value,
   options = [],
   disabled = false,
   fontSize = 13,
   labelStyle={}
}) {
   let [value, setValue] = useState(null);
   let [anchorEl, setAnchorEl] = useState(null);

   useEffect(() => {
      if (value === null) {
         setValue(_value)
      }
   }, [_value])

   //console.log('carregando month', monthSelected);

   return (
      <Fragment>
         <div className='d-flex align-items-center'>
            <Button
               icon='fas fa-chevron-left'
               label={language.PREVIOUS}
               color='success'
               variant='circle'
               outlined
               style={{
                  border: 'none',
                  fontSize: 12
               }}
               onClick={() => {
                  setValue(v => {
                     let index = options.findIndex(e => e.value == v);
                     let _index = index != -1 ? Math.max(0, index - 1) : 0;
                     let val = options[_index].value;
                     onChange(val);
                     return val;
                  })

               }}
               disabled={disabled}
            />

            <Tooltip
               html={
                  <div style={{ color: 'white' }}>{(options.find(e => e.value == value) || {}).label}</div>
               }
               arrow={true}
               position='left'
               theme='dark'
               style={{ width: '100%' }}
               className='ellipsis'
            >
               <h3
                  aria-owns={anchorEl ? 'long-menu' : undefined}
                  onClick={evt => setAnchorEl(evt.currentTarget)}
                  className='label-month-filter px-3 ellipsis'
                  style={{ width: '100%', textAlign: 'center', fontSize, ...labelStyle }}
               >
                  {(options.find(e => e.value == value) || {}).label}
               </h3>
            </Tooltip>
            <Button
               icon='fas fa-chevron-right'
               label={language.NEXT}
               color='warning'
               variant='circle'
               outlined
               style={{
                  border: 'none',
                  fontSize: 12
               }}
               onClick={() => {
                  setValue(v => {
                     let index = options.findIndex(e => e.value == v);
                     let _index = index != -1 ? Math.min(options.length - 1, index + 1) : 0;
                     let val = options[_index].value;
                     onChange(val);
                     return val;
                  })
               }}
               disabled={disabled}
            />
         </div>
         <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={!!anchorEl && !disabled}
            onClose={() => setAnchorEl(null)}

         >
            {options.map((option, i) => (
               <MenuItem
                  key={option}
                  onClick={() => {
                     setAnchorEl(null);
                     onChange(option.value)
                     setValue(option.value)
                  }}
                  style={value == option.value ? { fontWeight: 'bold' } : {}}
               >
                  {option.label}
               </MenuItem>
            ))}
         </Menu>
      </Fragment>
   )
}