import React, { useState, useReducer } from 'react';
import { Tooltip } from 'react-tippy';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import CommonForm from '../../common/CommonForm2';

import BarBlue from '@images/BarBlue.png';

export default function ({ open, onClose, onSubmit, language }) {
  let [form, setForm] = useReducer((state, value = {}) => {
    return { ...state, ...value };
  }, {});
  let [ref, setRef] = useState(null);
  return (
    <Dialog
      PaperProps={{
        id: 'CapacityPlanForm',
      }}
      open={open}
      onClose={onClose}
      contentTitle={{
        style: { width: '100%' },
      }}
      titleContentProps={{
        style: {
          width: 'calc(100% - 60px)',
        },
      }}
      title={
        <div className="w-100 bar" style={{ zoom: 0.8 }} zoom={0.8}>
          <CommonForm
            individual
            values={
              {
                /*subject: form.subject*/
              }
            }
            alert={false}
            fields={[
              {
                type: 'text',
                name: 'subject',
                label: language.SUBJECT,
                className: 'subject-ticket',
                required: true,
              },
            ]}
            onRef={(r) => setRef(r)}
            onChangeField={(f, v) => setForm({ [f.name]: v })}
          />
        </div>
      }
    >
      <CommonForm
        fields={[
          {
            type: 'btnGroup',
            name: 'monitor_type',
            label: language.TYPE_OF_MONITORING,
            options: [
              { value: 1, label: language.LINK },
              { value: 2, label: language.DESKTOP },
              { value: 3, label: language.SERVER },
              { value: 4, label: language.NONE },
            ],
          },
          {
            type: 'element',
            content: <hr />,
          },
          {
            col: 4,
            type: 'select',
            name: 'type',
            label: language.TYPE,
            options: [],
          },
          {
            col: 4,
            type: 'select',
            name: 'company',
            label: language.COMPANY,
            options: [],
          },
          {
            col: 4,
            type: 'select',
            name: 'department',
            label: language.DEPARTMENT,
            options: [],
          },
          {
            col: 6,
            type: 'listCheckbox',
            name: 'users',
            label: language.USERS,
            options: [],
          },
          {
            col: 6,
            type: 'listCheckbox',
            name: 'contracts',
            label: language.CONTRACTS,
            options: [],
          },
          {
            type: 'element',
            content: <hr />,
          },
          {
            type: 'btnGroup',
            name: 'conection',
            label: language.CONNECTION,
            options: [
              { value: 1, label: 'RDP' },
              { value: 2, label: 'VNC' },
              { value: 3, label: 'SSH' },
            ],
          },
        ]}
        button={{
          fluid: true,
          style: {
            height: 40,
          },
        }}
        _onSubmit={() => {
          ref.submit();
        }}
        onChangeField={(f, v) => setForm({ [f.name]: v })}
        onSubmit={(v) => {
          if (!!form.subject) {
            onSubmit(form);
          }
        }}
      />
    </Dialog>
  );
}
