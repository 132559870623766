import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import { Card, Chip, Switch } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';

import Button from '../../common/Button';
import { filterObj } from '../../common/Func';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';

@observer
export default class CatalogList extends Component {
  render() {
    let {
      language,
      handlePage,
      redirect,
      catalogs,
      values,
      onFormSubmit,
      groups,
      handlePageDelete,
      callPageSelectFilter,
      totalcatalogs,
      currentPage,
    } = this.props;

    let col = [
      { key: 'id', label: language.ID, style: { width: 10 } },
      { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
      {
        key: 'description',
        label: language.DESCRIPTION,
        style: { textAlign: 'center' },
      },
      {
        key: 'dtCad',
        label: language.DATE_CAD,
        style: { textAlign: 'center' },
      },
      {
        key: 'categories',
        label: language.CATEGORIES,
        style: { width: 19, textAlign: 'center' },
        search: false
      },
      {
        key: 'id_group_users',
        label: language.GROUP_TECH,
        style: { textAlign: 'center' },
      },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 60, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data = catalogs.map((obj) => {
      return {
        id: obj.id_catalog_service,
        name: obj.name,
        description: obj.description,
        dtCad: moment(obj.dt_cad).format('L'),
        categories: (
          <Tooltip
            interactive
            html={<div className="view-catalogs-tooltip">{obj.categories}</div>}
            arrow={true}
            position="left"
            theme="light"
          >
            <i
              class="fas fa-eye"
              style={{ fontSize: 20, color: 'var(--warning)' }}
            ></i>
          </Tooltip>
        ),

        id_group_users: obj.name_group,

        action: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.NEW_ASSIGNMENT}
                    color="primary"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    onClick={() => handlePage('assigment', obj)}
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12 }}
                    size={0.7}
                    className="mx-2"
                    onClick={() => handlePage('new', obj)}
                  />
                  <Button
                    variant="circle"
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 14 }}
                    size={0.7}
                    _loading={'del-catalog-1' + obj.id_catalog_service}
                    onClick={() => handlePageDelete(true, obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          index="CatalogList"
          data={data}
          onClearAllFilter={this.props.onClearAllFilter}
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          searchColumnEvent={(v) => callPageSelectFilter(0, v, 75, 'filter')}
          countTotal={totalcatalogs}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          loadSearch={true}
          currentPage={currentPage}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          beforeBar={
            <Button
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.NEW_CATALOG}
              primary={true}
              onClick={() => handlePage('new')}
            />
          }
        />
      </Fragment>
    );
  }
}
