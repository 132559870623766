import React, { Component, Fragment } from 'react';
import './styles.css';
import classnames from 'classnames';
export default class Progress extends Component{
	arredonda = (n) => {
	    return Math.ceil(n / 100) * 100;
	}
	render(){
		const props = this.props;
		let {currentProps={}} = this.props;

		var background = props.low?props.low.color:(props.background||props.color);

		if(props.mid && parseInt(props.current)>=props.mid.value){
			var background = props.mid.color;
		}
		if(props.high && parseInt(props.current)>=props.high.value){
			var background = props.high.color;
		}
		
		if(!!props.arredonda&&props.current){
			var width = ((100 * props.current) / this.arredonda(props.current));
		}else{
			var width = props.current;
		}
		
		return(
			<div className={classnames( "progress", this.props.className)} style={{...this.props.style, padding:this.props.padding?1:0}}>
			  	<div 
				  	className={classnames("progress-bar", currentProps.className)} 
				  	role="progressbar" 
				  	{...currentProps}
				  	style={{"width": width+'%', background:background, ...(!width?{color:(this.props.emptyColor?background:'#616161')}:{}), ...currentProps.style}} 
				  	aria-valuenow={props.current} 
				  	aria-valuemin="0" 
				  	aria-valuemax="100"
				>
					{[undefined, false].includes(props.hiddenText) &&
						<span>
							{props.current}{[undefined, true].includes(props.symbol) && '%'}
						</span>
					}
				</div>
			</div>
		)
	}
}