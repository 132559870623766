import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import Animate from '../../common/Animate';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';

import AnswerList from './AnswerList';
import AnswerForm from './AnswerForm';

@observer
export default class AnswerContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.ANSWER, 'answer');
  }

  onFormSubmit(values) {
    let method = values.hasOwnProperty('id_answer') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_answer')
      ? this.APIEndpoints.ANSWER + '/' + values.id_answer
      : this.APIEndpoints.ANSWER;
    this.action.execute(method, url, 'answer', values, 'id_answer');
    this.handlePage('list');
  }

  onDelete() {
    let { values } = this.state;

    this.action.execute(
      'post',
      this.APIEndpoints.ANSWER + '/' + 'removeanswer',
      'answer',
      values,
      'id_mail'
    );
    this.handlePage('list');
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;

    let { language, mails, companies, groups, contracts, mailContract } =
      this.store;
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <AnswerList
          redirect={this.redirect2}
          language={language}
          handlePage={this.handlePage}
          store={this.store}
          configurationProps={configurationProps}
        />

        {page === 'form' ? (
          <AnswerForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.name}
          />
        ) : null}
      </Fragment>
    );
  }
}
