import React, { Component } from 'react';
import moment from 'moment';
import { Dialog, Card, Paper } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import MdLocalPrintShop from 'react-icons/lib/md/local-print-shop';

import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import SimplesAlert from '../../common/SimplesAlert';

export default class AuditTerminal extends Component {
  constructor() {
    super();
    this.onPrint = this.onPrint.bind(this);
  }

  onPrint() {
    var content = document.getElementById('print');
    var pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  render() {
    let { language, handlePage, assessment, audit } = this.props;

    let col = [
      { key: 'date', label: language.DATE, style: { width: 80 } },
      { key: 'cmd', label: language.TERMINAL },
    ];

    let data = audit.map((a) => ({
      date: moment(a.dt_cad).format('L LTS'),
      cmd: a.value_logs,
    }));

    return (
      <PageLayout
        icon={<div className="auditIcon" style={{ width: 44, height: 44 }} />}
        title={language.REPORTS}
        subTitle={language.DASHBOARD_PAGE.GCF + '/'}
      >
        <Container fluid>
          <div className="d-flex align-items-center">
            <SimplesAlert
              viewIcon={false}
              variant="success"
              message={language.DEVICE + ': ' + assessment.device.name}
              style={{ marginRight: 10 }}
            />
            <SimplesAlert
              viewIcon={false}
              variant="success"
              message={language.TECH + ': ' + assessment.tech.name}
            />
          </div>
          <CommonTable
            title={''}
            index="AuditTerminal"
            data={data}
            columns={col}
            language={language}
            qtdRow={data.length}
            hideFooterToolbar={true}
          />
        </Container>
      </PageLayout>
    );
  }
}
