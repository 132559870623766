import React from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../../../common/CommonForm2';

const UserStartAttendance = ({
  APIEndpoints,
  setOpenUserStart,
  action,
  Attendance,
  Store,
  updateAttendance,
}) => {
  const changeStartUserAttendAttend = (values) => {
    values.user = Store.getUserLoged();
    values.id_attendance_sac = Attendance.id_attendance_sac;
    action
      .execute('put', APIEndpoints.SAC_ATTENDANCE + '/userStart', '', values)
      .then((res) => {
        updateAttendance();
        setOpenUserStart(false);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={() => setOpenUserStart(false)}
      title="Técnico responsável"
      maxWidth="sm"
      PaperProps={{
        style: {
          height: '60%',
        },
      }}
    >
      <CommonForm2
        fields={[
          {
            span: 12,
            type: 'autocomplete',
            name: 'user_start',
            label: 'Técnico responsável',
            className: 'tech-group-ticket',
            required: true,
            method: 'POST',
            route: `${APIEndpoints.USER}/filter`,
            fieldquery: 'search',
            textinit:
              Attendance.name_user_start != ''
                ? Attendance.name_user_start
                : 'Selecione',
            fieldvaluedb: 'id_user',
            fieldlabeldb: 'name',
            idinit: Attendance.name_user_start,
            routeAll: `${APIEndpoints.USER}/filter/all?`,
            detail: true,
            methodGetAll: 'POST',
            routeGetCount: `${APIEndpoints.USER}/active/count/all`,
            filterAll: {},
          },
        ]}
        onSubmit={changeStartUserAttendAttend}
      />
    </Dialog>
  );
};

export default UserStartAttendance;
