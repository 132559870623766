import React from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../../../common/CommonForm2';

const GroupAttendance = ({
  APIEndpoints,
  setOpenGroupTech,
  action,
  Attendance,
  Store,
  updateAttendance,
}) => {
  const changeGroupTechAttend = (values) => {
    values.user = Store.getUserLoged();
    values.id_attendance_sac = Attendance.id_attendance_sac;
    action
      .execute('put', APIEndpoints.SAC_ATTENDANCE + '/group', '', values)
      .then((res) => {
        updateAttendance();
        setOpenGroupTech(false);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={() => setOpenGroupTech(false)}
      title="Grupo técnico"
      maxWidth="sm"
      PaperProps={{
        style: {
          height: '60%',
        },
      }}
    >
      <CommonForm2
        fields={[
          {
            span: 12,
            type: 'autocomplete',
            name: 'fk_id_group_tech',
            label: 'Grupo Técnico',
            className: 'tech-group-ticket',
            required: true,
            method: 'POST',
            route: `${APIEndpoints.GROUP}/filter`,
            fieldquery: 'search',
            textinit:
              Attendance.name_group_attend != ''
                ? Attendance.name_group_attend
                : 'Selecione',
            fieldvaluedb: 'id_group_users',
            fieldlabeldb: 'name',
            idinit: Attendance.fk_id_group_tech,
            routeAll: `${APIEndpoints.GROUP}/all?`,
            detail: true,
            methodGetAll: 'POST',
            routeGetCount: `${APIEndpoints.GROUP}/count`,
            filterAll: {},
          },
        ]}
        onSubmit={changeGroupTechAttend}
      />
    </Dialog>
  );
};

export default GroupAttendance;
