import React from 'react';
/*-------------------common---------------------------*/
import AppBar from '@common/AppBar2';
import CommonTable from '@common/CommonTable';
import Loading from '@common/Loading';
/*-------------------material-ui---------------------------*/
import { Card } from '@material-ui/core';
/*-------------------pages---------------------------*/

export default function ({
  news = {},
  open = {},
  attendance = {},
  resolved = {},
  closed = {},
  reject = {},
  language,
}) {
  let chekLoading = [news, open, attendance, resolved, closed]
    .flatMap((e) => [e.generated, e.late])
    .some(Boolean);
  return (
    <Card id="TicketQuantity">
      <AppBar title={language.QUANTITY_OF_TICKETS} />
      <div className="content-table-GD">
        <CommonTable
          bar={false}
          pagination={false}
          paper={false}
          orderColumn={false}
          col={[
            {
              key: 'ticket',
              label: language.TICKETS,
              style: { paddingLeft: '1.2rem' },
            },
            { key: 'generated', label: language.GENERATED_PLURAL },
            { key: 'late', label: language.LATE },
          ]}
          data={[
            {
              ticket: (
                <div className="content-item-GD c-1 pl-3">
                  <div></div>
                  <span>{language.NEW_PLURAL}</span>
                </div>
              ),
              generated: news.generated,
              late: news.late,
            },
            {
              ticket: (
                <div className="content-item-GD c-2 pl-3">
                  <div></div>
                  <span>{language.TICKET_STATUS.OPENS}</span>
                </div>
              ),
              generated: open.generated,
              late: open.late,
            },
            {
              ticket: (
                <div className="content-item-GD c-3 pl-3">
                  <div></div>
                  <span>{language.TICKET_STATUS.IN_PROGRESS}</span>
                </div>
              ),
              generated: attendance.generated,
              late: attendance.late,
            },
            {
              ticket: (
                <div className="content-item-GD c-4 pl-3">
                  <div></div>
                  <span>{language.TICKET_STATUS.RESOLVED}</span>
                </div>
              ),
              generated: resolved.generated,
              late: resolved.late,
            },
            {
              ticket: (
                <div className="content-item-GD c-5 pl-3">
                  <div></div>
                  <span>{language.TICKET_STATUS.CLOSED}</span>
                </div>
              ),
              generated: closed.generated,
              late: closed.late,
            },
            {
              ticket: (
                <div className="content-item-GD c-6 pl-3">
                  <div></div>
                  <span>{language.STATUS_LOG_REJECTED}</span>
                </div>
              ),
              generated: reject.generated,
              late: reject.late,
            },
          ]}
        />
      </div>
    </Card>
  );
}
