import React from 'react';
import CommonTable from '@common/CommonTable';
import Store from '@data/Store';
import { differenceBetweenTime } from '../../utils/formatDeadline';
import AttachFile from '@common/AttachFile';
import moment from 'moment';
const language = Store.language;

const StepsConcludedPageAttendance = ({ Attendance, onDownFileStep }) => {
  const configLayoutSteps = (attendanceSteps) => {
    return attendanceSteps
      ? attendanceSteps
          .filter((a) => a.stage === 'Conclued' || a.stage === 'Canceled')
          .map((step) => {
            const obj = {};
            obj.name = step.name;
            obj.stage = step.stage === 'Conclued' ? language.CONCLUDED : language.CANCELED,
            obj.responsible = step.responsible;
            obj.name_department = step.name_department;
            obj.subject = step.subject;
            obj.dt_start_first = step.dt_start_first ? moment(step.dt_start_first).format('DD/MM/YYYY HH:mm') : '';
            obj.dt_resolved = step.stage === 'Canceled' ? '' : moment(step.dt_resolved).format('DD/MM/YYYY HH:mm');
            (obj.sla_time = (
              step.stage === 'Canceled' ? '' : (
              <div className="d-flex align-items-center">
                {step.sla_time && (
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      background:
                      differenceBetweenTime(
                          moment(step.dt_resolved),
                          moment(step.sla_time)
                        ) >= 0
                          ? '#3b82f6'
                          : '#f52626cf',
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  />
                )}
                {step.sla_time ? moment(step.sla_time).format('DD/MM/YYYY HH:mm') : ''}
              </div>
              )
            )),
            (obj.attach = (
              <div>
                {Attendance.files_steps
                  ? Attendance.files_steps
                  .filter((s) => s.fk_id_step === step.id_sac_task)
                  .map((f) => (
                    <AttachFile
                      label={f.file}
                      onClick={() =>
                        onDownFileStep(
                          f.id_attachment_attendance_file,
                          f.file
                        )
                      }
                      style={{ maxWidth: '100%' }}
                    />
                    ))
                  : null}
              </div>
            ));

            return obj;
          })
      : [];
  };

  return (
    <CommonTable
      col={[
        { key: 'name', label: language.STEP },
        { key: 'stage', label: language.STATE },
        { key: 'responsible', label: language.RESPONSIBLE },
        { key: 'name_department', label: language.RESPONSIBLE_DEPARTMENT },
        { key: 'subject', label: language.SUBJECT },
        { key: 'dt_start_first', label: language.INITIAL_DATE },
        { key: 'dt_resolved', label: language.CONCLUDED_DATE },
        { key: 'sla_time', label: language.SLA_DATE },
        { key: 'attach', label: language.ATTACHMENTS },
      ]}
      data={configLayoutSteps(Attendance.steps)}
    />
  );
};

export default StepsConcludedPageAttendance;
