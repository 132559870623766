import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-grid-system';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Chip from '@common/RaisedChip';
import Button from '@common/Button';
import history2 from '@common/history2';
import classnames from 'classnames';
import { wrap } from 'highcharts';
const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'var(--primary)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'var(--primary)',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: 'var(--primary)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'var(--primary)'
        }
      }
    }
  }
});
const styles = {
  TitleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30
  },
  TitleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%'
  },
  Title: {
    fontSize: 21,
    fontWeight: 900,
    margin: 0,
    color: 'var(--primary)',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    width: '100%'    
  },
  TitleIcon: {
    height: 34,
    width: 34,
    marginRight: 15
  },
  SubTitle: {
    color: 'var(--primary)',
    fontSize: 9,
  },
  RightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginTop: 25
    marginTop: -5
  },
};

const PageLayoutPropTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  rightElements: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const PageLayout = ({ icon, fa, style = {}, title, subTitle, back = true, rightElements = [], subHeader, children, contentProps = {}, customCss, smallTitle }) => {
  let customCSS_ = customCss ? customCss[0] : []
  return (
    <React.Fragment>
      <Grid container id='pageLayout' spacing={2} {...contentProps} style={{ paddingTop: 22, backgroundColor: '#EAF3F4', zIndex: 1, ...contentProps.style, ...customCSS_ }}>
        <Grid item xs={12} md={smallTitle ? 2 : !!rightElements.length ? 3 : 12} className={classnames({ '-mt-3': subTitle })}>
          <div style={styles.TitleContainer}>
            {back &&
              <Button
                icon='fas fa-arrow-left'
                color='warning'
                variant='circle'
                outlined
                style={{ border: 'none', fontSize: 27 }}
                className='mr-3'
                onClick={(typeof back) != 'function' ? (() => history2.goBack()) : back}
              />
            }
            <div>
              {subTitle &&
                <span style={styles.SubTitle}>{subTitle}</span>
              }
              <h1 style={styles.Title}>{title}</h1>
            </div>
          </div>
        </Grid>
        {!!rightElements.length &&
          <Grid item xs={12} md={9}>

            <div style={{ ...styles.RightContainer, ...style, width : smallTitle ? '125%' : '' }}>
              <MuiThemeProvider theme={theme}>
                {rightElements.map((element, index) => (
                  <element.type key={index} {...element.props} />
                ))}
              </MuiThemeProvider>
            </div>

          </Grid>
        }
      </Grid>
      <Grid container spacing={2} className='mt-4'>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </React.Fragment>
  )
};

PageLayout.defaultProps = {
  rightElements: <div />
};

PageLayout.propTypes = PageLayoutPropTypes;

export default PageLayout;
