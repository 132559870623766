import React from 'react';
import CommonPage from '@common/CommonPage';
import SnmpList from './snmpList';
import CommonDelete from '../../common/CommonDelete';
import {testRoute} from '@common/Func';
import Animate from '@common/Animate';
import { observer } from 'mobx-react';

@observer
export default class SnmpContainer extends CommonPage {
	constructor(props){
		super(props);
		this.state = {
			index: 0,
			page: 'list',
			clone: '',
			values:{},
			id_brands:0,
			id_device:0
		}
	}
	componentWillMount(){
		this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
		this.action.execute('get', this.APIEndpoints.SNMP+'/getmanufactured', 'manufactures');
		this.action.execute('get', this.APIEndpoints.SNMP+'/getlistbrand', 'brands');
		
		let history  = this.history2;
		let {state, pathname, search} = history.location;
		var q = (new URLSearchParams(search));

		var id_brands = q.get('id_brands');
		var id_device = q.get('id_device');

		this.setState({id_brands, id_device});
		if(id_brands){
			this.getSnmps(id_brands);
		}else{
			this.getSnmps();
		}
	}
	getSnmps = (id_brands)=>{
		if(id_brands){
			//console.log(id_brands)
			this.action.execute('get', this.APIEndpoints.SNMP+'/getbrandsid/'+parseInt(id_brands), 'snmps');
		}else{
			this.action.execute('get', this.APIEndpoints.SNMP+'/getallsnmps', 'snmps');
		}
	}
	handlePage = (page, edit) => {
	  this.setState({ page: page, values: edit });
	}
	onDelete = ()=> {
	  let {values} = this.state;
	  this.action.execute('delete', this.APIEndpoints.SNMP+'/deletesnmp/'+values.id, 'snmps', null, 'id');
	  this.handlePage('list');
	}
	onFormSubmit = (values, type)=>{

		var route = this.APIEndpoints.SNMP;
		var model = 'manufactures';
	  if(type==1){
	    route += '/savemanufacture';
	  }else if(type==2){
	  	route += '/savebrand';
	  }
	  if(values && type){
	  	this.action.execute('post', route, model, values);
	  }
	  this.handlePage('list')
	 
	}
	oidFormSubmit = (values)=>{

		if(this.state.id_brands&&this.state.id_device){
			this.action.execute('post', this.APIEndpoints.SNMP+'/savebrandsmonitored', 'snmp', values);
		}else{

			if(values.id_brands!=0){
				this.action.execute('post', this.APIEndpoints.SNMP+'/savesnmp', 'snmp', values);
			}
		}
		this.handlePage('list')
	}
	submitSnmpEdit = (values)=>{
		this.action.execute('put', this.APIEndpoints.SNMP+'/editsnmp', 'snmp', values, 'id');
		this.handlePage('list')
	}

	render(){
		let {language, companies, users, devicetypes, manufactures, snmps, brands} = this.store;
		let {page, loading, id_brands, id_device} = this.state;
		let history  = this.history2;

		let PAGE = history.location.pathname;
		
		var values;
		
		return(
			<div>
				<SnmpList
					language={language}
					companies={companies}
					redirect={this.redirect2}
					handlePage={this.handlePage}
					requestLoading={!!loading}
					manufactures={manufactures}
					onFormSubmit={this.onFormSubmit}
					oidFormSubmit={this.oidFormSubmit}
					snmps={snmps}
					brands={brands}
					submitSnmpEdit={this.submitSnmpEdit}
					id_brands={id_brands}
					id_device={id_device}
				/>
				{/*testRoute(/\/dash\/snmp\/(new|edit|clone)/, PAGE) &&
					<Animate>
					<SnmpForm
					  language={language}
					  handlePage={this.handlePage}
					  onFormSubmit={this.onFormSubmit}
					  values={values}
					  companies={companies}
					  users={users}
					  devicetypes={devicetypes}
					  clone={testRoute('/dash/snmp/clone', PAGE)?'clone':''}
					  APIEndpoints={this.APIEndpoints}
					  redirect={this.redirect2}
					/>
					</Animate>
				*/}
				{page === 'remove' ? (
				  <CommonDelete
				    language={language}
				    closeModal={() => this.handlePage('list')}
				    onDelete={this.onDelete}
				    message={this.state.values.device_oids}
				  />
				) : null}
			</div>
		)
	}
}