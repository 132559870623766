import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

export default function ServicePortalModalWrapper({
  fullWidth = true,
  maxWidth = 'sm',
  isOpen = false,
  title,
  onClose,
  onConfirm,
  children,
  buttonCloseLabel = 'Close',
  buttonConfirmLabel = 'Confirm',
  disableCloseButton = false,
  disableConfirmButton = false,
  enableCloseIcon = false,
  customButtons,
}) {
  const [isOpenControl, setIsOpenControl] = useState(isOpen);

  useEffect(() => {
    setIsOpenControl(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsOpenControl(false);
    onClose && onClose();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={isOpenControl}
      onClose={handleClose}
    >
      <DialogTitle>
        {title}
        {enableCloseIcon ? (
          <div className="tw-absolute tw-top-0 tw-right-0 ">
            <IconButton
              aria-label="close"
              className="tw-text-gray-500"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : null}
      </DialogTitle>

      {/* closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }, */}

      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div
          className="tw-flex tw-items-center tw-justify-end tw-w-full tw-h-full tw-px-4 tw-py-2 tw-rounded tw-gap-4"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
        >
          {!!customButtons && customButtons}
          {!disableCloseButton && (
            <Button onClick={handleClose} color="default">
              {buttonCloseLabel}
            </Button>
          )}
          {!disableConfirmButton && (
            <Button onClick={handleConfirm} color="primary" variant="contained">
              {buttonConfirmLabel}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}
