import React, { useState, useEffect } from 'react';
import CommonForm from '../../common/CommonForm2';
import Constants from '../../data/Constants';
import Store from '@data/Store';
import Actions from '@data/Actions';

const action = new Actions();
const { language } = Store;

const retroOpts = {
  created: language.CREATED_IN
}

const initialValue = {
  id_slaparam: '',
  sla_retro_start: false,
  sla_retro_pause: false,
  sla_retro_defined: ''
};

const FormStart = (props) => {
  const [values, setValues] = useState(initialValue);

  useEffect(() => {
    const { id_slaparam, sla_retro_defined, sla_retro_pause, sla_retro_start } =
      props.sla.value;
    setValues({
      id_slaparam,
      sla_retro_start,
      sla_retro_pause,
      sla_retro_defined,
    });
  }, []);

  const onSubmit = (val) => {
    action
      .execute('put', `${Constants.APIEndpoints.SLA_WORK}/slaparam/${val.id_slaparam}/condition`, '', {
        ...val,
        status: 'start',
      })
      .then((res) => {
        props.loadSla();
      });
  };

  return (
    <div style={{ marginBottom: '20px', marginTop: '20px' }}>
      <CommonForm
        values={values}
        fields={[
          {
            col: 3,
            name: 'sla_retro_start',
            label: language.RETROSPECTIVE_START,
            type: 'switch',
          },
          {
            col: 9,
            name: 'sla_retro_defined',
            label: language.DEFINE_LIKE,
            type: 'select',
            options: Object.keys(retroOpts).map((r) => ({
              label: retroOpts[r],
              value: r,
            })),
          },
          {
            col: 3,
            name: 'sla_retro_pause',
            label: language.RETROACTIVE_BREAK,
            type: 'switch',
          },
        ]}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default FormStart;
