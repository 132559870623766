import { PowerSettingsNew as PowerSettingsNewIcon } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

// ------------------------------------------------------------

import InfoMinimized from './InfoMinimized';

// ------------------------------------------------------------

export default function ({ language, _device, netStatsTx, uptime, netStats }) {
  const [device, setDevice] = useState({});
  const [sysInfo, setSysInfo] = useState({});
  const [agentInfoDt, setAgentInfoDt] = useState({});
  const [open, setOpen] = useState(true);

  useEffect(() => {
    function load() {
      if (_device) {
        return new Promise((resolve) => {
          resolve(_device);
        });
      }
    }
    load().then((x) => {
      setDevice(x);

      let agentInfoDtLoad =
        x.values && x.values['agent-info-dt'] ? x.values['agent-info-dt'] : {};
      let sysInfoLoad =
        x.values && x.values['sys-info'] ? x.values['sys-info'] : {};
      if (sysInfoLoad) {
        //console.log('sysinfo', sysInfoLoad);
        setSysInfo(sysInfoLoad);
      }
      if (agentInfoDtLoad) {
        //console.log('agentInfoDtLoad', agentInfoDtLoad);
        setAgentInfoDt(agentInfoDtLoad);
      }
    });
  }, [_device, uptime]);

  return (
    <div className="AgentCard content-medium">
      <div className="header d-flex align-items-center justify-content-between">
        <h2>
          <i className="fas fa-file-alt icon" />
          {device.type === 'server' && 'Servidor'}
          {device.type === 'desktop' && 'Desktop'}
        </h2>
        <div className="d-flex justify-content-end">
          {!open && (
            <div style={{ width: 200 }}>
              <InfoMinimized netStatsTx={netStatsTx} netStats={netStats} />
            </div>
          )}
          <a href="#" onClick={() => setOpen((open) => !open)}>
            {open ? 'Ver menos' : 'Ver mais'}
          </a>
        </div>
      </div>
      {open && (
        <Grid container spacing={16} className="pl-4 body-collapsis">
          <Grid item xs={6} className="content-info">
            {[
              { label: 'Nome do host', value: device.name },
              {
                label: 'IP',
                value: `${device.ip}${device.port ? ':' + device.port : ''}`,
              },
              { label: 'Descrição', value: sysInfo.description || '' },
            ].map((e) => (
              <Fragment>
                <span className="label">{e.label}</span>
                <span className="value">{e.value}</span>
              </Fragment>
            ))}
          </Grid>
          <Grid item xs={6}>
            <div className="content-info-tab">
              <div>
                <i className="fab fa-microsoft" />
                <span>{sysInfo.vendor || ''}</span>
              </div>
              <div>
                <span>{language.VERSION}</span>
                <span>{sysInfo.vendorVersion || ''}</span>
              </div>
              <div>
                <span>{language.AGENT_VERSION}</span>
                <span>{sysInfo.agentVersion || ''}</span>
              </div>
            </div>
            <div className="content-info">
              <span className="label" style={{ textAlign: 'right' }}>
                {language.UPTIME}
              </span>
              <span className="value d-flex align-items-center justify-content-end">
                <PowerSettingsNewIcon
                  style={{ color: 'var(--success)', fontSize: 18 }}
                  className="mr-1"
                />
                {uptime}
              </span>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
