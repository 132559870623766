import React, { useState, useEffect } from 'react';
import ListColumn from '@common/ListColumn';
import CommonTable from '@common/CommonTable';
import PageLayout from '@common/PageLayout';
import { Card, Grid, Chip } from '@material-ui/core';
import CommonPage from '@common/CommonPage';
import RaisedButton from '../../common/RaisedButton';
import './Inventorymac.css';
import Store from '@data/Store';

const { language } = Store;

const toHtml = (thing,v) => {
  const htmlEntities = string => {
    // Makes text displayable in browsers
    return string
      .replace(/&/g, '&amp;')
      .replace(/\\"/g, '&bsol;&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  };
  const replacer = (match, p1, p2, p3, p4) => {
    // Converts the four parenthesized capture groups into HTML
    const part = { indent: p1, key: p2, value: p3, end: p4 };
    const key = '<span class=json-key>';
    const val = '<span class=json-value>';
    const bool = '<span class=json-boolean>';
    const str = '<span class=json-string>';
    const isBool = ['true', 'false'].includes(part.value);
    const valSpan = /^"/.test(part.value) ? str : isBool ? bool : val;
    const findName = /"([\w]+)": |(.*): /;
    const indentHtml = part.indent || '';
    const keyName = part.key && part.key.replace(findName, '$1$2');
    const keyHtml = part.key ? key + keyName + '</span>: ' : '';

     try{

       if(v==true && 
          part.value != undefined && 
          !isNaN(parseFloat(part.value)) && 
          isFinite(part.value)
          ){ 
       
        let v1  = parseFloat(part.value)/1024
        let v2 = parseFloat(v1)/1024
        let v3 = parseFloat(v2)/1024
        part.value= v3.toFixed(3).toString()+' Gb'
        if(part.value=='NaN Gb'){
          part.value=' ';
        }
       }
     }catch(e){
      part.value=''
     }

    const valueHtml = part.value
      ? valSpan + part.value.replace(/"/g, '') + '</span>'
      : '';
    const endHtml = part.end || '';
    return indentHtml + keyHtml + valueHtml;
  };
  const jsonLine = /^( *)("[^"]+": )?("[^"]*"|[\w.+-]*)?([{}[\],]*)?$/gm;
  return htmlEntities(JSON.stringify(thing, null, 3))
    .replace(jsonLine, replacer)
    .replace(/{|}/g, '');
};
export default class InventoryMacbookList extends CommonPage {
  constructor() {
    super();
    this.state = {
      nome: '',
      distro: '',
      versao: '',
      kernel: ''
    };
  }

  componentDidMount() {
    let { values } = this.props;
    //console.log(values);
    if (values) {
      this.action.execute(
        'get',
        `${this.APIEndpoints.INVENTORY}/${values.id_inventory_collector}/mac`,
        'softwares'
      );
    }
  }

  render() {
    let item = [];
    let item_ = [];
    let osInfo;
    
    this.store.softwares
      .filter(h => h.command != 'software')
      .map(g => {
        if (g.command === 'osInfo') {
          this.state.nome = g.value.hostname;
          this.state.distro = g.value.distro + ' - ' + g.value.codename;
          this.state.versao = g.value.release;
          this.state.kernel = g.value.kernel;
        }
        if (g.command === 'os') {
          if (g.value.csname != undefined) {
            this.state.nome = g.value.csname;
            this.state.distro = g.value.caption;
            this.state.versao = g.value.version;
            this.state.kernel = '';
          }
        }

        if (item_.filter(h => h.com == g.command).length == 0) {
         

         
          item_.push({ com: g.command });
          item.push({
            comando: (
              <Chip label={g.command} className="shadown primary keyItem" />
            ),
            /*valor:
				  <p
			   className='ajustefont'
				 
				dangerouslySetInnerHTML={{ __html:itens.replace(/[\\,]/g, "</br>")}}
			  />,*/
            valor: (
              <pre
                style={{ marginLeft: -17 }}
                dangerouslySetInnerHTML={{
                  __html: toHtml(
                    typeof g.value == 'string' ? JSON.parse(g.value) : g.value,
                    (g.command=='memoria'  || g.command=='disco') ? true:false
                  )
                }}
              />
            )
            /*valor2:<pre>{JSON.stringify(JSON.parse(itens2), undefined, 4)}</pre>*/
          });
        }
      });

    //{"platform":"darwin","distro":"Mac OS X","release":"10.13.6","codename":"macOS High Sierra","kernel":"17.7.0","arch":"x64","hostname":"MacBook-Pro-de-clash.local","codepage":"UTF-8","logofile":"apple","serial":"AA72F0D4-894C-372F-B168-7F3EE869AE03","build":"17G2208","servicepack":"","uefi":true}

    return (
      <PageLayout
        icon="inventaryIcon"
        title={'Inventário'}
        rightElements={[]}
      >
        <div id="InventoryMacbook" className="InventoryMacbook">
          <Card className="content">
            <ListColumn
              col={3}
              items={[
                {
                  col: 4,
                  label: language.COMPUTER_NAME,
                  value: this.state.nome //osInfo.hostname
                },
                {
                  col: 4,
                  label: language.DISTRO,
                  value: this.state.distro //osInfo.distro
                },
                {
                  col: 2,
                  label: language.VERSION,
                  value: this.state.versao // osInfo.release
                },
                {
                  col: 2,
                  label: 'Kernel',
                  value: this.state.kernel //osInfo.kernel
                }
              ]}
            />
          </Card>
          <br />
          <CommonTable
            col={[
              { key: 'comando', label: 'Key' },
              { key: 'valor', label: language.INFORMATION }
            ]}
            data={item}
          />
        </div>
      </PageLayout>
    );
  }
}
