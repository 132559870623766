import React, { useState } from 'react';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import { Card, Avatar, Grid, Checkbox, Badge } from '@material-ui/core';
import Progress from '@common/Progress';
import { Tooltip } from 'react-tippy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import Evolution from './Evolution'
import AttachFile from '@common/AttachFile';
import './ProjectItem.css';
import Dropzone from 'react-dropzone';
import { lang } from 'moment';
const flexBasis = col => ({
  maxWidth: 8.33333333 * (col || 12) + '%',
  flexBasis: 8.33333333 * (col || 12) + '%'
});

export default function ({
  statusProject = {},
  idproject,
  p,
  language,
  groupName,
  users,
  addStage,
  getMilestoneSetor,
  getMilestone,
  removeFile,
  onDrop,
  ondonwFile,
  iduser,
  route,
  index,
  APIEndpoints,
  action,
  setRemoveProjectConfirm,
  toggleAlert,
  companyname,
  role,
  redirect
}) {
  const verifyFiles = () => {
    var ta = !!this.state.ta.file.length;
    var tm = !!this.state.tm.file.length;
    var te = !!this.state.te.file.length;
    return ta || tm || te;
  };

  let state = {
    ta: { open: false, file: [] },
    tm: { open: false, file: [] },
    te: { open: false, file: [] },
    at: { open: false, file: [] },
    pa: { open: false, file: [] }
  };

  let [termActive, setTermActive] = useState(null);
  let [pro, setPro] = useState(null);
  let [fileItem, setFileItem] = useState({
    ta: { open: false, file: [] },
    tm: { open: false, file: [] },
    te: { open: false, file: [] },
    at: { open: false, file: [] },
    pa: { open: false, file: [] }
  });
  let data = {};

  let files = [];

  if (p.files != null) {
    files = p.files;
  }

  const onClosedModal = () => {
    setPro(null)
  }

  const editProject = () => {
    if (parseInt(iduser) == parseInt(p.user_cad) || role == 'client') {
      addStage()
    } else {
      toggleAlert(true, language.ONLY_THE_PROJECT_MANAGER_OR_ADMINISTRATOR_CAN_PERFORM_THIS_ACTION, 'error')
    }

  }

  var documents = [
    { key: 'ta', label: language.TERM_OPENING },
    { key: 'tm', label: language.TERM_CHANGE },
    { key: 'te', label: language.TERM_CLOSURE },
    { key: 'at', label: language.ATTACHMENT },
    { key: 'pa', label: language.SCHEDULE }
  ];



  let arrayUsersTooltips = [];
  let colors = [
    'var(--success)',
    'var(--warning)',
    'var(--danger)',
    '#8b3594',
    '#17a2b8',
    '#629DAA',
    '#6c757d'
  ];
  let countColor = 1;

  let tic = p.tickets_ && p.tickets_.map((e, i) => {
    return (
       (
        <div
        style={{ radius: 10, marginLeft: '5px', marginTop: '2px' }}
        className="d-flex align-items-center"
      >
        <Avatar
          className={classnames('avatar', { 'mt-2': i != 0 })}
          style={{
            fontSize: '10px',
            width: '17px',
            height: '17px',
            backgroundColor:
              colors[countColor < 5 ? countColor++ : (countColor = 1)]
          }}
        >
          {e&&e
            .substr(0, 1)
            .toString()
            .toUpperCase()}
        </Avatar>
        <span style={{ color: 'black', marginLeft: '4px' }}>{e && e}</span>
      </div>
      )
    );
      
  })



  let usersAv = p.users.map((e, i) => {
    let u = users.filter(j => parseInt(j.id_user) === parseInt(e))[0];
    u && arrayUsersTooltips.push(u);
    return (
      u && (
        <Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
              <strong>{u.name}</strong>
            </div>
          }
          position="right"
          theme="dark"
        >
          <div>
            <Avatar
              style={{ backgroundColor: colors[i] }}
              className={classnames('avatar', { 'mt-2': i != 0 })}
            >
              {u.name
                .substr(0, 1)
                .toString()
                .toUpperCase()}
            </Avatar>
          </div>
        </Tooltip>
      )
    );
  });
  let gestor = "";

  try { gestor = users && users.filter(j => parseInt(j.id_user) == parseInt(p.user_cad))[0].name }
  catch (y) { }

  let usersToolAc = arrayUsersTooltips.map((u, i) => (
    <div
      style={{ radius: 10, marginLeft: '5px', marginTop: '2px' }}
      className="d-flex align-items-center"
    >
      <Avatar
        className={classnames('avatar', { 'mt-2': i != 0 })}
        style={{
          fontSize: '10px',
          width: '17px',
          height: '17px',
          backgroundColor:
            colors[countColor < 5 ? countColor++ : (countColor = 1)]
        }}
      >
        {u.name
          .substr(0, 1)
          .toString()
          .toUpperCase()}
      </Avatar>
      <span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
    </div>
  ));

 
  return (
    <Card className={classnames('ProjectItem', { 'mt-3': index != 0 })}>
      <AppBar
        title={p.id_project + ' - ' + groupName.toLowerCase()}
        titleSize={0.79}
        dividerProps={{
          className: 'mt-1'
        }}
        titleProps={{
          onClick: route,
          style: {
            cursor: 'pointer'
          },
          className:'mr-3'
        }}
        afterTitle={

          <div>{p.type_project && <Button
            icon="fas fa-wrench"
            label={language.TYPE_PROJECT_ITEMS[p.type_project - 1].label}
            variant="normal"
            size={0.67}
            className="mr-2"
          />
          }
            <Button
              icon="fas fa-user-cog"
              label={language.MANAGE + ' ' + gestor}
              variant="normal"
              title={'Gestor'}
              size={0.67}
              className="mr-2"
            />
              <Button
              icon=""
              label={language.COMPANY + ' ' + (companyname && ((typeof companyname)=='object') && companyname[0] &&companyname[0].name)}
              variant="normal"
              title={language.COMPANY}
              size={0.67}
              className="mr-2"
            />
       
       {p.tickets_.length >0 ? <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 11 }}>{tic}</div>
            }
            position="right"
            theme="light"
          >
             <Button
              icon=""
              color="warning"
              label={'Tickets'}
              variant="normal"
              title={language.COMPANY}
              size={0.67}
              className="mr-2"
            />
       </Tooltip> :null}


          </div>


        }
      />
      <div className="content pt-0 d-flex px-3">
        <div className="avatar-content mr-3">
          {usersAv && usersAv.length > 2 ? usersAv.slice(0, 3) : usersAv}

          <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 11 }}>{usersToolAc}</div>
            }
            position="right"
            theme="light"
          >
            {usersAv && usersAv.length > 2 && (
              <span style={{ cursor: 'pointer' }} className="mt-1">
                +{usersAv.length - 3}
              </span>
            )}
          </Tooltip>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={2.6} style={{ ...flexBasis(2.6) }} className="grid-1">
            <div className="py-2 px-3">
              <div className="d-flex align-items-center justify-content-between">
                <h3 onClick={route} style={{ cursor: 'pointer' }}>
                  {language.SCOPE}
                </h3>
                <div className='d-flex aling-items-center'>

                  <i onClick={() => setRemoveProjectConfirm({id_project:p.id_project, name:p.name, user_cad:p.user_cad})}
                    style={{
                      color: 'red',
                      
                      color: 'red',
                      marginTop:2.5,
                      fontSize: '12px', cursor: 'pointer'
                    }}
                    className="fas fa-trash mr-3"/>
                    
                  <i onClick={() => redirect('/dash/gp/report', { id_project: p.id_project})}
                    style={{
                      color: '#629DAA',
                      color: '#629DAA',
                      marginTop:2.5,
                      fontSize: '12px', cursor: 'pointer'
                    }}
                    className="fas fa-print mr-3"/>

                  <Button
                    icon="fas fa-pencil-alt"
                    variant="circle"
                    outlined
                    onClick={editProject}
                    style={{ border: 'none' }}
                    size={0.6}
                  />
                </div>

              </div>
              <PerfectScrollbar
                className="scrollVisible"
                style={{
                  // maxHeight:'calc(100% - 70px)'
                  height: 67,
                  marginTop: 10
                }}
              >
                <p className="scope-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: p.scope
                    }}
                  />
                </p>
              </PerfectScrollbar>
            </div>
          </Grid>
          <Grid item xs={3.4} style={{ ...flexBasis(3.4) }} className="grid-2">
            <Card className="w-100 py-2 px-2 border-radius-10">
              <h3 className="mb-2">KPI’s {language.EVOLUTION}</h3>

              <div className="content-cards d-flex align-items-center">
                {[
                  {
                    label: language.ACTIVITIES,
                    value: p.statusproject.total,
                    color: '#00CEF5'
                  },
                  {
                    label: language.PROJECT_STATUS.DONE,
                    value: parseInt(p.statusproject.DONEPER),
                    color: '#629DAA'
                  },
                  {
                    label: language.PROJECT_STATUS.DOING,
                    value: parseInt(p.statusproject.DOINGPER),
                    color: 'var(--warning)'
                  },
                  {
                    label: language.PROJECT_STATUS.TO_DO,
                    value: parseInt(p.statusproject.TODOPER),
                    color: '#019FC2'
                  },
                  {
                    label: language.PROJECT_STATUS.STOPPED,
                    value: parseInt(p.statusproject.STOPPEDPER),
                    color: 'var(--danger)'
                  }
                ].map((e, i) => (
                  <div
                    key={i}
                    className="pb-2 pt-3"
                    style={{ backgroundColor: e.color }}
                  >
                    <h4 className="mx-2">{e.value + (i != 0 ? '%' : '')}</h4>
                    <span className="ml-2">{e.label}</span>
                  </div>
                ))}
              </div>
            </Card>
          </Grid>
          {((parseInt(iduser) == parseInt(p.user_cad)) ||
              role ==='client' ) && 
          <Grid item xs={1} className="grid-3">
            <Card className="p-2">
              <span>{language.EXPECTED_COST}</span>
              <h4>R$ {p.cust_project} </h4>
            </Card>
            <Card className="p-2">


            <Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
              {p.valoractivity.map(h=>
         <strong>{h.replace('**',' - ')}</strong>
        ) }
           
            </div>
          }
          position="right"
          theme="dark"
        >
            <span style={{fontSize:12,color:'var(--warning)'}} >{language.REALIZED_COST}</span>
              <h4  style={{fontSize:12,color:'var(--warning)'}}  >R$ {p.valoractivity.map(item => parseFloat(item.split('**')[1]))
  .reduce((prev, curr) => prev + curr, 0) }</h4>
        </Tooltip>



             

             

            </Card>
          </Grid>
          }
          <Grid item xs={2.5} style={{ ...flexBasis(2.5) }} className="grid-4">
            <Card className="py-2 px-3">
              <h3 className="mb-1">{language.EVOLU}</h3>
              <Evolution action={action}      
              APIEndpoints={APIEndpoints}
               project={p}
              ></Evolution>
              {/*
              <div className="d-flex align-items-center justify-content-between">
                <Progress
                  hiddenText
                  className="w-100"
                  background="var(--danger)"
                  current={p.statusproject.percentConcluido}
                />
                <span className="ml-2 percent-text">
                  {p.statusproject.percentConcluido}%
                </span>
              </div>
              */}
              
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div>
                  <span>{language.INITIAL_DATE}</span>
                  <span className="d-flex align-items-center mt-2">
                    <i className="fas fa-calendar-day mr-2" />
                    {moment(p.start_date).format('L')}
                  </span>
                </div>
                <div>
                  <span>{language.FINAL_DATE}</span>
                  <span className="d-flex align-items-center mt-2">
                    <i className="fas fa-calendar-day mr-2" />
                    {moment(p.end_date).format('L')}
                  </span>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={2.5} style={{ ...flexBasis(2.5) }} className="grid-5">
            <Card className="py-2 px-3" style={{ height: 118 }}>
              <PerfectScrollbar
                className="scrollVisible"
                style={{
                  maxHeight: 118
                }}
                option={{
                  suppressScrollX: true,
                  scrollYMarginOffset: 10
                }}
              >
                <ul>
                  {documents.map((e, i) => (
                    <li key={i}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <Button
                            icon={
                              'fas ' +
                              (termActive == i
                                ? 'fa-caret-down'
                                : 'fa-caret-right')
                            }
                            variant="circle"
                            outlined
                            style={{ border: 'none' }}
                            size={0.7}
                            onClick={() =>
                              setTermActive(termActive === i ? null : i)
                            }
                          />
                          <span>{e.label}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <Badge
                            badgeContent={
                              (files || []).filter(
                                g => g.key.toString() == e.key.toString()
                              ).length
                            }
                            className="badge-total-file"
                          />

                          <Dropzone
                            accept=".zip, .7z, .json, .vbs, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar, .msg, audio/*, image/*"
                            required
                            onDrop={a => onDrop(a, e.key, p.id_project, i)}
                            style={{
                              border: '1px solid transparent'
                              // width: 28,
                              // height: 28
                            }}
                          >
                            <Button
                              icon="fas fa-plus"
                              variant="circle"
                              _loading={'post-file-project-'+p.id_project+'-'+i}
                              style={{
                                position: 'relative',
                                top: -3
                              }}
                              size={0.55}
                              className="ml-4"
                            />{' '}
                          </Dropzone>
                        </div>
                      </div>
                      {termActive == i && (
                        <ul className="list-files ml-3">
                          {files
                            .filter(g => g.key.toString() == e.key.toString())
                            .map((e, i) => (
                              <li key={i}>
                                <AttachFile
                                  file={e}
                                  onClick={() =>
                                    ondonwFile(e.id_project, e.name)
                                  }
                                  style={{ maxWidth: '100%', zoom: 0.61 }}
                                  className="my-1 file-item"
                                  onDelete={() =>
                                    removeFile(
                                      e.id_project_file,
                                      e.id_project_file
                                    )
                                  }
                                />
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </PerfectScrollbar>
            </Card>
          </Grid>
        </Grid>
      </div>

    </Card>
  );
}
