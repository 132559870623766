import React from 'react';
import { Breadcrumbs, Typography } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useServicePortalStyles } from '../helpers';

export const ServicePortalSubHeader = ({ breadcrumbs }) => {
    const styles = useServicePortalStyles();

    return (
        <div className='sp-subheader tw-bg-gray-200 tw-p-2 tw-h-10'>
            <div className='sp-subheader-container tw-container tw-m-auto'>
                <Breadcrumbs color='inherit' separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    {breadcrumbs.map(item => <Typography key={item} component='span' className={styles.text}>{item}</Typography>)}
                </Breadcrumbs>
            </div>
        </div>
    )
}



