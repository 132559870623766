import React, { Component, useCallback } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { TextField, FormControlLabel, Grid, Checkbox } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import { FixedSizeList } from 'react-window';
import { Tooltip } from 'react-tippy';
import { Scrollbars } from 'react-custom-scrollbars';
import { Card } from '@material-ui/core';
import classnames from 'classnames';
import Store from '@data/Store';

const { language } = Store;

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));
const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback((scrollbarsRef) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <Scrollbars
      ref={refSetter}
      style={{ ...style, overflow: 'hidden' }}
      onScroll={onScroll}
    >
      {children}
    </Scrollbars>
  );
};

let delayTimerDebounce;

export default class ListCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      filter: null,
    };
  }
  componentWillMount() {
    let options = this.props.field.options;
    this.setState({ options });
  }
  componentWillReceiveProps(nextProps) {
    if (!this.state.filter || !this.state.options.length) {
      let options = nextProps.field.options;
      this.setState({ options });
    }
  }

  getStatusCheckedAll = () => {
    let { values = [] } = this.props;
    let { options } = this.state;
    var valueFilter = options.filter(
      (e) => values && values.indexOf(e.value) != -1
    );
    //sortitem
    return !(options.length > valueFilter.length);
  };
  setCheckboxValue = (val, status) => {
    var { field } = this.props;
    let values = [];
    if (this.props.values) {
      if (this.props.single == true) {
        values = this.props.values;
      } else {
        values = Object.assign(
          [],
          this.props.values.filter((e) => e != undefined)
        );
      }
    }

    if (this.props.single == true) {
      values = val;
      //console.log(val);
    } else {
      if (status) {
        values.push(val);
      } else {
        values = values.filter((e) => e != val);
      }
    }
    this.props.handleValue(values, val);
  };
  setCheckboxValueAll = (status) => {
    let { options } = this.state;
    let { field } = this.props;
    if (status) {
      var values = options.map((e) => e.value);
    } else {
      var values = [];
    }

    this.props.handleValue(values, field.name);
  };

  onFilterValue = (val) => {
    var { options } = this.props.field;

    if (!!this.props.handleFilter) {
      clearTimeout(delayTimerDebounce);

      delayTimerDebounce = setTimeout(() => {
        this.props.handleFilter(val);
      }, 1000);

      return;
    }

    if (options.filter((e) => e.labelnohtml != undefined).length > 0) {
      options = options.filter(
        (e) =>
          typeof e.labelnohtml == 'string' &&
          e.labelnohtml.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) !==
            -1
      );
    } else {
      options = options.filter(
        (e) =>
          typeof e.label == 'string' &&
          e.label.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) !== -1
      );
    }

    this.setState({ options, filter: val });
  };
  render() {
    let {
      field,
      spacing,
      theme,
      col,
      card,
      search,
      language,
      values = [],
      selectAll,
      classes,
      required,
      errors,
    } = this.props;
    let { options } = this.state;

    var BoxContainer = [undefined, true].includes(card) ? Card : Fragment;
    return (
      <MuiThemeProvider theme={theme}>
        <BoxContainer
          style={{
            position: 'relative',
            paddingTop: '1rem',
          }}
          className={classnames('list-checkbox-containter', {
            error: !!errors,
          })}
        >
          {field.label && (
            <span
              className="font"
              style={{
                paddingLeft: 18,
                fontSize: 14,
                display: 'block',
                color: !!errors ? '#f44336' : 'var(--primary)',
              }}
            >
              {field.label}
              {field.required ? ' *' : ''}
              {!!errors && (
                <span
                  style={{
                    color: '#f44336',
                    fontSize: '0.75rem',
                    display: 'block',
                  }}
                >
                  {errors}
                </span>
              )}
            </span>
          )}

          {field.header != false ? (
            <div style={{ padding: '0 18px' }}>
              {[undefined, true].includes(search) && this.props.single != true && (
                <TextField
                  onChange={(evt) => this.onFilterValue(evt.target.value)}
                  fullWidth
                  inputProps={{ style: { height: 'auto' } }}
                  type="search"
                  variant="outlined"
                  margin="dense"
                  className="input-min"
                  placeholder={language.SEARCH}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.rootSearchListCheckbox,
                    },
                  }}
                />
              )}
            </div>
          ) : null}
          {field.header != false && this.props.single != true && (
            <div style={{ padding: '10px 18px' }}>
              <Grid container alignItems="flex-end" spacing={spacing || 4}>
                {!!options.length && [true, undefined].includes(selectAll) && (
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.getStatusCheckedAll()}
                          onChange={(evt) =>
                            this.setCheckboxValueAll(evt.target.checked)
                          }
                        />
                      }
                      label={
                        <span
                          style={{ fontWeight: 500, color: 'var(--primary)' }}
                        >
                          {language.SELECT_ALL} ({options.length})
                        </span>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          )}
          {(field.label || field.header) && <hr style={{ margin: '0' }} />}
          <div className="container-item-listCheckbox">
            <div className="react-window">
              {!options.length ? (
                <Grid
                  container
                  alignItems="flex-end"
                  spacing={spacing || 4}
                  style={{ padding: '0 18px', paddingTop: 20 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Typography
                      variant="subheading"
                      gutterBottom
                      className="font"
                    >
                      {language.NOTHING_REGISTERED}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <FixedSizeList
                  height={options.length <= 6 ? options.length * 40 : 250}
                  itemCount={options.length}
                  itemSize={40}
                  width={'100%'}
                  innerElementType={({ children, style, ...other }) => (
                    <Grid
                      {...other}
                      container
                      alignItems="flex-end"
                      spacing={spacing || 4}
                      style={{ ...style, paddingTop: 20 }}
                    >
                      {children}
                    </Grid>
                  )}
                  outerElementType={CustomScrollbarsVirtualList}
                  itemData={options}
                >
                  {({ index, style, data }) => (
                    <Grid
                      item
                      xs={col || 12}
                      key={data[index].label + '-' + index}
                      style={{
                        ...style,
                        paddingLeft: 18,
                        paddingTop: 0,
                        paddingBottom: 0,
                        ...(data[index].contentProps &&
                          data[index].contentProps.style),
                      }}
                      {...data[index].contentProps}
                    >
                      {data[index].beforeComponent}
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            <strong>{data[index].label}</strong>
                          </div>
                        }
                        position="left"
                        theme="dark"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                values &&
                                values.indexOf(data[index].value) !== -1
                              }
                              onChange={(evt) =>
                                this.setCheckboxValue(
                                  data[index].value,
                                  evt.target.checked
                                )
                              }
                              {...(data[index].checkbox || {})}
                            />
                          }
                          className="w-100 label-checkbox-listCheckbox"
                          label={
                            <span
                              style={{ color: 'var(--primary)' }}
                              className="ellipsis d-block"
                            >
                              {data[index].label}
                            </span>
                          }
                        />
                      </Tooltip>
                      {data[index].afterComponent}
                    </Grid>
                  )}
                </FixedSizeList>
              )}
            </div>
          </div>
        </BoxContainer>
      </MuiThemeProvider>
    );
  }
}
