import { settings } from 'nprogress';
import React, { useState, useEffect } from 'react';
import Constants from '@data/Constants';
import Store from '@data/Store';

const { language } = Store; 
 
// --------------------------------icons----------------------------------

const tenant = Constants.Tenant;

export default function({device, ioServers}) {
  let [color, Setcolor] = useState('red');
    useEffect(() => {
      ioServers.on(`_${tenant}_${device.id_device}`,
        (data) => {
          //Emitter.emit("status",device.id_device)
         if(data.offline==true){
          Setcolor('red')
         }else{
          Setcolor('#00ff00')
      
         }     });

   const intervalId= setInterval(() => {
      ioServers.on(`_${tenant}_${device.id_device}`,
      (data) => {
        //Emitter.emit("status",device.id_device)
       if(data.offline==true){
        Setcolor('red')
       }else{
        Setcolor('#00ff00')
    
       }
      });
    }, 9000);

    return () => clearInterval(intervalId); //This is important

 }, [device]);

  return <div style={{marginTop:9}} > 
{color == '#00ff00'?  <i className="fas fa-circle mr-1" />
    :  
    <i
      className="fas fa-circle mr-1"
      style={{
        color: ' #DEDEDE',
        fontSize: '8px',
        textShadow: '0 0 3px #DEDEDE, 0 0 5px #DEDEDE',
      }}
    />
    
    }<span>   
      {color == 'red' ? language.NOT_MONITORING : language.MONITORING}</span>
 
   </div>
  
 
}
