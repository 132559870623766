import './index.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import Icon from '@material-ui/core/Icon';
import * as Icons from '@material-ui/icons';

import Dialog from '../../common/Dialog';
import Animate from '../../common/Animate';
import { testRoute } from '../../common/Func';
import CommonPage from '../../common/CommonPage';
import DialogConfirm from '../../common/DialogConfirm';

import CatalogForm from './CatalogForm';
import CatalogList from './CatalogList';
import CatalogAddTaskForm from './CatalogAddTaskForm';

@observer
export default class CatalogContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'listCatalog',
      catalogs: [],
      currentPage: 0,
      page: 0,
      filter: {},
      pageDelete: {
        open: false,
        values: undefined,
      },
    };
    this.onDelete = this.onDelete.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.refreshCatalog = this.refreshCatalog.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.cleanAllFilters = this.cleanAllFilters.bind(this);
  }

  componentWillMount() {
    this.callPageSelectFilter();
  }

  cleanAllFilters() {
    this.callPageSelectFilter();
  }

  componentDidMount() {
    let icons_ = Object.keys(Icons)
      .filter(
        (i) =>
          !i.toString().includes('Outlined') &&
          !i.toString().includes('Rounded') &&
          !i.toString().includes('Sharp') &&
          !i.toString().includes('TwoTone') &&
          !i.toString().includes('Forward') &&
          !i.toString().includes('Extra') &&
          !i.toString().includes('Reduced')
      )
      .map((str) => ({
        value: str.replace(/[A-Z]/g, (letter, index) => {
          return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
        }),
        label: (
          <div>
            <Icon>
              {str.replace(/[A-Z]/g, (letter, index) => {
                return index == 0
                  ? letter.toLowerCase()
                  : '_' + letter.toLowerCase();
              })}
            </Icon>
            {str}
          </div>
        ),
        field: str,
      }));
    this.setState({ allIcons: icons_ });
  }

  callPageSelectFilter(page = 0, values = {}, totalPerPage = 75, type) {
    this.setState({ currentPage: page });
    this.setState({ filter: values });
    let limit = totalPerPage;
    let offset = page > 0 ? page * limit : 0;

    if (type == 'filter') {
      offset = 0;
    }

    this.action
      .execute('post', `${this.APIEndpoints.CATALOG}/list?limit=${totalPerPage}&offset=${offset}`, '', values)
      .then((res) => {
        this.setState({
          catalogs: res.data,
          totalcatalogs: parseInt(res.data[0]?.total_count ? res.data[0].total_count : 0),
        });
      });
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  handlePageDelete = (open = false, values) => {
    this.setState({ pageDelete: { open, values } });
  };

  onFormSubmit(values) {
    let method = values.hasOwnProperty('id_catalog_service') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_catalog_service')
      ? this.APIEndpoints.CATALOG + '/' + values.id_catalog_service
      : this.APIEndpoints.CATALOG;
    this.action
      .execute(method, url, 'catalogs', values, 'id_catalog_service', {
        btn_loading: 'post-put-catalog',
      })
      .then((h) => {
        this.handlePage('list');
        this.callPageSelectFilter(this.state.currentPage, this.state.filter);
      });
  }

  onDelete() {
    let { values } = this.state.pageDelete;

    let method = 'delete';
    let url = this.APIEndpoints.CATALOG + '/' + values.id_catalog_service;
    let body = null;
    let vt = 1;

    this.handlePageDelete();
    this.action
      .execute(method, url, 'catalogs', body, 'id_catalog_service', {
         btn_loading: 'del-catalog-' + vt + values.id_catalog_service,
      })
      .then((res) => {
         this.handlePage('list');
         this.callPageSelectFilter(this.state.currentPage, this.state.filter);
      })
      .catch((error) => {
         console.error(error);
         this.store.toggleAlert(true, this.store.language.THERE_WAS_AN_ERROR_SAVING_THE_FIELDS, 'error');
      });
  }

  refreshCatalog(newCatalog) {
    const { catalogs } = this.state;
    this.setState({
      catalogs: catalogs.map((catalog) => {
        if (catalog.id_catalog_service === newCatalog.id_catalog_service) {
          catalog = newCatalog;
        }
        return catalog;
      }),
    });
  }

  render() {
    let { language, groups } = this.store;
    let { page, values, catalogs, totalcatalogs, searchValue } = this.state;
    let redirect = this.redirect2;

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <CatalogList
            onFormSubmit={this.onFormSubmit}
            handlePage={this.handlePage}
            language={language}
            redirect={redirect}
            catalogs={catalogs}
            groups={groups}
            page={page}
            handlePageDelete={this.handlePageDelete}
            values={values}
            configurationProps={configurationProps}
            totalcatalogs={totalcatalogs}
            callPageSelectFilter={this.callPageSelectFilter}
            onClearAllFilter={this.cleanAllFilters}
          />
        </Animate>
        {['new', 'edit'].includes(page) && (
          <Dialog
            title={
              values && values.id_catalog_service
                ? language.EDIT_CATALOG
                : language.ADD_CATALOG
            }
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="md"
          >
            <CatalogForm
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              language={language}
              catalogs={catalogs}
              redirect={redirect}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              groups={groups}
              isDev={this.store.isDev()}
              {...this.state}
              values={values}
            />
          </Dialog>
        )}
        {page == 'assigment' && (
          <Dialog
            title={language.NEW_ASSIGNMENT}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="md"
          >
            <CatalogAddTaskForm
              handlePage={this.handlePage}
              language={language}
              catalogs={catalogs}
              refreshCatalog={this.refreshCatalog}
              values={
                catalogs.filter(
                  (c) => c.id_catalog_service === values.id_catalog_service
                )[0]
              }
            />
          </Dialog>
        )}

        {this.state.pageDelete.open && (
          <DialogConfirm
            open={this.state.pageDelete.open}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={this.onDelete}
            onClose={() => this.handlePageDelete()}
            title={language.CONFIRM_DELETE}
            message={this.state.pageDelete.values.name}
          />
        )}
      </Fragment>
    );
  }
}
