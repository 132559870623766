import React, { useReducer, useState } from 'react';
import Header from '@common/Header';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import Constants from '@data/Constants';
// ------------------------------common-----------------------------------------
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Progress from '@common/Progress';
import Tag from '@common/Tag';
// ------------------------------material-ui-------------------------------------
// ------------------------------components-------------------------------------
import { projectList, activitiesList } from '../jsonFormat';
import TagTable from './TagTable';
import Table from '../Table';
import GanttPmo from '../Block_1/GanttPmo';
import Report from './ReportSintect2/index';
import { jsonFormatGantt } from '../../Project/jsonFormat';
import Dialog from '@common/Dialog';
import DialogTableActivities from '../Block_1/DialogTableActivities';

/*
	------------------------------------------------fromato----------------------------------------------------------
	let data = [
		{
			id:151,
			project:'Projeto Teste',
			manager:'Fabricio Silva',
			project_type:'corrective',
			priority:7.22,
			status:'Em execução',
			percent:65,
			expected_start:'12/05/2020',
			expected_end:'12/05/2020',
			realized_start:'23/10/2020',
			realized_end:'23/10/2020',
			expected_cost:1000,
			realized_cost:1000,
			activities:[4, 5, 3, 7, 1],
			data:[
				{
					name:'UX/UI',
					expected_start:'23/10/2020',
					realized_start:'23/10/2020',
					expected_end:'23/10/2020',
					realized_end:'23/10/2020',
					expected_cost:1000,
					realized_cost:1000,
					data:[
						{
							id:'R.58',
							activity:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
							sponsor:[
								{src:undefined, name:'João'},
								{src:undefined, name:'Pedro'},
								{src:undefined, name:'Carlos'}
							],
							evolution:65,
							status:'Categorização',
							priority:{
								label:'Baixa',
								color:'var(--primary)'
							},
							goal:'20/04/2020',
						}
					]
				}
			]
		}
	]
*/

export default function ({
  pmos,
  users,
  dateInitial,
  dateEnd,
  redirect,
  language,
}) {
  let [open, setOpen] = useReducer(
    (state, v) => (state === v ? null : v),
    null
  );
  const [view, setView] = useState(1);
  const [print, setPrint] = useState(0);

  let data = pmos.map((e) => {
    let activitiesTags = [
      ...e.utils.DONE,
      ...e.utils.BACKLOG,
      ...e.utils.DOING,
      ...e.utils.STOPPED,
      ...e.utils.VALIDATION,
      ...e.utils.TODO,
    ]
      .flatMap((e) => e)
      .reduce(
        (obj, a) => {
          (a.tagac || []).forEach((tag) => {
            let index = obj.findIndex(
              (t) => t.id_project_tag == tag.id_project_tag
            );
            try {
              obj[index].data.push(activitiesList({ e, a, users }));
            } catch (e) {}
          });

          return obj;
        },
        (e.tagpj || []).map((e) => ({
          id_project_tag: e.id_project_tag,
          name: e.name,
          color: e.color,
          data: [],
        }))
      );

    return projectList({ e, activitiesTags, users });
  });

  let end_date = moment();
  let dataGantt = [
    ...pmos
      .filter((e) => parseInt(e.percent) != 100)
      .map((project) => {
        let obj = {
          name: project.name,
          dt_start: project.start_date,
          dt_end: project.end_date,
          tagac: project.tagpj,
          percent: parseInt(project.percent),
          usersphoto: project.usersphoto,
        };
        end_date = moment.max(
          [end_date, project.end_date].map((e) => moment(e))
        );
        return jsonFormatGantt(obj, users);
      }),
  ];

  // -------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div id="Block_2">
      <Header
        onChange={(i) => setView(i)}
        btnFilter={[language.GANTT, language.PROJECT_TABLE]}
      >
        <Button
          icon="fas fa-print"
          label={language.PRINT}
          color="warning"
          onClick={(v) => setPrint(1)}
        />
      </Header>
      {view === 1 ? (
        <Table data={data} redirect={redirect} />
      ) : (
        <GanttPmo
          group={dataGantt}
          startDate={dateInitial}
          endDate={dateEnd || end_date}
        />
      )}

      {print === 1 ? (
        <span style={{ width: '100%' }}>
          <Dialog
            title={language.SYNTHETIC_DETAILS}
            open={print == 1 ? true : false}
            maxWidth="xl"
            monitor_scroll
            onClose={(v) => setPrint(0)}
          >
            <Report
              pmos={pmos}
              dataGantt={dataGantt}
              gannt={
                <GanttPmo
                  relatorio={true}
                  group={dataGantt}
                  startDate={dateInitial}
                  endDate={dateEnd || end_date}
                />
              }
            />
          </Dialog>
          <GanttPmo
            group={dataGantt}
            startDate={dateInitial}
            endDate={dateEnd || end_date}
          />
        </span>
      ) : null}
    </div>
  );
}
