import React, { memo, useState, useEffect } from 'react';
import classnames from 'classnames';
import IntegrationReactSelect from './select';
import MultipleSelect from './selectMultiple';
import MultipleSelectMin from './selectMultipleMin';

export default memo(
  ({
    f,
    statusError,
    _value,
    _handleValue,
    errors,
    individual,
    classes,
    variant,
    margin,
    language,
    isMulti,
    isMultiMin,
  }) => {
    let [value, setValue] = useState(isMulti ? [] : '');

    function handleValue(val, name) {
      setValue(val);
      _handleValue(val, f.name);
    }
    useEffect(() => {
      if (_value !== undefined) {
        setValue(_value);
      }
    }, [_value]);

    let options = f.options || [];
    return isMulti ? (
      <MultipleSelect
        label={f.label}
        value={value}
        options={
          options.length
            ? [{ value: '__all', label: language.SELECT_ALL }].concat(options)
            : []
        }
        onChange={(evt) => {
          let value = evt ? evt.map((e) => e.value) : [];

          if (value.find((e) => e == '__all')) {
            handleValue(
              options.map((e) => e.value),
              f.name
            );
          } else {
            handleValue(value, f.name);
          }
        }}
        className={
          f.className == '-static'
            ? 'w-100 common-select-min common-multi-select-static '
            : 'w-100 common-select-min common-multi-select '
        }
        isMulti={isMulti}
        language={language}
        closeMenuOnSelect={!isMulti}
        sortOptions={f.sortOptions}
        readOnly={f.inputProps ? f.inputProps.readOnly : false}
        textFieldProps={{
          startIcon: f.startIcon,
          endIcon: f.endIcon,
          margin: f.margin || margin || 'dense',
          variant: f.variant || variant || 'outlined',
          disabled: f.disabled,
          helperText: !f.disableddHelperText
            ? !individual
              ? statusError[errors[f.name]] || f.help || ' '
              : undefined
            : undefined,
          required: !!f.required,
          multiline: isMulti,
          style: { width: '100%', marginTop: 8, marginBottom: 4 },
          inputProps: {
            ...f.inputProps,
            style: { height: 'auto' },
          },
          InputProps: {
            classes: {
              underline: classes.underline,
            },
          },
          InputLabelProps: {
            classes: {
              root: classes.label,
            },
          },
          error: statusError[errors[f.name]],
          ...f.textFieldProps,
        }}
      />
    ) : isMultiMin ? (
      <MultipleSelectMin
        label={f.label}
        value={value}
        options={
          options.length
            ? [{ value: '__all', label: language.SELECT_ALL }].concat(options)
            : []
        }
        onChange={(evt) => {
          let value = evt ? evt.map((e) => e.value) : [];

          if (value.find((e) => e == '__all')) {
            handleValue(
              options.map((e) => e.value),
              f.name
            );
          } else {
            handleValue(value, f.name);
          }
        }}
        className={
          isMultiMin
            ? 'w-100 common-select-min common-multi-select-static ' + f.className
            : 'w-100 common-select-min common-multi-select ' + f.className
        }
        isMulti={isMulti}
        language={language}
        closeMenuOnSelect={!isMulti}
        sortOptions={f.sortOptions}
        readOnly={f.inputProps ? f.inputProps.readOnly : false}
        textFieldProps={{
          startIcon: f.startIcon,
          endIcon: f.endIcon,
          margin: f.margin || margin || 'dense',
          variant: f.variant || variant || 'outlined',
          disabled: f.disabled,
          helperText: !f.disableddHelperText
            ? !individual
              ? statusError[errors[f.name]] || f.help || ' '
              : undefined
            : undefined,
          required: !!f.required,
          multiline: isMulti,
          style: { width: '100%', marginTop: 8, marginBottom: 4 },
          inputProps: {
            ...f.inputProps,
            style: { height: 'auto' },
          },
          InputProps: {
            classes: {
              underline: classes.underline,
            },
          },
          InputLabelProps: {
            classes: {
              root: classes.label,
            },
          },
          error: statusError[errors[f.name]],
          ...f.textFieldProps,
        }}
      />
    ) : (
      <IntegrationReactSelect
        label={f.label}
        value={value}
        options={options}
        onChange={(evt) => {
          handleValue(
            isMulti ? (evt.options || []).map((e) => e.value) : evt.value,
            f.name
          );
        }}
        className={'w-100 common-select-min ' + f.className}
        language={language}
        closeMenuOnSelect={!isMulti}
        sortOptions={f.sortOptions}
        readOnly={f.inputProps ? f.inputProps.readOnly : false}
        textFieldProps={{
          startIcon: f.startIcon,
          endIcon: f.endIcon,
          margin: f.margin || margin || 'dense',
          variant: f.variant || variant || 'outlined',
          disabled: f.disabled,
          helperText: !f.disableddHelperText
            ? !individual
              ? statusError[errors[f.name]] || f.help || ' '
              : undefined
            : undefined,
          required: !!f.required,
          style: { width: '100%', marginTop: 8, marginBottom: 4 },
          inputProps: {
            ...f.inputProps,
            style: { height: 'auto' },
          },
          InputProps: {
            classes: {
              underline: classes.underline,
            },
          },
          InputLabelProps: {
            classes: {
              root: classes.label,
            },
          },
          error: statusError[errors[f.name]],
          ...f.textFieldProps,
        }}
      />
    );
  }
);
