import { observer } from 'mobx-react';
import React, { Component } from 'react';

import keycloak from './Keycloak';

import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import Store from '@data/Store';
import {
  Grid,
  MuiThemeProvider,
  Button as RaisedButton,
  TextField,
  createTheme,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Msal from 'msal';
import Intersection from '../../../public/images/intersection.png';
import { LoginChangePassword } from './LoginChangePassword';
import LoginGoogle from './LoginGoogle';
import './LoginPage.css';
import LoginRecoveryPassword from './LoginRecoveryPassword';
import LoginRecoverySetting from './LoginRecoverySetting';

const { language } = Store;

var PAGE = window.location.href
  .replace(window.location.origin + '/', '')
  .replace(/\/.*/, '');

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});

/* **** */

const MyHeader = ({ title, subTitle, className }) => (
  <AppBar
    contentProps={{
      className: 'pl-0 ' + className,
    }}
    titleProps={{
      style: { color: 'var(--primary)' },
    }}
    title={title}
    subTitle={subTitle}
  />
);

/* **** */

@observer
export default class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      load: false,
      direction: PAGE === 'redefine' ? 'center' : 'right',
      openModal: false,
      values: [],
      files: [],
      Logo: null,
      isMobile: false,
      authenticated: false,
      userLoaded: false,
    };
    this.handleDirection = this.handleDirection.bind(this);
    this.showWelcomeMessage = this.showWelcomeMessage.bind(this);
    this.handleResize = this.handleResize.bind(this);

    // keycloak.init({onLoad: 'check-sso', silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`}).then(authenticated => {
    //   this.setState({ authenticated: authenticated });
    // })
  }

  handleDirection(direction) {
    this.props.store.newUser = '';
    this.setState({ direction: direction });
  }

  componentWillMount() {
    const action = new Actions();
    action
      .execute('get', Constants.APIRoot + '/picturePreview', '')
      .then((item) => {
        this.setState({ Logo: item.data });
        // window.localStorage.setItem('imagePreview',item.data);
        sessionStorage.setItem('imagePreview', item.data);
      });

    window.localStorage.setItem('previous_url', window.location.pathname);

    window.addEventListener('resize', this.handleResize());

    // debugger
    // if (this.props.general_parameters?.enable_sso) {
    //   debugger
    //   keycloak
    //     .init({
    //       onLoad: 'check-sso',
    //       silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`
    //     })
    //     .then(authenticated => {
    //       this.setState({ authenticated: authenticated });
    //     })
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevGeneralParameters = prevProps.general_parameters;
    const actualGeneralParameters = this.props.general_parameters;
    if (
      prevGeneralParameters?.enable_sso != actualGeneralParameters?.enable_sso
    ) {
      if (actualGeneralParameters?.enable_sso) {
        if (!this.state.keycloakInitialized) {
          if (!keycloak) return;
          keycloak
            .init({
              onLoad: 'check-sso',
              silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
            })
            .then((authenticated) => {
              this.setState({
                authenticated: authenticated,
                keycloakInitialized: true,
              });
            });
        }
      } else if (
        !actualGeneralParameters?.enable_sso &&
        this.state.keycloakInitialized
      ) {
        this.setState({ authenticated: false, keycloakInitialized: false });
      }
    }

    if (this.props.settings && prevProps.settings !== this.props.settings) {
      this.setState(
        {
          direction: 'settings',
          password_recovery_code: this.props.settings.password_recovery_code,
        },
        () => {
          console.log('state', this.state);
        }
      );
    }
  }

  handleResize() {
    if (window.innerWidth < 720) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  signIn(myMSALObj) {
    var requestObj = {
      scopes: ['user.read'],
    };
    var t = this;
    myMSALObj
      .loginPopup(requestObj)
      .then(function (loginResponse) {
        //Successful login
        t.showWelcomeMessage(myMSALObj);
      })
      .catch(function (error) {
        //Please check the console for errors
      });
  }

  loadUser() {
    this.setState({ userLoaded: true });
    keycloak.loadUserInfo().then((user) => {
      const kcInfo = JSON.stringify({
        id: user.sub,
        name: user.name,
        username: user.email,
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        emailVerified: user.email_verified,
      });
      sessionStorage.setItem('keycloak', kcInfo);
      const company = sessionStorage.getItem('IdCompany');

      this.props.onLogin({
        id: user.sub,
        name: user.name,
        username: user.email,
        password: keycloak.token,
        company: parseInt(company),
        auth: 'sso',
      });

      var divWelcome = document.getElementById('WelcomeMessage');
      divWelcome.innerHTML = 'Welcome ' + user.email + ' to Microsoft Entra ID';
    });
  }

  async signInAD() {
    const email = sessionStorage.getItem('username');
    const action = new Actions();
    await action
      .execute('post', Constants.APIEndpoints.LOGIN + '/sso', '', {
        username: email,
      })
      .then(async (item) => {
        sessionStorage.setItem('IdCompany', item.data.id_company);

        if (item.data.sso) {
          if (item.data.idp) {
            if (this.state.authenticated) {
              this.loadUser();
            } else {
              let kcLogin = keycloak.login;
              keycloak.login = (options) => {
                options.idpHint = item.data.idp;
                options.loginHint = email;
                kcLogin(options);
              };
              await keycloak.login({ onLoad: 'login-required' });
            }
          } else {
            this.props.onLogin({
              username: this.props.username,
              password: this.props.password,
              company: item.data.id_company,
              auth: 'sso',
            });
          }
        } else
          this.props.store.toggleAlert(
            true,
            'Domínio/Usuário não permitido para esse tipo de autenticação.',
            'error'
          );
      })
      .catch((err) => {
        console.error(err);
        this.props.store.toggleAlert(
          true,
          !keycloak
            ? 'Erro ao carregar as configurações do serviço de autenticação.'
            : 'Domínio/Usuário não permitido para esse tipo de autenticação.',
          'error'
        );
      });
  }

  signOut() {
    myMSALObj.logout();
  }

  acquireTokenPopupAndCallMSGraph() {
    //Always start with acquireTokenSilent to obtain a token in the signed in user from cache

    myMSALObj
      .acquireTokenSilent(requestObj)
      .then(function (tokenResponse) {
        callMSGraph(
          graphConfig.graphMeEndpoint,
          tokenResponse.accessToken,
          graphAPICallback
        );
      })
      .catch(function (error) {
        console.log(error);
        // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
        // Call acquireTokenPopup(popup window)
        if (requiresInteraction(error.errorCode)) {
          myMSALObj
            .acquireTokenPopup(requestObj)
            .then(function (tokenResponse) {
              callMSGraph(
                graphConfig.graphMeEndpoint,
                tokenResponse.accessToken,
                graphAPICallback
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
  }

  callMSGraph(theUrl, accessToken, callback) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200)
        callback(JSON.parse(this.responseText));
    };
    xmlHttp.open('GET', theUrl, true); // true for asynchronous
    xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xmlHttp.send();
  }

  graphAPICallback(data) {
    alert(JSON.stringify(data, null, 2));
    document.getElementById('json').innerHTML = JSON.stringify(data, null, 2);
  }

  showWelcomeMessage(myMSALObj) {
    // alert(JSON.stringify(myMSALObj))

    this.props.onLogin({
      username: myMSALObj.getAccount().userName,
      auth: 'microsoft',
      id: myMSALObj.getAccount().idToken.aud,
    });

    var divWelcome = document.getElementById('WelcomeMessage');
    divWelcome.innerHTML =
      'Welcome ' + myMSALObj.getAccount().userName + ' to Microsoft Graph API';
  }

  //This function can be removed if you do not need to support IE
  acquireTokenRedirectAndCallMSGraph() {
    //Always start with acquireTokenSilent to obtain a token in the signed in user from cache
    myMSALObj
      .acquireTokenSilent(requestObj)
      .then(function (tokenResponse) {
        this.callMSGraph(
          graphConfig.graphMeEndpoint,
          tokenResponse.accessToken,
          graphAPICallback
        );
      })
      .catch(function (error) {
        console.log(error);
        // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
        // Call acquireTokenRedirect
        if (requiresInteraction(error.errorCode)) {
          myMSALObj.acquireTokenRedirect(requestObj);
        }
      });
  }

  authRedirectCallBack(error, response) {
    if (error) {
      console.log(error);
    } else {
      RaisedButton;
      if (response.tokenType === 'access_token') {
        callMSGraph(
          graphConfig.graphMeEndpoint,
          response.accessToken,
          graphAPICallback
        );
      } else {
      }
    }
  }

  requiresInteraction(errorCode) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return (
      errorCode === 'consent_required' ||
      errorCode === 'interaction_required' ||
      errorCode === 'login_required'
    );
  }

  render() {
    let language = this.props.store.language;
    let { direction, load } = this.state;
    let { logo, general_parameters, itensParams } = this.props;
    let codigoMS = null;
    try {
      this.store.params.map((g) => {
        itensParams = JSON.parse(this.decryp(g.token.toString()));
        itensParams = itensParams[0];
      });
    } catch (e) {}
    try {
      codigoMS = itensParams.microsoftid;
    } catch (e) {}

    if (this.state.authenticated && !this.state.userLoaded) this.loadUser();

    if (this.props.store.newUser) direction = 'left';

    var msalConfig = {
      auth: {
        clientId: codigoMS,
        authority: 'https://login.microsoftonline.com/common',
        redirectURI: 'http://localhost:3110/dash/',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    };

    var graphConfig = {
      graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
    };

    // create a request object for login or token request calls
    // In scenarios with incremental consent, the request object can be further customized
    var requestObj = {
      scopes: ['user.read'],
    };

    var myMSALObj = new Msal.UserAgentApplication(msalConfig);

    // Register Callbacks for redirect flow
    // myMSALObj.handleRedirectCallbacks(acquireTokenRedirectCallBack, acquireTokenErrorRedirectCallBack);
    myMSALObj.handleRedirectCallback(this.authRedirectCallBack);

    if (PAGE === 'expired' && !load) {
      setTimeout(() => {
        /*this.props.store.alerts = {
          msg: this.props.store.language.ERROR_CODE.SESSION_EXPIRED,
          type: 'error'
        };*/
        this.state.load = true;
      }, 1000);
    }
    //console.log(this.state.Logo)
    return (
      <MuiThemeProvider theme={theme}>
        <div id="WelcomeMessage"></div>
        <div id="loginBG">
          <Grid container spacing={3}>
            <Grid
              item
              xs={this.state.isMobile ? 12 : 6}
              className="d-flex align-items-center justify-content-center"
            >
              <div
                id="login-container"
                className="tw-duration-300 tw-ease-in-out"
                style={{
                  width: direction === 'settings' ? '50%' : '',
                  transitionProperty: 'width',
                }}
              >
                {this.state.Logo ? (
                  <img src={this.state.Logo} id="logo" />
                ) : null}
                {direction === 'right' ? (
                  <Login
                    myMSALObj={myMSALObj}
                    signIn={this.signIn}
                    general_parameters={general_parameters}
                    {...this.props}
                    handleDirection={this.handleDirection}
                  />
                ) : ['center', 'forgot', 'settings'].includes(direction) ? (
                  <ForgotPass
                    {...this.props}
                    handleDirection={this.handleDirection}
                    direction={direction}
                  />
                ) : null}
                <div>
                  {direction === 'right' &&
                    ([true].includes(
                      general_parameters.GOOGLE_LOGIN ||
                        general_parameters.google_login
                    ) ? (
                      <LoginGoogle onLogin={this.props.onLogin}></LoginGoogle>
                    ) : null)}
                  {[true].includes(general_parameters.microsoft_login) &&
                    direction === 'right' && (
                      <RaisedButton
                        variant="contained"
                        fullWidth={true}
                        style={{ margin: '15px 0', background: '#025398' }}
                        onClick={() => this.signIn(myMSALObj)}
                      >
                        <span>
                          <i class="fab fa-windows btn-icon" />
                          <span className="btn-label">Microsoft</span>
                        </span>
                      </RaisedButton>
                    )}
                  {[true].includes(general_parameters.enable_sso) &&
                    direction === 'right' && (
                      <RaisedButton
                        variant="contained"
                        fullWidth={true}
                        style={{ margin: '15px 0', background: '#0078d4' }}
                        onClick={() => this.signInAD()}
                      >
                        <span>
                          <i class="fab fa-microsoft btn-icon" />
                          <span className="btn-label">Microsoft Entra ID</span>
                        </span>
                      </RaisedButton>
                    )}{' '}
                </div>
              </div>
            </Grid>

            {!this.state.isMobile && (
              <Grid item xs={6}>
                <div id="img-Intersection-content" className="content">
                  <img src={Intersection} alt="" />
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

@observer
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      files: [],
    };
    this.saveData = this.saveData.bind(this);
    this.emailFound = this.emailFound.bind(this);
    this.msg = '';
  }
  componentDidMount() {}
  changeView = () => {
    this.setState({ view: !this.state.view });
  };

  saveData = (values) => {
    let filesSend = [];

    let cont = 0;

    if (values.files) {
      let total = Array.from(values.files).length;
      Array.from(values.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 10000000) {
            alert(language.FILE + file.name + ' ' + language.MORE_THAN_10MB);
            values.files = [];
            cont = 0;
            setTimeout(
              function () {
                this.handlePage('login', true);
              }.bind(this),
              2000
            );
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            let host = Constants.APIRoot;
            values.file = filesSend;
            values.description = values.description.replace(
              /<(?:.|\n)*?>/gm,
              ''
            );

            const action = new Actions();
            action.execute('post', `${host}/rall`, '', values)
              .then((results) => {
                if( results.data ) {
                  results = results.data;
                }

                this.setState({ sendFile: false });
                if (results.ok) {
                  Store.toggleAlert(
                    true,
                    language.REQUEST_OPENED_SUCCESSFULLY,
                    'success'
                  );
                  this.setState({ openModal: false });
                } else {
                  Store.toggleAlert(true, language.ERROR_REQUEST, 'error');
                }
                setTimeout(
                  function () {
                    this.handlePage('login', true);
                  }.bind(this),
                  2000
                );
              });
          }
        });
      });
    } else {
      let host = Constants.APIRoot;
      values.description.replace(/<(?:.|\n)*?>/gm, '');

      const action = new Actions();
      action
        .execute('post', `${host}/rall`, '', values)
        .then((results) => {
          if( results.data ) {
            results = results.data;
          }

          if (results.ok == true) {
            Store.toggleAlert(true, 'Solicitação Aberta com Sucesso!', 'success');
            this.setState({ openModal: false });
          } else {
            Store.toggleAlert(
              true,
              'Email não cadastrado na plataforma.',
              'error'
            );
          }
        });
    }
  };

  emailFound = (values) => {
    const action = new Actions();

    let { email } = values;

    if (!email) {
    } else {
      action.execute('post', Constants.APIEndpoints.FORGOT, 'forgot', {
        email: email,
        url: window.location.origin,
      });

      setTimeout(() => {
        if (this.store.codigo.toString() == 'E006') {
          this.store.toggleAlert(
            true,
            'Usuário não foi localizado na base dados como ativo!',
            'error'
          );
        } else {
          this.store.toggleAlert(true, 'Email localizado!', 'success');
        }
      }, 2000);
    }
  };

  render() {
    let { language } = this.props.store;
    let {
      onChangeField,
      onLogin,
      general_parameters,
      signIn,
      myMSALObj,
      onOpenSolicitation,
    } = this.props;
    let { view } = this.state;
    let buttons = { md: 12, label: language.SAVE, primary: true };

    return (
      <div id="Login">
        <TextField
          label={language.USERNAME}
          fullWidth={true}
          onChange={(evt) => onChangeField('username', evt.target.value)}
          className="mb-4"
          required
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fas fa-user tw-text-gray-300 tw-text-xs" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={language.PASSWORD + '*'}
          fullWidth={true}
          type={view ? 'text' : 'password'}
          onChange={(evt) => onChangeField('password', evt.target.value)}
          className="mb-2"
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onLogin(ev);
              ev.preventDefault();
            }
          }}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i
                  className={
                    'btn-view-password fas' +
                    (view ? ' fa-eye' : ' fa-eye-slash') +
                    (view ? ' default-color' : ' tw-text-gray-500')
                  }
                  onClick={this.changeView}
                />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <i className="fas fa-key tw-text-gray-300 tw-text-xs" />
              </InputAdornment>
            ),
          }}
        />
        <div className="tw-text-right">
          <span
            className="pointer mt-2 tw-rounded-full tw-px-3 tw-py-1 tw-transition tw-duration-300 tw-ease-in-out tw-text-xs tw-bg-gray-50 tw-text-gray-500 hover:tw-bg-gray-200 hover:tw-text-gray-700"
            // id="btn-fogot-password"
            onClick={() => this.props.handleDirection('forgot')}
          >
            {language.FORGOT_PASS}
          </span>
        </div>
        <div className="mt-5">
          <RaisedButton
            fullWidth={true}
            variant="contained"
            color="primary"
            className="btn"
            style={{ margin: '15px 0' }}
            onClick={onLogin}
          >
            <span className="btn-label">{language.ENTER}</span>
          </RaisedButton>

          {[true].includes(general_parameters.call_opening) ? (
            <RaisedButton
              fullWidth={true}
              variant="contained"
              color="#37474f"
              className="btn btn-support-tech"
              style={{ margin: '15px 0' }}
              onClick={() => this.setState({ openModal: true })}
            >
              <span className="btn-label">Suporte técnico</span>
            </RaisedButton>
          ) : null}
          <Dialog
            open={this.state.openModal}
            title="Suporte Técnico"
            onClose={() => this.setState({ openModal: false })}
            maxWidth="xl"
          >
            {/* ***************************************************************** */}
            <div class="mt-3" id="modalServiceTec">
              <CommonForm
                onSubmit={this.saveData}
                values={this.state.values}
                fields={[
                  {
                    col: 4,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="Nome"
                        subTitle="Digite aqui o seu nome"
                      />
                    ),
                    name: 'name',
                    type: 'text',
                    label: 'Nome',
                    required: true,
                  },
                  {
                    col: 4,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="Telefone"
                        subTitle="Digite aqui o telefone"
                      />
                    ),
                    name: 'phone',
                    type: 'text',
                    label: 'Telefone',
                    required: true,
                  },
                  {
                    col: 4,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="E-mail"
                        subTitle="Digite aqui o e-mail"
                      />
                    ),
                    name: 'email',
                    type: 'text',
                    label: 'E-mail',
                    required: true,
                  },
                  {
                    col: 4,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="Assunto"
                        subTitle="Digite aqui o assunto"
                      />
                    ),
                    name: 'subject',
                    type: 'text',
                    label: 'Assunto',
                    required: true,
                  },
                  {
                    col: 4,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="ACESSO REMOTO"
                        subTitle="Digite aqui o acesso remoto"
                      />
                    ),
                    name: 'id_access_remote',
                    type: 'text',
                    label: 'Acesso remoto',
                    required: false,
                  },
                  {
                    col: 9,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="DESCRIÇÃO *"
                        subTitle="DEIXE UMA BREVE DESCRIÇÃO SOBRE O SUPORTE1"
                      />
                    ),
                    name: 'description',
                    type: 'textEditor',
                    placeholder: 'Digite aqui uma descrição',
                    required: true,
                  },
                  {
                    col: 3,
                    colMobile: 12,
                    beforeContent: (
                      <MyHeader
                        title="ANEXAR"
                        subTitle="ARRASTE E SOLTE UM ARQUIVO DE IMAGEM AQUI OU CLIQUE."
                      />
                    ),
                    name: 'files',
                    type: 'file',
                    label: 'ANEXAR',
                    required: false,
                    contentProps: {
                      className: 'pt-2',
                    },
                  },
                ]}
              />
            </div>
            {/* ***************************************************************** */}
          </Dialog>
        </div>
      </div>
    );
  }
}

@observer
class ForgotPass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      confirmPassword: '',
    };
  }

  render() {
    let { language, redefine, forgot } = this.props.store;
    let { onChangeField, onRedefine, direction, onForgot, username, password } =
      this.props;
    const { newPassword, confirmPassword } = this.state;

    const onChange = (field, value) => {
      if (field === 'new_pass') {
        this.setState({ newPassword: value });
      }

      if (field === 'confirm') {
        this.setState({ confirmPassword: value });
      }

      onChangeField(field, value);
    };

    if (PAGE === 'redefine') {
      let url = new URL(window.location.href);
      let token = url.searchParams.get('t');

      if (!token) {
        window.location.href = window.location.origin;
      }
    }

    if (redefine || forgot) {
      this.props.handleDirection('right');
    }

    return (
      <div>
        {direction != 'forgot' && PAGE === 'redefine' ? (
          <LoginChangePassword
            recoveryToken={new URL(window.location.href).searchParams.get('t')}
          />
        ) : // <div>
        //   <div className="mb-4">
        //     <RecoveryPasswordHeader />
        //   </div>
        //   <TextField
        //     type="password"
        //     label={language.NEW_PASSWORD}
        //     fullWidth={true}
        //     onChange={(evt) => onChange('new_pass', evt.target.value)}
        //     className="mb-3"
        //     variant="outlined"
        //     InputLabelProps={{ shrink: true }}
        //     InputProps={{
        //       startAdornment: (
        //         <InputAdornment position="start">
        //           <i className="fas fa-key tw-text-gray-300 tw-text-xs" />
        //         </InputAdornment>
        //       ),
        //     }}
        //     error={
        //       this.state.newPassword &&
        //       !SensritUtilsPk.checkIfStringHasValidPasswordFn(
        //         this.state.newPassword
        //       )
        //     }
        //   />
        //   <PasswordRequirements
        //     language={language}
        //     style={{ marginBottom: '2rem', zoom: 0.9 }}
        //     password={this.state.newPassword}
        //   />
        //   <TextField
        //     type="password"
        //     label={language.CONFIRM_PASSWORD}
        //     fullWidth={true}
        //     onChange={(evt) => onChange('confirm', evt.target.value)}
        //     className="mb-4"
        //     variant="outlined"
        //     InputLabelProps={{ shrink: true }}
        //     InputProps={{
        //       startAdornment: (
        //         <InputAdornment position="start">
        //           <i className="fas fa-key tw-text-gray-300 tw-text-xs" />
        //         </InputAdornment>
        //       ),
        //     }}
        //     error={
        //       this.state.confirmPassword &&
        //       this.state.newPassword !== this.state.confirmPassword
        //     }
        //   />

        //   <StepButtons
        //     onPrev={() => this.props.handleDirection('right')}
        //     onNext={onRedefine}
        //     disableNextButton={
        //       !(
        //         SensritUtilsPk.checkIfStringHasValidPasswordFn(
        //           this.state.newPassword
        //         ) && this.state.newPassword === this.state.confirmPassword
        //       )
        //     }
        //     showPrev={false}
        //   />
        //   {/* <div className="tw-flex tw-justify-center tw-mt-6">
        //     <RaisedButton
        //       outlined={true}
        //       onClick={() => this.props.handleDirection('right')}
        //     >
        //       <span className="btn--label">
        //         <i className="fas fa-arrow-left mr-2" />
        //         {language.RETURN}
        //       </span>
        //     </RaisedButton>

        //     <RaisedButton
        //       fullWidth={true}
        //       primary={true}
        //       // style={{ margin: '16px 0' }}
        //       onClick={onRedefine}
        //       disabled={
        //         !(
        //           SensritUtilsPk.checkIfStringHasValidPasswordFn(
        //             this.state.newPassword
        //           ) && this.state.newPassword === this.state.confirmPassword
        //         )
        //       }
        //     >
        //       <span>{language.CHANGE_PASSWORD}</span>
        //     </RaisedButton>
        //   </div> */}

        //   {this.props.msnpass ? (
        //     <div className="alertpass">
        //       {this.props.msnpass.map((h) => (
        //         <div>{h}</div>
        //       ))}
        //     </div>
        //   ) : null}
        // </div>
        direction === 'settings' ? (
          <>
            <LoginRecoverySetting
              resetPasswordToken={this.props.password_recovery_code}
            />
          </>
        ) : (
          <div>
            {/* <h1 className="title-redefine-password">{language.FORGOT_PASS}</h1>

            <TextField
              type="email"
              label={language.EMAIL}
              fullWidth={true}
              onChange={(evt) => onChangeField('email', evt.target.value)}
            />
            <Button
              label={<span className="btn-label">{language.SEND}</span>}
              primary={true}
              style={{ margin: '15px 0', width: '100%' }}
              onClick={onForgot}
            />
            <div className="container-back d-flex align-item-center">
              <i className="fas fa-arrow-left mr-1" />
              <span
                className="pointer"
                onClick={() => this.props.handleDirection('right')}
              >
                {language.RETURN}
              </span>
            </div> */}

            <LoginRecoveryPassword
              onGoBack={() => this.props.handleDirection('right')}
            />
          </div>
        )}
      </div>
    );
  }
}
