import Store from '@data/Store';

const { language } = Store;

export function Categories({ data = [] }) {
  return {
    chart: {
      type: 'column',
      inverted: true,
      polar: true,
      background: 'transparent',

      height: 300,
    },
    title: null,
    tooltip: {
      outside: true,
    },
    pane: {
      size: '85%',
      innerSize: '20%',
      endAngle: 270,
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: 'right',
        useHTML: true,
        allowOverlap: true,
        step: 1,
        y: 3,
        style: {
          fontSize: '11px',
          color: '#A3A3A3',
        },
      },
      lineWidth: 0,
      categories: data.map((e) => e.label),
    },
    yAxis: {
      crosshair: {
        enabled: true,
        color: '#333',
      },
      labels: {
        style: {
          fontSize: 8,
        },
      },
      lineWidth: 0,
      tickInterval: 25,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
      },
    },
    credits: { enabled: false },
    legend: {
      // enabled: false
      itemStyle: {
        fontSize: 10,
        color: '#A3A3A3',
      },
    },
    series: [
      {
        name: language.TICKET_TYPES.SOLICITATION,
        color: 'var(--primary)',
        data: data.map((e) => e.solicitation),
      },
      {
        name: language.TICKET_TYPES.INCIDENT,
        color: 'var(--warning)',
        data: data.map((e) => e.incident),
      },
      {
        name: language.TICKET_TYPES.PROBLEM,
        color: 'var(--danger)',
        data: data.map((e) => e.problem),
      },
    ],
  };
}

export function SubCategories({ data, openModalDetail, categories, tp }) {
  return {
    chart: {
      renderTo: 'container',
      type: 'column',
    },
    title: null,

    xAxis: {
      type: 'category',
      labels: {
        style: {
          color: 'var(--primary)',
          fontSize: 8,
        },
      },
    },
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      title: null,
      labels: {
        style: {
          color: 'var(--primary)',
          fontSize: 8,
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y} </b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        borderRadius: 3,

        // borderWidth: 0,
        borderColor: 'red',
        dataLabels: {
          enabled: true,
          format: '{point.y:.0f}',
          color: 'var(--primary)',
          style: {
            fontSize: 8,
            color: 'var(--primary)',
          },
        },
        point: {
          events: {
            click: (e) => {
              let id = 0;

              id = categories.filter(
                (g) => g.name == e.point.name && g.tp == (tp || 'service')
              )[0].id;

              openModalDetail(tp || 'category', id);
            },
          },
        },
      },
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        name: '',
        data: data,
      },
    ],
  };
}
export function Department({ data }) {
  return {
    chart: {
      polar: true,
      type: 'area',
      height: 300,
      marginTop: 0,
    },

    title: null,

    pane: {
      // size: '80%'
    },

    xAxis: {
      categories: data.map((e) => e.name),
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          color: '#A0A0A0',
          fontSize: 8,
        },
      },
    },

    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      labels: {
        style: {
          color: '#A0A0A0',
          fontSize: 8,
        },
      },
    },

    tooltip: {
      shared: true,
      pointFormat: '<span style="color:{series.color}"><b>{point.y}</b><br/>',
    },

    plotOptions: {
      area: {
        fillOpacity: 0.3,
      },
    },

    legend: {
      enabled: false,
    },
    credits: { enabled: false },
    series: [
      {
        name: null,
        data: data.map((e) => e.value),
        pointPlacement: 'on',
      },
    ],
  };
}
