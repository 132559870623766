import React, {useState} from 'react';
import {toBase64} from '@common/Func';
import Button from '@common/Button';
import Dropzone from 'react-dropzone';
import Dialog from '@common/Dialog';
import defaultLogo from '@images/defaultImageAttach.png';
import { Redirect } from 'react-router';
export default function({open, onClose, APIEndpoints,redirect, language, action}){
	let [idx, setIdx] = useState(0);
	let [files, setFiles] = useState([]);
	
	const onDrop = (files) => {
	  setFiles(files);
	  
	}
	const updatelogo = ()=> {
	  toBase64(files, function(result){
	  	let upload = { file: result.base64, type: result.file.type, namefile: result.file.name };
	  	action.execute(
	  	  'post',
	  	   APIEndpoints.COMPANY + '/savelogo',
	  	  'bsc',
	  	  upload
	  	).then(e=>{
			  
			sessionStorage.setItem('imagePreview', e.data);
		    
			setTimeout(() => {
				onClose()
			}, 2000);
	  	});
	  })
	}
	return(
		<Dialog
		  open={open}
		  onClose={onClose}
		  miniVersion = {true}
		  title={language.REGISTER_COMPANY_LOGO}
		  maxWidth="xs"
		>
		  <div className="d-flex justify-content-center">
		    <Dropzone
		      style={{ display: 'inline-block' }}
		      accept="image/*"
		      required
		      onDrop={onDrop}
		      multiple={false}
		    >
		      {/*<img id="idimage" width="110px" height="80px" src={'data:image/png;base64,'+bsc}/>*/}
		      <img
		        id="idimage"
		        style={{ width: '100%' }}
		        src={
		          !!files.length
		            ? files[0].preview
		            : defaultLogo
		        }
		      />
		    </Dropzone>
		  </div>

		  <div className="d-flex justify-content-center">
		    <Button
		      variant='normal'
		      label={language.SAVE}
		      icon="fas fa-save"
		      color='success'
		      onClick={updatelogo}
		    />
		  </div>
		</Dialog>
	)
}