import React, {memo,useEffect, useState, Fragment,  useReducer} from 'react';
import AppBar from '@common/AppBar2';
import {Card} from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import {ActivityConcluded} from './Graph';
import ButtonControlCarousel from './ButtonControlCarousel';

export default memo(({
	language,
	data,
	ac,
	tickets, sprintkaban ,
	users
})=>{
	 let techs=[];
	 let companies=[];
	 
		ac.filter(c=>c.percent<100).map(g=>{
		 
		  g.usersid.map(i=>{
			  
				 if(techs[i]){
				  techs[i]=techs[i]+1
				 }else{
				  techs[i]=1;
				 }
			  })
  })
			   
	 tickets.map(i=>{
		 
				if(i.fk_id_tech&& i.progress<100){
			   if(techs[i.fk_id_tech]){
				   techs[i.fk_id_tech]=techs[i.fk_id_tech]+1
				  }else{
				   techs[i.fk_id_tech]=1;
				  }
			   } 

			  if(companies[i.company_name]){
				companies[i.company_name]=companies[i.company_name]+1
			   }else{
				companies[i.company_name]=1;
			   }

        }) 

 

	 
		  let a=[];
		  let b=[];
		 
			  techs.map((v,i)=>{
				let name="";
				try{
				   name= users.filter(c=>c.id_user == i)[0].name
				}catch(e){

				}
			     a.push(name) 
				  
				b.push(techs[i])
			  })
 console.log(companies.map(g=>g))
	return(
  		<Card className='h-100'>
			<AppBar title={language.ACTIVITY_IN_EXECUTION}>
  				 
  			</AppBar>
		 
  			<div className='content pt-0'>
				<ReactHighcharts
				  config={ActivityConcluded({
				  	categories:a,
				  	data:b, //isso é um lorem, que gera 12 itens aleatórios
				  })}
				/>
				<br></br>
				<ReactHighcharts
				  config={ActivityConcluded({
				  	categories:Object.keys(companies),
				  	data: Object.keys(companies).map(g=>companies[g])  //isso é um lorem, que gera 12 itens aleatórios
				  })}
				/>
  			</div>
  		</Card>
	)
})