import React, { Component } from 'react';
import './index.css';
import RaisedButton from '@common/RaisedButton';
import { Checkbox } from '@material-ui/core';
import { Select, MenuItem, Card, Grid, TextField } from '@material-ui/core'; // controls
import { Container, Col } from 'react-grid-system';
import CommonTable from '@common/CommonTable';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';

import ConnectForm from './ConnectForm';
import AssessmentForm from './AssessmentForm';
import Header from '@common/Header';
import $ from 'jquery';
window.$ = $;
export default class AssessmentPage extends Component {
  constructor() {
    super();
    this.state = {
      contract: '',
      device: '',
      connection: '',
      tickets: [],
      form:null,
      keyboard:undefined,
      user:null,
      password:null,
      domain:null
    };
    this.onCheckTicket = this.onCheckTicket.bind(this);
    this.onChangeContract = this.onChangeContract.bind(this);
    this.onChangeDevice = this.onChangeDevice.bind(this);
    this.startAssessment = this.startAssessment.bind(this);
  }

  onChangeContract(value) {
    this.setState({ contract: value });
  }

  onChangeDevice(value) {
    this.props.action.execute('post', this.props.APIEndpoints.DEVICE + '/filter', '',{id_device :value} ).then(item=>{
      this.setState({ device: item.data[0], connection:  item.data[0].connection });
      this.props.onChangeDevice( item.data[0],  item.data[0].ip);
    });
  }

  onCheckTicket(ticket) {
    let { tickets } = this.state;
    if (tickets.includes(ticket)) {
      tickets = tickets.filter(t => t !== ticket);
    } else {
      tickets.push(ticket);
    }
    this.state.tickets = tickets;
  }

  startAssessment() {
    let { contract, device, tickets, connection,keyboard,user,password,domain } = this.state;
    this.props.startAssessment(contract, device, tickets, connection, keyboard, user, password, domain);
  }

  removeDuplicates(originalArray) {
    let newArray = [];
    originalArray.map(g => {
      if (JSON.stringify(newArray).indexOf(JSON.stringify(g)) == -1) {
        newArray.push(g);
      }
    })
    return newArray;
  }

  render() {
    let { contracts, language, tickets = [],action, redirect, usercon_company, assessment, connection, formColor ,APIEndpoints } = this.props;
    let { contract, device } = this.state;

    let deviceOpts = [];

    var options = {
      contracts:() => contracts.map(e => ({value:e.id_contract, label:e.name})),
      devices:() => (
        contract?
          contracts.find(c => c.id_contract == contract).device.filter(c=>c.type=='server').map(e => ({value:e.id_device, label:e.name+' - '+e.ip}))
        :[]
      )
    }

    return (
      <PageLayout
        title={language.ASSESSMENT}
        subTitle={language.DASHBOARD_PAGE.GCF + '/'}
        rightElements={[]}
      >
        <div id='AssessmentPage'>
          <Card className='content card' style={{'--formColor':formColor}}>
            <Grid container spacing={16}>
              <Grid item xs={5}>
                <div id='content-form-access' className='content'
                 style={{overflowY:'scroll'}}>
                  <div>
                    <h3 id='title' className='mb-5'>{language.RUN_REMOTE_ACCESS}</h3>
                    <CommonForm
                      onRef={r=>{this.setState({form:r})}}
                      fields={[
                        {
                          type:'element',
                          content:(
                            <div className='c-header'>
                              <h4>{language.SELECT_USER}</h4>
                              <hr/>
                            </div>
                          )
                        },
                        {
                          type:'select',
                          name:'contracts',
                          label:language.CONTRACT_MANAGEMENT,
                          className:'fields',
                          required:true,
                          options:options.contracts()
                        },
                        {
                          type:'element',
                          content:(
                            <div className='c-header'>
                              <h4>{language.USER}</h4>
                              <hr/>
                            </div>
                          )
                        },
                        {
                          type:'text',
                          name:'name',
                          className:'fields',
                          label:language.NAME,
                        },
                        {
                          type:'password',
                          name:'password',
                          className:'fields',
                          label:language.PASSWORD,
                        },
                        {
                          type:'text',
                          name:'domain',
                          className:'fields',
                          label:language.DOMAIN,
                        },
                        {
                          type:'element',
                          content:(
                            <div className='c-header'>
                              <h4>{language.SELECT_DEVICE}</h4>
                              <hr/>
                            </div>
                          )
                        },

                       { type: 'autocomplete',
                       name: 'device',
                       label: '',
                       textlabel: '',
                       method: 'POST',
                       route: `${APIEndpoints.DEVICE}/filter/`,
                       routeGetCount: `${APIEndpoints.DEVICE}/filter?count=0`,
                       fieldquery: 'search',
                       textinit:  '',
                       fieldvaluedb: 'id_device',
                       fieldlabeldb: 'name',
                       idinit:  '',
                       routeAll: `${APIEndpoints.DEVICE}/filter?`,
                       filterAll: { type:  2 , fk_id_contract:contract},
                       methodGetAll: 'POST',
                       required: true,
                       showEmail: true },

                         {
                          type:'select',
                          name:'keyboard',
                          className:'fields',
                          label:language.OPTIONAL_KEYBOARD,
                          required:false,
                          options:[
                          {value:1,label:'PT BR ABNT2'},
                          {value:2,label:'PT BR '},
                          {value:3,label:'EN US'},
                          {value:4,label:'ES ES'},
                          {value:5,label:'FR FR'}
                         ]
                        },
                      ]}
                      onChangeField={(f,v)=>{
                        if(f.name=='contracts') this.setState({contract:v});
                        if(f.name=='device') this.onChangeDevice(v) ;
                        if(f.name=='name') this.setState({user:v});
                        if(f.name=='password') this.setState({password:v});
                        if(f.name=='domain') this.setState({domain:v});
                        if(f.name=='keyboard') this.setState({keyboard:v});
                      }}
                      onSubmit={v=>null}
                      hiddenSubmit
                    />
                  </div>
                  <div className='d-flex align-items-center justify-content-end'>
                    <Button
                      icon={<AddToQueueIcon style={{color: 'var(--warning)'}}/>}
                      color='warning'
                      label={language.ADD_CREDENTIALS}
                      className='mr-3'
                    />
                    <Button
                      icon='fas fa-play'
                      color='success'
                      label={language.CONNECT}
                      onClick={()=>this.startAssessment()}
                    />
                  </div>
                </div>
              </Grid>
               </Grid>
          </Card>
        </div>
      {/*-----------------------------------ConnectForm------------------------------------*/}
      <ConnectForm language={this.props.language} open={false} onClose={()=>null}/>
      {/*-----------------------------------AssessmentForm------------------------------------*/}
      <AssessmentForm language={this.props.language} open={false} onClose={()=>null} />
      </PageLayout>
    );
  }
}
