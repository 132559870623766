import React, {useState} from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default function({
	language,
	open,
	onClose
}){
	let [form, setForm] = useState(null);
	return(
		<Dialog
			open={open}
			onClose={onClose}
			bar={false}
			PaperProps={{
			  id: 'AssessmentForm'
			}}
			
			dialogChildren={
			  <div
			    className="d-flex align-items-start justify-content-between bar"
			    style={{
			      background: `url(${BarBlue})`,
			      borderBottom: '3px solid var(--warning)'
			    }}
			  >
			    <div className="pt-3 pb-1 pl-3 w-100">
			      <div className="d-flex align-items-start">
			        
			        <div className="w-100">
			          <CommonForm
			            
			            alert={false}
			            fields={[
			              {
			                type: 'text',
			                name: 'name',
			                label: language.DEVICE_NAME,
			                className: 'subject-ticket',
			                required:true
			              }
			            ]}
			            onRef={(r)=>setForm(r)}
			            onChangeField={(f, v) => setForm({ name: v })}
			          />
			        </div>
			      </div>
			    </div>
			    <IconButton
			      aria-label={language.CLOSE}
			      className="closeButton"
			      onClick={onClose}
			    >
			      <CloseIcon style={{ color: 'white' }} />
			    </IconButton>
			  </div>
			}
		>
			<CommonForm
				onRef={r=>setForm(r)}
				fields={[
					{
					  col:4,
					  type:'text',
					  name:'user',
					  label: language.USERNAME,
					},
					{
					  col:4,
					  type:'password',
					  name:'password',
					  label: language.PASSWORD,
					},
					{
					  col:4,
					  type:'text',
					  name:'domain',
					  label: language.DOMAIN,
					},
					{
						type:'element',
						content: <hr className='my-0'/>
					}
				]}
				beforeButtonElement={
					<Button
						label={language.CANCEL}
						icon='fas fa-times'
						color='danger'
						className='mr-3'
						variant='normal'
						style={{
							height:40
						}}
					/>
				}
				_onSubmit={()=>form.submit()}
				button={{
					style:{height:40}
				}}
				onSubmit={v=>null}
			/>
		</Dialog>
	)
}