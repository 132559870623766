import React, { Fragment, useState, useEffect } from 'react';
import CommonPage from '../../../common/CommonPage';
import CommonDelete from '../../../common/CommonDelete';
import PageLayout from '../../../common/PageLayout';
import SubjectList from './SubjectList';
import SubjectForm from './SubjectForm';
import TemplateSubjectForm from './TemplateSubjectForm';
import TemplateCloseSubjectForm from './TemplateCloseSubjectForm';
import Store from '@data/Store';
import TaskList from '../task/TaskList';
import TaskForm from '../task/TaskForm';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const SubjectContainer = () => {
  const [listWorkday, setListWorkday] = useState([]);
  const [themeOptions, setThemeOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [page, setPage] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [listSubjects, setListSubjects] = useState([]);
  const [countSubjects, setCountSubjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [topicSelected, setTopicSelected] = useState([]);
  const [templateSelected, setTemplateSelected] = useState([]);
  const [listTasks, setListTasks] = useState([]);
  const [taskSelected, setTaskSelected] = useState([]);
  const [templateCloseSelected, setTemplateCloseSelected] = useState([]);

  const language = Store.language;
  const APIEndpoints = Constants.APIEndpoints;

  const callPageSelectFilterTask = (page = 0, values = {}, limit = 75) => {
    setCurrentPage(page);
    action
      .execute('post', `${APIEndpoints.SAC_TASKS}/getAllTasks??limit=${limit}&offset=${0 * 75}`, '', values)
      .then((data) => {
        setListTasks(data.data);
      });
  };

  const getWorkday = () => {
    action
      .execute('get', `${APIEndpoints.SLA_WORK}/workday`, '')
      .then((data) => {
        setListWorkday(data.data);
      });
  };

  const onFormSubmit = (values) => {
    const method = values.id_sac_task ? 'PUT' : 'POST';
    const url = values.id_sac_task
      ? `${APIEndpoints.SAC_TASKS}/updateTask`
      : APIEndpoints.SAC_TASKS;

    let payload = {
      ...values,
      user: Store.getUserLoged(),
      id_sac_subject: topicSelected.id_sac_subject,
    };

    action
      .execute(method, url, '', payload)
      .then((res) => {
        handlerPageTask('open_task_list');
      })
      .catch((e) => {
        console.log('Error:', e);
      });
  };

  const callPageSelectFilter = (page = 0, values = {}, limit = 75) => {
    setCurrentPage(page);

    action
      .execute('post', `${APIEndpoints.SAC_SUBJECTS}/getAllSubjects?limit=${limit}&offset=${page * limit}`, '', values)
      .then((data) => {
        setListSubjects(data.data);
        setCountSubjects(data.data[0] ? data.data[0].total_count : 0);
      });
  };

  useEffect(() => {
    callPageSelectFilter();
    getWorkday();

    action
      .execute('get', `${APIEndpoints.TABLE_STRUCTURED}/130`, '')
      .then((data) => {
        setTypeOptions(
          data.data.map((t) => ({ value: t.id_table, label: t.description }))
        );
      });
  }, []);

  const getAllThemes = () => {
    action
      .execute('post', `${APIEndpoints.SAC_SUBJECTS}/getAllThemesForSubjects?`, '')
      .then((data) => {
        setThemeOptions(data.data);
      });
  };

  const getAllTemplates = () => {
    action
      .execute('post', `${APIEndpoints.SAC_SUBJECTS}/getAllTemplatesForSubjects?`, '')
      .then((data) => {
        setTemplateOptions(data.data);
      });
  };

  useEffect(() => {
    getAllThemes();
    getAllTemplates();
  }, []);

  const handlerPageTask = (page, obj) => {
    callPageSelectFilterTask(
      0,
      { id_sac_subject: topicSelected.id_sac_subject },
      75
    );
    setPage('open_task_list');
  };

  const handlePage = (page, obj) => {
    setPage(page);

    if (page !== 'form_task') {
      setTopicSelected(obj);
    } else {
      setTaskSelected([]);
    }

    if (page === 'open_task_list') {
      callPageSelectFilterTask(0, obj, 75);
    }
    if (page === 'form') {
      setTaskSelected([]);
    }
    if (page === 'template') {
      setTemplateSelected(obj);
    }
    if (page === 'template_close') {
      setTemplateCloseSelected(obj);
    }
    if (page === 'remove') {
      setTaskSelected(obj);
    }
    if (page === 'remove_subject') {
      setTopicSelected(obj);
    }
    if (page === 'edit_task') {
      setTaskSelected(obj);
    }
    setPage(page);
  };

  const onSubmitSubjectAndExit = (values) => {
    let method = 'POST';
    if (values && values.id_sac_subject) {
      method = 'PUT';
    }
    const url =
      method === 'PUT'
        ? `${APIEndpoints.SAC_SUBJECTS}/updateSubject`
        : APIEndpoints.SAC_SUBJECTS;

    action
      .execute(method, url, '', { ...values, user: Store.getUserLoged() })
      .then((res) => {
        handlePage('list');
        callPageSelectFilter();
      })
      .catch((e) => {
        console.log('Error:', e);
      });
  };

  const onSubmitSubjectAndProceed = (values) => {};

  const onDelete = () => {
    const id = taskSelected.id_sac_task;

    const values = { id, user: Store.getUserLoged() };

    action
      .execute('put', `${APIEndpoints.SAC_TASKS}/deleteTask`, '', values)
      .then((data) => {
        callPageSelectFilter();
        handlerPageTask('open_task_list');
      });
  };

  const onDeleteSubject = () => {
    const id = topicSelected.id_sac_subject;

    const values = { id, user: Store.getUserLoged() };

    action
      .execute('put', `${APIEndpoints.SAC_SUBJECTS}/deleteSubjects`, '', values)
      .then((data) => {
        callPageSelectFilter();
        setPage('list');
      });
  };

  const addTemplateToSubjects = (values) => {
    const method = 'POST';
    const url = `${APIEndpoints.SAC_SUBJECTS}/addTemplateToSubjects`;

    action
      .execute(method, url, '', { ...values, user: Store.getUserLoged() })
      .then((res) => {
        handlePage('list');
        callPageSelectFilter();
      })
      .catch((e) => {
        console.log('Error:', e);
      });
  };

  const addCloseTemplateToSubjects = (values) => {
    const method = 'POST';
    const url = `${APIEndpoints.SAC_SUBJECTS}/addCloseTemplateToSubject`;

    action
      .execute(method, url, '', { ...values, user: Store.getUserLoged() })
      .then((res) => {
        handlePage('list');
        callPageSelectFilter();
      })
      .catch((e) => {
        console.log('Error:', e);
      });
  };

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.SUBJECTS_LIST}
        subTitle={language.CONSUMER_SERVICE_SYSTEM}
      >
        <Fragment>
          <SubjectList
            language={language}
            handlePage={handlePage}
            setPage={setPage}
            topicSelected={topicSelected}
            editSubject={onSubmitSubjectAndExit}
            listSubjects={listSubjects}
            currentPage={currentPage}
            callPageSelectFilter={callPageSelectFilter}
            countSubjects={countSubjects}
            callPageSelectFilterTask={callPageSelectFilterTask}
          />
          {page === 'form' ? (
            <SubjectForm
              language={language}
              themeOptions={themeOptions}
              topicSelected={topicSelected}
              callPageSelectFilter={callPageSelectFilter}
              handlePage={handlePage}
              onSubmitSubjectAndExit={onSubmitSubjectAndExit}
              onSubmitSubjectAndProceed={onSubmitSubjectAndProceed}
              onDelete={onDelete}
              values={topicSelected}
              typeOptions={typeOptions}
            />
          ) : null}
          {page === 'template' ? (
            <TemplateSubjectForm
              language={language}
              addTemplateToSubjects={addTemplateToSubjects}
              templateSelected={templateSelected}
              templateOptions={templateOptions}
              callPageSelectFilter={callPageSelectFilter}
              handlePage={handlePage}
            />
          ) : null}
          {page === 'template_close' ? (
            <TemplateCloseSubjectForm
              language={language}
              addCloseTemplateToSubjects={addCloseTemplateToSubjects}
              topicSelected={topicSelected}
              templateOptions={templateOptions}
              handlePage={handlePage}
              templateCloseSelected={templateCloseSelected}
            />
          ) : null}

          {page === 'open_task_list' ? (
            <TaskList
              language={language}
              handlePage={handlePage}
              listTasks={listTasks}
              isStep={false}
            />
          ) : null}

          {page === 'form_task' || page === 'edit_task' ? (
            <TaskForm
              language={language}
              onFormSubmit={onFormSubmit}
              listSubjects={listSubjects}
              listTasks={listTasks}
              listWorkday={listWorkday}
              taskSelected={taskSelected}
              templateOptions={templateOptions}
              handlerPageTask={handlerPageTask}
              values={taskSelected}
            />
          ) : null}

          {page === 'remove_subject' ? (
            <CommonDelete
              language={language}
              closeModal={() => handlePage('list')}
              onDelete={onDeleteSubject}
            />
          ) : null}
          {page === 'remove' ? (
            <CommonDelete
              language={language}
              closeModal={() => handlePage('list')}
              onDelete={onDelete}
            />
          ) : null}
        </Fragment>
      </PageLayout>
    </CommonPage>
  );
};

export default SubjectContainer;
