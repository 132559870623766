import React, { useEffect, useState, useCallback } from 'react';

import { Grid, Card } from '@material-ui/core';
import AppBar2 from '@common/AppBar2';
import RadialGraph from '@common/RadialGraph';

import Constants from '@data/Constants';

const tenant = Constants.Tenant;

const DevicesAlert = ({ ioAlertsServers }) => {
  const [graficPercent, setGraficPercent] = useState([
    { name: 'Ok', value: 0, color: 'var(--success)' },
    { name: 'Warning', value: 0, color: 'var(--warning)' },
    { name: 'Danger', value: 0, color: 'var(--danger)' },
  ]);
  const [oks, setOks] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [dangers, setDangers] = useState([]);

  useEffect(() => {
    if (ioAlertsServers) {
      ioAlertsServers.on(`${tenant}_alerts`, (data) => {
        try {
          setOks(data.alertsall.countOk);
          setWarnings(data.alertsall.countYellow);
          setDangers(data.alertsall.countRed);

          graficPizza(
            data.alertsall.countOk.length,
            data.alertsall.countYellow.length,
            data.alertsall.countRed.length
          );
        } catch (error) {}
      });
    }

    return () => ioAlertsServers.off(`${tenant}_alerts`);
  }, []);

  const graficPizza = useCallback((ok, warning, danger) => {
    let denominator = ok + warning + danger;
    let okPercent = ok / denominator;
    let warningPercent = warning / denominator;
    let dangerPercent = danger / denominator;
    let valueOk = Math.floor(okPercent * 360);
    let valueWarning = Math.floor(warningPercent * 360);
    let valueDanger = Math.floor(dangerPercent * 360);

    setGraficPercent([
      { name: 'Ok', value: valueOk, color: 'var(--success)' },
      { name: 'Warning', value: valueWarning, color: 'var(--warning)' },
      { name: 'Danger', value: valueDanger, color: 'var(--danger)' },
    ]);
  }, []);

  const companyId = 1;
  return (
    <Card className="h-100">
      <AppBar2 title="ALERTA DE DISPOSITIVOS" />
      <div
        className="content-medium d-flex align-items-end"
        style={{ height: 'calc(100% - 70px)' }}
      >
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          className="b1-c1-content"
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <RadialGraph
              data={graficPercent}
              outerRadius={60}
              innerRadius={45}
              eProps={{
                centerValue: true,
                centerProps: { style: { fontWeight: 'bold', fontSize: 22 } },
              }}
              height={150}
              width={150}
            />
          </Grid>
          {[
            {
              value:
                oks.length > 0
                  ? oks.filter((h) => h.id_company == companyId).length
                  : 0,
              color: 'var(--success)',
            },
            {
              value:
                warnings.length > 0
                  ? warnings.filter((h) => h.id_company == companyId).length
                  : 0,
              color: 'var(--warning)',
            },
            {
              value:
                dangers.length > 0
                  ? dangers.filter((h) => h.id_company == companyId).length
                  : 0,
              color: 'var(--danger)',
            },
          ].map((e, i) => (
            <Grid
              item
              xs={4}
              className="item"
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => {}}
            >
              <div className="content-indicator">
                <div
                  key={e}
                  className="mr-1"
                  style={{ backgroundColor: e.color }}
                />
                <span style={{ color: e.color }}>
                  {i == 0 ? 'Ok' : i == 1 ? 'Warning' : 'Danger'}
                </span>
              </div>
              <h2>
                {e.value}
                <i className="fas fa-eye ml-1" />
              </h2>
            </Grid>
          ))}
        </Grid>
      </div>
    </Card>
  );
};

export default DevicesAlert;
