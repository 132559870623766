import React, {useCallback, memo,  Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';

import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

import Dialog from '@common/Dialog'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from "react-window";
import {AutoSizer} from 'react-virtualized';
import Card from './Card';
import { Droppable } from 'react-drag-and-drop';
import ViewActivity from '../../../../Projects/Project/ViewActivity';
import Form from '../../../../Projects/Project/Form';
import FormProject from '../../../../Projects/ProjectsList/Form';
import Options from '../../../../Projects/ProjectsList/options';
import DownloadFiles from '../../../../../util/DownloadFiles';

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

let action = new Actions();


const CustomScrollbars = ({ onScroll, forwardedRef, style, children,onAdd_ }) => {
  const refSetter = useCallback(scrollbarsRef => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <PerfectScrollbar
      	ref={refSetter}
        style={{ ...style, overflow: "hidden" }}
        className='pb-2'
        onScroll={onScroll}
        options={{
	        minScrollbarLength:30
	    }}
    >
      
      {children}
      
    </PerfectScrollbar>
  );
};

let act = (e) => [...e.utils.DONE, ...e.utils.BACKLOG, ...e.utils.DOING, ...e.utils.STOPPED, ...e.utils.VALIDATION, ...e.utils.TODO];
export default memo(({id_project_sprint, setSprintKanban, data,onAdd_, title,onDrop, onDelete=()=>null, onClickStar=()=>null, handleModalId, options, users, redirect2}) =>{
  
   let [viewOpen, setViewOpen] = useState({});
   let [formOpen, setFormOpen] = useState({});
   let [formOpenProject, setFormOpenProject] = useState({});

   let {APIEndpoints} = Constants;

   function onOpenForm(act){
      
      setFormOpen({activity:(viewOpen||formOpen).activity, project:(viewOpen||formOpen).project});
   }

   let onView = (id, pj) => {
      //console.log(pj)
      let _act = act(pj).find(e => e.id_project_activity==id)||{};
      
      if((_act.usersid||[]).includes(Store.getUserLoged().id)||
         viewOpen.project.fk_id_sponsor == Store.getUserLoged().id ||
         Store.getUserLoged().role =='client'
      ){
         setViewOpen(viewOpen => ({activity:_act, project:viewOpen.project}));
      }else{
         Store.toggleAlert(true, 'Você não tem acesso a essa atividade', 'error');
      }
   };

   function onSubmitActivitieAntecessor(values){
      action.execute(
         'put',
         Constants.APIRoot + '/api/project/activityantecessor',
         'project',
         values,
         'id_project'
      ).then(e=>{
         onView(values.id_project_activity, e.data);
      });
   }

   function onDownloadFile(id_project_activity, name){
      DownloadFiles(APIEndpoints.REQUESTS + '/downloadgp/' + id_project_activity + '&' + name, name)
   }

   function onSubmitComent(values){
      action.execute(
         'post',
         APIEndpoints.PROJECT + '/comments',
         'project',
         values,
         null,
         {btn_loading:'comments'}
      ).then(e=>{
         
         onView(values.fk_id_project_activity, e.data)
      })
   }
   let onSubmit = (v, isDuplicate = false)=>{
      if(v.files && v.files.length){
          v.files = v.files.map(e=>({
             id_project_activity: v.id_project_activity,
             file:e.base64,
             name:e.file.name,
             size:e.file.size,
             type:e.file.type,
         }))
      }
      
      action.execute('put', `${Constants.APIRoot}/api/project/activity`, 'project', v, 'id_project', {btn_loading:'load_activity'}).then(e=>{
         setFormOpen({})
         action
         .execute('post', APIEndpoints.PROJECT + '/sprint/kaban', '', {
            id_project_sprint: id_project_sprint
         }, null, null, false)
         .then(v => {
            setSprintKanban({  sprintkaban: [v.data] });
            
            onView(v.id_project_activity, e.data);
         });
      });
   }

   function onUpdate(type){
      action
         .execute('post', APIEndpoints.PROJECT + '/sprint/kaban', '', {
            id_project_sprint: id_project_sprint
         }, null, null, false)
         .then(v => {
            
            setSprintKanban({  sprintkaban: [v.data] });
            
            if(type==1){
               setViewOpen(vo => ({
                  project:vo.project,
                  activity:v.data.ac.find(e=>e.id_project_activity==vo.activity.id_project_activity)
               }))
            }
         });
   }

   useEffect(()=>{
      action.execute('get', APIEndpoints.COMPANY, 'companies');
      action.execute('get', APIEndpoints.CONTRACT+'low', 'contracts');
   }, [formOpenProject])


   let options2 = Options({
      companies:Store.companies,
      users:Store.users,
      language:Store.language,
      project_stages:Store.project_stages,
      contracts:Store.contracts,
      tickets:Store.tickets,
      departments:Store.departments
   });

  return (
     <Fragment>
        {!!Object.keys(viewOpen).length && (
            <ViewActivity 
               open={!!Object.keys(viewOpen).length} 
               data={viewOpen.activity}
               onOpenForm={onOpenForm}
               onClose={() => setViewOpen({})}
               users={users}
               options={options}
               project={viewOpen.project}
               action={action}
               APIEndpoints={APIEndpoints}
               subActivities={Store.subActivities}
               onSubmit={onSubmitComent}
               onSubmitActivitieAntecessor={onSubmitActivitieAntecessor}
               onDownloadFile={onDownloadFile}
               onUpdate={onUpdate}
            />
         )}
         {!!Object.keys(formOpen).length &&
            <Form 
               open={!!Object.keys(formOpen).length}
               onClose={() => {
                  setFormOpen({});
               }}
               action={action}
               APIEndpoints={APIEndpoints}
               values={formOpen.activity}
               options={options}
               project={formOpen.project}
               idProject={formOpen.project.id_project}
               onSubmit={onSubmit}
               onDownloadFile={onDownloadFile}
               ondeletefile={()=>'ondeletefile'}
            />
         } 
         <FormProject
            project_stages={Store.project_stages}
            open={!!Object.keys(formOpenProject).length}
            values={formOpenProject}
            options={options2}
            handleValue={v=>setFormOpenProject(v||{})}
            onUpdate={onUpdate}
            execute={action.execute}
            APIEndpoints={APIEndpoints}
            onClose={() => {
               setFormOpenProject({});
            }}
         />
         <div className='column'>
            <header className='content-medium d-flex align-items-center justify-content-between'>
               <span>{title}</span>
               <div>
                  {data.length}
               </div>
            </header>
            <hr className='mt-1'/>
            <div className='body' style={{height:'55vh'}}>
               {data.length?(
                                    
                  <AutoSizer style={{height:'auto', width:'auto'}}>
                        
                     {({height, width})=>(
                     
                        <FixedSizeList
                              
                        height={height}
                              itemCount={data.length}
                              itemSize={160}
                                 
                                 outerElementType={CustomScrollbarsVirtualList}
                           
                                 >
                           {({ index, style })=>(
                                 <div style={style} className='content py-0 d-flex align-items-center justify-content-center'>
            
                           <Card {...data[index]}
                           options={options}
                           redirect2={redirect2}
                           setViewOpen={setViewOpen}
                           setFormOpen={setFormOpen}
                           setFormOpenProject={setFormOpenProject}
                           activity={data[index]}
                           onAdd_={onAdd_}
                              handleModalId={handleModalId} onDelete={()=>onDelete(index)} onClickStar={(e)=>onClickStar(index, e)}/>
                                 
                              </div>
                           )}
                           
                        </FixedSizeList>
                        
                        )}
                     
                  </AutoSizer>
               ):(
                  <h3
                  style={{ color: 'white', fontWeight: 100, fontSize: 16, textAlign:'center' }}
                  className="mt-2"
                  >
                       {Store.language.NOTHING_REGISTERED}
                  </h3>
               )}
            </div>
         </div>
   </Fragment>
  );
})
