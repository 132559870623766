import moment from 'moment-timezone';
import Store from '@data/Store';

//Lógicas no frontend, utilizar sempre timezone do browser
const _concatDateHour = (date, hour) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTz = moment.tz(date, 'YYYY-MM-DD HH:mm:ss', localTimezone).tz(localTimezone);
  const hourTz = moment.tz(hour, 'YYYY-MM-DD HH:mm:ss', localTimezone).tz(localTimezone);
  const formated =
    dateTz.format('YYYY-MM-DD')
    + ' ' +
    hourTz.format('LTS');
  return formated;
};

const _moment = (date, format) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!date) moment.tz(localTimezone);
  const formated =
    moment.tz(date, format ?? 'YYYY-MM-DD HH:mm:ss', localTimezone).tz(localTimezone);
  return formated;
};

export {
  _moment as moment,
  _concatDateHour as concatDateHour,
};
