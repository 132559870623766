import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import PasswordList from './PasswordList';
import PasswordForm from './PasswordForm';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import CommonForm from '@common/CommonForm2';
// import PasswordStrengthBar from 'react-password-strength-bar';
import Button from '@common/Button';
import SimplesAlert from '@common/SimplesAlert';

const PasswordStrengthBar = React.lazy(() =>
  import('react-password-strength-bar')
);

import './PasswordContainer.css';
import Store from '@data/Store';

import Animate from '@common/Animate';
@observer
export default class PasswordContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
      passwords: [],
      openDialog: false,
      historicPasswords: [],
      showPass: false,
      passToShow: '',
      itemPass: '',
      passAssistent: false,
      numberChar: '',
      hasLower: false,
      hasUpper: false,
      hasSymbols: false,
      hasNumbers: false,
      generatePassword: '',
      passCompanies: [],
      modalCompany: false,
      companies: [],
      companySelected: [],
      allPass: [],
      modalNewPass: false,
      valuesNewPass: [],
      pass: [],
      hasMaster: true,
      modalMaster: true,
      showMaster: false,
      passMaster: '',
      alert: { open: false, message: '' },
      departaments: [],
      users: [],
      isLogged: false,
      cont: 0,
      companyName: '',
      companyNameTolltip: '',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.openHistory = this.openHistory.bind(this);
    this.closeHistory = this.closeHistory.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.openAssistent = this.openAssistent.bind(this);
    this.closeAssistent = this.closeAssistent.bind(this);
    this.saveGeneratePass = this.saveGeneratePass.bind(this);

    this.generatePassword = this.generatePassword.bind(this);
    this.companySelected = this.companySelected.bind(this);
    this.changeCompany = this.changeCompany.bind(this);

    this.createNewPass = this.createNewPass.bind(this);
    this.saveNewPass = this.saveNewPass.bind(this);

    this.enterMasterPass = this.enterMasterPass.bind(this);
    this.openMaster = this.openMaster.bind(this);
  }

  componentWillMount() {
    const action = new Actions();

    let role = this.store.getUserLoged();
    
    action
      .execute(
        'get',
        Constants.APIEndpoints.PASSWORD_SAFE + '/departaments',
        'password_safe_master'
      )
      .then((e) => {
        this.setState({ departaments: e.data });
      })
      .catch((error) => {
        console.log(error);
      });

    action
      .execute(
        'get',
        Constants.APIEndpoints.PASSWORD_SAFE + '/master',
        'password_safe_master'
      )
      .then((e) => {
        if (e.data.length > 0) {
          this.setState({ hasMaster: true });
          this.setState({ passMaster: e.data[0].password });
        } else {
          this.setState({ hasMaster: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    action
      .execute('get', Constants.APIEndpoints.PASSWORD_SAFE, 'password_safe')
      .then((e) => {
        this.setState({ allPass: e.data });
      })
      .catch((error) => {
        console.log(error);
      });

    action
      .execute('get', Constants.APIEndpoints.COMPANY, 'companies')
      .then((result) => {
        this.setState({ companies: result.data });
      });

    this.handlePage('list');
  }

  openMaster() {
    this.setState({ modalMaster: true });
  }

  toggleAlert(v, m = false) {
    var alert = this.state.alert;
    alert.open = v;
    if (m) {
      alert.message = m;
    }
    this.setState({ alert });
  }

  onFormSubmit(values) {
    this.state.companySelected.map((company) => {
      values.fk_id_company = company;
      const action = new Actions();
      let method = values.hasOwnProperty('id_password_safe') ? 'put' : 'post';
      let url = values.hasOwnProperty('id_password_safe')
        ? Constants.APIEndpoints.PASSWORD_SAFE + '/' + values.id_password_safe
        : Constants.APIEndpoints.PASSWORD_SAFE;

      action
        .execute(method, url, 'password_safe', values, 'id_password_safe')
        .then((e) => {
          this.setState({ allPass: e.data });
          this.companySelected();
        })
        .catch((error) => {});

      if (values.profile_type == '2') {
        values.departaments_selected.map((dep) => {
          let id = this.state.allPass[this.state.allPass.length - 1]?.id_password_safe + 1;
          let value = {
            fk_id_password: values.hasOwnProperty('id_password_safe')
              ? values.id_password_safe
              : id,
            fk_id_departament: dep,
          };

          action
            .execute(
              method,
              Constants.APIEndpoints.PASSWORD_SAFE + '/departaments',
              'password_safe_departament',
              value,
              'id_password_safe_departament'
            )
            .then((e) => {
              //    this.setState({ allPass: e.data });
              //    this.companySelected();
            })
            .catch((error) => {});
        });
      }
    });

    this.handlePage('list');
  }

  onDelete() {
    let { values } = this.state;
    const action = new Actions();

    action
      .execute(
        'post',
        Constants.APIEndpoints.PASSWORD_SAFE + '/' + 'deletepassword',
        'password_safe',
        values,
        'id_password_safe'
      )
      .then((e) => {
        this.setState({ passwords: e.data });
      });

    this.handlePage('list');
  }

  openHistory(item) {
    const action = new Actions();
    action
      .execute(
        'get',
        Constants.APIEndpoints.PASSWORD_SAFE + '/gethistory',
        'password_safe_logs'
      )
      .then((result) => {
        let historic = result.data.filter(
          (result) => result.description == item.description
        );

        this.setState({ historicPasswords: historic });
        this.setState({ itemPass: item.user_password });
        this.setState({ passToShow: item.user_password });

        this.setState({ openDialog: true });
      });
  }

  showPassword() {
    if (this.state.showPass == false) {
      const crypto = require('crypto');
      let decipher, tRet;
      var aSecret = 'Orch3str0!@forever!';

      let aMsg = this.state.itemPass.replace(/\s/g, '');
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');

      this.setState({ passToShow: tRet });

      this.setState({ showPass: !this.state.showPass });
    } else {
      this.setState({ showPass: !this.state.showPass });
    }
  }

  createNewPass(obj) {
    this.setState({ modalNewPass: true, pass: obj });
  }

  generatePassword() {
    let { hasNumbers, hasLower, hasUpper, hasSymbols, numberChar } = this.state;
    let numbers = '0123456789';
    let symbols = '!@#$%^&*()<>,.?/[]{}-=_+|/';
    let lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    let upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let characters = '';
    if (hasNumbers) {
      characters += numbers;
    }
    if (hasLower) {
      characters += lowerCase;
    }
    if (hasUpper) {
      characters += upperCase;
    }

    if (hasSymbols) {
      characters += symbols;
    }

    let password = '';

    for (let i = 0; i < numberChar; i++) {
      password += characters[this.getRandomInteger(0, characters.length - 1)];
    }
    this.setState({ generatePassword: password });
  }

  getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  saveGeneratePass() {
    let pass = { user_password: this.state.generatePassword };
    let newPass = { new_pass: this.state.generatePassword };
    this.setState({ values: pass });
    this.setState({ passAssistent: false });
    this.setState({ valuesNewPass: newPass });
  }

  saveNewPass(values) {
    let newPass = this.state.pass;

    if (values.hasOwnProperty('new_pass')) {
      newPass.user_password = values.new_pass;
    } else {
      newPass.user_password = this.state.valuesNewPass.new_pass;
    }
    this.onFormSubmit(newPass);
    this.setState({ modalNewPass: false, valuesNewPass: [] });
  }

  closeHistory() {
    this.setState({
      openDialog: false,
      historicPasswords: [],
      showPass: false,
      passToShow: '',
    });
  }

  openAssistent() {
    this.setState({ passAssistent: true });
  }

  closeAssistent() {
    this.setState({ passAssistent: false });
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  saveMasterPass(values) {
    const action = new Actions();

    action
      .execute(
        'post',
        Constants.APIEndpoints.PASSWORD_SAFE + '/master',
        'password_safe_pass_master',
        values,
        'id_password_safe_pass_master'
      )
      .then((e) => {})
      .catch((error) => {});
    Store.toggleAlert(true, Store.language.SUCCESS_PASSWORD, 'success');

    document.location.reload();
  }

  enterMasterPass(values) {
    let decipher, tRet;
    var aSecret = 'Orch3str0!@forever!';
    const crypto = require('crypto');

    let aMsg = this.state.passMaster.replace(/\s/g, '');
    decipher = crypto.Decipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');
    if (values.password == tRet) {
      this.setState({ modalMaster: false, modalCompany: true, isLogged: true });
    } else {
      this.toggleAlert(true, Store.language.INVALID_PASSWORD);
    }
  }
  companySelected() {
    let pass = [];

    let user = this.store.getUserLoged();
    let companyName = this.state.companies.filter(
      (c) => c.id_company == this.state.companySelected[0]
    )[0].name;
    this.setState({ companyName: companyName });

    let companyTooltip = '';

    if (user.role == 'client') {
      this.state.companySelected.map((company) => {
        let passFilter = this.state.allPass.filter(
          (pass) => pass.fk_id_company == company
        );
        let cName = this.state.companies.filter((c) => c.id_company == company);
        if (cName.length > 0) {
          companyTooltip += cName[0].name + '.\n';
        }
        pass.push(...passFilter);
      });
    } else {
      const action = new Actions();

      action
        .execute('get', `${Constants.APIEndpoints.USER}/${user.id}`, 'users')
        .then((result) => {
          console.log(result);
          this.setState({ users: result.data });
          let userId = result.data;

          this.state.companySelected.map((company) => {
            let passFilter = this.state.allPass.filter(
              (pass) => pass.fk_id_company == company && pass.profile_type == 3
            );
            pass.push(...passFilter);
            let userId = result.data;

            console.log('user id after get', userId);

            let fk_id_departament = userId.fk_id_department;
            let dept = this.state.departaments.filter(
              (dpt) => dpt.fk_id_departament == fk_id_departament
            );
            dept.map((dpt) => {
              let passDpt = this.state.allPass.filter(
                (pass) => pass.id_password_safe == dpt.fk_id_password
              );
              pass.push(...passDpt);
            });
          });

        });

      /*this.state.companySelected.map((company) => {
        let passFilter = this.state.allPass.filter(
          (pass) => pass.fk_id_company == company && pass.profile_type == 3
        );
        pass.push(...passFilter);
        let userId = this.state.users.filter((us) => us.id_user == user.id);

        let fk_id_departament = userId.fk_id_department;
        let dept = this.state.departaments.filter(
          (dpt) => dpt.fk_id_departament == fk_id_departament
        );
        dept.map((dpt) => {
          let passDpt = this.state.allPass.filter(
            (pass) => pass.id_password_safe == dpt.fk_id_password
          );
          pass.push(...passDpt);
        });
      });*/
    }

    this.setState({
      passwords: pass,
      modalCompany: false,
      modalMaster: false,
      companyNameTooltip: companyTooltip,
    });
  }

  changeCompany() {
    this.setState({ modalCompany: true, modalMaster: false });
  }

  render() {
    let { page, values, modalMaster } = this.state;

    let { language, mails, companies, groups, contracts, mailContract } =
      this.store;
    let { configurationProps = {} } = this.props;

    let fields = [
      {
        col: 2,
        name: 'numberChar',
        label: language.INCLUDE_QTD_CHARACTERS,
        type: 'text',
      },
      {
        col: 2.5,
        name: 'hasLower',
        label: language.INCLUDE_LOWERCASE,
        type: 'check',
      },

      {
        col: 2.5,
        name: 'hasUpper',
        label: language.INCLUDE_UPPERCASE,
        type: 'check',
      },
      {
        col: 2.5,
        name: 'hasSymbols',
        label: language.INCLUDE_SYMBOLS,
        type: 'check',
      },
      {
        col: 2.5,
        name: 'hasNumbers',
        label: language.INCLUDE_NUMBERS,
        type: 'check',
      },
    ];

    let companyOptions = this.state.companies.map((c) => ({
      value: c.id_company,
      label: c.name,
    }));
    companyOptions.push({
      value: 0,
      label: language.ALL,
    });

    let modalMasterDialog = [
      {
        col: 11,
        name: 'password',
        label: this.state.hasMaster
          ? language.TYPE_PASSWORD_ENTER
          : language.CREATE_YOUR_OWN_MASTER_PASSWORD,
        type: this.state.showMaster ? 'text' : 'password',
      },
      {
        col: 1,
        type: 'element',
        content: (v, h, submit) => (
          <Button
            icon="fas fa-eye"
            variant="circle"
            color="success"
            onClick={() =>
              this.setState({ showMaster: !this.state.showMaster })
            }
            style={{ marginTop: 20, marginLeft: -10 }}
          />
        ),
      },
    ];

    let modalCompany = [
      {
        col: 12,
        name: 'company_selected',
        label: language.COMPANIES,
        type: 'select',
        required: false,
        options: companyOptions,
        isMulti: true,
      },
    ];
    let passOptions = [
      { value: 1, label: language.PASSWORD_SIMPLE},
      { value: 2, label: language.PASSWORD_ASSISTANT_NEW },
    ];
    let newPass = [
      {
        col: 4,
        name: 'type_pass',
        type: 'select',
        label: language.PASSWORD_TYPE,
        options: passOptions,
      },
      {
        col: 8,
        name: 'new_pass',
        type: 'password',
        label: language.NEW_PASSWORD,
      },
    ];

    let button = { label: language.PASSWORD_GENERATE, icon: 'fas fa-check' };
    let buttonCompany = { label: language.SELECT_COMPANY };
    let buttonNewPass = { label: language.PASSWORD_SAVE };
    let buttonMaster = { label: this.state.hasMaster ? language.ENTER : language.SAVE };

    return (
      <div>
        <Fragment>
          <PasswordList
            redirect={this.redirect2}
            language={language}
            handlePage={this.handlePage}
            store={this.state.passwords}
            configurationProps={configurationProps}
            openHistory={this.openHistory}
            createNewPass={this.createNewPass}
            changeCompany={this.changeCompany}
            isLogged={this.state.isLogged}
            companyName={this.state.companyName}
            companyTooltip={this.state.companyNameTooltip}
            companies = {this.state.companies}
          />

          {page === 'form' ? (
            <PasswordForm
            company = {this.state.companySelected}
              {...this.state}
              language={language}
              handlePage={this.handlePage}
              onFormSubmit={this.onFormSubmit}
              passAssistent={this.openAssistent}
            />
          ) : null}
          {page === 'remove' ? (
            <CommonDelete
              language={language}
              closeModal={() => this.handlePage('list')}
              onDelete={this.onDelete}
              message={values.user_email}
            />
          ) : null}

          <Dialog
            open={this.state.openDialog}
            contentStyle={{ height: '80%', maxWidth: 'none' }}
            fullWidth={true}
            maxWidth="lg"
            title={language.HISTORIC_CHANGES}
            autoScrollBodyContent={true}
            onClose={this.closeHistory}
          >
            <div className="password">
              <h4>{language.PASSWORD_CURRENT}: </h4>
              <input
                type={this.state.showPass ? 'text' : 'password'}
                value={this.state.passToShow}
              />
              <RaisedButton
                circleButton
                icon="fas fa-eye"
                label={language.SEE}
                color="success"
                style={{ fontSize: 12, width: 21, height: 21, marginTop: 20 }}
                className="mr-2"
                onClick={this.showPassword}
              />
            </div>
            <PasswordList
              redirect={this.redirect2}
              language={language}
              handlePage={this.handlePage}
              store={this.state.historicPasswords}
              configurationProps={configurationProps}
              createNewPass={this.createNewPass}
              changeCompany={this.changeCompany}
              isLogged={this.state.isLogged}
              companies = {this.state.companySelected}
/>
          </Dialog>

          <Dialog
            open={this.state.passAssistent}
            onClose={this.closeAssistent}
            fullWidth={true}
            maxWidth="lg"
            title={language.PASSWORD_ASSISTANT}
          >
            <CommonForm
              fields={fields}
              button={button}
              onSubmit={() => this.generatePassword()}
              onChangeField={(field, value) => {
                if (field.name == 'numberChar') {
                  this.setState({ numberChar: value });
                }

                if (field.name == 'hasLower') {
                  this.setState({ hasLower: value });
                }
                if (field.name == 'hasUpper') {
                  this.setState({ hasUpper: value });
                }

                if (field.name == 'hasSymbols') {
                  this.setState({ hasSymbols: value });
                }
                if (field.name == 'hasNumbers') {
                  this.setState({ hasNumbers: value });
                }
              }}
            />
            <div className="password">
              <input value={this.state.generatePassword} />
            </div>
            {this.state.generatePassword.length > 0 && (
              <PasswordStrengthBar password={this.state.generatePassword} />
            )}
            <RaisedButton
              circleButton
              icon="fas fa-save"
              label={language.SAVE}
              color="success"
              style={{ fontSize: 14, width: 31, height: 31, marginTop: 20 }}
              className="mr-2"
              onClick={this.saveGeneratePass}
            />
          </Dialog>

          <Dialog
            open={this.state.modalCompany}
            onClose={() => this.setState({ modalCompany: false })}
            maxWidth="xs"
            title={language.SELECT_COMPANY}
          >
            <div style={{ height: 300 }}>
              <CommonForm
                fields={modalCompany}
                button={buttonCompany}
                onSubmit={this.companySelected}
                onChangeField={(f, v) => {
                  this.setState({ companySelected: v });
                }}
              />
            </div>
          </Dialog>

          <Dialog
            open={this.state.modalMaster}
            onClose={() => this.setState({ modalMaster: false })}
            maxWidth="xs"
            title={
              this.state.hasMaster
                ? language.TYPE_MASTER_PASSWORD
                : language.NEW_MASTER_PASSWORD
            }
          >
            <CommonForm
              fields={modalMasterDialog}
              button={buttonMaster}
              onSubmit={
                this.state.hasMaster
                  ? this.enterMasterPass
                  : this.saveMasterPass
              }
              onChangeField={(f, v) => {
                this.setState({ companySelected: v });
              }}
            />
          </Dialog>

          <SimplesAlert
            message={this.state.alert.message}
            variant="error"
            float
            open={this.state.alert.open}
            onClose={() => this.toggleAlert(false)}
          />

          <Dialog
            open={this.state.modalNewPass}
            onClose={() => this.setState({ modalNewPass: false })}
            maxWidth="lg"
            title={language.ADD_NEW_PASSWORD}
          >
            <CommonForm
              fields={newPass}
              values={this.state.valuesNewPass}
              button={buttonNewPass}
              onSubmit={this.saveNewPass}
              onChangeField={(f, v) => {
                if (v == 2) {
                  this.setState({ passAssistent: true });
                } else {
                  this.setState({ passAssistent: false });
                }
              }}
            />
          </Dialog>
        </Fragment>
      </div>
    );
  }
}
