import moment from 'moment';
import decrypt from './decrypt'
import Store from '@data/Store';
function calculateTimeIn(cur) {
    let days, hours, minutes, seconds = 0;
    days = Math.floor(cur / 86400);
    cur -= days * 86400;
    hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    seconds = Math.floor(cur) % 60;
    hours = (days * 24) + hours;
    if (hours < 0) hours = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;
    return hours + ':' + minutes + ':' + seconds;
}

function calculateTimeInSeconds(totalSeconds) {
    let days, hours, minutes, seconds = 0;
    hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    minutes = Math.floor(totalSeconds / 60);
    seconds = totalSeconds % 60;
    return hours + ':' + minutes + ':' + Math.round(seconds).toString().padStart(2, 0);
}

function calculateTimeInMinute(cur) {
    let days, hours, minutes, seconds = 0;
    days = Math.floor(cur / 86400);
    cur -= days * 86400;
    hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    seconds = Math.floor(cur) % 60;
    hours = (days * 24) + hours;
    if (hours < 0) hours = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;
    return (hours * 60) + minutes;
}
const toSecond = (hms) => {
    try {
        var a = hms.split(':');
        var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        return seconds;
    } catch (r) {
        return 0;
    }
}

const user = Store.getUserLoged();

export default {
    compare(dateTimeA, dateTimeB) {
        if (dateTimeA == null) {
            return false;
        }
        var currentTime = moment();
        var d = new Date();
        var n = d.getHours().toString() + d.getMinutes().toString();
        if (n > dateTimeA && n < dateTimeB) {
            return true;
        } else {
            return false;
        }
    },

    calcSla(obj, type, language, req, slatimeCatalog, open, g, delaystop, typesla) {

        let horas = undefined;
        try {
            if (type != 'request') {
                let itens = JSON.parse(decrypt(sessionStorage.getItem('times'))).filter(g => g.fk_id_company == obj.fk_id_company
                     //&&g.contract == obj.fk_id_contract
                )[0].v;

              

                horas = {
                    1: itens[0],
                    2: itens[1],
                    3: itens[2],
                    4: itens[3],
                    5: itens[4],
                    6: itens[5],
                    7: itens[6]
                }

                  if(itens.holiday == true){
                    horas[moment().isoWeekday()]={};
                   }
 
              
            } else {
                let itens = JSON.parse(decrypt(sessionStorage.getItem('times'))).filter(g => (g.fk_id_company == obj.fk_id_company ||
                      g.fk_id_company == obj.fk_id_company_fk
                      ) &&  
                    g.contract == 0 
                )[0].v;
                horas = {
                    1: itens[0],
                    2: itens[1],
                    3: itens[2],
                    4: itens[3],
                    5: itens[4],
                    6: itens[5],
                    7: itens[6]
                }
                
            }
        } catch (e) {
            horas = {
                1: { start: '00:00', end: '23:59' },
                2: { start: '00:00', end: '23:59' },
                3: { start: '00:00', end: '23:59' },
                4: { start: '00:00', end: '23:59' },
                5: { start: '00:00', end: '23:59' },
                6: { start: '00:00', end: '23:59' },
                7: { start: '00:00', end: '23:59' }
            }
        }
        let returnSlaDelayed = false;
        let delayed = false;
        let cur = 0;
        let color = 'var(--green)';
        let dt = 0;
        let ini = 0;
        let limitDate = 0;
        let end = 0;
        let time = "";
        let horasmin = 0;
        if (horas) {
            horasmin = this.minutesWorked(moment(obj.dt_cad), g > 0 ? obj.dt_up : moment().utc().tz(user.timezone.value), horas)
            if (type === 'request') {
                limitDate = obj.sla || obj.time_sla;
                let hora = typesla == 'M' ? parseInt(horasmin.totalMin) : parseInt(horasmin.totalMin / 60)
                if (hora < limitDate) {
                    time = horasmin != 0 ? calculateTimeInSeconds(horasmin.totalMin * 60) : 0
                }
                else {
                    color = 'var(--red)';
                    time = horasmin != 0 ? calculateTimeInSeconds(horasmin.totalMin * 60) : 0
                    delayed = true;
                }
            } else {
                if (obj.status === 'Open' || obj.status === 'In Progress' || obj.status === 'Reopened') {
                    color = 'var(--green)';
                    let minutes = horasmin.totalMin
                    minutes = minutes
                    if (obj.time_total_request) {
                        try {
                            minutes = minutes + parseInt(toSecond(obj.time_total_request) / 60)
                            horasmin.totalMin = horasmin.totalMin + parseInt(toSecond(obj.time_total_request) / 60)
                        } catch (e) { }
                    }
                    cur = horasmin.unix;
                    let limit = slatimeCatalog * 60;
                    let calculo = parseInt((minutes / limit) * 100)
                    if (calculo > 0 && calculo < 70) {
                        color = 'var(--green)';
                    }
                    if (calculo > 71 && calculo < 100) {
                        color = 'var(--secundary)';
                    }
                    if (calculo > 100) {
                        color = 'var(--red)';
                    }
                }
                let sec = horasmin.totalMin * 60;
                if (delaystop > 0) {
                    sec = (sec - delaystop)
                }
                time = horasmin != 0 ? calculateTimeInSeconds(sec) : 0
            }
        } else {
            if (req != undefined && req.length == 1) {
                dt = moment(moment(req[0].dt_up).diff(moment(req[0].dt_cad))).unix();
            }
            if (type === 'request') {
                ini = moment(obj.dt_cad).unix()
                limitDate = moment(obj.dt_cad).add(obj.sla || obj.time_sla, 'h')
                end = open && (obj.status != "Open" && obj.status != "Aproved") ? moment(open) : moment().utc().tz(user.timezone.value)
                if (obj.id_request === 27099) {
                }
                if (end < limitDate) {
                    cur = (open && (obj.status != "Open" && obj.status != "Aproved") ? moment(open).unix() : moment().utc().tz(user.timezone.value).unix()) - ini;
                }
                else {
                    color = 'var(--red)';
                    cur = (open && (obj.status != "Open" && obj.status != "Aproved") ? moment(open).unix() : moment().utc().tz(user.timezone.value).unix()) - ini;
                    delayed = true;
                }
            }
            else {
                if (obj.status === 'Open' || obj.status === 'In Progress' || obj.status === 'Reopened') {
                    ini = moment(obj.dt_cad).unix();
                    color = 'var(--green)';
                    let minutes = moment().utc().tz(user.timezone.value).diff(moment(obj.dt_cad), 'minutes');
                    cur = moment().utc().tz(user.timezone.value).unix() - ini;
                    let limit = slatimeCatalog * 60;
                    let calculo = parseInt((minutes / limit) * 100)
                    if (calculo > 0 && calculo < 70) {
                        color = 'var(--green)';
                    }
                    if (calculo > 71 && calculo < 100) {
                        color = 'var(--secundary)';
                    }
                    if (calculo > 100) {
                        color = 'var(--red)';
                    }
                }
            }
            time = calculateTimeIn(cur);
        }
        return {
            color,
            time: !returnSlaDelayed
                ? time
                : delayed
                    ? language.SLA_STATUS.DELAYED : language.SLA_STATUS.IN_TIME,
            delayed
        };
    },

    calcSla_(obj, type, language, req, slatimeCatalog, open, g, delaystop) {
        let horas = undefined;
        try {
            if (type != 'request') {
                let itens = JSON.parse(decrypt(sessionStorage.getItem('times'))).filter(g => g.fk_id_company == obj.fk_id_company 
                    //&&g.contract == obj.fk_id_contract
                )[0].v;
                horas = {
                    1: itens[0],
                    2: itens[1],
                    3: itens[2],
                    4: itens[3],
                    5: itens[4],
                    6: itens[5],
                    7: itens[6]
                }
            } else {
                let itens = JSON.parse(decrypt(sessionStorage.getItem('times'))).filter(g => g.fk_id_company == obj.fk_id_company
                     //&&g.contract == 0
                )[0].v;
                horas = {
                    1: itens[0],
                    2: itens[1],
                    3: itens[2],
                    4: itens[3],
                    5: itens[4],
                    6: itens[5],
                    7: itens[6]
                }
            }
        } catch (e) {
            horas = {
                1: { start: '00:00', end: '23:59' },
                2: { start: '00:00', end: '23:59' },
                3: { start: '00:00', end: '23:59' },
                4: { start: '00:00', end: '23:59' },
                5: { start: '00:00', end: '23:59' },
                6: { start: '00:00', end: '23:59' },
                7: { start: '00:00', end: '23:59' }
            }
        }
        let returnSlaDelayed = false;
        let delayed = false;
        let cur = 0;
        let color = 'var(--green)';
        let dt = 0;
        let ini = 0;
        let limitDate = 0;
        let end = 0;
        let time = "";
        let horasmin = 0;
        if (horas) {
            horasmin = this.minutesWorked(moment(obj.dt_cad),  obj.dt_up ? moment(obj.dt_up) : moment().utc().tz(user.timezone.value), horas)
            if (type === 'request') {
                limitDate = obj.sla || obj.time_sla;
                let hora = parseIn(horasmin.totalMin / 60)
                if (hora < limitDate) {
                    time = horasmin != 0 ? calculateTimeInSeconds(horasmin.totalMin * 60) : 0
                }
                else {
                    color = 'var(--red)';
                    time = horasmin != 0 ? calculateTimeInSeconds(horasmin.totalMin * 60) : 0
                    delayed = true;
                }
            } else {
          
                if (obj.status === 'Open' || obj.status === 'In Progress' || obj.status === 'Reopened') {
                    color = 'var(--green)';
                    let minutes = horasmin.totalMin
                    minutes = minutes
                    if (obj.time_total_request) {
                                       try {
                            minutes = minutes + parseInt(toSecond(obj.time_total_request) / 60)
                            horasmin.totalMin = horasmin.totalMin + parseInt(toSecond(obj.time_total_request) / 60)
                        } catch (e) { }
                    }
                    if (delaystop > 0) {
                        minutes = (minutes - parseInt(delaystop/60))
                    }
                    cur = horasmin.unix;
                    let limit = slatimeCatalog * 60;
                    let calculo = parseInt((minutes / limit) * 100)
                    if (calculo > 0 && calculo < 70) {
                        color = 'var(--green)';
                    }
                    if (calculo > 71 && calculo < 100) {
                        color = 'var(--secundary)';
                    }
                    if (calculo > 100) {
                        color = 'var(--red)';
                    }
        
              
                }
                let sec = horasmin.totalMin * 60;
                if (delaystop > 0) {
                    sec = (sec - delaystop)
                }
                time = horasmin != 0 ? calculateTimeInSeconds(sec) : 0
            }
        } else {
            if (req != undefined && req.length == 1) {
                dt = moment(moment(req[0].dt_up).diff(moment(req[0].dt_cad))).unix();
            }
            if (type === 'request') {
                ini = moment(obj.dt_cad).unix()
                limitDate = moment(obj.dt_cad).add(obj.sla || obj.time_sla, 'h')
                end = open && (obj.status != "Open" && obj.status != "Aproved") ? moment(open) : moment().utc().tz(user.timezone.value)
                if (obj.id_request === 27099) {
                }
                if (end < limitDate) {
                    cur = (open && (obj.status != "Open" && obj.status != "Aproved") ? moment(open).unix() : moment().utc().tz(user.timezone.value).unix()) - ini;
                }
                else {
                    color = 'var(--red)';
                    cur = (open && (obj.status != "Open" && obj.status != "Aproved") ? moment(open).unix() : moment().utc().tz(user.timezone.value).unix()) - ini;
                    delayed = true;
                }
            }
            else {
                if (obj.status === 'Open' || obj.status === 'In Progress' || obj.status == 'Reopened') {
                    ini = moment(obj.dt_cad).unix();
                    color = 'var(--green)';
                    let minutes = moment().utc().tz(user.timezone.value).diff(moment(obj.dt_cad), 'minutes');
                    cur = moment().utc().tz(user.timezone.value).unix() - ini;
                    let limit = slatimeCatalog * 60;
                    let calculo = parseInt((minutes / limit) * 100)
                    if (calculo > 0 && calculo < 70) {
                        color = 'var(--green)';
                    }
                    if (calculo > 71 && calculo < 100) {
                        color = 'var(--secundary)';
                    }
                    if (calculo > 100) {
                        color = 'var(--red)';
                    }
                }
            }
            time = calculateTimeIn(cur);
        }
        return {
            color,
            time: !returnSlaDelayed
                ? time
                : delayed
                    ? language.SLA_STATUS.DELAYED : language.SLA_STATUS.IN_TIME,
            delayed
        };
    },

    stopSla(obj, ticket_sla, language, req, slatimeCatalog) {
        let horas = undefined;
        try {
            let itens = JSON.parse(decrypt(sessionStorage.getItem('times'))).filter(g => g.fk_id_company == obj.fk_id_company
                 //&&g.contract == obj.fk_id_contract
            )[0].v;
            horas = {
                1: itens[0],
                2: itens[1],
                3: itens[2],
                4: itens[3],
                5: itens[4],
                6: itens[5],
                7: itens[6]
            }
        } catch (e) {
            horas = {
                1: { start: '00:00', end: '23:59' },
                2: { start: '00:00', end: '23:59' },
                3: { start: '00:00', end: '23:59' },
                4: { start: '00:00', end: '23:59' },
                5: { start: '00:00', end: '23:59' },
                6: { start: '00:00', end: '23:59' },
                7: { start: '00:00', end: '23:59' }
            }
        }

        let returnSlaDelayed = false;
        let delayed = false;
        let cur = 0;
        let color = 'var(--green)';
        let dt = 0;
        let ini = 0;
        let time;
        let limitDate = 0;
        let end = 0;
        let horasmin = 0;
        if (horas) {
            horasmin = this.minutesWorked(moment(obj.dt_cad),obj.dt_up ? moment(obj.dt_up) : moment(), horas)
            let minutes = horasmin.totalMin;
            let limit = slatimeCatalog * 60;
            let calculo = parseInt((minutes / limit) * 100)
            if (calculo > 0 && calculo < 70) {
                color = 'var(--green)';
            }
            if (calculo > 71 && calculo < 100) {
                color = 'var(--secundary)';
            }
            if (calculo > 100) {
                color = 'var(--red)';
            }
            time = horasmin != 0 ? calculateTimeInSeconds(horasmin.totalMin * 60) : 0
        } else {
            if (req != undefined && req.length == 1) {
                dt = moment(moment(req[0].dt_up).diff(moment(req[0].dt_cad))).unix();
            }
            ini = moment(obj.dt_cad).unix();
            end = moment(obj.dt_up).unix();
            color = 'var(--green)';
            let minutes = moment(obj.dt_up).diff(moment(obj.dt_cad), 'minutes');
            cur = end - ini;
            let limit = slatimeCatalog * 60;
            let calculo = parseInt((minutes / limit) * 100)
            if (calculo > 0 && calculo < 70) {
                color = 'var(--green)';
            }
            if (calculo > 71 && calculo < 100) {
                color = 'var(--secundary)';
            }
            if (calculo > 100) {
                color = 'var(--red)';
            }
            time = calculateTimeIn(cur);
        }
        return {
            time: time,
            color: color
        }
    },

    stopSlaTime(t, slatimeCatalog) {
        try {
            let color = 'var(--green)';
            let horasmin = 0;
            let time = '';
            if (t) {
                horasmin = t.split(':')
                let bbbb = parseInt(horasmin[0]) * 60 + parseInt(horasmin[1])
                let minutes = bbbb
                let limit = slatimeCatalog * 60;
                let calculo = parseInt((minutes / limit) * 100)
                if (calculo > 0 && calculo < 70) {
                    color = 'var(--green)';
                }
                if (calculo > 71 && calculo < 100) {
                    color = 'var(--secundary)';
                }
                if (calculo > 100) {
                    color = 'var(--red)';
                }
                time = horasmin != 0 ? calculateTimeInSeconds(minutes * 60) : 0
                return {
                    time: time,
                    color: color
                }
            } else {
                return {
                    time: "",
                    color: ""
                }
            }
        } catch (e) {
            return {
                time: "",
                color: ""
            }
        }
    },

    minutesWorked(start, end, bizHrs) {
        try {
            if (end.isBefore(start, 'second')) {
                return 0;
            }
            var timeDiff = moment.duration(end.diff(start));
            var startDay = start.format('YYYY-MM-DD');
            var endDay = end.format('YYYY-MM-DD');
            var current = start;
            var currentDay = current.format('YYYY-MM-DD');
            var totalMin = 0;
            var totalUnix = 0;
            var endTime, startTime;
            var weekday, bizStartTime, bizEndTime, duration, unix;
            do {
                weekday = current.format('E');
                bizStartTime = bizHrs[weekday].start;
                bizEndTime = bizHrs[weekday].end;
                if (bizStartTime && bizStartTime) {
                    if (currentDay == startDay) {
                        startTime = start.format("HH:mm:ss");
                        startTime = startTime > bizStartTime ? startTime : bizStartTime;
                        startTime = startTime < bizEndTime ? startTime : bizEndTime;
                    } else {
                        startTime = bizStartTime;
                    }
                    if (currentDay == endDay) {
                        endTime = end.format("HH:mm:ss");
                        endTime = endTime < bizEndTime ? endTime : bizEndTime;
                        endTime = endTime > bizStartTime ? endTime : bizStartTime;
                    } else {
                        endTime = bizEndTime;
                    }
                    startTime = moment(currentDay + ' ' + startTime);
                    endTime = moment(currentDay + ' ' + endTime);
                    duration = moment.duration(endTime.diff(startTime)).as('minutes');
                    unix = moment.duration(endTime.diff(startTime)).as('seconds');
                    totalMin += duration;
                    totalUnix += unix;
                }
                current.add(1, "days");
                currentDay = current.format('YYYY-MM-DD');
            }
            while (currentDay <= endDay);
            return { totalMin, unix }
        } catch (r) {
            var totalMin = 0;
            var unix = 0;
            return { totalMin, unix }
        }
    },

    reopedSla(obj, ticket_sla, language, req) {
        let time = parseInt(ticket_sla.filter(tks => tks.id_tickets == obj.id_tickets).map(c => c.time_resolution_sla))
        let ini = moment().unix();
        let end = moment().unix();
        let delayed = false;
        let dt = 0;
        if (req != undefined && req.length == 1) {
            dt = moment(moment(req[0].dt_up).diff(moment(req[0].dt_cad))).unix();

        }
        ini = moment(obj.dt_up).add(obj.time_solution, 'h').subtract(time, 'minutes').unix();
        ini = ini + dt;
        let cur = 0;
        let color = 'var(--green)';
        if (ini > end) {
            cur = ini - end;
        }
        else {
            color = 'var(--red)';
            cur = end - ini;
            delayed = true;
        }
        let days = Math.floor(cur / 86400);
        cur -= days * 86400;
        let hours = Math.floor(cur / 3600) % 24;
        cur -= hours * 3600;
        let minutes = Math.floor(cur / 60) % 60;
        cur -= minutes * 60;
        let seconds = Math.floor(cur) % 60;
        hours = (days * 24) + hours;
        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;
        return {
            color: color,
            time: hours + ':' + minutes + ':' + seconds,
            delayed: delayed
        };
    }
};
