import React, { useState } from 'react';
import { Card } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
import Store from '@data/Store';

const { language } = Store;

let statusIcon = {
  true: 'fas fa-chevron-down',
  false: 'fas fa-chevron-up',
  null: 'fas fa-sort',
};
export default function (props) {
  let {
    children,
    title,
    tickets,
    DASHBOARD_THEME,
    orderIndex,
    setOrderIndex,
    setOrderPosition,
    orderPosition,
    totalTickets = 0,
  } = props;

  return (
    <Card
      className="border-radius-10 GSDCardColumn"
      style={{ '--color': DASHBOARD_THEME }}
    >
      <AppBar
        title={title}
        titleProps={{ style: { color: 'white' } }}
        childrenContentProps={{ style: { height: 0 } }}
      >
        <div className="qtd-number -mt-3">
          {totalTickets > 0 ? totalTickets : tickets && tickets.length}
        </div>
      </AppBar>
      <div className="d-flex align-items-center btn-group -mt-3 ml-3 mb-2">
        {[
          { label: language.BY_ID, color: 'light' },
          { label: language.BY_DATE, color: 'light' },
        ].map((e, i) => (
          <Button
            icon={orderIndex === i ? statusIcon[orderPosition] : 'fas fa-sort'}
            label={e.label}
            className="btn-item"
            outlined={orderIndex !== i}
            onClick={() => {
              setOrderIndex(i);
              setOrderPosition((p) => !p);
            }}
            color={orderIndex === i ? e.color : 'light'}
            variant="normal"
            size={0.6}
          />
        ))}
      </div>
      <hr
        className="my-0 py-0 mt-1"
        style={{ borderColor: 'white', opacity: 0.4 }}
      />
      <div style={{ height: '66vh' }}>{children}</div>
    </Card>
  );
}
