import React, {memo, Fragment, useState} from 'react';
import {Tooltip} from 'react-tippy';
import Store from '@data/Store';
import Constants from '@data/Constants';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import List from '@common/List';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
// ------------------------material-ui------------------------------------
import {Card, Grid} from '@material-ui/core';

import RiskView from '../../../../riskmanagement/RiskView';
/* ---------------format-----------------------

let data = [
      {
         id:241,
         name:'Nome do item 2',
         date:'12/05/2020 as 18:20',
         description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod'
      }
   ]
   
*/

export default memo(({page, data=[], redirect, users, action})=>{
   let { language } = Store;
   let [actionRisk, setActionRisk] = useState({}); //id_section
  
   return(
      <Fragment>
         <Dialog
            maxWidth="xl"
            title={language.STANDARD_DETAIL}
            modal={false}
            open={!!Object.keys(actionRisk).length}
            onClose={() => setActionRisk({})}
         >
            <RiskView
               toggleAlert={Store.toggleAlert}
               UserLoged={Store.getUserLoged()}
               riskType={actionRisk.id_standard}
               idcompany={actionRisk.fk_id_company}
               secItem={actionRisk.id} 
               form={actionRisk} 
               ac={action}
               APIEndpoints={Constants.APIEndpoints} 
               users={users}
            />
         </Dialog>
         <Card id='AprovationItem'>
            <AppBar title={language.RAPID_ITEM_APPROVAL_AND_OR_EVALUATION} />
            <List
               mh={210}
               items={data.map(e=>({
                  content:(
                     <div className='wrap w-100 d-flex align-items-center justify-content-between content-medium'>
                        <div className='pr-4'>
                           <header className='d-flex align-items-center justify-content-between'>
                              <span>#{e.id} - {e.name}</span>
                              <span>{e.date}</span>
                           </header> 
                           <Tooltip
                              className='ellipsis-line camp-2 description py-2 d-block'
                              title={e.description}
                              arrow={true}
                              position='right'
                              theme='dark'
                              >
                              <p 
                                 className='ellipsis-line camp-2' 
                                 dangerouslySetInnerHTML={{ __html: e.description }}
                                 />
                           </Tooltip>
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                           <Button
                              icon='fas fa-user-check'
                              label={language.APPROVE}
                              color='success'
                              variant='normal'
                              className='mr-2'
                              onClick={()=>{
                                 if(e.type==1){
                                    redirect('/dash/request', {...e.request, fromDash:true, fromDashApprove:true})
                                 }else if(e.type==2){
                                    setActionRisk(e.risk)
                                 }else{
                                    setActionRisk(e.risk)
                                 }
                              }}
                              />
                           <Button
                              icon='fas fa-user-times'
                              label={language.FAIL}
                              color='danger'
                              variant='normal'
                              onClick={()=>{
                                 if(e.type==1){
                                    redirect('/dash/request', {...e.request, fromDash:true, fromDashApprove:false})
                                 }else if(e.type==2){
                                    setActionRisk(e.risk)
                                 }else{
                                    setActionRisk(e.risk)
                                 }
                              }}
                              />
                        </div>
                     </div>
                  )
               }))}
            />
         </Card>
      </Fragment>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
})