import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';
import Button from '../../common/Button';
import CommonDelete from '../../common/CommonDelete';
import { Chip } from '@material-ui/core';

export default function ({
  action,
  APIEndpoints,
  language,
  ticket,
  hasTasksPending,
}) {
  const [connects, setConnects] = useState([]);
  const [page, setPage] = useState('list');
  const [typeRelationship, setTypeRelationship] = useState(0);
  const [ticketsConnect, setTicketsConnect] = useState([]);
  const [gmudConnect, setGmudConnect] = useState([]);
  const [relationSelected, setRelationSelected] = useState([]);

  useEffect(() => {
    //getAssociateTickets();
    //getAssociateGMUD();
    getListConnect();
  }, [page]);

  useEffect(() => {
    if ([0, 2].includes(typeRelationship))
      getAssociateTickets();
    else if (typeRelationship == 1)
      getAssociateGMUD();

  }, [typeRelationship])


  function handlePage(page, obj) {
    setPage(page);
    if (page === 'remove') {
      setRelationSelected(obj)
    }
  }

  const getListConnect = async (filter = '') => {
    action
      .execute('get', `${APIEndpoints.TICKET}/connect/list/${ticket.id_tickets}`, '')
      .then((res) => {
        setConnects(res.data || []);
      })
      .catch((error) => {
        console.error({ error });
        setConnects([]);
      });
  };

  const getAssociateTickets = async (filter = '') => {
    const query = typeRelationship == 2 ? '?include=task' : '';
    action
      .execute('get', `${APIEndpoints.TICKET}/contract/${ticket.id_tickets}/${filter}${query}`, '')
      .then((res) => {
        const tickets = (res.data || []).map((e) => ({
          value: typeRelationship == 2 ? e.taskid : e.id_tickets,
          label:
            '#' + e.realid + '  ' + language.SOLICITANT +
            ': ' + e.namesol + ' / ' + language.SUBJECT + ': ' + e.subject +
            `${typeRelationship == 2 ? ` / ${language.TASK2}: ${e.taskname}` : ''}`
        }));

        setTicketsConnect(tickets);
      })
      .catch((error) => {
        console.error({ error });
        setTicketsConnect([]);
      });
  };

  const getAssociateGMUD = (filter = '') => {
    let values = { id_ticket: ticket.id_tickets, filter: filter };
    action
      .execute('post', `${APIEndpoints.TICKET}/connectGMUD/list`, '', values)
      .then((res) => {
        const gmuds = (res.data || []).map((e) => ({
          value: e.id_change,
          label: `${e.id_change} - ${e.reason}`,
        }));
        setGmudConnect(gmuds);
      })
      .catch((error) => {
        console.error({ error });
        setGmudConnect([]);
      });
  };

  let formAssociateTicket = [
    {
      span: 12,
      name: 'association',
      title: language.ASSOCIATING_TICKET_CLOSE,
      type: 'listCheckbox',
      handleFilter: getAssociateTickets,
      options: [0, 2].includes(typeRelationship) ? ticketsConnect : gmudConnect,
      selectAll: false,
    },
  ];

  const sendConnectTicketsNew = (values, id_ticket) => {
    action
      .execute(
        'post',
        `${APIEndpoints.TICKET}/connect/${id_ticket}`,
        'ticket',
        values
      )
      .then(() => handlePage('list'))
      .catch((error) => {
        console.error({ error });
      });
  };

  const sendConnectTickets = (values) => {
    let tempTickets = { id_tickets_con: values.association };

    if (values.association.length != 0) {
      sendConnectTicketsNew(tempTickets, ticket.id_tickets);
    }
  };

  const sendConnectionTasks = (values) => {
    const tasksId = { tasks_id: values.association };

    action
      .execute(
        'post',
        `${APIEndpoints.TICKET}/connect/${ticket.id_tickets}/task`,
        null,
        tasksId
      )
      .then(() => handlePage('list'))
      .catch((error) => {
        console.error({ error });
      });
  }

  const sendConnectGmudsNew = (values, id_ticket) => {
    action
      .execute(
        'post',
        `${APIEndpoints.TICKET}/connectGMUD/${id_ticket}`,
        'ticket',
        values
      )
      .then(() => handlePage('list'))
      .catch((error) => {
        console.error({ error });
      });
  };

  const sendConnectGmuds = (values) => {
    let tempGmuds = { id_gmuds_con: values.association };

    if (values.association.length != 0) {
      sendConnectGmudsNew(tempGmuds, ticket.id_tickets);
    }
  };

  const deleteRelation = (values) => {
    values.id_ticket_origin = ticket.id_tickets;
    action
      .execute('put', `${APIEndpoints.TICKET}/connect/remove/${values.id_table}`, '', values)
      .then((res) => {
        handlePage('list');
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  return (
    <div>
      <br />
      <h3 className="title-aba mb-4">{language.RELATIONS}</h3>
      <CommonTable
        style={{ zIndex: 0 }}
        title={''}
        language={language}
        index="DeviceICList"
        searchColumn
        orderColumn
        paginationTop={false}
        beforeBar={
          !hasTasksPending && (
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          )
        }
        columns={[
          {
            key: 'type',
            label: language.TYPE,
          },
          {
            key: 'relationship_type',
            label: language.PROCESS,
          },
          {
            key: 'id',
            label: language.ID,
          },
          {
            key: 'name',
            label: language.TICKET_SUBJECT,
          },
          {
            key: 'dt_cad',
            label: language.DATE_CAD,
            description: language.DATE_START_RELATED
          },
          {
            key: 'dt_closed',
            label: language.COMPLETION_DATE,
            description: language.DATE_CLOSED_RELATED
          },
          {
            key: 'start_date',
            label: language.INITIAL_DATE,
            description: language.DATE_START_RELATED_GMUD
          },
          {
            key: 'end_date',
            label: language.FINAL_DATE,
            description: language.DATE_END_RELATED_GMUD
          },
          {
            key: 'actions',
            label: language.ACTIONS,
            style: { textAlign: 'left' },
            thConfig: false,
          }
        ]}
        data={connects.map((ticket) => ({
          type:
            ticket.type === "Ticket" ?
              language.TICKET :
            ticket.type === "GMUD" ?
              language.GMUD :
            ticket.type === "TASK" ?
              language.SERVICE_PORTAL.TASK :
              ticket.type
          ,
          relationship_type:
            ticket.type === "TASK" ?
              '-' :
            ticket.type === "Ticket" && ticket.relationship_type === 4 ?
              language.TICKET_TYPES.SOLICITATION :
            ticket.type === "Ticket" && ticket.relationship_type === 1 ?
              language.TICKET_TYPES.INCIDENT :
            ticket.type === "Ticket" && ticket.relationship_type === 2 ?
              language.TICKET_TYPES.PROBLEM :
            ticket.type === "Ticket" && ticket.relationship_type === 5 ?
              language.TICKET_TYPES.EVENT :
            ticket.type === "Ticket" && ticket.relationship_type === 6 ?
              language.TICKET_TYPES.CRISIS :
            ticket.type === "Ticket" && ticket.relationship_type === 7 ?
              language.TICKET_TYPES.CRITICAL_INCIDENT :
            ticket.type === "GMUD" && ticket.relationship_type === 1 ?
              language.CORRECTIVE :
            ticket.type === "GMUD" && ticket.relationship_type === 2 ?
              language.EMERGENCY :
            ticket.type === "GMUD" && ticket.relationship_type === 3 ?
              language.NORMAL :
            ticket.type === "GMUD" ?
              language.DEFAULT :
              ticket.relationship_type,
          id: ticket.id,
          name: ticket.name,
          dt_cad: moment(ticket.dt_cad).format('LLL'),
          dt_closed: ticket.dt_closed ? moment(ticket.dt_closed).format('LLL') : '',
          start_date: ticket.start_date ? moment(ticket.start_date).format('LLL') : '',
          end_date: ticket.end_date ? moment(ticket.end_date).format('LLL') : '',
          actions: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13 }}
                      size={0.7}
                      onClick={() => handlePage('remove', ticket)}
                    />
                  </div>
                }
              />
            </React.Fragment>
          )
        }))}
      />

      {page === 'form' && (
        <Dialog
          modal={false}
          open={true}
          onClose={() => handlePage('list')}
          maxWidth="xs"
          title={language.TYPE_RELATIONSHIP}
        >
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
            <Button
              label="Ticket"
              onClick={() => {
                setTypeRelationship(0);
                handlePage('insert');
              }}
              color="primary"
              variant="normal"
            />
            <Button
              label="GMUD"
              onClick={() => {
                setTypeRelationship(1);
                handlePage('insert');
              }}
              color="light"
              variant="normal"
            />
            <Button
              label={language.TASKS}
              onClick={() => {
                setTypeRelationship(2);
                handlePage('insert');
              }}
              color="light"
              variant="normal"
            />
          </div>
        </Dialog>
      )}
      {page === 'insert' && (
        <Dialog
          open={true}
          onClose={() => {
            setTypeRelationship(null);
            handlePage('list');
          }}
          maxWidth="lg"
          monitor_scroll
          title={language.ADD}
        >
          <CommonForm
            values={[]}
            language={language}
            fields={formAssociateTicket}
            onSubmit={(v) => {
              [0, 2].includes(typeRelationship)
                ? (typeRelationship ? sendConnectionTasks(v) : sendConnectTickets(v))
                : sendConnectGmuds(v);
            }}
            button={{
              md: 3,
              offset: { md: 9 },
              label: language.SAVE,
              primary: true,
              style: { margin: 5 },
            }}
          />
        </Dialog>
      )}
      {page === 'remove'
        ? (
          <CommonDelete
            language={language}
            closeModal={() => handlePage('list')}
            onDelete={() => deleteRelation(relationSelected)}
          />
        )
        : null}
    </div>
  );
}
