import React, {useState} from 'react';
import classnames from 'classnames';
import moment from 'moment';
// ---------------------------------------common---------------------------------------------------------------
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Store from '@data/Store';
// -------------------------------------material-ui---------------------------------------------------------------
import {Avatar} from '@material-ui/core';
// -------------------------------------components---------------------------------------------------------------
import BtnOpen from './BtnOpen';
import SponsorsAvatar from './SponsorsAvatar';
import Nivel_2 from './Nivel_2';

const styleProgress = {width:'100%', height:7};
const styleContentProgress = {width:150};
const language = Store.language;


export default function({
	data=[],
	index,
	onChangeMenu,
	onOpenAvaliation,
	userOn
}){
	let [open, setOpen] = useState(null);

	let _data = data.flatMap((e,i)=> {
		let noAssociation = !e.fk_id_ticket && !e.fk_id_project_activity
		let options = [
			{icon:'fas fa-plus', label:language.ADD_A_SUBACTIVITY, color:'var(--primary)'},
			{icon:'fas fa-pencil-alt', label:language.EDIT, color:'var(--success)'},
			{icon:'fas fa-trash-alt', label:language.DELETE, color:'var(--danger)'},
		]
		if (noAssociation) {
			options.push(...[{icon:'fas fa-external-link-square-alt', label: language.ASSOCIATE_WITH_GP, color:'#92D050'},
			{icon:'fas fa-external-link-square-alt', label:language.ASSOCIATE_TICKETS, color:'#019FC2'}])
		}
		return [
		{
			btn:<BtnOpen open={open===i} onClick={()=>setOpen(open=>open===i?null:i)}/>,
			actions:(
				<MenuTooltip options={options} onChange={(data, _index) => onChangeMenu({data, index:_index, id_okr_results: e.id_okr_results}, index, i)}/>
			),
			priorization:(
				[1,2,3,4,5].map(a=>(
					<i key={a} className={classnames(a>e.priorization?'far fa-star':'fas fa-star', 'star-icon')}/>
				))
			),
			keys:e.keys,
			expected_result:e.expected_result,
			association: e.association,
			sponsor:<SponsorsAvatar avatares={e.sponsor}/>,
			date: moment(e.date).format('DD/MM/yyyy'),
			activity_total:e.activity_total,
			period:e.period,
			punctuation:(
				[1,2,3,4,5].map(a=>(
					<i key={a} className={classnames('fas fa-trophy', 'trophy-icon', {active:a<=e.punctuation})}/>
				))
			),
			status: (e.fk_id_ticket || e.fk_id_project_activity) ? (
				<div className='content-progress' style={styleContentProgress}>
					<Progress current={e.status} hiddenText style={styleProgress} background='var(--danger)'/>
					<span>{e.status}%</span>
				</div>
			) : language.NOT_ASSOCIATED_WITH_ACTIVITIES,
			avaliation: e.status === 100 && e.fk_id_user_approver === userOn.id && !e.appreciate ? (
				<Button
					onClick={() => onOpenAvaliation({keys: e.keys, id: e.id_okr_results})}
					varian='circle'
					color='success'
					style={{
						color:'#FFF',
					}}
					label={language.EVALUATE1}
				/>
			) : null,
		},
		e.dataNivel&&open!==null?{
			ConfigCell:{
				cols:{
					col_0:{
						colSpan: 12
					}
				}
			},
			btn:<Nivel_2 indexPai={index} index={i} data={e.dataNivel} onChangeMenu={onChangeMenu}/>
		}:null
	].filter(Boolean)
});

	return(
		<CommonTable
			bar={false}
			pagination={false}
			col={[
				{key:'btn', label:''},
				{key:'actions', label:language.ACTIONS},
				{key:'prioritization', label:language.PRIORITIZATION1},
				{key:'keys', label:language.KEYS},
				{key:'expected_result', label: language.EXPECTED_RESULT},
				{key:'association', label:language.ASSOCIATED_ACTIVITY},
				{key:'sponsor', label:language.RESPONSIBLE},
				{key:'date', label:language.DATE},
				{key:'activity_total', label:language.TOTAL_ACTIVITIES},
				{key:'period', label:language.PERIOD},
				{key:'punctuation', label:language.PUNCTUATION},
				{key:'status', label:language.STATE},
				{key: 'avaliation', label: ''}
			]}
			data={_data}
		/>
	)
}
