import React, { useState, useEffect, memo } from 'react';
import './index.css';
/*------------------------------common----------------------------------*/
import { flexBasis } from '@common/Func';
/*------------------------------material-ui----------------------------------*/
import { Grid } from '@material-ui/core';
/*------------------------------pages----------------------------------*/
import Categories from './Categories';
import SubCategories from './SubCategories';
import Department from './Department';

const RandomColor = (items) => items[Math.floor(Math.random() * items.length)];

export default memo(
  ({
    categ,
    categories,
    subcateg,
    catalog,
    openModalDetail,
    depart,
    comp,
    language,
  }) => {
    const [dataCateg, setDataCateg] = useState([]);
    const [dataSubcateg, setDataSubcateg] = useState([]);
    const [dataCatalogo, setDataCatalogo] = useState([]);
    const [dataDepart, setDataDepart] = useState([]);
    const [companys, setCompanys] = useState([]);

    useEffect(() => {
      if (categories && categ) {
        let data = categ
          .map((h) => {
            let label;
            try {
              label = h.name;
            } catch (e) {}
            let solicitation = h.solicitation || 0;
            let incident = h.incident || 0;
            let problem = h.problem || 0;

            return { label, solicitation, incident, problem };
          })
          .sort(
            (a, b) =>
              b.solicitation +
              b.incident +
              b.problem -
              (a.solicitation + a.incident + a.problem)
          )
          .slice(0, 10);
        console.log('setDataCateg', data);
        setDataCateg(data);
      }

      if (subcateg && categories) {
        try {
          let array = [];
          let data = subcateg.map((h) => {
            let item =
              categories &&
              categories.filter((g) => g.id == h.name && g.tp == 'service')[0]
                .name;
            array[item] = h.name;
            h.name = item;
            return h;
          });
          console.log('setDataSubcateg', data);
          setDataSubcateg(data);
        } catch (r) {}
      }

      if (catalog && categories) {
        try {
          let array = [];
          let data = catalog.map((h) => {
            let item =
              categories &&
              categories.filter((g) => g.id == h.name && g.tp == 'catalog')[0]
                .name;
            array[item] = h.name;
            h.name = item;
            return h;
          });
          console.log('setDataCatalogo', data);
          setDataCatalogo(data);
        } catch (r) {}
      }
      //

      if (depart && categories) {
        let array = [];
        let data = depart.map((h) => {
          try {
            let item = 'Sem Departamento';
            if (h.name > 0) {
              item =
                categories &&
                categories.filter(
                  (g) => g.id == h.name && g.tp == 'department'
                )[0].name;
            }

            array[item] = h.name;
            h.name = item;
            return { name: h.name, value: h.y, id_department: h.id_department };
          } catch (e) {}
        });

        setDataDepart(data.filter(Boolean));
      }

      if (categories && comp && comp.arrayItem) {
        let array = [];
        let data = comp.arrayItem.map((h) => {
          try {
            let item =
              categories &&
              categories.filter((g) => g.id == h.name && g.tp == 'company')[0]
                ? categories.filter(
                    (g) => g.id == h.name && g.tp == 'company'
                  )[0].name
                : '';
            array[item] = h.name;
            h.name = item;
            return { name: h.name, value: h.y, fk_id_company: h.fk_id_company };
          } catch (e) {
            console.log(e);
          }
        });

        setCompanys(data.filter(Boolean));
      }
    }, [categories, categ, subcateg, depart, comp]);

    var colors = [
      '#019FC2',
      '#02CBF0',
      '#02F0DE',
      '#02F079',
      '#A7F002',
      'var(--primary)',
      'var(--success)',
      'var(--warning)',
      'var(--purple)',
      'var(--danger)',
    ];
    /*---------------------------Categories------------------------------------------*/
    let CategoriesProps = {
      data: dataCateg,
      language,
    };
    /*---------------------------SubCategories------------------------------------------*/
    let SubCategoriesProps = {
      data: dataSubcateg,
      data1: dataCatalogo,
      language,
    };
    /*---------------------------Department------------------------------------------*/
    let DepartmentProps = {
      data: dataDepart,
      top10company: companys,
      language,
    };
    console.log('SubCategoriesProps', SubCategoriesProps);
    return (
      <div id="dashboard-block-4" className="mb-2">
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Categories {...CategoriesProps} />
          </Grid>
          <Grid item xs={3}>
            <SubCategories
              {...SubCategoriesProps}
              openModalDetail={openModalDetail}
              categories={categories}
            />
          </Grid>
          <Grid item xs={4}>
            <Department
              {...DepartmentProps}
              openModalDetail={openModalDetail}
              depart={depart}
            />
          </Grid>
        </Grid>
      </div>
    );
  },
  function (prev, next) {
    return _.isEqual(
      [prev.categ, prev.categories, prev.subcateg, prev.depart],
      [next.categ, next.categories, next.subcateg, next.depart]
    );
  }
);
