import React, {useState} from 'react';

export default function({
	users=[],
   activities_predecessor=[],
   companies
}){
	//------------------- caso as opções sejam dinâmicas, fazer o controle dela aqui nessa tela
	return {
		tech(filter){
			return users.filter(filter).map(e => ({value:e.id_user, label:e.name})).filter(e=>e.value!==undefined);
      },
      activities_predecessor(filter=() => true){
		  try{
         return activities_predecessor.filter(filter).map(e=>({value:e.id_project_activity, label:e.name})).filter(e=>e.value!==undefined);
		  }catch(e){
			  return [];
		  }
      },
		company(){
			return companies.map(e => ({value:e.id_company, label:e.name})).filter(e=>e.value!==undefined);
      },
      local(){
         return [{ value: 1, label:'Presencial' }, { value: 2, label:'Remoto' }];
      },
		// ----------------------------funções auxiliares------------------------------------
		getOptions(option, v, id){
			let _return = ((typeof option)=='string' ? this[option](id) : option).filter(e=>e.value!==undefined).find(e=>e.value==v);
			return (_return||{}).label
		},

		getOptionsJoin(option, v, id){
			let _return = ((typeof option)=='string' ? this[option](id) : option).filter(e=>v.includes(e.value)).map(e=>e.label).filter(e=>e.value!==undefined);
			return (_return||[]).join(', ')
		}
	}
}