import React, { useState, useEffect, memo } from 'react';
import './index.css';
import moment from 'moment';
/*----------------------common------------------------*/
import { flexBasis } from '@common/Func';
/*----------------------material-ui------------------------*/
import { Grid } from '@material-ui/core';
/*----------------------components------------------------*/
import CardTotal from './CardTotal';
import CardTotalTicket from './CardTotalTicket';
import CardOpenTicket from './CardOpenTicket';

export default ({
  graphModules,
  ITEMDELAY,
  ITEMOPEN,
  ITEMCLOSE,
  BACKLOG,
  openModalDetail,
  ticketsBroken,
  params,
  language,
}) => {
  const [itemDelay, setItemDelay] = useState([]);
  const [itemOpen, setItemOpen] = useState([]);
  const [itemClose, setItemClose] = useState([]);
  const [backlog, setBacklog] = useState([]);

  const [dayDelayCount, setDayDelayCount] = useState(0);
  const [weekDelayCount, setWeekDelayCount] = useState(0);
  const [monthDelayCount, setMonthDelayCount] = useState(0);

  const [dayCloseCount, setDayCloseCount] = useState(0);
  const [weekCloseCount, setWeekCloseCount] = useState(0);
  const [monthCloseCount, setMonthCloseCount] = useState(0);

  const [dayOpenCount, setDayOpenCount] = useState(0);
  const [weekOpenCount, setWeekOpenCount] = useState(0);
  const [monthOpenCount, setMonthOpenCount] = useState(0);

  useEffect(() => {
    
    setItemDelay(ITEMDELAY.total);
    setItemOpen(ITEMOPEN.total);
    setItemClose(ITEMCLOSE.total);
    setBacklog(BACKLOG.length);
    if (params[0].count_ticket_broken_once) {
      setDayDelayCount(ticketsBroken.dayBroken);
      setWeekDelayCount(ticketsBroken.weekBroken);
      setMonthDelayCount(ticketsBroken.monthBroken);
    } else {
      setDayDelayCount(ITEMDELAY.toDayDelay);
      setWeekDelayCount(ITEMDELAY.toWeekDelay);
      setMonthDelayCount(ITEMDELAY.toMonthDelay);
    }

    setDayCloseCount(ITEMCLOSE.toDayClose);
    setWeekCloseCount(ITEMCLOSE.toWeekClose);
    setMonthCloseCount(ITEMCLOSE.toMonthClose);

    setDayOpenCount(ITEMOPEN.toDayOpen);
    setWeekOpenCount(ITEMOPEN.toWeekOpen);
    setMonthOpenCount(ITEMOPEN.toMonthOpen);
  }, [graphModules.Solicitation, ITEMCLOSE, ITEMDELAY, ITEMOPEN]);
  /*--------------------------dataCardTotal-------------------------------------*/
  let dataCardTotal = [
    
    {
      name: language.CATEGORIZATION,
      value: graphModules.Request ? graphModules.Request : 0,
      color: 'var(--success)',
    },
    {
      name: language.TICKET_TYPES.SOLICITATION,
      value: graphModules.Solicitation > 0 ? graphModules.Solicitation : 0,
      color: '#02CBF0',
    },
    {
      name: language.TICKET_TYPES.INCIDENT,
      value: graphModules.Incident ? graphModules.Incident : 0,
      color: 'var(--warning)',
    },
    {
      name: language.TICKET_TYPES.PROBLEM,
      value: graphModules.Problem ? graphModules.Problem : 0,
      color: 'var(--danger)',
    },
  ];
  /*--------------------------dataCardTotalTicket-------------------------------------*/
  /*{
    title: 'Total de Tickets',
    values: [ITEMDELAY.length, ITEMOPEN.length, ITEMCLOSE.length],
    progress: 53,
    opent: 'openTotal',
    opentc: 'closedTotal',
    opentr: 'resolvedTotal'
    },*/
  let dataCardTotalTicket = {
    data: [
      {
        label: language.BURST,
        value:  monthDelayCount,
        opentr: 'resolvedTotal',
        status: 'Delay',
      },
      {
        label: language.TICKET_STATUS.CLOSED,
        value: itemClose > 0 ? itemClose : 0,
        opentc: 'closedTotal',
        status: 'Closed',
      },
      {
        label: language.TICKET_STATUS.OPENS,
        value: itemOpen > 0 ? itemOpen : 0,
        opent: 'openTotal',
        status: 'Open',
      },
      {
        label: language.BACKLOG,
        value: backlog > 0 ? backlog : 0,
        back: 'back',
        status: 'Backlog',
      },
    ],
    current: itemClose > 0 ? ((itemDelay / itemClose) * 100).toFixed(0) : 0,
  };
  /*--------------------------dataCardOpenTicket-------------------------------------*/
  let dataCardOpenTicket = [
    {
      data: [
        {
          label: language.BURST,
          value: dayDelayCount,
          opentr: 'resolvedDay',
          time: 'Day',
          status: 'Delay',
        },
        {
          label: language.TICKET_STATUS.CLOSED,
          value: dayCloseCount,
          opentc: 'closedDay',
          time: 'Day',
          status: 'Closed',
        },
        {
          label: language.TICKET_STATUS.OPENS,
          value: dayOpenCount,
          opent: 'openDay',
          time: 'Day',
          status: 'Open',
        },
      ],
      label: language.FOLLOY_DAY,
      total: dayOpenCount + dayCloseCount,
      current:
        dayCloseCount > 0
          ? ((dayDelayCount / dayCloseCount) * 100).toFixed(0)
          : 0,
    },
    {
      data: [
        {
          label: language.BURST,
          value: weekDelayCount,
          opentr: 'resolvedWeek',
          time: 'Week',
          status: 'Delay',
        },
        {
          label: language.TICKET_STATUS.CLOSED,
          value: weekCloseCount,
          opentc: 'closedWeek',
          time: 'Week',
          status: 'Closed',
        },
        {
          label: language.TICKET_STATUS.OPENS,
          value: weekOpenCount,
          opent: 'openWeek',
          time: 'Week',
          status: 'Open',
        },
      ],
      label: language.FOLLOY_WEEK,
      total: weekOpenCount + weekCloseCount,
      current:
        weekCloseCount > 0
          ? ((weekDelayCount / weekCloseCount) * 100).toFixed(0)
          : 0,
    },
    {
      data: [
        {
          label: language.BURST,
          value: monthDelayCount,
          opentr: 'resolvedMonth',
          time: 'Month',
          status: 'Delay',
        },
        {
          label: language.TICKET_STATUS.CLOSED,
          value: itemClose,
          opentc: 'closedMonth',
          time: 'Month',
          status: 'Closed',
        },
        {
          label: language.TICKET_STATUS.OPENS,
          value: monthOpenCount,
          opent: 'openMonth',
          time: 'Month',
          status: 'Open',
        },
      ],
      label: language.FOLLOY_MONTH,
      total: monthOpenCount + itemClose,
      current:
        monthCloseCount > 0
          ? ((monthDelayCount / monthCloseCount) * 100).toFixed(0)
          : 0,
    },
  ];
  return (
    <div id="dashboard-block-1" className="mb-2">
      <Grid container spacing={1}>
        <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
          <CardTotal data={dataCardTotal} />
        </Grid>
        <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
          <CardTotalTicket
            openModalDetail={openModalDetail}
            current={dataCardTotalTicket.current}
            data={dataCardTotalTicket.data}
            ticket={{ itemDelay, itemClose, itemOpen, backlog }}
          />
        </Grid>
        {dataCardOpenTicket.map((e) => (
          <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
            <CardOpenTicket
              data={e.data}
              current={e.current}
              label={e.label}
              total={e.total}
              openModalDetail={openModalDetail}
              ticket={{ itemDelay, itemClose, itemOpen }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
