import React, { useEffect, useState, memo } from 'react';
import './index.css';
import moment from 'moment';
/*------------------------------material-ui----------------------------------*/
import { Grid } from '@material-ui/core';
/*------------------------------pages----------------------------------*/
import CallMonth from './CallMonth';
import NPS from './NPS';

export default ({
  callMonth,
  nps,
  yeartype,
  openModalDetail,
  id_tech,
  idGroupTech,
  language,
}) => {
  const [solicitations, setSolicitations] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [problems, setProblems] = useState([]);

  useEffect(() => {
    if (callMonth) {
      setSolicitations(callMonth.map((e) => e[4] || 0));
      setIncidents(callMonth.map((e) => e[1] || 0));
      setProblems(callMonth.map((e) => e[2] || 0));
    }
  }, [callMonth]);

  /*---------------------------------CallMonth-----------------------------------------*/

  let dt = [];

  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((g) => {
    if (yeartype) {
      let valor = yeartype.filter((h) => h.month == g && h.type_tickets == 4);
      let valor1 = yeartype.filter((h) => h.month == g && h.type_tickets == 1);
      let valor3 = yeartype.filter((h) => h.month == g && h.type_tickets == 2);

      dt.push({
        solicitation: valor.length > 0 ? parseInt(valor[0].qtd) : 0,

        incident: valor1.length > 0 ? parseInt(valor1[0].qtd) : 0,
        problem: valor3.length > 0 ? parseInt(valor3[0].qtd) : 0,
      });
    }
  });
  /**month: "05"
qtd: "3"
type_tickets: 1
year: "2020"
1	Incident
2	Problem
3	Liberation
4	Solicitation */
  let CallMonthProps = {
    data: dt,
  };

  let NPSProps = {
    data: [
      { resolved: 2, avaliation_level: 0, experience: 2 },
      { resolved: 5, avaliation_level: 9, experience: 7 },
      { resolved: 1, avaliation_level: 0, experience: 1 },
      { resolved: 9, avaliation_level: 0, experience: 2 },
    ],
    language,
  };

  /*---------------------------------NPS-----------------------------------------*/
  return (
    <div id="dashboard-block-6" className="mb-2">
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <CallMonth
            yeartype={yeartype}
            id_tech={id_tech}
            idGroupTech={idGroupTech}
            {...CallMonthProps}
            dataMonth={{
              solicitations: solicitations,
              problems: problems,
              incidents: incidents,
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <NPS nps_={nps} openModalDetail={openModalDetail} {...NPSProps} />
        </Grid>
      </Grid>
    </div>
  );
};
