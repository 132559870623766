import React, {useEffect, useState} from 'react';
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
import EventBus from '@common/EventBus';

let eventBus = new EventBus();
export default function({
	x,
	y,
	value,
	label,
	z=1,
	tooltipCount,
	collection=[]
}){
	let [active, setActive] = useState(false);
	
	useEffect(()=>{

		let evt = eventBus.on(x+'-'+y, () => setActive(active=>!active));

		return function(){
			evt.unSubscribe();
		}

	},[])
	let p = collection.findIndex(e=>e==value);
	
	return(
		<div 
			className={classnames('selector', {active})} 
			style={{
				'--x':x, 
				'--y':y, 
				'--z':z, 
				'--p':p
			}}
			onClick={() => eventBus.emit(x+'-'+y)}
		>
			<Tooltip
			  html={
			    <span style={{color:'white'}}>{label}</span>
			  }
			  arrow={true}
			  position='left'
			  theme='dark'
			  
			>
				<div className='selector-item'>
					{value}
				</div>
				{tooltipCount?
					<div className='tooltip-count'>
						{tooltipCount}
					</div>
				:null}
			</Tooltip>
		</div>
	)
}