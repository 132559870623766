import React, { memo, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import classnames from 'classnames';
import JoditEditor from 'jodit-react';
import 'jodit/build/jodit.min.css';
import { IJodit } from 'jodit';

let delayTimer;
export default memo(
  ({ f, statusError, _value, _handleValue, errors, onKeyDown }) => {
    let [value, setValue] = useState('');
    let [data, setData] = useState('');
    const [config, setConfig] = useState({
      readonly: false,
      toolbar: true,
      language: 'pt_br',
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: true,
      exec: (instance) => {
        const content = instance.editor.innerHTML;
        handleSaveContent(content);
      },
    });
    const editorJodit = React.useRef(null);

    function handleValue(val, name, selected) {
      setValue(val);
      clearTimeout(delayTimer);

      delayTimer = setTimeout(() => {
        _handleValue(val, f.name, selected);
      }, 400);
    }

    useEffect(() => {
      setValue(_value);
    }, [_value]);

    const onEditorChange = (editorNewValue, name, selected) => {
      setData(editorNewValue);
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        _handleValue(editorJodit.current.value, f.name);
      }, 400);
    };
    return (
      <div>
        {!!f.label && (
          <span
            className={classnames('font label-text-editor', {
              error: statusError[errors[f.name]],
            })}
          >
            {f.label + (f.required ? ' *' : '')}
          </span>
        )}

        <JoditEditor
          ref={editorJodit}
          onBlur={(content, delta, source, editor) =>
            handleValue(content, f.name)
          }
          onChange={(content, delta, source, editor) =>
            onEditorChange(content, f.name)
          }
          config={config}
          value={value || '<span></span>'}
          readOnly={f.disabled}
          placeholder={f.placeholder}
          tabIndex={1}
        />
      </div>
    );
  }
);
