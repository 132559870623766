const ENV = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'

const APIDomain = process.env.API_DOMAIN
const Tenant = process.env.TENANT

const APIRoot = ENV === 'dev' ? 'http://localhost:3000' : Tenant ? '' : `https://${APIDomain}`
const IOTerminal = ENV === 'dev' ? 'http://localhost:4040' : Tenant ? '' : `https://${APIDomain}`
const AgentServers = ENV === 'dev' ? 'http://localhost:4042' : Tenant ? '' : `https://${APIDomain}`
const AgentLinks = ENV === 'dev' ? 'http://localhost:4041' : Tenant ? '' : `https://${APIDomain}`
const AlertServers = ENV === 'dev' ? 'http://localhost:4242' : Tenant ? '' : `https://${APIDomain}`
const AlertLinks = ENV === 'dev' ? 'http://localhost:4141' : Tenant ? '' : `https://${APIDomain}`
const discoverylink = ENV === 'dev' ? 'http://localhost:4043' : Tenant ? '' : `https://${APIDomain}`
const APIRootCore = ENV === 'dev' ? 'http://localhost:3001' : Tenant ? '' : `https://${APIDomain}`
const APIRootSac = ENV === 'dev' ? 'http://localhost:3011' : Tenant ? '' : `https://${APIDomain}`
const APIRootSla = ENV === 'dev' ? 'http://localhost:3010' : Tenant ? '' : `https://${APIDomain}`
const ReportsExport = ENV === 'dev' ? 'http://localhost:3003/report' : Tenant ? '/report' : `https://${APIDomain}/report`
const QueueUI = ENV === 'dev' ? 'http://localhost:3333/kue' : Tenant ? '' : `https://${APIDomain}/kue`

const GCMSERVER = ENV === 'dev' ? 'ws://localhost:9090' : `wss://${APIDomain}/gcm`

const BASE_URL_IMAGE = ENV === 'dev' ? '' : process.env.BASE_URL_IMAGE || '';

export default {
  APIDomain,
  APIRoot,
  AGENT_LINKS: AgentLinks,
  DISCOVERY: discoverylink,
  AGENT_SERVERS: AgentServers,
  ALERT_LINKS: AlertLinks,
  ALERT_SERVERS: AlertServers,
  GCMSERVER,
  IOTerminal,
  REPORTEXPORT: ReportsExport,
  QUEUE_UI: QueueUI,
  Tenant,
  BASE_URL_IMAGE,

  APIEndpoints: {
    LOGIN: APIRoot + '/login',
    FORGOT: APIRoot + '/forgot',
    REDEFINE: APIRoot + '/redefine',
    MY_QUESTIONS: APIRoot + '/password/my-questions',
    MY_REPLIES: APIRoot + '/password/check-replies',
    //  REQUEST: APIRoot + '/rall',
    AVALIATION: APIRoot + '/avaliation',
    COMPANY_: APIRoot + '/getlogo',
    COMPANYS_: APIRoot + '/companyall',
    DEPARTMENT_: APIRoot + '/departmentall',

    //  USER: APIRoot + '/api/user',
    REPORTEXPORT: ReportsExport,
    HOME: APIRoot + '/api/home',
    AUTOMATION: APIRoot + '/api/automation',
    CALENDAR: APIRoot + '/api/calendar',
    ANSWER: APIRoot + '/api/answer',
    ALLOWANCE: APIRoot + '/api/allowance',
    ALLOWANCE_TICKET: APIRoot + '/api/allowanceTicket',
    CHANGE_GROUP_TECH_SLAHISTORY: APIRoot + '/api/slahistory/grouptech/change',
    BUILD: APIRoot + '/api/build',
    AMOUNT_RISK: APIRoot + '/api/amount_risk',
    CANVAS: APIRootCore + '/core/canvas/',
    SNMP: APIRoot + '/api/snmp',
    ASSESSMENT: APIRoot + '/api/assessment',
    BACKUP: APIRoot + '/api/backup',
    PERFIL: APIRootCore + '/core/perfil/',

    MATRIZ: APIRootCore + '/core/matriz/',
    CATALOG: APIRoot + '/api/catalog',
    CATEGORY: APIRoot + '/api/category',
    CHANGE: APIRoot + '/api/change',
    PASSWORDMANAGER: APIRoot + '/api/passwordmanager',
    CHANGE_MILESTONES: APIRoot + '/api/milestone',
    CHANGE_ACTIVITIES: APIRoot + '/api/activities_milestone',
    CHANGE_CAB: APIRoot + '/api/change-cab',
    CHANGE_DATA: APIRoot + '/api/data',
    CHANGE_DATA_LOCATION: APIRoot + '/api/data-location',
    CHANGE_CAB_RISK: APIRoot + '/api/change-cab-risk',
    COMPANY: APIRoot + '/api/company',
    CONTRACT: APIRoot + '/api/contract',
    CONTRACTS: APIRoot + '/api/contracts',
    COUNTRY: APIRoot + '/api/country',

    DASHBOARD: APIRoot + '/api/dashboard',
    DEVICE: APIRoot + '/api/device',
    DEVICEIC: APIRoot + '/api/deviceic',
    DEPARTMENT: APIRoot + '/api/department',

    EVENT_CAB: APIRoot + '/api/event_cab',

    GROUP: APIRoot + '/api/group',

    TIMEZONE: APIRoot + '/api/timezone',

    ITEM: APIRoot + '/api/items',
    ITSM: APIRoot + '/api/itsm',

    LOGOUT: APIRoot + '/logout',

    MONITOR: APIRoot + '/api/monitor',
    ORGANIZATION: APIRoot + '/api/organization',
    OKR: APIRoot + '/api/okr',
    OCCUPATION: APIRoot + '/api/occupation',
    PASSWORD: APIRoot + '/api/password',
    PASSWORD_QUESTIONS: APIRoot + '/api/password/questions',
    PASSWORD_MY_QUESTIONS: APIRoot + '/api/password/my-questions',
    PASSWORD_MY_ALTERNATIVE_EMAIL: APIRoot + '/api/password/my-alternative-email',
    PROJECT: APIRoot + '/api/project',
    PROJECTS: APIRoot + '/api/projects',
    BUGET: APIRoot + '/api/budgetcontrol',

    REPORT: APIRoot + '/api/reports',
    REQUEST: APIRoot + '/api/request',
    RISK: APIRoot + '/api/risk',

    SAC_CONSUMERS: APIRootSac + '/sac/consumers',
    SAC_CONSUMER: APIRootSac + '/sac/consumer',

    SAC_CONSUMER_TYPE: APIRootSac + '/sac/consumertype',

    SAC_THEMES: APIRootSac + '/sac/themes',
    SAC_THEME: APIRootSac + '/sac/theme',

    SAC_SUBJECTS: APIRootSac + '/sac/subjects',
    SAC_SUBJECT: APIRootSac + '/sac/subject',

    SAC_TOPIC: APIRootSac + '/sac/topic',
    SAC_TOPICS: APIRootSac + '/sac/topics',

    SAC_STEPS: APIRootSac + '/sac/steps',
    SAC_TASKS: APIRootSac + '/sac/tasks',
    SAC_TASK: APIRootSac + '/sac/task',
    SAC_ATTENDANCE: APIRootSac + '/sac/attendance',
    SAC_OBSERVERS: APIRootSac + '/sac/observers',
    SERVICES: APIRoot + '/api/services',
    SERVICE_ORDER: APIRoot + '/api/os',
    SLA: APIRoot + '/api/sla',
    SOLUTION: APIRoot + '/api/solution',
    STATE: APIRoot + '/api/state',
    CITY: APIRoot + '/api/city',

    MAIL: APIRoot + '/api/mail',
    COMPANY_TIME: APIRoot + '/api/companytime',

    TABLE_STRUCTURED: APIRoot + '/api/table_structured',
    TASK: APIRoot + '/api/task',
    TASK_TEMPLATE: APIRoot + '/api/tasksTemplate',
    TASKS: APIRoot + '/api/tasks',
    TICKET: APIRoot + '/api/ticket',
    // TYPE_CONTRACTS: APIRoot + '/api/typeContracts',

    USER: APIRoot + '/api/user',
    INVENTORY: APIRoot + '/api/inventory',
    DOWNFILE: APIRoot,
    STOCKIN: APIRoot + '/api/stockin',
    STOCKOUT: APIRoot + '/api/stockout',
    DEVICETYPE: APIRoot + '/api/devicetype',
    AD: APIRoot + '/api/ad',
    SITE: APIRoot + '/api/site',
    REQUESTS: APIRoot,
    ROOT_: APIRoot + '/api',
    BSC: APIRoot + '/api/bsc',
    DISCOVERY: APIRoot + '/api/discovery',
    RISKMATRIZ: APIRoot + '/api/riskmatriz',
    DEMAND: APIRoot + '/api/demand',
    STATUS_AWAITING: APIRoot + '/api/status',
    TECH_EVALUATION_REGISTRATION: APIRoot + '/api/techevaluationregistration',
    HELP: APIRoot + '/api/help',
    PASSWORD_SAFE: APIRoot + '/api/passwordsafe',
    GENERAL_PARAMETERS: APIRoot + '/api/generalparameters',
    TEMPLATE_EMAIL: APIRoot + '/api/template_email',
    EFFORT_VALUE: APIRoot + '/api/effort',
    LOCATION: APIRoot + '/api/location',
    SLA_WORK: APIRootSla + '/sla',
    RESOLUTION_CATEGORY : APIRoot + '/api/resolutioncategory',
    RESOLUTION_CODE : APIRoot + '/api/resolutioncode',
    NOTIFICATION : APIRoot + '/api/notification',
    USER_CATALOG: APIRoot + '/api/usercatalog',
    USER_CATALOGS: APIRoot + '/api/usercatalogs',
    AUDIT: APIRoot + '/api/audit',
    BUSINESS_LINE: APIRoot + '/api/business-line',
  }
}
