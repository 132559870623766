import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Card, Grid } from '@material-ui/core';
import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import CommonTable from '../../../common/CommonTable';

import './index.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

const getStatus = {
  Create: 'Criada',
  Open: 'Aberta',
  Analyze: 'Analisando',
  Rejected: 'Rejeitado',
  Cancelled: 'Cancelada',
  Rollback: 'Rollback',
  Closed: 'Fechada',
  Concluded: 'Concluída',
  CAB: 'CAB',
  Finished: 'Finalizada',
  Progress: 'Em Progresso',
};

const CABDetail = ({
  action,
  APIEndpoints,
  language,
  event,
  onClose,
  redirect,
}) => {
  const [loading, setLoading] = useState(true);
  const [cabs, setCabs] = useState([]);

  useEffect(() => {
    action
      .execute(
        'get',
        `${APIEndpoints.CHANGE}/cab/${event.id}/events?date=${event.date}`
      )
      .then((res) => {
        setCabs(res.data);
        setLoading(false);
      });
  }, [loading]);

  if (loading) {
    return (
      <>
        <hr />
        <div>Carregando Informações...</div>
        <hr />
      </>
    );
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{cabs[0]?.cab_event}</span>
          <span>
            {cabs[0] ? moment(cabs[0].cab_date).format('DD/MM/YYYY') : ''}
          </span>
        </div>
        <hr />
        <CommonTable
          bar={false}
          col={[
            {
              key: 'id_change',
              label: 'ID',
              thConfig: true,
              style: { width: '5%' },
            },
            {
              key: 'title',
              label: language.NAME,
              thConfig: true,
              style: { width: '75%' },
            },
            {
              key: 'analyze',
              label: '',
              thConfig: true,
              style: { width: '3%' },
            },
            {
              key: 'status',
              label: language.STATE,
              thConfig: true,
              style: { width: '7%' },
            },
            {
              key: 'approved',
              label: 'Aprovada',
              thConfig: true,
              style: { width: '5%' },
            },
            {
              key: 'rejected',
              label: 'Reprovada',
              thConfig: true,
              style: { width: '5%' },
            },
          ]}
          data={cabs.map((cab) => ({
            id_change: cab.id_change,
            title: cab.title,
            analyze:
              ['CAB'].includes(cab.change_status) &&
              !cab.rejected &&
              !cab.approved &&
              moment(cabs[0].cab_date).format('DD/MM/YYYY') ===
                moment().format('DD/MM/YYYY') ? (
                <Button
                  variant="circle"
                  icon={
                    <div
                      className="analyzeIcon pointer cardIcon"
                      style={{ width: 17, height: 17 }}
                    />
                  }
                  label={language.ANALYZE}
                  color="light"
                  className="mx-1"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() =>
                    redirect('/dash/changemanagement/analyze', cab.id_change)
                  }
                  size={0.9}
                />
              ) : (
                <Button
                  variant="circle"
                  icon="fas fa-eye"
                  className="mx-1"
                  label={language.VISUALIZE}
                  color="primary"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() =>
                    redirect('/dash/changemanagement/view', cab.id_change)
                  }
                  size={0.9}
                />
              ),
            status: getStatus[cab.change_status],
            approved: cab.approved ? 'Sim' : 'Não',
            rejected: cab.rejected ? 'Sim' : 'Não',
          }))}
          hideFooterToolbar
          language={language}
          mini
          paper={false}
          pagination={false}
          paginationTop={false}
        />
      </div>
    </>
  );
};

export default function ({
  language,
  store,
  redirect,
  APIEndpoints,
  action,
  theme,
}) {
  const calendarComponentRef = useRef();
  const [calendar, setCalendar] = useState('');
  const [date_, setDate_] = useState(moment());
  const [selected, setSelected] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelected('');
    setModalVisible(false);
  };

  useEffect(() => {
    if (date_) {
      let calendarApi = calendarComponentRef.current.getApi();
      let date = moment(date_).format('YYYY-MM-DD');
      calendarApi.gotoDate(date);
    }
  }, [date_]);

  useEffect(() => {
    let calendarApi = calendarComponentRef.current.getApi();
    setMonth(calendarApi.getDate().getMonth());
    setYear(calendarApi.getDate().getFullYear());
  }, []);

  useEffect(() => {
    if (year) {
      action
        .execute(
          'get',
          `${APIEndpoints.CHANGE}/cab/calendar?month=${month + 1}&year=${year}`
        )
        .then((h) => {
          setCalendar(h.data);
        });
    }
  }, [month]);

  const handleEventClick = ({ event }) => {
    setSelected({
      id: event.extendedProps.uuid,
      date: moment(event.start).format('YYYY-MM-DD'),
    });
    openModal();
  };

  let events = [];

  let regex = /(<([^>]+)>)/gi;
  calendar &&
    calendar.events &&
    calendar.events.map((h) => {
      events.push({
        uuid: h.id,
        title: h.name,
        date: moment([year, month, h.date]).format('YYYY-MM-DD'),
      });
    });

  function showMonth() {
    return language.DATE_LANG.monthLongList[month];
  }

  return (
    <div>
      <div
        id="Calendar"
        style={{
          '--form': theme.form,
          '--card': theme.card,
          marginTop: '-1rem',
        }}
      >
        <Dialog
          open={modalVisible}
          onClose={() => closeModal()}
          title={'Evento CAB'}
          maxWidth="lg"
        >
          <CABDetail
            action={action}
            APIEndpoints={APIEndpoints}
            language={language}
            event={selected}
            onClose={() => closeModal()}
            redirect={redirect}
          />
        </Dialog>

        <Card className="content">
          <Grid container spacing={16} className="content-desc">
            <Grid item xs={12} id="content-grid-calendar" className="pt-0">
              <Grid container spacing={16} className="content-desc">
                <Grid item xs={3} className="p-1">
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.GMUD_RUNNING}</h4>
                    </div>
                    <h2>{calendar.indicators?.cab_runnning || 0}</h2>
                  </div>
                </Grid>
                <Grid item xs={3} className="p-1">
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.FINISHED_GMUD}</h4>
                    </div>
                    <h2>{calendar.indicators?.cab_finished || 0}</h2>
                  </div>
                </Grid>
                <Grid item xs={3} className="p-1">
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.GMUD_IN_CAB}</h4>
                    </div>
                    <h2>{calendar.indicators?.cab_daily || 0}</h2>
                  </div>
                </Grid>
                <Grid item xs={3} className="p-1">
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.TOTAL_GMUD}</h4>
                    </div>
                    <h2>{calendar.indicators?.cab_total || 0}</h2>
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ flex: 3 }}>
                  <h1
                    style={{ fontSize: '2rem', fontFamily: 'sans-serif' }}
                  >{`${showMonth()} - ${year}`}</h1>
                </div>
                <div>
                  <Button
                    label={'<'}
                    onClick={() => {
                      let calendarApi = calendarComponentRef.current.getApi();
                      calendarApi.prev();
                      setMonth(calendarApi.getDate().getMonth());
                      setYear(calendarApi.getDate().getFullYear());
                    }}
                  />
                  <Button
                    label={'>'}
                    onClick={() => {
                      let calendarApi = calendarComponentRef.current.getApi();
                      calendarApi.next();
                      setMonth(calendarApi.getDate().getMonth());
                      setYear(calendarApi.getDate().getFullYear());
                    }}
                  />
                </div>
              </div>
              <div id="content-calendar" className="mt-4">
                <FullCalendar
                  ref={calendarComponentRef}
                  defaultView="dayGridMonth"
                  plugins={[dayGridPlugin]}
                  buttonText={{ today: language.TODAY }}
                  eventClick={handleEventClick}
                  events={events}
                  columnHeaderFormat={{ weekday: 'long' }}
                  columnHeaderText={(date) =>
                    moment.weekdays().map((e) => e.replace('-feira', ''))[
                      date.getDay()
                    ]
                  }
                  header={{
                    left: 'title',
                    center: '',
                    right: 'today prev,next',
                  }}
                  titleFormat={{ year: 'numeric', month: 'long' }}
                  locale="pt-br"
                />
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}
