
export function radial({ data, name, height = 150, titlePosition = 30, title = {}, subTitle = {}, minPointSize = 15, innerSize = '100%' }) {
   return {
      chart: {
         type: 'variablepie',
         height,
         style: {
            fontFamily: 'Roboto'
         }
      },

      title: {
         text: `<span style="${title.style}; font-weight:bold; font-size:25px; color:var(--default);">${title.content}</span>
               <br>
               <span style="${subTitle.style}; font-size:12px; color:var(--default);">${subTitle.content}</span>`,
         align: 'center',
         verticalAlign: 'middle',
         y: titlePosition
      },
      tooltip: {
         headerFormat: '',
         pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/> {point.y}'
      },

      credits: { enabled: false },
      plotOptions: {
         variablepie: {
            dataLabels: {
               enabled: false
            },
         }
      },
      series: [{
         minPointSize,
         innerSize,
         zMin: 0,
         name,
         data
      }]
   }
}
export function ItemsWaitingApprovals({ categories, data }) {
   const color = {
      Demandas: 'var(--primary)',
      Projetos: 'var(--warning)',
      Riscos: 'var(--danger)'
   }
   return {
      chart: {
         type: 'bar',
         height: 200,
         style: {
            fontFamily: 'Roboto'
         }
      },
      credits: { enabled: false },
      title: null,
      xAxis: {
         categories: categories,
         labels: {
            style: {

               fontSize: 10
            },
            formatter() {
               return `<span style="color: ${color[this.value]}; font-weight:600">${this.value}</span>`
            }
         }
      },
      yAxis: {
         min: 0,
         title: {
            text: 'medias',
            style: {

               fontFamily: 'Open Sans',
               color: 'var(--primary)',
               fontSize: 10
            }
         },
         labels: {
            style: {
               color: 'var(--primary)',
               fontSize: 10
            }
         }
      },
      legend: {
         reversed: true,
         itemStyle: {
            fontSize: 11,
            color: 'var(--default)'
         }
      },
      plotOptions: {
         series: {
            stacking: 'normal',
            borderRadius: 1,
            dataLabels: {
               enabled: true,
               format: '{point.y:.0f}',
               borderWidth: 0,

               style: {
                  fontSize: 10,
                  color: 'white',
                  textShadow: false,
                  textOutline: 0
               }
            },
            marker: {

            }
         },
      },
      series: data
   }
}
