// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{box-sizing:border-box!important}#gestaoRisco.dev{padding:20px}#gestaoRisco .maturidade,.maturidade2{display:flex}#gestaoRisco{width:100%}#gestaoRisco #wrapp{overflow-x:auto;width:100%!important}#gestaoRisco .paper{width:auto!important}#gestaoRisco .theader-custom{background-color:hsla(0,0%,80%,.7)}#gestaoRisco .theader-custom td{color:rgba(0,0,0,.54);font-size:15px;font-weight:500}#gestaoRisco table thead{display:none}#gestaoRisco table .header-title td{font-size:18px;font-weight:600}#gestaoRisco table .header-col{background:hsla(0,0%,78%,.5)}#gestaoRisco table .header-col td{font-weight:500}#gestaoRisco .item_Maturidade{background-color:#ccc;border:1px solid #fff;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;color:#fff;cursor:pointer;display:inline-block;float:left;height:20px;margin:0;padding:2px;width:20px}#gestaoRisco .item_Maturidade.checado{background-color:#009dc0}#gestaoRisco .maturidade2 .item_Maturidade.checado{background-color:#efa824}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
