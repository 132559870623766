import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MenuItem, IconButton, Switch } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';

import { Radial } from 'sensrit-charts';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactTooltip from 'react-tooltip';
import MdFilterList from 'react-icons/lib/md/filter-list';
import moment from 'moment';
import crypt from '../../util/crypt';
import { percent, mem } from '../../util/MonitorUtils';
import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

let host = '';
let tokenApi = '';
var getConnection = '';

export default class Server extends CommonPage {
  constructor() {
    super();
    this.state = {
      available: false,
      mount: true,
      hold: '',
      aliveHold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disk: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      removeloading: true,
      dateUnavailableServer: '',
    };
    this.handleDisk = this.handleDisk.bind(this);
    this.handleNet = this.handleNet.bind(this);
    this.aliveSignal = this.aliveSignal.bind(this);
    this.fetchAll = this.fetchAll.bind(this);
  }

  componentWillMount() {
    this.store.loading = true;
    host = Constants.APIRoot;
    tokenApi = this.store.getToken();
    this.getConnectionDevice(host, tokenApi);
  }

  componentWillUnmount() {
    this.state.mount = false;
    clearTimeout(this.state.hold);
    clearTimeout(this.state.aliveHold);
    window.clearInterval(window.myInterval);
  }

  handleDisk(event, index) {
    this.setState({ diskIndex: index });
  }
  handleNet(event, index) {
    this.setState({ netIndex: index });
  }

  render() {
    let {
      handlePage,
      language,
      _device,
      down,
      handleModal,
      onRestartAgent,
      onDettachDevice,
      connection,
    } = this.props;
    let {
      values,
      available,
      diskIndex,
      netIndex,
      memory,
      memoryUsed,
      memoryUsedPercent,
      memoryUsedDash,
      cpuUsedDash,
      cors,
      uptime,
      cpuPerc,
      procStats,
      disk,
      netStats,
      netStatsRx,
      netStatsTx,
      removeloading,
      dateUnavailableServer,
    } = this.state;

    if (!_device.values) {
      return (
        <Row className="row flipContainer down">
          <div className="offline">
            <Row className="row infos">
              <Col md={6.5} xs={6}>
                {_device.name.toUpperCase()}
              </Col>
              <Col md={5} xs={6}>
                {_device.ip}
              </Col>
              <Col
                md={0.5}
                xs={1}
                className="text-right"
                style={{ padding: 0 }}
              >
                <IconMenu
                  iconButtonElement={
                    <IconButton
                      className="configurationsIcon"
                      style={{ height: 30, width: 30, margin: '-4px 5px 0 0' }}
                    />
                  }
                >
                  <MenuItem
                    primaryText={language.EDIT_CONFIGURATION}
                    leftIcon={
                      <div
                        className="editIcon"
                        style={{ width: 30, height: 30, margin: '5px 12px' }}
                      />
                    }
                    onClick={() => handlePage('deviceConf', _device)}
                  />
                </IconMenu>
              </Col>
            </Row>
            <Row className="row graphs">
              <Col
                md={4}
                xs={12}
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  className="incidentManagementIcon"
                  style={{ width: 80, height: 80 }}
                />
              </Col>
              <Col md={8} xs={12} style={{ padding: 0 }}>
                <h2>{language.UNAVAILABLE}</h2>
              </Col>
            </Row>
          </div>
        </Row>
      );
      this.store.loading = false;
    }

    //console.log(JSON.stringify(_device));

    let sysInfo = values['sys-info']
      ? values['sys-info']
      : _device.values['sys-info']
      ? _device.values['sys-info']
      : {};
    let servicesStats = values['service-list']
      ? Object.keys(values['service-list']).map((service) => (
          <Switch
            toggled={!/inactive/i.test(values['service-list'][service])}
            trackSwitchedStyle={{ backgroundColor: '#B5FFB9' }}
            thumbSwitchedStyle={{ backgroundColor: '#0DFFB8' }}
            trackStyle={{ backgroundColor: '#FF9B7D' }}
            thumbStyle={{ backgroundColor: '#F44336' }}
            label={service}
            labelPosition="left"
            key={service}
          />
        ))
      : '';

    let conf = values.conf ? values.conf : _device.conf;
    let diskAlert = false;

    disk = disk.sort(
      (a, b) =>
        percent(b.values.used, b.values.total) -
        percent(a.values.used, a.values.total)
    );

    let netOptions = netStats.map((n, i) => (
      <MenuItem value={i} key={i} primaryText={n.name} />
    ));

    let diskOptions = disk.map((d, i) => {
      d.values.used = parseInt(d.values.used);
      d.values.total = parseInt(d.values.total);
      let percent = percent(d.values.used, d.values.total);

      let icon = <span className="text-green">{percent}%</span>;

      if (percent >= conf['fs-list'].triggers.warning.value) {
        icon = <span className="text-yellow">{percent}%</span>;
        diskAlert = diskAlert !== 'danger' ? 'warning' : diskAlert;
      }
      if (percent >= conf['fs-list'].triggers.danger.value) {
        icon = <span className="text-red">{percent}%</span>;
        diskAlert = 'danger';
      }

      return (
        <MenuItem value={i} key={i} primaryText={d.name} rightIcon={icon} />
      );
    });

    let diskPercent = disk[diskIndex]
      ? percent(disk[diskIndex].values.used, disk[diskIndex].values.total)
      : 1;
    let dateUnavailable = values['agent-info']
      ? values['agent-info']
      : _device.values['agent-info'];

    let token = crypt(_device.id_device.toString());
    if (connection) {
      if (connection === 'ssh') {
        token =
          '{"token": "' + token + '", "url": "' + window.location.origin + '"}';
      } else if (connection === 'rdp') {
        token = (
          <div>
            <div>Token: {token}</div>
            <div>URL: {window.location.origin}</div>
          </div>
        );
      }
    }

    if (!removeloading) {
      this.store.loading = true;
    } else {
      this.store.loading = false;
    }

    let dangerMem = 70;
    let warningMem = 90;
    let dangerCpu = 70;
    let warningCpu = 90;
    let dangerList = 70;
    let warningList = 90;
    for (var k in _device.conf) {
      if (k === 'mem-info') {
        try {
          dangerMem = _device.conf[k].triggers.danger.value;
          warningMem = _device.conf[k].triggers.warning.value;
        } catch (e) {
          dangerMem = 70;
          warningMem = 90;
        }
      }
      if (k === 'cpu-stats') {
        try {
          dangerCpu = _device.conf[k].triggers.danger.value;
          warningCpu = _device.conf[k].triggers.warning.value;
        } catch (e) {
          dangerCpu = 70;
          warningCpu = 90;
        }
      }

      if (k === 'fs-list') {
        try {
          dangerList = _device.conf[k].triggers.danger.value;
          warningList = _device.conf[k].triggers.warning.value;
        } catch (e) {
          warningList = 70;
          dangerList = 90;
        }
      }
    }

    if (
      parseInt(cpuUsedDash) >= warningCpu ||
      parseInt(memoryUsedDash) >= warningCpu
    ) {
      return (
        /* cpuUsedDash > warningCpu ?

       memoryUsedDash > warningMem ?

        {diskAlert === 'warning' ||diskAlert === 'danger' ?
            */
        <div />
      );
    } else {
      return null;
    }
  }

  getConnectionDevice(host, tokenApi) {
    action
      .execute('get', `${host}/api/device/${this.props._device.id_device}`, '')
      .then((data) => {
        if (data.data[0].connection == 'ssh') {
          this.fetchAll(host, tokenApi);
        } else {
          this.aliveSignal(host, tokenApi);
        }
      });
  }

  aliveSignal(host, tokenApi) {
    // Fetch cors
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=alive-signal`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          var tempDate = data[0].values_info.replace('"', '');
          tempDate = tempDate.replace('"', '');

          var today = new Date();
          var avaibleTemp = new Date(tempDate);
          var diffMs = today - avaibleTemp; // milliseconds
          // var diffDays = Math.floor(diffMs / 86400000); // days
          // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
          var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
          var parsed = new Date(avaibleTemp);

          function zeroPad(d) {
            return ('0' + d).slice(-2);
          }

          if (diffMins > 99999999999999999999999999999) {
            //  console.log("Inativo por: " + diffMins);
            this.setState({
              available: true,
              dateUnavailableServer:
                zeroPad(parsed.getDate()) +
                '-' +
                zeroPad(parsed.getMonth() + 1) +
                '-' +
                parsed.getUTCFullYear() +
                ' ' +
                zeroPad(parsed.getHours()) +
                ':' +
                zeroPad(parsed.getMinutes()) +
                ':' +
                zeroPad(parsed.getSeconds()),
            });
            clearTimeout(this.state.hold);
            this.store.loading = false;
          } else {
            this.setState({ available: false });
            this.fetchAll(host, tokenApi);
          }
        } else {
          this.setState({ available: true });
          clearTimeout(this.state.hold);
          this.store.loading = false;
        }
      });

    //alert('Live Signal')

    let hold = setTimeout(
      () => {
        this.aliveSignal(host, tokenApi);
      },
      19000,
      this
    );

    this.setState({ aliveHold: hold });
  }

  fetchAll(host, tokenApi) {
    // Fetch de memory
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=mem-info`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let memoryTemp = JSON.parse(data[0].values_info);
          let memoryUsedTempS = String(memoryTemp.usedPercent).replace(
            ',',
            '.'
          );
          let memoryUsedTempI = memoryUsedTempS * 100;
          let memoryUsedDash = (memoryTemp.actualUsed / memoryTemp.total) * 100;
          this.setState({
            memory: mem(memoryTemp.total),
            memoryUsed: mem(memoryTemp.actualUsed),
            memoryUsedDash: memoryUsedDash,
          });
        }
      });

    // Fetch cors
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=cpu-list`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let corsTemp = JSON.parse(data[0].values_info);
          this.setState({ cors: corsTemp.length });
        }
      });

    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=cpu-stats`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let cp = JSON.parse(data[0].values_info);
          let cpuUsedDash = cp.usedPercent;
          this.setState({ cpuUsedDash: cpuUsedDash });
        }
      });

    // Fetch uptime
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=sys-uptime`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let uptimeTemp = JSON.parse(data[0].values_info);
          var x = parseInt(uptimeTemp.uptime);
          var tempTime = moment.duration(x);
          uptimeTemp.uptime =
            tempTime.days() +
            ' ' +
            language.DAYS +
            ' ' +
            tempTime.hours() +
            ' ' +
            language.HOURS +
            ' ' +
            tempTime.minutes() +
            ' ' +
            language.MINUTES +
            ' ';

          this.setState({ uptime: uptimeTemp.uptime });
        }
      });

    // Fetch proc-stats
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=proc-stats`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let procStatsTemp = JSON.parse(data[0].values_info);

          let topByCpuTemp = 0;
          let topByMemTemp = 0;

          procStatsTemp.topByCpu.map(
            (tcpu, key) => (topByCpuTemp += parseFloat(tcpu.cpuPerc.toFixed(1)))
          );

          procStatsTemp.topByMem.map(
            (tcpu, key) => (topByMemTemp += parseFloat(tcpu.memPerc.toFixed(1)))
          );

          this.setState({
            procStats: procStatsTemp.total,
            cpuPerc: topByCpuTemp.toFixed(1),
            memoryUsedPercent: parseInt(topByMemTemp),
          });
        }
      });

    // Fetch disk
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=fs-list`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let diskTemp = JSON.parse(data[0].values_info);

          this.setState({ disk: diskTemp, removeloading: true });
        }
      });

    // Fetch netStats
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=net-list-stats`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let netStatsTemp = JSON.parse(data[0].values_info);
          let netStatsTxTemp = netStatsTemp[0].values.TX;
          let netStatsRxTemp = netStatsTemp[0].values.RX;
          this.setState({
            netStats: netStatsTemp,
            netStatsRx: netStatsRxTemp,
            netStatsTx: netStatsTxTemp,
          });
        }
      });

    // console.log("server");

    let hold = setTimeout(
      () => {
        this.fetchAll(host, tokenApi);
      },
      90000,
      this
    );

    this.setState({ hold: hold });
  }
}
