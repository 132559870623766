import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Switch } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { Checkbox, Card, TextField, Paper } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import FaClose from 'react-icons/lib/fa/close';
import AppBar from '@common/AppBar';
import Store from '@data/Store';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';
import DownloadFiles from '../../util/DownloadFiles';

export default class GroupAddUserFocrm extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      usersGroup: [],
      filterItems: {},
      usersCountGroup: 0,
      open: false,
      items: {},
    };
    this.handleModal = this.handleModal.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.addUsersInGroup = this.addUsersInGroup.bind(this);
    this.getUsersData = this.getUsersData.bind(this);
    this.downloadUsersFromGroup = this.downloadUsersFromGroup.bind(this)
  }

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidMount() {
    this.getUsersData();
  }

  getUsersData() {
    const action = new Actions();
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.GROUP}/getAllUsersFromGroup?offset=0&limit=75`,
        '',
        {
          fk_id_group_users: parseInt(this.props.values.id_group_users || 0),
        }
      )
      .then((data) => {
        this.setState({
          usersGroup: data.data,
          usersCountGroup: data.totalCount
        });
      });
  }

  downloadUsersFromGroup() {
    const action = new Actions();

    Store.toggleAlert(true, 'Export dos dados sendo gerado', 'success');
    let filter = { ...this.state.items };
    filter.fk_id_group_users = parseInt(this.props.values.id_group_users || 0);
    action
      .execute(
        'post',
        Constants.APIEndpoints.GROUP + '/exportusersfromgroup',
        '',
        filter
      )
      .then((res) => {
        Store.toggleAlert(true, 'Export dos dados gerado', 'success')

        DownloadFiles(
          Constants.APIEndpoints.GROUP + '/downloadusersfromgroup/' + res.data.file,
          res.data.file
        );
      });
  }

  checkBoxSelect(id_user) {
    let { users } = this.state;
    if (users.indexOf(id_user) === -1) {
      users.push(id_user);
    } else {
      users = users.filter((id) => id != id_user);
    }
    this.setState({ users: users });
  }

  addUsersInGroup() {
    let { users } = this.state;
    let { values } = this.props;
    if (users.length) {
      const action = new Actions();

      action
        .execute(
          'put',
          Constants.APIEndpoints.GROUP + '/user',
          'groups',
          { users: users, id_group_users: values.id_group_users },
          'id_group_users'
        )
        .then((res) => {
          this.getUsersData();
        });

      this.setState({ users: [] });
      this.handleModal();
    }
  }

  callPageSelectFilterGroupUsers(page, items, rowPerPage, type) {
    items.fk_id_group_users = parseInt(this.props.values.id_group_users || 0);
    let limit = rowPerPage;
    this.setState({ items: items });
    const isEmpty = Object.keys(items).length === 0;

    let offset = (page + 1) * limit;

    if (type == 'filter') {
      offset = 0;
    }

    const action = new Actions();
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.GROUP}/getAllUsersFromGroup?offset=${offset}&limit=${limit}`,
        '',
        items
      )
      .then((data) => {
        this.setState({
          usersGroup: data.data,
          usersCountGroup: res.totalCount
        });
      });
  }

  render() {
    let {
      language,
      handlePage,
      values,
      usersCount,
      checkBoxUserSelect,
      redirect,
      paginateUsers,
      filterUsers,
      callPageSelectFilterUsers,
      users,
    } = this.props;

    const groupTypesLang = {
      1: language.ADD_TECH_TO_GROUP,
      2: language.ADD_SOLICITANT_TO_GROUP,
      3: language.ADD_APPROVAL_TO_GROUP,
      4: language.ADD_USER_TO_NOTIFICATION_GROUP,
    };

    let { open, usersGroup, usersCountGroup } = this.state;

    let fields = [
      { span: 6, name: 'name', label: language.NAME, type: 'text' },
      {
        span: 6,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        multiLine: true,
      },
    ];

    let col = [
      { key: 'id', label: '#', style: { width: 100 } },
      { key: 'name', label: language.NAME, style: { width: 100 } },
      { key: 'email', label: language.EMAIL, style: { width: 100 } },
      { key: 'level', label: language.LEVEL, style: { width: 100 } },
      { key: 'remove', label: language.REMOVE, style: { width: 10 } },
    ];
    let data = usersGroup.map((obj) => ({
      id: obj.id_user,
      name: obj.nome || '',
      email: obj.email,
      level:
        obj.role === 'solicitant'
          ? language.SOLICITANT
          : obj.role === 'client'
          ? language.ADMIN
          : language.TECH,
      remove: (
        <RaisedButton
          circleButton
          label={language.REMOVE}
          color="danger"
          icon="far fa-trash-alt"
          onClick={() =>
            handlePage('remove', {
              id_user: obj.id_user,
              id_group_users: values.id_group_users,
              name: obj.nome,
              detach: true,
            })
          }
          style={{ width: 21, height: 21, fontSize: 13 }}
        />
      ),
    }));

    let colUser = [
      { key: 'check', label: '', style: { width: 10 } },
      { key: 'id', label: language.ID, style: { width: 100 } },
      { key: 'name', label: language.NAME, style: { width: 100 } },
      { key: 'email', label: language.EMAIL, style: { width: 100 } },
      { key: 'level', label: language.LEVEL, style: { width: 100 } },
    ];

    let groupUsers = usersGroup.map((user) => user.id_user) || [];

    let filteredUsers = [];

    filteredUsers =
      users && users.filter((user) => !groupUsers.includes(user.id_user));
    let dataUser = filteredUsers.map((obj) => {
      return {
        check: (
          <Checkbox
            checked={this.state.users.indexOf(obj.id_user) != -1}
            onChange={() => this.checkBoxSelect(obj.id_user)}
          />
        ),
        id: obj.id_user,
        name: obj.name,
        email: obj.email,
        level:
          obj.role === 'solicitant'
            ? language.SOLICITANT
            : obj.role === 'tech'
            ? language.TECH
            : 'Admin',
      };
    });
    let addUserDialog = (
      <Dialog
        modal={false}
        open={open}
        onClose={() => this.handleModal()}
        maxWidth="md"
        title={values && groupTypesLang[values.type]}
      >
        <CommonTable
          title={''}
          index="UserList"
          language={language}
          columns={colUser}
          data={dataUser}
          hideFooterToolbar
          paginationTop={false}
          searchColumn={true}
          countTotal={usersCount}
          isAutomaticPagination={false}
          loadSearch={true}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilterUsers(page, values, rowsPerPage, type)
          }
        />
        <Row style={{ marginTop: 10 }}>
          <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ float: 'right' }}>
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                label={language.ADD}
                primary={true}
                fullWidth
                onClick={this.addUsersInGroup}
              />

            </div>
          </Col>
        </Row>
      </Dialog>
    );

    return (
      <div>
        <Paper className="pb-3 mb-3">
          <Container fluid className="pt-2">
            <Row>
              <Col md={3}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  value={values && values.name}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col md={3}>
                <TextField
                  label={language.LEVEL}
                  disabled={true}
                  value={values && groupTypesLang[values.type]}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col md={3}>
                <TextField
                  label={language.DATE_CAD}
                  disabled={true}
                  value={moment(values && values.dt_cad).format('L')}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col md={3}>
                <TextField
                  label={language.DESCRIPTION}
                  multiLine={true}
                  fullWidth
                  disabled={true}
                  value={values && values.description}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Container>
        </Paper>

        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          hideFooterToolbar
          paginationTop={false}
          searchColumn={true}
          countTotal={usersCountGroup}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.callPageSelectFilterGroupUsers(page, values, rowsPerPage, type)
          }
          beforeBar={
            <div className="d-flex align-items-center">
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={values && groupTypesLang[values.type]}
              onClick={this.handleModal}
            />
            <RaisedButton
            circleButton
            icon="fas fa-file-csv"
            label={language.EXPORT + ' ' + language.USERS}
            color="primary"
            className="mr-3"
            style={{
              fontSize: 15,
            }}
            onClick={() => this.downloadUsersFromGroup()}
          />
          </div>
          }
        />
        {addUserDialog}
      </div>
    );
  }
}
