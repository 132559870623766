import React, { useEffect, useReducer, useState } from 'react'
import Store from '@data/Store';
import Constants from '@data/Constants';
import { spFetch } from '../helpers';
import ServicePortalTableWrapper from './ServicePortalTableWrapper';
import moment from 'moment';

export default function ServicePortalTicketDesignationHistory({ ticketData }) {

  const { language } = Store;
  const formatDate = 'L LT';
  const [isLoading, setIsLoading] = useReducer(v => !v, false);
  const [rows, setRows] = useState([]);

  const HEAD_COLUMNS = [
    { id: 'id', label: 'ID' },
    { id: 'type', label: language.TYPE },
    { id: 'date', label: language.DATE },
    { id: 'name', label: language.NAME },
    { id: 'by', label: language.BY },
  ];

  useEffect(() => {
    setIsLoading(true);

    const urlTicketSla = `${Constants.APIEndpoints.TICKET}/historydesignation/${ticketData.id_tickets}`;

    spFetch(urlTicketSla, 'GET', null, { responseHeaders: true })
      .then((response) => {

        const data = (response.data ?? []).map(item => ({
          id: item.id,
          type: item.tipo,
          date : item.data ? moment(item.data).format(formatDate) : '',
          name: item.nome,
          by: item.por
        }));

        setRows(data);
      })
      .finally(() => setIsLoading(false));
  }, [])

  return (
    <div>
      <ServicePortalTableWrapper
        rows={rows}
        columns={HEAD_COLUMNS}
        isLoading={isLoading}
      />
    </div>
  )
}
