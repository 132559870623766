import React, { useState, useEffect, useReducer } from 'react';

// --------------------common--------------------------
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Progress from '@common/Progress';
import SearchInput from '@common/SearchInput';
// ------------------------------icons-----------------------------------------
import SlowIcon from '@images/svg-icon/SlowIcon';
import HightIcon from '@images/svg-icon/HightIcon';
import CriticIcon from '@images/svg-icon/CriticIcon';
import attachFile from '@images/attachFile.png';
// ----------------------------------------------------
import { Avatar, Card, Checkbox, Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Store from '@data/Store';

const { language } = Store;

export default function({id,action,APIEndpoints,type,users}) {

let [valor,setValor]=useState([]);
  
  useEffect(() => {
    //action.execute('get', APIEndpoints.CATALOG, 'catalogs');
  try{  action
      .execute(
        'get',
        APIEndpoints.RISK + '/history/'+id+'/'+type,
        ''
      )
      .then(h => {
        setValor(h.data);
      });
    }catch(e){}

   
  }, []);

 
  return (
    <div className="ml-2 detail_2">
      <h2 className="title-detail-2">{language.REVISIONS_HISTORY}</h2>
      <div className="pl-4">
        <CommonTable
          bar={false}
          pagination={false}
          paper={false}
          searchColumn
          col={[
            { key: 'activity', label: type },
            { key: 'dt', label: language.DATE },
            { key: 'question', label: language.HISTORY },
            { key: 'users', label: language.INDIVIDUALS }
          ]}
          data={[
            {
              activity: valor.name,
              dt: valor.dt_cad,
              question:<p
                    style={{ color: '#888080' }}
                    dangerouslySetInnerHTML={{ __html:  valor.description }}
                  /> ,
              users: (
                <div  
                style={{ 
                  maxHeight:'100px',
                  overflow: 'auto'}}>
 {valor.users && valor.users.map(x=>(
   <div  style={{display:'flex'}}>
  <Avatar 
  
  style={{
    fontSize: '10px',
    width: '17px',
    height: '17px'}}
  
  className="mr-2 avatar  ">
  {x.substr(0,2)}
  </Avatar><span>{x} </span> 
  </div>       
 ))}
    </div>
              )
            }
          ]}
        />
      </div>
    </div>
  );
}
