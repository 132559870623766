import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
} from 'react';
import './index.css';
import Options from './options';
import moment from 'moment';
import { AutoSizer } from 'react-virtualized';
// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import Header from '@common/Header';
import Button from '@common/Button';
import SearchInput from '@common/SearchInput';
import DownloadFiles from '../../../util/DownloadFiles';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import {
  toBase64,
  addTimes,
  hourToMinutes,
  minutesToSumHour,
} from '@common/Func';
import CircularProgress from '@material-ui/core/CircularProgress';
// --------------------------------material-ui----------------------------------------------------
import { Card } from '@material-ui/core';
// --------------------------------pages----------------------------------------------------
import Form from './Form';
import ListItem from './ListItem';
import { projectListFormat, activitiesList } from './jsonFormat';
// --------------------------------icons----------------------------------------------------
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import Store from '@data/Store';
import history2 from '@common/history2';

import InfiniteScroll from 'react-infinite-scroll-component';
import ReactToPrint from 'react-to-print';

const { language } = Store;

let keys = ['ta', 'tm', 'te', 'at', 'pa'];

export default function (props) {
  const [search, setSearch] = useState('');
  const [formOpen, setFormOpen] = useState(false);
  const [formValue, setFormValue] = useState(undefined);
  const [dt_i, setDt_i] = useState(null);
  const [dt_f, setDt_f] = useState(null);
  const [solicitant, setSolicitant] = useState(null);
  const [percentual, setPercentual] = useState(null);

  const [loading, setLoading] = useState(false);
  const [manager, setManager] = useState(null);
  const [company, setCompany] = useState(null);
  const [projectsConcludeds, setProjectsConcludeds] = useState(false);
  const [showProjects, setShowProjects] = useState(0);
  const [ticket, setTicket] = useState(undefined);
  const [departments, setDepartments] = useState([]);
  const [managerName, setManagerName] = useState('');

  let [allProjects, setAllProjects] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [tickets, setTickets] = useState([]);

  const [totalProject, SettotalProject] = useState(0);

  const action = new Actions();
  const componentRef = useRef();

  const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
    <CustomScrollbars {...props} forwardedRef={ref} />
  ));

  const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
    const refSetter = useCallback((scrollbarsRef) => {
      if (scrollbarsRef) {
        forwardedRef(scrollbarsRef.view);
      } else {
        forwardedRef(null);
      }
    }, []);

    return (
      <PerfectScrollbar
        ref={refSetter}
        style={{ ...style, overflow: 'hidden' }}
        className="pb-2"
        onScroll={onScroll}
        options={{
          minScrollbarLength: 30,
        }}
      >
        {children}
      </PerfectScrollbar>
    );
  };

  const onAdd = (files, index, id) => {
    toBase64(files || []).then((e) => {
      let upload = e.map((e) => ({
        fk_id_project: id,
        file: e.base64,
        name: e.file.name,
        size: e.file.size,
        type: e.file.type,
        key: keys[index],
      }));

      action
        .execute(
          'put',
          `${Constants.APIRoot}/api/project/uploaddoc`,
          'projects',
          upload,
          'id_project',
          { btn_loading: 'post-file-project-' + id + '-' + index }
        )
        .then((res) => {
          action
            .execute('get', Constants.APIRoot + '/api/projects', 'projects')
            .then((p) => {
             // setAllProjects(p.data);
            });
        });
    });

    /*----função responsável por adicionar arquivos na ultima coluna de cada projeto---
      o parâmetro index significa a posição, se é termo de aertura, termo de mudança e etc, o id é o identificador do projeto,
      com esses dois parâmetros eu sei em qual termo quero adicionar um arquivo e em qual projeto.
      o mesmo serve para função abaixo
      */
  };
  const onDownload = (file) => {
    DownloadFiles(
      Constants.APIEndpoints.REQUESTS +
        '/api/downloadgpdoc/' +
        file.id_project_file +
        '&' +
        file.label,
      file.label
    );
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getPj();
  }, [search, dt_i, dt_f, percentual, manager, company, departments,showProjects]);
  const getData = () => {
    action.execute('get', Constants.APIEndpoints.COMPANY, 'companies');
    action.execute(
      'post',
      Constants.APIEndpoints.CONTRACT + '/list',
      'contracts'
    );
    // tjos.action.execute('get', Constants.APIEndpoints.PROJECT + '/prioritization', 'prioritization');
    action
      .execute('get', Constants.APIEndpoints.DEPARTMENT, 'departments')
      .then((d) => {
        setAllDepartments(d.data);
      });

    if (props.location && props.location.state) {
      let ticket_ = props.location.state;
      setFormOpen(true);
      setTicket(ticket_);
    }
  };

  function isItemLoaded(index) {
    return index < totalProject && !!allProjects[index];
  }

  async function getPj(startIndex = 0, endIndex = 5) {
    action
      .execute('post', Constants.APIRoot + '/api/projects', 'projects', {
        limit: endIndex + 1,
        offset: 0,
        search,
        dt_i,
        dt_f,
        percentual,
        manager,
        company,
        departments,
        showProjects,
      })
      .then((p) => {
      let total = 0;
      try{

       total =  p.data[0].total_count
      }catch(e){}
        SettotalProject(total);
        setAllProjects(p.data);



      });
  }

  const onRemoveFile = (file, index, id) => {
    action.execute(
      'put',
      `${Constants.APIRoot}/project/delete/file/${file.id_project}/0/${file.id_project_file}`,
      'projects',
      null,
      'id_project'
    );
  };

  const removeProject = ({ id_project, user_cad }) => {
    let user = Store.getUserLoged();
    if (parseInt(user.id) == parseInt(user_cad) || user.role === 'client') {
      action.execute(
        'delete',
        Constants.APIEndpoints.PROJECT + '/deleteProject/' + id_project,
        'projects',
        null,
        'id_project',
        { btn_loading: 'project_' + id_project }
      );
      action
        .execute('get', Constants.APIRoot + '/api/projects', 'projects')
        .then((p) => {
          setAllProjects(p.data);
        });
    } else {
      Store.toggleAlert(
        true,
        language.ONLY_THE_PROJECT_MANAGER_CAN_PERFORM_THIS_ACTION,
        'error'
      );
    }
  };

  const handleValue = (formValue) => {
    setFormValue(formValue);
  };

  const onEdit = (id) => {
    let pj = Store.projects.find((e) => e.id_project == id) || {};
    setFormValue(pj);
    setFormOpen(true);
  };

  const onPause = (id, pause) => {
    action.execute(
      'put',
      `${Constants.APIEndpoints.PROJECT}/update/${id}`,
      'projects',
      { pause_project: pause },
      'id_project',
      { btn_loading: 'project_close_' + id }
    );
    action
        .execute('get', Constants.APIRoot + '/api/projects', 'projects')
        .then((p) => {
          setAllProjects(p.data);
        });
  };

  const onClose = (id) => {
    action.execute(
      'put',
      `${Constants.APIEndpoints.PROJECT}/update/${id}`,
      'projects',
      { closed: true },
      'id_project',
      { btn_loading: 'project_close_' + id }
    );
  };

  const onDuplicate = (id) => {
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.PROJECT}/clone/${id}`,
        'projects',
        null,
        null,
        { btn_loading: 'project_clone_' + id }
      )
      .then((res) => {
        getData();
      });
  };

  const redirect = (route, state) => {
    window.history.pushState({ key: 'sensr' + route, state: state }, '', route);
    window.location.reload();
  };
  const redirect2 = (route, state) => {
    history2.push(route, state);
  };
  let { language, companies, users, project_stages, contracts } = Store;
  let { PROJECTS } = Store.theme;
  let options = Options({
    companies,
    users,
    language,
    project_stages,
    contracts,
    tickets,
    departments,
    allDepartments,
  });
  let companyOptions = options.company();
  let data = allProjects.map((e) => {
    let act = [
      ...e.utils.DONE,
      ...e.utils.BACKLOG,
      ...e.utils.DOING,
      ...e.utils.STOPPED,
      ...e.utils.VALIDATION,
      ...e.utils.TODO,
    ];

    let actDetail = act.reduce(
      (obj, a) => {
        let hours_consumed = (e.hours_consumed || []).filter(
          (e) => e.fk_id_activity == a.id_project_activity
        );
        // ----------------checa se a atividade se enquadra na categoria de horas planejadas----------
        if (a.hours || a.minutes) {
          obj.planned_hours.push(activitiesList({ a, users, pj: e }));
        }

        // ----------------checa se a atividade se enquadra na categoria de horas realizadas----------
        if (hours_consumed.length) {
          //obj.realized_hour.push({hours_consumed, ...a});
          obj.realized_hour.push(activitiesList({ a, users, pj: e }));
        }

        // ----------------checa se a atividade se enquadra na categoria de horas excedidas----------
        if (hours_consumed.length) {
          let hours_consumed_total = hours_consumed.reduce(
            (h, e) => addTimes(h, e.hours_consumed),
            '00:00:00'
          );

          let total_hour_realized = hourToMinutes(hours_consumed_total);
          let total_hour_foreseen = hourToMinutes(
            a.hours + ':' + a.minutes + ':' + '00'
          );
          let exceeded_hours = total_hour_realized - total_hour_foreseen;

          if (exceeded_hours > 0) {
            //obj.exceeded_hours.push({exceeded_hours : minutesToSumHour(exceeded_hours), ...a});
            obj.exceeded_hours.push(activitiesList({ a, users, pj: e }));
          }
        }
        return obj;
      },
      {
        planned_hours: [],
        realized_hour: [],
        exceeded_hours: [],
      }
    );

    let exceeded_hours = act.map((e) =>
      moment(e.dt_end).diff(e.dt_up, 'minutes')
    );
    exceeded_hours = exceeded_hours.reduce((n, e) => n + e, 0);

    return projectListFormat({
      options,
      companyOptions,
      users,
      e,
      actDetail,
    });
  });

  const [hasMore, setHasMore] = useState(true);

  const [firstTime, setFirstTime] = useState(false);
  const [formRefs, setFormRefs] = useReducer((state, val) => {
    return [...state, val];
  }, []);
  const getUserName = (id) => {
    if (typeof id === 'number' && id > 0) {
      action.execute('post', `${Constants.APIEndpoints.USER}/filter`, '', { manager: true })
        .then((data) => {
          setManagerName(data.data.find((user) => user.id_user === id).name);
        });
    }
  };
  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <Form
        project_stages={project_stages}
        open={formOpen}
        values={formValue}
        redirect={redirect}
        projects={allProjects}
        options={options}
        handleValue={handleValue}
        ticketparam={ticket}
        execute={action.execute}
        APIEndpoints={Constants.APIEndpoints}
        onClose={() => {
          setFormOpen(false);
          setFormValue(undefined);
          getData();
        }}
        setTickets={setTickets}
        user={Store.getUserLoged()}
      />

      <PageLayout
        title={language.PROJECTS}
        subTitle={`${language.PROJECT_MANAGEMENT}1 /`}
        rightElements={[
          <div
            style={{ width: '100%', zoom: 0.8, marginTop: '1rem' }}
            zoom={0.8}
            className="-mr-2"
          >
            <CommonForm
              onRef={(ref) => setFormRefs(ref)}
              values={{
                showProjects: showProjects,
              }}
              individual
              onChangeField={(f, v) => {

                if (f.name == 'showProjects') setShowProjects(v);

                if (f.name == 'dt_i') setDt_i(v);

                if (f.name == 'dt_f') setDt_f(v);

                if (f.name == 'percentual') setPercentual(v);

                if (f.name == 'department') setDepartments(v);

                if (f.name == 'manager') {
                  getUserName(v);
                  setManager(v);
                }
                if (f.name == 'company') {
                  setCompany(v);

                  action.execute(
                    'post',
                    `${Constants.APIEndpoints.DEPARTMENT}/filter`,
                    '',
                    { fk_id_company: v },
                    ''
                  ).then((d) => {
                    setAllDepartments(d.data);
                  });
                };
              }}
              spacing={2}
              fields={[
                {
                  col: 1.8,
                  type: 'select',
                  name: 'showProjects',
                  label: language.VIEW_PROJECTS,
                  options: [
                    { label: language.ALL, value: 0 },
                    { label: language.NOT_COMPLETED_PLURAL, value: 1 },
                    { label: language.COMPLETED, value: 2 },
                    { label: language.PAUSED, value: 3 },
                    { label: language.CANCELED, value: 4 },
                  ],
                },
                {
                  col: 1.2,
                  type: 'date',
                  name: 'dt_i',
                  label: language.START_DATE,
                },
                {
                  col: 1.2,
                  type: 'date',
                  name: 'dt_f',
                  label: language.END_DATE,
                },
                {
                  col: 1.2,
                  type: 'select',
                  name: 'percentual',
                  label: language.PERCENTAGE,
                  options: Array.from(new Array(21), (_, i) => (i + 0) * 5).map(
                    (e) => ({ label: e + '%', value: e })
                  ),
                },
                {
                  col: 2,
                  type: 'autocomplete',
                  name: 'manager',
                  label: language.MANAGER,
                  textlabel: language.MANAGER,
                  className: 'tech-group-ticket',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: managerName,
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: manager,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  detail: true,
                  methodGetAll: 'POST',
                  textlabel: language.MANAGER,
                  filterAll: { manager: true },
                },
                {
                  col: 2,
                  type: 'select',
                  name: 'company',
                  label: language.COMPANY,
                  options: options.company(),
                },
                {
                  col: 2,
                  type: 'select',
                  name: 'department',
                  label: language.DEPARTMENT,
                  options: options.allDepartments(),
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (
                    <Button
                      icon="fas fa-eraser"
                      label="Resetar"
                      color="primary"
                      variant="circle"
                      style={{ marginTop: '1rem' }}
                      onClick={() => {
                      window.location.reload();
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>,
        ]}
      >
        <div id="ProjectsList" ref={componentRef}>
          <Header className="justify-content-between">
            <div className="d-flex align-items-center">
              <Button
                icon="fas fa-plus"
                color="warning"
                variant="circle"
                label={language.ADD}
                className="mr-3"
                onClick={() => {
                  setFormOpen(true);
                  setTicket(undefined);
                }}
              />
              <SearchInput
                onChange={(evt) => {
                  setSearch(evt.target.value);
                }}
                clearSeach={() => {
                  setSearch('');
                }}
              />
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="warning"
                    icon="fas fa-print"
                    label={language.PRINT}
                    className="m-auto mr-5"
                    onClick={() => {}}
                  />
                )}
                content={() => componentRef.current}
              />
            </div>
          </Header>
          {loading == true ? (
            <CircularProgress color="secondary" />
          ) : (
            <Card
              className="content"
              id="content-project-list"
              style={{ '--done': PROJECTS.done, minHeight: '100vh' }}
            >
              {data.length > 0 ? (
                <AutoSizer style={{ height: 'auto', width: 'auto' }}>
                  {({ height, width }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={totalProject}
                      loadMoreItems={getPj}
                    >
                      {({ onItemsRendered, ref }) => (
                        <FixedSizeList
                          onItemsRendered={onItemsRendered}
                          ref={ref}
                          height={height}
                          width={width}
                          itemCount={data.length}
                          itemSize={250}
                          outerElementType={CustomScrollbarsVirtualList}
                        >
                          {({ index, style, dt }) => (
                            <div style={style}>
                              <ListItem
                                user={Store.getUserLoged()}
                                onEdit={onEdit}
                                onPause={onPause}
                                redirect={redirect2}
                                data={data[index]}
                                onAdd={onAdd}
                                onRemoveFile={onRemoveFile}
                                key={data[index].id}
                                onDownload={onDownload}
                                removeProject={removeProject}
                                onClose={onClose}
                                onDuplicate={onDuplicate}
                              />
                            </div>
                          )}
                        </FixedSizeList>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              ) : null}
            </Card>
          )}
          <div style={{ textAlign: 'right', margin: '20px 0' }}>
            {language.PROJECTS}: {totalProject}
          </div>
        </div>
      </PageLayout>
    </CommonPage>
  );
}
