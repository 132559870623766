import React, { useState } from 'react';

// ----------------------------------------------------------------------

import Animate from '@common/Animate';

import { CoinList } from './CoinList';
import { CoinDelete } from './CoinDelete';
import { CoinAddOrEdit } from './CoinAddOrEdit';

// ----------------------------------------------------------------------

export const CoinManagementList = () => {
  const [page, setPage] = useState(null);
  const [editCoin, setEditCoin] = useState(null);
  const initialCoinState = {
    id_coin: null,
    name: '',
  };
  const [coinSelected, setCoinSelected] = useState(initialCoinState);

  return (
    <Animate>
      <CoinList
        setCoinSelected={setCoinSelected}
        setEditCoin={setEditCoin}
        setPage={setPage}
        page={page}
      />
      {page === 'delete' && (
        <CoinDelete
          closeModal={() => {
            setPage(null);
            setCoinSelected(initialCoinState);
          }}
          id={coinSelected?.id_coin}
          message={coinSelected?.name}
        />
      )}

      {(page === 'add' || page === 'edit') && (
        <CoinAddOrEdit
          closeModal={() => {
            setCoinSelected(initialCoinState);
            setPage(null);
          }}
          page={page}
          editCoin={editCoin}
        />
      )}
    </Animate>
  );
};
