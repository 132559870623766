import screenfull from 'screenfull';
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import AssessmentTerminal from '../assessment/AssessmentTerminal';
import PageLayout from '../../common/PageLayout';
import { TextField } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import DateFnsUtils from '@date-io/date-fns';
import SolutionContainerTicket from '../solution/SolutionContainerTicket';
import { Checkbox } from '@material-ui/core';
import Help from '@common/components/Help';
import GuacamoleView from '../guaca/index'
import moment from 'moment';
import TicketComment from './TicketComment';
import TicketNotes from './TicketNotes';
import TicketUtils from '../../util/TicketUtils';
import { Tooltip } from 'react-tippy';
import TicketNotesproblem from './TicketNotes_problem';
import CommonForm from '@common/CommonForm';
import CommonForm2 from '@common/CommonForm2';
import CommonPage from '@common/CommonPage';
import classnames from 'classnames';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FaClose } from 'react-icons/lib/fa';
const crypto = require('crypto');
import RaisedButton from '@common/RaisedButton';
import CommonTable from '@common/CommonTable';
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableHeaderColumn,
  TableRowColumn
} from '@material-ui/core';

import Dialog from '@common/Dialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import $ from 'jquery';
import TicketDescription from './TicketDescription.js';
import ContentFontDownload from 'material-ui/SvgIcon';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';

import { Card, Grid, Paper, Chip, Avatar } from '@material-ui/core';
import AppBar from '@common/AppBar';
import './TicketDetailTab.css';
import FaceIcon from '@material-ui/icons/Face';
import AttachFile from '@common/AttachFile';
import { isFuture } from 'date-fns';
import knowledgeBaseIcon2 from '@images/knowledgeBaseIcon2.png';
import ListColumn from '@common/ListColumn';
import { applyTimezoneOnHTML } from '../../common/Timezone';
var CryptoJS = require('crypto-js')

const styless = theme => ({
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '1200px'
  },
  dialogPaperAlert: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '90vh'
  },
  dialogPaper_: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '2000px'
  }
});

const styles = {
  tdTable: { width: '26px', paddingLeft: '15px', paddingRight: '15px' }
};

function decryp(aMsg) {
  try{
    var aSecret = 'Orch3str0!@forever!';
    aMsg = aMsg.replace(/\s/g, "");
    let decipher, tRet;
    decipher = crypto.createDecipher("aes-256-cbc", aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
    tRet += decipher.final("utf8");
    return tRet;
  }catch(e){
    return {}
  }

}

var objTicketsConnect = [];

@observer
class TicketDetailTab extends CommonPage {
  constructor() {
    super();
    this.state = {
      index: 0,
      openAssociate: false,
      ticketsConnect: [],
      open: false,
      ticketsSelected: [],
      connection: '',
      openassessment: false,
      resolutionModal: false,
      valor: {
        time_resolution: 0,
        time: 0,
        saveTemplate: true,
        modal: false,
        modalBase: false
      },
      openReopen: false,
      status: 0,
      idgrouptech: 0,
      modaltech: false,
      ticketNew: 0,
      menu: {
        menuStatus: null
      }
    };
    this.openAssociateOpen = this.openAssociateOpen.bind(this);
    this.openAssociateClose = this.openAssociateClose.bind(this);
    this.connectTickets = this.connectTickets.bind(this);
    this.sendConnectTickets = this.sendConnectTickets.bind(this);
    this.onCloseTicket = this.onCloseTicket.bind(this);
    this.updateRecorrence = this.updateRecorrence.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.modaNew = this.modaNew.bind(this);
    this.startAssessment = this.startAssessment.bind(this);
    this.updateTemp = this.updateTemp.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.updateTicketTech = this.updateTicketTech.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.modalBase = this.modalBase.bind(this);
    this.up = this.up.bind(this);
    this.handleValuesstatus = this.handleValuesstatus.bind(this);
    this.modal = this.modal.bind(this);
    this.finishAssessment = this.finishAssessment.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    let justify = document.getElementById('justify').value;

    // this.refs.justify.getValue();

    this.props.onChangeStatusJust(
      this.props.ticket.id_tickets,
      'reopen',
      justify
    );
    this.modal(false);
  }

  modalBase(item) {
    this.setState({ modalBase: item, modal: false });
  }
  finishAssessment(contract, note, tickets) {
    screenfull.exit();
    let { assessment } = this.store;
    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT,
      'assessment',
      { tickets: tickets, path: assessment[0].path, note: note },
      null,
      'Finished the remote connection'
    );
    this.setState({ openassessment: false });
    //this.redirect('/dash/tickets/detail', { ticket: this.props.location.state.ticket })
  }

  modalClose() {
    this.setState({ modal: false, modaltech: false });
    //this.redirect('/tech/tickets/detail', { ticket: this.props.ticket })
  }
  modal(v) {
    this.setState({ openReopen: v });
  }
  modaNew() {
    var hour = 0;
    var minute = 0;
    var second = 0;
    let ticket = this.props.ticket;
    if (ticket.type_tickets === 'Problem') {
      try {
        let data = null;

        if (ticket != undefined) {
          var time1 = ticket.time_analyze;
          var time2 = ticket.time_solution_development;
          var time3 = ticket.time_problem_identification;
          var time4 = ticket.time_test;



          var splitTime1 = time1.split(':');
          var splitTime2 = time2.split(':');
          var splitTime3 = time3.split(':');
          var splitTime4 = time4.split(':');

          hour =
            parseInt(splitTime1[0]) +
            parseInt(splitTime2[0]) +
            parseInt(splitTime3[0]) +
            parseInt(splitTime4[0]);
          minute =
            parseInt(splitTime1[1]) +
            parseInt(splitTime2[1]) +
            parseInt(splitTime3[1]) +
            parseInt(splitTime4[1]);
          hour = hour + minute / 60;
          minute = minute % 60;
          second =
            parseInt(splitTime1[2]) +
            parseInt(splitTime2[2]) +
            parseInt(splitTime3[2]) +
            parseInt(splitTime4[2]);
          minute = minute + second / 60;
          second = second % 60;

          data = parseInt(hour) + ':' + parseInt(minute) + ':' + second;
        }
        return data;
      } catch (e) {
        return null;
      }
    } else {
      try {
        ticket.config[0].forms.map(obj => {
          hour = hour + parseInt(obj.tempo.split(':')[0]);
          minute = minute + parseInt(obj.tempo.split(':')[1]);
        });
        hour = hour + minute / 60;
        minute = minute % 60;

        return (
          parseInt(hour)
            .toString()
            .padStart(2, '0') +
          ':' +
          parseInt(minute)
            .toString()
            .padStart(2, '0') +
          ':' +
          '00'
        );
      } catch (e) { }
    }
  }

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  modalOpen(v) {
    if (v == 'tech') {
      this.setState({ modaltech: true });
    } else {
      this.setState({ modal: true });
    }
  }
  startAssessment() {


    let { contracts, devices, ticket } = this.props;

    let device = devices
      .filter(d => d.id_device == ticket.fk_id_device)

    //console.log(device)

    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT,
      'assessment',
      {
        device: ticket.fk_id_device,
        tickets: undefined,
        contract: ticket.config[0].fk_id_contract
      },
      null,
      'Waiting for remote connection'
    );


    this.setState({
      ticketsSelected: ticket, connection: device[0].connection, device: device[0],
      openassessment: true
    });


  }

  /*
   startAssessment() {
     let { contracts, devices, ticket } = this.props;
     let device = devices
       .filter(d => d.id_device == ticket.fk_id_device)
       .map(c => {
         return {
           connection: c.connection,
           ip: c.ip
         };
       });
     let contract = contracts
       .filter(d => d.id_contract == ticket.config[0].fk_id_contract)
       .map(c => {
         return { c };
       });
     //
     /**
     this.props.startAssessment(
       ticket.config[0].fk_id_contract,
       ticket.fk_id_device,
       [],
       device[0].connection,
       device[0].ip
     );
     screenfull.request();
     //${this.props.APIEndpoints.TICKET}
     if (device == 84 || device == 85) {
       this.setState({ showFrame: true });
     } else {
       this.props.action.execute(
         'post',
         this.props.APIEndpoints.ASSESSMENT,
         'assessment',
         {
           device: ticket.fk_id_device,
           tickets: undefined,
           contract: ticket.config[0].fk_id_contract
         },
         null,
         'Waiting for remote connection'
       );
       this.setState({
         ticketsSelected: [],
         connection: device[0].connection,
         openassessment: true
       });
       //  this.setState({ page: 'terminal' });
     }
     // }
   } /*
   /*
     openAssociateOpen = () => {
       this.setState({openAssociate: true});
       this.props.action.execute('get', `${this.props.APIEndpoints.TICKET}`, 'tickets');
     };
   */
  handleResolutionModal = () => {
    this.setState({ resolutionModal: !this.state.resolutionModal });
  };

  openAssociateOpen = () => {
    this.props.action
      .execute('get', `${this.APIEndpoints.TICKET}/contract/${this.props.ticket.id_tickets}`, '')
      .then(res => {
        this.setState({ ticketsConnect: res.data, openAssociate: true });
      });
  };


  updateRecorrence = t => {
    let value = { id_tickets: t.id_tickets };
    this.props.action.execute(
      'get',
      `${this.props.APIEndpoints.TICKET}/updateRec/${t.id_tickets}`,
      'tickets'
    );
    this.redirect('/dash/tickets/detail', { ticket: value });
  };

  updateTemp(idHtml, ticket, id_template) {
    $('#' + idHtml + ' input').each(function () {
      $(this).attr('value', $(this).val());
    });

    if (this.state.time != null) {
      let values = {
        html: $('#' + idHtml).html(),
        ticket: ticket,
        id_template_ticket: id_template,
        time: this.state.time
      };
      this.props.onupdateTicket(values);
    } else {
      alert('Time null');
    }
  }

  onChangeField(value) {

    this.state.time = value;

    this.setState({
      time: value
    });
  }
  openAssociateClose = () => {
    this.setState({ openAssociate: false });
  };
  up() {
    this.setTimeout(this.setState({ ticketNew: 0 }), 3000);
  }
  connectTickets(id) {
    objTicketsConnect.push(id);
  }

  handleValuesstatus(value) {
    let array = { stop_ticket: value, action: 'notes' };
    let ticket = this.props.ticket;

    this.props.action
      .execute('post', `${this.APIEndpoints.TICKET}/${ticket.id_tickets}`, 'tickets', array)
      .then(res => {
        this.props.handleticket(res.data);
      });
  }

  updateTicketTech(values) {
    let ticket = this.props.ticket;
    let req = [];
    if (ticket.config != undefined) {
      if (ticket.config[0].dt_cad != null) {
        req = [
          {
            dt_up: ticket.config[0].dt_up,
            dt_cad: ticket.config[0].dt_cad,
            id_request: ticket.config[0].id_request
          }
        ];
      }
    }

    values.sla_time = TicketUtils.calcSla(
      ticket,
      'ticket',
      this.props.language,
      req,
      ticket.sla_task,
      null
    ).time;
    values.action = 'notes';
    let grupo;

    this.props.action
      .execute('post', `${this.APIEndpoints.TICKET}/${values.id_tickets}`, 'tickets', values)
      .then(res => {
        this.props.handleticket(res.data);
      })
      .catch((error) => {
        console.error(error);
      });

    this.setState({ modal: false, modaltech: false });
  }

  sendConnectTickets = values => {
    let tempTickets = { id_tickets_con: values.association };

    if (values.association.length != 0) {
      this.props.sendConnectTicketsNew(
        tempTickets,
        this.props.ticket.id_tickets
      );
    }

    this.setState({ openAssociate: false });

    //let value = { id_tickets: this.props.ticket.id_tickets };
    //this.redirect('/dash/tickets/detail', { ticket: value })
  };

  onCloseTicket = () => {
    redirect(backRoute, { route: back });
  };

  percent(start) {
    let count = start;
    let statusActivity = [];

    while (count < 100) {
      statusActivity.push({ value: count, text: `${count}%` });
      count = count + 10;
    }

    return statusActivity;
  }
  handleMenuStatus = evt => {
    var menu = this.state.menu;
    menu.menuStatus = evt ? evt.currentTarget : null;

    this.setState({ menu });
  };
  render() {
    let {
      language,
      handlePage,
      redirect,
      ticket,
      Alltickets,
      onChangeStatus,
      onChangeStatus_,
      onCommentTicket,
      onFormSubmit,
      onNotesTicket,
      onNotesTicketTab,
      onNotesTicket_1,
      onNotesTicket_2,
      onNotesTicket_3,
      onNotesTicket_4,
      back,
      groups,
      statusTicket,
      onResolution,
      requestTicket,
      type_solution,
      onDescriptionTicket,
      startAssessment,
      contracts,
      sol,
      users,
      md,
      onDrop,
      disabled,
      removeFile,
      fileup,
      classes,
      closeTab,
      handleticket,
      ticketNew
    } = this.props;
    let { open, valor, saveTemplate, modal } = this.state;
    let { tickets } = this.store;

    if (this.props.ticketNew != 0) {
      ticket = this.props.ticketNew;
      // this.up()
    }

    let recurrentOptions = [
      { value: 0, text: '' },
      { value: 1, text: language.RECURRENCE_TYPE.NO_RECURRENCE },
      { value: 2, text: language.RECURRENCE_TYPE.DAILY },
      { value: 3, text: language.RECURRENCE_TYPE.WEEKLY },
      { value: 4, text: language.RECURRENCE_TYPE.QUARTERLY },
      { value: 5, text: language.RECURRENCE_TYPE.SEMESTER },
      { value: 6, text: language.RECURRENCE_TYPE.MONTHLY },
      { value: 7, text: language.RECURRENCE_TYPE.YEARLY }
    ];
    let url = window.location.href;
    let values = null;
    let list = null;
    if (users != undefined) {
      list = users
        .filter(v => v.role === 'tech')
        .map(tk => ({ value: tk.id_user, text: tk.name }));
    }

    values = {
      fk_id_teck_new: 'fk_id_teck_new',
      id_tickets: ticket.id_tickets
    };

    let req = [];
    if (ticket.config != undefined) {


      ticket.comments_tech = ticket.config[0].comments_tech
      if (ticket.config[0].dt_cad != null) {
        req = [
          {
            dt_up: ticket.config[0].dt_up,
            dt_cad: ticket.config[0].dt_cad,
            id_request: ticket.config[0].id_request
          }
        ];
      }
    }


    let tipostopticketLabel = [
      this.props.language.TICKET_STATUS.IN_PROGRESS,
      this.props.language.WAITING + ' ' + this.props.language.REQUESTER,
      this.props.language.WAITING +
      ' ' +
      this.props.language.PROVIDER.replace('es', ''),
      this.props.language.WAITING + ' ' + this.props.language.PROJECT,
      this.props.language.WAITING + ' ' + 'GMUD'
    ];
    let tipostopticket = [];

    let closedButton =

      (ticket.status === 'Resolved' &&
        ticket.user_solicit[0].id_user ==
        this.store.getUserLoged().id)
        ? (
          <RaisedButton
            circleButton
            label={language.TICKET_STATUS.CLOSEDDETAIL}
            icon="fas fa-times"
            color="danger"
            onClick={() =>
              this.props.onChangeStatus_Tab(ticket.id_tickets, {
                action: 'closed'
              })
            }
            className="mr-2"
          />
        ) : null;

    let cancelButton =
      this.store.getUserLoged().role == 'solicitant' &&
        ticket.status != 'Resolved' &&
        (this.store.getUserLoged().role == 'tech' && ticket.status === 'Open') ? (
        <h4
          onClick={() =>
            this.props.onChangeStatus_Tab(ticket.id_tickets, {
              action: 'cancel'
            })
          }
          className="pointer"
          style={{
            width: '100%',
            background: 'var(--red)',
            color: '#fff',
            padding: 6,
            fontSize: 14,
            borderRadius: 2,
            textAlign: 'center'
          }}
        >
          {language.CANCEL}
        </h4>
      ) : null;

    let reopenButton =
      (
        ticket.status === 'Resolved' &&
        ticket.user_solicit[0].id_user ==
        this.store.getUserLoged().id) ? (
        <RaisedButton
          circleButton
          label={language.REOPEN}
          icon="fas fa-lock-open"
          color="primaryGradient"
          onClick={() => this.modal(true)}
          className="mr-2"
          style={{ fontSize: 16 }}
        />
      ) : null;

    tipostopticket.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={0}
        value={0}
        onClick={() => this.handleValuesstatus(0)}
      >
        {tipostopticketLabel[0]}
      </MenuItem>
    );

    tipostopticket.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={1}
        value={1}
        onClick={() => this.handleValuesstatus(1)}
      >
        {tipostopticketLabel[1]}
      </MenuItem>
    );

    tipostopticket.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={2}
        value={2}
        onClick={() => this.handleValuesstatus(2)}
      >
        {tipostopticketLabel[2]}
      </MenuItem>
    );

    tipostopticket.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={3}
        value={3}
        onClick={() => this.handleValuesstatus(3)}
      >
        {tipostopticketLabel[3]}
      </MenuItem>
    );

    tipostopticket.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={4}
        value={4}
        onClick={() => this.handleValuesstatus(4)}
      >
        {tipostopticketLabel[4]}
      </MenuItem>
    );

    let recorren = null;
    let now = new Date();

    saveTemplate = true;
    valor.time_resolution = this.modaNew();

    let forms = null;

    if (ticket.config != undefined) {
      if (ticket.config[0].forms != undefined) {

        let c = 0;
        forms = ticket.config[0].forms
          .filter(f => f.html != null && f.html != '')
          .map(obj => {
            c = c + 1;
            let id = 'ID' + c;

            return (
              <div className="contain">
                <div>
                  <h2 style={{ color: 'black' }}>
                    {c + ')'} {obj.nome}{' '}
                  </h2>
                </div>
                <div className="itemTicketDetail">
                  <p
                    style={{ color: '#888080' }}
                    id={id}
                    dangerouslySetInnerHTML={{ __html: applyTimezoneOnHTML(decryp(obj.html)) }}
                  />
                </div>
                <div style={{ width: '100px;' }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      value={this.state.time}
                      label={'Informe o tempo gasto'}
                      fullWidth={true}
                      ampm={false}
                      hintText="24hr Format"
                      onChange={value => this.onChangeField(value)}
                      required={true}
                      cancelLabel={language.CANCEL}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div>
                  {ticket.status === 'In Progress' &&
                    this.store.getUserLoged().role != 'solicitant' ? (
                    <RaisedButton
                      circleButton
                      color="primary"
                      id="send"
                      icon="fas fa-save"
                      style={{ fontSize: 15, paddingTop: 1, margin: '0 3px' }}
                      label={language.SAVE}
                      onClick={() =>
                        this.updateTemp(id, ticket, obj.id_template_ticket)
                      }
                    />
                  ) : null}
                </div>
                <div>
                  {obj.user_up != null ? (
                    <div>
                      <div>
                        <i>{language.LAST_UPDATE}:</i>
                      </div>
                      <div>
                        {' '}
                        <i>
                          {' '}
                          {language.USER}: {obj.user_up}
                        </i>
                      </div>
                      <div>
                        {' '}
                        <i>{moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')}</i>
                      </div>
                      <div>
                        {' '}
                        <i>{language.TIME_SPENT}</i>
                      </div>
                      <div>
                        {' '}
                        <i> {obj.tempo} </i>
                      </div>
                    </div>
                  ) : (
                    (saveTemplate = false)
                  )}
                </div>
                <hr />
              </div>
            );
          });
      } else {
      }
    } else {
    }

    let techGroupOptions = [];
    groups
      .filter(g => g.type == 1)
      .map(tec =>
        techGroupOptions.push({ value: tec.id_group_users, text: tec.name })
      );

    let fieldss = [
      {
        span: 2,
        name: 'id_tickets',
        label: 'ticket',
        type: 'text',
        disabled: true
      },
      {
        span: 8,
        name: 'fk_id_group_tech',
        label: language.GROUP_TECH,
        type: 'select',
        options: techGroupOptions,
        required: true
      }
    ];

    let techgroups = [];

    if (this.props.groupstech != undefined) {
      this.props.groupstech
        .filter(g => g.fk_id_group_users == ticket.fk_id_group_tech)
        .map(tec => techgroups.push({ value: tec.id_user, text: tec.name }));
    }

    let fieldtech = [
      {
        span: 2,
        name: 'id_tickets',
        label: 'ticket',
        type: 'text',
        disabled: true
      },
      {
        span: 8,
        name: 'fk_id_tech_new',
        label: language.TECH,
        type: 'select',
        options: techgroups,
        required: true
      }
    ];

    //select fk_id_group_users , tb_person.name ,tb_user.id_user

    if (ticket.recurrence > 1) {
      let v = { value: ticket.recurrence };
      let val = recurrentOptions[ticket.recurrence];
      let novo = language.RECURRENCE_STOP;
      recorren =
        this.store.getUserLoged().role != 'solicitant' ? (
          <RaisedButton
            buttonStyle={{ background: 'var(--yellow)' }}
            onClick={() => this.updateRecorrence(ticket)}
            className="btnTicketDetail"
            label={novo}
            style={{ marginRight: '1em' }}
          />
        ) : null;
    }
    let fields = [
      {
        span: 6,
        name: 'time_resolution',
        label: language.SERVICE_TIME,
        type: 'text',
        required: true,
        disabled: true
      }
    ];

    if (ticket.type_tickets === 'Problem') {
      fields.push({
        span: 6,
        name: 'fk_id_type_solution',
        label: language.TYPE_RESOLUTION,
        type: 'select',
        options: type_solution.map(s => ({
          value: s.id_table,
          text: s.description
        })),
        required: true
      });
    }

    let button = {
      md: 3,
      offset: { md: 9 },
      label: language.SAVE,
      primary: true,
      style: { margin: 5 }
    };

    let problem_identification = {
      problem_identification: ticket.problem_identification
    };
    let ploblem = (
      <Fragment>
        <Grid item xs={12} style={{ overflow: 'visible' }}>
          <Card
            className="cardDetail"
            style={{ overflow: 'visible' }}
          >
            <AppBar style={{ width: '95%' }}>
              <div className='d-flex align-items-center'>
                <div className='bg-icon'>
                  <i className='commentIcon' style={{ width: '100%', height: '100%' }} />
                </div>
                <span className='font ml-2'>{language.PROBLEM_IDENTIFICATION}</span>
              </div>
            </AppBar>

            <TicketNotesproblem
              language={language}
              values={ticket}
              item={this.props.ticket}
              field={'problem_identification'}
              ticket={ticket}
              onNotesTicket={onNotesTicket_1}
            />
            {ticket.problem_identification !== null ? (
              <div
                className="finishedStatusIconCheckIcon"
                style={{ width: 30, height: 30 }}
              />
            ) : null}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            className="cardDetail"
            style={{ overflow: 'visible' }}
          >
            <AppBar style={{ width: '95%' }}>
              <div className='d-flex align-items-center'>
                <div className='bg-icon'>
                  <i className='commentIcon' style={{ width: '100%', height: '100%' }} />
                </div>
                <span className='font ml-2'>{language.DESCRIBE_ANALYZE_PROBLEM}</span>
              </div>
            </AppBar>

            <TicketNotesproblem
              language={language}
              values={ticket}
              field={'analyze'}
              disabled={true}
              ticket={ticket}
              onNotesTicket={onNotesTicket_2}
            />
            {ticket.analyze !== null ? (
              <div
                className="finishedStatusIconCheckIcon"
                style={{ width: 30, height: 30 }}
              />
            ) : null}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            className="cardDetail"
            style={{ overflow: 'visible' }}
          >
            <AppBar style={{ width: '95%' }}>
              <div className='d-flex align-items-center'>
                <div className='bg-icon'>
                  <i className='commentIcon' style={{ width: '100%', height: '100%' }} />
                </div>
                <span className='font ml-2'>{language.SOLUTION_DEVELOPMENT}</span>
              </div>
            </AppBar>

            <TicketNotesproblem
              language={language}
              values={ticket}
              field={'solution_development'}
              disabled={true}
              ticket={ticket}
              onNotesTicket={onNotesTicket_3}
            />
            {ticket.solution_development !== null ? (
              <div
                className="finishedStatusIconCheckIcon"
                style={{ width: 30, height: 30 }}
              />
            ) : null}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            className="cardDetail"
            style={{ overflow: 'visible' }}
          >
            <AppBar style={{ width: '95%' }}>
              <div className='d-flex align-items-center'>
                <div className='bg-icon'>
                  <i className='commentIcon' style={{ width: '100%', height: '100%' }} />
                </div>
                <span className='font ml-2'>{language.TESTS}</span>
              </div>
            </AppBar>

            <TicketNotesproblem
              language={language}
              values={ticket}
              field={'test'}
              ticket={ticket}
              onNotesTicket={onNotesTicket_4}
            />
            {ticket.test !== null ? (
              <div
                className="finishedStatusIconCheckIcon"
                style={{ width: 30, height: 30 }}
              />
            ) : null}
          </Card>
        </Grid>
      </Fragment>
    );

    let backRoute = back === 'dash' ? '/dash/gsd' : '/dash/tickets';
    let actions = [
      this.store.getUserLoged().role != 'solicitant' ? (
        <RaisedButton
          label={'Cancel'}
          className="btnTicketDetail"
          primary
          onClick={this.openAssociateClose}
        ></RaisedButton>
      ) : null,
      this.store.getUserLoged().role != 'solicitant' ? (
        <RaisedButton
          label={'Submit'}
          circleButton
          icon="fas fa-times"
          color="primary"
          primary
          onClick={this.sendConnectTickets}
        ></RaisedButton>
      ) : null
    ];

    let dialogResolved = (
      <Dialog
        open={open}
        onClose={() => this.handleModal}
        title={
          ticket.type_tickets === 'Incident'
            ? language.RESOLUTION_DETAIL
            : language.ROOT_CAUSE_RESOLUTION
        }
      >
        <div style={{ width: '800px' }}></div>
        <Container fluid>
          <CommonForm
            language={language}
            button={button}
            onFormSubmit={_values => {
              ticket.type_tickets === 'Problem'
                ? this.props.onResolutionTab(ticket, _values)
                : this.props.onChangeStatus_Tab(
                  ticket.id_tickets,
                  {
                    time_resolution: _values.time_resolution,
                    action: 'resolved',
                    url: url
                  },
                  ticket
                );
              this.handleModal();
            }}
            values={valor}
            fields={fields}
          />
        </Container>
      </Dialog>
    );

    let buttons = (
      <div className="d-flex align-items-center">
        {cancelButton}
        {closedButton}
        {reopenButton}
        {this.store.getUserLoged().role != 'solicitant' ? (
          <RaisedButton
            circleButton
            color="default"
            icon="far fa-handshake"
            style={{ fontSize: 19, margin: '0 3px' }}
            label={language.ASSOCIATE_TICKETS}
            onClick={() => this.openAssociateOpen()}
          />
        ) : null}

        {ticket.type_tickets === 'Incident' &&
          (ticket.status === 'In Progress' ||
            ticket.status === 'Reopened') &&
          this.store.getUserLoged().role != 'solicitant' ? (
          <RaisedButton
            onClick={() =>
              this.props.onChangeStatus_Tab(
                ticket.id_tickets,
                { action: 'migrate' },
                ticket
              )
            }
            circleButton
            color="default"
            style={{ margin: '0 3px' }}
            icon="fas fa-hiking"
            label={language.MIGRATE_TO_PROBLEM}
          />
        ) : null}

        {ticket.status === 'Open' &&
          this.store.getUserLoged().role != 'solicitant' ? (
          //ticket.status === 'Open' || ticket.status === 'Reopened'
          <RaisedButton
            circleButton
            color="default"
            style={{ fontSize: 17, paddingLeft: 3 }}
            icon="fas fa-play"
            label={language.START_ATTENDANCE}
            onClick={() =>
              this.props.onChangeStatus_Tab(
                ticket.id_tickets,
                { action: 'resolve' },
                ticket
              )
            }
          />
        ) : ticket.status === 'In Progress' &&
          ticket.type_tickets === 'Problem' &&
          ticket.time_analyze != null &&
          ticket.time_solution_development != null &&
          ticket.time_problem_identification != null &&
          ticket.time_test ? (
          <RaisedButton
            circleButton
            color="default"
            icon="fas fa-stop"
            style={{ fontSize: 15, paddingTop: 1, margin: '0 3px' }}
            label={language.END_CALL}
            onClick={this.handleModal}
          />
        ) : ticket.status === 'In Progress' &&
          saveTemplate === true &&
          forms.length > 0 ? (
          <RaisedButton
            circleButton
            color="default"
            style={{ fontSize: 15, paddingTop: 1, margin: '0 3px' }}
            icon="fas fa-stop"
            label={language.END_CALL}
            onClick={this.handleModal}
          />
        ) : (
          ''
        )}

        {(ticket.status === 'In Progress' &&
          this.store.getUserLoged().role != 'solicitant') ||
          (ticket.status === 'Reopened' &&
            this.store.getUserLoged().role != 'solicitant')
          ? (
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.CATEGORIZE}</strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div
                style={{ width: 35, height: 35 }}
                className="categorizeIcon pointer"
                onClick={() => handlePage('categorizeTab', ticket)}
              />
            </Tooltip>
          ) : null}
        <RaisedButton
          circleButton
          color="default"
          style={{ margin: '0 3px' }}
          icon="fas fa-times"
          label={language.CLOSE}
          onClick={() => closeTab(this.props.ticket)}
        />
      </div>
    );
    //   this.state.ticketsConnect = this.store.tickets;
    let col = [
      { key: 'ID', label: 'ID', style: { width: 5, textAlign: 'center' } },
      {
        key: 'device',
        label: language.DEVICE,
        style: { width: 80, textAlign: 'left' }
      },
      {
        key: 'subject',
        label: language.SUBJECT,
        style: { width: 120, textAlign: 'center' }
      },

      { key: 'action', label: '', style: { width: 5, textAlign: 'center' } }
    ];

    let data = [];
    let sub = null;
    let des = null;
    let name = null;
    if (ticket.config != undefined) {
      if (ticket.config[0].historico != undefined) {
        data = ticket.config[0].historico.map(obj => {
          if (obj.name.toString().length > 20) {
            name = obj.name.toString().substr(0, 20) + '. . .';
          } else {
            name = obj.name;
          }

          if (obj.subject.toString().length > 60) {
            sub = obj.subject.toString().substr(0, 60) + '. . .';
          } else {
            sub = obj.subject;
          }

          if (obj.description.toString().length > 60) {
            des = obj.description.toString().substr(0, 60) + '. . .';
          } else {
            des = obj.description;
          }

          return {
            ID:
              obj.fk_id_request == null
                ? 'T' + obj.id_tickets
                : 'R' + obj.fk_id_request,
            device: name,
            subject: sub,

            action: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_DETAIL}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <div
                  onClick={() =>
                    redirect('/dash/tickets/detail', {
                      ticket: obj,
                      back: back
                    })
                  }
                  className="detailIcon pointer"
                  style={{ width: 25, height: 25 }}
                />
              </Tooltip>
            )
          };
        });
      }
    }

    try {
      if (backRoute.length < 9) {
        backRoute = backRoute + '/tickets/';
      }
    } catch (e) { }

    let ticketsConnect = this.state.ticketsConnect.map(e => ({
      value: e.id_tickets,
      text:
        '#' +
        e.realid +
        '  ' +
        language.SOLICITANT +
        ': ' +
        e.namesol +
        ' / ' +
        language.SUBJECT +
        ': ' +
        e.subject
    }));
    let formAssociateTicket = [
      {
        span: 12,
        name: 'association',
        title: language.ASSOCIATING_TICKET_CLOSE,
        type: 'listCheckbox',
        options: ticketsConnect
      }
    ];
    let st = null;
    let stt = null;

    let br = sessionStorage.getItem('sensr_lang') == 'PT_BR';
    if (
      sessionStorage.getItem('sensr_lang') == 'PT_BR' ||
      sessionStorage.getItem('sensr_lang') == null
    ) {
      if (ticket.type_tickets == 'Incident') {
        st = 'Incidente';
      } else if (ticket.type_tickets == 'Problem') {
        st = 'Problema';
        /*} else if (ticket.type_tickets == 'Liberation') {
          st = 'Liberação';*/
      } else if (ticket.type_tickets == 'Solicitation') {
        st = 'Solicitação';
      }

      if (ticket.status == 'In Progress') {
        stt = 'Em Atendimento';
      } else if (ticket.status == 'Resolved') {
        stt = 'Resolvido';
      } else if (ticket.status == 'Reopened') {
        stt = 'Reaberto';
      } else if (ticket.status == 'Approve') {
        stt = 'Em aprovação';
      } else if (ticket.status == 'Approved') {
        stt = 'Aprovado';
      } else if (ticket.status == 'Open') {
        stt = 'Aberto';
      } else if (ticket.status == 'Closed') {
        stt = 'Encerrado';
      } else if (ticket.status == 'Cancelled') {
        stt = 'Cancelado';
      }
    } else {
      st = ticket.type_tickets;
      stt = ticket.status;
    }

    return (
      this.store.assessment.length > 0 && this.state.openassessment ? (
        this.state.connection != "rdp" ?
          <AssessmentTerminal

            language={language}
            store={this.store}
            ticketsSelected={this.state.ticketsSelected}
            finishAssessment={this.finishAssessment}
            connection={this.state.connection}
          />
        :
          <GuacamoleView language={language}
            user={null}
            password={null}
            domain={null}
            path={this.store.assessment[0].path}
            finishAssessment={this.finishAssessment}
            device={this.state.device}></GuacamoleView>
      ) :
        <div id="TicketDetailTab">
          {recorren}
          {dialogResolved}
          {requestTicket ? (
            <div>
              <Card className="cardTitle cardDefault">
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 0
                  }}
                >
                  <Col md={10}>
                    <h2 className="font color-lead" style={{ fontWeight: 100 }}>
                      {'R' +
                        requestTicket.id_request +
                        ' - ' +
                        requestTicket.subject}
                    </h2>
                  </Col>
                  <Col md={2} className="cardDetailBtn">
                    <RaisedButton
                      buttonStyle={{ background: 'var(--green)' }}
                      onClick={() =>
                        handlePage('form', {
                          subject: requestTicket.subject,
                          description: requestTicket.description,
                          fk_id_user: requestTicket.id_user,
                          fk_id_request: requestTicket.id_request,
                          fk_id_company: requestTicket.fk_id_company,
                          fk_id_category: requestTicket.fk_id_category,
                          fk_id_catalog_service: requestTicket.fk_id_catalog_service,
                          fk_id_catalog_task: requestTicket.fk_id_catalog_task,
                          fk_id_device: requestTicket.fk_id_device,
                          fk_id_contract: requestTicket.fk_id_contract,
                          fk_id_sla: requestTicket.fk_id_sla,
                          ticketArray: [],
                          req: []
                        })
                      }
                      className="btnTicketDetail"
                      label={language.CATEGORIZE}
                      primary
                      style={{ float: 'right' }}
                    />
                  </Col>
                </Row>
              </Card>
              <Card className="cardTitle cardDefault">
                <div className="itemInfo">
                  <div className="attachmentIcon cardIcon" />
                  <h4>{'Anexos'}</h4>
                </div>
                <Row>
                  <Col md={10}>
                    {requestTicket.filesnew != undefined
                      ? requestTicket.filesnew.map(f => (
                        <Row>
                          <Col md={1}>
                            {' '}
                            <div
                              className="attachmentIcon"
                              style={{ marginTop: 12 }}
                            ></div>
                          </Col>
                          <Col md={7}>
                            <p
                              className="pointer"
                              onClick={() =>
                                this.props.ondonwFile(
                                  f.split('|')[2],
                                  f.split('|')[0],
                                  'request'
                                )
                              }
                            >
                              {f.split('|')[0]}
                            </p>
                          </Col>
                        </Row>
                      ))
                      : null}{' '}
                  </Col>
                </Row>
              </Card>
              <Grid container spacing={16} style={{ marginTop: 25 }}>
                <Grid item xs={6}>
                  <Card style={{ height: '100%', overflow: 'visible' }}>
                    <AppBar light style={{ fontFamily: 'GeosansLight' }}>
                      <div className="AppBarCustomContent">
                        <div className="bg-icon">
                          <div
                            className="detailIcon"
                            style={{ width: '100%', height: '100%' }}
                          />
                        </div>
                        <span style={{ marginLeft: 8, fontSize: 18 }}>
                          {language.DETAILS}
                        </span>
                      </div>
                    </AppBar>
                    <div className="content" style={{ paddingTop: 35 }}>
                      <ListColumn
                        col={6}
                        items={[
                          {
                            label: language.STATUS,
                            value: requestTicket.status
                          },
                          {
                            label: language.DATE_CAD,
                            value: moment(requestTicket.dt_cad).format('L')
                          },
                          {
                            col: 12,
                            tagValue: false,
                            label: language.SUBJECT,
                            value: (
                              <p
                                style={{ color: '#888080', display: 'inline' }}
                                dangerouslySetInnerHTML={{
                                  __html: requestTicket.subject
                                }}
                              />
                            )
                          },
                          {
                            label: language.REGISTER_HOUR,
                            value: moment(requestTicket.dt_cad).format('LTS')
                          },
                          {
                            label: language.SOLICITANT,
                            value:
                              requestTicket.namerequest == null
                                ? requestTicket.name
                                : requestTicket.namerequest
                          },
                          {
                            label: language.EMAIL,
                            value:
                              requestTicket.emailrequest == null
                                ? requestTicket.username
                                : requestTicket.emailrequest
                          },
                          {
                            label: language.COMPANY,
                            value: requestTicket.name_company
                          },
                          {
                            label: language.ID + ' ' + language.ACCESS_REMOTE,
                            value: requestTicket.id_access_remote
                          },

                          {
                            label: language.DESCRIPTION,
                            col: 12,
                            tagValue: false,
                            value: (
                              <p
                                style={{ color: '#888080' }}
                                dangerouslySetInnerHTML={{
                                  __html: requestTicket.description
                                }}
                              />
                            )
                          }
                        ]}
                      />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div />
          )}

          {ticket ? (
            <div>
              <Card className="cardDetail">
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 0
                  }}
                >
                  <Col md={6}>
                    <h2 className="font color-lead" style={{ fontWeight: 100 }}>
                      {(ticket.id_request === null || ticket.id_request === 0
                        ? 'T' + ticket.id_tickets
                        : 'R' + ticket.id_request) +
                        ' - ' +
                        ticket.subject}
                    </h2>
                  </Col>
                  <Col md={6} className="cardDetailBtn">
                    <div style={{ float: 'right' }}>{buttons}</div>
                  </Col>
                </Row>
              </Card>
              <Grid
                container
                spacing={16}
                style={{ marginTop: 20 }}
                className="DC-card"
              >
                <Grid item xs={6}>
                  <Card style={{ height: '100%', overflow: 'visible' }}>
                    <AppBar style={{ width: '95%' }}>
                      <div className='d-flex align-items-center'>
                        <div className='bg-icon'>
                          <i className='detailIcon' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <span className='font ml-2'>{language.DETAILS}</span>
                      </div>
                    </AppBar>

                    <div className="content">
                      <ListColumn
                        col={6}
                        items={[
                          {
                            label: language.TYPE,
                            value: st
                          },
                          {
                            label: language.DATE_CAD,
                            value: moment(

                              ticket.config[0].dt_cad
                              || ticket.dt_cad
                            ).format('DD/MM/YYYY HH:MM:SS')
                          },
                          {
                            label: language.STATUS,
                            value: stt
                          },
                          {
                            label: language.PRIORITY,
                            value: ticket.priority
                          },
                          {
                            label: language.ORGANIZATION,
                            value: ticket.company[0].name_company_master
                          },
                          {
                            label: language.CATEGORY,
                            value: ticket.name_cat
                          },
                          {
                            label: language.DEPARTMENT,
                            value: ticket.name_depart
                          },
                          {
                            label: language.SERVICE_CATALOG,
                            value: ticket.name_service
                          },

                          {
                            label: language.SERVICE_TIME,
                            value:
                              ticket.config[0].sla_task !== null
                                ? ticket.config[0].sla_task + ':00 Hs.'
                                : ''
                          },
                          {
                            label: language.TASK,
                            value: ticket.config[0].name_catalog_task
                          },
                          {
                            label: language.DEVICE,
                            contentProps: {
                              className: 'd-flex align-items-center'
                            },
                            labelProps: {
                              style: {
                                marginRight: 6
                              }
                            },
                            valueProps: {
                              className: 'd-flex align-items-center'
                            },
                            value: (
                              <Fragment>
                                {ticket.device_name}
                                {ticket.status === 'In Progress' &&
                                  this.store.getUserLoged().role != 'solicitant' ? (
                                  <RaisedButton
                                    circleButton
                                    color="primaryGradient"
                                    label={language.START_ASSESSMENT}
                                    onClick={this.startAssessment}
                                    icon="fas fa-play"
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: 13,
                                      paddingLeft: 3,
                                      width: 25,
                                      height: 25
                                    }}
                                  />
                                ) : null}
                              </Fragment>
                            )
                          },
                          {
                            label: language.SLA_PROGRESS_GRID,
                            value: TicketUtils.calcSla(
                              ticket,
                              'ticket',
                              language,
                              req,
                              ticket.sla_task,
                              null
                            ).time
                          },
                          {
                            visible: ticket.status == 'In Progress',
                            label: language.PROGRESS,
                            value:
                              ticket.config[0].progress === null
                                ? '0'
                                : ticket.config[0].progress + '%'
                          },
                          {
                            label: language.SOLICITANT,
                            value: ticket.user_solicit[0].name_person
                          },

                          {
                            label: language._CONTRACT,
                            value: ticket.config[0].contractname
                          },
                          {
                            label: language.ID + ' ' + language.ACCESS_REMOTE,
                            value: ticket.config[0].id_acesso_remoto
                          },
                          {
                            label: language.PHONE,
                            value: ticket.user_solicit[0].phone
                          },

                          ticket.status != 'Resolved'
                          && ticket.status != 'Closed' && {
                            label: language.WAITING,
                            contentProps: {
                              className: 'd-flex align-items-center'
                            },
                            labelProps: {
                              style: {
                                marginRight: 6
                              }
                            },
                            valueProps: {
                              className: 'd-flex align-items-center'
                            },
                            value: (
                              <Fragment>
                                {
                                  tipostopticketLabel[
                                  ticket.config[0].stop_ticket || 0
                                  ]
                                }
                                {(this.store.getUserLoged().role !=
                                  'solicitant' &&
                                  ticket.status != 'Resolved' && ticket.status != 'Closed')
                                  ? (
                                    <RaisedButton
                                      circleButton
                                      color="primaryGradient"
                                      label={language.STATE}
                                      icon="far fa-edit"
                                      style={{
                                        marginLeft: '10px',
                                        fontSize: 14,
                                        width: 25,
                                        height: 25
                                      }}
                                      onClick={evt => this.handleMenuStatus(evt)}
                                    />
                                  ) : null}
                                <Menu
                                  anchorEl={this.state.menu.menuStatus}
                                  open={this.state.menu.menuStatus != null}
                                  onClose={() => this.handleMenuStatus(null)}
                                >
                                  {tipostopticket}
                                </Menu>
                              </Fragment>
                            )
                          },
                          {
                            label: 'Ramal',
                            value: ticket.user_solicit[0].ramal
                          },
                          {
                            col: 12,
                            label: language.TECH,
                            contentProps: {
                              className: 'd-flex align-items-center'
                            },
                            labelProps: {
                              style: {
                                marginRight: 6
                              }
                            },
                            valueProps: {
                              className: 'd-flex align-items-center'
                            },
                            value: (
                              <Fragment>
                                {ticket.status != ''
                                  ? ticket.tech_start
                                  : null}
                                {ticket.status != 'Resolved' && ticket.status != 'Closed' &&

                                  this.store.getUserLoged().role != 'solicitant' ? (
                                  <RaisedButton
                                    circleButton
                                    color="primaryGradient"
                                    label={language.CHANGE_TECH_GROUP}
                                    onClick={() => this.modalOpen('tech')}
                                    icon="far fa-edit"
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: 14,
                                      width: 25,
                                      height: 25
                                    }}
                                  />
                                ) : null}
                              </Fragment>
                            )
                          },

                          {
                            col: 12,
                            label: language.GROUP_TECH,
                            contentProps: {
                              className: 'd-flex align-items-center'
                            },
                            labelProps: {
                              style: {
                                marginRight: 6
                              }
                            },
                            valueProps: {
                              className: 'd-flex align-items-center'
                            },
                            value: (
                              <Fragment>
                                {ticket.config[0].grouptech}
                                {ticket.status != 'Resolved' && ticket.status != 'Closed' &&
                                  this.store.getUserLoged().role != 'solicitant' ? (
                                  <RaisedButton
                                    circleButton
                                    color="primaryGradient"
                                    label={'Alterar Grupo Tecnico'}
                                    onClick={() => this.modalOpen()}
                                    icon="far fa-edit"
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: 14,
                                      width: 25,
                                      height: 25
                                    }}
                                  />
                                ) : null}
                              </Fragment>
                            )
                          },


                          {
                            col: 12,
                            label: language.DESCRIPTION,
                            value: (
                              <p
                                style={{ color: '#888080' }}
                                dangerouslySetInnerHTML={{
                                  __html: ticket.description
                                }}
                              />
                            )

                          }
                        ]}
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card style={{ height: '100%', overflow: 'visible' }}>
                    <AppBar style={{ width: '95%' }}>
                      <div className='d-flex align-items-center'>
                        <div className='bg-icon'>
                          <i className='commentIcon' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <span className='font ml-2'>{language.CHAT}</span>
                      </div>
                    </AppBar>

                    <TicketComment
                      language={language}
                      ticket={ticket}
                      onCommentTicket={(id_ticket, values) => onCommentTicket(id_ticket, values, 'solicitant')}
                      statusTicket={ticket.status}
                      maxHeightChat={383}
                      type_ticket={[null, 'solicitant']}
                    />

                  </Card>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={16}
                style={{ marginTop: 20 }}


              >

                {ticket.type_tickets !== 'Problem' &&
                  this.store.getUserLoged().role != 'solicitant' &&
                  <Grid item xs={6}>
                    <Card

                      style={{ overflow: 'visible', height: '100%' }}
                    >

                      <AppBar style={{ width: '95%' }}>
                        <div className='d-flex align-items-center'>
                          <div className='bg-icon'>
                            <i className='commentIcon' style={{ width: '100%', height: '100%' }} />
                          </div>
                          <span className='font ml-2'>{language.REQUEST_RESOLUTION}</span>
                          <RaisedButton
                            circleButton
                            color='warning'
                            className='ml-2'
                            style={{
                              padding: 4,
                              zoom: '77%'
                            }}
                            icon={
                              <img src={knowledgeBaseIcon2} style={{ width: '100%' }} />
                            }
                            onClick={() => this.modalBase(true)}
                            label={language.KNOWLEDGE_BASE}
                          />
                        </div>
                      </AppBar>

                      <div id="REQUEST_RESOLUTION_form">
                        {forms.length === 0 ? (
                          <div className='TicketNotes-request-resolution'>
                            <TicketNotes
                              language={language}
                              ticket={ticket}
                              onNotesTicket={onNotesTicketTab}
                              statusTicket={ticket.status}
                              url={url}
                              type_tickets={ticket.type_tickets}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Card>
                  </Grid>
                }
                {(this.store.getUserLoged().role == 'tech' || this.store.getUserLoged().role == 'client') ?
                  <Grid item xs={6}>
                    <Card style={{ overflow: 'visible', height: '100%' }}>
                      <AppBar style={{ width: '95%' }}>
                        <div className='d-flex align-items-center'>
                          <div className='bg-icon'>
                            <i className='detailIcon' style={{ width: '100%', height: '100%' }} />
                          </div>
                          <span className='font ml-2'>Chat Técnico</span>

                        </div>
                      </AppBar>

                      <TicketComment
                        language={language}
                        ticket={ticket}
                        loading={this.props.loading}
                        onCommentTicket={(id_ticket, values) => onCommentTicket(id_ticket, values, 'tech')}
                        statusTicket={ticket.status}
                        maxHeightChat={200}
                        type_ticket={['tech']}
                      />
                    </Card>
                  </Grid>
                  : null}
                <Grid
                  item

                  xs={this.store.getUserLoged().role == 'tech' && ticket.type_tickets !== 'Problem' ? 6 : 12}
                  style={{
                    marginTop: 14
                  }}
                >
                  <Card

                    style={{ overflow: 'visible', ...(this.store.getUserLoged().role == 'tech' && ticket.type_tickets !== 'Problem' ? { height: '100%' } : {}) }}
                  >


                    <AppBar style={{
                      width: (this.store.getUserLoged().role == 'tech' && ticket.type_tickets !== 'Problem' ? '95%' : '97%')
                    }}>
                      <div className='d-flex align-items-center'>

                        <Dropzone
                          className="bg-icon"
                          accept=".zip, .7Z, .json, .VBS, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar, .msg, audio/*, image/*"
                          required
                          onDrop={onDrop.bind(this)}
                          style={{

                            width: 24,
                            height: 24,
                            backgroundColor: 'transparent'
                          }}
                        >
                          <RaisedButton
                            circleButton
                            label={language.ATTACH_FILE}
                            color='warning'
                            style={{
                              width: 24,
                              height: 24,
                              fontSize: 15,
                              color: 'white'
                            }}
                            icon='far fa-file-alt'
                          />
                        </Dropzone>

                        <span className='font ml-2'>{language.ATTACH_FILE}</span>


                        {this.props.files.length > 0 && (
                          <RaisedButton
                            circleButton
                            className='ml-2'
                            icon="fas fa-paper-plane"
                            label={language.SEND}
                            color="success"
                            onClick={() => this.props.UpdateFiles(ticket)}
                            style={{ fontSize: 13, paddingRight: 2, width: 24, height: 24 }}
                          />
                        )}
                      </div>
                    </AppBar>

                    <div className='content'>

                      <div style={{ paddingLeft: 10 }}>
                        <aside className="pt-2">
                          <ul>
                            {this.props.files.map(f => (
                              <div style={{ margin: '5px 0' }}>
                                <AttachFile
                                  file={f}
                                  onDelete={() => this.props.removeFile(f)}
                                />
                              </div>
                            ))}
                          </ul>
                        </aside>

                        {ticket.config[0].files != undefined
                          ? ticket.config[0].files.map(f => (
                            <div className="titleContractList">
                              <p
                                className="pointer"
                                onClick={() =>
                                  this.props.ondonwFile(f.id_tickets, f.file)
                                }
                              >
                                {f.file +
                                  ' - ' +
                                  moment(f.dt_cad).format("DD/MM/YY HH:mm") + ' ' +

                                  f.user_cad
                                }
                              </p>
                              <div
                                className="trashIcon pointer"
                                onClick={this.store.getUserLoged().role != 'solicitant' ? () => this.props.ondeletefile(f) :
                                  null}
                                style={{ width: 25, height: 25 }}
                              >
                                {' '}
                              </div>
                            </div>
                          ))
                          : null}

                        {ticket.config != undefined
                          ? ticket.config[0].filesrequest.map(f => (
                            <div className="titleContractList">
                              <p
                                className="pointer"
                                onClick={() =>
                                  this.props.ondonwFile(
                                    f.id_request,
                                    f.file,
                                    'request'
                                  )
                                }
                              >
                                {f.file} - Anexo inserido na solicitaçao
                              </p>
                              {/*<div className='trashIcon pointer' onClick={() => this.props.ondeletefile(f)} style={{ width: 25, height: 25 }}> </div> */}
                            </div>
                          ))
                          : null}
                      </div>

                    </div>
                  </Card>


                </Grid>

                {ticket.type_tickets !== 'Problem' &&
                  this.store.getUserLoged().role != 'solicitant' ? (
                  forms.length > 0 ? (

                    <Grid item xs={12}>
                      <Card className="cardDetail">
                        <div className="template"> {forms}</div>
                      </Card>
                    </Grid>
                  ) : null
                ) : null}

                {ticket.type_tickets === 'Problem' && ploblem}
                <Grid item xs={12}>
                  <Card
                    className="cardDetail heightCardDetail"
                    style={{ overflow: 'visible', padding: 0 }}
                  >
                    <AppBar>
                      <div className='d-flex align-items-center'>
                        <div className='bg-icon'>
                          <i className='detailIcon' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <span className='font ml-2'>{language.HISTORIC}</span>

                      </div>
                    </AppBar>

                    <Row>
                      <Col md={12}>
                        <div style={{ overflowY: 'auto', maxHeight: 400 }}>
                          <p
                            className="fontAll first-historic"
                            style={{
                              color: '#888080',
                              fontSize: '14px',
                              padding: '0 20px'
                            }}
                            dangerouslySetInnerHTML={{ __html: ticket.notes }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div />
          )}

          <Dialog
            title={language.ASSOCIATION_TICKETS}
            PaperProps={{ classes: { root: classes.dialogPaperAlert } }}
            open={this.state.openAssociate}
            maxWidth="md"
            onClose={() => this.openAssociateClose()}
          >
            <CommonForm
              values={[]}
              language={language}
              fields={formAssociateTicket}
              onFormSubmit={this.sendConnectTickets}
              button={button}
            />
          </Dialog>

          {this.state.openReopen == true ? (
            <Dialog
              open={true}
              modal={true}
              onRequestClose={() => handlePage('list', '')}
            >
              <PageLayout
                icon={
                  <div
                    className="approvalIcon"
                    style={{ height: 40, width: 40 }}
                  />
                }
                title={'Ticket'}
                subtitle={'Reabrir Ticket'}
                rightElements={[]}
              >
                <div style={{ maxHeight: 400, overflow: 'auto', marginTop: 10 }}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <TextField
                          id="justify"
                          ref="justify"
                          name="justify"
                          floatingLabelText={language.JUSTIFICATION}
                          multiLine={true}
                          fullWidth
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <RaisedButton
                          label={'Reopen'}
                          primary
                          fullWidth
                          onClick={() => this.onSubmit()}
                        />
                      </Col>
                    </Row>
                  </Container>
                )}
                </div>
              </PageLayout>
            </Dialog>
          ) : null}

          {this.props.modalEdit}

          {this.state.modalBase ? (
            <Dialog
              open={true}
              fullScreen
              PaperProps={{ classes: { root: classes.dialogPaperAlert } }}
              autoScrollBodyContent={true}
              maxWidth="md"
              onClose={() => this.modalBase(false)}
            >
              {' '}
              <div style={{ width: '1200px' }}></div>
              <SolutionContainerTicket
                language={language}
                categories={this.props.categories}
                solutions={this.props.solutions}
                type_solution={this.props.type_solution}
              ></SolutionContainerTicket>
            </Dialog>
          ) : null}

          {md == false ? (
            <Dialog
              maxWidth="600px"
              title="Alterar grupo Técnico"
              modal={false}
              monitor_scroll
              open={modal}
              // onClose={()=>this.openAssociateClose}
              onClose={() => {
                this.modalClose();
                this.openAssociateClose();
              }}
            >
              <div>
                <CommonForm
                  values={values}
                  language={language}
                  fields={fieldss}
                  onFormSubmit={this.updateTicketTech}
                  button={button}
                />
              </div>
            </Dialog>
          ) : null}
          {md == false ? (
            <Dialog
              maxWidth="600px"
              title="Alterar Técnico"
              modal={false}
              open={this.state.modaltech}
              // onClose={()=>this.openAssociateClose}
              onClose={() => {
                this.modalClose();
                this.openAssociateClose();
              }}
            >
              <div>
                <CommonForm
                  values={values}
                  language={language}
                  fields={fieldtech}
                  onFormSubmit={this.updateTicketTech}
                  button={button}
                />
              </div>
            </Dialog>
          ) : null}


          {ticket && this.store.getUserLoged().role != 'solicitant' ? (
            <CommonTable
              title={language.HISTORIC_CALL}
              language={language}
              columns={col}
              data={data}
            />
          ) : null}
        </div>
    );
  }
}
TicketDetailTab.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styless)(TicketDetailTab);
