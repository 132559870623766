import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MenuItem, IconButton, Switch } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';

import { Radial } from 'sensrit-charts';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactTooltip from 'react-tooltip';
import MdFilterList from 'react-icons/lib/md/filter-list';
import moment from 'moment';
import vendors from '../../util/vendors';
import crypt from '../../util/crypt';
import { percent, mem, uptime } from '../../util/MonitorUtils';
import CommonPage from '../../common/CommonPageNotLoad';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

let host = '';
let tokenApi = '';
var getConnection = '';

export default class Desktop extends CommonPage {
  constructor() {
    super();
    this.state = {
      available: false,
      mount: true,
      hold: '',
      aliveHold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disk: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      removeloading: true,
      dateUnavailableServer: '',
    };
    this.handleDisk = this.handleDisk.bind(this);
    this.handleNet = this.handleNet.bind(this);
    this.aliveSignal = this.aliveSignal.bind(this);
    this.fetchAll = this.fetchAll.bind(this);
  }

  componentWillMount() {
    this.store.loading = false;
    host = Constants.APIRoot;
    tokenApi = this.store.getToken();
    this.getConnectionDevice(host, tokenApi);
  }

  componentWillUnmount() {
    this.state.mount = false;
    clearTimeout(this.state.hold);
    clearTimeout(this.state.aliveHold);
  }

  handleDisk(event, index) {
    this.setState({ diskIndex: index });
  }
  handleNet(event, index) {
    this.setState({ netIndex: index });
  }

  render() {
    let {
      handlePage,
      language,
      _device,
      down,
      handleModal,
      onRestartAgent,
      onDettachDevice,
      connection,
    } = this.props;
    let {
      values,
      available,
      diskIndex,
      netIndex,
      memory,
      memoryUsed,
      memoryUsedPercent,
      cors,
      uptime,
      cpuPerc,
      procStats,
      disk,
      netStats,
      netStatsRx,
      netStatsTx,
      removeloading,
      dateUnavailableServer,
    } = this.state;

    if (!_device.values) {
      return (
        <Row className="row flipContainer down">
          <div className="offline">
            <Row className="row infos">
              <Col md={6.5} xs={6}>
                {_device.name.toUpperCase()}
              </Col>
              <Col md={5} xs={6}>
                {_device.ip}
              </Col>
              <Col
                md={0.5}
                xs={1}
                className="text-right"
                style={{ padding: 0 }}
              >
                <IconMenu
                  iconButtonElement={
                    <IconButton
                      className="configurationsIcon"
                      style={{ height: 30, width: 30, margin: '-4px 5px 0 0' }}
                    />
                  }
                >
                  <MenuItem
                    primaryText={language.EDIT_CONFIGURATION}
                    leftIcon={
                      <div
                        className="editIcon"
                        style={{ width: 30, height: 30, margin: '5px 12px' }}
                      />
                    }
                    onClick={() => handlePage('deviceConf', _device)}
                  />
                </IconMenu>
              </Col>
            </Row>
            <Row className="row graphs">
              <Col
                md={4}
                xs={12}
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  className="incidentManagementIcon"
                  style={{ width: 80, height: 80 }}
                />
              </Col>
              <Col md={8} xs={12} style={{ padding: 0 }}>
                <h2>{language.UNAVAILABLE}</h2>
              </Col>
            </Row>
          </div>
        </Row>
      );
      this.store.loading = false;
    }

    let sysInfo = values['sys-info']
      ? values['sys-info']
      : _device.values['sys-info']
      ? _device.values['sys-info']
      : {};
    let servicesStats = values['service-list']
      ? Object.keys(values['service-list']).map((service) => (
          <Switch
            toggled={!/inactive/i.test(values['service-list'][service])}
            trackSwitchedStyle={{ backgroundColor: '#B5FFB9' }}
            thumbSwitchedStyle={{ backgroundColor: '#0DFFB8' }}
            trackStyle={{ backgroundColor: '#FF9B7D' }}
            thumbStyle={{ backgroundColor: '#F44336' }}
            label={service}
            labelPosition="left"
            key={service}
          />
        ))
      : '';
    let conf = values.conf ? values.conf : _device.conf;
    let diskAlert = false;
    disk = disk.sort(
      (a, b) =>
        percent(b.values.used, b.values.total) -
        percent(a.values.used, a.values.total)
    );
    let netOptions = netStats.map((n, i) => (
      <MenuItem value={i} key={i} primaryText={n.name} />
    ));

    let diskOptions = disk.map((d, i) => {
      let percent = percent(d.values.used, d.values.total);
      let icon = <span className="text-green">{percent}%</span>;
      if (percent >= conf['fs-list'].triggers.warning.value) {
        icon = <span className="text-yellow">{percent}%</span>;
        diskAlert = diskAlert !== 'danger' ? 'warning' : diskAlert;
      }
      if (percent >= conf['fs-list'].triggers.danger.value) {
        icon = <span className="text-red">{percent}%</span>;
        diskAlert = 'danger';
      }
      return (
        <MenuItem value={i} key={i} primaryText={d.name} rightIcon={icon} />
      );
    });

    let diskPercent = disk[diskIndex]
      ? percent(disk[diskIndex].values.used, disk[diskIndex].values.total)
      : 1;
    let dateUnavailable = values['agent-info']
      ? values['agent-info']
      : _device.values['agent-info'];

    let token = crypt(_device.id_device.toString());
    if (connection) {
      if (connection === 'ssh') {
        token =
          '{"token": "' + token + '", "url": "' + window.location.origin + '"}';
      } else if (connection === 'rdp') {
        token = (
          <div>
            <div>Token: {token}</div>
            <div>URL: {window.location.origin}</div>
          </div>
        );
      }
    }

    if (!removeloading) {
      this.store.loading = true;
    } else {
      this.store.loading = false;
    }

    return (
      <Row className={'row flipContainer'} style={{ minHeight: '170px' }}>
        <div className="online" style={{ minHeight: '170px' }}>
          <ReactTooltip id={'tooltip_' + _device.id_device} />
          <Row className="row infos" style={{ fontSize: '13px' }}>
            <Col
              md={6.5}
              xs={6}
              onClick={down ? () => {} : () => handlePage('summary', props)}
            >
              {(sysInfo.hostName || _device.name).toUpperCase()}
            </Col>
            <Col md={5} xs={5}>
              {uptime ? 'Uptime: ' + uptime : ''}
            </Col>
            <Col
              md={0.5}
              xs={1}
              className="text-right"
              style={{ padding: 0, marginRight: '13px', marginTop: '2px' }}
            >
              <IconMenu
                iconButtonElement={
                  <IconButton
                    className="configurationsIcon"
                    style={{ height: 35, width: 35, margin: '-4px 5px 0 0' }}
                  />
                }
              >
                <MenuItem
                  primaryText={language.DEVICE_INFO}
                  leftIcon={
                    <div
                      className="infoIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() => handlePage('summary', this.props)}
                />
                <MenuItem
                  primaryText={language.SERVICE_INFORMATION}
                  leftIcon={
                    <div
                      className="infoIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() => handlePage('services', this.props)}
                />
                <MenuItem
                  primaryText="Sensr Conf"
                  leftIcon={
                    <div
                      className="configurationsIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() => handleModal({ msg: token })}
                />
                <MenuItem
                  primaryText={language.UPDATE_AGENT}
                  leftIcon={
                    <div
                      className="changeManagementIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() =>
                    handleModal({
                      msg: language.CONFIRM_UPDATE_AGENT,
                      confirm: () => {
                        onRestartAgent(_device.id_device);
                        handleModal();
                      },
                    })
                  }
                />
                <MenuItem
                  primaryText={language.EDIT_CONFIGURATION}
                  leftIcon={
                    <div
                      className="editIcon cardIcon"
                      style={{ margin: '8px 12px' }}
                    />
                  }
                  onClick={() => handlePage('deviceConf', this.props._device)}
                />
                <MenuItem
                  primaryText={language.LEAVE_MONITOR}
                  leftIcon={
                    <div
                      className="removeIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() =>
                    handleModal({
                      msg: language.CONFIRM_STOP_MONITOR,
                      confirm: () => {
                        onDettachDevice(_device.id_device);
                        handleModal();
                      },
                    })
                  }
                />
              </IconMenu>
            </Col>
          </Row>
          <Row
            className="row graphs"
            style={{ boxShadow: '0px 3px 7px 0px #00000021' }}
          >
            <Col md={4} xs={12} style={{ padding: 0 }}>
              <Row>
                <div style={{ marginLeft: '-35px' }}>
                  {_device.ip}
                  {_device.port ? ':' + _device.port : ''}
                </div>
                <Col
                  className="server-icon"
                  style={{ margin: 0, padding: 0 }}
                  md={6}
                  xs={6}
                  data-tip={sysInfo.description}
                  data-for={'tooltip_' + _device.id_device}
                >
                  {vendors.getIcon(sysInfo.vendor)}
                </Col>
                {servicesStats ? (
                  <Col
                    style={{ margin: 0, padding: 0, textAlign: 'left' }}
                    md={6}
                    xs={6}
                  >
                    <div
                      style={{ marginTop: 0, paddingTop: 0, marginRight: 8 }}
                    >
                      {servicesStats}
                    </div>
                  </Col>
                ) : (
                  <Col
                    style={{ margin: 0, padding: 0, textAlign: 'left' }}
                    md={6}
                    xs={6}
                  >
                    <div>{cors ? 'CPU: ' + cors + ' Cors' : ''}</div>
                    <div>{memory ? 'Mem: ' + memory + 'GB' : ''}</div>
                  </Col>
                )}
                {/* <div>{crypt(_device.id_device)}</div> */}
              </Row>
            </Col>
            <Col md={2} xs={3}>
              <Radial
                low={{ color: '#33ABA0' }}
                mid={{ color: '#FFC107', value: 60 }}
                high={{ color: '#F44336', value: 80 }}
                className="host-graph-perc"
                size={70}
                current={cpuPerc}
                total={100}
                thick={8}
              >
                <span className="flaticon-cpu5"> {parseInt(cpuPerc)} %</span>
              </Radial>
              <div className="title">
                {procStats ? procStats + ' Procs' : 'CPU'}
              </div>
            </Col>
            <Col md={2} xs={3}>
              <Radial
                low={{ color: '#33ABA0' }}
                mid={{ color: '#FFC107', value: 60 }}
                high={{ color: '#F44336', value: 80 }}
                className="host-graph-perc"
                size={70}
                current={memoryUsedPercent}
                total={100}
                thick={8}
              >
                <span className="flaticon-random">
                  {' '}
                  {parseInt(memoryUsedPercent)} %
                </span>
              </Radial>
              <div className="title">
                {memoryUsed ? memoryUsed + 'GB' : 'MEM'}
              </div>
            </Col>
            <Col md={2} xs={3}>
              <Radial
                low={{ color: '#33ABA0' }}
                mid={{ color: '#FFC107', value: 60 }}
                high={{ color: '#F44336', value: 80 }}
                className="host-graph-perc"
                size={70}
                current={diskPercent}
                total={100}
                thick={8}
              >
                <span className="flaticon-data-storage4">
                  {' '}
                  {parseInt(diskPercent)}%{' '}
                </span>
              </Radial>
              <div
                className="title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '90%' }} className="text-center">
                  {disk[diskIndex]
                    ? disk[diskIndex].name.substr(0, 10)
                    : 'DISK'}
                </div>
                {diskOptions.length > 1 ? (
                  <IconMenu
                    menuStyle={{
                      border: '1px solid #000',
                      width: 300,
                      maxWidth: 300,
                    }}
                    iconButtonElement={
                      <IconButton>
                        <MdFilterList
                          size={15}
                          style={{ margin: 0, padding: 0 }}
                          className={
                            diskAlert === 'warning'
                              ? 'text-yellow'
                              : diskAlert === 'danger'
                              ? 'text-red'
                              : ''
                          }
                        />
                      </IconButton>
                    }
                    value={this.state.diskIndex}
                    onChange={this.handleDisk}
                  >
                    {diskOptions}
                  </IconMenu>
                ) : null}
              </div>
            </Col>
            <Col md={2} xs={3}>
              <div className="host-graph-net">
                <Sparklines data={[5, 10, 5, 20, 8, 15]}>
                  <SparklinesLine color="#33ABA0" />
                  <SparklinesSpots />
                </Sparklines>
                <span>
                  <i className="fa fa-caret-up text-green" />{' '}
                  {Number((netStatsTx / 1024).toFixed(3)) + ' Kbps'}
                </span>
              </div>
              <div className="host-graph-net">
                <Sparklines data={[5, 10, 5, 20, 8, 15]}>
                  <SparklinesLine color="#F44336" />
                  <SparklinesSpots />
                </Sparklines>
                <span>
                  <i className="fa fa-caret-down text-red" />{' '}
                  {Number((netStatsRx / 1024).toFixed(3)) + ' Kbps'}
                </span>
              </div>
              <div
                className="title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '90%' }} className="text-center">
                  {netStats ? netStats.name : ''}
                </div>
                {netOptions.length > 1 ? (
                  <IconMenu
                    iconButtonElement={
                      <IconButton>
                        <MdFilterList
                          size={15}
                          style={{ margin: 0, padding: 0 }}
                        />
                      </IconButton>
                    }
                    value={netIndex}
                    onChange={this.handleNet}
                  >
                    {netOptions}
                  </IconMenu>
                ) : null}
              </div>
            </Col>
            {available ? (
              <div
                style={{
                  background: '#ff3a3ab3',
                  color: 'rgb(255, 255, 255)',
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  paddingTop: '3px',
                  paddingBottom: '3px',
                }}
              >
                <span>
                  {' '}
                  Offline - Last date response: {dateUnavailableServer}{' '}
                </span>
              </div>
            ) : (
              <div />
            )}
          </Row>
        </div>
        <div className="offline">
          <Row className="row infos">
            <Col md={6.5} xs={6}>
              {_device.name.toUpperCase()}
            </Col>
            <Col md={5} xs={6}>
              {_device.ip}
            </Col>
            <Col md={0.5} xs={1} className="text-right" style={{ padding: 0 }}>
              <IconMenu
                iconButtonElement={
                  <IconButton
                    className="configurationsIcon"
                    style={{ height: 35, width: 35, margin: '-4px 5px 0 0' }}
                  />
                }
              >
                <MenuItem
                  primaryText="Sensr Conf"
                  leftIcon={
                    <div
                      className="configurationsIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() => handleModal({ msg: token })}
                />
                <MenuItem
                  primaryText={language.EDIT_CONFIGURATION}
                  leftIcon={
                    <div
                      className="editIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() => handlePage('deviceConf', _device)}
                />
                <MenuItem
                  primaryText={language.LEAVE_MONITOR}
                  leftIcon={
                    <div
                      className="removeIcon"
                      style={{ width: 35, height: 35, margin: '5px 12px' }}
                    />
                  }
                  onClick={() =>
                    handleModal({
                      msg: language.CONFIRM_STOP_MONITOR,
                      confirm: () => {
                        onDettachDevice(_device.id_device);
                        handleModal();
                      },
                    })
                  }
                />
              </IconMenu>
            </Col>
          </Row>
          <Row className="row graphs">
            <Col
              md={4}
              xs={12}
              style={{ padding: 0, display: 'flex', justifyContent: 'center' }}
            >
              <div
                className="incidentManagementIcon"
                style={{ width: 80, height: 80 }}
              />
            </Col>
            <Col md={8} xs={12} style={{ padding: 0 }}>
              <h2>{language.UNAVAILABLE}</h2>
              <h4>{dateUnavailableServer}</h4>
            </Col>
          </Row>
        </div>
      </Row>
    );
  }

  getConnectionDevice(host, tokenApi) {
    action
      .execute('get', `${host}/api/device/${this.props._device.id_device}`, '')
      .then((data) => {
        if (data.data[0].connection == 'ssh') {
          this.fetchAll(host, tokenApi);
        } else {
          this.aliveSignal(host, tokenApi);
        }
      });
  }

  aliveSignal(host, tokenApi) {
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=alive-signal`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          var tempDate = data[0].values_info.replace('"', '');
          tempDate = tempDate.replace('"', '');

          var today = new Date();
          var avaibleTemp = new Date(tempDate);
          var diffMs = today - avaibleTemp; // milliseconds
          var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
          var parsed = new Date(avaibleTemp);

          function zeroPad(d) {
            return ('0' + d).slice(-2);
          }

          if (diffMins > 1) {
            this.setState({
              available: true,
              dateUnavailableServer:
                zeroPad(parsed.getDate()) +
                '-' +
                zeroPad(parsed.getMonth() + 1) +
                '-' +
                parsed.getUTCFullYear() +
                ' ' +
                zeroPad(parsed.getHours()) +
                ':' +
                zeroPad(parsed.getMinutes()) +
                ':' +
                zeroPad(parsed.getSeconds()),
            });
            clearTimeout(this.state.hold);
            this.store.loading = false;
          } else {
            this.setState({ available: false });
            this.fetchAll(host, tokenApi);
          }
        } else {
          this.setState({ available: true });
          clearTimeout(this.state.hold);
          this.store.loading = false;
        }
      });

    //alert('Live Signal')

    let hold = setTimeout(
      () => {
        this.aliveSignal(host, tokenApi);
      },
      3000,
      this
    );

    this.setState({ aliveHold: hold });
  }

  fetchAll(host, tokenApi) {
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=mem-info`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let memoryTemp = JSON.parse(data[0].values_info);
          let memoryUsedTempS = String(memoryTemp.usedPercent).replace(
            ',',
            '.'
          );
          let memoryUsedTempI = memoryUsedTempS * 100;

          this.setState({
            memory: mem(memoryTemp.total),
            memoryUsed: mem(memoryTemp.actualUsed),
          });
        }
      });

    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=cpu-list`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        if (data.length != 0) {
          let corsTemp = JSON.parse(data[0].values_info);
          this.setState({ cors: corsTemp.length });
        }
      });

    // Fetch uptime
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=sys-uptime`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        if (data.length != 0) {
          let uptimeTemp = JSON.parse(data[0].values_info);
         // console.log('uptimeTemp');
          //console.log(uptimeTemp);
          this.setState({
            uptime: uptime(uptimeTemp.uptime, this.props.language),
          });
        }
      });

    // Fetch proc-stats
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=proc-stats`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        if (data.length != 0) {
          let procStatsTemp = JSON.parse(data[0].values_info);

          let topByCpuTemp = 0;
          let topByMemTemp = 0;

          procStatsTemp.topByCpu.map(
            (tcpu, key) => (topByCpuTemp += parseFloat(tcpu.cpuPerc.toFixed(1)))
          );

          procStatsTemp.topByMem.map(
            (tcpu, key) => (topByMemTemp += parseFloat(tcpu.memPerc.toFixed(1)))
          );

          this.setState({
            procStats: procStatsTemp.total,
            cpuPerc: topByCpuTemp.toFixed(1),
            memoryUsedPercent: parseInt(topByMemTemp),
          });
        }
      });

    // Fetch disk
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=fs-list`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        if (data.length != 0) {
          let diskTemp = JSON.parse(data[0].values_info);
          this.setState({ disk: diskTemp, removeloading: true });
        }
      });

    // Fetch netStats
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=net-list-stats`, '')
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        if (data.length != 0) {
          let netStatsTemp = JSON.parse(data[0].values_info);
          let netStatsTxTemp = netStatsTemp[0].values.TX;
          let netStatsRxTemp = netStatsTemp[0].values.RX;
          this.setState({
            netStats: netStatsTemp,
            netStatsRx: netStatsRxTemp,
            netStatsTx: netStatsTxTemp,
          });
        }
      });

    //alert('Tester Refresh Fetch')

    let hold = setTimeout(
      () => {
        this.fetchAll(host, tokenApi);
      },
      3000,
      this
    );

    this.setState({ hold: hold });
  }
}
