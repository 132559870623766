import "../../../public/css/pages.css";

import React from "react";
import { Container, Row, Col } from "react-grid-system";
import MdDashboard from "react-icons/lib/md/dashboard";
import {grey500} from 'material-ui/styles/colors'

import WelcomePagePanel from "./WelcomePagePanel";
import withLanguage from "../../common/languages/withLanguage";

const styles = {
    Title: {
        color: "var(--primary)",
        fontSize: 36,
        textAlign: "center"
    },
    Subtitle: {
        color: grey500,
        fontSize: 24,
        textAlign: "center"
    }
}

const WelcomePage = ({ language }) => (
  <Container fluid>
    <p style={styles.Title}>{language.WELCOME_PAGE.TITLE}</p>
    <p style={styles.Subtitle}>{language.WELCOME_PAGE.SUBTITLE}</p>
    <Row>
      <Col xl={3} md={6} xs={12}>
        <WelcomePagePanel
          title={language.CONTRACTS_MANAGEMENT}
          description={language.WELCOME_PAGE.CONTRACTS_MANAGEMENT}
          icon={MdDashboard}
        />
      </Col>
      <Col xl={3} md={6} xs={12}>
        <WelcomePagePanel
          title={language.INFRATRUCTURE_MANAGEMENT}
          description={language.WELCOME_PAGE.INFRATRUCTURE_MANAGEMENT}
          icon={MdDashboard}
        />
      </Col>
      <Col xl={3} md={6} xs={12}>
        <WelcomePagePanel
          title={language.RISKS_MANAGEMENT}
          description={language.WELCOME_PAGE.RISKS_MANAGEMENT}
          icon={MdDashboard}
        />
      </Col>
      <Col xl={3} md={6} xs={12}>
        <WelcomePagePanel
          title={language.SERVICE_DESK}
          description={language.WELCOME_PAGE.SERVICE_DESK}
          icon={MdDashboard}
        />
      </Col>
    </Row>
  </Container>
);

export default withLanguage(WelcomePage);
