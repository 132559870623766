import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import EffortList from './EffortList';
import EffortForm from './EffortForm';
 
import Animate from '@common/Animate';
@observer
export default class EffortContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      effort_value : [],
      page: 'list'
    }
     
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.EFFORT_VALUE, 'effort_value').then(res=> {
      this.setState({effort_value :res.data})
    });
   }

  onFormSubmit(values) {
    
    let method = values.hasOwnProperty('id_effort_value') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_effort_value') ? this.APIEndpoints.EFFORT_VALUE + '/' + values.id_effort_value : this.APIEndpoints.EFFORT_VALUE;
    this.action.execute(method, url, 'effort_value', values, 'id_effort_value').then(res => {
      this.setState({effort_value: res.data})
    })
    this.handlePage('list');
    

   
    
  }

  onDelete() {
 
    let { values } = this.state;
 
 
  this.action.execute('post', this.APIEndpoints.EFFORT_VALUE + '/' +'deleteeffort',
   'effort_value', values, 'id_effort_value');
  this.handlePage('list');
 
 
 
    
  }
  
  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    
    let { language, mails,companies,groups,contracts,mailContract } = this.store;
    let {configurationProps={}} = this.props;
    return (
      <Fragment>
        
        <EffortList
          redirect={this.redirect2}
          language={language}
          handlePage={this.handlePage}
          store={this.store}
          configurationProps={configurationProps}
          effort_value = {this.state.effort_value}
        />
        
        {page === 'form' ? (
          <EffortForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.value}
          />
        ):null  
        
        
         }
      </Fragment>
    );
  }
}
