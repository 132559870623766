import React, { useEffect, useState } from 'react';
import PageLayout from '../../../common/PageLayout';
import CommonTable from '../../../common/CommonTable';
import Button from '../../../common/Button';
import TabsAttendance from './TabsAttendance';
import OverlayLoading from '@common/OverlayLoading';
import moment from 'moment';
import './AttendanceList.css';
import Progress from '@common/Progress';
import CommonForm from '../../../common/CommonForm2';
import history from '@common/history';
import { getTotalTimeAttend } from '../utils/getTimeTotalAttend';
import { findUserGroup } from '../utils/findUserGroup';
import { getGroupsFromUserLogged } from '../utils/getGroupsFromUserLogged';
import { getGroupDefaultFALE } from '../utils/getGroupDefaultFALE';
import Store from '../../../data/Store';
const language = Store.language;
const AttendanceList = ({
  setPage,
  language,
  Theme,
  tabs,
  closeDetail,
  handleDetail,
  data,
  openAttendance,
  callPageSelectFilter,
  totalAttendances,
  filterStatus,
  setFilterStatus,
  setUpdateAttendances,
  Constants,
  setSubjectSelected,
  submitFilter,
  setDtInicialSelected,
  setDtFimSelected,
  setTopicSelected,
  setThemeSelected,
  setUserAttendance,
  setGroupTechSelected,
  clearFilter,
  sendvalues,
  isLoading,
  currentPage
}) => {
  const [showFale, setShowFale] = useState(false)
  const statusAttendanceFiler = [
    { label: language.ALL, value: 'Todos' },
    { label: language.OPENS, value: 'Open' },
    { label: language.IN_ATTENDANCE, value: 'In Progress' },
    { label: language.TICKET_STATUS['SOLVED'], value: 'Resolved' },
    { label: language.TICKET_STATUS['CANCELLEDS'], value: 'Canceled' },
  ];

  const columns = [
    { key: 'open', label: language.TO_MEET, width: 10 },
    { key: 'id', label: language.ATTENDANCE, width: 20 },
    { key: 'progress', label: language.PROGRESS_ },
    { key: 'status', label: language.STATE },
    { key: 'name_resp_step', label: language.STAGE_RESPONSIBLE },
    { key: 'name_step', label: language.STAGE_NAME },
    { key: 'dt_cad_step', label: language.STAGE_TOTAL_TIME },
    { key: 'sla_time', label: language.STATE_DEADLINE },
    { key: 'dt_start', label: language.START_DATE_TIME },
    { key: 'time_attend', label: language.TOTAL_TIME_ATTENDANCE },
    { key: 'sla_time_attend', label: language.ATTENDANCE_DEADLINE },
    { key: 'user', label: language.CLIENT },
    { key: 'name_user_start', label: language.SERVICE_TECH },
    { key: 'name_group_attend', label: language.ATTENDANCE_GROUP },
    { key: 'theme', label: language.THEME },
    { key: 'topic', label: language.TOPIC },
    { key: 'subject', label: language.SUBJECT }
  ];

  data = data.map((d) => ({
    open: (
      <Button
        onClick={() => openAttendance(d)}
        variant="circle"
        icon="fas fa-eye"
        className="mx-1"
        label={language.VISUALIZE}
        color="primary"
      />
    ),
    id: `#${d.id_attendance_sac}`,
    progress: (
      <div className="d-flex align-items-center" style={{ width: 75 }}>
        <Progress
          current={d.progress}
          hiddenText
          background="var(--primary)"
          style={{ height: 7, width: '100%' }}
        />
        <span className="ml-2 status" style={{ fontSize: 10 }}>
          {d.progress}%
        </span>
      </div>
    ),
    status:
      d.status == 'Open'
        ? language.OPENED
        : d.status == 'In Progress'
        ? language.IN_ATTENDANCE
        : d.status == 'Resolved'
        ? language.RESOLVED
        : d.status == 'Closed'
        ? language.STATUS_LOG_CLOSED
        : d.status == 'Canceled'
        ? language.CANCELED
        : d.status,
    name_resp_step: d.name_resp_step,
    name_step: d.name_step,
    dt_cad_step: d.status !== 'Resolved' && d.status !== 'Canceled' ? d.dt_cad_step ? `${getTotalTimeAttend(moment(d.dt_cad_step), moment())} hrs`: '' : '',
    sla_time: d.sla_time ? moment(d.sla_time).format('DD/MM/YYYY HH:mm') : '',
    dt_start: d.dt_start ? moment(d.dt_start).format('DD/MM/YYYY HH:mm') : '',
    time_attend: d.status !== 'Resolved' && d.status !== 'Canceled' && d.status !== 'Open' && d.dt_start ? (
                  <div className="d-flex align-items-center"
                    style={{ color: parseInt(getTotalTimeAttend(moment(d.dt_start), moment()).slice(0,3)) > 72 && 'red'}}
                  >
                    { getTotalTimeAttend(moment(d.dt_start), moment()) } hrs
                  </div>
                  )
                 : '',
    sla_time_attend:  d.sla_time_attend ? moment(d.sla_time_attend).format('DD/MM/YYYY HH:mm') : '',
    user: d.name_user,
    name_user_start: d.name_user_start,
    name_group_attend: d.name_group_attend,
    theme: d.name_theme,
    topic: d.name_topic,
    subject: d.name_subject
  }));

  const fetchDataGroup = async () => {
    const groupsUserLogged = await getGroupsFromUserLogged(Store.getUserLoged().id);
    const groupFale = await getGroupDefaultFALE();
    setShowFale(findUserGroup(groupsUserLogged, groupFale) || Store.getUserLoged().role === 'client');
  };

  useEffect(() => {
    fetchDataGroup();
  }, []);

  const getHeaderButton = () => {
    let arrayHeaderButton = [
      <Button
        variant="circle"
        icon="fas fa-sync"
        color="warning"
        className="mr-3"
        onClick={() => callPageSelectFilter()}
        tooltipLabel={language.REFRESH_PAGE}
      />
    ]

    if (showFale) {
      arrayHeaderButton.push(
        <Button
          onClick={() => setPage('form')}
          variant="circle"
          icon="fas fa-plus"
          color="warning"
          className="mr-3"
          label={language.ADD}
        />
      )
    }
    return arrayHeaderButton;
  }

  return (
    <PageLayout
      icon={
        <div
          className="panelico"
          style={{ width: 40, height: 40, overflowY: true }}
        />
      }
      title={language.LIST_OF_ATTENDANCES}
      subTitle={language.CONSUMER_SERVICE_SYSTEM}
      rightElements={[
        <div
          style={{ width: 2200, zoom: 0.8, marginRight: -200, marginTop: 25 }}
          zoom={0.8}
        >
          {isLoading && <OverlayLoading />}
          <CommonForm
            individual
            hiddenSubmit
            onChangeField={(f, fk_id) => {
              if (f.name == 'fk_id_theme') {
                setThemeSelected(fk_id);
              } else if (f.name == 'fk_id_topic') {
                setTopicSelected(fk_id);
              } else if (f.name == 'fk_id_subject') {
                setSubjectSelected(fk_id);
              } else if (f.name == 'dtinicial') {
                setDtInicialSelected(fk_id);
              } else if (f.name == 'dtfinal') {
                setDtFimSelected(fk_id);
              } else if (f.name == 'user_start') {
                setUserAttendance(fk_id);
              } else if (f.name == 'fk_id_group_tech') {
                setGroupTechSelected(fk_id);
              }
            }}
            fields={[
              {
                col: 1.5,
                name: 'fk_id_theme',
                textlabel: language.THEME,
                type: 'autocomplete',
                label: language.THEME,
                method: 'POST',
                route: `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`,
                routeGetCount: `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`,
                fieldquery: 'search',
                textinit: '',
                fieldvaluedb: 'id_theme',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`,
                methodGetAll: 'POST',
                detail: true,
              },
              {
                col: 1.5,
                name: 'fk_id_topic',
                textlabel: language.TOPIC,
                type: 'autocomplete',
                label: language.TOPIC,
                method: 'POST',
                route: `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`,
                routeGetCount: `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`,
                fieldquery: 'search',
                textinit: '',
                fieldvaluedb: 'id_topic',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`,
                methodGetAll: 'POST',
                detail: true,
              },
              {
                col: 1.5,
                name: 'fk_id_subject',
                textlabel: language.SUBJECT,
                type: 'autocomplete',
                label: language.SUBJECT,
                method: 'POST',
                route: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
                routeGetCount: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
                fieldquery: 'search',
                textinit: '',
                fieldvaluedb: 'id_sac_subject',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
                methodGetAll: 'POST',
                detail: true,
              },
              {
                col: 1.5,
                name: 'fk_id_group_tech',
                textlabel: language.TECH_GROUP,
                type: 'autocomplete',
                label: language.TECH_GROUP,
                method: 'POST',
                route: `${Constants.APIEndpoints.GROUP}/filter`,
                routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                fieldquery: 'search',
                textinit: '',
                fieldvaluedb: 'id_group_users',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                methodGetAll: 'POST',
                detail: true,
              },
              {
                col: 1.5,
                name: 'user_start',
                textlabel: language.USER,
                type: 'autocomplete',
                label: language.USER,
                method: 'POST',
                route: `${Constants.APIEndpoints.USER}/filter`,
                routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                fieldquery: 'search',
                textinit: '',
                fieldvaluedb: 'id_user',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                methodGetAll: 'POST',
                detail: true,
              },
              {
                col: 1,
                name: 'dtinicial',
                type: 'date',
                label: language.INITIAL_DATE,
                endIcon: 'fas fa-calendar-alt',
              },
              {
                col: 1,
                name: 'dtfinal',
                type: 'date',
                label: language.FINAL_DATE,
                endIcon: 'fas fa-calendar-alt',
              },
              {
                col: 1,
                type: 'element',
                content: (v, h, submit) => (
                  <Button
                    icon="fas fa-search"
                    color="success"
                    variant="normal"
                    label={language.SEARCH_FOR}
                    style={{
                      marginTop: 13,
                    }}
                    onClick={() => submitFilter()}
                  />
                ),
              },
              {
                col: 0.5,
                type: 'element',
                content: (v, h, submit) => (
                  <Button
                    onClick={() => clearFilter()}
                    variant="circle"
                    icon="fas fa-eraser"
                    color="success"
                    label={language.CLEAR}
                    style={{
                      fontSize: 14,
                      marginLeft: -25,
                      marginTop: 15,
                    }}
                  />
                ),
              },
              {
                col: 0.5,
                type: 'element',
                content: (v, h, submit) => (
                  <Button
                    onClick={() => history.push('/dash/sac/listtasks')}
                    variant="circle"
                    icon="fas fa-pencil-alt"
                    color="success"
                    label={language.TASKS_LIST}
                    style={{
                      fontSize: 14,
                      marginLeft: -25,
                      marginTop: 15,
                    }}
                  />
                ),
              },
            ]}
          />
        </div>,
      ]}
    >
      <TabsAttendance
        controlButtonTheme={Theme.controlButton}
        closeDetail={closeDetail}
        tabs={tabs}
        setLocalFiles={() => {}}
        handleDetail={handleDetail}
      />
      <div id="TicketManagementList">
        <CommonTable
          col={columns}
          data={data}
          excel={sendvalues}
          searchColumn
          beginWithNoFilter={true}
          paginationTop={false}
          currentPage={currentPage}
          isAutomaticPagination={false}
          countTotal={totalAttendances}
          searchColumnEvent={(v) => {
            callPageSelectFilter(0, v, 75);
          }}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          beforeBar={getHeaderButton()}
          beforeBar2={
            <div className="content-item-control d-flex align-items-center">
              {statusAttendanceFiler.map((status, i) => (
                <a
                  href="#"
                  style={{
                    backgroundColor: filterStatus.find(
                      (item) => item.value === status.value
                    )
                      ? 'var(--warning)'
                      : 'transparent',
                  }}
                  onClick={async () => {
                    setUpdateAttendances(true);
                    if (status.value === 'Todos') {
                      setFilterStatus([status]);
                    } else {
                      if (
                        filterStatus.find((item) => item.value === status.value)
                      ) {
                        setFilterStatus([
                          ...filterStatus.filter(
                            (item) => item.value !== status.value
                          ),
                        ]);
                      } else {
                        setFilterStatus([
                          ...filterStatus.filter(
                            (item) =>
                              item.value !== 'Todos' && item.value !== 'Job'
                          ),
                          status,
                        ]);
                      }
                    }
                  }}
                >
                  <span>{status.label}</span>
                </a>
              ))}
            </div>
          }
        />
      </div>
    </PageLayout>
  );
};

export default AttendanceList;
