import React, { memo, Fragment, useState } from 'react';
import classnames from 'classnames';
/*------------------------common------------------------*/
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
import { flexBasis } from '@common/Func';
import DialogConfirm from '@common/DialogConfirm';
import Dialog from '@common/Dialog';
import CommonButton from '@common/Button';
import Store from '@data/Store';
/*------------------------material-ui------------------------*/
import { Card, Grid, Checkbox } from '@material-ui/core';
/*------------------------pages------------------------*/
import ListAvatars from './ListAvatars';
import Scope from './Scope';
import EvolutionKPI from './EvolutionKPI';
import ForeseenVsRealized from './ForeseenVsRealized';
import EvolutionProject from './EvolutionProject';
import FileList from './FileList';

import Constants from '@data/Constants';
import Actions from '@data/Actions';

/*-----------------------------------------------------*/
import lodash from 'lodash';
import DialogTableActivities from './DialogTableActivities';

/*------------------------------------------FORMATO-----------------------------------------------------*/
/*
   let data = {
      id:58,
      company: 'Sensr.IT',
      name:'Projeto Teste',
      type:'corrective',
      ListAvatars:[
         {name:'Rodrigo'},
         {name:'Carla'},
         {name:'Fábio'},
         {name:'Platão'},
         {name:'Júlio'},
      ],
      Scope:'Lorem ipsum dolor sit, amet consectetur adipisicing, elit. Perspiciatis dolorum soluta dolore similique velit alias, inventore eligendi debitis sapiente sint iste quas officia excepturi, voluptatem, beatae dignissimos facilis necessitatibus, tempore!',
      EvolutionKPI:[
         {title:'Finalizado', value:4},
         {title:'Finalizado', value:4},
         {title:'Finalizado', value:4},
         {title:'Finalizado', value:4},
      ],
      ForeseenVsRealized:{
         foresseen:2009,
         realized:29100
      },
      EvolutionProject:{
         percent:50,
         dt_i:'26/03/2020',
         dt_f:'30/03/2020'
      },
      FileList:[
         {
            label:'Termo de abertura',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[
               {
                 label:'Nome do file',
                 size:9978,
                 type:'image/png'
               }
            ]
         },
         {
            label:'Termo de mudança',
            onAdd:()=>null,
            files:[]
         },
         {
            label:'Termo de encerramento',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[]
         },
         {
            label:'Anexo',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[]
         },
         {
            label:'Pauta',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[]
         },
      ]
   }
*/
/*-----------------------------------------------------------------------------------------------------*/

export default
  ({
    data = {},
    onAdd,
    onRemoveFile,
    onDownload,
    redirect,
    onEdit,
    removeProject,
    onClose,
    user,
    onDuplicate,
    onPause,
  }) => {
    const { language } = Store;
    let [removeProjectConfirm, setRemoveProjectConfirm] = useState(false);
    let [open, setOpen] = useState(null);
    let [openAct, setOpenAct] = useState({});
    let [scope, setScope] = useState({});
    let [links, setLinks] = useState([]);
    let [openLinkModal, setOpenLinkModal] = useState(false);
    let title = {
      planned_hours: language.ACTIVITIES_PLANNED_HOURS,
      realized_hour: language.ACTIVITIES_HOURS_PERFORMED,
      exceeded_hours: language.ACTIVITIES_EXCEEDED_HOURS,
    };

    const btnStatus = {
      3: {
        icon: 'fas fa-wrench',
        color: 'primary',
        label: language.TYPE_PROJECT_ITEMS[2].label,
      },
      1: {
        icon: 'fas fa-compass',
        color: 'info',
        label: language.TYPE_PROJECT_ITEMS[0].label,
      },
      5: {
        icon: 'fas fa-gavel',
        color: 'danger',
        label: language.TYPE_PROJECT_ITEMS[4].label,
      },
      4: {
        icon: 'fas fa-chart-line',
        color: 'success',
        label: language.TYPE_PROJECT_ITEMS[3].label,
      },
      2: {
        icon: 'fas fa-dumbbell',
        color: 'purple',
        label: language.TYPE_PROJECT_ITEMS[1].label,
      },
    };

    const titleAct = {
      BACKLOG: language.ACTIVITIES_IN_BACKLOG,
      TODO: language.ACTIVITIES_IN_TODO,
      DOING: language.ACTIVITIES_IN_DOING,
      STOPPED: language.ACTIVITIES_IN_STOPPED,
      VALIDATION: language.ACTIVITIES_IN_VALIDATION,
      DONE: language.ACTIVITIES_IN_DONE,
    };

    const openLinks = (id) => {
      const action = new Actions();

      action
        .execute(
          'get',
          Constants.APIEndpoints.PROJECT + '/links/' + id,
          'links'
        )
        .then((res) => {
          setLinks(res.data);
        });
      setOpenLinkModal(true);
    };

    const saveLinks = (values) => {
      values.fk_id_project = data.id;

      const action = new Actions();
      action
        .execute(
          'post',
          Constants.APIEndpoints.PROJECT + '/links/create',
          '',
          values
        )
        .then((res) => {
          setLinks(res.data);
        });
      setOpenLinkModal(false);
    };

    const deleteLinks = (values) => {
      const action = new Actions();
      action
        .execute(
          'post',
          Constants.APIEndpoints.PROJECT + '/links/delete',
          '',
          values
        )
        .then((res) => {
          setLinks(res.data);
        });
    };

    return (
      <Fragment>
        <DialogConfirm
          open={removeProjectConfirm}
          onClose={() => setRemoveProjectConfirm(false)}
          title={
            data.EvolutionProject.percent > 0
              ? language.WARNING_WANT_CANCEL_PROJECT
              : language.WARNING_WANT_REMOVE_PROJECT
          }
          confirmProps={{
            color: 'danger',
            _loading: 'delete-project_' + data.id,
          }}
          message={
            data.EvolutionProject.percent > 0
              ? `${language.ACCOUNTED_HOURS_WILL_NOT_BE_DELETED} - ${data.id} - ${data.name}`
              : data.id + ' - ' + data.name
          }
          onConfirm={() => {
            removeProject({ id_project: data.id, user_cad: data.user_cad });
            setRemoveProjectConfirm(false);
          }}
        />
        <DialogTableActivities
          open={open}
          data={data.actDetail[open] || []}
          title={title[open] || ''}
          onClose={() => setOpen()}
        />
        <DialogTableActivities
          open={!!Object.keys(openAct).length}
          data={openAct.data}
          title={titleAct[openAct.key]}
          onClose={() => setOpenAct({})}
        />
        <Dialog
          open={!!Object.keys(scope).length}
          onClose={() => setScope({})}
          title={scope.title}
        >
          <p
            className="text"
            dangerouslySetInnerHTML={{ __html: scope.body }}
          />
        </Dialog>
        <Dialog
          open={openLinkModal}
          title="Links"
          onClose={() => setOpenLinkModal(false)}
        >
          {links.map((l) => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <a
                href={l.link}
                target="_blank"
                style={{ fontWeight: 600, color: 'darkblue', margin: 3 }}
              >
                {l.link}
              </a>
              <CommonButton
                icon="fas fa-times"
                color="primary"
                variant="circle"
                outlined
                className="closeBtn"
                onClick={() => deleteLinks(l)}
              />
            </div>
          ))}
          <div style={{ height: 20 }} />
          <CommonForm
            fields={[
              { col: 12, name: 'link', type: 'text', label: 'Novo link' },
            ]}
            onSubmit={(values) => saveLinks(values)}
          />
        </Dialog>
        <li className="mb-3">
          {
            <Card className="content-medium pr-0">
              <div className="d-flex align-items-center justify-content-between content-info pr-3">
                <div
                  onClick={() => redirect('/dash/gp/' + data.id)}
                  className="d-flex align-items-center"
                >
                  <div
                    style={{ width: 37 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i
                      className="fas fa-check"
                      style={{
                        color: data.closed ? 'var(--success)' : 'var(--danger)',
                      }}
                      title={
                        data.closed
                          ? language.CLOSED_PROJECT
                          : language.OPEN_PROJECT
                      }
                    />
                  </div>
                  <span className="id mx-2">#{data.real_id}</span>
                  <Button
                    {...btnStatus[data.type]}
                    variant="normal"
                    size={0.7}
                  />
                  <span className="company-name mx-2">{data.company}</span>
                  <span className="project-name">{data.name ? ` | ${data.name}` : ''}</span>
                </div>
                <div className="d-flex align-items-center pt-3">
                  {data.pause_project == true ? (
                    <Button
                      circleButton
                      icon="fas fa-play"
                      color="primaryGradient"
                      label={language.CONTINUE_PROJECT}
                      outlined
                      style={{
                        border: 'none',
                      }}
                      variant="circle"
                      _loading={'project_close_' + data.id}
                      onClick={() => onPause(data.id, false)}
                      size={0.7}
                    />
                  ) : data.EvolutionProject.canceled ? null : (
                    <Button
                      circleButton
                      icon="fas fa-pause"
                      color="danger"
                      label={language.PAUSE_PROJECT}
                      outlined
                      style={{
                        border: 'none',
                      }}
                      variant="circle"
                      _loading={'project_close_' + data.id}
                      onClick={() => onPause(data.id, true)}
                      size={0.7}
                    />
                  )}
                  <Button
                    onClick={() =>
                      redirect('/dash/gp/report', { id_project: data.id })
                    }
                    icon="fas fa-print"
                    className={classnames({
                      'mr-2': data.EvolutionProject.percent < 100,
                    })}
                    color="default"
                    outlined
                    variant="circle"
                    size={0.7}
                    style={{
                      border: 'none',
                    }}
                    label={language.PRINT}
                  />
                  <Button
                    onClick={() => onDuplicate(data.id)}
                    icon="fas fa-clone"
                    className={classnames({
                      'mr-2': data.EvolutionProject.percent < 100,
                    })}
                    color="purple"
                    outlined
                    variant="circle"
                    label={language.DUPLICATE}
                    size={0.7}
                    _loading={`project_clone_${data.id}`}
                    style={{
                      border: 'none',
                    }}
                  />
                  {data.EvolutionProject.canceled ? null : (
                    <Button
                      onClick={() => onEdit(data.id)}
                      icon="fas fa-pencil-alt"
                      className={classnames({
                        'mr-2': data.EvolutionProject.percent < 100,
                      })}
                      color="primary"
                      outlined
                      label={language.EDIT}
                      size={0.7}
                      style={{
                        border: 'none',
                      }}
                      variant="circle"
                    />
                  )}
                  {data.EvolutionProject.canceled ? null : (
                    <Button
                      icon="fas fa-trash-alt"
                      color="danger"
                      outlined
                      style={{
                        border: 'none',
                      }}
                      className={classnames({
                        'mx-2': data.EvolutionProject.percent == 100,
                      })}
                      variant="circle"
                      onClick={() => setRemoveProjectConfirm(true)}
                      size={0.7}
                      label={language.ERASE}
                    />
                  )}
                  {data.EvolutionProject.percent == 100 ? (
                    <Button
                      icon="fas fa-check"
                      color="success"
                      label={language.PROJECT_CLOSE}
                      outlined
                      style={{
                        border: 'none',
                      }}
                      variant="circle"
                      _loading={'project_close_' + data.id}
                      onClick={() => onClose(data.id)}
                      size={0.7}
                    />
                  ) : null}
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={1} style={{ flexBasis: 47, width: 47 }}>
                  <ListAvatars data={data.ListAvatars} />
                </Grid>
                <Grid item xs={1.95} style={{ ...flexBasis(1.95) }}>
                  <Scope
                    scope={data.Scope}
                    title={data.name}
                    setScope={setScope}
                  />
                </Grid>
                <Grid item xs={4.1} style={{ ...flexBasis(4.1) }}>
                  <EvolutionKPI
                    data={data.EvolutionKPI}
                    setOpenAct={setOpenAct}
                  />
                </Grid>
                {user.role == 'client' || parseInt(data.user_cad) == user.id ? (
                  <Grid item xs={1.3} style={{ ...flexBasis(1.3) }}>
                    <ForeseenVsRealized
                      {...data.ForeseenVsRealized}
                      id={data.id}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={2.2} style={{ ...flexBasis(2.2) }}>
                  <EvolutionProject
                    {...data.EvolutionProject}
                    setOpen={setOpen}
                  />
                </Grid>
                <Grid item xs={1.95} style={{ ...flexBasis(1.95) }}>
                  <FileList
                    data={data.FileList}
                    onDownload={onDownload}
                    onAdd={onAdd}
                    id={data.id}
                    onRemoveFile={onRemoveFile}
                    saveLinks={saveLinks}
                    deleteLinks={deleteLinks}
                    openLinks={openLinks}
                  />
                </Grid>
              </Grid>
            </Card>
          }
        </li>
      </Fragment>
    );
  }
