import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import ReactTooltip from 'react-tooltip';
import { Card, Switch } from '@material-ui/core';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { Crad, Chip } from '@material-ui/core';

import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import Button from '@common/Button';

import Ad from './Ad';

@observer
export default class AdList extends Component {
  render() {
    let { language, handlePage, redirect, onDelete, ad } = this.props;

    let col = [
      { key: 'url', label: language.LDAP, style: { width: 80, textAlign: 'center' } },
      { key: 'dc', label: language.DC, style: { width: 120, textAlign: 'center' } },
      {
        key: 'user',
        label: language.USER,
        style: { width: 20, textAlign: 'center' },
      },
      {
        key: 'perfil',
        label: language.PROFILE,
        style: { width: 20, textAlign: 'center' },
      },

      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' },
      },
    ];
    let data = [];
    window.ad = ad;
   
   data = (ad || []).map((adL) => {
      return {
              url: adL.url,
              dc: adL.dc,
              user: adL.user,
              perfil: adL.perfil,
              action: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12 }}
                      size={0.7}
                      className="mr-2"
                      onClick={() => handlePage('form', adL)}
                    />
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13 }}
                      size={0.7}
                      onClick={() => handlePage('remove', adL)}
                    />
                  </div>
                }
              />
            </React.Fragment>
              ), 
      };
    });
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          index="AdList"
          columns={col}
          data={data}
          searchColumn
          orderColumn
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
