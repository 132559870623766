export function Graph_1({ categories, data, language }) {
  return {
    chart: {
      type: 'column',
      height: 200,
    },
    title: null,
    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: 8,
          color: 'var(--primary)',
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: language.VALUES,
        style: {
          fontSize: 10,
          color: '#A3A3A3',
        },
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return this.total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          });
        },
        style: {
          fontWeight: 'bold',
          color: 'var(--primary)',
          fontSize: 8,
        },
      },
      labels: {
        formatter() {
          return this.value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          });
        },
        style: {
          fontSize: 8,
          color: 'var(--primary)',
        },
      },
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y} <br/>Total: {point.stackTotal} ',
    },
    credits: { enabled: false },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          format: '{point.y:,.0f}',
          style: {
            textOutline: 0,
            color: 'white',
            fontSize: 8,
          },
        },
      },
      series: {
        borderRadius: 0,

        point: {
          events: {
            click: (e) => {},
          },
        },

        marker: {
          lineColor: '#333',
        },
      },
    },
    legend: {
      itemStyle: {
        fontSize: 8,
        color: 'var(--primary)',
      },
      enabled: false,
    },
    series: data,
  };
}
export function totalPerType(v1 = 0, v2 = 0, v3 = 0, language) {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
        fontFamily: 'Roboto',
      },
      height: 200,
    },
    colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
      return {
        radialGradient: {
          cx: 0.5,
          cy: 0.3,
          r: 0.7,
        },
        stops: [
          [0, color],
          [1, Highcharts.color(color).brighten(-0.3).get('rgb')],
        ],
      };
    }),
    title: null,
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    credits: { enabled: false },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          connectorColor: 'silver',
          style: {
            color: 'var(--primary)',
            fontSize: 10,
          },
        },
      },
    },
    series: [
      {
        name: language.VALUES,
        data: [
          { name: language.EXPENSE, y: v1 },
          { name: language.INVESTIMENT, y: v2 },
          { name: language._CONTRACT, y: v3 },
        ],
      },
    ],
  };
}
