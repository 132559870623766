import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import PageLayout from '@common/PageLayout';
import { TextField, MenuItem } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import './ProjectManagementPrioritization.css';

function RP(l, exp, rep = '') {
  return l.replace(exp, rep).trim();
}

export default class ProjectManagementPrioritizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      language,
      redirect,
      projects,
      updateProject,
      prioritization,
      UpPrioritization,
    } = this.props;
    const TF = (props, idscale, col, value) => (
      <TextField
        select
        style={{ minWidth: 100 }}
        value={parseInt(value)}
        margin="dense"
        onChange={(event, key, values) => {
          //console.log(SUMTOTAL()[col])
          // console.log(SUMTOTAL_()[col] )
          // console.log((SUMTOTAL()[col] - parseInt(value)) + parseInt(event.target.value))
          if (
            SUMTOTAL()[col] - parseInt(value) + parseInt(event.target.value) <=
            SUMTOTAL_()[col]
          ) {
            UpPrioritization(idscale, col, event.target.value);
          }
        }}
        className="input-min"
        SelectProps={{
          className: 'select-min',
        }}
        variant="outlined"
        {...props}
      >
        {[
          { text: '5', value: 5 },
          { text: '4', value: 4 },
          { text: '3', value: 3 },
          { text: '2', value: 2 },
          { text: '1', value: 1 },
        ].map((e) => (
          <MenuItem value={e.value}>{e.text}</MenuItem>
        ))}
      </TextField>
    );

    const CALC = (id) => {
      let total = 0;
      try {
        total = parseInt(
          prioritization.filter((f) => f.id_scale == id.desire_directors)[0]
            .desire_directors
        );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter(
              (f) => f.id_scale == id.strategic_alignment
            )[0].strategic_alignment
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.benefit_amount)[0]
              .benefit_amount
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter(
              (f) => f.id_scale == id.processes_improvement
            )[0].processes_improvement
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.financial_feedback)[0]
              .financial_feedback
          );
      } catch (e) {}

      return total;
    };

    const SUMTOTAL = () => {
      let total = 0;
      let array = {
        desire_directors: 0,
        benefit_amount: 0,
        strategic_alignment: 0,
        processes_improvement: 0,
        financial_feedback: 0,
        risk_if_not_done: 0,
        complexity: 0,
        involved_areas: 0,
        generates_costs: 0,
        urgency: 0,
      };
      prioritization
        .filter((f) => f.id_scale != 6)
        .map((g) => {
          (array.desire_directors += parseInt(g.desire_directors)),
            (array.benefit_amount += parseInt(g.benefit_amount)),
            (array.processes_improvement += parseInt(g.processes_improvement)),
            (array.strategic_alignment += parseInt(g.strategic_alignment)),
            (array.financial_feedback += parseInt(g.financial_feedback));
          (array.risk_if_not_done += parseInt(g.risk_if_not_done)),
            (array.complexity += parseInt(g.complexity)),
            (array.involved_areas += parseInt(g.involved_areas)),
            (array.generates_costs += parseInt(g.generates_costs)),
            (array.urgency += parseInt(g.urgency));
        });

      return array;
    };

    const SUMTOTAL_ = () => {
      let total = 0;
      let array = {
        desire_directors: 0,
        benefit_amount: 0,
        strategic_alignment: 0,
        processes_improvement: 0,
        financial_feedback: 0,
        risk_if_not_done: 0,
        complexity: 0,
        involved_areas: 0,
        generates_costs: 0,
        urgency: 0,
      };
      prioritization
        .filter((f) => f.id_scale == 6)
        .map((g) => {
          (array.desire_directors += parseInt(g.desire_directors)),
            (array.benefit_amount += parseInt(g.benefit_amount)),
            (array.processes_improvement += parseInt(g.processes_improvement)),
            (array.strategic_alignment += parseInt(g.strategic_alignment)),
            (array.financial_feedback += parseInt(g.financial_feedback));
          (array.risk_if_not_done += parseInt(g.risk_if_not_done)),
            (array.complexity += parseInt(g.complexity)),
            (array.involved_areas += parseInt(g.involved_areas)),
            (array.generates_costs += parseInt(g.generates_costs)),
            (array.urgency += parseInt(g.urgency));
        });

      return array;
    };

    const CALC1 = (id) => {
      let total = 0;
      try {
        total = parseInt(
          prioritization.filter((f) => f.id_scale == id.generates_costs)[0]
            .generates_costs
        );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.risk_if_not_done)[0]
              .risk_if_not_done
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.complexity)[0]
              .complexity
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.involved_areas)[0]
              .involved_areas
          );
      } catch (e) {}

      return total;
    };

    const TF2 = (props, idproject, col, value) => (
      <TextField
        select
        style={{ minWidth: 130 }}
        margin="dense"
        className="input-min"
        onChange={(event, key, values) =>
          updateProject(idproject, col, event.target.value)
        }
        value={parseInt(value)}
        variant="outlined"
        SelectProps={{
          className: 'select-min',
        }}
        {...props}
      >
        {[
          { text: language.PRIORITIZATION.VERY_HIGH, value: 5 },
          { text: language.PRIORITIZATION.HIGH, value: 4 },
          { text: language.PRIORITIZATION.MEDIUM, value: 3 },
          { text: language.PRIORITIZATION.SMALL, value: 2 },
          { text: language.PRIORITIZATION.VERY_SMALL, value: 1 },
        ].map((e) => (
          <MenuItem value={e.value}>{e.text}</MenuItem>
        ))}
      </TextField>
    );

    const column = [
      { key: '', label: '' },
      {
        key: '',
        label: language.BOARD_DESIRE,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.STRATEGIC_ALIGMENT,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.VALUE_OF_BENEFITS,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.PROCESSES_IMPROVEMENT,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.FINANCIAL_FEEDBACK,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.GENERATES_COSTS,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.RISK_IF_NOT_DONE,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.URGENCY,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.COMPLEXITY,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      {
        key: '',
        label: language.INVOLVED_AREAS,
        thConfig: true,
        style: {
          width: 50,
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      { key: '', label: '' },
      { key: '', label: '' },
      { key: '', label: '' },
    ];

    const col = {
      ConfigCell: {
        className: 'header_col',
        cols: {
          col_4: {
            style: {
              borderRight: '2px solid rgb(248, 249, 250)',
            },
          },
        },
      },
      Desejo_Diretoria: language.BOARD_DESIRE,
      Alinhamento_Estrategico: language.STRATEGIC_ALIGMENT,
      Valor_Beneficios: language.VALUE_OF_BENEFITS,
      Melhoria_processos: language.PROCESSES_IMPROVEMENT,
      Retorno_financeiro: language.FINANCIAL_FEEDBACK,
      Gera_custos: language.GENERATES_COSTS,
      Risco_s_n_feito: language.RISK_IF_NOT_DONE,
      Urgencia: language.URGENCY,
      Complexidade: language.COMPLEXITY,
      areas_envolvidas: language.INVOLVED_AREAS,
      empty_11: '',
      empty_12: '',
    };

    var data = [];

    // ###########################################--cabeçalho_1 (estático)--#####################################################
    const header_1 = {
      ConfigCell: {
        className: 'title title_1',
        cols: {
          col_0: {
            colSpan: 14,
          },
        },
      },
      label: language.PRIORITIZATION_MATRIX + ' - ' + language.WEIGHT,
    };

    const divider_1 = {
      ConfigCell: {
        className: 'divider divider_1',
        cols: {
          col_0: {
            rowSpan: 2,
            style: {
              borderRight: '2px solid rgb(248, 249, 250)',
            },
          },
          col_1: {
            colSpan: 5,
            style: {
              borderRight: '2px solid rgb(248, 249, 250)',
            },
          },
          col_2: {
            colSpan: 8,
          },
        },
      },
      total_peso: language.TOTAL + ' ' + language.WEIGHT,
      beneficios: language.BENEFITS,
      cc: language.COMPLEXITY + ' ' + language.AND + ' ' + language.COST,
    };

    data.push(header_1);
    data.push(divider_1);
    data.push(col);

    // -------------------------------------percentual (cálculo - dinâmico)-----------------------------------------
    var percentual = {};

    let valor = prioritization.filter((g) => g.id_scale == 6)[0];
    //console.log("passou aqui")
    //console.log(prioritization)
    let total = 0;
    try {
      total =
        parseInt(valor.desire_directors != '' ? valor.desire_directors : 0) +
        parseInt(
          valor.strategic_alignment != '' ? valor.strategic_alignment : 0
        ) +
        parseInt(valor.benefit_amount ? valor.benefit_amount : 0) +
        parseInt(
          valor.processes_improvement != '' ? valor.processes_improvement : 0
        ) +
        parseInt(
          valor.financial_feedback != '' ? valor.financial_feedback : 0
        ) +
        parseInt(valor.generates_costs != '' ? valor.generates_costs : 0) +
        parseInt(valor.risk_if_not_done != '' ? valor.risk_if_not_done : 0) +
        parseInt(valor.urgency != '' ? valor.urgency : 0) +
        parseInt(valor.complexity != '' ? valor.complexity : 0) +
        parseInt(valor.involved_areas != '' ? valor.involved_areas : 0);
    } catch (r) {}

    percentual['key_' + 1] = 'Soma ' + total + '%';

    percentual['key_' + 2] = (
      <TextField
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        label={valor && valor.desire_directors}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.desire_directors + parseInt(ev.target.value) <=
                100 ||
              parseInt(ev.target.value) < valor.desire_directors
            )
              UpPrioritization(6, 'desire_directors', ev.target.value);
          }
        }}
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 3] = (
      <TextField
        label={valor && valor.strategic_alignment + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.strategic_alignment + parseInt(ev.target.value) <=
                100 ||
              parseInt(ev.target.value) < valor.strategic_alignment
            )
              UpPrioritization(6, 'strategic_alignment', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 4] = (
      <TextField
        label={valor && valor.benefit_amount + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.benefit_amount + parseInt(ev.target.value) <= 100 ||
              parseInt(ev.target.value) < valor.benefit_amount
            )
              UpPrioritization(6, 'benefit_amount', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 5] = (
      <TextField
        label={valor && valor.processes_improvement}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (total + parseInt(ev.target.value) <= 100)
              UpPrioritization(6, 'processes_improvement', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 6] = (
      <TextField
        label={valor && valor.financial_feedback + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.financial_feedback + parseInt(ev.target.value) <=
                100 ||
              parseInt(ev.target.value) < valor.financial_feedback
            )
              UpPrioritization(6, 'financial_feedback', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 7] = (
      <TextField
        label={valor && valor.generates_costs + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.generates_costs + parseInt(ev.target.value) <=
                100 ||
              parseInt(ev.target.value) < valor.generates_costs
            )
              UpPrioritization(6, 'generates_costs', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 8] = (
      <TextField
        label={valor && valor.risk_if_not_done + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.risk_if_not_done + parseInt(ev.target.value) <=
                100 ||
              parseInt(ev.target.value) < valor.risk_if_not_done
            )
              UpPrioritization(6, 'risk_if_not_done', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 9] = (
      <TextField
        label={valor && valor.urgency + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.urgency + parseInt(ev.target.value) <= 100 ||
              parseInt(ev.target.value) < valor.urgency
            )
              UpPrioritization(6, 'urgency', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 10] = (
      <TextField
        label={valor && valor.complexity + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.complexity + parseInt(ev.target.value) <= 100 ||
              parseInt(ev.target.value) < valor.complexity
            )
              UpPrioritization(6, 'complexity', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 11] = (
      <TextField
        label={valor && valor.involved_areas + '%'}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (
              total - valor.involved_areas + parseInt(ev.target.value) <= 100 ||
              parseInt(ev.target.value) < valor.involved_areas
            )
              UpPrioritization(6, 'involved_areas', ev.target.value);
          }
        }}
        style={{ maxWidth: 100 }}
        margin="dense"
        className="input-min"
        variant="outlined"
      ></TextField>
    );

    percentual['key_' + 12] = '';

    percentual['key_' + 13] = '';

    data.push(percentual);

    data.push({
      ConfigCell: {
        style: { height: 20 },
        cols: {
          col_0: {
            style: {
              fontWeight: 500,
            },
          },
          col_1: {
            colSpan: 13,
          },
        },
      },
      escala: language.SCALE,
      empty_escale: '',
    });
    // -------------------------------------dados dinâmicos----------------------------------------------

    const data_header_1 = prioritization
      .filter((f) => f.id_scale < 6)
      .map((y, i) => ({
        total_peso: y.name, // esse label não muda, é pra ficar estático
        //---------------dados abaixo são dinâmicos--------------------------
        Desejo_Diretoria: TF(
          { label: RP(language.BOARD_DESIRE, /(Board|da Diretoria)/) },
          y.id_scale,
          'desire_directors',
          y.desire_directors
        ),
        Alinhamento_Estrategico: TF(
          { label: RP(language.STRATEGIC_ALIGMENT, /(Strategic|Estratégico)/) },
          y.id_scale,
          'strategic_alignment',
          y.strategic_alignment
        ),
        Valor_Benefícios: TF(
          { label: language.VALUE },
          y.id_scale,
          'benefit_amount',
          y.benefit_amount
        ),
        Melhoria_processos: TF(
          {
            label: RP(language.PROCESSES_IMPROVEMENT, /(Processes|Processos)/),
          },
          y.id_scale,
          'processes_improvement',
          y.processes_improvement
        ),
        Retorno_financeiro: TF(
          { label: language.RETURN.replace('ar', 'o') },
          y.id_scale,
          'financial_feedback',
          y.financial_feedback
        ),
        Gera_custos: TF(
          { label: language.GENERATES_COSTS },
          y.id_scale,
          'generates_costs',
          y.generates_costs
        ),
        Risco_se_não_feito: TF(
          { label: language.RISKS },
          y.id_scale,
          'risk_if_not_done',
          y.risk_if_not_done
        ),
        Urgência_1: TF(
          { label: language.URGENCY },
          y.id_scale,
          'urgency',
          y.urgency
        ),
        Complexidade_1: TF(
          { label: language.COMPLEXITY },
          y.id_scale,
          'complexity',
          y.complexity
        ),
        areas_envolvidas_1: TF(
          { label: RP(language.INVOLVED_AREAS, /(Involved|Envolvidas)/) },
          y.id_scale,
          'involved_areas',
          y.involved_areas
        ),
        //-------importante manter esses empty para a tabela não ficar faltando------
        empty_11: '',
        empty_12: '',
      }));
    // ------------------------------------------------------------------------------------------------------
    data.push(...data_header_1);

    // ###########################################--cabeçalho_2 (estático)--#####################################################

    const header_2 = {
      ConfigCell: {
        className: 'title title_2',
        cols: {
          col_0: {
            colSpan: 14,
          },
        },
      },
      label: language.PRIORITIZATION_MATRIX + ' - ' + language.EVALUTION,
    };
    const divider_2 = {
      ConfigCell: {
        className: 'divider divider_2',
        cols: {
          col_0: {
            rowSpan: 2,
            style: {
              borderRight: '2px solid rgb(248, 249, 250)',
            },
          },
          col_1: {
            colSpan: 5,
            style: {
              borderRight: '2px solid rgb(248, 249, 250)',
            },
          },
          col_2: {
            colSpan: 5,
          },
          col_3: {
            rowSpan: 2,
          },
          col_4: {
            rowSpan: 2,
          },
          col_5: {
            rowSpan: 2,
          },
        },
      },
      total_peso: language.INITIATIVES,
      beneficios: language.BENEFITS,
      cc: language.COMPLEXITY + ' ' + language.AND + ' ' + language.COST,

      beneficios_2: language.BENEFITS,
      cc_2: language.COMPLEXITY + ' ' + language.AND + ' ' + language.COST,
    };
    data.push(header_2);
    data.push(divider_2);

    var col2 = Object.assign({}, {}, col);
    delete col2.empty_11;
    delete col2.empty_12;
    data.push(col2);

    // -------------------------------------dados dinâmicos----------------------------------------------

    //bem aqui

    const data_header_2 =
      projects != undefined &&
      projects.map((y) => {
        return {
          total_peso: (
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{y.name}</strong>
                </div>
              }
              arrow={true}
              position={'right'}
              theme="dark"
            >
              <span
                className="ellipsis"
                style={{ display: 'block', width: 100 }}
              >
                {y.name}
              </span>
            </Tooltip>
          ), // esse label não muda, é pra ficar estático
          //---------------dados abaixo são dinâmicos--------------------------
          Desejo_Diretoria: TF2(
            { label: RP(language.BOARD_DESIRE, /(Board|da Diretoria)/) },
            y.id_project,
            'desire_directors',
            y.desire_directors
          ),

          Alinhamento_Estrategico: TF2(
            {
              label: RP(language.STRATEGIC_ALIGMENT, /(Strategic|Estratégico)/),
            },
            y.id_project,
            'strategic_alignment',
            y.strategic_alignment
          ),

          Valor_Benefícios: TF2(
            { label: language.VALUE },
            y.id_project,
            'benefit_amount',
            y.benefit_amount
          ),

          Melhoria_processos: TF2(
            {
              label: RP(
                language.PROCESSES_IMPROVEMENT,
                /(Processes|Processos)/
              ),
            },
            y.id_project,
            'processes_improvement',
            y.processes_improvement
          ),

          Retorno_financeiro: TF2(
            { label: language.RETURN.replace('ar', 'o') },
            y.id_project,
            'financial_feedback',
            y.financial_feedback
          ),

          Gera_custos: TF2(
            { label: language.GENERATES_COSTS },
            y.id_project,
            'generates_costs',
            y.generates_costs
          ),

          Risco_se_não_feito: TF2(
            { label: language.RISKS },
            y.id_project,
            'risk_if_not_done',
            y.risk_if_not_done
          ),

          Urgência_1: TF2(
            { label: language.URGENCY },
            y.id_project,
            'urgency',
            y.urgency
          ),

          Complexidade_1: TF2(
            { label: language.COMPLEXITY },
            y.id_project,
            'complexity',
            y.complexity
          ),

          areas_envolvidas_1: TF2(
            { label: RP(language.INVOLVED_AREAS, /(Involved|Envolvidas)/) },
            y.id_project,
            'involved_areas',
            y.involved_areas
          ),

          //-------importante manter esses empty para a tabela não ficar faltando------

          empty_11: CALC(y) / 5 + '%',

          empty_12: CALC1(y) / 5 + '%',
        };
      });

    // ---------------------------------------------------------------------------------------------------
    data.push(...data_header_2);

    // ##########################################################################################################################

    return (
      <PageLayout
        icon={
          <div
            className="projectManIcon"
            style={{ width: '40px', height: '40px', margin: 0, left: 6 }}
          />
        }
        title={language.PROJECT_MANAGEMENT}
        subTitle={language.PROJECT_MANAGEMENT + '/'}
        subHeader={{
          title:
            language.PRIORITIZATION_MATRIX +
            ' (' +
            language.WEIGHT +
            ' & ' +
            language.EVALUTION +
            ')',
        }}
      >
        <div id="ProjectManagementPrioritizationList">
          <CommonTable
            search={false}
            bar={false}
            pagination={false}
            col={column}
            data={data}
          />
        </div>
      </PageLayout>
    );
  }
}
