import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import { MdHelp } from 'react-icons/lib/md';

const HelpPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const Help = ({ children }) => (
  <Tooltip
    position="bottom"
    theme="light"
    trigger="click"
    html={<div>{children}</div>}
  >
    <MdHelp fontSize={30} className='pointer' color='var(--primary)' />
  </Tooltip>
);

Help.propTypes = HelpPropTypes;

export default Help;
