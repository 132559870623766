import React from 'react';
import { observer } from 'mobx-react';
import { Card } from '@material-ui/core';
import CommonPage from '../../common/CommonPage';
import moment from 'moment';
import CommonTable from '../../common/CommonTable';
import Button from '../../common/Button';
import PageLayout from '../../common/PageLayout';
import CommonForm from '@common/CommonForm2';
import { green } from '@material-ui/core/colors';
import Store from '@data/Store';
import './DeviceTree.css';

@observer
export default class DeviceFormRelationshipLogs extends CommonPage {
  constructor() {
    super();
    this.state = {
      valuesLogs: [],
      table70: [],
      table80: [],
      totalLogs: 0,
      currentPage: 0,
      dtinicialLog: 0,
      dtfinalLog: 0,
      dtinicialOri: 0,
      dtfinalOri: 0,
      dtinicialOut: 0,
      dtfinalOut: 0,
      type_ori: 0,
      type: 0,
      relationshipsICs: [],
      totalRelationshipsICs: 0,
      filtersIC: {},
      currentPageIC: 0,
      relationshipTree: [],
      treeLoadMore: false,
      treeCurrentPage: 1,
    };
    this.getLogs = this.getLogs.bind(this);
    this.getStatusLog = this.getStatusLog.bind(this);
    this.GetRelationshipICs = this.GetRelationshipICs.bind(this);
  }

  getLogs(values = {}, page = 0, totalPerPage = 75, type) {
    this.setState({ currentPage: page });
    let filters = {
      dtinicialLog: this.state.dtinicialLog,
      dtfinalLog: this.state.dtfinalLog,
      dtinicialOri: this.state.dtinicialOri,
      dtfinalOri: this.state.dtfinalOri,
      dtinicialOut: this.state.dtinicialOut,
      dtfinalOut: this.state.dtfinalOut,
      type_ori: this.state.type_ori,
      type: this.state.type,
    };

    let limit = totalPerPage || 75;
    let offset = page * limit;

    if( this.props.values && this.props.values.id_device ) {
      this.props.action
        .execute(
          'post',
          `${this.APIEndpoints.DEVICE}/${this.props.values.id_device}/logs?limit=${totalPerPage}&offset=${offset}`,
          '',
          filters
        )
        .then((res) => {
          if( res.data ) {
            res = res.data;
          }

          if ( !res['code'] ) {
            const data = res?.map((data) => {
              return {
                dt_cad: moment(data.dt_cad).format('DD/MM/YYYY HH:mm'),
                type: data.type === 'tb_tickets' ? 'Ticket' : 'GMUD',
                id_ori: data.id_ori,
                numero: data.numero,
                status: this.getStatusLog(data.status),
                type_desc: this.getStatusLog(data.type_desc),
                name_ori: data.name_ori,
                dt_cad_ori: data.dt_cad_ori
                  ? moment(data.dt_cad_ori).format('DD/MM/YYYY HH:mm')
                  : '',
                dt_end_ori: data.dt_end_ori
                  ? moment(data.dt_end_ori).format('DD/MM/YYYY HH:mm')
                  : '',
                dt_start_outage: data.dt_start_outage
                  ? moment(data.dt_start_outage).format('DD/MM/YYYY HH:mm')
                  : '',
                dt_end_outage: data.dt_end_outage
                  ? moment(data.dt_end_outage).format('DD/MM/YYYY HH:mm')
                  : '',
              };
            });

            this.setState({ valuesLogs: data });
            this.setState({ totalLogs: parseInt(res[0]?.total_count) || 0 });
          }
        });
    }
  }

  getStatusLog(status) {
    const getStatus = {
      Create: Store.language.STATUS_LOG_CREATE,
      Open: Store.language.STATUS_LOG_OPEN,
      Analyze: Store.language.STATUS_LOG_ANALYZE,
      Rejected: Store.language.STATUS_LOG_REJECTED,
      Cancelled: Store.language.STATUS_LOG_CANCELLED,
      Rollback: Store.language.STATUS_LOG_ROLLBACK,
      Closed: Store.language.STATUS_LOG_CLOSED,
      Concluded: Store.language.STATUS_LOG_CONCLUDED,
      CAB: Store.language.STATUS_LOG_CAB,
      Finished: Store.language.STATUS_LOG_FINISHED,
      Progress: Store.language.STATUS_LOG_PROGRESS,
      Concluded_Fail: Store.language.STATUS_LOG_CONCLUDED_FAIL,
      Concluded_Partial: Store.language.STATUS_LOG_CONCLUDED_PARTIAL,
      Concluded_Cancel: Store.language.STATUS_LOG_CONCLUDED_CANCEL,
      Finish_Fail: Store.language.STATUS_LOG_FINISH_FAIL,
      Finish_Partial: Store.language.STATUS_LOG_FINISH_PARTIAL,
      Finish_Cancel: Store.language.STATUS_LOG_FINISH_CANCEL,
      Incident: Store.language.STATUS_LOG_INCIDENT,
      Problem: Store.language.STATUS_LOG_PROBLEM,
      Solicitation: Store.language.STATUS_LOG_SOLICITATION,
      Liberation: Store.language.STATUS_LOG_LIBERATION,
      Corretiva: Store.language.STATUS_LOG_CORRETIVA,
      Emergencia: Store.language.STATUS_LOG_EMERGENCIA,
      Normal: Store.language.STATUS_LOG_NORMAL,
      Padrao: Store.language.STATUS_LOG_PADRAO,
    };

    return getStatus[status];
  }

  GetRelationshipICs(values = {}, page = 0, totalPerPage = 75) {
    this.setState({ currentPageIC: page, filtersIC: values });
    let limit = totalPerPage || 75;
    let offset = page * limit;

    this.props.action
      .execute(
        'post',
        `${this.APIEndpoints.DEVICE}/${this.props.values.id_device}/getrelationshipic?limit=${totalPerPage}&offset=${offset}`,
        '',
        values
      )
      .then((res) => {
        this.setState({
          relationshipsICs: res.data?.map((r) => ({
            ic: r.name,
            type: r.type,
            date: moment(r.date).format('DD/MM/YYYY HH:mm:ss'),
          })),
        });
      });
  }

  GetRelationshipTree() {
    let limit = 75;
    let offset = (this.state.treeCurrentPage - 1) * limit;

    this.props.action
      .execute(
        'post',
        `${this.APIEndpoints.DEVICE}/${this.props.values.id_device}/getrelationshipic?limit=${limit}&offset=${offset}`,
        '',
        {
          "type": "Dependência"
        }
      )
      .then((res) => {
        this.setState((prevState) => ({
          relationshipTree: [
            ...prevState.relationshipTree,
            ...res.data?.map((r) => ({
              id_device: r.id_device,
              name: r.name,
              type: r.type,
              date: moment(r.date).format('DD/MM/YYYY HH:mm:ss'),
              currentPage: 1,
              showMore: false
            })),
          ],

          treeLoadMore: (res.data.length === 75),

          treeCurrentPage: prevState.treeCurrentPage + 1,
        }));
      });
  }

  componentWillMount() {
    if( this.props.values && this.props.values.id_device ) {
      this.getLogs();
      this.GetRelationshipICs();
      this.GetRelationshipTree();
    }

    this.props.action
      .execute('get', `${this.APIEndpoints.TABLE_STRUCTURED}/70`)
      .then((structure) => this.setState({ table70: structure.data }));

    this.props.action
      .execute('get', this.APIEndpoints.TABLE_STRUCTURED + '/30')
      .then((structure) => this.setState({ table80: structure.data }));
  }

  fetchAndAppendData = async (device) => {
    let limit = 75;
    let offset = (device.currentPage - 1) * limit;

    try {
      const res = await this.props.action.execute(
        'post',
        `${this.APIEndpoints.DEVICE}/${device.id_device}/getrelationshipic?limit=${limit}&offset=${offset}`,
        '',
        {
          "type": "Dependência"
        }
      );

      const children = res.data.map(child => ({
        ...child,
        currentPage: 1,
        showMore: false
      }));

      const updateChildrenRecursively = (devices) => {
        return devices.map((d) => {
          if (d.id_device === device.id_device) {
            return {
              ...d,
              currentPage: device.currentPage + 1,
              showMore: devices.length === 75,
              children,
            };
          } else if (d.children && d.children.length > 0) {
            return {
              ...d,
              children: updateChildrenRecursively(d.children),
            };
          }
          return d;
        });
      };

      this.setState((prevState) => {
        return {
          relationshipTree: updateChildrenRecursively(prevState.relationshipTree),
        };
      });
    } catch (error) {
      console.error('Erro ao buscar e adicionar dados:', error);
    }
  };

  renderDeviceChildren = (device) => {
    if (device.children && device.children.length > 0) {
      return (
        <ul>
          {device.children.map((child) => (
            <li key={child.id_device}>
              <div
                className="deviceInfos"
                onClick={() => this.fetchAndAppendData(child)}
              >
                <i className="icon-hub"></i>
                <h5>{child.name}</h5>

                {/*<div className="system-status">
                  <i className="icon-alert"></i>
                  01 problema
                </div>*/}
              </div>
              {this.renderDeviceChildren(child)}
            </li>
          ))}

          {device.loadMore && (
            <li onClick={() => this.fetchAndAppendData(child)}>
              <span className="load-more">+</span>
            </li>
          )}
        </ul>
      );
    }
    return null;
  };

  render() {
    let { language } = this.props;

    const arrayStatus = [
      { value: 5001, label: language.CORRECTIVE },
      { value: 5002, label: language.EMERGENCY },
      { value: 5003, label: language.NORMAL },
      { value: 5004, label: language.DEFAULT },
    ];

    const origemOption = this.state.table70.map((data) => {
      return {
        value: data.item_table,
        label: this.getStatusLog(data.description),
      };
    });

    const colLogs = [
      {
        key: 'dt_cad',
        label: language.DATA_LOG,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'type',
        label: language.ORIGIN_TABLE,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'id_ori',
        label: language.ORIGIN_ID,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'numero',
        label: language.ORIGIN_ID_REAL,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'status',
        label: language.STATUS,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'type_desc',
        label: language.ORIGIN_TYPE,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'name_ori',
        label: language.ORIGIN_NAME,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'dt_cad_ori',
        label: language.ORIGIN_INI_DATE,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'dt_end_ori',
        label: language.ORIGIN_FIM_DATE,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'dt_start_outage',
        label: language.OUTAGE_INI_DATE,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'dt_end_outage',
        label: language.OUTAGE_FIM_DATE,
        thConfig: true,
        style: { width: 120 },
      },
    ];

    return (
      <Card className="content">
        <CommonForm
          hiddenSubmit
          onSubmit={() => {
            this.setState({ currentPage: 0 });
            this.getLogs({}, 0);
          }}
          onChangeField={(f, fk_id) => {
            if (f.name == 'dtinicialLog') {
              this.setState({ dtinicialLog: fk_id });
            } else if (f.name == 'dtfinalLog') {
              this.setState({ dtfinalLog: fk_id });
            } else if (f.name == 'dtinicialOri') {
              this.setState({ dtinicialOri: fk_id });
            } else if (f.name == 'dtfinalOri') {
              this.setState({ dtfinalOri: fk_id });
            } else if (f.name == 'dtinicialOut') {
              this.setState({ dtinicialOut: fk_id });
            } else if (f.name == 'dtfinalOut') {
              this.setState({ dtfinalOut: fk_id });
            } else if (f.name == 'type_ori') {
              this.setState({ type_ori: fk_id });
            } else if (f.name == 'type') {
              this.setState({ type: fk_id });
            }
          }}
          fields={[
            {
              col: 1.2,
              name: 'dtinicialLog',
              type: 'date',
              label: language.LOG_INI_DATE,
              endIcon: 'fas fa-calendar-alt',
            },
            {
              col: 1.2,
              name: 'dtfinalLog',
              type: 'date',
              label: language.LOG_FIM_DATE,
              endIcon: 'fas fa-calendar-alt',
            },
            {
              col: 1.2,
              name: 'dtinicialOri',
              type: 'date',
              label: language.ORIGIN_INI_DATE,
              endIcon: 'fas fa-calendar-alt',
            },
            {
              col: 1.2,
              name: 'dtfinalOri',
              type: 'date',
              label: language.ORIGIN_FIM_DATE,
              endIcon: 'fas fa-calendar-alt',
            },
            {
              col: 1.2,
              name: 'dtinicialOut',
              type: 'date',
              label: language.OUTAGE_INI_DATE,
              endIcon: 'fas fa-calendar-alt',
            },
            {
              col: 1.2,
              name: 'dtfinalOut',
              type: 'date',
              label: language.OUTAGE_FIM_DATE,
              endIcon: 'fas fa-calendar-alt',
            },
            {
              col: 1.2,
              type: 'select',
              name: 'type',
              options: [
                { value: 'tb_tickets', label: language.TICKET },
                { value: 'tb_change', label: language.GMUD },
                { value: 'all', label: language.ALL },
              ],
              label: language.TABLE,
            },
            {
              col: 1.8,
              type: 'select',
              name: 'type_ori',
              options: origemOption.concat(arrayStatus),
              label: language.ORIGIN_TYPE,
              isMultiMin: true,
            },
            {
              col: 1,
              type: 'element',
              content: (v, h, submit) => (
                <Button
                  icon="fas fa-search"
                  color="success"
                  variant="normal"
                  label={language.SEARCH_FOR}
                  style={{
                    marginTop: 13,
                  }}
                  onClick={() => submit()}
                />
              ),
            },
          ]}
        />
        <CommonTable
          title={''}
          language={language}
          columns={colLogs}
          data={this.state.valuesLogs}
          isAutomaticPagination={false}
          paginationTop={false}
          width="100%"
          currentPage={this.state.currentPage}
          countTotal={this.state.totalLogs}
          searchColumnEvent={this.getLogs}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.getLogs(values, page, rowsPerPage, type)
          }
        />

        <CommonTable
          searchColumn
          title={`${language.RELATIONSHIP_WITH} ICs`}
          language={language}
          columns={[
            {
              key: 'ic',
              label: 'IC',
            },
            {
              key: 'type',
              label: language.TYPE,
            },
            {
              key: 'date',
              label: language.DATE,
            },
          ]}
          data={this.state.relationshipsICs}
          isAutomaticPagination={false}
          paginationTop={false}
          width="100%"
          currentPage={this.state.currentPageIC}
          countTotal={this.state.totalRelationshipsICs}
          searchColumnEvent={this.GetRelationshipICs}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.GetRelationshipICs(values, page, rowsPerPage, type)
          }
        />

        <ul className="treeList">
          <li>
            <div className="deviceInfos">
              <i className="icon-hub"></i>
              <h5>{this.props.values.name}</h5>

              {/*<div className="system-status">
                <i className="icon-check"></i>
                Funcionando
              </div>*/}
            </div>

            {this.state.relationshipTree.length > 0 && (
              <ul>
                {this.state.relationshipTree.map((device) => (
                  <li key={device.ic}>
                    <div
                      className="deviceInfos"
                      onClick={() => this.fetchAndAppendData(device)}
                    >
                      <i className="icon-hub"></i>
                      <h5>{device.name}</h5>

                      {/*<div className="system-status">
                        <i className="icon-alert"></i>
                        01 problema
                      </div>*/}
                    </div>
                    {this.renderDeviceChildren(device)}
                  </li>
                ))}

                {this.state.treeLoadMore && (
                  <li onClick={() => this.GetRelationshipTree()}>
                    <span className="load-more">+</span>
                  </li>
                )}
              </ul>
            )}
          </li>
        </ul>
      </Card>
    );
  }
}
