import Button from '../../common/Button';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Toggle } from '@material-ui/core';
import { Card, Chip } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import CommonForm from '@common/CommonForm2';
@observer
export default class SlaList extends Component {
  render() {
    let { language, handlePage, redirect, APIEndpoints, onFormSubmit } =
      this.props;

    let col = [
      { key: 'id', label: language.ID, style: { width: 10 } },
      { key: 'sla', label: language.SLA, style: { textAlign: 'center' } },
      {
        key: 'solutionTime',
        label: language.SOLUTION_TIME,
        style: { textAlign: 'center' },
      },
      {
        key: 'reponseTime',
        label: language.RESPONSE_TIME,
        style: { textAlign: 'center' },
      },
      {
        key: 'request',
        label: 'Sla Request',
        style: { textAlign: 'center' },
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' },
      },
    ];

    let data = this.props.store.slas.map((obj) => {
      return {
        id: obj.id_sla,
        sla: obj.name,
        solutionTime:
          obj.type == 'M'
            ? obj.time_solution + ' m'
            : obj.time_solution + ' hs',
        reponseTime:
          obj.type == 'M'
            ? obj.time_response + ' m'
            : obj.time_response + ' hs',
        request:
          obj.request == true ? (
            <Button
              variant="circle"
              icon="fas fa-check-square"
              label={language.SLA_ACTIVE_CATEGORIZATION}
              color="success"
              style={{ fontSize: 12 }}
              className="mr-2"
              size={0.7}
            />
          ) : (
            <Button
              variant="circle"
              icon="fas fa-check-square"
              label={language.SELECT}
              color="default"
              style={{ fontSize: 12 }}
              className="mr-2"
              size={0.7}
              onClick={() => onFormSubmit({ request: true, value: obj.id_sla })}
            />
          ),

        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    style={{ fontSize: 12 }}
                    className="mr-3"
                    color="primary"
                    label={language.HOLIDAYS}
                    size={0.7}
                    primary
                    onClick={() => handlePage('listHolidaysBySlaId', obj)}
                  />

                  <Button
                    variant="circle"
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12 }}
                    className="mr-2"
                    size={0.7}
                    onClick={() => handlePage('form', obj)}
                  />

                  <Button
                    variant="circle"
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    _loading={'delete-' + APIEndpoints.SLA + '/' + obj.id_sla}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
            {/*<Row>
              <Col md={6}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.EDIT}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="editIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('form', obj)}
                  />
                </Tooltip>
              </Col>
              <Col md={6}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.REMOVE}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="trashIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </Tooltip>
              </Col>
            </Row>*/}
          </React.Fragment>
        ),
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          index="SlaList"
          data={data}
          searchColumn
          orderColumn
          paginationTop={false}
          beforeBar2={configurationProps.control}
          beforeBar={
            <Button
              variant="circle"
              icon="fas fa-plus"
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
