import React, {useRef, useCallback, Fragment, useState, useEffect, memo} from 'react';
import moment from 'moment';
import Store from '@data/Store';
import Constants from '@data/Constants';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Card} from '@material-ui/core';
/*-----------------------common----------------------------------*/

/*-----------------------components----------------------------------*/
import Column from './Column';

/*
	-------------------------formato----------------------------
	let data = {
	  id:3240, //importante colocar o id da atividade aqui, é a key do loop e o id do drag-drop
	  avatares:[
	    {name:'Rodrigo', src:undefined},
	    {name:'Carlos', src:undefined},
	    {name:'Marcos', src:undefined},
	    {name:'Platão', src:undefined},
	    {name:'João', src:undefined},
	    {name:'Magno', src:undefined},
	  ],
	  tags:[
	    {name:'Sprint 4', color:'#13CBF0'},
	    {name:'Lead Up', color:'#F0B02B'},
	    {name:'UX/UI', color:'#F05E02'},
	    {name:'UX/UI 2', color:'var(--primary)'},
	  ],
	  name:'Lorem ipsum dolor sit ambt almee deluradem ipsum dolor sit ambt almee delurad.',
	  dt:'12/03/2012',
	  commentsTotal:12,
	  filesTotal:3,
	  percent:60,
	  percentColor:'var(--danger)',
	  
	};
*/

let stage = [
   'BACKLOG',
   'TODO',
   'DOING',
   'STOPPED',
   'VALIDATION',
   'DONE'
]

export default memo(({
   data=[],
   project,
   columnTheme,
   redirect
})=>{
	let refAliceCarousel = useRef(null);

   function onDelete(id){
      let activity = data.flatMap(e => e.data).find(e => e.id_project_activity==id)||{}
      if(activity.percent > 0){
         Store.toggleAlert(
            true,
            `Atividade em progresso: ${activity.percent}%. Favor, finalizar atividade!`,
            'error'
         );
      }else{
         _onDelete(id);
      }
   }
   
	return(
      <AliceCarousel
         startIndex={0}
      
         ref={refAliceCarousel}
         dotsDisabled
         buttonsDisabled
         fadeOutAnimation
         stagePadding={
            {
            // paddingRight : 18
            }
         }
         responsive={{
            0: {
               items: 5
            }
         }}
         infinite={false}
      >
         {data.map((e, i)=>(
            <Column 
               {...e} 
               onView={()=>null} 
               onEdit={(id, duplicate) => null}
               onDelete={id => onDelete(id, i)}
               onDrop={d=>null} 
               key={e.title} 
               index={i}
               redirect={redirect}
               project={project}
               columnTheme={columnTheme}
            />
         ))}
      </AliceCarousel>
	)
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
})