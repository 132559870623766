import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card, Chip, Grid } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import RaisedButton from '../../common/RaisedButton';
import Button from '@common/Button';
import classnames from 'classnames';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import Header from '@common/Header';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@common/Dialog';
import { Top10, Status, Types } from './Graph';
import CommonForm from '@common/CommonForm2';
import ReactHighcharts from 'react-highcharts';
import PageLayout from '../../common/PageLayout';
import CommonTable from '@common/CommonTable';
import moment from 'moment';
import CommonPage from '../../common/CommonPage';
import ReactTooltip from 'react-tooltip';
import Store from '@data/Store';
import Calendario from './Calendario/Calendario';
import DownloadFiles from '../../util/DownloadFiles';
const styles = (theme) => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '1000px',
  },
  dialogPaperAlert: {
    minHeight: '25vh',
    maxHeight: '25vh',
    minWidth: '500px',
  },
  dialogPaperAnalyze: {
    width: '300px',
    maxWidth: '90%',
    textAlign: 'center',
  },
  dialogPaper_: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '2000px',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

const actionsChange = {
  Create: 'open',
  Open: 'analyze',
  Finish: 'close',
  Progress: 'progress',
  Rejected: 'rejected',
};

@observer
class ChangeManagementList extends CommonPage {
  constructor() {
    super();
    this.state = {
      change: '',
      open: false,
      companyId: 0,
      search: '',
      page: 0,
    };
    this.handleChanges = this.handleChanges.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createGmud = this.createGmud.bind(this);
    this.ondonwFile_ = this.ondonwFile_.bind(this);
    this.ondeletefile = this.ondeletefile.bind(this);
    this.Open = this.Open.bind(this);
  }

  ondonwFile_(id, file, type) {
    DownloadFiles(this.APIEndpoints.CHANGE+ '/downloadchange/' + id + '&' + file, file)
  }

  ondeletefile(id,not){
    this.action.execute('get', `${this.APIEndpoints.CHANGE}/deletefile/${id}`, '');
    setTimeout(() => {
      this.redirect('/dash/changemanagement');
    }, 2000);
  }

  getUsers = (change) => {
    try {
      return (
        <Tooltip
          html={
            <span>
              {change.techs.length > 4
                ? change.techs.split(',').map((u, i) => (
                    <div
                      style={{
                        radius: 10,
                        marginLeft: '5px',
                        marginTop: '2px',
                      }}
                      className="d-flex align-items-center"
                    >
                      <Avatar
                        className={classnames('avatar')}
                        style={{
                          fontSize: '10px',
                          width: '17px',
                          height: '17px',
                          backgroundColor: 'orange',
                        }}
                      >
                        {u.substr(0, 1).toString().toUpperCase()}
                      </Avatar>
                      <span style={{ color: 'black', marginLeft: '4px' }}>
                        {u}
                      </span>
                    </div>
                  ))
                : null}
              {change.techs_.length > 4
                ? change.techs_.split(',').map((u, i) => (
                    <div
                      style={{
                        radius: 10,
                        marginLeft: '5px',
                        marginTop: '2px',
                      }}
                      className="d-flex align-items-center"
                    >
                      <Avatar
                        className={classnames('avatar')}
                        style={{
                          fontSize: '10px',
                          width: '17px',
                          height: '17px',
                          backgroundColor: 'orange',
                        }}
                      >
                        {u.substr(0, 1).toString().toUpperCase()}
                      </Avatar>
                      <span style={{ color: 'black', marginLeft: '4px' }}>
                        {u}
                      </span>
                    </div>
                  ))
                : null}
            </span>
          }
          className="h-100"
          position="left"
          theme="light"
          interactive
        >
          <div className="d-flex align-items-center">
            <i class="far fa-user mr-1" />
            {change.techs && change.techs.split(',')[0]}
          </div>
        </Tooltip>
      );
    } catch (e) {
      return '';
    }
  };

  handleChanges = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ search: value });
  };

  cloneGmud = (id) => {
    this.action
      .execute('get', `${this.APIEndpoints.CHANGE}/clone/${id}`, '');
    setTimeout(() => {
      this.redirect('/dash/changemanagement');
    }, 2000);
  };

  Open(change) {
    this.action
      .execute('post', `${this.APIEndpoints.CHANGE}/getAllChanges/?offset=0&limit=1`, '', { id_change_filter:change.id_change })
      .then((res) => {
        change = res.data[0];
        if (change.ac != null && change.acroll != null) {
          if (change.acdtnull == null) {
            this.handleChange(change);
          } else {
            this.store.toggleAlert(
              true,
              this.store.language.ACTIVITY_WITHOUT_START,
              'error'
            );
          }
        } else {
          this.store.toggleAlert(
            true,
            this.store.language.ACTIVITY_REGISTER,
            'error'
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }

  createGmud(change) {
    if (
      change.managerid === this.store.getUserLoged().id ||
      this.store.getUserLoged().role === 'client'
    ) {
        this.action
          .execute('post', `${this.APIEndpoints.CHANGE}/getAllChanges/?offset=0&limit=1`, '', { id_change_filter:change.id_change })
          .then((res) => {
            change = res.data[0];
            if (change.ac != null && change.acroll != null) {
              this.handleChange(change);
            } else {
              this.store.toggleAlert(
                true,
                this.store.language.REGISTER_ACTIVITIES_RISK,
                'error'
              );
            }
          });
     } else {
      this.store.toggleAlert(true, this.store.language.GMUD_MANAGER, 'error');
    }
  }

  changeStatus(opt) {
    let { change } = this.state;
    this.props.changeStatus(
      opt || actionsChange[change.status],
      change.id_change
    );
    this.setState({
      change: '',
      open: false,
    });
  }
  handleChange(value) {
    this.setState({ change: value });
    this.handleModal();
  }
  handlePageRedirect = () => {
    this.store.change = [];
    this.props.handlePage('confirm');
  };

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes } = this.props;
    let {
      changes,
      language,
      handlePage,
      redirect,
      contracts,
      all_milestones,
      amount_risks,
      companies,
      allChanges,
      changesCount,
      callPagination,
      callDataFilter,
      callPageSelectFilter,
      callSortItem,
      handleCompany,
      typeTemp,
      cleanFilters
    } = this.props;

    let { open, change } = this.state;
    let { CALENDAR } = this.store.theme;

    if (this.state.search != '') {
      changes = changes.filter((item) => {
        return (
          item.reason.toUpperCase().indexOf(this.state.search.toUpperCase()) !=
            -1 ||
          item.manager.toUpperCase().indexOf(this.state.search.toUpperCase()) !=
            -1
        );
      });
    }

    let confirmModal = (
      <Dialog
        modal={false}
        open={open}
        PaperProps={{ classes: { root: (change.status === 'Open' ? classes.dialogPaperAnalyze : classes.dialogPaperAlert) } }}
        miniVersion={change.status === 'Open' ? true : false}
        onClose={() => this.handleModal()}
        title={language.STATUS_CHANGE}
        maxWidth="xs"
      >
        {change.status === 'Open' ? (
          <Row>
            <Col md={12}>
              <RaisedButton
                onClick={() => this.changeStatus()}
                label={language.ANALYZE}
                primary={true}
                fullWidth
              />
            </Col>
          </Row>
        ) : change.status === 'Create' ? (
          <Row>
            <Col md={12}>
              <RaisedButton
                onClick={() => this.changeStatus()}
                label={language.OPEN}
                primary={true}
                fullWidth
              />
            </Col>
          </Row>
        ) : change.status === 'Rejected' ? (
          <Row>
            <Col md={6}>
              <RaisedButton
                onClick={() => this.changeStatus('cancel')}
                label={language.CANCEL}
                primary
                fullWidth
                color="danger"
              />
            </Col>
            <Col md={6}>
              <RaisedButton
                onClick={() => this.changeStatus('reopen')}
                label={language.REOPEN}
                primary={true}
                fullWidth
              />
            </Col>
          </Row>
        ) : change.status === 'Progress' ? (
          <Row>
            <Col md={6}>
              <RaisedButton
                onClick={() => this.changeStatus('finish')}
                label={language.FINALIZE}
                primary
                fullWidth
                color="danger"
              />
            </Col>
            <Col md={6}>
              <RaisedButton
                onClick={() => this.changeStatus('rollback')}
                label={language.ROLLBACK}
                primary={true}
                fullWidth
              />
            </Col>
          </Row>
        ) : change.status === 'Finish' ? (
          <Row>
            <Col md={12}>
              <RaisedButton
                onClick={() => this.changeStatus()}
                label={language.CLOSE}
                primary={true}
                fullWidth
              />
            </Col>
          </Row>
        ) : change.status === 'Rollback' ? (
          <Col md={12}>
            <RaisedButton
              onClick={() => this.changeStatus('finish')}
              label={language.FINALIZE}
              primary={true}
              fullWidth
            />
          </Col>
        ) : (
          ''
        )}
      </Dialog>
    );

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { textAlign: 'left', width: 60 },
        thConfig: true,
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: {
          textAlign: 'center',
          width: 1,
          paddingRight: 0,
          paddingLeft: 0,
        },
        thConfig: true,
      },
      {
        key: 'status',
        label: language.STATUS,
        style: {
          textAlign: 'center',
          width: 100,
          paddingRight: 0,
          paddingLeft: 0,
        },
        thConfig: true,
      },
      {
        key: 'type_action',
        label: language.TYPE_ACTION,
        style: { textAlign: 'left', width: 90 },
        thConfig: true,
      },
      {
        key: 'reason',
        label: language.REASON,
        style: { textAlign: 'left', width: 280 },
        thConfig: true,
      },

      {
        key: 'company',
        label: language.COMPANY,
        style: { width: 180, textAlign: 'left' },
        thConfig: true,
      },

      {
        key: 'manager',
        label: language.RESPONSIBLE,
        style: { textAlign: 'left', width: 280 },
        thConfig: true,
      },

      {
        key: 'start_date',
        label: language.INITIAL_DATE,
        style: { maxWidth: 80, textAlign: 'left' },
        thConfig: true,
      },
      {
        key: 'end_date',
        label: language.END_DATE,
        style: { maxWidth: 80, textAlign: 'left' },
        thConfig: true,
      },
      {
        key: 'type',
        label: language.TYPE,
        style: {
          textAlign: 'center',
          width: 140,
          paddingRight: 0,
          paddingLeft: 0,
        },
        thConfig: true,
      }

    ];

    if (this.state.companyId != 0) {
      changes = changes
        .filter((c) => c.fk_id_company === this.state.companyId)
        .map((c) => {
          return c;
        });
    }

    if (typeTemp == true) {
      allChanges = Array.isArray(allChanges) ? allChanges.filter((item) => item.template == true) : [];
    } else {
      allChanges = Array.isArray(allChanges) ? allChanges.filter((item) => item.template !== true) : [];
    }

    let data = allChanges.map((change, i) => ({
      id: change.id_change,
      actions: (
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              {['Open', 'Create', 'Analyze'].includes(change.status) && (
                <Button
                  variant="circle"
                  icon="fas fa-pencil-alt"
                  label={language.EDIT}
                  color="warning"
                  className="mx-1"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => this.props.handlePage('edit-complete', change)}
                  size={0.9}
                />
              )}

              {typeTemp == false ? (
                change.status === 'Analyze' ? (
                  <Button
                    variant="circle"
                    icon={
                      <div
                        className="analyzeIcon pointer cardIcon"
                        style={{ width: 17, height: 17 }}
                      />
                    }
                    label={language.ANALYZE}
                    color="light"
                    className="mx-1"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() =>
                      redirect(
                        '/dash/changemanagement/analyze',
                        change.id_change
                      )
                    }
                    size={0.9}
                  />
                ) : change.status === 'CAB' ? (
                  <Button
                    variant="circle"
                    icon={
                      <div
                        className="analyzeIcon pointer cardIcon"
                        style={{ width: 17, height: 17 }}
                      />
                    }
                    label={language.CAB_ANALYSIS}
                    color="light"
                    className="mx-1"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() =>
                      redirect(
                        '/dash/changemanagement/analyze',
                        change.id_change
                      )
                    }
                    size={0.9}
                  />
                ) : [
                    'Rollback',
                    'Progress',
                    'Finished',
                    'Cancelled',
                    'Rejected',
                    'Concluded',
                    'RejectCAB',
                    'Concluded_Fail',
                    'Concluded_Partial',
                    'Concluded_Cancel',
                    'Finish_Fail',
                    'Finish_Partial',
                    'Finish_Cancel',
                    'Revision_After_Implementation',
                    'Open',
                    'Create'
                  ].includes(change.status) ? (
                  <Button
                    variant="circle"
                    icon="fas fa-eye"
                    className="mx-1"
                    label={language.VISUALIZE}
                    color="primary"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() =>
                      redirect('/dash/changemanagement/view', change.id_change)
                    }
                    size={0.9}
                  />
                ) : null
              ) : null}

              {
                ['Open', 'Create', 'Analyze', 'Progress'].includes(
                  change.status
                ) &&
                (
                  this.store.getUserLoged().id === change.managerid ||
                  this.store.getUserLoged().role == 'client' ||
                  ( change.solicitant && change.solicitant.includes(this.store.getUserLoged().id) )
                ) && (
                  <Button
                    variant="circle"
                    icon="fas fa-ban"
                    label={language.CANCEL}
                    color="danger"
                    className="mx-1"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() =>
                      this.props.handlePage('remove-complete', change)
                    }
                    size={0.9}
                  />
                )}
            </div>
          }
        />
      ),
      status:
        typeTemp == false ? (
          <div>
            {change.status === 'Open' ? (
              <div
                onClick={
                  change.managerid == this.store.getUserLoged().id
                    ? () => {
                        this.Open(change);
                      }
                    : () =>
                        this.store.toggleAlert(
                          true,
                          language.GMUD_MANAGER,
                          'error'
                        )
                }
                className="openStatusIcon pointer cardIcon "
                data-tip={language.GMUD_STATUS.OPENED}
              />
            ) : change.status === 'Create' ? (
              <div
                onClick={() => this.createGmud(change)}
                data-tip={language.GMUD_STATUS.CREATED}
                className="createIcon pointer cardIcon"
              />
            ) : change.status === 'Rejected' ? (
              <div
                onClick={
                  change.managerid == this.store.getUserLoged().id
                    ? () => this.handleChange(change)
                    : () =>
                        this.store.toggleAlert(
                          true,
                          language.GMUD_MANAGER,
                          'error'
                        )
                }
                className="rejectedIcon pointer cardIcon"
                data-tip={language.GMUD_STATUS.REJECTED}
              />
            ) : change.status === 'Progress' ? (
              <div
                data-tip={language.PROGRESS}
                className="inProgressIcon pointer cardIcon"
              />
            ) : change.status === 'Concluded' ? (
              <div
                data-tip={language.GMUD_STATUS.CONCLUDED}
                className={'resolvedIcon pointer cardIcon'}
              />
            ) : change.status === 'Concluded_Fail' ? (
              <div
                data-tip={language.GMUD_STATUS.CONCLUDED_FAIL}
                className={'gpTodoIcon pointer cardIcon'}
              />
            ) : change.status === 'Concluded_Partial' ? (
              <div
                data-tip={language.GMUD_STATUS.CONCLUDED_PART}
                className={'gpTodoIcon pointer cardIcon'}
              />
            ) : change.status === 'Concluded_Cancel' ? (
              <div
                data-tip={language.GMUD_STATUS.CONCLUDED_CANCEL}
                className={'gpTodoIcon pointer cardIcon'}
              />
            ) : change.status === 'Finish_Fail' ? (
              <div
                data-tip={language.GMUD_STATUS.FINISHED_FAIL}
                className={'gpTodoIcon pointer cardIcon'}
              />
            ) : change.status === 'Finish_Partial' ? (
              <div
                data-tip={language.GMUD_STATUS.FINISHED_PARTIALLY}
                className={'gpTodoIcon pointer cardIcon'}
              />
            ) : change.status === 'Finish_Cancel' ? (
              <div
                data-tip={language.GMUD_STATUS.FINISHED_CANCEL}
                className={'gpTodoIcon pointer cardIcon'}
              />
            ) : change.status === 'Revision_After_Implementation' ? (
              <div
                data-tip={language.POST_IMPLEMENTATION_REVIEW}
                className="finishedStatusIcon cardIcon"
              />
            ) : change.status === 'Finished' ? (
              <div
                data-tip={language.GMUD_STATUS.FINISHED_SUCCESS}
                className="finishedStatusIcon cardIcon"
              />
            ) : change.status === 'Rollback' ? (
              <div
                onClick={
                  change.managerid == this.store.getUserLoged().id &&
                  parseInt(change.rollback_activities_missing) == 0
                    ? () => this.handleChange(change)
                    : parseInt(change.rollback_activities_missing) > 0
                    ? () =>
                        this.store.toggleAlert(
                          true,
                          language.ACTIVITY_PENDENT,
                          'error'
                        )
                    : () =>
                        this.store.toggleAlert(
                          true,
                          language.GMUD_MANAGER,
                          'error'
                        )
                }
                data-tip={language.ROLLBACK}
                className="rollbackStatusIcon pointer cardIcon"
              />
            ) : change.status === 'Cancelled' ? (
              <div
                data-tip={language.GMUD_STATUS.CANCELED}
                className="cancelledIcon cardIcon"
              />
            ) : change.status === 'Analyze' ? (
              <div
                data-tip={language.ANALYZING}
                className="analyzeStatusIcon cardIcon"
              />
            ) : change.status === 'CAB' ? (
              <div
                data-tip={language.CAB_ANALYSIS}
                className="analyzeStatusIcon cardIcon"
              />
            ) : (
              change.status
            )}
            <ReactTooltip />
          </div>
        ) : null,
      type_action:
        change.type_action == 1
          ? language.CORRECTIVE
          : change.type_action == 2
          ? language.EMERGENCY
          : change.type_action == 3
          ? language.NORMAL
          : language.DEFAULT,

      reason: (
        <div
          className="maxHeight bgStriped"
          dangerouslySetInnerHTML={{ __html: change.reason }}
        />
      ),
      company: change.company,
      manager: change.manager,

      start_date:
        change.start_date != null ? moment.utc(change.start_date).format('L') : '',
      end_date:
        change.end_date != null ? moment.utc(change.end_date).format('L') : '',
      type: (
        <div
          style={{
            borderRadius: '10px',

            background: change.type_gmud != 2 ? '#17a2b8 ' : '#F44336',

            color: 'white',
            padding: '2px',
          }}
        >
          {change.type_gmud != 2 ? language.COMPLETE : language.STANDARD}
        </div>
      ),
    }));

    let colors = [
      '#019FC2',
      '#02CBF0',
      '#02F0DE',
      '#02F079',
      '#A7F002',
      '#E7F002',
      '#F0B002',
      '#F05E02',
      '#EE0D81',
      '#019FC2',
      '#E7F002',
      '#02CBF0',
      '#02F0DE',
      '#02F079',
      '#A7F002',
    ];

    let chaves = allChanges.reduce(function (acc, curr) {
      if (!acc[curr.company]) {
        acc[curr.company] = 0;
      }
      acc[curr.company]++;
      return acc;
    }, []);

    let dadosGraficotop10 = Object.keys(chaves).map((v) => {
      return {
        name: v,
        y: chaves[v],
        color: colors[Math.floor(Math.random() * colors.length)],
      };
    });

    dadosGraficotop10.sort(function (a, b) {
      if (a.y > b.y) {
        return -1;
      }
      if (a.y < b.y) {
        return 1;
      }
      return 0;
    });
    dadosGraficotop10 = dadosGraficotop10.slice(0, 10);

    let chavesActions = allChanges.reduce(function (acc, curr) {
      if (!acc[curr.type_action]) {
        acc[curr.type_action] = 0;
      }
      acc[curr.type_action]++;
      return acc;
    }, []);

    let dadosGraficoTypes = Object.keys(chavesActions).map((v) => {
      return {
        name:
          v == 1
            ? language.CORRECTIVE
            : v == 2
            ? language.EMERGENCY
            : v == 3
            ? language.NORMAL
            : language.DEFAULT,
        y: chavesActions[v],
        color: colors[Math.floor(Math.random() * colors.length)],
      };
    });

    let chavesStatus = allChanges.reduce(function (acc, curr) {
      if (!acc[curr.status]) {
        acc[curr.status] = 0;
      }
      acc[curr.status]++;
      return acc;
    }, []);

    let dadosStatus = Object.keys(chavesStatus).map((v) => {
      return {
        y: chavesStatus[v],
        name: v === "Open" ?
          language.OPENED :
          v === "Analyze" ?
            language.TICKET_WORKFLOW_OF_CHANGE['IN_ANALYSIS'] :
          v === "Cancelled" ?
            language.CANCELED :
          v === "Progress" ?
            language.ON_PROGRESS :
          v === "Concluded" ?
            language.CONCLUDED :
          v === "Rejected" ?
            language.REJECTED :
          v === "Finished" ?
            language.FINISHED :
            v,
        color: colors[Math.floor(Math.random() * colors.length)],
      };
    });

    dadosStatus.sort(function (a, b) {
      if (a.y > b.y) {
        return -1;
      }
      if (a.y < b.y) {
        return 1;
      }
      return 0;
    });

    function dataTemplate(data) {
      const newData = data.map((d) => {
        delete d.status;
        return d;
      });
      return newData;
    }
    function colTemplate(data) {
      data.splice(2, 1);
      return data;
    }

    const statusOptions =  [{value : 1, label : language.GMUD_STATUS.CREATED},
    {value : 2, label: language.GMUD_STATUS.OPENED},
    {value: 3, label : language.ANALYZING},
    {value: 4, label : language.GMUD_STATUS.REJECTED},
    {value: 5, label : language.GMUD_STATUS.CANCELED},
    {value: 6, label : language.PROGRESS},
    {value: 7, label : language.GMUD_STATUS.FINISHED_SUCCESS},
    {value: 8, label : language.ROLLBACK},
    {value: 9, label : language.GMUD_STATUS.CLOSED},
    {value: 10, label : language.GMUD_STATUS.CONCLUDED},
    {value: 11, label : language.CAB_ANALYSIS},
    {value: 12, label : language.CAB_REJECTED},
    {value: 13, label : language.GMUD_STATUS.CONCLUDED_FAIL},
    {value: 14, label : language.GMUD_STATUS.CONCLUDED_PART},
    {value: 15, label : language.GMUD_STATUS.CONCLUDED_CANCEL},
    {value: 16, label : language.GMUD_STATUS.FINISHED_FAIL},
    {value: 17, label : language.GMUD_STATUS.FINISHED_PARTIALLY},
    {value: 18, label : language.GMUD_STATUS.FINISHED_CANCEL}]

    return (
      <>
        {typeTemp ? (
          this.state.page != 2 && (
            <div
              style={{
                marginTop: '-40px',
                marginLeft: '-50px',
                marginRight: '0',
              }}
            >
              <CommonTable
                paginationTop={false}
                countTotal={changesCount}
                searchColumn
                loadSearch={true}
                isAutomaticPagination={false}
                beginWithNoFilter={true}
                language={language}
                title={''}
                columns={colTemplate(col)}
                index="ChangeManagementList"
                data={dataTemplate(data)}
                //showHeaderToolbar={false}
                orderColumn
                callPageSelect={(page) => callPagination(page)}
                callDataFilter={(values) => callDataFilter(values)}
                callPageSelectFilter={(page, values, rowsPerPage, type) =>
                  callPageSelectFilter(page, values, rowsPerPage, type)
                }
                onSortItem={(item, sortByStatus) =>
                  callSortItem(item, sortByStatus)
                }
                onClearAllFilter={() => cleanFilters()}

                beforeBar={
                  <RaisedButton
                    circleButton
                    icon="fas fa-plus"
                    label={language.NEW_CHANGE}
                    onClick={() => this.handlePageRedirect()}
                  />
                }
                rowsPerPageOptions={[75]}
              />
            </div>
          )
        ) : (
          <PageLayout
            icon={<div className="changeManagementIcon titleIconSize" />}
            title={language.MANAGER_OF + ' ' + (typeTemp ? 'Template' : language.CHANGE)}
            subTitle={language.DASHBOARD_PAGE.DM + '/'}
            rightElements={
              !typeTemp && [
                <div
                  style={{ width: 1100, zoom: 0.8, marginTop: '1rem' }}
                  zoom={0.8}
                >
                  <CommonForm
                    hiddenSubmit
                    fields={[
                      {
                        col: 6,
                        type: 'autocomplete',
                        name: 'multiple_companies',
                        label: language.COMPANY,
                        method: 'POST',
                        route: `${this.APIEndpoints.COMPANY}/filter`,
                        fieldquery: 'search',
                        textlabel: language.COMPANY,
                        textinit: language.COMPANY,
                        fieldvaluedb: 'id_company',
                        fieldlabeldb: 'name',
                        idinit: 0,
                        routeAll: `${this.APIEndpoints.COMPANY}/all?`,
                        methodGetAll: 'POST',
                        routeGetCount: `${this.APIEndpoints.COMPANY}/count`,
                        isMulti : true
                      },
                      {
                        col: 6,
                        type : 'select',
                        name : 'multiple_status',
                        label : language.STATUS,
                        isMultiMin : true,
                        options : statusOptions
                      }
                    ]}
                    onChangeField={(field, value) =>
                      handleCompany && handleCompany(field.name,value)
                    }
                  />
                </div>,
              ]
            }
          >
            {typeTemp == false ? (
              <Header
                btnFilterProps={{
                  style: { width: 150 },
                }}
                btnFilter={[language.GMUD, language.DASHBOARD, `${language.CALENDAR} CAB`]}
                onChange={(index) => {
                  this.setState({ page: index });
                }}
                active={this.state.page}
              />
            ) : null}

            {this.state.page == 2 ? (
              <Card style={{ borderRadius: '0 0 4px 4px' }} className="content">
                <Calendario
                  theme={CALENDAR}
                  store={this.store}
                  redirect={this.redirect}
                  APIEndpoints={this.APIEndpoints}
                  language={language}
                  action={this.action}
                />
              </Card>
            ) : null}

            {this.state.page == 1 ? (
              <Card style={{ borderRadius: '0 0 4px 4px' }} className="content">
                <Grid container spacing={16}>
                  <Grid item xs={6} className="panel-b1-c1">
                    <Card className="h-100">
                      <AppBar title={`Top 10 ${language.COMPANIES}`} subTitle={''} />

                      <ReactHighcharts config={Top10({ dadosGraficotop10 })} />
                    </Card>
                  </Grid>

                  <Grid
                    item
                    xs={this.store.getUserLoged().role == 'solicitant' ? 6 : 3}
                    className="panel-b1-c1"
                  >
                    <Card className="h-100">
                      <AppBar title={language.GRAPH_BY_STATUS} subTitle={''} />

                      <ReactHighcharts config={Status({ dadosStatus })} />
                    </Card>
                  </Grid>

                  <Grid item xs={3} className="panel-b1-c1">
                    <Card className="h-100">
                      <AppBar
                        title={language.GRAPH_TYPE_ACTION}
                        subTitle={''}
                      />

                      <ReactHighcharts config={Types({ dadosGraficoTypes })} />
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            ) : null}

            {this.state.page != 2 && (
              <CommonTable
                paginationTop={false}
                countTotal={changesCount}
                searchColumn
                loadSearch={true}
                isAutomaticPagination={false}
                beginWithNoFilter={true}
                language={language}
                title={''}
                columns={col}
                index="ChangeManagementList"
                data={data}
                //showHeaderToolbar={false}
                orderColumn
                callPageSelect={(page) => callPagination(page)}
                callDataFilter={(values) => callDataFilter(values)}
                callPageSelectFilter={(page, values, rowsPerPage, type) =>
                  callPageSelectFilter(page, values, rowsPerPage, type)
                }
                onSortItem={(item, sortByStatus) =>
                  callSortItem(item, sortByStatus)
                }
                onClearAllFilter={() => cleanFilters()}

                beforeBar={
                  <RaisedButton
                    circleButton
                    style={{ marginRight: 15 }}
                    icon="fas fa-plus"
                    label={language.NEW_CHANGE}
                    onClick={() => this.handlePageRedirect()}
                  />
                }
                rowsPerPageOptions={[75]}
              />
            )}
            {confirmModal}
          </PageLayout>
        )}
      </>
    );
  }
}

ChangeManagementList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ChangeManagementList);
