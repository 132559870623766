import Store from '@data/Store';
import { lighten, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Constants from '../../data/Constants';

const crypto = require('crypto');
const secretKey = 'Orch3str0!@forever!';
const cipherAlgorithm = 'aes-256-cbc';

function handleError(e) {
  console.error(e);
  return {};
}

export const useServicePortalStyles = makeStyles((theme) => ({
  bgGray: {
    backgroundColor: '#f3f4f6'
  },
  text: {
    fontSize: 12,
  },
  textEmphasis: {
    fontSize: 13,
    fontWeight: 700
  },
  title: {
    fontSize: 14,
  },
  description: {
    fontSize: 11,
  },
  iconButton: {
    padding: 10,
    marginRight: 20
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    backgroundColor: '#f3f4f6',
    border: '1px solid transparent',
    '&:focus-within': {
      borderColor: '#e5e7eb'
    }
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: '100%'
  },
  searchIconButton: {
    padding: 10,
  },
  searchInputBase: {
    height: '100%'
  },

  sendRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f3f4f6',
    border: '2px solid transparent',
    '&:focus-within': {
      borderColor: theme.palette.grey[500]
    },
    //borderRadius: '9999px',
    '-webkit-transition': 'border 300ms ease-out',
    '-moz-transition': 'border 300ms ease-out',
    '-o-transition': 'border 300ms ease-out',
    'transition': 'border 300ms ease-out'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  appBar: {
    boxShadow: 'none',
    //height: 42,
  },
  appBarTabs: {
    minHeight: 42,

  },
  appBarTabIndicator: {
    backgroundColor: 'var(--primary-customer) !important',
  },
  appBarTab: {
    height: 42,
    fontSize: 12,

    '&.Mui-selected': {
      color: 'var(--primary-customer) !important',
    },
    '&:focus': {
      outline: 'none'
    }
  },
  badgeText: {
    height: 16,
    minWidth: 16,
    padding: '0 4px',
    fontSize: 10,
    lineHeight: '10px'
  },
  buttonSmallRoot: {
    height: 38,
  },
  buttonSmallLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.3rem',

    fontSize: '0.75rem'
  },
  table: {
    minWidth: 750,
    borderRadius: 4,
    overflow: 'hidden'
  },
  tableCell: {
    lineHeight: 'inherit'
  },
  tableSelected: {
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  chipStatus: {
    fontSize: 12,
    lineHeight: '14px'
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  menuItemSmall: {
    fontSize: 12,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  autoCompleteInput: {
    borderColor: 'var(--primary)'
  }
}));

export function encrypt(text) {
  try {
    const cipher = crypto.createCipher(cipherAlgorithm, secretKey);
    let cipheredText = cipher.update(text.toString(), 'utf8', 'base64');
    cipheredText += cipher.final('base64');
    return cipheredText;
  } catch (e) {
    return handleError(e);
  }
}

export function decrypt(text) {
  try {
    const decipher = crypto.createDecipher(cipherAlgorithm, secretKey);
    const cleanedText = text.replace(/\s/g, '');
    let decipheredText = decipher.update(cleanedText, 'base64', 'utf8');
    decipheredText += decipher.final('utf8');
    return decipheredText;
  } catch (e) {
    return handleError(e);
  }
}

export function appendStyle(id, css) {
  const styleNode = document.head.querySelector('#' + id);

  if (styleNode)
    document.head.removeChild(styleNode);

  const node = document.createElement('style');
  node.textContent = css;
  node.id = id;

  document.head.appendChild(node);
}

export function styleToString(style) {
  return Object.keys(style).reduce((acc, key) => (
    acc + key.split(/(?=[A-Z])/).join('-').toLowerCase() + ':' + style[key] + ';'
  ), '');
};

export function decodeData(user) {
  return decodeURIComponent(
    atob(user)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeData(base64);

  return JSON.parse(jsonPayload);
}

export const sortBy = (key) => {
  return (a, b) => {
    const aValue = a[key].normalize('NFD').toLowerCase();
    const bValue = b[key].normalize('NFD').toLowerCase();
    return aValue > bValue ? 1 : (bValue > aValue ? -1 : 0);
  };
  //return (a, b) => (a[key].normalize('NFD') > b[key].normalize('NFD')) ? 1 : ((b[key].normalize('NFD') > a[key].normalize('NFD')) ? -1 : 0);
};

export const sortByName = () => {
  return sortBy('name');
};

export function useDebounce(value, timeout) {
  const [state, setState] = useState(value)

  useEffect(() => {
    const tick = setTimeout(() => setState(value), timeout)

    return () => clearTimeout(tick)
  }, [value, timeout])

  if (timeout <= 0) return value
  return state
}

export function spFetch(url, method = 'get', body = '', options = { responseHeaders: false, responseStatus: false }) {

  let request = {
    method: method,
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json',
      'X-Access-Token': Store.getToken(),
    },
  };

  if (['POST', 'PUT'].includes(method.toUpperCase()))
    request.body = JSON.stringify(body)

  function tryParseJson(value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  return new Promise((resolve, reject) => {
    const timeout = 2 * 60 * 1000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    request.signal = controller.signal;

    fetch(url, request)
      .then(async response => {
        let dataResponse = {};
        dataResponse.data = await response.json();

        if (options.responseHeaders) {
          dataResponse.headers = {};
          for (const [key, value] of response.headers.entries())
            dataResponse.headers[key.toLocaleLowerCase()] = value
        }

        if (options.responseStatus)
          dataResponse.status = response.status;

        if (response.status >= 200 && response.status < 300)
          return Promise.resolve(dataResponse);
        else
          return Promise.reject(dataResponse);
      })
      .then(result => {
        return resolve(result);
      })
      .catch(error => {

        function getErrorResult(error) {
          return {
            model: 'errors',
            data: tryParseJson(error)
          }
        }

        if (typeof error === 'object' && error.then instanceof Function) {
          error.then((result) => reject(getErrorResult(result)));
        }
        else
          reject(getErrorResult(error));
      })
      .finally(() => {
        clearTimeout(id);
      })

  })
}

export function randomId() {
  return new Date().getTime().toString(20);
}


export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getStatusMap(language) {
  return {
    'Open': language.TICKET_WORKFLOW_OF_CHANGE.OPEN,
    'In Progress': language.TICKET_STATUS.IN_PROGRESS,
    'Aguardando': language.WAITING,
    'Reopened': language.TICKET_STATUS.REOPENED,
    'Resolved': language.TICKET_STATUS.RESOLVED,
    'Closed': language.TICKET_STATUS.FINALIZED,
    'Approve': language.TICKET_STATUS.IN_APPROVAL,
    'Approved': language.TICKET_STATUS.APPROVE,
    'Cancelled': language.TICKET_STATUS.CANCELLED,
  }
}


export const STATUS_COLOR_MAP = {
  'Open': '#4DA8DA',
  'In Progress': '#FFD166',
  'Aguardando': '#A2A2A2',
  'Reopened': '#FF7676',
  'Resolved': '#008000',
  'Closed': '#4E4E4E',
  'Approve': '#B07ACC',
  'Approved': '#8ACB88',
  'Cancelled': '#FFA8A8',

  // 'Open': '#654321',
  // 'In Progress': '#8B6C42',
  // 'Aguardando': '#8B4C39',
  // 'Reopened': '#4B0082',
  // 'Resolved': '#556B2F',
  // 'Closed': '#333333',
  // 'Approve': '#333333',
  // 'Approved': '#333333',
  // 'Cancelled': '#333333',
}

export const getTicketStatusValues = (language, ticketStatus, ticketStopValue) => {
  let status = ticketStatus;
  if (ticketStopValue > 1 && (['In Progress', 'Reopened'].includes(ticketStatus))
  ) {
    status = 'Aguardando'; // Não traduzir, é usado como chave de objeto
  }

  const label = getStatusMap(language)[status] || ticketStatus;
  const color = STATUS_COLOR_MAP[status];
  return { label, color }
}

export function getPriorityMap(language) {
  return {
    0: language.TICKET_PRIORITY.SMALL,
    1: language.TICKET_PRIORITY.MEDIUM,
    2: language.TICKET_PRIORITY.HIGH,
    3: language.TICKET_PRIORITY.CRITICAL
  }
}

export const PRIORITY_COLOR_MAP = {
  0: '#008000',//'#6B8E23',
  1: '#FFB46E',//'#DAA520',
  2: '#FFA500',//'#FF8C00',
  3: '#FF0000',//'#8B0000'
}


export const getPriorityIndicator = (ticketPriority, priorities) => {
  // if (!priorities.length) return 4;
  // if (ticketPriority === priorities[2]?.id_table) return 3;
  // if (ticketPriority === priorities[1]?.id_table) return 2;
  // if (ticketPriority === priorities[0]?.id_table) return 1;
  // return 4;

  if (!priorities.length) return 3;

  const priority = priorities.find(priority => priority.id_table === ticketPriority);
  return priority ? priorities.indexOf(priority) : 3;
}

export const getIndexByProperty = (ticketData, dataArray, property) => {
  const propertyId = ticketData.config[0].arraypr[property];
  if (!!dataArray.length) {
    const item = dataArray.find(item => item.id_table === propertyId);
    if (item) return dataArray.indexOf(item);
  }
  return 3;
};


export function getFileFromBase64({ fileType, fileBlob }) {
  const binaryBlob = atob(fileBlob);
  const byteArray = new Uint8Array(binaryBlob.length);
  for (let i = 0; i < binaryBlob.length; i++) {
    byteArray[i] = binaryBlob.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: fileType });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
}

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const extractUrls = (tagsString) => {
  const urls = {
    css: [],
    js: []
  };

  const linkTags = tagsString.match(/<link [^>]*href="[^"]+"[^>]*>/g) || [];
  linkTags.forEach(tag => {
    const match = tag.match(/href="([^"]+)"/);
    if (match) {
      urls.css.push(match[1]);
    }
  });

  const scriptTags = tagsString.match(/<script [^>]*src="[^"]+"[^>]*><\/script>/g) || [];
  scriptTags.forEach(tag => {
    const match = tag.match(/src="([^"]+)"/);
    if (match) {
      urls.js.push(match[1]);
    }
  });

  return urls;
};

export const injectCSS = (href) => {
  if (!document.querySelector(`link[href="${href}"]`)) {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.appendChild(link);
  }
};


export const injectScript = (src) => {
  if (!document.querySelector(`script[src="${src}"]`)) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }
};

export function getApprovalMap(language) {
  return {
    ticket: language.TICKET,
    [language.TICKET]: {
      label: language.TICKET,
      icon: 'fas fa-ticket-alt',
      color: '#2196F3',
      url: `${Constants.APIEndpoints.REQUEST}one`,
    },
    task: language.TASK2,
    [language.TASK2]: {
      label: language.TASK2,
      icon: 'fas fa-tasks',
      color: '#4CAF50',
      url: `${Constants.APIEndpoints.TASKS}`,
    },
    change: language.TICKET_TYPES.CHANGE,
    [language.TICKET_TYPES.CHANGE]: {
      label: language.TICKET_TYPES.CHANGE,
      icon: 'fas fa-exchange-alt',
      color: '#FF9800',
      url: `${Constants.APIEndpoints.CHANGE}`,
    },
  };
}

export const convertJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}
