import Actions from '@data/Actions';
import Constants from '@data/Constants';

// ----------------------------------------------------------------------

export const getHolidaysService = async () => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints;
  const { data } = await action.execute(
    'post',
    `${APIEndpoints.COMPANY_TIME}/holiday`,
    '',
    {
      list: true,
    }
  );
  const normalizedHolidays = data.map((holiday) => {
    const { data, id_holiday, name } = holiday;

    return { id: id_holiday, name, date: data };
  });

  return normalizedHolidays;
};

export const getHolidaysBySlaIdService = async (slaId) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints;
  const { data } = await action.execute(
    'get',
    `${APIEndpoints.SLA}/${slaId}/holidays`,
    ''
  );

  return data;
};

export const deleteHolidayService = async (slaId, holidayId) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints;

  return await action.execute(
    'delete',
    `${APIEndpoints.SLA}/${slaId}/holiday/${holidayId}`,
    ''
  );
};

export const postHolidaysSlaService = async (slaId, holidaysId) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints;

  return await action.execute(
    'post',
    `${APIEndpoints.SLA}/${slaId}/holidays`,
    '',
    holidaysId
  );
};
