import React, {memo} from 'react';
/*--------------------------common----------------------------*/
import Header from '@common/Header';
import Button from '@common/Button';
import SearchInput from '@common/SearchInput';
import Store from '@data/Store';

const { language } = Store;


export default memo(({onOpenModal, setSearch,filterSprints})=>{
	return(
		<Header
			btnFilter={[
				language.ALL,
				language.IN_PROGRESS,
				language.COMPLETED
			]}
			onChange={(i)=>filterSprints(i)}
		>
			
			<div className='d-flex align-items-center'>
				<Button
					icon='fas fa-plus'
					variant='circle'
					color='warning'
					label={language.ADD}
					className='mr-3'
					onClick={onOpenModal}
				/>
				<SearchInput onChange={evt => setSearch(evt.target.value)} />
			</div>
		</Header>
	)
})