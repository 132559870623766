
import React, { useState, useEffect, useReducer } from 'react';
import CommonForm from '@common/CommonForm2';
import { Grid } from '@material-ui/core';
import './List.css';
import moment from 'moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';
import MenuTooltip from '@common/MenuTooltip';
const crypto = require('crypto');
import TicketComment from '../tickets/TicketComment';

export const EditMultipleTickets = ({
  language,
  ticket,
  onNotesTicket,
  statusTicket,
  ticketsArray,
  ticketsDescArray,
  setModalClose,
  user,
  toggleAlert,
  loadNormal,
  answer,
  action,
  setticketsArray,
  setTicketsDescArray,
  APIEndpoints,
  onChangeStatus_Tab,
  store,
  params,
  onCommentTicket
}) => {
  let [formRef, setFormRef] = useState(null);
  let [formPrimaryRef, setFormPrimaryRef] = useState(null);

  let type_tickets = 'Solicitation';
  let url = window.location.href;
  let [time_, SetTime_] = useState('00:00:00');
  let [progress, setProgress] = useState(1);
  let [statusAwaiting, setStatusAwaiting] = useState([]);
  const [typeConfigAll, setTypeConfigAll] = useState('progress');
  const [priorities, setPriorities] = useState([]);
  const [urgencies, setUrgencies] = useState([]);
  const [urgency, setUrgency] = useState({});
  const [priority, setPriority] = useState({});
  let [registerTimePause, setRegisterTimePause] = useState({time_pause_restart: false,
    time_pause_restart_required: false});
  const [registerTimePauseValue, setRegisterTimePauseValue] = useState();
  let [companyOptions, setCompanyOptions] = useState([]);
  let [fornecedor, setFornecedor] = useState(false);
  let [loading, setLoading] = useState(false);
  let [refComments, setRefComments] = useReducer(
    (state, v) => [...state, v],
    []
  );

  const onNotesTicket_ = (id_tickets, values, index, time_) => {
    setLoading(true)
    onNotesTicket(0, values, index, time_, null, id_tickets).then(() => {
      setModalClose(false);
      setticketsArray([]);
      setTicketsDescArray([])
      loadNormal();
      setLoading(false)
    });
  };

  function decryp(aMsg) {
    var aSecret = 'Orch3str0!@forever!';
    try {
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);

      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }

  useEffect(() => {
    let is = JSON.parse(decryp(sessionStorage.getItem('priorities')));
    let isu = JSON.parse(decryp(sessionStorage.getItem('urgencies')));

    setPriorities(is);
    setUrgencies(isu);

    action
      .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
      .then((res) => {
        setStatusAwaiting(res.data);
      })
      .catch((error) => {
        console.error(error);
      })

    action
      .execute('get', APIEndpoints.COMPANY, 'companies')
      .then((item) => {
        let listCompanies = (item.data || []).map((itemc) => ({
          value: itemc.id_company,
          label: itemc.name,
        }));

        setCompanyOptions(listCompanies);
      }).catch((error) => {
        console.error(error);
      });
  }, []);

  const getWaitingTicket = async (id_tickets) => {
    return action
      .execute('get', `${APIEndpoints.TICKET + '/' + id_tickets}`)
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error fetching ticket:', error);
        return [];
      });
  }

  return (
    <Grid item xs={12}>
      <span id="descriptionTicket___">
        <CommonForm
          clean
          onRef={(r) => setFormPrimaryRef(r)}
          childFields={
            (formRef || {}).getFields
              ? formRef
                  .getFields()
                  .map((e) => ({ name: e.name, required: e.required }))
              : []
          }
          values={{type: typeConfigAll}}
          onChangeField={(f, v) => {
            if (f.name === 'type') {
              setTypeConfigAll(v);
            }
          }}
          fields={[
            {
              col: 12,
              type: 'element',
              name: 'tickets_selected',
              label: '',
              content: () => (
                <div style={{ marginTop: 10, marginBottom: 10}}>
                  <p>Tickets <b>{ticketsDescArray.join(',')}</b> selecionado(s)</p>
                </div>
              )
            },
            {
              col: 12,
              type: 'select',
              name: 'type',
              label: language.TYPE,
              options: [
                { value: 'progress', label: 'Progresso/Resolver' },
                { value: 'cancelled', label: language.CANCEL },
                { value: 'attendance', label: 'Colocar em Atendimento' },
                { value: 'start', label: 'Iniciar Atendimento' },
                { value: 'reason', label: 'Motivo do Status' },
                { value: 'chat', label: 'Notas Técnicas/Chat' },
                { value: 'type', label: 'Tipo Ticket' }
              ],
              required: true,
            },
            {
              col: 3,
              type: 'element',
              name: 'group-field',
              visible: typeConfigAll == 'progress',
              content: (v, handleValue) => (
                <div className="mt-2">
                  <CommonForm
                    onRef={(r) => setFormRef(r)}
                    individual
                    onChangeField={(f, v) => {
                      handleValue(v, f.name);
                      if (f.name == 're') {
                        formPrimaryRef.handleValue(
                          answer.filter((g) => g.id_answer === v)[0]
                            .description,
                          'notes'
                        );
                      } else if (f.name == 'notes') {
                        formPrimaryRef.handleValue(undefined, 'notes');
                      }
                    }}
                    values={{ progress }}
                    clean
                    fields={[
                      {
                        type: 'element',
                        name: 'progress',
                        disabled: true,
                        required: false,
                        content: (handleValue) => (
                          <div className="py-3 d-flex aling-items-center">
                            <MuiThemeProvider>
                              <Slider
                                value={progress ? progress : ''}
                                min={0}
                                max={100}
                                step={5}
                                aria-labelledby="label"
                                onChange={(evt, v) =>
                                  setProgress(v)
                                }
                              />
                            </MuiThemeProvider>
                            <span
                              className="ml-1"
                              style={{ marginTop: -9, fontSize: 13 }}
                            >
                              {parseInt(progress)}%
                            </span>
                          </div>
                        ),
                      },

                      params[0].time_sheet == true
                        ? {
                            type: 'element',
                            content: () => (
                              <h3 className="title-aba mb-4">{time_}</h3>
                            ),
                          }
                        : {
                            name: 'time',
                            label: `${language.TIME_SPENT}${
                              typeConfigAll === 'progress' ? '*' : ''
                            }`,
                            type: 'text',
                            required: false,
                            format: '##:##',
                            change(v) {
                              return v.formattedValue;
                            },
                          },
                      {
                        type: 'select',
                        name: 're',
                        label: language.AUTOMATIC_ANSWER,
                        options:
                          answer &&
                          answer.map((h) => ({
                            value: h.id_answer,
                            label: h.name,
                          })),
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              col: typeConfigAll == 'cancelled'  ? 12 : 9,
              type: 'textEditor',
              contentProps: { style: { marginTop: -29 } },
              className: 't1-description',
              name: 'notes',
              label: language.DESCRIPTION,
              visible: typeConfigAll == 'cancelled' || typeConfigAll == 'progress',
              required: true,
            },
            {
              col: 9,
              type: 'element',
              contentProps: { style: { marginTop: -29 } },
              className: 't1-description',
              name: 'notes',
              label: language.DESCRIPTION,
              visible: typeConfigAll == 'attendance',
              content: () => (
                <div style={{ marginTop: 10}}>
                  <p>Os tickets selecionados serão colocados <b>Em atendimento</b></p>
                </div>
              )
            },
            {
              col: 9,
              type: 'element',
              contentProps: { style: { marginTop: -29 } },
              className: 't1-description',
              name: 'notes',
              label: language.DESCRIPTION,
              visible: typeConfigAll == 'start',
              content: () => (
                <div style={{ marginTop: 10}}>
                  <p>Será iniciado a atividade dos ticket selectionados<b></b></p>
                </div>
              )
            },
            {
              col: 6,
              type: 'element',
              contentProps: { style: { marginTop: -29 } },
              className: 't1-description',
              name: 'notes',
              label: 'Prioridade',
              visible: typeConfigAll == 'type',
              content: () => (
                <div style={{ marginTop: 10, display: 'flex'}}>
                <p><b>Prioridade:</b> {priority.name}</p>
                <MenuTooltip
                  icon="fas fa-pencil-alt"
                  propsButton={{
                    style: {
                      fontSize: 12,
                      transform: 'rotate(0deg)',
                      width: 35,
                      height: 35,
                    },
                  }}
                  options={[
                    {
                      value: !!priorities.length
                        ? priorities[0].id_table
                        : 0,
                      icon: 'fas fa-angle-down',
                      label: language.TICKET_PRIORITY.SMALL,
                      color: 'var(--primary)',
                    },
                    {
                      value: !!priorities.length
                        ? priorities[1].id_table
                        : 0,
                      icon: 'fas fa-angle-right',
                      label:
                        language.TICKET_PRIORITY.MEDIUM,
                      color: 'var(--warning)',
                    },
                    {
                      value: !!priorities.length
                        ? priorities[2].id_table
                        : 0,
                      icon: 'fas fa-angle-up',
                      label: language.TICKET_PRIORITY.HIGH,
                      color: 'var(--danger)',
                    },
                    {
                      value: !!priorities.length
                        ? priorities[3].id_table
                        : 0,
                      icon: 'fas fa-angle-up',
                      label:
                        language.TICKET_PRIORITY.CRITICAL,
                      color: 'var(--danger)',
                    },
                  ]}
                  onChange={(e) => {
                    setPriority({name: e.label, id: e.value})
                  }}
                />
                </div>
              )
            },
            {
              col: 6,
              type: 'element',
              contentProps: { style: { marginTop: -29 } },
              className: 't1-description',
              name: 'notes',
              label: 'Urgência',
              visible: typeConfigAll == 'type',
              content: () => (
                <div style={{ marginTop: 10, display: 'flex'}}>
                  <p><b>Urgência:</b> {urgency.name}</p>
                  <MenuTooltip
                    icon="fas fa-pencil-alt"
                    propsButton={{
                      style: {
                        fontSize: 12,
                        transform: 'rotate(0deg)',
                        width: 35,
                        height: 35,
                      },
                    }}
                    options={[
                      {
                        value: !!urgencies.length
                          ? urgencies[0].id_table
                          : 0,
                        icon: 'fas fa-angle-down',
                        label: language.TICKET_PRIORITY.SMALL,
                        color: 'var(--primary)',
                      },
                      {
                        value: !!urgencies.length
                          ? urgencies[1].id_table
                          : 0,
                        icon: 'fas fa-angle-right',
                        label:
                          language.TICKET_PRIORITY.MEDIUM,
                        color: 'var(--warning)',
                      },
                      {
                        value: !!urgencies.length
                          ? urgencies[2].id_table
                          : 0,
                        icon: 'fas fa-angle-up',
                        label: language.TICKET_PRIORITY.HIGH,
                        color: 'var(--danger)',
                      },
                      {
                        value: !!urgencies.length
                          ? urgencies[3].id_table
                          : 0,
                        icon: 'fas fa-angle-up',
                        label:
                          language.TICKET_PRIORITY.CRITICAL,
                        color: 'var(--danger)',
                      },
                    ]}
                    onChange={(e) => {
                      setUrgency({name: e.label, id: e.value})
                    }}
                  />
                </div>
              )
            },
            {
              col: 12,
              type: 'element',
              name: 'group-field',
              visible: typeConfigAll == 'reason',
              content: () => (
                <div style={{ minHeight: 300 }}>
                  <CommonForm
                    language={language}
                    onChangeField={(f, v) => {
                      if (f.name == 'id_waiting') {
                        const statusConfig = statusAwaiting.find((status) => status.id_status === v);
                        setFornecedor(statusConfig.select_provider);
                        setRegisterTimePause(statusConfig)
                        setRegisterTimePauseValue(null)
                      } else if (f.name === 'fk_id_company_provider') {
                        setFornecedor(v);
                      } else if (f.name === 'time_pause_restart') {
                        setRegisterTimePauseValue(v)
                    }
                    }}
                    fields={[
                      {
                        col: 12,
                        name: 'id_waiting',
                        label: language.WAITING,
                        type: 'select',
                        options: (statusAwaiting || []).map((status) => ({
                          value: status.id_status,
                          label: status.status,
                        })),
                      },
                      {
                        col: 12,
                        type: 'select',
                        name: 'fk_id_company_provider',
                        label: language.COMPANY,
                        options: companyOptions,
                        visible: fornecedor,
                      },

                      {
                        col: 12,
                        type: 'text',
                        name: 'chamado',
                        label: language.CALL,
                        visible: fornecedor,
                        require: false,
                      },
                      {
                        col: 6,
                        type: 'date',
                        name: 'time_pause_restart',
                        label: 'Data limite',
                        visible: registerTimePause.time_pause_restart,
                        required: registerTimePause.time_pause_restart_required,
                      },
                      {
                        col: 6,
                        type: 'time',
                        name: 'time_pause_restart',
                        label: 'Hora limite',
                        visible: registerTimePause.time_pause_restart,
                        required: registerTimePause.time_pause_restart_required,
                      },
                    ]}
                    onSubmit={(values) => {
                      if (ticketsArray?.length > 0) {
                        ticketsArray.forEach(async(id_ticket) => {
                          const ticket = await getWaitingTicket(id_ticket)
                          if (ticket.config[0].stop_ticket !== values.id_waiting) {
                            const dataHoraAtual = new Date();
                            if (registerTimePause.time_pause_restart &&
                              (dataHoraAtual > values.time_pause_restart)) {
                              return store.toggleAlert(true, language.LIMIT_DATE_TIME_PAUSE_SLA, 'error');
                            } else {
                              if (ticket.status == 'Open') {
                                await onChangeStatus_Tab(id_ticket, { action: 'resolve' }, _);
                              }
                            }
                          }
                          let value = {}
                          value.time_pause_restart = registerTimePauseValue ? moment(registerTimePauseValue).format('YYYY-MM-DD HH:mm:ss') : registerTimePauseValue;
                          value.stop_ticket = values.id_waiting;
                          value.fk_id_company_provider = values.fk_id_company_provider;
                          value.chamado = values.chamado;
                          value.type = 'reason';
                          onNotesTicket_([id_ticket], value, url, type_tickets);
                        })
                      }
                    }}
                    button={{
                      md: 3,
                      offset: { md: 9 },
                      label: language.SAVE,
                      loading: loading,
                      primary: true,
                      style: { margin: 5 },
                    }}
                  />
                </div>
              )
            },
            {
              col: 12,
              type: 'element',
              name: 'group-field',
              visible: typeConfigAll == 'chat',
              content: () => (
                <Grid container spacing={1} id="block-2">
                  <Grid item xs={6}>
                    <div className="mb-2 d-flex align-items-center">
                      <i
                        className="fas fa-comment-dots mr-2"
                        style={{ fontSize: 14, color: 'var(--warning)' }}
                      />
                      <h3 className="title-aba">{language.CHAT}</h3>
                    </div>
                    <TicketComment
                      isReadonly={false}
                      language={language}
                      changecomponent={params[0].integration}
                      isMultipleSelection
                      onCommentTicket={(ticketsArray, values) =>
                        onCommentTicket(ticketsArray, values, 'solicitant')
                      }
                      maxHeightChat={250}
                      setRefComments={setRefComments}
                      hasTasksPending={false}
                      ticketsArray={ticketsArray}
                      />
                  </Grid>
                {params[0].tech_notes_disable == true
                  ? user.role != 'solicitant' &&
                    store.getUserLoged().id !== ticket.fk_id_user && (
                      <Grid item xs={6}>
                        <div className="mb-2 d-flex align-items-center">
                          <i
                            className="fas fa-comments mr-2"
                            style={{
                              fontSize: 14,
                              color: 'var(--warning)',
                            }}
                          />
                          <h3 className="title-aba">
                            {language.TECHNICAL_NOTES}
                          </h3>
                        </div>
                        <TicketComment
                          isReadonly={false}
                          language={language}
                          isMultipleSelection
                          onCommentTicket={(ticketsArray, values) =>
                            onCommentTicket(ticketsArray, values, 'tech')
                          }
                          setRefComments={setRefComments}
                          maxHeightChat={250}
                          hasTasksPending={false}
                          changecomponent= { params[0].integration }
                          ticketsArray={ticketsArray}
                        />
                      </Grid>
                    )
                  : user.role != 'solicitant' && (
                      <Grid item xs={6}>
                        <div className="mb-2 d-flex align-items-center">
                          <i
                            className="fas fa-comments mr-2"
                            style={{
                              fontSize: 14,
                              color: 'var(--warning)',
                            }}
                          />
                          <h3 className="title-aba">
                            {language.TECHNICAL_NOTES}
                          </h3>
                        </div>
                        <TicketComment
                          isReadonly={false}
                          language={language}
                          isMultipleSelection
                          onCommentTicket={(ticketsArray, values) =>
                            onCommentTicket(ticketsArray, values, 'tech')
                          }
                          setRefComments={setRefComments}
                          maxHeightChat={250}
                          hasTasksPending={false}
                          changecomponent = { params[0].integration }
                          ticketsArray={ticketsArray}
                        />
                      </Grid>
                    )}
                </Grid>
              )
            },
          ]}
          button={{
            md: 2,
            offset: { md: 10 },
            label: language.SAVE,
            loading: loading,
            primary: true,
            style: { margin: 10, display: typeConfigAll == 'reason' ||
            typeConfigAll == 'chat' ?  'none' : 'flex'},
          }}
          onSubmit={(values) => {
            switch (typeConfigAll) {
              case 'type':
                values.fk_id_priority = priority.id;
                values.fk_id_urgency = urgency.id;
                onNotesTicket_(ticketsArray, values, url, type_tickets);
                break;
              case 'cancelled':
                if (!values.notes || values.notes === '') {
                  toggleAlert(true, language.ENTER_DESCRIPTION, 'error');
                } else {
                  onNotesTicket_(ticketsArray, values, url, type_tickets);
                }
                break;
              case 'progress':
                if (!values.time) {
                  toggleAlert(true, language.REPORT_THE_TIME_SPENT, 'error');
                }
                setTimeout(() => {
                  if (time_ !== '00:00:00' && params[0].time_sheet) {
                    values.time = time_;
                  }
                  values.progress = progress;
                  onNotesTicket_(ticketsArray, values, url, type_tickets);
                }, 1000);
                break;
              case 'attendance':
                onNotesTicket_(ticketsArray, values, url, type_tickets);
                break;
              case 'start':
                onNotesTicket_(ticketsArray, values, url, type_tickets);
                break;
              default:
                break;
            }
          }}
        />
      </span>
    </Grid>
  );
};
