import React, { Component, useEffect } from 'react';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import CommonPage from '../../common/CommonPage';
import TD from './TD.js';
import { Tooltip } from 'react-tippy';
import CommonDelete from '../../common/CommonDelete';
import Dialog from '@common/Dialog';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '../../common/CommonForm2';
import DialogConfirm from '@common/DialogConfirm';
import ModalRename from './ModalRename.js';
import ModalExtend from './ModalExtend.js';
import './TabelaRisco.css';
import moment from 'moment';
import TicketManagement from '../../components/TicketManagement';
import ViewActivity from '../../components/Projects/Project/ViewActivity';
import RaisedButton from '@common/RaisedButton';
import Button from '@common/Button';
import CommonTable from '@common/CommonTable';

import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  ClickAwayListener,
  MenuList,
  Popper,
  Grow,
  Card,
  Chip
} from '@material-ui/core';

import PageLayout from '@common/PageLayout';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FilledInput,
  TextField
} from '@material-ui/core';
import AppBar from '@common/AppBar2';
import Animate from '@common/Animate';
import ProgressLine from '@common/ProgressLine';
import Progress from '@common/Progress';
import ButtonGroupFilter from '@common/ButtonGroupFilter';
//------------------------------------------------------------
// -----------------------------------------------------
import ContainerChart from './TabelaRisco.graph.js';
import ReactHighcharts from 'react-highcharts';
import Loading from '@common/Loading';
import RiskMonitoring from './RiskMonitoring'
import GeneralInfrastructureRisk from './GeneralInfrastructureRisk'
// ---------------------------------------------------------(COMPONENTE - TabelaRisco)---------------------------------------------------------------------------
function awaitRedirect(status, callback = () => null) {
  useEffect(() => {
    if (!status) {
      callback();
    }
  }, [!!status]);
  return null;
}
class TabelaRisco extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      modal: 0,
      form: '',
      ticketHistory: null,
      value: null,
      companyId: 0,
      modaldelete: false,
      idProblem: 0,
      company: 0,
      id_matriz_risk: 0,
      edit: null,
      idproject: 0,
      index: null,
      riskVisionIndex: 0,
      id_matriz_planning: 0,
      not: true,
      probability: 2,
      impacty_: 5,
      safePilar: '',
      approveModal: null,
      tipo: 1,
      tbody: [
        {
          item: [
            [
              { name: 'Desenv', rowspan: 2 },
              { name: 'Desenv1', rowspan: 1 },
              { name: 'Desenv2', rowspan: 1 },
              { name: 'preocupa', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Baixo', rowspan: 1 }
            ],

            [
              { name: 'Desenv1', rowspan: 1 },
              { name: 'Desenv2', rowspan: 1 },
              { name: 'preocupa', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'Desenv3', rowspan: 1 },
              { name: 'AlTO', rowspan: 1 }
            ]
          ]
        }
      ],
      ModalRename: false,
      ModalExtend: false,
      ModalRenameData: { cord: {}, el: { name: '' } },
      ModalExtendData: { cord: {}, countFields: 0, fields: [] },
      viewOpen: {},
      dropdown: false,
      ticketsAssoc: [],
      departmentOptions : []
    };

    this.openModal = this.openModal.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitPlan = this.onFormSubmitPlan.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.update = this.update.bind(this);
    this.status = this.status.bind(this);
    this.risk = this.risk.bind(this);
    this.setIndex = this.setIndex.bind(this);
    this.saveApprove = this.saveApprove.bind(this);
    this.onDelete_ = this.onDelete_.bind(this);

  }

  onChangeField(field, value) {

    if (field.name === 'fk_id_risk_problem') {
      this.setState({
        idProblem: value,
        not: false
      });
    } else if (field.name === 'fk_id_company') {
      this.setState({
        company: value,
        not: false
      });
      this.action.execute('get', this.APIEndpoints.DEPARTMENT + '/' + value, '').then(v => {
        let departments = v.data.map(d => ({
          value: d.id_department,
          label: d.name,
        }))
        this.setState({ departmentOptions : departments})

      })

    } else if (field.name === 'fk_id_project') {
      this.setState({
        idproject: value,
        not: false
      });
    } else if (field.name == 'safety_pillar') {
      this.setState({ safePilar: value, not: false });
    }
    else if (field.name == 'tipo') {
      this.setState({ tipo: value });
    }



    //tipo
  }

  onSubmitActivitieAntecessor(values) {
    this.action.execute(
      'put',
      Constants.APIRoot + '/api/project/activityantecessor',
      'project',
      values,
      'id_project'
    ).then(e => {
      onView(values.id_project_activity, e.data);
    });
  }
  onUpdate(type) {
    this.action
      .execute('post', this.APIEndpoints.PROJECT + '/sprint/kaban', '', {
        id_project_sprint: id_project_sprint
      }, null, null, false)
      .then(v => {

        setSprintKanban({ sprintkaban: [v.data] });

        if (type == 1) {
          setViewOpen(vo => ({
            project: vo.project,
            activity: v.data.ac.find(e => e.id_project_activity == vo.activity.id_project_activity)
          }))
        }
      });
  }
  onSubmitComent(values) {
    action.execute(
      'post',
      this.state.APIEndpoints.PROJECT + '/comments',
      'project',
      values,
      null,
      { btn_loading: 'comments' }
    ).then(e => {

      onView(values.fk_id_project_activity, e.data)
    })
  }
  setIndex(e) {
    this.setState({ index: e.index });
  }
  handleCompany(values) {
    this.setState({ companyId: values });
  }
  handleModal = (type = 0, id_matriz_risk, idcompany, edit, id_matriz_planning) => {

    if (type == 3) {
      this.getAllMatrizPlanningTicket({ id_matriz_risk: id_matriz_risk })
    }

    this.setState({
      modal: type,
      id_matriz_risk: id_matriz_risk,
      company: idcompany,
      id_matriz_planning: id_matriz_risk,
      edit: edit,
      not: true
    });
  };

  onDelete_(values) {

    this.action
      .execute(
        'post',
        this.APIEndpoints.MATRIZ + 'remove',
        '',
        values,
        ''
      )
      .then(g => {
        values.id_matriz_risk = this.state.id_matriz_risk;
        this.getAllMatrizPlanningTicket(values);


      })

  };

  onDelete() {
    let { edit } = this.state;

    this.action
      .execute(
        'post',
        this.APIEndpoints.MATRIZ + 'remove',
        'matriz',
        edit,
        'id_mail'
      )
      .then(g => {
        this.action.execute(
          'get',
          this.APIEndpoints.MATRIZ + 'getmatriz',
          'matriz'
        );



        this.action.execute(
          'get',
          this.APIEndpoints.MATRIZ + 'getmatrizplanning',
          'matrizplanning'
        );
      });
    this.redirect('/dash/gri');
    this.setState({ modal: 0 });
  }



  getAllMatrizPlanningTicket(values) {


    this.action
      .execute(
        'post',
        this.APIEndpoints.MATRIZ + 'AllMatrizPlanningTicket',
        '',
        values
      )
      .then(e => {
        this.setState({ ticketsAssoc: e.data })

      }).catch(e => this.handleModal());

  }


  onFormSubmitTickets(values) {
    values.fk_id_company = this.state.company;
    values.id_matriz_planning = this.state.id_matriz_planning;

    this.action
      .execute(
        'post',
        this.APIEndpoints.MATRIZ + 'saveplanningticket',
        'matrizplanning',
        values
      )
      .then(e => {
        this.action.execute('get', this.APIEndpoints.TICKET + '/findticketall', 'tickets');

        this.getAllMatrizPlanningTicket({ id_matriz_risk: this.state.id_matriz_planning })

      }).catch(e => this.handleModal());

  }


  onFormSubmitPlan(values) {
    values.fk_id_company = this.state.company;
    values.fk_id_matriz_risk = this.state.id_matriz_risk;

    values.probability_change = this.state.probability;
    values.impact_change = this.state.impacty_;
    this.action
      .execute(
        'post',
        this.APIEndpoints.MATRIZ + 'saveplanning',
        'matrizplanning',
        values
      )
      .then(e => {
        this.action.execute(
          'get',
          this.APIEndpoints.MATRIZ + 'getmatriz',
          'matriz'
        );


        this.handleModal()
      }).catch(e => this.handleModal());

  }

  onFormSubmit(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.MATRIZ + 'savematriz',
      'matriz',
      values
    ).then(e => this.handleModal()).catch(e => this.handleModal())

  }

  openModal(open, form, values) {
    this.setState({ modal: open, form: form, value: values });
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // usage example:

  componentDidMount() {
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');

    this.action.execute(
      'get',
      this.APIEndpoints.DEPARTMENT + '/department',
      'departments'
    );
    this.store.projects = [];

    this.action.execute('get', this.APIEndpoints.PROJECT, 'project');
    this.action.execute('get', this.APIEndpoints.TICKET + '/findticketall', 'tickets');
    //this.action.execute('get', this.APIEndpoints.TICKET+'/findticketall', 'ticket');


    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/ticketFind',
        'ticketlist',
        [],
        null,
        null,
        null
      )
      .then(g => {
        //  setTickets(g.data);
      });

    this.action.execute(
      'get',
      this.APIEndpoints.RISK + '/projects/',
      'activity'
    );

    this.action.execute(
      'get',
      this.APIEndpoints.MATRIZ + 'getmatriz',
      'matriz'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.MATRIZ + 'getmatrizplanning',
      'matrizplanning'
    );
  }
  saveApprove() {

    let i = this.state.approveModal
    i.approve = true;

    if (i.fk_id_approver == this.store.getUserLoged().id) {



      this.action.execute(
        'post',
        this.APIEndpoints.MATRIZ + 'saveplanning',
        'matrizplanning',
        i
      );
    } else {
      this.store.toggleAlert(true, language.OPERATION_NOT_PERFORMED_ONLY_THE_APPROVER_CAN_PERFORM_THIS_ACTION)
    }
    this.setState({ approveModal: null })
  }

  update(tipo, values) {
    if (tipo == 'matriz') {
      this.action.execute(
        'post',
        this.APIEndpoints.MATRIZ + 'upmatrizpunc',
        'matriz',
        values
      );
    } else {
      this.action.execute(
        'post',
        this.APIEndpoints.MATRIZ + 'upmatrizplanpunc',
        'matrizplanning',
        values
      );
    }

    ///Route.post('/core/matriz/upmatrizpunc', 'MatrizController.updateMatrizPunc')
    //Route.post('/core/matriz/upmatrizplanpunc', 'MatrizController.updateMatrizPlanPunc')
  }
  risk(t) {
    let { language} = this.store;
    let count = t.probability + t.impact;
    if (count < 2) {
      return {
        value: count,
        color: 'var(--success)',
        label: language.ACCEPTABLE
      };
    } else if (count >= 2 && count <= 4) {
      return {
        value: count,
        color: 'var(--success)',
        label: language.ACCEPTABLE
      };
    } else if (count >= 5 && count <= 6) {
      return {
        value: count,
        color: 'var(--warning)',
        //label: 'Crítico'
        label: language.MEDIUM
      };
    } else if (count >= 7 && count <= 8) {
      return {
        value: count,
        color: 'var(--danger)',
        //label: 'Intolerável'
        label: language.HIGH
      };
    } else {
      return {
        value: count,
        color: 'var(--success)',
        //label: 'Aceitável'
        label: language.LOW
      };
    }
  }

  status(t) {
    let { activity } = this.store;
    let status = 0;
    try {
      if (activity.filter(g => g.id_project_activity == t.fk_id_activity)) {
        activity
          .filter(g => g.id_project_activity == t.fk_id_activity)
          .map(g => {
            status = g.percent;
          });
      }
    } catch (e) { }
    return status;
  }
  toggleModalRename = cord => {
    var data = this.state.ModalRenameData;
    if (!!cord) {
      data = {
        cord,
        el: this.state.tbody[cord.tbody].item[cord.tr][cord.td]
      };
    }
    this.setState(state => ({
      ModalRename: !state.ModalRename,
      ModalRenameData: data
    }));
  };
  toggleModalExtend = cord => {
    var data = { cord: {}, countFields: 0, fields: [] };

    if (!!cord) {
      var countFields = this.state.tbody[cord.tbody].item[cord.tr].length - 1;

      data = {
        cord,
        countFields,
        fields: []
      };
    }
    this.setState(state => ({
      ModalExtend: !state.ModalExtend,
      ModalExtendData: data
    }));
  };
  toggleDropdown = () => {
    this.setState(state => ({
      dropdown: !state.dropdown
    }));
  };
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ dropdown: false });
  };
  handlePage() {
    this.setState({ modal: 0 });
  }
  render() {
    var dropdown = classnames({
      open: this.state.dropdown
    });
    let {
      departments,
      language,
      matriz,
      users,
      companies,
      matrizplanning,
      project,
      activity,
      tickets,
      ticketlist
    } = this.store;
    let { form, edit, not, modal, company, idproject, viewOpen, departmentOptions } = this.state;

    let optionsprojeto = [];
    let optionsactivity = [];
    let ma = matrizplanning;

    let button = { md: 12, label: language.SAVE, primary: true };
    let fields = [];

    let arrayPilar = ['C', 'D', 'I'];
    if (this.state.index == 0) {
      ma = ma.filter(g => g.safety_pillar.indexOf('C'));
    } else if (this.state.index == 1) {
      ma = ma.filter(g => g.safety_pillar.indexOf('D'));
    } else if (this.state.index == 2) {
      ma = ma.filter(g => g.safety_pillar.indexOf('I'));
    }

    let values = [];
    if (edit) {
      if (not) {
        values = edit;
      }
    }



    let companyOptions = this.store.companies.map(obj => ({
      value: obj.id_company,
      label: obj.name
    }));

    if (company && !!companies.length) {
      optionsprojeto = project.map(obj => ({
        value: obj.id_project,
        label: obj.name
      }));
      if (idproject > 0) {
        /*console.log(activity
          .filter(g => g.id_project === idproject))*/
        optionsactivity = activity
          .filter(g => g.id_project === idproject)
          .map(obj => ({
            value: obj.id_project_activity,
            label: obj.name
          }));
      }

     /* departmentOptions = [];
      this.store.companies
        .filter(c => c.id_company == company)[0]
        .department.map(obj => {
          departmentOptions.push({
            value: obj.id_department,
            label: obj.name
          });
          if (obj.sub_dep.length) {
            obj.sub_dep.map(sd => {
              departmentOptions.push({
                value: sd.id_department,
                label: sd.name
              });
            });
          }
        });*/
    }

    return (

      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        <Animate>
          <PageLayout
            icon="bitdefenderIcon"
            title={language.RISK_MATRIX}
            subTitle={language.RISKS_MANAGEMENT_COMPLIANCE + '/'}
            rightElements={[]}
          >
            <div id="risk_matrix">
              {this.state.riskVisionIndex === 1 &&
                <Card className='border-radius-10 mb-2'>
                  <AppBar title={language.RISK_MATRIX}>
                    <ButtonGroupFilter
                      items={[
                        language.DETAILED_VIEW,
                        language.MANAGEMENT_VISION
                      ]}
                      itemsProps={{
                        style: {
                          textTransform: 'uppercase',
                          fontSize: 10
                        }
                      }}
                      active={1}
                      onClick={e => {
                        this.setState({ riskVisionIndex: e.index })
                      }}
                    />
                  </AppBar>
                  <div className='content'>
                    <GeneralInfrastructureRisk
                      matriz={matriz}
                      users={users}
                      companies={companies}
                      matrizplanning={matrizplanning}

                      language={language} />
                    <RiskMonitoring
                      matriz={matriz}
                      users={users}
                      companies={companies}
                      matrizplanning={matrizplanning}

                      language={language} />
                  </div>
                </Card>
              }
              {this.state.riskVisionIndex === 0 &&

                <div id="content_table_risk_matrix" className="mb-3">
                  <CommonTable
                    title={''}
                    searchColumn
                    clearFilters={false}
                    print={false}
                    search={true}
                    paginationTop={false}
                    AppBarProps={{
                      className: 'py-3'
                    }}
                    beforeBar={
                      <RaisedButton
                        circleButton
                        icon='fas fa-plus'
                        style={{zoom:.8, color: 'white'}}
                        zoom={.8}
                        className='mr-3'
                        color='warning'
                        primary={true}
                        label={language.ADD}
                        onClick={() => this.handleModal(1)}
                        />
                    }

                    afterBar2={
                      <div>
                        <div
                          style={{ position: 'relative', top: 14 }}
                          className="mr-5"
                        >
                          <ButtonGroupFilter
                            items={[
                              language.DETAILED_VIEW,
                              language.MANAGEMENT_VISION
                            ]}
                            itemsProps={{
                              style: {
                                textTransform: 'uppercase',
                                fontSize: 10
                              }
                            }}
                            active={0}
                            onClick={e => {
                              this.setState({ riskVisionIndex: e.index })
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            top: 16,
                            right: 16
                          }}
                        >
                        </div>
                      </div>
                    }

                    col={[
                      {
                        key: 'safety_pillar',
                        label: language.SAFETY_PILAR,
                        thConfig: true,
                        style: { width: '4%' }
                      },
                      {
                        key: 'action',
                        label: language.ACTION,
                        thConfig: true,
                        style: { width: '4%' }
                      },
                      {
                        key: 'id',
                        label: 'Id',
                        thConfig: true,
                        style: { width: '4%' }
                      },
                      {
                        key: 'name',
                        label: language.NAME,
                        thConfig: true,
                        style: { width: '9%' }
                      },
                      {
                        key: 'bussines',
                        label: language.BUSINESS_AREA,
                        thConfig: true,
                        style: { width: '9%' }
                      },
                      {
                        key: 'responsible',
                        label: language.RESPONSIBLE,
                        thConfig: true,
                        style: { width: '9%' }
                      },
                      {
                        key: 'launch_date',
                        label: language.RELEASE_DATE_OF,
                        thConfig: true,
                        style: { width: '9%' }
                      },
                      {
                        key: 'review',
                        label: language.REVIEW,
                        thConfig: true,
                        style: { width: '1%' }
                      },
                      {
                        key: 'asset_type',
                        label: language.ASSET_TYPE,
                        thConfig: true,
                        style: { width: '9%' }
                      },
                      {
                        key: 'risk_description',
                        label: language.RISK_DESCRIPTION,
                        thConfig: true,
                        style: { width: '34%' }
                      },
                      {
                        key: 'probability',
                        label: language.PROBABILITY,
                        thConfig: true,
                        style: { width: '1%' }
                      },
                      {
                        key: 'impact',
                        label: language.IMPACT,
                        thConfig: true,
                        style: { width: '1%' }
                      },
                      {
                        key: 'punctuation',
                        label: language.PUNCTUATION,
                        thConfig: true,
                        style: { width: '11%' }
                      },
                      {
                        key: 'Risk',
                        label: language.RISKS,
                        thConfig: true,
                        style: { width: '11%' }
                      }
                    ]}
                    data={matriz.map(e => ({
                      ConfigCell: {
                        cols: {
                          col_0: {
                            safety_pillar: e.safety_pillar[0]
                          }
                        }
                      },

                      safety_pillar: e.safety_pillar.join(' '),
                      action: (
                        <div className="d-flex align-items-center justify-content-between">
                          {!e.fk_id_matriz_risk ? (
                            <i
                              class="fas fa-plus"
                              onClick={() =>
                                this.handleModal(
                                  2,
                                  e.id_matriz_risk,
                                  e.fk_id_company,
                                  null
                                )
                              }
                              style={{
                                fontSize: '0.80rem',
                                cursor: 'pointer',
                                color: 'rgb(0, 157, 192)'
                              }}
                            ></i>
                          ) : null}

                          <i
                            class="fas fa-pencil-alt"
                            onClick={() =>
                              this.handleModal(1, null, e.fk_id_company, e)
                            }
                            style={{
                              fontSize: '0.80rem',
                              cursor: 'pointer',
                              color: 'rgb(0, 157, 192)'
                            }}
                          ></i>
                          <i
                            class="fas fa-trash-alt"
                            onClick={() =>
                              this.handleModal(5, null, e.fk_id_company, e)
                            }
                            style={{
                              fontSize: '0.80rem',
                              cursor: 'pointer',
                              color: '#F44336'
                            }}
                          ></i>
                        </div>
                      ),
                      id: e.id_matriz_risk,
                      name: e.name,
                      bussines: e.name_department,
                      responsible: e.user_name,
                      launch_date: moment(e.dt_lanc).format('L'),
                      review: e.review,
                      asset_type: 'Dados pessoais',
                      risk_description: (
                        <p dangerouslySetInnerHTML={{ __html: e.description }} />
                      ),
                      probability: (
                        <div className="content-maturity-selector">
                          {[1, 2, 3].map(f => (
                            <div
                              className="maturity-selector-item"
                              style={{
                                backgroundColor:
                                  e.probability >= f ? 'var(--warning)' : ''
                              }}
                              onClick={() =>
                                this.update('matriz', {
                                  probability: f,
                                  id_matriz_risk: e.id_matriz_risk
                                })
                              }
                            ></div>
                          ))}
                          <span>{e.probability}</span>
                        </div>
                      ),
                      impact: (
                        <div className="content-maturity-selector">
                          {[1, 2, 3, 4, 5].map(f => (
                            <div
                              className="maturity-selector-item"
                              style={{
                                backgroundColor:
                                  e.impact >= f ? 'var(--success)' : ''
                              }}
                              onClick={() =>
                                this.update('matriz', {
                                  impact: f,
                                  id_matriz_risk: e.id_matriz_risk
                                })
                              }
                            ></div>
                          ))}
                          <span>{e.impact}</span>
                        </div>
                      ),
                      punctuation: (
                        <ProgressLine current={e.probability + e.impact} />
                      ),
                      Risk: (
                        <Chip
                          className="indicator-risk"
                          style={{ backgroundColor: this.risk(e).color }}
                          label={
                            <span className="label">{this.risk(e).label}</span>
                          }
                        />
                      )
                    }))}
                  />
                </div>
              }

              <div
                id="content_table_planning"
                className={`mb-3 ${this.state.index == 0
                  ? 'corC'
                  : this.state.index == 1
                    ? 'corD'
                    : this.state.index == 2
                      ? 'corI'
                      : ''
                  } `}
              >
                <CommonTable
                  title={language.PLANNING}
                  searchColumn
                  clearFilters={false}
                  print={false}
                  search={false}
                  AppBarProps={{
                    className: 'py-3'
                  }}
                  paginationTop={false}
                  afterBar2={
                    <div>
                      <div
                        style={{ position: 'relative', top: 14 }}
                        className="mr-5"
                      >
                        <ButtonGroupFilter
                          items={[
                            language.CONFIDENTIALITY,
                            language.AVAILABILITY,
                            language.INTEGRITY,
                            language.ALL
                          ]}
                          itemsProps={{
                            style: {
                              textTransform: 'uppercase',
                              fontSize: 10
                            }
                          }}
                          index={0}
                          active={this.state.index}
                          onClick={e => this.setIndex(e)}
                        />
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          top: 16,
                          right: 16
                        }}
                      ></div>
                    </div>
                  }
                  col={[
                    {
                      key: 'action',
                      label: language.ACTION,
                      thConfig: true,
                      style: { width: '4%' }
                    },
                    {
                      key: 'id',
                      label: language.ID,
                      thConfig: true,
                      style: { width: '4%' }
                    },
                    {
                      key: 'control',
                      label: language.TREATMENT + '/' + language.CONTROL,
                      thConfig: true,
                      style: { width: '51%' }
                    },
                    {
                      key: 'controller',
                      label: language.RESPONSIBLE_FOR_TRATMENT,
                      thConfig: true,
                      style: { width: '16.5%' }
                    },
                    {
                      key: 'approver',
                      label: language.APPROVE,
                      thConfig: true,
                      style: { width: '16.5%' }
                    },
                    {
                      key: 'term',
                      label: language.TERM,
                      thConfig: true,
                      style: { width: '7.5%' }
                    },
                    {
                      key: 'status_',
                      label: language.ITEM,
                      thConfig: true,
                      style: { width: '15%' }
                    },
                    {
                      key: 'status',
                      label: language.STATE,
                      thConfig: true,
                      style: { width: '9%' }
                    },
                    {
                      key: 'probability',
                      label: language.PROBABILITY,
                      thConfig: true,
                      style: { width: '1%' }
                    },
                    {
                      key: 'impact',
                      label: language.IMPACT,
                      thConfig: true,
                      style: { width: '1%' }
                    },
                    {
                      key: 'punctuation',
                      label: language.PUNCTUATION,
                      thConfig: true,
                      style: { width: '11%' }
                    },
                    {
                      key: 'Risk',
                      label: language.RISKS,
                      thConfig: true,
                      style: { width: '11%' }
                    },
                    {
                      key: 'ac',
                      label: language.ACTION,
                      thConfig: true,
                      style: { width: '10%' }
                    }
                  ]}
                  /**[
    {
        "id_matriz_planning": 6,
        "fk_id_matriz_risk": 7,
        "fk_id_sponsor": 29,
        "dt_end": "2020-03-13T01:09:00.000Z",
        "description": "<p>wedwed</p>",
        "fk_id_activity": null,
        "fk_id_project": null,
        "fk_id_tickets": null,
        "fk_id_company": 1,
        "user_name": "Bruno Profeta Cavalcante"
    }
] */

                  data={ma.map(t => ({

                    action: (
                      <div className="d-flex align-items-center justify-content-between">
                        <i
                          class="fas fa-pencil-alt"
                          onClick={() =>
                            this.handleModal(2, null, t.fk_id_company, t)
                          }
                          style={{
                            fontSize: '0.80rem',
                            cursor: 'pointer',
                            color: 'rgb(0, 157, 192)'
                          }}
                        ></i>
                        <i
                          class="fas fa-trash-alt"
                          onClick={() =>
                            this.handleModal(5, null, t.fk_id_company, t)
                          }
                          style={{
                            fontSize: '0.80rem',
                            cursor: 'pointer',
                            color: '#F44336'
                          }}
                        ></i>
                      </div>
                    ),
                    id: t.fk_id_matriz_risk,

                    control: (
                      <p dangerouslySetInnerHTML={{ __html: t.description }} />
                    ),
                    controller: t.user_name,
                    approver: users.filter(b => b.id_user == t.fk_id_approver) && users.filter(b => b.id_user == t.fk_id_approver).length > 0 &&
                      users.filter(b => b.id_user == t.fk_id_approver)[0].name,
                    term: moment(t.dt_end).format('L'),
                    satus_: (


                      <div className="d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => t.fk_id_tickets > 0 ? this.setState({
                          ticketHistory: {
                            id_tickets: t.fk_id_tickets,
                            real_id: t.real_id
                          }
                        }) :
                          this.setState({ viewOpen: { activity: {}, project: {} } })
                        }
                      >
                        <i
                          class="far fa-eye" style={{ color: '#009dc0', marginLeft: 10 }}></i>
                        <span style={{ fontSize: 10 }}>
                          {t.fk_id_tickets > 0 ? t.real_id : '#' + t.fk_id_project + ' - ' + t.nameactivity}
                        </span>
                      </div>
                    ),
                    satus: (
                      <div className="d-flex align-items-center">
                        <Progress
                          className="mr-2"
                          style={{ width: '100%', height: 6 }}
                          hiddenText
                          current={t.fk_id_tickets > 0 ? t.progress : t.percent}
                          background={'var(--success)'}
                        />
                        <span style={{ fontSize: 10 }}>
                          {t.fk_id_tickets > 0 ? t.progress : t.percent + '%'}
                        </span>
                      </div>
                    ),
                    probability: (
                      <div className="content-maturity-selector">
                        {[1, 2, 3].map(e => (
                          <div
                            className="maturity-selector-item"
                            style={{
                              backgroundColor:
                                (this.status(t) == 100
                                  ? t.probability_
                                  : t.probability) >= e
                                  ? 'var(--warning)'
                                  : ''
                            }}
                            onClick={() =>
                              this.update('upmatrizplanpunc', {
                                probability: e,
                                id_matriz_planning: t.id_matriz_planning
                              })
                            }
                          ></div>
                        ))}
                        <span>
                          {this.status(t) == 100
                            ? t.probability_
                            : t.probability}
                        </span>
                      </div>
                    ),
                    impact: (
                      <div className="content-maturity-selector">
                        {[1, 2, 3, 4, 5].map(e => (
                          <div
                            className="maturity-selector-item"
                            style={{
                              backgroundColor:
                                (this.status(t) == 100
                                  ? t.impact_
                                  : t.impact) >= e
                                  ? 'var(--success)'
                                  : ''
                            }}
                            onClick={() =>
                              this.update('upmatrizplanpunc', {
                                impact: e,
                                id_matriz_planning: t.id_matriz_planning
                              })
                            }
                          ></div>
                        ))}
                        <span>
                          {this.status(t) == 100 ? t.impact_ : t.impact}
                        </span>
                      </div>
                    ),
                    punctuation: (
                      <ProgressLine current={t.probability + t.impact} />
                    ),
                    Risk: (

                      <Chip
                        className="indicator-risk"
                        style={{ backgroundColor: this.risk(t).color }}
                        label={
                          <span className="label">{this.risk(t).label}</span>
                        }
                      />
                    ),
                    ac:
                      t.approve == true ?
                        <span>
                          <Tooltip
                            interactive
                            html={
                              <span> {language.APPROVAL_DATE} {moment(t.dt_approve).format('DD-MM-YY HH:mm')} </span>
                            }
                            arrow={true}
                            position="left"
                            theme="light"
                          >

                            <i style={{ color: 'green', fontSize: '14px', cursor: 'point' }} class="fas fa-check"></i>
                          </Tooltip>

                          <i
                            class="far fa-eye"
                            onClick={() =>
                              this.handleModal(
                                3,
                                t.id_matriz_planning,
                                null
                              )

                            }

                            style={{
                              fontSize: '0.80rem',
                              cursor: 'pointer',
                              color: 'rgb(0, 157, 192)'
                            }}
                          ></i>
                        </span>

                        :


                        <i
                          class="far fa-eye"
                          onClick={() =>

                            (t.fk_id_tickets > 0 ? t.progress : t.percent) == 100 ?

                              t.approve != true ? this.setState({ approveModal: t }) :
                                this.handleModal(
                                  3,
                                  t.id_matriz_planning,
                                  null
                                )
                              : this.store.toggleAlert(true, language.ACTIVITY_DID_NOT_REACH_100_PERCENT, 'error')
                          }

                          style={{
                            fontSize: '0.80rem',
                            cursor: 'pointer',
                            color: 'rgb(0, 157, 192)'
                          }}
                        ></i>


                  }))}
                />
              </div>
            </div>
            <Dialog
              title={
                modal == 1
                  ? language.NEW_RISK
                  : modal == 2
                    ? language.NEW_TREATMENT
                    : language.ASSOCIATE_THE_CALLS
              }
              open={!!modal}
              PaperProps={{
                id: 'risk_matrix'
              }}

              maxWidth="lg"
              onClose={() => this.handleModal()}
            >
              <div className="pt-3">
                {modal == 1 ? (
                  <CommonForm

                    fields={[
                      {
                        //Confidencialidade, Disponibilidade e Integridade
                        col: 12,
                        name: 'name',
                        type: 'text',
                        required: true,
                        label: 'Nome',

                      },
                      {
                        //Confidencialidade, Disponibilidade e Integridade
                        col: 12,
                        name: 'safety_pillar',
                        type: 'listCheckbox',
                        required: true,
                        label: language.SAFETY_PILAR,
                        options: [
                          { value: 'C', label: language.CONFIDENTIALITY },
                          { value: 'D', label: language.AVAILABILITY },
                          { value: 'I', label: language.INTEGRITY }
                        ]
                      },
                      {
                        col: 6,
                        name: 'fk_id_company',
                        type: 'select',
                        label: language.COMPANY,
                        required: true,
                        options: companyOptions
                      },

                     {
                        col: 6,
                        type: 'autocomplete',
                        name: 'fk_id_department',
                        label: language.BUSINESS_AREA,
                        textlabel : language.BUSINESS_AREA,
                        className: 'tech-group-ticket',
                        method: 'POST',
                        route: !company || company === 0 ? null : `${this.APIEndpoints.DEPARTMENT}/filter/`,
                        filter:{
                          fk_id_company: company,
                          deleted: false,
                          status: true
                        },
                        routeAll: !company || company === 0 ? null : `${this.APIEndpoints.DEPARTMENT}/filter?`,
                        filterAll:{
                          fk_id_company: company,
                          deleted: false,
                          status: true
                        },
                        routeGetCount: !company || company === 0 ? null : `${this.APIEndpoints.DEPARTMENT}/filter?count=0`,
                        fieldquery: 'search',
                        textinit: language.BUSINESS_AREA,
                        fieldvaluedb: 'id_department',
                        fieldlabeldb: 'name',
                        idinit: '',
                        detail: true,
                        methodGetAll: 'POST',
                        disabled: !company || company === 0
                     },
                     {
                        col: 6,
                        type: 'autocomplete',
                        name: 'fk_id_sponsor',
                        label: language.RESPONSIBLE,
                        textlabel : language.RESPONSIBLE,
                        className: 'tech-group-ticket',
                        method: 'POST',
                        route: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                        routeGetCount: `${this.APIEndpoints.USER}/active/count/notsolicitant`,
                        fieldquery: 'search',
                        filterAll:{fk_id_company : company,  deleted : false, status : true},
                        textinit: language.TECH,
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: '',
                        routeAll: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                        detail: true,
                        methodGetAll: 'POST',
                      },
                      {
                        col: 6,
                        name: 'dt_lanc',
                        type: 'date',
                        label: language.RELEASE_DATE_OF,
                        required: true,

                      },
                      {
                        col: 6,
                        name: 'type_activite',
                        type: 'select',
                        label: language.ASSET_TYPE,
                        options: [
                          { value: 1, label: `- ${language.INFORMATION_ASSETS}` },
                          { value: 2, label: `- ${language.SOFTWARE_ASSETS}` },
                          { value: 3, label: `- ${language.PERSONAL_DATA}` },
                          { value: 4, label: `- ${language.INTANGIBLES}` },
                          { value: 5, label: `- ${language.SERVICES}` },
                          { value: 6, label: `- ${language.INFORMATION_TECHNOLOGY}` }
                        ],
                        required: true,

                      },
                      {
                        col: 6,
                        name: 'services',
                        type: 'text',
                        label: language.IMPACTED_SERVICES,
                        required: true,
                      },
                      {
                        col: 12,
                        name: 'description',
                        type: 'textEditor',
                        placeholder: language.RISK_DESCRIPTION,
                        required: true,

                      }
                    ]}
                    onChangeField={this.onChangeField}
                    values={values}
                    loading={'post-' + this.APIEndpoints.MATRIZ + 'savematriz'}
                    onSubmit={e => (this.onFormSubmit(e),console.log('e',e))}
                  />
                ) : modal == 2 ? (
                  <CommonForm
                    fields={[
                                 {
                        col: 6,
                        type: 'autocomplete',
                        name: 'fk_id_sponsor',
                        label: language.RESPONSIBLE_FOR_TRATMENT,
                        textlabel : language.RESPONSIBLE,
                        className: 'tech-group-ticket',
                        method: 'POST',
                        route: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                        routeGetCount: `${this.APIEndpoints.USER}/active/count/notsolicitant`,
                        fieldquery: 'search',
                        textinit: language.TECH,
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: '',
                        routeAll: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                        detail: true,
                        methodGetAll: 'POST',
                      },

                      {
                        col: 6,
                        type: 'autocomplete',
                        name: 'fk_id_sponsor',
                        label: language.APPROVER,
                        textlabel : language.RESPONSIBLE,
                        className: 'tech-group-ticket',
                        method: 'POST',
                        route: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                        routeGetCount: `${this.APIEndpoints.USER}/active/count/notsolicitant`,
                        fieldquery: 'search',
                        textinit: language.TECH,
                        fieldvaluedb: 'id_user',
                        fieldlabeldb: 'name',
                        idinit: '',
                        routeAll: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
                        detail: true,
                        methodGetAll: 'POST',
                      },
                      {
                        col: 12,
                        type: 'element',
                        content: (
                          <div style={{ display: 'flex' }}>
                            <span className="risk_maturity_label_" >Quando chegar aos 100% </span>
                            <div style={{ marginRight: '20px', marginLeft: '20px' }} className="content-maturity-selector_risk">
                              {[1, 2, 3].map(f => (
                                <div
                                  className="maturity-selector-item_risk"
                                  style={{
                                    backgroundColor:
                                      this.state.probability >= f ? 'var(--warning)' : 'rgba(98, 157, 170, 0.25)'
                                  }}
                                  onClick={() =>
                                    this.setState({ probability: f })

                                  }
                                ></div>
                              ))}

                            </div>

                            <div className="content-maturity-selector_risk">
                              {[1, 2, 3, 4, 5].map(f => (
                                <div
                                  className="maturity-selector-item_risk"
                                  style={{
                                    backgroundColor:
                                      this.state.impacty_ >= f ? 'var(--success)' : 'rgba(98, 157, 170, 0.25)'
                                    ,
                                  }}
                                  onClick={() =>
                                    this.setState({ impacty_: f })
                                  }
                                ></div>
                              ))}

                            </div>

                          </div>)
                      },

                      {
                        col: 6,
                        name: 'dt_end',
                        type: 'date',
                        label: language.TERM
                      },
                      {
                        col: 6,
                        name: 'tipo',
                        type: 'select',
                        label: language.TYPE,
                        options: [{ value: 1, label: language.PROJECT }, { value: 2, label: 'Ticket' }]
                      },
                      {
                        col: 6,
                        name: 'fk_id_project',
                        type: 'select',
                        label: language.PROJECT,
                        options: optionsprojeto,
                        visible: this.state.tipo == 1
                      },
                      {
                        col: 6,
                        name: 'fk_id_activity',
                        type: 'select',
                        label: language.ACTIVITY,
                        options: optionsactivity,
                        visible: this.state.tipo == 1
                      },
                      {
                        col: 6,
                        name: 'fk_id_tickets',
                        type: 'select',
                        label: 'Tickets',
                        options: ticketlist.filter(g => g.progress < 100).map(v => ({ value: v.id_tickets, label: v.real_id + ' - ' + v.subject })),
                        visible: this.state.tipo == 2
                      }


                      ,

                      {
                        col: 12,
                        name: 'description',
                        type: 'textEditor',
                        placeholder: language.DESCRIPTION
                      }
                    ]}
                    values={values}
                    onChangeField={this.onChangeField}
                    loading={'post-' + this.APIEndpoints.MATRIZ + 'saveplanning'}
                    onSubmit={e => this.onFormSubmitPlan(e)}
                  />
                ) : modal == 3 ?
                  <span>
                    <CommonForm
                      fields={[


                        {
                          col: 12,
                          name: 'fk_id_tickets',
                          type: 'listCheckbox',
                          label: 'Tickets',
                          options: tickets.map(v => ({ value: v.id_tickets, label: v.real_id + ' - ' + v.subject }))

                        }

                      ]}



                      onSubmit={e => this.onFormSubmitTickets(e)}
                    />
                    <br></br><br></br>
                    <CommonTable
                      title={language.ASSOCIATED_TICKETS}
                      searchColumn
                      clearFilters={false}
                      print={false}
                      search={false}
                      paginationTop={false}
                      AppBarProps={{
                        className: 'py-3'
                      }}

                      col={[
                        {
                          key: 'action',
                          label: language.ACTION,
                          thConfig: true,
                          style: { width: '2%' }
                        },
                        {
                          key: 'real_id',
                          label: 'Ticket',
                          thConfig: true,
                          style: { width: '4%' }
                        },
                        {
                          key: 'subject',
                          label: language.SUBJECT,
                          thConfig: true,
                          style: { width: '4%' }
                        },
                        {
                          key: 'data',
                          label: language.DATE,
                          thConfig: true,
                          style: { width: '4%' }
                        }

                      ]}
                      data={this.state.ticketsAssoc.map(e => ({
                        action: <span> <i
                          class="far fa-eye"
                          onClick={() =>
                            this.setState({
                              ticketHistory: {
                                id_tickets: e.id_tickets,
                                real_id: e.real_id
                              }
                            })
                          }
                          style={{
                            fontSize: '0.80rem',
                            cursor: 'pointer',
                            color: 'rgb(0, 157, 192)'

                          }}
                        ></i>
                          <i
                            class="fas fa-trash-alt"
                            onClick={() =>
                              this.onDelete_({ id_risk_evidence: e.id_risk_evidence })
                            }
                            style={{
                              fontSize: '0.80rem',
                              cursor: 'pointer',
                              color: '#F44336',
                              marginLeft: '4px'
                            }}
                          ></i></span>,
                        id: e.real_id,
                        name: e.subject,
                        data: moment(e.dt_cad).format('DD/MM/YY')
                      }))
                      }
                    />
                  </span>



                  : null}
              </div>
            </Dialog>


            {this.state.approveModal != null ?

              <DialogConfirm
                open={true}
                maxWidth="xs"
                onClose={() => {
                  this.setState({ approveModal: null })
                }}
                title={language.APPROVAL}
                message={language.CONFIRM_APPROVAL}
                confirmLabel={language.YES}
                cancelLabel={language.NO}
                cancelProps={{
                  color: 'primary'
                }}
                eventClose={false}
                onConfirm={() => {
                  this.saveApprove();
                }}
                onCancel={() => {
                  this.setState({ approveModal: null })
                }}
              /> : null
            }






            {!!Object.keys(viewOpen).length && (<li></li>
              /* <ViewActivity
                  open={!!Object.keys(viewOpen).length}
                  data={viewOpen.activity}
                  onOpenForm={onOpenForm}
                  onClose={() => setViewOpen({})}
                  users={users}
                  options={options}
                  project={viewOpen.project}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  subActivities={this.store.subActivities}
                  onSubmit={onSubmitComent}
                  onSubmitActivitieAntecessor={onSubmitActivitieAntecessor}
                  onDownloadFile={onDownloadFile}
                  onUpdate={onUpdate}
               />*/
            )}




            {this.state.ticketHistory != null ? <Dialog
              title={''}
              open={true}
              maxWidth="xl"
              onClose={() => this.setState({ ticketHistory: null })}
            >

              <TicketManagement
                detail={true}
                id_ticket_detail={this.state.ticketHistory}
                isHistory
              ></TicketManagement>
            </Dialog> : null
            }


            {this.state.ticketHistory != null ? <Dialog
              title={''}
              open={true}
              maxWidth="xl"
              onClose={() => this.setState({ ticketHistory: null })}
            >

              <TicketManagement
                detail={true}
                id_ticket_detail={this.state.ticketHistory}
                isHistory
              ></TicketManagement>
            </Dialog> : null
            }

            {modal == 5 && (
              <CommonDelete
                language={language}
                closeModal={() => this.handlePage()}
                onDelete={this.onDelete}
                message={
                  <p dangerouslySetInnerHTML={{ __html: values.description }} />
                }
              />
            )}


          </PageLayout>
        </Animate>
      </CommonPage>
    );
  }
}
export default TabelaRisco;
