import React from 'react';
import { Card, TextField, Chip, Grid } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
import './FormBuilderForm.css';
import { withStyles, styled } from '@material-ui/core/styles';
const crypto = require('crypto');
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Store from '@data/Store';
import moment from 'moment';
import 'moment/locale/pt-br';
import XLSX from 'xlsx';
moment.locale('pt-br');
import $ from 'jquery';
import Constants from '../../data/Constants';
window.jQuery = $;
window.$ = $;
require('jquery-ui-sortable');
require('formBuilder');
import Error from '@material-ui/icons/Error';
import Actions from '@data/Actions';

const action = new Actions();

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 18,
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#039FC0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#039FC0',
    },
    '& .MuiOutlinedInput-root': {
      height: 41,

      '& fieldset': {
        borderColor: '#039FC0',
      },
      '&:hover fieldset': {
        borderColor: '#039FC0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#039FC0',
      },
    },
  },
})(TextField);

function decryp(aMsg) {
  try {
    var aSecret = 'Orch3str0!@forever!';
    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');
    return tRet;
  } catch (e) {
    return {};
  }
}

function convertXlsxFiletoJSON(file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const XLRowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
      const jsonObject = JSON.stringify(XLRowObject);
      resolve(JSON.parse(jsonObject || '[]'));
    };

    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsBinaryString(file);
  });
}

function removeDefaultOptions(target) {
  var btnRemoveOption = $(target).find('[class="remove btn formbuilder-icon-cancel"]');
  $(btnRemoveOption).trigger('click');
}

function createOptionsFromJson(jsonOptions, target) {
  removeDefaultOptions(target);

  const btnAddOption = $(target).find('[class="add add-opt"]');
  jsonOptions.forEach(function(el) {
    $(btnAddOption).trigger('click');
    const inputsFromLastRow = $(target).find('.sortable-options-wrap').find('li:last input');
    $(inputsFromLastRow[1]).attr('value', el.label);
    $(inputsFromLastRow[2]).attr('value', el.value);
  });
}

function onAddOptions(fld) {
  const inputUpload = $(fld).find('input[type=file]');
  inputUpload.attr('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  inputUpload.on('change', async function (e) {
    const target = fld;
    const input = e.target;
    const file = input.files[0];
    const jsonXlsx = await convertXlsxFiletoJSON(file);
    createOptionsFromJson(jsonXlsx, target);
  });
}

export default class FormBuilderForm extends CommonPage {
  constructor() {
    super();

    this.state = {
      page: 'list',
      modal: false,
      edit: false,
      nameTemp: '',
      valuesTaskManagement: {},
      groups: [],
      taskName: '',
      taskTimeExecution: new Date(),
      taskTechGroup: null,
      taskDescription: '',
      tasksTemplate: [],
      taskDelete: {},
      openDeleteTask: false,
      idTaskEdit: null,
    };

    this.openModal = this.openModal.bind(this);
    this.openModalTaskManagement = this.openModalTaskManagement.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.closemodal = this.closemodal.bind(this);
    this.saveNewTask = this.saveNewTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.submitDeleteTask = this.submitDeleteTask.bind(this);
  }

  closemodal() {
    this.setState({ modal: false, nameTemp: '' });
  }

  clickTemplate() {
    $('#load').trigger('click');
  }

  openModalTaskManagement(open, v) {
    if (v) {
      this.setState({
        modalTaskManagement: open,
        taskName: v && v.name,
        taskDescription: v && v.description,
        taskTimeExecution: v && v.time_execution,
        taskTechGroup: v && v.groupsTechs,
        idTaskEdit: v && v.id_template_task,
      });
    } else {
      this.setState({
        modalTaskManagement: open,
        taskName: null,
        taskDescription: null,
        taskTimeExecution: null,
        taskTechGroup: null,
        idTaskEdit: null,
      });
    }
  }

  deleteTask(v) {
    this.setState({
      taskDelete: v,
      openDeleteTask: true,
    });
  }

  submitDeleteTask() {
    action
      .execute('delete', `${Constants.APIEndpoints.TASK_TEMPLATE}/${this.state.taskDelete.id_template_task}`, '')
      .then((e) =>
        this.setState({
          openDeleteTask: false,
          tasksTemplate: this.state.tasksTemplate.filter((it) => {
            if (
              it.id_template_task !== this.state.taskDelete.id_template_task
            ) {
              return it;
            }
          }),
        })
      )
      .catch((err) => console.log(err));
  }

  saveNewTask() {
    const data = {
      name: this.state.taskName,
      time_execution: moment(this.state.taskTimeExecution).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      description: this.state.taskDescription,
      taskTechGroup: this.state.taskTechGroup,
      fk_id_catalog_task: this.props.values.id_catalog_task,
    };

    const url = this.state.idTaskEdit
      ? `${Constants.APIEndpoints.TASK_TEMPLATE}/${this.state.idTaskEdit}`
      : `${Constants.APIEndpoints.TASK_TEMPLATE}/`;

    const method = this.state.idTaskEdit ? 'PUT' : 'POST';

    action
      .execute(method, url, '', data)
      .then((res) => {
        this.setState({
          modalTaskManagement: false,
        });

        action
          .execute('get', `${Constants.APIEndpoints.TASK_TEMPLATE}/${this.props.values.id_catalog_task}`, '')
          .then((res) => {
            if( res.data ) {
              res = res.data;
            }

            const newTasks = [];

            res.map(async (t) => {
              const exist = newTasks.findIndex(
                (task) => task.id_template_task === t.id_template_task
              );

              if (exist > -1) {
                newTasks[exist].groupsTechs.push({
                  value: t.id_group,
                  label: t.group_name,
                });
                newTasks[exist].groupsName += `,${t.group_name}`;
              } else {
                newTasks.push({
                  ...t,
                  groupsTechs: [
                    {
                      value: t.id_group,
                      label: t.group_name,
                    },
                  ],
                  groupsName: t.group_name,
                });
              }
            });

            this.setState({
              tasksTemplate: newTasks,
            });

            this.props.reloadFormTemplate();
          });
      })
      .catch((err) => console.log('error', Error));
  }

  openModal(valor, data) {
    const customFormFields = [{
      label: 'Time',
      attrs: {
        type: 'time'
      },
      icon: '<i class="fa fa-clock"></i>'
    }, {
      label: 'Date Time',
      attrs: {
        type: 'datetime'
      },
      icon: '<i class="fa fa-calendar-alt"></i>'
    }, {
      label: 'Custom',
      attrs: {
        type: 'custom'
      },
      icon: '<i class="fa fa-edit"></i>'
    }];

    const customFormTemplates = {
      custom: function (fieldData) {
        const allowEditingAfterOpeningRecord = !!fieldData.allowEditingAfterOpeningRecord;
        const showInServicePortal =  !!fieldData.showInServicePortal;
        const { placeholder, className, name, fieldType, addEmptyOption, label } = fieldData;
        return {
          field: `
            <input
              type="text"
              class="form-control ${className || ''}"
              placeholder="${placeholder || ''}"
              name="${name || ''}"
              id="${fieldData.name}"
              fieldType="${fieldType}"
              label="${label}"
              ${addEmptyOption ? 'addEmptyOption="add-empty-option"' : ''}
              ${allowEditingAfterOpeningRecord ? 'allowEditingAfterOpeningRecord="allow-editing-after-opening-record"' : ''}
              ${showInServicePortal ? 'showInServicePortal="show-in-service-portal"' : ''}
              readonly
            />
          `,
        };
      },
      time: function (fieldData) {
        const allowEditingAfterOpeningRecord = !!fieldData.allowEditingAfterOpeningRecord;
        const showInServicePortal = !!fieldData.showInServicePortal;
        const { placeholder, className, name, value } = fieldData;
        return {
          field: `
            <input
              type="time"
              class="form-control ${className || ''}"
              placeholder="${placeholder || ''}"
              name="${name || ''}"
              value="${value || ''}"
              id="${fieldData.name}"
              ${allowEditingAfterOpeningRecord ? 'allowEditingAfterOpeningRecord="allow-editing-after-opening-record"' : ''}
              ${showInServicePortal ? 'showInServicePortal="show-in-service-portal"' : ''}
            />
          `,
        };
      },
      datetime: function (fieldData) {
        const allowEditingAfterOpeningRecord = !!fieldData.allowEditingAfterOpeningRecord;
        const showInServicePortal = !!fieldData.showInServicePortal;
        const { placeholder, className, name } = fieldData;
        return {
          field: `
            <input
              type="datetime-local"
              class="form-control ${className || ''}"
              placeholder="${placeholder || ''}"
              name="${name || ''}"
              id="${fieldData.name}"
              ${allowEditingAfterOpeningRecord ? 'allowEditingAfterOpeningRecord="allow-editing-after-opening-record"' : ''}
              ${showInServicePortal ? 'showInServicePortal="show-in-service-portal"' : ''}
            />
          `,
        };
      }
    };

    var _that = this;

    const typeUserEvents = {
      select: {
        onadd: onAddOptions
      },
      'radio-group': {
        onadd: onAddOptions
      },
      'checkbox-group': {
        onadd: onAddOptions
      }
    }

    const typeUserAttrs = [
      'time',
      'custom',
      'datetime',
      'autocomplete',
      'button',
      'checkbox-group',
      'date',
      'file',
      'number',
      'radio-group',
      'select',
      'text',
      'textarea',
      'paragraph',
    ].reduce((result, field) => {
      if (['checkbox-group', 'select', 'radio-group'].includes(field)) {
        return {
          ...result,
          [field]: {
            uploadOptions: {
              label: 'Upload options',
              value: 'Upload options',
              type: 'file'
            },
            allowEditingAfterOpeningRecord: {
              label: 'Edit after opening',
              value: false,
              type: 'checkbox',
            },
            showInServicePortal: {
              label: 'Show in portal',
              value: false,
              type: 'checkbox',
            }
          }
        }
      }

      return {
        ...result,
        [field]: {
          allowEditingAfterOpeningRecord: {
            label: 'Edit after opening',
            value: false,
            type: 'checkbox',
          },
          showInServicePortal: {
            label: 'Show in portal',
            value: false,
            type: 'checkbox',
          }
        }
      }
    }, {});

    typeUserAttrs['select'] = {
      ...typeUserAttrs['select'],
      addEmptyOption: {
        label: 'Add empty option',
        value: false,
        type: 'checkbox'
      }
    }

    typeUserAttrs['custom'] = {
      ...typeUserAttrs['custom'],
      addEmptyOption: {
        label: 'Add empty option',
        value: false,
        type: 'checkbox',
      },
      fieldType: {
        label: 'Type field',
        value: '',
        type: 'select',
        options: {
          'companies_var': 'Companies',
          'allusers_var': 'Users',
          'subsdpt_var': 'Subdepartments',
          'departments_var': 'Departments',
          'chieftdpt_var': 'Chief department',
          'contracts_var': 'Contracts',
          'locations_var': 'Locations'
        }
      }
    }

    const typeUserDisabledAttrs = {
      custom: ['value']
    }

    if (data == undefined) {
      jQuery(function ($) {
        var fbEditor = $('#fb-editor');
        var formBuilder = $(fbEditor).formBuilder({
          disabledActionButtons: ['clear', 'save', 'data'],
          fields: customFormFields,
          templates: customFormTemplates,
          typeUserAttrs,
          typeUserDisabledAttrs,
          typeUserEvents
        });

        document.getElementById('send').addEventListener('click', function () {
          $(fbEditor).formBuilder('toggleAllFieldEdit')
          var itens;
          $("[class*='preview']").each(function () {
            var $this = $(this);
            var $select = $this.find('select[remove-default-value="remove-default-value"]');
            if ($select.length > 0) {
              $select.find('option').first().removeAttr('selected');
            }

            if (itens) {
              itens =
                itens +
                '<div  style="margin-top:10px;">' +
                $(this).html() +
                '</div>';
            } else {
              itens =
                '<div style="margin-top:10px;"> ' + $(this).html() + '</div>';
            }
          });

          _that.saveForm(
            itens,
            formBuilder.actions.getData('json', true),
            undefined
          );
        });
        document
          .getElementById('clearField')
          .addEventListener('click', function () {
            formBuilder.actions.clearFields();
          });
        document
          .getElementById('showData')
          .addEventListener('click', function () {
            formBuilder.actions.showData();
          });
      });
    } else {
      jQuery(function ($) {
        let dt = decryp(data.data);
        var fbEditor = $('#fb-editor');
        var formBuilder = $(fbEditor).formBuilder({
          ...dt,
          disabledActionButtons: ['clear', 'save', 'data', 'file'],
          fields: customFormFields,
          templates: customFormTemplates,
          typeUserAttrs,
          typeUserDisabledAttrs,
          typeUserEvents
        });

        document.getElementById('send').addEventListener('click', function () {
          $(fbEditor).formBuilder('toggleAllFieldEdit')
          var itens;
          $("[class*='preview']").each(function () {
            var $this = $(this);
            var $select = $this.find('select[add-empty-option="add-empty-option"]');
            if ($select.length > 0) {
              $select.find('option').first().removeAttr('selected');
              $select.prepend(`<option value="" selected="selected">Selecione</option>`);
            }

            if (itens) {
              itens =
                itens +
                '<div style="margin-top:10px;">' +
                $(this).html() +
                '</div>';
            } else {
              itens =
                '<div style="margin-top:10px;">' + $(this).html() + '</div>';
            }
          });

          _that.saveForm(
            itens,
            formBuilder.actions.getData('json', true),
            data
          );
        });
        document.getElementById('load').addEventListener('click', function () {
          formBuilder.actions.setData(dt);
          _that.setState({ nameTemp: data.name });
        });
        document
          .getElementById('clearField')
          .addEventListener('click', function () {
            formBuilder.actions.clearFields();
          });
        document
          .getElementById('showData')
          .addEventListener('click', function () {
            formBuilder.actions.showData();
          });
      });
      setTimeout(this.clickTemplate, 1000);
    }

    this.setState({ modal: true, edit: !!data });
  }

  saveForm(html, data, dt) {
    this.setState({ modal: false });

    this.props.saveForm(
      html,
      this.props.values,
      data,
      dt,
      $('#nameTemp').val()
    );
  }

  componentWillMount() {
    const groups = Store.groups;
    const usercon_company = Store.usercon_company;

    const groupMap =
      Store.getUserLoged().role == 'tech'
        ? groups
          .filter((x) =>
            (
              (usercon_company &&
                usercon_company.length > 0 &&
                usercon_company[0].group_user) ||
              []
            ).includes(x.id_group_users)
          )
          .map((e) => ({
            value: e.id_group_users,
            label: e.name,
          }))
        : groups.map((e) => ({
          value: e.id_group_users,
          label: e.name,
        }));

    setTimeout(() => {
      this.setState({
        groups: groupMap,
      });
    }, 1000);

    if (this.props.values) {
      action
        .execute('get', `${Constants.APIEndpoints.TASK_TEMPLATE}/${this.props.values.id_catalog_task}`, '')
        .then((res) => {
          if( res.data ) {
            res = res.data;
          }

          const newTasks = [];
          res.map && res.map(async (t) => {
            const exist = newTasks.findIndex(
              (task) => task.id_template_task === t.id_template_task
            );

            if (exist > -1) {
              newTasks[exist].groupsTechs.push({
                value: t.id_group,
                label: t.group_name,
              });
              newTasks[exist].groupsName += `,${t.group_name}`;
            } else {
              newTasks.push({
                ...t,
                groupsTechs: [
                  {
                    value: t.id_group,
                    label: t.group_name,
                  },
                ],
                groupsName: t.group_name,
              });
            }
          });

          this.setState({
            tasksTemplate: newTasks,
          });
        });
    }
  }

  render() {
    let {
      handlePage,
      language,
      onFormSubmit,
      values,
      impacts,
      priorities,
      urgencies,
      formstemplates,
      redirect,
    } = this.props;
    let data = [];
    let dataTaskTemplate = [];
    let sv = true;
    try {
      data = formstemplates
        .filter((c) => c.id_template_forms == values.id_template_forms)
        .map((v) => {
          return {
            nome: v.name,

            actions: (
              <React.Fragment>
                <div className="d-flex align-items-center justify-content-center">
                  <Chip
                    className="mx-2 muit-chip"
                    label={
                      <div className="d-flex align-items-center justify-content-between">


                        <RaisedButton
                          circleButton
                          icon="fas fa-edit"
                          label={language.EDIT}
                          color="default"
                          style={{ fontSize: 12, width: 21, height: 21 }}
                          className="mr-2"
                          onClick={() => this.openModal(true, v)}
                        />
                      </div>
                    }
                  />
                </div>
              </React.Fragment>
            ),
          };
        });
    } catch (e) {
      console.log('Error', e);
      sv = false;
    }

    let col = [
      { key: 'nome', label: language.ID, style: { width: 90 } },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 2, textAlign: 'left' },
        thConfig: true,
        jc: 'center',
      },
    ];

    let colTask = [
      { key: 'id_template_task', label: language.ID, style: { width: 90 } },
      { key: 'nome', label: language.NAME, style: { width: 90 } },
      { key: 'description', label: language.DESCRIPTION, style: { width: 90 } },
      { key: 'group_name', label: language.TECH_GROUP, style: { width: 90 } },
      {
        key: 'time_execution',
        label: language.RUNTIME?.toLocaleUpperCase(),
        style: { width: 90 },
      },

      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 2, textAlign: 'left' },
        thConfig: true,
        jc: 'center',
      },
    ];

    let approvalOptions = [
      { value: true, label: language.YES },
      { value: false, label: language.NOT },
    ];

    let countryComplexity = [
      { value: 'high', label: language.TICKET_PRIORITY.HIGH },
      { value: 'medium', label: language.TICKET_PRIORITY.MEDIUM },
      { value: 'low', label: language.TICKET_PRIORITY.SMALL },
    ];

    let impactOptions = impacts.map((i) => ({
      value: i.id_table,
      label: i.description,
    }));

    let priorityOptions = priorities.map((p) => ({
      value: p.id_table,
      label: p.description,
    }));

    let urgencyOptions = urgencies.map((u) => ({
      value: u.id_table,
      label: u.description,
    }));

    let fields = [
      {
        col: 6,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
      },
      {
        col: 4,
        name: 'disable_list_templates',
        label: language.DISABLE_TEMPLATE_LIST,
        type: 'select',
        options: [
          { value: 2, label: language.YES },
          { value: 1, label: language.NOT },
        ],
        required: true,
      },
      {
        col: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        multiLine: true,
        required: true,
      },
      {
        col: 4,
        name: 'use_in_ticket',
        label: language.USE_IN_TICKET,
        type: 'select',
        required: true,
        options: [
          { value: 2, label: language.YES },
          { value: 1, label: language.NOT },
        ],
      },
      {
        col: 4,
        name: 'use_in_gmud',
        label: language.USE_IN_GMUD,
        type: 'select',
        required: true,
        options: [
          { value: 2, label: language.YES },
          { value: 1, label: language.NOT },
        ],
      },
    ];

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10 },
    };

    let fieldsTasks = [
      {
        col: 6,
        name: 'name',
        label: language.TASK_NAME,
        type: 'text',
        required: true,
      },
      {
        col: 6,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
      },
    ];

    return (
      <div id="TaskForm">
        <Card className="content mb-3">
          <CommonForm
            button={button}
            onSubmit={onFormSubmit}
            fields={fields}
            values={values}
            loading="post-put-template"
          />
        </Card>

        <Dialog
          title={this.state.idTaskEdit ? language.EDIT_TASK : `${language.TO_REGISTER} ${language.TASK2}`}
          open={this.state.modalTaskManagement}
          contentProps={{
            style: { background: '#ebebeb' },
          }}
          maxWidth="lg"
          onClose={() => this.setState({ modalTaskManagement: false })}
        >
          <Grid container xs={12} spacing={2}>
            <Grid item xs={6}>
              <div className="item_form">
                <h2>{language.TASK_NAME?.toLocaleUpperCase()}&npsp;*</h2>
                <CustomTextField
                  required
                  fullWidth
                  variant="outlined"
                  id="filled-password-input"
                  label=""
                  type="text"
                  value={this.state.taskName}
                  onChange={(e) => this.setState({ taskName: e.target.value })}
                />
              </div>
            </Grid>

            <Grid xs={6} item>
              <div className="item_form">
                <h3>{language.RUNTIME?.toLocaleUpperCase()}&npsp;*</h3>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  libInstance={moment}
                  style={{ borderColor: '#039FC0' }}
                >
                  <KeyboardTimePicker
                    clearable
                    value={this.state.taskTimeExecution}
                    variant="inline"
                    style={{ borderColor: '#039FC0' }}
                    defaultValue="00:00"
                    inputVariant="outlined"
                    onChange={(date) =>
                      this.setState({
                        taskTimeExecution: date,
                      })
                    }
                    minDate={new Date()}
                    format="HH:mm"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>

            {this.state.groups && (
              <Grid xs={6} item>
                <div className="item_form">
                  <h2>{language.TECH_GROUP}</h2>
                  {this.state.taskTechGroup ? (
                    <CustomAutocomplete
                      defaultValue={this.state.taskTechGroup}
                      onChange={(evt, value) =>
                        this.setState({ taskTechGroup: value })
                      }
                      disableClearable
                      multiple
                      options={this.state.groups && this.state.groups}
                      id="respOptions"
                      renderInput={(params) => (
                        <CustomTextField {...params} variant="outlined" />
                      )}
                      getOptionLabel={(option) =>
                        `${option.value} - ${option.label}`
                      }
                      renderOption={(option) => {
                        return (
                          <span>{`${option.value} - ${option.label}`}</span>
                        );
                      }}
                    />
                  ) : (
                    <CustomAutocomplete
                      onChange={(evt, value) =>
                        this.setState({ taskTechGroup: value })
                      }
                      disableClearable
                      multiple
                      options={this.state.groups && this.state.groups}
                      id="respOptions"
                      renderInput={(params) => (
                        <CustomTextField {...params} variant="outlined" />
                      )}
                      getOptionLabel={(option) =>
                        `${option.value} - ${option.label}`
                      }
                      renderOption={(option) => {
                        return (
                          <span>{`${option.value} - ${option.label}`}</span>
                        );
                      }}
                    />
                  )}
                </div>
              </Grid>
            )}

            <Grid xs={12} item>
              <div className="item_form">
                <h2>{language.DESCRIPTION?.toLocaleUpperCase()}&npsp;* </h2>

                <ReactQuill
                  style={{ width: '97%', borderColor: '#039FC0' }}
                  theme="snow"
                  value={this.state.taskDescription || ''}
                  onChange={(evt) => this.setState({ taskDescription: evt })}
                />
              </div>
            </Grid>
            <br />

            <Grid xs={12} container>
              <Grid xs={4} item>
                <RaisedButton
                  color="success"
                  id="send"
                  icon="fas fa-save"
                  style={{ fontSize: 18, marginTop: 45 }}
                  label={language.SAVE}
                  onClick={this.saveNewTask}
                />
              </Grid>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          title={
            <TextField
              id="nameTemp"
              name="nameTemp"
              value={this.state.nameTemp}
              onChange={(evt) => {
                this.setState({ nameTemp: evt.target.value });
              }}
              label={language.NAME_FORM}
              variant="outlined"
              className='subject-ticket'
            />
          }
          open={this.state.modal}
          maxWidth="xl"
          onClose={() => this.closemodal(false)}
        >
          <div id="fb-editor" />
          <form id="fb-render" />
          <div>
            <div
              className="d-flex align-items-center justify-content-center my-2"
              style={{ float: 'right' }}
            >
              {this.state.edit && (
                <RaisedButton
                  circleButton
                  color="primaryGradient"
                  id="load"
                  icon="fas fa-spinner"
                  style={{ fontSize: 18 }}
                  label={language.TO_CHARGE}
                />
              )}
              <RaisedButton
                circleButton
                color="danger"
                id="clearField"
                icon="fas fa-eraser"
                style={{ fontSize: 18 }}
                label={language.CLEAR}
                className="mx-2"
              />
              <RaisedButton
                circleButton
                color="primaryGradient"
                icon="fas fa-eye"
                id="showData"
                style={{ fontSize: 18 }}
                label={language.VISUALIZE}
                className="mr-2"
              />
              <RaisedButton
                circleButton
                color="success"
                id="send"
                icon="fas fa-save"
                style={{ fontSize: 18 }}
                label={language.SAVE}
                onClick={this.handleModal}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          open={this.state.openDeleteTask}
          title={language.WARNING_DELETE_ITEM}
        >
          {this.state.taskDelete.name}
          <RaisedButton
            circleButton
            icon="far fa-trash-alt"
            label={language.DELETE}
            color="danger"
            style={{ fontSize: 13, width: 21, height: 21 }}
            onClick={() => this.submitDeleteTask()}
          />
        </Dialog>

        {sv == true ? (
          <div>
            <CommonTable
              beforeBar={
                sv == true ? (
                  <RaisedButton
                    circleButton
                    style={{ zoom: 0.8 }}
                    zoom={0.8}
                    className="mr-3"
                    color="primary"
                    icon="fas fa-plus"
                    label={language.NEW_FORM}
                    onClick={() => this.openModal(true)}
                  />
                ) : null
              }
              columns={col}
              data={data}
              showHeaderToolbar
            />
          </div>
        ) : null}
      </div>
    );
  }
}
