import { Fragment, useState } from 'react';
import './Tree.css';

export const Tree = ({ data, header }) => {
  const [open, setOpen] = useState({});
  return (
    <table className="tree" cellSpacing="0">
      <thead>
        <tr>
          {header.map((item, index) => (
            <th className="tree-header" key={index}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <Fragment>
              <tr
                key={index * 2 + 1}
                className={item.content ? 'tr-hover' : ''}
                style={item.content ? { cursor: 'pointer' } : null}
                onClick={() => {
                  !open[index] && item.onClick && item.onClick();
                  setOpen({ ...open, [index]: !open[index] })
                }}
              >
                {item.row.map((item, indexC) => (
                  <td key={indexC}>{item}</td>
                ))}
              </tr>
              {open[index] && item.content && (
                <tr key={index * 3 + 1}>
                  <td colSpan={item.row.length}>{item.content}</td>
                </tr>
              )}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  );
}