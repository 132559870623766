import React from 'react';
import './styles.css';

const Index = () => {
  return (
    <div class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content">
          <span class="spinner"></span>
        </div>
      </div>
    </div>
  );
};

export default Index;
