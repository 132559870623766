import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

// ------------------------------------------------------------

import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';

// ------------------------------------------------------------

export default observer(
  ({
    setAdvancedSearchModalOpen,
    advancedSearchModalOpen,
    onSubmitAdvancedSearch,
    action,
    APIEndpoints,
  }) => {
    const [search, setSearch] = useState(0);
    const { language } = Store;

    return (
      <Dialog
        open={advancedSearchModalOpen}
        onClose={() => setAdvancedSearchModalOpen(false)}
        title={language.ADVANCED_SEARCH}
        maxWidth="xs"
        monitor_scroll
      >
        <CommonForm
          onChangeField={(f, v) => {
            if (f.name == 'type') {
              setSearch(v);
            }
          }}
          fields={[
            {
              type: 'select',
              name: 'type',
              label: language.FIELD_TYPE,
              options: [
                { value: 'hadware', label: 'Hadware' },
                { value: 'software', label: language.SOFTWARE },
              ],
            },
            {
              type: 'text',
              name: 'search',
              label: language.SEARCH,
              disabled: !search,
            },
          ]}
          button={{
            label: language.SEARCH,
          }}
          loading="search_advanced_search"
          onSubmit={onSubmitAdvancedSearch}
        />
      </Dialog>
    );
  }
);
