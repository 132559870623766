import Store from '@data/Store';

const { language } = Store;

export default function getMonth(month) {
    const meses = language.DATE_LANG.monthLongList.reduce(function (result, item, index) {
        const key = `${index < 9 ? 0 : ''}${index + 1}`
        return {
            ...result,
            [key]: item
        }
    }, {});

    return meses[month];
}