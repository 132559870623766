import React, { useReducer } from 'react';
/*-------------- commons -------------*/
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import BarBlue from '@images/BarBlue.png';
import { formatCurrency } from '../../../../util';
import Store from '@data/Store';

const { language } = Store;

/* ------------------Material-ui --------------------- */
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


export default ({ open, onClose, otherCusts = [], techCusts = [] }) => {
  let [show, setShow] = useReducer((state, v) => state === v ? null : v, null);
  return (
    <Dialog
      PaperProps={{
        id: 'DialogModalValueRealized'
      }}
      open={open}
      onClose={onClose}
      bar={false}
      maxWidth='lg'

      contentProps={{
        style: {
          background: 'white'
        }
      }}
      dialogChildren={
        <div
          className="d-flex align-items-start justify-content-end bar"
          style={{
            background: `url(${BarBlue})`,
            borderBottom: '3px solid var(--warning)'
          }}
        >
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      }
    >
      <div>
        <h2 className="title-cust">{language.TECHNICAL_COST}</h2>
        <CommonTable
          pagination={true}
          bar={true}
          className='mt-2 table-tec'
          col={[
            { key: 'btn', label: '', thConfig: true, style: { width: '5%' } },
            { key: 'user', label: language.TECH, thConfig: true, style: { width: '45%' } },
            { key: 'total', label: language.TOTAL_VALUE_IN_THE_PROJECT, thConfig: true, style: { width: '50%' } }
          ]}
          AppBarProps={{
            dark: true,
            className: 'py-0 pr-0',
            style: {
              borderRadius: '10px 10px 0 0'
            }
          }}
          data={techCusts.flatMap((e, i) => [
            {
              btn: (
                <div className='btn' onClick={() => setShow(i)}>
                  <i className={show === i ? 'fas fa-chevron-down' : 'fas fa-chevron-right'} />

                </div>
              ),
              user: e.user,
              total: formatCurrency(e.total)
            },
            show === i ? {
              ConfigCell: {
                cols: {
                  col_0: { colSpan: 5, style: { paddingLeft: 30, paddingRight: 30 } }
                }
              },
              'btn': <CommonTable
                bar={false}
                pagination={false}
                className='table-activities'
                col={[
                  { key: 'activity', label: language.ACTIVITY_NAME, thConfig: true, style: { width: '50%' } },
                  { key: 'timeActivity', label: language.TIME_CONSUMED, thConfig: true, style: { width: '25%' } },
                  { key: 'valueConsumed', label: language.CONSUMED_VALUE, thConfig: true, style: { width: '25%' } }
                ]}
                data={e.activities.map(ac => (
                  {
                    activity: ac.activity,
                    timeActivity: ac.timeActivity,
                    valueConsumed: ac.valueConsumed
                  }
                ))}
              />
            } : null
          ]).filter(Boolean)}
          key="user"
        />
        <hr />
        <h2 className="title-cust">{language.OTHER_PROJECT_COSTS}</h2>
        <CommonTable
          pagination={true}
          bar={true}
          className='mt-2'
          col={[
            { key: 'id_project_others_custs', label: 'ID', thConfig: true, style: { width: '5%' } },
            { key: 'title', label: language.TITLE, thConfig: true, style: { width: '40%' } },
            { key: 'description', label: language.COST_DESCRIPTION, thConfig: true, style: { width: '40%' } },
            { key: 'value_cust', label: language.COST_VALUE, thConfig: true, style: { width: '15%' } },

          ]}
          AppBarProps={{
            dark: true,
            className: 'py-0 pr-0',
            style: {
              borderRadius: '10px 10px 0 0'
            }
          }}
          data={otherCusts}
          key="id_project_others_custs"
        />
      </div>
    </Dialog>
  )
}