import { Button, Link } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useEffect, useState } from 'react';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import ServicePortalCatalogForm from './ServicePortalCatalogForm';
import ServicePortalCatalogItem from './ServicePortalCatalogItem';
import ServicePortalModal from './ServicePortalModal';
import { useServicePortalStyles } from './helpers';

export default function ServicePortalCatalogItems({ items, language, catalogSelected, allowCustomizePriority, isIntegrationEnabled, enableLocationInOpeningTicket, getRoute, onCatalogSelect }) {

    const SUFIX = ': ';
    const COMPANY = language['SERVICE_PORTAL']['COMPANY'];
    const CATEGORY = language['SERVICE_PORTAL']['CATEGORY'];
    const SUBCATEGORY = language['SERVICE_PORTAL']['SUBCATEGORY'];
    const TASK = language['SERVICE_PORTAL']['TASK'];
    const SELECT_CATEGORY = language['SERVICE_PORTAL']['SELECT_CATEGORY'] + SUFIX;
    const SELECT_SUBCATEGORY = language['SERVICE_PORTAL']['SELECT_SUBCATEGORY'] + SUFIX;
    const SELECT_TASK = language['SERVICE_PORTAL']['SELECT_TASK'] + SUFIX;

    const thirdLevel = items
        ?.find(item => item.id == catalogSelected.firstLevel)
        ?.secondLevel.find(item => item.id == catalogSelected.secondLevel)
        ?.thirdLevel || [];
    const hasThirdLevel = thirdLevel.length > 0;
    const category = thirdLevel?.find(item => item.id == catalogSelected.thirdLevel);
    const subcategories = category?.subcategories || [];
    const subcategory = subcategories?.find(item => item.id == catalogSelected.subcategory);
    const tasks = subcategory?.tasks || [];
    const task = tasks?.find(item => item.id == catalogSelected.task);

    const styles = useServicePortalStyles();

    const [formSchema, setFormSchema] = useState();
    const [knowledge, setKnowledge] = useState([]);

    useEffect(async () => {
        if (catalogSelected.task) {
            const urlForm = `${Constants.APIEndpoints.TASK}/getformticket/${catalogSelected.task}`;
            const fetch = new Actions();
            await fetch
                .execute('get', urlForm, '')
                .then(async ({ data }) => setFormSchema(data))
        }
        else setFormSchema(null)
    }, [catalogSelected.task])

    useEffect(async () => {
        if (catalogSelected.subcategory) {
            const companyId = catalogSelected.firstLevel;
            const subcategoryId = catalogSelected.subcategory;
            const urlKnowledge = `${Constants.APIEndpoints.SOLUTION}/company/${companyId}/subcategory/${subcategoryId}`;

            const fetch = new Actions();
            await fetch
                .execute('get', urlKnowledge, '', {})
                .then(async ({ data }) => {
                    setKnowledge(data)
                })
        }
    }, [catalogSelected.subcategory])



    function handleSelectCatalogFromRoute(type, forceRoute = false) {
        onCatalogSelect(type, getRoute(type), forceRoute);
    }

    function handleSelectCategory(type, item) {
        onCatalogSelect(type, item.id);
    }

    function handlePreviousLevel(level) {
        (level === -1 || task) && handleSelectCatalogFromRoute('subcategory', true);
        (level === -2 || (!task && subcategory)) && handleSelectCatalogFromRoute('thirdLevel', true);
        (level === -3 || (!task && !subcategory && category)) && handleSelectCatalogFromRoute('secondLevel', true);
    }


    //fix catalog based on router
    hasThirdLevel && !category && handleSelectCatalogFromRoute('thirdLevel');
    hasThirdLevel && category && !subcategory && handleSelectCatalogFromRoute('subcategory');
    hasThirdLevel && category && subcategory && !task && handleSelectCatalogFromRoute('task');

    let breadcrumbs = [];
    thirdLevel.length > 0 && breadcrumbs.push(category ? CATEGORY + SUFIX + category.name : SELECT_CATEGORY);
    subcategories.length > 0 && breadcrumbs.push(subcategory ? SUBCATEGORY + SUFIX + subcategory.name : SELECT_SUBCATEGORY);
    tasks.length > 0 && breadcrumbs.push(task ? TASK + SUFIX + task.name : SELECT_TASK);

    return (
        <div className='tw-w-full'>
            {thirdLevel.length > 0 &&
                <div className='tw-flex tw-justify-between tw-items-center'>
                    <Breadcrumbs color='inherit' separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        {breadcrumbs.map((item, index) => {
                            const lastChild = index == breadcrumbs.length - 1;
                            if (!lastChild) {
                                return <Link key={item} color="primary" href="/" className={styles.text}
                                    onClick={event => {
                                        event.preventDefault();
                                        handlePreviousLevel(-(breadcrumbs.length - (index + 1)))
                                    }}>
                                    {item}
                                </Link>
                            }
                            return <Typography key={item} component='span' className={styles.text}>{item}</Typography>
                        })}
                    </Breadcrumbs>
                    <div>
                        <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ArrowBack />}
                            onClick={handlePreviousLevel}
                            disabled={!category}
                        >
                            {language.BACK}
                        </Button>
                    </div>
                </div>
            }

            <div className='tw-flex tw-flex-wrap tw-gap-4 tw-py-4'>

                {!task && subcategory && Array.isArray(knowledge) && knowledge.length > 0 && (
                    <div className='tw-border tw-border-r-0 tw-border-l-0 tw-w-full'>

                        <div className='tw-flex tw-items-center tw-gap-2 py-3'>
                            <Typography className='tw-mb-0' color="textSecondary" gutterBottom>
                                Ajuda:
                            </Typography>
                            {knowledge.map(item =>
                                <ServicePortalModal {...item} language={language} />
                            )}
                        </div>
                    </div>
                )}

                {!category && thirdLevel.map((categoryItem) => (
                    <ServicePortalCatalogItem
                        language={language}
                        key={'c_' + categoryItem.id}
                        title={CATEGORY}
                        item={categoryItem}
                        onSelectItem={(item) => handleSelectCategory('thirdLevel', item)}
                    />
                ))}

                {!subcategory && subcategories.map((subcategoryItem) => (
                    <ServicePortalCatalogItem
                        language={language}
                        key={'sc_' + subcategoryItem.id}
                        title={SUBCATEGORY}
                        item={subcategoryItem}
                        onSelectItem={(item) => handleSelectCategory('subcategory', item)}
                    />
                ))}

                {!task && tasks.map((taskItem) => (
                    <ServicePortalCatalogItem
                        language={language}
                        key={'t_' + taskItem.id}
                        title={TASK}
                        item={taskItem}
                        showDescription={false}
                        onSelectItem={(item) => handleSelectCategory('task', item)}
                    />
                ))}

                {task && formSchema &&
                    <ServicePortalCatalogForm
                        key={formSchema.fk_id_catalog_task}
                        task={task}
                        formSchema={formSchema}
                        catalogSelected={catalogSelected}
                        allowCustomizePriority={allowCustomizePriority}
                        isIntegrationEnabled={isIntegrationEnabled}
                        enableLocationInOpeningTicket={enableLocationInOpeningTicket}
                    />
                }
            </div>
        </div>
    )
}
