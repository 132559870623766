import React from 'react';

// ----------------------------------------------------------------------

import CommonDelete from '@common/CommonDelete';
import Store from '@data/Store';

import { deleteChannelByIdService } from '../Channel.services';

// ----------------------------------------------------------------------

export const ChannelDelete = ({ closeModal, id, message }) => {
  const { language } = Store;

  const handleDelete = () => {
    deleteChannelByIdService({ id }).then(() => {
      closeModal();
    });
  };

  return (
    <CommonDelete
      language={language}
      closeModal={closeModal}
      onDelete={handleDelete}
      message={message}
    />
  );
};

export default ChannelDelete;
