import trendLine from './trendLine';

const ContainerChart = {
	container0(e){
    let { language, data, name } = e;
    
    if((typeof data)!='string'){
      data = JSON.parse(JSON.stringify(data.reverse()));
    }else{
      data = [];
    }

    let sourceData = data;
    return {
  		chart: {
        type: 'column'
      },
      title: {
        text: name
      },
      subtitle: {
        text: language.CONTRACT_CONSUMPTION
      },
      xAxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
          ],
          crosshair: true
      },
      yAxis: {
            min: 0,
            title: {
                  text: language.UTILIZATION
            }
      },
      tooltip: { shared: true },
     /* tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0;color:{series.color};"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },*/
      plotOptions: {
            column: {
                  pointPadding: 0.2,
                  borderWidth: 0
            }
      },
      series: [
        {
          name: language.SPENT,
          data: sourceData.map(e=>e[1]),
          color: '#009dc0',
          tooltip: { valuePrefix: language.CURRENCY + ' ' },

        }
      ]
    }
	},
  container3(e){
    let { language, data, name } = e;
    
    if((typeof data)!='string'){
      data = JSON.parse(JSON.stringify(data.reverse()));
    }else{
      data = [];
    }


    let sourceData = data;
    let line = trendLine(sourceData.map((a, k) => [k, a[1]]), 2);
    
    return {
      title: {
        text: language.TREND
      },

      xAxis: {
        categories: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
          'Média'
        ],
        crosshair: true
      },

      yAxis: {
        min: 0,
        max: 100
      },

      tooltip: {
        headerFormat: '',
        pointFormat: '{point.y}'
      },

      series: [{
        name: language.TREND_LINE,
        data: line.points,
        pointStart: 0
      }]
    }
  },
	container1:{
	  chart: {
        type: 'solidgauge',
      },   
      title: {
        text: 'Lorem ipsum dolor'
      },
      subtitle: {
        text: 'sit amet, consectetur adipiscing elit'
      },

      pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },

      tooltip: {
        enabled: false
      },

      plotOptions: {
        solidgauge: {
          fillOpacity: 0.5,
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Utilização',
          y: 0
        },
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#DF5353'] // red
        ],
        lineWidth: 0,
        minorTickInterval: 10,
        tickAmount: 2,
        labels: {
          y: 15
        }
      },
      credits: {
        enabled: false
      },

     /* series: [{
        name: 'Utilização',
        data: [80],
        dataLabels: {
          format: '<div style="text-align:center"><span style="font-size:25px;color:black">{y}%</span></div>',
          y: -60    
        },
        tooltip: {
              valueSuffix: '%'
        }
      }]*/
	},
	container2:{
	  chart: {
        type: 'bar'
      },
      title: {
        text: 'Lorem ipsum dolor'
      },
      xAxis: {
        categories: ['GI', 'GSD', 'GP', 'GR']
      },
      yAxis: {
        min: 0,
        max: 100,
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Porcentagem',
        data: [50, 35, 42, 71],
        tooltip: {
          valueSuffix: '%'
        }
      }]
	},
	
	container4:{
	  chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      colors: ['#009dc0', '#c12c2c'],
      title: {
        text: 'Análise SLA',
        align: 'center',
        verticalAlign: 'middle',
        y: 40
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      series: [{
        type: 'pie',
        name: '% de SLA',
        innerSize: '50%',
        data: [
          ['OK', 75],
          ['Nok', 25]
        ]
      }]
	},
	container5:{
	  chart: {
        type: 'bar'
      },
      colors: ['#efa824'],
      title: {
        text: 'Lorem ipsum dolor'
      },
      xAxis: {
        categories: ['OS', 'Ticket', 'Suporte', 'Projeto']
      },
      yAxis: {
        min: 0,
        max: 100,
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Porcentagem',
        data: [50, 35, 42, 71],
        tooltip: {
          valueSuffix: '%'
        }
      }]
	}
}
export default ContainerChart;