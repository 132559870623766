import React, { useState, useEffect } from 'react';
import CommonTable from '@common/CommonTable';
import { Tooltip } from 'react-tippy';
import PageLayout from '@common/PageLayout';
import { Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Progress from '@common/Progress';
import Button from '@common/Button';

import moment from 'moment';

// --------------------------------icons----------------------------------

export default function({ activity, id_activity, id_project_activity }) {
  let [times, setTimes] = useState([]);

  let [progress, SetProgress] = useState(0);

  const projectstatusf = fk_id_project => {
    let per = null;
    if (fk_id_project != null) {
      try {
        let percentProject = activity
          .filter(
            x => parseInt(x.id_project_activity) === parseInt(fk_id_project)
          )
          .map(c => {
            return c.percent;
          });

        SetProgress(parseInt(percentProject[0]));
      } catch (e) {}
    }
    return per;
  };
  const projecnameActivity = fk_id_project => {
    let per = [];
    if (fk_id_project != null) {
      try {
        let percentProject = activity
          .filter(
            x => parseInt(x.id_project_activity) === parseInt(fk_id_project)
          )
          .map(c => {
            return c.project + ' - ' + c.name;
          });
        per = percentProject[0];
      } catch (e) {}
    }
    return per;
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      projectstatusf(id_project_activity);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="d-flex align-items-center" style={{ width: 100 }}>
      <Progress
        hiddenText
        current={progress}
        style={{ height: 7, width: '100%' }}
        background="var(--danger)"
      />
      <span style={{ color: '#707070', fontSize: 10 }} className="ml-2">
        {progress}%
      </span>
    </div>
  );
}
