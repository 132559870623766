import { useEffect, useState } from 'react';
import CommonForm from '../../common/CommonForm2';

export default function AddChangeTemplateForm({
  language,
  catalogTask,
  APIEndpoints,
  action,
  store,
  onSuccess,
}) {
  const [changeState, setChangeState] = useState(undefined);

  useEffect(() => {
    if(catalogTask?.fk_id_change_template){
      loadChange();
    }
  }, []);

  function loadChange() {
    store.spinner = true;
    action
      .execute(
        'get',
        `${APIEndpoints.CHANGE}/${catalogTask.fk_id_change_template}`,
        '',
      )
      .then(
        (result) => {
          console.log('result change: ', result);
          store.spinner = false;
          setChangeState(result.data);
        },
        (error) => {
          let messageError = language.ERROR_REQUEST;
          store.spinner = false;
          store.toggleAlert(true, messageError, 'error');
        },
      );
  }

  function onFormSubmit(values) {
    let data = catalogTask;
    delete data['fk_id_change_template'];
    data.fk_id_change_template = values.fk_id_change_template;
    data.fk_id_group = values.fk_id_group;

    store.spinner = true;
    action
      .execute(
        'put',
        `${APIEndpoints.TASK}/${catalogTask.id_catalog_task}`,
        '',
        data,
      )
      .then(
        () => {
          store.spinner = false;
          store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
          onSuccess();
        },
        (error) => {
          let messageError = language.ERROR_REQUEST;
          store.spinner = false;
          store.toggleAlert(true, messageError, 'error');
        },
      );
  }

  const values = {
    fk_id_change_template: catalogTask.fk_id_change_template,
    fk_id_group: catalogTask.fk_id_group
  };

  const fields = [
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_change_template',
      label: 'Gmud',
      textlabel: 'Gmud',
      method: 'POST',
      route: `${APIEndpoints.CHANGE}/getAllChanges/`,
      routeGetCount: `${APIEndpoints.CHANGE}/getAllChanges?count=1`,
      fieldquery: 'search',
      filterAll: { template: true, new: true },
      filter: { template: true },
      nosearch: true,
      fieldvaluedb: 'id_change',
      fieldlabeldb: 'reason',
      textinit: changeState ? changeState?.id_change + ' - ' + changeState?.reason : '',
      idinit: catalogTask?.fk_id_change_template || 0,
      routeAll: `${APIEndpoints.CHANGE}/getAllChanges?`,
      methodGetAll: 'POST',
      required: true,
      disablePortal: true,
      showId: true,
      idFieldToShow: 'id_change',
    },
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_group',
      textlabel: language.TECH_GROUP,
      method: 'POST',
      route: `${APIEndpoints.GROUP}/filter`,
      fieldquery: 'search',
      textinit: catalogTask && catalogTask.group_name ? catalogTask.group_name : '',
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      idinit: catalogTask?.fk_id_group || 0,
      routeAll: `${APIEndpoints.GROUP}/all?`,
      detail: true,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.GROUP}/count`,
    },
  ];

  return (
    <CommonForm
      button={language.SAVE}
      onSubmit={onFormSubmit}
      fields={fields}
      values={values}
    />
  );
}
