import React, { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import { useServicePortalStyles } from '../helpers';
import ServicePortalModalReopenTicket from './ServicePortalModalReopenTicket';
import classNames from 'classnames';
import Flag from './Flag';
import { useHistory } from "react-router-dom";

export default function ServicePortalTicketHeader({ ticketData, ticketParams, language, onCloseTicket, onReload, onReopenTicket }) {

    const styles = useServicePortalStyles();
    const history = useHistory();
    const [openDialogReopenTicket, setOpenDialogReopenTicket] = useState(false);

    const handleDialogClose = () => {
        setOpenDialogReopenTicket(false);
    };

    const handleDialogConfirm = (value) => {
        onReopenTicket(value);
        handleDialogClose();
    }

    return (
        <>
            <div>
                <h2 className='tw-text-2xl tw-font-bold'>
                    {ticketData._subject}
                </h2>
            </div>
            <div className={classNames('tw-flex tw-justify-between tw-px-3 tw-py-2 tw-mt-2 tw-mb-4 tw-rounded tw-uppercase tw-border', styles.bgGray)}>
                <div className='tw-flex tw-gap-5'>
                    <Flag label={language.PRIORITY} flagLabel={ticketData._priorityLabel} flagColor={ticketData._priorityColor} />
                    <Flag label={language.URGENCY} flagLabel={ticketData._urgencyLabel} flagColor={ticketData._urgencyColor} />
                    <Flag label={language.IMPACT} flagLabel={ticketData._impactLabel} flagColor={ticketData._impactColor} />
                </div>
                <div className='tw-flex tw-gap-3 tw-items-center'>

                    <Button
                        size="small"
                        classes={{ root: styles.buttonSmallRoot, label: styles.buttonSmallLabel }}
                        onClick={() => history.goBack()}
                    >
                        <i className='fas fa-arrow-left' />
                        {language.BACK}
                    </Button>

                    <IconButton
                        aria-label={language.REFRESH_PAGE}
                        className={styles.iconButton}
                        onClick={onReload}
                    >
                        <RefreshOutlined fontSize="small" />
                    </IconButton>

                    {
                        ticketParams.evaluate_ticket == true &&
                        ticketData._isOwner &&
                        ticketData._isClosedOrResolved
                        && (
                            <Button size="small" classes={{ root: styles.buttonSmallRoot, label: styles.buttonSmallLabel }}
                                onClick={() => window.open(ticketData.config[0].avaliation_token, '_blank')}>
                                <i className='fas fa-user-tag' />
                                {language.EVALUATE_CALL}
                            </Button>
                        )
                    }

                    {
                        ticketData.status === 'Resolved' &&
                        ticketData._isOwner
                        && (
                            <Button size="small" classes={{ root: styles.buttonSmallRoot, label: styles.buttonSmallLabel }}
                                onClick={() => setOpenDialogReopenTicket(true)}>
                                <i className='fas fa-lock-open' />
                                {language.REOPEN_TICKET}
                            </Button>
                        )
                    }

                    {
                        ticketData.status === 'Resolved' &&
                        ticketData._isOwner
                        && (
                            <Button size="small" classes={{ root: styles.buttonSmallRoot, label: styles.buttonSmallLabel }}
                                onClick={() => onCloseTicket(ticketData, { action: 'closed' })}>
                                <i className='fas fa-times-circle' />
                                {language.TICKET_STATUS.CLOSEDDETAIL}
                            </Button>
                        )
                    }
                </div>
            </div>

            <ServicePortalModalReopenTicket
                language={language}
                isOpen={openDialogReopenTicket}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />

        </>
    )
}
