import React, { useState, useCallback, useContext, useEffect } from 'react';
import AppBar from '@common/AppBar2';
// -----------------------------------material-ui--------------------------------------------
import { Slider } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
// -----------------------------------pages--------------------------------------------
import BtnControl from './BtnControl';
import ConfigPrioritizationContext from './ConfigPrioritizationContext';
import { parse } from 'date-fns';
import Button from '@common/Button';

const theme = createTheme({
  palette: {
    primary: { main: '#33ACA0' },
  },
});

export default function ({ title, col, toggleAlert, language }) {
  const { APIEndpoints, action, prioritizationTotal, total, setTotal } =
    useContext(ConfigPrioritizationContext);
  const [positionData, setPositionData] = useState([]);
  const [prioritization, setPrioritization] = useState([]);

  useEffect(() => {
    action
      .execute(
        'get',
        APIEndpoints.PROJECT + '/prioritization',
        'prioritization'
      )
      .then((h) => setPrioritization(h.data));
  }, [APIEndpoints, action]);

  useEffect(() => {
    if (prioritization && prioritization.length > 0) {
      let data = [];

      prioritization.forEach((p) => {
        if (p.id_scale === 1) {
          data.push({
            label: language.PRIORITIZATION.VERY_HIGH,
            id: 1,
            position: parseInt(p[col]),
            color: '#FE322B',
          });
        }
        if (p.id_scale === 2) {
          data.push({
            label: language.PRIORITIZATION.HIGH,
            id: 3,
            position: parseInt(p[col]),
            color: '#FA9633',
          });
        }
        if (p.id_scale === 3) {
          data.push({
            label: language.PRIORITIZATION.MEDIUM,
            id: 9,
            position: parseInt(p[col]),
            color: '#F8DB38',
          });
        }
        if (p.id_scale === 4) {
          data.push({
            label: language.PRIORITIZATION.SMALL,
            id: 11,
            position: parseInt(p[col]),
            color: '#C9E834',
          });
        }
        if (p.id_scale === 5) {
          data.push({
            label: language.PRIORITIZATION.VERY_SMALL,
            id: 31,
            position: parseInt(p[col]),
            color: '#97F035',
          });
        }

        if (p.id_scale === 6) {
          setProgressValue(parseInt(p[col]) || 0);
        }
      });

      setPositionData(
        data.sort((a, b) => {
          let comp = 0;

          if (a.id > b.id) {
            comp = 1;
          }
          if (a.id < b.id) {
            comp = -1;
          }

          return comp;
        })
      );

      const list = prioritization;
      let total_ = 0;
      getWeight(list).then((value) => {
        const {
          benefit_amount = 0,
          complexity = 0,
          desire_directors = 0,
          financial_feedback = 0,
          generates_costs = 0,
          involved_areas = 0,
          processes_improvement = 0,
          risk_if_not_done = 0,
          strategic_alignment = 0,
          urgency = 0,
          innovation = 0,
        } = value;

        if (benefit_amount) {
          total_ += parseInt(benefit_amount);
        }
        if (complexity) {
          total_ += parseInt(complexity);
        }
        if (desire_directors) {
          total_ += parseInt(desire_directors);
        }
        if (financial_feedback) {
          total_ += parseInt(financial_feedback);
        }
        if (generates_costs) {
          total_ += parseInt(generates_costs);
        }
        if (involved_areas) {
          total_ += parseInt(involved_areas);
        }
        if (processes_improvement) {
          total_ += parseInt(processes_improvement);
        }
        if (risk_if_not_done) {
          total_ += parseInt(risk_if_not_done);
        }
        if (strategic_alignment) {
          total_ += parseInt(strategic_alignment);
        }
        if (urgency) {
          total_ += parseInt(urgency);
        }
        if (innovation) {
          total_ += parseInt(innovation);
        }

        setTotal(total_);
      });
    }
  }, [prioritization]);

  let [ProgressValue, setProgressValue] = useState(0);

  const handleOnClickWeight = useCallback(async () => {
    if (prioritization.length > 0) {
      const beforeValue = prioritization.find((x) => x.id_scale === 6)[col];

      if (
        total - parseInt(beforeValue) + ProgressValue <=
        prioritizationTotal
      ) {
        await UpPrioritization(6, col, ProgressValue);
        setPrioritization((prio) => {
          return prio.map((x) => {
            if (x.id_scale === 6) {
              let obj = {
                ...x,
                [col]: ProgressValue,
              };
              return obj;
            }
            return x;
          });
        });
      } else {
        toggleAlert(true, language.TOTAL_SUM_MORE_100, 'error');
        setProgressValue(
          parseInt(prioritization.find((x) => x.id_scale === 6)[col])
        );
      }
    }
  }, [prioritization, total, ProgressValue]);

  const getWeight = (list) => {
    return new Promise((resolve) => {
      return resolve(list.filter((x) => x.id_scale === 6)[0]);
    });
  };

  const UpPrioritization = useCallback(
    async (idscale, col, value) => {
      let teste = { col: col, id_scale: idscale, value: value };

      await action.execute(
        'post',
        APIEndpoints.PROJECT + '/projectprioritization',
        'request',
        teste,
        'id_project'
      );
    },
    [APIEndpoints, action]
  );

  /*
		alí em baixo (BtnControl), no console.log irei retornar os dados nesse formto, por exemplo:
		{id: "11", type: "set", position: 4}

		id -> é o id do botão,
		type -> é o tipo de ação, "set" para prencher um determinado espaço (à esquerda) e "unset" é quando o botão volta pra a coluna de botões (à direita)
		position -> é a posição do botão setada por uma determinada ação, vai de 1 à 5

		pra quem passar por aqui, caso não tenha entendido, você pode brincar e ir testando.
	*/

  return (
    <div className="Card mb-3 h-100">
      <AppBar title={title}>
        <Button
          icon="fas fa-save"
          label={language.SAVE}
          color="success"
          onClick={handleOnClickWeight}
          variant="normal"
        />
      </AppBar>
      <div className="content pt-0 -mt-2">
        <span className="title">{language.WEIGHT}</span>
        <div className="mb-4 d-flex align-items-center">
          <MuiThemeProvider theme={theme}>
            <Slider
              value={ProgressValue}
              min={0}
              max={100}
              step={undefined}
              aria-labelledby="label"
              disabled={false}
              onChange={(evt, v) => {
                setProgressValue((progr) => parseInt(v));
              }}
            />
          </MuiThemeProvider>
          <span style={{ color: '#707070', fontSize: 14 }} className="ml-2">
            {parseInt(ProgressValue)}%
          </span>
        </div>
        <span className="title">{language.SCALE}</span>
        {positionData.length > 0 ? (
          <BtnControl data={positionData} onChange={(v) => console.log(v)} />
        ) : (
          language.LOAD
        )}
      </div>
    </div>
  );
}
