import React, { memo, Fragment, useState, useEffect } from 'react';
/* ----------------------------common------------------------------ */
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import TicketManagement from '../../../TicketManagement';
import moment from 'moment';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import DialogFormTicket from '../../../TicketManagement/DialogForm';
import Sla from '../../../TicketManagement/Sla';
import DownloadFiles from '../../../../util/DownloadFiles';

const { language } = Store;

const action = new Actions();

export default memo(
  ({ open, onClose, data = [], type, typeTickets }) => {
    let [idTicket, setIdTicket] = useState(0);
    let [activeRefresh, setActiveRefresh] = useState(false);
    let [catModal, setCatModal] = useState(false);
    let [catValues, setCatValues] = useState([]);
    const [tickets, setTickets] = useState([])
    const [totalTickets, setTotalTickets] = useState(0)


    const [statusAwaiting, setStatusAwaiting] = useState([]);
    useEffect(async () => {
      if (type == "CallsYou" && open == true) {
        await getTicketsCallYou()
      } else if (type == "TotalCalls" && open == true) {
        if (typeTickets > 0) {
          await getTicketsTotalCalls()
        } else {
          setTickets(data)
          setTotalTickets(data.length)
        }
      } else if (type == "Calls" && open == true) {
        setTickets(data)
        setTotalTickets(data.length)
      }
    }, [type, open])

    const getTicketsCallYou = async (page = 0, items = {}, rowPerPage = 75, type) => {
      let limit = rowPerPage;
      items.isHome = true;
      items.callsYou = true;

      return new Promise((resolve) => {
        action
          .execute('post', `${Constants.APIEndpoints.TICKET}/ticketFind?limit=${limit}&offset=${page}`, 'ticketList', items)
          .then((res) => {
            setTickets([...res.data]);
            setTotalTickets(res.totalCount);
            resolve();
          })
          .catch((error) => {
            console.log(error)
          });
      });
    }

    const getTicketsTotalCalls = async (page = 0, items = {}, rowPerPage = 75, type) => {
      let limit = rowPerPage;
      items.isHome = true;
      items.totalCalls = true;
      items.typeTickets = typeTickets;

      action
        .execute('post', `${Constants.APIEndpoints.TICKET}/ticketFind?limit=${limit}&offset=${page}`, '', items)
        .then((res) => {
          setTickets([...res.data]);
          setTotalTickets(res.totalCount);
        })
        .catch((error) => {
          console.log(error)
        });
    }

    function decryp(aMsg) {
      var aSecret = 'Orch3str0!@forever!';
      try {
        aMsg = aMsg.replace(/\s/g, '');
        let decipher, tRet;
        decipher = crypto.createDecipher('aes-256-cbc', aSecret);

        tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

        tRet += decipher.final('utf8');
        return tRet;
      } catch (e) {
        return {};
      }
    }

    const req = (t) => {
      let req = [];
      if (t.dt_cad_request != null) {
        req = [
          {
            dt_up: t.dt_end_request,
            dt_cad: t.dt_cad_request,
            id_request: t.fk_id_request,
          },
        ];
      }
      return req;
    }

    useEffect(() => {
      // let iss = JSON.parse(decryp(sessionStorage.getItem('params')));
      action
        .execute('get', Constants.APIEndpoints.STATUS_AWAITING, 'status_awaiting')
        .then((e) => {
          setStatusAwaiting(e.data);
        })
        .catch((error) => { });

      // if (iss.stop_sla == true) {
      //   setstopSlaParams(true);
      // } else {
      //   setstopSlaParams(false);
      // }
    }, []);

    const ondonwFile = (id, file) => {
      DownloadFiles(Constants.APIEndpoints.REQUESTS + '/downloadrequest/' + id + '&' + file, file)
    };

    const catHandle = (values) => {
      if (Store.getUserLoged().role === 'solicitant') {
        Store.toggleAlert(
          true,
          language.USER_NOT_ALLOWED_TO_CATEGORIZE,
          'error'
        );
      } else {
        setCatModal(true);
        setCatValues(values);
      }
    };

    const onFormSubmitItem_ = (values) => {
      let filesSend = [];
      let cont = 0;

      let total = values.files.length;
      Array.from(values.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
            //  this.setState({ sendFile: false });
            filesSend = [];
            //this.state.files = [];
            cont = 0;
          }
          //  console.log(values);
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;

          if (total === cont) {
            // console.log(JSON.stringify(filesSend));
            values.file = filesSend;
            let tokenApi = Store.getToken();

            action
              .execute('post', `${Constants.APIEndpoints.TICKET}`, '', values)
              .then((results) => {
                window.location.reload();
              })
              .then((data) => {
                window.location.reload();
              });
          }
        });
      });

      // this.redirect('/dash/request');
    };

    const statusTicketFilter = [
      { label: 'Aberto', value: 'Open' },
      { label: 'Em atendimento', value: 'In Progress' },
      { label: 'Resolvido', value: 'Resolved' },
      { label: 'Finalizados', value: 'Closed' },
      { label: 'Reabertos', value: 'Reopened' },
      { label: 'Aguardando', value: 'Aguardando' },
      { label: 'Tarefas', value: 'Job' },
    ];

    const getStatus = (ticket) => {

      let status = ticket.status
      let stop_ticket = ticket.stop_ticket

      if (status != 'In Progress' || stop_ticket == 0) {
        let st = statusTicketFilter.filter(statusT => statusT.value == status)[0]
        return st ? st.label : ""
      }
      else if (status == "In Progress" && stop_ticket > 0) {
        let st = statusAwaiting.filter(statusT => statusT.id_status_awaiting == stop_ticket)[0]
        return st ? st.status : ""

      } else {
        let st = statusTicketFilter.filter(statusT => statusT.value == status)[0]
        return st ? st.label : ""
      }
    }

    const onFormSubmitTab = (values) => {
      let contador = 0;
      let id_request = values.fk_id_request;
      if (values.files.length > 0) {
        onFormSubmitItem_(values, id_request);
      } else {
        if (values.ticketArray != undefined && values.ticketArray.length > 1) {
          values.ticketArray.forEach((num) => {
            contador++;
            let item = values.req.filter((x) => x.id_request === num)[0];

            values.fk_id_request = num;
            values.description = item.description;
            values.subject = item.subject;
            values.fk_id_user = item.fk_id_user;
            values.manys = true;

            this.action
              .execute(
                'post',
                Constants.APIEndpoints.TICKET,
                'requests',
                values
              )
              .then((e) => {
                if (contador == values.ticketArray.length) {
                  if (id_request) {
                    //  this.redirect('/dash/gsd');
                    window.location.reload();
                  } else {
                    window.location.reload();
                  }
                }
              });
          });
        } else {
          action
            .execute('post', Constants.APIEndpoints.TICKET, 'ticket', values)
            .then((h) => {
              if (id_request) {
                //              this.redirect('/dash/gsd');
                window.location.reload();
              } else {
                window.location.reload();
              }
            });
        }
      }
    };

    let dataTable = tickets.map((e) => ({
      action: (
        <Button
          icon="fas fa-eye"
          label={language.VISUALIZE}
          color="success"
          variant="normal"
          onClick={() =>
            e.id_tickets > 0
              ? setIdTicket(e.id_tickets)
              : //    window.location.href = "/dash/request"
              catHandle({
                subject: e.subject,
                description: e.description,
                fk_id_user: e.id_user,
                fk_id_request: e.id_request,
                fk_id_company: e.fk_id_company,
                fk_id_category: e.fk_id_category,
                fk_id_catalog_service: e.fk_id_catalog_service,
                fk_id_catalog_task: e.fk_id_catalog_task,
                fk_id_sla: e.fk_id_sla,
                template: e.template !== null ? e.template : null,
                ticketArray: [],
                req: [],
                fk_id_device: e.fk_id_device,
                fk_id_contract: e.fk_id_contract,
                files: e.files,
              })
          }
        />
      ),
      status: getStatus(e),
      id: e.grid_id,
      date: e.date || moment(e.dt_cad).format('L'),
      hour: e.hour || moment(e.dt_cad).format('HH:mm'),
      subject: e.subject,
      // sponsor: e.sponsor || e.name,
      // sla: e.sla,
      sla: e.sla_name_param
        ? e.sla_name_param
        : e.sla_task + `h`,
      sla_time:
        e.sla && e.sla.length > 3 ?
          e.sla && e.sla.indexOf('-') == 4 ? (
            moment(e.sla).format('DD/MM/YY HH:mm:ss')
          ) : e.sla && e.sla.indexOf('-') == 2 ? (
            e.sla
          ) : (
            ' '
          ) : <Sla
            statusAwaiting={{ sla: e.sla_time }}
            t={e}
            stopSlaParams={false}
            horas={undefined}
            req={req(e)}
            language={language}
          />,
      // e.sla,
    }))

    return (
      <Fragment>
        <Dialog
          title={language.TICKET_VIEW}
          open={!!idTicket}
          maxWidth="xl"
          onClose={() => {
            if (activeRefresh) {
              window.location.reload();
            } else {
              setIdTicket(0);
            }
          }}
        >
          <TicketManagement
            detail={true}
            onActiveRefresh={() => {
              setActiveRefresh(true);
            }}
            id_ticket_detail={{ id_tickets: idTicket }}
          />
        </Dialog>

        {catModal == true ? (
          <DialogFormTicket
            store={Store}
            onFormSubmitTab={onFormSubmitTab}
            open={catModal}
            APIEndpoints={Constants.APIEndpoints}
            action={action}
            ondonwFile={ondonwFile}
            values={catValues}
            onClose={() => setCatModal(false)}
          />
        ) : null}

        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="xl"
          bar={false}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          contentProps={{
            style: {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          <CommonTable
            loadingFromPromise
            callPageSelectFilter={async (page, values, rowsPerPage, type) =>
              await getTicketsCallYou(page, values, rowsPerPage, type)
            }
            countTotal={totalTickets}
            isAutomaticPagination={false}

            AppBarProps={{
              style: {
                borderRadius: '10px 10px 0 0',
              },
            }}
            paginationTop={false}
            maxHeight={500}
            afterBar2={
              <Button
                icon="fas fa-times"
                variant="circle"
                outlined
                color="light"
                label={language.CLOSE}
                onClick={onClose}
              />
            }
            col={[
              { key: 'action', label: language.ACTION },
              { key: 'status', label: language.STATE },
              { key: 'id', label: language.ID },
              { key: 'date', label: language.DATE },
              { key: 'hour', label: language.HOUR },
              { key: 'subject', label: language.SUBJECT },
              // { key: 'sponsor', label: 'Responsável' },
              { key: 'sla', label: language.SLA },
              { key: 'sla_time', label: language.SLA_TIME },
            ]}
            data={dataTable}
          />
        </Dialog>
      </Fragment>
    );
  },
  function (prev, next) {
    let omit = (obj) => _.omitBy(obj, (val) => typeof val == 'function');
    return _.isEqual(omit(prev), omit(next));
  }
);
