import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card } from '@material-ui/core';
import Dialog from '../../../common/Dialog';
import CommonTable from '../../../common/CommonTable';
import Store from '../../../data/Store';
import Header from '@common/Header';
import {
  differenceBetweenTime,
  formatDeadline,
  getDeadline
} from '../utils/formatDeadline';
import { getColorStatus } from '../utils/colorsStageSteps';
import Button from '../../../common/Button';
import DetailAttendance from '../common/DetailAttendance';
import { getTotalTimeAttend } from '../utils/getTimeTotalAttend';
import Actions from '@data/Actions';

const action = new Actions();

const AttendanceTasks = ({ APIEndpoints, language }) => {
  const [tasks, setTasks] = useState([]);
  const [countTasks, setCountTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [singleAttendance, setSingleAttendance] = useState({});

  const callPageSelectFilter = (page = 0, values, limit = 75) => {
    setCurrentPage(page);

    action
      .execute('post', `${APIEndpoints.SAC_STEPS}/listUser/${Store.getUserLoged().id}?limit=${limit}&offset=${page * limit}`, '', values)
      .then((data) => {
        setTasks(data.data || []);
        setCountTasks(data.data[0] ? data.data[0].total_count : 0);
      });
  };

  const getStatusStepDesc = (status) => {
    const statusDesc = {
      'In Progress': 'Em atendimento',
      Pending: 'Pendente',
    };

    return statusDesc[status];
  };

  useEffect(() => {
    callPageSelectFilter();
  }, [singleAttendance]);

  let data = tasks?.map((obj) => ({
    ticket: (
      <Button
        onClick={() => {
          action
            .execute('get', APIEndpoints.SAC_ATTENDANCE + '/' + obj.id_attendance_sac, '')
            .then((data) => {
              setSingleAttendance(data.data[0]);
            });
        }}
        variant="circle"
        icon="fas fa-eye"
        className="mx-1"
        label={language.VISUALIZE}
        color="primary"
      />
    ),
    id_attendance_sac: '#' + obj.id_attendance_sac,
    name: obj.name,
    status: (
      <p style={{ color: getColorStatus(obj.stage) }}>
        {getStatusStepDesc(obj.stage)}
      </p>
    ),
    dt_cad: moment(obj.dt_cad).format('DD/MM/YYYY HH:mm'),
    dt_start: obj.dt_start
      ? moment(obj.dt_start).format('DD/MM/YYYY HH:mm')
      : '',
    time_step: obj.status !== 'Resolved' && obj.status !== 'Canceled' ? `${getTotalTimeAttend(moment(obj.dt_cad_step), moment())} hrs` : '',
    deadline: obj.sla_time ? (
      <div className="d-flex align-items-center">
        <div
          style={{
            width: 15,
            height: 15,
            background:
              differenceBetweenTime(
                moment(),
                moment(obj.sla_time)
              ) >= 0
                ? '#3b82f6'
                : '#f52626cf',
            borderRadius: '50%',
            marginRight: 5,
          }}
        />
        {moment(obj.sla_time).format('DD/MM/YYYY HH:mm')}
      </div>
    ) : (
      formatDeadline(obj.deadline) + ' hrs'
    ),
    consumer_name: obj.consumer_name,
    name_group_attend: obj.name_group_attend,
    name_theme: obj.name_theme,
    name_topic: obj.name_topic,
    name_subject: obj.name_subject
  }));

  let col = [
    {
      key: 'ticket',
      label: language.ATTEND,
      style: {  width: 8, textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'id_attendance_sac',
      label: language.ATTENDANCE,
      style: { width: 10, textAlign: 'center' },
    },
    {
      key: 'name',
      label: language.STEP,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'status',
      label: language.STATUS,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'dt_cad',
      label: language.DATE_ATTENDANCE,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'dt_start',
      label: language.DATE_INITIAL_STEP,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'time_step',
      label: language.TIME_TOTAL_STEP,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'deadline',
      label: language.TERM,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'consumer_name',
      label: language.CLIENT,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'name_group_attend',
      label: language.TECH_GROUP,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'name_theme',
      label: language.THEME,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'name_topic',
      label: language.TOPIC,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'name_subject',
      label: language.SUBJECT,
      style: { textAlign: 'left' },
      thConfig: true,
    }
  ];
  return (
    <Card className="content-min px-3">
      {Object.keys(singleAttendance).length > 0 ? (
        <Dialog
          title={''}
          open={true}
          maxWidth="xl"
          onClose={() => setSingleAttendance([])}
        >
          <DetailAttendance
            Attendance={singleAttendance}
            language={Store.language}
            APIEndpoints={APIEndpoints}
            Store={Store}
            setSingleAttendance={setSingleAttendance}
            listAttendace={false}
          />
        </Dialog>
      ) : (
        false
      )}
      <CommonTable
        title={''}
        language={language}
        columns={col}
        data={data}
        index="TaskList"
        showHeaderToolbar={false}
        orderColumn
        countTotal={countTasks}
        isAutomaticPagination={false}
        beginWithNoFilter={true}
        paginationTop={false}
        searchColumn={true}
        loadSearch={true}
        currentPage={currentPage}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
        beforeBar={[
          <Button
            variant="circle"
            icon="fas fa-sync"
            color="warning"
            className="mr-3"
            onClick={() => callPageSelectFilter()}
            tooltipLabel={language.REFRESH_PAGE}
          />,
        ]}
      />
    </Card>
  );
};

export default AttendanceTasks;
