import React, { Component, Fragment } from 'react';
import { Card } from '@material-ui/core';
import Button from '@common/Button';
import { observer } from 'mobx-react';
import $ from 'jquery';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CommonLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm';
import Help from '../../common/components/Help';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import { Container, Row, Col } from 'react-grid-system';
import ReactTooltip from 'react-tooltip';
import Dialog from '@common/Dialog';
import AttachFile2 from '@common/AttachFile2';
import RequestFormProblem from './RequestFormProblem';
import Animate from '@common/Animate';
import { applyTimezoneOnHTML } from '../../common/Timezone';
const crypto = require('crypto');
var CryptoJS = require('crypto-js');
import moment from 'moment';

const styless = (theme) => ({
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '1200px',
  },

  dialogPaperAlert: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '90vh',
  },
  dialogPaper_: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '2000px',
  },
});

function decryp(aMsg = '') {
  try {
    var aSecret = 'Orch3str0!@forever!';
    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');
    return tRet;
  } catch (e) {
    return {};
  }
}

class RequestForm extends Component {
  constructor() {
    super();
    this.state = {
      company: '',
      contract: '',
      category: '',
      catalog: '',
      user: '',
      catalog_task: '',
      id_template: null,
      openModal: false,
      RequestFormProblem: {},
      index: 0,
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_company') {
      this.setState({
        company: value,
      });
    } else if (field.name === 'fk_id_contract') {
      this.setState({
        contract: value,
      });
    } else if (field.name === 'fk_id_category') {
      this.setState({
        category: value,
        RequestFormProblem: {},
      });
    } else if (field.name === 'fk_id_catalog_service') {
      this.setState({
        catalog: value,
        RequestFormProblem: {},
      });
    } else if (field.name === 'fk_id_user') {
      this.setState({
        user: value,
      });
    } else if (field.name === 'fk_id_catalog_task') {
      this.setState({
        catalog_task: value,
      });
    }
  }

  handleTemplate(values) {
    this.setState({ id_template: values });
    this.modalShow(false);
  }

  modalShow(value) {
    this.setState({ openModal: value });
  }

  showItem(value) {
    $('#template input').each(function () {
      $(this).attr('value', $(this).val());
    });
  }
  // -----------------------------------FUNÇÕES REFERENTES À TELA DE REQUESTFORMPROBLEM.JS----------------------------------------------------------------

  handleNext = (val) => {
    this.setState({ RequestFormProblem: val, index: 1 });
  };
  render() {
    let {
      language,
      handlePage,
      onFormSubmit,
      categories,
      catalogs,
      tasks,
      values = {},
      formstemplate,
      onDrop,
      disabled,
      onClose,
      files,
      removeFile,
      APIRoot,
      StoreLoading,
      redirect2,
    } = this.props;
    let { classes } = this.props;
    let {
      contract,
      category,
      catalog,
      catalog_task,
      openModal,
      id_template,
      index,
    } = this.state;

    let categoryOptions = categories.map((c) => ({
      value: c.id_category,
      text: c.name,
      catalog: c.catalog.map((ca) => ca.name),
    }));

    let template = null;
    let forms = null;
    if (id_template != null) {
      template = formstemplate.filter(
        (c) => c.fk_id_catalog_task === parseInt(id_template)
      );

      if (!!template.length) {
        values = {
          subject: template[0].name,

          fk_id_category: template[0].fk_id_category,
          fk_id_catalog_service: template[0].fk_id_catalog_service,
          fk_id_catalog_task: template[0].fk_id_catalog_task,
        };
        category = template[0].fk_id_category;
        catalog = template[0].fk_id_catalog_service;

        let c = 0;
        forms = template.map((obj) => {
          c = c + 1;
          let id = 'ID' + c;
          return (
            <div className="contain">
              <div className="itemTicketDetail">
                <p
                  style={{ color: '#888080' }}
                  id={id}
                  dangerouslySetInnerHTML={{ __html: applyTimezoneOnHTML(decryp(obj.html)) }}
                />
              </div>
              <div style={{ marginTop: '80px' }} />
              <div>
                {obj.user_up != null
                  ? `${language.LAST_UPDATE} ` +
                    obj.user_up +
                    '-' +
                    moment(obj.dt_up).format('DD-MM-YY HH:mm:ss')
                  : null}
              </div>
              <hr />
            </div>
          );
        });
      }
    }

    let listTemplate = [];

    try {
      listTemplate = tasks
        .filter((g) => g.template === true)
        .map((c) => (
          <MenuItem
            value={parseInt(c.id_catalog_task)}
            primaryText={c.description}
          >
            {c.description}
          </MenuItem>
        ));
    } catch (e) {}

    return (
      <CommonLayout
        icon={
          <div className="requesterIcon" style={{ width: 44, height: 44 }} />
        }
        title={language._REQUEST}
        rightElements={[
          <Button
            label={language._TEMPLATE}
            variant="normal"
            className="mr-2"
            onClick={() => this.modalShow(true)}
          />,

          <Button
            label={language.BACK}
            icon="fas fa-arrow-left"
            variant="circle"
            onClick={() => redirect2('/dash/request')}
          />,
        ]}
      >
        <RequestFormProblem
          StoreLoading={StoreLoading}
          APIRoot={APIRoot}
          language={language}
          handleNext={this.handleNext}
          catalogs={catalogs}
          categories={categories}
          onDrop={onDrop}
          values={values}
          removeFile={(f) => this.props.removeFile(f)}
          files={this.props.files}
          onFormSubmit={(e) => onFormSubmit('', e)}
        />
        {openModal == true ? (
          <Dialog
            title={language.ASSOCIATE_TO_TEMPLATE}
            PaperProps={{ classes: { root: classes.dialogPaperAlert } }}
            open={true}
            maxWidth="md"
            onClose={() => this.modalShow(false)}
          >
            <TextField
              id="outlined-select-currency"
              select
              label={language._TEMPLATE}
              style={{ minWidth: 100, marginRight: '1em' }}
              value={this.state.id_template || ''}
              onChange={(event, key, values) =>
                this.handleTemplate(event.target.value)
              }
              margin="dense"
              variant="outlined"
            >
              {listTemplate}
            </TextField>
          </Dialog>
        ) : null}

        {id_template != null ? (
          <Card className="cardDetail">
            {' '}
            <div id="template" className="template">
              {' '}
              {forms}
            </div>
          </Card>
        ) : null}
      </CommonLayout>
    );
  }
}
RequestForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styless)(RequestForm);
/** <PageLayout
          icon={<div className='stockIcon' style={{ width: 40, height: 40, overflowY:true }} />}
          title={language.STOCK_MANAGEMENT}
          subtitle={language.ADD_IN_STOCK}
          rightElements={[
            <FaClose
              fontSize={25}
              className="colorPrimary pointer"
              style={{ marginTop: -22 }}
              onClick={() => handlePage('list','')}
            />
          ]}
        >
        <div className="scroll">
          <CommonForm
            values={valueIn}
            language={language}
            fields={fields}formstemplate
            onFormSubmit={onFormSubmitIn}
            button={button}
          />
        </div>
        </PageLayout> */
