import React, {memo} from 'react';
import moment from 'moment';
// ----------------------------common--------------------------------------------

import {flexBasis} from '@common/Func';
import Header from '@common/Header';
import SwitchIos from '@common/SwitchIos';
// ----------------------------material-ui--------------------------------------------
import {Card, Grid} from '@material-ui/core';
// ----------------------------components--------------------------------------------

import CompletedActivities from './CompletedActivities';
import CompletedActivitiesMonth from './CompletedActivitiesMonth';
import CardsActivity from './CardsActivity';
import BURNDOWN from './BURNDOWN';

const columnTicket = (t, p) => {
	if (t.stop_ticket == 6 || t.stop_ticket == 5 ) {
	   return 'VALIDATION';
	} else if (t.stop_ticket != 0) {
	   return 'STOPPED';
	} else {
	   return undefined;
	}
 }
export default memo(({
	language,
	sprintkaban,
	users,
	burn,
	effort_value
})=>{
	let completeatividade  =0;
	let totalac  =0;
	let ac =[];

	let tickets=[];

	let sp = sprintkaban.flatMap(g => {
		let items = []
		items.push((g.projetos.projetos||[]).flatMap(c => {
			let arr = ['DONE', 'VALIDATION', 'DOING', 'STOPPED', 'TODO']
			return arr.flatMap(e => [...(c.statusproject[e]||[])]);
		}));

		items.push((g.tickets||[]).map(b => {
			  let key = columnTicket(b);
			  return {...b, _status:key||'TODO'}
		}));
		return items.flatMap(e=>e);
	 });
try{
	
	sprintkaban.map(g=>{
		 
		 
	 g.projetos.projetos.map(c=>{
		c.statusproject.DONE.map(z=>{
           
             ac.push(z);
			 })
			 c.statusproject.TODO.map(z=>{
           
				ac.push(z);
				})
				c.statusproject.STOPPED.map(z=>{
           
					ac.push(z);
					})
					c.statusproject.DOING.map(z=>{
           
						ac.push(z);
						})
      })}) 
	}catch(e){}
 
	  sprintkaban.map(g=>{
		   g.tickets.map(b=>{
		tickets.push(b);
	  }) 
	})
	let totalCon=sp.filter(e=>parseInt(e.percent||e.progress)==100).length;
	let total=sp.length;
	let total_effort_hour = 0;
	let total_effort_value = 0;
	tickets.map(ticket => {
		let effort = effort_value.filter(ef=> ef.value == ticket.fk_id_effort_value)[0]
		if (effort){		
		total_effort_hour += parseFloat((effort.hour).substring(0,2)) + parseFloat(((effort.hour).substring(2,4) /60))
		total_effort_value += effort.value
	}
	})
	ac.map(activity => {
		let effort = effort_value.filter(ef=> ef.value == activity.fk_id_effort_value)[0]
		if (effort){		
		total_effort_hour += parseFloat((effort.hour).substring(0,2)) + parseFloat(((effort.hour).substring(2,4) /60))
		total_effort_value += effort.value

		}
	})


	 /* totalCon=  parseInt(sprintkaban[0].sprint.ac_closed) + parseInt(sprintkaban[0].sprint.ti_closed)
	  total=parseInt(sprintkaban[0].sprint.tickets.length)+ parseInt(sprintkaban[0].sprint.project_sprints_activities.length)  */
	  
	 

	let CompletedActivitiesProps = {
		activitiesTotal:total,
     completedActivities:totalCon 
	}


	let CardsActivityProps = {
		notPlanned:(sprintkaban[0].sprint.tickets_unplanned.length+
		sprintkaban[0].sprint.project_sprints_activities_unplaned.length) ,
		total_effort_value : total_effort_value,
		total_effort_hour : total_effort_hour,
		planned:(ac.length+tickets.length)-(sprintkaban[0].sprint.tickets_unplanned.length+
			sprintkaban[0].sprint.project_sprints_activities_unplaned.length),

	}

	let BURNDOWNProps = {
		evolution:Array.from(new Array(moment().daysInMonth()), (_, i)=> parseInt(Math.random()*100)),// lorem,
		desired_maturity:Array.from(new Array(moment().daysInMonth()), (_, i)=> parseInt(Math.random()*100)),// lorem
	}
	 
 




	let CompletedActivitiesMonthData = Array.from(new Array(language.DATE_LANG.monthList.length), (_, i)=> parseInt(Math.random()*100));
	// quando você mudar o ano do gráfico de atividades concluídas, por favor, mudar o estado lá, não aqui, pra evitar renderizações desnecessárias

	return(
		<div id='Block_1' className='mb-3'>
			<Header className='justify-content-start'
			>
				{/*<SwitchIos onChange={evt=>console.log(evt.target.checked)}/>*/}
       <h3 style ={{ color: 'white' }}>{sprintkaban[0].sprint.name.toUpperCase()}</h3>
			</Header>
			<Card className='content'>
				<Grid container spacing={2}>

				  	<Grid item xs={2} style={{...flexBasis(2)}}>
				  		<CompletedActivities {...CompletedActivitiesProps}/>
				  	</Grid>

				  	<Grid item xs={3.6} style={{...flexBasis(3.6)}}>
						  <CompletedActivitiesMonth 
						 ac={ac}
						 tickets={tickets}
						 users={users}
						 sprintkaban={sprintkaban}
						  language={language} data={CompletedActivitiesMonthData}/>
				  	</Grid>

				  	<Grid item xs={2.4} style={{...flexBasis(1.9)}} className='col-card-activity'>
				  		<CardsActivity {...CardsActivityProps}/>
				  	</Grid>

				  	<Grid item xs={4.5} style={{...flexBasis(4.5)}}>
						  <BURNDOWN
						 	 ac={ac}
							  tickets={tickets} 
							  burn={burn}
							  sprintkaban={sprintkaban}
						  language={language} {...BURNDOWNProps}/>
				  	</Grid>

				</Grid>
			</Card>
		</div>
	)
})