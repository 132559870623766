import React, { Component, Fragment } from 'react';
import {
  TablePagination,
  Paper,
  Card,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'; //table
import { Tooltip } from 'react-tippy';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { CSVLink, CSVDownload } from 'react-csv';
import CommonPage from '@common/CommonPage';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import Button from '@common/Button';
import { TextField } from '@material-ui/core';

import './style.css';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AppBar from '@common/AppBar';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { setInjetSearchColumn, getInjetSearchColumn } from '@common/Func';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputSearch from './InputSearch';
import ColumnSearch from './ColumnSearch';
import Row from './Rows';
import { io } from '../Func';
import Dialog from '@common/Dialog';
import CommonForm2 from '../CommonForm2';

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});

function ToString(e) {
  // return (typeof e)!='object' && e != undefined && e != null ? e.toString() :'';
  if (typeof e != 'string') {
    var retorno = renderToStaticMarkup(e);
  } else {
    var retorno = e;
  }
  return retorno;
}

const styles = {
  root: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginBottom:10
  },
  paper: {
    padding: '0px 4px',
    display: 'flex',
    backgroundColor: '#629daa4d',
    alignItems: 'center',
    borderRadius: 15,
    height: 30,
    boxShadow: 'none',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    color: 'var(--primary)',
  },
  iconButton: {
    padding: 1,
    color: 'white',
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },

  tablePagination: {
    height: 52,
    minHeight: 52,
    display: 'flex',
    alignItems: 'center',
  },
  tablePaginationCaption: {
    color: 'var(--primary)',
  },
  tablePaginationSelectIcon: {
    color: 'var(--primary)',
  },
  tablePaginationSelect: {
    color: 'var(--primary)',
    fontSize: '0.75rem',
  },
  tablePaginationSelect2: {
    fontSize: '0.75rem',
  },

  tablePaginationActions: {
    color: 'white',
  },
  caption: {
    flexShrink: 0,
  },
};
var delayTimer;
var delayTimer2;
@observer
class CommomTable2 extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 75,
      search: '',
      searchColumn: {},
      sortBy: '',
      sortByStatus: true,
      noneP: false,
      load: false,
      dataFilter: [],
      openFilterMulti: false,
      columnSelected: {},
      labelsSelected: [],
      openSelectColumns : false,
      columnsSelectedToSee : [],
      updateColumns: false
    };
    this.saveSelectedLabels = this.saveSelectedLabels.bind(this);
    this.saveMultiFilters = this.saveMultiFilters.bind(this);
    this.getLabelsTooltip = this.getLabelsTooltip.bind(this);
    this.saveColumnsSelected = this.saveColumnsSelected.bind(this);
    this.refsColumnSearch = [];
  }

  componentDidUpdate() {
    let filtersSave = localStorage.getItem('table_filter_' + this.props.tableId)
    if (!this.state.updateColumns) {
      if (filtersSave) {
        let array = filtersSave.split(',')
        if (this.props?.newSla == true) {
          if (array.includes('grid_sla')) {
            array = array.filter((item) => item !== 'grid_sla');
          }
        } else if (this.props?.newSla == false) {
          if (!array.indexOf('grid_sla') > 0) {
            const pos = array.indexOf('grid_sla_time');
            array = array.splice(pos, 0, 'grid_sla')
          }
        }
          localStorage.setItem('table_filter_' + this.props.tableId, array)
          this.setState({ columnsSelectedToSee : array, updateColumns: true})
      } else {
        let col = this.props.col || this.props.columns || [];
        if (this.props?.newSla == true) {
          col = col.filter((item) => item.key !== 'grid_sla')
          this.setState({ columnsSelectedToSee : col.map(c => c.key), updateColumns: true})
        }
      }
    }

    if (this.props.currentPage < 0) {
      this.setState({ page: 0 });
    }

    if (this.props.searchValue && this.props.searchValue != this.state.search) {
      this.setState({ search: this.props.searchValue });
    }
  }

  componentWillMount() {
    var rowsPerPage = this.props.rowsPerPageProp
      ? this.props.rowsPerPageProp
      : this.props.rowsPerPageOptions
      ? this.props.rowsPerPageOptions[0]
      : 75;
    this.setState({ rowsPerPage });
    if (this.props.beginWithNoFilter) {
      this.clearAllFilter();
    }

    const filtersSave = localStorage.getItem(
      'table_filter_' + this.props.tableId
    );

    if (filtersSave) {
      this.setState({ columnsSelectedToSee: filtersSave.split(',') });
    } else {
      const col = this.props.col || this.props.columns || [];
      this.setState({ columnsSelectedToSee: col.map((c) => c.key) });
    }
  }
  componentDidMount() {
    var searchColumn = this.state.searchColumn;
    let { callDataFilter = () => null } = this.props;

    var injetSearchColumn =
      getInjetSearchColumn(
        window.location.pathname + (this.props.index || '')
      ) || {};
    let cols = this.props.col || this.props.columns;
    let cols_aux = [];
    if (cols) {
      this.state.columnsSelectedToSee.map((c) => {
        cols_aux.push(cols.find((col) => col.key == c));
      });
      cols = cols_aux;
    }

    (cols || []).forEach((e, i) => {
      if (this.state.columnsSelectedToSee.includes(cols[i]?.key)) {
        searchColumn[e.key] = injetSearchColumn[e.key] || '';
      }
    });
    if (!this.props.isAutomaticPagination) {
      const objct = getInjetSearchColumn(
        window.location.pathname + (this.props.index || '')
      );
      let valuesColumn = {};

      if (objct) {
        if (this.props.columns) {
          Object.keys(objct).forEach((item) => {
            /**
             * if acrescentado, alguns objetos estão vindo vazios e estão quebrando na renderização de tela/modal
             */
            if (this.props.columns[item] != undefined) {
              const columnItem = item;
              valuesColumn = {
                ...valuesColumn,
                [columnItem]: objct[item],
              };
              if (this.props.columns.find(c => c.key == item)?.multiOption) {
                valuesColumn = {
                  ...valuesColumn,
                  [columnItem + '_name_']:
                    this.state.labelsSelected.find((f) => f.index == item)
                      ?.label ?? '',
                };
              }
            }
          });
        } else {
          Object.keys(objct).forEach((item) => {
            try {
              const columnItem = key;
              valuesColumn = {
                ...valuesColumn,
                [columnItem]: objct[item],
              };
              if (this.props.col.find(c => c.key == item)?.multiOption) {
                valuesColumn = {
                  ...valuesColumn,
                  [columnItem + '_name_']:
                    this.state.labelsSelected.find((f) => f.index == item)
                      ?.label ?? '',
                };
              }
            } catch (e) {}
          });
        }
      }
      callDataFilter(valuesColumn);
    }

    this.setState({ searchColumn });
  }

  sortItem = (item) => {
    let { sortBy } = this.state;
    let { onSortItem = () => null, noSort = [] } = this.props;
    const sortByStatus = !this.state.sortByStatus;
    if (!noSort.includes(item)) {
      if (sortBy != item) {
        this.setState({ sortByStatus: true });
      }
      this.setState((state) => ({
        sortBy: item,
        sortByStatus,
      }));
    }
    onSortItem(item, sortByStatus);
  };
  handleChangePage = async (event, page) => {
    let { callPageSelect = () => null, callPageSelectFilter = () => null, loadingFromPromise } = this.props;
    loadingFromPromise && this.setState({ load: true });
    !loadingFromPromise && this.load_();
    
    callPageSelect(page);

    let valuesColumn = {};

    if( this.state.search ) {
      valuesColumn = {
        ...valuesColumn,
        search: this.state.search,
      };
    }

    if (!this.props.isAutomaticPagination) {
      const objct = getInjetSearchColumn(
        window.location.pathname + (this.props.index || '')
      );

      if (objct) {
        if (this.props.columns) {
          Object.keys(objct).forEach((item) => {
            const columnItem = item;
            valuesColumn = {
              ...valuesColumn,
              [columnItem]: objct[item],
            };
          });
        } else {
          Object.keys(objct).forEach((item) => {
            const columnItem = item;
            valuesColumn = {
              ...valuesColumn,
              [columnItem]: objct[item],
            };
          });
        }
      }
    }

    await callPageSelectFilter(page, valuesColumn, this.state.rowsPerPage, 'handle');
    loadingFromPromise && this.setState({ load: false });

    try {
      this.props.onPosition(page);
    } catch (e) {}

    this.setState({ page });
  };

  onXReachStart = (e) => {
    let { noneP } = this.state;
    if (!noneP) {
      this.setState({ noneP: true });
    }
  };
  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    this.setState({ rowsPerPage });

    let { callPageSelect = () => null, callPageSelectFilter = () => null } =
      this.props;

    callPageSelect(this.state.page);

    let valuesColumn = {};
    if (!this.props.isAutomaticPagination) {
      const objct = getInjetSearchColumn(
        window.location.pathname + (this.props.index || '')
      );
      if (objct) {
        if (this.props.columns) {
          Object.keys(objct).forEach((item) => {
            const columnItem = this.props.columns[item].key;
            valuesColumn = {
              ...valuesColumn,
              [columnItem]: objct[item],
            };
          });
        } else {
          Object.keys(objct).forEach((item) => {
            const columnItem = this.props.col[item].key;
            valuesColumn = {
              ...valuesColumn,
              [columnItem]: objct[item],
            };
          });
        }
      }
    }
    callPageSelectFilter(this.state.page, valuesColumn, rowsPerPage, 'handle');
  };

  searchEvent = (search) => {
    var searchColumn = Object.assign({}, this.state.searchColumn);

    Object.keys(searchColumn).forEach((e, i) => {
      searchColumn[e] = '';
    });

    setInjetSearchColumn(
      window.location.pathname + (this.props.index || ''),
      {}
    );
    setInjetSearchColumn(
      window.location.pathname + '_' + (this.props.index || ''),
      {}
    );
    this.props.searchColumnEvent && this.props.searchColumnEvent({ search });

    this.setState({ searchColumn, search });
  };

  clearSeach = () => {
    this.setState({ search: '' });
    this.props.searchColumnEvent &&
      this.props.searchColumnEvent({ search: '' });
    this.load_();
  };

  searchColumnEvent = (value, column) => {
    //Loading faz um update no componente dessa forma o socket é limpo eo erro
    //de duplicação de logs é solucionado
    //alem de melhorar a busca pois enquanto está em loading o teclado não trava\
    let { callDataFilter = () => null, callPageSelectFilter = () => null } = this.props;
    var searchColumn = this.state.searchColumn;
    searchColumn[column.key] = value;

    if (!value) delete searchColumn[column.key];

    setInjetSearchColumn(
      window.location.pathname + (this.props.index || ''),
      searchColumn
    );

    const objct = getInjetSearchColumn(
      window.location.pathname + (this.props.index || '')
    );

    if (!this.props.isAutomaticPagination) {
      let valuesColumn = {};

      if( this.state.search ) {
        valuesColumn = {
          ...valuesColumn,
          search: this.state.search,
        };
      }

      if (objct) {
        if (this.props.columns) {
          let cols = this.props.columns;
          let cols_aux = {};
          if (cols) {
            this.state.columnsSelectedToSee.map((c) => {
              cols_aux[c] = cols[c];
            });
            cols = cols_aux;
          }

          Object.keys(objct).forEach((item) => {
            if (this.state.columnsSelectedToSee.includes(item)) {
              const columnItem = item
              valuesColumn = {
                ...valuesColumn,
                [columnItem]: objct[item],
              };
            }

          });
        } else {
          let cols = this.props.col;
          let cols_aux = [];

          if (cols) {
            this.state.columnsSelectedToSee.map((c, i) => {
              cols_aux.push(cols.find((col) => col.key == c));
            });
            cols = cols_aux;
          }
          Object.keys(objct).forEach((item) => {
            if (this.state.columnsSelectedToSee.includes(item)) {
              const columnItem = item;
              if (cols.find(c => c.key == item).multiOption) {
                valuesColumn = {
                  ...valuesColumn,
                  [columnItem]: this.state.labelsSelected.find((f) => f.key == columnItem)
                  ?.ids ?? [],
                };
                valuesColumn = {
                  ...valuesColumn,
                  [columnItem + '_name_']:
                    this.state.labelsSelected.find((f) => f.key == columnItem)
                      ?.label ?? '',
                };
              }else{
                valuesColumn = {
                  ...valuesColumn,
                  [columnItem]: objct[item],
                };
              }
            }
          });
        }
      }

      // callDataFilter(valuesColumn);
      this.setState({ page: 0 }, () => {
        callPageSelectFilter(
          this.state.page,
          valuesColumn,
          this.state.rowsPerPage,
          'filter'
        );

        this.load_();
      });
    } else {
      if (this.props.loadSearch) {
        this.load_();
      }
      this.setState({ searchColumn, search: '' });
    }
  };

  compareBy(key, order) {
    let { orderColumn } = this.props;
    return function (a, b) {
      if (!!orderColumn && key) {
        var { ConfigCell = {} } = a;
        var ConfigCell_A = ConfigCell;
        var { ConfigCell = {} } = b;
        var ConfigCell_B = ConfigCell;
        try {
          if (ConfigCell_A['__order_' + key] !== undefined) {
            if (order) {
              textB = ConfigCell_A['__order_' + key];
              textA = ConfigCell_B['__order_' + key];
            } else {
              textA = ConfigCell_A['__order_' + key];
              textB = ConfigCell_B['__order_' + key];
            }
          } else {
            if (order) {
              textB = a[key];
              textA = b[key];
            } else {
              textA = a[key];
              textB = b[key];
            }
            textA = ToString(textA);
            textB = ToString(textB);
          }

          if (textA.length == 10 && textA.split('/').length == 3) {
            textA =
              textA.split('/')[2] + textA.split('/')[1] + textA.split('/')[0];
            textB =
              textB.split('/')[2] + textB.split('/')[1] + textB.split('/')[0];

            if (parseInt(textA) && parseInt(textB)) {
              textA = parseInt(textA);
              textB = parseInt(textB);

              return textA < textB ? -1 : textA > textB ? 1 : 0;
            } else {
              textA = textA.toUpperCase();
              textB = textB.toUpperCase();
              return textA.localeCompare(textB);
            }
          } else {
            if (!isNaN(parseInt(textA)) && !isNaN(parseInt(textB))) {
              textA = parseInt(textA);
              textB = parseInt(textB);

              return textA < textB ? -1 : textA > textB ? 1 : 0;
            } else {
              textA = textA.toUpperCase();
              textB = textB.toUpperCase();
              return textA.localeCompare(textB);
            }
          }
        } catch (e) {}
      }
    };
  }

  clearAllFilter = () => {
    let { onClearAllFilter = () => null } = this.props;
    var searchColumn = this.state.searchColumn;
    Object.keys(searchColumn).map((e, i) => {
      searchColumn[e] = '';
    });

    setInjetSearchColumn(
      window.location.pathname + (this.props.index || ''),
      searchColumn
    );
    setInjetSearchColumn(
      window.location.pathname + '_' + (this.props.index || ''),
      searchColumn
    );
    onClearAllFilter();
    this.setState({ search: '', sortBy: '', searchColumn, labelsSelected: [] });

    this.setState({ search: '' });
    this.props.searchColumnEvent &&
      this.props.searchColumnEvent({ search: '' });
    this.load_();
  };

  reverseString = (str) => {
    return str.split('/').reverse().join('-');
  };

  load_ = () => {
    if (this.state.load == false) {
      this.setState({ load: true });
    }

    if (!this.props.isAutomaticPagination) {
      setTimeout(() => {
        this.setState({ load: false });
      }, 1000);
    } else {
      setTimeout(() => {
        this.setState({ load: false });
      }, 3000);
    }
  };

  filterData = () => {
    var key;
    var textA;
    var textB;

    var text = this.state.search.toLowerCase();
    var searchColumn = this.state.searchColumn;
    var searchColumnKeys = Object.keys(searchColumn)
    var searchColumnValues = Object.values(searchColumn);
    var data = Object.assign([], this.props.data);

    var orderSearch = this.props.orderSearch;
    var { orderColumn = true } = this.props;
    var sortByStatus = this.state.sortByStatus;
    var sortBy = this.state.sortBy;

    if (sortBy) {
      data = data.sort(
        this.compareBy(this.state.sortBy, this.state.sortByStatus)
      );
    }

    if (this.props.noFilter) {
      return data;
    }


    return (data || [])
      .filter((e, i) => {
        if (
          !!searchColumnKeys.length &&
          !!searchColumnValues.find((e) => !!e)
        ) {

          const keys = Object.keys(e).slice(!!e.ConfigCell ? 1 : 0)
          var b = {};
         searchColumnKeys.forEach((t, i) =>{
            b[t] = e[t];
          })
          var d = [];
          for (var index in searchColumnValues) {
            if (typeof searchColumnValues[index] === 'string') {
              if (
                ToString(Object.values(b)[index])
                  .toLowerCase()
                  .indexOf(searchColumnValues[index].toLowerCase()) > -1
              ) {
                d.push(true);
              } else {
                if (typeof b[searchColumnKeys[index]] === 'string'){
                  if (b[searchColumnKeys[index]]?.toLowerCase().indexOf(searchColumnValues[index]?.toLowerCase()) > -1){
                    d.push(true)
                  }else{
                  d.push(false);
                }
                }else{
                  d.push(true)
                }
              }
            }
            // if (Object.values(b).join().toLowerCase().indexOf(searchColumnValues[index].toLowerCase()) > -1) return e;
          }
          if (e.ConfigCell && e.ConfigCell.index == 'detail-table') {
            return true;
          } else {
            if (d.indexOf(false) == -1) {
              return e;
            }
          }
        } else {
          if (e.ConfigCell && e.ConfigCell.index == 'detail-table') {
            return true;
          } else {
            if (
              Object.values(e)
                .slice(!!e.ConfigCell ? 1 : 0)
                .map((e) => ToString(e))
                .join()
                .toLowerCase()
                .indexOf(text) > -1
            )
              return e;
          }
        }
      })
      .sort(function (a, b) {
        if (!!orderColumn && sortBy) {
          var { ConfigCell = {} } = a;
          var ConfigCell_A = ConfigCell;
          var { ConfigCell = {} } = b;
          var ConfigCell_B = ConfigCell;

          if (ConfigCell_A['__order_' + sortBy] !== undefined) {
            if (sortByStatus) {
              textB = ConfigCell_A['__order_' + sortBy];
              textA = ConfigCell_B['__order_' + sortBy];
            } else {
              textA = ConfigCell_A['__order_' + sortBy];
              textB = ConfigCell_B['__order_' + sortBy];
            }
          } else {
            if (sortByStatus) {
              textB = a[sortBy];
              textA = b[sortBy];
            } else {
              textA = a[sortBy];
              textB = b[sortBy];
            }
            textA = ToString(textA);
            textB = ToString(textB);
          }

          try {
            if (textA.indexOf('R$ ') > -1) {
              textA = textA.replace(/\D/g, '');
              textB = textB.replace(/\D/g, '');
            }
          } catch (e) {}

          if (textA.length == 10 && textA.split('/').length == 3) {
            textA =
              textA.split('/')[2] + textA.split('/')[1] + textA.split('/')[0];
            textB =
              textB.split('/')[2] + textB.split('/')[1] + textB.split('/')[0];

            if (parseInt(textA) && parseInt(textB)) {
              textA = parseInt(textA);
              textB = parseInt(textB);

              return textA < textB ? -1 : textA > textB ? 1 : 0;
            } else {
              textA = textA.toUpperCase();
              textB = textB.toUpperCase();
              return textA.localeCompare(textB);
            }
          } else {
            if (!isNaN(parseInt(textA)) && !isNaN(parseInt(textB))) {
              textA = parseInt(textA);
              textB = parseInt(textB);

              return textA < textB ? -1 : textA > textB ? 1 : 0;
            } else {
              textA = textA.toUpperCase();
              textB = textB.toUpperCase();
              return textA.localeCompare(textB);
            }
          }
        }
      });
  };

  exportTicket() {
    var text = this.state.search.toLowerCase();
    var searchColumn = this.state.searchColumn;

    let v = {};
    this.props.col.map((g, i) => {
      v[g.key] = searchColumn[g.key];
    });

    const data = this.filterData();
    let item = data.map((g) => g.id);
    this.props.excel(v);
  }

  exportTicketPdf() {
    var text = this.state.search.toLowerCase();
    var searchColumn = this.state.searchColumn;

    let v = {};
    this.props.col.map((g, i) => {
      v[g.key] = searchColumn[g.key];
    });

    const data = this.filterData();
    let item = data.map((g) => g.id);
    this.props.pdf(v);
  }

  multiFilters(column, index) {
    this.setState({
      openFilterMulti: true,
      columnSelected: { ...column, index: index },
    });
  }

  saveMultiFilters(values) {
    if ( !values.filter_ ) {
      values.filter_ = {
        label: values.filter
      }
    }
    let { columnSelected } = this.state;
    this.setState({ openFilterMulti: false });
    this.saveSelectedLabels(values, values.filter_.label, columnSelected);
    this.searchColumnEvent(values.filter, columnSelected);
  }

  getLabelsTooltip(column, index) {
    const labelsFromState = this.state.labelsSelected.filter(
      (label) => label.key == column.key
    );

    const labelsFromProps =
      this.props.filterColumns &&
      this.props.filterColumns[column.key + '_name_'];
    if (labelsFromState && labelsFromState.length > 0) {
      return labelsFromState;
    } else if (labelsFromProps && labelsFromProps.length > 0) {
      let labels = [
        ...this.state.labelsSelected,
        {
          index: index,
          label: labelsFromProps,
          key: column.key,
          ids: this.props.filterColumns[column.key],
        },
      ];

      this.setState({ labelsSelected: labels });

      return [{ label: labelsFromProps }];
    } else {
      return [];
    }
  }

  saveSelectedLabels(values, label, column) {
    let labels = this.state.labelsSelected;
    if (!!labels.find((l) => l.key == column.key)) {
      let index_ = labels.findIndex((l) => l.key == column.key);
      labels[index_] = {
        index: values.filter_.index,
        label: label,
        key: column.key,
        ids: values.filter,
      };
    } else {
      labels.push({
        index: values.filter_.index,
        label: label,
        key: column.key,
        ids: values.filter,
      });
    }
    this.setState({ labelsSelected: labels });
  }

  saveColumnsSelected(values) {
    let columnsToSee = [];
    let searchColumns = this.state.searchColumn
    let labels = this.state.labelsSelected
    const col = this.props.col || this.props.columns || [];
    col.map((c, index) => {
      if (!!values.columnsSelect.find((cs) => cs == c.key)) {
        columnsToSee.push(c.key);
      } else {
        labels = labels.filter(l => l.key !== c.key)
        delete searchColumns[c.key]
      }
    });


    let newSearch = {}
    Object.keys(searchColumns).forEach((key, index) => {
      newSearch[index] = searchColumns[key]
    })
     setInjetSearchColumn(
      window.location.pathname + (this.props.index || ''),
      searchColumns
    );
    this.setState({
      columnsSelectedToSee: columnsToSee,
      openSelectColumns: false,
      searchColumn: searchColumns,
      labelsSelected : labels
    });
    localStorage.setItem('table_filter_' + this.props.tableId, columnsToSee);
  }

  render() {
    let {
      page,
      rowsPerPage,
      load,
      openFilterMulti,
      columnSelected,
      labelsSelected,
      openSelectColumns,
      columnsSelectedToSee,
    } = this.state;
    let { language, loading } = this.store;
    let { COMMON_PAGE } = this.store;
    const data = this.filterData();
    let {
      rowsPerPageOptions,
      columns,
      bar,
      beforeColumns,
      pagination,
      isAutomaticPagination = true,
      classes,
      search,
      width,
      paginationTop = true,
      searchColumn,
      orderColumn = true,
      title,
      beforeBar,
      afterBar,
      beforeBar2,
      afterBar2,
      maxHeight,
      height,
      AppBarProps = {},
      paper = true,
      print,
      printLast = false,
      mini = true,
      clearFilters,
      searchWidth,
      printButtonProps,
      clearFiltersProps,
      searchProps,
      className,
      footer,
      excel,
      pdf,
      suppressScrollX,
      suppreScrollLength = true,
      onPosition,
      beforeColumns2,
      callPageSelect,
      countTotal,
      currentPage,
      isResponsiveTicket = false,
      allowSelectColumns,
    } = this.props;
    const col = this.props.col || columns || [];
    let csvData = [];
    AppBarProps.dark = AppBarProps.dark != false ? true : undefined;
    const PS =
      bar !== false &&
      (pagination !== false || search !== false || title !== false);
    const Content = paper ? Card : 'div';
    const PrintBtn = (
      <Button
        icon="fas fa-print"
        label={language.PRINT}
        className="mr-3"
        color={AppBarProps.dark && 'warning'}
        onClick={() => window.print()}
        {...printButtonProps}
      />
    );
    let ContentScroll = PerfectScrollbar;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classnames('table-23-sensr', { mini })}>
          <Content
            className="paper paper-list-custom border-radius-10"

            // style={{ marginTop: PS ? 40 : 0 }}
          >
            {openFilterMulti && (
              <Dialog
                open={true}
                onClose={() => this.setState({ openFilterMulti: false })}
                title={language.FILTER}
                maxWidth="lg"
                PaperProps={{
                  style: {
                    height: 500,
                  },
                }}
              >
                {
                  !columnSelected.multiFilter && (
                    <CommonForm2
                      fields={[
                        {
                          col: 12,
                          type: 'autocomplete',
                          name: 'filter',
                          label: columnSelected.textlabel,
                          method: 'POST',
                          fieldquery: columnSelected.fieldquery,
                          fieldvaluedb: columnSelected.fieldvaluedb,
                          fieldlabeldb: columnSelected.fieldlabeldb,
                          textlabel: columnSelected.textlabel,
                          translateOptions: columnSelected.translateOptions,
                          showEmptyFilter: columnSelected.showEmptyFilter,
                          idinit: labelsSelected.find(
                            (f) => f.key == columnSelected.key
                          )
                            ? labelsSelected.find(
                                (f) => f.key == columnSelected.key
                              ).ids
                            : this.props.filterColumns &&
                              this.props.filterColumns[
                                columnSelected.key
                              ]
                            ? this.props.filterColumns[
                                columnSelected.key
                              ]
                            : columnSelected.idinit,
                          textinit: labelsSelected.find(
                            (f) => f.key == columnSelected.key
                          )
                            ? labelsSelected.find(
                                (f) => f.key == columnSelected.key
                              ).label
                            : this.props.filterColumns &&
                              this.props.filterColumns[
                                columnSelected.key + '_name_'
                              ]
                            ? this.props.filterColumns[
                                columnSelected.key + '_name_'
                              ]
                            : columnSelected.textinit,
                          routeAll: columnSelected.routeAll,
                          detail: true,
                          methodGetAll: 'POST',
                          filterAll: columnSelected.filterAll,
                          routeGetCount: columnSelected.routeGetCount,
                          isMulti: true,
                          index: columnSelected.index,
                          fromCommonTable: true,
                        },
                      ]}
                      onSubmit={this.saveMultiFilters}
                    />
                  )
                }

                {
                  columnSelected.multiFilter && (
                    <CommonForm2
                      fields={[
                        {
                          col: 12,
                          type: 'select',
                          name: 'filter',
                          options: columnSelected.valuesColumn,
                          label: columnSelected.textlabel,
                          textlabel: columnSelected.textlabel,
                          translateOptions: columnSelected.translateOptions,
                          showEmptyFilter: columnSelected.showEmptyFilter,
                          idinit: labelsSelected.find(
                            (f) => f.key == columnSelected.key
                          )
                            ? labelsSelected.find(
                                (f) => f.key == columnSelected.key
                              ).ids
                            : this.props.filterColumns &&
                              this.props.filterColumns[
                                columnSelected.key
                              ]
                            ? this.props.filterColumns[
                                columnSelected.key
                              ]
                            : columnSelected.idinit,
                          textinit: labelsSelected.find(
                            (f) => f.key == columnSelected.key
                          )
                            ? labelsSelected.find(
                                (f) => f.key == columnSelected.key
                              ).label
                            : this.props.filterColumns &&
                              this.props.filterColumns[
                                columnSelected.key + '_name_'
                              ]
                            ? this.props.filterColumns[
                                columnSelected.key + '_name_'
                              ]
                            : columnSelected.textinit,
                          index: columnSelected.index,
                          fromCommonTable: true,
                        },
                      ]}
                      onSubmit={this.saveMultiFilters}
                    />
                  )
                }
              </Dialog>
            )}

            {openSelectColumns && (
              <Dialog
                open={true}
                onClose={() => this.setState({ openSelectColumns: false })}
                title={language.FILTER}
                maxWidth="lg"
              >
                <CommonForm2
                  fields={[
                    {
                      col: 12,
                      name: 'columnsSelect',
                      label: language.SELECT,
                      type: 'listCheckbox',
                      options: col.map((c) => ({
                        value:c.key,
                        label: c.label,
                      })),
                    }
                  ]}
                  values={{ columnsSelect: this.state.columnsSelectedToSee }}
                  onSubmit={this.saveColumnsSelected}
                />
              </Dialog>
            )}

            {PS && (
              <Fragment>
                <div
                  {...AppBarProps}
                  dark={AppBarProps.dark && 'dark'}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: paginationTop ? 0 : '.5rem',
                    paddingTop: paginationTop ? 0 : '.5rem',
                    ...AppBarProps.style,
                    '--barColor': `${
                      COMMON_PAGE
                        ? COMMON_PAGE.bar
                        : 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)'
                    }`,
                  }}
                  className={'content AppBar-table ' + AppBarProps.className}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {allowSelectColumns && (
                      <Button
                        variant="circle"
                        outlined
                        icon="fas fa-cog"
                        label={language.CONFIGURE_COLUMNS}
                        className="ml-2 mr-3"
                        size={1.1}
                        onClick={() =>
                          this.setState({ openSelectColumns: true })
                        }
                        color={'light'}
                        style={{
                          border: 'none',
                        }}
                      />
                    )}

                    {beforeBar}
                    {excel && (
                      <Button
                        variant="circle"
                        icon="fas fa-file-export"
                        color="warning"
                        className="mr-3"
                        label={language.EXPORT + ' XLS'}
                        onClick={() => this.exportTicket()}
                      />
                    )}
                    {pdf && (
                      <Button
                        variant="circle"
                        icon="fas fa-file-pdf"
                        color="warning"
                        className="mr-3"
                        label={language.EXPORT + ' PDF'}
                        onClick={() => this.exportTicketPdf()}
                      />
                    )}
                    {search !== false && (
                      <InputSearch
                        placeholderProps={
                          this.props.placeholderSearch ||
                          language.SEARCH + '...'
                        }
                        classes={classes}
                        searchProps={searchProps}
                        searchWidth={searchWidth}
                        language={language}
                        _handleValue={this.searchEvent}
                        sh={this.props.sh}
                        clearSeach={this.clearSeach}
                        _value={this.state.search}
                      />
                    )}
                    {clearFilters != false && (
                      <Button
                        variant="circle"
                        outlined
                        icon="fas fa-eraser"
                        label={language.CLEAR_FILTER}
                        className="ml-2 mr-3"
                        size={1.1}
                        onClick={this.clearAllFilter}
                        color={AppBarProps.dark && 'light'}
                        style={{
                          border: 'none',
                        }}
                        {...clearFiltersProps}
                      />
                    )}
                    {typeof title == 'string' ? (
                      <Typography
                        variant="h6"
                        className="font title"
                        style={{
                          paddingTop: 0,
                          marginLeft: 10,

                          fontSize: '0.9em',
                          textTransform: 'uppercase',
                          fontFamily: 'Roboto',
                          fontWeight: 600,
                          margin: 0,
                        }}
                      >
                        {title}
                      </Typography>
                    ) : (
                      title
                    )}
                    {print && !printLast && PrintBtn}
                    {afterBar}
                    {print && printLast && PrintBtn}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {beforeBar2}
                    {pagination !== false && paginationTop && (
                      <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions || [25, 50, 75]}
                        labelRowsPerPage={language.ROWS_PER_PAGE}
                        component="div"
                        count={
                          this.props.countTotal
                            ? this.props.countTotal
                            : data.length
                        }
                        SelectProps={{
                          className: 'Select-TablePagination',
                        }}
                        rowsPerPage={rowsPerPage}
                        page={
                          this.props.currentPage ? this.props.currentPage : page
                        }
                        backIconButtonProps={{
                          'aria-label': language.PREVIOUS_PAGE,
                        }}
                        nextIconButtonProps={{
                          'aria-label': language.NEXT_PAGE,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        className="pagination pagination-top"
                        classes={{
                          root: classes.tablePagination,
                          caption: classes.tablePaginationCaption,
                          selectIcon: classes.tablePaginationSelectIcon,
                          select: classes.tablePaginationSelect,
                          actions: classes.tablePaginationActions,
                        }}
                      />
                    )}
                    {afterBar2}
                  </div>
                </div>
                {!AppBarProps.dark && <hr className="divider-2 my-0" />}
              </Fragment>
            )}

            <div
              className={classnames('', {
                'px-0': this.state.noneP || this.props.fluid,
              })}
            >
              <ContentScroll
                className="scrollVisible"
                style={{
                  maxHeight: maxHeight,
                  ...(height ? { height: height } : {}),
                }}
                onXReachStart={this.onXReachStart}
                option={{
                  scrollXMarginOffset: 4,
                  suppressScrollY:
                    isResponsiveTicket || height ? false : !maxHeight,
                  suppressScrollX: suppressScrollX,
                }}
              >
                <div
                  className={
                    isResponsiveTicket
                      ? 'table-responsive_ticket'
                      : 'table-responsive'
                  }
                >
                  <Table
                    ref={(el) => (this.componentRef = el)}
                    className={'table ' + className}
                    style={{ width: width || '100%' }}
                  >
                    {(this.props.col || columns) && (
                      <TableHead className="thead" style={this.props.freezeHead ? { position: 'sticky', top: 0, zIndex: 99} : {}}>
                        {beforeColumns && !!beforeColumns.length && (
                          <TableRow>
                            {beforeColumns.map((e, i) => (
                              <TableCell
                                key={'beforeColumns-' + i}
                                {...e}
                                className={classnames('th', e.className || '')}
                              >
                                {e.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        )}
                        {beforeColumns2 && !!beforeColumns2.length && (
                          <TableRow>
                            {beforeColumns2.map((e, i) => (
                              <TableCell
                                key={'beforeColumns-' + i}
                                {...e}
                                className={classnames('th', e.className || '')}
                              >
                                {e.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        )}

                        <TableRow>
                          {col
                            .filter((c) =>
                              this.state.columnsSelectedToSee.includes(c.key)
                            )
                            .map((e, i) => (
                              <TableCell
                                key={'col-' + i}
                                {...e}
                                className={classnames('th', e.className || '')}
                                onClick={() =>
                                  orderColumn && !e.noOrder
                                    ? this.sortItem(e.key)
                                    : null
                                }
                              >
                                {e.description ? (
                                  <Tooltip title={e.description}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: e.jc || 'flex-start',
                                      }}
                                      className="content-table-cell-header"
                                    >
                                      {e.label}
                                      {orderColumn && !e.noOrder && (
                                        <div className="control-order">
                                          <i
                                            style={{ padding: '0 6px' }}
                                            className={
                                              'fas fa-angle-' +
                                              (this.state.sortByStatus
                                                ? 'up'
                                                : 'down')
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: e.jc || 'flex-start',
                                    }}
                                    className="content-table-cell-header"
                                  >
                                    {e.label}
                                    {orderColumn && !e.noOrder && (
                                      <div className="control-order">
                                        <i
                                          style={{ padding: '0 6px' }}
                                          className={
                                            'fas fa-angle-' +
                                            (this.state.sortByStatus
                                              ? 'up'
                                              : 'down')
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </TableCell>
                            ))}
                        </TableRow>
                        {searchColumn && (
                          <TableRow className="search-column">
                            {col
                              .filter((c) =>
                                this.state.columnsSelectedToSee.includes(c.key)
                              )
                              .map((e, i) => (
                                <TableCell
                                  key={'searchColumn-' + i}
                                  className="th_search"
                                  style={{ padding: '0 5px' }}
                                >
                                  {e.multiOption || e.multiFilter ? (
                                    <div style={{ marginLeft: 7 }}>
                                      <label
                                        onClick={() => {
                                          this.multiFilters(e, i);
                                        }}
                                        style={{
                                          cursor: 'pointer',
                                          color: '#115d9e',
                                        }}
                                      >
                                        {language.FILTER}
                                      </label>
                                      {(this.getLabelsTooltip(e, i) || []).map(
                                        (f) => (
                                          (f.label || []).length > 0 && (
                                          <Tooltip
                                            html={(Array.isArray(f.label)
                                              ? f.label
                                              : []
                                            ).map((label) => (
                                              <div
                                                style={{
                                                  color: '#fff',
                                                  fontSize: 15,
                                                }}
                                              >
                                                <strong>{label}</strong>
                                              </div>
                                            ))}
                                            position="bottom"
                                            theme="dark"
                                            arrow={true}
                                          >
                                            <i
                                              className="fas fa-info"
                                              style={{
                                                width: 'auto',
                                                height: 'auto',
                                                fontSize: 11,
                                                color: 'black',
                                                padding: 5,
                                                marginTop: -5,
                                              }}
                                            />
                                          </Tooltip>
                                          )
                                        )
                                      )}
                                    </div>
                                  ) : [undefined, true].includes(e.search) ? (
                                    <ColumnSearch
                                      _value={this.state.searchColumn[e.key]}
                                      styleSearch={e.styleSearch}
                                      _handleValue={this.searchColumnEvent}
                                      i={i}
                                      e={e}
                                    />
                                  ) : (
                                    e.search
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}
                      </TableHead>
                    )}

                    <TableBody className="tbody">
                      {!data.length && (
                        <TableRow>
                          <TableCell colSpan={col.length}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '20px 0',
                              }}
                            >
                              {!!loading &&
                              this.props.data &&
                              this.props.data.length > 0 ? (
                                <CircularProgress size={60} />
                              ) : (
                                <Typography
                                  variant="h6"
                                  className="font"
                                  style={{ paddingTop: 0, fontWeight: 100 }}
                                >
                                  {language.NO_REGISTERED_USER}
                                </Typography>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                      {load == true && data.length > 0 ? (
                        <TableRow>
                          <TableCell colSpan={col.length}>
                            {' '}
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <CircularProgress size={60} />
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        !!data.length &&
                        (pagination !== false && isAutomaticPagination === true
                          ? data.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : data
                        ).map((e, i) => (
                          <Row
                            e={e}
                            i={i}
                            col={col}
                            allowSelectColumns = {allowSelectColumns}
                            columnsSelectedToSee={
                              this.state.columnsSelectedToSee
                            }
                          />
                        ))
                      )}
                    </TableBody>
                  </Table>
                </div>
              </ContentScroll>
              {pagination !== false && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions || [25, 50, 75]}
                  labelRowsPerPage={language.ROWS_PER_PAGE}
                  component="div"
                  SelectProps={{
                    className: 'Select-TablePagination',
                  }}
                  count={
                    this.props.countTotal ? this.props.countTotal : data.length
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': language.PREVIOUS_PAGE,
                  }}
                  nextIconButtonProps={{
                    'aria-label': language.NEXT_PAGE,
                  }}
                  className="pagination pagination-bottom"
                  classes={{
                    select: classes.tablePaginationSelect2,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              )}
            </div>
            {footer}
          </Content>
        </div>
      </MuiThemeProvider>
    );
  }
}
CommomTable2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CommomTable2);
