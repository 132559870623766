import React, { Fragment, useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
/*------------------------common-----------------------------*/
import moment from 'moment';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm2';
import RaisedButton from '../../common/RaisedButton';
import BarBlue from '@images/BarBlue.png';
import Button from '@common/Button';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import { translateFromPtBR } from '@common/languages/Dictionary';
/*----------------------material-ui-------------------------------*/

import { Card } from '@material-ui/core';
/*----------------------páginas-------------------------------*/

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: translateFromPtBR(option[attributeName], Store.language),
  }));

  return normalizedOptions;
};

export default function () {
  const [holidays, setHolidays] = useState([]);
  const [page, setPage] = useState('list');
  const [formRef, setFormRef] = useState(null);
  const [valor, setValor] = useState();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [countrySelected, setCountrySelected] = useState();
  const [stateSelected, setStateSelected] = useState();
  const [citySelected, setCitySelected] = useState();

  const { language } = Store;
  const { APIEndpoints } = Constants;
  const action = new Actions();

  const button = { label: valor?.id_holiday ? language.EDIT : language.SAVE };

  useEffect(() => {
    // Buscar Feriados
    action
      .execute('post', `${APIEndpoints.COMPANY_TIME}/holiday`, '', {
        list: true,
      })
      .then((h) => {
        h.data.map(holiday => {
          holiday.country = translateFromPtBR(holiday.country, language);
        })
        setHolidays(h.data);
      });

    // Buscar Paises
    action
      .execute('get', APIEndpoints.COUNTRY, '', null)
      .then((result) => {
        const { data } = result;
        setCountries(formatSelectOptions(data, 'name', 'id_country'));
      });
  }, []);

  const getStates = (countryId) => {
    action
      .execute('get', `${APIEndpoints.COUNTRY}/${countryId}`, '', {
        list: true,
      })
      .then((result) => {
        const { data } = result;
        setStates(formatSelectOptions(data, 'name', 'id_state'));
      });
  };

  const getCities = (stateId) => {
    action
      .execute('get', `${APIEndpoints.STATE}/${stateId}`, '', { list: true })
      .then((result) => {
        const { data } = result;

        setCities(formatSelectOptions(data, 'name', 'id_city'));
      });
  };

  const save = (v) => {
    v.create = true;
    v.id_country = countrySelected;
    v.id_state = stateSelected;
    v.id_city = citySelected;
    action
      .execute('post', `${APIEndpoints.COMPANY_TIME}/holiday`, 'holiday', v)
      .then((h) => {
        Store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
        setHolidays(h.data);
        setPage('list')
      });
  };

  const remove = (v) => {
    v.remove = true;
    action
      .execute('post', `${APIEndpoints.COMPANY_TIME}/holiday`, 'holiday', v)
      .then((h) => {
        setHolidays(h.data);
        Store.toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
      });
  };

  const col = [
    { label: language.ID, key: 'id_holiday' },
    { label: language.NAME, key: 'name' },
    { label: language.DATE, key: 'date' },
    { label: language.COUNTRY, key: 'country' },
    { label: language.STATE, key: 'state' },
    { label: language.CITY, key: 'city' },
    { label: language.ACTION, key: 'action' },
  ];

  const data = holidays.map((obj) => {
    return {
      id_holiday: obj.id_holiday,
      name: obj.name,
      data: moment(obj.data).utc().format('DD/MM/YYYY'),
      country: obj.country,
      state: obj.state,
      city: obj.city,
      action: (
        <div className="d-flex align-items-center">
          <RaisedButton
            circleButton
            icon="fas fa-edit"
            label={language.EDIT}
            color="default"
            style={{ fontSize: 13, width: 21, height: 21 }}
            onClick={() => {
              setValor(obj)
              setPage('form')
              getStates(obj.id_country);
              getCities(obj.id_state);
              setCountrySelected(obj.id_country)
              setStateSelected(obj.id_state)
              setCitySelected(obj.id_city)
            }}
            className="mr-2"
          />
          <RaisedButton
            circleButton
            icon="far fa-trash-alt"
            label={language.DELETE}
            color="danger"
            style={{ fontSize: 13, width: 21, height: 21 }}
            onClick={() => remove(obj)}
          />
        </div>
      ),
    };
  });

  return (
    <Fragment>
      { page === 'form' && (
        <Dialog
          modal={false}
          open={true}
          onClose={() => setPage('list')}
          title={valor ? language.EDIT : language.ADD}
          maxWidth='lg'
        >
          <Card className="content">
            <CommonForm
              onRef={setFormRef}
              onSubmit={(v) => save(v)}
              values={valor}
              fields={[
                {
                  col: 6,
                  name: 'name',
                  label: language.HOLIDAY_NAME,
                  type: 'text',
                  required: true,
                },
                {
                  col: 6,
                  name: 'data',
                  label: language.HOLIDAY,
                  type: 'date',
                  required: true,
                },
                {
                  col: 6,
                  name: 'id_country',
                  label: language.COUNTRY,
                  placeholder: language.LIST_OF_COUNTRIES,
                  type: 'select',
                  startIcon: 'fas fa-flag',
                  options: countries,
                  required: true,
                },
                {
                  col: 6,
                  name: 'id_state',
                  startIcon: 'far fa-flag',
                  label: language.DISTRICT,
                  type: 'select',
                  options: states,
                },
                {
                  col: 6,
                  name: 'id_city',
                  startIcon: 'fas fa-city',
                  label: language.CITY,
                  type: 'select',
                  options: cities,
                },
              ]}
              onChangeField={(field, optionSelected) => {
                if (field.name === 'id_country') {
                  getStates(optionSelected);
                  setCountrySelected(optionSelected)
                  setStateSelected(null);
                  setCitySelected(null)
                }
                if (field.name === 'id_state') {
                  getCities(optionSelected);
                  setStateSelected(optionSelected);
                  setCitySelected(null)
                }
                if (field.name === 'id_city') {
                  setCitySelected(optionSelected)
                }
              }}
              button={button}
            />
          </Card>
        </Dialog>
      )}

      <CommonTable
        beforeBar={[
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              setPage('form')
              setValor(null)
            }}
          />,
        ]}
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        index="CompanyTimeList"
        paginationTop={false}
      />
    </Fragment>
  );
}
