import React, { useEffect, useState, useRef, Fragment } from 'react';
import './FloatChat.css';
import Constants from '../../data/Constants.js';
import Store from '../../data/Store.js';
import { Avatar } from '@material-ui/core';
import classnames from 'classnames';
import moment from 'moment';
import CommonForm from '../../common/CommonForm2';
import { Paper } from '@material-ui/core';
import AppBar from '@common/AppBar';
import Button from '@common/Button';
import { Menu, MenuItem } from '@material-ui/core';
import TicketUtils from '../../util/TicketUtils';
import Actions from '@data/Actions';

const actions = new Actions();

function Comment(props) {


	let { language, myComment, name_create, description, dt_cad, ticket, name_device, subject, sla, role, user } = props;

	let FDate = moment.locale() == 'pt-br' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';
	myComment = !(user.id == props.fk_id_user ? true : false);


	return (
		<li className={classnames((myComment ? 'received' : 'sended'), 'msg')}>
			<div className='msg-box shadown'>
				{
					ticket && 1 != 1 ? (
						<div>
							<Row>
								{name_device ? <Col md={6}><h4>{language.DEVICE}: {name_device}</h4></Col> : null}
								<Col md={6}><h4>{language.SLA}: {sla}</h4></Col>
							</Row>
							<h4>{language.SUBJECT}: {subject}</h4>
							<hr />
							<ReactQuill value={description} modules={{ toolbar: null }} readOnly={true} style={{ border: 'none' }} />
						</div>
					) : (
							<Fragment>
								<Avatar className='avatar'>
									{name_create.substr(0, 1)}
								</Avatar>
								<div className='box-data'>
									<div className='title-content'>
										<small className='name'>{name_create}</small>
										<small className='date'>{moment(dt_cad).format(FDate + " - HH:mm:ss")}</small>
									</div>
									<div dangerouslySetInnerHTML={{ __html: description }} />

								</div>
							</Fragment>
						)
				}
			</div>
		</li>
	);
}

function OnScrollBottom({ comments, callback }) {
	useEffect(() => {
		callback();
	}, [comments])
	return null;
}
export default function (props) {

	let { language, onFormSubmit, ticket, hideNewComment, closeChat, action, APIEndpoints, comments, loading, redirect,role, redirect2 } = props;

	let [menuSelectTech, setMenuSelectTech] = useState(null);
	let [description, setDescription] = useState({ description: '' });
	let [paperMin, setPaperMin] = useState(false);
	let [ticketNew, setTicketNew] = useState(null);
	let [isSolicitant, setIsSolicitant] = useState(true);

	let type_ticket = isSolicitant ? 'solicitant' : 'tech';
	let msgContainter = useRef(null);

	if (ticketNew != null) {
		ticket = ticketNew;
		// this.up()
	}

	let fields = [{ col: 12, name: 'description', type: 'textEditor',required:true, scroll: true }];

	let button = { label: language.SEND, style: { marginTop: -20 }, loading };
	let user = Store.getUserLoged();
	let comm = [];
	let commentsNodes = [];

	Store.socket.on('chat-ticket-'+Store.getTenant, (data) => {
		action
        .execute('get', Constants.APIEndpoints.TICKET + '/' + ticket.id_tickets, 'ticket').then( res => {
			comments = res.data.comments
			Store.tickets_chat_opens.map(e => {
				if (e.id_tickets == ticket.id_tickets) {
						e.comments = res.data.comments
				}
				return e;
			});


		})
		setScrollBottom();
	})
	comments.filter(e =>  e.type ==='solicitant' ).map((c, k) => {
		if (!comm.find(h => h.id_comments == c.id_comments)) {
			comm.push(c)
			commentsNodes.push(<Comment user={user} key={k} language={language} {...c} myComment={c.fk_id_user === ticket.fk_id_user} />)
		}

	});



	// ---------------------------ID do ticket---------------------------------------
	var id = ticket.id_request === null || ticket.id_request === 0 ? 'T' + ticket.id_tickets : 'R' + ticket.id_request;

	// ---------------------------Lista de técnicos---------------------------------------
	let techgroups = [];

	if (props.groupstech != undefined) {
		techgroups = props.groupstech
			.filter(g => g.fk_id_group_users == ticket.fk_id_group_tech)
			.map(tec => ({ value: tec.id_user, label: tec.name }));
	}





	function setScrollBottom(active) {
		var el = msgContainter.current;
		if (el) {

			var scrollP = (el.scrollHeight - el.scrollTop) - el.offsetHeight;

			if (scrollP <= 300 || active) {
				el.scrollTo(0, 1000000);
			}
		}
	}
	useEffect(() => {
		setScrollBottom(true);

	}, [])

	useEffect(() => {

		if (props.selectTypeMsg) {

			if (props.selectTypeMsg == 'tech') {
				selectTech(ticket.config[0].fk_id_tech)
			} else {
				selectTech(ticket.fk_id_user)
			}
		}
	}, [props.selectTypeMsg])

	function selectTech(id) {
		let values = {};
		values.fk_id_tech_new = id;

		if (id == ticket.fk_id_user) {
			setIsSolicitant(true);
		} else {
			setIsSolicitant(false);
			updateTicketTech(values);
		}
	}
	function updateTicketTech(values) {
		let ticket = props.ticket;
		let req = [];
		if (ticket.config != undefined) {
			if (ticket.config[0].dt_cad != null) {
				req = [
					{
						dt_up: ticket.config[0].dt_up,
						dt_cad: ticket.config[0].dt_cad,
						id_request: ticket.config[0].id_request
					}
				];
			}
		}

		values.sla_time = TicketUtils.calcSla(
			ticket,
			'ticket',
			props.language,
			req,
			ticket.sla_task,
			null
		).time;

		values.fk_id_teck_new = "fk_id_teck_new";
		values.id_tickets = ticket.id_tickets;

		values.action = 'notes';
		let grupo;

		actions
      .execute('post', `${Constants.APIEndpoints.TICKET}/${ticket.id_tickets}`, '', values)
			.then(res => {
				setTicketNew(res.data);
			});

	}


	function onCommentTicket(id_ticket, values, type,role) {

		values.action = 'reply';
		values.id_ticket = id_ticket;
		values.id_user_sponsor = ticket.fk_id_user_sponsor;
		type ='solicitant'
		if (role == 'tech') {
			values.id_user = ticket.config[0].fk_id_tech;
		} else {

			values.id_user = ticket.fk_id_user;
		}
		const result = values.description.search('<img')
		if (result == -1){

		  values.description = values.description.replace(/\\/g, '&#92;');
		  values.description = values.description.replace(/["]+/g, '&#34;');

		}

		if (Store.getUserLoged().id == ticket.fk_id_user) {
			values.destino = ticket.config[0].fk_id_tech;
		} else {
			values.destino = ticket.fk_id_user;
		}
		Store.$emit('chat-ticket-'+Store.getTenant, null, { ...values, type }, 'id_tickets', false, { btn_loading: ['float-chat-' + ticket.id_tickets] }).then(data => {
			Store.tickets_chat_opens.map(e => {
				if (e.id_tickets == data.fk_id_tickets) {
						e.comments.push(data);
				}
				return e;
			});
			if (!!Store.ticket) {
				Store.ticket.comments.push(data);
			}

			Store.new_msg_notifications = Store.new_msg_notifications.filter(e => e.id_tickets != id_ticket)


			setDescription({ description: '' });
		});
	}
	function onChangeField(f, v) {
		setDescription({ description: v });
	}

	return (
		<div className={classnames('FloatChat', 'index-' + props.index)}>
			<OnScrollBottom
				comments={comments.length}
				callback={() => {
					setScrollBottom();
				}}
			/>
			<Paper style={{ overflow: 'visible' }} className={classnames({ 'paper-min': paperMin })}>
				<AppBar>
					<div className='d-flex align-items-center justify-content-between'>
						<div
							className='d-flex align-items-center'
							onClick={() => redirect2('/dash/ticketmanagement', {ticket:{id_tickets:ticket.id_tickets}})}
							style={{
								cursor: 'pointer'
							}}
						>
							<div className='bg-icon'>
								<i className='commentIcon' style={{ width: '100%', height: '100%' }} />
							</div>
							<span className='font ml-2'>{'ticket #' + id}</span>
						</div>

						<div className='d-flex align-items-center'>
							{/*['client', 'tech'].includes(props.role) &&

								<Button
									icon='fas fa-angle-down'
									color='light'
									iconProps={{
										style: { color: 'var(--primary)' }
									}}
									style={{
										fontSize: 18
									}}
									variant='normal'
									label={'Alternar chat para ' + (isSolicitant ? language.TECH : language.SOLICITANT)}
									size={0.65}
									onClick={evt => setIsSolicitant(!isSolicitant)}
								/>*/
							}
							<Button
								icon='fas fa-minus'
								color='light'
								variant='circle'
								size={0.65}
								className='mx-2'
								onClick={() => setPaperMin(!paperMin)}
							/>
							<Button
								icon='fas fa-times'
								color='light'
								variant='circle'
								size={0.65}
								onClick={() => closeChat(ticket.id_tickets)}
							/>
						</div>
					</div>
				</AppBar>
				<ul className='msg-containter mt-0' ref={msgContainter} style={!!props.maxHeightChat ? { height: props.maxHeightChat } : {}}>
					{commentsNodes}
				</ul>

				{
					!hideNewComment &&
					<div className='content px-2 pt-0'>

						{props.statusTicket === 'Closed' ?
							null :
							<CommonForm
								fields={fields}
								button={button}
								onSubmit={(values) => onCommentTicket(ticket.id_tickets, values, isSolicitant ? 'solicitant' : 'tech',role)}
								onChangeField={onChangeField}
								clean
							/>
						}
					</div>
				}
			</Paper>
		</div>
	)
}
