import React, {memo} from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import CommonForm from '@common/CommonForm2';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';
import withLanguage from '../../../../common/languages/withLanguage';

import {CallsAnswered1} from './Graphs';
import moment from 'moment';
export default memo(withLanguage(({language, data=[], setMonth, month, setYear, year, user})=>{

   return(
      <Card id='CallsAnswered1'>
         <AppBar title={user.role == 'solicitant' ? language.CALLS : language.ANSWERED_CALLS_RESOLVED_AND_CLOSED} subTitle={`(${language.IN_THE_YEAR})`}>
            <div style={{zoom:.9, width:200}}>
               <CommonForm
                  individual
                  values={{year}}
                  fields={[
                     {
                        type:'select',
                        name:'year',
                        label: language.YEAR,
                        options:[
                            {
                              value: moment().year() - 5,
                              label: moment().year() - 5,
                            },
                            {
                              value: moment().year() - 4,
                              label: moment().year() - 4,
                            },
                            {
                              value: moment().year() - 3,
                              label: moment().year() - 3,
                            },
                            {
                              value: moment().year() - 2,
                              label: moment().year() - 2,
                            },
                            {
                              value: moment().year() - 1,
                              label: moment().year() - 1,
                            },
                            { 
                              value: moment().year(), 
                              label: moment().year() 
                            },
                            {
                              value: moment().year() + 1,
                              label: moment().year() + 1,
                            }
                           
                        ]
                     }
                  ]}
                  onChangeField={(f,v) => {
                     setYear(v)
                  }}
               />
            </div>
         </AppBar>
         <div className='content-medium pt-0'>
            <ReactHighcharts config={CallsAnswered1({language, data, setMonth, month})}/>
         </div>
      </Card>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))