import React, { Component, Fragment, useState } from 'react';
import { observer } from 'mobx-react';

import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import CommonPage from '@common/CommonPage';
import { Container, Row, Col } from 'react-grid-system';
import { DatePicker } from '@material-ui/core';
import { Card, Paper, Grid } from '@material-ui/core';
import Button from '@common/Button';
import PageLayout from '@common/PageLayout';
import { TextField } from '@material-ui/core';
import { green100 } from 'material-ui/styles/colors';
import CSSModules from 'react-css-modules';
import {
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  List,
  ListItem,
} from '@material-ui/core'; // controls
import * as styles from '../styles.scss';
import AppBar from '@common/AppBar2';
import HeaderPrint from '@common/HeaderPrint';
import ReactToPrint from 'react-to-print';
import './styles.css';
import Progress from '@common/Progress';
import Badge from '@material-ui/core/Badge';
import { Tooltip } from 'react-tippy';
import Boll from '@common/Boll';
import Project from '../Project';
import MyList from '@common/List';
import Loading from '@common/Loading';
import CommonForm from '@common/CommonForm2';
import moment from 'moment';
import './index.css';
import { flexBasis } from '@common/Func';

// ---------------------------------PAGES------------------------------
import ProjectsEvolution from './ProjectsEvolution';
import ProjectPortfolio from './ProjectPortfolio';
import TeamAllocation from './TeamAllocation';
import ProjectDelivery from './ProjectDelivery';
import DialogFormProject from './DialogFormProject';
import DialogFormActivity from './DialogFormActivity';

// -------------------------ReactHighcharts----------------------------
import ContainerChart from '../PagePMO.graph.js';
import ReactHighcharts from 'react-highcharts';

@observer
class ProjectManagementContainer extends CommonPage {
  constructor() {
    super();

    this.state = {
      open: false,
      pj: [],
      idcompany: 0,
      dt_ini: '',
      dt_end: '',
      activity: [],
      openActivity: false,
      selectGestor: [],
      loadFilter: true,
      gestor: 0,
    };

    this.setOpen = this.setOpen.bind(this);
    this.setOpenActivity = this.setOpenActivity.bind(this);
  }
  setOpen(project) {
    this.setState({ open: true, pj: project, openActivity: false });
  }

  setOpenActivity(activity) {
    this.setState({ openActivity: true, activity: activity, open: false });
  }

  render() {
    const {
      emp,
      handleValues,
      language,
      projects,
      companies,
      searchPmo,
      users,
    } = this.props;

    let fprojects = projects;
    let selectItens = [];
    selectItens.push({ value: 0, label: language.ALL });

    let button = {
      md: 2,
      offset: { md: 10 },
      icon: 'fas fa-check-circle ',
      variant: 'circle',
      circle: true,
      label: language.SEARCH,
      primary: true,
      style: { marginTop: '-70px' },
    };
    companies &&
      companies.map((c) => {
        selectItens.push({ value: c.id_company, label: c.name });
      });

    let usersGestorProject = [];
    //console.log(projects);
    projects &&
      projects.projetos.map((p, i) => {
        usersGestorProject.push(p.user_cad);
      });
    if (usersGestorProject.length > 0) {
      users
        .filter((h) => usersGestorProject.includes(h.id_user))
        .map((h) => {
          this.state.selectGestor.push({
            value: h.id_user,
            label: h.name,
          });
        });
      this.state.selectGestor.push({
        value: 0,
        label: language.ALL,
      });
    }

    return (
      <PageLayout
        icon={
          <div
            className="projectManIcon"
            style={{ width: '40px', height: '40px', margin: 0, left: 6 }}
          />
        }
        title={language.PROJECT_MANAGEMENT}
        subTitle={language.PROJECT_MANAGEMENT + '/'}
        rightElements={[
          <div
            zoom={0.8}
            style={{ minWidth: 900, zoom: 0.8 }}
            className="mr-4 mt-2"
          >
            <CommonForm
              individual
              spacing={2}
              hiddenSubmit
              onSubmit={(e) => searchPmo(e)}
              onChangeField={(f, v) => {
                if (f.name == 'gestor') {
                  this.setState({ gestor: v, loadFilter: false });
                }
              }}
              gridProps={{
                alignItems: 'center',
              }}
              fields={[
                {
                  col: 2.75,
                  type: 'select',
                  name: 'gestor',
                  label: language.PROJECT_MANAGER,
                  options: this.state.selectGestor,
                },
                {
                  col: 2.75,
                  type: 'date',
                  name: 'dt_ini',
                  label: language.INITIAL_DATE,
                  endIcon: 'fas fa-calendar-alt',
                },
                {
                  col: 2.75,
                  type: 'date',
                  name: 'dt_end',
                  label: language.FINAL_DATE,
                  endIcon: 'fas fa-calendar-alt',
                },
                {
                  col: 2.75,
                  type: 'select',
                  name: 'company',
                  label: language.COMPANY,
                  options: selectItens,
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-search"
                      color="success"
                      variant="circle"
                      onClick={() => submit()}
                    />
                  ),
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (
                    <Button
                      icon="fas fa-sync-alt"
                      variant="circle"
                      style={{ left: 11 }}
                      onClick={() => this.redirect('/dash/gp/pmo')}
                    />
                  ),
                },
              ]}
            />
          </div>,
          <Button
            icon="fas fa-print"
            color="primary"
            label={language.PRINT_PMO}
            style={{ marginTop: '5px' }}
            onClick={() => window.print()}
          />,
        ]}
      >
        {
          <div id="PainelPmo" ref={(el) => (this.componentRef = el)}>
            <Card className="border-radius-10">
              <AppBar title={language.PANEL_PMO} />
              <div className="content pt-0 px-3 content-cards-pmo">
                <Grid container spacing={16}>
                  {[
                    {
                      label: language.BACKLOG_PROJECTS,
                      value:
                        projects &&
                        projects.backlog.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ).length,
                      pj:
                        projects &&
                        projects.backlog.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ),
                    },
                    {
                      label: language.PROJECT_IN_EXECUTION,
                      value:
                        projects &&
                        projects.execution.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ).length,
                      pj:
                        projects &&
                        projects.execution.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ),
                    },
                    {
                      label: language.PROJECT_CLOSED_IN_MONTH,
                      value:
                        projects &&
                        projects.finalized.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ).length,
                      pj:
                        projects &&
                        projects.finalized.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ),
                    },
                    {
                      label: language.PROJECT_IN_ARREARS,
                      value:
                        projects &&
                        projects.delay.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ).length,
                      pj:
                        projects &&
                        projects.delay.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ),
                    },
                    {
                      label: language.LATE_ACTIVITIES,
                      value: projects && projects.delayallac.length,
                      ac: projects.delayallac,
                    },
                    {
                      label: language.PROJECT_STARTED_IN_MONTH,
                      value:
                        projects &&
                        projects.startMonth.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ).length,
                      pj:
                        projects &&
                        projects.startMonth.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ),
                    },
                    {
                      label: language.NEXT_EXPIRATION,
                      value:
                        projects &&
                        projects.endMonth.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ).length,
                      pj:
                        projects &&
                        projects.endMonth.filter((p) =>
                          this.state.gestor == 0
                            ? p.user_cad > 0
                            : p.user_cad === this.state.gestor
                        ),
                    },
                    {
                      label: language.TOTAL_VALUE_PROJECT_IN_MONTH,
                      value: projects && projects.sumProject,
                      pj: [],
                    },
                  ].map((e, i) => (
                    <Grid
                      key={i}
                      className="cards-item-content"
                      item
                      xs={1.5}
                      style={{ ...flexBasis(1.5) }}
                    >
                      <div
                        className="cards-item"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          e.pj ? this.setOpen(e.pj) : this.setOpenActivity(e.ac)
                        }
                      >
                        <span className="mt-1">{e.label}</span>

                        <h2 className="mb-2">
                          {i == 7
                            ? e.value &&
                              e.value.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })
                            : e.value && e.value.toString().padStart(3, 0)}
                        </h2>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div className="content">
                <ProjectsEvolution
                  language={language}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  projects={{
                    projetos: projects.projetos.filter((p) =>
                      this.state.gestor == 0
                        ? p.user_cad > 0
                        : p.user_cad === this.state.gestor
                    ),
                  }}
                />
              </div>
            </Card>
            <Grid container spacing={16} className="mt-1">
              <Grid item xs={7.7} style={{ ...flexBasis(7.7) }}>
                <ProjectPortfolio
                  users={users}
                  setOpenActivity={this.setOpenActivity}
                  language={language}
                  redirect={this.redirect}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  projects={{
                    projetos: projects.projetos.filter((p) =>
                      this.state.gestor == 0
                        ? p.user_cad > 0
                        : p.user_cad === this.state.gestor
                    ),
                  }}
                />
              </Grid>
              <Grid
                className="cards-item-content"
                item
                xs={4.3}
                style={{ ...flexBasis(4.3) }}
              >
                <TeamAllocation
                  users={users}
                  projects={projects}
                  language={language}
                />
                <ProjectDelivery projects={projects} language={language} />
              </Grid>
            </Grid>
          </div>
        }

        {this.state.open == true ? (
          <DialogFormProject
            language={language}
            projects={this.state.pj}
          ></DialogFormProject>
        ) : null}
        {this.state.openActivity == true ? (
          <DialogFormActivity
            language={language}
            redirect={this.redirect}
            activity={this.state.activity}
          ></DialogFormActivity>
        ) : null}
      </PageLayout>
    );
  }
}

export default CSSModules(ProjectManagementContainer, styles);
