import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import moment from 'moment';
import CommonPage from '../../common/CommonPage';
import CIOPage from './CioPage';

import CIOPageDetail from './CIOPageDetail';

import Constants from '../../data/Constants';
import Actions from '@data/Actions'
import Store from '@data/Store'
const action = new Actions()

const CIOContainer = () => {
  const [page, setPage] = useState(null)
  const [ioAlertsLinks, setIoAlertsLinks] = useState(null)
  const [ioAlertsServers, setIoAlertsServers] = useState(null)
  const [teia, setTeia] = useState([])
  const [devicesAvailable, setDevicesAvailable] = useState([])
  const [companyId, setCompanyId] = useState(1)
  const [values, setValues] = useState([])
  const [tickets, setTickets] = useState([])
  const [projects, setProjects] = useState([])
  const [matrizMonth, setMatrizMonth] = useState([])
  const [devices, setDevices] = useState([])
  const [milestone, setMilestone] = useState([])
  const [itsm, setItsm ] = useState([])
  const [contracts, setContracts] = useState([])
  const [top10, setTop10] = useState([])
  const [slas, setSlas] = useState([])
  const [cioProject, setCioProject] = useState([])
  const [matrizProblem, setMatrizProblem] = useState([])
  const [matriz, setMatriz] = useState([])
  const [risksTest, setRisksTest] = useState([])
useEffect(() => {
  action.execute(
    'get',
    Constants.APIEndpoints.PROJECT + '/pmo',
    '$get-pmos,$get-projectsDeletedCount,$get-pmosDeleted'
  );

  action.execute('get', Constants.APIEndpoints.PROJECTS, '').then(res => setProjects(res.data));
  action.execute('get', Constants.APIEndpoints.DASHBOARD +'biCIO' +`?month=${moment().format('MM')}&year=${moment().format('YYYY')}&company=`+Store.getUserLoged().perfil.fk_id_company,
    'tickets'
  ).then(res => {

    setTickets(res.data)}
    )

  action.execute(
    'get',
    Constants.APIEndpoints.RISKMATRIZ + '/' + 'month',
    'matrizmonth'
  ).then(res => setMatrizMonth(res.data))

  action
    .execute(
      'post',
      Constants.APIEndpoints.MONITOR + '/deviceavailavility',
      '',
      {}
    )
    .then((c) => {
      setDevicesAvailable(c.data)
    });

  action.execute(
    'get',
    Constants.APIEndpoints.DEVICE + '?all=true',
    'devices'
  ).then(res => setDevices(res.data))

  action.execute(
    'get',
    Constants.APIEndpoints.RISKMATRIZ + '/' + 'problem',
    'matrizproblem'
  ).then(res => setMatrizProblem(res.data))

  action.execute(
    'get',
    Constants.APIEndpoints.MATRIZ + 'getmatriz',
    'matriz'
  )
  action.execute(
    'post',
    Constants.APIEndpoints.PROJECT + '/listMilestoneSt',
    'milestone',
    {},
    undefined,
    undefined,
    false
  ).then(res => setMilestone(milestone))
  let { month, year } = queryString.parse(location.search);
  if (!month && !year) {
    const today = moment();
    month = today.month() + 1;
    year = today.year();
  }
  let item = null;

  if (window.location.href.indexOf('cio') > -1) {
    item = 'dashcio';
  } else {
    item = 'dash';
  }

  if (item === 'dashcio') {

    action.execute(
      'get',
      Constants.APIEndpoints.DASHBOARD +
        '/itsm?date=' +
        moment(year + '-' + month).format('YYYY-MM'),
      'itsm'
    ).then(res => setItsm(res.data))


    action.execute('POST', Constants.APIEndpoints.CONTRACT+'/all?fk_id_out_company=1', 'contracts').then(res => setContracts(res.data));

    action.execute(
      'get',
      Constants.APIEndpoints.DASHBOARD + '/top10',
      'top10'
    ).then(res => setTop10(res.data))

    action.execute('get', Constants.APIEndpoints.SLA, 'slas').then(res => setSlas(res.data))

    action.execute('get', Constants.APIEndpoints.PROJECT, 'cio_project').then(res => setCioProject(res.data))

    action.execute(
      'get',
      Constants.APIEndpoints.PROJECT + '/getDetailAll',
      'statusProject'
    );

    action
      .execute(
        'get',
        `${Constants.APIEndpoints.RISK}/getDashMaturity/1`,
        'statusProject'
      )
      .then((res) => {
        setTeia(res.data)
      });
  }
  setPage(item)
}, [])




 const handlePage = (page, values) => {
    setPage(page)
    setValues(values)
  }

 const handleCompany = (companyId, month, currentYear) => {
      let CompanyId = [];

      CompanyId = companyId.length > 0 ? companyId : Store.getUserLoged().perfil.fk_id_company;

  let MonthDashoard = month       ? month       : moment().format('MM');
  let YearDashoard  = currentYear ? currentYear : moment().format('YYYY');

    action.execute(
      'get',
      Constants.APIEndpoints.RISK + '/dash/0/company/' + CompanyId,
      'risks_test'
    ).then(res => setRisksTest(res.data))

    action.execute('POST', Constants.APIEndpoints.CONTRACT+'/all?fk_id_out_company='+ CompanyId, 'contracts').then(res => setContracts(res.data));

    action.execute(
        'get',
        Constants.APIEndpoints.DASHBOARD +
          '/itsm?date=' +
          moment(YearDashoard + '-' + MonthDashoard).format('YYYY-MM'),
        'itsm'
      ).then(res => setItsm(res.data))

    action.execute(
      'get',
      Constants.APIEndpoints.DASHBOARD +
        'biCIO' +
        `?month=${MonthDashoard}&year=${YearDashoard}&company=${JSON.stringify(CompanyId)}`,
      'tickets'
    ).then(res => setTickets(res.data))

    action.execute('get', Constants.APIEndpoints.PROJECTS, '').then(res => setProjects(res.data));

    action.execute(
      'get',
      Constants.APIEndpoints.RISKMATRIZ + '/' + 'month',
      'matrizmonth'
    ).then(res => setMatrizMonth(res.data))

    action
      .execute(
        'post',
        Constants.APIEndpoints.MONITOR + '/deviceavailavility',
        '',
        {}
      )
      .then((c) => {
        setDevicesAvailable(c.data)
      });

    action.execute(
      'get',
      Constants.APIEndpoints.DEVICE + '?all=true',
      'devices'
    ).then(res => setDevices(res.data))

    action.execute(
      'get',
      Constants.APIEndpoints.RISKMATRIZ + '/' + 'problem',
      'matrizproblem'
    ).then(res => setMatrizProblem(res.data))

    action.execute(
      'get',
      Constants.APIEndpoints.MATRIZ + 'getmatriz',
      'matriz'
    )
    action.execute(
      'post',
      Constants.APIEndpoints.PROJECT + '/listMilestoneSt',
      'milestone',
      {},
      undefined,
      undefined,
      false
    ).then(res => setMilestone(milestone))

    action
      .execute(
        'get',
        `${Constants.APIEndpoints.RISK}/getDashMaturity/${CompanyId}`,
        'statusProject'
      )
      .then((res) => {
        setTeia(res.data)
      });
    setCompanyId(CompanyId)
  }

  // console.log('-=----------------------------', tickets)
      return (
        <CommonPage
          title={'CIO'}
          showMenuIconButton={false}
          iconAlert={true}
          loading={false}
        >
          {page === 'dashcio' ? (
            tickets && (
              <CIOPage
                handleCompany={handleCompany}
                companyId={companyId}
                devices={devices}
                contracts={contracts}
                action={action}
                APIEndpoints={Constants.APIEndpoints}
                statusProject={teia}
                devicesAvailable={devicesAvailable}
                projects={cioProject}
                handlePage={handlePage}
                itsm={itsm}
                teia={teia}
                dashCount={tickets}
                language={Store.language}
                tests={risksTest}
                matriz={matriz}
                top10={top10 && top10.length ? top10[0] : ''}
                gsdCount={tickets}
                milestone={milestone}
                matrizproblem={matrizProblem}
                matrizmonth={matrizMonth}
              />
            )
          ) : page === 'detail' ? (
            <CIOPageDetail
              gsdCount={tickets}
              devices={devices}
              slas={slas}
              language={Store.language}
              handlePage={handlePage}
              values={values}
            />
          ) : (
            ''
          )}
        </CommonPage>
      );
  }


export default CIOContainer
