import React, { Component, Fragment, useEffect, useState } from 'react';
import { Chip, Switch } from '@material-ui/core';

import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import Header from '../../../common/Header';
import CommonPage from '../../../common/CommonPage';
import PageLayout from '../../../common/PageLayout';
import CommonTable from '../../../common/CommonTable';
import RaisedButton from '../../../common/RaisedButton';
import DialogConfirm from '../../../common/DialogConfirm';
import Store from '../../../data/Store';
import Constants from '../../../data/Constants';
import Actions from '@data/Actions';
import WorkflowForm from './workflowForm';
import './index.css';

const action = new Actions();
const defaultPageDelete = { open: false, values: {} };

const index = () => {
  const { language } = Store;
  const [page, setPage] = useState('list');
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [workflowList, setWorkflowList] = useState([]);
  const [pageDelete, setPageDelete] = useState(defaultPageDelete);

  const loadWorkflow = () => {
    action
      .execute(
        'get',
        `${Constants.APIEndpoints.CHANGE}/approval/rules`,
        ''
      )
      .then((res) => {
        setWorkflowList(res.data);
      });
  };

  useEffect(() => {
    loadWorkflow();
  }, []);

  const handlePageDelete = (data) => {
    setPageDelete(data);
  };

  const deleteNotification = () => {
    action
      .execute(
        'delete',
        `${Constants.APIEndpoints.CHANGE}/approval/rules/${pageDelete.values.id_change_approval_rules}`,
        ''
      )
      .then((res) => {
        loadWorkflow();
        handlePageDelete(defaultPageDelete);
      });
  };

  let col = [
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'description',
      label: language.DESCRIPTION,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
      search: false
    },
  ];

  const data = workflowList.map((item) => ({
    name: item.name,
    description: item.description,
    action: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 13 }}
                size={0.7}
                className="mr-2"
                onClick={() => {
                  setValue(item);
                  setPage('condition');
                }}
              />
              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 14 }}
                size={0.7}
                onClick={() => handlePageDelete({ open: true, values: item })}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  return (
    <div>
      <CommonTable
        title=""
        index=""
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              setValue(null);
              setPage('sla');
            }}
          />
        }
      />
      {page !== 'list' ? (
        <Dialog
          title={
            page === 'condition'
              ? language.GMUD_WORKFLOW_EDITION
              : language.GMUD_WORKFLOW_REGISTRATION
          }
          open={true}
          onClose={() => setPage('list')}
          maxWidth="md"
          miniVersion = {true}
        >
          <WorkflowForm
            loadWorkflow={loadWorkflow}
            language={language}
            value={value}
            onClose={() => setPage('list')}
          />
        </Dialog>
      ) : null}
      {pageDelete.open && (
        <DialogConfirm
          open={pageDelete.open}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={deleteNotification}
          onClose={() => handlePageDelete(defaultPageDelete)}
          title={language.CONFIRM_DELETE}
          message={pageDelete.values.name}
        />
      )}
    </div>
  );
};

export default index;
