import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import Animate from '@common/Animate';
import Actions from '@data/Actions';

import DeviceICList from './DeviceICList';
import DeviceICForm from './DeviceICForm';

const action = new Actions();

@observer
export default class DeviceICContainer extends CommonPage {
  constructor() {
    super();

    this.state = {
      value: '',
      page: 'list',
      listDevicesIC: []
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    action
      .execute('post', this.APIEndpoints.DEVICEIC +'/list')
      .then((res) => {
          this.setState({ listDevicesIC: res.data })
        })
  }

  onFormSubmit(values) {
    values.user_id = this.store.getUserLoged().id;
    let method = values.hasOwnProperty('id_device_ic') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_device_ic')
      ? this.APIEndpoints.DEVICEIC + '/' + values.id_device_ic
      : this.APIEndpoints.DEVICEIC;

      action
        .execute(method, url, '', values)
        .then((res) => {
          this.setState({ listDevicesIC: res.data })
          this.handlePage('list');
        })

  }

  onDelete() {
    let { values } = this.state;
    values.user_id = this.store.getUserLoged().id;
    
    action
      .execute('delete', this.APIEndpoints.DEVICEIC + '/' + values.id_device_ic, '', values)
      .then((res) => {
        this.setState({ listDevicesIC: res.data })
        this.handlePage('list');
      })
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    let { language } = this.store;

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <DeviceICList
            listDevicesIC={this.state.listDevicesIC}
            redirect={this.redirect2}
            language={language}
            handlePage={this.handlePage}
            store={this.store}
            configurationProps={configurationProps}
          />
        </Animate>
        {page === 'form' ? (
          <DeviceICForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.name}
          />
        ) : null}
      </Fragment>
    );
  }
}
