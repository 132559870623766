import React, {memo} from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';
import withLanguage from '../../../../common/languages/withLanguage';

const Indicator = ({label, value, color})=>(
   <div style={{backgroundColor:color}} className='indicator content-medium'>
      <span>{label}</span>
      <h2>{value.toString().padStart(3, 0)}</h2>
   </div>
)
/* 
----------------------------format----------------------------
let data = [
      {
         label:'Atenção',
         value:3,
         color:'var(--danger)'
      },
      {
         label:'Alerta',
         value:3,
         color:'var(--warning)'
      },
      {
         label:'Ok',
         value:3,
         color:'var(--success)'
      }
   ]

*/
export default memo(withLanguage(({ page, data = [], language })=>{
   
   return(
      <Card id='Availability'>
         <AppBar title={language.AVAILABILITY} />
         <div className='content-medium pt-0'>
            <Grid container spacing={2}>
               {data.map(e=>(
                  <Grid key={e.label} item xs={4}>
                     <Indicator label={e.label} value={0} color={e.color}/>
                  </Grid>
               ))}
            </Grid>
         </div>
      </Card>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))