import React, { Component, Fragment, useEffect, useState } from 'react';
import './index.css';
import Store from '@data/Store';
import Button from '@common/Button';
import RaisedButton from '../../common/RaisedButton';
import Dialog from '@common/Dialog';
import FormSlaWork from './formSlaWork';
import CommonTable from '@common/CommonTable';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const index = () => {
  let { language, host } = Store;
  const [open, setOpen] = useState(false);
  const [listWorks, setWorks] = useState([]);
  const [values, setValues] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const loadWork = () => {
    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/workday`, '')
      .then((res) => {
        setWorks(res.data);
      });
  };

  const responseTimezones = () => {
    action
      .execute('get', Constants.APIEndpoints.TIMEZONE, '')
      .then((response) => {
        setTimezoneOptions(response.data.map(item => ({ value: item.id_timezone, label: item.name, })) || [])
      });
  };

  useEffect(() => {
    responseTimezones();
    loadWork();
  }, []);

  let col = [
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },

    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 10, width: 10, overflow: 'auto', textAlign: 'center' },
    },
  ];

  let data = listWorks.map((item) => ({
    name: item.wk_name,
    action: (
      <Button
        variant="circle"
        icon="fas fa-edit"
        label={language.EDIT}
        color="default"
        style={{ fontSize: 12 }}
        className="mr-2"
        size={0.7}
        onClick={() => {
          setValues(item);
          setOpen(true);
        }}
      />
    ),
  }));

  return (
    <div>
      <CommonTable
        title=""
        index=""
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={[
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              setValues([]);
              setOpen(true);
            }}
          />,
        ]}
      />

      {open == true ? (
        <Dialog
          title={language.WORKDAY}
          open={open}
          onClose={() => {
            loadWork(), setOpen(false);
          }}
          maxWidth="md"
        >
          <FormSlaWork
            values={values}
            timezoneOptions={timezoneOptions}
            setOpen={setOpen}
            onClose={() => {
              loadWork(), setOpen(false);
            }}
          />
        </Dialog>
      ) : null}
    </div>
  );
};

export default index;
