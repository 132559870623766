import React, { useState, useEffect } from 'react';

import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import Sidebar from './Sidebar';
import { Dashboard } from './Dashboard';
import { SidebarCompanies } from './SidebarCompanies';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './style.css';
import { DashboardMultiCatalog } from './DashboardMultiCatalog';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store'
import withLanguage from '../../common/languages/withLanguage';

export default withLanguage(function PortalContainer({ language }) {
  const action = new Actions();
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [openMenu, setOpenMenu] = useState(true);

  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [categoryDepartment, setCategoryDepartment] = useState([]);
  const [generalParams, setGeneralParams] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [expanded, setExpanded] = useState(true);
  const [expandedCompany, setExpandedCompany] = useState(true);

  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [idCompany, setIdCompany] = useState(0);

  const [hasMoreCategories, setHasMoreCategories] = useState(true);
  const [totalCategories, setTotalCategories] = useState(0);
  const [offsetCategories, setOffsetCategories] = useState(0);

  const [hasMoreCompanies, setHasMoreCompanies] = useState(true);
  const [totalCompanies, setTotalCompanies] = useState(1000);
  const [offsetCompanies, setOffsetCompanies] = useState(0);

  const [subFavorites, setSubFavorites] = useState([]);
  const [tasksFavorites, setTasksFavorites] = useState([]);

  const [isFilterTask, setIsFilterTask] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [allCatalog, setAllCatalog] = useState([]);

  const [singleTaskSelected, setSingleTaskSelected] = useState([]);

  const [search, setSearch] = useState('');

  const [taskFavorite, setTaskFavorite] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [values, setValues] = useState([]);
  const [resetDepartment, setResetDepartment] = useState(false);
  const [resetCatalog, setResetCatalog] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeCompany = (panel) => (event, newExpanded) => {
    setSelectedCompany(panel);
    setIdCompany(panel.id_company);
    action
      .execute('post', Constants.APIEndpoints.DEPARTMENT + '/filter?', '', {
        fk_id_company: panel.id_company,
        catalog: true,
      })
      .then((res) => {
        let dpt = res.data;
    
        dpt = dpt.sort(function (a, b) {
          if (a.name.normalize('NFD') > b.name.normalize('NFD')) return 1;
          if (a.name.normalize('NFD') < b.name.normalize('NFD')) return -1;
          return 0;
        });
        setDepartmentsOptions(dpt);
        setExpandedCompany(newExpanded ? panel.id_company : false);
      });
  };

  useEffect(() => {
    const user = Store.getUserLoged()
    action
      .execute('get', Constants.APIEndpoints.GENERAL_PARAMETERS)
      .then((res) => {
        setGeneralParams(res.data);
        if (res.data.mult_catalog) {
          action
            .execute(
              'post',
              Constants.APIEndpoints.COMPANY +
              `/all?limit=75&offset=${offsetCompanies}${offsetCompanies}&${res.data.list_only_customer_type_companies ? 'type=1' : ''}`,
              '',
              { catalog: true }
            )
            .then((c) => {
              getTasksFavorites();

              setOffsetCompanies(offsetCompanies + 75);

              setTotalCompanies(parseInt(c.data[0].count));
              if (parseInt(c.data[0].count) == c.data.length) {
                setHasMoreCompanies(false);
              }
              setCompanies(c.data);
              if (c.data.length == 1) {
                values.fk_id_company = c.data[0].id_company;
                setValues(values);
                handleChangeCompany({ id_company: c.data[0].id_company })(
                  null,
                  true
                );
              }
              setCompanyOptions(
                c.data.map((c_) => ({ value: c_.id_company, label: c_.name }))
              );
            });
        }
      });

    action
      .execute(
        'post',
        Constants.APIEndpoints.CATALOG +
        `/company/${user.id_company}/?limit=75&offset=${offsetCategories}`
      )
      .then((res) => {
        setOffsetCategories(offsetCategories + 75);
        setTotalCategories(parseInt(res.data[0].total_count));
        if (parseInt(res.data[0].total_count) == res.data.length) {
          setHasMoreCategories(false);
        }
        setCategories(res.data);
        getFavorites();
      });
  }, []);

  const filterCatalog = (filter) => {
    setSearch(filter);
    let data = { filter: filter };
    action
      .execute('post', Constants.APIEndpoints.CATALOG + '/company/1', '', data)
      .then((res) => {
        setCategories(res.data);
      });
  };

  const filterCompany = (key) => {
    if (key.keyCode == 13) {
      setSearch(key.target.value);
      let data = { name: key.target.value };
      action
        .execute('post', Constants.APIEndpoints.TASK + '/filter', '', data)
        .then((res) => {
          setAllCatalog(res.data);
          setIsFilterTask(true);
          setResetDepartment(true);
          setSelectedDepartment([]);
        });
    }
  };

  const getFavorites = () => {
    let data = { get: true, is_catalog: true };
    action
      .execute('post', Constants.APIEndpoints.CATALOG + '/favorite', '', data)
      .then((res) => {
        setSubFavorites(res.data);
      });
  };

  const getTasksFavorites = () => {
    let data = { get: true, is_catalog: false };
    action
      .execute('post', Constants.APIEndpoints.CATALOG + '/favorite', '', data)
      .then((res) => {
        setTasksFavorites(res.data);
      });
  };

  const handleFavorite = (type, id, fk_id_catalog_service, fk_id_category) => {
    if (type == 'subCategory') {
      let data = {
        id_catalog_service: id,
        fk_id_category: selectedCategory.id_category,
        is_catalog: true,
      };
      action
        .execute('post', Constants.APIEndpoints.CATALOG + '/favorite', '', data)
        .then((res) => {
          action
            .execute('post', Constants.APIEndpoints.CATALOG + '/company/1')
            .then((res) => {
              setCategories(res.data);
              getFavorites();
            });
        });
    } else if (type == 'catalog_task') {
      let data = {
        id_catalog_task: id,
        is_catalog: false,
        fk_id_catalog_service: fk_id_catalog_service,
        fk_id_category: fk_id_category,
      };
      action
        .execute('post', Constants.APIEndpoints.CATALOG + '/favorite', '', data)
        .then((res) => {
          getTasksFavorites();
        });
    } else if (type == 'removeFavorite') {
      let data = {
        id_catalog_service: id,
        fk_id_category: fk_id_category,
        is_catalog: true,
      };
      action
        .execute('post', Constants.APIEndpoints.CATALOG + '/favorite', '', data)
        .then((res) => {
          action
            .execute('post', Constants.APIEndpoints.CATALOG + '/company/1')
            .then((res) => {
              setCategories(res.data);
              getFavorites();
            });
        });
    }
  };

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.SELF_SERVICE_PORTAL}
        subTitle={language.ROOT_PREVIOUS_LEVEL}
        rightElements={[
          <div style={{ marginTop: 15, width: 400, marginRight: '-12%' }}>
            {generalParams.mult_catalog == true && (
              <CommonForm
                values={values}
                fields={[
                  {
                    col: 12,
                    type: 'select',
                    name: 'fk_id_company',
                    label: language.COMPANY,
                    options: companyOptions,
                  },
                ]}
                onChangeField={(f, v) => {
                  handleChangeCompany({ id_company: v })(null, true);
                }}
                style={{ marginBottom: -10 }}
              />
            )}
          </div>,
        ]}
      ></PageLayout>
      <div className="leftBar">
        {openMenu && generalParams.mult_catalog !== true ? (
          <Sidebar
            categories={categories}
            language={language}
            open={openMenu}
            setSelectedSubcategory={setSelectedSubcategory}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            filterCatalog={filterCatalog}
            expanded={expanded}
            handleChange={handleChange}
            handleFavorite={handleFavorite}
            hasMoreCategories={hasMoreCategories}
            setHasMoreCategories={setHasMoreCategories}
            offsetCategories={offsetCategories}
            setOffsetCategories={setOffsetCategories}
            totalCategories={totalCategories}
            subFavorites={subFavorites}
            setResetCatalog={setResetCatalog}
          />
        ) : openMenu && generalParams.mult_catalog == true ? (
            <SidebarCompanies
            language={language}
            companies={companies}
            departments={departments}
            setSelectedDepartment={setSelectedDepartment}
            setSelectedCompany={setSelectedCompany}
            selectedCompany={selectedCompany}
            expandedCompany={expandedCompany}
            handleChangeCompany={handleChangeCompany}
            handleFavorite={handleFavorite}
            departmentsOptions={departmentsOptions}
            filterCompany={filterCompany}
            hasMoreCompanies={hasMoreCompanies}
            setHasMoreCompanies={setHasMoreCompanies}
            setOffsetCompanies={setOffsetCompanies}
            setCompanies={setCompanies}
            offsetCompanies={offsetCompanies}
            totalCompanies={totalCompanies}
            tasksFavorites={tasksFavorites}
            isFilterTask={isFilterTask}
            allTasks={allTasks}
            setSingleTaskSelected={setSingleTaskSelected}
            setIsFilterTask={setIsFilterTask}
            setTaskFavorite={setTaskFavorite}
            setSelectedCategory={setSelectedCategory}
            setResetDepartment={setResetDepartment}
            resetDepartment={resetDepartment}
            setSearch={setSearch}
          />
        ) : null}
        {openMenu ? (
          <IconButton
            size="small"
            aria-label="Menu"
            onClick={() => setOpenMenu(false)}
            style={{
              height: 30,
              backgroundColor: '#F09726',
              color: 'white',
              borderRadius: 0,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <div className="divCatalogOpenMenu" onClick={() => setOpenMenu(true)}>
              {language.CATALOG}
          </div>
        )}
        {selectedCompany.id_company ||
          singleTaskSelected.id_catalog_task ||
          generalParams.mult_catalog == true ||
          taskFavorite.id_category ? (
          <DashboardMultiCatalog
            key={`key_${selectedCompany?.id_company}_${selectedDepartment?.id_department}`}
            language={language}
            selectedCompany={selectedCompany}
            selectedDepartment={selectedDepartment}
            categoryDepartment={categoryDepartment}
            setSelectedCompany={setSelectedCompany}
            setSelectedDepartment={setSelectedDepartment}
            handleChangeCompany={handleChangeCompany}
            departmentsOptions={departmentsOptions}
            setIdCompany={setIdCompany}
            isFilterTask={isFilterTask}
            allTasks={allTasks}
            singleTaskSelected={singleTaskSelected}
            setSingleTaskSelected={setSingleTaskSelected}
            setIsFilterTask={setIsFilterTask}
            handleFavorite={handleFavorite}
            taskFavorite={taskFavorite}
            setResetDepartment={setResetDepartment}
            resetDepartment={resetDepartment}
            allCatalog={allCatalog}
            tasksFavorites={tasksFavorites}
            setOpenMenu={setOpenMenu}
            generalParams={generalParams}
          />
        ) : (
          <Dashboard
              selectedSubcategory={selectedSubcategory}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSelectedSubcategory={setSelectedSubcategory}
              handleChange={handleChange}
              resetCatalog={resetCatalog}
              setResetCatalog={setResetCatalog}
              generalParams={generalParams}
              language={language}  
          />
        )}
      </div>
    </CommonPage>
  );
});
