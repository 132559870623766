import React, {useState, Fragment, useEffect, useReducer, useCallback} from 'react';
import './index.css';
import Constants from '@data/Constants';
/*-----------------------------common--------------------------*/
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import BarBlue from '@images/BarBlue.png';
import AppBar from '@common/AppBar2';
/*--------------------------material-ui--------------------------*/
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/*--------------------------pages--------------------------*/
import Block_1 from './Blocks/Block_1';
import Block_3 from './Blocks/Block_3';
import Block_4 from './Blocks/Block_4';
import Store from '@data/Store';

const { language } = Store;

export default function({
  open, 
  onClose,
  values,
  options,
  execute,
  APIEndpoints,
  onUpdate
}){
  /*-----------------------------------states------------------------------------------------*/
  
  let [form, setForm] = useReducer((state, v)=> [...state, v], []);
  let [formValues, setFormValues] = useState({});
  let [formPrimary, setFormPrimary] = useState(null);
  /*-----------------------------------useEffects------------------------------------------------*/
  useEffect(()=>{
      setFormValues(values||{});
      if(values){
         onChangeField({name:'fk_id_contract'}, values.fk_id_contract);
      }
  }, [values]);

  /*-----------------------------------functions------------------------------------------------*/
  
   const onSubmit = (v) => {
      v.isTemplate = true;
      v.template = true;
      if(v.id_project){
         execute('put', Constants.APIRoot + '/api/project', 'projects', v, 'id_project', {btn_loading:'saveProject'}).then(e => {onClose(); onUpdate()});
      }else{
         execute('post', Constants.APIRoot + '/api/create/project', 'projects', v, null, {btn_loading:'saveProject'}).then(e => onClose());
      } 
   };

   const onChangeField = (f, v) => {
      setFormValues(state => ({...state, [f.name]:v}));
      if(f.name=='fk_id_contract'){
         execute('post', APIEndpoints.REQUESTS + '/api/ticketchange', 'tickets', { id_contract: v }, null, null, false);
      }
   };
  
  return(
    <Dialog
      PaperProps={{
        id: 'DialogProjectForm'
      }}
      open={open}
      onClose={onClose}
      contentTitle={{
        style:{width:'100%'}
        }}
        titleContentProps={{
        style:{
          width:'calc(100% - 60px)'
        }
        }}
      title={<div className="w-100 bar" style={{ zoom: 0.8 }} zoom={0.8}>
      <CommonForm
      individual
        onRef={r => setForm(r)}
        values={{
          name:formValues.name
        }}
        alert={false}
        fields={[
          {
            type: 'text',
            name: 'name',
            label: language.PROJECT_NAME,
            className: 'subject-ticket',
            required:true
          }
        ]}
        onRef={(r) => setForm(r)}
        onChangeField={(f, v) => {
           formPrimary.handleValue(v, f.name)
        }}
      /></div>}
      maxWidth='lg'
      contentProps={{
        style:{
          background:'white'
        }
      }}
    >
    	<CommonForm
    		childFields={[
                ...form.flatMap(e => e.getFields().map(e=>({ name:e.name, type:e.type, required:e.required})))
         ]}
         values={values}
         onChangeField={onChangeField}
         _onSubmit={() => {
            form.forEach(e=>{
               e.submit();
            })
         }}
         loading='saveProject'
         onSubmit={onSubmit}
         onRef={r => setFormPrimary(r)}
    		fields={[
    			{
    				/*########################################-Informações Báscias-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
    					<Block_1
                     options={options}
                     values={values}
                     setForm={setForm}
                     h={h}
                     formValues={formValues}
                  />
    				),
    			},
    			
    			{
    				/*########################################-Período-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
                  <Block_3
                     setForm={setForm}
                     values={values}
                     h={h}
                  />
    				),
    			},
    			{
    				/*########################################-Evidências-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
                  <Block_4
                     setForm={setForm}
                     values={values}
                     h={h}
                  />
    				)
    			}
          ]}
          
    	/>
    </Dialog>
  )
}