import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card } from '@material-ui/core';
import FaListUl from 'react-icons/lib/fa/list-ul';
import { Container, Row, Col } from 'react-grid-system';

import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import Button from '../../common/Button';
import DialogIcon from '../../common/DialogIcons';
import Icon from '@material-ui/core/Icon';
import Dialog from '@common/Dialog';
import { Constants } from '../../data';
@observer
export default class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconType: props?.values?.icon_url && props?.values?.icon_url !== "null" && props?.values?.icon_url !== "" ? 'image' : 'icon',
      iconValue: '',
      openModal: false,
    };

    console.log(this.state.iconType);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleIconChange = this.handleIconChange.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
  }

  getCatType = () => {
    let { CatType, values } = this.props;
    if (this.props.values != undefined) {
      return CatType.map((e, i) => {
        if (e.id_category == values.id_category) {
          return e.type_ticket;
        }
      }).filter(Boolean);
    } else {
      return [];
    }
  };

  onFormSubmit = (values) => {
    values.icon_type = this.state.iconType;
    values.icon =
      this.state.iconType == 'icon'
        ? this.state.iconValue || values.icon
        : null;
    values.icon_url =
      this.state.iconType == 'image'
        ? this.state.iconValue || values.icon_url
        : null;
    this.props.onFormSubmit(values);
  };

  handleIconChange = (iconData) => {
    this.setState({
      iconType: iconData.type,
      iconValue: iconData.iconString,
      openModal: false,
    });
  };

  handleToggleModal = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    let { language, values, tickets_types } = this.props;

    let ticket_types = tickets_types
      .filter((v) => v.description != 'Liberation')
      .map((d) => ({
        value: d.item_table,
        label: language.TICKET_TYPES[d.description.toUpperCase()],
      }));

    let fields = [
      {
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
      },
      {
        name: 'types_tickets',
        type: 'listCheckbox',
        options: ticket_types,
        label: language.TICKET_TYPE_LABEL,
        required: true,
      },
      {
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        multiLine: true,
      },
      {
        col: 3,
        name: 'icon',
        label: 'Ícone',
        type: 'element',
        content: (
          <div className='tw-flex tw-items-center tw-gap-2'>
            <Button
              variant="normal"
              color={'success'}
              loading={false}
              disabled={false}
              onClick={() => {
                this.handleToggleModal(true);
              }}
              label={language.CHOOSE_ICON}
            />
            <div className="tw-p-2 tw-border tw-rounded tw-text-center">
              {this.state.iconType === 'image' ? (
                <img
                  className="tw-h-10"
                  src={`${Constants.BASE_URL_IMAGE}${this.state.iconValue || values?.icon_url}`}
                />
              ) : (
                <Icon>{this.state.iconValue || values?.icon}</Icon>
              )}
            </div>
          </div>
        ),
      },
    ];

    let button = {
      label: language.SAVE,
    };

    let array_values = {};
    if (this.props.values != undefined) {
      array_values = {
        id_category: values.id_category,
        name: values.name,
        description: values.description,
        types_tickets: this.getCatType(),
        icon: values.icon,
      };
    }

    return (
      <div>
        <CommonForm
          fields={fields}
          button={button}
          onSubmit={this.onFormSubmit}
          values={array_values}
          loading="post-put-category"
          // onChangeField={onChangeField}
        />
        {this.state.openModal && (
          <Dialog
            open={true}
            maxWidth="lg"
            title={language.CHOOSE_ICON}
            onClose={() => this.handleToggleModal(false)}
          >
            <DialogIcon onChange={this.handleIconChange} />
          </Dialog>
        )}
      </div>
    );
  }
}
