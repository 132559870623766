import React, {Fragment, useEffect, useState} from 'react';
import {Menu, MenuItem} from '@material-ui/core';
import moment from 'moment';

export default function({
  onChange=()=>null,
  value=parseInt(moment().format('YYYY')),
  disabled=false
}){
  function giveMarginLeftYear(res) {
    parseInt(res)
    switch(true){      
      case res <=600:
        return 535
      case res <= 900:
        return 90
      case res <= 1024:
        return -110
      case res <= 1280:
        return -223
      case res <= 1366:
        return -215
      case res <= 1920:
        return -290
      case res <= 2048:
        return -300
      case res <= 4096:
        return -100
      default:
        return -50
      
    }
  }
  function giveMarginTopYear(res) {
    parseInt(res)
    switch(true){
      case res <=350:
        return 80
      case res <= 380:
        return 20
      case res <= 800:
        return 25
      case res <= 1280:
        return 25
      case res <= 1920:
        return 20
      default:
        return 10
    }
  }
  let [yearSelected, setYearSelected] = useState('');
  let [anchorEl, setAnchorEl] = useState(null);
  let defaultValue = parseInt(moment().format('YYYY'));
  let years = [defaultValue - 3, defaultValue - 2, defaultValue - 1, defaultValue]

  useEffect(() => {
    if (value) {
        setYearSelected(value);
    }
  }, [value])

  //console.log('carregando month', monthSelected);

  return(
    <Fragment>
      <div className='d-flex align-items-center'>
        <h3
          aria-owns={anchorEl ? 'long-menu' : undefined}
          onClick={evt => setAnchorEl(evt.currentTarget)}
          className='label-month-filter px-3'
          style={{width:90, textAlign:'center'}}
        >
          { yearSelected ? 
            yearSelected :
            moment().format('YYYY')
          }
        </h3>
      </div>
      <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={!!anchorEl && !disabled}
          onClose={()=> setAnchorEl(null)}
          style={{marginLeft:giveMarginLeftYear(window.screen.width),marginTop:giveMarginTopYear(window.screen.height)}}
          
        >
          {years.map((option) => (
            <MenuItem 
              key={option} 
              onClick={()=>{
                setAnchorEl(null);
                onChange(option)
                setYearSelected(option)
              }}
              style={yearSelected==option?{fontWeight:'bold'}:{}}
            >
              {option}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  )
}