import React, { createContext } from 'react';
import { CtxVariables } from './Values';

export const SrContextApi = createContext(null);

const SrContext = (props) => {
  const { variables } = CtxVariables();

  return (
    <SrContextApi.Provider value={variables}>
      {props.children}
    </SrContextApi.Provider>
  );
};

export default SrContext;
