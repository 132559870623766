import React, {useState, useEffect, Fragment } from 'react';
import CommonTable from '@common/CommonTable'
import CommonForm from '@common/CommonForm2'
import Dialog from '@common/Dialog'
import Button from '@common/Button';

const GroupChangeConfigurationList = ({
    allGroupsManagement,
    totalGroupsManagement,
    callPageSelectFilter,
    Constants,
    action,
    handlePage,
    language,
    cleanFilters,
    sortBy
}) => {
    return (
      <Fragment>
          <CommonTable
          paginationTop={false}
          countTotal={totalGroupsManagement}
          loadSearch={true}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          title={''}
          beforeBar={
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              color="warning"
              variant="circle"
              className="mr-3"
              onClick={() => handlePage('form')}
            />
          }
          columns={[
            {
              key: 'id_rel_group_management_change',
              label: 'ID',
            },
            {
              key: 'company_name',
              label: language.COMPANY,
            },
            {
              key: 'group_name',
              label: language.GROUP,
            },
            {
                key: 'global',
              label: language.GLOBAL,
              },
              
            {
              key: 'actions',
              label: language.ACTIONS,
            },
          ]}
          index="ChangeManagementList"
          data={allGroupsManagement}
          
          //showHeaderToolbar={false}
          orderColumn
          searchColumn={true}
          searchColumnEvent={(v) => callPageSelectFilter(0, v, 75)}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          rowsPerPageOptions={[75]}
          onClearAllFilter={() => cleanFilters()}
          onSortItem={(item, sortByStatus) => {
            sortBy(item, sortByStatus)
           }}
        />
      </Fragment>
    );
  
}

export default GroupChangeConfigurationList