import { AppBar, Box, Tab, Tabs, makeStyles } from '@material-ui/core';
import { LocationOn, Security, Settings, VpnKey } from '@material-ui/icons';
import React, { useState } from 'react';
import { Store } from '../../data';
import { ServicePortalAccountData } from './components/ServicePortalAccountData';
import { ServicePortalAccountLocation } from './components/ServicePortalAccountLocation';
import { ServicePortalAccountPassword } from './components/ServicePortalAccountPassword';
import { ServicePortalAccountProtect } from './components/ServicePortalAccountProtect';
import { parseJwt } from './helpers';

const useStyles = makeStyles((theme) => ({
  appBarConfigRoot: {
    boxShadow: 'none',
    maxWidth: 200,
    borderRight: `1px solid ${theme.palette.divider}`,
    marginTop: 24,
  },
  appBarConfigTabIndicator: {
    backgroundColor: 'var(--primary-customer)',
  },
  appBarConfigTab: {
    fontSize: 12,
    minHeight: 'unset',
    paddingLeft: 0,
    textAlign: 'left',
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      gap: '0.3rem',
      fontSize: 13,
      justifyContent: 'start',
      '& .MuiSvgIcon-root': {
        marginBottom: 0,
      },
    },
    '&.Mui-selected': {
      color: 'var(--primary-customer)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{ width: 460 }}
      {...other}
    >
      {value === index && <Box className="tw-px-4">{children}</Box>}
    </div>
  );
}

export const ServicePortalAccountSettings = () => {
  const { language } = Store;
  const styles = useStyles();
  const tokenData = parseJwt(sessionStorage.getItem('token'));
  const userData = tokenData?.iss;

  const TABS = {
    GENERAL_DATA: {
      ID: 'GENERAL_DATA',
      label: language.GENERAL_DATE,
    },
    LOCATION: {
      ID: 'LOCATION',
      label: language.LOCATION,
    },
    CHANGE_PASSWORD: {
      ID: 'CHANGE_PASSWORD',
      label: language.PASSWORD,
    },
    PROTECT_YOUR_ACCOUNT: {
      ID: 'PROTECT_YOUR_ACCOUNT',
      label: language.RECOVERY_PASSWORD.PROTECT_ACCOUNT,
    },
  };

  const [tabActive, setTabActive] = useState(TABS.GENERAL_DATA.ID);

  return (
    <div>
      <div className="tw-flex tw-flex-grow" style={{ minHeight: 480 }}>
        <AppBar
          position="static"
          color="inherit"
          className={styles.appBarConfigRoot}
        >
          <Tabs
            value={tabActive}
            onChange={(event, tabSelected) => setTabActive(tabSelected)}
            variant="scrollable"
            orientation="vertical"
            scrollButtons="auto"
            classes={{
              indicator: styles.appBarConfigTabIndicator,
              flexContainerVertical: 'tw-gap-4'
            }}
          >
            <Tab
              value={TABS.GENERAL_DATA.ID}
              label={TABS.GENERAL_DATA.label}
              icon={<Settings fontSize="small" />}
              disableRipple={true}
              className={styles.appBarConfigTab}
            />
            <Tab
              value={TABS.LOCATION.ID}
              label={TABS.LOCATION.label}
              icon={<LocationOn fontSize="small" />}
              disableRipple={true}
              className={styles.appBarConfigTab}
            />
            <Tab
              value={TABS.CHANGE_PASSWORD.ID}
              label={TABS.CHANGE_PASSWORD.label}
              icon={<VpnKey fontSize="small" />}
              disableRipple={true}
              className={styles.appBarConfigTab}
            />
            <Tab
              value={TABS.PROTECT_YOUR_ACCOUNT.ID}
              label={TABS.PROTECT_YOUR_ACCOUNT.label}
              icon={<Security fontSize="small" />}
              disableRipple={true}
              className={styles.appBarConfigTab}
            />
          </Tabs>
        </AppBar>

        <TabPanel
          value={tabActive}
          index={TABS.GENERAL_DATA.ID}
          className="pb-0 tw-mx-auto"
        >
          <ServicePortalAccountData language={language} />
        </TabPanel>
        <TabPanel
          value={tabActive}
          index={TABS.LOCATION.ID}
          className="pb-0 tw-mx-auto"
        >
          <ServicePortalAccountLocation
            language={language}
            userData={userData}
          />
        </TabPanel>
        <TabPanel
          value={tabActive}
          index={TABS.CHANGE_PASSWORD.ID}
          className="pb-0 tw-mx-auto"
        >
          <ServicePortalAccountPassword language={language} />
        </TabPanel>
        <TabPanel
          value={tabActive}
          index={TABS.PROTECT_YOUR_ACCOUNT.ID}
          className="pb-0 tw-mx-auto"
        >
          <ServicePortalAccountProtect language={language} />
        </TabPanel>
      </div>
    </div>
  );
};
