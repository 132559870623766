import React, { Component } from 'react';
import CommonPage from '../../common/CommonPage';
import { Row, Col } from 'react-grid-system';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import {Card} from '@material-ui/core';
import vendors from '../../util/vendors';
import RaisedButton from '../../common/RaisedButton';
import './DownloadAgent.css';
import Animate from '@common/Animate'
export default class DownloadAgent extends CommonPage {
  render() {
    let { language } = this.store;
    return (
      <CommonPage
        title={
          <div
            className="logoWhite"
            onClick={() => this.redirect('/dash')}
          />
        }
        showMenuIconButton={true}
        iconAlert={true}
      >
        <Animate>
        <PageLayout
          icon={<div className="agentIcon titleIconSize" />}
          title={language.AGENT}
          subTitle={language.AVAILABILITY_MANAGEMENT+'/'}
          rightElements={[]}
        >
          <div style={{marginTop: '-1rem'}}>
          <Row>
            {/*<CardItemAgent
              styles="iconAgentSize pointer"
              icon="Red Hat"
              server="Red Hat Enterprise Linux 5"
              link={'./agents/rhel5/sensr_agent_rhel5'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="Red Hat"
              server="Red Hat Enterprise Linux 6"
              link={'./agents/rhel6/sensr_agent_rhel6'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="Red Hat"
              server="Red Hat Enterprise Linux 7"
              link={'./agents/rhel7/sensr_agent_rhel7'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="Ubuntu"
              server="Ubuntu 14 LTS+"
              link={'./agents/ubuntu14/sensr_agent_ubuntu14'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="CentOS"
              server="CentOS 5"
              link={'./agents/centos5/sensr_agent_centos5'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="CentOS"
              server="CentOS 6"
              link={'./agents/centos6/sensr_agent_centos6'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="CentOS"
              server="CentOS 7"
              link={'./agents/centos7/sensr_agent_centos7'}
            />
            <CardItemAgent
              styles="iconAgentSizeSuSe pointer"
              icon="SuSE"
              server="SUSE Linux Enterprise Server (SLES)"
              link={'./agents/suse12/sensr_agent_suse12'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="Inventory"
              server={language.INVENTORY}
              link={'./agents/inventory/inventory.rar'}
            /> */}
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="Microsoft"
              server="Microsoft Windows"
              link={'./agents/windows/agent.exe'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="linux"
              server={'Linux'}
              link={'./agents/linux/sensr_agent'}
            />
            <CardItemAgent
              styles="iconAgentSize pointer"
              icon="mac"
              server={'Mac'}
              link={'./agents/macos/sensr_agent-macos'}
            />
          </Row>
          </div>
        </PageLayout>
        </Animate>
      </CommonPage>
    );
  }
}

class CardItemAgent extends Component {
  render() {
    let {icon, link, server} = this.props;
    return (
      <Col md={3} className="itemAgent">
        <Card className="DownloadAgent" onClick={()=>window.open(link, '_blank')}>
          <div className="icon">
            {vendors.getIcon(icon)}
          </div>
          <span target="_blank" href={link}>{server}</span>
          
        </Card>
      </Col>
    );
  }
}
