import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import {

  Grid


} from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
var CryptoJS = require('crypto-js')
const crypto = require('crypto');
export default function ({

  onClose,
  store,
  action,
  APIEndpoints,
  values,
  closeContract

}) {


  const decryp = (aMsg = '') => {
    try {
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, "");
      let decipher, tRet;
      decipher = crypto.createDecipher("aes-256-cbc", aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
      tRet += decipher.final("utf8");
      return tRet;
    } catch (e) {
      return {}
    }

  }

  let [users, setUsers] = useState([]);
  let [users_labels, setUsersLabels] = useState([]);
  let [usersOptions, setUsersOptions] = useState([]);
  let [open, setOpen] = useState(false);
  const onSubmit = (v, values) => {

    values.items = v.fk_id_user
    values.update = 'user';
    action.execute('post', APIEndpoints.SOLUTION + '/association/', '', values).then(c => {

      setCompanies({ fk_id_user: c.data.map(b => b.fk_id_user) })

    })
    closeContract()
    // /api/user/upcontract
  }

  useEffect(() => {
    //let array = JSON.parse(decryp(sessionStorage.getItem('users')));
   

    

    //setContractsOptions(array.map(c => ({ value: c.id_user, label: c.name })))

    action.execute('post', APIEndpoints.SOLUTION + '/association/', '', {
      type: 'user', id_solution: values.id_solution
    }).then(c => {
      setUsers(c.data.map(b => b.fk_id_user))
      setUsersLabels(c.data.map(b => b.name))

    }) ///api/company/user/:id

  }, [values])

  return (
    <Dialog
      PaperProps={{
        id: ''
      }} title={
        'Associar Usuário '
      }
      open={true}
      onClose={closeContract}
    >

      <CommonForm
         
        fields={[

           
          {
            type: 'autocomplete',
            name: 'fk_id_user',
            label: '',
            textlabel: '',
            method: 'POST',
            route: `${APIEndpoints.USER}/filter/notsolicitant`,
            routeGetCount: `${APIEndpoints.USER}/active/count/${0}`,
            fieldquery: 'search',
            textinit:  users_labels.length > 0 ?  users_labels : '',
            fieldvaluedb: 'id_user',
            fieldlabeldb: 'name',
            idinit:  users.length >0 ?users : 0 ,
            routeAll: `${APIEndpoints.USER}/filter/notsolicitant?`,
             methodGetAll: 'POST',
            required: true,
            showEmail: true,
            isMulti: true
          }
        ]}
        onSubmit={v => onSubmit(v, values)}
      />
    </Dialog>

  );
}
