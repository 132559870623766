import React from 'react';
// ------------------------------common-----------------------------------------
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import CommonPage from '@common/CommonPage';
import Store from '@data/Store';

// ------------------------------material-ui-------------------------------------
// ------------------------------components-------------------------------------
import Avatares from './Avatares';

const language = Store.language;

export default function ({ data = [], edit }) {
	return (

		<CommonTable
			bar={false}
			className='table-primary'
			pagination={false}
			col={[
				{ key: '', label: language.ACTIONS },
				{ key: 'id', label: language.ID },
				{ key: 'activity', label: language.ACTIVITY },
				{ key: 'sponsors', label: language.RESPONSIBLES },
				{ key: 'evolution', label: language.EVOLUTION },
				{ key : 'dt_end_realized', label : language.EVOLUTION_DATE },
				{ key: 'status', label: language.STATE },
				{ key: 'dt_i', label: language.INITIAL_DATE },
				{ key: 'dt_f', label: language.FINAL_DATE },

			]}
			data={data.map(e => ({
				ConfigCell:{
					__order_id: e.id,
					__order_activity: e.activity,
					__order_evolution: e.evolution,
					__order_status: e.status,
					__order_dt_i: e.dt_i,
					__order_dt_f: e.dt_f
				},
				actions: (
					<MenuTooltip
					onChange={v => {
						if(v.value==1){
							edit(e)
						}
					 }}
		  					options={[
							{ value: 1, label: language.EDIT, icon: 'fas fa-pencil-alt', color: 'var(--primary)' },
							{ value: 2, label: language.DELETE, icon: 'fas fa-trash-alt', color: 'var(--danger)' },
						]}
					/>
				),
				id: e.id,
				activity: e.activity,
				sponsors: (
					<Avatares
						data={e.sponsors}
					/>
				),
				evolution: (

					<div className='d-flex align-items-center content-progress'>
						<Progress current={parseInt(e.evolution)} hiddenText className='w-100' background='var(--warning)' />
						<span className='ml-2'>{parseInt(e.evolution)}%</span>
					</div>

				),
				dt_end_realized : e.dt_end_realized,
				status: (
					<div className='status'>
						{e.status}
					</div>
				),
				dt_i: e.dt_i,
				dt_f: e.dt_f
			}))}
		/>
	)
}
