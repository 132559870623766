import Actions from '@data/Actions';
import Constants from '@data/Constants';

// ----------------------------------------------------------------------

export const getSquadsService = async () => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute('post', `${APIEndpoints}/business-line/list`, '');
  const normalizedSquads =
    data.map((squad) => {
      const { id_business_line, label } = squad;
      return { id_business_line, label };
    }) || [];

  return normalizedSquads;
};

export const getSquadByIdService = async ({ id }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute(
    'get',
    `${APIEndpoints}/business-line/${id}`,
    ''
  );

  return data;
};

export const deleteSquadByIdService = async ({ id }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'delete',
    `${APIEndpoints}/business-line/${id}`,
    ''
  );

  return result;
};

export const addSquadService = async ({ values }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;

  const normalizedValues = {
    label: values.label,
  };

  const result = await action.execute(
    'post',
    `${APIEndpoints}/business-line`,
    '',
    normalizedValues
  );

  return result;
};

export const editSquadService = async ({ values }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'put',
    `${APIEndpoints}/business-line`,
    '',
    values
  );

  return result;
};
