import React, { Fragment, useState, useEffect } from 'react';
import './index.css';
/*------------------------common-----------------------------*/
import Button from '@common/Button';
import PieChart from '@common/PieChart';
/*------------------------icons-----------------------------*/
import RouterIcon from '@material-ui/icons/Router';
import UsbIcon from '@material-ui/icons/Usb';
import StorageIcon from '@material-ui/icons/Storage';
import WifiIcon from '@material-ui/icons/Wifi';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import Status from './Status'
import MemoryIcon from '@material-ui/icons/Memory';
/*----------------------material-ui-------------------------------*/
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
/*----------------------páginas-------------------------------*/
import AgentCard from './AgentCard';
import SNMPCard from './SNMPCard';
import Traffic_Temperature_Graph from './Traffic_Temperature_Graph';
import MonitorUtils from '../../../util/MonitorUtils';
import classnames from 'classnames';
import { setISODay } from 'date-fns';
import { Tooltip } from 'react-tippy';

import Constants from '@data/Constants';

function PieChartIndicator({ icon, percent = 0, triggers = {} }) {
  const [danger_, setDanger] = useState({
    value: 0
  });
  const [warning_, setWarning] = useState({
    value: 0
  });

  

  useEffect(() => {
    let { danger, warning } = triggers;
      if (!danger) {
      
      } else {
        setDanger(danger);
      }
      if (!warning) {
        
      } else {
        setWarning(warning)
      }
      
  }, [triggers, percent])

  var compIcon = [
    <MemoryIcon
      className="PieChartIndicator-icon mr-1"
      style={{ fontSize: 19 }}
    />,
    <i
      className="fas fa-memory PieChartIndicator-icon mr-1"
      style={{ fontSize: 13 }}
    />,
    <i
      className="fas fa-save PieChartIndicator-icon mr-1"
      style={{ fontSize: 13 }}
    />,
  ];
   
   
  return (
    <div className="d-flex align-items-center mr-2">
      {compIcon[icon]}
      <div className="PieChartIndicator-graph">
        <PieChart
          percent={percent}
          color={
            ((percent >= danger_.value) && (danger_.value > 0))
              ? 'danger'
              : ((percent < danger_.value) && (percent >= warning_.value) && (warning_.value > 0))
              ? 'warning'
              : 'success'
          }
          strokeWidth={3.8}
        />
      </div>
    </div>
  );
}
export default function ({
  language,
  setAnchorEl,
  anchorEl,
  device,
  ioServers,
  monitor,
  setId,
  setDevice,
}) {
 
  const [deviceItem, setDeviceItem] = useState({});
  const [_device, _setDevice] = useState({});
  const [online, setOnline] = useState(false);
  const [cpuUsedDash, setCpuUsedDash] = useState(0);
  const [memory, setMemory] = useState(0);
  const [memoryUsed, setMemoryUsed] = useState(0);
  const [memoryUsedDash, setMemoryUsedDash] = useState(0);
  const [disk, setDisk] = useState(0);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [uptime, setUptime] = useState('');
  const [netStatsTx, setNetStatsTx] = useState('');
  const [netStats, setNetStats] = useState('');
  const [open, setOpen] = useState(false);
  const [triggers, setTriggers] = useState({});
  

  String.prototype.toHHMMSS = function () {
    var cur = parseInt(this, 10); // don't forget the second param

    let days = Math.floor(cur / 86400);
    cur -= days * 86400;
    let hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    let minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    let seconds = Math.floor(cur) % 60;

    var time =
      days.toString().padStart(2, '0') +
      ' Dias ' +
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0');

    return time;
  };

  useEffect(() => {
    setDeviceItem({});
    function load() {
      return new Promise((resolve, reject) => {
        let getDevice = monitor.find((m) => m.id_device === device.id_device);
        if (getDevice) {
          resolve(getDevice);
        }
      });
    }
    if (_device) {
      load().then((x) => {
       // SetGetDevice(x)
        const cpuTriggers = x.conf['cpu-stats'].triggers;
        const memoryTriggers = x.conf['mem-info'].triggers;
        const fsTriggers = x.conf['fs-list'].triggers;
        _setDevice({...x,...device});
        const data = {
          cpuTriggers,
          memoryTriggers,
          fsTriggers,
        };
  
        setTriggers(data);
  
        setDeviceItem(x);
      })

    }
  }, [device,monitor]);

  useEffect(() => {
    if (device) {
      _setDevice(device);
      /*setOnline(false);
      setCpuUsedDash(0);
      setMemory(0);
      setMemoryUsed(0);
      setMemoryUsedDash(0);
      setDisk(0);
      setUptime('');
      setNetStatsTx('');
      setNetStats('');
      setTriggers({}) */
    }

  }, [device]);

  useEffect(() => {

    if (_device.id_device === device.id_device) {

      const tenant = Constants.Tenant;

      try {
        ioServers.on(`_${tenant}_${_device.id_device}`, (data) => {
          if (!data.offline) {
            setOnline(true);
            if (data.key == 'process') {
              
              setCpuUsedDash( data.values.cpu.usedPercent);
              setMemoryUsedDash(data.values.memory.usedPercent)
              try {
                setDisk(data.values.fs) 

              } catch (error) {
                setDisk(0);
              }
            
                let netStatsTemp = data.values.network;
                try {
                  let netStatsTxTemp = netStatsTemp[0].values.TX
                  //let netStatsRxTemp = netStatsTemp[0].out[0];
                  setNetStatsTx(netStatsTxTemp);
                  setNetStats(netStatsTemp);
                } catch (e) {}
            
               
                  let uptimeTemp  = data.values.uptime;
                  var uptimes = (uptimeTemp + '').toHHMMSS();
                  setUptime(uptimes);
                

            }
  
            
          
           
          } else {
            setOnline(false);
            setCpuUsedDash(0);
            setMemory(0);
            setMemoryUsed(0);
            setMemoryUsedDash(0);
            setDisk(0);
            setUptime('');
            setNetStatsTx('');
            setNetStats('');
          }
  
        });

       ioServers.emit('lastmonitor',`_${tenant}_${_device.id_device}`)
        


      } catch (e) {}

      return () => ioServers.off(`_${tenant}_${_device.id_device}`)
    } 
  }, [_device, device]);

  useEffect(() => {
    let value = {};
    
    
    const diskValue = disk.length > 0
    ?  
        parseInt(disk[0].use)
       
       
    : 0
    if (cpuUsedDash || memoryUsedDash || diskValue) {

      if (triggers.memoryTriggers !== undefined 
          || triggers.memoryTriggers !== undefined
          || triggers.fsTriggers !== undefined) {

          const cpuDanger = triggers.cpuTriggers.danger.value || 0;
          const memoryDanger = triggers.memoryTriggers.danger.value || 0;
          const fsDanger = triggers.fsTriggers.danger.value || 0
    
          const cpuWarning = triggers.cpuTriggers.warning.value || 0;
          const memoryWarning = triggers.memoryTriggers.warning.value || 0;
          const fsWarning = triggers.fsTriggers.warning.value || 0;
          //check color
          

          if (cpuUsedDash >= cpuDanger) {
            value.color = 'danger';
          } else if (memoryUsedDash >= memoryDanger) {
            value.color = 'danger'
          } else if (diskValue >= fsDanger) {
            value.color = 'danger'
          } else if (cpuUsedDash < cpuDanger &&
            cpuUsedDash >= cpuWarning) {
            value.color = 'warning'
          } else if (memoryUsedDash < memoryDanger &&
            memoryUsedDash >= memoryWarning) {
            value.color = 'warning'
          } else if (diskValue < fsDanger &&
            diskValue >= fsWarning) {
            value.color = 'warning'
          } else if(_device.type === 'link') {
            value.color = 'success'
          } else {
            value.color = 'success'
          } 
    
          value.id = _device.id_device;
          value.type = _device.type

          //salvando na lista 
          /*setMonitorColors(monitorColors => 
            [value, ...monitorColors.filter(x => x.id !== _device.id_device)]);*/
        }
    }

    return () => value = {}
  }, [cpuUsedDash, memoryUsedDash, disk, _device, triggers ])
  
  let comp ='';
  try{
    
    comp=  <Tooltip
    html={
      <div style={{ color: '#fff', fontSize: 11 }}>
          {_device.companyname}
      </div>
    }
    position="right"
    interactive
    arrow
  >
    <span className="-mb-2 ellipsis _title">
    {_device.companyname.length >8 ?_device.companyname.substr(0,7)+'...':_device.companyname}
    </span>
  </Tooltip>
  }catch(e){

  }
  return (
    <Fragment>
      <div className="Item d-flex align-items-center justify-content-between content-min">
        <div className="d-flex align-items-center">
          <Button
            icon={
              open && online ? 'fas fa-chevron-down' : 'fas fa-chevron-right'
            }
            color="#CFC7C7"
            variant="circle"
            outlined
            onClick={() => setOpen((open) => !open)}
            style={{
              color: '#CFC7C7',
              border: 'none',
            }}
            disabled={!online}
          />

          {_device.type === 'server' && (
            <StorageIcon className="indicator-icon ml-1 mr-3" />
          )}
          {_device.type === 'link' && (
            <UsbIcon className="indicator-icon ml-1 mr-3" />
          )}
          {_device.type === 'desktop' && (
            <DesktopMacIcon className="indicator-icon ml-1 mr-3" />
          )}
          <div className="title-description">
           
           <div style={{display:'flex'}}>
           
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 11 }}>
                    {_device.name}
                </div>
              }
              position="right"
              interactive
              arrow
            >
              <span className="-mb-2 ellipsis _title">
              {_device.name}
              </span>
            </Tooltip>
            <i style={{marginLeft:'10px', color:'#f0b002',
                fontSize:'11px', marginRight:'1px',
                paddingTop:'3px',
                color: 'rgb(240, 176, 2)' 
          
    
          
          }} class="far fa-building"></i>
            {comp}  
            </div>
            <span className='_status'>
             <Status device={_device} ioServers={ioServers}></Status>
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center PieChartIndicator-content">
            <PieChartIndicator
              icon={0}
              percent={parseInt(cpuUsedDash)}
              triggers={triggers.cpuTriggers}
            />
            <PieChartIndicator
              icon={1}
              percent={parseInt(memoryUsedDash)}
              triggers={triggers.memoryTriggers}
            />
            <PieChartIndicator
              icon={2}
              percent={
                disk.length > 0?
                   
                 
                      isNaN(parseInt(disk[0].use))==true?0:(parseInt(disk[0].use))
                      :0
                  
              }
              triggers={triggers.fsTriggers}
            />
          </div>
          <IconButton
            aria-label="More"
            aria-owns={anchorEl ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={(evt) => {
              setAnchorEl(evt.currentTarget);
              setId(_device.id_device);
              setDevice(_device);
            }}
            style={{ transform: 'rotate(90deg)', padding: 2 }}
            className="mx-1"
          >
            <MoreVertIcon style={{ color: 'var(--success)' }} />
          </IconButton>
          
        </div>
      </div>
      {open && online && (
        <div className="content sub-items-content">
          {deviceItem ? (
            <AgentCard
              _device={deviceItem}
              uptime={uptime}
              netStatsTx={netStatsTx}
              netStats={netStats}
            />
          ) : (
            ''
          )}
          {/*<SNMPCard ioSNMP={ioSNMP} device={deviceItem}/>*/}
          <Traffic_Temperature_Graph
            netStatsTx={netStatsTx}
            netStats={netStats}
          />
        </div>
      )}
    </Fragment>
  );
}
