import React from 'react';
import AppBar from '@common/AppBar2';
import Progress from '@common/Progress';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';

const { language } = Store;


const Item = ({title, date, color})=>(
	<div className='item content-min' style={{backgroundColor:color}}>
		<span>{title}</span>
		<span>
			<i className="fas fa-calendar-day mr-1"/>
			{date}
		</span>
	</div>
)
const Hours = ({label, value, style, onClick=()=>null})=>(
   <Tooltip
      html={
         <div style={{ color: '#fff', fontSize: 11 }}>
            {label}
         </div>
      }
      position="left"
      arrow
   >
      <span className='info-hour-item d-flex align-items-center' style={style} onClick={onClick}>
         <i className='fas fa-clock mr-1'/>
         {value||'00:00'}
      </span>
   </Tooltip>
);
export default function({percent, dt_i, dt_f, planned_hours, realized_hour, balance, exceeded_hours, setOpen, color,pause_project}){
   
	return(
		<div className='EvolutionProject wrap'>
         <AppBar title={language.EVOLU} />
			<div className='content-medium pt-0 -mt-3'>
            <div className='info-hour d-flex align-items-center justify-content-between mb-1'>

               <Hours 
                  label={language.PLANNED_HOURS}
                  value={planned_hours} 
                  onClick={() => setOpen('planned_hours')}
               />
               <Hours 
                  label={language.CONSUMED_HOURS} 
                  value={realized_hour} 
                  onClick={() => setOpen('realized_hour')}
               />
               <Hours 
                  label={language.BALANCE} 
                  value={balance}
               />
               <Hours 
                  label={language.HOURS_EXCEEDED}
                  value={exceeded_hours} style={{color:'var(--danger)'}} 
                  onClick={() => setOpen('exceeded_hours')}
               />

            </div>
				<Progress current={percent} background='var(--danger)'/>
				<div className='d-flex align-items-center mt-2 justify-content-between'>
               <Item title={language.START_OF_THE_PROJECT} date={dt_i}/>
               <Item title={pause_project == true ? language.PAUSED_PROJECT : language.END_OF_PROJECT } color={color} date={dt_f}/>
				</div>
			</div>
		</div>
	)
}