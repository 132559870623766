import { Dialog } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import FaSpinner from 'react-icons/lib/fa/spinner';
//import pass from '../../data/ValidePassword';
import CommonPage from '@common/CommonPage';
import SimplesAlert from '@common/SimplesAlert';
import Store from '@data/Store';
import { createBrowserHistory } from 'history';
import 'react-tippy/dist/tippy.css';
import Constants from '../../data/Constants';
import FormNewUser from './FormNewUser';
import LoginPage from './LoginPage';
import SolicitantPage from './SolicitantPage';


var PRIVATE_IP = '';

//<meta name="" content="1073291132197-bjdr6neqi59u5e91vf5ge72keaa6df3p.apps.googleusercontent.com">

@observer
export default class LoginContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      error: false,
      page: 'login',
      send: false,
      modallogin: false,
      password: null,
      aduser: null,
      block: false,
      files: [],
      msnpass: false,
      fliesnk: [],
      send: [],
      sends: true,
      status_: false,
      sendFile: false,
      datageneral: [],
    };
    this.handlePage = this.handlePage.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onRedefine = this.onRedefine.bind(this);
    this.onForgot = this.onForgot.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onOpenSolicitation = this.onOpenSolicitation.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.msg = '';
  }
  decryp(aMsg) {
    var aSecret = 'Orch3str0!@forever!';
    const crypto = require('crypto');
    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');

    return tRet;
  }
  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      )
    );
  }
  componentWillMount() {
    this.action
      .execute('get', this.APIRoot + '/generalparameters', 'general_parameters')
      .then((g) => {
        this.setState({ datageneral: g.data });
      });

    this.action.execute('post', this.APIRoot + '/getparams', 'params');
  }

  componentDidMount() {
     this.store.publicIp =`10.0.0.1`
  }

  handlePage(page, value) {
    this.setState({
      page: page,
      send: value,
    });
  }

  onFormSubmit(values) {
    values.role = 'solicitant';
    values.url = '';
    values.password = this.state.password;

    this.action
      .execute(
        'post',
        `${this.APIRoot}/usernew`,
        'login',
        values
      )
      .then((res) => {
        this.store.toggleAlert(
          true,
          'Dados Cadatrados com Sucesso , Realize o Login',
          'successes'
        );
      });

    /* this.store.alerts = {
       msg: 'Dados cadastrados com sucesso!',
       type: 'success'
     };
 */
    setTimeout(
      function () {
        this.redirect('/')
      }.bind(this),
      2000
    );
  }

  onChangeField(field, value) {
    if (field === 'username') sessionStorage.setItem('username', value);
    this.state[field] = value;
  }
  onDrop(e) {
    //this.state.files =e.target.files
    var files = this.state.files;

    this.setState({ files: files.concat(e) });
    // setTimeout(function () {this.setState({page:'form',values:''})}.bind(this), 1000);
  }

  removeFile(item) {
    var files = this.state.files.filter((e) => e.name != item.name);

    this.setState({ files });
  }

  onLogin(values) {
    const history = createBrowserHistory();

    let { publicIp } = this.store;
    let token = undefined;
    try {
      let url = new URL(window.location.href);

      token = url.searchParams.get('token').replaceAll(' ', '+');
      //valor = JSON.parse(decrypt(token));
    } catch (e) { }
    if (values.username != undefined) {
      let body = {
        username: values.username,
        password: values.password ?? '',
        auth: values.auth,
        id: values.id,
        privateIP: PRIVATE_IP,
        publicIP: publicIp.ip,
        navigator: this.getNavigator(),
        token: token,
      };

      // execute(method, target, model, obj, primaryKey, msg, alert, callback) {
      // execute(method, target, model, obj, primaryKey, msg, alert=true) {
      this.action
        .execute(
          'post',
          this.APIEndpoints.LOGIN,
          'login',
          body,
          null,
          null,
          false
        )
        .then((b) => {
          if (!this.store.login?.token && this.store.codigo == 'E008') {
            this.setState({ modallogin: false });

            this.handlePage('new', {
              userad: values.username,
              email: values.username,
              company: values.company,
              name: values.name
            });
          } else if (!this.store.login?.token && this.store.codigo == 'E001') {
            this.store.toggleAlert(
              true,
              'Usuário ou senha Inválidos',
              'error'
            );
          } else {
            this.store.toggleAlert(
              true,
              'Login realizado com sucesso',
              'success'
            );




            sessionStorage.setItem('token', b.data.token);
            sessionStorage.setItem(
              'user',
              this.b64EncodeUnicode(JSON.stringify(b.data.user))
            );
            Store.handleLanguages(b.data.user.id_language || 'pt-br');

            if (b.data.user.password_change_required) {
              this.setState({ settings: true, password_recovery_code: b.data.user.password_recovery_code })
            } else {
              const currentUrl = new URL(window.location.href);
              const isReturnUrl = currentUrl.searchParams.get('returnUrl');
              let localRedirect = isReturnUrl
                ? isReturnUrl + currentUrl.hash
                : '';
              if (
                b.data.user?.use_service_portal_only &&
                !localRedirect.startsWith('/dash/service-portal')
              )
                localRedirect = '/dash/service-portal';

              history.push(
                localRedirect ? decodeURI(localRedirect) : b.data.redirect
              );
              history.go();
            }
          }

        })
        .catch((bb) => {
          setTimeout(() => {
            if (this.store.codigo == 'E008') {
              this.setState({ modallogin: false });

              this.handlePage('new', {
                userad: values.username,
                email: values.username,
                company: values.company,
                name: values.name
              });
            } else if (this.store.codigo.toString() == 'E001') {
              this.store.toggleAlert(
                true,
                'Usuário ou senha Inválidos',
                'error'
              );
            }
          }, 1000);
        });
    } else {
      let { username, password } = this.state;

      this.store.codigo = '';
      if (!username && !password) {
        /* this.store.alerts = {
           msg: this.store.language.ERROR_CODE.E000,
           type: 'error'
         };
 */
      } else {
        let body = {
          username: username,
          password: password,
          privateIP: PRIVATE_IP,
          publicIP: publicIp.ip,
          navigator: this.getNavigator(),
          token: token,
        };
        // execute(method, target, model, obj, primaryKey, msg, alert, callback) {
        this.action
          .execute(
            'post',
            this.APIEndpoints.LOGIN,
            'login',
            body,
            null,
            null,
            false
          )
          .then((b) => {
            setTimeout(() => {
              if (this.store.codigo.toString() == 'E008') {
                this.setState({ modallogin: false });

                this.handlePage('new', {
                  userad: username,
                  password: password,
                });
              } else if (this.store.codigo.toString() == 'E001') {
                this.store.toggleAlert(
                  true,
                  'Usuário ou senha Inválidos',
                  'error'
                );
              } else {
                this.store.toggleAlert(
                  true,
                  'Login realizado com sucesso',
                  'success'
                );
                sessionStorage.setItem('token', b.data.token);
                sessionStorage.setItem(
                  'user',
                  this.b64EncodeUnicode(JSON.stringify(b.data.user))
                );

                Store.handleLanguages(b.data.user.id_language || 'pt-br');

                if (b.data.user.password_change_required) {
                  this.setState({
                    settings: true,
                    password_recovery_code: b.data.user.password_recovery_code,
                  });
                } else {
                  const currentUrl = new URL(window.location.href);
                  const isReturnUrl = currentUrl.searchParams.get('returnUrl');
                  let localRedirect = isReturnUrl
                    ? isReturnUrl + currentUrl.hash
                    : '';
                  if (
                    b.data.user?.use_service_portal_only &&
                    !localRedirect.startsWith('/dash/service-portal')
                  )
                    localRedirect = '/dash/service-portal';

                  history.push(
                    localRedirect ? decodeURI(localRedirect) : b.data.redirect
                  );
                  history.go();
                }
              }
            }, 1000);
          })
          .catch((bb) => {
            setTimeout(() => {
              if (this.store.codigo == 'E008') {
                this.setState({ modallogin: false });

                this.handlePage('new', { userad: username, email: username });
              } else if (this.store.codigo.toString() == 'E001') {
                this.store.toggleAlert(
                  true,
                  'Usuário ou senha Inválidos',
                  'error'
                );
              }
            }, 1000);
          });
      }
    }
  }

  onRedefine() {
    let { new_pass, confirm } = this.state;
    let passDiv = true;

    if (passDiv == true) {
      if (!new_pass && !confirm) {
      } else if (new_pass != confirm) {
        /* this.store.alerts = {
           msg: this.store.language.ERROR_CODE.E004,
           type: 'error'
         };*/
      } else {
        let url = new URL(window.location.href);
        let token = url.searchParams.get('t');

        let body = {
          new_pass: new_pass,
          token: token,
        };

        this.action.execute(
          'post',
          this.APIEndpoints.REDEFINE,
          'redefine',
          body
        ).then((res) => {
          this.store.toggleAlert(true, 'Senha alterada com sucesso!', 'success');
          window.location.href = window.location.origin;
        }).catch((error) => {
          this.store.toggleAlert(true, `Ocorreu um erro inexperado ao redefinir a senha. Tente novamente mais tarde.`, 'error');
        });
      }
    } else {
      this.setState({ msnpass: passDiv });
    }
  }

  onForgot() {
    let { email } = this.state;

    if (!email) {
    } else {
      this.action.execute('post', this.APIEndpoints.FORGOT, 'forgot', {
        email: email,
        url: window.location.origin,
      });

      setTimeout(() => {
        if (this.store.codigo.toString() == 'E006') {
          this.store.toggleAlert(
            true,
            'Usuário não foi localizado na base dados como ativo!',
            'error'
          );
        } else {
          this.store.toggleAlert(true, 'Email enviado com sucesso!', 'success');
          this.setState({ page: 'login' });
        }
      }, 2000);
    }
  }

  getNavigator() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  onOpenSolicitation(values) {
    if (this.state.sends) {
      this.setState({ sends: false });

      let filesSend = [];

      this.setState({ block: false });

      let cont = 0;

      if (this.state.files.length > 0) {
        this.setState({ sendFile: true, send: false });
        let total = Array.from(this.state.files).length;
        Array.from(this.state.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 10000000) {
              alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_10MB);
              this.setState({ sendFile: false });
              filesSend = [];
              this.state.files = [];
              cont = 0;
              setTimeout(
                function () {
                  this.handlePage('login', true);
                }.bind(this),
                2000
              );
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              let host = Constants.APIRoot;
              values.file = filesSend;
              values.description = values.description.replace(
                /<(?:.|\n)*?>/gm,
                ''
              );

              this.action
                .execute(
                  'post',
                  `${host}/rall`,
                  'login',
                  values
                )
                .then((results) => {
                  if( results.data ) {
                    results = results.data;
                  }

                  this.setState({ sendFile: false });

                  if (results.ok) {
                    this.store.toggleAlert(
                      true,
                      'Solicitação Aberta com Sucesso!',
                      'success'
                    );
                  } else {
                    this.store.toggleAlert(
                      true,
                      this.store.language.E006,
                      'error'
                    );
                  }

                  setTimeout(
                    function () {
                      this.handlePage('login', true);
                    }.bind(this),
                    2000
                  );
                });
            }
          });
        });
      } else {
        let host = Constants.APIRoot;
        values.description.replace(/<(?:.|\n)*?>/gm, '');

        this.action
          .execute(
            'post',
            `${host}/rall`,
            '',
            values
          )
          .then((results) => {
            if( results.data ) {
              results = results.data;
            }

            if (results.ok == true) {
              this.msg.show(this.store.language.REQUEST_SUCCESS, {
                time: 5000,
                type: 'success',
              });
              setTimeout(
                function () {
                  window.location.reload();
                }.bind(this),
                2000
              );
            } else {
              this.msg.show(this.store.language.E006, {
                time: 5000,
                type: 'error',
              });
              setTimeout(
                function () {
                  window.location.reload();
                }.bind(this),
                2000
              );
            }
          });
      }
    }
  }
  showAlert = () => {
    try {
      this.state.error = true;

      //this.store.toggleAlert(true,this.store.language.E006,'success');

      /*this.msg.show(this.store.alerts, {
        time: 5000,
        type: this.store.alerts.type,
        onClose: () => {
          this.state.error = false;
          this.store.alerts = '';
        }
      });*/
    } catch (e) { }
  };

  render() {
    let itensParams = null;
    try {
      this.store.params.map((g) => {
        itensParams = JSON.parse(this.decryp(g.token.toString()));
        itensParams = itensParams[0];
      });
    } catch (e) { }
    let {
      language,
      publicIp,
      status,
      login,
      logo,
      general_parameters,
      history,
    } = this.store;

    try {
      this.store.params.map((g) => {
        itensParams = JSON.parse(this.decryp(g.token.toString()));
        itensParams = itensParams[0];
      });
    } catch (e) { }

    //    if (!!this.store.alerts && !this.state.error) this.showAlert();

    return (
      <div>
        {this.state.sendFile ? (
          <Dialog
            paperProps={{
              style: { backgroundColor: 'rgba(0, 0 , 0, 0)' },
              zdepth: 0,
            }}
            modal={true}
            open={true}
          >
            <div className="loadGroup">
              <h3>
                <FaSpinner className="loading" />
              </h3>
              <h3>{'Enviado Aguarde'}</h3>
            </div>
          </Dialog>
        ) : null}

        {this.state.page === 'login' ? (
          <LoginPage
            msnpass={this.state.msnpass}
            {...this.state}
            actions={this.actions}
            logo={logo}
            handlePage={this.handlePage}
            store={this.store}
            redirect={this.redirect}
            onChangeField={this.onChangeField}
            onLogin={this.onLogin}
            onRedefine={this.onRedefine}
            onForgot={this.onForgot}
            general_parameters={this.state.datageneral}
            itensParams={itensParams}
          />
        ) : this.state.page === 'new' ? (
          <Fragment>
            <style>
              {
                '\
              body{\
                overflow:visible !important;\
                height:auto !important;\
              }\
            '
              }
            </style>
            <FormNewUser
              companies={this.store.companies}
              departments={this.store.departments}
              modallogin={this.state.modallogin}
              onDrop={this.onDrop}
              values={this.state.send}
              onFormSubmit={this.onFormSubmit}
              files={this.state.files}
              removeFile={this.removeFile}
              handlePage={this.handlePage}
              language={language}
              onOpenSolicitation={this.onOpenSolicitation}
            />
          </Fragment>
        ) : (
          <Fragment>
            <style>
              {
                '\
              body{\
                overflow:visible !important;\
                height:auto !important;\
              }\
            '
              }
            </style>
            <SolicitantPage
              onDrop={this.onDrop}
              onFormSubmit={this.onFormSubmit}
              files={this.state.files}
              removeFile={this.removeFile}
              handlePage={this.handlePage}
              language={language}
              block={this.state.block}
              onOpenSolicitation={this.onOpenSolicitation}
            />
          </Fragment>
        )}
        <Dialog
          paperProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0)' },
            zdepth: 0,
          }}
          actions={false}
          modal={true}
          open={!!this.store.loading}
        >
          <div className="loadGroup">
            <h3>
              <FaSpinner className="loading" />
            </h3>
            <h3>{language.LOAD}</h3>
          </div>
        </Dialog>

        <SimplesAlert
          float
          open={this.store.alert.open}
          message={this.store.alert.message}
          variant={this.store.alert.variant}
          onClose={() => this.store.toggleAlert(false)}
        />
      </div>
    );
  }
}
