import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { Card } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import history2 from '../../../common/history2';
import CommonForm from '../../../common/CommonForm2';
import CommonTable from '../../../common/CommonTable';

import Actions from '@data/Actions';

const action = new Actions();

const getActivityType = {
  1: 'Atividade',
  2: 'Test',
  3: 'RollBack',
  4: 'PreRec',
};

const redirect = (route, state) => {
  history2.push(route, state);
}

const statusColorValues = {
  notStarted: 'rgb(240, 151, 31)',
  inProgress: 'rgb(52, 172, 160)',
  success: 'rgb(0 159 194)',
  fail: 'rgb(240, 31, 31)',
  canceled: 'rgb(240, 31, 31)',
}

const getStatus = (obj, language) => {
  const { progress, checked, fail, cancelled } = obj;
  let statusColor;
  let statusLabel;

  if( cancelled ) {
    statusColor = statusColorValues.canceled;
    statusLabel = language.CANCELED;
  } else if ( fail ) {
    statusColor = statusColorValues.fail;
    statusLabel = language.FAILURE;
  } else if( checked ) {
    statusColor = statusColorValues.success;
    statusLabel = language.SUCCESS;
  } else if( !progress ) {
    statusColor = statusColorValues.notStarted;
    statusLabel = language.NO_STARTED;
  } else {
    statusColor = statusColorValues.inProgress;
    statusLabel = language.TICKET_STATUS.IN_PROGRESS;
  }

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{ color: statusColor }}
    >
      {statusLabel.toUpperCase()}
    </div>
  );
};

const ChangeTasks = ({ APIEndpoints, language }) => {
  const [tasks, setTasks] = useState([]);
  const [countTasks, setCountTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const callPageSelectFilter = (page = 0, values, limit = 75) => {
    setCurrentPage(page);

    if (values && values.status && values.status !== "") {
      const taskStatusProgress = ['Em progresso', 'En curso', 'In Progress'];  
      const taskStatusSuccess = ['Success', 'Sucesso', 'Exito'];
      const taskStatusFail = ['failure', 'Falla', 'Falha'];
      const taskStatusCanceled = ['Canceled', 'Cancelado'];
      const taskStatusNoStarted = ['Not started', 'No se ha iniciado', 'Não iniciado'];
  
      const progress = taskStatusProgress.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))
      const checked = taskStatusSuccess.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const fail = taskStatusFail.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const canceled = taskStatusCanceled.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const notStarted = taskStatusNoStarted.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))  
  
      if (canceled) {
        values.status = 'cancelled'
      } else if (fail) {
        values.status = 'fail'
      } else if (checked) {
        values.status = 'checked'
      } else if (notStarted) { 
        values.status = 'no_started'
      } else if (progress) {
        values.status = 'progress'
      } else {
        values.status = ''
      }
    }

    action
      .execute('post', `${APIEndpoints.TASKS}/changetask?limit=${limit}&offset=${page * limit}`, '', values)
      .then((res) => {
        setTasks(res.data);
        setCountTasks(res.data[0] ? res.data[0].total_count : 0);
      });
  };

  useEffect(() => {
    callPageSelectFilter();
  }, []);

  let data = tasks.map((obj) => ({
    id: obj.id_change_activity,
    name: obj.name,
    type: getActivityType[obj.type],
    group: obj.group,
    sponsor: obj.sponsor || '-',
    start_date: obj.start_date
      ? moment(obj.start_date).format('DD/MM/YYYY HH:mm')
      : '-',
    end_date: obj.end_date
      ? moment(obj.end_date).format('DD/MM/YYYY HH:mm')
      : '-',
    status: getStatus(obj, language),
    change: (
      <div
        onClick={() => {
          redirect('/dash/changemanagement/analyze', obj.fk_id_change)
        }}
        className="d-flex align-items-center justify-content-around pointer"
      >
        <div>{obj.fk_id_change}</div>
      </div>
    ),
  }));

  let col = [
    {
      key: 'id',
      label: language.ID,
      style: { width: 10, textAlign: 'left' },
    },
    {
      key: 'name',
      label: language.NAME,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'type',
      label: language.TYPE,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'group',
      label: language.TECH_GROUP,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'sponsor',
      label: language.SPONSOR,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'start_date',
      label: language.INITIAL_DATE,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'end_date',
      label: language.FINAL_DATE,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'status',
      label: language.STATUS,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'change',
      label: language.TICKET_TYPES.CHANGE,
      style: { width: 100, textAlign: 'left' },
      noOrder: true,
      search: false,
    },
  ];

  return (
    <Card className="content-min px-3">
      <CommonTable
        title={''}
        language={language}
        columns={col}
        data={data}
        index="TaskList"
        showHeaderToolbar={false}
        orderColumn
        countTotal={countTasks}
        isAutomaticPagination={false}
        beginWithNoFilter={true}
        paginationTop={false}
        searchColumn={true}
        loadSearch={true}
        currentPage={currentPage}
        searchColumnEvent={(v) => {
          console.log(v);
          //callPageSelectFilter(0, {ticketOrreuest :v.search}, 75)
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
      />
    </Card>
  );
};

export default ChangeTasks;
