import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import SearchInput from '@common/SearchInput';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import styled from 'styled-components';

import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import IconButton from '@material-ui/core/IconButton';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

import DialogConfirm from '@common/DialogConfirm';

const action = new Actions();
import './style.css';

export const useStyles = (theme) => ({
  listItem: {
    padding: 0,
    width: 218,
    backgroundColor: 'blue',
  },
  iconButton: {
    backgroundColor: '#FFF',
    marginLeft: '-13px',

    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  itemIcon: {
    width: 29,
  },
  itemText: {
    textTransform: 'uppercase',
    color: '#FFF',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  subitemText: {
    textTransform: 'uppercase',
    color: 'var(--colorIconButton)',
    fontSize: '10px',
    // paddingTop: 10,
  },
});

export const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    height: '40px !important',

    '&$expanded': {
       minHeight: 56,
      padding: '15px 0  0 12px',
      paddingTop: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles({
  root: {
    // padding: theme.spacing(2),
    minHeight: 44,
    maxHeight: 46,
    width: '38vh',
    minWidth: '330px',
    backgroundColor: 'white',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#F09726 !important',
      color: 'white',
      '& .MuiTypography-body1': {
        color: 'white',
      },
      '& .MuiIconButton-sizeSmall': {
        color: 'white',
      },
    },
    '& .MuiTypography-body1': {
      fontSize: 12,
      marginTop: 10,
      color: '#A3A3A3',
      fontWeight: 400,
    },
    '& .MuiIconButton-sizeSmall': {
      color: '#F09726',
      position: 'absolute',
      right: 10,
    },
  },
})(MuiExpansionPanelDetails);

export const ImgBox = styled.div`
  background-color: #fff;
  width: 26px;
  height: 26px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconImg = styled.img`
  width: 100%;
`;

export const BoxList = styled.div`
  width: 168px;
  display: flex;
  align-items: center;
`;

export const TextList = styled.p`
  color: #a3a3a3;
  font-size: 12px;
  white-space: normal;
`;

export function SidebarCompanies({
  companies,
  setSelectedDepartment,
  selectedCompany,
  handleFavorite,
  handleChangeCompany,
  expandedCompany,
  departmentsOptions,
  filterCompany,
  hasMoreCompanies,
  setHasMoreCompanies,
  setOffsetCompanies,
  setCompanies,
  offsetCompanies,
  totalCompanies,
  tasksFavorites,
  setIsFilterTask,
  setTaskFavorite,
  setResetDepartment,
  language,
  resetDepartment,
}) {
  const [page, setPage] = useState(0);
  const [header, setHeader] = useState('divTopSidebarPortal');
  const [confirmation, setConfirmation] = useState({
    open: false,
    task: '',
    service: '',
    category: '',
    name: '',
  });

  const listenScrollEvent = (event) => {
    if (window.scrollY < 10) {
      return setHeader('divTopSidebarPortal');
    } else if (window.scrollY > 10) {
      return setHeader('divTopSidebarPortalScroll');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const getMoreDataCompanies = () => {
    if (companies.length === totalCompanies) {
      setHasMoreCompanies(false);
      return;
    } else {
      action
        .execute(
          'post',
          Constants.APIEndpoints.COMPANY +
            `/all?limit=50&offset=${offsetCompanies}`
        )
        .then((c) => {
          setOffsetCompanies(offsetCompanies + 50);

          let newCat = [...c.data];
          let cat = [...companies, ...newCat];
          setCompanies(cat);
        });
    }
  };

  const handleConfirmation = (obj) => {
    setConfirmation(obj);
  };

  const handleSelectedSingleTask = (task) => {
    setTaskFavorite(task);
    setIsFilterTask(false);
  };
  return (
    <div className="divSidebarPortal">
      <div className={header}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
            marginLeft: '12%',
            width: '75%',
          }}
        >
          <SearchInput
            placeholder={language.SEARCH_THE_CATALOG}
            onKeyUp={(evt) => filterCompany(evt)}
          />
        </div>
        <div className="buttonsSidebar">
          <div
            className="buttonSidebar"
            onClick={() => {
              setPage(0);
              setIsFilterTask(false);
            }}
            style={{ backgroundColor: page == 0 ? '#F09726' : '' }}
          >
            <Icon className="iconButtonSidebar">local_offer_icon</Icon>

            <label>{language.COMPANIES}</label>
          </div>
          {/*<div
            className="buttonSidebar"
            onClick={() => setPage(1)}
            style={{ backgroundColor: page == 1 ? '#F09726' : '' }}
          >
            <Icon className="iconButtonSidebar">format_list_bulleted_icon</Icon>
            <label>Processos</label>
          </div> */}
          <div
            className="buttonSidebar"
            onClick={() => setPage(2)}
            style={{ backgroundColor: page == 2 ? '#F09726' : '' }}
          >
            <Icon className="iconButtonSidebar">star_rate_icon</Icon>
            <label> {language.FAVORITES} </label>
          </div>
          {/*<div
            className="buttonSidebar"
            onClick={() => setPage(3)}
            style={{ backgroundColor: page == 3 ? '#F09726' : '' }}
          >
            <Icon className="iconButtonSidebar">insert_drive_file_icon</Icon>
            <label>Formulários</label>
          </div>
          */}
        </div>
      </div>
      {page == 0 ? (
        <div
          style={{
            backgroundColor: 'white',
            marginTop: header == 'divTopSidebarPortal' ? 125 : 0,
          }}
        >
          {companies &&
            companies.map((company) => (
              <div style={{ backgroundColor: 'white', marginTop: 1 }}>
                <ListItem
                  style={{
                    backgroundColor:
                      company.id_company == selectedCompany.id_company
                        ? 'rgb(240, 151, 38)'
                        : 'white',
                    padding: 0,
                  }}
                >
                  <ExpansionPanel
                    square
                    expanded={expandedCompany === company.id_company}
                    onChange={handleChangeCompany(company)}
                    elevation={0}
                  >
                    <ExpansionPanelSummary
                      className=""
                      aria-controls={company.id_company + 'd-content'}
                      id={company.id_company + 'd-header'}
                    >
                      <BoxList className="box-list">
                        <TextList
                          style={{
                            color:
                              company.id_company == selectedCompany.id_company
                                ? 'white'
                                : '#A3A3A3',
                          }}
                        >
                          {company.name}
                        </TextList>
                      </BoxList>
                    </ExpansionPanelSummary>
                    {departmentsOptions.map((dpt) => (
                      <div className="divExpanse">
                        <ExpansionPanelDetails
                          onClick={() => {
                            setResetDepartment(true);
                            setSelectedDepartment(dpt);
                            setIsFilterTask(false);
                            setTaskFavorite([]);
                          }}
                        >
                          <Typography>{dpt.name}</Typography>
                        </ExpansionPanelDetails>
                        <Divider className="divider" />
                      </div>
                    ))}
                  </ExpansionPanel>
                </ListItem>
                <Divider className="divider" />
              </div>
            ))}
        </div>
      ) : page == 2 ? (
        <div
          style={{
            backgroundColor: 'white',
            marginTop: header == 'divTopSidebarPortal' ? 125 : 0,
          }}
        >
          <div>
            <ListItem
              style={{
                backgroundColor: 'white',
                padding: 0,
              }}
            >
              <ExpansionPanel square expanded={true} elevation={0}>
                <ExpansionPanelSummary className="">
                  <BoxList className="box-list">
                    <TextList
                      style={{
                        color: '#A3A3A3',
                      }}
                    >
                        {language.YOUR_FAVORITES}
                    </TextList>
                  </BoxList>
                </ExpansionPanelSummary>
                {tasksFavorites.map((task, i) => (
                  <div style={{ marginTop: 1 }}>
                    {i == 0 && <Divider className="divider" />}
                    <div className="divExpanseFavorite">
                      <ExpansionPanelDetails
                        onClick={() => handleSelectedSingleTask(task)}
                        className="divFavorite"
                      >
                        <Typography>{task.name}</Typography>
                      </ExpansionPanelDetails>
                      <IconButton
                        size="small"
                        aria-label="Menu"
                        style={{
                          color: 'orange',
                          outlineColor: 'transparent',
                          backgroundColor: 'transparent',
                        }}
                        onClick={() => {
                          handleConfirmation({
                            open: true,
                            task: task.id_catalog_task,
                            service: task.id_catalog_service,
                            category: task.id_category,
                            name: task.name,
                          });
                        }}
                      >
                        <StarIcon />
                      </IconButton>
                    </div>
                    <Divider className="divider" />
                  </div>
                ))}
              </ExpansionPanel>
            </ListItem>
          </div>
        </div>
      ) : null}
      {hasMoreCompanies && (
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => getMoreDataCompanies()}
        >
          {language.LOAD_MORE}
        </div>
      )}
      {confirmation.open && (
        <DialogConfirm
          open={confirmation.open}
          confirmProps={{ color: 'danger' }}
          onConfirm={() => {
            handleFavorite(
              'catalog_task',
              confirmation.task,
              confirmation.service,
              confirmation.category
            );
            handleConfirmation({
              open: false,
              task: '',
              service: '',
              category: '',
              name: '',
            });
          }}
          onClose={() =>
            handleConfirmation({
              open: false,
              task: '',
              service: '',
              category: '',
              name: '',
            })
          }
          title={language.DO_YOU_REALLY_WANT_TO_DELETE_THIS_FAVORITE}
          message={confirmation.name}
        />
      )}
    </div>
  );
}
