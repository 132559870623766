import React, {useState} from 'react';

export default function({
	projects=[], 
	users=[],
	language,
	companies=[]
}){
	//------------------- caso as opções sejam dinâmicas, fazer o controle dela aqui nessa tela
	return {
		tech(){
			return users.map(e => ({value:e.id_user, label:e.name}));
		},
		project(){
			return projects.map(e => ({value:e.id_project, label:e.name}));
		},
		type(){
			return language.TYPE_PROJECT_ITEMS
		},
		company(){
			return companies.map(e => ({value:e.id_company, label:e.name}));
		},
		
		// ----------------------------funções auxiliares------------------------------------
		getOptions(option, v, id){
			let _return = this[option](id).find(e=>e.value==v);
			return (_return||{}).label
		},

		getOptionsJoin(option, v, id){
			let _return = this[option](id).filter(e=>v.includes(e.value)).map(e=>e.label);
			return (_return||[]).join(', ')
		}
	}
}