import React, { useState, Fragment, useEffect, useCallback } from 'react';
import './index.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
/*------------------------common-----------------------------*/
import PageLayout from '@common/PageLayout';
import Button from '@common/Button';
import SwitchIos from '@common/SwitchIos';
import AppBar2 from '@common/AppBar2';
import { flexBasis } from '@common/Func';
import List from '@common/List';
import Link from '../../monitor/Link'
import CommonDelete from '../../../common/CommonDelete';
import MenuTooltip from '@common/MenuTooltip';
import Header from '@common/Header';
/*----------------------material-ui-------------------------------*/
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Card, Grid } from '@material-ui/core';
import SearchInput from '@common/SearchInput';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';
/*------------------------pages---------------------------------*/
import Item from './Item';
import DashboardMonitor from '../../monitor/DashboardMonitor';
/*--------------------------------------------------------------*/
import { GraphArea } from './Graphs';
import ReactHighcharts from 'react-highcharts';
import crypt from '../../../util/crypt';
import DevicesAlert from './DevicesAlert';
import LinkInfo from './LinkInfo';
import MapDevice from './MapDevice';

function Carousel({
  setId,
  setDevice,
  ioServers,
  monitor,
  monitorColors,
  anchorEl,
  setAnchorEl,
  ioLinks,
  setAnchorElLink,
  anchorElLink,
  language,
  handlePage,
  dataList,
  alerts,
  searchDevice,

}) {

  let [search, setSearch] = useState('');
  
  let [slicePosition, setSlicePosition] = useState(0);
  let data = dataList.filter(e => (e.type + ' ' + e.name + ' ' + e.ip).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1);
  let maxItem = 30;
  let count = Math.ceil(data.length / maxItem);
  let refAliceCarousel;

   
 
  return (
    <Fragment>
      <Header className="px-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center content-medium">
          <SearchInput
            className='mr-3'
            placeholder={language.SEARCH + '...'}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            clearSeach={() => {
              // handleFilter() 
              setSearch('')
            }}
          />
          <Button icon="fas fa-print" color="warning" label={language.PRINT} />
        </div>
        <div className='d-flex align-items-center'>
          <Button
            variant='circle'
            icon='fas fa-chevron-left'
            label={language.BACK}
            color='light'
            outlined
            className='mr-4'
            onClick={() => {
              setSlicePosition(p => Math.max(p - 1, 0));
            }}
          />
          {
            /* Array.from(Array(d), (_,i) => {
                 if(i+1==Math.ceil(d/2)){
                   return (
                     <MenuTooltip
                       option={Array.from(Array(count), (_, i)=>({
                         label:i+1
                       }))}
                     />
                   )
                 }else{
                   return (
                     <Button
                       variant='circle'
                       icon={
                         <span>{i+1}</span>
                       }
                       label='Voltar'
                       color='light'
                       outlined
                       className='mx-1'
                     />
                   )
                 }
             })*/
          }
          <Button
            variant='circle'
            icon='fas fa-chevron-right'
            label={language.ADVANCE}
            color='light'
            outlined
            onClick={() => {
              setSlicePosition(p => Math.min(p + 1, count - 1));
            }}
          />
        </div>
      </Header>
      <Card className="content-min" style={{ borderRadius: '0 0 4px 4px' }}>
        <Grid container spacing={2}>
          {data.slice(slicePosition * maxItem, maxItem + slicePosition * maxItem).map(h => (
            <Grid item xs={4} key={h.id_device}>
              {h.type == 'link' ? (
                <Link
                  _device={h}
                  setId={setId}
                  setDevice={setDevice}
                  handlePage={handlePage}
                  language={language}
                  anchorEl={anchorElLink}
                  setAnchorEl={setAnchorElLink}
                  ioLinks={ioLinks}
                  onDettachDevice={() => handleModal()}
                  handleModal={() => handleModal()}
                />
              ) : (
                <Item
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  setId={setId}
                  setDevice={setDevice}
                  device={h}
                  ioServers={ioServers}
                  monitor={monitor}
                  monitorColors={monitorColors}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Card>
    </Fragment>
  )
}
export default function (props) {
  let refAliceCarousel;
  let [anchorEl, setAnchorEl] = useState(false);
  let [anchorElLink, setAnchorElLink] = useState(false);
  const [toggle, setToggle] = useState(false);
  let [filter, setFilter] = useState(false);
  const [filterByGroup, setFilterByGroup] = useState(false);
  const [mAll, setMAll] = useState([]);
  const [id, setId] = useState(0);
  const [_device, setDevice] = useState({});
  const [monitorColors, setMonitorColors] = useState([])
  /* const [oks, setOks] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [dangers, setDangers] = useState([]); 
  const [listOnline, setListOnline] = useState([]); */

  const [search, setSearch] = useState('');

  /*const [graficPercent, setGraficPercent] = useState([
    { name: 'Ok', value: 0, color: 'var(--success)' },
    { name: 'Warning', value: 0, color: 'var(--warning)' },
    { name: 'Danger', value: 0, color: 'var(--danger)' },
  ]); */

  const [linkDash, setLinkDash] = useState({
    title: '',
    data: [0, 0, 0, 0, 0, 0, 0]
  })

  const [modalOpen, setModalOpen] = useState(false);
  const [mo, setMo] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const [groups, setGroups] = useState([]);
  const [del, setDel] = useState(false);
  const [up, setUp] = useState(false);
  const [group, setGroup] = useState('');
  let token = crypt((id).toString());
  const {
    language,
    monitorGroups,
    ioServers,
    ioLinks,
    monitor,
    ioSNMP,
    ioAlertsServers,
    handlePage,
    onRestartAgent,
    openUpdateHandle,
    companies,
    company,
    alerts,
    onDettachDevice
  } = props;

  const companyId = 1;

  useEffect(() => {
    loadmAll().then((value) => {
      setMo(monitor)
      setMAll(value.mAllFromLoad);
      setGroups(value.groupsMonitor);
    });
  }, [monitorGroups]);

  /* useEffect(() => {
 
     if (ioLinks && mAll.length > 0) {
 
       const devices = mAll.filter(device => device.type === 'link');
 
       if (devices.length > 0) {
         try {
           ioLinks.on(devices[0].id_device, data => {
 
             setLinkDash({
               title: devices[0].name,
               data: data.data
             })
           })
         } catch (error) {
 
         }
 
       }
 
 
     }
   }, [ioLinks, mAll]) */

  /*useEffect(() => {
    if (ioAlertsServers) {
      ioAlertsServers.on('alerts', (data) => {
        try {

          setOks(data.alertsall[0]);
          setWarnings(data.alertsall[1]);
          setDangers(data.alertsall[2]);

         graficPizza(
            data.alertsall[0].length,
            data.alertsall[1].length,
            data.alertsall[2].length
          )
        } catch (error) {
        }
      });
    }
  }, [ioAlertsServers]);*/

  /*function graficPizza(ok, warning, danger) {
    let denominator = ok + warning + danger;
    let okPercent = ok / denominator;
    let warningPercent = warning / denominator;
    let dangerPercent = danger / denominator;
    let valueOk = Math.floor(okPercent * 360);
    let valueWarning = Math.floor(warningPercent * 360);
    let valueDanger = Math.floor(dangerPercent * 360);

    setGraficPercent([
      { name: 'Ok', value: valueOk, color: 'var(--success)' },
      { name: 'Warning', value: valueWarning, color: 'var(--warning)' },
      { name: 'Danger', value: valueDanger, color: 'var(--danger)' },
    ]);
  } */

  function loadmAll() {
    return new Promise((resolve) => {
      // console.log('monitorGRoups', monitorGroups);
      const groupsMonitor = [];
      var c = ['link', 'server', 'desktop'];
      let mAllFromLoad = monitorGroups
        .reduce((arr, i) => {
          i.groups.map((e) => {
            e.devices.map((e) => {
              arr.push(e);
            });
          });
          return arr;
        }, [])
        .sort(function (a, b) {
          return c.indexOf(a.type) - c.indexOf(b.type);
        });
      monitorGroups.map(monitor => {
        return monitor.groups.map(group => {
          groupsMonitor.push({ label: group.name_group, group_id: group.id_group, group: monitor, company: monitor.company, items: group.devices.length > 0 ? group.devices : [] });
        });
      });
      resolve({ mAllFromLoad, groupsMonitor });
    });
  }

  function handlePageMenu(option, device) {
    setAnchorEl(null);
    let getDevice = monitor.find(
      m => m.id_device == device.id_device);

    if (option.label === 'Informações do Dispositivo') {
      try {
        device.conf['cpu-stats'].triggers;

        handlePage('summary', { _device: device })
      } catch (e) { }
    }
    if (option.label === 'Informações de Serviço') {
      handlePage('services', { ...getDevice })
    }
    if (option.label === 'SensrConf') {
      handleModal({ msg: token })
    }
    if (option.label === language.EDIT_CONFIGURATION) {
      //handlePage('deviceConf', {...this.props._device,
      // id_brands:this.props.fk_id_brands, snmp:this.props.snmp})
      let itens = getDevice === undefined ? device : getDevice;

      handlePage('deviceConf', { ...itens, id_brands: props.fk_id_brands, snmp: props.snmp })
    }
    if (option.label === language.UPDATE_AGENT) {
   
      handleModal({
        msg: language.CONFIRM_UPDATE_AGENT, confirm: () => {   ioServers.emit("updateag",device.id_device) ; handleModal(); }
      })
    }
    if (option.label === language.STOP_MONITORING) {
      handleModal({
        msg: language.CONFIRM_STOP_MONITOR, confirm: () => { onDettachDevice(device.id_device); handleModal(); }
      })
    }

    if (option.label === 'Informações do Link') {
      handlePage('summarylink', { _device: device })
    }
  }

  const handleModal = useCallback((opts) => {
    setModalOpen(!modalOpen);
    setModalOptions(opts);
  }, []);

  const onClose = useCallback(() => {
    setDel(del => !del);
    setUp(up => !up);
    setGroup('');
  }, []);

  const onUpdate = useCallback((values) => {
    props.upGroup(group, 'up', values);
    onClose();
  }, [group]);

  const upGroup = useCallback((group, action) => {
    setGroup(group);
    if (action === 'del') setDel(true);
  }, []);

  const onDelete = useCallback(() => {
    props.upGroup(group, 'del');
    onClose();
  }, [group]);

  /*const handleClickFilter = useCallback((index) => {
    if (index === 0) {
      handleFilter();
    } else if (index === 1) {
      handleSelectByStatus(oks)
    } else if (index === 2) {
      handleSelectByStatus(warnings)
    } else if (index === 3) {
      handleSelectByStatus(dangers)
    }
  }, [oks, warnings, dangers]) */

  /*const handleSelectByStatus = useCallback((ids) => {
    const devices = ids.map((value) => {
      for (let i = 0; i < mAll.length; i++) {
        if (value.id_device === mAll[i].id_device) {
          return mAll[i];
        }
      }
    })

    setFilterByGroup(true);
    setMAll([...devices]);
  }, [mAll]) */

  const handleSelectGroup = useCallback((id) => {
    const devices = groups
      .filter(group => group.group_id === id)[0].items;
    setFilterByGroup(true);
    setMAll([...devices]);
  }, [groups])

  const handleColor = () => { } /*useCallback((id, type = '') => {
    const m = monitorColors.find(m => m.id === id);

    if (m) {
      
      return !m.color ? 'var(--primary)' : `var(--${m.color})`;
    }
    if (type === 'link') {
      return 'var(--primary)'
    }

    return 'var(--primary)';

  }, [monitorColors])*/

  const getAvailability = () => { } /*useCallback((items) => {
    let qtd = 0
    let total = 0
    
    if (items.length > 0) {
      
      total = items.length;

      items.map(x => {
        if (x.type === 'link') {
          qtd += 1
        } else {
          qtd += listOnline.filter(lo => x.id_device === lo.id_device)[0] ? 1 : 0
        }
      })

      return ((qtd/total) * 100).toFixed(0)
    }

    return 0
  }, [listOnline]) */

  const handleFilter = () => {
    setFilterByGroup(false);
    loadmAll().then((value) => {
      setMAll(value.mAllFromLoad);
    });
  }

  const searchDevice = useCallback(() => {
    if (search) {
      let list = mAll.filter((item) => {
        return (
          item.name.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
          item.ip.toUpperCase().indexOf(search.toUpperCase() !== -1
          )
        )
      })
      if (list.length > 0) {
        setMAll(list);
      }
    }
  }, [mAll, search]);

  let dataList = (mAll || []).filter(function (device) {
    return !this[JSON.stringify(device)] && (this[JSON.stringify(device)] = true);
  }, Object.create(null));

  let [alertsDevice, setalertsDevice] = useState('');
   
 //console.log(alerts, "kkkkkkkkkkk")
/*
 if(alertsDevice==''){ 

  alerts.on("alerts", (data) => {
   
   let dev = [...data.alertsall[1].map(v=>v.id_device),...data.alertsall[2].map(v=>v.id_device)]
   let itens_1 =dataList.filter(b=>dev.includes(b.id_device)==true) 
   let itens_2 =dataList.filter(b=>dev.includes(b.id_device)==false) 
   setalertsDevice([...itens_1,...itens_2])
    })
  }
  if(alertsDevice != ''){
    dataList = alertsDevice
  }
*/

  return (
    <PageLayout
      title={language.MONITOR}
      subTitle={language.AVAILABILITY_MANAGEMENT + ' / '}
    >
      <div id="Monitor-Home">
  
  
        <Carousel
          dataList={dataList}
          setId={setId}
          setDevice={setDevice}
          ioServers={ioServers}
          monitor={monitor}
          monitorColors={monitorColors}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          ioLinks={ioLinks}
          setAnchorElLink={setAnchorElLink}
          anchorElLink={anchorElLink}
          language={language}
          handlePage={handlePage}
          alerts={alerts}
          searchDevice={searchDevice}
          search={search}
          setSearch={setSearch}
        />

        {/*#####################################################-MENU-###################################################################################*/}
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {[
            
             
            {
              icon: 'fas fa-sun',
              label: 'SensrConf',
              color: '#9ed662',
            },
            {
              icon: 'fas fa-sync-alt',
              label: language.UPDATE_AGENT,
              color: '#06bbcd',
            },
            {
              icon: 'fas fa-pencil-alt',
              label: language.EDIT_CONFIGURATION,
              color: '#06bbcd',
            },
            {
              icon: 'fas fa-eye-slash',
              label: language.STOP_MONITORING,
              color: '#06bbcd',
            },
          ].map((e, i) => (
            <MenuItem onClick={() => {
              handlePageMenu(e, _device)
            }}>
              <i className={e.icon} style={{ color: e.color }} />
              <span
                style={{ fontSize: 15, color: 'var(--primary)' }}
                className="ml-2"
              >
                {e.label}
              </span>
            </MenuItem>
          ))}
        </Menu>
        {/*#####################################################-MENU LINK-###################################################################################*/}
        <Menu
          anchorEl={anchorElLink}
          open={!!anchorElLink}
          onClose={() => setAnchorElLink(null)}
        >
          {[
            {
              icon: 'fas fa-pencil-alt',
              label: 'Editar Configurações',
              color: '#06bbcd',
            },
            {
              icon: 'fas fa-eye-slash',
              label: 'Deixa de Monitorar',
              color: '#06bbcd',
            },
            {
              icon: 'fas fa-info-circle',
              label: 'Informações do Link',
              color: 'var(--primary)',
            },
          ].map((e, i) => (
            <MenuItem onClick={() => {
              handlePageMenu(e, _device)
            }}>
              <i className={e.icon} style={{ color: e.color }} />
              <span
                style={{ fontSize: 15, color: 'var(--primary)' }}
                className="ml-2"
              >
                {e.label}
              </span>
            </MenuItem>
          ))}
        </Menu>
        {modalOptions && Object.keys(modalOptions).length ? (
          <Dialog
            open={modalOpen}
            bodyStyle={{ padding: 0, overflowY: 'none' }}
            title={
              modalOptions.confirm ? language.CONFIRMATION : 'SensrConf'
            }
            onClose={() => handleModal()}
          >
            <h2 style={{ color: '#616161', fontWeight: 100 }}>
              {modalOptions.msg}
            </h2>
            {modalOptions.confirm ? (
              <div style={{ float: 'right' }}>
                <RaisedButton
                  primary={true}
                  label={language.IM_SURE}
                  onClick={modalOptions.confirm}
                />
              </div>
            ) : null}
          </Dialog>
        ) : null}
      </div>
    </PageLayout>
  );
}