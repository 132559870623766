import './CatalogForm.css';

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm2';
import RaisedButton from '../../common/RaisedButton';

import iconsSolid from '@images/icons-solid.png';

import Button from '../../common/Button';
import DialogIcon from '../../common/DialogIcons';
import Icon from '@material-ui/core/Icon';
import Dialog from '@common/Dialog';
import { Constants } from '../../data';

function importAll(r) {
  return r.keys().map(r);
}

const allImgs = importAll(
  require.context('@images/flat-icon-fa/', false, /\.(png|jpe?g|svg)$/)
);

@observer
export default class CatalogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      icon: null,
      iconType: props?.values?.icon_url ? 'image' : 'icon',
      iconValue: '',
      openModal: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleIconChange = this.handleIconChange.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
  }

  componentWillMount() {
    this.setState({ icon: (this.props.values || {}).icon });
  }

  toggleDialog = (open) => {
    this.setState({ dialog: open });
  };

  setIcon = (icon) => {
    this.setState({ icon: icon.replace(/\/dash|\/|\.png/g, '') }, () =>
      this.toggleDialog(false)
    );
  };

  onFormSubmit = (values) => {
    values.icon_type = this.state.iconType;
    values.icon =
      this.state.iconType == 'icon'
        ? this.state.iconValue || values.icon
        : null;
    values.icon_url =
      this.state.iconType == 'image'
        ? this.state.iconValue || values.icon_url
        : null;

    this.props.onFormSubmit(values);
  };

  handleIconChange = (iconData) => {
    this.setState({
      iconType: iconData.type,
      iconValue: iconData.iconString,
      openModal: false,
    });
  };

  handleToggleModal = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    let {
      language,
      handlePage,
      groups,
      redirect,
      isDev,
      APIEndpoints,
      allIcons,
    } = this.props;
    let { icon } = this.state;

    let values = Object.assign({}, this.props.values);
    let groupTechOptions = groups.map((g) => ({
      value: g.id_group_users,
      label: g.name,
    }));

    values.icon = icon;

    let src = (isDev ? '/' : '/dash/') + values.icon + '.png';

    let fields = [
      {
        col: 6,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
      },
      {
        col: 6,
        type: 'autocomplete', 
        name: 'id_group_users',
        textlabel: language.TECH_GROUP,
        className: 'tech-group-ticket',
        required: true,
        method: 'POST',
        route: `${APIEndpoints.GROUP}/filter`,
        fieldquery: 'search',
        textinit: values && values.name_group ? values.name_group : '',
        fieldvaluedb: 'id_group_users',
        fieldlabeldb: 'name',
        idinit: values && values.id_group_users ? values.id_group_users : 0,
        routeAll: `${APIEndpoints.GROUP}/all?`,
        detail: true,
        methodGetAll: 'POST',
        routeGetCount: `${APIEndpoints.GROUP}/count`,
      },
      {
        col: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        multiLine: true,
      },
      // {
      //   name: 'icon',
      //   label: language.ICON,
      //   type: 'select',
      //   options: allIcons,
      // },
      {
        col: 2,
        name: 'icon',
        label: 'Ícone',
        type: 'element',
        content: (
          <div className='tw-flex tw-items-center tw-gap-2'>
            <Button
              variant="normal"
              color={'success'}
              loading={false}
              disabled={false}
              onClick={() => {
                this.handleToggleModal(true);
              }}
              label={language.CHOOSE_ICON}
            />
            <div className="tw-p-2 tw-border tw-rounded tw-text-center">
              {this.state.iconType == 'image' ? (
                <img
                  className="tw-h-10"
                  src={`${Constants.BASE_URL_IMAGE}${this.state.iconValue || values.icon_url}`}
                />
              ) : (
                <Icon>{this.state.iconValue || values.icon}</Icon>
              )}
            </div>
          </div>
        ),
      },
    ];

    let button = {
      label: language.SAVE,
    };

    return (
      <div id="CatalogForm">
        <Dialog
          title={language.CHOOSE_ICON}
          open={this.state.dialog}
          onClose={() => this.toggleDialog(false)}
          maxWidth="md"
        >
          <div id="CatalogFormDialog">
            <Row>
              {allImgs.map((e) => (
                <Col sm={1} style={{ padding: 15 }}>
                  <div className="button-icon d-flex align-items-center justify-content-center p-2">
                    <img
                      onClick={() => this.setIcon(e)}
                      src={e}
                      alt={e}
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Dialog>
        <CommonForm
          fields={fields}
          button={button}
          onSubmit={this.onFormSubmit}
          values={values}
          loading="post-put-catalog"
        />
        {this.state.openModal && (
          <Dialog
            open={true}
            maxWidth="lg"
            title={language.CHOOSE_ICON}
            onClose={() => this.handleToggleModal(false)}
          >
            <DialogIcon onChange={this.handleIconChange} />
          </Dialog>
        )}
      </div>
    );
  }
}
