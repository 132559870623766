import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { Card, Switch } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import ReactTable from 'react-table';
import CompanyHoliday from './CompanyHoliday'
@observer
export default class CompanyTimeList extends Component {

  constructor() {
    super();
    this.state = {
      feriados: false
    };



  }

  render() {
    let { language, handlePage, redirect, onDelete, contracts, store, APIEndpoints, action } = this.props;

    let col = [
      { label: language.COMPANY, key: 'fk_id_company' },
      { label: language._CONTRACT, key: 'contrato' },
      { label: language.WEEK, key: 'semana' },
      { label: language.INITIAL_HOUR, key: 'hr_start' },
      { label: language.END_HOUR, key: 'hr_end' },

      { label: language.MODULE, key: 'module' },
      { label: 'gmt', key: 'time' },
      { label: language.ACTION, key: 'action' }
    ];

    var company_name;
    var contract_name;
    let data = this.props.store.companytimes.map(obj => {
      company_name = store.companies.filter(c => c.id_company === obj.fk_id_company).map(c => c.name);
      contract_name = store.contracts.filter(c => c.id_contract === obj.fk_id_contract).map(c => c.name);
      obj.name = contract_name;
      return {
        fk_id_company: company_name,
        contrato: contract_name,
        semana: obj.semanadetail,
        hr_start: obj.hr_start,
        hr_end: obj.hr_fim,
        module: obj.module,
        time: obj.time,

        action: (
          <div className='d-flex align-items-center'>
            <RaisedButton
              circleButton
              icon='fas fa-edit'
              label={language.EDIT}
              color='default'
              style={{ fontSize: 13, width: 21, height: 21 }}
              onClick={() => {
                handlePage('form', obj), this.setState({ feriados: false })
              }}
              className='mr-2'
            /><RaisedButton
              circleButton
              icon='far fa-trash-alt'
              label={language.DELETE}
              color='danger'
              style={{ fontSize: 13, width: 21, height: 21 }}
              onClick={() => {
                handlePage('remove', obj),
                  this.setState({ feriados: false })
              }}

            />

          </div>
        )
      };
    });

    let { configurationProps = {} } = this.props;

    return (
      <Fragment>

        <CommonTable data={data} columns={col} language={language} searchColumn orderColumn index='CompanyTimeList'
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <div style={{ display: 'flex' }}><RaisedButton
              circleButton
              icon='fas fa-plus'
              style={{ zoom: .8 }}
              zoom={.8}
              className='mr-3'
              color='primary'
              primary={true}
              label={language.ADD}
              onClick={() => {
                handlePage('form', null),
                  this.setState({ feriados: false })
              }}
            />
              <RaisedButton
                circleButton
                icon='far fa-calendar-times'
                style={{ zoom: .8 }}
                zoom={.8}
                className='mr-3'
                color='warning'
                primary={true}
                label={language.HOLIDAYS}
                onClick={() => this.setState({ feriados: true })}
              /></div>
          }
        />

        {this.state.feriados == true ?
          <CompanyHoliday
            store={this.props.store}
            APIEndpoints={APIEndpoints}
            action={action}
            language={language}></CompanyHoliday> : false}


      </Fragment>
    );
  }
}
