import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import { FaClose } from 'react-icons/lib/fa';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Checkbox, TextField, Paper } from '@material-ui/core';

import AppBar from '../../common/AppBar';
import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { translateFromPtBR } from '../../common/languages/Dictionary';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

const action = new Actions();

@observer
export default class CatalogAddTaskForm extends Component {
  constructor() {
    super();
    this.state = {
      checkedTasks: [],
      tasks: [],
      totaltasks: 0,
      currentPage: 0,
      open: false,
      catalog: null,
      modified: false,
    };
    this.handleModal = this.handleModal.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.addTasksInCatalog = this.addTasksInCatalog.bind(this);
    this.removeTaskOfCatalog = this.removeTaskOfCatalog.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
  }

  componentWillMount() {
    const catalog = this.props.values;
    this.setState({
      catalog: catalog,
    });
  }

  componentWillUnmount() {
    if (this.state.modified) {
      this.props.refreshCatalog(this.state.catalog);
    }
  }

  handleModal() {
    if (!this.state.open) {
      this.callPageSelectFilter();
    }
    this.setState({ open: !this.state.open });
  }

  checkBoxSelect(id_catalog_task) {
    let { checkedTasks } = this.state;
    if (checkedTasks.indexOf(id_catalog_task) === -1) {
      checkedTasks.push(id_catalog_task);
    } else {
      checkedTasks = checkedTasks.filter((id) => id != id_catalog_task);
    }
    this.setState({ checkedTasks: checkedTasks });
  }

  addTasksInCatalog() {
    let { catalog, tasks, checkedTasks } = this.state;
    if (checkedTasks.length) {
      let payload = {
        tasks: checkedTasks,
        id_catalog_service: catalog.id_catalog_service,
      };

      action
        .execute('put', `${Constants.APIEndpoints.CATALOG}/task`, '', payload)
        .then((res) => {
          this.setState({
            checkedTasks: [],
            catalog: res.data,
            modified: true,
          });
        });
      this.handleModal();
    }
  }

  removeTaskOfCatalog(values) {
    action
      .execute('put', `${Constants.APIEndpoints.CATALOG}/task`, '', values)
      .then((res) => {
        this.setState({
          checkedTasks: [],
          catalog: res.data,
          modified: true,
        });
      });
  }

  callPageSelectFilter(page = 0, values, totalPerPage = 75, type) {
    this.setState({ currentPage: page });
    let limit = totalPerPage;
    let offset = page > 0 ? page * limit : 0;

    if (type == 'filter') {
      offset = 0;
    }

    action
      .execute('post', `${Constants.APIEndpoints.TASK}/list?limit=${totalPerPage}&offset=${offset}`, '', values)
      .then((res) => {
        this.setState({
          tasks: res.data,
          totaltasks: parseInt(res.data[0].total_count),
        });
      });
  }

  render() {
    let { language, redirect } = this.props;

    let { open, catalog, tasks, checkedTasks, totaltasks, currentPage } =
      this.state;

    if (!catalog) return null;

    let col = [
      { key: 'id', label: language.ID },
      { key: 'name', label: language.NAME },
      { key: 'description', label: language.DESCRIPTION },
      { key: 'sla', label: language.SLA },
      { key: 'impact', label: language.IMPACT },
      { key: 'urgency', label: language.URGENCY },
      { key: 'priority', label: language.PRIORITY },
      { key: 'complexity', label: language.COMPLEXITY },
      { key: 'remove', label: language.REMOVE, style: { width: 20 } },
    ];

    let data =
      catalog.task &&
      catalog.task.map((obj) => ({
        id: obj.id_catalog_task,
        name: obj.name,
        description: obj.description
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace('&nbsp;', ''),
        sla: obj.time_sla,
        impact: translateFromPtBR(obj.impact, language),
        urgency: translateFromPtBR(obj.urgency, language),
        priority: translateFromPtBR(obj.priority, language),
        complexity: obj.complexity,

        remove: (
          <Button
            variant="circle"
            size={0.7}
            icon="far fa-trash-alt"
            label={language.DELETE}
            color="danger"
            _loading={
              'del-catalog-' +
              obj.id_catalog_task +
              '-' +
              catalog.id_catalog_service
            }
            style={{ fontSize: 14 }}
            onClick={() =>
              this.removeTaskOfCatalog({
                id_catalog_task: obj.id_catalog_task,
                id_catalog_service: catalog.id_catalog_service,
                name: obj.name,
                detach: true,
              })
            }
          />
        ),
      }));

    let groupTask = !catalog.task
      ? []
      : catalog.task.map((task) => task.id_catalog_task);

    let colTask = [
      { key: 'check', label: '', style: { width: 10 } },
      { key: 'id', label: '#', style: { width: 20 } },
      { key: 'name', label: language.NAME },
      { key: 'sla', label: language.SLA },
      { key: 'impact', label: language.IMPACT },
      { key: 'urgency', label: language.URGENCY },
      { key: 'priority', label: language.PRIORITY },
      { key: 'complexity', label: language.COMPLEXITY },
    ];

    let dataTask = tasks
      .filter((t) => !groupTask.includes(parseInt(t.id_catalog_task)))
      .map((obj) => {
        return {
          check: (
            <Checkbox
              checked={checkedTasks.indexOf(obj.id_catalog_task) != -1}
              onChange={() => this.checkBoxSelect(obj.id_catalog_task)}
            />
          ),
          id: obj.id_catalog_task,
          name: obj.name,
          sla: obj.time_sla,
          impact: obj.impact,
          urgency: obj.urgency,
          priority: obj.priority,
          complexity: obj.complexity,
        };
      });

    let addTaskDialog = (
      <Dialog
        modal={true}
        open={open}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        autoScrollBodyContent={true}
        onClose={() => this.handleModal()}
        title={language.NEW_ASSIGNMENT}
      >
        <CommonTable
          title={''}
          language={language}
          columns={colTask}
          data={dataTask}
          searchColumnEvent={(v) =>
            this.callPageSelectFilter(0, v, 75, 'filter')
          }
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.callPageSelectFilter(page, values, rowsPerPage, type)
          }
          countTotal={totaltasks}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          loadSearch={true}
          currentPage={currentPage}
          hideFooterToolbar
          paginationTop={false}
        />

        <Row style={{ marginTop: 10 }}>
          <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ float: 'right' }}>
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                onClick={this.addTasksInCatalog}
                label={language.ADD}
                primary={true}
                fullWidth
              />
            </div>
          </Col>
        </Row>
      </Dialog>
    );

    return (
      <div>
        <Paper className="pb-3 mb-3">
          <Container fluid className="pt-2">
            <Row>
              <Col md={2}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  value={catalog.name}
                  fullWidth
                  style={{ width: '100%' }}
                />
              </Col>
              <Col md={4}>
                <TextField
                  label={language.DATE_CAD}
                  disabled={true}
                  value={moment(catalog.dt_cad).format('LL')}
                  fullWidth
                  style={{ width: '100%' }}
                />
              </Col>

              <Col md={6}>
                <TextField
                  label={language.DESCRIPTION}
                  multiLine={true}
                  fullWidth
                  disabled={true}
                  value={catalog.description}
                  fullWidth
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Container>
        </Paper>

        <CommonTable
          title={``}
          language={language}
          columns={col}
          data={data}
          orderColumn
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.NEW_ASSIGNMENT}
              onClick={this.handleModal}
              primary
              fullWidth
            />
          }
        />
        {addTaskDialog}
      </div>
    );
  }
}
