import React from 'react';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Progress from '@common/Progress';
import { Card, Avatar } from '@material-ui/core';
import './ProjectPortfolio.css';
import Evolution from '../ProjectManagementList/Evolutionvalue'
import moment from 'moment';

export default function (props) {
	let { language, projects, setOpenActivity, users, redirect,
		action,
		APIEndpoints  } = props;
	let riskColor = ['var(--success)', 'var(--warning)',
		'var(--danger)'];
	//let riskType = ['Aceitável', 'Crítico', 'Intolerável'];
	let riskType = ['Baixo', 'Médio', 'Alto'];
	let gestor = "";


	let data = [];
	projects.projetos.map(g => {

 let ev =Evolution(g)

		try { gestor = users && users.filter(j => parseInt(j.id_user) == parseInt(g.user_cad))[0].name }
		catch (y) { }
		data.push({
			ConfigCell: {
				className: 'tr-principal',

			},
			'#': (
				<Button
					onClick={() =>
						redirect('/dash/gp/list/detail', {
							id_project: g.id_project,
							projectSelect: g,
							router: 'pmo'
						})}
					icon='fas fa-eye'
					variant='circle'
					outlined
					style={{ border: 'none' }}
				/>
			),
			id: g.id_project,
			project: g.name,
			gestor: gestor,
			progress: (
				<div className='d-flex align-items-center' style={{ width: 80 }}>
					<Progress hiddenText className='w-100' style={{ height: 6 }} current={ev.percentual} />
					<span className='ml-2'>{ev.percentual>0?ev.percentual:0}%</span>
				</div>
			),
			hourtotal:ev.horas,
			 houre :ev.horasex,
			 hourc:ev.horastcon,
			 dt_f:moment(g.end_date).format('DD/MM/YYYY'),
			risk: <span className='riskitem' style={{ backgroundColor: g.type_risk ? riskColor[g.type_risk - 1] : '' }}>{riskType && riskType[g.type_risk - 1]}</span>,
			type_project: g.type_project && language.TYPE_PROJECT_ITEMS[g.type_project - 1].label,
			expected_cost: g.cust_project,
			realized_cost: g.realized_cost,
			variation: '50%',
			activites: (
				<div className='d-flex align-items-center justify-content-between'>
					{[0, 1, 0, 0].map((e, i) => (
						<Button
							key={i}
							size={.7}
							onClick={() => setOpenActivity(i == 0 ? g.statusproject["TODO"] :
								i == 1 ? g.statusproject["DOING"] :
									i == 2 ? g.statusproject["STOPPED"] :
										i == 3 ? g.statusproject["DONE"] : [])}
							label={

								i == 0 ? g.statusproject["TODO"].length :
									i == 1 ? g.statusproject["DOING"].length :
										i == 2 ? g.statusproject["STOPPED"].length :
											i == 3 ? g.statusproject["DONE"].length :
												0

							}
							color={
								i == 0 ? 'primary' :
									i == 1 ? 'warning' :
										i == 2 ? 'danger' :
											i == 3 ? 'success' :
												null
							}
							style={{ borderRadius: 5 }}
						/>
					))}
				</div>
			)
		})


	})



	return (
		<div id='ProjectPortfolio'>
			<CommonTable
				title={<span style={{color:'white'}}>Portfólio do Projeto</span>}
				searchWidth={200}
				paginationTop={false}
				beforeTitle2={
					<div style={{ minWidth: 400, zoom: .7 }} className='mr-3 control-filter'>

					</div>
				}
				printButtonProps={{ zoom: .7 }}
				clearFiltersProps={{ zoom: .7 }}
				searchProps={{ zoom: .7 }}
				mini
				col={[
					{ key: '#', label: '', thConfig: true, style: { paddingRight: 5 } },
					{ key: 'id', label: 'ID' },
					{ key: 'project', label: language.PROJECT, thConfig: true, style: { paddingLeft: 0 } },
					{ key: 'gestor', label: 'Gestor' },
					{ key: 'progress', label: language.PERCENTAGE },
					{ key: 'hourtotal', label: 'Hr Total' },
					{ key: 'houre', label: 'Hr Excedida' },
					{ key: 'hourc', label: 'Hr Consumida' },
					{ key: 'dt_f', label: 'Data Final' },
					{ key: 'risk', label: language.RISKS },
				
					{ key: 'type_project', label: language.TYPE_PROJECT },
					{ key: 'expected_cost', label: language.EXPECTED_COST },
					{ key: 'realized_cost', label: language.REALIZED_COST },
					{ key: 'variation', label: 'Variação' },
					{ key: 'activites', label: language.ACTIVITIES },
				]}
				data={data}
			/>
		</div>
	)
}