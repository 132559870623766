import RaisedButton from '../../common/RaisedButton';
import Button from '@common/Button';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, TextField, Paper, MenuItem } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import CommonTable from '../../common/CommonTable';
import DialogConfirm from '../../common/DialogConfirm';
import Dialog from '@common/Dialog';
import CommonPage from '@common/CommonPage';

@observer
export default class CompanyLocationForm extends CommonPage {
  constructor() {
    super();
    this.state = {
      locations: [],
      open: false,
      openEdit: false,
      selectLocation: [],
      opencat: false,
      openNew: false,
      companytext: '',
      categories: [],
      pageDelete: {
        open: false,
        values: undefined,
      },
      location: [],
    };
    this.handleModal = this.handleModal.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.handlePageDelete = this.handlePageDelete.bind(this);
    this.addLocationInCompany = this.addLocationInCompany.bind(this);
    this.getLocationsByCompany = this.getLocationsByCompany.bind(this);
  }

  handleModal() {
    this.setState({ open: !this.state.open, openEdit: false });
  }

  addLocationInCompany() {
    let { values } = this.props;
    let { locations } = this.state;
    if (locations && locations.length) {
      this.action
        .execute(
          'put',
          `${this.APIEndpoints.COMPANY}/location/`,
          '',
          { locations: locations, id_company: values.id_company },
          'id_company'
        )
        .then((g) => {
          this.getLocationsByCompany(values.id_company);
          this.setState({ locations: [] });
          this.handleModal();
        });
    }
  }

  checkBoxSelect(id_location) {
    let { locations } = this.state;
    if (locations.indexOf(id_location) === -1) {
      locations.push(id_location);
    } else {
      locations = locations.filter((id) => id != id_location);
    }
    this.setState({ locations: locations });
  }

  getLocationsByCompany(companyId) {
    this.action
      .execute('get', `${this.APIEndpoints.LOCATION}/company/${companyId}`, '')
      .then((v) => {
        this.setState({ location: v.data });
      });
  }

  componentWillMount() {
    const { values } = this.props;
    this.getLocationsByCompany(values.id_company);
  }

  handlePageDelete(open = false, values) {
    this.setState({ pageDelete: { open, values } });
  }

  deleteLocation() {
    const { values } = this.state.pageDelete;
    this.action
      .execute(
        'put',
        `${this.APIEndpoints.COMPANY}/location`,
        'companies',
        values,
        'id_company'
      )
      .then((res) => {
        this.getLocationsByCompany(values.id_company);
        this.setState({ location: [] });
        this.handlePageDelete(false, {});
      });
  }

  render() {
    let {
      language,
      handlePage,
      values,
      locations,

      users,
      arrayUsers,
    } = this.props;
    let { open, location } = this.state;

    let col = [
      { key: 'id', label: '#', style: { width: 20 } },
      {
        key: 'name',
        label: language.NAME,
        thConfig: true,
        style: { width: 1000 },
      },
      { key: 'description', label: language.DESCRIPTION },

      {
        key: 'remove',
        label: language.REMOVE,
        thConfig: true,
        style: { width: 100 },
      },
    ];

    let data = [];

    location.map((d) => {
      if (data.filter((g) => g.id == d.id_location).length == 0)
        data.push({
          id: d.id_location,
          name: d.name,
          description: d.description,
          remove: (
            <Button
              variant="circle"
              icon="far fa-trash-alt"
              label={language.DELETE}
              color="danger"
              className="ml-2"
              style={{ fontSize: 13, width: 21, height: 21 }}
              size={0.9}
              onClick={() =>
                this.handlePageDelete(true, {
                  id_location: d.id_location,
                  id_company: values.id_company,
                  name: d.name,
                  detach: true,
                })
              }
              _loading={
                'del-company-list-dp-' + d.id_location + '-' + values.id_company
              }
            />
          ),
        });
    });

    let colLocDialog = [
      { key: 'checkbox', label: '', style: { width: 20 } },
      { key: 'id', label: language.ID, style: { width: 20 } },
      { key: 'name', label: language.NAME },
      { key: 'description', label: language.DESCRIPTION },
    ];

    let groupLocations = location.map((lo) => lo.id_location);

    let dataLocDialog = locations
      .filter((d) => !groupLocations.includes(d.id_location))
      .map((d) => {
        return {
          checkbox: (
            <Checkbox
              checked={this.state.locations.indexOf(d.id_location) != -1}
              onChange={() => this.checkBoxSelect(d.id_location)}
            />
          ),
          id: d.id_location,
          name: d.name,
          description: d.description,
        };
      });
    let addLocDialog = (
      <Dialog
        modal={false}
        open={open}
        onClose={() => this.handleModal()}
        title={language.ADD}
        contentProps={{
          id: 'nn',
        }}
        PaperProps={{
          id: 'nn2',
        }}
      >
        <CommonTable
          title={''}
          language={language}
          columns={colLocDialog}
          data={dataLocDialog}
          hideFooterToolbar
          paginationTop={false}
          index="table-2"
        />

        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <div style={{ float: 'right' }}>
              <RaisedButton
                circleButton
                icon="fas fa-save"
                primary={true}
                label={language.ADD}
                fullWidth
                onClick={this.addLocationInCompany}
              />
            </div>
          </Col>
        </Row>
      </Dialog>
    );

    return (
      <div>
        <Paper className="pb-2 mb-3">
          <Container fluid className="pt-2">
            <Row>
              <Col md={3}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  value={values.name}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col md={9}>
                <TextField
                  label={language.DESCRIPTION}
                  multiLine={true}
                  fullWidth={true}
                  disabled={true}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Container>
        </Paper>

        <CommonTable
          language={language}
          columns={col}
          data={data}
          title={language.ID + ' ' + values.id_company}
          maxHeight={1000000000000000}
          index="CompanyLocationForm"
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.ADD_LOCATION}
              onClick={this.handleModal}
              primary
              fullWidth
            />
          }
        />
        {addLocDialog}

        {this.state.pageDelete.open && (
          <DialogConfirm
            open={this.state.pageDelete.open}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={this.deleteLocation}
            onClose={() => this.handlePageDelete()}
            title={language.WARNING_DELETE_ITEM_SELECTED}
            message={this.state.pageDelete.values.name}
          />
        )}
      </div>
    );
  }
}
