import Store from '@data/Store';

const { language } = Store;

export function ActivityConcluded({ categories, data = [] }) {
	return {
		chart: {
			type: 'column',
			height: 200

		},
		title: null,
		xAxis: {
			// type:'category',
			categories,
			crosshair: true,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		yAxis: {
			min: 0,
			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
				'<td style="padding:0"><b>{point.y}</b></td></tr>',
			footerFormat: '</table>',
			shared: true,
			useHTML: true
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0
			},
			series: {
				borderRadius: 3,

				// borderWidth: 0,
				borderColor: 'red',
				dataLabels: {
					enabled: true,
					format: '{point.y:.0f}',
					color: 'var(--primary)',
					style: {
						fontSize: 8,
						color: 'var(--primary)'
					}
				},
			}
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		series: [{
			name: '',
			data: data
		}]
	}
}
export function BURNDOWN({ categories, evolution, desired_maturity }) {

	return {
		chart: {
			type: 'areaspline',
			height: 250
		},

		title: null,
		subtitle: null,
		xAxis: {
			allowDecimals: false,
			labels: {
				formatter: function () {
					return categories[this.value];
				},
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},

		},
		yAxis: {

			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			}

		},
		tooltip: {
			pointFormat: '{series.name} {point.y:,.0f}%'
		},
		plotOptions: {
			areaspline: {
				fillOpacity: 0.4,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			}
		},
		credits: { enabled: false },
		legend: {
			itemStyle: {
				fontSize: 10,
				color: '#A3A3A3'
			}
		},
		series: [{
			name: language.EVOLUTION_OF_ACTIVITIES,
			color: '#629DAA',
			data: evolution
		}, {
			name: language.PROJECTION_OF_ACTIVITIES,
			type: 'line',
			color: '#F09702',
			data: desired_maturity
		}]
	}
}
