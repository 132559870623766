import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import RaisedButton from '../../common/RaisedButton';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import CommonPage from '../../common/CommonPage';
import FaTimesCircle from 'react-icons/lib/fa/times-circle';
import moment from 'moment';
import Constants from '../../data/Constants';
import { MenuItem, Select, IconButton, Menu, Fade } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';
import '../../bClass.css';
let host = '';
let tokenApi = '';
var getConnection = '';

const tenant = Constants.Tenant;
export default class Link extends CommonPage {
  constructor() {
    super();
    this.state = {
      mount: true,
      available: true,
      hold: '',
      values: {},
      icmp: [],
      hold: '',
      aliveHold: '',
      seq: 0,
      anchorEl: null,
      open: ''

    };
    //   this.aliveSignal = this.aliveSignal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(item) {
    this.setState({ open: item })

  }

  handleClose() {
    this.setState({ open: '' })
  }
  componentWillMount() {

    try {

      this.props.ioLinks.on(`_${tenant}_${this.props._device.id_device}`, (data) => {
        clearTimeout(this.state.hold);

        this.setState({ icmp: data.data })
        let hold = setTimeout(() => (1000));
        this.setState({ available: false, hold: hold });
      })
    } catch (e) { }

  }

  render() {
    let {
      _device,
      handlePage,
      language,
      handleModal,
      onDettachDevice,
      anchorEl
    } = this.props;
    let { values, available, icmp, seq } = this.state;
    //console.log(JSON.stringify(icmp))
    ///console.log(icmp.indexOf(-1));


    return (
      <Row className="row">
        <Col md={12} style={{ padding: 0 }}>
          <div style={{ float: 'left', width: '50px' }}>   <div className="linkIcon" style={{ width: 20, height: 20 }} /></div>
          <div style={{ float: 'left', width: '80px' }}>IP: {_device != undefined ? _device.ip : null}</div>

          <div style={{ float: 'left', marginLeft: '30px', width: '200px' }}>

            <LinkGraph data={icmp} />
            <font style={{ size: '10px' }}> {icmp.length === 0 ? 0 : icmp[0]} ms</font>
          </div>




        </Col>


      </Row>
    );
  }
}

class LinkGraph extends Component {
  constructor() {
    super();
    this.state = {
      color: '#33ABA0'
    };
  }

  componentWillReceiveProps(nextProps) {
    let arr = nextProps.data;
    let zarr = [];
    let x = 10;

    for (let j = 0; j < 6; j++) {
      let narr = [];
      let i = j * 10
      for (i; i < x; i++) {
        narr.push(arr[i]);
      }
      x = i + 10;
      zarr.push(narr.reduce((sum, a) => {
        return sum + a;
      }, 0) / (narr.length || 1));
    }
    let average = zarr.reduce((sum, a) => {
      return sum + a;
    }, 0) / (zarr.length || 1);

    var danger = 0;
    var warning = 0;
    zarr.map(function (val) {
      // if(val > nextProps.triggers.icmp.danger.value) danger++;
      //if(val > nextProps.triggers.icmp.warning.value) warning++;
      if (val > 90) danger++;
      if (val > 80) warning++;
    });

    if (warning == 6 && danger < 6) this.state.color = '#FFC107';
    else if (danger == 6) this.state.color = '#F44336';
    else this.state.color = '#33ABA0';
  }
  render() {
    let { data } = this.props;
    let { color } = this.state;


    return (
      <Sparklines style={{ width: '130px' }} data={data}>
        <SparklinesLine color={color} />
        <SparklinesSpots />
      </Sparklines>
    );
  }
}
