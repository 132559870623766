import React, { Component } from 'react';
import { Menu as IconMenu } from '@material-ui/icons';

import { Select, MenuItem } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactTooltip from 'react-tooltip';
import MdFilterList from 'react-icons/lib/md/filter-list';
import moment from 'moment';
import crypt from '../../util/crypt';
import MonitorUtils from '../../util/MonitorUtils';
import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import { Menu, IconButton } from '@material-ui/core';
import RaisedButton from '@common/RaisedButton';
let host = '';
let tokenApi = '';
var getConnection = '';
String.prototype.toHHMMSS = function () {
  var cur = parseInt(this, 10); // don't forget the second param

  let days = Math.floor(cur / 86400);
  cur -= days * 86400;
  let hours = Math.floor(cur / 3600) % 24;
  cur -= hours * 3600;
  let minutes = Math.floor(cur / 60) % 60;
  cur -= minutes * 60;
  let seconds = Math.floor(cur) % 60;

  var time = days + ' Dias ' + hours + ' Horas ' + minutes + ' Minutos ';
  return time;
};

export default class ServerConfig extends CommonPage {
  constructor() {
    super();
    this.state = {
      available: false,
      mount: true,
      hold: '',
      aliveHold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disk: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      removeloading: true,
      dateUnavailableServer: '',

      anchorEl: null,
      open: false,
    };
    this.handleDisk = this.handleDisk.bind(this);
    this.handleNet = this.handleNet.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    this.store.loading = true;
  }

  handleClick(event, v) {
    this.setState({ anchorEl: event.currentTarget, open: v });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  componentWillUnmount() {
    this.state.mount = false;
    clearTimeout(this.state.hold);

    window.clearInterval(window.myInterval);
  }

  handleDisk(event, index) {
    this.setState({ diskIndex: index });
  }
  handleNet(event, index) {
    this.setState({ netIndex: index });
  }

  render() {
    let {
      handlePage,
      onRestartAgent,
      language,
      _device,
      down,
      handleModal,
      onDettachDevice,
      connection,
    } = this.props;
    let {
      values,
      available,
      diskIndex,
      netIndex,
      memory,
      memoryUsed,
      memoryUsedPercent,
      memoryUsedDash,
      cpuUsedDash,
      cors,
      uptime,
      cpuPerc,
      procStats,
      disk,
      netStats,
      netStatsRx,
      netStatsTx,
      removeloading,
      dateUnavailableServer,
    } = this.state;

    //console.log(JSON.stringify(_device));

    if (!removeloading) {
      this.store.loading = true;
    } else {
      this.store.loading = false;
    }

    let dangerMem = 70;
    let warningMem = 90;
    let dangerCpu = 70;
    let warningCpu = 90;
    let dangerList = 70;
    let warningList = 90;

    let token = crypt(_device.id_device.toString());
    let { anchorEl, open } = this.state;
    return (
      <div className="col-action">
        {/*<MenuItem  value={0} >
                  <IconButton   className="configurationsIcon"
                    style={{ width: 30, height: 30, position:'left',clear:'both' }}
                  />
                </MenuItem>*/}

        <IconButton
          color="secondary"
          aria-owns={anchorEl ? 'USER' : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleClick(event, 'USER')}
          aria-label="Add an alarm"
        >
          <RaisedButton
            circleButton
            icon="fas fa-cog"
            style={{ width: 25, height: 25, fontSize: 15 }}
            label={this.props.online ? 'Online' : 'Offline'}
            color={this.props.online ? 'greenGradient' : 'danger'}
          />
        </IconButton>
        <Menu
          id="USER"
          anchorEl={anchorEl}
          open={open == 'USER' ? true : false}
          onClose={this.handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            primaryText={language.DEVICE_INFO}
            leftIcon={
              <div
                className="infoIcon"
                style={{ width: 35, height: 35, margin: '5px 12px' }}
              />
            }
            onClick={() => handlePage('summary', this.props)}
          >
            <IconButton
              className="infoIcon"
              style={{ width: 30, height: 30, margin: 9 }}
            />

            {language.DEVICE_INFO}
          </MenuItem>

          <MenuItem
            primaryText={language.SERVICE_INFORMATION}
            leftIcon={
              <div
                className="infoIcon"
                style={{ width: 35, height: 35, margin: '5px 12px' }}
              />
            }
            onClick={() => handlePage('services', this.props)}
          >
            <IconButton
              className="infoIcon"
              style={{ width: 30, height: 30, margin: 9 }}
            />
            {language.SERVICE_INFORMATION}
          </MenuItem>

          <MenuItem
            primaryText="Sensr Conf"
            leftIcon={
              <div
                className="configurationsIcon"
                style={{ width: 35, height: 35, margin: '5px 12px' }}
              />
            }
            onClick={() => handleModal({ msg: token })}
          >
            <IconButton
              className="configurationsIcon"
              style={{ width: 30, height: 30, margin: 9 }}
            />
            Sensr Conf
          </MenuItem>
          <MenuItem
            primaryText={language.UPDATE_AGENT}
            leftIcon={
              <div
                className="changeManagementIcon"
                style={{ width: 35, height: 35, margin: '5px 12px' }}
              />
            }
            onClick={() =>
              handleModal({
                msg: language.CONFIRM_UPDATE_AGENT,
                confirm: () => {
                  onRestartAgent(_device.id_device);
                  handleModal();
                },
              })
            }
          >
            <IconButton
              className="changeManagementIcon"
              style={{ width: 30, height: 30, margin: 9 }}
            />
            {language.UPDATE_AGENT}
          </MenuItem>

          <MenuItem
            primaryText={language.EDIT_CONFIGURATION}
            leftIcon={
              <div
                className="editIcon cardIcon"
                style={{ margin: '8px 12px' }}
              />
            }
            onClick={() =>
              handlePage('deviceConf', {
                ...this.props._device,
                id_brands: this.props.fk_id_brands,
                snmp: this.props.snmp,
              })
            }
          >
            <IconButton
              className="editIcon cardIcon"
              style={{ width: 30, height: 30, margin: 9 }}
            />
            {language.EDIT_CONFIGURATION}
          </MenuItem>
          <MenuItem
            primaryText={language.LEAVE_MONITOR}
            leftIcon={
              <div
                className="removeIcon"
                style={{
                  width: 35,
                  height: 35,
                  margin: '5px 12px',
                }}
              />
            }
            onClick={() =>
              handleModal({
                msg: language.CONFIRM_STOP_MONITOR,
                confirm: () => {
                  onDettachDevice(_device.id_device);
                  handleModal();
                },
              })
            }
          >
            <IconButton
              className="removeIcon"
              style={{ width: 30, height: 30, margin: 9 }}
            />
            {language.LEAVE_MONITOR}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
