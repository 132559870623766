import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import Tag from '@common/Tag';
import { projectList } from '../../Projects/PMO/jsonFormat';

import { Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import Button from '@common/Button';
import PageLayout from '../../../common/PageLayout';
import { Radial } from 'sensrit-charts';
import Constants from '../../../data/Constants';

import { Tooltip } from 'react-tippy';
import { Bar } from 'recharts';
import trendLine from '../graphs/trendLine';
import moment from 'moment';
import CommonPage from '../../../common/CommonPage';
import AppBar from '@common/AppBar2';

import TicketUtils from '../../../util/TicketUtils';
import GiGraph from './GiGraph';
import { Grap as GraphsGRC } from '../../riskmanagement/gestaoRisco_dash';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FilledInput,
  TextField,
  Chip,
  Avatar,
} from '@material-ui/core';
import Progress from '@common/Progress';
import List from '@common/List';
import ListColumn from '@common/ListColumn';
import Loading from '@common/Loading';
// import { Scrollbars } from 'react-custom-scrollbars';
import Animate from '@common/Animate';
import CommonTable from '@common/CommonTable';
import CommonForm from '@common/CommonForm2';
import MediumTime from './MediumTime';

import { flexBasis } from '@common/Func';

//---------------------------------imagens----------------------------------------------------

import Flat from '@images/flat-icon/tag.png';
import Synchronize from '@images/flat-icon/synchronize.png';
import Shout from '@images/flat-icon/shout.png';
import incidentManagementIcon from '@images/incidentManagementIcon.png';
import problemManagementIcon2 from '@images/problemManagementIcon2.png';

// -------------------------------------------------------------------------------------------
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import { Grid, Paper } from '@material-ui/core'; //comum
import { ListItemSecondaryAction, ListItemText } from '@material-ui/core'; //List
import { Toolbar, Typography } from '@material-ui/core'; //header
import AliceCarousel from 'react-alice-carousel';
import Store from '@data/Store';

import './Cio.css';
// -------------------------ReactHighcharts----------------------------
import ContainerChart from '../CIOPage.graph.js';
import ReactHighcharts from 'react-highcharts';
// -------------------------RadialGraph----------------------------
import RadialGraph from '../../../common/RadialGraph';
// -------------------------Gestão de contratos----------------------------
// import GestaoContratos from '../gestaoContratos/gestaoContratos.js';
// ---------------------------------------------------------(FUNCTION - Progress)---------------------------------------------------------------------------
// --------------------------------funções----------------------------------
import SimplesBarGraph from '@common/SimplesBarGraph';
import ContractSupplier from '../ContractSupplier';
import PieChart from '@common/PieChart';
// --------------------------------icons----------------------------------
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import SyncIcon from '@material-ui/icons/Sync';

import NewContracts from '../../GCF/NewContracts';

let maxTimeTicket = [];
let minTimeTicket = [];
let maxTImeRequest = [];

function GraphCircle(props) {
  return (
    <div className="graphs-item">
      {!!props.title && <span className="title">{props.title}</span>}
      <Radial
        low={{ color: '#33ABA0' }}
        mid={{ color: '#FFC107', value: 60 }}
        high={{ color: '#F44336', value: 80 }}
        className="host-graph-perc radial-graph-item-of-group"
        size={props.size || 60}
        current={props.current}
        total={100}
        thick={props.thick || 8}
      >
        <span
          className="d-flex align-items-center title-center-radial"
          style={props.currentText}
        >
          {!!props.icon && <i className={props.icon}></i>} {props.current}%
        </span>
      </Radial>
      {!!props.subTitle && <h4 className="sub-title">{props.subTitle}</h4>}
    </div>
  );
}

function GraphCircleVl(props) {
  return (
    <div className="graphs-item">
      {!!props.title && <span className="title">{props.title}</span>}
      <Radial
        low={{ color: '#33ABA0' }}
        mid={{ color: '#FFC107', value: 60 }}
        high={{ color: '#F44336', value: 80 }}
        className="host-graph-perc radial-graph-item-of-group"
        size={props.size || 60}
        current={props.current}
        total={100}
        thick={props.thick || 8}
      >
        <span
          className="d-flex align-items-center title-center-radial"
          style={props.currentText}
        >
          R$ {!!props.icon && <i className={props.icon}></i>} {props.current}
        </span>
      </Radial>
      {!!props.subTitle && <h4 className="sub-title">{props.subTitle}</h4>}
    </div>
  );
}
class ItemContracts extends Component {
  render() {
    let { label, percent, onClick, idx } = this.props;

    return (
      <div style={{ padding: '10px 0' }}>
        <Tooltip
          html={
            <div
              style={{
                color: '#fff',
                fontSize: 15,
              }}
            >
              <strong>{label}</strong>
            </div>
          }
          arrow={true}
          position="left"
          theme="dark"
          open={false}
        >
          <Chip
            avatar={
              <Avatar
                style={{
                  backgroundColor: 'white',
                  width: 45,
                  height: 45,
                  marginLeft: -1,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <PieChart
                    textProps={{
                      style: { fontSize: (percent < 100 ? 0.6 : 0.5) + 'em' },
                    }}
                    percent={percent}
                  />
                </div>
              </Avatar>
            }
            className={'chip ItemContracts shadown ' + (idx ? 'active' : '')}
            onClick={onClick}
            style={{ maxWidth: '100%' }}
            label={
              <span className="ellipsis" style={{ display: 'block' }}>
                {label}
              </span>
            }
          />
        </Tooltip>
      </div>
    );
  }
}

function CardGsdItem({
  name,
  icon,
  total,
  closed,
  open,
  currentRef,
  color = 'var(--success)',
  onClick,
  language,
}) {
  return (
    <Grid item xs={10} style={{ maxWidth: '20%', flexBasis: '20%' }}>
      <Card className="card content pb-2" onClick={onClick}>
        <div className="indicator-icon" style={{ backgroundColor: color }}>
          <div className={icon + ' img'} />
        </div>
        <div className="data-text">
          <p className="font">{name}</p>
          <div className="content-number pt-2">
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>Total</strong>
                </div>
              }
              arrow={true}
              position={'right'}
              theme="dark"
            >
              <h5 className="mr-4">{total}</h5>
            </Tooltip>
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.TICKET_STATUS.CLOSED}</strong>
                </div>
              }
              arrow={true}
              position={'right'}
              theme="dark"
            >
              <h5 className="mr-4">{closed}</h5>
            </Tooltip>
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.TICKET_STATUS.OPEN}</strong>
                </div>
              }
              arrow={true}
              position={'right'}
              theme="dark"
            >
              <h4>{open}</h4>
            </Tooltip>
          </div>
        </div>
        <div className="pt-3 data-progress">
          <Progress
            background={color}
            current={currentRef}
            hiddenText={true}
            style={{ height: 6 }}
            className="mb-3"
          />
          <p className="d-flex align-items-center justify-content-between">
            <span style={{ fontSize: '12px' }}>
              {language.DASH.SLA_BURST_CLOSED}
            </span>
            <span>({currentRef || 0}%)</span>
          </p>
        </div>
      </Card>
    </Grid>
  );
}

@observer
export default class CIOPage extends CommonPage {
  constructor() {
    super();
    this.state = {
      companyId: 1,
      currentYear: moment().format('YYYY'),
      month: moment().format('MM'),
      fk_id_company : 1,
      c_id: null,
      c_open: false,
      refresh: null,
      countRed: [],
      countYellow: [],
      countOk: [],
      countTotal: [],
      countRedList: [],
      countYellowList: [],
      countOkList: [],
      tpmedio: 1,
      hold: '',
      series: [],
      TotalHoursConsumedProps: [],
      grap: [],
      typeContract: null,
      contract: null,
      idx: 0,
      dataProject: [],
      dataProjectCache: [],
    };

    this.handleModal = this.handleModal.bind(this);
    this.expandContract = this.expandContract.bind(this);
    this.calc = this.calc.bind(this);
    this.matrizRisk = this.matrizRisk.bind(this);
  }

  legendItemClick = (name) => {
    // evt.preventDefault();

    // var name = evt.target.name;

    var series = this.state.series;
    var visible;
    series = series.map((e) => {
      visible = e.name != name ? false : true;
      return Object.assign({}, e, { visible });
    });
    this.setState({ series });
    return false;
  };
  getAllPercentualStatus = (datas, status, obj = false) => {
    try {
      var data = ['open', 'late', 'progress', 'Gmud'].reduce((obj, item) => {
        var data = datas.reduce((i, data) => {
          i += (data.find((e) => e[item] != undefined) || {})[item] || 0;
          return i;
        }, 0);

        obj[item] = data;
        return obj;
      }, {});

      var value = Object.values(data).reduce((e, i) => e + i, 0);

      if (obj) {
        return data;
      } else {
        return Number(((data[status] / value) * 100).toFixed(0));
      }
    } catch (e) {
      return 0;
    }
  };
  expandContract(type, contract) {
    // pequeno algorítimo abaixo responsável por expandir o modal;
    var c_id = this.state.c_id;

    if (c_id != contract.id_contract) {
      c_id = contract.id_contract;
    } else {
      c_id = null;
    }

    this.setState({ c_id: c_id });
  }
  calcHour(obj) {
    if (obj.sum) {
      let { hours = 0, minutes = 0, seconds = 0 } = obj.sum;

      return hours + minutes / 60 + seconds / 60 / 60;
    } else {
      return 0;
    }
  }

  handleFilterConsumed(obj, date) {
    let value =
      (obj.filter((v) => v.to_char === date).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHour).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHour)[0]) ||
      0;
    return value;
  }

  calcHour(obj) {
    if (obj.sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = obj.sum;

      return days * 24 + hours + minutes / 60 + seconds / 60 / 60;
    } else {
      return 0;
    }
  }

  toogleModalContract = (open, id_contract = null) => {

    var c_id = id_contract;

    this.action
      .execute(
        'post',
        this.APIEndpoints.CONTRACT + '/getContractConsumed',
        '',
        { id_contract: c_id }
      )
      .then((response) => {
        let contract = response.data;
        this.setState({ contract: response.data });

        if (contract.contract[0].type == 'hour') {
          let hours_consumer_assessment = 0;
          let hours_consumer_os = 0;
          let hours_gmud = 0;
          let hours_project = 0;
          let hours_tickets = 0;
          let hours_total_consumer = 0;

          if (contract) {
            let monthYear = moment().format('MMYY');
            hours_consumer_assessment = this.handleFilterConsumed(
              contract.assessment,
              monthYear
            );
            hours_consumer_os = this.handleFilterConsumed(
              contract.osconsumed,
              monthYear
            );
            hours_gmud = this.handleFilterConsumed(
              contract.changesConsumed,
              monthYear
            );
            hours_project = this.handleFilterConsumed(
              contract.projectConsumed,
              monthYear
            );
            hours_tickets = this.handleFilterConsumed(
              contract.ticketconsumed,
              monthYear
            );
            hours_total_consumer =
              hours_consumer_assessment +
              hours_consumer_os +
              hours_gmud +
              hours_project +
              hours_tickets +
              hours_total_consumer;
          }
          let contractComput = contract;
          let year = moment().format('YY');

          let data = Array.from(new Array(12), (_, i) => {
            let date = (i + 1).toString().padStart(2, '0') + year;
            let currentConsumed =
              (this.handleFilterConsumed(contractComput.assessment, date) +
                this.handleFilterConsumed(
                  contractComput.changesConsumed,
                  date
                ) +
                this.handleFilterConsumed(contractComput.os, date) +
                this.handleFilterConsumed(contractComput.osconsumed, date) +
                this.handleFilterConsumed(
                  contractComput.projectConsumed,
                  date
                ) +
                this.handleFilterConsumed(contractComput.realizado, date) +
                this.handleFilterConsumed(
                  contractComput.ticketconsumed,
                  date
                )) *
              parseFloat(contractComput.contract[0].value);

            return currentConsumed;
          });

          this.setState({
            TotalHoursConsumedProps: [
              {
                label: language.REMOTE_ACCESS,
                current: hours_consumer_assessment.toFixed(2),
                color: 'var(--primary)',
              },
              {
                label: language.SERVICE_ORDER,
                current: hours_consumer_os.toFixed(2),
                color: 'var(--success)',
              },
              {
                label: language.GMUD,
                current: hours_gmud.toFixed(2),
                color: 'var(--warning)',
              },
              {
                label: language.PROJECT,
                current: hours_project.toFixed(2),
                color: 'var(--danger)',
              },
              {
                label: language.TICKETS,
                current: hours_tickets.toFixed(2),
                color: 'var(--purple)',
              },
              {
                label: language.TOTAL_HOURS_CONSUMED,
                current: hours_total_consumer.toFixed(2),
                color: 'var(--primary)',
              },
            ],
          });
        }
      });

    this.setState({ c_id: c_id, c_open: open });
  };
  handleModal(values) {}

  componentWillMount() {
    //Mount graphs
    this.action.execute(
      'get',
      this.APIEndpoints.CONTRACT + 'painel',
      'contracts'
    );

    this.action.execute(
      'get',
      `${this.APIEndpoints.RISK}/GetDashDetailMaturity/${this.props.companyId}`,
      ''
    )
      .then((results) => {
        if( results.data ) {
          results = results.data;
        }

        if (!!results.length) {
          var data = {};
          var result;
          var count;
          var to_char;
          for (var i in results) {
            result = results[i];
            count = parseInt(result.count);
            to_char = result.to_char;

            if (!!!data[result.name]) {
              data[result.name] = {
                current_maturity: [],
                planned_maturity: [],
                to_char: [],
              };
            }

            data[result.name].current_maturity.push(
              parseInt(result.current_maturity) || 0
            );
            data[result.name].planned_maturity.push(
              parseInt(result.planned_maturity) || 0
            );
            data[result.name].to_char.push(to_char);
          }

          data = Object.keys(data)
            .filter((item) => item !== 'GESTÃO DE BACKUP')
            .map((e) => {
              return { title: e, ...data[e] };
            });

          this.setState({ grap: data });
        }
      });
  }

  calculateTimeInMinute(cur) {
    let days,
      hours,
      minutes,
      seconds = 0;

    days = Math.floor(cur / 86400);
    cur -= days * 86400;
    hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    seconds = Math.floor(cur) % 60;
    hours = days * 24 + hours;

    if (hours < 0) hours = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return hours * 60 + minutes;
  }

  calcSla(obj, type, language, req, slatimeCatalog) {
    let ini = moment().unix();
    let end = moment().unix();
    let now = moment().unix();
    let limitDate = moment().unix();
    let returnSlaDelayed = false;
    let delayed = false;
    let cur = 0;
    let color = 'var(--green)';
    let dt = 0;

    if (req != undefined && req.length == 1) {
      dt = moment(moment(req[0].dt_up).diff(moment(req[0].dt_cad))).unix();
    }

    if (type === 'request') {
      ini = moment(obj.dt_cad).unix();
      limitDate = moment(obj.dt_cad).add(obj.sla, 'h').unix();
      end = moment().unix();

      if (now < limitDate) {
        cur = ini - end;
        color = '';
      } else {
        color = 'var(--red)';
        cur = end - ini;
        delayed = true;
      }
    } else {
      if (obj.status === 'Open') {
        ini = moment(obj.dt_cad).unix();
        end = moment(obj.dt_cad).add(obj.time_solution, 'h').unix();
        limitDate = moment(obj.dt_cad).add(obj.time_solution, 'h').unix();

        color = 'var(--secundary)';
        cur = moment().unix() - ini;

        let newhour = cur + dt;

        let minutes = this.calculateTimeInMinute(newhour);
        let limit = slatimeCatalog * 60;

        if (obj.id_tickets == 99) {
        }

        if (
          parseInt((minutes / limit) * 100) > 70 &&
          parseInt((minutes / limit) * 100) < 100
        ) {
          color = 'var(--secundary)';
        } else {
          color = 'var(--red)';
        }

        if (parseInt((minutes / limit) * 100) < 10) {
          color = 'var(--green)';
        }

        if (moment().unix() < limitDate) {
          cur = limitDate - moment().unix();
        } else {
          delayed = true;
        }
      } else if (
        obj.status === 'In Progress' ||
        obj.status === 'Resolve' ||
        obj.status === 'Resolved' ||
        obj.status === 'Closed'
      ) {
        if (true) {
          ini = moment(obj.dt_cad).unix();
          end = moment(obj.dt_up).unix();
          limitDate = moment(obj.dt_cad).add(obj.time_response, 'h').unix();

          if (moment().unix() < limitDate) {
            cur = limitDate - moment().unix();
          } else {
            color = 'var(--red)';
            cur = moment().unix() - ini;
            delayed = true;
          }

          if (obj.status !== 'In Progress') {
            returnSlaDelayed = true;
          }
        } else {
          return 'Without Workflow';
        }
      } else {
        return 'SLA';
      }
    }
    return {
      color,
    };
  }

  getPoint(id) {
    let project = Store.projects.filter((project) => project.id_project == id);
    let point = 0;
    try {
      point =
        (project[0].desire_directors +
          project[0].strategic_alignment +
          project[0].benefit_amount +
          project[0].processes_improvement +
          project[0].financial_feedback) /
          5 +
        (
          (project[0].generates_costs +
            project[0].risk_if_not_done +
            project[0].urgency +
            project[0].complexity +
            project[0].involved_areas) /
          5
        ).toFixed(0);
    } catch (e) {
      point = 0;
    }

    return point;
  }

  async componentDidMount() {
    var requestGSD = [];

    let host = Constants.APIRoot;
    let tokenApi = this.store.getToken();
    const users = this.store.users;
    const language = Store.language;

    let risks = {
      1: {
        label: language.LOW,
        color: 'var(--primary)',
      },
      2: {
        label: language.MEDIUM,
        color: 'var(--warning)',
      },
      3: {
        label: language.HIGH,
        color: 'var(--danger)',
      },
    };

    this.action.execute(
      'get',
      `${this.APIEndpoints.PROJECT + '/pmo'}`,
      ''
    )
      .then(async (result) => {
        if( result.data ) {
          result = result.data;
        }
        const newResult = result?.pmos?.map((pmo) => ({
          ...pmo,
          priority: risks[pmo.type_risk],

          percent: parseInt(pmo.percent) || 0,

          status:
            pmo.utils.awaitExecution && !pmo.deleted
              ? language.AWAITING_EXECUTION
              : pmo.utils.inExecution && !pmo.deleted
              ? language.RUNNING
              : pmo.utils.isDelay && !pmo.deleted
              ? language.LATE
              : pmo.utils.concluded
              ? language.CONCLUDED
              : pmo.utils.concludedDelay
              ? language.COMPLETED_LATE
              : (pmo.utils.isDelay ||
                  pmo.utils.inExecution ||
                  pmo.utils.awaitExecution) &&
                pmo.deleted
              ? language.CANCELED
              : null,
          manager:
            users.find((u) => parseInt(u.id_user) == parseInt(pmo.user_cad)) ||
            {},
        }));
        this.setState({
          dataProject: newResult,
        });
        this.setState({ dataProjectCache: newResult });
      })
      .catch((er) => console.log('erro', er));
  }

  getMilestoneSetor = (values) => {
    var data = this.props.milestone.filter((ob) => ob.fk_id_project === values);

    return ['DONE', 'STOPPED', 'TODO', 'DOING'].reduce((e, i) => {
      var dataStatus = this.props.milestone.filter(
        (ob) => ob.fk_id_project === values && ob.status == i
      );
      var percent = (dataStatus.length / data.length) * 100;
      percent = Number(percent.toFixed(0));
      e[i] = percent;
      return e;
    }, {});
  };

  getPercentRef = (e, key) => {
    var itemsSum = Object.values(e).reduce((sum, item) => {
      return sum + (!!item.length ? item[0].total : 0);
    }, 0);

    var percent = (e[key].length ? e[key][0].total : 0) / itemsSum;
    return (percent = (percent * 100).toFixed(0));
  };

  delayticket(ticket) {
    let proc = false;
    let req = null;
    let sla = null;
    let { language } = this.props;
    if (ticket.solicit) {
      req = [
        {
          dt_up: ticket.dtuprequest,
          dt_cad: ticket.dtcadrequest,
          id_request: 1,
        },
      ];
    }

    if (
      (ticket.tp == 'ticket' && ticket.status == 'Open') ||
      ticket.status == 'In Progress'
    ) {
      sla = TicketUtils.calcSla(
        ticket,
        'ticket',
        language,
        req,
        ticket.sla_task
      );
      if (sla.color == 'var(--red)') {
        return true;
      } else {
        return false;
      }
    } else if (ticket.status == 'Closed' || ticket.status == 'Resolved') {
      sla = TicketUtils.stopSla(ticket, null, language, req, ticket.sla_task);

      if (sla.color == 'var(--red)') {
        return true;
      } else {
        return false;
      }
    } else if (ticket.tp == 'request') {
      sla = TicketUtils.calcSla(ticket, 'request');
      if (sla.color == 'var(--red)') {
        return true;
      } else {
        return false;
      }
    }

    return proc;
  }
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  grouptech(tickets) {
    let f = 0;
    let arrayItem = [];
    let arr =
      tickets == undefined
        ? []
        : tickets.map((c) => {
            return c.tech_start;
          });
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let arrayOfObjs = a.map(function (d, i) {
      return {
        label: d,
        data: b[i] || 0,
      };
    });

    arrayOfObjs = arrayOfObjs.sort(this.dynamicSort('data'));
    a = [];
    b = [];

    arrayOfObjs.reverse().map((c) => {
      f++;
      if (f <= 10) arrayItem.push({ name: c.label, y: c.data });
    });
    return arrayItem;
  }

  groupCategory(tickets) {
    let f = 0;
    let arrayItem = [];
    let arr =
      tickets == undefined
        ? []
        : tickets.map((c) => {
            return c.name_service;
          });
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let arrayOfObjs = a.map(function (d, i) {
      return {
        label: d,
        data: b[i] || 0,
      };
    });

    arrayOfObjs = arrayOfObjs.sort(this.dynamicSort('data'));
    a = [];
    b = [];

    arrayOfObjs.reverse().map((c) => {
      f++;
      if (f <= 10) arrayItem.push({ name: c.label, y: c.data });
    });
    return arrayItem;
  }

  groupDepartment(tickets) {
    let f = 0;
    let arrayItem = [];
    let arr =
      tickets == undefined
        ? []
        : tickets.map((c) => {
            return c.name_depart;
          });
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let arrayOfObjs = a.map(function (d, i) {
      return {
        label: d,
        data: b[i] || 0,
      };
    });

    arrayOfObjs = arrayOfObjs.sort(this.dynamicSort('data'));
    a = [];
    b = [];

    arrayOfObjs.reverse().map((c) => {
      f++;
      if (f <= 10) arrayItem.push({ name: c.label, y: c.data });
    });
    return arrayItem;
  }
  groupCompany(tickets) {
    let arrayStatus = { Incident: [], Solicitation: [], Problem: [] };
    let f = 0;

    let arrayItem = [];
    let arr =
      tickets == undefined
        ? []
        : tickets.map((c) => {
            return c.name_company;
          });
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let arrayOfObjs = a.map(function (d, i) {
      return {
        label: d,
        data: b[i] || 0,
      };
    });

    arrayOfObjs = arrayOfObjs.sort(this.dynamicSort('data'));
    a = [];
    b = [];

    arrayOfObjs.reverse().map((c) => {
      f++;
      if (f <= 10) {
        arrayItem.push({ name: c.label, y: c.data });
        arrayStatus.Incident.push(
          tickets.filter(
            (t) => t.name_company === c.label && t.type_tickets == 1
          ).length
        );
        arrayStatus.Solicitation.push(
          tickets.filter(
            (t) => t.name_company === c.label && t.type_tickets == 4
          ).length
        );
        arrayStatus.Problem.push(
          tickets.filter(
            (t) => t.name_company === c.label && t.type_tickets == 2
          ).length
        );
      }
    });

    return {
      arrayItem,
      arrayStatus,
    };
  }

  groupPrioridade(tickets) {
    let arrayItem = [];
    let arr =
      tickets == undefined
        ? []
        : tickets.map((c) => {
            return c.priority;
          });
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let arrayOfObjs = a.map(function (d, i) {
      return {
        label: d,
        data: b[i] || 0,
      };
    });

    arrayOfObjs = arrayOfObjs.sort(this.dynamicSort('data'));
    a = [];
    b = [];

    arrayOfObjs.reverse().map((c) => {
      arrayItem.push({ name: c.label, y: c.data });
    });

    return arrayItem;
  }
  groupNPS(tickets) {
    let avaliation = ['Excelente', 'Otimo', 'Bom', 'Ruim', 'Péssimo'];

    let arrayItem = [];
    let arr =
      tickets == undefined
        ? []
        : tickets.map((c) => {
            return c.avaliation_level;
          });
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let arrayOfObjs = a.map(function (d, i) {
      return {
        label: d,
        data: b[i] || 0,
      };
    });

    arrayOfObjs = arrayOfObjs.sort(this.dynamicSort('data'));
    a = [];
    b = [];

    arrayOfObjs.reverse().map((c) => {
      arrayItem.push({ name: avaliation[c.label], y: c.data });
    });
    return arrayItem;
  }

  sumAverage() {}

  toHour_(seconds) {
    var d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';

    var hourComplete = hDisplay + mDisplay + sDisplay;

    return hourComplete;
  }
  toHour(seconds) {
    var d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';

    var hourComplete = h;
    return hourComplete;
  }

  toSecond(hms) {
    try {
      //hms = '02:04:33';   // your input string
      var a = hms.split(':'); // split it at the colons

      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      return seconds;
    } catch (r) {
      return 0;
    }
  }

  av(tickets) {
    let sum = 0;
    let cont = 0;
    let _max = 0;
    let _min = 0;
    let c = 1;
    tickets != undefined &&
      tickets.map((g) => {
        sum += this.toSecond(g.time_resolution);
        cont++;

        let se = this.toSecond(g.time_resolution);
        if (c == 1) {
          _max = se;
          _min = se;
          c = 2;
        } else {
          if (se > _max) {
            _max = se;
          }
          if (se < _min) {
            _min = se;
          }
        }
      });

    return {
      hour: this.toHour(sum / cont),
      hourtext: this.toHour_(sum / cont),
      max: this.toHour(_max / 1),
      min: this.toHour(_min / 1),
    };
  }

  delayAllTickets(tickets) {
    let count = 0;
    tickets != undefined &&
      tickets.map((g) => {
        if (this.delayticket(g)) {
          count++;
        }
      });

    return count;
  }

  matrizRisk() {
    let { language } = this.props;
    let color = ['var(--success)', 'var(--warning)', 'var(--danger)'];
    //let data = ['Aceitável', 'Crítico', 'Intolerável'].map((g, i) => {
    let data = [language.LOW, language.MEDIUM, language.HIGH].map((g, i) => {
      return {
        name: g,
        type: 'column',
        color: color[i],
        data: this.calc(g),
      };
    });
    return data;
  }

  calc(item) {
    let { matriz } = this.props;
    let arrayItem = [0, 0, 0];
    let c = 0;
    Array.from([-2, -1, 0]).map((i) => {
      let mes = moment().add(i, 'M').format('YYMM');

      matriz
        .filter((g) => moment(g.dt_lanc).format('YYMM') == mes)
        .map((g) => {
          //if (item === 'Aceitável') {
          if (item === 'Baixo') {
            let pontuacao = g.probability + g.impact;

            if (pontuacao >= 0 && pontuacao <= 4) {
              arrayItem[c] = arrayItem[c] + 1;
            }
          }

          //if (item === 'Crítico') {
          if (item === 'Médio') {
            let pontuacao = g.probability + g.impact;

            if (pontuacao >= 5 && pontuacao <= 6) {
              arrayItem[c] = arrayItem[c] + 1;
            }
          }

          //if (item === 'Intolerável') {
          if (item === 'Alto') {
            let pontuacao = g.probability + g.impact;

            if (pontuacao >= 7 && pontuacao <= 8) {
              arrayItem[c] = arrayItem[c] + 1;
            }
          }
        });
      c++;
    });
    return arrayItem;
  }

  avRequest(tickets) {
    let sum = 0;
    let cont = 0;
    let c = 1;
    let max = 0;
    let min = 0;
    tickets != undefined &&
      tickets.map((g) => {
        var now = moment(g.dtcadr); //todays date
        var end = moment(g.dtupr); // another date

        if (g.dtupr == null) {
          end = moment();
        }

        var duration = moment.duration(end.diff(now));

        var second = duration.asSeconds();
        if (c == 1) {
          c = 2;
          max = second;
          min = second;
        } else {
          if (second > max) {
            max = second;
          }
          if (second < min) {
            min = second;
          }
        }
        sum += second;
        cont++;
      });

    return {
      hour: this.toHour(sum / cont),
      hourtext: this.toHour_(sum / cont),
      max: this.toHour(max / 1),
      min: this.toHour(min / 1),
    };
    //return  this.toHour(sum /cont)
  }

  filterCompany(company) {
    this.setState({
      dataProject: this.state.dataProjectCache?.filter(
        (dp) => dp.fk_id_company == company
      ),
    });
  }

  handleContract(value) {

    let month = moment().month();
    let formatDate = moment(
      month.toString().padStart(2, '0') + moment().format('YY'),
      'MMYYYY'
    ).format('MMYYYY');
    this.action
      .execute(
        'post',
        this.APIEndpoints.CONTRACT + '/getContractConsumed',
        '',
        { id_contract: value, dateFilter: formatDate }
      )
      .then((response) => {

        this.setState({ contract: response.data });
      });
  }

  getContractCompany(value) {
    value = (typeof value === 'number' ? [value] : value)

    let contracts = [];
     this.store.contracts.filter(
       (contract) => {
           contract.fk_id_out_company == value
           value.map((c) => {

             if(contract.fk_id_out_company === parseInt(c))
             {
                contracts.push(contract)
             }
           })
       }
     );

    if (contracts[0]) {
      return contracts[0].id_contract;
    } else {
      return 0;
    }
  }

  computNewContracts(contract) {
    let year = moment().format('YY');

    if (contract) {
      let { name, trend } = contract;

      let previstoatual =
        contract.hours != 0
          ? parseFloat(contract.hours) * parseFloat(contract.value)
          : parseFloat(contract.value);

      let valuerealized = [];
      let consumeds = [];
      trend.map((e) => {
        let splited = e[0].split('/');
        splited[0] = parseInt(splited[0]).toString().padStart(2, '0');
        let mmYYYY = splited.join('/');

        try {
          if (
            contract.contract_month != undefined &&
            contract.contract_month != null &&
            contract.contract_month.filter((c) => c.period == mmYYYY)
          ) {
            let valor = contract.contract_month.filter(
              (c) => c.period == mmYYYY
            )[0];

            valuerealized.push(parseFloat(valor.valor));
          } else {
            valuerealized.push(0);
          }
        } catch (r) {
          valuerealized.push(0);
        }
      });

      let contractComput = this.state.contract;

      if (contract.type === 'hour') {
        let data = Array.from(new Array(12), (_, i) => {
          let date = (i + 1).toString().padStart(2, '0') + year;
          let currentConsumed =
            (this.handleFilterConsumed(contractComput.assessment, date) +
              this.handleFilterConsumed(contractComput.changesConsumed, date) +
              this.handleFilterConsumed(contractComput.os, date) +
              this.handleFilterConsumed(contractComput.osconsumed, date) +
              this.handleFilterConsumed(contractComput.projectConsumed, date) +
              this.handleFilterConsumed(contractComput.ticketconsumed, date)) *
            parseFloat(contractComput.contract[0].value);

          currentConsumed = parseFloat(currentConsumed.toFixed(2));
          return currentConsumed;
        });
        consumeds = data;
      } else {
        let data = Array.from(new Array(12), (_, i) => {
          let date = (i + 1).toString().padStart(2, '0') + year;
          let currentConsumed =
            this.handleFilterConsumed(contractComput.assessment, date) +
            this.handleFilterConsumed(contractComput.changesConsumed, date) +
            this.handleFilterConsumed(contractComput.os, date) +
            this.handleFilterConsumed(contractComput.osconsumed, date) +
            this.handleFilterConsumed(contractComput.projectConsumed, date) +
            this.handleFilterConsumed(contractComput.ticketconsumed, date);

          currentConsumed = parseFloat(currentConsumed.toFixed(2));
          return currentConsumed;
        });
        consumeds = data;
      }

      return [valuerealized.reverse(), consumeds, previstoatual || 0];
    } else {
      //previsto
      let previstoAtual = this.store.contracts.reduce((prev, actual) => {
        if (actual.hours !== 0) {
          return prev + parseInt(actual.hours) * parseFloat(actual.value);
        }

        return prev + parseFloat(actual.value);
      }, 0);

      //realized
      let valueRealized = {};
      this.store.contracts.forEach((contract) => {
        let { trend } = contract;
        trend.map((t) => {
          let splited = t[0].split('/');
          splited[0] = parseInt(splited[0]).toString().padStart(2, '0');
          let mmYYYY = splited.join('/');

          try {
            if (
              contract.contract_month != undefined &&
              contract.contract_month != null &&
              contract.contract_month.filter((c) => c.period == mmYYYY)
            ) {
              let contractMonth = contract.contract_month.filter(
                (c) => c.period == mmYYYY
              )[0];

              if (
                valueRealized[contractMonth.period] &&
                valueRealized[contractMonth.period].period == mmYYYY
              ) {
                let value = !!contractMonth.valor
                  ? parseFloat(contractMonth.valor)
                  : 0;
                valueRealized[contractMonth.period].valor =
                  parseFloat(valueRealized[contractMonth.period].valor) + value;
              } else {
                valueRealized[contractMonth.period] = contractMonth;
              }
            } else {
              if (!valueRealized[mmYYYY]) {
                valueRealized[mmYYYY] = {
                  valor: 0,
                  period: mmYYYY,
                };
              }
            }
          } catch (r) {
            if (!valueRealized[mmYYYY]) {
              valueRealized[mmYYYY] = {
                valor: 0,
                period: mmYYYY,
              };
            }
          }
        });
      });
      let valuesRealized = Object.values(valueRealized).map((x) => x.valor);

      //consumeds
      let consumeds;
      let dataConsumeds = Array.from(new Array(12), (_, i) => {
        let date = (i + 1).toString().padStart(2, '0') + year;
        let currentConsumed =
          this.handleFilterConsumed(this.state.contract.assessment, date) +
          this.handleFilterConsumed(this.state.contract.changesConsumed, date) +
          this.handleFilterConsumed(this.state.contract.os, date) +
          this.handleFilterConsumed(this.state.contract.osconsumed, date) +
          this.handleFilterConsumed(this.state.contract.projectConsumed, date) +
          this.handleFilterConsumed(this.state.contract.ticketconsumed, date);

        currentConsumed = parseFloat(currentConsumed.toFixed(2));
        return currentConsumed;
      });
      consumeds = dataConsumeds;

      return [valuesRealized.reverse(), consumeds, previstoAtual || 0];
    }
  }

  render() {
    let {
      language,
      itsm,
      contracts,
      tests,
      top10,
      gsdCount,
      handlePage,
      projects,
      warning,
      danger,
      monitor,
      companies,
      statusProject,
      companyMonitors,
      devices,
      handleCompany,
      companyId,
      currentYear,
      fk_id_company,
      month,
      dashboard,
      dashCount,
      matrizproblem,
      matrizmonth,
      matriz,
      devicesAvailable,
    } = this.props;
    let { dt_i, dt_f, solicitant, type, manager, company, status } = this.state;

    let btnStatus = {
      3: {
        icon: 'fas fa-wrench',
        color: 'primary',
        label: language.CORRECTIVE,
      },
      1: {
        icon: 'fas fa-compass',
        color: 'info',
        label: language.STRATEGIC,
      },
      5: {
        icon: 'fas fa-gavel',
        color: 'danger',
        label: language.LEGAL_OBLIGATION,
      },
      4: {
        icon: 'fas fa-chart-line',
        color: 'success',
        label: language.EVOLUTIONARY,
      },
      2: {
        icon: 'fas fa-dumbbell',
        color: 'purple',
        label: language.IMPROVEMENT,
      },
    };

    const projectsStore = Store.projects;
    const getPoint = (id) => {
      let project = projectsStore.filter((project) => project.id_project == id);
      let point = 0;
      try {
        point =
          (project[0].desire_directors +
            project[0].strategic_alignment +
            project[0].benefit_amount +
            project[0].processes_improvement +
            project[0].financial_feedback) /
            5 +
          (
            (project[0].generates_costs +
              project[0].risk_if_not_done +
              project[0].urgency +
              project[0].complexity +
              project[0].involved_areas) /
            5
          ).toFixed(0);
      } catch (e) {
        point = 0;
      }

      return point;
    };

    let pmos = this.store.pmos.filter((e) => {
      let errors = [];

      if (dt_i) {
        errors.push(
          moment(e.start_date).isBetween(
            dt_i || Date.now() / 1000,
            dt_f || Date.now() * 1000,
            undefined,
            '[]'
          )
        );
      }
      if (dt_f) {
        errors.push(
          moment(e.end_date).isBetween(
            dt_i || Date.now() / 1000,
            dt_f || Date.now() * 1000,
            undefined,
            '[]'
          )
        );
      }

      if (status > 0) {
        status == 1
          ? errors.push(e.percent == null || e.percent < 100)
          : status == 2
          ? errors.push(e.utils.isDelay == true)
          : status == 3
          ? errors.push(e.percent == 100)
          : status == 4
          ? errors.push(e.utils.concludedDelay == true)
          : status == 5
          ? errors.push(e.utils.inExecution == true)
          : '';
      }

      if (type) {
        errors.push(e.type_project == type);
      }

      if (manager) {
        errors.push(e.user_cad == manager);
      }

      if (company) {
        errors.push(e.fk_id_company == company);
      }

      return errors.every(Boolean);
    });

    let isBlock_1 = false;

    let users = this.store.users;

    let dataProject = pmos.map((e) => {
      let activitiesTags = [
        ...e.utils.DONE,
        ...e.utils.BACKLOG,
        ...e.utils.DOING,
        ...e.utils.STOPPED,
        ...e.utils.VALIDATION,
        ...e.utils.TODO,
      ]
        .flatMap((e) => e)
        .reduce(
          (obj, a) => {
            (a.tagac || []).forEach((tag) => {
              let index = obj.findIndex(
                (t) => t.id_project_tag == tag.id_project_tag
              );
              try {
                obj[index].data.push(activitiesList({ e, a, users }));
              } catch (e) {}
            });

            return obj;
          },
          (e.tagpj || []).map((e) => ({
            id_project_tag: e.id_project_tag,
            name: e.name,
            color: e.color,
            data: [],
          }))
        );

      return projectList({ e, activitiesTags, users });
    });

    setTimeout(() => {
      //  this.setState({ dataProject });
    }, 5000);

    let { CIO_COCKPIT, COMMON_PAGE } = this.store.theme;
    let { demand_management, average_availability } = CIO_COCKPIT;

    let cpu = [];
    let ticketsGSD = [];
    let requestGSD = [];
    let gmudGSD = [];

    let idCompany = Array.isArray(this.props.companyId) ? this.props.companyId : [this.props.companyId]

    if (this.props.companyId != '') {
      itsm = itsm
        .filter(
          (c) => (idCompany || []).map((id) =>
          c.id_company === id ||
          c.fk_id_out_company === id
          )
        )
        .map((c) => {

          return c;
        });

      gmudGSD =
        gsdCount.ticketsGSD &&
        gsdCount.changesGSD
          .filter((c) => (idCompany || []).map((id) => c.fk_id_company === id))
          .map((c) => {
            return c;
          });

      projects = (projects || [])
        .filter(
          (c) => (idCompany || []).map((id) =>
            c.fk_id_company === id &&
            c.deleted == false &&
            c.percent < 100
        ))
        .map((c) => {
          return c;
        });


    } else {
      ticketsGSD = gsdCount.ticketsGSD && gsdCount.ticketsGSD;
      projects = (projects || []).filter((g) => g.deleted == false && g.percent < 100);

      gmudGSD = gsdCount.changesGSD;
    }


    let listCompanies = [].map((c) => (
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={c.id_company}
        value={c.id_company ? c.id_company : ''}
        primaryText={c.name}
      >
        {c.name}
      </MenuItem>
    ));
    listCompanies.push(
      <MenuItem
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
        key={0}
        value={0 ? 0 : 0}
        primaryText={language.ALL}
      >
        {language.ALL}
      </MenuItem>
    );
    let giNormal = 0;
    let giDanger = 0;
    let giWarning = 0;
    let giNormalList = [];
    let giDangerList = [];
    let giWarningList = [];

    this.state.countOkList.length > 0 &&
      this.state.countOkList
        .filter((h) => h.id_company == this.props.companyId)
        .map((d) => {
          let cls =
            d.key == 'fs-list'
              ? 'flaticon-data-storage4  font'
              : d.key == 'cpu-status'
              ? 'flaticon-cpu5 font'
              : 'flaticon-random font';
          giNormalList.push({
            grid: [
              {
                col: 9,
                content: <span className={cls}> {d.name} </span>,
              },
              {
                col: 3,
                content: <div className="font">{parseInt(d.value)}%</div>,
              },
            ],
          });
        });
    this.state.countOkList.length > 0 &&
      this.state.countYellowList
        .filter((h) => h.id_company == this.props.companyId)
        .map((d) => {
          let cls =
            d.key == 'fs-list'
              ? 'flaticon-data-storage4  font'
              : d.key == 'cpu-status'
              ? 'flaticon-cpu5 font'
              : 'flaticon-random font';
          giWarningList.push({
            grid: [
              {
                col: 9,
                content: <span className={cls}> {d.name} </span>,
              },
              {
                col: 3,
                content: <div className="font">{parseInt(d.value)}%</div>,
              },
            ],
          });
        });
    this.state.countOkList.length > 0 &&
      this.state.countRedList
        .filter((h) => h.id_company == this.props.companyId)
        .map((d) => {
          let cls =
            d.key == 'fs-list'
              ? 'flaticon-data-storage4  font'
              : d.key == 'cpu-status'
              ? 'flaticon-cpu5 font'
              : 'flaticon-random font';
          giDangerList.push({
            grid: [
              {
                col: 9,
                content: <span className={cls}> {d.name} </span>,
              },
              {
                col: 3,
                content: <div className="font">{parseInt(d.value)}%</div>,
              },
            ],
          });
        });

    let listContract = [];
    let listContract2 = [];

    let usedc = 0;
    let dataP1 = [
      {
        name: 'Ok',
        value: giNormal,
        color: 'var(--success)',
        titleHover: false,
      },
      {
        name: 'Warning',
        value: giWarning,
        color: 'var(--warning)',
        titleHover: false,
      },
      {
        name: 'Danger',
        value: giDanger,
        color: 'var(--danger)',
        titleHover: false,
      },
    ];

    let datab1Ok;
    let datab1warning;
    let datab1danger;

    let data1 = [];

    let data2 = [];
    let data3 = [];

    if (itsm.length) {
      listContract2 = itsm
        .filter((v) =>
          this.state.typeContract == null
            ? v.id_contract > 0
            : v.type == this.state.typeContract
        )
        .map((contract, i) => {
          let con = contracts.filter(
            (v) => v.id_contract === contract.id_contract
          );

          try {
            usedc = parseInt(con[0].hours_total_consumer.substr(0, 2));
          } catch (e) {
            usedc = 0;
          }

          let trend = trendLine(
            contract.trend.map((a, k) => [k, a[1]]),
            2
          );

          let valorcontrated = con[0] != undefined ? con[0].hours : 0;

          /*!= 0
        ? parseInt( con[0].hours) * parseInt( con[0].value)
        : parseInt( con[0].value);*/

          let calcPerc =
            contract.type == 'hour'
              ? parseInt(contract.percent) > 100
                ? 100
                : parseInt(contract.percent)
              : contract.value;

          let direction =
            trend.points[0][1] > trend.points[trend.points.length - 1][1]
              ? 'upIcon'
              : 'downIcon';

          var idx = !this.state.c_id
            ? i == 0
            : contract.id_contract == this.state.c_id;
          return (
            <div
              onClick={() => {
                //  handleCompany(contract.id_contract);
                this.setState({ idx: contract.id_contract });

                this.handleContract(contract.id_contract);

                this.toogleModalContract(false, contract.id_contract);
              }}
              className={classnames('carousel-item content', { active: idx })}
            >
              <h6 className="title-items-carousel my-0 ellipsis-line camp-2">
                {contract.name}
                <br></br>
                {contract.type}
              </h6>
              <div className="mt-3" style={{ maxWidth: 71 }}>
                {contract.type == 'hour' ? (
                  <PieChart
                    textProps={{ fill: idx ? '#ffffff' : '#62AA9F' }}
                    color="bluegreen"
                    percent={isFinite(calcPerc) ? calcPerc : 0}
                    strokeWidth={4.5}
                    bgStrokeWidth={4.5}
                  />
                ) : (
                  <div
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      marginBottom: '30px',
                    }}
                  >
                    R$ {contract.value}
                  </div>
                )}
              </div>
            </div>
          );
        });
      if (listContract2.length < 6) {
        for (var i = listContract2.length; i < 6; i++) {
          listContract2.push(<div></div>);
        }
      }

      listContract = itsm.map((contract) => {
        let con = contracts.filter(
          (v) => v.id_contract === contract.id_contract
        );

        try {
          usedc = parseInt(con[0].hours_total_consumer.substr(0, 2));
        } catch (e) {
          usedc = 0;
        }

        let trend = trendLine(
          contract.trend.map((a, k) => [k, a[1]]),
          2
        );

        let valorcontrated = con[0] != undefined ? con[0].hours : 0;

        /*!= 0
        ? parseInt( con[0].hours) * parseInt( con[0].value)
        : parseInt( con[0].value);*/

        let calcPerc =
          contract.type == 'hour'
            ? parseInt(contract.percent) > 100
              ? 100
              : parseInt(contract.percent)
            : 0;

        let direction =
          trend.points[0][1] > trend.points[trend.points.length - 1][1]
            ? 'upIcon'
            : 'downIcon';

        return {
          onClick: () => this.toogleModalContract(true, contract.id_contract),
          grid: [
            {
              col: 10,
              content: (
                <div className="d-flex alig-items-center">
                  <div
                    className="control-order mr-2"
                    style={{ cursor: 'pointer' }}
                  >
                    <i
                      style={{ padding: '0 6px' }}
                      className={
                        'fas fa-angle-' +
                        (this.state.id_c == contract.id_contract
                          ? 'up'
                          : 'down')
                      }
                    ></i>
                  </div>
                  <a href="#" className="link text-muted-200">
                    {contract.name}
                  </a>
                </div>
              ),
            },
            {
              col: 2,
              content: (
                <div
                  className="control-view-item-list"
                  style={{ float: 'right' }}
                >
                  {contract.type == 'hour' ? (
                    <GraphCircle
                      currentText={{ fontSize: 12 }}
                      size={40}
                      current={calcPerc}
                    />
                  ) : (
                    <GraphCircleVl
                      currentText={{ fontSize: 12 }}
                      size={40}
                      current={calcPerc}
                    />
                  )}

                  <div>
                    <div
                      className={direction == 'upIcon' ? 'upIcon' : 'downIcon'}
                      data-tip={
                        direction == 'upIcon'
                          ? language.GROWING
                          : language.DECREASING
                      }
                      currentitem="false"
                      style={{ width: 20, height: 20, marginLeft: -10 }}
                    ></div>
                  </div>
                </div>
              ),
            },
          ],
        };
      });
    } else {
      listContract = [];
      listContract2 = [];
    }

    let data = [];
    let classIcon = {
      'ISO 27001': 'iso27Icon',
      'ISO 20000': 'iso20Icon',
      COBIT: 'cobitIcon',
      'ISO 37001': 'iso27Icon',
      'GESTÃO DE EVOLUÇÃO DE TI - dash': 'dashIcon',
      'GESTÃO DE BACKUP': 'backupManagementIcon',
      LGPD: 'bitdefenderIcon',
    };

    if (tests.length) {
      data = tests.map((t, k) => {
        let mat = 0;
        if (t.dash) {
          mat = t.dash.reduce(
            (value, obj) => value + (obj.value * 100) / obj.total,
            0
          );
          mat = (mat / t.dash.length).toFixed(2);
        }
        return (
          <Col md={4} key={k}>
            <GrGraphs
              title={
                <div className="fontCardGr">
                  <div
                    className={classIcon[t.name]}
                    style={{ width: 20, height: 20 }}
                  />
                  <h2>{t.name}</h2>
                </div>
              }
              current={parseFloat(mat)}
            />
          </Col>
        );
      });
    }

    //gsdCount
    //tickets.filter(c=>c.id_company === this.props.companyId).map(c=>{return (c)})


    let incident = 0;
    let solicitation = 0;
    let problems = 0;
    let incidentAtrasado = 0;
    let solicitationAtrasado = 0;
    let problemsAtrasado = 0;
    let totalincident = 0;
    let totalsolicitation = 0;
    let totalproblem = 0;
    let totalgmud = 0;
    let incidentProgress = 0;
    let solicitationProgress = 0;
    let problemsProgress = 0;
    let progressmes_;
    let requestOpen = 0;
    let requestAtrasado = 0;

    let gmudTicket = 0;


    if (gsdCount.ticketsGSD != undefined) {
      requestGSD.map((f) => {
        requestOpen = requestOpen + 1;
        let color = this.calcSla(f, 'request', language, undefined, null).color;
        if (color === 'var(--red)') {
          requestAtrasado = requestAtrasado + 1;
        }
      });

      requestOpen = requestGSD.length;
      ticketsGSD.map((t) => {
        let req = requestGSD[t];

        let color = this.calcSla(t, 'ticket', language, req, t.sla_task).color;

        if (t.type_tickets === 1) {
          totalincident++;
          if (t.status === 'Open') {
            incident = incident + 1;

            if (color === 'var(--red)') {
              incidentAtrasado = incidentAtrasado + 1;
            }
          } else if (t.status === 'In Progress') {
            incidentProgress = incidentProgress + 1;
          }
        } else if (t.type_tickets === 4) {
          totalsolicitation++;
          if (color === 'var(--red)') {
            solicitationAtrasado = solicitationAtrasado + 1;
          }
          if (t.status === 'Open') {
            solicitation = solicitation + 1;
          } else if (t.status === 'In Progress') {
            solicitationProgress = solicitationProgress + 1;
          }
        } else if (t.type_tickets === 2 || t.status === 'Closed') {
          totalproblem++;
          if (t.status === 'Open') {
            problems = problems + 1;

            if (color === 'var(--red)') {
              problemsAtrasado = problemsAtrasado + 1;
            }
          } else if (t.status === 'In Progress') {
            problemsProgress = problemsProgress + 1;
          }
        }
      });
    }

    //service desk graphs
    let requesterTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    let requesterInfo = [
      { name: language.REQUESTERSDASH, total: requestOpen, Late: 0, amt: 0 },

      { name: language.REQUESTERSDASH, open: requestOpen, Late: 0, amt: 0 },
      {
        name: language.REQUESTERSDASH,
        late: requestAtrasado,
        Late: 0,
        amt: 0,
      },
    ];

    let giInfoso = [
      { name: language.REQUEST, total: totalsolicitation, Late: 0, amt: 0 },
      { name: language.REQUEST, open: solicitation, Late: 0, amt: 0 },
      {
        name: language.TICKET_TYPES.REQUEST,
        progress: solicitationProgress,
        Late: 0,
        amt: 0,
      },
      {
        name: language.REQUEST,
        late: solicitationAtrasado,
        Late: 0,
        amt: 0,
      },
    ];

    let giInfo = [
      {
        name: language.TICKET_TYPES.INCIDENT,
        total: totalincident,
        Late: 0,
        amt: 0,
      },
      {
        name: language.TICKET_TYPES.INCIDENT,
        open: incident,
        Late: 0,
        amt: 0,
      },
      {
        name: language.TICKET_TYPES.INCIDENT,
        progress: incidentProgress,
        Late: 0,
        amt: 0,
      },
      {
        name: language.TICKET_TYPES.INCIDENT,
        late: incidentAtrasado,
        Late: 0,
        amt: 0,
      },
    ];

    let gmudInfo = [
      { name: 'Gmud', total: gmudTicket, Late: 0, amt: 0 },
      { name: 'Gmud', Gmud: gmudTicket, Late: 0, amt: 0 },
    ];
    progressmes_ = gsdCount.progressmes_;
    let gplInfo = [
      {
        name: language.TICKET_TYPES.PROBLEM,
        total: totalproblem,
        Late: 0,
        amt: 0,
      },
      {
        name: language.TICKET_TYPES.PROBLEM,
        open: problems,
        Late: 0,
        amt: 0,
      },
      {
        name: language.TICKET_TYPES.PROBLEM,
        progress: problemsProgress,
        Late: 0,
        amt: 0,
      },
      {
        name: language.TICKET_TYPES.PROBLEM,
        late: problemsAtrasado,
        Late: 0,
        amt: 0,
      },
    ];

    let id_ = 0;
    try {
      id_ = itsm[0].id_contract;
    } catch (e) {
      id_ = 1;
    }
    let contractSelected = contracts.find(
      (v) => v.id_contract === (this.state.c_id || id_)
    );
    let os = 0;
    let Ticket = 0;
    let Suporte = 0;
    let project = 0;
    let gmud = 0;

    try {
      if (contractSelected.hours != 0) {
        try {
          os =
            (parseInt(contractSelected.hours_consumer_os.substring(0, 2)) /
              contractSelected.hours) *
            100;
        } catch (e) {}
        try {
          Ticket =
            (parseInt(contractSelected.hours_tickets.substring(0, 2)) /
              contractSelected.hours) *
            100;
        } catch (e) {}
        try {
          Suporte =
            (parseInt(
              contractSelected.hours_consumer_assessment.substring(0, 2)
            ) /
              contractSelected.hours) *
            100;
        } catch (e) {}

        try {
          project =
            (parseInt(contractSelected.hours_project.substring(0, 2)) /
              contractSelected.hours) *
            100;
        } catch (e) {}

        try {
          gmud =
            (parseInt(contractSelected.hours_gmud.substring(0, 2)) /
              contractSelected.hours) *
            100;
        } catch (e) {}
      } else {
        try {
          os = parseInt(contractSelected.hours_consumer_os.substring(0, 2));
        } catch (e) {}
        try {
          Ticket = parseInt(contractSelected.hours_tickets.substring(0, 2));
        } catch (e) {}
        try {
          Suporte = parseInt(
            contractSelected.hours_consumer_assessment.substring(0, 2)
          );
        } catch (e) {}
      }
    } catch (e) {}
    // var GSDCallsSeries = this.state.series.map(e=>e.name);

    // ###############################################-Lógica abaixo referente ao gráfico de ticket-###################################################################################

    let startOfWeek = moment()
      .subtract(0, 'weeks')
      .startOf('week')
      .format('YYYYMMDD');
    let endOfWeek = moment()
      .subtract(0, 'weeks')
      .endOf('week')
      .format('YYYYMMDD');
    const currentMonthDates = new Array(moment().daysInMonth())
      .fill(null)
      .map((x, i) =>
        parseInt(moment().startOf('month').add(i, 'days').format('D'))
      );

    let ticketsGSDTop = [];

    let values = [];
    let NewContractsProps = {
      categories: language.DATE_LANG.monthLongList,
      consumed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      realized: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      foreseen: Array.from(new Array(12), (_, i) => 0),
    };
    if (
      this.store.contracts.length > 0 &&
      this.state.idx !== 0 &&
      this.state.contract
    ) {
      this.store.contracts.map((x) => {
        if (x.id_contract === this.state.idx) {
          values = contracts.length > 0 && this.computNewContracts(x);
          NewContractsProps = {
            categories: language.DATE_LANG.monthLongList,
            consumed: values[1],
            realized: values[0],
            foreseen: Array.from(new Array(12), (_, i) => values[2]),
          };
        }
        //  this.setState({NewContractsProps : NewContractsProps})
      });
    }



    let tempbaixo = 0;
    let tempmedio = 0;
    let tempalto = 0;
    let baixomes = [];
    let mediomes = [];
    let altomes = [];

    [
      '012019',
      '022019',
      '032019',
      '042019',
      '052019',
      '062019',
      '072019',
      '082019',
      '092019',
      '102019',
      '112019',
      '122019',
    ].map((mes) => {
      //  012019
      tempbaixo = 0;
      tempmedio = 0;
      tempalto = 0;

      matrizmonth
        .filter((c) => parseInt(c.datacad) == parseInt(mes))
        .map((c) => {
          if (c.tipo == 'Baixo') {
            tempbaixo = parseInt(c.valor);
          } else if (c.tipo == 'Medio') {
            tempmedio = parseInt(c.valor);
          } else if (c.tipo == 'Alto') {
            tempalto = parseInt(c.valor);
          }
        });

      baixomes.push(tempbaixo);
      mediomes.push(tempmedio);
      altomes.push(tempalto);
    });

    let category = [];
    let baixo = [];
    let medio = [];
    let alto = [];

    let mediograph2 = 0;
    let baixograph2 = 0;
    let altograph2 = 0;

    matrizproblem.map((c) => {
      if (c.tipo == 'Baixo') {
        baixograph2++;
      } else if (c.tipo == 'Medio') {
        mediograph2++;
      } else if (c.tipo == 'Alto') {
        altograph2++;
      }
    });

    let arr = matrizproblem.map((c) => {
      return c.nameproblem;
    });
    var a = [],
      b = [],
      prev;
    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }

    a.filter((g) => g != null).map((g) => {
      matrizproblem
        .filter((c) => c.nameproblem == g)
        .map((c) => {
          if (c.tipo == 'Baixo') {
            tempbaixo = parseInt(c.qtd);
          } else if (c.tipo == 'Medio') {
            tempmedio = parseInt(c.qtd);
          } else if (c.tipo == 'Alto') {
            tempalto = parseInt(c.qtd);
          }
        });
      category.push(g);
      baixo.push(tempbaixo);
      medio.push(tempmedio);
      alto.push(tempalto);
    });
    // let currentYear = moment().format('YYYY')
    // let month = moment().format('MM')
    let monthOptions = language.DATE_LANG.monthLongList.map((month, index) => {
      return { value: index + 1, label: index + 1 +' - ' + month };
    });

    return (
      <PageLayout
        icon={<div className="cioIcon" style={{ width: 30, height: 30 }} />}
        title={language.CIO_COCKPIT}
        subTitle={language.CIO_COCKPIT + '/'}
        rightElements={[
          <div style={{ width: '70%', zoom: 0.8, marginTop: '1rem' }} zoom={0.8}>
            <CommonForm
              values={{
                companyId,
                currentYear,
                month,
              }}
              fields={[
                // {
                //   type: 'select',
                //   name: 'companyId',
                //   label: 'Empresa1',
                //   options: companies.map((e) => ({
                //     value: e.id_company,
                //     label: e.name,
                //   })),
                // },
                {
                  col: 3,
                  name: 'month',
                  label: language.MONTH,
                  type: 'select',
                  options: monthOptions,
                  required: true
                },
                {
                  col: 2,
                  type: 'select',
                  name: 'currentYear',
                  label: language.YEAR,
                  options: [
                    {
                      value: moment().year() - 2,
                      label: moment().year() - 2,
                    },
                    {
                      value: moment().year() - 1,
                      label: moment().year() - 1,
                    },
                    {
                      value: moment().year(),
                      label: moment().year()
                    },
                    {
                      value: moment().year() + 1,
                      label: moment().year() + 1,
                    },
                    {
                      value: moment().year() + 2,
                      label: moment().year() + 2,
                    },
                    {
                      value: moment().year() + 3,
                      label: moment().year() + 3,
                    },
                    {
                      value: moment().year() + 4,
                      label: moment().year() + 4,
                    },
                    {
                      value: moment().year() + 5,
                      label: moment().year() + 5,
                    },
                  ],
                  required: true
                },
                {
                  col: 4,
                  type: 'autocomplete',
                  name: 'fk_id_company',
                  label: language.COMPANY,
                  method: 'POST',
                  route: `${this.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: language.COMPANY,
                  fieldvaluedb: 'id_company',
                  textinit:
                  this.state.fk_id_company == this.store.getUserLoged().perfil.fk_id_company
                            ? [this.store.getUserLoged().perfil.company]
                            : '',
                  idinit: this.state.fk_id_company == this.store.getUserLoged().perfil.fk_id_company
                  ? [this.store.getUserLoged().perfil.fk_id_company]
                  : '',
                  fieldlabeldb: 'name',
                  routeAll: `${this.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${this.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  isMulti: true,
                },
              ]}
              onChangeField={(f,v) => {
                if(f.name == 'month')
                {
                  this.setState({ month: v });

                  handleCompany(this.state.fk_id_company, v, this.state.currentYear);
                  this.filterCompany(this.state.fk_id_company);

                  if(this.state.fk_id_company === 'object' ){
                    let id_contract = this.getContractCompany(this.state.fk_id_company);
                    this.setState({ idx: id_contract });
                    this.handleContract(id_contract);
                  }
                  this.setState({ id_c: null });
                }
                else if(f.name == 'currentYear')
                {
                  this.setState({ currentYear: v });

                  handleCompany(this.state.fk_id_company, this.state.month, v);
                  this.filterCompany(this.state.fk_id_company);

                  if(this.state.fk_id_company === 'object'){
                    let id_contract = this.getContractCompany(this.state.fk_id_company);
                    this.setState({ idx: id_contract });
                    this.handleContract(id_contract);
                  }
                  this.setState({ id_c: null });
                }else{
                  fk_id_company = v

                  handleCompany(v, this.state.month, this.state.currentYear);
                  this.filterCompany(v);
                  let id_contract = this.getContractCompany(v);
                  this.setState({ idx: id_contract });
                  this.handleContract(id_contract);
                  this.setState({ id_c: null });
                  this.setState({ fk_id_company: v });
                }
                     }}
            />
          </div>,

          /*
           <TextField
             id="outlined-select-currency"
             individual
             select
             label={language.COMPANY}
             style={{ minWidth: 200, marginLeft: '1em' }}
             value={this.props.companyId}
             onChange={(event, key, values) => {handleCompany(event.target.value);
              this.setState({id_c:null})

             } }
             margin="dense"
             variant="outlined"
             className="input-min"
             SelectProps={{
               className: 'select-min'
             }}
           >
             {listCompanies}
           </TextField>

       */
        ]}
      >
        <div id="Cio" className="-mt-3">
          <div
            className="bar-cio-filter"
            style={{ background: COMMON_PAGE.bar }}
          ></div>
          <Card className="content-min px-3">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/*------------------------------------------GESTÃO DE DEMANDAS-------------------------------------------------------------*/}
                <Card style={{ height: 762 }} className="border-radius-10">
                  <AppBar
                    title={language.DEMAND_MANAGEMENT}
                    titleProps={{ style: { fontSize: '0.70em' } }}
                  />
                  <div className="content pt-0">
                    <div className="content-card-GD2 d-flex align-items-center justify-content-between">
                      {[
                        {
                          currentRef:
                          dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.RequestD > 0
                              ? parseInt(
                                  (dashCount.graphModulesClosedOpen.RequestO /
                                    dashCount.graphModulesClosedOpen.RequestD) *
                                    100
                                )
                              : 0,
                          color: 'purple-color',
                          onClick: () => this.redirect('/dash/request'),
                          icon: <NewReleasesIcon className="icon" />,
                          key: 'requesterInfo',
                          name: language.NEW_CALL,
                          total: '',
                          open: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.RequestP + dashCount.graphModulesClosedOpen.RequestO,
                        },

                        {
                          currentRef:
                          dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.totalClosed > 0
                              ? parseInt(
                                  (dashCount.graphModulesClosedOpen.SolicitationD /
                                    dashCount.graphModulesClosedOpen.totalClosed) *
                                    100
                                )
                              : 0,

                          color: 'yellow-color',
                          onClick: () =>
                            this.redirect('/dash/tickets', {
                              route: 'solicitation',
                            }),
                          icon: (
                            <i
                              className="fas fa-bullhorn icon my-1"
                              style={{ fontSize: 19 }}
                            />
                          ),
                          key: 'giInfoso',
                          name: language.REQUEST,
                          total: dashCount.graphModules && dashCount.graphModules.Solicitation,
                          closed: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.SolicitationP,
                          open: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.SolicitationO,
                        },

                        {
                          currentRef:
                          dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.totalClosed > 0
                              ? parseInt(
                                  (dashCount.graphModulesClosedOpen.IncidentD /
                                    dashCount.graphModulesClosedOpen.totalClosed) *
                                    100
                                )
                              : 0,
                          color: 'blue-color',
                          onClick: () =>
                            this.redirect('/dash/tickets', {
                              route: 'incident',
                            }),
                          icon: <WarningIcon className="icon" />,
                          localIcon: true,
                          key: 'giInfo',
                          name: language.TICKET_TYPES.INCIDENT,
                          total: dashCount.graphModules &&  dashCount.graphModules.Incident,
                          closed: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.IncidentP,
                          open: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.IncidentO,
                        },

                        {
                          currentRef:
                          dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.totalClosed > 0
                              ? parseInt(
                                  (dashCount.graphModulesClosedOpen.ProblemD /
                                    dashCount.graphModulesClosedOpen.totalClosed) *
                                    100
                                )
                              : 0,
                          color: 'red-color',
                          onClick: () =>
                            this.redirect('/dash/tickets', {
                              route: 'problem',
                            }),
                          icon: <ErrorIcon className="icon" />,
                          localIcon: true,
                          key: 'gplInfo',
                          name: language.TICKET_TYPES.PROBLEM,
                          total:dashCount.graphModules && dashCount.graphModules.Problem,
                          closed: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.ProblemP,
                          open:dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.ProblemO,
                        },

                        {
                          currentRef: 0,
                          color: 'lightblue-color',
                          color2: CIO_COCKPIT.demand_management.card_5,
                          onClick: () =>
                            this.redirect('/dash/changemanagement'),
                          icon: <SyncIcon className="icon" />,
                          key: 'gmudInfo',
                          name: language.GMUD,
                          total: '',
                          closed: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.GmudP,
                          open: dashCount.graphModulesClosedOpen && dashCount.graphModulesClosedOpen.GmudO,
                        },
                      ].map((e, i) => (
                        <div
                          className={classnames(
                            'content-card content-medium',
                            e.color,
                            'mb-2',
                            {
                              'mr-2 ml-2': ![0, 4].includes(i),
                              'mr-2': i == 0,
                              'ml-2': i == 4,
                            }
                          )}
                          style={{ '--myColor': e.color2 }}
                        >
                          <div className="d-flex align-items-center content-title">
                            {e.icon}
                            <span className="ml-2 title">{e.name}</span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center w-100">
                              <Progress
                                hiddenText
                                style={{ height: 5, width: '100%' }}
                                current={e.currentRef}
                              />
                              <span
                                className="ml-2"
                                style={{ fontSize: 10, color: 'white' }}
                              >
                                {e.currentRef}%
                              </span>
                            </div>

                            <hr className="my-2" />
                            <div className="d-flex aling-items-center justify-content-between content-desc">
                              <div>
                                <span>{language.OPENED}</span>
                                <h4>{e.open}</h4>
                              </div>
                              <div>
                                <span>{language.CLOSED}</span>
                                <h4>{e.closed}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={2.5} style={{ ...flexBasis(3) }}>
                        <Card className="h-100">
                          <AppBar
                            title={language.AVERAGE_TIME}
                            titleProps={{ style: { fontSize: '0.70em' } }}
                            subTitle={`(${language.OPEN_TICKET})`}
                          />
                          <div className="content-min">
                            {/* avrequest = gsdCount.avr;
      avTicket = gsdCount.avt;
      avCon = gsdCount.avConsumed; */}
                            <div className="d-flex justify-content-center">
                              <div className="-ml-4 pr-2">
                                <MediumTime
                                  config={{
                                    interval: 6,
                                    height: 350,
                                    width: 40,
                                    y:
                                    gsdCount.avt && gsdCount.avt.hour > 0
                                        ?  gsdCount.avt && gsdCount.avt.hour
                                        :  gsdCount.avt && gsdCount.avt.minutes,
                                    // yi:50,
                                    formatY: (y) => (
                                      <div>
                                        <h3
                                          className="my-0"
                                          style={{ fontSize: 20 }}
                                        >
                                          {y}
                                        </h3>
                                        <span
                                          style={{ fontSize: 11 }}
                                          className="d-block -mt-1"
                                        >
                                          {gsdCount.avt.hour > 0
                                            ? language.HOUR
                                            : language.MINUTES}
                                        </span>
                                      </div>
                                    ),
                                  }}
                                  data={[
                                    {
                                      color:
                                        CIO_COCKPIT.demand_management
                                          .times_medium.color_1,
                                      y:
                                      gsdCount.avt && gsdCount.avt.hour > 0
                                          ? gsdCount.avt.max / 3
                                          : 60 / 3,
                                    },
                                    {
                                      color: '#F0B002',
                                      y:
                                      gsdCount.avt && gsdCount.avt.hour > 0
                                          ? gsdCount.avt.max / 3
                                          : 60 / 3,
                                    },
                                    {
                                      color: '#F66868',
                                      y:
                                      gsdCount.avt && gsdCount.avt.hour > 0
                                          ? gsdCount.avt.max / 3
                                          : 60 / 3,
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xs={2.5} style={{ ...flexBasis(3) }}>
                        <Card className="h-100">
                          <AppBar
                            title={language.AVERAGE_TIME}
                            subTitle={`(${language.CATEGORIZATION})`}
                            titleProps={{ style: { fontSize: '0.70em' } }}
                          />
                          <div className="content-min">
                            <div className="d-flex justify-content-center">
                              <div className="-ml-4 pr-2">
                                <MediumTime
                                  config={{
                                    interval: 6,
                                    height: 350,
                                    width: 40,
                                    y:
                                    gsdCount.avr && gsdCount.avr.hour > 0
                                        ? gsdCount.avr && gsdCount.avr.hour
                                        : gsdCount.avr && gsdCount.avr.minutes,

                                    // yi:50,
                                    formatY: (y) => (
                                      <div>
                                        <h3
                                          className="my-0"
                                          style={{ fontSize: 20 }}
                                        >
                                          {y}
                                        </h3>
                                        <span
                                          style={{ fontSize: 11 }}
                                          className="d-block -mt-1"
                                        >
                                          {' '}
                                          {gsdCount.avr && gsdCount.avr.hour > 0
                                            ? language.HOUR
                                            : language.MINUTES}
                                        </span>
                                      </div>
                                    ),
                                  }}
                                  data={[
                                    {
                                      color:
                                        CIO_COCKPIT.demand_management
                                          .times_medium.color_1,
                                      y:
                                      gsdCount.avr &&gsdCount.avr.hour > 0
                                          ? gsdCount.avr.max / 3
                                          : 60 / 3,
                                    },
                                    {
                                      color: '#F0B002',
                                      y:
                                      gsdCount.avr &&gsdCount.avr.hour > 0
                                          ? gsdCount.avr.max / 3
                                          : 60 / 3,
                                    },
                                    {
                                      color: '#F66868',
                                      y:
                                      gsdCount.avr && gsdCount.avr.hour > 0
                                          ? gsdCount.avr.max / 3
                                          : 60 / 3,
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xs={6} style={{ ...flexBasis(6) }}>
                        <Card className="content h-100">
                          <div className="content-table-GD">
                            <CommonTable
                              bar={false}
                              pagination={false}
                              paper={false}
                              orderColumn={false}
                              col={[
                                { key: 'ticket', label: 'Tickets' },
                                { key: 'gerados', label: language.GENERATED_PLURAL },
                                { key: 'atrasados', label: language.LATE },
                              ]}
                              data={[
                                {
                                  ticket: (
                                    <div className="content-item-GD c-1">
                                      <div></div>
                                      <span>{language.NEW}</span>
                                    </div>
                                  ),
                                  gerados: dashCount.graphModulesClosedOpen &&dashCount.graphModulesClosedOpen.RequestO,
                                  atrasados: dashCount.delayTicket_ && dashCount.delayTicket_.delayInRquest,
                                },
                                {
                                  ticket: (
                                    <div className="content-item-GD c-2">
                                      <div></div>
                                      <span>{language.TICKET_STATUS.OPENS}</span>
                                    </div>
                                  ),
                                  gerados: dashCount.graphDtq &&dashCount.graphDtq.Open,
                                  atrasados: dashCount.delayTicket_ && dashCount.delayTicket_.delayOpen,
                                },
                                {
                                  ticket: (
                                    <div className="content-item-GD c-3">
                                      <div></div>
                                      <span>{language.TICKET_STATUS.IN_PROGRESS}</span>
                                    </div>
                                  ),
                                  gerados: dashCount.graphDtq && dashCount.graphDtq.InProgress,
                                  atrasados: dashCount.delayTicket_ && dashCount.delayTicket_.delayInprogress,
                                },
                                {
                                  ticket: (
                                    <div className="content-item-GD c-4">
                                      <div
                                        style={{
                                          backgroundColor:
                                            CIO_COCKPIT.demand_management
                                              .tickets.resolved,
                                        }}
                                      ></div>
                                      <span
                                        style={{
                                          color:
                                            CIO_COCKPIT.demand_management
                                              .tickets.resolved,
                                        }}
                                      >
                                        {language.TICKET_STATUS.SOLVED}
                                      </span>
                                    </div>
                                  ),
                                  gerados: dashCount.graphDtq && dashCount.graphDtq.Resolved,
                                  atrasados: dashCount.delayTicket_ && dashCount.delayTicket_.delayInResolved,
                                },
                                {
                                  ticket: (
                                    <div className="content-item-GD c-5">
                                      <div></div>
                                      <span>{language.TICKET_STATUS.CLOSED}</span>
                                    </div>
                                  ),
                                  gerados: dashCount.graphDtq && dashCount.graphDtq.Closed,
                                  atrasados:dashCount.delayTicket_ &&  dashCount.delayTicket_.delayInClosed,
                                },
                              ]}
                            />
                          </div>
                          {progressmes_ && (
                            <ReactHighcharts
                              config={ContainerChart.ticket(
                                currentMonthDates,
                                progressmes_.openv_,
                                progressmes_.PROGRESS_,
                                progressmes_.RESOLVED_,
                                progressmes_.REOPENED_,
                                progressmes_.CLOSED_
                              )}
                            />
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                    {/*<Bullet ticket={gsdCount} language={language}></Bullet>*/}
                  </div>
                </Card>
                {/*------------------------------------------GESTÃO DE PROJETOS-------------------------------------------------------------*/}
                <Card className="card-GP mt-2 border-radius-10">
                  <AppBar title={language.PROJECT_MANAGEMENT} />
                  {this.state.dataProject?.length > 0 && (
                    <CommonTable
                      fluid
                      bar={false}
                      paper={false}
                      maxHeight={380}
                      AppBarProps={{
                        style: isBlock_1
                          ? {
                              borderRadius: '10px 10px 0 0',
                            }
                          : {},
                      }}
                      col={[
                        //  { key: 'btn', label: '' },
                        { key: 'id', label: language.ID },
                        { key: 'project', label: language.PROJECT },
                        { key: 'manager', label: language.MANAGE },
                        { key: 'project_type', label: language.TYPE_PROJECT },
                        { key: 'priority', label: language.PRIORITY },
                        { key: 'point', label: language.PUNCTUATION },
                        { key: 'status', label: language.STATE },
                        { key: 'percent', label: language.EVOLUTION_PERCENTAGE },
                      ].filter(Boolean)}
                      data={this.state.dataProject
                        .flatMap((e, i) => [
                          {
                            ConfigCell: {
                              __order_id: e.id_project,
                              __order_project: e.name,
                              __order_manager: e.manager.name
                                ? e.manager.name
                                : '',
                              //  __order_project_type:
                              //    btnStatus[e.project_type].label,
                              //  __order_priority: e.priority.label,
                              //  __order_status: e.status,
                              //  __order_percent: e.percent,
                              //  __order_expected_start: e.expected_start,
                              //  __order_expected_end: e.expected_end,
                              //  __order_realized_start: e.realized_start,
                              //  __order_realized_end: e.realized_end,
                              //  __order_expected_cost:
                              //    e.expected_cost.toLocaleString('pt-BR', {
                              //      style: 'currency',
                              //      currency: 'BRL',
                              //    }),
                              //  __order_realized_cost:
                              //    e.realized_cost.toLocaleString('pt-BR', {
                              //      style: 'currency',
                              //      currency: 'BRL',
                              //    }),
                              //  __order_goal: e.goal,
                            },

                            id: e.id_project,
                            name: e.name,
                            manager: e.manager.name,
                            project_type: (
                              <Button
                                {...btnStatus[e.type_project]}
                                variant="normal"
                                size={0.7}
                              />
                            ),
                            priority: e.priority && (
                              <Tag
                                label={e.priority.label}
                                color={e.priority.color}
                              />
                            ),
                            point: getPoint(e.id_project),

                            status: e.status,
                            percent: (
                              <div className="d-flex align-items-center content-progress">
                                <Progress
                                  current={e.percent}
                                  hiddenText
                                  className="w-100"
                                  background="var(--warning)"
                                />
                                <span className="ml-2">{e.percent}%</span>
                              </div>
                            ),
                          },
                        ])
                        .filter(Boolean)}
                    />
                  )}
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {/*------------------------------------------GESTÃO DE RISCOS E COMPLIANCES-------------------------------------------------------------*/}
                    <Card
                      style={{ height: '100%' }}
                      className="border-radius-10"
                    >
                      <AppBar
                        title={language.RISK_AND_COMPLIANCE_MANAGEMENT}
                        titleProps={{ style: { fontSize: '0.70em' } }}
                      />
                      <div className="content-min">
                        <ReactHighcharts
                          config={ContainerChart.container0(
                            this.props.teia,
                            language
                          )}
                        />
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    {/*------------------------------------------MATRIZ DE RISCO-------------------------------------------------------------*/}
                    <Card
                      style={{ height: '100%' }}
                      className="border-radius-10"
                    >
                      <AppBar
                        title={language.RISK_MATRIX}
                        titleProps={{ style: { fontSize: '0.70em' } }}
                      />
                      <div className="content">
                        <ReactHighcharts
                          config={ContainerChart.risk_matrix(this.matrizRisk())}
                        />
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                {/*------------------------------------------GESTÃO DE DISPONIBILIDADE-------------------------------------------------------------*/}

                <GiGraph
                  average_availability={average_availability}
                  language={language}
                  companyId={this.props.companyId}
                  devicesav={devices}
                  devices={devicesAvailable}
                ></GiGraph>
                {/*------------------------------------------GESTÃO DE CONTRATOS E FORNECEDORES-------------------------------------------------------------*/}
                <Card className="mt-2 border-radius-10">
                  <div className="py-0">
                    <div className="d-flex justify-content-between">
                      <AppBar
                        title={language.DASHBOARD_PAGE.GCF}
                        contentProps={{ className: 'w-100' }}
                        titleProps={{ style: { fontSize: '0.70em' } }}
                      ></AppBar>
                    </div>
                  </div>
                  <Loading loading={!contracts.length} image>
                    <div className="content-carousel py-0 d-flex justify-content-between">
                      <div
                        className="control-carousel"
                        onClick={() => this.Carousel.slidePrev()}
                      >
                        <i className="fas fa-angle-left" />
                      </div>
                      <div className="carousel">
                        <AliceCarousel
                          // startIndex={this.state.position}
                          startIndex={0}
                          dotsDisabled
                          buttonsDisabled
                          mouseTrackingEnabled
                          fadeOutAnimation
                          ref={(el) => (this.Carousel = el)}
                          stagePadding={
                            {
                              // paddingRight : 18
                            }
                          }
                          responsive={{
                            0: {
                              items: 6,
                            },
                          }}
                          infinite={false}
                        >
                          {listContract2}
                        </AliceCarousel>
                      </div>
                      <div
                        className="control-carousel"
                        onClick={() => this.Carousel.slideNext()}
                      >
                        <i className="fas fa-angle-right" />
                      </div>
                    </div>
                  </Loading>
                  <div className="p-2 desc">
                    <Grid container spacing={2}></Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {contracts.length && contractSelected && (
                          <Card className="border-radius-10 mt-2">
                            <AppBar
                              title={contractSelected.name}
                              subTitle={language.RATION_CONSUMPTION_VALUE}
                              titleProps={{ style: { fontSize: '0.70em' } }}
                              subTitleProps={{
                                style: {
                                  fontSize: 8,
                                },
                              }}
                            />
                            <div className="content">
                              <Loading loading={!contracts.length} image>
                                <Animate anime="zoomIn">
                                  <ContractSupplier
                                    language={language}
                                    contract={contractSelected}
                                    contractprops={NewContractsProps}
                                  />
                                </Animate>
                              </Loading>
                            </div>
                          </Card>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </div>
      </PageLayout>
    );
  }
}

class SimpleLineChart extends Component {
  render() {
    let { language, percent } = this.props;
    const data = [
      { name: '04/05/2018', activities: 10, time: 20, amt: 24 },
      { name: '05/05/2018', activities: 20, time: 30, amt: 22 },
      { name: '06/05/2018', activities: 30, time: 40, amt: 22 },
    ];

    if (
      this.props.projectStatus != null &&
      this.props.projectStatus != undefined
    ) {
      let pro = this.props.pro;
      let projectStatus_ = null;
      let projectStatus = this.props.projectStatus;

      Object.keys(projectStatus).forEach(function (key) {
        if (projectStatus[key].id_project === pro.id_project) {
          projectStatus_ = projectStatus[key];
        }
      });

      let p = this.props.proj;
      let projectTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      let projectInfo = null;

      if (projectStatus_.hasOwnProperty('totalpercent')) {
        projectInfo = [{ name: '', Progresso: percent, amt: 100 }];
      } else {
        projectInfo = [{ name: '', Progresso: 0, amt: 100 }];
      }
      let dataContractBars = {
        dk: 'Progresso',
        f: 'var(--primary)',
        st: 'stack',
      };

      return (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.INITIAL_DATE}</strong>
                </div>
              }
              arrow={true}
              position="right"
              theme="dark"
            >
              <Chip
                style={{
                  cursor: 'pointer',
                  margin: '5px 0',
                  maxWidth: '100%',
                  height: 29,
                }}
                className="chip"
                avatar={
                  <Avatar
                    size={32}
                    style={{
                      backgroundColor: 'white',
                      border: '2px solid var(--success)',
                      width: 29,
                      height: 29,
                      fontSize: '0.9rem',
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i
                      className="fas fa-hourglass-start"
                      style={{ width: 'auto', height: 'auto' }}
                    ></i>
                  </Avatar>
                }
                label={
                  <div className="ellipsis">
                    {moment(projectStatus_.dtinicio).format('L')}
                  </div>
                }
              />
            </Tooltip>
            <Chip
              style={{
                cursor: 'pointer',
                margin: '5px 0',
                maxWidth: '100%',
                height: 29,
              }}
              className="chip"
              label={
                projectStatus_.totalactivityend +
                ' in ' +
                projectStatus_.totalactivity
              }
            />
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.FINAL_DATE}</strong>
                </div>
              }
              arrow={true}
              position="left"
              theme="dark"
            >
              <Chip
                style={{
                  cursor: 'pointer',
                  margin: '5px 0',
                  maxWidth: '100%',
                  height: 29,
                }}
                className="chip"
                avatar={
                  <Avatar
                    size={32}
                    style={{
                      backgroundColor: 'white',
                      border:
                        '2px solid ' +
                        (moment(projectStatus_.dtfim).isAfter(moment())
                          ? 'var(--success)'
                          : 'var(--danger)'),
                      width: 29,
                      height: 29,
                      fontSize: '0.9rem',
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i
                      className="fas fa-hourglass-end"
                      style={{ width: 'auto', height: 'auto' }}
                    ></i>
                  </Avatar>
                }
                label={
                  <div className="ellipsis">
                    {moment(projectStatus_.dtfim).format('L')}
                  </div>
                }
              />
            </Tooltip>
          </div>
          <Progress
            current={projectInfo[0].Progresso.toFixed(0)}
            color="var(--success)"
          />
        </div>
      );
    }
  }
}
function ItemStatus({ color, index, status }) {
  const percent = (status[index] || 0).toFixed(0);
  return (
    <div style={{ width: 40 }}>
      <PieChart
        color={color}
        strokeWidth={4}
        bgStrokeWidth={4}
        textProps={{
          style: {
            fontSize: (percent < 100 ? 0.8 : 0.7) + 'em',
          },
        }}
        percent={percent}
      />
    </div>
  );
}
class GrGraphs extends Component {
  render() {
    let current = this.props.current;
    return (
      <Card className="cardGr">
        {this.props.title}
        <Radial
          color="#009dc0"
          className="dash-contracts-radial"
          size={80}
          current={current}
          total={this.props.total || 100}
          thick={15}
        >
          <div
            className="percenteValueGr"
            style={
              current === 0
                ? { padding: '10px 0 0 16px' }
                : { padding: '8px 0 0 6px' }
            }
          >
            {current}
            {this.props.showQtd ? '' : '%'}
          </div>
        </Radial>
      </Card>
    );
  }
}
