import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-grid-system';

import RaisedButton from '../../common/RaisedButton';
import Dialog from '@common/Dialog';
import { FaClose } from 'react-icons/lib/fa';
import CommonForm from '../../common/CommonForm';
import CommonTable from '../../common/CommonTable';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import moment from 'moment';
import unique from '../../util/unique';
import { Card, Grid, Paper } from '@material-ui/core';
import './AnalyzePage.css';
import ListColumn from '@common/ListColumn';
@observer
export default class AnalyzePage extends Component {
  constructor() {
    super();
    this.state = {
      action: '',
      log: '',
      modal: false
    };
    this.handleModal = this.handleModal.bind(this);
    this.logModal = this.logModal.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleModal(action) {
    this.setState({
      action: action,
      log: '',
      modal: !this.state.modal
    });
  }

  logModal(log) {
    this.setState({
      log: log,
      modal: !this.state.modal
    });
  }

  onFormSubmit(values) {
    let { action } = this.state;
    let { change, changeStatus } = this.props;
    values.fk_id_change = change.id_change;
    values.approved = action === 'approve';
    changeStatus(action, values);
  }

  render() {
    try {

      let {
        change,
        changeStatus,
        getUserLoged,
        redirect,
        handlePage,
        language
      } = this.props;

      let {
        chiefs,
        key_users,
        manager,
        milestones,
        risks,
        solicitant,
        techs,
        tickets
      } = change;

      let { action, log, modal } = this.state;

      // if (!change) return <div />;

      let user = getUserLoged();

      let approversCol = [
        {
          key: 'id',
          label: language.ID,
          style: { width: 10, textAlign: 'center' }
        },
        {
          key: 'name',
          label: language.NAME,
          style: { maxWidth: 150, overflow: 'auto', textAlign: 'center' }
        },
        {
          key: 'revised',
          label: language.REVISED,
          style: { width: 20, textAlign: 'center' }
        },
        {
          key: 'approved',
          label: language.APPROVED,
          style: { width: 20, textAlign: 'center' }
        },
        {
          key: 'log',
          label: language.LOG,
          style: { width: 20, textAlign: 'center' }
        }
      ];

      let approvers = [];
      //if (chiefs) approvers.push(...chiefs);
      if (key_users) approvers.push(...key_users);

      approvers = unique(approvers, 'id_user');

      let approversData = (unique(approvers, 'id_user') || []).map(approver => ({
        id: approver.id_user,
        name: approver.name,
        revised: approver.revised ? (
          <div>
            <div data-tip={language.REVISED} className="revisedIcon cardIcon" />
            <ReactTooltip />
          </div>
        ) : (
          <div data-tip={language.NOT_REVISED}>
            <div className="notRevisedIcon cardIcon" />
            <ReactTooltip />
          </div>
        ),
        approved: approver.approved ? (
          <div>
            <div data-tip={language.REVISED} className="revisedIcon cardIcon" />
          </div>
        ) : (
          <div data-tip={language.NOT_APPROVED}>
            <div className="notApprovedIcon cardIcon" />
            <ReactTooltip />
          </div>
        ),
        log: approver.log ? (
          <div
            data-tip={language.LOG}
            onClick={() => this.logModal(approver.log)}
          >
            <div className="logIcon cardIcon pointer" />
            <ReactTooltip />
          </div>
        ) : null
      }));

      let envolvedTechsCol = [
        {
          key: 'id_user',
          label: language.ID,
          style: { width: 10, textAlign: 'center' }
        },
        { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
        {
          key: 'company',
          label: language.COMPANY,
          style: { textAlign: 'center' }
        }
      ];

      let envolvedTechsData = (techs || []).map(tech => ({
        id_user: tech.id_user,
        name: tech.name,
        company: tech.company
      }));

      let fields = [
        {
          span: 12,
          name: 'log',
          label: language.DESCRIPTION,
          type: 'text',
          required: true
        }
      ];

      let button = { md: 12, label: language.SAVE, primary: true };

      let activitiesCol = [
        {
          key: 'group',
          label: language.MILESTONE,
          style: { textAlign: 'center' }
        }
      ];

      let changeData = (change.milestones || []).map(m =>
        m.groups.map(g => ({ name: g.name, fk_id_milestone: g.fk_id_milestone }))
      )[0];

      let activitiesTables = (
        <React.Fragment>

          {changeData.map(cd => (


            <CommonTable
              paginationTop={false}
              title={language.CATEGORY_ACTIVITY + ' - ' + cd.name}

              data={this.props.activities.filter(e => e.fk_id_milestone == cd.fk_id_milestone).map(cd => ({

                group: <span dangerouslySetInnerHTML={{ __html: cd.name }} />
              }))}


              columns={activitiesCol}
              language={language}
              hideFooterToolbar
            />



          ))}









        </React.Fragment>
      );

      let risksCol = [
        {
          key: 'description',
          label: language.DESCRIPTION,
          style: { textAlign: 'center' }
        }
      ];
      let risksData = [];
      risksData = risks != undefined ? (risks || []).map(r => ({ description: r })) : [];

      let risksTable = (
        <React.Fragment>

          <CommonTable
            paginationTop={false}

            title={language.RISKS_INVOLVED}
            data={risksData}
            columns={risksCol}
            language={language}
            hideFooterToolbar
          />
        </React.Fragment>
      );







      let ticketsCol = [
        {
          key: 'description',
          label: language.DESCRIPTION,
          style: { textAlign: 'center' }
        }
      ];
      let listTickets = [];
      listTickets = tickets != undefined ? (tickets || []).map(r => ({ description: r.id_tickets + '-' + r.subject })) : [];

      let ticketsTable = (
        <React.Fragment>

          <CommonTable
            paginationTop={false}

            title={'Tickets'}
            data={listTickets}
            columns={ticketsCol}
            language={language}
            hideFooterToolbar
          />
        </React.Fragment>
      );






      return (
        <PageLayout
          icon={<div className="analyzeIcon titleIconSize" />}
          title={language.ANALYZE}
          subtitle={language.ANALYZE_CHANGE}
          rightElements={[
            <RaisedButton
              circleButton
              icon='fas fa-list'
              color='primaryGradient'
              label={language.CHANGE_LIST}
              primary
              onClick={() => redirect('/dash/changemanagement/')}
              style={{ fontSize: 17 }}
            />
          ]}
        >
          <div id="AnalyzePage">
            <Grid container spacing={3} style={{ marginTop: 25 }}>

              <Grid item xs={6}>
                <Card style={{ height: '100%', overflow: 'visible' }}>
                  <div className="content" style={{ paddingTop: 35 }}>
                    <ListColumn
                      col={6}
                      items={[
                        {
                          label: language.NUMBER,
                          value: change.id_change
                        },
                        {
                          label: language.INITIAL_DATE,
                          value: moment.utc(change.start_date).format('L')
                        },
                        {
                          label: language.INITIAL_HOUR,
                          value: moment.utc(change.start_date).format('LT')
                        },
                        {
                          label: language.TYPE,
                          value: change.type_action
                        },
                        {
                          label: language.END_DATE,
                          value: moment.utc(change.end_date).format('L')
                        },
                        {
                          label: language.END_HOUR,
                          value: moment.utc(change.end_date).format('LT')
                        },
                        {
                          label: language.MANAGER_GMUD,
                          value: manager.name
                        },
                        {
                          label: language.SOLICITANT,
                          value: solicitant.name
                        },
                      ]}
                    />

                  </div>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card style={{ height: '100%', overflow: 'visible' }}>
                  <div className="content" style={{ paddingTop: 35 }}>
                    <ListColumn
                      col={6}
                      items={[
                        {
                          label: language.REASON,
                          value: <span dangerouslySetInnerHTML={{ __html: change.reason }} className='description' />
                        },
                        {
                          label: language.BENEFITS,
                          value: <span dangerouslySetInnerHTML={{ __html: change.benefits }} className='description' />
                        },
                      ]}
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
            <div className="cardDefault">

              <Row>
                <Col md={6}>

                  <CommonTable
                    paginationTop={false}
                    data={approversData}
                    columns={approversCol}
                    language={language}
                    hideFooterToolbar
                    title={language.APPROVERS}
                  />
                </Col>
                <Col md={6}>


                  <CommonTable
                    paginationTop={false}
                    title={language.INVOLVED_TECH}
                    data={envolvedTechsData}
                    columns={envolvedTechsCol}
                    language={language}
                    hideFooterToolbar
                  />
                </Col>
              </Row>

              {/*<DescriptionDetail language={language} title={language.RISKS_INVOLVED} content={risks ? risks.map(risk => risk).join(', ') : []} />*/}
              <Row>
                <Col md={6}>{activitiesTables}</Col>
                <Col md={6}>{risksTable}</Col>
                <Col md={6}>{ticketsTable}</Col>
              </Row>


              {approvers.map(u => u.id_user).includes(user.id) ? (
                <div className="nextBtn">
                  <RaisedButton
                    circleButton
                    color='primaryGradient'
                    icon='fas fa-thumbs-up'
                    label={language.APPROVE}
                    onClick={() => this.handleModal('approve')}
                    className='mr-2'
                  />
                  <RaisedButton
                    circleButton
                    color='danger'
                    icon='fas fa-thumbs-down'
                    label={language.REJECT}
                    onClick={() => this.handleModal('reject')}
                  />
                </div>
              ) : null}
            </div>
            {modal ? (

              <Dialog


                open={modal}
                onClose={() => this.handleModal()}
                contentStyle={{ height: '80%', maxWidth: 'none' }}
                fullWidth={true}
                maxWidth='lg'
                autoScrollBodyContent={true}
                title={log && !action
                  ? 'Log'
                  : !log && action === 'approve'
                    ? language.APPROVE
                    : language.REJECT}
              >
                <div className='container' style={{ padding: '20px 0' }}>

                  {log ? (
                    <div dangerouslySetInnerHTML={{ __html: log }} />
                  ) : (
                    <CommonForm
                      language={language}
                      fields={fields}
                      button={button}
                      onFormSubmit={this.onFormSubmit}
                    />
                  )}
                </div>
              </Dialog>
            ) : null}
          </div>
        </PageLayout>
      );
    } catch (e) {

      return null;
    }
  }

}

class CardDetail extends Component {
  render() {
    let { language } = this.props;
    return (
      <Card className="cardAnalyzeDetail">
        <Row>
          <Col md={4}>
            <div className="textCardAnalyze">
              <h2>{this.props.firstTitle}</h2>
              <p>{this.props.firstDescription}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="textCardAnalyze">
              <h2>{this.props.secondTitle}</h2>
              <p>{this.props.secondDescription}</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="textCardAnalyze">
              <h2>{this.props.thirdTitle}</h2>
              <p>{this.props.thirdDescription}</p>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}

class DescriptionDetail extends Component {
  render() {
    let { language } = this.props;
    return (
      <Card className="cardAnalyzeDetail">
        <Row>
          <Col>
            <div className="textCardAnalyze">
              <h2>{this.props.title}</h2>
            </div>
            <div className="descriptionField" style={{ paddingLeft: 14 }}>
              <p>{this.props.content}</p>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}
