import styled, { css } from 'styled-components';

// ----------------------------------------------------------------------

import DialogCommon from '@common/Dialog';
import Solution from '../solution/SolutionContainer';

// ----------------------------------------------------------------------

export const Dialog = styled(DialogCommon).attrs(() => ({
  maxWidth: 'xs',
  fullScreen: false,
  PaperProps: {
    style: {
      width: 'calc(100% - 150px)',
      height: 'calc(100% - 150px)',
    },
  },
  contentProps: {
    style: {
      'min-height': 'auto',
    },
  },
}))`
  ${() => css``}
`;

export const SolutionContainer = styled(Solution)`
  ${() => css``}
`;
