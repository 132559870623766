import { Grid } from '@material-ui/core';
import React from 'react';

// ----------------------------------------------------------------------

import Button from '@common/Button';
import Dialog from '@common/Dialog';
import Store from '@data/Store';

// ----------------------------------------------------------------------

export const DialogConfirmChangeLanguage = ({
  handleSubmit,
  onClose,
}) => {
  const { language } = Store;

  return (
    <Dialog
      title={language.DO_YOU_WANT_TO_CHANGE_THE_LANGUAGE}
      modal={false}
      open
      onClose={onClose}
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {language.WHEN_PERFORMING_THE_LANGUAGE_CHANGE_YOU_WILL_BE_DISCONNECTED}
        <Grid
          item
          xs={4}
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            variant="normal"
            icon="fas fa-save"
            color="success"
            onClick={handleSubmit}
            label={language.CONFIRM}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
