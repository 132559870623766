import React, {
  useState,
  useReducer,
  Fragment,
  useEffect,
  useCallback,
} from 'react';
import AppBar from '@common/AppBar2';
import './index.css';
import Actions from '@data/Actions';
const actions = new Actions();
import Constants from '@data/Constants';

import { Tooltip } from 'react-tippy';
/*-----------------------------common--------------------------*/
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import Animate from '@common/Animate';
import { flexBasis } from '@common/Func';
/*--------------------------material-ui--------------------------*/
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Store from '@data/Store';

/*--------------------------pages--------------------------*/
import TagCarousel from './TagCarousel';

// import Options from './options';
import Options from '../options';

const { language } = Store;

const MyHeader = ({ title, subTitle, className }) => (
  <AppBar
    contentProps={{
      className: 'pl-0 ' + className,
    }}
    titleProps={{
      style: { color: 'var(--primary)' },
    }}
    title={title}
    subTitle={subTitle}
  />
);

export default function ({
  open,
  onClose,
  options,
  values: _values = {},
  project,
  idProject,
  onSubmit,
  onDownloadFile,
  ondeletefile,
  isDuplicate = false,
  action,
  APIEndpoints,
  ticket,
  onOpenForm,
  Store,
  clearHistory,
  updateProject
}) {
   
  let [form, setForm] = useReducer((state, v) => [...state, v], []);
  let [tagOptions, setTagOptions] = useState([]);
  let [tagValues, setTagValues] = useState([]);
  let [formPrimary, setFormPrimary] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [projects, setProjects] = useState([]);
  const [generalParams, setGeneralParams] = useState([])  
  let [inDays, seInDays] = useState(false);
  let values = {};
  let [OnlyOneCommit, setOnlyOneCommit] = useState(false);
  let [usersAssoc, SetUsersAssoc] = useState({ id: [], names: [] });
  let [valuesState, setValuesState] = useState(_values);
  let [resetSelectedUsers, setResetSelectedUsers] = useState(false);

  useEffect(() => { 
    resetSelectedUsers && setResetSelectedUsers(false)
  }, [resetSelectedUsers])


  if (!!Object.keys(_values).length) {
    values = Object.assign(
      {},
      _values,
      { _hours: _values.hours },
      {
        hours: `${(_values.hours || 0).toString().padStart(2, 0)}:${(
          _values.minutes || 0
        )
          .toString()
          .padStart(2, 0)}`,
      },
      {
        activityprd: (_values.activityprd || []).map(
          (e) => e.id_project_activity
        ),
      },
      { tags: (_values.tagac || []).map((e) => e.id_project_tag) },
      {
        files: !_values.file
          ? []
          : _values.file.map((f) => ({
              name: f.file,
              size: f.size,
              type: f.type,
              id_gp_file: f.id_gp_file,
              id_project_activity: f.id_project_activity,
            })),
      }
    );
  }


  useEffect(()=>{
     
    action.execute('get', Constants.APIEndpoints.PROJECTS+'/activity/users/'+values.id_project_activity, '').then(res => {
     

      SetUsersAssoc({id: res.data.map(g=>g.id_user) ,names: res.data.map(g=>g.name)});



    }).catch(error =>{
    })

}, [])

  
  useEffect(()=>{
     
    action.execute('get', Constants.APIEndpoints.GENERAL_PARAMS, 'general_params').then(res => {
      setGeneralParams(res.data)
    }).catch(error =>{
    })

}, [])

  useEffect(() => {
    let evt = window.$eventBus.on('close-modal-form-activity-project', onClose);

    if ((values.tagac || []).length) {
      setTagValues((values.tagac || []).map((e) => e.id_project_tag));
    }

    setTagOptions(
      (project.tagpj || []).map((e) => ({
        label: e.name,
        color: e.color,
        value: e.id_project_tag,
      }))
    );

    if (values) {
      seInDays(values.time_in_days);
    }

    return function () {
      evt.unSubscribe();
    };
  }, []);

  useEffect(() => {
    if (!!isDuplicate) {
      actions
        .execute('get', Constants.APIRoot + '/api/projects', 'projects')
        .then((response) => setProjects(response.data))
        .catch((error) => console.log('error', error));
    }
  }, []);

  useEffect(() => {
    if (!!isDuplicate) {
      action.execute(
        'get',
        `${APIEndpoints.PROJECT}/activity/subactivity?fk_id_activity=${values.id_project_activity}`,
        'subActivities'
      );
    }
  }, []);

  const handleSubmit = (v) => {
    let dup = false;
    v.hours = v.time;
    if (v.hours_in_days) {
      let hours = generalParams.hours_day;
      v.hours = v.hours * hours;
    }

    if (isDuplicate) {
      v.fk_id_project = projectId > 0 ? projectId : v.fk_id_project;
      delete v.id_project_activity;
      dup = true;
    }
    try {
      window.history.state.state = undefined;
    } catch (e) {}

    try {
      v.tickets = [ticket.id_tickets];
    } catch (e) {}
    const newTags = tagOptions.filter((t) => typeof t.value == 'string')
    onSubmit(
      {
        ...v,
        tagnew:  JSON.stringify(newTags),
        tags: tagOptions.filter((t) => (v.tags || []).includes(t.value)),
      },
    );
 
   onOpenForm(false);
    clearHistory();
      updateProject()
    //window.location.reload();
  };

  let projectSelect =
    projects.length > 0
      ? projects.map((pro) => ({ value: pro.id_project, label: pro.name }))
      : [];

  if (ticket != undefined) {
    values.name = ticket.real_id + ' - ' + ticket.subject;

    values.description = ticket.description;
  }

  if (values) {
    values.hours_in_days = values.time_in_days;
    if (values.hours_in_days && generalParams.hours_day) {
      values.time =
        parseInt(values.hours.split(':')[0]) /
        parseInt(generalParams.hours_day);
    } else {
      values.time = values.hours;
    }
  }

  const setTags = () => {
    let evt = window.$eventBus.on('close-modal-form-activity-project', onClose);

    if ((values.tagac || []).length) {
      setTagValues((values.tagac || []).map((e) => e.id_project_tag));
    }
    setTagOptions(
      (project.tagpj || []).map((e) => ({
        label: e.name,
        color: e.color,
        value: e.id_project_tag,
      }))
    );
    return function () {
      evt.unSubscribe();
    };
  };

  if (values.tagac != undefined && tagValues.length == 0) {
    setTags();
  }

  return (
    <Dialog
      PaperProps={{
        id: 'DialogActivityForm',
      }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      contentTitle={{
        style: { width: '100%' },
      }}
      titleContentProps={{
        style: {
          width: 'calc(100% - 60px)',
        },
      }}
      title={
        <div className="w-100 bar" style={{ zoom: 0.8 }} zoom={0.8}>
          {1 == 2 ? (
            <h4 id="name">{language.ACTIVITY_NAME}</h4>
          ) : (
            <CommonForm
              individual
              values={{
                name: values.name,
              }}
              alert={false}
              onRef={(r) => setForm(r)}
              fields={[
                {
                  type: 'text',
                  name: 'name',
                  label: language.ACTIVITY_NAME,
                  className: 'subject-ticket',
                  required: true,
                },
              ]}
              onChangeField={(f, v) => {
                formPrimary.handleValue(v, f.name);
              }}
            />
          )}
        </div>
      }
      contentProps={{
        style: {
          background: 'white',
        },
      }}
    >
      <CommonForm
        childFields={form.flatMap((e) =>
          e.getFields().map((e) => ({
            name: e.name,
            type: e.type,
            required: e.required,
            toBase64: e.type == 'file',
          }))
        )}
        onRef={(r) => setFormPrimary(r)}
        fixedValue={{
          fk_id_project: idProject,
        }}
        values={values}
        fields={[
          /*#######################################-Informações básicas-#############################################################3*/
          {
            type: 'element',
            content: (v, h) => (
              <div className="wrap mb-3">
                <AppBar title={language.BASIC_INFO} />
                {!!isDuplicate ? (
                  <div className="content pt-0">
                    <CommonForm
                      onRef={(r) => setForm(r)}
                      values={{ fk_id_project: idProject, ...values }}
                      fields={[
                        {
                          col: 8,
                          beforeContent: (
                            <MyHeader
                              title={language.DESCRIPTION}
                              subTitle={language.LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT}
                            />
                          ),
                          type: 'textEditor',
                          name: 'description',
                          required: true,
                        },
                        {
                          col: 4,
                          beforeContent: (
                            <MyHeader
                              title={language.USERS_INVOLVED}
                              subTitle={language.SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_CHANGE}
                            />
                          ),
                          type: 'select',
                          name: 'usersid',
                          label: language.USERS_INVOLVED,
                          isMulti: true,
                          options: options.tech((e) =>
                            (project.users || []).includes(e.id_user)
                          ),
                          required: true,
                        },
                        {
                          col: 4,
                          beforeContent: (
                            <MyHeader
                              title={language.PROJECT}
                              subTitle={language.SELECT_PROJECT_TO_SAVE_DUPLICATE_ACTIVITY}
                            />
                          ),
                          type: 'select',
                          name: 'fk_id_project',
                          label: language.PROJECT,
                          options: projectSelect,
                          required: true,
                        },
                      ]}
                      onChangeField={(f, v) => {
                        if (f.name === 'fk_id_project') {
                          setProjectId(v);
                        }
                        h(v, f.name);
                      }}
                    />
                  </div>
                ) : (
                  <div className="content pt-0">
                    <CommonForm
                      onRef={(r) => setForm(r)}
                      values={valuesState}
                      forceUpdate={true}
                      updateStateForm={true}
                      fields={[
                        {
                          col: 12,
                          beforeContent: (
                            <MyHeader
                              title={language.DESCRIPTION}
                              subTitle={language.LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT}
                            />
                          ),
                          type: 'textEditor',
                          name: 'description',
                          required: true,
                        }, {
                          col: 6,
                          beforeContent: (
                          <MyHeader
                            title={language.CONTRACT}
                            subTitle={language.INFORM_THE_CONTRACT_INVOLVED_IN_THIS_ACTIVITY}
                          />
                          ),
                          label: language.CONTRACT_INVOLVED + ' *',
                          textlabel: language.CONTRACT_INVOLVED + ' *',
                          name: 'contractid',
                          type: 'autocomplete',
                          method: 'POST',
                          methodGetAll: 'POST',
                          showSearchButtom: false,
                          fieldquery: 'name',
                          fieldvaluedb: 'id',
                          fieldlabeldb: 'name',
                          textinit: values.contractid ? values.contract_name : '',
                          idinit: values.contractid ? values.contractid : null,
                          route: `${Constants.APIEndpoints.PROJECTS}/contracts/${idProject}?`,
                          routeAll: `${Constants.APIEndpoints.PROJECTS}/contracts/${idProject}?`,
                          detail: true,
                          required: true,
                        },
                        {
                          col: 6,
                          beforeContent: (
                            <MyHeader
                              title={language.USERS_INVOLVED}
                              subTitle={language.SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_PROJECT}
                            />
                          ),
                          type: 'autocomplete',
                          name: 'usersid',
                          label: language.USERS_INVOLVED,
                          textlabel: language.USERS_INVOLVED,
                          resetValue: resetSelectedUsers,
                          isMulti: true,
                          className: 'tech-group-ticket',
                          method: 'POST',
                          methodGetAll: 'POST',
                          route: `${Constants.APIEndpoints.PROJECTS}/cost/users/${idProject}/${valuesState.contractid}?`,
                          routeAll: `${Constants.APIEndpoints.PROJECTS}/cost/users/${idProject}/${valuesState.contractid}?`,
                          textinit: values.users ? values.users : '',
                          idinit: values.usersid ? values.usersid  : null,
                          fieldquery: 'name',
                          fieldvaluedb: 'id_user',
                          fieldlabeldb: 'user_name',
                          detail: true,
                          methodGetAll: 'POST',
                          textlabel: language.USERS_INVOLVED,
                          required: true,
                        },
                      ]}
                      onChangeField={(f, v) => {
                        h(v, f.name);
                        if (f.name === 'contractid') {
                          v && v !== valuesState.contractid && setResetSelectedUsers(true);
                          setValuesState({ ...valuesState, usersid: [], contractid: v });
                        } else { 
                          setValuesState({ ...valuesState, [f.name]: v });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            ),
          },
          /*#######################################-Período-#############################################################3*/
          {
            type: 'element',
            content: (v, h) => (
              <div className="wrap mb-3">
                <AppBar title={language.PERIOD} />
                <div className="content pt-0">
                  <CommonForm
                    onRef={(r) => setForm(r)}
                    values={values}
                    fields={[
                      {
                        col: 3,
                        beforeContent: (
                          <MyHeader
                            title={language.INITIAL_DATE}
                            subTitle={language.ENTER_THE_START_DATE}
                          />
                        ),
                        type: 'date',
                        name: 'dt_start',
                        label: language.INITIAL_DATE,
                        required: true,
                      },
                      {
                        col: 3,
                        beforeContent: (
                          <MyHeader
                            title={language.END_DATE}
                            subTitle={language.LET_US_KNOW_WHAT_THE_END_DATE_WILL_BE}
                          />
                        ),
                        type: 'date',
                        name: 'dt_end',
                        label: language.END_DATE,
                        required: true,
                      },
                      {
                        col: 2,
                        beforeContent: (
                          <MyHeader
                            title={language.MEASURE_IN_DAIYS}
                            subTitle={language.MEASURE_EFFORT_IN_DAYS}
                          />
                        ),
                        type: 'check',
                        name: 'hours_in_days',
                        style: { marginTop: 40 },
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.EFFORT}
                            subTitle={
                              inDays
                                ? language.INFORM_THE_EFFORT_IN_DAYS_TO_BE_CARRIED_OUT
                                : language.INFORM_WHAT_WILL_BE_THE_EFFORT_IN_HOURS_TO_BE_CARRIED_OUT
                            }
                          />
                        ),
                        type: 'text',
                        name: 'time',
                        label: language.EFFORT,
                        format: inDays ? '' : '##:##',
                        placeholder: inDays ? '' : 'xx:xx',
                        required: true,
                      },

                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.ACTIVITY_PRD}
                            subTitle={language.SELECT_THE_PREDECESSOR_ACTIVITY_FOR_THIS_ACTIVITY}
                          />
                        ),
                        type: 'select',
                        isMulti: true,
                        name: 'activityprd',
                        label: language.ACTIVITY_PRD,
                        options: options.activities_predecessor((e) =>
                          Object.keys(_values).length
                            ? e.id_project_activity !=
                              values.id_project_activity
                            : true
                        ),
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.PRIORIZATION}
                            subTitle={language.SELECT_PRIORIZATION_MAKING_CHANGE}
                          />
                        ),
                        type: 'element',
                        name: 'priorization',
                        required: true,
                        content: (v, h) => (
                          <div className="d-flex aling-items-center content-stars">
                            {[1, 2, 3].map((e) => (
                              <i
                                key={e}
                                onClick={() => h(e, 'priorization')}
                                className={
                                  (e <= v ? 'fas fa-star' : 'far fa-star') +
                                  ' star-btn mr-2'
                                }
                              />
                            ))}
                          </div>
                        ),
                      },
                      {
                        col: 4,
                        beforeContent: (
                          <MyHeader
                            title={language.WEIGHT}
                            subTitle={language.SELECT_WEIGHT_ACTIVITY}
                          />
                        ),
                        type: 'element',
                        name: 'weight',
                        required: false,
                        content: (v, h) => (
                          <div className="d-flex aling-items-center content-stars">
                            {[1, 2, 3, 4, 5].map((e) => (
                              <i
                                key={e}
                                onClick={() => h(e, 'weight')}
                                className={
                                  (e <= v ? 'selected' : '') +
                                  ' fas fa-weight-hanging weight-btn mr-2'
                                }
                              />
                            ))}
                          </div>
                        ),
                      },
                    ]}
                    onChangeField={(f, v) => {
                      if (f.name == 'hours_in_days') {
                        seInDays(v);
                      }
                      h(v, f.name);
                    }}
                  />
                </div>
              </div>
            ),
          },
          /*#######################################-Período-#############################################################3*/
          {
            type: 'element',
            content: (v, h) => (
              <div className="wrap mb-3">
                <AppBar title={language.COMPL_INFO} />
                <div className="content pt-0">
                  <CommonForm
                    onRef={(r) => setForm(r)}
                    values={values}
                    fields={[
                      {
                        col: 3,
                        beforeContent: (
                          <MyHeader
                            title={language.ATTACH}
                            subTitle={language.ATTACH_FILES_HERE}
                          />
                        ),
                        type: 'file',
                        name: 'files',
                        label: language.ATTACH,
                        removeFile: (f) => ondeletefile(f),
                        onClick: (f) => onDownloadFile(f.id_gp_file, f.name),
                      },
                      {
                        col: 9,
                        beforeContent: (
                          <MyHeader
                            title={language.MILESTONE}
                            subTitle={language.CREATE_OR_ADD_THIS_ACTIVITY_TO_AN_ACTIVITY_GROUP}
                          />
                        ),
                        type: 'element',
                        name: 'tags',
                        required: true,
                        content: (v, h) => (
                          <TagCarousel
                            h={h}
                            onRef={(r) => setForm(r)}
                            tagOptions={tagOptions}
                            setTagOptions={setTagOptions}
                            tagValues={tagValues}
                            setTagValues={setTagValues}
                          />
                        ),
                      },
                    ]}
                    onChangeField={(f, v) => {
                      h(v, f.name);
                    }}
                  />
                </div>
              </div>
            ),
          },
        ]}
        beforeButtonElement={
          <Button
            icon="fas fa-times"
            label={language.CANCEL}
            variant="normal"
            color="danger"
            className="mr-3"
            onClick={onClose}
          />
        }
        hiddenSubmit={OnlyOneCommit}
        _onSubmit={() => {
          form.forEach((e) => {
            e.submit();
          });
        }}
        loading="load_activity"
        button={{ label: isDuplicate ? language.DUPLICATE : language.SAVE }}
        onSubmit={(v) => {
          setOnlyOneCommit(true)
            handleSubmit(v)
          }}
      />
    </Dialog>
  );
}
