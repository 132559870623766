import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonGroup,
  IconButton,
  InputLabel,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import Tag from '@common/Tag';
import Constants from '@data/Constants';
import Store from '@data/Store';
import { RefreshOutlined, Visibility } from '@material-ui/icons';
import moment from 'moment';
import ServicePortalWrapper from './ServicePortalWrapper';
import Search from './components/Search';
import ServicePortalModalApprovalDetails from './components/ServicePortalModalApprovalDetails';
import ServicePortalModalApprovalRejectAction from './components/ServicePortalModalApprovalRejectAction';
import { ServicePortalSubHeader } from './components/ServicePortalSubHeader';
import ServicePortalTableWrapper from './components/ServicePortalTableWrapper';
import {
  getApprovalMap,
  parseJwt,
  randomId,
  spFetch,
  useDebounce,
  useServicePortalStyles,
} from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    marginRight: 20,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tableCell: {
    lineHeight: 'inherit',
  },
}));

export default function ServicePortalApprovals({ params }) {
  const { language } = Store;
  const tokenData = parseJwt(sessionStorage.getItem('token'));
  const classes = useStyles();
  const styles = useServicePortalStyles();

  const FLAG_MAP = getApprovalMap(language);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsCount, setRowsCount] = useState(0);

  const [selected, setSelected] = useState([]);
  const [filterText, setFilterText] = useState('');
  const filterTextSearch = useDebounce(filterText, 300);

  const [filterType, setFilterType] = useState({
    [FLAG_MAP.ticket]: false,
    [FLAG_MAP.task]: false,
    [FLAG_MAP.change]: false,
  });

  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(randomId());

  const [modalApprovalDetailData, setModalApprovalDetailData] = useState();
  const [showModalApproveRejectAction, setShowModalApproveRejectAction] =
    useState();

  const HEAD_COLUMNS = [
    {
      id: 'id_',
      label: ' ',
      disablePadding: true,
      cellProps: { padding: 'checkbox' },
      render: ({ row }) => (
        <IconButton
          aria-label={language.VIEW}
          className={classes.iconButton}
          onClick={() => setModalApprovalDetailData(row)}
        >
          <Visibility fontSize="medium" />
        </IconButton>
      ),
    },
    {
      id: 'id',
      label: language.ID,
      disablePadding: true,
    },
    {
      id: 'type',
      label: `${language.TYPE}`,
      cellProps: { size: 'small' },
      render: ({ row }) => {
        const { icon, color, label } = FLAG_MAP[row.type];

        return (
          <Tag
            label={
              <>
                <i className={`mr-1 ${icon}`} style={{ color: color }}></i>
                {label}
              </>
            }
            color="#ffffff"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '13px',
              height: 'auto',
            }}
          />
        );
      },
    },
    {
      id: 'company_name',
      label: language.COMPANY,
    },
    {
      id: 'group_tech_name',
      label: language.TECH_GROUP,
    },
    {
      id: 'dt_cad',
      label: language.DT_CAD,
    },
    {
      id: 'start_date',
      label: language.START_DATE,
    },
    {
      id: 'end_date',
      label: language.END_DATE,
    },
    {
      id: 'sol',
      label: language.REQUESTER,
    },
    {
      id: 'creator',
      label: language.CREATOR,
    },
  ];

  useEffect(() => {
    // getData();
  }, []);

  useEffect(() => {
    getData();
  }, [filterTextSearch, filterType, page, reload]);

  const getData = () => {
    getApprovals();
  };

  const getApprovals = () => {
    setIsLoading(true);
    const qs = `limit=${rowsPerPage}&offset=${page * rowsPerPage}`;
    const url = `${Constants.APIEndpoints.TICKET}/getallapprovals?${qs}`;

    const payload = {
      userApprove: tokenData.iss.id,
      statusFilter: Object.keys(filterType)
        .filter((key) => filterType[key])
        .map((item) => ({ value: item })),
      id: filterTextSearch,
    };

    spFetch(url, 'post', payload)
      .then((response) => {
        const dateFormat = (date) => moment(date).format('DD/MM/YYYY HH:mm:ss');
        let rowCount = 0;
        const data = response.data?.map((item, index) => {
          if (index === 0) rowCount = item.total_count;

          if (item.dt_cad) item.dt_cad = dateFormat(item.dt_cad);
          if (item.start_date) item.start_date = dateFormat(item.start_date);
          if (item.end_date) item.end_date = dateFormat(item.end_date);

          return item;
        });

        setRows(data);
        setRowsCount(rowCount);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterTextChange = (event) => {
    setPage(0);
    setFilterText(event.target.value);
  };

  const handleReload = () => setReload(randomId());

  const handleToggleFlag = (typeKey, value) => {
    setFilterType({ ...filterType, [typeKey]: value });
  };

  return (
    <>
      <ServicePortalSubHeader
        breadcrumbs={[
          <Typography component="span" className={styles.text}>
            {language['SERVICE_PORTAL']['MENU_APPROVAL_CENTER']}
          </Typography>,
        ]}
      />
      <ServicePortalWrapper>
        <div className="tw-w-full tw-overflow-y-auto">
          <Paper variant="outlined" className={classes.paper}>
            <Toolbar className={classes.toolbar}>
              <div className="tw-flex tw-justify-between tw-w-full">
                <div className="tw-flex">
                  <div
                    className={`approval-container ${
                      selected?.length > 0 ? 'expanded' : ''
                    }`}
                  >
                    {selected?.length > 0 && (
                      <div className="tw-flex tw-gap-3 slide-in tw-h-full">
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            setShowModalApproveRejectAction('approve')
                          }
                        >
                          <i className="fas fa-check mr-2"></i>
                          {language.APPROVE}
                        </Button>
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          onClick={() =>
                            setShowModalApproveRejectAction('reject')
                          }
                        >
                          <i className="fas fa-trash-alt mr-2"></i>
                          {language.REJECT}
                        </Button>
                      </div>
                    )}
                  </div>
                  <Search
                    label={language.SEARCH}
                    onChange={handleFilterTextChange}
                  />
                </div>

                <div className="tw-flex tw-gap-5">
                  <IconButton
                    className={classes.iconButton}
                    aria-label={language.REFRESH_PAGE}
                    onClick={handleReload}
                  >
                    <RefreshOutlined fontSize="medium" />
                  </IconButton>

                  <div className="tw-flex tw-flex-col">
                    <InputLabel shrink={true} className={styles.text}>
                      {language.TYPE}
                    </InputLabel>
                    <ButtonGroup size="small">
                      {Object.keys(FLAG_MAP)
                        .filter(
                          (flagKey) => typeof FLAG_MAP[flagKey] !== 'string'
                        )
                        .map((flagKey) => (
                          <Button
                            key={flagKey}
                            onClick={() =>
                              handleToggleFlag(
                                FLAG_MAP[flagKey].label,
                                !filterType[FLAG_MAP[flagKey].label]
                              )
                            }
                            color={
                              filterType[FLAG_MAP[flagKey].label]
                                ? 'primary'
                                : ''
                            }
                            variant={
                              filterType[FLAG_MAP[flagKey].label]
                                ? 'contained'
                                : 'outlined'
                            }
                          >
                            <i
                              className={`mr-1 ${FLAG_MAP[flagKey].icon}`}
                              style={{
                                color: filterType[FLAG_MAP[flagKey].label]
                                  ? 'inherit'
                                  : FLAG_MAP[flagKey].color,
                              }}
                            ></i>
                            {FLAG_MAP[flagKey].label}
                          </Button>
                        ))}
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </Toolbar>

            <ServicePortalTableWrapper
              key={reload}
              isLoading={isLoading}
              columns={HEAD_COLUMNS}
              rows={rows}
              pagination={{
                page: page,
                count: rowsCount,
                rowsPerPage: rowsPerPage,
                enabled: true,
              }}
              selectionEnabled={true}
              selectionKey="id"
              onSelectChange={(data) => setSelected(data)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>

        <ServicePortalModalApprovalDetails
          language={language}
          isOpen={!!modalApprovalDetailData}
          data={modalApprovalDetailData}
          onClose={(reload) => {
            setModalApprovalDetailData(null);
            reload && handleReload();
            reload && setSelected([]);
          }}
          params={params}
          // onConfirm={handleConfirmModalObservant}
        />

        <ServicePortalModalApprovalRejectAction
          language={language}
          isOpen={!!showModalApproveRejectAction}
          type={showModalApproveRejectAction}
          selectedItems={rows.filter((item) => selected.includes(item.id))}
          onClose={(reload) => {
            setShowModalApproveRejectAction(null);
            reload && handleReload();
            reload && setSelected([]);
          }}
        />
      </ServicePortalWrapper>
    </>
  );
}
