import React, { useState, useReducer } from 'react';

// --------------------common--------------------------
import CommonTable from '@common/CommonTable';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import { Tooltip } from 'react-tippy';
import Progress from '@common/Progress';
import SearchInput from '@common/SearchInput';
import Dialog from '@common/Dialog';
import base6 from '@common/Func';
import moment from 'moment';
// ------------------------------icons-----------------------------------------
import SlowIcon from '@images/svg-icon/SlowIcon';
import HightIcon from '@images/svg-icon/HightIcon';
import CriticIcon from '@images/svg-icon/CriticIcon';
import attachFile from '@images/attachFile.png';
import SwitchIos from '@common/SwitchIos';
import RiskView from './../RiskView/index';
// ----------------------------------------------------
import { Avatar, Card, Checkbox, Switch, Badge } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Detail_2 from './Detail_2';
import ProgressA from './ProgressA';
import ProgressTicket from './ProgressTicket';
function ProgressStage({ percent, color, value, form, setForm, type, id }) {
  percent = (percent / 5) * 100;
  return (
    <div className="content-stage-progress">
      {[1, 2, 3, 4, 5].map((e) => (
        <div
          onClick={() => {
            if (type == 'mat') {
              let current_maturity = form.current_maturity;
              current_maturity[id] = e;
              setForm({ current_maturity });
            } else {
              let planned_maturity = form.planned_maturity;
              planned_maturity[id] = e;
              setForm({ planned_maturity });
            }
          }}
          style={{
            backgroundColor: color,
            cursor: 'pointer',
            textAlign: 'center',
          }}
          key={e}
        >
          {parseInt(e) == parseInt(value) ? (
            <i
              class="fas fa-caret-down"
              style={{
                fontSize: '26px',
                marginTop: '-13px',
                color: 'rgb(115 103 103)',
                color: '#D9DDE5',
                transform: 'scaleX(.7)',
              }}
            ></i>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default function ({
  section,
  form,
  setForm,
  save,
  activity,
  usersList,
  users,
  projects,
  APIEndpoints,
  ac,
  UserLoged,
  setMediaprogress,
  setmediaMaturidadeAtual,
  setmediaMaturidadeDesejada,
  toggleAlert,
  riskType,
  idcompany,
  language,
  getDados
}) {
  let [action, setAction] = useState(null);
  let [modalUser, SetModalUser] = useState(false);
  let [secItem, SetSecItem] = useState(0);
  let [usersValue, SetUsersValue] = useState(0);
  let [keyValue, SetKeyValue] = useState('');
  let [modaActivity, SetModaActivity] = useState(false);
  let [modaAnexo, SetModaAnexo] = useState(false);
  let [modaTicket, SetModaTicket] = useState(false);
  let [idProject, SetIdProject] = useState(0);
  let [tickets, setTickets] = useState([]);

  let [modalPriority, SetModalPriority] = useState(false);

  let [dataModal, setdataModal] = useState(false);
  let [dataModalEx, setdataModalEx] = useState(false);
  let [dataValue, setDataValue] = useState('');
  let [modalDetail, SetmodalDetail] = useState(false);
  let [filesItem, setfilesItem] = useState([]);

  const vl = (v) => {
    if (v != null && v != 'null') {
      if (
        parseInt(moment().format('YYYYMMDD')) >
        parseInt(v.substr(6, 4) + '' + v.substr(3, 2) + '' + v.substr(0, 2))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  let [indexOpen, setIndexOpen] = useReducer(
    (state, value) =>
      state.includes(value) ? state.filter((e) => e != value) : [value],
    []
  );

  const handleClick = (event) => {
    setAction(event.currentTarget);
  };
  let open = !!action;
  let anchorEl = action;
  function status(selected) {
    return {
      fill: selected ? 'white' : 'var(--primary)',
      color: selected ? 'warning' : 'primary',
      active: !selected,
    };
  }
  function btn(i) {
    return [
      {
        icon: (e) => (
          <SlowIcon style={{ fill: status(e.selected).fill, width: 50 }} />
        ),
        colorActive: 'primary',
      },
      {
        icon: (e) => (
          <HightIcon style={{ fill: status(e.selected).fill, width: 50 }} />
        ),
        colorActive: 'warning',
      },
      {
        icon: (e) => (
          <CriticIcon style={{ fill: status(e.selected).fill, width: 50 }} />
        ),
        colorActive: 'danger',
      },
    ][i];
  }
  const ticketConnect = () => {
    ac.execute(
      'post',
      APIEndpoints.TICKET + '/ticketFind',
      'tickets',
      [],
      null,
      null,
      null
    ).then((g) => {
      setTickets(g.data);
    });
  };

  const formatDate = (date) => {
    const checkLanguage = language.LOCATION_LANGUAGE === 'pt-BR';

    if (checkLanguage) {
      return date;
    } else {
      let formatDate =
        date.substr(3, 2) + '/' + date.substr(0, 2) + '/' + date.substr(6, 4);
      return formatDate;
    }
  };

  let ad = 0;
  let ap = 0;
  let itens = [];
  section.map((sec, _i) => {

    itens.push(
      {
        first_col: (
          <Button
            onClick={() => setIndexOpen(sec.id)}
            icon={
              indexOpen.includes(sec.id)
                ? 'fas fa-chevron-down'
                : 'fas fa-chevron-right'
            }
            variant="circle"
            label={language.MORE_INFORMATION}
            color="default"
            size={0.8}
            className="expand-info"
            outlined
            style={{
              border: 'none',
            }}
          />
        ),

        actions: (
          <IconButton
            aria-label="More"
            aria-owns={action ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={(c) => {
              handleClick(c);
              SetSecItem(sec.id);
            }}
            style={{ transform: 'rotate(90deg)', padding: 5 }}
          >
            <MoreVertIcon style={{ color: 'var(--success)' }} />
          </IconButton>
        ),

        applicable: (
          <div style={{ zoom: 0.8 }}>
            <SwitchIos
              onClick={() => {
                let applicable = form.applicable;

                if (applicable[sec.id] == true) {
                  applicable[sec.id] = false;
                } else {
                  applicable[sec.id] = true;
                }
                setForm({ applicable: applicable });
              }}
              checked={form.applicable[sec.id]}
            />
          </div>
        ),
        aderence: (
          <div style={{ zoom: 0.8 }}>
            <SwitchIos
              onClick={() => {
                let adherent = form.adherent;

                if (adherent[sec.id] == true) {
                  adherent[sec.id] = false;
                } else {
                  adherent[sec.id] = true;
                }
                setForm({ adherent: adherent });
              }}
              checked={
                form.adherent[sec.id] != undefined
                  ? form.adherent[sec.id]
                  : sec.adherent
              }
            />
          </div>
        ),
        dt_activity: (
          <div className="d-flex align-items-center">
            <div style={{ width: 97 }}>
              {form.execution_date[sec.id] != null &&
                form.execution_date[sec.id] != 'null' &&
                form.execution_date[sec.id] &&
                formatDate(form.execution_date[sec.id])}
            </div>
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="primary"
              outlined
              size={0.75}
              onClick={() => {
                setdataModalEx(true);
                //setDataValue(form.review_period[sec.id]||[])
                SetSecItem(sec.id);
              }}
              style={{
                borderStyle: 'dotted',
              }}
            />
          </div>
        ),
        review_period: (
          <div className="d-flex align-items-center">
            <div style={{ width: 97 }}>
              {' '}
              {form.review_period[sec.id] != null &&
                form.review_period[sec.id] != 'null' &&
                form.review_period[sec.id] &&
                formatDate(form.review_period[sec.id])}
            </div>
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="primary"
              outlined
              size={0.75}
              onClick={() => {
                setdataModal(true);
                //setDataValue(form.review_period[sec.id]||[])
                SetSecItem(sec.id);
              }}
              style={{
                borderStyle: 'dotted',
              }}
            />
          </div>
        ),
        status: (
          <div>
            {sec.pending == 'PENDENTE' ? (
              <i
                style={{
                  color: '#fa9633',
                  marginLeft: '-24px',
                  marginRight: '3px',
                  fontSize: '18px',
                }}
                class="fas fa-exclamation-circle"
              ></i>
            ) : null}

            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  {vl(form.review_period[sec.id]) == false
                    ? language.ON_TIME
                    : language.REVISION_REQUIRED}
                </div>
              }
              position="right"
              theme="dark"
              arrow={true}
            >
              <i
                style={{
                  fontSize: 17,
                  color:
                    vl(form.review_period[sec.id]) == false
                      ? 'var(--success)'
                      : 'var(--danger)',
                }}
                className={
                  vl(form.review_period[sec.id]) == false
                    ? 'fas fa-clipboard-check'
                    : 'fas fa-clipboard'
                }
              />
            </Tooltip>
          </div>
        ),
        activity:
          form.project_activity[sec.id] || sec.fk_id_project ? (
            <ProgressA
              id_project_activity={
                form.project_activity[sec.id] || sec.fk_id_project
              }
              sec={sec}
              activity={activity}
            ></ProgressA>
          ) : form.tickets[sec.id] || sec.fk_id_ticket ? (
            <ProgressTicket
              fk_id_ticket={form.tickets[sec.id] || sec.fk_id_ticket}
              detail_ticket={sec.detail_ticket}
              sec={sec}
            ></ProgressTicket>
          ) : null,

        question: sec.desc,
        priority:
          form.priority[sec.id] == undefined ? (
            <HightIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                SetModalPriority(true);
                SetSecItem(sec.id);
              }}
              style={{ fill: 'var(--success)', width: 14 }}
            />
          ) : form.priority[sec.id] == 0 ? (
            <SlowIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                SetModalPriority(true);
                SetSecItem(sec.id);
              }}
              style={{ fill: 'var(--success)', width: 14 }}
            />
          ) : form.priority[sec.id] == 1 ? (
            <HightIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                SetModalPriority(true);
                SetSecItem(sec.id);
              }}
              style={{ fill: 'var(--warning)', width: 14 }}
            />
          ) : form.priority[sec.id] == 2 ? (
            <CriticIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                SetModalPriority(true);
                SetSecItem(sec.id);
              }}
              style={{ fill: 'var(--danger)', width: 14 }}
            />
          ) : null,
        current_maturity: (
          <ProgressStage
            form={form}
            type={'mat'}
            id={sec.id}
            setForm={setForm}
            value={form.current_maturity[sec.id]}
            color={'#FA9633'}
            percent={2}
          />
        ),
        desired_maturity: (
          <ProgressStage
            setForm={setForm}
            form={form}
            type={'pl'}
            id={sec.id}
            value={form.planned_maturity[sec.id]}
            color={'#009dc0'}
            percent={4}
          />
        ),
        r: (
          <div className="d-flex align-items-center content-avatares">
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>
                    {users
                      .filter((b) =>
                        (form.userR[sec.id] || []).includes(b.id_user)
                      )
                      .map((h) => (
                        <li>{h.name}</li>
                      ))}
                  </strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div>
                <Avatar className="mr-2 avatar">
                  {(form.userR[sec.id] || []).length}
                </Avatar>
              </div>
            </Tooltip>
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="primary"
              outlined
              size={0.75}
              onClick={() => {
                SetModalUser(true);

                SetUsersValue(form.userR[sec.id] || []);
                SetKeyValue('userR');

                SetSecItem(sec.id);
              }}
              style={{
                borderStyle: 'dotted',
              }}
            />
          </div>
        ),
        a: (
          <div className="d-flex align-items-center content-avatares">
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>
                    {users
                      .filter((b) =>
                        (form.userA[sec.id] || []).includes(b.id_user)
                      )
                      .map((h) => (
                        <li>{h.name}</li>
                      ))}
                  </strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div>
                <Avatar className="mr-2 avatar">
                  {(form.userA[sec.id] || []).length}
                </Avatar>
              </div>
            </Tooltip>

            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="primary"
              outlined
              onClick={() => {
                SetModalUser(true);

                SetUsersValue(form.userA[sec.id] || []);
                SetKeyValue('userA');

                SetSecItem(sec.id);
              }}
              size={0.75}
              style={{
                borderStyle: 'dotted',
              }}
            />
          </div>
        ),
        c: (
          <div className="d-flex align-items-center content-avatares">
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>
                    {users
                      .filter((b) =>
                        (form.userI[sec.id] || []).includes(b.id_user)
                      )
                      .map((h) => (
                        <li>{h.name}</li>
                      ))}
                  </strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div>
                <Avatar className="mr-2 avatar">
                  {(form.userC[sec.id] || []).length}
                </Avatar>
              </div>
            </Tooltip>
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="primary"
              outlined
              onClick={() => {
                SetModalUser(true);

                SetUsersValue(form.userC[sec.id] || []);
                SetKeyValue('userC');

                SetSecItem(sec.id);
              }}
              size={0.75}
              style={{
                borderStyle: 'dotted',
              }}
            />
          </div>
        ),
        i: (
          <div className="d-flex align-items-center content-avatares">
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>
                    {users
                      .filter((b) =>
                        (form.userI[sec.id] || []).includes(b.id_user)
                      )
                      .map((h) => (
                        <li>{h.name}</li>
                      ))}
                  </strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div>
                <Avatar className="mr-2 avatar">
                  {(form.userI[sec.id] || []).length}
                </Avatar>
              </div>
            </Tooltip>
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="primary"
              outlined
              size={0.75}
              style={{
                borderStyle: 'dotted',
              }}
              onClick={() => {
                SetModalUser(true);

                SetUsersValue(form.userI[sec.id] || []);
                SetKeyValue('userI');

                SetSecItem(sec.id);
              }}
            />
          </div>
        ),
        attach: (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (form.userR[sec.id] == undefined) {
                toggleAlert(true, language.INFORM_A_PERSON_IN_CHARGE, 'error');
              } else {
                if (form.userR[sec.id].includes(parseInt(UserLoged.id))|| UserLoged.role == 'client') {
                  /*  var items = values.files != undefined
                             ? values.files.map(f => (
                              {
                               name: f.split('|')[0] +" - "+ moment( f.split('|')[3]).format('L'),
                                size:'',
                               type: '',
                               nn: f.split('|')[0],
                               id_tickets_file: f.split('|')[2],
                               fk_id_request: values.fk_id_request,
                               user_cad: '',
                               id_user: ''
                             })):null
             */

                  SetModaAnexo(true);
                  SetSecItem(sec.id);
                } else {
                  toggleAlert(
                    true,
                    language.ONLY_THE_PERSON_IN_CHARGE_CAN_PERFORM_THIS_ACTION,
                    'error'
                  );
                }
              }
            }}
          >
            <Badge
              badgeContent={
                (form.files[sec.id] && form.files[sec.id].length) || 0
              }
              color="secondary"
            >
              <img src={attachFile} alt="" style={{ width: 25 }} />
            </Badge>
          </div>
        ),
        action: (
          <Button
            icon="fas fa-eye"
            label={language.VISUALIZE}
            variant="circle"
            onClick={() => {
              SetmodalDetail(true);
              sec.idd = form.project_activity[sec.id] || form.tickets[sec.id];
              SetSecItem(sec);
            }}
            color="primary"
            outlined
            style={{
              border: 'none',
            }}
          />
        ),
      },
      indexOpen.includes(sec.id)
        ? {
            ConfigCell: {
              cols: {
                col_0: { colSpan: 19 },
              },
            },
            first_col: (
              <Detail_2
                id={form.project_activity[sec.id] || form.tickets[sec.id]}
                action={ac}
                APIEndpoints={APIEndpoints}
                users={users}
                type={form.project_activity[sec.id] ? 'projeto' : 'ticket'}
              />
            ),
          }
        : {}
    );
  });
  return (
    <div className="detail_1">
      <Dialog
        maxWidth="xs"
        title={language.REGISTER_NEW_USER}
        modal={false}
        open={modalUser}
        onClose={() => SetModalUser(false)}
      >
        {' '}
        <div>
          <CommonForm
            individual
            spacing={2}
            gridProps={{
              alignItems: 'center',
            }}
            values={{ user: usersValue }}
            fields={[
              {
                col: 12,
                type: 'listCheckbox',
                name: 'user',
                label: language.USER,
                options: usersList,
              },
            ]}
            onSubmit={(v) => {
              if (keyValue == 'userA') {
                form.userA[secItem] = v.user;
                setForm({ userA: form.userA });
              } else if (keyValue == 'userR') {
                form.userR[secItem] = v.user;
                setForm({ userR: form.userR });
              } else if (keyValue == 'userC') {
                form.userC[secItem] = v.user;
                setForm({ userC: form.userC });
              } else {
                form.userI[secItem] = v.user;
                setForm({ userI: form.userI });
              }

              SetModalUser(false);
            }}
            button={{
              fluid: true,
              style: { height: 40 },
              className: '-mt-4',
            }}
          />
        </div>
      </Dialog>

      <Dialog
        maxWidth="xl"
        title={language.STANDARD_DETAIL}
        modal={false}
        open={modalDetail}
        onClose={() => {
          getDados();
          SetmodalDetail(false)}

        }
      >
        <RiskView
          toggleAlert={toggleAlert}
          UserLoged={UserLoged}
          riskType={riskType}
          idcompany={idcompany}
          secItem={secItem}
          form={form}
          ac={ac}
          APIEndpoints={APIEndpoints}
          users={users}
        ></RiskView>
      </Dialog>

      <Dialog
        maxWidth="xs"
        title={language.PRIORITY}
        modal={false}
        open={modalPriority}
        onClose={() => SetModalPriority(false)}
      >
        <CommonForm
          individual
          spacing={2}
          onSubmit={(v) => {
            form.priority[secItem] = v.priority;
            setForm({ priority: form.priority });
            SetModalPriority(false);
            save();
          }}
          gridProps={{
            alignItems: 'center',
          }}
          fields={[
            {
              name: 'priority',
              label: language.PRIORITY,
              type: 'btnGroup',
              colorInactive: 'primary',
              options: [
                { ...btn(0), value: 0, label: language.LOW },
                { ...btn(1), value: 1, label: language.MEDIUM },
                { ...btn(2), value: 2, label: language.HIGH },
              ],
            },
          ]}
          button={{
            fluid: true,
            style: { height: 40 },
            className: '-mt-4',
          }}
        />
      </Dialog>

      <Dialog
        maxWidth="xs"
        title={language.ATTACH_DATE}
        modal={false}
        open={dataModalEx}
        onClose={() => setdataModalEx(false)}
      >
        {' '}
        <div>
          <CommonForm
            individual
            spacing={2}
            gridProps={{
              alignItems: 'center',
            }}
            fields={[
              {
                col: 12,
                type: 'date',
                name: 'dates',
                label: language.DATE,
              },
            ]}
            onSubmit={(v) => {
              if (moment(v.dates).isBefore(moment())) {
                toggleAlert(true, language.BACKDATING_IS_NOT_ALLOWED, 'error');
              } else {
                form.execution_date[secItem] = moment(v.dates).format(
                  'DD/MM/YYYY'
                );
                setForm({ execution_date: form.execution_date });
                setdataModalEx(false);
              }
            }}
            button={{
              fluid: true,
              style: { height: 40 },
              className: '-mt-4',
            }}
          />
        </div>
      </Dialog>

      <Dialog
        maxWidth="xs"
        title={language.ATTACH_DATE}
        modal={false}
        open={dataModal}
        onClose={() => setdataModal(false)}
      >
        {' '}
        <div>
          <CommonForm
            individual
            spacing={2}
            gridProps={{
              alignItems: 'center',
            }}
            fields={[
              {
                col: 12,
                type: 'date',
                name: 'dates',
                label: language.DATE,
              },
            ]}
            onSubmit={(v) => {
              if (moment(v.dates).isBefore(moment())) {
                toggleAlert(true, language.BACKDATING_IS_NOT_ALLOWED, 'error');
              } else {
                form.review_period[secItem] = moment(v.dates).format(
                  'DD/MM/YYYY'
                );

                setForm({ review_period: form.review_period });
                setdataModal(false);
              }
            }}
            button={{
              fluid: true,
              style: { height: 40 },
              className: '-mt-4',
            }}
          />
        </div>
      </Dialog>

      <Dialog
        maxWidth="xl"
        title={language.LINK_ACTIVITY}
        modal={false}
        open={modaActivity}
        onClose={() => SetModaActivity(false)}
      >
        {' '}
        <div style={{ height: 400 }}>
          <CommonForm
            individual
            spacing={2}
            gridProps={{
              alignItems: 'center',
            }}
            fields={[
              {
                col: 6,
                type: 'select',
                name: 'projeto',
                label: language.PROJECT,
                options: projects
                  .filter((b) => b.deleted == false)
                  .map((g) => ({ value: g.id_project, label: g.name })),
              },
              {
                col: 6,
                type: 'select',
                name: 'atividade',
                label: language.ACTIVITY,
                options: activity
                  .filter((g) => g.id_project === idProject)
                  .map((g) => ({
                    value: g.id_project_activity,
                    label: g.name,
                  })),
              },
            ]}
            onChangeField={(f, v) => {
              if (f.name == 'projeto') {
                SetIdProject(v);
              }
            }}
            onSubmit={(v) => {
              let project_activity = form.project_activity;
              project_activity[secItem] = v.atividade;
              setForm({ project_activity: project_activity });
              SetModaActivity(false);
            }}
            button={{
              fluid: true,
              style: { height: 40 },
              className: '-mt-4',
            }}
          />
        </div>
      </Dialog>

      <Dialog
        maxWidth="xl"
        title={language.ATTACH_FILE}
        modal={false}
        open={modaAnexo}
        onClose={() => SetModaAnexo(false)}
      >
        {' '}
        <div>
          <CommonForm
            individual
            spacing={2}
            gridProps={{
              alignItems: 'center',
            }}
            values={{ files: form.files[secItem] || [] }}
            fields={[
              {
                col: 6,
                type: 'file',
                name: 'files',
                label: language.FILES,
              },
            ]}
            onSubmit={async (v) => {
              let files = [];
              let total = v.files.filter((g) => g.size > 0).length;
              let c = 0;

              Array.from(v.files).forEach((file) => {
                if (file.size > 0) {
                  c++;
                  const promise = new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      if (!!reader.result) {
                        resolve(reader.result);
                      } else {
                        reject(Error('Failed converting to base64'));
                      }
                    };
                  });
                  promise.then((result) => {
                    files.push({
                      file: result,
                      type: file.type,
                      namefile: file.name,
                      size: file.size,
                    });
                    if (c == total) {
                      let item = form.files;
                      item[secItem] = files;

                      setForm({ files: item });
                      SetModaAnexo(false);
                    }
                  });
                } else {
                  files.push({
                    type: file.name,
                    namefile: file.name,
                  });
                }
              });
            }}
            button={{
              fluid: true,
              style: { height: 40 },
              className: '-mt-4',
            }}
          />
        </div>
      </Dialog>

      <Dialog
        maxWidth="xl"
        title={language.BIND_TICKET}
        modal={false}
        open={modaTicket}
        onClose={() => SetModaTicket(false)}
      >
        {' '}
        <div>
          <CommonForm
            individual
            spacing={2}
            gridProps={{
              alignItems: 'center',
            }}
            fields={[
              {
                col: 12,
                name: 'tickets',
                type: 'listCheckbox',
                label: 'Tickets',
                options:
                  (tickets.length > 0 &&
                    tickets.map((u) => ({
                      value: u.id_tickets,
                      label:
                        u.real_id + ' - ' + u.subject + ' - ' + u.namecompany,
                    }))) ||
                  [],
                filtrogmud: true,
                filterarray: tickets
                  .filter((g) => g.stop_ticket === 4)
                  .map((g) => g.id_tickets),
              },
            ]}
            onChangeField={(f, v) => {
              let tickets = form.tickets;
              tickets[secItem] = v;
              setForm({ tickets: tickets });
              SetModaTicket(false);
            }}
            button={{
              fluid: true,
              style: { height: 40 },
              className: '-mt-4',
            }}
          />
        </div>
      </Dialog>

      <CommonTable
        bar={false}
        paper={false}
        className="table_detail_1"
        pagination={false}
        searchColumn
        col={[
          { key: 'first_col', label: '#' },

          { key: 'actions', label: language.ACTIONS },
          {
            key: 'applicable',
            label: language.APPLICABLE,
            thConfig: true,
            style: { paddingLeft: 0 },
          },
          { key: 'aderence', label: language.ADHERENCE },
          {
            key: 'dt_activity',
            label: language.DATE_FOR_THE_ACTIVITY,
            style: { minWidth: 200 },
          },
          {
            key: 'review_period',
            label: language.REVIEW_PERIOD,
            style: { minWidth: 200 },
          },
          { key: 'status', label: language.STATE },
          { key: 'activity', label: language.PROGRESS },
          { key: 'question', label: language.QUESTION, style: { minWidth: 200 } },
          { key: 'priority', label: language.PRIORITY },
          { key: 'current_maturity', label: language.CURRENT_MATURITY },
          { key: 'desired_maturity', label: language.DESIRED_MATURITY },
          {
            key: 'r',
            label: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>{language.RESPONSIBLE}</div>
                }
                position="right"
                theme="dark"
                arrow={true}
              >
                <span
                  style={{ fontSize: 16, color: '#9E24F0', fontWeight: 600 }}
                >
                  R
                </span>
              </Tooltip>
            ),
          },
          {
            key: 'a',
            label: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>{language.APPROVER}</div>
                }
                position="right"
                theme="dark"
                arrow={true}
              >
                <span
                  style={{ fontSize: 16, color: '#92D050', fontWeight: 600 }}
                >
                  A
                </span>
              </Tooltip>
            ),
          },
          {
            key: 'c',
            label: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>{language.CONSULTED}</div>
                }
                position="right"
                theme="dark"
                arrow={true}
              >
                <span
                  style={{ fontSize: 16, color: '#019FC2', fontWeight: 600 }}
                >
                  C
                </span>
              </Tooltip>
            ),
          },
          {
            key: 'i',
            label: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>{language.INFORMED}</div>
                }
                position="right"
                theme="dark"
                arrow={true}
              >
                <span
                  style={{ fontSize: 16, color: '#F66868', fontWeight: 600 }}
                >
                  I
                </span>
              </Tooltip>
            ),
          },
          { key: 'attach', label: language.ATTACHMENTS },
          { key: 'action', label: language.ACTIONS },
        ]}
        data={itens}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAction(null)}>
        {[
          {
            icon: 'fas fa-external-link-alt',
            label: language.ASSOCIATE_WITH_GP,
            color: '#9ed662',
          },
          {
            icon: 'fas fa-external-link-alt',
            label: language.ASSOCIATE_WITH_TK,
            color: '#06bbcd',
          },
        ].map((e, i) => (
          <MenuItem
            onClick={() => {
              if (i == 0) {
                SetModaActivity(true);
              } else if (i == 1) {
                SetModaTicket(true);
                ticketConnect();
              }

              setAction(null);
            }}
          >
            <i className={e.icon} style={{ color: e.color }} />
            <span
              style={{ fontSize: 15, color: 'var(--primary)' }}
              className="ml-2"
            >
              {e.label}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
