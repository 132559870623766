import React, { useState, useEffect, useRef } from 'react';

import CommonForm from '@common/CommonForm2';
import PageLayout from '@common/PageLayout'
import CommonTable from '@common/CommonTable'
import Dialog from '@common/Dialog';
import Button from '@common/Button'
import Store from '@data/Store';
const language = Store.language;
import './index.css'
export default function ({
  action,
  APIEndpoints
}) {
  let [build, setBuild] = useState(0);

  let [editbuild, setEditBuild] = useState([]);
  let [openitem, setOpenItem] = useState(false);
  let [openBuild, setOpenBuild] = useState(false);
  let [builds, setBuilds] = useState([]);
  let [buildItens, setBuildItens] = useState([]);
  let [values, setValues] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    action.execute('post', APIEndpoints.BUILD, 'catalogs', { type: 'selectbuid' }).then(x => {
      setBuilds(x.data)
    })

    action.execute('post', APIEndpoints.BUILD, 'catalogs', { type: 'selectbuilditem' }).then(x => {
      setBuildItens(x.data)
    })
  }

  const savebuild = (v) => {
    //console.log('****', v)
    let method = ''
    v.id_build ? method = 'put' : method = 'post'
    v.type = 'createbuild'
    let url = v.hasOwnProperty('id_build') ? APIEndpoints.BUILD + '/' + v.id_build : APIEndpoints.BUILD;

    action.execute(method, url, '', v).then(x => {
      // setBuilds(x.data)
      setTimeout(() => {
        load();
      }, 2000);
    });

    setOpenBuild(false)
    setOpenItem(false)
  }

  const deleted_ = (v) => {
    v.type = 'deletebuilditem'
    action.execute('post', APIEndpoints.BUILD, '', v).then(x => {
      // setBuilds(x.data)
      setTimeout(() => {
        load();
      }, 2000);
    })
  }

  const delete_build = (v) => {
    v.type = 'deletebuil'
    action.execute('post', APIEndpoints.BUILD, '', v).then(x => {
      // setBuilds(x.data)
      load();
    });
  }

  const closeEdit = () => {
    setOpenItem(false);
    setValues();
  }

  const savebuildItem = (v) => {
    v.type = 'createbuilditem';
    v.id_build = build;

    if (v.id_build_item > 0) {
      v.type = 'editbuilditem'
      v.id_build_item = values.id_build_item
    }

    action.execute('post', APIEndpoints.BUILD, '', v).then(x => {
      // setBuilds(x.data)
      load();
    });

    setOpenBuild(false);
    setOpenItem(false);
    setValues();
  }

  let datas =

    buildItens.filter(c => c.fk_id_build == build).map(v => ({
      check: '', name: v.name
      ,
      action: <div className='d-flex align-items-center justify-content-between'>
        <Button
          variant='circle'
          icon='fas fa-edit'
          label={language.EDIT}
          color='default'
          style={{ fontSize: 12 }}
          size={.7}
          className='mx-2'
          onClick={() => { setValues(v); setOpenItem(true) }}

        />
        <Button
          variant='circle'
          icon='far fa-trash-alt'
          label={language.DELETE}
          color='danger'
          style={{ fontSize: 13 }}
          size={.7}
          onClick={(c) => deleted_(v)}
        />
      </div>
    }))
  return (
    <div id="buildform" >
      <PageLayout
        title={language.REGISTER_BUILD}
        subTitle={' '}
        back
        rightElements={[
          <div className="d-flex align-items-center _btn-group">
          </div>
        ]}
      >
        <div id="selectBuild">
          <div style={{ minWidth: 200 }}>
            <CommonForm
              alert={false}
              fields={[
                {
                  col: 12,
                  type: 'select',
                  name: 'build_id',
                  label: language.BUILD,
                  options: builds.map(c => ({ value: c.id_build, label: c.name })),
                  required: true
                }
              ]}
              onChangeField={(f, v) => {
                setBuild(v);
              }}
            />
          </div>
          <div style={{ marginLeft: '10px' }}>
            <Button
              variant="circle"
              icon="fas fa-plus"
              color="warning"
              className="mr-3"
              onClick={() => setOpenBuild(true)}
              tooltipLabel={language.REGISTER_BUILD}
            />
          </div>
          {build > 0 ? <div style={{ marginLeft: '10px' }}>
            <Button
              variant="circle"
              icon="fas fa-edit"
              color="warning"
              className="mr-3"
              onClick={() => { setEditBuild(builds.filter(c => c.id_build == build)[0]); setOpenBuild(true) }}
              tooltipLabel={language.EDIT_BUILD}
            />  </div>
            : null}

          {build > 0 ? <div style={{ marginLeft: '10px' }}>
            <Button
              variant="circle"
              icon="far fa-times-circle"
              color="danger"
              className="mr-3"
              onClick={() => delete_build(builds.filter(c => c.id_build == build)[0])}
              tooltipLabel="Remover"
            />  </div> : null}

        </div>

        <div>
          <CommonTable
            searchColumn
            print={false}
            paginationTop={false}
            beforeBar={
              <Button
                variant="circle"
                icon="fas fa-plus"
                color="warning"
                className="mr-3"
                onClick={() => setOpenItem(true)}
                tooltipLabel={language.REGISTER_ITEM_FOR_BUILD}
              />
            }
            col={[
              { key: 'check', label: '#', thConfig: true, style: { width: 10 } },
              { key: 'name', label: language.NAME },
              { key: 'action', label: language.ACTION, style: { width: 30 } }
            ]}
            data={datas}
          />
        </div>
      </PageLayout>

      {openBuild &&
        <Dialog
          open={openBuild}
          onClose={() =>setOpenBuild(false)}
          title={language.ADD_NEW_BUILD}
          dialogChildren={
            <div
              className="d-flex align-items-start justify-content-between bar"
              style={{
                borderBottom: '3px solid var(--warning)'
              }}
            />}>
          <CommonForm
            title={language.ADD_NEW_BUILD}
            onSubmit={(g) => savebuild(g)}
            values={editbuild}
            onClose={() => setOpenBuild(false)}
            fields={[
              {
                col: 12,
                type: 'text',
                name: 'name',
                label: language.BUILD,
                required: true
              },
              {
                col: 12,
                type: 'date',
                name: 'start_build',
                label: language.DATE_START_PRODUCTION,
                required: true
              }
            ]}

          />
        </Dialog>
      }
      <Dialog
        maxWidth='lg'
        open={openitem}
        onClose={() => closeEdit()}
        title={language.ADD_NEW_ITEM}
      >
        <CommonForm
          onSubmit={(g) => savebuildItem(g)}
          values={values}
          fields={[
            {
              col: 12,
              type: 'text',
              name: 'name',
              label: language.NAME,
              required: true
            },
            {
              col: 12,
              type: 'textEditor',
              name: 'description',
              label: language.DESCRIPTION,
              required: true
            }
          ]}
        />
      </Dialog>
    </div>
  )
}
