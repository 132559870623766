import Categorize from './Categorize';
import Close from './Close';
import Comment from './Comment';
import Default from './Default';
import Remove from './Remove';
import Resolve from './Resolve';

export const AutomationActionForm = {
  Categorize,
  Close,
  Comment,
  Default,
  Remove,
  Resolve,
};

export const fieldsRequiredActionForm = {
  categorize: [
    { name: 'type_category', required: true },
    { name: 'type_tickets', required: true },
    { name: 'fk_id_category', required: true },
    { name: 'fk_id_catalog_service', required: true },
    { name: 'fk_id_catalog_task', required: true },
    { name: 'fk_id_solicitant', required: true },
    { name: 'fk_id_tech', required: false },
    { name: 'fk_id_contract', required: true },
    { name: 'fk_id_company', required: true },
    { name: 'recurrence', required: false },
  ],
  close: [
    { name: 'type_category', required: true },
    { name: 'type_tickets', required: true },
    { name: 'fk_id_category', required: true },
    { name: 'fk_id_catalog_service', required: true },
    { name: 'fk_id_catalog_task', required: true },
    { name: 'fk_id_solicitant', required: true },
    { name: 'fk_id_tech', required: false },
    { name: 'fk_id_contract', required: true },
    { name: 'fk_id_company', required: true },
    { name: 'recurrence', required: false },
    { name: 'time', required: true },
    { name: 'description_details', required: true },
  ],
  comment: [
    { name: 'type_category', required: true },
    { name: 'fk_id_company', required: true },
  ],
  remove: [
    { name: 'type_category', required: true },
    { name: 'fk_id_company', required: true },
    { name: 'fk_id_solicitant', required: true },
    { name: 'fk_id_tech', required: false },
  ],
  resolve: [
    { name: 'type_category', required: true },
    { name: 'fk_id_company', required: true },
  ],
  default: [{ name: 'type_category', required: true }],
};
