import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import { Card, Chip } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';

import Button from '../../common/Button';
import { filterObj } from '../../common/Func';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';

@observer
export default class CategoryList extends Component {
  render() {
    let {
      language,
      handlePage,
      handlePageDelete,
      onFormSubmit,
      redirect,
      categories,
      values,
      sendvalues,
    } = this.props;

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10, textAlign: 'center' },
      },
      { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
      {
        key: 'description',
        label: language.DESCRIPTION,
        style: { textAlign: 'center' },
      },
      { key: 'date', label: language.DATE_CAD, style: { textAlign: 'center' } },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 60, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data = categories.map((obj) => {
      return {
        id: obj.id_category,
        name: obj.name,
        description: obj.description,
        date: moment(obj.dt_cad).format('L'),
        action: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="circle"
                    size={0.7}
                    icon="fas fa-plus"
                    label={language.ADD_CATALOG}
                    color="primaryGradient"
                    style={{ fontSize: 14 }}
                    onClick={() => handlePage('add/catalog', obj)}
                  />
                  <Button
                    variant="circle"
                    size={0.7}
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12 }}
                    className="mx-2"
                    onClick={() => handlePage('edit', obj)}
                  />
                  <Button
                    variant="circle"
                    size={0.7}
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    _loading={'del-category-item-1' + obj.id_category}
                    style={{ fontSize: 14 }}
                    onClick={() => handlePageDelete(true, obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          values={values}
          index="CategoryList"
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          searchColumn
          orderColumn
          beforeBar={
            <div>
              <Button
                variant="circle"
                icon="fas fa-plus"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                label={language.NEW_CATEGORY}
                primary={true}
                onClick={() => handlePage('new')}
              />
              <Button
                variant="circle"
                icon="fas fa-file-export"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                label={'Export Excel'}
                primary={true}
                onClick={() => sendvalues(1)}
              />
            </div>
          }
        />
      </Fragment>
    );
  }
}
