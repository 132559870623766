import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '@common/CommonPage';
import GSDPage from './GSDPage';

@observer
export default class GSDContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      dt_inicial: '',
      dt_final: '',
      gsddash: [],
      requests: [],
    };
    this.getDashboard = this.getDashboard.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  updateState() {
    this.getDashboard();
  }
  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/20',
      'type_solution'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/60',
      'priorities'
    );
    this.action.execute('get', this.APIEndpoints.ANSWER, 'answer');
    this.action.execute('get', this.APIEndpoints.TICKET + '/params', 'params');
    this.action.execute(
      'get',
      this.APIEndpoints.STATUS_AWAITING,
      'status_awaiting'
    );

    this.getDashboard();
    this.updateState();
  }

  getDashboard(values) {
    if (values == 'update') {
      let t = this.store.ticket;
      this.store.gsddash = this.state.gsddash.map((g) => {
        if (g.id_tickets == t.id_tickets) {
          g.stop_ticket = t.config[0].stop_ticket;
          g.progress = t.config[0].progress;
          g.status = t.status;
          return g;
        } else {
          return g;
        }
      });
    } else {
    }
    let obj = [];
    obj = values;

    if (values && values.dtinicial && values.dtfinal) {
      this.setState({
        dt_inicial: values.dtinicial,
        dt_final: values.dtfinal,
      });
    }

    if (values != 'update') {
      this.action.execute('post', this.APIEndpoints.REQUEST+'/list', '',{limit:10, offset:0}).then(res => {
        this.setState({requests : res.data})
      })

    }
  }

  render() {
    let { language, users, itensgsd } = this.store;
    let { gsddash, requests } = this.state;

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        loading={false}
      >
        {gsddash ? (
          <GSDPage
            users={users}
            itensgsd={itensgsd}
            requests={requests}
            dt_inicial={this.state.dt_inicial}
            dt_final={this.state.dt_final}
            redirect={this.redirect}
            language={language}
            redirect2 = {this.redirect2}
            {...this.state}
            dashboard={gsddash}
            getDashboard={this.getDashboard}
          />
        ) : null}
      </CommonPage>
    );
  }
}
