import React, { Component, Fragment } from 'react';
import RaisedButton from '../../common/RaisedButton';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Switch } from '@material-ui/core';
import { Card, Chip } from '@material-ui/core';
import Button from '../../common/Button';
import { observer } from 'mobx-react';
import CommonTable from '../../common/CommonTable';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import { filterObj } from '@common/Func';
import Constants from '../../data/Constants';
import Store from '@data/Store';

const groupTypes = {
  1: 'Grupo de Técnicos',
  2: 'Grupo de Solicitantes',
  3: 'Grupo de Aprovadores',
  4: 'Grupo de Notificações'
};

@observer
export default class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
    };
  }

  render() {
    let {
      language,
      handlePage,
      redirect,
      groups,
      callPagination,
      callDataFilter,
      callPageSelectFilter,
      countTotal,
      cleanFilters
    } = this.props;

    let col = [
      { key: 'id', label: language.ID, style: { width: 10 } },
      { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
      {
        key: 'description',
        label: language.DESCRIPTION,
        style: { textAlign: 'center' },
      },
      {
        key: 'name_company',
        label: language.COMPANY,
        style: { textAlign: 'center' },
      },
      {
        key: 'type',
        label: language.LEVEL,
        style: { width: 200, textAlign: 'center' },
        thConfig: true,
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 60, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data =
      groups &&
      groups.map((obj) => {
        return {
          id: obj.id_group_users,
          name: obj.name,
          description: obj.description,
          name_company: obj.name_company,
          type: groupTypes[obj.type],
          actions: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="fas fa-plus"
                      label={language.ADD_USER_TO_GROUP}
                      color="primaryGradient"
                      style={{ fontSize: 14 }}
                      size={0.7}
                      onClick={() => handlePage('add/user', obj)}
                    />
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 13 }}
                      size={0.7}
                      className="mx-2"
                      onClick={() => handlePage('edit', obj)}
                    />
                    <RaisedButton
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      size={0.7}
                      _loading={'delete-group-item-' + obj.id_group_users}
                      onClick={() => handlePage('remove', obj)}
                    />
                  </div>
                }
              />
            </React.Fragment>
          ),
        };
      });

    let { configurationProps = {} } = this.props;


    return (
      <Fragment>
        <CommonTable
          title={''}
          language={Store.language}
          columns={col}
          index="GroupList"
          loadSearch={true}
          data={groups.map((obj) => {
            return {
              id: obj.id_group_users,
              name: obj.name,
              description: obj.description,
              name_company: obj.name_company,
              type: groupTypes[obj.type],
              actions: (
                <React.Fragment>
                  <Chip
                    className="mx-2 muit-chip"
                    label={
                      <div className="d-flex align-items-center justify-content-between">
                        <Button
                          variant="circle"
                          icon="fas fa-plus"
                          label={language.ADD_USER_TO_GROUP}
                          color="primaryGradient"
                          style={{ fontSize: 14 }}
                          size={0.7}
                          onClick={() => handlePage('add/user', obj)}
                        />
                        <Button
                          variant="circle"
                          icon="fas fa-edit"
                          label={language.EDIT}
                          color="default"
                          style={{ fontSize: 13 }}
                          size={0.7}
                          className="mx-2"
                          onClick={() => handlePage('edit', obj)}
                        />
                        <Button
                          variant="circle"
                          icon="far fa-trash-alt"
                          label={language.DELETE}
                          color="danger"
                          style={{ fontSize: 14 }}
                          size={0.7}
                          _loading={'delete-group-item-' + obj.id_group_users}
                          onClick={() => handlePage('remove', obj)}
                        />
                      </div>
                    }
                  />
                </React.Fragment>
              ),
            };
          })}
          showHeaderToolbar
          searchColumn
          orderColumn
          paginationTop={false}
          searchColumn={true}
          isAutomaticPagination={false}
          callPageSelect={(page) => callPagination(page)}
          callDataFilter={(values) => callDataFilter(values)}
          searchColumnEvent={(v)=>{callPageSelectFilter(0,v,75,'filter')}}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          onClearAllFilter={() => cleanFilters()}

          countTotal={countTotal}
          beforeBar2={configurationProps.control}
          beforeBar={
            <Button
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.NEW_GROUP}
              primary={true}
              onClick={() => handlePage('new')}
            />
          }
        />
      </Fragment>
    );
  }
}
