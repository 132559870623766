import '../../../public/css/pages.css';

import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import DeviceForm from './DeviceForm';
import DeviceList from './DeviceList';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
import Header from '@common/Header';
import Dialog from '@common/Dialog';
import DeviceFormIcmdb from './DeviceFormIcmdb';
import DeviceFormRelationshipLogs from './DeviceFormRelationshipLogs';
import Blackout from './Blackout';
import DeviceContact from './DeviceContact';
const crypto = require('crypto');
var CryptoJS = require("crypto-js");
@observer
export default class DeviceContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      index: 0,
      page: 'list',
      clone: '',
      edit: false,
      valueEdit: [],
      companies: [],
      pageic: 0
    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  decryp(aMsg = '') {
    try {
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, "");
      let decipher, tRet;
      decipher = crypto.createDecipher("aes-256-cbc", aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
      tRet += decipher.final("utf8");

      return tRet;
    } catch (e) {
      return {}
    }

  }
  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.DEVICETYPE, 'devicetype');
    this.action.execute('get', this.APIEndpoints.CONTRACT + 'table', 'contracts');
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }, null, null, null
    );
    try {
      let route = this.props.brand;
      this.action.execute('get', this.APIEndpoints.SNMP + '/getmanufactured', 'manufactures');
      this.action.execute('get', this.APIEndpoints.SNMP + '/getlistbrand', 'brands');

      let history = this.history2;
      let state = history.location.state;

      if (!state && !testRoute('/dash/deviceInventory', PAGE)) {
        this.setState({ page: 'cad' });
      }
    } catch (e) { }
  }



  onFormSubmit(values, inv) {
    if (values.user_cad.length == 0 || values.contracts.length == 0) {
      this.store.toggleAlert(true, 'Informe os campos obrigatórios!!', 'error');
    } else {
      let method = values.hasOwnProperty('id_device') && this.state.page != 'clone' ? 'put' : 'post';
      let url = values.hasOwnProperty('id_device') && this.state.page != 'clone'
        ? this.APIEndpoints.DEVICE + '/' + values.id_device
        : this.APIEndpoints.DEVICE;

      this.action.execute(method, url, 'devices', values, 'id_device')
        .then(
          d => {
            let id = values.hasOwnProperty('id_device') ? values.id_device : d.data.id_device

            if (values.files) {
              let deleteFiles = [];

              if (this.state.valueEdit.hasOwnProperty('files')) {
                deleteFiles = this.state.valueEdit.files.filter(f => !values.files.includes(f));
              }

              if (deleteFiles.length > 0 && deleteFiles[0].db == 'filedb') {
                Array.from(deleteFiles).forEach(file => {
                  this.action.execute('put', this.APIEndpoints.DEVICE + '/devicefiles/' + id, '', file)
                });
              }

              Array.from(values.files).forEach(file => {
                const promise = new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    if (!!reader.result) {
                      resolve(reader.result);
                    } else {
                      reject(Error('Failed converting to base64'));
                    }
                  };
                });
                try {
                  let data_b = atob(file.data)
                  let upload = {
                    id_device: id,
                    file: file.data,
                    namefile: file.name,
                  };
                  this.action.execute('post', this.APIEndpoints.DEVICE + '/uploadDevice', '', upload)

                } catch (e) {
                  promise.then(
                    result => {
                      let upload = {
                        id_device: id,
                        file: result,
                        type: file.type,
                        namefile: file.name,
                      };

                      this.action.execute('post', this.APIEndpoints.DEVICE + '/uploadDevice', '', upload)
                    },
                    err => { }
                  );

                }
              });
            }
            this.setState({ edit: false, page: 'list' })
          }
        );
    }
  }

  deviceEdit(state) {
    this.action
      .execute(
        'get',
        `${this.APIEndpoints.DEVICE + '/' + state.id_device}`,
        'device'
      )
      .then(res => {
        this.setState({ valueEdit: res.data[0], edit: true });
      });
  }

  onDelete() {
    let { values } = this.state;
    this.action.execute(
      'delete',
      this.APIEndpoints.DEVICE + '/' + values.id_device,
      'devices',
      null,
      'id_device'
    );
    this.handlePage('list');
    this.redirect('/dash/configuration/device');
  }

  handlePage(page, edit, clone) {

    let tokenApi = this.store.getToken();
    if (page != 'new' && edit) {
      this.action
        .execute(
          'get',
          `${this.APIEndpoints.DEVICE + '/' + edit.id_device}`,
          'devices'
        )
        .then(res => {
          this.setState({ valueEdit: res.data[0], edit: true, page: page, values: edit });
        });

    } else {
      this.setState({ page: page, values: edit, clone: clone });
    }

  }

  render() {
    let { language, devices, users, devicetype, contracts } = this.store;
    let { page, clone, values, companies } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    let redirect1 = this.redirect;
    let PAGE = history.location.pathname;
    let state = history.location.state;
    let inv = false;

    if (page == 'deviceInventory') {
      values = devices.find(e => e.id_device == state.id_device);

      inv = true;
    }

    let { configurationProps = {} } = this.props;
    let CMDB = <DeviceFormIcmdb
      language={language}
      handlePage={this.handlePage}
      onFormSubmit={this.onFormSubmit}
      values={this.state.valueEdit}
      inv={inv}
      typeDevice={this.state.valueEdit && this.state.valueEdit.namedevicetype ? this.state.valueEdit.namedevicetype : ''}

      snmp={true}
      companies={companies}
      users={users}
      contracts={this.store.contracts}
      user={this.store.getUserLoged()}
      manufactures={this.store.manufactures}
      brands={this.store.brands}
      devicetype={devicetype}
      redirect1={redirect1}
      clone={testRoute('/dash/device/clone', PAGE) ? 'clone' : ''}
      action={this.action}
      APIEndpoints={this.APIEndpoints}
      redirect={this.redirect}
    />


    return (
      <Fragment>
        <Animate>
          <DeviceList
            page={this.page}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
            devices={devices}
            companies={companies}
            contracts={contracts}
            redirect={redirect}
            user={this.store.getUserLoged()}
            devicetype={devicetype}
            configurationProps={configurationProps}
            usercon_company={this.store.usercon_company}
          />
        </Animate>

        {(page === 'edit' || page === 'clone') && this.state.edit && (
          <Dialog
            title={
              values && clone
                ? language.CLONE
                : values
                  ? language.EDIT_DEVICE
                  : language.ADD_NEW_DEVICE
            }
            open={true}
            onClose={() => { this.setState({ pageic: 0 }); this.handlePage('list') }}
            maxWidth="xl"
          >
            <Header
              btnFilter={[language.REGISTER, 'CMDB', language.RELATIONSHIP, 'Blackout', language.CONTACTS]}
              onChange={(e) => this.setState({ pageic: e })}
              style={{ marginBottom: '40px' }}
            />

            {this.state.pageic == 0 && (
              <DeviceForm
                language={language}
                handlePage={this.handlePage}
                onFormSubmit={this.onFormSubmit}
                values={this.state.valueEdit}
                inv={inv}
                companies={companies}
                redirect1={redirect1}
                contracts={contracts}
                usercon_company={this.store.usercon_company}
                users={users}
                user={this.store.getUserLoged()}
                devicetype={devicetype}
                clone={page == 'clone' ? 'clone' : ''}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                redirect={redirect}
              />
            )}

            {this.state.pageic == 1 && ( CMDB )}

            {this.state.pageic == 2 && (
              <DeviceFormRelationshipLogs
                language={language}
                values={this.state.valueEdit}
                store={this.store}
                action={this.action}
              />
            )}

            {this.state.pageic == 3 && (
              <Blackout
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                language={language}
                device={this.state.valueEdit}
              />
            )}
            {this.state.pageic == 4 && (
              <DeviceContact device={this.state.valueEdit} />
            )}

          </Dialog>
        )}

        {['new', 'deviceInventory'].includes(page) && (
          <Dialog
            title={
              values && clone
                ? language.CLONE
                : values
                  ? language.EDIT_DEVICE
                  : language.ADD_NEW_DEVICE
            }
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
          >
            <Header
              btnFilter={[language.REGISTER, 'CMDB', language.RELATIONSHIP]}
              onChange={(e) => this.setState({ pageic: e })}
              style={{ marginBottom: '40px' }}
            />
            {this.state.pageic == 0 ?
              <DeviceForm
                redirect1={redirect1}
                language={language}
                handlePage={this.handlePage}
                contracts={contracts}
                onFormSubmit={this.onFormSubmit}
                values={values}
                inv={inv}
                companies={companies}
                usercon_company={this.store.usercon_company}
                users={users}
                user={this.store.getUserLoged()}
                devicetype={devicetype}
                clone={page ? 'clone' : ''}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                redirect={redirect}
              />
              : this.state.pageic == 1
                ? CMDB
                : <DeviceFormRelationshipLogs
                  language={language}
                  values={this.state.valueEdit}
                  store={this.store}
                  action={this.action}
                />
            }
          </Dialog>
        )}

        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={this.state.values.name}
          />
        ) : null}
        {page === 'cad' && (
          <Dialog
            title={
              values && clone
                ? language.CLONE
                : values
                  ? language.EDIT_DEVICE
                  : language.ADD_NEW_DEVICE
            }
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
          >
          </Dialog>
        )}

      </Fragment>
    );
  }
}
