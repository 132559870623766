import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { Card, Paper, Checkbox } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import { Tooltip } from 'react-tippy';
import ReactTable from 'react-table';
import { FaQuestionCircle, FaClose } from 'react-icons/lib/fa';
import 'react-table/react-table.css';
import moment from 'moment';
export default class DeviceSolicitation extends Component {
  constructor() {
    super();
    this.state = {
      typeout: '',
      errorSt: '',
      btn: '',
      id_sponso: '',
      description: ''
    };

    this.onChangeField = this.onChangeField.bind(this);
    this.opendescription = this.opendescription.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ description: '' });
  }

  opendescription(value) {
    this.setState({ description: value });
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_contract') {
      this.setState({
        typeout: value
      });
    }
  }

  render() {
    let {
      language,
      handlePage,
      onFormSubmitSolc,
      values,
      users,
      contracts,
      solicitations,
      id_user,
      updateStatus
    } = this.props;
    let { typeout, errorSt, btn, id_sponso, description } = this.state;

    id_user = 15;
    let listUsers = [];
    if (users != undefined) {
      listUsers = users
        .filter(user => user.id_company == values.fk_id_company)
        .map(u => ({ value: u.id_user, text: u.name }));
    }
    let listcontracts = [];
    if (contracts != undefined) {
      contracts.map(d => {
        d.device.map(f => {
          if (f.id_device === values.fk_id_device) {
            listcontracts.push({ value: d.id_contract, text: d.name });
          }
        });
      });
    }

    if (solicitations != undefined) {
      let col = [
        { Header: language.DESCRIPTION, accessor: 'user' },
        { Header: language.COMPANY, accessor: 'company' },
        { Header: language.SUBJECT, accessor: 'subject' },
        { Header: language.DATE_CAD, accessor: 'dt_cad' },

        { Header: language.SLA_AGREED, accessor: 'slaTime' },
        { Header: language.SLA_PROGRESS_GRID, accessor: 'sla' },
        { Header: language.TIME_SPENT, accessor: 'timespent' },
        { Header: language.PROGRESS, accessor: 'progress' },
        { Header: language.STATE, accessor: 'status' },
        { Header: language.ACTION, accessor: 'detail' }
      ];
    }

    let col = [
      { Header: '#', accessor: 'view', width: 40 },
      { Header: language.CONTRACT, accessor: 'contract' },
      { Header: '', accessor: 's', width: 40 },

      { Header: 'Solicitation', accessor: 'solicitation' },
      { Header: '', accessor: 'p', width: 40 },
      { Header: 'Sponsor', accessor: 'sponso' },
      { Header: 'data', accessor: 'data' },
      { Header: '#', accessor: 'action' }
    ];

    /** ( <div>{t.check_solicitation == null  ?
          <FaQuestionCircle  className='pointer' color='var(--orange)'
          fontSize={21} data-tip={language.APPROVE} style={{marginTop:3}} />

            :    <div  className="finishedStatusIconCheckIcon"
            style={{ width: 15, height: 15 }}  />

          } {t.solicitation}   </div>     ) */

    // { Header: 'Description', accessor:'description' },
    //description : t.description,

    /**
 * <div style={{ width: 20, height: 20, background:'#F44336', borderRadius: '50%', float: 'left', marginRight: 5 }}/>
          <div style={{ width: 20, height: 20, background:'#DAA520', borderRadius: '50%', float: 'left', marginRight: 5 }}/>
          <div style={{ width: 20, height: 20, background: '#85cc00', borderRadius: '50%', float: 'left', marginRight: 5 }}/>
 *
 */

    let data = solicitations.map(t => {
      return {
        view: (
          <div
            style={{ width: 25, height: 25 }}
            className="eyeIcon pointer"
            onClick={() => this.opendescription(t.description)}
          />
        ),
        contract: t.contract,
        s:
          t.check_solicitation == null ? (
            <div
              style={{
                width: 20,
                height: 20,
                background: '#DAA520',
                borderRadius: '50%',
                float: 'left',
                marginRight: 5
              }}
            />
          ) : t.check_solicitation == null ? (
            <div
              style={{
                width: 20,
                height: 20,
                background: '#85cc00',
                borderRadius: '50%',
                float: 'left',
                marginRight: 5
              }}
            />
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
                background: '#F44336',
                borderRadius: '50%',
                float: 'left',
                marginRight: 5
              }}
            />
          ),
        solicitation: t.solicitation,

        //removeIcon pointer
        sponso: t.sponso,
        p:
          t.check_approver == null ? (
            <div
              style={{
                width: 20,
                height: 20,
                background: '#DAA520',
                borderRadius: '50%',
                float: 'left',
                marginRight: 5
              }}
            />
          ) : t.check_approver == true ? (
            <div
              style={{
                width: 20,
                height: 20,
                background: '#85cc00',
                borderRadius: '50%',
                float: 'left',
                marginRight: 5
              }}
            />
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
                background: '#DAA520',
                borderRadius: '50%',
                float: 'left',
                marginRight: 5
              }}
            />
          ),

        contract: t.contract,
        data: moment(t.dt_ini).format('DD/MM/YYYY'),
        action:
          t.fk_id_sponsor == id_user && t.check_approver == null ? (
            <div>
              {' '}
              <RaisedButton
                label={language.YES}
                onClick={() =>
                  updateStatus({
                    check: 'true',
                    tipo: 'sponso',
                    id_device_solicitation: t.id_device_solicitation,
                    id_device: t.fk_id_device
                  })
                }
                primary
              />
              <RaisedButton
                onClick={() =>
                  updateStatus({
                    check: 'false',
                    tipo: 'sponso',
                    id_device_solicitation: t.id_device_solicitation,
                    id_device: t.fk_id_device
                  })
                }
                primary
                buttonStyle={{ background: 'var(--secundary)' }}
                label={language.CANCEL}
              />{' '}
            </div>
          ) : t.id_user_solicitation == id_user && t.check_approver == null ? (
            <div>
              {' '}
              <RaisedButton
                label={language.YES}
                onClick={() =>
                  updateStatus({
                    check: 'true',
                    tipo: 'sol',
                    id_device_solicitation: t.id_device_solicitation,
                    id_device: t.fk_id_device
                  })
                }
                primary
              />
              <RaisedButton
                onClick={() =>
                  updateStatus({
                    check: 'false',
                    tipo: 'sol',
                    id_device_solicitation: t.id_device_solicitation,
                    id_device: t.fk_id_device
                  })
                }
                primary
                buttonStyle={{ background: 'var(--secundary)' }}
                label={language.CANCEL}
              />{' '}
            </div>
          ) : null
      };
    });

    if (id_sponso != '') {
    }

    let fields = [
      {
        span: 4,
        name: 'dt_ini',
        label: language.INITIAL_DATE,
        type: 'date',
        required: true
      },
      { span: 4, name: 'dt_end', label: language.END_DATE, type: 'date' },
      {
        span: 12,
        name: 'description',
        label: language.COMPL_INFO,
        type: 'textEditor',
        required: true
      },
      {
        span: 4,
        name: 'fk_id_contract',
        label: language.CONTRACT,
        type: 'select',
        options: listcontracts,
        required: true
      },
      {
        span: 4,
        name: 'id_user_solicitation',
        label: language.REQUESTER,
        type: 'select',
        options: listUsers,
        required: true
      }
    ];

    let button = {
      md: 12,
      label: language.SAVE,
      primary: true,
      disabled: false,
      style: { marginTop: 5 }
    };
    let buttons = {
      md: 12,
      label: language.SAVE,
      primary: true,
      disabled: true,
      style: { marginTop: 5 }
    };

    return (
      <Dialog

        open={true}
        autoDetectWindowHeight={false}
        autoScrollBodyContent={false}
        contentStyle={{ width: '80%', maxWidth: 'none' }}
        onClose={() => (description != '' ? this.onClose() : handlePage('list', ''))}
        title={'Solicitação de Dispositivos'}
      >

        <div className="scroll">
          {description != '' ? (
            <p
              style={{ color: '#888080' }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : null}

          <CommonForm
            onChangeField={this.onChangeField}
            values={values}
            language={language}
            onChangeField={this.onChangeField}
            fields={fields}
            onFormSubmit={onFormSubmitSolc}
            button={btn === 'error' ? buttons : button}
          />

          <PageLayout
            icon={
              <div
                className="inventaryIcon"
                style={{ width: 40, height: 40 }}
              />
            }
            title={'Lista de Aprovações'}
            subtitle={language.DEVICE_CONFIG}
            rightElements={[]}
          >
            <Card style={{ marginTop: '20px' }}>
              <ReactTable
                data={data}
                filterable={false}
                columns={col}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </Card>
          </PageLayout>
        </div>

      </Dialog>
    );
  }
}
