import React, { memo, useState, Fragment } from 'react';
import Store from '@data/Store';


/*-----------------------common-------------------*/
import Progress from '@common/Progress';
import MenuTooltip from '@common/MenuTooltip';
/*-----------------------material-ui-------------------*/
import { Avatar } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import { Draggable } from 'react-drag-and-drop';


export default memo(({
   avatares,
   stars,
   days,
   description,
   date,
   hour,
   color,
   onDelete,
   nameprojeto,
   onClickStar,
   handleModalId,
   activity,
   language,
   onAdd_,
   redirect2,
   setViewOpen,
   setFormOpen,
   setFormOpenProject
}) => {


   return (
      <Fragment>

         <Draggable type="milestone" className='w-100' data={JSON.stringify(activity)}>
            <div
               className='Card'

            >
               <div className='menu content-medium' style={{ backgroundColor: color }}>
                  {avatares.slice(0, 3).map((e, i) => (
                     <Tooltip
                        html={
                           <div style={{ color: '#fff', fontSize: 11 }}>
                              {e.name}
                           </div>
                        }
                        position="right"

                        interactive
                     >
                        <Avatar src={e.src} key={e.name + '-' + i} className='avatar'>
                           {e.name.slice(0, 2)}
                        </Avatar>
                     </Tooltip>

                  ))}

                  {avatares.length > 3 &&
                     <Tooltip
                        html={
                           <div style={{ color: '#fff', fontSize: 11 }}>
                              {avatares.slice(3).map((u, i) => (
                                 <div
                                    onClick={() => onAdd_(activity.pj ? 1 : 2, activity.pj ? activity :
                                       activity.ticket
                                       , activity.pj)}
                                    style={{ marginLeft: '5px', marginTop: '2px' }}
                                    className="d-flex align-items-center"
                                    key={u.name + '-' + i}
                                 >
                                    <Avatar
                                       className={'avatar'}
                                       key={i}
                                       style={{
                                          fontSize: '10px',
                                          width: '17px',
                                          height: '17px',

                                       }}
                                    >
                                       {u.name.slice(0, 2)}
                                    </Avatar>
                                    <span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
                                 </div>
                              ))}
                           </div>
                        }
                        position="right"
                        theme="light"
                        interactive
                     >

                        <span className='more'>+{avatares.length - 3}</span>

                     </Tooltip>
                  }
               </div>
               <div
                  className='body content-medium'

               >
                  <div
                     className='d-flex align-items-center justify-content-between content-header'
                  >
                     <div className='d-flex align-items-center content-star'>
                        {[1, 2, 3].map(e => (
                           <i className={e > stars ? 'far fa-star' : 'fas fa-star'} onClick={() => onClickStar(e)} />
                        ))}
                     </div>
                     <span className='duration'>
                        {activity.reloads && activity.reloads.length > 0 &&
                           <Tooltip
                              html={
                                 <div style={{ color: '#fff', fontSize: 11 }}>
                                    {activity.reloads.map((u, i) => (

                                       <div>{u}</div>
                                    ))}
                                 </div>
                              }
                              position="left"
                              theme="dark"
                              interactive

                           >
                              <i style={{ color: 'var(--danger)' }} class="fas fa-redo-alt">
                                 <span style={{ marginLeft: '5px' }} >{activity.reloads && activity.reloads.length}</span></i>


                           </Tooltip>}
                     </span>

                  </div>
                  <Tooltip
                     html={
                        <div style={{ color: 'white', fontSize: 11 }}>
                           {description}
                        </div>
                     }
                     position="left"
                     theme="dark"
                     interactive
                  >
                     {activity.pj ?

                        <div>
                           <p className='description ellipsis-line camp-2 pt-1'
                              onClick={() => {
                                 if (activity.pj) {
                                    setViewOpen({ activity, project: activity.pj })
                                 } else {
                                    onAdd_(activity.pj ? 1 : 2, activity.pj ? activity :
                                       activity.ticket
                                       , activity.pj);
                                 }
                              }}
                              background={'var(--danger)'}>
                              (Projeto {activity.pj.name})
                              {"\n " + description.replace(/<\/?[^>]+(>|$)/g, "")}
                           </p>
                           <p className='description ellipsis-line camp-2 pt-1' >
                              {activity.name}
                           </p>

                        </div>
                        :
                        <p onClick={() => {

                           if (activity.pj) {
                              setViewOpen({ activity, project: activity.pj })
                           } else {
                              onAdd_(activity.pj ? 1 : 2, activity.pj ? activity :
                                 activity.ticket
                                 , activity.pj);
                           }
                        }} className='description ellipsis-line camp-2 pt-1' background={'var(--danger)'}>
                           {description}
                        </p>
                     }

                  </Tooltip>
                  <Progress current={activity.pj ? activity.percent : activity.progress} className='mt-1' />
                  <div className='content-info d-flex align-items-center'>
                     <div className='d-flex align-items-center justify-content-between'>
                        <i className="fas fa-calendar-day" />
                        <span>{date}</span>

                        <i className="fas fa-clock" />
                        <span>{activity.hour}h</span>


                        {/*<i className="fas fa-trash del-card" onClick={onDelete}/>*/}

                        <Tooltip
                           interactive
                           title={activity.pj ? 'Ver projeto vinculado' : ''}
                           position="right"
                           theme="dark"
                           className='redirect-icon'
                        >
                           <i
                              className="fas fa-directions"
                              onClick={() => {
                                 if (activity.pj) {
                                    redirect2('/dash/gp/' + activity.pj.id_project)
                                 } else {
                                    redirect2('/dash/ticketmanagement', { ticket: activity.ticket })
                                 }
                              }} />
                        </Tooltip>
                     </div>
                     {activity.pj ?
                        <MenuTooltip
                           propsButton={{
                              style: {
                                 padding: 0,
                                 marginBottom: -8,
                                 position: 'relative',
                                 left: 19
                              }
                           }}
                           options={[
                              { value: 1, icon: 'fas fa-pencil-alt mr-1', label: 'Editar Atividade', color: 'var(--success)' },
                              { value: 2, icon: 'fas fa-edit mr-1', label: 'Editar Projeto', color: 'var(--primary)', visible: Store.getUserLoged().role == 'client' },
                           ]}
                           onChange={e => {
                              if (e.value == 1) {
                                 activity.description = activity.desc
                                 setFormOpen({ activity, project: activity.pj })
                              } else {
                                 setFormOpenProject(activity.pj)
                              }
                           }}
                        /> : null}
                  </div>
               </div>
            </div>


         </Draggable>
      </Fragment>
   )
})