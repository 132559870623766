import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import CommonForm from '../../common/CommonForm';
import CommonForm2 from '../../common/CommonForm2';
import { Grid, Button } from '@material-ui/core';
import CommonDelete from '../../common/CommonDelete';
import ContractForm from './ContractForm';
import ContractList from './ContractList';
import AttachSla from './AttachSla';
import AttachDevice from './AttachDevice';
import AttachTech from './AttachTech';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import FaClose from 'react-icons/lib/fa/close';
import GestaoContratos from './gestaoContratos';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
import AttachFile from '@common/AttachFile';

import DownloadFiles from '../../util/DownloadFiles';

@observer
export default class ContractContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      disabled: true,
      files: [],
      fileError: '',
      techs: [],
      devices: [],
      files: [],
      slas: [],
      countTechs: 0,
      countDevices: 0,
      countFiles: 0,
      countSlas: 0,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onClose = this.onClose.bind(this);
    this.load = this.load.bind(this);
    this.getDeviceContract = this.getDeviceContract.bind(this);
    this.getTechContract = this.getTechContract.bind(this);
    this.getSlaContract = this.getSlaContract.bind(this);

    this.getFileContract = this.getFileContract.bind(this);
  }

  valuesset() {
    this.setState({ disabled: !this.state.disabled });
  }

  onDrop(g) {
    var files = this.state.files.concat(g);
    this.setState({ files });
  }

  removeFile(item) {
    var files = this.state.files.filter((f) => f.name != item.name);
    this.setState({ files });
  }

  load(v) {
    if (v == 1) {
      this.action
        .execute('post', this.APIEndpoints.CONTRACT, 'contractslists')
        .then((e) => {});
    } else {
      this.action
        .execute('post', this.APIEndpoints.CONTRACT, 'contractslists')
        .then((e) => {});
    }
  }

  componentWillMount() {
    this.getData();
  }
  getFileContract(id_contract, fields = {}) {
    this.action
      .execute(
        'get',
        this.APIEndpoints.CONTRACT + '/files/' + id_contract,
        ''
      )
      .then((b) => {
        let count = 0;
        try {
          count = b.data[0].count;
        } catch (e) {}

        this.setState({ countFiles: count });
        let files = b.data.map((_f, k) => ({
          className: 'listItem d-flex justify-content-between',
          content: (
            <div className="w-100">
              <AttachFile
                style={{ maxWidth: '100%' }}
                onClick={() => this.ondonwFile(_f.id_contract_file, _f.file)}
                label={_f.file || 'Arquivo sem nome'}
                onDelete={() =>
                  this.handlePage('remove', {
                    id_contract: _f.id_contract,
                    id: _f.id_contract_file,
                    id_contract_file: _f.id_contract_file,
                    detach: 'up',
                    name: _f.file,
                  })
                }
              />
            </div>
          ),
        }));
        this.setState({ files });
      });
  }

  getSlaContract(id_contract, fields = {}) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.CONTRACT + '/sla?offset=0&limit=100',
        '',
        {
          id_contract: id_contract,
          ...fields,
        }
      )
      .then((b) => {
        let count = 0;
        try {
          count = b.data[0].count;
        } catch (e) {}
        this.setState({ countSlas: count });
        let slas = b.data.map((_sla, k) => ({
          className: 'listItem d-flex justify-content-between',
          content: (
            <Fragment>
              <span>
                {_sla.name} - {parseInt(_sla.time)}h
              </span>
              <span>
                <div
                  data-tip={this.store.language.REMOVE}
                  className="trashIcon pointer cardIcon"
                  onClick={() =>
                    this.handlePage('remove', {
                      id_contract: id_contract,
                      id: _sla.id_sla,
                      detach: 'sla',
                      name: _sla.name,
                    })
                  }
                />
              </span>
            </Fragment>
          ),
        }));
        this.setState({ slas });
      });
  }

  getDeviceContract(id_contract, fields = {}) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.CONTRACT + '/device?offset=0&limit=100',
        '',
        {
          id_contract: id_contract,
          ...fields,
        }
      )
      .then((b) => {
        let count = 0;
        try {
          count = b.data[0].count;
        } catch (e) {}

        this.setState({ countDevices: count });
        let devices = b.data.map((_device, k) => ({
          className: 'listItem d-flex justify-content-between',
          content: (
            <Fragment>
              <span className="name">{_device.name}</span>
              <span>
                <div
                  data-tip={this.store.language.REMOVE}
                  className="trashIcon pointer cardIcon"
                  onClick={() =>
                    this.handlePage('remove', {
                      id_contract: id_contract,
                      id: _device.id_device,
                      detach: 'device',
                      name: _device.name,
                    })
                  }
                />
              </span>
            </Fragment>
          ),
        }));
        this.setState({ devices });
      });
  }

  getTechContract(id_contract, fields = {}) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.CONTRACT + '/tech?offset=0&limit=100',
        '',
        {
          id_contract: id_contract,
          ...fields,
        }
      )
      .then((b) => {
        let count = 0;
        try {
          count = b.data[0].count;
        } catch (e) {}

        this.setState({ countTechs: count });
        let techs = b.data.map((_tech, k) => ({
          className: 'listItem d-flex justify-content-between',
          content: (
            <Fragment>
              <span className="name">{_tech.name}</span>
              <span>
                <div
                  data-tip={this.store.language.REMOVE}
                  className="trashIcon pointer"
                  onClick={() =>
                    this.handlePage('remove', {
                      id_contract: id_contract,
                      id: _tech.id_user,
                      detach: 'tech',
                      name: _tech.name,
                    })
                  }
                  style={{ width: 25, height: 25 }}
                />
              </span>
            </Fragment>
          ),
        }));
        this.setState({ techs });
      });
  }

  getData() {
    this.action.execute('get', this.APIEndpoints.SLA, 'slas');

    let PAGE = window.location.href.replace(window.location.origin + '/', '');
  }

  ondonwFile(id, file) {
    DownloadFiles(
      this.APIEndpoints.DOWNFILE + '/download/' + id + '&' + file,
      file
    );
  }

  onClose() {
    this.setState({
      fileError: '',
    });
  }

  onFormSubmit(values) {
    let up = 'sim';
    let method = values.hasOwnProperty('id_contract') ? 'put' : 'post';
    let page = this.state.page;

    let url = values.hasOwnProperty('id_contract')
      ? this.APIEndpoints.CONTRACT + '/' + values.id_contract
      : this.APIEndpoints.CONTRACT;

    if (this.state.page.indexOf('attach') !== -1) {
      values.id_contract = this.state.values.id_contract;
    }
    if (this.state.page === 'form') {
      up = 'nao';
    }
    if (this.state.page === 'attachSla') {
      url += '/sla';
      method = 'put';
      up = 'nao';
    }
    if (this.state.page === 'contract') {
      url += '/up';
      method = 'put';
      up = 'nao';
    } else if (this.state.page === 'attachDevice') {
      url += '/device';
      method = 'put';
      up = 'nao';
    } else if (this.state.page === 'attachTech') {
      url += '/tech';
      method = 'put';
      up = 'nao';
    }

    this.action
      .execute(
        method,
        url,
        'contractslists',
        values,
        'id_contract',
        undefined,
        !this.state.files.length
      )
      .then((e) => {
        this.handlePage('list');

        var files = this.state.files.slice();
        if (!!files.length) {
          this.setState({ files: [] });

          Array.from(files).forEach((file) => {
            const promise = new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                if (!!reader.result) {
                  resolve(reader.result);
                } else {
                  reject(Error('Failed converting to base64'));
                }
              };
            });
            promise.then(
              (result) => {
                // let upload  = {fk_id_contract:2 ,deleted:false , file: res , type:this.state.files[0].type, namefile:this.state.files[0].name};
                let upload = {
                  id_contract:
                    values.id_contract != undefined &&
                    values.id_contract != null
                      ? values.id_contract
                      : 0,
                  deleted: false,
                  file: result,
                  type: file.type,
                  namefile: file.name,
                  fk_id_company: values.fk_id_company,
                };
                this.action.execute(
                  'post',
                  this.APIEndpoints.CONTRACT + '/upload',
                  'contractslists',
                  upload,
                  null,
                  { key: 'id_contract' }
                );
              },
              (err) => {}
            );
          });
        }
        if (page == 'attachSla') {
          this.getSlaContract(values.id_contract);
        } else if (page == 'attachTech') {
          this.getTechContract(values.id_contract);
        } else if (page == 'attachDevice') {
          this.getDeviceContract(values.id_contract);
        } else {
          this.redirect2('/dash/contracts');
        }
      });
  }
  onDelete(v = '') {
    let { values } = this.state;
    let url = this.APIEndpoints.CONTRACT + '/' + values.id_contract;
    let method = 'delete';
    let body = {
      justify: v,
      close: values.close,
    };

    if (values.close) {
      let body = {
        id_contract: values.id_contract,
        justify: v,
        close: values.close,
      };
      //this.action.execute('post', this.APIEndpoints.CONTRACT +'/enc', 'contractslists', body, 'id_contract');
      this.redirect('/dash/contracts');
    } else {
      if (values.hasOwnProperty('detach')) {
        url = this.APIEndpoints.CONTRACT + '/' + values.detach;
        method = 'put';
        if (!values.hasOwnProperty('id_contract_file')) {
          body = { id_contract: values.id_contract, detach: true };
        } else {
          body = {
            id_contract: values.id_contract,
            detach: true,
            id_contract_file: values.id_contract_file,
          };
        }
        body['id_' + values.detach] = values.id;
      }
      this.action
        .execute(method, url, 'contractslists', body, 'id_contract')
        .then((e) => {
          this.getDeviceContract(values.id_contract);
          this.getTechContract(values.id_contract);
          this.getFileContract(values.id_contract);
          this.getSlaContract(values.id_contract);

          this.getData();
        });
      this.handlePage('list');
    }
  }

  handlePage(page, obj) {
    if (page === 'list') {
    }
    this.setState({ page: page, values: obj });
  }

  handleCompany(companyId) {
    this.setState({
      companyId: companyId,
    });
  }

  render() {
    let { configurationProps = {}, sub } = this.props;

    let { language } = this.store;
    let { page, values, disabled, files, fileError } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;

    let { state, pathname } = history.location;
    if (!state && testRoute('/dash/contracts/edit', pathname)) {
      redirect('/dash/contracts');
      return null;
    }
    if (sub) {
      state = values;
    }
    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.TERMINATE_CONTRACT,
      primary: true,
      style: { margin: 10, float: 'right' },
    };

    const CP = !sub ? CommonPage : Fragment;
    return (
      <CP
        loading={false}
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={true}
        iconAlert={true}
      >
        {testRoute(['/dash/contracts/new', '/dash/contracts/edit'], pathname) &&
          !sub && (
            <Animate>
              <ContractForm
                {...this.state}
                values={state}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                files={files}
                removeFile={this.removeFile}
                onDrop={this.onDrop}
                redirect={redirect}
                valuesset={this.valuesset}
                language={language}
                handlePage={this.handlePage}
                onFormSubmit={this.onFormSubmit}
                store={this.store}
              />
            </Animate>
          )}
        {sub && (
          <Dialog
            open={['new', 'edit'].includes(page)}
            title={
              page == 'new'
                ? `${language.ADD} ${language.NEW_CONTRACT}`
                : language.EDIT_CONTRACT
            }
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
          >
            <ContractForm
              sub={sub}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              {...this.state}
              history={history}
              values={state}
              disabled={disabled}
              files={files}
              removeFile={this.removeFile}
              onDrop={this.onDrop}
              redirect={redirect}
              valuesset={this.valuesset}
              language={language}
              handlePage={this.handlePage}
              onFormSubmit={this.onFormSubmit}
              store={this.store}
            />
          </Dialog>
        )}
        {/*---------------------------------------pra tela de configuração-------------------------------------------------------*/}
        {testRoute('/dash/contracts', pathname) &&
          !sub &&
          !(state || {}).ulc && (
            <Animate>
              <ContractList
                load={this.load}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                ondonwFile={this.ondonwFile}
                language={language}
                handlePage={this.handlePage}
                store={this.store}
                getTechContract={this.getTechContract}
                getDeviceContract={this.getDeviceContract}
                getFileContract={this.getFileContract}
                getSlaContract={this.getSlaContract}
                techs={this.state.techs}
                devices={this.state.devices}
                files={this.state.files}
                slas={this.state.slas}
                countTechs={this.state.countTechs}
                countDevices={this.state.countDevices}
                countFiles={this.state.countFiles}
                countSlas={this.state.countSlas}
                redirect={redirect}
              />
            </Animate>
          )}
        {sub && (
          <Animate>
            <ContractList
              fromConfiguration={this.props.fromConfiguration}
              load={this.load}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              configurationProps={configurationProps}
              sub={true}
              ondonwFile={this.ondonwFile}
              language={language}
              handlePage={this.handlePage}
              store={this.store}
              redirect={redirect}
              getTechContract={this.getTechContract}
              getDeviceContract={this.getDeviceContract}
              getFileContract={this.getFileContract}
              getSlaContract={this.getSlaContract}
              techs={this.state.techs}
              devices={this.state.devices}
              files={this.state.files}
              countTechs={this.state.countTechs}
              countDevices={this.state.countDevices}
              countFiles={this.state.countFiles}
            />
          </Animate>
        )}

        {/*
     fileError === 'error' ?
      <Dialog title={language.UPLOAD_ERROR} onClose={() => this.onClose('')}  modal={false} open={true}>

       </Dialog>
      :  null
        */}

        {page === 'attachSla' ? (
          <AttachSla
            open={page === 'attachSla'}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
            contract={values}
            store={this.store}
          />
        ) : page === 'attachDevice' ? (
          <AttachDevice
            open={page === 'attachDevice'}
            APIEndpoints={this.APIEndpoints}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
            contract={values}
            store={this.store}
          />
        ) : page === 'attachTech' ? (
          <AttachTech
            open={page === 'attachTech'}
            APIEndpoints={this.APIEndpoints}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
            contract={values}
            store={this.store}
          />
        ) : page === 'remove' && values.close == true ? (
          <Dialog
            title={language.TERMINATE_CONTRACT}
            open={true}
            maxWidth="lg"
            onClose={() => this.setState({ values: [], page: 'list' })}
          >
            <CommonForm2
              style={{ width: '200px' }}
              language={language}
              onChangeField={(f, v) => ''}
              fields={[
                {
                  col: 12,
                  name: 'justify',
                  label: language.REASON,
                  multiLine: true,
                  type: 'textEditor',
                  required: true,
                },
              ]}
              onSubmit={(c) => this.onDelete(c.justify)}
              button={button}
            />
          </Dialog>
        ) : page === 'remove' ? (
          <CommonDelete
            language={language}
            confirm={values.detach ? language.CONFIRM_DETACH : null}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.name}
          />
        ) : null}
      </CP>
    );
  }
}
