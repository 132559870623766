import React, { useReducer, useState, Fragment, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import { Card, Avatar } from '@material-ui/core';
import Tag from '@common/Tag';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import Button from '@common/Button';
import Progress from '@common/Progress';
import Store from '@data/Store';
import './Gantt.css';

const { language } = Store;

var enumerateDaysBetweenDates = function (startDate, endDate) {
   startDate = moment(startDate);
   endDate = moment(endDate);
   var now = startDate.clone(),
      dates = [];

   while (now.isSameOrBefore(endDate)) {
      dates.push(new Date(now));
      now.add(1, 'week');
   }
   return dates;
};

function Header({ rangeDate, spaceCollumn }) {
   return (
      <div className='dates-content d-flex align-items-center mb-2' style={{ width: (rangeDate.length * spaceCollumn) }}>
         {rangeDate.map((e, i) => (
            <div className='d-flex align-items-center justify-content-center'>
               {moment(e).isSame(new Date(), 'days') ? (
                  <span style={{ color: 'var(--success)' }}>{language.TODAY}</span>
               ) : (
                     <Fragment>
                        <span>{moment(e).format('DD/')}</span>
                        <span>{moment(e).format('MMM')}</span>
                     </Fragment>
               )}
            </div>
         ))}
      </div>
   )
}

export default ({
   startDate = moment(),
   endDate = moment().add(30, 'day'),
   group = [],
   spaceCollumn = 42,
   relatorio
}) => {
   let [open, setOpen] = useState(true);
   let [openItem, setOpenItem] = useReducer(
      (state, v) => {
         return state.indexOf(v) != -1
            ? state.filter((e) => e != v)
            : [...state, v];
      },
      [0, 1, 2, 3, 4, 5]
   );
   const controlRef = useRef();

   let rangeDate = enumerateDaysBetweenDates(
      startDate,
      moment(endDate).diff(startDate, 'day') < 30
         ? moment(endDate || Date.now()).add(20, 'days')
         : endDate
   );
   let HPercent = open ? 1 : 11 / 30;
   let scrollBarHeight = 380;
   let pd_visible;
   let pd;
   let avatarMax;
   let name_width;
   function positionDate(dt_i, dt_f) {
      let _dt_i = moment(dt_i).diff(moment(startDate), 'week', true);
      _dt_i = (_dt_i * 42);

      let _dt_f = moment(dt_f).diff(moment(dt_i), 'week', true);
      _dt_f = (_dt_f * 42);

      return { position: _dt_i, width: Math.abs(_dt_f) };
   }
   
   return (
      <div className="mb-3" id="GanttPage">
         <Card
            id="Gantt_pmo"
            className="content d-flex px-0 pb-2"
            style={{ '--spaceCollumn': spaceCollumn + 'px' }}
         >
         
            <div style={{marginTop:20, height: relatorio==true? '100%': scrollBarHeight - 20  }}>
               <div id='content-control' ref={controlRef}>
                  {group.map((a, l) => {
                     var maxH = 1;
                     return (
                        <div 
                           className="content-info-control d-flex align-items-center pr-3"
                           style={{
                              height:((maxH ? 60 * maxH : 60) - 9.5 * (maxH - 1)) * HPercent
                           }}
                        >
                           <div className="w-100">

                              <span className="label-name ellipsis">{a.label}</span>
                              <div
                                 className={classnames('content-label-info', {
                                    late: a.late,
                                 })}
                              >
                                 <span>{moment(a.dt_i).format('DD/MM/YYYY')} à </span> 
                                 <span>{moment(a.dt_f).format('DD/MM/YYYY')} </span>
                                 {a.late && (
                                    <span>
                                       <i className="fas fa-circle" /> atrasado
                                    </span>
                                 )}
                              </div>
                              {!isNaN(a.percent)?(

                                 <div className='d-flex align-items-center w-100'>
                                    <Progress current={a.percent} background='var(--warning)' hiddenText style={{height:7, width:'100%'}}/>
                                    <span style={{color:'#707070', fontSize:10}} className='ml-2'>{a.percent}%</span>
                                 </div>
                              ):null}
                           </div>
                        </div>
                     )
                  })}
               </div>
            </div>
            <PerfectScrollbar
               className="scrollVisible gantt-scroll"
               onScrollY={evt=>{
                  controlRef.current.style.marginTop = -evt.scrollTop+'px';
               }}
               style={{
                  maxHeight:  relatorio==true? '100%': scrollBarHeight,
                  zIndex: 9,
               }}
            >
               <Header
                  rangeDate={rangeDate}
                  spaceCollumn={spaceCollumn}
               />
               {group.map((a, l) => {
                  pd = positionDate(a.dt_i, a.dt_f);
                  pd_visible =
                     pd.width >= spaceCollumn * 3 ||
                     (!open && pd.width > spaceCollumn);
                  name_width = !pd_visible ? { width: '100%' } : {};
                  avatarMax = parseInt(pd.width / spaceCollumn) * 2;
                  var maxH = 1;
                 
                  let isMarginAvatar = moment(a.dt_f).diff(moment(), 'day')>0;
                  return (
                     
                        <div
                           key={l}
                           className="gantt-content d-flex align-items-center"
                           style={{
                              width: rangeDate.length * spaceCollumn + 210,
                              height: ((maxH ? 60 * maxH : 60) - 9.5 * (maxH - 1)) * HPercent
                           }}
                        >
                           
                           <div
                              className="content-info-activities d-flex align-items-center"
                              style={{ width: rangeDate.length * spaceCollumn }}
                           >
                              <div
                                 className={classnames(
                                    'd-flex align-items-center item-activity',
                                    pd_visible
                                       ? 'justify-content-between'
                                       : 'justify-content-center'
                                 )}
                                 style={{
                                    left: pd.position,
                                    top: (15 * 1 + 30 * (1 - 1)) * HPercent,
                                    width: pd.width,
                                    height: 30 * HPercent,
                                    backgroundColor: a.color,
                                 }}
                              >
                                 <div 
                                    className="content-avatares d-flex align-items-center"
                                    style={{
                                       marginLeft: pd.position < 0 && isMarginAvatar ? -pd.position : 0
                                    }}
                                 >
                                    {a.avatares.slice(0, avatarMax).map((e, i) => (
                                       <Tooltip
                                          interactive
                                          html={
                                             <div
                                                style={{
                                                   color: '#fff',
                                                   fontSize: 11,
                                                   maxWidth: 210,
                                                   flexWrap: 'wrap',
                                                }}
                                                className="d-flex align-items-center"
                                             >
                                                {e.name}
                                             </div>
                                          }
                                          position="right"
                                          arrow
                                          className="content-avatar"
                                       >
                                          <Avatar
                                             src={e.src}
                                             key={i}
                                             className="avatar"
                                             style={{ zIndex: a.avatares.length - (i + 1) }}
                                          >
                                             {e.name.substr(0, 2)}
                                          </Avatar>
                                       </Tooltip>
                                    ))}
                                    {a.avatares.length > avatarMax && (
                                       <Tooltip
                                          html={
                                             <div style={{ color: '#fff', fontSize: 11 }}>
                                                {a.avatares.slice(avatarMax).map((u, i) => (
                                                   <div
                                                      style={{
                                                         marginLeft: '5px',
                                                         marginTop: '2px',
                                                      }}
                                                      className="d-flex align-items-center"
                                                   >
                                                      <Avatar
                                                         src={u.src}
                                                         className={'avatar'}
                                                         key={i}
                                                         style={{
                                                            fontSize: '10px',
                                                            width: '17px',
                                                            height: '17px',
                                                         }}
                                                      >
                                                         {u.name
                                                            .substr(0, 2)
                                                            .toString()
                                                            .toUpperCase()}
                                                      </Avatar>
                                                      <span
                                                         style={{
                                                            color: 'black',
                                                            marginLeft: '4px',
                                                         }}
                                                      >
                                                         {u.name}
                                                      </span>
                                                   </div>
                                                ))}
                                             </div>
                                          }
                                          interactive
                                          position="right"
                                          theme="light"
                                          arrow
                                          className="content-avatar"
                                       >
                                          <Avatar
                                             className="avatar _white"
                                             style={{ zIndex: 0 }}
                                          >
                                             +{a.avatares.slice(avatarMax).length}
                                          </Avatar>
                                       </Tooltip>
                                    )}
                                 </div>
                              </div>

                              {rangeDate.map((e, i) => (
                                 <Fragment>
                                    {moment(e).diff(new Date(), 'days') < 0 && (
                                       <div
                                          className={classnames('curtain-date-past')}
                                          style={{
                                             left: i * spaceCollumn,
                                          }}
                                       ></div>
                                    )}
                                    <div
                                       key={i}
                                       className={classnames('divisor', {
                                          active: moment(e).isSame(new Date(), 'days'),
                                       })}
                                       style={{ '--index': i }}
                                    ></div>
                                 </Fragment>
                              ))}
                           </div>
                        </div>
                     

                  );
               })}
            </PerfectScrollbar>
         
         </Card>
      </div>
   );
};
