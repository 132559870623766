import React from 'react';
import { CircularProgress } from "@material-ui/core";


export function ServicePortalLoading() {
    return (
        <div className='tw-h-screen tw-w-screen tw-flex tw-justify-between tw-items-center'>
            <CircularProgress color='inherit' className='tw-m-auto' />
        </div>
    )
}