import React, {memo, Fragment} from 'react';
import classnames from 'classnames';
/*---------------------------common-------------------------------*/
import AppBar from '@common/AppBar2';
import List from '@common/List';
/*---------------------------material-ui-------------------------------*/
import {Card, Avatar} from '@material-ui/core';
/*---------------------------components-------------------------------*/
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Store from '@data/Store';
const { language } = Store;

const ListItem = memo(({index, name, trophy, points})=>(
	<Fragment>
		<span className='position'>{index+1}°</span>
		<div className='d-flex align-items-center justify-content-between content-item content-min w-100'>
			<div className='d-flex align-items-center'>
				<Avatar className='avatar'>
					{name.slice(0,2)}
				</Avatar>
				<span className='name ellipsis'>{name}</span>
			</div>
			<div className='d-flex align-items-center'>
				{[1,2,3,4,5].map(a=>(
					<i key={a} className={classnames('fas fa-trophy', 'trophy-icon', {active:a<=trophy})}/>
				))}
				<span className='points'>{points}pts</span>
			</div>
		</div>
	</Fragment>
))
export default function({data=[]}){

	/*
		----------------------formato----------------------------
		{
			name:'Rodrigo Sousa',
			trophy:3,
			points:5
		},
	*/
	return(
		<Card id='Rank' className='h-100'>
			<AppBar title={language.RANK}/>
			<List
				mh={204}
				items={data.map((e,i)=>(
					{
						content:<ListItem {...e} index={i} key={i}/>
					}
				))}
			/>
		</Card>
	)
}
