import Store from '@data/Store';
import { Avatar, Button, Chip, Divider, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Constants from '../../../data/Constants';
import { getApprovalMap, spFetch } from '../helpers';
import { GridLineItem } from './GridUtils';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';
import Tag from '@common/Tag';
import ServicePortalModalApprovalRequestDetails from './ServicePortalModalApprovalRequestDetails';
import ServicePortalModalApprovalTicketDetails from './ServicePortalModalApprovalTicketDetails';
import ServicePortalModalApprovalRejectAction from './ServicePortalModalApprovalRejectAction';

export default function ServicePortalModalApprovalDetails({
  isOpen,
  language,
  data,
  onClose,
  onConfirm,
  params,
}) {
  const FLAG_MAP = getApprovalMap(language);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState(false);
  const [dataDetails, setDataDetails] = useState();

  const [showModalView, setShowModalView] = useState();
  const [showModalApproveRejectAction, setShowModalApproveRejectAction] =
    useState();

  useEffect(() => {
    if (isOpen) getData(data);
  }, [isOpen]);

  const getData = (data) => {
    if (FLAG_MAP.ticket === data.type) getRequestDetail();
    else if (FLAG_MAP.task === data.type) getTaskDetail();
    else getChangeDetail();
  };

  const getRequestDetail = () => {
    setIsLoading(true);
    const url = `${FLAG_MAP[data.type].url}/${data.id}`;
    spFetch(url)
      .then((response) => {
        const [requestData] = response.data;
        const dataDetail = {
          type: data.type,
          subject: requestData.subject,
          description: requestData.description,
          files: requestData.files,
          requestId: requestData.id_request,
          details: {
            email: requestData.username,
            phone: requestData.phone,
            department: requestData.department,
            category: requestData.namecategory_,
            subcategory: requestData.catalogservicename_,
            task: requestData.catalogtaskname_,
            html_values: requestData.html_values,
            html: requestData.template,
          },
        };
        console.log('ticket', dataDetail);
        setDataDetails(dataDetail);
      })
      .finally(() => setIsLoading(false));
  };

  const getTaskDetail = () => {
    setIsLoading(true);
    const url = `${FLAG_MAP[data.type].url}/${data.id}`;
    spFetch(url)
      .then((response) => {
        const [taskData] = response.data?.tasks;
        const dataDetail = {
          type: data.type,
          subject: taskData.name,
          description: taskData.description,
          files: taskData.files,
          fk_id: taskData.fk_id_tickets,
          taskId: taskData.id_tasks,
        };
        console.log('task', dataDetail);
        setDataDetails(dataDetail);
      })
      .finally(() => setIsLoading(false));
  };

  const getChangeDetail = () => {
    setIsLoading(true);
    const url = `${FLAG_MAP[data.type].url}/${data.id}`;
    spFetch(url)
      .then((response) => {
        const changeData = { ...response.data };
        const dataDetail = {
          type: data.type,
          subject: changeData.reason,
          description: changeData.benefits,
          files: changeData.file,
          changeId: changeData.id_change,
        };
        setDataDetails(dataDetail);
      })
      .finally(() => setIsLoading(false));
  };

  const handleConfirm = () => {
    if (value) {
      onConfirm && onConfirm(value);
      setError(false);
      setValue('');
    } else {
      setError(true);
      Store.toggleAlert(true, language.INFORM_REASON, 'error');
    }
  };

  const handleClose = (reload) => {
    setDataDetails(null);
    setShowModalView(null);
    setShowModalApproveRejectAction(null);
    onClose?.(reload);
  };

  return (
    <>
      <ServicePortalModalWrapper
        isOpen={isOpen}
        title={language.APPROVAL_DETAILS}
        onClose={handleClose}
        onConfirm={handleConfirm}
        buttonCloseLabel={language.CLOSE}
        buttonConfirmLabel={language.SAVE}
        disableConfirmButton={true}
        customButtons={
          <>
            {dataDetails && (
              <div className="tw-flex tw-gap-3 slide-in tw-h-full tw-w-full">
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => setShowModalApproveRejectAction('approve')}
                >
                  <i className="fas fa-check mr-2"></i>
                  {language.APPROVE}
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={() => setShowModalApproveRejectAction('reject')}
                >
                  <i className="fas fa-trash-alt mr-2"></i>
                  {language.REJECT}
                </Button>
                {dataDetails?.type &&
                  [FLAG_MAP.task, FLAG_MAP.ticket].includes(
                    dataDetails?.type
                  ) && (
                    <Button
                      size="small"
                      color="default"
                      variant="outlined"
                      onClick={() => setShowModalView(dataDetails?.type)}
                    >
                      <i className="fas fa-eye mr-2"></i>
                      {language.VIEW}
                    </Button>
                  )}
              </div>
            )}
          </>
        }
      >
        <div className="tw-w-full">
          {dataDetails && (
            <Grid container spacing={1}>
              <GridLineItem
                label={language.TYPE}
                content={
                  <Tag
                    label={
                      <>
                        <i
                          className={`mr-1 ${FLAG_MAP[dataDetails.type].icon}`}
                          style={{ color: FLAG_MAP[dataDetails.type].color }}
                        ></i>
                        {dataDetails.type}
                      </>
                    }
                    color="#ffffff"
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '13px',
                      height: 'auto',
                    }}
                  />
                }
              />

              <Grid item sm={12}>
                <Divider />
              </Grid>

              <GridLineItem label={language.ID} text={data?.id_} />

              <Grid item sm={12}>
                <Divider />
              </Grid>

              <GridLineItem
                label={language.DT_CAD}
                text={data?.dt_cad}
                icon="fa-calendar"
              />

              <Grid item sm={12}>
                <Divider />
              </Grid>

              <GridLineItem
                label={language.COMPANY}
                text={data?.company_name}
                icon="fa-building"
              />

              <Grid item sm={12}>
                <Divider />
              </Grid>

              <GridLineItem
                label={language.REQUESTER}
                text={data?.sol}
                icon="fa-user"
              />

              <Grid item sm={12}>
                <Divider />
              </Grid>

              {dataDetails.details && (
                <>
                  <GridLineItem
                    label={language.EMAIL}
                    text={dataDetails.details?.email}
                    icon="fa-envelope"
                  />

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <GridLineItem
                    label={language.PHONE}
                    text={dataDetails.details?.phone}
                    // icon="fa-phone"
                  />

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <GridLineItem
                    label={language.DEPARTMENT}
                    text={dataDetails.details?.department}
                    // icon="fa-people-arrows"
                  />

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <GridLineItem
                    label={language['SERVICE_PORTAL']['CATEGORY']}
                    text={dataDetails.details?.category}
                    // icon="fa-people-arrows"
                  />

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <GridLineItem
                    label={language['SERVICE_PORTAL']['SUBCATEGORY']}
                    text={dataDetails.details?.subcategory}
                    // icon="fa-people-arrows"
                  />

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <GridLineItem
                    label={language['SERVICE_PORTAL']['TASK']}
                    text={dataDetails.details?.task}
                    // icon="fa-people-arrows"
                  />

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}

              <GridLineItem
                label={language.SUBJECT}
                text={dataDetails.subject}
              />

              {dataDetails.files && (
                <>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <GridLineItem
                    label={language.FILES}
                    content={(dataDetails.files || []).map((item, index) => (
                      <div key={index}>
                        <Chip
                          size="small"
                          label={
                            item.name?.length > 16
                              ? item.name.substr(0, 6) +
                                '...' +
                                item.name.substr(-7)
                              : item.name
                          }
                          avatar={
                            <Avatar>
                              <i className="far fa-file tw-w-auto tw-h-auto" />
                            </Avatar>
                          }
                        />
                      </div>
                    ))}
                  />
                </>
              )}

              <Grid item sm={12}>
                <Divider />
              </Grid>

              <GridLineItem
                isLongText={true}
                label={language.DESCRIPTION}
                text={
                  dataDetails.description?.length > 200
                    ? dataDetails.description
                        .replace(/<[^>]*>?/gm, '')
                        .toString()
                        .substring(0, 200) + '...'
                    : dataDetails.description?.replace(/<[^>]*>?/gm, '')
                }
              />
            </Grid>
          )}
        </div>
      </ServicePortalModalWrapper>

      <ServicePortalModalApprovalRequestDetails
        language={language}
        isOpen={!!showModalView && showModalView === FLAG_MAP.ticket}
        data={dataDetails}
        onClose={(reload) =>
          reload ? handleClose(reload) : setShowModalView(null)
        }
      />

      <ServicePortalModalApprovalTicketDetails
        language={language}
        isOpen={!!showModalView && showModalView === FLAG_MAP.task}
        principalId={dataDetails?.fk_id}
        onClose={(reload) =>
          reload ? handleClose(reload) : setShowModalView(null)
        }
        params={params}
      />

      <ServicePortalModalApprovalRejectAction
        language={language}
        isOpen={!!showModalApproveRejectAction}
        type={showModalApproveRejectAction}
        selectedItems={[data]}
        onClose={(reload) => {
          reload ? handleClose(reload) : setShowModalApproveRejectAction(null);
        }}
      />
    </>
  );
}
