import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import ReactHighcharts from 'react-highcharts';
import ReactToPrint from 'react-to-print';

// ------------------------------------------------------------

import './index.css';
import { SlaAnalysisTrimestral } from '../Graphs';
import TotalHoursConsumed from '../TotalHoursConsumed';

import Logo from '@images/newLogoSensr.png';
import BarBlue from '@images/BarBlue.png';

// ------------------------------------------------------------

export default function ({
  contract,
  NewContractsProps,
  currentMonth,
  currentYear,
  hours_total_consumer,
  mes,
  language,
  currentSla,
  currentConsumed,
  TotalHoursConsumedProps,
  users,
  companies,
  companytimes,
  devicesAll,
  inventoryAll,
  budgetcontrols,
  jobsConsumedHours,
  companySelected,
}) {
  let [contract_, setcontract_] = useState([]);
  let [tickets, settickets] = useState([]);
  const [jobs, setJobs] = useState([]);
  let [ticketsTotal, setTotalTickets] = useState(0);
  let [projetos, setprojetos] = useState([]);
  let [acesso, setAcesso] = useState([]);
  let [os, setOs] = useState([]);
  let [inventory, setInventory] = useState([]);
  let [devices, setDevices] = useState([]);
  let [changes, setChanges] = useState([]);
  const [valueExtended, setValueExtended] = useState(0);
  const [budget, setBudget] = useState([]);
  let month = language.DATE_LANG.monthLongList;
  let monthCurrent = month[currentMonth - 1];
  const componentRef = useRef(null);
  useEffect(() => {
    try {
      //      settickets(contract.ticketconsumed)
      let tickets_ = contract.ticketconsumed;
      let value = 0;
      tickets_.map((t) => {
        if (isDelayed(t)) {
          t.isDelayed = true;
          let hours_consumed = getMinutes(t.hours_consumed);
          value += calculateTimeExtend(hours_consumed, t);
        }
      });
      settickets(tickets_);
      setValueExtended(value);
      let budget_ = budgetcontrols.filter(
        (b) =>
          b.name == contract.contract[0].name && b.expected_value.length > 0
      )[0];
      setBudget(budget_);
      setJobs(contract.jobsConsumed);
      setprojetos(contract.projectConsumed);
      setTotalTickets(contract.ticketconsumedcount);
      setOs(contract.osconsumed);
      setAcesso(contract.assessment);
      setcontract_(contract.contract[0]);
      setInventory(contract.inventory);
      console.log('companySelected',companySelected)
      console.log('companies',companies)
      console.log('contract.contract[0]',contract.contract[0])
      console.log('contract.changes',contract.changes)
      let change = contract.changes.filter(
        (d) => d.fk_id_company == companySelected
      );

      setChanges(change);

      let dev = devicesAll.filter(
        (d) => d.fk_id_company == contract.contract[0].fk_id_out_company
      );
      setDevices(dev);
    } catch (e) {
      console.log(e);
    }
  }, [contract]);

  // console.log('acesso', acesso)

  const getMinutes = (time) => {
    let time_array = time.split(':');
    return Math.floor(time_array[0]) * 60 + Math.floor(time_array[1]);
  };

  const isDelayed = (ticket) => {
    let company_times = companytimes.filter(
      (company) => company.fk_id_company == ticket.fk_id_company
    )[0];
    let hour_end = getMinutes(moment(ticket.dt_up).format('HH:mm:ss'));

    if (company_times) {
      let time_end = getMinutes(company_times.timefim);

      if (hour_end > time_end) {
        return true;
      }
    }
    return false;
  };

  const calculateTimeExtend = (time, ticket) => {
    let value = (time / 60) * parseFloat(ticket.hour_value_additional || 0);
    return value;
  };

  let data = {
    logo: Logo,
    name: contract_.outcompany,
    typeProject: 'improvement',
    listInfo: [
      {
        label: `${language.MONTH}:`,
        value: mes,
      },
      {
        label: `${language._CONTRACT}:`,
        value: contract_.name,
      },
      {
        label: `${language.CONTRACT_VALUE}:`,
        value:
          budget &&
          budget.expected_value &&
          budget.expected_value.filter(
            (b) => b.year == currentYear && b.month == currentMonth
          )[0]
            ? budget.expected_value.filter(
                (b) => b.year == currentYear && b.month == currentMonth
              )[0].value
            : 0,
        /* contract_.type === 'hour' ?
           `R$  ${(contract_.hours * contract_.value) ? (contract_.hours * contract_.value).toFixed(2) : 0} ` :
           `R$ ${contract_.value ? contract_.value : 0}` */
      },

      {
        label: `${language.TYPE}:`,
        value:
          contract_.type == 'hour'
            ? language.HOUR
            : contract_.type == 'year'
            ? language.YEARLY
            : language.MONTHLY,
      },
      {
        label:
          contract_.type === 'hour'
            ? `${language.CONTRACTED_HOURS}:`
            : contract_.type === 'month'
            ? `${language.MONTH}:`
            : `${language.YEAR}:`,
        value:
          contract_.type === 'hour'
            ? parseInt(contract_.hours) + ' H'
            : contract_.type === 'month'
            ? parseInt(contract_.month) + ' M'
            : parseInt(contract_.month / 12) + ' A',
      },
      {
        label:
          contract_.type === 'hour'
            ? `${language.HOURS_CONSUMED}:`
            : contract_.type === 'month'
            ? `${language.CONSUMED} ${language.MONTH}:`
            : `${language.CONSUMED} ${language.YEAR}:`,
        value: `${hours_total_consumer}H`,
      },
      {
        label: contract_.type === 'hour' ? ' Valor Excedente a Pagar' : '',
        value:
          contract_.type === 'hour'
            ? currentSla[2] + valueExtended < currentSla[0]
              ? 'R$ 0,00'
              : 'R$ ' +
                (currentSla[2] + valueExtended - currentSla[0]).toFixed(2)
            : /*  
          formatCurrency(
  
              (contract_.hours * contract_.value) - (hours_total_consumer * contract_.value) >
                (contract_.hours * contract_.value) ?
                (contract_.hours * contract_.value) - (hours_total_consumer * contract_.value) : ''
  
            )
            */
              '',
      },
      {
        label: 'Valor de Horas Extras a Pagar',
        value: 'R$ ' + valueExtended.toFixed(2),
      },
      {
        label: `${language.CLIENT}:`,
        value: contract_.outcompany,
      },
      {
        label: 'Horas Consumidas Por Jobs:',
        value: jobsConsumedHours + 'H',
      },
    ],
    cardInfo: [
      {
        label: 'Quantidade de tickets consumidos',
        value: contract.ticketconsumedcount,
        size: 2,
      },
      {
        label: 'Quantidade de jobs',
        value: jobs.length,
        size: 2,
      },
      {
        label: 'Quantidade de atividades de projetos',
        value: projetos.length,
        size: 2,
      },
      {
        label: 'Quantidade de dispositivos',
        value: devices.length,
        size: 2,
      },
      {
        label: 'Quantidade de OS',
        value: os.length,
        size: 2,
      },
      {
        label: 'Quantidade de acessos remoto',
        value: acesso.length,
        size: 2,
      },
    ],
    dataContract: [
      {
        title: 'Tickets (LISTA DE TICKETS CONSUMIDOS)',
        color: 'var(--purple)',
        table: (
          <table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Chamado</th>
                <th>Data de Cadastro</th>
                <th>Data de Lancamento</th>
                <th>Data de Fechamento</th>

                <th>Titulo</th>
                <th>Status</th>

                <th>Técnico</th>
                <th>Horas</th>
              </tr>
            </thead>

            {tickets.map((v) => {
              let delayed = v.isDelayed ? 'isDelayed' : 'isNot';
              return (
                <tbody>
                  <tr>
                    <td id={delayed}>{v.real_id}</td>

                    <td id={delayed}>
                      {v.dt_cad_ticket ? v.dt_cad_ticket : v.dt_created_ticket}
                    </td>
                    <td id={delayed}>{v.dt_cad}</td>
                    <td id={delayed}>
                      {v.progress == 100
                        ? moment(v.dt_up).format('DD/MM/YY HH:mm:ss')
                        : '-'}
                    </td>
                    <td id={delayed}>{v.subject}</td>
                    <td id={delayed}>
                      {v.status == 'In Progress'
                        ? 'Em Andamento'
                        : v.status == 'Reopened'
                        ? 'Reaberto'
                        : v.status == 'Open'
                        ? 'Aberto'
                        : v.status == 'Closed'
                        ? 'Fechado'
                        : v.status == 'Resolved'
                        ? 'Resolvido'
                        : ''}
                    </td>
                    <td id={delayed}>{v.tech}</td>
                    <td id={delayed}>{v.hours_consumed}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ),
      },
      {
        title: 'PROJETOS (LISTA DE PROJETOS E ATIVIDADES)',
        color: 'var(--primary)',
        table: (
          <table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Projeto</th>
                <th>Atividade</th>
                <th>Técnico</th>
                <th>Data da Atividade</th>
                <th>Hora da Atividade</th>
                <th>Data de Término</th>
                <th>Hora de Término</th>
                <th>Horas</th>
              </tr>
            </thead>
            {projetos.map((v) => {
              return (
                <tbody>
                  <tr
                    style={
                      v.canceled ? { flex: 0, backgroundColor: 'red' } : {}
                    }
                  >
                    <td>
                      #{v.id_project + ' - ' + v.project}
                      {v.canceled ? <small> (Projeto Cancelado)</small> : ''}
                    </td>
                    <td>#{v.fk_id_project_activity + ' - ' + v.activity}</td>
                    <td>{v.name}</td>
                    <td>{moment(v.dt_cad).format('DD/MM/YYYY')}</td>
                    <td>{moment(v.dt_cad).format('HH:mm:ss')}</td>
                    <td>{moment(v.dt_end).format('DD/MM/YYYY')}</td>
                    <td>{moment(v.dt_end).format('HH:mm:ss')}</td>
                    <td>{v.hours_consumed}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ),
      },
      {
        title: 'Ordem de Serviço (Lista das ordens de serviço)',
        color: 'var(--danger)',
        table: (
          <table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>OS</th>
                <th>Data de Criação</th>
                <th>Nome</th>
                <th></th>
                <th>Técnico</th>
                <th>Horas</th>
                <th>Data Inicial</th>
                <th>Data Final</th>
              </tr>
            </thead>
            {os.map((v) => {
              return (
                <tbody>
                  <tr>
                    <td>{v.deleted ? v.id_os + ' (excluída)' : v.id_os}</td>
                    <td>{moment(v.dt_cad).format('DD/MM/YYYY')}</td>
                    <td>{v.objective}</td>
                    <td></td>
                    <td>{v.tech}</td>
                    <td>{v.hours_consumed}</td>
                    <td>
                      {moment(v.start_date).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td>
                      {v.end_date
                        ? moment(v.end_date).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ),
      },
      {
        title: 'ACESSO (ACESSO REMOTO REALIZADO NO CONTRATO)',
        color: 'var(--success)',
        table: (
          <table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Pasta do Vídeo</th>
                <th>Dispositivo</th>
                <th>Ip</th>
                <th>Data do Acesso</th>
                <th>Técnic o</th>
                <th>Horas</th>
              </tr>
            </thead>
            {acesso.map((v) => {
              return (
                <tbody>
                  <tr>
                    <td>{v.str_path}</td>
                    <td>{v.device}</td>
                    <td>{v.ip}</td>
                    <td>{moment(v.dt_cad).format('DD/MM/YYYY')}</td>
                    <td>{v.name}</td>
                    <td>{v.hours_consumed}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ),
      },
      {
        title: 'DISPOSITIVOS',
        color: 'var(--success)',
        table: (
          <table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Dispositivo</th>
                <th>SO</th>
                <th>Ip</th>
                <th>Memória</th>
                <th>HD</th>
                <th>Processador</th>
                <th>Processadores</th>
                <th>Core(s)</th>
              </tr>
            </thead>
            {devices.map((v) => {
              let memory_device = null;
              let cpu_device = null;
              let disk_device = null;
              let distro_ = null;
              let disk_size = 0;
              try {
                memory_device = inventoryAll.filter(
                  (i) => i.id_device == v.id_device && i.command == 'memoria'
                )[0];
                cpu_device = inventoryAll.filter(
                  (i) => i.id_device == v.id_device && i.command == 'cpu'
                )[0];
                disk_device = inventoryAll.filter(
                  (i) => i.id_device == v.id_device && i.command == 'disco'
                )[0];
                distro_ = inventoryAll.filter(
                  (i) => i.id_device == v.id_device && i.command == 'osInfo'
                )[0];
              } catch (e) {
                console.log(e);
              }
              try {
                disk_size = (
                  JSON.parse(disk_device.value)[0].size / 1000000000
                ).toFixed(2);
              } catch (e) {}

              return (
                <tbody>
                  <tr>
                    <td>{v.name}</td>

                    <td>{distro_ ? distro_.value.distro : ''}</td>
                    <td>{v.ip}</td>
                    <td>
                      {memory_device && memory_device.value
                        ? (
                            JSON.parse(memory_device.value).total / 1000000000
                          ).toFixed(2)
                        : ''}{' '}
                      GB
                    </td>
                    <td>{disk_size} GB</td>
                    <td>{cpu_device ? cpu_device.value.brand : ''}</td>
                    <td>{cpu_device ? cpu_device.value.processors : ''}</td>
                    <td>{cpu_device ? cpu_device.value.physicalCores : ''}</td>
                  </tr>
                </tbody>
              );

              /*           return <tbody>
                         <tr>
                           <td>{v.name}</td>
                           <td>  {distro_ ? distro_.value.distro : ''}</td>
                           <td>{v.ip}</td>
                           <td>{memory_device ? (JSON.parse(memory_device.value).total / 1000000000).toFixed(2) : ''} GB</td>
                           <td>{disk_device ? (JSON.parse(disk_device.value)[0].size / 1000000000).toFixed(2) : ''} GB</td>
                           <td>{cpu_device ? cpu_device.value.brand : ''}</td>
                           <td>{cpu_device ? cpu_device.value.processors : ''}</td>
                           <td>{cpu_device ? cpu_device.value.physicalCores : ''}</td>
                         </tr>
                       </tbody>
              */
            })}
          </table>
        ),
      },
      {
        title: 'GMUD',
        color: 'var(--success)',
        table: (
          <table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Tipo de Ação</th>
                <th>Motivo</th>
                <th>Empresa</th>
                <th>Gestor da Mudança</th>
                <th>Data Inicial</th>
                <th>Data Final</th>
              </tr>
            </thead>
            {changes.map((v) => {
              return (
                <tbody>
                  <tr>
                    <td>{v.type_action}</td>
                    <td>{v.reason}</td>
                    <td>{v.company}</td>
                    <td>{v.manager}</td>
                    <td>{moment(v.start_date).format('DD/MM/YYYY')}</td>
                    <td>{moment(v.end_date).format('DD/MM/YYYY')}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ),
      },
    ],
  };
  return (
    <div id="Contract-report" ref={componentRef}>
      {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
      <header
        style={{ background: `url(${BarBlue})`, backgroundSize: 'cover' }}
        className="content d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div>
            <span>Cliente</span>
            <h2>{data.name}</h2>
          </div>
        </div>
        <img src={data.logo} alt="" />

        <div className="d-flex align-items-center">
          <h4>
            {contract_.name}
            {' - '}
            {`${currentMonth}/${currentYear}`}
          </h4>
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.

              return <i id="btnimprimiros" class="fas fa-print"></i>;
            }}
            content={() => componentRef.current}
          />
        </div>
      </header>

      <div className="content">
        <div
          style={{
            backgroundColor: `var(--primary)`,
          }}
          className="content-contract-type content-medium"
        >
          <span>Tipo de Contrato</span>
          <span>
            {contract_.type == 'hour'
              ? 'Hora'
              : contract_.type == 'year'
              ? 'Anual'
              : 'Mensal'}
          </span>
        </div>
        {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
        <div className="content-medium content-infos">
          <Grid container spacing={16}>
            <Grid item xs={3}>
              <ul className="content-info-list">
                {data.listInfo.map((e) => (
                  <li key={e.label}>
                    <div className="label">{e.label}</div>
                    <div className="value ml-1">{e.value}</div>
                  </li>
                ))}
              </ul>
            </Grid>

            <Grid item xs={4}>
              <ReactHighcharts
                config={SlaAnalysisTrimestral(...currentSla, monthCurrent)}
              />
            </Grid>

            <Grid item xs={5} className="content-period">
              <TotalHoursConsumed
                {...TotalHoursConsumedProps}
                language={language}
                month={currentMonth}
                year={currentYear}
                mh={300}
              />
            </Grid>
          </Grid>
        </div>
        {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
        <Grid container spacing={2} className="px-5 mt-3">
          {data.cardInfo.map((e) => (
            <Grid item xs={e.size} key={e.label}>
              <div className="content-info-cards content">
                <span>{e.label}</span>
                <h4>{e.value}</h4>
              </div>
            </Grid>
          ))}
        </Grid>
        <hr />

        <ul className="content-contract-data px-5">
          {data.dataContract.map((e, i) => (
            <React.Fragment>
              <li
                key={e.title}
                className="content py-2 mb-2"
                style={{ backgroundColor: e.color }}
              >
                <span>{e.title}</span>
              </li>
              <ul>
                <li className="content content-table-data mb-3" key={i}>
                  {e.table}
                </li>
              </ul>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}
