export const getTotalTimeAttend = (dateIni, dateFim) => {
  const dataInicio = new Date(dateIni.format('YYYY-MM-DD HH:mm'));
  const dataFim = new Date(dateFim.format('YYYY-MM-DD HH:mm'));

  const diferencaEmMilissegundos = Math.abs(dataFim - dataInicio);
  
  const horas = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60));
  const minutos = Math.floor((diferencaEmMilissegundos % (1000 * 60 * 60)) / (1000 * 60));
  
  return `${horas.toString().padStart(3,0)}:${minutos.toString().padStart(2,0)}`   
}