import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card, Toggle } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';

import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';

import FaTags from 'react-icons/lib/fa/tags';
import FaTicket from 'react-icons/lib/fa/ticket';

export default class TagList extends Component {
  render() {
    let { language, handlePage, redirect } = this.props;

    let col = [
      { key: 'tag', label: language.TAG },
      { key: 'ticket', label: language.TICKET }
      // { key: 'change', label: language.CHANGE },
      // { key: 'solution', label: language.SOLUTION }
    ];

    let data = [
      {
        tag: 'tag link',
        ticket: (
          <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 15 }}>
                <strong>{language.TICKETS}</strong>
              </div>
            }
            position="right"
            theme="dark"
          >
            <FaTicket
              className="tableOption pointer"
              onClick={() => handlePage('form')}
            />
          </Tooltip>
        )
      }
    ];

    return (
      <PageLayout
        icon={<FaTags />}
        title={language.TAG}
        subtitle={language.TAG_LIST}
        rightElements={[
          <RaisedButton
            label={language.BACK}
            primary={true}
            style={{ marginRight: 10 }}
            onClick={() => redirect('/dash/configuration')}
          />,
          <Help>help</Help>
        ]}
      >
        <Card style={{ marginTop: 10 }}>
          <CommonTable
            title={''}
            language={language}
            columns={col}
            data={data}
          />
        </Card>
      </PageLayout>
    );
  }
}
