export default (data, decimalPlaces) => {
  var sum = [0, 0, 0, 0, 0],
    n = 0,
    results = [],
    N = data.length;

  for (; n < data.length; n++) {
    if (data[n][1] === null) data[n][1] = 0;
    if (data[n]['x'] != null) {
      data[n][0] = data[n].x;
      data[n][1] = data[n].y;
    }
    if (data[n][1] != null) {
      sum[0] += data[n][0]; //Σ(X)
      sum[1] += data[n][1]; //Σ(Y)
      sum[2] += data[n][0] * data[n][0]; //Σ(X^2)
      sum[3] += data[n][0] * data[n][1]; //Σ(XY)
      sum[4] += data[n][1] * data[n][1]; //Σ(Y^2)
    } else {
      N -= 1;
    }
  }

  var gradient =
    (N * sum[3] - sum[0] * sum[1]) / (N * sum[2] - sum[0] * sum[0]);
  var intercept = sum[1] / N - (gradient * sum[0]) / N;
  // var correlation = (N * sum[3] - sum[0] * sum[1]) / Math.sqrt((N * sum[2] - sum[0] * sum[0]) * (N * sum[4] - sum[1] * sum[1]));

  for (var i = 0, len = data.length; i < len; i++) {
    var coorY = data[i][0] * gradient + intercept;
    if (decimalPlaces) coorY = parseFloat(coorY.toFixed(decimalPlaces));
    var coordinate = [data[i][0], coorY];
    results.push(coordinate);
  }

  results.sort(function(a, b) {
    if (a[0] > b[0]) {
      return 1;
    }
    if (a[0] < b[0]) {
      return -1;
    }
    return 0;
  });

  var string =
    'y = ' +
    Math.round(gradient * 100) / 100 +
    'x + ' +
    Math.round(intercept * 100) / 100;
  return { equation: [gradient, intercept], points: results, string: string };
};
