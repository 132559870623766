import crypto from 'crypto';

const aSecret = 'Orch3str0!@forever!';

export default (aMsg) => {
  try{
  let cipher, tRet;
  cipher = crypto.createCipher('aes-256-cbc', aSecret);
  tRet = cipher.update(aMsg, 'utf8', 'base64');
  tRet += cipher.final('base64');
  return tRet;
  }catch(e){

    return {}
  }
};

/*
let crypto = require('crypto');

const aSecret = 'Orch3str0!@forever!';

 

let cipher, tRet;
cipher = crypto.createCipher('aes-256-cbc', aSecret);
tRet = cipher.update("207", 'utf8', 'base64');
tRet += cipher.final('base64');
 console.log(tRet)
 */