import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Card, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
import { Checkbox } from '@material-ui/core';
import { translateFromPtBR } from '../../common/languages/Dictionary';

@observer
export default class StatusList extends CommonPage {
  constructor() {
    super();
    this.state = {
      openTest: false,
    };

    this.openmail = this.openmail.bind(this);
  }

  openmail() {
    this.setState({ openTest: true });
  }
  render() {
    let { language, handlePage, redirect, onDelete } = this.props;

    let col = [
      {
        key: 'id_status',
        label: language.ID,
        thConfig: true,
        style: { width: 1, textAlign: 'center', paddingRight: 0 },
      },
      {
        key: 'status',
        label: language.STATUS,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'description',
        label: language.DESCRIPTION,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'sla',
        label: language.FOR_SLA + '?',
        thConfig: true,
        style: { width: 140 },
      },

      {
        key: 'fk_id_status_kanban',
        label: language.KANBAN_COLUMN,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'select_provider',
        label: language.SELECT_SUPPLIER,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'auto_close',
        label: language.AUTOMATIC_CLOSING,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'ticket_close_days',
        label: language.DAYS_AUTO_CLOSE,
        thConfig: true,
        style: { width: 140 },
      }, 
      {
        key: 'ticket_resume',
        label: language.INTERACTION_CHAT_PUT_ATTENDANCE,
        thConfig: true,
        style: { width: 140 },
      },

      {
        key: 'action',
        label: language.ACTION,
        thConfig: true,
        style: { width: 100, textAlign: 'center' },
      },
    ];

    let data = (this.props.store || []).map((obj, index) => {
      return {
        id_status: obj.id_status,
        status: translateFromPtBR(obj.status, language),
        description: obj.description,
        sla: obj.sla ? language.YES : language.NO,
        fk_id_status_kanban:
          obj.fk_id_status_kanban == 1
          ? language.IMPEDITIVE
          : obj.fk_id_status_kanban == 2
          ? language.VALIDATION
          : obj.fk_id_status_kanban == 3
          ? language.DOING
          : language.TO_DO, 
            
        select_provider: obj.select_provider ? language.YES : language.NO,
        auto_close: obj.auto_close ? language.YES : language.NO,
        ticket_close_days: obj.ticket_close_days,
        ticket_resume: obj.ticket_resume ? language.YES : language.NO,
        action: obj.status == 'Em atendimento' ? null : (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                    <RaisedButton
                      circleButton
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      className="mr-2"
                      onClick={() => handlePage('form', obj)}
                    />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    let button = { label: language.SAVE };
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        {this.state.openTest ? (
          <Dialog
            monitor_scroll
            title={language.TYPE_YOUR_EMAIL}
            modal={false}
            open={true}
          >
            <CommonForm
              fields={fieldstest}
              onSubmit={(e) => this.testeMail(e)}
              button={button}
            />
          </Dialog>
        ) : null}

        <CommonTable
          title={''}
          language={language}
          columns={col}
          index="MailList"
          data={data}
          searchColumn
          orderColumn
          width="100%"
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
