import React, {memo} from 'react';
import CommonForm from '@common/CommonForm2';
import Constants from '../../../../data/Constants';
import Store from '@data/Store';

const { language } = Store;

export default memo(({onChangeField,users,companies,form, template, project})=>{
	if (template){
		form.fk_id_company = project.fk_id_company;
	}

	///fk_id_user
	return(
		<div style={{zoom:.9}}>
			<CommonForm
			values={form}
				fields={[
					{
						col: 4,
						type: 'autocomplete',
						name: 'fk_id_company',
						label: language.COMPANY,
						method: 'POST',
						route: `${Constants.APIEndpoints.COMPANY}/filter`,
						fieldquery: 'search',
						textlabel: language.COMPANY,
						textinit:
						form.name_company || language.COMPANY,
						fieldvaluedb: 'id_company',
						fieldlabeldb: 'name',
						idinit: form.fk_id_company,
						routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
						methodGetAll: 'POST',
						routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
						detail: true,
						disable: template,
						required: true
						 
					  },
					{
						col: 4,
						type: 'autocomplete',
						name: 'fk_id_user',
						label: language.REQUESTER,
						textlabel: language.REQUESTER,
						method: 'POST',
						filter: { all_fk_id_company: form.fk_id_company || 0},
						route: `${Constants.APIEndpoints.USER}/filter`,
						routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${
						  form.fk_id_company || 0
						}`,
		  
						fieldquery: 'search',
		  
						textinit:form.user_solicitant,
						fieldvaluedb: 'id_user',
						fieldlabeldb: 'name',
						idinit: form.fk_id_user,
						routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
						filterAll: { all_fk_id_company: form.fk_id_company || 0 },
						methodGetAll: 'POST',
						detail: true,
				        required: true,
						 
						showEmail: true,
		  
						 
					  },
					{
						col:4,
						type:'date',
						name:'dt_start',
						required: !template,
						label: language.START_DATE
					},
					{
						col:4,
						type:'time',
						name:'hour_start',
						required: !template,
						label: language.START_HOUR
					},
					{
						col:4,
						type:'date',
						name:'dt_end',
						required: !template,
						label: language.END_DATE
					},
					{
						col:4,
						type:'time',
						name:'hour_end',
						required: !template,
						label: language.END_HOUR
					},
				]}
				onChangeField={onChangeField}
			/>
		</div>
	)
})