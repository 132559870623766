import React, {Component} from 'react'
import { Grid, Toolbar, Typography, Card } from '@material-ui/core'

import AlocacaoDeEquipe from './AlocacaoDeEquipe'
import CustoPorProjeto from './CustoPorProjeto'
import GestorDeProjeto from './GestorDeProjeto'
import AndamentoDoProjeto from './AndamentoDoProjeto'
import ProjetosEntreguesPorMes from './ProjetosEntreguesPorMes'
import PageLayout from '@common/PageLayout';
import RaisedButton from '@common/RaisedButton';

const data1 = [
  {
    name: 'Técnico 1',
    p1: 4000,
    p2: 2400,
    p3: 2400,
    amt: 2400
  },
  {
    name: 'Técnico 2',
    p1: 3000,
    p2: 1398,
    p3: 1398,
    amt: 2210
  },
  {
    name: 'Técnico 3',
    p1: 2000,
    p2: 9800,
    p3: 9800,
    amt: 2290
  },
  {
    name: 'Técnico 4',
    p1: 2780,
    p2: 3908,
    p3: 3908,
    amt: 2000
  },
  {
    name: 'Técnico 5',
    p1: 1890,
    p2: 4800,
    p3: 4800,
    amt: 2181
  }
]

const data2 = [
  { name: 'Projeto 1', value: 30 },
  { name: 'Projeto 2', value: 70 }
]

const data3 = [
  {
    name: 'Projeto 1',
    uv: 4000,
    pv: 2400,
    mv: 3200,
    amt: 2400
  },
  {
    name: 'Projeto 2',
    uv: 3000,
    pv: 1398,
    mv: 7300,
    amt: 2210
  },
  {
    name: 'Projeto 3',
    uv: 2000,
    pv: 9800,
    mv: 2500,
    amt: 2290
  },
  {
    name: 'Projeto 4',
    uv: 2780,
    pv: 3908,
    mv: 4700,
    amt: 2000
  },
  {
    name: 'Projeto 5',
    uv: 1890,
    pv: 4800,
    mv: 3500,
    amt: 2181
  }
]

const data4 = [
  { progress: 'Aberto', index: 1, value: 5 },
  { progress: 'Andamento', index: 1, value: 15 },
  { progress: 'Atrasado', index: 1, value: 4 }
]

const data5 = [
  {
    name: 'Janeiro',
    p1: 10,
    p2: 1
  },
  {
    name: 'Fevereiro',
    p1: 6,
    p2: 2
  },
  {
    name: 'Março',
    p1: 8,
    p2: 0
  },
  {
    name: 'Abril',
    p1: 5,
    p2: 2
  },
  {
    name: 'Maio',
    p1: 5,
    p2: 3
  },
  {
    name: 'Junho',
    p1: 8,
    p2: 1
  },
  {
    name: 'Julho',
    p1: 9,
    p2: 4
  },
  {
    name: 'Agosto',
    p1: 10,
    p2: 0
  },
  {
    name: 'Setembro',
    p1: 5,
    p2: 3
  },
  {
    name: 'Outubro',
    p1: 5,
    p2: 4
  },
  {
    name: 'Novembro',
    p1: 8,
    p2: 6
  },
  {
    name: 'Dezembro',
    p1: 12,
    p2: 7
  }
]

export default class GestaoDeProjetos extends Component{
  render(){
    let {language, handlePage} = this.props;
    return (

      <PageLayout
        icon="imgGsd"
        title={language.PROJECT_MANAGEMENT}
        rightElements={[]}
        back={()=>   handlePage('dash')}
      >
        <Grid container direction="row">
          

          {/* Alocação de Equipe */}
          <Grid item xs={12} md={6}>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px'
                }}
                variant="headline"
                color="inherit"
                component="p"
              >
                Alocação de Equipe
              </Typography>
              <AlocacaoDeEquipe data={data1} />
            </Card>
          </Grid>

          {/* Análise de custo por projeto */}
          <Grid item xs={12} md={6}>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px'
                }}
                variant="headline"
                color="inherit"
                component="p"
              >
                Análise de custo por projeto
              </Typography>
              <CustoPorProjeto data={data2} />
            </Card>
          </Grid>

          {/* Gestor por projeto */}
          <Grid item xs={12} md={6}>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px'
                }}
                variant="headline"
                color="inherit"
                component="p"
              >
                Gestor por projeto
              </Typography>
              <GestorDeProjeto data={data3} />
            </Card>
          </Grid>

          {/* Andamento dos projetos */}
          <Grid item xs={12} md={6}>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px'
                }}
                variant="headline"
                color="inherit"
                component="p"
              >
                Andamento dos projetos
              </Typography>
              <AndamentoDoProjeto data={data4} />
            </Card>
          </Grid>

          {/* Projetos entregues por mês */}
          <Grid item xs={12}>
            <Card style={{ margin: '20px', padding: '20px' }}>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px'
                }}
                variant="headline"
                color="inherit"
                component="p"
              >
                Projetos entregues por mês
              </Typography>
              <ProjetosEntreguesPorMes data={data5} />
            </Card>
          </Grid>
        </Grid>
      </PageLayout>
    )
  }
}
