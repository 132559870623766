import React, {Fragment} from 'react';
import AppBar from '@common/AppBar2';
import List from '@common/List';
import Progress from '@common/Progress';
import moment from 'moment';
/*-------------------------------material-ui--------------------------------------------*/
import {Card} from '@material-ui/core';

export default function ({ data, month = '', year = '', mh = 206, mx, language }){
	
	return(
		<Card id='TotalHoursConsumed' className='h-100'>
			<AppBar title={language.TOTAL_HOURS_CONSUMED} subTitle={ month && year ?
				`${moment(`01-${month.toString().padStart(2, '0')}`, 'dd-MM').format('MMMM')} - ${year}` :
				`${moment().format('MMMM')} - ${moment().format('YYYY')}`
			}/>
			<List
				mh={mh}
				mx={mx}
				items={data.map(e=>(
					{
						grid:[
							{
								col:6,
								className:'content-name',
								content:(
									<Fragment>
										<div className='mr-2' style={{backgroundColor:e.color}}></div>
										<span className='ellipsis d-block' style={{color:e.color}}>{e.name}</span>
									</Fragment>
								)
							},
							{
								col:6,
								className:'content-progress',
								content:(
									<Fragment>
										<Progress hiddenText current={e.current} background={e.color}/>
										<span>{e.current}H</span>
									</Fragment>
								)
							}
						]
					}
				))}
				
			/>
		</Card>
	)
}