import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm';

@observer
export default class TicketNotesproblem extends Component {
  render() {
    let { language, onNotesTicket, ticket, values, field } = this.props;
    let fields = [];

    let button = {
      md: 1,
      offset: { md: 8 },
      label: language.SAVE,
      primary: true,
      style: { float: 'right' }
    };
    fields = [
      {
        span: 12,
        name: field,
        label: '',
        type: 'textEditor',
        multiLine: true,
        required: true
      },
      {
        span: 3,
        name: 'time_' + field,
        label: language.TIME,
        type: 'time',
        required: true
      }
    ];

    if (ticket['time_' + field] != null) {
      fields = [
        {
          span: 12,
          name: field,
          label: '',
          type: 'textEditor',
          multiLine: true,
          required: true
        },
        {
          span: 3,
          name: 'time_' + field,
          label: language.TIME,
          type: 'text',
          required: true,
          disabled: true
        }
      ];
    }
    if (ticket.status != 'In Progress') {
      button = {
        md: 1,
        offset: { md: 8 },
        label: language.SAVE,
        primary: true,
        disabled: true,
        style: { float: 'right' }
      };
    }

    return (
      <CommonForm
        language={language}
        values={values}
        fields={fields}
        button={button}
        onFormSubmit={values => onNotesTicket(ticket.id_tickets, values)}
      />
    );
  }
}
