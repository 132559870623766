export default {
  _ANALYZE: 'Analisis',
  _CONTRACT: 'Contrato',
  _PROGRESS: 'Progreso',
  _REQUEST: 'Solicitud',
  _SERVICE: 'Servicio',
  _TEMPLATE: 'PLANTILLA',
  ABSTRACT: 'Resumen',
  ACCEPT: 'Aceptar',
  ACCEPT_SUB_CALLS_OTHER_GROUPS: 'Acepta sub-llamadas de otros grupos',
  ACCEPTABLE: 'Aceptado',
  ACCEPTED: 'Acepto',
  ACCESS: 'Acesso',
  ACCESS_CONTROL: 'Control de acceso',
  ACCESS_OPTION_VIEW_REPORTS: 'Acceda a esta opción para visualizar todos los Informes',
  ACCESS_OPTION_VIEW_THE_REPORTS: 'Acceda a esta opción para visualizar los Informes',
  ACCESS_REMOTE: 'Acceso remoto',
  ACCOUNT: 'Cuenta',
  ACCOUNT_MANAGER: 'Gerente de cuentas',
  ACCOUNTED_HOURS_WILL_NOT_BE_DELETED: 'Las horas contabilizadas no serán excluidas',
  ACCUMULATED: 'Acumulado',
  ACCUMULATED_TOTAL: 'Total acumulado',
  ACCUMULATED_VALUE: 'Valor acumulado',
  ACCUMULATED_VALUES: 'Valores acumulados',
  ACN: 'ACN',
  ACTION: 'Acción',
  ACTION_NOT_ALLOWED_GMUD_FROZEN: 'Acción no permitida, Cambio bloqueado',
  ACTIONS: 'Acciones',
  ACTIONS_TICKET: 'Acciones de tickets',
  ACTIVE: 'Activo',
  ACTIVE_TIME: 'Activo tiempo',
  ACTIVE_PLURAL: 'Activos',
  ACTIVITIES: 'Actividades',
  ACTIVITIES_EXCEEDED_HOURS: 'Actividades (horas excedidas)',
  ACTIVITIES_HOURS_PERFORMED: 'Actividades (horas realizadas)',
  ACTIVITIES_IN_BACKLOG: 'Actividades pendientes de ejecución',
  ACTIVITIES_IN_DOING: 'Actividades en desarrollo',
  ACTIVITIES_IN_DONE: 'Actividades finalizadas',
  ACTIVITIES_IN_STOPPED: 'Actividades paralizadas',
  ACTIVITIES_IN_TODO: 'Actividades por hacer',
  ACTIVITIES_IN_VALIDATION: 'Actividades en validación',
  ACTIVITIES_PLANNED_HOURS: 'Actividades (horas previstas)',
  ACTIVITIES_RELATED_TO_PROJECT_YOU_INVOLVED_IN: 'Actividades relacionadas con el proyecto en el que participa',
  ACTIVITY: 'Actividad',
  ACTIVITY_CLOSED_IN_MONTH: 'Actividades cerradas',
  ACTIVITY_DATE: 'Fecha de la actividad',
  ACTIVITY_DESCRIPTION: 'Descripción de las Actividades',
  ACTIVITY_DID_NOT_REACH_100_PERCENT: 'La actividad no alcanzó el 100%',
  ACTIVITY_EFFORT: 'Actividad Esfuerzo',
  ACTIVITY_EVOLUTION: 'Evolución de la Actividad',
  ACTIVITY_GROUP_NAME: 'Nombre del grupo de actividades',
  ACTIVITY_IN_APRREARS: 'Actividades atrasadas',
  ACTIVITY_IN_ARREARS: 'Actividades atrasadas ',
  ACTIVITY_IN_EXECUTION: 'Actividades en ejecución',
  ACTIVITY_IN_GMUD: 'Actividad en Cambio',
  ACTIVITY_NAME: 'Nombre de la actividad',
  ACTIVITY_NEXT_EXPIRATION: 'Actividades Atrasadas',
  ACTIVITY_PENDENT: 'Existen actividades pendientes',
  ACTIVITY_PER_USER: 'Actividad por usuario',
  ACTIVITY_REGISTER: 'Registro de actividades obligatorio',
  ACTIVITY_STARTED_IN_MONTH: 'Actividades iniciadas en el mes',
  ACTIVITY_STATUS: 'Estado de actividad',
  ACTIVITY_SUMMARY: 'Resumen de actividad',
  ACTIVITY_TAGS: 'Etiquetas de actividad',
  ACTIVITY_TIME: 'Tiempo de actividad',
  ACTIVITY_WITHOUT_START: '¡Actividad registrada sin fecha de inicio!',
  ACTUAL_AVAILABILITY: 'Disponibilidad real',
  ACTUAL_END_DATE: 'Fecha de finalización real',
  ACTUAL_START_DATE: 'Fecha de inicio real',
  AD_USER: 'Usuario AD',
  ADD: 'Agregar',
  ADD_A_COMMENT: 'Añadir un comentario',
  ADD_A_NEW_RESULT: 'Agregar un nuevo resultado',
  ADD_A_SUBACTIVITY: 'Agregar una subactividad',
  ADD_ACTIVITIES: 'Agregar Actividades',
  ADD_ACTIVITIES_TO_CATEGORY: 'Agregar actividades a la categoría',
  ADD_ACTIVITY: 'Agregar Actividad',
  ADD_APPROVAL_TO_GROUP: 'Agregar aprobación al Grupo',
  ADD_ASSIGNMENT: 'Agregar Nueva Tarea',
  ADD_BLACKOUT: 'Agregar Blackout',
  ADD_CATALOG: 'Agregar Catálogo',
  ADD_CATALOG_TO_CATEGORY: 'Agregar Catálogo de Servicio a Categoría',
  ADD_CATEGORIZATION: 'Agregar categorización',
  ADD_CATEGORY: 'Agregar Nueva Categoría',
  ADD_CHANGE_TEMPLATE: 'Agregar plantilla de cambio',
  ADD_COMPANY: 'Agregar Empresa Contratista',
  ADD_COMPLETION_TEMPLATE: 'Agregar plantilla de finalización de llamada',
  ADD_CONTRACT: 'Agregar Contrato',
  ADD_CREDENTIALS: 'Agregar Credenciales',
  ADD_DEPARTMENT: 'Agregar Departamento',
  ADD_EVENT: 'Agregar Evento',
  ADD_EVIDENCE: 'Agregar Evidencia',
  ADD_EXPECTED_RESULT: 'Agregar resultado esperado',
  ADD_FORM: 'Agregar Formulario',
  ADD_GLOBAL: 'Agregar Global',
  ADD_GROUP: 'Agregar Grupo',
  ADD_GROUPING: 'Agregar agrupación',
  ADD_IN_STOCK: 'Registrar en Stock',
  ADD_KNOWLEDGE_BASE: 'Agregar Nueva Base de Conocimiento',
  ADD_LOCATION: 'Agregar Ubicación',
  ADD_MAIL: 'Agregar Email',
  ADD_MILESTONE: 'Agregar Categoría',
  ADD_MONITORING_GROUP: 'Agregar Grupo de Seguimiento',
  ADD_NEW_BLACKOUT: 'Agregar nuevo Blackout',
  ADD_NEW_BUILD: 'Agregar nova Construcción',
  ADD_NEW_DEVICE: 'Agregar Nuevo Dispositivo',
  ADD_NEW_ITEM: 'Agregar nuevo ítem',
  ADD_NEW_PASSWORD: 'Registrar nueva contraseña',
  ADD_NEW_PASSWORD2: 'Agregar nueva contraseña',
  ADD_NEW_TYPE_CONTRACT: 'Agregar Nuevo Tipo de Contrato',
  ADD_OBSERVER: 'Agregar Observadores',
  ADD_OTHER_COSTS: 'Agregar otros costos',
  ADD_OUTSOURCED: 'Agregar Externalización',
  ADD_REQUEST_FORM: 'Agregar Formulario de Solicitud',
  ADD_REQUESTER: 'Agregar Solicitante',
  ADD_RISKS_AND_ACTIVITIES: 'Agregar Riesgos y Grupo de Actividades',
  ADD_RULE: 'Adicionar regra',
  ADD_SERVICE: 'Agregar Servicios',
  ADD_SERVICE_PORTAL: 'Configurar el portal de servicios',
  ADD_SLA: 'Agregar SLA',
  ADD_SOLICITANT: 'Agregar Solicitante',
  ADD_SOLICITANT_TO_GROUP: 'Agregar Solicitante al Grupo',
  ADD_SPONSOR: 'Registrar Nuevo Responsable',
  ADD_SPRINT: 'Agregar Sprint',
  ADD_SUBJECT: 'Agregar asunto',
  ADD_TASK: 'Agregar Nueva Tarea',
  ADD_TASK_ADD: 'Agregar Actividad',
  ADD_TASK2: 'Agregar tarea',
  ADD_TECH_TO_GROUP: 'Agregar Técnico al Grupo',
  ADD_TECHS: 'Agregar Técnico',
  ADD_THE_ACTIVITY: 'Agregar la actividad',
  ADD_THIRD_LEVEL_CATEGORY: 'Agregar Categoría de Tercer Nivel',
  ADD_TO_SPRINT: 'Agregar a Sprint',
  ADD_USER: 'Agregar Usuario',
  ADD_USER_TO_DEPARTMENT: 'Agregar Usuario al Departamento',
  ADD_USER_TO_GROUP: 'Agregar Usuario al Grupo',
  ADD_USER_TO_NOTIFICATION_GROUP: 'Agregar Usuario al Grupo del Notificaciones',
  ADD_USERS_TO_PROJECT: 'Agregar Usuarios al Proyecto',
  ADDED_COSTS: 'Costos adicionales',
  ADDITIONAL_INFORMATION: 'Informaciones adicionales',
  ADDITIONAL_RECIPIENTS: 'Destinatarios Adicionales',
  ADDITIONAL_RECIPIENTS_HOLDER: 'Usa una coma "," para separar las direcciones de correo electrónico',
  ADDITIONALINFO: 'Información adicional',
  ADDRESS: 'Dirección',
  ADHERENCE: 'Adherencia',
  ADHERENT: 'Adherido',
  ADMIN: 'Administrador',
  ADVANCE: 'Avanzar',
  ADVANCED_SEARCH: 'Búsqueda Avanzada',
  AFFECTED_AREA: 'Área afetada',
  AFFECTED_PRODUCT: 'Producto afectado',
  AFTER: 'Después',
  AFTER_DEADLINE: 'Después de la fecha límite',
  AFTER_POST_IMPLEMENTATION_REVIEW: 'Después de revisión post implementación',
  AGENT: 'Agente',
  AGENT_DOWNLOAD: 'Agente de descargas',
  AGENT_VERSION: 'Versión para Agente',
  ALERT: 'Alerta',
  ALERT_ATTACH_SAVE_CHECK: 'El archivo adjunto se agregó con éxito, para guardar haga clic en el botón guardar al final de las comprobaciones',
  ALERT_CENTER: 'Centro de alerta',
  ALERT_CONTRACTOR_APROVE_REQUEST: '¡Sólo el responsable del contrato tiene permiso para aprobar la solicitud!',
  ALERT_DELETE_SUCCESS: 'Datos Eliminados con Éxito',
  ALERT_INSERT_SUCCESS: 'Datos introducidos con Éxito',
  ALERT_MANAGEMENT: 'Gestión de alertas',
  ALERT_NO_RESULT_FOUND: 'No se han encontrado resultados',
  ALERT_TICKET: 'no contestado',
  ALERT_UPDATE_SUCCESS: 'Datos actualizados con Éxito',
  ALERT_WANT_GENERATE_DETAILED_GMUD: '¿Quiere generar un Cambio detallado? Le recomendamos la opción completa. Si desea generar un Cambio sencillo y rápido, elija la opción standard',
  ALERTS: 'Alertas',
  ALERTS_GROUP: 'Grupo de Alertas',
  ALIAS: 'Alias',
  ALL: 'Todos',
  ALL_ADDED_ACTIVITIES: 'Se han agregado todas las actividades',
  ALL_DEVICES: 'Todos los Dispositivos',
  ALLOCATED: 'Asignado',
  ALLOCATED_HOURS: 'Horas Asignadas',
  ALLOCATION: 'Asignación',
  ALLOCATION_AND_DELIVERIES: 'Asignación y entregas',
  ALLOCATION_OF_TEAM: 'Asignación de equipo',
  ALLOW_CREATE_SUB_CALLS: 'Permitir crear sub-llamadas',
  ALLOW_CUSTOMIZE_PRIORITY: 'Permitir personalizar la prioridad',
  ALLOW_EDITING_OF_TICKET_REQUESTER: 'Permitir la edición del solicitante al ticket',
  ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL: 'Permitir la edición de gmud técnico después de aprobación',
  ALLOW_SENDING_EMAIL: 'Permitir envío de email',
  ALLOW_CREATING_DOCUMENTED_CRISIS_TICKET: 'Permitir la creación de tickets de crisis documentados',
  ALLOWANCE: 'Abonar',
  ALLOWANCE_READY: 'Ticket abonado Con Éxito',
  ALLOWANCE_SELECTED: 'Ticket seleccionados',
  ALLOWANCE_SLA: 'SLA Abonado',
  ALLOWED_EXTENSIONS: 'Extensiones permitidas',
  ALSO_FINALIZE_THE_SUBCONTRACTS: 'También finalizar los subcontratos',
  ALSO_REMOVE_THE_SUBCONTRACTS: 'Eliminar también los subcontratos',
  ALTERNATE_BOSS: 'Jefe Alternativo',
  ALTERNATIVE_EMAIL: 'Email alternativo',
  ALTERNATIVE_LEADER: 'Líder Alternativo',
  AMOUNT: 'Valor Total',
  AMOUNT_OF_HOURS: 'Cantidad de horas',
  AMOUNT_OF_MILESTONES: 'Cantidad de actividades',
  AMOUNT_OF_RISKS: 'Número de Riesgos',
  AN_HOUR: 'uma hora',
  AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER: 'Ocurrió un error interno en el servidor',
  ANALITIC: 'Analítico',
  ANALYSIS: 'Análisis',
  ANALYSIS_OF_INFORMATION_CALL: 'Análisis de Información de la llamada',
  ANALYSIS_OF_INFORMATION_GSD: 'Análisis de la Información de GSD',
  ANALYSIS_PROJECT_MANAGEMENT: 'Análisis de gestión de proyectos',
  ANALYST: 'Analista',
  ANALYST_CALLS: 'Llamadas de Analistas',
  ANALYSTS: 'Analistas',
  ANALYZE: 'Analizar',
  ANALYZE_CHANGE: 'Analizar el cambio',
  ANALYZE_DEVICE_MONITORING: 'Análisis de la supervisión de dispositivos',
  ANALYZING: 'Analizando',
  AND: 'y',
  ANNOTATION: 'Anotación',
  ANSWER: 'Respuesta',
  ANSWER_QUESTION: 'Pendiente de Respuesta',
  ANSWERED_CALLS_RESOLVED_AND_CLOSED: 'Llamadas atendidas (Resueltas y Cerradas)',
  ANSWERS: 'Respuestas',
  ANTICIPATED: 'Anticipado',
  APPLICABLE: 'Aplicable',
  APPROVAL: 'Aprobación',
  APPROVAL_CARRIED_OUT_SUCCESSFULLY: 'Aprobación realizada con éxito',
  APPROVAL_CENTER: 'Centro de aprobación',
  APPROVAL_DATE: 'Fecha de Aprobación',
  APPROVAL_DETAILS: 'Detalles de Aprobación',
  APPROVAL_GROUP: 'Grupo de Aprobaciones',
  APPROVAL_HISTORY: 'Historial de aprobación',
  APPROVAL_REQUIRED: 'Aprobación necesaria',
  APPROVAL_SUCCESSFULLY_COMPLETED: 'Aprobación realizada con éxito',
  APPROVALS: 'Aprobaciones',
  APPROVE: 'Aprobar',
  APPROVE_ATTACHMENTS: 'Aprobar archivos adjuntos',
  APPROVE_EVIDENCE: 'Aprobar evidencia',
  APPROVE_KNOWLEDGE: 'Aprovar Criação/Alteração da base de conhecimento',
  APPROVE_MATURITY: 'Aprobar vencimiento',
  APPROVE_REQUESTS: 'APROBAR SOLICITUDES',
  APPROVED: 'Aprobado',
  APPROVER: 'Aprobación',
  APPROVERS: 'Aprobaciones',
  APPROVERS_GROUP: 'Grupo de Aprobadores',
  APPROVERS_GROUPS: 'Grupos de aprobadores',
  APPROVING_GROUP: 'Grupo de aprobación',
  ARCHITECTURE: 'Arquitectura',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COST: '¿Está seguro de que desea excluir este costo?',
  AREA_BENEFITED_FROM_THE_PROJECT: 'Área beneficiada por el proyecto',
  ASK: 'Pregunta',
  ASKS: 'Preguntas',
  ASSESSMENT: 'Soporte',
  ASSESSMENTS: 'Evaluaciones',
  ASSESSMENTS_LIST: 'Lista de Soportes',
  ASSET_INVOLVED: 'Activo involucrado',
  ASSET_MANAGEMENT: 'Administración de Activos',
  ASSET_MANAGER: 'Asset Manager',
  ASSET_TYPE: 'Tipo de Activo',
  ASSIGN: 'Asignar',
  ASSIGN_CALLS_ONLY_TECH_IS_FREE: 'Asignar llamadas sólo si el técnico está libre',
  ASSIGNED_GROUP: 'Grupo asignado',
  ASSIGNED_TECH_STOP_RESPONSE_SLA: 'Técnico asignado, detener SLA de respuesta',
  ASSOCIATE: 'Asociado',
  ASSOCIATE_COMPANY: 'Compañía asociada',
  ASSOCIATE_DEPARTMENT: 'Departamento Asociado',
  ASSOCIATE_IN: 'Asociado en',
  ASSOCIATE_PREDECESSOR_TASKS: 'Asociar tareas anteriores',
  ASSOCIATE_PROJECT_ACTIVITIES: 'Asociar actividades del proyecto',
  ASSOCIATE_TASK_TEMPLATE: 'Plantilla de tareas asociadas',
  ASSOCIATE_TASKS: 'Tareas asociadas',
  ASSOCIATE_TECH_CONTRACT: 'Asociar Técnico x contratos',
  ASSOCIATE_TECH_X_COMPANY: 'Técnico Asociado x Empresa',
  ASSOCIATE_TICKET: 'Ticket Asociado',
  ASSOCIATE_TICKETS: 'Asociar Tickets',
  ASSOCIATE_TO_TEMPLATE: 'Asociar a Plantilla',
  ASSOCIATE_TO_TICKET: 'Asociar al ticket',
  ASSOCIATE_USER: 'Usuario Asociado',
  ASSOCIATE_USER_CONTRACT: 'Asociar Usuario x contratos',
  ASSOCIATE_USER_X_COMPANY: 'Asociar Usuario x Empresa',
  ASSOCIATE_WITH_GP: 'Asociado con GP',
  ASSOCIATE_WITH_TK: 'Asociado con TK',
  ASSOCIATED_ACTIVITY: 'Actividad asociada',
  ASSOCIATED_ITEMS: 'Artículos asociados',
  ASSOCIATED_TASKS: 'Tareas asociadas',
  ASSOCIATED_TICKETS: 'Tickets asociados',
  ASSOCIATING_TICKET_CLOSE: 'Al asociar los ticket estos se cerrarán',
  ASSOCIATION_ITEM_TO_PROJECT: 'Asociar ítem al proyecto',
  ASSOCIATION_ITEM_TO_PROJECT_ACTIVITY: 'Asociar ítem de actividad a un proyecto',
  ASSOCIATION_ITEM_TO_PROJECT_OR_MANUAL: 'Asociar el ítem selecconado a un #Proyecto, Tiempo promedio de Manejo, Tiempo de Categorización Manual',
  ASSOCIATION_TICKETS: 'Asociación de Tickets',
  AT: 'En',
  ATTACH: 'Adjuntar',
  ATTACH_DATE: 'Adjuntar fecha',
  ATTACH_EVIDENCE: 'Adjuntar Evidencia',
  ATTACH_FILE: 'Adjuntar archivo',
  ATTACH_FILE: 'Adjuntar archivos',
  ATTACH_FILE_ERROR: 'Este archivo tiene un formato inválido o es más grande de lo permitido.',
  ATTACH_FILES_HERE: 'Adjuntar archivos aquí',
  ATTACHMENT: 'Adjunto',
  ATTACHMENTS: 'Adjuntos',
  ATTACHED_EDIT: 'Archivos adjuntos editados',
  ATTACHMENT: 'Adjunto',
  ATTACHMENTS: 'Adjuntos',
  ATTEND: 'Atender',
  ATTENDANCE: 'Servicio',
  ATTENDANCE_AVERAGE: 'Promedio de asistencia',
  ATTENDANCE_DEADLINE: 'Fecha límite de atención',
  ATTENDANCE_GROUP: 'Grupo de atención',
  ATTENDANCE_STOPPED: 'Llamada paralizada',
  ATTENDANT: 'Asistente',
  ATTENDED: 'Atendidos',
  ATTENTION: 'Atención!',
  AUDIT: 'Auditoría',
  AUDIT_CATALOG_MANAGEMENT_BODY: 'Auditoría de lo catalogo',
  AUDIT_COMPANY_MANAGEMENT_BODY: 'Auditoría de la empresa',
  AUDIT_FLOW_APPROBATION_GMUD: 'Auditoría del flujos de aprobación de cambios',
  AUDIT_FORM: 'Auditoría de formulario',
  AUDIT_USERS_MANAGEMENT_BODY: 'Auditoría de usuarios',
  AUTHOR: 'Autor',
  AUTOMATIC_ANSWER: 'Respuesta Automática',
  AUTOMATIC_APPROVER_GROUPS: 'Grupos de aprobación automática',
  AUTOMATIC_ASSIGN_TICKET_AFTER: 'Asignar ticket automáticamente después de',
  AUTOMATIC_CLOSING: 'Cierre automático',
  AUTOMATION: 'Automatización',
  AVAILABILITY: 'Disponibilidad',
  AVAILABILITY_MANAGEMENT: 'Gestión de Disponibilidad',
  AVAILABLE_CLASSES: 'Clases disponibles',
  AVALIATION_SENT: 'Gracias, la encuesta de satisfacción ha sido enviada exitosamente.',
  AVALIATION_TABLE: 'Tabla de Avaluación',
  AVALIATION_TICKET: 'Evaluación del ticket',
  AVALIATION_TICKET_RESOLUTION_QUALITY: 'En una escala del 0 al 10, ¿cuál es tu evaluación sobre la calidad de la solución del ticket?',
  AVALIATION_TICKET_RESOLUTION_TIME: 'En una escala del 0 al 10, ¿cuál es tu evaluación sobre el tiempo de resolución del ticket?',
  AVALIATION_TICKET_TEAM: 'En una escala del 0 al 10, ¿cómo fue tu experiencia con nuestro equipo de atención al cliente?',
  AVERAGE: 'Promedio',
  AVERAGE_AVAILABILITY: 'Disponibilidad media',
  AVERAGE_CATEGORIZATION_TIME: 'Tiempo Promedio de Categorización',
  AVERAGE_OF_THE_LAST_TWELVE_MONTHS: 'Promedio de los últimos 12 meses',
  AVERAGE_SERVICE_TIME: 'Tiempo Promedio de Servicio',
  AVERAGE_TIME: 'Tiempo promedio',
  AVERAGES_OF_EACH_STANDARD: 'Medias de cada norma',
  AWAITING_EXECUTION: 'Aguardando ejecución',
  BACK: 'Volver',
  BACKDATING_IS_NOT_ALLOWED: '¡No se permite retroceder!',
  BACKLOG: 'BackLog',
  BACKLOG_ACTIVITY: 'Actividades Pendientes',
  BACKLOG_PROJECTS: 'Proyectos Pendientes',
  BACKUP: 'Copia de Seguridad',
  BACKUP_LIST: 'Lista de Copias de Seguridad',
  BACKUP_MANAGEMENT: 'Gestión de Copias de Seguridad',
  BACKUP_SIDE_DISH: 'Seguimiento de Copias de Seguridad',
  BACKUP_TYPE: 'Tipo de Copia de Seguridad',
  BALANCE: 'Balance',
  BALANCE_SCORE_CARD: 'Balanced Score Card',
  BASE: 'Base',
  BASIC_INFO: 'Información Básica',
  BASIC_TICKET_INFORMATION: 'Información básica del ticket',
  BEFORE: 'Antes',
  BEFORE_DEADLINE: 'Antes de la fecha límite',
  BENEFITED_AREA_PROJECT: 'Área beneficiaria del proyecto',
  BENEFITS: 'Beneficios',
  BILLING_DATE: 'Fecha de Facturación',
  BILLING_DATE_MONTH: 'Mes de Faturación',
  BILLING_DAY: 'Día de facturación',
  BILLING_TYPE: 'Tipo de Faturación',
  BIND_TICKET: 'Ticket de vinculación',
  BIOS_MANUFACTURER: 'Fabricante da bios',
  BIOS_NAME: 'Nombre de la BIOS',
  BIOS_SERIAL_NUMBER: 'Número de serie de la bios',
  BIOS_VERSION: 'Versión de la BIOS',
  BLOCK_TICKET_WITH_TASKS_PENDENT: 'Bloquear la Modificación de Tickets Pendientes',
  BOARD_DESIRE: 'Deseo de la Junta Directiva',
  BOARD_MANUFACTURER: 'Fabricante de placas',
  BOARD_PRODUCT: 'Bandeja de producto',
  BOARD_SERIAL_NUMBER: 'Número de serie de la placa',
  BOARD_VERSION: 'Versión de placa',
  BOSS: 'Jefe',
  BSC: 'BSC',
  BSC_IN: 'Indicadores',
  BSC_INDICATOR: 'Indicador',
  BSC_METAS: 'Metas',
  BSC_OB: 'Objetivo empresarial',
  BSC_PERS: 'Perspectiva',
  BSC_TI: 'Objetivo de TI',
  BUDGETED_AMOUNT: 'Valor presupuestado',
  BUDGETED_DEVIATION: 'Desviación presupuestaria',
  BUILD: 'Construir',
  BURST: 'Quebrado',
  BUSINESS_AREA: 'Area de negocio',
  BUSINESS_CONTINUITY_MANAGEMENT: 'Gestión de la Continuidad del Negocio',
  BY: 'Por',
  BY_AREA: 'Por área',
  BY_CATEGORIZATION: 'Por categorización',
  BY_CLOSING: 'Al cerrar',
  BY_CONSUMPTION: 'Por consumo',
  BY_DATE: 'Por fecha',
  BY_DEPARTMENT: 'Por departamento',
  BY_ID: 'Por identificación',
  BY_MONTH: 'Por mes',
  BY_OPENING: 'Al abrir',
  BY_TECHNICAL_GROUP_WITH_CONSUMED_HOURS: 'Por grupo técnico con horas consumidas ',
  BY_TYPE: 'Por tipo',
  BY_VALUE: 'Por valor',
  CAB_ACCEPT: 'Aprobar CAB',
  CAB_ALERT: 'CAB guardado correctamente',
  CAB_ANALYSIS: 'Análisis del CAB',
  CAB_ATTACH: 'Adjuntar ATA',
  CAB_EVENTS: 'Eventos del CAB',
  CAB_REJECT: 'Rechazar CAB',
  CAB_REJECTED: 'CAB Rechazado',
  CAB_SCHEDULING: 'Programación de CAB',
  CADASTRE_MADE: 'Catastro Realizado',
  CADASTRE_TIME: 'Hora Catastro',
  CALENDAR: 'Calendario',
  CALENDAR_GMUD: 'Calendario Cambio',
  CALENDAR_OF_ACTIVITIES: 'Calendario de actividades',
  CALL: 'Llamada',
  CALL_CLOSING: 'Cierre de llamadas',
  CALL_EVALUATION: 'Evaluación de Llamadas',
  CALL_NUMBER: 'Número de llamada',
  CALL_QUANTITY_GRAPH: 'Gráfico de cantidad de llamadas',
  CALL_REJECTED: 'Llamada rechazada',
  CALL_REPORTING: 'Informe de llamadas',
  CALL_TREND_PER_DAY: 'Tendencia de llamadas por día y mes',
  CALLED_BY_ANALYST: 'Llamadas Por Analista',
  CALLED_PROVIDER: 'Proveedor llamado',
  CALLED_TYPE: 'Tipo llamado',
  CALLS: 'Llamadas',
  CALLS_OF_MONTH: 'Llamadas del mes',
  CALLS_PER_MONTH: 'Llamadas en el mes',
  CANCEL: 'Cancelar',
  CANCEL_ACTIVITY: 'Cancelar actividad',
  CANCEL_CALL: 'Cancelar Llamada',
  CANCELED: 'Cancelado',
  CANCELED_PROJECTS: 'Proyectos cancelados',
  CANNOT_BE_ASSOCIATED_DIFFERENT_COMPANIES: 'Item não pode ser associado, empresas diferente!',
  CANVAS: {
    '1_TO_5': 'Del 1 al 5',
    ACTIONS_SUPPORT_COMPANY_STRATEGY: 'Acciones que apoyan la estrategia de la empresa',
    ACTIVITY_PRD: 'Actividad predecesora',
    BOARD_DIRECTORS: 'Junta',
    BUSINESS_PARTHER_CONTRACTS: 'Contratos de socios empresariales (cadena de valor)',
    COMMUNICATION_RESULTS: 'Comunicación y Resultados',
    CRITICAL_PROCESS_IT_SUPPORTS: 'Procesos críticos que TI soporta con impacto directo',
    CRITICAL_SERVICES: 'Servicios críticos',
    EXPENSES: 'Gastos',
    HOW_RESULTS_MONITORED: 'Cómo se monitorearán y presentarán los resultados',
    ID_ACESS_REMOTE: 'Id de acceso remoto',
    INDICATOR_TYPES: 'Tipos de indicadores',
    INVESTMENT: 'Inversión',
    INVESTMENTS: 'Inversiones',
    IT_MODEL_CANVAS: 'Plantilla de pantalla de TI',
    IT_OBJECTIVE: 'Objetivo de TI',
    ITEMS_IMPACTS_BUSSINES: 'Impedimentos para los objetivos estratégicos de la empresa',
    ITEMS_SUPPORT_GOALS: 'Elementos que apoyan los objetivos de la empresa',
    PLANED_REQUIRED_EXPENSES: 'Gastos previstos/necesarios',
    PLANNED_INVESTIMENTS: 'Inversiones previstas/necesarias',
    PRIORITIZATION: 'Prioridades',
    RISK_MAPPING: 'Mapeo de riesgos'
  },
  CANVAS_: 'Lienzo',
  CAPACITY: 'Capacidad',
  CAPACITY_MEMORY: 'Capacidad de memoria',
  CAPACITY_PLAN: 'Plan de Capcidad',
  CATALOG: 'Catalogar',
  CATALOG_LIST: 'Lista de subcategorías',
  CATALOG_TASK: 'Categoría de tercer nivel',
  CATEGORIES: 'Categorías',
  CATEGORIZATION: 'Categorización',
  CATEGORIZATION_DATA_FINAL: 'Fecha de Categorización - Final',
  CATEGORIZATION_DATA_INITIAL: 'Fecha de Categorización - Inicial',
  CATEGORIZATION_DATE: 'Fecha de Categorización',
  CATEGORIZATION_LIST: 'Lista de categorización',
  CATEGORIZATION_TIME: 'Tiempo de Categorización',
  CATEGORIZATIONS: 'Categorizaciones',
  CATEGORIZE: 'Clasificar por categorías',
  CATEGORIZE_SELECTED: 'Categorizar seleccionado',
  CATEGORY: 'Categoría',
  CATEGORY_ACTIVITY: 'Grupo de Actividad',
  CATEGORY_DESCRIPTION: 'Es un elemento utilizado principalmente para clasificar y facilitar el orden de los Grupos.',
  CATEGORY_LIST: 'Lista de Categorías',
  CAUSE: 'Causa',
  CELL_PHONE: 'Teléfono Móvil',
  CHANGE: 'Cambios',
  CHANGE_ADDED_SUCCESSFULLY: 'Cambio agregado con éxito',
  CHANGE_DATA: 'Cambiar datos',
  CHANGE_DATA_SUCCESS: 'Datos Cambiados con Éxito',
  CHANGE_HISTORY: 'Cambia la historia',
  CHANGE_LIST: 'Lista de cambios',
  CHANGE_LOCATION: 'Cambiar ubicación',
  CHANGE_MANAGEMENT: 'Gestión del cambio',
  CHANGE_MANAGER: 'Gestor de procesos de cambios',
  CHANGE_OPEN: 'Abrir el cambio',
  CHANGE_PASSWORD: 'Cambiar Contraseña',
  CHANGE_PASSWORD_SUCCESS: 'Contraseña cambiada con éxito',
  CHANGE_REGISTER_MANAGER: 'Gestor de registros de cambios',
  CHANGE_REQUESTER: 'Requeridor de cambios',
  CHANGE_RISK_ALERT: 'Riesgo de cambio registrado con éxito',
  CHANGE_SORTING: 'Cambiar la clasificación',
  CHANGE_STATUS: 'Cambiar estado',
  CHANGE_TECH_GROUP: 'Cambiar el grupo técnico',
  CHANGE_TECHNICIAN: 'Cambiar de técnico',
  CHANGE_TEMPLATE: 'Cambiar plantilla',
  CHANGE_VISUALIZATION: 'Cambiar de vista',
  CHANNEL: 'Canal',
  CHANNELS: 'Canales',
  CHANNELS_MANAGEMENT: 'Gestión de canales',
  CHARACTERS: 'Caracteres',
  CHARGE_INTERVAL: 'Pausa de carga',
  CHARGE_TRANSFER: 'Posiciones de transferencia',
  CHAT: 'Chat',
  CHAT_HISTORY: 'Historial de chat',
  CHECK: 'Consulta',
  CHECK_ALL: 'Marca todas',
  CHECK_FIELD_SIZES: 'Compruebe el tamaño de los campos',
  CHECK_YOUR_EMAIL_BOX: 'Revisa tu casilla de correo electrónico',
  CHECKED: 'Verificado',
  CHOOSE_ICON: 'Elegir Ícono',
  CHOOSE_ITENS: 'Elige los artículos',
  CIO: 'CIO',
  CIO_COCKPIT: 'CIO Cockpit',
  CITY: 'Ciudad',
  CLASSIFICATION: 'Clasificación',
  CLEAR: 'Limpiar',
  CLEAR_FILTER: 'Limpiar Filtro',
  CLEAR_TECH_AFTER_REOPEN_TICKET: 'Borrar técnico después de reabrir el ticket',
  CLICK_ICON_SEE_ATTENDANCE: 'Haga clic en el ícono para ver los detalles de los servicios prestados',
  CLIENT: 'Cliente',
  CLIENTS: 'Clientes',
  CLIENTS_REGISTER: 'Registro de Clientes',
  CLOCK_SPEED: 'Velocidad de reloj',
  CLONE: 'Clonar Dispositivo',
  CLONE2: 'Clonar',
  CLOSE: 'Cerrar',
  CLOSE_TICKET: 'Cerrar el ticket',
  CLOSED: 'Cerrado',
  CLOSED_PLURAL: 'Cerrados',
  CLOSED_PROJECT: 'Proyecto cerrado',
  CLOSING: 'Cerrar',
  CLOSING_DATE: 'Fecha de cierre',
  CMDB: {
    APPLICATION: 'Solicitud',
    ASSET: 'Activo',
    BACKUP: 'Backup',
    BATCHJOB: 'Trabajo por lotes',
    CI_BASE: 'Base de IC',
    CI_BUSINESS_SERVICE: 'Servicio de negocios IC',
    DATABASE: 'Banco de datos',
    ENDPOINT: 'Endpoint',
    IP: 'IP',
    MONITORING: 'Supervisión',
    REFERENCE_ID: 'ID de referencia',
    SERVER: 'Servidor'
  },
  CMDB_: 'CMDB',
  CMDB_APP_NAME: 'Nombre de la Aplicación',
  CMDB_APP_PORT: 'Puerto de Aplicación',
  CMDB_ASSET_COLLOCATION: 'Colocación de Activos',
  CMDB_ASSET_DC: 'Activo DC',
  CMDB_ASSET_OWNER: 'Propietario del Activo',
  CMDB_ASSET_TAG: 'Etiqueta del Activo',
  CMDB_ASSET_TYPE: 'Tipo de Activo',
  CMDB_BATCHJOB_CONF_DIR: 'Diretorio de configuración',
  CMDB_BATCHJOB_CONF_FILE: 'Archivo de configuración',
  CMDB_BATCHJOB_INS_DIR: 'Directorio de instalación',
  CMDB_BATCHJOB_INT_REF: 'Referencia para integración',
  CMDB_BATCHJOB_JUSTIFY: 'Justificación',
  CMDB_BATCHJOB_RUN_PROC_COM: 'Comando del proceso de ejecución',
  CMDB_BATCHJOB_RUN_PROC_COM_HASH: 'Hash de comando del proceso de ejecución',
  CMDB_BATCHJOB_RUN_PROC_KEY_PARAM: 'Parámetros clave del proceso de ejecución',
  CMDB_BATCHJOB_RUN_PROC_KEY_PARAM_HASH: 'Hash de parámetros clave del proceso de ejecución',
  CMDB_BATCHJOB_TCP_PORTS: 'Puertos TCP',
  CMDB_BKP_CLIENT_HOST: 'Cliente host copia de seguridad',
  CMDB_BKP_JOB_TYP: 'Copia de Seguridad del Tipo de Trabajo',
  CMDB_BKP_MASTER_CLIENT: 'Servidor maestro copia de seguridad',
  CMDB_BKP_SCHEDULES: 'Programación de copias de seguridad',
  CMDB_CI_BASE_ACN: 'IC Base ACN',
  CMDB_CI_BASE_ASSIGN_GROUP: 'Grupo de Asignación de Base CI',
  CMDB_CI_BASE_CDPT: 'CDPT Base de IC',
  CMDB_CI_BASE_CI_TYPE: 'CI Tipo de base',
  CMDB_CI_BASE_CODE: 'Código Base IC',
  CMDB_CI_BASE_ENV: 'Entorno base CI',
  CMDB_CI_BASE_FQDN: 'IC Base Fqdn',
  CMDB_CI_BASE_LOCATION: 'Ubicación de la base IC',
  CMDB_CI_BASE_OPER_DATE: 'Fecha operativa base de la IC',
  CMDB_CI_BASE_OPER_STATUS: 'Estado Operativo de la Base IC',
  CMDB_CI_BASE_SUP_GROUP: 'Grupo de soporte da base de IC',
  CMDB_CI_BASE_SUPPORTED: 'Base IC compatíble',
  CMDB_CI_BASE_VERSION: 'Versión de base IC',
  CMDB_CI_BS_BUSINESS_CRITICALITY: 'Criticidad Del Servicio De Negocios De IC',
  CMDB_CI_BS_BUSINESS_IMPACT: 'Impacto Del Servicio de Negocio De IC',
  CMDB_CI_BS_BUSINESS_PROCESS: 'Proceso De Servicio de Negocio De IC',
  CMDB_CI_BS_SERVICE_NAME: 'CI Nombre del servicio comercial',
  CMDB_CI_STATUS: 'Estado de CI',
  CMDB_DB_INSTANCE: 'Instancia de la Base de Datos',
  CMDB_DB_TCP: 'Puerto Tcp de la base de datos ',
  CMDB_ENDPOINT_PORT: 'Puerto De Punto Final',
  CMDB_ENDPOINT_PROTOCOL: 'Protocolo De Puntos Finales',
  CMDB_IP_MANAGED_GROUP: 'Administración Grupo De Ip',
  CMDB_IP_OWNER: 'Propietario Ip',
  CMDB_IP_TYPE: 'Tipo De Ip',
  CMDB_MON_POLICY_ID: 'Política de control Id',
  CMDB_MON_TOOL: 'Herramienta de Control',
  CMDB_REF_ID: 'Id de Referencia',
  CMDB_REF_TOOL: 'Herramienta De Referencia',
  CMDB_SERIAL_NUMBER: 'Número de Serie del Activo',
  CMDB_SRV_CPU: 'Cpu Del Servidor',
  CMDB_SRV_DISK_SPACE: 'Espacio de Disco en el Servidor',
  CMDB_SRV_HOST: 'Host Del Servidor',
  CMDB_SRV_HOST_ID: 'Id Del Host Del Servidor',
  CMDB_SRV_IS_VIRTUAL: 'El Servidor es Virtual',
  CMDB_SRV_LOCALIZATION: 'Ubicación Del Servidor',
  CMDB_SRV_OBJECT_ID: 'Id De Objeto Del Servidor',
  CMDB_SRV_OS: 'Sistema Operativo Del Servidor',
  CMDB_SRV_RAM: 'Ram Del Servidor',
  COBIT: 'COBIT',
  CODE: 'Código',
  CODE_PEP: 'Código PEP',
  CODE_RESOLUTION_NAME: 'Nombre del código de resolución',
  CODE_RESOLUTION_VALUE: 'Valor del código de resolución',
  CODE_SAP: 'Código SAP',
  COIN: 'Divisa',
  COIN_LIST_AFGHAN: 'Afgani',
  COIN_LIST_ARUBAN_FLORIN: 'Florín arubeño',
  COIN_LIST_AZERBAIJANI_MANAT: 'Manat azerbaiyano',
  COIN_LIST_BAHAMIAN_DOLLAR: 'Dólar bahameño',
  COIN_LIST_BAHRAINI_DINAR: 'Dinar bareiní',
  COIN_LIST_BALBOA: 'Balboa (El dólar estadounidense USD es la moneda en el país)',
  COIN_LIST_BARBADIAN_DOLLAR: 'Dólar barbadense',
  COIN_LIST_BELARUSIAN_RUBLE: 'Rublo bielorruso',
  COIN_LIST_BELIZE_DOLLAR: 'Dólar beliceño',
  COIN_LIST_BERMUDIAN_DOLLAR: 'Dólar bermudeño',
  COIN_LIST_BOLIVAR: 'Bolívar Soberano venezolano',
  COIN_LIST_BOLIVAR2: 'Bolívar Soberano venezolano',
  COIN_LIST_BRUNEI_DOLLAR: 'Dólar de Brunéi',
  COIN_LIST_BURUNDIAN_FRANC: 'Franco burundés',
  COIN_LIST_CANADIAN_DOLLAR: 'Dólar canadiense',
  COIN_LIST_CAYMAN_ISLANDS_DOLLAR: 'Dólar de las Islas Caimán',
  COIN_LIST_COLON: 'Colon de Costa Rica',
  COIN_LIST_CONGOLESE_FRANC: 'Franco congoleño',
  COIN_LIST_CONVERTIBLE_MARK: 'Marco bosnioherzegovino',
  COIN_LIST_CORDOBA_NICARAGUAN: 'Córdoba nicaragüense',
  COIN_LIST_CZECH_CROWN: 'Corona checa',
  COIN_LIST_DANISH_KRONE: 'Corona danesa',
  COIN_LIST_FALKLAND_ISLANDS_POUND: 'Libra de las Malvinas',
  COIN_LIST_FIJIAN_DOLLAR: 'Dólar fiyiano',
  COIN_LIST_GIBRALTAR_POUND: 'Libra gibraltareña',
  COIN_LIST_GUINEAN_FRANC: 'Franco guineano',
  COIN_LIST_GUYANESE_DOLLAR: 'Dólar de Guyana',
  COIN_LIST_HUNGARIAN_FORINT: 'Forinto húngaro',
  COIN_LIST_ICELANDIC_KRONE: 'Corona islandesa',
  COIN_LIST_IRANIAN_RIAL: 'Rial iraní',
  COIN_LIST_IRAQI_DINAR: 'Dinar iraquí',
  COIN_LIST_JAMAICAN_DOLLAR: 'Dólar jamaiquino',
  COIN_LIST_KENYAN_SHILLING: 'Chelín keniano',
  COIN_LIST_KUWAITI_DINAR: 'Dinar kuwaití',
  COIN_LIST_MAROCCAN_DIRHAM: 'Dírham marroquí',
  COIN_LIST_MEXICAN_INVESTIMENT_UNIT: 'Unidad mexicana de inversión',
  COIN_LIST_MOLDOVAN_LEU: 'Leu moldavo',
  COIN_LIST_NAMIBIAN_DOLLAR: 'Dólar namibio',
  COIN_LIST_NEW_ZEALAND_DOLLAR: 'Dólar neozelandés',
  COIN_LIST_NORWEGIAN_KRONE: 'Corona noruega',
  COIN_LIST_QATARI_RIYAL: 'Rial qatarí',
  COIN_LIST_ROMANIAN_LEU: 'Leu rumano',
  COIN_LIST_RWANDAN_FRANC: 'Franco ruandés',
  COIN_LIST_SERBIAN_DINAR: 'Dinar serbio',
  COIN_LIST_SEYCHELLOIS_RUPEE: 'Rupia de Seychelles',
  COIN_LIST_SINGAPORE_DOLLAR: 'Dólar de Singapur',
  COIN_LIST_SOLOMON_ISLANDS_DOLLAR: 'Dólar de las Islas Salomón',
  COIN_LIST_SOMALI_SHILLING: 'Chelín somalí',
  COIN_LIST_SOUTH_KOREAN_WON: 'Won surcoreano',
  COIN_LIST_SRI_INDIAN_RUPEE: 'Rupia india',
  COIN_LIST_SRI_LANKAN_RUPEE: 'Rupia de Sri Lanka',
  COIN_LIST_SUCRE: 'Sucre (El dólar estadounidense USD es la moneda en el país)',
  COIN_LIST_SUCRE2: 'Sucre (El dólar estadounidense USD es la moneda en el país)',
  COIN_LIST_SUDANESE_DINAR: 'Dinar sudanés',
  COIN_LIST_SURINAMESE_DOLLAR: 'Dólar surinamés',
  COIN_LIST_SWEDISH_KRONE: 'Corona sueca',
  COIN_LIST_SWISS_FRANC: 'Franco suizo',
  COIN_LIST_SYRIAN_POUND: 'Libra de Siria',
  COIN_LIST_TANZANIAN_SHILLING: 'Chelín de Tanzania',
  COIN_LIST_TRINIDAD_AND_TOBAGO_DOLLAR: 'Dólar trinitense',
  COIN_LIST_TUNISIAN_DINAR: 'Dinar tunecino',
  COIN_LIST_UGANDAN_SHILLING: 'Chelín ugandés',
  COIN_LIST_UKRAINIAN_HRYVNIA: 'Grivna',
  COIN_LIST_UNIT_OF_ACCOUNT: 'Unidad de desarrollo (código de fondos)',
  COIN_LIST_URUGUAYAN_PESO: 'Peso uruguayo',
  COIN_LIST_URUGUAYAN_PESO_IN_INDEXED_UNITS: 'Peso uruguayo en unidades indexadas',
  COIN_LIST_WIR_EURO: 'WIR euro (moneda complementaria)',
  COIN_LIST_WIR_FRANC: 'WIR franc (moneda complementaria)',
  COIN_LIST_YEMENI_RIAL: 'Rial yemení',
  COIN_LIST_ZIMBABWEAN_DOLLAR: 'Dólar zimbabuense',
  COIN_MANAGEMENT: 'Gestión de divisas',
  COLLECTION_HISTORY: 'Historial de la colección',
  COLOR: 'Color',
  COLORS_CUSTOMIZATION_TICKET_PRIORITY_URGENCY_IMPACT: 'Personalización de los colores de Prioridad/Urgencia/Impacto en los tickets',
  COMERCIAL_PHONE: 'Teléfono Comercial',
  COMMENT: 'Comentarios',
  COMMENT_DETAILS: 'Detalles de los comentarios',
  COMMENTAR: 'Comentar',
  COMMENTARY: 'Comentario',
  COMMUNICATION_AND_OPERATIONS_MANAGEMENT: 'Comunicación y Gestión de Operaciones',
  COMMUNITY: 'Comunidad',
  COMPANIES: 'Empresas',
  COMPANY: 'Empresa',
  COMPANY_CAB_GLOBAL: 'Empresa mundial CAB',
  COMPANY_CHANGE: 'Cambiar de empresa',
  COMPANY_CONTRACTED: 'Empresa Contratada',
  COMPANY_DESCRIPTION: 'Una empresa es una unidad económico-social, integrada por elementos humanos y materiales.',
  COMPANY_DETAIL: 'Registre su horario comercial por módulo',
  COMPANY_DOES_NOT_HAVE_SMTP_CONFIGURATION_SELECTED: 'La empresa no tiene seleccionada la configuración SMTP',
  COMPANY_FILTER_CONTRACT: 'Filtro de empresa y contrato',
  COMPANY_FILTER_DEVICE: 'Filtro de empresa y dispositivo',
  COMPANY_FILTER_STANDARDS: 'Filtro de empresas y normas',
  COMPANY_HOUR: 'Horario de apertura de la empresa',
  COMPANY_HOUR_DETAIL: 'Registrar el horario laboral de la empresa por módulos.',
  COMPANY_LIST: 'Lista de Empresas',
  COMPANY_LOGO: 'Logo de la empresa',
  COMPANY_OBJECTIVE: 'Objetivo de la empresa',
  COMPARATION: 'Comparación',
  COMPL_INFO: 'Información Complementaria',
  COMPLEMENT: 'Complemento',
  COMPLETE: 'Completa',
  COMPLETED: 'Terminado',
  COMPLETED_LATE: 'Retraso en la finalización',
  COMPLETED_PROJECT: 'Proyectos concluidos',
  COMPLETED_SPRINTS: 'Sprints terminados',
  COMPLETED_TICKETS: 'Boletos completados',
  COMPLETION_DATE: 'Fecha de finalización',
  COMPLEXITY: 'Complejidad',
  COMPLEXITY_AND_COST: 'Complejidad y costo',
  COMPONENT: 'Componentes',
  COMPUTER_NAME: 'Nombre de la computadora',
  CON: 'Concluído',
  CONCLUDE: 'Concluir',
  CONCLUDED: 'Concluído',
  CONCLUDED_CANCEL: 'Concluído Cancelado',
  CONCLUDED_DATE: 'Fecha de conclusión',
  CONCLUDED_FAIL: 'Completado con falta',
  CONCLUDED_PART: 'Concluído Parcialmente',
  CONCLUDED_SUCCESS: 'Concluído con éxito',
  CONCLUDES_THE_PREVIOUS_ACTIVITIES_TO_THIS: 'Completa las actividades que preceden a esta',
  CONCLUI: 'Concluido',
  CONDITION: 'Condición',
  CONFIDENTIALITY: 'Confidencialidad',
  CONFIG: 'Configuraciones',
  CONFIG_ALTERNATE_BOSS_BODY: 'Informar al jefe alternativo de los usuarios',
  CONFIG_ANSWERS_BODY: 'Cree respuestas automáticas y facilite el servicio de atención al cliente',
  CONFIG_AUTOMATION_BODY: 'Automatización de las demandas',
  CONFIG_CAB_EVENTS_BODY: 'Crear eventos para CAB',
  CONFIG_CATEGORIES_BODY: 'Dirija su catálogo de servicios',
  CONFIG_CHANGE_TEMPLATE_BODY: 'Crear plantillas para los cambios',
  CONFIG_CHANNELS_BODY: 'Gestión de canales',
  CONFIG_COIN_MANAGEMENT_BODY: 'Configurar monedas para usar en contratos',
  CONFIG_COMPANIES_BODY: 'Crear, editar y/o eliminar empresas',
  CONFIG_COMPANY_LOGO_BODY: 'Adjunte aquí el logo de su empresa',
  CONFIG_CONTRACT_BODY: 'Gestione aquí los contratos de su empresa',
  CONFIG_DEPARTMENT_BODY: 'Crear, editar y/o eliminar departamentos',
  CONFIG_DEVICE_BODY: 'Registre los dispositivos de la empresa',
  CONFIG_DEVICE_TYPE_BODY: 'Crear, editar y/o eliminar tipos de dispositivos',
  CONFIG_EMAIL_TEMPLATES_2_BODY: 'Registre aquí las plantillas de e-mail',
  CONFIG_EMAIL_TEMPLATES_BODY: 'Registre aquí las plantillas de e-mail',
  CONFIG_FILE_EXTENSIONS_BODY: 'Configurar las extensiones de archivos compatibles con la plataforma',
  CONFIG_FORMS_BODY: 'Cree formularios personalizados que se adapten a su empresa',
  CONFIG_GENERAL_PARAMETERS_BODY: 'Configurar los Parâmetros de acceso a la plataforma',
  CONFIG_GROUPS_BODY: 'Cree el o los grupos de servicio para su empresa',
  CONFIG_HELP_DESK_BODY: 'Configurar los tutoriales del Centro de Ayuda',
  CONFIG_LDAP_BODY: 'Ingrese su AD en la plataforma Sensr.IT',
  CONFIG_LOCATION_BODY: 'Crear, editar y/o eliminar ubicaciones',
  CONFIG_MAIL_POLICY_BODY: 'Configurar la comunicación por email para la plataforma',
  CONFIG_OPENING_HOURS_BODY: 'Parametrizar el inicio y el fin del servicio de la plataforma',
  CONFIG_OUTAGE_BODY: 'Registro de interrupciones',
  CONFIG_PANEL: 'Panel de Configuración',
  CONFIG_PARAM_ADMIN_ATTENDANCE: 'Contabilización de las asistencia de los administradores',
  CONFIG_PARAM_ADMIN_HISTORIC_REQUEST: 'El gestor sólo ve sus llamadas',
  CONFIG_PARAM_ALLOW_EDIT_FORM_CATEGORIZATION: 'Permitir la edición de formularios en la pantalla de categorización',
  CONFIG_PARAM_APPROVE_GROUP_USERS: 'Aprobar solicitud mediante grupo de autorizadores',
  CONFIG_PARAM_APPROVE_REQUEST_CHIEF: 'Permitir que el Gestor apruebe sus propias llamadas',
  CONFIG_PARAM_ASSOCIATE_REMOTE_ACCESS_TICKET: 'Asociar acceso remoto al ticket',
  CONFIG_PARAM_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: 'Los tickets asociados deben seguir el estado de la llamada principal',
  CONFIG_PARAM_AVALIATION_TICKETS: 'Avaluar NPS',
  CONFIG_PARAM_CALL_OPENING: 'Activar la integración con Microsoft',
  CONFIG_PARAM_CAUSE_PROBLEM: 'Activar la causa del problema',
  CONFIG_PARAM_CHANGE_GROUP_TECH_APPROVE: 'Asociación automática del Grupo Técnico como aprobador del Cambio',
  CONFIG_PARAM_COUNT_HOURS_TECH_CLIENT: 'Recuento de horas por técnicos tipo cliente',
  CONFIG_PARAM_COUNT_TICKET_BROKEN_ONCE: 'Contabilizar las llamadas salientes una sola vez',
  CONFIG_PARAM_EMAIL_RESPONSE_CHAT: 'Activar Chat/Respuesta por Email',
  CONFIG_PARAM_ENABLE_CHANGE_STATUS_REASON: 'Habilitar motivo en cambio de estado de Ticket',
  CONFIG_PARAM_ENABLE_CREATE_PROBLEM_WHEN_RESOLVE_CRISIS: 'Habilitar la creación de problemas al resolver una crisis',
  CONFIG_PARAM_ENABLE_DESCRIPTION_EDITING: 'Habilitar la edición de descripción',
  CONFIG_PARAM_ENABLE_LOGS_DEVICE_GMUD_TICKET: 'Activar la grabación de registros para Ticket y Cambio que contengan dispositivos vinculados',
  CONFIG_PARAM_ENABLE_MULTIPLE_SELECTION: 'Permitir la selección de todos los solicitantes y aprobadores en la creación de OS',
  CONFIG_PARAM_ENABLE_REQUIRED_TEMPLATE_GMUD: 'Activar la selección obligatoria de formularios en Cambio',
  CONFIG_PARAM_ENABLE_SERVICE_PORTAL_BY_COMPANIES: 'Habilitar portal de servicios por empresas',
  CONFIG_PARAM_EVALUATE_TICKET: 'Activar la evaluación de ticket',
  CONFIG_PARAM_GMUD_CLOSING_OF_GMUD: 'Apertura de Problema al cerrar Cambio con falta.',
  CONFIG_PARAM_GMUD_PARTIAL_TICKET_OPEN_CATEGORY: 'Categoría para apertura de llamada',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY: 'Categoría para apertura de llamada',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_SECOND: 'Sub-categoría para apertura de llamada',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_THIRD: 'Categoria de Tecer nivel para apertura de llamada',
  CONFIG_PARAM_GOOGLE_LOGIN: 'Activar la integración a través de Google',
  CONFIG_PARAM_HOURS_DAY: 'Número de horas de trabajo de 1 día para medir las actividades en días',
  CONFIG_PARAM_LINKING_MULTI_COMPANY_TICKETS: 'Enlaces entre varias empresas',
  CONFIG_PARAM_MICROSOFT_LOGIN: 'Activar la integración con Microsoft',
  CONFIG_PARAM_MULT_CATALOG: 'Activar varios catálogos',
  CONFIG_PARAM_OPEN_TICKET_WHEN_GMUD_FAILS: 'Abir Ticket de Problema quando GMUD finalizar com Falha',
  CONFIG_PARAM_READJUSTMENT_CONTRACT: 'Reajuste obligatorio del contrato',
  CONFIG_PARAM_REQUEST_BY_PORTAL: 'Solicitudes abiertas a través del portal',
  CONFIG_PARAM_REQUEST_CLOSED_TICKETS: 'Número de días para cerrar solicitudes con estado Aprobado',
  CONFIG_PARAM_REQUEST_SOLICITANT_SELECT: 'Seleccione al solicitante',
  CONFIG_PARAM_SEND_EMAIL_CATEGORIZATION: 'Enviar email al categorizar',
  CONFIG_PARAM_SEND_EMAIL_CREATE_TASK_IN_TICKET: 'Enviar email al crear tarea en ticket',
  CONFIG_PARAM_SEND_EMAIL_GROUP_TECH: 'Activar E-mail Cambiar grupo técnico',
  CONFIG_PARAM_SEND_MAIL_OBSERVERS: 'Activar observador(es) de E-mail',
  CONFIG_PARAM_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: 'Mostrar las solicitudes rechazadas en la pantalla de categorización',
  CONFIG_PARAM_SUBTICKET_CLOSED_VERIFY: 'Las llamadas se pueden finalizar independientemente de que haya sub-llamadas abiertas',
  CONFIG_PARAM_TECHNICAL_EVALUATION_REGISTRATION: 'Evaluación activa de llamadas de técnicos',
  CONFIG_PARAM_TICKET_CLOSE_DAYS: 'Número de días para cerrar automáticamente las llamadas',
  CONFIG_PARAM_TICKET_CLOSED_TASKS: 'Cerrar la llamada solo cuando se hayan completado todas las tareas',
  CONFIG_PARAM_TICKET_CONTRACT_SOLICITANT: 'Apertura de una solicitud de dispositivo global',
  CONFIG_PARAM_TICKET_PARTIAL_CLOSING: 'Apertura de Solicitud cuando Tarea cerrar con falta',
  CONFIG_PARAM_TICKET_SIMPLIFIED: 'Simplificación de la emisión de ticket',
  CONFIG_PARAM_TIME_REFRESH: 'Tiempo de actualización de la página de tickets en minutos',
  CONFIG_PARAM_TIME_REFRESH_VISAO: 'Tiempo de actualización de la página de vista de gestión en minutos',
  CONFIG_PARAM_TOOLIP_ENABLE_REQUIRED_TEMPLATE_GMUD: 'Activar la selección obligatoria de formularios en Cambio',
  CONFIG_PARAM_TOOLTIP_ADMIN_ATTENDANCE: 'Activando este parámetro, las horas de servicio realizadas por los Administradores se contabilizarán en el contrato.',
  CONFIG_PARAM_TOOLTIP_ADMIN_HISTORIC_REQUEST: 'Activando este parámetro el responsable de departamento sólo verá sus llamados',
  CONFIG_PARAM_TOOLTIP_ALLOW_EDIT_FORM_CATEGORIZATION: 'Activando este parámetro se pueden editar los formularios de la pantalla de categorización',
  CONFIG_PARAM_TOOLTIP_APPROVE_GROUP_USERS: 'Active este parámetro para aprobar solicitudes',
  CONFIG_PARAM_TOOLTIP_ASSOCIATE_REMOTE_ACCESS_TICKET: 'Al activar este parámetro, los accesos no se contabilizarán en el resumen ni en el informe técnico',
  CONFIG_PARAM_TOOLTIP_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: 'Al activar este parámetro, cuando uno o más tickets estén asociados a un ticket padre, no se cerrarán, pero cuando se actualice el estado del ticket padre, también se actualizará el estado del ticket o tickets hijos',
  CONFIG_PARAM_TOOLTIP_COUNT_HOURS_TECH_CLIENT: 'Activando este parámetro se contabilizarán las horas de los tickets contestados por técnicos de tipo cliente',
  CONFIG_PARAM_TOOLTIP_COUNT_TICKET_BROKEN_ONCE: 'Al activar este parámetro, las llamadas que se desborden se contabilizarán siempre en el mes en el que se desbordaron',
  CONFIG_PARAM_TOOLTIP_ENABLE_MULTIPLE_SELECTION: 'Permitir la selección de todos los solicitantes y aprobadores en la creación de OS',
  CONFIG_PARAM_TOOLTIP_EVALUATE_TICKET: 'Al activar este parámetro, el solicitante podrá evaluar la llamada.',
  CONFIG_PARAM_TOOLTIP_GMUD_LEADERS_APPROVERS: 'Al habilitar este parámetro, los patrocinadores de usuarios y los patrocinadores alternativos de usuarios pueden aprobar los flujos de trabajo de GMUD.',
  CONFIG_PARAM_TOOLTIP_GMUD_WORKFLOW: 'Al activar este parámetro permite crear un flujo de aprobadores para una GMUD basado en condiciones establecidas.',
  CONFIG_PARAM_TOOLTIP_LINKING_MULTI_COMPANY_TICKETS: 'Activando este parámetro, será posible enlazar llamadas entre varias empresas.',
  CONFIG_PARAM_TOOLTIP_REQUEST_BY_PORTAL: 'Activando este parámetro la apertura de solicitudes desde la pantalla Mi Experiencia se realizará a través del nuevo portal de autoservicio',
  CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CATEGORIZATION: 'Activando este parámetro se enviará un email al categorizar una llamada.',
  CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CREATE_TASK_IN_TICKET: 'Activando este parámetro cada vez que se cree una tarea dentro de un ticket, el grupo técnico responsable recibirá un correo electrónico informándole',
  CONFIG_PARAM_TOOLTIP_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: 'Activando este parámetro se mostrarán en la pantalla de categorización las solicitudes anuladas',
  CONFIG_PARAM_TOOLTIP_SUBTICKET_CLOSED_VERIFY: 'Las llamadas se pueden finalizar independientemente de que haya sub-llamadas abiertas',
  CONFIG_PARAM_TOOLTIP_TECHNICAL_EVALUATION_REGISTRATION: 'Activando este parámetro al final del ticket en la pantalla de Gestión de Tickets el Técnico puede poner evaluaciones registradas',
  CONFIG_PARAM_VISION_REQUEST: 'Número de días a presentar en la vista de gestión',
  CONFIG_PASSWORD_VAULT_BODY: 'Organiza tus contraseñas',
  CONFIG_POSITIONS_BODY: 'Crear, editar y/o eliminar trabajos',
  CONFIG_PROFILE_BODY: 'Edite su perfil, cambie sus credenciales siempre que sea necesario',
  CONFIG_PROGRESS_STATUS_BODY: 'Cree sus estados para el progreso de los tickets',
  CONFIG_PROJECT_TEMPLATE_BODY: 'Registrar una plantilla para utilizar en el proyecto',
  CONFIG_RISK_CHANGE_BODY: 'Crear preguntas para evaluar los riesgos del cambio',
  CONFIG_SEND_MAIL: 'Configuración de envío de correo electrónico',
  CONFIG_SLA_PARAMS_BODY: 'Registro del sla',
  CONFIG_SLA_POLICY_BODY: 'Registre aquí el SLA del servicio contrado',
  CONFIG_SLA_WORKDAY_BODY: 'Horas trabajadas',
  CONFIG_SNMP_BODY: 'Registrar los dispositivos mediante protocolo de red',
  CONFIG_SPRINT_ACTIVITY_EFFORT_BODY: 'Configurar los esfuerzos de las actividades de un Sprint',
  CONFIG_SQUAD_BODY: 'Crea, edita o elimina Squads',
  CONFIG_SUBCATEGORIES_BODY: 'Organizar en grupos las actividades para la asistencia',
  CONFIG_SUBDEPARTMENT_BODY: 'Crear, editar y/o eliminar subdepartamentos',
  CONFIG_THIRD_LEVEL_CATEGORY_BODY: 'Registrar todas las actividades para la asistencia',
  CONFIG_USERS_BODY: 'Crear, editar y/u eliminar usarios en la  plataforma',
  CONFIG_USERS_PERMISSIONS: 'Crear, editar y/o eliminar permisos de usuario en la plataforma',
  CONFIGURATION_MODULE: 'Módulo de Configuración',
  CONFIGURE_COLUMNS: 'Configurar columnas',
  CONFIGURE_MULTIPLE_TICKETS: 'Configurar Llamadas Múltiples',
  CONFIGURE_RELATIONSHIP_TREE: 'Configurar árbol de relaciones',
  CONFIGURE_RESOLUTION_CATEGORY: 'Configurar categorías de resolución',
  CONFIGURE_RESOLUTION_CODE: 'Configurar Codigos de resolución',
  CONFIRM: 'Confirmar',
  CONFIRM_APPROVAL: 'Confirmar aprobación',
  CONFIRM_CHANGE: '¿Desea abrir el cambio?',
  CONFIRM_CLOSE_ACESS_SUPPORT: '¿Desea cerrera el acceso al soporte remoto?',
  CONFIRM_CLOSE_ASSESSMENT: '¿Estás seguro que deseas terminar este servicio?',
  CONFIRM_CRISES: '¿Crisis confirmada?',
  CONFIRM_DELETE: '¿Realmente desea eliminar este ítem?',
  CONFIRM_DELETE_BODY: 'Una vez que elimine este ítem, ya no podrá recuperarlo, asegúrece de que desea eliminarlo antes de continuar.',
  CONFIRM_DELETE_BUSINESS_AREA: '¿Desea excluir un área de negocio?',
  CONFIRM_DELETE_COMPANY: 'Atención: Al borrar la EMPRESA, se borrarán todos los elementos relacionados con ella, ¿realmente quieres borrarla?',
  CONFIRM_DELETE_ITEM: 'Desea eliminar este ítem',
  CONFIRM_DETACH: '¿Está seguro que desea eliminar este archivo adjunto?',
  CONFIRM_DIALOG: '¿Estás seguro que deseas guardar ?',
  CONFIRM_DISABLE: '¿Realmente deseas desactivar este elemento?',
  CONFIRM_ENABLE: '¿Realmente desea habilitar este elemento?',
  CONFIRM_PASSWORD: 'Confirmar nueva contraseña',
  CONFIRM_SAVE_ITEM: '¿Desea guardar este ítem?',
  CONFIRM_START_SERVICE: '¿Desea iniciar este servicio?',
  CONFIRM_STOP_MONITOR: '¿Estás seguro que quieres dejar de monitorear este dispositivo?',
  CONFIRM_TICKETS_ASSESSMENT: 'Selecione a continuación los tickets finalizados en este servicio',
  CONFIRM_UPDATE_AGENT: '¿Está seguro que desea actualizar el agente de este dispositivo?',
  CONFIRMATION: 'Confirmación',
  CONNECT: 'Conectar',
  CONNECTION: 'Conexión',
  CONSULT_APPROVALS: 'Consultar Homologaciones',
  CONSULT_HISTORIC: 'Consultar el historial',
  CONSULT_INTEGRATION_CALL: 'Consultar Integración',
  CONSULTANT: 'Consultor',
  CONSULTED: 'Consultado',
  CONSUMED: 'Consumido',
  CONSUMED_HOURS: 'Horas consumidas',
  CONSUMED_TIME: 'Tiempo consumido',
  CONSUMED_VALUE: 'Valor consumido',
  CONSUMED_VALUES: 'Valores consumidos',
  CONSUMER_SERVICE_SYSTEM: 'Sistema de Atención al Consumidor',
  CONSUMPTION_OF_FORECAST_REALIZED_AND_CONSUMED: 'Consumo de previsto, realizado y consumido',
  CONTACT: 'Contacto',
  CONTACTS: 'Contactos',
  CONTAIN: 'Contiene',
  CONTEST: 'Concurso',
  CONTINUE_PROJECT: 'Continuar Proyecto',
  CONTRACT: 'Contrato',
  CONTRACT_CONSUMPTION: 'Consumo contractual',
  CONTRACT_DESCRIPTION: 'Gestión eficiente de contratos que le permite controlar y supervisar las horas de terceros.',
  CONTRACT_DETAILS: 'Detalles del Contrato',
  CONTRACT_FILTER: 'Filtro de contrato',
  CONTRACT_INVOLVED: 'Contrato involucrado',
  CONTRACT_LIST: 'Lista de Contratos',
  CONTRACT_MANAGEMENT: 'Gestión de Contratos',
  CONTRACT_OVERVIEW: 'Resumen del Contrato',
  CONTRACT_SUPORT: 'Soporte del contrato',
  CONTRACT_TITLE: 'Registro de Contrato',
  CONTRACT_TYPE: 'Tipos de Contrato',
  CONTRACT_TYPE_LIST: 'Lista de tipos de contrato',
  CONTRACT_VALUE: 'Valor del Contrato',
  CONTRACTED: 'Contratista',
  CONTRACTED_HOUR: 'Valor Hora Contratada',
  CONTRACTED_HOURS: 'Horas Contratadas',
  CONTRACTOR: 'Contratista',
  CONTRACTS: 'Contratos',
  CONTRACTS_FILTER: 'Filtrar Por Contratos',
  CONTRACTS_MANAGEMENT: 'Gestión de Contratos',
  CONTRACTUAL_BILLING: 'Facturación por contrato',
  CONTRIBUTION_TO_BUSINESS: 'Contribución a la empresa',
  CONTROL: 'Control',
  CORE: 'COLORES',
  CORRECTION: 'Corrección',
  CORRECTIVE: 'Corrección',
  CORRELATION: 'Correlación',
  CORRELATION_DISPLAY: 'Pantalla de correlación',
  COST: 'Gasto',
  COST_CENTER: 'Centro de Costo',
  COST_DESCRIPTION: 'Descripción de costos',
  COST_PER_HOUR: 'Costo por hora',
  COST_PER_PROJECT: 'Costo por proyecto',
  COST_TITLE: 'Título del costo',
  COST_VALUE: 'Valor del costo',
  COUNT: 'Contém',
  COUNTRIES: 'Países',
  COUNTRY: 'País',
  COUNTRY_AFGHANISTAN: "Afganistán",
  COUNTRY_ALBANIA: "Albania",
  COUNTRY_ALGERIA: "Argelia",
  COUNTRY_AMERICAN_SAMOA: "Samoa Americana",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguila",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antigua y Barbuda",
  COUNTRY_ARGENTINA: "Argentina",
  COUNTRY_ARMENIA: "Armenia",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_ASCENSION_ISLAND: "Isla de Ascensión",
  COUNTRY_AUSTRALIA: "Australia",
  COUNTRY_AUSTRIA: "Austria",
  COUNTRY_AZERBAIJAN: "Azerbaiyán",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Baréin",
  COUNTRY_BANGLADESH: "Bangladés",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Bielorrusia",
  COUNTRY_BELGIUM: "Bélgica",
  COUNTRY_BELIZE: "Belice",
  COUNTRY_BENIN: "Benín",
  COUNTRY_BERMUDA: "Bermudas",
  COUNTRY_BHUTAN: "Bután",
  COUNTRY_BOLIVIA: "Bolivia",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bosnia y Herzegovina",
  COUNTRY_BOTSWANA: "Botsuana",
  COUNTRY_BRAZIL: "Brasil",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: "Territorio Británico del Océano Índico",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "Islas Vírgenes Británicas",
  COUNTRY_BRUNEI: "Brunéi",
  COUNTRY_BULGARIA: "Bulgaria",
  COUNTRY_BURKINA_FASO: "Burkina Faso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_CAMBODIA: "Camboya",
  COUNTRY_CAMEROON: "Camerún",
  COUNTRY_CANADA: "Canadá",
  COUNTRY_CAPE_VERDE: "Cabo Verde",
  COUNTRY_CAYMAN_ISLANDS: "Islas Caimán",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "República Centroafricana",
  COUNTRY_CHAD: "Chad",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CHRISTMAS_ISLAND: "Islas Christmas",
  COUNTRY_COCOS_ISLANDS: "Islas Cocos (Keeling)",
  COUNTRY_CODE: 'Código del país',
  COUNTRY_COLOMBIA: "Colombia",
  COUNTRY_COMOROS: "Comoras",
  COUNTRY_CONGO: "Congo",
  COUNTRY_COOK_ISLANDS: "Islas Cook",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Croacia",
  COUNTRY_CUBA: "Cuba",
  COUNTRY_CYPRUS: "Chipre",
  COUNTRY_CZECH_REPUBLIC: "República Checa",
  COUNTRY_DENMARK: "Dinamarca",
  COUNTRY_DJIBOUTI: "Yibuti",
  COUNTRY_DOMINICA: "Dominica",
  COUNTRY_DOMINICAN_REPUBLIC: "República Dominicana",
  COUNTRY_ECUADOR: "Ecuador",
  COUNTRY_EGYPT: "Egipto",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_EQUATORIAL_GUINEA: "Guinea Ecuatorial",
  COUNTRY_ERITREA: "Eritrea",
  COUNTRY_ESTONIA: "Estonia",
  COUNTRY_ESWATINI: "Esuatini",
  COUNTRY_ETHIOPIA: "Etiopía",
  COUNTRY_FALKLAND_ISLANDS: "Islas Malvinas",
  COUNTRY_FAROE_ISLANDS: "Islas Feroe",
  COUNTRY_FIJI: "Fiyi",
  COUNTRY_FINLAND: "Finlandia",
  COUNTRY_FRANCE: "Francia",
  COUNTRY_FRENCH_GUIANA: "Guayana Francesa",
  COUNTRY_FRENCH_POLYNESIA: "Polinesia Francesa",
  COUNTRY_GABON: "Gabón",
  COUNTRY_GAMBIA: "Gambia",
  COUNTRY_GAZA_STRIP: "Franja de Gaza",
  COUNTRY_GEORGIA: "Georgia",
  COUNTRY_GERMANY: "Alemania",
  COUNTRY_GHANA: "Ghana",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_GREECE: "Grecia",
  COUNTRY_GREENLAND: "Groenlandia",
  COUNTRY_GRENADA: "Granada",
  COUNTRY_GUADELOUPE: "Guadalupe",
  COUNTRY_GUAM: "Guam",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUINEA: "Guinea",
  COUNTRY_GUINEA_BISSAU: "Guinea-Bisáu",
  COUNTRY_GUYANA: "Guyana",
  COUNTRY_HAITI: "Haití",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG_SAR: "Hong Kong SAR",
  COUNTRY_HUNGARY: "Hungría",
  COUNTRY_ICELAND: "Islandia",
  COUNTRY_INDIA: "India",
  COUNTRY_INDONESIA: "Indonesia",
  COUNTRY_IRAN: "Irán",
  COUNTRY_IRAQ: "Irak",
  COUNTRY_IRELAND: "Irlanda",
  COUNTRY_ISLE_OF_MAN: "Isla de Man",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Italia",
  COUNTRY_IVORY_COAST: "Costa de Marfil",
  COUNTRY_JAMAICA: "Jamaica",
  COUNTRY_JAPAN: "Japón",
  COUNTRY_JARVIS_ISLAND: "Isla Jarvis",
  COUNTRY_JORDAN: "Jordania",
  COUNTRY_KAZAKHSTAN: "Kazajistán",
  COUNTRY_KENYA: "Kenia",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KUWAIT: "Kuwait",
  COUNTRY_KYRGYZSTAN: "Kirguistán",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LATVIA: "Letonia",
  COUNTRY_LEBANON: "Líbano",
  COUNTRY_LESOTHO: "Lesoto",
  COUNTRY_LIBERIA: "Liberia",
  COUNTRY_LIBYA: "Libia",
  COUNTRY_LIECHTENSTEIN: "Liechtenstein",
  COUNTRY_LITHUANIA: "Lituania",
  COUNTRY_LUXEMBOURG: "Luxemburgo",
  COUNTRY_MACAU: "Macao",
  COUNTRY_MADAGASCAR: "Madagascar",
  COUNTRY_MALAWI: "Malaui",
  COUNTRY_MALAYSIA: "Malasia",
  COUNTRY_MALDIVES: "Maldivas",
  COUNTRY_MALI: "Malí",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MARSHALL_ISLANDS: "Islas Marshall",
  COUNTRY_MARTINIQUE: "Martinica",
  COUNTRY_MAURITANIA: "Mauritania",
  COUNTRY_MAURITIUS: "Mauricio",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "México",
  COUNTRY_MICRONESIA: "Micronesia",
  COUNTRY_MONACO: "Mónaco",
  COUNTRY_MONGOLIA: "Mongolia",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MOROCCO: "Marruecos",
  COUNTRY_MOZAMBIQUE: "Mozambique",
  COUNTRY_MYANMAR: "Birmania",
  COUNTRY_MYANMAR: "Myanmar",
  COUNTRY_NAMIBIA: "Namibia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NETHERLANDS: "Países Bajos",
  COUNTRY_NETHERLANDS_ANTILLES: "Antillas Neerlandesas",
  COUNTRY_NEW_CALEDONIA: "Nueva Caledonia",
  COUNTRY_NEW_ZEALAND: "Nueva Zelanda",
  COUNTRY_NICARAGUA: "Nicaragua",
  COUNTRY_NIGER: "Níger",
  COUNTRY_NIGERIA: "Nigeria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORFOLK_ISLAND: "Isla Norfolk",
  COUNTRY_NORTH_MACEDONIA: "Macedonia del Norte",
  COUNTRY_NORTHERN_CYPRUS: "República Turca de Chipre del Norte",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Islas Marianas del Norte",
  COUNTRY_NORWAY: "Noruega",
  COUNTRY_OMAN: "Omán",
  COUNTRY_PAKISTAN: "Pakistán",
  COUNTRY_PALAU: "Palaos",
  COUNTRY_PANAMA: "Panamá",
  COUNTRY_PAPUA_NEW_GUINEA: "Papúa Nueva Guinea",
  COUNTRY_PARAGUAY: "Paraguay",
  COUNTRY_PERU: "Perú",
  COUNTRY_PHILIPPINES: "Filipinas",
  COUNTRY_PITCAIRN_ISLAND: "Isla Pitcairn",
  COUNTRY_POLAND: "Polonia",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_PUERTO_RICO: "Puerto Rico",
  COUNTRY_QATAR: "Catar",
  COUNTRY_REUNION: "Reunión",
  COUNTRY_ROMANIA: "Rumanía",
  COUNTRY_RUSSIA: "Rusia",
  COUNTRY_RWANDA: "Ruanda",
  COUNTRY_SAINT_HELENA: "Santa Elena",
  COUNTRY_SAINT_KITTS_AND_NEVIS: "San Cristóbal y Nieves",
  COUNTRY_SAINT_LUCIA: "Santa Lucía",
  COUNTRY_SAINT_PIERRE_AND_MIQUELON: "San Pedro y Miquelón",
  COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: "San Vicente y las Granadinas",
  COUNTRY_SAMOA: "Samoa",
  COUNTRY_SAN_MARINO: "San Marino",
  COUNTRY_SAO_TOME_AND_PRINCIPE: "Santo Tomé y Príncipe",
  COUNTRY_SAUDI_ARABIA: "Arabia Saudita",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SERBIA: "Serbia",
  COUNTRY_SERBIA_AND_MONTENEGRO: "Serbia y Montenegro",
  COUNTRY_SEYCHELLES: "Seychelles",
  COUNTRY_SIERRA_LEONE: "Sierra Leona",
  COUNTRY_SINGAPORE: "Singapur",
  COUNTRY_SLOVAKIA: "Eslovaquia",
  COUNTRY_SLOVENIA: "Eslovenia",
  COUNTRY_SOLOMON_ISLANDS: "Islas Salomón",
  COUNTRY_SOMALIA: "Somalia",
  COUNTRY_SOUTH_AFRICA: "Sudáfrica",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS: "Islas Georgias del Sur y Sandwich del Sur",
  COUNTRY_SOUTH_KOREA: "Corea del Sur",
  COUNTRY_SOUTH_SANDWICH_ISLANDS: "Islas Sandwich del Sur",
  COUNTRY_SPAIN: "España",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SUDAN: "Sudán",
  COUNTRY_SURINAME: "Surinam",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Islas Svalbard y Jan Mayen",
  COUNTRY_SWEDEN: "Suecia",
  COUNTRY_SWITZERLAND: "Suiza",
  COUNTRY_SYRIA: "Siria",
  COUNTRY_TAIWAN: "Taiwán",
  COUNTRY_TAJIKISTAN: "Tayikistán",
  COUNTRY_TANZANIA: "Tanzania",
  COUNTRY_THAILAND: "Tailandia",
  COUNTRY_TIMOR_LESTE: "Timor Oriental",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TOKELAU: "Tokelau",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad y Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristán de Acuña",
  COUNTRY_TUNISIA: "Túnez",
  COUNTRY_TURKEY: "Turquía",
  COUNTRY_TURKMENISTAN: "Turkmenistán",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Islas Turcas y Caicos",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_UKRAINE: "Ucrania",
  COUNTRY_UNITED_ARAB_EMIRATES: "Emiratos Árabes Unidos",
  COUNTRY_UNITED_KINGDOM: "Reino Unido",
  COUNTRY_UNITED_STATES: "Estados Unidos",
  COUNTRY_URUGUAY: "Uruguay",
  COUNTRY_US_VIRGIN_ISLANDS: "Islas Vírgenes Americanas",
  COUNTRY_UZBEKISTAN: "Uzbekistán",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Santa Sede",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnam",
  COUNTRY_WALLIS_AND_FUTUNA: "Wallis y Futuna",
  COUNTRY_WEST_BANK: "Cisjordania",
  COUNTRY_WESTERN_SAHARA: "Sáhara Occidental",
  COUNTRY_YEMEN: "Yemen",
  COUNTRY_YUGOSLAVIA: "Yugoslavia",
  COUNTRY_ZAIRE: "Zaire",
  COUNTRY_ZAMBIA: "Zambia",
  COUNTRY_ZIMBABWE: "Zimbabue",
  CPF: 'CPF',
  CPF_CNPJ_ALREADY_EXISTIS: 'CPF/CNPJ ya existe en la base de datos',
  CPU: 'CPU',
  CPU_CAPTION: 'CPU caption',
  CPU_USAGE: 'Uso de la CPU',
  CREATE: 'Creado',
  CREATE_ATTENDANCE: 'Crear Servicio',
  CREATE_EDIT_AND_OR_EXCLUDE_RELATIONSHIP_TYPES: 'Crear, editar y/o excluir tipos de relación',
  CREATE_OR_ADD_TAGS_ACTIVITY: 'Crear o agregar etiquetas a esta actividad',
  CREATE_OR_ADD_THIS_ACTIVITY_TO_AN_ACTIVITY_GROUP: 'Crear o agregar esta actividad a un grupo de actividades',
  CREATE_PRIORITY_CUSTOM_GLOBAL_SETTING: 'crear una configuración global personalizada de prioridad',
  CREATE_SUB_CALL: 'Crear sub-llamado',
  CREATE_TAG: 'Crear Tag',
  CREATE_TASK_FLOW: 'Crear Flujo de Tareas',
  CREATE_TEMPLATE_BASED_GMUD: '¿Crear Cambio basado en plantilla?',
  CREATE_WORKFLOW: 'Crear Flujo de Trabajo',
  CREATE_YOUR_OWN_MASTER_PASSWORD: 'Cree su contraseña maestra',
  CREATED: 'Creado',
  CREATED_IN: 'Creado en',
  CREATOR: 'Creador',
  CRISIS_GROUP: 'Grupo de crisis',
  CRISIS_IS_CONFIRMED: 'Se confirma la crisis',
  CRISIS_IS_UNCONFIRMED: 'La crisis no está confirmada',
  CRISIS_PLURAL: 'Crisis',
  CRITICAL: 'Crítico',
  CRITICAL_UNIT: 'Unidad critica',
  CRITICALITY: 'Criticidad',
  CURRENCY: 'R$',
  CURRENT: 'Actual',
  CURRENT_MATURITY: 'Vencimiento actual',
  CURRENT_MONTH_AVAILABILITY: 'Disponibilidad del mes actual',
  CUST: 'Gasto',
  CUSTOMER_DATA: 'Datos del Cliente',
  CUSTOMIZE_PRIORITY: 'Prioridad Personalizada',
  CUSTOMIZED: 'Personalizado',
  DAILY: 'A diario',
  DANGER: 'Peligro',
  DANGEROUS: 'Peligroso',
  DASH: {
    SLA_BURST_CLOSED: 'SLA fuera de fecha'
  },
  DASHBOARD: 'Panel',
  DASHBOARD_G: 'Visión Gerencial',
  DASHBOARD_PAGE: {
    ATTENDANCE: 'Gestión de servicio',
    DM: 'Gestión de la Demanda',
    GCF: 'Gestión de Contratos y Proveedores',
    GI: 'Gestión de Infraestructura',
    GSD: 'Gestión de Service Desk',
    SUB_TITLE: 'Panel de Información',
    TITLE: 'Título'
  },
  DATA_LOG: 'Registro de fecha',
  DATA_SENT_SUCCESSFULLY: 'Datos enviados correctamente',
  DATABASE_SUPPORT: 'Soporte de Base de Datos',
  DATAMAT: {
    mat: ['', 'Inicial / Ad Hoc', ' Repetible, pero intuitivo', 'Proceso Definido', 'Gestionado y Medido', 'Optimizado']
  },
  DATE: 'Fecha',
  DATE_ATTENDANCE: 'Data atendimento',
  DATE_CAB: 'Fecha CAB',
  DATE_CAD: 'Fecha de Registro',
  DATE_CLOSED_RELATED: 'Fecha de finalización del registro correspondiente',
  DATE_DATE_FINAL: 'Fecha Fecha - Final',
  DATE_DATE_INITIAL: 'Fecha Fecha - Inicial',
  DATE_END_RELATED_GMUD: 'Fecha final de ejecución del cambio',
  DATE_FOR_THE_ACTIVITY: 'Fecha para la actividad',
  DATE_INITIAL_STEP: 'Fecha Ini. Etapa',
  DATE_LANG: {
    dayAbbreviation: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    dayFullList: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayList: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
    monthList: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthLongList: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  },
  DATE_LAST_CHANGE: 'Última Fecha de Modificación',
  DATE_NORMALIZATION: 'Fecha de normalización',
  DATE_OPTIONS: 'Opciones de Fecha',
  DATE_OUTSIDE_PROJECT_PERIOD: 'Fecha fuera del período del proyecto',
  DATE_REGISTER: 'Registro de fecha',
  DATE_REMOVED: 'Fecha de eliminación',
  DATE_START_PRODUCTION: 'Fecha para Activar en Producción',
  DATE_START_RELATED: 'Fecha de inscripción del registro relacionado',
  DATE_START_RELATED_GMUD: 'Fecha inicial de ejecución del cambio',
  DATE_TARGET: 'Fecha objetivo',
  DATE_TIME: 'Fecha y Hora',
  DATE_WITH: 'Por Fecha de',
  DAY: 'Día',
  DAYS: 'días',
  DAYS_AUTO_CLOSE: 'Días para cierre automático',
  DAYS_IN_MONTH: 'Días en el mes',
  DC: 'DC',
  DEADLINE: 'Plazo final',
  DECIMAL_PLACES: 'Casas decimales',
  DECREASING: 'En Disminución',
  DEFAULT: 'Estándar',
  DEFAULT_CHANNEL_TICKETS: 'Canal predeterminado en tickets',
  DEFAULT_COMPANY_CRISIS: 'Empresa estándar en crisis',
  DEFAULT_COMPANY_PROJETS: 'Morosidad de la empresa en los proyectos',
  DEFAULT_GROUP_FALE: 'Grupo predeterminado HABLAR',
  DEFINE_LIKE: 'Definir como',
  DELAYED: 'Retraso',
  DELETE: 'Eliminar',
  DELETE_ITEM: 'Excluir ítem',
  DELETE_ONE_OR_MORE_ITEM: 'Eliminar uno o más ítems',
  DELETE_PERSPECTIVE: 'Eliminar Perspectiva',
  DELETE2: 'Borrar',
  DELIVERY_DATE: 'Fecha de entrega',
  DELIVERY_MANAGER: 'Gerente de Entregas',
  DEMAND_MANAGEMENT: 'Gestión de Demanda',
  DEMANDS: 'Demandas',
  DEPARTMENT: 'Departamento',
  DEPARTMENT_BOSS: 'Jefe de Departamento',
  DEPARTMENT_DESCRIPTION: 'Departamento se refiere a una división o segmento distinto de la empresa.',
  DEPARTMENT_INVOLVED: 'Departamentos implicados',
  DEPARTMENT_LIST: 'Lista de Departamentos',
  DEPARTMENT_NAME: 'Nombre del departamento',
  DEPARTMENT_STATUS_CHART: 'Gráfico de estado por departamentos',
  DEPARTMENT_TABLE: 'Tabla del departamento',
  DEPARTMENTS: 'Departamentos',
  DEPARTMENTS_SUBDEPARTMENTS: 'Departamentos/Subdepartamentos',
  DEPARTURE_DATE: 'Fecha de salida',
  DESCRIBE_ANALYZE_PROBLEM: 'Análisis: Describa el análisis realizado',
  DESCRIBE_THE_GOALS_OF_THIS_PROJECT: 'Describa los objetivos de este proyecto',
  DESCRIBE_THE_RISKS_INVOLVED_IN_THIS_PROJECT: 'Describa los riesgos involucrados en este proyecto.',
  DESCRIBE_THE_RISKS_THAT_SURROUND_THIS_PROJECT: 'Describa los riesgos que rodean este proyecto',
  DESCRIPTION: 'Descripción',
  DESCRIPTION_CANNOT_BE_BLANK: 'La descripción no puede estar en blanco',
  DESCRIPTION_OF_CONTRACT: 'Descripción del contrato',
  DESIGNATED_ANALYST: 'Análista Designado',
  DESIGNATION_HISTORY: 'Historial de designaciones',
  DESIRED_MATURITY: 'Vencimiento deseado',
  DESKTOP: 'Escriba a',
  DETAILED_VIEW: 'Vista Detallada',
  DETAILS: 'Detalles',
  DETAILS_OF_THE_CALLS_MADE: 'Detalles de los servicios prestados',
  DETRACTORS: 'Detractores',
  DEVELOP: 'Desarrollo',
  DEVELOPMENT: 'Desarrollo',
  DEVICE: 'Dispositivo',
  DEVICE_ASSOCIATED_WITH_ANOTHER_GMUD: 'Dispositivo asociado a otro cambio',
  DEVICE_ATTACH: 'Dispositivo de enlace',
  DEVICE_ATTACH_WARNING: 'Todos los dispositivos disponíbles ya están vinculados a este contrato',
  DEVICE_CONF: 'Items de Configuración',
  DEVICE_CONFIG: 'Configuración de Dispositivo ',
  DEVICE_CONFIGURATION: 'Configuración del dispositivo',
  DEVICE_DESCRIPTION: 'Un conjunto de condiciones que debe cumplir un ticket para que se aplique la política de SLA.',
  DEVICE_IC: 'Dispositivo IC',
  DEVICE_INFO: 'Información del dispositivo',
  DEVICE_LIST: 'Lista de Dispositivos',
  DEVICE_MAP: 'Mapa de dispositivos',
  DEVICE_MONITORING: 'Supervisión de dispositivos',
  DEVICE_NAME: 'Nombre del dispositivo',
  DEVICE_STATUS: 'Estado de los Dispositivos',
  DEVICE_TABLE: 'Tabla de dispositivos',
  DEVICE_TITLE: 'Registro de Dispositivos',
  DEVICE_TYPE: 'Tipo de Dispositivo',
  DEVICE_TYPE_DESCRIPTION: 'Registro de la Lista de Dispositivos',
  DEVICE_TYPE_IC: 'Tipo de Dispositivo IC',
  DEVICES: 'Dispositivos',
  DEVICES_GROUP: 'Grupo de Dispositivos',
  DEVICES_GROUP_DETAIL: 'Detalles del Grupo de Dispositivos',
  DEVICES_SERVERS_IMPACTED: 'Dispositivos (servidores) afectados',
  DIFFERENCE_BETWEEN_EXPECTED_AND_CONSUMED: 'Diferencia entre esperado y consumido',
  DIFFERENT_FROM: 'Diferente de',
  DIRECT_LEADER: 'Líder Directo',
  DIRECTOR: 'Director',
  DISABLE: 'Deshabilitar',
  DISABLE_AUTOMATIC_LOADING: 'Deshabilitar la carga automática',
  DISABLE_FIELDS: 'Deshabilitar campos',
  DISABLE_TEMPLATE_LIST: 'Desactivar en la lista de plantillas',
  DISABLED: 'Desactivado',
  DISCARD: 'Descartar',
  DISCHECK_ALL: 'Desmarcar Todos',
  DISCOVERY: 'Descubierto',
  DISK: 'Discos',
  DISK_HEADER: 'Encabezado del disco',
  DISK_SECTOR: 'Sector del disco',
  DISK_TRACKS: 'Pistas del discos',
  DISK_USAGE: {
    boot: 'Uso del Disco /boot',
    home: 'Uso del Disco /home',
    repo: 'Uso del Disco /repo',
    root: 'Uso del Disco /',
    tmp: 'Uso del Disco /tmp',
    tools: 'Uso del Disco /tools',
    u01: 'Uso del Disco /u01',
    usr: 'Uso del Disco /usr',
    var: 'Uso del Disco /var',
    varLog: 'Uso del Disco /var/log'
  },
  DISTRIBUTE_TICKETS_AUTOMATICALLY: 'Distribuir llamadas automáticamente',
  DISTRICT: 'Estado',
  DISTRO: 'Distribución',
  DIVISION: 'División',
  DIVISIONS: 'Divisiones',
  DO_YOU_REALLY_WANT_TO_APPROVE_DOCUMENTATION: '¿Realmente quieres Aprobar Documentación?',
  DO_YOU_REALLY_WANT_TO_APPROVE_MATURITY: '¿Realmente quieres Aprobar Vencimiento?',
  DO_YOU_REALLY_WANT_TO_DELETE_THE_SPRINT: '¿Realmente desea eliminar el SPRINT?',
  DO_YOU_REALLY_WANT_TO_DELETE_THIS_FAVORITE: '¿Realmente desea eliminar este marcador?',
  DO_YOU_WANT_REMOVE_THIS_ITEM: '¿Desea eliminar este ítem?',
  DO_YOU_WANT_TO_CHANGE_THE_LANGUAGE: '¿Quieres cambiar el idioma?',
  DO_YOU_WANT_TO_CHANGE_YOUR_TIMEZONE: '¿Quieres cambiar tu zona horaria?',
  DO_YOU_WANT_TO_CREATE_THE_PROJECT_FROM_A_TEMPLATE: '¿Quieres crear el proyecto a partir de una Plantilla?',
  DO_YOU_WANT_TO_PUT_ACTIVITY_IN_PROGRESS: '¿Quieres poner la actividad en curso?',
  DO_YOU_WANT_TO_REMOVE_ACTIVITY: '¿Quieres eliminar la actividad?',
  DO_YOU_WANT_TO_REMOVE_SUB_ACTIVITY: '¿Quieres eliminar la sub-actividad?',
  DO_YOU_WANT_TO_REMOVE_THE_EXPECTED_RESULT: '¿Quieres eliminar el resultado esperado?',
  DO_YOU_WANT_TO_REMOVE_THIS_COST: '¿DESEA ELIMINAR ESTE COSTO?',
  DOC: 'Documento',
  DOCS: 'Documentos',
  DOING: 'Haciendo',
  DOMAIN: 'Dominio',
  DONT_COUNT: 'Não contem',
  DOWNLOAD: 'Descargar',
  DOWNLOAD_ATTACHMENT: 'Descargar archivo adjunto',
  DOWNLOAD_CSV: 'Descargar CSV',
  DOWNLOAD_JPEG: 'Descargar imagen JPEG',
  DOWNLOAD_MIDI: 'Descargar MIDI',
  DOWNLOAD_PDF: 'Descargar documento PDF',
  DOWNLOAD_PNG: 'Descargar imagen PNG',
  DOWNLOAD_READY: 'Descarga realizada con éxito!',
  DOWNLOAD_SVG: 'Descargar imagen SVG',
  DOWNLOAD_XLS: 'Descargar XLS',
  DOWNTIME: 'Tiempo real de indisponibilidad',
  DRAG_AND_DROP_AN_IMAGE_FILE_HERE_OR_CLICK: 'Arrastre y suelte un archivo de imagen aquí o haga clic en',
  DRAG_THE_FILE: 'Haga Click en el Icono para Adjuntar',
  DT_CAD: 'Fecha de apertura',
  DUPLICATE: 'Duplicar',
  DUPLICATE_FIELDS_WORKFLOW: 'No se pueden repetir niveles.',
  ECONOMY: 'Economía',
  EDIT: 'Editar',
  EDIT_ASSIGNMENT: 'Editar Tareas Añadidas',
  EDIT_BLACKOUT: 'Editar Blackout',
  EDIT_BUILD: 'Editar Construcción',
  EDIT_CATALOG: 'Editar Catálogo de Servicios',
  EDIT_CATEGORY: 'Editar Categoría',
  EDIT_CHANGE: 'Editar Cambio',
  EDIT_COMPANY: 'Editar Empresa',
  EDIT_CONFIGURATION: 'Editar configuración',
  EDIT_CONTRACT: 'Editar Contrato',
  EDIT_CRISIS_CONFIRMATION: 'Editar confirmación de crisis',
  EDIT_DEPARTMENT: 'Editar Departamento',
  EDIT_DEVICE: 'Editar Dispositivo',
  EDIT_EXPECTED_RESULT: 'Editar resultado esperado',
  EDIT_FORM: 'Editar Formulario',
  EDIT_GROUP: 'Editar Grupo',
  EDIT_MAIL: 'Editar Email',
  EDIT_NEW_TASK: 'Editar nueva tarea',
  EDIT_OR_ADD: 'Editar o agregar',
  EDIT_OUTSOURCED: 'Edición subcontratada',
  EDIT_POSITION: 'Editar cargo',
  EDIT_SERVICE_ORDER: 'Editar Orden de Servicio',
  EDIT_SLA: 'Editar SLA',
  EDIT_SOLICITANT: 'Editar Solicitante',
  EDIT_SPONSOR: 'Editar Responsables',
  EDIT_SUB_ACTIVITIES: 'Editar subactividad',
  EDIT_SUBDEPARTMENT: 'Editar Subdepartamento',
  EDIT_TAG: 'Editar Tag',
  EDIT_TASK: 'Editar Tarea',
  EDIT_TECH: 'Editar Técnico',
  EDIT_TICKETS: 'Editar Tickets',
  EDITING_OF_TECH_GMUD_AFTER_APPROVAL: 'Edición de gmud técnico después de aprobación',
  EDITING_SUBACTIVITY_STATUS: 'Edición de estado de subactividad',
  EDITION: 'Edición',
  EDITION_OF_SUB_ACTIVITY_STATUS: 'Edición del estado de la subactividad',
  EDITOR: 'Editor',
  EDITPROJECT: 'Editar Proyecto',
  EFFECTIVENESS_ANALYSIS: 'Análisis de Eficacia',
  EFFICIENCY: 'Eficiencia',
  EFFORT: 'Esfuerzo',
  EFFORT_VALUE: 'Valor del esfuerzo',
  EMAIL: 'E-mail',
  EMAIL_ALREADY_REGISTERED: 'E-mail ya registrado',
  EMAIL_BODY: 'Cuerpo del e-mail',
  EMAIL_POLICY_DESCRIPTION: 'Rutina de registro por e-mail',
  EMAIL_SUCCESSFULLY_RESENT: 'E-mail reenviado con éxito.',
  EMAIL_TEMPLATES: 'Modelos de e-mail',
  EMAILS_SENT_SUCCESSFULLY: 'Correos electrónicos enviados con éxito',
  EMERGENCI_CHART_TECH: 'Gráfico de urgencia por técnicos',
  EMERGENCY: 'Emergencia',
  EMPTY: 'Vacío',
  ENABLE: 'Habilitar',
  ENABLE_ASSET_INVOLVED_TICKET_CREATION: 'Habilitar Activo Involucrado en la creación de tickets',
  ENABLE_AUTOMATIC_LOADING: 'Habilitar la carga automática',
  ENABLE_COMPANY_CATALOG_GROUPING: 'Habilitar agrupamiento de catálogo de servicios',
  ENABLE_CRISIS_TICKET_TYPE: 'Habilitar tipo de ticket de crisis',
  ENABLE_CRITICAL_INCIDENT_TICKET_TYPE: 'Habilitar tipo de ticket de Incidente Crítico',
  ENABLE_EVENT_TICKET_TYPE: 'Habilitar tipo de ticket de evento',
  ENABLE_GENERAL_APPROVE_REPROVE_BUTTON_GMUD: 'Habilitar reglas para mostrar botones (Aprobar/Desaprobar) en la gestión de cambios',
  ENABLE_GMUD_APPROVERS_WORKFLOW: 'Habilitar flujo de aprobadores de GMUD',
  ENABLE_GMUD_FORMS_FILTER: 'Habilitar filtro de formulario en GMUD',
  ENABLE_GMUD_LEADERS_AS_APPROVERS: 'Permitir que los patrocinadores y patrocinadores alternativos aprueben el flujo de trabajo de GMUD',
  ENABLE_NOTIFICATION_CENTER: 'Habilitar Central de Notificationes ',
  ENABLE_REQUIREMENT_CHANNEL_TICKET: 'Habilitar canales obligatorios en tickets',
  ENABLE_SSO: 'Habilitar SSO',
  ENABLE_TICKET_LISTING_FOR_ASSOCIATED_COMPANIES: 'Habilitar listado de tickets para empresas asociadas',
  ENABLE_TICKET_LISTING_FOR_THE_DEPARTMENT: 'Habilitar listado de tickets para el departamento',
  ENABLED: 'Activado',
  END: 'Final',
  END_: 'Fin',
  END_CALL: 'Finalizar llamada ',
  END_DATE: 'Fecha de término',
  END_DATE_AND_TIME: 'fecha y hora de finalización',
  END_DATE_BREAK: 'Fecha final del Intervalo ',
  END_HOUR: 'Hora Final',
  END_OF_PROJECT: 'Fin del proyecto',
  END_OUTAGE: 'Fin de la interrupción',
  END_RECURRENCE: 'Fin de recurrencia',
  END_SELECTED_TICKETS: 'Finalizar ticket seleccionadas',
  END_TIME: 'Hora final',
  END_TIME_BREAK: 'Hora Final del Intervalo',
  ENDPOINT_MANAGEMENT: 'Gestión de Puntos Finales',
  ENDS_WITH: 'Termina com',
  ENTER: 'Entrar',
  ENTER_DESCRIPTION: 'Introducir descripción',
  ENTER_END_DATE_CHANGE: 'Indique la fecha final de este cambio.',
  ENTER_EVOLUTION: 'Introducir evolución',
  ENTER_TEMPLATE_BELOW: 'Introduzca la plantilla a continuación',
  ENTER_THE_CORRECT_AMOUNT_OF_ACTIVITY_EFFORT: 'Introduzca la cantidad correcta de esfuerzo de actividad',
  ENTER_THE_CORRECT_TICKET_EFFORT_VALUE: 'Ingrese el valor de esfuerzo de ticket correcto',
  ENTER_THE_END_DATE_OF_THE_SPRINT: 'Introduce la fecha de finalización del sprint',
  ENTER_THE_END_TIME_OF_THE_SPRINT: 'Introduce la hora de finalización del sprint',
  ENTER_THE_NAME_OF_THE_SPRINT: 'Introduce el nombre del Sprint',
  ENTER_THE_START_DATE: 'Introduce la fecha de inicio',
  ENTER_THE_START_DATE_OF_THE_SPRINT: 'Introduce la fecha de inicio del sprint',
  ENTER_THE_START_TIME_OF_THE_SPRINT: 'Introduce la hora de inicio del sprint',
  ENTER_TICKET_PRIORITY: 'Ingrese la prioridad del boleto',
  ENTER_VALID_TIME_VALUE: 'Introduzca un valor de tiempo',
  ENTRY_DATE: 'Fecha de Ingreso',
  EQUAL_TO: 'Igual a',
  EQUIPELOCATION: 'ASIGNACIÓN DE EQUIPO',
  EQUIPMENT: 'Equipamiento',
  ERASE: 'Borrar',
  ERRO_PJ_AC: '  Existen actividades registradas con una fecha de inicio inferior a la fecha del proyecto',
  ERROR_CODE: {
    E001: 'Contraseña Antigua inválida',
    E002: 'La nueva contraseña no puede ser la misma que la actual',
    E007: 'Error en el flujo',
    E008: 'Por favor Actualice / Registre sus datos en la plataforma',
    E009: 'Revisión ya enviada',
    E010: 'Vídeos no encontrados',
    E011: 'Usuario ya registrado',
    E012: 'La fecha final no puede ser inferior a la fecha inicial',
    E019: 'No se ha completado la actividad vinculada, Por favor Verificar el detalle de la actividad',
    E020: 'Valor no permitido',
    E404: 'Ruta inexistente',
    E500: 'Error Interno del Servidor'
  },
  ERROR_LOADING_CURRENT_SMTP_CONFIGURATION: 'Error al cargar la configuración SMTP actual',
  ERROR_LOADING_SMTP_LIST: 'Error al cargar la lista SMTP',
  ERROR_PASSWORD_NOT_ALLOWED: 'La contraseña no cumple con los criterios necesarios.',
  ERROR_PROCESSING: 'Error en el procesamiento',
  ERROR_REQUEST: 'Solicitud de error',
  ERROR_SELECTING_SMTP: 'Error al seleccionar SMTP',
  ESCALATION_INFORMATION: 'Información de escalado',
  ESTIMATED_COMPLETION_DATE: 'Fecha Estimada de Finalización',
  ESTIMATED_TIME: 'Tiempo Estimado',
  EVALUATE: 'Evaluar OS',
  EVALUATE_CALL: 'Evaluar Llamada',
  EVALUATE1: 'Evaluar',
  EVALUATION_REGARDING_ATTENDANCE_TEAM: 'de 0 a 10, ¿Cuál ha sido su experiencia con nuestro equipo de atención?',
  EVALUATION_REGARDING_CALL_RESOLUTION_QUALITY: 'de 0 a 10, ¿Cuál es su valoración respecto a la calidad de resolución de la llamada?',
  EVALUATION_REGARDING_CALL_RESOLUTION_TIME: 'De 0 a 10, ¿Cuál es su evaluación respecto al tiempo de resolución de la llamada?',
  EVALUTION: 'Evaluación',
  EVENT: 'Evento',
  EVENT_CAB_ALERT: 'Evento CAB registrado con éxito',
  EVENTS: 'Eventos',
  EVIDENCE: 'Evidencia',
  EVIDENCE_DESCRIPTION: 'Descripción de la Evidencia',
  EVIDENCES: 'Pruebas',
  EVOLU: 'Evolución del Proyecto',
  EVOLUTION: 'Evolución',
  EVOLUTION_DATE: 'Fecha de evolución',
  EVOLUTION_KPIS: "Evolución de KPI's",
  EVOLUTION_OF_ACTIVITIES: 'Evolución de las Actividades',
  EVOLUTION_OF_TESTS: 'Evolución de las pruebas',
  EVOLUTION_OF_TICKETS: 'EVOLUCION DE TICKETS',
  EVOLUTION_PERCENTAGE: 'Porcentaje de evolución',
  EVOLUTION_STATUS: 'Estado de evolución',
  EVOLUTION_TESTS_PLANS: 'Evolución del Plan de Pruebas',
  EVOLUTIONARY: 'Evolución',
  EXCEEDED: 'Excedido',
  EXECUTED: 'Ejecutado',
  EXECUTION: 'Ejecución',
  EXECUTION_ANNEXES: 'Anexos de ejecución',
  EXECUTOR: 'Ejecutor',
  EXPECTED_COST: 'Costo Estimado',
  EXPECTED_END: 'Fin esperado',
  EXPECTED_RESULT: 'Resultado esperado',
  EXPECTED_START: 'Comienzo esperado',
  EXPENSE: 'Gasto',
  EXPENSES: 'Gastos',
  EXPERIENCE: 'Experiencia',
  EXPIRATION_DATE: 'Fecha de caducidad',
  EXPIRE: 'Venció',
  EXPIRE_THIS_WEEK: 'Expiró esta semana',
  EXPIRE_TODAY: 'Expiró hoy',
  EXPIRED: 'Venció',
  EXPIRED_SLA: 'SLA vencido',
  EXPIRY_DATE: 'Fecha de expiración',
  EXPORT: 'Exportar',
  EXPORT_COMPANIES: 'Exportar Empresas',
  EXPORT_DATE: 'Exportar Datos',
  EXPORT_DEVICES: 'Exportar Dispositivos',
  EXPORT_EXCEL: 'Exportar excel',
  EXPORT_INVENTORY: 'Exportar inventario',
  EXPORT_LOCATION: 'Ubicación de Exportación',
  EXPORT_SOFTWARE: 'Exportar Softwares',
  EXPORT_TASKS: 'Exportar tareas',
  EXPORT_TO_EXCEL: 'Exportar a Excel',
  EXTEND_OS: 'Ampliar OS',
  EXTENSION: 'Extensión',
  EXTERNAL: 'Externo',
  FACE_TO_FACE: 'En persona',
  FACTORY: 'Fábrica',
  FAIL: 'Fallar',
  FAILURE: 'Falla',
  FARM: 'Granja',
  FAVORITES: 'Favoritos',
  FIELD: 'Campo',
  FIELD: 'Campo',
  FIELD_EXTENSION: 'Extensión (Añada .* para liberar todas las extensiones de archivo)',
  FIELD_TYPE: 'Tipo de campo',
  FILE: 'Archivo',
  FILE_EXTENSIONS: 'Extensiones de Archivos',
  FILE_NAME: 'Nombre del Archivo',
  FILE_SYSTEM: 'Sistema de archivos',
  FILE_TYPE: 'Tipo de Archivo',
  FILES: 'Archivos',
  FILL_IN_ALL_FIELDS: 'Rellene todos los campos',
  FILL_REQUIRED: '¡Rellene los Campos Obligatorios!',
  FILL_THE_FIELD: 'Rellene el campo',
  FILTER: 'Filtro',
  FILTER_BY: 'Filtrar por',
  FILTER_CONDITION: 'Condición de filtro',
  FINAL_DATE: 'Fecha Final',
  FINAL_HOUR: 'Hora Final',
  FINALIZE: 'Finalizar',
  FINALIZE_CONTRACT: 'Finalizar contrato',
  FINANCIAL: 'Finanzas',
  FINANCIAL_ANALYSIS_OF_CONTRACT: 'Análisis financiero del contrato',
  FINANCIAL_FEEDBACK: 'Rentabilidad Financiera',
  FINANCIAL_SUMMARY: 'Resumen financiero',
  FINISH_DONE: 'Terminación Completada',
  FINISH_GMUD: 'Finalizar Cambio',
  FINISH_THE_TASK_BEFORE_MOVING_IT_TO_THE_FINISHED_COLUMN: 'Complete la tarea antes de moverla a la columna terminada',
  FINISHED: 'Finalizado',
  FINISHED_CALL: 'llamada finalizada',
  FINISHED_CANCEL: 'Finalizado Cancelado',
  FINISHED_FAIL: 'Finalizado con falta',
  FINISHED_GMUD: 'Cambio Finalizado',
  FINISHED_PARTIALLY: 'Finalizado Parcial',
  FINISHED_SUCCESS: 'Finalizado con éxito',
  FIRMWARE_VERSION: 'Versión de FIRMWARE',
  FIRST_NEED_FINALIZE_SUBCALLS: 'Es necesario terminar primero las sub-llamadas',
  FIVE_WHYS_METHODOLOGY: 'Metodología de los 5 Porqués',
  FLAG: 'Bandera',
  FOLDER_ID: 'Carpeta Id',
  FOLLOY_DAY: 'Seguimiento del día',
  FOLLOY_MONTH: 'Seguimiento del mes',
  FOLLOY_WEEK: 'Seguimiento de la semana',
  FOR_SLA: 'Para SLA',
  FORECAST_TIME: 'Tiempo previsto',
  FORESEEN: 'Previsto',
  FORGOT_PASS: '¿Ha olvidado su contraseña?',
  FORM_FIELD: 'Campo de formulario',
  FORM_FIELDS: 'Campos de formulario',
  FORM_FIELDS_CANNOT_BE_BLANK: 'Los campos del formulario no pueden estar en blanco',
  FORM_TEMPLATE: 'Formulario Plantilla',
  FORMS: 'Formularios',
  FORTNIGHTLY: 'Quincenalmente',
  FREE: 'Gratis',
  FREE_SPACE: 'Espacio libre',
  FREE_TIMES: 'Horas Libres',
  FROM: 'De',
  FULL: 'Completar',
  FULL_NAME: 'Nombre Completo',
  FULL_SCREEN: 'Pantalla Completa',
  GANTT: 'GANTT',
  GANTT_GRAPH: 'Gráfico Gantt',
  GCF: 'GCF',
  GENERAL: 'General',
  GENERAL_DATE: 'Datos Generales',
  GENERAL_INF: 'Información General',
  GENERAL_INFRASTRUCTURE_RISK: 'Riesgo General de Infraestructura',
  GENERAL_PARAMETERS: 'Parámetros Generales',
  GENERAL_SERVICE_DESK_REPORT: 'Informe general del Service Desk',
  GENERAL_SETTINGS: 'Configuración general',
  GENERAL_STATUS_OF_THE_PROJECT: 'Estado general del proyecto',
  GENERAL_USER_CREATION: 'Creación general de usuarios',
  GENERAL_USER_PERMISSIONS_CREATION: 'Creación de Permisos de Usuario',
  GENERAL_USER_PERMISSIONS_EDITION: 'Edición de Permisos de Usuario',
  GENERATE: 'Generar',
  GENERATE_NEW_CHANGE: 'Generar un nuevo cambio',
  GENERATE_NEW_CHANGE_TEMPLATE: 'Generar una nueva plantilla de cambios',
  GENERATE_REPORT: 'Generar informe',
  GENERATED_PLURAL: 'Generados',
  GENERATES_COSTS: 'Genera Gastos',
  GI: 'GI',
  GLOBAL: 'Global',
  GLOBAL_DATA_ON_RISKS_OF_CHANGE_NOT_REGISTERED: 'Datos globales sobre riesgos de cambio no registrados',
  GMUD: 'Cambio',
  GMUD_AND_CAB_RISKS_SUCCESSFULLY_REGISTERED: 'Riesgos Cambio y CAB registrados con éxito',
  GMUD_APPROVE: '¿Deseas aprobar este Cambio?',
  GMUD_BACK_TO_EDITION: 'Volver Cambio para edición',
  GMUD_CONCLUDE: '¿Deseas concluir este Cambio?',
  GMUD_FLOW: 'Flujo de Aprobación de Cambio',
  GMUD_FLOW_INFOS: 'Crear y gestionar flujos de aprobación para Cambio',
  GMUD_IN_CAB: 'Cambio en CAB(diario)',
  GMUD_INFORMATION: 'INFORMACIÓN Cambio',
  GMUD_MANAGER: '¡Sólo el gestor del Cambio!',
  GMUD_REGISTRATION: 'Registro cambio',
  GMUD_REJECT: 'Rechazar GMUD',
  GMUD_RESEND_EMAIL: 'Reenviar e-mail para aprobación',
  GMUD_RISKS_SUCCESSFULLY_REGISTERED: 'Riesgos de Cambio registrados con éxito',
  GMUD_RUNNING: 'Cambio en ejecución',
  GMUD_STATUS: {
    ANALYZING: 'Analizando',
    CANCELED: 'Cancelado',
    CLOSE: 'Cerrada',
    CLOSED: 'Cerrado',
    CONCLUDED: 'Concluido',
    CONCLUDED_CANCEL: 'Terminado Cancelado',
    CONCLUDED_FAIL: 'Fallido Completo',
    CONCLUDED_PART: 'Parcialmente completado',
    CONCLUDED_SUCCESS: 'Concluido con éxito',
    CREATED: 'Creado',
    FINISHED: 'Finalizado',
    FINISHED_CANCEL: 'Finalizado Cancelado',
    FINISHED_FAIL: 'Finalizado con falla',
    FINISHED_PARTIALLY: 'Parcialmente Finalizado',
    FINISHED_SUCCESS: 'Finalizado con éxito',
    OPENED: 'Abierto',
    POST_IMPLEMENTATION_REVIEW: 'Revisión post-implementación',
    REJECTED: 'Desaprobado'
  },
  "GMUD_STATUS_DOES_NOT_ALLOW_RUNNING_TASKS": "El estado de Cambio no permite ejecutar tareas",
  "GMUD_STATUS_WILL_BE_SET_AS_OPEN": `El estado del Cambio se establecerá como "Abierto"`,
  "GMUD_SUCCESSFULLY_FINISHED": "Cambio finalizado con éxito",
  "GMUD_TYPE": "Tipo de GMUD",
  "GMUD_WORKFLOW_EDITION": "Edición de Flujo de Aprobación",
  "GMUD_WORKFLOW_REGISTRATION": "Registro de Flujo de Aprobación",
  GO_TO: "Ir a",
  GO_TO_ACTIVITY: 'Ir a la Actividad',
  "GOAL": "Objetivo",
  "GP": "GP",
  "GPU_ADAPTER": "Adaptador de GPU",
  "GPU_CAPTION": "GPU Caption",
  "GRAPH_BY_STATUS": "Gráfico por estado",
  "GRAPH_CIO": "Total de Tickets Abiertos",
  "GRAPH_DETAILS": "Detalles del gráfico",
  "GRAPH_STATUS_AVALATION": "Gráfico de estado por Evaluación",
  "GRAPH_STATUS_TECH": "Gráfico de estado por Grupo Técnico",
  "GRAPH_TYPE_ACTION": "Gráfico por Tipo de acción",
  "GRC": "GRC",
  "GREAT": "EXCELENTE",
  GROUP: "Grupo",
  "GROUP_BROKEN": "Grupo de Quebra",
  "GROUP_DESCRIPTION": "Grupo de Usuarios con perfil de Técnico y Solicitantes.",
  "GROUP_DESCRIPTION2": "Descripción del Grupo",
  "GROUP_GMUD": "Grupos de Cambio",
  "GROUP_LEADER": "Líder del grupo",
  "GROUP_LIST": "Lista de Grupos",
  "GROUP_NAME": "Nombre del grupo",
  "GROUP_REQUIRED_FIELD_CONDITIONAL": "Se requiere parámetro global o de empresa.",
  GROUP_TECH: "Grupo de Técnico",
  "GROUPING_NAME": "Nombre de agrupación",
  "GROUPS": "Grupos",
  "GROUPTECH_TABLE": "Tabla de Grupos Técnicos",
  "GROWING": "En Crecimiento",
  "GROWTH": "En Crecimiento",
  "GSD": "GSD",
  "GSD_INFORMATIONS": "Información GSD",
  "HARDWARE": "Hardware",
  "HARDWARE_LIST": "Lista de Dispositivos",
  "HAVE_AN_EVALUATION_OF_AT_LEAST_8_OF_SATISFACTION_IN_USING_THE_EVOLUTION_SOLUTION": "Tener una evaluación de al menos 8.0 de satisfacción en el uso de la solución evolution",
  "HEALTHY": "SALUDABLE",
  "HELP_DESK": "Centro de Ayuda",
  "HIDE": "Recoger",
  "HIGH": "Alta",
  "HISTORIC": "Historial",
  "HISTORIC_APPROVAL_CENTER": "Historia del Centro de Aprobación",
  "HISTORIC_ATEND_CLIENTS": "Historial de Atención al Cliente",
  "HISTORIC_CALL": "Historial de llamadas ",
  "HISTORIC_CHANGES": "Historial de cambios",
  "HISTORIC_GP": "Historial de acciones del proyecto",
  "HISTORIC_LIST": "Lista de Historial",
  "HISTORIC_SLA": "Historial de SLA",
  "HISTORIC_SLA_SERIES": "Historial de SLA de la serie",
  "HISTORY": "Histórico",
  "HISTORY_APPROVALS": "Historial de Aprobaciones",
  HISTORY_BACK_TO_EDITION: 'Historial de ediciones',
  "HISTORY_OF_REJECTIONS_AND_CANCELLATIONS": "HISTORIAL DE RECHAZOS Y CANCELACIONES",
  "HOLIDAY": "Festivo",
  "HOLIDAY_NAME": "Nombre feriado",
  "HOLIDAYS": "Festivos",
  "HOST": "Nombre del Host",
  "HOUR": "Hora",
  "HOUR_EXCEEDED_VALUE": "Valor de la Hora Excedida",
  "HOUR_NORMALIZATION": 'Hour Normalization',
  "HOUR_VALUE": "Valor de la Hora",
  "HOUR_VALUE_ADDITIONAL": "Valor de la Hora Adicional",
  "HOUR_VALUE_TECH": "Valor de la Hora Técnica",
  "HOURLY": "Por hora",
  "HOURS": "Horas",
  "HOURS_CONSUMED": "Horas Consumidas",
  "HOURS_CONSUMED_BY_TECHNICIAN_GROUP_ONLY_INCLUDES_TICKET": "Horas consumidas por Grupo Técnico (Solo incluye TICKET)",
  "HOURS_CONSUMED_PER_TOTAL_TEAM": "Horas consumidas por equipo total",
  "HOURS_CONTRACTED": "Horas Contratadas",
  "HOURS_CONTRACTED_VS_CONSUMED": "Horas Contratadas Por Horas Consumidas",
  "HOURS_EXCEEDED": "Horas Excedidas",
  "HOURS_PER_ANALYST": "Horas por analista",
  "HOURS_USERS": "Horas por Usuario",
  "HOURS_WORKED": "Horas trabajadas",
  "HOVER_OVER_COLUMN": "Pase el mouse por encima de las columnas para ver los detalles",
  "HOW_MANY_HOURS": "¿Cuántas horas?",
  "HRS_CON": "HRS/CON",
  "IC_ALREADY_INCLUDED_IN_THE_TICKET": 'IC ya incluido en el ticket',
  "IC_CONFIGURATION_ITEM": "IC (Elemento de configuración)",
  "IC_CONTACT_APP_GROUP": "Grupo de aplicaciones",
  "IC_CONTACT_CLIENT_KEY_USER_IT": "Cliente - Usuario Clave TI",
  "IC_CONTACT_CRISI_COMPANY_REPRESENTATIVES": "Representantes especiales",
  "IC_CONTACT_CRITICAL_SERVICE_GROUP": "Grupo de aplicaciones",
  "IC_CONTACT_CUSTOMER_GROUP_THAT_SHOULD_RECEIVE_SMS_IN_CASE_CRISIS_OR_DEGRADATION": "Grupo de Clientes que deben recibir SMS en caso de Crisis o Degradación",
  "IC_CONTACT_CUSTOMER_KEY_USER_BUSINESS": "Cliente - Usuario Clave Negocio",
  "IC_CONTACT_CUSTOMER_REPRESENTATIVES": "Representantes de clientes",
  "IC_CONTACT_INFRA_GROUP": "Grupo Infra",
  "IC_CONTACT_RESPONSIBLE_FOR_CRITICAL_SERVICE": "Responsable de Servicio Crítico",
  "IC_CONTACT_SPECIAL_GROUP_MUST_RECEIVE_SMS_IN_CASE_CRISIS_OR_DEGRADATION": "Grupo Especial que debe recibir SMS en caso de Crisis o Degradación",
  "IC_CONTACT_VIRTUAL_RESTORATION_TEAM_AND_KEY_RESOLVERS": "Equipo de restauración virtual y solucionadores clave",
  "IC_RELATIONSHIP": "relación de CI",
  "IC_SUCCESSFULLY_INCLUDED": 'IC incluido con éxito',
  "IC_WITHIN_BLACKOUT_RANGE_WANT_TO_CONTINUE": "IC dentro del rango de apagón, quiero continuar",
  "IC_WITHIN_OUTAGE_RANGE_WANT_TO_CONTINUE": "IC dentro del rango de Interrupción, quiero continuar",
  "ICON": "Ícono",
  "ICS_WITH_LESS_AVAILABILITY_OF_CURRENT_MONTH": "Ic's con menor disponibilidad del mes en curso",
  id: 'ID',
  "ID": "ID",
  "ID_COMPANY": "Id Empresa",
  "ID_CORRELATION": "Correlación ID",
  "ID_DISPLAY": "Pantalla ID",
  "ID_INTEGRATION": "ID de Integración",
  "ID_PROJECT": "ID de Proyecto",
  "ID_TICKET": "ID de Ticket",
  ID_TICKET_CREATION_REQUEST: 'ID de solicitud de creación de ticket',
  "IDENTIFICATION_NUMBER": "Número de identificación",
  IDS: 'IDs',
  "IF_YES_HOW_MANY_DAYS": "Si la respuesta es sí, ¿con cuántos días?",
  "IM_SURE": "Estoy seguro!",
  "IMAGE": "Imagen",
  "IMPACT": "Impacto",
  "IMPACT_CHART_TECH": "Gráfico de impacto por Técnicos",
  "IMPACTED_SERVICES": "Servicios Afectados",
  "IMPACTED_SYSTEM": "Sistema Impactado",
  "IMPEDITIVE": "Impeditivo",
  "IMPORT": "Importar",
  "IMPORTANT": "Importante",
  "IMPROVEMENT": "Mejora",
  "IN": "En",
  "IN_APPROVAL": "En aprobación",
  "IN_ARREARS": "Atrasado",
  "IN_ATTENDANCE": "En curso",
  "IN_HOURS": "En horas",
  "IN_LIST": "En la lista",
  "IN_PAUSE": "Em Pausa",
  "IN_PROGRESS": "En curso",
  "IN_THE_LAST_SEMESTER": "En el último semestre",
  "IN_THE_MONTH": "En el mes",
  "IN_THE_YEAR": "En el año",
  "IN_TIME": "A Tiempo",
  "INACTIVE": "Inactivo",
  "INCIDENT": "Incidente",
  "INCIDENT_MANAGEMENT": "Gestión de Incidentes",
  "INCIDENTS": "Incidentes",
  "INCLUDE_CHAT_HISTORY": "Incluir historial de chat",
  "INCLUDE_FORM_FIELDS": "Incluir campos del formulario",
  "INCLUDE_LOWERCASE": "Incluir minúsculas",
  "INCLUDE_NUMBERS": "Incluir números",
  "INCLUDE_QTD_CHARACTERS": "Número de caracteres",
  "INCLUDE_SYMBOLS": "Incluir símbolos",
  "INCLUDE_TECHNICAL_NOTES": "Incluir notas técnicas",
  "INCLUDE_TICKET_HISTORY": "Incluir historial del ticket",
  "INCLUDE_UPPERCASE": "Incluir mayúsculas",
  "INCORRECT_ORDER": "¡Orden incorrecto!",
  "INCREMENTAL": "Incremento",
  "INDEX": "Índice",
  "INDICATORS": "Indicadores",
  "INDIVIDUALS": "Individual",
  "INFO_DATA_BELOW": "Introduzca los datos a continuación",
  "INFORM_A_PERSON_IN_CHARGE": "Informar a un responsable",
  "INFORM_ALL_FIELDS": "¡Rellene todos los campos del formulario!",
  "INFORM_AN_APPROVER": "Notificar a un aprobador",
  "INFORM_DATE": "Introduzca fecha",
  "INFORM_REASON": "Informar el motivo",
  "INFORM_REQUIRED_FIELDS": "Informar los campos obligatorios",
  "INFORM_THE_CONTRACT_INVOLVED_IN_THIS_ACTIVITY": "Ingrese el contrato involucrado en esta actividad",
  "INFORM_THE_EFFORT_IN_DAYS_TO_BE_CARRIED_OUT": "Informar el esfuerzo en días a realizar",
  "INFORM_THE_PRIORITY_OF_THE_ACTIVITY": "Informar la prioridad de la actividad.",
  "INFORM_THE_PROJECT_PARTICIPANTS_AND_THEIR_HOURLY_VALUE": "Informar a los participantes del proyecto y su tarifa por hora.",
  INFORM_THE_REASON: 'Informar la justificación',
  "INFORM_THE_REASON_FOR_THE_GMUD": "Informar el motivo de la Cambio",
  "INFORM_THE_SPRINT_COMPANY": "Informar a la empresa Sprint",
  "INFORM_THE_SPRINT_REQUESTER": "Informar al Solicitante de Sprint",
  "INFORM_WHAT_TIME_THE_CHANGE_SHOULD_START": "Informar a qué hora debe comenzar el cambio",
  "INFORM_WHAT_WILL_BE_EFFORT_HOURCS_CARRIED": "Informar cuál será el esfuerzo en horas a realizar.",
  "INFORM_WHAT_WILL_BE_START_DATE_CHANGE": "Indique la fecha de inicio de este cambio",
  "INFORM_WHAT_WILL_BE_THE_EFFORT_IN_HOURS_TO_BE_CARRIED_OUT": "Informar cuál será el esfuerzo en horas a realizar",
  "INFORMATION": "Información",
  "INFORMATION_ASSETS": "Activos de información",
  "INFORMATION_TECHNOLOGY": "Tecnología de la informacion",
  "INFORMATIONS": "Informaciones",
  "INFORMED": "Informado",
  "INFRATRUCTURE_MANAGEMENT": "Gestión de Infraestructura",
  "INI_DATE_BREAK": "Fecha de inicio del intervalo",
  "INI_TIME_BREAK": "Horario Inicial del Intervalo",
  "INIT_OUTAGE": "Inicio de la interrupción",
  "INITIAL_CAUSE_ANALYSIS": "Análisis de la Causa Raiz",
  "INITIAL_DATE": "Fecha Inicial",
  "INITIAL_HOUR": "Hora de inicio",
  "INITIAL_TIME": "Tiempo Inicial",
  INITIATE_TICKET_SERVICE_ONLY_BY_USER_BELONGING_TO_THE_TECHNICAL_GROUP: 'Iniciar el servicio de tickets solo por parte del usuario que pertenece al grupo técnico',
  "INITIATIVES": "Iniciativas",
  "INNOVATION": "Innovación",
  "INPUT": "Entrada",
  "INSTALATION_DATE": "Fecha de instalación",
  "INSTALL_DATE": "Fecha de instalación",
  "INSTALLATION_TYPE": "Tipo de instalación",
  INSTRUCTION: "Instrucción",
  INSTRUCTIONS: "Instrucciones",
  "INTANGIBLES": "Intangible",
  INTEGRATION: "Integración",
  INTEGRATION_DATA: 'Datos de Integración',
  "INTEGRITY": "Integridad",
  "INTERACTION_CHAT_PUT_ATTENDANCE": "Puesta en marcha de la interacción por chat",
  "INTERNAL": "Interno",
  "INTERVAL": "Intervalo",
  "INVALID_CONTRACT_DATE": "Datos del contrato inválida",
  "INVALID_PASSWORD": "Contraseña incorrecta",
  "INVENTORY": "Inventario",
  "INVENTORY_LIST": "Lista de Inventarios",
  "INVENTORY_REPORT": "Informe de inventario",
  "INVOICE": "Factura",
  "INVOLVED_APPLICANTS": "Solicitantes Involucrados",
  "INVOLVED_AREAS": "*Areas Involucradas",
  "INVOLVED_IN_THE_CHANGE": "Involucrado en el cambio",
  "INVOLVED_IN_THE_PROJECT": "Usuarios involucrados",
  "INVOLVED_OUTSOURCED": "*Terceros Involucrados",
  "INVOLVED_PLURAL": "Involucrados",
  "INVOLVED_TECH": "*Técnicos Involucrados",
  "IP": "IP",
  "IS_GREATER_OR_EQUAL_TO": "Es mayor o igual a",
  "IS_LESS_THAN": "Es menor que",
  "IS_LESS_THAN_OR_EQUAL_TO": "Es menor o igual a",
  "IS_NOT_NULL": "No es Nulo",
  "IS_NULL": "Es Nulo",
  "ISO_9001": "ISO 9001",
  "ISO_20000": "ISO 20000",
  "ISO_27001": "ISO 27001",
  "ISO_37001": "ISO 37001",
  "ISO_INFORMATION": "Información ISO",
  "IT_BUDGET_CONTROL": "Control Presupuestario de TI",
  "IT_EVOLUTION_MANAGEMENT": "Gestión de la Evolución de TI",
  "IT_IS_NOT_NECESSARY_TO_INFORM": "no es neceserio informar",
  "IT_MANAGEMENT": "Gestión de TI",
  "ITEM": "Item",
  "ITEMS": "Items",
  "ITEMS_ASSOCIATED_WITH_ANOTHER_SPRINT": "Elementos asociados con otro sprint",
  "ITEMS_IN_SERVICE_FOR_YOU": "Artículos en servicio para usted",
  "ITEMS_LISTING": "Listado de Item",
  "ITEMS_WAITING_FOR_APPROVALS_AND_EVALUATIONS": "Elementos en espera de aprobaciones y revisiones",
  "ITS_GREATER_THAN": "Es mayor que",
  "ITSM": "ITSM",
  JURIDIC: 'Jurídica',
  "JUSTIFICATION": "Justificación",
  JUSTIFICATION_OF_TERMINATION: 'Justificación de finalización',
  "JUSTIFIED": "Justificado",
  "KANBAN_COLUMN": "Columna Kanban",
  "KANBAN_GRAPH": "Gráfico KANBAN",
  "KEY": "Clave",
  "KEYS": "Claves",
  "KNOWLEDGE_BASE": "Base de Conocimientos",
  "KNOWLEDGE_BASE_LIST": "Listado de Soluciones",
  "KNOWN_ERROR": "Error Conocido",
  "LABELS": "Etiquetas",
  "LANDLINE": "Teléfono Fijo",
  "LANG": {
    "EN": "Inglés",
    "ES": "Español",
    "PT_BR": "Portugués"
  },
  "LANGUAGE": "Idioma",
  LANGUAGE_TIMEZONE_CHANGES_NOTICE: 'Los cambios de idioma y zona horaria solo se aplicarán después del próximo inicio de sesión.',
  LANGUAGE_TIMEZONE_CHANGES_NOTICE: "Los cambios de idioma y zona horaria solo se aplicarán después del próximo inicio de sesión.",
  "LANGUAGE_TIMEZONE_CHANGES_NOTICE": "Los cambios de idioma y zona horaria solo se aplicarán después del próximo inicio de sesión.",
  LAST_ACTION: 'Última acción',
  LAST_BOOT_TIME: 'Última puesta en marcha',
  LAST_UPDATE: 'Ultima Actualización: ',
  LAST_UPDATE_DATE: 'Fecha de última actualización',
  LAST_UPDATES: 'Últimas actualizaciones',
  LASTNAME: 'Apellido',
  LATE: 'Tarde',
  LATE_ACTIVITIES: 'Actividades atrasadas',
  LDAP: 'LDAP',
  LDPA: 'LDPA',
  LEARNING: 'Aprendiendo',
  LEAVE_A_BRIEF_DESCRIPTION_ABOUT_THIS_CHANGE: 'Deje una breve descripción sobre este cambio',
  LEAVE_A_BRIEF_DESCRIPTION_OF_THE_BENEFITS_OF_THIS_CHANGE: 'Deje una breve descripción de los beneficios de este cambio',
  LEAVE_A_BRIEF_DESCRIPTION_OF_THIS_COST: 'Deje una breve descripción de este costo',
  LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT: 'Deje una breve descripción del proyecto',
  LEAVE_MONITOR: 'Detener la supervisión',
  LEGAL_OBLIGATION: 'Obligación legal',
  LEGEND: 'Leyenda',
  LET_US_KNOW_WHAT_THE_END_DATE_WILL_BE: 'Háganos saber cuál será la fecha de finalización',
  LET_US_KNOW_WHAT_TIME_THE_CHANGE_SHOULD_END: 'Háganos saber a qué hora debe terminar el cambio',
  LEVEL: 'Nivel',
  LGPD: 'LGPD',
  LICENSE: 'Licencia',
  LIMIT_DATE_TIME_PAUSE_SLA: '¡La fecha y hora ingresadas son anteriores a la fecha y hora actuales!',
  LIMIT_TIME_PAUSE_SLA: '¿Crear tiempo de espera para reiniciar el ticket en pausa?',
  LIMIT_TIME_PAUSE_SLA_REQUIRED: 'Obligar a informar el tiempo límite de pausa',
  LINK: 'Link',
  LINK_ACTIVITY: 'Adjuntar actividad',
  LINK_REGISTRATION: 'Registro de Link',
  LIST: 'Lista',
  LIST_ALLOWANCE: 'Lista de tickets a pagar ',
  LIST_BY_DEVICES: 'Lista por dispositivos',
  LIST_BY_GROUPS: 'Listar por grupos',
  LIST_OF_ASSOCIATED_ITEMS: 'Lista de elementos asociados',
  LIST_OF_ATTENDANCES: 'Lista de Atenciones',
  LIST_OF_COUNTRIES: 'Listado de países',
  LIST_OF_MONITORED_COMPANIES: 'Lista de Empresas Supervisadas',
  LIST_ONLY_CUSTOMER_TYPE_COMPANIES_IN_THE_COMPANY_FIELD_WHEN_OPENING_THE_TICKET: 'Lista solo las empresas de tipo cliente en el campo de empresa al abrir el ticket',
  LOAD: 'Cargando, Por favor espere...',
  LOAD_MORE: 'Carga más',
  LOAD_VIDEO: 'Por favor espere... Estamos cargando su vídeo',
  LOAN: 'Préstamo',
  LOCATION: 'Ubicación',
  LOCATIONS: 'Ubicaciones',
  LOCATION_DESCRIPTION: 'Registrar información sobre la ubicación',
  LOCATION_LANGUAGE: 'pt-BR',
  LOCATION_TITLE: 'Ubicación',
  LOG: 'Registros',
  LOG_FIM_DATE: 'Registro fecha final',
  LOG_INI_DATE: 'Fecha de inicio del registro',
  LOGGED_IN_USER: 'Usuario conectado',
  LOGICAL_CONDITIONS: 'Condiciones lógicas',
  LOGOFF: 'Salir',
  LOOK_FOR_SUPPLIERS_TICKETS_IN_RELATIONSHIP: 'Buscar tickets de proveedores en Relación',
  LOST: 'Se perdió',
  LOW: 'Bajo',
  MACADRESS: 'Mac',
  MAIL_DEFAULT: 'Si se activa, la empresa se vinculará a nuevos correos electrónicos',
  MAIL_HOST: 'Host',
  MAIL_HOST_VALUE: 'Valor del Host',
  MAIL_LIST: 'Lista de Email',
  MAIL_MAIL: 'Mail',
  MAIL_PASSWORD: 'Contraseña',
  MAIL_POLICY: 'Configuración de Email',
  MAIL_POP: 'Pop',
  MAIL_PORT: 'Port',
  MAIL_PORT_VALUE: 'Valor del Port',
  MAIL_SMTP: 'Smtp',
  MAIL_TLS: 'Tls',
  MAIL_TLS_VALUE: 'Valor del Tls',
  MAIN: 'Principal',
  MAIN_MODULES: 'Módulos Principales',
  MAIN_USER: 'Usuario Principal',
  MAINTAIN_ORIGINAL_CATALOG_OF_REQUESTS: 'Mantener el catálogo original de solicitudes',
  MAKE_YOUR_REQUEST_HERE: 'Haz tu pedido aquí',
  MANAGE: 'Gestor',
  MANAGE_CHANGE_GROUPS: 'Administrar grupos de cambio',
  MANAGEMENT_ALLOWANCE: 'Gestión de la asignación de Ticket SLA',
  MANAGEMENT_DASHBOARD: 'Panel de Gestión',
  MANAGEMENT_INFORMATION_TABLE: 'Tabla de información de gestión',
  MANAGEMENT_OF_DEMANDS_MOVING: 'GESTIÓN DE LA DEMANDA/CAMBIOS',
  MANAGEMENT_TICKET: 'Gestión Ticket',
  MANAGEMENT_VIEW: 'Visión de Gestión',
  MANAGEMENT_VISION: 'Visión de Gestión',
  MANAGER: 'Gestor',
  MANAGER_COORDINATOR: 'Gerente/Coordinador',
  MANAGER_GMUD: 'Gestor de Cambios',
  MANAGER_LINE: 'Línea de gestión',
  MANAGER_OF: 'Gestión de',
  MANUAL: 'Manual',
  MANUFACTURER: 'Fabricante',
  MAP: 'Mapa',
  MATURITY: 'Vencimiento',
  MATURITY_LEVEL: 'Nivel de madurez',
  MATURITY_MUST_CONTAIN_AN_ACTIVITY_OR_TICKET_THAT_IS_100_PERCENT_COMPLETE: '¡El vencimiento debe contener una actividad o boleto que esté 100% completo!',
  MAXATTACH: 'El archivo adjunto no puede superar los 20MB',
  MAXIMUM: 'Máximo',
  MEASURE_EFFORT_IN_DAYS: '¿Medir el esfuerzo en días?',
  MEASURE_IN_DAIYS: 'Medir en días',
  MEDIUM: 'Medio',
  MEM: 'MEM',
  MEMBERS: 'Miembros',
  MEMORY: 'Memoria',
  MEMORY_MANUFACTURER: 'Fabricante de la memoria',
  MEMORY_PART_NUMBER: 'Número de pieza de la memoria',
  MEMORY_SERIAL_NUMBER: 'Número de serie de la memoria',
  MEMORY_USAGE: 'Uso de Memoria(GB)',
  MENU: 'Menú',
  MESSAGE: 'Mensaje',
  MIGRATE_GM: 'Migrar GM',
  MIGRATE_GP: 'Migrar GP',
  MIGRATE_TICKET: 'Migrar Ticket',
  MIGRATE_TO_A_NEW_PROJECT: 'Migrar a un nuevo proyecto',
  MIGRATE_TO_A_PROJECT_ACTIVITY: 'Migrar a una actividad de proyecto',
  MIGRATE_TO_CRISIS: 'Migrar a la crisis/degradación',
  MIGRATE_TO_GMUD: 'Migrar a Cambio',
  MIGRATE_TO_OS: 'Migrar a OS',
  MIGRATE_TO_PROBLEM: 'Migrar al Problema',
  MIGRATE_TO_PROJECT: 'Migrar a proyecto',
  MILESTONE: 'Grupo de Actividades',
  MINIMUM: 'Mínimo',
  MINUTES: 'minutos',
  MINUTES_SUCCESSFULLY_RECORDED: 'Acta registrada con éxito',
  MODE: 'Modo',
  MODEL: 'Modelo',
  MODIFICATION: 'Modificación',
  MODIFICATION_DATE: 'Fecha de modificación',
  MODIFICATION_USER: 'Usuario que modificó',
  MODULE: 'Módulo',
  MONETARY_TYPE: 'Tipo de moneda',
  MONITOR: 'Controlar',
  MONITOR_DEVICE: 'Supervisar un nuevo dispositivo',
  MONITORING: 'Monitoreo',
  MONITORING_DEVICES_AND_LINKS: 'Supervisión de dispositivos y enlaces',
  MONITORING_GROUP: 'Grupo de supervisión',
  MONTH: 'Mes',
  MONTH_SELECTED: 'Mes seleccionado',
  MONTH_VALUE: 'Valor Mensual',
  MONTHLY: 'Mensual',
  MONTHS: 'Meses',
  MORE: 'Más',
  MORE_INFORMATION: 'Mas informaciones',
  MORE_THAN_10MB: 'supera los 10mb permitidos',
  MORE_THAN_15MB: 'supera los 15mb permitidos',
  MORE_THAN_40MB: 'supera los 40mb permitidos',
  MOVIMENT: 'Movimiento',
  MULTI_CALL: 'Llamada múltiple',
  MULTI_TICKETS: 'Tickets Múltiples',
  MUST_COMPLETE_ALL_ACTIVITIES: 'Es necesario completar todas las actividades',
  MY_DEMANDS: 'Mis Exigencias',
  MY_EXPERIENCE: 'Mi Experiencia',
  MY_TICKETS: 'Mis boletos',
  N_CONTRACT: 'N Contrato',
  N1_NAME: 'Nombre de nivel N1',
  N1_VALUE: 'Valor de nivel N1',
  N2_NAME: 'Nombre de nivel N2',
  N2_VALUE: 'Valor de nivel N2',
  N3_NAME: 'Nombre de nivel N3',
  N3_VALUE: 'Valor de nivel N3',
  N4_NAME: 'Nombre de nivel N4',
  N4_VALUE: 'Valor de nivel N4',
  NAME: 'Nombre',
  NAME_CONTRACT: 'Nombre del Contrato',
  NAME_FORM: 'Nombre del formulario',
  NAME_IS_IN_USE: 'El nombre está en uso',
  NATURE: 'Naturaleza',
  NEEDS_APPROVAL: 'Necesita Aprobación',
  NEGOTIABLE: 'Negociable',
  NEIGHBORHOOD: 'Barrio',
  NETWORK: 'Red',
  NEVER: 'Nunca',
  NEW: 'Nuevo',
  NEW_ASSESSMENT: 'Nuevo Soporte',
  NEW_ASSIGNMENT: 'Agregar Nueva Tarea al Catálogo de Servicio',
  NEW_CALL: 'Nueva llamada',
  NEW_CALLS: 'Nuevos llamados',
  NEW_CATALOG: 'Nuevo Catálogo',
  NEW_CATEGORY: 'Nueva Categoría',
  NEW_CATEGORY_ACTIVITY: 'Nueva Categoría de Actividad',
  NEW_CHANGE: 'Registrar Nuevo Cambio',
  NEW_COMPANY: 'Nueva Empresa',
  NEW_CONTRACT: 'Nuevo Contrato',
  NEW_DEPARTMENT: 'Nuevo Departamento',
  NEW_DEVICE: 'Nuevo Dispositivo',
  NEW_FORM: 'Nuevo formulario',
  NEW_GROUP: 'Nuevo Grupo',
  NEW_GROUP_OF: 'Nuevo Grupo de',
  NEW_LOCATION: 'Nueva ubicación',
  NEW_MASTER_PASSWORD: 'Crear la contraseña maestra',
  NEW_MILESTONE: 'Nueva Categoría',
  NEW_OUTSOURCED: 'Nueva Subcontratación',
  NEW_PASSWORD: 'Nueva Contraseña',
  NEW_PERMISSION: 'Nueva permiso',
  NEW_PLURAL: 'Nuevos',
  NEW_POSITION: 'NUEVO CARGO',
  NEW_PROJECT: 'Registrar Nuevo Proyecto',
  NEW_REGISTRATION: '¿Desea realizar un nuevo registro?',
  NEW_REQUEST: 'Nueva solicitud',
  NEW_REQUESTER: 'Nuevo solicitante',
  NEW_RISK: 'Nuevo Riesgo',
  NEW_SERVICE_ORDER: 'Nueva Orden de Servicio',
  NEW_SOLUTION: 'Nueva Solución',
  NEW_SPONSOR: 'Nuevo Responsable',
  NEW_STANDARD_ITEM: 'Nuevo artículo estándar',
  NEW_SUBDEPARTMENT: 'Nuevo Subdepartamento',
  NEW_TASK: 'NUEVA TAREA',
  NEW_TASK_TEMPLATE: 'Nueva planilla de tarea',
  NEW_TECH: 'Nuevo Técnico',
  NEW_TEST: 'Nueva Test',
  NEW_TICKET: 'Nuevo Ticket',
  NEW_TREATMENT: 'Nuevo tratamiento',
  NEXT: 'Siguiente',
  NEXT_EXPIRATION: 'Próxima fecha de vencimiento',
  NEXT_PAGE: 'Página siguiente',
  NO: 'No',
  NO_ALERTS: 'Ninguna alerta hasta ahora',
  NO_ATTENDANCE: 'Sin asistencia hasta la fecha',
  NO_COMMENTS: 'No hay comentarios registrados',
  NO_CONTRACTS: 'Sin contrato por el momento',
  NO_DEPARTMENT_HEADS: 'No hay jefes de departamento disponibles',
  NO_DEVICES_CAPACITY_PLAN: 'No hay dispositivos por el momento',
  NO_DEVICES_MONITOR: 'Hasta ahora no se han agregado dispositivos a la supervisión',
  NO_OPTIONS: 'Sin opciones',
  NO_RECURRENCE: 'Sin recurrencia',
  NO_REG_FIND: 'No se ha encontrado ningún registro',
  NO_REGISTERED_USER: 'No se han Encontrado Registros',
  NO_STARTED: 'No se ha iniciado',
  NO_TICKETS: 'No hay tickets por el momento',
  NON_CRITICAL: 'No crítico',
  NONE: 'Ninguno',
  NONE_SO_FAR: 'Ninguno en este momento',
  NORMAL: 'Normal',
  NOT: 'No',
  NOT_ALLOWED_EVOLVE_CALL_WITH_PENDING_APPROVAL: '¡No se permite evolucionar la convocatoria con Tareas pendientes de aprobación!',
  NOT_APPROVED: 'No Aprobado',
  NOT_ASSOCIATED_WITH_ACTIVITIES: 'No asociado a actividades',
  NOT_ATTRIBUTED: 'No asignado',
  NOT_AUTHORIZED: 'No Autorizado',
  NOT_CLOSE_TICKET: 'No cerrar el ticket',
  NOT_COMPLETED_PLURAL: 'No concluidos',
  NOT_EXECUTED: 'Sin ejecutar',
  NOT_FINISHED: 'No finalizado',
  NOT_GROWTH: 'Sin crecimiento',
  NOT_MONITORING: 'No monitoreo',
  NOT_PERMANENT: 'No permanente',
  NOT_REVISED: 'No Revisado',
  NOTES_ACTIVITY: 'Notas da Actividad',
  NOTES_TICKETS_AS_TABLE: 'Usar el historial de tickets como una tabla',
  NOTHING_REGISTERED: 'Sin Registros',
  NOTIFICATION: 'Notificación',
  NOTIFICATION_CENTER: 'Centro de Notificaciones',
  NOTIFICATION_CENTER_DESCRIPTION: 'Establezca reglas y políticas de envío de notificaciones',
  NOTIFICATION_CENTER_TAGS: 'Tags de Notificaciones',
  NOTIFICATION_CENTER_TAGS_DESCRIPTION: 'Cree tags para ser usadas como variables en el envío de notificaciones',
  NOTIFICATION_CONDITION: 'Condiciones de Notificación',
  NOTIFICATION_GROUP: 'Grupo de Notificaciones',
  NOTIFICATION_REGISTRATION: 'Registro de Notificaciones',
  NOTIFICATION_HISTORY: 'Historial de notificaciones',
  NOTIFICATION_SUBJECT_HOLDER: 'Utiliza las mismas etiquetas (variables) del mensaje aquí',
  NUMBER: 'Número',
  NUMBER_CALLS: 'Número de llamados',
  NUMBER_HOURS_PER_DAY: 'Número de horas al día',
  NUMBER_OF_ACTIVITIES: 'Número de actividades',
  NUMBER_OF_CALLS_PER_ANALYST: 'Cantidad de Llamados por Analista',
  NUMBER_OF_CLOSED_TICKETS_PER_TECHNICIAN_PER_DAY: 'Número de tickets cerrados por técnico por día',
  NUMBER_OF_DEVICES: 'Número de dispositivos',
  NUMBER_OF_OPEN_ACTIVITIES: 'Número de actividades abiertas',
  NUMBER_OF_OPEN_TASKS: 'NÚMERO DE TAREAS ABIERTAS',
  NUMBER_OF_SERVERS_MONITORED: 'Número de servidores supervisados',
  NUMBER_OF_SERVERS_MONITOREDDASH: 'Número de servidores supervisados',
  NUMBER_OF_TICKETS_PER_TECHNICIAN: 'Número de tickets por técnico',
  NUMBERS_CORE: 'Núcleo de números',
  NUMBERS_OF_LEVELS: 'Número de niveles de resolución',
  OBJECT_PRIORITIZATION: 'Priorización de objetos',
  OBJECTIVE: 'Objetivo',
  OBJECTIVE_OS: 'Objetivo de la Orden de Servicio',
  OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH: 'Obligación de Razón en el cambio de técnico/grupo técnico',
  OBSERVATION: 'Observación',
  OBSERVATIONS: 'Observaciones',
  OBSERVER: 'Observadores',
  OBSERVER_ADDED_SUCCESSFULLY: 'Observador agregado con éxito',
  of: 'of',
  OF: 'de',
  OF_THE_IT: 'de TI',
  OFF_THE_LIST: 'Fuera de la lista',
  OFFICE: 'Oficina',
  OK: 'Ok',
  OKR_ACTIVITIES: 'Actividades OKR',
  OKR_EVOLUTION: 'Evolución OKR',
  OLA: 'OLA',
  OLD_PASSWORD: 'Contraseña Antigua',
  ON_ALERT: 'En alerta',
  ON_LATE: 'En atraso',
  ON_PROGRESS: 'En proceso',
  ON_TIME: 'En el plazo',
  ONLY_APPROVERS_CAN_ACCESS_THIS_OPTION: 'Solo los aprobadores pueden acceder a esta opción',
  ONLY_LINKS: 'Sólo Links',
  ONLY_RESPONSIBLE_FOR_FILE_CAN_DELETE: 'Sólo el responsable del archivo puede borrar',
  ONLY_SEND: 'Solo enviar',
  ONLY_SERVERS: 'Sólo Servidores',
  ONLY_THE_CHANGE_MANAGER_CAN_EDIT: 'Solo el administrador de cambios puede editar',
  ONLY_THE_PERSON_IN_CHARGE_CAN_PERFORM_THIS_ACTION: 'Solo la persona responsable puede realizar esta acción.',
  ONLY_THE_PROJECT_MANAGER_CAN_PERFORM_THIS_ACTION: 'Solo el Project Manager puede realizar esta acción.',
  ONLY_THE_PROJECT_MANAGER_OR_ADMINISTRATOR_CAN_PERFORM_THIS_ACTION: 'Solo el Project Manager o el Administrador pueden realizar esta acción.',
  OPEN: 'Abrir',
  OPEN_PROJECT: 'Proyecto abierto',
  OPEN_REQUESTS: 'Solicitudes Abiertas',
  OPEN_TICKET: 'Ticket Abierto',
  OPENED: 'Abierto',
  OPENING: 'Apertura',
  OPENING_DATA_FINAL: 'Fecha de apertura - Final',
  OPENING_DATA_INITIAL: 'Fecha de apertura - Inicial',
  OPENING_DATE: 'Fecha de apertura',
  OPENING_HOURS: 'Horarios de aperturas',
  OPENS: 'Abierto',
  OPERATING_TIME: 'Tiempo de funcionamiento',
  OPERATION_NOT_PERFORMED: 'Operación no realizada.',
  OPERATION_NOT_PERFORMED_MAX_20_USERS: 'Operación no realizada, máximo 20 usuarios',
  OPERATION_NOT_PERFORMED_ONLY_THE_APPROVER_CAN_PERFORM_THIS_ACTION: 'Operación no realizada, solo el aprobador puede realizar esta acción',
  OPERATION_PERFORMED_SUCCESSFULLY: 'Operación realizada con éxito',
  OPERATIONAL_SYSTEM: 'Sistema Operativo',
  OPERATOR: 'Operador',
  OPTIONAL: 'Opcional',
  OPTIONAL_KEYBOARD: 'Teclado Opcional',
  OR: 'ou',
  ORDER: 'Orden',
  ORGANIZATION: 'Organización',
  ORIGIN: 'Origen',
  ORIGIN_FIM_DATE: 'Fecha final del origen',
  ORIGIN_ID: 'ID Origen',
  ORIGIN_ID_REAL: 'Origen real ID',
  ORIGIN_INI_DATE: 'Data início origen',
  ORIGIN_NAME: 'Origen de nombre',
  ORIGIN_TABLE: 'Tabla de origen',
  ORIGIN_TYPE: 'Tipo de origen',
  OS: 'OS',
  OS_RELEASE: 'Release do SO',
  OS_VERSION: 'Versión do SO',
  OTHER_PROJECT_COSTS: 'Otros costos del proyecto',
  OUTAGE: 'Indisponibilidad',
  OUTAGE_FIM_DATE: 'Fecha de finalización de la Indisponibilidad',
  OUTAGE_ID: 'ID Indisponibilidad',
  OUTAGE_INI_DATE: 'Fecha de inicio de la Indisponibilidad',
  OUTAGE_OUTSIDE_GMUD_RANGE: 'Indisponibilidad fuera del rango de Cambio',
  OUTAGES: 'Interrupciones',
  OUTPUT: 'Salida',
  OUTSOURCED: 'Subcontratación',
  OUTSOURCED_COMPANY: 'Empresa Subcontratada',
  OUTSOURCED_DESCRIPTION: 'Persona contratada para prestar servicios a través del contratista.',
  OUTSOURCED_LIST: 'Lista de Subcontratos',
  OVERDUE_TASKS: 'Tareas atrasadas',
  OVERVIEW: 'Visión general',
  PACKAGE_NAME: 'Nombre del empaque',
  PANEL_DASH: 'Panel Táctico',
  PANEL_PMO: 'Panel PMO',
  PARAMETERS: 'Parámetros',
  PARTIAL: 'Parcial',
  PARTICIPANT: 'Participante',
  PARTICIPANT_REQUEST: 'Solicitudes de los participantes',
  PARTICIPANTS: 'Participantes',
  PASSIVATORS: 'Pasivo',
  PASSIVE: 'Pasivo',
  PASSIVES: 'Pasivos',
  PASSWORD: 'Contraseña',
  PASSWORD_ASSISTANT: 'Asistente de contraseña',
  PASSWORD_ASSISTANT_NEW: 'Asistente para creación de contraseñas',
  PASSWORD_CHANGE_NEXT_LOGON: 'Cambiar contraseña en el próximo inicio de sesión',
  PASSWORD_CURRENT: 'Contraseña actual',
  PASSWORD_GENERATE: 'Generar contraseña',
  PASSWORD_QUESTION: 'Preguntas de seguridad',
  PASSWORD_QUESTION_DESCRIPTION: 'Crear preguntas de seguridad para la recuperación de contraseña',
  PASSWORD_RULE_LABEL: 'Tu contraseña debe tener al menos',
  PASSWORD_RULE_LOWERCASE_CHAR: 'Un carácter en minúscula',
  PASSWORD_RULE_NUMBER: 'Un número',
  PASSWORD_RULE_SPECIAL_CHAR: 'Un carácter especial',
  PASSWORD_RULE_UPPERCASE_CHAR: 'Un carácter en mayúscula',
  PASSWORD_SAVE: 'Guardar contraseña',
  PASSWORD_SIMPLE: 'Contraseña simple',
  PASSWORD_TYPE: 'Tipo de contraseña',
  PASSWORD_VAULT: 'Contraseña Segura',
  PATRIMONY: 'Patrimonio',
  PAUSE_PROJECT: 'Pausar proyecto',
  PAUSED: 'En pausa',
  PAUSED_TIME: 'Tiempo de pausa',
  PENDING: 'Pendiente',
  PENDING_ACTIVITIES: 'Actividades pendientes',
  PENDING_APPROVALS: 'Aprobaciones pendientes',
  PENDING_TICKET_LIST: 'Lista de ticket pendientes',
  PEOPLE: 'Personas',
  PER: 'Por',
  PER_PANEL: 'Por panel',
  PER_TEAM: 'Por equipo',
  PERCENTAGE: 'Percentaje',
  PERCENTAGE_INDEX: 'Índice %',
  PERCENTAGE_OF: 'Porcentaje de',
  PERCENTAGE_OF_MATURITY: 'Porcentaje de madurez',
  PERFORM_AUDIT_OF_EQUIPMENT_FOR_REPLACEMENT_UNDER_WARRANTY: 'Auditoría de equipos para su sustitución en garantía',
  PERFORM_TEST_REGISTERED_EMAIL: 'Realizar prueba con email registrado',
  PERFORMANCE_OF_PROJECTS: 'Nivel de satisfacción en la entrega',
  PERFORMED: 'Realizado',
  PERIOD: 'Período',
  PERIOD_AND_PRIORITY: 'PERIODO Y PRIORIDAD',
  PERMANENT: 'Permanente',
  PERSONAL_DATA: 'Datos personales',
  PERSPECTIVE: 'Perspectiva',
  PERSPECTIVE_OVERALL_STATUS: 'Estado general de la perspectiva',
  PHASE_ORDER: 'Orden de las etapas',
  PHASES: 'Etapas',
  PHONE: 'Telefóno',
  PHONE_CALL: 'Llamada Telefónica',
  PHYSICAL_SECURITY_AND_ENVIRONMENT: 'Seguridad Física y Medio ambiente',
  PHYSICS: 'Física',
  PLANNED: 'Planificado',
  PLANNED_ACTIVITIES: 'Actividades planificadas',
  PLANNED_AVAILABILITY: 'Disponibilidad prevista',
  PLANNED_HOURS: 'Horas planificadas',
  PLANNED_MATURITY: 'Vencimiento previsto',
  PLANNING: 'Planificación',
  PLATFORM: 'Plataforma',
  PLEASE_PROVIDE_A_BRIEF_JUSTIFICATION_FOR_THIS_CHANGE: 'Proporcione una breve justificación de este cambio.',
  PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA: 'Por favor espere estamos procesando los datos',
  POP_PORT: 'Puerto Pop',
  POPULATE_MAP: 'Rellenar mapa',
  PORT: 'Puerto',
  PORTAL_CUSTOMIZATION: 'Personalización del portal',
  POSITION: 'Posición',
  POSITIONS: 'Posiciones',
  POST_IMPLEMENTATION_REVIEW: 'Revisión post implementación',
  POSTAL_CODE: 'Código postal',
  POSTED_HOURS: 'Horas publicadas',
  PREDECESSOR_ACTIVITIES: 'Actividades predecesoras',
  PREDECESSOR_TASK: 'Tarea predecesora',
  PREDECESSOR_TASKS: 'Tareas previas',
  PREDECESSORS: 'PREDECESORES',
  PREOCUTIONS: 'Inquietudes',
  PREVENTIVE: 'Preventivo',
  PREVIOUS: 'Anterior',
  PREVIOUS_PAGE: 'Página anterior',
  PRIMARY: 'Primario',
  PRINT: 'Imprimir',
  PRINT_CHART: 'Tabla de impresión',
  PRINT_PMO: 'Imprimir PMO',
  PRIORITIES: 'Prioridades',
  PRIORITIZATION: {
    HIGH: 'Alto (a)',
    MEDIUM: 'Medio (a)',
    SMALL: 'Bajo (a)',
    VERY_HIGH: 'Muy Alto (a)',
    VERY_SMALL: 'Muy Bajo (a)'
  },
  PRIORITIZATION_MATRIX: 'Matriz de Priorización',
  PRIORITIZATION1: 'Priorización',
  PRIORITY: 'Prioridades',
  PRIORITY_CHART_TECH: 'Cuadro de Prioridades por Técnicos',
  PRIORIZATION: 'PRIORIZACIÓN',
  PRIVATE: 'Privado',
  PROBABILITY: 'Probabilidad',
  PROBLEM: 'Problema',
  PROBLEM_IDENTIFICATION: 'Identificación deL problema',
  PROBLEM_MANAGEMENT: 'Gestión de Problemas',
  PROBLEM_NAME: 'Nombre del problema',
  PROBLEMS: 'Problemas',
  PROBLEMS_SOLVED: 'Problemas resueltos',
  PROCESS: 'Proceso',
  PROCESSES: 'Procesos',
  PROCESSES_IMPROVEMENT: 'Mejoras de Procesos',
  PROCESSOR: 'Procesador ',
  PRODUCT_CATALOG: 'Catálogo de Productos',
  PRODUCTIVE: 'Productivo',
  PRODUCTIVE_HOURS: 'Horas Productivas',
  PROFILE: 'Perfil',
  PROFILE_PICTURE: 'Foto de Perfil',
  PROFILE_TYPE: 'Tipo de perfil',
  PROGRESS: 'En curso',
  PROGRESS_: 'Progreso',
  PROGRESS_STATUS: 'Estado de avance',
  PROJECT: 'Proyecto',
  PROJECT_BENEFIT: 'Beneficios del proyecto',
  PROJECT_BOOK: 'Libro de proyectos',
  PROJECT_CANCELED: 'Proyecto cancelado',
  PROJECT_CLOSE: 'Cerrar proyecto',
  PROJECT_CLOSED_IN_MONTH: 'Proyectos cerrados',
  PROJECT_DELETED_SUCCESSFULLY: '¡Proyecto eliminado con éxito!',
  PROJECT_DETAIL: 'Detalles del Proyecto',
  PROJECT_ID: 'Projecto ID',
  PROJECT_IN_ARREARS: 'proyectos atrasados',
  PROJECT_IN_EXECUTION: 'Proyectos en ejecución',
  PROJECT_LIST: 'Lista de Proyectos',
  PROJECT_MANAGEMENT: 'Gestión de Proyectos',
  PROJECT_MANAGEMENT_SPRINT: 'GESTIÓN DE PROYECTOS / SPRINT',
  PROJECT_MANAGER: 'Jefe de Proyecto',
  PROJECT_NAME: 'Nombre del proyecto',
  PROJECT_PORTFOLIO: 'Portafolio de proyectos',
  PROJECT_PRIORITIZATION: 'Priorización de proyectos',
  PROJECT_PRIORITIZATION_MAP: 'Mapa de prioridades de los proyectos',
  PROJECT_PRIORITY: 'Prioridad del proyecto',
  PROJECT_PROGRESS: 'AVANCE DE LOS PROYECTOS',
  PROJECT_STARTED_IN_MONTH: 'Proyectos iniciados en el mes',
  PROJECT_STATUS: {
    DOING: 'Ejecutando',
    DONE: 'Finalizado',
    STOPPED: 'Detenido',
    TO_DO: 'Ejecutar',
    VALIDATION: 'Validación'
  },
  PROJECT_TABLE: 'Tabla de proyectos',
  PROJECT_TEMPLATE: 'Plantilla de proyecto',
  PROJECTENT: 'ENTREGA DEL PROYECTO',
  PROJECTION_OF_ACTIVITIES: 'Proyección de Actividades',
  PROJECTS: 'Proyectos',
  PROJECTS_AWAITING_EXECUTION: 'Proyectos pendientes de ejecución',
  PROJECTS_COMPLETED_LATE: 'Proyectos terminados con retraso',
  PROJECTS_GOAL: 'Objetivo del proyecto',
  PROJECTS_STARTED_LATE: 'Proyectos iniciados con retraso',
  PROMOTERS: 'Promotores',
  PROVIDER: 'Proveedores',
  PROVIDER_CLIENT: 'Provedor Cliente',
  PROVIDER_TIVIT: 'Provedor Tivit',
  PROVIDER2: 'Proveedor',
  PROVISIONED: 'Provisionado',
  PROVISIONED_ITEMS: 'Items provisionados',
  PUNCTUATION: 'Puntuación',
  QUANTITIES: 'Cantidades',
  QUANTITY: 'Cantidad',
  QUANTITY_OF_TICKETS: 'CANTIDAD DE TICKETS',
  QUANTITY_PER_MONTH: 'Cantidad Por Mes',
  QUANTITY_PERIOD: 'Cantidad / Período',
  QUARTER: 'Cuarto',
  QUERY_MADE: 'Consulta realizada',
  QUERY_NOT_REALIZE: 'Consulta no realizada, valor superior a 31 días',
  QUESTION: 'Pregunta',
  QUOTE: 'cita',
  RANK: 'Rango',
  RANKING: 'Ranking',
  RANKING_TOP_10: 'RANKING - TOP 10',
  RAPID_ITEM_APPROVAL_AND_OR_EVALUATION: 'Aprobación y/o revisión rápida de artículos',
  RATING_OF_THIS_PROJECT: 'Calificación de este proyecto',
  RATION_CONSUMPTION_VALUE: 'Relación entre el valor y consumo',
  RDP: 'RDP',
  READ: 'Lectura',
  READJUSTMENT: 'Reajuste',
  READJUSTMENTS: 'Reajustes',
  REAL: 'Real',
  REAL_ID: 'ID Real',
  REALIZED: 'Realizado',
  REALIZED_COST: 'Costo Realizado',
  REALIZED_TIME: 'Tiempo empleado',
  REALIZED_VALUE: 'Valor efectivo',
  REASON: 'Motivo',
  REASON_FOR_REJECTION: 'MOTIVO DEL RECHAZO',
  REASON_REQUIRED: 'Motivo requerido',
  REASON_STATUS: 'Motivo del Estado',
  REASSIGNMENT_COUNT: 'Recuento de reasignaciones',
  RECEIVED: 'Recibió',
  RECIPIENTS: 'Destinatarios',
  RECLASSIFY_ATTENDANCE: 'Reclasificar Atendimento',
  RECORD: 'Guardar',
  RECORD_UNDERACTIVITY: 'Registrar subactividad',
  RECOVER: 'Recuperar',
  RECOVERY_PASSWORD: {
    ALTERNATIVE_EMAIL: 'Agregar un correo electrónico alternativo',
    ALTERNATIVE_EMAIL_DESCRIPTION: 'Agrega un correo electrónico alternativo o selecciona algunas preguntas y proporciona respuestas para ayudar a proteger tu cuenta. Si olvidas tu contraseña, puedes usar esta información para restablecerla.',
    ALTERNATIVE_EMAIL_INFO: 'Agregar un correo electrónico alternativo es una excelente manera de proteger tu cuenta. Si olvidas tu contraseña, puedes recibir un enlace para restablecerla en tu correo electrónico alternativo.',
    CHALLENGE_RECOVERY: 'Recuperar contraseña usando preguntas de seguridad',
    CHOOSE_RECOVERY_METHOD: 'Elige uno de los métodos de recuperación',
    EMAIL_RECOVERY: 'Recuperar contraseña usando correo electrónico o correo electrónico alternativo',
    INCORRECT_ANSWERS: 'Respuestas incorrectas. Por favor, inténtelo de nuevo.',
    NO_QUESTIONS_FOUND: 'No se encontraron preguntas de seguridad.',
    PASSWORD_RECOVERY_QUESTIONS: 'Preguntas de recuperación de contraseña',
    PROTECT_ACCOUNT: 'Protege tu cuenta',
    SECURITY_QUESTION: 'Pregunta de seguridad',
    SELECT_SECURITY_QUESTIONS: 'Seleccionar preguntas de seguridad',
    SELECT_SECURITY_QUESTIONS_INFO: 'Seleccionar preguntas de seguridad es otra forma de proteger tu cuenta. Cuando olvides tu contraseña, puedes responder a las preguntas de seguridad para restablecerla.',
    THANK_YOU: 'Gracias por solicitar la recuperación de contraseña. Pronto recibirás un correo electrónico con un enlace seguro para restablecer tu contraseña.'
  },
  RECURRENCE: 'Recurrencia',
  RECURRENCE_DATE: 'Fecha de recorrurrencia',
  RECURRENCE_OUTAGES: 'Outages recurrentes',
  RECURRENCE_STOP: 'Excluir recurrencia',
  RECURRENCE_TYPE: {
    DAILY: 'Diario',
    MONTHLY: 'Mensual',
    NO_RECURRENCE: 'Sin recurrencia',
    QUARTERLY: 'Trimestral',
    SEMESTER: 'Semestral',
    WEEKLY: 'Semanal',
    YEARLY: 'Anual'
  },
  REDEFINE_PASS: 'Restablecer contraseña',
  REDO_RISK_ANALYSIS: 'Rehacer análisis de riesgo',
  REFERENCE_MONTH: 'Mes de referencia',
  REFERENCE_YEAR: 'Año de referencia',
  REFRESH_PAGE: 'Actualizar Página',
  REFUSE: 'Rechazar',
  REFUSED: 'Rechazado',
  REGISTER: 'Registro',
  REGISTER_ACTIVITIES: 'Registro de actividades',
  REGISTER_ACTIVITIES_GROUP: 'Inscripción de grupos de actividades',
  REGISTER_ACTIVITIES_RISK: 'Es necesario registrar al menos una (actividad y RollBack), y un riesgo',
  REGISTER_BUILD: 'Registrar Construcción',
  REGISTER_BUSINESS: 'Registrar área de negocio',
  REGISTER_COMPANY_LOGO: 'Registrar logotipo de la empresa',
  REGISTER_DATA: 'Datos del Registro',
  REGISTER_EVOLUTION: 'Registro Evolución',
  REGISTER_HOLIDAYS: 'Registrar vacaciones',
  REGISTER_HOUR: 'Tiempo de registro',
  REGISTER_ITEM_FOR_BUILD: 'Registrar ítem para Construcción',
  REGISTER_LOCATION_USER: 'En el registro de usuarios solo informar la ubicación',
  REGISTER_NEW_MILESTONE: 'Nuevo Grupo de Actividades',
  REGISTER_NEW_USER: 'Crear nuevo usuario',
  REGISTER_OS: 'Registro OS',
  REGISTER_PHASE: 'Pasos del registro',
  REGISTER_PHASES: 'Registro de etapas',
  REGISTER_PROJECT: 'Registrar proyecto',
  REGISTER_PROJECT_OR_OS: 'Registrar proyecto o SO',
  REGISTER_SAVE: '¡Registro guardado correctamente!',
  REGISTER_SERVICE_ORDER: 'Registrar Nueva Orden de Servicio',
  REGISTER_STATUS_ALLOWANCE: 'Registrar el estado de los tickets a remitir',
  REGISTER_SUB_ACTIVITIES: 'Registrar Sub-Actividad',
  REGISTER_TAG: 'Registrar Tag',
  REGISTER_TECHS: 'Registros de Técnicos',
  REGISTER_THIRD_LEVEL_CATEGORY: 'Registrar Categoría de Tercer Nivel',
  REGISTERED_USER: 'Usuario registrado',
  REGISTRATION: 'Registro',
  REGISTRATION_DATE: 'Fecha de registro',
  REGISTRATIONS: 'Registros',
  REJECT: 'Rechazar',
  REJECT_SELECTED: 'RECHAZAR SELECCIONADO',
  REJECTED: 'Desaprobado',
  REJECTED_THE_GMUD: 'Rechazado el Cambio',
  RELATION_TICKETS: 'Tipo de relación',
  RELATIONS: 'Relacionamentos',
  RELATIONSHIP: 'Relación',
  RELATIONSHIP_WITH: 'Relación con',
  RELEASE_DATE_OF: 'Fecha de Publicación',
  RELOAD_ALL: 'Recargar todo',
  REMOTE: 'Remoto',
  REMOTE_ACCESS: 'Acceso remoto',
  REMOVE: 'Borrar',
  REOPEN: 'Vuelva a abrir',
  REOPEN_COUNT: 'Cantidad Reapertura',
  REOPEN_TICKET: 'Reabrir el Ticket',
  REOPENED: 'Reabierto',
  REOPENS_COUNT: 'Recuento de reapertura',
  REPORT_AT_LEAST_ONE_EVENT_TO_THE_CAB: 'Reportar al menos un evento al CAB',
  REPORT_EVIDENCE: 'Informe Pruebas',
  REPORT_OR_COST_PROVIDED_FOR_THE_REALIZATION_OF_THIS_PROJECT: 'Informe o costo proporcionado para la realización de este proyecto',
  REPORT_OR_NAME: 'Informe o nombre',
  REPORT_OR_VALUE_CONSUMED: 'Introduce la cantidad consumida',
  REPORT_THE_TIME_SPENT: 'Imforme del tiempo empleado',
  REPORT_WHAT_IT_WILL_BE_AT_THE_END_OF_THIS_MOVE: 'Informar lo que será al final de este movimiento',
  REPORT_WHAT_IT_WILL_BE_AT_THE_START_DATE_OF_THIS_MOVE: 'Informar lo que será en la fecha de inicio de este movimiento',
  REPORTING_CALL_ANALYSIS: 'Análisis de informe de llamadas',
  REPORTS: 'Informes',
  REPORTS_AUDIT: 'Informes - Auditoría',
  REPORTS_CONTRACT_ANL: 'Informes - Resumen del contrato - Análitico',
  REPORTS_CONTRACT_SINT: 'Informes - Resumen del contrato - Síntesis',
  REPORTS_DEVICE: 'Informes - Dispositivos',
  REPORTS_FOR_TECH: 'Informes Técnicos',
  REPORTS_FOR_TECH_GROUP: 'Informes de los técnicos',
  REPORTS_TICKETS: 'Informes - Tickets',
  REPORTS_TICKETS_OS: 'Informes - Ticket y OS',
  REPRESENTATIVE: 'Representante',
  REPROCESS_SLA: 'Reprocesar SLA',
  REQUEST: 'Requerimiento',
  REQUEST_ATTACHMENTS: 'Anexos de la solicitud',
  REQUEST_DESCRIPTION: 'Solicitar descripción',
  REQUEST_DETAIL: 'Detalles de Solicitud',
  REQUEST_INFO: 'Información de Solicitud',
  REQUEST_MANAGEMENT: 'Gestión de Solicitud',
  REQUEST_OPENED_SUCCESSFULLY: 'Solicitud abierta con éxito',
  REQUEST_RESOLUTION: 'Resolución de Solicitud',
  REQUEST_TEMPLATE: 'Plantilla de aplicación',
  REQUEST_TIME: 'Tiempo de Respuesta',
  REQUEST_TITLE: 'Título de solicitud',
  REQUESTED_APPROVAL: 'Llamada en Aprobación',
  REQUESTER: 'Solicitante',
  REQUESTER_DESCRIPTION: 'Quién hace las peticiones por algún problema interno/externo.',
  REQUESTER_GROUP: 'Grupo de Solicitantes',
  REQUESTER_LIST: 'Lista de Solicitud',
  REQUESTER_SERVICE_DESK: 'Solicitudes del Servicio de Atención al Cliente',
  REQUESTER_TIME: 'Hora de Solicitud',
  REQUESTER_TYPE: 'Tipo de Solicitud',
  REQUESTERS: 'Solicitudes',
  REQUESTERS_LIST: 'Lista de Solicitudes',
  REQUESTERSDASH: 'Categorización',
  REQUESTING_TECHNICIAN: 'Técnico Solicitante',
  REQUESTS: 'Solicitudes',
  REQUESTS_TO_APPROVE: 'Solicitudes para aprobar',
  REQUIRED: 'Obligatorio',
  REQUIRED_FIELDS: 'Campos obligatorios',
  REQUIRES_APPROVAL_EVERYONE: 'Requiere la aprobación de todos',
  RESCHEDULE: 'Reagendar',
  RESET: 'Restablecer',
  RESOLUTION: 'Resolución',
  RESOLUTION_CATEGORY: 'Categorías de resolución',
  RESOLUTION_CODE: 'Codigos de resolución',
  RESOLUTION_DESCRIPTION: 'Descripción de Resolución',
  RESOLUTION_TIME: 'Tiempo de Resolución',
  RESOLVE: 'Resolver',
  RESOLVED: 'Resuelto',
  RESOLVED_AND_FINISHED: 'Solucionado y terminado',
  RESPONSE_TIME: 'Tiempo de Respuesta',
  RESPONSIBLE: 'Responsable',
  RESPONSIBLE_DEPARTMENT: 'Departamento responsable',
  RESPONSIBLE_FOR_APPROVAL: '*Responsable de la Aprobación',
  RESPONSIBLE_FOR_EXECUTION_THE_TASK: 'Responsable de la ejecución de la tarea',
  RESPONSIBLE_FOR_TRATMENT: 'Responsable Del Tratamiento',
  RESPONSIBLE_GROUP: 'Grupo Responsable',
  RESPONSIBLE_NOT_REGISTERED: 'Funcionario no registrado acceda a configuraciones -> listado de empresas -> agregar departamento -> elejir jefe de departamento',
  RESPONSIBLES: 'Responsables',
  RESULT: 'Resultado',
  RESUME_CALL: 'Reanudar la llamada',
  RESUME_TICKET: 'Resumen Ticket',
  RETROACTIVE_BREAK: 'Pausa retroactiva',
  RETROSPECTIVE_START: 'Inicio Retroativo',
  RETURN: 'Devolución',
  RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_ATTACHMENT: 'Devolver la llamada al servicio si el solicitante agrega un adjunto',
  RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_CHAT: 'Devolver la llamada al servicio si el solicitante interactúa en el chat',
  RETURN_PLAN: 'Plan de devolución',
  REVERT: 'Revertir',
  REVIEW: 'Revisión',
  REVIEW_PERIOD: 'Período de revisión',
  REVISED: 'Revisado',
  REVISION_DATE: 'Fecha de revisión',
  REVISION_DAY: 'Día de revisión',
  REVISION_REQUIRED: 'Revisión requerida',
  REVISIONS_HISTORY: 'Historial de revisiones',
  RISK: 'Riesgo',
  RISK_AND_COMPLIANCE_MANAGEMENT: 'Gestión de riesgos y cumplimiento',
  RISK_CHANGE: 'Riesgos del Cambio',
  RISK_DESCRIPTION: 'Descripción de Riesgo',
  RISK_ENVIRONMENTAL: 'Riesgo medioambiental',
  RISK_IF_NOT_DONE: 'Riesgo si no se realiza',
  RISK_M_ANALYSIS: 'Análisis de gestión de riesgos',
  RISK_MATRIX: 'Matriz de riesgos',
  RISK_MONITORING: 'Supervisión de riesgos',
  RISK_NAME: 'Nombre del riesgo',
  RISK_TYPE: 'Tipo de riesgo',
  RISKC: {
    AC: 'Bajo',
    CRITICAL: 'Medio',
    IN: 'Alto'
  },
  RISKS: 'Riesgos',
  RISKS_AND_COMPLIANCE: 'Riesgo y Cumplimiento',
  RISKS_INVOLVED: 'Riesgos asociados',
  RISKS_MANAGEMENT: 'Gestión de Riesgos',
  RISKS_MANAGEMENT_COMPLIANCE: 'Gestión de Riesgos y Cumplimiento',
  RISKS_NO_TEST: 'No se han realizado pruebas a esta empresa',
  RISKSS: 'Riesgos',
  RISKY: 'RIESGOSO',
  ROLE: 'Nivel',
  ROLLBACK: 'Rollback',
  ROLLBACK_PENDING_ACTIVITIES: 'Hay actividades de rollback pendientes',
  ROOT_PREVIOUS_LEVEL: 'Raiz / Nível Anterior',
  ROW: 'Filas',
  ROW_LIST: 'Lista de Filas',
  ROW_SIZE_TABLE: 'Líneas por Página:',
  ROWS_PER_PAGE: 'Líneas por página',
  RULE: 'Regla',
  RULE_SERVICE_LEVEL: 'Regla de nivel de servicio',
  RUN_REMOTE_ACCESS: 'Realizar Acceso Remoto',
  RUNNING: 'En ejecución',
  RUNNING_SPRINTS: 'Sprints corriendo',
  RUNTIME: 'Tiempo de ejecución',
  SAFETY_PILAR: 'Pilar de Seguridad',
  SAMPLE_SERVICES: 'Servicios de muestra',
  SAT: 'Grado de Satisfacción sobre entrega',
  SATISFACTION: {
    BAD: 'Malo',
    GOOD: 'Bueno',
    GREAT: 'Execelente',
    UNSATISFIED: 'Insatisfecho'
  },
  SAVE: 'Guardar',
  SAVE_CHANGES: 'Guardar cambios',
  SAVE_FORM: 'Guardar Formulario',
  SCALE: 'Escala',
  SCHEDULE: 'Tarifa',
  SCHEDULING: 'Programación',
  SCHEDULING_SINGLE: 'Horario único',
  SCM: 'SCM',
  SCOPE: 'Alcance del Proyecto',
  SCRIPT: "Guión",
  SCRIPT: "Guión",
  SEARCH: 'Buscar',
  SEARCH_BY_CALL: 'Buscar por llamada',
  SEARCH_BY_CATEGORY: 'Buscar por categoría',
  SEARCH_BY_ID: 'Buscar por ID',
  SEARCH_FOR: 'Buscar',
  SEARCH_FOR_DEVICE_OR_SERVICES: 'Buscar por dispositivo o servicios',
  SEARCH_GROUP: 'Grupo de Búsqueda',
  SEARCH_THE_CATALOG: 'Buscar en el catálogo',
  SECONDS: ' Segundos',
  SECTION_OF_THE_STANDARD: 'Sección de la norma',
  SECURITY_HR: 'Seguridad de RRHH',
  SECURITY_ORGANIZATION: 'Organización de Seguridad',
  SECURITY_POLICY: 'Política de Seguridad',
  SEE_ACTIVITIES: 'Ver actividades',
  SEE_FINALIZATION: 'Ver Finalización',
  SEE_PERCENTAGE_COLOR: 'Ver el porcentaje por estado según el color',
  SEE_RPI: 'Ver RPI',
  SEGMENT: 'Segmento',
  SEGMENTATION_OF_THE_OPERATING_SYSTEM: 'SEGMENTACIÓN DEL SISTEMA OPERATIVO',
  SELECT: 'Seleccione',
  SELECT_A_CHANNEL: 'Seleccione un canal',
  SELECT_A_CONTRACT: 'Seleccione un contrato!',
  SELECT_A_DEPARTMENT: 'Seleccione un departamento',
  SELECT_A_FILE: 'Seleccione un archivo',
  SELECT_A_MAIN_CATEGORY: 'Seleccione una categoría principal',
  SELECT_A_PROJECT_TYPE: 'Seleccione un tipo de proyecto',
  SELECT_A_RISK_TYPE: 'Seleccione un tipo de riesgo',
  SELECT_A_SUBCATEGORY: 'Selecciona una sub-categoría',
  SELECT_A_THIRD_LEVEL_CATEGORY: 'Seleccione una categoría de tercer nivel',
  SELECT_ACTIVITIES: 'Seleccionar Actividades',
  SELECT_ACTIVITY: 'Seleccionar Actividad',
  SELECT_ALL: 'Seleccionar Todo',
  SELECT_ALL_AREAS_THAT_ARE_INVOLVED_IN_THIS_CHANGE: 'Seleccione todas las áreas que están involucradas en este cambio',
  SELECT_ALL_THE_AREAS_THAT_ARE_INVOLVED_IN_THIS_MOVE: 'Seleccione todas las áreas que están involucradas en este movimiento',
  SELECT_ALL_THE_AREAS_THAT_WILL_BE_BENEFITED_BY_THIS_PROJECT: 'Seleccione todas las áreas que serán beneficiadas por este proyecto',
  SELECT_APPROVERS: 'Seleccionar aprobadores',
  SELECT_COMPANY: 'Seleccione una Empresa',
  SELECT_COMPANY_CONTRACT: 'Seleccione la empresa y el contrato para generar el informe',
  SELECT_COMPANY_STANDARD_REPORT: 'Seleccione la empresa, la norma y elija las fechas para generar el informe',
  SELECT_DEVICE: 'Seleccione el Dispositivo',
  SELECT_FILE: 'Seleccione la imagen',
  SELECT_FORM: 'Seleccionar formulario',
  SELECT_FORM_ASSOCIATED_WITH_GMUD: 'Seleccionar formulario asociado al cambio',
  SELECT_GMUD_APPROVER_GROUPS: 'Seleccione cambiar Grupos de aprobadores',
  SELECT_HOW_MANY_POINTS_WILL_BE_AWARDED_TO_THIS_PROJECT: 'Seleccione cuántos puntos se otorgarán a este proyecto',
  SELECT_IMAGE: 'Seleccione una imagen',
  SELECT_ONE_BELOW: 'Seleccione uno a continuación',
  SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_CHANGE: 'Seleccione una de las acciones a continuación para determinar el tipo de cambio',
  SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_PROJECT: 'Seleccione una de las acciones a continuación para determinar el tipo de proyecto.',
  SELECT_ONE_OR_MORE_TICKETS_TO_LINK_TO_GMUD: 'Seleccione uno o más boletos para vincular para cambiar',
  SELECT_OR_DEPARTMENT: 'Seleccionar o Departamento',
  SELECT_OR_FILTER_TYPE: 'Seleccionar o filtrar tipo',
  SELECT_PRIORIZATION_MAKING_CHANGE: 'Seleccione la prioridad para realizar este cambio.',
  SELECT_PROJECT: 'Seleccione el Proyecto',
  SELECT_PROJECT_TO_SAVE_DUPLICATE_ACTIVITY: 'Seleccione el proyecto para guardar la actividad duplicada',
  SELECT_REQUESTER: 'Seleccionar solicitante',
  SELECT_SUPPLIER: 'Seleccionar proveedor',
  SELECT_TEMPLATE: 'Seleccione una plantilla',
  SELECT_THE_AREA_INVOLVED_IN_GMUD: 'Seleccione el Área involucrada en el cambio',
  SELECT_THE_CI_CONFIGURATION_ITEM_INVOLVED_IN_THE_GMUD: 'Seleccione el CI (elemento de configuración) involucrado en el cambio',
  SELECT_THE_COMPANY_INVOLVED_IN_THE_PROJECT: 'Seleccione la empresa involucrada en el proyecto',
  SELECT_THE_COMPANY_THAT_WILL_RECEIVE_THE_SERVICE: 'Seleccione la empresa que recibirá el servicio',
  SELECT_THE_DAY: 'Seleccione el día',
  SELECT_THE_DEVICES_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Seleccione los dispositivos que se asociarán con este cambio',
  SELECT_THE_FILES: 'Seleccione los archivos',
  SELECT_THE_MANAGER_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Seleccione el Administrador que se asociará con este cambio',
  SELECT_THE_MANAGER_WHO_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT: 'Seleccione el Gerente que se asociará con este proyecto',
  SELECT_THE_PREDECESSOR_ACTIVITY_FOR_THIS_ACTIVITY: 'Seleccione la actividad predecesora para esta actividad',
  SELECT_THE_PRIORITY_FOR_THE_REALIZATION_OF_THE_PROJECT: 'Seleccione la prioridad para la realización del proyecto',
  SELECT_THE_REQUESTER_OF_THIS_CHANGE: 'Seleccione el solicitante de este cambio',
  SELECT_THE_RISK_FOR_THE_REALIZATION_OF_THE_PROJECT_LOW_MEDIUM_HIGH: 'Seleccione el riesgo para la realización del proyecto (Bajo, Medio, Alto)',
  SELECT_THE_SERVICES_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Seleccione los servicios que se asociarán con este cambio',
  SELECT_THE_TECHNICAL_GROUPS_INVOLVED_IN_THE_GMUD: 'Seleccione los Grupos Técnicos involucrados en el cambio',
  SELECT_THE_TECHNICIAN_WHO_WILL_BE_RESPONSIBLE_FOR_THE_APPROVAL: 'Seleccione el Técnico que será responsable de la aprobación',
  SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_CHANGE: 'Seleccione los técnicos que estarán involucrados en este cambio.',
  SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_PROJECT: 'Seleccione los técnicos que participarán en este proyecto',
  SELECT_THE_TICKETS_THAT_YOU_WANT_TO_ASSOCIATE_WITH_THIS_MOVE: 'Seleccione los boletos que desea asociar a este movimiento',
  SELECT_THE_TICKETS_YOU_WANT_TO_ASSOCIATE_WITH_THIS_CHANGE: 'Seleccione los tickets que desea asociar con este cambio',
  SELECT_TICKET_TYPE: 'Seleccione el tipo de ticket',
  SELECT_USER: 'Seleccione el Usuario',
  SELECT_USERS_INVOLVED_IN_THIS_PROJECT: 'Seleccionar usuarios involucrados en este proyecto',
  SELECT_WEIGHT_ACTIVITY: 'Seleccione el peso de esta actividad.',
  SELECT_WHICH_CONTRACT_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT: 'Seleccione qué contrato se asociará con este proyecto',
  SELECTED_TECHNICAL_GROUP_WILL_APPROVE_THE_GMUD: 'El grupo técnico seleccionado aprobará el cambio',
  SELF_SERVICE_PORTAL: 'Portal de autoservicio',
  SELFSERVICE: 'Autoservicio',
  SEMESTER: 'Semestre',
  SEND: 'Enviar',
  SEND_EMAIL_TO_RESPONSIBLE_PERSONS: 'Enviar correo electrónico a las personas responsables',
  SEND_REASON_TO_CHAT: 'Enviar motivo al chat',
  SEND_REPLY: 'Enviar Respuesta',
  SEND_THE_ANSWERS: '¿Desea Enviar las Respuestas ?',
  SERIAL: 'Serie',
  SERIAL_NUMBER: 'Número de serie',
  SERIAL_SO: 'Licencia de Control en serie',
  SERVER: 'Servidor',
  SERVER_NAME: 'Nombre del Servidor',
  SERVER_REGISTRATION: 'Registro del Servidor',
  SERVERS: 'Servidores',
  SERVICE: 'Servicios',
  SERVICE_CATALOG: 'Subcategoría',
  SERVICE_CATALOG_DESCRIPTION: 'Se trata de un documento consistente en la descripción de servicios previamente analizados y aprobados.',
  SERVICE_DESK: 'Gestión del Servicio Atención al Cliente',
  SERVICE_DISCOVERY: 'Detección de servicios',
  SERVICE_INFORMATION: 'Información de Servicio',
  SERVICE_LIST: 'Lista de Servicios',
  SERVICE_NAME: 'Nombre del Servicio',
  SERVICE_ORDER: 'Orden de Servicio',
  SERVICE_ORDER_DETAIL: 'Detalles de la Orden de Servicio',
  SERVICE_ORDER_LIST: 'Lista de Orden de Servicio',
  SERVICE_ORDERS: 'Ordenes de Servicio',
  SERVICE_PORTAL: {
    CATALOG: 'Catalogar',
    CATEGORY: 'Categoría',
    COMPANY: 'Empresa',
    FORM_FIELD_ACTION_BUTTON_BG_COLOR: 'Color de fondo',
    FORM_FIELD_ACTION_BUTTON_COLOR: 'Color de fuente',
    FORM_FIELD_BASE_BUTTON_BG_COLOR: 'Color de fondo',
    FORM_FIELD_BASE_BUTTON_COLOR: 'Color de fuente',
    FORM_FIELD_BRAND_LEFT: 'Distancia desde la izquierda',
    SERVICE_PORTAL: 'Portal de Servicios',
    FORM_GROUP_LAYOUT: 'Layout',
    FORM_FIELD_LAYOUT_FLUID: 'Layout Fluído',
    FORM_GROUP_BRAND: 'Logo',
    FORM_FIELD_BRAND_LOGO: 'Logo',
    FORM_FIELD_BRAND_PREVIEW: 'Visualización',
    FORM_FIELD_BRAND_TOP: 'Distancia desde arriba',
    FORM_FIELD_CATALOG_BG_COLOR: 'Color de fondo',
    FORM_FIELD_CATALOG_COLOR: 'Color de fuente',
    FORM_FIELD_CATALOG_TITLE_COLOR: 'Color de fuente del título',
    FORM_FIELD_CATALOG_TITLE_SIZE: 'Tamaño de fuente del título',
    FORM_FIELD_CSS_TEXT: 'Texto CSS',
    FORM_FIELD_HEADER_BG_COLOR: 'Color de fondo',
    FORM_FIELD_HEADER_BG_IMG: 'Imagen de fondo',
    FORM_FIELD_HEADER_BG_PREVIEW: 'Visualización',
    FORM_FIELD_HEADER_COLOR: 'Color de fuente',
    FORM_FIELD_HEADER_COLOR_HOVER: 'Color de fuente (hover)',
    FORM_FIELD_HOMEPAGE_BG_COLOR: 'Color de fondo',
    FORM_FIELD_HOMEPAGE_BG_IMG: 'Imagen de fondo',
    FORM_FIELD_HOMEPAGE_BG_PREVIEW: 'Visualización',
    FORM_FIELD_HOMEPAGE_COLOR: 'Color de fuente',
    FORM_FIELD_IVI_CODE: 'Script IVI (Chat Bot)',
    FORM_FIELD_LAYOUT_FLUID: 'Layout Fluído',
    FORM_FIELD_SUBHEADER_BG_COLOR: 'Color de fondo',
    FORM_FIELD_SUBHEADER_COLOR: 'Color de fuente',
    FORM_GROUP_ACTION_BUTTON: 'Botones de acción',
    FORM_GROUP_BASE_BUTTON: 'Botones',
    FORM_GROUP_BRAND: 'Logo',
    FORM_GROUP_CATALOG: 'Catálogo de Servicio',
    FORM_GROUP_CSS: 'CSS personalizado',
    FORM_GROUP_HEADER: 'Header',
    FORM_GROUP_HOMEPAGE: 'Pagina principal',
    FORM_GROUP_LAYOUT: 'Layout',
    FORM_GROUP_MENU: 'Configuración del menú',
    FORM_GROUP_SUBHEADER: 'Sub Header',
    FORM_GROUP_TAB: 'Configuración de pestañas',
    IN_CATEGORIZATION: 'En Categorización',
    MENU_APPROVAL_CENTER: 'Mis aprobaciones',
    MENU_CHANGE_PASSWORD: 'Restablecer/desbloquear contraseña',
    MENU_EXCLAMATION: 'Para cambiar el menú, guarde la personalización.',
    MENU_FAQ: 'Preguntas frecuentes',
    MENU_HOME: 'Página de inicio',
    MENU_SEARCH: 'Buscar',
    MENU_TICKET: 'Mis solicitudes',
    MOST_USED: 'Servicios más utilizados',
    PAGE_FILTERS_NOT_FOUND: 'No hay registros para el filtro seleccionado',
    PAGE_NOT_RESULTS_FOUND: 'No se han encontrado resultados.',
    PAGE_SETTINGS_NOT_FOUND: 'Configuración no encontrada.',
    PAGE_SETTINGS_NOT_FOUND_DETAILS: 'Lo sentimos, no pudimos encontrar los detalles de la empresa asociada con su cuenta. Póngase en contacto con el soporte para obtener ayuda.',
    SELECT: 'Seleccionar',
    SELECT_CATEGORY: 'Seleccione una categoría',
    SELECT_SUBCATEGORY: 'Selecciona una sub-categoría',
    SELECT_TASK: 'Seleccione una tarea',
    SUBCATEGORY: 'Subcategoría',
    TAB_CONFIGURATION_ITEMS: 'Pestaña de elementos de configuración',
    TAB_DESIGNATION_HISTORY: 'Pestaña de historial de designaciones',
    TAB_DETAIL: 'Pestaña detalles',
    TAB_RELATION_TYPE: 'Pestaña de tipo de relación',
    TAB_SLA_HISTORY: 'Pestaña de historial de sla',
    TASK: 'Tarea'
  },
  SERVICE_SLA: 'SLA de Servicio',
  SERVICE_TABLE: 'Tabla de servicio',
  SERVICE_TECH: 'Técnico del servicio',
  SERVICE_TIME: 'Tiempo de asistencia',
  SERVICE_TIME_DETAIL: 'Datos de asistencia',
  SERVICES: 'Servicios',
  SETTINGS_NOT_FOUND: 'Configuración no encontrada',
  SEVERITY: 'Gravedad',
  SHOW: 'Mostrar',
  SHOW_IC_COMPANY: 'Mostrar CI de todas las empresas en el registro de dispositivos',
  SHOW_INTERATION_TICKET_CHANGE: 'Habilitar la opción para cada Empresa de cambiar el estado a "En Proceso" cuando el solicitante interactúa.',
  SHOW_MORE: 'Mostrar más',
  SIDE_DISH: 'Seguimiento',
  SIDE_DISH_CALENDARY: 'Calendario de Seguimiento',
  SIGN_IN: 'Iniciar sesión',
  SITUATION: 'Situación',
  SIZE: 'Tamaño',
  SIZE_DISK: 'Tamaño del disco',
  SLA: 'SLA',
  SLA_ACTIVE_CATEGORIZATION: 'Sla activa en categorización',
  SLA_AGREED: 'SLA acordado',
  SLA_ATTACH: "Agregar SLA'S",
  SLA_ATTACH_WARNING: "Todos los SLA'S disponibles ya están adjuntos a este contrato",
  SLA_BROKEN_OUT: 'SLA Quebrado',
  SLA_CONDITION: 'Condiciones del SLA',
  SLA_DATE: 'Fecha del SLA',
  SLA_FIXED: 'Sla Fijo',
  SLA_GLOBAL_LIST_ERROR: 'Lista global de SLA de tipo de inicio no encontrada',
  SLA_HOLIDAY: 'SLA Feriados',
  SLA_LIST: 'Lista de SLAs',
  SLA_MANAGEMENT: 'Gestión de SLA',
  SLA_NAME: 'Nombre del SLA',
  SLA_PARAM_NOT_FOUND: 'Parámetro de SLA no encontrado',
  SLA_PARAMS: 'Parametrización del SLA',
  SLA_POLICY: 'Políticas de SLA',
  SLA_POLICY_DESCRIPTION: 'Un conjunto de condiciones que debe cumplir un ticket para que se aplique la política de SLA.',
  SLA_PROBLEM_STEP_ANALYSIS: 'SLA para análisis de efectividad',
  SLA_PROBLEM_STEP_CORRECTION: 'SLA para corrección',
  SLA_PROBLEM_STEP_RESOLUTION: 'SLA para resolución',
  SLA_PROBLEM_STEP_ROOT_CAUSE: 'SLA para análisis de causa raíz',
  SLA_PROBLEM_STEPS: 'SLA para pasos problemáticos',
  SLA_PROGRESS_GRID: 'Progreso',
  SLA_REGISTRATION: 'Registro de SLA',
  SLA_REPROCESSING: 'Reprocesamiento de SLA',
  SLA_REPROCESSING_DESCRIPTION: 'Solicitar reprocesamiento de SLA',
  SLA_RULE_INCOMPATIBLE: 'Regla de SLA incompatible con la empresa',
  SLA_RULES_NO_MATCH: 'No hay reglas de SLA compatibles',
  SLA_STATUS: {
    DELAYED: 'Atrasado',
    IN_TIME: 'A Tiempo'
  },
  SLA_TIME: 'Tiempo del SLA',
  SLA_TYPE: 'Tipo de SLA',
  SLA_WORKDAY: 'Jornada Laboral del SLA',
  SMS: 'Email/Llamar',
  SMTP_PORT: 'Puerto smtp',
  SMTP_SELECTED_SUCCESSFULLY: 'SMTP seleccionado exitosamente',
  SNMP: 'SNMP',
  SNMP_LIST: 'Lista de SNMPs',
  SNMP_REGISTRATION: 'Registro de SNMP',
  SO_BUILD_NUMBER: 'Número de orden de servicio',
  SO_CAPTION: 'Lectura de orden de servicio',
  SO_VERSION: 'Versión de orden de servicio',
  SOFTWARE: 'Software',
  SOFTWARE_ASSETS: 'Activos de software',
  SOFTWARE_LIST: 'Lista de Programas',
  SOLICITACAO: 'Solicitudes',
  SOLICITANT: 'Solicitante',
  SOLICITATION_FORM: 'Formulario de inscripción',
  SOLICITATION_DATE: 'Fecha de solicitud', 
  SOLICITATION_HOUR: 'Solicitar hora', 
  SOLUTION: 'Soluciones',
  SOLUTION_DETAIL: 'Detalles de la Solución',
  SOLUTION_DEVELOPMENT: 'Desarrollo de solución',
  SOLUTION_TIME: 'Tiempo de Solución',
  SOLVED: 'Resuelto',
  SPEAK: 'HABLAR',
  SPENT: 'Gasto',
  SPONSOR: 'Responsable',
  SPONSOR_DESCRIPTION: 'Registrar al Responsable del Contrato',
  SPONSOR_LIST: 'Lista de responsables',
  SPRINT: 'Sprint',
  SPRINT_ACTIVITY_EFFORT: 'Esfuerzo de las actividades de Sprint',
  SPRINT_EFFORT: 'Esfuerzo de Sprint',
  SPRINT_NAME: 'Nombre del sprint',
  SPRINTS_CREATED: 'Sprints creados',
  SQUAD: 'Squad',
  SRD: 'SRD',
  SSH: 'SSH',
  SSH_KEY: 'Clave SSH',
  STABLES: 'Estable',
  STAGE_NAME: 'Nombre de Etapa',
  STAGE_RESPONSIBLE: 'Resp. Etapa',
  STAGE_TOTAL_TIME: 'Tempo Total Etapa',
  STANDARD: 'Estándar',
  STANDARD_DETAIL: 'Detalle estándar',
  STANDARDS: 'Estandards',
  START: 'Inicio',
  START_: 'Inicio',
  START_ASSESSMENT: 'Iniciar Soporte',
  START_ATTENDANCE: 'Iniciar Asistencia',
  START_DATE: 'Fecha de inicio',
  START_DATE_AND_TIME: 'Fecha y hora de inicio',
  START_DATE_CANNOT_BE_GREATER_THAN_END_DATE: 'La fecha de inicio no puede ser posterior a la fecha de finalización',
  START_DATE_TIME: 'Fecha de inicio del servicio',
  START_DONE: 'Comenzar Listo',
  START_HOUR: 'Hora de inicio',
  START_MODE: 'Modo de inicio',
  START_NAME: 'Nombre inicial',
  START_OF_ACTIVITY: 'Inicio de la actividad',
  START_OF_THE_PROJECT: 'Inicio del proyecto',
  START_RECURRENCE: 'Inicio de recurrencia',
  START_WITH: 'Inicia com',
  STARTED: 'Iniciado',
  STATE: 'Estado',
  STATE_DEADLINE: 'Fecha límite de etapa',
  STATUS: 'Estados',
  STATUS_ACTIVITY: 'Estado de la Actividad',
  STATUS_CHANGE: 'Cambio de estado',
  STATUS_CHART_BY_DEVICE: 'Gráfico de estado por dispositivo',
  STATUS_CHART_BY_SERVICE: 'Gráfico de estado por servicio',
  STATUS_CHART_BY_TECH: 'Gráfico de estado por técnico',
  STATUS_LOG_ANALYZE: 'Analizando',
  STATUS_LOG_CAB: 'CAB',
  STATUS_LOG_CANCELLED: 'Cancelado',
  STATUS_LOG_CLOSED: 'Cerrado',
  STATUS_LOG_CONCLUDED: 'Concluido',
  STATUS_LOG_CONCLUDED_CANCEL: 'Terminado Cancelado',
  STATUS_LOG_CONCLUDED_FAIL: 'Fallido Completo',
  STATUS_LOG_CONCLUDED_PARTIAL: 'Parcialmente completado',
  STATUS_LOG_CORRETIVA: 'Corrección',
  STATUS_LOG_CREATE: 'Creado',
  STATUS_LOG_EMERGENCIA: 'Emergencia',
  STATUS_LOG_FINISH_CANCEL: 'Terminado Cancelado ',
  STATUS_LOG_FINISH_FAIL: 'Terminado con falla',
  STATUS_LOG_FINISH_PARTIAL: 'Parcialmente terminado',
  STATUS_LOG_FINISHED: 'Finalizado',
  STATUS_LOG_INCIDENT: 'Incidente',
  STATUS_LOG_LIBERATION: 'Liberar',
  STATUS_LOG_NORMAL: 'Normal',
  STATUS_LOG_OPEN: 'Abierto',
  STATUS_LOG_PADRAO: 'Estándar',
  STATUS_LOG_PROBLEM: 'Problema',
  STATUS_LOG_PROGRESS: 'En preparación',
  STATUS_LOG_REJECTED: 'Rechazado',
  STATUS_LOG_ROLLBACK: 'Retroceso',
  STATUS_LOG_SOLICITATION: 'Solicitar',
  STATUS_NOT_ALLOWED: 'Estado no permitido',
  STATUS_OF_PROJECT: 'Portafolio de proyectos',
  STATUS_OF_TASK: 'Estado de la tarea',
  STATUS_OF_TICKET: 'Estado del Ticket',
  STATUS_OF_WAITING: 'Estado de Espera',
  STATUS_SHOULD_SELECT_VENDOR: 'Este estado debe seleccionar un proveedor?',
  STATUS_SHOULD_SELECT_VENDOR_REQUIRED: 'Obrigar a informar um fornecedor',
  STATUS_TICKET_ALLOWANCE: 'Estado de ticket SLA para abonar',
  STATUS_WILL_AUTOMATICALLY_CLOSE_TICKET: 'Este estado cerrará automáticamente el ticket?',
  STATUS_WILL_STOP_SLA: 'Este estado detendrá el SLA',
  STEP: 'Etapa',
  STOCK: 'Existencias',
  STOCK_APPROVER: 'Aprobador',
  STOCK_DEPARTAMENT: 'Departamento',
  STOCK_DT: 'Fecha',
  STOCK_IN: 'Entrada de Producto',
  STOCK_IN_HIST: 'En movimiento',
  STOCK_INF_IN: 'Stock de Entrada',
  STOCK_INF_OUT: 'Stock de Salida',
  STOCK_LISTING: 'Listados de acciones',
  STOCK_MANAGEMENT: 'Gestión de acciones',
  STOCK_MODEL: 'Modelo',
  STOCK_NAME: 'Nombre',
  STOCK_OUT: 'Salida de Producto',
  STOCK_POLICY: 'Registro de stock',
  STOCK_QTDINPUT: 'Stock',
  STOCK_REASON: 'Motivo',
  STOCK_SPONSO: 'Responsable',
  STOCK_STOCK_ERROR: 'Valor superior a las existencias actuales',
  STOCK_TYPE: 'Tipo',
  STOCK_TYPE_INOUT: 'En movimiento',
  STOCK_USER: 'Reponsable',
  STOP_ATTENDANCE: 'Dejar de asistir',
  STOP_DATE: 'Fecha Límite',
  STOP_MONITORING: 'Dejar de monitorear',
  STRATEGIC: 'Estratégico',
  STRATEGIC_ALIGMENT: 'Alinación Estratégica',
  STRATEGIC_PARTNERS: 'Socios Estratégicos',
  STREET: 'Ruta',
  SUB_ACTIVITIES: 'Sub-Actividades',
  SUB_ACTIVITY_DELETED_SUCCESSFULLY: 'Sub-actividad eliminada con éxito',
  SUB_CALL: 'Sub-Llamada',
  SUB_CALLS: 'Sub-Llamadas',
  SUB_CATEGORY: 'Sub Categoria',
  SUB_INSTITUTION: 'Sub institución',
  SUBACTIVITIES: 'Subactividades',
  SUBCATEGORIES: 'Subcategorías',
  SUBCATEGORY: 'Subcategoria',
  SUBDEPARTMENT: 'Subdepartamento',
  SUBDEPARTMENT_BOSS: 'Jefe de Subdepartamento',
  SUBDEPARTMENT_DESCRIPTION: 'Un Subdepartamento es una división dentro de un departamento.',
  SUBDEPARTMENT_LIST: 'Lista de Subdepartamentos',
  SUBJECT: 'Asunto',
  SUBJECT_OF_THE_CALL: 'Asunto de la llamada',
  SUBJECTS_LIST: 'Lista de Asuntos',
  SUBJECTS_REGISTER: 'Registro de Asuntos',
  SUBMENU: 'Submenú',
  SUBSTITUTION: 'sustitución',
  SUCCESS: 'Exito',
  SUCCESS_PASSWORD: 'Contraseña creada correctamente',
  SUCCESSFULLY_DELETED_RESULT: 'Resultado eliminado con éxito',
  SUCCESSFULLY_UPDATED: 'Actualizado exitosamente',
  SUGGESTION: 'Sugerecia',
  SUGGESTIONS: 'Sugestões',
  SUPPORT_CONTACT: 'Horas de contrato diferenciadas',
  SURPRISE_WITH_THE_IMPROVEMENTS: 'Sorprende con las mejoras',
  SURVEILLANCE: 'Monitoreo',
  SWITCH_TO_DEFAULT: 'Cambiar a predeterminado',
  SWITCH_TO_NEW_SCREEN: 'Cambiar a nueva pantalla',
  SWITCH_TO_SUBCATEGORY: 'Cambiar a SubCategoría',
  SWITCH_TO_THIRD_LEVEL_CATEGORY: 'Cambiar a categorías de tercer nível',
  SYNTHETIC: 'Sintético',
  SYNTHETIC_DETAILS: 'Detalles sintéticos',
  SYSTEM: 'Sistema',
  SYSTEM_DEVELOPMENT_AND_MAINTENANCE: 'Desarrollo y Mantenimiento del Sistema',
  SYSTEM_MANUAL: 'Manual del Sistema',
  SYSTEM_VERSION: 'Versión del Sistema',
  TABLE: 'Tabla',
  TAG: 'Tags',
  TAG_DESCRIPTION: 'Los tags son palabras que sirven de etiqueta y ayudan a organizar la información.',
  TAG_LIST: 'Lista de Tags',
  TAG_NAME: 'Nombre de la tag',
  TAG_TAGNAME_DOES_NOT_BELONG_TO_EVENT: 'La etiqueta tagName no pertenece al evento',
  TARGET: 'Objetivo',
  TASK: 'Categoría de Tercer Nivel',
  TASK_CREATED_SUCCESSFULLY: 'Tarea creada con éxito',
  TASK_DESCRIPTION: 'Una tarea es una actividad o un trabajo que hay que realizar.',
  TASK_FLOW: 'Flujo de Tarea',
  TASK_FORM: 'Formulario de tareas',
  TASK_LIST: 'Lista de tareas',
  TASK_LIST_TEMP: 'Lista de Tarea',
  TASK_MANAGEMENT: 'Gestión de Tareas',
  TASK_NAME: 'Nombre de la Tarea',
  TASK_STATUS: {
    APPROVED: 'Aprobado',
    CANCELED: 'Cancelado',
    DONE: 'Completado',
    IN_PROGRESS: 'Progreso',
    PENDING: 'Pendiente de Aprobación',
    REJECTED: 'Rechazado',
    STARTED: 'Iniciado',
    WAITING: 'A la espera de'
  },
  TASK_TEMP: 'Formulario',
  TASK_TEMPLATE: 'Plantilla de tareas',
  TASK_TOTALS: 'Tareas totales',
  TASK2: 'Tarea',
  TASKS: 'Tareas',
  TASKS_LIST: 'Lista de plantillas',
  TASKS_PER_PERIOD: 'TAREAS POR PERIODO',
  TASKS_TO_APPROVE: 'Tareas para aprobar',
  TEAM_ALLOCATION: 'Asignación de equipo',
  TEAM_AVERAGE: 'Promedio del equipo',
  TECH: 'Técnico',
  TECH_ATTACH: 'Adjuntar datos técnicos',
  TECH_ATTACH_WARNING: 'Todos los técnicos disponibles ya están adscritos a este contrato',
  TECH_BY_HOURS: 'Técnicos por horas',
  TECH_BY_VALUE: 'Técnicos por valor',
  TECH_DESCRIPTION: 'Quién hace las peticiones por algún problema interno/externo.',
  TECH_DETAILS: 'Detalles técnicos',
  TECH_GROUP: 'Grupo Técnico',
  TECH_GROUP_COMPANY: 'Grupo tecnico de la empresa',
  TECH_HAS_ALREADY_CLOSED_TASK: 'El técnico ya ha cerrado esta tarea.',
  TECH_LIST: 'Lista de Técnicos',
  TECH_TABLE: 'Quadro de técnicos',
  TECH_TYPE: 'Tipo de técnico',
  TECHIN: 'El técnico ha finalizado las actividades de OS, a la espera de aprobación',
  TECHNICAL_ACCOUNT_MANAGER: 'Gerente de Cuenta Técnica',
  TECHNICAL_COST: 'Coste técnico',
  TECHNICAL_FORM: 'FORMULARIO TÉCNICO',
  TECHNICAL_GROUP_COUNT: 'Recuento del grupo técnico',
  TECHNICAL_GROUPS: 'Grupos Técnicos',
  TECHNICAL_NOTES: 'Notas Técnicas',
  TECHNICAL_REPORT: 'Relato técnico',
  TECHNICIAN_BY_VALUES_CONSUMED_TOTAL: 'Técnico por valores consumidos total',
  TECHNICIAN_PER_HOURS_CONSUMED_TOTAL: 'Técnico por  horas consumidas total',
  TECHS: 'Técnicos',
  TELEPHONE_EXTENSION: 'Extención',
  TEMPERATURE: 'Temperatura',
  TEMPLATE: 'Plantilla de solicitud',
  TEMPLATE_ADD_CATALOG: 'Agregar plantilla de solicitud  ',
  TEMPLATE_DESCRIPTION: 'Plantillas de solicitud de registro',
  TEMPLATE_S_CATALOG: 'Seleccione la plantilla de solicitud ',
  TEMPLATE_SUBJECT_CLOSE: 'Seleccione el Formulario de cierre',
  TEMPLATE_SUBJECT_OPEN: 'Seleccione el formulario de apertura',
  TEMPLATES: 'Planillas',
  TERM: 'Plazo',
  TERM_CHANGE: ' Término de cambio',
  TERM_CLOSURE: ' Término de cierre',
  TERM_OPENING: ' Término de apertura',
  TERMINAL: 'Terminal',
  TERMINATE_CONTRACT: 'Cerrar contrato',
  TEST: 'Prueba',
  TESTS: 'Pruebas',
  TESTS_PLAN: 'Plan de Pruebas',
  THE_JUSTIFICATION_FIELD_IS_REQUIRED_FOR_APPROVAL: 'El campo de justificación es obligatorio para su aprobación.',
  THE_JUSTIFICATION_FIELD_REQUIRED_FOR_APPROVAL: 'El campo de justificación es obligatorio para la aprobación.',
  THE_TECHNICIAN_NOT_ALLOWED_CATEGORIZE_CALLS_GROUP_THAT_DOES_NOT_BELONG: '¡El técnico no tiene permitido categorizar llamadas, del grupo técnico que no pertenece!',
  THE_TICKET_WILL_BE_CLOSED_DO_YOU_REALLY_WANT_TO_JOIN: 'El ticket se cerrará, ¿de verdad quieres unirte?',
  THEME: 'Tema',
  THEME_REGISTER: 'Registro de Tema',
  THERE_ARE_NO_RECORDS_TO_DELETE: 'No hay registros para eliminar',
  THERE_CHANGE_WORKFLOW: 'Hay cambios en el análisis con este flujo de trabajo.',
  THERE_IS_ONLY_ONE_RECORD_MODIFY_THE_CURRENT_ONE: 'Solo hay un registro, modifica el actual',
  THERE_WAS_AN_ERROR_SAVING_THE_FIELDS: 'Hubo un error al guardar los campos',
  THIRD_LEVEL: 'Tercer Nivel',
  THIRD_LEVEL_CATEGORIES: 'Categorías de tercer nivel',
  THIRD_LEVEL_CATEGORY: 'Categoría de tercer Nivel',
  THIRDED_LEVEL: '3° Nivel',
  THIS_ACTION_WILL_ERASE_ALL_PREVIOUS_RECORDS_TO_RE_ANALYZE_THE_RISKS_OF_THIS_CHANGE: 'Esta acción borrará todos los registros anteriores para volver a analizar los riesgos de este cambio',
  THIS_ACTIVITY_HAS_BEEN_COMPLETED_IT_CANNOT_BE_EDITED: 'Esta actividad ha sido completada, no se puede editar',
  THIS_ACTIVITY_RECORDED_AUDITED: 'Esta actividad es registrada y auditada',
  THIS_GMUD_WILL_OBLIGATORILY_GO_TO_THE_CAB_FOR_APPROVAL: 'Este Cambio pasará obligatoriamente al CAB para su aprobación. Por favor informe la fecha del CAB',
  THIS_MONTH: 'Este mes',
  THIS_TICKET_WAS_CREATED_USING_MULTI_TICKETS: 'Este boleto fue creado usando múltiples tickets',
  THREAT: 'Amenaza',
  THREE_HOURS: 'tres horas',
  TICKET: 'Ticket',
  TICKET_ALLOWANCE: 'Tickets Abonados',
  TICKET_AUTOMATION_REGISTRATION: 'Registro automático de ticket',
  TICKET_CONTAINS_RULES: 'Ticket tiene reglas',
  TICKET_DETAIL: 'Detalle del Ticket',
  TICKET_DETAILS: 'Detalles del Ticket',
  TICKET_DOES_NOT_CONTAIN_RULES: 'Ticket no tiene reglas',
  TICKET_LIST: 'Lista de Tickets',
  TICKET_MANAGEMENT: 'Gestión de Tickets',
  TICKET_NOT_FOUND: 'Ticket no encontrado',
  TICKET_OPEN_TODAY: 'Boleto abierto hoy',
  TICKET_PRIORITY: {
    CRITICAL: 'Crítico',
    HIGH: 'Alta',
    MEDIUM: 'Media',
    SMALL: 'Baja',
    URGENT: 'Urgente'
  },
  TICKET_RESOLUTION: {
    CANNOT_REPRODUCE: 'No se puede reproducir',
    DONE: 'Listo',
    DUPLICATE: 'Duplicado',
    FIXED: 'Solucionado',
    INCOMPLETE: 'Incompleto',
    NOT_RESOLVED: 'No Resueltos',
    RESOLVED: 'Resueltos',
    WONT_FIX: 'No lo solucionaremos'
  },
  TICKET_STATE_NOT_ALLOWED: 'Estado del ticket no permitido para reprocesamiento',
  TICKET_STATUS: {
    APPROVE: 'Aprobados',
    APPROVED: 'Aprobado',
    CANCELLED: 'Cancelado',
    CANCELLEDS: 'Cancelados',
    CLOSED: 'Cerrados',
    CLOSEDDETAIL: 'Cerrar',
    FINALIZED: 'Finalizados',
    IN_APPROVAL: 'En aprobación',
    IN_PROGRESS: 'En curso',
    NEW: 'Nuevos Tickets',
    OPEN: 'Abierto',
    OPENS: 'Abiertos',
    REJECTED: 'Rechazado',
    REOPEN: 'Reabiertos',
    REOPENED: 'Reabierto',
    RESOLVED: 'Resuelto',
    SOLVED: 'Resueltos',
    TASKS: 'Recurrentes',
    WAITING: 'En espera'
  },
  TICKET_SUBJECT: 'Asunto del ticket',
  TICKET_SUCCESSFULLY_EVALUATED: 'Llamada evaluada con éxito!',
  TICKET_TASKS: 'TAREAS DEL TICKET',
  TICKET_TELEMETRY: 'TELEMETRÍA DEL TICKET',
  TICKET_TIME: 'Hora del Ticket',
  TICKET_TITLE: 'TÍTULO DEL TICKET',
  TICKET_TYPE_LABEL: 'Tipo de Ticket',
  TICKET_TYPES: {
    CHANGE: 'Cambiar',
    CRISIS: 'Crisis',
    DOCUMENTED_CRISIS: 'Crisis Documentada',
    CRITICAL_INCIDENT: 'Incidente Crítico',
    EVENT: 'Evento',
    INCIDENT: 'Incidente',
    LIBERATION: 'Solicitud de liberación',
    PROBLEM: 'Problema',
    SOLICITATION: 'Requerimiento'
  },
  TICKET_VIEW: 'Vista de ticket',
  TICKET_WITHOUT_SLA: 'Tickets sin SLA',
  TICKET_WORKFLOW_OF_CHANGE: {
    ANALYZED: 'Analizado',
    DEVELOPED: 'Desarrollado',
    FINALIZED: 'Finalizado',
    IN_ANALYSIS: 'En Análisis',
    IN_DEVELOPMENT: 'En desarrollo',
    IN_TEST: 'En prueba',
    OPEN: 'Abierto',
    REJECTED: 'Rechazado',
    TESTED_WITH_ERRORS: 'Probado con errores',
    TESTED_WITHOUT_ERRORS: 'Probado sin errores'
  },
  TICKET_HISTORY: 'Historial de Ticket',
  TICKETCONHIST: 'Historial de Tickets',
  TICKETS: 'Tickets',
  TICKETS_CATEGORY_MONTH: 'Tickets por sub-categoría en el mes',
  TICKETS_STATUS: 'Estado de tickets',
  TICKETS_TECH_MONTH: 'Tickets por técnico en el mes',
  TICKETS_TO_CATEGORIZE: 'Tickets para categorizar',
  TIME: 'Horario',
  TIME_CONSUMED: 'Tiempo Consumido',
  TIME_DETAIL: 'Detalle horario',
  TIME_SPENT: 'Tiempo empleado',
  TIME_TO_RESOLUTION: 'Tiempo de resolución',
  TIME_TO_RESPONSE: 'Tiempo de Respuesta',
  TIME_TOTAL_STEP: 'Tiempo total de etapa',
  TIMEOUT_PRICE: 'Valor Hora Excedida',
  TIMEZONE: 'Zona horaria',
  TIN: 'CNPJ',
  TITLE: 'Título',
  TO_CHARGE: 'Cargar',
  TO_DO: 'Hacer',
  TO_MEET: 'Consulte',
  TO_MONITOR: 'Supervisar',
  TO_REGISTER: 'Regístrese en',
  TO_REMOVE: 'Eliminar',
  TO_SEND: 'Mandar',
  TODAY: 'Hoy',
  TOGGLE_TO_GRAY: 'Cambiar a gris',
  TOKEN: 'Ficha',
  TOP_10_CATEGORIES: 'Top 10 Categorías',
  TOP_10_COMPANIES: 'Top 10 Empresas',
  TOP_10_CONSUMPTION: 'Top 10 Consumo',
  TOP_10_DEPARTAMENT: 'Top 10 Departamento',
  TOP_10_DEPARTAMENTS: 'Top 10 Departamentos',
  TOP_TEN_RECENTE: 'Top 10 más recientes',
  TOPIC: 'Tema',
  TOPICS_REGISTER: 'Registro de Tópicos',
  TOTAL: 'Total',
  TOTAL_ACTIVITIES: 'Total de actividades',
  TOTAL_ACTIVITIES_COMPLETED: 'Actividades totales completadas',
  TOTAL_AMOUNT_HOURS: 'Total de horas',
  TOTAL_APPROVED: 'Total aprobado',
  TOTAL_APPROVERS: 'Aprobadores totales',
  TOTAL_BY_COMPANY: 'Total por Empresa',
  TOTAL_CALLS: 'total de llamadas',
  TOTAL_CALLS_CURRENT_MONTH: 'Llamadas totales (mes actual)',
  TOTAL_CONTRACTS: 'Totales de contratos',
  TOTAL_CORPORATE_OBJECTIVES: 'Totales Objetivos corporativos',
  TOTAL_COST: 'Costo total',
  TOTAL_COST_CARE: 'Costo total de asistencia',
  TOTAL_FACE_TO_FACE_HOURS: 'Horas totales de contacto',
  TOTAL_GMUD: 'Total de Cambio (mes en curso)',
  TOTAL_GOALS: 'Objetivos totales',
  TOTAL_HOURS_CONSUMED: 'Total de horas consumidas',
  TOTAL_IC_MONITORING: 'Número total de IC controladas',
  TOTAL_KEYS: 'llaves totales',
  TOTAL_MONTH: 'Totales en el mes',
  TOTAL_OUT_OF_DATE_TICKETS: 'Totales de boletos vencidos',
  TOTAL_PEOPLE_INVOLVED: 'Totales de personas implicadas',
  TOTAL_REJECTED: 'Total rechazado',
  TOTAL_REMOTE_HOURS: 'Totales de horas remotas',
  TOTAL_SUM_MORE_100: 'La suma total de los elementos supera el 100%',
  TOTAL_TICKET: 'TOTAL DE TICKET',
  TOTAL_TICKET_TIME: 'Tiempo Total del Ticket',
  TOTAL_TICKET_TIME_BETWEEN_OPENING_AND_RESOLUTION: 'Tiempo total del ticket, es el tiempo transcurrido desde la apertura de la solicitud hasta su resolución',
  TOTAL_TICKETS: 'Totales tickets',
  TOTAL_TICKETS_CLOSED_PER_DAY: 'totales de tickets cerrados por día',
  TOTAL_TICKETS_ON_TIME: 'Entradas totales a tiempo',
  TOTAL_TIME_ATTENDANCE: 'Tiempo Total de Atención',
  TOTAL_VALUE_IN_THE_PROJECT: 'Valor total del proyecto',
  TOTAL_VALUE_PROJECT_IN_MONTH: 'Valor total de los proyectos en el mes',
  TOWER: 'TORRE',
  TRAINING: 'Formación',
  TRANSFERRED: 'Transferencia',
  TREATMENT: 'Tratamiento',
  TREND: 'Tendencia',
  TREND_ANALYSIS_BY_DEVICE: 'Análisis de Tendencia por Dispositivo',
  TREND_LINE: 'Línea de Tendencia',
  TRIGGER: 'Disparador',
  TWO_HOURS: 'duas horas',
  TYPE: 'Tipo',
  TYPE_ACTION: 'Tipo de Acción',
  TYPE_CLIENT: 'Tipo Cliente',
  TYPE_DEVICE: 'Tipo de dispositivo',
  TYPE_DEVICE_ADD: 'Agregar tipo de dispositivo',
  TYPE_DEVICE_EDIT: 'Editar tipo de dispositivo',
  TYPE_MASTER_PASSWORD: 'Introduzca la contraseña maestra',
  TYPE_OF_ACTIVITY: 'Tipo de actividad',
  TYPE_OF_APPROVAL: 'Tipo de Aprobación',
  TYPE_OF_APPROVER: 'Tipo de Aprobador',
  TYPE_OF_CAPITAL: 'Tipo de Capital',
  TYPE_OF_DEMAND: 'Tipo de Demanda',
  TYPE_OF_MONITORING: 'Tipo de Control',
  TYPE_OF_SERVICE: 'Tipo de cuidado',
  TYPE_PASSWORD_ENTER: 'Introduzca la contraseña para iniciar sesión',
  TYPE_PROJECT: 'Tipo de Proyecto',
  TYPE_PROJECT_ITEMS: [{
    color: 'info',
    icon: 'fas fa-compass',
    label: 'Estratégico',
    value: 1
  }, {
    color: 'purple',
    icon: 'fas fa-dumbbell',
    label: 'Mejora',
    value: 2
  }, {
    color: 'primary',
    icon: 'fas fa-wrench',
    label: 'Corrección',
    value: 3
  }, {
    color: 'éxito',
    icon: 'fas fa-chart-line',
    label: 'Evolutivo',
    value: 4
  }, {
    color: 'danger',
    icon: 'fas fa-gavel',
    label: 'Obligación Legal',
    value: 5
  }],
  TYPE_PROVIDER: 'Tipo Proveedor',
  TYPE_RELATIONSHIP: 'Tipo de asociación',
  TYPE_SLA_STATUS_CANCELED: 'Cancelado',
  TYPE_SLA_STATUS_CONCLUDED: 'Concluído',
  TYPE_SLA_STATUS_IN_PROGRESS: 'En curso',
  TYPE_SLA_STATUS_PAUSE: 'Pausa',
  TYPE_SLA_STATUS_RESUMPTION: 'Reanudación',
  TYPE_SLA_STATUS_START: 'Inicio',
  TYPE_TO_SEARCH_OR_CLICK_MAGNIFYING_GLASS: 'Escriba para buscar o haga clic en la lupa para vizualizar todo.',
  TYPE_YOUR_EMAIL: 'Introduzca su email',
  TYPE_YOUT_EMAIL: 'Escriba su correo electrónico',
  UNABLE_TO_EVALUATE: 'No fue posible evaluar',
  UNAVAILABLE: 'No disponible',
  UNFREEZE_SUCCESSFULLY_COMPLETED: 'Descongelamiento realizado con éxito',
  UNINFORMED: 'No informado',
  UNPLANNED_ACTIVITIES: 'Actividades no planificadas',
  UNREGISTERED_USER: 'Usuario no registrado',
  UNRESOLVED_PROBLEMS: 'Problemas sin resolver',
  UNSTABLE: 'Inestable',
  UNTIL: 'hasta',
  UPDATE: 'Actualización',
  UPDATE_AGENT: 'Agente de actualización',
  UPDATE_ITEM_LIST: 'Actualizar lista de elementos',
  UPDATE_TICKET_LIST_SCREEN: 'Actualización de la pantalla de la lista de tickets',
  UPDATE_TICKET_MANAGEMENT_VIEW_SCREEN: 'Actualización de la pantalla de la lista de tickets',
  UPDATE_USER_INFO: 'Modificar datos de usuario',
  UPDATED: 'Actualizado',
  UPDATED_CALL: 'Actualizada la llamada',
  UPDATED_FIELDS: 'Campos actualizados',
  UPDATES: 'Actualizaciones',
  UPLOAD_ERROR: '¡Introduzca el contrato que desea cargar!',
  UPLOAD_FILE: 'Carga de Archivos',
  UPTIME: 'Uptime',
  URGENCY: 'Urgencia',
  URL: 'URL',
  USE_APPROVAL_CENTER: 'centro de aprobación de uso',
  USE_CUSTOM_FORM_FOR_CHANGE_MANAGEMENT_CAB: 'Utilizar formulario personalizado para la CAB',
  USE_IN_GMUD: 'Usar en cambio',
  USE_IN_TICKET: 'Usar en ticket',
  USE_MULTI_FILTER_TICKET: 'Usar multifiltro en la pantalla del ticket',
  USE_ONLY_GROUP_TECH_IN_CREATE_GMUD_ACTIVITY: 'Cuando está habilitado, solo mostrará grupos técnicos para seleccionar en la pantalla de creación de actividad de cambio.',
  USE_RESOLUTION_CATEGORY: 'Usar categorías de resolución',
  USE_SERVICE_PORTAL_ONLY: 'Solo portal de servicio',
  USE_STATUS_SUB_CALLS: 'Utilizar el estado en las sub-llamadas',
  USE_TECH_GROUP_IN_GMUD_ACTIVITY: 'Uso de grupos técnicos en actividades de cambio.',
  USED: 'Usado',
  USED_FOR: 'Utilizado para',
  USER: 'Usuario',
  USER_APPROVED: 'Usuario que aprobó',
  USER_CAD: 'Usuario que aprobó',
  USER_DOES_NOT_BELONG_TO_THE_TECHNICAL_GROUP: 'El usuario no pertenece al grupo técnico',
  USER_DOES_NOT_HAVE_PERMISSION: 'El usuario no tiene permisos para realizar esta acción',
  USER_HAS_ALREADY_APPROVED: 'El usuario ya ha aprobado',
  USER_HAS_ALREADY_MADE_APPROVAL: 'El usuario ya ha hecho la aprobación',
  USER_HAS_ALREADY_PERFORMED_THIS_ACTION: 'El usuario ya ha realizado esta acción',
  USER_IS_NOT_PART_OF_THE_ACTIVITY: 'El usuario no es parte de la actividad, comuníquese con el gerente del proyecto',
  USER_IS_NOT_PART_OF_THE_APPROVAL_GROUP: 'Usuário não faz parte do grupo aprovador',
  USER_IS_NOT_PART_OF_THE_APPROVER_GROUP: 'El usuario no es parte del grupo de aprobadores.',
  USER_IS_NOT_RESPONSIBLE_REGISTERED_IN_THE_TASK: 'El usuario no es responsable registrado en la tarea',
  USER_MANAGEMENT: 'Gestión de Usuarios',
  USER_NOT_ALLOWED: 'Usuario no permitido',
  USER_NOT_ALLOWED_TO_CATEGORIZE: '¡Usuario sin permiso para categorizar!',
  USER_REGISTERED: 'Usuario registrado',
  USER_REGISTRATION: 'Registro de Usuario',
  USER_VIP: 'Este es un usuario de categoría VIP',
  USER_WHO_REJECTED: 'Usuario que rechazó',
  USER_WILL_BE_INVOLVED_IN_THE_PROJECT: '¡El usuario _user_ estará involucrado en el proyecto!',
  USERNAME: 'Nombre de Usuario',
  USERS: 'Usuarios',
  USERS_CREATION: 'Creación de Usuarios',
  USERS_INVOLVED: 'El usuario no forma parte del grupo de aprobación',
  USERS_PERMISSIONS: 'Permisos de Usuario',
  UST: 'Unidad de Soporte Técnico',
  UTILIZATION: 'Utilización',
  VALIDATE_RELATIONSHIP: 'Validar relación',
  VALIDATE_RELATIONSHIP_BETWEEN_CI: 'Validar relación entre CI',
  VALIDATION: 'Validación',
  VALIDATIONS_APPROVALS: 'Validaciones/Aprobaciones',
  VALUE: 'Valor',
  VALUE_OF_BENEFITS: 'Valor de la Prestación',
  VALUES: 'Valores',
  VARIATION: 'Variación',
  VENDOR: 'Marca',
  VERSION: 'Versión',
  VERY: 'Muy',
  VIEW: 'Vista',
  VIEW_ATTACHMENT: 'Ver versión',
  VIEW_COSTS: 'Ver costos',
  VIEW_FULL_SCREEN: 'Ver en pantalla completa',
  VIEW_FULL_TICKET: 'Ver boleto completo',
  VIEW_LINE: 'Ver línea',
  VIEW_OF_SELECTED_CONTRACT: 'Vista del contrato seleccionado',
  VIEW_PROJECTS: 'Visualizar Proyectos',
  VIP: 'VIP',
  VIRTUAL_MEMORY: 'Memoria virtual',
  VISUALIZATION_OF_TASKS: 'Vista de tareas',
  VISUALIZE: 'Ver',
  VOLUME_NAME: 'Nombre del volumen',
  VOLUME_SERIAL_NUMBER: 'Número de serie del volumen',
  VOTES: 'Votos',
  VULNERABILITY: 'Vulnerabilidad',
  VULNERABILITY_MANAGEMENT: 'Gestión de vulnerabilidades',
  WAIT_GENERATING_REPORT: 'Espera generando informe',
  WAITING: 'En espera',
  WAITING_FOR_PREDECESSORS: 'A LA ESPERA DE LOS PRECEDENTES',
  WANT_TO_CLOSE_TICKET_WHEN_MIGRATING_TO_OS: '¿Desea cerrar el ticket al migrar a OS?',
  WARNING: 'Atención',
  WARNING_DELETE_COMPANY: 'Atención: ¿Al borrar la EMPRESA, se borrarán todos los elementos relacionados con ella?',
  WARNING_DELETE_ITEM: '¿Realmente desea eliminar este ítem?',
  WARNING_DELETE_ITEM_SELECTED: 'Realmente desea eliminar  el  ítem seleccionado ?',
  WARNING_DELETE_LOCATION: 'Advertencia: ¿Realmente desea eliminar esta ubicación?',
  WARNING_SUBACTIVITY_OUTSIDE_PROJECT_PERIOD: 'Subactividad fuera del periodo del proyecto',
  WARNING_WANT_CANCEL_PROJECT: '¿PROYECTO YA INICIADO, DESEA CANCELAR EL PROYECTO?',
  WARNING_WANT_REMOVE_PROJECT: 'DESEA ELIMINAR EL PROYECTO?',
  WARRANTY: 'Garantía',
  WEEK: 'Semana',
  WEEKLY: 'Semanalmente',
  WEIGHT: 'Peso',
  WEIGHT_CAB: 'Peso CAB',
  WELCOME_PAGE: {
    CONTRACTS_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    INFRATRUCTURE_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    RISKS_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    SERVICE_DESK: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    SUBTITLE: 'Elija una apción para comenzar',
    TITLE: 'Bienvenido'
  },
  WHANT_TO_APPROVE: '¿Está seguro de la Aprobación ?',
  WHAT_YOUR_SUBCATEGORY: '¿Cúal es su sub-categoría?',
  WHEN_PERFORMING_THE_LANGUAGE_CHANGE_YOU_WILL_BE_DISCONNECTED: 'Al realizar el cambio de idioma, se le desconectará y necesita iniciar sesión de nuevo. ¿Quieres continuar?',
  WHEN_YOU_CHANGE_YOUR_TIMEZONE_YOU_WILL_BE_DISCONNECTED: 'Cuando cambie su zona horaria, será desconectado y necesita iniciar sesión de nuevo. ¿Quieres continuar?',
  WHICH_KANBAN_COLUMN_SPRINT_WILL_STATUS_MATCH: '¿A qué columna kanban del sprint corresponderá este estado?',
  WHY: 'Por qué',
  WISH: 'Desear',
  WISHE: 'Deseo',
  WISHES: 'Desear',
  WORKDAY: 'Jornada de trabajo',
  WORKED_TICKETS: 'Tickets Trabajados',
  WORKFLOW: 'Flujo de Trabajo',
  WORKFLOW_OF_CHANGE: 'Flujo de cambios',
  WRITE: 'Escribir',
  WRITE_STATUS_DESCRIPTION: 'Escriba una descripción del estado',
  WRITE_STATUS_NAME: 'Escriba el nombre del estado',
  WRITE_THE_BENEFIT_HERE: 'Escriba el beneficio aquí',
  WRITE_THE_DESCRIPTION_HERE: 'Escriba la descripción aquí',
  YEAR: 'Año',
  YEAR_OPTIONS: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  YEAR_VALUE: 'Valor Anual',
  YEARLY: 'Anual',
  YES: 'Si',
  YOU_ARE_CALLER: '¡Usted es el solicitante del llamado!',
  YOU_ARE_NOT_PART_OF_THE_GROUP_REVISION: 'No formas parte del grupo con permisos para revisar este cambio',
  YOU_ARE_NOT_PART_OF_THE_TECH_GROUP: 'No formas parte del equipo técnico de la actividad',
  YOU_DO_NOT_HAVE_ACCESS_TO_CREATE_USERS: 'No tiene acceso para crear usuarios',
  YOU_HAVE_BEEN_ADDED_OBSERVER: '¡Se le ha incluido como observador!',
  YOUR_FAVORITES: 'Tus favoritos',
  YOUR_POINT_AVERAGE_NPS: 'Tu PROMEDIO DE PUNTOS (NPS)',
  YOUR_REPORT_WILL_BE_GENERATED_IN_A_FEW_SECONDS: 'Su informe se generará en unos segundos'
}