import Button from '@common/Button';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Chip } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm2';
import moment from 'moment';
import MdLiveHelp from 'react-icons/lib/md/live-help';
import CommonPage from '../../common/CommonPage';
import { filterObj } from '@common/Func';
import Dialog from '@common/Dialog';
import TaskFormTarefa from './TaskFormTarefa';
import { translateFromEn, translateFromPtBR } from '../../common/languages/Dictionary';
@observer
export default class TaskList extends CommonPage {
  constructor() {
    super();

    this.state = {
      id_catalog_task: 0,
      open: false,
      valuesRelForm: [],
      valuesList: [],
      opentask: false,
      avaliation:false,
      templateform:false,
      id_template_task:0
    };
    this.openTemplate = this.openTemplate.bind(this);
    this.saveRelForm = this.saveRelForm.bind(this);
    this.openCatalogAvaliation = this.openCatalogAvaliation.bind(this);
    this.openTemaplateTask =this.openTemaplateTask.bind(this);
  }

  translate(valor) {
    if (
      sessionStorage.getItem('sensr_lang') == 'PT_BR' ||
      sessionStorage.getItem('sensr_lang') == null
    ) {
      return valor.toLowerCase() == 'low'
        ? 'baixo'
        : valor.toLowerCase() == 'medium'
        ? 'Medio'
        : valor.toLowerCase() == 'high'
        ? 'Alto'
        : valor;
    } else {
      return valor.toLowerCase() == 'baixo'
        ? 'Low'
        : valor.toLowerCase() == 'medio'
        ? 'medium'
        : valor.toLowerCase() == 'alto'
        ? 'high'
        : valor;
    }
  }


  saveRelForm(v) {
    let {avaliation,templateform,id_template_task }= this.state;
    this.action
      .execute('post', this.APIEndpoints.TASK + '/formssaverel', null, {
        ...v,
        id_catalog_task: this.state.id_catalog_task,
        avaliation,
        templateform,
        id_template_task
      })
      .then((g) => {
        
        this.setState({
          valuesRelForm: g.data.map((b) => b.fk_id_catalog_task),
          open: false,
        });
      });
  }
  openCatalogAvaliation(id) {
    this.action
      .execute('get', this.APIEndpoints.TASK + '/getform?origin=ticket', 'formstemplates')
      .then((g) => {
        let itens = g.data.map((h) => h.id_template_forms);
        this.setState({ valuesList: g.data,avaliation:true,templateform:false });
      });

    this.action
      .execute('post', this.APIEndpoints.TASK + '/formsrel', null, {
        id_catalog_task: id,
        avaliation:true
      })
      .then((g) => {
        this.setState({
          valuesRelForm: g.data.map((b) => b.id_template_forms),
          open: true,
        });
      });

    this.setState({ id_catalog_task: id });
  }
  openTemaplateTask(id) {
    this.action
      .execute('get', this.APIEndpoints.TASK + '/getform', 'formstemplates')
      .then((g) => {
        let itens = g.data.map((h) => h.id_template_forms);
        this.setState({ valuesList: g.data,avaliation:false,templateform:true });
      });

    this.action
      .execute('post', this.APIEndpoints.TASK + '/formsrel', null, {
        id_template_task: id,
        templateform:true
      })
      .then((g) => {
      //console.log(g)
      
        this.setState({
          valuesRelForm: g.data.map((b) => b.fk_id_template_forms),
          open: true,
        });

      });

    this.setState({ id_template_task: id });
  }

  openTemplate(id) {
    this.action
      .execute('get', this.APIEndpoints.TASK + '/getform?origin=ticket', 'formstemplates')
      .then((g) => {
        let itens = g.data.map((h) => h.id_template_forms);
        this.setState({ valuesList: g.data ,avaliation:false,templateform:false});
      });

    this.action
      .execute('post', this.APIEndpoints.TASK + '/formsrel', null, {
        id_catalog_task: id,
      })
      .then((g) => {
        // console.log( g.data.map(b=>b.fk_id_catalog_task))
       
        this.setState({
          valuesRelForm: g.data.map((b) => b.fk_id_template_forms),
          open: true,
        });
      });

    this.setState({ id_catalog_task: id });
  }

  render() {
    let {
      language,
      handlePage,
      handlePageDelete,
      redirect,
      tasks,
      impacts,
      priorities,
      urgencies,
      complexities,
      callPageSelectFilter,
      totaltasks,
      currentPage,
    } = this.props;

    let template_forms = this.state.valuesList.map((i) => ({  
      value: i.id_template_forms,
      label: i.name,
    }));

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10, textAlign: 'center' },
      },
      { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
      {
        key: 'time_sla',
        label: language.SLA_TIME,
        style: { width: 100 },
        thConfig: true,
      },
      {
        key: 'complexity',
        label: language.COMPLEXITY,
        style: { width: 40, textAlign: 'center' },
      },
      {
        key: 'impact',
        label: language.IMPACT,
        style: { width: 40, textAlign: 'center' },
      },
      {
        key: 'priority',
        label: language.PRIORITY,
        style: { width: 40, textAlign: 'center' },
      },
      {
        key: 'urgency',
        label: language.URGENCY,
        style: { width: 40, textAlign: 'center' },
      },
      {
        key: 'services',
        label: language.SUB_CATEGORY,
        style: { width: 25, textAlign: 'center' },
        search: false
      },
      {
        key: 'category',
        label: language.CATEGORIES,
        style: { width: 25, textAlign: 'center' },
        search: false
      },
      {
        key: 'aprove',
        label: language.APPROVAL,
        style: { width: 25, textAlign: 'center' },
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data = tasks.map((obj) => {
      return {
        id: obj.id_catalog_task,
        name: obj.name,
        time_sla:
          obj.time_sla > 1
            ? obj.time_sla + ' ' + language.HOURS
            : obj.time_sla + ' ' + language.HOUR,
        
        complexity: complexities
          .filter((i) => i.id_table === obj.id_complexity)
          .map((ug) => translateFromPtBR(ug.description, language)),
        impact: impacts
          .filter((i) => i.id_table === obj.id_impact)
          .map((im) => translateFromPtBR(im.description, language)),
        priority: priorities
          .filter((i) => i.id_table === obj.id_priority)
          .map((pri) => translateFromPtBR(pri.description, language)),
        urgency: urgencies
          .filter((i) => i.id_table === obj.id_urgency)
          .map((ug) => translateFromPtBR(ug.description, language)),
        
        services: (
          <Tooltip
            interactive
            html={
              <div className="view-catalogs-tooltip">
                {obj.services &&
                  obj.services.split(' - ').map((string) => <li>{string}</li>)}
              </div>
            } 
            arrow={true}
            position="left"
            theme="light"
          >
            {(obj.services && obj.services.length) > 0 ? (
              <i
                class="fas fa-eye"
                style={{ fontSize: 20, color: 'var(--warning)' }}
              ></i>
            ) : null}
          </Tooltip>
        ),

        category: (
          <Tooltip
            interactive
            html={
              <div className="view-catalogs-tooltip">
                {obj.category &&
                  obj.category.split(' - ').map((string) => <li>{string}</li>)}
              </div>
            }
            arrow={true}
            position="left"
            theme="light"
          >
            {(obj.category && obj.category.length) > 0 ? (
              <i
                class="fas fa-eye"
                style={{ fontSize: 20, color: 'var(--warning)' }}
              ></i>
            ) : null}
          </Tooltip>
        ),

        aprove: obj.liberation == true ? language.YES : language.NO,
        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                       <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.ADD_COMPLETION_TEMPLATE}
                    color="default"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => this.openCatalogAvaliation(obj.id_catalog_task)}
                  />

                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.ADD_TASK2}
                    color="default"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() =>
                      this.setState({
                        id_catalog_task: obj.id_catalog_task,
                        opentask: true,
                      })
                    }
                  />
                   <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.CONFIGURE_MULTIPLE_TICKETS}
                    color="default"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => handlePage('multitickets', obj)}
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.ADD_CHANGE_TEMPLATE}
                    color="warning"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => handlePage('change_template', obj)}
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.TEMPLATE_ADD_CATALOG}
                    color="success"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => this.openTemplate(obj.id_catalog_task)}
                  />

                  <Button
                    variant="circle"
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12 }}
                    className="mr-2"
                    size={0.7}
                    onClick={() => handlePage('edit', obj)}
                  />
                  <Button
                    variant="circle"
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="default"
                    size={0.7}
                    _loading={'del-task-list-' + obj.id_catalog_task}
                    style={{ fontSize: 14 }}
                    onClick={() => handlePageDelete(true, { detach: false, ...obj })}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        {this.state.opentask == true ? (
          <Dialog
            PaperProps={{
              style: {
                height: '70%',
              },
            }}
            open={true}
            title={language.TASK_TEMPLATE}
            maxWidth="md"
            onClose={() => this.setState({ opentask: false })}
          >
            <TaskFormTarefa
              id_catalog_task={this.state.id_catalog_task}
              language={language}
              openTemaplateTask={this.openTemaplateTask}
              saveRelForm={this.saveRelForm}
               
            ></TaskFormTarefa>
          </Dialog>
        ) : null}

        {this.state.open == true ? (
          <Dialog
            PaperProps={{
              style: {
                height: '50%',
              },
            }}
            open={true}
            title={language.TEMPLATE_S_CATALOG}
            onClose={() => this.setState({ open: false })}
            maxWidth="md"
          >
            <Card style={{ height: '80%', overflow: 'visible' }} className="content">
              <CommonForm
              values={{ id_template_forms: this.state.valuesRelForm[0]}}
                fields={[
                  {
                    col: 12,
                    name: 'id_template_forms',
                    label: language.TEMPLATES,
                    type: 'select',                                       
                    options: template_forms,
                  },
                ]}
                onSubmit={(v) => {
                  this.saveRelForm(v);
                }}
              />
            </Card>
          </Dialog>
        ) : null}
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index="TaskList"
          showHeaderToolbar
          orderColumn
          searchColumnEvent={(v) => callPageSelectFilter(0, v, 75, 'filter')}
          countTotal={totaltasks}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          paginationTop={false}
          searchColumn={true}
          loadSearch={true}
          currentPage={currentPage}
          onClearAllFilter = {this.props.onClearAllFilter}

          beforeBar2={configurationProps.control}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          beforeBar={
            <Button
              label={language.NEW_CATEGORY}
              primary={true}
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              onClick={() => handlePage('new')}
            />
          }
        />
      </Fragment>
    );
  }
}
