// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{box-sizing:border-box!important}a.link{text-decoration:none}#GV.dev{padding:20px}#GV{width:100%}#GV .paper{width:auto!important}.text-muted-200{color:rgba(0,0,0,.8)}.text-muted-600{color:rgba(0,0,0,.6)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
