import React from 'react';
import './index.css';

export default function({
	config={}, data=[]
}){

	var montante = data.reduce((i,e)=>e.y+i,0);
	config.interval = montante < config.interval ? montante : config.interval
	var montanteDivided = parseInt((montante/config.interval))

	function rp(y){
		return (y/montante)*100
	}
	
	var rph = 100/(config.interval+1)
	var yi = config.yi||0;

	try{
		var value = config.formatY(config.y);
	}catch(e){
		var value = config.y;
	}
	return (
		<div className='content-medium-time' style={{height:config.height, width:config.width}}>
		    <div className='values'>
		      {Array.from(Array(config.interval+1).keys()).reverse().map(e=>(
		      	<div 
		      		key={e} 
		      		style={{
		      			height:(rph/(e==0||e==config.interval?2:1))+'%'
		      		}}
		      	>
		      		{yi+(montanteDivided*e)}
		      	</div>
		      ))}
		    </div>
		    <div className='content-division'>
		     
			{[1,2,3].map(e=>(
			
			<div 
			
				style={{height:'33%', backgroundColor:e==1?'#f66868': e==2?'#f0b002':'#92d050'}}
			/>
	      	))}
		    </div>
		    <div className='indicador-medium' style={{bottom:rp(config.y-yi)+'%', left:`calc(100% - ${16+(config.width-40)}px)`}}>
		      <div className='indicator' style={{width:22+(config.width-40)+'px'}}></div>
		      {value}
		    </div>
		</div>
	)
}