import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import RiskTestDashboard from './RiskTestDashboard';
import RiskGeneralDashboard from './RiskGeneralDashboard';
import RiskManagementConfiguration from './RiskManagementConfiguration';
import { testRoute } from '@common/Func';
import './RiskManagementContainer.css';
import Animate from '@common/Animate';
import { toBase64 } from '@common/Func';
import RiskDashboard from './RiskDashboard';
import ConfigurationDashboard from './ConfigurationDashboard';
import RiskView from './RiskView';
import DownloadFiles from '../../util/DownloadFiles';


@observer
export default class RiskManagementContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'dash',
      companyId: 1,
      upload: [],
      usercon : [],
      dados : []
    };
    this.handlePage = this.handlePage.bind(this);
    this.saveTest = this.saveTest.bind(this);

    this.handleCompany = this.handleCompany.bind(this);
    this.update = this.update.bind(this);
    this.verifyActivitTech = this.verifyActivitTech.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.getData = this.getData.bind(this);
    this.setDados = this.setDados.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.risk != this.props.risk) {
      this.getData();
    }
  }

  ondonwFile(id, file) {
    DownloadFiles(this.APIEndpoints.REQUESTS + '/downloadrisk/' + 1 + '&' + file, file)
  }

  componentWillMount() {
    this.getData();
  }
  setDados(dados){
    this.setState({dados})
  }
  getData = () => {
    this.store.risks_test = [];

    let params = this.props.location.state;

    if (params && params['risk'] && params['id_company']) {
      this.action.execute('get', this.APIEndpoints.RISK + '/dash/' + params.risk + '/company/' + params.id_company, 'risks_test');
      this.action.execute(
        'get',
        this.APIEndpoints.RISK + '/maturity/' + 1 + '/stand/' + params.risk,
        'maturity'
      );
    } else {

      try {

        this.setState({ companyId: this.props.location.state.id_company })
        this.action.execute('get', this.APIEndpoints.RISK + '/dash/' + this.props.risk + '/company/' + this.props.location.state.id_company, 'risks_test');

      } catch (e) {

        this.action.execute('get', this.APIEndpoints.RISK + '/dash/' + this.props.risk + '/company/' + this.state.companyId, 'risks_test');
        this.action.execute(
          'get',
          this.APIEndpoints.RISK + '/maturity/' + 1 + '/stand/' + this.props.risk,
          'maturity'
        );
      }
    }

    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }, null, null, null
    ).then(r => {
      this.setState({usercon : r.data})
    })
    this.action.execute(
      'get',
      this.APIEndpoints.RISK + '/projects/',
      'activity'
    );


    this.action.execute('get', this.APIEndpoints.RISK + '/at', 'attachment');


    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/getDetailAll',
      'statusProject'
    );
  };

  verifyActivitTech(id_tech) {
    this.action.execute(
      'get',
      this.APIEndpoints.DEMAND + '/tickets/' + id_tech,
      'tickets'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.DEMAND + '/activity/' + id_tech,
      'activities'
    );
  }

  handleCompany(companyId) {

    this.action.execute(
      'get',
      this.APIEndpoints.RISK +
      '/dash/' +
      this.props.risk +
      '/company/' +
      companyId,
      'risks_test'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.RISK + '/maturity/' + companyId + '/stand/' + this.props.risk,
      'maturity'
    );
    this.setState({
      companyId: companyId
    });
  }
  update(id, item, value) {
    let data = {
      id: id,
      item: item,
      value: value,
      id_risk: this.props.risk,
      id_company: this.state.companyId
    };

    this.action.execute(
      'post',
      this.APIEndpoints.RISK + '/update',
      'risks',
      data
    );
    //  this.action.execute('get', this.APIEndpoints.RISK + '/' + this.props.risk + '/company/' + this.state.companyId, 'risks');
  }
  handlePage(page) {
    if (page === 'config') {
      // this.action.execute('get', this.APIEndpoints.RISK + '/' + this.props.risk + '/company/' + this.state.companyId, 'risks');
    }
    this.setState({ page: page });
  }

  onUploadFile = async (file = []) => {
    let { upload } = this.state;
    upload = upload.filter(e => e.idchecked != file[0].idcheck);

    upload = (await toBase64(file.map(e => e.file))).map(e => ({
      file: e.base64,
      type: e.file.type,
      namefile: e.file.name,
      idchecked: file[0].idcheck,
      descattach: file[0].desc
    }));

    this.setState({ upload })
  }

  saveTest(
    result,
    anexo,
    tech,
    project_activity,
    execution_date,
    current_maturity,
    planned_maturity,
    adherent,
    applicable,
    review_period,
    userA,

    userC,
    userI,
    userR,
    tickets,
    priority,
    desc,
    norma
  ) {


    this.action.execute('post', this.APIEndpoints.RISK, 'risks_test', {
      questions: result,
      project_activity: project_activity,
      execution_date: execution_date,
      current_maturity: current_maturity,
      planned_maturity: planned_maturity,
      adherent: adherent,
      tech: tech,
      company: this.props.location.state.id_company,
      standard: this.props.risk,
      applicable: applicable,
      files: anexo,
      userA: userA,
      review_period: review_period,
      userC: userC,
      userI: userI,
      userR: userR,
      tickets: tickets,
      priority: priority,
      desc: desc,
      norma: norma
    }).then(res => {
      this.action
      .execute(
        'get',
        this.APIEndpoints.RISK + '/' + this.props.risk + '/company/' + this.props.location.state.id_company,
        'risks'
      )
      .then(h => {
        this.setDados(h.data);
      });
    })
    .catch(error => console.log('error', error));
  }

  render() {
    let {
      companies,
      language,
      risks,
      risks_test,
      users,
      projects,
      statusProject,
      maturity,
      attachment,
    } = this.store;
    let { page, values, companyId, usercon } = this.state;
    let { RISKMANAGEMENT } = this.store.theme;
   
    let companyName='';
try{ 
  companyName=  companies.filter(comp=>comp.id_company === this.props.location.state.id_company)[0].name
   }catch(r){

   }

    let companiesList = companies;
    if (this.store.getUserLoged().role == 'tech') {

      try {
        companiesList =
          companies.filter(g => usercon[0].company.includes(g.id_company));
      } catch (t) {

        companiesList = [];
      }
    }



    let history = this.history2;
    let redirect = this.redirect2;
    let redirect1 = this.redirect;

    let risk = this.props.risk;
    let PAGE = history.location.pathname

    let classIcon = {
      'ISO 27001': 'iso27Icon',
      'ISO 20000': 'iso20Icon',
      COBIT: 'cobitIcon',
      'ISO 37001': 'iso27Icon',
      [language.IT_EVOLUTION_MANAGEMENT]: 'evolutionIcon',
      [language.BACKUP_MANAGEMENT]: 'backupManagementIcon',
      LGPD: 'bitdefenderIcon'
    };

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={true}
        iconAlert={true}
        loading={false}
      >
        <div className="RiskManagementContainer">

          {testRoute('/dash/riskmanagement/*/config', PAGE) &&

            <ConfigurationDashboard risk={risk}
              risks={risks}
              users={users}
              language={language}
              riskType={this.props.risk}
              idcompany={this.props.location.state.id_company}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              history={history}
              companyName={companyName}
              saveTest={this.saveTest}
              activity={this.store.activity}
              name={this.props.location.state.name}
              toggleAlert={this.store.toggleAlert}
              UserLoged={this.store.getUserLoged()}
              themes={RISKMANAGEMENT}
              dados = {this.state.dados}
              setDados = {this.setDados}
              language={language} />

            /*<Animate>
            <RiskManagementConfiguration
              classIcon={classIcon}
              tickets={this.store.tickets}
              activities={this.store.activities}
              attachment={attachment}
              ondonwFile={this.ondonwFile}
              verifyActivitTech={this.verifyActivitTech}
              activity={this.store.activity}
              statusProject={statusProject}
              projects={projects}
              risk={risk}
              redirect1={redirect1}
              redirect2={this.redirect2}
              usercon_company={this.store.usercon_company}
              idcompany={this.props.location.state.id_company}
              update={this.update}
              users={users}
              onUploadFile={this.onUploadFile}
              upload={this.state.upload}
              handlePage={this.handlePage}
              language={language}
              riskType={this.props.risk}
              risks={risks}
              saveTest={this.saveTest}
              tests={risks_test}
              history={history}
              redirect={redirect}
            /> 
            </Animate> */
          }

          {!testRoute('/dash/riskmanagement/*/config', PAGE) && page === 'dash' && !risk &&

            <RiskGeneralDashboard
              classIcon={classIcon}
              maturity={maturity}
              companies={companiesList}
              risk={risk}
              redirect2={this.redirect2}
              handlePage={this.handlePage}
              handleCompany={this.handleCompany}
              usercon_company={usercon}
              companyId={companyId}
              language={language}
              toggleAlert={this.store.toggleAlert}
              UserLoged={this.store.getUserLoged()}
              tests={risks_test}
              redirect={redirect}
              risk={risk}
            />

          }

          {!testRoute('/dash/riskmanagement/*/config', PAGE) && page === 'dash' && risk &&
            <Animate>
              {/*<RiskTestDashboard
                classIcon={classIcon}
                maturity={maturity}
                companies={companiesList}
                redirect2={this.redirect2}
                history={history}
                risk={risk}
                
                
                handleCompany={this.handleCompany}
                usercon_company={this.store.usercon_company}
                companyId={companyId}
                language={language}
                tests={risks_test}
         
                
              />*/}

              {

                <RiskDashboard
                  language={language}
                  classIcon={classIcon}
                  toggleAlert={this.store.toggleAlert}
                  maturity={maturity}
                  companies={companiesList}
                  redirect2={this.redirect2}
                  history={history}
                  risk={risk}
                  users={users}
                  handleCompany={this.handleCompany}
                  user_companies={usercon}
                  companyId={companyId}
                  language={language}
                  tests={risks_test}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  UserLoged={this.store.getUserLoged()}

                />
              }
              {/*
                tela de configuração
              <ConfigurationDashboard language={language}/>*/}
              {/*
                tela de detalhe
              <RiskView language={language}/>*/}
            </Animate>
          }

        </div>
      </CommonPage>
    );
  }
}
