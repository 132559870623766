import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import Button from '@common/Button';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '@common/CommonForm2';
import { formatCurrency } from '../../../../util';
import PageLayout from '../../../../common/PageLayout';
import moment from 'moment'
import Help from '../../../../common/components/Help';
import Store from '@data/Store'
import CommonTable from '../../../../common/CommonTable';
import Dialog from '@common/Dialog';

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ComposedChart,
  Line,
  Area
} from 'recharts';

import { Card, TextField, MenuItem, Divider, Grid } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../graphs/SimplesBarGrap';
import ButtonControlYear from '../../../dashboard/block_5/ButtonControlYear';
import './tech.css'

import Constants from '../../../../data/Constants';

import DownloadFiles from '../../../../util/DownloadFiles';

export default class Tech extends Component {
  constructor() {
    super();
    this.state = {
      techs: [],
      company: null,
      idtech: null,
      month: parseInt(moment().format('M')) - 1,
      year: parseInt(moment().format('YYYY')),
      daySelect: null,
      openall: false,
      allvalues: [],
      general_params: [],
      day: 0
    }

    this.handleValues = this.handleValues.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.handleValuesAll = this.handleValuesAll.bind(this);
  }

  selectDay(v) {
    this.setState({ daySelect: v })
  }

  componentWillMount() {
    this.props.action.execute('get', this.props.APIEndpoints.COMPANY_TIME, 'companytimes');
    this.props.action.execute('get', this.props.APIEndpoints.GENERAL_PARAMETERS).then(g => {
      this.setState({ general_params: g.data })
    })
  }

  handleValuesItens(name, value) {
    let { action, APIEndpoints } = this.props
    let isCompany = name == 'company'

    if (this.state.company !== null || isCompany) {
      let { year, month } = this.state;
      let id = this.state.company || value;
      let months = (month + 1).toString().padStart('2', 0);
      let date = `${months}${year}`

      action.execute('get', APIEndpoints.COMPANY + `/${id}/report-techs?date=${date}`)
        .then(res => this.setState({ techs: Object.values(res.data) }))
        .catch(erro => console.error(erro))
    }
  }

  getMinutes(time) {
    let time_array = time.split(':');
    return Math.floor(time_array[0]) * 60 + Math.floor(time_array[1])
  }

  getValueToPay(value_hour, value_hour_additional, id_company, total_hours, dt_end) {
    let company_times = Store.companytimes.filter(company => company.fk_id_company == id_company)[0]
    let total_to_pay = 0;
    let hour_end = this.getMinutes(moment(dt_end).format('HH:mm:ss'))
    total_hours = this.getMinutes(total_hours)

    if (company_times) {
      let time_end = this.getMinutes(company_times.timefim)
      if (hour_end > time_end) {
        let extra_time = hour_end - time_end;
        if (value_hour_additional) {
          total_to_pay += extra_time * (value_hour_additional / 60)
        } else {
          total_to_pay += extra_time * (value_hour / 60)
        }
        let in_time = total_hours - extra_time
        total_to_pay += in_time * (value_hour / 60)

      } else {
        total_to_pay += total_hours * (value_hour / 60)
      }
    } else {
      total_to_pay += total_hours * (value_hour / 60)

    }

    return total_to_pay.toFixed(2)
  }

  isDelayed(id_company, dt_end) {
    let company_times = Store.companytimes.filter(company => company.fk_id_company == id_company)[0]
    let hour_end = this.getMinutes(moment(dt_end).format('HH:mm:ss'))
    if (company_times) {
      let time_end = this.getMinutes(company_times.timefim)

      if (hour_end > time_end) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
    return false
  }

  handleValuesAll() {
    Store.toggleAlert(true, language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA, 'success');

    let { year, month, company} = this.state;
    let months = (month + 1).toString().padStart('2', 0);
    let date = `${months}${year}`

    action
      .execute('post', `${Constants.APIEndpoints.REPORTEXPORT}/reportteam`, '', { date: date, id_company : company })
      .then((res) => {
        this.setState({ allvalues: res.data, openall: true });
        //clearInterval(handle);
      });
  }

  handleValues(event, tech) {
    let { action, APIEndpoints, id_user } = this.props

    if (id_user > 0) {
      this.state.idtech = this.props.id_user;
    }

    if (event != 'enviar') {
      if (tech == undefined) {
        try {
          let { value, name } = event.target;
          this.setState({ [name]: value })
        } catch (error) {
          this.setState({ year: event })
        }
      }

      if (tech != undefined) {

        this.setState({ idtech: event })
      }

    } else if (event == 'enviar') {
      if (this.state.idtech > 0) {
        let { year, month } = this.state;
        let id = this.state.idtech;
        let months = (month + 1).toString().padStart('2', 0);
        let date = `${months}${year}`
        if (tech == 'excel') {
          action
            .execute('get', APIEndpoints.COMPANY + `/${id}/report-techsexcel?date=${date}`)
            .then(res => {
              DownloadFiles(APIEndpoints.REQUESTS + '/downloadticketrexport/' + Store.getUserLoged().id + '/' + res.data[0].file, res.data[0].file)
            })
            .catch(erro => console.error(erro))
        } else {
          action.execute('get', APIEndpoints.COMPANY + `/${id}/report-techs?date=${date}`)
            .then(res => {
              this.setState({ techs: Object.values(res.data) })})
            .catch(erro => console.error(erro))
        }

      }
    }
  }

  getCompany = (id) => {
    let company = this.props.companies.filter(c => c.id_company == id)[0]
    if (company) {
      return company.name
    } else {
      return ''
    }
  }
  getContract = (id) => {
    let contract = Store.contracts.filter(c => c.id_contract == id)[0]
    if (contract) {
      return contract.name
    } else {
      return ''
    }
  }
  getMonths(month, year) {

    var ar = [];
    var start = moment(year + "-" + (month + 1), "YYYY-MM");
    for (var end = moment(start).add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
      ar.push(start.format('DD'));
    }
    return ar;
  }

  toSecond = (hms) => {
    try {
      //hms = '02:04:33';   // your input string
      var a = hms.split(':'); // split it at the colons
      let hours = a[0];
      if (isNaN(a[0])) {
        let hours_ = a[0].split(' ')
        hours = parseInt(hours_[0]) * 8 + parseInt(hours_[hours_.length - 1])
      }
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var seconds = + hours * 60 * 60 + parseInt(a[1]) * 60 + parseInt(a[2])
      return parseFloat(seconds);
    } catch (r) {
      return 0;
    }
  }
  toHour = (seconds, type) => {
    var d = seconds
    var h = d / 3600
    return h;
  }

  render() {
    let {
      language,
      handlePage,
      users,
      companies,
    } = this.props;

    let { techs, year, daySelect, month, day, general_params } = this.state;
    let data = [];
    let arraymes = [];
    let arrayTicketsClosedMonth = []
    let array = this.getMonths(month, year);
    let colors = [
      '#019FC2',
      '#00CEF5',
      '#00F5DE',
      '#00F669',
      '#8EF500',
      '#E3F300',
      '#FAAF00',
      '#FF5100',
      '#FF0D83',
      '#AF26F9',
      '#00A1C6',
      '#00CEF5',
      '#019FC2',
      '#00CEF5',
      '#00F5DE',
      '#00F669',
      '#8EF500',
      '#E3F300',
      '#FAAF00',
      '#FF5100',
      '#FF0D83',
      '#AF26F9',
      '#00A1C6',
      '#00CEF5',
      '#019FC2',
      '#00CEF5',
      '#00F5DE',
      '#00F669',
      '#8EF500',
      '#E3F300',
      '#FAAF00',
      '#FF5100',
      '#FF0D83',
      '#AF26F9',
      '#00A1C6',
      '#00CEF5',
      '#019FC2',
      '#00CEF5',
      '#00F5DE',
      '#00F669',
      '#8EF500',
      '#E3F300',
      '#FAAF00',
      '#FF5100',
      '#FF0D83',
      '#AF26F9',
      '#00A1C6',
      '#00CEF5',
      '#019FC2',
      '#00CEF5',
      '#00F5DE',
      '#00F669',
      '#8EF500',
      '#E3F300',
      '#FAAF00',
      '#FF5100',
      '#FF0D83',
      '#AF26F9',
      '#00A1C6',
      '#00CEF5'
    ]
    let dateSelected = year + '-' + (month + 1).toString().padStart(2, '0')

    let optionsDay = Array.from({ length: moment(newDate).daysInMonth() }, i => ({ value: i, label: i }))
    var call_month = Array.from({ length: moment(dateSelected, 'YYYY-MM').daysInMonth() }, i => ({}));

    var newDate = moment();
    newDate.set('year', year);
    newDate.set('month', month + 1);
    array.map(b => {
      if (techs.length > 0) {

        let itens = techs[0].tickets.filter(g => parseInt(g.dt_cad.split('/')[0]) == parseInt(b))
          .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)
        try {
          itens = itens + techs[0].projects.filter(g => parseInt(moment(g.dt_cad).format('DD/MM/YY').toString().split('/')[0]) == parseInt(b))
            .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)
        } catch (r) { }

        itens = itens + techs[0].os.filter(g => parseInt(moment(g.dt_cad).format('DD/MM/YY').toString().split('/')[0]) == parseInt(b))
          .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)

        try {
          if (!general_params.associate_remote_access_ticket) {
            itens = itens + techs[0].assessments.filter(g => parseInt(moment(g.dt_cad).format('DD/MM/YY').toString().split('/')[0]) == parseInt(b))
              .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)
          }
        } catch (r) { }
        arraymes.push(itens)


        let tickets = techs[0] && techs[0].ticketsClosedMonth ?  techs[0].ticketsClosedMonth.filter(g => parseInt(moment(g.dt_up).format('DD/MM/YY').toString().split('/')[0]) == parseInt(b)) : []
        arrayTicketsClosedMonth.push(tickets.length)

      }
    });
    let arraynome = Object.keys(this.state.allvalues);
    let arrayvaluesgroup = [];
    let arraygroups = [];
    let arrayvalues = [];
    let arrayvaluesTicket = [];
    let arrayGroupTicketsClosedMonth = []
    Object.keys(this.state.allvalues).map((k, ii) => {
      let techs = this.state.allvalues[k]

      if (ii == 0) {
        arraygroups = techs.groupusers;
      }

      let itens = (day > 0 ? techs.tickets.filter(g => parseInt(g.dt_cad.split('/')[0]) == parseInt(day)) : techs.tickets)
        .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0);

      const countticket = [...new Set(
        (day > 0 ? techs.tickets.filter(g => parseInt(g.dt_cad.split('/')[0]) == parseInt(day)) : techs.tickets).
          map(item => item.id_ticket))].length;

      arrayvaluesTicket.push({
        type: 'column',
        name: k,
        color: colors[ii],
        data: [countticket]
      });

      let totalTicketsMonth = []
      array.map(b => {
        let tickets_closed = techs.ticketsClosedMonth ? techs.ticketsClosedMonth.filter(g => parseInt(moment(g.dt_up).format('DD/MM/YY').toString().split('/')[0]) == parseInt(b)) : []
        totalTicketsMonth.push(tickets_closed.length)
      })
      arrayGroupTicketsClosedMonth.push({
        type: 'line',
        name: k,
        color: colors[ii],
        data: [totalTicketsMonth]
      });

      try {

        itens = itens + (day > 0 ?
          techs.projects.filter(g => g.dt_cad != undefined && parseInt(moment(g.dt_cad).format('DD/MM/YY').toString().split('/')[0]) == parseInt(day))
          :

          techs.projects)
          .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)
      } catch (r) { }


      try {

        itens = itens +
          (day > 0 ? techs.os.filter(g => parseInt(moment(g.dt_cad).format('DD/MM/YY').toString().split('/')[0]) == parseInt(day)) :
            techs.os
          )
            .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)
      } catch (r) { }
      try {
        if (!general_params.associate_remote_access_ticket) {
          itens = itens + (day > 0 ?
            techs.assessments.filter(g => parseInt(moment(g.dt_cad).format('DD/MM/YY').toString().split('/')[0]) == parseInt(day))
            : techs.assessments)
            .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)
        }
      } catch (r) { }

      arrayvalues.push({
        type: 'column',
        name: k,
        color: colors[ii],
        data: [itens]
      }
      )
    })

    Object.keys(arraygroups || []).map((k, ii) => {
      let techs = arraygroups[k]
      let itens = (day > 0 ? techs.tickets.filter(g => parseInt(g.dt_cad.split('/')[0]) == parseInt(day)) : techs.tickets)
        .reduce((accum, item) => accum + this.toSecond(item.hours_consumed), 0)

      arrayvaluesgroup.push({
        type: 'column',
        name: k,
        color: colors[ii],
        data: [itens]
      }
      )
    })

    if (techs.length > 0) {
      data = techs.map(tec => {
        return {
          title: `${language.TECH} ${tec.name}`,
          hours: this.props.id_user > 0 ? null : `Total ${language.CONSUMED_VALUE} - ${formatCurrency(tec.total_values_hours)}`,
          color: 'var(--danger)',
          tableTickets: (<table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th className={'techrelcss____'}>{language.CALL}</th>
                <th className={'techrelcss____'}>{language.COMPANY}</th>
                <th className={'techrelcss____'}>{language.OPENING_DATE}</th>
                <th className={'techrelcss____'}>{language.CLOSING_DATE}</th>
                <th className={'techrelcss____'}>Status</th>

                <th className={'techrelcss____'}>{language.TITLE}</th>
                <th className={'techrelcss____'}>{language.DESCRIPTION}</th>
                <th className={'techrelcss__'} >{language.HOURS_CONSUMED}</th>

                {this.props.id_user > 0 ? null : <th>{language.CONSUMED_VALUE}</th>}
              </tr>
            </thead>
            <tbody>
              {tec.tickets.filter(v => daySelect != null ? parseInt(v.dt_cad.split('/')[0]) ==
                parseInt(daySelect) : v.dt_cad != null
              ).map(tic => {

                let delayed = this.isDelayed(tic.id_company, tic.dt_up) ? "isDelayed" : "isNot"
                return (
                  <tr>
                    <td id={delayed} className={'techrelcss____'}>{tic.id_ticket}</td>
                    <td id={delayed} className={'techrelcss____'}>{tic.company}</td>
                    <td id={delayed} className={'techrelcss____'}>{tic.dt_cad_new}</td>
                    <td id={delayed} className={'techrelcss____'}>{tic.progress == 100 ? tic.dt_up : '-'}</td>
                    <td id={delayed} className={'techrelcss____'}>{tic.status == "In Progress" ? language.TICKET_STATUS.IN_PROGRESS :
                      tic.status == "Reopened" ? language.TICKET_STATUS.REOPENED :
                        tic.status == "Open" ? language.TICKET_STATUS.OPEN :
                          tic.status == "Closed" ? language.TICKET_STATUS.CLOSED :
                            tic.status == "Resolved" ? language.TICKET_STATUS.RESOLVED  : ""
                    }</td>



                    <td id={delayed} className={'techrelcss____'}>{tic.subject}</td>
                    <td id={delayed} className={'techrelcss____'} dangerouslySetInnerHTML={{
                      __html: tic.description
                    }}></td>
                    <td id={delayed} className={'techrelcss__'}>{tic.hours_consumed}</td>

                    {this.props.id_user > 0 ? null :
                      <td id={delayed}>R$ {this.getValueToPay(tic.value_tech, tic.value_tech_additional, tic.id_company, tic.hours_consumed, tic.dt_up)}</td>
                    }

                  </tr>
                )
              })}
            </tbody>
          </table>),
          tableActivities: (<table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th className={'techrelcss___'}>{language.PROJECT}</th>
                <th className={'techrelcss___'}>{language.ACTIVITY}</th>
                <th className={'techrelcss__'}>{language.HOURS_CONSUMED}</th>
                {this.props.id_user > 0 ? null : <th>{language.CONSUMED_VALUE}</th>}
              </tr>
            </thead>
            <tbody>
              {tec.projects.filter(v => daySelect != null ? parseInt(moment(v.dt_cad).format('DD/MM/YY').toString().split('/')[0]) ==
                parseInt(daySelect) : v.dt_cad != null
              ).map(pro => {
                return (
                  <tr style={pro.canceled ? {flex: 0, backgroundColor: 'red' } : {}}>
                    <td className={'techrelcss___'}>{pro.project}{pro.canceled ? <small> ({language.PROJECT_CANCELED})</small> : ''}</td>
                    <td className={'techrelcss___'}>{pro.fk_id_project_activity + ' - ' + pro.activity}</td>
                    <td className={'techrelcss__'}>{pro.hours_consumed}</td>
                    {this.props.id_user > 0 ? null : <td>{formatCurrency(pro.value_consumed)}</td>}
                  </tr>
                )
              })}
            </tbody>
          </table>),
          tableOS: (<table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th className={'techrelcss___'}>OS</th>
                <th className={'techrelcss___'}>{language.name}</th>
                <th className={'techrelcss__'}>{language.HOURS_CONSUMED}</th>
                {this.props.id_user > 0 ? null : <th>{language.CONSUMED_VALUE}</th>}
              </tr>
            </thead>
            <tbody>

              {tec.os.filter(v => daySelect != null ? parseInt(moment(v.dt_cad).format('DD/MM/YY').toString().split('/')[0]) ==
                parseInt(daySelect) : v.dt_cad != null
              ).map(o => {
                return (
                  <tr>
                    <td className={'techrelcss___'}>{o.deleted ? o.id_os + ' (excluída)' : o.id_os}</td>
                    <td className={'techrelcss___'}>{o.objective}</td>
                    <td className={'techrelcss__'}>{o.hours_consumed}</td>
                    {this.props.id_user > 0 ? null : <td>{formatCurrency(o.value_consumed)}</td>}
                  </tr>
                )
              })}
            </tbody>
          </table>),
          tableAcesso: (<table className="table-data" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th className={'techrelcss___'}>{language.DEVICE}</th>
                <th className={'techrelcss___'}>{language.COMPANY}</th>
                <th className={'techrelcss___'}>{language.CONTRACT}</th>
                <th className={'techrelcss___'}>IP</th>
                <th className={'techrelcss___'}>{language.DATE}</th>
                <th className={'techrelcss__'} >{language.HOURS_CONSUMED}</th>
                {this.props.id_user > 0 ? null : <th>{language.CONSUMED_VALUE}</th>}
              </tr>
            </thead>

            <tbody>
              {!general_params.associate_remote_access_ticket && tec.assessments.filter(v => daySelect != null ? parseInt(moment(v.dt_cad).format('DD/MM/YY').toString().split('/')[0]) ==
                parseInt(daySelect) : v.dt_cad != null
              ).map(as => {
                return (
                  <tr>
                    <td className={'techrelcss___'}>{as.device}</td>
                    <td className={'techrelcss___'}>{this.getCompany(as.fk_id_company)}</td>
                    <td className={'techrelcss___'}>{this.getContract(as.fk_id_contract)}</td>

                    <td className={'techrelcss___'}>{as.ip}</td>
                    <td className={'techrelcss____'}>{moment(as.dt_cad).format('DD/MM/YY')}</td>
                    <td className={'techrelcss__'}>{as.hours_consumed}</td>
                    {this.props.id_user > 0 ? null : <td>{formatCurrency(as.value_consumed)}</td>}
                  </tr>
                )
              })}
            </tbody>
          </table>),
          tableChange: (<table className="table-data" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th className={'techrelcss_'}>{language.CHANGE}</th>
              <th className={'techrelcss__'}>{language.TYPE_ACTION}</th>
              <th className={'techrelcss___'}>{language.REASON}</th>
              <th className={'techrelcss__'}>{language.COMPANY}</th>
              <th className={'techrelcss__'}>{language.MANAGER}</th>
              <th className={'techrelcss____'}>{language.ACTIVITY}</th>
              <th className={'techrelcss___'}>{language.START_DATE}</th>
              <th className={'techrelcss___'}>{language.END_DATE}</th>
              <th className={'techrelcss__'} >{language.HOURS_CONSUMED}</th>
              {this.props.id_user > 0 ? null : <th>{language.CONSUMED_VALUE}</th>}
            </tr>
          </thead>

          <tbody>
            {tec.changes.map(ch => {
              return (
                <tr>
                  <td className={'techrelcss_'}>{ch.id_change_activity}</td>
                  <td className={'techrelcss__'}>{ch.type_action}</td>
                  <td className={'techrelcss___'}>{ch.reason}</td>
                  <td className={'techrelcss__'}>{ch.company}</td>
                  <td className={'techrelcss__'}>{ch.manager}</td>
                  <td className={'techrelcss____'}>{ch.activity}</td>
                  <td className={'techrelcss____'}>{moment(ch.start_date).format('DD/MM/YY')}</td>
                  <td className={'techrelcss____'}>{moment(ch.end_date).format('DD/MM/YY')}</td>
                  <td className={'techrelcss__'}>{ch.hours_consumed}</td>
                  {this.props.id_user > 0 ? null : <td>{formatCurrency(ch.value_consumed)}</td>}
                </tr>
              )
            })}
          </tbody>
        </table>),
        }
      });
    }

    let monthItem = language.DATE_LANG.monthLongList.map((month, index) => (
      <MenuItem key={month} value={index} primaryText={month} >
        {month}
      </MenuItem>
    ));

    var dataGraphUser = ContainerChart.container1({
      title: language.TECH,
      subtitle: language.TECHNICIAN_BY_VALUES_CONSUMED_TOTAL,
      yTitle: language.CONSUMED_VALUES,
      series: [
        {
          type: 'column',
          name: language.CONSUMED_VALUE,
          color: '#019FC2',
          data: techs.length > 0 ? techs.map(ug => ({ name: ug.name, y: Math.round(Number(ug.total_values_hours) * 100) / 100 })) : []
        },
        {
          type: 'column',
          name: language.HOURS,
          color: '#33ABA0',
          data: techs.length > 0 ? techs.map(ug => ({ name: ug.name, y: Math.round(Number(ug.total_hours) * 100) / 100 })) : []
        }
      ],
    })

    var dataGraphUserTicket = ContainerChart.container0({
      title: language.TECH,
      selectDay: this.selectDay,
      subtitle: language.TECHNICIAN_PER_HOURS_CONSUMED_TOTAL,
      yTitle: language.HOURS_CONSUMED,
      yAxis: {
        labels: {
          formatter: function () {
            // show the labels as whole hours (3600000 milliseconds = 1 hour)
            return Highcharts.numberFormat(this.value / 3600000, 0);
          }
        },
        title: {
          text: language.HOURS
        },
        tickInterval: 3600000 // number of milliseconds in one hour
      },
      categories: arraymes.map((e, i) => i + 1),

      series: [
        {
          type: 'column',
          name: language.CONSUMED_TIME,
          color: '#33ABA0',
          data: arraymes
        }
      ]
    },
    )

    var dataGraphUserByTotalTickets = ContainerChart.container3({
      title: language.TECH,
      selectDay: this.selectDay,
      subtitle: language.TOTAL_TICKETS_CLOSED_PER_DAY,
      yTitle: language.COMPLETED_TICKETS,
      categories: arraymes.map((e, i) => i + 1),
      series: [
        {
          type: 'line',
          name: language.COMPLETED_TICKETS,
          color: '#33ABA0',
          data: arrayTicketsClosedMonth
        }
      ]
    })

    var dataGraphUserTicketTotal = ContainerChart.container0({
      title: ' ' + language.HOURS_CONSUMED_PER_TOTAL_TEAM,
      selectDay: '',
      subtitle: `${language.PERIOD} ${this.state.day > 0 ? this.state.day +'/' : ''}${this.state.month + 1}/${this.state.year}`,
      yTitle: '',
      yAxis: {
        labels: {
          formatter: function () {
            // show the labels as whole hours (3600000 milliseconds = 1 hour)
            return Highcharts.numberFormat(this.value / 3600000, 0);
          }
        },
        title: {
          text: language.HOURS
        },
        tickInterval: 3600000 // number of milliseconds in one hour
      },
      categories: arrayvalues.sort(function (a, b) {
        return a.data[0] - b.data[0];
      }).map(a => a.name),

      series: [
        {
          type: 'column',
          name: language.CONSUMED_TIME,
          color: '#33ABA0',
          data: arrayvalues.sort(function (a, b) {
            return a.data[0] - b.data[0];
          }).map(a => a.data)
        }
      ]

    },
    )
    var dataGraphGroupTicketTotal = ContainerChart.container0({
      title: language.HOURS_CONSUMED_BY_TECHNICIAN_GROUP_ONLY_INCLUDES_TICKET,
      selectDay: '',
      subtitle: `${language.PERIOD} ${this.state.month + 1}/${this.state.year}`,
      yTitle: '',
      yAxis: {
        labels: {
          formatter: function () {
            // show the labels as whole hours (3600000 milliseconds = 1 hour)
            return Highcharts.numberFormat(this.value / 3600000, 0);
          }
        },
        title: {
          text: language.HOURS
        },
        tickInterval: 3600000 // number of milliseconds in one hour
      },
      categories: arrayvaluesgroup.sort(function (a, b) {
        return a.data[0] - b.data[0];
      }).map(a => a.name),

      series: [
        {
          type: 'column',
          name: language.CONSUMED_TIME,
          color: '#33ABA0',
          data: arrayvaluesgroup.sort(function (a, b) {
            return a.data[0] - b.data[0];
          }).map(a => a.data)
        }
      ]
    },
    )
    var dataGraphUserTicketTotaluniq = ContainerChart.container01({
      title: language.NUMBER_OF_TICKETS_PER_TECHNICIAN,
      selectDay: '',
      subtitle: `${language.PERIOD} ${this.state.month + 1}/${this.state.year}`,
      yTitle: '',
      yAxis: {
        labels: {
          formatter: function () {
            // show the labels as whole hours (3600000 milliseconds = 1 hour)
            return this.value
          }
        },
        title: {
          text: language.QUANTITY
        }
      },
      categories: arrayvaluesTicket.sort(function (a, b) {
        return a.data[0] - b.data[0];
      }).map(a => a.name),

      series: [
        {
          type: 'column',
          name: 'Total',
          color: '#33ABA0',
          data: arrayvaluesTicket.sort(function (a, b) {
            return a.data[0] - b.data[0];
          }).map(a => a.data)
        }
      ]
    },
    )

    var dataGraphUserTicketTotalByDay= ContainerChart.container3({
      title: language.NUMBER_OF_CLOSED_TICKETS_PER_TECHNICIAN_PER_DAY,
      selectDay: '',
      subtitle: `${language.PERIOD} ${this.state.month + 1}/${this.state.year}`,
      yTitle: '',
      multiple : true,
      categories: arrayGroupTicketsClosedMonth.length>0 ? arrayGroupTicketsClosedMonth[0].data[0].map((e, i) => i + 1) : [],

      yAxis: {
        labels: {
          formatter: function () {
            // show the labels as whole hours (3600000 milliseconds = 1 hour)
            return this.value
          }
        },
        title: {
          text: language.QUANTITY
        }
      },
      series: arrayGroupTicketsClosedMonth.map(a => ({name : a.name, data : a.data[0]}))
    }
    )

    return (
      <PageLayout
        icon={
          <div className="imgGcf" style={{ width: '44px', height: '44px' }} />
        }
        title={language.REPORTS}
        subtitle={language.REPORTS_FOR_TECH}
        back={() => handlePage('dash')}
        style={{ zoom: 0.8 }}
        rightElements={[
          Store.getUserLoged().role != 'tech' ? (
            <RaisedButton
              color='primary'
              label={language.PER_TEAM}
              primary
              onClick={() => this.handleValuesAll()}
            />
          ) : <div></div>,
          <ButtonControlYear value={year} onChange={this.handleValues} />,
          <div style={{ width: this.props.id_user > 0 ? 70 : 500 }}>

            <CommonForm
              spacing={16}
              values={{ fk_id_company: this.state.company, fk_id_tech: this.state.idtech }}
              alert={false}
              onChangeField={(e, v) => {
                if (e.name == 'fk_id_tech') {
                  this.handleValues(v, 'tech')
                } else {
                  this.setState({ company: v })
                }

              }}
              individual
              fields={[
                {
                  col: 6,
                  type: 'autocomplete',
                  name: 'fk_id_company',
                  label: language.COMPANY,
                  method: 'POST',
                  route: `${this.props.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: language.COMPANY,
                  textinit: '',
                  fieldvaluedb: 'id_company',
                  fieldlabeldb: 'name',
                  idinit: '',
                  routeAll: `${this.props.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${this.props.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: this.props.id_user > 0 ? false : true,
                  required: true,
                },
                {
                  col: 6,
                  type: 'autocomplete',
                  name: 'fk_id_tech',
                  label: language.TECH,
                  textlabel: language.TECH,
                  textinit: '',
                  className: 'tech-group-ticket',
                  required: true,
                  method: 'POST',
                  route: `${this.props.APIEndpoints.USER}/filter/all?`,
                  filter: { all_fk_id_company: this.state.company },
                  routeAll: `${this.props.APIEndpoints.USER}/filter/all?`,
                  filterAll: { all_fk_id_company: this.state.company },
                  routeGetCount: `${this.props.APIEndpoints.USER}/active/count/all?`,
                  fieldquery: 'search',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: '',
                  detail: true,
                  methodGetAll: 'POST',
                  visible: this.props.id_user > 0 ? false : true
                }
              ]} ></CommonForm>
          </div>
          ,
          <TextField
            select
            label={language.MONTH}
            style={{ minWidth: 200, zoom: .8 }}
            value={this.state.month}
            onChange={(e) => this.handleValues(e)}
            margin="dense"
            variant="outlined"
            name="month"
            className='mx-4'
          >
            {monthItem}
          </TextField>,
          <RaisedButton
            color='primary'
            label={language.TO_SEND}
            primary
            onClick={() => this.handleValues('enviar')}
          />,
          <RaisedButton
            color='primary'
            label={language.EXPORT + ' '}
            primary
            onClick={() => this.handleValues('enviar', 'excel')}
          />
        ]}
      >

        {this.state.openall == true ?
          <Dialog
            open={this.state.openall}
            bodyStyle={{ padding: 0, overflowY: 'none' }}
            title={
              ``
            }
            maxWidth="lg"
            onClose={() => this.setState({ openall: false })}
            contentProps={{
              className: 'pt-4'
            }}
          >

            <CommonForm
              spacing={16}
              values={{ fk_id_company: this.state.company, fk_id_tech: this.state.idtech }}
              alert={false}
              onChangeField={(e, v) => {
                this.setState({ day: v })

              }}
              values={{ 'day': day }}
              individual
              fields={[
                {
                  col: 3,
                  type: 'select',
                  name: 'day',
                  label: language.SELECT_THE_DAY,
                  options: [{ value: 0, label: language.ALL }, ...call_month.map((g, i) => ({ value: i + 1, label: i + 1 }))]
                  ,
                  required: true,
                  visible: this.props.id_user > 0 ? false : true
                }]
              } ></CommonForm>
            <ReactHighcharts config={dataGraphUserTicketTotal} />
            <br></br>
            <ReactHighcharts config={dataGraphUserTicketTotaluniq} />
            <br></br>
            <ReactHighcharts config={dataGraphUserTicketTotalByDay} />
            <br></br>
            <ReactHighcharts config={dataGraphGroupTicketTotal} />
          </Dialog> : null}
        <Card className="cardDefault -mt-2">
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              {this.props.id_user > 0 ? null :
                <ReactHighcharts config={dataGraphUser} />}
            </div>
            <div style={{ width: '50%' }}>
              <div style={{
                position: 'absolute',
                height: '30px',
                width: '50px',
                zIndex: '1000',
                marginLeft: '60px'

              }}>
                <Button
                  tooltipLabel={language.RELOAD_ALL}
                  variant="circle"
                  onClick={() => {
                    this.setState({ daySelect: null })
                  }}
                  color='primary'
                  className="mr-1"
                  icon="fas fa-redo"
                  size={.55}
                />
              </div>
              {this.props.id_user > 0 ? null :
                <ReactHighcharts config={dataGraphUserTicket} />}
            </div>
          </div>
        </Card>
        <Card style={{marginTop : 10}}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              {this.props.id_user > 0 ? null :
                <ReactHighcharts config={dataGraphUserByTotalTickets} />}
            </div>
          </div>
        </Card>
        <div className='mb-3'>
          <ul className="content-contract-data">
            {data.map((e, i) => (
              <React.Fragment>
                <li key={e.title} className="content py-2 mb-2" style={{ backgroundColor: e.color }}>
                  <span>{e.title}</span>
                  <span>{e.hours}</span>
                </li>
                <ul>
                  <li className="content content-table-data mb-3" key={i}>
                    {e.tableTickets}
                  </li>
                  <li className="content content-table-data mb-3" key={i}>
                    {e.tableAcesso}
                  </li>
                  <li className="content content-table-data mb-3" key={i}>
                    {e.tableActivities}
                  </li>
                  <li className="content content-table-data mb-3" key={i}>
                    {e.tableOS}
                  </li>
                  <li className="content content-table-data mb-3" key={i}>
                    {e.tableChange}
                  </li>
                </ul>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </PageLayout>
    );
  }
}

class LineBarAreaComposedChart extends Component {
  render() {
    let { language } = this.props;
    return (
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart layout="vertical" data={this.props.dataLineBar}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Legend />
          <Area dataKey="Total" fill="#ddd" stroke="#ddd" />
          <Bar dataKey="Ammount" barSize={20} fill="var(--primary)" />
          <Line dataKey="Trend" stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
