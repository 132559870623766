import React, { Component} from 'react';
import {Chip, Avatar} from '@material-ui/core';
import classnames from 'classnames';
import './style.css';
export default class RaisedChip extends Component{
  render(){
    const styles = {
      statusCustomIconAv: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        width: 48,
        border: '2px solid #0097bf',
        height: 48,
      },
     
      chip: {
        backgroundColor: 'inehit',
        background: 'linear-gradient(to right, #41c0e0 , #0097bf)',
        boxShadow: '0 5px 11px 0 rgba(0,0,0,.18), 0 1px 11px 0 rgba(0,0,0,.15)',
        color: 'white',
        fontSize: '1.1em',
        padding: '18px 0',
        borderRadius: 18,
        zIndex:2,
        // position:'relative'
      }
    };
    let {title, icon, style, className, fa, subHeader} = this.props;
    return(
      <div>
        <Chip
          style={{...styles.chip, ...style}}
          avatar={
            <Avatar style={styles.statusCustomIconAv} className="avatar">
              {fa?icon:(
                <div className={icon} style={{ width: '90%', height: '90%' }} />
              )}
            </Avatar>
          }
          label={title}
          className={className}
        />
        {!!subHeader && 
          <div>
            <Chip
              className={classnames('shadown custon-chip-header sub-chip', {'has-icon':!!subHeader.icon})}
              avatar={
                <Avatar style={styles.statusCustomIconAv} className="avatar">
                  {subHeader.fa?subHeader.icon:(
                    <div className={subHeader.icon} style={{ width: '90%', height: '90%' }} />
                  )}
                </Avatar>
              }
              label={subHeader.label||subHeader.title}
            />
          </div>
        }
      </div>
    )
  }
}