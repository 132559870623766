import React, { Component } from 'react'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine
} from 'recharts'

export default class AlocacaoDeEquipe extends Component {
  render() {
    const { data } = this.props
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid
            stroke="#d9d9d9"
            strokeDasharray="5 5"
            vertical={false}
          />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="p1"
            name="Projeto 1"
            stackId="a"
            barSize={50}
            fill="#82ca9d"
          />
          <Bar
            dataKey="p2"
            name="Projeto 2"
            stackId="a"
            barSize={50}
            fill="#5e92f3"
          />
          <Bar
            dataKey="p3"
            name="Projeto 3"
            stackId="a"
            barSize={50}
            fill="#ffc947"
          />
          <ReferenceLine y={10000} stroke="red" />
        </BarChart>
      </ResponsiveContainer>
    )
  }
}
