import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import Actions from '@data/Actions';
import Store from '@data/Store';

const action = new Actions();
const { language } = Store;

export default function ({
  id_tickets,
  APIEndpoints,
  tokenApi,
  historico,
  isIntegration,
  showEditDescription,
  onSave
}) {
  let [description, setDescription] = useState(``);
  let [load, setLoad] = useState(true);
  let [all, setAll] = useState(false);

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setAll(false);
      loadDescription();
    }, 1000);
  }, [id_tickets]);

  const loadDescription = (not) => {
    let endpoint = historico === true ? `${APIEndpoints.TICKET}/detailhistory` : `${APIEndpoints.TICKET}/detail`;
    let values = not == true ? { id_tickets: id_tickets, all: true } : { id_tickets: id_tickets };

    action
      .execute('post', endpoint, '', values)
      .then((res) => {
        setDescription(res.data.description)
        setLoad(false)
        if (not) {
          setAll(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const updateDescription = async () => {
    let values = {
      id_tickets,
      description
    };

    action
      .execute('put', `${APIEndpoints.TICKET}/description`, '', values)
      .then((res) => {
        onSave();
      }).catch((error) => {
        console.error(error);
      })
  }

  const renderAsView = () => {
    return isIntegration ? (
      <pre
        style={{ fontSize: 14, color: '#87868a', fontFamily: 'Open Sans', whiteSpace: 'pre-wrap' }}
        className="pr-3"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    ) : (
      <p
        style={{ fontSize: 14 }}
        className="pr-3"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    )
  }

  const renderAsEdit = () => {
    return (
      <>
        <CommonForm
          values={{ description }}
          onChangeField={(f, v) => {
            setDescription(v);
          }}
          alert={false}
          fields={[
            {
              type: isIntegration ? 'simplesTextEditor' : 'textEditor',
              name: 'description',
              placeholder: language.DESCRIPTION,
              required: true,
            }
          ]}
        />
        <Button
          icon="fas fa-save"
          variant="normal"
          color="success"
          fluid
          onClick={updateDescription}
          style={{ height: 40, marginTop: 15 }}
          label={language.SAVE}
        />
      </>
    );
  }

  return (
    load == true ?
      <CircularProgress color="secondary" /> :
      <PerfectScrollbar
        className="scrollVisible"
        style={{
          height: '100%',
          wordBreak: 'break-word',
        }}
        option={{
          suppressScrollX: true,
        }}
      >
        {showEditDescription ? renderAsEdit() : renderAsView()}
        {all == false && description.length == 900 ?
          <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => loadDescription(true)} >

            <div style={{ display: 'flex', top: '5px', alignItems: 'center', justifyContent: 'center' }}>
              <i style={{ color: '#f0b002' }} class="fas fa-angle-double-down"></i>
              <p style={{ margin: '2px' }}><b>{language.SHOW_MORE}</b></p>
              <i style={{ color: '#f0b002' }} class="fas fa-angle-double-down"></i>
            </div>
          </div>
          : null}
      </PerfectScrollbar>
  )
}
