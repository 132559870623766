import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Paper, Card } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import FaClose from 'react-icons/lib/fa/close';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';

@observer
export default class AttachDevice extends Component {

  render() {
    let { language, onFormSubmit, handlePage,APIEndpoints,company } = this.props;
 
   
    const dialogPaper = {

      minHeight: '80vh',
      maxHeight: '80vh',

    };
    let fields = [
      {
        col: 12,
        name: 'id_device',
        label: language.DEVICE,
        textlabel: language.DEVICES,
        type: 'autocomplete',
        method: 'POST',
        route: `${APIEndpoints.DEVICE}/filter/`,
        routeGetCount: `${APIEndpoints.DEVICE}/active/count/${company || 0}`,
        fieldquery: 'search',
        textinit:  language.DEVICE,
        fieldvaluedb: 'id_device',
        fieldlabeldb: 'name',
        idinit:     0,
        routeAll: `${APIEndpoints.DEVICE}/filter?`,
        filterAll: { fk_id_company: company || 1 },
        methodGetAll: 'POST',
        required: true,
        showEmail: true,
        isMulti: true
      }
    ];
    let button = { label: language.SAVE };

    return (
      <Dialog
        classes={{ paper: dialogPaper }}
        modal={false}
        open={this.props.open}
        monitor_scroll
        onClose={() => handlePage('list')}
        title={language.DEVICE_ATTACH}
        contentProps={{
          className: 'pt-4'
        }}
      >
      
          <CommonForm

            fields={fields}
            onSubmit={onFormSubmit}
            button={button}
          />
        

      </Dialog>
    );
  }
}
