import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import { MaskTel } from '@common/Mask';

let companiesOptions = [];
var id_company_selected = 0;

@observer
export default class ContractForm extends Component {
  constructor() {
    super();
    this.state = {
      type: 'type',
      company: 0,
      removeDepart: false
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(field, value) {
    if (field.name === 'type') {
      this.setState({
        type: value
      });
    } else if (field.name === 'id_company') {
      this.setState({
        company: value
      });

      id_company_selected = value;

      if (this.props.role == 'sponsor') {
        let filterCompayTer = companiesOptions.filter(co => co.value == value);

        if (filterCompayTer[0].type == 2) {
          this.setState({ removeDepart: true });
        } else {
          this.setState({ removeDepart: false });
        }
      }
    }
  }

  render() {
    let { language, handlePage, onFormSubmit, values, role } = this.props;

    let type = values ? values.type : this.state.type;

    let company = values ? values.id_company : id_company_selected;


    companiesOptions = this.props.companies.map(obj => ({
      value: obj.id_company,
      label: obj.name,
      type: obj.type
    }));

    let roleOptions = [
      { value: 'tech', label: language.TECH },
      { value: 'out', label: language.OUTSOURCED },
      { value: 'solicitant', label: language.SOLICITANT },
      { value: 'sponsor', label: language.SPONSOR }
    ];

    let typeOptions = [
      { value: 1, label: language.CLIENT },
      { value: 2, label: language.INTERNAL }
    ];

    let fields = [
      {
        col: 3,
        name: 'role',
        label: language.ROLE,
        type: 'select',
        required: true,
        options: roleOptions,
        disabled: true
      },
      {
        col: 3,
        name: 'name',
        label: language.FULL_NAME,
        type: 'text',
        required: true
      },
      {
        col: 3,
        name: 'userad',
        label: language.AD_USER,
        type: 'text',
        required: false
      },
      {
        col: 3,
        name: 'email',
        label: language.EMAIL,
        type: 'text',
        required: true
      },
      {
        col: 3,
        name: 'phone',
        label: language.PHONE,
        type: 'text',
        InputProps: {

          inputComponent: MaskTel
        },

      }, {
        col: 3,
        name: 'ramal',
        label: language.TELEPHONE_EXTENSION,
        type: 'text',
      }
    ];

    if (role === 'out') {
      fields.push({
        col: 3,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        required: true,
        options: companiesOptions
      });
    } else if (role === 'solicitant' || role === 'sponsor') {
      if (role === 'sponsor' && this.props.values && this.props.values.sponsor)
        this.props.values.role = 'sponsor';
      fields.push({
        col: 3,
        name: 'registration',
        label: language.REGISTRATION,
        type: 'text',
        required: false
      });

      let departmentOptions = [];
      if (company) {
        this.props.companies
          .filter(c => c.id_company == company)[0]
          .department.map(obj => {
            departmentOptions.push({
              value: obj.id_department,
              label: obj.name
            });
            if (obj.sub_dep.length) {
              obj.sub_dep.map(sd => {
                departmentOptions.push({
                  value: sd.id_department,
                  label: sd.name
                });
              });
            }
          });
      }

      fields.push({
        col: 3,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        required: true,
        options: companiesOptions
        //disabled: !!(values && values.id_company)
      });

      if (!this.state.removeDepart) {
        fields.push({
          col: 3,
          name: 'fk_id_department',
          label: language.DEPARTMENT,
          type: 'select',
          required: true,
          options: departmentOptions
        });
      }
    } else if (role === 'tech') {

      let departmentOptions = [];
      if (type === 1) {
        if (company) {
          this.props.companies
            .filter(c => c.id_company == company)[0]
            .department.map(obj => {
              departmentOptions.push({
                value: obj.id_department,
                label: obj.name
              });
              if (obj.sub_dep.length) {
                obj.sub_dep.map(sd => {
                  departmentOptions.push({
                    value: sd.id_department,
                    label: sd.name
                  });
                });
              }
            });
        }
      } else {



        this.props.companies.filter(c => c.id_company == 1)[0]
          .department.map(obj => {
            departmentOptions.push({
              value: obj.id_department,
              label: obj.name
            });
          })
      }



      fields.push({
        col: 3,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        required: true,
        options: typeOptions,
        disabled: !!(values && values.type)
      });
      fields.push({
        col: 3,
        name: 'hour_value',
        label: language.HOUR_VALUE_TECH,
        type: 'number'
      });
      if (type === 1) {
        fields.push({
          col: 3,
          name: 'id_company',
          label: language.COMPANY,
          type: 'select',
          required: true,
          options: companiesOptions,
          disabled: !!(values && values.type)
        });
      }
      fields.push({
        col: 3,
        name: 'fk_id_department',
        label: language.DEPARTMENT,
        type: 'select',
        required: true,
        options: departmentOptions
      });
    }

    let button = {

      label: language.SAVE,

    };
    return (
      <CommonForm
        values={this.props.values}
        fields={fields}
        onSubmit={onFormSubmit}
        button={button}
        onChangeField={this.onChangeField}
        loading={['post-/dash/configuration/generalsettings', 'put-/dash/configuration/generalsettings']}
      />
    );
  }
}
