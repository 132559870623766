import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Toolbar, Typography, Paper, IconButton, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Switch } from '@material-ui/core';

import { PRIORITY_COLOR_MAP, STATUS_COLOR_MAP, decrypt, getPriorityIndicator, getPriorityMap, getStatusMap, getTicketStatusValues, parseJwt, randomId, spFetch, useDebounce, useServicePortalStyles } from './helpers';
import ServicePortalWrapper from './ServicePortalWrapper';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Tag from '@common/Tag';
import moment from 'moment';
import classNames from 'classnames';
import Search from './components/Search';
import { RefreshOutlined } from '@material-ui/icons';
import TicketIconType from './components/TicketIconType';
import { ServicePortalSubHeader } from './components/ServicePortalSubHeader';
import { Link } from 'react-router-dom';
import LinearProgressWithLabel from './components/LinearProgressWithLabel';
import ServicePortalTableWrapper from './components/ServicePortalTableWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        marginRight: 20
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    tableCell: {
        lineHeight: 'inherit'
    }
}));

export default function ServicePortalTickets({ params }) {

    const { language } = Store;
    const tokenData = parseJwt(sessionStorage.getItem('token'));
    const classes = useStyles();
    const styles = useServicePortalStyles();

    const filterStatusDefault = ["Open", "In Progress", "Aguardando", "Reopened", "Resolved"];

    const [isLoading, setIsLoading] = useState(false);
    const [isInCategorization, setIsInCategorization] = useReducer((s, v) => v ?? !s, false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [rowsCount, setRowsCount] = useState(0);

    const [filterPriority, setFilterPriority] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterText, setFilterText] = useState('');
    const filterTextSearch = useDebounce(filterText, 300);

    const hasFilters = filterPriority || filterStatus || filterText;

    const [rows, setRows] = useState([]);
    const [priorities, setPriorities] = useState([]);

    const [reload, setReload] = useState(randomId());

    const PRIORITY_MAP = getPriorityMap(language);

    const STATUS_MAP = getStatusMap(language);

    const TYPE_MAP = {
        1: language.TICKET_TYPES.INCIDENT,
        2: language.TICKET_TYPES.PROBLEM,
        3: language.TICKET_TYPES.INCIDENT,
        4: language.REQUEST
    }

    const HEAD_COLUMNS = [
        {
            id: 'typeLabel',
            label: ' ',
            hidden: isInCategorization,
            disablePadding: true,
            cellProps: { padding: 'checkbox' },
            render: ({ row }) => <TicketIconType label={row.typeLabel} type={row.type_tickets} />
        },
        {
            id: 'id_request',
            label: `#${language.TICKET}`,
            cellProps: { size: 'small' },
            render: ({ row }) => {
                return (
                    <>
                        {!row.isRequest &&
                            <Link to={`/dash/service-portal/my-tickets/${row.id_tickets}`} className='hover:tw-underline'>
                                {row.identifier}
                            </Link>}
                        {row.isRequest && <>{row.identifier}</>}
                    </>
                )
            }
        },
        {
            id: 'subject',
            label: language.SUBJECT,
            cellProps: { className: 'tw-truncate tw-max-w-xs', style: { maxWidth: 300 } }
        },
        // {
        //     id: 'grid_company',
        //     label: language.COMPANY,
        //     render: ({ row }) => <>{row.name_company || row.grid_company}</>
        // },
        {
            id: 'grid_category',
            label: language.REQUESTERSDASH,
            cellProps: { className: 'tw-flex', style: { maxWidth: 260 } },
            render: ({ row }) => {
                return (
                    <div className={classNames(styles.description, 'tw-flex tw-flex-col tw-flex-1 tw-min-w-0')}>
                        <div className='tw-truncate' title={row.grid_category}>
                            <span className='tw-text-gray-400'></span>
                            <span className='tw-truncate'>
                                {isInCategorization ? row.namecategory_ : row.grid_category}
                            </span>
                        </div>
                        <div className='tw-truncate tw-flex' style={{ gap: '0.1rem' }} title={row.grid_catalog_service}>
                            <span className='tw-text-gray-400'>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(-90deg)' }} width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.854 14.854a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V3.5A2.5 2.5 0 0 1 6.5 1h8a.5.5 0 0 1 0 1h-8A1.5 1.5 0 0 0 5 3.5v9.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4z" />
                                </svg>
                            </span>
                            <span className='tw-truncate'>
                                {isInCategorization ? row.catalogservicename_ : row.grid_catalog_service}
                            </span>
                        </div>
                        <div className='tw-truncate tw-flex' style={{ gap: '0.1rem', paddingLeft: '14px' }} title={row.grid_catalog_task}>
                            <span className='tw-text-gray-400'>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(-90deg)' }} width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.854 14.854a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V3.5A2.5 2.5 0 0 1 6.5 1h8a.5.5 0 0 1 0 1h-8A1.5 1.5 0 0 0 5 3.5v9.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4z" />
                                </svg>
                            </span>
                            <span className='tw-truncate'>
                                {isInCategorization ? row.catalogtaskname_ : row.grid_catalog_task}
                            </span>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'nametech',
            label: language.TECH,
            hidden: isInCategorization
        },
        {
            id: 'nameusers',
            label: language.TECH_GROUP,
            hidden: !isInCategorization
        },
        {
            id: 'priority',
            label: language.PRIORITY,
            render: ({ row }) => <Tag label={row.indicatorLabel} color={row.indicatorColor} style={{ fontSize: 'inherit' }} />
        },
        {
            id: 'progress',
            label: language._PROGRESS,
            hidden: isInCategorization,
            cellProps: { style: { minWidth: 170 } },
            render: ({ row }) => <LinearProgressWithLabel variant="determinate" value={row.progress ?? 0} />
        },
        {
            id: 'dt_cad',
            label: language.DATE,
            render: ({ row }) => row.dateFormatted
        },
        {
            id: 'status',
            label: language.STATUS,
            render: ({ row }) => <Tag label={row.statusLabel} color={row.statusColor} style={{ fontSize: 'inherit' }} />
        },
    ];



    useEffect(() => {
        setIsLoading(true);
        const priorities = sessionStorage.getItem('priorities');
        setPriorities(JSON.parse(decrypt(priorities)));
    }, [])

    useEffect(() => {
        priorities?.length > 0 && getData();
    }, [priorities, filterTextSearch, filterStatus, filterPriority, page, isInCategorization, reload]);

    const getData = () => {
        isInCategorization ? getRequests() : getTickets();
    }

    const getRequests = () => {
        setIsLoading(true);
        const urlRequests = `${Constants.APIEndpoints.REQUEST}/list`;

        const requestPayload = {
            filter_user_group: tokenData.iss.id,
            language: 'pt-BR',
            limit: rowsPerPage,
            offset: page,
            sortByDesc: null,
            statusFilter: filterStatus ? [filterStatus] : filterStatusDefault,
            filterFind: filterText,
            originFrom: 'service-portal',
            filterColumns: {
                grid_priority: filterPriority
            }
        }

        spFetch(urlRequests, 'post', requestPayload, { responseHeaders: true })
            .then((response) => {
                const result = processResults(response, priorities);
                setRows(result.data);
                setRowsCount(result.count);
            })
            .finally(() => setIsLoading(false));
    }

    const getTickets = () => {
        setIsLoading(true);
        const urlTickets = `${Constants.APIEndpoints.TICKET}/ticketFind?limit=${rowsPerPage}&offset=${page}`;

        const ticketPayload = {
            filter_user_group: tokenData.iss.id,
            language: 'pt-BR',
            sortByDesc: true,
            //sortBy: "grid_priority",
            statusFilter: filterStatus ? [filterStatus] : filterStatusDefault,
            filterFind: filterText,
            originFrom: 'service-portal',
            filterColumns: {
                grid_priority: filterPriority
            }
        }

        spFetch(urlTickets, 'post', ticketPayload, { responseHeaders: true })
            .then((response) => {
                const result = processResults(response, priorities);
                setRows(result.data);
                setRowsCount(result.count);
            })
            .finally(() => setIsLoading(false));

    }

    const processResults = (result, priorityResult) => {
        if (!Array.isArray(result.data) || result.data.length === 0) return { data: [], count: 0 };

        const getDateField = (item) => item.status == 'Closed' || item.status == 'Resolved' ? 'dt_up' : 'grid_date';

        const data = result.data.map(item => {
            const indicator = getPriorityIndicator(item.fk_id_priority, priorityResult);
            const isRequest = item.tp === 'request';
            const statusValues = getTicketStatusValues(language, item.status, item.stop_ticket);
            return {
                ...item,
                isRequest,
                dateFormatted: isRequest ? moment(item.dt_cad).format('L LT') : moment(item[getDateField(item)]).format('L LT'),
                indicator,
                identifier: isRequest ? `R${item.id_request}` : item.grid_id,
                statusLabel: statusValues.label,
                statusColor: statusValues.color,
                indicatorLabel: PRIORITY_MAP[indicator] || null,
                indicatorColor: PRIORITY_COLOR_MAP[indicator] || null,
                progressColor: item.progress > 66 ? 'var(--success-light)' : item.progress > 33 ? 'var(--warning)' : 'var(--danger)',
                typeLabel: TYPE_MAP[item.type_tickets],
            };
        });

        let count = data.length;

        if (isInCategorization)
            count = data?.[0].count;
        else if (result.headers['x-total-count'])
            count = parseInt(result.headers['x-total-count']);

        return { data, count };
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterStatusChange = (event) => {
        setPage(0);
        setFilterStatus(event.target.value);
    };

    const handleFilterPriorityChange = (event) => {
        setPage(0);
        setFilterPriority(event.target.value)
    }

    const handleFilterTextChange = (event) => {
        setPage(0);
        setFilterText(event.target.value);
    };

    const handleFilterCategorizationChange = () => {
        setPage(0);
        setIsInCategorization()
    }

    const handleReload = () => setReload(randomId());

    return (
        <>
            <ServicePortalSubHeader breadcrumbs={[<Typography component='span' className={styles.text}>{language['SERVICE_PORTAL']['MENU_TICKET']}</Typography>]} />
            <ServicePortalWrapper>
                <div className='tw-w-full tw-overflow-y-auto'>
                    <Paper variant="outlined" className={classes.paper}>
                        <Toolbar className={classes.toolbar}>
                            <div className='tw-flex tw-justify-between tw-w-full'>

                                <Search label={language.SEARCH} onChange={handleFilterTextChange} />

                                <div className='tw-flex tw-gap-5'>

                                    <IconButton className={classes.iconButton} aria-label={language.REFRESH_PAGE} onClick={handleReload}>
                                        <RefreshOutlined fontSize="medium" />
                                    </IconButton>

                                    {!params.automaticCategorization &&
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={isInCategorization}
                                                    onChange={handleFilterCategorizationChange}
                                                    name="isInCategorization"
                                                    size="small"
                                                    color="primary"
                                                />
                                            }
                                            label={<Typography className={styles.text}>{language.SERVICE_PORTAL.IN_CATEGORIZATION}</Typography>}
                                        />
                                    }

                                    <FormControl variant="filled" size='small'>
                                        <InputLabel shrink={true} className={styles.text}>{language.PRIORITY}</InputLabel>
                                        <Select
                                            displayEmpty
                                            value={filterPriority}
                                            disableUnderline={true}
                                            onChange={handleFilterPriorityChange}
                                            style={{ minWidth: 200, borderRadius: 4 }}
                                            className={styles.title}
                                        >
                                            <MenuItem value="" dense={true} className={classNames(styles.menuItemSmall)}>{language.SELECT}</MenuItem>
                                            {priorities.map(priority =>
                                                <MenuItem key={priority.description} value={priority.description} dense={true} className={classNames(styles.menuItemSmall)}>
                                                    {priority.description}
                                                </MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="filled" size='small' disabled={isInCategorization}>
                                        <InputLabel shrink={true} className={styles.text}>{language.STATUS}</InputLabel>
                                        <Select
                                            displayEmpty
                                            value={filterStatus}
                                            disableUnderline={true}
                                            onChange={handleFilterStatusChange}
                                            style={{ minWidth: 200, borderRadius: 4 }}
                                            className={styles.title}
                                        >
                                            <MenuItem value="" dense={true} className={classNames(styles.menuItemSmall)}>{language.SELECT}</MenuItem>
                                            {
                                                Object.keys(STATUS_MAP).map(statusKey =>
                                                    <MenuItem key={statusKey} value={statusKey} dense={true} className={classNames(styles.menuItemSmall)}>
                                                        {STATUS_MAP[statusKey]}
                                                    </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Toolbar>

                        <ServicePortalTableWrapper
                            isLoading={isLoading}
                            columns={HEAD_COLUMNS}
                            rows={rows}
                            size='small'
                            pagination={{
                                page: page,
                                count: rowsCount,
                                rowsPerPage: rowsPerPage,
                                enabled: true
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                    </Paper>
                </div>
            </ServicePortalWrapper>
        </>
    );
}
