import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import Help from '../../common/components/Help';
import { Container, Row, Col } from 'react-grid-system';
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDesktop,
  faMemory,
  faMicrochip,
  faServer,
  faHdd,
  faDatabase,
  faLaptop
} from '@fortawesome/free-solid-svg-icons';
import { Paper, List, ListItem, Grid } from '@material-ui/core';
import './InventoryListHardware.css';
import AppBar from '@common/AppBar';
import CommonPage from '@common/CommonPage';

@observer
class RaisedList extends Component {
  render() {
    let { title, icon, list } = this.props;
    return (
      <Paper className='paper paper-list-custom' style={{ margin: '20px auto', height: '100%' }}>
        <AppBar light>

          <div className='d-flex align-items justify-content-center'>
            <FontAwesomeIcon icon={icon} />
            <span className='mx-1'>{title}</span>
          </div>
        </AppBar>
        <List className='list listMin'>
          {list.map((e, i) => (
            <ListItem className='listItem justify-content-start' dense button key={i}>
              <span style={{ color: 'var(--warning)', fontWeight: 600 }} className='mr-1'>{e.label}:</span> {e.content}
            </ListItem>
          ))}

        </List>
      </Paper>
    )
  }
}
export default class InventoryListHardware extends CommonPage {
  constructor() {
    super();
    this.state = {
      render2: false
    };
  }

  componentWillMount() {
    let { values } = this.props;

    if (values) {

      this.props.action.execute(
        'get',
        `${this.props.APIEndpoints.INVENTORY}/${values.id_inventory_collector
        }/hardware`,
        'hardwares'
      );
    }
  }

  componentDidMount() {
    var my_array = this.props.hardwares;
    this.hardwareLastElement = my_array[my_array.length - 1];
    //console.log(this.hardwareLastElement);
  }

  render() {
    var { handlePage, language, hardwares } = this.props;

    // console.log(this.props.device);
    //console.log(hardwares);
    var h = hardwares.length > 0 ? hardwares.filter(e => !!e)[0] : [];
    return (
      <PageLayout
        icon={
          <div className="inventaryIcon" style={{ width: 40, height: 40 }} />
        }
        title={language.INVENTORY}
        subtitle={language.HARDWARE_LIST}
        rightElements={[]}
      >
        <div id='InventoryListHardware'>
          <Grid container spacing={3} style={{ marginBottom: 60 }}>
            <Grid item xs={6}>
              <RaisedList
                title='CPU'
                icon={faMicrochip}
                list={[
                  {
                    label: language.CPU,
                    content: h.cpu_name,
                  },
                  {
                    label: 'Cache 1',
                    content: h.cpu_l2_cache,
                  },
                  {
                    label: 'Cache 2',
                    content: h.cpu_l3_cache,
                  },
                  {
                    label: language.CLOCK_SPEED,
                    content: h.cpu_max_clock_speed,
                  },
                  {
                    label: language.NUMBERS_CORE,
                    content: h.cpu_number_cores,
                  },
                  {
                    label: language.CLOCK_SPEED,
                    content: h.cpu_current_clock_speed,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}>

              <RaisedList
                title='Memory'
                icon={faMemory}
                list={[
                  {
                    label: language.CAPACITY_MEMORY,
                    content: (h.memory_capacity / 1024 / 1024 / 1024) + 'GB',
                  },
                  {
                    label: language.MEMORY_MANUFACTURER,
                    content: h.memory_manufacturer,
                  },
                  {
                    label: language.MEMORY_PART_NUMBER,
                    content: h.memory_part_number,
                  },
                  {
                    label: language.MEMORY_SERIAL_NUMBER,
                    content: h.memory_serial_number,
                  }
                ]}
              />

            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: 60 }}>
            <Grid item xs={6}>
              <RaisedList
                title='Board'
                icon={faLaptop}
                list={[
                  {
                    label: language.BOARD_MANUFACTURER,
                    content: h.board_manufacturer,
                  },
                  {
                    label: language.BOARD_PRODUCT,
                    content: h.board_product,
                  },
                  {
                    label: language.BOARD_SERIAL_NUMBER,
                    content: h.board_serial_number,
                  },
                  {
                    label: language.BOARD_VERSION,
                    content: h.board_version,
                  }
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <RaisedList
                title='Bios'
                icon={faMicrochip}
                list={[
                  {
                    label: language.BIOS_MANUFACTURER,
                    content: h.bios_manufacturer,
                  },
                  {
                    label: language.BIOS_NAME,
                    content: h.bios_name,
                  },
                  {
                    label: language.BIOS_SERIAL_NUMBER,
                    content: h.bios_serial_number,
                  },
                  {
                    label: language.BIOS_VERSION,
                    content: h.bios_version,
                  }
                ]}
              />

            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: 60 }}>

            <Grid item xs={6}>
              <RaisedList
                title='Os'
                icon={faServer}
                list={[
                  {
                    label: language.SO_BUILD_NUMBER,
                    content: h.os_build_number,
                  },
                  {
                    label: language.SO_CAPTION,
                    content: h.os_caption,
                  },
                  {
                    label: language.SO_VERSION,
                    content: h.os_version,
                  },
                  {
                    label: language.VIRTUAL_MEMORY,
                    content: h.os_total_virtual_memory,
                  },
                  {
                    label: language.LAST_BOOT_TIME,
                    content: h.os_last_boot_time,
                  },
                  {
                    label: language.COUNTRY_CODE,
                    content: h.os_country_code,
                  },
                  {
                    label: language.INSTALL_DATE,
                    content: h.os_install_date,
                  },
                  {
                    label: language.REGISTERED_USER,
                    content: h.os_registered_user,
                  },
                  {
                    label: language.SERIAL_NUMBER,
                    content: h.os_serial_number,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <RaisedList
                title='Logical Disk'
                icon={faHdd}
                list={[
                  {
                    label: language.LEGEND,
                    content: h.logicaldisk_caption,
                  },
                  {
                    label: language.VOLUME_NAME,
                    content: h.logicaldisk_volume_name,
                  },
                  {
                    label: language.SO_VERSION,
                    content: h.os_version,
                  },
                  {
                    label: language.FILE_SYSTEM,
                    content: h.logicaldisk_file_system,
                  },
                  {
                    label: language.SIZE,
                    content: ((h.logicaldisk_size / 1024 / 1024 / 1024).toFixed()) + 'Gb',
                  },
                  {
                    label: language.FREE_SPACE,
                    content: ((
                      h.logicaldisk_free_space /
                      1024 /
                      1024 /
                      1024
                    ).toFixed()) + 'Gb',
                  },
                  {
                    label: language.VOLUME_SERIAL_NUMBER,
                    content: h.logicaldisk_volume_serial_number,
                  }
                ]}
              />

            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: 60 }}>
            <Grid item xs={6}>
              <RaisedList
                title='GPU'
                icon={faDesktop}
                list={[
                  {
                    label: language.GPU_CAPTION,
                    content: h.gpu_caption,
                  },
                  {
                    label: language.GPU_ADAPTER,
                    content: h.gpu_adapter_ram,
                  }
                ]}
              />

            </Grid>
            <Grid item xs={6}>
              <RaisedList
                title='Disk'
                icon={faDatabase}
                list={[
                  {
                    label: language.LEGEND,
                    content: h.disk_caption,
                  },
                  {
                    label: language.FIRMWARE_VERSION,
                    content: h.disk_firmware_revision,
                  },
                  {
                    label: 'Bytes Sector',
                    content: h.disk_bytes_sector,
                  },
                  {
                    label: language.MANUFACTURER,
                    content: h.disk_manufacturer,
                  },
                  {
                    label: language.SERIAL_NUMBER,
                    content: h.disk_serial_number,
                  },
                  {
                    label: language.SIZE_DISK,
                    content: ((h.disk_size / 1024 / 1024 / 1024).toFixed()) + 'Gb',
                  },
                  {
                    label: language.DISK_SECTOR,
                    content: ((h.disk_sectors / 1024 / 1024 / 1024).toFixed()) + 'Gb'
                  },
                  {
                    label: language.DISK_TRACKS,
                    content: h.disk_tracks,
                  },
                  {
                    label: language.DISK_HEADER,
                    content: h.disk_heads,
                  }
                ]}
              />

            </Grid>
          </Grid>
          <br />
          <br />
        </div>
      </PageLayout>
    );
  }
}
