import React, {Fragment, useState, useReducer} from 'react';
import {Avatar, Card} from '@material-ui/core';
import classnames from 'classnames';
import Tag from '@common/Tag';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from '@common/Button';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';
import './index.css';

const { language } = Store;

var enumerateDaysBetweenDates = function(startDate, endDate) {
    startDate = moment(new Date(startDate));
    endDate   = moment(new Date(endDate));
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(new Date(now));
        now.add(1, 'days');
    }
    return dates;
};

export default function({
	spaceCollumn=42, 
	startDate=moment().add(-4, 'days'), 
	endDate=moment().add(14, 'days'),
	group=[]
}){
	
	
	let [open, setOpen] = useState(false);
	let [filterTags, setFilterTags] = useReducer((state, value) => {

		if(state.indexOf(value)!=-1){
			return state.filter(e=>e!=value)
		}else{
			return [...state, value];
		}
	},[])
	

	var rangeDate = enumerateDaysBetweenDates(startDate, endDate);
	
	function positionDate(dt_i, dt_f) {
		var _dt_i = moment(dt_i).diff(moment(startDate), 'minutes');
		_dt_i = (_dt_i*42)/(60*24);

		var _dt_f = moment(dt_f).diff(moment(dt_i), 'minutes');
		_dt_f = (_dt_f*42)/(60*24);

		return {position:_dt_i, width:Math.abs(_dt_f)};
	};

	const isB = (dt, dt_i, dt_f) => moment(dt).isBetween(dt_i, dt_f, 'day');
	
	var act;
	var pd;
	var pd_visible;
	var name_width;
	var activities;
	var HPercent = open?1:(11/30);


	return(
		<Card id='Gantt' className='content px-0 pb-2' style={{'--spaceCollumn':spaceCollumn+'px'}}>
			<PerfectScrollbar
		  	  className="scrollVisible gantt-scroll"
		  	  style={{
		  	    maxHeight:400,
		  	    zIndex:9
		  	  }}
		  	  
		  	>
				<div className='dates-content d-flex align-items-center mb-2' style={{width:(rangeDate.length*spaceCollumn)}}>
					{rangeDate.map((e,i)=>(
						<div className='d-flex align-items-center justify-content-center'>
							{moment(e).isSame(new Date(), 'days')?(
								<span style={{ color: 'var(--success)' }}>{language.TODAY}</span>
							):(
							<Fragment>
								<span>{moment(e).format('DD/')}</span>
								<span>{moment(e).format('MMM')}</span>
							</Fragment>
							)}
						</div>
					))}
				</div>
			
				{group.map((a,l)=>{
					
					activities = a.activities.filter(e=>{
						return e.tags.filter(e=>!filterTags.includes(l+'-'+e)).length
					});
					activities.forEach(e=>{
						delete e.line;
					})
					activities.forEach((e,i)=>{
						
						act = activities.filter(act=>
							act.line!=undefined&&
							(
								(moment(e.dt_i).isSame(act.dt_i)||isB(e.dt_i, act.dt_i, act.dt_f))||
								(moment(e.dt_f).isSame(act.dt_f)||isB(e.dt_f, act.dt_i, act.dt_f))||
								(
									(moment(e.dt_i).isSame(act.dt_i)||moment(e.dt_i).isBefore(act.dt_i))&&
									(moment(e.dt_f).isSame(act.dt_f)||moment(e.dt_f).isAfter(act.dt_f))
								)
							)
						).map(e=>e.line);
						
						act = Math.max(...act);
						
						if(!isFinite(act)){
							activities[i].line = 1;
						}else{
							activities[i].line = act+1;
						}
						
					})

					var maxH = Math.max(...a.activities.map(e=>e.line));
					return (
						
						<div 
							key={l} 
							className='gantt-content d-flex align-items-center' 
							style={{
								width:(rangeDate.length*spaceCollumn)+210, height:((maxH?49*maxH:49)-(9.5*(maxH-1)))*HPercent
							}}
						>
								
							<div className='content-info-control d-flex align-items-center pr-3'>
								<div className='w-100'>
									<div className='content-tags d-flex align-items-center mb-1'>
										{(a.tags&& a.tags.length>2?a.tags.slice(0,3): a.tags ).map((e,i)=>(
											<Tag 
												key={i} 
												onClick={()=>setFilterTags(l+'-'+e.id)}
												label={e.label} 
												zoom={.8} 
												
												style={{
													zoom:.8, 
													border:'1px solid '+e.color,
													backgroundColor:(filterTags.includes(l+'-'+e.id)?'transparent':e.color),
													color:(filterTags.includes(l+'-'+e.id)?'black':'white'),
													cursor:'pointer'
												}}
												className={classnames('mb-0', {'ml-0':i==0})}
											/>
										))}
										{a.tags.length>3&&
											<Tooltip
											  interactive
											  html={
											    <div style={{ color: '#fff', fontSize: 11, maxWidth:210, flexWrap:'wrap' }} className='d-flex align-items-center'>
											      {a.tags.slice(3).map((e, i) => (
											        <Tag 
											        	key={i} 
											        	onClick={()=>setFilterTags(l+'-'+e.id)}
											        	label={e.label} 
											        	zoom={.8} 
											        	
											        	style={{
											        		zoom:.8, 
											        		border:'1px solid '+e.color,
											        		backgroundColor:(filterTags.includes(l+'-'+e.id)?'transparent':e.color),
											        		color:(filterTags.includes(l+'-'+e.id)?'black':'white'),
											        		cursor:'pointer'
											        	}}
											        />
											      ))}
											    </div>
											  }
											  position="right"
											  theme="light"
											>
											  
											    <span className="number-more">
											      +{a.tags.slice(3).length}
											    </span>
											  
											</Tooltip>
										}
									</div>
									{open&&
										<div className='content-avatares d-flex align-items-center'>
											{a.avatares.slice(0,7).map((e,i)=>(
												<Avatar key={i} className='avatar'>
													{e.substr(0,2)}
												</Avatar>
											))}
											{a.avatares.length>7&&
												<Tooltip
												  html={
												    <div style={{ color: '#fff', fontSize: 11 }}>
												      {a.avatares.slice(7).map((u, i) => (
												        <div
												          style={{ marginLeft: '5px', marginTop: '2px' }}
												          className="d-flex align-items-center"
												        >
												          <Avatar
												            className={'avatar'}
												            key={i}
												            style={{
												              fontSize: '10px',
												              width: '17px',
												              height: '17px',
												              
												            }}
												          >
												            {u
												              .substr(0, 2)
												              .toString()
												              .toUpperCase()}
												          </Avatar>
												          <span style={{ color: 'black', marginLeft: '4px' }}>{u}</span>
												        </div>
												      ))}
												    </div>
												  }
												  position="right"
												  theme="light"
												>
												  
												    <span className="number-more">
												      +{a.avatares.slice(7).length}
												    </span>
												  
												</Tooltip>
											}
										</div>
									}
								</div>
							</div>
							<div className='content-info-activities d-flex align-items-center' style={{width:(rangeDate.length*spaceCollumn)}}>
								{activities.map((e,i)=>{
									pd = positionDate(e.dt_i, e.dt_f);
									pd_visible = (pd.width>=spaceCollumn*3)||(!open&&pd.width>spaceCollumn);
									name_width = !pd_visible?{width:'100%'}:{}

									
									return(
										<div 
											key={i}
											className={
												classnames(
													'd-flex align-items-center item-activity', 
													pd_visible?'justify-content-between':'justify-content-center'
												)
											}
											style={{
												left:pd.position,
												top:((9.5*e.line)+(30*(e.line-1)))*HPercent,
												width:pd.width,
												height:30*HPercent,
												backgroundColor:e.color
											}}
										>


                                           <Tooltip html={
											    <div style={{ color: '#fff', fontSize: 11 }}>
											      {e.label}
											    </div>
											  }
											  arrow={true}
											  position="right"
											  theme="dark"
											  
											>
                            
											{pd_visible&&
												<span className='dt_i'>{moment(e.dt_i).format('DD/MMM')}</span>
											}
											</Tooltip>
											 
											<Tooltip
											  html={
											    <div style={{ color: '#fff', fontSize: 11 }}>
											      {e.label}
											    </div>
											  }
											  arrow={true}
											  position="right"
											  theme="dark"
											   
											>
												{open&&(pd.width>=spaceCollumn*2)?(
													<span className='name ellipsis' style={{...name_width}}>{e.label}</span>
												):(pd.width<=spaceCollumn)?(
													<span className='name ellipsis' style={{...name_width}}>
														<i 
															className="fas fa-tasks"
															style={{
																fontSize:17*HPercent
															}}
														/>
													</span>
												):null}
											</Tooltip>
											{pd_visible&&

												<span className='dt_f'>{moment(e.dt_f).format('DD/MMM')}</span>
											}
										</div>
									)
								})}
								{rangeDate.map((e,i)=>(
									<Fragment>

										{moment(e).diff(new Date(), 'days')<0&&
											<div 
												className={
													classnames(
														'curtain-date-past', 
														
													)
												}
												style={{
													left:i*spaceCollumn
												}}
											>
											</div>
										}
										<div 
											key={i} 
											className={classnames('divisor', {active:moment(e).isSame(new Date(), 'days')})}
											style={{'--index':i}}
										>
										</div>
									</Fragment>
								))}
							</div>
								
						</div>
					)
				})}
			</PerfectScrollbar>
			<Button
				icon={open?'fas fa-caret-up':'fas fa-caret-right'}
				label={open?'Fechar':'Expandir'}
				color='primary'
				outlined
				style={{border:'none'}}
				size={.7}
				zoom={.7}
				onClick={()=>setOpen(!open)}
				className='ml-3'
			/>
		</Card>
	)
}