import React from 'react';
import ProgressLine2 from '@common/ProgressLine2';
import Store from '@data/Store';

const { language } = Store;

export default function({current, isClosed = false}){
	return (
		<div>
			<span style={{ color: '#A3A3A3', fontSize: 10 }}>
				{isClosed ? language.DASH.SLA_BURST_CLOSED : `SLA ${language.BURST}`}
			</span>
			<ProgressLine2 current={current} barProps={{style:{height:10}}}/>
		</div>
	)
}