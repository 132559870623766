import React, { useState, useEffect} from 'react';
import {DinamicGraphArea} from './Graphs';
import ReactHighcharts from 'react-highcharts';
import classnames from 'classnames';
import InfoMinimized from './InfoMinimized';
import CommonForm from '@common/CommonForm2';

export default function({
	netStatsTx,
	name,
	netStats
}){
	const [dataStats, setDataStats] = useState([]);
	const [dataStatsRX, setDataStatsRX] = useState([]);
	const [open, setOpen] = useState(true);
	const [indexmonitor, setIndexmonitor] = useState(1);
	useEffect(() => {
		try{ 
		  let value = netStats[indexmonitor-1].values.TX
		   dataStats.push(value)
		   if(dataStats.length > 10){
		    dataStats.shift(); 
		   }
		   let value_ = netStats[indexmonitor-1].values.RX
		   dataStatsRX.push(value_)
		   if(dataStatsRX.length > 10){
		    dataStatsRX.shift(); 
		   }
		  setDataStats(dataStats)
		  setDataStatsRX(dataStatsRX)
		}catch(e){}
    }, [netStats])
 let index=0;
 try{ 
	index= Number(( netStats[indexmonitor-1]&&netStats[indexmonitor-1].values.TX / 1024).toFixed(3))
}catch(e){}
let last=0;
try{ 
	last= Number(( netStats[indexmonitor-1]&&netStats[indexmonitor-1].values.RX / 1024).toFixed(3))
}catch(e){}

 
	return(
		<div className='content-Traffic_Temperature_Graph content-medium'>
			<div className='header d-flex align-items-center justify-content-between'>
				<h2 className='d-flex align-items-center'>
					<i className="fas fa-chart-line icon"/>
					Tráfego  <div
					style={{width: '250px',
						marginLeft:'20px',marginTop:'25px'}}
					><CommonForm
          spacing={16}
          values={{rede:indexmonitor}}
          alert={false}
		  onChangeField={(f, v) => setIndexmonitor(v)}
          fields={[
            {
              col: 12,
              type: 'select',
              name: 'rede',
              label: '',
              options:netStats&& netStats.map((g,i)=>({value:i+1,label:g.name})),
            
           
            }]} ></CommonForm></div>
				</h2>
				 
			</div>
			{open&&
				<div className='d-flex align-items-end'>
					<div style={{width:'85%'}}>
						<ReactHighcharts 
							config={DinamicGraphArea([{
								
								color:'#F66868',
								name,
								data:dataStats
							},
							{
								
								color:'#019FC2',
								name,
								data:dataStatsRX
							}])} 
						/>
					</div>
					<div> 
					<div className='legend-info mb-3' style={{width:'15%'}}>
						<i style={{color:'var(--danger)'}} className="fas fa-arrow-down"/>
						<h2>{index}</h2>
						<span>kb/s</span>
					</div>
					<div className='legend-info mb-3' style={{width:'15%'}}>
						<i className="fas fa-arrow-up"/>
						<h2>{last}</h2>
						<span>kb/s</span>
					</div>
					</div>
				</div>
			}
		</div>
	)
}