import React, { useEffect, useState } from 'react';
import FormAttendance from '../common/FormAttendance';
import CommonPage from '../../../common/CommonPage';
import AttendanceList from './AttendanceList';
import Store from '../../../data/Store';
import Constants from '../../../data/Constants';
import Actions from '@data/Actions';
import DownloadFiles from '../../../util/DownloadFiles';
import DetailAttendance from '../common/DetailAttendance';

const action = new Actions();
const AttendanceContainer = () => {
  const [page, setPage] = useState('list');
  const [TabsAttendance, setTabsAttendance] = useState([
    { id: 0, label: 'Atendimentos', active: true },
  ]);
  const [detail, setDetail] = useState(0);
  const [singleAttendance, setSingleAttendance] = useState([]);
  const [data, setData] = useState([]);
  const [userSelected, setUserAttendance] = useState(0);
  const [groupTechSelected, setGroupTechSelected] = useState(0);
  const [themeSelected, setThemeSelected] = useState(0);
  const [topicSelected, setTopicSelected] = useState(0);
  const [subjectSelected, setSubjectSelected] = useState(0);
  const [dtInicialSelected, setDtInicialSelected] = useState(0);
  const [dtFimSelected, setDtFimSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalAttendances, setTotalAttendances] = useState(0);
  const [valuesFilter, setValuesFilter] = useState({});
  const [updateAttendances, setUpdateAttendances] = useState(false);
  const [filterStatus, setFilterStatus] = useState([
    { label: 'Todos', value: 'Todos' },
  ]);

  useEffect(() => {
    callPageSelectFilter();
  }, [page]);

  const submitFilter = () => {
    setUpdateAttendances(true);
  };

  const clearFilter = () => {
    setUserAttendance(0);
    setGroupTechSelected(0);
    setThemeSelected(0);
    setTopicSelected(0);
    setSubjectSelected(0);
    setDtInicialSelected(0);
    setDtFimSelected(0);
    setValuesFilter({});
    setUpdateAttendances(true);
  };

  useEffect(() => {
    if (updateAttendances) {
      let filters = valuesFilter;
      filters.filterStatus = filterStatus;
      filters.fk_id_theme = themeSelected;
      filters.fk_id_topic = topicSelected;
      filters.fk_id_subject = subjectSelected;
      filters.dtinicial = dtInicialSelected;
      filters.dtfinal = dtFimSelected;
      filters.fk_id_group_attend = groupTechSelected;
      filters.fk_id_user_filter = userSelected;
      callPageSelectFilter(0, filters, 75);
      setUpdateAttendances(false);
    }
  }, [updateAttendances]);

  const sendvalues = (data) => {
    setIsLoading(true);
    let filters = data;
    filters.id_user = Store.getUserLoged().id;
    filters.role = Store.getUserLoged().role;
    filters.fk_id_theme = themeSelected;
    filters.fk_id_topic = topicSelected;
    filters.fk_id_subject = subjectSelected;
    filters.dtinicial = dtInicialSelected;
    filters.dtfinal = dtFimSelected;
    filters.fk_id_group_attend = groupTechSelected;
    filters.fk_id_user_filter = userSelected;
    filters.id_user = Store.getUserLoged().id;
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.REPORTEXPORT}/attendance/dash`,
        'attendance',
        filters
      )
      .then((g) => {
        setTimeout(() => {
          setIsLoading(false);
          DownloadFiles(
            Constants.APIEndpoints.REPORTEXPORT +
              '/' +
              Store.getUserLoged().id +
              '/' +
              g.data.file,
            g.data.file
          );
        }, 3000);
      });
  };

  const callPageSelectFilter = (page = 0, values = {}, totalPerPage = 75) => {
    values.filterStatus = filterStatus;
    values.fk_id_theme = themeSelected;
    values.fk_id_topic = topicSelected;
    values.fk_id_subject = subjectSelected;
    values.dtinicial = dtInicialSelected;
    values.dtfinal = dtFimSelected;
    values.fk_id_group_attend = groupTechSelected;
    values.fk_id_user_filter = userSelected;
    values.id_user = Store.getUserLoged().id;
    setValuesFilter(values);
    setCurrentPage(page);
    let limit = totalPerPage || 75;
    let offset = page * limit;
    action
      .execute(
        'post',
        `${
          Constants.APIEndpoints.SAC_ATTENDANCE
        }/getAllAttendance?limit=${totalPerPage}&offset=${offset}`,
        '',
        values
      )
      .then((res) => {
        setTotalAttendances(res.data[0] ? res.data[0].total_count : 0);
        setData(res.data);
      });
  };

  const closeDetail = (detail) => {
    let tabs = TabsAttendance;
    const tabActive = tabs.find((t) => t.active);
    tabs = tabs.filter((t) => t.id != detail);
    if (tabActive.id == detail) {
      handleDetail(0, tabs);
    }
    setTabsAttendance(tabs);
  };

  const openAttendance = (attendance) => {
    let tabs_ = TabsAttendance;
    if (tabs_.filter((t) => t.id == attendance.id_attendance_sac).length == 0) {
      tabs_.push({
        id: attendance.id_attendance_sac,
        label: '#' + attendance.id_attendance_sac,
        active: false,
      });
    } else {
      tabs_.map((g) => {
        if (g.id_attendance_sac == attendance.id_attendance_sac) {
          g.active = true;
          return g;
        } else {
          g.active = false;
          return g;
        }
      });
    }
    setTabsAttendance([...tabs_]);
    setDetail(2);
  };
  const handleDetail = (id, tabs_) => {
    let tab_ = tabs_ || TabsAttendance;
    tab_.map((t) => {
      if (t.id == id) {
        t.active = true;
        return t;
      } else {
        t.active = false;
        return t;
      }
    });
    if (id > 0) {
      action
        .execute('get', Constants.APIEndpoints.SAC_ATTENDANCE + '/' + id)
        .then((res) => {
          setSingleAttendance(res.data[0]);
        });
    } else {
      callPageSelectFilter();
    }
    setTabsAttendance([...tab_]);
    setDetail(id == 0 ? 0 : 1);
  };

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      {detail == 1 ? (
        <DetailAttendance
          Attendance={singleAttendance}
          language={Store.language}
          APIEndpoints={Constants.APIEndpoints}
          Store={Store}
          setSingleAttendance={setSingleAttendance}
          handleDetail={handleDetail}
          tabs={TabsAttendance}
          closeDetail={closeDetail}
          Theme={Store.theme.TICKETS}
          listAttendace={true}
        />
      ) : (
        <AttendanceList
          setPage={setPage}
          language={Store.language}
          Constants={Constants}
          action={action}
          Theme={Store.theme.TICKETS}
          handleDetail={handleDetail}
          tabs={TabsAttendance}
          closeDetail={closeDetail}
          Store={Store}
          openAttendance={openAttendance}
          data={data}
          callPageSelectFilter={callPageSelectFilter}   
          currentPage={currentPage}
          totalAttendances={totalAttendances}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          setUpdateAttendances={setUpdateAttendances}
          setSubjectSelected={setSubjectSelected}
          submitFilter={submitFilter}
          setDtInicialSelected={setDtInicialSelected}
          setDtFimSelected={setDtFimSelected}
          setTopicSelected={setTopicSelected}
          setThemeSelected={setThemeSelected}
          setUserAttendance={setUserAttendance}
          setGroupTechSelected={setGroupTechSelected}
          clearFilter={clearFilter}
          sendvalues={sendvalues}
          isLoading={isLoading}
        />
      )}

      {page === 'form' && (
        <FormAttendance
          Store={Store}
          language={Store.language}
          Constants={Constants}
          action={action}
          setPage={setPage}
          callPageSelectFilter={callPageSelectFilter}
          setSingleAttendance={setSingleAttendance}
          values_={[]}
        />
      )}
    </CommonPage>
  );
};

export default AttendanceContainer;
