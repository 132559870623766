export const cleanObject = (objectRequest, requestFields) => {
    let _object = objectRequest;
    _object.forEach(item => {
        Object.keys(item).forEach(key => {
            if (!requestFields.includes(key)) {
                delete item[key];
            }
        });
    });

    return _object;
}