import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import Animate from '../../common/Animate';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';

import AdList from './AdList';
import Ad from './Ad';
import Store from '@data/Store';

const { language } = Store;

@observer
export default class AdContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      ad: [],
      value: '',
      page: 'list',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    this.callPageSelectFilter();
    // this.action.execute('get', this.APIEndpoints.AD, 'ad');
    this.action.execute('get', this.APIEndpoints.AD + '/perfil', 'perfil');
  }

  callPageSelectFilter(page=0, values, totalPerPage=75) {

    this.setState({currentPage:page})
        let limit = totalPerPage || 75;
        let pageCount = page + 1;
        let offset = page * limit;

    this.action
      .execute('get', `${this.APIEndpoints.AD}/?limit=${totalPerPage}&offset=${offset}`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        this.setState({ ad: res, totalad:parseInt(res[0].total_count) });
       return res.json();
      }).catch((error) => {
        this.store.toggleAlert(true, language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA, 'success');
      });

  }

  onFormSubmit(values) {
    this.action.execute('post', this.APIEndpoints.AD, 'ad', values);
    this.handlePage('list');
  }

  onDelete() {
    let { values } = this.state;

    this.action.execute('delete', this.APIEndpoints.AD+'?id='+values.id_ldap, 'ad',values,'id_ldap');
    this.handlePage('list');
  }

  handlePage(page, edit) {
    if (edit) {
      edit.update = true;
    }
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    let { language, ad, perfil } = this.store;
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <AdList
            ad={this.state.ad}
            redirect={this.redirect2}
            language={language}
            handlePage={this.handlePage}
            store={this.store}
            configurationProps={configurationProps}
          />
        </Animate>

        {page === 'form' ? (
          <Ad
            {...this.state}
            language={language}
            perfil={perfil}
            values={this.state.values}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            message={values.url}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
          />
        ) : null}
      </Fragment>
    );
  }
}
