const ContainerChart = {
	container0(e){
		let {language, title, subtitle, yTitle, series,selectDay,categories} = e;
		return {
			credits:{
			    enabled:false
			},
			title: {
			  text: title,
			  style:{
			    color: '#616161',
			    
			    fontFamily: 'sans-serif'
			  }  
			},
			 
		  legend: {
			  enabled: false
		  },
			subtitle: {
			  text: subtitle
			},
			xAxis: {
				categories:  categories,
			},
			yAxis: {
				labels: {
					formatter: function() {
						// show the labels as whole hours (3600000 milliseconds = 1 hour)
						return Highcharts.numberFormat(this.value/3600,0);
					}
				},
				title: {
					text: 'Hours'
				},
				tickInterval: 3600 // number of milliseconds in one hour
			  },
			

			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						formatter: function() {
							let hours = Math.floor(this.y / 3600);
							let resto = this.y % 3600;
							let minutes = Math.floor(resto / 60);
							resto = resto % 60;
							let seconds = resto
							return hours + ":" + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
							//return Highcharts.numberFormat(this.y/3600,2);
						}
					},
					point: {
						events: {
							click:
								(e) => {
									let id = 0;
									selectDay(e.point.category )
								}
						}
					}
				}
			},


			tooltip: {
				formatter: function() {
					let hours = Math.floor(this.y / 3600);
					let resto = this.y % 3600;
					let minutes = Math.floor(resto / 60);
					resto = resto % 60;
					let seconds = resto
			
					return  '<b>' + this.series.name +' : </b>' +
					hours + ":" + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
				}
			},
			 

			series
		}
	} 
	,
	container01(e){
		let {language, title, subtitle, yTitle, series,selectDay,categories} = e;
		return {
			credits:{
			    enabled:false
			},
			title: {
			  text: title,
			  style:{
			    color: '#616161',
			    
			    fontFamily: 'sans-serif'
			  }  
			},
			 
		  legend: {
			  enabled: false
		  },
			subtitle: {
			  text: subtitle
			},
			xAxis: {
				categories:  categories,
			},
			yAxis: {
				labels: {
					formatter: function() {
						// show the labels as whole hours (3600000 milliseconds = 1 hour)
						return  this.value 
					}
				},
				title: {
					text: 'Hours'
				},
				 
			  },
			

			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						formatter: function() {
					
							return 	 this.y  
							//return Highcharts.numberFormat(this.y/3600,2);
						}
					},
					point: {
						events: {
							click:
								(e) => {
									let id = 0;
									selectDay(e.point.category )
								}
						}
					}
				}
			},


			tooltip: {
				formatter: function() {
				 
			
					return  '<b>' + this.series.name +' : </b>' +this.y
					 
				}
			},
			 

			series
		}
	} 
	,
	container1(e){
		let {language, title, subtitle, yTitle, series,selectDay} = e;
		return {
			credits:{
			    enabled:false
			},
			title: {
			  text: title,
			  style:{
			    color: '#616161',
			    
			    fontFamily: 'sans-serif'
			  }  
			},
			subtitle: {
			  text: subtitle
			},
			xAxis: {
			  type: 'category'
			},
			yAxis: {
			  title: {
			    text: yTitle
			  }

			},
			plotOptions: {
				column: {
					pointPadding: 0.2,
					borderWidth: 0
				},
				series: {
					borderRadius: 3,
	
					// borderWidth: 0,
					borderColor: 'red',
					dataLabels: {
						enabled: true,
						format: '{point.y:.0f}',
						color: 'var(--primary)',
						style: {
							fontSize: 8,
							color: 'var(--primary)'
						}
					},
					point: {
						events: {
							click:
								(e) => {
									let id = 0;
									selectDay(e.point.category )
								}
						}
					}
	
				}
			},
			 

			tooltip: {
			  headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
			  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
			},

			series
		}
	},
	container3(e){
		let {language, title, subtitle, yTitle, series,selectDay,categories, multiple} = e;
		return {
			credits:{
			    enabled:false
			},
			title: {
			  text: title,
			  style:{
			    color: '#616161',
			    
			    fontFamily: 'sans-serif'
			  }  
			},
			legend: multiple ? {
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				borderWidth: 0,
				showInLegend: false
			  } : false,
			subtitle: {
			  text: subtitle
			},
			xAxis: {
				categories:  categories,
			},
	

			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						},
					point: {
						events: {
							click:
								(e) => {
									let id = 0;
									selectDay(e.point.category )
								}
						}
					}
				}
			},


			tooltip: {
				formatter: function() {
					return  '<b>' + this.series.name +' : </b>' + this.y
				}
			},
			 

			series
		}
	} 
	,
}

export default ContainerChart;