import { TableCell, withStyles } from "@material-ui/core";

const ServicePortalTableHeadCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#f3f4f6'
    }
}))(TableCell);

const ServicePortalTableCell = withStyles((theme) => ({
    root: {
        lineHeight: 'inherit',
        fontSize: '12px'
    }
}))(TableCell);

export { ServicePortalTableHeadCell, ServicePortalTableCell }
