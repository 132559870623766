import React from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../../../common/CommonForm2';

const ObserversAttendance = ({
  APIEndpoints,
  setOpenObservers,
  action,
  Attendance,
  Store,
  updateAttendance,
}) => {
  const attachObservers = (values) => {
    values.fk_id_attendance = Attendance.id_attendance_sac;
    values.user = Store.getUserLoged();
    action
      .execute('post', APIEndpoints.SAC_OBSERVERS, '', values)
      .then((res) => {
        updateAttendance();
        setOpenObservers(false);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={() => setOpenObservers(false)}
      title="Observadores"
      maxWidth="sm"
      PaperProps={{
        style: {
          height: '60%',
        },
      }}
    >
      <CommonForm2
        fields={[
          {
            span: 12,
            type: 'autocomplete',
            name: 'fk_id_user',
            className: 'tech-group-ticket',
            required: true,
            method: 'POST',
            route: `${APIEndpoints.USER}/filter`,
            routeGetCount: `${APIEndpoints.USER}/active/count/all`,
            filterAll: {},
            fieldquery: 'search',
            textinit: ' Observadores',
            fieldvaluedb: 'id_user',
            fieldlabeldb: 'name',
            idinit: '',
            routeAll: `${APIEndpoints.USER}/filter/all?`,
            detail: true,
            isMulti: true,
            methodGetAll: 'POST',
          },
        ]}
        onSubmit={attachObservers}
      />
    </Dialog>
  );
};

export default ObserversAttendance;
