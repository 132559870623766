import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import ReactTooltip from 'react-tooltip';
import { Switch } from '@material-ui/core';
import { Card, Chip } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';

import Dialog from '../../common/Dialog';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonPage from '../../common/CommonPage';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';

@observer
export default class AnswerList extends CommonPage {
  constructor() {
    super();
    this.state = {
      openTest: false,
    };

    this.openmail = this.openmail.bind(this);
  }

  openmail() {
    this.setState({ openTest: true });
  }
  render() {
    let { language, handlePage, redirect, onDelete } = this.props;

    let col = [
      {
        key: 'id_answer',
        label: language.ID,
        thConfig: true,
        style: { width: 1, textAlign: 'center', paddingRight: 0 },
      },
      {
        key: 'name',
        label: language.NAME,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'description',
        label: language.DESCRIPTION,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'action',
        label: language.ACTION,
        thConfig: true,
        style: { width: 100, textAlign: 'center' },
      },
    ];
    let data = (this.props.store.answer || []).map((obj) => {
      return {
        id_mail: obj.id_answer,
        mail: obj.name,
        pop: obj.description.replace(/<\/?[^>]+(>|$)/g, ''),
        action: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <RaisedButton
                    circleButton
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    className="mr-2"
                    onClick={() => handlePage('form', obj)}
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });
    let button = { label: language.SAVE };
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        {this.state.openTest ? (
          <Dialog
            monitor_scroll
            title={language.TYPE_YOUR_EMAIL}
            modal={false}
            open={true}
          >
            <CommonForm
              fields={fieldstest}
              onSubmit={(e) => this.testeMail(e)}
              button={button}
            />
          </Dialog>
        ) : null}

        <CommonTable
          title={''}
          language={language}
          columns={col}
          index="MailList"
          data={data}
          searchColumn
          orderColumn
          width="100%"
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
