import React, { Component } from 'react';
import { Card, Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';

export default class DeviceTypeForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;

    let tlsOptions = [{ value: 'S', label: language.YES }, { value: 'N', label: language.NOT }];

    //(idmail, mail, password, pop, smtp, host, port, tls, host_value, port_value, tls_value, deleted)
    let fields = [
      {
        col: 12,
        name: 'name',
        label: language.DESCRIPTION,
        type: 'text',
        required: true
      }
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog  modal={false} open={true} onClose={() => handlePage('list')} maxWidth='xs' title={values ? language.EDIT : language.ADD} miniVersion={true}>

        <CommonForm
          values={values}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
        />

      </Dialog>
    );
  }
}
