import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Radial extends Component {

  static defaultProps = {
    thick: 8,
    size: 100,
  }

  static propTypes = {
    color: PropTypes.string,
    current: PropTypes.number,
    high: PropTypes.object,
    low: PropTypes.object,
    mid: PropTypes.object,
    size: PropTypes.number,
    thick: PropTypes.number,
    total: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      current: 0,
      high: props.high,
      low: props.low,
      mid: props.mid,
      size: props.size,
      thick: props.thick,
      total: 1,
      chart: props.total && props.current,
    };
  }

  componentDidMount() {
    let {current, total} = this.props;
    setTimeout(function(_this) {
      _this.setValues(current, total);
    }, 100, this);
  }

  componentWillReceiveProps(nextProps) {
    let {current, total} = nextProps;
    this.setValues(current, total);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let state = this.state;
    let props = this.props;
    return state.current !== nextState.current || state.total !== nextState.total || props.className !== nextProps.className;
  }

  setValues(current,total) {
    this.setState({
      current: current,
      total: total>0?total:1,
      chart: total && current
    });
  }

  darken(color, percent) {
    percent = '-0.'+percent*2;
    let f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return '#'+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
  }

  render() {
    let {chart, current, size, total, thick} = this.state;
    let {low, mid, high, color} = this.props;
    if(chart) {
      let value = ((current/total).toFixed(2)).split('.');
      if(!color) {
        if(Number(value[0]) === 0 && value[1] < mid.value) {
          color = low.color;
        } else if (Number(value[0]) === 0 && (value[1] >= mid.value && value[1] < high.value)) {
          color = mid.color;
        } else {
          color = high.color;
        }
      }
      let deg = (value[1]*1.8);
      let bgcolor = value[0] > 0 ? this.darken(color, value[0]-1) : '#efefef';

      color = value[0] > 0 ? this.darken(color, value[0]) : color;

      let styles = {
        donut: {
          position: 'relative',
          margin: '0 auto',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderRadius: '50%',
          boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)',
        },
        full: {
          position: 'absolute',
          width: size,
          height: size,
          clip: 'rect(0px, '+size+'px, '+size+'px, '+size/2+'px)',
          WebKitBackfaceVisibility: 'hidden',
          transition: 'WebKitTransform 1s',
          transition: 'MsTransform 1s',
          transition: 'transform 1s',
          borderRadius: '50%',
          WebKitTransform: 'rotate('+deg+'deg)',
          MsTransform: 'rotate('+deg+'deg)',
          transform: 'rotate('+deg+'deg)',
        },
        fill: {
          position: 'absolute',
          width: size,
          height: size,
          clip: 'rect(0px, '+size/2+'px, '+size+'px, 0px)',
          WebKitBackfaceVisibility: 'hidden',
          transition: 'WebKitTransform 1s',
          transition: 'MsTransform 1s',
          transition: 'transform 1s',
          borderRadius: '50%',
          backgroundColor: color,
          WebKitTransform: 'rotate('+deg+'deg)',
          MsTransform: 'rotate('+deg+'deg)',
          transform: 'rotate('+deg+'deg)',
        },
        half: {
          position: 'absolute',
          width: size,
          height: size,
          clip: 'rect(0px, '+size+'px, '+size+'px, '+size/2+'px)',
          WebKitBackfaceVisibility: 'hidden',
          transition: 'WebKitTransform 1s',
          transition: 'MsTransform 1s',
          transition: 'transform 1s',
          borderRadius: '50%',
        },
        fill2: {
          position: 'absolute',
          width: size,
          height: size,
          clip: 'rect(0px, '+size/2+'px, '+size+'px, 0px)',
          WebKitBackfaceVisibility: 'hidden',
          transition: 'WebKitTransform 1s',
          transition: 'MsTransform 1s',
          transition: 'transform 1s',
          borderRadius: '50%',
          backgroundColor: color,
          WebKitTransform: 'rotate('+(deg*2)+'deg)',
          MsTransform: 'rotate('+(deg*2)+'deg)',
          transform: 'rotate('+(deg*2)+'deg)',
        },
        middle: {
          position: 'absolute',
          width: size-thick,
          height: size-thick,
          marginTop: thick/2,
          marginLeft: thick/2,
          backgroundColor: '#fff',
          borderRadius: '50%',
          textAlign: 'center',
        },
        middleIn: {
          position: 'absolute',
          width: size-thick,
          height: size-thick,
          marginTop: '0px',
          backgroundColor: '#fff',
          borderRadius: '50%',
          textAlign: 'center',
        },
      };
      return (
        <div style={styles.donut}>
          <div style={styles.full}>
            <div style={styles.fill}/>
          </div>
          <div style={styles.half}>
            <div style={styles.fill}/>
            <div style={styles.fill2}/>
          </div>
          <div style={styles.middle}>
            <div className={this.props.className} style={styles.middleIn}>
              {this.props.children}
            </div>
          </div>
        </div>
      );
    } else {
      let styles = {
        donut: {
          position: 'relative',
          margin: '0 auto',
          width: size,
          height: size,
          borderRadius: '50%',
          boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12)',
          textAlign: 'center',
        },
      };
      return (
        <div className={this.props.className} style={styles.donut}>
          {this.props.children}
        </div>
      );
    }
  }

};
