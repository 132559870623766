import React from 'react';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import Store from '../../data/Store';
import Button from '@common/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';

const { language } = Store;

export default observer(({
	title, 
	message, 
	open, 
	onClose, 
	onConfirm=()=>null,
	onCancel=()=>null,
	onConfirmCancel,
	confirmLabel,
	confirmProps,
	cancelLabel,
	cancelProps,
	showCancel=true,
	maxWidth,
	eventClose,
	children,
	showOther,
	otherProps,
	otherLabel,
	onOther,
	gmud,
	gmudlabel,
	CancelP,
	miniVersion
})=>{
	return(
		<Dialog 
			
			open={open}
			onClose={onClose}
			maxWidth={maxWidth||'xs'}
			miniVersion = {miniVersion}
			bar={false}
			PaperProps={{
				className:'DialogConfirm'
			}}
			contentProps={{
				style:{
					background:'white'
				}
			}}
		>
			<div className='d-flex align-items-center justify-content-end contet-close-button'>
				<IconButton aria-label="Close" onClick={onClose} style={{padding:6}}>
				  <CloseIcon style={{color:'var(--primary)'}}/>
				</IconButton>
			</div>
			<h4 id='title'>{title}</h4>
			<p id='body' className='content py-0'>
				{message||children}
			</p>
			<div className='d-flex align-items-center justify-content-end'>
				<Button
					label={confirmLabel || language.YES}
					outlined
					variant='normal'
					color='success'
					className='mr-1'
					size={.88}
					style={{border:'none', fontWeight:600}}
					onClick={()=> {
						eventClose?onClose():null;
						onConfirm();
					}}
					{...confirmProps}
				/>
				{showCancel && <Button
					label={cancelLabel||language.CANCEL}
					outlined
					variant='normal'
					color='default'
					size={.88}
					style={{border:'none', fontWeight:600}}
					onClick={()=> {
						[undefined, true].includes(eventClose)?onClose():null;
						onCancel();
					}}
					{...cancelProps}
				/>}
				{gmud && <Button
					label={gmudlabel || language.EMERGENCY}
					outlined
					variant='normal'
					color='success'
					className='mr-1'
					size={.88}
					style={{border:'none', fontWeight:600}}
					onClick={()=> {
						eventClose?onClose():null;
						if(onConfirmCancel){
							onConfirmCancel()
						}else{
							onConfirm();
						}
					 
					}}
					
					{...CancelP}
				/>}
	

			</div>
			{showOther&&<div style={{display:'flex', color:'red',fontSize:'12px'}}> 
	       <h6 style={{color:'danger'}}> <Button 
					outlined
					color='danger'
					icon="fas fa-plus"
					size={.88}
					style={{border:'none', fontWeight:600}}
					onClick={()=> {
						[undefined, true].includes(eventClose)?onClose():null;
						onOther();
					}}
					{...otherProps} />  <b style={{ fontWeight: '600' }}>{language.CREATE_TEMPLATE_BASED_GMUD}</b></h6></div>}
		</Dialog>
	)
})