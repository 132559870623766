import React, { Component } from 'react';
import { observer } from 'mobx-react';
import lodash from 'lodash';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../../../../common/PageLayout';
import Help from '../../../../../common/components/Help';
import CommonPage from '../../../../../common/CommonPage';
import RaisedButton from '../../../../../common/RaisedButton';
import areIntlLocalesSupported from 'intl-locales-supported';
import {
  Card,
  Dialog,
  Paper,
  SelectField,
  MenuItem,

  RadioButton,
  CircularProgress,
  DatePicker
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faUser,
  faPaperclip,
  faClock
} from '@fortawesome/free-solid-svg-icons';

const styleH2 = {
  textAlign: 'left'
};

const subtitle = {
  color: '#009dc0'
};

const iconsS = {
  color: '#009dc0',
  marginRigth: '5px'
};

export default class CardSections extends CommonPage {
  constructor() {
    super();
    this.convertMaskData = this.convertMaskData.bind(this);
  }

  convertMaskData(data) {
   // console.log('adasda');
    var ano = data.substr(0, 4);
    var mes = data.substr(5, 2);
    var dia = data.substr(8, 2);
    var time = data.substr(11, 8);
    return dia + '-' + mes + '-' + ano + ' ' + time;
  }

  render() {
    let {
      namecard,
      numsection,
      numsection2,
      arraysections,
      attachs,
      noexact,
      openFilec,
      language
    } = this.props;

    return (
      <div>
        <Card className="cardDefault">
          <Container>
            <Row>
              <Col md={12}>
                <div>
                  <h2> {this.props.namecard} </h2>
                  <div
                    style={{
                      height: '350px',
                      overflowX: 'hidden',
                      overflowY: 'scroll'
                    }}
                  >
                    {this.props.arraysections.map((sections, i) => {
                      if (this.props.noexact == false) {
                        if (sections.fk_id_section == this.props.numsection) {
                          var objectAttch = '';
                          this.props.attachs.map(pass => {
                            if (pass.idcheck == sections.id_section) {
                              objectAttch = (
                                <Row style={{ paddingLeft: '15px' }}>
                                  <hr style={{ border: '1px solid #dedede' }} />
                                  <Col md={12} style={{ marginBottom: '10px' }}>
                                    <span style={subtitle}>
                                      <strong>{language.QUESTION}</strong>
                                    </span>{' '}
                                    <br />
                                    <br />
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={iconsS}
                                    />
                                    <span style={{ marginLeft: '10px' }}>
                                      {' '}
                                      {sections.desc}{' '}
                                    </span>
                                  </Col>
                                  <br />
                                  <br />
                                  <Col md={6}>
                                    <div style={{ marginBottom: '10px' }}>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        style={iconsS}
                                      />{' '}
                                      <span
                                        style={{ subtitle, marginLeft: '10px' }}
                                      >
                                        {language.RESPONSIBLE}: {pass.usrsponsor}{' '}
                                      </span>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <div>
                                        <FontAwesomeIcon
                                          icon={faPaperclip}
                                          style={iconsS}
                                        />
                                        <a
                                          href="javascript:;"
                                          onClick={() =>
                                            this.props.openFilec(pass.namefile)
                                          }
                                          style={{
                                            subtitle,
                                            marginLeft: '10px'
                                          }}
                                        >
                                          {pass.namefile}
                                        </a>
                                      </div>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <div>
                                        <FontAwesomeIcon
                                          icon={faClock}
                                          style={iconsS}
                                        />
                                        <span
                                          style={{
                                            subtitle,
                                            marginLeft: '10px'
                                          }}
                                        >
                                          {' '}
                                          {this.convertMaskData(pass.data)}{' '}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6} style={{ marginTop: '2em' }}>
                                    <span style={subtitle}>
                                      <strong>{language.DESCRIPTION}</strong>
                                    </span>
                                    <br />
                                    <div>
                                      <span style={{ wordWrap: 'break-word' }}>
                                        {pass.desc}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          });

                          return (
                            <div key={i}>
                              <Row>{objectAttch}</Row>
                            </div>
                          );
                        }
                      } else {
                        if (
                          sections.fk_id_section >= this.props.numsection &&
                          sections.fk_id_section <= this.props.numsection2
                        ) {
                          var objectAttch = '';
                          this.props.attachs.map(pass => {
                            if (pass.idcheck == sections.id_section) {
                              objectAttch = (
                                <Row style={{ paddingLeft: '15px' }}>
                                  <hr style={{ border: '1px solid #dedede' }} />
                                  <Col md={12} style={{ marginBottom: '10px' }}>
                                    <span style={subtitle}>
                                      <strong>{language.QUESTION}</strong>
                                    </span>{' '}
                                    <br />
                                    <br />
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={iconsS}
                                    />
                                    <span style={{ marginLeft: '10px' }}>
                                      {' '}
                                      {sections.desc}{' '}
                                    </span>
                                  </Col>
                                  <Col md={6} style={{ paddingRight: '1px' }}>
                                    <br />
                                    <br />
                                    <div style={{ marginBottom: '10px' }}>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        style={iconsS}
                                      />{' '}
                                      <span
                                        style={{ subtitle, marginLeft: '10px' }}
                                      >
                                        {language.RESPONSIBLE}: {pass.usrsponsor}{' '}
                                      </span>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <div>
                                        <FontAwesomeIcon
                                          icon={faPaperclip}
                                          style={iconsS}
                                        />
                                        <a
                                          href="javascript:;"
                                          onClick={() =>
                                            this.props.openFilec(pass.namefile)
                                          }
                                          style={{
                                            subtitle,
                                            marginLeft: '10px'
                                          }}
                                        >
                                          {pass.namefile}
                                        </a>
                                      </div>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                      <div>
                                        <FontAwesomeIcon
                                          icon={faClock}
                                          style={iconsS}
                                        />
                                        <span
                                          style={{
                                            subtitle,
                                            marginLeft: '10px'
                                          }}
                                        >
                                          {' '}
                                          {pass.data}{' '}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6} style={{ marginTop: '2em' }}>
                                    <span style={subtitle}>
                                      <strong>{language.DESCRIPTION}</strong>
                                    </span>
                                    <br />
                                    <span>{pass.desc}</span>
                                  </Col>
                                </Row>
                              );
                            }
                          });

                          return (
                            <div key={i}>
                              <Row>{objectAttch}</Row>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  }
}
