const ContainerChart = {
	container0(e){
		let {language, title, subtitle, series, yTitle, pSeries, pOptions, ...other} = e;
		return {
			credits:{
			    enabled:false
			},
			title: {
			  text: title||'',
			  style:{
			    color: '#616161',
			    fontFamily: 'sans-serif'
			  }  
			},
			subtitle: {
			  text: subtitle||'',
			  style:{
			    color: '#616161',
			    fontFamily: 'sans-serif'
			  }  
			},
			xAxis: {
			  type: 'category'
			},
			yAxis: {
			  title: {
			    text: yTitle||'',
			    style:{
				    color: '#616161',
				    fontFamily: 'sans-serif'
				} 
			  }
			},
			
			plotOptions: {
			  series: {
			    borderWidth: 0,
			    dataLabels: {
			      enabled: true,
			      format: '{point.y:.1f}'
			    },

			  	...(pSeries||{})
			  },
			  ...(pOptions||{})
			},

			tooltip: {
			  headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
			  pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
			},

			series:series||[],
			...other
		}
	}
}

export default ContainerChart;