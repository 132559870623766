import React, { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Store from '@data/Store';

import { addCoinService, editCoinService } from '../coin.services';

// ----------------------------------------------------------------------

export const CoinAddOrEdit = ({ closeModal, editCoin, page }) => {
  const { language } = Store;
  const [values, setValues] = useState({});

  const submitForm = async (fields) => {
    const values = {
      ...fields,
    };

    page === 'add'
      ? await addCoinService({ values }).then(() => {
          closeModal();
        })
      : await editCoinService({ id: editCoin.id_coin, values }).then(() => {
          closeModal();
        });
  };

  useEffect(() => {
    page === 'edit' && setValues(editCoin);
  }, []);

  return (
    <Dialog
      title={
        page === 'add'
          ? `${language.ADD} ${language.COIN}`
          : `${language.EDITION} ${language.COIN} ${values.name}`
      }
      modal={false}
      open
      onClose={closeModal}
    >
      <CommonForm
        language={language}
        fields={[
          {
            col: 3,
            name: 'code',
            label: language.CODE,
            type: 'text',
            required: true,
          },
          {
            col: 3,
            name: 'name',
            label: language.COIN,
            type: 'text',
            required: true,
          },
          {
            col: 3,
            name: 'number',
            label: language.NUMBER,
            type: 'number',
            required: true,
          },
          {
            col: 3,
            name: 'decimal_places',
            label: language.DECIMAL_PLACES,
            type: 'text',
            required: true,
          },
        ]}
        values={values}
        onSubmit={submitForm}
        button={{ md: 12, label: language.SAVE, primary: true }}
      />
    </Dialog>
  );
};

export default CoinAddOrEdit;
