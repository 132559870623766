import React, { useState, useEffect, Fragment } from 'react';
import CommonTable from '@common/CommonTable';
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';

const GroupChangeConfigurationForm = ({
  values,
  onSubmit,
  language,
  handlePage,
  Constants,
}) => {
  if (values && values.global !== undefined) {
    values.global = values.global == true ? 2 : 1;
  }
  const fields = [
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_company',
      label: 'Empresa *',
      method: 'POST',
      route: `${Constants.APIEndpoints.COMPANY}/filter`,
      fieldquery: 'search',
      textlabel: `${language.COMPANY} *`,
      textinit: values && values.company_name ? values.company_name : '',
      fieldvaluedb: 'id_company',
      fieldlabeldb: 'name',
      idinit: values ? values.fk_id_company : 0,
      routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
      methodGetAll: 'POST',
      routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
      required: true,
    },
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_group',
      method: 'POST',
      textlabel: language.TECH_GROUP,
      route: `${Constants.APIEndpoints.GROUP}/all?`,
      routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
      fieldquery: 'search',
      textinit: values && values.group_name ? values.group_name : '',
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      idinit: values && values.fk_id_group ? values.fk_id_group : 0,
      methodGetAll: 'POST',
      required: true,
    },
    {
      col: 12,
      label: language.GLOBAL,
      name: 'global',
      type: 'select',
      options: [
        { value: 1, label: language.NO },
        { value: 2, label: language.YES },
      ],
    },
  ];

  let button = {
    md: 12,
    label: language.SAVE,
    primary: true,
    style: { margin: '40px 0' },
  };

  return (
    <Dialog
      title={values ? language.EDIT : language.ADD}
      open={true}
      onClose={() => handlePage('list')}
      miniVersion = {true}
      maxWidth="md"
    >
      <CommonForm
        fields={fields}
        values={values}
        onSubmit={onSubmit}
        button={button}
      />
    </Dialog>
  );
};

export default GroupChangeConfigurationForm;
