import React, {useState} from 'react';
import { Card } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommonForm from '@common/CommonForm2';
import { Droppable } from 'react-drag-and-drop';
import CardItem from './CardItem';
import './GridCards.css';
import moment from 'moment';

let statusIcon = {
	true:'fas fa-chevron-down',
	false:'fas fa-chevron-up',
	null:'fas fa-sort',
}

let _orderIndex = {
	0:'id_project_activity',
	1:'dt_start',
	null:'id_project_activity'
}

export default function (props) {
	let { children, data, title, onAdd, index, language, onDrop, toggleAlert, gestor, id_user_log, handleDialog, last, removeActivity, end_date } = props;
	let [orderIndex, setOrderIndex] = useState(null);
	let [orderPosition, setOrderPosition] = useState(null);

	let outOfTime = moment(new Date()).isAfter(end_date);
	return (
		<Droppable
			types={['milestone']} // <= allowed drop types
			onDrop={onDrop}
		>
			<Card
				className='GridCards'
				style={{
					height: 694,
					borderRadius: '0 0 10px 10px',
					borderTop: '6px solid #019fc2',
					borderColor: (
						index == 0 ?
							'#019fc2' :
							index == 1 ?
								'var(--warning)' :
								index == 2 ?
									'var(--danger)' :
									'var(--primary)'
					)

				}}
			>
				<AppBar
					title={title}
					titleContentProps={{ className: 'titleContainer' }}
					childrenContentProps={{ style: { height: 0 } }}
					dividerProps={{
						className: 'divider-1',
						style: { borderColor: '#EAEAEA' }
					}}
				>					
					<div className='d-flex align-items-center justify-content-end -mt-4'>
						<div className='d-flex align-items-center btn-group'>

						  {[
						    {label:'Por ID', color:'primary'},
						    {label:'Por data', color:'primary'},
						  ].map((e, i)=>(
						    <Button 
						      icon='fas fa-sort'
						      icon={orderIndex===i?statusIcon[orderPosition]:'fas fa-sort'}
						      label={e.label}
						      className='btn-item' 
						      outlined={orderIndex!==i}
						      onClick={() => {
						      	setOrderIndex(i);
						      	setOrderPosition(p=>!p);
						      }}
						      color={orderIndex===i?e.color:undefined}
						      variant='normal'
						      size={.6}
						    />
						  ))}
						</div>
						{index === 0 &&
							<Button
								icon='fas fa-plus'
								variant='circle'
								style={{ borderRadius: 5 }}
								size={.6}
								onClick={onAdd}
								className='ml-3'
								disabled={outOfTime}
							/>
						}
					</div>
				</AppBar>
				{/*<div className='content pt-0 px-4'>
					/<CommonForm
						individual
						fields={[
							{
								name:'search',
								placeholder:language.SEARCH+'...',
								type:'text',
								className:'darks',
								startIcon:'fas fa-search'
							}
						]}
					/>
					</div> */}
				<PerfectScrollbar
					className="scrollVisible pb-2"
					style={{
						maxHeight: 'calc(100% - 80px)'
					}}

				>
					<div className='content px-4 pt-0'>
						{data && data.sort((a, b) => {
							if(orderIndex===1){
								return new Date(([null, true].includes(orderPosition)?a:b)[_orderIndex[orderIndex]]) - new Date(([null, true].includes(orderPosition)?b:a)[_orderIndex[orderIndex]])
							}else{
								return ([null, true].includes(orderPosition)?a:b)[_orderIndex[orderIndex]] - ([null, true].includes(orderPosition)?b:a)[_orderIndex[orderIndex]]
							}
						})
						.map(g => (
						  <CardItem removeActivity={removeActivity} index={index} toggleAlert={toggleAlert} gestor={gestor} id_user_log={id_user_log} last={last} language={language} onAdd={handleDialog} activity={g} />
						))}
					</div>

				</PerfectScrollbar>
			</Card>
		</Droppable>
	)
}