import React from 'react';
/*------------------------common----------------------------------*/
import AppBar2 from '@common/AppBar2';

/*------------------------material-ui----------------------------------*/
import { Card, Grid } from '@material-ui/core';
/*-------------------------------------------------------------------*/
import ProgressSla from './ProgressSla';
import Store from '@data/Store';

const { language } = Store;

const Indicator = ({
  label,
  value,
  i,
  ticket,
  openModalDetail,
  opentc,
  opent,
  opentr,
  backlog,
}) => (
  <Grid
    item
    xs={6}
    className="indicator-item"
    style={{ cursor: 'pointer' }}
    onClick={() =>
      openModalDetail(
        i == 0 ? opentr : i == 1 ? opentc : i == 3 ? backlog : opent,
        '',
        i == 0
          ? ticket.itemDelay
          : i == 1
          ? ticket.itemClose
          : i == 3
          ? ticket.backlog
          : ticket.itemOpen
      )
    }
  >
    <div className="d-flex align-items-center justify-content-between px-2">
      <span>{label}</span>
      <span>{value}</span>
    </div>
  </Grid>
);

export default function ({ data, current, openModalDetail, ticket, status }) {
  return (
    <Card id="CardTotalTicket" className="h-100">
      <AppBar2 title={language.TOTAL_TICKETS} />
      <div className="content-medium pt-0">
        <Grid container spacing={2} className="content-indicator">
          {data.map((e, i) => (
            <Indicator
              {...e}
              i={i}
              openModalDetail={() => openModalDetail('', '', '', e.status,'Total')}
              ticket={ticket}
            />
          ))}
        </Grid>
        <ProgressSla current={current} />
      </div>
    </Card>
  );
}
