import Store from '@data/Store';

// ----------------------------------------------------------------------

const { language } = Store;

// ----------------------------------------------------------------------

export const columns = [
  {
    key: 'id_business_line',
    label: language.ID,
    style: { textAlign: 'left' },
  },
  {
    key: 'label',
    label: language.NAME,
    style: { textAlign: 'left' },
  },
  {
    key: 'action',
    label: language.ACTIONS,
    style: { textAlign: 'right' },
  },
];
