import Constants from "../../../data/Constants";
import Actions from '@data/Actions';

const action = new Actions();

export const getGroupsFromUserLogged = async (id_user) => {
  try {
    return action
      .execute('get', `${Constants.APIEndpoints.USER}/${id_user}/groups`)
      .then((res) => {
        return res.data;
      });
  } catch (e) {
    console.log('Error:', e);
  }
};
