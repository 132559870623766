import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = {
  success: {
    backgroundColor: 'var(--success)',
  },
  error: {
    backgroundColor: 'var(--danger)',
  },
  info: {
    backgroundColor: '#17a2b8',
  },
  warning: {
    backgroundColor: 'var(--warning)',
  },
  default:{
    backgroundColor: 'rgb(50, 50, 50)',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
};

function MySnackbarContentWrapper(props) {
  const classes = useStyles1;
  const { className, message, onClose, variant, viewIcon, style, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
     
      style={{...classes[variant], ...style}}
      className={className}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={classes.message}>
          {!!Icon && viewIcon!=false && <Icon style={{...classes.icon, ...classes.iconVariant}} />}
          {message}
        </span>
      }
      action={[
        onClose && <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon styles={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

export default class SimplesAlert extends Component {
  
  render(){
    let {variant, float, message, x, y, open, ...other} = this.props;
    let s = (
      <React.Fragment>
        {variant=='error'?(
          <MySnackbarContentWrapper
            variant="error"
            style={{margin:'8px 0'}}
            message={message}
            {...other}
          />
        ):variant=='warning'?(
          <MySnackbarContentWrapper
            variant="warning"
            style={{margin:'8px 0'}}
            message={message}
            {...other}
          />
        ):variant=='info'?(
          <MySnackbarContentWrapper
            variant="info"
            style={{margin:'8px 0'}}
            message={message}
            {...other}
          />
        ):variant=='success'?(
          <MySnackbarContentWrapper
            variant="success"
            style={{margin:'8px 0'}}
            message={message}
            {...other}
          />
        ):(
          <MySnackbarContentWrapper
            variant="default"
            style={{margin:'8px 0'}}
            message={message}
            {...other}
          />
        )}
      </React.Fragment>
    )
    return (
      

      float?(
        <Snackbar
          anchorOrigin={{
            vertical: y||'bottom',
            horizontal: x||'right',
          }}
          open={open}
          autoHideDuration={6000}
          {...other}
        >
          {s}
        </Snackbar>
      ):(
        s
      )
    );
  }
}
