import React, { Component } from 'react';
import '../bClass.css';


import './monitor.css'
import { Grid, Paper } from '@material-ui/core'; //comum
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'; //List

class Monitor extends Component {

	render() {
		return (
			<section id='Monitor' >
				{!this.props.cp &&
					<Grid container spacing={3} className='my-3'>
						<Grid item xs={12}>
							<Paper id='pmd-card-empresa-title' className='paper paper-list-custom d-flex align-items-center'>
								<h3 id='empresa-title' className='my-2 ml-2'><a href="#">SensrIT</a></h3>
							</Paper>
						</Grid>
					</Grid>
				}
				<Grid container spacing={3} id='lists'>
					{[1, 2, 4, 5, 6].map((n) =>
						<Grid item sm={6} xs={4} className='col' key={n}>
							<Paper className='paper paper-list-custom'>
								<div className='card-header d-flex justify-content-between align-items-center'>
									<h4 className='card-title'><a href="#">Aplicação</a></h4>
									<div className='btn-controls'>
										<a href="#" className='mx-2'><i className="fas fa-edit"></i></a>
										<a href="#" className='mx-2'><i className="fas fa-trash-alt"></i></a>
									</div>
								</div>
								<div className='card-body d-flex justify-content-end'>

									<List className='List'>
										<ListItem className='grid-container listItem' dense button>
											<span className="badge badge-info badge-first">{n}</span>
											<span>Dapibus ac facilisis in </span>
											<span className="badge badge-pill badge-danger float-rights">6</span>
										</ListItem>
										<ListItem className='grid-container listItem' dense button>
											<span className="badge badge-info badge-first">{n}</span>
											<span>Dapibus ac facilisis in </span>
											<span className="badge badge-pill badge-danger float-rights">6</span>
										</ListItem>
										<ListItem className='grid-container listItem' dense button>
											<span className="badge badge-info badge-first">{n}</span>
											<span>Dapibus ac facilisis in </span>
											<span className="badge badge-pill badge-danger float-rights">6</span>
										</ListItem>

										{/*<span className="list-group-item list-group-item-action">
							  	<span className="badge badge-info">{n}</span>
							  	Dapibus ac facilisis in 
							  	<span class="badge badge-pill badge-danger float-right">6</span>
							  </span>*/}

									</List>
								</div>
							</Paper>
						</Grid>
					)}
				</Grid>
			</section>
		)
	}
}
export default Monitor;