import React, { Component } from 'react';
import { Grid, Paper , Card} from '@material-ui/core'; //comum
import { Toolbar, Typography } from '@material-ui/core'; //header
import CommonTable from '@common/CommonTable';
import './GV.css';
import RaisedButton from '../../common/RaisedButton';
import '@bClass';
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
// -----------------------------------------------------
import ContainerChart from './GV.graph.js';
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts-more';
import ListColumn from '@common/ListColumn';

export default class GV extends CommonPage {
  constructor(props){
    super(props);
    this.state = {
      nome: '',
      distro: '',
      versao: '',
      kernel: ''
    }
  }
  componentWillMount(){
  	let {values} = this.props;
  	
  	if(values){
  	   	this.action.execute(
  	 		  'get',
  	 		  `${this.APIEndpoints.INVENTORY}/${
  	          values.id_device
  	 		   }/vulnerabilidade`,
  	 		  'bsc'
  	     );
        this.action.execute(
          'get',
          `${this.APIEndpoints.INVENTORY}/${values.id_inventory_collector}/mac`,
          'softwares'
        );
  	}
  }
  render() {
    let { language } = this.props;
    let data1 = {
      title: language.severity,
      data: [
        { name: language.CRITICAL, y: 24, colo: '#F44336' },
        { name: language.LOW, y: 29, color: '#33ABA0' },
        { name: language.MEDIUM, y: 31, color: '#DAA520' },
        { name: language.IMPORTANT, y: 16 }
      ]
    };
    let data2 = {
      title: language.AFFECTED_PRODUCT,
      data: [
        {
          name: 'Chrome',
          y: 62.74
        },
        {
          name: 'Firefox',
          y: 10.57
        },
        {
          name: 'Internet Explorer',
          y: 7.23,
          drilldown: 'Internet Explorer'
        },
        {
          name: 'Safari',
          y: 5.58
        },
        {
          name: 'Edge',
          y: 4.02
        },
        {
          name: 'Opera',
          y: 1.92
        },
        {
          name: 'Other',
          y: 7.62
        }
      ]
    };
    let col = [
      {
        key: 'device',
        label: 'Device',
        style: { width: 50, paddingRight: 0 },
        thConfig: true
      },
      {
        key: 'cve',
        label: 'CVE',
        style: { width: 120, paddingRight: 0 },
        thConfig: true
      },

      {
        key: 'title',
        label: language.TITLE,
        style: { width: 400 },
        thConfig: true
      },
      { key: 'date', label: language.STOCK_DT },
      { key: 'affectedProduct', label: language.AFFECTED_PRODUCT },
      { key: 'severity', label: language.severity },
      {
        key: 'impact',
        label: language.IMPACT,
        style: { paddingRight: 0 },
        thConfig: true
      },
      { key: 'exploit', label: 'Exploit' }
    ];
    let data = [1, 2, 3, 4, 5].map(e => {
      return {
        cve: 'ID_' + e,
        title: 'title' + e,
        date: 'date' + e,
        affectedProduct: 'affected_product' + e,
        severity: 'severity' + e,
        impact: 'impact' + e,
        exploit: 'exploit' + e
      };
    });

    let arr = [];
    let arr1 = [];
    this.props.itens.map(c => {
      arr.push(c.values.severity);
      arr1.push(c.values.affectedProduct);
    });

    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }
    let color = [
      '#F44336',
      '#33ABA0',
      '#DAA520',
      '#F44336',
      '#33ABA0',
      '#DAA520',
      '#F44336',
      '#33ABA0',
      '#DAA520'
    ];
    let arrayOfObjs = a.map(function(d, i) {
      return {
        name: d,
        y: b[i] || 0,
        color: color[i]
      };
    });

    data1 = { title: language.SEVERITY, data: arrayOfObjs };

    var a1 = [],
      b1 = [],
      prev;

    arr1.sort();
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== prev) {
        a1.push(arr1[i]);
        b1.push(1);
      } else {
        b1[b1.length - 1]++;
      }
      prev = arr1[i];
    }
    let arrayOfObjss = a1.map(function(d, i) {
      return {
        name: d,
        y: b1[i] || 0
      };
    });

    data1 = { title: language.severity, data: arrayOfObjs };

    data2 = { title: language.AFFECTED_PRODUCT, data: arrayOfObjss };

    data = this.props.itens.map(e => {
      return {
        device: (this.history.location.state||{}).name,
        cve: e.values.CVE,
        title: e.values.title,
        date: e.values.datePosted,
        affectedProduct: e.values.affectedProduct,
        severity: e.values.severity,
        impact: e.values.impact,
        exploit: e.values.exploits
      };
    });
    /*{"datePosted":"20161108",
		"CVE":"CVE-2016-7195",
		"bulletinKB":"3200970",
		"title":"Microsoft Browser 
		Memory Corruption Vulnerability","affectedProduct":"Internet Explorer 11 on
		 Windows Server 2016","affectedComponent":"Microsoft Browsers","severity"
		 :"Moderate","impact":"Remote Code Execution","exploits":""}

 */

    /*
  
		let arr =dashboard.full == undefined ? [] : dashboard.full.map(c=>{return c.name_user})
		var a = [], b = [], prev;
		  
		arr.sort();
		for ( var i = 0; i < arr.length; i++ ) {
			if ( arr[i] !== prev ) {
				a.push(arr[i]);
				b.push(1);
			} else {
				b[b.length-1]++;
			}
			prev = arr[i];
		}
		let arrayOfObjs = a.map(function(d, i) {
		  return {
			label: d,
			data: b[i] || 0
		  };
		});
	   */

    this.store.softwares
        .filter(h => h.command != 'software')
        .map(g => {
          if (g.command === 'osInfo') {
            this.state.nome = g.value.hostname;
            this.state.distro = g.value.distro + ' - ' + g.value.codename;
            this.state.versao = g.value.release;
            this.state.kernel = g.value.kernel;
          }
          if (g.command === 'os') {
            if (g.value.csname != undefined) {
              this.state.nome = g.value.csname;
              this.state.distro = g.value.caption;
              this.state.versao = g.value.version;
              this.state.kernel = '';
            }
          }
        });
    return (
      <PageLayout
        icon="requesterIcon titleIconSize"
        title={language.VULNERABILITY_MANAGEMENT}
        subtitle=""
        rightElements={[
          <RaisedButton
            circleButton
            icon="fas fa-arrow-left"
            label={language.BACK}
            color="primaryGradient"
            onClick={() => this.history.goBack()}
          />
        ]}
      >
        <div id="GV">
          <Card className="content">
            <ListColumn
              col={3}
              items={[
                {
                  col: 4,
                  label: language.COMPUTER_NAME,
                  value: this.state.nome //osInfo.hostname
                },
                {
                  col: 4,
                  label: language.DISTRO,
                  value: this.state.distro //osInfo.distro
                },
                {
                  col: 2,
                  label: language.VERSION,
                  value: this.state.versao // osInfo.release
                },
                {
                  col: 2,
                  label: 'Kernel',
                  value: this.state.kernel //osInfo.kernel
                }
              ]}
            />
          </Card>
          <br />
          <Paper className='mb-3' style={{ padding: '20px 0' }}>
            <div className="d-flex justify-content-around">
              <ReactHighcharts
                config={ContainerChart.container0(data1)}
              ></ReactHighcharts>
              <ReactHighcharts
                config={ContainerChart.container0(data2)}
              ></ReactHighcharts>
            </div>
          </Paper>
          <CommonTable data={data} col={col} />
        </div>
      </PageLayout>
    );
  }
}
