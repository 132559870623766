const KeyMap = {
  '': 0x0000,
  Escape: 0x0001,
  Digit1: 0x0002,
  Digit2: 0x0003,
  Digit3: 0x0004,
  Digit4: 0x0005,
  Digit5: 0x0006,
  Digit6: 0x0007,
  Digit7: 0x0008,
  Digit8: 0x0009,
  Digit9: 0x000a,
  Digit0: 0x000b,
  Minus: 0x000c,
  Equal: 0x000d,
  Backspace: 0x000e,
  Tab: 0x000f,
  KeyQ: 0x0010,
  KeyW: 0x0011,
  KeyE: 0x0012,
  KeyR: 0x0013,
  KeyT: 0x0014,
  KeyY: 0x0015,
  KeyU: 0x0016,
  KeyI: 0x0017,
  KeyO: 0x0018,
  KeyP: 0x0019,
  BracketLeft: 0x001a,
  BracketRight: 0x001b,
  Enter: 0x001c,
  ControlLeft: 0x001d,
  KeyA: 0x001e,
  KeyS: 0x001f,
  KeyD: 0x0020,
  KeyF: 0x0021,
  KeyG: 0x0022,
  KeyH: 0x0023,
  KeyJ: 0x0024,
  KeyK: 0x0025,
  KeyL: 0x0026,
  Semicolon: 0x0027,
  Quote: 0x0028,
  Backquote: 0x0029,
  ShiftLeft: 0x002a,
  Backslash: 0x002b,
  KeyZ: 0x002c,
  KeyX: 0x002d,
  KeyC: 0x002e,
  KeyV: 0x002f,
  KeyB: 0x0030,
  KeyN: 0x0031,
  KeyM: 0x0032,
  Comma: 0x0033,
  Period: 0x0034,
  Slash: 0x0035,
  ShiftRight: 0x0036,
  NumpadMultiply: 0x0037,
  AltLeft: 0x0038,
  Space: 0x0039,
  CapsLock: 0x003a,
  F1: 0x003b,
  F2: 0x003c,
  F3: 0x003d,
  F4: 0x003e,
  F5: 0x003f,
  F6: 0x0040,
  F7: 0x0041,
  F8: 0x0042,
  F9: 0x0043,
  F10: 0x0044,
  Pause: 0x0045,
  ScrollLock: 0x0046,
  Numpad7: 0x0047,
  Numpad8: 0x0048,
  Numpad9: 0x0049,
  NumpadSubtract: 0x004a,
  Numpad4: 0x004b,
  Numpad5: 0x004c,
  Numpad6: 0x004d,
  NumpadAdd: 0x004e,
  Numpad1: 0x004f,
  Numpad2: 0x0050,
  Numpad3: 0x0051,
  Numpad0: 0x0052,
  NumpadDecimal: 0x0053,
  PrintScreen: 0x0054,
  IntlBackslash: 0x0056,
  F11: 0x0057,
  F12: 0x0058,
  NumpadEqual: 0x0059,
  F13: 0x0064,
  F14: 0x0065,
  F15: 0x0066,
  F16: 0x0067,
  F17: 0x0068,
  F18: 0x0069,
  F19: 0x006a,
  F20: 0x006b,
  F21: 0x006c,
  F22: 0x006d,
  F23: 0x006e,
  KanaMode: 0x0070,
  Lang2: 0x0071,
  Lang1: 0x0072,
  IntlRo: 0x0073,
  F24: 0x0076,
  Convert: 0x0079,
  NonConvert: 0x007b,
  IntlYen: 0x007d,
  NumpadComma: 0x007e,
  MediaTrackPrevious: 0xe010,
  MediaTrackNext: 0xe019,
  NumpadEnter: 0xe01c,
  ControlRight: 0xe01d,
  VolumeMute: 0xe020,
  LaunchApp2: 0xe021,
  MediaPlayPause: 0xe022,
  MediaStop: 0xe024,
  VolumeDown: 0xe02e,
  VolumeUp: 0xe030,
  BrowserHome: 0xe032,
  NumpadDivide: 0xe035,
  PrintScreen: 0xe037,
  AltRight: 0xe038,
  NumLock: 0xe045,
  Pause: 0xe046,
  Home: 0xe047,
  ArrowUp: 0xe048,
  PageUp: 0xe049,
  ArrowLeft: 0xe04b,
  ArrowRight: 0xe04d,
  End: 0xe04f,
  ArrowDown: 0xe050,
  PageDown: 0xe051,
  Insert: 0xe052,
  Delete: 0xe053,
  OSLeft: 0xe05b,
  OSRight: 0xe05c,
  ContextMenu: 0xe05d,
  Power: 0xe05e,
  BrowserSearch: 0xe065,
  BrowserFavorites: 0xe066,
  BrowserRefresh: 0xe067,
  BrowserStop: 0xe068,
  BrowserForward: 0xe069,
  BrowserBack: 0xe06a,
  LaunchApp1: 0xe06b,
  LaunchMail: 0xe06c,
  MediaSelect: 0xe06d
};


const UnicodeToCode_EN = {
  27: 'Escape',
  112: 'F1',
  113: 'F2',
  114: 'F3',
  115: 'F4',
  116: 'F5',
  117: 'F6',
  118: 'F7',
  119: 'F8',
  120: 'F9',
  121: 'F10',
  122: 'F11',
  123: 'F12',
  192: 'Backquote',
  49: 'Digit1',
  50: 'Digit2',
  51: 'Digit3',
  52: 'Digit4',
  53: 'Digit5',
  54: 'Digit6',
  55: 'Digit7',
  56: 'Digit8',
  57: 'Digit9',
  48: 'Digit0',
  173: 'Minus',
  61: 'Equal',
  8: 'Backspace',
  9: 'Tab',
  81: 'KeyQ',
  87: 'KeyW',
  69: 'KeyE',
  82: 'KeyR',
  84: 'KeyT',
  89: 'KeyY',
  85: 'KeyU',
  73: 'KeyI',
  79: 'KeyO',
  80: 'KeyP',
  219: 'BracketLeft',
  221: 'BracketRight',
  13: 'Enter',
  20: 'CapsLock',
  65: 'KeyA',
  83: 'KeyS',
  68: 'KeyD',
  70: 'KeyF',
  71: 'KeyG',
  72: 'KeyH',
  74: 'KeyJ',
  75: 'KeyK',
  76: 'KeyL',
  59: 'Semicolon',
  222: 'Quote',
  220: 'Backslash',
  16: 'ShiftLeft',
  220: 'IntlBackslash',
  90: 'KeyZ',
  88: 'KeyX',
  67: 'KeyC',
  86: 'KeyV',
  66: 'KeyB',
  78: 'KeyN',
  77: 'KeyM',
  188: 'Comma',
  190: 'Period',
  191: 'Slash',
  16: 'ShiftRight',
  17: 'ControlLeft',
  18: 'AltLeft',
  91: 'OSLeft',
  32: 'Space',
  18: 'AltRight',
  91: 'OSRight',
  93: 'ContextMenu',
  17: 'ControlRight',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  40: 'ArrowDown',
  39: 'ArrowRight',
  144: 'NumLock',
  144: 'NumLock',
  111: 'NumpadDivide',
  106: 'NumpadMultiply',
  109: 'NumpadSubtract',
  103: 'Numpad7',
  104: 'Numpad8',
  105: 'Numpad9',
  107: 'NumpadAdd',
  100: 'Numpad4',
  101: 'Numpad5',
  102: 'Numpad6',
  97: 'Numpad1',
  98: 'Numpad2',
  99: 'Numpad3',
  13: 'NumpadEnter',
  96: 'Numpad0',
  110: 'NumpadDecimal',
  17: 'ControlLeft'
};

export default {
  scancode: e => KeyMap[e.code || UnicodeToCode_EN[e.keyCode]]
};
