import Fetch from './Fetch';
import Store from '@data/Store';
import Constants from '@data/Constants';

let expoCreate = Fetch.create({
   store:Store,
   baseURL:Constants.APIRoot,
   token:Store.getToken()
})

export default expoCreate;