import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { convertJson, decodeData } from '../helpers';
import { GridLineItem } from './GridUtils';
import { TitleSlash } from './TitleSlash';

export const ServicePortalAccountData = ({ language }) => {
  const [user, setUser] = useState();
  const [userGrid, setUserGrid] = useState([]);

  useEffect(() => {
    const userSessionStorage = sessionStorage.getItem('user');
    const userData = convertJson(decodeData(userSessionStorage));

    const grid = [
      { label: language.NAME, text: userData?.name, icon: 'fa-user' },
      { label: language.USERNAME, text: userData?.email, icon: 'fa-envelope' },
      {
        label: language.PHONE,
        text: userData?.phone ?? '--',
      },
      { label: language.COMPANY, text: userData?.company, icon: 'fa-building' },
      { label: language.DEPARTMENT, text: userData?.department ?? '--' },
      {
        label: language.LANGUAGE,
        text: language.LANG[userData?.id_language?.toUpperCase()] ?? '--',
      },
      {
        label: language.TIMEZONE,
        text: userData?.timezone?.value ?? '--',
        icon: 'fa-clock',
      },
    ];

    setUser(userData);
    setUserGrid(grid);
  }, []);

  return (
    <div className="tw-px-4 tw-max-w-md" style={{ minHeight: 280 }}>
      <TitleSlash>Dados Gerais</TitleSlash>
      <Grid container spacing={2}>
        {userGrid.map((item, index) => (
          <GridLineItem
            key={index}
            label={item.label}
            text={item.text}
            icon={item.icon}
            content={item.content}
            isHidden={item.isHidden}
          />
        ))}
      </Grid>
    </div>
  );
};
