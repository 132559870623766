import React, {Fragment, useState, useEffect} from 'react';
import DialogConfirm from '@common/DialogConfirm';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';

import Form from './Form';

export default function({execute, APIEndpoints, options, project_stages, handleValue, values, open, onClose}){

   return (
      <Form 
         options={options}
         values={values} 
         open={open} 
         APIEndpoints={APIEndpoints}
         execute={execute}
         onClose={onClose}
      />
   )
}