import { useState, useEffect } from 'react';
// import robot_animate from '@images/robot_animate.gif';
import robto_computer from '@images/robto_computer.png';
import iconsSolid from '@images/icons-solid.png';
import { TextField } from '@material-ui/core';
import { Card, Grid } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import './RequestFormProblem.css';
import BarSquare from '@common/BarSquare';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import AttachFile2 from '@common/AttachFile2';
import { Tooltip } from 'react-tippy';

function Step({ val, style, status }) {
  return (
    <span
      className={classnames(
        'font-roboto step',
        !!status ? 'success' : 'warning '
      )}
      style={style}
    >
      {val}
    </span>
  );
}

export default function RequestFormProblem(props) {
  const [form, setForm] = useState({
    subject: null,
    fk_id_category: null,
    fk_id_catalog_service: null,
    fk_id_catalog_task: null,
    description: null,
    search: null,
  });

  let {
    language,
    catalogs,
    categories,
    onDrop,
    APIRoot,
    values,
    onFormSubmit,
    StoreLoading,
    handleNext = () => null,
  } = props;

  const handleField = (field, val) => setForm({ ...form, [field.name]: val });

  let search = '';
  //fk_id_category: 1, fk_id_catalog_service: 4, fk_id_catalog_task: 15}

  let categoryOptions = categories.map((c) => ({
    value: c.id_category,
    label: c.name,
  }));

  let category = categories.find((e) => e.id_category == form.fk_id_category);
  let catalog = category && category.catalog;

  if (values.fk_id_category != null) {
    category = categories.find((e) => e.id_category == values.fk_id_category);
    form.fk_id_category = values.fk_id_category;

    catalog = category && category.catalog;
    form.fk_id_catalog_service = values.fk_id_catalog_service;
    form.fk_id_catalog_task = values.fk_id_catalog_task;
  } else {
  }

  // item.indexOf(this.state.search.toUpperCase()) !== -1 ||

  if (form.search != null && form.search != '') {
    if (catalog) {
      catalog = catalog.filter(
        (p) => p && p.name.toUpperCase().indexOf(form.search.toUpperCase()) > -1
      );
    }
  }

  let tasksOptions = [];
  if (form.fk_id_catalog_service) {
    tasksOptions = catalogs
      .filter((c) => c.id_catalog_service == form.fk_id_catalog_service)[0]
      .task.map((t) => ({ value: t.id_catalog_task, label: t.name }));
  }

  useEffect(() => {
    handleField({ name: 'fk_id_catalog_service' }, null);
  }, [form.fk_id_category]);

  let dir_imgs = APIRoot + '/images/flat-icon-fa';

  return (
    <div id="RequestFormProblem">
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems={form.fk_id_catalog_service ? 'flex-start' : 'center'}
        style={{ marginTop: 42 }}
      >
        <Grid
          item
          xs={4}
          className="d-flex align-items-center"
          style={{ flexDirection: 'column', position: 'relative' }}
        >
          <div className="box-message box-message-1">
            <CommonForm
              fields={[
                {
                  col: 12,
                  name: 'subject',
                  label: language.SUBJECT,
                  type: 'text',
                  required: true,
                },
              ]}
              onChangeField={handleField}
            />
            <Step val={1} status={form['subject']} />
          </div>
          <div className="box-message box-message-2">
            <CommonForm
              values={values}
              fields={[
                {
                  col: 12,
                  name: 'fk_id_category',
                  label: language.CATEGORY,
                  type: 'select',
                  options: categoryOptions,
                  required: true,
                },
              ]}
              onChangeField={handleField}
            />
            <Step val={2} status={form['fk_id_category']} />
          </div>
          <Card className="mt-3 mb-3 card_robot_animate">
            <div className="img_robot_animate">
              <img src={robto_computer} alt={robto_computer} />
            </div>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <div
            className={classnames('card-icons', {
              static: form.fk_id_catalog_service,
            })}
          >
            <BarSquare
              icon={
                <img
                  src={iconsSolid}
                  className="desaturate"
                  style={{
                    width: '80%',
                  }}
                />
              }
              title={language.WHAT_YOUR_SUBCATEGORY}
            />

            <div className="px-3 mt-2" style={{ marginBottom: -25 }}>
              <CommonForm
                fields={[
                  {
                    name: 'search',
                    label: language.SEARCH,
                    variant: 'outlined',
                    margin: 'dense',
                    startIcon: 'fas fa-search',
                    className: 'input-min',
                  },
                ]}
                onChangeField={(f, v) => {
                  handleField({ name: 'search' }, v);
                }}
              />
            </div>

            <Step
              val={3}
              style={{ top: 13, right: -13.5 }}
              status={form['fk_id_catalog_service']}
            />
            <hr className="mb-0" />
            <PerfectScrollbar
              style={{
                maxHeight: 220,
              }}
              option={{
                suppressScrollX: true,
              }}
            >
              <div className="content list-buttons-icon">
                <Grid container spacing={16}>
                  {catalog &&
                    catalog.map(
                      (e) =>
                        e.id_catalog_service && (
                          <Grid item xs={2}>
                            <Tooltip
                              html={
                                <div style={{ color: '#fff', fontSize: 15 }}>
                                  <strong>{e.name}</strong>
                                </div>
                              }
                              arrow={true}
                              position="right"
                              theme="dark"
                            >
                              <div className="content-button-icon">
                                <div
                                  key={e.id_catalog_service}
                                  onClick={() =>
                                    handleField(
                                      { name: 'fk_id_catalog_service' },
                                      e.id_catalog_service
                                    )
                                  }
                                  className={classnames(
                                    'button-icon',
                                    form.fk_id_catalog_service ==
                                      e.id_catalog_service
                                      ? 'success'
                                      : 'primary',
                                    { invert: e.icon }
                                  )}
                                >
                                  <img
                                    src={
                                      e.icon
                                        ? dir_imgs + '/' + e.icon + '.png'
                                        : iconsSolid
                                    }
                                    alt={e.name}
                                  />
                                </div>
                                <span className="font ellipsis button-label mt-1">
                                  {e.name}
                                </span>
                              </div>
                            </Tooltip>
                          </Grid>
                        )
                    )}
                </Grid>
              </div>
            </PerfectScrollbar>
          </div>
          {form.fk_id_catalog_service && (
            <div className="card-other-form">
              {Object.values(form).filter(Boolean).length >= 5 ? (
                <div className="content-button-save">
                  <Button
                    variant="circle"
                    icon="fas fa-save"
                    color="success"
                    className="button-save"
                    loading={StoreLoading}
                    label={language.SAVE}
                    size={1.2}
                    onClick={() => (!StoreLoading ? onFormSubmit(form) : null)}
                  />
                </div>
              ) : (
                <Step
                  val={4}
                  style={{ top: 13, right: -13.5 }}
                  status={form['fk_id_catalog_task'] && form['description']}
                />
              )}
              <div className="content">
                <AttachFile2
                  files={Array.from(props.files)}
                  onDrop={onDrop}
                  accept=".zip, .7Z, .json, .VBS, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar, .msg , audio/*, image/*"
                  label={language.ATTACH}
                  onRemove={(f) => props.removeFile(f)}
                  style={{ maxWidth: '100%' }}
                />
                <CommonForm
                  values={values}
                  fields={[
                    {
                      col: 12,
                      name: 'fk_id_catalog_task',
                      label: language.TASK,
                      type: 'select',
                      options: tasksOptions,
                      required: true,
                    },
                    {
                      col: 12,
                      name: 'description',
                      label: language.DESCRIPTION,
                      type: 'textEditor',
                      multiLine: true,
                      required: true,
                    },
                  ]}
                  onChangeField={handleField}
                />
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
