import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import FilterListIcon from '@material-ui/icons/FilterList';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import ButtonCommon from '@common/Button';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Header from '@common/Header';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import lodash from 'loadsh';

import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

import './styles.css';
import moment from 'moment';

const { language } = Store;

const useStyles = makeStyles((theme) => ({
  list: {
    width: 500,
    color: '#019FC2',
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
  },
  fullList: {
    width: 600,
    color: '#019FC2',
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
  },
  root: {
    color: '#999',
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
  },
  appBar: {
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
  },
  container: {
    padding: 50,
  },
  textSubTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#a2a2a2',
    marginBottom: 25,
  },
  textField: {
    border: '1px solid #019FC2',
  },
  paper: {
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
  },
  container: {
    top: 30,
  },
  itemList: {
    display: 'grid',
    margin: 5,
    color: '#FFF',
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: '#039FC1',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
  },
}))(Button);

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF',
    },
    '& .MuiInputLabel-shrink': {
      color: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      height: 36,

      '& fieldset': {
        borderColor: '#039FC0',
      },
      '&:hover fieldset': {
        borderColor: '#039FC0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#039FC0',
      },
    },
  },
})(TextField);

const CustomSelect = styled(Select)({
  root: {
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    '&$selected': {
      backgroundColor: 'orange',
    },
  },
  '& .MuiSelect-root': {
    '& option': {
      padding: 0,
    },
  },
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 36,
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
  },

  background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',

    border: '1px solid #FFF',
    fontSize: 16,
    padding: '10px 26px 3px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      border: '1px solid #FFF',
      color: '#FFF',

      background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    },
  },
}))(InputBase);

export function FilterMenu({ tasksFilter }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [open, setOpen] = React.useState(false);
  const [usersRespOptions, setUsersResOptions] = useState([]);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [gestor, setGestor] = useState([]);
  const [respSelected, setRespSelected] = useState();
  const [chiefSelected, setChiefSelected] = useState();
  const [creatorSelected, setCreatorSelected] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));

  let { users, getUserLoged } = Store;

  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.USER}/allByRole`, '')
      .then((res) => {
        if ( res.data ) {
          res = res.data;
        }

        let userMap = [];

        res.map((userFind) => {
          userMap.push({
            value: userFind.name,
            id: userFind.id_user,
          });
        });

        setUsersResOptions(userMap);
        setCreatorOptions(userMap);
      })
      .catch((err) => console.log('erro', err));

    action
      .execute('get', `${Constants.APIEndpoints.USER}/department/chief`, '')
      .then((res) => {
        if ( res.data ) {
          res = res.data;
        }
        let userMap = [];

        const uniqueUsers = lodash.uniqBy(res, 'id_user');

        uniqueUsers.map((userFind) => {
          userMap.push({
            value: userFind.name,
            id: userFind.id_user,
          });
        });

        setGestor(userMap);
      })
      .catch((err) => console.log('erro', err));
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function submit() {
    const data = {
      responsible: respSelected,
      dateStart: dateStart
        ? moment(dateStart).format('YYYY-MM-DD')
        : new Date(),
      dateEnd: dateEnd || new Date(),
      chief: chiefSelected,
      idCreator: creatorSelected,
    };

    action
      .execute('post', `${Constants.APIEndpoints.TASKS}/filter`, '', data)
      .then((res) => {
        if ( res.data ) {
          res = res.data;
        }
        tasksFilter(res);
        setOpen(false);
      })
      .catch((err) => console.log(err));
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <CssBaseline />

      <AppBar position="static" className={classes.appBar}>
        <span style={{ fontSize: 28, fontWeight: 600, fontFamily: 'Arial' }}>
          {language.FILTER}
        </span>
        <CloseIcon onClick={() => setOpen(false)} />
      </AppBar>
      <List>
        <ListItem className={classes.itemList}>
          <span style={{ marginBottom: '13px' }}>
            <b>{language.PERIOD}</b>
          </span>
          <div style={{ display: 'flex', color: '#FFF' }}>
            <CustomTextField
              variant="outlined"
              type="date"
              value={dateStart}
              onChange={(ev) => setDateStart(ev.target.value)}
              defaultValue={moment().format('YYYY-MM-DD')}
              label={language.START_DATE}
              style={{ marginRight: '3px', color: '#FFF' }}
            />
            <CustomTextField
              variant="outlined"
              type="date"
              value={dateEnd}
              onChange={(ev) => setDateEnd(ev.target.value)}
              label={language.END_DATE}
              defaultValue={moment().format('YYYY-MM-DD')}
              style={{ color: '#FFF' }}
            />
          </div>
        </ListItem>
        <ListItem>
          <div className="item_form form_label">
            <h2>{language.RESPONSIBLE}</h2>
            <FormControl sx={12}>
              <CustomSelect
                value={respSelected}
                onChange={(ev) => setRespSelected(ev.target.value)}
                variant="outlined"
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                input={<BootstrapInput />}
              >
                <MenuItem value="">
                  <em>{language.NONE}</em>
                </MenuItem>
                <MenuItem value={10}>Test</MenuItem>
                {usersRespOptions.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.value}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            </FormControl>
          </div>
        </ListItem>
        <ListItem>
          <div className="item_form form_label">
            <h2>{language.CREATOR}</h2>
            <FormControl sx={12}>
              <CustomSelect
                value={creatorSelected}
                onChange={(ev) => setCreatorOptions(ev.target.value)}
                variant="outlined"
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                input={<BootstrapInput />}
              >
                <MenuItem value="">
                  <em>{language.NONE}</em>
                </MenuItem>
                <MenuItem value={10}>Teste</MenuItem>
                {usersRespOptions.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.value}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            </FormControl>
          </div>
        </ListItem>

        {/* <ListItem>
          <div className="item_form form_label">
            <h2>Criador</h2>
            <FormControl sx={12}>
              <CustomSelect
                variant="outlined"
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                input={<BootstrapInput />}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {/* {usersCreatorOptions.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.value}
                    </MenuItem>
                  );
                })} */}
        {/* </CustomSelect>
            </FormControl>
          </div>
        </ListItem> */}
        <ListItem>
          <div className="item_form form_label">
            <h2>{language.MANAGE}</h2>
            <FormControl sx={12}>
              <CustomSelect
                variant="outlined"
                value={chiefSelected}
                onChange={(ev) => setChiefSelected(ev.target.value)}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                input={<BootstrapInput />}
              >
                <MenuItem value="">
                  <em>{language.NONE}</em>
                </MenuItem>
                {gestor.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.value}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            </FormControl>
          </div>
        </ListItem>
        <ListItem>
          <Button
            style={{
              background: '#58A9A0',
              padding: '2px 45px',
              color: '#FFF',
            }}
            onClick={submit}
          >
            {language.FILTER}
          </Button>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <ColorButton
            startIcon={<FilterListIcon />}
            onClick={handleDrawerOpen}
          >
            {language.FILTER}
          </ColorButton>

          <SwipeableDrawer
            classes={{ paper: classes.paper }}
            anchor={anchor}
            open={open}
            onOpen={toggleDrawer(anchor, true)}
          >
            <div className={classes.root}>{list(anchor)}</div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
