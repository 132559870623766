import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, TextField, Dialog } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm';
import moment from 'moment';
import { FaEye, FaTrash, FaClose } from 'react-icons/lib/fa';

@observer
export default class TicketAddObserver extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleModal = this.handleModal.bind(this);
  }

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    let { language, handlePage } = this.props;
    //let { open } = this.state;

    let col = [
      { key: 'id', label: language.ID, style: { width: 20 } },
      { key: 'name', label: language.NAME },
      { key: 'email', label: language.EMAIL },
      { key: 'phone', label: language.PHONE },
      { key: 'remove', label: language.REMOVE, style: { width: 20 } }
    ];

    let data = [
      {
        id: '01',
        name: 'name',
        email: 'email',
        phone: '983928392',
        remove: <FaTrash className="pointer colorPrimary" fontSize={20} />
      }
    ];

    let colOb = [
      { key: 'check', label: '', style: { width: 20 } },
      { key: 'id', label: language.ID, style: { width: 20 } },
      { key: 'name', label: language.NAME },
      { key: 'level', label: language.LEVEL }
    ];

    let dataOb = [
      { check: <Checkbox />, id: '01', name: 'Name', level: 'Solicitant' }
    ];

    let addObserverDialog = (
      <Dialog
        modal={false}
        open={this.state.open}
        onRequestClose={() => this.handleModal}
      >
        <PageLayout
          icon={<FaEye />}
          title={language.OBSERVER}
          subtitle={language.ADD_OBSERVER}
          rightElements={[
            <FaClose
              fontSize={30}
              style={{ marginTop: -20 }}
              className="colorPrimary pointer"
              onClick={() => this.handleModal}
            />
          ]}
        >
          <div style={{ maxHeight: 180, overflow: 'auto' }}>
            <CommonTable
              title={''}
              language={language}
              columns={colOb}
              data={dataOb}
              hideFooterToolbar
            />
          </div>
          <Row style={{ marginTop: 10 }}>
            <Col
              md={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <RaisedButton label={language.ADD} primary={true} fullWidth />
            </Col>
          </Row>
        </PageLayout>
      </Dialog>
    );

    return (
      <PageLayout
        icon={<FaEye />}
        title={language.OBSERVER}
        subtitle={language.ADD_OBSERVER}
        rightElements={[
          <RaisedButton
            label={language.BACK}
            primary={true}
            style={{ marginRight: 10 }}
            onClick={() => handlePage('list')}
          />,
          <Help>help</Help>
        ]}
      >
        <Card style={{ marginTop: 10 }}>
          <Container fluid>
            <Row>
              <Col md={2}>
                <h2>{language.ID} 01</h2>
              </Col>
              <Col md={3.33}>
                <TextField
                  hintText={language.NAME}
                  floatingLabelText={language.NAME}
                  disabled={true}
                  value="Name"
                />
              </Col>
              <Col md={3.33}>
                <TextField
                  hintText={language.LEVEL}
                  floatingLabelText={language.LEVEL}
                  disabled={true}
                  value="Level"
                />
              </Col>
              <Col md={3.33}>
                <TextField
                  hintText={language.DATE_CAD}
                  floatingLabelText={language.DATE_CAD}
                  disabled={true}
                  value="Dt_cad"
                />
              </Col>
            </Row>
            <Row>
              <Col md={9} offset={{ md: 2 }}>
                <TextField
                  hintText={language.DESCRIPTION}
                  floatingLabelText={language.DESCRIPTION}
                  multiLine={true}
                  fullWidth
                  disabled={true}
                  value="description"
                />
              </Col>
            </Row>
          </Container>
        </Card>
        <Container fluid style={{ margin: 10 }}>
          <Row>
            <Col md={2.5} offset={{ md: 9.7 }}>
              <RaisedButton
                onClick={() => this.handleModal}
                label={language.ADD_OBSERVER}
                primary={true}
                fullWidth
              />
            </Col>
          </Row>
        </Container>
        <Card>
          <CommonTable
            title={''}
            language={language}
            columns={col}
            data={data}
          />
        </Card>
        {addObserverDialog}
      </PageLayout>
    );
  }
}
