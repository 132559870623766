export default {
   type:'normal',
   COMMON_PAGE:{
      bar:'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
      slideBar:{
         drawn:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)',
         iconButton:'#DDF8FF',
         iconButtonHover:'#d5d5d5',
         colorIconButton:'#4bb3cb',
      },
      text:'var(--primary)'
   },
   CIO_COCKPIT:{
      demand_management:{
         card_5: 'linear-gradient(268deg, #92D050 0%, #33ACA0 100%)',
         times_medium:{
            color_1:'#92d050'
         },
         tickets:{
            resolved:'var(--success)'
         }
      },
      average_availability:'bluegreen'
   },
   PANEL:{
      total_card_1:'linear-gradient(180deg, #92D050 0%, #33ACA0 100%)',
      indicator_1:'linear-gradient(180deg, #92D050 0%, #33ACA0 100%)',
      b2:'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)'
   },
   ASSESSMENT:{
      form:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)'
   },
   BUDGETCONTROL:{
      total_acumulated:'linear-gradient(180deg, #92D050 0%, #33ACA0 100%)',
      acumulated:'linear-gradient(245deg, #92D050 0%, #33ACA0 100%)'
   },
   GSD:{
      columns:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)'
   },
   DASHBOARD:{
      greenTheme:'linear-gradient(180deg, #92D050 0%, #33ACA0 100%)'
   },
   INVENTORY:{
      total_acompany:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)' 
   },
   RISKMANAGEMENT:{
      config:{
         bar:'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)'
      }
   },
   PMO:{
      cardGreen:'linear-gradient(226deg, #92D050 0%, #33ACA0 100%)',
      alocation:{
         cardGreen:'linear-gradient(0deg, #92D050 0%, #33ACA0 100%)',
         graph:'#92D050'
      }
   },
   PROJECT:{
      column:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)',
      barRight:{
         background:'var(--primary)',
         button:'primary'
      }
   },
   PROJECTS:{
      done:'linear-gradient(180deg, #92D050 0%, #33ACA0 100%)'
   },
   SPRINT:{
      card:'linear-gradient(244deg, var(--cStart) 0%, var(--cEnd) 100%)',
      KANBAN:{
         column:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)'
      }
   },
   CALENDAR:{
      form:'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)',
      card:'linear-gradient(270deg, #02F0DE 0%, #0B85BC 100%)'
   },
   TICKETS:{
      controlButton:{
         active:'#01C1B4',
         normal:'#57CEC3'
      }
   }
}