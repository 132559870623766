import React, { useState } from 'react';
import Store from '@data/Store';
export default function ({
  projects = [],
  users = [],
  language,
  companies = [],
  project_stages = [],
  contracts = [],
  tickets = [],
  departments = [],
  allDepartments = [],
}) {
  //------------------- caso as opções sejam dinâmicas, fazer o controle dela aqui nessa tela
  return {
    tech() {
      return users.map((e) => ({ value: e.id_user, label: e.name }));
    },
    project() {
      return projects.map((e) => ({ value: e.id_project, label: e.name }));
    },
    type() {
      return language.TYPE_PROJECT_ITEMS;
    },
    company() {
      return companies.map((e) => ({ value: e.id_company, label: e.name }));
    },
    project_stages() {
      try {
        return project_stages
          ? project_stages[0].projetos.map((h) => ({
              value: h.id_project,
              label: h.id_project + ' - ' + h.name,
            }))
          : [];
      } catch (e) {
        return [];
      }
    },
    contract(filter = () => true) {
      return contracts
        .filter(filter)
        .map((e) => ({ value: e.id_contract, label: e.name }));
    },
    department(id) {
      try {
        return allDepartments
          .filter((f) => f.companies.includes(id))
          .map((e) => ({ value: parseInt(e.id_department), label: e.name }));
      } catch (e) {
        return [];
      }
    },
    departments() {
      return departments.map((e) => ({
        value: e.id_department,
        label: e.name,
      }));
    },
    allDepartments() {
      return allDepartments.map((e) => ({
        value: e.id_department,
        label: e.name,
      }));
    },
    ticket(filter = () => true) {
      return tickets
        .filter(filter)
        .map((u) => ({
          value: u.id_tickets,
          label: u.realid + ' - ' + u.subject,
        }));
    },
    users(idCompany) {
      if (Store.getUserLoged().role == 'client') {
        return users
          .filter((g) => g.deleted == false && g.status == true)
          .map((u) => ({ value: u.id_user, label: u.name + ' - ' + u.email }));
      } else {
        return users
          .filter((g) => g.deleted == false && g.status == true)
          .map((u) => ({ value: u.id_user, label: u.name + ' - ' + u.email }));
      }
    },

    // ----------------------------funções auxiliares------------------------------------
    getOptions(option, v, id) {
      let _return = (
        typeof option == 'string' ? this[option](id) : option
      ).find((e) => e.value == v);
      return (_return || {}).label;
    },

    getOptionsJoin(option, v, id) {
      let _return = (typeof option == 'string' ? this[option](id) : option)
        .filter((e) => v.includes(e.value))
        .map((e) => e.label);
      return (_return || []).join(', ');
    },
  };
}
