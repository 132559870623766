import Store from '@data/Store';
import { Avatar, Button, Chip, Divider, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Constants from '../../../data/Constants';
import { convertJson, getApprovalMap, spFetch } from '../helpers';
import { GridLineItem } from './GridUtils';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';
import Tag from '@common/Tag';
import ServicePortalTicketFormView from './ServicePortalTicketFormView';

export default function ServicePortalModalApprovalRequestDetails({
  isOpen,
  language,
  data,
  onClose,
  onConfirm,
}) {

  const handleClose = () => {
    onClose?.();
  };

  const ticketData = {
    forms: [{ html: data?.details?.html }],
    _formValue: convertJson(data?.details?.html_values),
  };

  return (
    <>
      <ServicePortalModalWrapper
        isOpen={isOpen}
        title={language.APPROVAL_DETAILS}
        onClose={handleClose}
        buttonCloseLabel={language.CLOSE}
        disableConfirmButton={true}
      >
        <div className="tw-w-full">
          {ticketData._formValue && (
            <ServicePortalTicketFormView
              language={language}
              ticketData={ticketData}
              hideByFlag={false}
              isInModal={true}
            />
          )}
        </div>
      </ServicePortalModalWrapper>
    </>
  );
}
