import React, { Component, useEffect, useState, useRef } from 'react';

import Dialog from '@common/Dialog';
/*-------------------------------common--------------------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import PieChart from '@common/PieChart';
import { Card, Grid } from '@material-ui/core';
import './ReportSintect.css';

import ReactToPrint from 'react-to-print';
import moment from 'moment';
/**   contract={this.state.contractDetail}
            NewContractsProps={NewContractsProps}> */
export default function ({
  contract,
  NewContractsProps,
  currentMonth,
  consumed,
  realized,
  foreseen,
  dadosFinanc,
  currentYear,
  hours_total_consumer,
  mes,
  currentConsumed,
  language,
}) {
  let [contract_, setcontract_] = useState([]);
  let [tickets, settickets] = useState([]);
  let [projetos, setprojetos] = useState([]);
  let [acesso, setAcesso] = useState([]);
  let [os, setOs] = useState([]);

  useEffect(() => {
    try {
      setcontract_(dadosFinanc);
    } catch (e) { }
  }, [contract]);

  const componentRef = useRef();

  const calcValue = (contract) => {
    let value = (calcHour(contract.consumed.assessment) +
    calcHour(contract.consumed.changes) +
    calcHour(contract.consumed.os) +
    calcHour(contract.consumed.projects) +
    calcHour(contract.consumed.tickets)) *
  parseFloat(contract.value)
  
return value
}

  const calcHour = (obj) => {
    if (obj[0] && obj[0].sum) {
      let { days = 0, hours = 0, minutes = 0, seconds = 0 } = obj[0].sum;
      return days * 24 + hours + minutes / 60 + seconds / 60 / 60;
    } else {
      return 0;
    }
  }



  return (
    <Card className="h-100">
      <ReactToPrint
        trigger={() => {
          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
          // to the root node of the returned component as it will be overwritten.

          return <i id="btnimprimiros" class="fas fa-print"></i>;
        }}
        content={() => componentRef.current}
      />
      <div
        id="reportSintect"
        style={{ backgroundColor: 'white' }}
        ref={componentRef}
      >
        <AppBar
          title={' Ano: ' + currentYear}
          subTitle="(LISTA RESUMO FINANCEIRO )"
        ></AppBar>
        <table style={{ width: '100%' }}>
          <thead style={{ backgroundColor: 'var(--purple)' }}>
            <tr style={{ color: 'white' }}>
              <th style={{ color: 'white' }}>Cliente</th>
              <th style={{ color: 'white' }}>Contrato</th>
              <th style={{ color: 'white' }}>Mês</th>
              <th style={{ color: 'white' }}>Previsto (Valor)</th>
              <th style={{ color: 'white' }}>Consumido (Valor) </th>
              <th style={{ color: 'white' }}>Excedido (Valor)</th>
            </tr>
          </thead>
          {contract_.map((v) => {
            return (
              <tbody>
                <tr>
                  <th>{v.company}</th>
                  <th>{v.name}</th>
                  <th>
                    {
                      language.DATE_LANG.monthLongList[
                      currentMonth - 1
                      ]
                    }
                  </th>
                  <th>
                    {
                      v.hours != 0 ?
                        (parseInt(v.hours) * parseFloat(v.value)).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })

                        :
                        (parseFloat(v.value) || 0).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                    }
                  </th>
                  <th>
                    {
                      calcValue(v).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })

                    }


                  </th>
                  {/* <th>{
                     (parseFloat(v.realized_cost)||0)
                      > 
                     (parseFloat(v.value)||0) ? ( parseFloat(v.realized_cost)||0) - (parseFloat(v.value)||0) 
                     :0

                      }</th> */}
                  <th>
                    {
                      parseFloat(calcValue(v)) <= (parseInt(v.hours) * parseFloat(v.value))
                     ? 0 :
                      (
                        Math.abs(calcValue(v) - (parseInt(v.hours) * parseFloat(v.value)))
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </th>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div></div>
    </Card>
  );
}
