import Animate from '@common/Animate';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import CommonPage from '../../common/CommonPage';
import DialogConfirm from '../../common/DialogConfirm';
import AddChangeTemplateForm from './AddChangeTemplateForm';
import DialogForm from './DialogForm';
import MultiTicketTask from './MultiTicketTask';
import TaskList from './TaskList';
import './index.css';
@observer
export default class TaskContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      allIcons: [],
      buttonDisabled: false,
      tasks: [],
      taskName: '',
      load: true,
      totaltasks: 0,
      currentPage: 0,
      page: 0,
      lastSearch: {},
      pageDelete: {
        open: false,
        values: undefined,
      },
      urgencies_st: [],
      complexities_st: [],
      impacts_st: [],
      priority_st: [],
      categorySelected: 0,
      catalogServiceSelected: 0,
    };
    this.handlePage = this.handlePage.bind(this);
    this.handlePageDelete = this.handlePageDelete.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.cleanAllFilters = this.cleanAllFilters.bind(this);
  }
  cleanAllFilters() {
    this.callPageSelectFilter();
  }

  componentWillMount() {
    // this.callPageSelectFilter();
  }

  callPageSelectFilter(page = 0, values, totalPerPage = 75, type) {
    this.setState({ currentPage: page });
    let limit = totalPerPage;
    let offset = page > 0 ? page * limit : 0;

    if (type == 'filter') {
      offset = 0;
    }

    this.action.execute('post', `${this.APIEndpoints.TASK}/list?limit=${totalPerPage}&offset=${offset}`, '', values)
      .then((res) => {
        this.setState({
          tasks: res.data,
          totaltasks: parseInt(res.data[0]?.total_count || 0),
          lastSearch: values,
        });
      });
  }

  componentDidMount() {
    const impactRequest = this.action.execute(
      'get',
      `${this.APIEndpoints.TABLE_STRUCTURED}/50`,
      'impacts',
    );

    const priorityRequest = this.action.execute(
      'get',
      `${this.APIEndpoints.TABLE_STRUCTURED}/60`,
      'priorities',
    );

    const urgencyRequest = this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/40',
      'urgencies',
    );

    const complexityRequest = this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/130',
      'complexities',
    );

    const getAll = async () => {
      const results = await Promise.all([
        impactRequest,
        priorityRequest,
        urgencyRequest,
        complexityRequest
      ]);

      const impacts = results[0]?.data || [];
      const priorities = results[1]?.data || [];
      const urgencies = results[2]?.data || [];
      const complexities = results[3]?.data || [];
      this.setState({
        impacts_st: impacts,
        priority_st: priorities,
        urgencies_st: urgencies,
        complexities_st: complexities,
      });
    };

    getAll();

    // let icons_ = Object.keys(Icons)
    //   .filter(
    //     (i) =>
    //       !i.toString().includes('Outlined') &&
    //       !i.toString().includes('Rounded') &&
    //       !i.toString().includes('Sharp') &&
    //       !i.toString().includes('TwoTone') &&
    //       !i.toString().includes('Forward') &&
    //       !i.toString().includes('Extra') &&
    //       !i.toString().includes('Reduced')
    //   )
    //   .map((str) => ({
    //     value: str.replace(/[A-Z]/g, (letter, index) => {
    //       return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
    //     }),
    //     label: (
    //       <div>
    //         <Icon>
    //           {str.replace(/[A-Z]/g, (letter, index) => {
    //             return index == 0
    //               ? letter.toLowerCase()
    //               : '_' + letter.toLowerCase();
    //           })}
    //         </Icon>
    //         {str}
    //       </div>
    //     ),
    //     field: str,
    //   }));
    // this.setState({ allIcons: icons_ });
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  onFormSubmit(values) {
    if (values.fk_id_approver === 0 || !values.liberation) {
      values.fk_id_approver = null;
    }

    if (
      values.name &&
      values.name.length > 1 &&
      this.state.taskName.length == 0
    ) {
    } else {
      values.name = this.state.taskName;
    }
    if (
      values.id_impact < 2 ||
      values.id_urgency < 2 ||
      values.id_impact < 2 ||
      values.id_complexity == undefined ||
      values.name.length == 0
    ) {
      this.store.toggleAlert(true, language.FILL_IN_ALL_FIELDS, 'error');
    } else {
      this.setState({ buttonDisabled: true });
      let method = values.hasOwnProperty('id_catalog_task') ? 'put' : 'post';
      let url = values.hasOwnProperty('id_catalog_task')
        ? this.APIEndpoints.TASK + '/' + values.id_catalog_task
        : this.APIEndpoints.TASK;

      this.action
        .execute(method, url, 'tasks', values, 'id_catalog_task', {
          btn_loading: 'put-post-task-list',
        })
        .then((e) => {
          this.callPageSelectFilter(0, this.state.lastSearch);
          this.handlePage('list', null);
          this.setState({ buttonDisabled: false });
        })
        .catch((e) => {
          this.setState({ buttonDisabled: false });
        });
    }
  }

  onDelete() {
    let { tasks, pageDelete } = this.state;
    let { values } = pageDelete;
    let method = 'delete';
    let url = this.APIEndpoints.TASK;
    let body = null;

    if (values.detach) {
      method = 'put';
      url += '/user';
      body = values;
      this.handlePage('taskForm', values);
    } else {
      url += '/' + values.id_catalog_task;
      this.handlePageDelete(false, {});
      this.setState({
        tasks: tasks.filter((t) => t.id_catalog_task != values.id_catalog_task),
      });
    }

    this.action.execute(method, url, 'tasks', body, 'id_catalog_task', {
      btn_loading: 'del-task-list-' + values.id_catalog_task,
    });
    this.handlePage('/dash/configuration/task');
  }

  handlePageDelete(open = false, values) {
    this.setState({ pageDelete: { open, values } });
  }

  render() {
    let { language, impacts, priorities, urgencies, complexities } = this.store;
    let {
      page,
      values,
      tasks,
      totaltasks,
      impacts_st,
      urgencies_st,
      complexities_st,
      priority_st,
    } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;

    let PAGE = history.location.pathname;
    let state = history.location.state;

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <TaskList
            language={language}
            redirect={redirect}
            handlePage={this.handlePage}
            handlePageDelete={this.handlePageDelete}
            tasks={tasks}
            {...this.state}
            impacts={impacts}
            priorities={priorities}
            urgencies={urgencies}
            complexities={complexities}
            configurationProps={configurationProps}
            totaltasks={totaltasks}
            callPagination={this.callPagination}
            callDataFilter={this.callDataFilter}
            callPageSelectFilter={this.callPageSelectFilter}
            onClearAllFilter={this.cleanAllFilters}
          />
        </Animate>
        {['new', 'edit'].includes(page) ? (
          <DialogForm
            onClose={() => this.handlePage('list')}
            language={language}
            onFormSubmit={this.onFormSubmit}
            tasks={tasks}
            impacts={impacts_st}
            priorities={priority_st}
            urgencies={urgencies_st}
            complexities={complexities_st}
            values={values}
            open={['new', 'edit'].includes(page)}
            buttonDisabled={this.state.buttonDisabled}
            // allIcons={this.state.allIcons}
            taskName={this.state.taskName}
            setTaskName={(evt) => this.setState({ taskName: evt })}
          />
        ) : null}
        {page == 'multitickets' && (
          <Dialog
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            title={language.ADD_CATEGORIZATION}
          >
            <MultiTicketTask
              language={language}
              handlePage={this.handlePage}
              catalog_task={this.state.values}
            />
          </Dialog>
        )}
        {page == 'change_template' && (
          <Dialog
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xl"
            title={language.ENTER_TEMPLATE_BELOW}
          >
            <AddChangeTemplateForm
              language={language}
              catalogTask={this.state.values}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              store={this.store}
              onSuccess={() => {
                this.handlePage('list');
                this.callPageSelectFilter();
              }}
            />
          </Dialog>
        )}
        {this.state.pageDelete.open && (
          <DialogConfirm
            open={this.state.pageDelete.open}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={this.onDelete}
            onClose={() => this.handlePageDelete()}
            title="VOCÊ QUER MESMO DELETAR ESTE ITEM?"
            message={this.state.pageDelete.values.name}
          />
        )}
      </Fragment>
    );
  }
}
