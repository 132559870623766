import React,{useState} from 'react';
// ------------------------------common-----------------------------------------
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import Dialog from '@common/Dialog';
// ------------------------------material-ui-------------------------------------
// ------------------------------components-------------------------------------
import Avatares from './Avatares';
import ViewActivity from '../../../Projects/Project/ViewActivity'
import Constants from '../../../../data/Constants';
import Actions from '../../../../data/Actions';
import Options from '../../../Projects/Project/options';
import Store from '@data/Store'

const { language } = Store;

const action = new Actions()
export default function({data=[], open, onClose, title, redirect}){
  let [_act,set_act]=useState([]);
  let [view,setView]=useState(false);

	const getAc=(id)=>{
    action
     .execute('get', `${Constants.APIEndpoints.PROJECT}/activity/${id}`, '')
     .then((res) => {
			 set_act(res.data[0]);
			 setView(true);
		  })
	}

  let options2 = Options({
    companies:[],
    users:[],
    language:[],
    project_stages:[],
    contracts:[],
    tickets:[],
    departments:[],
  });

  const sendEmail = () => {
    action
      .execute('post', Constants.APIEndpoints.PROJECT + '/sendEmailResponsableActivitiesDelayed', '')
      .then(res => {
        Store.toggleAlert(true, language.EMAILS_SENT_SUCCESSFULLY, 'success');
      });
  }

	return(
    <React.Fragment>
      {view==true && _act.id_project_activity ?
         <ViewActivity
         open={true}
         disableBt={true}
         data={_act}
         onOpenForm={true}
         onClose={() => setView(false)}
         users={[]}
         options={options2}
         project={[]}
         action={new Actions()}
         APIEndpoints={Constants.APIEndpoints}
         subActivities={[]}
         onSubmit={[]}
         onSubmitActivitieAntecessor={[]}
         onDownloadFile={[]}
         onUpdate={[]}
         template={true}
      /> :null }

      <Dialog
         open={open}
         onClose={onClose}
         maxWidth='xl'
         bar={false}

			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}

			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
      >
         <CommonTable
            className='table-primary-pmo'
            title={title}
            paginationTop={false}
            maxHeight={500}
            AppBarProps={{
               style:{
                  borderRadius: '10px 10px 0 0'
               }
            }}
            col={[
               {key:'', label: language.ACTIONS},
               {key:'id', label: language.PROJECT_ID},
               {key:'name', label: language.PROJECT},
               {key:'activity', label:language.ACTIVITY},
               {key:'sponsors', label:language.RESPONSIBLES},
               {key:'evolution', label: language.EVOLUTION},
               {key:'status', label: language.STATUS},
               {key:'dt_i', label: language.START_DATE},
               {key:'dt_f', label: language.END_DATE},

            ]}
            beforeBar2={
            <div style={{padding : 10}}>
               <Button
               icon='fas fa-envelope'
               color='warning'
               variant='normal'
               label={language.SEND_EMAIL_TO_RESPONSIBLE_PERSONS}
               onClick={() => sendEmail()}
               style={{marginRight : 30}}
            />
              <Button
                  icon='fas fa-times'
                  color='light'
                  variant='circle'
                  outlined
                  onClick={onClose}
               />
            </div>
            }

            data={data.map(e=>({
               actions:(
                  <i
                     className='fas fa-eye'
                     style={{color:'var(--primary)', fontSize:18, cursor:'pointer'}}
                     onClick={() => {
                        getAc(e.id)
                     }}
                  />
               ),
               id:e.id_project,
               name:e.name,
               activity:e.id+' - '+e.activity,
               sponsors:(
                  <Avatares
                     data={e.sponsors}
                  />
               ),
               evolution:(

                  <div className='d-flex align-items-center content-progress'>
                     <Progress current={parseInt(e.evolution)} hiddenText className='w-100' background='var(--warning)'/>
                     <span className='ml-2'>{parseInt(e.evolution)}%</span>
                  </div>

               ),
               status:(
                  <div className='status'>
                     {e.status}
                  </div>
               ),
               dt_i:e.dt_i,
               dt_f:e.dt_f
            }))}
         />
      </Dialog>
    </React.Fragment>
	)
}
