import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core'; //comum
import { Toolbar, Typography } from '@material-ui/core'; //header
import CommonTable from '@common/CommonTable';
import './GV.css';
import '@bClass';
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
// -----------------------------------------------------
import ContainerChart from './GV.graph.js'
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts-more';

export default class GV extends Component {
	render() {
		let data1 = {
			title: 'Severidade', data: [
				{ name: 'Crítico', y: 24, colo: '#F44336' },
				{ name: 'Baixo', y: 29, color: '#33ABA0' },
				{ name: 'Médio', y: 31, color: '#DAA520' },
				{ name: 'Importante', y: 16 },
			]
		};
		let data2 = {
			title: 'Produto Afetado', data: [
				{
					name: "Chrome",
					y: 62.74,

				},
				{
					name: "Firefox",
					y: 10.57,

				},
				{
					name: "Internet Explorer",
					y: 7.23,
					drilldown: "Internet Explorer"
				},
				{
					name: "Safari",
					y: 5.58,

				},
				{
					name: "Edge",
					y: 4.02,

				},
				{
					name: "Opera",
					y: 1.92,

				},
				{
					name: "Other",
					y: 7.62,
				}
			]
		}
		let col = [
			{ key: 'cve', label: 'CVE', style: { width: 120, paddingRight: 0 }, thConfig: true },
			{ key: 'title', label: 'Titulo', style: { width: 400 }, thConfig: true },
			{ key: 'date', label: 'Data' },
			{ key: 'affectedProduct', label: 'Produto Afetado' },
			{ key: 'severity', label: 'Severidade' },
			{ key: 'impact', label: 'Impacto', style: { paddingRight: 0 }, thConfig: true },
			{ key: 'exploit', label: 'Exploit' },
			{ key: 'action', label: 'Ação' },
		]
		let data = [1, 2, 3, 4, 5].map(e => {
			return {
				cve: 'ID_' + e,
				title: 'title' + e,
				date: 'date' + e,
				affectedProduct: 'affected_product' + e,
				severity: 'severity' + e,
				impact: 'impact' + e,
				exploit: 'exploit' + e,
				action: 'action' + e,
			}
		});
		data.unshift({
			"cve": "CVE-2013-1283",
			"title": "Vulnerabilities in Kernel-Mode Driver Could Allow Elevation Of Privilege",
			"date": "2013/04/09",
			"affectedProduct": "Windows 7 for x64-based Systems",
			"severity": "Important",
			"impact": "Elevation of Privilege",
			"exploits": "2808735",

			"action": "action",

		})
		return (
			<CommonPage
				title={
					<div
						className="logoWhite"
						onClick={() => this.redirect('/dash')}
					/>
				}
				showMenuIconButton={false}
				iconAlert={true}
			>
				<PageLayout
					icon="requesterIcon titleIconSize"
					title="Gestão de vulnerabilidade"
					subtitle=''
					rightElements={[]}
				>
					<div id='GV' style={{ padding: 20 }}>
						<Grid container spacing={3} style={{ marginBottom: 10 }}>
							<Grid item xs={12} className='col'>
								<Paper style={{ padding: '20px 0' }}>

									<div className="d-flex justify-content-around">

										<ReactHighcharts config={ContainerChart.container0(data1)}></ReactHighcharts>
										<ReactHighcharts config={ContainerChart.container0(data2)}></ReactHighcharts>
									</div>
								</Paper>
							</Grid>
						</Grid>
						<Grid container spacing={3} style={{ marginBottom: 10 }}>
							<Grid item xs={12} className='col'>
								<CommonTable data={data} col={col} />
							</Grid>
						</Grid>
					</div>
				</PageLayout>
			</CommonPage>
		)
	}
}