export default function({
	users=[],
   companies=[],
   projects=[]
}){
	//------------------- caso as opções sejam dinâmicas, fazer o controle dela aqui nessa tela
	return {
		
        company(){
        return companies.map(e => ({value:e.id_company, label:e.name}));
      },

      projects(company) {
        return projects.filter(p => p.fk_id_company === company).map(p => ({value: p.id_project, label: p.name}))
      },
      department(find=() => true){
         let dep=[];
         let options=[];
         try{
                 companies.find(find).department.map(e =>{
                if(dep.filter(b=>b == e.id_department).length==0){
                dep.push(e.id_department)
                options.push({value:e.id_department, label:e.name})
                }
            })
            return options
         }catch(e){
            return [];
         }
         return
      },
      users(company){
          let options = users;
          if (company) {
              options = users.filter(u => u.id_company === company);
          }
         return options.filter(g=>g.deleted==false && g.status==true).map(u =>({ value: u.id_user, label: u.name }));
      },
		
		// ----------------------------funções auxiliares------------------------------------
		getOptions(option, v, id){
			let _return = ((typeof option)=='string' ? this[option](id) : option).find(e=>e.value==v);
			return (_return||{}).label
		},

		getOptionsJoin(option, v, id){
			let _return = ((typeof option)=='string' ? this[option](id) : option).filter(e=>v.includes(e.value)).map(e=>e.label);
			return (_return||[]).join(', ')
		}
	}
}