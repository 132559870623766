import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ServicePortalApp from './ServicePortalApp';

export default function ServicePortal() {
    return (
        <Router>
            <ServicePortalApp />
        </Router>
    )
}
