import React from 'react';
import { LoginChangePassword } from '../../login/LoginChangePassword';

export const ServicePortalAccountPassword = () => {
  return (
    <div className="tw-px-4" style={{ minHeight: 280 }}>
      <LoginChangePassword
        redirectToLoginPage={false}
        overrideAlertSuccessVariant="default"
      />
    </div>
  );
};
