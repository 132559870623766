import React, { useState, useEffect } from 'react';
import './index.css';
/*----------------------------------common----------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';

/*------------------------------material-ui----------------------------------*/
import { Grid, Card } from '@material-ui/core';
/*--------------------------------------------------------------------------*/
import { SubCategories } from './Graph';

import CommonForm from '@common/CommonForm2';
import RaisedButton from '@common/RaisedButton';

export default function ({
  data,
  data1,
  openModalDetail,
  categories,
  load,
  language,
}) {
  let [idcat, setIdcat] = useState(1);

  if (idcat == 2) {
    data = data1;
  }

  useEffect(() => {
    try {
      let result = [];
      data.forEach(function (a) {
        if (!this[a.name]) {
          this[a.name] = { name: a.name, y: 0, color: a.color };
          result.push(this[a.name]);
        }
        this[a.name].y += a.y;
      }, Object.create(null));

      data = result;
    } catch (e) {}
  }, [load]);

  const tp = idcat === 1 ? 'service' : 'catalog';

  return (
    <Card className="h-100">
      <AppBar
        title={`TOP 20 ${
          idcat == 1 ? language.SUBCATEGORIES : language.THIRD_LEVEL_CATEGORY
        } `}
      >
        <RaisedButton
          circleButton
          icon="fa fa-retweet"
          label={
            idcat == 1
              ? language.SWITCH_TO_THIRD_LEVEL_CATEGORY
              : language.SWITCH_TO_SUBCATEGORY
          }
          color="sucess"
          style={{ fontSize: 13, width: 21, height: 21, marginLeft: '80%' }}
          onClick={() => {
            if (idcat == 1) {
              setIdcat(2);
            } else {
              setIdcat(1);
            }
          }}
        />
      </AppBar>

      <div
        className="content pt-0"
        style={{ width: '390px', overflowX: 'scroll' }}
      >
        <div className="content pt-0" style={{ width: '700px' }}>
          <ReactHighcharts
            config={SubCategories({ data, openModalDetail, categories, tp })}
          />
        </div>
      </div>
    </Card>
  );
}
