import React, {memo} from 'react';
import CommonTable from '@common/CommonTable';
import classnames from 'classnames';
import Button from '@common/Button'; 
import Store from '@data/Store';

const { language } = Store;

export default memo(({onOpenModal, data=[]})=>{
	return(
		<div id="table1_sprint" >
		<CommonTable
			paginationTop={false}
			beforeBar={
				<Button
					icon='fas fa-plus'
					label={language.ADD}
					color='warning'
					variant='circle'
					className='mr-3'
					onClick={() => onOpenModal()}
				/>
			}
			afterBar={
				<Button
					icon='fas fa-plus'
					label={language.BACKLOG}
					color='warning'
					variant='normal'
					className='mr-3'
					onClick={() => onOpenModal(true)}
				/>
			}
			col={[
				{key:'project', label:language.PROJECT},
				{key:'activity', label: language.ACTIVITY},
				{key:'effort', label:'SLA', noOrder: true},
				{key: 'effort_value', label: language.ACTIVITY_EFFORT, noOrder: true},
				{key:'priority', label: language.PRIORITY},
				{key: 'delivery_date', label: language.DELIVERY_DATE },
				{key: 'company_name', label: language.COMPANY},
				{key: 'sponsor', label: language.REQUESTER},
				{key:'del', label:''},
			]}
			data={data}
		/>
	</div>

	)
})