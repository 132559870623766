import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import Button from '@common/Button';
import MenuDefault from './default/index';
import { AppBar, Drawer } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';

import { Dialog } from '@material-ui/core';
import { Container, Col } from 'react-grid-system';
import { FaSpinner, FaCaretDown } from 'react-icons/lib/fa';
import MediaQuery from 'react-responsive';
import Store from '../data/Store';
import Constants from '../data/Constants';
import Actions from '../data/Actions';
import ReactTooltip from 'react-tooltip';
import 'react-tippy/dist/tippy.css';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import history2 from '@common/history2';
import history from '@common/history';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import { ActionOpenInNew } from 'material-ui/svg-icons';
import { Tooltip } from 'react-tippy';
import { Chip, Avatar } from '@material-ui/core';
import SimplesAlert from '@common/SimplesAlert';
import CommonPageMenu from '@common/CommonPageMenu';
import '../bClass.css';
import './styles.css';

import '../../node_modules/nprogress/nprogress.css';
import NProgress from '../../node_modules/nprogress/nprogress.js';
import MarketingStrategy from '@images/marketing-strategy.png';
import { Badge, Grid } from '@material-ui/core';
import './CommonPage.css';
import FloatChat from '../components/FloatChat/FloatChat';
import Notification from '../components/Notification/Notification.js';

import 'react-perfect-scrollbar/dist/css/styles.css';
import $ from 'jquery';
import FlagBr from '@images/flags_language/flag_brazil.gif';
import FlagEua from '@images/flags_language/flag_EUA.gif';
import FlagSpain from '@images/flags_language/Flag_of_Spain.gif';
NProgress.configure({ showSpinner: false });

history2.listen((location, action) => {
  window.scrollTo(0, 0);
});

function ProgressLoading({ loading }) {
  useEffect(() => {
    if (loading) {
      NProgress.set(0.3);
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [!!loading]);
  return null;
}

@observer
export default class CommonPage extends Component {
  constructor(props) {
    super(props);
    this.history2 = history2;
    this.history = history;
    this.store = Store;
    this.APIRoot = Constants.APIRoot;
    this.APIEndpoints = Constants.APIEndpoints;
    this.action = new Actions();
    this.state = {
      open: false,
      error: false,
      anchorEl: null,
      loading: 0,
      notificationButton: {
        index: 0,
        anchorEl: null
      }
    };
    this.msg = '';
    this.alertOptions = {
      offset: 14,
      position: 'top right',
      theme: 'light',
      time: 5000,
      transition: 'scale',
      type: 'info'
    };
    this.handleClick = this.handleClick.bind(this);

    this.redirect = this.redirect.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  redirect(route, state) {
    window.history.pushState({ key: 'sensr' + route, state: state }, '', route);
    window.location.reload();
  }
  redirect2(route, state) {
    history2.push(route, state);
  }

  handleToggle = () => this.setState({ open: !this.state.open });

  onLogout() {
    this.action.execute(
      'post',
      this.APIEndpoints.LOGOUT,
      'logout',
      this.store.getUserLoged()
    );
    this.redirect('/');
  }

  handleClick(event, v) {
    this.setState({ anchorEl: event.currentTarget, open: v });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };
  componentWillMount() {

      this.store.profilePhoto =
        this.APIRoot +
        '/public/users/' +
        this.store.getUserLoged().id +
        '/profileLogo.png';

     this.action.execute(
      'get',
      this.APIEndpoints.USER + '/techgroups',
      'groupstech'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TICKET + '/getcomments',
      'new_msg_notifications'
    );
  }

  componentDidMount() {

    $.cssNumber.zoom = true;
    if (!("zoom" in document.body.style)) {
      $.cssHooks.zoom = {
        get: function (elem, computed, extra) {
          var value = $(elem).data('zoom');
          return value != null ? value : 1;
        },
        set: function (elem, value) {
          var $elem = $(elem);
          var size = { // without margin
            width: $elem.outerWidth(),
            height: $elem.outerHeight()
          };
          $elem.data('zoom', value);
          if (value != 1) {
            $elem.css({
              transform: 'scale(' + value + ')',
              marginLeft: (size.width * value - size.width) / 2,
              marginRight: (size.width * value - size.width) / 2,
              marginTop: (size.height * value - size.height) / 2,
              marginBottom: (size.height * value - size.height) / 2
            });
          } else {
            $elem.css({
              transform: 'scale(1)',
              margin: 0
            });
          }
        }
      };

      $('[zoom]').each((i, el) => {

        $(el).css({ zoom: $(el).attr('zoom') })
      })
    }


  }

  componentDidUpdate(nextState, nextProps) {
    /*if(this.store.loading==1 && this.state.loading!=this.store.loading){
      this.setState({loading:1})
      console.log(this.store.loading, nextState.loading)
      NProgress.start()
    }else if(this.store.loading==0 && this.state.loading!=this.store.loading){
      this.setState({loading:0})
      console.log(this.store.loading, nextState.loading)
      NProgress.done()
    }*/
  }
  logoutApp() {
    window.localStorage.clear();
    localStorage.clear();
    this.redirect('/');
  }

  // -----------------------------------abre a msg--------------------------------------------------
  openMsg = t => {

    this.action
      .execute('get', this.APIEndpoints.TICKET + '/' + t.id_tickets, '')
      .then(e => {
        this.store.$emit('remove-msg-of-list-notification', 'new_msg_notifications', { id_comments: t.id_comments });
        // this.action.execute('get', this.APIEndpoints.TICKET + '/' + 137, '').then(e=>{

        e.data.selectTypeMsg = t.type;
        if (!Store.tickets_chat_opens.find(e => e.id_tickets == t.id_tickets)) {
          Store.tickets_chat_opens.push(e.data);
        } else {
          Store.tickets_chat_opens = Store.tickets_chat_opens.map(tco =>
            tco.id_tickets == t.id_tickets ? e.data : tco
          );
        }
      });
  };
  closeChat = id_tickets => {
    Store.tickets_chat_opens = Store.tickets_chat_opens.filter(
      t => t.id_tickets != id_tickets
    );
  };
  render() {
    let {
      language,
      language_type,
      profilePhoto,
      new_msg_notifications,
      tickets_chat_opens
    } = this.store;
    let alert = !!this.store.alerts && !this.state.error;
    const { anchorEl } = this.state;
    //const open = Boolean(anchorEl);
    const open = this.state.open;
    if (alert) {
      setTimeout(() => {
        this.store.alerts = '';
      }, 5000);
    }
    //console.log(this.store.getUserLoged().perfil)

    var patternList = {
      className: 'drawerIconSize custom-icon-button',
      anchorEl,
      onClose: () => this.handleClose(),
      menuProps: {
        onClick: () => this.handleClose()
      }
    };
    let p = this.store.getUserLoged().perfil;
    let user = this.store.getUserLoged();

    return (
      <div id="CommonPage" style={{ backgroundColor: '#EAF3F4' }}>

        <MediaQuery maxDeviceWidth={767}>
          {matches => (
            <div>
              <AppBar
                zdepth={1}
                titlestyle={{
                  textAlign: !this.props.titleCenter ? 'left' : '',
                  height: 'auto'
                }}
                style={{
                  position: 'fixed',
                  top: 0,
                  backgroundColor: '#fff',
                  borderBottom: '2px solid',
                  height: 65,
                  display: 'flex',
                  alignItems: 'center',
                  zIndex: 10,
                  paddingRight: 0
                }}
                className="colorPrimary"
                title={this.props.title || ''}
                id='header'
                showMenuIconButton={matches}
                iconElementRight={
                  <div className="commonIconMenu">
                    {

                      <Notification
                        new_msg_notifications={new_msg_notifications}
                        openMsg={this.openMsg}
                        language={language}
                        role={user.role}
                        userid={user.id}
                      />
                    }
                    <Tooltip
                      html={
                        <div style={{ color: '#fff', fontSize: 15 }}>
                          <strong>{language.LANGUAGE}</strong>
                        </div>
                      }
                      position="bottom"
                      theme="dark"
                      arrow={true}
                    >
                      <div
                        onClick={() =>
                          this.store.handleLanguages(
                            language_type === 'PT_BR' ? 'EN' : 
                              language_type === 'EN' ? 'ES' :
                                language_type === 'ES' ? 'PT_BR' : 'PT_BR'
                          )
                        }
                        className="d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          className="font mr-2"
                          style={{ color: 'var(--primary)' }}
                        >
                          {language_type === 'PT_BR' ? 'Português' : language_type === 'EN' ? 'English' : 'Español'}
                        </span>
                        <img
                          src={language_type === 'PT_BR' ? FlagBr : language_type === 'EN' ? FlagEua : FlagSpain}
                          alt={language_type}
                          style={{
                            width: 30
                          }}
                        />
                      </div>
                    </Tooltip>
                    <IconButton
                      color="secondary"
                      style={{
                        fontSize: 15,
                        marginRight: 25,
                        backgroundColor: 'transparent'
                      }}
                      className="userIconMenu"
                      aria-owns={anchorEl ? 'USER' : undefined}
                      aria-haspopup="true"
                      onClick={event => this.handleClick(event, 'USER')}
                      aria-label="Add an alarm"
                    >
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            <strong>{language.ACCOUNT}</strong>
                          </div>
                        }
                        position="bottom"
                        theme="dark"
                        arrow={true}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              style={{
                                background: 'white',
                                border: '1.9px solid var(--primary)',
                                width: 40,
                                height: 40,
                                ...(profilePhoto ? { borderWidth: 0 } : {})
                              }}
                              src={profilePhoto}
                            >
                              <div
                                className="clientIcon iconMenuClient"
                                style={{ width: '100%', height: '100%' }}
                              />
                            </Avatar>
                          }
                          label={this.store.getUserLoged().name}
                          onDelete={() => null}
                          deleteIcon={<FaCaretDown />}
                        />
                      </Tooltip>
                    </IconButton>

                    <Menu
                      id="USER"
                      anchorEl={anchorEl}
                      open={open == 'USER' ? true : false}
                      onClose={this.handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem
                        className="py-4"
                        onClick={() => this.redirect2('/dash/changeData')}
                      >
                        <IconButton
                          className="changeDataIcon"
                          style={{ width: 30, height: 30, marginRight: 9 }}
                        />
                        {language.CHANGE_DATA}{' '}
                      </MenuItem>

                      <MenuItem
                        className="py-4"
                        onClick={() =>
                          this.redirect2('/dash/changePass', { role: 'out' })
                        }
                      >
                        <IconButton
                          className="changePassIcon"
                          style={{ width: 30, height: 30, marginRight: 9 }}
                        />
                        {language.CHANGE_PASSWORD}{' '}
                      </MenuItem>

                      <MenuItem
                        className="py-4"
                        onClick={() => this.logoutApp()}
                      >
                        <IconButton
                          className="logoutIcon"
                          style={{ width: 30, height: 30, marginRight: 9 }}
                        />
                        {language.LOGOFF}{' '}
                      </MenuItem>
                    </Menu>
                  </div>
                }
              />
              <MenuDefault
                language={language}
                redirect={this.redirect}
                redirect2={this.redirect2}
                perfil={p}
                user={user}
              >  <div
                style={{
                  width: `calc(100% - ${matches ? 0 : 35}px`,
                  marginLeft: matches ? 0 : 'auto',
                  marginRight: matches ? 0 : 'auto',
                  paddingBottom: 10,
                  marginTop: 90
                }}
              >
                  {this.props.children}
                </div>

              </MenuDefault>
            </div>
          )}
        </MediaQuery>

        {/*<SimplesAlert
          float
          open={alert}
          message={this.store.alerts.msg}
          variant={this.store.alerts.type}
          onClose={()=>{this.store.alerts = ''}}
        />*/}
        <SimplesAlert
          float
          open={this.store.alert.open}
          message={this.store.alert.message}
          variant={this.store.alert.variant}
          onClose={() => this.store.toggleAlert(false)}
        />

        {tickets_chat_opens
          .slice(
            tickets_chat_opens.length > 2 ? tickets_chat_opens.length - 2 : 0,
            tickets_chat_opens.length
          )
          .map((ticket, i) => (
            <FloatChat
              groupstech={this.store.groupstech}
              language={language}
              index={i}
              key={i}
              role={this.store.getUserLoged().role}
              ticket={ticket}
              selectTypeMsg={ticket.selectTypeMsg}
              comments={ticket.comments}
              loading={this.store.$btn_loading(
                'float-chat-' + ticket.id_tickets
              )}

              closeChat={this.closeChat}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              statusTicket={ticket.status}
              maxHeightChat={383}
              redirect={this.redirect}
              type_ticket={[
                null,
                this.store.getUserLoged().role == 'tech' ? 'tech' : 'solicitant'
              ]}
            />
          ))}
      </div>
    );
  }
}
