import ChiefAlternative from './Index';
import CommonPage from '../../common/CommonPage';

export default class Index extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      logo: false,
    };
  }
  render() {
    return (
      <ChiefAlternative
        action={this.action}
        APIEndpoints={this.APIEndpoints}
        store={this.store}
        language={this.store.language}
      ></ChiefAlternative>
    );
  }
}
