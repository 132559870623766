import { observable, computed, action } from 'mobx';
import Dispatcher from './Dispatcher';
import Dictionary from '../common/languages/Dictionary';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import 'moment/min/locales.min';
import globalSocket from './socket.js';
import { io } from '@common/Func';
import crypt from '../util/crypt';
import themes from '@common/themes';
import Constants from './Constants';
const socket = io();

function dataJson(data) {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
}


var language = sessionStorage.getItem('sensr_lang');

if (!language) {
  const userLanguage = (
    navigator.language || navigator.userLanguage
  ).toUpperCase();

  if (userLanguage.startsWith('ES')) {
    language = 'ES';
  } else if (userLanguage.startsWith('EN')) {
    language = 'EN';
  } else {
    language = 'PT_BR';
  }
}

if (language === 'PT_BR') {
  moment.locale('pt-br');
} else {
  moment.locale('en-ca');
}
class Store {
  constructor() {
    this.dispatchToken = '';
    this.execute();
    this.socket = socket;
    this.getTenant = this.getTenant();

    const _globalSocket = globalSocket.bind(this);
    _globalSocket(socket);
  }

  @observable myData = { id: 258, company: { id: 44 } };
  @observable logo = '';
  @observable profilePhoto = undefined;
  @observable alerts = '';
  @observable alert = {
    open: false,
    variant: '',
    message: ''
  };
  @observable monitordevicecompany = [];
  @observable maturity = [];
  @observable publicIp = {};
  @observable prioritization = [];
  @observable all_milestones = [];
  @observable amount_risks = [];
  @observable backups = '';
  @observable activities = [];
  @observable activities_project = [];
  @observable audit = [];
  @observable assessment = [];
  @observable assessments = [];
  @observable capacity = [];
  @observable catalogs = [];
  @observable categories = [];
  @observable mailcontracts = [];
  @observable change = [];
  @observable change_selected = {};
  @observable change_actions = [];
  @observable change_risks = [];
  @observable changes = [];
  @observable chiefs = [];
  @observable cities = [];
  @observable companies = [];
  @observable companiess = [];
  @observable contracts = [];
  @observable contractslists = [];
  @observable countries = [];
  @observable danger = [];
  @observable devices = [];
  @observable device = [];
  @observable sprints = [];
  @observable automations = [];
  @observable dashboard = {
    changesGSD: [],
    ticketsGSD: []
  };
  @observable gsdCount = {
    changesGSD: [],
    ticketsGSD: [],
    ticketsGSDRequest: []
  };
  @observable ticketlist = [];
  @observable yeartype = null;
  @observable budgetcontrols = [];
  @observable calls = [];
  @observable nps = [];
  @observable departments = [];
  @observable bugetContracts = [];
  @observable department_users = [];
  @observable fk_id_impact = [];
  @observable formstemplate = [];
  @observable formstemplates = [];
  @observable groups = [];
  @observable gmuds = [];
  @observable general_parameters = {};
  @observable impacts = [];
  @observable items = [];
  @observable itsm = [];
  @observable language = Dictionary[language];
  @observable language_type = language;
  @observable loading = 0;
  @observable milestones = [];
  @observable monitor = [];
  @observable monitorGroups = [];
  @observable project = {};
  @observable projects = [];
  @observable projectsbsc = [];
  @observable sprintpjs = [];
  @observable project_stages = [];
  @observable occupations=[];
  @observable reports = [];
  @observable requests = [];
  @observable risks = [];
  @observable risks_test = [];
  @observable matrizplanning = [];
  @observable repor_risks_test = [];
  @observable serviceOrder = '';
  @observable serviceOrders = [];
  @observable services = [];
  @observable solutions = [];
  @observable softwares = [];
  @observable services = [];
  @observable sections = [];
  @observable hardwares = [];
  @observable monitordevice = [];
  @observable mem_monitor = [];
  @observable msgLoading = false;
  @observable stages = [];
  @observable states = [];
  @observable slas = [];
  @observable tasks = [];
  @observable top10 = [];
  @observable techs = [];
  @observable attendance = [];  
  @observable ticket = '';
  @observable tickets = [];
  @observable ticketsCategories = [];
  @observable tickets_stop_sla = [];
  @observable tickets_types = [];
  @observable tickets_types_cat = [];
  @observable mail = [];
  @observable top10company = [];
  @observable mails = [];
  @observable attachment = [];
  @observable pmoactivity = [];
  @observable gsddash = [];
  // @observable typeContracts = [];
  @observable urgencies = [];
  @observable os = [];
  @observable priorities = [];
  @observable complexities = [];
  @observable type_tickets = [];
  @observable type_solution = [];
  @observable users = [];
  @observable userLoged = [];
  @observable videos = [];
  @observable stockins = [];
  @observable stockouts = [];
  @observable stockinlist = [];
  @observable milestone = [];
  @observable activity = [];
  @observable warning = [];
  @observable devicetypes = [];
  @observable companytime = [];
  @observable companytime_ = [];
  @observable companytimes = [];
  @observable cio_project = [];
  @observable ad = {};
  @observable site = [];
  @observable groups = [];
  @observable blackLists = [];
  @observable rels = [];
  @observable contract = [];
  @observable requestsob = [];
  @observable time = [];
  @observable analyst = [];
  @observable gmt = [];
  @observable solicitations = [];
  @observable view = [];
  @observable condevice = [];
  @observable bsc = [];
  @observable bsctialls = [];
  @observable bsccorps = [];
  @observable params = [];
  @observable deviceReport = [];
  @observable milestoneupdate = [];
  @observable discovery = [];
  @observable itensgsd = [];
  @observable matriz = [];
  @observable matrizproblem = [];
  @observable matrizmonth = [];
  @observable mailContract = [];
  @observable new_msg_notifications = [];
  @observable new_notification = [];
  @observable tickets_chat_opens = [];
  @observable timeall = [];
  @observable groupstech = [];
  @observable canvas = [];
  @observable manufactures = [];
  @observable brands = [];
  @observable snmps = [];
  @observable oids = [];
  @observable usercon_company = [];
  @observable perfil = [];
  @observable btn_loading = [];
  @observable subActivities = [];
  @observable inventorydistros = [];
  @observable inventories = [];
  @observable calendar = [];
  @observable pmo = undefined;
  @observable avaliation = [];
  @observable answer = [];
  @observable statusproject = {};
  @observable holiday = [];
  @observable sprintkaban = [];
  @observable capacityplan = [];
  @observable sprintpj = [];
  @observable pmos = [];
  @observable pmosDeleted = [];
  @observable projectsDeletedCount = 0;
  @observable keyLoading = [];
  @observable btnLoading = [];
  @observable projectsCanceled = [];
  @observable okrs = [];
  @observable department_user = {};
  @observable devices_av = [];
  @observable reload = false;
  @observable theme = themes[localStorage.getItem('theme') || 'normal'];
  @observable spinner = false;



  @action
  update(data) {
    Object.keys(data).forEach((e) => {
      this[e] = data[e];
    });
  };

  handleTheme(theme) {

    if (theme == 'grey') {
      localStorage.setItem('theme', 'grey');
      this.theme = themes['grey'];
    } else {
      localStorage.removeItem('theme');
      this.theme = themes['normal'];
    }
    //window.location.reload();
  }
  isDev = () => window.location.hostname == 'localhost';

  host = () => {
    return window.location.protocol + `//${Constants.APIDomain}`;
  };
  getToken() {
    return sessionStorage.getItem('token');
  }

 

  getHostUrl() {
    return `https://${Constants.APIDomain}`;
  }
  setUserLoged(data) {
    var setUser = Object.assign(this.getUserLoged(), data);
    setUser = JSON.stringify(setUser);
    setUser = window.btoa(setUser);
    sessionStorage.setItem('user', setUser);
  }

  getTenant(){
    let tenant =
    Constants.Tenant || (window.location.host == 'localhost:3110' ? 'dev' :
    window.location.hostname.replace(/\/|.sensr.com.br|.sensrit.com.br|.sensr.it/g, ""))
    //console.log(tenant,"tenant chat")
    return tenant;
  }
  
  getUserLoged() {
    let userStorage = sessionStorage.getItem('user');
    if (userStorage) {
      let user = decodeURIComponent(
        atob(
          sessionStorage.getItem('user')
        )
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      return JSON.parse(user);
    }
    return {};
  }
  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
        match,
        p1
      ) {
        return String.fromCharCode('0x' + p1);
      })
    );
  }

  handleLanguages(lang) {
    const langUpperCase = lang.toUpperCase().replace("-", "_");
    this.language = Dictionary[langUpperCase];
    this.language_type = langUpperCase;
    sessionStorage.setItem('sensr_lang', langUpperCase);
  }

  pushAlert(host, server) {
    let { danger, warning } = this;
    if (server) host.id_device = parseInt(host.id_device.replace(/\D/g, ''));

    if (host.normal) {
      danger = danger.filter(d => d != host.id_device);
      warning = warning.filter(d => d != host.id_device);
    } else if (
      (host.type == 'danger' || host.type == 'down') &&
      !danger.includes(host.id_device)
    ) {
      danger.push(host.id_device);
    } else if (host.type == 'warning' && !warning.includes(host.id_device)) {
      warning.push(host.id_device);
    }
  }
  toggleAlert = (open, message = '', variant = this.alert.variant) => {

    var alert = this.alert;
    alert.open = open;
    alert.message = message;
    alert.variant = variant;
    this.alert = alert;

    // this.setState({alert})
  };

  $btn_loading(btn) {
    if ((typeof btn) == 'object') {
      btn = btn.filter(e => this.btn_loading.indexOf(e) != -1);

      if (btn.length) return true;

    } else {

      return this.btn_loading.indexOf(btn) != -1
    }
  }
  $on(name, model, key, _delete, callback) {

    socket.on(name, data => {

      data._control = data._control || { key, _delete }

      key = data._control.key;
      _delete = data._control._delete;
      if (model) {

        if (key) {
          if (_delete) {
            this[model] = this[model].filter(e => e[key] != data[key])
          } else {


            this[model] = this[model].map(e => {
              if (e[key] == data[key]) {
                e = data;
              }
              return e;
            });
          }
        } else {

          data instanceof Array
            ? (this[model] = data)
            : this[model] instanceof Array
              ? this[model].unshift(data)
              : this[model] = data;
        }
      }
      callback(data);
    })

  }
  $emit(name, model, obj, key, _delete, config = {}) {

    return new Promise((resolve, reject) => {

      this.loading++;
      if (config.btn_loading) {
        this.btn_loading = this.btn_loading.concat(config.btn_loading);
      }

      socket.emit(name, obj, (data, err) => {

        if (!err) {


          if (model) {

            if (key) {
              if (_delete) {
                this[model] = this[model].filter(e => e[key] != data[key])
              } else {
                this[model] = this[model].map(e => {
                  if (e[key] == data[key]) {
                    e = data;
                  }
                  return e;
                });
              }
            } else {

              data instanceof Array
                ? (this[model] = data)
                : this[model] instanceof Array
                  ? this[model].unshift(data)
                  : this[model] = data;
            }
          }
          if (config.btn_loading) {
            this.btn_loading = this.btn_loading.filter(x => !config.btn_loading.includes(x));
          }
          this.loading--;
          resolve(data);
        } else {
          reject(err)
        }
      })
    })
  }
  setModel(action) {
    action.config = action.config == undefined ? {} : action.config;
    switch (action.model) {
      case 'errors':

        if (action.data === 'REDIRECT') {
          window.location.href = window.location.origin;
        } else if (action.data === 'LOGOUT') {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user');
          window.location.href = window.location.origin;
        } else {
          let data =
            typeof action.data === 'object'
              ? action.data
              : dataJson(action.data);
          this.codigo = data.code || action.data.message;
          this.alerts = {
            msg: this.language.ERROR_CODE[data.code] || action.data.message,
            type: 'error'
          };
        }
        break;
      default:
        switch (action.type) {
          case 'post':
            if (action.config.key) {
              this[action.model] = this[action.model].map(data => {
                if (data[action.config.key] == action.data[action.config.key]) {
                  data = action.data;
                }
                return data;
              });
            } else {
              action.data instanceof Array
                ? (this[action.model] = action.data)
                : this[action.model] instanceof Array
                  ? this[action.model].unshift(action.data)
                  : (this[action.model] = action.data);
            }

            if (action.alert) {
              this.toggleAlert(
                true,
                this.language.ALERT_INSERT_SUCCESS,
                'success'
              );
            }
            break;
          case 'put':
            if (action.primaryKey) {
              action.model.endsWith('s')
                ? (this[action.model] = this[action.model].map(data => {
                  if (
                    data[action.primaryKey] == action.data[action.primaryKey]
                  ) {
                    data = action.data;
                  }
                  return data;
                }))
                : (this[action.model] = action.data);
            } else {
              this[action.model] = action.data;
            }
            if (action.alert) {

              this.toggleAlert(
                true,
                this.language.ALERT_UPDATE_SUCCESS,
                'success'
              );
            }

            break;
          case 'delete':
            if (action.primaryKey) {
              this[action.model] = this[action.model].filter(data => {
                return (
                  data[action.primaryKey] != action.data[action.primaryKey]
                );
              });
            } else {
              this[action.model] = action.data;
            }
            this.toggleAlert(
              true,
              this.language.ALERT_DELETE_SUCCESS,
              'success'
            );

            break;
          case 'get':
            this[action.model] = action.data;
            break;
          default:
            break;
        }
        break;
    }
  }
  execute() {
    this.dispatchToken = Dispatcher.register(action => {

      this.setModel(action);

      this.loading--;
    });
  }
}

const appStore = new Store();

export default appStore;
