import React from 'react';
import { Card } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import { Constants } from '@data'

export default function Remove({
  language,
  values,
  options,
  setFormReference,
  handleValue,
  setValue,
}) {

  function loadFields(){

    let fields = [
      {
        col: 4,
        type: 'select',
        name: 'type_category',
        label: 'Item',
        options: options.type_category(values?.rule),
        required: true,
      },
    ];

    if(values?.type_category !== undefined){
      fields = fields.concat([
        {
          col: 4,
          type: 'autocomplete',
          name: 'fk_id_company',
          label: language.COMPANY,
          method: 'POST',
          route: `${Constants.APIEndpoints.COMPANY}/filter`,
          fieldquery: 'search',
          textlabel: language.COMPANY,
          textinit: values?.fk_id_company > 0 ? values.namecompany : '',
          fieldvaluedb: 'id_company',
          fieldlabeldb: 'name',
          idinit: values?.fk_id_company > 0 ? values.fk_id_company : null,
          routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
          methodGetAll: 'POST',
          routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
          detail: true,
          required: true,
        },
        {
          col: 4,
          type: 'autocomplete',
          name: 'fk_id_solicitant',
          label: language.SOLICITANT,
          className: 'tech-group-ticket',
          required: true,
          method: 'POST',
          route: `${Constants.APIEndpoints.USER}/filter`,
          routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
          filterAll: { all_fk_id_company: values.fk_id_company },
          fieldquery: 'search',
          textlabel: language.SOLICITANT,
          textinit:
            values.fk_id_solicitant > 0 ? values.solicitant_name : '',
          fieldvaluedb: 'id_user',
          fieldlabeldb: 'name',
          idinit: values.fk_id_solicitant > 0 ? values.fk_id_solicitant : null,
          routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
          detail: true,
          methodGetAll: 'POST',
        },
        {
          col: 4,
          type: 'autocomplete',
          name: 'fk_id_tech',
          label: language.TECH,
          className: 'tech-group-ticket',
          required: false,
          method: 'POST',
          route: `${Constants.APIEndpoints.USER}/filter`,
          routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
          filterAll: { all_fk_id_company: values.fk_id_company },
          fieldquery: 'search',
          textlabel: language.TECH,
          textinit: values.fk_id_tech > 0 ? values.tech_name : '',
          fieldvaluedb: 'id_user',
          fieldlabeldb: 'name',
          idinit: values.fk_id_tech > 0 ? values.fk_id_tech : null,
          routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
          detail: true,
          methodGetAll: 'POST',
        },
      ]);
    }

    return fields;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <AppBar title={language.ACTIONS} />
      <div className="content pt-0">
        <CommonForm
          sensitive
          values={{
            type_category: values.type_category,
            fk_id_solicitant: values.fk_id_solicitant,
            fk_id_tech: values.fk_id_tech,
            fk_id_company: values.fk_id_company,
          }}
          onRef={(ref) => setFormReference(ref)}
          fields={loadFields()}
          onChangeField={(f, v) => {

            if (f && f.name == 'type_category') {
              setValue('type_category', v);
            }

            if (f && f.name == 'fk_id_company') {
              setValue('fk_id_company', v);
            }
            
            if (f && f.name == 'fk_id_solicitant') {
              setValue('fk_id_solicitant', v);
            }
            
            if (f && f.name == 'fk_id_tech') {
              setValue('fk_id_tech', v);
            }

            handleValue(v, f.name);
          }}
        />
      </div>
    </Card>
  );
}
