import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import { Container, Row, Col } from 'react-grid-system';
import { FaIndustry, FaEdit, FaClose, FaTrash } from 'react-icons/lib/fa';
import { Checkbox, TextField, Paper, MenuItem, Switch, SelectField } from '@material-ui/core';

import AppBar from '../../common/AppBar';
import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import CommonPage from '../../common/CommonPage';
import PageLayout from '../../common/PageLayout';
import CommonForm2 from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import DialogConfirm from '../../common/DialogConfirm';

import DepartmentAddTaskForm from './DepartmentAddTaskForm';

@observer
export default class CompanyDepartamentForm extends CommonPage {
  constructor() {
    super();
    this.state = {
      departments: [],
      open: false,
      openEdit: false,
      selectdepartment: [],
      opencat: false,
      openNew: false,
      companytext: '',
      categories: [],
      pageDelete: {
        open: false,
        values: undefined,
      },
      department: [],
      departmentsData : [],
      totalDepartments : 0,
      currentPageDepartment : 0,
    };
    this.handleModal = this.handleModal.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.deleteDepartment = this.deleteDepartment.bind(this);
    this.handlePageDelete = this.handlePageDelete.bind(this);
    this.addDepartmentInCompany = this.addDepartmentInCompany.bind(this);
    this.getDepartmentsByCompany = this.getDepartmentsByCompany.bind(this);
    this.callPageSelectFilterDepartments = this.callPageSelectFilterDepartments.bind(this);

  }

  handleModal() {
    this.setState({ open: !this.state.open, openEdit: false });
  }

  callPageSelectFilterDepartments(page = 0, values = {}, totalPerPage = 75) {
    this.setState({ currentPageDepartment: page });
    let limit = totalPerPage || 75;
    let offset = page * limit;
    values.company_exclueded = this.props.values.id_company;

    this.action
      .execute('post', `${this.APIEndpoints.DEPARTMENT}/list?limit=${limit}&offset=${offset}`, '', values)
      .then((res) => {
        this.setState({
          departmentsData: res.data,
          totalDepartments: parseInt(res.data[0] ? res.data[0].total_count : 0),
        });

        return res.data.json();
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          this.props.language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }


  addDepartmentInCompany() {
    let { values } = this.props;
    let { departments } = this.state;
    if (departments && departments.length) {
      this.action
        .execute(
          'put',
          `${this.APIEndpoints.COMPANY}/department`,
          '',
          { departments: departments, id_company: values.id_company },
          'id_company'
        )
        .then((g) => {
          this.getDepartmentsByCompany(values.id_company);
          this.callPageSelectFilterDepartments()
          this.setState({ departments: [] });
          this.handleModal();
        });
    }
  }

  checkBoxSelect(id_department) {
    let { departments } = this.state;
    if (departments.indexOf(id_department) === -1) {
      departments.push(id_department);
    } else {
      departments = departments.filter((id) => id != id_department);
    }
    this.setState({ departments: departments });
  }

  getDepartmentsByCompany(companyId) {
    this.action
      .execute('get', `${this.APIEndpoints.DEPARTMENT}/${companyId}`, '')
      .then((v) => {
        this.setState({ department: v.data });
      });
  }

  changeDepartmentBoss(company, department, boss) {
    this.action
      .execute('put', this.APIEndpoints.COMPANY + '/department/user', ' ', {
        fk_id_company: company,
        fk_id_department: department,
        fk_id_user: boss,
      })
      .then((e) => {
        this.getDepartmentsByCompany(company);
      });
  }

  componentWillMount() {
    const { values } = this.props;
    this.getDepartmentsByCompany(values.id_company);
    this.callPageSelectFilterDepartments()

  }

  handlePageDelete(open = false, values) {
    this.setState({ pageDelete: { open, values } });
  }

  deleteDepartment() {
    const { values } = this.state.pageDelete;
    this.action
      .execute(
        'put',
        `${this.APIEndpoints.COMPANY}/department`,
        'companies',
        values,
        'id_company'
      )
      .then((res) => {
        this.getDepartmentsByCompany(values.id_company);
        this.setState({ department: [] });
        this.handlePageDelete(false, {});
      });
  }

  render() {
    let {
      language,
      handlePage,
      values,

      users,
      arrayUsers,
    } = this.props;
    let { open, department, departmentsData } = this.state;

    let col = [
      { key: 'id', label: '#', style: { width: 20 } },
      {
        key: 'name',
        label: language.NAME,
        thConfig: true,
        style: { width: 1000 },
      },
      { key: 'boss', label: language.DEPARTMENT_BOSS },
      { key: 'adddep', label: language.CATALOG },
      { key: 'edit', label: language.EDIT, thConfig: true, style: { width: 100 } },
      {
        key: 'remove',
        label: language.REMOVE,
        thConfig: true,
        style: { width: 100 },
      },
    ];
    let data = [];
    let dptData = this.state.departmentsData.map((d) => {
      return {
        checkbox: (
          <Checkbox
            checked={this.state.departments.indexOf(d.id_department) != -1}
            onChange={() => this.checkBoxSelect(d.id_department)}
          />
        ),
        id: d.id_department,
        name: d.name,
        description: d.description,
      };
    })
    department
      .filter((e) => e.deleted === false)
      .map((d) => {
        if (data.filter((g) => g.id == d.id_department).length == 0)
          data.push({
            id: d.id_department,
            name: d.name,
            boss: d.chiefname,
            adddep: (
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                label={''}
                color="default"
                style={{ fontSize: 12, width: 21, height: 21 }}
                onClick={() => {
                  this.setState({ selectdepartment: d, opencat: true });
                }}
              />
            ),
            edit: (
              <RaisedButton
                circleButton
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 12, width: 21, height: 21 }}
                onClick={() =>
                  this.setState({ openEdit: true, selectdepartment: d })
                }
              />
            ),
            remove: (
              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                className="ml-2"
                style={{ fontSize: 13, width: 21, height: 21 }}
                size={0.9}
                onClick={() =>
                  this.handlePageDelete(true, {
                    id_department: d.id_department,
                    id_company: values.id_company,
                    name: d.name,
                    detach: true,
                  })
                }
                _loading={
                  'del-company-list-dp-' +
                  d.id_department +
                  '-' +
                  values.id_company
                }
              />
            ),
          });
      });

    let colDptDialog = [
      { key: 'checkbox', label: '', style: { width: 20 } },
      { key: 'id', label: language.ID, style: { width: 20 } },
      { key: 'name', label: language.NAME },
      { key: 'description', label: language.DESCRIPTION },
    ];

    let addDptDialog = (
      <Dialog
        modal={false}
        open={open}
        onClose={() => this.handleModal()}
        title={language.ADD}
        contentProps={{
          id: 'nn',
        }}
        PaperProps={{
          id: 'nn2',
        }}
      >
        <CommonTable
          title={''}
          language={language}
          columns={colDptDialog}
          data={dptData}
          countTotal = {this.state.totalDepartments}
          searchColumnEvent={(v) =>
            this.callPageSelectFilterDepartments(0, v, 75)
          }
          onClearAllFilter={() => this.callPageSelectFilterDepartments()}

          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.callPageSelectFilterDepartments(page, values, rowsPerPage)
          }
          isAutomaticPagination={false}
          hideFooterToolbar
          paginationTop={false}
        />

        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            <div style={{ float: 'right' }}>
              <RaisedButton
                circleButton
                icon="fas fa-save"
                primary={true}
                label={language.ADD}
                fullWidth
                onClick={this.addDepartmentInCompany}
              />
            </div>
          </Col>
        </Row>
      </Dialog>
    );

    return (
      <div>
        {this.state.opencat == true && (
          <Dialog
            open={true}
            maxWidth="md"
            onClose={() => {
              this.setState({ opencat: false });
            }}
          >
            <div style={{ height: '400px' }}>
              <DepartmentAddTaskForm
                listTask={this.store.listTask}
                categories={this.state.categories}
                openNew={this.state.openNew}
                id_department={this.state.selectdepartment.id_department}
                redirect={this.redirect}
                addDepartmentInCompany={this.addDepartmentInCompany}
                departments={this.store.departments}
                values={values}
                language={this.store.language}
                handlePage={() => alert('')}
                changeDepartmentBoss={() => alert('')}
                users={this.store.users}
                handlePageDelete={() => alert('')}
              />
            </div>
          </Dialog>
        )}

        <Paper className="pb-2 mb-3">
          <Container fluid className="pt-2">
            <Row>
              <Col md={3}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  value={values.name}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col md={9}>
                <TextField
                  label={language.DESCRIPTION}
                  multiLine={true}
                  fullWidth={true}
                  disabled={true}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Container>
        </Paper>

        <CommonTable
          language={language}
          columns={col}
          data={data}
          title={language.ID + ' ' + values.id_company}
          maxHeight={1000000000000000}
          index="CompanyDepartamentForm"
          index="table-3"
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.ADD_DEPARTMENT}
              onClick={this.handleModal}
              primary
              fullWidth
            />
          }
        />

        {addDptDialog}
        {this.state.openEdit && (
          <Dialog
            open={true}
            onClose={() => {
              this.setState({ openEdit: false });
            }}
            title={language.EDIT_OR_ADD}
            maxWidth="xs"
            monitor_scroll
          >
            <div style={{ height: '200px' }}>
              <CommonForm2
                sensitive
                values={{
                  boss: this.state.selectdepartment.chief,
                }}
                fields={[
                  {
                    span: 12,
                    type: 'autocomplete',
                    name: 'boss',
                    label: language.DEPARTMENT_BOSS,
                    textlabel: language.REQUESTER,
                    method: 'POST',
                    filter: { fk_id_company: values.id_company },
                    route: `${this.APIEndpoints.USER}/filter`,
                    routeGetCount: `${this.APIEndpoints.USER}/active/count/${values.id_company}`,
                    fieldquery: 'search',
                    textinit: language.DEPARTMENT_BOSS,
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${this.APIEndpoints.USER}/filter/all?`,
                    filterAll: 0,
                    methodGetAll: 'POST',
                    detail: true,
                    visible: true,
                    required: true,
                    showEmail: true,
                  },
                ]}
                onChangeField={(f, v) => {
                  if (v > 0) {
                    this.setState({ openEdit: false });
                    this.changeDepartmentBoss(
                      values.id_company,
                      this.state.selectdepartment.id_department,
                      v
                    );
                  }
                }}
              />
            </div>
          </Dialog>
        )}
        {this.state.pageDelete.open && (
          <DialogConfirm
            open={this.state.pageDelete.open}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={this.deleteDepartment}
            onClose={() => this.handlePageDelete()}
            title={language.WARNING_DELETE_ITEM_SELECTED}
            message={this.state.pageDelete.values.name}
          />
        )}
      </div>
    );
  }
}
