import Store from '@data/Store';

const { language } = Store;

export function alocation({ data = [] }) {
	return {
		chart: {
			type: 'column',
			height: 200,
			fontFamily: 'Roboto',
			style: {
				fontFamily: 'Roboto'
			}
		},
		title: null,

		xAxis: {
			categories: language.DATE_LANG.monthList,
			crosshair: true,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		yAxis: {
			min: 0,
			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>{point.y} </b></td></tr>',
			footerFormat: '</table>',
			shared: true,
			useHTML: true
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0
			},
			series: {
				borderRadius: 3,

				// borderWidth: 0,
				borderColor: 'red',
				dataLabels: {
					enabled: true,
					format: '{point.y:.0f}',
					color: 'var(--primary)',
					style: {
						fontSize: 8,
						color: 'var(--primary)'
					}
				},


			}
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		series: [{
			name: '',
			data
		}]
	}
}
export function perfomance(before, after) {
	return {
		chart: {
			type: 'bar',
			height: 200,
			fontFamily: 'Roboto',
			style: {
				fontFamily: 'Roboto'
			}
		},
		credits: { enabled: false },
		title: null,
		subtitle: null,
		accessibility: {
			point: {
				valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
			}
		},
		xAxis: [{

			categories: [''],
			reversed: false,
			labels: {
				step: 1,
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
			accessibility: {
				description: 'Age (male)'
			}
		}, { // mirror axis on right side
			opposite: true,
			reversed: false,
			categories: [''],
			linkedTo: 0,
			labels: {
				step: 1
			},
			accessibility: {
				description: 'Age (female)'
			}
		}],
		yAxis: {
			min: -100,
			max: 100,
			title: {
				text: null
			},
			labels: {
				formatter: function () {
					return Math.abs(this.value) + '%';
				},
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
			accessibility: {
				description: 'Percentage population',
				rangeDescription: 'Range: 0 to 5%'
			}
		},

		plotOptions: {
			series: {
				stacking: 'normal',
				borderRadius: 3,
				dataLabels: {
					enabled: true,
					style: {
						color: 'white',
						fontSize: '17px',
						textOutline: 0
					},

					formatter: function () {
						return Math.abs(this.y) + '%';
					}
				}
			}
		},
		legend: {
			itemStyle: {
				fontSize: 10
			}
		},

		tooltip: {
			formatter: function () {
				return '<b>' + this.series.name + ' ' + this.point.category + '</b><br/>' +
					'Perfomance: ' + Highcharts.numberFormat(Math.abs(this.point.y), 1) + '%';
			}
		},

		series: [{
			name: language.BEFORE_DEADLINE,
			color: 'var(--primary)',
			data: [before]
		}, {
			name: language.AFTER_DEADLINE,
			color: 'var(--warning)',
			data: [after]
		}]
	}
}
export function satisfation(value = 0) {
	return {
		chart: {
			type: 'solidgauge',
			height: 150,
			margin: 0,
			backgroundColor: 'transparent',
			fontFamily: 'Roboto',
			style: {
				fontFamily: 'Roboto'
			}
		},

		title: null,
		pane: {
			center: ['50%', '90%'],
			size: '100%',
			startAngle: -90,
			endAngle: 90,
			background: {
				backgroundColor: '#EEE',
				innerRadius: '60%',
				outerRadius: '100%',
				shape: 'arc'
			}
		},

		exporting: {
			enabled: false
		},

		tooltip: {
			enabled: false
		},

		// the value axis
		yAxis: {
			stops: [
				[0.1, 'var(--danger)'], // red
				[0.4, '#F6D068'], // yellow
				[0.8, Store.theme.PMO.alocation.graph] // green
			],
			lineWidth: 0,
			tickWidth: 0,
			minorTickInterval: null,
			tickAmount: 2,
			title: {
				y: -70
			},
			labels: {
				y: 16,
				enabled: false
			},
			min: 0,
			max: 100,
			title: null,
		},

		plotOptions: {
			solidgauge: {
				dataLabels: {
					y: 0,
					borderWidth: 0,
					useHTML: true
				}
			},

		},

		credits: {
			enabled: false
		},


		series: [{
			name: 'Speed',
			data: [value > 0 ? parseInt(value) : 0],
			dataLabels: {
				format: `
		            	<div style="text-align:center;">
		            		<span style="font-size:20px; color:var(--primary);">{y}%</span><br/>
		            	</div>
		            `
			},
			tooltip: null
		}]
	}
}
