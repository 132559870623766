import React, { useEffect, useState } from 'react';
import { Constants } from '../../data';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { Chip, TextField } from '@material-ui/core';
import { movesToTheFirstPositionOfAnArrayByCondition } from '../../fn';

export default function CompanyConfigSendMail({
  language,
  action,
  values,
  store,
  onSuccess,
}) {
  const [relayList, setRelayList] = useState([]);
  const [currentRelay, setCurrentRelay] = useState(null);

  useEffect(() => {
    store.spinner = true;
    mountCurrentRelay();
    mounRelayList();
  }, []);

  function mountCurrentRelay() {
    store.spinner = true;
    action
      .execute(
        'get',
        `${Constants.APIEndpoints.COMPANY}/config-send-mail/${values.id_company}`,
        ''
      )
      .then(({ data }) => {
        setCurrentRelay(data);
        store.spinner = false;
      })
      .catch((result) => {
        if (result.data.id_error == '464acac9-2901-4b27-97b0-e2c53efb3b0e') {
          store.toggleAlert(
            true,
            language.COMPANY_DOES_NOT_HAVE_SMTP_CONFIGURATION_SELECTED,
            'warning'
          );
        } else {
          store.toggleAlert(
            true,
            language.ERROR_LOADING_CURRENT_SMTP_CONFIGURATION,
            'error'
          );
        }
        store.spinner = false;
      });
  }

  function mounRelayList() {
    store.spinner = true;
    action
      .execute('get', `${Constants.APIEndpoints.MAIL}`, '')
      .then(({ data }) => {
        setRelayList(data);
        store.spinner = false;
      })
      .catch(() => {
        store.toggleAlert(true, language.ERROR_LOADING_SMTP_LIST, 'error');
        store.spinner = false;
      });
  }

  function setConfigSendMail(id_company, id_mail) {
    store.spinner = true;
    action
      .execute(
        'post',
        `${Constants.APIEndpoints.COMPANY}/${id_company}/config-send-mail/${id_mail}`,
        ''
      )
      .then(() => {
        onSuccess();
        store.spinner = false;
        store.toggleAlert(true, language.SMTP_SELECTED_SUCCESSFULLY, 'success');
      })
      .catch((result) => {
        store.toggleAlert(true, language.ERROR_SELECTING_SMTP, 'error');
        store.spinner = false;
      });
  }

  const columnsTable = [
    {
      key: 'id_mail',
      label: language.ID,
      thConfig: true,
      style: { width: 1, textAlign: 'center', paddingRight: 0 },
    },
    {
      key: 'mail',
      label: language.MAIL_MAIL,
      thConfig: true,
      style: { width: 120 },
    },
    {
      key: 'smtp',
      label: language.MAIL_SMTP,
      style: {
        textAlign: 'center',
        width: 200,
        paddingRight: 0,
        paddingLeft: 0,
        width: 50,
      },
      thConfig: true,
    },
    {
      key: 'smtp_port',
      label: language.SMTP_PORT,
      style: {
        textAlign: 'center',
        paddingRight: 0,
        paddingLeft: 0,
        width: 50,
      },
      thConfig: true,
    },
    {
      key: 'tls',
      label: language.MAIL_TLS,
      style: { width: 100 },
      thConfig: true,
    },
    {
      key: 'action',
      label: language.ACTION,
      thConfig: true,
      style: { width: 100, textAlign: 'center' },
    },
  ];

  let dataTable = relayList.map((item) => {
    return {
      id_mail: item.id_mail,
      mail: item.mail,
      smtp: item.smtp,
      smtp_port: item.smtp_port,
      tls: item.tls === 'S' ? language.YES : language.NO,
      action: !(currentRelay?.id_mail === item.id_mail) && (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <RaisedButton
                  circleButton
                  icon="fas fa-plus"
                  label={language.SELECT}
                  color="primaryGradient"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() =>
                    setConfigSendMail(values.id_company, item.id_mail)
                  }
                />
              </div>
            }
          />
        </React.Fragment>
      ),
    };
  });

  dataTable = movesToTheFirstPositionOfAnArrayByCondition(dataTable);

  return (
    <React.Fragment>
      <TextField
        label={language.NAME}
        disabled={true}
        value={values.name}
        style={{ width: '100%' }}
      />
      <CommonTable
        title={''}
        language={language}
        columns={columnsTable}
        index="MailList"
        data={dataTable}
        searchColumn
        orderColumn
        width="100%"
        paginationTop={false}
      />
    </React.Fragment>
  );
}
