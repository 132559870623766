import Store from '@data/Store';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { ExitToApp, Settings } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ServicePortalAccountSettings } from '../ServicePortalAccountSettings';
import { convertJson, decodeData, useServicePortalStyles } from '../helpers';
import { ServicePortalMenuLink } from './ServicePortalMenuLink';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';

export const ServicePortalMenu = ({ items, language }) => {
  const styles = useServicePortalStyles();
  const user = Store.getUserLoged();
  const userSessionStorage = sessionStorage.getItem('user');
  const userData = convertJson(decodeData(userSessionStorage));
  const hasButtonBackSensr = !userData.use_service_portal_only;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [openConfigs, setOpenConfigs] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogout = () => {
    window.location.href = '/';
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      <ul className="sp-menu tw-flex tw-gap-6">
        {hasButtonBackSensr && (
          <li className="sp-menu-item">
            <Link to="" onClick={() => (window.location.href = '/dash')}>
              <Typography
                component="span"
                className={styles.text}
              >{`${language.BACK} SENSRIT`}</Typography>
            </Link>
          </li>
        )}

        {items.map((item) => (
          <ServicePortalMenuLink
            key={item.label}
            item={item}
            language={language}
          />
        ))}
      </ul>
      <div>
        <Button
          size="small"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <span
            className="tw-flex tw-items-center tw-gap-2 sp-menu-item"
            style={{ color: 'var(--suc)' }}
          >
            <Avatar className={styles.avatarSmall} alt={userData.name} />
            <div
              className="tw-flex tw-flex-col tw-gap-0 tw-text-left"
              style={{ maxWidth: 200, lineHeight: '13px' }}
            >
              <span className="sp-link tw-block tw-truncate">
                {userData.name}
              </span>
              <small className="sp-link">{userData.company}</small>
            </div>
          </span>
        </Button>
        <Popper
          transition
          disablePortal
          open={open}
          role={undefined}
          anchorEl={anchorRef.current}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    <MenuItem
                      dense={true}
                      component="div"
                      onClick={(event) => {
                        setOpenConfigs(true);
                        handleClose(event);
                      }}
                      className={classNames(styles.menuItemSmall)}
                    >
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {language.CONFIG}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      dense={true}
                      component="div"
                      onClick={handleLogout}
                      className={classNames(styles.menuItemSmall)}
                    >
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <ExitToApp fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {language.LOGOFF}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <ServicePortalModalWrapper
          maxWidth="md"
          isOpen={openConfigs}
          title={language.CONFIG}
          enableCloseIcon={true}
          disableCloseButton={true}
          disableConfirmButton={true}
          onClose={() => setOpenConfigs(false)}
        >
          <ServicePortalAccountSettings />
        </ServicePortalModalWrapper>
      </div>
    </div>
  );
};
