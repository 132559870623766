import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import PerfectScrollbar from 'react-perfect-scrollbar';
// --------------------------------common----------------------------------------------------
import Button from '../../common/Button';
import AppBar from '../../common/AppBar2';
import CommonForm from '../../common/CommonForm2';

import Header from './Header';
import { Unavailability } from './Graphs';

import Constants from '../../data/Constants';

const tenant = Constants.Tenant;

const Indicator = ({ title, value = 0, percent = false, language }) => (
  <div className="content-medium content-indicator">
    <span className="title">{title}</span>
    <div className="content-value">
      {!percent ? <i className="fas fa-clock" /> : null}
      <span className="value mr-1">
        {value}
        {percent ? '%' : null}
      </span>
      {percent ? (
        <span className="value-inactive">
          {' '}
          / {100 - value}% {language.INACTIVE}
        </span>
      ) : null}
    </div>
  </div>
);

String.prototype.toHHMMSS = function (language) {
  let cur = parseInt(this, 10); // don't forget the second param

  let days = Math.floor(cur / 86400);
  cur -= days * 86400;
  let hours = Math.floor(cur / 3600) % 24;
  cur -= hours * 3600;
  let minutes = Math.floor(cur / 60) % 60;
  cur -= minutes * 60;
  let seconds = Math.floor(cur) % 60;

  let time =
    days +
    ' ' +
    language.DAYS +
    ' ' +
    hours +
    ' ' +
    language.HOURS +
    ' ' +
    minutes +
    ' ' +
    language.MINUTES +
    ' ';
  return time;
};

export default function ({
  handleYear,
  selectedYear,
  language,
  MonitoresAva,
  ioServers,
  CardsData = {},
  UnavailabilityData = {},
  id_device,
  UnavailabilityDataYear = {},
}) {
  let [uptime, setUptime] = useState(0);
  let [UnavailabilityData_, setUnavailabilityData_] = useState(0);
  let [p, setP] = useState(0);
  useEffect(() => {
    ioServers.on(`_${tenant}_${id_device}`, (data) => {
      if (data.key == 'process') {
        let uptimeTemp = data.values.uptime;

        let uptimes = (uptimeTemp + '').toHHMMSS(language);
        setUptime(uptimes);
      }
    });
    setUnavailabilityData_(UnavailabilityData);
    try {
      setP(MonitoresAva[MonitoresAva.length - 1].percent);
    } catch (e) {
      setP(0);
    }
  }, [UnavailabilityData, id_device, MonitoresAva]);

  return (
    <Card className="h-100 w-100">
      <AppBar title={language.AVAILABILITY} />
      <div className="content pt-0">
        <Header
          selectedYear={selectedYear}
          handleValue={(field, value) => handleYear(value)}
          language={language}
        />
        {/*-----------------------------------------Cards-----------------------------------------------------------*/}
        <Grid container spacing={2} className="mt-3">
          <Grid
            item
            xs={4}
            style={{ '--startColor': '#92D050', '--endColor': '#33ACA0' }}
          >
            <Indicator
              title={language.OPERATING_TIME}
              value={uptime}
              language={language}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ '--startColor': '#01C2B3', '--endColor': '#019FC2' }}
          >
            <Indicator
              title={language.ACTUAL_AVAILABILITY}
              value={parseInt(p)}
              percent
              language={language}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ '--startColor': '#F05E02', '--endColor': '#F0B002' }}
          >
            <Indicator
              title={language.PLANNED_AVAILABILITY}
              value={0}
              percent
              language={language}
            />
          </Grid>
        </Grid>

        {/*-----------------------------------------Graphs-----------------------------------------------------------*/}
        <div className="mt-3 w-100">
          <ReactHighcharts
            config={Unavailability({
              ...UnavailabilityData_,
              language: language,
            })}
          />
          <hr className="my-0" style={{ borderColor: 'var(--primary)' }} />
        </div>
        {/*-----------------------------------------legends-----------------------------------------------------------*/}

        <div className="content-legend">
          {[
            { label: language.REAL, color: 'var(--primary)' },
            { label: language.PLANNED, color: 'var(--warning)' },
          ].map((e) => (
            <div key={e.label} className="legend-item mr-3">
              <div className="mr-1" style={{ backgroundColor: e.color }}></div>
              <span>{e.label}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}
