import React, {memo} from 'react';
import Store from '@data/Store';
import {observer} from 'mobx-react-lite';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import withLanguage from '@common/languages/withLanguage';
// ------------------------material-ui------------------------------------
import {Card, Grid} from '@material-ui/core';

let Indicator = observer(({label, value})=>{
   let {type} = Store.theme;
   return (
      <div className={'content-indicator '+type}>
         <span>{label}</span>
         <span>{value.toString().padStart(2,0)}</span>
      </div>
   )
})
export default memo(withLanguage(({page, data=[0,0,0], language})=>{
   
   return(
      <Card id='Expenses'>
         <AppBar title={language.EXPIRED} subTitle={`(${language.MY_TICKETS})`} />
         <div className='content-medium pt-0'>
            <Indicator label={language.EXPIRE} value={data[0]}/>
            <Indicator label={language.EXPIRE_THIS_WEEK} value={data[1]}/>
            <Indicator label={language.EXPIRE_TODAY} value={data[2]}/>
         </div>
      </Card>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))