
export function GraphArea({ language, data, color = '#629DAA', name = '', height }) {
	var categories = [1, 2, 3, 4, 5, 6, 7];
	return {
		chart: {
			type: 'area',
			height: height || 120,
			margin: 0,
			animation: false
		},

		title: null,
		subtitle: null,
		xAxis: {
			allowDecimals: false,
			labels: {
				enabled: false,
				formatter: function () {
					return categories[this.value] + 's';
				},
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},

		},
		yAxis: {

			title: null,
			labels: {

				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			startOnTick: false,
			endOnTick: false,
			tickPositions: [],

		},

		tooltip: {
			pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			area: {
				fillOpacity: 0.4,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			},
			series: {
				animation: false
			}
		},
		legend: {
			enabled: false
		},
		credits: { enabled: false },
		series: [{
			name: name,
			color: {
				linearGradient: {
					x1: 0,
					x2: 0,
					y1: 0,
					y2: 1
				},
				stops: [
					[0, color],
					[1, 'white']
				]
			},
			data,
		}]
	}
}
export function DinamicGraphArea(dd) {
	var categories = [1, 2, 3, 4, 5, 6, 7];
	return {
		chart: {
			type: 'area',
			height: 120,
			animation: false

		},

		title: null,
		subtitle: null,
		xAxis: {
			allowDecimals: false,
			gridLineWidth: 1,
			labels: {
				enabled: false,
				formatter: function () {
					return categories[this.value] + 's';
				},
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},

		},
		yAxis: {
			gridLineWidth: 1,
			title: null,
			labels: {
				enabled: true,
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},


		},

		tooltip: {
			pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			area: {
				fillOpacity: 0.4,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			},
			series: {
				animation: false
			}
		},
		legend: {
			enabled: false
		},
		credits: { enabled: false },
		series: dd
	}
}
