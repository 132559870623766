import React, { Component } from 'react';
import { Card, Paper } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
const styles = theme => ({
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '1200px'
  }, dialogPaperAlert: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '1200px'
  },
  dialogPaper_: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '2000px'
  }
});

class StockFormOut extends Component {
  constructor() {
    super();
    this.state = {
      typeout: '',
      errorSt: '',
      btn: '',
      devices: []
    };

    this.onChangeField = this.onChangeField.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  componentWillMount() {
    this.props.action
      .execute('get', this.props.APIEndpoints.DEVICE, 'devices')
      .then(res => {
      });
    this.getUsers(this.props.valueOut.id_device);
  }

  onChangeField(field, value) {
    let { valueOut } = this.props;

    if (field.name === 'reason') {
      this.setState({
        typeout: value
      });
    }

    if (field.name === 'qtdInput') {
      if (parseFloat(value) > parseFloat(valueOut.qtdinput)) {
        this.setState({
          errorSt: 'error',
          btn: 'error'
        });
      } else {
        this.setState({
          errorSt: '',
          btn: ''
        });
      }
    }
  }

  onClose() {
    this.setState({
      errorSt: ''
    });
  }

  getUsers = (id) => {
    this.props.action
      .execute('get', `${this.props.APIEndpoints.DEVICE + '/' + id}`, '')
      .then(res => {
        this.setState({ devices: res.data[0] })
      });
  }

  getUserName = (id) => {

    let user = this.props.store.users.filter(e => e.id_user == id)[0]
    if (user) {
      return user.name
    } else {
      return ''
    }
  }
  render() {
    let { language, classes, handlePage, onFormSubmitOut, valueOut,id_company } = this.props;
    let { typeout, errorSt, btn } = this.state;
    let devices = this.props.store.devices.map(u => ({
      value: u.id_device,
      label: u.name
    }));

    let reasons = [
      { value: 'loan', label: language.LOAN },
      { value: 'replacement', label: language.SUBSTITUTION },
      { value: 'discard', label: language.DISCARD }
    ];

    let loan = true;
    let loanReq = false;

        // let listUsers = this.props.store.users
        //   .filter(user => user.id_company == valueOut.id_company)
        //   .map(u => ({ value: u.id_user, label: u.name }));

    let listUsers = []

    // if (this.state.devices.user_cad != undefined) {
    //   this.state.devices.user_cad.map((e) => {
    //     if (this.getUserName(e).length > 0) {
    //       listUsers.push({ value: e, label: this.getUserName(e) })
    //     }
    //   })
    // }

    if (typeout === 'loan') {
      loan = false;
      loanReq = true;
    }

    valueOut.id_user = this.props.id_user;



    let fields = [
      {
        col: 4,
        name: 'id_device',
        label: language.NAME,
        type: 'select',
        options: devices,
        required: true,
        disabled: !!valueOut.id_device
      },
      {
        col: 4,
        // name: 'id_user',
        // label: language.REQUESTER,
        // type: 'select',
        // options: listUsers,
        // required: true
        type: 'autocomplete',
        name: 'id_user',
        label: language.REQUESTER,
        textlabel: language.REQUESTER,
        className: 'tech-group-ticket',
        required: true,
        method: "POST",
        route: `${this.props.APIEndpoints.USER}/filter`,
        routeGetCount: `${this.props.APIEndpoints.USER}/active/count/all`,
        filterAll:{fk_id_company : id_company,  deleted : false, status : true},
        fieldquery: 'search',
        textinit:   ' ',
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit:'',
        routeAll: `${this.props.APIEndpoints.USER}/filter/all?`,
        detail: true,
        methodGetAll : 'POST'
        // isMulti: true
      },
      {
        col: 4,
        // name: 'sponso',
        // label: language.STOCK_APPROVER,
        // type: 'select',
        // options: listUsers,
        // required: true
        type: 'autocomplete',
        name: 'sponso',
        label: language.STOCK_APPROVER,
        textlabel: language.STOCK_APPROVER,
        className: 'tech-group-ticket',
        required: true,
        method: "POST",
        route: `${this.props.APIEndpoints.USER}/filter`,
        routeGetCount: `${this.props.APIEndpoints.USER}/active/count/all`,
        filterAll:{fk_id_company : id_company,  deleted : false, status : true},
        fieldquery: 'search',
        textinit:   ' ',
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit:'',
        routeAll: `${this.props.APIEndpoints.USER}/filter/all?`,
        detail: true,
        methodGetAll : 'POST'
      },
      {
        col: 2,
        name: 'qtdInput',
        label: language.QUANTITY,
        type: 'number',
        inputProps: {
          min: '1',
          max: (valueOut || {}).qtdinput
        },
        error: errorSt === 'error',
        help: errorSt === 'error' ? language.STOCK_STOCK_ERROR : undefined,
        required: true
      },
      {
        col: 4,
        name: 'reason',
        label: language.REASON,
        type: 'select',
        options: reasons,
        required: true
      },

      {
        col: 3,
        name: 'dt_cad_empout',
        label: language.DEPARTURE_DATE,
        type: 'date',
        required: true,
        //disabled: loan
      },

      typeout == 'loan' && {
        col: 3,
        name: 'dt_cad_empin',
        label: language.ENTRY_DATE,
        type: 'date',
        required: true,
        //disabled: loan
      },
      {
        col: 12,
        name: 'description',
        label: language.COMPL_INFO,
        type: 'textEditor',
        required: true
      },

    ].filter(Boolean);

    let button = {

      label: language.SAVE,

      disabled: false,

    };
    let buttons = {

      label: language.SAVE,

      disabled: true,

    };

    return (
      <Dialog

        open={true}
        onClose={() => (errorSt === 'error' ? this.onClose('') : handlePage('list', ''))}
        title={language.OUTPUT}
        maxWidth="md"
      >
        <CommonForm
          onChangeField={this.onChangeField}
          values={valueOut}
          fields={fields}
          onSubmit={onFormSubmitOut}
          button={btn === 'error' ? buttons : button}
          loading='request-stock'
        />

      </Dialog>
    );
  }
}
StockFormOut.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(StockFormOut);
