import React, { useState, useEffect } from 'react';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import TicketManagement from './index';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';
import Store from '@data/Store';

const { language } = Store;

const action = new Actions();

const IntegrationDetail = (props) => {
  let tokenApi = props.store.getToken();
  const [openDialog, setOpenDialog] = useState(false);
  let [modal, setModal] = useState(false);
  let [dados, setDados] = useState([]);
  const [openTicketDialog, setOpenTicketDialog] = useState(false);
  const [ticket, setTicket] = useState([]);

  const searchdetail = (text) => {
    action
      .execute('get', `${props.APIEndpoints.TICKET}/integration/${text}`, '')
      .then((res) => {
        if (res.data.length == 0) {
          props.store.toggleAlert(true, language.NO_REGISTERED_USER, 'error');
          setDados([]);
        } else {
          var output = Object.entries(res.data).map(([key, value]) => ({
            key,
            value,
          }));
          setDados(output);
        }
      });
  };

  const handleOpenTicket = () => {
    setOpenTicketDialog(true);
    const id_tickets = dados.find((d) => d.key == 'id_tickets').value;
    const real_id = dados.find((d) => d.key == 'real_id').value;
    setTicket({ id_tickets: id_tickets, real_id: real_id });
  };

  useEffect(() => {
    setDados([]);
  }, [modal]);

  return modal == false ? (
    <Button
      onClick={() => setModal(true)}
      label={language.CONSULT_INTEGRATION_CALL}
      className="btn-item"
      color="success"
      variant="normal"
      style={{ marginTop: '1rem', marginRight: '1rem' }}
    />
  ) : (
    <div>
      <Dialog
        style={{ height: '250px' }}
        title={language.CONSULT_INTEGRATION_CALL}
        open={modal}
        onClose={() => setModal(false)}
        maxWidth="lg"
      >
        <div style={{ width: '40%', margin: 'auto' }}>
          <CommonForm
            individual
            hiddenSubmit
            button={{
              md: 2,
              offset: { md: 10 },
              label: language.SEARCH,
              primary: true,
              style: { margin: 10 },
            }}
            onSubmit={(values) => {
              searchdetail(values.search);
            }}
            fields={[
              {
                span: 3,
                type: 'text',
                name: 'search',
                label: language.SEARCH,
                className: 'subject-ticket',
                required: true,
              },
              {
                span: 1,
                type: 'element',
                content: (v, h, submit) => (
                  <Button
                    color="success"
                    label={language.SEARCH}
                    onClick={() => submit()}
                  />
                ),
              },
            ]}
          />
        </div>
        <br></br>
        {openTicketDialog && (
          <Dialog
            title={'TICKET'}
            open={true}
            maxWidth="xl"
            onClose={() => {
              setOpenTicketDialog(false);
            }}
          >
            <TicketManagement
              detail={true}
              id_ticket_detail={ticket}
              isReadOnly
            ></TicketManagement>
          </Dialog>
        )}
        <div></div>
        {dados.length > 0 ? (
          <div>
            {dados.length > 0 && !!dados.find(d => d.key =='id_tickets') && (
              <Button
                onClick={() => handleOpenTicket()}
                label={language.VIEW_FULL_TICKET}
                className="btn-item"
                color="success"
                variant="normal"
                style={{ marginTop: '1rem', marginRight: '1rem', marginBottom: '1rem' }}
              />
            )}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                border: 'solid #ccc',
                borderWidth: '1px',
                paddingLeft: '10px',
              }}
            >
              {dados
                .filter((h) => typeof h.value == 'string')
                .map((e, i) => (
                  <div style={{ width: '50%' }}>
                    <span className="label-item">{e.key}</span>
                    <span style={{ marginLeft: '10px' }} className="value-item">
                      {e.value}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </Dialog>
    </div>
  );
};

export default IntegrationDetail;
