import React, { Component } from 'react';
import { Card, Paper } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Help from '../../common/components/Help';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';
const styles = theme => ({
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '1200px'
  }, dialogPaperAlert: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '1200px'
  },
  dialogPaper_: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '2000px'
  }
});

class StockForm extends Component {
  render() {
    let { language, handlePage, onFormSubmitIn, valueIn, classes } = this.props;

    let devices = this.props.store.devices.map(u => ({
      value: u.id_device,
      label: u.name
    })).filter(e => {
      if (!!valueIn) {
        return true;
      } else {

        return !this.props.store.stockins.map(e => e.id_device).includes(e.value);
      }
    });

    //(idmail, mail, password, pop, smtp, host, port, tls, host_value, port_value, tls_value, deleted)

    let fields = [
      {
        col: 12,
        name: 'id_device',
        label: language.DEVICE,
        type: 'select',
        options: devices,
        required: true,
        disabled: !!(valueIn && valueIn.id_device)
      },
      {
        col: 12,
        name: 'qtdInput',
        label: language.QUANTITY,
        type: 'number',
        min: '1',
        required: true,
      },
      {
        col: 12,
        name: 'Additional_Information',
        label: language.COMPL_INFO,
        type: 'textEditor',
        required: true
      }
    ];


    return (
      <Dialog modal={false}
        onClose={() => handlePage('list', '')}
        title={valueIn ? language.INPUT : language.NEW}
        maxWidth='xs'

        open={true}>


        <CommonForm
          values={valueIn}
          language={language}
          fields={fields}
          loading='request-stock'
          onSubmit={onFormSubmitIn}
        />


      </Dialog>
    );
  }
}
StockForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(StockForm);
