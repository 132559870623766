import React, { Component, Fragment, useEffect, useState } from 'react';

import { Checkbox } from '@material-ui/core';
import Header from '@common/Header';
import Button from '@common/Button';
import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import './index.css';
import Store from '@data/Store';
const action = new Actions();
const { language_type } = Store;

const index = (props) => {
  const { language } = props;
  const [data, setData] = useState([]);
  const [tagId, setTagId] = useState(null);
  const [updateInfos, setUpdateInfos] = useState(true);

  const loadEvents = () => {
    action
      .execute(
        'get',
        `${Constants.APIEndpoints.NOTIFICATION}/events`,
        ''
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        let messageError = language.ERROR_REQUEST;
        Store.toggleAlert(true, messageError, 'error');
      });
  };

  const loadTagEvents = (id) => {
    action
      .execute(
        'get',
        `${Constants.APIEndpoints.NOTIFICATION}/tag/events/${id}`,
        null,
        null,
        null,
        null,
        null,
        true
      )
      .then((res) => {
        let eventsUpdated = data.map((obj) => {
          return {
            check: (
              <Checkbox
                checked={res.data.data.some(rel => rel.event_id === obj.id)}
                onChange={() => updateTagRel(obj, (res.data.data.some(rel => rel.event_id === obj.id)))}
              />
            ),
            name: obj.name,
            description: language_type === 'EN' ? obj.en_description : language_type === 'ES' ? obj.es_description : obj.description
          };
        });

        setData(eventsUpdated);

        Store.spinner = false;
      })
      .catch((error) => {
        console.log(error);
        let messageError = language.ERROR_REQUEST;
        Store.spinner = false;
        Store.toggleAlert(true, messageError, 'error');
      });
  };

  useEffect(() => {
    if (props.value?.id) {
      Store.spinner = true;
      setTagId(props.value.id);
      loadEvents();
    }
  }, [props.value]);

  useEffect(() => {
    if (tagId !== null && data.length > 0 && updateInfos) {
      setUpdateInfos(false);
      loadTagEvents(tagId);
    }
  }, [tagId, data, updateInfos]);

  let col = [
    { key: 'check', label: '', style: { width: 10 } },
    { key: 'name', label: language.NAME },
    { key: 'description', label: language.DESCRIPTION },
  ];

  const updateTagRel = (val, checkStatus) => {
    let payload = {
      event_id: val.id,
      tag_id: tagId
    }

    Store.spinner = true;
    if( !checkStatus ) {
      action
        .execute(
          'post',`${Constants.APIEndpoints.NOTIFICATION}/module/event`,
          null,
          payload,
          null,
          null,
          null,
          true
        ).then(
        (res) => {
          Store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
          loadTagEvents(tagId);
        },
        (error) => {
          console.log(error);
          let messageError = language.ERROR_REQUEST;
          Store.spinner = false;
          Store.toggleAlert(true, messageError, 'error');
        });
    } else {
      action
        .execute(
          'delete',
          `${Constants.APIEndpoints.NOTIFICATION}/module/event/${tagId}/${val.id}`,
          null,
          null,
          null,
          null,
          null,
          true
        ).then(
        (res) => {
          loadTagEvents(tagId);
          Store.toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
        },
        (error) => {
          console.log(error);
          let messageError = language.ERROR_REQUEST;
          Store.spinner = false;
          Store.toggleAlert(true, messageError, 'error');
        });
    }
  };

  return (
    <div>
      <CommonTable
        title={''}
        language={language}
        columns={col}
        data={data}
        hideFooterToolbar
        paginationTop={false}
        searchColumn={true}
        countTotal={0}
        isAutomaticPagination={false}
        beginWithNoFilter={true}
      />
    </div>
  );
};

export default index;
