import React, {memo, Fragment, useState} from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import Button from '@common/Button';
// ------------------------material-ui------------------------------------
import {Card, Grid} from '@material-ui/core';

import {radial} from './Graphs';
import ActivitiesList from '../Dialog/ActivitiesList';
import ListDetail_1 from '../Dialog/ListDetail_1';
import RisksList from '../Dialog/RisksList';
import withLanguage from '../../../../common/languages/withLanguage';

const Graph = ({title, subTitle, total, data})=>(
   <div>
      <ReactHighcharts config={radial({
         height:150,
         width: 100,
         name:title,
         minPointSize:10,
         titlePosition:25,
         data:data,
         title:{content:total, style:'color:var(--success)'},
         subTitle:{content:subTitle},
      })}/>
      <span className='_title'>{title}</span>
   </div>
)
/* 
----------------------------format----------------------------
let data = {
      demands:[
         {name:'Solicitação', y:45, color:'var(--primary)'},
         {name:'Incidente', y:45, color:'var(--warning)'},
         {name:'Problema', y:45, color:'var(--danger)'},
      ],
      projects:[
         {name:'Backlog', y:25, color:'var(--default)'},
         {name:'A Fazer', y:25, color:'var(--warning)'},
         {name:'Fazendo', y:25, color:'var(--primary)'},
         {name:'Impeditivo', y:25, color:'var(--danger)'},
         {name:'Validação', y:25, color:'var(--purple)'},
         {name:'Finalizado', y:25, color:'var(--success)'},
      ],
      risks:[
         {name:'Aberto', y:45, color:'var(--primary)'},
         {name:'Fechado', y:45, color:'var(--success)'},
      ]
   }

*/
export default memo(withLanguage(({page, user, language, demands=[], projects=[], risks=[], activitiesListData, demandListData, risksListData, redirect, statusAwaiting, totalTicketsInService})=>{
   let [open, setOpen] = useState(0);
   const totalTicket = parseInt(totalTicketsInService.totalAwaiting) + parseInt(totalTicketsInService.totalInProgress) + parseInt(totalTicketsInService.totalOpen)
   const dataGraphDemands = [
      { name: language.TICKET_STATUS.OPEN, y : totalTicketsInService.totalOpen, color: 'var(--primary)'},
      { name: language.TICKET_STATUS.IN_PROGRESS, y : totalTicketsInService.totalInProgress, color: 'var(--warning)'},
      { name: language.TICKET_STATUS.WAITING, y : totalTicketsInService.totalAwaiting, color: 'var(--danger)'},
]
activitiesListData = activitiesListData.filter(a => ['Backlog', 'A fazer', 'Fazendo', 'Validação'].includes(a.status) || a.status == undefined)
return(
      <Fragment>
         <ActivitiesList 
            open={open==2}
            onClose={() => setOpen(0)}
            data={activitiesListData}
            redirect={redirect}
         />
         <ListDetail_1 
            open={open==1}
            onClose={() => setOpen(0)}
            data={demandListData}
            statusAwaiting={statusAwaiting}
            totalTicketsInService = {totalTicketsInService}
            type = 'CallsYou'
         />
         <RisksList
            open={open==3}
            onClose={() => setOpen(0)}
            data={risksListData}
         />
         <Card id='CallsYou'>
            <AppBar title={language.ITEMS_IN_SERVICE_FOR_YOU} />
            <div className='content-medium pt-0'>
               <Grid container spacing={0}>
                  <Grid item xs={4} className='grid'>
                   <div>
                     <Graph
                        title={language.DEMANDS}
                        subTitle='Total'
                        total={totalTicket}
                        data={dataGraphDemands}
                     />
                     <div className='d-flex justify-content-center'>
                        <Button
                           icon='fas fa-eye'
                           size={.8}
                           className='mt-3'
                           variant='normal'
                           label={language.VISUALIZE}
                           onClick={() => setOpen(1)}
                        />
                        </div>
                     </div>
                  </Grid>
                  <Grid item xs={4} className='grid'>
                     <div>
                     <Graph
                        title={language.PROJECTS}
                        subTitle='Total'
                        total={projects.reduce((n, e) => n+e.y, 0)}
                        data={projects}
                     />
                     <div className='d-flex justify-content-center'>
                        <Button
                           icon='fas fa-eye'
                           size={.8}
                           className='mt-3'
                           variant='normal'
                           label={language.VISUALIZE}
                           onClick={() => setOpen(2)}
                        />
                        </div>
                     </div>
                  </Grid>
                  <Grid item xs={4} className='grid'>
                     <div>
                     <Graph
                        title={language.RISKSS}
                        subTitle='Total'
                        total={risks.reduce((n, e) => n+e.y, 0)}
                        data={risks}
                     />
                     <div className='d-flex justify-content-center'>
                        <Button
                           icon='fas fa-eye'
                           size={.8}
                           className='mt-3'
                           variant='normal'
                           label={language.VISUALIZE}
                           onClick={() => setOpen(3)}
                        />
                     </div>
                     </div>
                  </Grid>
               </Grid>
            </div>
         </Card>
      </Fragment>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))