import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import FaClose from 'react-icons/lib/fa/close';
import { Card, Paper } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';

import Dialog from '../../common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm';

@observer
export default class AssessmentList extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      id_contest: '',
      description: '',
    };
    this.onContestSubmit = this.onContestSubmit.bind(this);
  }

  onContestSubmit(values) {
    values.id_contest = this.state.id_contest;
    this.setState({ modalOpen: false });
    this.props.onFormSubmit(values);
  }

  render() {
    let { language, handlePage, store, onFormSubmit } = this.props;
    let { modalOpen, description } = this.state;

    let col = [
      { key: 'tech', label: language.TECH },
      { key: 'device', label: language.DEVICE },
      { key: 'start', label: language.INITIAL_DATE, style: { width: 120 } },
      { key: 'end', label: language.END_DATE, style: { width: 120 } },
      { key: 'time', label: language.TIME, style: { width: 70 } },
      { key: 'sla', label: language.SLA, style: { width: 70 } },
      { key: 'actions', label: language.ACTION, style: { width: 100 } },
    ];

    let data = store.assessments.map((obj) => ({
      tech: obj.tech.name,
      device: obj.device.name,
      start: moment(obj.start_date).format('L LTS'),
      end: obj.end_date ? moment(obj.end_date).format('L LTS') : null,
      time: obj.date,
      sla: obj.sla.status == -1 ? language.DELAYED : language.IN_TIME,
      actions:
        obj.contest.state == 'open' ? (
          <RaisedButton
            primary={true}
            label={language.JUSTIFY}
            onClick={() =>
              this.setState({
                modalOpen: true,
                id_contest: obj.contest.id_contest,
                description: obj.contest.description,
              })
            }
          />
        ) : obj.contest.state == 'justified' ? (
          language.WAITING
        ) : obj.contest.state === 'refused' ? (
          language.REFUSED
        ) : obj.contest.state === 'accept' ? (
          language.ACCEPTED
        ) : null,
    }));

    let fields = [
      {
        span: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
      },
    ];
    let button = {
      md: 12,
      label: language.SAVE,
      primary: true,
      style: { margin: '20px 0' },
    };

    return (
      <Container fluid={true}>
        <Row>
          <CommonTable
            title={language.ASSESSMENTS}
            data={data}
            columns={col}
            language={language}
          />
        </Row>

        <Dialog
          open={modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          title={language.JUSTIFY}
          maxWidth="lg"
        >
          <div className="container" style={{ padding: '20px 0' }}>
            <Card className="modalCard">
              <Paper zdepth={0}>
                <Row>
                  <Col md={10} offset={{ md: 1 }}>
                    {language.DESCRIPTION}: {description}
                  </Col>
                </Row>
                <CommonForm
                  language={language}
                  fields={fields}
                  onFormSubmit={this.onContestSubmit}
                  button={button}
                />
              </Paper>
            </Card>
          </div>
        </Dialog>
      </Container>
    );
  }
}
