import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import PageLayout from '@common/PageLayout';
import './ProjectManagementPrioritization.css';
import Typography from '@material-ui/core/Typography';
import ReactHighcharts from 'react-highcharts';
import { Paper } from '@material-ui/core';
import AppBar from '@common/AppBar';
import Graph, { callback } from './graph.js';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Store from '@data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';

import { InputBase, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Icon from '@material-ui/core/Icon';

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});
const styles = {
  root: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginBottom:10
  },
  paper: {
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
};
@observer
export default class ProjectManagementPrioritization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }
  handlerSearch = (evt) => {
    this.setState({ filter: evt.target.value });
  };
  clearSearch = () => {
    this.setState({ filter: '' });
  };
  render() {
    let { language, redirect, projects, prioritization } = this.props;
    let { filter } = this.state;
    const CALC = (id) => {
      let total = 0;
      try {
        total = parseInt(
          prioritization.filter((f) => f.id_scale == id.desire_directors)[0]
            .desire_directors
        );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter(
              (f) => f.id_scale == id.strategic_alignment
            )[0].strategic_alignment
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.benefit_amount)[0]
              .benefit_amount
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter(
              (f) => f.id_scale == id.processes_improvement
            )[0].processes_improvement
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.financial_feedback)[0]
              .financial_feedback
          );
      } catch (e) {}

      return total;
    };
    const CALC1 = (id) => {
      let total = 0;
      try {
        total = parseInt(
          prioritization.filter((f) => f.id_scale == id.generates_costs)[0]
            .generates_costs
        );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.risk_if_not_done)[0]
              .risk_if_not_done
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.complexity)[0]
              .complexity
          );
      } catch (e) {}
      try {
        total =
          total +
          parseInt(
            prioritization.filter((f) => f.id_scale == id.involved_areas)[0]
              .involved_areas
          );
      } catch (e) {}

      return total;
    };
    let data = projects.map((y) => {
      return {
        nome: y.name,
        x: CALC(y) / 5,
        y: CALC1(y) / 5,
        z: 10,
        name: y.id_project,
        country: translateFromPtBR(y.name, Store.language),
      };
    });

    return (
      <PageLayout
        icon={
          <div
            className="projectManIcon"
            style={{ width: '40px', height: '40px', margin: 0, left: 6 }}
          />
        }
        title={language.PROJECT_PRIORITIZATION_MAP}
        subTitle={language.PROJECT_MANAGEMENT + '/'}
        rightElements={[]}
      >
        <MuiThemeProvider theme={theme}>
          <div id="ProjectManagementPrioritization" className="pt-4">
            <Paper>
              <AppBar>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <RaisedButton
                      circleButton
                      label={language.POPULATE_MAP}
                      icon="fas fa-plus"
                      onClick={() =>
                        this.props.redirect('/dash/gp/prioritization/list')
                      }
                    />
                    <Typography
                      variant="h6"
                      className="font"
                      style={{
                        color: 'white',
                        paddingTop: 0,
                        fontWeight: 100,
                        marginLeft: 10,
                      }}
                    >
                      {language.MAP}
                    </Typography>
                  </div>
                  <div style={styles.root}>
                    <Paper style={styles.paper} elevation={1}>
                      <IconButton
                        size="small"
                        style={styles.iconButton}
                        aria-label="Menu"
                      >
                        <SearchIcon />
                      </IconButton>

                      <InputBase
                        style={styles.input}
                        placeholder={language.SEARCH}
                        value={filter}
                        onChange={this.handlerSearch}
                      />

                      {!!filter && (
                        <Fragment>
                          <Divider style={styles.divider} />
                          <IconButton
                            color="primary"
                            onClick={this.clearSearch}
                            style={styles.iconButton}
                            aria-label="Directions"
                          >
                            <Icon
                              color="primary"
                              style={{ transform: 'rotate(-45deg)' }}
                            >
                              add_circle
                            </Icon>
                          </IconButton>
                        </Fragment>
                      )}
                    </Paper>
                  </div>
                </div>
              </AppBar>
              <div className="content">
                <ReactHighcharts
                  config={Graph({ language }, data)}
                  callback={callback}
                />
              </div>
            </Paper>
          </div>
        </MuiThemeProvider>
      </PageLayout>
    );
  }
}
