import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import ThemeSacForm from './ThemeSacForm';
import CommonDelete from '../../../common/CommonDelete';
import CommonTable from '../../../common/CommonTable';
import Constants from '../../../data/Constants';
import CommonPage from '../../../common/CommonPage';
import PageLayout from '../../../common/PageLayout';
import Store from '@data/Store';
import Actions from '@data/Actions';

const action = new Actions();

const SacThemeList = () => {
  const [form, setForm] = useState(false);
  const [page, setPage] = useState('list');
  const [themes, setThemes] = useState([]);
  const [countThemes, setCountThemes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [themeSelected, setThemeSelected] = useState([]);

  const callPageSelectFilter = (page = 0, values = {}, limit = 75) => {
    setCurrentPage(page);

    action
      .execute('post', `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?limit=${limit}&offset=${page * limit}`, '', values)
      .then((data) => {
        setThemes(data.data);
        setCountThemes(data.data[0] ? data.data[0].total_count : 0);
      });
  };

  const handlePage = (page, obj) => {
    setPage(page);

    if (page === 'edit') {
      setForm(true);
      setThemeSelected(obj);
    }
    if (page === 'remove') {
      setThemeSelected(obj);
    }
  };

  const deleteTheme = (id_theme) => {
    const values = { id_theme, user: Store.getUserLoged() };

    action
      .execute('put', `${Constants.APIEndpoints.SAC_THEMES}/deletethemes`, '', values)
      .then((data) => {
        callPageSelectFilter();
        setPage('list');
        setThemeSelected([]);
      });
  };

  const editTheme = (id_theme) => {
    const values = { id_theme, user: Store.getUserLoged() };

    action
      .execute('put', `${Constants.APIEndpoints.SAC_THEMES}/updatetheme`, '', values)
      .then((res) => {
        setForm(false);
      });
  };

  useEffect(() => {
    callPageSelectFilter();
  }, []);

  const changeStatus = (obj) => {
    const values = {
      id_theme: obj.id_theme,
      status: obj.status,
      user: Store.getUserLoged(),
    };

    action
      .execute('put', `${Constants.APIEndpoints.SAC_THEMES}/updatethemestatus`, '', values)
      .then((data) => {
        callPageSelectFilter();
        setPage('list');
      });
  };

  const data = themes.map((obj) => ({
    name: obj.name,
    name_group_theme: obj.name_group_theme,
    actions: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
                icon="fas fa-edit"
                label={Store.language.EDIT}
                color="default"
                style={{ fontSize: 12 }}
                size={0.7}
                className="mr-2"
                onClick={() => handlePage('edit', obj)}
                onClose={() => setForm(true)}
              />

              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={Store.language.DELETE}
                color="danger"
                style={{ fontSize: 13 }}
                size={0.7}
                onClick={() => handlePage('remove', obj)}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  const col = [
    {
      key: 'name',
      label: Store.language.THEME,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'name_group_theme',
      label: Store.language.TECH_GROUP,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'actions',
      label: Store.language.ACTIONS,
      style: { textAlign: 'left' },
      thConfig: false,
    },
  ];

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title="Lista de Temas"
        subTitle={Store.language.CONSUMER_SERVICE_SYSTEM}
      >
        <Dialog
          title="Adicionar Tema"
          open={form}
          onClose={() => {
            setThemeSelected('');
            setForm(false);
          }}
          maxWidth="md"
          miniVersion
        >
          <ThemeSacForm
            APIEndpoints={Constants.APIEndpoints}
            language={Store.language}
            setForm={setForm}
            values_={themeSelected}
            callPageSelectFilter={() => callPageSelectFilter()}
          />
        </Dialog>
        <CommonTable
          title={''}
          language={Store.language}
          columns={col}
          data={data}
          index="SacThemeList"
          showHeaderToolbar={false}
          orderColumn
          countTotal={countThemes}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          paginationTop={false}
          searchColumn={true}
          loadSearch={true}
          currentPage={currentPage}
          searchColumnEvent={(v) => {}}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          beforeBar={
            <Button
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label="Adicionar Tema"
              primary={true}
              onClick={() => {
                setThemeSelected([]);
                setForm(true);
              }}
            />
          }
        />

        {page === 'remove' ? (
          <CommonDelete
            miniVersion
            language={Store.language}
            closeModal={() => setPage('list')}
            onDelete={() => deleteTheme(themeSelected.id_theme)}
            onEdit={() => editTheme(themeSelected.id_theme)}
            message={''}
          />
        ) : null}
      </PageLayout>
    </CommonPage>
  );
};

export default SacThemeList;
