import 'react-grid-layout/css/styles.css';

import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ReactTooltip from 'react-tooltip';
import Constants from '../../data/Constants';
import PageLayout from '../../common/PageLayout';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

import Server from './Server';
import ServerSnmp from './ServerSnmp';
import Link from './Link';
import Desktop from './Desktop';
import CommonPageNotLoad from '../../common/CommonPageNotLoad';
import io from 'socket.io-client';
export default class MonitorPage extends CommonPageNotLoad {
  constructor() {
    super();
    this.state = {
      filterServer: false,
      filterLink: false,
      modalOpen: false,
      modalOptions: {},
      update: 0,
      removeloading: true

    };
    this.onFilter = this.onFilter.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.store.loading = false;
    this.updateState();
  }

  updateState() {
    //this.props.state.page = 'monitor';
    // setTimeout(this.updateState, 5000, this);
  }

  onFilter(filter) {
    this.setState(filter);
  }

  handleModal(opts) {
    this.setState({ modalOpen: !this.state.modalOpen, modalOptions: opts });
  }

  render() {
    let {
      language,
      onLayoutChange,
      devices,
      handlePage,
      showAddButton,
      ioServers
    } = this.props;
    let { filterServer, filterLink, modalOpen, modalOptions } = this.state;

    if (!devices)
      return (
        <h2 style={{ padding: '100px 0' }}>{language.NO_DEVICES_MONITOR}</h2>
      );

    if (filterServer) devices = devices.filter(d => d.type === 'server');
    if (filterLink) devices = devices.filter(d => d.type === 'link');

    let layouts = { lg: [], md: [], xxs: [] };
    let lg = 0,
      md = 0,
      lineMd = 0,
      lineLg = 0,
      lineXxs = 0,
      lineCountMd = 0,
      lineCountLg = 0;
    let qtdColsServer = 2,
      qtdColsLink = 1,
      qtdColsLg = 6,
      qtdColsMd = 4;

    for (let i = 0; i < devices.length; i++) {
      let width = devices[i].type === 'link' ? qtdColsLink : qtdColsServer;

      layouts.lg.push({
        i: devices[i].id_device.toString(),
        x: lg,
        y: lineLg,
        w: width,
        h: 1,
        isResizable: false
      });
      layouts.md.push({
        i: devices[i].id_device.toString(),
        x: md,
        y: lineMd,
        w: width,
        h: 1,
        isResizable: false
      });
      layouts.xxs.push({
        i: devices[i].id_device.toString(),
        x: 0,
        y: lineXxs,
        w: width,
        h: 2,
        isResizable: false
      });

      lg += width;
      md += width;
      lineCountLg += width;
      lineCountMd += width;

      if (lg >= qtdColsLg) lg = 0;
      if (md >= qtdColsMd) md = 0;
      if (lineCountLg >= qtdColsLg) {
        lineLg++;
        lineCountLg = 0;
      }
      if (lineCountMd >= qtdColsMd) {
        lineMd++;
        lineCountMd = 0;
      }
      lineXxs++;
    }

    return (
      <PageLayout
        icon={<div className="monitorIcon titleIconSize" />}
        title={language.MONITOR}
        subtitle={language.MONITORING_DEVICES_AND_LINKS}
        rightElements={[
          <div
            data-tip={language.ALL_DEVICES}
            data-for="monitor_filter"
            className="monitorFilter titleIconSize deviceIcon"
            onClick={() =>
              this.onFilter({ filterServer: false, filterLink: false })
            }
          />,
          <div
            data-tip={language.ONLY_SERVERS}
            data-for="monitor_filter"
            className="monitorFilter titleIconSize serverIcon"
            onClick={() =>
              this.onFilter({ filterServer: true, filterLink: false })
            }
          />,
          <div
            data-tip={language.ONLY_LINKS}
            data-for="monitor_filter"
            className="monitorFilter titleIconSize linkIcon"
            onClick={() =>
              this.onFilter({ filterServer: false, filterLink: true })
            }
          />,
          <RaisedButton
            label={language.BACK}
            primary
            onClick={() => handlePage('dash')}
          />
        ]}
      >
        <Card className="cardDefault">
          <ReactTooltip id="monitor_filter" />
          <ResponsiveReactGridLayout
            className="layout"
            layouts={layouts}
            breakpoints={{ lg: 1680, md: 1024, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: qtdColsLg, md: qtdColsMd, sm: 2, xs: 2, xxs: 1 }}
            onLayoutChange={onLayoutChange}
            style={{ background: '#FFF' }}
          >  {devices.map(d => (
            <div key={d.id_device} className="host">

              {

                d.type.toLowerCase() === 'server' ? (

                  <Server
                    io={ioServers}
                    _device={d}
                    handlePage={handlePage}
                    action={this.props.action}
                    APIEndpoints={this.props.APIEndpoints}
                    {...this.props}
                    handleModal={this.handleModal}
                  />
                ) : d.type.toLowerCase() === 'desktop' ? (
                  <Server
                    io={this.state.ioServers}
                    _device={d}
                    handlePage={handlePage}
                    action={this.props.action}
                    APIEndpoints={this.props.APIEndpoints}
                    {...this.props}
                    handleModal={this.handleModal}
                  />
                ) : (
                  <Link
                    _device={d}
                    {...this.props}
                    ioLinks={this.props.ioLinks}
                    handleModal={this.handleModal}
                  />
                )}
            </div>
          ))}
          </ResponsiveReactGridLayout>
          {modalOptions && Object.keys(modalOptions).length ? (
            <Dialog

              open={modalOpen}
              bodyStyle={{ padding: 0, overflowY: 'none' }}
              onClose={() => this.handleModal}
              title={modalOptions.confirm ? language.CONFIRMATION : 'Sensr Conf'}
            >
              <Card className="modalCard">

                <Paper zdepth={0}>
                  <h2>{modalOptions.msg}</h2>
                  <Row className="row" style={{ padding: '20px 0px' }}>
                    <Col
                      md={modalOptions.confirm ? 5 : 2}
                      offset={{ md: modalOptions.confirm ? 7 : 10 }}
                    >
                      {modalOptions.confirm ? (
                        <RaisedButton
                          primary={true}
                          label={language.IM_SURE}
                          onClick={modalOptions.confirm}
                        />
                      ) : null}
                      <RaisedButton
                        primary={false}
                        label={language.CANCEL}
                        onClick={this.handleModal}
                        style={{ marginLeft: 10 }}
                      />
                    </Col>
                  </Row>
                </Paper>
              </Card>
            </Dialog>
          ) : null}
        </Card>
      </PageLayout>
    );
  }
}
