import React, { useState, useEffect } from 'react';
 
import Progress from '@common/Progress';
 
import { Tooltip } from 'react-tippy';
 
// --------------------------------icons----------------------------------
 

export default function(
  
    project) {
    let sla =project.percent||0 
      let he =project.hour_excedida||"00:00" 
      let h =project.horas_planejadas||"00:00" 
      let  hc =project.hour_consumida||"00:00" 
      
  
 
 

  /**    horasex:calculateTimeInSeconds(totalex),
    horas:calculateTimeInSeconds(totalsec1),
    horastConsumed:calculateTimeInSeconds(totalsec3), */

return {percentual:sla ,  horas:h,horasex:he, horastcon:hc}
}