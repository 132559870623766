import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
// import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import IconDashboard from '../../../public/images/icon-dashboard.png'
import IconTask from '../../../public/images/projectManIcon.png'
import IconPeople from '../../../public/images/icon-people.png'
import IconEngine from '../../../public/images/icon-engine.png'
import IconClock from '../../../public/images/icon-clock.png'
import IconEdit from '../../../public/images/icon-edit.png'
import IconCheck from '../../../public/images/icon-check.png'
import IconFale from '../../../public/images/sac_fale.png'
import IconClipboard from '../../../public/images/icon-clipboard.png'
import IconSettings from '../../../public/images/icon-settings.png'
import IconCalendar from '../../../public/images/calendar.png'
import IconMonitor from '../../../public/images/monitorIcon.png'

import {
  useStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ImgBox,
  Icon,
  BoxList,
  TextList
} from './styles.js'
import './styles.css'
import { findUserGroup } from '../../components/sac/utils/findUserGroup'
import { getGroupsFromUserLogged } from '../../components/sac/utils/getGroupsFromUserLogged'
import { getGroupDefaultFALE } from '../../components/sac/utils/getGroupDefaultFALE'
import { getTokenSuperset } from '../../components/sac/utils/getTokenSuperset'

function Sidebar ({
  drawerOpen,
  drawerStatus,
  classes,
  perfil,
  user,
  redirect2,
  language,
  redirect,
  open,
  params
}) {
  const [expanded, setExpanded] = React.useState(false)
  const [showFale, setShowFale] = React.useState(false)
  const [tokenSuperset, setTokenSuperset] = React.useState({})

  const fetchDataGroupUserFALE = async () => {
    const groupsUserLogged = await getGroupsFromUserLogged(user.id);
    const groupFale = await getGroupDefaultFALE();
    setShowFale(findUserGroup(groupsUserLogged, groupFale) || user.role === 'client');
  };

  const fetchTokenSuperset = async () => {
    const token = await getTokenSuperset();
    setTokenSuperset(token)
  };

  React.useEffect(() => {
    if (!drawerStatus) {
      setExpanded(false)
    }
    fetchDataGroupUserFALE()
    fetchTokenSuperset()
  }, [drawerStatus])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
    drawerOpen()
  }

  React.useEffect(() => {
    if (open == false) {
      setExpanded(false)
    }
  }, [open])

  const checkRoleAndPerfil = (role, perfil, type) => {
    let check = false
    switch (type) {
      case 'gp':
        if (
          role != 'solicitant' ||
          perfil.gp_panel_pmo ||
          perfil.gp_projects ||
          perfil.sprint ||
          perfil.gp_allocation ||
          perfil.gp_object_priorization
        ) { check = true }
        break

      case 'config':
        if (role !== 'solicitant') check = true
        break
      case 'gcf':
        if (
          (role !== 'solicitant' && role !== 'tech') ||
          perfil.gcf_dasboard ||
          perfil.gcf_contract ||
          perfil.gcf_service_order ||
          perfil.gcf_audit ||
          perfil.gcf_assessment ||
          perfil.gcf_it_budget_control
        ) { check = true }
        break

      case 'gd':
        if (
          (role !== 'solicitant' && role != 'tech') ||
          perfil.gsd_dash_ge ||
          perfil.gsd_dashboard ||
          perfil.gestao_ticket ||
          perfil.gsd_dm ||
          perfil.gsd_categorization ||
          perfil.gsd_change_management ||
          perfil.gsd_stock_management ||
          perfil.gsd_inventory ||
          perfil.gsd_knowledge_base
        ) { check = true }
        break

      case 'gdd':
        if (
          (role != 'solicitant' && role !== 'tech') ||
          perfil.gi_dashboard ||
          perfil.gi_monitor ||
          perfil.gi_agent_download ||
          perfil.gi_capacity_plain ||
          perfil.gi_discovery
        ) { check = true }

        break

      case 'grc':
        if (
          (role !== 'solicitant' && role !== 'tech') ||
          perfil.grc_dashboard ||
          perfil.grc_side_dish ||
          perfil.grc_iso_27001 ||
          perfil.grc_iso_20000 ||
          perfil.grc_iso_37001 ||
          perfil.grc_iso_9001 ||
          perfil.grc_cobit ||
          perfil.grc_backup_management ||
          perfil.grc_it_evolution_management ||
          perfil.grc_lgpd ||
          perfil.grc_risk_matrix
        ) { check = true }
        break

        case 'fale':
          if (
            (role !== 'solicitant' && role !== 'tech') ||
            perfil.fale
          )
          check = true
          break
        case 'task':
          if (
            (role !== 'solicitant' && role !== 'tech') ||
            perfil.task_dashboard ||
            perfil.task_approve ||
            perfil.task_calendar
               )
            check = true;
          break;

        case 'task':
          if (
            (role !== 'solicitant' && role !== 'tech') ||
            perfil.task_dashboard ||
            perfil.task_approve ||
            perfil.task_calendar
               )
            check = true;
          break;


      default:
        break
    }

    return check
  }

  return (
    <div>
      {(user.role != 'tech' && user.role != 'solicitant') ||
      (user.perfil.cio_cio_cockpit ||
        user.perfil.cio_panel ||
        user.perfil.cio_bsc) == true ? (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Tooltip title="CIO COCKPIT">
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconDashboard} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList className="box-list">
                  <TextList>CIO COCKPIT</TextList>
                </BoxList>
              )}
              {/* <ListItemText
              className={classes.itemText}
              primary="CIO COCKPIT"
              disableTypography
            /> */}
            </ExpansionPanelSummary>

            {(user.perfil.cio_cio_cockpit == null
              ? user.role != 'tech' && user.role != 'solicitant'
              : user.perfil.cio_cio_cockpit) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/cio')}>
                <Typography className={classes.subitemText}>
                  CIO COCKPIT
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.cio_panel == null
              ? user.role != 'tech' && user.role != 'solicitant'
              : user.perfil.cio_panel) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/panel')}>
                <Typography className={classes.subitemText}>
                  {language.PANEL_DASH}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.cio_bsc == null
              ? user.role != 'tech' && user.role != 'solicitant'
              : user.perfil.cio_bsc) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/bsc')}>
                <Typography className={classes.subitemText}>BSC</Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {
              <ExpansionPanelDetails onClick={() => redirect2('/dash/okr')}>
                <Typography className={classes.subitemText}>OKR</Typography>
              </ExpansionPanelDetails>
            }
            {(user.perfil.cio_canvas == null
              ? user.role != 'tech' && user.role != 'solicitant'
              : user.perfil.cio_canvas) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/canvas')}>
                <Typography className={classes.subitemText}>
                  IT CANVAS
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
          </ExpansionPanel>
        </ListItem>
          ) : null}

      <Divider />
      {checkRoleAndPerfil(user.role, user.perfil, 'gcf') && (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            elevation={0}
          >
            <ExpansionPanelSummary
              className=""
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Tooltip title={language.DASHBOARD_PAGE.GCF}>
                <ListItemIcon
                  className={classes.itemIcon}
                  style={{ width: 'auto' }}
                >
                  <ImgBox>
                    <Icon src={IconPeople} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList className="box-list">
                  <TextList>{language.DASHBOARD_PAGE.GCF}</TextList>
                </BoxList>
              )}
            </ExpansionPanelSummary>
            {(user.perfil.gcf_dasboard == null
              ? user.role != 'solicitant' && user.role != 'tech'
              : user.perfil.gcf_dasboard) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/gcf')}>
                <Typography className={classes.subitemText}>
                  DASHBOARD
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gcf_contract == null
              ? user.role != 'solicitant' && user.role != 'tech'
              : user.perfil.gcf_contract) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/contracts')}
              >
                <Typography className={classes.subitemText}>
                  {language.CONTRACTS}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gcf_service_order != false) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/serviceorder')}
              >
                <Typography className={classes.subitemText}>
                  {language.SERVICE_ORDER}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gcf_audit == null
              ? user.role != 'solicitant' && user.role != 'tech'
              : user.perfil.gcf_audit) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/audit')}>
                <Typography className={classes.subitemText}>
                  {language.AUDIT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gcf_assessment == null
              ? user.role != 'solicitant'
              : user.perfil.gcf_assessment) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/assessment')}
              >
                <Typography className={classes.subitemText}>
                  {language.ASSESSMENT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gcf_it_budget_control == null
              ? user.role != 'solicitant' && user.role != 'tech'
              : user.perfil.gcf_it_budget_control) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/budgetcontrol2')}
              >
                <Typography className={classes.subitemText}>
                  {language.IT_BUDGET_CONTROL}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
          </ExpansionPanel>
        </ListItem>
      )}

      <Divider />

      {checkRoleAndPerfil(user.role, user.perfil, 'gdd') ? (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Tooltip title={language.AVAILABILITY_MANAGEMENT}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconEngine} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList className="box-list">
                  <TextList>{language.AVAILABILITY_MANAGEMENT}</TextList>
                </BoxList>
              )}
              {/* <ListItemText
              className={classes.itemText}
              primary="GESTÃO DE DISPONIBILIDADE"
              disableTypography
            /> */}
            </ExpansionPanelSummary>
            {((user.perfil.gi_dashboard == null && user.role != 'solicitant') ||
              user.perfil.gi_dashboard) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/gi')}>
                <Typography className={classes.subitemText}>
                  {language.DASHBOARD}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {((user.perfil.gi_monitor == null && user.role != 'solicitant') ||
              user.perfil.gi_monitor) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/monitor')}>
                <Typography className={classes.subitemText}>
                  {language.MONITOR}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {((user.perfil.gi_agent_download == null &&
              user.role != 'solicitant') ||
              user.perfil.gi_agent_download) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/download')}
              >
                <Typography className={classes.subitemText}>
                  DOWNLOAD AGENT
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gi_capacity_plain == null
              ? user.role != 'solicitant'
              : user.perfil.gi_capacity_plain) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/capacityPlan')}
              >
                <Typography className={classes.subitemText}>
                  CAPACITY PLAN
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {/* (user.perfil.gi_discovery == null
              ? user.role != 'solicitant'
              : user.perfil.gi_discovery) == true ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/Discovery')}
              >
                <Typography className={classes.subitemText}>
                  DISCOVERY
                </Typography>
              </ExpansionPanelDetails>
              ) : null */}
          </ExpansionPanel>
        </ListItem>
      ) : null}
      <Divider />
      {checkRoleAndPerfil(user.role, user.perfil, 'gd') && (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Tooltip title={language.DASHBOARD_PAGE.DM}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconClock} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList className="box-list">
                  <TextList>{language.DASHBOARD_PAGE.DM}</TextList>
                </BoxList>
              )}
              {/* <ListItemText
              className={classes.itemText}
              primary="GESTÃO DE DEMANDAS"
              disableTypography
            /> */}
            </ExpansionPanelSummary>

            {(user.perfil.gsd_dash_ge == null
              ? user.role != 'solicitant'
              : user.perfil.gsd_dash_ge) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect('/dash/dashboard')}
              >
                <Typography className={classes.subitemText}>
                  {language.DASHBOARD_G}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gsd_dashboard == null
              ? true
              : user.perfil.gsd_dashboard) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/gsd')}>
                <Typography className={classes.subitemText}>
                  {language.DASHBOARD}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gestao_ticket == null
              ? true
              : user.perfil.gestao_ticket) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/ticketmanagement')}
              >
                <Typography className={classes.subitemText}>
                  {language.TICKET_MANAGEMENT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gsd_categorization == null ||
              user.perfil.gsd_categorization) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/request')}>
                <Typography className={classes.subitemText}>
                  {user.role == 'solicitant'
                    ? language.SOLICITACAO
                    : language.CATEGORIZATION}
                </Typography>
              </ExpansionPanelDetails>
            ) : null}
            {((user.role == 'solicitant' &&
              user.perfil.gsd_categorization == null) ||
              user.perfil.gsd_categorization) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/requestapprove')}
              >
                <Typography className={classes.subitemText}>
                  {language.APPROVE_REQUESTS}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gsd_change_management == null
              ? true
              : user.perfil.gsd_change_management) == true
              ? (
              <ExpansionPanelDetails
                onClick={() =>
                  redirect('/dash/changemanagement', {
                    route: 'problem'
                  })
                }
              >
                <Typography className={classes.subitemText}>
                  {language.CHANGE_MANAGEMENT}
                </Typography>
              </ExpansionPanelDetails>
            ) : null}
               {(user.perfil.gsd_cmdb == null ||
              user.perfil.gsd_cmdb) == true ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/configuration/device')}>
                <Typography className={classes.subitemText}>
                    CMDB
                </Typography>
              </ExpansionPanelDetails>
            ) : null}
            {(user.perfil.gsd_stock_management == null
              ? user.role != 'solicitant'
              : user.perfil.gsd_stock_management) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/stock')}>
                <Typography className={classes.subitemText}>
                  {language.STOCK_MANAGEMENT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gsd_inventory == null
              ? user.role != 'solicitant'
              : user.perfil.gsd_inventory) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/inventory')}
              >
                <Typography className={classes.subitemText}>
                  {language.INVENTORY}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gsd_knowledge_base == null
              ? true
              : user.perfil.gsd_knowledge_base) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/solution')}
              >
                <Typography className={classes.subitemText}>
                  {language.KNOWLEDGE_BASE}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            <ExpansionPanelDetails
              onClick={() => redirect2('/dash/searchTickets')}
            >
              <Typography className={classes.subitemText}>
                {language.CONSULT_HISTORIC}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ListItem>
      )}

      <Divider />
      {checkRoleAndPerfil(user.role, user.perfil, 'task') && (

      <ListItem className={classes.listItem}>
        <ExpansionPanel
          square
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          elevation={0}
        >
          <ExpansionPanelSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Tooltip title={language.TASK_MANAGEMENT}>
              <ListItemIcon style={{ width: 'auto' }}>
                <ImgBox>
                  <Icon src={IconCalendar} alt={language.TASK_MANAGEMENT} />
                </ImgBox>
              </ListItemIcon>
            </Tooltip>
            {open && (
              <BoxList className="box-list">
                <TextList>{language.TASK_MANAGEMENT}</TextList>
              </BoxList>
            )}

            <ListItemText
              className={classes.itemText}
              primary="CIO COCKPIT"
              disableTypography
            />
          </ExpansionPanelSummary>
          {(user.perfil.task_dashboard == null || user.perfil.task_dashboard) ==
          true
            ? (
            <ExpansionPanelDetails onClick={() => redirect2('/dash/task')}>
              <Typography className={classes.subitemText}>DASHBOARD</Typography>
            </ExpansionPanelDetails>
              )
            : null}
          {(user.perfil.task_list == null || user.perfil.task_list) ==
          true
            ? (
            <ExpansionPanelDetails
              onClick={() => redirect2('/dash/techtask')}
            >
              <Typography className={classes.subitemText}>
                {language.TASKS_LIST}
              </Typography>
            </ExpansionPanelDetails>
              )
            : null}
          {(user.perfil.task_approve == null || user.perfil.task_approve) ==
          true
            ? (
            <ExpansionPanelDetails
              onClick={() => redirect2('/dash/taskapprove')}
            >
              <Typography className={classes.subitemText}>
                {language.APPROVALS}
              </Typography>
            </ExpansionPanelDetails>
              )
            : null}
          {(user.perfil.task_calendar == null || user.perfil.task_calendar) ==
          true
            ? (
            <ExpansionPanelDetails
              onClick={() => redirect2('/dash/task/calendar')}
            >
              <Typography className={classes.subitemText}>
                {language.CALENDAR}
              </Typography>
            </ExpansionPanelDetails>
              )
            : null}

        </ExpansionPanel>
      </ListItem>
      )}
      <Divider />

      {checkRoleAndPerfil(user.role, user.perfil, 'grc') ? (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel6'}
            onChange={handleChange('panel6')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Tooltip title={language.RISKS_MANAGEMENT_COMPLIANCE}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconEdit} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList className="box-list">
                  <TextList>{language.RISKS_MANAGEMENT_COMPLIANCE}</TextList>
                </BoxList>
              )}
              {/* <ListItemText
              className={classes.itemText}
              primary="GESTÃO DE RISCOS E COMPLIANCE"
              disableTypography
            /> */}
            </ExpansionPanelSummary>
            {(user.perfil.grc_dashboard == null
              ? user.role != 'solicitant'
              : user.perfil.grc_dashboard) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement')}
              >
                <Typography className={classes.subitemText}>
                  {language.DASHBOARD}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_side_dish == null
              ? user.role != 'solicitant'
              : user.perfil.grc_side_dish) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/sideDish')}
              >
                <Typography className={classes.subitemText}>
                  {language.SIDE_DISH}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_iso_27001 == null
              ? user.role != 'solicitant'
              : user.perfil.grc_iso_27001) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement/iso_27001')}
              >
                <Typography className={classes.subitemText}>
                  ISO 27001
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_iso_20000 == null
              ? user.role != 'solicitant'
              : user.perfil.grc_iso_20000) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement/iso_20000')}
              >
                <Typography className={classes.subitemText}>
                  ISO 20000
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_iso_9001 == null
              ? user.role != 'solicitant'
              : user.perfil.grc_iso_9001) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement/iso_9001')}
              >
                <Typography className={classes.subitemText}>
                  ISO 9001
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_iso_37001 == null
              ? user.role != 'solicitant'
              : user.perfil.grc_iso_37001) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement/iso_37001')}
              >
                <Typography className={classes.subitemText}>
                  ISO 37001
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_cobit == null
              ? user.role != 'solicitant'
              : user.perfil.grc_cobit) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement/cobit')}
              >
                <Typography className={classes.subitemText}>COBIT</Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_backup_management == null
              ? user.role != 'solicitant'
              : user.perfil.grc_backup_management) == true
              ? (
              <ExpansionPanelDetails
                onClick={() =>
                  redirect2('/dash/riskmanagement/backup_management')
                }
              >
                <Typography className={classes.subitemText}>
                  {language.BACKUP_MANAGEMENT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.grc_it_evolution_management == null
              ? user.role != 'solicitant'
              : user.perfil.grc_it_evolution_management) == true
              ? (
              <ExpansionPanelDetails
                onClick={() =>
                  redirect2('/dash/riskmanagement/it_evolution_management')
                }
              >
                <Typography className={classes.subitemText}>
                  {language.IT_EVOLUTION_MANAGEMENT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.grc_lgpd == null
              ? user.role != 'solicitant'
              : user.perfil.grc_lgpd) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/riskmanagement/lgpd')}
              >
                <Typography className={classes.subitemText}>LGPD</Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.grc_risk_matrix == null
              ? user.role != 'solicitant'
              : user.perfil.grc_risk_matrix) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/gri')}>
                <Typography className={classes.subitemText}>
                  {language.RISK_MATRIX}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
          </ExpansionPanel>
        </ListItem>
      ) : null}
      <Divider />

      {checkRoleAndPerfil(user.role, user.perfil, 'gp') && (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              {/* solicitant tech client
               client- perfil :ele vai ver po que ele
            */}
              <Tooltip title={language.PROJECT_MANAGEMENT}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconCheck} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList className="box-list">
                  <TextList>{language.PROJECT_MANAGEMENT}</TextList>
                </BoxList>
              )}
              {/* <ListItemText
              className={classes.itemText}
              primary="GESTÃO DE PROJETOS"
              disableTypography
            /> */}
            </ExpansionPanelSummary>
            {(user.perfil.gp_panel_pmo == null
              ? user.role != 'solicitant'
              : user.perfil.gp_panel_pmo) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/gp/pmo')}>
                <Typography className={classes.subitemText}>
                  {language.PANEL_PMO}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gp_projects != false) == true
              ? (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/gp')}>
                <Typography className={classes.subitemText}>
                  {language.PROJECT}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(
              user.perfil.sprint == null
                ? user.role != 'solicitant'
                : user.perfil.sprint
            )
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/projectmanagement/sprint')}
              >
                <Typography className={classes.subitemText}>SPRINT</Typography>
              </ExpansionPanelDetails>
                )
              : null}

            {(user.perfil.gp_allocation == null
              ? user.role != 'solicitant'
              : user.perfil.gp_allocation) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/calendar')}
              >
                <Typography className={classes.subitemText}>
                  {language.ALLOCATION}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gp_object_priorization == null
              ? user.role != 'solicitant'
              : user.perfil.gp_object_priorization) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/gp/prioritization')}
              >
                <Typography className={classes.subitemText}>
                  {language.PROJECT_PRIORITIZATION}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
            {(user.perfil.gp_template == null
              ? user.role != 'solicitant'
              : user.perfil.gp_template) == true
              ? (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/configuration/project')}
              >
                <Typography className={classes.subitemText}>
                  {language.PROJECT_TEMPLATE}
                </Typography>
              </ExpansionPanelDetails>
                )
              : null}
          </ExpansionPanel>
        </ListItem>
      )}

      <Divider />

      {user.role == 'client' ||
      user.perfil.report_tech ||
      user.perfil.report_group_tech
        ? (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel8'}
            onChange={handleChange('panel8')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Tooltip title={language.REPORTS}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconClipboard} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList
                  className="box-list"
                  onClick={() => redirect('/dash/reports')}
                >
                  <TextList>{language.REPORTS}</TextList>
                </BoxList>
              )}
            </ExpansionPanelSummary>
          </ExpansionPanel>
        </ListItem>
          )
        : null}
      <Divider />
      {params?.show_superset && (user.role == 'client' ||
      user.perfil.report_tech ||
      user.perfil.report_group_tech )
        ? (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel8'}
            onChange={handleChange('panel8')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Tooltip title={'SENSR BI'}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconMonitor} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList
                  className="box-list"
                  onClick={() => window.open(`${params.url_superset}/login?username=${user.email}&token=${tokenSuperset}`, '_blank')}
                >
                  <TextList>{'SENSR BI'}</TextList>
                </BoxList>
              )}
            </ExpansionPanelSummary>
          </ExpansionPanel>
        </ListItem>
          )
        : null}
      <Divider />
      {/* --------------------SAC HERE------------------------------ */}

      {/*checkRoleAndPerfil(user.role, user.perfil, 'fale') && (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel9'}
            onChange={handleChange('panel9')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel9d-content"
              id="panel9d-header"
            >
              <Tooltip title="FALE">
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                      <Icon
                        style={{ width: 25, height: 19, maxWidth: 'none'}}
                        src={IconFale}
                        alt="SAC Fale"
                      />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>

              {open && (
                <BoxList className="box-list">
                  <TextList>SAC FALE</TextList>
                </BoxList>
              )}
            </ExpansionPanelSummary>

            {showFale && (
              <ExpansionPanelDetails onClick={() => redirect2('/dash/sac/consumer-base')}>
                <Typography className={classes.subitemText}>
                  {language.CLIENTS_REGISTER}
                </Typography>
              </ExpansionPanelDetails>
            )}

            <ExpansionPanelDetails onClick={() => redirect2('/dash/sac/service-registration')}>
              <Typography className={classes.subitemText}>
                {language.LIST_OF_ATTENDANCES}
              </Typography>
            </ExpansionPanelDetails>

            <ExpansionPanelDetails
              onClick={() => redirect2('/dash/sac/dashboard')}
            >
              <Typography className={classes.subitemText}>Dashboard</Typography>
            </ExpansionPanelDetails>

            <ExpansionPanelDetails
              onClick={() => redirect2('/dash/sac/listtasks')}
            >
              <Typography className={classes.subitemText}>
                {language.TASKS_LIST}
              </Typography>
            </ExpansionPanelDetails>

            {showFale && (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/sac/SacThemeList')}
              >
                <Typography className={classes.subitemText}>
                  {language.THEME_REGISTER}
                </Typography>
              </ExpansionPanelDetails>
            )}

            {showFale && (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/sac/SacTopicList')}
              >
                <Typography className={classes.subitemText}>
                  {language.TOPICS_REGISTER}
                </Typography>
              </ExpansionPanelDetails>
            )}

            {showFale && (
              <ExpansionPanelDetails
                onClick={() => redirect2('/dash/sac/SubjecList')}
              >
                <Typography className={classes.subitemText}>
                  {language.SUBJECTS_REGISTER}
                </Typography>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        </ListItem>
      )*/}

      <Divider />

      {checkRoleAndPerfil(user.role, null, 'config') && (
        <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel10'}
            onChange={handleChange('panel10')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel10d-content"
              id="panel10d-header"
            >
              <Tooltip title={language.CONFIG}>
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                    <Icon src={IconSettings} alt="Cio Cockpit" />
                  </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList
                  className="box-list"
                  onClick={() => redirect2('/dash/configuration')}
                >
                  <TextList>{language.CONFIG}</TextList>
                </BoxList>
              )}
            </ExpansionPanelSummary>
          </ExpansionPanel>
        </ListItem>
      )}

      <Divider />
      {/*
      <ListItem className={classes.listItem}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel9'}
            onChange={handleChange('panel9')}
            elevation={0}
          >
            <ExpansionPanelSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
              onClick={() => redirect2('/dash/update')}
            >
              <Tooltip title="ATUALIZAÇÕES">
                <ListItemIcon style={{ width: 'auto' }}>
                  <ImgBox>
                  <i class="fas fa-info-circle" style={{width:'100%',color:'#F0B002', marginLeft:'-2px'}}></i>
                   </ImgBox>
                </ListItemIcon>
              </Tooltip>
              {open && (
                <BoxList
                  className="box-list"

                >
                  <TextList>ATUALIZAÇÕES</TextList>
                </BoxList>
              )}
            </ExpansionPanelSummary>
          </ExpansionPanel>
        </ListItem> */}
    </div>
  )
}
// <i class="fas fa-info-circle"></i>
Sidebar.propTypes = {
  drawerOpen: PropTypes.func.isRequired,
  drawerStatus: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(Sidebar)
