import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import { Tooltip } from 'react-tippy';
import './style.css';

const styles = {
	button: {
		border: 10,
		boxSizing: 'border-box',
		display: 'inline-block',
		fontFmily: 'GeosansLight',
		cursor: 'pointer',
		textDecoration: 'none',
		outline: 'none',
		fontSize: 'inherit',
		fontWeight: 'inherit',
		position: 'relative',
		zIndex: 1,
		textTransform: 'uppercase',
		lineHeight: '36px',
		borderRadius: 2,
		padding: '0 20px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',

		textAlign: 'center',
		'&:hover': {
			opacity: 0.8
		}
	},
	buttonSmall: {
		lineHeight: '30px',
		padding: '0 15px',
		fontSize: '15px',
		/*lineHeight: '18px',
		fontSize:'9px',
		padding:'0 10px',*/
	},
	primary: {
		color: 'white',
		backgroundColor: 'var(--primary)',
		background: 'var(--primary)'
	},
	success: {
		color: 'white',
		backgroundColor: 'var(--success)',
		background: 'var(--success)'
	},
	danger: {
		color: 'white',
		backgroundColor: 'var(--danger)',
		background: 'var(--danger)',
	},
	info: {
		color: 'white',
		backgroundColor: 'var(--info2)',
		background: 'var(--info2)'
	},


	greenGradient: {
		color: 'white',
		backgroundColor: '##33ABA0',
		background: '#33ABA0'
	},
	warning: {
		color: '#494747',
		backgroundColor: 'var(--warning)',
		background: 'var(--warning)'
	},
	default: {
		color: 'white',
		backgroundColor: '#6c757d',
		background: '#6c757d'
	},
	light: {
		color: '#616161',
		backgroundColor: 'white',
		background: 'white'
	},
	primaryGradient: {
		color: 'white',

		background: '#629DAA'
	}
};

class RaisedButton extends React.Component {

	render() {
		const { classes, className, tPosition, ...other } = this.props;
		const props = this.props;
		var style = props.style;
		// var other = { ...this.props };
		// delete other.style;
		// delete other.className;

		var size = { ...style };
		if (props.size && props.size != 1) {
			size = {
				lineHeight: (36 * props.size) + 'px',
				fontSize: (16 * props.size + 1) + 'px',
				padding: `0 ${20 * props.size}px`,
				...style
			}
		}
		var classname = classnames(classes.button, (props.color ? classes[props.color] : classes.primary), (props.small ? classes.buttonSmall : ''), className);
		var btn = (
			this.props.circleButton ?
				<div className={classnames('btn-circle', (props.color ? classes[props.color] : ''), className)} {...other}>
					{(typeof this.props.icon) == 'string' ? <i className={this.props.icon}></i> : this.props.icon}
				</div> : null
		)
		return (
			this.props.circleButton ? (
				props.label ?
					<Tooltip
						html={
							<div style={{ color: '#fff', fontSize: 15 }}>
								<strong>{props.label}</strong>
							</div>
						}
						arrow={true}
						position={tPosition || "left"}
						theme="dark"

					>
						{btn}
					</Tooltip> : btn
			) : (
				<button
					{...other}
					className={classname}
					style={size}
				>
					{props.label}
				</button>
			)
		)
	}
}

RaisedButton.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RaisedButton);