import SimplesAlert from '@common/SimplesAlert';
import { Checkbox, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { Actions, Constants } from '../../data';
import { useDebounce } from '../ServicePortal/helpers';

export default function CompanyGroupingDetailForm({
  companyData,
  params,
  language,
  onClose,
}) {
  const alertDefault = { opened: false, message: '', variant: '' };
  const [alert, setAlert] = useState(alertDefault);

  const [data, setData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const [textSearch, setTextSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const filterTextSearch = useDebounce(textSearch, 300);

  const [groupingName, setGroupingName] = useState(
    companyData.grouping_name ?? ''
  );
  const [tasksSelected, setTasksSelected] = useState(companyData.tasks || []);

  useEffect(() => {
    const pagination = `limit=${rowsPerPage}&offset=${
      currentPage * rowsPerPage
    }`;
    const fetch = new Actions();
    fetch
      .execute(
        'post',
        `${Constants.APIEndpoints.TASK}/list?${pagination}`,
        null,
        {
          fk_id_company: companyData.id_company,
          search: filterTextSearch,
        }
      )
      .then((response) => {
        setData(response.data);
        setTotalDataCount(response.data?.[0]?.total_count ?? 0);
      })
      .catch((error) => {
        setData([]);
        setTotalDataCount(0);
      });

    return () => {};
  }, [currentPage, rowsPerPage, filterTextSearch]);

  const handleChangeSelect = (idChange) => {
    setTasksSelected((prevTasksSelected) => {
      const isSelected = prevTasksSelected.includes(idChange);
      if (isSelected) {
        return prevTasksSelected.filter((id) => id !== idChange);
      }
      return [...prevTasksSelected, idChange];
    });
  };

  const handleClose = (reload) => {
    onClose?.(reload);
  };

  const handleSave = () => {
    if (!groupingName || !tasksSelected.length) {
      handleOpenAlert(language.FILL_REQUIRED, 'error');
      return;
    }

    const idEdition = companyData.id_company_catalog_grouping;

    const fetch = new Actions();
    const endpoint = `${Constants.APIEndpoints.COMPANY}/${companyData.id_company}/catalog_groupings`;
    const method = idEdition ? 'put' : 'post';

    fetch
      .execute(method, endpoint, '', {
        grouping_name: groupingName,
        tasks: tasksSelected,
        id_company_catalog_grouping: companyData.id_company_catalog_grouping,
      })
      .then((response) => {
        if (response.data.newId || response.data.id) handleClose(true);
        else {
          handleOpenAlert(language.ERROR_CODE.E500, 'error');
        }
      })
      .catch((error) => {
        handleOpenAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const handleSearch = (text, call) => {
    setTextSearch(text && typeof text === 'object' ? '' : text ?? '');
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    //setCurrentPage(0);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  const handleOpenAlert = (message, variant = '') => {
    setAlert({ opened: true, message: message, variant: variant });
  };

  const cols = [
    { key: 'checkbox', label: '', style: { width: 20 } },
    { key: 'id', label: language.ID, style: { width: 20 } },
    { key: 'name', label: language.NAME },
    { key: 'description', label: language.DESCRIPTION },
  ];

  const dataRows = useMemo(() => {
    return data.map((item, index) => {
      return {
        checkbox: (
          <Checkbox
            checked={tasksSelected.includes(+item.id_catalog_task)}
            onChange={() => handleChangeSelect(+item.id_catalog_task)}
          />
        ),
        id: item.id_catalog_task,
        name: item.name,
        description: item.description,
      };
    });
  }, [data, tasksSelected]);

  return (
    <div>
      <div className="tw-mb-6">
        <TextField
          label={language.GROUPING_NAME}
          value={groupingName}
          variant="outlined"
          style={{ width: '30%' }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setGroupingName(e.target.value);
          }}
        />
      </div>

      <CommonTable
        title={''}
        language={language}
        columns={cols}
        data={dataRows}
        countTotal={totalDataCount}
        searchColumnEvent={(value) => {
          handleSearch(value?.search, 'searchColumnEvent');
        }}
        onClearAllFilter={() => {
          handleSearch('', 'onClearAllFilter');
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) => {}}
        isAutomaticPagination={false}
        hideFooterToolbar
        paginationTop={false}
        currentPage={currentPage}
        rowsPerPageProp={rowsPerPage}
        callPageSelect={handlePageChange}
      />

      <Row style={{ marginTop: 10 }}>
        <Col md={12}>
          <div style={{ float: 'right' }}>
            <RaisedButton
              fullWidth
              circleButton
              primary={true}
              icon="fas fa-save"
              label={language.SAVE}
              onClick={handleSave}
            />
          </div>
        </Col>
      </Row>

      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
}
