import { matchPath } from "react-router-dom";
import ioSocket from 'socket.io-client';
import Constants from "../data/Constants";

// ------------------------------------------FILE TO BASE64 local---------------------------------------------------------
export function truncate(str, length, separator) {
	return _.truncate(str, {
		length,
		separator /*       /,?\.* +/        */
	})
}
// ------------------------------------------GRAVAR OS SEARCH EM CADA COLUNA DAS TABELAS---------------------------------------
export function setInjetSearchColumn(pg, value) {
	var get = localStorage.getItem('injetSearchColumn') || '{}';
	get = JSON.parse(get);
	get[pg] = value;

	if (!Object.values(get[pg]).find(e => e !== '')) {
		delete get[pg];
	}
	value = JSON.stringify(get);
	localStorage.setItem('injetSearchColumn', value);
}

export function getInjetSearchColumn(pg) {
	var get = localStorage.getItem('injetSearchColumn') || '{}';
	get = JSON.parse(get);
	return get[pg];
}

// -----------------------------------FILTRA ARRAYS ENORMES NULOS PARA SEREM PASSADOS NOS ROUTES-------------------------------
export function filterObj(obj) {
	var keys = Object.keys(obj);

	return keys.reduce((new_obj, key) => {

		if (!!obj[key] && (typeof obj[key]) == 'object' && obj[key].hasOwnProperty('$mobx')) {

			new_obj[key] = obj[key].$mobx.values;
		} else { new_obj[key] = obj[key] }
		return new_obj;
	}, {});
}

// ----------------------------------------TESTA AS OCORRÊNCIAS DE ROTAS------------------------------------------------------
export function testRoute(obj, location) {
	var mp;
	obj = [].concat(obj);
	var objResult = obj.filter(e => {
		mp = matchPath(location, {
			path: e,
			exact: true,
		});
		return !!mp;
	});

	return !!objResult.length;
}
export function io() {
	return ioSocket(window.location.protocol + '//' + Constants.APIDomain, 
	{ query: 'token=' + sessionStorage.getItem('token'),transports: ["websocket"] });
}
export function flexBasis(col) {
	return { maxWidth: 8.33333333 * (col || 12) + '%', flexBasis: 8.33333333 * (col || 12) + '%' }
}
export function toBase64(files = [], callback = () => null) {
	const _toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve({ file, base64: reader.result });
		reader.onerror = error => reject(error);
	});

	return Promise.all(Array.from(files).map(file => {
		var f = _toBase64(file);
		f.then(f => callback(f))
		return f;
	}))
}

//----soma dois horários passados, exemplo addTimes('05:03:00', '01:02:01') -> 06:05:01
export function addTimes(startTime, endTime) {
	var times = [0, 0, 0]
	var max = times.length

	var a = (startTime || '').split(':')
	var b = (endTime || '').split(':')

	// normalize time values
	for (var i = 0; i < max; i++) {
		a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
		b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
	}

	// store time values
	for (var i = 0; i < max; i++) {
		times[i] = a[i] + b[i]
	}

	var hours = times[0]
	var minutes = times[1]
	var seconds = times[2]

	if (seconds >= 60) {
		var m = (seconds / 60) << 0
		minutes += m
		seconds -= 60 * m
	}

	if (minutes >= 60) {
		var h = (minutes / 60) << 0
		hours += h
		minutes -= 60 * h
	}

	return hours.toString().padStart(2, 0) + ':' + minutes.toString().padStart(2, 0) + ':' + seconds.toString().padStart(2, 0)
}

//----me retorna a horas no formato hh:mm baseado no total de minutos passados, exemplo minutesToSumHour(48*60) -> 48:00
export function minutesToSumHour(num) {
	const hours = Math.floor(num / 60);
	const minutes = num % 60;
	return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}`;
}

//--converte horas para minutos---- exemplo hourToMinutes('01:30') -> 90
export function hourToMinutes(hour) {
	hour = hour.split(':')
	let h = parseInt(hour[0]) || 0;
	let m = parseInt(hour[1]) || 0;

	return h * 60 + m;
}
