import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import SlaList from './SlaList';
import SlaForm from './SlaForm';
import { ListHolidays } from './ListHolidays';
import Animate from '@common/Animate';

@observer
export default class SlaContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.SLA, 'slas');
  }

  onFormSubmit(values) {
    //console.log(values)
    let method = values.hasOwnProperty('id_sla') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_sla')
      ? this.APIEndpoints.SLA + '/' + values.id_sla
      : this.APIEndpoints.SLA;

    this.action
      .execute(method, url, 'slas', values, 'id_sla')
      .then((e) => this.redirect('/dash/configuration/sla'));
  }

  onDelete() {
    let { values } = this.state;
    this.action.execute(
      'delete',
      this.APIEndpoints.SLA + '/' + values.id_sla,
      'slas',
      null,
      'id_sla'
    );
    this.handlePage('list');
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    let { language, slas } = this.store;

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <SlaList
            redirect={this.redirect2}
            language={language}
            onFormSubmit={this.onFormSubmit}
            handlePage={this.handlePage}
            store={this.store}
            APIEndpoints={this.APIEndpoints}
            configurationProps={configurationProps}
          />
        </Animate>

        {page === 'listHolidaysBySlaId' && (
          <ListHolidays
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        )}

        {page === 'form' && (
          <SlaForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        )}

        {page === 'remove' && (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.id_sla + ' - ' + values.name}
          />
        )}
      </Fragment>
    );
  }
}
