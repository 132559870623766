import React, {Fragment, memo} from 'react';
/*------------------------------common-------------------------------------*/
import CommonForm from '@common/CommonForm2';
/*------------------------------material-ui-------------------------------------*/
import {Avatar} from '@material-ui/core';

const ListItem = memo(({name, date, text})=>{
	return(
		<li className='mb-3'>
			<Avatar className='avatar'>
				{name.slice(0,2)}
			</Avatar>
			<div>
				<header>
					<span className='mx-2'>{name}</span>
					<span>{date}</span>
				</header>
				<p className='my-0 ml-2' dangerouslySetInnerHTML={{__html: text}}/>
			</div>
		</li>
	)
})

export default memo(({data=[]})=>{
	return(
		<div className='Block_6'>
			<ul>
				{data.map((e,i)=>(
					<ListItem key={i} index={i} {...e}/>
				))}
			</ul>
		</div>
	)
})