import React from 'react';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import Store from '../../data/Store';
import Button from '@common/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';

export default observer((props)=>{
	let {title, message, open, onClose, onDelete} = props;
	let {language} = Store;
	return(
		<Dialog 
			
			open={open}
			onClose={onClose}
			maxWidth='sm'
			bar={false}
			PaperProps={{
				className:'CommonDelete2'
			}}
		>
			<div className='d-flex align-items-center justify-content-end contet-close-button'>
				<IconButton aria-label="Close" onClick={onClose} style={{padding:6}}>
				  <CloseIcon style={{color:'var(--primary)'}}/>
				</IconButton>
			</div>
			<h4 id='title'>{title||language.CONFIRM_DELETE}</h4>
			<p id='body' className='content py-0'>
				{message||CONFIRM_DELETE_BODY}
			</p>
			<div className='d-flex align-items-center justify-content-end'>
				<Button
					label='Sim, deletar'
					outlined
					variant='normal'
					color='danger'
					className='mr-1'
					size={.88}
					style={{border:'none', fontWeight:600}}
					onClick={onDelete}
				/>
				<Button
					label='Cancelar'
					outlined
					variant='normal'
					color='default'
					size={.88}
					style={{border:'none', fontWeight:600}}
					onClick={onClose}
				/>
			</div>
		</Dialog>
	)
})