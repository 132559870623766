import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonPage from '../../common/CommonPage';
import CommonTable from '../../common/CommonTable';
import { FaCircle } from 'react-icons/lib/fa';
import MdDashboard from 'react-icons/lib/md/dashboard';
import moment from 'moment';
import TicketUtils from '../../util/TicketUtils';
import SelectField from 'material-ui/SelectField';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core'; // controls
import ErrorIcon from '@material-ui/icons/Error';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../bClass.css';
import './TicketList.css';

@observer
export default class TicketList extends CommonPage {
  constructor() {
    super();
    this.state = {
      update: 0,
      companyId: null,
      search: ''
    };
    this.updateState = this.updateState.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    const {
      target: { name, value }
    } = event;
    this.setState({ search: value });
  };
  getTimezoneOffset() {
    var offset = new Date().getTimezoneOffset(),
      o = Math.abs(offset);
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2);
  }

  componentDidMount() {
    this.updateState();
  }

  updateState() {
    this.setState({ update: ++this.state.update });
    setTimeout(this.updateState, 1000, this);
  }

  handleCompany(values) {
    this.setState({ companyId: values });
  }

  render() {
    let {
      language,
      handlePage,
      redirect,
      tickets,
      ticket,
      route,
      stopsla,
      companies,
      times,
      addTab,
      timeall
    } = this.props;
    let { ty } = this.state;
    let col = [

      {
        label: language.ID,
        key: 'id',
        style: { width: 1, paddingRight: 0, textAlign: 'left' },
        thConfig: true
      },
      {
        label: language.ACTION,
        key: 'detail',
        style: {
          width: route === 'problem' ? 50 : 1,
          paddingRight: 5,
          paddingLeft: 5
        },
        thConfig: true
      },
      {
        label: language.STATE,
        key: 'status',
        style: { width: 100, paddingRight: 0, paddingLeft: 0 },
        thConfig: true
      },

      {
        label: language.USER,
        key: 'user',
        style: { width: 300, paddingRight: 0 },
        thConfig: true
      },
      {
        label: language.COMPANY,
        key: 'company',
        style: { width: 100, paddingRight: 0 }
      },
      {
        label: language.SUBJECT,
        key: 'subject',
        style: { width: 400, paddingRight: 0 },
        thConfig: true
      },
      {
        label: language.GROUP_TECH,
        key: 'grouptech',
        style: { width: 350 },
        thConfig: true
      },
      {
        label: language.SERVICE_TECH,
        key: 'tech',
        style: { width: 150, paddingRight: 0 },
        thConfig: true
      },
      {
        label: language.SLA_PROGRESS_GRID,
        key: 'sla',
        style: { width: 10, paddingRight: 0, paddingLeft: 0 },
        thConfig: true
      },

      { label: language.DATE_CAD, key: 'dt_cad', style: { width: 100 } },
      {
        label: language.HOUR,
        key: 'dt_hr',
        style: { width: 1, paddingRight: 0, paddingLeft: 0 },
        thConfig: true
      },
      {
        label: language.SLA_AGREED,
        key: 'slaTime',
        style: { width: 150 },
        thConfig: true
      },
      {
        label: 'Aguardando',
        key: 'status_',
        style: { width: 150 },
        thConfig: true
      },

      { label: language.TIME_SPENT, key: 'timespent', style: { width: 100 } }
    ];



    if (this.props.search != '') {
      tickets = tickets.filter(item => {
        return (
          item.subject.toUpperCase().indexOf(this.props.search.toUpperCase()) !=
          -1 ||
          item.config[0].realid
            .toUpperCase()
            .indexOf(this.props.search.toUpperCase()) != -1 ||
          item.config[0].tech_start
            .toUpperCase()
            .indexOf(this.props.search.toUpperCase()) != -1 ||
          item.config[0].group_tech
            .toUpperCase()
            .indexOf(this.props.search.toUpperCase()) != -1
        );

        /**
           *  tech: t.config[0].tech_start,
    grouptech: t.config[0].group_tech,
           */
      });
    }



    let gmt = 0;
    let data = tickets.map(t => {
      let open = true;

      if (
        timeall.filter(x => x.fk_id_contract == t.fk_id_contract).length > 0
      ) {

        let itens = times
          .filter(x => x.fk_id_contract === t.fk_id_contract)
          .map(x => {
            return {
              hr_start: x.hr_start,
              hr_fim: x.hr_fim,
              gmthour: x.gmthour
            };
          });

        if (itens.length > 0) {
          open = TicketUtils.compare(itens[0].hr_start, itens[0].hr_fim);
        } else {
          open = false;
        }
      }

      let req = [];

      if (t.config[0].dt_cad != null) {
        req = [
          {
            dt_up: t.config[0].dt_up,
            dt_cad: t.config[0].dt_cad,
            id_request: t.config[0].id_request
          }
        ];
      }
      t.time_resolution = t.config[0].time_resolution;

      if (t.config[0].time_resolution_problem != null) {
        t.time_resolution = t.config[0].time_resolution_problem;
      }

      /* if (itens.length > 0) {
        if (this.getTimezoneOffset() != itens[0].gmthour) {
          gmt = parseFloat(itens[0].gmthour);
        }
      }*/

      let sla =
        open == true
          ? TicketUtils.calcSla(t, 'ticket', language, req, t.sla_task, gmt)
          : TicketUtils.stopSla(t, stopsla, language, req, t.sla_task);

      let sla_stop = TicketUtils.stopSla(t, stopsla, language, req, t.sla_task);
      let sla_reopen = TicketUtils.reopedSla(t, stopsla, language, req);

      let id_ = null;

      if (req != undefined && req.length == 1) {
        id_ = 'R' + req[0].id_request;
      } else {
        id_ = 'T' + t.id_tickets;
      }

      let timep = null;
      if (t.config[0].progress > 0) {
        timep = t.time_resolution;
      } else {
        timep = null;
      }

      return {

        id: id_,
        detail: (
          <div className="col-action">



            <div>
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_DETAIL}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <div
                  onClick={() =>
                    //  redirect('/dash/tickets/detail', { ticket: t, back: route })
                    addTab(t)
                  }
                  className="detailIcon pointer"
                  style={{ width: 25, height: 25 }}
                />
              </Tooltip>
            </div>
            {route === 'problem' ? (
              <React.Fragment>
                {''}
                <div>
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{'Migrar Ticket - GM'}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <div
                      onClick={() =>
                        redirect('/dash/changemanagement', { ticket: t })
                      }
                      className="changeManagementIcon pointer"
                      style={{ width: 25, height: 25 }}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{'Migrar Ticket - GP'}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <div
                      onClick={() => redirect('/dash/gp/list', { ticket: t })}
                      className="projectManIcon pointer"
                      style={{ width: 25, height: 25 }}
                    />
                  </Tooltip>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        ),
        status: (
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              {t.status === 'Resolved' ? (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_STATUS.RESOLVED}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <span>
                    <div
                      className="resolvedIcon"
                      style={{ width: 25, height: 25 }}
                    />
                  </span>
                </Tooltip>
              ) : t.status === 'In Progress' ? (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_STATUS.IN_PROGRESS}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <span>
                    <div
                      className="inProgressIcon"
                      style={{ width: 25, height: 25 }}
                    />
                  </span>
                </Tooltip>
              ) : t.status === 'Closed' ? (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_STATUS.CLOSED}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <span>
                    <div
                      className="closedIcon"
                      style={{ width: 25, height: 25 }}
                    />
                  </span>
                </Tooltip>
              ) : t.status === 'Cancelled' ? (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_STATUS.CANCELLED}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <span>
                    <div
                      className="cancelledIcon"
                      style={{ width: 25, height: 25 }}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.TICKET_STATUS.OPEN}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <span>
                    <div
                      className="openIcon"
                      style={{ width: 25, height: 25 }}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="orderdivpro w-100 ml-1" id={t.config[0].progress}>
              <div style={{ textAlign: 'center' }}>{t.config[0].progress}%</div>
              <div
                style={{
                  width: `${t.config[0].progress}%`,
                  height: '7px',
                  backgroundColor:
                    t.config[0].progress > 66
                      ? '#85cc00'
                      : t.config[0].progress > 33
                        ? '#ffbf00'
                        : '#ff2e00',
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              />
            </div>
          </div>
        ),

        user: t.name_person,
        company: t.name_company,
        subject: t.subject,
        grouptech: t.config[0].group_tech,
        tech: t.status != 'Open' ? t.config[0].tech_start : '',

        sla:
          t.status == 'Resolved' || t.status == 'Closed' ? (
            <div>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: sla_stop.color,
                  borderRadius: '50%',
                  float: 'left',
                  marginRight: 5
                }}
              />
              <span style={{ color: sla_stop.color }}>{sla_stop.time}</span>
            </div>
          ) : t.status == 'Reopened' ? (
            <div>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: sla_reopen.color,
                  borderRadius: '50%',
                  float: 'left',
                  marginRight: 5
                }}
              />
              <span style={{ color: sla_reopen.color }}>{sla_reopen.time}</span>
            </div>
          ) : typeof sla !== 'string' ? (
            <div>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: sla.color,
                  borderRadius: '50%',
                  float: 'left',
                  marginRight: 5
                }}
              />
              <span style={{ color: sla.color }}>{sla.time}</span>
            </div>
          ) : (
            language.NOTHING_REGISTERED
          ),

        dt_cad: moment(
          t.config[0].dt_cad != null ? t.config[0].dt_cad : t.dt_cad
        ).format('L'),
        dt_hr: moment(
          t.config[0].dt_cad != null ? t.config[0].dt_cad : t.dt_cad
        ).format('HH:mm'),
        slaTime: t.sla_task + ' Horas',
        status_: t.stop_ticket_text,

        timespent: timep
      };
    });

    return (
      <div id="TicketList">
        {/*<ReactTable
          data={data}
          filterable={true}
          defaultFilterMethod={(filter, row) => {
            return String(row[filter.id]).toUpperCase().indexOf(filter.value.toUpperCase()) === 0
          }}
          columns={col}
          defaultPageSize={100}
          className="-striped -highlight"
        />*/}
        <CommonTable
          data={data}
          col={col}
          width="120%"
          rowsPerPageOptions={[100, 250]}
          searchColumn
          orderColumn
          beforeBar={this.props.btnNewTicket}

        />
      </div>
    );
  }
}
