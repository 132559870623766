import React, {memo,useState, Fragment} from 'react';
import { Tooltip } from 'react-tippy';
import { Draggable, Droppable } from 'react-drag-and-drop';
/*--------------------------common----------------------------*/
import Progress from '@common/Progress';
import MenuTooltip from '@common/MenuTooltip';
import Dialog from '@common/Dialog';
import Tag from '@common/Tag';
/*--------------------------material-ui----------------------------*/
import {Checkbox} from '@material-ui/core';
/*--------------------------components----------------------------*/
import ListAvatars from './ListAvatars';
import moment from 'moment'
import DialogTicket from '../../DialogTicket';

export default memo(({
	id,
	avatares=[],
	name,
	dt,
	commentsTotal,
	filesTotal,
	percent,
	percentColor,
   onDelete,
   onEdit,
   onView,
   project,
   star,
   activity,
   type,
   redirect,
   id_project,
   pj
})=>{
   let [open, setOpen] = useState(false);
   let [idTicket, setIdTicket] = useState(0);
	return(
		<Fragment>
         <DialogTicket
            open={!!idTicket}
            onClose={() => setIdTicket(0)}
            idTicket={idTicket}
         />
      
         <Draggable type="milestone" data={JSON.stringify({id, percent, project})} className='Card d-flex w-100'>
            
            <div className='content-users p-1 pt-1'>
               <span className='id'>{id}</span>
               <ListAvatars star={false} maxItem={3} className='list-avatars-in-project' data={avatares}/>
            </div>
            <div className='content-min content-desc'>
               <div onClick={()=>onView(id)}>
                  <div className='d-flex align-items-center justify-content-between'>
                     <div>
                        {[1,2,3].map(e=>(
                           <i className={(e <= star?'fas fa-star':'far fa-star')+' star'}/>
                        ))}
                     </div>
                     <Tag {...type} style={{fontSize:8}}/>
                  </div>
                  <Tooltip
                  title={name}
                  position="left"
                  interactive
                  arrow={true}
                  >
                     <p
                        className='name ellipsis-line camp-2 my-1'
                        dangerouslySetInnerHTML={{ __html: name }}
                     />
                  </Tooltip>
                  
               </div>
               <div>
                  <Progress current={percent} className='w-100' background={percentColor}/>
                  <div className='content-info -mb-1'>
                     <span>
                        <i className="fas fa-calendar-day"/>
                        {dt}
                        
                     </span>
                     <span>
                        <i className="fas fa-comments"/>
                        {commentsTotal}
                     </span>
                     
                     <span>
                        <i className="fas fa-paperclip"/>
                        {filesTotal}
                     </span>

                     <MenuTooltip
                        
                        propsButton={{
                           style:{
                              padding:0,
                              marginTop:-3
                           }
                        }}
                        options={[
                           // {value:1, label:'Editar', icon:'fas fa-pencil-alt', color:'var(--success)'},
                           // {value:2, label:'Excluir', icon:'fas fa-trash-alt', color:'var(--danger)'},
                           {value:2, label:'Visualizar', icon:'fas fa-eye', color:'var(--primary)'},
                        ]}
                        onChange={val => {
                           if(type.type==1){
                              redirect('/dash/gp/'+id_project, {
                                 id,
                                 openView:true,
                                 pj
                              })
                           }else{
                              setIdTicket(id);
                           }
                        }}
                     />
                  </div>
               </div>
            </div>
         </Draggable>
      </Fragment>
	)
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
})