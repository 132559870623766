import Button from '@common/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import CommonForm from '../../common/CommonForm2';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import { CompanyPortalInnerHeader } from './CompanyPortalForm';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function CompanyPortalFormTabs({ data, language, onClose, tabsOptions }) {

    const classes = useStyles();
    const [dataTabs, setDataTabs] = useState();

    useEffect(() => {
        if (Array.isArray(data?.portal?.tabs)) {
            let tabsOptionsSwap = [...tabsOptions];

            data.portal.tabs.forEach(element => {
                const indexFound = tabsOptionsSwap.findIndex(item => item.name === element.name);

                tabsOptionsSwap[indexFound] = {
                    ...tabsOptionsSwap[indexFound],
                    index: element.index,
                    label: language['SERVICE_PORTAL'][element.lang_key],
                    lang_key: element.lang_key,
                    checked: element.checked,
                    allowCheck: element.allow_check,
                };
            });

            setDataTabs(tabsOptionsSwap)
        }
        else {
            setDataTabs(tabsOptions)
        }
    }, [data])

    const handleChangeCheck = (event, item) => {
        if (item.allowCheck) {
            let _data = [...dataTabs];
            const indexFound = _data.findIndex(element => item.name === element.name);

            _data[indexFound].checked = event.target.checked;
            setDataTabs(_data);
        }
    }

    const handleSave = () => {
        const baseUrl = `${Constants.APIEndpoints.COMPANY}/${data.id_company}/portal/${data.portal.id_company_portal}/tabs`;

        let payload = dataTabs.map(item => ({ ...item }));
        payload.forEach(item => delete item.Child);

        const dispatcher = new Actions();
        dispatcher
            .execute('post', baseUrl, 'company_portal_tabs', { tabs: payload }, 'id_company_portal_tabs')
            .then(() => { })
            .finally(() => onClose())
    }

    dataTabs?.sort((a, b) => a.index - b.index);
    const hasPortalConfiguration = data?.hasOwnProperty('portal') && data?.portal?.hasOwnProperty('id_company_portal');

    return (
        <div className={classes.root}>
            <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_TAB}</CompanyPortalInnerHeader>
            {
                dataTabs?.map((item, index) => {
                    return (
                        <Accordion key={index} disabled={!hasPortalConfiguration}>
                            <AccordionSummary
                                aria-label="Expand"
                                id={`additional-actions${index}-header`}
                                expandIcon={item.Child ? <ExpandMoreIcon /> : <></>}
                                aria-controls={`additional-actions${index}-content`}
                                onClick={(event) => !item.Child && event.stopPropagation()}
                            >
                                <div className='tw-flex tw-justify-between tw-items-center tw-max-w-full tw-pr-5'>
                                    <FormControlLabel
                                        aria-label={item.label}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                checked={item.checked}
                                                defaultChecked={!item.allowCheck}
                                                color={item.allowCheck ? 'primary' : 'default'}
                                                onClick={(event) => handleChangeCheck(event, item)}
                                            />
                                        }
                                        label={item.label}
                                    />
                                </div>
                            </AccordionSummary>
                            {item.Child &&
                                <AccordionDetails>
                                    <div className='tw-pb-5 tw-w-full' style={{ paddingLeft: 86 }}>
                                        {item.Child}
                                    </div>
                                </AccordionDetails>
                            }
                        </Accordion>
                    )
                })
            }
            <div className='tw-py-3 tw-text-right'>
                {!hasPortalConfiguration &&
                    <small className='tw-px-3 tw-text-yellow-400'>
                        <i className='fas fa-exclamation tw-px-2'></i>
                        {language.SERVICE_PORTAL.TABS_EXCLAMATION}
                    </small>
                }
                <Button
                    md={2}
                    primary={true}
                    variant="normal"
                    color={'success'}
                    offset={{ md: 10 }}
                    icon={'fas fa-save'}
                    disabled={!hasPortalConfiguration}

                    onClick={handleSave}
                    label={language.SAVE}
                />
            </div>

        </div>
    );
}
