import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import DownloadFiles from '../../util/DownloadFiles';
import SolutionForm from './SolutionForm';
import SolutionList from './SolutionList';
import SolutionDetail from './SolutionDetail';

@observer
export default class SolutionContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }
  onDelete() {
    let { values } = this.state;
    this.action.execute(
      'delete',
      this.APIEndpoints.SOLUTION + '/' + values.id_solution,
      'solutions',
      null,
      'id_solution'
    );
    this.handlePage('list');
  }
  onDrop(g) {
    Array.from(g).forEach((f) => {
      this.state.fliesnk.push(f);
    });
    //this.state.files =e.target.files
    //console.log( this.state.fliesnk)
    this.setState({ files: this.state.fliesnk });
  }
  removeFile(item) {
    var filesArray = [];
    Array.from(this.state.fliesnk).forEach((f) => {
      if (f.name != item.name) {
        filesArray.push(f);
      }
    });
    this.state.fliesnk = filesArray;
    this.setState({ files: filesArray });
  }

  ondonwFile(id, file) {
    DownloadFiles(
      this.APIEndpoints.SOLUTION + '/download/' + id + '&' + file,
      file
    );
  }

  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/20',
      'type_solution'
    );
  }

  handlePage(page, solution) {
    this.setState({ page: page, values: solution });
  }

  onFormSubmit(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.SOLUTION,
      'solutions',
      values
    );
    this.handlePage('list');
  }

  render() {
    let { categories, language, solutions, type_solution, catalogs } =
      this.store;
    let { page, values } = this.state;
    return (
      <div>
        {page === 'list' ? (
          <SolutionList
            values={values}
            handlePage={this.handlePage}
            language={language}
            company={this.props.company}
            subcategory={this.props.subcategory}
            openticket={true}
            solutions={solutions}
          />
        ) : page === 'detail' ? (
          <SolutionDetail
            ondonwFile={this.ondonwFile}
            values={values}
            ticket=""
            openticket={true}
            handlePage={this.handlePage}
            language={language}
          />
        ) : page === 'form' ? (
          <SolutionForm
            files={this.state.files}
            removeFile={this.removeFile}
            onDrop={this.onDrop}
            ondonwFile={this.ondonwFile}
            categories={categories}
            catalogs={catalogs}
            values={values}
            openticket={true}
            handlePage={this.handlePage}
            language={language}
            onFormSubmit={this.onFormSubmit}
            type_solution={type_solution}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
