import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';

import RaisedButton from '../../common/RaisedButton';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import moment from 'moment';
import { Card, Paper, Grid } from '@material-ui/core';
import AppBar from '@common/AppBar';
import './StockDetail.css';
import ListColumn from '@common/ListColumn';
import CommonPage from '@common/CommonPage'

export default class StockDetail extends CommonPage {

  render() {
    let { handlePage, language, value } = this.props;

    return (
      <PageLayout
        icon={<div className="stockIcon" style={{ width: 40, height: 40 }} />}
        title={language.STOCK_MANAGEMENT}
        subtitle={language.DETAILS}
        subHeader={{
          fa: true,
          label: '#' + value.id_stockout + ' ' + value.name,
          icon: <i className="fas fa-info" style={{ width: 'auto', height: 'auto', fontSize: 11 }} />
        }}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            color='primaryGradient'
            onClick={() => this.history.goBack()}
            fontSize={25}

            label={language.BACK}
          />
        ]}
      >
        <div id="StockDetail" className='mt-3'>

          <Grid container spacing={3}>

            <Grid item xs={6}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar style={{ width: '95%' }}>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="detailIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span style={{ marginLeft: 8 }}>{language.DETAILS}</span>
                  </div>
                </AppBar>
                <div className="content" style={{ paddingTop: 35 }}>
                  <ListColumn
                    col={6}
                    items={[
                      {
                        label: language.USER,
                        value: value.username
                      },
                      {
                        label: language.TYPE,
                        value: value.type
                      },
                    ]}
                  />

                </div>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar style={{ width: '95%' }}>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="finishedStatusIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span style={{ marginLeft: 8 }}>{language.APPROVAL_DETAILS}</span>
                  </div>
                </AppBar>
                <div className="content" style={{ paddingTop: 35 }}>
                  <ListColumn
                    col={6}
                    items={[
                      {
                        label: language.DATE_CAD,
                        value: moment(value.date_cad).format('L')
                      },
                      {
                        label: language.STATUS,
                        value: 'Approved'
                      },
                      {
                        label: language.MOVIMENT,
                        value: (
                          value.tpi === 'in' ? (
                            <div className="titleDetailOs">
                              <div
                                className="upIcon"
                                style={{ width: 25, height: 25, marginTop: 3 }}
                              />
                              <p>{language.INPUT}</p>
                            </div>
                          ) : (
                            <div className="titleDetailOs">
                              <div
                                className="downIcon"
                                style={{ width: 25, height: 25, marginTop: 3 }}
                              />
                              <p>{language.OUTPUT}</p>
                            </div>
                          )
                        )
                      },
                      {
                        label: language.QUANTITY,
                        value: value.qtdinput
                      },
                      {
                        col: 12,
                        label: language.COMPL_INFO,
                        value: <span dangerouslySetInnerHTML={{ __html: value.additional_information || value.description }} />
                      }
                    ]}
                  />
                </div>
              </Card>
            </Grid>

          </Grid>

        </div>
      </PageLayout>
    );
  }
}
