import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'react-tippy';
import { Avatar } from '@material-ui/core';

export const generateApproversTooltip = (listOfApprovers) => {
  if (listOfApprovers === null || typeof listOfApprovers === 'string') {
    const normalizeUsers = (
      <div
        style={{ radius: 10, marginLeft: '5px', marginTop: '2px' }}
        className="d-flex align-items-center"
      >
        <Avatar
          className={classnames('avatar')}
          style={{
            fontSize: '10px',
            width: '17px',
            height: '17px',
            backgroundColor: 'orange',
          }}
        >
          {listOfApprovers === null
            ? 'Não informado'
            : listOfApprovers[0].toUpperCase()}
        </Avatar>
        <span style={{ color: 'black', marginLeft: '4px' }}>
          {listOfApprovers === null ? 'Não informado' : listOfApprovers}
        </span>
      </div>
    );

    return (
      <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 11 }}>{normalizeUsers}</div>
        }
        className="h-100"
        position="left"
        theme="light"
        interactive
      >
        <div className="d-flex align-items-center">
          <i class="far fa-user mr-1" />
          {listOfApprovers}
        </div>
      </Tooltip>
    );
  }

  if (Array.isArray(listOfApprovers) && listOfApprovers.length >= 1) {
    const normalizeUsers = listOfApprovers.map((userName) => (
      <div
        style={{ radius: 10, marginLeft: '5px', marginTop: '2px' }}
        className="d-flex align-items-center"
      >
        <Avatar
          className={classnames('avatar')}
          style={{
            fontSize: '10px',
            width: '17px',
            height: '17px',
            backgroundColor: 'orange',
          }}
        >
          {userName[0].toUpperCase()}
        </Avatar>
        <span style={{ color: 'black', marginLeft: '4px' }}>{userName}</span>
      </div>
    ));

    return (
      <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 11 }}>{normalizeUsers}</div>
        }
        className="h-100"
        position="left"
        theme="light"
        interactive
      >
        <div className="d-flex align-items-center">
          <i class="far fa-user mr-1" />
          {listOfApprovers[0]}
        </div>
      </Tooltip>
    );
  }

  return 'Indefinido';
};
