import React, {useState} from 'react';
import classnames from 'classnames';
import Store from '@data/Store';
import './index.css';

export default function({
	btnFilter=[],
	children,
	active,
	onChange=()=>null,
	btnFilterProps={},
	className,
	style={}
}){
   let [_active, setActive] = useState(false);
   let { COMMON_PAGE } = Store.theme;

	return(
		<header 
			className={classnames('sensrit-header content d-flex align-items-center', btnFilter.length?'justify-content-between':null, className)}
			style={{...style, background:COMMON_PAGE.bar}}
		>
			{children?
				children
				:
				<div></div>
			}
			{btnFilter.length?
				<div className="content-item-control d-flex align-items-center">
				  {btnFilter.map((e,i)=>(
				  	<a
				  		href="#" 
				  		key={(e.label||e)+'-'+i}
				  		className={
				  			classnames({
				  				active:((typeof active)=='number'?active:_active)===(e.value||i)
				  			})
				  		} 
				  		onClick={()=>{
				  			setActive(e.value||i);
				  			onChange(e.value||i);
				  		}}
				  		style={btnFilterProps.style}
				  	>
				  	  <span>{e.label||e}</span>
				  	</a>
				  ))}
				</div>
			:null}
		</header>
	)
}