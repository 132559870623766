import ReactHighcharts from 'react-highcharts';
import moment from 'moment';
// import highcharts3d from 'highcharts/highcharts-3d';
import { Component } from 'react';

// highcharts3d(ReactHighcharts.Highcharts);
/*const op = {
    
    chart: {
        backgroundColor: 'transparent',
        style: {
            fontFamily: '\'Unica One\', sans-serif'
        },
        plotBorderColor: '#606063'
    },
    title: {
        style: {
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '20px'
        }
    },
    subtitle: {
        style: {
            color: 'white',
            textTransform: 'uppercase'
        }
    },
    xAxis: {
        gridLineColor: 'rgba(250,250,250,.5)',
        labels: {
            style: {
                color: 'white'
            }
        },
        lineColor: 'rgba(250,250,250,.5)',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
            style: {
                color: '#A0A0A3'

            }
        }
    },
    yAxis: {
        gridLineColor: 'rgba(250,250,250,.5)',
        labels: {
            style: {
                color: 'white'
            }
        },
        lineColor: 'rgba(250,250,250,.5)',
        minorGridLineColor: '#505053',
        tickColor: 'rgba(250,250,250,.5)',
        tickWidth: 1,
        title: {
            style: {
                color: 'white'
            }
        }
    },
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#F0F0F0'
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: '#F0F0F3',
                style: {
                    fontSize: '13px'
                }
            },
            marker: {
                lineColor: '#333'
            }
        },
        boxplot: {
            fillColor: '#505053'
        },
        candlestick: {
            lineColor: 'white'
        },
        errorbar: {
            color: 'white'
        }
    },
    legend: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        itemStyle: {
            color: '#E0E0E3'
        },
        itemHoverStyle: {
            color: '#FFF'
        },
        itemHiddenStyle: {
            color: '#606063'
        },
        title: {
            style: {
                color: '#C0C0C0'
            }
        }
    },
    credits: {
        style: {
            color: '#666'
        }
    },
    labels: {
        style: {
            color: '#707073'
        }
    },

    drilldown: {
        activeAxisLabelStyle: {
            color: '#F0F0F3'
        },
        activeDataLabelStyle: {
            color: '#F0F0F3'
        }
    },

    navigation: {
        buttonOptions: {
            symbolStroke: '#DDDDDD',
            theme: {
                fill: '#505053'
            }
        }
    },

    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: '#505053',
            stroke: '#000000',
            style: {
                color: '#CCC'
            },
            states: {
                hover: {
                    fill: '#707073',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                },
                select: {
                    fill: '#000003',
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                }
            }
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
            backgroundColor: '#333',
            color: 'silver'
        },
        labelStyle: {
            color: 'silver'
        }
    },

    navigator: {
        handles: {
            backgroundColor: '#666',
            borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
            color: '#7798BF',
            lineColor: '#A6C7ED'
        },
        xAxis: {
            gridLineColor: '#505053'
        }
    },

    scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043'
    }
};*/

// Apply the theme
// ReactHighcharts.Highcharts.setOptions(op);
export default class ContractSupplier extends Component {
  config = (e) => {
    let { language, data, name, contract } = e;
    let m = moment().format('M');
    if (typeof data != 'string') {
      data = JSON.parse(JSON.stringify(data));
    } else {
      data = [];
    }
    let { contractprops } = this.props;

    let valuerealized = [];

    var color = [
      '#025EF0',
      '#02CBF0',
      '#02F0DE',
      '#02F079',
      '#A7F002',
      '#E7F002',
      '#F0B002',
      '#F05E02',
      '#F00282',
      '#9E02F0',
      '#025EF0',
      '#02CBF0',
    ];

    data.map((e) => {
      try {
        if (
          contract.contract_month != undefined &&
          contract.contract_month != null &&
          contract.contract_month.filter(
            (c) => parseInt(c.period) === parseInt(e[0])
          )
        ) {
          let valor = contract.contract_month.filter(
            (c) => parseInt(c.period) === parseInt(e[0])
          )[0];

          valuerealized.push({
            color: color[valuerealized.length],
            y: parseFloat(valor.valor),
          });
        } else {
          valuerealized.push({ color: color[valuerealized.length], y: 0 });
        }
      } catch (r) {
        valuerealized.push({ color: color[valuerealized.length], y: 0 });
      }
    });

    let sourceData = data;
    let month = moment().month();

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: "'Unica One', sans-serif",
        },
        height: 314,
        /*options3d: {
          enabled: true,
          alpha: 10,
          beta: 20,
          depth: 70
        }*/
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: contractprops.categories.reverse().slice(11 - month), // sourceData.map(e => e[0]).slice(11 - month),
        crosshair: false,
        labels: {
          style: {
            color: 'var(--primary)',
            fontSize: 8,
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: language.UTILIZATION,
          style: {
            fontFamily: 'Open Sans',
            color: 'var(--primary)',
            fontSize: 8,
          },
        },
        labels: {
          style: {
            color: 'var(--primary)',
            fontSize: 8,
          },
        },
      },
      tooltip: { shared: true },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },

        series: {
          borderRadius: 8,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });
            },
            borderWidth: 0,

            style: {
              fontSize: 8,
              color: 'var(--primary)',
              textShadow: false,
              textOutline: 0,
            },
          },
          marker: {},
        },
      },

      legend: {
        backgroundColor: 'transparent',
        enabled: false,
      },
      series: [
        {
          name: language.CONSUMED,
          data: contractprops.consumed
            .reverse()
            .map((e, i) => ({ color: color[i], y: e }))
            .slice(11 - month), // (contract.type =='hour'?  sourceData.map((e,i) => ({color:color[i], y:e[1]})):valuerealized).slice(11 - month),
          // data:[{color:color[0], y:12},{color:color[1], y:34},{color:color[2], y:56},{color:color[3], y:9},{color:color[4], y:86},{color:color[5], y:54}, {color:color[6], y:70},{color:color[7], y: 4},{color:color[8], y: 90}, {color:color[9], y:10}, {color:color[10], y:11}, {color:color[11], y:34}],

          tooltip: { valuePrefix: language.CURRENCY + ' ' },
        },
        {
          name: language.FORESEEN,
          data: contractprops.foreseen
            .reverse()
            .map((e, i) => ({ color: color[i], y: e }))
            .slice(11 - month), // (contract.type =='hour'?  sourceData.map((e,i) => ({color:color[i], y:e[1]})):valuerealized).slice(11 - month),
          // data:[{color:color[0], y:12},{color:color[1], y:34},{color:color[2], y:56},{color:color[3], y:9},{color:color[4], y:86},{color:color[5], y:54}, {color:color[6], y:70},{color:color[7], y: 4},{color:color[8], y: 90}, {color:color[9], y:10}, {color:color[10], y:11}, {color:color[11], y:34}],

          tooltip: { valuePrefix: language.CURRENCY + ' ' },
        },
        {
          name: language.REALIZED,
          data: contractprops.realized
            .reverse()
            .map((e, i) => ({ color: color[i], y: e }))
            .slice(11 - month), // (contract.type =='hour'?  sourceData.map((e,i) => ({color:color[i], y:e[1]})):valuerealized).slice(11 - month),
          // data:[{color:color[0], y:12},{color:color[1], y:34},{color:color[2], y:56},{color:color[3], y:9},{color:color[4], y:86},{color:color[5], y:54}, {color:color[6], y:70},{color:color[7], y: 4},{color:color[8], y: 90}, {color:color[9], y:10}, {color:color[10], y:11}, {color:color[11], y:34}],

          tooltip: { valuePrefix: language.CURRENCY + ' ' },
        },
      ],
    };
  };

  render() {
    let { contract, language } = this.props;
    return (
      <ReactHighcharts
        config={this.config({
          data: contract.trend,
          language,
          name: contract.name,
          contract: contract,
        })}
      />
    );
  }
}
