import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Paper } from '@material-ui/core';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import ChangeData from './ChangeData';
import ChangePassword from './ChangePassword';
import UserList from './UserList';
import UserForm from './UserForm';
import UserListContract from './UserListContract';
import GeneralUserList from './GeneralUserList';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
import { validaPass } from '../../util/ValidePassword';
import Dialog from '@common/Dialog';
import Store from '@data/Store';
import { setInjetSearchColumn, getInjetSearchColumn } from '@common/Func';
import DownloadFiles from '../../util/DownloadFiles';



@observer
export default class UserContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      index: 0,
      page: 'list',
      GeneralUserListModal: false,
      profilePhoto: '',
      email: null,
      companies: [],
      occupations: [],
      userItems: [],
      countTotal: 0,
      filter: {},
      currentPage : 0,
      sortElement : '',
      sortOrder : null
    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.callPagination = this.callPagination.bind(this);
    this.callDataFilter = this.callDataFilter.bind(this);
    this.downloadUsers = this.downloadUsers.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this)
    this.sortBy = this.sortBy.bind(this)

  }

  componentDidMount() {
    //this.getData();
    this.callPageSelectFilter(this.state.currentPage, this.state.filter, 75)

  }

  getData() {
    let col = [
      { key: 'status', label: this.store.language.STATE },
      { key: 'name', label: this.store.language.NAME },
      { key: 'email', label: this.store.language.EMAIL },
      { key: 'perfil', label: this.store.language.PROFILE },
      { key: 'company', label: this.store.language.COMPANY },
      { key: 'department', label: this.store.language.DEPARTMENT },
      { key: 'occupation', label: this.store.language.POSITION },
      { key: 'telefone', label: this.store.language.PHONE },
      { key: 'city', label: this.store.language.CITY },
      { key: 'sys_id', label: 'Sys Id' },
      { key: 'actions', label: this.store.language.ACTION },
    ];

    // Buscar filtro
    const objct = getInjetSearchColumn(
      window.location.pathname + 'GeneralUserList'
    );
    let valuesColumn = {};
    if (objct) {
      Object.keys(objct).forEach((item) => {
        const columnItem = item;
        valuesColumn = {
          ...valuesColumn,
          [columnItem]: objct[item],
        };
      });
    }

    const PAGE = window.location.pathname;
    if (PAGE == '/dash/changeData') {
      valuesColumn.fk_id_user = this.store.getUserLoged().id;
    }

    this.action
      .execute('post', `${this.APIEndpoints.USER}/all?limit=75&offset=0`, '', valuesColumn)
      .then((res) => {
        console.log(res);
        this.setState({
          userItems: res.data,
          countTotal: res.totalCount
        });
      });
  }

  getRole = () => {
    return this.props.role;
  };

  componentWillMount() {
    const PAGE = window.location.pathname;
    let role = this.getRole();
    let typeCompany = role === 'out' ? 2 : 1;
    if (window.location.href.indexOf('?') > -1) {
      this.setState({
        email: window.location.href.split('?')[1],
        GeneralUserListModal: true,
      });
    }
    if (PAGE == '/dash/creategeneraluser') {
      this.action
        .execute('get', this.APIEndpoints.DEPARTMENT, 'departments');
    }
  }

  setProfilePhoto = () => {
    this.redirect('/dash');
  };

  onFormSubmit(values, page) {
    if (page === 'changePass') {
      if (validaPass(values.password, this.store)) {
        this.action.execute(
          'post',
          this.APIEndpoints.PASSWORD,
          'userLoged',
          values,
          null,
          this.store.language.CHANGE_PASSWORD_SUCCESS,
          true
        )
        .catch((e) => {
          setTimeout(() => {
            if (e.data.code == 'E001') {
              this.store.toggleAlert(
                true,
                this.store.language.ERROR_CODE.E001,
                'error'
              );
            } else if (e.data.code == 'E002') {
              this.store.toggleAlert(
                true,
                this.store.language.ERROR_CODE.E002,
                'error'
              );
            } else {
              this.store.toggleAlert(
                true,
                this.store.language.CHANGE_PASSWORD_SUCCESS,
                'success'
              );
            }
          }, 1000);
        });
      }
    } else if (page === 'changeData') {
      this.action
        .execute(
          'post',
          this.APIEndpoints.CHANGE_DATA,
          'userLoged',
          values,
          null,
          this.store.language.CHANGE_DATA_SUCCESS,
          true
        )
        .then((i) => {});
    } else {
      let method = values.hasOwnProperty('id_user') ? 'put' : 'post';
      let url = values.hasOwnProperty('id_user')
        ? this.APIEndpoints.USER + '/' + values.id_user
        : this.APIEndpoints.USER;
      values.url = window.location.origin;
      this.action
        .execute(method, url, 'users', values, 'id_user')
        .then((e) => {
          this.callPageSelectFilter(this.state.currentPage, this.state.filter, 75)

          // this.getData();
          this.redirect2('/dash/configuration/users');
        })
        .catch((e) => {
          if (e.data.code == 'E409')
            this.store.toggleAlert(
              true,
              `Falha ao ${
                method == 'post' ? 'cadastrar' : 'editar'
              } usuário: email já cadastrado`,
              'error'
            );
            this.callPageSelectFilter(this.state.currentPage, this.state.filter, 75)

            //this.getData();
        });
    }
  }

  downloadUsers() {
    this.store.toggleAlert(true, 'Export dos dados sendo gerado', 'success')
    this.action
      .execute(
        'post',
        this.APIEndpoints.USER + '/exportusers',
        '',
        this.state.filter
      )
      .then((res) => {
        this.store.toggleAlert(true, 'Export dos dados gerado', 'success')

        DownloadFiles(
          this.APIEndpoints.USER +
            '/downloadusersexport/' +
            res.data.file,
            res.data.file
        );
      });
  }

  onDelete() {

    let { values } = this.state;
    this.action.execute(
      'delete',
      this.APIEndpoints.USER + '/' + values.id_user,
      'users',
      null,
      'id_user'
    );
    this.handlePage('list');
    this.redirect('/dash/configuration/users');

  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  callPagination(page) {}

  sortBy(item, order ){

    this.setState({sortElement : item, sortOrder : order})
    setTimeout(() => {
      this.callPageSelectFilter(this.state.currentPage, this.state.filter, 75)
    }, 50)
  }
  cleanFilters () {
    this.callPageSelectFilter()
  }
  callPageSelectFilter(page = 0, items = {}, rowPerPage = 75, type) {
    this.setState({
      filter: items,
      currentPage : page
    });
    let limit = rowPerPage;

    const isEmpty = Object.keys(items).length === 0;
    let sortBy = {item : this.state.sortElement, sort : this.state.sortOrder}
    items.sortBy = sortBy
    let offset = page * limit;
    //  let offset = 0;
    items.listUsers = true;
    if (!isEmpty) {
      this.action
        .execute('post', `${this.APIEndpoints.USER}/all?offset=${offset}&limit=${limit}`, '', items)
        .then((res) => {
          this.setState({
            userItems: res.data,
            countTotal: res.totalCount
          });
        });
    } else {
      this.action
        .execute('post', `${this.APIEndpoints.USER}/all?offset=${offset}&limit=${limit}`, '', items)
        .then((res) => {
          this.setState({
            userItems: res.data,
            countTotal: res.totalCount
          });
        });
    }
  }

  callDataFilter(items = {}) {}

  deleteProfilePhoto = () => {
    this.action
      .execute(
        'post',
        this.APIEndpoints.USER + '/delete/profilephoto',
        '',
        {},
        null,
        null,
        false
      )
      .then((data) => {
        if (data) {
          this.setProfilePhoto();
        }
      });
  };

  sendCrop = (file, crop = {}) => {
    if (file && !!Object.keys(crop).length) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        const values = { file: reader.result, ...crop };
        this.action
          .execute('post', this.APIEndpoints.USER + '/profilephoto', '', values)
          .then((data) => {
            if (data) {
              this.setProfilePhoto();
            }
          });
      });
    }
  };

  render() {
    let {
      language,
      contracts,
      contract,

      departments,
      profilePhoto,
    } = this.store;
    let { page, values, companies, occupations } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    let { state, pathname } = history.location;

    let role = this.getRole();
    let { configurationProps, type_config } = this.props;

    var PG = testRoute(['/dash/changeData', '/dash/changePass'], pathname)
      ? CommonPage
      : Fragment;

    return (
      <PG
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={true}
        iconAlert={true}
        loading={false}
      >
        {testRoute('/dash/changeData', pathname) && (
          <Animate>
            <ChangeData
              redirect={redirect}
              store={this.store}
              userItems={this.state.userItems}
              language={language}
              onFormSubmit={this.onFormSubmit}
              sendCrop={this.sendCrop}
              profilePhoto={profilePhoto}
              deleteProfilePhoto={this.deleteProfilePhoto}
            />
          </Animate>
        )}

        {testRoute('/dash/changePass', pathname) && (
          <Animate>
            <ChangePassword
              redirect={redirect}
              language={language}
              onFormSubmit={this.onFormSubmit}
            />
          </Animate>
        )}

        {type_config == '/dash/generaluser' && (
          <Animate>
            <GeneralUserList
              configurationProps={configurationProps}
              language={language}
              countTotal={this.state.countTotal}
              callPagination={this.callPagination}
              callDataFilter={this.callDataFilter}
              callPageSelectFilter={this.callPageSelectFilter}
              cleanFilters = {this.cleanFilters}
              sortBy = {this.sortBy}
              userItems={this.state.userItems}
              companies={companies}
              countries={this.store.countries}
              departments={departments}
              occupations={this.state.occupations}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              contract={contract}
              redirect={redirect}
              values={this.state.email ? { email: this.state.email } : null}
              perfil={this.store.perfil}
              email={this.state.email}
              contracts={contracts}
              store={this.store}
              GeneralUserListModal={this.state.GeneralUserListModal}
              toggleModal={(open) => {
                this.setState({ GeneralUserListModal: open });
              }}
              downloadUsers={this.downloadUsers}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        )}

        {type_config == '/dash/user/sponsor' && (
          <Animate>
            <UserListContract
              contract={contract}
              contracts={contracts}
              language={language}
              handlePage={this.handlePage}
              onFormSubmit={this.onFormSubmit}
              store={this.store}
              configurationProps={configurationProps}
              role={role}
              redirect={redirect}
            />
          </Animate>
        )}
        {testRoute('/dash/user/outsourced', pathname) && (
          <Animate>
            <UserList
              contract={contract}
              contracts={contracts}
              language={language}
              handlePage={this.handlePage}
              onFormSubmit={this.onFormSubmit}
              store={this.store}
              role={role}
              redirect={redirect}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        )}

        {['sponsor/new', 'outsourced/new', 'outsourced/edit'].includes(
          page
        ) && (
          <Dialog
            title={
              values && role === 'solicitant'
                ? language.EDIT_SOLICITANT
                : values && role === 'tech'
                ? language.EDIT_TECH
                : !values && role === 'solicitant'
                ? language.ADD_SOLICITANT
                : !values && role === 'tech'
                ? language.ADD_TECHS
                : values && role === 'out'
                ? language.EDIT_OUTSOURCED
                : !values && role === 'out'
                ? language.ADD_OUTSOURCED
                : !values && role === 'sponsor'
                ? language.ADD_SPONSOR
                : values.name
                ? language.EDIT_SPONSOR
                : ''
            }
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="lx"
          >
            <UserForm
              {...this.state}
              values={values}
              language={language}
              history={history}
              handlePage={this.handlePage}
              redirect={redirect}
              onFormSubmit={this.onFormSubmit}
              store={this.store}
              role={role}
              APIEndpoints={this.APIEndpoints}
            />
          </Dialog>
        )}

        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={this.state.values.name}
          />
        ) : null}
      </PG>
    );
  }
}
