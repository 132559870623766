import React from 'react';
import CommonPage from '@common/CommonPage';
import {
  Grid,
  Card,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Chip
} from '@material-ui/core';
import PageLayout from '@common/PageLayout';
import MarketingStrategy from '@images/marketing-strategy.png';
import './ModelCanvas.css';
import moment from 'moment';

import { observer } from 'mobx-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Tooltip } from 'react-tippy';
import RaisedButton from '@common/RaisedButton';
import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import CommonForm from '@common/CommonForm2';
import CommonDelete from '../../common/CommonDelete';
import CommonDelete2 from '@common/CommonDelete2';
import Dialog from '@common/Dialog';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AppBar2 from '@common/AppBar2';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
    secondary: { main: '#DAA520' }
  }
});

function importAll(r) {
  return r.keys().map(r);
}
const allImgs = importAll(
  require.context('@images/ModelCanvas/', false, /\.(png|jpe?g|svg)$/)
);

function findImg(name) {
  return allImgs.find(e => e.indexOf(name) != -1);
}

function Header({
  title,
  subTitle,
  icon,
  iconProps,
  titleProps,
  subTitleProps,
  add,
  ...other
}) {
  return (
    <AppBar2
      title={title}
      subTitle={subTitle}
      titleSize={0.8}
      subTitleSize={0.7}
      {...other}
    >
      {add ? (
        <Button
          icon="fas fa-plus"
          color="primary"
          variant="circle"
          className="button-add-canvas"
          onClick={add}
          size={0.7}
          style={{
            position: 'relative',
            top: -7,
            borderRadius: 5
          }}
        />
      ) : null}
    </AppBar2>
  );
}
const ArrayLevel = ['Baixo', 'Médio', 'Alto'];
@observer
export default class ModelCanvasContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      itemOpen: {},
      modalOpen: false,
      type: '',
      level: false,
      deleteItem: false,
      valuesDel: '',
      values: '',
      modalOpenHist: false,
      arrayhist: [],
      modal: {
        info: false,
        delete: false,
        edit: false,
        add: false
      }
    };
    this.sendValues = this.sendValues.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  /*handleModal2 = (value, type, level, open=false)=>{
    let modal = this.state.modal;
    modal[value] = open;
    this.setState({modal})
	
  var other = { type: type, values: values}

  if(level != undefined){
    other.level = level;
  }
    this.setState(other);
  }*/
  handleModal = (open = false, type, level, values) => {
    //console.log(level);
    if (level != undefined) {
      this.setState({
        modalOpen: open,
        type: type,
        level: level,
        values: values
      });
    } else {
      this.setState({ modalOpen: open, type: type, values: values });
    }
  };
  handleClick = (event, item = {}) => {
    this.setState({ anchorEl: (event || {}).currentTarget });
    this.setState({ itemOpen: item });
  };

  handleModalHistClose = open => {
    this.setState({ modalOpenHist: false });
  };
  handleModalHist = (open = false, type, level, id) => {
    let itens = this.store.canvas.TbCanvasHistorics.filter(
      g => g.namecanvas == type && g.fk_id == id
    ).map(g => (
      <Card className="content mb-1 canvas-body">
        {level == true ? <p>{ArrayLevel[g.level]} </p> : null}

        <p>{moment(g.dt_cad).format('L')} </p>
        <p
          dangerouslySetInnerHTML={{
            __html: g.name
          }}
          className="font fontAll my-0"
        ></p>
      </Card>
    ));

    if (level != undefined) {
      this.setState({
        modalOpenHist: true,
        type: type,
        level: level,
        arrayhist: itens
      });
    } else {
      this.setState({ modalOpenHist: true, type: type, arrayhist: itens });
    }
  };

  revision = (open = false, type, level, id) => {
    try {
      return (
        'V.' +
        this.store.canvas.TbCanvasHistorics.filter(
          g => g.namecanvas == type && g.fk_id == id
        ).length +
        1
      );
    } catch (e) { }
  };

  handleModalDeleted = (open = false, type, values) => {
    values.type = type;
    this.setState({
      deleteItem: open,
      type: type,
      valuesDel: values
    });
  };
  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.CANVAS + 'GetAllCanvas',
      'canvas'
    );
  }

  onDelete() {
    this.action.execute(
      'post',
      this.APIEndpoints.CANVAS + 'DeleteCanvas',
      'canvas',
      this.state.valuesDel
    );
    this.setState({ modalOpen: false, deleteItem: false });
  }

  sendValues(values) {
    values.type = this.state.type;

    this.action.execute(
      'post',
      this.APIEndpoints.CANVAS + 'SaveCanvas',
      'canvas',
      values
    );
    this.setState({ modalOpen: false, deleteItem: false });
  }

  render() {
    let {
      modalOpen,
      values,
      modalOpenHist,
      arrayhist,
      anchorEl,
      itemOpen
    } = this.state;
    let { language, canvas } = this.store;

    let datamapping = [];
    let datapriorization = [];

    // datapriorization.push( );

    canvas.TbRiskMapping &&
      canvas.TbRiskMapping.map(g =>
        datamapping.push({
          content: (
            <MuiThemeProvider theme={theme}>
              <div className="d-flex align-items-center justify-content-between">
                <Tooltip
                  arrow={true}
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>
                        {g.level == 1
                          ? language.TICKET_PRIORITY.SMALL
                          : g.level == 2
                            ? language.TICKET_PRIORITY.MEDIUM
                            : g.level == 3
                              ? language.TICKET_PRIORITY.HIGH
                              : ''}
                      </strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <Button
                    color={
                      g.level == 1
                        ? 'success'
                        : g.level == 2
                          ? 'warning'
                          : g.level == 3
                            ? 'danger'
                            : 'light'
                    }
                    variant="circle"
                    style={{ fontSize: 11, borderRadius: 3 }}
                    className="button-control-canvas mx-1"
                  />
                </Tooltip>
              </div>
            </MuiThemeProvider>
          ),
          item: (
            <Tooltip
              arrow={true}
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>
                    {g.name.toString().replace(/<\/?[^>]+(>|$)/g, '')}
                  </strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div className='d-flex align-items-center justify-content-between'>
                <div className="ellipsis table-content" style={{ maxWidth: 170 }}>
                  {this.revision(true, 'mapping', undefined, g.id_risk_mapping) +
                    ' - ' +
                    g.name.toString().replace(/<\/?[^>]+(>|$)/g, '')}
                </div>
                <IconButton
                  aria-label="More"
                  aria-owns={
                    anchorEl ? 'long-menu' : undefined
                  }
                  aria-haspopup="true"
                  style={{
                    transform: 'rotate(90deg)',
                    padding: 1
                  }}
                  onClick={evt => this.handleClick(evt, { ...g, pt: 'mapping' })}
                >
                  <MoreVertIcon
                    style={{ color: 'var(--success)', fontSize: 24 }}
                  />
                </IconButton>
              </div>

            </Tooltip>
          ),

        })
      );

    canvas.TbPrioritizations &&
      canvas.TbPrioritizations.map(g =>
        datapriorization.push({
          content: (
            <MuiThemeProvider theme={theme}>
              <div className="d-flex align-items-center justify-content-between">
                <Tooltip
                  arrow={true}
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>
                        {g.level == 1
                          ? language.TICKET_PRIORITY.SMALL
                          : g.level == 2
                            ? language.TICKET_PRIORITY.MEDIUM
                            : g.level == 3
                              ? language.TICKET_PRIORITY.HIGH
                              : ''}
                      </strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <Button
                    color={
                      g.level == 1
                        ? 'success'
                        : g.level == 2
                          ? 'warning'
                          : g.level == 3
                            ? 'danger'
                            : 'light'
                    }
                    variant="circle"
                    style={{ fontSize: 11, borderRadius: 3 }}
                    className="button-control-canvas mx-1"
                  />
                </Tooltip>
              </div>
            </MuiThemeProvider>
          ),
          item: (
            <Tooltip
              arrow={true}
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>
                    {g.name.toString().replace(/<\/?[^>]+(>|$)/g, '')}
                  </strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div className='d-flex align-items-center justify-content-between'>
                <div className="ellipsis table-content" style={{ maxWidth: 170 }}>
                  {this.revision(
                    true,
                    'priorization',
                    undefined,
                    g.id_prioritizations
                  ) +
                    ' - ' +
                    g.name.toString().replace(/<\/?[^>]+(>|$)/g, '')}
                </div>
                <IconButton
                  aria-label="More"
                  aria-owns={
                    anchorEl ? 'long-menu' : undefined
                  }
                  aria-haspopup="true"
                  style={{
                    transform: 'rotate(90deg)',
                    padding: 1
                  }}
                  onClick={evt => this.handleClick(evt, { ...g, pt: 'priorization' })}
                >
                  <MoreVertIcon
                    style={{ color: 'var(--success)', fontSize: 24 }}
                  />
                </IconButton>
              </div>


            </Tooltip>
          ),

        })
      );

    let listOptions = [
      { value: 1, label: language.TICKET_PRIORITY.SMALL },
      { value: 2, label: language.TICKET_PRIORITY.MEDIUM },
      { value: 3, label: language.TICKET_PRIORITY.HIGH }
    ];

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        <PageLayout
          title="IT Canvas"
          subTitle={language.CIO_COCKPIT + '/'}
          rightElements={[]}
        >
          <div style={{ marginTop: '-1rem' }}>
            <Card id="ModelCanvasContainer">
              <AppBar2 title={language.MANAGEMENT_INFORMATION_TABLE} />
              <div style={{ padding: 10 }} className="pt-0">
                <Grid container spacing={2}>
                  {/*---------------------------------BLOCO-1----------------------------------------*/}
                  <Grid
                    item
                    xs={12}
                    style={{ flexBasis: '20%', maxWidth: '20%', }}
                  >
                    <Card className="content-grid bloco-1">
                      <Header
                        title={language.STRATEGIC_ALIGMENT}
                        subTitle={language.COMPANY_OBJECTIVE}
                        icon="strategic_alignment"
                      />

                      <PerfectScrollbar style={{ height: '535px' }}>
                        <div className="content px-2 pt-0">
                          {canvas.StrategicAlignment &&
                            canvas.StrategicAlignment.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, g)}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'strategic_alignments',
                                          undefined,
                                          g.id_strategic_alignments
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() =>
                                this.handleModal(true, 'strategic_alignments')
                              }
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ flexBasis: '20%', maxWidth: '20%' }}
                  >
                    {/*---------------------------------BLOCO-2----------------------------------------*/}
                    <Card className="content-grid bloco-2">
                      <Header
                        title={language.STRATEGIC_PARTNERS}
                        subTitle={language.CANVAS.BUSINESS_PARTHER_CONTRACTS}
                        icon="strategic_partners"
                      />
                      <PerfectScrollbar style={{ height: 225 }}>
                        <div className="content px-2 pt-0">
                          {canvas.TbStrategicPartner &&
                            canvas.TbStrategicPartner.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'strategic_partners' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'strategic_partners',
                                          undefined,
                                          g.id_strategic_partners
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() =>
                                this.handleModal(true, 'strategic_partners')
                              }
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                    {/*---------------------------------BLOCO-3----------------------------------------*/}
                    <Card className="content-grid bloco-3 mt-2">
                      <Header
                        title={language.CANVAS.CRITICAL_SERVICES}
                        subTitle={language.CANVAS.CRITICAL_PROCESS_IT_SUPPORTS}
                        icon="critical_services"
                      />
                      <PerfectScrollbar style={{ height: 223.5 }}>
                        <div className="content px-2 pt-0">
                          {canvas.TbCriticalService &&
                            canvas.TbCriticalService.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'critical_services' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'critical_services',
                                          undefined,
                                          g.id_critical_services
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() =>
                                this.handleModal(true, 'critical_services')
                              }
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>

                  {/*---------------------------------BLOCO-4----------------------------------------*/}
                  <Grid
                    item
                    xs={12}
                    style={{ flexBasis: '20%', maxWidth: '20%' }}
                  >
                    <Card className="content-grid bloco-4">
                      <Header
                        title={language.CANVAS.IT_OBJECTIVE}
                        subTitle={
                          language.CANVAS.ACTIONS_SUPPORT_COMPANY_STRATEGY
                        }
                        icon="IT_goal"
                      />
                      <PerfectScrollbar style={{ height: 535 }}>
                        <div className="content px-2 pt-0">
                          {canvas.TbItObjective &&
                            canvas.TbItObjective.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-'2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'it_objectives' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'it_objectives',
                                          undefined,
                                          g.id_it_objective
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() =>
                                this.handleModal(true, 'it_objectives')
                              }
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ flexBasis: '20%', maxWidth: '20%' }}
                  >
                    {/*---------------------------------BLOCO-5----------------------------------------*/}
                    <Card className="content-grid bloco-5">
                      <Header
                        title={language.CANVAS.RISK_MAPPING}
                        subTitle={language.CANVAS.ITEMS_IMPACTS_BUSSINES}
                        icon="risk_mapping"
                        add={() => this.handleModal(true, 'mapping', true)}
                        dividerProps={{ className: 'mb-0' }}
                      />
                      <PerfectScrollbar style={{ height: 225 }}>
                        <div>
                          <CommonTable
                            fluid
                            width="100%"
                            bar={false}
                            pagination={false}
                            col={[
                              {
                                key: 'content',
                                label: language.THREAT,
                                thConfig: true,
                                style: { width: '1%' }
                              },
                              {
                                key: 'item',
                                label: 'Item',
                                thConfig: true,
                                style: { width: '99%' }
                              }
                            ]}
                            data={datamapping}
                          />
                        </div>
                      </PerfectScrollbar>
                    </Card>
                    {/*---------------------------------BLOCO-6----------------------------------------*/}
                    <Card className="content-grid bloco-6 mt-2">
                      <Header
                        title={language.CANVAS.PRIORITIZATION}
                        subTitle={language.CANVAS.ITEMS_SUPPORT_GOALS}
                        icon="priority"
                        add={() => this.handleModal(true, 'priorization', true)}
                        dividerProps={{ className: 'mb-0' }}
                      />
                      <PerfectScrollbar style={{ height: 223.5 }}>
                        <div>
                          <CommonTable
                            fluid
                            width="100%"
                            bar={false}
                            pagination={false}
                            col={[
                              {
                                key: 'content',
                                label: language.THREAT,
                                thConfig: true,
                                style: { width: '1%' }
                              },
                              {
                                key: 'item',
                                label: 'Item',
                                thConfig: true,
                                style: { width: '99%' }
                              }
                            ]}
                            data={datapriorization}
                          />
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                  {/*---------------------------------BLOCO-7----------------------------------------*/}
                  <Grid
                    item
                    xs={12}
                    style={{ flexBasis: '20%', maxWidth: '20%' }}
                  >
                    <Card className="content-grid bloco-7">
                      <Header
                        title="KPI´s"
                        subTitle={language.CANVAS.INDICATOR_TYPES}
                        icon="kpi"
                      />
                      <PerfectScrollbar style={{ height: 535 }}>
                        <div className="content px-2 pt-0">
                          {canvas.TbKpi &&
                            canvas.TbKpi.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'kpis' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'strategic_alignments',
                                          undefined,
                                          g.id_kpis
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() => this.handleModal(true, 'kpis')}
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="mt-2">
                  {/*---------------------------------BLOCO-8----------------------------------------*/}
                  <Grid item xs={4}>
                    <Card className="content-grid bloco-8 border-top">
                      <Header
                        title={language.CANVAS.INVESTMENTS + ' - CAPEX'}
                        subTitle={language.CANVAS.PLANNED_INVESTIMENTS}
                        icon="capex"
                      /> 
                      <PerfectScrollbar style={{ height: 229.5 }}>
                        <div className="content px-2 pt-0 content-canvas-50">
                          {canvas.TbInvestmentsCapex &&
                            canvas.TbInvestmentsCapex.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'investment' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'strategic_alignments',
                                          undefined,
                                          g.id_investments_capex
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() => this.handleModal(true, 'investment')}
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                  {/*---------------------------------BLOCO-9----------------------------------------*/}
                  <Grid item xs={4}>
                    <Card className="content-grid bloco-9 border-top">
                      <Header
                        title={language.CANVAS.EXPENSES + ' - OPEX'}
                        subTitle={language.CANVAS.PLANED_REQUIRED_EXPENSES}
                        icon="opex"
                      />
                      <PerfectScrollbar style={{ height: 229.5 }}>
                        <div className="content px-2 pt-0 content-canvas-50">
                          {canvas.TbExpensesOpex &&
                            canvas.TbExpensesOpex.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'expensesopex' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'strategic_alignments',
                                          undefined,
                                          g.id_expenses_opex
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() =>
                                this.handleModal(true, 'expensesopex')
                              }
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                  {/*---------------------------------BLOCO-10----------------------------------------*/}
                  <Grid item xs={4}>
                    <Card className="content-grid bloco-10 border-top">
                      <Header
                        title={language.CANVAS.COMMUNICATION_RESULTS}
                        subTitle={language.CANVAS.HOW_RESULTS_MONITORED}
                        icon="communication_results"
                      />
                      <PerfectScrollbar style={{ height: 229.5 }}>
                        <div className="content px-2 pt-0 content-canvas-50">
                          {canvas.TbCommunicationAndResult &&
                            canvas.TbCommunicationAndResult.map(g => (
                              <Card className="mb-2 canvas-body">
                                <div className="d-flex aling-items-center justify-content-between content-card-divisor">
                                  <div className="w-100 py-2 content-medium">
                                    <div className="d-flex align-items-start justify-content-between">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: g.name
                                        }}
                                        className="font fontAll my-0"
                                      />
                                      <IconButton
                                        aria-label="More"
                                        aria-owns={
                                          anchorEl ? 'long-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        style={{
                                          transform: 'rotate(90deg)',
                                          padding: 1
                                        }}
                                        onClick={evt => this.handleClick(evt, { ...g, pt: 'comunication' })}
                                      >
                                        <MoreVertIcon
                                          style={{ color: 'white' }}
                                        />
                                      </IconButton>
                                    </div>

                                    <p className="date-version font fontAll my-0 mt-3 d-flex align-items-center justify-content-between">
                                      <span>
                                        {moment(g.created_at).format('L H:m:s')}
                                      </span>
                                      <span>
                                        {this.revision(
                                          true,
                                          'strategic_alignments',
                                          undefined,
                                          g.id_communication_and_results
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Card>
                            ))}
                          <div className="content mb-2 canvas-body-add">
                            <Button
                              icon="fas fa-plus"
                              color="default"
                              className="button-add"
                              variant="circle"
                              onClick={() =>
                                this.handleModal(true, 'comunication')
                              }
                            />
                          </div>
                        </div>
                      </PerfectScrollbar>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Card>

            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => this.handleClick()}
            >
              {[
                {
                  icon: 'fas fa-pencil-alt',
                  label: 'Editar',
                  color: 'var(--success)'
                },
                {
                  icon: 'fas fa-trash-alt',
                  label: 'Excluir',
                  color: 'var(--danger)'
                }
              ].map((e, i) => (
                <MenuItem
                  onClick={() => {
                    this.setState({ anchorEl: null });
                    if (i == 0) {
                      this.handleModal(
                        true,
                        (itemOpen || {}).pt || 'strategic_alignments',
                        undefined,
                        itemOpen
                      );
                    } else if (i == 1) {
                      this.handleModalDeleted(
                        true,
                        (itemOpen || {}).pt || 'strategic_alignments',
                        itemOpen
                      );
                    }
                  }}
                >
                  <i className={e.icon} style={{ color: e.color }} />
                  <span
                    style={{ fontSize: 15, color: 'var(--primary)' }}
                    className="ml-2"
                  >
                    {e.label}
                  </span>
                </MenuItem>
              ))}
            </Menu>

            <CommonDelete2
              onDelete={this.onDelete}
              open={this.state.deleteItem}
              onClose={() => {
                this.setState({ deleteItem: false, modalOpen: false });
              }}
              title={language.CONFIRM_DELETE.replace(
                'item',
                ['mapping', 'priorization'].includes(this.state.type)
                  ? 'item'
                  : 'card'
              )}
              message={language.CONFIRM_DELETE_BODY.replace(
                'item',
                ['mapping', 'priorization'].includes(this.state.type)
                  ? 'item'
                  : 'card'
              )}
            />

            <Dialog
              open={modalOpen}
              title={language.EXECUTION}
              onClose={() => this.handleModal(false)}
            >
              <div>
                {this.state.level == true ? (
                  <CommonForm
                    values={values}
                    fields={[
                      {
                        col: 12,
                        type: 'select',
                        name: 'level',
                        options: listOptions,
                        label: 'Level'
                      },
                      {
                        col: 12,
                        type: 'textEditor',
                        name: 'name',
                        placeholder: language.DESCRIPTION
                      }
                    ]}
                    onSubmit={e => this.sendValues(e)}
                  />
                ) : (
                  <CommonForm
                    values={values}
                    fields={[
                      {
                        col: 12,
                        type: 'textEditor',
                        name: 'name',
                        placeholder: language.TITLE
                      }
                    ]}
                    beforeButtonElement={
                      <Button
                        icon="fas fa-trash-alt"
                        color="danger"
                        outlined
                        style={{ border: 'none' }}
                        variant="circle"
                        className="mr-3"
                        onClick={() => this.handleModalDeleted(true, '', values)}
                      />
                    }
                    onSubmit={e => this.sendValues(e)}
                  />
                )}
              </div>
            </Dialog>

            <Dialog
              open={this.state.modalOpenHist}
              title={'Canvas'}
              onClose={() => this.handleModalHistClose(false)}
            >
              {arrayhist}
            </Dialog>
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}
