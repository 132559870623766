import React from 'react';

export const TitleSlash = ({ children }) => {
  return (
    <div class="tw-relative tw-my-4">
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center">
        <span class="tw-w-full tw-border-t"></span>
      </div>
      <div class="tw-relative tw-flex tw-justify-center tw-text-xs tw-uppercase">
        <span class="tw-px-2 default-color tw-bg-white tw-font-bold">
          {children}
        </span>
      </div>
    </div>
  );
};
