import React from 'react';
import { Tooltip } from 'react-tippy';
/*-----------------------------------------common-------------------------------------------*/
import List from '@common/List';
import AppBar from '@common/AppBar2';
/*-----------------------------------------material-ui-------------------------------------------*/
import { Card } from '@material-ui/core';
import Store from '@data/Store';

const { language } = Store;

const Label = (Label) => (
	<Tooltip
		html={
			<div style={{ color: '#fff', fontSize: 15 }}>
				{Label}
			</div>
		}
		arrow={true}
		position={'right'}
		theme="dark"
		className='w-100'
	>
		<span className='ellipsis d-block'>{Label}</span>
	</Tooltip>
)

export default function ({ data = [], redirect2 }) {
	let _data = data.sort((a, b) => b[7] - a[7]);
	console.log(_data);
	return (
		<Card id='Ranking' style={{overflow:'visible'}}>
			<div style={{
				top: 10,
				position: 'sticky',
				zIndex: 1,
				backgroundColor: 'white'
			}}>
				<AppBar title={language.RANKING_TOP_10} />
				<List
					hover={false}
					scroll={false}
					scrollProps={{
						className: 'content py-0'
					}}
					items={[
						{
							className: 'list-items-header',
							grid: [
								{
									col: .5,
									className: 'item',
									content: ''
								},
								{
									col: .5,
									className: 'item',
									content: Label('ID'),
								},
								{
									col: 1,
									className: 'item',
									content: Label('Real ID'),
								},
								{
									col: 2,
									className: 'item',
									content: Label(language.PROJECT),
								},
								{
									col: 2,
									className: 'item',
									content: Label(language.PROJECTS_GOAL),
								},
								{
									col: 2,
									className: 'item',
									content: Label(language.PROJECT_BENEFIT),
								},
								{
									col: 1,
									className: 'item',
									content: Label(language.AVERAGE_TIME),
								},
								{
									col: 1,
									className: 'item',
									content: Label(language.EXPECTED_COST),
								},
								{
									col: 1,
									className: 'item',
									content: Label(language.PROJECT_PRIORITY),
								},
								{
									col: 1,
									className: 'item',
									content: Label(language.TOTAL	),
								},
							]
						}
					]}
				/>
			</div>
			<List
				scroll={false}
				hover={false}
				scrollProps={{
					className: 'content pt-0'
				}}
				items={_data.map((e, i) => ({
					className: 'list-items-body',
					onClick:() => redirect2('/dash/gp/'+e[0]),
					grid: [
						{
							col: .5,
							className: 'item',
							content: Label((i + 1) + '°'),
						},
						{
							col: .5,
							className: 'item',
							content: Label((e[0]).toString().padStart(2, 0)),
						},
						{
							col: 1,
							className: 'item',
							content: Label(e[1]),
						},
						{
							col: 2,
							className: 'item',
							content: Label(e[2]),
						},
						{
							col: 2,
							className: 'item',
							content: Label(e[3]),
						},
						{
							col: 2,
							className: 'item',
							content: Label(e[4]),
						},
						{
							col: 1,
							className: 'item',
							content: Label(e[5]),
						},
						{
							col: 1,
							className: 'item',
							content: Label(e[6]),
						},
						{
							col: 1,
							className: 'item',
							content: Label(e[7]),
						},
						{
							col: 1,
							className: 'item',
							content: (
								<div className='d-flex align-items-center'>
									<span className='ellipsis'>{e[8] + " pts"}</span>
									{i == 0 && <i className="fas fa-trophy" />}
								</div>
							),
						},
					]
				}))
				}
			/>
		</Card>
	)
}
