import Constants from "../../../data/Constants";
import Actions from '@data/Actions';

const action = new Actions();

export const getGroupDefaultFALE = async () => {
  try {
    return action
      .execute('get', `${Constants.APIEndpoints.GENERAL_PARAMETERS}`)
      .then((res) => {
        return res.data.fk_id_group_default_fale;
      });
  } catch (e) {
    console.log('Error:', e);
  }
};
