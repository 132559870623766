import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { Card, Toggle } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import CommonForm from '../../common/CommonForm';
import './SiteList.css';
@observer
export default class SiteList extends Component {
  render() {
    let {
      language,
      handlePage,
      redirect,
      onDelete,
      site,
      device,
      group,
      rel,
      onFormSubmit,
      contracts
    } = this.props;

    let values = '';
    let col = [
      { key: 'ID', label: 'ID', style: { width: 20, textAlign: 'center' } },
      { key: 'name', label: language.NAME, style: { width: 20, textAlign: 'center' } },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' }
      }
    ];

    let responsibleOptions = [];
    if (group != undefined) {
      responsibleOptions = group.map(c => ({
        value: c.id_group_black_list,
        text: c.name
      }));
    }

    let optioncontracts = [];
    if (contracts != undefined) {
      optioncontracts = contracts
        .filter(c => c.fk_id_device === device.id_device)
        .map(c => ({ value: c.id_contract, text: c.name }));
    }
    values = [{ fk_id_group_black_list: 0, fk_id_device: device.id_device }];

    let fields = [
      {
        span: 3,
        name: 'fk_id_group_black_list',
        label: language.GROUP,
        type: 'select',
        options: responsibleOptions,
        require: true
      },
      {
        span: 3,
        name: 'id_contract',
        label: language._CONTRACT,
        type: 'select',
        options: optioncontracts,
        require: true
      }
    ];
    let button = { md: 12, label: language.SAVE, primary: true };
    let data = [];
    if (rel != undefined) {
      data = rel.map(obj => {
        return {
          ID: obj.id_rel_group_black_list,
          name: obj.name,

          action: (
            <Row>
              <Col md={4}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.REMOVE}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="trashIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </Tooltip>
              </Col>
            </Row>
          )
        };
      });
    }

    return (
      <PageLayout
        icon={<div className="companyIcon" style={{ width: 40, height: 40 }} />}
        title={'Site Black List'}
        subtitle={'Site Black List'}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            color='primaryGradient'
            label={language.BACK}
            primary={true}
            onClick={() => redirect('/dash/site')}
          />
        ]}
      >

        <div id="SiteList">
          <p> {device.name + '#'} </p>
          <paper>
            <CommonForm
              values={values}
              language={language}
              fields={fields}
              onFormSubmit={onFormSubmit}
              button={button}
            />
          </paper>
          <CommonTable
            title={''}
            index='SiteList'
            language={language}
            columns={col}
            data={data}
            searchColumn orderColumn
          />

        </div>
      </PageLayout>
    );
  }
}
