import CommonForm from '@common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import Button from '@common/Button';
import RaisedButton from '../../common/RaisedButton';
import Dialog from '@common/Dialog';
import React, { useEffect, useState, Fragment } from 'react';
import { Chip, Container } from '@material-ui/core';
import CommonDelete from '../../common/CommonDelete';
import RelationshipTree from './RelationshipTree';

const action = new Actions();
const language = Store.language;
const ResolutionCategoryContainer = ({}) => {
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState('');
  const [values, setValues] = useState({});
  const [global, setGlobal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0)
  const [filters, setFilters] = useState([])
  const [sortElement, setSortElement] = useState('')
  const [sortOrder, setSortOrder] = useState(null)
  useEffect(() => {
    callPageSelectFilter();
  }, []);

  const sortBy = (item, order ) =>{
    setSortElement(item)
    setSortOrder(order)
    setTimeout(() => {
      callPageSelectFilter(currentPage, filters, 75)
    }, 50)
  }
  const callPageSelectFilter = (page = 0, items = {}, totalPerPage = 75) => {
    setCurrentPage(page)
    setFilters(items)
    let sortBy = {item : sortElement, sort :sortOrder}
    items.sortBy = sortBy
    let offset = page * totalPerPage;

    action
      .execute('post', `${Constants.APIEndpoints.RESOLUTION_CATEGORY}/filter?limit=${totalPerPage}&offset=${offset}` , '', items)
      .then((res) => {
        setTotalData(res.data[0] ? res.data[0].total_count : 0)
        setData(res.data);
      });
  };

  const handlePage = (page, obj = {}, global = false) => {
    setPage(page);
    setValues(obj);
    setGlobal(global);
  };

  const saveResolution = (values) => {
    values.is_global = global;
    const method = values.id_resolution_category ? 'put' : 'post';
    action
      .execute(method, Constants.APIEndpoints.RESOLUTION_CATEGORY, '', values)
      .then((res) => {
        handlePage('list');
        callPageSelectFilter(currentPage, filters, 75)
      });
  };

  const deleteResolution = () => {
    action
      .execute(
        'delete',
        Constants.APIEndpoints.RESOLUTION_CATEGORY +
          '/' +
          values.id_resolution_category,
        ''
      )
      .then((res) => {
        handlePage('list');
        callPageSelectFilter()
      });
  };

  const importFiles = (values) => {
    let filesSend = [];
    let cont = 0;
    if (values.file && values.file.length > 0) {
      let total = Array.from(values.file).length;
      Array.from(values.file).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
            filesSend = [];
            cont = 0;
            setTimeout(() => {
              handlePage('list'), 1000;
            });
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.file = filesSend;

            action
              .execute('post', `${Constants.APIEndpoints.RESOLUTION_CATEGORY}/import`, '', values)
              .then((data) => {
                setTimeout(() => {
                  callPageSelectFilter()
                  handlePage('list'), 1000;
                });
              });
          }
        });
      });
    }
  };

  const cols = [
    { key: 'company', label: language.COMPANY },
    { key: 'active', label: language.ACTIVE },
    { key: 'type', label : language.TYPE},
    { key: 'level', label: language.LEVEL },
    { key: 'name', label: language.NAME },
    { key: 'value', label: language.VALUE },
    { key: 'action', label: language.ACTION },
  ];
  const getType = [
    '',
    language.INCIDENT,
    language.REQUEST
  ]
  const dataTable = data.map((d) => {
    return {
      company: d.company,
      active: d.active ? language.YES : language.NOT,
      type: getType[d.type],
      level : d.level,
      name : d.name,
      value : d.value,
      action: (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <RaisedButton
                  circleButton
                  icon="fas fa-edit"
                  label={language.EDIT}
                  color="default"
                  style={{ fontSize: 12, width: 21, height: 21 }}
                  className="mr-2"
                  onClick={() =>
                    handlePage('add', d, d.fk_id_company == 0 ? true : false)
                  }
                />
                <RaisedButton
                  circleButton
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => handlePage('remove', d)}
                />
              </div>
            }
          />
        </React.Fragment>
      ),
    };
  });
  return (
    <div>
      <CommonTable
        paginationTop={false}
        beforeBar={
          <div>
            <Button
              variant="circle"
              icon="fas fa-plus"
              color="warning"
              label={language.ADD}
              className="mr-3"
              onClick={() => handlePage('add', {})}
            />
            <Button
              variant="circle"
              icon="fas fa-tree"
              color="warning"
              label={language.CONFIGURE_RELATIONSHIP_TREE}
              className="mr-3"
              onClick={() => handlePage('tree', {})}
            />
            <Button
              variant="circle"
              icon="fas fa-file-excel"
              color="warning"
              label={language.IMPORT}
              className="mr-3"
              onClick={() => handlePage('import', {})}
            />
            <Button
              variant="circle"
              icon="fas fa-globe"
              label={language.ADD_GLOBAL}
              color="warning"
              className="mr-3"
              onClick={() => handlePage('add', {}, true)}
            />
          </div>
        }
        countTotal={totalData}
        isAutomaticPagination={false}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
        onClearAllFilter={() => callPageSelectFilter()}
        loadSearch={true}
        searchColumn={true}
        data={dataTable}
        col={cols}
        noFilter = {true}
        orderColumn
        onSortItem={(item, sortByStatus) => {
          sortBy(item, sortByStatus);
        }}
      />
      {page == 'add' && (
        <Dialog
          open={true}
          onClose={() => handlePage('list')}
          title={language.ADD}
        >
          <CommonForm
            fields={[
              {
                col: 5,
                type: 'autocomplete',
                name: 'fk_id_company',
                label: language.COMPANY,
                method: 'POST',
                route: `${Constants.APIEndpoints.COMPANY}/filter`,
                fieldquery: 'search',
                textlabel: language.COMPANY,
                textinit: values.company ? values.company : '',
                fieldvaluedb: 'id_company',
                fieldlabeldb: 'name',
                idinit: values.fk_id_company ? values.fk_id_company : 0,
                routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                required: global == true ? false : true,
                visible: global == true ? false : true,
              },
              {
                col: 2,
                label: language.ACTIVE,
                type: 'switch',
                name: 'active',
              },
              {
                col: 4,
                label: language.TYPE,
                type: 'select',
                name: 'type',
                required : true,
                options: [
                  {value: 1, label : language.INCIDENT},
                  {value: 2, label : language.REQUEST}
                ]
              },
              {
                col: 6,
                label: language.LEVEL,
                name: 'level',
                type: 'select',
                options: [
                  { value: 1, label: 'N1' },
                  { value: 2, label: 'N2' },
                  { value: 3, label: 'N3' },
                  { value: 4, label: 'N4' },
                ],
                required: true,
              },

              {
                col: 6,
                type: 'text',
                name: 'name',
                label: language.NAME,
              },
              {
                col: 6,
                type: 'text',
                name: 'value',
                label: language.VALUE,
              },
            ]}
            values={values}
            onSubmit={saveResolution}
          />
        </Dialog>
      )}
      {page === 'remove' ? (
        <CommonDelete
          language={language}
          closeModal={() => handlePage('list')}
          onDelete={deleteResolution}
          message={values.name}
        />
      ) : null}

      {page === 'import' && (
        <Dialog
          title={language.IMPORT}
          open={true}
          onClose={() => handlePage('list')}
        >
          <CommonForm
            fields={[
              {
                col: 6,
                name: 'file',
                label: language.ATTACH,
                type: 'file',
                customPortalCss: true,
              },
              {
                col: 6,
                type: 'autocomplete',
                name: 'fk_id_company',
                label: language.COMPANY,
                method: 'POST',
                route: `${Constants.APIEndpoints.COMPANY}/filter`,
                fieldquery: 'search',
                textlabel: language.COMPANY,
                textinit: '',
                fieldvaluedb: 'id_company',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                showOptionGlobal: true,
                required: true,
              },
            ]}
            onSubmit={importFiles}
          />
        </Dialog>
      )}

      {page === 'tree' && (
        <Dialog
          title={language.CONFIGURE_RELATIONSHIP_TREE}
          open={true}
          onClose={() => handlePage('list')}
        >
          <RelationshipTree
            action={action}
            Constants={Constants}
            language={language}
            handlePage={handlePage}
          />
        </Dialog>
      )}
    </div>
  );
};
export default ResolutionCategoryContainer;
