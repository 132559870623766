import React, { memo, useState, Fragment } from 'react';
/* ---------------------common------------------ */
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Progress from '@common/Progress';
/* ---------------------material-ui------------------ */

import DialogTicket from '../DialogTicket';
import jsonFormat from '../jsonFormat';

import Store from '@data/Store';
const { language } = Store;

export default function View2({
  tickets,
  projects,
  users,
  gmuds,
  os,
  redirect,
}) {
  let [idTicket, setIdTicket] = useState(0);
  //----as regras de negócio de cada módulo fazer dentro do jsonFormat----
  let data = jsonFormat.table({ tickets, projects, users, gmuds, os });

  return (
    <Fragment>
      <DialogTicket
        open={!!idTicket}
        onClose={() => setIdTicket(0)}
        idTicket={idTicket}
      />
      <CommonTable
        bar={false}
        col={[
          {
            key: 'action',
            label: language.ACTION,
            thConfig: true,
            style: { width: '1%' },
          },
          {
            key: 'status',
            label: language.STATE,
            thConfig: true,
            style: { width: '5%' },
          },
          {
            key: 'id',
            label: language.id,
            thConfig: true,
            style: { width: '1%' }
          },
          {
            key: 'date',
            label: language.DATE,
            thConfig: true,
            style: { width: '10%' },
          },
          {
            key: 'hour',
            label: language.HOUR,
            thConfig: true,
            style: { width: '10%' },
          },
          {
            key: 'demand_type',
            label: language.TYPE_OF_DEMAND,
            thConfig: true,
            style: { width: '10%' },
          },
          {
            key: 'module',
            label: language.MODULE,
            thConfig: true,
            style: { width: '14%' },
          },
          {
            key: 'user',
            label: language.USER,
            thConfig: true,
            style: { width: '14%' },
          },
          {
            key: 'description',
            label: language.DESCRIPTION,
            thConfig: true,
            style: { width: '25%' },
          },
        ]}
        data={data.map((e) => ({
          action: (
            <MenuTooltip
              options={[
                // {value:1, label:'Editar', icon:'fas fa-pencil-alt', color:'var(--success)'},
                // {value:2, label:'Excluir', icon:'fas fa-trash-alt', color:'var(--danger)'},
                {
                  value: 2,
                  label: language.VIEW,
                  icon: 'fas fa-eye',
                  color: 'var(--primary)',
                },
              ]}
              onChange={(val) => {
                if (e.type == 1) {
                  redirect('/dash/gp/' + e.id_project, {
                    id: e.id,
                    openView: true,
                    pj: e.pj,
                  });
                } else {
                  setIdTicket(e.idTicket);
                }
              }}
            />
          ),
          status: (
            <div className="d-flex align-items-center" style={{ width: 75 }}>
              <Progress
                background="var(--success)"
                hiddenText
                current={e.status}
                style={{ width: '100%', height: 7 }}
              />
              <span style={{ fontSize: 10, color: '#707070' }} className="ml-1">
                {e.status}%
              </span>
            </div>
          ),
          id: e.id,
          date: e.date,
          hour: e.hour,
          demand_type: e.demand_type,
          module: e.module,
          user: e.user,
          description: e.description,
        }))}
      />
    </Fragment>
  );
}
