import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import UsbIcon from '@material-ui/icons/Usb';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RaisedButton from '../../common/RaisedButton';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import CommonPage from '../../common/CommonPage';
import FaTimesCircle from 'react-icons/lib/fa/times-circle';
import moment from 'moment';
import Constants from '../../data/Constants';
import { MenuItem, Select, IconButton, Menu, Fade } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';

import { Tooltip } from 'react-tippy';
import '../../bClass.css';
let host = '';
let tokenApi = '';
var getConnection = '';

const tenant = Constants.Tenant;
export default class Link extends CommonPage {
  constructor() {
    super();
    this.state = {
      mount: true,
      available: true,
      hold: '',
      values: {},
      icmp: [],
      hold: '',
      aliveHold: '',
      seq: 0,
      open: false
    };

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event, v) {

    this.setState({ anchorEl: event.currentTarget, open: v });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  componentWillMount() {


    try {
      this.props.ioLinks.on(`_${tenant}_${this.props._device.id_device}`, (data) => {

        this.setState({ icmp: data.data })
        //console.log(data.data)
        // let hold = setTimeout(() =>  (1000));
        // this.setState({available: false, hold: hold});
      })
    } catch (e) { }

  }


  render() {
    let {
      _device,
      handlePage,
      language,
      handleModal,
      onDettachDevice,
      setId,
      setDevice,
      anchorEl,
      setAnchorEl
    } = this.props;
    let { values, available, icmp, seq, open } = this.state;

    return (

      <Row
        className={
          values.down || icmp[0] <= -1 || icmp[0] == "-1" || icmp[0] == undefined
            ? 'row flipContainer down'

            : 'row flipContainer down_up '
        }
      >
        <div className="online" style={{
          width: '100%',
          paddingLeft: '30px',
          backgroundColor: '#FBFBFB',
          paddingBottom: 2
        }}>


          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center mr-2' style={{ width: '48%', marginLeft: -11 }}>

              <i style={{ color: 'var(--danger)' }} class="fas fa-link indicator-icon ml-1 mr-3 "></i>
              <div className='d-flex justify-content-between align-items-center' style={{ width: '73%' }}>
                <div style={{ width: '100%' }}>
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{_device != undefined ? _device.name : ''}</strong>
                      </div>
                    }
                    arrow={true}
                    position={"right"}
                    theme="dark"

                  >
                    <div
                      style={{ textTransform: 'uppercase', fontWeight: 600, fontSize: 13, maxWidth: '100%' }}
                      className='ellipsis'
                    >
                      {_device != undefined ? _device.name : ''}
                    </div>
                  </Tooltip>
                  <div style={{ fontSize: 11, fontWeight: 600 }}>{_device != undefined ? _device.ip : null}</div>
                  <div style={{ fontSize: 11, fontWeight: 600 }}>{"R.Time: " + icmp.length === 0 ? 0 : icmp[0]} ms</div>
                </div>
              </div>
            </div>
            {(typeof icmp) == 'object' &&
              <LinkGraph data={icmp} />
            }


            {/* 
         <IconButton
                color="secondary"
                
                aria-owns={anchorEl ? 'USER' : undefined}
                aria-haspopup="true"
                onClick={event =>{
                   setAnchorEl(event.currentTarget);
                    setId(_device.id_device);
                    setDevice(_device);
                  }}
                aria-label="Add an alarm"
                style={{padding:0}}
                className='mr-2'
                style={{
                  transform: 'rotate(90deg)'
                }}
                >
                

<MoreVertIcon style={{ color: 'var(--success)' }} />
              </IconButton>
  
  */}

          </div>
          {


          }
        </div>
      </Row>
    );
  }
}

class LinkGraph extends Component {
  constructor() {
    super();
    this.state = {
      color: '#33ABA0'
    };
  }

  componentWillReceiveProps(nextProps) {
    try {
      let arr = nextProps.data;
      let zarr = [];
      let x = 10;

      for (let j = 0; j < 6; j++) {
        let narr = [];
        let i = j * 10
        for (i; i < x; i++) {
          narr.push(arr[i]);
        }
        x = i + 10;
        zarr.push(narr.reduce((sum, a) => {
          return sum + a;
        }, 0) / (narr.length || 1));
      }
      let average = zarr.reduce((sum, a) => {
        return sum + a;
      }, 0) / (zarr.length || 1);

      var danger = 0;
      var warning = 0;
      zarr.map(function (val) {
        // if(val > nextProps.triggers.icmp.danger.value) danger++;
        //if(val > nextProps.triggers.icmp.warning.value) warning++;
        if (val > 90) danger++;
        if (val > 80) warning++;
      });

      if (warning == 6 && danger < 6) this.state.color = '#FFC107';
      else if (danger == 6) this.state.color = '#F44336';
      else this.state.color = '#33ABA0';
    } catch (e) {

    }
  }
  render() {
    let { data } = this.props;
    let { color } = this.state;

    if (data == undefined) {
      data = [];
    }
    return (
      data.length > 0 && data[0] > -1 ?
        <Sparklines style={{ width: '230px' }} data={data}>
          <SparklinesLine color={color} />
          <SparklinesSpots />
        </Sparklines> :
        <Sparklines style={{ width: '230px' }} data={[0, 0, 0, 0, 0, 0, 0]}>
          <SparklinesLine color={color} />
          <SparklinesSpots />
        </Sparklines>

    );
  }
}

