import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, Typography } from '@material-ui/core';

import Header from '../../common/Header';
import { MaskMoney } from '../../common/Mask';
import Help from '../../common/components/Help';
import CommonPage from '../../common/CommonPage';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm2';
import RaisedButton from '../../common/RaisedButton';
import DownloadFiles from '../../util/DownloadFiles';

@observer
export default class DeviceFormIcmdb extends CommonPage {
  constructor() {
    super();

    this.state = {
      type: 'server',
      connection: '',
      company: 0,
      user: '',
      fk_id_manufacture: '',
      departmentOptions: [],
      data: [],
    };
  }

  componentWillMount() {
    this.action
      .execute(
        'get',
        this.APIEndpoints.DEVICE + '/' + this.props.values.id_device + '/cmdb',
        ''
      )
      .then((item) => {
        this.setState({ data: item.data });
      });
  }

  render() {
    let { language, typeDevice } = this.props;

    let fields = [
      {
        type: 'element',
        visible: typeDevice === 'APPLICATION',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              fontWeight: 600,
              textTransform: 'uppercase',
              marginBottom: '14px',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.APPLICATION}
          </span>
        ),
      },
      {
        col: 3,
        name: 'app_port',
        visible: typeDevice === 'APPLICATION',
        label: language.CMDB_APP_PORT,
        type: 'text',
      },
      {
        col: 3,
        name: 'app_name',
        visible: typeDevice === 'APPLICATION',
        label: language.CMDB_APP_NAME,
        type: 'text',
      },

      {
        type: 'element',
        visible: typeDevice === 'ASSET',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.ASSET}
          </span>
        ),
      },

      {
        col: 3,
        name: 'asset_dc_asset',
        visible: typeDevice === 'ASSET',
        label: language.CMDB_ASSET_DC,
        type: 'text',
      },
      {
        col: 3,
        name: 'asset_collocation',
        label: language.CMDB_ASSET_COLLOCATION,
        visible: typeDevice === 'ASSET',
        type: 'text',
      },
      {
        col: 3,
        name: 'serial_number',
        label: language.CMDB_SERIAL_NUMBER,
        visible: typeDevice === 'ASSET',
        type: 'text',
      },

      {
        col: 3,
        name: 'asset_asset_tag',
        label: language.CMDB_ASSET_TAG,
        visible: typeDevice === 'ASSET',
        type: 'text',
      },
      {
        col: 3,
        name: 'asset_asset_owner',
        label: language.CMDB_ASSET_OWNER,
        visible: typeDevice === 'ASSET',
        type: 'text',
      },
      {
        col: 3,
        name: 'asset_asset_type',
        label: language.CMDB_ASSET_TYPE,
        visible: typeDevice === 'ASSET',
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'BATCHJOB',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              fontWeight: 600,
              marginBottom: '14px',
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB_BATCHJOB}
          </span>
        ),
      },
      {
        col: 3,
        name: 'batchjob_conf_dir',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_CONF_DIR,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_ins_dir',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_INS_DIR,
        type: 'text',
      },

      {
        col: 3,
        name: 'batchjob_int_ref',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_INT_REF,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_justify',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_JUSTIFY,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_run_proc_com',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_RUN_PROC_COM,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_run_proc_com_hash',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_RUN_PROC_COM_HASH,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_run_proc_key_param',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_RUN_PROC_KEY_PARAM,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_run_proc_key_param_hash',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_RUN_PROC_KEY_PARAM_HASH,
        type: 'text',
      },
      {
        col: 3,
        name: 'batchjob_tcp_ports',
        visible: typeDevice === 'BATCHJOB',
        label: language.CMDB_BATCHJOB_TCP_PORTS,
        type: 'text',
      },
      {
        type: 'element',
        visible: true, //typeDevice  === 'CI_BASE',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.CI_BASE}
          </span>
        ),
      },
      {
        col: 3,
        name: 'ci_base_code',
        label: language.CMDB_CI_BASE_CODE,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_status',
        label: language.CMDB_CI_STATUS,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_assign_group',
        label: language.CMDB_CI_BASE_ASSIGN_GROUP,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },

      {
        col: 3,
        name: 'ci_base_version',
        label: language.CMDB_CI_BASE_VERSION,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },

      {
        col: 3,
        name: 'ci_base_supported',
        label: language.CMDB_CI_BASE_SUPPORTED,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_acn',
        label: language.CMDB_CI_BASE_ACN,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_env',
        label: language.CMDB_CI_BASE_ENV,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_oper_status',
        label: language.CMDB_CI_BASE_OPER_STATUS,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_cdpt',
        label: language.CMDB_CI_BASE_CDPT,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },

      {
        col: 3,
        name: 'ci_base_fqdn',
        label: language.CMDB_CI_BASE_FQDN,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_sup_group',
        label: language.CMDB_CI_BASE_SUP_GROUP,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_ci_type',
        label: language.CMDB_CI_BASE_CI_TYPE,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_location',
        label: language.CMDB_CI_BASE_LOCATION,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_base_oper_date',
        label: language.CMDB_CI_BASE_OPER_DATE,
        visible: true, //typeDevice  === 'CI_BASE',
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'DATABASE',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.DATABASE}
          </span>
        ),
      },
      {
        col: 3,
        name: 'db_tcp',
        label: language.CMDB_DB_TCP,
        visible: typeDevice === 'DATABASE',
        type: 'text',
      },
      {
        col: 3,
        name: 'db_instance',
        label: language.CMDB_DB_INSTANCE,
        visible: typeDevice === 'DATABASE',
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'IP',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.IP}
          </span>
        ),
      },
      {
        col: 3,
        name: 'ip_owner',
        label: language.CMDB_IP_OWNER,
        visible: typeDevice === 'IP',
        type: 'text',
      },
      {
        col: 3,
        name: 'ip_managed_group',
        label: language.CMDB_IP_MANAGED_GROUP,
        visible: typeDevice === 'IP',
        type: 'text',
      },
      {
        col: 3,
        name: 'ip_type',
        label: language.CMDB_IP_TYPE,
        visible: typeDevice === 'IP',
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'SERVER',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.SERVER}
          </span>
        ),
      },
      {
        col: 3,
        visible: typeDevice === 'SERVER',
        name: 'srv_os',
        label: language.CMDB_SRV_OS,
        type: 'text',
      },
      {
        col: 3,
        visible: typeDevice === 'SERVER',
        name: 'srv_localization',
        label: language.CMDB_SRV_LOCALIZATION,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_is_virtual',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_IS_VIRTUAL,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_host',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_HOST,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_object_id',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_OBJECT_ID,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_host_id',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_HOST_ID,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_cpu',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_CPU,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_ram',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_RAM,
        type: 'text',
      },
      {
        col: 3,
        name: 'srv_disk_space',
        visible: typeDevice === 'SERVER',
        label: language.CMDB_SRV_DISK_SPACE,
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'CI_BUSINESS_SERVICE',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.CI_BUSINESS_SERVICE}
          </span>
        ),
      },
      {
        col: 3,
        name: 'ci_bs_service_name',
        label: language.CMDB_CI_BS_SERVICE_NAME,
        visible: typeDevice === 'CI_BUSINESS_SERVICE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_bs_business_criticality',
        label: language.CMDB_CI_BS_BUSINESS_CRITICALITY,
        visible: typeDevice === 'CI_BUSINESS_SERVICE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_bs_business_impact',
        label: language.CMDB_CI_BS_BUSINESS_IMPACT,
        visible: typeDevice === 'CI_BUSINESS_SERVICE',
        type: 'text',
      },
      {
        col: 3,
        name: 'ci_bs_business_process',
        label: language.CMDB_CI_BS_BUSINESS_PROCESS,
        visible: typeDevice === 'CI_BUSINESS_SERVICE',
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'MONITORING',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              fontWeight: 600,
              marginBottom: '14px',
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.MONITORING}
          </span>
        ),
      },
      {
        col: 3,
        name: 'mon_policy_id',
        visible: typeDevice === 'MONITORING',
        label: language.CMDB_MON_POLICY_ID,
        type: 'text',
      },
      {
        col: 3,
        name: 'mon_tool',
        visible: typeDevice === 'MONITORING',
        label: language.CMDB_MON_TOOL,
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'BACKUP',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.BACKUP}
          </span>
        ),
      },
      {
        col: 3,
        name: 'bkp_client_host',
        visible: typeDevice === 'BACKUP',
        label: language.CMDB_BKP_CLIENT_HOST,
        type: 'text',
      },
      {
        col: 3,
        visible: typeDevice === 'BACKUP',
        name: 'bkp_master_client',
        label: language.CMDB_BKP_MASTER_CLIENT,
        type: 'text',
      },
      {
        col: 3,
        name: 'bkp_job_typ',
        visible: typeDevice === 'BACKUP',
        label: language.CMDB_BKP_JOB_TYP,
        type: 'text',
      },
      {
        col: 3,
        name: 'bkp_schedules',
        visible: typeDevice === 'BACKUP',
        label: language.CMDB_BKP_SCHEDULES,
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'REFERENCE_ID',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.REFERENCE_ID}
          </span>
        ),
      },
      {
        col: 3,
        name: 'ref_id',
        visible: typeDevice === 'REFERENCE_ID',
        label: language.CMDB_REF_ID,
        type: 'text',
      },
      {
        col: 3,
        name: 'ref_tool',
        visible: typeDevice === 'REFERENCE_ID',
        label: language.CMDB_REF_TOOL,
        type: 'text',
      },
      {
        type: 'element',
        visible: typeDevice === 'ENDPOINT',
        content: (
          <span
            style={{
              display: 'block',
              color: 'var(--primary)',
              marginBottom: '14px',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderBottom: '2px solid var( --primary)',
            }}
          >
            {language.CMDB.ENDPOINT}
          </span>
        ),
      },
      {
        col: 3,
        name: 'endpoint_port',
        visible: typeDevice === 'ENDPOINT',
        label: language.CMDB_ENDPOINT_PORT,
        type: 'text',
      },
      {
        col: 3,
        name: 'endpoint_protocol',
        visible: typeDevice === 'ENDPOINT',
        label: language.CMDB_ENDPOINT_PROTOCOL,
        type: 'text',
      },
      {
        col: 3,
        name: 'used_for',
        label: language.USED_FOR,
        type: 'text',
      },
      {
        col: 3,
        name: 'escalation_information',
        label: language.ESCALATION_INFORMATION,
        type: 'text',
      },
      {
        col: 6,
        type: 'select',
        name: 'criticalities',
        isMulti: true,
        label: language.CRITICALITY,
        options: [
          { label: language.CRITICAL, value: 1 },
          { label: language.NON_CRITICAL, value: 2 },
        ],
        disabled: true
      },
      {
        col: 6,
        type: 'select',
        name: 'priorities',
        isMulti: true,
        label: language.PRIORITY,
        options: [
          { label: language.LOW, value: 1 },
          { label: language.MEDIUM, value: 2 },
          { label: language.HIGH, value: 3 }
        ],
        disabled: true
      },
    ];

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      icon: 'fas fa- save',
      style: { float: 'right', fontSize: 15 },
    };
    return (
      <Card className="content">
        <CommonForm fields={fields} button={button} values={this.state.data} />
      </Card>
    );
  }
}
