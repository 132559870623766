import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';

// ----------------------------------------------------------------------

export const Container = styled(Paper)`
  ${() => css`
    margin-top: 20px;
  `}
`;

export const CommonFormContainer = styled('div')`
  ${() => css`
    padding: 0 20px;
  `}
`;
