export default function ({ language, company, contracts, budgetcontrols }) {
  return {
    type(filter = () => true) {
      return [
        { value: 1, label: 'Despesa' },
        { value: 2, label: 'Investimento' }
      ].filter(filter);
    },
    company(filter = () => true) {
      return company
        .filter(filter)
        .map((e) => ({ value: e.id_company, label: e.name }));
    },
    type_expenditure(filter = () => true) {
      return [
        { value: 1, label: 'Capex' },
        { value: 2, label: 'Opex' },
      ].filter(filter);
    },
    period(filter = () => true) {
      return language.DATE_LANG.monthLongList
        .filter(filter)
        .map((e, i) => ({ value: i + 1, label: e }));
    },
    contract(filter = () => true) {
      return contracts
        .filter(filter)
        .map((e) => ({ value: e.id_contract, label: e.name }));
    },
    status() {
      return [
        { value: 1, label: language.CONTRACTED },
        { value: 2, label: language.PROVISIONED },
      ];
    },
  };
}
