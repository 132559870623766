import React from 'react';
import { Card, Grid } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import PerfectScrollbar from 'react-perfect-scrollbar';

import AppBar from '../../common/AppBar2';
import Button from '../../common/Button';
import CommonForm from '../../common/CommonForm2';

import Header from './Header';
import { Capacity } from './Graphs';

export default function ({
  language,
  CapacityData = {},
  categories,
  CapacityDataYear = {},
}) {
  return (
    <Card className="h-100">
      <AppBar title={language.CAPACITY} />
      <div className="content pt-0">
        <div className="mt-3">
          <ReactHighcharts
            config={Capacity({
              categories: categories,
              data: CapacityData,

            })}
          />
          <hr className="my-0" style={{ borderColor: 'var(--primary)' }} />
          <PerfectScrollbar
            className="scrollVisible"
            style={{
              width: '43vw',
            }}
            option={{
              suppressScrollY: true,
              suppressScrollX: false,
            }}
          ></PerfectScrollbar>
        </div>
      </div>
    </Card>
  );
}
