import Actions from '@data/Actions';
import Constants from '@data/Constants';

// ----------------------------------------------------------------------

export const getChannelsService = async () => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute('get', `${APIEndpoints}/channels`, '');

  const normalizedChannels =
    data.map((channel) => {
      const { id_channel, is_attachment_required, name } = channel;

      return {
        id_channel,
        name,
        is_attachment_required,
      };
    }) || [];

  return normalizedChannels;
};

export const getChannelByIdService = async ({ id }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute(
    'get',
    `${APIEndpoints}/channel/${id}`,
    ''
  );

  return data[0];
};

export const deleteChannelByIdService = async ({ id }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'delete',
    `${APIEndpoints}/channel/${id}`,
    ''
  );

  return result;
};

export const addChannelService = async ({ values }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'post',
    `${APIEndpoints}/channel`,
    '',
    values
  );

  return result;
};

export const editChannelService = async ({ id, values }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const result = await action.execute(
    'put',
    `${APIEndpoints}/channel/${id}`,
    '',
    values
  );

  return result;
};
