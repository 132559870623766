import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../../common/CommonPage';

import Calendario from './Calendario';

@observer
export default class CalendarioContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      openmodal: false,
    };
  }

  render() {
    let { language, store } = this.store;
    let { CALENDAR } = this.store.theme;

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        <Calendario
          theme={CALENDAR}
          store={this.store}
          redirect={this.redirect}
          APIEndpoints={this.APIEndpoints}
          language={language}
          action={this.action}
        />
      </CommonPage>
    );
  }
}
