import React, { memo, useState, useEffect } from 'react';
import classnames from 'classnames';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MaskNumber } from '@common/Mask';

let delayTimer;
export default memo(({
	f,
	classes,
	statusError,
	individual,
	variant,
	margin,
	_value,
	_handleValue,
	errors
}) => {
	let [value, setValue] = useState('');

	function handleValue(val, name) {
		setValue(val);

		clearTimeout(delayTimer);

		delayTimer = setTimeout(() => {
			_handleValue(val, f.name)
		}, 200);
	}
	useEffect(() => {
		setValue(_value);
	}, [_value])

	return (
		<TextField
			type={f.type}
			error={statusError[errors[f.name]] || f.error}
			label={f.label}
			fullWidth={true}
			value={value || ''}
			onChange={evt => handleValue(evt.target.value, f.name)}
			onKeyDown={f.onKeyDown || undefined}
			onKeyUp={f.onKeyUp}
			helperText={!individual ? (statusError[errors[f.name]] || f.help || ' ') : undefined}
			variant={f.variant || variant || 'outlined'}
			margin={f.margin || margin || 'dense'}
			disabled={f.disabled}
			required={f.required}
			placeholder={f.placeholder}
			className={'input-min ' + f.className}
			InputLabelProps={{
				classes: {
					root: classes.label
				}
			}}
			InputProps={{
				classes: {
					underline: classes.underline
				},
				startAdornment: (
					(f.startIcon || f.startOutput) &&

					<InputAdornment position="start">
						{(typeof f.startIcon) == 'string' ? (
							<i className={classnames(f.startIcon, 'color-icon adormentIcon')}></i>
						) : f.startIcon}

						{(f.startOutput) && (
							<output className='only-text tw-ml-4'>{f.startOutputFormat?.(value) ?? value}</output>
						)}
					</InputAdornment>
				),
				endAdornment: (
					f.endIcon &&

					<InputAdornment position="start">
						{(typeof f.endIcon) == 'string' ? (
							<i className={classnames(f.endIcon, 'color-icon adormentIcon')}></i>
						) : f.endIcon}
					</InputAdornment>
				),
				...f.InputProps,
				...(f.format ? {

					id: {
						format: f.format,
						mask: f.mask || '_',
						placeholder: f.placeholder,
						change: f.change
					},
					inputComponent: MaskNumber
				} : {})
			}}
			inputProps={{
				...f.inputProps
			}}
		/>
	)
})