import React from "react";
import NumberFormat from 'react-number-format';

export function MaskNumber(props) {
  const { inputRef, id = {}, onChange, ...other } = props;

  // console.log(props);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        var { change } = id;
        onChange({
          target: {
            value: change ? change(values) : values.value,
          },
        });
      }}
      {...id}
    />
  );
}
export function MaskTel(props) {
  const { inputRef, id = {}, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      format="(##) #####-####"
      mask="_"
      placeholder="(00) 00000-0000"
      {...id}
    />
  );
}

export function MaskMoney(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      thousandSeparator={'.'}
      decimalSeparator={','}
      prefix="R$ "
      fixedDecimalScale={true}
      isNumericString={true}
    />
  );
}
