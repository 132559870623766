import RaisedButton from '@common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, Step, Stepper, StepLabel } from '@material-ui/core';
import PageLayout from '@common/PageLayout';
import Help from '@common/components/Help';
import CommonForm from '@common/CommonForm2';
import CommonPage from '../../common/CommonPage';
import AddActivitiesForm from './AddActivitiesForm';
import { concatDateHour, moment } from '../../util/dateTime';
import { testRoute } from '@common/Func';

@observer
export default class ServiceOrderForm extends CommonPage {
  constructor() {
    super();
    this.state = {
      company: '',
      contract: '',
      open: false,
      finished: false,
      stepIndex: 0,
      sev: null,
    };
    this.onChangeField = this.onChangeField.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_company') {
      this.setState({ company: value });
    }
    if (field.name === 'fk_id_contract') {
      this.setState({ contract: value });
    }
  }

  handleModal() {
    this.setState({ open: !this.state.open });
  }

  handleNext = () => {
    let { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 2,
    });
  };

  handlePrev = () => {
    let { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  onFormSubmit(values, osId) {
    if (this.props.serviceOrder) {
      osId = this.props.serviceOrder.id_os;
    }

    if (this.props.clone == true) {
      values.id_os_clone = this.props.serviceOrder.os_id;
    }

    values.start_date = concatDateHour(values.start_date, values.start_time);

    if (values.end_time) {
      values.end_date = concatDateHour(values.end_date, values.end_time);
    } else {
      values.end_date = null;
      values.end_time = null;
    }

    if (
      values.break_initial_date &&
      values.break_end_date &&
      values.break_initial_time &&
      values.break_end_time
    ) {
      values.ini_break = concatDateHour(values.break_initial_date, values.break_initial_time);
      values.end_break = concatDateHour(values.break_end_date, values.break_end_time);
    }
    let dt1 = parseInt(
      moment(values.start_date).format('YYYYMMDD') +
      moment(values.start_time).format('HH')
    );
    let dt2 = parseInt(
      moment(values.end_date).format('YYYYMMDD') +
      moment(values.end_time).format('HH')
    );

    if (dt1 > dt2 && values.end_time != null) {
      this.store.toggleAlert(true, 'Intervalo entre datas inválido', 'error');
    } else {
      delete values.start_time;
      delete values.end_time;
      delete values.break_initial_date;
      delete values.break_initial_date;
      delete values.break_end_date;
      delete values.break_end_time;

      let meth = 'post';
      let path = this.APIEndpoints.SERVICE_ORDER;
      var btn_loading = 'service-order-post';

      if (osId) {
        meth = 'put';
        values.action = 'update';
        path = path.concat(`/${osId}`);
        btn_loading = 'service-order-put';
      }
 
    this.action
        .execute(meth, path, 'serviceOrder', values, null, { btn_loading })
        .then((c) => {
          this.setState({ sev: c.data });
          this.store.serviceOrder = c.data;
          this.handleNext();
     }); 
    } 
  }

  render() {
    var {
      handleModal,
      open,
      handleModalThirdLevel,
      openThirdLevel,
      onTaskSubmit,
      language,
      handlePage,
      onFormTaskSubmit,
      contracts,
      serviceOrder,
      tasks,
      users,
      impacts,
      priorities,
      urgencies,
      companies,
      redirect,
      history,
      user,
      tickets,
      clone,
    } = this.props;
    let { company, contract, finished, stepIndex } = this.state;
    company = company != '' ? company : serviceOrder.fk_id_out_company;
 
    if (tickets != null) {
      serviceOrder = {
        fk_id_company: tickets.fk_id_company,
        company_name: tickets.company_name,
        contract_name: tickets.contract_name,
        fk_id_contract: tickets.fk_id_contract,
        fk_id_out_company: tickets.fk_id_company,
        objective: tickets.real_id + ' - ' + tickets.subject,
        description: tickets.notes,
        fk_id_solicitant: tickets.fk_id_user,
        ticket: tickets,
        fk_id_user_tech: tickets.fk_id_tech,
        tech_name: tickets.tech_start,
        solicitant: tickets.sol
      };
      company = tickets.fk_id_company;
      contract = tickets.fk_id_contract;
    }

    if (clone == true) {
      delete serviceOrder.id_os;
    }

    if (
      testRoute('/dash/serviceorder/new', history.location.pathname) &&
      stepIndex === 0
    ) {
      //serviceOrder='';
    }

    //let companyOptions = companies.map(c => ({ values: c.id_company, label: c.name }));

    let fields = [
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_company',
        label:  language.COMPANY,
        method: 'POST',
        route: `${this.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel:  language.COMPANY,
        textinit: serviceOrder ? serviceOrder.company_name : 'Empresa',
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: serviceOrder ? serviceOrder.fk_id_company : 0,
        routeAll: `${this.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${this.APIEndpoints.COMPANY}/count`,
        detail: true,
        required: true,

      },
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_contract',
        label:  language.CONTRACTS,
        method: 'POST',
        route: `${this.APIEndpoints.CONTRACT}/filter`,
        filterAll : {fk_id_out_company : company},
        fieldquery: 'search',
        textlabel:  language.CONTRACTS,
        textinit: serviceOrder  ? serviceOrder.contract_name : 'Contrato',
        fieldvaluedb: 'id_contract',
        fieldlabeldb: 'name',
        idinit: serviceOrder ? serviceOrder.fk_id_contract :0,
        routeAll: `${this.APIEndpoints.CONTRACT}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${this.APIEndpoints.CONTRACT}/count?`,
        detail: true,
        required: true,
      },
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_user_tech',
        label: language.TECH,
        className: 'tech-group-ticket',
        required: true,
        method: "POST",
        route: `${this.APIEndpoints.USER}/filter`,
        routeGetCount: `${this.APIEndpoints.USER}/active/count/`,
        filterAll: { role:'notsolicitant' , all_fk_id_company: company},
        filter: { role:'notsolicitant', all_fk_id_company: company },
        fieldquery: 'name',
        textlabel:language.TECH,
        textinit: serviceOrder ? serviceOrder.tech_name :  language.TECH,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: serviceOrder  ? serviceOrder.fk_id_user_tech : 0,
        routeAll: `${this.APIEndpoints.USER}/filter/notsolicitant?`,
        detail: true,
        methodGetAll: 'POST',
        showEmail: true
      },
      {
        /*  col: 4,
          name: 'fk_id_solicitant',
          label: language.SOLICITANT,
          type: 'select',
          required: true,
          options:
            tickets != null
              ? [{ value: tickets.fk_id_user, label: tickets.sol }]
              : users
                  .filter((v) => v.id_company === company)
                  .map((c) => ({
                    value: c.id_user,
                    label: c.name + ' - ' + c.email,
                  })),
        */
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_solicitant',
        label: language.SOLICITANT,
        required: true,
        method: "POST",
        route: `${this.APIEndpoints.USER}/filter`,
        routeGetCount: `${this.APIEndpoints.USER}/active/count/all`,
        filterAll: { all_fk_id_company: company },
        fieldquery: 'name',
        textlabel :  language.SOLICITANT, 
        textinit: serviceOrder  ? serviceOrder.solicitant :language.SOLICITANT,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: serviceOrder  ? serviceOrder.fk_id_solicitant : 0,
        routeAll: `${this.APIEndpoints.USER}/filter/all?`,
        detail: true,
        methodGetAll: 'POST'
      },
            {
        col: 2,
        name: 'start_date',
        label: language.INITIAL_DATE,
        type: 'date',
        required: true,
      },
      {
        col: 2,
        name: 'start_time',
        label: language.INITIAL_TIME,
        type: 'time',
        required: true,
      },
      {
        col: 2,
        name: 'end_time',
        label: language.END_TIME,
        type: 'time',
        required: false,
      },
      { col: 2, name: 'interval', label: language.INTERVAL, type: 'time' },
      {
        col: 2,
        name: 'transferred',
        label: language.TRANSFERRED,
        type: 'text',
        required: false,
        format: '##:##',
      },
      {
        col: 10,
        name: 'objective',
        label: language.OBJECTIVE_OS,
        type: 'text',
        required: true,
      }, {
        /*
              col: 4,
              name: 'approver',
              label: language.APPROVER,
              type: 'select',
              options: approverOptions,
              required: true,
      */

        col: 4,
        type: 'autocomplete',
        name: 'approver',
        label: language.APPROVER,
        required: true,
        method: "POST",
        route: `${this.APIEndpoints.USER}/filter`,
        routeGetCount: `${this.APIEndpoints.USER}/active/count/all`,
        filterAll: {all_fk_id_company: company ? company : 0, fk_id_user: serviceOrder ? serviceOrder.approver : 0 },
        fieldquery: 'name',
        textlabel: language.APPROVER, 
        textinit: serviceOrder ? serviceOrder.aprovador :  language.APPROVER,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: serviceOrder ? serviceOrder.approver : 0,
        routeAll: `${this.APIEndpoints.USER}/filter/all?`,
        detail: true,
        methodGetAll: 'POST'
      },
   
      {
        col: 1,
        name: 'charge_transfer',
        label: language.CHARGE_TRANSFER,
        type: 'check'
        },
        {
          col: 1,
          name: 'charge_interval',
          label: language.CHARGE_INTERVAL,
          type: 'check'
          },
      {
        col: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'textEditor',
        required: true,
      },
    ];
    let button = { label: language.SAVE };
   // console.log('------', serviceOrder)
    return (
      <PageLayout
        icon={
          <div className="serviceOrderIcon" style={{ width: 30, height: 30 }} />
        }
        title={language.SERVICE_ORDER}
        subtitle={
          serviceOrder
            ? language.EDIT_SERVICE_ORDER
            : language.NEW_SERVICE_ORDER
        }
        rightElements={[
          <RaisedButton
            circleButton
            icon="fas fa-arrow-left"
            color="primary"
            onClick={() => redirect('/dash/serviceorder')}
            label={language.BACK}
            primary
          />,
        ]}
      >
        <Card className="cardDefault" id="ServiceOrderForm">
          <Stepper activeStep={stepIndex}>
            <Step>
              <StepLabel>{language.REGISTER_SERVICE_ORDER}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{language.ACTIVITIES}</StepLabel>
            </Step>
          </Stepper>
          <div>
            {finished ? (
              <div />
            ) : (
              <div>
                {stepIndex === 0 ? (
                  <div className="content">
                    <CommonForm
                      values={serviceOrder}
                      fields={fields}
                      onSubmit={this.onFormSubmit}
                      button={button}
                      onChangeField={this.onChangeField}
                      beforeButtonElement={
                        '/dash/serviceorder/edit' ===
                          history.location.pathname && clone == false ? (
                          <React.Fragment>
                            {stepIndex > 0 ? (
                              <RaisedButton
                                circleButton
                                primary
                                label={language.RETURN}
                                icon="fas fa-arrow-left"
                                disabled={stepIndex === 0}
                                onClick={this.handlePrev}
                              />
                            ) : null}
                            {stepIndex !== 1 ? (
                              <RaisedButton
                                circleButton
                                icon="fas fa-arrow-right"
                                buttonStyle={{ background: 'var(--secundary)' }}
                                label={language.NEXT}
                                primary={true}
                                className="mx-2"
                                onClick={this.handleNext}
                              />
                            ) : null}
                          </React.Fragment>
                        ) : null
                      }
                    />
                  </div>
                ) : stepIndex === 1 ? (
                  <AddActivitiesForm
                    handleModal={handleModal}
                    open={open}
                    handleModalThirdLevel={handleModalThirdLevel}
                    openThirdLevel={openThirdLevel}
                    onTaskSubmit={onTaskSubmit}
                    redirect={redirect}
                    impacts={impacts}
                    priorities={priorities}
                    urgencies={urgencies}
                    tasks={tasks}
                    language={language}
                    onFormSubmit={onFormTaskSubmit}
                    serviceOrder={this.props.serviceOrder || this.state.sev}
                    handlePage={handlePage}
                  />
                ) : (
                  ''
                )}
              </div>
            )}
          </div>

          <div className="nextBtn" style={{ padding: '0 15px' }}></div>
        </Card>
      </PageLayout>
    );
  }
}
