import React, { memo } from 'react';
import { Tooltip } from 'react-tippy';
// -----------------------------------common------------------------------------------
import Button from '@common/Button';
import Tag from '@common/Tag'
// -----------------------------------material-ui------------------------------------------
import { Avatar, Grid } from '@material-ui/core';
import Store from '@data/Store';

const { language } = Store;

export default memo(({
	avatares = [],
	tags = [],
	onOpenForm,
	percent
}) => {

	return (
		<Grid container spacing={2} className='Block_2 mb-4'>
			<Grid item xs={6}>
				<span className='label-items'>{language.RESPONSIBLES}</span>
				<div className='content-avatar d-flex mt-2'>
					{avatares.slice(0, 3).map((e, i) => (
						<Tooltip
							interactive
							html={
								<div style={{ color: '#fff' }}>
									{e.name}
								</div>
							}
							position="right"
							arrow
						>
							<Avatar src={e.src} key={e.name + '-' + i} className='avatar'>
								{e.name.slice(0, 2)}
							</Avatar>
						</Tooltip>
					))}

					{avatares.length > 3 &&
						<Tooltip
							html={
								<div style={{ color: '#fff', fontSize: 11 }}>
									{avatares.slice(3).map((u, i) => (
										<div
											style={{ marginLeft: '5px', marginTop: '2px' }}
											className="d-flex align-items-center"
											key={u.name + '-' + i}
										>
											<Avatar
												className={'avatar'}
												key={i}
												style={{
													fontSize: '10px',
													width: '17px',
													height: '17px',

												}}
											>
												{u.name.slice(0, 2)}
											</Avatar>
											<span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
										</div>
									))}
								</div>
							}
							position="right"
							theme="light"
							interactive
						>
							<Avatar className='avatar more'>
								+{avatares.length - 3}
							</Avatar>
						</Tooltip>
					}
					{percent != 100 ? (

						<Avatar className='avatar btn-add' onClick={onOpenForm}>
							<i className='fas fa-plus' />
						</Avatar>
					) : null}
				</div>
			</Grid>
			<Grid item xs={6}>
				<span className='label-items'>Tags</span>
				<div className='content-tag'>
					{tags.map((e, i) => (
						<Tag label={e.label} color={e.color} className='mx-1 my-1' />
					))}
				</div>
			</Grid>
		</Grid>
	)
})