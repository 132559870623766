import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card, Chip } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';
import {filterObj, testRoute} from '@common/Func';


@observer
export default class OccupationList extends Component {
  render() {
    let {
      language,
      handlePage,
      handleOpen,
      handleClose,
      redirect,
      occupations,
      users,
      route,

      callPageSelectFilter,
      totaloccupation,
      currentPage
    } = this.props;

    
    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10 }
      },
      { key: 'name', label: language.NAME },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40},
        jc:'center'
      }
    ];

    let data = (occupations || []).map(obj => {
        return {
          id: obj.id_occupation,
          name: obj.name,
          actions: (
            <React.Fragment>
              <Chip
                className='mx-2 muit-chip'
                label={
                  <div className='d-flex align-items-center justify-content-between'>
                    <RaisedButton
                      circleButton
                      icon='fas fa-edit'
                      label={language.EDIT}
                      color='default'
                      style={{fontSize:12, width:21, height:21}}
                      onClick={() => handlePage('edit', obj)}
                      className='mr-2'
                      
                    />
                    <RaisedButton
                      circleButton
                      icon='far fa-trash-alt'
                      label={language.DELETE}
                      color='danger'
                      style={{fontSize:13, width:21, height:21}}
                      onClick={() => handlePage('remove', obj)}
                      
                    />
                  </div>
                }
              />
              {/*<Row>
                <Col md={6}>
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.EDIT}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <div
                      className="editIcon pointer"
                      style={{ width: 25, height: 25 }}
                      onClick={() => handlePage('dptForm', obj)}
                    />
                  </Tooltip>
                </Col>
                <Col md={6}>
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.REMOVE}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <div
                      className="trashIcon pointer"
                      style={{ width: 25, height: 25 }}
                      onClick={() => handlePage('remove', obj)}
                    />
                  </Tooltip>
                </Col>
              </Row>*/}
            </React.Fragment>
          )
        };
      });
    let {configurationProps={}} = this.props;
    return (
      <Fragment>
        
          <CommonTable
            
            columns={col}
            data={data}
            index='OccupationList'
            orderColumn
            paginationTop={false}
            searchColumn={true}
            searchColumnEvent={callPageSelectFilter}
            countTotal={totaloccupation}
            isAutomaticPagination={false}
            beginWithNoFilter = {true}
            language={language}
            loadSearch={true}
            currentPage={currentPage}
            callPageSelectFilter={(page, values, rowsPerPage, type) =>
              callPageSelectFilter(page, values, rowsPerPage, type)
            }
            beforeBar2={configurationProps.control}
            beforeBar={
              <RaisedButton
                circleButton
                icon='fas fa-plus'
                style={{zoom:.8}}
                zoom={.8}
                className='mr-3'
                color='primary'
                label={language.NEW_POSITION}    
                onClick={() => handlePage('new')}
              />
            }
          />
        
      </Fragment>
    );
  }
}
