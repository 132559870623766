import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import DialogConfirm from '../../common/DialogConfirm';

import Store from '@data/Store';

import GroupList from './GroupList';
import GroupForm from './GroupForm';
import GroupAddUserForm from './GroupAddUserForm';
import Dialog from '@common/Dialog';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
import { setInjetSearchColumn, getInjetSearchColumn } from '@common/Func';

@observer
export default class GroupContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'groupList',
      groupsItems: [],
      countTotal: 0,
      users: [],
      usersCount: 0,
      typeSelected : 0
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.checkBoxUserSelect = this.checkBoxUserSelect.bind(this);
    this.addUsersInGroup = this.addUsersInGroup.bind(this);
    this.callPagination = this.callPagination.bind(this);
    this.callDataFilter = this.callDataFilter.bind(this);
    this.paginateUsers = this.paginateUsers.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.callPageSelectFilterUsers = this.callPageSelectFilterUsers.bind(this);
    this.getGroupData = this.getGroupData.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this)
  }

  componentDidMount() {
    this.action.execute('get', this.APIEndpoints.TICKET + '/params', 'params');

    this.action
      .execute('post', `${this.APIEndpoints.USER}/active/count/all`, '')
      .then((data) => {
        this.setState({ usersCount: data.data.count });
      });

    this.getGroupData();
  }

  getGroupData() {
    let col = [
      { key: 'id', label: 'ID' },
      { key: 'name', label: 'Name' },
      {
        key: 'description',
        label: 'description',
      },
      {
        key: 'name_company',
        label: 'company',
      },
      {
        key: 'type',
        label: 'level',
      },
      {
        key: 'actions',
        label: 'actions',
      },
    ];
    const objct = getInjetSearchColumn(window.location.pathname + 'GroupList');
    let valuesColumn = {};
    if (objct) {
      Object.keys(objct).forEach((item) => {
        const columnItem = item;

        valuesColumn = {
          ...valuesColumn,
          [columnItem]: objct[item],
        };
      });
    }

    this.action
      .execute('post', `${this.APIEndpoints.GROUP}/filter?limit=75&offset=0`, '', valuesColumn)
      .then((data) => {
        this.setState({
          groupsItems: data.data,
          countTotal: data.totalCount
        });
      });
  }

  onFormSubmit(values) {
    if (values.type > 0) {
      let method = values.hasOwnProperty('id_group_users') ? 'put' : 'post';
      let url = values.hasOwnProperty('id_group_users')
        ? this.APIEndpoints.GROUP + '/' + values.id_group_users
        : this.APIEndpoints.GROUP;
      this.action
        .execute(method, url, 'groups', values, 'id_group_users', {
          btn_loading: 'post-put-group',
        })
        .then((e) => {
          this.getGroupData();
          this.handlePage('list');
        });
    }
  }

  handlePage(page, edit) {
    if (page == "add/user"){
      this.setState({typeSelected : edit.type})
      setTimeout(() => {
        this.callPageSelectFilterUsers()
      }, 1000);
    }
    this.setState({ page: page, values: edit });
  }

  cleanFilters () {
    this.callPageSelectFilter()
  }

  callPageSelectFilter(page, items = {}, rowPerPage = 75, type) {
    let limit = rowPerPage;

    const isEmpty = Object.keys(items).length === 0;

    let offset = page > 0 ? page * limit : 0;
    if (type == 'filter') {
      offset = 0;
    }

    if (!isEmpty) {
      this.action
        .execute('post', `${this.APIEndpoints.GROUP}/filter?limit=${limit}&offset=${offset}`, '', items)
        .then((data) => {
          setTimeout(() => {
            this.setState({
              groupsItems: data.data,
              countTotal: data.totalCount
            });
          }, 2000);
        });
    } else {
      this.action
        .execute('post', `${this.APIEndpoints.GROUP}/filter?offset=${offset}&limit=${limit}`, '')
        .then((data) => {
          setTimeout(() => {
            this.setState({
              groupsItems: data.data,
              countTotal: data.totalCount
            });
          }, 2000);
        });
    }
  }

  callPageSelectFilterUsers(page = 0, items = {}, rowPerPage = 75, type) {
    let limit = rowPerPage;
    const isEmpty = Object.keys(items).length === 0;

    let offset = page * limit;
    if (type == 'filter') {
      offset = 0;
    }
    let typeSelected = this.state.typeSelected;

    this.action
      .execute('post', `${this.APIEndpoints.USER}/filter/${typeSelected == 1 ? "notsolicitant" : typeSelected == 2 ? "solicitant" : 'all'}?offset=${offset}&limit=${limit}`, '', items)
      .then((data) => {
        setTimeout(() => {
          this.setState({ users: data.data });
        }, 2000);
      });

    this.action
      .execute('post', `${this.APIEndpoints.USER}/active/count/all`, '', items)
      .then((data) => {
        this.setState({ usersCount: data.data.count });
      });
  }

  callPagination(page) {}

  callDataFilter(items = {}) {}

  addUsersInGroup(users, id_group_users) {
    this.action.execute(
      'put',
      this.APIEndpoints.GROUP + '/user',
      'groups',
      { users: users, id_group_users: id_group_users },
      'id_group_users'
    );
    window.location.reload();
  }

  onDelete() {
    let { values, groupsItems } = this.state;
    let method = 'delete';
    let url = this.APIEndpoints.GROUP;
    let body = null;
    if (values.detach) {
      method = 'put';
      url += '/user';
      body = values;
      this.handlePage('add/user', values);
    } else {
      url += '/' + values.id_group_users;
      this.handlePage('groupList');
    }

    this.action
      .execute(method, url, 'groups', body, 'id_group_users', {
        btn_loading: 'delete-group-item-' + values.id_group_users,
      })
      .then((res) => {
        if(!values.detach) {
          this.setState({
            groupsItems: groupsItems.filter(
              (g) => g.id_group_users != res.data.id_group_users
            ),
          });
        }
      });
  }

  checkBoxUserSelect(type, id_user, id_group_users) {
    this.action.execute(
      'put',
      this.APIEndpoints.GROUP + '/user/' + type,
      'groups',
      { id_user: id_user, id_group_users: id_group_users },
      'id_group_users'
    );
  }

  paginateUsers(page) {
    let limit = 50;
    let offset = (page + 1) * limit;

    this.action
      .execute('post', `${this.APIEndpoints.USER}/filter/all?offset=${offset}&limit=${limit}`, '')
      .then((data) => {
        this.setState({ users: data.data });
      });
  }

  filterUsers(items = {}) {
    let limit = 50;
    const isEmpty = Object.keys(items).length === 0;

    let offset = (1 + 1) * limit;
    if (!isEmpty) {
      this.action
        .execute('post', `${this.APIEndpoints.USER}/filter/all?offset=0&limit=100`, '', items)
        .then((data) => {
          this.setState({ users: data.data, usersCount: data.data.length });
        });
    } else {
      this.action
        .execute('post', `${this.APIEndpoints.USER}/filter/all?offset=0&limit=100`, '', items)
        .then((data) => {
          this.setState({ users: data.data });
        });

      this.action
        .execute('post', `${this.APIEndpoints.USER}/active/count/all`, '')
        .then((data) => {
          this.setState({ usersCount: data.data.count });
        });
    }
  }

  render() {
    let { language, groups, companies } = this.store;
    let { page, open, values, groupsItems, users, usersCount, typeSelected } = this.state;
    //  this.setState({ groupsItems: groups });
    let history = this.history2;
    let redirect = this.redirect2;
    let { pathname, state } = history.location;
    let { configurationProps = {} } = this.props;
    const groupTypesLang = {
      1: language.ADD_TECH_TO_GROUP,
      2: language.ADD_SOLICITANT_TO_GROUP,
      3: language.ADD_APPROVAL_TO_GROUP,
      4: language.ADD_USER_TO_NOTIFICATION_GROUP,
    };
    return (
      <Fragment>
        <Animate>
          <GroupList
            onFormSubmit={this.onFormSubmit}
            handlePage={this.handlePage}
            callPagination={this.callPagination}
            callDataFilter={this.callDataFilter}
            callPageSelectFilter={this.callPageSelectFilter}
            countTotal={this.state.countTotal}
            language={language}
            redirect={redirect}
            {...this.state}
            configurationProps={configurationProps}
            groups={groupsItems}
            cleanFilters = {this.cleanFilters}
          />
        </Animate>
        {['new', 'edit'].includes(page) && (
          <Dialog
            title={values ? language.EDIT_GROUP : language.NEW_GROUP}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="md"
          >
            <GroupForm
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              language={language}
              {...this.state}
              values={values}
              groups={groupsItems}
              companies={companies}
              action={this.action}
              integration={this.store.params && this.store.params[0]}
              redirect={redirect}
              APIEndpoints={this.APIEndpoints}
            />
          </Dialog>
        )}
        {page == 'add/user' && (
          <Dialog
            title={values && groupTypesLang[values.type]}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="md"
          >
            <GroupAddUserForm
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              language={language}
              redirect={redirect}
              users={users}
              usersCount={usersCount}
              paginateUsers={this.paginateUsers}
              filterUsers={this.filterUsers}
              callPageSelectFilterUsers={this.callPageSelectFilterUsers}
              values={
                groupsItems.filter(
                  (g) => g.id_group_users == parseInt(values.id_group_users)
                )[0]
                  ? groupsItems.filter(
                      (g) => g.id_group_users == parseInt(values.id_group_users)
                    )[0]
                  : []
              }
              addUsersInGroup={this.addUsersInGroup}
              checkBoxUserSelect={this.checkBoxUserSelect}
            />
          </Dialog>
        )}
        {page === 'remove' && (
          <CommonDelete
          language={language}
          closeModal={() => this.handlePage('list')}
          onDelete={this.onDelete}
          message={this.state.values.name}
          />
        )}
      </Fragment>
    );
  }
}
