import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useServicePortalStyles } from '../helpers';

export function GridLine({ label, children }) {
    return (
        <>
            <Grid item sm={5} className='tw-text-gray-500'>
                {label}
            </Grid>
            <Grid item sm={7}>
                {children}
            </Grid>
        </>
    )
}

export function GridLineItem({ label, text, icon, content, isHidden, isLongText = false }) {
    const styles = useServicePortalStyles();

    if (isHidden)
        return <></>

    return (
        <GridLine label={label}>
            {icon && <i className={`far ${icon} tw-mr-2`} />}
            {text &&
                <Typography component='span' className={isLongText ? styles.description : styles.textEmphasis}>
                    {text}
                </Typography>
            }
            {content ?? <></>}
        </GridLine>
    )
}