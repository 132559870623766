import React, { useState, useReducer, useEffect } from 'react';
import TicketUtils from '../../util/TicketUtils';
import { Card } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import TicketManagement from '../TicketManagement/index';
import OverlayLoading from '@common/OverlayLoading';
import moment from 'moment';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import Header from '@common/Header';
import Block_1 from './block_1';
import Block_2 from './block_2';
import Block_3 from './block_3';
import Block_4 from './block_4';
import Block_5 from './block_5';
import Block_6 from './block_6';

import './Dashboard.css';

export default function ({
  language,
  contracts,
  gsdCount,
  searchProject,
  categories,
  users,
  avaliation,
  action,
  APIEndpoints,
  user,
  groups,
  greenTheme,
  dashboard,
  alert,
  params,
}) {
  const [modalDetail, setModalDetail] = useState(false);
  const [modalDetailNps, SetmodalDetailNps] = useState(false);
  const [itens, setItens] = useState([]);
  const [Body, setBody] = useState([]);
  const [idTicket, setIdTicket] = useState(0);
  const [id_tech, setId_Tech] = useState(0);
  const [idGroupTech, setIdGroupTech] = useState(0);
  const [id_company, setId_company] = useState(0);
  const [load, setLoad] = useState(false);
  const [lastParams, setLastParams] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [filterContract, setFilterContract] = useState(false);
  const [dataDownload, setDataDownload] = useState([]);
  const [form, setForm] = useState();

  const [statusState, setStatusState] = useState({});
  const [timeState, setTimeState] = useState({});
  const [priorityState, setPriorityState] = useState(null);
  const [extraFilterState, setExtraFilterState] = useState([]);

  const [filterColumns, setFilterColumns] = useState({});
  const [filterFind, setFilterFind] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(15);
  const [totalTickets, setTotalTickets] = useState(0);
  const [sortBy, setSortBy] = useState('grid_date');
  const [sortByDesc, setSortByDesc] = useState(true);
  const [updateGrid, setUpdateGrid] = useState(false);
  const [usePagination, setUsePagination] = useState(true);
  const [loading, setLoading] = useState(false);
  const [datagmud, setdatagmud] = useState([]);
  const [modalGmud, setModalGmud] = useState(false);
  const [ticketDelay, setTicketDelay] = useState(false);
  useEffect(() => {
    setForm({
      ...form,
      dtini: moment().format('MM/01/YYYY'),
      dtend: moment().format(
        'MM/' + moment().endOf('month').format('DD') + '/YYYY'
      ),
      type_date: 1,
    });
  }, []);

  useEffect(() => {
    setUpdateGrid(true);
  }, [
    filterColumns,
    currentPage,
    totalPerPage,
    sortBy,
    sortByDesc,
    filterFind,
  ]);

  useEffect(() => {
    if (form) {
      if (Math.abs(moment(form.dtend).diff(form.dtini, 'days')) > 31) {
        alert(true, language.QUERY_NOT_REALIZE, 'error');
      } else {
        searchProject(form);
      }
    }
  }, [form]);

  useEffect(() => {
    refreshGrid(statusState, timeState, priorityState, extraFilterState);
  }, [updateGrid]);

  const clearFilter = () => {
    setForm({});
  };

  const findContract = (v) => {
    setForm({ ...form, fc: [v.fc] });
    setFilterContract(false);
    setModalDetail(false);
  };

  async function callPageSelectFilter(page, values, totalPerPage) {
    setCurrentPage(page);
    setTotalPerPage(totalPerPage);
    if (JSON.stringify(values) !== JSON.stringify(filterColumns)) {
      setCurrentPage(0);
    }
    setFilterColumns(values);
  }

  const openModalDetail = async (
    type,
    value,
    ticketsGSDTop,
    status,
    time,
    priority
  ) => {
    if (type == 'gmud') {
      setModalGmud(true);
      setdatagmud(value);
    } else {
      const extraFilter = [];
      let tb_field = ticketsGSDTop;
      if (type) {
        extraFilter.push({ field: `${type.trim()}_id`, value, tb_field });
      }
      await refreshGrid(status, time, priority, extraFilter);

      setStatusState(status);
      setTimeState(time);
      setPriorityState(priority);
      setExtraFilterState(extraFilter);
      setModalDetail(true);
    }
  };

  const refreshGrid = (status, time, priority, extraFilter) => {
    const isBacklog = status && status === 'Backlog';
    const isDelay = status && status === 'Delay';
   //gsdCount.backlog
    setUsePagination(!isDelay);
    setTicketDelay(isDelay);
    const limit = isDelay ? null : totalPerPage;
    const offset = isDelay ? null : currentPage;

    const statusFilter = [];
    if (!priority && status) {
      statusFilter.push(status);
      if (statusFilter[0] === 'Delay') {
        statusFilter.push('Open');
        statusFilter.push('In Progress');
        statusFilter.push('Reopened');
        statusFilter.push('Aguardando');
        statusFilter.push('Closed');
        statusFilter.push('Resolved');
      } else if (statusFilter[0] === 'Open' || isBacklog) {
        statusFilter.push('Open');
        statusFilter.push('In Progress');
        statusFilter.push('Reopened');
        statusFilter.push('Aguardando');
      } else {
        statusFilter.push('Closed');
        statusFilter.push('Resolved');
      }
    }

    const data =
      time || isBacklog
        ? null
        : {
            dtini: moment(form?.dtini).format('YYYYMMDD'),
            dtend: moment(form?.dtend).format('YYYYMMDD'),
          };
    const dataBase = isBacklog ? null : moment(form?.dtini).format('YYYYMMDD');

    const body = {
      time,
      status,
      limit,
      offset,
      data,
      ticketsClose: true,
      type_date: form?.type_date,
      payload: {
        company_ids: form?.companys,
        tech_group_ids: form?.group,
        technician_ids: form?.tech,
        contract_ids: form?.fc ? form?.fc[0] : null,
        dataBase,
        statusFilter,
        priority,
        filterColumns,
        extraFilter,
        sortBy,
        sortByDesc,
        filterFind,
        language: language.LOCATION_LANGUAGE,
        visonManeger: true,
      },
    };

    setLoading(true);

    return action
      .execute(
        'post',
        APIEndpoints.TICKET + '/getticketsdash',
        'ticketsdash',
        body,
        null,
        null,
        false
      )
      .then((res) => {
        const itens = res.data;
        setBody(body);
        if(isBacklog){
        setTotalTickets(gsdCount.backlog.length);
        }else{ 
        setTotalTickets(itens.length ? itens[0].total_count : 0);
        }
        setItens(formatArrayTable(itens));
        setLoading(false);
      })
      .catch((error) => {
        console.error({ error });
        setItens([]);
        setLoading(false);
      })
      .finally(() => {
        setUpdateGrid(false);
      });
  };

  function exportExcel() {
    let body = Body;
    body.limit = false;
    action
      .execute(
        'post',
        APIEndpoints.TICKET + '/getticketsdash',
        'ticketsdash',
        body,
        null,
        null,
        false
      )
      .then((res) => {
        const itens = res.data;
        if (itens.length) {
          let dataCSV = [Object.keys(itens[0])].concat(
            itens.map((e) => Object.values(e))
          );
          var wb = XLSX.utils.book_new();
          wb.Props = {
            Title: `Sensr.IT excel ${moment().format('L')}`,
            Subject: `Sensr.IT - ${language.MANAGEMENT_VIEW}`,
            Author: 'Sensr.IT',
            CreatedDate: new Date(2017, 12, 19),
          };

          wb.SheetNames.push('Sensr.IT');
          var ws = XLSX.utils.aoa_to_sheet(dataCSV);
          wb.Sheets['Sensr.IT'] = ws;
          var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          saveAs(
            new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
            `Sensr.IT excel ${moment().format('L')}.xlsx`
          );
        }
      })
      .catch((error) => {
        console.error({ error });
        setItens([]);
        setLoading(false);
      })
      .finally(() => {
        setUpdateGrid(false);
      });
  }

  function searchColumnEvent({ search }) {
    setCurrentPage(0);
    setFilterFind(search);
  }

  const formatArrayDownload = (tickets) => {
    let _dataDownload = tickets
      .filter((g) => g.tp == 'ticket')
      .map((f) => {
        try {
          f.id_request = null;
        } catch (g) {}
        let sla = null;
        let sla_stop = null;
        if (f.tp === 'request') {
          let item = { dt_cad: f.dtcadrequest, sla: f.sla_task };
          sla = TicketUtils.calcSla(item, 'request', language);
        } else {
          sla = TicketUtils.calcSla(
            f,
            'ticket',
            language,
            [],
            f.sla_task,
            undefined
          );
          if (f.status == 'Resolved' || f.status == 'Closed') {
            sla_stop = TicketUtils.stopSlaTime(f.time_total, f.sla_task);
          }
        }
        let gp = '';
        try {
          gp = groups.find(
            (gr) => gr.id_group_users == f.fk_id_group_tech
          ).name;
        } catch (e) {}

        return {
          Status: f.status,
          Evolução: f.progress + '%',
          Descrição: f.real_id,
          Usuários: f.nameuser,
          Técnico: f.nametech,
          'Grupo Técnico': gp,
          Empresa: f.namecompany,
          Assunto: f.subject,
          SLA:
            f.status == 'Resolved' || f.status == 'Closed'
              ? sla_stop.time
              : f.status == 'Reopened'
              ? sla.time
              : typeof sla !== 'string'
              ? sla.time
              : language.NOTHING_REGISTERED,
          Sugestão: f.suggestions,
          'Data Cadastro': moment(f.dt_cad).format('L'),
        };
      });
    setDataDownload(_dataDownload);
  };

  const formatArrayTable = (tickets) => {
    let array = tickets
      .filter((g) => g.tp == 'ticket')
      .map((f) => {
        try {
          f.id_request = null;
        } catch (g) {}
        let sla = null;
        let sla_stop = null;
        if (f.tp === 'request') {
          let item = { dt_cad: f.dtcadrequest, sla: f.sla_task };
          sla = TicketUtils.calcSla(item, 'request', language);
        } else {
          sla = TicketUtils.calcSla(
            f,
            'ticket',
            language,
            [],
            f.sla_task,
            undefined
          );
          if (f.status == 'Resolved' || f.status == 'Closed') {
            sla_stop = TicketUtils.stopSlaTime(f.time_total, f.sla_task);
          }
        }
        let gp = '';
        try {
          gp = groups.find(
            (gr) => gr.id_group_users == f.fk_id_group_tech
          ).name;
        } catch (e) {}
        return {
          detail: (
            <i
              class="fas fa-eye"
              style={{ width: 10, height: 10, cursor: 'pointer' }}
              onClick={() =>
                setIdTicket({ id_tickets: f.id_tickets, real_id: f.real_id })
              }
            ></i>
          ),
          grid_status: (
            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                {f.grid_status === 'Resolved' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.RESOLVED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="resolvedIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : f.grid_status === 'In Progress' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.IN_PROGRESS}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="inProgressIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : f.grid_status === 'Closed' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.CLOSED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="closedIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : f.grid_status === 'Cancelled' ? (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.CANCELLED}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="cancelledIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    html={
                      <div style={{ color: '#fff', fontSize: 15 }}>
                        <strong>{language.TICKET_STATUS.OPEN}</strong>
                      </div>
                    }
                    position="right"
                    theme="dark"
                  >
                    <span>
                      <div
                        className="openIcon"
                        style={{ width: 25, height: 25 }}
                      />
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="orderdivpro w-100 ml-1" id={f.progress}>
                <div style={{ textAlign: 'center' }}>{f.progress}%</div>
                <div
                  style={{
                    width: `${f.progress}%`,
                    height: '7px',
                    backgroundColor:
                      f.progress > 66
                        ? '#85cc00'
                        : f.progress > 33
                        ? '#ffbf00'
                        : '#ff2e00',
                    borderRadius: '2px',
                    transition: 'all .2s ease-out',
                  }}
                />
              </div>
            </div>
          ),
          grid_id: f.grid_id,
          grid_user: f.grid_user,
          grid_service_technician: f.grid_service_technician,
          grid_tech_group: f.grid_tech_group,
          grid_company: f.grid_company,
          grid_subject: f.grid_subject,
          grid_sla: f.grid_sla + 'h',
          grid_sla_time:
            f.status === 'Resolved' || f.status === 'Closed' ? (
              <div style={{ width: 80 }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    background: TicketUtils.stopSlaTime(
                      f.time_total,
                      f.sla_task
                    ).color,
                    borderRadius: '50%',
                    float: 'left',
                    marginRight: 5,
                  }}
                />
                <span
                  style={{
                    color: TicketUtils.stopSlaTime(f.time_total, f.sla_task)
                      .color,
                  }}
                  className="sla_time"
                >
                  {TicketUtils.stopSlaTime(f.time_total, f.sla_task).time}
                </span>
              </div>
            ) : null,
          grid_suggestions: f.grid_suggestions,
          grid_date: moment(f.grid_date).format('L'),
          grid_backlog: f.grid_backlog ? (
            <div>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background:
                    'transparent linear-gradient(180deg, #02F0DE 0%, #0B85BC 100%)',
                  borderRadius: '50%',
                  float: 'left',
                  marginRight: 5,
                }}
              />
            </div>
          ) : null,
        };
      });
    return array;
  };

  let ticketsGSDTop = [];
  let yeartype = null;
  let graphModules = gsdCount.graphModules;
  let graphModulesClosedOpen = gsdCount.graphModulesClosedOpen;

  if (gsdCount.changesGSD != undefined) {
    graphModules.Gmud = gsdCount.changesGSD.length;
    graphModules.Total = gsdCount.changesGSD.length;
  }

  let ticketFields = [
    {
      label: language.ACTION,
      key: 'detail',
      style: {
        width: 1,
        paddingRight: 5,
        paddingLeft: 5,
      },
      thConfig: true,
      search: false,
    },
    {
      label: language.STATUS,
      key: 'grid_status',
      style: { width: 100, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
    },

    {
      label: language.ID,
      key: 'grid_id',
      style: { width: 1, paddingRight: 0, textAlign: 'left' },
      thConfig: true,
    },

    {
      label: language.USER,
      key: 'grid_user',
      style: { width: 300, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.TECH,
      key: 'grid_service_technician',
      style: { width: 300, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.GROUP_TECH,
      key: 'grid_tech_group',
      style: { width: 300, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.COMPANY,
      key: 'grid_company',
      style: { width: 100, paddingRight: 0 },
    },
    {
      label: language.SUBJECT,
      key: 'grid_subject',
      style: { width: 400, paddingRight: 0 },
      thConfig: true,
    },
    {
      label: language.SLA,
      key: 'grid_sla',
      style: { width: 10, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
      search: false,
    },
    {
      label: language.TIME,
      key: 'grid_sla_time',
      style: { width: 15, paddingRight: 0, paddingLeft: 0 },
      thConfig: true,
      search: false,
    },
    {
      label: language.SUGGESTION,
      key: 'grid_suggestions',
      style: { width: 400 },
    },
    {
      label: language.DATE_CAD,
      key: 'grid_date',
      style: { width: 100 },
    },
    {
      label: language.BACKLOG,
      key: 'grid_backlog',
      style: { width: 20 },
      search: false,
    },
  ];

  const typeOptions = [
    { icon: 'fas fa-tools', label: language.OPENING, value: 1 },
    { icon: 'fas fa-chart-line', label: language.CLOSING, value: 2 },
  ];
  return (
    <>
      {loading && <OverlayLoading />}
      <PageLayout
        title={language.DASHBOARD_G}
        subTitle={language.DASHBOARD_PAGE.DM + '/'}
        back
        rightElements={[
          <div
            id="FilterDashForm"
            style={{
              width: '130%',
              zoom: '0.8',
              marginTop: '15px',
              marginRight: -60,
            }}
            zoom={0.8}
          >
            <CommonForm
              individual
              hiddenSubmit
              values={form}
              spacing={0}
              onSubmit={(e) => {
                if (Math.abs(moment(e.dtend).diff(e.dtini, 'days')) > 31) {
                  alert(true, language.QUERY_NOT_REALIZE, 'error');
                } else {
                  setRefresh(false);
                  setId_Tech(e.tech);
                  setId_company(e.companys);
                  setIdGroupTech(e.group);
                  searchProject(e);
                  setTimeout(() => {
                    setRefresh(true);
                  }, 3000);
                }
              }}
              onChangeField={(f, v) => {
                if (f.name == 'contracts') {
                  setIdContract(v);
                }
                if (f.name == 'dtini') {
                  //setDateYear(v);
                }
                if (f.name == 'tech') {
                }

                if (f.name == 'companys') {
                  //setIdCompany(v);
                }

                setForm({ ...form, [f.name]: v });
              }}
              fields={[
                {
                  col: 2,
                  type: 'autocomplete',
                  name: 'group',
                  textlabel: language.TECH_GROUP,
                  className: 'tech-group-ticket',
                  textlabel: language.TECH_GROUP,
                  isMulti: true,
                  method: 'POST',
                  route: `${APIEndpoints.GROUP}/filter`,
                  fieldquery: 'search',
                  textinit: '',
                  idinit: 0,
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  useinit: true,
                  routeAll: `${APIEndpoints.GROUP}/all?`,
                  detail: true,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.GROUP}/count`,
                  textinit: language.TECH_GROUP,
                  filterAll: { orderby: 'name', type_search: 1 },
                },
                {
                  col: 2,
                  type: 'autocomplete',
                  name: 'tech',
                  label: language.TECH,
                  textlabel: language.TECH,
                  className: 'tech-group-ticket',
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter/notsolicitant?`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/notsolicitant`,
                  fieldquery: 'search',
                  textinit: language.TECH,
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: '',
                  routeAll: `${APIEndpoints.USER}/filter/notsolicitant?`,
                  detail: true,
                  isMulti: true,
                  methodGetAll: 'POST',
                  textlabel: language.TECH,
                  visible: user.role == 'client',
                },
                {
                  col: 2,
                  type: 'autocomplete',
                  name: 'companys',
                  label: language.COMPANY,
                  method: 'POST',
                  route: `${APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: language.COMPANY,
                  textinit: language.COMPANY,
                  fieldvaluedb: 'id_company',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.COMPANY}/count`,
                  detail: true,
                  isMulti: true,

                  visible: user.role == 'client',
                },

                {
                  col: 1.5,
                  type: 'date',
                  name: 'dtini',
                  label: language.START_,
                },
                {
                  col: 1.5,
                  type: 'date',
                  name: 'dtend',
                  label: language.END,
                },
                {
                  col: 2,
                  name: 'type_date',
                  label: language.DATE_WITH,
                  type: 'btnGroup',
                  colorInactive: 'primary',
                  colorActive: 'primary',
                  options: typeOptions,
                },

                {
                  col: 1,
                  type: 'element',
                  content: (v, h, submit) => (
                    <div style={{ display: 'flex' }}>
                      <Button
                        icon="fas fa-file-contract"
                        variant="circle"
                        color="primary"
                        label={language.CONTRACTS_FILTER}
                        onClick={() => setFilterContract(true)}
                        style={{ marginTop: 15, marginRight: 5 }}
                      />
                      <Button
                        icon="fas fa-eraser"
                        variant="circle"
                        color="primary"
                        label={language.CLEAR}
                        onClick={clearFilter}
                        style={{ marginTop: 15, marginRight: 5 }}
                      />
                      <Button
                        icon="fas fa-search"
                        variant="circle"
                        color="success"
                        label={language.SEARCH}
                        style={{ marginTop: 15 }}
                        onClick={() => submit()}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>,
        ]}
      >
        {idTicket == 0 ? null : (
          <Dialog
            title={language.ASSOCIATION_TICKETS}
            open={idTicket == 0 ? false : true}
            maxWidth="xl"
            onClose={() => {
              setIdTicket(0);
            }}
          >
            <TicketManagement
              detail={true}
              id_ticket_detail={idTicket}
            ></TicketManagement>
          </Dialog>
        )}

        <Dialog
          title={''}
          onClose={() => {
            setCurrentPage(0);
            setTotalPerPage(15);
            setModalDetail(false);
            setPriorityState(null);
            setExtraFilterState([]);
            setStatusState({});
            setTimeState({});
          }}
          open={modalDetail}
          maxWidth="xl"
          loading={loading}
          bar={false}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          contentProps={{
            style: {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          {ticketDelay ? (
            <CommonTable
              searchColumn
              rowsPerPageOptions={[15, 25, 50, 75]}
              rowsPerPageProp={totalPerPage}
              noSort={['sla_time', 'status']}
              orderColumn
              paginationTop={false}
              afterBar={
                <Button
                  icon="fas fa-file-export"
                  label={language.EXPORT_EXCEL}
                  variant="circle"
                  color="warning"
                  style={{
                    fontSize: 16,
                  }}
                  onClick={exportExcel}
                />
              }
              beforeBar2={
                <Button
                  icon="fas fa-times"
                  color="light"
                  variant="circle"
                  outlined
                  onClick={() => setModalDetail(false)}
                />
              }
              language={language}
              columns={ticketFields}
              data={itens}
            ></CommonTable>
          ) : (
            <CommonTable
              searchColumn
              searchColumnEvent={searchColumnEvent}
              searchValue={filterFind}
              rowsPerPageOptions={[15, 25, 50, 75]}
              pagination={true}
              paginationTop={false}
              isAutomaticPagination={false}
              currentPage={currentPage}
              countTotal={totalTickets}
              noSort={['sla_time', 'status']}
              rowsPerPageProp={totalPerPage}
              callPageSelectFilter={(page, values, rowsPerPage, type) =>
                callPageSelectFilter(page, values, rowsPerPage, type)
              }
              AppBarProps={{
                style: {
                  borderRadius: '10px 10px 0 0',
                },
              }}
              orderColumn
              afterBar={
                <Button
                  icon="fas fa-file-export"
                  label={language.EXPORT_EXCEL}
                  variant="circle"
                  color="warning"
                  style={{
                    fontSize: 16,
                  }}
                  onClick={exportExcel}
                />
              }
              beforeBar2={
                <Button
                  icon="fas fa-times"
                  color="light"
                  variant="circle"
                  outlined
                  onClick={() => setModalDetail(false)}
                />
              }
              language={language}
              columns={ticketFields}
              onSortItem={(item, sortByStatus) => {
                setSortBy(item);
                setSortByDesc(sortByStatus);
              }}
              data={itens}
            ></CommonTable>
          )}
        </Dialog>

        {
          <Dialog
            title={''}
            onClose={() => setModalGmud(false)}
            open={modalGmud}
            maxWidth="md"
          >
            <CommonTable
              searchColumn
              orderColumn
              title={''}
              language={language}
              columns={[
                {
                  key: 'id',
                  label: language.ID,
                  style: { textAlign: 'left', width: 60 },
                  thConfig: true,
                },

                {
                  key: 'status',
                  label: language.STATUS,
                  style: {
                    textAlign: 'center',
                    width: 1,
                    paddingRight: 0,
                    paddingLeft: 0,
                  },
                  thConfig: true,
                },

                {
                  key: 'reason',
                  label: language.REASON,
                  style: { textAlign: 'left', width: 280 },
                  thConfig: true,
                },

                {
                  key: 'company',
                  label: language.COMPANY,
                  style: { width: 180, textAlign: 'left' },
                  thConfig: true,
                },

                {
                  key: 'manager',
                  label: language.RESPONSIBLE,
                  style: { textAlign: 'left', width: 280 },
                  thConfig: true,
                },
                {
                  key: 'start_date',
                  label: language.INITIAL_DATE,
                  style: { maxWidth: 80, textAlign: 'left' },
                  thConfig: true,
                },
                {
                  key: 'end_date',
                  label: language.END_DATE,
                  style: { maxWidth: 80, textAlign: 'left' },
                  thConfig: true,
                },
              ]}
              data={datagmud.map((change) => ({
                id: change.id_change,

                status: (
                  <div>
                    {change.status === 'Open' ? (
                      <div
                        className="openStatusIcon pointer cardIcon "
                        data-tip={language.TICKET_STATUS.OPEN}
                      />
                    ) : change.status === 'Create' ? (
                      <div
                        data-tip={language.CREATE}
                        className="createIcon pointer cardIcon"
                      />
                    ) : change.status === 'Rejected' ? (
                      <div
                        className="rejectedIcon pointer cardIcon"
                        data-tip={language.REJECT}
                      />
                    ) : change.status === 'Progress' ? (
                      <div
                        data-tip={language.PROGRESS}
                        className="inProgressIcon pointer cardIcon"
                      />
                    ) : change.status === 'Finished' ? (
                      <div
                        data-tip={language.FINALIZE}
                        className="finishedStatusIcon cardIcon"
                      />
                    ) : change.status === 'Rollback' ? (
                      <div
                        data-tip={language.ROLLBACK}
                        className="rollbackStatusIcon pointer cardIcon"
                      />
                    ) : change.status === 'Cancelled' ? (
                      <div
                        data-tip={language.TICKET_STATUS.CANCELLED}
                        className="cancelledIcon cardIcon"
                      />
                    ) : change.status === 'Analyze' ? (
                      <div
                        data-tip={language.ANALYZING}
                        className="analyzeStatusIcon cardIcon"
                      />
                    ) : (
                      change.status
                    )}
                  </div>
                ),

                reason: (
                  <div
                    className="maxHeight bgStriped"
                    dangerouslySetInnerHTML={{ __html: change.reason }}
                  />
                ),
                company: change.company,
                manager: change.manager,
                start_date:
                  change.start_date != null
                    ? moment(change.start_date).format('L')
                    : '',
                end_date:
                  change.end_date != null
                    ? moment(change.end_date).format('L')
                    : '',
              }))}
            ></CommonTable>
          </Dialog>
        }

        <div id="GeneralDashboard" style={{ '--greenTheme': greenTheme }}>
          <Header />
          <Card className="content-medium">
            <Block_1
              graphModules={graphModules}
              ITEMDELAY={gsdCount.ITEMDELAY}
              ITEMCLOSE={gsdCount.ITEMCLOSE}
              ITEMOPEN={gsdCount.ITEMOPEN}
              // BACKLOG = {BACKLOG}
              ticketsBroken={gsdCount.ticketsBroken}
              params={params}
              language={language}
              BACKLOG={gsdCount.backlog}
              ticketsBroken={gsdCount.ticketsBroken}
              openModalDetail={openModalDetail}
              params={params}
            />
            <Block_2
              graphModulesClosedOpen={graphModulesClosedOpen}
              graphModules={graphModules}
              changesGSD={gsdCount.changesGSD}
              openModalDetail={openModalDetail}
              language={language}
            />
            <Block_3
              avTicket={dashboard.avt}
              avRequest={dashboard.avr}
              avCon={dashboard.avConsumed}
              graphModulesCO={dashboard.graphModulesClosedOpen}
              ticketsGSDTop={ticketsGSDTop}
              setLoad={setLoad}
              progressmes={dashboard.progressmes_}
              delayTicket={dashboard.delayTicket_}
              graphDtq={dashboard.graphDtq}
              openModalDetail={openModalDetail}
              priority={dashboard.groupprioridade}
              language={language}
            />
            {load == true ? (
              <Block_4
                categ={dashboard.groupcategory}
                load={load}
                categories={categories}
                subcateg={dashboard.groupsubcategory}
                catalog={dashboard.groupcat}
                depart={dashboard.groupdepart}
                comp={dashboard.groupcompany}
                openModalDetail={openModalDetail}
                top10company={dashboard.groupcompany}
                language={language}
              />
            ) : null}
            {user.role != 'solicitant' && refresh == true ? (
              <Block_5
                dataCompany={dashboard.groupcompany}
                categories={categories}
                setIdTicket={setIdTicket}
                openModalDetail={openModalDetail}
                action={action}
                id_tech={id_tech}
                idGroupTech={idGroupTech}
                language={language}
                id_company={id_company}
                APIEndpoints={APIEndpoints}
              />
            ) : null}
            <Block_6
              yeartype={yeartype}
              id_tech={id_tech}
              idGroupTech={idGroupTech}
              id_company={id_company}
              openModalDetail={openModalDetail}
              callMonth={dashboard.call_month}
              nps={dashboard.groupnps}
              language={language}
            />
          </Card>
        </div>

        <Dialog
          title={language.CONTRACT_FILTER}
          open={filterContract}
          onClose={() => setFilterContract(false)}
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'fc',
                type: 'listCheckbox',
                label: language.CONTRACTS,
                options: contracts.map((e) => ({
                  value: e.id_contract,
                  label: e.name,
                })),
              },
            ]}
            button={{
              label: language.SEARCH,
              variant: 'normal',
              icon: 'fas fa-search',
            }}
            onSubmit={(v) => findContract(v)}
          />
        </Dialog>
      </PageLayout>
    </>
  );
}
