import React from 'react';
/*--------------------------common-------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts, {Highcharts} from 'react-highcharts';
import Loading from '@common/Loading';
/*--------------------------material-ui-------------------------*/
import {Card} from '@material-ui/core';
import { TelemetryTicket } from './Graph';
import Store from '@data/Store';

const { language } = Store;

export default function({data=[], months}){

	return(
		<Card id='TelemetryTicket' className='h-100'>
         <AppBar title={language.TICKET_TELEMETRY} subTitle={`(${language.QUARTER})`} />
         <Loading msg={false} loading={!data.length} className='-mt-3'>
            <div className='content-medium'>
               <ReactHighcharts config={TelemetryTicket({data, months})}/>
            </div>
         </Loading>
		</Card>
	)
}