import React, { useEffect, useState, useReducer } from 'react';
import PageLayout from '../../../common/PageLayout';
import Header from '@common/Header';
import Actions from '@data/Actions';
import { Card, Grid } from '@material-ui/core';
import DownloadFiles from '../../../util/DownloadFiles';
import CommonTable from '@common/CommonTable';
import TaskForm from '../task/TaskForm';
import CommonDelete from '../../../common/CommonDelete';
import AttendenceForm from './FormAttendance';
import '../../TicketManagement/Detail.css';
import moment from 'moment';
import TaskList from '../task/TaskList';
import Constants from '../../../data/Constants';
import TabsAttendance from '../attendance/TabsAttendance';
import CommentAttendance from '../components/CommentAttendance';
import ObserversAttendance from './componentsModal/ObserversAttendance';
import CancelAttendance from './componentsModal/CancelAttendance';
import GroupAttendance from './componentsModal/GroupAttendance';
import UserStartAttendance from './componentsModal/UserStartAttendance';
import FinishAttendance from './componentsModal/FinishAttendance';
import StepAttendance from './componentsModal/StepAttendance';
import StepsConcludedPageAttendance from './componentsPage/StepsConcludedPageAttendance';
import MainPageAttendance from './componentsPage/MainPageAttendance';
const action = new Actions();
const DetailAttendance = ({
  Attendance,
  language,
  APIEndpoints,
  Store,
  setSingleAttendance,
  handleDetail,
  tabs,
  closeDetail,
  Theme,
  listAttendace,
}) => {
  const [page, setPage] = useState(0);
  const [SearchHistoric, setSearchHistoric] = useState('');
  const [slaHistory, setSlaHistory] = useState([]);
  const [clientAttendanceHistory, setClientAttendanceHistory] = useState([]);
  const [openStepDialog, setOpenStepDialog] = useState(false);
  const [stepToShow, setStepToShow] = useState([]);
  const [openStepsManagement, setOpenStepsManagement] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [stepSelected, setStepSelected] = useState([]);
  const [filesAttendance, setFilesAttendance] = useState([]);
  const [filesSolution, setFilesSolution] = useState([]);
  const [listWorkday, setListWorkday] = useState([]);
  const [openUserStart, setOpenUserStart] = useState(false);
  const [openGroupTech, setOpenGroupTech] = useState(false);
  const [openObservers, setOpenObservers] = useState(false);
  const [openReclassifyDialog, setOpenReclassifyDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const [pageStep, setPageStep] = useState('');
  let approveParecer = false;
  let cancelParecer = false;
  let startParecer = false;

  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );
  const getAllTemplates = () => {
    const values = {};

    action
      .execute('post', `${APIEndpoints.SAC_SUBJECTS}/getAllTemplatesForSubjects?`, '', values)
      .then((data) => {
        setTemplateOptions(data.data);
      });
  };

  useEffect(() => {
    getAllTemplates();
    getWorkday();
  }, []);

  useEffect(() => {
    if (
      Attendance &&
      Attendance.files &&
      Attendance.files[0] &&
      Attendance.files[0].sac
    ) {
      setFilesAttendance(Attendance.files[0].sac);
    }
    if (
      Attendance &&
      Attendance.files &&
      Attendance.files[0] &&
      Attendance.files[0].sac_final_attendance
    ) {
      setFilesSolution(Attendance.files[0].sac_final_attendance);
    }
  }, [Attendance]);

  useEffect(() => {
    if (page == 1) {
      action.execute('post',
          APIEndpoints.SAC_ATTENDANCE + '/getAllAttendance','',
          { fk_id_user: Attendance.fk_id_user,
            id_user: Store.getUserLoged().id}
        )
        .then((res) => {
          setClientAttendanceHistory(
            res.data.map((d) => ({
              id: `#${d.id_attendance_sac}`,
              status:
                d.status == 'OPEN'
                  ? 'Aberto'
                  : d.status == 'IN PROGRESS'
                  ? 'Em atendimento'
                  : d.status == 'RESOLVED'
                  ? 'Finalizado'
                  : d.status == 'CLOSED'
                  ? 'Fechado'
                  : d.status,
              dt_cad: moment(d.dt_cad).format('DD/MM/YYYY HH:mm'),
              theme: d.name_theme,
              topic: d.name_topic,
              subject: d.name_subject,
              user: d.name_user,
              description: d.description
                ? d.description.replace(/<\/?[^>]+(>|$)/g, '')
                : '',
              instructions: d.instructions
                ? d.instructions.replace(/<\/?[^>]+(>|$)/g, '')
                : '',
            }))
          );
        });
    } else if (page > 2) {
      action
        .execute('get',APIEndpoints.SAC_STEPS + `/sla/${Attendance.id_attendance_sac}`,'')
        .then((res) => {
          setSlaHistory(
            res.data.map((d) => {
              const obj = {}
              obj.name_step = d.name_step;
              obj.name_user = d.name_user;
              obj.dt_start = moment(d.dt_start).format('DD/MM/YYYY HH:mm');
              obj.dt_finish = moment(d.dt_finish).format('DD/MM/YYYY HH:mm');

              return obj;
            })
          );
        });
    }
  }, [page]);

  const updateAttendance = () => {
    action.execute('get',
        APIEndpoints.SAC_ATTENDANCE + '/' + Attendance.id_attendance_sac
      )
      .then((res) => {
        setSingleAttendance(res.data[0]);
      });
  };

  const onDelete = () => {
    const values = stepSelected;
    values.id_attendance_sac = Attendance.id_attendance_sac;
    values.description = Attendance.description
    values.user = Store.getUserLoged();

    action
      .execute('put', `${APIEndpoints.SAC_STEPS + '/delete'}`,'', values)
      .then(() => {
        updateAttendance()
        setOpenStepsManagement(true)
        setPageStep('');
      });
  };

  const handleOpenTask = (state, step) => {
    setOpenStepDialog(state);
    approveParecer = false;
    cancelParecer = false;
    startParecer = false;
    setStepToShow(step);
  };

  const onDownFileStep = (id_attachment_attendance_sac, file) => {
    DownloadFiles(
      Constants.APIEndpoints.SAC_STEPS +
        '/downloadstep/' +
        id_attachment_attendance_sac +
        '&' +
        file,
      file
    );
  };

  const getWorkday = () => {
    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/workday`,'')
      .then((data) => {
        setListWorkday(data.data);
      });
  };

  const onSubmitStep = (values) => {
    values.user = Store.getUserLoged();
    values.id_attendance_sac = Attendance.id_attendance_sac;
    values.fk_id_sac_subject = Attendance.fk_id_subject;
    values.id_sac_steps = values.id_sac_task;
    const method = values.id_sac_task ? 'put' : 'post';

    action.execute(method, Constants.APIEndpoints.SAC_STEPS, '', values).then((res) => {
      setPageStep('');
      setSingleAttendance(res.data[0]);
    });
  };

  const handlePageStep = (page, obj) => {
    if (page === 'list') {
      setOpenStepsManagement(false);
    }
    setPageStep(page);
    if (page === 'form_task') {
      setStepSelected([]);
    } else if (obj) {
      setStepSelected(obj);
    }
  };

  const htmlBody = (
    <>
      <Header
        btnFilter={[
          'Detalhes',
          'Histórico de Atendimentos',
          'Histórico de Etapas',
          'Histórico de Parecer',
          'Histórico de SLA',
        ]}
        onChange={(e) => setPage(e)}
      />
      {openReclassifyDialog && (
        <AttendenceForm
          values_={Attendance}
          Store={Store}
          language={language}
          callPageSelectFilter={null}
          Constants={Constants}
          action={action}
          setPage={(evt) => setOpenReclassifyDialog(false)}
          setSingleAttendance={setSingleAttendance}
          updateState={updateState}
        />
      )}
      {openObservers && (
        <ObserversAttendance
          APIEndpoints={Constants.APIEndpoints}
          setOpenObservers={setOpenObservers}
          action={action}
          Attendance={Attendance}
          updateAttendance={updateAttendance}
          Store={Store}
        />
      )}
      {openCancelDialog && (
        <CancelAttendance
          APIEndpoints={Constants.APIEndpoints}
          setOpenCancelDialog={setOpenCancelDialog}
          setSingleAttendance={setSingleAttendance}
          Attendance={Attendance}
          action={action}
          Store={Store}
        />
      )}
      {openGroupTech && (
        <GroupAttendance
          APIEndpoints={Constants.APIEndpoints}
          setOpenGroupTech={setOpenGroupTech}
          action={action}
          Attendance={Attendance}
          Store={Store}
          updateAttendance={updateAttendance}
        />
      )}
      {openUserStart && (
        <UserStartAttendance
          APIEndpoints={Constants.APIEndpoints}
          setOpenUserStart={setOpenUserStart}
          action={action}
          Attendance={Attendance}
          Store={Store}
          updateAttendance={updateAttendance}
        />
      )}
      {openFinishDialog && (
        <FinishAttendance
          APIEndpoints={Constants.APIEndpoints}
          setOpenFinishDialog={setOpenFinishDialog}
          action={action}
          Attendance={Attendance}
          Store={Store}
          setSingleAttendance={setSingleAttendance}
          updateState={updateState}
          language={language}
          stepToShow={stepToShow}
        />
      )}
      {openStepDialog && (
        <StepAttendance
          APIEndpoints={Constants.APIEndpoints}
          setOpenStepDialog={setOpenStepDialog}
          Attendance={Attendance}
          Store={Store}
          updateAttendance={updateAttendance}
          stepToShow={stepToShow}
          language={language}
          onDownFileStep={onDownFileStep}
          updateState={updateState}
          action={action}
          approveParecer={approveParecer}
          cancelParecer={cancelParecer}
          startParecer={startParecer}
          json_template_solicitation={json_template_solicitation}
          setPageStep={setPageStep}
        />
      )}
      {openStepsManagement && (
        <TaskList
          language={language}
          currentPage={0}
          handlePage={handlePageStep}
          listTasks={Attendance.steps}
          isStep={true}
        />
      )}
      {pageStep === 'form_task' || pageStep === 'edit_task' ? (
        <TaskForm
          language={language}
          onFormSubmit={onSubmitStep}
          listSubjects={[]}
          Attendance={Attendance}
          taskSelected={stepSelected}
          templateOptions={templateOptions}
          callPageSelectFilter={null}
          handlePage={handlePageStep}
          values={stepSelected}
          handlerPageTask={() => setPageStep('')}
          isStep={true}
          listWorkday={listWorkday}
        />
      ) : null}
      {pageStep === 'remove' ? (
        <CommonDelete
          language={language}
          closeModal={() => setPageStep('')}
          onDelete={onDelete}
          message={null}
        />
      ) : null}
      {page == 0 ? (
        <MainPageAttendance
          APIEndpoints={Constants.APIEndpoints}
          Attendance={Attendance}
          setOpenCancelDialog={setOpenCancelDialog}
          setOpenReclassifyDialog={setOpenReclassifyDialog}
          language={language}
          setOpenGroupTech={setOpenGroupTech}
          setOpenUserStart={setOpenUserStart}
          setOpenStepsManagement={setOpenStepsManagement}
          Store={Store}
          handleOpenTask={handleOpenTask}
          updateState={updateState}
          setSearchHistoric={setSearchHistoric}
          SearchHistoric={SearchHistoric}
          filesAttendance={filesAttendance}
          setOpenObservers={setOpenObservers}
          filesSolution={filesSolution}
          updateAttendance={updateAttendance}
          setSingleAttendance={setSingleAttendance}
          action={action}
          setOpenFinishDialog={setOpenFinishDialog}
        />
      ) : page == 1 ? (
        <Card className="content">
          <CommonTable
            col={[
              { key: 'id', label: language.NUMBER },
              { key: 'status', label: language.STATE },
              { key: 'dt_cad', label: language.DT_CAD },
              { key: 'theme', label: language.THEME },
              { key: 'topic', label: language.TOPIC },
              { key: 'subject', label: language.SUBJECT },
              { key: 'user', label: language.USER },
              { key: 'description', label: language.DESCRIPTION },
              { key: 'instructions', label: language.INSTRUCTION },
            ]}
            data={clientAttendanceHistory}
          />
        </Card>
      ) : page == 2 ? (
        <Card className="content">
          <Grid container spacing={1} className="block-1 mt-2">
            <StepsConcludedPageAttendance
              Attendance={Attendance}
              onDownFileStep={onDownFileStep}
            />
          </Grid>
        </Card>
      ) : page == 3 ? (
        <Card className="content">
          <Grid container spacing={1} xs={12} className="block-1 mt-2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
            {Attendance?.comments?.length > 0 &&
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 15,
                    marginLeft: 10,
                    width: '47%',
                  }}
                >
                  <CommentAttendance
                    updateAttendance={updateAttendance}
                    Attendance={Attendance}
                    language={language}
                    ticket={Attendance}
                    APIEndpoints={APIEndpoints}
                    statusTicket={Attendance.status}
                    maxHeightChat={65}
                    hideNewComment={true}
                  />
                </div>
              }
          </Grid>
        </Card>
      ) : (
        <Card className="content">
          <CommonTable
            col={[
              { key: 'name_step', label: language.STEP },
              { key: 'name_user', label: language.USER },
              { key: 'dt_start', label: language.START_DATE },
              { key: 'dt_finish', label: language.FINAL_DATE },
            ]}
            data={slaHistory}
          />
        </Card>
      )}
    </>
  );

  return (
    <>
      {listAttendace ? (
        <PageLayout
          icon={
            <div
              className="panelico"
              style={{ width: 40, height: 40, overflowY: true }}
            />
          }
          title={language.LIST_OF_ATTENDANCES}
          subTitle={language.CONSUMER_SERVICE_SYSTEM}
        >
          <TabsAttendance
            controlButtonTheme={Theme.controlButton}
            closeDetail={closeDetail}
            tabs={tabs}
            setLocalFiles={() => {}}
            handleDetail={handleDetail}
          />
          <div id="TicketDetail" style={{ marginTop: 10, overflowY: 'auto' }}>
            {htmlBody}
          </div>
        </PageLayout>
      ) : (
        <PageLayout
          title={
            '#' + Attendance.id_attendance_sac + ' - ' + Attendance.name_subject
          }
          back={() => setSingleAttendance([])}
        >
          <div id="TicketDetail" style={{ marginTop: 10, overflowY: 'auto' }}>
            {htmlBody}
          </div>
        </PageLayout>
      )}
    </>
  );
};

export default DetailAttendance;
