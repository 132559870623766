import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { SelectField, Divider } from '@material-ui/core';
import PageLayout from '../../../../common/PageLayout';
import { CSVLink, CSVDownload } from 'react-csv';
import * as style from './styles.scss';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'; //table
import { black } from 'material-ui/styles/colors';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Card, TextField, MenuItem, Paper, Grid } from '@material-ui/core';
import CommonTable from '@common/CommonTable';
import AppBar from '@common/AppBar';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../graphs/SimplesPieGraph.js';

@observer
export default class DeviceTrend extends Component {
  constructor(props) {
    super(props);

    this.handleValues = this.handleValues.bind(this);
    this.renderGraph = this.renderGraph.bind(this);
    this.state = {
      emp: '',
      idContract: '',
    };
  }

  handleValues(values, obj) {
    if (obj == 'empresa') {
      this.setState({ emp: values, idContract: '' });
    } else {
      this.setState({ idContract: values });
    }
  }
  renderGraph(empresa, contracto) {
    let { devices, deviceReport } = this.props;
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    if (empresa != '') {
      devices = devices
        .filter((x) => x.empresa.toUpperCase() == empresa.toUpperCase())
        .map((c) => {
          return c;
        });
    }
    if (contracto != '') {
      devices = devices
        .filter((x) => x.contract.toUpperCase() == contracto.toUpperCase())
        .map((c) => {
          return c;
        });
    }

    var pie1 = [];
    let plat = [];
    devices.map((e) => {
      if (!plat[e.plataforma]) {
        plat[e.plataforma] = {
          name: e.plataforma,
          ['value']: e.valor,
        };
        pie1.push(plat[e.plataforma]);
      } else {
        plat[e.plataforma]['value'] = plat[e.plataforma]['value'] + 1;
      }
    });

    if (pie1.length == 0) {
      pie1 = [
        {
          name: 'VAZIO',
          ['value']: 1,
        },
      ];
    }
    var pie2 = [];
    let sistem = [];
    devices.map((e) => {
      if (!sistem[e.sistem]) {
        sistem[e.sistem] = {
          name: e.sistem,
          ['value']: e.valor,
        };
        pie2.push(sistem[e.sistem]);
      } else {
        sistem[e.sistem]['value'] = sistem[e.sistem]['value'] + 1;
      }
    });
    if (pie2.length == 0) {
      pie2 = [
        {
          name: 'VAZIO',
          ['value']: 1,
        },
      ];
    }
    const spanStyle = {
      fontSize: '12px',
      fontWeight: 'bold',
      marginLeft: '3px',
    };
    var pie3 = [];
    let antivirus = [];
    devices.map((e) => {
      if (!antivirus[e.antivirus]) {
        antivirus[e.antivirus] = {
          name: e.antivirus,
          ['value']: e.valor,
        };
        pie3.push(antivirus[e.antivirus]);
      } else {
        antivirus[e.antivirus]['value'] = antivirus[e.antivirus]['value'] + 1;
      }
    });

    let legends = pie1.map((entry, index) => {
      return (
        <div>
          <strong
            style={{
              fontSize: '9px',
              color: '#fff',
              backgroundColor: COLORS[index % COLORS.length],
              padding: '5px',
            }}
          >
            {entry.name}
          </strong>
          <span style={spanStyle}>{''}</span>
        </div>
      );
    });
    let legends1 = pie2.map((entry, index) => {
      return (
        <div>
          <strong
            style={{
              fontSize: '9px',
              color: '#fff',
              backgroundColor: COLORS[index % COLORS.length],
              padding: '5px',
            }}
          >
            {entry.name}
          </strong>
          <span style={spanStyle}>{''}</span>
        </div>
      );
    });

    let legends2 = pie3.map((entry, index) => {
      return (
        <div>
          <strong
            style={{
              fontSize: '9px',
              color: '#fff',
              backgroundColor: COLORS[index % COLORS.length],
              padding: '5px',
            }}
          >
            {entry.name}
          </strong>
          <span style={spanStyle}>{''}</span>
        </div>
      );
    });
    return {
      pie1,
      pie2,
    };
    /*return (
      <div className="graficos">
        <div
          style={{
            width: '5%',
            marginLeft: '80px',
            padding: '0px 0px 0px 0px',
            float: 'left'
          }}
        >
          {legends}
        </div>
        <div style={{ width: '30%', textAlign: 'left', float: 'left' }}>
          <ResponsiveContainer
            padding="0 0 0 0"
            width="100%"
            aspect={4.0 / 3.0}
          >
            <PieChart>
              <Pie
                data={pie1}
                labelLine={true}
                label={renderCustomizedLabel}
                fill="#8884d8"
              >
                {pie1.map((entry, index) => (
                  <Cell fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div style={{ width: '20%', float: 'right' }}>{legends1}</div>
        <div
          style={{ width: '30%', padding: '0px 0px 0px 0px', float: 'right' }}
        >
          <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
            <PieChart>
              <Pie
                data={pie2}
                labelLine={false}
                label={renderCustomizedLabel}
                fill="#8884d8"
              >
                {pie2.map((entry, index) => (
                  <Cell fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    );*/
  }

  render() {
    let { language, handlePage, devices, companies, contracts, deviceReport } =
      this.props;
    let { emp, idContract, showGraph } = this.state;

    let filtro = '';
    let empresa = '';
    let contracto = '';
    if (emp != '') {
      filtro = companies
        .filter((c) => c.id_company === emp)
        .map((c) => {
          return (empresa = c.name), 'Filtro Empresa: ' + c.name;
        });
    }
    const options = {
      title: {
        text: 'My chart',
      },
      series: [
        {
          data: [1, 2, 3],
        },
      ],
    };

    if (idContract != '') {
      filtro =
        filtro +
        contracts
          .filter((c) => c.id_contract === idContract)
          .map((c) => {
            return (contracto = c.name), ' / Contract: ' + c.name;
          });
    }
    if (empresa != '') {
      devices = devices
        .filter((x) => x.empresa.toUpperCase() == empresa.toUpperCase())
        .map((c) => {
          return c;
        });
    }
    if (contracto != '') {
      devices = devices
        .filter((x) => x.contract.toUpperCase() == contracto.toUpperCase())
        .map((c) => {
          return c;
        });
    }

    let csvData = [];
    csvData.push([
      'dispositivo',
      'contrato',
      'memory_capacity',
      'memory_free',
      'memory_manufacturer',
      'memory_part_number',
      'memory_serial_number',
      'cpu_name',
      'cpu_l2_cache',
      'cpu_l3_cache',
      'cpu_max_clock_speed',
      'cpu_number_cores',
      'cpu_current_clock_speed',
      'board_manufacturer',
      'board_product',
      'board_serial_number',
      'board_version',
      'bios_manufacturer',
      'bios_name',
      'bios_serial_number',
      'bios_version',
      'computer_manufacturer',
      'computer_model',
      'computer_workgroup',
      'os_build_number',
      'os_caption',
      'os_version',
      'os_total_virtual_memory',
      'os_last_boot_time',
      'os_country_code',
      'os_install_date',
      'os_registered_user',
      'os_serial_number',
      'logicaldisk_caption',
      'logicaldisk_volume_name',
      'logicaldisk_file_system',
      'logicaldisk_size',
      'logicaldisk_free_space',
      'logicaldisk_volume_serial_number',
      'disk_caption',
      'disk_firmware_revision',
      'disk_bytes_sector',
      'disk_manufacturer',
      'disk_serial_number',
      'disk_size',
      'disk_sectors',
      'disk_tracks',
      'disk_heads',
    ]);

    deviceReport.map((d) => {
      csvData.push([
        d.dispositivo,

        d.contract,
        d.memory_capacity,
        d.memory_free,
        d.memory_manufacturer,
        d.memory_part_number,
        d.memory_serial_number,
        d.cpu_name,
        d.cpu_l2_cache,
        d.cpu_l3_cache,
        d.cpu_max_clock_speed,
        d.cpu_number_cores,
        d.cpu_current_clock_speed,
        d.board_manufacturer,
        d.board_product,
        d.board_serial_number,
        d.board_version,
        d.bios_manufacturer,
        d.bios_name,
        d.bios_serial_number,
        d.bios_version,
        d.computer_manufacturer,
        d.computer_model,
        d.computer_workgroup,
        d.os_build_number,
        d.os_caption,
        d.os_version,
        d.os_total_virtual_memory,
        d.os_last_boot_time,
        d.os_country_code,
        d.os_install_date,
        d.os_registered_user,
        d.os_serial_number,
        d.logicaldisk_caption,
        d.logicaldisk_volume_name,
        d.logicaldisk_file_system,
        d.logicaldisk_size,
        d.logicaldisk_free_space,
        d.logicaldisk_volume_serial_number,
        d.disk_caption,
        d.disk_firmware_revision,
        d.disk_bytes_sector,
        d.disk_manufacturer,
        d.disk_serial_number,
        d.disk_size,
        d.disk_sectors,
        d.disk_tracks,
        d.disk_heads,
      ]);
    });

    let selectItens = companies.map((c) => (
      <MenuItem key={c.id_company} value={c.id_company} primaryText={c.name}>
        {c.name}
      </MenuItem>
    ));

    let contractList = [];
    if (emp != '') {
      contractList = contracts
        .filter((x) => x.fk_id_company === emp)
        .map((c) => (
          <MenuItem
            key={c.id_contract}
            value={c.id_contract}
            primaryText={c.name}
          >
            {c.name}
          </MenuItem>
        ));
    }
    var gp = this.renderGraph(empresa, contracto);

    return (
      <PageLayout
        icon={
          <div className="imgGi" style={{ width: '44px', height: '44px' }} />
        }
        back={()=>   handlePage('dash')}
        title={language.DEVICE}
        subtitle={language.TREND_ANALYSIS_BY_DEVICE}
        style={{ zoom: 0.8 }}
        rightElements={[
          <TextField
            id="outlined-select-currency"
            select
            label={language.COMPANY}
            style={{ minWidth: 200, zoom: 0.8 }}
            value={emp}
            onChange={(evt) => this.handleValues(evt.target.value, 'empresa')}
            margin="dense"
            variant="outlined"
          >
            {selectItens}
          </TextField>,
          <TextField
            id="outlined-select-currency"
            select
            label={language._CONTRACT}
            style={{ minWidth: 200, zoom: 0.8 }}
            value={idContract}
            onChange={(evt) => this.handleValues(evt.target.value, 'contract')}
            margin="dense"
            variant="outlined"
            className="mx-4"
          >
            {contractList}
          </TextField>,
          <CSVLink data={csvData}>
            <RaisedButton
              label="Export Excel"
              style={{ fontSize: 17 }}
              primary
              circleButton
              icon="fas fa-file-excel"
              color="primaryGradient"
            />
          </CSVLink>,

          <RaisedButton
            circleButton
            icon="fas fa-arrow-left"
            color="primary"
            label={language.BACK}
            primary
            onClick={() => handlePage('dash')}
            className="mx-2"
            style={{ marginRight: 0 }}
          />,
        ]}
      >
        <Grid container spacing={16} className="-mt-3">
          <Grid item xs={6}>
            <Paper style={{ marginTop: 20 }}>
              <AppBar light>
                <span className="font titlePattern">
                  {language.INVENTORY_REPORT}
                </span>
              </AppBar>
              <div className="content">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <ReactHighcharts
                      config={ContainerChart.container0({
                        title: gp.pie1.name,
                        size: 200,
                        data: [
                          { name: gp.pie1[0].name, y: gp.pie1[0].value * 100 },
                          { name: ' ', y: (1 - gp.pie1[0].value) * 100 },
                        ],
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ReactHighcharts
                      config={ContainerChart.container0({
                        title: gp.pie2.name,
                        size: 200,
                        data: [
                          { name: gp.pie2[0].name, y: gp.pie2[0].value * 100 },
                          { name: ' ', y: (1 - gp.pie2[0].value) * 100 },
                        ],
                      })}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <CommonTable
              paginationTop={false}
              col={[
                { key: 'device', label: language.DEVICE },
                { key: 'company', label: language.COMPANY },
                { key: 'contract', label: language._CONTRACT },
              ]}
              data={devices.map((d) => ({
                device: d.dispositivo,
                company: d.empresa,
                contract: d.contract,
              }))}
            />
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}
