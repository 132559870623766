import React, { Fragment } from 'react';
import io from 'socket.io-client';
import moment from 'moment';
import CommonForm from '@common/CommonForm2';
// ---------------------------common---------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import RadialGraph from '@common/RadialGraph';
import HomeEdit from '../monitor2/indexEdit'
import AppBar2 from '@common/AppBar2';
import PieChart from '@common/PieChart';
import Header from '@common/Header';
import AssessmentTerminal from '../assessment/AssessmentTerminal';
import GuacamoleView from '../guaca/index';
// -------------------------material-ui------------------------------------
import { Card, Grid, Checkbox, TextField, MenuItem } from '@material-ui/core';
// ------------------------------------------------------------------------
import classnames from 'classnames';
import { observer } from 'mobx-react';
import GroupAlerts from './GroupAlerts'
import './index.css';
import Constants from '../../data/Constants';
import devices from 'material-ui/svg-icons/device/devices';
import TableType from './TableType';
import TableTypeAlerts from './TableTypeAlerts';
import GI2Context from './GI2Context';
import TableTypeAv from './TableTypeAv'
import Button from '@common/Button';
import AlertChart from './AlertChart'

let danger = [];
@observer
export default class Monitor extends CommonPage {
  constructor() {
    super();
    this.state = {
      countYellow: [],
      countRed: [],
      companyId: 0,
      alerts: io.connect(Constants.ALERT_SERVERS, {
        path: '/server/alert',
        query: 'user=' + this.store.getUserLoged().id + '&token=' + this.store.getToken(),
        transports: ["websocket"] 
      }),

      ioServers: io.connect(Constants.AGENT_SERVERS, {
        path: '/server',
        query:
          'user=' +
          this.store.getUserLoged().id +
          '&token=' +
          this.store.getToken(),
          transports: ["websocket"] 
      }),
      ioLinks: io.connect(Constants.AGENT_LINKS, {
        path: '/icmp',
        query:
          'user=' +
          this.store.getUserLoged().id +
          '&token=' +
          this.store.getToken(),
          transports: ["websocket"] 
      }),
      hold: '',
      list: [],
      page: 2,
      position: 0,
      openas: false,
      device: [],
      companies: [],
      MonitoresAva: [],
      idgroup:0
    };
    this.handleCompany = this.handleCompany.bind(this);
    this.findAlerts = this.findAlerts.bind(this);
    this.refresh_ = this.refresh_.bind(this);
    this.startAssessment = this.startAssessment.bind(this);
    this.finishAssessment = this.finishAssessment.bind(this);
    this.getAvailability = this.getAvailability.bind(this);
  }

  refreshPage() {
    //this.componentWillMount();
    //finishAssessment();
    this.setState({ page: this.state.page })
    this.setState({ companyId: 0 })
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }, null, null, null
    )
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/available',
      'devices',
      { id_user: null }, null, null, null
    );

    this.action.execute('get', this.APIEndpoints.ITEM, 'items');
    this.action.execute('get', this.APIEndpoints.COMPANY + '/monitor', 'companies').then(c => {
      this.setState({ companies: c.data })
    })
  }

     getAvailability(value){
   this.setState({idgroup:value})
      }
  componentWillMount() {
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }, null, null, null
    )
    this.action.execute('post', this.APIEndpoints.MONITOR + '/deviceavailavility', '', {}).then(c => {
      this.setState({ MonitoresAva: c.data })
    })
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/available',
      'devices',
      { id_user: null }, null, null, null
    );
    this.action.execute('get', this.APIEndpoints.ITEM, 'items');
    this.action.execute(
      'get',
      this.APIEndpoints.MONITOR + '/groups',
      'monitorGroups'
    );

    this.action.execute('get', this.APIEndpoints.COMPANY + '/monitor', 'companies').then(c => {
      this.setState({ companies: c.data })
    })
       
     if(this.store.getUserLoged().role=='solicitant'){
      this.setState({ companyId: this.store.getUserLoged().id_company });
     }
     
   

  }

  startAssessment(device) {
    /* window.onbeforeunload = function () { return false; }
     document.addEventListener("click", e => {
       if (e.target.outerText == "Finalizar" ||
         e.target.outerText == "FullScrean"
 
       ) {
 
       } else {
         e.stopPropagation();
         e.preventDefault();
       }
 
     }, true);*/
 
    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT,
      'assessment',
      {
        device: device.fk_id_device,
        tickets: undefined,
        contract: 1
      },
      null,
      'Waiting for remote connection'
    ).then(b => {
   
      this.setState({ openas: true, device: device })
    })
  }

  finishAssessment() {
    let { assessment } = this.store;
    this.action
      .execute(
        'post',
        this.APIEndpoints.ASSESSMENT,
        'assessment',
        { tickets: [], path: assessment[0].path, note: '' },
        null,
        'Finished the remote connection'
      )
      .then(e => {
        this.setState({ openas: false })
      });
  }

  findAlerts(danger, warning) {
    this.setState({ page: 2, countRed: danger, countYellow: warning })
  }

  handleCompany(values) {
    if (values == 99999) {
      values = 0;
    }
    this.setState({ companyId: values });
  }

  refresh_(p) {
    this.setState({ position: p })
  }

  render() {
    let {
      language,
      dashboard,
      usercon_company,
      store
    } = this.store;

    let {
      countOk,
      countYellow,
      countRed,
      countTotal,
      companyId,
      graficPercent,
    } = this.state;

    let monitorGroups =
      companyId > 0 ?
        this.store.monitorGroups.filter(e => e.id_company == companyId) :
        this.store.monitorGroups;
    let listdevice = [];
    let listdeviceDis = [];
    if (this.store.getUserLoged().role == 'solicitant') {
      listdevice = monitorGroups
        .reduce((arr, i) => {
          i.groups.map((e) => {
            e.devices.filter(v => (v.type == 'link' || v.type == 'server')
            ).map((e) => {
              arr.push(e.id_device);
            });
          });
          return arr;
        }, [])
    } else {
      listdevice = null
    }
    if(this.state.page==3){
      listdeviceDis = monitorGroups
        .reduce((arr, i) => {
          i.groups.map((e) => {
            e.devices.filter(v => (v.type == 'link' || v.type == 'server')
            ).map((e) => {
              arr.push(e.id_device);
            });
          });
          return arr;
        }, [])
   
      }






    
    let devices = companyId > 0 ? this.store.devices.filter(e => e.fk_id_company == companyId) :
      this.store.devices;
    /*window._count = {countYellow, countRed, countTotal, devices, monitorGroups, graficPercent};
    window._companyId = companyId;*/
    let a = moment().startOf('month')
    let b = moment();
    let seconds = b.diff(a, 'seconds')
    let total = 0
    devices.filter(b => b.valor > 0).map(g => {
      total = total + parseInt(g.valor);
    })
    if (devices.length < 3) {
      devices.push({ name: '', valor: 0 })
      devices.push({ name: '', valor: 0 })
      devices.push({ name: '', valor: 0 })
    }

    let companiesArray = this.state.companies.filter(c => c.dt_del == null).map(v => ({ value: v.id_company, label: v.name }))
    companiesArray.push({ value: 99999, label: language.ALL })
    return (
      <CommonPage loading={false} showMenuIconButton={true} iconAlert={true}>
        <PageLayout
          icon={
            <div className="imgGi titleIconSize" style={{ width: 44, height: 44 }} />
          }
          title={language.DASHBOARD_PAGE.GI}
          subTitle={language.AVAILABILITY_MANAGEMENT + '/'}
          rightElements={[
            <div style={{ width: 200, zoom: 0.8, marginTop: '1rem' }} zoom={0.8}>
              <CommonForm
                spacing={16}
                values={{ fk_id_company: this.state.companyId }}
                alert={false}
                onChangeField={(f, v) => {
                  this.handleCompany(v)
                }}
                fields={[
                  {
                    col: 2,
                    type: 'element',
                    content: (v, h, submit) => (
                      <Button
                        icon="fas fa-sync-alt"
                        variant="circle"
                        color="primary"
                        label={language.UPDATE}
                        onClick={() => this.refreshPage()}
                        style={{ marginTop: 15 }}
                      />
                    )
                  },
                  {
                    col: 10,
                    type: 'select',
                    name: 'fk_id_company',
                    label: language.COMPANY,
                    options: companiesArray,
                    required: true
                  },
                ]
                } ></CommonForm></div>]} >

          {this.state.openas == true ?
            <div>
              {this.state.device.connection != 'rdp' ?
                <AssessmentTerminal
                  language={language}
                  store={store}
                  ticketsSelected={[]}
                  store={this.store}
                  finishAssessment={this.finishAssessment}
                  connection={this.state.device.connection}
                />
                :
                <GuacamoleView
                  language={language}
                  user={null}
                  password={null}
                  domain={null}
                  path={this.store.assessment && this.store.assessment[0].path}
                  finishAssessment={this.finishAssessment}
                  device={this.state.device}
                >
                  {' '}
                </GuacamoleView>}
            </div>
            :
            <section id="GI">
              {/*##############################################################-BLOCO-1-#################################################################################*/}
              <Header
                active={this.state.page - 1}
                btnFilter={[language.MONITOR, language.ALERT, language.AVAILABILITY]}
                onChange={e => {
                  this.setState({ page: e + 1, countRed: [], countYellow: [] })
                }}
              />

              <Card className='content mb-3' style={{ borderRadius: '0 0 4px 4px' }}>
                <Grid container spacing={16}>
                  {/*---------------------------------------------BLOCO-1 - Coluna 1-----------------------------------------------------*/}
                  {this.state.page == 2 ?
                    <Grid item xs={4} className='content-b1-c1'>
                      <Card className='h-100'>
                        <AppBar2 title={`${language.PERCENTAGE_OF} ic's`} />
                        <AlertChart listdevice={listdevice} alerts={this.state.alerts} position={this.state.position} findAlerts={this.findAlerts} companyId={companyId}  ></AlertChart>
                      </Card>
                    </Grid> : null}
                  {/*---------------------------------------------BLOCO-1 - Coluna 2-----------------------------------------------------*/}
                  {this.state.page == 2 ?
                    <Grid item xs={8} className='content-b1-c2'>
                      <Card className='h-100'>
                        <AppBar2 title={language.ALERTS_GROUP} />
                        <GroupAlerts listdevice={listdevice} alerts={this.state.alerts} position={this.state.position} findAlerts={this.findAlerts} companyId={companyId}  >
                        </GroupAlerts>
                      </Card>
                    </Grid> : null}

                  {this.state.page == 3 ?
                    <Grid item xs={9} className='content-b1-c2'>
                      <Card className='h-100'>
                        <AppBar2 title={language.ICS_WITH_LESS_AVAILABILITY_OF_CURRENT_MONTH} />
                        <Grid container className='content' style={{ height: '200px', overflowX: 'hidden', overflowY: 'scroll' }}>
                          {this.state.MonitoresAva.filter(v=>listdeviceDis.includes(v.id_device)).map(device => {
                            return {
                              label: device.name,
                              percent: parseInt(device.percent),
                              up: parseInt(device.percent)
                            }
                          }).sort(function (a, b) {
                            return a.percent < b.percent ? -1 : a.percent > b.percent ? 1 : 0;
                          })
                            .map((e, i) => (
                              <Grid item xs={2} key={i + '-' + e.label} >
                                <div className='content-graph'>
                                  <PieChart
                                    percent={/*parseFloat((e.percent||0).toFixed(1))*/e.percent}
                                    color={
                                      e.percent >= 60 ? 'success' :
                                        e.percent <= 59 && e.percent >= 35 ? 'warning' :
                                          e.percent <= 34 ? 'danger' : undefined
                                    }
                                    strokeWidth={2.8}
                                  />
                                </div>
                                <div className='content-info-text ml-2 mb-2'>
                                  <span>{e.label}</span>
                                  <h2
                                    style={{
                                      color: (
                                        e.up != undefined ? 'var(--success)' : 'var(--danger)'
                                      )
                                    }}
                                  >
                                  </h2>
                                </div>
                              </Grid>
                            ))}
                        </Grid>

                      </Card>
                    </Grid> : null}
                  {/*---------------------------------------------BLOCO-1 - Coluna 3-----------------------------------------------------*/}
                  {this.state.page == 3 ?
                    <Grid item xs={3} className='content-b1-c3'>
                      <Card className='h-100'>
                        <AppBar2 title={language.AVERAGE_AVAILABILITY} />
                        <div className='d-flex align-items-end content'>
                          <div className='content-graph'>
                            <PieChart
                              percent={
                                parseInt((this.state.MonitoresAva.reduce((acc, item) => {
                                  return parseInt(acc) + parseInt(item.percent)
                                }, 0) / this.state.MonitoresAva.length))
                              }
                              color='blue'
                              strokeWidth={2.8}
                            />
                          </div>
                          <div className='content-info-text ml-2 mb-3'>
                            <span>Total</span>
                            <h2
                              style={{
                                color: 'var(--success)'
                              }}
                            >
                              <i className='fas fa-arrow-up' />
                              {
                                parseInt((this.state.MonitoresAva.reduce((acc, item) => {
                                  return parseInt(acc) + parseInt(item.percent)
                                }, 0) / this.state.MonitoresAva.length))
                              }%
                      </h2>
                          </div>
                        </div>
                      </Card>
                    </Grid> : null}
                  {this.state.page == 1 && this.store.getUserLoged().role != 'solicitant' ?
                    <Grid item xs={12}>
                      <Card className='h-100'>
                        <HomeEdit getAvailability={this.getAvailability} monitorGroups={monitorGroups}></HomeEdit>
                      </Card>
                    </Grid> : null}
                </Grid>
              </Card>
              {/*#########################################################-BLOCO-2 - TABELA-############################################################################*/}
              {this.state.page == 1 ?
                <TableType
                  countOk={[]}
                  idgroup={this.state.idgroup}
                  refresh_={this.refresh_}
                  finishAssessment={this.finishAssessment}
                  ioLinks={this.state.ioLinks}
                  countRed={this.state.countRed}
                  countYellow={this.state.countYellow}
                  page={this.state.page}
                  connectionRemote={this.connectionRemote}
                  companies={this.state.companies}
                  ioServers={this.state.ioServers}
                  monitorGroups={monitorGroups}
                  companyId={companyId}
                  language={language}
                  store={this.store}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  countTotal={countTotal}
                  startAssessment={this.startAssessment}
                  devices={this.store.device} />
                : this.state.page == 2 ?
                  <TableTypeAlerts
                    countOk={[]}
                    refresh_={this.refresh_}
                    ioLinks={this.state.ioLinks}
                    countRed={this.state.countRed}
                    countYellow={this.state.countYellow}
                    page={this.state.page}
                    alerts={this.state.alerts}
                    listdevice={listdevice}
                    startAssessment={this.startAssessment}
                    companies={this.state.companies}
                    ioServers={this.state.ioServers}
                    monitorGroups={monitorGroups}
                    connectionRemote={this.connectionRemote}
                    companyId={companyId}
                    language={language}
                    action={this.action}
                    APIEndpoints={this.APIEndpoints}
                    countTotal={countTotal}
                    devices={this.store.device} />
                  :
                  <TableTypeAv
                    countOk={[]}
                    refresh_={this.refresh_}
                    finishAssessment={this.finishAssessment}
                    MonitoresAva={this.state.MonitoresAva}
                    ioLinks={this.state.ioLinks}
                    countRed={this.state.countRed}
                    countYellow={this.state.countYellow}
                    page={this.state.page}
                    connectionRemote={this.connectionRemote}
                    companies={this.state.companies}
                    ioServers={this.state.ioServers}
                    monitorGroups={monitorGroups}
                    companyId={companyId}
                    language={language}
                    store={this.store}
                    action={this.action}
                    APIEndpoints={this.APIEndpoints}
                    countTotal={countTotal}
                    startAssessment={this.startAssessment}
                    devices={this.store.device} />
              }
            </section>
          }
        </PageLayout>
      </CommonPage>
    );
  }
}
