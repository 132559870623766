import React, { Component } from 'react';
// import Modal from 'react-awesome-modal';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import addLineAndColumn from './addLineAndColumn.js';

import {TextField, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core'; //inputs
import {Dialog, DialogContent} from '@material-ui/core'; //digalog
import {Button} from '@material-ui/core';

function fieldsP(){
	
	return {
		an:{order:1,name:'', rowspan:1},
		pbm:{order:2,name:'', rowspan:1},
		aa:{order:3,name:'', rowspan:1},
		pc:{order:4,name:'', rowspan:1},
		am:{order:5,name:'', rowspan:1},
		pb:{order:6,name:'', rowspan:1},
		ipt:{order:7,name:'', rowspan:1},
		rsc:{order:8,name:'', rowspan:1},
		rsp:{order:9,name:'', rowspan:1},
		dta:{order:10,name:'', rowspan:1},
		ds:{order:11,name:'', rowspan:1},
		at:{order:12,name:'', rowspan:1},
	}
}

function in_array(val, arr){
	return arr.indexOf(val)!=-1?true:false;
}
class ModalExtend extends Component{

	constructor(props){
		super(props);
		
		this.state = {
			...props.state,
			fields:fieldsP(),
			ModalExtendData:{cord:{}, countFields:0, fields:[]}
		}
	}
	
	changeField = (e)=>{
		
		if(!!e.target.name){
			
			var fields = this.state.fields
			fields[e.target.name].name=e.target.value
			this.setState({
				fields
			});
			
		}
	}

	save = ()=>{
		var data = [];
		var i;
		this.state.ModalExtendData.fields.map((field)=>{
			i = this.state.fields[field];
			data[i.order] = i;
		});
		this.setState(state=>({
			fields:fieldsP()
		}))

		if(this.state.ModalExtendData.countFields!=0){
			var cord = this.state.ModalExtendData.cord;
		}else{
			var cord = null;
		}

		addLineAndColumn(this.state.tbody, cord, data.filter(Boolean));
		this.props.toggleModalExtend(false);
	}
	componentWillReceiveProps(nextProps){
		
		if(nextProps.visible){
			
			var state = nextProps.state;
			
			if(state.ModalExtendData.countFields!=0){
				state.ModalExtendData.fields = Object.keys(this.state.fields).slice((state.ModalExtendData.cord.td + (12 - state.ModalExtendData.countFields)),12);
			}else{
				state.ModalExtendData.fields = Object.keys(this.state.fields);
			}
			
			this.setState({
				...state
			});
		}
		
	}
	toggleModalExtend = ()=>{
		this.props.toggleModalExtend(false);
	}
	render(){
		return (
	       
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.visible}>
	            <DialogContent style={{width:'300px'}}>
	                <form acttion="" method="POST" className='Modal-content' name='form-fields'>
	               
		                {in_array('an',this.state.ModalExtendData.fields) &&
		                	<div className="form-group">
			                  <TextField className="w-100 mb-3" name="an" label="Área de negócio" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('pbm',this.state.ModalExtendData.fields) &&
			                <div className="form-group">
			                  <TextField className="w-100 mb-3" name="pbm" label="Problemas" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('aa',this.state.ModalExtendData.fields) &&
			                <div className="form-group">
			                  <TextField className="w-100 mb-3" name="aa" label="Área afetada" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('pc',this.state.ModalExtendData.fields) &&
			                <div className="form-group">
			                  <TextField className="w-100 mb-3" name="pc" label="Preocupações" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('am',this.state.ModalExtendData.fields) &&
			                <div className="form-group">
			                  <TextField className="w-100 mb-3" name="am" label="Ameaças" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('pb',this.state.ModalExtendData.fields) &&
			                <FormControl className="w-100 mb-3">
		          			  <InputLabel htmlFor="age-simple">Probabilidade</InputLabel>
						      <Select onChange={this.changeField} value={this.state.fields.pb.name} inputProps={{name:'pb'}}>
						        <MenuItem value={'auto'}>auto</MenuItem>
						        <MenuItem value={'medio'}>medio</MenuItem>
						        <MenuItem value={'baixo'}>baixo</MenuItem>
						        <MenuItem value={'moderado'}>Moderado</MenuItem>
						      </Select>
						    </FormControl>
						}
						{in_array('ipt',this.state.ModalExtendData.fields) &&
			                <FormControl className="w-100 mb-3">
			                	<InputLabel htmlFor="age-simple">Impacto</InputLabel>
							      <Select onChange={this.changeField} value={this.state.fields.ipt.name} inputProps={{name:'ipt'}}>
							        <MenuItem value={'auto'}>auto</MenuItem>
							        <MenuItem value={'medio'}>medio</MenuItem>
							        <MenuItem value={'baixo'}>baixo</MenuItem>
							      </Select>
						    </FormControl>
						}
						{in_array('rsc',this.state.ModalExtendData.fields) &&
			               <FormControl className="w-100 mb-3">
			               		<InputLabel htmlFor="age-simple">Risco</InputLabel>
						      <Select onChange={this.changeField} value={this.state.fields.rsc.name} inputProps={{name:'rsc'}}>
						        <MenuItem value="auto">auto</MenuItem>
						        <MenuItem value="medio">medio</MenuItem>
						        <MenuItem value="baixo">baixo</MenuItem>
						      </Select>
						    </FormControl>
						}
						{in_array('rsp',this.state.ModalExtendData.fields) &&
			                <div className="form-group">
			                  <TextField className="w-100 mb-3" name="rsp" label="Responsável" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('dta',this.state.ModalExtendData.fields) &&
			                <div className="form-group">
			                  <TextField className="w-100 mb-3" name="dtn" label="Data atividade" onChange={this.changeField}/>
			                </div>
			            }
			            {in_array('ds',this.state.ModalExtendData.fields) &&
			                <FormControl className="w-100 mb-3">
			                	<InputLabel htmlFor="age-simple">Desejo</InputLabel>
						      <Select onChange={this.changeField} value={this.state.fields.ds.name} inputProps={{name:'ds'}}>
						        <MenuItem value="auto">auto</MenuItem>
						        <MenuItem value="medio">medio</MenuItem>
						        <MenuItem value="baixo">baixo</MenuItem>
						      </Select>
						    </FormControl>
						}
						{in_array('at',this.state.ModalExtendData.fields) &&
			                <FormControl className="w-100 mb-3">
			                	<InputLabel htmlFor="age-simple">Atual</InputLabel>
						      <Select onChange={this.changeField} value={this.state.fields.at.name} inputProps={{name:'at'}}>
						        <MenuItem value="auto">auto</MenuItem>
						        <MenuItem value="medio">medio</MenuItem>
						        <MenuItem value="baixo">baixo</MenuItem>
						      </Select>
						    </FormControl>
						}
					

					    <div className="w-100 d-flex justify-content-end">
					    	<Button variant="contained" onClick={this.toggleModalExtend} color="secondary" className="mr-3">Cancelar</Button>
					    	<Button variant="contained" onClick={this.save} color="primary">Salvar</Button>
	                	</div>
	                </form>
                </DialogContent>
            </Dialog>
	       
	    );
	}
}
export default ModalExtend;