import React, { useState } from 'react';

// ----------------------------------------------------------------------

import Animate from '@common/Animate';

import { ChannelList } from './ChannelList';
import { ChannelDelete } from './ChannelDelete';
import { ChannelAddOrEdit } from './ChannelAddOrEdit';

// ----------------------------------------------------------------------

const ChannelManagementList = () => {
  const [page, setPage] = useState(null);
  const [editChannel, setEditChannel] = useState(null);
  const initialChannelState = {
    channelId: null,
    channelName: '',
  };
  const [channelSelected, setChannelSelected] = useState(initialChannelState);

  return (
    <Animate>
      <ChannelList
        setChannelSelected={setChannelSelected}
        setEditChannel={setEditChannel}
        setPage={setPage}
        page={page}
      />

      {page === 'delete' && (
        <ChannelDelete
          closeModal={() => {
            setPage(null);
            setChannelSelected(initialChannelState);
          }}
          id={channelSelected?.channelId}
          message={channelSelected?.channelName}
        />
      )}

      {(page === 'add' || page === 'edit') && (
        <ChannelAddOrEdit
          closeModal={() => {
            setChannelSelected(initialChannelState);
            setPage(null);
          }}
          page={page}
          editChannel={editChannel}
        />
      )}
    </Animate>
  );
};

export default ChannelManagementList;
