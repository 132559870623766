import React, { Component } from 'react';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import CommonPage from '../../../common/CommonPage';
import { Container, Row, Col } from 'react-grid-system';
import Progress from '../../../common/Progress';
import moment from 'moment';
import { Card } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '../../../common/RaisedButton';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Grid,
  Paper
} from '@material-ui/core'; //List
import Constants from '../../../data/Constants';
import { MenuItem, IconButton, Menu, Fade } from '@material-ui/core'; // Menu config
import { percent, mem } from '../../../util/MonitorUtils';
import './MonitorHome.css';
import CommonDelete from '../../../common/CommonDelete';
import { Radial } from 'sensrit-charts';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import Monitor from './monitor/monitor.js';
import { Chip, Avatar } from '@material-ui/core';
import GroupModal from '../GroupModal';
import Server from '../Server';
import ServerSnmp from '../ServerSnmp';
import Link from '../Link';
import LinkNew from '../LinkNew';
import PageLayout from '@common/PageLayout';
import DeviceGroup from '@images/device-group.png';

import ServerConfig from '../ServerConfig';

const tenant = Constants.Tenant;

// --------------------------------Função ícone----------------------------------
/*function Icon(props){
  return(
    <div className='iconContainer'>
      <div className='statusCustomIcon' style={{background:props.color}}>    
            <i className={`fas fa-${props.icon}`}></i>
          </div>
          {!!props.current && <span>{props.current}</span>}
        </div>
  )
}*/
// --------------------------------funções----------------------------------
function Graph_circle(props) {
  return (
    <div className="graphs-item">
      <h4 className="title">{props.title}</h4>
      <Radial
        low={{ color: '#33ABA0' }}
        mid={{ color: '#FFC107', value: 60 }}
        high={{ color: '#F44336', value: 80 }}
        className="host-graph-perc radial-graph-item-of-group"
        size={60}
        current={props.current}
        total={100}
        thick={8}
      >
        <h4 className="d-flex align-items-center">
          <i className={props.icon}></i> {props.current}%
        </h4>
      </Radial>
      <h4 className="sub-title">{props.subTitle}</h4>
    </div>
  );
}

function Graph_badge(props) {
  return (
    <div className="graphs-item">
      <Radial
        low={{ color: props.color }}
        mid={{ color: props.color }}
        high={{ color: props.color }}
        className="host-graph-perc radial-graph-item-of-group"
        size={40}
        current={props.current}
        total={props.current}
        thick={8}
      >
        <h4 className="d-flex align-items-center">{props.current}</h4>
      </Radial>
    </div>
  );
}

function Graph_progress(props) {
  return (
    <div className="graphs-progress-item">
      {!!props.title && (
        <span className="title" style={{ fontWeight: 900 }}>
          {props.title}
        </span>
      )}
      <Progress
        low={{ color: '#33ABA0' }}
        mid={{ color: '#FFC107', value: props.warning }}
        high={{ color: '#F44336', value: props.danger }}
        current={props.current}
      />
    </div>
  );
}

let host = '';
let tokenApi = '';
var getConnection = '';
// -----------------------------------------------------------(COMPONENTE - ListGroupItem)-------------------------------------------------------------------------
class ListGroupItem extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ListGroupItemShow: false,
      anchorEl: null,
      open: null,
      available: false,
      mount: true,
      hold: '',
      aliveHold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disk: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      removeloading: true,
      dateUnavailableServer: '',
      open: '',
      online: false
    };
    //	this.aliveSignal = this.aliveSignal.bind(this);
    //	this.fetchAll    = this.fetchAll.bind(this);
  }

  componentWillMount() {
    if (this.props.ioServers != undefined) {
      this.props.ioServers.on(`_${tenant}_${this.props.device.id_device}`, data => {
        let getDevice = this.props.monitor.find(
          m => m.id_device === this.props.device.id_device
        );
        clearTimeout(this.state.hold);

        if (data.offline == false) {
          this.setState({ online: true });
        } else {
          this.setState({ online: false });
        }

        if (data.item == 'cpu-stats') {
          let cp = data.data;
          let cpuUsedDash = cp.usedPercent;

          if (getDevice != undefined) {
            if (
              parseInt(getDevice.conf[data.item].triggers.danger.value) <=
              parseInt(cpuUsedDash)
            ) {
              this.props.updateCountMaster(
                'r',
                this.props.device.id_device + 'c'
              );
            } else if (
              parseInt(getDevice.conf[data.item].triggers.warning.value) <=
              parseInt(cpuUsedDash)
            ) {
              this.props.updateCountMaster(
                'd',
                this.props.device.id_device + 'c'
              );
            } else {
              this.props.updateCountMaster(
                'o',
                this.props.device.id_device + 'c'
              );
            }
          }

          this.setState({ cpuUsedDash: parseInt(cpuUsedDash) });
        }
        if (data.item == 'mem-info') {
          let memoryTemp = data.data;
          let memoryUsedTempS = String(memoryTemp.usedPercent).replace(
            ',',
            '.'
          );
          let memoryUsedTempI = memoryUsedTempS * 100;
          let memoryUsedDash = (memoryTemp.actualUsed / memoryTemp.total) * 100;

          if (getDevice != undefined) {
            if (
              parseInt(getDevice.conf[data.item].triggers.danger.value) <=
              parseInt((memoryTemp.actualUsed / memoryTemp.total) * 100)
            ) {
              this.props.updateCountMaster(
                'r',
                this.props.device.id_device + 'm'
              );
            } else if (
              parseInt(getDevice.conf[data.item].triggers.warning.value) <=
              parseInt((memoryTemp.actualUsed / memoryTemp.total) * 100)
            ) {
              this.props.updateCountMaster(
                'd',
                this.props.device.id_device + 'm'
              );
            } else {
              this.props.updateCountMaster(
                'o',
                this.props.device.id_device + 'm'
              );
            }
          }
          this.setState({
            memory: mem(memoryTemp.total),
            memoryUsed: mem(memoryTemp.actualUsed),
            memoryUsedDash: memoryUsedDash
          });
        }
        if (data.item == 'fs-list') {
          let diskTemp = data.data;

          if (getDevice != undefined) {
            if (
              parseInt(getDevice.conf[data.item].triggers.danger.value) <=
              parseInt(
                percent(
                  data.data[0].values.used,
                  data.data[0].values.total
                )
              )
            ) {
              this.props.updateCountMaster(
                'r',
                this.props.device.id_device + 'd'
              );
            } else if (
              parseInt(getDevice.conf[data.item].triggers.warning.value) <=
              parseInt(
                percent(
                  data.data[0].values.used,
                  data.data[0].values.total
                )
              )
            ) {
              this.props.updateCountMaster(
                'd',
                this.props.device.id_device + 'd'
              );
            } else {
              this.props.updateCountMaster(
                'o',
                this.props.device.id_device + 'd'
              );
            }
          }

          this.setState({ disk: diskTemp, removeloading: true });
        }

        // {id_device: "3", item: "cpu-stats", data: {…}}data: {idle: 9700, total: 9900, usedPercent: 97.97979797979798}id_device: "3"item: "cpu-stats"__proto__: Object
        let hold = setTimeout(() => 1000);

        this.setState({ available: true, hold: hold });
      });
    }
  }

  handleModal(opts) {
    this.setState({ modalOpen: !this.state.modalOpen, modalOptions: opts });
  }
  toogleListGroup = () => {
    this.setState(state => ({
      ListGroupItemShow: !state.ListGroupItemShow
    }));
  };
  handleClick = (event, v) => {
    this.setState({ anchorEl: event.currentTarget, open: v });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, open: null });
  };
  render() {
    var ListGroupItemShow = classnames({
      active: this.state.ListGroupItemShow
    });
    let {
      ioLinks,
      device,
      handlePage,
      handleModal,
      ioServers,
      onRestartAgent,
      onDettachDevice,
      graphs
    } = this.props;
    let { open, anchorEl } = this.state;

    let getDevice = this.props.monitor.find(
      m => m.id_device === device.id_device
    );

    let cpuDanger = 0;
    let cpuWar = 0;

    let memDanger = 0;
    let memWar = 0;

    let fslistDanger = 0;
    let fslistWar = 0;
    let snmpgraph = [];

    try {
      cpuDanger = getDevice.conf['cpu-stats'].triggers.danger.value;
      cpuWar = getDevice.conf['cpu-stats'].triggers.warning.value;
      memDanger = getDevice.conf['mem-info'].triggers.danger.value;
      memWar = getDevice.conf['mem-info'].triggers.warning.value;

      fslistDanger = getDevice.conf['fs-list'].triggers.danger.value;
      fslistWar = getDevice.conf['fs-list'].triggers.warning.value;


    } catch (e) { }
    try {
      snmpgraph = getDevice.snmpgraph
    } catch (e) { }

    return (
      <li
        className="list-group-item"
        style={{
          ...(device.type.toLowerCase() != 'server' &&
            device.type.toLowerCase() != 'desktop'
            ? {
              paddingTop: 6,
              paddingBottom: 3,
              ...(this.props.divider ? { width: '49.5%' } : {})
            }
            : {})
        }}
      >
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          {device.type.toLowerCase() != 'server' &&
            device.type.toLowerCase() != 'desktop' &&
            device.snmp != true
            ? null : (
              <React.Fragment>
                <div>
                  <a
                    href="#"
                    style={{ textDecoration: 'none' }}
                    className="mr-2"
                    onClick={
                      device.type.toLowerCase() != 'server' &&
                        device.type.toLowerCase() != 'desktop' &&
                        device.snmp != true
                        ? () => null
                        : this.toogleListGroup
                    }
                  >
                    {device.type.toLowerCase() != 'server' &&
                      device.type.toLowerCase() != 'desktop' &&
                      device.snmp != true ? (
                      <div
                        className="linkIcon"
                        style={{ width: 20, height: 20, margin: '0 8px' }}
                      />
                    ) : (
                      <div className="control-order">
                        <i
                          className={
                            'fas fa-' +
                            (this.state.ListGroupItemShow ? 'minus' : 'plus')
                          }
                        ></i>
                      </div>
                    )}
                  </a>
                </div>
                <Grid item xs={4}>
                  <span>{device.name}</span>
                </Grid>
              </React.Fragment>
            )}
          <Grid
            item
            xs={
              device.type.toLowerCase() != 'server' &&
                device.type.toLowerCase() != 'desktop'
                ? 12
                : 8
            }
          >
            {device.type.toLowerCase() != 'server' &&
              device.type.toLowerCase() != 'desktop' &&
              device.snmp != true ? (
              <Link
                _device={getDevice}
                ioLinks={ioLinks}

                {...this.props}
                handleModal={this.props.handleModal}
              />
            ) : (
              <div className="bar-graph d-flex align-items-center justify-content-end">
                {


                  device.type.toLowerCase() === 'server' ||
                    device.type.toLowerCase() === 'desktop' ||
                    device.snmp == true
                    ? (
                      <ServerConfig
                        token
                        online={this.state.online}
                        _device={getDevice == undefined ? device : getDevice}
                        ioServers={ioServers}
                        onDettachDevice={onDettachDevice}
                        onRestartAgent={onRestartAgent}
                        handlePage={handlePage}
                        action={this.props.action}
                        APIEndpoints={this.props.APIEndpoints}
                        {...this.props}
                        handleModal={handleModal}
                      />
                    ) : null}

                {snmpgraph == null ?
                  <Graph_progress
                    danger={cpuDanger}
                    warning={cpuWar}
                    current={parseInt(this.state.cpuUsedDash)}
                    title="CPU"
                  /> : null}
                {snmpgraph == null ? <Graph_progress
                  danger={memDanger}
                  warning={memWar}
                  current={parseInt(this.state.memoryUsedDash)}
                  title="Memória"
                /> : null}
                {snmpgraph == null ? <Graph_progress
                  danger={fslistDanger}
                  warning={fslistWar}
                  current={
                    this.state.disk.length > 0
                      ? percent(
                        this.state.disk[0].values.used,
                        this.state.disk[0].values.total
                      )
                      : 0
                  }
                  title="Disco"
                /> : null}

              </div>
            )}
          </Grid>
        </Grid>

        <div className={`expand-data ${ListGroupItemShow}`}>
          <hr />


          {device.snmp == true ?

            <ServerSnmp
              _device={getDevice == undefined ? device : getDevice}
              ioServers={ioServers}
              snmpgraph={snmpgraph}
              onRestartAgent={onRestartAgent}
              handlePage={handlePage}
              action={this.props.action}
              APIEndpoints={this.props.APIEndpoints}
              {...this.props}
              handleModal={handleModal}
            />

            : null}





          {device.type.toLowerCase() === 'server' && device.snmp != true
            ||
            device.type.toLowerCase() === 'desktop' && device.snmp != true ? (
            <Server
              _device={getDevice == undefined ? device : getDevice}
              ioServers={ioServers}
              onRestartAgent={onRestartAgent}
              handlePage={handlePage}
              action={this.props.action}
              APIEndpoints={this.props.APIEndpoints}
              {...this.props}
              handleModal={handleModal}
            />
          ) : null}

          {device.type.toLowerCase() != 'server' && device.snmp != true &&
            device.type.toLowerCase() != 'desktop' && device.snmp != true ? (
            <Link
              _device={getDevice}
              {...this.props}
              ioLinks={ioLinks}
              handleModal={this.props.handleModal}
            />
          ) : null}
        </div>
      </li>
    );
  }
}

// -----------------------------------------------------------(COMPONENTE - ListGroup)----------------------------------------------------------------
class ListGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ListGroupItemShow: false,
      countRedSub: [],
      countYellowSub: [],
      countOkSub: [],
      del: false,
      up: false,
      group: ''
    };

    this.onClose = this.onClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.upGroup = this.upGroup.bind(this);

    this.updateCountSub = this.updateCountSub.bind(this);
  }

  onClose() {
    this.setState({
      del: false,
      up: false,
      group: ''
    });
  }

  updateCountSub(item, id_device) {
    if (item == 'r') {
      let value = this.state.countRedSub;

      if (value.indexOf(id_device) === -1) value.push(id_device);
      this.setState({ countRedSub: value });
    }
    if (item == 'e') {
      let value = this.state.countYellowSub;

      if (value.indexOf(id_device) === -1) value.push(id_device);
      this.setState({ countYellowSub: value });
    }
    if (item == 'o') {
      let value = this.state.countOkSub;

      if (value.indexOf(id_device) === -1) value.push(id_device);
      this.setState({ countOkSub: value });
    }
  }

  toogleListGroup = () => {
    this.setState(state => ({
      ListGroupItemShow: !state.ListGroupItemShow
    }));
  };

  onClose() {
    this.setState({
      del: false,
      up: false,
      group: ''
    });
  }

  onDelete() {
    let { group } = this.state;
    this.props.upGroup(group, 'del');
    this.onClose();
  }

  onUpdate(values) {
    let { group } = this.state;
    this.props.upGroup(group, 'up', values);
    this.onClose();
  }

  upGroup(group, action) {
    let obj = { group: group };
    if (action === 'del') obj.del = true;
    if (action === 'up') obj.up = true;
    this.setState(obj);
  }
  render() {
    var ListGroupItemShow = classnames({
      show: this.state.ListGroupItemShow || this.props.all
    });
    let {
      gd,
      ioLinks,
      onDettachDevice,
      monitor,
      company,
      language,
      comp,
      companies,
      handlePage,
      handleModal,
      ioServers,
      onRestartAgent,
      updateCountMaster,
      upGroup
    } = this.props;
    let { del, group, up } = this.state;
    let divider = {
      width: '49%'
    };
    let graphs = [];

    try {
      graphs = gd.snmpgraph;
    } catch (r) {

    }
    return (
      <li
        style={this.props.divider ? divider : {}}
        className={classnames({ 'pl-3': !this.props.all })}
      >
        {!this.props.all && (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <div>
              <a
                href="#"
                className="mr-3"
                onClick={this.toogleListGroup}
                style={{ textDecoration: 'none' }}
              >
                <div className="control-order">
                  <i
                    className={
                      'fas fa-' +
                      (this.state.ListGroupItemShow ? 'minus' : 'plus')
                    }
                  ></i>
                </div>
              </a>
            </div>
            <Grid item xs={4}>
              <span className="grid-item titlenew">{gd.name_group}</span>
            </Grid>
            <Grid
              item
              xs={8}
              className="bar-graph d-flex align-items-center justify-content-end"
            >
              <RaisedButton
                circleButton
                color="danger"
                icon="fas fa-trash-alt"
                style={{ width: 25, height: 25, fontSize: 15 }}
                label={language.DELETE}
                onClick={() => this.upGroup(gd.id_group, 'del')}
              />
              <RaisedButton
                circleButton
                icon={
                  <img
                    src={DeviceGroup}
                    alt="device-group"
                    style={{ width: '60%', filter: 'invert(100%)' }}
                  />
                }
                color="primaryGradient"
                style={{ width: 25, height: 25 }}
                className="mx-2 mr-3"
                label={language.EDIT + ' ' + language.DEVICES_GROUP}
                onClick={() =>
                  this.props.openUpdateHandle({
                    id_group: gd.id_group,
                    onClose: this.onClose,
                    companies: companies,
                    comp: comp,
                    company: company,
                    group: group,
                    devices: gd,
                    open: true,
                    onUpdate: this.onUpdate
                  })
                }
              />
              {graphs == undefined || graphs.length == 0}
              <Chip
                avatar={
                  <Avatar
                    className="statusCustomIconAv"
                    style={{ backgroundColor: '#33ABA0' }}
                  >
                    <i className="fas fa-check"></i>
                  </Avatar>
                }
                label={this.state.countOkSub.length}
              />
              <Chip
                avatar={
                  <Avatar
                    className="statusCustomIconAv"
                    style={{ backgroundColor: '#DAA520' }}
                  >
                    <i className="fas fa-exclamation"></i>
                  </Avatar>
                }
                label={this.state.countYellowSub.length}
                className="mx-1"
              />
              <Chip
                avatar={
                  <Avatar
                    className="statusCustomIconAv"
                    style={{ backgroundColor: '#F44336' }}
                  >
                    <i className="fas fa-times"></i>
                  </Avatar>
                }
                label={this.state.countRedSub.length}
              />
            </Grid>
          </Grid>
        )}

        <div className={`collapse list-container ${ListGroupItemShow} `}>
          <ul
            className={classnames(
              'list-group w-100',
              this.props.divider &&
                (this.props.all ? [gd] : gd.devices).find(
                  c => c.type.toLowerCase() == 'link'
                )
                ? 'd-flex justify-content-between'
                : ''
            )}
            style={{
              ...(this.props.divider &&
                (this.props.all ? [gd] : gd.devices).find(
                  c => c.type.toLowerCase() == 'link'
                )
                ? { flexDirection: 'row', flexWrap: 'wrap' }
                : {})
            }}
          >
            {(this.props.all ? [gd] : gd.devices).map(c => (
              <ListGroupItem
                divider={!!this.props.divider}
                ioLinks={ioLinks}
                graphs={graphs}
                onDettachDevice={onDettachDevice}
                updateCountSub={this.updateCountSub}
                onRestartAgent={onRestartAgent}
                ioServers={ioServers}
                handleModal={handleModal}
                handlePage={handlePage}
                {...this.props}
                device={c}
              />
            ))}
          </ul>

          {del ? (
            <CommonDelete
              language={language}
              closeModal={this.onClose}
              onDelete={this.onDelete}
            />
          ) : up ? null : null}
        </div>
      </li>
    );
  }
}

// ----------------------------------------------------------------(COMPONENTE - MyList)--------------------------------------------------------------------
class MyList extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      list_active: false,

      countRed: [],
      countYellow: [],
      countOk: []
    };
    this.updateCountMaster = this.updateCountMaster.bind(this);
  }

  updateCountMaster(item, id_device) {
    if (item == 'r') {
      let value = this.state.countRed;

      if (value.indexOf(id_device) === -1) value.push(id_device);
      this.setState({ countRed: value });
    }
    if (item == 'e') {
      let value = this.state.countYellow;

      if (value.indexOf(id_device) === -1) value.push(id_device);
      this.setState({ countYellow: value });
    }
    if (item == 'o') {
      let value = this.state.countOk;

      if (value.indexOf(id_device) === -1) value.push(id_device);
      this.setState({ countOk: value });
    }
  }
  toogleListGroup = () => {
    this.setState(state => ({
      list_active: !state.list_active
    }));
  };
  render() {
    var listClass = classnames({
      active: this.state.list_active || this.props.all
    });
    let defaultStyle = { border: 'solid 1px #ddd' };
    let dangerStyle = { boxShadow: '0px 1px 21px 0px rgba(255,0,0,1)' };
    let warningStyle = { boxShadow: '0px 1px 21px 0px rgba(255,255,0,1)' };

    let {
      ioLinks,
      monitorGroup,
      onDettachDevice,
      danger,
      warning,
      monitor,
      handlePage,
      handleModal,
      ioServers,
      onRestartAgent,
      upGroup,
      companies,
      language
    } = this.props;

    let { countRed, countYellow, countOk } = this.state;

    if (!monitorGroup) return <div />;

    return (
      <React.Fragment>
        {!this.props.all && (
          <ListItem className="grid-container listItem" dense button>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <div className="plus">
                <a
                  href="#"
                  className=""
                  style={{ textDecoration: 'none' }}
                  onClick={this.toogleListGroup}
                >
                  <div className="control-order">
                    <i
                      className={
                        'fas fa-' + (this.state.list_active ? 'minus' : 'plus')
                      }
                    ></i>
                  </div>
                </a>
              </div>
              <Grid item xs={4} className="plus">
                <div className="company">{monitorGroup.company}</div>
              </Grid>
              <Grid item xs={8} className="plus">
                <div className="bar-graph d-flex align-items-center justify-content-end">
                  <Chip
                    avatar={
                      <Avatar
                        className="statusCustomIconAv"
                        style={{ backgroundColor: '#33ABA0' }}
                      >
                        <i className="fas fa-check"></i>
                      </Avatar>
                    }
                    label={countOk.length}
                  />
                  <Chip
                    avatar={
                      <Avatar
                        className="statusCustomIconAv"
                        style={{ backgroundColor: '#DAA520' }}
                      >
                        <i className="fas fa-exclamation"></i>
                      </Avatar>
                    }
                    label={countYellow.length}
                    className="mx-1"
                  />
                  <Chip
                    avatar={
                      <Avatar
                        className="statusCustomIconAv"
                        style={{ backgroundColor: '#F44336' }}
                      >
                        <i className="fas fa-times"></i>
                      </Avatar>
                    }
                    label={countRed.length}
                  />
                </div>
              </Grid>
            </Grid>
          </ListItem>
        )}

        <ListItem
          className={`lists listItem grid-container-100 ${listClass}`}
          style={this.props.all ? { padding: 0 } : {}}
          dense
          button
        >
          <div className="grid-item">
            <div className="list-card">
              {this.props.list ? (
                <ul
                  className={classnames('list-group', {
                    'list-group-flex': !!this.props.divider
                  })}
                >
                  {(this.props.all ? [monitorGroup] : monitorGroup.groups).map(
                    (gd, i) => {
                      // if(parseInt(gd.user_cad) === this.store.getUserLoged().id) 
                      return <ListGroup
                        ioLinks={ioLinks}
                        divider={!!this.props.divider}
                        onDettachDevice={onDettachDevice}
                        comp={monitorGroup.company}
                        updateCountMaster={this.updateCountMaster}
                        company={[]}
                        companies={companies}
                        upGroup={upGroup}
                        onRestartAgent={onRestartAgent}
                        ioServers={ioServers}
                        handleModal={handleModal}
                        handlePage={handlePage}
                        {...this.props}
                        gd={gd}
                      />
                    }
                  )}
                </ul>
              ) : (
                <Monitor cp={true} />
              )}
            </div>
          </div>
        </ListItem>
      </React.Fragment>
    );
  }
}

// ---------------------------------------------------------(COMPONENTE - MonitorHome)---------------------------------------------------------------------------
class MonitorHome extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      list: true,
      filterServer: false,
      filterLink: false,
      modalOpen: false,
      modalOptions: {},
      update: 0,
      removeloading: true
    };
    this.onFilter = this.onFilter.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  updateState() {
    //this.props.state.page = 'monitor';
    // setTimeout(this.updateState, 5000, this);
  }

  onFilter(filter) {
    this.setState(filter);
  }

  handleModal(opts) {
    this.setState({ modalOpen: !this.state.modalOpen, modalOptions: opts });
  }

  changeView = () => {
    this.setState(state => ({
      list: !state.list
    }));
  };

  render() {
    let {
      danger,
      language,
      companies,
      handlePage,
      DashboardMonitor,
      monitor,
      monitorGroups,
      warning,
      onDettachDevice,
      onRestartAgent,
      ioServers,
      ioLinks,
      monitordevicecompany,
      upGroup,
      all
    } = this.props;


    var CTag = all ? 'div' : Paper;
    let defaultStyle = { border: 'solid 1px #ddd' };
    let { filterServer, filterLink, modalOpen, modalOptions } = this.state;
    window.m = monitorGroups;
    var mAll = [];

    if (all) {

      var c = ['link', 'server', 'desktop'];
      mAll = monitorGroups
        .reduce((arr, i) => {

          i.groups.map(e => {
            e.devices.map(e => {
              arr.push(e);
            });
          });
          return arr;
        }, [])
        .sort(function (a, b) {
          return c.indexOf(a.type) - c.indexOf(b.type);
        });
    }

    return (
      <PageLayout
        icon="monitorIcon"
        title={language.MONITOR}
        rightElements={[
          <DashboardMonitor

            ioAlertsLinks={this.props.ioAlertsLinks}
            ioAlertsServers={this.props.ioAlertsServers}
            ioLinks={ioLinks}
            devices={this.props.devices}
            monitordevicecompany={monitordevicecompany}
            companies={this.props.companies}
            language={this.props.language}
            handlePage={this.props.handlePage}
            monitorGroups={this.props.monitorGroups}
            onGroupSubmit={this.props.onGroupSubmit}
            danger={this.props.danger}
            warning={this.props.warning}
            ioServers={this.props.ioServers}
          />,
          <RaisedButton
            circleButton
            icon={'fas ' + (all ? 'fa-columns' : 'fa-list')}
            color="primaryGradient"
            className="ml-2"
            style={{ fontSize: 17 }}
            onClick={() => handlePage(all ? 'dash' : 'dashall')}
          />
        ]}
      >
        <div id="MonitorHome">
          <Grid container spacing={all ? 8 : 24}>
            {(all ? mAll : monitorGroups).map((monitorGroup, i) => (
              <Grid
                item
                xs={
                  all
                    ? monitorGroup.type == 'link'
                      ? 3
                      : 6
                    : monitorGroups.length == 1
                      ? 12
                      : 6
                }
              >
                <CTag className={classnames({ paper: !all })}>
                  <List
                    className="list listMin"
                    style={all ? { paddingTop: 0, paddingBottom: 0 } : {}}
                  >
                    <MyList
                      {...this.props}
                      monitorGroupsLength={monitorGroups.length}
                      divider={all ? false : monitorGroups.length == 1}
                      toDetails={() => handlePage('groupDetail', monitorGroup)}
                      hintTextSearch={language.SEARCH_GROUP}
                      ioLinks={ioLinks}
                      styleStatus={defaultStyle}
                      language={language}
                      onDettachDevice={onDettachDevice}
                      monitorGroup={monitorGroup}
                      upGroup={upGroup}
                      ioServers={ioServers}
                      all={all}
                      addGroupDevice={this.handleModal}
                      dataTip={language.ADD_MONITORING_GROUP}
                      editDtip={language.EDIT}
                      companies={companies}
                      handlePage={handlePage}
                      onRestartAgent={onRestartAgent}
                      handleModal={this.handleModal}
                      trashDtip={language.REMOVE}
                      danger={danger}
                      warning={warning}
                      list={this.state.list}
                      key={i}
                    />
                  </List>
                </CTag>
              </Grid>
            ))}
          </Grid>
          {modalOptions && Object.keys(modalOptions).length ? (
            <Dialog
              open={modalOpen}
              bodyStyle={{ padding: 0, overflowY: 'none' }}
              title={
                modalOptions.confirm ? language.CONFIRMATION : 'Sensr Conf'
              }
              onClose={() => this.handleModal()}
            >
              <h2 style={{ color: '#616161', fontWeight: 100 }}>
                {modalOptions.msg}
              </h2>
              {modalOptions.confirm ? (
                <div style={{ float: 'right' }}>
                  <RaisedButton
                    primary={true}
                    label={language.IM_SURE}
                    onClick={modalOptions.confirm}
                  />
                </div>
              ) : null}
            </Dialog>
          ) : null}
        </div>
      </PageLayout>
    );
  }
}
export default MonitorHome;
