import React from 'react';
import {Avatar} from '@material-ui/core';
import { Tooltip } from 'react-tippy';

export default function({data=[], avatarMax=3}){
	
	return(
		<div className='content-avatares-horizontal d-flex align-items-center'>
			{data.slice(0,avatarMax).map((e,i)=>(
				<Tooltip
				  interactive
				  html={
				    <div style={{ color: '#fff', fontSize: 11, maxWidth:210, flexWrap:'wrap' }} className='d-flex align-items-center'>
				      {e.name}
				    </div>
				  }
				  position="right"
				  arrow
				  className='content-avatar'
				>
					<Avatar src={e.src} key={i} className='avatar' style={{zIndex:data.length-(i+1)}}>
						{e.name.substr(0,2).toUpperCase()}
					</Avatar>
				</Tooltip>
			))}
			{data.length>avatarMax&&
				<Tooltip
				  html={
				    <div style={{ color: '#fff', fontSize: 11 }}>
				      {data.slice(avatarMax).map((u, i) => (
				        <div
				          style={{ marginLeft: '5px', marginTop: '2px' }}
				          className="d-flex align-items-center"
				        >
				          <Avatar
				          	src={u.src}
				            className={'avatar'}
				            key={i}
				            style={{
				              fontSize: '10px',
				              width: '17px',
				              height: '17px',
				              
				            }}
				          >
				            {u.name
				              .substr(0, 2)
				              .toString()
				              .toUpperCase()}
				          </Avatar>
				          <span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
				        </div>
				      ))}
				    </div>
				  }
				  interactive
				  position="right"
				  theme="light"
				  arrow
				  className='content-avatar'
				>
				  	<Avatar className='avatar _white' style={{zIndex:0}}>
				  		+{data.slice(avatarMax).length}
				  	</Avatar>															  
				</Tooltip>
			}
		</div>
	)
}