import React, {Fragment, memo} from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import List from '@common/List';
import withLanguage from '@common/languages/withLanguage';
// ------------------------material-ui------------------------------------
import {Card, Grid} from '@material-ui/core';


/* 
----------------------------format----------------------------
let data = {
      list:[
         {label:'Abertos', value:25, color:'var(--primary)'},
         {label:'Em Atendimento', value:25, color:'var(--warning)'},
         {label:'Resolvidos', value:25, color:'var(--success)'},
         {label:'Fechados', value:25, color:'var(--purple)'},
         {label:'Total', value:25, color:'var(--default)'},
      ],
      openToday:1
   }
*/
export default memo(withLanguage(({page, list=[], openToday=0, language })=>{

   return(
      <Card id='TotalOpenTickets'>
         <AppBar title={language.GRAPH_CIO} subTitle={`(${language.OVERVIEW})`} />
         <div className='content-medium pt-0'>
            <Grid container spacing={2} alignItems='center'>
               <Grid item xs={5}>
                  <div className='content-total content-medium'>
                     <h1>{openToday}</h1>
                     <span>{language.TICKET_OPEN_TODAY}</span>
                  </div>
               </Grid>
               <Grid item xs={7}>
                  <List
                     className='list'
                     hover={false}
                     items={list.concat({label:'Total', value:list.reduce((n,e) => n+e.value, 0), color:'var(--default)'}).map((e,i)=>({
                        className:'d-flex justify-content-between',
                        style:{'--color':e.color, padding:8},
                        content:(
                           <Fragment>
                              {i < list.length ? <div className='indicator'/> : null }
                              <span className='label'>{e.label}</span>
                              <span className='value'>{e.value}</span>
                           </Fragment>
                        )
                     }))}
                  />
               </Grid>
            </Grid>
         </div>
      </Card>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))