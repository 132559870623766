import CommonForm from '@common/CommonForm2';
import Constants from '@data/Constants';
import Store from '@data/Store';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { applyTimezoneOnHTML } from '../../../common/Timezone';
import { decrypt } from '../helpers';
import ServicePortalTicketSubTitle from './ServicePortalTicketSubTitle';
import { EmptyGrid } from './EmptyState';

const formshtml = (
  t,
  formValues,
  allowEditingAfterOpeningRecord,
  allFieldsReadOnly,
  hideByFlag = true
) => {
  const { language } = Store;
  const userLogin = Store.getUserLoged();
  const timezone = userLogin?.timezone?.value;
  /**
   * Added the timezone of the last user who updated the form.
   * This field is used to calculate the new time for the datetime field.
   */
  let data = [
    <input id="timezone-last-update-user" type="hidden" value={timezone} />,
  ];

  (t.split('<div') || []).map((i) => {
    const required = (i || '').indexOf('formbuilder-required') > -1;
    const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
    const label = labelMatch
      ? (labelMatch[1] || '').replace('<br>', '')
      : undefined;
    const readOnly =
      allFieldsReadOnly ||
      (allowEditingAfterOpeningRecord &&
        !i.includes('allow-editing-after-opening-record'));

    if (hideByFlag && i.indexOf('show-in-service-portal') == -1) {
      return '';
    }

    if (i.indexOf('companies_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) => v.name.includes('companies')).value;
      } catch (e) {}

      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="companies_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ companies_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.COMPANY,
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  disabled: readOnly,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('locations_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) =>
          v.name.includes('locations_var')
        ).value;
      } catch (e) {}
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="locations_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ locations_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'locations_var',
                  label: label || language.LOCATION,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.LOCATION}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.LOCATION,
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  routeAll: `${Constants.APIEndpoints.LOCATION}/filter?`,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_location',
                  // value:
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('allusers_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) => v.name.includes('allusers')).value;
      } catch (e) {}
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="allusers_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ allusers_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.USER,
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: language.USER,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                  disabled: readOnly,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('hours') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) => v.name.includes('hours')).value;
      } catch (e) {}
      let nameField = 'hours_var';
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="hours_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ hours: value });
              // }}
              values={{
                [nameField]: textInit || null,
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  textlabel: language.HOURS,
                  type: 'time',
                  format: '##:##',
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('hidden_var') > -1) {
      let value_field = '';
      let name_field = '';
      try {
        const regex = /<p(.*?)<\/p>/;
        const textWithoutTags = i.match(regex);
        name_field = textWithoutTags[1].split('|')[1];
        value_field = textWithoutTags[1].split('|')[2];
      } catch (e) {}
      const html_field = `<div separator="true" hidden><input value = ${value_field} name = ${name_field} /></div>`;
      data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
    } else if (i.indexOf('subsdpt_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) =>
          v.name.includes('subdepartments')
        ).value;
      } catch (e) {}
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="subsdpt_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ subdepartments_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: '',
                  textlabel: label || language.SUBDEPARTMENT,
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  disabled: readOnly,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('departments_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) => v.name.includes('departments')).value;
      } catch (e) {}
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="departments_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ departments_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: label || language.DEPARTMENTS,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required,
                  disablePortal: true,
                  disabled: readOnly,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('contracts_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) => v.name.includes('contracts')).value;
      } catch (e) {}
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div separator="true" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ contracts_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: language.CONTRACTS,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.CONTRACT,
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
                  textlabel: language.CONTRACTS,
                  disablePortal: true,
                  showId: true,
                  required,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>
        </div>
      );
    } else if (i.indexOf('chieftdpt_var') > -1) {
      let textInit = '';
      try {
        textInit = formValues.find((v) => v.name.includes('boss')).value;
      } catch (e) {}
      data.push(
        <div style={readOnly ? { pointerEvents: 'none' } : {}}>
          <div
            separator="true"
            id="chieftdpt_var"
            style={{ margin: '15px 0px 30px 0px' }}
          >
            <CommonForm
              // onChangeField={(field, value) => {
              //   updateState({ chieftdpt_var: value });
              // }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss',
                  label: '',
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: textInit,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: language.DEPARTMENT_BOSS,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  disabled: readOnly,
                  detail: true,
                  visible: true,
                  required,
                  disablePortal: true,
                },
              ]}
            />
          </div>
        </div>
      );
    } else {
      if (i.length > 0) {
        let g = '';
        if (i.indexOf('ql-tooltip ql-hidden') > -1) {
          g = `<div style = "display : none" ${i}`;
        } else if (
          i.indexOf('ql-preview') > -1 ||
          i.indexOf('ql-clipboard') > -1 ||
          i.indexOf('ql-snow') > -1
        ) {
          g = `<div  ${i}`;
        } else if (i.indexOf('ql-editor') > -1) {
          g = `<div style="border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
        } else {
          g = `<div class="template-dinamic" style="pointer-events: ${
            readOnly ? 'none' : 'auto'
          }" ${i}`;
        }

        const html = $('<div />', { html: g });
        const el = $(html).find('[name]');
        const elType = $(el).attr('type');
        const elName = $(el).attr('name');
        const elValue = formValues?.find((h) => h.name === elName)?.value;

        if (elType === 'time') {
          const hour = (elValue || '').split(':')[0];
          const min = (elValue || '').split(':')[1];
          const date = new Date(2000, 1, 1, hour, min, 0);

          if (isNaN(date) == false) {
            $(el).attr('value', moment(date).format('HH:mm'));
          }
        } else if (elType === 'datetime-local') {
          const date = new Date(elValue);
          elValue &&
            $(el).attr('value', moment(date).format('YYYY-MM-DD HH:mm'));
        } else if (elType === 'radio') {
          const opt = $(el)
            .parent()
            .find('input[value="' + elValue + '"]');
          $(opt).attr('checked', true);
        } else if (elType === 'checkbox') {
          const valuesCheckbox = formValues.filter((h) => h.name === elName);
          valuesCheckbox.forEach((v) => {
            const opt = $(el)
              .parent()
              .find('input[value="' + v.value + '"]');
            $(opt).attr('checked', 'checked');
          });
        } else if ($(el).is('select')) {
          const opt = $(el).find('option[value="' + elValue + '"]');
          $(opt).attr('selected', 'selected');
        } else {
          $(el).attr('value', elValue);
        }

        if (i.indexOf('fieldset') < 0 && i.indexOf('button') < 0) {
          data.push(
            <div dangerouslySetInnerHTML={{ __html: $(html).html() }} />
          );
        }
      }
    }
  });
  return data;
};

export default function ServicePortalTicketFormView({
  language,
  ticketData,
  hideByFlag = true,
  isInModal = false,
}) {
  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (ticketData?.forms) {
      let _forms = [];
      for (const form of ticketData.forms) {
        if (form.html) {
          const formCode = decrypt(form.html);
          (!hideByFlag || formCode.indexOf('show-in-service-portal') > 0) &&
            _forms.push(formCode);
        }
      }
      _forms.length > 0 && setForms(_forms);
    }
  }, [ticketData?.forms]);

  return (
    <div className="form-view">
      <ServicePortalTicketSubTitle
        label={language.TASK_TEMP}
        iconText="fa-list-ol"
      />
      <div
        className="mb-2 tw-border tw-rounded tw-py-5 tw-px-2 tw-flex grow"
        style={{ minHeight: '12rem' }}
      >
        {console.log('forms', forms)}
        {console.log('ticketData._formValue', ticketData._formValue)}
        {forms.length > 0 && (
          <div className={isInModal ? 'tw-w-full' : 'tw-w-1/2'}>
            {forms.map((item) =>
              formshtml(
                applyTimezoneOnHTML(item, ticketData._formValue),
                ticketData._formValue,
                true,
                hideByFlag
              )
            )}
          </div>
        )}

        {forms.length === 0 && (
          <div className="tw-flex tw-justify-center tw-items-center tw-flex-grow">
            <EmptyGrid>
              <h2 className="tw-text-sm tw-text-gray-400">
                {language['SERVICE_PORTAL']['PAGE_NOT_RESULTS_FOUND']}
              </h2>
            </EmptyGrid>
          </div>
        )}
      </div>
    </div>
  );
}
