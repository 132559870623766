import React, { Fragment, useState, useReducer, useRef } from 'react';
import { Avatar, Card } from '@material-ui/core';
import classnames from 'classnames';
import Tag from '@common/Tag';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from '@common/Button';
import Progress from '@common/Progress';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';
import './Gantt.css';

const { language } = Store;

var enumerateDaysBetweenDates = function (startDate, endDate) {
   startDate = moment(startDate);
   endDate = moment(endDate);
   var now = startDate.clone(), dates = [];

   while (now.isSameOrBefore(endDate)) {
      dates.push(new Date(now));
      now.add(1, 'days');
   }
   return dates;
};

export default function ({
   spaceCollumn = 42,
   startDate = moment().add(-4, 'days'),
   endDate,
   group = []
}) {


   let [open, setOpen] = useState(true);
   let [openItem, setOpenItem] = useReducer((state, v) => {
      return state.indexOf(v) != -1 ? state.filter(e => e != v) : [...state, v]
   }, [0, 1, 2, 3, 4, 5]);
   const controlRef = useRef();

   var rangeDate = enumerateDaysBetweenDates(startDate, moment(endDate).diff(startDate, 'day') < 30 ? moment(endDate || Date.now()).add(20, 'days') : endDate);

   function positionDate(dt_i, dt_f) {
      var _dt_i = moment(dt_i).diff(moment(startDate), 'minutes');
      _dt_i = (_dt_i * 42) / (60 * 24);

      var _dt_f = moment(dt_f).diff(moment(dt_i), 'minutes');
      _dt_f = (_dt_f * 42) / (60 * 24);

      return { position: _dt_i, width: Math.abs(_dt_f) };
   };

   const isB = (dt, dt_i, dt_f) => moment(dt).isBetween(dt_i, dt_f, 'day');

   let scrollBarHeight = 380;
   let act;
   let pd;
   let pd_visible;
   let name_width;
   let activities;
   let HPercent = open ? 1 : (11 / 30);
   let avatarMax;


   return (
      <Card id='Gantt_project' className='content px-0 pb-2' style={{ '--spaceCollumn': spaceCollumn + 'px' }}>
         <div className='d-flex' style={{overflow:'hidden'}}>
            <div style={{ marginTop: 20, height: scrollBarHeight - 20 }}>
               <div id='content-control' ref={controlRef}>
                  {group.map((a, l) => {
                     var maxH = 1.3;
                     var maxHH = 1;
                     return (
                        <Fragment>
                           <div
                              key={l}
                              className='gantt-content d-flex align-items-center'
                              onClick={() => setOpenItem(l)}
                              style={{
                                 width: (rangeDate.length * spaceCollumn) + 210, 
                                 height: ((maxHH ? 60 * maxHH : 60) - (9.5 * (maxHH - 1))) * HPercent,
                                 //backgroundColor: 'rgb(240 176 2 / .1)',
                                 backgroundColor: 'transparent',
                                 borderTop: '1px solid var(--default)'
                              }}
                           >
                              <div className='content-info-control d-flex align-items-center pr-3'>
                                 <div className='w-100'>
                                    <i
                                       className={classnames('control-header', openItem.includes(l) ? 'fas fa-chevron-down' : 'fas fa-chevron-right')}
                                    />
                                    <span className='label-header'>{a.label}</span>
                                 </div>
                              </div>
                           </div>
                           {a.data.map((a, _l) => {

                              return (
                                 <div
                                    className='content-info-control d-flex align-items-center pr-3'
                                    style={{
                                       height: openItem.includes(l) ? ((maxH ? 60 * maxH : 60) - (9.5 * (maxH - 1))) * HPercent : 0
                                    }}
                                 >
                                    <div className='w-100'>
                                       {open &&
                                          <div className='content-tags d-flex align-items-center mb-1'>
                                             {(a.tags && a.tags.length > 2 ? a.tags.slice(0, 3) : a.tags).map((e, i) => (
                                                <Tag
                                                   key={i}

                                                   label={e.label}
                                                   zoom={.8}

                                                   style={{
                                                      zoom: .8,
                                                      backgroundColor: e.color
                                                   }}
                                                   className={classnames('mb-0 mr-1', { 'ml-0': i == 0 })}
                                                />
                                             ))}
                                             {a.tags.length > 3 &&
                                                <Tooltip
                                                   interactive
                                                   html={
                                                      <div style={{ color: '#fff', fontSize: 11, maxWidth: 210, flexWrap: 'wrap' }} className='d-flex align-items-center'>
                                                         {a.tags.slice(3).map((e, i) => (
                                                            <Tag
                                                               key={i}

                                                               label={e.label}
                                                               zoom={.8}

                                                               style={{
                                                                  zoom: .8,
                                                                  backgroundColor: e.color
                                                               }}
                                                            />
                                                         ))}
                                                      </div>
                                                   }
                                                   position="right"
                                                   theme="light"
                                                   arrow
                                                >

                                                   <span className="number-more">
                                                      +{a.tags.slice(3).length}
                                                   </span>

                                                </Tooltip>
                                             }

                                          </div>
                                       }
                                       <span className='label-name ellipsis'>{a.label}</span>
                                       {open &&
                                          <Fragment>
                                             <div className={classnames('content-label-info', { late: a.late })}>
                                                <span>{moment(a.dt_i).format('DD/MM')} a </span>
                                                <span>{moment(a.dt_f).format('DD/MM')} </span>
                                                {a.late && <span><i className="fas fa-circle" /> atrasado</span>}
                                             </div>
                                             {!isNaN(a.percent)?(

                                                <div className='d-flex align-items-center w-100'>
                                                   <Progress current={a.percent} background='var(--warning)' hiddenText style={{height:7, width:'100%'}}/>
                                                   <span style={{color:'#707070', fontSize:10}} className='ml-2'>{a.percent}%</span>
                                                </div>
                                             ):null}
                                          </Fragment>
                                       }
                                    </div>
                                 </div>
                              )
                           })}
                        </Fragment>
                     )
                  })}
               </div>
            </div>
            <PerfectScrollbar
               className="scrollVisible gantt-scroll"
               onScrollY={evt => {
                  controlRef.current.style.marginTop = -evt.scrollTop + 'px';
               }}
               style={{
                  maxHeight: scrollBarHeight,
                  zIndex: 9
               }}

            >
               <div className='dates-content d-flex align-items-center mb-2' style={{ width: (rangeDate.length * spaceCollumn) }}>
                  {rangeDate.map((e, i) => (
                     <div className='d-flex align-items-center justify-content-center'>
                        {moment(e).isSame(new Date(), 'days') ? (
                           <span style={{ color: 'var(--success)' }}>{language.TODAY}</span>
                        ) : (
                              <Fragment>
                                 <span>{moment(e).format('DD/')}</span>
                                 <span>{moment(e).format('MMM')}</span>
                              </Fragment>
                           )}
                     </div>
                  ))}
               </div>

               {group.map((a, l) => {


                  var maxH = 1.3;
                  var maxHH = 1;
                  return (
                     <Fragment>
                        <div
                           key={l}
                           className='gantt-content d-flex align-items-center'
                           onClick={() => setOpenItem(l)}
                           style={{
                              width: (rangeDate.length * spaceCollumn) + 210, 
                              height: ((maxHH ? 60 * maxHH : 60) - (9.5 * (maxHH - 1))) * HPercent,
                              //backgroundColor: 'rgb(240 176 2 / .1)',
                              backgroundColor: 'transparent',
                              borderTop: '1px solid var(--default)'
                           }}
                        >
                           <div className='content-info-activities d-flex align-items-center' style={{ width: (rangeDate.length * spaceCollumn) }}>


                              {rangeDate.map((e, i) => (
                                 <Fragment>

                                    {moment(e).diff(new Date(), 'days') < 0 &&
                                       <div
                                          className={
                                             classnames(
                                                'curtain-date-past',

                                             )
                                          }
                                          style={{
                                             left: i * spaceCollumn
                                          }}
                                       >
                                       </div>
                                    }
                                    <div
                                       key={i}
                                       className={classnames('divisor', { active: moment(e).isSame(new Date(), 'days') })}
                                       style={{ '--index': i }}
                                    >
                                    </div>
                                 </Fragment>
                              ))}
                           </div>
                        </div>
                        {a.data.map((a, _l) => {
                           pd = positionDate(a.dt_i, a.dt_f);
                           pd_visible = (pd.width >= spaceCollumn * 3) || (!open && pd.width > spaceCollumn);
                           name_width = !pd_visible ? { width: '100%' } : {};
                           avatarMax = parseInt(pd.width / spaceCollumn) * 2;

                           return (
                              <div
                                 key={_l}
                                 className='gantt-content d-flex align-items-center'
                                 style={{
                                    width: (rangeDate.length * spaceCollumn) + 210,
                                    height: openItem.includes(l) ? ((maxH ? 60 * maxH : 60) - (9.5 * (maxH - 1))) * HPercent : 0
                                 }}
                              >
                                 <div className='content-info-activities d-flex align-items-center' style={{ width: (rangeDate.length * spaceCollumn) }}>
                                    <div

                                       className={
                                          classnames(
                                             'd-flex align-items-center item-activity',
                                             pd_visible ? 'justify-content-between' : 'justify-content-center'
                                          )
                                       }
                                       style={{
                                          left: pd.position,
                                          top: ((15 * 1) + (30 * (1 - 1))) * HPercent,
                                          width: pd.width,
                                          height: 30 * HPercent,
                                          backgroundColor: a.color
                                       }}
                                    >
                                       {open &&
                                          <div className='content-avatares d-flex align-items-center'>
                                             {a.avatares.slice(0, avatarMax).map((e, i) => (
                                                <Tooltip
                                                   interactive
                                                   html={
                                                      <div style={{ color: '#fff', fontSize: 11, maxWidth: 210, flexWrap: 'wrap' }} className='d-flex align-items-center'>
                                                         {e.name}
                                                      </div>
                                                   }
                                                   position="right"
                                                   arrow
                                                   className='content-avatar'
                                                >
                                                   <Avatar src={e.src} key={i} className='avatar' style={{ zIndex: a.avatares.length - (i + 1) }}>
                                                      {e.name.substr(0, 2)}
                                                   </Avatar>
                                                </Tooltip>
                                             ))}
                                             {a.avatares.length > avatarMax &&
                                                <Tooltip
                                                   html={
                                                      <div style={{ color: '#fff', fontSize: 11 }}>
                                                         {a.avatares.slice(avatarMax).map((u, i) => (
                                                            <div
                                                               style={{ marginLeft: '5px', marginTop: '2px' }}
                                                               className="d-flex align-items-center"
                                                            >
                                                               <Avatar
                                                                  src={u.src}
                                                                  className={'avatar'}
                                                                  key={i}
                                                                  style={{
                                                                     fontSize: '10px',
                                                                     width: '17px',
                                                                     height: '17px',

                                                                  }}
                                                               >
                                                                  {u.name
                                                                     .substr(0, 2)
                                                                     .toString()
                                                                     .toUpperCase()}
                                                               </Avatar>
                                                               <span style={{ color: 'black', marginLeft: '4px' }}>{u.name}</span>
                                                            </div>
                                                         ))}
                                                      </div>
                                                   }
                                                   interactive
                                                   position="right"
                                                   theme="light"
                                                   arrow
                                                   className='content-avatar'
                                                >
                                                   <Avatar className='avatar _white' style={{ zIndex: 0 }}>
                                                      +{a.avatares.slice(avatarMax).length}
                                                   </Avatar>
                                                </Tooltip>
                                             }
                                          </div>
                                       }
                                       {/*<Tooltip
                                       html={
                                          <div style={{ color: '#fff', fontSize: 11 }}>
                                             {a.label}
                                          </div>
                                       }
                                       arrow={true}
                                       position="right"
                                       theme="dark"
                                          
                                       >
                                          {open&&(pd.width>=spaceCollumn*2)?(
                                             <span className='name ellipsis' style={{...name_width}}>{a.label}</span>
                                          ):(pd.width<=spaceCollumn)?(
                                             <span className='name ellipsis' style={{...name_width}}>
                                                <i 
                                                   className="fas fa-tasks"
                                                   style={{
                                                      fontSize:17*HPercent
                                                   }}
                                                />
                                             </span>
                                          ):null}
                                       </Tooltip>*/}
                                    </div>

                                    {rangeDate.map((e, i) => (
                                       <Fragment>

                                          {moment(e).diff(new Date(), 'days') < 0 &&
                                             <div
                                                className={
                                                   classnames(
                                                      'curtain-date-past',

                                                   )
                                                }
                                                style={{
                                                   left: i * spaceCollumn
                                                }}
                                             >
                                             </div>
                                          }
                                          <div
                                             key={i}
                                             className={classnames('divisor', { active: moment(e).isSame(new Date(), 'days') })}
                                             style={{ '--index': i }}
                                          >
                                          </div>
                                       </Fragment>
                                    ))}
                                 </div>

                              </div>
                           )
                        })}

                     </Fragment>
                  )

               })}
            </PerfectScrollbar>
         </div>
         <Button
            icon={open ? 'fas fa-caret-up' : 'fas fa-caret-right'}
            label={open ? 'Fechar' : 'Expandir'}
            color='primary'
            outlined
            style={{ border: 'none' }}
            size={.7}
            zoom={.7}
            onClick={() => setOpen(!open)}
            className='ml-3'
         />
      </Card>
   )
}