import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import HelpList from './HelpList';
import HelpForm from './HelpForm';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

import Animate from '@common/Animate';
@observer
export default class HelpContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
      helps: [],
      files : []
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  
  componentWillMount() {
    const action = new Actions();

    action
      .execute('get', Constants.APIEndpoints.HELP, 'help')
      .then((e) => {
        this.setState({ helps: e.data });
      })
      .catch((error) => {
        console.log('error ', error);
      });

    this.handlePage('list');
  }

  onFormSubmit(values) {

    values.url = values.url[0].name;
    const action = new Actions();
    let method = values.hasOwnProperty('id_help') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_help')
      ? Constants.APIEndpoints.HELP + '/' + values.id_help
      : Constants.APIEndpoints.HELP;
    action
      .execute(method, url, 'help', values, 'id_help')
      .then((e) => {
        this.setState({ helps: e.data });
      })
      .catch((error) => {});

      method == 'put' ? this.UploadFiles(values, values.id_help) :'';

    this.handlePage('list');
  }

  onDelete() {
    let { values } = this.state;
    const action = new Actions();

    action.execute('post', Constants.APIEndpoints.HELP  + '/' +'deletehelp',
     'help', values, 'id_help')
     .then ((e) => {
       //console.log(e.data)
      this.setState({ helps: e.data });

     })


     this.handlePage('list');  

  }


  UploadFiles(ac, id_file) {
    //console.log('id no upload', id_file)
    //console.log('vaijsioa ', id_file == -1 ? value + 1 : id_file + 0)
    const action = new Actions();
    let value =0;
    action
      .execute('get', Constants.APIEndpoints.HELP, 'help')
      .then((e) => {
        value = e.data[e.data.length - 1].id_help;
        value = id_file == -1 ? value + 1 : id_file + 0
        //console.log('value no execute', value, id_file)
        Array.from(ac).forEach(file => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then(
            result => {
              let upload = {
                id_help: value,
                deleted: false,
                file: result,
                type: file.type,
                namefile: file.name,
            
              };
              action.execute(
                'post',
                Constants.APIEndpoints.HELP + '/uploadhelpfile',
                '',
                upload
              );
              
              //setTimeout(function() {   this.redirect('/tech/tickets/detail', { ticket: this.props.location.state.ticket}) }.bind(this), 1000);
            },
            err => { }
          );
        });
   
      })
      .catch((error) => {
        console.log('error ', error);
      });

 
  }



  handlePage(page, edit) {

    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;

    let {
      language,
     } = this.store;
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <HelpList
          redirect={this.redirect2}
          language={language}
          handlePage={this.handlePage}
          store={this.state.helps}
          configurationProps={configurationProps}
        />

        {page === 'form' ? (
          <HelpForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
            UploadFiles = {this.UploadFiles}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.title}
          />
        ) : null}
      </Fragment>
    );
  }
}
