import React from 'react';

import { Card } from '@material-ui/core';

import AppBar from '../../../../common/AppBar2';

const Indicator = ({ title, subTitle, value, isString }) => (
  <Card className="card">
    <AppBar title={title} subTitle={subTitle} />
    <span className="value">
      {isString
        ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        : value}
    </span>
  </Card>
);

export default function ({ acumulated = 0, total = 0, language }) {
  return (
    <div id="Totals">
      <Indicator value={acumulated} title={language.ACCUMULATED_VALUE} isString />
      <Indicator value={total} title={language.TOTAL_CONTRACTS} />
    </div>
  );
}
