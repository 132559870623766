import React from 'react';
import './index.css';
// ----------------------------other--------------------------------------------]
import { observer } from 'mobx-react';
// ----------------------------common--------------------------------------------
import CommonPage from '@common/CommonPage';
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import AppBar from '@common/AppBar2';
import  moment from 'moment'
import CompletedActivities from '../Kanban/Block_1/CompletedActivities';
// ----------------------------material-ui--------------------------------------------
import { Card, Grid } from '@material-ui/core';
// ----------------------------components--------------------------------------------
import CP from '../CP';
import Header from './Header';
import Carousel from './Carousel';
import SprintCard from './SprintCard';
import DialogForm from '../DialogForm';
import Button from '@common/Button';
import Block1 from './Block1'

@observer
export default class index extends CommonPage {
  constructor(props) {
    super(props);
    this.Carousel;
    this.state = {
      modal: 0,
      sprints: [],
      spmodal: false,
      id_project_sprint: 0,
      editItens: [],
      search:'',
      filter : -1,
      formSearch:{dt_start: moment().startOf('month').toDate(),dt_end:moment().endOf('month').toDate()
      ,id_company:0}
    };
    this.changeStatus = this.changeStatus.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.listSprintDate = this.listSprintDate.bind(this);
    this.filterSprints = this.filterSprints.bind(this)
  }
  handleModal = (v = 0, c) => {
    this.setState({ modal: v, editItens: c });
  };

  componentWillMount() {
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/sprints',
      'sprints',{dt_start: moment().startOf('month').subtract(1, 'months').toDate(), 
      dt_end:moment().endOf('month').add(1, 'months').toDate()}
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/sprintpjs',
      'sprintpjs'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TICKET + '/params',
      'params'
    );
  }

  listSprintDate(dadosForm){
 
   this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/sprints',
      'sprints',dadosForm
    );
  }

  handleDialog(id) {
    this.setState({ spmodal: true, id_project_sprint: id });
  }

  changeStatus(value, obj) {
    //id_project_sprint
    if (obj == true) {
      this.action
        .execute('post', this.APIEndpoints.PROJECT + '/sprintup', '', {
          statusdel: true,
          id_project_sprint: this.state.id_project_sprint
        })
        .then(c => {
          this.action.execute(
            'get',
            this.APIEndpoints.PROJECT + '/sprints',
            'sprints'
          );
        });
      this.redirect('/dash/projectmanagement/sprint');
    } else {
      this.action
        .execute('post', this.APIEndpoints.PROJECT + '/sprintup', '', {
          statusup: true,
          id_project_sprint: value.id_project_sprint
        })
        .then(c => {});
      this.redirect('/dash/projectmanagement/sprint');
    }
  }

  setSearch = (search)=>{
    this.setState({search})
  }
  filterSprints = (filter) => {
    this.setState({filter})
  }
 acStatus(sprint){
   try{
  let totalCon=0;
let total=0;
  
  totalCon=  parseInt(sprint.ac_closed) + parseInt(sprint.ti_closed)
  total=parseInt(sprint.tickets.length)+ parseInt(sprint.project_sprints_activities.length) 
  
 
let CompletedActivitiesProps = {
  activitiesTotal:total,
  completedActivities:totalCon,
  home:true
}

return 	<CompletedActivities {...CompletedActivitiesProps}/>
   }catch(e){
     return <b></b>
   }
 }

  render() {
    let { modal, search, filter } = this.state;
    let { language, users, sprints, sprintpjs } = this.store;
    let { SPRINT } = this.store.theme;
    let sprintsFilter = []
    if (filter == 1) {
      sprintsFilter = sprints.filter(b => b.status == 'In Progress' &&
        moment().format('YYMMDD') <= moment(b.dt_end).format('YYMMDD'))
    } else if (filter == 2) {
      sprintsFilter = sprints.filter(b => b.status == 'In Progress' && moment().format('YYMMDD') > moment(b.dt_end).format('YYMMDD'))
    } else {
      sprintsFilter = sprints
    }

    // -------------------------------------LISTA DE SPRINTS CONCLUÍDAS---------------------------------------------------------------
    let sprintConcluded = [];
    // ---------------------------------------------LISTA DE SPRINTS---------------------------------------------------------------

    //----------------NÃO APAGUE ISTO----------------------
    if (sprintConcluded.length < 6) {
      for (var i = sprintConcluded.length; i < 6; i++) {
        sprintConcluded.push(<div></div>);
      }
    }

  
    let totalUnplaned = 0
    let totalPlanned = 0;
    let _sprint =
      sprintsFilter &&
      sprintsFilter.map(v => {
        totalUnplaned = totalUnplaned + v.project_sprints_activities_unplaned.length + v.tickets_unplanned.length;
        totalPlanned = totalPlanned + v.tickets.length + v.project_sprints_activities.length;

        return {
          dt_start: v.dt_start,
          dt_end: v.dt_end,
          description: v.name,
          onChange: () => this.changeStatus(v),
          checked: false,
          id: v.id_project_sprint,
          status: v.status,
          tickets: v.tickets,
          project_sprints: v.project_sprints,
          sp: v,
          acStatus: this.acStatus(v)

        }
      });

     

     
      
    //let iv= <CompletedActivities></CompletedActivities>

    return (
      <CP
        title="SPRINT"
        subTitle={`${language.PROJECT_MANAGEMENT}/`}
        rightElements={[
          <div style={{ width: 500, zoom: 0.8, marginTop: '1rem' }} zoom={0.8} className='mr-4'>
            <CommonForm
              spacing={16}
              hiddenSubmit
              values={this.state.formSearch}
                onSubmit={e => {
                   this.listSprintDate(e)
                  }}
              onChangeField={(f, v) => {
             let formSearch=   this.state.formSearch[f.name] =v;
              
             }}
             
              fields={[
                {
                  col: 3.5,
                  type: 'select',
                  name: 'id_company',
                  label: language.COMPANY,
                  options: this.store.companies.map(b => ({
                    value: b.id_company,
                    label: b.name
                  }))
                },
                {
                  col: 4,
                  type: 'date',
                  name: 'dt_start',
                  label: language.START_DATE
                },
                {
                  col: 4,
                  type: 'date',
                  name: 'dt_end',
                  label: language.END_DATE
                },
                {
                  col: 0.5,
                  type: 'element',

                  content: (v, h, 
                    submit) => (
                    <Button
                       icon="fas fa-search"
                       variant="circle"
                       color="success"
                        label={language.SEARCH}
                       style={{ marginTop: 15 }}
                       onClick={() => submit()}
                    />)

 
                }
              ]}
            />
          </div>
        ]}
      >
        <DialogForm
          users={users}
          action={this.action}
          APIEndpoints={this.APIEndpoints}
          companies={this.store.companies}
          sprintpjs={this.store.sprintpjs}
          redirect={this.redirect}
          editItens={this.state.editItens}
          onOpenModal={v => this.handleModal(v)}
          open={modal == 1}
          onClose={() => this.handleModal()}
        />

        <div id="Home">
      
        <Block1 totalUnplaned={totalUnplaned} action={this.action} APIEndpoints={this.APIEndpoints} users={users} totalPlanned={totalPlanned}
        
        sprints={sprintsFilter}
        ></Block1>
      <Header onOpenModal={() => this.handleModal(1)} setSearch={this.setSearch} filterSprints={this.filterSprints}/>
          <Card>
            <AppBar title={language.RUNNING_SPRINTS} />
             <Grid container spacing={2} className="content pt-0">
                {_sprint
                  .filter(b => b.status == 'In Progress' &&
                  moment().format('YYMMDD') <=  moment(b.dt_end).format('YYMMDD')
                  
                  ).filter(e=>e.description.toLocaleLowerCase().indexOf(search.toLocaleLowerCase())!=-1) 
                  .map((e, i) => (
                    <Grid item xs={2} key={e.description + '-' + i}>
                      <SprintCard
                        cardTheme={SPRINT.card}
                        redirect={this.redirect}
                        handleDialog={this.handleDialog}
                        changeStatus={this.changeStatus}
                        {...e}
                      />
                    </Grid>
                  ))}
            </Grid>
            <AppBar title={language.SPRINTS_CREATED} />
            <Grid container spacing={2} className="content pt-0">
              {_sprint
                .filter(b => b.status == 'Create').filter(e=>e.description.toLocaleLowerCase().indexOf(search.toLocaleLowerCase())!=-1)
                .map((e, i) => (
                  <Grid item xs={2} key={e.description + '-' + i}>
                    <SprintCard
                      cardTheme={SPRINT.card}
                      handleDialog={this.handleDialog}
                      changeStatus={this.changeStatus}
                      handleModal={() => this.handleModal(1, e.sp)}
                      {...e}
                    />
                  </Grid>
                ))}
            </Grid>
            <AppBar title={language.COMPLETED_SPRINTS} />
            <Grid container spacing={2} className="content pt-0">
              {_sprint
                .filter(b => b.status == 'In Progress' &&  moment().format('YYMMDD') >  moment(b.dt_end).format('YYMMDD')).filter(e=>e.description.toLocaleLowerCase().indexOf(search.toLocaleLowerCase())!=-1)
                .map((e, i) => (
                  <Grid item xs={2} key={e.description + '-' + i}>
                    <SprintCard
                     cardTheme={SPRINT.card}
                      handleDialog={this.handleDialog}
                      noopen={true}
                      changeStatus={this.changeStatus}
                      {...e}

                    />
                  </Grid>
                ))} 
            </Grid>
          </Card>
        </div>
        <Dialog open={this.state.spmodal}>
          <div style={{ textAlign: 'center', color: 'var(--primary)' }}>
            <h2>{language.DO_YOU_REALLY_WANT_TO_DELETE_THE_SPRINT}</h2>
            <Button
              label={language.CONFIRM}
              className="btn-item"
              onClick={() => this.changeStatus({}, true)}
              color="primary"
              variant="normal"
            />
          </div>
        </Dialog>
      </CP>
    );
  }
}
