import React,{ Component } from 'react';
import './style.css';
export default class PieChart extends Component{
  render(){
    let {
      percent, 
      bgStrokeWidth, 
      strokeWidth, 
      color='', 
      strokeColor,
      textProps={},
      text=true,
      total,
      slaok
     
    } = this.props;
    var _color = color.indexOf('#')===0;

    return(
      <svg viewBox="0 0 36 36"  className={"circular-chart "+(!_color?(color?('m'+color):'orange'):'')}>
        <path className="circle-bg"
          stroke-width={bgStrokeWidth||3.8}
          stroke={strokeColor||'#eee'}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
     
     {total && 
        <path className="circle"
          stroke={_color?color:undefined}
          stroke-dasharray={(slaok/total)*100+", 100"}
          stroke-width={strokeWidth||2.8}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
     }

     
{percent && 
        <path className="circle"
          stroke={_color?color:undefined}
          stroke-dasharray={percent+", 100"}
          stroke-width={strokeWidth||2.8}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
     }



        {text&& total &&
          <text 
            x={18} 
            y={20.35} 
            fill='#666'
            {...textProps}
            className={"percentage "+textProps.className}
          >
           
              {total}
            
          </text>
        }
      {text&& percent &&
                <text 
            x={18} 
            y={20.35} 
            fill='#666'
            {...textProps}
            className={"percentage "+textProps.className}
          >
           
              {percent}%
            
          </text>
        }


      </svg>
    )
  }
}