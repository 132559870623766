import React from 'react';

import Store from '@data/Store';

const { language } = Store;

export default function({history}){
   return (
      <div className='Block_7'>
         <span className='label-items mb-4 d-block'>{language.HISTORY}</span>
         <p dangerouslySetInnerHTML={{__html: history}}/>
      </div>
   )
}