import React, { Component } from 'react';
import { observer } from 'mobx-react';
import  Dialog  from '@common/Dialog';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';  
import ReactTolltip from 'react-tooltip';
import CommonForm from '../../common/CommonForm';
import FaClose from 'react-icons/lib/fa/close';
const styles = theme => ({
  dialogPaper: {
     minHeight: '80vh',
    maxHeight: '80vh',
    minWidth:'1200px'
  } ,dialogPaperAlert: {
    minHeight: '80vh',
   maxHeight: '80vh',
   minWidth:'1200px'
 },
  dialogPaper_: {
    minHeight: '80vh',
   maxHeight: '80vh',
   minWidth:'1200px'
 } 
});
  class GroupModal extends Component {
  render() {
    let {
      closeModal,
      company,
      companies,
      group,
      language,
      onFormSubmit,
      open,
      comp,
      devices,
      monitordevicecompany
    } = this.props;
    const { classes } = this.props;
    let name_group  
 
    
    let bool = true;
      if (company.length == 0 && comp != undefined){
       // console.log(JSON.stringify(devices) )
      let   emp =   companies.find(c=>c.name === comp)
      
      name_group = devices.name_group;
      company = {id_company : emp.id_company , company: emp.name}
      
      devices =    devices.devices.map(d => d.id_device);
      bool =false;
      }else{
        name_group = company.groups.find(g => g.id_group === group).name_group;
      }
      
    
    let companyOptions = [{ value: company.id_company, text: company.company }];
 
    if(this.props.user.role=='tech'){

      try{
        companyOptions = 
         companies.filter(g=> this.props.usercon_company[0].company.includes(g.id_company)).map(c => ({
        value: c.id_company,
        text: c.name
          }));
        }catch(t){
          companyOptions=[];
        }
          }  
      



    let devicesOptions = !company
      ? []
      : companies
          .find(c => c.id_company == company.id_company)
          .devices.map(d => ({ value: d.id_device, text: d.name }));

    let devicefields = [
      {
        span: 6,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        options: companyOptions,
        required: true,
        disabled: !!company
      },
      {
        span: 6,
        name: 'name_group',
        label: language.NAME,
        type: 'text',
        required: true
      },
      {
        span: 12,
        name: 'devices',
        title: language.DEVICES,
        type: 'listCheckbox',
        options: devicesOptions
      }
    ];

    let deviceButton = {
      md: 12,
      label: language.SAVE,
      primary: true,
      style: { marginTop: 10 }
    };

  
 if(bool){
      devices = company.groups
      .find(g => g.id_group === group)
      .devices.map(d => d.id_device);
 }

    return (
      <Dialog open={open} onClose={closeModal}
      PaperProps={{ classes: {root: classes.dialogPaperAlert } }}
      title={language.DEVICES_GROUP}
      >
          <CommonForm
            language={language}
            fields={devicefields}
            values={{
              id_company: company.id_company,
              name_group: name_group,
              devices: devices
            }}
            button={deviceButton}
            onFormSubmit={onFormSubmit}
          />
        
      </Dialog>
    );
  }
}
GroupModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(GroupModal);