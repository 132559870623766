import 'react-quill/dist/quill.snow.css';
import React, { Component, Fragment, useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import moment from 'moment';
import classnames from 'classnames';
import './TicketComment.css';
import { Avatar } from '@material-ui/core';
import Store from '../../../data/Store';
import CommonForm2 from '../../../common/CommonForm2';
import { findUserGroup } from '../utils/findUserGroup';
import Actions from '@data/Actions';

const action = new Actions();

function OnScrollBottom({ comments, callback }) {
  useEffect(() => {
    callback();
  }, [comments]);
  return null;
}

export default class AttendanceComment extends Component {
  constructor(props) {
    super(props);
    this.msgContainter = React.createRef();
    this.state = {
      description: '',
    };
  }
  setScrollBottom = () => {
    var el = this.msgContainter.current;
    var scrollP = el.scrollHeight - el.scrollTop - el.offsetHeight;

    if (scrollP <= 300) {
      el.scrollTo(0, 1000000);
    }
  };

  onChangeField = (f, v) => {
    this.setState({ description: v });
  };

  handleCheckIsLink = (value) => {
    const regex =
      /(http:\/\/|https:\/\/)(w{3}\.)?([a-zA-Z0-9]+)(.\w+)?(\.com)(.+)?/gm;
    const result = regex.exec(value);

    if (result) {
      let link = result[0].split(' ')[0].replace(/<\/p>|\s.rel+/g, '');
      if (link.includes('</a>')) {
        link = link.replace('</a>', '');
      }

      let newLink = `<a href="${link}" target="_blank">${link}</a>`;
      let newValue = value.replace(link, newLink);

      return newValue;
    }

    return value;
  };

  onCommentTicketSub(id_sac_attendance, values, changecomponent) {
    values.id_attendance_sac = id_sac_attendance;
    values.id_sac_steps = this.props.step.id_sac_task;
    values.fk_id_user = values.fk_id_user
    values.deadline = values.deadline

    const result = values.description.search('<img');
    if (result == -1) {
      values.description = values.description.replace(/\\/g, '&#92;');
      values.description = values.description.replace(/["]+/g, '&#34;');
    }
    values.description = changecomponent
      ? values.description.replace(/<.*?>/g, '')
      : values.description;
    values.description = this.handleCheckIsLink(values.description);

    action
      .execute('post', `${this.props.APIEndpoints.SAC_STEPS}/comment`, '', values)
      .then((e) => this.props.updateAttendance());
  }

  render() {
    let randomSeed = Math.floor(Math.random() * 1000);

    let {
      language,
      ticket,
      hideNewComment,
      step,
      loading,
      Attendance,
      setRefComments,
      changecomponent
    } = this.props;

    let fields = [
      {
        col: 12,
        name: 'description',
        label: language.MESSAGE,
        required: true,
        type: 'simplesTextEditor',
        filter: this.props.filter,
      },
    ];

    let button = {
      label: language.SEND,
      loading,
      disabled: step && (ticket.status == 'Resolved' ||
                      ticket.status == 'Closed' ||
                      step.stage == 'Canceled' ||
                (!findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) &&
                step.fk_id_user !== Store.getUserLoged().id)) ?
                true : false
    };

    let user = Store.getUserLoged();

    let commentsNodes = [];
    let duplicate = [];


    ticket?.comments?.length > 0 &&
    ticket.comments.map((c, k) => {
        if (
          duplicate.filter((n) => n === c.id_comments_attendance).length == 0
        ) {
          duplicate.push(c.id_comments_attendance);
          commentsNodes.push(
            <Comment
              user={user}
              randomSeed={randomSeed}
              key={k}
              name={c.name}
              language={language}
              {...c}
              myComment={step ? c.fk_id_user === step.fk_id_user : c.fk_id_user == Store.getUserLoged().id}
            />
          );
        }
      });

    return (
      <div id="TicketComment">
        <OnScrollBottom
          comments={ticket?.comments?.length > 0}
          callback={() => {
            this.setScrollBottom();
          }}
        />
        <Container>
          <Row>
            <Col md={12}>
              <ul
                className="msg-containter"
                ref={this.msgContainter}
                style={
                  !!this.props.maxHeightChat
                    ? { height: `${this.props.maxHeightChat}vh` }
                    : {}
                }
              >
                {commentsNodes}
              </ul>
            </Col>
          </Row>

          {!hideNewComment && (
            <div
              className="content px-0 pt-0"
              style={{ marginTop: '10px' }}
            >
              {this.props.statusTicket === 'Closed' ||
              (this.props.isReadonly &&
                this.props.statusTicket !== 'Resolved') ? null : (
                <>
                  <CommonForm2
                    onRef={setRefComments}
                    fields={fields}
                    button={button}
                    clean
                    onSubmit={(values) => {
                        if (ticket.status !== 'In Progress') {
                          Store.toggleAlert(true, 'Necessário iniciar a Tarefa antes de informar o Parecer', 'error');
                        } else {
                          if (step.stage === 'Pending') {
                            this.props.startStep(false);
                          }
                          values.fk_id_user = step.fk_id_user;
                          values.deadline = step.deadline;
                          this.onCommentTicketSub(
                            ticket.id_attendance_sac,
                            values,
                            changecomponent
                          )
                        }
                      }
                    }
                    onChangeField={this.onChangeField}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 15,
                    }}
                  ></div>
                </>
              )}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

class Comment extends Component {
  render() {
    let {
      myComment,
      name_create,
      description,
      dt_cad,
      user,
      name
    } = this.props;

    let FDate = moment.locale() == 'pt-br' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';
    myComment = !(user.id == this.props.fk_id_user ? true : false);

    description = description.replace(/\&#34;/g, '"');

    description = description.replace(
      '<ol',
      '<ol class="show-number-list-style"'
    );
    description = description.replace(
      '<ul',
      '<ul class="show-bullet-list-style"'
    );

    return (
      <li className={classnames(myComment ? 'received' : 'sended', 'msg')}>
        <div className="msg-box shadown">
          <Fragment>
            <Avatar className="avatar">{name_create.substr(0, 1)}</Avatar>
            <div className="box-data">
              <div className="title-content">
                <small className="name">{name_create} / Etapa: {name}</small>
                <small className="date">
                  {moment(dt_cad).format(FDate + ' - HH:mm:ss')}
                </small>
              </div>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </Fragment>
        </div>
      </li>
    );
  }
}
