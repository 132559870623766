

import React, { Component } from 'react';
import Store from '../../data/Store.js';

class Notify extends Component {


    constructor() {
        super();
         this.state = {
          ex:false
        }
    }
    componentDidMount() {
    this.props.socket.on('chat-ticket-'+Store.getTenant, (data) => {
      
        if(data.fk_id_tickets == this.props.t.id_tickets){
            this.setState({ex:true})
        }
     
     })
   
     this.props.msn.map(data=>{
      if(data.id_tickets == this.props.t.id_tickets){
         this.setState({ex:true}) 
        }
      })
    }

    render(){
 
        

    return  this.state.ex == true ?
          <i  style={{color:'#fa9633',
           marginTop : '2px',
           fontSize:'14px' }}
          class="fas fa-exclamation-circle"></i>:<i></i> 
    }
}
export default Notify;

 