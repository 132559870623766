import { Tooltip } from 'react-tippy';
import React from 'react'
import { BugReport, Help, Report, ReportProblem } from '@material-ui/icons';
import { Box } from '@material-ui/core';

export default function TicketIconType({ label, type }) {

    const TYPE_ICON_MAP = {
        1: Report,
        2: BugReport,
        3: Report,
        4: Help
    }

    const TYPE_COLOR_MAP = {
        1: 'var(--warning)',
        2: 'var(--danger)',
        3: 'var(--warning)',
        4: 'var(--default)'
    }

    const Icon = [2, 4].includes(type) ? TYPE_ICON_MAP[type] : TYPE_ICON_MAP[1];
    const color = [2, 4].includes(type) ? TYPE_COLOR_MAP[type] : TYPE_COLOR_MAP[1];

    return (
        <Box className='tw-flex tw-justify-center'>
            <Tooltip
                title={label}
                position="right"
                //className='tw-cursor-pointer'
            >
                <Icon
                    style={{
                        color: color,
                        fontSize: 20,
                    }}
                />
            </Tooltip>
        </Box>
    )
}
