import React, {Fragment} from 'react';


export default function ({group, openUpdateHandle, handleSelectGroup, getAvailability, onUpdate, companies, onClose, upGroup}) {
    return (
        <Fragment>
          <i class="fas fa-pen p-2"
            style={{ cursor: 'pointer', color: 'var(--success)', fontSize: '' }}
            onClick={() => openUpdateHandle({
              id_group: group.group_id,
              onClose,
              companies: companies,
              comp: group.company,
              company: group.group,
              group: group.group_id,
              devices: group.items,
              open: true,
              onUpdate: onUpdate
            })}
          ></i>
          <i
            className="fas fa-trash-alt p-2"
            style={{ cursor: 'pointer', color: 'var(--danger)', fontSize: '' }}
            onClick={() => upGroup(group.group_id, 'del')}>

          </i>
          {/*  this.props.openUpdateHandle({
id_group: gd.id_group,
onClose: this.onClose,
companies: companies,
comp: comp,
company: company,
group: group,
devices: gd,
open: true,
onUpdate: this.onUpdate
}) */}
          <div className="d-flex" onClick={() => 
          
            
          getAvailability(group.group_id)}>
            <span className="ml-3 mr-1 label ellipsis">
              {group.label}
              
              
            </span>
          

            <div
              className="d-flex align-items-center"
              style={{ flexWrap: 'wrap' }}
            >
              {group.items.length > 0 && group.items.map((item) => (
                <div
                  key={item.id_device}
                  className="device-map-item"
                  style={{ backgroundColor: 'var(--primary)' }}
                />
              ))}
            </div>
          </div>
        </Fragment>
      )
}