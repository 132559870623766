import { Checkbox, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

import {
  getHolidaysService,
  postHolidaysSlaService,
} from '../../ListHolidays.services';

import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';

// ----------------------------------------------------------------------

const columns = (language) => [
  { key: 'check', label: '', style: { width: 10 } },
  { key: 'id', label: '#', style: { width: 20 } },
  { key: 'name', label: language.NAME },
  { key: 'date', label: language.DATE },
];

export const DialogAddHolidaySla = (props) => {
  const { language, setDialogAddHolidaySlaOpen, slaId, holidaysInSla } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(75);
  const [holidaysSelected, setHolidaysSelected] = useState([]);
  const [getHolidays, setGetHolidays] = useState([]);

  const rows = getHolidays.map((row) => ({
    check: (
      <Checkbox
        checked={holidaysSelected.includes(row?.id)}
        onChange={() => handleHolidays(row?.id)}
      />
    ),
    ...row,
    date: moment(row.data).format('DD/MM/YYYY'),
  }));

  const handleHolidays = (holidayId) => {
    const holidayIsSelected = holidaysSelected.includes(holidayId);
    const updateHolidayIsSelected = holidayIsSelected
      ? holidaysSelected.filter((holiday) => holiday !== holidayId)
      : [...holidaysSelected, holidayId];

    return setHolidaysSelected(updateHolidayIsSelected);
  };

  const handleSubmit = async () => {
    await postHolidaysSlaService(slaId, holidaysSelected);
    setDialogAddHolidaySlaOpen(false);
  };

  useEffect(() => {
    const getHolidays = async () => {
      const result = await getHolidaysService();
      const filterNotHolidaysInSla = result.filter(
        (holiday) => !holidaysInSla.includes(holiday.id)
      );

      setGetHolidays(filterNotHolidaysInSla);
    };

    getHolidays();
  }, []);

  return (
    <Dialog
      title={language.HOLIDAYS}
      modal={false}
      open
      onClose={() => setDialogAddHolidaySlaOpen(false)}
    >
      <CommonTable
        title=""
        language={language}
        columns={columns(language)}
        data={rows}
        callPageSelectFilter={({ page, values, rowsPerPage, type }) => {
          setPage(page);
          setRowsPerPage(rowsPerPage);
        }}
        countTotal={getHolidays?.length}
        isAutomaticPagination={false}
        beginWithNoFilter
        loadSearch
        currentPage={page}
        hideFooterToolbar
        paginationTop={false}
      />
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="normal"
          icon="fas fa-save"
          color="success"
          onClick={handleSubmit}
          label={language.SAVE}
        />
      </Grid>
    </Dialog>
  );
};
