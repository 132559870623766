import React, { Component } from "react";
import { observer } from "mobx-react";

import Store from "../../data/Store";

function withLanguage(WrappedComponent) {
  @observer
  class WithLanguage extends Component {
    constructor(props) {
      super(props);
      this.store = Store;
    }
    render() {
      const { language } = this.store;
      const { children } = this.props;

      return <WrappedComponent {...this.props} language={language} />;
    }
  }

  return WithLanguage;
}

export default withLanguage;
