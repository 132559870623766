import React, { useState, useEffect } from 'react';
import './BarRight.css';
import { Avatar } from '@material-ui/core';
import Button from '@common/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
import moment from 'moment'
import Store from '@data/Store';

const { language } = Store;
export default function ({ idProject, action, APIEndpoints, barRightTheme }) {
	let [open, setOpen] = useState(false);
	let [fee, SetFee] = useState([]);

	useEffect(() => {
		action.execute('get', APIEndpoints.PROJECT + '/feeds/' + idProject).then(e => {
			SetFee([...e.data])
		});
		const interval = setInterval(() => {
			action.execute('get', APIEndpoints.PROJECT + '/feeds/' + idProject).then(e => {
				SetFee([...e.data])
			})
		}, 120 * 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div id='GSD-BarRight' className={classnames({ close: !open })} style={{'--background':barRightTheme.background}}>
			<div className='content-medium d-flex aling-items-center' id='header'>
				<div>
					{open ? (
						<Button
							icon='fas fa-angle-right'
							style={{ border: 'none' }}
							label={language.HIDE}
                     color={barRightTheme.button}
							className='button-close'
							onClick={() => setOpen(!open)}
						/>
					) : (
							<Button
								icon='fas fa-flag-checkered'
								style={{ border: 'none' }}
								variant='circle'
								color='light'
								outlined
								onClick={() => setOpen(!open)}
							/>
						)}
				</div>
			</div>
			<hr className='my-0' />
			<div className='content-medium' id='body'>
				<h3>Log de atividades</h3>
				<PerfectScrollbar
					className="scrollVisible"
					style={{
						maxHeight: 'calc(100vh - 170px)'
					}}
					option={{
						suppressScrollX: true
					}}

				>
					<ul>
						{fee.map((e, i) => (
							<li className={classnames('d-flex align-items-center mt-3 mb-3', { 'mt-0': i == 0 })}>
								<Avatar className='avatar'>
									<i className='fas fa-user-alt' />
								</Avatar>

								<div className={classnames({ 'mx-2': open })}>
								
								</div>
								<div className='logs'>
									<Tooltip
										html={
											<div style={{ color: '#fff', fontSize: 15 }}>
												<strong>{e.name_user}</strong>
											</div>
										}
										arrow={true}
										position='left'
										theme='dark'

									>
										<h4 className='ellipsis'>{e.name_user}   </h4>
									</Tooltip>
									<Tooltip
										html={
											<div style={{ color: '#fff', fontSize: 15 }}>
												<strong>{e.name}  </strong>
											</div>
										}
										arrow={true}
										position='left'
										theme='dark'

									>
										<h3 className='ellipsis' style={{ maxWidth: 131 }}>{e.fk_id_project_activity + ' - ' + e.name}</h3>
									</Tooltip>
									<div className='d-flex align-items-center justify-content-between'>
										<span>{moment(e.dt_cad).format('DD/MM/YYYY')}</span>
										<span>{moment(e.dt_cad).format('HH:mm')}</span>
									</div>
								</div>
							</li>
						))}
					</ul>
				</PerfectScrollbar>
			</div>
		</div>
	)
}