import React, { Fragment, memo, useReducer } from 'react';
import classnames from 'classnames';
/*------------------------------------common--------------------------------------------------*/
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
/*------------------------------------material-ui----------------------------------------------*/
import Store from '@data/Store';

const { language } = Store;

export default memo(({
	company,
	priority,
	dt_i,
	dt_f,
	late,
	realized,
	effort,
	percent,
	onOpenForm,
	values: _values = {},
	weight,
	setFormSubactivitiesOpen,
	setFormEvolutionOpen,
	subActivities,
	time_in_days,
	general_params,
	_onOpenForm,
	ondeletefile,
	onDownloadFile,
	data,
	UpdateFiles
}) => {

	let [form, setForm] = useReducer((state, v) => [...state, v], []);
	let values = {};

	if (!!Object.keys(data).length) {
		values = Object.assign(
		  {},
		  data,
		  {
			files: !data.file
			  ? []
			  : data.file.map((f) => ({
				  name: f.file,
				  size: f.size,
				  type: f.type,
				  id_gp_file: f.id_gp_file,
				  id_project_activity: f.id_project_activity,
				})),
		  }
		);
	}

	return (
		<Fragment>
			<div className='content nav-b-1'>
				<span className='label-items'>
					<i className="fas fa-briefcase" />
					{language.COMPANY}
				</span>
				<span className='name mb-3'>{company}</span>
				<span className='label-items'>
					<i className="fas fa-star" />
					{language.PRIORITY}
				</span>
				<div className='content-star mt-1 mb-3'>
					{[1, 2, 3].map(e => (
						<i className={classnames(e > priority ? 'far fa-star' : 'fas fa-star', 'star-icon', { 'mx-1': e == 2 })} />
					))}
				</div>

				<span className='label-items'>
					<i className="fas fa-weight-hanging" />
					{language.WEIGHT}
				</span>
				<div className='mt-1'>
					{[1, 2, 3, 4, 5].map(e => (
						<i key={e} className={(e <= weight ? 'selected' : '') + ' fas fa-weight-hanging weight-btn mr-2'} />
					))}
				</div>
			</div>
			{/*---------------------------------------------------------------------------------------------------------------*/}
			<div className='content nav-b-2'>
				<h5 className='mb-3'>{language.ACTIVITY_TIME}</h5>
				<span className='label-items'>
					<i className="fas fa-calendar-day" />
					{language.START_}
				</span>
				<span className='value-items'>{dt_i}</span>

				<span className='label-items mt-3'>
					<i className="fas fa-calendar-day" />
					{language.END_}
				</span>
				<span className='value-items' style={{ color: late ? 'var(--danger)' : undefined }}>{dt_f}</span>

				<span className='label-items mt-3'>{language.CONSUMED}</span>
				<h3 className='mb-2'>{realized}</h3>
				<Progress current={percent} background='var(--danger)' />

				<span className='label-items mt-3'>{language.EFFORT}</span>
				<h4>{time_in_days && general_params.hours_day ? effort.split(':')[0] / general_params.hours_day : effort}{time_in_days ? 'd' : 'h'}</h4>
			</div>
			{/*---------------------------------------------------------------------------------------------------------------*/}
			{percent != 100 && typeof(_onOpenForm) == "function" ? (
				<div className='content nav-b-3'>
					<h5 className='mb-3'>{language.ADD_THE_ACTIVITY}</h5>
					{!subActivities.length ? (
						<div className='mb-1'>
							<Button
								icon='fas fa-clock'
								color='warning'
								label={language.REGISTER_EVOLUTION}
								variant='normal'
								onClick={setFormEvolutionOpen}
								fluid
							/>
						</div>
					) : null}
					<div className='mb-1' style={{ paddingTop: "17px", paddingBottom: "12px"}}>
						<CommonForm
                    		onRef={(r) => setForm(r)}
                    		values={values}
                    		fields={[
                      		{
                        		col: 12,
                        		type: 'file',
                        		name: 'files',
														label: language.ATTACH,
								removeFile: (f) => ondeletefile(f),
                        		onClick: (f) => onDownloadFile(f.id_gp_file, f.name),
                      		}
							]}
							onChangeField={(f, v) => {
								UpdateFiles(v, data);
							}}
						/>
					</div>			
					<div className='mb-1'>
						<Button
							icon='fas fa-user-friends'
							color='success'
							label={language.MEMBERS}
							variant='normal'
							onClick={onOpenForm}
							fluid
						/>
					</div>
					<div className='mb-1'>
						<Button
							icon='fas fa-list'
							color='success'
							label={language.SUBACTIVITIES}
							variant='normal'
							onClick={setFormSubactivitiesOpen}
							fluid
						/>
					</div>
				</div>
			) : null}
		</Fragment>
	)
})