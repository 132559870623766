import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import Pickers from '@common/Pickers';
import TextField from '@material-ui/core/TextField';
import AppBar from '@common/AppBar';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import CommonForm2 from '../../common/CommonForm2';
import { FaBriefcase, FaPlusSquare, FaClose } from 'react-icons/lib/fa';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'react-tippy';
import SimplesAlert from '@common/SimplesAlert';
import AttachFile from '@common/AttachFile';
import {
  Chip,
  Avatar,
  Card,
  Badge,
  ListItem,
  FormControlLabel,
  List,
  Checkbox,
} from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AttachFile2 from '@common/AttachFile2';
import Dialog from '@common/Dialog';
import { MaskMoney, MaskNumber } from '@common/Mask';
import { FormatMoney } from '@common/Format';
import { testRoute } from '@common/Func';
import Header from '@common/Header';
import './styles.css';
import CommonTable from '../../common/CommonTable';
import Button from '@common/Button';
import Constants from '@data/Constants';
import './ContractForm.js';
import { getCoinsService } from '@components/CoinManagement/coin.services';
import { translateFromPtBR } from '@common/languages/Dictionary';

import Store from '@data/Store';

const { language } = Store;

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});
function setHours(hour) {
  var m = moment();
  m.set({ hour: hour, minute: 0, second: 0, millisecond: 0 });
  return m;
}
class ListTeckSupport extends Component {
  render() {
    let { handleCheckbox, handleFrom, handleTo, handleValue, language, e } =
      this.props;

    return (
      <ListItem className="listItem" dense button>
        <FormControlLabel
          style={{ marginTop: 12, marginLeft: 0 }}
          className="fieldCustom"
          control={
            <Checkbox
              disabled={e.disabled}
              checked={e.checked}
              value={e.id}
              onChange={handleCheckbox}
            />
          }
          label={e.label}
        />
        <Pickers
          className="fieldCustom"
          label={language.FROM}
          type="time"
          style={{ width: 150 }}
          value={e.from}
          disabled={e.disabled}
          onChange={handleFrom}
        />
        <Pickers
          className="fieldCustom"
          label={language.AT}
          type="time"
          style={{ width: 150 }}
          value={e.to}
          disabled={e.disabled}
          onChange={handleTo}
        />
        <TextField
          className="fieldCustom"
          disabled={e.disabled}
          label={language.VALUE}
          style={{ width: 75 }}
          value={e.value}
          onChange={handleValue}
          InputProps={{
            inputComponent: MaskMoney,
          }}
        />
      </ListItem>
    );
  }
}
class Title extends Component {
  render() {
    return (
      <Button variant="contained" size="small" color="primary" {...this.props}>
        {this.props.label}
      </Button>
    );
  }
}
@observer
export default class ContractForm extends Component {
  constructor() {
    super();
    this.state = {
      company: '',
      mes: true,
      year: false,
      page: 0,
      values: [],
      historic: [],
      select: {
        age: '',
        handleModalContractTime: false,
      },
      addSolicitantDialog: false,
      type: '',
      companyprovider: 0,
      alert: {
        open: false,
        variant: '',
        message: '',
      },
      information: '',
      form: {
        support: [],
      },
      addSupportDialog: false,
      readjustmentContract: true,
      parent_contract_options: [],
      coins_options: [],
      end_date : '',
      disabled_start_date: true
    };
    this.onChangeField = this.onChangeField.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentWillMount() {
    this.props.action
      .execute('get', Constants.APIRoot + '/api/generalparameters')
      .then((e) => {
        this.setState({ readjustmentContract: e.data.readjustment_contract });
      })
      .catch((error) => {
        console.log(error);
      });

    const getCoins = async () => {
      const result = await getCoinsService();
      const normalizedResult = result.map((coin) => ({
        value: coin.id_coin,
        label: translateFromPtBR(coin.name, language),
      }));
      console.log('here', normalizedResult);

      this.setState((currentState) => ({
        ...currentState,
        coins_options: normalizedResult,
      }));
    };

    getCoins();

    var values = this.props.values;

    if (this.props.values != undefined) {
      if (this.props.values.hasOwnProperty('id_company')) {
        this.props.action.execute('get', this.props.APIEndpoints.CONTRACT + '/historic/' + values.id_contract, '')
          .then(res => {
            this.setState({ historic: res.data })
          })
        this.props.values.type_contract = Number(this.props.values.type_contract) ? Number(this.props.values.type_contract) : this.props.values.type_contract == "opex" ? 2 : this.props.values.type_contract == "capex" ? 1 : '';
        this.setState({ values: this.props.values })
      }

      this.setState({ end_date: values.end_date })
    }

    var form = this.state.form;
    form.support = [
      {
        id: 7,
        label: language.WEEK,
        from: setHours(9),
        to: setHours(17),
        value: '',
      },
      {
        id: 0,
        label: moment()._locale._weekdays[0],
        from: new Date(setHours(9)),
        to: new Date(setHours(17)),
        value: '',
      },
      {
        id: 6,
        label: moment()._locale._weekdays[6],
        from: new Date(setHours(9)),
        to: new Date(setHours(17)),
        value: '',
      },
    ];

    if (values && values.type) {
      var contact_support = (values.contact_support || []).filter((e) => e);
      form.support = form.support.filter((e) => {
        return !contact_support.find((i) => i.id == e.id);
      });
      form.support = form.support.concat(contact_support);

      form.support = form.support.map((e) =>
        Object.assign({}, e, {
          checked: !!(values.contact_support || []).find((i) => i.id == e.id),
          from:
            typeof e.from == 'string'
              ? new Date(e.from.replace('.000Z', ''))
              : e.from,
          to:
            typeof e.to == 'string'
              ? new Date(e.to.replace('.000Z', ''))
              : e.to,
          label: e.id != 7 ? moment()._locale._weekdays[e.id] : language.WEEK,
        })
      );
      let companyProvider = values.company_provider || 0;
      this.setState({ companyprovider: companyProvider });
    }
    this.setState({ form });
  }

  onChangeField(field, value) {
    let values = { ...this.state.values };
    values[field.name] = value;

    this.setState({ values });

    if (field.name === 'company_provider') {
      this.props.action
        .execute(
          'get',
          `${Constants.APIRoot}/api/contract/detail/company/${value}`
        )
        .then((result) => {
          const { data } = result;
          const normalizeOptions = data.map((option) => ({
            label: option.name,
            value: option.id_contract,
          }));

          this.setState({ parent_contract_options: normalizeOptions });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (field.name === 'fk_id_company') this.setState({ company: value });
    if (field.name === 'company_provider')
      this.setState({ companyprovider: value });

    if (field.name === 'type' && value === 'month') {
      this.setState({ type: 'month' });
    } else if (field.name === 'type' && value === 'hour') {
      this.setState({ type: 'hour' });
    } else if (field.name === 'type' && value === 'year') {
      this.setState({ type: 'year' });
    }

    if (field.name === 'month') {
      this.setState({ disabled_start_date: false });
    } else if (field.name ===  'hour') {
      this.setState({ disabled_start_date: false });
    } else if (field.name ===  'year') {
      this.setState({ disabled_start_date: false });
    }

    if (field.name == 'start_date'){
      if(this.state.values.type === 'month'){
        this.setState({ end_date : moment(value).add(this.state.values.month, 'month')}, () =>  {
          values['end_date'] = this.state.end_date
        });
      }
      else if(this.state.values.type === 'year'){
        this.setState({ end_date : moment(value).add(this.state.values.year, 'year')}, () =>  {
          values['end_date'] = this.state.end_date
        });
      }
      else{
        // this.setState({ end_date : value})
        values['end_date'] = this.state.end_date
      }
    }

    if(this.state.end_date && this.state.values.start_date && field.name != 'start_date'){
      if(field.name === 'month'){
        this.setState({ end_date : moment(this.state.values.start_date).add(value, 'month')}, () =>  {
          values['end_date'] = this.state.end_date
        });
      }
      else if(field.name === 'year'){
        this.setState({ end_date : moment(this.state.values.start_date).add(value, 'year')}, () =>  {
          values['end_date'] = this.state.end_date
        });
      }
      else if(field.name === 'hour'){
        this.setState({ end_date : this.state.values.end_date}, () =>  {
          values['end_date'] = this.state.end_date
        });
      }
    }


    values[field.name] = value
    this.setState({ values })

  }
  addDay = (e) => {
    var form = this.state.form;
    var item = {
      id: e.id,
      label: moment()._locale._weekdays[e.id],
      from: new Date(moment(e.start_time)),
      to: new Date(moment(e.end_time)),
      value: e.value,
    };
    form.support.push(item);
    this.setState({ form, addSupportDialog: false });
  };

  returnFormatedWithoutZone = (date) => {
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(date.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);

    return localISOTime;
  };
  onFormSubmit = (e) => {
    let item = 'sub';
    if (e.type == 'hour') {
      if (
        parseInt(moment(e.end_date).format('YYMMDD')) <
        parseInt(moment(e.start_date).format('YYMMDD'))
      ) {
        item = 'false';
        this.props.store.toggleAlert(
          true,
          language.INVALID_CONTRACT_DATE,
          'error'
        );
      }
    }

    if (item == 'sub') {
      var form = this.state.form;

      e.contact_support = form.support
        .filter((e) => e.checked)
        .map((e) => ({
          id: e.id,
          from: e.from,
          to: e.to,
          value: FormatMoney(e.value, { local: 'en-US' }),
        }));
      if (e.readjustment_percent) {
        e.readjustment_percent = e.readjustment_percent
          .toString()
          .replace(',', '.');
      }
      //e.end_date = (new Date(moment(e.start_date).add(e.month||0, 'M')));

      this.props.onFormSubmit(e);
    }
  };
  toggleAlert = (open, message = '', variant = this.state.alert.variant) => {
    var alert = this.state.alert;
    alert.open = open;
    alert.message = message;
    alert.variant = variant;
    this.setState({ alert });
  };
  addSupport = (v) => {
    var form = this.state.form;

    /* var findAll = form.support.find(e=>(e.id==7&&e.checked));

     if(findAll){
       this.toggleAlert(true, 'Desmarque a opção semana, para adicionar um dia espercífico', 'error');
     }else{
       this.setState({addSupportDialog:v})
     } */
    this.setState({ addSupportDialog: v });
  };
  handleSupportPattern = (v, id, name) => {
    var form = this.state.form;

    if (name == 'value') {
      v = v.target.value;
    } else if (name == 'checked') {
      v = v.target.checked;
    } else if (name == 'from' || name == 'to') {
      v = new Date(v);
    }
    form.support = form.support.map((e) =>
      e.id == id
        ? Object.assign({}, e, {
            [name]: v,
          })
        : e
    );
    if (id == 7) {
      form.support = form.support.map((e) =>
        e.id != 7 && e.id != 0 && e.id != 6
          ? Object.assign({}, e, {
              disabled: v,
              [name]: false,
            })
          : e
      );
    }

    this.setState({ form });
  };
  onSave = (id) => {
    var fields = this.state.form.support.find((e) => e.id == id);
  };

  onChangePage() {
    this.setState({ page: this.state.page + 1 });
  }

  render() {
    let {
      removeFile,
      handlePage,
      onFormSubmit,
      values,
      onDrop,
      valuesset,
      files,
      APIEndpoints,
      action,
      store,
      redirect,
    } = this.props;
    let { companies, users, contracts } = store;

    values = this.state.values;

    let type = values && values.type ? values.type : this.state.type;

    let { company, readjustmentContract } = this.state;
    let companiesOptions = companies.map((obj) => ({
      value: obj.id_company,
      label: obj.name,
    }));
    /*let daysOptions = moment()._locale._weekdays.slice(1,6).map(day => ({
      value: day,
      text: day
    }));*/

    var allItemSupport = this.state.form.support.map((e) => e.id);

    let daysOptions = moment()
      ._locale._weekdays.map((e, i) => {
        if (allItemSupport.indexOf(i) == -1) {
          return {
            value: i,
            text: e,
          };
        }
      })
      .filter(Boolean);
    daysOptions.push({ value: 7, text: language.WEEK });
    let responsibleOptions = users.map((u) => ({
      value: u.id_user,
      label: u.name + ' - ' + u.email,
    }));
    let contractsOptions = contracts.map((u) => ({
      value: u.value,
      label: u.name,
    }));

    let responsibleOptionsContato = [];

    //responsibleOptionsContato = users.filter(g => g.id_company == this.state.companyprovider).map(u => ({ value: u.id_user, label: u.name }))

    let fields = [];
    let BILLING_TYPE = {
      hour: language.HOUR,
      month: language.MONTH,
      year: language.YEAR,
    };
    let fieldsContracts = [];
    fieldsContracts = fieldsContracts.concat(
      {
        type: 'element',
        content: (
          <span className="title-aba mb-3">{language.GENERAL_DATE}</span>
        ),
      },

      {
        col: 3,
        name: 'type',
        label: language.BILLING_TYPE,
        type: 'select',
        options: [
          { value: 'month', label: language.MONTH },
          { value: 'hour', label: language.HOUR },
          { value: 'year', label: language.YEAR },
        ],
        required: true,
        disabled: values && values.id_contract > 0 ? true : false,
      },
      type == 'month'
        ? {
            col: 3,
            name: 'month',
            label: language.MONTHS,
            type: 'number',
          }
        : type == 'year'
        ? {
            col: 3,
            name: 'year',
            label: language.YEAR,
            type: 'select',
            options: [
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
            ],
          }
        : {
            col: 3,
            name: 'hours',
            label: language.HOUR,
            type: 'text',
            inPut: (value) => value.toString().padStart(4, 0),
            outPut: (value) => parseInt(value),
            InputProps: {
              id: {
                format: '####h',
              },
              inputComponent: MaskNumber,
            },
            required: true,
          },
      {
        col: 3,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'number',
        label: language.NUMBER,
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'start_date',
        label: language.INITIAL_DATE,
        type: 'date',
        required: true,
        dependsOn : type !== 'hour' ? 'end_date' : null,
        value : this.state.end_date,
      });



    fieldsContracts = fieldsContracts.concat({
      col: 3,
      name: 'end_date',
      label: language.FINAL_DATE,
      type: 'date',
      disabled: type !== 'hour',
    })

    if (values && values.fk_id_out_company && company == '') {
      values.fk_id_company = values.fk_id_out_company;
    } else {
      try {
        values.fk_id_company = company;
      } catch (e) {}
    }

    fieldsContracts = fieldsContracts.concat(
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit: values.fk_id_company > 0 ? values.company : language.COMPANY,
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: values.fk_id_company > 0 ? values.fk_id_company : 0,
        routeAll: `${APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${APIEndpoints.COMPANY}/count`,
        detail: true,
        visible: true,
        required: true,
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_sponsor',
        label: language.REQUESTER,
        textlabel: language.REQUESTER,
        method: 'POST',
        // filter: { fk_id_company: form.fk_id_company || 0 },
        route: `${APIEndpoints.USER}/filter`,
        routeGetCount: `${APIEndpoints.USER}/active/count/`,
        fieldquery: 'search',
        textinit:
          values.fk_id_sponsor > 0 ? values.sponsor_name : language.RESPONSIBLE,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: values.fk_id_sponsor > 0 ? values.fk_id_sponsor : 0,
        routeAll: `${APIEndpoints.USER}/filter/all?`,
        //filterAll : {fk_id_company : form.fk_id_company || 0},
        methodGetAll: 'POST',
        detail: true,
        visible: true,
        required: true,
        showEmail: true,

        /*  col: 4,
            type: 'select',
            name: 'fk_id_user',
            label: 'Solicitante',
            options: solicitantOptions,
            detail: true,
            visible: values.ticketArray.length > 1 ? false : true,
            required: values.ticketArray.length > 1 ? false : true,
*/
      },

      /* {
         col: 3,
         name: 'fk_id_sponsor',
         label: language.SPONSOR,
         type: 'select',
         options: responsibleOptions,
         required: true
       },*/
      type == 'year'
        ? {
            col: 3,
            name: 'dt_fat',
            label: language.BILLING_DATE_MONTH,
            type: 'select',
            required: true,
            options: [
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
              { value: 6, label: '6' },
              { value: 7, label: '7' },
              { value: 8, label: '8' },
              { value: 9, label: '9' },
              { value: 10, label: '10' },
              { value: 11, label: '11' },
              { value: 12, label: '12' },
            ],
          }
        : {
            col: 3,
            name: 'dt_fat',
            label: language.BILLING_DAY,
            type: 'number',
            inputProps: { min: 0, max: 31 },
          },
      {
        col: 3,
        name: 'value',
        label:
          language.CONTRACT_VALUE +
          ' ' +
          (BILLING_TYPE[this.state.type || (values && values.type)] || ''),
        type: 'text',
        outPut: (value) => parseFloat(value),
        InputProps: {
          inputComponent: MaskMoney,
        },
        required: true,
      },
      {
        col: 3,
        name: 'criticality',
        label: language.CRITICALITY,
        type: 'select',
        required: false,
        options: [
          {
            value: 'high',
            label: language.TICKET_PRIORITY.HIGH,
          },
          { value: 'medium', label: language.TICKET_PRIORITY.MEDIUM },
          { value: 'low', label: language.TICKET_PRIORITY.SMALL },
        ],
      },
      {
        col: 3,
        type: 'select',
        name: 'type_contract',
        label: language.TYPE,
        className: 'tech-group-ticket',
        options: [
          { label: 'capex', value: 1 },
          { label: 'opex', value: 2 },
        ],
        required: true,
      },
      {
        type: 'check',
        name: 'global',
        label: language.GLOBAL,
        thConfig: true,
      },
      // { col: 3, name: 'file', label: language.UPLOAD_FILE, type: 'uploadSingle', accept: '.pdf' },
      {
        type: 'element',
        content: (
          <span className="title-aba mb-3 mt-3">
            {language.PROVIDER.replace('es', '')}
          </span>
        ),
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'company_provider',
        label: language.COMPANY,
        method: 'POST',
        route: `${APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit:
          values.company_provider > 0 ? values.name_provider : language.COMPANY,
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: values.company_provider > 0 ? values.company_provider : 0,
        routeAll: `${APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${APIEndpoints.COMPANY}/count`,
        detail: true,
        visible: true,
        required: true,
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'contact_provider',
        label: language.CONTACT,
        textlabel: language.CONTACT,
        method: 'POST',
        filter: { fk_id_company: this.state.companyProvider || 0 },
        route: `${APIEndpoints.USER}/filter`,
        routeGetCount: `${APIEndpoints.USER}/active/count/`,
        fieldquery: 'search',
        textinit:
          values.contact_provider > 0
            ? values.contact_provider_name
            : language.CONTACT,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: values.contact_provider > 0 ? values.contact_provider : 0,
        routeAll: `${APIEndpoints.USER}/filter/all?`,
        filterAll: { fk_id_company: this.state.companyProvider || 0 },
        methodGetAll: 'POST',
        detail: true,
        visible: true,
        required: true,
        showEmail: true,

        /*  col: 4,
            type: 'select',
            name: 'fk_id_user',
            label: 'Solicitante',
            options: solicitantOptions,
            detail: true,
            visible: values.ticketArray.length > 1 ? false : true,
            required: values.ticketArray.length > 1 ? false : true,
*/
      },
      {
        col: 3,
        type: 'select',
        name: 'fk_id_parent_contract',
        label: language._CONTRACT,
        className: 'tech-group-ticket',
        options:
          values.company_provider > 0 ? this.state.parent_contract_options : [],
        disabled: values.company_provider > 0 ? false : true,
      },
      {
        col: 3,
        type: 'select',
        name: 'fk_id_coin',
        label: language.COIN,
        className: 'tech-group-ticket',
        options: this.state.coins_options || [],
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_service_delivery_manager',
        textlabel: language.DELIVERY_MANAGER,
        method: 'POST',
        route: `${Constants.APIEndpoints.USER}/filter`,
        routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
        fieldquery: 'search',
        textinit: values?.service_delivery_manager,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: '',
        routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
        loadOnFocus: true,
        detail: true,
        methodGetAll: 'POST',
      },
      {
        col: 12,
        name: 'information',
        label: language.COMPL_INFO,
        multiLine: true,
        type: 'textEditor',
      },
      {
        col: 12,
        name: 'observation',
        label: language.OBSERVATIONS,
        multiLine: true,
        type: 'textEditor',
      }
    );

    let fieldsHistoric = [
      {
        key: 'id',
        label: language.ID,
        thConfig: true,
        style: { width: 50 },
      },
      {
        key: 'user',
        label: language.MODIFICATION_USER,
        thConfig: true,
        style: { width: 50 },
      },
      {
        key: 'date',
        label: language.MODIFICATION_DATE,
        thConfig: true,
        style: { width: 50 },
      },
      {
        key: 'modification',
        label: language.MODIFICATION,
        thConfig: true,
        style: { width: 450 },
      },
    ];

    let dataHistoric = (this.state.historic || []).map((obj, index) => {
      const getUser = (id) => {
        let user = this.props.store.users.filter(
          (user) => user.id_user == id
        )[0];
        if (user) {
          return user.name;
        } else {
          return '';
        }
      };

      return {
        id: obj.id_contract_historic,
        user: getUser(obj.fk_id_user),
        date: moment(obj.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
        modification: obj.modification,
      };
    });

    let fieldsHours = [
      {
        col: 12,
        type: 'element',
        content: (
          <Paper
            style={{
              width: '100%',
              margin: '10px 0',
              marginTop: 24,
              height: 'calc(100% - 34px)',
            }}
          >
            <AppBar light style={{ width: '90%', top: -17 }} className="font">
              <div className="d-flex align-items-center justify-content-between">
                <div />
                <span>{language.SUPPORT_CONTACT}</span>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 20 }}>
                      <strong>{language.ADD}</strong>
                    </div>
                  }
                  position="left"
                  theme="dark"
                >
                  <div
                    className="bg-icon"
                    onClick={() => this.addSupport(true)}
                  >
                    <div
                      className="openIcon"
                      style={{
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </AppBar>
            <List className="list listMin" style={{ maxHeight: 400 }}>
              {this.state.form.support.map((e, i) => (
                <ListTeckSupport
                  language={language}
                  e={e}
                  key={e.id}
                  handleCheckbox={(v) =>
                    this.handleSupportPattern(v, e.id, 'checked')
                  }
                  handleFrom={(v) => this.handleSupportPattern(v, e.id, 'from')}
                  handleTo={(v) => this.handleSupportPattern(v, e.id, 'to')}
                  handleValue={(v) =>
                    this.handleSupportPattern(v, e.id, 'value')
                  }
                  onSave={() => this.onSave(e.id)}
                />
              ))}
            </List>
          </Paper>
        ),
      },
    ];

    let dropzoneRef;
    let buttonContracts = {
      md: 2,
      offset: { md: 10 },
      label: language.ADVANCE,
      primary: true,
      style: { margin: 10, float: 'right' },
    };

    let buttonHour = {
      md: 2,
      offset: { md: 10 },
      label: language.ADVANCE,
      primary: true,
      style: { margin: 10, float: 'right' },
    };

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10, float: 'right' },
    };

    let fieldsSupport = [
      {
        span: 3,
        name: 'id',
        label: language.DAY,
        type: 'select',
        options: daysOptions,
        required: true,
      },
      {
        span: 3,
        name: 'start_time',
        label: language.FROM,
        type: 'time',
        required: true,
      },
      {
        span: 3,
        name: 'end_time',
        label: language.AT,
        type: 'time',
        required: true,
      },
      {
        span: 3,
        name: 'value',
        label: language.VALUE,
        type: 'text',
        required: true,
        InputProps: {
          inputComponent: MaskMoney,
        },
      },
    ];

    let fieldsReadjustment = [
      {
        col: 3,
        name: 'readjustment',
        label: language.READJUSTMENTS,
        type: 'select',
        required: readjustmentContract,
        options: [
          { value: 1, label: 'IGP-DI (FGV)' },
          { value: 2, label: 'IGP-M (FGV)' },
          { value: 3, label: 'IPC (FIPE)' },
          { value: 4, label: 'IPCA (IBGE)' },
          { value: 5, label: 'INPC (IBGE)' },
          { value: 6, label: 'ICV (Dieese)' },
          { value: 7, label: language.NEGOTIABLE },
        ],
      },
      {
        col: 2,
        name: 'reference_year',
        label: language.REFERENCE_YEAR,
        type: 'select',
        required: readjustmentContract,
        visible: values,
        options: [
          { value: moment().year() - 1, label: moment().year() - 1 },
          { value: moment().year(), label: moment().year() },
          { value: moment().year() + 1, label: moment().year() + 1 },
          { value: moment().year() + 2, label: moment().year() + 2 },
          { value: moment().year() + 3, label: moment().year() + 3 },
        ],
      },
      {
        col: 2,
        name: 'reference_month',
        label: language.REFERENCE_MONTH,
        type: 'select',
        required: readjustmentContract,
        options: [
          { value: 0, label: `01 / ${language.DATE_LANG.monthLongList[0]}` },
          { value: 1, label: `02 / ${language.DATE_LANG.monthLongList[1]}` },
          { value: 2, label: `03 / ${language.DATE_LANG.monthLongList[2]}` },
          { value: 3, label: `04 / ${language.DATE_LANG.monthLongList[3]}` },
          { value: 4, label: `05 / ${language.DATE_LANG.monthLongList[4]}` },
          { value: 5, label: `06 / ${language.DATE_LANG.monthLongList[5]}` },
          { value: 6, label: `07 / ${language.DATE_LANG.monthLongList[6]}` },
          { value: 7, label: `08 / ${language.DATE_LANG.monthLongList[7]}` },
          { value: 8, label: `09 / ${language.DATE_LANG.monthLongList[8]}` },
          { value: 9, label: `10 / ${language.DATE_LANG.monthLongList[9]}` },
          { value: 10, label: `11 / ${language.DATE_LANG.monthLongList[10]}` },
          { value: 11, label: `12 / ${language.DATE_LANG.monthLongList[11]}` },
        ],
      },
      {
        col: 1,
        name: 'readjustment_percent',
        label: language.PERCENTAGE_INDEX,
        type: 'text',
        required: readjustmentContract,
      },
    ];

    let { sub } = this.props;
    let PL = !sub ? PageLayout : 'div';

    /* if(this.state.information != ''){

      values.information = this.state.information
     }*/

    return (
      <PL
        icon={<div className="contractIcon titleIconSize" />}
        back={() => redirect('/dash/contracts')}
        title={!values ? language.ADD_CONTRACT : language.EDIT_CONTRACT}
        subTitle={language.DASHBOARD_PAGE.GCF + '/' + language.CONTRACT + '/'}
        rightElements={[]}
      >
        <div id="SubscribeContracts">
          <Header>
            <div className="btnFilterHeader">
              <span
                className="spanFilter"
                onClick={() => this.setState({ page: 0 })}
              >
                {language.CONTRACTS.toLocaleUpperCase()}
              </span>
              <span
                className="spanFilter"
                onClick={() => this.setState({ page: 1 })}
              >
                {language.HOUR.toLocaleUpperCase()}
              </span>
              <span
                className="spanFilter"
                onClick={() => this.setState({ page: 2 })}
              >
                {language.READJUSTMENT.toLocaleUpperCase()}
              </span>
            </div>
          </Header>

          <Card
            style={{ display: this.state.page > 0 ? 'none' : 'block' }}
            className="cardDefault"
            id="ContractForm"
          >
            <div className="CommonForm-content content">
              <CommonForm2
                values={values}
                language={language}
                fields={fieldsContracts}
                onSubmit={this.onChangePage}
                onChangeField={this.onChangeField}
                button={buttonContracts}
                loading={[
                  'post-/dash/contracts/new',
                  'put-/dash/contracts/edit',
                  'post-/dash/configuration/gcf',
                  'put-/dash/configuration/gcf',
                ]}
              />
              <MuiThemeProvider theme={theme}>
                <div className="dev">
                  <SimplesAlert
                    float
                    open={this.state.alert.open}
                    message={this.state.alert.message}
                    variant={this.state.alert.variant}
                    onClose={() => this.toggleAlert(false)}
                  />
                  <Dialog
                    title={language.ADD}
                    onClose={() => this.addSupport(false)}
                    miniVersion={true}
                    maxWidth="md"
                    maxHeight="500px"
                    open={this.state.addSupportDialog}
                  >
                    <div style={{ height: '350px' }}>
                      <CommonForm
                        values={[]}
                        language={language}
                        fields={fieldsSupport}
                        onFormSubmit={this.addDay}
                        button={button}
                      />
                    </div>
                  </Dialog>

                  <Dialog
                    title={''}
                    onClose={() =>
                      this.setState({ addSolicitantDialog: false })
                    }
                    maxWidth="md"
                    maxHeight="500px"
                    open={this.state.addSolicitantDialog}
                  >
                    <div style={{ height: '350px' }}>
                      <CommonForm
                        values={{
                          contractsolicitant:
                            values && values.contractsolicitant,
                        }}
                        language={language}
                        fields={[
                          {
                            col: 6,
                            name: 'contractsolicitant',
                            label: language.REQUESTER,
                            type: 'listCheckbox',
                            options: responsibleOptions,
                            required: true,
                          },
                        ]}
                        onFormSubmit={() =>
                          this.setState({ addSolicitantDialog: false })
                        }
                        button={button}
                      />
                    </div>
                  </Dialog>
                </div>
              </MuiThemeProvider>
            </div>
          </Card>

          {this.state.page == 1 && (
            <Card className="cardDefault" id="ContractForm">
              <div
                className="CommonForm-content content"
                style={{ display: 'flex' }}
              >
                <div id="ContractCommonForm" style={{ width: '70%' }}>
                  <CommonForm2
                    sensitive
                    values={values}
                    language={language}
                    fields={fieldsHours}
                    //       onSubmit={this.onChangePage}
                    onChangeField={this.onChangeField}
                    button={buttonHour}
                    loading={[
                      'post-/dash/contracts/new',
                      'put-/dash/contracts/edit',
                      'post-/dash/configuration/gcf',
                      'put-/dash/configuration/gcf',
                    ]}
                  />
                </div>
                <div id="AttacHistory" style={{ width: '80%' }}>
                  <div
                    style={{
                      height: '90%',
                    }}
                  >
                    <AttachFile2
                      label={language.ATTACH}
                      id={'idContractAttach'}
                      subLabel={language.DRAG_THE_FILE}
                      onRemove={(f) => this.props.removeFile(f)}
                      files={this.props.files}
                      onDrop={onDrop}
                    />
                  </div>
                  <Button
                    variant="normal"
                    icon="fas fa-save"
                    color="success"
                    onClick={this.onChangePage}
                    label={language.ADVANCE}
                    style={{
                      position: 'absolute',
                      right: 40,
                    }}
                  />
                </div>
                <MuiThemeProvider theme={theme}>
                  <div className="dev">
                    <SimplesAlert
                      float
                      open={this.state.alert.open}
                      message={this.state.alert.message}
                      variant={this.state.alert.variant}
                      onClose={() => this.toggleAlert(false)}
                    />

                    <Dialog
                      title={language.ADD}
                      onClose={() => this.addSupport(false)}
                      miniVersion={true}
                      maxWidth="md"
                      maxHeight="500px"
                      open={this.state.addSupportDialog}
                    >
                      <div style={{ height: '350px' }}>
                        <CommonForm
                          values={[]}
                          language={language}
                          fields={fieldsSupport}
                          onFormSubmit={this.addDay}
                          button={button}
                        />
                      </div>
                    </Dialog>

                    <Dialog
                      title={''}
                      onClose={() =>
                        this.setState({ addSolicitantDialog: false })
                      }
                      maxWidth="md"
                      maxHeight="500px"
                      open={this.state.addSolicitantDialog}
                    >
                      <div style={{ height: '350px' }}>
                        <CommonForm
                          values={{
                            contractsolicitant:
                              values && values.contractsolicitant,
                          }}
                          language={language}
                          fields={[
                            {
                              col: 6,
                              name: 'contractsolicitant',
                              label: language.REQUESTER,
                              type: 'listCheckbox',
                              options: responsibleOptions,
                              required: true,
                            },
                          ]}
                          onFormSubmit={() =>
                            this.setState({ addSolicitantDialog: false })
                          }
                          button={button}
                        />
                      </div>
                    </Dialog>
                  </div>
                </MuiThemeProvider>
              </div>
            </Card>
          )}

          {this.state.page == 2 && (
            <Card
              className="cardDefault"
              id="ContractForm"
              style={{ minHeight: '500px' }}
            >
              <div className="CommonForm-content content">
                <CommonForm2
                  sensitive
                  values={values}
                  language={language}
                  fields={fieldsReadjustment}
                  onSubmit={this.onFormSubmit}
                  onChangeField={this.onChangeField}
                  button={button}
                  loading={[
                    'post-/dash/contracts/new',
                    'put-/dash/contracts/edit',
                    'post-/dash/configuration/gcf',
                    'put-/dash/configuration/gcf',
                  ]}
                />

                {this.state.historic.length > 0 && (
                  <CommonTable
                    title={language.HISTORIC_CHANGES}
                    language={language}
                    columns={fieldsHistoric}
                    index="MailList"
                    data={dataHistoric}
                    searchColumn
                    orderColumn
                    width="100%"
                    orderColumn
                    paginationTop={false}
                    searchColumn={true}
                  />
                )}

                <MuiThemeProvider theme={theme}>
                  <div className="dev">
                    <SimplesAlert
                      float
                      open={this.state.alert.open}
                      message={this.state.alert.message}
                      variant={this.state.alert.variant}
                      onClose={() => this.toggleAlert(false)}
                    />
                    <Dialog
                      title={language.ADD}
                      onClose={() => this.addSupport(false)}
                      maxWidth="md"
                      maxHeight="500px"
                      miniVersion={true}
                      open={this.state.addSupportDialog}
                    >
                      <div style={{ height: '350px' }}>
                        <CommonForm
                          values={[]}
                          language={language}
                          fields={fields}
                          onFormSubmit={this.addDay}
                          button={button}
                        />
                      </div>
                    </Dialog>

                    <Dialog
                      title={''}
                      onClose={() =>
                        this.setState({ addSolicitantDialog: false })
                      }
                      maxWidth="md"
                      maxHeight="500px"
                      open={this.state.addSolicitantDialog}
                    >
                      <div style={{ height: '350px', margin: '300px' }}>
                        <CommonForm
                          values={{
                            contractsolicitant:
                              values && values.contractsolicitant,
                          }}
                          language={language}
                          fields={[
                            {
                              col: 6,
                              name: 'contractsolicitant',
                              label: language.INDEX,
                              type: 'listCheckbox',
                              options: responsibleOptions,
                              required: true,
                            },
                          ]}
                          onFormSubmit={() =>
                            this.setState({ addSolicitantDialog: false })
                          }
                          button={button}
                        />
                      </div>
                    </Dialog>
                  </div>
                </MuiThemeProvider>
              </div>
            </Card>
          )}
        </div>
      </PL>
    );
  }
}
