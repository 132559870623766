import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../../graphs/SimplesPieGraph.js';
import {Chip, Avatar} from '@material-ui/core';

//const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300}, {name: 'Group C', value: 300}, {name: 'Group D', value: 200}];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
 
const spanStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginLeft: '3px'
};

export default class NumberOfCalls extends Component{
  render() {
    let {language} = this.props;
    var objReport = this.props.reportCall;
    var contractValue = this.props.resultContract;

    // Pega todos os servirços que foi prestadado para esse contrato;
    var tableDetailList = [];
    Object.keys(objReport).forEach(function(key) {
      if (objReport[key].fk_id_contract == contractValue) {
        tableDetailList.push(objReport[key]);
      }
    });

    let data = [];
    var totalVar = 0;
    var resolvedVar = 0;
    var closedVar = 0;
    var openVar = 0;
    var inProgressVar = 0;
    var total =0;
    Object.keys(tableDetailList).forEach(function(key) {
      if (tableDetailList[key].status == 'Resolved') {
        resolvedVar = resolvedVar + 1;
        total =total+1;
      }
      if (tableDetailList[key].status == 'Closed') {
        closedVar = closedVar + 1;
        total =total+1;
      }
      if (tableDetailList[key].status == 'Open') {
        openVar = openVar + 1;
        total =total+1;
      }
      if (tableDetailList[key].status == 'In Progress') {
        inProgressVar = inProgressVar + 1;
        total =total+1;
      }
    });

    data = [
      { name: language.TICKET_STATUS.RESOLVED, y: ((resolvedVar/total) *100), color: 'var(--success)' },
      { name: language.TICKET_STATUS.CLOSED, y: ((closedVar/total)*100), color: 'var(--danger'},
      { name: language.TICKET_STATUS.OPEN, y: ((openVar/total)*100), color:'var(--primary)' },
      { name: language.TICKET_STATUS.IN_PROGRESS, y: ((inProgressVar/total)*100), color:'var(--warning)' }
    ];
    
    
    return (
      <div>
        <ReactHighcharts config={ContainerChart.container0({
          title: this.props.title,
          subTitle:this.props.subTitle,
          size:200,
          data:data
        })}/>

        <div style={{ textAlign: 'center', marginTop: '2.5em' }}>
          <div>
            <strong>{language.NUMBER_CALLS}:</strong>
            <span style={spanStyle}>{tableDetailList.length}</span>
          </div>
          <br />
          <div className='d-flex align-items-center justify-content-around'>
            <Chip
              avatar={<Avatar style={{color:'white', background: '#438a83'}}>{resolvedVar}</Avatar>}
              label={language.TICKET_STATUS.RESOLVED}
              className='success'
            />
           
          
            <Chip
              avatar={<Avatar style={{color:'white', background: 'rgba(181, 36, 25, 0.9)'}}>{closedVar}</Avatar>}
              label={language.TICKET_STATUS.CLOSED}
              className='danger'
            />
          
            <Chip
              avatar={<Avatar style={{color:'white', background: 'rgb(0, 86, 192)'}}>{openVar}</Avatar>}
              label={language.TICKET_STATUS.OPEN}
              className='primary'
            />
            
          
            <Chip
              avatar={<Avatar style={{color:'white', background: '#987419'}}>{inProgressVar}</Avatar>}
              label={language.TICKET_STATUS.IN_PROGRESS}
              className='warning'
            />
          </div>
        </div>
        <br />
      </div>
    );
  }
};