import React, {useState} from 'react';
import ReactHighcharts from 'react-highcharts';
// -----------------------common------------------------
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import {addTimes} from '@common/Func';
// -----------------------material-ui------------------------
import {Card} from '@material-ui/core';
// -----------------------components------------------------
import {alocation} from './Graph';
import moment from 'moment';

export default function({language, pmos, options}){

	let [filterTech, setFilterTech] = useState(0);
	let [filterProject, setFilterProject] = useState(0);

	let data = pmos
	.filter(e => filterProject ? e.id_project==filterProject : true)
	.reduce((obj, e)=>{
		(e.hours_consumed||[]).filter(e => filterTech ? e.fk_id_user==filterTech : true).forEach(h=>{
			let month = moment(h.dt_cad).months();
			obj[month] = addTimes(obj[month], h.hours_consumed);
		});
		return obj;

	}, 
		['0','0','0','0','0','0','0','0','0','0','0','0']

	).map(e => parseFloat(e.replace(/:/g, '.')))
	
	
	return(
		<Card className='h-100'>
			<AppBar title={language.TEAM_ALLOCATION}>
				<div style={{zoom:.8, width:300}}>
					<CommonForm
						fields={[
							{
								col:6,
								type:'select',
								name:'tech',
								label: language.TECH,
								options:options.tech()
							},
							{
								col:6,
								type:'select',
								name:'project',
								label: language.PROJECT,
								options:options.project()
							}
						]}
						onChangeField={(f,v)=>{
							if(f.name=='tech'){
								setFilterTech(v)
							}else if(f.name=='project'){
								setFilterProject(v)
							}
						}}
					/>
				</div>
			</AppBar>
			<div className='content-medium pt-0'>
				<ReactHighcharts config={alocation({language, data})}/>
			</div>
		</Card>
	)
}