/* eslint-disable react/prop-types */
import React, { Component, useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '../../../common/CommonForm2';
import Button from '../../../common/Button';
import Constants from '../../../data/Constants';
import { Card } from '@material-ui/core';
import Store from '../../../data/Store';
import { getGroupDefaultFALE } from '../utils/getGroupDefaultFALE';
import { useReducer } from 'react';
import Actions from '@data/Actions';

const action = new Actions();

const TaskForm = ({
  language,
  onFormSubmit,
  handlerPageTask,
  values,
  taskSelected,
  templateOptions,
  isStep,
  listWorkday,
  Attendance
}) => {
  const [name, setName] = useState(values.name)
  const [deadline, setDeadline] = useState(values.deadline)
  const [responsible, setResponsible] = useState(values?.responsible)
  const [cdResponsible, setCdResponsible] = useState(values?.fk_id_user)
  const [departmentName, setDepartmentName] = useState(values?.name_department)
  const [department, setDepartment] = useState(values.fk_id_department)
  const [user, setUser] = useState(values.fk_id_user)
  const [template, setTemplate] = useState(values.id_template_forms)
  const [workday, setWorkday] = useState(values.id_workday)
  const [description, setDescription] = useState('')
  const [currentTask, setCurrentTask] = useState()
  const [cleanValue, setCleanValue] = useState(false)
  const [formRefs, setFormRefs] = useReducer((state, val) => {
    return [...state, val];
  }, []);

  const fieldsOptionsTemplate = templateOptions.map((template) => ({
    value: template.id_template_forms,
    label: template.name,
  }));

  const fieldsOptionWorkday = listWorkday.map((workday) => ({
    value: workday.id_workday,
    label: workday.wk_name,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const departmentResponse = await action.execute('get', `${Constants.APIEndpoints.USER}/department/from/${user}`, '');

          const departmentData = await departmentResponse.data.json();
          if (departmentData && departmentData.length > 0) {
            setDepartmentName(departmentData[0].name_department);
            setDepartment(departmentData[0].fk_id_department);
          }
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
    setCleanValue(false)
  }, [user]);

  useEffect(() => {
    const fetchDataFromTask = async () => {

      try {
        let task = {...values};

        if (Attendance?.seq_step === 0 && Attendance) {
          task.description = Attendance.description
          setDescription(Attendance.description);
        } else {
          setDescription('');
        }

        if (Object.keys(taskSelected).length === 0) {
          const groupFale = await getGroupDefaultFALE();
          const groupResponse = await action.execute( 'get', `${Constants.APIEndpoints.GROUP}/${groupFale}`, '');

          const groupData = await groupResponse.data.json();
          if (groupData) {
            setWorkday(groupData.fk_id_workday);
            task.id_workday = groupData.fk_id_workday
          }
          task.deadline = '048:00'
          setDeadline('048:00')
        }
        setCurrentTask(task);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchDataFromTask();
  }, []);

  const cleanValues = () => {
    setDepartment(0)
    setCdResponsible(0)
    setDepartmentName("")
    setResponsible("");
    setCleanValue(true)

    formRefs[0].getFields().map((e) => {
      if (e.name == 'fk_id_user' || e.name == 'fk_id_department' ) {
        formRefs[0].handleValue(0, e.name, 0);
      }
    });
  };


  const fields = [
    {
      name: 'name',
      label: 'Etapa',
      type: 'text',
      required: true,
      col: 12,
    },
    {
      col: 2,
      name: 'deadline',
      label: 'Prazo Máximo',
      type: 'text',
      format: '###:##',
      required: true
    },
    {
      col: 5,
      name: 'id_workday',
      label: 'Jornada',
      type: 'select',
      options: fieldsOptionWorkday,
      required: true,
    },
    {
      col: 5,
      name: 'id_template_forms',
      label: 'Template',
      type: 'select',
      options: fieldsOptionsTemplate,
      required: false,
    },
    {
      col: 6,
      type: 'autocomplete',
      name: 'fk_id_department',
      label: 'Departamento',
      className: 'tech-group-ticket',
      textlabel: 'Departamento',
      method: 'POST',
      route: `${Constants.APIEndpoints.DEPARTMENT}/filter`,
      routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/count?`,
      fieldquery: 'search',
      textinit:  departmentName ? departmentName : 'Departamento',
      fieldvaluedb: 'id_department',
      fieldlabeldb: 'name',
      idinit: department ? department : 0,
      routeAll: `${Constants.APIEndpoints.DEPARTMENT}/list?`,
      methodGetAll: 'POST',
      detail: true,
      required: true,
      cleanValue: cleanValue
    },
    {
      col: 5.4,
      type: 'autocomplete',
      name: 'fk_id_user',
      label: 'Responsável',
      className: 'tech-group-ticket',
      textlabel: 'Responsável',
      method: 'POST',
      route: `${Constants.APIEndpoints.USER}/filter`,
      routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
      fieldquery: 'search',
      textinit:  responsible ? responsible: 'Responsável',
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      idinit: cdResponsible > 0 ? cdResponsible : 0,
      routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
      methodGetAll: 'POST',
      filterAll : {fk_id_department : department || 0},
      detail: true,
      required: true,
      cleanValue: cleanValue
    },
    {
      col: 0.4,
      type: 'element',
      content: (
        <Button
          style={{ marginTop: 20}}
          variant="circle"
          icon="fas fa-eraser"
          color="success"
          label="Limpar"
          onClick={() => {
            cleanValues()
          }}
        />
      )
    },
    {
      col: 12,
      name: 'description',
      label: 'Parecer',
      required: true,
      type: 'simplesTextEditor',
      value: Attendance?.description,
      style: { height: '450px' },
      visible: (isStep && !Object.keys(taskSelected).length > 0) || Attendance?.seq_step == 0
    }
  ];

  return (
    <Dialog
      miniVersion
      maxWidth="lg"
      title={'Adicionar Etapa'}
      open={true}
      PaperProps={{
        style: {
          height: '70%',
        },
      }}
      onClose={() => handlerPageTask('open_task_list')}
    >
      <Card className="content" style={{ height: '100%'}}>
        <CommonForm
          onRef={(ref) => setFormRefs(ref)}
          onChangeField={(f, v) => {
            if (f.name == 'name') {
              setName(v)
            } else if (f.name == 'deadline') {
              setDeadline(v)
              setCurrentTask({deadline: v})
            } else if (f.name == 'fk_id_department') {
                setDepartment(v)
            } else if (f.name == 'fk_id_user') {
                  setUser(v)
            } else if (f.name == 'id_template_forms') {
              setTemplate(v)
            }  else if (f.name == 'id_workday') {
              setWorkday(v)
              setCurrentTask({workday: v})
            } else if (f.name == 'description') {
              setDescription(v)
            }
          }}
          values={currentTask}
          fields={fields}
        />
      </Card>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15}}>
        <Button
          variant="normal"
          color="success"
          icon={'fas fa-save'}
          style={{ width: 100 }}
          label={'Salvar'}
          onClick={() => {
            if (description == '' && isStep && !Object.keys(taskSelected).length > 0) {
              Store.toggleAlert(true, 'Informe um Parecer inicial para essa etapa', 'error');
            } else if (!name) {
              Store.toggleAlert(true, 'Informe o nome da etapa', 'error');
            } else if (!deadline) {
              Store.toggleAlert(true, 'Informe o prazo da etapa', 'error');
            } else if (!workday) {
              Store.toggleAlert(true, 'Informe a jornada da etapa', 'error');
            } else if (!user) {
              Store.toggleAlert(true, 'Informe o responsável da etapa', 'error');
            } else {
              onFormSubmit({name, deadline,
                fk_id_department: department,
                fk_id_user: user,
                id_template_forms: template,
                id_workday: workday,
                id_sac_task: values.id_sac_task,
                description: description
              })
            }
          }}
        />
      </div>
    </Dialog>
  );
};

export default TaskForm;
