import React, { Fragment, useState, useEffect } from 'react';
import './index.css';
import Dialog from '@common/Dialog';
/*------------------------------common----------------------------------*/
import AppBar from '@common/AppBar2';
import List from '@common/List';
import Progress from '@common/Progress';
import ReactHighcharts from '@common/ReactHighcharts';

/*------------------------------material-ui----------------------------------*/
import { Grid, Card } from '@material-ui/core';
/*------------------------------pages----------------------------------*/
import CardIndicatorB3B6 from '../CardIndicatorB3B6';
import { npsVelocimeter } from './Graph';
import ButtonControlCarousel from './ButtonControlCarousel';
import NpsGraph from './NpsGraph';

const checkNumber = (value) => {
  var n = Number(value);

  if (n > 0) {
    return n;
  } else {
    return 0;
  }
};

export default function ({ nps_, openModalDetail, language }) {
  var arrGenerate = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  var [question, setQuestion] = useState('avaliation_level');
  // #####################################################-contagem do npm-################################################################
  let [nps, setNps] = useState(undefined);
  let [openmodal, SetOpenModal] = useState(false);

  useEffect(() => {
    setNps(nps_);
  }, [nps_]);

  const sel = (question) => {
    if (question === 'resolved') {
      nps.item1 = nps.resolved;
      nps.detratores = nps.detratores_re;
      nps.pasivos = nps.pasivos_re;
      nps.promotores = nps.promotores_re;
      nps.item2 = parseInt(nps.totallevel_re);

      setNps(nps);
    }

    if (question === 'experience') {
      nps.item1 = nps.experience;
      nps.detratores = nps.detratores_ex;
      nps.pasivos = nps.pasivos_ex;
      nps.promotores = nps.promotores_ex;
      nps.item2 = parseInt(nps.totallevel_ex);
      setNps(nps);
    }

    if (question == 'avaliation_level') {
      nps.item1 = nps.avaliation_av;
      nps.detratores = nps.detratores_av;
      nps.pasivos = nps.pasivos_av;
      nps.promotores = nps.promotores_av;
      nps.item2 = parseInt(nps.totallevel_av);

      setNps(nps);
    }

    setQuestion(question);
  };
  // ------------------------------------------------------função para os 0 à 10-------------------------------------------------------

  // ------------------------------------------------------função para os 3 indicadores (respectivamente)-------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <Card id="nps" className="mb-2 h-100">
      {openmodal == false ? null : (
        <Dialog
          title={''}
          open={true}
          maxWidth="md"
          onClose={() => SetOpenModal(false)}
        >
          <NpsGraph nps={nps}></NpsGraph>
        </Dialog>
      )}

      <AppBar
        title="NPS"
        beforeTitle={
          <i
            onClick={() => SetOpenModal(true)}
            style={{
              fontSize: '14px',
              color: 'var(--warning)',
              cursor: 'pointer',
              marginRight: '10px',
            }}
            class="fas fa-eye"
          ></i>
        }
      >
        <ButtonControlCarousel
          options={[
            {
              label: `(1) ${language.EVALUATION_REGARDING_CALL_RESOLUTION_TIME}`,
              value: 'avaliation_level',
            },
            {
              label: `(2) ${language.EVALUATION_REGARDING_CALL_RESOLUTION_QUALITY}`,
              value: 'resolved',
            },
            {
              label: `(3) ${language.EVALUATION_REGARDING_ATTENDANCE_TEAM}`,
              value: 'experience',
            },
          ]}
          value={question}
          onChange={(v) => sel(v)}
        />
      </AppBar>

      <div className="content-medium pt-0">
        <Grid container spacing={16}>
          {/*-------------------------------------------LISTA--------------------------------------------------------------*/}
          <Grid item xs={5}>
            <List
              className="list-category-itms"
              items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                .map((e) => ({
                  className:
                    'd-flex align-items-center justify-content-between items py-1',
                  content: (
                    <Fragment style={{ cursor: 'pointer' }}>
                      <div
                        onClick={() => openModalDetail('nps', e, question)}
                        className="index-indicator"
                      >
                        {e}
                      </div>
                      <span
                        onClick={() => openModalDetail('nps', e, question)}
                        className="total mr-2"
                      >
                        {nps && nps.item1 && nps.item1[e]}
                      </span>
                      <Progress
                        onClick={() => openModalDetail('nps', e, question)}
                        hiddenText
                        current={
                          nps && nps.item1 && nps.item1[e] == 0
                            ? 0
                            : (nps && nps.item1 && nps.item1[e] / nps.item2) *
                              100
                        }
                        style={{ height: 5, width: 100 }}
                      />
                      <span
                        onClick={() => openModalDetail('nps', e, question)}
                        className="percent ml-2"
                      >
                        {nps && nps.item1 && nps.item1[e] == 0
                          ? 0
                          : (
                              (nps && nps.item1 && nps.item1[e] / nps.item2) *
                              100
                            ).toFixed(0)}
                        %
                      </span>
                    </Fragment>
                  ),
                }))
                .reverse()}
            />
          </Grid>
          <Grid item xs={7}>
            {/*------------------------------------3 CARDS INDICADORES--------------------------------------------------*/}
            <Grid container spacing={2}>
              {[language.DETRACTORS, language.PASSIVE, language.PROMOTERS]
                .map((e, i) => (
                  <Grid item xs={4} className="content-indicators">
                    <CardIndicatorB3B6
                      hasIcon
                      label={e}
                      desc={language.VOTES}
                      percent={
                        i == 0
                          ? checkNumber(
                              (
                                (nps && nps.detratores / nps.item2) * 100
                              ).toFixed(0)
                            )
                          : i == 1
                          ? checkNumber(
                              ((nps && nps.pasivos / nps.item2) * 100).toFixed(
                                0
                              )
                            )
                          : checkNumber(
                              (
                                (nps && nps.promotores / nps.item2) * 100
                              ).toFixed(0)
                            )
                      }
                      value={
                        i == 0
                          ? nps && nps.detratores
                          : i == 1
                          ? nps && nps.pasivos
                          : nps && nps.promotores
                      }
                      language={language}
                    />
                  </Grid>
                ))
                .reverse()}
            </Grid>
            {/*---------------------------------------------GRÁFICO--------------------------------------------------*/}
            <div className="mt-5">
              <ReactHighcharts
                config={npsVelocimeter(
                  (nps &&
                    checkNumber(
                      (
                        (nps.promotores / nps.item2) * 100 -
                        (nps.detratores / nps.item2) * 100
                      ).toFixed(0)
                    )) ||
                    0
                )}
              />
              <div className="legent-content d-flex justify-content-center">
                <div>
                  <span>NPS = {language.PROMOTERS}</span>
                  <span>
                    {checkNumber(
                      ((nps && nps.promotores / nps.item2) * 100).toFixed(0)
                    )}
                    %
                  </span>
                  <span>- {language.DETRACTORS}</span>
                  <span>
                    {checkNumber(
                      ((nps && nps.detratores / nps.item2) * 100).toFixed(0)
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
