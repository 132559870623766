import React, { Component } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';

export default class ExtensionForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;

    const data = {
      extension: values
    }
    let fields = [
      {
        col: 12,
        name: 'extension',
        label: language.FIELD_EXTENSION,
        type: 'text',
        required: true,
      },
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog maxWidth="sm" miniVersion = {true} monitor_scroll title={values ? language.EDIT : language.ADD} modal={false} open={true} onClose={() => handlePage('list')}>

        <CommonForm
          values={data}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
        />

      </Dialog>
    );
  }
}
