import Store from '@data/Store';

const { language } = Store;

export function TicketEvolution({ categories, data }) {
	return {
		chart: {
			type: 'area',
			// height: 275
		},
		credits: {
			enabled: false
		},
		accessibility: {
			description: ''
		},
		title: {
			text: ''
		},
		subtitle: {
			text: ''
		},
		xAxis: {
			allowDecimals: false,
			title: {
				text: language.DAYS,
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			labels: {
				categories,
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			}
		},
		yAxis: {
			title: {
				text: language.VALUES,
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			labels: {
				formatter: function () {
					return this.value;
				},
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
			tickWidth: 1
		},
		tooltip: {
			// pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			area: {
				pointStart: 1,
				fillOpacity: 0.5,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			}
		},
		legend: {
			// enabled: false
			itemStyle: {
				fontSize: 10,
				color: '#A3A3A3'
			}
		},
		series: data
	};
}
