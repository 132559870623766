import React, { useState, useEffect, useContext, Fragment } from 'react'
import GI2Context from './GI2Context';
import MonitorUtils from '../../util/MonitorUtils';
import Emitter from './EventEmitter_'
 

import { Tooltip } from 'react-tippy';
 


function PieChartIndicator({ icon, percent = 0, triggers = {}, device ,   companyId,ic,fs}) {
     
 let item= ['CPU','MEN','DISC']  
 
 return (
      
       ic == 'danger' ?
       <Tooltip
       html={
         <div style={{ color: '#fff', fontSize: 15 }}>
           <strong>{item[icon]}</strong>
         </div>
       }
       position="right"
       theme="dark"
     >
         <i style={{color:'#F44336',fontSize:15}} class="fas fa-check-circle"></i>
         <span style={{marginLeft:5}}>{item[icon] == 'MEN' ? 'Alto consumo de  memoria '+percent+'%':item[icon]=='CPU'?'Alto consumo na CPU '+percent+'%':'Alto consumo no Disco '+percent +'% '+fs}</span>
    
        </Tooltip>
       
       
      :
        ic == 'warning' ?   <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{item[icon]}</strong>
          </div>
        }
        position="right"
        theme="dark"
      ><i style={{color:'#f0b002',fontSize:15}} class="fas fa-exclamation-circle"></i>  
           <span style={{marginLeft:5}}>{item[icon] == 'MEN' ? 'Alerta memoria '+percent+'%':item[icon]=='CPU'?'Alerta CPU '+percent+'%':'Alerta no Disco '+percent+'% '+fs }</span>
   
      
      </Tooltip>:
        ic == 'successes' ?   <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{item[icon]}</strong>
          </div>
        }
        position="right"
        theme="dark"
      ><i style={{color:'#00ff00',fontSize:15}}  class="fas fa-check-circle"></i>
        <span style={{marginLeft:5}} className="fontstatus">{item[icon]} </span>
      
      </Tooltip>:
        <span> 
        <span style={{marginLeft:5}}></span></span>

      
         
        
       
    );
  }

export default function ({device,item,monitor,ioServers,items,ic,percent,fs}) {
    const [cpuUsedDash, setCpuUsedDash] = useState(0);
    const [memory, setMemory] = useState(0);
    const [memoryUsed, setMemoryUsed] = useState(0);
    const [memoryUsedDash, setMemoryUsedDash] = useState(0);
    const [disk, setDisk] = useState(0);
    const [triggers, setTriggers] = useState({});
    const [deviceItem, setDeviceItem] = useState({})
    const [online, setOnline] = useState(false);
  
    
      /**if(warning != undefined && danger!= undefined &&percent>0){
    
  let i=((percent >= danger.value) && (danger.value> 0))
    ? 'danger'
    : ((percent < danger.value) && (percent >= warning.value) && (warning.value> 0))
    ? 'warning'
    : percent  < warning.value  &&percent >0 && warning.value>0  ?
     'successes':'default'
 */
    return (
        <Fragment>
         <div className={items+'____'} style={{textAlign:'left'}}>
      {item==1?
       <PieChartIndicator
        icon={0}
        ic={ic}
        fs={fs}
        percent={percent}
      
     
         />
        :
        item==2?     <PieChartIndicator
      
        percent={percent}
        ic={ic}
        fs={fs}
        icon={1}
         />
        : <PieChartIndicator
       
        percent={percent}
        icon={2}
        fs={fs}
        ic={ic}
       
        />
      }
        </div>
        </Fragment>
  
        
    )
}