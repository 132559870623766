import React, {
  useReducer,
  useCallback,
  Fragment,
  useEffect,
  useState,
  memo,
} from 'react';
import Options from '../ProjectsList/options';
import { Tooltip } from 'react-tippy';
import moment from 'moment';
// ------------------------------common-----------------------------------------
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Progress from '@common/Progress';
import Tag from '@common/Tag';
import $ from 'jquery';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import Store from '@data/Store';
import DialogConfirm from '@common/DialogConfirm';
import DownloadFiles from '../../../util/DownloadFiles';

// ------------------------------pages-----------------------------------------
import TagTable from './Block_2/TagTable';
import Form from '../ProjectsList/Form';
import ActivityForm from '../../Projects/Project/Form';
import ActivityOptions from '../../Projects/Project/options';
const { language } = Store;

window.$ = $;
let sortPosition = null;
let btnStatus = {
  3: {
    icon: 'fas fa-wrench',
    color: 'primary',
    label: language.CORRECTIVE,
  },
  1: {
    icon: 'fas fa-compass',
    color: 'info',
    label: language.STRATEGIC,
  },
  5: {
    icon: 'fas fa-gavel',
    color: 'danger',
    label: language.LEGAL_OBLIGATION,
  },
  4: {
    icon: 'fas fa-chart-line',
    color: 'success',
    label: language.EVOLUTIONARY,
  },
  2: {
    icon: 'fas fa-dumbbell',
    color: 'purple',
    label: language.IMPROVEMENT,
  },
};

const action = new Actions();

export default memo(({ data, isBlock_1, title, onClose, redirect, type }) => {
  let [open, setOpen] = useReducer(
    (state, v) => (state === v ? null : v),
    null
  );
  const [project, setProject] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  let [formOpen, setFormOpen] = useState(false);
  const [ticket, setTicket] = useState([]);
  const [removeProjectConfirm, setRemoveProjectConfirm] = useState(false);

  const [projects, setData] = useState([]);
  // const [projectsEdit, setProjectsEdit] = useState([]);

  let [formValue, setFormValue] = useState({});
  let [idProject, setIdProject] = useState(0);
  let [activity, setActivity] = useState([]);
  let [projectActitivy, setProjectActivity] = useState([]);
  let [optionsActivity, setOptionsActivity] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  function order() {
    if (type) {
      sortPosition = !sortPosition;
      $('.pjdialog thead tr th:nth-child(2)')
        .find('.control-order')
        .css('transform', `rotate(${sortPosition ? 180 : 0}deg)`);
      let newOrder = $('.pjdialog tbody tr')
        .map(function (i, e) {
          let text = $(e).find('.content-progress span').text();

          return {
            number: text ? parseInt(text.replace('%', '')) : 0,
            obj: e,
          };
        })
        .sort(
          (a, b) =>
            (sortPosition === true ? a : b).number -
            (sortPosition === true ? b : a).number
        )
        .map((i, e) => {
          return e.obj;
        });

      $(null).replaceAll('.pjdialog tbody tr');
      $('.pjdialog tbody').append(newOrder);
    } else {
      sortPosition = !sortPosition;
      $('table thead tr th:nth-child(2)')
        .find('.control-order')
        .css('transform', `rotate(${sortPosition ? 180 : 0}deg)`);
      let newOrder = $('table tbody tr')
        .map(function (i, e) {
          let text = $(e).find('.content-progress span').text();

          return {
            number: text ? parseInt(text.replace('%', '')) : 0,
            obj: e,
          };
        })
        .sort(
          (a, b) =>
            (sortPosition === true ? a : b).number -
            (sortPosition === true ? b : a).number
        )
        .map((i, e) => {
          return e.obj;
        });

      $(null).replaceAll('table tbody tr');
      $('table tbody').append(newOrder);
    }
  }
  useEffect(() => {
    action.execute('get', Constants.APIEndpoints.COMPANY, 'companies');
    action.execute('get', Constants.APIEndpoints.CONTRACT + 'low', 'contracts');
    action.execute(
      'get',
      Constants.APIEndpoints.PROJECT + '/prioritization',
      'prioritization'
    );
    action.execute('get', Constants.APIEndpoints.PROJECTS, 'projects');
  }, []);

  useEffect(() => {
    let pSort = data.sort(function (a, b) {
      if (getPoint(a.id) < getPoint(b.id)){
        return 1;
      }
      if (getPoint(a.id) > getPoint(b.id)){
        return -1;
      }
      return 0
    })

    setData(data);
  }, [data]);

  const getProject = useCallback(async (id) => {
    return await action.execute(
      'get',
      Constants.APIEndpoints.PROJECTS + `/${id}`
    );
  }, []);

  const onEdit = useCallback(async (id) => {
    try {
      const result = await getProject(id);

      setProject(result.data);
      setOpenForm(true);
    } catch (error) {}
  }, []);

  const onDelete = useCallback(async (id) => {
    try {
      const result = await getProject(id);

      setProject(result.data);
      setRemoveProjectConfirm(true);
    } catch (error) {}
  }, []);

  const removeProject = useCallback(
    async ({ id_project, user_cad }) => {
      let user = Store.getUserLoged();
      if (parseInt(user.id) == parseInt(user_cad) || user.role === 'client') {
        try {
          const result = await action.execute(
            'delete',
            Constants.APIEndpoints.PROJECT + '/deleteProject/' + id_project,
            null,
            null,
            'id_project',
            { btn_loading: 'project_' + id_project }
          );

          if (result.data.id_project) {
            setProject(null);
            setRemoveProjectConfirm(false);
            Store.toggleAlert(true, language.PROJECT_DELETED_SUCCESSFULLY, 'info');
            setData((prev) =>
              prev.filter((x) => x.id != result.data.id_project)
            );
          }
        } catch (error) {
          Store.toggleAlert(
            true,
            language.AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER,
            'error'
          );
        }
      } else {
        Store.toggleAlert(
          true,
          language.ONLY_THE_PROJECT_MANAGER_CAN_PERFORM_THIS_ACTION,
          'error'
        );
      }
    },
    [projects]
  );


  const handleOptions = useCallback((type, id) => {
    if (type === 1) {
      onEdit(id);
    } else if (type === 2) {
      onDelete(id);
    }
  }, []);

  const isClosed = useCallback((e) => {
    return e.status === 'Concluído' || e.status === 'Concluído com atraso';
  }, []);

  const editActivity = (activity) => {
    if (activity.evolution != 100){

    action
      .execute(
        'get',
        `${Constants.APIRoot}/api/projects/${activity.id_project}`,
        'project'
      )
      .then((result) => {
        let projetos = result.data.utils;
        let projeto = [];

        projeto = projetos.BACKLOG.filter(
          (projeto) => projeto.id_project_activity == activity.id
        )

        if (projeto.length==0) {
           projeto = projetos.TODO.filter(
            (projeto) => projeto.id_project_activity == activity.id
          )
        }

        if (projeto.length==0) {
         projeto = projetos.DOING.filter(
          (projeto) => projeto.id_project_activity == activity.id
        )
      }
      if (projeto.length==0) {

         projeto = projetos.DONE.filter(
          (projeto) => projeto.id_project_activity == activity.id
        )
      }

      if (projeto.length==0) {
         projeto = projetos.STOPPED.filter(
          (projeto) => projeto.id_project_activity == activity.id
        )
      }
      if (projeto.length==0) {
         projeto = projetos.VALIDATION.filter(
          (projeto) => projeto.id_project_activity == activity.id
        )
      }



        projeto[0].avatares = projeto[0].users.map((t) => ({
          name: t,
          src: undefined,
        }));
        projeto[0].pj = result.data.utils;
        projeto[0].days = '';
        projeto[0].description =
           projeto[0].name;
        projeto[0].desc = projeto[0].description;

        projeto[0].date = getPriorityExecution(projeto[0].id_project_activity);
        projeto[0].hour= getPriorityHour(projeto[0].id_project_activity);
        projeto[0].color =  getPlaned(projeto[0].id_project_activity) == true
        ? 'var(--danger)'
        : 'var(--warning)';

        projeto[0].stars = getPriority(projeto[0].id_project_activity),
        projeto[0].users = result.data.users;

        projeto[0].tagpj = result.data.tagpj;
        setProjectActivity(projeto[0]);
        setActivity(result.data);
        setIdProject(activity.id_project);

        let opt = ActivityOptions({
          users: Store.users,
          activities_predecessor: [
            ...(result.data.utils.TODO || []),
            ...(result.data.utils.DOING || []),
            ...(result.data.utils.STOPPED || []),
            ...(result.data.utils.VALIDATION || []),
            ...(result.data.utils.BACKLOG || []),
          ],
          // companies: Store.companies,
        });

        setOptionsActivity(opt);
      });
    setFormOpen(true), setTicket(activity);
    }else{
      Store.toggleAlert(true, language.THIS_ACTIVITY_HAS_BEEN_COMPLETED_IT_CANNOT_BE_EDITED, "error");
    }
  };

  const getPriority = (id) => {
   let item = 0;
   Store.sprintpjs.map((g) => {
     item = g.activities.filter((v) => v.fk_id_activity == id)[0].priority;
   });
   return item;
 };
  const getPriorityExecution = (id) => {
   let item = 0;
   Store.sprintpjs.map((g) => {
     item = g.activities.filter((v) => v.fk_id_activity == id)[0]
       .dt_execution;
     item = moment(item).format('DD-MM-YYYY');
   });
   return item;
 };
 const getPriorityHour = (id) => {
   let item = 0;
   Store.sprintpjs.map((g) => {
     item = g.activities.filter((v) => v.fk_id_activity == id)[0].hours;
   });
   return item;
 };
 const getPlaned = (id) => {
   let item = 0;
   Store.sprintpjs.map((g) => {
     item = g.activities.filter((v) => v.fk_id_activity == id)[0].unplanned;
   });
   return item;
 };

const getPoint = (id) => {

  let project = Store.projects.filter((project) => project.id_project == id);
  let point=0;
  try{
    point = 	(((project[0].desire_directors+
    project[0].strategic_alignment+
    project[0].benefit_amount+
    project[0].processes_improvement+
    project[0].financial_feedback)/5)+

    ((project[0].generates_costs+
      project[0].risk_if_not_done+
      project[0].urgency+
      project[0].complexity+
      project[0].involved_areas)/5).toFixed(0))
  }catch(e){
      point= 0;
  }

return point;

}



  const onSubmit = (v, isDuplicate = false) => {
    // delete [v.date, v.avatares, v.color, v.days, v.desc, v.hour, v.pj, v.stars, v.tagpj]
    delete v.date;
    delete v.avatares;
    delete v.color;
    delete v.days;
    delete v.desc;
    delete v.hour;
    delete v.pj;
    delete v.stars;
    delete v.tagpj;

    if (v.files && v.files.length) {
      v.files = v.files.map((e) => ({
        id_project_activity: v.id_project_activity,
        file: e.base64,
        name: e.file.name,
        size: e.file.size,
        type: e.file.type,
      }));
    }
    action
      .execute(
        'put',
        `${Constants.APIRoot}/api/project/activity`,
        'project',
        v,
        'id_project',
        { btn_loading: 'load_activity' }
      )
      .then((e) => {
        window.$eventBus.emit('close-modal-form-activity-project', 1);

        if (!!isDuplicate) {
          //duplicar sub atividades

          this.store.subActivities.map((sub, i) => {
            const data = {
              name: sub.name,
              fk_id_responsible: sub.fk_id_responsible,
              dt_end: sub.dt_end,
              fk_id_project: v.fk_id_project,
              fk_id_activity: parseInt(e.data.newActivityId),
              neworder: i + 1,
            };

            action
              .execute(
                'post',
                Constants.APIEndpoints.PROJECT + '/activity/subactivity',
                'subActivities',
                data,
                null,
                { btn_loading: 'subactivities' }
              )
              .then((_) => console.log('save'));
          });
        }

        if (v.id_project_activity) {
          window.$eventBus.emit('evtUpdateStatus', {
            fk_id_project_activity: v.id_project_activity,
            pj: e.data,
          });
        }
      });

      window.location.reload();
    };

  function onDownloadFile(id_project_activity, name) {
    DownloadFiles(Constants.APIEndpoints.REQUESTS + '/downloadgp/' + id_project_activity + '&' + name, name)
  }

  function ondeletefile(f) {
    action.execute(
      'get',
      `${Constants.APIEndpoints.REQUESTS}/deletefileproject/${f.id_project_activity}&${f.id_gp_file}`
    );
  }

  let options = Options({
    companies: Store.companies,
    users: Store.users,
    language: Store.language,
    project_stages: Store.project_stages,
    contracts: Store.contracts,
    tickets: Store.tickets,
    departments: Store.departments,
    activities_predecessor: projectActitivy.utils,
  });

  let opt = ActivityOptions({
    users: Store.users,
    activities_predecessor: activity.utils,
    // companies: Store.companies,
  });


  return (
    <Fragment>
      <Form
        project_stages={Store.project_stages}
        open={openForm}
        values={project}
        options={options}
        handleValue={setProject}
        execute={action.execute}
        APIEndpoints={Constants.APIEndpoints}
        onClose={() => {
          setProject(null);
          setOpenForm(false);
        }}
      />

      <ActivityForm
        open={formOpen}
        onClose={() => {
          setProjectActivity({});
          setActivity({});
          setIdProject(0);

          setFormOpen(false);
        }}
        options={optionsActivity.length == undefined ? optionsActivity : opt}
        values={projectActitivy}
        project={projectActitivy}
        idProject={idProject}
        action={action}
        onSubmit={onSubmit}
        onDownloadFile={onDownloadFile}
        ondeletefile={ondeletefile}
        APIEndpoints={Constants.APIEndpoints}
      />

      <DialogConfirm
        open={removeProjectConfirm}
        onClose={() => {
          setRemoveProjectConfirm(false);
          setProject(null);
        }}
        title={language.WARNING_WANT_REMOVE_PROJECT}
        confirmProps={{
          color: 'danger',
          _loading: 'delete-project_' + `${project ? project.id_project : ''}`,
        }}
        message={
          `${project ? project.id_project : ''}` +
          ' - ' +
          `${project ? project.name : ''}`
        }
        onConfirm={() =>
          removeProject({
            id_project: project.id_project || 0,
            user_cad: project.user_cad || 0,
          })
        }
      />
      <CommonTable
        className={`table-primary-pmo  ${type ? `pjdialog` : ''} `}
        title={title}
        paginationTop={!isBlock_1}
        maxHeight={isBlock_1 ? 500 : undefined}
        AppBarProps={{
          style: isBlock_1
            ? {
                borderRadius: '10px 10px 0 0',
              }
            : {},
        }}
        col={[
          {key: 'actions', label: language.ACTIONS },
          {key: 'id', label: language.ID },
          {key: 'real_id', label: language.REAL_ID },
          {key: 'project', label: language.PROJECT },
          {key: 'manager', label: language.MANAGE },
          {key: 'project_type', label: language.TYPE_PROJECT },
          {key: 'priority', label: language.PRIORITIES },
          {key: 'status', label: language.STATE },
          {key: 'percent', label: language.EVOLUTION_PERCENTAGE },
          {key: 'expected_start', label: language.EXPECTED_START },
          {key: 'expected_end', label: language.EXPECTED_END },
          {key: 'realized_start', label: language.START_DONE },
          {key: 'realized_end', label: language.FINISH_DONE },
          {key: 'expected_cost', label: language.EXPECTED_COST },
          {key: 'realized_cost', label: language.REALIZED_COST },
          {key: 'goal', label: language.GOAL },
          {key: 'activities', label: language.ACTIVITIES },
          {key: 'activities', label: '' },
        ].filter(Boolean)}
        beforeBar2={
          isBlock_1 ? (
            <Button
              icon="fas fa-times"
              color="light"
              variant="circle"
              outlined
              onClick={onClose}
            />
          ) : undefined
        }
        noSort={['percent']}
        onSortItem={(item) => {
          if (item == 'percent') {
            order();
          }
        }}
        data={projects
          .flatMap((e, i) => [
            {
              ConfigCell: {
                __order_id: e.id,
                __order_real_id: e.real_id,
                __order_project: e.project,
                __order_manager: e.manager,
                __order_project_type: btnStatus[e.project_type] ? btnStatus[e.project_type].label : '',
                __order_priority: e.priority ? e.priority.label : '',
                __order_status: e.status,
                __order_percent: e.percent,
                __order_expected_start: e.expected_start,
                __order_expected_end: e.expected_end,
                __order_realized_start: e.realized_start,
                __order_realized_end: e.realized_end,
                __order_expected_cost: e.expected_cost.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
                __order_realized_cost: e.realized_cost.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
                __order_goal: e.goal,
              },
              btn: isBlock_1 ? (
                <i
                  className="fas fa-eye"
                  style={{
                    color: 'var(--primary)',
                    fontSize: 18,
                    cursor: 'pointer',
                  }}
                  onClick={() => redirect(e.id)}
                />
              ) : (
                <div className="btn" onClick={() => setOpen(e.id)}>
                  <i
                    className={
                      open === e.id
                        ? 'fas fa-chevron-down'
                        : 'fas fa-chevron-right'
                    }
                  />
                  <div style={{ backgroundColor: e.color }}></div>
                </div>
              ),
              ...(!isClosed(e) && !isBlock_1
                ? {
                    actions: (
                      <MenuTooltip
                        onChange={(v) => {
                          if (v.value == 3) {
                            redirect('/dash/gp/' + e.id);
                          } else {
                            handleOptions(v.value, e.id);
                          }
                        }}
                        options={[
                          {
                            value: 3,
                            label: language.VISUALIZE,
                            icon: 'fas fa-eye',
                            color: 'var(--primary)',
                          },
                          {
                            value: 1,
                            label: language.EDIT,
                            icon: 'fas fa-pencil-alt',
                            color: 'var(--success)',
                          },
                          {
                            value: 2,
                            label: language.DELETE,
                            icon: 'fas fa-trash-alt',
                            color: 'var(--danger)',
                          },
                        ]}
                      />
                    ),
                  }
                : isBlock_1
                ? null
                : { actions: <span></span> }),
              id: e.id,
              real_id: e.real_id,
              project: e.project,
              manager: e.manager,
              project_type: (
                <Button
                  {...btnStatus[e.project_type]}
                  variant="normal"
                  size={0.7}
                />
              ),
              priority: e.priority && (
                <Tag label={e.priority.label} color={e.priority.color} />
              ),

              status: e.status,
              percent: (
                <div className="d-flex align-items-center content-progress">
                  <Progress
                    current={e.percent}
                    hiddenText
                    className="w-100"
                    background="var(--warning)"
                  />
                  <span className="ml-2">{e.percent}%</span>
                </div>
              ),
              expected_start: e.expected_start,
              expected_end: e.expected_end,
              realized_start: e.realized_start,
              realized_end: e.realized_end,
              expected_cost: e.expected_cost.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }),
              realized_cost: e.realized_cost.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }),
              goal: (
                <Tooltip
                  html={
                    <div className="content-medium">
                      <span dangerouslySetInnerHTML={{ __html: e.goal }} />
                    </div>
                  }
                  arrow={true}
                  position="left"
                  theme="light"
                  interactive
                >
                  <Button
                    icon="fas fa-eye"
                    color="primary"
                    variant="circle"
                    size={0.7}
                  />
                </Tooltip>
              ),
              activities: (
                <div className="content-status-activities">
                  {e.activities.map((e, i) => (
                    <Tooltip
                      html={<span style={{ color: 'white' }}>{e.label}</span>}
                      arrow={true}
                      position="left"
                      theme="dark"
                    >
                      <div
                        key={e.label}
                        className={
                          'status-activities status-activities-' + (i + 1)
                        }
                      >
                        {e.value}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              ),
            },
            open === e.id && !isBlock_1
              ? {
                  ConfigCell: {
                    cols: {
                      col_0: {
                        colSpan: 17,
                        style: { paddingLeft: 30, paddingRight: 30 },
                      },
                    },
                    __order_id: e.id,
                    __order_real_id: e.real_id,
                    __order_project: e.project,
                    __order_manager: e.manager,
                    __order_project_type: btnStatus[e.project_type].label,
                    __order_priority: e.priority.label,
                    __order_status: e.status,
                    __order_percent: e.percent,
                    __order_expected_start: e.expected_start,
                    __order_expected_end: e.expected_end,
                    __order_realized_start: e.realized_start,
                    __order_realized_end: e.realized_end,
                    __order_expected_cost: e.expected_cost.toLocaleString(
                      'pt-BR',
                      { style: 'currency', currency: 'BRL' }
                    ),
                    __order_realized_cost: e.realized_cost.toLocaleString(
                      'pt-BR',
                      { style: 'currency', currency: 'BRL' }
                    ),
                    __order_goal: e.goal,
                  },
                  btn: <TagTable data={e.data} edit={editActivity} />,
                }
              : null,
          ])
          .filter(Boolean)}
      />
    </Fragment>
  );
});
