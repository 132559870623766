import React, { Component } from 'react'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'

export default class ProjetosEntreguesPorMes extends Component {
  render() {
    const { data } = this.props
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis dataKey="name" />
          <YAxis />
          <Legend />
          <Tooltip />
          <Bar
            dataKey="p1"
            name="Entregues"
            stackId="a"
            barSize={50}
            fill="#369057"
          />
          <Bar
            dataKey="p2"
            name="Não entregues"
            stackId="a"
            barSize={50}
            fill="#C43333"
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }
}
