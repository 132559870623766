import React, { useReducer } from 'react';
// ------------------------------common-----------------------------------------
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Progress from '@common/Progress';
// ------------------------------material-ui-------------------------------------
// ------------------------------components-------------------------------------
import ActivitiesTable from './ActivitiesTable';
import Store from '@data/Store';

const { language } = Store;


let border = { border: '1.5px solid #e9ecef' }

export default function ({ data = [], edit }) {
	let [open, setOpen] = useReducer((state, v) => state === v ? null : v, null);
	return (
		<CommonTable
			bar={false}
			pagination={false}
			className='table-tags'
			col={[
				{ key: 'btn', label: '', thConfig: true, style: { width: '1%' } },
				{ key: 'name', label: language.ACTIVITY_GROUP_NAME, thConfig: true, style: { width: '30%' } },
				{ key: 'number', label: language.NUMBER_OF_ACTIVITIES, thConfig: true, style: { width: '5%' } },
				{ key: 'percent', label: language.EVOLUTION_PERCENTAGE, thConfig: true, style: { width: '64%' } },
			]}
			data={data.flatMap((e, i) => [
				{
					ConfigCell: {
						className: 'line-tag',
						onClick: () => setOpen(e.id_project_tag),
						__order_name: e.name,
						__order_number: e.data.length,
						__order_percent: parseInt(e.data.reduce((n, e) => n + parseInt(e.evolution), 0) / (e.data.length || 1))
					},
					btn: (
						<div className='btn'>
							<i className={open === e.id_project_tag ? 'fas fa-chevron-down' : 'fas fa-chevron-right'} />
						</div>
					),
					name: (
						<Button
							variant='normal'
							label={e.name}
							color={e.color}
							size={.7}
							style={{
								backgroundColor: e.color,
								color: 'white'
							}}
						/>
					),
					number: (
						<div className='qtd-activity'>
							{e.data.length}
						</div>
					),
					percent: (
						<div className='d-flex align-items-center content-progress'>
							<Progress current={parseInt(e.data.reduce((n, e) => n + parseInt(e.evolution), 0) / (e.data.length || 1))} hiddenText className='w-100' background='var(--warning)' />
							<span className='ml-2'>{parseInt(e.data.reduce((n, e) => n + parseInt(e.evolution), 0) / (e.data.length || 1))}%</span>
						</div>
					)
				},
				open == e.id_project_tag ? {
					ConfigCell: {
						cols: {
							col_0: { colSpan: 4, style: { paddingLeft: 30, paddingRight: 30 } }
						},
						__order_name: e.name,
						__order_number: e.data.length,
						__order_percent: parseInt(e.data.reduce((n, e) => n + parseInt(e.evolution), 0) / (e.data.length || 1))
					},
					'btn': <ActivitiesTable data={e.data} edit = {edit}/>
				} : null
			]).filter(Boolean)}
		/>
	)
}