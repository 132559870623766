import React from 'react';
import LoginRecoverySetting from '../../login/LoginRecoverySetting';

export const ServicePortalAccountProtect = () => {
  return (
    <div className="tw-px-4" style={{ minHeight: 280 }}>
      <LoginRecoverySetting triggerAlertOnSuccess={true} />
    </div>
  );
};
