import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import withLanguage from '../../common/languages/withLanguage';

import CommonForm from '../../common/CommonForm';

import FaFileTextO from 'react-icons/lib/fa/file-text-o';

export default class AssetManagerContractForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit } = this.props;

    let fields = [
      {
        span: 6,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true
      },
      {
        span: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
        multiLine: true
      }
    ];

    let button = {
      md: 3,
      offset: { md: 8.9 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10 }
    };

    return (
      <PageLayout
        icon={<FaFileTextO fontSize={30} className="colorPrimary" />}
        title={language.CONTRACT_TYPE}
        subtitle={language.ADD_NEW_TYPE_CONTRACT}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            label={language.BACK}
            color='primaryGradient'
            onClick={() => handlePage('listTypeContracts')}
          />
        ]}
      >
        <Card style={{ marginTop: 10 }}>
          <CommonForm
            fields={fields}
            button={button}
            language={language}
            onFormSubmit={onFormSubmit}
          />
        </Card>
      </PageLayout>
    );
  }
}
