import React, { memo } from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';
import { Average } from './Graphs';
import withLanguage from '../../../../common/languages/withLanguage';

/* 
----------------------------format----------------------------
let data = {
      currentMaturity:[5, 3, 4, 7],
      desiredMaturity:[2, 2, 3, 2]
   }
*/
export default memo(withLanguage(({ page, currentMaturity = [], desiredMaturity = [], language }) => {

   return (
      <Card className='h-100'>
         <AppBar title={language.AVERAGES_OF_EACH_STANDARD} subTitle={`(${language.IN_THE_MONTH})`} />
         <div className='content-medium pt-0'>
            <ReactHighcharts
               config={Average({
                  data: [
                     {
                        color: 'var(--warning)',
                        name: language.CURRENT_MATURITY,
                        data: currentMaturity
                     }, {
                        name: language.DESIRED_MATURITY,
                        color: 'var(--primary)',
                        data: desiredMaturity
                     }
                  ],
                  categories: ['ISO 27001', 'ISO 2000', 'ISO 9001', 'ISO 37001', 'COBIT']
               })}
            />
         </div>
      </Card>
   )
}, function (prev, next) {
   let omit = obj => _.omitBy(obj, val => typeof val == 'function');
   return _.isEqual(omit(prev), omit(next));
}))