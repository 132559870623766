import React, { Component, useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '../../../common/CommonForm2';
import Button from '../../../common/Button';
import Constants from '../../../data/Constants';
import { Card } from '@material-ui/core';

const TemplateSubjectForm = ({
  language,
  handlePage,
  templateOptions,
  addTemplateToSubjects,
  templateSelected,
  topicSelected,
}) => {
  const fieldsOptionsTemplate = templateOptions.map((template) => ({
    value: template.id_template_forms,
    label: template.name,
  }));

  const fields = [
    {
      col: 12,
      name: 'id_template',
      label: 'Template',
      type: 'select',
      options: fieldsOptionsTemplate,
      required: true,
    },
  ];
  const newTemplateSelected = { ...templateSelected, ...topicSelected };
  const button = { label: language.ADD, icon: 'fas fa-save' };
  return (
    <Dialog
      miniVersion
      open={true}
      title={language.TEMPLATE_SUBJECT_OPEN}
      onClose={() => handlePage('list')}
      maxWidth="sm"
    >
      <Card style={{ height: 250 }} className="content">
        <CommonForm
          values={newTemplateSelected}
          button={button}
          fields={fields}
          onSubmit={addTemplateToSubjects}
        />
      </Card>
    </Dialog>
  );
};

export default TemplateSubjectForm;
