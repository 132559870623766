import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import StatusList from './StatusList';
import StatusForm from './StatusForm';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

import Animate from '@common/Animate';
@observer
export default class StatusContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
      status: [],
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    const action = new Actions();

    action
      .execute('get', Constants.APIEndpoints.STATUS_AWAITING, 'status_awaiting')
      .then((e) => {
        this.setState({ status: e.data });
      })
    this.handlePage('list');
  }

  onFormSubmit(values) {
    const action = new Actions();
    let method = values.hasOwnProperty('id_status') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_status')
      ? Constants.APIEndpoints.STATUS_AWAITING + '/' + values.id_status
      : Constants.APIEndpoints.STATUS_AWAITING;

    action
      .execute(method, url, 'status_awaiting', values, 'status_id')
      .then((e) => {
        this.setState({ status: e.data });
      })
      .catch((error) => {});

    this.handlePage('list');
  }

  onDelete() {
    let { values } = this.state;
    const action = new Actions();

    action.execute('post', Constants.APIEndpoints.STATUS_AWAITING  + '/' +'deletestatus',
     'status_awaiting', values, 'id_status')
     .then ((e) => {
      this.setState({ status: e.data });

     })


     this.handlePage('list');  

  }

  handlePage(page, edit) {

    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;

    let {
      language,
      mails,
      companies,
      groups,
      contracts,
      mailContract,
    } = this.store;
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <StatusList
          redirect={this.redirect2}
          language={language}
          handlePage={this.handlePage}
          store={this.state.status}
          configurationProps={configurationProps}
        />

        {page === 'form' ? (
          <StatusForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.status}
          />
        ) : null}
      </Fragment>
    );
  }
}
