import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import { testRoute } from '@common/Func';


@observer
export default class OccupationForm extends Component {
  render() {
    let {
      language,
      handlePage,
      values,
      store,
      onFormSubmit,
      onChangeField,
      departments,
      route
    } = this.props;

    /*  let departmentOptions = departments.filter(d => !d.fk_id_department).map(depart => ({
         value: parseInt(depart.id_department),
         label: depart.name
     })) */
    /* 
        window._departmentOptions = departmentOptions; */
    window._values = values;

    let fields = [
      {
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true
      }
    ];

    return (
      <CommonForm
        values={values}
        fields={fields}
        onChangeField={this.onChangeField}
        onSubmit={onFormSubmit}

      />
    );
  }
}
