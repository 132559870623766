import React, { useEffect, useState, memo } from 'react';
import './index.css';
/*---------------------------common----------------------*/
import { flexBasis } from '@common/Func';
/*---------------------------material-ui----------------------*/
import { Grid } from '@material-ui/core';
/*---------------------------pages----------------------*/
import Card from './Card';

export default memo(
  ({
    graphModulesClosedOpen,
    graphModules,
    changesGSD,
    openModalDetail,
    language,
  }) => {
    const [graphModulesCO, setGraphModulesCO] = useState({});
    const [graphM, setGraphM] = useState({});

    useEffect(() => {
      if (graphModulesClosedOpen) {
        setGraphModulesCO(graphModulesClosedOpen);
      }
      if (graphModules) {
        setGraphM(graphModules);
      }
    }, [graphModulesClosedOpen, graphModules]);
    return (
      <div id="dashboard-block-2" className="mb-2">
        <Grid container spacing={1}>
          {[
            {
              label: language.NEW_CALLS,
              current: graphModulesCO
                ? parseInt(
                    100 -
                      (graphModulesCO.RequestD /
                        (graphModulesCO.RequestO + graphModulesCO.RequestP)) *
                        100
                  )
                : 0,
              opened: graphModulesCO
                ? graphModulesCO.RequestP + graphModulesCO.RequestO
                : 0,
              closed: graphModulesCO ? graphModulesCO.RequestP : 0,
            },
            {
              label: language.REQUEST,
              current: graphModulesCO
                ? parseInt(
                    100 -
                      (graphModulesCO.SolicitationD /
                        graphModulesCO.SolicitationP) *
                        100
                  )
                : 0,
              opened: graphModulesCO.SolicitationO,
              closed: graphModulesCO.SolicitationP,
            },
            {
              label: language.TICKET_TYPES.INCIDENT,
              current: graphModulesCO
                ? parseInt(
                    100 -
                      (graphModulesCO.IncidentD / graphModulesCO.IncidentP) *
                        100
                  )
                : 0,
              opened: graphModulesCO.IncidentO,
              closed: graphModulesCO.IncidentP,
            },
            {
              label: language.TICKET_TYPES.PROBLEM,
              current: graphModulesCO
                ? parseInt(
                    100 -
                      (graphModulesCO.ProblemD / graphModulesCO.ProblemP) * 100
                  )
                : 0,
              opened: graphModulesCO.ProblemO,
              closed: graphModulesCO.ProblemP,
            },
            {
              label: language.GMUD,
              current: 0,
              opened:
                changesGSD && changesGSD.filter((v) => v.status == 'Open'),
              create:
                changesGSD && changesGSD.filter((v) => v.status == 'Create'),
              analyse:
                changesGSD && changesGSD.filter((v) => v.status == 'Analyze'),
              closed:
                changesGSD &&
                changesGSD.filter(
                  (v) => v.status == 'Closed' || v.status == 'Finished'
                ),
              cancelled:
                changesGSD && changesGSD.filter((v) => v.status == 'Cancelled'),
              rollback:
                changesGSD && changesGSD.filter((v) => v.status == 'Rollback'),
              inprogress:
                changesGSD && changesGSD.filter((v) => v.status == 'Progress'),
            },
            /**3 analyse 

1 create 

2 aberto  */
          ].map((e, i) => (
            <Grid
              item
              xs={2.4}
              style={{ ...flexBasis(2.4) }}
              className="content-card"
              key={`${e.label}-${i}`}
            >
              <Card
                label={e.label}
                openModalDetail={openModalDetail}
                iconIndex={i}
                current={e.current || 0}
                opened={e.opened}
                closed={e.closed}
                analyse={e.analyse}
                create={e.create}
                cancelled={e.cancelled}
                rollback={e.rollback}
                inprogress={e.inprogress}
                language={language}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  },
  function (prev, next) {
    return _.isEqual(
      [prev.graphModulesClosedOpen, prev.graphModules],
      [next.graphModulesClosedOpen, next.graphModules]
    );
  }
);
