import RaisedButton from '../../common/RaisedButton';
import 'react-quill/dist/quill.snow.css'

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { Card, Paper, GridList } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import ReactQuill from 'react-quill';
import MdLocalPlay from 'react-icons/lib/md/local-play';
import FaUser from 'react-icons/lib/fa/user';
import FaUserSecret from 'react-icons/lib/fa/user-secret';
import FaCommentingO from 'react-icons/lib/fa/commenting-o';
import FaWrench from 'react-icons/lib/fa/wrench';
import moment from 'moment';
import CommonForm from '../../common/CommonForm2';
import classnames from 'classnames';
import './ProjectComment.css';
import { Avatar } from '@material-ui/core';
import randomColor from 'randomcolor'

@observer
export default class ProjectComment extends Component {
  render() {
    let randomSeed = Math.floor(Math.random() * 1000)

    let { language, onFormSubmit, ticket, onCommentTicket, hideNewComment } = this.props;
    let fields = [
      { col: 12, name: 'description', placeholder: 'Escreva sua mensagem...', type: 'textEditor', contentProps: { style: { marginTop: -20, marginBottom: -20 } } }
    ];

    let button = { label: language.SEND, icon: 'fas fa-paper-plane', style: { paddingRight: 2 } };

    let commentsNodes = (ticket.comments || []).map((c, k) => <Comment randomSeed={randomSeed} key={k} language={language} {...c} myComment={c.fk_id_user === ticket.fk_id_user} />);

    return (
      <div id='ProjectComment'>

        <Container>

          <Row>
            <Col md={12} style={{ height: '100%' }}>
              <ul className='msg-containter' style={!!this.props.maxHeightChat ? { height: this.props.maxHeightChat } : {}}>
                {commentsNodes}
              </ul>
            </Col>
          </Row>

          {
            !hideNewComment &&
            <div>

              {this.props.statusTicket === 'Closed' ?
                null :
                <CommonForm values={{ description: '' }} fields={fields} button={button} onSubmit={(values) => onCommentTicket(ticket.id_project_activity, values)} />
              }

            </div>

          }
        </Container>
      </div>
    );
  }
}

class Comment extends Component {
  render() {

    let { language, randomSeed, myComment, name_create, description, dt_cad, ticket, name_device, subject, sla, role } = this.props;

    let FDate = moment.locale() == 'pt-br' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';

    return (
      <li className={classnames((myComment ? 'received' : 'sended'), 'msg')}>
        <div className='msg-box shadown'>
          {
            ticket && 1 != 1 ? (
              <div>
                <Row>
                  {name_device ? <Col md={6}><h4>{language.DEVICE}: {name_device}</h4></Col> : null}
                  <Col md={6}><h4>{language.SLA}: {sla}</h4></Col>
                </Row>
                <h4>{language.SUBJECT}: {subject}</h4>
                <hr />
                <ReactQuill value={description} modules={{ toolbar: null }} readOnly={true} style={{ border: 'none' }} />
              </div>
            ) : (
              <Fragment>
                {name_create &&
                  <Avatar className='avatar'>
                    {name_create.substr(0, 1)}
                  </Avatar>
                }
                <div className='box-data'>
                  <div className='title-content'>
                    <small className='name'>{name_create}</small>
                    <small className='date'>{moment(dt_cad).format(FDate + " - HH:mm:ss")}</small>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: description }} />

                </div>
              </Fragment>
            )
          }
        </div>
      </li>
    );
  }
}

/*import RaisedButton from '../../common/RaisedButton';
import 'react-quill/dist/quill.snow.css'

import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Card, Paper, GridList } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import ReactQuill from 'react-quill';
import MdLocalPlay from 'react-icons/lib/md/local-play';
import FaUser from 'react-icons/lib/fa/user';
import FaUserSecret from 'react-icons/lib/fa/user-secret';
import FaCommentingO from 'react-icons/lib/fa/commenting-o';
import FaWrench from 'react-icons/lib/fa/wrench';
import moment from 'moment';
import CommonForm from '../../common/CommonForm';

@observer
export default class ProjectComment extends Component {
  render() {

    let { language, onFormSubmit, ticket, onCommentTicket, hideNewComment } = this.props;
    console.log(ticket)
    let fields = [
      { span: 12, name: 'description', label: language.MESSAGE, type: 'textEditor', multiLine: true }
    ];

    let button = { md: 1, offset: { md: 11 }, label: language.SEND, primary: true, style: { float: 'right', margin: '10px auto' } };

    let commentsNodes = ticket.comments !=null ? ticket.comments.map((c, k) => <Comment key={k} language={language} {...c} myComment={c.fk_id_user === ticket.fk_id_user} />):[];

    return (
      <Container>

          <Row className='maxHeightChat' style={!!this.props.maxHeightChat?{maxHeight:this.props.maxHeightChat}:{}}>
            <Col md={12}>
              {commentsNodes}
            </Col>
          </Row>


            <div className='comment-field' style={{overflowY:'auto'}}>


                  <CommonForm values={{ description: '' }} language={language} fields={fields} button={button} onFormSubmit={(values) => onCommentTicket(ticket.id_project_activity, values)} />


            </div>


      </Container>
    );
  }
}

class Comment extends Component {
  render() {

    let { language, myComment, name_create, description, dt_cad, ticket, name_device, subject, sla, role } = this.props;

    return (
      <Row className='row'>
        <Col md={12}>
          <div className={myComment ? 'msgClient' : 'msgSuport'}>
            <Container fluid={true}>
              <Row>
                <Col md={12}>
                  {
                    ticket ?
                      <div>
                        <Row>
                          {name_device ? <Col md={6}><h4>{language.DEVICE}: {name_device}</h4></Col> : null}
                          <Col md={6}><h4>{language.SLA}: {sla}</h4></Col>
                        </Row>
                        <h4>{language.SUBJECT}: {subject}</h4>
                        <hr />
                        <ReactQuill value={description} modules={{ toolbar: null }} readOnly={true} style={{ border: 'none' }} />
                      </div>
                      :
                      <div style={{ marginTop: 10 }}>
                        <small>{name_create} {moment(dt_cad).format("YYYY-MM-DD HH:mm:ss")}</small>
                        <ReactQuill value={description} modules={{ toolbar: null }} readOnly={true} style={{ border: 'none' }} />
                      </div>
                  }
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    );
  }
}*/