import moment from 'moment';
import Constants from '@data/Constants';
import React, { memo, Fragment, useState, useEffect } from 'react';
import Actions from '@data/Actions';
import Store from '@data/Store';

let {APIEndpoints} = Constants;

function photo(e, users) {
   let _users = (e.usersphoto || []).map(e => {

      let photo = e.photo && `${Constants.APIRoot}/public/users/${e.id_user}/profileLogo.png`;

      return {

         src: photo || undefined,
         name: e.name_user || ''
      }
   });

   return _users;
}

let keys = ['BACKLOG', 'TODO', 'DOING', 'STOPPED', 'VALIDATION', 'DONE'];
let percentColor = (percent) => percent <= 33 ? 'var(--danger)' : percent <= 66 ? 'var(--warning)' : 'var(--success)';

export default {
   
   kanban({ tickets, projects, users }) {

      let action = new Actions();
      let [statusAwaiting, setStatusAwaiting] = useState([]);
      const { language } = Store;

      useEffect(() => {
         action
           .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
           .then((e) => {
             setStatusAwaiting(e.data);
           })
           .catch((error) => {
             console.log(error);
           });
       }, []);

      //Se o status for em andamento, ele verifica se ficará em impeditivo ou validação
      const columnTicket = (t, p) => {
         const fk_id_status_kanban =
         statusAwaiting.find((waiting) => waiting.id_status === t.stop_ticket)
            ?.fk_id_status_kanban || '';
         if (fk_id_status_kanban && fk_id_status_kanban == 1)
         return 'STOPPED'
         else if (fk_id_status_kanban && fk_id_status_kanban == 2)
         return 'VALIDATION'
         else if (fk_id_status_kanban && fk_id_status_kanban == 3)
         return 'DOING'
         else if (fk_id_status_kanban && fk_id_status_kanban == 4)
         return 'TODO'
       return undefined    

      };

      let activitiesData = projects.flatMap(e => {
         return keys.flatMap((key, i) => [...e.utils[key]]).map(a => ({
            type: {
               type: 1,
               label: language.PROJECT,
               color: 'var(--success)'
            },
            id_project: e.id_project,
            pj: e,
            id: a.id_project_activity,
            avatares: photo(a, users),
            star: a.priorization,
            name: a.name,
            stage: a.stage,
            dt: moment(a.dt_start).format('L'),
            commentsTotal: a.commentscount,
            filesTotal: a.countfiles,
            percent: parseInt(a.percent),
            percentColor: percentColor(parseInt(a.percent)),
         }))
      })
      let ticketsData = tickets.map(e => 
         ({
         type: {
            type: 2,
            label: 'Ticket',
            color: 'var(--warning)'
         },
         stage: ['In Progress','Reopened'].includes(e.status) ? columnTicket(e) :  ['Create', 'Open'].includes(e.status) ? 'TODO' : e.status == 'In Progress' ? 'DOING' : 'DONE',
         avatares: [
            { name: e.nameuser }
         ],
         id: e.id_tickets,
         star: e.fk_id_priority == 31 ? 1 : e.fk_id_priority == 32 ? 2 : 3,
         name: e.subject,
         dt: moment(e.dt_cad).format('L'),
         commentsTotal: 0,
         filesTotal: 0,
         percent: parseInt(e.progress),
         percentColor: percentColor(parseInt(e.progress)),
      }))

      return [
         {
            title: language.PROJECT_STATUS.TO_DO,
            data: (activitiesData.concat(ticketsData) || []).filter(e => e.stage == 'TODO')
         },
         {
            title: language.PROJECT_STATUS.DOING,
            data: (activitiesData.concat(ticketsData) || []).filter(e => e.stage == 'DOING')
         },
         {
            title: language.PROJECT_STATUS.STOPPED,
            data: (activitiesData.concat(ticketsData) || []).filter(e => e.stage == 'STOPPED')
         },
         {
            title: language.PROJECT_STATUS.VALIDATION,
            data: (activitiesData.concat(ticketsData) || []).filter(e => e.stage == 'VALIDATION')
         },
         {
            title: language.PROJECT_STATUS.DONE,
            data: (activitiesData.concat(ticketsData) || []).filter(e => e.stage == 'DONE')
         }
      ];
   },
   table({ tickets, projects, users }) {

      let activitiesData = projects.flatMap(e => {
         return keys.flatMap((key, i) => [...e.utils[key]]).map(a => ({
            status: parseInt(a.percent),
            type: 1,
            id_project: e.id_project,
            pj: e,
            id: a.id_project_activity,
            date: moment(a.dt_start).format('L'),
            hour: moment(a.dt_start).format('HH:mm'),
            demand_type: 'Atividade',
            module: 'Gestão de Projetos',
            user: photo(a, users)[0].name,
            description: a.name,
         }))
      })

      let ticketsData = tickets.map(e => ({
         status: parseInt(e.progress),
         id: e.real_id,
         type: 2,
         idTicket: e.id_tickets,
         date: moment(e.dt_cad).format('L'),
         hour: moment(e.dt_cad).format('HH:mm'),
         demand_type: 'Ticket',
         module: 'Gestão de Ticket',
         user: e.nameuser,
         description: e.subject,
      }))

      return activitiesData.concat(ticketsData);
   }
}
