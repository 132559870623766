function getMin(data, pl) {
	return data.reduce((min, p) => p[pl] < min[pl] ? p : min, data[0]);
}
function getMax(data, pl) {
	return data.reduce((max, p) => p[pl] > max[pl] ? p : max, data[0]);
}

export function callback(chart) {
	window.chart = chart
	var width = chart.plotBox.width / 2.0;
	var height = chart.plotBox.height / 2.0 + 1;

	chart.renderer.rect(chart.plotBox.x, chart.plotBox.y, width, height, 1)
		.attr({ fill: 'var(--danger)', zIndex: 0 })
		.add();

	chart.renderer.rect(chart.plotBox.x + width, chart.plotBox.y, width, height, 1)
		.attr({ fill: 'var(--warning)', zIndex: 0 })
		.add();

	chart.renderer.rect(chart.plotBox.x, chart.plotBox.y + height, width, height, 1)
		.attr({ fill: 'var(--primary)', zIndex: 0 })
		.add();

	chart.renderer.rect(chart.plotBox.x + width, chart.plotBox.y + height, width, height, 1)
		.attr({ fill: 'var(--success)', zIndex: 0 })
		.add();
}
export default function (e, data) {
	let { language = {} } = e;
	var data = data

	/*var max_x = getMax(data, 'x');
	var min_x = getMin(data, 'x');
	var plan_x = (max_x.x+min_x.x)/2;

	var max_y = getMax(data, 'y');
	var min_y = getMin(data, 'y');
	var plan_y = (max_y.y+min_y.y)/2;*/

	return {

		chart: {
			type: 'bubble',
			plotBorderWidth: 1,
			zoomType: 'xy'
		},
		credits: {
			enabled: false
		},
		legend: {
			enabled: false
		},

		title: {
			text: ''
		},

		xAxis: {
			gridLineWidth: 0,
			title: {
				text: language.CONTRIBUTION_TO_BUSINESS,
				style: {
					color: '#616161',
					fontFamily: 'sans-serif',
					fontWeight: 500
				}
			},
			labels: {
				format: '{value}'
			},
			// ----------- faz o plano carteziano na vertical
	    /*plotLines: [{
	      color: 'black',
	      dashStyle: 'dot',
	      width: 2,
	      value: plan_x,
	      
	      zIndex: 3
	    }]*/
		},

		yAxis: {
			startOnTick: false,
			endOnTick: false,
			gridLineWidth: 0,
			title: {
				text: language.COMPLEXITY + ' ' + language.AND + ' ' + language.COST,
				style: {
					color: '#616161',
					fontFamily: 'sans-serif',
					fontWeight: 500
				}
			},
			labels: {
				format: '{value}'
			},
			// ----------- faz o plano carteziano na horizontal
	    /*plotLines: [{
	      color: 'black',
	      dashStyle: 'dot',
	      width: 2,
	      value: plan_y,
	      
	      zIndex: 3
	    }]*/
		},

		tooltip: {
			useHTML: true,
			headerFormat: '<table>',
			pointFormat: '<tr><th colspan="2"><h3>{point.country}</h3></th></tr>' +
				'<tr><th>Project {point.name}:</th><td>{point.x} -{point.y} </td></tr>',
			footerFormat: '</table>',
			followPointer: true
		},

		plotOptions: {
			series: {
				dataLabels: {
					enabled: true,
					format: '{point.name}'
				}
			},
			bubble: {
				color: 'rgba(250,250,250, 0.8)'
			},
		},

		series: [{
			data: data
		}]
	}
}