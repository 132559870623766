import React, { useState, useEffect } from 'react';
import CommonTable from '@common/CommonTable';
import { Tooltip } from 'react-tippy';
import PageLayout from '@common/PageLayout';
import { Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Progress from '@common/Progress';
import Button from '@common/Button';

import moment from 'moment';

// --------------------------------icons----------------------------------

export default function({ fk_id_ticket, detail_ticket ,sec}) {
  let [times, setTimes] = useState([]);

  let [progress, SetProgress] = useState(0);

  useEffect(() => {
    const interval = setTimeout(() => {
      // projectstatusf(id_project_activity)
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="d-flex align-items-center" style={{ width: 100 }}>
      <Progress
        hiddenText
        current={sec.progresso}
        style={{ height: 7, width: '100%' }}
        background="var(--danger)"
      />
      <span style={{ color: '#707070', fontSize: 10 }} className="ml-2">
        {sec.progresso }%
      </span>
    </div>
  );
}
