import React from 'react';
import { Card } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import constants from '@data/Constants';
import CommonForm from '@common/CommonForm2';

export default function Comment({
  handleValue,
  setValue,
  setFormReference,
  options,
  language,
  values,
}) {
  
  function loadFields() {
    let fields = [
      {
        col: 4,
        type: 'select',
        name: 'type_category',
        label: 'Item',
        options: options.type_category(values?.rule),
        required: true,
      },
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${constants.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit: values.fk_id_company > 0 ? values.namecompany : '',
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: values.fk_id_company > 0 ? values.fk_id_company : 0,
        routeAll: `${constants.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${constants.APIEndpoints.COMPANY}/count`,
        detail: true,
        required: true,
      },
    ];

    return fields;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <AppBar title={language.ACTIONS} />
      <div className="content pt-0">
        <CommonForm
          sensitive
          values={values}
          onRef={(ref) => setFormReference(ref)}
          fields={loadFields()}
          onChangeField={(f, v) => {
            if (f && f.name == 'type_category') {
              setValue('type_category', v);
            }

            handleValue(v, f.name);
          }}
        />
      </div>
    </Card>
  );
}
