import React, { useState, useEffect, useRef } from 'react';
import { Card } from '@material-ui/core';
import AppBar2 from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import { GraphArea } from './Graphs';

import Constants from '@data/Constants';

const tenant = Constants.Tenant;

const LinkInfo = ({ ioLinks, mAll, language}) => {
  const [linkDash, setLinkDash] = useState({
    title: '',
    data: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    let device_id = '';
    if (ioLinks && mAll.length > 0) {
      const devices = mAll.filter((device) => device.type === 'link');

      if (devices.length > 0) {
        device_id = devices[0].id_device;
        try {
          ioLinks.on(`_${tenant}_${devices[0].id_device}`, data => {
            setLinkDash({
              title: devices[0].name,
              data: data.data
            })
          });
        } catch (error) {}
      }
    }
    return () => ioLinks.off(device_id)
  }, []);

  //console.log('renderizando linkinfo')

  return (
    <Card className="h-100">
      <AppBar2
        title={linkDash.title}
        subTitle={linkDash.ip}
        subTitleProps={{ style: { marginBottom: -1 } }}
      />
      <div className="content-medium content-home-graph-area d-flex align-items-end justify-content-between">
        <div className="content-info-b1-col-3 mx-3" style={{ width: '10%' }}>
          <i className="fas fa-arrow-up" />
          <h2>{linkDash.data[linkDash.data.length - 1]}</h2>
          <span>ms</span>
        </div>
        <div style={{ width: '90%' }}>
          <ReactHighcharts
            config={GraphArea({
              language,
              color: '#019FC2',
              name: 'google secundário',
              data: linkDash.data,
            })}
          />
        </div>
      </div>
    </Card>
  );
};

export default LinkInfo;
