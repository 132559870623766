import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Card, TextField } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import { Container, Row, Col } from 'react-grid-system';
import ReactTolltip from 'react-tooltip';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';
import CommonPageNotLoad from '../../common/CommonPageNotLoad';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';


const styles = theme => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '800px'
  }
});

@observer
class DashboardMonitor extends CommonPageNotLoad {
  constructor() {
    super();
    this.state = {
      company: '',
      open: false,
      search: [],
      removeloading: true
    };
    this.handleModal = this.handleModal.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  handleModal = company => {
    this.setState({ open: !this.state.open, company: company });
  };

  onChangeField(field, value) {
    if (field.name === 'id_company') {
      this.setState({
        company: value
      });
    }
  }

  onFormSubmit(values) {
    this.props.onGroupSubmit(values);
    this.handleModal();
  }

  updateSearch(event) {
    this.setState({ search: event.target.value.substr(0, 20) });
  }

  render() {
    const { classes } = this.props;
    let {
      danger,
      language,
      companies,
      handlePage,
      monitorGroups,
      warning,
      filtered = false,
      handleFilter

    } = this.props;

    let { company, open } = this.state;

    let defaultStyle = { boxShadow: '0px 1px 21px 0px rgba(199,199,199,1)' };
    let companyOptions = companies.map(c => ({
      value: c.id_company,
      label: c.name
    }));
    if (this.store.getUserLoged().role == 'solicitant') {
      companyOptions =
        companies.filter(g => g.id_company ==
          this.store.getUserLoged().id_company

        ).map(c => ({
          value: c.id_company,
          label: c.name
        }));

    }



    //
    let array = [];




    let devicesOptions = [];









    if (this.store.getUserLoged().role == 'tech') {

      !company
        ? []
        : companies
          .find(c => c.id_company == company)
          .devices.filter(x => x.type != 'desktop').map(d => {

            if (this.props.monitordevicecompany[0].device_contract.indexOf(d.id_device) > -1) {

              devicesOptions.push({ value: d.id_device, label: d.name });
            }
          })
    } else {
      !company
        ? []
        : companies
          .find(c => c.id_company == company)
          .devices.filter(x => x.type != 'desktop').map(d => {


            devicesOptions.push({ value: d.id_device, label: d.name });

          })

    }

    let devicefields = [
      {
        col: 6,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        options: companyOptions,
        required: true,
        disabled: !!company
      },
      {
        col: 6,
        name: 'name_group',
        label: language.NAME,
        type: 'text',
        required: true
      },
      {
        col: 12,
        name: 'devices',
        label: language.DEVICES,
        type: 'listCheckbox',
        options: devicesOptions
      }
    ];

    let deviceButton = {
      md: 12,
      label: language.ADD,
      circleButton: false,
      loading: false,
      primary: true,
      style: { marginTop: 10 }
    };

    let addDeviceDialog = (
      <Dialog
        monitor_scroll
        modal={false}
        open={open}
        onClose={() => this.handleModal()}
        title={language.DEVICES_GROUP}
      >
        <CommonForm
          fields={devicefields}
          values={{ id_company: company }}
          button={deviceButton}
          onSubmit={this.onFormSubmit}
          onChangeField={this.onChangeField}
        />
      </Dialog>
    );

    let filterCompanies = companies.filter(m => {
      return m.name.indexOf(this.state.search) !== -1;
    });

    return (
      <div
        style={{
          width: '100%',
          textAlign: 'right'
        }}
      >
        {filtered && <Button
          icon="fas fa-redo"
          color="success"
          variant="circle"
          label="Recarregar todos"
          onClick={handleFilter}
          className="mr-4"
        />}
        <Button
          icon="fas fa-plus"
          color="success"
          variant="circle"
          label={language.ADD_COMPANY}
          onClick={() => this.handleModal()}
        />

        {addDeviceDialog}
      </div>
    );
  }
}
DashboardMonitor.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DashboardMonitor);