import React, { useEffect, useState } from 'react';
import CommonPage from '../../common/CommonPage';
import CommonForm from '../../common/CommonForm2';
import Constants from '../../data/Constants'
import FormRules from './FormRules'
import './index.css'
import Actions from '@data/Actions'
import Store from '@data/Store'
import { translateFromPtBR } from '@common/languages/Dictionary';

const action = new Actions()
const arraySla = ['start', 'pause', 'restart']

const { language } = Store
const targetOpt = {
  response: language.ANSWER,
  resolution: language.RESOLUTION,
  ola: language.OLA
}

const convertHour = (min) => {
  const hours = Math.floor(min / 60);
  min = min % 60;
  const textHour = `000${hours}`.slice(-3);
  const textMin = `00${min}`.slice(-2);
  return `${textHour}:${textMin}`;
}

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: translateFromPtBR(option[attributeName], Store.language)
  }))

  return normalizedOptions
}

const index = (props) => {
  const [countries, setCountries] = useState([])
  const [tab, setTab] = useState(0)
  const [value, setValue] = useState([])
  const [slaProblemStepsEnabled, setSlaProblemStepsEnabled] = useState(false);

  useEffect(() => {
    if (props.value) {
      props.value.sla_duration = convertHour(props.value.sla_duration);
      setValue(props.value)
    }
    // Buscar Paises
    action
      .execute('get', Constants.APIEndpoints.COUNTRY, '', {
        list: true
      })
      .then((result) => {
        const { data } = result
        setCountries(formatSelectOptions(data, 'name', 'id_country'))
      })
  }, [props.value])

  const convertStringInMinutes = (value) => {
    const [hours, minutes] = value.split(':').map(Number);
    return hours * 60 + minutes;
  }

  const saveSla = (obj) => {
    obj.sla_duration = convertStringInMinutes(obj.sla_duration);

    if (obj.sla_problem_steps_enabled) {
      obj.sla_problem_step_root_cause = convertStringInMinutes(obj.sla_problem_step_root_cause);
      obj.sla_problem_step_correction = convertStringInMinutes(obj.sla_problem_step_correction);
      obj.sla_problem_step_resolution = convertStringInMinutes(obj.sla_problem_step_resolution);
      obj.sla_problem_step_analysis = convertStringInMinutes(obj.sla_problem_step_analysis);
    }

    action
      .execute('post', `${Constants.APIEndpoints.SLA_WORK}/slaparam`, '', obj)
      .then((res) => {
        delete res.data.message;
        setValue(res.data)
      });

    setTimeout(() => {
      props.loadSla();
    }, 2000);
  };

  const handleFieldChange = (field, value) => {
    if (field.name === 'sla_problem_steps_enabled') {
      setSlaProblemStepsEnabled(Boolean(value));
    }
  }

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <br></br>
      <CommonForm
        values={value}
        fields={[
          {
            col: 6,
            name: 'sla_name',
            label: language.NAME,
            type: 'text',
            required: true
          },
          {
            col: 6,
            name: 'sla_target',
            label: language.TARGET,
            type: 'select',
            options: Object.keys(targetOpt).map((t) => ({
              label: targetOpt[t],
              value: t
            })),
            required: true
          },
          {
            col: 6,
            name: 'sla_duration',
            label: language.SLA_TIME,
            type: 'text',
            required: true,
            format: '####:##',
            change(v) {
              return v.formattedValue;
            }
          },
          {
            col: 6,
            name: 'fk_id_workday',
            label: language.CALENDAR,
            type: 'autocomplete',
            disablePortal: true,
            textlabel: `${language.CALENDAR} *`,
            method: 'POST',
            route: `${Constants.APIEndpoints.SLA_WORK}/workday/list`,
            fieldquery: 'search',
            textinit: '',
            fieldvaluedb: 'id_workday',
            fieldlabeldb: 'name',
            idinit: 0,
            routeAll: `${Constants.APIEndpoints.SLA_WORK}/workday/list?`,
            methodGetAll: 'POST',
            detail: true,
            visible: true,
            required: true
          },
          {
            col: 6,
            name: 'fk_id_country',
            label: language.COUNTRY,
            type: 'select',
            startIcon: 'fas fa-flag',
            options: countries,
            required: true
          },
          {
            col: 6,
            type: 'autocomplete',
            name: 'fk_id_change',
            label: `${language.CHANGE}`,
            textlabel: `${language.CHANGE}`,
            method: 'POST',
            route: `${Constants.APIEndpoints.CHANGE}/getAllChanges?`,
            fieldquery: 'search',
            fieldvaluedb: 'id_change',
            fieldlabeldb: 'reason',
            routeAll: `${Constants.APIEndpoints.CHANGE}/getAllChanges?`,
            methodGetAll: 'POST',
            detail: true,
            loadOnFocus: true,
          },
          {
            col: 6,
            name: 'sla_fixed_start',
            label: language.SLA_FIXED,
            type: 'switch'
          },
          {
            col: 6,
            name: 'sla_problem_steps_enabled',
            label: language.SLA_PROBLEM_STEPS,
            type: 'switch'
          },
          { col: 6, type: 'element', content: <></>, visible: slaProblemStepsEnabled },
          {
            col: 6,
            name: 'sla_problem_step_root_cause',
            label: language.SLA_PROBLEM_STEP_ROOT_CAUSE,
            type: 'text',
            format: '####:##',
            change(value) { return value.formattedValue },
            required: true,
            visible: slaProblemStepsEnabled
          },
          {
            col: 6,
            name: 'sla_problem_step_correction',
            label: language.SLA_PROBLEM_STEP_CORRECTION,
            type: 'text',
            format: '####:##',
            change(value) { return value.formattedValue },
            required: true,
            visible: slaProblemStepsEnabled
          },
          {
            col: 6,
            name: 'sla_problem_step_resolution',
            label: language.SLA_PROBLEM_STEP_RESOLUTION,
            type: 'text',
            format: '####:##',
            change(value) { return value.formattedValue },
            required: true,
            visible: slaProblemStepsEnabled
          },
          {
            col: 6,
            name: 'sla_problem_step_analysis',
            label: language.SLA_PROBLEM_STEP_ANALYSIS,
            type: 'text',
            format: '####:##',
            change(value) { return value.formattedValue },
            required: true,
            visible: slaProblemStepsEnabled
          },
        ]}
        onSubmit={(v) => saveSla(v)}
        onChangeField={handleFieldChange}
      />

      {value && value.sla_target ? (
        <React.Fragment>
          <div id="slaform">
            <div className="tabs">
              <div className="tabcontent">
                {['Inicio', 'Pausa', 'Retomada'].map((item, index) => (
                  <span
                    onClick={() => setTab(index)}
                    className="abacontainer"
                    style={{
                      padding: '10px',
                      borderRadius: '13px 13px 0px 0px',
                      minWidth: '130px',
                      textAlign: 'center',
                      margin: '0px 2px',
                      color: 'white',
                      cursor: 'pointer',
                      background:
                        index == tab
                          ? '#f0b002'
                          : 'transparent linear-gradient(89deg,#01c2b3,#019fc2)',
                    }}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
            <div
              class="tabbody"
              style={{
                width: '100%',
                borderColor: 'var(--success)',
                border: '1px solid var(--success)',
                borderRadius: '0px 10px 10px 10px',
                padding: '4px',
                height: '75vh',
              }}
            >
              <FormRules
                tab={tab}
                type={arraySla[tab]}
                value={value}
                language={language}
                loadSla={props.loadSla}
              />
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </CommonPage>
  );
};

export default index;
