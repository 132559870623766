import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Card, Chip, Paper } from '@material-ui/core';

import List from '../../common/List';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import Actions from '@data/Actions';

const action = new Actions();

import AuditList from './AuditList';

@observer
export default class ContractList extends Component {
  constructor() {
    super();
    this.state = {
      contractList: null,
      id_contract: null,
    };
  }
  getContract = (value) => {
    let url = this.props.APIEndpoints.ASSESSMENT;
    url += '?date=' + moment().format('YYYY-MM');
    url += '&contract=' + value;
    if (this.state.id_contract != value) {
      this.setState({
        contractList: null,
        id_contract: value,
      });

      action
        .execute('get', url, '')
        .then((res) => {
          this.setState({
            contractList: res.data,
          });
        });
    } else {
      this.setState({
        id_contract: null,
      });
    }
  };
  onFormSubmit = () => {};
  render() {
    let { handlePage, language, store, redirect } = this.props;

    let data = store.contracts.reduce((arr, obj, idx) => {
      let open = this.state.id_contract === obj.id_contract;

      return arr.concat([
        {
          className: 'py-3 font color-lead',
          onClick: () => this.getContract(obj.id_contract),
          content: (
            <Fragment>
              <div
                className="control-order mr-2"
                style={{
                  cursor: 'pointer',
                  ...(open ? { width: 20, height: 20 } : {}),
                }}
              >
                {open && this.state.contractList === null ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i
                    style={{ padding: '0 6px' }}
                    className={
                      'fas fa-angle-' +
                      (this.state.id_contract == obj.id_contract
                        ? 'up'
                        : 'down')
                    }
                  ></i>
                )}
              </div>
              {obj.name}
            </Fragment>
          ),
        },
        {
          visible: open,
          content: (
            <AuditList
              redirect={redirect}
              language={language}
              handlePage={handlePage}
              downloadPage={this.props.downloadPage}
              store={store}
              contractList={this.state.contractList}
              onFormSubmit={this.onFormSubmit}
            />
          ),
        },
      ]);
    }, []);

    return (
      <PageLayout
        icon={<div className="auditIcon titleIconSize" />}
        title={language.AUDIT}
        subTitle={language.DASHBOARD_PAGE.GCF + '/'}
        rightElements={[]}
      >
        <Paper style={{ padding: 0, marginTop: '-1rem' }} id="ContractList">
          <List className="List" scale={false} mh={100000} items={[...data]} />
        </Paper>
      </PageLayout>
    );
  }
}
