import React, {memo, Fragment} from 'react';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import classnames from 'classnames';
import Store from '@data/Store';

const { language } = Store;

const MyHeader = ({
	title,
	subTitle,
	className
})=>(
	<AppBar
		contentProps={{
			className:'pl-0 '+className
		}}
		titleProps={{
			style:{color:'var(--primary)'}
		}}
		title={title}
		subTitle={subTitle}
	/>
)
export default memo(({
  
   values,
   setForm,
   h
})=>(
   <div className='wrap mb-3'>
      <AppBar title={language.PERIOD_AND_PRIORITY} />
     
      <div className='content'>
         <CommonForm
               onRef={r=>setForm(r)}
               values={values}
            fields={[
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.INITIAL_DATE}
                        subTitle={language.REPORT_WHAT_IT_WILL_BE_AT_THE_START_DATE_OF_THIS_MOVE}
                     />
                  ),
                  type:'date',
                  name:'start_date',
                  label: language.START_DATE
               },
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.END_DATE}
                        subTitle={language.REPORT_WHAT_IT_WILL_BE_AT_THE_END_OF_THIS_MOVE}
                     />
                  ),
                  type:'date',
                  name:'end_date',
                  label: language.END_DATE,
               },
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.EFFORT}
                        subTitle={language.INFORM_WHAT_WILL_BE_THE_EFFORT_IN_HOURS_TO_BE_CARRIED_OUT}
                     />
                  ),
                  type:'text',
                  name:'effort',
                  label: language.EFFORT,
                  format:'###:##',
                  placeholder:'xxx:xx',
                  change(v){
                     return v.formattedValue;
                  }
               },
               {
                              col:4,
                              required:true,
                              beforeContent:(
                                 <MyHeader
                                       className='-mt-3'
                                    title={language.RISK_TYPE}
                                    subTitle={language.SELECT_THE_RISK_FOR_THE_REALIZATION_OF_THE_PROJECT_LOW_MEDIUM_HIGH}
                                 />
                              ),
                              type:'element',
                              name:'type_risk',
                              content:(v, h, submit, error)=>(
                                 <Fragment>
                                    <div>
                                       {[1,2,3].map(e=>(
                                          <i onClick={() => h(e, 'type_risk')} key={e} className={(e==v?'fas fa-star':'far fa-star')+' star-btn type_risk'}/>
                                       ))}
                                    </div>
                                    {error?(
                                       <span className='error-label ml-1'>{error}</span>
                                    ):null}
                                 </Fragment>
                              )
                           },
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.RATING_OF_THIS_PROJECT}
                        subTitle={language.SELECT_HOW_MANY_POINTS_WILL_BE_AWARDED_TO_THIS_PROJECT}
                     />
                  ),
                  type:'element',
                  name:'pontuation',
                  content:(v, h, submit, error)=>(
                     <Fragment>
                        <div>
                           {[1,2,3,4,5].map(e=>(
                              <i 
                                 onClick={() => h(e, 'pontuation')} 
                                 key={e} 
                                 className={classnames('fas fa-trophy trophy-btn', {active:e<=v})}
                              />
                           ))}
                        </div>
                        {error?(
                           <span className='error-label ml-1'>{error}</span>
                        ):null}
                     </Fragment>
                  )
               },
                           {
                              col:4,
                              required:true,
                              beforeContent:(
                                 <MyHeader
                                       className='-mt-3'
                                    title={language.PRIORIZATION}
                                    subTitle={language.SELECT_THE_PRIORITY_FOR_THE_REALIZATION_OF_THE_PROJECT}
                                 />
                              ),
                              type:'element',
                              name:'priorization',
                              content:(v, h, submit, error)=>(
                                 <Fragment>
                                    <div>
                                          {[1,2,3].map(e=>(
                                             <i onClick={() => h(e, 'priorization')} key={e} className={(e<=v?'fas fa-star':'far fa-star')+' star-btn'}/>
                                          ))}
                                    </div>
                                    {error?(
                                       <span className='error-label ml-1'>{error}</span>
                                    ):null}
                                 </Fragment>
                              )
                           },
            ]}
            onChangeField={(f,v)=>{
               h(v, f.name);
            }}
         />
      </div>
   </div>
))