import Button from '@common/Button';

export default function({open, onClick=()=>null}){
	return(
		<Button
			icon={open?'fas fa-chevron-down':'fas fa-chevron-right'}
			onClick={onClick}
			varian='circle'
			color='#BDBDBD'
			outlined
			style={{
				color:'#BDBDBD',
				border:'none'
			}}
		/>
	)
}