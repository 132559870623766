import ReactDOM from 'react-dom';
import { useState, useEffect} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

var imageRef = null;
export default function Crop(props) {

  var fileUrl = '';

  const [src, setSrc] = useState(null);

  const {
    aspect=(9/9), 
    width=30, 
    unit='%',
    changeCrop=()=>null,
    file
  } = props;

  const [crop, setCrop] = useState({
      aspect,
      width,
      unit
  });

  useEffect(() => {
    if(file){
      
      if((typeof file)=='string'){
        setSrc(file);
      }else{
        if (file) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            setSrc(reader.result)
          );
          reader.readAsDataURL(file);
        }
      }
    }
  }, [file]);
 
  function onImageLoaded (image) {
   
    imageRef = image;

  };

  function onCropComplete (c) {
   
    makeClientCrop(c);
  };

  function onCropChange (crop, percentCrop){
   
    
    setCrop(crop);
    // makeClientCrop(crop);
  };

  async function makeClientCrop (crop) {
   
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      changeCrop(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas está vazia');
          return;
        }
        blob.name = fileName;
      
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);

        resolve({
          blob:fileUrl,
          x:crop.x * scaleX,
          y:crop.y * scaleY,
          w:crop.width * scaleX,
          h:crop.height * scaleY
        });
      }, 'image/jpeg');
    });
  }
  return (
    <div className="Crop-sensr">
      {src && file && (
        <ReactCrop
          src={src}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
    </div>
  );
  
}