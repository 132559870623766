import styled, { css } from 'styled-components';

// ----------------------------------------------------------------------

import Button from '@common/Button';

// ----------------------------------------------------------------------

export const ButtonDelete = styled(Button).attrs(({ label }) => ({
  variant: 'circle',
  icon: 'far fa-trash-alt',
  label,
  color: 'danger',
  size: 0.7,
}))`
  ${() => css`
    font-size: 13;
  `}
`;
