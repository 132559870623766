import React, { Fragment, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

import Dialog from '../../common/Dialog';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import DialogConfirm from '../../common/DialogConfirm';

import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

import EventCabForm from './EventCabForm';

import './index.css';

const EventCabContainer = () => {
  const { language } = Store;
  const [page, setPage] = useState('list');
  const [eventCab, setEventCab] = useState('');
  const [deleteItem, setDeleteItem] = useState({});
  const [cabEvents, setCabEvents] = useState([]);

  useEffect(() => {
    loadEventCab();
  }, []);

  const handleForm = (crId) => {
    setEventCab(crId);
    setPage('form');
  };

  const loadEventCab = () => {
    action
      .execute('get', `${Constants.APIEndpoints.EVENT_CAB}`, '')
      .then((res) => {
        setCabEvents(res.data);
      });
  };

  const deleteEventCab = () => {
    action
      .execute('delete', `${Constants.APIEndpoints.EVENT_CAB}/${deleteItem.id}`, '')
      .then((res) => {
        if (!res.data.code) {
          Store.toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
          setDeleteItem({});
          loadEventCab();
          return;
        }
        throw new Error();
      })
      .catch((err) => {
        Store.toggleAlert(true, language.ERROR_CODE['E500'], 'error');
      });
  };

  const handleDelete = (obj) => {
    setDeleteItem(obj);
  };

  const col = [
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 10, overflow: 'auto', textAlign: 'center' },
    },
  ];

  const data = cabEvents.map((c) => ({
    name: c.ec_name,
    action: (
      <Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <RaisedButton
                circleButton
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 12, width: 21, height: 21 }}
                className="mr-2"
                onClick={() => {
                  handleForm(c.ec_id);
                }}
              />
              <RaisedButton
                circleButton
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 13, width: 21, height: 21 }}
                onClick={() => {
                  handleDelete({ id: c.ec_id, name: c.ec_name });
                }}
              />
            </div>
          }
        />
      </Fragment>
    ),
  }));

  return (
    <div>
      <CommonTable
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={[
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              handleForm('');
            }}
          />,
        ]}
      />
      {page === 'form' && (
        <Dialog
          title={language.ADD}
          open={page === 'form'}
          onClose={() => {
            setPage('list');
          }}
          maxWidth="md"
        >
          <EventCabForm
            eventCab={eventCab}
            onClose={() => {
              loadEventCab();
              setPage('list');
            }}
          />
        </Dialog>
      )}
      <DialogConfirm
        open={!!deleteItem.id}
        language={language}
        confirmProps={{ color: 'danger' }}
        onConfirm={deleteEventCab}
        onClose={() => handleDelete({})}
        title={language.CONFIRM_DELETE_ITEM}
        message={deleteItem.name}
      />
    </div>
  );
};

export default EventCabContainer;
