import Store from '../data/Store';

export default (url, file) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': Store.getToken(),
    },
  };

  fetch(url, options)
    .then(async (results) => {
      if (!results.ok) {
        const resultsJson = await results.json();
        let messageError = Store.language.ERROR_REQUEST;
        if (resultsJson?.id_error === 'bf4e7c4f-a3ec-4a56-b87d-0b7b3cf2ab08') {
          messageError = results.message;
        } else if (
          resultsJson?.id_error === 'd16d8f30-ca12-49b3-80bd-643ca8529a4a'
        ) {
          messageError = resultsJson.message;
        }
        throw new Error(messageError);
      } else {
        return await results.blob();
      }
    })
    .then((response) => {
      let data = response;
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(data);
      elm.setAttribute('download', file);
      elm.click();
      elm.remove();
    })
    .catch((error) => {
      Store.toggleAlert(true, error.message, 'error');
    });
};
