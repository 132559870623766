import React from 'react';
/*-------------------------------common--------------------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import { Card } from '@material-ui/core';
import { NewContracts } from './Graphs.js';
import Store from '@data/Store';

export default function ({
  categories,
  consumed,
  realized,
  foreseen,
  contract,
  budgetcontrols,
  year
}) {
  const { language } = Store;
  if (contract) {
    let budget = budgetcontrols.filter(b => b.name == contract.contract[0].name && b.expected_value.length > 0)[0]
    //    realized = contract.realizado.map((b) => b.realized_cost);
     for (let i = 0; i < 12; i++) {
      let value_month = contract.realizado.filter(r => r.month == i + 1 && r.year == year)[0]
      realized[i] = value_month ? parseFloat(value_month.realized_cost) : 0
      let expected_month = budget && budget.expected_value.filter(b => b.year == year && b.month == i + 1)[0]
      foreseen[i] = expected_month ? parseFloat(expected_month.value) : 0
    }
  }
  return (
    <Card id="NewContracts">
      <AppBar
        title={language.VIEW_OF_SELECTED_CONTRACT}
        subTitle={`(${language.CONSUMPTION_OF_FORECAST_REALIZED_AND_CONSUMED})`}
      />
      <div className="content-medium pt-0">
        <ReactHighcharts
          config={NewContracts({
            categories,
            consumed,
            realized,
            foreseen
          })}
        />
      </div>
    </Card>
  );
}
