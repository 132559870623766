import React, { Component } from 'react';
import './style.css';
import classnames from 'classnames';
import { List as L, ListItem, Grid } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { flexBasis } from '@common/Func';

export default class List extends Component {
	render() {
		let {
			items,
			hover,
			itemsProps,
			col,
			h,
			mh,
			mx,
			GridContainerProps,
			className,
			style,
			scale,
			scrollProps = {},
			scroll = true,
			...other
		} = this.props;

		let Content = scroll ? PerfectScrollbar : 'div';
		return (
			<Content
				{...scrollProps}
				style={{
					...(scroll ? {
						height: h || 'auto',
						maxHeight: h ? 'auto' : (mh || 500),
						maxWidth: mx || 'auto',
						...scrollProps.style
					} : { ...scrollProps.style })
				}}
			>
				<L
					{...other}
					className={
						classnames(
							'list-23-sensr',
							{
								scale: [undefined, true].includes(scale)
							},
							className
						)
					}
					style={{ margin: 0, ...style }}>
					{items && items.map((item, i) => (
						[undefined, true].includes(item.visible) ? (
							<ListItem
								{...itemsProps}
								{...item}
								className={
									classnames(
										'listItem',
										(itemsProps ? itemsProps.className : ''),
										item.className,
										{ hover: [undefined, true].includes(hover) },
									)
								}
								dense button key={i}
							>

								{
									item.content ||
									(
										<Grid container spacing={3} {...GridContainerProps}>
											{(item.grid || []).map((e, i) => (
												<Grid
													item xs={e.col || col || 12}
													{...e}
													style={{ ...flexBasis(e.col || col || 12), ...(e.style || {}) }}
												>
													{e.content}
												</Grid>
											))}
										</Grid>
									)
								}
							</ListItem>
						) : null
					))}
				</L>
			</Content>
		)
	}
}