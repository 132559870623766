import React, { useEffect, useState, memo } from 'react';
import { Row, Col } from 'react-grid-system';

import Store from '@data/Store';
import Actions from '@data/Actions';

import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import DialogConfirm from '@common/DialogConfirm';

import { Checkbox } from '@material-ui/core';

const action = new Actions();

const groupTypes = {
  1: 'Grupo de Técnicos',
  2: 'Grupo de Solicitantes',
  3: 'Grupo de Aprovadores',
  4: 'Grupo de Notificações'
};

const colGroup = [
  { key: 'check', label: '', style: { width: 10 } },
  { key: 'id_group_users', label: Store.language.ID, style: { width: 10 } },
  { key: 'name', label: Store.language.NAME, style: { textAlign: 'center' } },
  {
    key: 'description',
    label: Store.language.DESCRIPTION,
    style: { textAlign: 'center' },
  },
  {
    key: 'name_company',
    label: Store.language.COMPANY,
    style: { textAlign: 'center' },
  },
  {
    key: 'type',
    label: Store.language.LEVEL,
    style: { textAlign: 'center' },
    thConfig: true,
  },
];

const AddGroupDialog = ({
  addGroups,
  callPageSelectFilter,
  countGroups,
  groups,
  language,
  onClose,
}) => {
  const [filtered, setFiltered] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);

  useEffect(() => {
    callPageSelectFilter(0, {})
  }, [])

  const selectGroup = (groupId) => {
    let newGroups = Array.from(checkedGroups);
    if (!newGroups.includes(groupId)) {
      newGroups.push(groupId);
    } else {
      newGroups = newGroups.filter((c) => c !== groupId);
    }
    setCheckedGroups(newGroups);
  };

  const CheckField = ({ groupId }) => {
    return (
      <Checkbox
        checked={checkedGroups.includes(groupId)}
        onChange={() => selectGroup(groupId)}
      />
    );
  };

  return (
    <Dialog modal={false} open={true} onClose={onClose} maxWidth="md">
      <CommonTable
        beginWithNoFilter={true}
        language={language}
        columns={colGroup}
        data={groups.map((fg) => ({
          check: <CheckField groupId={fg.id_group_users} />,
          id_group_users: fg.id_group_users,
          name: fg.name,
          description: fg.description,
          name_company: fg.name_company,
          type: groupTypes[fg.type],
        }))}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
        searchColumnEvent={(v) => callPageSelectFilter(0, v, 75, 'filter')}
        countTotal={countGroups}
        isAutomaticPagination={false}
        searchColumn={true}
        hideFooterToolbar
      />
      <Row style={{ marginTop: 10 }}>
        <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ float: 'right' }}>
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              label={language.ADD}
              primary={true}
              fullWidth
              onClick={() => addGroups(checkedGroups)}
            />
          </div>
        </Col>
      </Row>
    </Dialog>
  );
};

const GroupUser = ({ APIEndpoints, language, values, onClose }) => {
  const defaultPageDelete = { open: false, values: {} };
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [countGroups, setCountGroups] = useState(0);
  const [userGroups, setUserGroups] = useState([]);
  const [pageDelete, setPageDelete] = useState(defaultPageDelete);

  useEffect(() => {
    getGroups();
  }, []);

  const handleModal = (open) => {
    setOpen(open);
  };

  const getGroups = () => {
    action
      .execute('get', `${APIEndpoints.USER}/${values.id_user}/groups`)
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        setUserGroups(
          data.map((d) => ({
            id_group_users: d.id_group_users,
            name: d.name,
            description: d.description,
            name_company: d.name_company,
            type: groupTypes[d.type],
            remove: (
              <RaisedButton
                circleButton
                label={language.REMOVE}
                color="danger"
                icon="far fa-trash-alt"
                onClick={() =>
                  handlePageDelete({
                    open: true,
                    values: {
                      id_rel_group_users: d.id_rel_group_users,
                      title: d.name,
                    },
                  })
                }
                style={{ width: 21, height: 21, fontSize: 13 }}
              />
            ),
          }))
        );
      });
  };

  const col = [
    { key: 'id_group_users', label: language.ID, style: { width: 10 } },
    { key: 'name', label: language.NAME, style: { textAlign: 'center' } },
    {
      key: 'description',
      label: language.DESCRIPTION,
      style: { textAlign: 'center' },
    },
    {
      key: 'name_company',
      label: language.COMPANY,
      style: { textAlign: 'center' },
    },
    {
      key: 'type',
      label: language.LEVEL,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'remove',
      label: language.REMOVE,
      style: { width: 40, textAlign: 'center' },
      jc: 'center',
    },
  ];

  const callPageSelectFilter = (page, items, rowPerPage = 75, type) => {
    const limit = rowPerPage;

    let offset = type != 'filter' ? page * limit : 0;

    action
      .execute('post', `${APIEndpoints.GROUP}/filter?limit=${limit}&offset=${offset}&role=${values.role}`, '', items)
      .then((data) => {
        setCountGroups(data.totalCount);

        if ( data.data ) {
          data = data.data;
        }

        const pageGroups = data.filter(
          (group) =>
            !userGroups
              .map((group) => group.id_group_users)
              .includes(group.id_group_users)
        );

        setGroups(pageGroups);
      });
  };

  const addGroups = (checkedGroups) => {
    if (checkedGroups.length) {
      let payload = {
        id_user: values.id_user,
        groups: checkedGroups,
      };

      action
        .execute('post', `${APIEndpoints.USER}/${values.id_user}/group`, '', payload)
        .then((res) => {
          getGroups();
          handleModal(false);
        });
    }
  };

  const removeGroup = () => {
    action
      .execute('delete', `${APIEndpoints.USER}/${values.id_user}/group`, '', pageDelete.values)
      .then((res) => {
        getGroups();
        handlePageDelete(defaultPageDelete);
      });
  };

  const handlePageDelete = (data) => {
    setPageDelete(data);
  };

  return (
    <Dialog
      modal={false}
      open={true}
      onClose={onClose}
      maxWidth="md"
      title={language.GROUP_LIST}
    >
      <CommonTable
        data={userGroups}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            circleButton
            icon="fas fa-plus"
            label={language.ADD_GROUP}
            onClick={() => handleModal(true)}
          />
        }
      />
      {open && (
        <AddGroupDialog
          callPageSelectFilter={callPageSelectFilter}
          countGroups={countGroups}
          groups={groups}
          language={language}
          addGroups={addGroups}
          onClose={() => handleModal(false)}
        />
      )}
      {pageDelete.open && (
        <DialogConfirm
          open={pageDelete.open}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={removeGroup}
          onClose={() => handlePageDelete(defaultPageDelete)}
          title={language.CONFIRM_DELETE_ITEM}
          message={pageDelete.values.title}
        />
      )}
    </Dialog>
  );
};

export default GroupUser;
