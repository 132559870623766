import React, { useCallback, useState, useEffect } from 'react';
/*-------------- commons -------------*/
import Dialog from '@common/Dialog';
import BarBlue from '@images/BarBlue.png';
import { formatCurrency } from '../../../../util';
import Store from '@data/Store';
import CommonTable from '@common/CommonTable';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import { Tree } from './Tree';

const action = new Actions();

const { language } = Store;

export default ({ projectId, open, onClose }) => {
  const [contractsData, setContractsData] = useState([]);
  const [show, setShow] = useState({});

  const findContractsFromProject = () => {
    return action.execute('get', Constants.APIEndpoints.PROJECTS + `/cost/contracts/${projectId}`)
      .then(response => response.data)
      .catch(err => console.error(err))
  }

  const findActivitiesByProjectAndContract = (contractId) => {
    return action.execute('get', Constants.APIEndpoints.PROJECTS + `/cost/activity/${projectId}/${contractId}`)
      .then(response => response.data)
      .catch(err => console.error(err))
  }

  const findUserActivityByProjectContractAndActivity = (contractId, activityId) => { 
    return action.execute('get', Constants.APIEndpoints.PROJECTS + `/cost/users/activity/${projectId}/${contractId}/${activityId}`)
      .then(response => response.data)
      .catch(err => console.error(err))
  }

  const buildContractsFromProject = () => { 
    findContractsFromProject().then((result) => {
      const data = result.reduce((result, item) => { 
        result[item.contract_id] = {
          ...item,
          hours_consumed: item.hours_consumed?.toFixed(2),
          value_consumed: formatCurrency(item.value_consumed)
        };
        return result;
      }, {});
      setContractsData(data);
    });
  }

  const buildActivitiesByProjectAndContract = (contractId) => { 
    findActivitiesByProjectAndContract(contractId).then((resultData) => {
      const data = resultData.reduce((result, item) => {
        const key = item.activity_id;
        if (!result[contractId].activities) {
          result[contractId].activities = {};
        }
        result[contractId].activities[key] = {
          ...item,
          hours_consumed: item.hours_consumed?.toFixed(2).replace('.', ':') + 'h',
          value_consumed: formatCurrency(item.value_consumed),
          users: []
        };
        return result;
      }, { ...contractsData });
      setContractsData(data);
    });
  }

  const buildUserActivityByProjectContractAndActivity = (contractId, activityId) => {
    findUserActivityByProjectContractAndActivity(contractId, activityId).then((resultData) => {
      const resultBase = { ...contractsData, };
      resultBase[contractId].activities[activityId].users = [];
      
      const data = resultData.reduce((result, item) => { 
        result[contractId].activities[activityId].users.push({
          ...item,
          hours_consumed: item.hours_consumed?.toFixed(2).replace('.', ':') + 'h',
          value_consumed: formatCurrency(item.value_consumed)
        });
        return result;
      }, { ...resultBase,  });
      setContractsData(data);
    });
  }

  const buildTreeActivitiesUsers = (contractKey, activityKey) => {
    const header = [
      <div />
    ];

    const contractData = contractsData[contractKey].activities || {};
    const activitiesData = contractData[activityKey] || {};
    const usersData = activitiesData.users || [];
    const resultData = usersData.map((item) => {
      return {
        row: [(
          <CommonTable
            pagination={false}
            bar={false}
            className='mt-2 table-tec'
            col={[
              { key: 'person_name', label: language.PARTICIPANT, thConfig: true, style: { width: '50%' } },
              { key: 'hours_consumed', label: language.TIME_CONSUMED, thConfig: true, style: { width: '25%' } },
              { key: 'value_consumed', label: language.CONSUMED_VALUE, thConfig: true, style: { width: '25%' } }
            ]}
            AppBarProps={{
              dark: true,
              className: 'py-0 pr-0',
              style: {
                borderRadius: '10px 10px 0 0'
              }
            }}
            data={[{
              person_name: item.person_name,
              hours_consumed: item.hours_consumed,
              value_consumed: item.value_consumed
            }]}
            key="person_name"
          />
        )],
      };
    });

    return (
      <Tree
        header={header}
        data={resultData}
      />
    );
  }

  const buildTreeActivities = (contractKey) => { 
    const header = [
      <div />
    ];

    const contractData = contractsData[contractKey].activities || {};
    const resultData = Object.keys(contractData).reduce((result, key) => {
      result.push({
        row: [(
          <CommonTable
            pagination={false}
            bar={false}
            className='mt-2 table-tec'
            col={[
              { key: 'btn', label: '', thConfig: true, style: { width: '5%' } },
              { key: 'activity_name', label: language.ACTIVITY, thConfig: true, style: { width: '45%' } },
              { key: 'hours_consumed', label: language.TIME_CONSUMED, thConfig: true, style: { width: '25%' } },
              { key: 'value_consumed', label: language.CONSUMED_VALUE, thConfig: true, style: { width: '25%' } }
            ]}
            AppBarProps={{
              dark: true,
              className: 'py-0 pr-0',
              style: {
                borderRadius: '10px 10px 0 0'
              }
            }}
            data={[
              {
                btn: (
                  <div className='btn'>
                    <i className={show[key] ? 'fas fa-chevron-down' : 'fas fa-chevron-right'} />
                  </div>
                ),
                activity_name: contractData[key].activity_name,
                hours_consumed: contractData[key].hours_consumed,
                value_consumed: contractData[key].value_consumed
              },
            ]}
            key="activity_name"
          />
        )],
        content: buildTreeActivitiesUsers(contractKey, key),
        onClick: () => {
          setShow({ ...show, [key]: !!key });
          buildUserActivityByProjectContractAndActivity(contractKey, key);
        }
      });
      return result;
    }, []);
    
    return (
      <Tree
        header={header}
        data={resultData}
      />
    );
  }

  const buildTreeMain = useCallback(() => {
    const header = [
      <div />,
    ];

    const treeData = Object.keys(contractsData).map((key) => {
      return {
        row: [(
          <CommonTable
            pagination={false}
            bar={false}
            className='mt-2 table-tec'
            col={[
              { key: 'btn', label: '', thConfig: true, style: { width: '5%' } },
              { key: 'contract_name', label: language.CONTRACT, thConfig: true, style: { width: '45%' } },
              { key: 'coin_name', label: language.COIN, thConfig: true, style: { width: '25%' } },
              { key: 'value_consumed', label: language.AMOUNT, thConfig: true, style: { width: '25%' } }
            ]}
            AppBarProps={{
              dark: true,
              className: 'py-0 pr-0',
              style: {
                borderRadius: '10px 10px 0 0'
              }
            }}
            data={[{
              btn: (
                <div className='btn'>
                  <i className={show[key] ? 'fas fa-chevron-down' : 'fas fa-chevron-right'} />
                </div>
              ),
              contract_name: contractsData[key].contract_name,
              coin_name: contractsData[key].coin_name,
              value_consumed: contractsData[key].value_consumed,
            }]}
            key="contract_name"
          />
        )],
        content: buildTreeActivities(key),
        onClick: () => {
          setShow({ ...show, [key]: !!key });
          buildActivitiesByProjectAndContract(key);
        }
      };
    });

    return (
      <Tree
        header={header}
        data={treeData}
      />
    );
  }, [contractsData]);

  useEffect(buildContractsFromProject, []);

  return (
    <Dialog
      PaperProps={{
        id: 'DialogModalValueRealized'
      }}
      open={open}
      onClose={onClose}
      bar={false}
      maxWidth='lg'
      contentProps={{
        style:{
          background:'white'
        }
      }}
      dialogChildren={
        <div
          className="d-flex align-items-start justify-content-end bar"
          style={{
            background: `url(${BarBlue})`,
            borderBottom: '3px solid var(--warning)'
          }}
        >
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      }
    >
      {buildTreeMain()}
    </Dialog>
  );
}