import React, { useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // needed for dayClick
import timeGrid from '@fullcalendar/timegrid';
import {
  Card,
  Grid,
  MenuItem,
  Divider,
  Select as MuiSelect,
  InputBase,
  Button,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {
  makeStyles,
  withStyles,
  useTheme,
  styled,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import { Tooltip } from 'react-tippy';

import Select from 'react-select';
import { useState } from 'react';
import moment from 'moment';

import { NewTask } from './NewTask';

import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

var randomColor = require('randomcolor'); // import the script
import './styles.css';

const optionStatus = [
  { value: 'AGUARDANDO', id: 'AGUARDANDO' },
  { value: 'INICIADO', id: 'INICIADO' },
  { value: 'ANDAMENTO', id: 'ANDAMENTO' },
  { value: 'CONCLUIDO', id: 'CONCLUIDO' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > span': {
      margin: theme.spacing(2),
    },
    justifyContent: 'center',
  },
  button: {
    height: 30,
    margin: 10,
    justifyContent: 'center',
    outline: 'none',
    '& focus': {
      outline: 'none',
    },
  },
  icon: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: { minWidth: '500px' },
}));

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: '1px solid #019fc2', // default border color
    boxShadow: 'none', // no box-shadow
    marginLeft: 10,
    zIndex: 9,
  }),
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: '#039FC1',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
  },
}))(Button);

const CustomSelect = styled(MuiSelect)({
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 36,
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    background: '#FFF',
    border: '1px solid #019FC2',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#019FC2',
      background: '#FFF',
    },
  },
}))(InputBase);

export default function CalendarTask({
  type,
  eventTask,
  reload,
  task,
  dateFilter,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [openChangeStatus, setOpenChangeStatus] = useState(false);

  let { language, host, getUserLoged } = Store;
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarComponentRef = useRef();
  const [monthSelected, setMonthSelected] = useState('-1');
  const [yearSelected, setYearSelected] = useState('-1');
  const [daySelected, setDaySelected] = useState('-1');
  const [typeCalendar, setTypeCalendar] = useState(type);
  const [dayOptions, setDayOptions] = useState([]);
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [events, setEvents] = useState([]);
  const [openEvent, setOpenEvent] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const [predecessors, setPredecessors] = useState([]);
  const [involved, setInvolved] = useState([]);
  const [responsible, setResponsible] = useState([]);
  const [statusSelected, setStatusSelected] = useState();
  const [canEdit, setCanEdit] = useState(false);

  const [editTask, setEditTask] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [update, setUpdate] = useState(false);

  const [selectedYear, setSelectedYear] = useState({
    value: -1,
    label: language.YEAR,
  });
  const [selectedDay, setSelectedDay] = useState({
    value: -1,
    label: language.DAY,
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: -1,
    label: language.MONTH,
  });

  const options = [
    { value: '-1', label: language.MONTH },
    { value: 1, label: language.DATE_LANG.monthLongList[0] },
    { value: 2, label: language.DATE_LANG.monthLongList[1] },
    { value: 3, label: language.DATE_LANG.monthLongList[2] },
    { value: 4, label: language.DATE_LANG.monthLongList[3] },
    { value: 5, label: language.DATE_LANG.monthLongList[4] },
    { value: 6, label: language.DATE_LANG.monthLongList[5] },
    { value: 7, label: language.DATE_LANG.monthLongList[6] },
    { value: 8, label: language.DATE_LANG.monthLongList[7] },
    { value: 9, label: language.DATE_LANG.monthLongList[8] },
    { value: 10, label: language.DATE_LANG.monthLongList[9] },
    { value: 11, label: language.DATE_LANG.monthLongList[10] },
    { value: 12, label: language.DATE_LANG.monthLongList[11] },
  ];

  const optionsYear = [
    { value: -1, label: language.YEAR },
    { value: 2022, label: '2022' },
    { value: 2021, label: '2021' },
    { value: 2020, label: '2020' },
    { value: 2019, label: '2019' },
    { value: 2018, label: '2018' },
  ];

  useEffect(() => {
    if (dateFilter && dateFilter.dt_ini) {
      let calendarApi = calendarComponentRef.current.getApi();
      let date = moment(dateFilter.dt_ini).format('YYYY-MM-DD');
      calendarApi.changeView('dayGridMonth');
      calendarApi.gotoDate(date);
    }
  }, [dateFilter]);

  useEffect(() => {
    let dptoColor = [];
    let groupColor = [];
    if (task) {
      handleEventClick(task);
    }
    const lastDayMonth = moment().endOf('month').format('DD');
    let days = [];

    for (var i = 1; i <= lastDayMonth; i++) {
      if (String(i).length == 1) {
        days.push({ value: `0${i}`, label: `0${i}` });
      } else {
        days.push({ value: `${i}`, label: `${i}` });
      }
    }

    setDayOptions(days);

    action
      .execute('get', `${Constants.APIEndpoints.TASKS}`, '')
      .then((res) => {
        if ( res.data ) {
          res = res.data;
        }
        setEvents([]);
        let resMap = [];
        res.map((re) => {
          let color;
          if (re.role == 'solicitant') {
            const dpto = dptoColor.find((dp) => dp.id == re.fk_id_department);
            if (dpto) {
              color = dpto.color;
            } else {
              color = randomColor();
              dptoColor.push({
                id: re.fk_id_department,
                color,
              });
            }
          } else if (re.role == 'tech') {
            const exist = false;
            let findGroup;
            groupColor.filter((g1) => {
              g1.ids.map((g) => {
                if (re.group.includes(g)) {
                  findGroup = g1;
                }
              });
            });
            if (findGroup) {
              color = findGroup.color;
            } else {
              color = randomColor();
              groupColor.push({
                ids: re.group,
                color,
              });
            }
          } else {
            color = '#0d68ad';
          }

          resMap.push({
            start: re.dt_start,
            end: moment(re.dt_start).add(re.time_execution, 'hours').toDate(),
            title: re.name,
            id: re.id_tasks,
            backgroundColor: color,
            time_execution: re.time_execution,
            status: re.status,
          });
        });
        setEvents(resMap);
      });

    if (reload || update) {
      let calendarApi = calendarComponentRef.current.getApi();
      calendarApi.changeView('dayGridMonth');
      setTypeCalendar('dayGridMonth');
    }
  }, [reload, update]);

  useEffect(() => {
    let dptoColor = [];
    let groupColor = [];
    if (eventTask) {
      let resMap = [];
      eventTask.map((re) => {
        let color;
        if (re.role == 'solicitant') {
          const dpto = dptoColor.find((dp) => dp.id == re.fk_id_department);
          if (dpto) {
            color = dpto.color;
          } else {
            color = randomColor();
            dptoColor.push({
              id: re.fk_id_department,
              color,
            });
          }
        } else if (re.role == 'tech') {
          const exist = false;
          let findGroup;
          groupColor.filter((g1) => {
            g1.ids.map((g) => {
              if (re.group.includes(g)) {
                findGroup = g1;
              }
            });
          });
          if (findGroup) {
            color = findGroup.color;
          } else {
            color = randomColor();
            groupColor.push({
              ids: re.group,
              color,
            });
          }
        } else {
          color = '#0d68ad';
        }

        resMap.push({
          start: re.dt_start,
          end: moment(re.dt_start).add(re.time_execution, 'hours').toDate(),
          title: re.name,
          id: re.id_tasks,
          backgroundColor: color,
          time_execution: re.time_execution,
          status: re.status,
        });
      });

      setEvents(resMap);
    }
  }, [eventTask, typeCalendar]);

  const handleEventClick = (arg) => {
    setOpenEvent(true);
    const id = arg.event ? arg.event.id : arg[0].id_tasks;

    action
      .execute('get', `${Constants.APIEndpoints.TASKS}/${id}`, '')
      .then((res) => {
        if ( res.data ) {
          res = res.data;
        }
        const ticketInfo = res.tasks[0].fk_id_tickets
          ? {
              id: res.tasks[0].fk_id_tickets,
              value: `${res.ticketInfo.real_id} - ${res.ticketInfo.subject}`,
            }
          : null;

        setTicketDetails({
          predecessors: res,
          id: id,
          title: arg.event ? arg.event.title : arg[0].name,
          start: arg.event ? arg.event.start : arg[0].dt_start,
          time_execution: arg.event
            ? arg.event.extendedProps.time_execution
            : arg[0].time_execution,
          status: res.tasks[0].status,
          date_end: res.tasks[0].date_end,
          id_creator: res.tasks[0].id_creator,
          tasksInfo: res.tasks[0],
          idTicket: ticketInfo,
          real_id: res.ticketInfo.real_id,
        });

        setPredecessors(res.predecessors);

        const existOpenTask = res.predecessors.find((p) => {
          return p.status != 'CONCLUIDO';
        });

        setInvolved(res.involved);
        setResponsible(res.responsible);

        const id_user = getUserLoged().id;

        if (existOpenTask) {
          setCanEdit(false);
        } else {
          const canEdit = res.responsible.some(
            (item) => item.id_user == id_user
          );
          if (canEdit || getUserLoged().role == 'client') {
            setCanEdit(true);
          }
        }
      });
  };

  function removeTask() {
    action
      .execute('delete', `${Constants.APIEndpoints.TASKS}/${ticketDetails.id}`, '')
      .then((e) => {
        setOpenEvent(false);
        setRemoveDialog(false);
        setUpdate(true);
      });
  }

  const handleClose = () => {
    setOpenEvent(false);
    setEditTask(false);
  };

  const handleChangeMonth = (event) => {
    let date;
    let day = moment().format('DD');
    let year = moment().format('YYYY');

    let calendarApi = calendarComponentRef.current.getApi();

    if (yearSelected.length >= 4) {
      year = yearSelected;
    }

    let month = event.value;
    if (daySelected > -1) {
      day = daySelected;
      setTypeCalendar('timeGridDay');
      calendarApi.changeView('timeGridDay');
    } else {
      setTypeCalendar('dayGridMonth');
      calendarApi.changeView('dayGridMonth');
    }

    date = `${year}-${month.toString().padStart(2, '0')}-${day}`;
    setMonthSelected(event.value);

    //  setTypeCalendar('dayGridMonth');
    //  calendarApi.changeView('dayGridMonth');

    calendarApi.gotoDate(date);
  };

  const handleChangeYear = (event) => {
    let date;
    let day = moment().format('DD');
    let month = moment().format('MM');
    let year = moment().format('YYYY');

    let calendarApi = calendarComponentRef.current.getApi();

    year = event.value;
    setSelectedYear({ value: event.value, label: event.label });

    if (monthSelected > -1) {
      month = monthSelected;
    }
    if (daySelected > -1) {
      day = daySelected;
      setTypeCalendar('timeGridDay');
      calendarApi.changeView('timeGridDay');
    } else {
      setTypeCalendar('dayGridMonth');
      calendarApi.changeView('dayGridMonth');
    }

    date = `${year}-${month.toString().padStart(2, '0')}-${day}`;

    setYearSelected(event.value);
    calendarApi.gotoDate(date);
  };

  const handleChangeDay = (event) => {
    let date;
    let day = moment().format('DD');
    let month = moment().format('MM');
    let year = moment().format('YYYY');

    let calendarApi = calendarComponentRef.current.getApi();

    if (yearSelected > -1) {
      year = yearSelected;
    }

    if (monthSelected > -1) {
      month = monthSelected;
    }

    day = event.value;
    setSelectedDay({ value: event.value, label: event.label });
    date = `${year}-${month.toString().padStart(2, '0')}-${day}`;
    setDaySelected(event.value);
    setTypeCalendar('timeGridDay');
    calendarApi.changeView('timeGridDay');

    calendarApi.gotoDate(date);
  };

  const addMonth = () => {
    const newDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
    setCurrentDate(newDate);

    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.changeView('timeGridDay');

    calendarApi.gotoDate(newDate);
  };

  const removeMonth = () => {
    const newDate = moment(currentDate)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    setCurrentDate(newDate);
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.changeView('timeGridDay');

    calendarApi.gotoDate(newDate);
  };

  const handleChangeStatus = () => {
    setOpenChangeStatus(true);
  };

  const handleSelect = (event) => {
    setStatusSelected(event.target.value);
  };

  const submitStatus = () => {
    action
      .execute('put', `${Constants.APIEndpoints.TASKS}/status/${ticketDetails.id}`, '', {
        status: statusSelected,
      })
      .then((e) => {
        if( e.data ) {
          e = e.data;
        }

        setOpenChangeStatus(false);
        setOpenEvent(false);
        const findEvent = events.find((e) => e.id == ticketDetails.id);
        findEvent.status = 'Teste';

        const newEvent = events.map((f) =>
          f.id == ticketDetails.id ? findEvent : f
        );

        setEvents(newEvent);
      })
      .catch((err) => console.log(err));
  };

  function finish(dateFromChild) {
    setEditTask(false);
    setOpenEvent(false);
    setUpdate(true);
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        className={classes.paper}
        open={openEvent}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            background: '#2d758a',
            color: '#FFF',
            textAlign: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Typography variant="h6">
              {' '}
              {ticketDetails.title} - {ticketDetails.id}{' '}
            </Typography>
          </DialogTitle>

          <CloseIcon
            style={{ marginRight: '20px' }}
            onClick={() => setOpenEvent(false)}
          />
        </div>
        <DialogContent>
          <DialogContentText>
            <span>
              Início:{' '}
              <strong>
                {moment(ticketDetails.start_time).format('DD/MM/YYYY HH:mm:ss')}
              </strong>
            </span>
            <br />
            <span>
              {language.RUNTIME}: {ticketDetails.time_execution}
            </span>
            {ticketDetails.tasksInfo &&
              ticketDetails.tasksInfo.fk_id_tickets && (
                <>
                  <br />
                  <span>
                    {language.ASSOCIATE_TICKET}:{' '}
                    <strong>{ticketDetails.real_id}</strong>
                  </span>
                </>
              )}
            <br />

            <div style={{ display: 'flex' }}>
              <span>
                {language.STATUS}: <strong>{ticketDetails.status}</strong>
              </span>
              {ticketDetails.status != 'CONCLUIDO' && canEdit && (
                <EditIcon
                  style={{ marginLeft: '5px' }}
                  onClick={handleChangeStatus}
                />
              )}

              <br />
            </div>

            <br />
            {ticketDetails.date_end && (
              <span>
                Fim:{' '}
                <strong>
                  {moment(ticketDetails.date_end).format('DD/MM/YYYY HH:mm')}
                </strong>
              </span>
            )}

            <Divider />
            <br />

            <>
              <h6>
                <strong>{language.DESCRIPTION}</strong>
              </h6>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    ticketDetails.tasksInfo &&
                    ticketDetails.tasksInfo.description,
                }}
              ></span>
            </>

            <Divider />
            <br />
            {predecessors && (
              <>
                <h6>
                  <strong>{language.PREDECESSOR_TASKS}</strong>
                </h6>
                {predecessors.map((pred) => {
                  return (
                    <>
                      <span>{pred.name}</span> - <strong>{pred.status}</strong>
                    </>
                  );
                })}
              </>
            )}
            <br />
            <Divider />
            <br />
            {involved && (
              <>
                <h6>
                  <strong>{language.INVOLVED_PLURAL}</strong>
                </h6>
                <div style={{ display: 'flex !important' }}>
                  {involved.map((inv) => {
                    return (
                      <Tooltip position="bottom" title={inv.name}>
                        <div
                          style={{
                            borderRadius: '50%',
                            background: '#DDD',
                            padding: '10px',
                            width: '50px',
                            height: '50px',
                            textAlign: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: '5px',
                          }}
                        >
                          {inv.name
                            .split(' ')
                            .map((n) => n[0].toUpperCase())
                            .join(' ')}
                        </div>

                        <span></span>
                      </Tooltip>
                    );
                  })}
                </div>
              </>
            )}
            <br />
            <Divider />
            <br />
            {responsible && (
              <>
                <h6>
                  <strong>{language.RESPONSIBLES}</strong>
                </h6>
                <div style={{ display: 'flex' }}>
                  {responsible.map((item) => {
                    return (
                      <Tooltip position="bottom" title={item.name}>
                        <div
                          style={{
                            borderRadius: '50%',
                            background: '#DDD',
                            padding: '10px',
                            width: '50px',
                            height: '50px',
                            textAlign: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {item.name
                            .split(' ')
                            .map((n) => n[0].toUpperCase())
                            .join(' ')}
                        </div>

                        <span></span>
                      </Tooltip>
                    );
                  })}
                </div>
              </>
            )}
            <Divider />
            {ticketDetails.status != 'CONCLUIDO' && (
              <div style={{ display: 'flex' }}>
                <Button
                  style={{
                    background: '#2d8a65',
                    marginTop: '13px',
                    color: '#FFF',
                    outline: 'none',
                  }}
                  onClick={() => setEditTask(true)}
                >
                  {language.EDIT}
                </Button>

                <Button
                  style={{
                    background: 'rgb(138 45 45)',
                    marginTop: '13px',
                    color: '#FFF',
                    marginLeft: '5px',
                    outline: 'none',
                  }}
                  onClick={() => setRemoveDialog(true)}
                >
                  {language.TO_REMOVE}
                </Button>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={removeDialog}>
        <div
          style={{
            color: '#FFF',
            background:
              ' linear-gradient(89deg, rgb(1, 194, 179) 0%, rgb(1, 159, 194) 100%) ',
          }}
        >
          <DialogTitle>{language.DO_YOU_WANT_REMOVE_THIS_ITEM}</DialogTitle>
        </div>
        <DialogContent>{ticketDetails.title}</DialogContent>
        <div style={{ display: 'flex' }}>
          <Button
            size="small"
            style={{
              background: 'rgb(138 45 45)',
              color: '#FFF',
              outline: 'none',
              margin: 10,
              width: 100,
            }}
            onClick={() => removeTask()}
          >
            {language.TO_REMOVE}
          </Button>
          <Button
            size="small"
            style={{
              background: 'rgb(49 161 193)',
              color: '#FFF',
              outline: 'none',
              margin: 10,
              width: 100,
            }}
            onClick={() => setRemoveDialog(false)}
          >
            {language.CANCEL}
          </Button>
        </div>
      </Dialog>

      {editTask && (
        <NewTask
          ticketDetails={ticketDetails}
          openDefault={true}
          reload={finish}
          language={language}
        />
      )}

      <Dialog
        open={openChangeStatus}
        className={classes.paper}
        onClose={() => setOpenChangeStatus(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h6">{language.CHANGE_STATUS}</Typography>
        </DialogTitle>
        <DialogContent>
          <CustomSelect
            fullWidth
            variant="outlined"
            value={statusSelected}
            onChange={handleSelect}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            input={<BootstrapInput />}
          >
            {optionStatus.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </CustomSelect>
          <br />
          <Button
            style={{ marginTop: 10, float: 'right' }}
            onClick={submitStatus}
            variant="contained"
            color="primary"
          >
            {language.SAVE}
          </Button>
        </DialogContent>
      </Dialog>

      <Grid container spacing={2} style={{ marginTop: 80 }}>
        <ColorButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={removeMonth}
          startIcon={<ArrowBackIosIcon className={classes.icon} />}
        ></ColorButton>
        <ColorButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => {
            let calendarApi = calendarComponentRef.current.getApi();
            const dt = moment().format('YYYY-MM-DD');
            const date = moment();
            calendarApi.changeView('timeGridDay');

            setSelectedYear({
              value: date.format('YYYY'),
              label: optionsYear.filter(
                (o) => o.value == date.format('YYYY')
              )[0].label,
            });
            setSelectedMonth({
              value: date.format('M'),
              label: options.filter((o) => o.value == date.format('M'))[0]
                .label,
            });
            setSelectedDay({
              value: date.format('DD'),
              label: dayOptions.filter((o) => o.value == date.format('DD'))[0]
                .label,
            });

            calendarApi.gotoDate(dt);
          }}
        >
          {language.TODAY}
        </ColorButton>
        <ColorButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={addMonth}
          startIcon={<ArrowForwardIos />}
        ></ColorButton>
        <Grid item container xs={8}>
          <Select
            className="select"
            width="200px"
            value={selectedDay}
            styles={customStyles}
            placeholder={language.DAY}
            onChange={handleChangeDay}
            options={dayOptions}
            theme={(theme) => ({
              ...theme,
              boxShadow: 'none',
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: '#019fc2',
                primary: '#019fc2',
              },
              zIndex: 9,
            })}
          />
          <Select
            className="select"
            width="200px"
            value={selectedMonth}
            styles={customStyles}
            placeholder={language.MONTH}
            onChange={handleChangeMonth}
            options={options}
            theme={(theme) => ({
              ...theme,
              boxShadow: 'none',
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: '#019fc2',
                primary: '#019fc2',
              },
              zIndex: 9,
            })}
          />
          <Select
            className="select"
            width="200px"
            placeholder={language.YEAR}
            styles={customStyles}
            value={selectedYear}
            theme={(theme) => ({
              ...theme,
              boxShadow: 'none',
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: '#019fc2',
                primary: '#019fc2',
              },
              zIndex: 9,
            })}
            onChange={handleChangeYear}
            options={optionsYear}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <div id="Calendar">
        <div id="content-calendar" className="mt-4">
          <FullCalendar
            ref={calendarComponentRef}
            defaultView={typeCalendar}
            plugins={[dayGridPlugin, timeGrid]}
            allDaySlot={false}
            buttonText={{
              today: language.TODAY,
            }}
            eventClick={(arg) => handleEventClick(arg)}
            events={events}
            columnHeaderFormat={{ weekday: 'long' }}
            columnHeaderText={(date) =>
              moment.weekdays().map((e) => e.replace('-feira', ''))[
                date.getDay()
              ]
            }
            titleFormat={{ year: 'numeric', month: 'long' }}
            locale="pt-br"
          />
        </div>
      </div>
    </>
  );
}
