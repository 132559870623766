import React, { useState } from 'react';
import Store from '@data/Store';
import { TextField, Typography } from '@material-ui/core';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';
import { spFetch } from '../helpers';
import Constants from '../../../data/Constants';

export default function ServicePortalModalApprovalRejectAction({
  isOpen,
  language,
  onClose,
  onConfirm,
  type,
  data,
  selectedItems,
}) {
  const [value, setValue] = useState();
  const [file, setFile] = useState();
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    handleMulti(type);
  };

  const handleClose = (reload) => {
    setValue('');
    onClose?.(reload);
  };

  const handleMulti = (type) => {
    const tickets = selectedItems.filter((s) => s.type == 'Ticket');
    const tasks = selectedItems.filter((s) => s.type == 'Tarefa');
    const changes = selectedItems.filter((s) => s.type == 'Mudança');

    const totalExecution = tickets.length + tasks.length + changes.length;
    const isApproval = type === 'approve';
    const delegate = isApproval ? handleApproveSingle : handleRejectSingle;

    const formValues = isApproval ? value : { reason: value, file: file };

    for (let index = 0; index < tickets.length; index++) {
      const ticket = tickets[index];
      const isLastExecution = totalExecution === index + 1;
      delegate('Ticket', formValues, ticket, isLastExecution);
    }

    for (let index = 0; index < tasks.length; index++) {
      const task = tasks[index];
      const isLastExecution = totalExecution === index + 1;
      delegate('Tarefa', formValues, task, isLastExecution);
    }

    for (let index = 0; index < changes.length; index++) {
      const change = changes[index];
      const isLastExecution = totalExecution === index + 1;
      delegate('Mudança', formValues, change, isLastExecution);
    }
  };

  const handleApproveSingle = async (type, reason, data, isLastExecution) => {
    let payload = {};
    if (data) {
      payload = data;
    }
    if (type == 'Ticket') {
      payload.action = 'approval';
      payload.reason = reason;
      payload.status = 'Approve';

      spFetch(
        `${Constants.APIEndpoints.REQUEST}/${payload.id_request}`,
        'post',
        payload
      ).then((result) => {
        Store.toggleAlert(true, 'Aprovação realizada com sucesso', 'success');
        isLastExecution && handleClose(true);
      });
    } else if (type == 'Tarefa') {
      payload.justify = reason;
      payload.approve = true;
      payload.id_tasks = payload.id;
      spFetch(
        `${Constants.APIEndpoints.TASKS}/approvetask`,
        'post',
        payload
      ).then((e) => {
        if (e.data.code == '3030') {
          Store.toggleAlert(
            true,
            'Usuário não faz parte do grupo aprovador',
            'error'
          );
        } else if (e.data.code == '3031') {
          Store.toggleAlert(true, 'Usuário ja realizou aprovação', 'error');
        } else if (e.data.code == 'ok') {
          Store.toggleAlert(true, 'Aprovação realizada com sucesso', 'success');
          isLastExecution && handleClose(true);
        }
      });
    } else {
      payload.fk_id_change = payload.id;
      payload.reason = reason;
      payload.approved = true;

      console.log('change', payload);
      spFetch(
        `${Constants.APIEndpoints.CHANGE}/revision`,
        'post',
        payload
      ).then((e) => {
        if (e.data.erro == 20) {
          Store.toggleAlert(
            true,
            'Usuário não tem permissão para executar essa ação',
            'error'
          );
        } else if (e.data.erro == 30) {
          Store.toggleAlert(true, 'Usuário já executou essa ação', 'error');
        } else {
          Store.toggleAlert(true, 'Aprovação realizada com sucesso', 'success');
          isLastExecution && handleClose(true);
        }
      });
    }
  };

  const handleRejectSingle = (type, formValues, data, isLastExecution) => {
    let values = {};
    if (data) {
      values = data;
      values.typeItem = type;
    }
    if (values.typeItem == 'Ticket') {
      values.action = 'approval';
      values.reason = formValues.reason;
      values.status = 'Rejected';
      if (formValues.files && formValues.files.length > 0) {
        let filesSend = [];
        let cont = 0;
        values.files = formValues.files;
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
              filesSend = [];
              cont = 0;
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              let host = Constants.APIRoot;
              values.file = filesSend;
              // let tokenApi = Store.getToken();
              // fetch(`${host}/api/request/` + values.id_request, {
              //   method: 'POST',
              //   body: JSON.stringify(values),
              //   headers: {
              //     'Content-Type': 'application/json',
              //     'x-access-token': tokenApi,
              //   },
              // })
              spFetch(
                `${Constants.APIEndpoints.REQUEST}/${data.id_request}`,
                'post',
                values
              )
                // .then((results) => {})
                .then((data) => {
                  isLastExecution && handleClose(true);
                  // clearScreen();
                  // createLog(values, values_.reason, false);
                });
            }
          });
        });
      } else {
        // action
        //   .execute(
        //     'post',
        //     Constants.APIEndpoints.REQUEST + '/' + values.id_request,
        //     '',
        //     values
        //   )
        spFetch(
          `${Constants.APIEndpoints.REQUEST}/${data.id_request}`,
          'post',
          values
        ).then((res) => {
          isLastExecution && handleClose(true);
        });
      }
    } else if (values.typeItem == 'Tarefa') {
      values.files = formValues.files;
      values.justify = formValues.reason;
      values.id_tasks = values.id;

      if (formValues.files && formValues.files.length > 0) {
        let filesSend = [];
        let cont = 0;
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
              filesSend = [];
              cont = 0;
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              values.file = filesSend;

              // fetch(`${host}/api/tasks/approvetask`, {
              //   method: 'POST',
              //   body: JSON.stringify(values),
              //   headers: {
              //     'Content-Type': 'application/json',
              //     'x-access-token': tokenApi,
              //   },
              // })
              spFetch(
                `${Constants.APIEndpoints.TASKS}/approvetask`,
                'post',
                values
              )
                // .then((results) => {})
                .then((data) => {
                  isLastExecution && handleClose(true);
                });
            }
          });
        });
      } else {
        // action
        //   .execute(
        //     'post',
        //     Constants.APIEndpoints.TASKS + '/approvetask',
        //     '',
        //     values
        //   )
        spFetch(
          `${Constants.APIEndpoints.TASKS}/approvetask`,
          'post',
          values
        ).then((res) => {
          isLastExecution && handleClose(true);
        });
      }
    } else {
      values.fk_id_change = values.id;
      values.approved = false;
      if (formValues.files && formValues.files.length > 0) {
        let filesSend = [];
        let cont = 0;
        values.files = formValues.files;
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
              filesSend = [];
              cont = 0;
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              values.file = filesSend;
              // fetch(`${Constants.APIEndpoints.CHANGE}/revision`, {
              //   method: 'POST',
              //   body: JSON.stringify(values),
              //   headers: {
              //     'Content-Type': 'application/json',
              //     'x-access-token': tokenApi,
              //   },
              // })
              spFetch(
                `${Constants.APIEndpoints.CHANGE}/revision`,
                'post',
                values
              )
                // .then((results) => {})
                .then((data) => {
                  isLastExecution && handleClose(true);
                });
            }
          });
        });
      } else {
        spFetch(
          `${Constants.APIEndpoints.CHANGE}/revision`,
          'post',
          values
        ).then((res) => {
          isLastExecution && handleClose(true);
        });
      }
    }
  };

  return (
    <ServicePortalModalWrapper
      isOpen={isOpen}
      title={type === 'approve' ? language.APPROVE : language.REJECT}
      onClose={handleClose}
      onConfirm={handleConfirm}
      buttonCloseLabel={language.CLOSE}
      buttonConfirmLabel={language.SAVE}
    >
      <div className="tw-w-full">
        <div className="tw-mb-4">
          <Typography>{`${selectedItems?.length} ${language.ITEMS}`}</Typography>
        </div>
        <TextField
          autoFocus
          fullWidth
          multiline
          minRows={4}
          type="text"
          variant="outlined"
          error={error}
          label={language.JUSTIFICATION}
          InputLabelProps={{ shrink: true }}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
      </div>
    </ServicePortalModalWrapper>
  );
}
