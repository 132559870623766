import React from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../../../common/CommonForm2';

const CancelAttendance = ({
  APIEndpoints,
  setOpenCancelDialog,
  setSingleAttendance,
  action,
  Store,
  Attendance,
}) => {
  const cancelAttendance = (values) => {
    values.user = Store.getUserLoged();
    values.id_attendance_sac = Attendance.id_attendance_sac;
    action
      .execute('post', APIEndpoints.SAC_ATTENDANCE + '/cancel', '', values)
      .then((res) => {
        Store.toggleAlert(true, 'Chamado Cancelado com Sucesso', 'success');
        setOpenCancelDialog(false);
        setSingleAttendance(res.data[0]);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={() => setOpenCancelDialog(false)}
      title="Cancelar Atendimento"
      maxWidth="lg"
    >
      <CommonForm2
        fields={[
          {
            col: 12,
            type: 'textEditor',
            name: 'desc_cancel',
            label: language.JUSTIFICATION,
            required: true,
          },
        ]}
        onSubmit={cancelAttendance}
      />
    </Dialog>
  );
};

export default CancelAttendance;
