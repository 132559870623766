import React, { useState } from 'react';

export default function ({
	language,
  contracts,
  categories,
  catalogs,
  users,
}) {
  return {
    rules() {
      return [
        { value: 'mail', label: language.EMAIL },
        { value: 'plataform', label: language.PLATFORM },
      ];
    },
    type_tickets() {
      return [
        { value: 4, label: language.REQUEST },
        { value: 1, label: language.TICKET_TYPES.INCIDENT },
        { value: 2, label: language.TICKET_TYPES.PROBLEM },
        { value: 5, label: language.TICKET_TYPES.EVENT },
        { value: 6, label: language.TICKET_TYPES.CRISIS },
        { value: 7, label: language.TICKET_TYPES.CRITICAL_INCIDENT },
      ];
    },

    type_category(rule) {
      
      let types = [
        { value: 'close', label: language.CLOSE },
        { value: 'categorize', label: language.CATEGORIZE },
        { value: 'remove', label: language.DELETE },
      ];

      if (rule && rule === 'mail') { 
        types = types.concat([
          { value: 'resolve', label: language.RESOLVE },
          { value: 'comment', label: language.COMMENTAR },
        ])
      }
      return types;
    },
    categories() {
      return categories
        .filter((v) => v.deleted == false)
        .map((e) => ({ value: e.id_category, label: e.name }));
    },
    subCategory(id) {
      try {
        if (id) {
          return categories
            .find((e) => e.id_category == id && e.deleted == false)
            .catalog.filter((c) => c.deleted == false)
            .map((e) => ({ value: e.id_catalog_service, label: e.name }));
        } else {
          return catalogs
            .filter((v) => v.deleted == false)
            .map((e) => ({ value: e.id_catalog_service, label: e.name }));
        }
      } catch (e) {
        return [];
      }
    },
    catalogTask(id) {
      try {
        return catalogs
          .find((e) => e.id_catalog_service == id && e.deleted == false)
          .task.map((e) => ({ value: e.id_catalog_task, label: e.name }));
      } catch (e) {
        return [];
      }
    },
    solicitant(id) {
      if (id > 0) {
        return users
          .filter((v) => v.id_company === id)
          .map((e) => ({ value: e.id_user, label: e.name }));
      } else {
        return users
          .filter((e) => e.role == 'solicitant')
          .map((e) => ({ value: e.id_user, label: e.name }));
      }
    },
    tech() {
      return users
        .filter((e) => e.role == 'tech' || e.role == 'client')
        .map((e) => ({ value: e.id_user, label: e.name }));
    },
    contracts() {
      return contracts.map((e) => ({ value: e.id_contract, label: e.name }));
    },
    type() {
      return [
        { value: 'contains', label: language.CONTAIN },
        { value: 'no_contains', label: language.NO + ' ' + language.CONTAIN },
        { value: 'in', label: language.EQUAL_TO },
        { value: 'no_in', label: language.DIFFERENT_FROM },
      ];
    },
    action() {
      return [
        { value: 'mail', label: language.EMAIL },
        { value: 'subject', label: language.SUBJECT },
        { value: 'solicitant', label: language.SOLICITANT },
        { value: 'category', label: language.CATEGORY },
        { value: 'subcategory', label: language.SUBCATEGORY},
        { value: 'company', label: language.COMPANY },
        { value: 'description', label: language.EMAIL_BODY },
      ];
    },

    // ----------------------------funções auxiliares------------------------------------
    getOptions(option, v, id) {
      let _return = this[option](id).find((e) => e.value == v);
      return (_return || {}).label;
    },

    getOptionsJoin(option, v, id) {
      let _return = this[option](id)
        .filter((e) => v.includes(e.value))
        .map((e) => e.label);
      return (_return || []).join(', ');
    },
  };
}
