import React from 'react';
import { Card } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';

export default function Default({
  handleValue,
  setValue,
  setFormReference,
  options,
  language,
  values,
}) {
  
  function loadFields() {
    let fields = [
      {
        col: 4,
        type: 'select',
        name: 'type_category',
        label: language.ITEM,
        options: options.type_category(values?.rule),
        required: true,
      },
    ];

    return fields;
  }

  return (
    <Card style={{ overflow: 'visible' }}>
      <AppBar title={language.ACTIONS} />
      <div className="content pt-0">
        <CommonForm
          sensitive
          values={values}
          onRef={(ref) => setFormReference(ref)}
          fields={loadFields()}
          onChangeField={(f, v) => {
            if (f && f.name == 'type_category') {
              setValue('type_category', v);
            }

            handleValue(v, f.name);
          }}
        />
      </div>
    </Card>
  );
}
