import React, { Component } from 'react';
import { Card, Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';
import { MaskNumber } from '@common/Mask';

export default class SlaForm extends Component {
  constructor() {
    super();
    this.state = {
      type: 'H'
    };

  }

  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;
    let { type } = this.state;

    const onFormSubmit_ = (v) => {
      v.type = type;
      onFormSubmit(v)
    }

    let fields = [
      {
        col: 12,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true
      },
      {
        col: 12,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        options: [{ value: 'H', label: language.HOURS }, { value: 'M', label: language.MINUTES.charAt(0).toLocaleUpperCase() + language.MINUTES.slice(1) }],
        required: true
      },
      {
        col: 12,
        name: 'time_response',
        label: language.REQUEST_TIME,
        type: 'text',
        inPut: value => value.toString().padStart(2, 0),
        outPut: value => parseInt(value),
        InputProps: {
          id: {
            format: type == 'H' ? "##h" : "##m",
            mask: '_',
            placeholder: type == 'H' ? '00h' : '00m',

          },
          inputComponent: MaskNumber
        },
        required: true
      },
      {
        col: 12,
        name: 'time_solution',
        label: language.SERVICE_TIME,
        type: 'text',
        inPut: value => value.toString().padStart(2, 0),
        outPut: value => parseInt(value),
        InputProps: {
          id: {
            format: type == 'H' ? "##h" : "##m",
            mask: '_',
            placeholder: type == 'H' ? '00h' : '00m',

          },
          inputComponent: MaskNumber
        },
        required: true
      }
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog title={values ? language.EDIT : language.ADD} modal={false} open={true} onClose={() => handlePage('list')} maxWidth='xs'>

        <CommonForm
          values={values}
          fields={fields}
          loading={['post-/dash/configuration/gcf', 'put-/dash/configuration/gcf']}
          onSubmit={onFormSubmit_}
          onChangeField={(g, v) => {
            if (g.name == 'type') {
              this.setState({ type: v })
            }


          }}
          button={button}
        />

      </Dialog>
    );
  }
}
