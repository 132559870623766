import React, {useState} from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default function({
	language,
	open,
	onClose
}){
	let [form, setForm] = useState(null);
	return(
		<Dialog
			open={open}
			onClose={onClose}
			bar={false}
			PaperProps={{
			  id: 'ConnectForm'
			}}
			maxWidth='xs'
			dialogChildren={
			  <div
			    className="d-flex align-items-start justify-content-between bar pl-4 py-2"
			    style={{
			      background: `url(${BarBlue})`,
			      borderBottom: '3px solid var(--warning)'
			    }}
			  >
			    <h4 id="title">{language.CONNECT}</h4>
			    <IconButton
			      aria-label={language.CLOSE}
			      className="closeButton"
			      onClick={onClose}
			    >
			      <CloseIcon style={{ color: 'white' }} />
			    </IconButton>
			  </div>
			}
		>
			<CommonForm
				onRef={r=>setForm(r)}
				fields={[
					{
						type:'btnGroup',
						name:'reason',
						label:language.REASON,
						options:[
							{value:1, label:language.CORRECTIVE},
							{value:2, label:language.PREVENTIVE},
							{value:3, label:language.EVOLUTIONARY},
						]
					}
				]}
				onSubmit={v=>null}
				hiddenSubmit
			/>
			<hr className='mt-4'/>
			<div className='d-flex align-items-center justify-content-between w-100'>
				<div style={{width:'46%'}}>
					<Button
						label={language.CANCEL}
						color='warning'
						fluid
						variant='normal'
						style={{
							height:40
						}}
					/>
				</div>
				<div style={{width:'46%'}}>
					<Button
						label={language.ASSOCIATE}
						color='primary'
						fluid
						variant='normal'
						onClick={()=>form.submit()}
						style={{
							height:40
						}}
					/>
				</div>
			</div>
		</Dialog>
	)
}