import Button from '@common/Button';
import Dialog from '@common/Dialog';
import Store from '@data/Store';
import { Chip } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import CommonForm2 from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import DeviceContact from '../device/DeviceContact';

const { language } = Store;

export default function ({
  action,
  APIEndpoints,
  ticket,
  hasTasksPending,
}) {
  const [ics, setIcs] = useState([]);
  const [page, setPage] = useState('list');
  const [viewIc, setViewIc] = useState();

  useEffect(() => {
    getData();
  }, [ticket, page]);

  const getData = () => {
    action
      .execute('get', `${APIEndpoints.TICKET}/${ticket.id_tickets}/ics`)
      .then((res) => {
        setIcs(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function handlePage(page) {
    setPage(page);
  }

  function onFormSubmit(values) {
    action
      .execute('post', `${APIEndpoints.TICKET}/${ticket.id_tickets}/ics`, '', values)
      .then((res) => {
        handlePage('list');
        if (res.data.length > 0 && res.data[0].message == 0) {
          Store.toggleAlert(true, language.IC_ALREADY_INCLUDED_IN_THE_TICKET, 'error');
        } else {
          Store.toggleAlert(true, language.IC_SUCCESSFULLY_INCLUDED, 'success');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleViewIc = (ic) => {
    setViewIc(ic);
    handlePage('view');
  }

  const removeIC = (ic) => {
    action
      .execute(
        'delete',
        `${APIEndpoints.TICKET}/ics/${ic.id_rel_tickets_device}`
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <br />
      <h3 className="title-aba mb-4">{language.DEVICE_CONF}</h3>
      <CommonTable
        title={''}
        language={language}
        index="DeviceICList"
        searchColumn
        orderColumn
        paginationTop={false}
        beforeBar={
          !hasTasksPending && (
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          )
        }
        columns={[
          {
            key: 'name',
            label: language.NAME,
          },
          {
            key: 'type',
            label: language.TYPE,
          },
          {
            key: 'dt_cad',
            label: language.DATE_CAD,
          },
          {
            key: 'is_acn',
            label: 'Acn',
          },
          {
            key: 'ci_base_env',
            label: language.CMDB_CI_BASE_ENV,
          },
          {
            key: 'ci_base_oper_status',
            label: language.OPERATIONAL_SYSTEM,
          },
          {
            key: 'ci_base_ci_type',
            label: language.CMDB_CI_BASE_CI_TYPE,
          },
          {
            key: 'ip',
            label: 'IP',
          },
          // {
          //   key: 'action',
          //   label: language.REMOVE,
          // },
        ]}
        data={ics.map((ic) => ({
          name: ic.name,
          type: ic.type,
          dt_cad: moment(ic.dt_cad).format('LLL'),
          is_acn: ic.is_acn ? language.YES : language.NO,
          ci_base_env: ic.ci_base_env,
          ci_base_oper_status: ic.ci_base_oper_status,
          ci_base_ci_type: ic.ci_base_ci_type,
          ip: ic.ip,
          // action: (
          //   <Fragment>
          //     <Chip
          //       className="mx-2 muit-chip"
          //       label={
          //         <div className="d-flex align-items-center justify-content-between tw-gap-3">

          //           {ticket.id_type == 6 &&
          //             <Button
          //               size={0.7}
          //               variant="circle"
          //               icon="far fa-eye"
          //               color="secondary"
          //               label={language.VIEW}
          //               style={{ fontSize: 13 }}
          //               onClick={() => handleViewIc(ic)}
          //             />
          //           }

          //           <Button
          //             variant="circle"
          //             icon="far fa-trash-alt"
          //             label={language.DELETE}
          //             color="danger"
          //             style={{ fontSize: 13 }}
          //             size={0.7}
          //             onClick={() => removeIC(ic)}
          //           />
          //         </div>
          //       }
          //     />
          //   </Fragment>
          // ),
        }))}
      />

      {page === 'form' && (
        <Dialog
          modal={false}
          open={true}
          onClose={() => handlePage('list')}
          maxWidth="lg"
          PaperProps={{
            style: {
              height: '60%',
            },
          }}
          title={language.ADD}
        >
          <CommonForm2
            fields={[
              {
                col: 12,
                type: 'autocomplete',
                name: 'devices',
                label: 'Device',
                textlabel: 'Device',
                className: 'tech-group-ticket',
                method: 'POST',
                route: `${APIEndpoints.DEVICE}/filter/`,
                routeGetCount: `${APIEndpoints.DEVICE}/filter?`,
                fieldquery: 'search',
                textinit: 'Device',
                fieldvaluedb: 'id_device',
                fieldlabeldb: 'name',
                routeAll: `${APIEndpoints.DEVICE}/filter?`,
                filterAll: { fk_id_company: ticket.fk_id_company },
                methodGetAll: 'POST',
                detail: true,
                visible: true,
                isMulti: true,
                customFields: [
                  { value: 'ci_status', label: language.CMDB_CI_STATUS },
                  { value: 'ci_base_oper_status', label: language.CMDB_CI_BASE_OPER_STATUS },
                  { value: 'ci_base_fqdn', label: language.CMDB_CI_BASE_FQDN },
                ]
              },
            ]}
            onSubmit={onFormSubmit}
            button={{ label: language.SAVE }}
          />
        </Dialog>
      )}

      {page === 'view' && (
        <>
          <Dialog
            open={true}
            maxWidth="xl"
            title={language.CONTACTS}
            onClose={() => { setPage('list'); setViewIc(null) }}
          >
            <div className='tw-pt-4'>
              <DeviceContact device={viewIc} />
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}
