import React, {useCallback, useState, memo} from 'react';
import Store from '@data/Store';
/*--------------------common---------------------*/
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
/*--------------------material-ui---------------------*/
import {Card} from '@material-ui/core';
/*-------------------components----------------------*/
import MyCard from './Card';
/*--------------------------------------------------*/

import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from "react-window";
import {AutoSizer} from 'react-virtualized';
import { Droppable } from 'react-drag-and-drop';

const { language } = Store;


const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback(scrollbarsRef => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <PerfectScrollbar
      	ref={refSetter}
        style={{ ...style, overflow: "hidden" }}
        className='pb-2'
        onScroll={onScroll}
        options={{
	        minScrollbarLength:30
	    }}
    >
      
      {children}
      
    </PerfectScrollbar>
  );
};
/*
	---------------------------------formato---------------------------------------------
	{
		title:'A Fazer',
		data:[
			{
				avatares:[
					{name:'Rodrigo'},
					{name:'Carlos'},
					{name:'Marcos'},
					{name:'Platão'},
					{name:'João'},
					{name:'Magno'},
				],
				tags:[
					{name:'Sprint 4', color:'#13CBF0'},
					{name:'Lead Up', color:'#F0B02B'},
					{name:'UX/UI', color:'#F05E02'},
					{name:'UX/UI 2', color:'#F05E02'},
				],
				name:'Lorem ipsum dolor sit ambt almee deluradem ipsum dolor sit ambt almee delurad.',
				dt:'12/03/2012',
				commentsTotal:12,
				filesTotal:3,
				percent:60,
				percentColor:'var(--danger)',
				
			}
		]
	}
*/
let statusIcon = {
	true:'fas fa-chevron-down',
	false:'fas fa-chevron-up',
	null:'fas fa-sort',
}

let _orderIndex = {
	0:'id_project_activity',
	1:'dt_start',
	null:'id_project_activity'
}
export default memo(({title, data, onDrop=()=>null, onEdit, onDelete, onView, project, columnTheme})=>{
   let [search, setSearch] = useState('');
   let [orderIndex, setOrderIndex] = useState(null);
	let [orderPosition, setOrderPosition] = useState(null);

	data = data.filter(e => 
		JSON.stringify({
			id:e.id,
			avatares:e.avatares,
			tags:(e.tags||[]).map(e=>e.label).join(' '),
			name:e.name,
			dt:e.dt,
			commentsTotal:e.commentsTotal,
			filesTotal:e.filesTotal,
			percent:e.percent,
		}).toLowerCase().indexOf(search) != -1,
   ).sort((a, b) => {
      if(orderIndex===1){
         return new Date(([null, true].includes(orderPosition)?a:b)[_orderIndex[orderIndex]]) - new Date(([null, true].includes(orderPosition)?b:a)[_orderIndex[orderIndex]])
      }else{
         return ([null, true].includes(orderPosition)?a:b)[_orderIndex[orderIndex]] - ([null, true].includes(orderPosition)?b:a)[_orderIndex[orderIndex]]
      }
   });
	
	return(

		<Droppable types={['milestone']} onDrop={onDrop} className='border-radius-10 KanbanCardColumn pb-2' style={{'--kanbanColor':columnTheme}}>
         <AppBar 
            title={title} 
            titleProps={{style:{color:'white'}}} 
            childrenContentProps={{
               style:{height:0}, 
               //className:'d-flex align-items-center'
            }}
         >
				<div className='qtd-number -mt-3'>
					{(data && data.length)}
				</div>
			</AppBar>
         <div className='d-flex align-items-center btn-group -mt-3 ml-3 mb-2'>
            {[
							 { label: language.BY_ID, color:'light'},
							 { label: language.BY_DATE, color:'light'},
            ].map((e, i)=>(
               <Button 
                  icon={orderIndex===i?statusIcon[orderPosition]:'fas fa-sort'}
                  label={e.label}
                  className='btn-item' 
                  outlined={orderIndex!==i}
                  onClick={() => {
                     setOrderIndex(i);
                     setOrderPosition(p=>!p);
                  }}
                  color={orderIndex===i?e.color:'light'}
                  variant='normal'
                  size={.6}
               />
            ))}
         </div>
			<hr className='my-0 py-0 mt-1' style={{borderColor:'white', opacity:.4}}/>
			<div className='content-search px-3 mt-2'>
				<CommonForm
					fields={[
						{
							startIcon:'fas fa-search',
							type:'text',
							name:'search',
							placeholder:language.SEARCH
						}
					]}
					onChangeField={(f, v) => setSearch(v)}
				/>
			</div>
			<div style={{height:'66vh'}} className='content-body'>
	      		{data.length?(
		      		<AutoSizer style={{height:'auto', width:'auto'}}>
		      		    {({height, width})=>(
		      			  	<FixedSizeList
		      		  				
		      	  				height={height}
		      	  		      	itemCount={data.length}
		      	  		      	itemSize={150}
		      	  		      	width={width}
		      	  				
		      	  				outerElementType={CustomScrollbarsVirtualList}
		      	  				
		      	  			>
		      	  				{({ index, style })=>(
		      	  					<div style={style} key={data[index].id} className='content-medium py-0 d-flex align-items-center justify-content-center'>
		      	  						<MyCard {...data[index]} project={project} key={data[index].id} onEdit={onEdit} onDelete={onDelete} onView={onView}/>
		      	  					</div>
		      	  				)}
		      			  		
		      			  	</FixedSizeList>
		      	  		)}
		      		</AutoSizer>
	      		):(
		      		<h3
		      		  style={{ color: 'white', fontWeight: 100, fontSize: 16, textAlign:'center' }}
		      		  className="mt-2"
		      		>
								{language.NOTHING_REGISTERED}
		      		</h3>
	      		)}
			</div>
		</Droppable>
	)
}, function(prev, next){
	return  _.isEqual([prev.data, prev.columnTheme], [next.data, next.columnTheme])
})