import React, { useEffect, useState, useReducer } from 'react';
import moment from 'moment';
import MemoryIcon from '@material-ui/icons/Memory';
import CommonTable from '@common/CommonTable';
import DeviceConf from '../monitor/DeviceConf';
import Progress from '@common/Progress';
import Button from '@common/Button';
import ReactHighcharts from 'react-highcharts';
import CommonForm from '@common/CommonForm2';
import PieChart from '@common/PieChart';
import Details from './Details';
import Link from './LinkGI';
import './index.css';
import CommonDelete from '../../common/CommonDelete';
import DeviceForm from '../device/DeviceForm';
const crypto = require('crypto');
import { observer } from 'mobx-react-lite';
import Dialog from '@common/Dialog';
import { company } from '../dashboard/Graph';
import { Capacity } from './Graph';
import Status from './Status';
var CryptoJS = require('crypto-js');

const TableType = observer(({
    countOk,
    countYellow,
    countRed,
    monitorGroups,
    companyId,
    countTotal,
    devices,
    ioLinks,
    action,
    language,
    APIEndpoints,
    companies,
    ioServers,
    page,
    devicelast,
    refresh_,
    store,
    startAssessment,
    finishAssessment,
    MonitoresAva
}) => {
    const [groupType, setGroupType] = useState([]);
    const [monitor, setMonitores] = useState([]);
    const [devicea, setDevicea] = useState([]);
    const [groups, setGroups] = useState([]);
    let [filter, setFilter] = useState([0]);
    const [grupos, setGrupos] = useState([]);
    const [remove, setRemove] = useState(null);
    const [edit, setEdit] = useState(null);
    const [valueEdit, setValueEdit] = useState(null);
    const [editDevice, setEditDevice] = useState(null);
    const [arrayRemove, setArrayRemove] = useState([]);
    const [companiess, setCompaniess] = useState([]);
    const [hist, setModalHist] = useState([]);
    const [dataHistorico, setDataHistorico] = useState([]);
    const [filterB, setFilterB] = useState(1);
    const [d, setD] = useState(0);
    const [allDevices, setAllDevices] = useState([]);
    const [di, setDi] = useState(moment());
    const [df, setDf] = useState(moment());
    const [type, setType] = useState('');
    const [devicesOk, setDevicesOk] = useState([]);
    const [indexOpen, setIndexOpen] = useReducer((state, value) => (
        state.includes(value) ? state.filter(e => e !== value) : [...state, value]
    ), []);

    const getHistoric = (id, t) => {
        if (id) {
            setD(id);
        }
        if (t) {
            setType(t);
        }

        let da = { fk_id_device: id || d, di: moment(di).format('YYMMDD'), df: moment(df).format('YYMMDD') };

        action.execute('post', `${APIEndpoints.MONITOR}/historic`, '', da).then(c => {
            if ((t || type) !== 'link') {
                let colorsBar = [
                    '#02F0DE',
                    '#02F079',
                    '#A7F002',
                    '#E7F002',
                    '#F0B002',
                    '#F05E02',
                    '#EE0D81',
                    '#019FC2',
                    '#E7F002'
                ];

                let cat = t === 'link' ? c.data.map(v => moment(v.dt_cad).format('DD-MM HH:mm')) : c.data.filter(v => v.type === 'cpu-stats').map(v => moment(v.dt_cad).format('DD-MM HH:mm'));

                let ids = c.data.filter(v => v.type === 'fs-list').map(o => o.unidade);
                let unique = ids.filter((item, i, ar) => ar.indexOf(item) === i);

                let data = [
                    {
                        name: language.PROCESSOR,
                        zIndex: 2,
                        color: '#019FC2',
                        data: c.data.filter(v => v.type === 'cpu-stats').map(v => parseInt(v.percent || 0))
                    },
                    {
                        name: language.MEMORY,
                        zIndex: 2,
                        color: '#F0B002',
                        data: c.data.filter(v => v.type === 'mem-info').map(v => parseInt(v.percent || 0))
                    }
                ];

                unique.forEach((uni, i) => {
                    data.push({
                        name: `${language.DISK} - uni`,
                        zIndex: 2,
                        color: colorsBar[i],
                        data: c.data.filter(b => b.unidade === uni).map(v => parseInt(v.percent || 0))
                    });
                });

                setDataHistorico({
                    categories: cat,
                    data: data
                });

            } else {
                let colorsBar = [
                    '#02F0DE',
                    '#02F079',
                    '#A7F002',
                    '#E7F002',
                    '#F0B002',
                    '#F05E02',
                    '#EE0D81',
                    '#019FC2',
                    '#E7F002'
                ];
                let cat = c.data.map(v => moment(v.dt_cad).format('DD/MM HH:mm'));

                let data = [
                    {
                        name: 'Link',
                        zIndex: 2,
                        color: '#019FC2',
                        data: c.data.map(v => parseInt(v.used || 0))
                    }
                ];

                setDataHistorico({
                    categories: cat,
                    data: data
                });
            }
        });

        setModalHist(true);
    };

    const editDevice_ = (v) => {
        let tokenApi = store.getToken();
        action.execute(
            'get',
            `${APIEndpoints.DEVICE}/${v.id_device}`,
            ''
        )
          .then(res => {
              setValueEdit(res.data[0]);
          });
    };

    const getUsers = (device) => {
        action.execute(
            'get',
            `${APIEndpoints.MONITOR}/${device}/users`,
            'users'
        );
    };

    const onConfSubmit = (conf, confTrigger, users, edit) => {
        action.execute(
            'post',
            `${APIEndpoints.MONITOR}/device`,
            'monitor',
            { conf: conf, confTrigger: confTrigger, users: users, edit: edit }
        ).then(c => {
            let index = monitor.findIndex(f => f.id_device === c.data.id_device);
            monitor[index] = c.data;
            setMonitores([...monitor]);
        });
        setEdit(null);
    };

    const onDelete = () => {
        let v = arrayRemove;
        action.execute('get', `${APIEndpoints.MONITOR}/remove/${remove.id_rel_monitor_group_device}`, '').then(c => {
            v.push(remove.id_rel_monitor_group_device);
            setArrayRemove(v);
            loadTable();
            setRemove(null);
        });
    };

    const getCompany = (comp, id) => {
        try {
            return comp.filter(v => v.id_company === id)[0].name;
        } catch (e) {
            return "";
        }
    };

    const decrypt = (aMsg = '') => {
        try {
            var aSecret = 'Orch3str0!@forever!';
            aMsg = aMsg.replace(/\s/g, "");
            let decipher, tRet;
            decipher = crypto.createDecipher("aes-256-cbc", aSecret);
            tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
            tRet += decipher.final("utf8");
            return tRet;
        } catch (e) {
            return {};
        }
    };

    useEffect(() => {
        action.execute('get', APIEndpoints.COMPANY).then(h => {
            setCompaniess(h.data);
        });

        action.execute('get', APIEndpoints.DEVICETYPE, 'devicetype');
        action.execute('get', `${APIEndpoints.CONTRACT}table`, 'contracts');

        let list = monitorGroups
            .reduce((arr, i) => {
                i.groups.map((e) => {
                    e.devices.filter(v => (v.type === 'link' || v.type === 'server')).map((e) => {
                        arr.push(e.id_device);
                    });
                });

                return arr;
            }, []);
        setDevicesOk(list);

    }, []);

    const onFormSubmit = (values) => {
        if (values.user_cad.length === 0 || values.contracts.length === 0) {
            store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');
        } else {
            let method = 'put';
            let url = `${APIEndpoints.DEVICE}/${values.id_device}`;

            action.execute(method, url, ' ', values, 'id_device').then(
                d => {
                    setValueEdit(null);
                }
            );
        }
    };

    function loadTable(type, devicesAlert) { }

    function filterGroup(list, type) {
        let ok = 0;
        let warning = 0;
        let danger = 0;
        let total = 0;

        return list.reduce((arr, i) => {
            let f = devices.filter(f => f.id_rel_monitor_group_device === i.id_rel_monitor_group_device && !arrayRemove.includes(f.id_rel_monitor_group_device));
            let item = {
                id: i.id_rel_monitor_group_device,
                name: i.name,
                id_device: i.id_device,
                id_monitor_group: i.id_monitor_group,
                company: getCompany(companies, i.id_company),
                dt_cad: moment(i.dt_cad).format('DD/MM/YY'),
                user: i.user_cad,
                ok: f.filter(a => a.status === 'ok').length,
                warning: f.filter(a => a.status === 'warning').length,
                danger: f.filter(a => a.status === 'danger').length,
                total: f.length,
                type: i.type,
                status: i.status
            };

            arr.push(item);
            ok += item.ok;
            warning += item.warning;
            danger += item.danger;
            total += item.total;

            if (type === 'history' && ok + warning + danger === 0) {
                arr.pop();
            }

            return arr;
        }, []);
    }

    const updateAssessments = async (idMonitorGroup) => {
        try {
            const response = await action.execute('get', `${APIEndpoints.MONITOR}/${idMonitorGroup}/updateAssessments`);
            if (response.data) {
                const updatedMonitorGroups = monitorGroups.map(group => {
                    if (group.id_monitor_group === idMonitorGroup) {
                        return { ...group, ...response.data };
                    }
                    return group;
                });
                store.setMonitorGroups(updatedMonitorGroups);
            }
        } catch (error) {
            console.error('Error updating assessments:', error);
        }
    };

    return (
        <>
            <Details />
            <Link />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '70%', padding: 20 }}>
                    <CommonTable
                        countOk={countOk}
                        countYellow={countYellow}
                        countRed={countRed}
                        countTotal={countTotal}
                        filter={filter}
                        onFilterChange={setFilter}
                        data={filterGroup(monitorGroups, 'default')}
                        language={language}
                    />
                </div>
                <div style={{ width: '30%', padding: 20 }}>
                    <PieChart
                        countOk={countOk}
                        countYellow={countYellow}
                        countRed={countRed}
                        countTotal={countTotal}
                        language={language}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '100%', padding: 20 }}>
                    <CommonTable
                        data={filterGroup(monitorGroups, 'history')}
                        language={language}
                    />
                </div>
            </div>
            <div style={{ padding: 20 }}>
                <Button onClick={() => startAssessment()}>{language.START_ASSESSMENT}</Button>
                <Button onClick={() => finishAssessment()}>{language.FINISH_ASSESSMENT}</Button>
            </div>
        </>
    );
});

export default TableType;
