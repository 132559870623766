import Guacamole from 'guacamole-common-js';
import tokengcm from '../../util/gcmToken';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, Card, Grid, TextField } from '@material-ui/core'; // controls

import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import { withStyles } from '@material-ui/core/styles';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import RaisedButton from '../../common/RaisedButton';
import Store from '@data/Store';

import Button from '@common/Button';
import $ from 'jquery';
import Constants from '../../data/Constants';
import './style.css';

const { language } = Store;

window.$ = $;

const useStyles = {
  root: {
    minWidth: 100,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

let guac;

class GuacamoleView extends Component {
  constructor() {
    super();
    this.state = {
      refresh: '',
    };
  }
  goFullScreen() {
    var canvas = document.getElementById('display');
    if (canvas.requestFullScreen) {
      canvas.requestFullScreen();
    } else if (canvas.webkitRequestFullScreen) {
      canvas.webkitRequestFullScreen();
    } else if (canvas.mozRequestFullScreen) {
      canvas.mozRequestFullScreen();
    }
  }
  componentDidMount() {
    this.buildRpd();
  }

  buildRpd() {
    var display = document.getElementById('display');

    let arraygcm = [
      'pt-br-abnt2',
      'pt-br-qwerty',
      'en-us-qwerty',
      'es-es-qwerty',
      'fr-fr-azerty',
      'it-it-qwerty',
    ];

    let device = this.props.device;
    device.vd = this.props.path;
    if (this.props.keyboard != 0 && this.props.keyboard != undefined) {
      device.keyboard = arraygcm[this.props.keyboard - 1];
    } else {
      device.keyboard = undefined;
    }

    device.username =
      this.props.user != null && this.props.user != ''
        ? this.props.user
        : device.username;
    device.password =
      this.props.password != null && this.props.password != ''
        ? this.props.password
        : device.password;
    if (this.props.password) {
      device.nodecode = true;
    }

    device.domain =
      this.props.domain != null ? this.props.domain : device.domain;

    let tunnel = new Guacamole.WebSocketTunnel(Constants.GCMSERVER);
    guac = new Guacamole.Client(tunnel);

    guac.connect('token=' + tokengcm(device));

    // Add client to display div
    display.appendChild(guac.getDisplay().getElement());

    // Error handler
    guac.onerror = function (error) {
      console.error(error)
    };

    // Disconnect on close
    window.onunload = function () {
      // guac.disconnect();
    };

    // Mouse
    var mouse = new Guacamole.Mouse(guac.getDisplay().getElement());

    mouse.onmousedown =
      mouse.onmouseup =
      mouse.onmousemove =
        function (mouseState) {
          guac.sendMouseState(mouseState);
        };

    // Keyboard
    var keyboard = new Guacamole.Keyboard(document);

    keyboard.onkeydown = function (keysym) {
      guac.sendKeyEvent(1, keysym);
    };

    keyboard.onkeyup = function (keysym) {
      guac.sendKeyEvent(0, keysym);
    };
  }

  render() {
    let { language, classes } = this.props;

    return (
      <div>
        <div id="display" style={{ height: 0 }}></div>

        <div id="fixguac">
          <Card className="content card">
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <div id="content-form-access" className="content">
                  <div>
                    <h3 id="title" className="mb-5">
                      ${language.ACCESS_REMOTE} {this.state.refresh}
                    </h3>
                    <CommonForm
                      fields={[
                        {
                          type: 'element',
                          content: (
                            <div className="c-header">
                              <h4>{language.DEVICE}</h4>
                              <hr />
                              <h4>{this.props.device.ip}</h4>
                              <hr />
                              <h4>{this.props.device.name}</h4>
                            </div>
                          ),
                        },
                      ]}
                      onSubmit={(v) => null}
                      hiddenSubmit
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      icon={
                        <AddToQueueIcon style={{ color: 'var(--warning)' }} />
                      }
                      color="warning"
                      label={language.FINALIZE}
                      className="mr-3"
                      onClick={() =>
                        this.props.finishAssessment(
                          'rdp',
                          null,
                          null,
                          this.props.device,
                          guac
                        )
                      }
                    />
                    <Button
                      icon="fas fa-play"
                      color="success"
                      label="FullScrean"
                      onClick={() => this.goFullScreen()}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

GuacamoleView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(GuacamoleView);
