import React, { Fragment, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import classnames from 'classnames';
/*--------------------------common-------------------------*/
import AppBar from '@common/AppBar2';
import List from '@common/List';
import Progress from '@common/Progress';
import ReactHighcharts, { Highcharts } from 'react-highcharts';
import Button from '@common/Button';
import Loading from '@common/Loading';
/*--------------------------material-ui-------------------------*/
import { Card, Grid } from '@material-ui/core';
/*--------------------------pages-------------------------*/
import { Call_x_Tech } from './Graph';
import ButtonControlMonth from './ButtonControlMonth';
import ButtonControlYear from './ButtonControlYear';
import { setDate } from 'date-fns';
import RaisedButton from '@common/RaisedButton';

const Indicator = ({ label, value, hasIcon }) => (
  <div className="content-medium indicator-item content-min mb-1">
    <span className="ellipsis">{label}</span>
    <div className="mt-1 d-flex align-items-center justify-content-between">
      <h3 className={classnames({ very: value > 1 })}>{value}</h3>
      {hasIcon && <i className="fas fa-thumbs-up" />}
    </div>
  </div>
);

export default function ({
  list = [],
  type,
  title,
  list2,
  subTitle,
  dataGraph = [],
  productiveHours = 0,
  hoursPerformed = 0,
  loadAvCallCateg,
  dateMonth,
  dateYear,
  OpenModal,
  OpenModalGroup,
  language,
}) {
  const [monthSelect, setMonthSelect] = useState('');
  const [yearSelect, setYearSelect] = useState('');
  const [_dataGraph, setDataGraph] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [value, setValue] = useState('');
  const [order, setOrder] = useState('hour');
  const [labelMonth, setLabelMonth] = useState(moment().format('MMMM'));

  var dataTotal = hoursPerformed;
  useEffect(() => {
    if (dateMonth) {
      const month = dateMonth;
      const labelMM = moment(dateMonth - 1).format('MMMM');

      if (!labelMonth) {
        setLabelMonth(labelMM);
      }
      setMonthSelect(month);
    }
  }, [dateMonth]);
  useEffect(() => {
    if (dateYear) {
      const year = dateYear;
      setYearSelect(year);
    }
  }, [dateYear]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (dataGraph && dataGraph.length > 0) {
        setDataGraph(dataGraph);
      } else if (dataGraph && dataGraph.length == 0) {
        setDataGraph([]);
      }
    }, 0);

    return () => clearInterval(interval);
  }, [dataGraph, list2]);

  const handleDate = useCallback(
    (month = '', year = '') => {
      if (month) {
        setMonthSelect(month);
      }

      if (year) {
        setYearSelect(year);
      }
      let monthCurrent = month || monthSelect;
      let yearCurrent = year || yearSelect;
      let prepareFormatDate = `01-${monthCurrent
        .toString()
        .padStart(2, '0')}-${yearCurrent}`;
      const date = moment(prepareFormatDate, 'dd-MM-yyyy').format('MM-YYYY');
      const label = moment(
        `01-${monthCurrent.toString().padStart(2, '0')}`,
        'dd-MM'
      ).format('MMMM');
      if (type === 'group') {
        loadAvCallCateg(date, 'group', value);
      } else if (type === 'tech') {
        loadAvCallCateg(date, 'tech', value);
      }
      setLabelMonth(label);
    },
    [subTitle, value, monthSelect, yearSelect]
  );

  const handleFilter = useCallback(
    (value) => {
      let prepareFormatDate = `01-${monthSelect
        .toString()
        .padStart(2, '0')}-${yearSelect}`;
      const date = moment(prepareFormatDate, 'dd-MM-yyyy').format('MM-YYYY');
      loadAvCallCateg(date, type, value);
    },
    [monthSelect, type, yearSelect]
  );

  const clearFilter = useCallback(() => {
    let prepareFormatDate = `01-${monthSelect
      .toString()
      .padStart(2, '0')}-${yearSelect}`;
    const date = moment(prepareFormatDate, 'dd-MM-yyyy').format('MM-YYYY');
    loadAvCallCateg(date, type);
    setFiltered(false);
    setValue('');
  }, [monthSelect, type, yearSelect]);

  const changeOrder = () => {
    order == 'hour' ? setOrder('tickets') : setOrder('hour');
  };

  if (order == 'hour') {
    list.sort(function (a, b) {
      if (parseFloat(a.hours) > parseFloat(b.hours)) {
        return -1;
      }

      if (parseFloat(a.hours) < parseFloat(b.hours)) {
        return 1;
      }
      return 0;
    });
  } else {
    list.sort(function (a, b) {
      if (parseFloat(a.datas.length) > parseFloat(b.datas.length)) {
        return -1;
      }

      if (parseFloat(a.datas.length) < parseFloat(b.datas.length)) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <Card className="Call_x_Tech h-100">
      <AppBar title={title} subTitle={subTitle}>
        {filtered && (
          <Button
            tooltipLabel={language.RELOAD_ALL}
            variant="circle"
            onClick={() => {
              clearFilter();
            }}
            color="primary"
            className="mr-1"
            icon="fas fa-redo"
            size={0.55}
          />
        )}
        <div className="d-flex -align-items-center">
          <ButtonControlYear
            onChange={(e) => handleDate(null, e)}
            value={yearSelect}
          />
          <ButtonControlMonth
            onChange={(e) => handleDate(e)}
            value={monthSelect}
          />
        </div>
      </AppBar>

      <div className="content-medium">
        <Grid container spacing={16}>
          <Grid item xs={9}>
            <ReactHighcharts
              config={Call_x_Tech({
                categories: Array.from(
                  new Array(moment().daysInMonth()),
                  (_, i) => i + 1
                ),
                data: _dataGraph,
                currentMonth: labelMonth,
                OpenModal:
                  type == 'tech'
                    ? OpenModal
                    : type == 'group'
                    ? OpenModalGroup
                    : null,
              })}
            />
          </Grid>
          <Grid item xs={3} className="content-indicator">
            <Indicator
              label={language.PRODUCTIVE_HOURS}
              value={productiveHours}
            />
            <Indicator label={language.HOURS_WORKED} value={hoursPerformed} />
            <Indicator
              label={language.TOTAL}
              value={parseFloat(
                hoursPerformed > 0 || productiveHours > 0
                  ? (hoursPerformed / productiveHours).toFixed(2)
                  : 0
              )}
              hasIcon
            />
          </Grid>
        </Grid>
      </div>
      <RaisedButton
        circleButton
        icon="fa fa-retweet"
        label={language.CHANGE_SORTING}
        color="sucess"
        style={{ fontSize: 13, width: 21, height: 21, marginLeft: '95%' }}
        onClick={() => changeOrder()}
      />
      <List
        className="list-category-items"
        mh={145}
        items={(list || []).map((e) => ({
          className:
            'd-flex align-items-center justify-content-between items py-1',
          content: (
            <Fragment>
              <div
                className="d-flex align-items-center content-label w-75"
                onClick={() => {
                  handleFilter(e.label);
                  setValue(e.label);
                  setFiltered(true);
                  if (type == 'tech') {
                    OpenModal(undefined, e.label);
                  } else if (type == 'group') {
                    OpenModalGroup(undefined, e.label);
                  }
                }}
              >
                <div className="mr-2" style={{ backgroundColor: e.color }} />
                <span className="ellipsis d-block">{e.label}</span>
              </div>

              <div
                className="d-flex align-items-center justify-content-end content-progress-info"
                onClick={() => {
                  handleFilter(e.label);
                  setValue(e.label);
                  setFiltered(true);
                }}
              >
                <div className="d-flex align-items-center">
                  <span className="label-progress" style={{ width: 50 }}>
                    {isNaN(parseFloat((e.hours / dataTotal) * 100))
                      ? 0
                      : parseFloat((e.hours / dataTotal) * 100).toFixed(1)}
                    %
                  </span>
                  <Progress
                    hiddenText
                    current={parseFloat((e.hours / dataTotal) * 100).toFixed(1)}
                    background={e.color}
                    style={{ width: 75, height: 6 }}
                  />
                </div>
                <span
                  style={{ color: e.color, width: 100 }}
                  className="ml-2 label-hours"
                >
                  {`${
                    e.datas
                      .map((item) => item.real_id)
                      .reduce((acc, item) => {
                        if (acc.indexOf(item) < 0) {
                          acc.push(item);
                        }
                        return acc;
                      }, []).length
                  }T - `}
                  {e.hours}h
                </span>
              </div>
            </Fragment>
          ),
        }))}
      />
    </Card>
  );
}
