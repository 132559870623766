import React, {useState, useEffect, Fragment } from 'react';
import Actions from '@data/Actions'
import Constants from '@data/Constants'
import CommonTable from '@common/CommonTable'
import CommonForm from '@common/CommonForm2'
import Dialog from '@common/Dialog'
import GroupChangeConfigurationList from './GroupChangeConfigurationList';
import Store from '@data/Store'
import GroupChangeConfigurationForm from './GroupChangeConfigurationForm';
import RaisedButton from '@common/RaisedButton';
import { Card, Chip } from '@material-ui/core';
import CommonDelete from '@common/CommonDelete';

const action = new Actions()
const GroupChangeConfigurationContainer = () => {
    const [allGroupsManagement, setAllGroupsManagement] = useState([])
    const [totalGroupsManagement, setTotalGroupsManagement] = useState(0)
    const [page, setPage] = useState('list')
    const [values, setValues] = useState([])
    const [filter, setFilter] = useState({})
    useEffect(() => {
        callPageSelectFilter()
    }, [])

    const handlePage = (page, obj) =>{
        setPage(page)
        setValues(obj)
    }

    const orderBy = (item, order) => {
      let data = filter
      data.sortBy = {item : item, sort : order}
      callPageSelectFilter(0, data, 75)
    }

    const callPageSelectFilter = (page = 0, items = {}, rowPerPage = 75) => {
        let limit = rowPerPage;
        let offset = page * limit;
        setFilter(items)
        action
          .execute(
            'post',
            `${Constants.APIEndpoints.CHANGE}/getAllGroupsManagement/?offset=${offset}&limit=${limit}`,
            '',
            items
          )
          .then((h) => {
            const data = h.data.map(d => ({
                id_rel_group_management_change : d.id_rel_group_management_change,
                company_name : d.company_name,
                group_name : d.group_name,
                global: d.global ? Store.language.YES : Store.language.NO,
                actions : (
                    <React.Fragment>
                      <Chip
                        className="mx-2 muit-chip"
                        label={
                          <div className="d-flex align-items-center justify-content-between">
                            <RaisedButton
                              circleButton
                              icon="fas fa-edit"
                              label={Store.language.EDIT}
                              color="default"
                              style={{ fontSize: 12, width: 21, height: 21 }}
                              className="mr-2"
                              onClick={() => handlePage('form', d)}
                            />
                            <RaisedButton
                              circleButton
                              icon="far fa-trash-alt"
                              label={Store.language.DELETE}
                              color="danger"
                              style={{ fontSize: 13, width: 21, height: 21 }}
                              onClick={() => handlePage('remove', d)}
                            />
                          </div>
                        }
                      />
                    </React.Fragment>
                  ),
                
            }))
            setTotalGroupsManagement(h.data[0].total_count);
            setAllGroupsManagement(data);
          });
      };
    
const onSubmit = (values = {}) => {
    const method = values.id_rel_group_management_change ? 'put' : 'post'
    action.execute(method, Constants.APIEndpoints.CHANGE + '/groupManagementChange', '', values).then(res => {
      handlePage('list')
        callPageSelectFilter()
    }).catch(e => {
      if (e.data.code = 'E008'){
        Store.toggleAlert(true, 'Já existe um grupo técnico global', 'error')
      }
    })
}
const onDelete = () => {
    let data = {}
    data.id_rel_group_management_change = values.id_rel_group_management_change
    data.action = 'remove'
    onSubmit(data)
}
    return (
      <Fragment>
            <GroupChangeConfigurationList 
            allGroupsManagement = {allGroupsManagement}
            totalGroupsManagement = {totalGroupsManagement}
            callPageSelectFilter = {callPageSelectFilter}
            Constants = {Constants}
            action = {action}
            language = {Store.language}
            handlePage = {handlePage}
            cleanFilters = {() => callPageSelectFilter()}
            sortBy = {orderBy}
            />
    {page == 'form' && (
        <GroupChangeConfigurationForm
        values = {values}
        language = {Store.language}
        handlePage = {handlePage}
        onSubmit = {onSubmit}
        Constants = {Constants}
        />
    )}
        {page == 'remove' && (
       <CommonDelete
       language={Store.language}
       closeModal={() => handlePage('list')}
       onDelete={onDelete}
       message={values.name}
     />
        
    )}
      </Fragment>
    );
  
}

export default GroupChangeConfigurationContainer