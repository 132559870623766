import React, {Fragment, memo} from 'react';
import Store from '@data/Store';

const { language } = Store;


export default memo(({description, onOpenForm, percent})=>{
	return(
		<div className='Block_1 mt-3'>
			<span className='label-items'>
				{language.DESCRIPTION}
				{percent != 100?<i className="fas fa-pencil-alt" onClick={onOpenForm}/>:null}
			</span>
			<p dangerouslySetInnerHTML={{__html: description}}/>
		</div>
	)
})