import './index.css';
import React, { useState, useReducer, useEffect, Fragment } from 'react';
import moment from 'moment';
import { put, remove } from 'fetch';
import { Tooltip } from 'react-tippy';
import { Card, Grid } from '@material-ui/core';

import Button from '../../../common/Button';
import Header from '../../../common/Header';
import Progress from '../../../common/Progress';
import { MaskMoney } from '../../../common/Mask';
import CommonForm from '../../../common/CommonForm2';
import CommonTable from '../../../common/CommonTable';
import MenuTooltip from '../../../common/MenuTooltip';
import SearchInput from '../../../common/SearchInput';

import Form from './Form';
import { translateFromPtBR } from '../../../common/languages/Dictionary';

const rendervalue = (value) => {
  try {
    let item = value.replace('.', '');
    item = item.replace(',', '.');
    return item;
  } catch (e) {
    return value;
  }
};

function Pgrss({ current }) {
  return (
    <div className="content-progress">
      <Progress hiddenText current={current} background="var(--primary)" />
      <span>{current}%</span>
    </div>
  );
}

let type = {
  1: 'Despesa',
  2: 'Investimento',
  3: 'Contrato',
};

let type_capital_expenditure = {
  1: 'Capex',
  2: 'Opex',
};

function Field({ value, e, a, addRealizedCost, language }) {
  let [form, setForm] = useState(null);
  return (
    <CommonForm
      individual
      onRef={setForm}
      fields={[
        {
          type: 'text',
          label: window.Number(value)
            ? value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : translateFromPtBR(value, language),
          placeholder: language.VALUE,
          name: 'realized',
          InputProps: {
            inputComponent: MaskMoney,
          },
          onKeyUp: (evt) => {
            if (evt.keyCode === 13) {
              addRealizedCost(
                e.id_budgetcontrol,
                evt.target.value,
                a + 1,
                form
              );
            }
          },
        },
      ]}
    />
  );
}

export default function ({
  action,
  getBudget,
  APIEndpoints,
  language,
  contractsList,
  CarouselList,
  updateItens,
  acumulatedTheme,
  budgetcontrols,
  filterMonthsData,
  currentYear,
  contracts,
}) {
  let [month, SetMonth] = useState(null);
  let [position, setPosition] = useState(0);
  let [open, setOpen] = useState(false);
  let [values, setValues] = useState({});
  let onChangeField = (value, n1, n2, n4) => {
    updateItens(
      CarouselList[n1].id_contract,
      (n2 + 1).toString().padStart(2, '0') + '/2021',
      rendervalue(value),
      'month'
    );
  };

  function onAction(e, v) {
    if (e.value == 1) {
      setOpen(true);
      setValues(v);
    } else {
      remove(
        `/api/budgetcontrol/${v.id_budgetcontrol}`,
        'budgetcontrols',
        'id_budgetcontrol'
      );
    }
  }
  function addRealizedCost(fk_id_budgetcontrol, realized_cost, month, form) {
    put(
      '/api/budgetcontrol/realized_cost',
      'budgetcontrols',
      { fk_id_budgetcontrol, realized_cost, month, year: currentYear },
      'id_budgetcontrol'
    ).then((e) => {
      form.handleValue(undefined, 'realized');
      action.execute('get', APIEndpoints.BUGET, 'budgetcontrols');
    });
  }

  function getExpectedMonthlyPercent(budget, month) {
    let value = 0;
    let budgets =
      budget &&
      budget.expected_value
        .filter((b) => b.year == currentYear)
        .filter((b) => b.month == month + 1);
    if (budget && budget.lenght > 0 && budgets[0].value) {
      value = budgets[0].value;
    } else if (budget && budget.period && budget.period.includes(month + 1)) {
      value = budget.expected_cost || 0;
    }

    return value;
  }

  function getExpectedMonthly(budget, month) {
    let value = 0;
    let budgets =
      budget &&
      budget.data &&
      budget.data.expected_value
        .filter((b) => b.year == currentYear)
        .filter((b) => b.month == month + 1);
    if (budget && budget.lenght > 0 && budgets[0].value) {
      value = budgets[0].value;
    } else if (
      budget.data &&
      budget.data.period &&
      budget.data.period.includes(month + 1)
    ) {
      value = budget.data.expected_cost || 0;
    }

    return value;
  }

  function getForeseen(budget) {
    let contract = contracts.filter((c) => c.name == budget.name)[0];

    let value = 0;
    let currentMonth = moment().format('MM');
    if (
      contract &&
      contract.type == 'year' &&
      budget.expected_value
        .filter((b) => b.year == currentYear)
        .filter((b) => b.month == parseInt(currentMonth))[0]
    ) {
      value = budget.expected_value
        .filter((b) => b.year == currentYear)
        .filter((b) => b.month == parseInt(currentMonth))[0].value;
    } else {
      if (!contract && budget.expected_value.length == 0) {
        value = budget.period ? budget.expected_cost * budget.period.length : 0;
      } else {
        value = budget.expected_value
          .filter((b) => b.year == currentYear)
          .reduce((n, e) => n + e.value, 0);
      }
    }

    return value;
  }

  let _month = language.DATE_LANG.monthLongList;
  let dataTableFormat = budgetcontrols.map((e) => {
    let contract = contracts.filter((c) => c.name == e.name)[0];
    let foreseen = getForeseen(e);
    let monthCurrent = filterMonthsData(e.months);
    let realized = monthCurrent.reduce((n, e) => n + e.realized_cost, 0);
    let retorno = {
      data: e,
      description: e.description,
      name: e.name,
      type: type[e.type],
      type_capital_expenditure:
        type_capital_expenditure[e.type_capital_expenditure],
      company: e.company_name,
      year: e.months
        .filter((e) => e.year < currentYear)
        .reduce((n, e) => n + e.realized_cost, 0),
      foreseen,
      realized,
      percentual: parseInt((realized / foreseen) * 100),
      start_month: contract ? contract.dt_fat : '',
      type_contract: contract ? contract.type : '',
      end_date: contract ? contract.end_date : '',
      id_contract : contract ? contract.id_contract : 0
    };
    [position, position + 1].forEach((a, i) => {
      let ret;
      if (e.period && e.period.includes(a + 1)) {
        let month = monthCurrent.find((e) => e.month - 1 == a);
        ret = {
          ['foreseen' + i]: e.expected_cost,
          ['realized' + i]: [e, a, month ? month.realized_cost : 'Valor'],
          ['percentual' + i]:
            contract && contract.type == 'year'
              ? parseInt(
                  ((month ? month.realized_cost : 0) /
                    getExpectedMonthlyPercent(e, a, e.expected_cost * 12)) *
                    100
                )
              : parseInt(
                  ((month ? month.realized_cost : 0) /
                    getExpectedMonthlyPercent(e, a, e.expected_cost)) *
                    100
                ),
        };
      } else {
        ret = {
          ['foreseen' + i]: null,
          ['realized' + i]: null,
          ['percentual' + i]: null,
        };
      }
      retorno = { ...retorno, ...ret };
    });

    return retorno;
  });

  let dataTable = dataTableFormat.map((e) => ({
    action: (
      <div className="d-flex align-items-center">
        <MenuTooltip
          options={[
            {
              value: 1,
              icon: 'fas fa-pencil-alt',
              label: language.EDIT,
              color: 'var(--success)',
            },
            {
              value: 2,
              icon: 'fas fa-trash-alt',
              label: language.DELETE,
              color: 'var(--danger)',
            },
          ]}
          onChange={(a) => onAction(a, e.data)}
        />
        <Tooltip
          interactive
          html={
            <div className="content view-description">
              <p
                style={{ color: '#888080' }}
                dangerouslySetInnerHTML={{ __html: e.description }}
              />
            </div>
          }
          arrow={true}
          position="right"
          theme="light"
        >
          <i className="fas fa-eye btn-eye" />
        </Tooltip>
      </div>
    ),
    name: e.name,
    type: translateFromPtBR(e.type, language),
  type_capital_expenditure:  currentYear,
    company: e.company,
    year: e.year.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }),
    foreseen: e.foreseen.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }),
    realized: e.realized.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }),
    percentual: <Pgrss current={e.percentual || 0} />,
    foreseen0:
      e['foreseen0'] != null &&
      (e.type_contract != 'year' ||
        !(
          e.type_contract == 'year' &&
          parseInt(e.start_month) != parseInt(e['realized0'][1]) + 1
        ))
        ? getExpectedMonthly(
            e,
            e['realized0'][1],
            e['foreseen0']
          ).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        : '-',
    realized0:
      e['realized0'] !== null &&
      (e.type_contract !== 'year' ||
        !(
          e.type_contract == 'year' &&
          parseInt(e.start_month) != parseInt(e['realized0'][1]) + 1 
        )) &&
      (e.id_contract ? moment(e.end_date).isAfter(moment(`${currentYear}${ parseInt(e['realized0'][1])+1 < 10 ? `0` + (parseInt(e['realized0'][1]) +1) : parseInt(e['realized0'][1])+1 }01`, 'YYYYMMDD')) : 1==1)
        ? (
        <div className="relized-field">
          <Field
            value={e['realized0'][2]}
            e={e['realized0'][0]}
            a={e['realized0'][1]}
            addRealizedCost={addRealizedCost}
            language={language}
          />
        </div>
      ) : (
        '-'
      ),
    percentual0:
      e['percentual0'] !== null ? (
        <Pgrss current={e['percentual0'] || 0} />
      ) : (
        '-'
      ),
    foreseen1:
      e['foreseen1'] !== null &&
      (e.type_contract !== 'year' ||
        !(
          e.type_contract == 'year' &&
          parseInt(e.start_month) != parseInt(e['realized1'][1]) + 1
        ))
        ? getExpectedMonthly(
            e,
            e['realized1'][1],
            e['foreseen1']
          ).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        : '-',
    realized1:
      e['realized1'] !== null &&
      (e.type_contract !== 'year' ||
        !(
          e.type_contract == 'year' &&
          parseInt(e.start_month) != parseInt(e['realized1'][1]) + 1
        )) 
        &&
        (e.id_contract ? moment(e.end_date).isAfter(moment(`${currentYear}${ parseInt(e['realized1'][1])+1 < 10 ? `0` + (parseInt(e['realized1'][1]) +1) : parseInt(e['realized1'][1])+1 }01`, 'YYYYMMDD')) : 1==1)
        ? (
        <div className="relized-field">
          <Field
            value={e['realized1'][2]}
            e={e['realized1'][0]}
            a={e['realized1'][1]}
            addRealizedCost={addRealizedCost}
            language={language}
          />
        </div>
      ) : (
        '-'
      ),
    percentual1:
      e['percentual1'] !== null ? (
        <Pgrss current={e['percentual1'] || 0} />
      ) : (
        '-'
      ),
  }));

  return (
    <Fragment>
      <Form
        title="Adicionar Item"
        open={open}
        values={values}
        onClose={() => {
          setOpen(false);
          setValues({});
          getBudget();
        }}
        budgetcontrols={budgetcontrols}
      />
      <div id="Block_2" style={{ '--acumulatedTheme': acumulatedTheme }}>
        <CommonTable
          beforeBar={
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              color="warning"
              variant="circle"
              className="mr-3"
              onClick={() => setOpen(true)}
            />
          }
          searchColumn
          beforeColumns={[
            { key: '', label: '', colSpan: 6 },
            {
              key: '',
              label: language.ACCUMULATED,
              colSpan: 3,
              className: 'header-label',
            },
            {
              key: '',
              label: (
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    icon="fas fa-arrow-left"
                    color="light"
                    variant="circle"
                    outlined
                    onClick={() => setPosition((p) => Math.max(p - 1, 0))}
                  />
                  <span style={{ color: 'white' }}>{_month[position]}</span>
                  <div style={{ width: 30 }}></div>
                </div>
              ),
              colSpan: 3,
              className: 'header-label-m-1',
            },
            {
              key: '',
              label: (
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ width: 30 }}></div>
                  <span style={{ color: 'white' }}>{_month[position + 1]}</span>
                  <Button
                    icon="fas fa-arrow-right"
                    color="light"
                    variant="circle"
                    outlined
                    onClick={() =>
                      setPosition((p) => Math.min(p + 1, _month.length - 2))
                    }
                  />
                </div>
              ),
              colSpan: 3,
              className: 'header-label-m-2',
            },
          ]}
          beforeColumns2={[
            { key: '', label: '', colSpan: 6 },
            {
              key: '',
              label: (
                <div className="d-flex justify-content-around">
                  <span>
                    T.P
                    {' - '}
                    {dataTableFormat
                      .reduce((n, e) => n + e.foreseen, 0)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </span>
                  <span>
                    T.R
                    {' - '}
                    {dataTableFormat
                      .reduce((n, e) => n + e.realized, 0)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </span>
                </div>
              ),
              colSpan: 3,
              style: { borderRight: '2px solid var(--primary)' },
            },
            {
              key: '',
              label: (
                <div className="d-flex justify-content-around">
                  <span>
                    T.P
                    {' - '}
                    {dataTableFormat
                      .reduce((n, e) => n + (e['foreseen0'] || 0), 0)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </span>
                  <span>
                    T.R
                    {' - '}
                    {dataTableFormat
                      .reduce((n, e) => {
                        if (e['realized0'] && window.Number(e['realized0'][2]))
                          return n + e['realized0'][2];
                        return n;
                      }, 0)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </span>
                </div>
              ),
              colSpan: 3,
            },
            {
              key: '',
              label: (
                <div className="d-flex justify-content-around">
                  <span>
                    T.P
                    {' - '}
                    {dataTableFormat
                      .reduce((n, e) => n + (e['foreseen1'] || 0), 0)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </span>
                  <span>
                    T.R
                    {' - '}
                    {dataTableFormat
                      .reduce((n, e) => {
                        if (e['realized1'] && window.Number(e['realized1'][2]))
                          return n + e['realized1'][2];
                        return n;
                      }, 0)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </span>
                </div>
              ),
              colSpan: 3,
            },
          ]}
          col={[
            {
              key: 'action',
              label: language.ACTION,
              thConfig: true,
              style: { width: '1%' },
              search: false,
            },
            {
              key: 'name',
              label: language.NAME,
              thConfig: true,
              style: { width: '11%' },
            },
            {
              key: 'type',
              label: language.TYPE,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'type_capital_expenditure',
              label: language.TYPE_OF_CAPITAL,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'company',
              label: language.COMPANY,
              thConfig: true,
              style: { width: '11%' },
            },
            {
              key: 'year',
              label: language.REAL + ' ' + language.COST + '/' + currentYear,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'foreseen',
              label: language.FORESEEN,
              thConfig: true,
              style: { width: '1.076923%' },
            },
            {
              key: 'realized',
              label: language.REALIZED,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'percentual',
              label: language.PERCENTAGE,
              thConfig: true,
              style: { width: '7.69230769%' },
            },

            {
              key: 'foreseen0',
              label: language.FORESEEN,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'realized0',
              label: language.REALIZED,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'Percentual0',
              label: language.PERCENTAGE,
              thConfig: true,
              style: { width: '7.69230769%' },
            },

            {
              key: 'foreseen1',
              label: language.FORESEEN,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'realized1',
              label: language.REALIZED,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
            {
              key: 'percentual1',
              label: language.PERCENTAGE,
              thConfig: true,
              style: { width: '7.69230769%' },
            },
          ]}
          rowsPerPageOptions={[15, 30, 45]}
          data={dataTable}
        />
      </div>
    </Fragment>
  );
}
