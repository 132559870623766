import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';


const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' }
  },
});
const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',


  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    labelPlacement: "top"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  buttonprimary: {
    margin: theme.spacing(1),
    backgroundColor: '#009dc0',
    color: 'white',
    size: '14px',
    weight: 500

  },
  TimePickerStyle: {
    marginTop: 2,
    size: '12'

  },
  DatePickerStyle: {
    marginTop: 10,
    size: '12'

  }

};


class CommonTest1 extends React.Component {

  render() {
    <div>
      <span>Oiiiiii Test</span>
    </div>
  }
}

export default CommonTest1