import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import Button from '@material-ui/core/Button';
import PageLayout from '../../common/PageLayout';
import { TextField } from '@material-ui/core';
import Help from '../../common/components/Help';
import PieChartGraph from './piechart/PieChartGraph';
import GestaoRisco_dash from './gestaoRisco_dash';
import { Select, InputLabel, MenuItem } from '@material-ui/core'; // controls
import ReactToPrint from 'react-to-print';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  Brush,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  YAxis,
  Legend,
  LabelList,
} from 'recharts';
import moment from 'moment';
import Animate from '@common/Animate';
import CommonForm from '@common/CommonForm2';

@observer
export default class RiskGeneralDashboard extends Component {
  render() {
    let {
      language,
      handleCompany,
      handlePage,
      companies,
      companyId,
      tests,
      maturity,
      risk,
    } = this.props;
    let companiesSelect = companies
      .filter((c) => c.type !== 2)
      .map((c) => (
        <MenuItem
          style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
          key={c.id_company}
          value={c.id_company}
          primaryText={c.name}
        >
          {c.name}
        </MenuItem>
      ));

    if (!tests.length) {
      return <div />;
    }

    let data = [];

    let classIcon = {
      'ISO 27001': 'iso27Icon',
      'ISO 20000': 'iso20Icon',
      COBIT: 'cobitIcon',
      'ISO 37001': 'iso27Icon',
      [language.IT_EVOLUTION_MANAGEMENT]: 'evolutionIcon',
      [language.BACKUP_MANAGEMENT]: 'backupManagementIcon',
      LGPD: 'bitdefenderIcon',
    };

    if (tests.length) {
      data = tests.map((t, k) => {
        let mat = 0;
        if (t.dash) {
          mat = t.dash.reduce(
            (value, obj) => value + (obj.value * 100) / obj.total,
            0
          );
          mat = (mat / t.dash.length).toFixed(2);
        }
        return { name: t.name, value: mat };
      });
    }

    return (
      <PageLayout
        icon={
          <div className="evolutionIcon" style={{ width: 44, height: 44 }} />
        }
        title={language.RISKS_MANAGEMENT}
        subTitle={language.RISKS_MANAGEMENT_COMPLIANCE + '/'}
        rightElements={[
          <div style={{ width: 300, zoom: 0.8, marginTop: '1rem' }} zoom={0.8}>
            <CommonForm
              values={{
                companyId,
              }}
              fields={[
                {
                  type: 'select',
                  name: 'companyId',
                  label: language.COMPANY,
                  options: companies.map((e) => ({
                    value: e.id_company,
                    label: e.name,
                  })),
                },
              ]}
              onChangeField={(event, key, values) => {
                handleCompany(key);
              }}
            />
          </div>
        ]}
      >
        {tests[0].dash ? (
          <Animate>
            <GestaoRisco_dash
              risk={risk}
              maturity={maturity}
              tests={tests}
              companyId={this.props.companyId}
              data={data}
              language={language}
            />
          </Animate>
        ) : (
          <h2 style={{ paddingTop: 30 }}>{language.RISKS_NO_TEST}</h2>
        )}
      </PageLayout>
    );
  }
}

class MaturityGraph extends Component {
  render() {
    let { title, value } = this.props;

    return (
      <Col md={3}>
        <Card>
          {title}
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '5px' }}
              >
                {language.PRINT}
              </Button>
            )}
            content={() => this.componentRef}
          />
          <ResponsiveContainer
            ref={(el) => (this.componentRef = el)}
            width="100%"
            height={354}
          >
            <ComposedChart
              height={300}
              data={[{ name: '', total: parseFloat(value) }]}
              margin={{ right: 25, top: 25, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
              <CartesianGrid stroke="#f5f5f5" />
              <Bar dataKey="total" barSize={50} fill="var(--primary)">
                <LabelList
                  dataKey="total"
                  position="top"
                  fill="var(--secundary)"
                  content={(a) => a.value + '%'}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    );
  }
}
