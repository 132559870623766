import React from 'react';
import './index.css';
// --------------------------------material-ui----------------------------------------------------
import { TextField, MenuItem } from '@material-ui/core';

// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
// --------------------------------components----------------------------------------------------
import Block_1 from './Block_1';
import Block_2 from './Block_2';
import Options from './options';
import CommonForm from '@common/CommonForm2';

export default class extends CommonPage {
	constructor() {
		super();
		this.state = {
			company: 1
		}

		this.handleSelectCompany = this.handleSelectCompany.bind(this);

	}
	componentDidMount() {
		this.action.execute('get', this.APIEndpoints.OKR, 'okrs');
		this.action.execute('get', this.APIRoot + '/api/projects', 'projects');
		this.action.execute('get', this.APIEndpoints.DEPARTMENT+'/'+ this.store.getUserLoged().id, 'departments' )
	}
	handleSelectCompany(value) {
		this.setState({ company: value });
	}
	render(){
		let { okrs, users, companies, language, projects, getUserLoged, departments } = this.store
		const { company } = this.state
		let options = Options({
			users,
			companies,
			projects
		})
		
		let listCompanies = companies.map(c => (
			<MenuItem
			  style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
			  key={c.id_company}
			  value={c.id_company}
			  primaryText={c.name}
			>
			  {c.name}
			</MenuItem>
		  ));
		//let departments = options.department(e => e.id_company == company)
		let departmentsOptions = departments.map(d => ({
			value : d.id_department,
			label : d.name
		})) 
	
		let projectList = options.projects(company)
		let usersList = options.users(company)
		
		/*----------------------------------------------------DADOS E PROPS DO BLOCO 1---------------------------------------------------------------------*/
		//---- ta no componente index do bloco 1
		/*----------------------------------------------------DADOS E PROPS DO BLOCO 2---------------------------------------------------------------------*/
		let okrsMapped = [];
		if (okrs.length) {
			okrsMapped = okrs.filter(o => o.fk_id_company === company).map(okr => {
				let results = [];
				let subactivities = [];
				let media = 0;
				if (!!okr.results) {
					
					
					//povoar key results
					results = okr.results.map(res => {
						media += res.percent;
						if (!!res.subactivities) {
	
							//POVOAR SUBACTIVITIES
							subactivities = res.subactivities.map(sub => ({
								id: sub.id_okr_results_subactivies,
								subactivities: sub.description,
								sponsor: sub.responsibles.map(sr => ({src: undefined, ...sr})),
								deadline: sub.deadline_subactivity,
								punctuation: 0,//a definir
								expected_result: sub.result_subactivity,
								state:true
							}))
	
						}

						let type = res.fk_id_ticket ? `Ticket #${res.fk_id_ticket} - ` : res.fk_id_project_activity ? `Atividade #${res.fk_id_project_activity} - ` : ''
						return {
							id_okr_results: res.id_okr_results,
							keys: res.key_result,
							priorization: res.priorization_result,
							appreciate: res.appreciate,
							expected_result: res.result_expected,
							fk_id_ticket: res.fk_id_ticket,
							fk_id_user_approver: res.fk_id_user_approver,
							name_user_approver: res.name_user_approver,
							fk_id_project_activity: res.fk_id_project_activity,
							sponsor: res.responsibles.map(r => ({src: undefined, ...r})),
							date: res.deadline,
							association: `${type}${res.activity_associate}`,
							activity_total: !!res.subactivities ? res.subactivities.length : 0,
							period: res.period_result,
							punctuation: 0, //a definir,
							status: res.percent,
							dataNivel: subactivities
						}

					})
					media = isNaN(media / results.length) ? 0 : media / results.length
				}
				return {
					id_okr_objective: okr.id_okr_objective,
					department: okr.fk_id_department,
					objective: okr.description_objective,
					keys_total: !!okr.results ? okr.results.length : 0,
					status: media, //a definir
					dataNivel: results
				}
			})
		}
		let Block_2_props = {
			data: okrsMapped /*[
				{
					objective:'Surpreender com as melhorias',
					keys_total:3,
					status:64,
					dataNivel:[ // --- dados do Nivel_1
						{
							prioritization:3,
							keys:'KR 3',
							keys:'KR 3',
							expected_result:'Ter avaliação de ao menos 8.0 de satisfação de uso da solução de evoluções',
							sponsor:[
								{name:'Rodrigo', src:undefined},
								{name:'Fábio', src:undefined},
								{name:'Fabrício', src:undefined},
								{name:'Luiz', src:undefined},
							],
							date:'24/08/2020',
							activity_total:5,
							period:'Trimestral',
							punctuation:3,
							status:64,
							dataNivel:[ // --- dados do Nivel_2
								{
									subactivities:'Ter avaliação de ao menos 8.0 de satisfação de uso da solução de evoluções',
									sponsor:[
										{name:'Rodrigo', src:undefined},
										{name:'Fábio', src:undefined},
										{name:'Fabrício', src:undefined},
										{name:'Luiz', src:undefined},
									],
									deadline:'24/08/2020',
									punctuation:3,
									expected_result:'Ter avaliação de ao menos 8.0 de satisfação de uso da solução de evoluções',
									state:true
								}
							]
						}
					]
				}
			] */
		}
		let userOn = getUserLoged();

		return(
			<CommonPage
			  showMenuIconButton={false}
			  iconAlert={true}
			  loading={false}
			>
				<PageLayout
				  title={'OKR'}
				  subTitle={'CIO COCKPIT/'}
				  rightElements={[
				
				
					<div style={{ width: 300, zoom: 0.8, marginTop: '1rem'}} zoom={0.8} >
					<CommonForm
						values = {
		  					{
								  company
							  }
						}

					fields={[
							{
								type: 'select',
								name: 'company',
								label: language.COMPANY,
								options: companies.map(e => ({
									value: e.id_company,
									label: e.name
								}))
							}
						]}
						onChangeField={(event, key, values) => {
							this.handleSelectCompany(key);
					this.setState({id_c:null})

					} }
					/>
				</div>


			/*
					<TextField
					id="outlined-select-currency"
					select
					label={language.COMPANY}
					style={{ minWidth: 200, marginLeft: '1em', marginTop: '1em' }}
					value={this.state.company}
					onChange={(event, key, values) => this.handleSelectCompany(event.target.value)}
					margin="dense"
					variant="outlined"
					className="input-min"
					SelectProps={{
					  className:'select-min'
					}}
					>
					  {listCompanies}
					</TextField>
				
			*/
			
			]}
				>
					<div id='OKR'>
						<Block_1 
							action={this.action}
							APIEndpoints={this.APIEndpoints}
							companyId={company} 
						/>
						<Block_2 
							departments={departmentsOptions} 
							projectsList={projectList} 
							projects={projects}
							companyId={company} 
							users={usersList}
							action={this.action}
							APIEndpoints={this.APIEndpoints}
							userOn={userOn}
							{...Block_2_props}
						/>
					</div>
				</PageLayout>
			</CommonPage>
		)
	}
}