import React, { Component } from 'react';

import moment from 'moment';
import CommonPage from '../../common/CommonPage';
import ChangeManagementList from './ChangeManagementList';
import ActionsComponents from './ActionsComponents';
import AnalyzePage from './AnalyzePage';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
import Complete from './Complete';
import Standard from './Standart';
import CommonForm from '@common/CommonForm2';
import DialogConfirm from '@common/DialogConfirm';
import Dialog from '@common/Dialog';
import { FaSpinner } from 'react-icons/lib/fa';
import LinearProgress from '@material-ui/core/LinearProgress';
import Store from '@data/Store';
import { toBase64 } from '../../common/Func';
import { concatDateHour } from '../../util/dateTime';

export default class ChangeManagementContainer extends CommonPage {
  constructor() {
    super();

    this.state = {
      page: 'list',
      ticket: null,
      finished: false,
      stepIndex: 0,
      edit: false,
      change: null,
      stand: false,
      openanalyse: false,
      openview: false,
      loading: true,
      changes: [],
      changesCount: 0,
      filter: null,
      sortBy: 'id_change',
      sortByDesc: true,
      id_company: 0,
      reaload: false,
      template: false,
      params: [],
      createToTemplate: false,
      createToTemplate_fields: false,
      cabBoard: '',
      change_selected: [],
      companyId: [],
      statusId: [],
    };

    this.getData = this.getData.bind(this);
    this.setReload = this.setReload.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.callSortItem = this.callSortItem.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.refreshChange = this.refreshChange.bind(this);
    this.callPagination = this.callPagination.bind(this);
    this.callDataFilter = this.callDataFilter.bind(this);
    this.onCancelChange = this.onCancelChange.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    this.updateChange = this.updateChange.bind(this);
  }

  setReload() {
    this.setState({ reaload: true });
  }
  componentDidUpdate(prevProps) {
    try {
      if (prevProps.location.pathname != this.props.location.pathname) {
        this.getData();
      }
    } catch (e) {}
  }
  componentWillMount() {
    this.action
      .execute('get', this.APIEndpoints.TICKET + '/params', 'params')
      .then((item) => {
        this.setState({ params: item.data[0] });
      });

    this.getData();
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  callSortItem(sortBy, sortByDesc) {
    this.setState({ sortBy: sortBy, sortByDesc: sortByDesc });

    let limit = 75;
    let offset = 0;

    let values = {
      ...this.state.filter,
      sortBy: sortBy,
      new: true,
      sortByDesc: sortByDesc,
      fk_id_company: this.state.id_company,
    };

    this.action
      .execute('post', `${this.APIEndpoints.CHANGE}/getAllChanges/?offset=${offset}&limit=${limit}`, '', values)
      .then((res) => {
        this.setState({
          changes: res.data,
          changesCount: res.totalCount
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  callPagination(page) {}
  cleanFilters() {
    this.callPageSelectFilter();
  }

  callPageSelectFilter(page = 0, items = {}, rowPerPage = 75, type) {
    items.multiple_status = this.state.statusId;
    items.multiple_companies = this.state.companyId;
    this.setState({
      filter: items,
    });
    let { pathname } = this.history2.location;
    let limit = rowPerPage;
    let typeTemp = false;
    if (testRoute('/dash/configuration/changemanagement', pathname) == true) {
      typeTemp = true;
    }
    let offset = page * limit;

    let values = {
      ...items,
      new: true,
      sortyBy: this.state.sortBy,
      sortByDesc: this.state.sortByDesc,
      fk_id_company: this.state.id_company,
      template: typeTemp,
    };

    this.action
      .execute('post', `${this.APIEndpoints.CHANGE}/getAllChanges/?offset=${offset}&limit=${limit}`, '', values)
      .then((res) => {
        this.setState({
          changes: res.data,
          changesCount: res.totalCount
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  callDataFilter(items = {}) {}

  handleCompany(type, ids) {
    if (type == 'status_multiple') {
      this.setState({ statusId: ids });
    } else {
      this.setState({ companyId: ids });
    }

    let values = this.state.filter;
    values[type] = ids;
    this.setState({ filter: values });
    let { state, pathname } = this.history2.location;
    let limit = 75;
    let typeTemp = false;
    if (testRoute('/dash/configuration/changemanagement', pathname) == true) {
      typeTemp = true;
    }

    let offset = 0;

    let payload = {
      ...values,
      template: typeTemp,
      new: true
    };

    this.action
      .execute('post', `${this.APIEndpoints.CHANGE}/getAllChanges/?offset=${offset}&limit=${limit}`, '', payload)
      .then((res) => {
        this.setState({
          changes: res.data,
          changesCount: res.totalCount
        });
      });
  }
  componentDidUpdate(prevProps) {
    try {
      if (prevProps.location.pathname != this.props.location.pathname) {
        this.getData();
      }
    } catch (e) {}
  }
  getData() {
    let { state, pathname } = this.history2.location;
    var values = state;
    try {
      let i = this.props.location.state.ticket;
      if (this.props.location.state.ticket != undefined) {
        this.setState({
          ticket: this.props.location.state.ticket,
          page: 'new-complete',
          stand: false,
        });
        this.redirect2('/dash/changemanagement/new');
      }
    } catch (e) {}

    // this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
    //  this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    //this.action.execute('get', this.APIEndpoints.CHANGE, 'changes');

    ///dash/configuration/changemanagement
    let typeTemp = false;

    if (testRoute('/dash/configuration/changemanagement', pathname) == true) {
      typeTemp = true;
    }

    this.setState({ cabBoard: '' });

    if (values) {
      if (
        testRoute(
          /\/dash\/changemanagement\/(edit|analyze|view|risk_milestone)/,
          pathname
        )
      ) {
        this.action
          .execute(
            'get',
            `${this.APIEndpoints.CHANGE}/${values}`,
            'change_selected'
          )
          .then((res) => this.setState({ change_selected: res.data }));
        this.action.execute(
          'get',
          `${this.APIEndpoints.CHANGE}/activities_milestone/${values}`,
          'activities',
          null,
          null,
          { btn_loading: 'change_risks' }
        );
        this.action
          .execute(
            'get',
            `${this.APIEndpoints.CHANGE}/${values}/cab`,
            'change_risks',
            null,
            null,
            { btn_loading: 'change_risks' }
          )
          .then((g) => {});
        this.setState({ stepIndex: 0 });
      } else if (testRoute(/\/dash\/changemanagement\/new/, pathname)) {
        this.action.execute('get', this.APIEndpoints.CHANGE, 'changes');
      }

      if (testRoute('/dash/changemanagement/view', pathname)) {
        setTimeout(() => {
          this.setState({ openview: true });
        }, 2000);
        this.action
          .execute(
            'get',
            this.APIEndpoints.CHANGE + '/' + values + '/cab/board',
            ''
          )
          .then((res) => {
            if (res.data && res.data.cab_id) {
              this.setState({ cabBoard: res.data });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (testRoute('/dash/changemanagement/analyze', pathname)) {
        this.action
          .execute(
            'get',
            `${this.APIEndpoints.CHANGE}/${values}/cab`,
            'change_risks',
            null,
            null,
            { btn_loading: 'change_risks' }
          )
          .then((g) => {
            this.action
              .execute(
                'get',
                `${this.APIEndpoints.CHANGE}/${values}/rfc`,
                'change'
              )
              .then((g) => {
                this.action
                  .execute(
                    'get',
                    `${this.APIEndpoints.CHANGE}/${values}`,
                    'change_selected'
                  )
                  .then((g) => {
                    this.setState({ change_selected: g.data });
                    this.setState({ openanalyse: true });
                  });
              });
          });
        this.action
          .execute(
            'get',
            this.APIEndpoints.CHANGE + '/' + values + '/cab/board',
            ''
          )
          .then((res) => {
            if (res.data && res.data.cab_id) {
              this.setState({ cabBoard: res.data });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  refreshChange(values) {
    this.action
      .execute(
        'get',
        `${this.APIEndpoints.CHANGE}/${values}`,
        'change_selected'
      )
      .then((res) => this.setState({ change_selected: res.data }))
      .catch((error) => {
        console.error(error);
      });
  }

  updateChange(id_change){
    this.action
      .execute(
        'get',
        `${this.APIEndpoints.CHANGE}/${id_change}`,
        'change_selected'
      )
      .then((res) => this.setState({ change_selected: res.data }))
      .catch((error) => {
        console.error(error);
      });
  }

  handlePage(page, values, cretatotemplate) {
    if (page === 'analyze')
      this.action
        .execute(
          'get',
          `${this.APIEndpoints.CHANGE}/${values}`,
          'change_selected'
        )
        .then((res) => this.setState({ change_selected: res.data }))
        .catch((error) => {
          console.error(error);
        });
    if (page === 'list')
      this.action.execute('get', this.APIEndpoints.CHANGE, 'changes'),
        this.action.execute(
          'get',
          this.APIEndpoints.CHANGE_MILESTONES,
          'all_milestones'
        ),
        this.action.execute(
          'get',
          this.APIEndpoints.AMOUNT_RISK,
          'amount_risks'
        );
    if (['edit', 'risk_milestone'].includes(page))
      this.action.execute(
        'get',
        `${this.APIEndpoints.CHANGE}/${values}`,
        'change'
      );

    if (page == 'view') {
    }
    if (page == 'edit-complete') {
      if (cretatotemplate) {
      } else {
        if (values.type_gmud == 2) {
          this.setState({ stand: true });
        } else {
          this.setState({ stand: false });
        }
      }

      this.action
        .execute(
          'get',
          `${this.APIEndpoints.CHANGE}/${values.id_change}`,
          'change'
        )
        .then((g) => {
          if (cretatotemplate) {
            g.data.cretaToTemplate = true;
            if (g.data.type_gmud == 2) {
              this.setState({ stand: true });
            } else {
              this.setState({ stand: false });
            }
          }
          this.setState({
            page: page,
            change: g.data,
            createToTemplate: cretatotemplate ? true : false,
            createToTemplate_fields: cretatotemplate ? true : false,
          });
        });
    } else if (page == 'remove-complete') {
      this.setState({ page: page, change: values });
    } else {
      this.store.change_risks = [];
      this.setState({ page: page, change: values, values });
    }
  }

  async onCancelChange(values) {
    const { change } = this.state;
    change.action = 'cancel';
    change.log = values.log;

    if (values.file && values.file[0]) {
      const file = values.file[0]
      change.file = [{
        file: (await toBase64([file]))[0],
        namefile: file.name,
        type: file.type
      }]
    }

    this.action
      .execute(
        'put',
        `${this.APIEndpoints.CHANGE}/${change.id_change}`,
        'change',
        change,
        'id_change'
      )
      .then((e) => this.redirect('/dash/changemanagement'))
      .catch((e) => {
        this.store.toggleAlert(true, e.data.code, 'error');
      });
  }

  handlePrev = () => {
    let { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };
  handleNext = (idChange) => {
    let { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 2,
    });
  };

  onFormSubmit(values, form) {
    let { state, pathname } = this.history2.location;
    values = Object.assign({}, values);
    if (values.initial_date != undefined) {
      values.start_date = concatDateHour(values.initial_date, values.initial_hour);
      values.end_date = concatDateHour(values.final_date, values.final_hour);
      ['initial_date', 'initial_hour', 'final_date', 'final_hour'].map(
        (d) => delete values[d]
      );
    }
    values.users = {};
    ['key_users', 'manager', 'outs', 'solicitant', 'techs'].map((d) => {
      values.users[d] = values[d];
      delete values[d];
    });
    this.action.execute(
      'post',
      `${this.APIEndpoints.CHANGE}`,
      'change',
      values,
      'id_change'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.CHANGE_MILESTONES,
      'all_milestones'
    );
    //  this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
    if (pathname == '/dash/changemanagement/edit') {
      this.redirect('/dash/changemanagement');
    }
    if (pathname == '/dash/changemanagement/new') {
      setTimeout(() => {
        this.action.execute('get', this.APIEndpoints.CHANGE + '/0', 'change');
        this.handleNext(0);
      }, 4000);
    }
  }

  changeStatus(action, change, send, dataApprove ={}) {
    const {language} = this.store;
    if (['cancel', 'reschedule'].includes(action)) {
      change.action = action;
      this.action
        .execute(
          'put',
          `${this.APIEndpoints.CHANGE}/${change.fk_id_change}`,
          'change',
          change,
          'id_change'
        )
        .then((e) => this.redirect('/dash/changemanagement'))
        .catch((e) => {
          this.store.toggleAlert(true, e.data.code, 'error');
        });
    } else if (['approve', 'reject'].includes(action)) {
      const data = {
        fk_id_change : change.fk_id_change,
        type_approve : dataApprove.type_approve,
        id_approve : dataApprove.id_approve,
        action : action,
        log : change.log,
        approved : change.approved,
        sys_id : change.sys_id,
        fromAPI : false
      }
      this.action
        .execute(
          'post',
          `${this.APIEndpoints.CHANGE}/revision`,
          'change',
          data,
          'id_change'
        )
        .then((e) => {
         if (e.data.erro == 20) {
            this.store.toggleAlert(
              true,
              language.USER_DOES_NOT_HAVE_PERMISSION,
              'error'
            );
          } else if (e.data.erro == 30) {
            this.store.toggleAlert(
              true,
              language.USER_HAS_ALREADY_PERFORMED_THIS_ACTION,
              'error'
            );
          } else {
            if (action == 'approve'){
              this.updateChange(change.fk_id_change);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }else{
              this.redirect('/dash/changemanagement');
            }
          }
        }, (error) => {
          this.store.toggleAlert(
            true,
            language.AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER,
            'error'
          );
        });
    } else {
      if (send == true) {
        this.action
          .execute(
            'put',
            `${this.APIEndpoints.CHANGE}/${change.fk_id_change}`,
            'changes',
            { action: action, send: true },
            'id_change'
          )
          .then((g) => {
            this.store.toggleAlert(
              true,
              language.EMAIL_SUCCESSFULLY_RESENT,
              'success'
            );
          });
      } else {
        const body = change.fk_id_change ? change : { action: action };
        const id_change = change.fk_id_change ? change.fk_id_change : change;
        this.action
          .execute(
            'put',
            `${this.APIEndpoints.CHANGE}/${id_change}`,
            'changes',
            body,
            'id_change'
          )
          .then((g) => {
            if (action == 'close') {
              this.store.toggleAlert(
                true,
                language.GMUD_SUCCESSFULLY_FINISHED,
                'success'
              );
            }
            setTimeout(() => {
              this.redirect('/dash/changemanagement');
            }, 1000);
          });
      }
    }
  }

  render() {
    let { ticket, page, changes, changesCount, cabBoard } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    let { state, pathname } = history.location;
    let redirects = this.redirect;
    let { change, language } = this.store;
    let typeTemp = false;

    if (testRoute('/dash/configuration/changemanagement', pathname) == true) {
      typeTemp = true;
    }
    return (
      <>
        {!this.state.loading ? (
          <CommonPage
            title={
              <div
                className="logoWhite"
                onClick={() => this.redirect('/dash')}
              />
            }
            iconAlert={true}
            loading={false}
          >
            <DialogConfirm
              open={page == 'confirm'}
              maxWidth="xs"
              onClose={() => this.handlePage('list')}
              title={
                typeTemp
                  ? language.GENERATE_NEW_CHANGE_TEMPLATE
                  : language.GENERATE_NEW_CHANGE
              }
              message={language.ALERT_WANT_GENERATE_DETAILED_GMUD}
              confirmLabel={language.COMPLETE}
              cancelLabel={language.STANDARD}
              otherLabel={language._TEMPLATE}
              showCancel={typeTemp ? true : false}
              showOther={typeTemp ? false : true}
              gmud={true}
              cancelProps={{
                color: 'primary',
              }}
              eventClose
              onConfirm={() => {
                this.handlePage('new-complete');
                this.setState({ stand: false });
              }}
              onCancel={() => {
                this.handlePage('new-complete');
                this.setState({ stand: true });
              }}
              onOther={() => {
                //this.handlePage('new-complete');
                this.setState({ template: true });
              }}
            />

            {(testRoute('/dash/changemanagement', pathname) ||
              testRoute('/dash/configuration/changemanagement', pathname)) && (
              <Animate>
                <ChangeManagementList
                  {...this.store}
                  typeTemp={typeTemp}
                  handlePage={this.handlePage}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  changeStatus={this.changeStatus}
                  redirect={redirect}
                  redirects={redirects}
                  redirect2={this.redirect2}
                  allChanges={changes}
                  changesCount={changesCount}
                  callPagination={this.callPagination}
                  callDataFilter={this.callDataFilter}
                  callPageSelectFilter={this.callPageSelectFilter}
                  callSortItem={this.callSortItem}
                  handleCompany={this.handleCompany}
                  cleanFilters={this.cleanFilters}
                />
              </Animate>
            )}

            {['new-complete', 'edit-complete'].includes(page) ? (
              <Complete
                ticketparam={ticket}
                {...this.state}
                {...this.store}
                userl={this.store.getUserLoged()}
                resetTemplate={() => {
                  this.setState({ createToTemplate: false });
                }}
                changeitem={this.state.change}
                page={page.replace('-complete', '')}
                params={this.state.params}
                action={this.action}
                APIEndpoints={this.APIEndpoints}
                typeTemp={typeTemp}
                edit={this.state.edit}
                finished={this.state.finished}
                stand={this.state.stand}
                setReload={this.setReload}
                stepIndex={this.state.stepIndex}
                handleNext={this.handleNext}
                handlePrev={this.handlePrev}
                handlePage={this.handlePage}
                onFormSubmit={this.onFormSubmit}
                redirect={redirect}
                language={language}
                open={true}
                onClose={() =>
                  this.state.reaload == true
                    ? this.redirect('/dash/changemanagement')
                    : this.handlePage('list')
                }
                getData={this.getData}
              />
            ) : null}

            {['remove-complete'].includes(page) && (
              <Dialog
                open={page == 'remove-complete'}
                onClose={() => {
                  this.setState({ page: '', change: '' });
                }}
                contentStyle={{ height: '80%', maxWidth: 'none' }}
                fullWidth={true}
                maxWidth="lg"
                autoScrollBodyContent={true}
                title={language.CANCEL}
              >
                <div className="container" style={{ padding: '20px 0' }}>
                  <CommonForm
                    language={language}
                    fields={[
                      {
                        span: 12,
                        name: 'log',
                        label: language.JUSTIFICATION,
                        type: 'text',
                        required: true,
                      },
                      {
                        col: 12,
                        type: 'file',
                        name: 'file',
                        label: language.ATTACH,
                        maxFiles: 1
                      }
                    ]}
                    button={{
                      fluid: true,
                      size: 1,
                      style: {
                        height: 40,
                      },
                    }}
                    onSubmit={this.onCancelChange}
                  />
                </div>
              </Dialog>
            )}

            {testRoute(
              /\/dash\/changemanagement\/(edit|new|risk_milestone)/,
              pathname
            ) && (
              <Animate>
                <ChangeManagementList
                  {...this.store}
                  handlePage={this.handlePage}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  typeTemp={typeTemp}
                  changeStatus={this.changeStatus}
                  redirect={redirect}
                  redirects={redirects}
                  redirect2={this.redirect2}
                  allChanges={changes}
                  changesCount={changesCount}
                  callPagination={this.callPagination}
                  callDataFilter={this.callDataFilter}
                  callPageSelectFilter={this.callPageSelectFilter}
                  handleCompany={this.handleCompany}
                  cleanFilters={this.cleanFilters}
                />
              </Animate>
            )}
            {testRoute('/dash/changemanagement/view', pathname) &&
              this.state.openview == true && (
                <Animate>
                  <ActionsComponents
                    type="view"
                    APIEndpoints={this.APIEndpoints}
                    action={this.action}
                    userl={this.store.getUserLoged()}
                    language={language}
                    changeId={this.state.change}
                    companies={this.store.companies}
                    contracts={this.store.contracts}
                    users={this.store.users}
                    typeTemp={typeTemp}
                    redirect={redirects}
                    devices={this.store.devices}
                    changeStatus={this.changeStatus}
                    refreshChange={this.refreshChange}
                    change={this.state.change_selected}
                    activities_={this.store.activities}
                    change_risks={this.store.change_risks.risks || []}
                    store={this.store}
                    cabBoard={cabBoard}
                  />
                </Animate>
              )}

            <Dialog
              onClose={() => {
                this.setState({ template: false });
              }}
              open={this.state.template}
              maxWidth="lg"
              title={language.ENTER_TEMPLATE_BELOW}
            >
              <div style={{ height: '200px' }}>
                <CommonForm
                  language={language}
                  onChangeField={(f, v) => {
                    if (v > 0) {
                      this.setState({ template: false });
                      this.handlePage('edit-complete', { id_change: v }, true);
                    }
                  }}
                  fields={[
                    {
                      col: 12,
                      type: 'autocomplete',
                      name: 'id_change',
                      label: 'Gmud',
                      textlabel: 'Gmud',
                      method: 'POST',
                      route: `${this.APIEndpoints.CHANGE}/getAllChanges/`,
                      routeGetCount: `${this.APIEndpoints.CHANGE}/getAllChanges?count=1`,
                      fieldquery: 'search',
                      filterAll: { template: true, new: true },
                      filter: { template: true },
                      nosearch: true,
                      textinit: '',
                      fieldvaluedb: 'id_change',
                      fieldlabeldb: 'reason',
                      idinit: '',
                      routeAll: `${this.APIEndpoints.CHANGE}/getAllChanges?`,
                      methodGetAll: 'POST',
                      required: true,
                      disablePortal: true,
                      showId: true,
                      idFieldToShow: 'id_change',
                    },
                  ]}
                ></CommonForm>
              </div>
            </Dialog>

            {testRoute('/dash/changemanagement/analyze', pathname) &&
              this.store.activities.length > 0 &&
              this.state.openanalyse == true && (
                <Animate>
                  <ActionsComponents
                    type="view"
                    APIEndpoints={this.APIEndpoints}
                    activities_={this.store.activities}
                    typeTemp={typeTemp}
                    action={this.action}
                    userl={this.store.getUserLoged()}
                    language={language}
                    changeId={this.state.change}
                    companies={this.store.companies}
                    contracts={this.store.contracts}
                    redirect={redirects}
                    users={this.store.users}
                    change_risks={this.store.change_risks.risks || []}
                    devices={this.store.devices}
                    changeStatus={this.changeStatus}
                    change={this.state.change_selected}
                    activities={this.store.activities}
                    refreshChange={this.refreshChange}
                    cabBoard={cabBoard}
                    updateChange = {this.updateChange}
                  />
                </Animate>
              )}

            {this.state.page == 'an' && (
              <AnalyzePage
                {...this.store}
                getUserLoged={this.store.getUserLoged}
                redirect={redirect}
                typeTemp={typeTemp}
                handlePage={this.handlePage}
                changeStatus={this.changeStatus}
              />
            )}
          </CommonPage>
        ) : (
          <CommonPage
            title={
              <div
                className="logoWhite"
                onClick={() => this.redirect('/dash')}
              />
            }
            loading={false}
          >
            <LinearProgress />
          </CommonPage>
        )}
      </>
    );
  }
}
