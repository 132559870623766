import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import FormBuilderList from './FormBuilderList';
import FormBuilderForm from './FormBuilderForm';
import Dialog from '@common/Dialog';
import Animate from '@common/Animate';
import { post } from 'fetch';

@observer
export default class FormBuilderContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      modal: false,
    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.onDeleteTask = this.onDeleteTask.bind(this);
    this.reloadFormTemplate = this.reloadFormTemplate.bind(this);
  }

  reloadFormTemplate() {
    window.location.reload();
  }

  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.TASK + '/getform',
      'formstemplates'
    );

    this.action.execute(
      'get',
      `${this.APIEndpoints.TABLE_STRUCTURED}/50`,
      'impacts'
    );
    this.action.execute(
      'get',
      `${this.APIEndpoints.TABLE_STRUCTURED}/60`,
      'priorities'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/40',
      'urgencies'
    );
  }

  onFormSubmitClone() {}

  cloneTask = (id) => {
    post(
      '/api/task/form/' + id + '/clone',
      'post-tasks, post-formstemplates',
      undefined
    ).then((g) => {
      window.location.reload();
    });
  };

  saveForm(html, values, data, dt, name) {
    values.html = html;
    values.data = data;
    values.name_ = name;
    try {
      values.id_template_category = dt.id_template_category;
    } catch (e) {
      values.id_template_category = undefined;
    }

    this.action
      .execute(
        'post',
        this.APIEndpoints.TASK + '/form',
        'formstemplates',
        values
      )
      .then((c) => {
        this.store.toggleAlert(true, 'Item salvo com sucesso', 'success');
        window.location.reload();
      });
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  onFormSubmit(values) {
    values.template = true;
    let method = values.hasOwnProperty('id_catalog_task') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_catalog_task')
      ? this.APIEndpoints.TASK + '/' + values.id_catalog_task
      : this.APIEndpoints.TASK;
    this.action
      .execute(method, url, 'tasks', values, 'id_catalog_task', {
        btn_loading: 'post-put-template',
      })
      .then((e) => this.handlePage('list'));
    setTimeout(() => {
      this.store.toggleAlert(true, 'Item salvo com sucesso', 'success');
    }, 1000);

    setTimeout(() => {
      this.redirect('/dash/configuration/template');
    }, 2000);
  }

  onDeleteTask() {
    let { values } = this.state;
    this.action
      .execute(
        'delete',
        this.APIEndpoints.TASK + '/form/' + values.id_template_category,
        'formstemplates',
        values,
        'id_template_category'
      )
      .then((h) => {
        this.action
          .execute('get', this.APIEndpoints.TASK + '/getform', 'formstemplates')
          .then((g) => {
            this.store.formstemplates = g.data;
          });
      });

    setTimeout(() => {
      this.redirect('/dash/configuration/template');
    }, 2000);
  }
  onDelete() {
    let { values } = this.state;
    this.action
      .execute(
        'delete',
        this.APIEndpoints.TASK + '/form/' + values.id_template_forms,
        'formstemplates',
        values,
        'id_template_category'
      )
      .then((h) => {
        var filtered = this.store.formstemplates.filter(function (el) {
          return el.id_template_forms != values.id_template_forms;
        });
        this.store.formstemplates = filtered;
      });
    setTimeout(() => {
      this.redirect('/dash/configuration/template');
    }, 2000);
  }

  render() {
    let { language, tasks, impacts, priorities, urgencies, formstemplates } =
      this.store;
    let { page, values } = this.state;

    let history = this.history2;
    let redirect = this.redirect2;
    let { pathname, state } = history.location;

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <FormBuilderList
            language={language}
            redirect={redirect}
            cloneTask={this.cloneTask}
            handlePage={this.handlePage}
            tasks={formstemplates}
            {...this.state}
            values={values}
            impacts={impacts}
            priorities={priorities}
            configurationProps={configurationProps}
            urgencies={urgencies}
          />
        </Animate>
        {['new', 'edit'].includes(page) && (
          <Dialog
            title={!values ? language.ADD_FORM : language.EDIT_FORM}
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="md"
          >
            <FormBuilderForm
              saveForm={this.saveForm}
              language={language}
              formstemplates={formstemplates}
              handlePage={this.handlePage}
              onFormSubmit={this.onFormSubmit}
              tasks={tasks}
              reloadFormTemplate={this.reloadFormTemplate}
              redirect={redirect}
              impacts={impacts}
              priorities={priorities}
              urgencies={urgencies}
              {...this.state}
              values={values}
            />
          </Dialog>
        )}
        {page === 'remove' && (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={this.state.values.name}
          />
        )}
      </Fragment>
    );
  }
}
