import React, { memo } from 'react';
import Constants from '@data/Constants'
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';

const { language } = Store;

const MyHeader = ({
   title,
   subTitle,
   className,
   isAutocompleteField
}) => (
   <AppBar
      contentProps={{
         className: 'pl-0 ' + className
      }}
      titleProps={{
         style: { color: 'var(--primary)', marginTop: isAutocompleteField ? 15 : 0 }
      }}
      title={title}
      subTitle={subTitle}
   />
)

export default memo(({
   options,
   values,
   setForm,
   h,
   formValues
}) => {

   return (
      <div className='wrap mb-3'>
         <AppBar title={language.INVOLVED_IN_THE_PROJECT} />

         <div className='content'>
            <CommonForm
               onRef={r => setForm(r)}
               values={values}
               fields={[
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.USERS_INVOLVED}
                           subTitle={language.SELECT_USERS_INVOLVED_IN_THIS_PROJECT}
                           isAutocompleteField = {true}
                        />
                     ),
                     type: 'autocomplete',
                     name: 'users',
                     label: `${language.USERS_INVOLVED} *`,
                     method: 'POST',
                     route: `${Constants.APIEndpoints.USER}/filter`,
                     routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                     fieldquery: 'search',
                     textinit: formValues.name_users ? formValues.name_users: '',
                     fieldvaluedb: 'id_user',
                     fieldlabeldb: 'name',
                     idinit: formValues.users ?  Array.from(formValues.users): '',
                     routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                     filterAll: { all_fk_id_company: formValues.fk_id_company || 0},
                     methodGetAll: 'POST',
                     required: true,
                     isMulti:true,
                     disablePortal: true,
                  }, 
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.RESPONSIBLE_FOR_APPROVAL}
                           subTitle={language.SELECT_THE_TECHNICIAN_WHO_WILL_BE_RESPONSIBLE_FOR_THE_APPROVAL}
                           isAutocompleteField = {true}
                        />
                     ),
                     type: 'autocomplete',
                     name: 'fk_id_sponsor',
                     label: `${language.RESPONSIBLE_FOR_APPROVAL} *`,
                     method: 'POST',
                     filter: { fk_id_company: formValues.fk_id_company || 0, notcurrent:true },
                     route: `${Constants.APIEndpoints.USER}/filter`,
                     routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${formValues.fk_id_company || 0}`,
                     fieldquery: 'search',
                     textinit: formValues.fk_id_sponsor ? formValues.namesponsor : '',
                     fieldvaluedb: 'id_user',
                     fieldlabeldb: 'name',
                     idinit: formValues.fk_id_sponsor ? formValues.fk_id_sponsor  : null,
                     routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                     filterAll: { fk_id_company: formValues.fk_id_company || 0,notcurrent:true},
                     methodGetAll: 'POST',
                     detail: true,
                     required: true,
                     disablePortal: true,
                  },
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.PROJECT_MANAGER}
                           subTitle={language.SELECT_THE_MANAGER_WHO_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT}
                           isAutocompleteField = {true}
                        />
                     ),

                     
                     type: 'autocomplete',
                     name: 'user_cad',
                     label: `${language.PROJECT_MANAGER} *`,
                     method: 'POST',
                     filter: { fk_id_company: formValues.fk_id_company || 0 , notcurrent:true},
                     route: `${Constants.APIEndpoints.USER}/filter`,
                     routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${formValues.fk_id_company || 0}`,
                     fieldquery: 'search',
                     textinit: formValues.user_cad ? formValues.usercadname : '',
                     fieldvaluedb: 'id_user',
                     fieldlabeldb: 'name',
                     idinit: formValues.user_cad ? formValues.user_cad  : null,
                     routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                     filterAll: { fk_id_company: formValues.fk_id_company || 0,notcurrent:true},
                     methodGetAll: 'POST',
                     detail: true,
                     required: true,
                     disablePortal: true,
                  },
               ]}
               onChangeField={(f, v) => {
                  h(v, f.name);
               }}
            />
         </div>
      </div>
   )
})