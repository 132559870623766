import React, { Component } from 'react';
import FaClose from 'react-icons/lib/fa/close';
import { Card, Paper } from '@material-ui/core';

import Dialog from '../../common/Dialog';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm2';

export default class Ad extends Component {
  render() {
    let { language, handlePage, onFormSubmit, perfil, values } = this.props;

    let fields = [
      {
        col: 6,
        name: 'url',
        label: 'LDAP://IP:' + language.PORT.toUpperCase(),
        type: 'text',
        required: true,
      },
      { col: 6, name: 'dc', label: 'DC', type: 'text', required: true },
      {
        col: 6,
        name: 'user',
        label: language.USER,
        type: 'text',
        required: true,
      },
      {
        col: 6,
        name: 'password',
        label: language.PASSWORD,
        type: 'password',
        required: true,
      },
      {
        col: 6,
        name: 'fk_id_perfil',
        label: language.PROFILE,
        type: 'select',
        required: true,
        options: perfil.map((v) => ({ value: v.perfil_id, label: v.name })),
      },
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog
        modal={true}
        open={true}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        title={language.ADD + ' LDAP'}
        autoScrollBodyContent={true}
        onClose={() => handlePage('list')}
      >
        <div className="container" style={{ padding: '20px 0' }}>
          <CommonForm
            values={values}
            fields={fields}
            onSubmit={onFormSubmit}
            button={button}
          />
        </div>
      </Dialog>
    );
  }
}
