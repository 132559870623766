import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import PieChartGraph from './TicketsGraph/PieChartGraph';
import ComposedChartGraph from './TicketsGraph/ComposedChartGraph';

@observer
export default class CIOPageDetail extends Component {
  render() {
    let {
      handlePage,
      values,
      language,
      slas,
      devices,
      users,
      gsdCount,
      condevice
    } = this.props;
    let listSlas = slas.map((s, i) => <p key={i}>{s.name}</p>);
    //let listDevices = devices.map((d, i) => (<p key={i}>{d.name}</p>));
    //    let listUsers = users.filter(f => f.role === 'tech').map((u, i) => (<p key={i}>{u.name}</p>))

    let listDevices = [];
    devices.map(v => {
      condevice
        .filter(g => g.fk_id_device === v.id_device)
        .map(n => {
          if (n.fk_id_contract === values.id_contract) {
            listDevices.push(<p>{v.name}</p>);
          }
        });
    });

    let dataItens = users
      .filter(f => f.role === 'tech')
      .map(lu => {
        let tech = values.users.filter(f => f.fk_id_user_tech === lu.id_user);

        if (tech.length > 0) {
          return {
            name: lu.name,
            Tech: tech[0].time_tickets.substr(0, 2)
          };
        } else {
          return null;
        }
      });

    let listUsers = users
      .filter(f => f.role === 'tech')
      .map(lu => {
        let tech = values.users.filter(f => f.fk_id_user_tech === lu.id_user);

        if (tech.length > 0) {
          return <p>{lu.name}</p>;
        } else {
          return null;
        }
      });

    let item = gsdCount.ticketsGSD
      .filter(c => c.fk_id_contract === values.id_contract)
      .map(c => {
        return c;
      });

    values.tickets.total = item.length;
    return (
      <PageLayout
        icon={<div className="contractIcon titleIconSize" />}
        title={language.CONTRACT}
        subtitle={language.CONTRACT_DETAILS}
        rightElements={[
          <RaisedButton
            onClick={() => handlePage('dash')}
            label={language.BACK}
            primary
          />,
          <Help>help</Help>
        ]}
      >
        <div className="cardDefault">
          <Card className="cardTitle">
            <h2>{values.name}</h2>
          </Card>
          <Row>
            <Col md={3}>
              <ItemInfo
                icon={<div className="infoIcon cardIcon" />}
                title={language.GENERAL_INF}
                company={<p>{language.COMPANY}: company</p>}
                sponsor={<p>{language.SPONSOR}: sponsor</p>}
                amount={<p>{language.AMOUNT}: amount</p>}
                hour={
                  <p>
                    {language.HOUR_VALUE}: R${values.value},00
                  </p>
                }
                exceeded={
                  <p>
                    {language.HOUR_EXCEEDED_VALUE}: {values.timeoutValue}hs
                  </p>
                }
                initialDate={<p>{language.INITIAL_DATE}: init_date</p>}
                endDate={<p>{language.END_DATE}: end_date</p>}
                contractedHours={
                  <p>
                    {language.CONTRACTED_HOURS}: {values.contracted}hs
                  </p>
                }
              />
            </Col>
            <Col md={3}>
              <ItemInfo
                icon={<div className="slaIcon cardIcon" />}
                title={language.SLA}
                slaItem={listSlas}
              />
            </Col>
            <Col md={3}>
              <ItemInfo
                icon={<div className="deviceIcon cardIcon" />}
                title={language.DEVICES}
                deviceItem={listDevices}
              />
            </Col>
            <Col md={3}>
              <ItemInfo
                icon={<div className="techIcon cardIcon" />}
                title={language.TECHS}
                userItem={listUsers}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className="cardDefault">
                <div className="itemInfo">
                  <div className="evolutionIcon cardIcon" />
                  <h4>{language.GRAPH_DETAILS}</h4>
                </div>
                <div className="divider" />
                <Row>
                  <Col md={6}>
                    <Col md={6}>
                      <PieChartGraph
                        qtdTickets={values.tickets.total}
                        qtdOs={values.serviceOrders}
                        qtdAssessment={values.cont_assessment}
                      />
                    </Col>
                    <Col md={6}>
                      <div className="legendChartContractDetail">
                        <p>
                          <div
                            className="itemLegend"
                            style={{
                              background: 'var(--primary)',
                              float: 'left'
                            }}
                          />
                          {language.TICKETS}: {values.tickets.total}
                        </p>
                        <p>
                          <div
                            className="itemLegend"
                            style={{
                              background: 'var(--secundary)',
                              float: 'left'
                            }}
                          />
                          {language.OS}: {values.serviceOrders}
                        </p>
                        <p>
                          <div
                            className="itemLegend"
                            style={{
                              background: 'var(--green)',
                              float: 'left'
                            }}
                          />
                          {language.ASSESSMENT}: {values.cont_assessment}
                        </p>
                      </div>
                    </Col>
                  </Col>
                  <Col md={6}>
                    <ComposedChartGraph data={dataItens} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </PageLayout>
    );
  }
}

const ItemInfo = ({
  icon,
  title,
  company,
  sponsor,
  amount,
  hour,
  exceeded,
  initialDate,
  endDate,
  contractedHours,
  slaItem,
  deviceItem,
  userItem
}) => (
  <Card className="cardDefault">
    <div className="itemInfo">
      {icon}
      <h4>{title}</h4>
    </div>
    <div className="divider" />
    <div className="containerCardGrid">
      <div className="cardGrid">
        {company}
        {sponsor}
        {amount}
        {hour}
        {exceeded}
        {initialDate}
        {endDate}
        {contractedHours}
        {slaItem}
        {deviceItem}
        {userItem}
      </div>
    </div>
  </Card>
);
