import React from 'react';
import classnames from 'classnames';
import './styles.css';

export default function(props){
	let {items, contentProps={}, active, itemsProps={}, onClick=()=>null} = props;
	return(
		<div {...contentProps} className={classnames('ButtonGroupFilter', contentProps.className)}>
			{items.map((label,i)=>(
				<a 
					href="#" 
					{...itemsProps} 
					onClick={() => onClick({label, index:i})}
					className={classnames('ButtonGroupFilter-item mx-2', {active:i==active}, itemsProps.className)}
				>
					{label}
				</a>
			))}
		</div>
	)
}