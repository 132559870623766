import React, { useState, Fragment, useEffect, useCallback } from 'react';
import './index.css';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
/*------------------------common-----------------------------*/
import PageLayout from '@common/PageLayout';
import Button from '@common/Button';
import SwitchIos from '@common/SwitchIos';
import AppBar2 from '@common/AppBar2';
import { flexBasis } from '@common/Func';
import List from '@common/List';
import Link from '../../monitor/Link'
import CommonDelete from '../../../common/CommonDelete';
import MenuTooltip from '@common/MenuTooltip';
import Header from '@common/Header';
/*----------------------material-ui-------------------------------*/
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Card, Grid } from '@material-ui/core';
import SearchInput from '@common/SearchInput';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';
import TextField from "@material-ui/core/TextField"
/*------------------------pages---------------------------------*/
import Item from './Item';
import DashboardMonitor from '../../monitor/DashboardMonitor';

/*--------------------------------------------------------------*/
import { GraphArea } from './Graphs';
import ReactHighcharts from 'react-highcharts';
import crypt from '../../../util/crypt';
import DevicesAlert from './DevicesAlert';
import LinkInfo from './LinkInfo';
import MapDevice from './MapDevice';




function Carousel({

  setId,
  setDevice,
  ioServers,
  monitor,
  monitorColors,
  anchorEl,
  setAnchorEl,
  ioLinks,
  setAnchorElLink,
  anchorElLink,
  language,
  handlePage,
  dataList,

  searchDevice,

}) {


  let [search, setSearch] = useState('');
  let [slicePosition, setSlicePosition] = useState(0);
  let data = dataList.filter(e => (e.type + ' ' + e.name + ' ' + e.ip).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) != -1);
  let maxItem = 30;
  let count = Math.ceil(data.length / maxItem);
  let refAliceCarousel;

  /*Array.from(new Array(Math.ceil(dataList.length/maxDivisionItem)))
  .map((e,i)=> dataList.slice(maxDivisionItem*i, maxDivisionItem+maxDivisionItem*i));*/

  return (
    <Fragment>
      <Header className="px-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center content-medium">
          <SearchInput
            className='mr-3'
            placeholder={language.SEARCH + '...'}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            clearSeach={() => {
              // handleFilter() 
              setSearch('')
            }}

          />
          <Button icon="fas fa-print" color="warning" label={language.PRINT} />
        </div>
        <div className='d-flex align-items-center'>
          <Button
            variant='circle'
            icon='fas fa-chevron-left'
            label={language.BACK}
            color='light'
            outlined
            className='mr-4'
            onClick={() => {

              setSlicePosition(p => Math.max(p - 1, 0));
            }}
          />
          {
            /* Array.from(Array(d), (_,i) => {
                 if(i+1==Math.ceil(d/2)){
                   return (
                     <MenuTooltip
                       option={Array.from(Array(count), (_, i)=>({
                         label:i+1
                       }))}
                     />
                   )
                 }else{
                   return (
                     <Button
                       variant='circle'
                       icon={
                         <span>{i+1}</span>
                       }
                       label='Voltar'
                       color='light'
                       outlined
                       className='mx-1'
                     />
                   )
                 }
             })*/
          }
          <Button
            variant='circle'
            icon='fas fa-chevron-right'
            label={language.ADVANCE}
            color='light'
            outlined
            onClick={() => {

              setSlicePosition(p => Math.min(p + 1, count - 1));
            }}
          />
        </div>
      </Header>
      <Card className="content-min" style={{ borderRadius: '0 0 4px 4px' }}>

        <Grid container spacing={2}>
          {data.slice(slicePosition * maxItem, maxItem + slicePosition * maxItem).map(h => (

            <Grid item xs={4} key={h.id_device}>
              {h.type == 'link' ? (
                <Link
                  _device={h}
                  setId={setId}
                  setDevice={setDevice}
                  handlePage={handlePage}
                  language={language}
                  anchorEl={anchorElLink}
                  setAnchorEl={setAnchorElLink}
                  ioLinks={ioLinks}
                  onDettachDevice={() => handleModal()}
                  handleModal={() => handleModal()}
                />
              ) : (
                <Item
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  setId={setId}
                  setDevice={setDevice}
                  device={h}
                  ioServers={ioServers}
                  monitor={monitor}
                  monitorColors={monitorColors}
                />
              )}
            </Grid>

          ))}
        </Grid>

      </Card>
    </Fragment>
  )
}
export default function (props) {
  let refAliceCarousel;
  let [anchorEl, setAnchorEl] = useState(false);
  let [anchorElLink, setAnchorElLink] = useState(false);
  const [toggle, setToggle] = useState(false);
  let [filter, setFilter] = useState(false);
  const [filterByGroup, setFilterByGroup] = useState(false);
  const [mAll, setMAll] = useState([]);
  const [id, setId] = useState(0);
  const [_device, setDevice] = useState({});
  const [monitorColors, setMonitorColors] = useState([])


  /* const [oks, setOks] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [dangers, setDangers] = useState([]); 
  const [listOnline, setListOnline] = useState([]); */

  const [search, setSearch] = useState('');

  /*const [graficPercent, setGraficPercent] = useState([
    { name: 'Ok', value: 0, color: 'var(--success)' },
    { name: 'Warning', value: 0, color: 'var(--warning)' },
    { name: 'Danger', value: 0, color: 'var(--danger)' },
  ]); */

  const [linkDash, setLinkDash] = useState({
    title: '',
    data: [0, 0, 0, 0, 0, 0, 0]
  })

  const [modalOpen, setModalOpen] = useState(false);
  const [mo, setMo] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const [groups, setGroups] = useState([]);
  const [del, setDel] = useState(false);
  const [up, setUp] = useState(false);
  const [group, setGroup] = useState('');
  const [groupCache, setGroupCache] = useState([])
  const [textSearchDevice, setTextSearchDevice] = useState('');
  let token = crypt((id).toString());
  const {
    language,
    monitorGroups,
    ioServers,
    ioLinks,
    monitor,
    ioSNMP,
    ioAlertsServers,
    handlePage,
    onRestartAgent,
    openUpdateHandle,
    companies,
    company,
    onDettachDevice,
    getAvailability
  } = props;

  const companyId = 1;

  useEffect(() => {
    loadmAll().then((value) => {

      setMo(monitor)
      setMAll(value.mAllFromLoad);

      setGroups(value.groupsMonitor);
      setGroupCache(value.groupsMonitor)


    });
  }, [monitorGroups]);


  function loadmAll() {
    return new Promise((resolve) => {
      // console.log('monitorGRoups', monitorGroups);
      const groupsMonitor = [];
      var c = ['link', 'server', 'desktop'];
      let mAllFromLoad = monitorGroups
        .reduce((arr, i) => {
          i.groups.map((e) => {
            e.devices.map((e) => {
              arr.push(e);
            });
          });
          return arr;
        }, [])
        .sort(function (a, b) {
          return c.indexOf(a.type) - c.indexOf(b.type);
        });

      monitorGroups.map(monitor => {

        return monitor.groups.map(group => {

          groupsMonitor.push({ label: group.name_group, getAvailability:getAvailability, group_id: group.id_group, group: monitor, company: monitor.company, items: group.devices.length > 0 ? group.devices : [] });
        }); 
      });

      resolve({ mAllFromLoad, groupsMonitor });
    });
  }

  function handlePageMenu(option, device) {
    setAnchorEl(null);

    let getDevice = monitor.find(
      m => m.id_device == device.id_device);

    if (option.label === 'Informações do Dispositivo') {
      try {
        device.conf['cpu-stats'].triggers;

        handlePage('summary', { _device: device })
      } catch (e) { }
    }
    if (option.label === 'Informações de Serviço') {
      handlePage('services', { ...getDevice })
    }
    if (option.label === 'SensrConf') {
      handleModal({ msg: token })
    }
    if (option.label === language.EDIT_CONFIGURATION) {
      //handlePage('deviceConf', {...this.props._device,
      // id_brands:this.props.fk_id_brands, snmp:this.props.snmp})
      let itens = getDevice === undefined ? device : getDevice;

      handlePage('deviceConf', { ...itens, id_brands: props.fk_id_brands, snmp: props.snmp })
    }
    if (option.label === language.UPDATE_AGENT) {
      handleModal({
        msg: language.CONFIRM_UPDATE_AGENT, confirm: () => { onRestartAgent(device.id_device); handleModal(); }
      })
    }
    if (option.label === language.STOP_MONITORING) {
      handleModal({
        msg: language.CONFIRM_STOP_MONITOR, confirm: () => { onDettachDevice(device.id_device); handleModal(); }
      })
    }

    if (option.label === 'Informações do Link') {
      handlePage('summarylink', { _device: device })
    }
  }

  const handleModal = useCallback((opts) => {
    setModalOpen(!modalOpen);
    setModalOptions(opts);
  }, []);

  const onClose = useCallback(() => {
    setDel(del => !del);
    setUp(up => !up);
    setGroup('');
  }, []);

  const onUpdate = useCallback((values) => {
    props.upGroup(group, 'up', values);
    onClose();
  }, [group]);

  const upGroup = useCallback((group, action) => {
    setGroup(group);
    if (action === 'del') setDel(true);
  }, []);

  const onDelete = useCallback(() => {
    props.upGroup(group, 'del');
    onClose();
  }, [group]);

  /*const handleClickFilter = useCallback((index) => {
    if (index === 0) {
      handleFilter();
    } else if (index === 1) {
      handleSelectByStatus(oks)
    } else if (index === 2) {
      handleSelectByStatus(warnings)
    } else if (index === 3) {
      handleSelectByStatus(dangers)
    }
  }, [oks, warnings, dangers]) */

  /*const handleSelectByStatus = useCallback((ids) => {
    const devices = ids.map((value) => {
      for (let i = 0; i < mAll.length; i++) {
        if (value.iddevice === mAll[i].id_device) {
          return mAll[i];
        }
      }
    })

    setFilterByGroup(true);
    setMAll([...devices]);
  }, [mAll]) */

  const handleSelectGroup = useCallback((id) => {
    const devices = groups
      .filter(group => group.group_id === id)[0].items;
    setFilterByGroup(true);
    setMAll([...devices]);

  }, [groups])

  const handleColor = () => { } /*useCallback((id, type = '') => {
    const m = monitorColors.find(m => m.id === id);

    if (m) {
      
      return !m.color ? 'var(--primary)' : `var(--${m.color})`;
    }
    if (type === 'link') {
      return 'var(--primary)'
    }

    return 'var(--primary)';

  }, [monitorColors])*/

 /*useCallback((items) => {
    let qtd = 0
    let total = 0
    
    if (items.length > 0) {
      
      total = items.length;

      items.map(x => {
        if (x.type === 'link') {
          qtd += 1
        } else {
          qtd += listOnline.filter(lo => x.id_device === lo.id_device)[0] ? 1 : 0
        }
      })

      return ((qtd/total) * 100).toFixed(0)
    }

    return 0
  }, [listOnline]) */

  const handleFilter = () => {
    setFilterByGroup(false);

    loadmAll().then((value) => {
      setMAll(value.mAllFromLoad);
    });
  }

  const searchDevice = useCallback(() => {

    if (search) {
      let list = mAll.filter((item) => {

        return (
          item.name.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
          item.ip.toUpperCase().indexOf(search.toUpperCase() !== -1

          )
        )
      })
      if (list.length > 0) {
        setMAll(list);
      }
    }
  }, [mAll, search]);


  let dataList = (mAll || []).filter(function (device) {
    return !this[JSON.stringify(device)] && (this[JSON.stringify(device)] = true);
  }, Object.create(null));

  const changeServer = useCallback((event) => {
    setTextSearchDevice(event.target.value)
    //console.log()

    const text = event.target.value;
    if (text.length <= 0) {
      setGroups(groupCache);
      return;
    }

    const filterGroup = groupCache.map(gp => {
      return {
        ...gp,
        labelCase: gp.label.toLowerCase(),
      }
    }).filter(gp => gp.labelCase.includes(text.toLowerCase()));
    //console.log(monitorGroups)
    setGroups(filterGroup)

  }, [textSearchDevice, groupCache, groups, monitorGroups])


  return (

    <div>
      <AppBar2
        title={language.DEVICE_MAP}
        titleContentProps={{
          style: { width: '100%' }
        }}

        afterTitle={(
          <Fragment>
            <Button
                        icon="fas fa-sync-alt"
                        variant="circle"
                        color="primary"
              label={language.ALL}
                        onClick={() =>getAvailability(0)}
                        style={{ marginTop: 15 }}
                      />
            <i className="fas redo-alt"
              onClick={handleFilter}></i>
            <DashboardMonitor
              handleFilter={handleFilter}
              filtered={filterByGroup}
              ioAlertsLinks={props.ioAlertsLinks}
              ioAlertsServers={props.ioAlertsServers}
              ioLinks={ioLinks}
              devices={props.devices}
              monitordevicecompany={props.monitordevicecompany}
              companies={props.companies}
              language={props.language}
              handlePage={props.handlePage}
              monitorGroups={props.monitorGroups}
              onGroupSubmit={props.onGroupSubmit}
              danger={props.danger}
              warning={props.warning}
              ioServers={props.ioServers}
            />
          </Fragment>)}

      />



      <div>
        {groups.length > 0 && (
          <List
            mh={150}
            items={groups.sort(function (a, b) {
              return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            }).map((e, i) => ({
              className: 'device-map-content d-flex align-items-center',
              content: <MapDevice group={e} handleSelectGroup={handleSelectGroup} openUpdateHandle={openUpdateHandle} getAvailability={getAvailability} key={i} onUpdate={onUpdate} companies={companies} onClose={onClose} upGroup={upGroup} />,
            }))}
          />
        )}
        {del && (
          <CommonDelete
            language={language}
            closeModal={onClose}
            onDelete={onDelete}
          />
        )}
      </div>

    </div>

  );
}
