import React, { Fragment } from 'react';
import { Card } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';

import AppBar from '../../../../common/AppBar2';

import { totalPerType } from './Graph';

export default function ({ data = [], language }) {
  return (
    <Card id="ContractValue" className="h-100">
      <AppBar title={language.ACCUMULATED_VALUES} subTitle={`(${language.PER} ${language.TYPE})`} />
      <ReactHighcharts config={totalPerType(...data, language)} />
    </Card>
  );
}
