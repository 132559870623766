import { addTimes } from '@common/Func';
import moment from 'moment';
import Constants from '@data/Constants';
import Store from '@data/Store';

const { language } = Store;

let status = {
   'BACKLOG': language.BACKLOG,
   'TODO': language.TO_DO,
   'DOING': language.DOING,
   'STOPPED': language.IMPEDITIVE,
   'DONE': language.FINISHED
}

let risks = {
   1: {
      label: language.LOW,
      color: 'var(--primary)'
   },
   2: {
      label: language.MEDIUM,
      color: 'var(--warning)'
   },
   3: {
      label: language.HIGH,
      color: 'var(--danger)'
   }
}

function photo(e, users) {
   let _users = (e.usersphoto || []).map(e => {

      let photo = e.photo && `${Constants.APIRoot}/public/users/${e.id_user}/profileLogo.png`;

      return {

         src: photo || undefined,
         name: e.name_user || ''
      }
   });

   return _users;
}

export default {
   CallsAnswered1({ calls, user }) {


      let data = calls.reduce((arr, obj) => {
         arr[parseInt(obj.mon) - 1] = parseInt(obj.total);
         return arr;
      }, Array.from(new Array(12), () => 0));

      return data;

   },
   CallsAnswered2({ slaCall, year, month, user }) {



      let tc = slaCall

      return {
         slaNotOk: tc.filter(e => ['Closed', 'Resolved'].includes(e.status) && e.delay_tickets == true).length,
         slaOk: tc.filter(e => ['Closed', 'Resolved'].includes(e.status) && e.delay_tickets != true ).length,
         sla: tc.filter(e => ['In Progress', 'Open', 'Reopened'].includes(e.status)).length,
         averageTime: tc.filter(e => ['Closed', 'Resolved'].includes(e.status)).reduce((n, e) => addTimes(n, (e.time_resolution_problem) || '00:00:00'), '00:00:00')

      }
   },
   NPS({ data, value }) {

      function level(col, obj, e, p) {
         let val = e[col];
         if (val == 9 || val == 10) obj[p][0] += 1;
         if (val == 7 || val == 8) obj[p][1] += 1;
         if (val >= 0 && val <= 6) obj[p][2] += 1;
      }

      return data.reduce((obj, e) => {
         level('resolved', obj, e, 1);
         level('avaliation_level', obj, e, 2);
         level('experience', obj, e, 3);
         return obj;
      }, {
         1: [0, 0, 0],
         2: [0, 0, 0],
         3: [0, 0, 0],
      });

   },
   ActivitiesInvolved({ projects }) {

      let _percent = 0;
      return projects.reduce((obj, e) => {

         let keys = ['BACKLOG', 'TODO', 'DOING', 'STOPPED', 'VALIDATION'];
         let percent = 0;
         keys.forEach((key, i) => {
            obj.data[i].value += e.utils[key].length;
            let percents = e.utils[key].map(e => parseInt(e.percent) || 0);

            percent += percents.length ? percents.reduce((n, e) => n + e, 0) / percents.length : 0;
         });

         _percent = percent / 6;
         obj.percent = _percent;
         return obj;

      }, {
         data: [
            { label: language.BACKLOG, value: 0, color: 'var(--default)' },
            { label: language.TO_DO, value: 0, color: 'var(--warning)' },
            { label: language.DOING, value: 0, color: 'var(--primary)' },
            { label: language.IMPEDITIVE, value: 0, color: 'var(--danger)' },
            { label: language.VALIDATION, value: 0, color: 'var(--purple)' }
         ],
         percent: 0
      });
   },
   CallsYou(tickets, projects, risks, user) {
      if (user.role == 'tech') {

         tickets = tickets.filter(v => v.fk_id_tech == user.id)
      }
      return {
         demands: tickets.reduce((obj, e) => {
            if (!['Closed', 'Resolved'].includes(e.status) && e.stop_ticket != 0) {
               obj[2].y++;
            } else {
               if (e.status == 'Open') obj[0].y++;
               if (e.status == 'In Progress') obj[1].y++;
            }
            return obj;
         }, [
            { name: language.OPENED, y: 0, color: 'var(--primary)' },
            { name: language.IN_PROGRESS, y: 0, color: 'var(--warning)' },
            { name: language.WAITING, y: 0, color: 'var(--danger)' },
         ]),

         projects: projects.reduce((obj, e) => {
            ['BACKLOG', 'TODO', 'DOING', 'VALIDATION'].forEach((key, i) => {
               obj[i].y += e.utils[key].length;
            });
            return obj;
         }, [
            { name: language.BACKLOG, y: 0, color: 'var(--default)' },
            { name: language.TO_DO, y: 0, color: 'var(--warning)' },
            { name: language.DOING, y: 0, color: 'var(--primary)' },
            { name: language.VALIDATION, y: 0, color: 'var(--purple)' }
         ]),
         risks: risks.reduce((obj, e) => {
            if ((e.percent || e.progress) != 100) obj[0].y++;

            return obj;
         }, [
            { name: 'Aberto', y: 0, color: 'var(--primary)' }
         ])
      }
   },
   TotalCalls(tickets) {
      return tickets.reduce((obj, e) => {
         if (e.type_tickets == 4) {
            obj[0].y += 1;
         } else if (e.type_tickets == 2) {
            obj[2].y += 1;
         } else if (e.type_tickets == 5) {
            obj[3].y += 1;
         } else if (e.type_tickets == 6) {
            obj[4].y += 1;
         } else {
            obj[1].y += 1;
         }
         return obj;
      }, [
         { name: language.TICKET_TYPES.SOLICITATION, y: 0, color: 'var(--primary)' },
         { name: language.TICKET_TYPES.INCIDENT, y: 0, color: 'var(--warning)' },
         { name: language.TICKET_TYPES.PROBLEM, y: 0, color: 'var(--danger)' },
         { name: language.TICKET_TYPES.EVENT, y: 0, color: 'var(--primary)' },
         { name: language.TICKET_TYPES.CRISIS, y: 0, color: 'var(--warning)' },
         { name: language.TICKET_TYPES.CRITICAL_INCIDENT, y: 0, color: 'var(--danger)' },
      ]);
   },
   Calls({ tickets }) {
      return tickets.map(e => ({
         id: e.id_tickets,
         title: e.subject,
         realid: e.real_id,
         status: e.status,
         type_tickets: e.type_tickets,
         description: e.description,
         date: moment(e.dt_cad).format('DD/MM/YYY - HH:mm'),
         dt: e.dt_cad
      })).sort((a, b) => moment(b.dt).unix() - moment(a.dt).unix()).slice(0, 10);
   },
   OKR() {
      return Array.from(new Array(3), () => ({
         name: 'KR 3',
         title: language.SURPRISE_WITH_THE_IMPROVEMENTS,
         description: language.HAVE_AN_EVALUATION_OF_AT_LEAST_8_OF_SATISFACTION_IN_USING_THE_EVOLUTION_SOLUTION,
         date: '12/05/2020 as 18:20',
         trophy: 2
      }))
   },
   Average({ risks }) {
      let rk = risks.reduce((obj, e) => {
         if (obj[e.name]) {
            obj[e.name][0]++;
            obj[e.name][1] += e.current_maturity;
            obj[e.name][2] += e.planned_maturity;
         }
         return obj;
      }, {
         'ISO 27001': [0, 0, 0],
         'ISO 2000': [0, 0, 0],
         'ISO 9001': [0, 0, 0],
         'ISO 37001': [0, 0, 0],
         'COBIT': [0, 0, 0]
      });

      return Object.values(rk).reduce((obj, e, i) => {
         obj.currentMaturity[i] = parseFloat(((e[1] / e[0]) || 0).toFixed(2));
         obj.desiredMaturity[i] = parseFloat(((e[2] / e[0]) || 0).toFixed(2));
         return obj;
      }, {
         currentMaturity: [0, 0, 0, 0],
         desiredMaturity: [0, 0, 0, 0]
      });
   },
   Availability() {
      return [
         {
            label: language.ATTENTION,
            value: 3,
            color: 'var(--danger)'
         },
         {
            label: language.ALERT,
            value: 3,
            color: 'var(--warning)'
         },
         {
            label: 'Ok',
            value: 3,
            color: 'var(--success)'
         }
      ]
   },
   ListDetail_1(tickets) {
      let status = {
         Closed: language.TICKET_STATUS.CLOSED,
         Open: language.TICKET_STATUS.OPEN,
         Resolved: language.TICKET_STATUS.RESOLVED,
         'In Progress': language.TICKET_STATUS.IN_PROGRESS
      }

      return tickets.map(e => ({
         status: status[e.status],
         type_tickets: e.type_tickets,
         id: e.id_tickets,
         date: moment(e.dt_cad).format('L'),
         hour: moment(e.dt_cad).format('HH:mm'),
         realid: e.real_id,
         status: e.status,
         subject: e.subject,
         sponsor: e.nameuser,
         sla: addTimes(e.time_total_ticket, '00:00:00'),
         ticket:e
      }));

   },
   ListDetail_2() {
      return [
         {
            priority: 3,
            keys: 'KR 3',
            result: 'Ter avaliação de ao menos 8.0 de satisfação de uso da solução de evoluções',
            sponsor: 'Fabricio Silva',
            spenses: '24/08/2020',
            total: 5,
            period: 'Trimestral',
            pontuation: 3,
            status: 68
         }
      ]
   },
   ListDetail_5(tickets) {
      let status = {
         Closed: language.TICKET_STATUS.CLOSED,
         Open: language.TICKET_STATUS.OPEN,
         Resolved: language.TICKET_STATUS.RESOLVED,
         'In Progress': language.TICKET_STATUS.IN_PROGRESS
      }



      return tickets.filter(t => ['Open', 'In Progress'].includes(t.status) || (!['Closed', 'Resolved'].includes(t.status) && t.stop_ticket != 0)).map(e => ({
         status: status[e.status],
         type_tickets: e.type_tickets,
         id: e.id_tickets,
         date: moment(e.dt_cad).format('L'),
         hour: moment(e.dt_cad).format('HH:mm'),
         realid: e.real_id,
         status: e.status,
         subject: e.subject,
         sponsor: e.nameuser,
         sla: addTimes(e.time_total_ticket, '00:00:00'),
         ...e
      }));

   },
   activitiesList(projects, users) {

      return projects.flatMap(e => {
         return ['BACKLOG', 'TODO', 'DOING', 'STOPPED', 'VALIDATION'].flatMap((key, i) => {
            return [...e.utils[key]];
         }).map(a => {

            return {
               pj: e,
               id_project: e.id_project,
               name: e.name,

               id: a.id_project_activity,
               activity: a.name,
               sponsors: photo(a, users),
               evolution: parseInt(a.percent),
               status: status[a.stage || 'BACKLOG'],
               dt_i: moment(a.dt_start).format('L'),
               dt_f: moment(a.dt_end).format('L'),
            }
         })
      })

   },
   riskList(risks) {
      return risks.map(e => ({
         status: e.status,
         percent: e.percent,
         date: e.execution_date,
         period: e.review_period,
         question: e.desc,
         priority: e.priority,
         current_maturity: e.current_maturity,
         desired_maturity: e.desired_maturity
      }));
   },
   projectList({ projects, users }) {
      return projects.map(e => ({
         id: e.id_project,
         color: e.utils.isDelay ? 'var(--danger)' : 'var(--primary)',
         project: e.name,
         manager: (users.find(u => parseInt(u.id_user) == parseInt(e.user_cad)) || {}).name,
         project_type: e.type_project,
         priority: risks[e.type_risk], //7.22
         status: (
            e.utils.awaitExecution && !e.deleted ?
               'Aguardando execução' :
               e.utils.inExecution && !e.deleted ?
                  'Em execução' :
                  e.utils.isDelay && !e.deleted ?
                     'Atrasado' :
                     e.utils.concluded ?
                        'Concluído' :
                        e.utils.concludedDelay ?
                           'Concluído com atraso' :
                           (e.utils.isDelay || e.utils.inExecution || e.utils.awaitExecution) && e.deleted ?
                              'Cancelado' :
                              null
         ),
         percent: parseInt(e.percent) || 0,
         expected_start: moment(e.start_date).format('L'),
         expected_end: moment(e.end_date).format('L'),
         realized_start: e.utils.start_dt_realized ? moment(e.utils.start_dt_realized * 1000).format('L') : '',
         realized_end: e.utils.end_dt_realized ? moment(e.utils.end_dt_realized * 1000).format('L') : '',
         expected_cost: parseFloat(e.cust_project),
         realized_cost: parseFloat(e.realized_cost),
         goal: e.goal,
         activities: [
            { label: language.TO_DO, value: e.utils.TODO.length },
            { label: language.DOING, value: e.utils.DOING.length },
            { label: language.IMPEDITIVE, value: e.utils.STOPPED.length },
            { label: language.VALIDATION, value: 0 },
            { label: language.FINISHED, value: e.utils.DONE.length },
         ]
      }));
   }
}