import { Chip, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { Actions, Constants } from '../../data';

export default function CompanyFormsForm({
  companyData,
  params,
  language,
  onClose,
}) {
  const randomId = () => new Date().getTime().toString(20);
  const relationType = 'gmud';

  const [forms, setForms] = useState([]);
  const [data, setData] = useState([]);
  const [formSelectedItem, setFormSelectedItem] = useState();
  const [formIds, setFormIds] = useState([]);
  const [reload, setReload] = useState(randomId());

  useEffect(() => {
    getFormList();
    getRelatedFormList();

    return () => {};
  }, [, reload]);

  const getFormList = async () => {
    const fetch = new Actions();
    fetch
      .execute('get', `${Constants.APIEndpoints.TASK}/getforms?type=kv`)
      .then((response) => {
        const result = response.data;

        const dataResult = result.map((item, index) => {
          return {
            value: item.fk_id_template_forms,
            label: item.name,
          };
        });

        setForms(dataResult);
      });
  };

  const getRelatedFormList = async () => {
    const fetch = new Actions();
    fetch
      .execute(
        'get',
        `${Constants.APIEndpoints.COMPANY}/${companyData.id_company}/forms?type=${relationType}`,
      )
      .then((response) => {
        const result = response.data;

        const uniqueIdForms = new Set();

        const dataResult = result.map((item, index) => {
          uniqueIdForms.add(item.id_template_forms);
          return {
            id: item.id_company_form,
            name: item.name,
            // id_form: item.id_template_forms,
            actions: (
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="tw-flex tw-items-center tw-justify-items-center tw-gap-2">
                    <RaisedButton
                      circleButton
                      color="danger"
                      icon="fas fa-trash-alt"
                      label={language.REMOVE}
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() => handleDelete(item)}
                    />
                  </div>
                }
              />
            ),
          };
        });

        setFormIds(uniqueIdForms);
        setData(dataResult);
      });
  };

  const handleCreate = () => {
    const payload = {
      id_form: formSelectedItem,
      type: relationType,
    };

    const fetch = new Actions();
    fetch
      .execute(
        'post',
        `${Constants.APIEndpoints.COMPANY}/${companyData.id_company}/forms`,
        null,
        payload,
      )
      .then((response) => {
        setFormSelectedItem(null);
        getRelatedFormList();
      });
  };

  const handleDelete = (item) => {
    const fetch = new Actions();
    fetch
      .execute(
        'delete',
        `${Constants.APIEndpoints.COMPANY}/${companyData.id_company}/forms/${item.id_company_form}`,
      )
      .then((response) => {
        getRelatedFormList();
      });
  };

  const handleReload = () => setReload(randomId());

  const cols = [
    { key: 'id', label: '#', style: { width: 20 } },
    {
      key: 'name',
      label: language.NAME,
    },
    {
      key: 'actions',
      label: language.ACTIONS,
      style: { width: 100 },
    },
  ];

  const formsFiltered = forms.filter((item) => {
    if (formIds && formIds.length > 0) {
      return !formIds.has(item.value);
    } else {
      return true;
    }
  });

  return (
    <div>
      <Paper className="tw-p-3 mb-3">
        <Row>
          <Col md={6}>
            <TextField
              disabled={true}
              label={language.COMPANY}
              value={`${companyData.id_company} - ${companyData.name}`}
              InputLabelProps={{ shrink: true }}
              style={{ width: '100%' }}
            />
          </Col>
          <Col md={6}></Col>
        </Row>
        <Row className="tw-mt-8">
          <Col md={6}>
            <CommonForm
              values={[]}
              fields={[
                {
                  col: 12,
                  name: 'id_form',
                  label: language.FORMS,
                  type: 'select',
                  options: formsFiltered,
                },
              ]}
              onChangeField={(field, value) => setFormSelectedItem(value)}
            />
          </Col>
          <Col md={6}>
            <RaisedButton
              primary
              fullWidth
              small={true}
              color="primary"
              className="mr-3"
              icon="fas fa-plus"
              label={language.ADD}
              disabled={!formSelectedItem}
              onClick={handleCreate}
            />
          </Col>
        </Row>
      </Paper>

      <CommonTable
        language={language}
        columns={cols}
        data={data}
        title={`${language.ID} ${companyData.id_company}`}
      />
    </div>
  );
}
