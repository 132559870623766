import React, {useEffect} from 'react';
/* ----------------------------------common----------------------------------- */
/* ----------------------------------material-ui----------------------------------- */

import Block_1 from './Block_1';
import Block_2 from './Block_2';

export default function({
   page,
   execute, 
   APIRoot, 
   APIEndpoints, 
   tickets=[], 
   projects=[],
   gmuds=[],
   day,
   month,
   year,
   dt_ini,
   dt_end,
   os,
   priorities,
   users,
   redirect,
   requests,
   action,
   risks,
   matrizplanning
}){
   useEffect(() => {
         execute('get', `${APIRoot}/api/home/tickets?month=${month}&year=${year}`, 'tickets');

      execute('get', `${APIRoot}/api/home/tickets/priorities?year=${year}`, 'priorities');
      execute('get', `${APIRoot}/api/home/projects?month=${month}&year=${year}`, 'projects');
      execute('get', `${APIRoot}/api/home/gmud?month=${month}&year=${year}`, 'gmuds');
      execute('get', `${APIRoot}/api/home/os?month=${month}&year=${year}`, 'os');
   }, [month, year]);

   useEffect(() => {
      if(page==3){
         execute('get', `${APIRoot}/api/home/request?month=${month}&year=${year}`, 'requests');
         execute('get', `${APIRoot}/api/home/risks?month=${month}&year=${year}`, 'risks');
         execute(
            'get',
             APIEndpoints.MATRIZ + 'getmatrizplanning',
            'matrizplanning'
          );
      }
   }, [page])
   
   return(
      <div id='Home-View2'>
         <Block_1 risks={risks} matrizplanning={matrizplanning} redirect={redirect} requests={requests} year={year} page={page} action={action} users={users} priorities={priorities} execute={execute} APIRoot={APIRoot} tickets={tickets} projects={projects} gmuds={gmuds} os={os}/>
         <Block_2 redirect={redirect} tickets={tickets} gmuds={gmuds} os={os} users={users} projects={projects}/>
      </div>
   )
}