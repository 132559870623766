import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

@observer
export default class ComposedChartGraph extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={230}>
        <ComposedChart
          layout="vertical"
          data={this.props.data}
          margin={{ top: 20, right: 20, bottom: 20, left: 1 }}
        >
          <CartesianGrid stroke="#ddd" />
          <XAxis
            type="number"
            tick={{ fontSize: 12, fill: 'var(--primary)' }}
          />
          <YAxis
            dataKey="name"
            type="category"
            tick={{ fontSize: 12, fill: 'var(--primary)' }}
          />

          <Tooltip />
          <Legend iconSize={10} wrapperStyle={{ fontSize: 12 }} />

          {/*   <Area dataKey='Hours' fill='#ddd' stroke='var(--lightGreen)' /> */}
          <Bar dataKey="Tech" barSize={15} fill="var(--primary)" />
          {/* <Line dataKey='Trend' stroke='var(--secundary)'/> */}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
