import React, { useCallback, useEffect, useState } from 'react';
/*-------------------common---------------------------*/
import MediumTime from '@common/MediumTime';
import AppBar from '@common/AppBar2';
import Loading from '@common/Loading';
/*-------------------material-ui---------------------------*/
import { Card } from '@material-ui/core';

export default function ({ index, data, y, hour, minute, language }) {
  const [value, setValue] = useState(0);
  const [isMinutes, setIsMinutes] = useState(false);
  useEffect(() => {
    if (hour || minute) {
      let checkIsMinutes = hour < 1;
      if (checkIsMinutes) {
        setIsMinutes(checkIsMinutes);
        setValue(minute);
      } else {
        setValue(hour);
      }
    }

    return () => {
      setValue(0);
      setIsMinutes(false);
    };
  }, [hour, minute]);
  const convertMinutesToHour = useCallback((minutes) => {
    let time = minutes / 60;
    let display = parseFloat(time.toFixed(2));
    return display;
  }, []);

  return (
    <Card className="MediumTime h-100">
      <AppBar
        title={language.AVERAGE_TIME}
        subTitle={
          index == 0
            ? `(${language.TOTAL_TICKET})`
            : index == 1
            ? `(${language.CATEGORIZATION})`
            : `(${language.ATTENDANCE})`
        }
      />
      <div className="content-medium">
        <MediumTime
          config={{
            interval: 6,
            height: 356,
            width: 45,
            y: isMinutes ? convertMinutesToHour(value) : value,
            formatY: (y) => (
              <div>
                <h3 className="my-0" style={{ fontSize: 20 }}>
                  {value}
                </h3>
                <span style={{ fontSize: 11 }} className="d-block -mt-1">
                  {isMinutes ? language.MINUTES : language.HOURS}
                </span>
              </div>
            ),
          }}
          data={data}
        />
      </div>
    </Card>
  );
}
