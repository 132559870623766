import React, {memo} from 'react';
import classnames from 'classnames';
/* ----------------------------common------------------------------ */
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import MenuTooltip from '@common/MenuTooltip';
import {Tooltip} from 'react-tippy';
import Progress from '@common/Progress';
/* ----------------------------material------------------------------ */
import Store from '@data/Store';

const { language } = Store;

function ProgressStage({ color, value }) {
   return (
     <div className="content-stage-progress">
       {[1, 2, 3, 4, 5].map(e => (
         <div
           style={{
             backgroundColor: color,
             cursor: 'pointer',
             textAlign: 'center'
           }}
           key={e}
         >
           {parseInt(e) == parseInt(value) ? (
             <i
               class="fas fa-caret-down"
               style={{
                 fontSize: '26px',
                 marginTop: '-13px',
                 color: 'rgb(115 103 103)',
                 color: '#D9DDE5',
                 transform: 'scaleX(.7)'
               }}
             ></i>
           ) : null}
         </div>
       ))}
     </div>
   );
}

export default memo(({open, onClose, data=[]})=>{
   let priorityStatus = {
      1:<span style={{color:'var(--primary)'}}>Baixo</span>,
      2:<span style={{color:'var(--warning)'}}>Médio</span>,
      3:<span style={{color:'var(--danger)'}}>Alto</span>
   }
   return(
      <Dialog
         open={open}
         onClose={onClose}
         maxWidth='xl'
         bar={false}

			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
      >
         <CommonTable
            AppBarProps={{
               style:{
                  borderRadius: '10px 10px 0 0'
               }
            }}
            className='Home-View1-Risks'
            paginationTop={false}
            maxHeight={500}
            afterBar2={
               <Button
                  icon='fas fa-times'
                  variant='circle'
                  outlined
                  color='light'
                  label={language.CLOSE}
                  onClick={onClose}
               />
            }
            col={[
               {key:'status', label:language.STATE},
               { key: 'progress', label: language.PROGRESS_ },
               { key: 'date', label: language.ACTIVITY_DATE },
               { key: 'period', label: language.REVIEW_PERIOD },
               { key: 'question', label: language.QUESTION },
               {key:'priority', label: language.PRIORITY},
               { key: 'current_maturity', label: language.CURRENT_MATURITY },
               { key: 'desired_maturity', label: language.DESIRED_MATURITY },
            ]}
            data={data.map(e=>({
               status:(
                  <Tooltip
                     className='tooptip ellipsis title d-block'
                     title={e.status ? language.ON_TIME : language.REVISION_REQUIRED}
                     arrow={true}
                     position='right'
                     theme='dark'
                  >
                     <i
                        className={e.status?"fas fa-clipboard-check":"fas fa-clipboard"}
                        style={{
                           fontSize: 17,
                           color: e.status ? 'var(--success)' : 'var(--danger)'
                        }}
                     />
                  </Tooltip>
               ),
               progress:(
                  <div className='d-flex align-items-center content-progress' style={{width:75}}>
                     <Progress current={parseInt(e.percent)} hiddenText className='w-100' style={{height:7}} background='var(--warning)'/>
                     <span className='ml-2'>{parseInt(e.percent)}%</span>
                  </div>
               ),
               date:e.date,
               period:e.period,
               question:e.question,
               priority:priorityStatus[e.priority||1],
               current_maturity:(
                  <ProgressStage
                     value={e.current_maturity}
                     color={'#FA9633'}
                  />
               ),
               desired_maturity:(
                  <ProgressStage
                     value={e.desired_maturity}
                     color={'#009dc0'}
                  />
               )
            }))}
         />
      </Dialog>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
})
