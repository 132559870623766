import React, {useState, useEffect, useReducer} from 'react';
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
import moment from 'moment';
// ---------------------------------------common---------------------------------------------------------------
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import MenuTooltip from '@common/MenuTooltip';
// -------------------------------------material-ui---------------------------------------------------------------
import {Checkbox} from '@material-ui/core';
// -------------------------------------components---------------------------------------------------------------
import SponsorsAvatar from './SponsorsAvatar';

let options = [
	{icon:'fas fa-pencil-alt', label:'Editar', color:'var(--success)'},
	{icon:'fas fa-trash-alt', label:'Excluir', color:'var(--danger)'},
]

export default function({
	data=[],
	index,
	indexPai,
	onChangeMenu
}){
	let [checkedList, SetCheckedList] = useReducer((state, {index, value})=>{

		if(Array.isArray(value)){
			return value;
		}

		state[index]=value;

		return [...state];
		
	},[])

	useEffect(()=>{
		let _data = data.map(e=>e.state);
		if(checkedList.length!=_data.length){
			SetCheckedList(_data);
		}

	},[data]);

	let _data = data.map((e,i)=>({
		
		actions:<MenuTooltip options={options} onChange={(data, _index) => onChangeMenu({data, index:_index, id_okr_results_subactivies: e.id}, indexPai, index, i)}/>,
		subactivities:e.subactivities,
		sponsor:<SponsorsAvatar avatares={e.sponsor}/>,
		deadline: moment(e.deadline).format('DD/MM/yyyy'),
		punctuation:(
			[1,2,3,4,5].map(a=>(
				<i key={a} className={classnames('fas fa-trophy', 'trophy-icon', {active:a<=e.punctuation})}/>
			))
		),
		expected_result:e.expected_result,
		state:<Checkbox checked={checkedList[i]} onChange={(evt)=> SetCheckedList({index:i, value:evt.target.checked})}/>,
		
	}));

	return(
		<CommonTable
			bar={false}
			pagination={false}
			col={[
				{key:'actions', label:'Ações'},
				{key:'subactivities', label:'Subatividade'},
				{key:'sponsor', label:'Responsável'},
				{key:'deadline', label:'Prazo'},
				{key:'punctuation', label:'Pontuação'},
				{key:'expected_result', label:'Resultado esperado'},
				{key:'state', label:'Estado'},
			]}
			data={_data}
		/>
	)
}