import React from 'react';
import {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Store from '@data/Store';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react';

const styles = {
  root: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
    // marginBottom:10
  },
  paper: {
    padding: '0px 4px',
    display: 'flex',
    backgroundColor:'white',
    alignItems: 'center',
    borderRadius:15,
    height:30,
    boxShadow:'none'
  },
  input: {
    marginLeft: 8,
    flex: 1,
    color:'var(--primary)'
  },
  iconButton: {
    padding: 1,
    color:'#33ACA0'
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },

  tablePagination: {
    height: 46,
    minHeight: 46,
    display: 'flex',
    alignItems: 'center'
  },
  tablePaginationCaption: {
    color: 'var(--primary)'
  },
  tablePaginationSelectIcon: {
    color: 'var(--primary)'
  },
  tablePaginationSelect: {
    color: 'var(--primary)',
    fontSize: '0.75rem'
  },
  tablePaginationSelect2: {
    fontSize: '0.75rem'
  },

  tablePaginationActions: {
    color: 'white'
  },
  caption: {
    flexShrink: 0
  }
};

let delayTimer;
@observer
class SearchInput extends Component{
  constructor(props){
    super(props);
    this.state = {
      value:''
    }
  }

  handleValue = (evt) => {
    
    let value = evt.target.value;
    this.setState({value});

    clearTimeout(delayTimer);

 if(typeof(this.props.onChange) === 'function'){ 
    delayTimer = setTimeout(() => {
      this.props.getAllEvt ? this.props.onChange(evt) : this.props.onChange({
        target:{value}
      });
    }, 400);
   }
  }

  handleClearSearch = () => {
    this.setState({ value: ''})
    if(typeof(this.props.clearSeach) === 'function'){ 
    this.props.clearSeach();
    }
  }

  componentDidMount(){
    this.setState({value:this.props.value||''})
  }
	render(){
		let {classes, onClick, placeholder, clearSeach, className, style={}, onKeyUp, onChange, ...other} = this.props;
    let {value} = this.state;
		let { language } = Store;
		return(
			<Paper className={classes.paper+' search-paper '+className} style={{width: 300, ...style}} {...other} elevation={1}>
			  <IconButton
			    size="small"
			    className={classes.iconButton+' icon-search'}
          aria-label="Menu"
          onClick={onClick}
			  >
			    <SearchIcon />
			  </IconButton>

			  <InputBase
			    style={{
			      width: !!value ? '50%' : '100%'
			    }}
			    className={classes.input}
			    placeholder={placeholder||(language.SEARCH+'...')}
			    value={value}
			    onChange={this.handleValue}
             onKeyUp={onKeyUp}
          
			  />

			  {!!value && (
			    <Fragment>
			      <Divider className={classes.divider} />
			      <IconButton
			        color="primary"
			        onClick={this.handleClearSearch}
			        className={classes.iconButton+' icon-remove-search'}
			        aria-label="Directions"
			      >
			        {/*<CloseIcon />*/}
			        <Icon
			          color="primary"
			          style={{ transform: 'rotate(-45deg)' }}
			        >
			          add_circle
			        </Icon>
			      </IconButton>
			    </Fragment>
			  )}
			</Paper>
		)
	}
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchInput);