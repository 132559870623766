import React, { useState, Fragment } from 'react';
import ReactHighcharts from 'react-highcharts';
/*--------------------------------common-----------------------------*/
import Header from '@common/Header';
import { flexBasis } from '@common/Func';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import List from '@common/List';
import Tag from '@common/Tag';
import { addTimes } from '@common/Func';
/*--------------------------------material-ui-----------------------------*/
import { Card, Grid } from '@material-ui/core';
/*--------------------------------components-----------------------------*/
import { perfomance, satisfation } from './Graph';

import Alocation from './Alocation';
import DialogTableProject from './DialogTableProject';
import DialogTableActivities from './DialogTableActivities';
import { activitiesList } from '../jsonFormat';

function Indicator({ title, value = 0, money, onClick = () => null }) {
  return (
    <div className="indicator content-medium" onClick={onClick}>
      <h3>{title}</h3>
      <span>
        {money
          ? value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : value.toString().padStart(3, 0)}
      </span>
    </div>
  );
}
function Indicator2({ title, value, language }) {
  return (
    <div className="indicator2">
      <AppBar title={title} subTitle={`(${language.IN_HOURS})`} />
      <div className="content-medium content-info">
        <h4>{value}</h4>
        <span>{language.HOURS}</span>
      </div>
    </div>
  );
}

function hours(pmos) {
  let total_hours = pmos.reduce((t, e) => addTimes(t, e.hour_consumida), '0');
  let total = _.uniqBy(pmos.flatMap((e) => e.users)).length;

  return {
    team_average: parseInt(total_hours) / total,
    team_acumulated: parseInt(total_hours),
  };
}

export default function ({
  language,
  pmos,
  projectsDeletedCount,
  users,
  options,
  redirect,
  pmosDeleted = [],
  themes,
  dateInitial,
  dateEnd,
  projectsCanceled=[]
}) {
  let [view, setView] = useState(0);
  let [open, setOpen] = useState(null);
  let pjCategory = {
    projectsDeletedCount: pmosDeleted,
    inExecution: [],
    concluded: [],
    isDelay: [],
    initializedDelay: [],
    concludedDelay: [],
    awaitExecution: [],
    activitiesDelay: [],
    concludedWithoutDelay: [],
    activitiesDelay: [],
    projectsCanceled:projectsCanceled
  };
  let info = pmos.reduce(
    (obj, e) => {
      let realized_cost = 0;
      (e.valoractivity || []).map((e) => {
        let [userName, valueConsumed, activity, timeActivity] = e.split('**');

        realized_cost += parseFloat(valueConsumed) || 0;
      });

      let custProject = e.cust_project ? parseFloat(e.cust_project) : 0;
      let realizedCost = e.realized_cost2
        ? parseFloat(e.realized_cost2) + realized_cost
        : 0;

      obj.cust_project += custProject;
      obj.realized_cost += realizedCost;

      // ----------------------Projetos em execução----------------------------------------
      if (e.utils.inExecution) {
        obj.inExecution++;
        pjCategory.inExecution.push(e);
      }

      // ----------------------Projetos em concluídos----------------------------------------
      if (e.utils.concluded) {
        obj.concluded++;
        pjCategory.concluded.push(e);
      }

      // ----------------------Projetos em em atraso----------------------------------------
      if (e.utils.isDelay) {
        obj.isDelay++;
        pjCategory.isDelay.push(e);
      }

      // ----------------------Projetos em iniciados com atraso----------------------------------------
      if (e.utils.initializedDelay) {
        obj.initializedDelay++;
        pjCategory.initializedDelay.push(e);
      }

      // ----------------------Projetos em concluídos com atraso----------------------------------------
      if (e.utils.concludedDelay) {
        obj.concludedDelay++;
        pjCategory.concludedDelay.push(e);
      }

      // ----------------------Projetos aguardando execução----------------------------------------
      if (e.utils.awaitExecution) {
        obj.awaitExecution++;
        pjCategory.awaitExecution.push(e);
      }

      // ----------------------Projetos concluídos sem atrasos----------------------------------------
      if (!e.utils.concludedDelay && e.utils.concluded) {
        obj.concludedWithoutDelay++;
        pjCategory.concludedWithoutDelay.push(e);
      }

      // ----------------------Atividades atrasadas----------------------------------------
      let activitiesDelay = [
        ...e.utils.DONE,
        ...e.utils.BACKLOG,
        ...e.utils.DOING,
        ...e.utils.STOPPED,
        ...e.utils.VALIDATION,
        ...e.utils.TODO,
      ].filter((e) => e.isDelay);
      obj.activitiesDelay += activitiesDelay.length;

      pjCategory.activitiesDelay.push(
        activitiesDelay.map((a) => activitiesList({ e, a, users }))
      );

      return obj;
    },
    {
      inExecution: 0,
      concluded: 0,
      isDelay: 0,
      initializedDelay: 0,
      concludedDelay: 0,
      awaitExecution: 0,
      activitiesDelay: 0,
      concludedWithoutDelay: 0,

      cust_project: 0,
      realized_cost: 0,
    }
  );

  let portfolio = [
    {
      title: language.PROJECT_PORTFOLIO,
      value: pmos.length,
    },
    {
      title: language.PROJECT_IN_EXECUTION,
      value: info.inExecution,
      type: 'inExecution',
      onClick: () => setOpen('inExecution'),
    },
    {
      title: language.COMPLETED_PROJECT,
      value: info.concluded,
      type: 'concluded',
      onClick: () => setOpen('concluded'),
    },
    {
      title: language.PROJECT_IN_ARREARS,
      value: info.isDelay,
      type: 'isDelay',
      onClick: () => setOpen('isDelay'),
    },
    {
      title: language.PROJECTS_STARTED_LATE,
      value: info.initializedDelay,
      type: 'initializedDelay',
      onClick: () => setOpen('initializedDelay'),
    },
    {
      title: language.PROJECTS_COMPLETED_LATE,
      value: info.concludedDelay,
      type: 'concludedDelay',
      onClick: () => setOpen('concludedDelay'),
    },
    {
      title: language.PROJECTS_AWAITING_EXECUTION,
      value: info.awaitExecution,
      type: 'awaitExecution',
      onClick: () => setOpen('awaitExecution'),
    },
    {
      title: language.CANCELED_PROJECTS,
      value: projectsCanceled.length,
      type: 'projectsDeletedCount',
      onClick: () => setOpen('projectsCanceled'),
    },
    
    {
      title: language.OVERDUE_TASKS,
      value: info.activitiesDelay,
      type: 'activitiesDelay',
      onClick: () => setOpen('activitiesDelay'),
    },
    {
      title: language.BUDGETED_AMOUNT,
      value: info.cust_project,
      money: true,
    },
    {
      title: language.REALIZED_VALUE,
      value: info.realized_cost,
      money: true,
    },
    {
      title: language.BUDGETED_DEVIATION,
      value: Math.max(0, info.realized_cost - info.cust_project),
      money: true,
    },
  ];

  let _hours = hours(pmos);
  let data = {
    perfomance: [
      -((info.concludedDelay * 100) / pmos.length),
      parseFloat(((info.concludedWithoutDelay * 100) / pmos.length).toFixed(1)),
    ],
    team_average: parseInt(_hours.team_average),
    team_acumulated: _hours.team_acumulated,
    satisfation: {
      resolved: 64,
      notResolved: 12,
    },
  };

  // -------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div id="Block_1" className="mb-3">
      {view == 0 ? (
        <Fragment>
          <DialogTableProject
            data={open != 'activitiesDelay' ? pjCategory[open] : []}
            users={users}
            open={open != 'activitiesDelay' && !!open}
            onClose={() => setOpen(null)}
            title={(portfolio.find((e) => e.type == open) || {}).title}
            redirect={redirect}
            type="dialog"
          />
          <DialogTableActivities
            data={pjCategory.activitiesDelay.flatMap((e) => e)}
            title={(portfolio.find((e) => e.type == open) || {}).title}
            open={open == 'activitiesDelay' && !!open}
            onClose={() => setOpen(null)}
            redirect={redirect}
          />
        </Fragment>
      ) : null}
      <Header
        onChange={(i) => setView(i)}
        btnFilter={[
          language.PROJECT_PORTFOLIO,
          language.ALLOCATION_AND_DELIVERIES,
        ]}
      />
      <Card className="content" id="GanttPage">
        {view === 0 ? (
          <Grid
            container
            spacing={2}
            style={{ '--cardGreen': themes.cardGreen }}
          >
            {portfolio.map((e, i) => (
              <Grid item xs={2} className="content-indicator">
                <Indicator {...e} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Fragment>
            <Grid
              container
              spacing={2}
              style={{ '--cardGreen': themes.alocation.cardGreen }}
            >
              <Grid item xs={4}>
                <Alocation language={language} pmos={pmos} options={options} />
              </Grid>
              <Grid
                item
                xs={2}
                className="d-flex justify-content-between"
                style={{ flexDirection: 'column' }}
              >
                <Indicator2
                  title={language.TEAM_AVERAGE}
                  value={data.team_average}
                  language={language}
                />
                <Indicator2
                  title={language.ACCUMULATED_TOTAL}
                  value={data.team_acumulated}
                  language={language}
                />
              </Grid>
              <Grid item xs={3.5} style={{ ...flexBasis(3.5) }}>
                <Card className="h-100">
                  <AppBar title={language.PERFORMANCE_OF_PROJECTS} />
                  <div className="content-medium pt-0">
                    <ReactHighcharts config={perfomance(...data.perfomance)} />
                  </div>
                </Card>
              </Grid>
              <Grid item xs={2.5} style={{ ...flexBasis(2.5) }}>
                <Card className="h-100">
                  <AppBar title={language.SAT} />
                  <div className="-mt-4">
                    <ReactHighcharts
                      config={satisfation(
                        parseInt(
                          (data.satisfation.resolved * 100) /
                            (data.satisfation.resolved +
                              data.satisfation.notResolved)
                        )
                      )}
                    />
                    <div className="d-flex align-items-center">
                      <Tag
                        label={language.ACCEPTABLE}
                        className="-mt-4"
                        style={{ margin: '0 auto' }}
                      />
                    </div>
                    <List
                      items={[
                        {
                          content: (
                            <div className="content-list-item">
                              <div className="content-label">
                                <div></div>
                                <span>{language.PROBLEMS_SOLVED}</span>
                              </div>
                              <span className="value">
                                {data.satisfation.resolved}
                              </span>
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div className="content-list-item">
                              <div className="content-label">
                                <div></div>
                                <span>{language.UNRESOLVED_PROBLEMS}</span>
                              </div>
                              <span className="value">
                                {data.satisfation.notResolved}
                              </span>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Card>
    </div>
  );
}
