import React, { Component } from 'react';
import ReactHighChart from 'react-highcharts';

export default class SlaGauge extends Component {
  render() {
    let { value } = this.props;

    var config = {
      chart: {
        type: 'solidgauge',
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0]
      },

      credits: { enabled: false },
      tooltip: { enabled: false },
      exporting: { enabled: false },
      title: { text: null },

      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [],
        size: '130%',
        center: ['50%', '75%']
      },

      plotOptions: {
        gauge: {
          dataLabels: { enabled: false },
          dial: {
            radius: '50%'
          }
        }
      },

      yAxis: {
        min: 0,
        max: 100,
        tickAmount: 0,
        minorTicks: false,
        lineColor: '#FFF',
        lineWidth: 5,
        labels: { enabled: false },

        plotBands: [
          { from: 0, to: 76, color: '#289BC1', thickness: '40%' },
          { from: 76, to: 80, color: '#FFFFFF', thickness: '40%' },
          { from: 80, to: 100, color: '#D99D0B', thickness: '40%' }
        ]
      },

      series: [
        {
          name: 'Speed',
          data: [value]
        }
      ]
    };

    return <ReactHighChart config={config} />;
  }
}
