import './RequestView.css';
import React, { Component } from 'react';
import {  Row, Col } from 'react-grid-system';
import { Card, Grid } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import RaisedButton from '@common/RaisedButton';
import PageLayout from '../../common/PageLayout';
import moment from 'moment';
import BarBlue from '@images/BarBlue.png';
import Button from '../../common/Button';
import Dialog from '@common/Dialog';
import AppBar from '@common/AppBar';
import ListColumn from '@common/ListColumn';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AttachFile from '@common/AttachFile';

@observer
export default class RequestView extends Component {
  render() {
    let {
      language,
      handlePage,
      catHandle,
      request,
      dash,
      ondonwFile,
      redirect2,
      store,
      open=true,
      onClose,
      version
    } = this.props;
    
    
    let back = (
      <Button
        variant="circle"
        icon="fas fa-arrow-left"
        color="primaryGradient"
        label={language.BACK}
        onClick={() => redirect2('/dash/request')}
      />
    );
   

    if (dash) {
      back = <i></i>;
    }
    if (request) {
    } else {
      request = store.requests.filter(
        g => g.fk_id_request == request.fk_id_request
      )[0];
    }

    let stt = null;
    let br = sessionStorage.getItem('sensr_lang') == 'PT_BR';
    if (
      sessionStorage.getItem('sensr_lang') == 'PT_BR' ||
      sessionStorage.getItem('sensr_lang') == null
    ) {
      if (request) {
        if (request.status == 'In Progress') {
          stt = 'Em Atendimento';
        } else if (request.status == 'Resolved') {
          stt = 'Resolvido';
        } else if (request.status == 'Reopened') {
          stt = 'Reaberto';
        } else if (request.status == 'Approve') {
          stt = 'Em aprovação';
        } else if (request.status == 'Approved') {
          stt = 'Aprovado';
        } else if (request.status == 'Open') {
          stt = 'Aberto';
        } else if (request.status == 'Closed') {
          stt = 'Encerrado';
        } else if (request.status == 'Cancelled') {
          stt = 'Cancelado';
        } else if (request.status == 'Reject') {
          stt = 'Rejeitado';
        }
      } else {
        stt = request.status;
      }
    }

   

  //  store.catalogs.filter(n=>n.id_catalog_service === )
  let category= request.namecategory_;
  let service = request.catalogservicename_;
  let terceironivel= request.catalogtaskname_;
 
      
     
let req=[];
{request.files != undefined? request.files.filter(g => this.props.filesdelete != null ? !this.props.filesdelete.includes(g):g!=null ) .map(f => (
 req.push(<li className='py-1'>
    <AttachFile
      label={
        f.split('|')[0]+' - '+moment(f.split('|')[3]).format('L')
      }
      onClick={() =>
        ondonwFile(f.split('|')[2], f.split('|')[0])
      }
      onDelete={()=>this.props.ondeletefile(f)}
    />
  </li>)
)):null}
    
    return (
      <Dialog
        PaperProps={{
          id: 'RequestView'
        }}
        open={open}
        onClose={onClose}
        bar={false}
        dialogChildren={
          <div
            className="d-flex align-items-start justify-content-between bar"
            style={{
              background: `url(${BarBlue})`,
              borderBottom: '3px solid var(--warning)'
            }}
          >
            <div className="pt-3 pb-1 pl-3 w-100">
              <div className="d-flex align-items-start">
                <div className="w-100" style={{ zoom: 0.8 }} zoom={0.8}>
                  <Tooltip
                    html={
                      <span>{request.subject}</span>
                    }
                    arrow={true}
                    position='right'
                    theme='dark'
                    
                  >
                    <span className="subject_fixed ellipsis">{request.subject}</span>
                   
                  </Tooltip>
                </div>
              </div>
            </div>
            <IconButton
              aria-label="Close"
              className="closeButton"
              onClick={onClose}
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </div>
        }
      >
       <div className="content">
         <ListColumn
           col={6}
           items={[
             {
               label: language.STATUS,
               value: stt
             },
              version?{
                label:'Versão',
                value:version
             }:null,
             {
               label: language.DATE_CAD,
               value: moment(request.dt_cad).format('L')
             },
             {
               label: language.REGISTER_HOUR,
               value: moment(request.dt_cad).format('LTS')
             },
             {
               label: language.COMPANY,
               value: request.name_company
             },

             {
               label: language.SOLICITANT,
               value:
                 request.namerequest == null
                   ? request.name
                   : request.namerequest
             },
             {
               label: language.EMAIL,
               value:
                 request.emailrequest == null
                   ? request.username
                   : request.emailrequest
             },
             {
                visible:request.status == 'Cancelled' || request.status == 'Reject',
                label: language.REASON,
                value: (
                  <p
                     style={{ color: '#888080' }}
                     dangerouslySetInnerHTML={{
                       __html: request.reason
                     }}
                  />
                )
             },
             {
               label: `Id ${language.REMOTE_ACCESS}`,
               value: request.id_access_remote
             },
             {
               label: language.PHONE,
               value: request.phone
             },
             {
               label: language.TELEPHONE_EXTENSION,
               value: request.ramal
             },
             {
              label: language.CATEGORY,
              value: category
            },
             {
              label: language.SUB_CATEGORY,
              value: service
            },
            {
              label: language.THIRDED_LEVEL,
              value: terceironivel
            },
           
             {
              col:12,
              label: language.DESCRIPTION,
              value: (
                <p
                   style={{ color: '#888080' }}
                   dangerouslySetInnerHTML={{
                     __html: request.description
                   }}
                />
              )
             },
             {
              col:12,
              label:language.ATTACHMENT,
              value:(
                <ul className='my-0' style={{zoom:.8}}>
                {req.map(v=>v)}
                </ul>
              )
            }
           ].filter(Boolean)}
         />
       </div>
        
      </Dialog>
    );
  }
}
