import React, { useState, useEffect, useContext } from 'react';
import { Row } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

import GI2Context from './GI2Context';

import Constants from '@data/Constants';

const tenant = Constants.Tenant;

export default function ({ device ,ioLinks }) {
  const [values, setValues] = useState({});
  const [icmp, setIcmp] = useState([]);

  

  useEffect(() => {
   
  
    try {
      ioLinks.on(`_${tenant}_${device.id_device}`, (data) => {
        setIcmp(data.data);
      });
    } catch (e) {}
  }, [ioLinks,device]);

  return (
    
      <div
        className="online"
        style={{
          width: '100%',
          paddingLeft: '30px',
          backgroundColor: '#FBFBFB',
        }}
      >
        <div className="d-flex justify-content-between">
          <span style={{marginTop:'20px'}}>
         {'R.Time: ' + icmp.length === 0 ? 0 : icmp[0]} ms   
         </span>
         <span>
          {typeof icmp == 'object' && <LinkGraph data={icmp} />}
          </span>
        </div>
      </div>
     
  );
}

function LinkGraph({ data }) {
  const [color, setColor] = useState('#33ABA0');

  useEffect(() => {
    try {
      let arr = data.data;
      let zarr = [];
      let x = 10;

      for (let j = 0; j < 6; j++) {
        let narr = [];
        let i = j * 10;
        for (i; i < x; i++) {
          narr.push(arr[i]);
        }
        x = i + 10;
        zarr.push(
          narr.reduce((sum, a) => {
            return sum + a;
          }, 0) / (narr.length || 1)
        );
      }
      let average =
        zarr.reduce((sum, a) => {
          return sum + a;
        }, 0) / (zarr.length || 1);

      var danger = 0;
      var warning = 0;
      zarr.map(function (val) {
        // if(val > nextProps.triggers.icmp.danger.value) danger++;
        //if(val > nextProps.triggers.icmp.warning.value) warning++;
        if (val > 90) danger++;
        if (val > 80) warning++;
      });

      if (warning == 6 && danger < 6) this.state.color = '#FFC107';
      else if (danger == 6) this.state.color = '#F44336';
      else this.state.color = '#33ABA0';
    } catch (e) {}
  }, [data]);

  if (data == undefined) {
    data = [];
  }
  return data.length > 0 && data[0] > -1 ? (
    <Sparklines style={{ width: '130px' }} data={data}>
      <SparklinesLine color={color} />
      <SparklinesSpots />
    </Sparklines>
  ) : (
    <Sparklines style={{ width: '130px' }} data={[0, 0, 0, 0, 0, 0, 0]}>
      <SparklinesLine color={color} />
      <SparklinesSpots />
    </Sparklines>
  );
}
