import React from 'react';
import moment from 'moment';
/*-------------------common---------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import Loading from '@common/Loading';
/*-------------------material-ui---------------------------*/
import { Card } from '@material-ui/core';
/*-------------------pages---------------------------*/
import { TicketEvolution } from './Graph';

export default function ({ data, language }) {
  return (
    <Card id="TicketEvolution" className="h-100">
      <AppBar title={language.EVOLUTION_OF_TICKETS} />
      <div className="content-medium">
        <ReactHighcharts
          config={TicketEvolution({
            categories: Array.from(
              new Array(moment().daysInMonth()),
              (_, i) => i + 1
            ),
            data,
          })}
        />
      </div>
    </Card>
  );
}
