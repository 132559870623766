/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import { InputAdornment } from '@material-ui/core';
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
    padding: 10,
  },
  input: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,

    //---para input outlined
    /*padding-bottom: 9.5px;
    padding-top: 9.5px;*/
  },
  input2: {
    display: 'flex',
    paddingBottom: 9.5,
    paddingTop: 9.5,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${8 / 2}px ${8 / 4}px`,
  },

  noOptionsMessage: {
    padding: `${8}px ${8 * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 100,
    marginTop: 4,
    left: 0,
    right: 0,
  },
  divider: {
    height: 8 * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.selectProps.language.NO_OPTIONS}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  var { input2, icon } = props.selectProps.textFieldProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        ...(icon
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {typeof icon == 'string' ? (
                    <i className={classnames(icon, 'color-lead')}></i>
                  ) : (
                    icon
                  )}
                </InputAdornment>
              ),
            }
          : {}),
        inputProps: {
          className: !input2
            ? props.selectProps.classes.input
            : props.selectProps.classes.input2,
          inputRef: props.innerRef,
          children: props.children,

          ...props.innerProps,
          ...props.selectProps.textFieldProps.inputProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <Tooltip
      html={
        <div style={{ color: '#fff', fontSize: 15 }}>
          <strong>{props.children}</strong>
        </div>
      }
      position="right"
      theme="dark"
    >
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        className="py-4"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    </Tooltip>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      {...props.selectProps.menuProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  constructor() {
    super();
    this.state = {
      shrink: false,
    };
  }
  sortLabel = (arr) => {
    return arr.sort(function (a, b) {
      if (a.label && b.label) {
        var textA = a.label;
        var textB = b.label;
        if (parseInt(textA) && parseInt(textB)) {
          textA = parseInt(textA);
          textB = parseInt(textB);

          return textA < textB ? -1 : textA > textB ? 1 : 0;
        } else {
          return textA.localeCompare(textB);
        }
      }
    });
  };

  render() {
    var {
      classes,
      theme,
      label,
      options,
      menuProps,
      textFieldProps,
      readOnly,
      value,
      ...other
    } = this.props;
    let { disabled, variant, icon, className, ...textFieldPropsOther } =
      textFieldProps;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    options = options.reduce((e, i) => {
      if (!e.find((e) => e.value == i.value)) e.push(i);
      return e;
    }, []);

    options = this.sortLabel(options);

    var maxLabel = 0;
    try {
      options.reduce((e, i) => {
        if (e.length < i.label.length) e = i.label;
        return e;
      }, '').length;
    } catch (e) {}
    var opt = options.find((e) => e.value === value);
    let _this = this;
    return (
      <NoSsr>
        <Select
          classes={classes}
          options={options}
          components={components}
          value={opt || ''}
          isDisabled={disabled || readOnly}
          menuProps={{
            style: {
              ...(maxLabel > 31 ? { width: 9 * maxLabel } : {}),
            },
            ...(menuProps || {}),
          }}
          Tooltip={maxLabel > 31}
          textFieldProps={{
            className: classnames(className, {
              selectField: variant != 'outlined',
            }),
            label: label,
            input2: variant == 'outlined',
            icon,
            InputLabelProps: {
              shrink: !!icon || (opt !== undefined ? true : this.state.shrink),
            },
            onFocus: () => _this.setState({ shrink: true }),
            onBlur: () => _this.setState({ shrink: false }),
            ...{ ...textFieldPropsOther, variant },
          }}
          isClearable={false}
          placeholder=""
          {...other}
          styles={{ ...selectStyles, ...this.props.styles, padding: 20 }}
        />
      </NoSsr>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
