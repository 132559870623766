
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { Chip } from '@material-ui/core';
import Button from '../../common/Button';
import { translateFromPtBR } from '@common/languages/Dictionary';

const LocationList = ({
  language,
  locations,
  handlePage,
  currentPage,
  totalLocations,
  callPageSelectFilter
  }) => {
  const col = [
    {
      key: 'id',
      label: language.ID,
      style: { width: 1, paddingRight: 0 },
      thConfig: true,
    },
    {
      key: 'type',
      label: language.TYPE,
      style: { width: 30, paddingRight: 0 },
      thConfig: true,
    },
    {
      key: 'name',
      label: language.NAME,
      style: { width: 200, paddingRight: 0 },
      thConfig: true,
    },
    {
      key: 'company',
      label: language.COMPANY,
      style: { textAlign: 'center', maxWidth: 100, overflow: 'auto' },
    },

    {
      key: 'street',
      label: language.STREET,
      style: { width: 200, paddingRight: 0 },
      thConfig: true,
    },
    {
      key: 'country',
      label: language.COUNTRY,
      style: { paddingRight: 0 },
      thConfig: true,
    },
    {
      key: 'actions',
      label: language.ACTIONS,
      style: { width: 90, textAlign: 'center' },
      jc: 'center',
    },
  ];

  return (
    <div>
      <CommonTable
        title=""
        data={locations.map((obj) => {
          return {
            id: obj.id_location,
            type:
              obj.type === 1
                ? language.FARM
                : obj.type === 2
                ? language.OFFICE
                : language.FACTORY,
            name: obj.name,
            company: obj.company,
            street: obj.street,
            country: translateFromPtBR(obj.country, language),
            actions: (
              <div>
                <Chip
                  className="mx-2 muit-chip"
                  label={
                    <div className="d-flex align-items-center justify-content-between">
                      <Button
                        variant="circle"
                        icon="fas fa-edit"
                        label={language.EDIT}
                        color="default"
                        style={{ fontSize: 12, width: 21, height: 21 }}
                        size={0.9}
                        onClick={() => handlePage('edit', obj)}
                      />
                      {obj.type !== 0 ? (
                        <RaisedButton
                          circleButton
                          icon="far fa-trash-alt"
                          label={language.DELETE}
                          color="danger"
                          style={{ fontSize: 10, width: 19, height: 19 }}
                          onClick={() => handlePage('remove', obj)}
                        />
                      ) : null}
                    </div>
                  }
                />
              </div>
            ),
          };
        })}
        columns={col}
        language={language}
        orderColumn
        paginationTop={false}
        searchColumn={true}
        currentPage={currentPage}
        countTotal={totalLocations}
        isAutomaticPagination={false}
        searchColumnEvent={(v) => {
          callPageSelectFilter(0, v, 75);
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
        onClearAllFilter = {() => callPageSelectFilter()}
        beforeBar={
          <Button
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.NEW_LOCATION}
              primary={true}
              onClick={() => handlePage('new')}
            />
        }
      />
    </div>
  );
}

export default LocationList
