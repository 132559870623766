import React, { useState, useEffect, useReducer, useCallback } from 'react';
import './index.css';

// --------------------common--------------------------
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Progress from '@common/Progress';
import SearchInput from '@common/SearchInput';
import PageLayout from '@common/PageLayout';
// ------------------------------icons-----------------------------------------
 
import Form from './Form';

// ----------------------------------------------------
import { Avatar, Card, MenuItem, Select } from '@material-ui/core';

import Detail_1 from './Detail_1';

export default function({
  risks,
  language,
  saveTest,
  users,
  riskType,
  idcompany,
  APIEndpoints,
  action,
  history,
  name,
  activity,
  toggleAlert,
  UserLoged,
  themes,
  companyName,
  dados,
  setDados
}) {
  let [search, setSearch] = useState('');
  let [modal, setModal] = useState(false);
  let [usersList, setUsersList] = useState([]);
  let [projects, setProjects] = useState([]);
  let [activities, setActivities] = useState([]);

  let [mediaprogress, setMediaprogress] = useState(0);

  let [mediaMaturidadeAtual, setmediaMaturidadeAtual] = useState(0);

  let [mediaMaturidadeDesejada, setmediaMaturidadeDesejada] = useState(0);
  let [indexOpen, setIndexOpen] = useReducer((state, value)=>(

  	state.includes(value)?state.filter(e=>e!=value):[value]

  ), []);
  /*let [indexOpen, setIndexOpen] = useReducer((state, value)=>(

  	state.includes(value)?state.filter(e=>e!=value):[...state, value]

  ), []);*/

const PorValue=(items)=>{

   let total =0;
   items.filter(b=>b.progresso >0).map(g=>{
     total =total +  parseInt(g.progresso)
   })
  

   return ((total/(items.length*100))*100).toFixed(0);
   
}

const addvalues=(secs)=>{
  
  secs.map(sec=> {

    if(form.applicable[sec.id]==undefined){
  form.applicable[sec.id] = sec.applicable;
  
  
  form.current_maturity[sec.id] = sec.current_maturity;


  form.planned_maturity[sec.id] = sec.planned_maturity;
 

  form.tech[sec.id] = sec.fk_id_user;
 

  form.project_activity[sec.id] = sec.fk_id_project;


  form.tickets[sec.id] = sec.fk_id_ticket


 
  form.execution_date[sec.id] = sec.execution_date;

  form.adherent[sec.id] = sec.adherent;



  form.userA[sec.id] = sec.usera;

  form.userR[sec.id] = sec.userr;

  form.userI[sec.id] = sec.useri;

  form.userC[sec.id] = sec.userc;
 
  form.files[sec.id] = sec.files;


  form.review_period[sec.id] = sec.review_period;
 
  form.priority[sec.id] = sec.priority;
  form.desc[sec.id] = sec.desc
  
    }
})
return true
}


  function ProgressStage({ percent, color, value, form, setForm, type, id }) {
     
    return (
      <div className="content-stage-progress">
        {[ 1, 2, 3, 4, 5].map(e => (
          <div
            style={{
              backgroundColor: color,
              cursor: 'pointer',
              textAlign: 'center'
            }}
            key={e}
          >
            {parseInt(e) == parseInt(value) ? (
              <i
                class="fas fa-caret-down"
                style={{
                  fontSize: '26px',
                  marginTop: '-13px',
                  color: 'rgb(115 103 103)',
                  color: '#D9DDE5',
                  transform: 'scaleX(.7)'
                }}
              ></i>
            ) : (parseInt(e) == 1 && value < 1) ? (
              <i
                class="fas fa-caret-down"
                style={{
                  fontSize: '26px',
                  marginTop: '-13px',
                  marginLeft: '-8px',
                  color: 'rgb(115 103 103)',
                  color: '#D9DDE5',
                  transform: 'scaleX(.7)'
                }}
              ></i>
            ): (null)}
          </div>
        ))}
      </div>
    );
  }
  const reducer = (state, value) => ({ ...state, ...value });
  let [form, setForm] = useReducer(reducer, {
    open: [],
    accepted: [],
    accepted2: [],
    valores: [],
    files: [],
    tech: [],
    applicable: [],
    project_activity: [],
    execution_date: [],
    current_maturity: [],
    planned_maturity: [],
    userR: [],
    userA: [],
    userC: [],
    userI: [],
    tickets: [],
    adherent: [],
    review_period: [],
    priority :[],
    desc:[],
    norma:name
  });

  const saveTest_ = () => {
    let Switch = [];
    var checked = document.querySelectorAll('input[type=checkbox]:checked');
    /*	checked.forEach(c => {
			  if (c.type == 'checkbox' && c.value != 'checkedB') {
				Switch.push(parseInt(c.value));
			  }
			});*/

    form.applicable.map((u, i) => {
      if (u == true) {
        Switch.push(i);
      }
    });
 
     saveTest(
      Switch,
      form.files,
      form.tech,
      form.project_activity,
      form.execution_date,
      form.current_maturity,
      form.planned_maturity,
      form.adherent,
      form.applicable,
      form.review_period,
      form.userA,
      form.userC,
      form.userI, 
      form.userR,
      form.tickets,
      form.priority,
      form.desc,
      form.norma
    ); 






    
  };
  useEffect(() => {
    //action.execute('get', APIEndpoints.CATALOG, 'catalogs');
    getDados();
  }, []);
  const getDados = () =>{
    action
      .execute(
        'get',
        APIEndpoints.RISK + '/' + riskType + '/company/' + idcompany,
        'risks'
      )
      .then(h => {
        setDados(h.data);
      });

    action.execute('get', APIEndpoints.PROJECT, 'projects').then(g => {
      setProjects(g.data);
    });
  }
  let vv = 0;
  let idd = 0;

  usersList = users.map(u => ({
    value: u.id_user,
    label: u.name + ' - ' + u.email
  }));
  

 
  const calcProgress = useCallback((data = [], attr ='') => {
   
 
    let result =   data.reduce((accum,item) =>{ 
        return accum + (item[attr]||0)
       }, 0);

   
      let i =  parseInt(result/data.length)
   
    
    return i;
  }, []);
  
  return (
    <PageLayout
      subTitle={`${language.RISKS_MANAGEMENT_COMPLIANCE} / ${name} /`}
      title={language.CONFIG}
      rightElements={[ 
      
      <div className='cc' >{companyName}</div>,
       
      
 
    
    
    
    ]}
    >
      <div id="ConfigurationDashboard">

        <Form open={modal} onClose={() => setModal(false)} />
        <header className="d-flex align-items-center justify-content-between content py-0" style={{'--bar':themes.config.bar}}>
          <div className="d-flex align-items-center">
            <Button
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              color="warning"
              onClick={() => setModal(true)}
            />
            <SearchInput
              value={search}
              onChange={evt => setSearch(evt.target.value)}
              className="mx-3"
            />
         

             
           { /* <Button icon="fas fa-print" label="Imprimir" color="warning" />*/}
          
          </div>
         
          <div style={{display:'flex' }}>
           <div style={{    marginLeft: 'auto',
         color:'white',width:'260px' }} >
              {language.CURRENT_MATURITY}
            </div>
            <div style={{    marginLeft: 'auto',
         color:'white',width:'290px' }} >
              {language.DESIRED_MATURITY}
            </div>
            <Button
        label={language.SAVE}
        color="success"
        size={1.2}
        style={{marginRight:'10px'}}
        onClick={() => saveTest_()}
      />
            </div>
            
          
        </header>
        <Card>
       
          <CommonTable
            bar={false}
            pagination={true}
            data={dados.flatMap(section=>[
              
		    	{ 
			        ConfigCell: {
			          className: 'line-primary',
			          cols: {
			            col_1: { className: 'table_1_name' }
			          }
			        },
			        first_col: (
			          <Button
			          	onClick={()=>setIndexOpen(section.id)}
			            icon={indexOpen.includes(section.id)?'fas fa-chevron-down':'fas fa-chevron-right'}
			            variant="circle"
                  label={language.MORE_INFORMATION}
			            color="default"
			            className="expand-info"
			            size={0.8}
			            outlined
			            style={{
			              border: 'none'
			            }}
			          />
			        ),
			        name: section.desc,
			        progress: (
			          <div className="d-flex align-items-center" style={{ width: 100 }}>
			            <Progress
                    current={section.items&&PorValue(section.items)
                      
                    }
			              hiddenText
			              style={{ height: 7, width: '100%' }}
			              background="var(--warning)"
			            />
			            <span
			              style={{ color: '#707070', fontSize: 10 }}
			              className="ml-2"
			            >
                    {    
                  section.items&&PorValue(section.items)      }% 
                      
                         
			            </span>
			          </div>
			        ),
			        progress_btn: (
                 <ProgressStage
                  value={section.items&& calcProgress(section.items, 'current_maturity')}
			            color={'#FA9633'}
			            percent={2}
			          />
			        ),

			        progress_btn_2: (
			          <ProgressStage
			          value={section.items&&  calcProgress(section.items, 'planned_maturity')}
            
			            color={'#009dc0'}
			            percent={4}
			          />
			        ),
			        avatares: (
			          <div className="d-flex align-items-center content-avatares">
			           
			          </div>
			        )
			    },


      



			    section.items&&addvalues(section.items)&&indexOpen.includes(section.id)?{
		          ConfigCell: {
		            cols: {
		              col_0: {
		                colSpan: 6,
		                style: { paddingLeft: 0, paddingRight: 0 }
		              }
		            }
		          },

		          first_col: (
		            <Detail_1
                UserLoged={UserLoged}
                  users={users}
                  save={saveTest_}
		              usersList={usersList}
		              setmediaMaturidadeAtual={setmediaMaturidadeAtual}
		              setmediaMaturidadeDesejada={setmediaMaturidadeDesejada}
		              projects={projects}
		              ac={action}
		              setMediaprogress={setMediaprogress}
		              APIEndpoints={APIEndpoints}
		              activity={activity}
                  form={form}
                  riskType={riskType}
                  idcompany={idcompany}
                  language={language}
                  toggleAlert={toggleAlert}
		              setForm={setForm}
		              section={section.items}
                  getDados = {getDados}
		            />
		          )
		        }:null

		    ].filter(Boolean))
        }/>
        </Card>
      
      </div>
    </PageLayout>
  );
}
