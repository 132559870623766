import React from 'react';
import AppBar from '@common/AppBar2';
import { Grid } from '@material-ui/core';
import Store from '@data/Store';

const { language } = Store;

const Item = ({title, value, percent, onClick})=>(
	<div className='item content-min' onClick={onClick}>
		<span className='title'>{title}</span>
		<div className='content-statistic'>
			<h2>{value||0}</h2>
			<span>({percent||0}%)</span>
		</div>
	</div>
);

export default function({data=[], setOpenAct}){
	
	let total = data.reduce((n, e) => n+e.value, 0)
	return(
		<div className='EvolutionKPI px-0 wrap'>
			<AppBar title={language.EVOLUTION_KPIS}>
				<div className='qtd'>
					{total}
				</div>
			</AppBar>
			<div className='content-medium pt-0'>
				<Grid container spacing={2}>
					{data.map(e=>(
						<Grid item xs={2} className='content-item'>
                     <Item 
                        title={e.title} 
                        onClick={() => setOpenAct({key:e.key, data:e.data})} 
                        value={e.value} 
                        percent={Math.round(e.value*100/total)}
                     />
						</Grid>
					))}
				</Grid>
			</div>
		</div>
	)
}