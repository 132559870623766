import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem';
import { BoxList, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TextList } from '../portal/SidebarCompanies';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useServicePortalStyles } from './helpers';


export const CustomExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'transparent',
        minHeight: '40px !important',
        color: {},

        '&$expanded': {
            minHeight: 56,
            //padding: '15px 0  0 12px',
            paddingTop: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

export const CustomExpansionPanelDetails = withStyles({
    root: {
        minHeight: 38,
        maxHeight: 46,
        width: '100%',
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: '6px 16px',
        alignItems: 'center'
    },
})(MuiExpansionPanelDetails);


export default function ServicePortalCatalog({ companies, catalogSelected, isMultiCatalog, onSelectCompany, onSelectDepartment }) {
    const styles = useServicePortalStyles();
    //console.log('service-catalog', companies)
    const items = isMultiCatalog ? companies : companies.length === 1 ? companies[0].firstLevel : [];

    return (
        <ul className='sp-catalog'>
            {items?.map(
                (firstLevelItem) => (
                    <ListItem key={firstLevelItem.id} className='sp-catalog-item tw-bg-gray-100 tw-border-b-2' style={{ padding: 0 }}>
                        <ExpansionPanel
                            square
                            elevation={0}
                            className='tw-w-full'
                            expanded={firstLevelItem.id == catalogSelected.firstLevel}
                            onChange={() => { onSelectCompany(firstLevelItem) }}
                        >
                            <CustomExpansionPanelSummary
                                className={classNames({ 'sp-item-selected font-bold': firstLevelItem.id == catalogSelected.firstLevel })}
                                aria-controls={firstLevelItem.id + 'd-content'}
                                id={firstLevelItem.id + 'd-header'}
                            >
                                <Typography component='span' className={classNames('sp-catalog-item-text', styles.title)}>
                                    {firstLevelItem.name}
                                </Typography>
                            </CustomExpansionPanelSummary>

                            <div className='tw-border tw-border-b-0 tw-border-gray-100'>
                                {firstLevelItem.secondLevel?.map((secondLevelItem) => (
                                    <CustomExpansionPanelDetails
                                        key={`${firstLevelItem.id}_${secondLevelItem.id}`}
                                        onClick={() => { onSelectDepartment(firstLevelItem, secondLevelItem); }}
                                        className={
                                            classNames('sp-catalog-subitem tw-w-full tw-border-b-2 tw-border-gray-100',
                                                { 'sp-item-selected font-bold': secondLevelItem.id == catalogSelected.secondLevel })
                                        }
                                    >
                                        <Typography component='span' className={classNames('tw-pl-4 sp-catalog-subitem-text', styles.text)}>
                                            {secondLevelItem.name}
                                        </Typography>
                                    </CustomExpansionPanelDetails>
                                ))}
                            </div>
                        </ExpansionPanel>
                    </ListItem>
                )
            )}
        </ul>
    )
}
