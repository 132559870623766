import React, { Fragment } from 'react';
import SearchInput from '@common/SearchInput';
import Button from '@common/Button';
import Header from '@common/Header';
import {Link} from 'react-router-dom';
import SwitchIos from '@common/SwitchIos';

export default function({
	language,
	open,
	setOpen,
	search
}){
	return(
		<Header className='d-flex align-items-center justify-content-between'>
			<div className='d-flex align-items-center'>
				<SearchInput onChange={search} placeholder={language.SEARCH+'...'}/>
				<div onClick={() => window.location ="/dash/gp/prioritization/avaliation"} >

					<Button 
						icon='fas fa-diagnoses' 
						label={language.EVALUTION}
						color='success'
						className='ml-3'
						/>
				</div>
			</div>
			<SwitchIos 
				checked={open} 
				onChange={(evt) => setOpen(evt.target.checked)}
			/>
		</Header>
	)
}