import React, {Fragment, useState, useEffect} from 'react';
import DialogConfirm from '@common/DialogConfirm';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import Form from './Form';

import Store from '@data/Store';

const { language } = Store;

export default function({user, ticketparam,execute, APIEndpoints, options,projects,project_stages, handleValue, values, open, onClose, redirect, onUpdate=()=>null, setTickets} ){
   let [modalIsTemplate, setModalIsTemplate] = useState(false);
   let [isTemplate, setIsTemplate] = useState(false);
   let [formOpen, setFormOpen] = useState(false);
   let [visibleForm, setVisibleForm] = useState(false);
 
   useEffect(()=>{

     
      if(open){
         if(!values){
            setModalIsTemplate(true);
         }else{
            setFormOpen(true);
         }
      }
      
     
   }, [open])
   


   function selectTemplate(){
      execute('post', APIEndpoints.PROJECT + '/projectlist', 'project_stages', {template:true}).then(h=>{
         setModalIsTemplate(false);
         setIsTemplate(true);
      });
   }
 
 
   return (open) == true ?(
      <Fragment>
         {ticketparam == undefined ?
            <DialogConfirm
               open={modalIsTemplate}
               maxWidth="xs"
               onClose={() => {
                  onClose();
               }}
               title={language.NEW_PROJECT}
               message={language.DO_YOU_WANT_TO_CREATE_THE_PROJECT_FROM_A_TEMPLATE}
               confirmLabel={language.YES}
               cancelLabel={language.NO}
            miniVersion = {true}
            cancelProps={{
               color: 'primary'
            }}
            eventClose={false}
            onConfirm={() =>{
               selectTemplate();
            }}
            onCancel={() =>  {
               setModalIsTemplate(false);
               setFormOpen(true);
            }}
         /> :
         <Dialog 
   open={true}
 >
    <div style={{minHeight:!visibleForm ?'90px':'300px',textAlign:'center'}}>
       <div style={{marginBottom:10}}>
       <Button
        
        variant="normal"
        color="success"
         label={language.MIGRATE_TO_A_NEW_PROJECT}
        size={0.9}
      
        onClick={()=>{
            setModalIsTemplate(false);
              setFormOpen(true);
        }
      }
        zoom={0.9}
        style={{ fontSize: 14 , marginRight:30}}
        
      />
      <Button
        
        variant="normal"
        color="warning"
         label={language.MIGRATE_TO_A_PROJECT_ACTIVITY}
        size={0.9}
      
        onClick={()=>{
         setVisibleForm(true)
        }
      }
        zoom={0.9}
        style={{ fontSize: 14 , marginLeft:30}}
        
      />

       </div>
       <h4></h4>
 {visibleForm ?<CommonForm
            fields={[
               {
                  type: 'select',
                  name: 'gestor',
                  label: language.MIGRATE_TO_A_PROJECT_ACTIVITY,
                  options:  projects.map(v=>({value:v.id_project, label: v.name}))
               }
            ]}
            onChangeField={(f, v) => {
               redirect('/dash/gp/'+v ,{ ticket: ticketparam } )
              
            }}
   >

  </CommonForm>:null}
  </div>
 </Dialog>}


 

         {isTemplate?(
            <Dialog
               open={true}
               onClose={() => {
                  onClose();
                  setIsTemplate(false)
               }}
               title={language.SELECT_TEMPLATE}
               maxWidth='xs'
               monitor_scroll
            >
               <CommonForm
                  fields={[
                     {
                        type: 'select',
                        name: 'gestor',
                        label: language.PROJECT_TEMPLATE,
                        options: options.project_stages()
                     }
                  ]}
                  onChangeField={(f, v) => {
                     let project = project_stages && project_stages[0].projetos.find(b=>b.id_project==v)||{}
                     project.isTemplate = true;
                     handleValue(project);
                     setIsTemplate(false);
                     setFormOpen(true);
                  }}
               />
            </Dialog>
         ):(
            <Form 
               options={options}
               values={values} 
               open={formOpen} 
               redirect={redirect}
               APIEndpoints={APIEndpoints}
               execute={execute}
               ticketparam={ticketparam}
               onClose={() => {
                  setFormOpen(false);
                  onClose();
                  handleValue();
               }}
               onUpdate={onUpdate}
               setTickets = {setTickets}
               user = {user}
            />
         )}
         
      </Fragment>
   ) 
   
:null
}