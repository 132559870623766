import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import {
  Card,
  Paper,
  Dialog,

  Step,
  Stepper,
  StepLabel
} from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import { observer } from 'mobx-react';

import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';

import CommonForm from '../../common/CommonForm';

import { FaServer } from 'react-icons/lib/fa/';

@observer
export default class InventoryDetail extends Component {
  constructor() {
    super();
    this.state = {
      type: 'desktop',
      connection: '',
      company: '',
      user: ''
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(values) { }

  render() {
    let { language, values } = this.props;

    let { type, connection } = this.state;
    let fields;

    fields = [
      { span: 3, name: 'type', label: language.TYPE, type: 'text' },
      { span: 3, name: 'name', label: language.STOCK_NAME, type: 'text', required: true },
      { span: 3, name: 'Company', label: language.COMPANY, type: 'text' },
      {
        span: 3,
        name: 'IPAddress',
        label: language.IP,
        type: 'text',
        required: true
      },
      { span: 3, name: 'Connection', label: language.CONNECTION, type: 'text' },
      {
        span: 3,
        name: 'Patrimony',
        label: language.PATRIMONY,
        type: 'text',
        required: true
      },
      {
        span: 3,
        name: 'connection',
        label: language.CONNECTION,
        type: 'text',
        required: true
      },
      { span: 3, name: 'patrimony', label: language.PATRIMONY, type: 'text' },
      { span: 3, name: 'cost', label: language.COST, type: 'number' },
      { span: 3, name: 'model', label: language.MODEL, type: 'text' },
      { span: 3, name: 'warranty', label: language.WARRANTY, type: 'text' },
      { span: 3, name: 'invoice', label: language.INVOICE, type: 'text' },
      { span: 3, name: 'serial', label: language.SERIAL, type: 'text' }
    ];

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10, float: 'right' },
      disabled: true
    };

    return (
      <Card style={{ marginTop: 10 }}>
        <Stepper activeStep={0}>
          <Step>
            <StepLabel>{language.MAIN}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{language.DETAILS}</StepLabel>
          </Step>
          <Step>
            <StepLabel>Sub {language.DETAILS}</StepLabel>
          </Step>
        </Stepper>
        <div>
          <CommonForm
            values={values}
            language={language}
            fields={fields}
            onFormSubmit={this.onFormSubmit}
            button={button}
          />
        </div>
        <div className="nextBtn">
          <RaisedButton
            buttonStyle={{ background: 'var(--secundary)' }}
            label={language.NEXT}
            primary={true}
          />
        </div>
      </Card>
    );
  }
}
