import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { Card } from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import CommonForm from '../../../common/CommonForm2';
import CommonTable from '../../../common/CommonTable';

import Actions from '@data/Actions';

import TicketManagement from '../../TicketManagement';
import { translateFromPtBR } from '@common/languages/Dictionary';

const action = new Actions();

const TicketTasks = ({ APIEndpoints, language }) => {
  const [tasks, setTasks] = useState([]);
  const [countTasks, setCountTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState({});
  const [ticketObj, setTicketObj] = useState(null);
  const [sortBy, setsortBy] = useState('');
  const [sortByDesc, setsortByDesc] = useState(null);

  useEffect(() => {
    callPageSelectFilter(0,  {}, 75);
  }, [
    sortBy,
    sortByDesc,
  ]);

  const callPageSelectFilter = (page = 0, values, limit = 75) => {
    setCurrentPage(page);

    values.sortBy = sortBy;
    values.sortByDesc = sortByDesc;

    if (values && values.status && values.status !== "") {
      const taskStatusWaiting = ['Waiting', 'En espera', 'Aguardando'];  
      const taskStatusStarted = ['Started', 'Iniciado'];  
      const taskStatusInProgress = ['In progress', 'Em andamento', 'Progreso'];
      const taskStatusConcluded = ['Concluded', 'Concluído', 'Concluido'];
      const taskStatusFail = ['failure', 'Falla', 'Falha'];
      const taskStatusCanceled = ['Canceled', 'Cancelado'];      
  
      const waiting = taskStatusWaiting.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))
      const started = taskStatusStarted.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const progress = taskStatusInProgress.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const conclued = taskStatusConcluded.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const fail = taskStatusFail.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))            
      const canceled = taskStatusCanceled.some((option) => option.toLowerCase().includes(values.status.toLowerCase()))                  
  
      if (canceled) {
        values.status = 'CANCELADO'
      } else if (fail) {
        values.status = 'FALHA'
      } else if (waiting) {
        values.status = 'AGUARDANDO'
      } else if (started) { 
        values.status = 'INICIADO'
      } else if (progress) {
        values.status = 'ANDAMENTO'
      } else if (conclued) {
        values.status = 'CONCLUIDO'
      } else {
        values.status = ''
      }
    }

    action
      .execute('post', `${APIEndpoints.TASKS}/techtask?limit=${limit}&offset=${page * limit}`, '', values)
      .then((res) => {
        setTasks(res.data);
        setCountTasks(res.data[0] ? res.data[0].total_count : 0);
      });
  };

 const callSortItem = (sortBy, sortByDesc) => {
    setsortByDesc(sortBy)
    setsortBy(sortByDesc)
    setTimeout(() => {
      callPageSelectFilter(currentPage, {}, 75);
    }, 50);
  }

  const statusColorValues = {
    notStarted: 'rgb(240, 151, 31)',
    inProgress: 'rgb(52, 172, 160)',
    success: 'rgb(0 159 194)',
    fail: 'rgb(240, 31, 31)',
    canceled: 'rgb(240, 31, 31)',
  }

  const getColorStatus = (status) => {
    if (status.toUpperCase() == 'AGUARDANDO') {
      return 'rgb(240, 151, 31)';
    } else  if (status.toUpperCase() == 'INICIADO') {
      return 'rgb(240, 176, 2)';
    } else if (status.toUpperCase() == 'ANDAMENTO') {
      return 'rgb(52, 172, 160)';
    } else if (status.toUpperCase() == 'CONCLUIDO') {
      return 'rgb(0 159 194)';
    } else if (status.toUpperCase() == 'CANCELADO') {
      return 'rgb(240, 31, 31)';
    } else if (status.toUpperCase() === 'FALHA') {
      return 'rgb(240, 31, 31)';
    } else {
      return 'rgb(52, 172, 160)';
    }
  };

  const getTypeTicket = {
    1: 'INCIDENT',
    2: 'PROBLEM',
    4: 'SOLICITATION',
  };

  let data = tasks.map((obj) => ({
    id: obj.id_tasks,
    name: obj.name,
    description: (
      <p
        style={{ color: '#888080' }}
        dangerouslySetInnerHTML={{
          __html: obj.description,
        }}
      ></p>
    ),
    group: (
      <Tooltip
        html={<div style={{ color: 'white' }}>{obj.group}</div>}
        arrow={true}
        position="left"
        theme="dark"
        style={{ width: '100%' }}
        className="ellipsis"
      >
        {obj.group}
      </Tooltip>
    ),
    tech: obj.tech || '-',
    deadline: obj.deadline
      ? moment(obj.deadline).format('DD/MM/YYYY ')
      : '-',
    status: (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ color: getColorStatus(obj.status) }}
      >
        {translateFromPtBR(obj.status, language)}
      </div>
    ),
    ticket: (
      <div
        onClick={() => {
          setOpen(false);
          setTicketObj({ id_tickets: obj.fk_id_tickets, historico: true });
        }}
        className="d-flex align-items-center justify-content-around pointer"
      >
        <div>{obj.real_id}</div>
        <div style={{ width: 25, height: 25 }}>
          <Tooltip
            html={
              <span style={{ color: '#fff', fontSize: 15 }}>
                {language.TICKET_TYPES[getTypeTicket[obj.type_tickets]]}
              </span>
            }
            position="right"
            theme="dark"
          >
            {obj.type_tickets == 4 ? (
              <i
                className="fas fa-bullhorn icon my-1"
                style={{
                  color: 'var(--primary)',
                  fontSize: 13,
                }}
              />
            ) : obj.type_tickets == 2 ? (
              <ErrorIcon
                style={{
                  color: ' var(--danger)',
                  fontSize: 18,
                }}
              />
            ) : (
              <WarningIcon
                style={{
                  color: 'var(--warning)',
                  fontSize: 17,
                }}
              />
            )}
          </Tooltip>
        </div>
      </div>
    ),
  }));

  let col = [
    {
      key: 'id',
      label: language.ID,
      style: { width: 10, textAlign: 'left' },
    },
    {
      key: 'name',
      label: language.NAME,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'description',
      label: language.DESCRIPTION,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'group',
      label: language.TECH_GROUP,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'tech',
      label: language.TECH,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'deadline',
      label: language.TERM,
      style: { textAlign: 'left' },
      thConfig: true,
    },
    {
      key: 'status',
      label: language.STATUS,
      style: { width: 100, textAlign: 'left' },
    },
    {
      key: 'ticket',
      label: language.TICKET,
      style: { width: 100, textAlign: 'left' },
      noOrder: true,
      search: false,
    },
  ];
  return (
    <Card className="content-min px-3">
      {ticketObj && (
        <Dialog
          title={language.TICKET}
          open={!!ticketObj}
          onClose={() => setTicketObj(null)}
          maxWidth="xl"
        >
          <TicketManagement detail={true} id_ticket_detail={ticketObj} />
        </Dialog>
      )}
      <CommonTable
        title={''}
        language={language}
        columns={col}
        data={data}
        orderColumn
        searchColumn
        index="TaskList"
        showHeaderToolbar={false}
        countTotal={countTasks}
        isAutomaticPagination={false}
        beginWithNoFilter={true}
        noFilter
        paginationTop={false}
        loadSearch={true}
        currentPage={currentPage}
        onSortItem={(item, sortByStatus) =>
          callSortItem(item, sortByStatus)
        }
        searchColumnEvent={(v) => {
          callPageSelectFilter(0, v, 75);
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
      />
    </Card>
  );
};

export default TicketTasks;
