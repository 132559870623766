import { Fragment } from 'react';
import { Chip } from '@material-ui/core';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';

export default function ChangeCabList({ dataTable, language, handlePage }) {
  const col = [
    {
      key: 'weight',
      label: language.WEIGHT,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'type',
      label: language.TYPE,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'group_label',
      label: language.APPROVAL_GROUP,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 10, overflow: 'auto', textAlign: 'center' },
    },
  ];
  
  const data = dataTable.map((cab) => ({
    weight: cab.cc_weight,
    type: cab.type,
    group_label: cab.cc_group_label,
    action: (
      <Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <RaisedButton
                circleButton
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 12, width: 21, height: 21 }}
                className="mr-2"
                onClick={() => handlePage('edit', cab)}
              />
              <RaisedButton
                circleButton
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 13, width: 21, height: 21 }}
                onClick={() => handlePage('delete', cab)}
              />
            </div>
          }
        />
      </Fragment>
    ),
  }));

  return (
    <CommonTable
      data={data}
      columns={col}
      language={language}
      searchColumn
      orderColumn
      beforeBar={[
        <RaisedButton
          style={{ zoom: 0.8 }}
          zoom={0.8}
          className="mr-3"
          color="primary"
          key="02"
          circleButton
          icon="fas fa-plus"
          label={language.ADD}
          primary={true}
          onClick={() => {
            handlePage('create')
          }}
        />,
      ]}
    />
  );
}
