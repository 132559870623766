

export function validaPass(value, store) {

    let concat = '';
    let msn = '';

    [true].includes(store.general_parameters.PASS_LOWER) && (concat += '(?=.*[a-z])');
    [true].includes(store.general_parameters.PASS_SP) && (concat += '(?=.*[$*&@#])');
    [true].includes(store.general_parameters.PASS_UPPER) && (concat += '(?=.*[A-Z])');
    [true].includes(store.general_parameters.PASS_EIGHT) && (concat += '[0-9a-zA-Z$*&@#]{8,}');

    [false].includes(store.general_parameters.PASS_LOWER) && (msn += ' *deve conter ao menos uma letra minúscula');
    [true].includes(store.general_parameters.PASS_SP) && (msn += ' *deve conter ao menos uma letra maiúscula');
    [true].includes(store.general_parameters.PASS_UPPER) && (msn += '*deve conter ao menos um caractere especial');
    [true].includes(store.general_parameters.PASS_EIGHT) && (msn += '*deve conter ao menos 8 dos caracteres mencionados');


    var r = new RegExp(concat);

    if (!concat) {
        return true
    }

    if (r.test(value)) {
        return true;
    } else {
        store.toggleAlert(true, msn, 'error')

        return false;

    }
}

