import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/CommonTable';
import { Actions, Constants } from '../../data';
import RaisedButton from '../../common/RaisedButton';
import Dialog from '@common/Dialog';
import CompanyGroupingDetailForm from './CompanyGroupingDetailForm';
import { Chip, Container, Paper, TextField } from '@material-ui/core';
import { Col, Row } from 'react-grid-system';

export default function CompanyGroupingForm({
  companyData,
  params,
  language,
  onClose,
}) {
  const randomId = () => new Date().getTime().toString(20);

  const [data, setData] = useState([]);
  const [showModalForm, setShowModalForm] = useState();
  const [reload, setReload] = useState(randomId());
  
  useEffect(() => {
    const fetch = new Actions();
    fetch
      .execute(
        'get',
        `${Constants.APIEndpoints.COMPANY}/${companyData.id_company}/catalog_groupings`
      )
      .then((response) => {
        const result = response.data;

        const dataResult = result.map((item, index) => {
          return {
            id: item.id_company_catalog_grouping,
            grouping_name: item.grouping_name,
            task_count: item.task_count,
            actions: (
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="tw-flex tw-items-center tw-justify-items-center tw-gap-2">
                    <RaisedButton
                      circleButton
                      color="default"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() => setShowModalForm(item)}
                    />
                    <RaisedButton
                      circleButton
                      color="danger"
                      icon="fas fa-trash-alt"
                      label={language.REMOVE}
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() => handleDelete(item)}
                    />
                  </div>
                }
              />
            ),
          };
        });

        setData(dataResult);
      });

    return () => {};
  }, [, reload]);

  const handleClose = () => {
    onClose?.();
  };

  const handleDelete = (item) => {
    const fetch = new Actions();
    fetch
      .execute(
        'delete',
        `${Constants.APIEndpoints.COMPANY}/${companyData.id_company}/catalog_groupings/${item.id_company_catalog_grouping}`
      )
      .then((response) => {
        handleReload();
      });
  };

  const handleReload = () => setReload(randomId());

  const cols = [
    { key: 'id', label: '#', style: { width: 20 } },
    {
      key: 'grouping_name',
      label: language.NAME,
    },
    { key: 'task_count', label: language.TASKS },
    {
      key: 'actions',
      label: language.ACTIONS,
      style: { width: 100 },
    },
  ];

  return (
    <div>
      <Paper className="pb-2 mb-3">
        <Row>
          <Col md={6}>
            <TextField
              disabled={true}
              label={language.NAME}
              value={`${companyData.id_company} - ${companyData.name}`}
              InputLabelProps={{ shrink: true }}
              style={{ width: '100%' }}
            />
          </Col>
          <Col md={6}></Col>
        </Row>
      </Paper>

      <CommonTable
        language={language}
        columns={cols}
        data={data}
        title={`${language.ID} ${companyData.id_company}`}
        beforeBar={
          <RaisedButton
            primary
            fullWidth
            circleButton
            small={true}
            color="primary"
            className="mr-3"
            icon="fas fa-plus"
            // style={{ zoom: 0.8 }}
            // zoom={0.8}
            label={language.ADD}
            onClick={() => {
              setShowModalForm(true);
            }}
          />
        }
      />

      {!!showModalForm && (
        <Dialog
          open={true}
          maxWidth="xl"
          title={language.ADD_GROUPING}
          onClose={() => setShowModalForm()}
        >
          <CompanyGroupingDetailForm
            companyData={{
              ...showModalForm,
              id_company: companyData.id_company,
            }}
            language={language}
            params={params}
            onClose={(reload) => {
              setShowModalForm();
              reload && handleReload();
            }}
          />
        </Dialog>
      )}
    </div>
  );
}
