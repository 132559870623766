import '../../../public/css/pages.css';

import React, { Component,Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import DeviceTypeList from './DeviceTypeList';
import DeviceTypeForm from './DeviceTypeForm';
import Animate from '@common/Animate';

@observer
export default class DeviceTypeContainer extends CommonPage {
  constructor() {
    super();

    this.state = {
      value: '',
      page: 'list'
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.DEVICETYPE, 'devicetypes');
  }

  onFormSubmit(values) {
    let method = values.hasOwnProperty('id_device_type') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_device_type')
      ? this.APIEndpoints.DEVICETYPE + '/' + values.id_device_type
      : this.APIEndpoints.DEVICETYPE;
    this.action.execute(method, url, 'devicetypes', values, 'id_device_type').then(e=>{
      
      this.handlePage('list')
    });
    
   
  }

  onDelete() {
    let { values } = this.state;

    this.action.execute(
      'delete',
      this.APIEndpoints.DEVICETYPE + '/' + values.id_device_type,
      'devicetypes',
      null,
      'id_device_type'
    ).then(e=>this.handlePage('list'));
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    let { language, devicetype } = this.store;

    let {configurationProps={}} = this.props;
    return (
      <Fragment>
        <Animate>
        <DeviceTypeList
          redirect={this.redirect2}
          language={language}
          handlePage={this.handlePage}
          store={this.store}
          configurationProps={configurationProps}
        />
        </Animate>
        {page === 'form' ? (
          <DeviceTypeForm
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}

          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.name}
          />
        ) : null}
      </Fragment>
    );
  }
}
