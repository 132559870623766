import React, { Fragment, memo } from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import List from '@common/List';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';
import withLanguage from '@common/languages/withLanguage';
/* --------------format--------------------- 

let data = [
      {module:'GSD', name:'gestão de demandas', open:12, attendance:12, resolved:12, closed:12},
      {module:'GP', name:'gestão de projetos', open:12, attendance:12, resolved:12, closed:12},
      {module:'OS', name:'ordem de serviço', open:12, attendance:12, resolved:12, closed:12},
      {module:'GMUD', name:'gestão de mudança', open:12, attendance:12, resolved:12, closed:12},
   ]
   
*/
export default memo(withLanguage(({ page, data = [], language }) => {

   let legend = [
      language.TICKET_STATUS.OPEN,
      language.TICKET_STATUS.IN_PROGRESS,
      language.TICKET_STATUS.RESOLVED,
      language.TICKET_STATUS.CLOSED
   ];
   return (
      <Card id='TypePrecedence'>
         <AppBar title={language.TYPE_OF_SERVICE} subTitle={`(${language.PER_PANEL})`} />
         <div className='-mt-4'>
            <List
               hover={false}
               Itens={[{
                  style: { padding: '8px 16px' },
                  grid: [
                     {
                        col: 6,
                        content: null
                     },
                     {
                        col: 1.5,
                        content: <span className='label'>Total</span>,
                     },
                     {
                        col: 1.5,
                        content: (
                           <div style={{ position: 'relative', top: 24 }}>
                              <span className='legend-1 legend'>{legend[0]}</span>
                              <div className='border' style={{ '--color': 'var(--primary)' }} />
                           </div>
                        ),
                     },
                     {
                        col: 1.5,
                        content: (
                           <div style={{ position: 'relative', top: 24 }}>
                              <span className='legend-2 legend'>{legend[1]}</span>
                              <div className='border' style={{ '--color': 'var(--warning)' }} />
                           </div>
                        ),
                     },
                     {
                        col: 1.5,
                        content: (
                           <div style={{ position: 'relative', top: 24 }}>
                              <span className='legend-3 legend'>{legend[2]}</span>
                              <div className='border' style={{ '--color': 'var(--success)' }} />
                           </div>
                        ),
                     },
                  ]
               }].concat(data.map((e, i) => ({
                  className: 'grid',
                  style: { padding: '8px 16px' },
                  grid: [
                     {
                        col: 6,
                        className: 'grid-col',
                        content: (
                           <Fragment>
                              <span className='bold-title _title'>({e.module})</span>
                              <span className='_title'> {e.name}</span>
                           </Fragment>
                        )
                     },
                     {
                        col: 1.5,
                        className: 'grid-col',
                        content: (
                           (parseInt(e.open) || 0) +
                           (parseInt(e.attendance) || 0) +
                           (parseInt(e.resolved) || 0)
                        ),
                     },
                     {
                        col: 1.5,
                        title: e.openLabel,
                        className: 'grid-col',
                        content: e.open,
                     },
                     {
                        col: 1.5,
                        title: e.attendanceLabel,
                        className: 'grid-col',
                        content: e.attendance,
                     },
                     {
                        col: 1.5,
                        title: e.resolvedLabel,
                        className: 'grid-col',
                        content: e.resolved,
                     },
                  ]
               })))}
            />
         </div>
      </Card>
   )
}, function (prev, next) {
   let omit = obj => _.omitBy(obj, val => typeof val == 'function');
   return _.isEqual(omit(prev), omit(next));
}))