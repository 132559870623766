import React, { useState, useEffect } from 'react';
 
import Progress from '@common/Progress';
 
import { Tooltip } from 'react-tippy';
 
// --------------------------------icons----------------------------------
 

export default function({
   project
}) {
  
  let [times, setTimes] = useState([]);
  const calculateTimeInSeconds=(totalSeconds)=> {
    let days, hours, minutes, seconds = 0;
    hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    minutes = Math.floor(totalSeconds / 60);
    seconds = totalSeconds % 60;
  
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0')  
  }
  
  const toSecond = (hms) => {
    try {
      //hms = '02:04:33';   // your input string
      var a = hms.split(':'); // split it at the colons
  
      // minutes are worth 60 seconds. Hours are worth 60 minutes.
      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      return seconds;
    } catch (r) {
      return 0;
    }
  }
 
  let [sla, setSla] = useState(0);
  let [h, setH] = useState("00:00");
  let [he, setHe] = useState("00:00");
  let [hc, setHc] = useState("00:00");

  let [hview, SetHview] = useState("00:00");

  let [hColor, setColor] = useState("00:00");
  let [hSaldo, setSaldo] = useState("00:00");

 
  useEffect(() => {
  const interval = setTimeout(() => {
   
        setSla(project.percent||0)
        setHe(project.hour_excedida||"00:00")
        setH(project.horas_planejadas||"00:00")
        setHc(project.hour_con||"00:00")
        SetHview(project.hour_consumida||"00:00")
        setColor(project.color)
      
      let planejadas=  toSecond(project.horas_planejadas+':00'||"00:00:00")
      let consumida=  toSecond(project.hour_consumida+':00'||"00:00:00")
    if(consumida >planejadas ){
      planejadas = consumida
    }
      setSaldo(calculateTimeInSeconds(planejadas-consumida))

        /**  setSla(project.percent||"00:00")
        setHe(project.hour_excedida||"00:00")
        setH(project.horas_planejadas||"00:00")
        setHc(project.hour_con */
  
 }, 3000);
 return () => clearInterval(interval);
}, [project]);   //resolucao bug filtro

  

return   <div>
<div className="d-flex align-items-center justify-content-between">
<Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
              <strong>Total de horas Planejadas</strong>
            </div>
          }
          position="right"
          theme="dark"
        > <span  className={'sp'} >
          <i class="far fa-clock"></i> {h}</span > </Tooltip> 
          <Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
         <strong>{`Horas Consumidas` }</strong>
            </div>
          }
          position="right"
          theme="dark"
        > 
           <span className={'spr'} style={{color:'var(--primary)'}} >
             <i class="far fa-clock"></i> {hview}</span>
           </Tooltip>

           <Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
         <strong>{`Saldo` }</strong>
            </div>
          }
          position="right"
          theme="dark"
        > 
           <span className={'spr'} style={{color:'var(--primary)'}} >
             <i class="far fa-clock"></i> {hSaldo}</span>
           </Tooltip>





 <Tooltip
          html={
            <div style={{ color: '#fff', fontSize: 15 }}>
              <strong>{ `Total de horas Excedentes`}</strong>
            </div>
          }
          position="right"
          theme="dark"
        > 
           <span className={'spr'} style={{color:'var(--danger)'}} >
             <i class="far fa-clock"></i> {he}</span>
           </Tooltip> {/** SetHview(h.data.hourview)
        setColor(h.data.color) */}
 </div>
<div className="d-flex align-items-center justify-content-between">
<Progress
  hiddenText
  className="w-100"
  background="var(--danger)"
  current={sla}
/>
<span className="ml-2 percent-text">
  { sla >0 ? sla:0 }%
</span>
</div>
</div>
}