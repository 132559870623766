import React, { Fragment, useState, useEffect } from 'react';
import Constants from '@data/Constants';
import Dialog from '@common/Dialog';
import './index.css';
import { jsonFormatKanban } from '../../jsonFormat';
import Column from './Column';
import Store from '@data/Store';
import { observer } from 'mobx-react-lite';
import TagDialog from './TagDialog';

import Form from '../Form';
import DownloadFiles from '../../../../util/DownloadFiles';

export default observer(
  ({
    open,
    onClose,
    id,
    users,
    companies,
    action,
    handleModal,
    handleEdit,
    handleDelete,
  }) => {
    let [tagOpen, setTagOpen] = useState(false);
    let [formOpen, setFormOpen] = useState(false);
    let [formValue, setFormValue] = useState({});

    let { PROJECT } = Store.theme;
    let { column } = PROJECT;
    let { APIEndpoints } = Constants;
    const { language } = Store;

    let data = Store.projects.find((e) => e.id_project == id);

    let attributesType = [
      'BACKLOG',
      'TODO',
      'DOING',
      'STOPPED',
      'VALIDATION',
      'DONE',
    ];
    let dataAc = attributesType.flatMap((e) => [...(data.utils[e] || [])]);
    let dataKanban = dataAc.map((e) => jsonFormatKanban(e, users));
    let onSubmit = (v, isDuplicate = false) => {
      if (v.files && v.files.length) {
        v.files = v.files.map((e) => ({
          id_project_activity: v.id_project_activity,
          file: e.base64,
          name: e.file.name,
          size: e.file.size,
          type: e.file.type,
        }));
      }
      v.template = true;
      action
        .execute(
          'put',
          `${Constants.APIRoot}/api/project/activity`,
          'projects',
          v,
          'id_project',
          { btn_loading: 'load_activity' }
        )
        .then((e) => {
          setFormOpen(false);
          setFormValue({});
        });
    };

    function onDownloadFile(id_project_activity, name) {
      DownloadFiles(APIEndpoints.REQUESTS + '/downloadgp/' + id_project_activity + '&' + name, name);
    }

    let onDelete = (_id) => {
      action.execute(
        'put',
        `${APIEndpoints.PROJECT}/deleteactivity/${id}/${_id}?template=true`,
        'projects',
        null,
        'id_project'
      );
    };

    return (
      <Fragment>
        {formOpen && (
          <Form
            open={true}
            onClose={() => {
              setFormOpen(false);
              setFormValue({});
            }}
            action={action}
            APIEndpoints={APIEndpoints}
            values={formValue}
            options={[]}
            project={data}
            idProject={data.id_project}
            onSubmit={onSubmit}
            onDownloadFile={onDownloadFile}
            ondeletefile={() => null}
          />
        )}
        <TagDialog
          open={tagOpen}
          onClose={() => setTagOpen(false)}
          tags={data.tagpj || []}
          action={action}
          language
        />
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="xs"
          bar={false}
          PaperProps={{
            id: 'ActivitiesDialogProjectTemplate',
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          contentProps={{
            style: {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          <Column
            onClose={onClose}
            title={data.name}
            handleModal={handleModal}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            data={dataKanban}
            onEdit={(id) => {
              setFormOpen(true);
              setFormValue(dataAc.find((e) => e.id_project_activity == id));
            }}
            onDelete={onDelete}
            onView={() => null}
            project={data}
            columnTheme={column}
            setTagOpen={setTagOpen}
            setFormOpen={setFormOpen}
            users={users}
            companies={companies}
          />
        </Dialog>
      </Fragment>
    );
  }
);
