import RaisedButton from '../../../common/RaisedButton';
import React, { Fragment, useState, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import CommonTable from '../../../common/CommonTable';
import Constants from '../../../data/Constants';
import Button from '../../../common/Button';
import Store from '@data/Store';

const SubjectList = ({
  handlePage,
  language,
  setPage,
  topicSelected,
  editSubject,
  listSubjects,
  currentPage,
  callPageSelectFilter,
}) => {
  const col = [
    {
      key: 'name',
      label: Store.language.SUBJECT,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'theme',
      label: Store.language.THEME,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'topic',
      label: Store.language.TOPIC,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'actions',
      label: Store.language.ACTIONS,
      style: { textAlign: 'left' },
      thConfig: false,
    },
  ];
  const data = listSubjects.map((obj) => ({
    name: obj.name,
    theme: obj.theme,
    topic: obj.topic,
    actions: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
                icon="fas fa-edit"
                label={Store.language.EDIT}
                color="default"
                style={{ fontSize: 12 }}
                size={0.7}
                className="mr-2"
                onClick={() => handlePage('form', obj)}
              />

              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={Store.language.DELETE}
                color="danger"
                style={{ fontSize: 13 }}
                size={0.7}
                onClick={() => handlePage('remove_subject', obj)}
              />

              <Button
                variant="circle"
                color="default"
                icon="fas fa-plus"
                label={'Adicionar Formulário de Abertura'}
                style={{ fontSize: 12 }}
                className="ml-2"
                size={0.7}
                onClick={() => handlePage('template', obj)}
              />

              <Button
                variant="circle"
                color="default"
                icon="fas fa-plus"
                label={'Adicionar Formulário de Encerramento'}
                style={{ fontSize: 12 }}
                className="ml-2"
                size={0.7}
                onClick={() => handlePage('template_close', obj)}
              />

              <Button
                variant="circle"
                color="default"
                icon="fas fa-plus"
                label={'Adicionar Tarefa'}
                style={{ fontSize: 12 }}
                className="ml-2"
                size={0.7}
                onClick={() => handlePage('open_task_list', obj)}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  return (
    <Fragment>
      <CommonTable
        title={''}
        language={language}
        currentPage={currentPage}
        columns={col}
        onEdit={() => editSubject(topicSelected.id_sac_topic)}
        index="ExtensionList"
        data={data}
        searchColumn
        orderColumn
        width="100%"
        paginationTop={false}
        beforeBar={
          <RaisedButton
            circleButton
            icon="fas fa-plus"
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            primary={true}
            label={language.ADD_SUBJECT}
            onClick={() => handlePage('form')}
          />
        }
      />
    </Fragment>
  );
};

export default SubjectList;
