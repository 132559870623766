import React, {Fragment, memo} from 'react';
/*------------------------------common-------------------------------------*/
import CommonForm from '@common/CommonForm2';
/*------------------------------material-ui-------------------------------------*/

import Store from '@data/Store';

const { language } = Store;

export default memo(({fk_id_project_activity, fk_id_project, onSubmit, project})=>{
let values = {}
values.fk_id_project_activity = fk_id_project_activity
values.fk_id_project = fk_id_project

return(
	typeof(onSubmit) == "function" && 
		<div className='Block_5'>
			
			<span className='label-items mb-4 d-block'>{language.COMMENTARY}</span>
			<CommonForm
			clean
			values = {values}
				fields={[
					{
						type:'textEditor',
						name:'description',
						placeholder: language.ADD_A_COMMENT,
						className:'activityFields'
					}
				]}
            onSubmit={project.pause_project == false ? onSubmit : ''}
            loading='comments'
				button={{
					style:{
						marginTop:-40
					}
				}}
			/>
		</div>
	
	)
})