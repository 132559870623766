import { InputAdornment, TextField } from '@material-ui/core';
import * as SensritUtilsPk from '@sensrit/utils';
import React, { useState } from 'react';
import SimplesAlert from '../../common/SimplesAlert';
import { Constants, Store } from '../../data';
import GenericActions from '../../data/Actions';
import PasswordRequirements from '../user/components/PasswordRequirements';
import { RecoveryPasswordHeader, StepButtons } from './LoginRecoveryPassword';

export const LoginChangePassword = ({
  recoveryToken,
  redirectToLoginPage = true,
  overrideAlertSuccessVariant,
}) => {
  const { language } = Store;
  const alertDefault = { opened: false, message: '', variant: 'success' };
  const [alert, setAlert] = useState(alertDefault);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  const handleOpenAlert = (message, variant = 'success') => {
    setAlert({ opened: true, message: message, variant: variant });
  };

  const handleSubmit = async () => {
    const payload = recoveryToken
      ? { new_pass: newPassword, token: recoveryToken }
      : {
          oldpassword: oldPassword,
          password: newPassword,
          confirmation: confirmPassword,
        };

    const url = recoveryToken
      ? Constants.APIEndpoints.REDEFINE
      : Constants.APIEndpoints.PASSWORD;

    const fetch = new GenericActions();
    fetch
      .execute('post', url, null, payload)
      .then((result) => {
        handleOpenAlert(
          language.CHANGE_PASSWORD_SUCCESS,
          overrideAlertSuccessVariant ?? 'success'
        );
        if (redirectToLoginPage) {
          window.location.href = window.location.origin;
        } else {
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
        }
      })
      .catch((error) => {
        handleOpenAlert(language.ERROR_CODE.E500, 'error');
      });
  };
  return (
    <div>
      <div className="mb-4">
        <RecoveryPasswordHeader />
      </div>
      {!recoveryToken && (
        <TextField
          autoFocus
          type="password"
          fullWidth={true}
          className="mb-4"
          variant="outlined"
          value={oldPassword}
          label={language.OLD_PASSWORD}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setOldPassword(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <i className="fas fa-key tw-text-gray-300 tw-text-xs" />
              </InputAdornment>
            ),
          }}
          error={newPassword && !oldPassword}
        />
      )}

      <TextField
        autoFocus={!!recoveryToken}
        type="password"
        fullWidth={true}
        className="mb-3"
        variant="outlined"
        value={newPassword}
        label={language.NEW_PASSWORD}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => setNewPassword(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fas fa-key tw-text-gray-300 tw-text-xs" />
            </InputAdornment>
          ),
        }}
        error={
          newPassword &&
          !SensritUtilsPk.checkIfStringHasValidPasswordFn(newPassword)
        }
      />
      <PasswordRequirements
        language={language}
        style={{ marginBottom: '2rem', zoom: 0.9 }}
        password={newPassword}
      />
      <TextField
        type="password"
        fullWidth={true}
        className="mb-4"
        variant="outlined"
        value={confirmPassword}
        label={language.CONFIRM_PASSWORD}
        onChange={(event) => setConfirmPassword(event.target.value)}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fas fa-key tw-text-gray-300 tw-text-xs" />
            </InputAdornment>
          ),
        }}
        error={confirmPassword && newPassword !== confirmPassword}
      />

      <StepButtons
        onNext={handleSubmit}
        disableNextButton={
          !(
            SensritUtilsPk.checkIfStringHasValidPasswordFn(newPassword) &&
            newPassword === confirmPassword
          )
        }
        showPrev={false}
      />

      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
};
