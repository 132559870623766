import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm2';
import Dialog from '../../common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import { Card, Chip } from '@material-ui/core';
import CommonDelete from '@common/CommonDelete';
import moment from 'moment';

const Blackout = ({ action, APIEndpoints, language, device }) => {
  console.log('device', device);
  const [data, setData] = useState([]);
  const [page, setPage] = useState('list');
  const [obj, setObj] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const handlePage = (page, values) => {
    setPage(page);
    setObj(values);
  };

  const getData = () => {
    action
      .execute(
        'get',
        APIEndpoints.DEVICE + '/getBlackouts/' + device.id_device,
        ''
      )
      .then((res) => {
        const dataTable = res.data.map((d) => ({
          id: d.id_device_blackout,
          name: d.name,
          dt_start: moment(d.dt_start).format('DD/MM/YYYY'),
          dt_end: moment(d.dt_end).format('DD/MM/YYYY'),
          actions : (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <RaisedButton
                      circleButton
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      className="mr-2"
                      onClick={() => handlePage('edit', d)}
                    />
                    <RaisedButton
                      circleButton
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      onClick={() => handlePage('remove', d)}
                    />
                  </div>
                }
              />
            </React.Fragment>
          )
        }));
        setData(dataTable);
        console.log('res.data', res.data);
      });
  };

  const createBlackout = (values) => {
    values.fk_id_device = device.id_device;
    const method = values.id_device_blackout ? 'put' : 'post'
    action
      .execute(method, APIEndpoints.DEVICE + '/createBlackout', '', values)
      .then((res) => {
        getData();
        handlePage('list');
      });
  };

  const onDelete = () => {
    const values = {
        remove : true,
        id_device_blackout : obj.id_device_blackout
    }
    action
      .execute('put', APIEndpoints.DEVICE + '/createBlackout', '', values)
      .then((res) => {
        getData();
        handlePage('list');
      });
    
  }
  const columns = [
    {
      key: 'id',
      label: language.ID,
    },
    {
      key: 'name',
      label: language.NAME,
    },
    {
      key: 'dt_start',
      label: language.START_DATE,
    },
    {
      key: 'dt_end',
      label: language.END_DATE,
    },
    {
      key: 'actions',
      label: language.ACTIONS,
    },
  ];
  return (
    <div>
      <CommonTable
        columns={columns}
        data={data}
        beforeBar={[
          <RaisedButton
            circleButton
            icon="fas fa-plus"
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            label={language.ADD_NEW_BLACKOUT}
            primary={true}
            onClick={() => handlePage('new')}
          />,
        ]}
      />

      {(page == 'edit' || page == 'new') && (
        <Dialog
          open={true}
          onClose={() => handlePage('list')}
          title={
            obj?.id_device_blackout ? language.ADD_BLACKOUT : language.EDIT_BLACKOUT
          }
          maxWidth="md"
        >
          <CommonForm
            onSubmit={createBlackout}
            values = {obj}
            fields={[
              {
                col: 12,
                name: 'name',
                label: language.NAME,
                type: 'text',
                required: true,
              },
              {
                col: 6,
                type: 'date',
                label: language.START_DATE,
                name: 'dt_start',
                required: true,
              },
              {
                col: 6,
                type: 'date',
                label: language.END_DATE,
                name: 'dt_end',
                required: true,
              },
            ]}
          />
        </Dialog>
      )}

{page == 'remove' && (
       <CommonDelete
       language={language}
       closeModal={() => handlePage('list')}
       onDelete={onDelete}
       message={obj.name}
     />
        
    )}
    </div>
  );
};

export default Blackout;
