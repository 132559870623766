import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

// ------------------------------------------------------------

import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import Store from '@data/Store';

// ------------------------------------------------------------

export default observer(
  ({
    setAdvancedSearchModalOpen,
    advancedSearchModalOpen,
    onSubmitAdvancedSearch,
    action,
    APIEndpoints,
  }) => {
    const [search, setSearch] = useState(0);
    const { contracts, departments, language } = Store;

    return (
      <Dialog
        open={advancedSearchModalOpen}
        onClose={() => setAdvancedSearchModalOpen(false)}
        title={language.ADVANCED_SEARCH}
        maxWidth="xs"
        monitor_scroll
      >
        <CommonForm
          onChangeField={(f, v) => {
            if (f.name == 'type') {
              setSearch(v);
              if (v == 'fk_id_contract') {
                action.execute('get', APIEndpoints.CONTRACT, 'contracts');
              }
              if (v == 'fk_id_department') {
                action.execute('get', APIEndpoints.DEPARTMENT, 'departments');
              }
            }
          }}
          fields={[
            {
              type: 'select',
              name: 'type',
              label: 'Tipo de campo',
              options: [
                { value: 'description', label: language.DESCRIPTION },
                { value: 'fk_id_contract', label: language._CONTRACT },
                { value: 'fk_id_department', label: language.DEPARTMENT },
                { value: 'form', label: language.FORM_TEMPLATE },
              ],
            },
            {
              type: 'text',
              name: 'search',
              label: language.SEARCH,
              visible: ['description', 'form'].includes(search),
            },
            {
              type: 'select',
              name: 'search',
              label: language.SEARCH,
              visible: search == 'fk_id_contract',
              options: contracts.map((e) => ({
                value: e.id_contract,
                label: e.name,
              })),
            },
            {
              type: 'select',
              name: 'search',
              label: language.SEARCH,
              visible: search == 'fk_id_department',
              options: departments.map((e) => ({
                value: e.id_department,
                label: e.name,
              })),
            },
          ]}
          button={{
            label: language.SEARCH,
          }}
          loading="search_advanced_search"
          onSubmit={onSubmitAdvancedSearch}
        />
      </Dialog>
    );
  }
);
