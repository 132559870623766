import '../../../public/css/monitor.css';

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import io from 'socket.io-client';

import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import MonitorPage from './MonitorPage';
import Summary from './Summary';
import DeviceConf from './DeviceConf';
import ServicesList from './ServicesList';
import DashboardMonitor from './DashboardMonitor';
import DeviceGroupDetail from './DeviceGroupDetail';
import GroupModal from './GroupModal';
import Animate from '@common/Animate';
import DeviceConfOid from './DeviceConfOid';

// ---------------------------------MonitorHome-----------------------------------

import MonitorHomeAll from './gr/MonitorHomeAll';
import MonitorHome from './gr/MonitorHome';

let devicesArray=[];


@observer
export default class MonitorContainer extends CommonPage {
  constructor() {
    super();
   // query: 'user=1&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOnsiaWQiOjEsInJvbGUiOiJjbGllbnQiLCJjb21wYW55Ijp7ImlkIjoxfX0sImRhdGUiOjE1NTg2NDgyODgsImV4cCI6MTU1ODY2Njc5OTk5OX0.TVr4W8kgMO79HUy0CaOnq656GpRdYBOh5RMByDZ-Rks'})
 
    this.state = {
        ioLinks: io.connect(Constants.AGENT_LINKS, { path: '/icmp',
        query:'user='+this.store.getUserLoged().id+'&token='+ this.store.getToken()  
        }),
        ioServers: io.connect(Constants.AGENT_SERVERS, { path: '/server',
       query:'user='+this.store.getUserLoged().id+'&token='+ this.store.getToken()  }),
        
      // ioAlertsLinks: io.connect(Constants.ALERT_LINKS, { path: '/icmp/alert' }),
     //   ioAlertsServers: io.connect(Constants.ALERT_SERVERS, { path: '/server/alert' }),
      page: 'dash',
      company: '',
      groupDevices: [],
      itens:'',
      id_group:0,
      id_device:0,
      id_brands:0
    };
    this.handlePage = this.handlePage.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.onConfSubmit = this.onConfSubmit.bind(this);
    this.onGroupSubmit = this.onGroupSubmit.bind(this);
    this.onRestartAgent = this.onRestartAgent.bind(this);
    this.onDettachDevice = this.onDettachDevice.bind(this);
    this.setService = this.setService.bind(this);
    this.serviceCmd = this.serviceCmd.bind(this);
    this.serviceDiscovery = this.serviceDiscovery.bind(this);
    this.upGroup = this.upGroup.bind(this);
    this.openUpdateHandle = this.openUpdateHandle.bind(this);
    this.onUpdateItens = this.onUpdateItens.bind(this);
  }

  componentWillMount() {
    this.store.loading = false;
    this.action.execute('get', this.APIEndpoints.MONITOR, 'monitor');
    this.action.execute('get',
      this.APIEndpoints.MONITOR + '/groups',
      'monitorGroups'
    );
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id },null, null, null
    )
//app.get("/api/monitordevicecon", (req, res, next) =>
this.action.execute('get', this.APIEndpoints.MONITOR+'/devicecon', 'monitordevicecompany');

    this.action.execute(
      'get',
      this.APIEndpoints.MONITOR + '/companies',
      'companies'
    );
    this.action.execute('get', this.APIEndpoints.ITEM, 'items');
  }

  componentDidMount() {
    let { ioAlertsLinks, ioAlertsServers } = this.state;

    // ioAlertsLinks.on('alerts', (link) => this.store.pushAlert(link));
    // ioAlertsServers.on('alerts', (server) => this.store.pushAlert(server, true));
  } 

  componentWillUnmount() {
    let { ioAlertsLinks, ioAlertsServers } = this.state;

    // ioAlertsLinks.removeAllListeners('alerts');
    // ioAlertsServers.removeAllListeners('alerts');
  }

  onConfSubmit(conf, confTrigger, users, edit) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/device',
      'monitor',
      { conf: conf, confTrigger: confTrigger, users: users, edit: edit }
    ).then(c=>{
      this.redirect2('/dash/monitor')
    })
  }

  onGroupSubmit(values) {
    this.action.execute(
      'post',
      `${this.APIEndpoints.MONITOR}/group`,
      'monitorGroups',
      values
    ).then(res => {
      this.redirect('/dash/gi')
    })
  }

  onRestartAgent(id_device) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/device/' + id_device + '/restart',
      '',
      {}
    );
  }

  onDettachDevice(id_device) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/device/' + id_device,
      'monitor',
      {}
    );
  }

  setService(id, data) {
    this.action.execute(
      'put',
      this.APIEndpoints.MONITOR + '/services/' + id,
      'services',
      data,
      'id_rel_device_service'
    );
  }
  onUpdateItens(values) {
   
    this.upGroup(this.state.id_group, 'up', values);
    this.setState({page : 'monitor'})
  }

  openUpdateHandle(valor){
   
    let values = { page: 'deviceModal', itens: valor , id_group : valor.id_group };
    this.setState(values);
  }

  handlePage(page, device) {
    this.store.loading = false;
    
  
    

    if(device&& page=='deviceConf' && device.fk_id_brands){
      let  brand= {id_brands:device.fk_id_brands , id_device:device.id_device  }
      this.action.execute(
        'post',
        this.APIEndpoints.SNMP + '/getbrandsid/',
        'oids',
        brand
      );
      this.setState({page:'oid',id_device:device.id_device ,
      id_brands:device.fk_id_brands 
    })
   

      //this.redirect2('/dash/snmp?id_brands='+device.fk_id_brands+'&id_device='+device.id_device)
    }else{
    let values = { page: page, device: device };
    if (page === 'services')  ;
    else if (page === 'deviceConf' && device)  
    if (page === 'groupDetail') values['company'] = device;
    if (page === 'monitor' && device) values['groupDevices'] = device;
    this.setState(values);
    }
  }

  serviceCmd(cmd, service) {
    this.action.execute('post', this.APIEndpoints.MONITOR + '/services/', '', {
      cmd: cmd,
      service: service
    });
  }

  serviceDiscovery(id_device) {
    this.action.execute(
      'post',
      this.APIEndpoints.MONITOR + '/services/discovery',
      '',
      { id_device: id_device }
    );
  }

  getUsers(device) {
    this.action.execute(
      'get',
      this.APIEndpoints.MONITOR + '/' + device + '/users',
      'users'
    );
  }

  upGroup(groupId, action, data) {
    if (action === 'del')
      this.action.execute(
        'delete',
        `${this.APIEndpoints.MONITOR}/group/${groupId}`,
        'monitorGroups',
        null
      );
    if (action === 'up')
      this.action.execute(
        'put',
        `${this.APIEndpoints.MONITOR}/group/${groupId}`,
        'monitorGroups',
        data
      );
    this.setState({ page: 'dash', company: '' });
  }
  
  getUniqueGroupItens(groups){
    let devices=[];
      let itens=[];
      groups.map(h=>{
      
       h.devices.map(y=>{
       devicesArray.push({id_device:y.id_device}); 
          devices.push(y);
         })
     
   itens.push({
     devices: devices,
     id_group: h.id_group,
name_group: h.name_group,
user_cad: 1} )
     
       })
 return itens;
  } 
   
  render() {
    let {
      language,
      danger,
      devices,
      monitor,
      services,
      companies,
      monitorGroups,
      warning,
      monitordevicecompany
    } = this.store;
    let {
      page,
      device,
      company,
      groupDevices,
      ioAlertsLinks,
      ioAlertsServers
    } = this.state;
 
    let devicesMonitor = groupDevices.map(gd => {
      let getDevice = monitor.find(m => m.id_device === gd.id_device);
      if (getDevice) gd = getDevice;
       
      return gd
    });
     
 
  let monitorGroups_=[];
 
  if(this.store.getUserLoged().role =='client'){
    monitorGroups_=  monitorGroups .map(g=>{
     return  { company:g.company,
        groups:   g.groups 
        ,   id_company:g.id_company,
 
       user_cad: this.store.getUserLoged().id
     }
    
     })
  }else{
  monitorGroups_=  monitorGroups.filter(g=>parseInt(g.user_cad)===
   this.store.getUserLoged().id ).map(g=>{
    return  { company:g.company,
       groups:   g.groups.filter(g=>parseInt(g.user_cad) === 
       this.store.getUserLoged().id )  
       ,   id_company:g.id_company,

      user_cad: g.user_cad
    }
   
    })
  }
  
  
  /*if(this.store.getUserLoged().role =='client'){
    monitorGroups.map(g=>{

 
      if(monitorGroups_.filter(c=> c.ng=== g.groups[0].name_group).length==0)
       
      monitorGroups_.push({ company:g.company,
               groups:   g.groups  
              ,id_company:g.id_company,
               ng: g.groups[0].name_group
              })})
   
  }else{
    
  }*/
  
 
    
  
       
    return (
      <CommonPage
        title={
          <div
            className="logoWhite"
            onClick={() => this.redirect('/dash')}
          />
        }
        showMenuIconButton={true}
        iconAlert={true}
        loading={false}
      >
         {(page === 'dash'||page === 'dashall') || page === 'monitor'  ? (
          <Animate>
         <MonitorHome
         all={page === 'dashall'}
         ioAlertsLinks={ioAlertsLinks}
         openUpdateHandle={this.openUpdateHandle}
         ioLinks={this.state.ioLinks}
         onDettachDevice={this.onDettachDevice}
         ioAlertsServers={ioAlertsServers}
         ioServers={this.state.ioServers}
         devices={devices}
         monitor={monitor}
         monitordevicecompany={monitordevicecompany}
         DashboardMonitor={DashboardMonitor}
         companies={companies}
         language={language}
         upGroup={this.upGroup}
         {...this.props}
         onRestartAgent={this.onRestartAgent}
         handlePage={this.handlePage}
         monitorGroups={monitorGroups_}
         onGroupSubmit={this.onGroupSubmit}
         danger={danger}
         warning={warning}
    
      />
      </Animate>



        ): page === 'oid' ? (
          <DeviceConfOid
            language={language}
            oids={this.store.oids}

            id_device={this.state.id_device}
            handlePage={this.handlePage}
           id_brands={this.state.id_brands}
            
          />
        ) 
        :page === 'groupDetail' ? (
          <DeviceGroupDetail
            ioAlertsLinks={ioAlertsLinks}
            ioAlertsServers={ioAlertsServers}
            language={language}
            handlePage={this.handlePage}
            companies={companies}
            company={company}
            upGroup={this.upGroup}
            danger={danger}
            warning={warning}
          />
        ) : page === 'monitor' ? (
          <MonitorPage
            {...this.state}
            action={this.action}
             
            ioAlertsLinks={ioAlertsLinks}
             
            ioLinks={this.state.ioLinks}
            ioServers={this.state.ioServers}
            APIEndpoints={this.APIEndpoints}
            key="monitor"
             
          />
        ) : page === 'deviceConf' ? (
          <DeviceConf
          monitordevicecompany={this.store.monitordevicecompany}
            key="deviceConf"
            language={language}
            handlePage={this.handlePage}
            onConfSubmit={this.onConfSubmit}
            devices={devicesMonitor}
            editConf={device}
            store={this.store}
            getUsers={this.getUsers}
          
          />
        ) 
        : page === 'deviceModal' ? (
          <GroupModal
          monitordevicecompany={this.store.monitordevicecompany}
          closeModal={()=>this.handlePage('monitor')}
          companies={this.state.itens.companies}
          comp={this.state.itens.comp}
          company={this.state.itens.company}
          group={this.state.itens.group}
          devices={this.state.itens.devices}
          user={this.store.getUserLoged()}
          open={this.state.itens.open}
          language={language}
          onFormSubmit={this.onUpdateItens}
          usercon_company={this.store.usercon_company}
        />
/**onClose:this.onClose,companies:companies,
			comp:comp, company:company,group:group , devices:gd, open:up, onUpdate:this.onUpdate */
          
        )
        
        : page === 'services' ? (
          <ServicesList
            ioServers={this.state.ioServers}
            handlePage={this.handlePage}
            language={language}
            services={services}
            {...device}
            setService={this.setService}
            serviceCmd={this.serviceCmd}
            serviceDiscovery={this.serviceDiscovery}
          />
        ) : (
          <Summary
            key="summary"
            language={language}
            redirect={this.redirect2}
            handlePage={this.handlePage}
            {...device}
          />
        )}
        
      </CommonPage>
    );
  }
}
