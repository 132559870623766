import React, { Component } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  LabelList,
  ScatterChart,
  Scatter,
  ZAxis,
  YAxis
} from 'recharts'

export default class AndamentoDoProjeto extends Component {
  render() {
    const { data } = this.props
    const range = [1000, 3000]

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ScatterChart margin={{ top: 120 }}>
          <XAxis
            type="category"
            dataKey="progress"
            interval={0}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis type="number" dataKey="index" width={0} />
          <ZAxis type="number" dataKey="value" range={range} />
          <Scatter data={data} fill="#8884d8">
            <LabelList dataKey="value" />
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    )
  }
}
