import React, { useState } from 'react';
import SimplesAlert from '../../common/SimplesAlert';
import PasswordQuestionForm from './PasswordQuestionForm';
import PasswordQuestionList from './PasswordQuestionList';

const PasswordQuestionContainer = () => {
  const alertDefault = { opened: false, message: '', variant: 'success' };
  const [alert, setAlert] = useState(alertDefault);

  const [showPageType, setShowPageType] = useState({ type: 'list' });

  const handleFormToggle = (typeData) => {
    setShowPageType(typeData);
  };

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  const handleOpenAlert = (message, variant = '') => {
    setAlert({ opened: true, message: message, variant: variant });
  };

  return (
    <div>
      <PasswordQuestionList
        forceReload={showPageType.reloadId}
        onChangePageType={handleFormToggle}
        onAlert={handleOpenAlert}
      />
      {showPageType.type === 'form' && (
        <PasswordQuestionForm
          onChangePageType={handleFormToggle}
          onAlert={handleOpenAlert}
          data={showPageType.data}
        />
      )}

      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
};

export default PasswordQuestionContainer;
