import React, {useState, Fragment} from 'react';
/*-------------------------------------------------------*/
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styleMenuLabel = {fontSize:15, color:'var(--primary)'};

export default function({onChange, children, options=[], propsButton={}, icon}){
	let [anchorEl, setAnchorEl] = useState(null);
	function handleAnchor(evt){
		if(evt){
			setAnchorEl(evt.currentTarget);
		}else{
			setAnchorEl(null)
		}
	}
	return(
		<Fragment>
			<Menu
	          anchorEl={anchorEl}
	          open={!!anchorEl}
	          onClose={() => handleAnchor()}
	        >
		        {options.filter(e=>[undefined, true].includes(e.visible)).map((e,i)=>(
			        <MenuItem 
			        	{...e}
				        onClick={()=>{
				        	onChange(e,i);
				        	handleAnchor();
				        }}
			        >
			        	{(typeof e.icon)=='string'?(
			            	<i className={e.icon} style={{color:e.color}}/>
			        	):e.icon}
			            <span style={styleMenuLabel} className='ml-2'>{e.label}</span>
			        </MenuItem>
		        ))}
	        </Menu>

	        {children?
	        	children(handleAnchor)
	        :(
	        	<IconButton
	        	  aria-label="More"
	        	  aria-owns={anchorEl ? 'long-menu' : undefined}
	        	  aria-haspopup="true"
	        	  onClick={handleAnchor}
	        	  {...propsButton}
	        	  style={Object.assign({transform: 'rotate(90deg)', color:'var(--success)'}, propsButton.style)}
	        	>
				  {typeof icon=='string'?(
					<i className={icon}/>
				  ):(
	        	  	<MoreVertIcon/>
				  )}
	        	</IconButton>
	        )}
	    </Fragment>
	)
}