import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import React, { Component } from 'react';
import { translateFromPtBR } from '../../common/languages/Dictionary';

export default class StatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_pause_restart: this.props.values?.time_pause_restart ?? {},
      select_provider: this.props.values?.select_provider ?? {} 
    };
  }
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;
    if (values && values.status === 'Em Atendimento') { 
      values.statusOriginal = values.status;
      values.status = translateFromPtBR(values.status, language);
    }

    let columnKanban = [
      {
        id: 1,
        name: language.IMPEDITIVE,
      },
      {
        id: 2,
        name: language.VALIDATION,
      },
      {
        id: 3,
        name: language.DOING,
      },
      {
        id: 4,
        name: language.TO_DO,
      },
    ];

    let fields = [
      {
        col: 10,
        name: 'status',
        label: language.STATUS,
        type: 'text',
        required: true,
        placeholder: language.WRITE_STATUS_NAME,
      },
      {
        col: 10,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: false,
        multiLine: true,
        placeholder: language.WRITE_STATUS_DESCRIPTION,
      },
      {
        col: 10,
        name: 'sla',
        label: language.STATUS_WILL_STOP_SLA,
        type: 'check',
        checked: false,
        required: false,
      },
      {
        col: 10,
        name: 'fk_id_status_kanban',
        label: language.WHICH_KANBAN_COLUMN_SPRINT_WILL_STATUS_MATCH,
        type: 'select',
        required: true,
        options: columnKanban.map((column) => {
          return {
            value: column.id,
            label: column.name,
          };
        }),
      },
      {
        col: 10,
        name: 'select_provider',
        label: language.STATUS_SHOULD_SELECT_VENDOR,
        type: 'check',
        required: false,
      },
      {
        col: 10,
        name: 'select_provider_required',
        label: language.STATUS_SHOULD_SELECT_VENDOR_REQUIRED,
        type: 'check',
        required: false,
        visible: this.state.select_provider
      },
      {
        col: 10,
        name: 'time_pause_restart',
        label: language.LIMIT_TIME_PAUSE_SLA,
        type: 'check',
        required: false,
      },
      {
        col: 10,
        name: 'time_pause_restart_required',
        label: language.LIMIT_TIME_PAUSE_SLA_REQUIRED,
        type: 'check',
        required: false,
        visible: this.state.time_pause_restart ?? false
      },
      {
        col: 10,
        name: 'auto_close',
        label: language.STATUS_WILL_AUTOMATICALLY_CLOSE_TICKET,
        type: 'check',
        required: false,
      },
      {
        col: 10,
        name: 'ticket_close_days',
        label: language.IF_YES_HOW_MANY_DAYS,
        type: 'text',
        required: false,
      },
      {
        col: 10,
        name: 'ticket_resume',
        label: language.RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_CHAT,
        type: 'check',
        required: false,
        // visible: true
      },
      {
        col: 10,
        name: 'ticket_resume_attachment',
        label: language.RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_ATTACHMENT,
        type: 'check',
        required: false,
        // visible: true
      },
      {
        col: 10,
        name: 'subticket',
        label: language.USE_STATUS_SUB_CALLS,
        type: 'check',
        required: false,
      },
      {
        col: 10,
        name: 'reason_required',
        label: language.REASON_REQUIRED,
        type: 'check',
        required: false,
      },
      {
        col: 10,
        name: 'send_reason_to_chat',
        label: language.SEND_REASON_TO_CHAT,
        type: 'check',
        required: false,
      },
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog
        maxWidth="sm"
        monitor_scroll
        title={values ? language.EDIT : language.ADD}
        modal={false}
        open={true}
        onClose={() => handlePage('list')}
      >
        <CommonForm
          onChangeField={ (f, v) => {
            if (f.name === 'time_pause_restart') {
              this.setState({time_pause_restart: v})
            } else if (f.name === 'select_provider') {
              this.setState({select_provider: v})
            }
          }}
          values={values}
          fields={fields}
          onSubmit={(v) => {
            if (values && values.statusOriginal) {
              v.status = values.statusOriginal;
            }
            onFormSubmit(v);
          }}
          button={button}
        />
      </Dialog>
    );
  }
}
