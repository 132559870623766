export function OKREvolution({ data }) {
	return {
		chart: {
			type: 'column',
			height: 200

		},
		title: null,

		xAxis: {
			type: 'category',
			crosshair: true,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		yAxis: {
			min: 0,
			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
			footerFormat: '</table>',
			shared: true,
			useHTML: true
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0
			},
			series: {
				borderRadius: 3,

				// borderWidth: 0,
				borderColor: 'red',
				dataLabels: {
					enabled: true,
					format: '{point.y:.0f}',
					color: 'var(--primary)',
					style: {
						fontSize: 8,
						color: 'var(--primary)'
					}
				},


			}
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		series: [{
			name: '',
			data: data
		}]
	}
}
