

import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {Grid, Card} from '@material-ui/core';
import AppBar from '@common/AppBar2';
import {NpsItens} from './Graph';

import NPSCompany from './NPSCompany'
import NPSTech from './NPSTech'

export default function(nps){
	 
	
	  const [months, setMonths] = useState([]);
	  const [list, setList] = useState([]);
	  const [list2, setList2] = useState([]);
	
 return(
		<Card id='nps' className='mb-2 h-100'>
			<AppBar title='NPS'>
			 
			</AppBar>
			<div className='content-medium pt-0'>
				<Grid container spacing={16}>
			   	<Grid item xs={6}>
					 <NPSCompany nps={nps}></NPSCompany>
					</Grid>
					<Grid item xs={6}>
						<NPSTech nps={nps}></NPSTech>
					 </Grid>
				</Grid>
			</div>
		</Card>
	)
}