import React, { Component } from 'react';
import { Card,Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import FaClose from 'react-icons/lib/fa/close';
import CommonTable from '../../common/CommonTable';
export default class SiteGroup extends Component {
  constructor() {
    super();
    this.state = {
      groupId: null,
      groupModal: false
    };
    this.onChangeField = this.onChangeField.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({
      groupModal: true
    });
  }
  closeModal() {
    this.setState({
      groupModal: false
    });
  }
  onChangeField(field, value) {
   // console.log('fsfsd');
    if (field.name === 'fk_id_group_black_list') {
      this.setState({
        groupId: value
      });
    }
  }
  render() {
    let {
      language,
      handlePage,
      onFormSubmit,
      device,
      group,
      blackLists
    } = this.props;
    let { groupId, groupModal } = this.state;
    let values = '';
    let data = [];
    let fields = [
      {
        span: 12,
        name: 'name',
        label: language.GROUP_NAME,
        type: 'text',
        required: true
      }
    ];

    if (groupId != null) {
      blackLists = blackLists
        .filter(c => c.fk_id_group_black_list == groupId)
        .map(c => {
          return c;
        });
    }

    let responsibleOptions = [];
    if (group != undefined) {
      responsibleOptions = group.map(c => ({
        value: c.id_group_black_list,
        text: c.name
      }));
    }
    let fieldsgroup = [
      {
        span: 4,
        name: 'fk_id_group_black_list',
        label: 'Group',
        type: 'select',
        options: responsibleOptions
      },
      {
        span: 4,
        name: 'site_name',
        label: 'site',
        type: 'text',
        required: true
      },
      { span: 4, name: 'site_url', label: 'url', type: 'text', required: true }
    ];
    let col = [
      {
        key: 'id_black_list',
        label: '#ID',
        style: { width: 100, textAlign: 'center' }
      },
      {
        key: 'name',
        label: language.GROUP,
        style: { width: 60, textAlign: 'center' }
      },
      {
        key: 'site_name',
        label: 'site',
        style: { width: 50, textAlign: 'center' }
      },
      { key: 'site_url', label: 'url', style: { textAlign: 'center' } },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' }
      }
    ];

    if (blackLists != undefined) {
      data = blackLists.map(obj => {
        return {
          id_black_list: obj.id_black_list,
          name: obj.name,
          site_name: obj.site_name,
          site_url: obj.site_url,

          action: (
            <Col md={4}>
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.REMOVE}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <div
                  className="trashIcon pointer"
                  style={{ width: 25, height: 25 }}
                  onClick={() => handlePage('remove', obj)}
                />
              </Tooltip>
            </Col>
          )
        };
      });
    }

    let button = { md: 12, label: language.SAVE, primary: true };

    return (
      <PageLayout
        icon={
          <div
            className="companyIcon"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={'Black List Sites'}
        subtitle={values ? language.EDIT_MAIL : 'Black List Sites'}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-plus'
            color='primaryGradient'
            onClick={() => this.openModal()}
            label={language.NEW_GROUP}
            primary
          />
        ]}
      >
        <Dialog modal={groupModal} open={groupModal} onClose={()=>this.closeModal()} title={'New Group'} maxWidth='xs'>
          
            <CommonForm
              values={values}
              language={language}
              fields={fields}
              onFormSubmit={onFormSubmit}
              button={button}
            />
          
        </Dialog>

        
        <Paper>
          <CommonForm
            values={values}
            language={language}
            fields={fieldsgroup}
            onChangeField={this.onChangeField}
            onFormSubmit={onFormSubmit}
            button={button}
          />
        </Paper>
        
        <CommonTable
          language={language}
          columns={col}
          data={data}
        />
      </PageLayout>
    );
  }
}
