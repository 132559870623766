import React, { Component } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import Button from '@common/Button';

export default class PasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      allDevices: [],
      devices: [],
      deviceOptions: [],
      companies: [],
      companiesVisible: false,
      passVisible: false,
      devicesTypes: [],
      departaments: [],
      departamentsSelected: []
    };
  }

  componentWillMount() {
    const action = new Actions();

    console.log('company props', this.props.company);

    action
      .execute('get', Constants.APIEndpoints.DEVICE, 'devices')
      .then((result) => {
        this.setState({ allDevices: result.data });

        /*
        let deviceOptions = result.data.map((d) => ({
          value: d.id_device,
          label: d.name,
        }));
        this.setState({ deviceOptions });
     */
      });

    action
      .execute('get', Constants.APIEndpoints.COMPANY, 'companies')
      .then((result) => {
        this.setState({ companies: result.data });
      });

    action
      .execute('get', Constants.APIEndpoints.DEVICETYPE, 'devicetype')
      .then((result) => {
        this.setState({ devicesTypes: result.data });
      });

    if ( this.props.company ) {
      action
        .execute(
          'post',
          Constants.APIEndpoints.DEPARTMENT + '/filter',
          '',
          {fk_id_companies: this.props.company},
          ''
        )
        .then((result) => {
          this.setState({ departaments: result.data });
        });
    } else {
      action
        .execute(
          'get',
          Constants.APIEndpoints.DEPARTMENT + '/departments',
          'departments'
        )
        .then((result) => {
          this.setState({ departaments: result.data });
        });
    }

    action
      .execute(
        'get',
        Constants.APIEndpoints.PASSWORD_SAFE + '/departaments',
        'password_safe'
      )
      .then((result) => {
        this.setState({ departamentsSelected: result.data });
      });
  }

  loadDevices(value) {
    let devices = this.state.allDevices.filter(
      (device) => device.fk_id_device_type == value
    );
    this.setState({ devices })
  }

  render() {
    let { language, handlePage, onFormSubmit, values, passAssistent, company } =
      this.props;
    let deviceOptions = [];

    console.log('values', values);

    if (company) {
      let devicesFilter = this.state.devices.filter(d => company.includes(d.fk_id_company))
      deviceOptions = devicesFilter.map((d) => ({
        value: d.id_device,
        label: d.name,
      }));
    }

    if (values) {
      if (values.hasOwnProperty('user_email')) {

        var base64 =
          /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
        if (base64.test(values.user_email)) {

          try {
            const crypto = require('crypto');
            let decipher, tRet;
            var aSecret = 'Orch3str0!@forever!';

            let aMsg = values.user_email.replace(/\s/g, '');
            decipher = crypto.Decipher('aes-256-cbc', aSecret);
            tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
            tRet += decipher.final('utf8');
            values.user_email = tRet;
          } catch (e) {

          }
        }
      }

      if (values.hasOwnProperty('profile_type')) {
        values.profile_type = parseInt(values.profile_type);
        if (values.profile_type == 2) {
          let departamentSelected = [];
          let deps = this.state.departamentsSelected.filter((dep) => dep.fk_id_password == values.id_password_safe)
          deps.map((dep) => {
            departamentSelected.push(dep.fk_id_departament)
          })
          if (departamentSelected.length > 0) {
            values.departaments_selected = departamentSelected;
          }


        }
      }
    }

    let deviceTypeOptions = this.state.devicesTypes.map((d) => ({
      value: d.id_device_type,
      label: d.name,
    }));

    let companyOptions = this.state.companies.map((c) => ({
      value: c.id_company,
      label: c.name,
    }));

    let departamentOptions = this.state.departaments.map((c) => ({
      value: c.id_department,
      label: c.name,
    }));

    let passOptions = [
      { value: 1, label: language.PASSWORD_SIMPLE },
      { value: 2, label: language.PASSWORD_ASSISTANT_NEW },
    ];

    let typeOptions = [
      { value: 1, label: language.PRIVATE },
      { value: 2, label: language.CUSTOMIZED },
      { value: 3, label: language.FREE },
    ];

    let fields = [
      {
        col: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
        placeholder: language.WRITE_STATUS_NAME,
      },
      {
        col: 12,
        name: 'user_email',
        label: language.USER,
        type: 'text',
        required: true,
        multiLine: true,
        placeholder: language.WRITE_STATUS_DESCRIPTION,
      },

      {
        col: 4,
        name: 'type_pass',
        label: language.PASSWORD_TYPE,
        type: 'select',
        required: false,
        options: passOptions,
      },
      {
        col: 7,
        name: 'user_password',
        label: language.PASSWORD,
        type: this.state.passVisible ? 'text' : 'password',
        required: true,
      },
      {
        col: 1,
        type: 'element',
        content: (v, h, submit) => (
          <Button
            icon="fas fa-eye"
            variant="circle"
            color="success"
            onClick={() =>
              this.setState({ passVisible: !this.state.passVisible })
            }
            style={{ marginTop: 15, marginLeft: -20 }}
          />
        ),
      },
      {
        col: 6,
        name: 'fk_id_device_type',
        label: language.TYPE_DEVICE,
        type: 'select',
        options: deviceTypeOptions,
        required: false,
      },

      {
        col: 6,
        name: 'fk_id_device',
        label: language.DEVICE,
        type: 'select',
        options: deviceOptions,
        required: false,
      },
      {
        col: 12,
        name: 'link',
        label: language.LINK,
        type: 'text',
        required: false,
      },

      {
        col: 12,
        name: 'expiration_date',
        label: language.EXPIRATION_DATE,
        type: 'date',
        required: false,
      },
      {
        col: 12,
        name: 'profile_type',
        label: language.PROFILE_TYPE,
        type: 'select',
        required: true,
        options: typeOptions,
      },

      {
        col: 12,
        name: 'departaments_selected',
        // label: language.DEPARTMENTS,
        // type: 'select',
        // required: false,
        // isMulti: true,
        // options: departamentOptions,
        type: 'autocomplete',
        label: language.DEPARTMENT,
        textlabel: language.DEPARTMENT,
        method: 'POST',
        route:  !company || company === 0 ? null : `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
        filter: { fk_id_companies: company || 0 },
        routeAll: !company || company === 0 ? null : `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
        filterAll: { fk_id_companies: company || 0 },
        routeGetCount: !company || company === 0 ? null : `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
        fieldquery: 'search',
        textinit:
          values && values.departments_labels
            ? values.departments_labels
            : language.DEPARTMENT,
        fieldvaluedb: 'id_department',
        fieldlabeldb: 'name',
        idinit:
          values && values.departaments_selected ? values.departaments_selected : 0,
        methodGetAll: 'POST',
        required:  false ,
        showEmail: false,
        isMulti: true,
        loadOnFocus : true,
        disabled: !company || company === 0
      },
      {
        col: 12,
        name: 'observation',
        label: language.OBSERVATION,
        type: 'textEditor',
        required: false,
      },
    ];

    let button = { label: language.SAVE };
    return (
      <Dialog
        maxWidth="sm"
        monitor_scroll
        title={language.PASSWORD}
        modal={false}
        open={true}
        onClose={() => handlePage('list')}
      >
        <CommonForm
          values={values}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
          onChangeField={(field, value) => {
            if (field.name == 'type_pass') {
              if (value == 2) {
                passAssistent();
              }
            }
            if (field.name == 'profile_type') {
              if (value == 2) {
                this.setState({ companiesVisible: true });
              } else {
                this.setState({ companiesVisible: false });
              }
            }

            if (field.name == 'fk_id_device_type') {
              this.loadDevices(value);
            }
          }}
        />
      </Dialog>
    );
  }
}
