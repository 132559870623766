import Button from '../../common/Button';
import React from 'react';
import { observer } from 'mobx-react';
import { Top10, Status } from './Graph';
import { Card, Chip, Grid } from '@material-ui/core';
import CommonTable from '@common/CommonTable';
import PageLayout from '../../common/PageLayout';

import moment from 'moment';
import AppBar from '@common/AppBar2';
import ReactTooltip from 'react-tooltip';
import './ServiceOrderList';
import CommonPage from '../../common/CommonPage';
import classnames from 'classnames';
import { filterObj } from '@common/Func';

import './ServiceOrderList.css';
import Header from '@common/Header';
import ButtonControlCarousel from '../dashboard/block_6/ButtonControlCarousel';
import CardIndicatorB3B6 from '../dashboard/CardIndicatorB3B6';

import { npsVelocimeter } from '../dashboard/block_6/Graph';
import ReactHighcharts from 'react-highcharts';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

import NPSCompany from '../dashboard/block_6/NPSCompany';
import NPSTech from '../dashboard/block_6/NPSTech';

import CommonForm from '@common/CommonForm2';

import DownloadFiles from '../../util/DownloadFiles';

const action = new Actions();

@observer
export default class ServiceOrderList extends CommonPage {
  constructor() {
    super();
    this.state = {
      search: '',
      nps: [],
      question: 'avaliation_level',
      dados_nps: [],
      form: {
        form: null,
        dtini: moment().format('MM/01/YYYY'),
        dtend: moment().format('MM/DD/YYYY'),
        company: null,
      },
      page: 0,
    };
  }

  checkNumber = (value) => {
    var n = Number(value);

    if (n > 0) {
      return n;
    } else {
      return 0;
    }
  };

  componentWillMount() {


    action.execute(
      'post',
      Constants.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id },
      null,
      null,
      false
    );

    this.getNPS(
      null,
      null,
      moment().format('YYYY-MM-01'),
      moment().format('YYYY-MM-DD')
    );
    action.execute('get', Constants.APIEndpoints.GROUP, 'groups');
  }

  getNPS = (companies = null, techs = null, dt_ini, dt_end) => {
    action
      .execute(
        'get',
        Constants.APIRoot + '/api/orderavaliation',
        'service_order_avaliation'
      )
      .then((res) => {
        let data = res.data.filter(
          (data) =>
            moment(data.dt_cad).format('YYYY-MM-DD') >=
              moment(dt_ini).format('YYYY-MM-DD') &&
            moment(data.dt_cad).format('YYYY-MM-DD') <=
              moment(dt_end).format('YYYY-MM-DD')
        );

        let role = this.store.getUserLoged().role;
        if (role == 'solicitant') {
          data = data.filter(
            (data) => data.fk_id_solicitant == this.store.getUserLoged().id
          );
        } else if (role == 'tech') {
          data = data.filter(
            (data) => data.fk_id_tech == this.store.getUserLoged().id
          );
        }

        if (companies) {
          data = data.filter((d) => companies.includes(d.fk_id_company));
        }

        if (techs) {
          data = data.filter((d) => techs.includes(d.fk_id_tech));
        }

        this.setState({ nps: data });

        let nps = {
          nps: {
            npsDetail: {
              list: [],
              list2: [],
            },
          },
        };

        let grouppedC = _.groupBy(data, (data) => data.fk_id_solicitant);

        let cont = 0;
        Object.keys(grouppedC).map((index) => {
          let detA = grouppedC[index].filter(
            (item) => parseInt(item.avaliation_level) < 7
          );
          let pasA = grouppedC[index].filter(
            (item) =>
              parseInt(item.avaliation_level) > 6 &&
              parseInt(item.avaliation_level) < 9
          );
          let promA = grouppedC[index].filter(
            (item) => parseInt(item.avaliation_level) > 8
          );

          let detE = grouppedC[index].filter(
            (item) => parseInt(item.experience) < 7
          );
          let pasE = grouppedC[index].filter(
            (item) =>
              parseInt(item.experience) > 6 && parseInt(item.experience) < 9
          );
          let promE = grouppedC[index].filter(
            (item) => parseInt(item.experience) > 8
          );

          let detR = grouppedC[index].filter(
            (item) => parseInt(item.resolved) < 7
          );
          let pasR = grouppedC[index].filter(
            (item) => parseInt(item.resolved) > 6 && parseInt(item.resolved) < 9
          );
          let promR = grouppedC[index].filter(
            (item) => parseInt(item.resolved) > 8
          );

          nps.nps.npsDetail.list[cont] = {
            label: grouppedC[index][0].company_name,
            total: res.data.length,
            total1: {
              avaliation_level: {
                detratores: detA.length,
                pasivos: pasA.length,
                promotores: promA.length,
                total: detA.length + pasA.length + promA.length,
              },
              resolved: {
                detratoresre: detR.length,
                pasivosre: pasR.length,
                promotoresre: promR.length,
                total: detR.length + pasR.length + promR.length,
              },
              experience: {
                detratoresex: detE.length,
                pasivosex: pasE.length,
                promotoresex: promE.length,
                total: detE.length + pasE.length + promE.length,
              },
            },
          };
          cont++;
        });

        let grouppedT = _.groupBy(data, (data) => data.fk_id_tech);
        let contT = 0;
        Object.keys(grouppedT).map((index) => {
          let detA = grouppedT[index].filter(
            (item) => parseInt(item.avaliation_level) < 7
          );
          let pasA = grouppedT[index].filter(
            (item) =>
              parseInt(item.avaliation_level) > 6 &&
              parseInt(item.avaliation_level) < 9
          );
          let promA = grouppedT[index].filter(
            (item) => parseInt(item.avaliation_level) > 8
          );

          let detE = grouppedT[index].filter(
            (item) => parseInt(item.experience) < 7
          );
          let pasE = grouppedT[index].filter(
            (item) =>
              parseInt(item.experience) > 6 && parseInt(item.experience) < 9
          );
          let promE = grouppedT[index].filter(
            (item) => parseInt(item.experience) > 8
          );

          let detR = grouppedT[index].filter(
            (item) => parseInt(item.resolved) < 7
          );
          let pasR = grouppedT[index].filter(
            (item) => parseInt(item.resolved) > 6 && parseInt(item.resolved) < 9
          );
          let promR = grouppedT[index].filter(
            (item) => parseInt(item.resolved) > 8
          );
          nps.nps.npsDetail.list2[contT] = {
            label: grouppedT[index][0].tech_name,
            total: res.data.length,
            total1: {
              avaliation_level: {
                detratores: detA.length,
                pasivos: pasA.length,
                promotores: promA.length,
                total: detA.length + pasA.length + promA.length,
              },
              resolved: {
                detratoresre: detR.length,
                pasivosre: pasR.length,
                promotoresre: promR.length,
                total: detR.length + pasR.length + promR.length,
              },
              experience: {
                detratoresex: detE.length,
                pasivosex: pasE.length,
                promotoresex: promE.length,
                total: detE.length + pasE.length + promE.length,
              },
            },
          };
          contT++;
        });

        this.setState({ dados_nps: nps });
      });
  };

  sel = (question) => {
    this.setState({ question: question });
  };

  sendvalues(id, array) {
    this.setState({ dialog: false });
    this.store.loading = true;
    if (id == 'export') {
      action
        .execute('post', `${this.APIRoot}/api/os/export/`, '', { os: array })
        .then((res) => {
          if ( res.data ) {
            res = res.data;
          }
          setTimeout(() => {
            this.store.loading = false;
            DownloadFiles(
              this.APIEndpoints.REQUESTS +
                '/downloadosexport/' +
                this.store.getUserLoged().id +
                '/' +
                res[0].file,
              res[0].file
            );
          }, 3000);
        });
    } else {
      action
        .execute('get', `${this.APIRoot}/api/os/export/` + id, '')
        .then((res) => {
          if ( res.data ) {
            res = res.data;
          }setTimeout(() => {
            this.store.loading = false;
            DownloadFiles(
              this.APIEndpoints.REQUESTS +
                '/downloadosexport/' +
                this.store.getUserLoged().id +
                '/' +
                res[0].file,
              res[0].file
            );
          }, 3000);
        });
    }
  }

  render() {
    let { handlePage, language, serviceOrders, redirect, redirect_, searchOS } =
      this.props;
    let { question, nps, dados_nps } = this.state;
    let { users, companies } = this.store;
    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 1, paddingRight: 0 },
        thConfig: true,
      },

      {
        key: 'status',
        label: language.STATUS,
        style: { width: 1, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'objective',
        label: language.OBJECTIVE,
        style: { width: 300 },
        thConfig: true,
      },
      {
        key: 'date_cad',
        label: language.DATE_CAD,
        style: { width: 100 },
        thConfig: true,
      },

      {
        key: 'user',
        label: language.SOLICITANT,
        style: { width: 100 },
        thConfig: true,
      },
      {
        key: 'aprovador',
        label: language.APPROVER,
        style: { width: 100 },
        thConfig: true,
      },
      {
        key: 'company',
        label: language.COMPANY,
        style: { width: 200 },
        thConfig: true,
      },
      {
        key: 'contract',
        label: language.CONTRACT,
        style: { width: 250, overflow: 'auto' },
        thConfig: true,
      },
      {
        key: 'service_time',
        label: language.SERVICE_TIME,
        style: { width: 100 },
        thConfig: true,
      },
      {
        key: 'tech',
        label: language.TECH,
        style: { width: 100 },
        thConfig: true,
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 1, paddingRight: 0, paddingLeft: 0 },
        thConfig: true,
        jc: 'center',
      },
    ];
    const reloadNPS = (values) => {
      let companies = values.companies ? values.companies : null;
      let techs = values.techs ? values.techs : null;
      this.getNPS(companies, techs, values.dtini, values.dtend);
    };

    /*if(this.store.getUserLoged().role  == 'solicitant' ){
     serviceOrders = serviceOrders.filter(os=>os._status ==='Finished')
    }
    if(this.store.getUserLoged().role  == 'tech'){
     serviceOrders = serviceOrders.filter(os=>os.fk_id_user_tech ===
       this.store.getUserLoged().id ||
       os.approver ==  this.store.getUserLoged().id
        )
    }*/

    let approve = [];
    let passive = [];
    let reprove = [];

    let optionsUsers = users
      .filter((u) => u.role === 'tech' || u.role === 'client')
      .map((p) => ({ value: p.id_user, label: p.name }));

    if (question == 'avaliation_level') {
      approve = nps.filter((nps) => parseInt(nps.avaliation_level) > 8);
      passive = nps.filter(
        (nps) =>
          parseInt(nps.avaliation_level) > 6 &&
          parseInt(nps.avaliation_level) < 9
      );
      reprove = nps.filter((nps) => parseInt(nps.avaliation_level) < 7);
    }

    if (question == 'resolved') {
      approve = nps.filter((nps) => parseInt(nps.resolved) > 8);
      passive = nps.filter(
        (nps) => parseInt(nps.resolved) > 6 && parseInt(nps.resolved) < 9
      );
      reprove = nps.filter((nps) => parseInt(nps.resolved) < 7);
    }

    if (question == 'experience') {
      approve = nps.filter((nps) => parseInt(nps.experience) > 8);
      passive = nps.filter(
        (nps) => parseInt(nps.experience) > 6 && parseInt(nps.experience) < 9
      );
      reprove = nps.filter((nps) => parseInt(nps.experience) < 7);
    }

    let data = serviceOrders.map((os) => {
      let endTime = moment(os.end_date);

      let startTime = moment(os.start_date);
      let interval = moment(os.interval);

      let a = moment(endTime).diff(moment(startTime));
      let g = moment(a).diff(interval);

      let timeSpent = os.intervalo;
      let header = {};
      try {
        header = {
          id_order: os.id_os,
          solicitant: os.solicitant_name,
          company: os.company_name,
          tech: os.tech_name,
          objective: os.objective,
          fk_id_solicitant: os.fk_id_solicitant,
          fk_id_user_tech: os.fk_id_user_tech,
          fk_id_approver: os.approver,
        };

        header = JSON.stringify(header);
        header = btoa(header);
      } catch (r) {}

      let token = '/dash/serviceorder/avaliation?t=' + header;

      return {
        id: os.id_os,
        status: (
          <div>
            {os._status === 'Finished' ? (
              <div
                data-tip={language.FINISHED}
                className="finishedStatusIcon"
                style={{ height: 25, width: 25 }}
              />
            ) : os._status === 'Approved' ? (
              <div
                data-tip={language.APPROVED}
                className="revisedIcon"
                style={{ height: 25, width: 25 }}
              />
            ) : os._status === 'Create' && os.closed == false ? (
              <div
                data-tip={language.CREATE}
                className="createIcon"
                style={{ height: 25, width: 25 }}
              />
            ) : os._status === 'Cancelled' ? (
              <div
                data-tip={language.TICKET_STATUS.CANCELLED}
                className="cancelledIcon"
                style={{ height: 25, width: 25 }}
              />
            ) : os._status === 'Rejected' ? (
              <div
                data-tip={language.TICKET_WORKFLOW_OF_CHANGE.REJECTED}
                className="rejectedIcon cardIcon"
              />
            ) : (
              <div
                data-tip={language.FINISHED}
                className="finishedStatusIcon"
                style={{ height: 25, width: 25 }}
              />
            )}
            <ReactTooltip />
          </div>
        ),
        objective:
          os.id_os_clone > 0
            ? `Os ${PRIMARY} #` + os.id_os_clone + ' ' + os.objective
            : os.objective,
        date_cad: moment(os.start_date).format('L'),

        aprovador: os.solicitant,
        user: os.aprovador,
        company: os.company_name,
        contract: os.contract_name,
        service_time:
          timeSpent === 'Invalid date'
            ? language.NOTHING_REGISTERED
            : timeSpent,
        tech: os.tech_name,

        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  {(os.approver == this.store.getUserLoged().id ||
                    os.fk_id_solicitant == this.store.getUserLoged().id ||
                    this.store.getUserLoged().role == 'client') &&
                  os.closed == true ? (
                    <Button
                      variant="circle"
                      icon="fas  fa-user-tag"
                      label={language.EVALUATE}
                      color="default"
                      style={{
                        fontSize: 13,
                        width: 21,
                        height: 21,
                        marginRight: 10,
                      }}
                      onClick={() => window.open(token, '_blank')}
                    />
                  ) : (
                    <div></div>
                  )}

                  {this.store.getUserLoged().role == 'client' &&
                  os.status == 2 ? (
                    <Button
                      variant="circle"
                      icon="far fa-copy"
                      label={language.EXTEND_OS}
                      color="default"
                      style={{
                        fontSize: 13,
                        width: 21,
                        height: 21,
                        marginRight: 10,
                      }}
                      onClick={() => {
                        os.clone = true;

                        redirect_('/dash/serviceorder/edit', os);
                      }}
                    />
                  ) : null}

                  {this.store.getUserLoged().role == 'client' &&
                  !['Approved'].includes(os._status) ? (
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      onClick={() => handlePage('remove', os)}
                      _loading={'delete-os-item-' + os.id_os}
                    />
                  ) : null}
                  {(this.store.getUserLoged().role == 'client' ||
                    this.store.getUserLoged().id == os.user_cad) &&
                  !['Approved'].includes(os._status) ? (
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="light"
                      className={classnames('ml-2', {
                        'mr-2':
                          this.store.getUserLoged().role != 'solicitant' &&
                          ['Create', 'Rejected', 'Finished'].includes(
                            os._status
                          ),
                      })}
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      onClick={() =>
                        //redirect_('/dash/serviceorder/edit', filterObj(os))
                        handlePage('edit',os)
                      }
                    />
                  ) : null}

                  {['Create', 'Rejected', 'Finished', 'Approved'].includes(
                    os._status
                  ) ? (
                    <Button
                      variant="circle"
                      icon="fas fa-eye"
                      label={language.VISUALIZE}
                      color="default"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() =>
                        redirect('/dash/serviceorder/view', filterObj(os))
                      }
                    />
                  ) : null}

                  <Button
                    variant="circle"
                    icon="far fa-file-excel"
                    label={'Export'}
                    color="default"
                    style={{
                      fontSize: 12,
                      width: 21,
                      height: 21,
                      marginLeft: 10,
                    }}
                    onClick={() => this.sendvalues(os.id)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    //serviceOrders.reduce()
    // return { name: h.name, value: h.y};

    let colors = [
      '#019FC2',
      '#02CBF0',
      '#02F0DE',
      '#02F079',
      '#A7F002',
      '#E7F002',
      '#F0B002',
      '#F05E02',
      '#EE0D81',
      '#019FC2',
      '#E7F002',
      '#02CBF0',
      '#02F0DE',
      '#02F079',
      '#A7F002',
    ];

    let chaves = serviceOrders.reduce(function (acc, curr) {
      if (!acc[curr.company_name]) {
        acc[curr.company_name] = 0;
      }
      acc[curr.company_name]++;
      return acc;
    }, []);

    let dadosGraficotop10 = Object.keys(chaves).map((v) => {
      return {
        name: v,
        y: chaves[v],
        color: colors[Math.floor(Math.random() * colors.length)],
      };
    });

    dadosGraficotop10.sort(function (a, b) {
      if (a.y > b.y) {
        return -1;
      }
      if (a.y < b.y) {
        return 1;
      }
      return 0;
    });
    dadosGraficotop10 = dadosGraficotop10.slice(0, 10);

    let chavesStatus = serviceOrders.reduce(function (acc, curr) {
      let status =
        curr._status == 'Approved'
          ? language.APPROVED
          : curr._status == 'Finished'
          ? language.CLOSED
          : curr._status == 'Cancelled'
          ? language.CANCELED
          : curr._status == 'Create'
          ? language.CREATED
          : '';
      if (!acc[status]) {
        acc[status] = 0;
      }
      acc[status]++;
      return acc;
    }, []);

    let dadosStatus = Object.keys(chavesStatus).map((v) => {
      return {
        y: chavesStatus[v],
        name: v,

        color: colors[Math.floor(Math.random() * colors.length)],
      };
    });

    dadosStatus.sort(function (a, b) {
      if (a.y > b.y) {
        return -1;
      }
      if (a.y < b.y) {
        return 1;
      }
      return 0;
    });

    return (
      <PageLayout
        icon={
          <div className="serviceOrderIcon" style={{ width: 30, height: 30 }} />
        }
        title={language.SERVICE_ORDER}
        subTitle={language.DASHBOARD_PAGE.GCF + '/'}
        rightElements={[
          <div
            //            id="FilterDashForm"
            style={{ width: 1100, zoom: 0.8, marginTop: '1rem' }}
            zoom={0.8}
            className="mr-2"
          >
            <CommonForm
              individual
              hiddenSubmit
              values={this.state.form}
              onSubmit={(e) => {
                searchOS(e);
                reloadNPS(e);
              }}
              fields={[
                {
                  col: 3.5,
                  type: 'autocomplete',
                  name: 'companies',
                  label: language.COMPANY,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: language.COMPANY,
                  textinit: language.COMPANY,
                  fieldvaluedb: 'id_company',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                  required: true,
                  isMulti: true,
                },
                {
                  col: 3.5,
                  type: 'autocomplete',
                  name: 'techs',
                  label: language.TECH,
                  className: 'tech-group-ticket',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'name',
                  textinit: language.TECH,
                  textlabel: language.TECH,
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: '',
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  detail: true,
                  isMulti: true,
                  methodGetAll: 'POST',
                  visible: this.store.getUserLoged().role == 'client',
                },

                {
                  col: 2,
                  type: 'date',
                  name: 'dtini',
                  label: language.START,
                },
                {
                  col: 2,
                  type: 'date',
                  name: 'dtend',
                  label: language.END,
                },

                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-eraser"
                      variant="circle"
                      color="primary"
                      label={language.CLEAR}
                      onClick={() => window.location.reload()}
                      style={{ marginTop: 15 }}
                    />
                  ),
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-search"
                      variant="circle"
                      color="success"
                      label={language.SEARCH}
                      style={{ marginTop: 15 }}
                      onClick={() => submit()}
                    />
                  ),
                },
              ]}
            />
          </div>,
        ]}
      >
        <div style={{ marginTop: '-1rem' }}>
          <Header
            btnFilterProps={{
              style: { width: 150 },
            }}
            btnFilter={['OS', 'NPS', 'DASHBOARD']}
            onChange={(index) => {
              this.setState({ page: index });
            }}
            active={this.state.page}
          />
          {this.state.page == 0 && (
            <CommonTable
              columns={col}
              index="ServiceOrderList"
              data={data}
              searchColumn
              orderColumn
              beforeBar={
                <div>
                  {this.store.getUserLoged().role != 'solicitant' ? (
                    <Button
                      variant="circle"
                      icon="fas fa-plus"
                      className="mr-3"
                      label={language.NEW_SERVICE_ORDER}
                      onClick={() => {
                        this.props.clear();
                        redirect('/dash/serviceorder/new');
                      }}
                    />
                  ) : null}
                  {this.store.getUserLoged().role != 'solicitant' ? (
                    <Button
                      variant="circle"
                      icon="fas fa-file-csv"
                      label={`${language.EXPORT} OS`}
                      color="warning"
                      className="mr-3"
                      onClick={() =>
                        this.sendvalues(
                          'export',
                          serviceOrders.map((g) => g.id_os)
                        )
                      }
                    />
                  ) : null}{' '}
                  {this.store.getUserLoged().role == 'client' ? (
                    <Button
                      variant="circle"
                      icon="fas fa-file-csv"
                      label={`${language.EXPORT} NPS`}
                      color="warning"
                      className="mr-3"
                      onClick={() => this.sendvalues('NPS')}
                    />
                  ) : null}{' '}
                </div>
              }
            />
          )}

          <Card
            style={{ borderRadius: '0 0 4px 4px', marginTop: '10px' }}
            className="content"
          >
            {this.state.page == 1 && (
              <Grid container spacing={16}>
                <Grid item xs={4} className="panel-b1-c1">
                  <Grid>
                    <div id="caroulsel">
                      <ButtonControlCarousel
                        options={[
                          {
                            label:
                              `(1) ${language.EVALUATION_REGARDING_CALL_RESOLUTION_TIME} `,
                            value: 'avaliation_level',
                          },
                          {
                            label:
                              `(2) ${language.EVALUATION_REGARDING_CALL_RESOLUTION_QUALITY}`,
                            value: 'resolved',
                          },
                          {
                            label:
                              `(3) ${language.EVALUATION_REGARDING_ATTENDANCE_TEAM}`,
                            value: 'experience',
                          },
                        ]}
                        value={question}
                        onChange={(v) => this.sel(v)}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    {/*------------------------------------3 CARDS INDICADORES--------------------------------------------------*/}
                    <div id="cards">
                      <Grid container spacing={4}>
                        {[language.DETRACTORS, language.PASSIVE, language.PROMOTERS]
                          .map((e, i) => (
                            <Grid
                              item
                              xs={4}
                              id="content-indicator"
                              className="content-indicators"
                            >
                              <CardIndicatorB3B6
                                hasIcon
                                label={e}
                                desc="Votos"
                                percent={
                                  i == 0
                                    ? this.checkNumber(
                                        (
                                          (nps && reprove.length / nps.length) *
                                          100
                                        ).toFixed(0)
                                      )
                                    : i == 1
                                    ? this.checkNumber(
                                        (
                                          (nps && passive.length / nps.length) *
                                          100
                                        ).toFixed(0)
                                      )
                                    : this.checkNumber(
                                        (
                                          (nps && approve.length / nps.length) *
                                          100
                                        ).toFixed(0)
                                      )
                                }
                                value={
                                  i == 0
                                    ? nps && reprove.length
                                    : i == 1
                                    ? nps && passive.length
                                    : nps && approve.length
                                }
                                language={language}
                              />
                            </Grid>
                          ))
                          .reverse()}
                      </Grid>
                    </div>
                    {/*---------------------------------------------GRÁFICO--------------------------------------------------*/}
                    <div className="mt-5">
                      <ReactHighcharts
                        config={npsVelocimeter(
                          (nps.length > 0 &&
                            (
                              (approve.length / nps.length) * 100 -
                              (reprove.length / nps.length) * 100
                            ).toFixed(0)) ||
                            0
                        )}
                      />
                      <div className="legent-content d-flex justify-content-center">
                        <div>
                          <span>NPS = {language.PROMOTERS} </span>
                          <span>
                            {this.checkNumber(
                              (
                                (nps && approve.length / nps.length) * 100
                              ).toFixed(0)
                            )}
                            %
                          </span>
                          <span> - {language.DETRACTORS} </span>
                          <span>
                            {this.checkNumber(
                              (
                                (nps && reprove.length / nps.length) * 100
                              ).toFixed(0)
                            )}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={4} className="panel-b1-c1">
                  <NPSCompany nps={dados_nps}></NPSCompany>
                </Grid>

                <Grid item xs={4} className="panel-b1-c1">
                  <NPSTech nps={dados_nps}></NPSTech>
                </Grid>
              </Grid>
            )}
            {this.state.page == 2 && (
              <Grid container spacing={16}>
                {this.store.getUserLoged().role != 'solicitant' && (
                  <Grid item xs={6} className="panel-b1-c1">
                    <Card className="h-100">
                      <AppBar title={language.TOP_10_COMPANIES} subTitle={''} />

                      <ReactHighcharts config={Top10({ dadosGraficotop10 })} />
                    </Card>
                  </Grid>
                )}
                <Grid
                  item
                  xs={this.store.getUserLoged().role == 'solicitant' ? 12 : 6}
                  className="panel-b1-c1"
                >
                  <Card className="h-100">
                    <AppBar title={language.GRAPH_BY_STATUS} subTitle={''} />
                    <ReactHighcharts config={Status({ dadosStatus })} />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Card>
        </div>
      </PageLayout>
    );
  }
}
