import React from 'react';
import Selector from './Selector';
import Store from '@data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';

export default function ({ projects, prioritization, language }) {
  const CALC = (id) => {
    let total = 0;
    try {
      total = parseInt(
        prioritization.filter((f) => f.id_scale == id.desire_directors)[0]
          .desire_directors
      );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.strategic_alignment)[0]
            .strategic_alignment
        );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.benefit_amount)[0]
            .benefit_amount
        );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter(
            (f) => f.id_scale == id.processes_improvement
          )[0].processes_improvement
        );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.financial_feedback)[0]
            .financial_feedback
        );
    } catch (e) {}

    return total;
  };

  const CALC1 = (id) => {
    let total = 0;
    try {
      total = parseInt(
        prioritization.filter((f) => f.id_scale == id.generates_costs)[0]
          .generates_costs
      );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.risk_if_not_done)[0]
            .risk_if_not_done
        );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.urgency)[0].urgency
        );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.complexity)[0]
            .complexity
        );
    } catch (e) {}
    try {
      total =
        total +
        parseInt(
          prioritization.filter((f) => f.id_scale == id.involved_areas)[0]
            .involved_areas
        );
    } catch (e) {}

    return total;
  };

  let _data = [];

  if (projects && prioritization) {
    let data = projects.map((y) => {
      return {
        label: y.name,
        x: CALC1(y) / 5,
        y: CALC(y) / 5,
        z: y.innovation * 2 || 1,
        value: y.id_project,
        country: translateFromPtBR(y.name, Store.language),
      };
    });

    let key;
    let dx = data.reduce((obj, e) => {
      key = obj[e.x + '-' + e.y];
      if (!key) {
        obj[e.x + '-' + e.y] = { qtd: 0, id: 0, collection: [] };
      }
      obj[e.x + '-' + e.y].qtd++;
      obj[e.x + '-' + e.y].id = e.value;
      obj[e.x + '-' + e.y].collection.push(e.value);
      return obj;
    }, {});

    _data = data.map((e) => {
      let item = dx[e.x + '-' + e.y];
      return (
        <Selector
          {...e}
          tooltipCount={
            item.qtd > 1 && item.id == e.value ? item.qtd : undefined
          }
          collection={item.collection}
        />
      );
    });
  }

  return (
    <div className="content-prioritization">
      {[
        { label: language.DANGEROUS },
        { label: language.RISKY },
        { label: language.HEALTHY },
        { label: language.GREAT },
      ].map((e, i) => (
        <div className="content-item">
          <div className="content">
            <h3>{e.label}</h3>
          </div>
        </div>
      ))}
      <span className="label-x">{language.CONTRIBUTION_TO_BUSINESS}</span>
      <span className="label-y">{language.COMPLEXITY_AND_COST}</span>
      {_data}
    </div>
  );
}
