import React, { Component } from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  Legend
} from 'recharts'

export default class CustoPorProjeto extends Component {
  render() {
    const { data } = this.props
    const colors = ['#0088fe', '#00c49f']
    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Tooltip />
          <Legend />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="90%"
            unit="%"
            label
          >
            <Label value="R$ 3.000,00" position="center" fill="#00c49f" />
            {data.map((entry, index) => (
              <Cell fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}
