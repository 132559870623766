import React, { Fragment, useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import decrypt from '../../util/decrypt';
import Store from '@data/Store';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import { constants } from 'crypto';
import { concatDateHour, moment } from '../../util/dateTime';

export default function ({
  title,
  open,
  onClose,
  onSubmit,
  language,
  onSaveActivity,
  typeOptions = [],
  type,
  ac,
  change,
  store,
  ondeletefile,
  typeTemp,
  params
}) {
  const [groupApprove, setGroupAprove] = useState(false);
  const [valuesTaskManagement, setValuesTaskManagement] = useState({});
  const [id_group, setId_Group] = useState(0);

  const onSaveActivity_ = (values) => {
    values.start_date = concatDateHour(values.start_date, values.start_time);
    values.start_time = concatDateHour(values.start_date, values.start_time);
    values.end_date = concatDateHour(values.end_date, values.end_time);
    values.end_time = concatDateHour(values.end_date, values.end_time);

    values.type = type;
    if (
      (moment(values.start_date).format('YYMMDDHHmm') <
        moment(change.start_date).format('YYMMDDHHmm') ||
        moment(values.start_date).format('YYMMDDHHmm') >=
          moment(change.end_date).format('YYMMDDHHmm') ||
        moment(values.end_date).format('YYMMDDHHmm') <=
          moment(change.start_date).format('YYMMDDHHmm') ||
        moment(values.end_date).format('YYMMDDHHmm') >
          moment(change.end_date).format('YYMMDDHHmm')) &&
      typeTemp == false
    ) {
      if (moment(values.end_date).isSameOrBefore(moment(values.start_date))) {
        Store.toggleAlert(
          true,
          `Data fora do intervalo da GMUD e data de término não pode ser anterior a data de início`,
          `error`
        );
      } else {
        Store.toggleAlert(true, `Data fora do intervalo da GMUD`, `error`);
      }
    } else if (
      moment(values.start_date).isSameOrAfter(moment(values.end_date))
    ) {
      Store.toggleAlert(
        true,
        'Data de término não pode ser igual ou anterior a de início'
      );
    } else {
      onSaveActivity(values);
      onClose();
    }
  };

  return (
    <Dialog title={title} open={open} onClose={onClose} maxWidth="md">
      <CommonForm
        values={ac}
        onChangeField={(field, value) => {
          if (field.name == 'fk_id_group_tech') {
            setId_Group(value);
          }
        }}
        fields={[
          {
            col: 8,
            label: language.TASK_NAME,
            name: 'name',
            type: 'text',
            required: true,
          },
          {
            col: 4,
            name: 'priority',
            label: language.PRIORITY,
            type: 'number',
            required: true,
          },
          {
            col: 3,
            name: 'start_date',
            label: language.INITIAL_DATE,
            type: 'date',
            required: true,
          },
          {
            col: 3,
            name: 'start_time',
            label: language.INITIAL_TIME,
            type: 'time',
            required: true,
          },
          {
            col: 3,
            name: 'end_date',
            label: language.END_DATE,
            type: 'date',
            required: true,
          },
          {
            col: 3,
            name: 'end_time',
            label: language.END_TIME,
            type: 'time',
            required: true,
          },

          {
            span: 4,
            type: 'autocomplete',
            name: 'fk_id_group_tech',
            label: language.TECH_GROUP,
            textlabel: language.TECH_GROUP,
            textinit: (ac && ac.group_tech) || language.TECH_GROUP,
            className: 'tech-group-ticket',
            required: true,
            method: 'POST',
            filterAll: { fk_id_change: change.id_change, fk_id_type : params.use_tech_group_in_gmud_activity ? 1 : 0 },
            route: `${Constants.APIEndpoints.GROUP}/filter`,
            fieldquery: 'search',
            fieldvaluedb: 'id_group_users',
            fieldlabeldb: 'name',
            routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
            detail: true,
            methodGetAll: 'POST',
            routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
            idinit: (ac && ac.fk_id_group_tech) || 0,
          },

          {
            col: 6,
            type: 'autocomplete',
            name: 'fk_id_sponsor',
            label: language.RESPONSIBLE,
            textlabel: language.RESPONSIBLE,
            textinit: (ac && ac.nameuser) || language.RESPONSIBLE,
            method: 'POST',
            routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
            routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${
              change.fk_id_company || 0
            }`,
            fieldquery: 'search',
            textinit: (ac && ac.nameuser) || language.RESPONSIBLE,
            fieldvaluedb: 'id_user',
            fieldlabeldb: 'name',
            idinit: (ac && ac.fk_id_sponsor) || 0,
            routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
            filterAll: { fk_id_group_users: id_group },
            filter: { fk_id_group_users: id_group },
            methodGetAll: 'POST',
            required: false,
            showEmail: true,
            isMulti: false,
          },
          {
            col: 6,
            beforeContent: <br></br>,
            type: 'file',
            name: 'file',
            label: language.ATTACH,
            removeFile: (f) => ondeletefile(f.id_change_activity_file),
          },

          {
            col: 12,

            label: language.DESCRIPTION,
            name: 'description',
            type: 'textEditor',
            required: true,
          },
        ]}
        onSubmit={onSaveActivity_}
      />
      {/*generalParams.azure_devops == true ?
			<CommonForm
				hiddenSubmit
				onChangeField={(f, v) => {
					setIntegration(v)

				}}
				fields={[
					{
						col: 12,
						type: 'select',
						name: 'integraion',
						label: 'Tipo de atividade ',
						options: [{ label: 'default', value: 'default' }, { label: 'azure', value: 'azure' }]
					}
				]} />
			: null}

		{integration == 'azure' ?
			<CommonForm

				values={ac}
				onChangeField={(f, v) => {
					if (f.name == 'organization') {
						fetchValuesGet({ type: 'project', organization: v })
					}
					if (f.name == 'project') {
						fetchValuesGet({ type: 'pipeline', project: v })

						fetchValuesGet({ type: 'branch', project: v })
					}




				}}

				fields={[
					{
						col: 12,
						type: 'select',
						name: 'organization',
						label: 'Organização ',
						options: organizationList


					},
					{
						col: 12,
						type: 'select',
						name: 'project',
						label: 'Projetos ',
						options: projectList
					},
					{
						col: 12,
						type: 'select',
						name: 'branch',
						label: 'Branch ',
						options: branchs
					},
					{
						col: 12,
						type: 'select',
						name: 'pipeline',
						label: 'Pipeline ',
						options: pplist
					},
					{
                        span: 4,
                        type: 'autocomplete',
                        name: 'fk_id_group_tech',
                        label: 'Grupo Técnico',
                        className: 'tech-group-ticket',
                        required: true,
                        method: 'POST',
                        route: `${Constants.APIEndpoints.GROUP}/filter`,
                        fieldquery: 'search',
                        textinit:  'Selecione',
                        fieldvaluedb: 'id_group_users',
                        fieldlabeldb: 'name',
                        idinit: 1,
                        routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                        detail: true,
                        methodGetAll: 'POST',
                        routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                      },
					{
						type: 'select',
						name: 'fk_id_sponsor',
						label: 'Responsável pela atividade',
						options: listUsers,
						required: true
					}

				]}
				button={{
					fluid: true
				}}
				onSubmit={onSaveActivity_}
			/>
			: <br></br>}
		{integration == 'default' ?
			<CommonForm
				values={ac}
				fields={[


					{
						type: 'text',
						name: 'name',
						label: 'Nome',
						required: true
					},

					{
                        span: 4,
                        type: 'autocomplete',
                        name: 'fk_id_group_tech',
                        label: 'Grupo Técnico',
                        className: 'tech-group-ticket',
                        required: true,
                        method: 'POST',
                        route: `${Constants.APIEndpoints.GROUP}/filter`,
                        fieldquery: 'search',
                        textinit:  'Selecione',
                        fieldvaluedb: 'id_group_users',
                        fieldlabeldb: 'name',
                        idinit: 1,
                        routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                        detail: true,
                        methodGetAll: 'POST',
                        routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                      },
					{
						type: 'select',
						name: 'fk_id_sponsor',
						label: 'Responsável pela atividade',
						options: listUsers,
						required: true
					},

					{
						type: 'time',
						name: 'time_estimate',
						label: 'Tempo Estimado',
						required: true
					},
					{
						type: 'file',
						name: 'file',
						label: 'Anexar',
					},
				]}
				button={{
					fluid: true
				}}
				onSubmit={onSaveActivity_}
			/>
			: <br></br>*/}
    </Dialog>
  );
}
