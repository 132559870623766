import React, { useEffect, useState } from 'react';
import CommonForm from '../../common/CommonForm2';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';

export default function CompanyPortalFormFaq({ dataCompany, language }) {

    const [data, setData] = useState(dataCompany);
    const [hasChanged, setHasChanged] = useState(false)

    useEffect(() => {
        const baseUrl = `${Constants.APIEndpoints.COMPANY}/${data.id_company}/portal/${data.portal.id_company_portal}/file`

        const fetchData = async () => {
            const fetch = new Actions();
            fetch
                .execute('get', baseUrl, 'company_portal_file', null, 'id_company_portal_file')
                .then(({ data }) => {
                    const base64 = `data:${data.type};base64,${data.blob}`;
                    const image = convertBase64ToFile(base64, data.file);
                    image.base64 = base64;

                    const _data = { ...dataCompany, ...data };
                    _data.portal['file_data'] = [image]

                    setData(_data);
                });
        }
        if (data.portal.id_company_portal)
            fetchData();
    }, [])

    const fields = [
        {
            col: 4,
            name: 'file_data',
            label: 'Arquivo',
            type: 'file',
            startIcon: 'fas fa-upload',
            accept: 'application/pdf,application/vnd.ms-excel',
            maxFiles: 1,
            toBase64: true,
            customPortalCss: true,
            disabled: data?.portal?.file_data?.length,
            btnProps: { color: data?.portal?.file_data?.length ? 'secondary' : 'success' },
            removeFile: f => handleChangeField({ name: f.field }, null)
        },
    ];

    const convertBase64ToFile = (base64String, fileName) => {
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let uint8Array = new Uint8Array(n);
        while (n--) uint8Array[n] = bstr.charCodeAt(n);

        return new File([uint8Array], fileName, { type: mime });
    }

    const handleChangeField = (field, value) => {
        const _data = { ...data };
        _data.portal[field.name] = value;
        setData(_data);
        setHasChanged(true);
    }

    const handleFormSubmit = (formData) => {
        const baseUrl = `${Constants.APIEndpoints.COMPANY}/${data.id_company}/portal/${data.portal.id_company_portal}/file`

        if (formData.file_data[0]) {
            formData.file_data[0].type = formData.file_data[0].file.type;
            formData.file_data[0].size = formData.file_data[0].file.size;
            formData.file_data[0].name = formData.file_data[0].file.name;

            //remove unnecessary fields
            Object.keys(formData).forEach(key => (key !== 'file_data') && (delete formData[key]));
        }

        const dispatcher = new Actions();
        dispatcher
            .execute('post', baseUrl, 'company_portal_file', formData, 'id_company_portal_file')
            .then(() => { })
            .finally(() => setHasChanged(false));

    }

    return (
        <>
            <CommonForm
                values={data?.portal}
                fields={fields}
                onSubmit={handleFormSubmit}
                onChangeField={handleChangeField}
                button={{
                    md: 2,
                    offset: { md: 10 },
                    label: language.SAVE,
                    primary: true,
                    disabled: !hasChanged,
                    style: { marginBottom: 10, float: 'right' },
                }}
            />
        </>
    )
}
