import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TicketList from './TicketList';
import PropTypes from 'prop-types';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core'; // controls
import TicketDetail from './TicketDetail';
import GuacamoleView from '../guaca/index';
import Help from '../../common/components/Help';
import TicketDetailTab from './TicketDetailTab';
import TicketForm from './TicketForm';
import TicketFormEdit from './TicketFormEdit';
import TicketAddObserver from './TicketAddObserver';
import PageLayout from '../../common/PageLayout';
import { TextField } from '@material-ui/core';
import CommonPage from '../../common/CommonPage';
import moment from 'moment';
import screenfull from 'screenfull';
import { Dialog, Card } from '@material-ui/core';
import AssessmentTerminal from '../assessment/AssessmentTerminal';
import SolutionContainerTicket from '../solution/SolutionContainerTicket';
import FaClose from 'react-icons/lib/fa/close';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import '../dashboards/CioPage/Cio.css';
import AppBar from '@material-ui/core/AppBar';
import classnames from 'classnames';

import DownloadFiles from '../../util/DownloadFiles';

let backurl = '';
const styles = theme => ({
  label: {
    color: 'orange'
  },
  textColor: {
    color: 'orange'
  },
  indicator: {
    backgroundColor: 'orange'
  }
});
@observer
class TicketContainer extends CommonPage {
  constructor() {
    super();

    let PAGE = window.location.href.replace(window.location.origin + '/', '');

    this.state = {
      index: 0,
      page: PAGE === 'dash/tickets/detail' ? '' : 'list',
      r: null,
      ticketsSelected: [],
      md: false,
      files: [],
      disabled: true,
      fileError: '',
      tab: 0,
      paginas: [],
      render: true,
      itensTickets: [],
      connection: '',
      companyId: 0,
      search: '',
      device: null,
      ticketNew: 0,
      FixedBar: false,
      editmodal: false
    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmitTab = this.onFormSubmitTab.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onCommentTicket = this.onCommentTicket.bind(this);
    this.onNotesTicket = this.onNotesTicket.bind(this);
    this.onResolution = this.onResolution.bind(this);
    this.onChangeStatus_ = this.onChangeStatus_.bind(this);
    this.onNotesTicket_1 = this.onNotesTicket_1.bind(this);
    this.onNotesTicket_2 = this.onNotesTicket_2.bind(this);
    this.onNotesTicket_3 = this.onNotesTicket_3.bind(this);
    this.onNotesTicket_4 = this.onNotesTicket_4.bind(this);
    this.onFormSubmitItem = this.onFormSubmitItem.bind(this);
    this.onDescriptionTicket = this.onDescriptionTicket.bind(this);
    this.startAssessment = this.startAssessment.bind(this);
    this.finishAssessment = this.finishAssessment.bind(this);
    this.onupdateTicket = this.onupdateTicket.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDrops = this.onDrops.bind(this);
    this.UpdateFiles = this.UpdateFiles.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addTab = this.addTab.bind(this);
    this.closeTab = this.closeTab.bind(this);
    this.onNotesTicketTab = this.onNotesTicketTab.bind(this);
    this.onResolutionTab = this.onResolutionTab.bind(this);
    this.onChangeStatus_Tab = this.onChangeStatus_Tab.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleChangesearch = this.handleChangesearch.bind(this);
    this.handleticket = this.handleticket.bind(this);
    this.publicRefreshList = this.publicRefreshList.bind(this);
    this.onChangeStatusJust = this.onChangeStatusJust.bind(this);
    this.onFormSubmitRequest = this.onFormSubmitRequest.bind(this);
    this.ondeletefileRequest = this.ondeletefileRequest.bind(this);
  }

  handleticket(ticket) {
    this.setState({ ticketNew: ticket });
  }
  handleCompany(values) {
    this.setState({ companyId: values });
  }

  handleChangesearch = event => {
    const {
      target: { name, value }
    } = event;
    this.setState({ search: value });
  };
  finishAssessment(contract, note, tickets) {
    screenfull.exit();
    let { assessment } = this.store;
    this.action
      .execute(
        'post',
        this.APIEndpoints.ASSESSMENT,
        'assessment',
        { tickets: tickets, path: assessment[0].path, note: note },
        null,
        'Finished the remote connection'
      )
      .then(e => {
        this.redirect('/dash/tickets/detail', {
          ticket: this.props.location.state.ticket
        });
      });
  }

  addTab(id_ticket) {
    let v = this.state.paginas;
    let check = true;
    let indice = 0;
    if (this.state.paginas.indexOf(id_ticket) == -1) {
      v.push(id_ticket);
      this.setState({ paginas: v });
    }
  }

  closeTab(id_ticket) {
    this.publicRefreshList();
    let v = [];
    this.state.paginas.map(c => {
      if (c.id_tickets != id_ticket.id_tickets) {
        v.push(c);
      }
    });

    this.setState({ paginas: v, tab: 0 });
  }
  startAssessment(
    contract,
    iddevice,
    tickets,
    connection,
    ip,
    contracts,
    device
  ) {
    //console.log(this.props.location.state.ticket);

    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT,
      'assessment',
      {
        device: iddevice,
        tickets: undefined,
        contract: contract
      },
      null,
      'Waiting for remote connection'
    );

    this.setState({
      ticketsSelected: tickets,
      connection: device.connection,
      device: device
    });
    this.setState({ page: 'terminal' });
  }
  publicRefreshList() {
    if (this.store.getUserLoged().role != 'solicitant') {
      let route = this.props.location.state.route;
      route =
        route === 'incident'
          ? 'Incident'
          : route === 'problem'
            ? 'Problem'
            : route === 'solicitation'
              ? 'Solicitation'
              : '';
      this.action.execute(
        'get',
        this.APIEndpoints.TICKET + '?type=' + route + 'workflow',
        'tickets'
      );
    } else {
      //this.action.execute('get', this.APIEndpoints.TICKET, 'tickets');
    }
  }
  componentWillMount() {
    var _this = this;

    window.onscroll = function () {
      if (_this.state.paginas.length == 0) {
        scrollFunction();
      }
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 211 ||
        document.documentElement.scrollTop > 211
      ) {
        if (_this.state.FixedBar == false) {
          _this.setState({ FixedBar: true });
        }
      } else {
        if (_this.state.FixedBar == true) {
          _this.setState({ FixedBar: false });
        }
      }
    }




    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }
    );

    this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
    this.action.execute('get', this.APIEndpoints.DEVICE, 'devices');
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/20',
      'type_solution'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.COMPANY_TIME + '/time',
      'time'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.COMPANY_TIME + '/timeall',
      'timeall'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.USER + '/techgroups',
      'groupstech'
    );

    if (this.store.getUserLoged().role != 'solicitant') {
      let route = this.props.location.state.route;
      route =
        route === 'incident'
          ? 'Incident'
          : route === 'problem'
            ? 'Problem'
            : route === 'solicitation'
              ? 'Solicitation'
              : '';

      let PAGE = window.location.href.replace(window.location.origin + '/', '');

      if (PAGE === 'dash/tickets/create') {
        this.handlePage('form', {
          subject: '',
          description: '',
          fk_id_user: this.store.getUserLoged().id,
          fk_id_request: null,
          fk_id_company: 1
        })





      } else
        if (PAGE === 'dash/tickets/edit') {
          this.action.execute(
            'get',
            this.APIEndpoints.TICKET + '/' + this.props.location.state.ticket,
            'ticket'
          ).then(g => {
           // console.log(this.store.ticket)
            this.handlePage('categorize', g.data)
          })



        } else {

          if (PAGE === 'dash/tickets/detail') {
            let ticketDetail = this.props.location.state.ticket;
            this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
            try {
              backurl = this.props.location.state.back;
            } catch (r) { }
            if (ticketDetail.id_request != undefined) {
            } else {
              this.action.execute(
                'get',
                this.APIEndpoints.TICKET + '/' + ticketDetail.id_tickets,
                'ticket'
              );

              this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
            }
          } else if (this.state.page === 'list') {
            this.action.execute(
              'get',
              this.APIEndpoints.TICKET + '?type=' + route + 'workflow',
              'tickets'
            );


          } else {
            this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
            this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
            this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
            this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
            this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');
            this.action.execute(
              'get',
              this.APIEndpoints.TABLE_STRUCTURED + '/40',
              'urgencies'
            );
            this.action.execute(
              'get',
              this.APIEndpoints.TABLE_STRUCTURED + '/60',
              'priorities'
            );
            this.action.execute(
              'get',
              this.APIEndpoints.TABLE_STRUCTURED + '/70',
              'type_tickets'
            );
            this.action.execute(
              'get',
              this.APIEndpoints.TABLE_STRUCTURED + '/50',
              'impacts'
            );
            this.action.execute('get', this.APIEndpoints.SLA, 'slas');
            this.action.execute(
              'get',
              this.APIEndpoints.USER + '/department/chief',
              'chiefs'
            );
            this.action.execute('get', this.APIEndpoints.GROUP, 'requests');
            this.action.execute('get', this.APIEndpoints.TASK, 'taskcatalog');
            this.action.execute(
              'get',
              this.APIEndpoints.COMPANY_TIME + '/time',
              'time'
            );
          }
        }
    } else {
      let PAGE = window.location.href.replace(window.location.origin + '/', '');

      if (PAGE === 'dash/tickets/detail') {
        try {
          backurl = this.props.location.state.back;
        } catch (r) { }
        let ticketDetail = this.props.location.state.ticket;

        if (ticketDetail.id_request != undefined) {
        } else {
          this.action.execute(
            'get',
            this.APIEndpoints.TICKET + '/' + ticketDetail.id_tickets,
            'ticket'
          );

          this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
        }
      } else {
        this.action.execute('get', this.APIEndpoints.TICKET, 'tickets');
      }


    }

  }

  handlePage(page, item) {

    if (page != 'categorizeTab') {
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/20',
        'type_solution'
      );
      let route = '';
      try {
        route = this.props.location.state.route;
      } catch (e) { }
      route =
        route === 'incident'
          ? 'Incident'
          : route === 'problem'
            ? 'Problem'
            : route === 'solicitation'
              ? 'Solicitation'
              : '';

      if (page === 'list') {
        this.action.execute(
          'get',
          this.APIEndpoints.TICKET + '?type=' + route + 'workflow',
          'tickets'
        );
        this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
        this.action.execute(
          'get',
          this.APIEndpoints.TICKET + '/stop/sla?type=' + route,
          'tickets_stop_sla'
        );
      } else if (page == 'form') {
        this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
        this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
        this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
        this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
        this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/40',
          'urgencies'
        );
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/60',
          'priorities'
        );
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/70',
          'type_tickets'
        );
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/50',
          'impacts'
        );
        this.action.execute('get', this.APIEndpoints.SLA, 'slas');
        this.action.execute(
          'get',
          this.APIEndpoints.USER + '/department/chief',
          'chiefs'
        );
        this.action.execute('get', this.APIEndpoints.GROUP, 'requests');
        this.action.execute('get', this.APIEndpoints.TASK, 'taskcatalog');
        this.action.execute(
          'get',
          this.APIEndpoints.COMPANY_TIME + '/time',
          'time'
        );
        setTimeout(
          function () {
            this.setState({ page: 'form', values: item });
          }.bind(this),
          1000
        );
      } else if (page == 'categorize') {
        this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
        this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
        this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
        this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
        this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/40',
          'urgencies'
        );
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/60',
          'priorities'
        );
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/70',
          'type_tickets'
        );
        this.action.execute(
          'get',
          `${this.APIEndpoints.CATEGORY}/type_ticket/1`,
          'tickets_types_cat'
        );
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/50',
          'impacts'
        );
        this.action.execute('get', this.APIEndpoints.SLA, 'slas');
        this.action.execute(
          'get',
          this.APIEndpoints.USER + '/department/chief',
          'chiefs'
        );
        this.action.execute('get', this.APIEndpoints.GROUP, 'requests');
        this.action.execute('get', this.APIEndpoints.TASK, 'taskcatalog');
        this.action.execute(
          'get',
          this.APIEndpoints.COMPANY_TIME + '/time',
          'time'
        );

        this.setState({ page: 'categorize', values: item });

      } else if (page == 'base') {
        this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
        this.action.execute('get', this.APIEndpoints.SOLUTION, 'solutions');
        this.action.execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/20',
          'type_solution'
        );
        this.setState({ page: page, values: item });
      } else {
        this.setState({ page: page, values: item });
      }
    } else {
      this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
      this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
      this.action.execute('get', this.APIEndpoints.CATEGORY, 'categories');
      this.action.execute('get', this.APIEndpoints.GROUP, 'groups');
      this.action.execute('get', this.APIEndpoints.CATALOG, 'catalogs');
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/40',
        'urgencies'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/60',
        'priorities'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/70',
        'type_tickets'
      );
      this.action.execute(
        'get',
        `${this.APIEndpoints.CATEGORY}/type_ticket/1`,
        'tickets_types_cat'
      );
      this.action.execute(
        'get',
        this.APIEndpoints.TABLE_STRUCTURED + '/50',
        'impacts'
      );
      this.action.execute('get', this.APIEndpoints.SLA, 'slas');
      this.action.execute(
        'get',
        this.APIEndpoints.USER + '/department/chief',
        'chiefs'
      );
      this.action.execute('get', this.APIEndpoints.GROUP, 'requests');
      this.action.execute('get', this.APIEndpoints.TASK, 'taskcatalog');
      this.action.execute(
        'get',
        this.APIEndpoints.COMPANY_TIME + '/time',
        'time'
      );

      if (this.state.editmodal) {
        this.state.editmodal = false;
      } else {
        this.state.editmodal = true;
      }

      this.setState({ editmodal: this.state.editmodal, values: item });
    }
  }

  onChangeStatusJust(id_tickets, status, just, refresh) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/' + id_tickets,
      'tickets',
      { action: status, justify: just }
    );
    var t = this;
    setTimeout(function () {
      if (refresh === true) {
        t.redirect('/dash/tickets/detail', {
          ticket: t.props.location.state.ticket
        });
      } else {
        t.action.execute(
          'get',
          t.APIEndpoints.TICKET + '/' + id_tickets,
          'ticket'
        );
      }
    }, 2000);
  }

  onResolution(ticket, values) {
    values.id_tickets = ticket.id_tickets;
    values.fk_id_category = ticket.fk_id_category;
    values.time_resolution = values.time_resolution;
    values.fk_id_catalog_service = ticket.fk_id_catalog_service;
    values.subject = ticket.subject;
    values.type_origin = 2
    this.action.execute('post', this.APIEndpoints.SOLUTION, 'ticket', values);
    this.redirect('/dash/tickets', { route: 'problem' });
  }


  onFormSubmitRequest(type, values) {

    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    values.values.action = 'approval';
    values.values.status = 'Rejected';

    let contador = 0;
    this.action.execute(
      'post',
      this.APIEndpoints.REQUEST + '/' + values.id_request,
      'requests',
      values.values
    ).then(e => this.redirect('/dash/gsd'));



  }



  onResolutionTab(ticket, values) {
    values.id_tickets = ticket.id_tickets;
    values.fk_id_category = ticket.fk_id_category;
    values.time_resolution = values.time_resolution;
    values.fk_id_catalog_service = ticket.fk_id_catalog_service;
    values.subject = ticket.subject;
    values.type_origin = 2
    this.action.execute('post', this.APIEndpoints.SOLUTION, 'ticket', values);
  }

  onFormSubmitTab(values) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    this.action.execute('post', this.APIEndpoints.TICKET, 'ticket', values);
    this.setState({ editmodal: false });
  }

  onFormSubmitItem(values) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    this.setState({ sendFile: true });
    let filesSend = [];
    let cont = 0;

    let total = Array.from(this.state.files).length;
    Array.from(this.state.files).forEach(file => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then(result => {
        if (file.size >= 40000000) {
          alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
          this.setState({ sendFile: false });
          filesSend = [];
          this.state.files = [];
          cont = 0;
          values.type_tickets == 1
            ? this.redirect('/dash/tickets', { route: 'incident' })
            : values.type_tickets == 2
              ? this.redirect('/dash/tickets', { route: 'problem' })
              : values.type_tickets == 4
                ? this.redirect('/dash/tickets', { route: 'solicitation' })
                : this.redirect('/dash/tickets', { route: 'incident' });
        }
        //  console.log(values);
        filesSend.push({
          file: result,
          type: file.type,
          namefile: file.name
        });
        cont = cont + 1;
        // console.log(cont);
        if (total === cont) {
          // console.log(JSON.stringify(filesSend));

          values.file = filesSend;
          this.action
            .execute('post', `${this.APIEndpoints.TICKET}`, '', values)
            .then(results => {
              values.type_tickets == 1
                ? this.redirect('/dash/tickets', { route: 'incident' })
                : values.type_tickets == 2
                  ? this.redirect('/dash/tickets', { route: 'problem' })
                  : values.type_tickets == 4
                    ? this.redirect('/dash/tickets', { route: 'solicitation' })
                    : this.redirect('/dash/tickets', { route: 'incident' });
            })
            .catch((error) => {
              console.error(error);
            })
        }
      });
    });

    // this.redirect('/dash/request');
  }

  onFormSubmit(values) {
    if (this.props.onActiveRefresh) {
      this.props.onActiveRefresh();
    }
    if (values.update) {
      this.action
        .execute('post', this.APIEndpoints.TICKET, 'ticket', values)
        .then(e => {
          this.redirect('/dash/tickets/detail', {
            ticket: { id_tickets: values.id_tickets }
          });
        });
    } else {
      if (this.state.files.length > 0) {
        this.onFormSubmitItem(values);
      } else {
        let method = values.hasOwnProperty('id_tickets') ? 'put' : 'post';
        let url = values.hasOwnProperty('id_tickets')
          ? this.APIEndpoints.TICKET + '/' + values.id_tickets
          : this.APIEndpoints.TICKET;
        this.action
          .execute(method, url, 'tickets', values, 'id_tickets')
          .then(e => {
            this.redirect('/dash/ticketmanagement')

          });
      }
    }
  }

  onDrops(files) {
    if (files.length == 0) {
      alert(
        'Extensões permitidas .pdf .doc .docx .xls .xlsx .ppt .csv .txt .zip, .rar, .msg, audio'
      );
    }

    let item = [];
    Array.from(files).forEach(file => {
      item.push(file);
      if (file.size >= 15000000) {
        alert(language.FILE + file.name + language.MORE_THAN_15MB);
        files = files.filter(function (item) {
          return item.name !== file.name;
        });
      }
    });
    Array.from(this.state.files).forEach(file => {
      item.push(file);
    });

    this.setState({
      files: item
    });
  }

  onDrop(files) {
    if (files.length == 0) {
      alert(
        'Extensões permitidas .pdf .doc .docx .xls .xlsx .ppt .csv .txt .zip, .rar, .msg, audio'
      );
    }
    Array.from(files).forEach(file => {
      if (file.size >= 15000000) {
        alert(language.FILE + file.name + language.MORE_THAN_15MB);
        files = files.filter(function (item) {
          return item.name !== file.name;
        });
      }
    });

    this.setState({
      files: files
    });
  }
  removeFile(item) {
    var f = this.state.files.filter(function (f) {
      return f.name != item.name;
    });
    this.setState({ files: f });
  }
  valuesset() {
    this.setState({ disabled: !this.state.disabled });
  }

  onupdateTicket(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/templateupdate',
      'ticket',
      values
    );
  }
  ondonwFile(id, file, type) {
    if (type != undefined) {
      DownloadFiles(this.APIEndpoints.REQUESTS + '/downloadrequest/' + id + '&' + file, file)
    } else {
      DownloadFiles(this.APIEndpoints.REQUESTS + '/downloadticket/' + id + '&' + file, file)
    }
  }


  ondeletefileRequest(id, request, files) {
    /*
     this.action.execute(
       'get',
       this.APIEndpoints.REQUESTS +
         '/deletefilerequest/' +
         0 +
         '&' +
         id,
         '',
       'filedelete'
     ).then(h=>{
       //this.action.execute('get', this.APIEndpoints.REQUEST, 'requests');
     }) */
    let file = files.filter(h => h.id_request_file !=
      id).map(h => h);
    this.redirect('/dash/tickets/detail', {
      ticket: request,
      flrequest: file && file.length > 0 ?
        JSON.stringify(file) : null,
      back: 'dash'
    })

  }


  ondeletefile(id) {
    this.action.execute(
      'get',
      this.APIEndpoints.REQUESTS +
      '/deletefileticket/' +
      id.id_tickets +
      '&' +
      id.id_tickets_file +
      '',
      'ticket'
    );
  }
  UpdateFiles(tickets) {
    Array.from(this.state.files).forEach(file => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then(
        result => {
          let upload = {
            id_tickets: tickets.id_tickets,
            deleted: false,
            file: result,
            type: file.type,
            namefile: file.name,
            fk_id_company: tickets.id_company
          };
          this.action.execute(
            'post',
            this.APIEndpoints.TICKET + '/upload',
            'ticket',
            upload
          );
          this.state.files = [];
          //setTimeout(function() {   this.redirect('/tech/tickets/detail', { ticket: this.props.location.state.ticket}) }.bind(this), 1000);
        },
        err => { }
      );
    });
  }

  onChangeStatus(id_tickets, status) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_tickets,
        'ticket',
        status
      )
      .then(e => {
        ticket.type_tickets == 'Incident'
          ? this.redirect('/dash/tickets', { route: 'incident' })
          : ticket.type_tickets == 'Problem'
            ? this.redirect('/dash/tickets', { route: 'problem' })
            : ticket.type_tickets == 'Solicitation'
              ? this.redirect('/dash/tickets', { route: 'solicitation' })
              : this.redirect('/dash/tickets', { route: 'incident' });
      });
  }

  onChangeStatus_(id_tickets, status, ticket) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_tickets,
        'ticket',
        status
      )
      .then(e => {
        this.redirect('/dash/tickets/detail', {
          ticket: this.props.location.state.ticket
        });
      });
  }

  onChangeStatus_Tab(id_tickets, status, ticket) {
    this.action
      .execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_tickets,
        'zerovalue',
        status
      )
      .then(e => {
        this.action.execute(
          'get',
          this.APIEndpoints.TICKET + '/' + id_tickets,
          'ticket'
        );
      });
  }

  sendConnectTicketsNew = (values, id_ticket) => {
    this.action.execute(
      'post',
      `${this.APIEndpoints.TICKET}/connect/${id_ticket}`,
      'ticket',
      values
    );
  };

  onCommentTicket(id_ticket, values, type) {
    values.action = 'reply';
    values.id_ticket = id_ticket;
    values.id_user_sponsor = this.store.ticket.fk_id_user_sponsor;
    if (type == 'tech') {
      values.id_user = this.store.ticket.config[0].fk_id_tech;
    } else {
      values.id_user = this.store.ticket.fk_id_user;
    }

    if (this.store.getUserLoged().role == 'solicitant') {
      values.destino = this.store.ticket.config[0].fk_id_tech;
    } else {
      values.destino = this.store.ticket.fk_id_user;
    }

    this.store.$emit('chat-ticket-'+this.store.getTenant, null, { ...values, type }, 'id_tickets', false, { btn_loading: ['ticket-chat'] }).then(data => {

      this.store.ticket.comments.push(data);
      this.store.tickets_chat_opens.map(e => {
        if (e.id_tickets == data.fk_id_tickets) {
          e.comments.push(data);
        }
        return e;
      });
      this.store.new_msg_notifications = this.store.new_msg_notifications.filter(e => e.id_tickets != id_ticket)
    });

  }

  onNotesTicketTab(id_ticket, values, url, type_tickets) {
    if (type_tickets == 'comments') {
      values.action = 'notes';
      values.savecomments = true;
      this.action.execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_ticket,
        'ticket',
        values
      );

      // this.state.md = true;
    }
    //console.log(JSON.stringify(values))
    else if (values.fk_id_teck_new != undefined) {
      values.action = 'notes';
      this.action.execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_ticket,
        'ticket',
        values
      );

      // this.state.md = true;
    } else {
      //values.notes =   values.notes.replace(/<(?:.|\n)*?>/gm, '');
      values.notes = values.notes;

      values.action = 'notes';
      values.url = url;
      this.action.execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_ticket,
        'ticket',
        values
      );
      this.setState({ needReportTime: true });
      if (values.progress == 100) {
      } else {
        values.notes = '';
        values.time = '';
      }
    }
    if (values.progress == 100) {
      setTimeout(
        function () {
          this.publicRefreshList();
        }.bind(this),
        2000
      );

      this.closeTab({ id_tickets: id_ticket });
    }
  }

  onNotesTicket(id_ticket, values, url, type_tickets) {
    if (values.fk_id_teck_new != undefined) {
      values.action = 'notes';
      this.action.execute(
        'post',
        this.APIEndpoints.TICKET + '/' + id_ticket,
        'ticket',
        values
      );

      //this.state.md = true;
    } else {
      //values.notes =   values.notes.replace(/<(?:.|\n)*?>/gm, '');
      values.notes = values.notes;

      values.action = 'notes';
      values.url = url;
      this.action
        .execute(
          'post',
          this.APIEndpoints.TICKET + '/' + id_ticket,
          'ticket',
          values
        )
        .then(e => {
          this.setState({ needReportTime: true });
          if (values.progress == 100) {
            type_tickets == 'Incident'
              ? this.redirect('/dash/tickets', { route: 'incident' })
              : type_tickets == 'Problem'
                ? this.redirect('/dash/tickets', { route: 'problem' })
                : type_tickets == 'Solicitation'
                  ? this.redirect('/dash/tickets', { route: 'solicitation' })
                  : this.redirect('/dash/tickets', { route: 'incident' });
          } else {
            values.notes = '';
            values.time = '';
          }
        });
    }
  }

  onNotesTicket_1(id_ticket, values) {
    values.action = 'notes';
    values.type = 'v1';

    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/' + id_ticket,
      'ticket',
      values
    );
  }
  onNotesTicket_2(id_ticket, values) {
    values.action = 'notes';
    values.type = 'v2';
    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/' + id_ticket,
      'ticket',
      values
    );
  }
  onNotesTicket_3(id_ticket, values) {
    values.action = 'notes';
    values.type = 'v3';
    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/' + id_ticket,
      'ticket',
      values
    );
  }
  onNotesTicket_4(id_ticket, values) {
    values.type = 'v4';
    values.action = 'notes';
    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/' + id_ticket,
      'ticket',
      values
    );
  }

  onDescriptionTicket(id_ticket, values) {
    values.action = 'description';
    this.action.execute(
      'post',
      this.APIEndpoints.TICKET + '/' + id_ticket,
      'ticket',
      values
    );
  }
  handleChange(event, newValue) {
    let i = newValue - 1;
    if (newValue == 0) {
      this.publicRefreshList();
    }

    try {
      this.action.execute(
        'get',
        this.APIEndpoints.TICKET + '/' + this.state.paginas[i].id_tickets,
        'ticket'
      );
    } catch (e) { }

    this.setState({ tab: newValue, ticketNew: 0 });
  }

  render() {
    let { classes } = this.props;
    let {
      solutions,
      impacts,
      language,
      assessment,
      tickets,
      contracts,
      categories,
      groups,
      urgencies,
      priorities,
      users,
      ticket,
      companies,
      type_solution,
      type_tickets,
      slas,
      catalogs,
      chiefs,
      taskcatalog,
      tickets_stop_sla,
      requests,
      time,
      timeall,
      devices,
      usercon_company
    } = this.store;
    let { page, values, md, disabled, files, fileup } = this.state;
    let PAGE = window.location.href.replace(window.location.origin + '/', '');
    let route = null;
    let back = null;
    if (this.store.getUserLoged().role != 'solicitant') {
      route = this.props.location.state.route;

      back = this.props.location.state.back;
    } else {
      route = '';
      back = '';
    }

    if (this.store.getUserLoged().role == 'tech') {
      companies
        .filter(g => usercon_company[0].company.includes(g.id_company))
        .map(c => (
          <MenuItem
            style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
            key={c.id_company}
            value={c.id_company}
            primaryText={c.name}
          >
            {c.name}
          </MenuItem>
        ));
    }

    let itensTickets = [];
    let itens = [];
    let idTab = 1;
    var btnNewTicket = (
      <RaisedButton
        circleButton
        icon="fas fa-plus"
        label={language.NEW_TICKET}
        color='primary'
        className='mr-3'
        style={{ zoom: .8 }}
        zoom={.8}
        onClick={() =>
          this.store.getUserLoged().role != 'solicitant'
            ? this.handlePage('form', {
              subject: '',
              description: '',
              fk_id_user: this.store.getUserLoged().id,
              fk_id_request: null,
              fk_id_company: 1
            })
            : this.redirect('/dash/request', { page: 'form' })
        }
      />
    );
    page === 'list'
      ? itensTickets.push(
        <Typography component="div">

        </Typography>
      )
      : null;
    itens.push(<Tab label="List Ticket"></Tab>);

    this.state.paginas.length > 0
      ? this.state.paginas.map(c => {
        itens.push(<Tab label={'Ticket #' + c.config[0].realid}></Tab>);
      })
      : null;



    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        iconAlert={true}
        loading={false}
      >
        <div
          id="TicketContainer"
          className={classnames({ fixed: this.state.FixedBar })}
        >
          {PAGE === 'dash/tickets/detail' && !page ? (
            <TicketDetail
              onChangeStatusJust={this.onChangeStatusJust}
              groupstech={this.store.groupstech}
              categories={this.store.categories}
              type_solution={this.store.type_solution}
              solutions={this.store.solutions}
              closeTab={this.closeTab}
              onFormSubmitRequest={this.onFormSubmitRequest}
              backurl={backurl}
              onActiveRefresh={this.props.onActiveRefresh}
              ondonwFile={this.ondonwFile}
              ondeletefile={this.ondeletefile}
              UpdateFiles={this.UpdateFiles}
              valuesset={this.valuesset}
              fileup={fileup}
              files={files}
              ondeletefileRequest={this.ondeletefileRequest}
              removeFile={this.removeFile}
              disabled={disabled}
              onDrop={this.onDrop}
              users={users}
              onupdateTicket={this.onupdateTicket}
              sol={null}
              startAssessment={this.startAssessment}
              contracts={contracts}
              devices={devices}
              route={this.route}
              onResolution={this.onResolution}
              onResolutionTab={this.onResolutionTab}
              flrequest={this.props.location.state.flrequest}
              requestTicket={
                this.props.location.state.ticket.id_request === undefined
                  ? null
                  : this.props.location.state.ticket
              }
              back={back}
              onFormSubmit={this.onFormSubmit}
              onChangeStatus_Tab={this.onChangeStatus_Tab}
              onChangeStatus={this.onChangeStatus}
              onChangeStatus_={this.onChangeStatus_}
              language={language}
              handlePage={this.handlePage}
              redirect={this.redirect}
              ticket={ticket}
              values={values}
              onCommentTicket={this.onCommentTicket}
              onNotesTicket={this.onNotesTicket}
              type_solution={type_solution}
              onNotesTicketTab={this.onNotesTicketTab}
              onNotesTicket_1={this.onNotesTicket_1}
              onNotesTicket_2={this.onNotesTicket_2}
              onNotesTicket_3={this.onNotesTicket_3}
              onNotesTicket_4={this.onNotesTicket_4}
              groups={groups}
              md={md}
              onDescriptionTicket={this.onDescriptionTicket}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              btnNewTicket={btnNewTicket}
            />
          ) : page === 'list' ? (
            <div>
              {this.state.tab == 0 ? (

                <div />

              ) : (
                <div>
                  <div style={{ marginBottom: '30px' }}>
                    <PageLayout
                      icon={<div className="imgGsd titleIconSize" />}
                      title={language.TICKET_DETAILS}
                      subtitle={language.DASHBOARD_PAGE.SUB_TITLE}
                      rightElements={[]}
                    >
                      <AppBar
                        className="AppBar-23-sensr"
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          position: 'relative',
                          zIndex: 5
                        }}
                      >
                        <Tabs
                          value={this.state.tab}
                          classes={{ indicator: classes.indicator }}
                          onChange={this.handleChange}
                          variant="fullWidth"
                        >
                          {itens}
                        </Tabs>{' '}
                      </AppBar>
                    </PageLayout>
                  </div>

                  <div>{itensTickets[this.state.tab]}</div>
                </div>
              )}
            </div>
          ) : page === 'base' ? (
            <Dialog
              modal={false}
              open={true}
              autoDetectWindowHeight={false}
              autoScrollBodyContent={true}
              contentStyle={{ width: '80%', maxWidth: 'none' }}
            >
              <FaClose
                fontSize={25}
                className="colorPrimary pointer"
                style={{ marginTop: -20 }}
                onClick={() =>
                  this.redirect('/dash/tickets/detail', {
                    ticket: this.props.location.state.ticket
                  })
                }
              />

              <SolutionContainerTicket
                language={language}
                categories={categories}
                solutions={solutions}
                type_solution={type_solution}
              ></SolutionContainerTicket>
            </Dialog>
          ) : page === 'form' ? (
            <TicketForm
              companies={companies}
              route={route}
              impacts={impacts}
              language={language}
              handlePage={this.handlePage}
              values={values}
              onFormSubmit={this.onFormSubmit}
              redirect={this.redirect}
              chiefs={chiefs}
              contracts={contracts}
              atach={true}
              categories={categories}
              groups={groups}
              urgencies={urgencies}
              priorities={priorities}
              users={users}
              companies={companies}
              type_tickets={type_tickets}
              slas={slas}
              files={files}
              groupstech={this.store.groupstech}
              catalogs={catalogs}
              taskcatalog={taskcatalog}
              action={this.action}
              onDrop={this.onDrops}
              APIEndpoints={this.APIEndpoints}
            />
          ) : page === 'categorize' ? (
            <TicketFormEdit
              companies={companies}
              tickets_types_cat={this.store.tickets_types_cat}
              route={route}
              impacts={impacts}
              language={language}
              handlePage={this.handlePage}
              values={values}
              onFormSubmit={this.onFormSubmit}
              redirect={this.redirect}
              chiefs={chiefs}
              contracts={contracts}
              categories={categories}
              groups={groups}
              urgencies={urgencies}
              priorities={priorities}
              users={users}
              companies={companies}
              type_tickets={type_tickets}
              slas={slas}
              btnback={
                <RaisedButton
                  circleButton
                  icon="fas fa-arrow-left"
                  label={language.BACK}
                  color="primaryGradient"
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    this.redirect('/dash/tickets/detail', {
                      ticket: { id_tickets: values.id_tickets }
                    })
                  }
                />
              }
              catalogs={catalogs}
              taskcatalog={taskcatalog}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            />
          ) : page === 'add' ? (
            <TicketAddObserver
              language={language}
              handlePage={this.handlePage}
            />
          ) : assessment.length ? (
            this.state.connection != 'rdp' ? (
              <AssessmentTerminal
                language={language}
                store={this.store}
                ticketsSelected={this.state.ticketsSelected}
                finishAssessment={this.finishAssessment}
                connection={this.state.connection}
              />
            ) : (
              <GuacamoleView
                language={language}
                user={null}
                password={null}
                domain={null}
                path={this.store.assessment[0].path}
                finishAssessment={this.finishAssessment}
                device={this.state.device}
              ></GuacamoleView>
            )
          ) : null}
        </div>
      </CommonPage>
    );
  }
}
TicketContainer.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(TicketContainer);
