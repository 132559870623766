import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CircularProgress extends Component {

  static defaultProps = {
    percent: true,
  }

  static propTypes = {
    color: PropTypes.string.isRequired,
    current: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    percent: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      size: props.size,
      color: props.color,
      current: 0,
      total: 1,
      percent: props.percent,
    };
  }

  componentDidMount() {
    let {current, total} = this.props;
    setTimeout(function(_this) {
      _this.setValues(current, total);
    }, 100, this);
  }

  componentWillReceiveProps(nextProps) {
    let {current, total} = nextProps;
    this.setValues(current, total);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let state = this.state;
    return state.current !== nextState.current || state.total !== nextState.total;
  }

  setValues(current,total) {
    this.setState({
      current: current,
      total: total>0?total:1,
    });
  }

  darken(color, percent) {
    percent = '-0.'+percent;
    let f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return '#'+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
  }

  render() {
    let {color, current, size, total, percent} = this.state;
    let txt = percent?((current/total)*100).toFixed(0)+'%':current;
    let value = ((current/total).toFixed(2)).split('.');
    let deg = (value[1]*1.8);
    let bgcolor = value[0] > 0 ? this.darken(color, value[0]-1) : '#efefef';

    color = value[0] > 0 ? this.darken(color, value[0]) : color;

    let styles = {
      donut: {
        position: 'relative',
        display: 'inline-block',
        width: size,
        height: size,
        backgroundColor: bgcolor,
        borderRadius: '50%',
      },
      full: {
        position: 'absolute',
        width: size,
        height: size,
        clip: 'rect(0px, '+size+'px, '+size+'px, '+size/2+'px)',
        WebKitBackfaceVisibility: 'hidden',
        transition: 'WebKitTransform 1s',
        transition: 'MsTransform 1s',
        transition: 'transform 1s',
        borderRadius: '50%',
        WebKitTransform: 'rotate('+deg+'deg)',
        MsTransform: 'rotate('+deg+'deg)',
        transform: 'rotate('+deg+'deg)',
      },
      fill: {
        position: 'absolute',
        width: size,
        height: size,
        clip: 'rect(0px, '+size/2+'px, '+size+'px, 0px)',
        WebKitBackfaceVisibility: 'hidden',
        transition: 'WebKitTransform 1s',
        transition: 'MsTransform 1s',
        transition: 'transform 1s',
        borderRadius: '50%',
        backgroundColor: color,
        WebKitTransform: 'rotate('+deg+'deg)',
        MsTransform: 'rotate('+deg+'deg)',
        transform: 'rotate('+deg+'deg)',
      },
      half: {
        position: 'absolute',
        width: size,
        height: size,
        clip: 'rect(0px, '+size+'px, '+size+'px, '+size/2+'px)',
        WebKitBackfaceVisibility: 'hidden',
        transition: 'WebKitTransform 1s',
        transition: 'MsTransform 1s',
        transition: 'transform 1s',
        borderRadius: '50%',
      },
      fill2: {
        position: 'absolute',
        width: size,
        height: size,
        clip: 'rect(0px, '+size/2+'px, '+size+'px, 0px)',
        WebKitBackfaceVisibility: 'hidden',
        transition: 'WebKitTransform 1s',
        transition: 'MsTransform 1s',
        transition: 'transform 1s',
        borderRadius: '50%',
        backgroundColor: color,
        WebKitTransform: 'rotate('+(deg*2)+'deg)',
        MsTransform: 'rotate('+(deg*2)+'deg)',
        transform: 'rotate('+(deg*2)+'deg)',
      },
      middle: {
        position: 'absolute',
        width: size-size/4,
        height: size-size/4,
        marginLeft: size/8,
        marginTop: size/8,
        backgroundColor: '#fff',
        borderRadius: '50%',
        textAlign: 'center',
      },
      txt: {
        display: 'block',
        fontWeight: 'bold',
        fontSize: size/5,
        lineHeight: size-size/4+'px',
        color: this.darken(color, 4),
        transition: 'width 1s',
      },
    };

    return (
      <div style={styles.donut}>
        <div style={styles.full}>
          <div style={styles.fill}/>
        </div>
        <div style={styles.half}>
          <div style={styles.fill}/>
          <div style={styles.fill2}/>
        </div>
        <div style={styles.middle}>
          <div style={styles.txt}>{txt}</div>
        </div>
      </div>
    );
  }

};
