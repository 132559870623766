import React, { Component, Fragment } from 'react';
import { Tooltip } from 'react-tippy';
import { TextField, Paper, Checkbox, Grid, Card, FormControlLabel, Switch } from '@material-ui/core';
import { observer } from 'mobx-react';
import RaisedButton from '../../common/RaisedButton';
import TicketManagement from '../TicketManagement/index'
import Dialog from '@common/Dialog';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import CommonPage from '../../common/CommonPage';
import Boll from '@common/Boll';
import { testRoute, filterObj, flexBasis } from '@common/Func';
import Progress from '@common/Progress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@common/List';
import PieChart from '@common/PieChart';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Constants from '@data/Constants';
import Actions from '@data/Actions';

const styles = theme => ({

  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#52d869',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
});


@observer
class DemandmanagementList extends CommonPage {
  constructor() {
    super();
    this.state = {
      module: '',
      company: '',
      tech: '',
      anchorEl: null,
      viewInfo: true,
      idTicket: 0,
      statusAwaiting: []
    };
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  changeTextfield = (value, key) => {
    this.setState({ [key]: value });
  };

  handleRedirect(value) {
    if (value != '' && value != null) {
      this.setState({ page: 'list', projectSelect: value, route: '' });
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentWillMount = () => {
    const action = new Actions();

    action
      .execute('get', Constants.APIEndpoints.STATUS_AWAITING, 'status_awaiting')
      .then((e) => {
        this.setState({ statusAwaiting: e.data });
      })
      .catch((error) => {

      });

  }

  handleTicket = () => {
    this.action.execute('get', this.APIEndpoints.DEMAND + '/tickets/0', 'tickets');
    this.action.execute('get', this.APIEndpoints.DEMAND + '/activity/0', 'activities');
  }

  render() {
    let { language, companies, techs } = this.props;


    /*
    let waitingTickets = [
      language.TICKET_STATUS.IN_PROGRESS,
      language.WAITING + ' ' + language.REQUESTER,
      language.WAITING + ' ' + language.PROVIDER.replace('es', ''),
      language.WAITING + ' ' + language.PROJECT,
      language.WAITING + ' ' + 'GMUD',
      language.WAITING + ' ' + language.DEVELOP
    ];
*/

    let waitingTickets = [];

    this.state.statusAwaiting.map((status) => {
      waitingTickets.push(status.status);
    });





    //console.log(techs.filter(e => e))

    let listCompanies = companies.map((c, i) => (
      <MenuItem value={c.id_company} key={i}>
        {c.name}
      </MenuItem>
    ));

    let listTech = techs.map((t, i) => (
      <MenuItem value={t.id_user} key={i}>
        {t.name}
      </MenuItem>
    ));

    let data = [];
    let col = [
      { key: 'module', label: language.MODULE, style: { width: 50 } },
      { key: 'action', label: language.ACTION, style: { width: 30 } },
      { key: 'status', label: language.STATE, style: { width: 30 } },
      { key: 'id', label: language.ID },
      { key: 'subject', label: language.DESCRIPTION, style: { width: 700 } },
      { key: 'date', label: language.DATE },
      {
        label: language.USER,
        key: 'user',
        style: { width: 300, paddingRight: 0 },
        thConfig: true
      },
      {
        label: language.COMPANY,
        key: 'company',
        style: { width: 100, paddingRight: 0 }
      },
      {
        label: language.WAITING,
        key: 'status_',
        style: { width: 150 },
        thConfig: true
      },

    ];
    this.props.tickets.map(c => {
      if (c.fk_id_tech == this.store.getUserLoged().id &&
        c.progress < 100
      ) {
        data.push({
          module: <div className="imgGsd" style={{ width: 25, height: 25 }} />,
          action: (
            <div>
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.TICKET_DETAIL}</strong>
                  </div>
                }
                position="right"
                theme="dark"
              >
                <div
                  onClick={() =>
                    this.setState({
                      idTicket:
                        { id_tickets: c.id_tickets, real_id: c.real_id }
                    })

                  }
                  className="detailIcon pointer"
                  style={{ width: 25, height: 25 }}
                />
              </Tooltip>
            </div>
          ),

          status: (
            <div className="orderdivpro w-100 ml-1" id={c.progress}>
              <div style={{ textAlign: 'center' }}>{c.progress}%</div>
              <div
                style={{
                  width: `${c.progress}%`,
                  height: '7px',
                  backgroundColor:
                    c.progress > 66
                      ? '#85cc00'
                      : c.progress > 33
                        ? '#ffbf00'
                        : '#ff2e00',
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              />
            </div>
          ),
          id: c.real_id,
          subject: (
            'Ticket: ' + c.subject

          ),
          date: moment(
            c.dt_cad
          ).format('L'),
          user: c.nameuser,
          company: c.namecompany,
          //status_:c.progress==100 ?'':waitingTickets[c.stop_ticket]

          status_: c.status == 'In Progress' ? (['Closed', 'Resolved'].includes(c.status) == true ?
            '' : waitingTickets[c.stop_ticket] + ' ' +
            (c.stop_ticket == 2 ?
              (c.nameprovider ?
                c.nameprovider : '')
              : '')) :
            c.status == "Resolved" ? language.RESOLVED :
              c.status == "Reopened" ? language.REOPENED :
                c.status == "Approve" ? language.IN_APPROVAL :
                  c.status == 'Open' ? language.OPENED :
                    c.status == 'Closed' ? language.CLOSED :
                      c.status == 'Cancelled' ? language.CANCELED :
                        '',


        });
      }
    });

    this.props.activities.map(c => {
      data.push({
        module: (
          <div
            className="projectManIcon pointer"
            style={{ width: 25, height: 25 }}
          />
        ),

        action: (
          <div>
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.GO_TO_ACTIVITY}</strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <div
                onClick={() =>
                  //  this.redirect2('/dash/gp/list', { projectSelect: c.id_project })

                  this.redirect2('/dash/gp/' + c.id_project, {
                    id_project: c.id_project,
                    id_ac: c.id_project_activity,
                    from_demandmanagement: true,
                    projectSelect: { id_project: c.id_project }
                  })


                }
                className="detailIcon pointer"
                style={{ width: 25, height: 25 }}
              />

            </Tooltip>
          </div>
        )
        ,
        status: (
          <div className="orderdivpro w-100 ml-1" id={c.percent}>
            <div style={{ textAlign: 'center' }}>{c.percent}%</div>
            <div
              style={{
                width: `${c.percent}%`,
                height: '7px',
                backgroundColor:
                  c.percent > 66
                    ? '#85cc00'
                    : c.percent > 33
                      ? '#ffbf00'
                      : '#ff2e00',
                borderRadius: '2px',
                transition: 'all .2s ease-out'
              }}
            />
          </div>
        ),
        id: c.id_project_activity,
        subject: (


          'Activity:  ' + c.name

        ),
        date: moment(
          c.dt_cad
        ).format('L')


      });
    });
    const { anchorEl, viewInfo } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;
    return (

      <div id="DemandmanagementList">
        {/*<div id='content-infos' className='mb-3'>
          <header className='d-flex justify-content-end align-items-center px-4'>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  classes={{
                    switchBase: classes.iOSSwitchBase,
                    bar: classes.iOSBar,
                    icon: classes.iOSIcon,
                    iconChecked: classes.iOSIconChecked,
                    checked: classes.iOSChecked,
                  }}
                  disableRipple
                  checked={viewInfo}
                  onChange={()=>{this.setState({viewInfo:!viewInfo})}}
                  value="checkedB"
                />
              }
              label={<span style={{color:'white'}}>+ detalhes</span>}
            />
          </header>
          <Card className={classnames({'content-medium':viewInfo, notView:!viewInfo})} id='body'>
            <Grid container spacing={2}>
              <Grid item xs={2.5} style={{...flexBasis(2.5)}} className='grid-1'>
                <Card className='h-100'>
                  <h3 className='title content-min pb-0'>Total de tickets ABERTOS hoje</h3>
                  <span className='sub-title content-min py-0'>(Visão geral)</span>
                  <List
                    items={[{y:40, color:'#02CBF0'},{y:13, color:'#F0B002'},{y:27, color:'#33ACA0'},{y:43, color:'#F05E02'},{y:123}].map((e,i)=>(
                      {content:(
                        <div key={i} className='content-item-list d-flex align-items-center justify-content-between w-100'>
                          {i==4?(
                            <span className='total'>total</span>
                          ):(
                            <div className='d-flex align-items-center'>
                              <div
                                className='indicator-color'
                                style={{backgroundColor:e.color}}
                              />
                              <span className='ml-2' style={{color:e.color}}>{
                                i==0?
                                  'abertos':
                                i==1?
                                  'em atendimento':
                                i==2?
                                  'resolvidos':
                                i==3?
                                  'fechados':
                                null
                              }</span>
                            </div>
                          )}
                          <span>{e.y}</span>
                        </div>
                      )}
                    ))}
                  />
                </Card>
              </Grid>
              <Grid item xs={2.5} style={{...flexBasis(2.5)}} className='grid-2'>
                <Card className='h-100'>
                  <h3 className='title content-min pb-0'>Total de tickets abertos hoje</h3>
                  <span className='sub-title content-min py-0'>(Por painel)</span>
                  <List
                    className='mt-2'
                    items={[12,12,50,0].map((e,i)=>(
                      {content:(
                        <div key={i} className='content-item-list d-flex align-items-center justify-content-between w-100'>
                          <div>
                            <span>({
                              i==0?
                                'gsd':
                              i==1?
                                'gp':
                              i==2?
                                'os':
                              i==3?
                                'gmud':
                              null
                            })</span>
                            <span>{
                              i==0?
                                'gestão de demandas':
                              i==1?
                                'gestão de projetos':
                              i==2?
                                'ordem de serviço':
                              i==3?
                                'gestão de mudança':
                              null
                            }</span>
                          </div>
                          <span>{e}</span>
                        </div>
                      )}
                    ))}
                  />
                </Card>
              </Grid>
              <Grid item xs={3.5} style={{...flexBasis(3.5)}} className='grid-3'>
                <Card className='h-100'>
                  <h3 className='title content-min pb-0'>Total de horas produtivas</h3>
                  <span className='sub-title content-min py-0'>(Por meses)</span>
                  <Grid container spacing={2} className='mt-2'>
                    <Grid item xs={6}>
                      <List
                        items={[123,124,125,126,127,128].map((e,i)=>(
                          {content:(
                            <div key={i} className='content-item-list d-flex align-items-center justify-content-between w-100'>
                              <span>{language.DATE_LANG.monthLongList[i]}</span>
                              <span>{e}h</span>
                            </div>
                          )}
                        ))}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <List
                        items={[123,124,125,126,127,128].map((e,i)=>(
                          {content:(
                            <div key={i} className='content-item-list d-flex align-items-center justify-content-between w-100'>
                              <span>{language.DATE_LANG.monthLongList[i+6]}</span>
                              <span>{e}h</span>
                            </div>
                          )}
                        ))}
                      />
                    </Grid>
                  </Grid>

                </Card>
              </Grid>
              <Grid item xs={3.5} style={{...flexBasis(3.5)}} className='grid-4'>
                <Card className='h-100'>
                  <h3 className='title content-min pb-0'>Total de horas produtivas</h3>
                  <span className='sub-title content-min py-0'>(Por meses)</span>
                  <List
                    className='mt-2'
                    items={
                      [
                        {content:(
                          <div className='content-item-list d-flex align-items-center justify-content-between w-100'>
                            <span>Total de horas produtivas</span>
                            <span>12314h</span>
                          </div>
                        )},
                        {content:(
                          <Grid container spacing={2} className='pt-3'>
                            <Grid item xs={6} className='d-flex align-items-end'>
                              <div style={{width:'40%'}}>
                                <PieChart percent={43} color='success'/>
                              </div>
                              <div className='ml-2 item-info'>
                                <h3>1460h</h3>
                                <span>Alocado</span>
                              </div>
                            </Grid>
                            <Grid item xs={6} className='d-flex align-items-end'>
                              <div style={{width:'40%'}}>
                                <PieChart percent={67} color='orange'/>
                              </div>
                              <div className='ml-2 item-info'>
                                <h3>344h</h3>
                                <span>Consumido</span>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      ]
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          </Card>
        </div>*/}
        {/*<CommonTable
          AppBarProps={{
            dark:true,
            style:{
              background:'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)'
            }
          }}
          col={[
            {key:'check', label:<Checkbox value='valor-all'/>},
            {key:'date', label:'Data'},
            {key:'hour', label:'Hora'},
            {key:'id', label:'ID'},
            {key:'module', label:'Módulo'},
            {key:'user', label:'Usuário'},
            {key:'description', label:'Descrição'},
            {key:'status', label:'Status'},
            {key:'action', label:'Ação'},
          ]}
          data={[
            {
              check:<Checkbox value='valor'/>,
              date:'20/04/2020',
              hour:'04:56',
              id:'R.36',
              module:'Gestão de Projeto',
              user:'DEV SensrIT',
              description:'Problemas com os campos de compras',
              status: (
                <div className='d-flex align-items-center'>
                  <Progress current={54} background='var(--primary)' hiddenText style={{height:7, width:'100%'}}/>
                  <span className='ml-2' style={{fontSize:10}}>54%</span>
                </div>
              ),
              action:(
                <IconButton
                  aria-label="More"
                  aria-owns={open ? 'long-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  style={{transform: 'rotate(90deg)'}}
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
          ]}
        />*/}
        {/*<Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <i className='fas fa-eye' style={{color:'var(--primary)'}}/>
            <span style={{fontSize:15, color:'var(--primary)'}} className='ml-2'>Visualizar</span>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <i className='fas fa-trash' style={{color:'var(--danger)'}}/>
            <span style={{fontSize:15, color:'var(--primary)'}} className='ml-2'>Excluir</span>
          </MenuItem>
        </Menu>*/}
        {this.state.idTicket != '' ?
          <Dialog
            title={language.ASSOCIATION_TICKETS}
            open={this.state.idTicket != '' ? true : false}
            maxWidth="lg"
            onClose={() => {
              this.store.ticket = null;
              this.setState({ idTicket: '' })
              this.handleTicket()

            }}
          >
            <TicketManagement detail={true}
              id_ticket_detail={this.state.idTicket} noBack></TicketManagement>
          </Dialog> : null}



        <CommonTable
          beforeTitle={null

          }

          col={col}
          data={data}
          rowsPerPageOptions={[100, 250]}
          searchColumn
          orderColumn
        />
      </div>
    );
  }
}

DemandmanagementList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DemandmanagementList);
