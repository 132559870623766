import React, {memo} from 'react';
import {Card} from '@material-ui/core';
import SwitchIos from '@common/SwitchIos';
import {Link} from 'react-router-dom';
import moment from 'moment'

export default memo(({
	dt_start,
	dt_end,
	description,
	onChange,
	checked,
	id,
	status,
	spmodal,
	handleDialog ,
	handleModal,
	noopen,
   acStatus,
   cardTheme,
   redirect
})=>{
	
	let colors = [
		['#01C2B3', '#019FC2'],
		/*['#9E24F0', '#9E24F0'],
		['#92D050', '#33ACA0'],
		['#F66868', '#F66868'],
		['#9E24F0', '#0B85BC'],
		['#F09702', '#F09702'],
		['#01C2B3', '#01C2B3'],
		['#F05E02', '#F0B002'],
		['#F0B002', '#F0B002']*/
	];
	 
	colors = colors[Math.floor(Math.random() * colors.length)];
	return(
		
		<Card  className='SprintCard content-medium' style={{'--cStart':colors[0], '--cEnd':colors[1], '--card':cardTheme}}>
			
			
		 
				<div onClick={()=>window.location.href = `/dash/projectmanagement/sprint/${id}/kanban`} > 
				
					<span className='dt-created'><i style={{color:'white'}} class="far fa-calendar-alt"></i> {moment(dt_start).format('DD/MM')} á {moment(dt_end).format('DD/MM')}	</span> 
				 
				      
				
					<p className='description ellipsis-line camp-3'>{description}</p>
				</div>
		 
			{!noopen?
			<div  className='d-flex align-items-center justify-content-between'>
			
				<div style={{zoom:.75}} className='-ml-2'>
				 <SwitchIos checked={status=='In Progress'?true:false} onChange={onChange}/> 
				{}
				</div>
				
				 <div className="d-flex">
				{ status=='Create'? <div>
				<i class="fas fa-edit"
				
			     onClick={handleModal} 
				style={{color:'white',cursor:'pointer',marginLeft:'40%'}}></i>
			  
				</div>:null}
				{ status=='Create'? <div style={{marginLeft:'15px'}}>
				<i onClick={()=>handleDialog(id,true)}  style={{color:'var(--danger)',cursor:'pointer'}}   class="fas fa-trash"></i>
				</div>:null}</div>
			
			</div>
	   : acStatus}
	 
	</Card>
		
	)
})