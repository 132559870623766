import React, { useState, useEffect } from 'react';
import './List.css';
import TicketUtils from '../../util/TicketUtils';

export default function ({ t, horas, language, req, setRef, stopSlaParams, statusAwaiting, not,statusAwaitingObj }) {
  let [times, setTimes] = useState([]);
  let [seconds, setSeconds] = useState(0);
  let [sla, setSla] = useState({ color: '', time: '' });
  let [sla_stop, setSlaTop] = useState({ color: '', time: '' });
  let [sla_reopen, setSlaReopen] = useState({ color: '', time: '' });

  function set_sla(status_awaiting) {

    let sla_awaiting = true;
   
   let  resultStatus = (statusAwaitingObj || []).find(i=>i.id_status ==t.stop_ticket);
   
     if(resultStatus && resultStatus.id_status>0){
      sla_awaiting = resultStatus.sla;
     }
 

    if (not) {
      t.sla_task = t.config[0].sla_task;
      t.stop_time = t.config[0].stop_time;
      t.stop_ticket = t.config[0].stop_ticket;
      t.time_total_request = t.config[0].time_total_request;
      t.dt_up = t.config[0].dt_up;
    }
    

    setSla(
      (t.stop_ticket == 0|| sla_awaiting == false|| stopSlaParams == true) && (t.status == 'Open' || t.status == 'In Progress' || t.status == 'Reopened')
        ? TicketUtils.calcSla(
          t,
          'ticket',
          language,
          req,
          t.sla_task,
          [],
          [],
          t.stop_time
        )
        :

        TicketUtils.calcSla_(
          t,
          'ticket',
          language,
          req,
          t.sla_task,
          [],
          [],
          t.stop_time
        )
    );
                  
    setSlaTop(
      t.status == 'Closed' || t.status == 'Resolved' ||  t.progress==100 || (t.status == 'In Progress' && sla_awaiting) 
        ? TicketUtils.stopSla(t, [], language, req, t.sla_task)
        : ''
    );
    if (not) {
    delete t.sla_task
    delete t.stop_ticket
    delete t.stop_time
    }
    setSeconds(seconds => seconds + 1);
  }

  useEffect(() => {
    set_sla(statusAwaiting);
    const interval = setInterval(() => {
      set_sla(statusAwaiting);
    }, 3000);
    return () => clearInterval(interval);
  }, [t, statusAwaiting]);

  return t.status == 'Resolved' || t.status == 'Closed' ? (
    <div style={{ width: 80 }}>
      {!not && <div
        style={{
          width: 10,
          height: 10,
          background: sla_stop.color,
          borderRadius: '50%',
          float: 'left',
          marginRight: 5
        }}
      />}
      <span style={{ color: sla_stop.color }} className='sla_time'>
        {sla_stop.time}
      </span>
    </div>

  ) : typeof sla !== 'string' ? (
    <div style={{ width: 80 }}>
      {!not && <div
        style={{
          width: 10,
          height: 10,
          background: sla.color,
          borderRadius: '50%',
          float: 'left',
          marginRight: 5
        }}
      />}
      <span style={{ color: sla.color }} className='sla_time'>
        {sla.time}
      </span>
    </div>
  ) : (
    language.NOTHING_REGISTERED
  );
}
