import "./solicitant.css";
import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { Container, Row, Col } from "react-grid-system";
import CommonForm from "../../common/CommonForm2";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  TextField,
  FormControlLabel,
  Dialog
} from "@material-ui/core";
import RaisedButton from "../../common/RaisedButton";
import AttachFile2 from "@common/AttachFile2";
import CommonPage from "../../common/CommonPage";
import NativeSelect from "@material-ui/core/NativeSelect";
import { MaskTel } from '../../common/Mask'
import Constants from '../../data/Constants';
import Actions from '../../data/Actions';
var crypto = require('crypto')
export default class FormNewUser extends Component {
  constructor() {
    super();
    this.state = {
      company: 0,
      department: 0,
      companies: [],
      departments:[],
      ssoLogin: false
    };
    this.APIRoot = Constants.APIRoot;
    this.APIEndpoints = Constants.APIEndpoints;
    this.action = new Actions();
  }


  decryp(aMsg) {
    try{
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, "");
  let decipher, tRet;
  decipher = crypto.createDecipher("aes-256-cbc", aSecret);
  tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
  tRet += decipher.final("utf8");
  return tRet;
    }catch(e){
      return {}
    }
  
  }

  componentWillMount() {
    this.action.execute(
      "post",
      this.APIEndpoints.COMPANYS_,
      "companies",
      this.props.values
    ).then(v => {

      this.setState({ companies: v.data })
    })


    this.action.execute(
      "get",
      this.APIEndpoints.DEPARTMENT_,
      ""
    ).then(v => {
      this.setState({ departments: v.data })
    })

    if (this.props.values.company) this.setState({ssoLogin: true, company: this.props.values.company})
  }
  onChangeFieldd(value) {

    this.setState({ department: value });
  }

  onChangeFieldc(value) {
    this.setState({ company: value });
  }

  onFormSubmitsave(value) {
    value.id_company = this.state.company;

    value.fk_id_department = this.state.department;
    if (this.state.company > 0 &&
      this.state.department > 0
    ) {
      this.props.onFormSubmit(value);
    }

  }

  render() {
    let {
      handlePage,
      language,
      onOpenSolicitation,
      onDrop,
      values
    } = this.props;
    let company = [];
    let departments = [];
    this.state.companies.map(g => {
      company = JSON.parse(this.decryp(g.token))
    })
    this.state.departments.map(g => {
      departments = JSON.parse(this.decryp(g.token))
    })
 
    let departmentOptions = [];
    let companyOptions = [];

    const email = sessionStorage.getItem('username');
    const userDomain = email?.match(/[^@]+@(.*)/)[1];

    company.filter((company) => company.domain == userDomain).map(c =>
      companyOptions.push({ label: c.name, value: c.id_company })
    );

    if (this.state.company != 0) {
      departmentOptions = [];

      departmentOptions.push(<option value={0}>{"Selecione Um Departamento"}</option>);
     let id=  company
        .filter(c => c.id_company == this.state.company)[0];
 
         departments.filter(b=>b.companies.includes(id?.id_company)).map(obj => {
          departmentOptions.push(
            { label: obj.name, value: obj.id_department }
            // <option value={obj.id_department}>{obj.name}</option>
          );
       });
    }

    let fields = [

      { col: 4, name: "company", label: "Empresa", type: "select", options: companyOptions, required: true /*disabled: this.state.ssoLogin*/ },
      { col: 4, name: "department", label: "Departamento", type: "select", options: departmentOptions, required: true },


      { col: 4, name: "name", label: "Nome", type: "text", required: true, disabled: this.props.values.name },
      {
        col: 4,
        name: "userad",
        label: "Usuário Ad",
        type: "text",
        required: true,
        disabled: true
      },


      {
        col: 4,
        name: "email",
        label: language.EMAIL,
        type: "text",
        required: true,
        disabled: this.state.ssoLogin
      },
      {
        col: 4,
        name: "phone",
        label: "Telefone",
        type: "text",
        // required: true,
        InputProps: {
          inputComponent: MaskTel
        },
      },
      { col: 4, name: "ramal", label: "Ramal", type: "text" }
    ];

    let buttons = { md: 12, label: language.SAVE, primary: true };

    let data = [];

    try {
      Array.from(this.props.files).forEach(g => {
        data.push(
          <Row>
            <Col md={0.1}>
              <div
                className="trashIcon pointer"
                onClick={() => this.props.removeFile(g)}
                style={{ width: 25, height: 25 }}
              >
                {" "}
              </div>
            </Col>
            <Col md={3}>{g.name}</Col>
          </Row>
        );
      });
    } catch (d) { }
    return (
      <Container fluid>
        <div className='my-4 d-flex align-items-center justify-content-center w-100'>
          <img
            src={require('../../../public/images/newLogoSensr.png')}
            alt=""
            style={{
              width: '20%',
              minWidth: 250
            }}
          />
        </div>
        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <Card>
              <h2
                style={{ padding: 20, color: "#030235", textAlign: "center" }}
              >
                {"Informe seus dados, para o primeiro acesso!"}
              </h2>
              {this.props.modallogin == true ? (
                <Dialog
                  paperProps={{
                    zdepth: 0
                  }}
                  modal={true}
                  open={true}
                >
                  <div className="loadGroups">
                    <h3>{"Dados salvos com sucesso, fazer login"}</h3>
                  </div>
                  <div>
                    {" "}
                    <RaisedButton
                      circleButton
                      icon="fas fa-arrow-right"
                      onClick={() => handlePage("login")}
                      label={"Login"}
                      style={{ marginRight: "0.5em" }}
                    />
                  </div>
                </Dialog>
              ) : null}


              <CommonForm
                language={language}
                fields={fields}
                button={buttons}
                values={values}
                onSubmit={value => this.onFormSubmitsave(value)}
                onChangeField={(f, v) => {


                  if (f.name == 'company') {
                    this.setState({ company: v })
                  }
                  if (f.name == 'department') {
                    this.setState({ department: v })
                  }

                }}
                buttons={[
                  <RaisedButton
                    circleButton
                    icon="fas fa-arrow-left"
                    onClick={() => handlePage("login")}
                    label={language.CANCEL}
                    style={{ marginRight: "0.5em" }}
                  />
                ]}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
