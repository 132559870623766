import React, { useState } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import Button from '../../common/Button';
import CommonForm from '../../common/CommonForm2';

export default function ({ selectedYear, handleValue = () => null, language }) {
  let [filterPeriod, setFilterPeriod] = useState(6);
  return (
    <Grid container spacing={2} className="content-header">
      <Grid item xs={6} style={{ zoom: 0.8 }}>
        <CommonForm
          spacing={2}
          individual
          values={{ year: selectedYear }}
          onChangeField={(f, v) => handleValue(f.name, v)}
          fields={[
            {
              col: 5.5,
              type: 'select',
              name: 'year',
              label: language.YEAR,
              options: [
                { value: 2021, label: '2021' },
                { value: 2022, label: '2022' },
              ],
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
