import React, { Component, Fragment, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import './index.css';
import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import Store from '@data/Store';
import Header from '@common/Header';
import RaisedButton from '../../common/RaisedButton';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const formSlaWork = (props) => {
  let { language, host } = Store;
  let { values, setOpen, timezoneOptions } = props;
  const [listWorkhour, setListWorkhour] = useState([]);
  const [values_, setValues_] = useState([]);
  const [textSla, setTextSla] = useState([]);
  const [val, setval] = useState({});

  useEffect(() => {
    if (values.id_workday) {
      setListWorkhour([]);
      setValues_(values);
      loadHours();
    } else {
      setListWorkhour([]);
      setValues_([]);
    }

  }, [timezoneOptions,values.id_workday]);


  const loadHours = () => {
    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/workday/${values.id_workday}/workhour`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }

        let dados = [
          { value: 'monday', label: language.DATE_LANG.dayFullList[1] },
          { value: 'tuesday', label: language.DATE_LANG.dayFullList[2] },
          { value: 'wednesday', label: language.DATE_LANG.dayFullList[3] },
          { value: 'thursday', label: language.DATE_LANG.dayFullList[4] },
          { value: 'friday', label: language.DATE_LANG.dayFullList[5] },
          { value: 'saturday', label: language.DATE_LANG.dayFullList[6] },
          { value: 'sunday', label: language.DATE_LANG.dayFullList[0] },
        ].flatMap((item) => ({
          [item.value]: item.value,
          [item.value + 'wh_start']:
            res.find((i) => i.wh_name == item.value) && moment('01/01/1970 ' + res.find((i) => i.wh_name == item.value).wh_start),
          [item.value + 'wh_end']:
            res.find((i) => i.wh_name == item.value) && moment('01/01/1970 ' + res.find((i) => i.wh_name == item.value).wh_end)
        }));

        let object = Object.assign({}, ...dados);
        object.wk_name = values.wk_name;
        object.wk_holidays = values.wk_holidays;
        object.fk_id_timezone = values.fk_id_timezone;
        setval(object);
      });
  };

  const saveWorkHours = async (obj) => {
    setTimeout(() => {
      if (values_.id_workday || obj.id_workday) {
        [
          { value: 'monday', label: language.DATE_LANG.dayFullList[1] },
          { value: 'tuesday', label: language.DATE_LANG.dayFullList[2] },
          { value: 'wednesday', label: language.DATE_LANG.dayFullList[3] },
          { value: 'thursday', label: language.DATE_LANG.dayFullList[4] },
          { value: 'friday', label: language.DATE_LANG.dayFullList[5] },
          { value: 'saturday', label: language.DATE_LANG.dayFullList[6] },
          { value: 'sunday', label: language.DATE_LANG.dayFullList[0] },
        ].map((item) => {
          if (obj[item.value + 'wh_start'] && obj[item.value + 'wh_start']) {
            let payload = {
              wh_name: item.value,
              wh_start: obj[item.value + 'wh_start'],
              wh_end: obj[item.value + 'wh_end'],
            };
            action
              .execute('post', `${Constants.APIEndpoints.SLA_WORK}/workday/${values_.id_workday || obj.id_workday}/workhour`, '', payload)
              .then((res) => {});
          }
        });
      }
    }, 3000);
  };

  const saveWork = async (obj) => {
    let payload = values_.id_workday == undefined
      ? { wk_name: obj.wk_name, wk_holidays: obj.wk_holidays, fk_id_timezone: obj.fk_id_timezone }
      : {
        wk_name: obj.wk_name,
        wk_holidays: obj.wk_holidays || false,
        id_workday: values_.id_workday,
        fk_id_timezone: obj.fk_id_timezone
      };

    action
      .execute(values_.id_workday ? 'put' : 'post', values_.id_workday ? `${Constants.APIEndpoints.SLA_WORK}/workday/${values_.id_workday}` : `${Constants.APIEndpoints.SLA_WORK}/workday`, '', payload)
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        if (res.id_workday) {
          setValues_(res);
          obj.id_workday = res.id_workday;
          obj.wk_holidays = res.wk_holidays;
        }
        saveWorkHours(obj);
        Store.toggleAlert(true, language.OPERATION_PERFORMED_SUCCESSFULLY, 'success');
        setOpen(false);
      });
  };

  useEffect(() => {
    if (values_.id_workday !== undefined) {
      //loadHours()
    }
  }, [values_.id_workday]);

  let col = [
    {
      key: 'day',
      label: language.DAY,
      style: { width: 10, textAlign: 'center' },
    },
    {
      key: 'start_hour',
      label: language.START_HOUR,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'end_hour',
      label: language.END_HOUR,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'actions',
      label: language.ACTION,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
  ];

  return (
    <React.Fragment>
      {
        <div id={'slaformwork'} style={{ width: '100%', margin: 'auto' }}>
          <CommonForm
            values={val}
            fields={[
              { value: 'cad', label: language.DATE_LANG.dayFullList[1] },
              { value: 'monday', label: language.DATE_LANG.dayFullList[1] },
              { value: 'tuesday', label: language.DATE_LANG.dayFullList[2] },
              { value: 'wednesday', label: language.DATE_LANG.dayFullList[3] },
              { value: 'thursday', label: language.DATE_LANG.dayFullList[4] },
              { value: 'friday', label: language.DATE_LANG.dayFullList[5] },
              { value: 'saturday', label: language.DATE_LANG.dayFullList[6] },
              { value: 'sunday', label: language.DATE_LANG.dayFullList[0] },
            ].flatMap((item) =>
              item.value == 'cad'
                ? [
                  {
                    col: 4,
                    type: 'element',
                    content: (v, h, submit) => <span></span>,
                  },

                  {
                    col: 4,
                    name: 'wk_name',
                    label: language.REPORT_OR_NAME,
                    type: 'text',
                    required: true,
                  },
                  {
                    col: 2,
                    name: 'wk_holidays',
                    label: language.HOLIDAY,
                    type: 'switch',
                  },
                  // {
                  //   col: 2,
                  //   name: 'wk_holidays',
                  //   label: 'Holiday',
                  //   type: 'switch',
                  // },
                  {
                    col: 2,
                    type: 'select',
                    name: 'fk_id_timezone',
                    label: language.TIMEZONE,
                    options: timezoneOptions,
                    required: true,
                  },
                ]
                : [
                  {
                    col: 4,
                    type: 'element',
                    content: (v, h, submit) => (
                      <h3
                        style={{
                          padding: '10px',
                          textAlign: 'center',
                          color: '#6c757d',
                        }}
                      >
                        {item.label}
                      </h3>
                    ),
                  },
                  {
                    col: 4,
                    name: item.value + 'wh_start',
                    label: language.START_HOUR,
                    type: 'time',
                    format: '##:##',
                    change(v) {
                      return v.formattedValue;
                    },
                  },
                  {
                    col: 4,
                    name: item.value + 'wh_end',
                    label: language.END_HOUR,
                    type: 'time',
                    format: '##:##',
                    change(v) {
                      return v.formattedValue;
                    },
                  },
                ]
            )}
            onSubmit={saveWork}
          />
        </div>
      }
    </React.Fragment>
  );
};

export default formSlaWork;
