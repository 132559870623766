import React, { Component } from 'react';


import { Tooltip } from 'react-tippy';
import { TextField } from '@material-ui/core';
import { Paper, Grid, Chip, Avatar } from '@material-ui/core';
import { Select, InputLabel, MenuItem, FormControl, FormControlLabel, Switch } from '@material-ui/core'; // controls
import { Checkbox } from '@material-ui/core';
import { observer } from 'mobx-react';

import RaisedButton from '@common/RaisedButton';

import PageLayout from '@common/PageLayout';
import Help from '@common/components/Help';
import CommonTable from '@common/CommonTable';
import SlaGauge from '../../dashboards/graphs/SlaGauge';
import moment from 'moment';
import TicketUtils from '../../../util/TicketUtils';
import ReactTable from 'react-table';
import classnames from 'classnames';
import AppBar from '@common/AppBar';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm';


import './SideDishList.css';

@observer
export default class ServerList extends Component {

  constructor() {
    super();
    this.state = {
      companyId: 1,
      fk_id_device: 0,
      idx: {
        id: null,
        view: false
      },
      addServer: false,
      openbackup: false,
      itensArray: []
    };
    this.saveBack = this.saveBack.bind(this);
    this.saveBackModal = this.saveBackModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalView = this.toggleModalView.bind(this);
  }
  handleCompany(values) {

    this.setState({ companyId: values == 0 ? '' : values });
  }
  toggleModal = (id, device, view = false) => {
    var idx = this.state.idx;
    idx.id = id;
    idx.view = view;


    let fk_id_device = device
    this.setState({ idx, fk_id_device })



  }
  toggleModalView = (e) => {

    this.setState({ openbackup: true, itensArray: e })



  }




  toggleModalAddServer = (open) => {
    this.setState({ addServer: open, openbackup: false })
  }

  saveBack(values) {
    values.fk_id_company = this.state.companyId;
    this.props.onFormSubmit(values);
  }
  saveBackModal(values) {
    values.fk_id_company = this.state.companyId;
    values.fk_id_device = this.state.fk_id_device;
    this.props.onFormSubmit(values);
  }
  render() {


    var date = moment().add(7, 'days');
    var begin = moment(date).startOf('week').isoWeekday(1);

    let { language, companies, devices, backups, user, usercon_company } = this.props;
    let { idx, open, addServer } = this.state;

    let c = 1;




    try {
      let listCompanies = companies.map(c => (
        <MenuItem key={c.id_company} value={c.id_company} primaryText={c.name} >
          {c.name}
        </MenuItem>
      ));


      if (user.role == 'tech') {

        try {
          listCompanies =
            companies.filter(g => usercon_company[0].company.includes(g.id_company)).map(c => ({
              value: c.id_company,
              text: c.name
            }));
        } catch (t) {
          listCompanies = [];
        }
      }





      let col = [
        { label: language.SERVERS, key: 'servers' },
        { label: language.DATE_LANG.dayList[1], key: 'day1' },
        { label: language.DATE_LANG.dayList[2], key: 'day2' },
        { label: language.DATE_LANG.dayList[3], key: 'day3' },
        { label: language.DATE_LANG.dayList[4], key: 'day4' },
        { label: language.DATE_LANG.dayList[5], key: 'day5' },
        { label: language.DATE_LANG.dayList[6], key: 'day6' },
        { label: language.DATE_LANG.dayList[0], key: 'day0' },
      ];

      let colback = [
        { label: language.LOG, key: 'description' },
        { label: language.STATE, key: 'success' },
        { label: language.TYPE, key: 'type_backup' },
        { label: language.MODE, key: 'modo' },
      ];

      var itemDay = (e, semana, backups, iddevice) => {



        let countsucess = 0;
        let counterro = 0;

        /**attachment: null
      description: "<p>ee</p>"
      dt_back: "2019-09-12T12:22:00.000Z"
      dt_cad: "2019-09-12T13:22:38.741Z"
      first: false
      fk_id_company: 1
      fk_id_device: 22
      id_backup: 26
      modo: 1
      name: "12313"
      success: 1
      type_backup: 1
      user_cad: null */


        var date = moment().add(7, 'days');
        var begin = moment(date).startOf('week').isoWeekday(semana);
        begin.format('DDMMYYYY')
        let dataArray = [];
        try {

          backups.filter(c => moment(c.dt_back).format('DDMMYYYY') ===
            begin.format('DDMMYYYY') && c.fk_id_device === iddevice).map(c => {
              dataArray.push({
                description:

                  <p
                    style={{ color: '#888080' }}

                    dangerouslySetInnerHTML={{ __html: c.log }}
                  />
                ,



                success: c.success == 1 ? 'Sucesso' : 'Error', type_backup: c.type_backup == 1 ? 'Backup' : 'Recovery',
                modo: c.modo == 1 ? 'Full' : 'Incremental'
              })

              if (c.success === 1) {
                countsucess++
              } else {
                counterro++
              }
            })
        } catch (e) { }
        if (countsucess > 0 || counterro > 0) {

          return (
            <div className='d-flex align-items-center'>
              <div
                className={classnames(!counterro ? 'success' : 'danger', 'circleAlert')}
              >{!counterro ? countsucess : counterro} </div>

              <div>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.VISUALIZE}</strong>
                    </div>
                  }

                  arrow={true}
                  position={"right"}
                  theme="dark"

                >
                  <div className="eyeIcon pointer" style={{ width: 25, height: 25 }} onClick={() => this.toggleModalView(dataArray, true)}></div>
                </Tooltip>

              </div>
            </div>
          )
        } else {
          return null;
        }

      }






      let data = backups.filter(f => f.first == true).map(e => ({


        servers: (
          <div className='d-flex align-items-center justify-content-between'>
            <span>{e.name} {e.id_backup}</span>
            <div>
              <RaisedButton
                label={language.OPEN}
                icon='fas fa-plus'
                circleButton
                style={{
                  fontSize: 12,
                  width: 20,
                  height: 20
                }}
                onClick={() => this.toggleModal(e.id_backup, e.fk_id_device)}
              />
            </div>
          </div>
        ),


        day1: itemDay(e.id_backup, 1, backups, e.fk_id_device),
        day2: itemDay(e.id_backup, 2, backups, e.fk_id_device),
        day3: itemDay(e.id_backup, 3, backups, e.fk_id_device),
        day4: itemDay(e.id_backup, 4, backups, e.fk_id_device),
        day5: itemDay(e.id_backup, 5, backups, e.fk_id_device),
        day6: itemDay(e.id_backup, 6, backups, e.fk_id_device),
        day0: itemDay(e.id_backup, 7, backups, e.fk_id_device)

      }));

      // Adicionar Servidor


      let devicesList = devices.map(c => ({ value: c.id_device, text: c.name }));




      var formAddServer = [
        {
          span: 12,
          name: 'fk_id_device',
          label: language.SERVERS.replace('es', ''),
          type: 'select',
          options: devicesList,
          required: true
        }
      ]

      /**
       *   `${data.fk_id_company}, ${data.fk_id_device}, ${user.id},${data.success},
         ${data.type_backup} ,${data.modo}, '${data.description}',false `,
       */
      var formStatus = [
        {
          span: 6,
          name: 'success',
          label: language.SUCCESS,
          type: 'select',
          options: [
            { value: 0, text: '' },
            { value: 1, text: language.YES },
            { value: 2, text: language.NOT },
          ],
          required: true,

        },
        {
          span: 6,
          name: 'dt_back',
          label: language.DAY,
          type: 'date',

          required: true,
          inputProps: {
            readOnly: idx.view
          }
        },
        {
          span: 6,
          name: 'type_backup',
          label: language.TYPE,
          type: 'select',
          options: [
            { value: 'backup', text: 'Backup' },
            { value: 'recovery', text: 'Recovery' },
          ],
          required: true,
          inputProps: {
            readOnly: idx.view
          }
        },
        {
          span: 6,
          name: 'modo',
          label: language.MODO,
          type: 'select',
          options: [
            { value: 'full', text: 'Full' },
            { value: 'incremental', text: 'Incremental' },
          ],
          required: true,
          inputProps: {
            readOnly: idx.view
          }
        },
        {
          span: 12,
          name: 'description',
          label: language.LOG,
          type: 'textEditor',
          multiLine: true,
          disabled: idx.view
        }
      ]
      let button = {
        md: 2,
        offset: { md: 10 },
        label: language.SAVE,
        primary: true,
        style: idx.view ? { display: 'none' } : {}
      };


      var values = idx.view ? {
        success: true,
        type: 'backup',
        day: (new Date()),
        mode: 'full',
        log: 'Olá mundo'
      } : null


      let dayOk = 0;
      let dayNg = 0;
      let weekOk = 0;
      let weekNg = 0;
      let monthOk = 0;
      let monthNg = 0;


      var date = moment().add(7, 'days');


      if (backups != undefined) {
        backups.filter(c => moment(c.dt_back).format('DDMMYYYY') ===
          moment().format('DDMMYYYY') && c.first == false).map(c => {

            if (c.success === 1) {
              dayOk++
            } else {
              dayNg++
            }
          })




        backups.filter(c => moment(c.dt_back).format('YYMMDD') >=
          moment(date).startOf('week').isoWeekday(0).format('YYMMDD') &&
          moment(c.dt_back).format('YYMMDD') <=
          moment(date).startOf('week').isoWeekday(7).format('YYMMDD')
          && c.first == false).map(c => {

            if (c.success === 1) {
              weekOk++
            } else {
              weekNg++
            }
          })


        backups.filter(c => moment(c.dt_back).format('MMYYYY') ===
          moment().format('MMYYYY') && c.first == false).map(c => {

            if (c.success === 1) {
              monthOk++
            } else {
              monthNg++
            }
          })

      }





      return (
        <PageLayout
          icon="backupManagementIcon"
          title={language.BACKUP_SIDE_DISH}
          style={{ width: 1400, zoom: 0.8, marginTop: '1rem' }}
          rightElements={[

            <TextField
              id="outlined-select-currency"
              select
              label={language.COMPANY}
              style={{ minWidth: 200, marginLeft: '1em', marginRight: '1em' }}
              value={this.state.companyId}
              onChange={(event, key, values) => this.handleCompany(event.target.value)}
              margin="dense"
              variant="outlined"
            >
              {listCompanies}
            </TextField>,
            <RaisedButton
              circleButton
              icon='fas fa-arrow-left'
              color='primaryGradient'
              label={language.BACK}
              primary={true}
              onClick={() => redirect('/dash/gsd')}
            />

          ]}
        >
          <div id='SideDishList'>
            <Grid container spacing={3} className='mt-5'>
              <Grid item xs={4}>
                <Paper>
                  <AppBar light className='font' style={{ textTransform: 'uppercase' }}>{language.DAY}</AppBar>
                  <div className='content d-flex justify-content-center'>
                    <div className="content-boll" style={{ '--content--color': 'var(--success)' }}>
                      <div className="boll">
                        <div className="body-boll">
                          <span className="font" color={{ style: '#616161' }}>{dayOk}</span>
                        </div>
                      </div>
                    </div>
                    <div className="content-boll" style={{ '--content--color': 'var(--danger)' }}>
                      <div className="boll">
                        <div className="body-boll">
                          <span className="font" color={{ style: '#616161' }}>{dayNg}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <AppBar light className='font' style={{ textTransform: 'uppercase' }}>{language.WEEK}</AppBar>
                  <div className='content d-flex justify-content-center'>
                    <div className="content-boll" style={{ '--content--color': 'var(--success)' }}>
                      <div className="boll">
                        <div className="body-boll">
                          <span className="font" color={{ style: '#616161' }}>{weekOk}</span>
                        </div>
                      </div>
                    </div>
                    <div className="content-boll" style={{ '--content--color': 'var(--danger)' }}>
                      <div className="boll">
                        <div className="body-boll">
                          <span className="font" color={{ style: '#616161' }}>{weekNg}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <AppBar light className='font' style={{ textTransform: 'uppercase' }}>{language.MONTH}</AppBar>
                  <div className='content d-flex justify-content-center'>
                    <div className="content-boll" style={{ '--content--color': 'var(--success)' }}>
                      <div className="boll">
                        <div className="body-boll">
                          <span className="font" color={{ style: '#616161' }}>{monthOk}</span>
                        </div>
                      </div>
                    </div>
                    <div className="content-boll" style={{ '--content--color': 'var(--danger)' }}>
                      <div className="boll">
                        <div className="body-boll">
                          <span className="font" color={{ style: '#616161' }}>{monthNg}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>

            </Grid>
            <CommonTable

              beforeTitle={
                <RaisedButton
                  circleButton
                  icon='fas fa-plus'
                  label={language.ADD}
                  onClick={() => this.toggleModalAddServer(true)}
                />
              }

              data={data}
              col={col}
            />
            <Dialog
              open={idx.id !== null}
              title={!idx.view ? (language.ADD + ' ' + language.EVIDENCES) : language.EVIDENCES}
              onClose={() => this.toggleModal(null)}
            >
              <CommonForm
                language={language}
                button={button}
                values={values}
                onFormSubmit={this.saveBackModal}
                fields={formStatus}
              />
            </Dialog>

            <Dialog
              title={language.ADD + ' ' + language.SERVERS.replace('es', '')}
              open={addServer}
              onClose={() => this.toggleModalAddServer(false)}
              maxWidth='xs'
              monitor_scroll
            >
              <CommonForm
                language={language}
                button={button}
                onFormSubmit={this.saveBack}
                fields={formAddServer}
              />
            </Dialog>

            <Dialog
              title={'Backups'}
              open={this.state.openbackup}
              onClose={() => this.toggleModalAddServer(false)}
              maxWidth='xs'
              maxWidth='400px'
              PaperProps={{
                style: {
                  height: '50%'
                }
              }}
            >
              <CommonTable



                data={this.state.itensArray}
                col={colback}
              />




            </Dialog>



          </div>

        </PageLayout>
      );
    } catch (e) {
      return (null)
    }




  }
}
