import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import './gestaoRisco_dash.css';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';

// -----------------------select-------------------------
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// -----------------------------------------------------
// import RenderizingChart from './script.gestaoRisco_dash.js'

import { Grid, Card } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import ButtonGroupFilter from '@common/ButtonGroupFilter';
import ContainerChart from './gestaoRisco_dash_config.js';
import ReactHighcharts from 'react-highcharts';
import CommonPage from '../../common/CommonPage';
export class Grap extends Component {
  render() {
    let { language, row, link } = this.props;
    if (link !== '') {
      return (
        <React.Fragment>
          {this.props.grap.map((e, i) => (
            <Grid item xs={4} key={i}>
              <Card>
              	<div onClick={()=>this.redirect(link)} >

                  <AppBar title={e.title} />
                  <div className='content'>
                    <ReactHighcharts config={ContainerChart.container1(e, language)} />
                  </div>
                  </div>
              </Card>
            </Grid>
          ))}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.props.grap.map((e, i) => (
          <Grid item xs={4} key={i}>
            <Card>
              <AppBar title={e.title} />
              <div className='content'>
                <ReactHighcharts config={ContainerChart.container1(e, language)} />
              </div>
            </Card>
          </Grid>
        ))}
      </React.Fragment>
    );
  }
}
class GestaoRisco_dash extends CommonPage {
  state = {
    age: '',
    name: 'hai',
    labelWidth: 0,
    company: 1,
    grap: [],
    teia: [],
    grapSelectedIndex: 0
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.RISK + '/dash/' + this.props.risk + '/company/' + this.props.companyId, 'risks_test');
    this.action.execute(
      'get',
      this.APIEndpoints.RISK + '/maturity/' + 1 + '/stand/' + this.props.risk,
      'maturity'
    );

    // RenderizingChart(this.props.data);
    this.action
      .execute('get', `${this.APIEndpoints.RISK}/GetDashDetailMaturity/${this.props.companyId}`, '')
      .then(e => e.json()).then(results => {
        if( results.data ) {
          results = results.data;
        }

        if (!!results.length) {
          var data = {};
          var result;
          var count;
          var to_char;
          for (var i in results) {
            result = results[i];
            count = parseInt(result.count);
            to_char = result.to_char

            if (!!!data[result.name]) {
              data[result.name] = { current_maturity: [], planned_maturity: [], to_char: [] };
            }

            data[result.name].current_maturity.push(parseInt(result.current_maturity) || 0);
            data[result.name].planned_maturity.push(parseInt(result.planned_maturity) || 0);
            data[result.name].to_char.push(to_char);
          }

          data = Object.keys(data).map(e => { return { title: e, ...data[e] } });

          this.setState({ grap: data });
        }
      });
  }

  selectGrap = (index) => {
    let r = 0;
    if (index.label == 'LGPD') {
      r = 6
    } else if (index.label == 'ISO 27001') {
      r = 1
    } else if (index.label == 'ISO 9001') {
      r = 7
    }
    else if (index.label == 'GESTÃO DE BACKUP') {
      r = 4
    } else if (index.label == 'GESTÃO DE EVOLUÇÃO DE TI') {
      r = 5
    } else if (index.label == 'COBIT') {
      r = 3
    } else if (index.label == 'ISO 20000') {
      r = 2
    } else if (index.label == 'ISO 37001') {
      r = 8
    }

    this.action
      .execute('get', this.APIEndpoints.RISK + '/dash/' + r + '/company/' + this.props.companyId, 'risks_test');

    this.action
      .execute(
        'get',
        this.APIEndpoints.RISK + '/maturity/' + this.props.companyId + '/stand/' + r,
        'maturity'
      );

    this.setState({ grapSelectedIndex: index.index })
  }
  render() {
    let { language } = this.props;
    let { grap, grapSelectedIndex } = this.state;
    let { risks_test, maturity } = this.store;

    let tests = risks_test;

    let dttt = [];
    let sumlinha = 0;
    let sumTotalmat = 0;
    tests[0].dash.map((t, i) => {

      maturity.filter(v => v.id_section == t.id).map(g => {
        sumlinha = sumlinha + parseInt(g.totallinha);
        if (g.current_maturity === null) {
          g.current_maturity = 0;
        }
        sumTotalmat = sumTotalmat + parseInt(g.current_maturity);

        dttt.push({
          subject: t.area, A: parseInt((g.current_maturity / g.total) * 100),
          B: parseInt((g.planned_maturity / g.total) * 100), fullMark: g.planned_maturity
        })
      })

    })

    sumlinha = (sumTotalmat / sumlinha).toFixed(1)
    var polar = {
      chart: {
        polar: true,
        type: 'area',
        height: 800
      },

      title: {
        text: language.MATURITY,
      },
      subtitle: {
        text: ''
      },

      pane: {
        size: '80%'
      },

      xAxis: {
        categories: dttt.map(e => e.subject),
        tickmarkPlacement: 'on',
        lineWidth: 0
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true,
        valueSuffix: '%'
      },

      series: [

        {
          name: language.PLANNED_MATURITY,
          data: dttt.map(e => !!e.B ? parseInt(e.B) : 0),
          pointPlacement: 'on',
          color: '#efa824'
        },
        {
          name: language.CURRENT_MATURITY,
          data: dttt.map(e => !!e.A ? parseInt(e.A) : 0),
          pointPlacement: 'on',
          color: '#009dc0'
        }
      ]
    }



    return (

      <div id="gestaoRisco_dash" className='-mt-3'>
        <Card>
          <AppBar
            title={language.RISKS_MANAGEMENT_COMPLIANCE}
          >
            <div style={{ position: 'relative', top: 5 }}>
              <ButtonGroupFilter
                items={[
                  language.OVERVIEW,
                  ...grap.map(e => e.title)
                ]}
                itemsProps={{
                  className: 'mx-3',
                  style: { fontSize: 10 }
                }}
                onClick={this.selectGrap}
                active={grapSelectedIndex}
              />
            </div>
          </AppBar>
          <div className='content'>
            {grapSelectedIndex == 0 ? (
              <Grid container spacing={3}>
                <Grap grap={grap} language={language} />
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <ReactHighcharts config={polar} />
                </Grid>
                <Grid item xs={6}>
                  <Card className='w-100 mb-3 content'>
                    <ReactHighcharts config={ContainerChart.container1(grap[grapSelectedIndex], language, true)} />

                  </Card>

                </Grid>
              </Grid>
            )}
          </div>
        </Card>
      </div>
    );
  }
}
export default GestaoRisco_dash;
