import React, {useState, useEffect} from 'react';

import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import {flexBasis} from '@common/Func';
import {Grid, Chip} from '@material-ui/core';
import classnames from 'classnames';
import Store from '@data/Store';

const { language } = Store;

export default function({
   h, 
   options,
   tagOptions,
   setTagOptions,
   tagValues,
   setTagValues,
   onRef
}){
	
	let [form, setForm] = useState(null);
	let [view, setView] = useState(0);

	
	return(
		<Grid container spacing={2}>
		  <Grid item xs={5} className='-ml-3' style={{overflow:'hidden'}}>
		    <div className={classnames('content-carousel', {active:view})}>
		      <div className='carousel-item content-medium pt-0'>
		      	<Button
		      		icon='fas fa-arrow-right'
							label={language.CREATE_TAG}
		      		color='success'
		      		variant='normal'
		      		fluid
		      		className='mb-2'
		      		onClick={() => setView(1)}
		      	/>
		        <CommonForm
		          onRef={ref => {
                   setForm(ref);
                   onRef(ref);
                }}
		          values={{
		          	tags:tagValues
		          }}
		          fields={[
		            {
		              type:'listCheckbox',
		              name:'tags',
                    label:language.TAG,
                    required:true,
		              options:tagOptions.map(e=>({
		              	value:e.value,
		              	label:(
		              	  <li className='d-flex align-items-center'>
		              	    <div className='mr-1' style={{backgroundColor:e.color}}></div>
		              	    {e.label}
		              	  </li>
		              	)
		              }))
		            }
		          ]}
		          onChangeField={(f, v)=>{
                  setTagValues(v);
		          	h(v, 'tags');
		          }}
		        />
		      </div>
		      <div className='carousel-item content-medium pt-0'>
		      	<Button
		      		icon='fas fa-arrow-left'
							label={language.BACK}
		      		color='success'
		      		variant='normal'
		      		fluid
		      		className='mb-3'
		      		onClick={() => setView(0)}
		      	/>
		        <CommonForm
		          clean
		          fields={[
		            {
		              type:'text',
		              name:'name',
									label: language.TAG_NAME,
		              required:true,
		            },
		            {
		              type:'element',
		              name:'color',
		              required:true,
		              content:(v, h)=>(
		                <Grid
		                  container
		                  spacing={2}
		                  className="content-colors-tags mb-2 -mt-2"
		                >
		                  {[
		                    '#9E24F0',
		                    '#F00D82',
		                    '#F05E02',
		                    '#F0B002',
		                    '#92D050',
		                    '#02F0DE',
		                    '#02CBF0',
		                    '#00008B',
		                    '#708090',
		                    '#2F4F4F',
		                    '#8B4513',
		                    '#8B0000',
		                    '#D8BFD8',
		                    '#4682B4'].map((e, i) => (
		                    <Grid
		                      key={i}
		                      item
		                      xs={1.71428571}
		                      style={{ ...flexBasis(1.71428571) }}
		                    >
		                      <div
		                        onClick={() => h(e, 'color')}
		                        className="item"
		                        style={{ backgroundColor: e, cursor: 'pointer' }}
		                      >
		                        {v==e && (
		                          <i
		                            style={{ color: 'white', marginLeft: '3px' }}
		                            class="fas fa-check"
		                          />
		                        )}
		                      </div>
		                    </Grid>
		                  ))}
		                </Grid>
		              )
		            }
		          ]}
		          onSubmit={v => {
		          	setTagOptions(t=>[...t, {label:v.name, color:v.color, value:v.name}])
		          }}
		          button={{
		          	label: language.ADD
		          }}
		        />
		      </div>
		    </div>
		  </Grid>
		  <Grid item xs={7}>
		    <div id='content-tag' className='content px-1 py-1'>
		     {tagValues.map(e=>{
		     	let tag = tagOptions.find(t => t.value==e);
		     	return (
		     		<Chip
		     			key={e}
		     			style={{backgroundColor:tag.color, color:'white'}}
		     			onDelete={()=>{
		     				form.handleValue(tagValues.filter(t=>t!=e), 'tags');
		     			}}
		     			label={tag.label}
		     			className='mx-1 my-1'
		     		/>
		     	)
		     })}
		    </div>
		  </Grid>
		</Grid>
	)
}