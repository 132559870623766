import React, {Fragment, memo, useState} from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import List from '@common/List';
import Progress from '@common/Progress';
// ------------------------material-ui------------------------------------
import {Card, Grid} from '@material-ui/core';

import ActivitiesList from '../Dialog/ActivitiesList';
import withLanguage from '../../../../common/languages/withLanguage';
/* 
----------------------------format----------------------------
let info = {
      data:[
         {label:'Backlog', value:25, color:'var(--default)'},
         {label:'A Fazer', value:25, color:'var(--warning)'},
         {label:'Fazendo', value:25, color:'var(--primary)'},
         {label:'Impeditivo', value:25, color:'var(--danger)'},
         {label:'Validação', value:25, color:'var(--purple)'},
         {label:'Finalizado', value:25, color:'var(--success)'},
      ],
      percent:35
   }

*/

export default memo(withLanguage(({page, data=[], percent=0, activitiesListData=[], language}) => {

   let [open, setOpen] = useState(false);
   
   let sum = data.reduce((n, e) => n+e.value, 0);
   return(
      <Fragment>
         <ActivitiesList 
            open={open}
            onClose={() => setOpen(false)}
            data={activitiesListData}
         />
      
         <Card id='ActivitiesInvolved'>
            <AppBar title={language.ACTIVITIES_RELATED_TO_PROJECT_YOU_INVOLVED_IN} subTitle={`(${language.IN_THE_YEAR})`}>
                  <i className='fas fa-eye btn-view-detail' onClick={() => setOpen(true)}/>
            </AppBar>
            <div className='content-medium pt-0'>
               <Grid container spacing={2}>
                  <Grid item xs={6}>
                     <h1 style={{fontSize:sum>=100?70:114}}>{sum}</h1>
                     <div className='d-flex align-items-center content-progress -mt-4'>
                        <Progress hiddenText style={{width:'100%', height:8}} background='var(--success)' current={percent}/>
                        <span>{parseInt(percent)}%</span>
                     </div>
                  </Grid>
                  <Grid item xs={6}>
                        <List
                           className='list'
                           hover={false}
                           items={data.map(e=>({
                              className:'d-flex justify-content-between',
                              style:{'--color':e.color, padding:8},
                              content:(
                                 <Fragment>
                                    <div className='indicator'/>
                                    <span className='label'>{e.label}</span>
                                    <span className='value'>{e.value}</span>
                                 </Fragment>
                              )
                           }))}
                        />
                  </Grid>
               </Grid>
            </div>
         </Card>
      </Fragment>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))