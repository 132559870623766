import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import ServicePortalCatalog from './ServicePortalCatalog';
import ServicePortalCatalogItems from './ServicePortalCatalogItems';
import ServicePortalCatalogMostUsed from './ServicePortalCatalogMostUsed';
import ServicePortalWrapper from './ServicePortalWrapper';
import { EmptyGrid } from './components/EmptyState';
import { ServicePortalSubHeader } from './components/ServicePortalSubHeader';
import { parseJwt, sortByName } from './helpers';

export default function ServicePortalContainer({
  language,
  isMultiCatalog,
  allowCustomizePriority,
  isIntegrationEnabled,
  enableLocationInOpeningTicket,
}) {
  const fetch = new Actions();
  const tokenData = parseJwt(sessionStorage.getItem('token'));
  const [companies, setCompanies] = useState([]);

  const catalogDefaultValue = {
    firstLevel: undefined,
    secondLevel: undefined,
    thirdLevel: undefined,
    subcategory: undefined,
    task: undefined,
  };
  const [catalogSelected, setCatalogSelected] = useState(catalogDefaultValue);

  const [catalogMostUsed, setCatalogMostUsed] = useState();

  const TYPE_STATE = {
    NONE: 'None',
    LOADING: 'Loading',
    COMPLETE: 'Complete',
    FAIL: 'Failed',
  };
  const [fetchState, setFetchState] = useState(TYPE_STATE.NONE);

  useEffect(async () => {
    if (window.location.hash) extractRoute();

    if (isMultiCatalog !== undefined) {
      isMultiCatalog ? await getCompanies() : await getSingleCatalog();
    }

    if (fetchState === TYPE_STATE.NONE && catalogMostUsed === undefined) {
      await getMostUsed();
    }
  }, []);

  function getRoute(type) {
    let currentHash = window.location.hash;
    const hasHash = currentHash.indexOf('#') > -1;
    let valueFromHash = '';

    if (!hasHash) return null;
    currentHash = currentHash.replace('#', '');
    const values = currentHash.split(/\|(?=[^\|]+:)/);
    //const getValue = (hash, expression) => hash.split(expression).shift().split(':').pop();
    const getValue = (expression) =>
      values
        .find((item) => item.startsWith(expression))
        ?.split(':')
        .pop();

    switch (type) {
      case 'firstLevel':
        valueFromHash = getValue('fl:');
        break;
      case 'secondLevel':
        valueFromHash = getValue('sl:');
        break;
      case 'thirdLevel':
        valueFromHash = getValue('tl:');
        break;
      case 'subcategory':
        valueFromHash = getValue('sc:');
        break;
      case 'task':
        valueFromHash = getValue('t:');
        break;
    }

    return valueFromHash;
  }

  function setRoute(type, value, forceRoute = false) {
    const currentHash = window.location.hash;
    const hasHash = currentHash.indexOf('#') > -1;
    const currentValue = getRoute(type);
    let hashParts = [];

    switch (type) {
      case 'firstLevel':
        (forceRoute || currentValue != value) && hashParts.push('fl:' + value);
        break;
      case 'secondLevel':
        if (forceRoute || currentValue != value) {
          hashParts.push(currentHash.split('|sl:')[0]);
          hashParts.push('sl:' + value);
        }
        break;
      case 'thirdLevel':
        if (forceRoute || currentValue != value) {
          hashParts.push(currentHash.split('|tl:')[0]);
          hashParts.push('tl:' + value);
        }
        break;
      case 'subcategory':
        if (forceRoute || currentValue != value) {
          hashParts.push(currentHash.split('|sc:')[0]);
          hashParts.push('sc:' + value);
        }
        break;
      case 'task':
        if (forceRoute || currentValue != value) {
          hashParts.push(currentHash.split('|t:')[0]);
          hashParts.push('t:' + value);
        }
        break;
    }

    if (type !== 'reset') {
      if (hashParts.length > 0) window.location.hash = `${hashParts.join('|')}`;
    } else if (hasHash) window.location.hash = '';
  }

  function extractRoute() {
    const firstLevelRouteValue = getRoute('firstLevel');
    const secondLevelRouteValue = getRoute('secondLevel');
    const thirdLevelRouteValue = getRoute('thirdLevel');
    const subcategoryRouteValue = getRoute('subcategory');
    const taskRouteValue = getRoute('task');

    setCatalogSelected({
      firstLevel: firstLevelRouteValue,
      secondLevel: secondLevelRouteValue,
      thirdLevel: thirdLevelRouteValue,
      subcategory: subcategoryRouteValue,
      task: taskRouteValue,
    });

    // console.log('set-catalog-from_route',
    //     {
    //         firstLevel: firstLevelRouteValue,
    //         secondLevel: secondLevelRouteValue,
    //         thirdLevel: thirdLevelRouteValue,
    //         subcategory: subcategoryRouteValue,
    //         task: taskRouteValue,
    //     })
  }

  function handleCatalogSelection(type, value, forceRoute = false) {
    if (
      ![
        'firstLevel',
        'secondLevel',
        'thirdLevel',
        'subcategory',
        'task',
      ].includes(type) ||
      !value
    )
      return;

    setRoute(type, value, forceRoute);

    if (type === 'firstLevel') {
      setCatalogSelected((prev) => ({
        ...catalogDefaultValue,
        firstLevel: value,
      }));
    } else if (type === 'secondLevel') {
      setCatalogSelected((prev) => ({
        ...catalogDefaultValue,
        firstLevel: prev.firstLevel,
        secondLevel: value,
      }));
    } else if (type === 'thirdLevel') {
      setCatalogSelected((prev) => ({
        ...catalogDefaultValue,
        firstLevel: prev.firstLevel,
        secondLevel: prev.secondLevel,
        thirdLevel: value,
      }));
    } else if (type === 'subcategory') {
      setCatalogSelected((prev) => ({
        ...catalogDefaultValue,
        firstLevel: prev.firstLevel,
        secondLevel: prev.secondLevel,
        thirdLevel: prev.thirdLevel,
        subcategory: value,
      }));
    } else if (type === 'task') {
      setCatalogSelected((prev) => ({
        ...catalogDefaultValue,
        firstLevel: prev.firstLevel,
        secondLevel: prev.secondLevel,
        thirdLevel: prev.thirdLevel,
        subcategory: prev.subcategory,
        task: value,
      }));
    }
  }

  async function getCompanies() {
    const urlCompanies = `${Constants.APIEndpoints.COMPANY}/all?limit=150&offset=0`;
    await fetch
      .execute('post', urlCompanies, '', { catalog: true })
      .then(async ({ data }) => {
        let mountCompanies = data?.map((company) => ({
          id: company.id_company,
          name: company.name,
          enable_catalog_grouping: company.enable_catalog_grouping,
          secondLevel: [],
        }));

        Array.isArray(mountCompanies) && mountCompanies.sort(sortByName());
        setCompanies(mountCompanies);

        const firtLevelValue = getRoute('firstLevel');
        const foundCompanyIndexByRouteValue = mountCompanies.findIndex(
          (item) => item.id == firtLevelValue
        );
        if (foundCompanyIndexByRouteValue > -1)
          await handleSelectCompany(
            mountCompanies[foundCompanyIndexByRouteValue],
            mountCompanies
          );
        else if (mountCompanies?.length === 1)
          await handleSelectCompany(mountCompanies[0], mountCompanies);
      });
  }

  async function getSingleCatalog() {
    let ownerCompany = {
      id: tokenData.iss.id,
      name: tokenData.iss.name,
      isSelected: true,
      secondLevel: [],
    };
    const urlCompanyCatalog = `${Constants.APIEndpoints.CATALOG}/company/${ownerCompany.id}/?limit=75&offset=0`;
    await fetch.execute('post', urlCompanyCatalog).then(({ data }) => {
      let firstLevel = data?.map((department) => {
        let secondLevel = department.catalog?.map((category) => {
          let thirdLevel = category.task?.map((task) => ({
            id: task.id_catalog_task,
            name: task.name,
            icon: task.icon,
          }));
          Array.isArray(thirdLevel) && thirdLevel.sort(sortByName());

          return {
            id: category.id_catalog_service,
            name: category.name,
            description: category.description,
            isSelected: false,
            thirdLevel: thirdLevel,
          };
        });

        Array.isArray(secondLevel) && secondLevel.sort(sortByName());

        return {
          id: department.id_category,
          name: department.name,
          isSelected: false,
          secondLevel: secondLevel,
        };
      });

      Array.isArray(firstLevel) && firstLevel.sort(sortByName());
      ownerCompany.firstLevel = firstLevel;

      setCompanies([ownerCompany]);
    });

    // let ownerCompany = { id: tokenData.iss.id, name: tokenData.iss.name, isSelected: true, secondLevel: [] };
    // setCompanies([ownerCompany]);
    // handleSelectCompany(ownerCompany, [ownerCompany]);
  }

  async function getMostUsed() {
    const urlMostUsed = `${Constants.APIEndpoints.COMPANY}/${tokenData.iss.company.id}/catalog/mostused`;
    await fetch.execute('get', urlMostUsed).then(async ({ data }) => {
      setCatalogMostUsed(data || []);
    });
  }

  function getBreadcrumbs() {
    const labelInitialBreadcrumb = isMultiCatalog
      ? language['SERVICE_PORTAL']['COMPANY']
      : language['SERVICE_PORTAL']['CATEGORY'];
    let breadcrumbs = [labelInitialBreadcrumb];

    const catalogBase = isMultiCatalog ? companies : companies[0]?.firstLevel;

    const firstLevel = catalogBase?.find(
      (item) => item.id == catalogSelected.firstLevel
    );
    firstLevel && breadcrumbs.push(firstLevel.name);

    const secondLevel = firstLevel?.secondLevel.find(
      (item) => item.id == catalogSelected.secondLevel
    );
    secondLevel && breadcrumbs.push(secondLevel.name);

    return breadcrumbs;
  }

  async function handleSelectCompany(company, companiesShadow) {
    let companiesCopy = [...(companiesShadow ?? companies)];
    let companyBase = isMultiCatalog
      ? companiesCopy
      : companiesCopy[0].firstLevel;
    const companyIndex = companyBase.findIndex(
      (item) => item.id === company.id
    );
    let sameCompany = catalogSelected.firstLevel == company.id;

    if (!sameCompany) {
      if (companyBase[companyIndex].secondLevel.length === 0) {
        const urlDepartmentCompany = `${Constants.APIEndpoints.DEPARTMENT}/filter`;
        await fetch
          .execute('post', urlDepartmentCompany, '', {
            fk_id_company: company.id,
            catalog: true,
            check_catalog_grouping: true,
          })
          .then(async ({ data }) => {
            let secondLevel = data?.map((item) => ({
              id: company.enable_catalog_grouping
                ? item.id_company_catalog_grouping
                : item.id_department,
              name: company.enable_catalog_grouping
                ? item.grouping_name
                : item.name,
              thirdLevel: [],
            }));
            Array.isArray(secondLevel) && secondLevel.sort(sortByName());
            companyBase[companyIndex].secondLevel = secondLevel;
          });
      }
    }

    setCompanies(companyBase);
    handleCatalogSelection('firstLevel', company.id);

    const secondLevelValue = getRoute('secondLevel');
    const foundDepartmentIndexByRouteValue = companyBase[
      companyIndex
    ].secondLevel?.findIndex((item) => item.id == secondLevelValue);
    if (foundDepartmentIndexByRouteValue > -1)
      handleSelectDepartment(
        companyBase[companyIndex],
        companyBase[companyIndex].secondLevel[foundDepartmentIndexByRouteValue],
        companiesCopy
      );
  }

  async function handleSelectDepartment(company, department, companiesShadow) {
    let companiesCopy = [...(companiesShadow ?? companies)];
    let companyBase = isMultiCatalog
      ? companiesCopy
      : companiesCopy[0].firstLevel;
    const companyIndex = companyBase.findIndex(
      (item) => item.id === company.id
    );
    const companyDepartmentIndex = companyBase[
      companyIndex
    ].secondLevel.findIndex((item) => item.id === department.id);

    let sameDepartment = catalogSelected.secondLevel == department.id;

    if (!sameDepartment) {
      if (
        companyBase[companyIndex].secondLevel[companyDepartmentIndex].thirdLevel
          .length === 0
      ) {
        setFetchState(TYPE_STATE.LOADING);

        const urlCompanyDepartmentCatalog = `${Constants.APIEndpoints.CATALOG}/company/${company.id}/department/${department.id}`;
        await fetch
          .execute('get', urlCompanyDepartmentCatalog)
          .then(({ data }) => {
            let thirdLevel = data?.map((category) => {
              let subcategories = category.catalog?.map((subcategory) => {
                let tasks = subcategory.task?.map((task) => ({
                  id: task.id_catalog_task,
                  name: task.name,
                  description: task.description,
                  id_urgency: task.id_urgency,
                  icon: task.icon,
                  icon_url: task.icon_url,
                  disable_fields: task.disable_fields,
                }));
                Array.isArray(tasks) && tasks.sort(sortByName());
                return {
                  id: subcategory.id_catalog_service,
                  name: subcategory.name,
                  description: subcategory.description,
                  icon: subcategory.icon,
                  icon_url: subcategory.icon_url,
                  tasks: tasks,
                };
              });
              Array.isArray(subcategories) && subcategories.sort(sortByName());
              return {
                id: category.id_category,
                name: category.name,
                description: category.description,
                icon: category.icon,
                icon_url: category.icon_url,
                subcategories: subcategories,
              };
            });

            Array.isArray(thirdLevel) && thirdLevel.sort(sortByName());

            companyBase[companyIndex].secondLevel[
              companyDepartmentIndex
            ].thirdLevel = thirdLevel;
            companyBase[companyIndex].secondLevel[
              companyDepartmentIndex
            ].isSelected = true;

            setFetchState(TYPE_STATE.COMPLETE);
          })
          .catch((error) => {
            setFetchState(TYPE_STATE.FAIL);
          });
      }
    }

    setCompanies(companyBase);
    handleCatalogSelection('secondLevel', department.id);
  }

  async function handleSelectThirdLevel() {}

  return (
    <>
      <ServicePortalSubHeader breadcrumbs={getBreadcrumbs()} />
      <ServicePortalWrapper>
        <aside className="tw-w-3/12 2xl:tw-w-2/12 tw-flex tw-flex-col tw-overflow-y-auto tw-rounded-lg">
          <ServicePortalCatalog
            companies={companies}
            catalogSelected={catalogSelected}
            isMultiCatalog={isMultiCatalog}
            onSelectCompany={handleSelectCompany}
            onSelectDepartment={handleSelectDepartment}
          />
        </aside>

        <main className="tw-w-9/12 2xl:tw-w-10/12 tw-overflow-y-auto">
          <div
            className="p-4 tw-border-2 tw-rounded-lg tw-bg-white tw-flex"
            style={{ minHeight: 300 }}
          >
            {fetchState == TYPE_STATE.NONE && catalogMostUsed?.length === 0 && (
              <div className="tw-flex tw-justify-center tw-items-center tw-flex-grow">
                <EmptyGrid>
                  <h2 className="tw-text-sm tw-text-gray-400">
                    {language['SERVICE_PORTAL']['PAGE_NOT_RESULTS_FOUND']}
                  </h2>
                </EmptyGrid>
              </div>
            )}
            {fetchState == TYPE_STATE.NONE && catalogMostUsed?.length > 0 && (
              <ServicePortalCatalogMostUsed
                language={language}
                items={catalogMostUsed}
              />
            )}
            {fetchState == TYPE_STATE.LOADING && (
              <div className="tw-flex tw-justify-center tw-items-center tw-flex-grow">
                <CircularProgress color="inherit" />
              </div>
            )}
            {fetchState == TYPE_STATE.FAIL && (
              <div className="tw-flex tw-justify-center tw-items-center tw-flex-grow">
                <EmptyGrid>
                  <h2 className="tw-text-sm tw-text-gray-400">
                    {
                      language['SERVICE_PORTAL'][
                        'AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER'
                      ]
                    }
                  </h2>
                </EmptyGrid>
              </div>
            )}
            {fetchState == TYPE_STATE.COMPLETE &&
              catalogSelected.secondLevel && (
                <ServicePortalCatalogItems
                  items={companies}
                  language={language}
                  getRoute={getRoute}
                  catalogSelected={catalogSelected}
                  allowCustomizePriority={allowCustomizePriority}
                  isIntegrationEnabled={isIntegrationEnabled}
                  enableLocationInOpeningTicket={enableLocationInOpeningTicket}
                  onSelectCategory={handleSelectThirdLevel}
                  onCatalogSelect={handleCatalogSelection}
                />
              )}
          </div>
        </main>
      </ServicePortalWrapper>
    </>
  );
}
