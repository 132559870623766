import dispatch from './dispatch';
function jsonParse(data){
    try{
      return JSON.parse(data);
    }catch(err){
      return data;
    }
}

let msg = {
    post:'Dados inseridos com sucesso',
    put:'Dados atualizados com sucesso',
    delete:'Dados deletados com sucesso'
}

let changeStore = {
    removeKeyLoading(keyLoading, method, url){
        this.store.keyLoading = this.store.keyLoading.filter(e => ![keyLoading, method+'-'+url].includes(e));
    },
    init(config={}){
        Object.keys(config).forEach(e=>{
            this[e] = config[e];
        })
        return this;
    },
    start(config){
        if(this.store.loading++);
        this.store.keyLoading.push(this.keyLoading, this.method+'-'+this.url);
    },
    success(senData){
        if(this.store.loading--);
        dispatch({...senData, store: this.store});

        if(this.store.toggleAlert){
            if(senData.method != 'get'){
               this.store.toggleAlert(true, msg[senData.method], 'success');
            }
        }
        this.removeKeyLoading(this.keyLoading, this.method, this.url);
    },
    error(error){
        if(error && (typeof error.then) == 'function'){
            return error.then(e=>{
               let err = jsonParse(e);
              
                if(err.msg && this.store.toggleAlert){
                    this.store.toggleAlert(true, err.msg, 'error');
                }
                this.store.loading--;
               
               return err;
            });
            
            this.removeKeyLoading(this.keyLoading, this.method, this.url);
        }
    }
}

export default changeStore;