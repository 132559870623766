import React, { Component, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Dictionary from '@common/languages/Dictionary';

export default class Pickers extends Component {
  dateTimeFormat(locale, options) {
    let language = sessionStorage.getItem('sensr_lang');
    language = !language
      ? (navigator.language || navigator.userLanguage)
          .toUpperCase()
          .replace('-', '_') == 'PT_BR'
        ? 'PT_BR'
        :  (navigator.language || navigator.userLanguage)
          .toUpperCase()
          .replace('-', '_') == 'EN'
          ? 'EN'
          : 'ES'
      : language;

    let dayAbbreviation = Dictionary[language].DATE_LANG.dayAbbreviation;
    let dayList = Dictionary[language].DATE_LANG.dayList;
    let monthList = Dictionary[language].DATE_LANG.monthList;
    let monthLongList = Dictionary[language].DATE_LANG.monthLongList;

    this.format = function (date) {
      if (
        options.month === 'short' &&
        options.weekday === 'short' &&
        options.day === '2-digit'
      ) {
        return (
          dayList[date.getDay()] +
          ', ' +
          monthList[date.getMonth()] +
          ' ' +
          date.getDate()
        );
      } else if (
        options.year === 'numeric' &&
        options.month === 'numeric' &&
        options.day === 'numeric'
      ) {
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        );
      } else if (options.year === 'numeric' && options.month === 'long') {
        return monthLongList[date.getMonth()] + ' ' + date.getFullYear();
      } else if (options.weekday === 'narrow') {
        return dayAbbreviation[date.getDay()];
      } else if (options.year === 'numeric') {
        return date.getFullYear().toString();
      } else if (options.day === 'numeric') {
        return date.getDate();
      } else {
        process.env.NODE_ENV !== 'production'
          ? (0, _warning2.default)(
              false,
              'Material-UI: Wrong usage of DateTimeFormat'
            )
          : void 0;
      }
    };
  }
  render() {
    let { type, format, ...other } = this.props;

    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {type == 'date' ? (
            <KeyboardDatePicker
              {...other}
              format={this.dateTimeFormat}
              //   DateTimeFormat={this.dateTimeFormat}
              //   firstDayOfWeek={0}
              animateYearScrolling
              autoOk={true}
              fullWidth={true}
              format={format || 'dd/MM/yyyy'}
            />
          ) : type == 'time' ? (
            <KeyboardTimePicker
              {...other}
              clearable
              ampm={false}
              fullWidth={true}
            />
          ) : null}
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}
