import React, {Fragment, memo, useState, useEffect} from 'react';
/*------------------------------common-------------------------------------*/
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import AttachFile from '@common/AttachFile';
/*------------------------------material-ui-------------------------------------*/
import Store from '@data/Store';

const { language } = Store;

export default memo(({values=[], onDownload})=>{
	return(
		<div className='Block_4 mb-4'>
         <span className='label-items d-block'>{language.ATTACHMENTS}</span>
         <ul>
            {values.map((f,i)=>(
               <li key={f.label+'-'+i} className='content-file py-1 d-block'>
                  <AttachFile onClick={() => onDownload(f.id_gp_file, f.label)} {...f}/>
               </li>	
            ))}
         </ul>
		</div>
	)
})