import React, {Fragment, memo} from 'react';
import './index.css';
//--------------------------common---------------------------- 
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import Button from '@common/Button';

//--------------------------icons---------------------------- 
import BuildIcon from '@material-ui/icons/Build';
import ExploreIcon from '@material-ui/icons/Explore';
import GavelIcon from '@material-ui/icons/Gavel';
import TimelineIcon from '@material-ui/icons/Timeline';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

import moment from 'moment'; 

const styleIcons = [
	{fontSize:11, color:'var(--primary)'},
	{fontSize:11, color:'#0F9FC2'},
	{fontSize:11, color:'var(--danger)'},
	{fontSize:11, color:'var(--warning)'},
	{fontSize:11, color:'var(--purple)'},
]
const icons = [
	<BuildIcon style={styleIcons[0]}/>,
	<ExploreIcon style={styleIcons[1]}/>,
	<GavelIcon style={styleIcons[2]}/>,
	<TimelineIcon style={styleIcons[3]}/>,
	<FitnessCenterIcon style={styleIcons[4]}/>
]
export default memo(({
	setViewProjectsId,
	onClose,
	projects,
	users
})=>{
	//----- as requisições para esses dados serão feitas nesse componente

	let today = new Date();
	let data= projects.length>0
		&&projects
			.filter(x => !x.deleted && !x.closed)
			.map(g=>{ 
		let sponsor = ''
		try {
			sponsor = users.find(x => x.value === g.fk_id_sponsor).label
		} catch (error) {
			
		}
	 
		return{  check: (
				<Button
					icon='fas fa-eye'
					label='Ver Atividades'
					variant='normal'
					color='success'
					size={.8}
					onClick={()=>setViewProjectsId(g.id_project)} //--id do projeto
				/>
			),
			id:g.id_project,
			subject:g.name,
			dt:moment(g.start_date).format('DD-MM-YY'),
			hour:moment(g.start_date).format('HH:mm'),
			sponsor: sponsor
			 
		}
	})





	 
	return(
		<CommonTable
			className='AssociateActivityTable'
			maxHeight={500}
			paginationTop={false}
			afterBar2={
				<Button
					label='Fechar'
					icon='fas fa-times'
					color='#ffffff'
					variant='circle'
					outlined
					style={{
						border:'none',
						color:'#ffffff'
					}}

					onClick={onClose}
				/>
			}
			AppBarProps={{
				style:{
					borderRadius:'10px 10px 0 0'
				}
			}}
			col={[
				{key:'action', label:'Ação'},
			    {key:'id', label:'ID'},
				{key:'subject', label:'Nome Projeto'},
				{key:'dt', label:'Data'},
				{key:'hour', label:'Hora'},
			
				{key:'sponsor', label:'Responsável'},
				 
			]}
			data={data}
			footer={
				<Fragment>
					<hr/>
					<div className='d-flex align-items-center justify-content-end pb-3 pr-3'>
						<Button
							label='Cancelar'
							color='danger'
							variant='normal'
							className='mr-3'
							onClick={onClose}
						/>
					</div>
				</Fragment>
			}
		/>
	)
})