export function updateAutomationDTO(values) {
  
  if ('dt_cad' in values) delete values['dt_cad'];
  if ('user_cad' in values) delete values['user_cad'];
  if ('id_automation' in values) delete values['id_automation'];
  if ('deleted' in values) delete values['deleted'];
  if ('namecompany' in values) delete values['namecompany'];
  if ('solicitant_name' in values) delete values['solicitant_name'];
  if ('tech_name' in values) delete values['tech_name'];

  if ('rule' in values) {
    if (values.rule === null) delete values['rule'];
  }

  if ('description' in values) {
    if (values.description === null) delete values['description'];
  }

  if ('id_action_automation_detail' in values) {
    if (values.id_action_automation_detail === null) delete values['id_action_automation_detail'];
  }

  if ('type_category' in values) {
    if (values.type_category === null) delete values['type_category'];
  }

  if ('fk_id_catalog_task' in values) {
    if (values.fk_id_catalog_task === null) delete values['fk_id_catalog_task'];
  }

  if ('fk_id_solicitant' in values) {
    if (values.fk_id_solicitant === null) delete values['fk_id_solicitant'];
  }

  if ('fk_id_tech' in values) {
    if (values.fk_id_tech === null) delete values['fk_id_tech'];
  }

  if ('fk_id_contract' in values) {
    if (values.fk_id_contract === null) delete values['fk_id_contract'];
  }

  if ('recurrence' in values) {
    if (values.recurrence === null) delete values['recurrence'];
  }

  if ('description_details' in values) {
    if (values.description_details === null) delete values['description_details'];
  }

  if ('time' in values) {
    if (values.time === null) delete values['time'];
  }

  if ('fk_id_automation' in values) {
    if (values.fk_id_automation === null) delete values['fk_id_automation'];
  }

  if ('type_tickets' in values) {
    if (values.type_tickets === null) delete values['type_tickets'];
  }

  if ('fk_id_catalog_service' in values) {
    if (values.fk_id_catalog_service === null) delete values['fk_id_catalog_service'];
  }

  if ('fk_id_category' in values) {
    if (values.fk_id_category === null) delete values['fk_id_category'];
  }

  if ('fk_id_company' in values) {
    if (values.fk_id_company === null) delete values['fk_id_company'];
  }

  values.actions_automation.forEach((item) => {
    if ('name' in item) delete item['name'];
  });

  return values;
}
