import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useServicePortalStyles } from '../helpers';

export default function ServicePortalTicketSubTitle({ label, iconText }) {
    const styles = useServicePortalStyles();
    return (
        <Grid item sm={12}>
            {iconText && <i className={`fas ${iconText} tw-mr-2`} style={{ color: 'var(--warning)' }} />}

            <Typography component='span' className={styles.text}>
                <span className='tw-uppercase tw-text-gray-500 tw-font-bold'>
                    {label}
                </span>
            </Typography>
        </Grid>
    )
}
