import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Switch, Checkbox, SelectField, MenuItem } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { FaIndustry, FaEdit, FaClose, FaTrash } from 'react-icons/lib/fa';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import { TextField, Card, Chip } from '@material-ui/core';
import CommonPage from '@common/CommonPage';
import Button from '@common/Button';
@observer
export default class DepartmentAddTaskForm extends CommonPage {
  constructor() {
    super();

    this.state = {
      departments: [],
      open: false,
      category: null,
      catalog: null,
      catalog_task: null,
      openNew: false,
      listTask: [],
      categories: [],
    };

    this.handleModal = this.handleModal.bind(this);
    this.addDepartmentInCompany = this.addDepartmentInCompany.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.load = this.load.bind(this);
  }

  load() {
    this.action
      .execute('get', this.APIEndpoints.COMPANY + '/taskdp', '')
      .then((v) => {
        this.setState({ listTask: v.data });
      });
    this.action
      .execute('get', this.APIEndpoints.CATEGORY, 'categories')
      .then((h) => {
        this.setState({ categories: h.data });
      });
  }

  componentWillMount() {
    this.load();
  }

  handleModal() {
    this.setState({ open: !this.state.open });
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_category') {
      this.setState({
        category: value,
      });
    } else if (field.name === 'fk_id_catalog_service') {
      delete this.props.values.add;
      this.setState({
        catalog: value,
      });
    } else if (field.name === 'fk_id_catalog_task') {
      this.setState({
        catalog_task: value,
      });
    }
  }

  addDepartmentInCompany() {
    let { departments } = this.state;
    let { values } = this.props;
    if (departments.length) {
      this.props.addDepartmentInCompany(departments, values.id_company);
      this.setState({ departments: [] });
      this.handleModal();
    }
  }

  checkBoxSelect(id_department) {
    let { departments } = this.state;
    if (departments.indexOf(id_department) === -1) {
      departments.push(id_department);
    } else {
      departments = departments.filter((id) => id != id_department);
    }
    this.setState({ departments: departments });
  }

  onDelete(values) {
    let method = 'delete';
    let url = this.APIEndpoints.COMPANY;
    let body = null;
    this.action
      .execute(
        'delete',
        this.APIEndpoints.COMPANY +
          '/taskdp/' +
          values.id_rel_catalog_task_department,
        'listTask',
        values,
        'id_rel_catalog_task_company',
        {
          btn_loading: 'del-task-list-' + values.id_rel_catalog_task_department,
        }
      )
      .then((v) => {
        this.load();
      });
  }

  onFormSubmit(values) {
    values.id_department = this.props.id_department;
    if (values.fk_id_category != undefined) {
      this.action
        .execute('put', this.APIEndpoints.COMPANY + '/task', 'listTask', values)
        .then((e) => {
          this.load();
        });
    }
  }

  render() {
    let { catalogs, onDelete, id_department } = this.props;

    let { open } = this.state;
    let categoryOptions = this.state.categories.map((c) => ({
      value: c.id_category,
      label: c.id_category + ' - ' + c.name,
    }));
    let serviceCatalogOptions = [];
    let tasksOptions = [];

    let {
      language,
      handlePage,
      values,
      departments,
      changeDepartmentBoss,
      users,
    } = this.props;

    let fields = [
      {
        col: 12,
        name: 'fk_id_category',
        label: language.CATEGORY,
        type: 'listCheckbox',
        options: categoryOptions,
        required: true,
      },
    ];

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10, textAlign: 'center' },
      },
      {
        key: 'id_category',
        label: 'ID da Categoria',
        style: { width: 10, textAlign: 'center' },
      },
      {
        key: 'categoria',
        label: language.CATEGORY,
        style: { width: 50, textAlign: 'center' },
      },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 90, textAlign: 'center' },
      },
    ];
    let data = this.state.listTask
      .filter(
        (c) =>
          c.fk_id_company == values.id_company &&
          c.fk_id_department == id_department
      )

      .map((obj) => {
        return {
          id: obj.id_rel_catalog_task_department,
          id_category: obj.fk_id_category,
          categoria: obj.categoria,

          action: (
            <Button
              variant="circle"
              size={0.7}
              icon="far fa-trash-alt"
              label={language.DELETE}
              color="danger"
              _loading={'del-task-list-' + obj.id_rel_catalog_task_department}
              style={{ fontSize: 14 }}
              onClick={() => this.onDelete(obj)}
            />
          ),
        };
      });

    let button = { md: 12, label: language.SAVE, primary: true };

    return (
      <div>
        <Card className="pb-2 mb-3">
          <Container fluid>
            <Row>
              <Col md={10}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  value={values.name}
                />
              </Col>
            </Row>
            {this.state.openNew === true ? (
              <Row>
                <Dialog
                  PaperProps={{
                    style: {
                      height: '70%',
                    },
                  }}
                  open={true}
                  title={language.ADD}
                  maxWidth="md"
                >
                  <Card className="content">
                    <CommonForm
                      values={values}
                      fields={fields}
                      button={button}
                      onSubmit={(v) => {
                        this.onFormSubmit(v);
                        this.setState({ openNew: false });
                      }}
                    />
                  </Card>
                </Dialog>
              </Row>
            ) : null}
          </Container>
        </Card>

        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index="table-4"
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => this.setState({ openNew: true })}
            />
          }
          index={'CompanyAddTaskForm'}
        />
      </div>
    );
  }
}
