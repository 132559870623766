import React, { Fragment, useState, useEffect, useRef } from 'react';

import CommonTable from '@common/CommonTable';
import moment from 'moment';
import axios from 'axios';
import Store from '@data/Store';

const { language } = Store;

export default function Historic({
  action,
  APIEndpoints,
  handleOpenHistory,
  fk_id_user,
  fk_id_device,
}) {
  let [tickets, setTickets] = useState([]);
  const isMountedRef = useRef(null);

  useEffect(() => {
    getTickets();
    return () => {
      setTickets([]);
    };
  }, []);

  function getTickets() {
    action
      .execute(
        'post',
        APIEndpoints.TICKET + '/ticketFind',
        //   'tickets',
        null,
        { historic: true, fk_id_user, fk_id_device },
        null,
        null,
        null
      )
      .then((g) => {
        setTickets(g.data);
      });
  }

  let datas = tickets.map((obj) => {
    return {
      action: (
        <i
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenHistory(obj)}
          className="fas fa-eye"
          style={{ color: 'var(--primary)' }}
        />
      ),

      id: obj.real_id,
      solicitant: obj.nameuser,
      catalogservice: obj.catalogservice,
      group_users: obj.group_users,
      department: obj.department,
      dt: moment(obj.dt_cad_request || obj.dt_cad).format('DD/MM/YYYY'),
      hour: moment(obj.dt_cad_request || obj.dt_cad).format('HH:mm'),
      device: obj.device,
      subject: obj.subject,
    };
  });

  return (
    <CommonTable
      bar={false}
      index="ticket-detail-history-call"
      searchColumn
      paper={false}
      mini
      col={[
        { key: 'action', label: language.ACTION },
        { key: 'id', label: 'ID' },
        { key: 'solicitant', label: language.SOLICITANT },
        { key: 'catalogservice', label: language.SUB_CATEGORY },
        { key: 'group_users', label: language.TECH_GROUP },
        { key: 'department', label: language.DEPARTMENT },
        { key: 'dt', label: language.DATE },
        { key: 'hour', label: language.HOUR },
        { key: 'device', label: language.DEVICE },
        { key: 'subject', label: language.SUBJECT },
      ]}
      data={datas}
    />
  );
}
