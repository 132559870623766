import React, { useState, useEffect, useRef } from 'react';

import './buildhome.css'
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import ReactToPrint from 'react-to-print';

import CommonForm from '@common/CommonForm2';
import PageLayout from '@common/PageLayout'
import CommonTable from '@common/CommonTable'
import Dialog from '@common/Dialog';
import Button from '@common/Button'

/* ----------------- common ------------------- */
import BarBlue from '@images/BarBlue.png';

import { Grid } from '@material-ui/core';

import Store from '@data/Store';

const { language } = Store;

export default function ({ APIEndpoints, action }) {
  useEffect(() => {
    //BUILD

    action.execute('post', 'https://cs.sensrit.com.br/build', 'catalogs', { type: 'selectbuid' }).then(x => {

      setBuilds(x.data)
    })
    action.execute('post', 'https://cs.sensrit.com.br/build', 'catalogs', { type: 'selectbuilditem' }).then(x => {

      setBuildItens(x.data)
    })

  }, []);

  let [builds, setBuilds] = useState([])
  let [build, setBuild] = useState(0);
  let [buildItens, setBuildItens] = useState([])
  return (
    <div id="Contract-report" style={{ minHeight: '800px' }} >
      <header
        style={{ background: `url(${BarBlue})` }}
        className="content d-flex justify-content-between">
        <h4 style={{ color: 'white' }}>{language.LAST_UPDATES}</h4>
      </header>
      <div style={{ marginTop: '20px', marginLeft: '5px' }}>
        <CommonForm
          alert={false}

          fields={[
            {
              col: 4,
              type: 'select',
              name: 'build_id',
              label: language.BUILD,
              options: builds.map(c => ({ value: c.id_build, label: c.name })),
              required: true
            }
          ]}
          onChangeField={(f, v) => {

            setBuild(v);
          }}
        />
      </div>
      <div className="content">
        {NestedList(buildItens.filter(c => c.fk_id_build == build))}
      </div>


    </div>

  )
}




function NestedList(itens) {




  const [open, setOpen] = React.useState(-1);

  const handleClick = (v) => {
    if (v == open) {
      setOpen(-1);
    } else {
      setOpen(v);
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {language.UPDATE_ITEM_LIST}
        </ListSubheader>
      }

    >
      {itens.map((v, i) => (


        <div>

          <ListItem button onClick={() => handleClick(i)}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={v.name} />
            {open == i ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open == i ? true : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button  >
                <ListItemIcon>

                </ListItemIcon>
                <p dangerouslySetInnerHTML={{ __html: v.description }}
                />
              </ListItem>
            </List>
          </Collapse></div>

      ))}

    </List>
  );
}
