import React, { useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';
import { flexBasis } from '@common/Func';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import './styles.css';
import CalendarTask from './Calendar';

export function WeekTask({ eventTask, reload, type }) {
  let events = [];

  const handleDateClick = () => {};
  return (
    <Card className="content-min px-3">
      <Grid container spacing={3} xs={12}>
        <CalendarTask
          events
          type={type}
          eventTask={eventTask}
          reload={reload}
        />
      </Grid>
    </Card>
  );
}
