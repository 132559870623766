export default {
	velocimeter(e) {
	  let { suffix, value, label, title, size, height,maxvl ,seriesname} = e;
	  return {
	    chart: {
	      type: 'gauge',
	      plotBackgroundColor: null,
	      plotBackgroundImage: null,
	      plotBorderWidth: 0,
	      plotShadow: false,
	      backgroundColor: 'transparent',
	      height: height || 200
	    },
	    credits: {
	      enabled: false
	    },
	    title: {
	      text: title||'',
	      style:{
	      	fontSize:15
	      }
	    },

	    pane: {
	      size: size || 150,
	      startAngle: -90,
	      endAngle: 90,
	      background: [
	        {
	          borderWidth: 0,
	          backgroundColor: 'transparent'
	        },
	        {
	          borderWidth: 0,
	          backgroundColor: 'transparent'
	        }
	      ]
	    },

	    // the value axis
	    yAxis: {
	      min: 0,
	      max:maxvl|| 200,

	      minorTickInterval: 'auto',
	      minorTickWidth: 1,
	      minorTickLength: 10,
	      minorTickPosition: 'inside',
	      minorTickColor: '#666',

	      tickPixelInterval: 30,
	      tickWidth: 2,
	      tickPosition: 'inside',
	      tickLength: 10,
	      tickColor: '#666',
	      labels: {
	        step: 2,
	        rotation: 'auto'
	      },
	      title: {
	        text: label
	      },
	      plotBands: [
	        {
	          from: 0,
	          to: value,
	          color: 'var(--success)' // green
	        }
	      ]
	    },

	    series: [
	      {
	        name: seriesname||'hours ' ,
	        data: [value],
	        tooltip: {
	          valueSuffix: suffix||''
	        }
	      }
	    ]
	  };
	},
}