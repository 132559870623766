import React, { useEffect, useState } from 'react';
import CommonForm from '../../../common/CommonForm2';
import Store from '@data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';
import Actions from '@data/Actions';

const action = new Actions();

const ConsumerForm = ({
  APIEndpoints,
  language,
  setForm,
  callPageSelectFilter,
  values,
}) => {
  const [types, setTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [isCpf, setIsCpf] = useState(true);

  const changePersonNature = () => {
    setIsCpf((prev) => !prev);
  };

  const getState = (idCountry) => {
    action
      .execute('get', `${APIEndpoints.COUNTRY + '/' + idCountry}`)
      .then((res) => {
        setStates(res.data);
      });
  };

  const getCities = (idState) => {
    action
      .execute('get', `${APIEndpoints.STATE + '/' + idState}`)
      .then((res) => {
        setCity(res.data);
      });
  };

  useEffect(() => {
    action
      .execute('get', `${APIEndpoints.COUNTRY}`)
      .then((res) => {
        setCountries(res.data);
      });
  }, []);

  useEffect(() => {
    action
      .execute('get', `${APIEndpoints.SAC_CONSUMER_TYPE}`)
      .then((res) => {
        setTypes(res.data);
      });
    if (values.fk_id_country) {
      getState(values.fk_id_country);
      getCities(values.fk_id_state);
    }
  }, []);

  const onFormSubmit = (values) => {
    const method = values.id ? 'PUT' : 'POST';
    const url = values.id
      ? APIEndpoints.SAC_CONSUMER + '/update'
      : APIEndpoints.SAC_CONSUMER;

    action
      .execute(method, url, '', { ...values, user: Store.getUserLoged() })
      .then((res) => {
        if (res.data[0]['error']) {
          Store.toggleAlert(true, language.CPF_CNPJ_ALREADY_EXISTIS, 'error');
        } else {
          setForm(false);
          callPageSelectFilter();
        }
      });
  };

  const fields = [
    {
      type: 'element',
      content: <span className="title-aba mb-3 bg-info">{language.PERSONAL_DATA}</span>,
    },
    {
      name: 'status',
      label: language.STATE,
      type: 'select',
      options: [
        { label: language.ANTICIPATED, value: 1 },
        { label: language.CANCELED, value: 2 },
        { label: language.ACTIVE, value: 3 },
        { label: language.INACTIVE, value: 4 },
      ],
      required: true,
      col: 3,
    },
    {
      name: 'nature_person',
      label: language.NATURE,
      type: 'select',
      options: [
        { label: language.PHYSICS, value: 'pf' },
        { label: language.JURIDIC, value: 'pj' },
      ],
      required: true,
      col: 3,
    },
    {
      col: 3,
      name: 'id_consumer_type',
      label: language.TYPE,
      type: 'select',
      required: true,
      options: types.map((t) => ({ value: t.id_consumer_type, label: t.name })),
    },
    {
      name: 'cpf_cnpj',
      label: `${isCpf === true ? 'CPF' : 'CNPJ'}`,
      type: 'text',
      format: `${isCpf === true ? '###.###.###-##' : '##.###.###/####-##'}`,
      required: true,
      col: 3,
    },
    {
      name: 'name',
      label: language.NAME,
      type: 'text',
      required: true,
      col: 3,
    },
    {
      name: 'email',
      label: language.EMAIL,
      type: 'text',
      required: true,
      col: 3,
    },
    {
      type: 'element',
      content: <span className="title-aba mb-3">{language.REGISTER_DATA}</span>,
    },
    {
      col: 3,
      name: 'fk_id_country',
      label: language.COUNTRY,
      type: 'select',
      startIcon: 'fas fa-flag',
      options: countries.map((country) => ({
        value: country.id_country,
        label: translateFromPtBR(country.name, language),
      })),
      required: true,
    },
    {
      col: 3,
      name: 'fk_id_state',
      startIcon: 'far fa-flag',
      label: language.DISTRICT,
      type: 'select',
      options: states.map((st) => ({ value: st.id_state, label: st.name })),
      required: true,
    },
    {
      col: 3,
      name: 'fk_id_city',
      startIcon: 'fas fa-city',
      label: language.CITY,
      type: 'select',
      options: city.map((ct) => ({ value: ct.id_city, label: ct.name })),
      required: true,
    },
    {
      name: 'address',
      label: language.ADDRESS,
      type: 'text',
      required: true,
      col: 3,
    },
    {
      name: 'complement',
      label: language.COMPLEMENT,
      type: 'text',
      required: true,
      col: 3,
    },
    {
      name: 'number_address',
      label: language.NUMBER,
      type: 'number',
      required: true,
      col: 3,
    },
    {
      name: 'zip_code',
      label: language.POSTAL_CODE,
      type: 'text',
      required: true,
      col: 3,
    },
    {
      name: 'neighborhood',
      label: language.NEIGHBORHOOD,
      type: 'text',
      required: true,
      col: 3,
    },
    {
      name: 'phone',
      label: language.PHONE,
      type: 'text',
      required: true,
      col: 3,
    },

    {
      type: 'element',
      content: <span className="title-aba mb-3">{language.INTEGRATION_DATA}</span>,
    },

    {
      name: 'id_integration',
      label: language.ID_INTEGRATION,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'dt_up',
      label: language.LAST_UPDATE_DATE,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'number_rn',
      label: 'RN',
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'manager',
      label: language.MANAGER_COORDINATOR,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'director',
      label: language.DIRECTOR,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'base',
      label: language.BASE,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'segment',
      label: language.SEGMENT,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'flag',
      label: language.FLAG,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
    {
      name: 'operator',
      label: language.ATTENDANT,
      type: 'text',
      required: false,
      col: 3,
      disabled: true,
    },
  ];

  return (
    <div>
      <CommonForm
        button={{ label: language.SAVE }}
        fields={fields}
        onSubmit={onFormSubmit}
        onChangeField={(field, value) => {
          if (field.name === 'fk_id_country') {
            getState(value);
          }
          if (field.name === 'nature_person') {
            changePersonNature();
          }
          if (field.name === 'fk_id_state') {
            getCities(value);
          }
          if (field.name === 'nature_person') {
            if (value === 'pf') {
              setIsCpf(true);
            } else {
              setIsCpf(false);
            }
          }
        }}
        values={values}
      />
    </div>
  );
};

export default ConsumerForm;
