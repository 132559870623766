import './index.css';

import React from 'react';
import moment from 'moment';
import io from 'socket.io-client';
import { observer } from 'mobx-react';

import Button from '../../common/Button';
import CommonPage from '../../common/CommonPage';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import Constants from '../../data/Constants';

import Nivel_1 from './Nivel_1';
import DialogForm from './DialogForm';

let styleBtn = { border: 'none' };

String.prototype.insert = function (index, string) {
  if (index > 0) {
    return (
      this.substring(0, index) + string + this.substring(index, this.length)
    );
  }

  return string + this;
};
@observer
export default class extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      formDialog: false,
      listDevices: [],
      companyId: 1,
      device_: null,
      selectedYear: moment().format('YYYY'),
      ioServers: io.connect(Constants.AGENT_SERVERS, {
        path: '/server',
        query:
          'user=' +
          this.store.getUserLoged().id +
          '&token=' +
          this.store.getToken(),
      }),
    };
    this.handleSelectCompany = this.handleSelectCompany.bind(this);
  }

  componentDidMount() {
    let { companyId } = this.state;
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');

    this.action
      .execute('post', this.APIEndpoints.MONITOR + '/capacity', '')
      .then((c) => {
        this.setState({ listDevices: c.data });
      });
  }
  handleOpen = (i) => {
    this.setState((state) => ({
      open: state.open === i ? null : i,
    }));
  };
  computMonth = (date) => {
    let value = moment(date, 'MMYY').format('MM');
    return value;
  };
  handleOpenDialog = (open = false) => {
    this.setState({ formDialog: open });
  };

  handleSelectCompany = (field, value) => {
    this.setState({ companyId: value });
  };

  handleYear = (year) => {
    this.setState({ selectedYear: year });
  };
  render() {
    let { language, companies, capacityplan } = this.store;
    let { open, formDialog, device_, companyId } = this.state;

    let dat = language.DATE_LANG.monthList;
    let CapacityData = {
      categories: Array.from(new Array(12), (_, i) =>
        moment().add(i, 'M').format('MM/YY')
      ),
      processor: {
        data: Array.from(new Array(12), (_, i) => 10 * i),
        z1: 8, //---defined até qual item no eixo X o gráfico vai ficar azul
        z2: 12, //---defined até qual item no eixo X o gráfico vai ficar amarelo - começa do - 0
      },
      memory: {
        data: Array.from(new Array(12), (_, i) => 3 * i),
        z1: 8,
        z2: 12,
      },
      disk: {
        data: Array.from(new Array(12), (_, i) => 17 * i),
        z1: 8,
        z2: 12,
      },
    };

    if (device_) {
      CapacityData.categories = Array.from(new Array(12), (_, i) =>
        moment().add(i, 'M').format('MM/YY')
      );
      CapacityData.processor = {
        data: Array.from(device_.cpu, (_, i) => {
          return parseFloat(_.value);
        }),
        z1: 8,
        z2: 12,
      };
      CapacityData.memory = {
        data: Array.from(device_.men, (_, i) => {
          return parseFloat(_.value);
        }),
        z1: 8,
        z2: 12,
      };
      CapacityData.disk = {
        data: Array.from(device_.arrayDisco_[0].fslist, (_, i) => {
          return parseFloat(_.value);
        }),
        z1: 8,
        z2: 12,
      };
    }

    let data = [];

    this.state.listDevices
      .filter((v) => v.fk_id_company == companyId)
      .map((e) => {
        data.push({
          device: e.name,
          id_device: e.id_device,
          data: {
            UnavailabilityData: {
              /*--------------dados dos cards--------------------*/
              CardsData: {
                operation: '2341:01:21',
                real_availability: 94,
                planned_availability: 98,
              },
              /*--------------dados do gráfico de 12 meses--------------------*/
              UnavailabilityData: {
                categories: dat,
                data: [10, 20, 31, 32, 33, 20, 10, 40, 39, 10, 11, 32],
                z1: 8,
                z2: 12,
              },
              /*--------------dados do gráfico por ano--------------------*/
              UnavailabilityDataYear: {
                categories: dat
                  .map((e) => e + '/2019')
                  .concat(
                    dat.map((e) => e + '/2020'),
                    dat.map((e) => e + '/2021')
                  ),
                data: Array.from(new Array(36), (_, i) =>
                  parseInt(Math.random() * 100)
                ),
                z1: 11,
                z2: 36,
                height: 100,
              },
            },
            // ----------------------------dados da Capacidade-----------------------------------------
            CapacityData: {
              CapacityData,
            },
          },
        });
      });

    let selectCompanies =
      companies.length > 0 &&
      companies.map((c, i) => ({
        label: c.name,
        value: c.id_company,
      }));

    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        <PageLayout
          title={language.CAPACITY_PLAN}
          subTitle={language.AVAILABILITY_MANAGEMENT + '/'}
          rightElements={[
            <div
              style={{ width: 200, zoom: 0.8, marginTop: '1rem' }}
              zoom={0.8}
            >
              <CommonForm
                individual
                fields={[
                  {
                    type: 'select',
                    name: 'company',
                    label: language.COMPANIES,
                    options: selectCompanies,
                  },
                ]}
                values={{ company: companyId }}
                onChangeField={this.handleSelectCompany}
              />
            </div>,
          ]}
        >
          <DialogForm
            open={formDialog}
            onClose={() => this.handleOpenDialog()}
            onSubmit={(v) => console.log(v)}
						language={language}
          />
          <div id="CapacityPlan">
            <CommonTable
              print
              col={[
                {
                  key: 'btn',
                  label: '',
                  thConfig: true,
                  style: { width: '1%' },
                },
                {
                  key: 'device',
                  label: language.DEVICE,
                  thConfig: true,
                  style: { width: '99%' },
                },
              ]}
              data={data.flatMap((e, i) =>
                [
                  {
                    ConfigCell: {
                      className: 'line-primary',
                    },
                    btn: (
                      <Button
                        icon={
                          open === i
                            ? 'fas fa-chevron-down'
                            : 'fas fa-chevron-right'
                        }
                        label={open === i ? language.OPEN : language.CLOSE}
                        color="dark"
                        variant="circle"
                        outlined
                        style={styleBtn}
                        onClick={() => this.handleOpen(i)}
                      />
                    ),
                    device: '#' + e.id_device + ' ' + e.device,
                  },
                  open === i
                    ? {
                        ConfigCell: {
                          cols: {
                            col_0: { colSpan: 2 },
                          },
                        },
                        btn: (
                          <Nivel_1
                            handleYear={this.handleYear}
                            selectedYear={this.state.selectedYear}
                            dat={dat}
                            ioServers={this.state.ioServers}
                            {...e.data}
                            action={this.action}
                            APIEndpoints={this.APIEndpoints}
                            id_device={e.id_device}
                            language={language}
                          />
                        ),
                      }
                    : null,
                ].filter(Boolean)
              )}
            />
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}
