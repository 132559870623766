import React, { useEffect, useState } from 'react'
import { Card } from '@material-ui/core';
import CommonForm from '../../common/CommonForm2';
import Constants from '../../data/Constants'
import Actions from '../../data/Actions';
import CompanyPortalFormMenu from './CompanyPortalFormMenu';
import CompanyPortalFormFaq from './CompanyPortalFormFaq';
import { cleanObject } from './utils';
import CompanyPortalFormTabs from './CompanyPortalFormTabs';

export const CompanyPortalInnerHeader = ({ children }) => (
    <fieldset className='tw-mt-4 tw-py-2 tw-border-solid tw-border-t-2 tw-border-gray-200'>
        <legend>
            <h3 className='tw-text-gray-500 tw-pr-3'>
                {children}
            </h3>
        </legend>
    </fieldset>
)

const ImagePreview = ({ data }) => (
    <div className='tw-bg-gray-100 tw-mt-2 tw-p-5 tw-rounded tw-flex tw-justify-center tw-border-solid tw-border-2 tw-border-gray-200'>
        {Array.isArray(data) && <img src={data[0]?.base64 ?? data[0]?.preview} width={150} />}
    </div>
)

export default function CompanyPortalForm({ values, language, onClose }) {

    const configDefault = {
        logo_position_left: 0,
        logo_position_top: 0,
        topbar_background_color: '#FFFFFF',
        topbar_font_color: '#000000',
        topbar_font_color_hover: '#333333',
        topbar_sub_background_color: '#F3F4F6',
        topbar_sub_font_color: '#000000',
        button_action_background_color: '#0D6EFD',
        button_action_font_color: '#FFFFFF',
        button_default_background_color: '#ADB5BD',
        button_default_font_color: '#000000',
        homepage_background_color: '#FFFFFF',
        homepage_font_color: '#000000',
        catalog_item_title_font_color: '#0D6EFD',
        catalog_item_title_font_size: '18',
        catalog_item_font_color: '#000000',
        catalog_item_background_color: '#FFFFFF',
    }

    const [data, setData] = useState({ ...values, portal: values.portal ? { ...configDefault, ...values.portal } : configDefault, });
    const [isLoading, setIsLoading] = useState(false);

    const menuOptions = [
        {
            id: 1,
            index: 0,
            name: 'HOME',
            label: language['SERVICE_PORTAL']['MENU_HOME'],
            lang_key: 'MENU_HOME',
            checked: true,
            allowCheck: false,
        },
        {
            id: 2,
            index: 1,
            name: 'TICKET',
            label: language['SERVICE_PORTAL']['MENU_TICKET'],
            lang_key: 'MENU_TICKET',
            checked: true,
            allowCheck: false,
        },
        {
            id: 3,
            index: 2,
            name: 'CHANGE_PASSWORD',
            label: language['SERVICE_PORTAL']['MENU_CHANGE_PASSWORD'],
            lang_key: 'MENU_CHANGE_PASSWORD',
            checked: false,
            allowCheck: true,
        },
        {
            id: 4,
            index: 3,
            name: 'FAQ',
            label: language['SERVICE_PORTAL']['MENU_FAQ'],
            lang_key: 'MENU_FAQ',
            checked: false,
            allowCheck: true,
            Child: <CompanyPortalFormFaq dataCompany={data} language={language} />
        },
        {
            id: 5,
            index: 4,
            name: 'SEARCH',
            label: language['SERVICE_PORTAL']['MENU_SEARCH'],
            lang_key: 'MENU_SEARCH',
            checked: false,
            allowCheck: true,
        },
        {
            id: 6,
            index: 5,
            name: 'APPROVAL_CENTER',
            label: language['SERVICE_PORTAL']['MENU_APPROVAL_CENTER'],
            lang_key: 'MENU_APPROVAL_CENTER',
            checked: false,
            allowCheck: false,
        }
    ];

    const tabOptions = [
        {
            id: 1,
            index: 0,
            name: 'DETAIL',
            label: language['SERVICE_PORTAL']['TAB_DETAIL'],
            lang_key: 'TAB_DETAIL',
            checked: true,
            allowCheck: false,
        },
        {
            id: 2,
            index: 1,
            name: 'SLA_HISTORY',
            label: language['SERVICE_PORTAL']['TAB_SLA_HISTORY'],
            lang_key: 'TAB_SLA_HISTORY',
            checked: true,
            allowCheck: true,
        },
        {
            id: 3,
            index: 2,
            name: 'CONFIGURATION_ITEMS',
            label: language['SERVICE_PORTAL']['TAB_CONFIGURATION_ITEMS'],
            lang_key: 'TAB_CONFIGURATION_ITEMS',
            checked: true,
            allowCheck: true,
        },
        {
            id: 4,
            index: 3,
            name: 'DESIGNATION_HISTORY',
            label: language['SERVICE_PORTAL']['TAB_DESIGNATION_HISTORY'],
            lang_key: 'TAB_DESIGNATION_HISTORY',
            checked: true,
            allowCheck: true,
            //Child: <CompanyPortalFormFaq dataCompany={data} language={language} />
        },
        {
            id: 5,
            index: 4,
            name: 'RELATION_TYPE',
            label: language['SERVICE_PORTAL']['TAB_RELATION_TYPE'],
            lang_key: 'TAB_RELATION_TYPE',
            checked: true,
            allowCheck: true,
        }
    ];


    useEffect(() => {

        transformImageData(['logo_data', 'topbar_background_data', 'homepage_background_data']);

    }, [values])

    const fields = [
        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_LAYOUT}</CompanyPortalInnerHeader> },
        { col: 3, type: 'switch', name: 'layout_fluid', label: language.SERVICE_PORTAL.FORM_FIELD_LAYOUT_FLUID },
        { col: 12, type: 'element', content: <></> },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_BRAND}</CompanyPortalInnerHeader> },
        { col: 3, type: 'element', content: <label className='only-label'>{language.SERVICE_PORTAL.FORM_FIELD_BRAND_LOGO}</label> },
        { col: 3, type: 'element', content: <label className='only-label'>{language.SERVICE_PORTAL.FORM_FIELD_BRAND_PREVIEW}</label> },
        { col: 12, type: 'element', content: <></> },
        {
            col: 3, name: 'logo_data', label: language.IMAGE, type: 'file', startIcon: 'fas fa-upload',
            accept: 'image/*', maxFiles: 1, toBase64: true, customPortalCss: true,
            disabled: data?.portal?.logo_data?.length,
            btnProps: { color: data?.portal?.logo_data?.length ? 'secondary' : 'success' },
            removeFile: f => handleFileRemove(f.field)
        },
        { col: 3, type: 'element', content: <ImagePreview data={data?.portal?.logo_data} /> },
        { col: 12, type: 'element', content: <br /> },
        {
            col: 3, name: 'logo_position_left', label: language.SERVICE_PORTAL.FORM_FIELD_BRAND_LEFT, type: 'range', startIcon: 'fas fa-arrow-right',
            startOutput: true, startOutputFormat: (data) => `${data ?? '0'}%`,
            inputProps: { max: 100, min: 0, value: data?.portal?.logo_position_left ?? 0 }
        },
        {
            col: 3, name: 'logo_position_top', label: language.SERVICE_PORTAL.FORM_FIELD_BRAND_TOP, type: 'range', startIcon: 'fas fa-arrow-down',
            startOutput: true, startOutputFormat: (data) => `${data ?? '0'}%`,
            inputProps: { max: 100, min: 0, value: data?.portal?.logo_position_top ?? 0 }
        },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_HEADER}</CompanyPortalInnerHeader> },
        { col: 3, type: 'element', content: <label className='only-label'>{language.SERVICE_PORTAL.FORM_FIELD_HEADER_BG_IMG}</label> },
        { col: 3, type: 'element', content: <label className='only-label'>{language.SERVICE_PORTAL.FORM_FIELD_HEADER_BG_PREVIEW}</label> },
        { col: 12, type: 'element', content: <></> },
        {
            col: 3, name: 'topbar_background_data', label: language.IMAGE, type: 'file', startIcon: 'fas fa-upload',
            accept: 'image/*', maxFiles: 1, toBase64: true, customPortalCss: true,
            disabled: data?.portal?.topbar_background_data?.length,
            btnProps: { color: data?.portal?.topbar_background_data?.length ? 'secondary' : 'success' },
            removeFile: f => handleFileRemove(f.field)
        },
        { col: 3, type: 'element', content: <ImagePreview data={data?.portal?.topbar_background_data} /> },
        { col: 12, type: 'element', content: <br /> },
        { col: 3, name: 'topbar_background_color', label: language.SERVICE_PORTAL.FORM_FIELD_HEADER_BG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'topbar_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_HEADER_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'topbar_font_color_hover', label: language.SERVICE_PORTAL.FORM_FIELD_HEADER_COLOR_HOVER, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_SUBHEADER}</CompanyPortalInnerHeader> },
        { col: 3, name: 'topbar_sub_background_color', label: language.SERVICE_PORTAL.FORM_FIELD_SUBHEADER_BG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'topbar_sub_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_SUBHEADER_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_ACTION_BUTTON}</CompanyPortalInnerHeader> },
        { col: 3, name: 'button_action_background_color', label: language.SERVICE_PORTAL.FORM_FIELD_ACTION_BUTTON_BG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'button_action_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_ACTION_BUTTON_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_BASE_BUTTON}</CompanyPortalInnerHeader> },
        { col: 3, name: 'button_default_background_color', label: language.SERVICE_PORTAL.FORM_FIELD_BASE_BUTTON_BG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'button_default_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_BASE_BUTTON_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_HOMEPAGE}</CompanyPortalInnerHeader> },
        { col: 3, type: 'element', content: <label className='only-label'>{language.SERVICE_PORTAL.FORM_FIELD_HOMEPAGE_BG_IMG}</label> },
        { col: 3, type: 'element', content: <label className='only-label'>{language.SERVICE_PORTAL.FORM_FIELD_HOMEPAGE_BG_PREVIEW}</label> },
        { col: 12, type: 'element', content: <></> },
        {
            col: 3, name: 'homepage_background_data', label: language.IMAGE, type: 'file', startIcon: 'fas fa-upload',
            accept: 'image/*', maxFiles: 1, toBase64: true, customPortalCss: true,
            disabled: data?.portal?.homepage_background_data?.length,
            btnProps: { color: data?.portal?.homepage_background_data?.length ? 'secondary' : 'success' },
            removeFile: f => handleFileRemove(f.field)
        },
        { col: 3, type: 'element', content: <ImagePreview data={data?.portal?.homepage_background_data} /> },
        { col: 12, type: 'element', content: <br /> },
        { col: 3, name: 'homepage_background_color', label: language.SERVICE_PORTAL.FORM_FIELD_HOMEPAGE_BG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'homepage_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_HOMEPAGE_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_CATALOG}</CompanyPortalInnerHeader> },
        { col: 3, name: 'catalog_item_title_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_CATALOG_TITLE_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'catalog_item_title_font_size', label: language.SERVICE_PORTAL.FORM_FIELD_CATALOG_TITLE_SIZE, type: 'text', startIcon: 'fas fa-text-size', },
        { col: 3, name: 'catalog_item_font_color', label: language.SERVICE_PORTAL.FORM_FIELD_CATALOG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette', },
        { col: 3, name: 'catalog_item_background_color', label: language.SERVICE_PORTAL.FORM_FIELD_CATALOG_BG_COLOR, type: 'html5-color', startOutput: true, startIcon: 'fas fa-palette' },

        { col: 12, type: 'element', content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_CSS}</CompanyPortalInnerHeader> },
        { col: 12, name: 'custom_css_text', label: language.SERVICE_PORTAL.FORM_FIELD_CSS_TEXT, type: 'simplesTextEditor' },
        {
            col: 12, type: 'element',
            content: <small>
                <pre>
                    {language.AVAILABLE_CLASSES}:
                    .sp-wrapper, .sp-header, .sp-header-container, .sp-logo, .sp-menu, .sp-subheader, .sp-subheader-container, .sp-container, .sp-catalog, .sp-catalog-item
                </pre>
            </small>
        },
        { col: 12, name: 'ivi_code', label: language.SERVICE_PORTAL.FORM_FIELD_IVI_CODE, type: 'simplesTextEditor' },
        // {
        //     col: 12,
        //     type: 'element',
        //     content: <CompanyPortalInnerHeader>{language.SERVICE_PORTAL.FORM_GROUP_MENU}</CompanyPortalInnerHeader>
        // },
        // {
        //     col: 12,
        //     type: 'element',
        //     content: <CompanyPortalFormMenu language={language} />
        // }
    ];

    const convertBase64ToFile = (base64String, fileName) => {
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let uint8Array = new Uint8Array(n);
        while (n--) uint8Array[n] = bstr.charCodeAt(n);

        return new File([uint8Array], fileName, { type: mime });
    }

    const transformImageData = (items = []) => {
        let _data = { ...data };

        items.forEach(item => {
            if (_data?.portal?.[item]) {
                const image = convertBase64ToFile(_data.portal[item], language.IMAGE);
                image.field = item;
                image.base64 = _data.portal[item];
                _data.portal[item] = [image]
            }
        })

        setData(_data);
    }

    const handleFileRemove = item => handleChangeField({ name: item }, null);

    const handleFormSubmit = (formData) => {

        setIsLoading(true);

        const isEdit = data.hasOwnProperty('portal') && data.portal.hasOwnProperty('id_company_portal');
        const baseUrl = `${Constants.APIEndpoints.COMPANY}/${data.id_company}/portal`
        const method = isEdit ? 'put' : 'post';
        const url = isEdit ? `${baseUrl}/${data.portal.id_company_portal}` : baseUrl;

        if (Array.isArray(formData.logo_data))
            formData.logo_data = formData.logo_data[0]?.base64;

        if (Array.isArray(formData.topbar_background_data))
            formData.topbar_background_data = formData.topbar_background_data[0]?.base64;

        if (Array.isArray(formData.homepage_background_data))
            formData.homepage_background_data = formData.homepage_background_data[0]?.base64;

        const requestFields = ['name', 'index', 'lang_key', 'checked', 'allowCheck'];


        formData.menu = cleanObject(formData.menu ?? menuOptions, requestFields);
        formData.tabs = cleanObject(formData.tabs ?? tabOptions, requestFields);

        //console.log('formData', formData)
        //console.log('formData', JSON.stringify(formData))

        const dispatcher = new Actions();
        dispatcher
            .execute(method, url, 'company_portal', formData, 'id_company_portal')
            .then(() => onClose())
            .finally(() => setIsLoading(false));
    }

    const handleChangeField = (field, value) => {
        let _data = { ...data };
        _data.portal[field.name] = value;
        setData(_data);
    }

    return (
        <Card className="content tw-pt-4" style={{ overflow: 'visible' }}>
            <CommonForm
                fields={fields}
                values={data.portal}
                onSubmit={handleFormSubmit}
                onChangeField={handleChangeField}
                loading={isLoading}
                button={{
                    md: 2,
                    offset: { md: 10 },
                    label: language.SAVE,
                    primary: true,
                    disabled: isLoading,
                    style: { marginBottom: 10, float: 'right' },
                }}
            />
            <div className='tw-flex tw-gap-4'>
                <CompanyPortalFormMenu language={language} data={data} menuOptions={menuOptions} onClose={onClose} />
                <CompanyPortalFormTabs language={language} data={data} tabsOptions={tabOptions} onClose={onClose} />
            </div>
        </Card>
    )
}
