import React, {Fragment, useState, memo, useEffect} from 'react';
import './index.css';
//--------------------------common---------------------------- 
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Progress from '@common/Progress';
import Button from '@common/Button';
//--------------------------material-ui---------------------------- 
import {Checkbox} from '@material-ui/core';
//--------------------------pages---------------------------- 
import TableListProjects from './TableListProjects';
import TableListActivities from './TableListActivities';

export default memo(({
	open,
	onClose,
	onSave,
    projects,
    users,
    action,
    APIEndpoints
})=>{
	let [viewProjectsId, setViewProjectsId] = useState(0);
	return(
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth='lg'
			bar={false}
			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
		>
			{!viewProjectsId?(
				<TableListProjects  
                    onClose={onClose} 
                    setViewProjectsId={setViewProjectsId} 
                    users={users} 
                    projects={projects}
                />
			):( 
			    <TableListActivities
                    action={action}
                    APIEndpoints={APIEndpoints}
                    onSave={onSave}
                    users={users}
                    viewProjectsId={viewProjectsId} onClose={onClose} setViewProjectsId={setViewProjectsId}
                />

			)}

		</Dialog>
	)
})