import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../../../common/RaisedButton';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonTable from '../../../../common/CommonTable';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from 'recharts';
import moment from 'moment';
import { format } from 'url';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../graphs/SimplesBarGrap';

export default class GSDInformations extends Component {
  render() {
    let { language, handlePage, tickets, serviceOrders } = this.props;

    let col = [
      { key: 'id_tickets', label: language.ID, style: { width: 20 } },
      { key: 'name_person', label: language.REQUESTER },
      { key: 'name_depart', label: language.DEPARTMENT },
      { key: 'subject', label: language.SUBJECT },
      { key: 'sla_name', label: language.SLA }
    ];
    let data = tickets
      .filter(ti => ti.type_tickets === 'Incident')
      .map(t => ({
        id_tickets: t.id_tickets,
        name_person: t.name_person,
        name_depart: t.name_depart,
        subject: t.subject,
        sla_name: t.sla_name
      }));

    let colOs = [
      { key: 'id_os', label: language.ID, style: { width: 20 } },
      { key: 'company_name', label: language.COMPANY },
      { key: 'description', label: language.DESCRIPTION },
      { key: 'tech_name', label: language.TECH }
    ];
    let dataOs = serviceOrders.map(os => ({
      id_os: os.id_os,
      company_name: os.company_name,
      description: os.description,
      tech_name: os.tech_name
    }));

    let colProblem = [
      { key: 'id_tickets', label: language.ID, style: { width: 20 } },
      { key: 'name_person', label: language.REQUESTER },
      { key: 'name_depart', label: language.DEPARTMENT },
      { key: 'subject', label: language.SUBJECT },
      { key: 'description', label: language.DESCRIPTION },
      { key: 'type_tickets', label: language.TYPE }
    ];
    let dataProblem = tickets
      .filter(t => t.type_tickets === 'Problem')
      .map(t => ({
        id_tickets: t.id_tickets,
        name_person: t.name_person,
        name_depart: t.name_depart,
        subject: t.subject,
        description: t.description,
        type_tickets: t.type_tickets
      }));


    var dataSimpleBarChart = ContainerChart.container0({
      title: language.INCIDENT_MANAGEMENT,
      subtitle: language.QUANTITY_PER_MONTH,
      yTitle: null,
      series: [
        {
          type: 'column',
          name: language.TECH,
          data: [
            { name: 'Jan', y: 20 },
            { name: 'Fev', y: 30 }
          ]
        }
      ],

    })

    return (
      <PageLayout
        icon={
          <div className="imgGsd" style={{ width: '44px', height: '44px' }} />
        }
        back={()=>   handlePage('dash')}
        title={language.REPORTS}
        subtitle={language.ANALYSIS_OF_INFORMATION_GSD}
        rightElements={[]}
      >
        <Card className="cardDefault pt-3">
          <Container fluid>
            <Row>
              <Col md={4}>
                <ReactHighcharts config={dataSimpleBarChart} />
              </Col>
              <Col md={4}>

                <ReactHighcharts config={{ ...dataSimpleBarChart, title: { text: language.PROBLEM_MANAGEMENT, ...dataSimpleBarChart.title } }} />
              </Col>
              <Col md={4}>

                <ReactHighcharts config={{ ...dataSimpleBarChart, title: { text: language.CHANGE_MANAGEMENT, ...dataSimpleBarChart.title } }} />
              </Col>
            </Row>
          </Container>
        </Card>

        <CommonTable
          title={language.INCIDENT_MANAGEMENT}
          language={language}
          columns={col}
          data={data}
          hideFooterToolbar
        />

        <CommonTable

          language={language}
          columns={colOs}
          data={dataOs}
          hideFooterToolbar
          title={language.SERVICE_ORDER}
        />

        <CommonTable
          title={language.PROBLEM_MANAGEMENT}
          language={language}
          columns={colProblem}
          data={dataProblem}
          hideFooterToolbar
        />

      </PageLayout>
    );
  }
}

class SimpleBarChart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={this.props.dataSimpleBarChart}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Legend />
          <Bar dataKey="Amount" fill="var(--primary)" barSize={35}>
            <LabelList dataKey="Amount" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
