import React, { useEffect, useState } from 'react';
var Highcharts = require('highcharts');
import moment from 'moment';
import Store from '@data/Store';

const { language } = Store;

export function PeriodTask({ taskPeriod }) {
  const [expiredChart, setExpireChart] = useState([]);
  const [inDayChart, setInDayChart] = useState([]);
  const [finishChart, setFinishChart] = useState([]);

  var chart;
  var config = {
    chart: {
      type: 'column',
    },
    colors: ['#E9C3C3', '#E9DFC3', '#C8E9C3'],
    title: {
      text: '',
    },
    credits: { enabled: false },

    xAxis: {
      categories: [
        language.DATE_LANG.monthList[0],
        language.DATE_LANG.monthList[1],
        language.DATE_LANG.monthList[2],
        language.DATE_LANG.monthList[3],
        language.DATE_LANG.monthList[4],
        language.DATE_LANG.monthList[5],
        language.DATE_LANG.monthList[6],
        language.DATE_LANG.monthList[7],
        language.DATE_LANG.monthList[8],
        language.DATE_LANG.monthList[9],
        language.DATE_LANG.monthList[10],
        language.DATE_LANG.monthList[11],
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
        },
      },
    },
    //  legend: {
    //    align: 'right',
    //    x: -30,
    //    verticalAlign: 'top',
    //    y: 25,
    //    floating: true,
    //    backgroundColor:
    //      Highcharts.defaultOptions.legend.backgroundColor || 'white',
    //    borderColor: '#CCC',
    //    borderWidth: 1,
    //    shadow: false,
    //  },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: language.EXPIRED,
        data: [
          expiredChart[0],
          expiredChart[1],
          expiredChart[2],
          expiredChart[3],
          expiredChart[4],
          expiredChart[5],
          expiredChart[6],
          expiredChart[7],
          expiredChart[8],
          expiredChart[9],
          expiredChart[10],
          expiredChart[11],
        ],
      },
      {
        name: language.ON_TIME,

        data: [
          inDayChart[0],
          inDayChart[1],
          inDayChart[2],
          inDayChart[3],
          inDayChart[4],
          inDayChart[5],
          inDayChart[6],
          inDayChart[7],
          inDayChart[8],
          inDayChart[9],
          inDayChart[10],
          inDayChart[11],
        ],
      },
      {
        name: language.CONCLUDED,
        data: [
          finishChart[0],
          finishChart[1],
          finishChart[2],
          finishChart[3],
          finishChart[4],
          finishChart[5],
          finishChart[6],
          finishChart[7],
          finishChart[8],
          finishChart[9],
          finishChart[10],
          finishChart[11],
        ],
      },
    ],
  };

  useEffect(() => {
    if (taskPeriod) {
      const finish = [
        taskPeriod[0] &&
          taskPeriod[0].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[1] &&
          taskPeriod[1].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[2] &&
          taskPeriod[2].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[3] &&
          taskPeriod[3].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[4] &&
          taskPeriod[4].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[5] &&
          taskPeriod[5].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[6] &&
          taskPeriod[6].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[7] &&
          taskPeriod[7].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[8] &&
          taskPeriod[8].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[9] &&
          taskPeriod[9].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[10] &&
          taskPeriod[10].filter((r) => r.status === 'CONCLUIDO').length,
        taskPeriod[11] &&
          taskPeriod[11].filter((r) => r.status === 'CONCLUIDO').length,
      ];

      const notFinish0 = taskPeriod[0].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish1 = taskPeriod[1].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish2 = taskPeriod[2].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish3 = taskPeriod[3].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish4 = taskPeriod[4].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish5 = taskPeriod[5].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish6 = taskPeriod[6].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish7 = taskPeriod[7].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish8 = taskPeriod[8].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish9 = taskPeriod[9].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish10 = taskPeriod[10].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const notFinish11 = taskPeriod[11].filter(
        (r) =>
          r.status == 'AGUARDANDO' ||
          r.status == 'ANDAMENTO' ||
          r.status == 'INICIADO'
      );

      const expired = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const inDay = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      notFinish0.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[0] = expired[0] + 1;
        } else {
          inDay[0] = inDay[0] + 1;
        }
      });

      notFinish1.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[1] = expired[1] + 1;
        } else {
          inDay[1] = inDay[1] + 1;
        }
      });

      notFinish2.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[2] = expired[2] + 1;
        } else {
          inDay[2] = inDay[2] + 1;
        }
      });

      notFinish3.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[3] = expired[3] + 1;
        } else {
          inDay[3] = inDay[3] + 1;
        }
      });

      notFinish4.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[4] = expired[4] + 1;
        } else {
          inDay[4] = inDay[4] + 1;
        }
      });

      notFinish5.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[5] = expired[5] + 1;
        } else {
          inDay[5] = inDay[5] + 1;
        }
      });

      notFinish6.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[6] = expired[6] + 1;
        } else {
          inDay[6] = inDay[6] + 1;
        }
      });

      notFinish7.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[7] = expired[7] + 1;
        } else {
          inDay[7] = inDay[7] + 1;
        }
      });

      notFinish8.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[8] = expired[8] + 1;
        } else {
          inDay[8] = inDay[8] + 1;
        }
      });

      notFinish9.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[9] = expired[9] + 1;
        } else {
          inDay[9] = inDay[9] + 1;
        }
      });

      notFinish10.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[10] = expired[10] + 1;
        } else {
          inDay[10] = inDay[10] + 1;
        }
      });

      notFinish11.filter((r) => {
        const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(r.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(r.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        if (moment(dateNow).isAfter(moment(dateAddTime))) {
          expired[11] = expired[11] + 1;
        } else {
          inDay[11] = inDay[11] + 1;
        }
      });

      setTimeout(() => {
        setInDayChart(inDay);

        setExpireChart(expired);

        setFinishChart(finish);
      }, 1000);
    }
  }, [taskPeriod]);

  useEffect(() => {
    if (chart) {
      if (window.Highcharts) {
        chart = window.Highcharts.chart(chart, config);
      }
    }

    return function () {
      if (chart) {
        chart.destroy();
      }
    };
  }, [config]);

  return <div ref={(ref) => (chart = ref)} />;
}
