

export function CallsAnswered1({ language, data = [], setMonth, month }) {
  let categories = language.DATE_LANG.monthList;
  return {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Roboto',
      },
      height: 230,
      /*options3d: {
           enabled: true,
           alpha: 10,
           beta: 20,
           depth: 70
         }*/
      events: {
        load: function () {
          var axis = this.xAxis[0];
          var ticks = axis.ticks;
          var points = this.series[0].points;
          var tooltip = this.tooltip;

          points.forEach(function (point, i) {
            if (ticks[i]) {
              var label = ticks[i].label.element;

              label.onclick = function () {
                setMonth((i + 1).toString().padStart(2, 0));
              };
            }
          });
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories,
      crosshair: false,
      labels: {
        style: {
          color: 'var(--primary)',
          fontSize: 10,
        },
        useHTML: true,
        formatter() {
          return `
                     <a class="btn-month-selector ${
                       categories[parseInt(month) - 1] == this.value
                         ? 'active'
                         : ''
                     }">
                        ${this.value}
                     </a>
                  `;
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: language.QUANTITIES,
        style: {
          fontFamily: 'Open Sans',
          color: 'var(--primary)',
          fontSize: 10,
        },
      },
      labels: {
        style: {
          color: 'var(--primary)',
          fontSize: 10,
        },
      },
    },
    tooltip: { shared: true },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },

      series: {
        borderRadius: 1,
        dataLabels: {
          enabled: true,
          y: -5,
          format: '{point.y:.0f}',
          borderWidth: 0,

          style: {
            fontSize: 10,
            color: 'var(--primary)',
            textShadow: false,
            textOutline: 0,
          },
        },
        // events: {
        //    click: function (event) {
        //       alert(
        //          this.name + ' clicked\n' +
        //          'Alt: ' + event.altKey + '\n' +
        //          'Control: ' + event.ctrlKey + '\n' +
        //          'Meta: ' + event.metaKey + '\n' +
        //          'Shift: ' + event.shiftKey
        //       );
        //    }
        // }
      },
    },
    legend: {
      backgroundColor: 'transparent',
      enabled: false,
    },
    series: [
      {
        name: language.CALLS,
        color: 'var(--primary)',
        data,
        tooltip: { valuePrefix: ' ' + ' ' },
      },
    ],
  };
}

export function radial({
  data,
  name,
  height = 150,
  width,
  titlePosition = 30,
  title = {},
  subTitle = {},
  minPointSize = 15,
  innerSize = '100%',
}) {
  return {
    chart: {
      type: 'variablepie',
      height: height,
      width: width,
      style: {
        fontFamily: 'Roboto',
      },
    },
    title: {
      text: `<span style="${title.style}; font-weight:bold; font-size:25px; color:var(--default);">${title.content}</span>
               <br>
               <span style="${subTitle.style}; font-size:12px; color:var(--default);">${subTitle.content}</span>`,
      align: 'center',
      verticalAlign: 'middle',
      y: titlePosition,
    },
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/> {point.y}',
    },
    credits: { enabled: false },
    plotOptions: {
      variablepie: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        minPointSize,
        innerSize,
        zMin: 0,
        name,
        data,
      },
    ],
  };
}

export function Average({ categories, data }) {
  return {
    chart: {
      type: 'bar',
      height: 325,
      style: {
        fontFamily: 'Roboto',
      },
    },
    credits: { enabled: false },
    title: null,
    xAxis: {
      categories: categories,
      labels: {
        style: {
          color: 'var(--primary)',
          fontSize: 10,
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'medias',
        style: {
          fontFamily: 'Open Sans',
          color: 'var(--primary)',
          fontSize: 10,
        },
      },
      labels: {
        style: {
          color: 'var(--primary)',
          fontSize: 10,
        },
      },
    },
    legend: {
      reversed: true,
      itemStyle: {
        fontSize: 11,
        color: 'var(--default)',
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        borderRadius: 1,
        dataLabels: {
          enabled: true,
          format: '{point.y:.0f}',
          borderWidth: 0,
          style: {
            fontSize: 10,
            color: 'white',
            textShadow: false,
            textOutline: 0,
          },
        },
        marker: {},
      },
    },
    series: data,
  };
}
