import React, { useState, useEffect } from 'react';
import Button from '@common/Button';
import { Card } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Edit from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Store from '../../../data/Store';
import Dialog from '../../../common/Dialog';
import AttachFile from '../../../common/AttachFile';
import PageLayout from '../../../common/PageLayout';
import CommonForm from '../../../common/CommonForm2';
import DownloadFiles from '../../../util/DownloadFiles';
import DialogConfirm from '../../../common/DialogConfirm';
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { USER_ROLE_ENUM } from '../../../enums/user';
import { moment } from '../../../util/dateTime';
import { toBase64 } from '@common/Func';

import './index.css';

import InfoTicket from './InfoTicket';
import RegisterActivity from './RegisterActivity';
import If from '../../If';

const CabRecord = ({ action, language, APIEndpoints, id, onClose }) => {
  const [data, setData] = useState('');

  useEffect(() => {
    action
      .execute('get', `${APIEndpoints.CHANGE}/${id}/cab/record`)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      });
  }, []);

  const onDownFile = (f) => {
    DownloadFiles(
      `${APIEndpoints.CHANGE}/cab/record/${f.id_change_cab_file}&${f.file}`,
      f.file
    );
  };

  const onRemoveFile = (f) => {
    action
      .execute('delete', `${APIEndpoints.CHANGE}/${id}/cab/record`, '', {
        id_change_cab_file: f.id_change_cab_file,
      })
      .then((h) => {
        Store.toggleAlert(true, language.REGISTER_DELETED, 'success');
      })
      .catch((error) => {
        Store.toggleAlert(
          true,
          error.data.code || language.OPERATION_NOT_PERFORMED,
          'error'
        );
      });
  };

  const onSubmit = (values) => {
    action
      .execute('put', `${APIEndpoints.CHANGE}/${id}/cab`, '', values)
      .then((h) => {
        Store.toggleAlert(true, language.MINUTES_SUCCESSFULLY_RECORDED, 'success');
        onClose();
      })
      .catch((error) => {
        Store.toggleAlert(
          true,
          error.data.code || language.OPERATION_NOT_PERFORMED,
          'error'
        );
      });
  };

  const submitRecord = (values) => {
    if (!values.files || !values.files.length) {
      onSubmit(values);
      return;
    }

    let filesSend = [];
    let cont = 0;

    const total = Array.from(values.files).length;

    Array.from(values.files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then((result) => {
        if (file.size >= 40000000) {
          alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
          this.setState({ sendFile: false });

          cont = 0;
        } else {
          filesSend.push({
            file: result,
            type: file.type,
            name: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.files = filesSend;
            onSubmit(values);
          }
        }
      });
    });
  };

  if (data) {
    return (
      <>
        <CommonForm
          fields={[
            {
              span: 12,
              name: 'record',
              label: '',
              type: 'textEditor',
            },
            {
              col: 4,
              name: 'files',
              label: 'Anexo',
              type: 'file',
              removeFile: (f) => onRemoveFile(f),
              onClick: (f) => onDownFile(f),
            },
          ]}
          values={data}
          onSubmit={submitRecord}
        />
      </>
    );
  }
  return (
    <div>
      <CommonForm
        fields={[
          {
            span: 12,
            name: 'record',
            label: '',
            type: 'textEditor',
          },
          {
            col: 4,
            name: 'files',
            label: 'Anexo',
            type: 'file',
          },
        ]}
        onSubmit={submitRecord}
      />
    </div>
  );
};

export default function ({
  APIEndpoints,
  action,
  language,
  change,
  userl,
  companies,
  contracts,
  users,
  devices,
  tickets,
  changeStatus,
  activities_,
  store,
  redirect,
  change_risks,
  refreshChange,
  cabBoard,
}) {
  var view = 0;
  var actionsOptions = [
    { icon: 'fas fa-tools', label: language.CORRECTIVE, value: 1 },
    {
      icon: 'fas fa-exclamation-triangle',
      label: language.EMERGENCY,
      value: 2,
    },
    { icon: 'fas fa-chart-line', label: language.NORMAL, value: 3 },
    { icon: 'fas fa-shield-alt', label: language.DEFAULT, value: 4 },
  ];

  let [modal, SetModal] = useState(false);
  let [ac, SetAc] = useState(null);
  let [activities, Setactivities] = useState([]);
  let [openCon, setopenCon] = useState(false);
  const [cabDecision, setCabDecision] = useState('');
  const [cabModal, setCabModal] = useState(false);
  const [validTech, setvalidTech] = useState(false);
  const [finishGMUDModal, setFinishGMUDModal] = useState(false);
  const [openFinishedJustify, setOpenFinishedJustify] = useState(false);
  const [openRevisionImplementation, setOpenRevisionImplementation] =
    useState(false);
  const [isAllowedActionsGMud, setAllowedActionsGMud] = useState(false);
  const [isAllowedUnfreezeGmud, setIsAllowedUnfreezeGmud] = useState(false);
  const [openRPIJustify, setOpenRPIJustify] = useState(false);
  const getStatus = {
    Create: language.GMUD_STATUS.CREATED,
    Open: language.GMUD_STATUS.OPENED,
    Analyze: language.GMUD_STATUS.ANALYZING,
    Rejected: language.GMUD_STATUS.REJECTED,
    Cancelled: language.GMUD_STATUS.CANCELED,
    Rollback: language.ROLLBACK,
    Closed: language.GMUD_STATUS.CLOSE,
    Concluded: language.GMUD_STATUS.CONCLUDED,
    CAB: language.STATUS_LOG_CAB,
    Finished: language.GMUD_STATUS.FINISHED_SUCCESS,
    Progress: language.IN_PROGRESS,
    Concluded_Fail: language.GMUD_STATUS.CONCLUDED_FAIL,
    Concluded_Partial: language.GMUD_STATUS.CONCLUDED_PART,
    Concluded_Cancel: language.GMUD_STATUS.CONCLUDED_CANCEL,
    Finish_Fail: language.GMUD_STATUS.FINISHED_FAIL,
    Finish_Partial: language.GMUD_STATUS.FINISHED_PARTIALLY,
    Finish_Cancel: language.GMUD_STATUS.FINISHED_CANCEL,
    Revision_After_Implementation: language.GMUD_STATUS.POST_IMPLEMENTATION_REVIEW,
  };

  const validTechCheck = (id, ac) => {
    if (!id) return;
    if (ac.fk_id_sponsor > 0) {
      setvalidTech(true);
    } else {
      action
        .execute('get', `${APIEndpoints.CHANGE}/validtech/${id}`)
        .then((res) => {
          if (res.data.length > 0) {
            setvalidTech(true);
          } else {
            Store.toggleAlert(
              true,
              language.YOU_ARE_NOT_PART_OF_THE_TECH_GROUP,
              'error'
            );
            setvalidTech(false);
          }
        });
    }
  };

  useEffect(() => {
    Setactivities(activities_);
  }, [activities_]);

  const donwFileAc = (id, file, type) => {
    DownloadFiles(
      APIEndpoints.REQUESTS + '/api/downloadchangeac/' + id + '&' + file,
      file
    );
  };

  const donwFileAc_ = (id, file, type) => {
    DownloadFiles(
      APIEndpoints.REQUESTS + '/api/downloadchangeevidence/' + id + '&' + file,
      file
    );
  };

  const donwFileFinish = (id, file, type) => {
    DownloadFiles(
      APIEndpoints.REQUESTS + '/downloadchangefinish/' + id + '&' + file,
      file
    );
  };

  const donwFileRPI = (id, file, type) => {
    DownloadFiles(
      APIEndpoints.REQUESTS + '/downloadchangerpi/' + id + '&' + file,
      file
    );
  };

  const resolveCab = (values) => {
    const dataCab = {
      decision: cabDecision,
    };
    if (cabDecision === 'rejected') {
      dataCab.log = values.log;
    }
    action
      .execute(
        'post',
        APIEndpoints.CHANGE + '/' + change.id_change + '/cab/resolve',
        '',
        dataCab
      )
      .then(() => {
        setCabDecision('');
        refreshChange(change.id_change);
        setTimeout(() => {
          redirect('/dash/changemanagement');
        }, 1000);
      })
      .catch((error) => {

        let messageError = language.ERROR_REQUEST;

        if( error.data?.id_error === "58db1daa-b5eb-4fcd-949a-d13b2d523b84" ) {
          messageError = 'gmud id not found';
        }else if(error.data?.id_error === "258eb0a0-f312-4bc1-9107-8a851fb00373"){
          messageError = language.USER_NOT_ALLOWED;
        }else if(error.data?.id_error === "d86198c0-ba14-4f30-87e9-5a5374038b25"){
          messageError = 'GMUD is not in CAB!';
        }

        Store.toggleAlert(true, messageError, 'error');

        setCabDecision('');
      });
  };

  const Progress = (v) => {
    action
      .execute(
        'post',
        APIEndpoints.CHANGE + '/activity_milestone/' + v.id_change_activity,
        '',
        v
      )
      .then((h) => {
        action
          .execute(
            'get',
            `${APIEndpoints.CHANGE}/activities_milestone/${v.id_change}`,
            'activities',
            null,
            null,
            { btn_loading: 'change_risks' }
          )
          .then((g) => {
            Setactivities(g.data);
          });
      });
  };

  const onFormSubmitAc = (values) => {
    action
      .execute(
        'post',
        APIEndpoints.CHANGE + '/activity_milestone/' + values.id_activity,
        '',
        values
      )
      .then((h) => {
        action
          .execute(
            'get',
            `${APIEndpoints.CHANGE}/activities_milestone/${values.id_change}`,
            'activities',
            null,
            null,
            { btn_loading: 'change_risks' }
          )
          .then((g) => {
            Setactivities(g.data);
            let acInfo = g.data.filter((g) => g.checked != true);
            if (acInfo && acInfo.length == 0) {
              redirect('/dash/changemanagement');
            }
          });
      });
  };

  const changeStatus_ = (opt) => {
    let acInfo = activities.filter((g) => g.checked != true && g.type != 3);
    if (acInfo && acInfo.length > 0) {
      Store.toggleAlert(true, language.ACTIVITY_PENDENT, 'error');
    } else {
      if (
        activities.filter((g) => g.checked != true && g.type == 3).length > 0
      ) {
        Store.toggleAlert(
          true,
          language.ROLLBACK_PENDING_ACTIVITIES,
          'error'
        );
      } else {
        if (opt == 'finish') {
          setFinishGMUDModal(true);
        } else {
          changeStatus(opt, change.id_change);
        }
      }
    }
  };

  const revisionGmud = () => {
    if (userl.role == 'client') {
      setOpenRevisionImplementation(true);
    } else {
      action
        .execute(
          'get',
          `${APIEndpoints.CHANGE}/verifyUserCanRPI/${change.id_change}`,
          '',
          null
        )
        .then((res) => {
          setOpenRevisionImplementation(true);
        })
        .catch((e) => {
          if ((e.data.code = 'E008')) {
            Store.toggleAlert(
              true,
              language.YOU_ARE_NOT_PART_OF_THE_GROUP_REVISION,
              'error'
            );
          }
        });
    }
  };

  const finishGMUD = (values) => {
    values.fk_id_change = change.id_change;
    values.action = 'finish';
    let filesSend = [];
    let cont = 0;
    if (values.files && values.files != '') {
      let total = Array.from(values.files).length;
      Array.from(values.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 10000000) {
            alert(language.FILE + file.name + language.MORE_THAN_10MB);
            this.setState({ sendFile: false });
            filesSend = [];
            values.files = [];
            cont = 0;
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.file = filesSend;
            changeStatus('finish', values);
          }
        });
      });
    } else {
      changeStatus('finish', values);
    }
  };

  const onFormSubmit = async (values) => {
    if( ac === "new_attachments" ) {
      if(values.file){
        values.file = values.file.filter(g=>g.size != 0)
      }
      if(values.file&& values.file.length > 0 ){
        let files = await toBase64(values.file);
        let itens = files.map((h) => {
          return {
            file: h.base64,
            namefile: h.file.name,
            type: h.file.type,
          };
        });
        values.file = itens;
      }
      action
        .execute(
          'post',
          `${APIEndpoints.CHANGE}/attachments/${change.id_change}`,
          '',
          values
        )
        .then((res) => {
          Store.toggleAlert(
            true,
            language.ALERT_INSERT_SUCCESS,
            'success'
          );
          refreshChange(change.id_change);
          SetModal(false);
        })
        .catch((e) => {
          console.log('error', e);
          Store.toggleAlert(
            true,
            language.THERE_WAS_AN_ERROR_SAVING_THE_FIELDS,
            'error'
          );
        });
    } else if( ac === 'back_edit' ) {
      action
        .execute(
          'put',
          `${APIEndpoints.CHANGE}/${change.id_change}`,
          '',
          {
            action: 'updateAnalize',
            log: values.log
          }
        )
        .then((res) => {
          redirect('/dash/changemanagement');
        }, (error) => {
          Store.toggleAlert(
            true,
            language.GMUD_MANAGER,
            'error'
          );
          console.log(error);
        });
    } else {
      values.fk_id_change = change.id_change;
      values.approved = ac === 'approve';
      SetModal(false);
      changeStatus(ac, values);
    }
  };

  const saveRPI = (values) => {
    values.fk_id_change = change.id_change;

    let filesSend = [];
    let cont = 0;
    if (values.files && values.files != '') {
      let total = Array.from(values.files).length;
      Array.from(values.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 10000000) {
            alert(language.FILE + file.name + language.MORE_THAN_10MB);
            this.setState({ sendFile: false });
            filesSend = [];
            values.files = [];
            cont = 0;
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.file = filesSend;
            action
              .execute('post', `${APIEndpoints.CHANGE}/createRPI`, '', values)
              .then((res) => {
                redirect('/dash/changemanagement');
              });
          }
        });
      });
    } else {
      action
        .execute('post', `${APIEndpoints.CHANGE}/createRPI`, '', values)
        .then((res) => {
          redirect('/dash/changemanagement');
        });
    }
  };

  const onVerifyGroupUser = async () => {
    try {
      if(userl.role == USER_ROLE_ENUM.CLIENT){
        setAllowedActionsGMud(change._status === 'Analyze' && Store.params[0].enable_general_approve_reprove_button_gmud);
        setIsAllowedUnfreezeGmud(change.is_freezed);
        return;
      };

      const {
        data: { belongsToGroup },
      } = await action.execute(
        'get',
        `${APIEndpoints.CHANGE}/verifyUserHasInGroupChange/${change.id_change}`
      );

      setAllowedActionsGMud(change._status === 'Analyze' && belongsToGroup && Store.params[0].enable_general_approve_reprove_button_gmud);
      setIsAllowedUnfreezeGmud(belongsToGroup && change.is_freezed);
    } catch (error) {
      setAllowedActionsGMud(false);
      setIsAllowedUnfreezeGmud(false);
    }
  };

  useEffect(() => {
    onVerifyGroupUser();
  }, []);

  const onSubmitUnfreezeChange = () => {
    action
      .execute('post',
        `${APIEndpoints.CHANGE}/freeze/${change.id_change}`, '', { action:  'unfreeze'})
      .then((h) => {
        Store.toggleAlert(true, language.UNFREEZE_SUCCESSFULLY_COMPLETED, 'success');
        setTimeout(redirect('/dash/changemanagement'), 6000);
      })
      .catch((error) => {
        Store.toggleAlert(
          true,
          error.data.code || language.OPERATION_NOT_PERFORMED,
          'error'
        );
      });
  };

  /*##########################################-OPÇÕES PARA O FORMULÁRIO-##############################################################*/
  var options = {
    actionsOptions: () => actionsOptions,
    companyOptions: () =>
      companies.map((obj) => ({ value: obj.id_company, label: obj.name })),
    contractOptions: () =>
      contracts
        .filter((c) => c.fk_id_company === change.fk_id_company)
        .map((ctr) => ({ value: ctr.id_contract, label: ctr.name })),
    involvedAreasOptions: () =>
      change.fk_id_company
        ? companies
            .find((c) => c.id_company === change.fk_id_company)
            .department.map((u) => ({ value: u.id_department, label: u.name }))
        : [],
    solicitantOptions: () =>
      users
        .filter((c) => c.id_company === change.fk_id_company)
        .map((u) => ({ value: u.id_user, label: u.name })),
    techOptions: () =>
      users
        .filter((c) => c.role === 'tech' && c.type === 2)
        .map((u) => ({ value: u.id_user, label: u.name })),
    ticketsList() {
      try {
        return tickets.map((u) => ({
          value: u.id_tickets,
          label: u.realid + ' - ' + u.subject,
        }));
      } catch (g) {
        return [];
      }
    },
    deviceOptions: () =>
      devices.map((d) => ({
        value: d.id_device,
        label: d.name,
      })),
    impactOptions: () => [
      { value: 2, label: language.LOW },
      { value: 1, label: language.MEDIUM },
      { value: 0, label: language.HIGH },
    ],
    priorityOptions: () => [
      { value: 2, label: language.LOW },
      { value: 1, label: language.MEDIUM },
      { value: 0, label: language.HIGH },
    ],
  };

  function findOption(key, option, array) {
    try {
      if (!array) {
        return (
          (options[option]().find((e) => e.value == change[key]) || {}).label ||
          null
        );
      } else {
        return options[option]().filter((e) => change[key].includes(e.value));
      }
    } catch (e) {}
  }

  let usersk;

  usersk = [];

  return (
    <PageLayout
      title={change.id_change ? change.id_change + ' - ' + change.reason : ''}
      subTitle={language.MANAGEMENT_OF_DEMANDS_MOVING}
      rightElements={[]}
    >
      <br></br>
      <div id="ActionsComponents">
        {/*------------------------------------CABEÇALHO AZUL--------------------------------------------------------------*/}
        <header className="d-flex align-items-center  pl-3">
          <div className="d-flex align-items-center">
            {change._status === 'CAB' && (
              <>
                <Button
                  className="mr-2"
                  icon={
                    <ThumbUpIcon
                      style={{ padding: '2px', color: 'var(--primary)' }}
                    />
                  }
                  label={language.CAB_ACCEPT}
                  color="primary"
                  onClick={() => setCabDecision('approved')}
                />
                <Button
                  className="mr-2"
                  icon={
                    <ThumbDownIcon
                      style={{ padding: '2px', color: 'var(--danger)' }}
                    />
                  }
                  label={language.CAB_REJECT}
                  color="danger"
                  onClick={() => setCabDecision('rejected')}
                />
                <Button
                  className="mr-2"
                  icon={
                    <CloudUploadIcon
                      style={{ padding: '2px', color: 'var(--warning)' }}
                    />
                  }
                  label={language.CAB_ATTACH}
                  color="warning"
                  onClick={() => setCabModal(true)}
                />
              </>
            )}

            {(userl.id == change.manager || userl.role == 'client') &&
            (change._status === 'Concluded' ||
              change._status === 'Concluded_Partial' ||
              change._status === 'Concluded_Cancel' ||
              change._status === 'Concluded_Fail') &&
            change.rpi !== true ? (
              <Button
                icon={<FindInPageIcon style={{ color: 'var(--warning)' }} />}
                label="Finalizar"
                color="warning"
                onClick={() => {
                  changeStatus_('finish');
                }}
                className="mr-2"
              />
            ) : null}
            {change._status === 'Finished' ||
            change._status === 'Finish_Fail' ||
            change._status === 'Finish_Partial' ||
            change._status === 'Finish_Cancel' ? (
              <Button
                icon={<FindInPageIcon style={{ color: 'var(--warning)' }} />}
                label={language.POST_IMPLEMENTATION_REVIEW}
                color="warning"
                onClick={() => {
                  revisionGmud();
                }}
                className="mr-2"
              />
            ) : null}

            {change._status !== 'CAB' &&
            activities.filter((item) => item.checked == true && item.type != 3)
              .length == activities.filter((item) => item.type != 3).length &&
            (userl.id === change.manager ||
              userl.role == 'client' ||
              userl.role == 'solicitant' ||
              userl.perfil.gsd_is_change_manager == true) &&
            change._status !== 'Concluded' &&
            change._status !== 'Finished' &&
            change._status !== 'Cancelled' &&
            change._status !== 'Rejected' &&
            change._status !== 'Concluded_Fail' &&
            change._status !== 'Concluded_Partial' &&
            change._status !== 'Concluded_Cancel' &&
            change._status !== 'Finish_Fail' &&
            change._status !== 'Finish_Partial' &&
            change._status !== 'Finish_Cancel' ? (
              <Button
                icon={<RestorePageIcon style={{ color: 'var(--warning)' }} />}
                label={language.CONCLUDE}
                color="warning"
                onClick={() => setopenCon(true)}
                className="mr-2"
              />
            ) : null}
          </div>

          <If condition={isAllowedActionsGMud}>
            <div className="d-flex align-items-center">
                <Button
                  icon={<FindInPageIcon style={{ color: 'var(--warning)' }} />}
                  label={language.APPROVE}
                  color="warning"
                  onClick={() => {
                    SetAc('approve');
                    SetModal(true);
                  }}
                  className="mr-2"
                />
                <Button
                  className="mr-2"
                  icon={<RestorePageIcon style={{ color: 'var(--danger)' }} />}
                  label={language.FAIL}
                  color="danger"
                  onClick={() => {
                    SetAc('reject');
                    SetModal(true);
                  }}
                />
              </div>
          </If>

          {(((change.manager === Store.getUserLoged().id || Store.getUserLoged() === 'client' ) &&
            change._status === 'Analyze')) || change._status === 'Rejected' ? (
              <div className="d-flex align-items-center">
                <Button
                  icon={<Edit style={{ color: 'var(--success)' }} />}
                  label={language.GMUD_BACK_TO_EDITION}
                  color="primary"
                  onClick={() => {
                    SetAc('back_edit');
                    SetModal(true);
                  }}
                  className="mr-2"
                />
              </div>
          ) : null}

          {(
            (
              change.manager === Store.getUserLoged().id ||
              Store.getUserLoged().role === 'client' ||
              (
                change.solicitant && Array.isArray(change.solicitant) && change.solicitant.includes(Store.getUserLoged().id) ||
                change.solicitant === Store.getUserLoged().id
              )
            ) &&
            ['Open', 'Create', 'Analyze', 'Progress', 'Concluded'].includes(change._status)
          ) ? (
              <div className="d-flex align-items-center">
                <Button
                  icon={<AttachFileIcon style={{ color: 'var(--success)' }} />}
                  label={language.ATTACH_FILES}
                  color="primary"
                  onClick={() => {
                    SetAc('new_attachments');
                    SetModal(true);
                  }}
                  className="mr-2"
                />
              </div>
          ) : null}

          <If condition={change._status === 'Analyze'}>
            <div className="d-flex align-items-center">
                <Button
                  icon={<FindInPageIcon style={{ color: 'var(--warning)' }} />}
                  label={language.GMUD_RESEND_EMAIL}
                  color="warning"
                  onClick={() => {
                    changeStatus(
                      'analyze',
                      {
                        send: true,
                        fk_id_change: change.id_change,
                      },
                      true
                    );
                  }}
                  className="mr-2"
                />
              </div>
          </If>

          {isAllowedUnfreezeGmud && (
            <div className="d-flex align-items-center">
              <Button
                className="mr-2"
                icon={<AcUnitIcon style={{ color: 'var(--primary)' }} />}
                label="Descongelar"
                color="primary"
                onClick={onSubmitUnfreezeChange}
              />
            </div>
          )}

          <div className="d-flex align-items-center">
            {
              (
                change.manager === Store.getUserLoged().id ||
                Store.getUserLoged().role === 'client' ||
                (
                  change.solicitant && Array.isArray(change.solicitant) && change.solicitant.includes(Store.getUserLoged().id) ||
                  change.solicitant === Store.getUserLoged().id
                )
              ) &&
              (
                ['Open', 'Create', 'Analyze', 'Progress'].includes(change._status)
              ) ? (
                <Button
                  icon={<FindInPageIcon style={{ color: 'var(--warning)' }} />}
                  label={language.CANCEL}
                  color="danger"
                  onClick={() => {
                    SetAc('cancel');
                    SetModal(true);
                  }}
                  className="mr-2"
                />
              ) : null
            }
          </div>

          <div
            style={{
              width: '100%',
              textAlign: 'end',
              marginRight: '10px',
              color: 'white',
            }}
          >
            {`${getStatus[change._status]}
             ${change.rpi == true ? language.AFTER_POST_IMPLEMENTATION_REVIEW : ''}

               -
              ${moment(change.dt_up || change.dt_created || change.dt_cad).format(
                'DD/MM/YYYY HH:mm'
              )}`}
          </div>
        </header>
        <Card className="content card">
          {/*------------------------------------INFORMAÇÕES BÁSICAS DO TICKET--------------------------------------------------------------*/}
          {change.fk_id_company && (
            <InfoTicket
              Progress={Progress}
              change_risks={change_risks}
              onFormSubmitAc={onFormSubmitAc}
              donwFileAc={donwFileAc}
              validTech={validTech}
              validTechCheck={validTechCheck}
              setvalidTech={setvalidTech}
              action={action}
              APIEndpoints={APIEndpoints}
              donwFileAc_={donwFileAc_}
              toggleAlert={Store.toggleAlert}
              activities={activities}
              findOption={findOption}
              users={users}
              userl={userl}
              options={options}
              change={change}
              language={language}
              cabBoard={cabBoard}
              setOpenFinishedJustify={setOpenFinishedJustify}
              setOpenRPIJustify={setOpenRPIJustify}
              changeStatus = {changeStatus}
              preventActionsDueGmudFreeze={change.is_freezed}
            />
          )}
          <Dialog
            open={openFinishedJustify}
            onClose={() => {
              setOpenFinishedJustify(false);
            }}
            contentStyle={{ height: '60%', maxWidth: 'none' }}
            fullWidth={true}
            maxWidth="xl"
            autoScrollBodyContent={true}
            title={language.JUSTIFICATION_OF_TERMINATION}
          >
            <span
              className="font-roboto"
              style={{ color: 'var(--primary)', marginTop: 10, marginTop: 10 }}
            >
              {language.JUSTIFICATION}
            </span>

            <div
              dangerouslySetInnerHTML={{ __html: change.finished_justify }}
            />
            {change.file_finish &&
              change.file_finish.map((h) => (
                <Grid item xs={6} style={{ marginTop: 15 }}>
                  <AttachFile
                    onClick={() =>
                      donwFileFinish(h.id_change_finish_file, h.name, '')
                    }
                    label={h.name}
                    type="image/png"
                  />
                </Grid>
              ))}
          </Dialog>
          <Dialog
            open={openRPIJustify}
            onClose={() => {
              setOpenRPIJustify(false);
            }}
            contentStyle={{ height: '60%', maxWidth: 'none' }}
            fullWidth={true}
            maxWidth="xl"
            autoScrollBodyContent={true}
            title={language.POST_IMPLEMENTATION_REVIEW}
          >
            {change.rpis &&
              change.rpis.map((rpi) => (
                <div style={{ marginTop: 20, padding: 5 }}>
                  <div className="font-roboto">
                    <strong style={{ color: 'var(--primary)' }}>Data : </strong>
                    {moment(rpi.dt_cad).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                  <div className="font-roboto">
                    <strong style={{ color: 'var(--primary)' }}>
                      {language.STATE} :
                    </strong>{' '}
                    {rpi.fk_id_status == 7
                      ? language.GMUD_STATUS['FINISHED_SUCCESS']
                      : rpi.fk_id_status == 16
                      ? language.GMUD_STATUS['FINISHED_FAIL']
                      : rpi.fk_id_status == 17
                      ? language.GMUD_STATUS['FINISHED_PARTIALLY']
                      : language.GMUD_STATUS['FINISHED_CANCEL']}
                  </div>

                  <span
                    className="font-roboto"
                    style={{
                      color: 'var(--primary)',
                      marginTop: 10,
                      marginTop: 10,
                    }}
                  >
                    <strong>{language.JUSTIFICATION}</strong>
                  </span>

                  <div dangerouslySetInnerHTML={{ __html: rpi.justify }} />
                  {rpi.files_rpi &&
                    rpi.files_rpi.map((h) => (
                      <Grid item xs={6} style={{ marginTop: 15, marginBottom: 5 }}>
                        <AttachFile
                          onClick={() =>
                            donwFileRPI(h.id_change_rpi_file, h.name, '')
                          }
                          label={h.name}
                          type="image/png"
                        />
                      </Grid>
                    ))}
                  <Divider />
                </div>
              ))}
          </Dialog>

          <Dialog
            open={openRevisionImplementation}
            onClose={() => {
              setOpenRevisionImplementation(false);
            }}
            contentStyle={{ height: '60%', maxWidth: 'none' }}
            fullWidth={true}
            maxWidth="xl"
            autoScrollBodyContent={true}
            title={language.POST_IMPLEMENTATION_REVIEW}
          >
            <CommonForm
              fields={[
                {
                  col: 12,
                  type: 'select',
                  name: 'fk_id_status',
                  label: language.STATE,
                  required: true,
                  options: [
                    { value: 7, label: language.GMUD_STATUS['FINISHED_SUCCESS'] },
                    { value: 16, label: language.GMUD_STATUS['FINISHED_FAIL'] },
                    { value: 17, label: language.GMUD_STATUS['FINISHED_PARTIALLY'] },
                    { value: 18, label: language.GMUD_STATUS['FINISHED_CANCEL'] },
                  ],
                },
                {
                  col: 8,
                  type: 'textEditor',
                  name: 'justify',
                  label: language.JUSTIFICATION,
                  required: true,
                },
                {
                  col: 4,
                  name: 'files',
                  label: language.ATTACHMENT,
                  type: 'file',
                  customPortalCss: true,
                },
              ]}
              onSubmit={saveRPI}
            />
          </Dialog>
          <Dialog
            open={finishGMUDModal}
            onClose={() => {
              setFinishGMUDModal(false);
            }}
            contentStyle={{ height: '60%', maxWidth: 'none' }}
            fullWidth={true}
            maxWidth="xl"
            autoScrollBodyContent={true}
            title={language.FINISH_GMUD}
          >
            <CommonForm
              onSubmit={(g) => finishGMUD(g)}
              fields={[
                {
                  col: 8,
                  type: 'select',
                  name: 'executed',
                  label: language.EXECUTED,
                  options: [
                    { value: 1, label: language.FAILURE },
                    { value: 2, label: language.SUCCESS },
                    { value: 3, label: language.PARTIAL }
                  ],
                  required: true,
                },
                {
                  col: 4,
                  name: 'files',
                  label: language.ATTACHMENT,
                  type: 'file',
                  customPortalCss: true,
                },
                {
                  col: 12,
                  type: 'textEditor',
                  name: 'justify',
                  label: language.JUSTIFICATION,
                  required: true,
                },
              ]}
            />
          </Dialog>

          {modal ? (
            <Dialog
              open={modal}
              onClose={() => SetModal(false)}
              contentStyle={{ height: '80%', maxWidth: 'none' }}
              fullWidth={true}
              maxWidth="lg"
              autoScrollBodyContent={true}
              title={
                ac === 'approve' ?
                  language.APPROVE :
                ac === 'cancel' ?
                  language.CANCEL :
                ac === 'reschedule' ?
                  language.RESCHEDULE :
                ac === 'back_edit' ?
                  language.GMUD_BACK_TO_EDITION :
                ac === 'new_attachments' ?
                  language.ATTACH_FILES :
                  language.REJECT
              }
            >
              <div className="container" style={{ padding: '20px 0' }}>
                <CommonForm
                  language={language}
                  values={ac === "new_attachments" ? change : null}
                  fields={[
                    ac === "new_attachments" ?
                      {
                        span: 12,
                        type: 'file',
                        name: 'file',
                        label: language.ATTACH
                      } :
                    ac !== 'reschedule' ?
                      {
                        span: 12,
                        name: 'log',
                        label: language.JUSTIFICATION,
                        type: 'text',
                        required: true,
                      } :
                      ({
                        col: 12,
                        type: 'element',
                        content: () => (
                          <h3 style={{ color: '#ffc107' }}>
                            {language.GMUD_STATUS_WILL_BE_SET_AS_OPEN}
                          </h3>
                        ),
                      },
                      {
                        span: 12,
                        name: 'log',
                        label: language.JUSTIFICATION,
                        type: 'text',
                        required: true,
                      }),
                  ]}
                  button={{
                    fluid: true,
                    size: 1,
                    style: {
                      height: 40,
                    },
                  }}
                  onSubmit={onFormSubmit}
                />
              </div>
            </Dialog>
          ) : null}
        </Card>
      </div>
      <DialogConfirm
        open={openCon}
        language={language}
        confirmProps={{ color: 'danger' }}
        onConfirm={() => changeStatus_('conclude')}
        onClose={() => setopenCon(false)}
        title={language.GMUD_CONCLUDE}
        message={''}
      />

      {cabDecision === 'approved' ? (
        <DialogConfirm
          open={true}
          language={language}
          confirmProps={{ color: 'primary' }}
          onConfirm={() => resolveCab()}
          onClose={() => setCabDecision('')}
          title={language.GMUD_APPROVE}
          message={''}
        />
      ) : cabDecision === 'rejected' ? (
        <Dialog
          open={true}
          onClose={() => setCabDecision('')}
          title={language.GMUD_REJECT}
          contentStyle={{ height: '80%', maxWidth: 'none' }}
          fullWidth={true}
          maxWidth="lg"
          autoScrollBodyContent={true}
        >
          <div className="container" style={{ padding: '20px 0' }}>
            <CommonForm
              language={language}
              fields={[
                {
                  span: 12,
                  name: 'log',
                  label: language.JUSTIFICATION,
                  type: 'text',
                  required: true,
                },
              ]}
              button={{ fluid: true, size: 1, style: { height: 40 } }}
              onSubmit={resolveCab}
            />
          </div>
        </Dialog>
      ) : null}

      <Dialog
        open={cabModal}
        onClose={() => {
          setCabModal(false);
        }}
        title="ATA do CAB"
      >
        <CabRecord
          action={action}
          language={language}
          APIEndpoints={APIEndpoints}
          id={change.id_change}
          onClose={() => {
            setCabModal(false);
          }}
        />
      </Dialog>
    </PageLayout>
  );
}
