import moment from 'moment';
import TicketUtils from '../../../../util/TicketUtils';
import AppStore from '../../../../data/Store';
import Store from '@data/Store';

const { language } = Store;

export function TotalOpenTickets({ tickets = [] }) {
  return new Promise((resolve, reject) => {
    let keys = {
      Open: 0,
      'In Progress': 1,
      Resolved: 2,
      Closed: 3,
    };

    const result = {
      list: tickets.reduce(
        (obj, e) => {
          if (obj[keys[e.status]]) {
            obj[keys[e.status]].value += 1;
          }
          return obj;
        },
        [
          { label: language.OPEN, value: 0, color: 'var(--primary)' },
          { label: language.IN_PROGRESS, value: 0, color: 'var(--warning)' },
          { label: language.RESOLVED, value: 0, color: 'var(--success)' },
          { label: language.CLOSED, value: 0, color: 'var(--purple)' },
        ]
      ),
      openToday: tickets.filter(
        (e) => moment().isSame(e.dt_cad, 'day') && e.status == 'Open'
      ).length,
    };
    resolve(result);
  });
}
export function TypePrecedence({ gmuds, tickets, projects, os }) {
  return new Promise((resolve, reject) => {
    let status = {
      Approved: 'attendance',
      Finished: 'resolved',
      Create: 'open',
    };
    const result = [
      //--------------------------------------------------------------------------
      tickets.reduce(
        (obj, e) => {
          if (e.status == 'Open') obj.open++;
          if (e.status == 'In Progress') obj.attendance++;
          if (['Closed', 'Resolved'].includes(e.status)) obj.resolved++;
          return obj;
        },
        {
          module: 'GSD',
          name: 'gestão de demandas',
          open: 12,
          attendance: 12,
          resolved: 12,

          openLabel: 'Aberto',
          attendanceLabel: 'Em Atendimento',
          resolvedLabel: 'Resolvido',
        }
      ),
      //--------------------------------------------------------------------------
      projects.reduce(
        (obj, e) => {
          obj.open += e.utils.BACKLOG.length + e.utils.TODO.length;
          obj.attendance += e.utils.DOING.length;
          obj.resolved += e.utils.DONE.length;
          return obj;
        },
        {
          module: 'GP',
          name: 'gestão de projetos',
          open: 12,
          attendance: 12,
          resolved: 12,

          openLabel: 'Backlog - A Fazer',
          attendanceLabel: 'Fazendo',
          resolvedLabel: 'Finalizado',
        }
      ),
      //--------------------------------------------------------------------------
      os.reduce(
        (obj, e) => {
          if (obj[status[e.status]]) {
            obj[status[e.status]]++;
          }
          return obj;
        },
        {
          module: 'OS',
          name: 'ordem de serviço',
          open: 0,
          attendance: 0,
          resolved: 0,

          openLabel: 'Criado',
          attendanceLabel: 'Aprovado',
          resolvedLabel: 'Resolvido',
        }
      ),
      //--------------------------------------------------------------------------
      gmuds.reduce(
        (obj, e) => {
          if (e.checked) {
            obj.resolved++;
          } else {
            obj.open++;
          }
          return obj;
        },
        {
          module: 'GMUD',
          name: 'gestão de mudança',
          open: 0,
          attendance: '-',
          resolved: 0,

          openLabel: 'Aberto',
          attendanceLabel: '-',
          resolvedLabel: 'Resolvido',
        }
      ),
    ];

    resolve(result);
  });
}
export function Expenses({ tickets }) {
  return new Promise((resolve, reject) => {
    const result = tickets.reduce(
      (obj, e) => {
        if (['Open', 'In Progress'].includes(e.status)) {
          let tc = TicketUtils.calcSla(
            e,
            'ticket',
            AppStore.language,
            [],
            e.sla_task,
            0
          );

          if (tc.color == 'var(--red)') {
            let date = (tc.time || '').split(':');
            date = moment(e.dt_cad)
              .add('hour', parseInt(date[0] || 0))
              .add('minutes', parseInt(date[1] || 0))
              .add('seconds', parseInt(date[2] || 0));

            let isThisMonth = moment().isSame(date, 'month');
            let isThisWeek = moment().isSame(date, 'week');
            let isThisDay = moment().isSame(date, 'day');

            if (isThisMonth) obj[0]++;
            if (isThisWeek) obj[1]++;
            if (isThisDay) obj[2]++;
          }
        }
        return obj;
      },
      [0, 0, 0]
    );

    resolve(result);
  });
}
export function AverageSla({ priorities }) {
  return new Promise((resolve, reject) => {
    try {
      const result = priorities.reduce(
        (obj, e) => {
          obj[parseInt(e.month_cad) - 1].hight += e.hight;
          obj[parseInt(e.month_cad) - 1].medium += e.medium;
          obj[parseInt(e.month_cad) - 1].slow += e.slow;
          return obj;
        },
        Array.from(new Array(12), () => ({
          hight: 0,
          medium: 0,
          slow: 0,
        }))
      );
      resolve(result);
    } catch (e) {
      reject([]);
    }
  });
}
export function ItemsWaitingApprovals({ requests, risks }) {
  let reqCount = requests.filter(
    (e) => e.approval && e.status == 'Approve'
  ).length;
  let riskCount = risks.filter((e) => !!(e.usera || []).length).length;
  return {
    aprovationPendent: [reqCount, riskCount],
  };
}
export function AprovationItem({ requests, risks }) {
  let req = requests
    .filter((e) => e.approval && e.status == 'Approve')
    .map((e) => ({
      type: 1,
      request: e,
      id: e.id_request,
      name: e.subject,
      date: moment(e.dt_cad).format('DD/MM/YYYY - HH:mm'),
      description: e.description,
    }));

  let risk = risks
    .filter((e) => !!(e.usera || []).length)
    .map((e) => ({
      type: 2,
      risk: e,
      name: e.name,
      date: e.execution_date,
      description: e.desc,
    }));

  return req.concat(risk);
}
