import API from './Constants';
import Actions from './Actions';
 
export default function(socket){

	var action = new Actions();
	
	/*socket.on('teste-client', (data)=>{
	  console.log(data);
	})*/
	// this.$on('teste-client', 'companies', 'cnpj', 'delete')
	// this.$emit('send-msg', 'companies', {id:1, name:'olá', cnpj:1})
	let tenant =
    API.Tenant || (window.location.host == 'localhost:3110' ? 'dev' :
    window.location.hostname.replace(/\/|.sensr.com.br|.sensrit.com.br|.sensr.it/g, ""))
   
	this.$on('chat-ticket-'+tenant, null, null, null, data=>{
		/*this.setModel({
			type: 'put',
			model: 'tickets_chat_opens' ,
			data,
			primaryKey: 'id_tickets',
			alert:false
		})*/
		
		// ---------------------------atualiza os coments do float-chat------------------------------------
		this.tickets_chat_opens.map(e=>{
			if(e.id_tickets==data.fk_id_tickets){
				e.comments.push(data);
			}
			return e;
		});

		// ---------------------------atualiza os coments do chat interno do tiket------------------------------------
		if(!!this.ticket){
			this.ticket.comments.push(data);
		}

		action.execute(
		  'get',
		  API.APIEndpoints.TICKET + '/getcomments',
		  'new_msg_notifications'
		);
	});
}