import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import Constants from '../../data/Constants';
import './DialogForm.css';

import { translateFromPtBR } from '../../common/languages/Dictionary';

import Icon from '@material-ui/core/Icon';
import DialogIcon from '../../common/DialogIcons';

function status(selected) {
  return {
    fill: selected ? 'white' : 'var(--primary)',
    color: selected ? 'warning' : 'primary',
    active: !selected,
  };
}

function btn(i) {
  return [
    {
      icon: (e) => (
        <i className="tw-text-base fas fa-check-circle" />
        // <SlowIcon style={{ fill: status(e.selected).fill, width: 40 }} />
      ),
      colorActive: 'primary',
    },
    {
      icon: (e) => (
        <i className="tw-text-base fas fa-exclamation-triangle" />
        // <HightIcon style={{ fill: status(e.selected).fill, width: 40 }} />
      ),
      colorActive: 'warning',
    },
    {
      icon: (e) => (
        <i className="tw-text-base fas fa-fire" />
        // <CriticIcon style={{ fill: status(e.selected).fill, width: 40 }} />
      ),
      colorActive: 'danger',
    },
    {
      icon: (e) => (
        <i className="tw-text-base fas fa-bolt" />
        // <CriticIcon style={{ fill: status(e.selected).fill, width: 40 }} />
      ),
      colorActive: 'danger',
    },
  ][i];
}

function sortFunctionOptions(a, b) {
  return a.value - b.value;
}

export default class DialogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: null,
      form: null,
      liberation: (props.values && props.values.liberation) || null,
      iconType: props?.values?.icon_url ? 'image' : 'icon',
      iconValue: '',
      openModal: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleIconChange = this.handleIconChange.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
  }

  setRef = (name, ref) => {
    if (!this.state[name]) {
      this.setState({ [name]: ref });
    }
  };

  handleChangeForm = (name, value) => {
    this.setState({ [name]: value });
  };

  onFormSubmit = (values) => {
    values.icon_type = this.state.iconType;
    values.icon =
      this.state.iconType == 'icon'
        ? this.state.iconValue || values.icon
        : null;
    values.icon_url =
      this.state.iconType == 'image'
        ? this.state.iconValue || values.icon_url
        : null;
    this.props.onFormSubmit(values);
  };

  handleIconChange = (iconData) => {
    this.setState({
      iconType: iconData.type,
      iconValue: iconData.iconString,
      openModal: false,
    });
  };

  handleToggleModal = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    const {
      open = true,
      onClose,
      language,
      values = {},
      impacts,
      priorities,
      urgencies,
      complexities,
      buttonDisabled,
      allIcons,
      setTaskName,
      taskName,
    } = this.props;

    const approvalOptions = [
      { value: true, label: language.YES },
      { value: false, label: language.NOT },
    ];

    const countryComplexity = [
      { ...btn(0), value: 'low', label: language.LOW },
      { ...btn(1), value: 'medium', label: language.MEDIUM },
      { ...btn(2), value: 'high', label: language.HIGH },
    ];

    const createOptions = (data, language) => {
      return data
        .map((item, index) => ({
          ...btn(index),
          value: item.id_table,
          label: translateFromPtBR(item.description, language),
        }))
        .sort(sortFunctionOptions);
    };

    const impactOptions = createOptions(impacts, language);
    const priorityOptions = createOptions(priorities, language);
    const urgencyOptions = createOptions(urgencies, language);
    const complexityOptions = createOptions(complexities, language);

    const fields = [
      {
        col: 6,
        name: 'time_sla',
        label: language.SLA_TIME,
        type: 'number',
        required: true,
      },
      {
        col: 6,
        name: 'liberation',
        label: language.NEEDS_APPROVAL,
        type: 'select',
        options: approvalOptions,
      },
      {
        col: 12,
        type: 'autocomplete',
        name: 'fk_id_approver',
        label: language.APPROVER,
        textlabel: language.APPROVER,
        method: 'POST',
        filter: 0,
        route: `${Constants.APIEndpoints.USER}/filter`,
        routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${0}`,

        fieldquery: 'search',
        textinit: (values && values.approver) || language.APPROVER,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: this.state.values && this.state.values.fk_id_approver,
        routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
        filterAll: 0,
        methodGetAll: 'POST',
        detail: true,
        visible: this.state.liberation || false,
        required: false,
        showEmail: true,
      },
      {
        type: 'element',
        content: <hr />,
      },
      {
        col: 6,
        name: 'id_priority',
        label: language.PRIORITY + ' *',
        type: 'btnGroup',
        align: 'left',
        colorInactive: 'primary',
        options: priorityOptions,
      },
      {
        col: 6,
        name: 'id_impact',
        label: language.IMPACT + ' *',
        type: 'btnGroup',
        align: 'left',
        colorInactive: 'primary',
        options: impactOptions,
      },
      {
        type: 'element',
        content: <hr />,
      },
      {
        col: 6,
        name: 'id_complexity',
        label: language.COMPLEXITY + ' *',
        type: 'btnGroup',
        align: 'left',
        colorInactive: 'primary',
        options: complexityOptions,
      },
      {
        col: 6,
        name: 'id_urgency',
        label: language.URGENCY + ' *',
        type: 'btnGroup',
        align: 'left',
        colorInactive: 'primary',
        options: urgencyOptions,
      },
      {
        type: 'element',
        content: <hr />,
      },
      {
        col: 6,
        name: 'icon',
        label: 'Ícone',
        type: 'element',
        content: (
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button
              variant="normal"
              color={'success'}
              loading={false}
              disabled={false}
              onClick={() => {
                this.handleToggleModal(true);
              }}
              label={language.CHOOSE_ICON}
            />
            {this.state.iconValue ||
              (values.icon && (
                <div className="tw-p-2 tw-border tw-rounded tw-flex tw-items-center tw-justify-center">
                  {this.state.iconType == 'image' ? (
                    <img
                      className="tw-h-10"
                      src={`${Constants.BASE_URL_IMAGE}${this.state.iconValue || values.icon_url}`}
                    />
                  ) : (
                    <Icon>{this.state.iconValue || values.icon}</Icon>
                  )}
                </div>
              ))}
          </div>
        ),
      },
      {
        col: 6,
        name: 'disable_fields',
        label: language.DISABLE_FIELDS,
        type: 'switch',
      },
      {
        type: 'element',
        content: <hr />,
      },
      {
        type: 'textEditor',
        name: 'description',
        label: language.DESCRIPTION,
      },
      {
        type: 'simplesTextEditor',
        name: 'script',
        label: language.SCRIPT,
      },
      {
        col: 12,
        type: 'element',
        content: <hr />,
      },
    ];

    return (
      <Dialog
        PaperProps={{
          id: 'task_list',
        }}
        bar={false}
        open={open}
        onClose={onClose}
        dialogChildren={
          <div
            className="d-flex align-items-start justify-content-between bar"
            style={{
              background: `url(${BarBlue})`,
              borderBottom: '3px solid var(--warning)',
            }}
          >
            <div className="pt-3 pb-1 pl-3 w-100">
              <div className="d-flex align-items-start">
                <h4 className="mr-3 id-ticket"></h4>
                <div className="w-100" style={{ zoom: 0.8 }} zoom={0.8}>
                  <CommonForm
                    values={{
                      name: values.name,
                    }}
                    alert={false}
                    fields={[
                      {
                        type: 'text',
                        name: 'name',
                        label: language.TASK_NAME,
                        className: 'subject-ticket',
                        required: true,
                      },
                    ]}
                    onChangeField={(f, v) => setTaskName(v)}
                  />
                </div>
              </div>
            </div>
            <IconButton
              aria-label="Close"
              className="closeButton"
              onClick={onClose}
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </div>
        }
      >
        <CommonForm
          values={values}
          fields={fields}
          button={{
            disabled: buttonDisabled,
            fluid: true,
            style: {
              height: 40,
            },
          }}
          onSubmit={this.onFormSubmit}
          onChangeField={(field, valueField) => {
            this.handleChangeForm(field.name, valueField);
          }}
          loading="put-post-task-list"
        />

        {this.state.openModal && (
          <Dialog
            open={true}
            maxWidth="lg"
            title={language.CHOOSE_ICON}
            onClose={() => this.handleToggleModal(false)}
          >
            <DialogIcon onChange={this.handleIconChange} />
          </Dialog>
        )}
      </Dialog>
    );
  }
}
