
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '@common/CommonPage';
import SideDishList from './SideDishList';
import Animate from '@common/Animate';
import Loading from '@common/Loading';

export default class ServerContainer extends CommonPage{
	constructor() {
		super();
		this.state = {
        page:''
			  
		}
		this.onFormSubmit =   this.onFormSubmit.bind(this);
	}
	componentDidMount() {
		this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
		this.action.execute('get', this.APIEndpoints.DEVICE, 'devices');
		this.action.execute('get', this.APIEndpoints.BACKUP, 'backups');
		this.action.execute(
			'post',
			this.APIEndpoints.USER + '/techcontracts',
			'usercon_company',
			{ id_user: this.store.getUserLoged().id },null, null, null
		  )
		 
	}

	onFormSubmit(values) {
		let contador = 0;
   //	`${data.fk_id_company}, ${data.fk_id_device},  ${data.first} `,
	   
		 this.action.execute(
		   'post',
		   this.APIEndpoints.BACKUP+'/backupsave' ,
		   'backups',
		   values
		 );
		// this.redirect('/dash/request');
	   }
	 
   



	render(){
		let { language,  companies ,backups } = this.store;
		return(
			<CommonPage
			  title={
			    <div
			      className="logoWhite"
			      onClick={() => this.redirect('/dash')}
			    />
			  }
			  showMenuIconButton={false}
			  iconAlert={true}
			  loading={false}
			>
			
			
				<Loading loading={!backups.length}>	
					<Animate>
						<SideDishList 
						user={this.store.getUserLoged()}
						usercon_company={this.store.usercon_company} onFormSubmit={this.onFormSubmit} backups={backups} language={language} devices={this.store.devices} companies={companies}/>
					</Animate>
				</Loading>
			
			</CommonPage>
		)
	}
}