import React, { useState, useEffect } from 'react';
import './index.css';
import Store from '@data/Store';
import Dialog from '@common/Dialog';
import AppBar from '@common/AppBar2';
import BarBlue from '@images/BarBlue.png';
import CommonForm from '@common/CommonForm2';
import { MaskMoney } from '@common/Mask';
import DialogConfirm from '@common/DialogConfirm';
import Constants from '@data/Constants';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ContractCust } from './ContractCust';

const MyHeader = ({ title, subTitle, className }) => (
  <AppBar
    contentProps={{
      className: 'pl-0 ' + className,
    }}
    titleProps={{
      style: { color: 'var(--primary)' },
    }}
    title={title}
    subTitle={subTitle}
  />
);

export default ({ open, onClose, idProject, action, APIEndpoints }) => {
  const { language } = Store;
  const [deleteCust, setDeleteCust] = useState();
  const [projectContracts, setProjectContracts] = useState({});

  useEffect(() => {
    getCosts();
  }, [idProject]);

  const getCosts = () => { 
    action
      .execute('get', APIEndpoints.PROJECTS + `/cost/users/${idProject}`)
      .then((response) => response.data)
      .then((data) => buildProjectContracts(data))
      .catch((err) => console.error(err));
  }

  const buildProjectContracts = (contractsData) => { 
    const resultData = contractsData.reduce((result, item) => {
      const { id_contract } = item;
      const key = String(id_contract);
      if (!result[key]) { 
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});
    setProjectContracts(resultData);
  }

  const onDeleteOtherCust = () => { 
    action
      .execute('delete', APIEndpoints.PROJECTS + `/cost/${deleteCust}`, 'project')
      .then(getCosts);
    setDeleteCust(null);
  }

  const handleSubmit = (values) => { 
    action
      .execute('post', APIEndpoints.PROJECTS + `/cost`, 'project', { ...values, id_project: idProject })
      .then(getCosts);
  }

  return (
    <Dialog
      PaperProps={{
        id: 'DialogModalOtherCust',
      }}
      open={open}
      onClose={onClose}
      bar={false}
      maxWidth="lg"
      dialogChildren={
        <div
          className="d-flex align-items-start justify-content-between bar"
          style={{
            background: `url(${BarBlue})`,
            borderBottom: '3px solid var(--warning)',
          }}
        >
          <div className="pt-3 pb-1 pl-3 w-100" />
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      }
      contentProps={{
        style: {
          background: 'white',
        },
      }}
    >
      <MyHeader
        title={language.COST_PER_PROJECT}
        subTitle={language.INFORM_THE_PROJECT_PARTICIPANTS_AND_THEIR_HOURLY_VALUE}
      />
      <CommonForm
        loading="saveOtherCust"
        fields={[
          {
            col: 4,
            label: language.PARTICIPANT + ' *',
            textlabel: language.PARTICIPANT + ' *',
            name: 'fk_id_user',
            type: 'autocomplete',
            method: 'POST',
            showSearchButtom: false,
            fieldquery: 'search',
            fieldvaluedb: 'id',
            fieldlabeldb: 'name',
            route: `${Constants.APIEndpoints.PROJECTS}/users/${idProject}?`,
            routeAll: `${Constants.APIEndpoints.PROJECTS}/users/${idProject}/?`,
            detail: true,
            methodGetAll: 'POST',
            required: true,
          }, {
            col: 4,
            type: 'text',
            label: language.COST_PER_HOUR,
            name: 'value',
            required: true,
            InputProps: {
              inputComponent: MaskMoney,
            },
          }, {
            col: 4,
            label: language.CONTRACT + ' *',
            textlabel: language.CONTRACT + ' *',
            name: 'fk_id_contract',
            type: 'autocomplete',
            method: 'POST',
            methodGetAll: 'POST',
            showSearchButtom: false,
            fieldquery: 'name',
            fieldvaluedb: 'id',
            fieldlabeldb: 'name',
            route: `${Constants.APIEndpoints.PROJECTS}/contracts/${idProject}?`,
            routeAll: `${Constants.APIEndpoints.PROJECTS}/contracts/${idProject}?`,
            detail: true,
            required: true,
          }
        ]}
        onSubmit={handleSubmit}
      />
      <hr />
      <div>
        {(
          <DialogConfirm
            open={!!deleteCust}
            onClose={() => {
              setDeleteCust(null);
            }}
            title={language.DO_YOU_WANT_TO_REMOVE_THIS_COST}
            confirmProps={{
              color: 'danger',
            }}
            message={language.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COST}
            onConfirm={onDeleteOtherCust}
          />
        )}
        {Object.keys(projectContracts).map((k) => (
          <ContractCust
            key={k}
            contractCust={projectContracts[k]}
            onDelete={(id) => setDeleteCust(id)}
          />
        ))}
      </div>
    </Dialog>
  );
};
