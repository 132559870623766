import React, { useState, useReducer, useEffect, Fragment } from 'react';
import { Grid, Chip } from '@material-ui/core';
import Button from '@common/Button';
import RaisedButton from '@common/RaisedButton';
import { Card } from '@material-ui/core';
import classnames from 'classnames';
import { Tooltip } from 'react-tippy';
import AddActivity from '../AddActivity';
import DialogConfirm from '@common/DialogConfirm';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import { useUtils } from '@material-ui/pickers';
import Workflow from './Workflow'
import CommonForm from '../../../common/CommonForm2';
import Store from '@data/Store';

const { language } = Store;

// #############################################################-ITEMS-##########################################################################################

const Items = ({
  id,
  name,

  priority,
  impact,
  type_gmud,
  sponsor,
  hour,
  onDelete,
  onEdit,
  _loadingD,
  _loadingE,
  _static,
  openTemplate,
  setac,
  openTemplateAssoc,
  setWork,
  noButton,
  fk_id_priority,
  typeTemp,
  order
}) => (
  <div className="items">
    {!_static && (
      <div className="d-flex align-items-center justify-content-between">
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                label={language.ADD_REQUEST_FORM}
                color="blue"
                className="mr-2"
                style={{ fontSize: 13, width: 21, height: 21 }}
               onClick={() => {setac(), openTemplate(0)} }
              />

              <RaisedButton
              circleButton
                icon="fas fa-pencil-alt"
                label={language.EDIT}
                color="blue"
                className="mr-2"
                style={{ fontSize: 13, width: 21, height: 21 }}
                onClick={onEdit}
                _loading={_loadingE}
              />

              <RaisedButton
                circleButton
                icon="fas fa-trash"
                label={language.DELETE}
                color="danger"
                disabled={type_gmud ==  2  && typeTemp==false?true:false}
                className="mr-2"
                style={{ fontSize: 13, width: 21, height: 21 }}
                onClick={onDelete}
                _loading={_loadingD}
              />
            </div>
          }
        />
      </div>
    )}

    <div className="item">#{id}</div>
    <Tooltip
      html={<span style={{ color: 'white' }}>{name}</span>}
      arrow={true}
      position="right"
      theme="dark"
      className="d-flex align-items-center"
    >
      <div className="ellipsis item" style={{ display: 'block' }}>
        {name}
      </div>
    </Tooltip>
    <div className="item">{priority || sponsor}</div>
    <div  className="item">{impact || hour}</div>
  </div>
);
// #########################################################-ActivityGroup-######################################################################################
export function ActivityGroup({
  setIdx,
  idx,
  users,
  onSaveActivity,
  activities,
  onDeleteActivity,
  change,
  language,
  store,
  typeTemp,
  createToTemplate,
  params
}) {
  const action = new Actions();
  let [ActivityOpen, setActivityOpen] = useState(null);
  let [ac, setAc] = useState(null);
  let [type, setType] = useState(null);
  let [modaldelete, setModalDelete] = useState(false);

let [open,setOpen]=useState(false);
  let [valuesRelForm , setValuesRelForm]=useState([]);
  let [valuesList , setValuesList]=useState([]);
  let [templateform,settemplateform]=useState(false)
  let [openAssociateTask,setopenAssociateTask] = useState(false);
  let [taskWorkflow,settaskWorkflow] = useState(false);
  let [taskAssociate,settaskAssociate] =  useState([]);
  window._activities = activities;

  activities = activities.sort(function (a, b) {
    return a.id_change_activity - b.id_change_activity;
  });


  const saveRelForm =(v)=>{
    v.id_change_activity = ac.id;
    action
    .execute(
      'post',
      `${Constants.APIEndpoints.CHANGE}/activity_milestone`,
      'activities',
      v,
      'id_change_activity',
      null,
      null
    )
    .then((g) => {

      setOpen(false)
    })

  }
  const ondeletefile= (id,not) => {
		 action.execute('get', `${Constants.APIEndpoints.CHANGE}/deletefileac/${id}`, '');

	  }

  const openTemplate=(id)=> {
    action
        .execute('get', Constants.APIEndpoints.TASK + '/getform', 'formstemplates')
        .then((g) => {
         console.log(g.data)
          setValuesList( g.data)
          setOpen(true)
          setopenAssociateTask(false)
        });
      }
  const openTemplateAssoc=(id)=> {


  }









  return (

    <Fragment>
      {/*##############################-GRUPO DE ATIVIDADES-#######################################*/}
      <div className="activity-list">
        <span
          style={{ fontSize: 10, color: 'var(--primary)', fontWeight: 'bold' }}
        >
          {language.ADD_ACTIVITIES_TO_CATEGORY}
        </span>
        <div className="mt-1">
          {[
            {
              label: 'Pre rec',
              indice: 4,
              items:
                activities &&
                activities
                  .filter((h) => h.type == 4)
                  .map((h) => {
                    return {
                      id: h.id_change_activity,
                      name: h.name,
                      hour: h.time_estimate,
                      sponsor: h.nameuser||h.group_tech,
                      fk_id_template_form : h.fk_id_template_form,
                      order : h.priority,
                      onEdit: () => {
                        setActivityOpen(true);
                        setAc(h);
                      },
                      onDelete: () => {
                        setAc(h), setModalDelete(true);
                      },
                    };
                  }),
            },
            {
              label: language.ACTIVITY + ' *',
              indice: 1,
              items:
                activities &&
                activities
                  .filter((h) => h.type == 1)
                  .map((h) => {
                    return {
                      id: h.id_change_activity,
                      name: h.name,
                      hour: h.time_estimate,
                      sponsor: h.nameuser||h.group_tech,
                      fk_id_template_form : h.fk_id_template_form,
                      order : h.priority,
                      onEdit: () => {
                        setActivityOpen(true);
                        setAc(h);
                      },
                      onDelete: () => {
                        setAc(h), setModalDelete(true);
                      },
                    };
                  }),
            },
            {
              label: language.TEST,
              indice: 2,
              items:
                activities &&
                activities
                  .filter((h) => h.type == 2)
                  .filter((g) => g.type == 2)
                  .map((h) => {
                    return {
                      id: h.id_change_activity,
                      name: h.name,
                      hour: h.time_estimate,
                      sponsor: h.nameuser||h.group_tech,
                      fk_id_template_form : h.fk_id_template_form,
                      order : h.priority,
                      onEdit: () => {
                        setActivityOpen(true);
                        setAc(h);
                      },
                      onDelete: () => {
                        setAc(h), setModalDelete(true);
                      },
                    };
                  }),
            },
            {
              label: language.ROLLBACK + ' *',
              indice: 3,
              items:
                activities &&
                activities
                  .filter((h) => h.type == 3)
                  .filter((g) => g.type == 3)
                  .map((h) => {
                    return {
                      id: h.id_change_activity,
                      name: h.name,
                      hour: h.time_estimate,
                      sponsor: h.nameuser||h.group_tech,
                      fk_id_template_form : h.fk_id_template_form,
                      order : h.priority,
                      onEdit: () => {
                        setActivityOpen(true);
                        setAc(h);
                      },
                      onDelete: () => {
                        setAc(h), setModalDelete(true);
                      },
                    };
                  }),
            },
          ].map((e, i) => (
            <Fragment>
              <div className="d-flex align-items-center mt-2">
                <div
                  className="d-flex align-items-center btn-control"
                  onClick={() => setIdx(idx == i ? null : i)}
                >
                  <i
                    className={classnames(
                      'fas mr-2',
                      idx == i ? 'fa-chevron-down' : 'fa-chevron-right '
                    )}
                  />
                  <span>{e.label}</span>
                </div>
                 <Button
                  icon="fas fa-plus"
                  color="warning"
                  variant="circle"
                  size={0.6}
                  disabled ={change.type_gmud == 2 && typeTemp==false ?
                   true:false}
                  onClick={() => {{


                    setActivityOpen(e.label);
                    setAc(null);
                    setType(e.indice);
                  }}}
                />
              </div>
              <div className={classnames('content-items', { open: idx == i })}>

                {e.items.map((e) => (
                  <Items openTemplate={openTemplate} type_gmud={change.type_gmud} typeTemp={typeTemp} openTemplateAssoc={openTemplateAssoc}
                   setWork={()=>settaskWorkflow(true)}
                  setac={()=>{ setOpen(false); setopenAssociateTask(false);  setAc(e)  }} key={e.id} {...e} />
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      {/*###############################-MODAL ADICIONAR ATIVIDADE-##########################################*/}

      <DialogConfirm
        open={modaldelete}
        maxWidth="xs"
        onClose={() => setModalDelete(false)}
        title={language.CHANGE}
        message={language.DO_YOU_WANT_TO_REMOVE_ACTIVITY}
        confirmLabel={language.YES}
        cancelLabel={language.NO}
        cancelProps={{
          color: 'primary',
        }}
        eventClose
        onConfirm={() => onDeleteActivity(ac.id_change_activity)}
        onCancel={() => setModalDelete(false)}
      />
      <AddActivity
        open={ActivityOpen}
        users={users}
        typeTemp={typeTemp}
        change={change}
        language={language}
        store={store}
        ondeletefile={ondeletefile}
        onSaveActivity={onSaveActivity}
        ac={ac}
        type={type}
        title={language.ADD + ' ' + ActivityOpen}
        onClose={() => setActivityOpen(null)}
        onSubmit={(e) => null}
        params = {params}
      />


{ open == true ? (
          <Dialog
            PaperProps={{
              style: {
                height: '70%',
              },
            }}
            open={true}
            title={language.FORM_TEMPLATE}
            onClose={() => setOpen( false )}
            maxWidth="md"
          >
            <Card className="content">
              <CommonForm
                values={{ id_template_forms: ac.fk_id_template_form }}
                fields={[
                  {
                    col: 12,
                    name: 'id_template_forms',
                    label: 'templates',
                    type: 'listCheckbox',

                    options:  (valuesList||[]).filter(item=>item.id_template_forms > 0).map((i) => ({
                      value: i.id_template_forms,
                      label: i.name,
                    })),
                  },
                ]}
                onSubmit={(v) => {
                 saveRelForm(v);
                }}
              />
            </Card>
          </Dialog>
        ) : null}

      <Dialog
        open={openAssociateTask}
        title={language.ASSOCIATE_TASKS}
        >
          <CommonForm
            values={{fk_id_change_activity_assoc :valuesList.map(g=>g.fk_id_activity_change_predecessor) }}
            fields={[
              {
                name: 'fk_id_change_activity_assoc',
                type: 'listCheckbox',
                options: (
                  (activities) ||
                  []
                )
                  .filter(
                    (c) =>c.id_change_activity != (ac&&ac.id) )
                  .map((v) => ({
                    label: v.name,
                    value: parseInt(v.id_change_activity),
                  })),
              },
            ]}
            onSubmit={(v) => saveRelForm(v)}
          />
        </Dialog>


        <Dialog
          open={taskWorkflow}
          title="Workflow"
          onClose={() => settaskWorkflow(false)}
        >
          <Workflow
            action={action}
            endpoints={Constants.APIEndpoints}
            language={language}
            taskAssociate={taskAssociate}
          />
        </Dialog>

    </Fragment>
  );
}
