import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import Button from '@common/Button';
import { Select, MenuItem, InputLabel } from '@material-ui/core'; // controls
import PageLayout from '../../common/PageLayout';
import { TextField } from '@material-ui/core';
import Help from '../../common/components/Help';
import PieChartGraph from './piechart/PieChartGraph';
import { PureComponent } from 'react';
import ReactHighcharts from 'react-highcharts';
import CommonPage from '@common/CommonPage';

import ReactToPrint from 'react-to-print';
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis
} from 'recharts';

import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  YAxis,
  LabelList
} from 'recharts';
import moment from 'moment';
import Loading from '@common/Loading';

@observer
export default class RiskTestDashboard extends CommonPage {
  render() {
    let {
      language,
      handleCompany,
      handlePage,
      companies,
      companyId,
      maturity,
      tests,
      classIcon,
      usercon_company
    } = this.props;

    let company = '';
    try {
      company = companies.filter(b => b.id_company == this.props.companyId)[0].name
    } catch (e) { }

    let companiesSelect = companies
      .filter(c => c.type !== 2)
      .map(c => (
        <MenuItem
          style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
          key={c.id_company}
          value={c.id_company}
          primaryText={c.name}
        >
          {c.name}
        </MenuItem>
      ));

    if (this.store.getUserLoged().role == 'tech') {
      companiesSelect = companies
        .filter(g => usercon_company[0].company.includes(g.id_company))
        .map(c => (
          <MenuItem
            style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
            key={c.id_company}
            value={c.id_company}
            primaryText={c.name}
          >
            {c.name}
          </MenuItem>
        ));
    }

    if (!tests.length) {
      return <Loading loading={true} />;
    }

    let mat = 0;
    let data = [];
    try {
      if (tests[0] && tests[0].dash) {
        mat = tests[0].dash.reduce(
          (value, obj) => value + (obj.value * 100) / obj.total,
          0
        );
        mat = (mat / tests[0].dash.length).toFixed(2);

        data = tests.map(t => {
          let value = t.dash.reduce(
            (value, obj) => value + (obj.value * 100) / obj.total,
            0
          );
          return {
            name: moment(t.dt_cad).format('L'),
            qtd: value / t.dash.length
          };
        });
      }
    } catch (e) { }

    let sumTotalmat = 0;
    let sumlinha = 0;

    let dttt = [];
    try {
      tests[0].dash.map((t, i) => {
        // data[result.name].current_maturity.push(parseInt((result.current_maturity/result.planned_maturity)*100) || 0);
        //data[result.name].planned_maturity.push(parseInt((result.planned_maturity/result.total)*100) || 0);

        maturity
          .filter(v => v.id_section == t.id)
          .map(g => {
            sumlinha = sumlinha + parseInt(g.totallinha);
            if (g.current_maturity === null) {
              g.current_maturity = 0;
            }
            sumTotalmat = sumTotalmat + parseInt(g.current_maturity);

            dttt.push({
              subject: t.area,
              A: parseInt((g.current_maturity / g.total) * 100),
              B: parseInt((g.planned_maturity / g.total) * 100),
              fullMark: g.planned_maturity
            });
          });
         });

      sumlinha = (sumTotalmat / sumlinha).toFixed(1);
    } catch (e) { }

    var polar = {
      chart: {
        polar: true,
        type: 'area',
        height: 800,
        margin: 0
      },

      title: {
        text: language.MATURITY
      },
      subtitle: {
        text: ''
      },

      pane: {
        size: '80%'
      },

      xAxis: {
        categories: dttt.map(e => e.subject),
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          formatter: function () {
            if (this.value.toString().length > 5 && dttt.length > 20) {
              return this.value.toString().substr(0, 5) + '...';
            } else {
              return this.value;
            }
          }
        }
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true,
        valueSuffix: '%'
      },

      series: [
        {
          name: language.PLANNED_MATURITY,
          data: dttt.map(e => (!!e.B ? parseInt(e.B) : 0)),
          pointPlacement: 'on',
          color: '#efa824'
        },
        {
          name: language.CURRENT_MATURITY,
          data: dttt.map(e => (!!e.A ? parseInt(e.A) : 0)),
          pointPlacement: 'on',
          color: '#009dc0'
        }
      ]
    };

    var QUANTITY_PERIOD = {
      chart: {
        type: 'column'
      },
      title: {
        text: language.QUANTITY_PERIOD
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 150,
        y: 100,
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF'
      },
      xAxis: {
        categories: data.reverse().map(e => e.name)
      },
      yAxis: {
        title: {
          text: language.MATURITY
        },
        max: 100
      },
      tooltip: {
        shared: true,
        valueSuffix: '%'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5
        }
      },
      series: [
        {
          name: language.MATURITY,
          data: data.reverse().map(e => e.qtd),
          color: '#009dc0'
        }
      ]
    };

    return (
      <PageLayout
        icon={
          <div
            className={classIcon[tests[0].name]}
            style={{ width: 44, height: 44 }}
          />
        }
        title={tests[0].name}
        rightElements={[
          <div style={{ width: 400, zoom: 0.8, marginTop: '1rem' }} zoom={0.8} className='d-flex align-items-center'>
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary"
                  icon="fas fa-print"
                  style={{ marginTop: '5px' }}
                  label={language.PRINT}
                />
              )}
              content={() => this.componentRef}
            />
            <TextField
              id="outlined-select-currency"
              select
              label={language.COMPANY}
              style={{ minWidth: 200, marginLeft: '1em', marginRight: '1em' }}
              value={this.props.companyId}
              onChange={(event, key, values) => handleCompany(event.target.value)}
              margin="dense"
              variant="outlined"
            >
              {companiesSelect}
            </TextField>
            <RaisedButton
              label={language.CONFIG}
              primary={true}
              onClick={() =>
                this.props.redirect2(
                  this.props.history.location.pathname + '/config',
                  { id_company: this.props.companyId, risk: this.props.risk, namecompany: company }
                )
              }
              circleButton
              icon="fas fa-cog"
              color="primaryGradient"
            />
          </div>
        ]}
      >
        <Loading>
          <Card style={{ marginTop: 10 }}>
            {tests[0].dash ? (
              <Container ref={el => (this.componentRef = el)} fluid>
                <Row>
                  <Col
                    md={12}
                    style={{
                      display: 'flex !important',
                      justifyContent: 'center !important'
                    }}
                  >

                    <ReactHighcharts config={polar} />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col md={10}>
                    <Card>
                      <ReactHighcharts config={QUANTITY_PERIOD} />

                    </Card>
                  </Col>
                  <Col md={2}>
                    <Card>
                      <h2 style={{ paddingTop: 35, textAlign: 'center' }}>
                        {language.MATURITY}
                      </h2>
                      <ResponsiveContainer width="100%" height={354}>
                        <ComposedChart
                          height={300}
                          data={[{ name: '', total: sumlinha }]}
                          margin={{ right: 25, top: 25, bottom: 5 }}
                        >
                          <XAxis dataKey="name" />
                          <YAxis ticks={[5]} />
                          <CartesianGrid stroke="#f5f5f5" />
                          <Bar
                            dataKey="total"
                            barSize={20}
                            fill="var(--primary)"
                          >
                            <LabelList
                              dataKey="total"
                              position="top"
                              fill="var(--secundary)"
                              content={a => a.value + '%'}
                            />
                          </Bar>
                        </ComposedChart>
                      </ResponsiveContainer>
                    </Card>
                  </Col>
                </Row>
              </Container>
            ) : (
              <h2 style={{ paddingTop: 30 }}>{language.RISKS_NO_TEST}</h2>
            )}
          </Card>
        </Loading>
      </PageLayout>
    );
  }
}
