import React, { Component, useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '../../../common/CommonForm2';
import Button from '../../../common/Button';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const SubjectForm = ({
  handlePage,
  onSubmitSubjectAndExit,
  language,
  values,
  themeOptions,
  typeOptions,
}) => {
  const [topicOptions, setTopicOptions] = useState([]);

  const getAllTopics = (values) => {
    return action
      .execute('post', `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllTopicsForTheme?`, values)
      .then((data) => {
        setTopicOptions(data.data);
      });
  };

  useEffect(() => {
    if (values?.id_theme) {
      getAllTopics({ id_theme: values.id_theme });
    }
  }, []);

  const fieldsOptionsTheme = themeOptions.map((theme) => ({
    value: theme.id_theme,
    label: theme.name,
  }));

  const fieldsOptionsTopic = topicOptions?.map((topic) => ({
    value: topic.id_topic,
    label: topic.name,
  }));

  const fields = [
    {
      col: 12,
      name: 'id_theme',
      label: language.THEME,
      type: 'select',
      options: fieldsOptionsTheme,
      required: true,
    },
    {
      col: 12,
      name: 'id_topic',
      label: language.TOPIC,
      type: 'select',
      options: fieldsOptionsTopic,
      required: true,
    },
    {
      col: 12,
      name: 'name',
      label: language.SUBJECT,
      type: 'text',
      required: true,
    },
    {
      col: 6,
      name: 'classification',
      label: language.CLASSIFICATION,
      type: 'select',
      options: typeOptions,
      required: true,
    },
    {
      col: 6,
      name: 'status',
      label: language.STATE,
      type: 'select',
      options: [
        { value: true, label: language.ACTIVE },
        { value: false, label: language.INACTIVE },
      ],
    },
    {
      col: 12,
      name: 'instruction',
      label: language.INSTRUCTIONS,
      type: 'textEditor',
      required: true,
    },
  ];

  const button = { label: language.SAVE, icon: 'fas fa-save' };
  return (
    <Dialog
      miniVersion
      maxWidth="sm"
      monitor_scroll
      title={language.ADD_SUBJECT}
      modal={false}
      open={true}
      onClose={() => handlePage('list')}
    >
      <CommonForm
        values={{ ...values, status: true }}
        fields={fields}
        button={button}
        onChangeField={(field, value) => {
          if (field.name === 'id_theme') {
            getAllTopics({ id_theme: value });
          }
        }}
        onSubmit={onSubmitSubjectAndExit}
      />
    </Dialog>
  );
};

export default SubjectForm;
