import React from 'react';
import { withRouter } from 'react-router';
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import { Card } from '@material-ui/core';
import Button from '@common/Button';
import history from '@common/history';
import Constants from '../../../data/Constants';
import CommonForm from '@common/CommonForm2';
import SearchInput from '@common/SearchInput';
import Header from '@common/Header';
import './AttendanceGADPage.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import AttendanceGADGridList from './AttendanceGADGridList';
import FormAttendance from '../common/FormAttendance';
import { getGroupsFromUserLogged } from '../utils/getGroupsFromUserLogged';
import { getGroupDefaultFALE } from '../utils/getGroupDefaultFALE';
import { findUserGroup } from '../utils/findUserGroup';

class DashboardPage extends CommonPage {
  constructor() {
    super();
    this.state = {
      search: '',
      filter: {},
      dtinicial: '',
      dtfinal: '',
      page: 'list',
      showFale: false
    };

    this.searchfind = this.searchfind.bind(this);
    this.filterDashboard = this.filterDashboard.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.fetchDataGroup = this.fetchDataGroup.bind(this);
  }

  updatePage(param) {
    this.setState({ page: param });
    if (param === 'list') {
      this.filterDashboard()
    }
  }

  searchfind(value) {
    this.setState({ search: value });
    this.setState({ filter: { ...this.state.filter, search: value } });
  }

  clearFilter() {
    this.setState({
      filter: {
        search: '',
        fk_id_theme: 0,
        fk_id_topic: 0,
        fk_id_subject: 0,
        dtinicial: 0,
        dtfinal: 0,
        fk_id_group_tech: 0,
        fk_id_user: 0,
      },
    });
  }
  clearSearch() {
    this.setState({ search: '' });
    this.setState({ filter: { ...this.state.filter, search: '' } });
  }

  filterDashboard(values) {
    this.setState({ filter: { ...values, search: this.state.search } });
  }

  fetchDataGroup = async () => {
    const groupsUserLogged = await getGroupsFromUserLogged(this.store.getUserLoged().id);
    const groupFale = await getGroupDefaultFALE();
    this.setState({ showFale : findUserGroup(groupsUserLogged, groupFale) || this.store.getUserLoged().role === 'client'});
  };

  componentWillMount() {
    this.fetchDataGroup();
  }

  render() {
    const { language } = this.props;
    let { GSD } = this.store.theme;

    const statusList = [
      {
        title: language.TICKET_WORKFLOW_OF_CHANGE.OPEN,
        index: 1,
        status: 'Open',
      },
      {
        title: language.TICKET_STATUS.IN_PROGRESS,
        index: 2,
        status: 'In Progress',
      },
      {
        title: language.TICKET_STATUS.RESOLVED,
        index: 4,
        status: 'Resolved',
      },
      {
        title: language.TICKET_STATUS.CANCELLED,
        index: 5,
        status: 'Canceled',
      },
    ];

    return (
      <PageLayout
        icon="imgGsd titleIconSize"
        title={language.DASHBOARD_PAGE.ATTENDANCE}
        subTitle={language.DASHBOARD_PAGE.DM + '/'}
        rightElements={[
          <div
            style={{ width: 2000, zoom: 0.8, marginRight: -200, marginTop: 20 }}
            zoom={0.8}
          >
            <CommonForm
              onSubmit={(e) => this.filterDashboard(e)}
              individual
              hiddenSubmit
              fields={[
                {
                  col: 1.4,
                  name: 'fk_id_theme',
                  textlabel: language.THEME,
                  type: 'autocomplete',
                  label: language.THEME,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`,
                  routeGetCount: `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'id_theme',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`,
                  methodGetAll: 'POST',
                  detail: true,
                },
                {
                  col: 1.4,
                  name: 'fk_id_topic',
                  textlabel: language.TOPIC,
                  type: 'autocomplete',
                  label: language.TOPIC,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`,
                  routeGetCount: `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'id_topic',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`,
                  methodGetAll: 'POST',
                  detail: true,
                },
                {
                  col: 1.4,
                  name: 'fk_id_subject',
                  textlabel: language.SUBJECT,
                  type: 'autocomplete',
                  label: language.SUBJECT,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
                  routeGetCount: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'id_sac_subject',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
                  methodGetAll: 'POST',
                  detail: true,
                },
                {
                  col: 1.4,
                  name: 'fk_id_group_tech',
                  textlabel: language.TECH_GROUP,
                  type: 'autocomplete',
                  labal: language.TECH_GROUP,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.GROUP}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'id_group_users',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                  methodGetAll: 'POST',
                  detail: true,
                },
                {
                  col: 1.4,
                  name: 'user_start',
                  textlabel: language.USER,
                  type: 'autocomplete',
                  label: language.USER,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'id_user',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  detail: true,
                },
                {
                  col: 1,
                  name: 'dtinicial',
                  type: 'date',
                  label: language.INITIAL_DATE,
                  endIcon: 'fas fa-calendar-alt',
                },
                {
                  col: 1,
                  name: 'dtfinal',
                  type: 'date',
                  label: language.FINAL_DATE,
                  endIcon: 'fas fa-calendar-alt',
                },
                {
                  col: 1.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-search"
                      color="success"
                      variant="normal"
                      label={language.SEARCH_FOR}
                      style={{
                        marginTop: 13,
                      }}
                      onClick={() => submit()}
                    />
                  ),
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      onClick={() => this.clearFilter()}
                      variant="circle"
                      icon="fas fa-eraser"
                      color="success"
                      label="Limpar"
                      style={{
                        fontSize: 14,
                        marginLeft: -90,
                        marginTop: 15,
                      }}
                    />
                  ),
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      onClick={() => history.push('/dash/sac/listtasks')}
                      variant="circle"
                      icon="fas fa-pencil-alt"
                      color="success"
                      label="Lista de tarefas"
                      style={{
                        fontSize: 14,
                        marginLeft: -100,
                        marginTop: 15,
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>,
        ]}
      >
        <div id="GSDPage">
          <Header>
            <Button
              variant="circle"
              icon="fas fa-sync"
              color="warning"
              className="mr-3"
              onClick={() => this.filterDashboard()}
              tooltipLabel="Atualizar página"
            />
            {this.state.showFale && (
              <Button
                icon="fas fa-plus"
                color="warning"
                label="Adicionar"
                variant="circle"
                className="mr-3"
                onClick={() => this.updatePage('form')}
              />
            )}
            <SearchInput
              onKeyUp={(v) => {
                if (v.keyCode === 13) {
                  this.searchfind(v.target.value);
                }
              }}
              clearSeach={() => this.clearSearch()}
            />
          </Header>
          <Card className="content">
            <div
              id="_gsdcarrosell"
              style={{ flex: `1`, display: `flex`, overflow: `auto` }}
            >
              <div id=" " style={{ display: 'flex' }}>
                {statusList.map((status) => (
                  <AttendanceGADGridList
                    getDashboard={this.filterDashboard}
                    user={this.store.getUserLoged().id}
                    title={status.title}
                    index={status.index}
                    language={language}
                    status={status.status}
                    APIEndpoints={this.APIEndpoints}
                    tokenApi={this.store.getToken()}
                    filter={this.state.filter}
                    GSD_THEME={GSD}
                  />
                ))}
              </div>
              {this.state.page === 'form' && (
                <FormAttendance
                  Store={this.store}
                  language={this.store.language}
                  Constants={Constants}
                  action={this.action}
                  setPage={this.updatePage}
                  values_={[]}
                />
              )}
            </div>
          </Card>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(DashboardPage);
