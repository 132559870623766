import React, { useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../common/CommonForm2';
import Constants from '../../data/Constants';
import CommonTable from '../../common/CommonTable';
import Actions from '@data/Actions';
import Button from '@common/Button';
import { Card, Chip } from '@material-ui/core';
import DialogConfirm from '../../common/DialogConfirm';

const action = new Actions();
const MultiTicketTask = ({ language, catalog_task }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState('list');
  const [obj, setObj] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubcategory, setSelectedSubcategory] = useState(0);
  const col = [
    {
      key: 'id_rel_task_multi_ticket',
      label: 'ID',
    },
    {
      key: 'name',
      label: language.NAME,
    },
    {
      key: 'category',
      label: language.CATEGORY,
    },
    {
      key: 'catalog_service',
      label: language.SUBCATEGORY,
    },
    {
      key: 'catalog_task',
      label: language.THIRD_LEVEL,
    },
    {
      key: 'actions',
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (obj.fk_id_category) {
      setSelectedCategory(obj.fk_id_category);
    }
    if (obj.fk_id_catalog_service) {
      setSelectedSubcategory(obj.fk_id_catalog_service);
    }
  }, [obj]);

  const handlePageMulti = (page, obj) => {
    setPage(page);
    setObj(obj);
  };

  const getData = () => {
    action
      .execute('post', Constants.APIEndpoints.TASK + '/getallmultiticket', '', {
        task: catalog_task.id_catalog_task,
      })
      .then((res) => {
        setData(
          res.data.map((d) => ({
            id_rel_task_multi_ticket: d.id_rel_task_multi_ticket,
            name: d.name,
            category: d.category,
            catalog_service: d.catalog_service,
            catalog_task: d.catalog_task,
            actions: (
              <React.Fragment>
                <Chip
                  className="mx-2 muit-chip"
                  label={
                    <div className="d-flex align-items-center justify-content-between">
                      <Button
                        variant="circle"
                        icon="fas fa-edit"
                        label={language.EDIT}
                        color="warning"
                        style={{ fontSize: 12 }}
                        className="mr-2"
                        size={0.7}
                        onClick={() => handlePageMulti('edit', d)}
                      />
                      <Button
                        variant="circle"
                        icon="far fa-trash-alt"
                        label={language.DELETE}
                        color="danger"
                        size={0.7}
                        _loading={'del-task-list-' + obj.id_catalog_task}
                        style={{ fontSize: 14 }}
                        onClick={() => handlePageMulti('delete', d)}
                      />
                    </div>
                  }
                />
              </React.Fragment>
            ),
          }))
        );
      });
  };

  const submitMultiTickets = (values) => {
    const url = obj.id_rel_task_multi_ticket
      ? '/editmultiticket'
      : '/createmultiticket';
    values.task = catalog_task.id_catalog_task;
    values.id_rel_task_multi_ticket = obj.id_rel_task_multi_ticket;
    action
      .execute('post', Constants.APIEndpoints.TASK + url, '', values)
      .then((res) => {
        getData();
        handlePageMulti('list', []);
      });
  };
  const deleteMultiTicket = () => {
    action.execute('post', Constants.APIEndpoints.TASK + '/deletemultiticket', '', obj).then(res => {
      getData();
      handlePageMulti('list', []);
    })
  }
  return (
    <>
      <CommonTable
        columns={col}
        data={data}
        title={language.MULTI_TICKETS}
        beforeBar={
          <Button
            label={language.NEW_CATEGORY}
            primary={true}
            variant="circle"
            icon="fas fa-plus"
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            onClick={() => handlePageMulti('add', [])}
          />
        }
      />
      {(page == 'add' || page == 'edit') && (
          <Dialog open={true} onClose={() => setPage('list')} maxWidth="xl">
            <CommonForm2
              values={{ name: obj?.name }}
              fields={[
                {
                  col: 3,
                  type: 'text',
                  name: 'name',
                  required: true,
                  label: language.NAME,
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'fk_id_category',
                  label: language.CATEGORY,
                  textlabel: language.CATEGORY,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.CATEGORY}/list`,
                  fieldquery: 'search',
                  textinit: obj?.category ? obj.category : '',
                  fieldvaluedb: 'id_category',
                  fieldlabeldb: 'name',
                  idinit: obj?.fk_id_category ? obj.fk_id_category : 0,
                  routeAll: `${Constants.APIEndpoints.CATEGORY}/list?`,
                  methodGetAll: 'POST',
                  detail: true,
                },
                {
                  // visible: store.getUserLoged().role != 'solicitant',
                  col: 3,
                  type: 'autocomplete',
                  name: 'fk_id_catalog_service',
                  label: language.SERVICE_CATALOG,
                  textlabel: language.SERVICE_CATALOG,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.CATALOG}/category/all`,
                  fieldquery: 'search',
                  textinit: obj?.catalog_service ? obj.catalog_service : '',
                  fieldvaluedb: 'id_catalog_service',
                  fieldlabeldb: 'name',
                  idinit: obj?.fk_id_catalog_service
                    ? obj.fk_id_catalog_service
                    : 0,
                  routeAll: `${Constants.APIEndpoints.CATALOG}/category/all?`,
                  methodGetAll: 'POST',
                  detail: true,
                  filterAll: { id_category: selectedCategory },
                },
                {
                  col: 3,
                  type: 'autocomplete',
                  name: 'fk_id_catalog_task',
                  label: language.TASK,
                  textlabel: language.TASK,
                  method: 'POST',
                  route: `${Constants.APIEndpoints.TASK}/list`,
                  fieldquery: 'search',
                  textinit: obj?.catalog_task ? obj.catalog_task : '',
                  fieldvaluedb: 'id_catalog_task',
                  fieldlabeldb: 'name',
                  idinit: obj?.fk_id_catalog_task ? obj.fk_id_catalog_task : 0,
                  routeAll: `${Constants.APIEndpoints.TASK}/list?`,
                  methodGetAll: 'POST',
                  detail: true,
                  required: true,
                  filterAll: { fk_id_catalog_service: selectedSubcategory },
                },
              ]}
              onChangeField={(f, v) => {
                if (f.name == 'fk_id_category') {
                  setSelectedCategory(v);
                }
                if (f.name == 'fk_id_catalog_service') {
                  setSelectedSubcategory(v);
                }
              }}
              onSubmit={submitMultiTickets}
              button={{
                md: 12,
                label: language.SAVE,
                primary: true,
                style: { margin: '120px 0 0 0' },
              }}
            />
          </Dialog>
        )}
      {page == 'delete' && (
        <DialogConfirm
          open={true}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={deleteMultiTicket}
          onClose={() => handlePageMulti('list')}
          title={language.CONFIRM_DELETE}
          message={obj.name}
        />
      )}
    </>
  );
};

export default MultiTicketTask;
