import React, { useEffect, useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Checkbox } from '@material-ui/core';
import CommonPage from '@common/CommonPage';
import moment from 'moment';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';
import Animate from '@common/Animate';
import PageLayout from '@common/PageLayout';
import CommonForm2 from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import OverlayLoading from '@common/OverlayLoading';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
import DownloadFiles from '../../util/DownloadFiles';

const action = new Actions();

const Allowance = () => {
  const [ticketsArray, setTicketsArray] = useState([]);
  const [ticketsIdArray, setTicketsIdArray] = useState([]);
  const [valuesTickets, setValuesTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [dtinicial, setDtinicial] = useState(0);
  const [dtfinal, setDtfinal] = useState(0);
  const [fk_id_company, setFk_id_company] = useState(0);
  const [ids, setIds] = useState(0);
  const [allowance, setAllowance] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState('list');
  const [cleanValueCompany, setCleanValueCompany] = useState(false);
  const [formRefs, setFormRefs] = useReducer((state, val) => {
    return [...state, val];
  }, []);

  const cleanValues = () => {
    formRefs[0].getFields().map((e) => {
      formRefs[0].handleValue(0, e.name, 0);
    });
  };

  const clearFilter = () => {
    setDtinicial(0);
    setDtfinal(0);
    setFk_id_company(0);
    setIds(0);
    cleanValues();
    setCleanValueCompany(true)
  };

  const getTickets = (values = {}, page = 0, totalPerPage = 75, type) => {
    setCurrentPage(page);
    let filters = {
      dtinicial: dtinicial,
      dtfinal: dtfinal,
      fk_id_company: fk_id_company,
      ids: ids,
      allowance: allowance,
      values: values,
    };

    let limit = totalPerPage || 75;
    let offset = page * limit;

    action
      .execute('post', `${Constants.APIEndpoints.ALLOWANCE_TICKET}/list?limit=${totalPerPage}&offset=${offset}`, '', filters)
      .then((res) => {
        setValuesTickets(res.data || []);
        setTotalTickets(parseInt(res.data[0].total_count) || 0);
      });
  };

  const data =
    valuesTickets?.length > 0 &&
    valuesTickets?.map((ticket) => {
      return {
        check_: (
          <Checkbox
            checked={ticketsArray.includes(ticket.real_id)}
            onClick={(e) => {
              setTicketsArray((oldArray) =>
                ticketsArray.includes(ticket.real_id)
                  ? ticketsArray.filter((item) => item !== ticket.real_id)
                  : [...oldArray, ticket.real_id]
              );
              setTicketsIdArray((oldArray) =>
                ticketsArray.includes(ticket.id_tickets)
                  ? ticketsArray.filter((item) => item !== ticket.id_tickets)
                  : [...oldArray, ticket.id_tickets]
              );
            }}
          />
        ),
        real_id: ticket.real_id,
        name: ticket.name,
        dt_cad: ticket.dt_cad
          ? moment(ticket.dt_cad).format('DD/MM/YYYY HH:mm')
          : '',
        dt_up: ticket.dt_up
          ? moment(ticket.dt_up).format('DD/MM/YYYY HH:mm')
          : '',
        sh_prev: ticket.sh_prev
          ? moment(ticket.sh_prev).format('DD/MM/YYYY HH:mm')
          : '',
        sh_stopped: ticket.sh_stopped
          ? moment(ticket.sh_stopped).format('DD/MM/YYYY HH:mm')
          : '',
        sla_name: ticket.sla_name.map((sla) => sla.sla_name).join(' ** ')
      };
    });

  const onFormSubmit = (values) => {
    values.tickets = ticketsIdArray;
    let files = [];
    let cont = 0;
    if (values.files && values.files.length > 0) {
      let total = Array.from(values.files).length;
      Array.from(values.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });

        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(language.FILE + file.name + language.MORE_THAN_40MB);
            filesSend = [];
            cont = 0;
            setTimeout(function () { }.bind(this), 1000);
          }
          files.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.files = files;
            let method = values.allowance ? 'put' : 'post';

            action
              .execute(method, Constants.APIEndpoints.ALLOWANCE_TICKET, '', values)
              .then(() => {
                setPage('list');
                setTimeout(() => {
                  getTickets();
                }, 1000);
                setTicketsArray([]);
                setTicketsIdArray([])
                Store.toggleAlert(true, language.ALLOWANCE_READY, 'success');
              })
              .catch(() => {
                Store.toggleAlert(true, language.ERROR_CODE.E500, 'error');
              });
          }
        });
      });
    } else {
      let method = values.allowance ? 'put' : 'post';

      action
        .execute(method, Constants.APIEndpoints.ALLOWANCE_TICKET, '', values)
        .then(() => {
          setPage('list');
          getTickets();
          setTicketsArray([]);
          setTicketsIdArray([])
          Store.toggleAlert(true, language.ALLOWANCE_READY, 'success');
        })
        .catch(() => {
          Store.toggleAlert(true, language.ERROR_CODE.E500, 'error');
        });
    }
  };

  const onFormSubmitChangeGroupTech = (values) => {

    let files = [];
    let cont = 0;
    if (values.files && values.files.length > 0) {
      let total = Array.from(values.files).length;
      Array.from(values.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });

        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(language.FILE + file.name + language.MORE_THAN_40MB);
            filesSend = [];
            cont = 0;
            setTimeout(function () { }.bind(this), 1000);
          }
          files.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.files = files;
            dispatchChangeGroupTech(values)
          }
        });
      });
    } else {
      dispatchChangeGroupTech(values)
    }
  };

  const dispatchChangeGroupTech = (data) => {
    action
      .execute('post', Constants.APIEndpoints.CHANGE_GROUP_TECH_SLAHISTORY, '', data)
      .then(() => {
        setPage('list');
        getTickets();
        setTicketsArray([]);
        setTicketsIdArray([])
        Store.toggleAlert(true, language.ALLOWANCE_READY, 'success');
      })
      .catch(() => {
        Store.toggleAlert(true, language.ERROR_CODE.E500, 'error');
      });
  }


  useEffect(() => {
    getTickets();
    setTicketsArray([]);
    setTicketsIdArray([])
  }, [allowance]);

  const sendvalues = () => {
    setIsLoading(true);
    let filters = {
      dtinicial: dtinicial,
      dtfinal: dtfinal,
      fk_id_company: fk_id_company,
      ids: ids,
      allowance: allowance,
    };
    action
      .execute('post', `${Constants.APIEndpoints.ALLOWANCE_TICKET}/report`, '', filters)
      .then((g) => {
        if( g['data']['data'] ) {
          g['data'] = g['data']['data'];
        }
        setTimeout(() => {
          setIsLoading(false);
          Store.toggleAlert(true, language.DOWNLOAD_READY, 'success');
          DownloadFiles(
            Constants.APIEndpoints.ALLOWANCE_TICKET +
            '/' +
            Store.getUserLoged().id +
            '/' +
            g.data.file,
            g.data.file
          );
        }, 3000);
      });
  };

  const language = Store.language;

  const cols = [
    {
      key: 'check_',
      label: '#',
      thConfig: true,
      style: { width: 10 },
      search: false,
    },
    {
      key: 'real_id',
      label: language.ID,
      thConfig: true,
    },
    {
      key: 'name',
      label: language.COMPANY,
      thConfig: true,
      style: { width: 150 },
    },
    {
      key: 'dt_cad',
      label: language.REGISTRATION_DATE,
      thConfig: true,
    },
    {
      key: 'dt_up',
      label: language.DATE_LAST_CHANGE,
      thConfig: true,
    },
    {
      key: 'sh_prev',
      label: language.DATE_TARGET,
      thConfig: true,
    },
    {
      key: 'sh_stopped',
      label: language.END_DATE,
      thConfig: true,
    },
    {
      key: 'sla_name',
      label: language.RULE_SERVICE_LEVEL,
      thConfig: true,
    },
  ];

  return (
    <Card className="content">
      {isLoading && <OverlayLoading />}
      <CommonForm
        spacing={2}
        onRef={(ref) => setFormRefs(ref)}
        hiddenSubmit
        onSubmit={() => {
          setCleanValueCompany(false)
          setCurrentPage(0);
          getTickets({}, 0);
        }}
        onChangeField={(f, fk_id) => {
          if (f.name == 'ids') {
            setIds(fk_id);
          } else if (f.name == 'fk_id_company') {
            setFk_id_company(fk_id);
          } else if (f.name == 'dtinicial') {
            setDtinicial(fk_id);
          } else if (f.name == 'dtfinal') {
            setDtfinal(fk_id);
          } else if (f.name == 'allowance') {
            setAllowance(fk_id);
          }
        }}
        fields={[
          {
            col: 1.5,
            name: 'ids',
            type: 'chipInput',
            label: language.IDS,
            placeholder: 'T123,T456,...',
          },
          {
            col: 2,
            type: 'autocomplete',
            name: 'fk_id_company',
            label: language.COMPANY,
            method: 'POST',
            route: `${Constants.APIEndpoints.COMPANY}/filter`,
            fieldquery: 'search',
            textlabel: language.COMPANY,
            textinit: language.COMPANY,
            fieldvaluedb: 'id_company',
            fieldlabeldb: 'name',
            idinit: 0,
            routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
            methodGetAll: 'POST',
            routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
            detail: true,
            cleanValue: cleanValueCompany,
          },
          {
            col: 1.5,
            name: 'dtinicial',
            type: 'date',
            label: language.START_DATE,
            endIcon: 'fas fa-calendar-alt',
          },
          {
            col: 1.5,
            name: 'dtfinal',
            type: 'date',
            label: language.FINAL_DATE,
            endIcon: 'fas fa-calendar-alt',
          },
          {
            col: 1.5,
            name: 'allowance',
            type: 'check',
            label: language.TICKET_ALLOWANCE,
            style: { marginTop: 6 },
          },
          {
            col: 1,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                icon="fas fa-search"
                color="success"
                variant="normal"
                label={language.SEARCH_FOR}
                style={{
                  marginTop: 13,
                }}
                onClick={() => submit()}
              />
            ),
          },
          {
            col: 0.5,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                onClick={() => clearFilter()}
                variant="circle"
                icon="fas fa-eraser"
                color="success"
                label={language.CLEAR_FILTER}
                style={{
                  fontSize: 14,
                  //marginLeft: 6,
                  marginTop: 15,
                }}
              />
            ),
          },
          {
            col: 1,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                onClick={() => setPage('abonar')}
                variant="normal"
                icon="fas fa-save"
                color="primary"
                label={allowance ? language.REVERT : language.ALLOWANCE}
                style={{
                  //fontSize: 14,
                  //marginLeft: -15,
                  marginTop: 15,
                }}
              />
            ),
          },
          {
            col: 1.5,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                onClick={() => setPage('change-group-tech')}
                variant="normal"
                color="primary"
                label={language.CHANGE_TECH_GROUP}
                style={{
                  //fontSize: 14,
                  //marginLeft: -15,
                  marginTop: 15,
                }}
              />
            ),
          },
        ]}
      />
      <CommonTable
        title={''}
        language={language}
        col={cols}
        excel={sendvalues}
        data={data}
        isAutomaticPagination={false}
        paginationTop={false}
        width="100%"
        searchColumn
        currentPage={currentPage}
        countTotal={totalTickets}
        searchColumnEvent={(v) => {
          getTickets(v);
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          getTickets(values, page, rowsPerPage, type)
        }
      />
      {page == 'abonar' && (
        <Dialog
          open={true}
          onClose={() => setPage('list')}
          title={language.ALLOWANCE}
          maxWidth="lg"
        >
          <CommonForm2
            values={{
              notes: ticketsArray
                .map((ticket) => {
                  return `${ticket} -  ${valuesTickets.find((t) => t.real_id == ticket)
                    ?.subject
                    } </br>`;
                })
                .join(''),
              allowance: allowance,
            }}
            fields={[
              {
                name: 'allowance',
                visible: false,
              },
              {
                col: 12,
                type: 'textEditor',
                name: 'notes',
                label: language.ALLOWANCE_SELECTED,
                disabled: true,
                style: { height: 70 },
              },
              {
                col: 12,
                type: 'textEditor',
                name: 'justification',
                label: language.JUSTIFICATION,
                required: true,
                visible: !allowance,
              },
              {
                col: 12,
                name: 'files',
                label: language.ATTACH_FILE,
                type: 'file',
              },
            ]}
            onSubmit={onFormSubmit}
          />
        </Dialog>
      )}

      {page == 'change-group-tech' && (
        <Dialog
          open={true}
          onClose={() => setPage('list')}
          title={language.CHANGE_TECH_GROUP}
          maxWidth="lg"
        >
          <CommonForm2
            values={{
              notes: ticketsArray
                .map((ticket) => {
                  return `${ticket} - ${valuesTickets.find((t) => t.real_id == ticket)?.subject} </br>`;
                })
                .join(''),
              tickets: ticketsArray.map(item => valuesTickets.find((t) => t.real_id == item)?.id_tickets)
            }}
            fields={[
              {
                name: 'tickets',
                visible: false,
              },
              {
                col: 12,
                type: 'textEditor',
                name: 'notes',
                label: language.ALLOWANCE_SELECTED,
                disabled: true,
                style: { height: 70 },
              },
              {
                span: 12,
                type: 'autocomplete',
                name: 'fk_id_group_tech',
                label: language.TECH_GROUP,
                className: 'tech-group-ticket',
                required: true,
                method: 'POST',
                route: `${Constants.APIEndpoints.GROUP}/filter`,
                fieldquery: 'search',
                textinit: language.CHANGE_TECH_GROUP,
                fieldvaluedb: 'id_group_users',
                fieldlabeldb: 'name',
                //idinit: values.fk_id_group_tech,
                routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                detail: true,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                // filterAll: {
                //   subtickets:
                //     ticket.config[0].real_id.indexOf('.') > -1
                //       ? true
                //       : false,
                //   mail_default: ticket.mail_default,
                //   id_company_mail_default: ticket.fk_id_company
                // },
              },
              {
                col: 12,
                type: 'textEditor',
                name: 'justification',
                label: language.JUSTIFICATION,
                required: true,
                visible: !allowance,
              },
              {
                col: 12,
                name: 'files',
                label: language.ATTACH_FILE,
                type: 'file',
              },
            ]}
            onSubmit={onFormSubmitChangeGroupTech}
          />
        </Dialog>
      )}
    </Card>
  );
};

export default Allowance;
