import {useState} from 'react';
import {Grid, Card, Chip} from '@material-ui/core';
import AppBar from '@common/AppBar';
import classnames from 'classnames';
import ReactHighcharts from 'react-highcharts';
import SimplesBarGraph from '@common/SimplesBarGraph';
import Graphs from './Graphs.js';
import moment from 'moment';

export default function DemandGraph(props){
	let {language={}, tickets, activities, ticketsCategories, techs, changes, serviceOrders} = props;

	function toSecond(hms) {
	  try {
	    //hms = '02:04:33';   // your input string
	    var a = hms.split(':'); // split it at the colons

	    // minutes are worth 60 seconds. Hours are worth 60 minutes.
	    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
	    return seconds;
	  } catch (r) {
	    return 0;
	  }
	}
	
	// ----------------------------------rotina para o gráfico de MINHAS DEMANDAS-------------------------------------------------
	var openToday = 0;
	var status = tickets.reduce((obj, e)=>{
	  if(moment().isSame(e.dt_cad, 'day')) openToday++;
	  obj[e.status]++;
	  return obj;
	}, {
	  'In Progress':0,
	  Open:0,
	  Resolved:0,
	  Closed:0
	});
	
	// ----------------------------------rotina para o gráfico de HORAS-----------------------------------------------------------

	 let array=[];
	 
	 tickets.map(g=>{

		array.push({dt_cad:g.dt_cad ,  time_resolution : g.time_resolution   })
	   }) 
	   serviceOrders.map(g=>{

		array.push({dt_cad:g.start_date ,  time_resolution : g.intervalo   })
       }) 
   

	var hoursGraph = array.reduce((obj, e)=>{
		var month = moment(e.dt_cad).format('M');
		if(obj[month]){
			obj[month] += Number((toSecond(e.time_resolution)/3600).toFixed(1));
		}else{
			obj[month] = Number((toSecond(e.time_resolution)/3600).toFixed(1));
		}

		return obj;
	}, {});


	 
	
  


	let obb=0;
	var hoursGraphTotal = 0;
	var hoursGraphTotalAlocate =0;
	 tickets.map(e=>{
 
		hoursGraphTotal  += Number((toSecond(e.time_resolution)/3600).toFixed(1));
	 }) 

 
	  activities.map(e=>{
 
		 
		   let diff=    moment.duration(moment(e.dt_end).diff(moment(e.dt_start)));
	    
		hoursGraphTotalAlocate += parseInt(diff.asHours());
	 })  

	 changes.map(e=>{
 
		 
		let diff=    moment.duration(moment(e.end_date).diff(moment(e.start_date)));
	 
	 hoursGraphTotalAlocate += parseInt(diff.asHours());
  }) 
  serviceOrders.map(e=>{
 
	 hoursGraphTotal += 	Number((toSecond(  e.intervalo)/3600).toFixed(1));
	
		 
}) 
	  
	   
	 

	return(
		<div id='DemandGraphs'>
			<Grid container spacing={16}>
				{/*----------------------------------------MINHAS DEMANDAS------------------------------------------------------------------*/}
				<Grid item xs={4}>
					<Card className='card h-100'>
						<AppBar style={{top:-15, marginBottom:-15, width:'90%'}}>
							<span className='font'>{language.MY_DEMANDS}</span>
						</AppBar>
						<div className='content'>
							<Grid container spacing={2} justify="space-between">
								<Grid item xs={8}>
									<div className='status-items-content'>
										{[
											{current:status['In Progress'], title:language.TICKET_STATUS.IN_PROGRESS},
											{current:status['Open'], title:language.TICKET_STATUS.OPEN},
											{current:status['Resolved'], title:language.TICKET_STATUS.RESOLVED},
											{current:status['Closed'], title:language.TICKET_STATUS.CLOSED}
										].map(e=>(
											<div className='status-items'>
												<h3>{e.current}</h3>
												<h4 className='pl-2'>{e.title}</h4>
											</div>
										))}
									</div>
								</Grid>
								<Grid item xs={4}>
									<div id='open-today'>
										<h3 className='mb-2'>{openToday}</h3>
										<h4>{language.TICKET_STATUS.OPEN+' '+language.TODAY}</h4>
									</div>
								</Grid>
							</Grid>
							<hr/>
							<div className='d-flex align-items-center justify-content-between modules-items-content'>
								{[
									{current:tickets.length, title:'GSD', type:'warning'},
									{current:activities.length, title:'GP', type:'danger'},
									{current:serviceOrders.length, title:'OS', type:'primary'},
									{current:changes.length, title:'GMUD', type:'success'}
								].map(e=>(
									<div className='modules-items' type={e.type}>
										<h5>{e.current}</h5>
										<Chip
											className='chip'
											label={e.title}
											variant="outlined"
										/>
									</div>
								))}
							</div>
						</div>
					</Card>
				</Grid>
			{/*-----------------------------------------------HORAS-------------------------------------------------------------------------*/}
				<Grid item xs={5}>
					<Card className='card h-100'>
						<AppBar style={{top:-15, marginBottom:-15, width:'90%'}}>
							<span className='font'>{language.HOURS}</span>
						</AppBar>
						<div className='content'>
							<ReactHighcharts
								config={
									SimplesBarGraph.container0({
										chart: {
										  type: 'line',
										  backgroundColor:'transparent',
										  height:340,
										  style: {
										      fontFamily: '\'Unica One\', sans-serif'
										  },
										},
										legend: {
										  enabled: false
										},
										series:[
											{
												name:'Horas',
												data: [
													{name:language.DATE_LANG.monthList[6], y:hoursGraph['7']||0},
													{name:language.DATE_LANG.monthList[7], y:hoursGraph['8']||0},
													{name:language.DATE_LANG.monthList[8], y:hoursGraph['9']||0},
													{name:language.DATE_LANG.monthList[9], y:hoursGraph['10']||0},
												]
											}
										]
									})
								}
							/>
						</div>
					</Card>
				</Grid>
			{/*----------------------------------------HORAS PRODUTIVAS & VELOCÍMETROS-----------------------------------------------------*/}
				<Grid item xs={3}>
					<Card className='card h-100 content velocimeter'>
						<div>
							<div className='product-hours'>
								<h5>176</h5>
								<Chip
									className='chip'
									label={language.PRODUCTIVE_HOURS}
									variant="outlined"
								/>
							</div>
						</div>
						<hr className='mb-2'/>
						<div style={{marginBottom:-20}}>
							<ReactHighcharts
								config={Graphs.velocimeter({
									height:145,
									title:language.ALLOCATED,
									size:100,
									value:hoursGraphTotalAlocate
								})}
							/>
						</div>
						<hr className='mb-2'/>
						<div style={{marginBottom:-20}}>
							<ReactHighcharts
								config={Graphs.velocimeter({
									height:145,
									title:language.CONSUMED,
									size:100,
									value:hoursGraphTotal
								})}
							/>
						</div>
					</Card>
				</Grid>
			</Grid>
		</div>
	)
}