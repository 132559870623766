import moment from 'moment';

export const formatDeadline = (value) => {
  const mask = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})/)
  return !mask ? value : parseInt(mask[1]) + ':' + mask[2]
}

export const getDeadline = (deadline, date) => {
  const mask = deadline.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})/)
  
  let dateHours = date.add(mask[1], 'hours');
  let dateMin = dateHours.add(mask[2], 'minutes');
  
  return dateMin;
}

export const differenceBetweenTime = (startTime, endTime) => {
  const diffInMs = moment(endTime).diff(startTime); 
  const diffInMinutes = moment.duration(diffInMs).asMinutes();
  return diffInMinutes;
}

