import BarSquare from '@common/BarSquare';
import Crop from '@common/Crop';
import { MaskTel } from '@common/Mask';
import Constants from '@data/Constants';
import Store from '@data/Store';
import { Avatar, Grid, Paper } from '@material-ui/core';
import classnames from 'classnames';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import CommonForm from '../../common/CommonForm2';
import PageLayout from '../../common/PageLayout';
import RaisedButton from '../../common/RaisedButton';
import Actions from '../../data/Actions';
import LoginRecoverySetting from '../login/LoginRecoverySetting';
import './ChangeData.css';
import { DialogConfirmChangeLanguage } from './components/DialogConfirmChangeLanguage';
import { DialogConfirmChangeTimezone } from './components/DialogConfirmChangeTimezone';
function importAll(r) {
  return r.keys().map(r);
}
//const allImgs = importAll(require.context('@images/flag_countries/', false, /\.(png|jpe?g|svg)$/));

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: option[attributeName],
  }));

  return normalizedOptions;
};

export default class ChangeData extends Component {
  constructor() {
    super();
    this.state = {
      type_tel: 0,
      file: null,
      cropData: {},
      userDados: {},
      DialogConfirmChangeTimezoneOpen: false,
      DialogConfirmChangeLanguageOpen: false,
      DialogConfirmChangeTimezoneValues: {},
      DialogConfirmChangeLanguageValues: {},
    };
    this.action = new Actions();
  }
  onChangeField = (field, value) => {
    if (field.name == 'type_tel') {
      this.setState({ type_tel: value });
    }
  };
  componentWillUnmount() {
    this.action
      .execute('get', Constants.APIEndpoints.USER + '/unique', '')
      .then((item) => {
        this.setState({ userDados: item.data[0] });
      });
  }

  componentDidMount() {
    let { store } = this.props;
    setTimeout(() => {
      if (store.ad.phone != undefined && store.ad.phone.length > 15) {
        this.setState({ type_tel: 1 });
      }
    }, 2000);

    const responseTimezones = () => {
      this.action
        .execute('get', Constants.APIEndpoints.TIMEZONE, '')
        .then((response) => {
          const { data } = response;
          this.setState({
            timezoneOptions: formatSelectOptions(data, 'name', 'id_timezone'),
          });
        });
    };

    responseTimezones();
  }
  onDrop = (e) => {
    if (e.length) {
      this.setState({ file: e[0] });
    }
  };

  logoutApp() {
    window.localStorage.clear();
    localStorage.clear();
    this.redirect('/');
  }

  redirect(route, state) {
    window.history.pushState({ key: 'sensr' + route, state: state }, '', route);
    window.location.reload();
  }

  render() {
    let {
      language,
      onFormSubmit,
      store,
      redirect,
      sendCrop,
      deleteProfilePhoto,
      profilePhoto,
      userItems,
    } = this.props;
    let userLogin = Store.getUserLoged();

    let { timezoneOptions } = this.state;

    let fields = [
      {
        col: 6,
        name: 'name',
        label: language.NAME,
        type: 'text',
        disabled: true,
      },
      {
        col: 6,
        name: 'email',
        label: language.EMAIL,
        type: 'text',
        disabled: true,
      },
      {
        col: 6,
        name: 'phone',
        label: language.CELL_PHONE,
        InputProps: {
          id: {
            format: '(##) #####-####',
          },
          inputComponent: MaskTel,
        },
        type: 'text',
      },
      {
        col: 6,
        name: 'phone_landline',
        label: language.LANDLINE,
        InputProps: {
          id: {
            format: '(##) ####-####',
            placeholder: '(00) 0000-0000',
          },
          inputComponent: MaskTel,
        },
        type: 'text',
      },
      {
        col: 6,
        name: 'ramal',
        label: language.TELEPHONE_EXTENSION,
        type: 'text',
      },
      {
        col: 6,
        type: 'element',
        content: <></>
      },
      {
        col: 6,
        type: 'select',
        name: 'id_timezone',
        label: 'Timezone',
        options: timezoneOptions,
        required: true,
      },
      {
        col: 6,
        type: 'select',
        name: 'id_language',
        label: language.LANGUAGE,
        options: [
          { value: 'pt_br', label: 'Português BR' },
          { value: 'es', label: 'Español' },
          { value: 'en', label: 'English' },
        ],
        required: true,
      },
    ];

    let button = {
      label: language.SAVE,
    };

    let values = userLogin;

    return (
      <>
        {this.state.DialogConfirmChangeLanguageOpen && (
          <DialogConfirmChangeLanguage
            onClose={() =>
              this.setState({
                DialogConfirmChangeLanguageOpen: false,
                DialogConfirmChangeLanguageValues: {},
              })
            }
            handleSubmit={() => {
              onFormSubmit(
                this.state.DialogConfirmChangeLanguageValues,
                'changeData'
              );
              this.logoutApp();
            }}
          />
        )}
        {this.state.DialogConfirmChangeTimezoneOpen && (
          <DialogConfirmChangeTimezone
            onClose={() =>
              this.setState({
                DialogConfirmChangeTimezoneOpen: false,
                DialogConfirmChangeTimezoneValues: {},
              })
            }
            handleSubmit={() => {
              onFormSubmit(
                this.state.DialogConfirmChangeTimezoneValues,
                'changeData'
              );
              this.logoutApp();
            }}
          />
        )}
        <PageLayout
          icon={
            <div className="changeDataIcon" style={{ width: 40, height: 40 }} />
          }
          title={language.USER}
          subtitle={language.UPDATE_USER_INFO}
          rightElements={[]}
        >
          <div id="ChangeData">
            <br />
            <br />
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Paper style={{ height: '100%' }}>
                  <BarSquare
                    icon="fab fa-wpforms"
                    title={language.BASIC_INFO}
                  />
                  <div className="content">
                    <CommonForm
                      fields={fields}
                      onSubmit={(values) => {
                        const hasNewTimezone =
                          userLogin?.timezone?.id !== values?.timezone?.id;
                        const hasNewLanguage =
                          userLogin?.language !== values?.id_language;

                        hasNewTimezone &&
                          this.setState({
                            DialogConfirmChangeTimezoneOpen: true,
                            DialogConfirmChangeTimezoneValues: values,
                          });

                        hasNewLanguage &&
                          this.setState({
                            DialogConfirmChangeLanguageOpen: true,
                            DialogConfirmChangeLanguageValues: values,
                          });
                      }}
                      button={button}
                      values={values}
                      onChangeField={this.onChangeField}
                    />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ height: '100%' }}>
                  <BarSquare
                    icon="fas fa-image"
                    title={language.PROFILE_PICTURE}
                  />
                  <div className="content content-profile d-flex align-items-center">
                    <Grid container spacing={2} justify="center">
                      <Grid item xs={8}>
                        <Dropzone
                          accept={'.jpg,.png,.jpeg'}
                          required
                          multiple={false}
                          onDrop={this.onDrop.bind(this)}
                          disabled={!!this.state.file}
                          style={{ height: '100%' }}
                        >
                          <div
                            className={classnames('area-choose-image', {
                              empty: !this.state.file,
                            })}
                          >
                            {this.state.file && (
                              <div className="button-close-image">
                                <RaisedButton
                                  circleButton
                                  icon="fas fa-times"
                                  label={language.CANCEL}
                                  color="danger"
                                  className="button"
                                  onClick={() => {
                                    this.setState({ file: null });
                                    this.setState({ cropData: {} });
                                  }}
                                  style={{
                                    fontSize: 16,
                                  }}
                                />
                              </div>
                            )}
                            <div
                              style={{ overflow: 'hidden', borderRadius: 10 }}
                            >
                              <Crop
                                file={this.state.file}
                                changeCrop={(e) => {
                                  this.setState({ cropData: e });
                                }}
                              />
                            </div>
                            {!this.state.file && (
                              <div className="area-choose-image-title">
                                <i className="fas fa-image" />
                                <h4>{language.SELECT_IMAGE}</h4>
                              </div>
                            )}
                          </div>
                        </Dropzone>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div style={{ position: 'relative' }}>
                          {profilePhoto && (
                            <div className="content-button-delete-image">
                              <RaisedButton
                                circleButton
                                icon="fas fa-times"
                                label={language.CANCEL}
                                color="danger"
                                className="button-delete-image"
                                onClick={deleteProfilePhoto}
                              />
                            </div>
                          )}
                          <Avatar
                            className="avatar"
                            src={this.state.cropData.blob || profilePhoto}
                          >
                            <i className="far fa-user-circle" />
                          </Avatar>
                          {this.state.file && (
                            <RaisedButton
                              circleButton
                              icon="fas fa-save"
                              label={language.SAVE}
                              color="success"
                              onClick={() =>
                                sendCrop(this.state.file, this.state.cropData)
                              }
                              style={{
                                margin: '0 auto',
                                fontSize: 16,
                                marginTop: 20,
                              }}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ height: '100%' }}>
                  <BarSquare
                    icon="fas fa-shield-alt"
                    title={language.RECOVERY_PASSWORD.PROTECT_ACCOUNT}
                  />
                  <div className="content content-profile d-flex align-items-center tw-mt-6">
                    <LoginRecoverySetting
                      showHeaderLine={false}
                      triggerAlertOnSuccess={true}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </PageLayout>
      </>
    );
  }
}
