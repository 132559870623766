import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
// import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Sidebar from '../Sidebar/index';

import Constants from '../../data/Constants';
import Actions from '../../data/Actions';
import Typography from '@material-ui/core/Typography';

import CommonTable from '@common/CommonTable';
import { Dialog } from '@material-ui/core';
import DialogForm from '@common/Dialog';

import IconArrowForward from '../../../public/images/icon-arrow-forward.png';
import IconArrowRight from '../../../public/images/icon-arrow-right.png';
import IconCentralHelp from '../../../public/images/central_help.jpg';

import infos from '../../../public/images/iconeinformation.png';

import {
  useStyles,
  BoxBottom,
  ImgBox,
  Icon,
  BoxList,
  TextList,
} from './styles.js';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '../Sidebar/styles';

import './styles.css';

function DefaultLayout(props) {
  let { children, classes, perfil, user, language, marginzero, slideBarTheme } =
    props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationsEl, setNotificationsEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [helpModal, setHelpModal] = React.useState(false);
  const [helpDetailModal, setHelpDetailModal] = React.useState(false);
  const [pdf, setPdf] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [helps, setHelps] = React.useState([]);
  const [helpGroup, setHelpGroup] = React.useState([]);
  const [params, setParams] = React.useState([])

  React.useEffect(() => {
    const action = new Actions();

    action
    .execute(
      'get',
      Constants.APIEndpoints.GENERAL_PARAMETERS
    )
    .then((result) => {  
      setParams(result.data);
    })

  }, [])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleShowNotifications = (event) => {
    setNotificationsEl(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setNotificationsEl(null);
  };

  const redirect = (e) => {
    setOpen(false);
    props.redirect(e);
  };
  const redirect2 = (e) => {
    setOpen(false);
    props.redirect2(e);
  };

  const openPDF = (file) => {
    const action = new Actions();
    //console.log('openpdf', file);
    action
      .execute(
        'get',
        Constants.APIEndpoints.HELP + '/' + file.id_help + '/' + file.url
      )
      .then((result) => {
        //console.log('edata,', result.data);

        setPdf(result.data);
        setHelpDetailModal(true);
      })
      .catch((error) => {
        console.log('error, ', error);
      });
  };

  const getHelp = () => {
    const action = new Actions();
    action.execute('get', Constants.APIEndpoints.HELP, 'help').then((e) => {
      setHelps(e.data);
      setHelpModal(true);

      let groupped = _.groupBy(e.data, (data) => data.module);
      setHelpGroup(groupped);
    });
  };

  const showNotifications = Boolean(notificationsEl);

  return (
    <div
      className={classes.root}
      style={{
        '--slideBarTheme': slideBarTheme.drawn,
        '--slideBarThemeIconButton': slideBarTheme.iconButton,
        '--colorIconButton': slideBarTheme.colorIconButton,
        '--iconButtonHover': slideBarTheme.iconButtonHover,
      }}
    >
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            marginzero ? classes.drawerPaper_top : classes.drawerPaper,
            !open && classes.drawerPaperClose
          ),
        }}
        className="drawn"
        open={true}
        id="menu"
      >
        {/*<div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>*/}

        {/* <List>{mainListItems}</List> */}

        <List>
          <Sidebar
            open={open}
            perfil={perfil}
            redirect={redirect}
            language={language}
            user={user}
            redirect2={redirect2}
            drawerOpen={handleDrawerOpen}
            drawerStatus={true}
            params = {params}
          />

          <Divider />
        </List>
        <div style={{ marginTop: open ? 65 : 171 }}>
          <BoxBottom>
            <div className={classes.toolbarIcon}>
              {open ? (
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <ImgBox onClick={() => handleDrawerClose()}>
                      <Icon src={IconArrowForward} alt={language.HIDE} />
                    </ImgBox>
                  </ListItemIcon>
                  <BoxList className="box-list">
                    <TextList>{language.HIDE}</TextList>
                  </BoxList>
                  {/* <ListItemText
                className={classes.itemText}
                primary="RECOLHER"
                disableTypography
              /> */}
                </ListItem>
              ) : (
                <ListItem className={classes.listItem}>
                  <ListItemIcon>
                    <ImgBox onClick={() => handleDrawerOpen()}>
                      <Icon src={IconArrowRight} alt="expand" />
                    </ImgBox>
                  </ListItemIcon>
                </ListItem>
              )}
            </div>

            <Divider />

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ImgBox onClick={() => getHelp()}>
                  <Icon src={IconCentralHelp} alt={language.HELP_DESK} />
                </ImgBox>
              </ListItemIcon>
              <BoxList
                style={{ cursor: 'pointer' }}
                onClick={() => getHelp()}
                className="box-list"
              >
                <TextList>{language.HELP_DESK}</TextList>
              </BoxList>
            </ListItem>
          </BoxBottom>
        </div>
      </Drawer>

      <DialogForm
        open={helpModal}
        onClose={() => setHelpModal(false)}
        maxWidth="md"
        title={language.HELP_DESK}
      >
        {Object.keys(helpGroup).map((item, index) => (
          <ListItem className="moduleContainer" key={index}>
            <Divider />

            <ExpansionPanel
              square
              expanded={expanded === item}
              onChange={handleChange(item)}
              elevation={0}
            >
              <ExpansionPanelSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <i className="fas fa-chevron-down"></i>
                <div style={{ width: 10 }}></div>
                <Typography className="moduleTitle"> {item}</Typography>
              </ExpansionPanelSummary>

              <CommonTable
                index="ticket-detail-history-call"
                searchColumn
                col={[
                  { key: 'action', label: 'Ação' },
                  { key: 'id', label: 'ID' },
                  { key: 'title', label: 'Título' },
                  { key: 'module', label: 'Módulo' },
                  { key: 'description', label: 'Descrição' },
                ]}
                data={helpGroup[item].map((help, indexGroup) => {
                  return {
                    action: (
                      <i
                        key={indexGroup}
                        style={{ cursor: 'pointer' }}
                        onClick={() => openPDF(help)}
                        className="fas fa-eye"
                        style={{ color: 'var(--primary)' }}
                      />
                    ),
                    id: help.id_help,
                    title: help.title,
                    module: help.module,
                    description: help.description,
                  };
                })}
              />
            </ExpansionPanel>
          </ListItem>
        ))}
      </DialogForm>

      <DialogForm
        open={helpDetailModal}
        onClose={() => setHelpDetailModal(false)}
        maxWidth="xl"
      >
        <iframe
          src={'data:text/html;base64,' + pdf}
          width="100%"
          height={screen.height - 150}
        />
      </DialogForm>

      <div
        id="common-content"
        style={{
          marginLeft: open ? '225px' : '55px',
          width: `calc(100% - ${open ? '225px' : '55px'})`,
        }}
      >
        {children}
      </div>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(DefaultLayout);
