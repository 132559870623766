import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Chip, Dialog } from '@material-ui/core';

import Store from '../../data/Store';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import SimplesAlert from '@common/SimplesAlert';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import DeviceContactSearch from './DeviceContactSearch';

export default function DeviceContact({ device }) {
console.log('device', device)
    const fetch = new Actions();
    const deviceId = device.id_device;
    const { language, getUserLoged } = Store;
    const hasActionField = getUserLoged().role == 'client';

    const labelRole = {
        'scm': language.SCM,
        'tech': language.TECH,
        'client': language.ADMIN,
        'solicitant': language.SOLICITANT
    }

    const alertDefault = { opened: false, message: '', variant: 'success' };
    const [alert, setAlert] = useState(alertDefault);
    const [groups, setGroups] = useState([]);
    const [groupSelected, setGroupSelected] = useState();
    const [contacts, setContacts] = useState([]);

    const [openDialogToAddUser, setOpenDialogToAddUser] = useState(false);

    useEffect(async () => {
        const groupResults = await handleGetGroups(deviceId);
        setGroups(groupResults);

        handleItemSelect(groupResults[0]);

    }, []);

    const handleGetGroups = (deviceId) => {
        return fetch
            .execute('get', `${Constants.APIEndpoints.DEVICE}/${deviceId}/contact_groups`)
            .then(result => {
                return Array.isArray(result.data) ? result.data : [];
            })
            .catch(err => {
                console.error(err);
                return [];
            })
    }

    const handleGetContactGroup = (deviceId, groupId) => {
        return fetch
            .execute('get', `${Constants.APIEndpoints.DEVICE}/${deviceId}/contact_groups/${groupId}/contacts`)
            .then(result => {
                if (Array.isArray(result.data)) {
                    return result.data;
                }

                return [];
            })
            .catch(err => {
                console.error(err);
                return [];
            })
    }

    const handleItemSelect = async (selectedItem) => {
        const groupId = selectedItem.id_device_contact_groups;
        await handleGridLoad(deviceId, groupId);
        setGroupSelected(selectedItem);
    }

    const handleGridLoad = async (deviceId, groupId) => setContacts(await handleGetContactGroup(deviceId, groupId));

    const handleDialogClose = async (values) => {

        if (values && Array.isArray(values.id_user)) {
            await handleAddUsers(values);
            await handleGridLoad(deviceId, groupSelected.id_device_contact_groups);
        }

        setOpenDialogToAddUser(false);
    }

    const handleAddUsers = (users) => {

        const payload = {
            //users: users.map(item => item.id_user)
            users: users.id_user
        }

        fetch
            .execute('post', `${Constants.APIEndpoints.DEVICE}/${deviceId}/contact_groups/${groupSelected.id_device_contact_groups}/contacts`, null, payload)
            .then(result => {
                handleOpenAlert(`${language.ALERT_INSERT_SUCCESS}`)
            })
            .catch(err => {
                handleOpenAlert(`ERROR: ${language.UNREGISTERED_USER}`, 'error');
                console.error(err);
            })
    }

    const handleRemoveUser = (user) => {
        const contact = contacts.find(item => item.id_device_contacts == user.id_device_contacts);
        contact && fetch
            .execute('delete', `${Constants.APIEndpoints.DEVICE}/${deviceId}/contact_groups/${groupSelected.id_device_contact_groups}/contacts/${contact.id_user}`)
            .then(async (result) => {
                handleOpenAlert(`${language.ALERT_DELETE_SUCCESS}`);
                await handleGridLoad(deviceId, groupSelected.id_device_contact_groups)
            })
            .catch(err => {
                handleOpenAlert(`ERROR: ${language.AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER}`, 'error');
                console.error(err);
            });
    }

    const handleOpenAlert = (message, variant = 'success') => setAlert({ opened: true, message: message, variant: variant });

    const handleCloseAlert = () => setAlert(alertDefault);


    const columns = [
        { key: 'id_device_contacts', label: language.ID, style: { width: 1 } },
        { key: 'user_name', label: language.USER },
        { key: 'company_name', label: language.COMPANY },
        { key: 'role', label: language.ROLE },
        { key: 'phone', label: language.PHONE },
        { key: 'status', label: language.STATUS },
    ];

    if (hasActionField)
        columns.push({ key: 'actions', label: language.ACTIONS, style: { width: 80, textAlign: 'center' } });


    let dataFiltered = [];
    if (contacts.length > 0) {
        dataFiltered = [...contacts].map(item => ({
            id_device_contacts: item.id_device_contacts,
            user_name: item.user_name,
            company_name: item.company_name,
            role: labelRole[item.role] ?? '',
            phone: item.phone,
            status: item.status ? language.ACTIVE : language.INACTIVE,
        }));;

        if (hasActionField) {
            dataFiltered.forEach(item => {
                item.actions = (
                    <React.Fragment>
                        <RaisedButton
                            circleButton
                            color="danger"
                            icon="far fa-trash-alt"
                            label={language.DELETE}
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            onClick={() => handleRemoveUser(item)}
                        />
                    </React.Fragment>
                )
            });
        }
    }

    return (
        <div className='tw-flex tw-gap-4'>
            <div id='ConfigurationContainer'>
                <PerfectScrollbar
                    style={{ maxHeight: '100%' }}
                    className="scrollVisible content-ConfigurationGroupOption content pt-0 pb-0"
                    options={{ scrollXMarginOffset: 4, suppressScrollX: true }}
                >
                    <div className='ConfigurationGroupOption content'>
                        <header className='d-flex align-items-center mb-4'>
                            <h3 className='title ml-2'>{language.GROUPS}</h3>
                        </header>
                        <section>
                            {groups.map((item, index) =>
                                <Card
                                    key={`group_${index}`}
                                    onClick={() => handleItemSelect(item)}
                                    className={classNames('content-medium py-2 border-radius-10 card-item', { "active": item.id_device_contact_groups == groupSelected?.id_device_contact_groups })}
                                >
                                    <h5 className='title'>{language[item.key_lang]}</h5>
                                    <p>{language[item.key_lang]}</p>
                                </Card>
                            )}
                        </section>
                    </div>
                </PerfectScrollbar>
            </div>
            <div className='tw-w-full'>
                {groupSelected &&
                    <CommonTable
                        //title={''}
                        index=''
                        data={dataFiltered}
                        columns={columns}
                        language={language}
                        showHeaderToolbar
                        orderColumn
                        currentPage={1}
                        countTotal={[].length}
                        searchColumn={false}
                        paginationTop={false}
                        isAutomaticPagination={false}
                        pagination={false}
                        //searchColumnEvent={this.callPageSelectFilter}
                        onClearAllFilter={() => { debugger }}

                        callPageSelectFilter={
                            (page, values, rowsPerPage, type) => {
                                debugger
                            }
                        }
                        beforeBar={
                            <RaisedButton
                                style={{ zoom: 0.8 }}
                                zoom={0.8}
                                key="02"
                                color="primary"
                                className="mr-3"
                                icon="fas fa-plus"
                                circleButton
                                primary={true}
                                label={language.ADD_USER}
                                onClick={() => { setOpenDialogToAddUser(true) }}
                            />
                        }
                    />
                }
            </div>

            {openDialogToAddUser &&
                <DeviceContactSearch
                    isOpen={openDialogToAddUser}
                    onClose={() => { setOpenDialogToAddUser(false) }}
                    onConfirm={handleDialogClose}
                />
            }

            <SimplesAlert
                float
                open={alert.opened}
                message={alert.message}
                variant={alert.variant}
                onClose={() => handleCloseAlert()}
            />
        </div>
    )
}
