import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Paper, Grid } from '@material-ui/core';
import moment from 'moment';
import PieChart from '@common/PieChart';
import './Cio.css';
import { Card } from '@material-ui/core';
import Boll from '@common/Boll';
import Constants from '@data/Constants';
import Animate from '@common/Animate';
import Button from '@common/Button';
import io from 'socket.io-client';
import CommonPage from '@common/CommonPage';
import AppBar2 from '@common/AppBar2';
import { Tooltip } from 'react-tippy';
import List from '@common/List';
import Loading from '@common/Loading';
import PerfectScrollbar from 'react-perfect-scrollbar';

const tenant = Constants.Tenant;
export default class GiGraph extends CommonPage {
  constructor() {
    super();
    this.state = {
      c_id: null,
      c_open: false,
      refresh: null,
      c: 0,
      countRed: [],
      countYellow: [],
      countOk: [],
      countTotal: [],
      countRedList: [],
      countYellowList: [],
      countOkList: [],
      giDangerList: [],
      alerts: io.connect(Constants.ALERT_SERVERS, {
        path: '/server/alert',
        query:
          'user=' +
          this.store.getUserLoged().id +
          '&token=' +
          this.store.getToken(),
      }),
      hold: '',
    };
    this.getDevices = this.getDevices.bind(this);
  }

  componentWillMount() {
    let cont = this.state.c;
    const { devicesav } = this.props;
    this.state.alerts.on(`${tenant}_alerts`, (data) => {
      let vv = [];
      cont++;

      if (cont == 30 || cont == 1) {
        if (cont == 30) {
          this.setState({ c: 0 });
        }

        data.alertsall.countRed
          .filter((h) => h.id_company == this.props.companyId)
          .map((d) => {
            let cls =
              d.key == 'fs-list'
                ? 'flaticon-data-storage4 '
                : d.key == 'cpu-status'
                ? 'flaticon-cpu5 '
                : 'flaticon-random ';
            vv.push({
              name: (
                <span className={cls}>
                  {' ' +
                    this.getDevices(devicesav, d.id_device) +
                    ' ' +
                    parseInt(d.percent)}
                  %
                </span>
              ),
              value: true,
            });
          });
        data.alertsall.countYellow
          .filter((h) => h.id_company == this.props.companyId)
          .map((d) => {
            let cls =
              d.key == 'fs-list'
                ? 'flaticon-data-storage4 '
                : d.key == 'cpu-status'
                ? 'flaticon-cpu5 '
                : 'flaticon-random ';
            vv.push({
              name: (
                <span className={cls}>
                  {' ' +
                    this.getDevices(devicesav, d.id_device) +
                    ' ' +
                    parseInt(d.percent)}
                  %
                </span>
              ),
              value: false,
            });
          });

        this.setState({ giDangerList: vv });
      }

    });
  }

  getDevices(devices, id) {
    try {
      let y = devices.filter((b) => b.id_device == id);

      return y[0].name;
    } catch (e) {
      const { language } = this.props;
      return ` ${language.DEVICE} `;
    }
  }
  render() {
    const { language, companyId, devices, average_availability, devicesav } =
      this.props;

    const { giDangerList } = this.state;
    //devicesav

    let devicess = devices.filter(
      (x) => x.fk_id_company == this.props.companyId
    );

    return (
      <Card className="mt-2 border-radius-10">
        <div className="content-min">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card className="h-100 border-radius-10">
                <AppBar2
                  title={language.ALERT_MANAGEMENT}
                  contentProps={{ className: 'w-100' }}
                  titleProps={{
                    style: { fontSize: '0.70em', fontWeight: 600 },
                  }}
                />
                <div className="d-flex justify-content-center mb-3"></div>

                <div>
                  <div className="content-list-gd" type="danger">
                    <PerfectScrollbar
                      className="scrollVisible"
                      style={{
                        maxHeight: 250,
                      }}
                    >
                      <ul>
                        {giDangerList &&
                          giDangerList.map((e) => (
                            <li style={{ listStyleType: 'none' }}>
                              <div
                                style={{
                                  backgroundColor:
                                    e.value == true
                                      ? 'var(--danger)'
                                      : 'var(--warning)',
                                }}
                              ></div>
                              <span>{e.name}</span>
                            </li>
                          ))}
                      </ul>
                    </PerfectScrollbar>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={6} className="content-average-availability">
              <Card style={{ height: 377 }} className="border-radius-10">
                <AppBar2 title={language.ICS_WITH_LESS_AVAILABILITY_OF_CURRENT_MONTH} />
                <Grid
                  container
                  spacing={10}
                  className="content"
                  style={{
                    height: '240px',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  {devicess
                    .map((device) => {
                      return {
                        label: device.name,
                        percent: parseInt(device.percent),
                        up: parseInt(device.percent),
                      };
                    })
                    .sort(function (a, b) {
                      return a.percent < b.percent
                        ? -1
                        : a.percent > b.percent
                        ? 1
                        : 0;
                    })
                    .map((e, i) => (
                      <Grid item xs={3} spacing={10} key={i + '-' + e.label}>
                        <div className="content-graph">
                          <PieChart
                            percent={
                              /*parseFloat((e.percent||0).toFixed(1))*/ e.percent
                            }
                            color={
                              e.percent >= 60
                                ? 'success'
                                : e.percent <= 59 && e.percent >= 35
                                ? 'warning'
                                : e.percent <= 34
                                ? 'danger'
                                : undefined
                            }
                            strokeWidth={2.8}
                          />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <Tooltip
                            interactive
                            html={
                              <span style={{ fontSize: '12px' }}>
                                {e.label}
                              </span>
                            }
                            arrow={true}
                            position="left"
                            theme="light"
                          >
                            <span style={{ fontSize: '9px' }}>
                              {e.label.length > 8
                                ? e.label.substring(0, 8)
                                : e.label}
                            </span>
                          </Tooltip>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  }
}
