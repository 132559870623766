import React, { memo, useState } from 'react';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';
// -----------------------------------common-----------------------------------------------
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
// -----------------------------------material-ui------------------------------------------
import { Avatar, Checkbox, Grid } from '@material-ui/core';
// -----------------------------------components------------------------------------------

export default memo(({ open, onClose, options, onSubmit }) => {
  let [inDays, setInDays] = useState(false);
  let [generalParams, setGeneralParams] = useState([]);
  const { language } = Store;

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      miniVersion = {true}
      onClose={onClose}
      title={language.REGISTER_EVOLUTION}
      PaperProps={{
        id: 'EvolutionProject',
      }}
      contentProps={{
        style: {
          background: '#F5F5F5',
        },
      }}
    >
      <div>
        <CommonForm
          fields={[
            {
              type: 'check',
              name: 'hours_in_days',
              label: `${language.MEASURE_IN_DAIYS}?`,
            },
            {
              name: 'runtime',
              label: language.RUNTIME,
              type: 'text',
              required: true,
              className: 'detail_activity',
              format: inDays ? '' : '##:##',
              change(v) {
                return v.formattedValue;
              },
            },
            {
              name: 'local',
              label: language.TYPE_OF_ACTIVITY,
              type: 'select',
              className: 'detail_activity',
              options: options.local(),
              required: false,
            },
          ]}
          loading="runtime"
          onSubmit={onSubmit}
          onChangeField={(f, v) => {
            if (f.name == 'hours_in_days') {
              setInDays(v);
            }
          }}
        />
      </div>
    </Dialog>
  );
});
