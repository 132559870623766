import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import {

  SelectField,
  DatePicker
} from '@material-ui/core';
import { Paper, TextField, MenuItem } from '@material-ui/core';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonTable from '../../../../common/CommonTable';
import RaisedButton from '../../../../common/RaisedButton';
import Pickers from '@common/Pickers';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend
} from 'recharts';
import { FaPrint } from 'react-icons/lib/fa';
import ReactHighcharts from 'react-highcharts';

export default class ContractedHours extends Component {
  render() {
    let {
      language,
      handlePage,
      contracts,
      companies,
      devices,
      handleChange,
      value
    } = this.props;

    let col = [
      { key: 'tech', label: language.TECH },
      { key: 'company', label: language.COMPANY },
      { key: 'contract', label: language.CONTRACT },
      { key: 'device', label: language.DEVICE }
    ];

    let data = [
      {
        tech: 'Matheus oliveira',
        company: 'SensrIT',
        contract: 'SensrIT',
        device: 'device'
      }
    ];

    let colDetail = [
      { key: 'sla', label: language.SLA },
      { key: 'amount', label: language.AMOUNT },
      { key: 'hour', label: language.HOUR },
      { key: 'service_time', label: language.SERVICE_TIME }
    ];

    let dataDetail = [
      { sla: '4hs', amount: '40', hour: '4hs', service_time: '8hs' }
    ];

    const dataDeviceGraph = {
      title: {
        text: language.CONTRACT,
        style: {
          color: '#616161',

          fontFamily: 'sans-serif'
        }
      },
      subtitle: {
        text: 'RED HAT'
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr']
      },

      yAxis: {

        title: {
          text: 'Valores'
        },
      },

      series: [
        {
          name: '2017',
          data: [20, 80, 50, 10],
          color: 'var(--warning)'
        },
        {
          name: '2018',
          data: [40, 30, 20, 50],
          color: 'var(--primary)'

        }
      ]
    }

    let contractItem = contracts.map(c => (
      <MenuItem
        key={c.id_contract}
        value={c.id_contract}
        primaryText={c.name}
      >
        {c.name}
      </MenuItem>

    ));

    let companyItem = companies.map(co => (
      <MenuItem
        key={co.id_company}
        value={co.id_company}
        primaryText={co.name}
      >
        {co.name}
      </MenuItem>
    ));

    let devicesItem = devices.map(de => (
      <div className="deviceListReport pointer" key={de.id_device}>
        <div className="deviceIcon" style={{ heigth: 30, width: 30 }} />
        <h4>{de.name}</h4>
      </div>
    ));

    return (
      <PageLayout
        icon='imgGcf'
        title={language.REPORTS}
        subtitle={language.HOURS_CONTRACTED_VS_CONSUMED}
        style={{ zoom: 0.8, marginTop: '-1rem' }}
        back={()=>   handlePage('dash')}
        rightElements={[
          <TextField
            id="outlined-select-currency"
            select
            label={language.COMPANY}
            style={{ minWidth: 200, zoom: .85 }}
            value={value}
            onChange={handleChange}
            margin="dense"
            variant="outlined"
            className='mr-'
          >
            {companyItem}
          </TextField>,
          <TextField
            id="outlined-select-currency"
            select
            label={language.CONTRACTS}
            style={{ minWidth: 200, zoom: .85 }}
            value={value}
            onChange={handleChange}
            margin="dense"
            variant="outlined"
            className='mx-4'
          >
            {contractItem}
          </TextField>,
          <Pickers type='DatePicker' variant="outlined" margin='dense' className='mr-2' />,
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            color='primary'
            label={language.BACK}
            primary
            onClick={() => handlePage('dash')}
          />
        ]}
      >



        <Paper className='pt-3 -mt-3'>
          <Row>
            <Col md={6}>
              <Container>
                <ReactHighcharts config={dataDeviceGraph} />

              </Container>
            </Col>
            <Col md={6}>
              <h2 className="titleGraph">{language.DEVICE}</h2>
              <h2 className="subTitleGraph">{language.SELECT_DEVICE}</h2>
              {devicesItem}
            </Col>
          </Row>
        </Paper>

        <CommonTable
          title={language.TECHS}
          language={language}
          columns={col}
          data={data}
          hideFooterToolbar
        />

        <CommonTable
          title={language.SERVICE_TIME_DETAIL}
          language={language}
          columns={colDetail}
          data={dataDetail}
          hideFooterToolbar
        />



      </PageLayout>
    );
  }
}
