import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card, Chip } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';
import { filterObj, testRoute } from '@common/Func';

@observer
export default class DepartmentList extends Component {
  render() {
    let {
      language,
      handlePage,
      handleOpen,
      handleClose,
      redirect,
      departments,
      users,
      route,
      callPageSelectFilter,
      totaldepartment,
      currentPage,
    } = this.props;

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10 },
      },
      { key: 'name', label: language.NAME },
      {
        key: 'description',
        label: language.DESCRIPTION,
      },
      {
        key: 'cost_center',
        label: language.COST_CENTER,
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40 },
        jc: 'center',
      },
    ];

    let data = departments.map((obj) => {
      return {
        id: obj.id_department,
        name: obj.name,
        description: obj.description,
        cost_center: obj.cost_center,
        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <RaisedButton
                    circleButton
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    onClick={() => handlePage('edit', obj)}
                    className="mr-2"
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });
    let { configurationProps = {} } = this.props;

    return (
      <Fragment>
        <CommonTable
          title=""
          index="DepartmentList"
          data={data}
          columns={col}
          language={language}
          searchColumn
          orderColumn
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={
                route === 'department'
                  ? language.NEW_DEPARTMENT
                  : language.NEW_SUBDEPARTMENT
              }
              onClick={() => handlePage('new')}
            />
          }
        />
      </Fragment>
    );
  }
}
