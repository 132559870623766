import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import ButtonCommon from '@common/Button';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Popper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Header from '@common/Header';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Store from '@data/Store';
import Actions from '@data/Actions';

const action = new Actions();

import Constants from '../../../data/Constants';

import './styles.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  rootComplete: {
    color: '#019FC2',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#019FC2',
      color: '#019FC2',

      // height: 45,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#019FC2',
      color: '#019FC2',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#019FC2',
      color: '#019FC2',
    },
    '& .MuiAutocomplete-tag ': {
      maxWidth: 'calc(100% - 50px)',
    },
  },
  list: {
    width: 1000,
    color: '#019FC2',
    //  background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    //  '&:hover': {
    //    backgroundColor: '#039FC0',
    //  },
  },
  fullList: {
    width: 1000,
    color: '#019FC2',
    //  background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    //  '&:hover': {
    //    backgroundColor: '#039FC0',
    //  },
  },
  root: {
    color: '#999',
    //  background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    //  '&:hover': {
    //    backgroundColor: '#039FC0',
    //  },
  },
  appBar: {
    background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
    '&:hover': {
      backgroundColor: '#039FC0',
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
  },
  container: {
    padding: 50,
  },
  textSubTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#a2a2a2',
    marginBottom: 25,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 21,
  },
  textField: {
    border: '1px solid #019FC2',
  },
  rootAlert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: (props) => props.color || '#FFF',
    backgroundColor: (props) => props.bkg,
    '&:hover': {
      opacity: 0.9,
      backgroundColor: (props) => props.bkg,
    },
  },
}))(Button);

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 18,
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#039FC0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#039FC0',
    },
    '& .MuiOutlinedInput-root': {
      height: 41,

      '& fieldset': {
        borderColor: '#039FC0',
      },
      '&:hover fieldset': {
        borderColor: '#039FC0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#039FC0',
      },
    },
  },
})(TextField);

const CustomSelect = styled(Select)({
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 36,
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    background: '#FFF',
    border: '1px solid #019FC2',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#019FC2',
      background: '#FFF',
    },
  },
}))(InputBase);

export function NewTask({
  taskTicket,
  reload,
  openDefault = false,
  ticketDetails,
  idTicketInfo,
  language,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [open, setOpen] = React.useState(openDefault);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [respon, setRespon] = useState(
    ticketDetails && ticketDetails.predecessors
      ? ticketDetails.predecessors.responsible
      : []
  );
  const [envolvidos, setEnvolvidos] = useState([]);

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(
    ticketDetails && ticketDetails.predecessors
      ? ticketDetails.predecessors.tasks[0].description
      : null
  );
  const [descriptionValue, setDescriptionValue] = useState();
  const [dtStart, setDtStart] = useState(
    ticketDetails && ticketDetails.predecessors
      ? ticketDetails.predecessors.tasks[0].dt_start
      : new Date()
  );
  const [startTime, setStartTime] = useState(null);
  const [timeExecution, setTimeExecution] = useState(null);

  const ticketId = idTicketInfo ? idTicketInfo : ticketDetails;

  const [idTicket, setIdTicket] = useState(ticketId ? ticketId.idTicket : null);

  const [usersRespOptions, setUsersResOptions] = useState([]);
  const [usersCreatorOptions, setUsersCreatorOptions] = useState([]);
  const [usersInvolvedOptions, setUsersInvolvedOptions] = useState([]);
  const [predecessorsOptions, setPredecessorsOptions] = useState([]);
  const [userOptionsResponsible, setUserOptionsResponsible] = useState();

  const [involved, setInvolved] = useState(
    ticketDetails && ticketDetails.predecessors
      ? ticketDetails.predecessors.involved
      : []
  );

  const [tickets, setTickets] = useState([]);

  const [predecessors, setPredecessors] = useState(
    ticketDetails && ticketDetails.predecessors
      ? ticketDetails.predecessors.predecessors
      : []
  );

  const [message, setMessage] = useState(null);
  const [messageSeverity, setMessageSeverity] = useState('success');
  const [openMessage, setOpenMessage] = useState(false);

  let { users, getUserLoged } = Store;
  const [creators, setCreators] = useState();

  const [messageError, setMessageError] = useState(false);
  const [render, setRender] = useState(false);

  function loading() {
    action
      .execute('get', `${Constants.APIEndpoints.USER}/filter/allByRole`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }

        let userMap = [];

        res.map((userFind) => {
          userMap.push({
            value: `${userFind.name} - ${userFind.username}`,
            id: userFind.id_user,
          });
        });

        if (ticketDetails) {
          const findUser = res.find(
            (user) => user.id_user == ticketDetails.id_creator
          );
          setCreators(res[0]);

          setDtStart(ticketDetails.predecessors.tasks[0].dt_start);
          setStartTime(
            `2021-10-10 ${ticketDetails.predecessors.tasks[0].start_time}`
          );
          setTimeExecution(ticketDetails.predecessors.tasks[0].time_execution);

          setName(ticketDetails.title);

          let respOpt = [];

          res.map((user) => {
            if (
              ticketDetails.predecessors.responsible.indexOf(user.id_user) > -1
            ) {
              respOpt.push(user);
            }
          });
        } else {
          const findUser = res.find(
            (user) => user.id_user == getUserLoged().id
          );

          setCreators(findUser);
        }

        setUsersResOptions(res);
        setUsersCreatorOptions(res);
      })
      .catch((err) => console.log('erro', err));

    if (getUserLoged().role == 'client') {
      action
        .execute('get', `${Constants.APIEndpoints.TICKET}/findAll`, '')
        .then((res) => {
          if( res.data ) {
            res = res.data;
          }
          const ticketOptions = res.map((t) => ({
            value: `${t.real_id} - ${t.subject}`,
            id: t.id_tickets,
          }));
          setTickets(ticketOptions);
        });
    } else {
      action
        .execute('get', `${Constants.APIEndpoints.TICKET}/findAll/company`, '')
        .then((res) => {
          if( res.data ) {
            res = res.data;
          }
          setTickets(
            res.map((t) => ({
              value: `${t.real_id} - ${t.subject}`,
              id: t.id_tickets,
            }))
          );
        });
    }

    action
      .execute('get', `${Constants.APIEndpoints.TASKS}`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        setPredecessorsOptions(res);
      });

    action
      .execute('get', `${Constants.APIEndpoints.USER}/findByAssociated`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        let userMap = [];
        res.map((userFind) => {
          userMap.push({
            value: `${userFind.name} - ${userFind.username}`,
            id: userFind.id_user,
          });
        });
        setUserOptionsResponsible(res);
        setUsersInvolvedOptions(res);
      });
  }

  useEffect(() => {
    loading();

    setTimeout(() => {
      setRender(true);
    }, 800);
  }, [ticketDetails]);

  const usersOptions = [
    { id: 1, value: 'Thiago' },
    { id: 2, value: 'Thaís' },
    { id: 3, value: 'Fabrício' },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);

    setName('');
    setDescription('');
    setDtStart(new Date());
    setStartTime(null);
    setTimeExecution(null);
    setRespon([]);
    setInvolved([]);
    setPredecessors([]);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChange = (event, value) => {
    setRespon(value);
  };

  const handleChangeEnv = (event, value) => {
    setEnvolvidos(value);
  };

  const handleCreatorChange = (event, value) => {
    setCreators(value);
  };

  const handleInvolvedChange = (event, value) => {
    setInvolved(value);
  };

  const handlePredecessorChange = (event, value) => {
    setPredecessors(value);
  };

  const handleTicket = (event, value) => {
    setIdTicket(value);
  };

  function getNameUser(id) {
    return usersRespOptions.find((t) => t.id == id).value;
  }

  function getNameCreate(id) {
    return usersCreatorOptions.find((t) => t.id == id).value;
  }

  function getNameInvolved(id) {
    return usersInvolvedOptions.find((t) => t.id == id).value;
  }

  function getNamePredecessor(id) {
    return predecessorsOptions.find((t) => t.id == id).value;
  }

  function closeModal() {
    setOpen(false);
    reload(true);
  }

  const createTask = () => {
    if (
      !name ||
      !description ||
      !dtStart ||
      !involved ||
      !respon ||
      !startTime ||
      moment(startTime).format('HH') == '00' ||
      !timeExecution
    ) {
      setMessageError(true);
      return;
    }

    if (ticketDetails) {
      const id_tasks = ticketDetails && ticketDetails.tasksInfo.id_tasks;
      const tasks = {
        id_tasks,
        name: name,
        description: description,
        dt_start: moment(dtStart).format('MM-DD-YYYY'),
        start_time: moment(startTime).format('HH:mm'),
        time_execution: timeExecution,
        fk_id_tickets: idTicket,
        resp: respon,
        creator: creators,
        involved: involved,
        predecessors,
      };

      action
        .execute('put', `${Constants.APIEndpoints.TASKS}/update/${id_tasks}`, '', tasks)
        .then((res) => {
          setMessage(language.TASK_CREATED_SUCCESSFULLY);
          setOpenMessage(true);
          setMessageSeverity('success');
          setOpen(false);
          setMessageError(false);
          setTimeout(() => {
            setOpenMessage(false);
          }, 6000);
          loading();
          reload(true);
        });
    } else {
      const tasks = {
        name: name,
        description: description,
        dt_start: moment(dtStart).format('MM-DD-YYYY'),
        start_time: moment(startTime).format('HH:mm'),
        time_execution: timeExecution,
        fk_id_tickets: idTicket,
        resp: respon,
        creator: creators,
        involved: involved,
        predecessors,
      };
      
      action
        .execute('post', `${Constants.APIEndpoints.TASKS}`, '', tasks)
        .then((e) => {
          setMessage(language.TASK_CREATED_SUCCESSFULLY);
          setOpenMessage(true);
          setMessageSeverity('success');
          setOpen(false);
          setMessageError(false);
          setTimeout(() => {
            setOpenMessage(false);
          }, 6000);
          loading();
          reload(true);
        });
    }
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <CssBaseline />
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ height: 100 }}
        sx={{ display: 'flex' }}
      >
        <span className={classes.title}>{language.NEW_TASK}</span>
        <CloseIcon onClick={() => closeModal()} />
      </AppBar>
      {messageError && (
        <Grid xs={12} className="msg_error">
          <span>{language.FILL_REQUIRED}</span>
        </Grid>
      )}

      {render && (
        <Container spacing={3} className={classes.container}>
          <Grid xs={12}>
            <Grid xs={12}>
              <Typography variant="h4" className={classes.textSubTitle}>
                {language.BASIC_INFO}
              </Typography>
            </Grid>

            <Grid xs={6}></Grid>
          </Grid>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <div className="item_form">
                <h2>{language.TASK_NAME} *</h2>
                <CustomTextField
                  required
                  fullWidth
                  variant="outlined"
                  id="filled-password-input"
                  label=""
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="item_form">
                <h3>{`${language.RESPONSIBLE_FOR_EXECUTION_THE_TASK}*`}</h3>
                <FormControl sx={12} fullWidth>
                  <CustomAutocomplete
                    defaultValue={respon}
                    className={classes.rootComplete}
                    limitTags={1}
                    onChange={handleChange}
                    multiple
                    disableClearable
                    options={userOptionsResponsible}
                    id="respOptions"
                    renderInput={(params) => (
                      <CustomTextField {...params} variant="outlined" />
                    )}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(option) => {
                      return (
                        <span>{`${option.name} - ${option.username}`}</span>
                      );
                    }}
                  />
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="item_form">
                <h2>{language.PREDECESSORS}</h2>
                <FormControl sx={12} fullWidth>
                  <CustomAutocomplete
                    className={classes.rootComplete}
                    onChange={handlePredecessorChange}
                    multiple
                    defaultValue={predecessors}
                    limitTags={1}
                    disableClearable
                    options={predecessorsOptions}
                    id="predecesrs"
                    renderInput={(params) => (
                      <CustomTextField {...params} variant="outlined" />
                    )}
                    getOptionLabel={(option) =>
                      `${option.id_tasks} - ${option.name}`
                    }
                    getOptionSelected={(option) => option.id_user === creators}
                    renderOption={(option) => {
                      return (
                        <span>{`${option.id_tasks} - ${option.name}`}</span>
                      );
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <div className="item_form">
              <h2>{`${language.DESCRIPTION}*`}</h2>
              <ReactQuill
                style={{ width: '97%' }}
                theme="snow"
                value={description || ''}
                onChange={setDescription}
              />
            </div>
          </Grid>

          <Grid style={{ marginTop: 20 }} container xs={12} spacing={3}>
            <Grid item xs={6}>
              <div className="item_form">
                <h3>{language.OBSERVER}</h3>
                <FormControl sx={12} fullWidth>
                  <CustomAutocomplete
                    className={classes.rootComplete}
                    onChange={handleInvolvedChange}
                    multiple
                    defaultValue={involved}
                    limitTags={1}
                    disableClearable
                    options={usersInvolvedOptions}
                    id="obs"
                    renderInput={(params) => (
                      <CustomTextField {...params} variant="outlined" />
                    )}
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.username}`
                    }
                    renderOption={(option) => {
                      return (
                        <span>{`${option.name} - ${option.username}`}</span>
                      );
                    }}
                  />
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="item_form">
                <h3>{language.ASSOCIATE_TO_TICKET}</h3>
                <FormControl sx={12} fullWidth>
                  <CustomAutocomplete
                    className={classes.rootComplete}
                    onChange={handleTicket}
                    limitTags={1}
                    disabled={idTicket ? true : false}
                    disableClearable
                    defaultValue={idTicket}
                    options={tickets}
                    id="auto_ticket"
                    renderInput={(params) => (
                      <CustomTextField {...params} variant="outlined" />
                    )}
                    getOptionLabel={(option) => `${option.value}`}
                    renderOption={(option) => {
                      return <span>{`${option.value}`}</span>;
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Grid container xs={12} spacing={3}>
            <Grid item xs={6}>
              <div className="item_form">
                <h3>{`${language.INITIAL_DATE}*`}</h3>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    clearable
                    value={dtStart}
                    variant="inline"
                    style={{ borderColor: '#039FC0' }}
                    inputVariant="outlined"
                    minDate={new Date()}
                    onChange={(date) => setDtStart(date)}
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: {
                        borderColor: '#039FC0',
                      },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="item_form">
                <h3>{`${language.INITIAL_HOUR}*`}</h3>
                <FormControl sx={12} fullWidth>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      clearable
                      value={startTime}
                      variant="inline"
                      style={{ borderColor: '#039FC0' }}
                      inputVariant="outlined"
                      onChange={(date) => setStartTime(date)}
                      minDate={new Date()}
                      format="HH:mm"
                      placeholder="HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </div>

              <div className="item_form">
                <h3>{`${language.RUNTIME}*`}</h3>
                <FormControl sx={12} fullWidth>
                  <CustomTextField
                    required
                    value={timeExecution}
                    fullWidth
                    variant="outlined"
                    id="time-execution-input"
                    label="00:00"
                    type="text"
                    onChange={(e) =>
                      setTimeExecution(
                        e.target.value
                          .replace(/\D/g, '')
                          .replace(/^(\d{2})(\d{2})+?$/, '$1:$2')
                      )
                    }
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className="m-10 p-3"
            sx={{ m: 12 }}
            xs={12}
            spacing={4}
          >
            <Grid item xs={6}>
              <ColorButton
                fullWidth
                bkg="#E6716D"
                className="m-10 p-3"
                sx={{ m: 12 }}
                variant="contained"
                onClick={() => closeModal()}
              >
                {language.CANCEL}
              </ColorButton>
            </Grid>
            <Grid item xs={6}>
              <ColorButton
                bkg="#58A9A0"
                fullWidth
                className="m-10 p-3"
                sx={{ m: 12 }}
                variant="contained"
                onClick={() => createTask()}
              >
                {language.SEND}
              </ColorButton>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );

  return (
    <div>
      <div id="alert_message" className={classes.rootAlert}>
        {openMessage && (
          <Alert
            style={{
              position: 'absolute',
              top: '100px',
              right: 0,
              width: '500px',
            }}
            variant="filled"
            severity={messageSeverity}
          >
            {message}
          </Alert>
        )}
      </div>

      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {taskTicket && !openDefault && (
            <Button
              onClick={handleDrawerOpen}
              style={{
                background: '#93D05B',
                padding: '0 30px',
                height: 31,
                color: '#FFF',
              }}
              startIcon={<PlaylistAddCheckIcon />}
            >
              {language.ADD_TASK}
            </Button>
          )}
          {!taskTicket && !openDefault && (
            <ButtonCommon
              icon="fas fa-plus"
              label={language.ADD}
              variant="circle"
              onClick={handleDrawerOpen}
              color="warning"
              className="mr-3"
            />
          )}

          <SwipeableDrawer
            anchor={anchor}
            open={open}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
