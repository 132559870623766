import React, {useState} from 'react';
import classnames from 'classnames';
/*-------------------------------common---------------------------------------*/
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Constants from '../../../data/Constants';
import Store from '@data/Store';
import List from '@common/List';

import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@common/Button'; 
/*-------------------------------material-ui---------------------------------------*/

const { language } = Store;

export default function({open, values={}, onClose, onSubmit, users, idObjective,companyId}){

	if (values.id_okr_results) {
		let responsibles = values.responsibles.map(x => x.id_user);
		values =  { ...values, responsibles}
	}
	
	const handleSubmit = (v) => {
		if (!values.id_okr_results) {
			v.fk_id_okr_objective = idObjective
		}

		onSubmit(v)
	}
	return(
		<Dialog
		  PaperProps={{
		    id: 'DialogFormOkrExpectedResult'
		  }}
		  open={open}
		  onClose={onClose}
			title={`${values.id_okr_results ? language.EDIT_EXPECTED_RESULT : language.ADD_EXPECTED_RESULT}`}
		>
				<CommonForm
					values={values}
					fields={[
						{
							col:12,
							type:'element',
							name:'priorization_result',
							required:true,
							content:(v, handleValue)=>(
								<div className='w-100 mb-3'>
									<span className='title-label d-block w-100' style={{ textAlign: 'center' }}>{language.PRIORIZATION}</span>
									<div className='content-star mt-1 d-flex align-items-center w-100 justify-content-center'>
										{[1,2,3,4,5].map(e=>(
											<i 
												className={classnames(e>(v||0)?'far fa-star':'fas fa-star', 'star-icon')} 
												onClick={()=>handleValue(e, 'priorization_result')}
											/>
										))}
									</div>
								</div>
							)
						},
						{
							col:4,
							type:'text',
							name:'key_result',
							required:true,
							label: language.KEY
						},
						{
							col:4,
							type:'date',
							name:'deadline',
							required:true,
							label: language.DEADLINE
						},
						{
							col:4,
							type:'select',
							name:'period_result',
							label: language.PERIOD,
							required:true,
							options:[
								{value:'1 mês', label: `1 ${language.MONTH}`},
								{ value: '2 mês', label:`2 ${language.MONTH}`},
								{ value: '3 mês', label:`3 ${language.MONTH}`},
								{ value: '4 mês', label:`4 ${language.MONTH}`},
								{ value: '5 mês', label:`5 ${language.MONTH}`},
								{ value: '6 mês', label:`6 ${language.MONTH}`},
								{ value: '7 mês', label:`7 ${language.MONTH}`},
								{ value: '8 mês', label:`8 ${language.MONTH}`},
								{ value: '9 mês', label:`9 ${language.MONTH}`},
								{ value: '10 mês', label:`10 ${language.MONTH}`},
								{ value: '11 mês', label:`11 ${language.MONTH}`},
								{ value: '12 mês', label:`12 ${language.MONTH}`},
							]
						},
						{
							col: 12,
							type: 'autocomplete',
							name: 'fk_id_user_approver',
							label: language.APPROVER,
							textlabel: language.APPROVER,
							className: 'tech-group-ticket',
							method: 'POST',
							route: `${Constants.APIEndpoints.USER}/filter/all?`,
							routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
							// filterAll: { fk_id_company: companyId},
							// filter: { fk_id_company: companyId },
							textinit: (values.name_user_approver) || '',
							idinit: values.fk_id_user_approver > 0? values.fk_id_user_approver : '',
							fieldquery: 'search',
							fieldvaluedb: 'id_user',
							fieldlabeldb: 'name',
							routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
							detail: true,
							methodGetAll: 'POST',
						},
						{
							col:12,
							type:'simplesTextEditor',
							name:'result_expected',
							label: language.EXPECTED_RESULT,
							required:true,
						},
						{
							col:12,
							type:'listCheckbox',
							name:'responsibles',
							label: language.RESPONSIBLES,
							required:true,
							options:users
						},
						
					]}
					onSubmit={handleSubmit}
					button={
						{ label: values.id_okr_results ? language.UPDATE : language.SAVE}
					}
				/>

		</Dialog>
	)
}