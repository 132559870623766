import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { AppBar, Slide } from '@material-ui/core';
import AppBar2 from '@common/AppBar2';
import CommonButton from '@common/Button';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import OverlayLoading from '@common/OverlayLoading';

const _theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});
const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: 'linear-gradient(to right, #41c0e0, #0097bf)'
    // background: 'linear-gradient(to right, #ea9b25, #ec6624)'
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  closeButton: {
    color: _theme.palette.grey[500],
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, fullScreen, theme } = props;

  var st = typeof children == 'string' ? { background: theme } : {};

  return (
    <MuiDialogTitle disableTypography className={classes.root} style={st}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.beforeTitle}

        {typeof children == 'string' ? (
          <Typography variant="h6" style={{ color: 'white' }}>
            {children}
          </Typography>
        ) : (
          <div>{children}</div>
        )}

        {props.afterTitle}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.beforeTitle2}

        {onClose && (
          <IconButton
            aria-label="Close"
            className={classes.closeButton + ' closeButton'}
            onClick={() => onClose({ clicked_x: true })}
          >
            <CloseIcon
              style={
                typeof children == 'string' || fullScreen
                  ? { color: 'white' }
                  : {}
              }
            />
          </IconButton>
        )}

        {props.afterTitle2}
      </div>
    </MuiDialogTitle>
  );
});

const DialogTitleComum = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    fullScreen,
    theme,
    title,
    contentTitle,
    titleContentProps,
    btnCloseProps = {},
  } = props;

  var st = typeof children == 'string' ? { background: theme } : {};

  return (
    <AppBar2
      title={title || ' '}
      dividerProps={{ className: 'mb-0 divider-1' }}
      beforeTitle={props.beforeTitle}
      afterTitle={props.afterTitle}
      contentProps={{
        style: {
          background: 'linear-gradient(89deg, #01C2B3 0%, #019FC2 100%)',
          borderRadius: '10px 10px 0 0',
        },
      }}
      contentTitle={contentTitle}
      titleContentProps={titleContentProps}
      titleProps={{
        style: { color: 'white', marginTop: 15 },
      }}
      subTitleProps={{
        style: { color: 'white' },
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.beforeTitle2}

        {onClose && (
          <CommonButton
            icon="fas fa-times"
            color="light"
            variant="circle"
            outlined
            className="closeBtn"
            onClick={() => onClose({ clicked_x: true })}
            onClick={onClose}
            {...btnCloseProps}
          />
        )}

        {props.afterTitle2}
      </div>
    </AppBar2>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingTop: 16,
    paddingBottom: 16,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
  },
}))(MuiDialogActions);

class RaisedDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
  }
  openModal = (open) => {
    if (!this.props.onClose) {
      this.setState({ open });
    }
    if (open) {
      this.props.onOpen ? this.props.onOpen() : null;
    } else {
      this.props.onClose ? this.props.onClose({}) : null;
    }
  };

  handleOpen = () => {
    this.props.keepOpen ? null : this.openModal(false);
  };
  componentWillMount() {
    this.setState({ open: this.props.open });
  }

  componentWillReceiveProps(nextProps) {
    let { open } = nextProps;

    this.setState({ open });
  }
  componentDidMount() {
    if (this.props.monitor_scroll) {
      var s = setInterval(() => {
        var p = document.querySelector('#DialogPaper');

        if (p || !this.props.open) {
          clearInterval(s);
        }

        if (typeof (p || {}).onclick != 'function' && this.props.open && p) {
          this.executeSc();
        }
      }, 500);
    }
  }
  componentDidUpdate(nextState, nextProps) {
    if (this.props.monitor_scroll) {
      setTimeout(() => {
        var p = document.querySelector('#DialogPaper');

        if (typeof (p || {}).onclick != 'function' && this.props.open && p) {
          this.executeSc();
        }
      }, 500);
    }
  }
  executeSc = () => {
    var p = document.querySelector('#DialogPaper');

    var pH = p.offsetHeight;
    var wH = window.innerHeight;
    var CH = Math.round((pH / wH) * 100);

    var currentH = document.querySelector('#DialogContent');
    var barH = document.querySelector('#DialogContent');

    document.querySelector('#DialogPaper').onclick = () => {
      if (
        Math.max(currentH.offsetHeight, barH.scrollHeight) ==
        currentH.offsetHeight
      ) {
        p.style.height = 'auto';
      } else {
        var n = 10;

        while (
          n < 90 &&
          Math.max(currentH.offsetHeight, barH.scrollHeight) ==
            barH.scrollHeight &&
          pH + 96 != wH
        ) {
          p.style.height = n + CH + '%';
          n += 10;
        }
      }
    };
  };
  render() {
    let {
      maxWidth,
      title,
      footer,
      open,
      contentProps = {},
      fullScreen,
      PaperProps = {},
      theme = 'blue',
      bar,
      dialogChildren,
      contentTitle,
      titleContentProps,
      btnCloseProps,
      loading,
      miniVersion
    } = this.props;
    let bTitle = {
      beforeTitle: (PaperProps || {}).beforeTitle,
      afterTitle: (PaperProps || {}).afterTitle,
      beforeTitle2: (PaperProps || {}).beforeTitle2,
      afterTitle2: (PaperProps || {}).afterTitle2,
    };

    const color = {
      // blue:'linear-gradient(to right, #0097bf, #41c0e0)',
      blue: 'var(--primary)',
      yellow: 'linear-gradient(to right, #efa824, #ed9325)',
    };

    return (
      <>
        <div className="dialog-23-sensr">
          <Dialog
            onClose={(_, reason) => {
              if (reason !== "backdropClick"){
                this.handleOpen
              }
            }}
            open={this.state.open}
            backdrop

            maxWidth={miniVersion ? maxWidth : 'xl'}
            fullWidth={true}
            {...(fullScreen ? { TransitionComponent: Transition } : {})}
            fullScreen={fullScreen}
            PaperProps={{
              id: 'DialogPaper',
              ...PaperProps,
              style: {
                borderRadius: 10,
                ...PaperProps.style,
              },
            }}
          >
            {bar != false && (
              <Fragment>
                {fullScreen ? (
                  <MuiThemeProvider theme={_theme}>
                    <AppBar
                      style={{ background: color[theme], position: 'relative' }}
                    >
                      <DialogTitle
                        {...bTitle}
                        theme={color[theme]}
                        fullScreen
                        onClose={() => this.openModal(false)}
                      >
                        {title}
                      </DialogTitle>
                    </AppBar>
                  </MuiThemeProvider>
                ) : (
                  <DialogTitleComum
                    title={title}
                    btnCloseProps={btnCloseProps}
                    titleContentProps={titleContentProps}
                    contentTitle={contentTitle}
                    {...bTitle}
                    theme={color[theme]}
                    onClose={() => this.openModal(false)}
                  />
                )}
              </Fragment>
            )}
            {dialogChildren}

            <DialogContent
              dividers={true}
              id="DialogContent"
              {...contentProps}
              style={{ background: '#f9fbfc', 
              minHeight : miniVersion ? '' : '80vh',
              ...(contentProps.style || {}) }}
            >
              {!!loading && <OverlayLoading />}
              {this.props.children}
            </DialogContent>
            <DialogActions>{footer}</DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

export default RaisedDialog;
