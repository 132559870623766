const ContainerChart = {
  
  setorInfo(data){
    return {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height:220,
          backgroundColor:'transparent'

      },
      title: null,
      credits:{enabled:false},
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.percentage:.1f} %',
                  distance: -30,
                  style:{
                    fontSize:8
                  }
              },
              borderWidth:3,
              showInLegend: true
          }
      },
      legend:{enabled:false},
      series: [{
          name: 'Brands',
          colorByPoint: true,
          data:  
             data
           
      }]
    }
  },
  container0(data, qtdTotal){
    var dataKeys = Object.keys(data);

    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: 'transparent',
        style: {
          fontFamily: "'Unica One', sans-serif"
        },
        plotBorderColor: '#606063',
        
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      xAxis: {
        crosshair: false,

        gridLineColor: 'rgba(250,250,250,.5)',
        labels: {
          style: {
            color: 'white'
          }
        },
        lineColor: 'rgba(250,250,250,.5)',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
          style: {
            color: '#A0A0A3'
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          style: {
            color: 'white',
            fontFamily: 'sans-serif'
          }
        },

        gridLineColor: 'rgba(250,250,250,.5)',
        labels: {
          style: {
            color: 'white'
          }
        },
        lineColor: 'rgba(250,250,250,.5)',
        minorGridLineColor: '#505053',
        tickColor: 'rgba(250,250,250,.5)',
        tickWidth: 1
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            connectorColor: 'silver'
          },
          showInLegend: true
        },

        series: {
          borderRadius: 8,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y:.1f}%',
            borderWidth: 0,
            // color: '#F0F0F3',
            style: {
              fontSize: 11,
              textShadow: false,
              textOutline: 0
            }
          },
           
           
          marker: {
            lineColor: '#333'
          }
        },
      },

      /*series: [
        {
          name: 'Valor',
          data: []
        }
      ]*/
        series: [{
            name: 'Percentual',
            data:dataKeys.map((e,i)=>({
                name: e,
                y: (data[e]/qtdTotal)*100
            }))
        }]
        /*series: dataKeys.map((e,i)=>({
            name: e,
            data: {
                y: (data[e]/qtdTotal)*100
            }
        }))*/
    };

    
  }
  
}
export default ContainerChart;