import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import SearchInput from '@common/SearchInput';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import styled from 'styled-components';

import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import IconButton from '@material-ui/core/IconButton';

import DialogConfirm from '@common/DialogConfirm';

import './style.css';

export const useStyles = (theme) => ({
  listItem: {
    padding: 0,
    width: 218,
    backgroundColor: 'blue',
  },
  iconButton: {
    backgroundColor: '#FFF',
    marginLeft: '-13px',

    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  itemIcon: {
    width: 29,
  },
  itemText: {
    textTransform: 'uppercase',
    color: '#FFF',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  subitemText: {
    textTransform: 'uppercase',
    color: 'var(--colorIconButton)',
    fontSize: '10px',
    // paddingTop: 10,
  },
});

export const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    height: '40px !important',

    '&$expanded': {
      // minHeight: 56,
      padding: '15px 0  0 12px',
      paddingTop: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles({
  root: {
    // padding: theme.spacing(2),
    minHeight: 48,
    width: '38vh',
    minWidth: '331px',
    backgroundColor: 'white',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#F09726 !important',
      color: 'white',
      '& .MuiTypography-body1': {
        color: 'white',
      },
      '& .MuiIconButton-sizeSmall': {
        color: 'white',
      },
    },
    '& .MuiTypography-body1': {
      fontSize: 12,
      marginTop: 10,
      color: '#A3A3A3',
      fontWeight: 400,
    },
    '& .MuiIconButton-sizeSmall': {
      color: '#F09726',
      position: 'absolute',
      right: 10,
    },
  },
})(MuiExpansionPanelDetails);

export const ImgBox = styled.div`
  background-color: #fff;
  width: 26px;
  height: 26px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconImg = styled.img`
  width: 100%;
`;

export const BoxList = styled.div`
  width: 168px;
  display: flex;
  align-items: center;
`;

export const TextList = styled.p`
  color: #a3a3a3;
  font-size: 12px;
  white-space: normal;
`;

const Sidebar = (props) => {
  const {
    categories,
    setSelectedSubcategory,
    setSelectedCategory,
    selectedCategory,
    filterCatalog,
    handleChange,
    expanded,
    handleFavorite,
    hasMoreCategories,
    totalCategories,
    setOffsetCategories,
    offsetCategories,
    setCategories,
    setHasMoreCategories,
    subFavorites,
    setSelectedIdCategory,
    setResetCatalog,
    language
  } = props;
  const [page, setPage] = useState(0);
  const [confirmation, setConfirmation] = useState({
    open: false,
    catalog: '',
    category: '',
  });
  const [header, setHeader] = useState('divTopSidebarPortal');
  const listenScrollEvent = (event) => {
    if (window.scrollY < 10) {
      return setHeader('divTopSidebarPortal');
    } else if (window.scrollY > 10) {
      return setHeader('divTopSidebarPortalScroll');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  const getMoreDataCategories = () => {
    if (categories.length === totalCategories) {
      setHasMoreCategories(false);
      return;
    } else {
      action
        .execute(
          'post',
          Constants.APIEndpoints.CATALOG +
            `/company/1/?limit=75&offset=${offsetCategories}`
        )
        .then((res) => {
          setOffsetCategories(offsetCategories + 75);
          let newCat = [...res.data];
          let cat = [...categories, ...newCat];
          setCategories(cat);
        });
    }
  };

  const handleConfirmation = ({ open, catalog, category }) => {
    setConfirmation({ open, catalog, category });
  };

  return (
    <div className="divSidebarPortal">
      <div className={header}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
            marginLeft: '12%',
            width: '75%',
          }}
        >
          <SearchInput
            placeholder={language.SEARCH_BY_CATEGORY}
            onChange={(evt) => {
              filterCatalog(evt.target.value);
            }}
          />
        </div>
        <div className="buttonsSidebar">
          <div
            className="buttonSidebar"
            onClick={() => setPage(0)}
            style={{ backgroundColor: page == 0 ? '#F09726' : '' }}
          >
            <Icon className="iconButtonSidebar">local_offer_icon</Icon>

            <label>{language.CATEGORIES}</label>
          </div>
          {/*
        <div
          className="buttonSidebar"
          onClick={() => setPage(1)}
          style={{ backgroundColor: page == 1 ? '#F09726' : '' }}
        >
          <Icon className="iconButtonSidebar">format_list_bulleted_icon</Icon>
          <label>Processos</label>
        </div>*/}
          <div
            className="buttonSidebar"
            onClick={() => setPage(2)}
            style={{ backgroundColor: page == 2 ? '#F09726' : '' }}
          >
            <Icon className="iconButtonSidebar">star_rate_icon</Icon>
            <label> {language.FAVORITES} </label>
          </div>
          {/*
        <div
          className="buttonSidebar"
          onClick={() => setPage(3)}
          style={{ backgroundColor: page == 3 ? '#F09726' : '' }}
        >
          <Icon className="iconButtonSidebar">insert_drive_file_icon</Icon>
          <label>Formulários</label>
        </div>
      */}
        </div>
      </div>
      {page == 0 ? (
        <div
          style={{
            backgrounColor: 'white',
            marginTop: header == 'divTopSidebarPortal' ? 125 : 0,
          }}
        >
          {categories.map((category) => (
            <div style={{ backgroundColor: 'white' }}>
              <ListItem
                style={{
                  backgroundColor:
                    category.id_category == selectedCategory.id_category
                      ? 'rgb(240, 151, 38)'
                      : 'white',
                  padding: 0,
                }}
              >
                <ExpansionPanel
                  square
                  expanded={expanded === category.id_category}
                  onChange={handleChange(category.id_category)}
                  elevation={0}
                >
                  <ExpansionPanelSummary
                    className=""
                    aria-controls={category.id_category + 'd-content'}
                    id={category.id_category + 'd-header'}
                    onClick={() => setSelectedCategory(category)}

                  >
                    <BoxList className="box-list">
                      <TextList
                        style={{
                          color:
                            category.id_category == selectedCategory.id_category
                              ? 'white'
                              : '#A3A3A3',
                        }}
                      >
                        {category.name}
                      </TextList>
                    </BoxList>
                  </ExpansionPanelSummary>
                  {category.catalog &&
                    category.catalog.map((cat) => (
                      <div className="divExpanseFavoriteNoMultiCatalog">
                      <ExpansionPanelDetails
                          onClick={() => {
                            setSelectedSubcategory(cat)
                            setResetCatalog(true)
                          }}
                          className="divFavorite"

                        >
                          <Typography>{cat.name}</Typography>
                        </ExpansionPanelDetails>
                        <IconButton
                          size="small"
                          style={{
                            color: 'orange',
                            outlineColor: 'transparent',
                            backgroundColor: 'transparent',
                          }}
                    
                          aria-label="Menu"
                          onClick={() =>
                            handleFavorite(
                              'subCategory',
                              cat.id_catalog_service
                            )
                          }
                        >
                          {cat.isFavorite ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                        <Divider className="divider" />
                      </div>
                    ))}
                </ExpansionPanel>
              </ListItem>
              <Divider className="divider" />
            </div>
          ))}
        </div>
      ) : page == 2 ? (
        <div
          style={{
            backgroundColor: 'white',
            marginTop: header == 'divTopSidebarPortal' ? 125 : 0,
          }}
        >
          <div>
            <ListItem
              style={{
                backgroundColor: 'white',
                padding: 0,
              }}
            >
              <ExpansionPanel square expanded={true} elevation={0}>
                <ExpansionPanelSummary className="">
                  <BoxList className="box-list">
                    <TextList
                      style={{
                        color: '#A3A3A3',
                      }}
                    >
                        {language.YOUR_FAVORITES}
                    </TextList>
                  </BoxList>
                </ExpansionPanelSummary>
                {subFavorites.map((cat) => (
                  <div style={{ marginTop: 1 }}>
                    <div className="divExpanseFavorite">
                      <ExpansionPanelDetails
                        onClick={() => {
                          setSelectedSubcategory(cat)
                          setResetCatalog(true)
                        }}
                        className="divFavorite"
                      >
                        <Typography>{cat.name}</Typography>
                      </ExpansionPanelDetails>

                      <IconButton
                        size="small"
                        aria-label="Menu"
                        style={{
                          color: 'orange',
                          outlineColor: 'transparent',
                          backgroundColor: 'transparent',
                        }}
                        onClick={() =>
                          handleConfirmation({
                            open: true,
                            catalog: cat.id_catalog_service,
                            category: cat.fk_id_category,
                          })
                        }
                      >
                        <StarIcon />
                      </IconButton>
                    </div>

                    <Divider className="divider" />
                  </div>
                ))}
              </ExpansionPanel>
            </ListItem>
          </div>
        </div>
      ) : null}
      {hasMoreCategories && (
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => getMoreDataCategories()}
        >
          {language.LOAD_MORE}
        </div>
      )}
      {confirmation.open && (
        <DialogConfirm
          open={confirmation.open}
          confirmProps={{ color: 'danger' }}
          onConfirm={() => {
            handleFavorite(
              'removeFavorite',
              confirmation.catalog,
              '',
              confirmation.category
            );
            handleConfirmation({ open: false, catalog: '', category: '' });
          }}
          onClose={() =>
            handleConfirmation({ open: false, catalog: '', category: '' })
          }
          title={language.DO_YOU_REALLY_WANT_TO_DELETE_THIS_FAVORITE}
          message={''}
        />
      )}
    </div>
  );
};

export default Sidebar;
