import Store from '@data/Store';

const { language } = Store;

export function Company({ Highcharts, data, colors, openModal }) {
	return {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			height: 300
		},
		colors: colors.map(function (color) {
			return {
				radialGradient: {
					cx: 0.5,
					cy: 0.3,
					r: 0.7
				},
				stops: [
					[0, color],
					[1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
				]
			};
		}),
		title: null,
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					format: '<b>{point.name}</b>: {point.percentage:.1f} %',
					connectorColor: 'silver',
					style: {
						fontSize: '10px',
						color: '#A3A3A3'
					}
				}
			},
			series: {
				point: {
					events: {
						click:
							(e) => {
								openModal('company', e.point.name)
							}
					}
				}
			}
		},
		credits: { enabled: false },
		series: [{
			name: 'Share',
			data
		}]
	}
}
export function Call_x_Tech({ categories, data, currentMonth, OpenModal }) {

	return {
		chart: {
			type: 'line',
			height: 200
		},
		title: null,

		subtitle: null,

		yAxis: {
			title: {
				text: language.HOURS,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},

		xAxis: {
			categories,
			title: {
				text: `${language.PERIOD} (${currentMonth})`,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
		},

		legend: {
			enabled: false
		},

		plotOptions: {
			series: {
				label: {
					connectorAllowed: false
				}
			},
			series: {
				point: {
					events: {
						click:
							(e) => {
								OpenModal(e.point.category,
									e.point.series.name)
							}
					}
				}
			}
		},
		credits: { enabled: false },
		series: data,
	}
}
export function TelemetryTicket({ data, months }) {
	return {
		chart: {
			type: 'column',
			height: 300
		},
		title: null,
		xAxis: {
			categories: months,
			labels: {
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				}
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: language.VALUES,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			stackLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					color: 'var(--primary)',
				}
			},
			labels: {
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				}
			}
		},

		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			pointFormat: '{series.name}: {point.y} <br/>Total: {point.stackTotal} '
		},
		credits: { enabled: false },
		plotOptions: {
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: true,
					format: '{point.y:,.0f}',
					style: {
						textOutline: 0,
						color: 'white',
						fontSize: 10
					},
				}
			},
			series: {
				borderRadius: 3,

				point: {
					events: {
						click: (e) => {

						}
					}
				},

				marker: {
					lineColor: '#333'
				}
			},
		},
		legend: {
			itemStyle: {
				fontSize: 8,
				color: 'var(--primary)'
			}
		},
		series: data
	}
}
