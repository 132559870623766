import React from 'react';
import './index.css';
import { observer } from 'mobx-react';
import Options from './options';
import moment from 'moment';
// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import Header from '@common/Header';
import Button from '@common/Button';
import SearchInput from '@common/SearchInput';
import { toBase64, addTimes, hourToMinutes, minutesToSumHour } from '@common/Func';
import request from '@common/request';
// --------------------------------material-ui----------------------------------------------------
import { Card } from '@material-ui/core';
// --------------------------------pages----------------------------------------------------
import Form from './Form';
import ListItem from './ListItem';
import { projectListFormat, activitiesList } from './jsonFormat';
import DialogForm from '../projectmanagement/Sprint/DialogForm';
import DownloadFiles from '../../util/DownloadFiles';

// --------------------------------icons----------------------------------------------------
let keys = [
   'ta',
   'tm',
   'te',
   'at',
   'pa',
];

@observer
export default class extends CommonPage {
   constructor() {
      super();
      this.state = {
         search: '',
         formOpen: false,
         formValue: undefined,
         dt_i: null,
         dt_f: null,
         solicitant: null,
         percentual: null,
         manager: null,
         company: null,
         projectsConcludeds: false,
         showProjects: 1,
         modal: 0,
         editItens: [],
         project: [],
         sprintpjs: []
      };

   }
   onAdd = (files, index, id) => {

      toBase64(files || []).then(e => {

         let upload = e.map(e => ({
            fk_id_project: id,
            file: e.base64,
            name: e.file.name,
            size: e.file.size,
            type: e.file.type,
            key: keys[index],
         }));

         this.action.execute(
            'put',
            `${this.APIRoot}/api/project/uploaddoc`,
            'projects',
            upload,
            'id_project',
            { btn_loading: 'post-file-project-' + id + '-' + index }
         )
      })
      /*----função responsável por adicionar arquivos na ultima coluna de cada projeto---
      o parâmetro index significa a posição, se é termo de aertura, termo de mudança e etc, o id é o identificador do projeto,
      com esses dois parâmetros eu sei em qual termo quero adicionar um arquivo e em qual projeto.
      o mesmo serve para função abaixo
      */
   }

   handleModal = (v = 0, c) => {

      if (v == 0) {
         this.setState({ modal: 0, editItens: c });
      } else {
         this.action.execute(
            'post',
            this.APIEndpoints.PROJECT + '/sprintpjs/template',
            'sprintpjs',
            { id_project: v.id_project }

         ).then(res => {
            this.setState({ sprintpjs: res.data });
         });
         this.setState({ modal: 1, editItens: c, project: v });

      }
   };

   handleDelete = (v) => {
      this.action.execute(
         'post',
         this.APIEndpoints.PROJECT + '/sprintpjs/template/remove',
         'sprintpjs',
         { id_project_sprint_template: v.id_project_sprint_template, id_project: v.fk_id_project_template }

      ).then(res => {
         window.location.reload()
      });

   }

   onDownload = (file) => {
      DownloadFiles(this.APIEndpoints.REQUESTS + '/downloadgpdoc/' + file.id_project + '&' + file.label, file.label)
   }
   componentWillMount() {
      this.store.projects = [];
      /* request.get('https://localhost:300/api/projects', 'projects');
      console.log(this.APIEndpoints.DEPARTMENT);
      request.get(this.APIEndpoints.DEPARTMENT, 'departments');
      request.get(this.APIEndpoints.USER, 'departments');
      request.get(this.APIEndpoints.COMPANY, 'departments');
      request.get(this.APIEndpoints.CONTRACT, 'departments');
      request.get(this.APIEndpoints.PROJECT + '/prioritization', 'departments'); */

      this.action.execute('get', this.APIRoot + '/api/projects?template=true', 'projects');
      this.action.execute('get', this.APIEndpoints.COMPANY, 'companies')
      this.action.execute('post', this.APIEndpoints.CONTRACT + '/list', 'contracts');
      this.action.execute('get', this.APIEndpoints.PROJECT + '/prioritization', 'prioritization');

      this.action.execute(
         'get',
         this.APIEndpoints.PROJECT + '/sprintpjs',
         'sprintpjs'
      );
   }

   onRemoveFile = (file, index, id) => {

      this.action.execute('put', `${this.APIRoot}/project/delete/file/${file.id_project}/0/${file.id_project_file}`, 'projects', null, 'id_project');
   }
   removeProject = ({ id_project, user_cad }) => {

      let user = this.store.getUserLoged();
      if ((parseInt(user.id) == parseInt(user_cad)) || user.role === 'client') {
         this.action.execute('delete', this.APIEndpoints.PROJECT + '/deleteProject/' + id_project + '?template=true', 'projects', null, 'id_project', { btn_loading: 'project_' + id_project });
      } else {
         this.store.toggleAlert(true, 'Apenas o Gestor do projeto pode executar essa ação', 'error')
      }
   }

   handleValue = (formValue) => {
      this.setState({ formValue })
   }
   onEdit = (id) => {
      let pj = this.store.projects.find(e => e.id_project == id) || {}

      this.setState({
         formValue: pj,
         formOpen: true
      });
   }

   onClose = (id) => {

      this.action.execute('put', `${this.APIEndpoints.PROJECT}/update/${id}`, 'projects', { closed: true }, 'id_project', { btn_loading: 'project_close_' + id });
   }

   render() {
      let { language, projects, companies, users, project_stages, contracts, departments, tickets, pmo } = this.store;
      let { search, formOpen, formValue, showProjects, modal } = this.state;
      let { PROJECTS } = this.store.theme;
      let { dt_i, dt_f, solicitant, percentual, manager, company } = this.state;
      let options = Options({
         companies,
         users,
         language,
         project_stages,
         contracts,
         tickets,
         departments
      });
      let companyOptions = options.company();
      //----lorem----

      let data = projects.filter(e => {
         let errors = [];
         if (dt_i) {
            errors.push(moment(e.start_date).isBetween(dt_i || Date.now() / 1000, dt_f || Date.now() * 1000, undefined, '[]'))
         }
         if (dt_f) {
            errors.push(moment(e.end_date).isBetween(dt_i || Date.now() / 1000, dt_f || Date.now() * 1000, undefined, '[]'))
         }

         if (percentual) {
            errors.push(parseFloat(e.percent) >= percentual)
         }

         if (manager) {
            errors.push(e.user_cad == manager);
         }

         if (company) {
            errors.push(e.fk_id_company == company);
         }

         if (showProjects == 2) {
            let concluded = e.utils.concluded || e.utils.concludedDelay
            errors.push(concluded)
         }
         if (showProjects == 1) {
            let concluded = (!e.utils.concluded && !e.utils.concludedDelay)
            errors.push(concluded)
         }

         return errors.every(Boolean);
      }).map(e => {

         let act = [...e.utils.DONE, ...e.utils.BACKLOG, ...e.utils.DOING, ...e.utils.STOPPED, ...e.utils.VALIDATION, ...e.utils.TODO]

         let actDetail = act.reduce((obj, a) => {
            let hours_consumed = (e.hours_consumed || []).filter((e) => e.fk_id_activity == a.id_project_activity);
            // ----------------checa se a atividade se enquadra na categoria de horas planejadas----------
            if (a.hours || a.minutes) {
               obj.planned_hours.push(activitiesList({ a, users, pj: e }));
            }

            // ----------------checa se a atividade se enquadra na categoria de horas realizadas----------
            if (hours_consumed.length) {
               //obj.realized_hour.push({hours_consumed, ...a});
               obj.realized_hour.push(activitiesList({ a, users, pj: e }));
            }


            // ----------------checa se a atividade se enquadra na categoria de horas excedidas----------
            if (hours_consumed.length) {

               let hours_consumed_total = hours_consumed.reduce((h, e) => addTimes(h, e.hours_consumed), '00:00:00');

               let total_hour_realized = hourToMinutes(hours_consumed_total);
               let total_hour_foreseen = hourToMinutes(a.hours + ':' + a.minutes + ':' + '00');
               let exceeded_hours = total_hour_realized - total_hour_foreseen

               if (exceeded_hours > 0) {
                  //obj.exceeded_hours.push({exceeded_hours : minutesToSumHour(exceeded_hours), ...a});
                  obj.exceeded_hours.push(activitiesList({ a, users, pj: e }));
               }
            }

            return obj;
         }, {
            planned_hours: [],
            realized_hour: [],
            exceeded_hours: []
         })

         let exceeded_hours = act.map(e => moment(e.dt_end).diff(e.dt_up, 'minutes'));
         exceeded_hours = exceeded_hours.reduce((n, e) => n + e, 0);

         return projectListFormat({ options, companyOptions, users, e, actDetail });
      }).filter(e =>
         JSON.stringify({
            id: e.id,
            company: e.company,
            name: e.name,
            type: e.type,
            ListAvatars: e.ListAvatars,
            Scope: e.Scope,
            EvolutionKPI: e.EvolutionKPI,
            ForeseenVsRealized: e.ForeseenVsRealized,
            EvolutionProject: e.EvolutionProject
         }).toLowerCase().indexOf(search) != -1
      );
      //console.log(contracts,"testetetertertre")

      return (
         <div className='pt-3'>
            <Form
               project_stages={project_stages}
               open={formOpen}
               values={formValue}
               options={options}
               handleValue={this.handleValue}
               execute={this.action.execute}
               APIEndpoints={this.APIEndpoints}
               onClose={() => {
                  this.setState({
                     formOpen: false,
                     formValue: undefined
                  });
               }}
            />
            <PageLayout
               title={language.PROJECTS?.toLocaleUpperCase()}
               subTitle={(language.PROJECT_MANAGEMENT + ' /')?.toLocaleUpperCase()}
               rightElements={[]}
               back={false}
            >
               <DialogForm
                  users={users}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                  companies={companies}
                  sprintpjs={[]}
                  redirect={this.redirect2}
                  editItens={this.state.editItens}
                  onOpenModal={(v) => this.handleModal(v)}
                  open={modal == 1}
                  onClose={() => this.handleModal()}
                  template={true}
                  project={this.state.project}
               />
               <div id="ProjectsListTemplate">
                  <Header style={{ zIndex: 100, position: 'sticky', top: -5 }}>
                     <Button
                        icon='fas fa-plus'
                        color='warning'
                        variant='circle'
                        label={language.ADD}
                        className='mr-3'
                        onClick={() => {
                           this.setState({ formOpen: true })
                        }}
                     />
                     <SearchInput onChange={evt => {
                        this.setState({ search: evt.target.value })
                     }} />
                     <Button
                        icon='fas fa-print'
                        color='warning'
                        label={language.PRINT}
                        className='mx-3'
                     />
                  </Header>
                  <Card className='content' id='content-project-list' style={{ '--done': PROJECTS.done }}>
                     <ul>
                        {data.map((e, i) => (
                           <ListItem
                              user={this.store.getUserLoged()}
                              onEdit={this.onEdit}
                              handleModal={v => this.handleModal(v)}
                              handleEdit={(v, s) => this.handleModal(v, s)}
                              handleDelete={(v) => this.handleDelete(v)}
                              redirect={this.redirect2}
                              data={e}
                              onAdd={this.onAdd}
                              onRemoveFile={this.onRemoveFile}
                              key={e.id}
                              onDownload={this.onDownload}
                              removeProject={this.removeProject}
                              onClose={this.onClose}
                              action={this.action}
                           />
                        ))}
                     </ul>
                  </Card>
               </div>
            </PageLayout>
         </div>


      );
   }
}
