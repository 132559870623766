import React from 'react';
import CommonPage from '@common/CommonPage';
import AttendanceGADPage from './AttendanceGADPage';

export default class GSDContainer extends CommonPage {
  constructor() {
    super();
  }

  render() {
    let { language } = this.store;    

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        showMenuIconButton={false}
        loading={false}
      >      
        <AttendanceGADPage           
          language={language}          
          {...this.state}
        />        
      </CommonPage>
    );
  }
}
