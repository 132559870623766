// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{box-sizing:border-box!important}#SubscribeContracts{width:100%}#SubscribeContracts.dev{padding:40px 20px}#SubscribeContracts .content{padding:20px;width:100%}#SubscribeContracts small{color:#616161;display:block;margin-bottom:10px}#SubscribeContracts .fieldset,#SubscribeContracts .fieldsetP{border:none;border:1px solid hsla(0,0%,39%,.5);border-radius:3px}#SubscribeContracts .fieldsetP{border:1px solid #009dc0}#SubscribeContracts .fieldCustom{margin:0 6px;width:calc(25% - 12px)}#SubscribeContracts .fieldset-linear,#SubscribeContracts .fieldset-linearP{border:none;border-top:1px solid hsla(0,0%,39%,.5)}#SubscribeContracts .fieldset-linearP{border-top:1px solid #009dc0}#SubscribeContracts .list{max-height:350px;overflow:auto;overflow-x:hidden!important;position:relative;width:100%}#SubscribeContracts .listMin{max-height:200px}.list.active{max-height:100vh!important}#SubscribeContracts .col{display:flex;justify-content:center;margin:20px 0}#SubscribeContracts .listItem{align-items:center;border-bottom:1px solid rgba(0,0,0,.1);display:flex;justify-content:space-between}#SubscribeContracts .listItem:last-child{border-bottom:none}#SubscribeContracts .listItem:hover{background-color:rgba(0,151,191,.2)!important;overflow:hidden;-webkit-transform:scale(1.01);transform:scale(1.01)}#SubscribeContracts .paper{height:100%!important}#SubscribeContracts .bg-icon,#SubscribeContracts .listItem .control-view-item-list{align-items:center;display:flex}#SubscribeContracts .bg-icon{background:#fff;border-radius:50%;height:24px;justify-content:center;width:24px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
