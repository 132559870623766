import { Chip } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import Button from '../../common/Button';
import CommonTable from '../../common/CommonTable';
import './CompanyList.css';

@observer
export default class CompanyList extends Component {
  constructor() {
    super();
    this.state = { arrayStatus: [] };
    //this.onStatusChange = this.onStatusChange.bind(this);
  }

  componentWillMount() {
    this.props.callPageSelectFilter();
  }

  /*onStatusChange(toChange) {
    let active = !toChange.status;
    this.props.onFormSubmitStatus({ id_company: toChange.id_company, active: active });

    let itens = this.state.arrayStatus;

    if (itens[toChange.id_company]) {
      itens[toChange.id_company] = active;
    } else {
      itens = { [toChange.id_company]: active };
    }

    this.setState({ arrayStatus: itens });
  }*/

  render() {
    let {
      language,
      handlePage,
      params,
      companies,
      handlePageDelete,
      handlePageDisable
    } = this.props;

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 60, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'status',
        label: language.STATUS,
        style: { width: 60, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'type',
        label: language.TYPE,
        style: { width: 30, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'company',
        label: language.COMPANY,
        style: { textAlign: 'center', maxWidth: 100, overflow: 'auto' },
      },
      {
        key: 'tin',
        label: language.TIN,
        style: { width: 180, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'phone',
        label: language.PHONE,
        style: { width: 180, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'email',
        label: language.EMAIL,
        style: { width: 180, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'sys_id',
        label: 'Sys Id',
        style: { width: 180, paddingRight: 0 },
        thConfig: true,
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 90, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let csvData = [];

    let colCSV = [
      { key: 'id', label: language.ID },
      { key: 'type', label: language.TYPE },
      { key: 'company', label: language.COMPANY },
      { key: 'tin', label: language.TIN },
      { key: 'telefone', label: language.PHONE },
      { key: 'email', label: language.EMAIL },
      { key: 'sys_id', label: 'Sys Id' },
    ];

    let { configurationProps = {}, downloadCompanies } = this.props;
    return (
      <Fragment>
        <CommonTable
          title=""
          data={companies.map((obj) => {
            if (this.state.arrayStatus[obj.id_company] != undefined) {
              obj.status = this.state.arrayStatus[obj.id_company];
            }
            return {
              id: obj.id_company,
              status: (
                <Button
                  variant="circle"
                  icon={obj.status ? 'fa fa-check' : 'fas fa-times'}
                  color={obj.status ? 'success' : 'danger'}
                  style={{ fontSize: 12 }}
                  size={0.7}
                  label={`${language.DISABLE} ${language.COMPANY}`}
                  className="mr-2"
                  //onClick={() => this.onStatusChange(obj)}
                  onClick={() => handlePageDisable(true, obj)}
                />
              ),
              type:
                obj.type === 0
                  ? language.COMPANY
                  : obj.type === 1
                  ? language.CLIENT
                  : language.OUTSOURCED,
              company: obj.name,
              tin: obj.cnpj,
              phone: obj.phone,
              email: obj.email,
              sys_id: obj.sys_id,
              actions: (
                <React.Fragment>
                  <Chip
                    className="mx-2 muit-chip"
                    label={
                      <div className="d-flex align-items-center justify-content-between">
                        {params?.allow_customize_priority && (
                          <Button
                            variant="circle"
                            icon="fas fa-plus"
                            label={language.CUSTOMIZE_PRIORITY}
                            color="warning"
                            style={{
                              fontSize: 13,
                              width: 21,
                              height: 21,
                              marginRight: 10,
                            }}
                            onClick={() =>
                              handlePage('customize_priority', obj)
                            }
                            size={0.9}
                          />
                        )}

                        <Button
                          variant="circle"
                          icon="fas fa-plus"
                          label={language.WORKFLOW_OF_CHANGE}
                          color="primary"
                          style={{
                            fontSize: 13,
                            width: 21,
                            height: 21,
                            marginRight: 10,
                          }}
                          onClick={() => handlePage('worklowchange', obj)}
                          size={0.9}
                        />
                        <Button
                          variant="circle"
                          icon="fas fa-plus"
                          label={language.ADD_TASK_ADD}
                          color="primary"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => handlePage('new/task', obj)}
                          size={0.9}
                        />

                        {obj.type !== 10 ? (
                          <Button
                            variant="circle"
                            icon="fas fa-plus"
                            color="success"
                            label={language.ADD_DEPARTMENT}
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            className="mx-2"
                            onClick={() => handlePage('new/department', obj)}
                            size={0.9}
                          />
                        ) : (
                          <Button
                            variant="circle"
                            icon="fas fa-times"
                            label={language.NOT_AUTHORIZED}
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            size={0.9}
                            className="mx-2"
                          />
                        )}
                        <Button
                          variant="circle"
                          icon="fas fa-plus"
                          color="warning"
                          label={language.ADD_LOCATION}
                          className="mr-2"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => handlePage('new/location', obj)}
                          size={0.9}
                        />
                        {params?.enable_service_portal_by_companies && (
                          <Button
                            variant="circle"
                            icon="fas fa-plus"
                            color="info"
                            label={language.ADD_SERVICE_PORTAL}
                            className="mr-2"
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            onClick={() => handlePage('new/portal', obj)}
                            size={0.9}
                          />
                        )}
                        <Button
                          variant="circle"
                          icon="fas fa-plus"
                          color="success"
                          label={language.ADD_GROUPING}
                          className="mr-2"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => handlePage('new/grouping', obj)}
                          size={0.9}
                        />
                        <Button
                          variant="circle"
                          icon="fas fa-plus"
                          color="warning"
                          label={language.FORM_TEMPLATE}
                          className="mr-2"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => handlePage('new/forms', obj)}
                          size={0.9}
                        />
                        {obj.use_custom_form_for_rfc_cab === true && (
                          <Button
                            variant="circle"
                            icon="fas fa-plus"
                            color="danger"
                            label={language.RISK_CHANGE}
                            className="mr-2"
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            onClick={() => handlePage('edit/cab_risk', obj)}
                            size={0.9}
                          />
                        )}
                        <Button
                          variant="circle"
                          icon="fas fa-envelope"
                          color="default"
                          label={language.CONFIG_SEND_MAIL}
                          className="mr-2"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => handlePage('edit/send_mail', obj)}
                          size={0.9}
                        />
                        <Button
                          variant="circle"
                          icon="fas fa-edit"
                          label={language.EDIT}
                          color="default"
                          style={{ fontSize: 12, width: 21, height: 21 }}
                          size={0.9}
                          onClick={() => handlePage('edit', obj)}
                        />
                        {obj.type !== 0 ? (
                          <Button
                            variant="circle"
                            icon="far fa-trash-alt"
                            label={language.DELETE}
                            color="danger"
                            style={{ fontSize: 12, width: 21, height: 21 }}
                            size={0.9}
                            className="mx-2"
                            onClick={() => handlePageDelete(true, obj)}
                          />
                        ) : null}
                      </div>
                    }
                  />
                </React.Fragment>
              ),
            };
          })}
          columns={col}
          language={language}
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beginWithNoFilter={true}
          currentPage={this.props.currentPage}
          countTotal={this.props.countTotal}
          isAutomaticPagination={false}
          searchColumnEvent={(v) => {
            this.props.callPageSelectFilter(0, v, 75);
          }}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.props.callPageSelectFilter(page, values, rowsPerPage, type)
          }
          beforeBar2={
            <>
              <Button
                variant="circle"
                icon="fas fa-plus"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="warning"
                label={language.CREATE_PRIORITY_CUSTOM_GLOBAL_SETTING}
                primary={true}
                onClick={() => handlePage('customize_priority', {})}
              />
            </>
          }
          beforeBar={
            <>
              <Button
                variant="circle"
                icon="fas fa-plus"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                label={language.NEW_COMPANY}
                primary={true}
                onClick={() => this.props.handlePage('new')}
              />
              <Button
                variant="circle"
                icon="fas fa-file-csv"
                label={language.EXPORT_COMPANIES}
                color="warning"
                className="mr-3"
                style={{
                  fontSize: 15,
                }}
                onClick={downloadCompanies}
              />
            </>
          }
        />
      </Fragment>
    );
  }
}
