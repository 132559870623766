import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import SimplesAlert from '../../common/SimplesAlert';
import { Constants, Store } from '../../data';
import GenericActions from '../../data/Actions';
import { EMAIL_REGEX } from '../../util/regex';
import {
  RecoveryBox,
  RecoveryPasswordHeader,
  StepButtons,
} from './LoginRecoveryPassword';

const useStyles = makeStyles((theme) => ({
  menuItemSmall: {
    fontSize: 12,
    paddingTop: theme.spacing(0.85),
    paddingBottom: theme.spacing(0.85),
  },
  font12: {
    fontSize: 12,
  },
  font13: {
    fontSize: 13,
  },
}));

export default function LoginRecoverySetting({
  onGoBack,
  showHeaderLine = true,
  resetPasswordToken,
  triggerAlertOnSuccess = false,
}) {
  const { language } = Store;

  const RECOVERY_TYPE = {
    EMAIL: 'email',
    CHALLENGE: 'challenge',
  };

  const RECOVERY_TYPE_LABEL = {
    email: language.RECOVERY_PASSWORD.ALTERNATIVE_EMAIL,
    challenge: language.RECOVERY_PASSWORD.SELECT_SECURITY_QUESTIONS,
  };

  const classes = useStyles();
  const alertDefault = { opened: false, message: '', variant: 'success' };
  const [alert, setAlert] = useState(alertDefault);
  const [recoveryType, setRecoveryType] = useState();
  const [step, setStep] = useState(1);

  const [email, setEmail] = useState();
  const [passwordQuestions, setPasswordQuestions] = useState([]);
  const [question1, setQuestion1] = useState();
  const [question2, setQuestion2] = useState();

  useEffect(() => {
    if (step === 3) {
      getRecoveryQuestions();
    }
  }, [step]);

  const handleRecoveryType = (type) => {
    setRecoveryType(type);
  };

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  const handleOpenAlert = (message, variant = 'success') => {
    setAlert({ opened: true, message: message, variant: variant });
  };

  const handleToggleStep = (goto) => setStep(goto);

  const handleSubmitReplyQuestion = () => {
    const url = Constants.APIEndpoints.PASSWORD_MY_QUESTIONS;
    const replies = [question1, question2];
    const payload = { replies };
    const fetch = new GenericActions();
    fetch
      .execute('post', url, null, payload)
      .then((response) => {
        if (triggerAlertOnSuccess) {
          handleOpenAlert(language.REGISTER_SAVE, 'default');
          handleToggleStep(1);
        }
        handleRedirectChangePassword();
      })
      .catch((error) => {
        handleOpenAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const handleSubmitAlternativeEmail = () => {
    const url = Constants.APIEndpoints.PASSWORD_MY_ALTERNATIVE_EMAIL;
    const fetch = new GenericActions();
    fetch
      .execute('post', url, null, { email })
      .then((response) => {
        if (triggerAlertOnSuccess) {
          handleOpenAlert(language.REGISTER_SAVE, 'default');
          handleToggleStep(1);
        }
        handleRedirectChangePassword();
      })
      .catch((error) => {
        handleOpenAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const handleRedirectChangePassword = (second) => {
    if (resetPasswordToken) {
      const history = createBrowserHistory();
      history.push(`/redefine/?t=${resetPasswordToken}`);
      history.go();
    }
  };

  const getRecoveryQuestions = () => {
    const lang = sessionStorage.getItem('sensr_lang').toLowerCase();
    const url = Constants.APIEndpoints.PASSWORD_QUESTIONS;
    const queryString = `?lang=${lang}&limit=150&offset=0`;
    const fetch = new GenericActions();
    fetch
      .execute('get', url + queryString)
      .then((response) => {
        const { data } = response;
        if (data && data.length > 0) {
          setPasswordQuestions(data);
        }
      })
      .catch((error) => {
        setPasswordQuestions([]);
      });
  };

  return (
    <div className="default-color">
      {showHeaderLine && (
        <div className="mb-2">
          <RecoveryPasswordHeader>
            {language.RECOVERY_PASSWORD.PROTECT_ACCOUNT}
          </RecoveryPasswordHeader>
        </div>
      )}
      {step === 1 && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-sm">
          <p className="tw-mb-2 tw-text-xs tw-leading-4 tw-text-gray-600">
            {language.RECOVERY_PASSWORD.ALTERNATIVE_EMAIL_DESCRIPTION}
          </p>
          <RecoveryBox
            selected={recoveryType === RECOVERY_TYPE.EMAIL}
            onRecoveryClick={() => handleRecoveryType(RECOVERY_TYPE.EMAIL)}
          >
            <div>
              <p className="tw-text-xs tw-uppercase tw-text-gray-600 tw-font-semibold">
                {RECOVERY_TYPE_LABEL[RECOVERY_TYPE.EMAIL]}
              </p>
              <div className="tw-text-xs tw-leading-4 tw-mt-1 default-color">
                {language.RECOVERY_PASSWORD.ALTERNATIVE_EMAIL_INFO}
              </div>
            </div>
          </RecoveryBox>
          <RecoveryBox
            selected={recoveryType === RECOVERY_TYPE.CHALLENGE}
            onRecoveryClick={() => handleRecoveryType(RECOVERY_TYPE.CHALLENGE)}
          >
            <div>
              <p className="tw-text-xs tw-uppercase tw-text-gray-600 tw-font-semibold">
                {RECOVERY_TYPE_LABEL[RECOVERY_TYPE.CHALLENGE]}
              </p>
              <div className="tw-text-xs tw-leading-4 tw-mt-1 default-color">
                {language.RECOVERY_PASSWORD.SELECT_SECURITY_QUESTIONS_INFO}
              </div>
            </div>
          </RecoveryBox>

          <StepButtons
            // onPrev={onGoBack}
            onNext={() =>
              handleToggleStep(recoveryType === RECOVERY_TYPE.EMAIL ? 2 : 3)
            }
            disableNextButton={!recoveryType}
            showPrev={false}
          />
        </div>
      )}
      {step === 2 && (
        <>
          <p className="tw-text-xs tw-uppercase tw-text-gray-600 tw-font-semibold tw-mb-1">
            {RECOVERY_TYPE_LABEL[RECOVERY_TYPE.EMAIL]}
          </p>
          <p className="tw-mb-6 tw-text-xs tw-leading-4 tw-text-gray-600">
            {language.RECOVERY_PASSWORD.ALTERNATIVE_EMAIL_INFO}
          </p>
          <TextField
            autoFocus
            fullWidth
            type="text"
            variant="outlined"
            error={email && !EMAIL_REGEX.test(email)}
            label={language.ALTERNATIVE_EMAIL}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setEmail(event.target.value)}
          />
          <div className="tw-h-10"></div>
          <StepButtons
            onPrev={() => handleToggleStep(1)}
            onNext={handleSubmitAlternativeEmail}
            disableNextButton={!email}
          />
        </>
      )}
      {step === 3 && (
        <>
          <p className="tw-text-xs tw-uppercase tw-text-gray-600 tw-font-semibold tw-mb-1">
            {RECOVERY_TYPE_LABEL[RECOVERY_TYPE.CHALLENGE]}
          </p>
          <p className="tw-mb-6 tw-text-xs tw-leading-4 tw-text-gray-600">
            {language.RECOVERY_PASSWORD.SELECT_SECURITY_QUESTIONS_INFO}
          </p>
          {passwordQuestions.length > 0 && (
            <>
              <FormControl
                size="small"
                variant="outlined"
                className="tw-w-full mb-4"
              >
                <InputLabel id="question-1" className={classes.font13}>
                  {language.RECOVERY_PASSWORD.SECURITY_QUESTION}
                </InputLabel>
                <Select
                  displayEmpty
                  className={classes.font12}
                  labelId="question-1"
                  label={language.RECOVERY_PASSWORD.SECURITY_QUESTION}
                  onChange={(event) =>
                    setQuestion1({ id_password_question: event.target.value })
                  }
                >
                  {passwordQuestions.map((question, index) => (
                    <MenuItem
                      key={index}
                      dense={true}
                      value={question.id_password_question}
                      className={classes.menuItemSmall}
                      component={'div'}
                      style={{
                        paddingTop: '1rem !important',
                        paddingBottom: '1rem !important',
                      }}
                    >
                      {question.question_text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                type="text"
                label={language.ANSWER}
                variant="outlined"
                className="tw-text-xs"
                disabled={!question1}
                InputLabelProps={{ shrink: true }}
                error={question1 && !question1?.reply}
                autoFocus={question1 && !question1?.reply}
                onChange={(event) => {
                  setQuestion1({ ...question1, reply: event.target.value });
                }}
              />
              <hr className="my-6" />
              <FormControl
                size="small"
                variant="outlined"
                className="tw-w-full mb-4"
              >
                <InputLabel className={classes.font13} id="question-2">
                  {language.RECOVERY_PASSWORD.SECURITY_QUESTION}
                </InputLabel>
                <Select
                  displayEmpty
                  className={classes.font12}
                  labelId="question-2"
                  label={language.RECOVERY_PASSWORD.SECURITY_QUESTION}
                  onChange={(event) =>
                    setQuestion2({ id_password_question: event.target.value })
                  }
                >
                  {passwordQuestions
                    .filter(
                      (item) =>
                        item.id_password_question !==
                        question1?.id_password_question
                    )
                    .map((question, index) => (
                      <MenuItem
                        key={index}
                        dense={true}
                        component={'div'}
                        value={question.id_password_question}
                        className={classes.menuItemSmall}
                        style={{
                          paddingTop: '1rem !important',
                          paddingBottom: '1rem !important',
                        }}
                      >
                        {question.question_text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                type="text"
                label={language.ANSWER}
                variant="outlined"
                className="tw-text-xs"
                disabled={!question2}
                InputLabelProps={{ shrink: true }}
                error={question2 && !question2?.reply}
                autoFocus={question2 && !question2?.reply}
                onChange={(event) => {
                  setQuestion2({ ...question2, reply: event.target.value });
                }}
              />
            </>
          )}
          <StepButtons
            onPrev={() => handleToggleStep(1)}
            onNext={handleSubmitReplyQuestion}
            disableNextButton={!question1?.reply && !question2?.reply}
          />
        </>
      )}

      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
}
