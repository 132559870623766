import React, { Fragment } from 'react';
import './index.css';
/*----------------------------------common----------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import List from '@common/List';

/*------------------------------material-ui----------------------------------*/
import { Grid, Card } from '@material-ui/core';
/*--------------------------------------------------------------------------*/
import { Categories } from './Graph';
import GridStatusListB4B6 from '../GridStatusListB4B6';
export default function ({ data, language }) {
  return (
    <Card id="Categories" className="h-100">
      <AppBar title={language.TOP_10_CATEGORIES} />
      <div className="content pt-0">
        <Grid container spacing={16}>
          <Grid item xs={5}>
            <ReactHighcharts config={Categories({ data })} />
          </Grid>
          <Grid item xs={7}>
            <GridStatusListB4B6
              gridColumn="50% 16.6666667% 16.6666667% 16.6666667%"
              data={data}
              language={language}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
