import React from 'react';
import './index.css';
import { observer } from 'mobx-react';
import ReactHighcharts from 'react-highcharts';
import moment from 'moment';
// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import Header from '@common/Header';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import Progress from '@common/Progress';
// --------------------------------material-ui----------------------------------------------------
import { Card, Grid } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
// --------------------------------pages----------------------------------------------------
import { foreseen_vs_realized } from './Graph';
import Logo from '../../../../public/images/newLogoSensrWhite.png';

let typeProject = {
  corrective: {
    icon: 'fas fa-wrench',
    color: 'primary',
    label: 'Corretiva',
  },
  strategic: {
    icon: 'fas fa-compass',
    color: 'info',
    label: 'Estratégico',
  },
  obligation: {
    icon: 'fas fa-gavel',
    color: 'danger',
    label: 'Obrigação Legal',
  },
  evolutionary: {
    icon: 'fas fa-chart-line',
    color: 'success',
    label: 'Evolutiva',
  },
  improvement: {
    icon: 'fas fa-dumbbell',
    color: 'purple',
    label: 'Melhoria',
  },
};

@observer
export default class extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
    };
    this.handleValuePreOrRem = this.handleValuePreOrRem.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }

  componentWillMount() {
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectlist',
      'pmo',
      null,
      null,
      null
    );

  }

  handleValuePreOrRem(values = '', type) {
    if (values) {
      return values.grupo
        ? values.grupo
            .filter((x) => !x.deleted)
            .reduce((prev, next) => {
              return prev + !next.atividades
                ? []
                : next.atividades
                    .filter((at) => !at.deleted)
                    .reduce((pr, ne) => {
                      if (ne.local[0] == type && ne.sum_hour) {
                        let result =
                          pr +
                          this.convertHourStringToNumber(ne.sum_hour, /\,/g);

                        return result;
                      }
                      return pr + 0;
                    }, 0);
            }, 0)
        : 0;
    }
    return 0;
  }

  convertHourStringToNumber = (value = '', regex = '') => {
    if (!value) return 0;
    let newValue = value.split(':');
    let v = 0;

    if (regex) {
      let format = value.split(regex);
      format.shift();
      v = format.reduce((prev, next) => {
        if (next == 'Data inválida') return prev + 0;
        let valueNew = parseInt(next.split(':')[0]);
        return prev + valueNew;
      }, 0);
    } else {
      v = parseInt(newValue[0]);
    }

    return isNaN(v) ? 0 : v;
  };

  handlePrint() {
    window.print();
  }

  render() {
    let { language, pmo, users, companies } = this.store;
    const { status, openAll } = this.state;
    let { location } = this.props;

    if (!location.state) {
      this.redirect('/dash/gp');
      return;
    }

    let values = pmo
      ? pmo.projetos.find(
          (projeto) => projeto.id_project === location.state.id_project
        )
      : null;

    let activities =
      values && values.grupo
        ? values.grupo
            .filter((gr) => !gr.deleted)
            .reduce((prev, next) => {
              let newArray = next.atividades
                ? next.atividades
                : []
                    .filter((at) => !at.deleted)
                    .map((atividade) => {
                      return {
                        id: atividade.id_project_activity,
                        name: atividade.name,
                        sponsor: users.find(
                          (u) => u.id_user == atividade.fk_id_user
                        ).name,
                        dt_i: moment(atividade.dt_start).format('L'),
                        dt_end: moment(atividade.dt_end).format('L'),
                        effort: atividade.hours + 'h',
                        consumed: pmo.arrayConsumed.find(
                          (pa) =>
                            pa.fk_id_activity == atividade.id_project_activity
                        )
                          ? pmo.arrayConsumed.find(
                              (pa) =>
                                pa.fk_id_activity ==
                                atividade.id_project_activity
                            ).hours_consumed
                          : 0,
                        status: atividade.stage,
                      };
                    });
              return [...prev, ...newArray];
            }, [])
        : [];

    let risk = values ? values.type_risk : 0;
    let data = {
      logo: Logo,
      name:
        companies.length > 0 && values
          ? companies.find((com) => com.id_company === values.fk_id_company)
              .name
          : '',
      typeProject: 'improvement',
      listInfo: [
        {
          label: `${language.PROJECT}:`,
          value: values ? values.name : '',
        },
        {
          label: `${language.MANAGE}:`,
          value:
            users.length > 0 && values
              ? users.find((u) => u.id_user == values.user_cad)?.name
              : '',
        },
        {
          label: `${language.RISKS}:`,
          value: (
            <div className="content-star">
              {[1, 2, 3].map((e) => (
                <i className={risk >= e ? 'fas fa-star' : 'far fa-star'} />
              ))}
            </div>
          ),
        },
        {
          label: `${language.COST}:`,
          value: parseFloat(values ? values.cust_project : '0').toLocaleString(
            'pt-BR',
            { style: 'currency', currency: 'BRL' }
          ),
        },
        {
          label: `${language.GOAL}:`,
          value: values ? (
            <p dangerouslySetInnerHTML={{ __html: values.goal }}></p>
          ) : (
            ''
          ),
        },
      ],
      foresseen: values
        ? this.convertHourStringToNumber(values.horas_planejadas)
        : 0,
      realized: values
        ? this.convertHourStringToNumber(values.hour_consumida)
        : 0,
      dt_i: values ? moment(values.start_date).format('LL') : '',
      dt_end: values ? moment(values.end_date).format('LL') : '',
      evolution: values ? (!isNaN(+values.percent) ? +values.percent : 0) : 0,
      cardInfo: [
        {
          label: language.TOTAL_ACTIVITIES,
          value: values ? values.statusproject.total : 0,
        },
        {
          label: language.TOTAL_PEOPLE_INVOLVED,
          value: values ? values.users.length : 0,
        },
        {
          label: language.TOTAL_REMOTE_HOURS,
          value: this.handleValuePreOrRem(values, 2),
        },
        {
          label: language.TOTAL_FACE_TO_FACE_HOURS,
          value: this.handleValuePreOrRem(values, 1),
        },
      ],
      projectStatus: [
        /*
        {
          title:'Backlog',
          qtd:4,
          total:15
        },*/
        {
          title: language.PROJECT_STATUS.TO_DO,
          qtd: values ? values.statusproject.TODO.length : 0,
          total: values ? values.statusproject.total : 0,
          status: 'TODO',
          color: 'var(--purple)',
        },
        {
          title: language.PROJECT_STATUS.DOING,
          qtd: values ? values.statusproject.DOING.length : 0,
          total: values ? values.statusproject.total : 0,
          status: 'DOING',
          color: 'var(--primary)',
        },
        {
          title: language.PROJECT_STATUS.STOPPED,
          qtd: values ? values.statusproject.STOPPED.length : 0,
          total: values ? values.statusproject.total : 0,
          status: 'STOPPED',
          color: 'var(--danger)',
        },
        /*
        {
          title:'Validação',
          qtd:4,
          total:15
        },*/
        {
          title: language.PROJECT_STATUS.DONE,
          qtd: values ? values.statusproject.DONE.length : 0,
          total: values ? values.statusproject.total : 0,
          status: 'DONE',
          color: 'var(--success)',
        },
      ] /*[
        {
          id:105,
          name:'Instalação do SSD na máquina da Hellen',
          sponsor:'Guilherme',
          dt_i:'12/03/2020',
          dt_end:'12/03/2020',
          effort:'12h',
          consumed:'12:23:00',
          status:'Concluído'
        },
        {
          id:105,
          name:'Instalação do SSD na máquina da Hellen',
          sponsor:'Guilherme',
          dt_i:'12/03/2020',
          dt_end:'12/03/2020',
          effort:'12h',
          consumed:'12:23:00',
          status:'Concluído'
        }
      ] */,
      /*activities: values && values.grupo ? values.grupo.filter(gr => !gr.deleted).reduce((prev, next) => {
        let newArray = next.atividades.filter(at => !at.deleted).map(atividade => {
          return {
            id: atividade.id_project_activity,
            name: atividade.name,
            sponsor: users.find(u => u.id_user == atividade.fk_id_user).name,
            dt_i: moment(atividade.dt_start).format('L'),
            dt_end: moment(atividade.dt_end).format('L'),
            effort: atividade.hours + 'h',
            consumed:  pmo.arrayConsumed.find(pa => pa.fk_id_activity == atividade.id_project_activity) ? pmo.arrayConsumed.find(pa => pa.fk_id_activity == atividade.id_project_activity).hours_consumed : 0,
            status: atividade.stage
          }
        });
        return [...prev, ...newArray];
      }, []) : [] */
    };
    let _typeProject = typeProject[data.typeProject];
    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        <PageLayout
          title={language.PANEL_PMO}
          subTitle={`${language.PROJECT_MANAGEMENT} /`}
          rightElements={[
            <ReactToPrint
              trigger={() => (
                <Button
                  color="warning"
                  icon="fas fa-print"
                  label={language.PRINT}
                  className="m-auto mr-5"
                  onClick={() => {}}
                />
              )}
              content={() => this.componentRef}
            />,
            /*<Button 
              color="warning"  
              icon='fas fa-print'
              label='Imprimir'
              className='m-auto mr-5'
              onClick={this.handlePrint}
            /> */
          ]}
        >
          <div id="Project-report" ref={(el) => (this.componentRef = el)}>
            {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
            <header
              style={{ background: `url(${BarBlue})` }}
              className="content d-flex justify-content-between"
            >
              <div className="d-flex align-items-center">
                <div>
                  <span>{language.CLIENT}</span>
                  <h2>{data.name}</h2>
                </div>
              </div>
              <img src={data.logo} alt="" style={{ objectFit: 'contain' }} />

              <h4>{language.PROJECT_BOOK}</h4>
            </header>

            <div className="content">
              <div
                style={{
                  backgroundColor: `var(--${
                    values
                      ? language.TYPE_PROJECT_ITEMS[values.type_project - 1]
                          .color
                      : 'primary'
                  })`,
                }}
                className="content-project-type content-medium"
              >
                <span>{language.TYPE_PROJECT}</span>
                <span>
                  <i
                    className={
                      values
                        ? language.TYPE_PROJECT_ITEMS[values.type_project - 1]
                            .icon
                        : ''
                    }
                  />
                  {values
                    ? language.TYPE_PROJECT_ITEMS[values.type_project - 1].label
                    : ''}
                </span>
              </div>
              {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="content-medium content-infos">
                <Grid container spacing={16}>
                  <Grid item xs={4}>
                    <ul className="content-info-list">
                      {data.listInfo.map((e) => (
                        <li key={e.label}>
                          <div className="label">{e.label}</div>
                          <div className="value ml-1">{e.value}</div>
                        </li>
                      ))}
                    </ul>
                  </Grid>

                  <Grid item xs={5}>
                    <Grid
                      container
                      spacing={2}
                      className="content-foresseen_vs_realized-info content py-0"
                    >
                      <Grid
                        item
                        xs={6}
                        className="content-foresseen_vs_realized"
                      >
                        <div>
                          <span>{language.FORESEEN}</span>
                          <span>{data.foresseen}</span>
                        </div>
                        <div>
                          <span>{language.CONSUMED}</span>
                          <span>{data.realized}</span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <ReactHighcharts
                          config={foreseen_vs_realized(
                            data.foresseen,
                            data.realized
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3} className="content-period">
                    <span className="label">
                      <i className="fas fa-calendar-day" />
                      {language.INITIAL_DATE}
                    </span>
                    <span className="value">{data.dt_i}</span>
                    <span className="label">
                      <i className="fas fa-calendar-day" />
                      {language.END_DATE}
                    </span>
                    <span className="value">{data.dt_end}</span>
                    <span className="label">{language.EVOLUTION_STATUS}</span>
                    <div className="value d-flex align-items-center">
                      <Progress
                        current={data.evolution}
                        className="w-100"
                        hiddenText
                        style={{ height: 7 }}
                        background="var(--danger)"
                      />
                      <span className="ml-2">{data.evolution}%</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
              <Grid container spacing={2} className="px-5 mt-3">
                {data.cardInfo.map((e) => (
                  <Grid item xs={3} key={e.label}>
                    <div className="content-info-cards content">
                      <span>{e.label}</span>
                      <h4>{e.value}</h4>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <hr />

              <ul className="content-info-activities-status px-5">
                {data.projectStatus.map((e) => (
                  <React.Fragment>
                    <li
                      key={e.title}
                      className="content py-2 mb-2"
                      style={{ backgroundColor: e.color }}
                    >
                      <span>{e.title}</span>
                      <div>
                        <h4>
                          {e.qtd}/{e.total}
                        </h4>
                        <span>
                          (
                          {isNaN(parseInt((e.qtd * 100) / e.total))
                            ? 0
                            : parseInt((e.qtd * 100) / e.total)}
                          %)
                        </span>
                      </div>
                    </li>
                    <ul>
                      {activities
                        .filter((x) => x.status === e.status)
                        .map((e, i) => (
                          <li
                            className="content content-list-projects mb-3"
                            key={i}
                          >
                            <div className="header d-flex justify-content-between w-100">
                              <p>
                                <span>{e.id}</span> - {e.name}
                              </p>
                              <p>
                                {language.EXECUTOR}: <span>{e.sponsor}</span>
                              </p>
                            </div>
                            <hr />
                            <div className="content content-body">
                              <span>{language.ACTIVITY_TIME}</span>
                              <div className="content-info-project">
                                <div>
                                  <span>
                                    <i className="fas fa-calendar-day" />
                                    {language.START_}
                                  </span>
                                  <span>{e.dt_i}</span>
                                </div>
                                <div>
                                  <span>
                                    <i className="fas fa-calendar-day" />
                                    {language.END_}
                                  </span>
                                  <span>{e.dt_end}</span>
                                </div>
                                <div>
                                  <span>
                                    <i className="fas fa-calendar-day" />
                                    {language.EFFORT}
                                  </span>
                                  <span>{e.effort}</span>
                                </div>
                                <div>
                                  <span>
                                    <i className="fas fa-calendar-day" />
                                    {language.CONSUMED}
                                  </span>
                                  <span>{e.consumed}</span>
                                </div>
                                <div>
                                  <span>
                                    <i className="fas fa-calendar-day" />
                                    {language.STATUS}
                                  </span>
                                  <span>{e.status}</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}
