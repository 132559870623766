import React, { Component } from 'react';
import RaisedButton from '../../common/RaisedButton';
import { Row, Col } from 'react-grid-system';
import vendors from '../../util/vendors';
import { Radial } from 'sensrit-charts';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import { mem, convertMemory, percent, disk, bytes } from '../../util/MonitorUtils';
import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import moment from 'moment';
import { Grid, Card, Avatar, Chip } from '@material-ui/core';
import PageLayout from '@common/PageLayout';
import ListColumn from '@common/ListColumn';
import List from '@common/List';
import AppBar from '@common/AppBar';
import './Summary.css';
import { Tooltip } from 'react-tippy';
import Actions from '@data/Actions';

const action = new Actions();
let host = '';
let tokenApi = '';
let typeDevice = '';
String.prototype.toHHMMSS = function () {

  var cur = parseInt(this, 10); // don't forget the second param


  let days = Math.floor(cur / 86400);
  cur -= days * 86400;
  let hours = Math.floor(cur / 3600) % 24;
  cur -= hours * 3600;
  let minutes = Math.floor(cur / 60) % 60;
  cur -= minutes * 60;
  let seconds = Math.floor(cur) % 60;


  var time = days + ' Dias ' + hours + ' Horas ' + minutes + ' Minutos ';
  return time;
}

class CardCpuMemory extends Component {
  render() {
    let {
      title,
      titleIcon,
      language,
      label1,
      label2,
      value1,
      value2,
      currentGraph,
      data
    } = this.props;
    return (
      <Grid item xs={6}>
        <Card style={{ overflow: 'visible' }}>
          <div className='content pb-0 pt-3'>
            <Chip
              avatar={
                <Avatar className='avatar'>
                  <i className={titleIcon} />
                </Avatar>
              }
              className='chip-header shadown'
              label={title}
            />
          </div>
          <hr />
          <div className='content pr-0 pt-0 pb-0'>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <div style={{ margin: '0 auto' }}>
                  <Radial
                    low={{ color: '#33ABA0' }}
                    mid={{ color: '#FFC107', value: 60 }}
                    high={{ color: '#F44336', value: 80 }}
                    className="host-graph-perc"
                    size={200}
                    current={parseInt(currentGraph)}
                    total={100}
                    thick={50}
                  >
                    <h1>
                      <span className={titleIcon} style={{ fontSize: currentGraph >= 100 ? 42 : undefined }}>
                        {' '}
                        {parseInt(currentGraph)}%
                      </span>
                    </h1>
                  </Radial>
                </div>
                <div className='mt-4'>
                  <ListColumn
                    col={12}
                    items={[
                      {
                        contentProps: {
                          style: {
                            textAlign: 'center'
                          }
                        },
                        label: label1,
                        value: value1
                      },
                      {
                        contentProps: {
                          style: {
                            textAlign: 'center'
                          }
                        },
                        label: label2,
                        value: value2
                      }
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={8} className='pt-0 pb-0'>
                <List
                  style={{
                    borderLeft: '1px solid rgba(0,0,0,0.1)'
                  }}
                  className='pt-0 pb-0'
                  items={[
                    {
                      style: {
                        backgroundColor: 'rgba(0,0,0,0.1)'
                      },
                      grid: [
                        {
                          col: 3,
                          className: 'font',
                          style: {
                            fontWeight: 500,

                          },
                          content: language.PROCESSES
                        },
                        {
                          col: 3,
                          className: 'font',
                          style: {
                            fontWeight: 500,

                          },
                          content: '%' + language.CPU
                        },
                        {
                          col: 3,
                          className: 'font',
                          style: {
                            fontWeight: 500,

                          },
                          content: '%' + language.MEM
                        },
                        {
                          col: 3,
                          className: 'font',
                          style: {
                            fontWeight: 500,

                          },
                          content: language.MEM
                        }
                      ]
                    },
                    ...data
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    )
  }
}
export default class Summary extends CommonPage {
  constructor() {
    super();
    this.state = {
      available: false,
      mount: true,
      hold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disks: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      valuesTop: []
    };
  }

  componentWillMount() {
    host = Constants.APIRoot;
    tokenApi = this.store.getToken();
    this.getConnectionDevice(host, tokenApi);
    this.fetchAll(host, tokenApi);
  }

  componentWillUnmount() {
    this.state.mount = false;
    clearTimeout(this.state.hold);
  }

  render() {
    let { _device, language, handlePage } = this.props;
    let {
      values,
      uptime,
      cpuPerc,
      cors,
      memoryUsedPercent,
      memoryUsed,
      memory,
      procStats,
      disk,
      netStats,
      netStatsTx,
      netStatsRx,
      valuesTop,
      memoryUsedDash,
      cpuUsedDash, redirect
    } = this.state;

    let sysInfo = values['sys-info']
      ? values['sys-info']
      : _device.values['sys-info']
        ? _device.values['sys-info']
        : {};
    let conf = values.conf ? values.conf : _device.conf;

    let topByCpu = [];
    let topByCpu2 = [];
    let topByMem = [];
    let topByMem2 = [];
    let disks = [];
    let network = [];

    if (valuesTop.length != 0) {


      topByCpu2 = valuesTop.topByCpu.map((tcpu, key) => ({
        grid: [
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block',
            },
            content: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{tcpu.name}</strong>
                  </div>
                }
                arrow={true}
                position='right'
                theme="dark"
              >
                {tcpu.name}
              </Tooltip>
            )
          },
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block',
              color: 'rgb(255, 161, 20)',
            },
            content: tcpu.cpuPerc != undefined && tcpu.cpuPerc.toFixed(2)
          },
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block',
            },
            content: tcpu.memPerc != undefined && tcpu.memPerc.toFixed(2)
          },
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block',
            },
            content: convertMemory(tcpu.memUsed)
          }
        ]
      }));
      topByMem2 = valuesTop.topByMem.map((tmem, key) => ({
        grid: [
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block'
            },
            content: (
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{tmem.name}</strong>
                  </div>
                }
                arrow={true}
                position='right'
                theme="dark"
              >
                {tmem.name}
              </Tooltip>
            )
          },
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block',

            },
            content: tmem.cpuPerc != undefined && tmem.cpuPerc.toFixed(2)
          },
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block',
              color: 'rgb(20, 161, 255)'
            },
            content: tmem.memPerc != undefined && tmem.memPerc.toFixed(2)
          },
          {
            col: 3,
            className: 'font ellipsis',
            style: {
              fontSize: 14,
              display: 'block'
            },
            content: convertMemory(tmem.memUsed)
          }
        ]
      }));

    }

    if (disk) {
      disks = disk.map((disk, key) => (
        <Col md={2.8} className="summaryDisk" key={key}>
          <Row>
            <Col
              md={Object.keys(disk.values).indexOf('read_s') !== -1 ? 6 : 12}
            >
              <div className="summaryDiskInfo">{disk.name}</div>
              <Radial
                low={{ color: '#33ABA0' }}
                mid={{ color: '#FFC107', value: 60 }}
                high={{ color: '#F44336', value: 80 }}
                className="dash-alerts-radial"
                size={80}
                current={disk.values.used}
                total={disk.values.total}
                thick={20}
              >
                <div>
                  {percent(disk.values.used, disk.values.total)}%
                </div>
              </Radial>
              <div className="summaryDiskInfo">
                {disk(disk.values.used)} /{' '}
                {disk(disk.values.total)}
              </div>
            </Col>
            {Object.keys(disk.values).indexOf('read_s') !== -1 ? (
              <Col md={6} className="text-left">
                <div style={{ marginTop: 10 }}>{language.READ}:</div>
                <div>{disk.values.read_s + ' iops/s'}</div>
                <div>{bytes(disk.values.rb_s)}</div>
                <div style={{ marginTop: 20 }}>{language.WRITE}:</div>
                <div>{disk.values.write_s + ' iops/s'}</div>
                <div>{bytes(disk.values.wb_s)}</div>
              </Col>
            ) : null}
          </Row>
        </Col>
      ));
    }

    if (netStats) {
      network = netStats.map((net, key) => (
        <Col md={2} className="row summaryDisk" key={key}>
          <Col className="summaryInfo" md={12}>
            <b>{language.NAME}:</b>
            <span>{net.name}</span>
          </Col>
          <Col className="summaryInfo" md={12}>
            <b>{language.IP}:</b>
            <span>{net.address}</span>
          </Col>
          <div className="netlinkGroup">
            <div className="host-graph-net">
              <Sparklines data={[5, 10, 5, 20, 8, 15]}>
                <SparklinesLine color="#33ABA0" />
                <SparklinesSpots />
              </Sparklines>
              <h5>
                <FaCaretUp className="up" />
                {Number((netStatsTx / 1024).toFixed(3)) + ' Kbps'}
              </h5>
            </div>
            <div className="host-graph-net">
              <Sparklines data={[5, 20, 30, 5, 8, 15]}>
                <SparklinesLine color="#F44336" />
                <SparklinesSpots />
              </Sparklines>
              <h5 className="netRight">
                <FaCaretDown className="down" />
                {Number((netStatsRx / 1024).toFixed(3)) + ' Kbps'}
              </h5>
            </div>
          </div>
        </Col>
      ));
    }

    return (
      <PageLayout
        icon="monitorIcon"
        title={language.DEVICE_INFO}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            onClick={() => handlePage('dash')}
            label={language.RETURN}
            color='primaryGradient'
          />
        ]}
      >
        <div id='Summary' className='pt-3'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card style={{ overflow: 'visible' }}>
                <div className='content pb-0'>
                  <Chip
                    avatar={
                      <Avatar className='avatar'>
                        {vendors.getIcon(sysInfo.description)}
                      </Avatar>
                    }
                    className='chip-general-info shadown'
                    label={language.GENERAL_INF}
                  />
                </div>
                <hr />
                <div className='content'>
                  <ListColumn
                    col={4}
                    items={[
                      {
                        label: language.HOST,
                        value: (sysInfo.hostName || _device.name).toUpperCase()
                      },
                      {
                        label: language.IP,
                        value: _device.ip + (_device.port ? ':' + _device.port : '')
                      },
                      {
                        label: language.UPTIME,
                        value: uptime ? uptime : language.LOAD
                      },
                      {
                        label: language.VENDOR,
                        value: sysInfo.vendor ? sysInfo.vendor.toUpperCase() : language.LOAD
                      },
                      {
                        label: language.DESCRIPTION,
                        value: sysInfo.description ? sysInfo.description : language.LOAD
                      },
                      {
                        label: language.VERSION,
                        value: sysInfo.vendorVersion ? sysInfo.vendorVersion : language.LOAD
                      },
                      {
                        label: language.ARCHITECTURE,
                        value: sysInfo.arch ? sysInfo.arch : language.LOAD
                      },
                      {
                        label: language.SYSTEM_VERSION,
                        value: sysInfo.version ? sysInfo.version : language.LOAD
                      },
                      {
                        label: language.AGENT_VERSION,
                        value: sysInfo.agentVersion ? sysInfo.agentVersion : language.LOAD
                      },
                    ]}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <CardCpuMemory
              title={language.CPU}
              titleIcon='flaticon-cpu5'
              label1={language.PROCESSES}
              label2={'# ' + language.CORE}
              value1={procStats ? procStats : language.LOAD}
              value2={cors ? cors : language.LOAD}
              currentGraph={cpuUsedDash}
              data={topByCpu2}
              language={language}
            />
            <CardCpuMemory
              title={language.MEMORY}
              titleIcon='flaticon-random'
              label1={language.USED}
              label2={language.TOTAL}
              value1={memoryUsed ? memoryUsed : language.LOAD}
              value2={memory}
              currentGraph={memoryUsedDash}
              data={topByMem2}
              language={language}
            />
          </Grid>

          <Grid container spacing={3} className='mt-3'>
            <Grid item xs={12}>
              <Card style={{ overflow: 'visible' }}>
                <div className='content pb-0 pt-3'>
                  <Chip
                    avatar={
                      <Avatar className='avatar'>
                        <i className='flaticon-data-storage4' />
                      </Avatar>
                    }
                    className='chip-header shadown'
                    label={language.DISK}
                  />
                </div>
                <hr />
                <div className='content'>
                  <Row className="row summaryGroup">
                    {disks.length ? (
                      disks
                    ) : (
                      <Col md={12} className="text-center">
                        {language.LOAD}
                      </Col>
                    )}
                  </Row>
                </div>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card style={{ overflow: 'visible' }}>
                <div className='content pb-0 pt-3'>
                  <Chip
                    avatar={
                      <Avatar className='avatar'>
                        <i className='flaticon-computers3' />
                      </Avatar>
                    }
                    className='chip-header shadown'
                    label={language.NETWORK}
                  />
                </div>
                <hr />
                <div className='content'>
                  <Row className="row summaryGroup">
                    {network.length ? (
                      network
                    ) : (
                      <Col md={12} className="text-center">
                        {language.LOAD}
                      </Col>
                    )}
                  </Row>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </PageLayout>
    );
  }

  getConnectionDevice(host, tokenApi) {
    action
      .execute('get', `${host}/api/device/${this.props._device.id_device}`, '')
      .then(data => {
        if (data.data[0].connection == 'ssh') {
          typeDevice = 'linux';
        } else {
          typeDevice = 'windows';
        }
      });
  }

  fetchAll(host) {
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=mem-info`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length) {
          let memoryTemp = JSON.parse(data[0].values_info);
          let memoryUsedTempS = String(memoryTemp.usedPercent).replace(',', '.');
          let memoryUsedTempI = memoryUsedTempS * 100;
          let memoryUsedDash = (memoryTemp.actualUsed / memoryTemp.total) * 100;
          this.setState({
            memory: mem(memoryTemp.total),
            memoryUsed: mem(memoryTemp.actualUsed),
            memoryUsedDash: memoryUsedDash
          });
        }
      });

    // Fetch cors
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=cpu-list`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length) {
          let corsTemp = JSON.parse(data[0].values_info);
          this.setState({ cors: corsTemp.length });
        }
      });

    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=cpu-stats`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length != 0) {
          let cp = JSON.parse(data[0].values_info);
          let cpuUsedDash = cp.usedPercent;
          this.setState({ cpuUsedDash: cpuUsedDash });
        }
      });

    // Fetch uptime
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=sys-uptime`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length) {
          let uptimeTemp = JSON.parse(data[0].values_info);
          var uptimes = (uptimeTemp.uptime + "").toHHMMSS();
          this.setState({ uptime: uptimes });
        }
      });

    // Fetch proc-stats
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=proc-stats`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length) {
          let procStatsTemp = JSON.parse(data[0].values_info);
          let topByCpuTemp = 0;
          let topByMemTemp = 0;
          try {
            procStatsTemp.topByCpu.map(
              (tcpu, key) => (topByCpuTemp += parseFloat(tcpu.cpuPerc.toFixed(1)))
            );

            procStatsTemp.topByMem.map(
              (tcpu, key) => (topByMemTemp += parseFloat(tcpu.memPerc.toFixed(1)))
            );
          } catch (e) { }
          try {
            this.setState({
              procStats: procStatsTemp.total,
              valuesTop: procStatsTemp,
              cpuPerc: topByCpuTemp.toFixed(1),
              memoryUsedPercent: parseInt(topByMemTemp)
            });
          } catch (e) { }
        }
      });

    // Fetch disk
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=fs-list`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length) {

          let diskTemp = JSON.parse(data[0].values_info);
          this.setState({ disk: diskTemp });
        }
      });

    // Fetch netStats
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=net-list-stats`, '')
      .then(data => {
        if( data.data ) {
          data = data.data;
        }

        if (data.length) {
          let netStatsTemp = JSON.parse(data[0].values_info);
          let netStatsTxTemp = netStatsTemp[0].values.TX;
          let netStatsRxTemp = netStatsTemp[0].values.RX;
          this.setState({
            netStats: netStatsTemp,
            netStatsRx: netStatsRxTemp,
            netStatsTx: netStatsTxTemp
          });
        }
      });

    let hold = setTimeout(
      () => {
        this.fetchAll(host, tokenApi);
      },
      3000,
      this
    );

    this.setState({ hold: hold });
  }
}
