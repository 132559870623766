import React, { Component } from 'react';
import './solicitant.css';
import { Card } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '../../common/CommonForm';
import CommonTest1 from '../../common/CommonTest1';

import RaisedButton from '../../common/RaisedButton';
import AttachFile2 from '../../common/AttachFile2';
export default class SolicitantPage extends CommonForm {


  render() {
    let { handlePage, language, onOpenSolicitation, onDrop } = this.props;

    let fields = [
      { span: 4, name: 'email', label: language.EMAIL, type: 'text', required: true },
      { span: 4, name: 'subject', label: language.SUBJECT, type: 'text', required: true },
      { span: 4, name: 'id_access_remote', label: language.IDREMOTE, type: 'text', required: false },
      { span: 12, name: 'description', label: language.DESCRIPTION, type: 'textEditor' }
    ];

    let buttons = { md: 12, label: language.SAVE, primary: true }


    // The prop `button` is marked as required in `CommonForm`, but its value is `undefined
    let data = [];

    try {
      Array.from(this.props.files).forEach(g => {

        data.push(
          <Row>
            <Col md={0.1}>
              <div className='trashIcon pointer' onClick={() => this.props.removeFile(g)}
                style={{ width: 25, height: 25 }}> </div>
            </Col>
            <Col md={3}>
              {g.name}
            </Col>
          </Row>

        )
      })
    } catch (d) {

    }
    return (
      <Container fluid id='SolicitantPage'>

        <Row>
          <Col md={10} offset={{ md: 1 }} className='py-4 d-flex align-items-center justify-content-center'>
            <img src={require('../../../public/images/newLogoSensr.png')} style={{ width: 200 }} />
          </Col>
        </Row>
        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <RaisedButton
              circleButton
              icon='fas fa-arrow-left'
              onClick={() => handlePage('login')}
              label={language.CANCEL}
              style={{ marginRight: '0.5em' }}
            />
            <br></br>
            <Card>
              <h2 style={{ padding: 20, color: '#030235', textAlign: 'center' }}>{language.SOLICITATION_FORM}</h2>

              <div style={{ padding: '0 29px', paddingTop: 10 }}>

                <AttachFile2
                  label={language.ATTACH}
                  accept=".zip, .7Z, .json, .VBS, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar, .msg, audio/*, image/*"

                  subLabel={language.DRAG_THE_FILE}
                  onRemove={(f) => this.props.removeFile(f)}
                  files={this.props.files}
                  onDrop={onDrop}
                />
              </div>

              <CommonForm
                language={language}
                fields={fields}
                button={buttons}
                onFormSubmit={onOpenSolicitation}
                buttons={[
                  <RaisedButton
                    circleButton
                    icon='fas fa-arrow-left'
                    onClick={() => handlePage('login')}
                    label={language.CANCEL}
                    style={{ marginRight: '0.5em' }}
                  />
                ]}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
