import React, { useReducer } from 'react';
import './index.css';
import { observer } from 'mobx-react';

// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import Header from '@common/Header';
import Button from '@common/Button';
import SearchInput from '@common/SearchInput';
// --------------------------------material-ui----------------------------------------------------
import { Card } from '@material-ui/core';
// --------------------------------pages----------------------------------------------------
import Block_1 from './Block_1';
import Block_2 from './Block_2';
import Options from './options';
import { Tooltip } from 'react-tippy';
import OptionsActivity from '../../Projects/ProjectsList/options';
import Constants from '@data/Constants';
import moment from 'moment';

@observer
export default class extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      dt_i: undefined,
      dt_f: undefined,
      solicitant: null,
      type: null,
      manager: null,
      company: null,
      status: null,
      formRefs: null,
    };

    this.cleanValues = this.cleanValues.bind(this);
  }
  cleanValues() {
    let formRefs = this.state.formRefs;
    formRefs.getFields().map((e) => {
      formRefs.handleValue(0, e.name, '');
    });

    formRefs.getFields().map((e) => {
      formRefs.handleValue(0, e.name, 0);
    });

    formRefs.getFields().map((e) => {
      formRefs.handleValue(0, e.name, '');
    });
  }

  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/pmo',
      '$get-pmos,$get-projectsDeletedCount,$get-pmosDeleted,$get-projectsCanceled'
    );
  }

  render() {
    let { language, projectsDeletedCount, users, companies, pmosDeleted,projectsCanceled } =
      this.store;
    let { dt_i, dt_f, solicitant, type, manager, company, status } = this.state;
    let { PMO } = this.store.theme;

    let pmos = this.store.pmos.filter((e) => {
      let errors = [];

      if (dt_i) {
        errors.push(moment(e.start_date).isSameOrAfter(moment(dt_i), 'day'));
        pmosDeleted = pmosDeleted.filter((p) =>
          moment(p.start_date).isSameOrAfter(moment(dt_i), 'day')
        );
      }

      if (dt_f) {
        errors.push(moment(e.end_date).isSameOrBefore(moment(dt_f), 'day'));
        pmosDeleted = pmosDeleted.filter((p) =>
          moment(p.end_date).isSameOrBefore(moment(dt_f), 'day')
        );
      }

      if (status > 0) {
        status == 1
          ? errors.push(e.percent == null || e.percent < 100)
          : status == 2
          ? errors.push(e.utils.isDelay == true)
          : status == 3
          ? errors.push(e.percent == 100)
          : status == 4
          ? errors.push(e.utils.concludedDelay == true)
          : status == 5
          ? errors.push(e.utils.inExecution == true && e.percent < 100)
          : status == 6
          ? errors.push(e.utils.paused == true && e.percent < 100)
          : '';
      }

      if (type) {
        errors.push(e.type_project == type);
      }

      if (manager) {
        errors.push(e.user_cad == manager);
      }

      if (company) {
        errors.push(e.fk_id_company == company);
      }

      return errors.every(Boolean);
    });

    let options = Options({
      projects: this.store.pmos,
      users,
      language,
      companies,
    });

    return (
      <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
        <PageLayout
          title={language.PANEL_PMO}
          subTitle={`${language.PROJECT_MANAGEMENT} /`}
          rightElements={[
            <div
              style={{ width: 1200, zoom: 0.8, marginTop: '1rem' }}
              zoom={0.8}
              className="mr-2"
            >
              <CommonForm
                onRef={(ref) => this.setState({ formRefs: ref })}
                individual
                values={{
                  dt_i,
                  dt_f,
                  status,
                  solicitant,
                  type,
                  manager,
                  company,
                }}
                fields={[
                  {
                    col: 2,
                    type: 'date',
                    name: 'dt_i',
                    label: language.INITIAL_DATE,
                  },

                  {
                    col: 2,
                    type: 'date',
                    name: 'dt_f',
                    label: language.FINAL_DATE,
                  },
                  {
                    col: 1.5,
                    type: 'select',
                    name: 'status',
                    label: language.STATUS,
                    options: [
                      { value: 1, label: language.TICKET_STATUS.OPEN },
                      { value: 2, label: language.DELAYED },
                      { value: 3, label: language.CONCLUDED },
                      { value: 4, label: language.COMPLETED_LATE },
                      { value: 5, label: language.RUNNING },
                      { value: 6, label: language.PAUSED },
                      { value: 0, label: language.ALL },
                    ],
                  },

                  {
                    col: 1.5,
                    type: 'select',
                    name: 'type',
                    label: language.TYPE,
                    options: options.type(),
                  },
                  {
                    col: 2.5,
                    type: 'autocomplete',
                    name: 'manager',
                    label: language.MANAGE,
                    textlabel: language.MANAGE,
                    className: 'tech-group-ticket',
                    method: 'POST',
                    route: `${Constants.APIEndpoints.USER}/filter/notsolicitant?`,
                    routeGetCount: `${Constants.APIEndpoints.USER}/active/count/notsolicitant`,
                    fieldquery: 'search',
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    idinit: manager,
                    routeAll: `${Constants.APIEndpoints.USER}/filter/notsolicitant?`,
                    detail: true,
                    methodGetAll: 'POST',
                    textlabel: `Gestor`,
                    filterAll: { manager: true },
                  },
                  {
                    col: 2,
                    type: 'select',
                    name: 'company',
                    label: language.COMPANY,
                    options: options.company(),
                  },
                  {
                    col: 0.5,
                    type: 'element',
                    content: (
                      <Button
                        icon="fas fa-eraser"
                        label={language.RESET}
                        color="primary"
                        variant="circle"
                        style={{ marginTop: '1rem' }}
                        onClick={() => this.cleanValues()}
                      />
                    ),
                  },
                ]}
                onChangeField={(f, v) => {
                  this.setState({ [f.name]: v });
                }}
              />
            </div>,
          ]}
        >
          <div id="PMO">
            <Block_1
              redirect={this.redirect2}
              language={language}
              options={options}
              pmos={pmos}
              users={users}
              projectsCanceled={projectsCanceled}
              themes={PMO}
              projectsDeletedCount={projectsDeletedCount}
              pmosDeleted={pmosDeleted}
            />
            <Block_2
              redirect={this.redirect2}
              language={language}
              options={options}
              pmos={pmos}
              users={users}
              dateInitial={dt_i}
              dateEnd={dt_f}
            />
          </div>
        </PageLayout>
      </CommonPage>
    );
  }
}
