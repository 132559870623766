import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import PageLayout from '@common/PageLayout';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import './DeviceConf.css';
import {
  SelectField,

} from '@material-ui/core';
import {
  Step,
  Stepper,
  StepLabel,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem
} from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '../../common/CommonForm';
import CommonTable from '../../common/CommonTable';
import CommonPageNotLoad from '../../common/CommonPageNotLoad';
import ServerOid from './ServerOid'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' }
  },
});

export default class DeviceConf extends CommonPageNotLoad {
  constructor() {
    super();
    this.state = {
      value: '',
      finished: false,
      stepIndex: 0,
      type: '',
      conf: '',
      confTriggers: '',
      removeloading: true,
      oids: [],
      oids_desc: [],
      graphs: [],
      positions: [],
      arrayGraphs: [],
      warnings: [],
      dangers: [],
      arrayNormal: [],
      load: true

    };
    this.handleOid = this.handleOid.bind(this);
    this.handlePosition = this.handlePosition.bind(this);
    this.saveItens = this.saveItens.bind(this);
  }
  handleOid = (id, valor) => {

    let graphs = this.state.graphs;
    graphs[id] = valor



    this.setState({ graphs, load: false })
  };


  handleWarning = (id, valor) => {

    let warnings = this.state.warnings;
    warnings[id] = valor



    this.setState({ warnings, load: false })
  };


  handleDanger = (id, valor) => {

    let dangers = this.state.dangers;
    dangers[id] = valor



    this.setState({ dangers, load: false })
  };


  saveItens() {

    let values = {
      type: this.state.type,

      fk_id_brands: this.props.id_brands,
      positions: this.state.positions,
      oids: this.props.oids.map(g => g.id),
      graphs: this.state.graphs,
      warnings: this.state.warnings,
      dangers: this.state.dangers,
      fk_id_device: this.props.id_device
    }

    this.action.execute(
      'post',
      this.APIEndpoints.SNMP + '/savemonitored',
      'oids',
      values
    );

    this.redirect('/dash/monitor')

  }

  componentDidMount() {

  }
  handlePosition = (id, valor) => {
    let arrayGraphs = this.state.arrayGraphs;

    if (this.state.graphs[id] != null) {


      arrayGraphs.push({ name: this.state.graphs[id], position: valor })



    }

    let positions = this.state.positions;


    var index = positions.indexOf(valor);
    if (index > -1) {

      positions[index] = 10
      //splice(index, 1);
    }

    positions[id] = valor
    this.setState({ positions, arrayGraphs, load: false })

  };




  render() {

    let {
      language,
      handlePage,
      devices,
      onConfSubmit,
      editConf,
      store
    } = this.props;

    let listTemplate = [];
    let listPosicao = [];
    let listwarnings = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(f => <MenuItem
      style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
      key={f}
      value={f}
      primaryText={f}
    >
      {f}
    </MenuItem>)





    listTemplate.push(<MenuItem
      style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
      key={'circle'}
      value={'circle'}
      primaryText={'circle'}
    >
      {'circle'}
    </MenuItem>

    )
    listTemplate.push(<MenuItem
      style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
      key={'sparkline'}
      value={'sparkline'}
      primaryText={'sparkline'}
    >
      {'sparkline'}
    </MenuItem>)




    listPosicao = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(f => <MenuItem
      style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
      key={f}
      value={f}
      primaryText={f}
    >
      {f}
    </MenuItem>)
    /**"id": 286,
          "fk_id_brands": 9,
          "device_oids": "9.8.1.4.6.5.2.4.9",
          "oid_description": "<p>testando </p>",
          "created_at": "2019-12-10T16:15:24.000Z",
          "updated_at": "2019-12-10T16:15:24.000Z",
          "active": null */
    if (this.state.load) {

      this.props.oids.map(g => {
        this.state.graphs[g.id] = g.graph;
        this.state.positions[g.id] = g.position;
      })

    }




    let col = [{
      label: 'Oids',
      key: 'oid',
      style: { width: 1, paddingRight: 0, textAlign: 'left' },
      thConfig: true
    },
    {
      label: 'Description',
      key: 'oid_description',
      style: { width: 1, paddingRight: 0, textAlign: 'left' },

      thConfig: true
    },
    {
      label: 'Graph',
      key: 'typeGraph',
      style: { width: 1, paddingRight: 0, paddingLeft: 0 },
      thConfig: true
    },
    {
      label: 'Graph',
      key: 'position',
      style: { width: 1, paddingRight: 0, paddingLeft: 0 },
      thConfig: true
    }


    ]

    return (
      <PageLayout
        icon='configurationsIcon'
        title={language.CONFIG}
        rightElements={[

          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            onClick={() => handlePage('monitor')}
            label={language.RETURN}
            label={language.RETURN}
            color='primaryGradient'
          />
        ]}
      >
        <Container fluid id='DeviceConf'>
          <div style={{ width: 200, margin: 'auto' }}  >

            <ServerOid arrayGraphs={this.state.arrayGraphs}  ></ServerOid></div>
          <MuiThemeProvider theme={theme}>

            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Oids</TableCell>
                  <TableCell align="right">Descrição</TableCell>
                  <TableCell align="right">Graph</TableCell>
                  <TableCell align="right">Posição</TableCell>
                  <TableCell align="right">Warning</TableCell>
                  <TableCell align="right">Danger</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.oids.map(t => (
                  <TableRow  >
                    <TableCell component="th" scope="row">
                      {t.device_oids}
                    </TableCell>
                    <TableCell align="right">{t.oid_description}</TableCell>

                    <TableCell align="right">{

                      <TextField
                        id="outlined-select-currency"
                        select

                        style={{ minWidth: 100, marginRight: '1em' }}
                        value={this.state.graphs[t.id] || t.graph}
                        onChange={(event, key, values) =>
                          this.handleOid(t.id, event.target.value)
                        }


                      >
                        {listTemplate}
                      </TextField>

                    }</TableCell>
                    <TableCell align="right">{<TextField
                      id="outlined-select-currency"
                      select
                      disabled={!(this.state.graphs[t.id] || t.graph)}
                      style={{ minWidth: 100, marginRight: '1em' }}
                      value={this.state.positions[t.id] || t.position}
                      onChange={(event, key, values) =>
                        this.handlePosition(t.id, event.target.value)
                      }


                    >
                      {listPosicao}
                    </TextField>}</TableCell>




                    <TableCell align="right">{<TextField
                      id="outlined-select-currency"
                      select
                      disabled={!(this.state.graphs[t.id] || t.graph)}
                      style={{ minWidth: 100, marginRight: '1em' }}
                      value={this.state.warnings[t.id] || t.warning}
                      onChange={(event, key, values) =>
                        this.handleWarning(t.id, event.target.value)
                      }


                    >
                      {listwarnings}
                    </TextField>}</TableCell>


                    <TableCell align="right">{<TextField
                      id="outlined-select-currency"
                      select
                      disabled={!(this.state.graphs[t.id] || t.graph)}
                      style={{ minWidth: 100, marginRight: '1em' }}
                      value={this.state.dangers[t.id] || t.danger}
                      onChange={(event, key, values) =>
                        this.handleDanger(t.id, event.target.value)
                      }


                    >
                      {listwarnings}
                    </TextField>}</TableCell>


                  </TableRow>
                ))}



              </TableBody>
            </Table>



            <RaisedButton
              label={language.SAVE}
              primary={true}
              style={{ marginRight: 10 }}
              onClick={() => this.saveItens()}
            />

          </MuiThemeProvider>
          <div className='d-flex justify-content-end mt-2'>

          </div>

        </Container>
      </PageLayout>
    );
  }
}
