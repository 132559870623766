import React, { Component } from 'react';
import RaisedButton from '../../common/RaisedButton';
import { Row, Col } from 'react-grid-system';
import { Radial } from 'sensrit-charts';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import MonitorUtils from '../../util/MonitorUtils';
import ReactHighcharts, { Highcharts } from 'react-highcharts';
import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import moment from 'moment';
import { Grid, Card, Avatar, Chip } from '@material-ui/core';
import PageLayout from '@common/PageLayout';
import ListColumn from '@common/ListColumn';
import List from '@common/List';
import AppBar from '@common/AppBar';
import './Summary.css';
import Actions from '@data/Actions';

const action = new Actions();

import { Call_x_Tech } from './Graph';
import { Tooltip } from 'react-tippy';
let host = '';
let tokenApi = '';
let typeDevice = '';
String.prototype.toHHMMSS = function () {
  var cur = parseInt(this, 10); // don't forget the second param

  let days = Math.floor(cur / 86400);
  cur -= days * 86400;
  let hours = Math.floor(cur / 3600) % 24;
  cur -= hours * 3600;
  let minutes = Math.floor(cur / 60) % 60;
  cur -= minutes * 60;
  let seconds = Math.floor(cur) % 60;

  var time = days + ' Dias ' + hours + ' Horas ' + minutes + ' Minutos ';
  return time;
};

export default class Summary extends CommonPage {
  constructor() {
    super();
    this.state = {
      available: false,
      mount: true,
      hold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disks: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      valuesTop: [],
      data: [],
      legends: [],
    };
  }

  componentWillMount() {
    host = Constants.APIRoot;
    tokenApi = this.store.getToken();
    this.getConnectionDevice(host, tokenApi);
    this.fetchAll(host, tokenApi);
  }

  componentWillUnmount() {
    this.state.mount = false;
    clearTimeout(this.state.hold);
  }

  render() {
    let { _device, language, handlePage } = this.props;
    let {
      values,
      uptime,
      cpuPerc,
      cors,
      memoryUsedPercent,
      memoryUsed,
      memory,
      procStats,
      disk,
      netStats,
      netStatsTx,
      netStatsRx,
      valuesTop,
      memoryUsedDash,
      cpuUsedDash,
      redirect,
    } = this.state;

    return (
      <PageLayout
        icon="monitorIcon"
        title={'Últimas horas'}
        rightElements={[
          <RaisedButton
            circleButton
            icon="fas fa-arrow-left"
            onClick={() => handlePage('dash')}
            label={language.RETURN}
            color="primaryGradient"
          />,
        ]}
      >
        <div id="Summary" className="pt-3">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card style={{ overflow: 'visible' }}>
                <div className="content pb-0">
                  <Chip
                    avatar={<Avatar className="avatar"></Avatar>}
                    className="chip-general-info shadown"
                    label={language.GENERAL_INF}
                  />
                </div>
                <hr />
                <div className="content">
                  <ListColumn
                    col={4}
                    items={[
                      {
                        label: language.HOST,
                        value: _device.name.toUpperCase(),
                      },
                      {
                        label: language.IP,
                        value:
                          _device.ip + (_device.port ? ':' + _device.port : ''),
                      },
                    ]}
                  />
                  <br></br>
                  <ReactHighcharts
                    config={Call_x_Tech({
                      categories: this.state.legends,
                      data: [
                        {
                          name: _device.name,
                          color: 'var(--primary)',
                          data: this.state.data,
                        },
                      ],
                      currentMonth: '',
                    })}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </PageLayout>
    );
  }

  getConnectionDevice(host, tokenApi) {
    action
      .execute('get', `${host}/api/device/${this.props._device.id_device}`, '')
      .then((data) => {
        if (data.data[0].connection == 'ssh') {
          typeDevice = 'linux';
        } else {
          typeDevice = 'windows';
        }
      });
  }

  fetchAll(host) {
    action
      .execute('get', `${host}/api/monitor/device/${this.props._device.id_device}?type=link-info`, '')
      .then((data) => {
        if (data.data.length != 0) {
          let i = data.data.map((v) => moment(v.dt_cad).format('HH:mm:ss'));
          let e = data.data.map((v) => parseFloat(v.values_info));

          this.setState({ legends: i, data: e });
        }
      });

    this.fetchAll(host, tokenApi);
  }
}
