import React from 'react';
import './styles.css';

export default function(props){
	let {current=0, symbol=true, barProps={}, textProps={}, ...other} = props;
	
	return(
		<div className='progress-line' {...other}>
		  <div {...barProps}>
		    <div style={{left:current+'%'}}></div>
		  </div>
		  <span 
		  	{...textProps}
		  	style={{
		  		color:current<=32?'#6c757d':current>=62?'#6c757d':'#6c757d',
		  		...textProps.style
		  	}}
		  >
		  	{current+(symbol?'%':'')}
		  </span>
		</div>
	)
}