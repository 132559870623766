import Constants from '@data/Constants';
import moment from 'moment';
import { addTimes } from '@common/Func';

function photo(e, users) {

  if (e.usersphoto) {
    let _users = (e.usersphoto || []).map((e) => {
      let photo =
        e.photo &&
        `${Constants.APIRoot}/public/users/${e.id_user}/profileLogo.png`;
      return {
        src: photo || undefined,
        name: e.name_user || ''
      };
    });

    return _users;
  } else {
    let photo =
      e.photo &&
      `${Constants.APIRoot}/public/users/${e.fk_id_user}/profileLogo.png`;

    return [{
      src: photo || undefined,
      name: (users.find((u) => u.id_user == e.fk_id_user) || {}).name || '',

    }];
  }
}

let status = (selected, initialized) => {
  if (initialized) {
    return 'Fazendo';
  } else if (selected) {
    return 'Concluída';
  } else {
    return 'A fazer';
  }
};

export function viewActivity({ pj, e, users, options, subActivities }) {
  return {
    Block1props: {
      description: e.description,
    },

    Block2props: {
      avatares: photo(e, users),
      tags: (e.tagac || []).map((e) => ({ label: e.name, color: e.color })),
    },

    Block3props: {
      id_project_activity: e.id_project_activity,
      id_project: pj.id_project,
      data: (subActivities || []).map((e) => ({
        ...e,
        username: e.responsible,
        text: e.name,
        time: e.runtime, //'4:23:00'
        status: status(e.selected, e.initialized),
      })),
    },
    Block4props: {
      values: (e.file || []).map((e) => ({
        id_project_activity: e.id_project_activity,
        label: e.file,
        file: e.file,
        id_gp_file: e.id_gp_file,
        size: e.size,
        type: e.type,
      })),
    },
    Block5props: {
      fk_id_project_activity: e.id_project_activity,
      fk_id_project: pj.id_project,
    },
    Block6props: {
      data: (e.comments || []).map((e) => ({
        name: e.name_create,
        src: undefined,
        date: moment(e.dt_cad).format('LLL'),
        text: e.description,
      })),
    },
    Block_7: e.description_history,
    PredecessorActivities: {
      id_project_activity: e.id_project_activity,
      fk_id_project: pj.id_project,
      values: {
        activityprd: (e.activityprd || []).map((e) => e.id_project_activity),
      },
    },
    nav: {
      company: options.getOptions('company', pj.fk_id_company),
      priority: e.priorization,
      weight: e.weight,
      dt_i: moment(e.dt_start).format('L'),
      dt_f: moment(e.dt_end).format('L'),
      late: moment().isAfter(e.dt_end),
      realized: (e.sum_hour || '')
        .split(',')
        .reduce((n, e) => addTimes(n, e), '00:00'),
      effort: e.hours+ ':' + (e.minutes || '00').toString().padStart(2, '0'),
      percent: parseInt(e.percent),
      time_in_days : e.time_in_days,
    },
  };
}
export function jsonFormatGanttTable(e, users) {
  return {
    id: e.id_project_activity,
    real_id: e.real_id,
    title: e.name,
    predecessors_activitys: (e.activityprd || []).map(
      (e) => e.id_project_activity
    ),
    sponsor: photo(e, users),
    evolution: parseInt(e.percent),
    planned_start: moment(e.dt_start).format('L'),
    realized_start: e.dt_start_realized ? moment(e.dt_start_realized).format('L') : '',
    planned_end: moment(e.dt_end).format('L'),
    realized_end:
      e.dt_up && parseInt(e.percent) == 100
        ? moment(e.dt_up).format('L')
        : '',
    planned_duration: `${Math.ceil(e.hours / 24)} dias`,
    realized_duration:  e.dt_up && parseInt(e.percent) == 100 && e.dt_start_realized ? `${moment(e.dt_up).diff(moment(e.dt_start_realized), 'days')} dias` :'',
  };
}
export function jsonFormatGantt(e, users) {
  return {
    label: e.name,
    dt_i: e.dt_start,
    dt_f: e.dt_end,
    percent: parseInt(e.percent),
    color: moment().isAfter(e.dt_end) ? 'var(--danger)' : 'var(--primary)',
    tags: (e.tagac || []).map((e) => ({
      label: e.name,
      color: e.color,
      id_project_tag: e.id_project_tag,
    })),
    avatares: photo(e, users),
  };
}

export function jsonFormatKanban(e, users) {
  return {
    id: e.id_project_activity, //importante colocar o id da atividade aqui, é a key do loop e o id do drag-drop
    real_id: e.real_id,
    avatares: photo(e, users),
    tags: (e.tagac || []).map((e) => ({
      name: e.name,
      color: e.color,
      id_project_tag: e.id_project_tag,
    })),
    name: e.name,
    dt: moment(e.dt_end).format('L'),
    dt_end: e.dt_end,
    commentsTotal: (e.comments || []).length,
    filesTotal: (e.file || []).length,
    percent: parseInt(e.percent),
    percentColor: '#f05e02',
    id_project_activity: e.id_project_activity,
    dt_start: e.dt_start,
    sprint:
      e.sprint &&
      e.sprint.map((e) => ({ id: e.id_project_sprint, name: e.name })),
    activity: e,
  };
}
