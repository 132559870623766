import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import {MaskMoney, MaskNumber} from '@common/Mask';
import {FormatMoney} from '@common/Format';
import Menu from '@material-ui/core/Menu';
// ------------------------------icons-----------------------------------------
import SlowIcon from '@images/svg-icon/SlowIcon';
import HightIcon from '@images/svg-icon/HightIcon';
import CriticIcon from '@images/svg-icon/CriticIcon';
import './index.css';

export default function({
  open,
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  language,
  users,
  contracts,
  usercon_company,
  companies,
  ticketparam,
  onFormSubmit
}) {
  // -------------------------------------options---------------------------------------------
  var companyOptions = companies.map(obj => ({value: obj.id_company, label: obj.name}));
  // console.log(store.getUserLoged())
  
      if (store.getUserLoged().role == 'solicitant') {
        companyOptions = companies.filter(b=>b.id_company ==
          store.getUserLoged().id_company
          ).map(obj => ({value: obj.id_company, label: obj.name}));
      }
        

  let [company, setCompany] = useState('');
  let [user, setUser] = useState(0);
  let [type_project, setType_project] = useState(0);
  let [modalTicket, setmodalTicket] = useState(false);
  let [idcontract, setIdcontract] = useState(0);
  let [refForm, setRefForm] = useState(null);
  let [refFormName, setRefFormName] = useState(null);
  let [refFormSponsor, setRefFormSponsor] = useState(null);
  let [msn, setMsn] = useState(null);
  let [tickets, setTickents] = useState([]);
  let [disableButton, setDisableButton] = useState(false);
  let [form, setForm] = useReducer((state, value)=> ({...state, ...value}), {})
 
  let [solicitantOptions, setSolicitantOptions] = useState([]);
  useEffect(()=>{
    setSolicitantOptions(users.filter(g=>g.deleted==false && g.status==true).map(u =>({ value: u.id_user, label: u.name +' - '+u.email  })))
   
    if(values){
      setForm({name:values.name, fk_id_sponsor:values.fk_id_sponsor, type_project:values.type_project, type_risk:values.type_risk});
      if(values){
         
       // setIdcontract( values.fk_id_contract );
      action.execute(
        'post',
        APIEndpoints.REQUESTS + '/api/ticketchange',
        'tickets',
        { id_contract: values.fk_id_contract } 
      ).then(h=>{
        setTickents(h.data)
      })  
       
      }     
    }
     
  




  }, [values])

 //console.log(values);



  function onSubmit(v){
    var error = null;
    if (!form.type_project) error = language.SELECT_A_PROJECT_TYPE;
    if (!form.type_risk) error = language.SELECT_A_RISK_TYPE;
    if(!form.name) error=true;
    if(!form.fk_id_sponsor) error=true;
 
    if(error && (typeof error)=='string'){
      store.toggleAlert(true, error, 'error');
    }else{
      if(!error){
         setDisableButton(true);
        var data = {...v, ...form};
        onFormSubmit(data);
      }
    }

  }

  company = values ? values.fk_id_company : company;

  let contract = (values||{}).fk_id_contract||0;

  if (idcontract > 0) {
    contract = idcontract;
    try {
      // values.fk_id_contract = idcontract;
    } catch (e) {}
  }

  if (store.getUserLoged().role == 'tech') {
    try {
      companyOptions = companies
        .filter(g =>  usercon_company[0].company.includes(g.id_company))
        .map(c => ({
          value: c.id_company,
          label: c.name
        }));
    } catch (t) {
      companyOptions = [];
    }
  }
  
  const onChangeField = (field, value) => {
    
    if (field.name === 'fk_id_company') {
      setCompany(value );
    } else if (field.name === 'user_cad') {
      setUser(value);
    } else if (field.name === 'type_project') {
      setType_project( value );
    } else if (field.name === 'tickets') {
      setmodalTicket(true);
      let userCod =   tickets.filter(h=>h.id_tickets  == value[value.length-1] )
      .map(j=>j.fk_id_user)
       try{
      setMsn(users.filter(b=>b.id_user  ==userCod[0])[0].name)
       }catch(e){}
      
    } else if (field.name === 'fk_id_contract') {
      setIdcontract( value );
      action.execute(
        'post',
        APIEndpoints.REQUESTS + '/api/ticketchange',
        'tickets',
        { id_contract: value } 
      ).then(h=>{
        setTickents(h.data)
      })
    }
  }

  let contractOptions = [];
  if (values) {
    if (values.fk_id_company == 1) {
      if (store.getUserLoged().role == 'tech') {
        try {
          contractOptions = contracts
            .filter(g => store.usercon_company[0].contract.includes(g.id_contract))
            .map(c => ({
              value: c.id_contract,
              label: c.name
            }));
        } catch (t) {
          contractOptions = [];
        }
      }

      contractOptions = contracts
        .filter(c => c.fk_id_company === company)
        .map(ctr => ({ value: ctr.id_contract, label: ctr.name }));
    } else {
      contractOptions = contracts
        .filter(c => c.fk_id_out_company === company)
        .map(ctr => ({ value: ctr.id_contract, label: ctr.name }));
    }
  } else {
    if (companyOptions == 1) {
      contractOptions = contracts
        .filter(c => c.fk_id_company === company)
        .map(ctr => ({ value: ctr.id_contract, label: ctr.name }));
    } else {
      contractOptions = contracts
        .filter(c => c.fk_id_out_company === company)
        .map(ctr => ({ value: ctr.id_contract, label: ctr.name }));
    }
  }

  let departmentOptions = [];
  if (values && values.fk_id_company) {
    company = values.fk_id_company;
  }
  if (company && !!companies.length) {
    departmentOptions = [];
    companies.forEach(g => {
      g.department.forEach(obj => {
        if (!departmentOptions.find(e => e.value == obj.id_department)) {
          departmentOptions.push({
            value: obj.id_department,
            label: obj.name
          });
        }
        if (obj.sub_dep.length) {
          obj.sub_dep.forEach(sd => {
            if (!departmentOptions.find(e => e.value == sd.id_department)) {
              departmentOptions.push({
                value: sd.id_department,
                label: sd.name
              });
            }
          });
        }
      });
    });
  }

  let ticketsList = [];

  ticketsList =
    tickets.length > 0 &&
   tickets
      .filter(g => g.fk_id_contract === contract)
      .map(u => ({
        value: u.id_tickets,
        label: u.realid + ' - ' + u.subject
      })); 

  let optionticket = [];
 

  return (
    <Dialog
      PaperProps={{
        id: 'ProjectForm'
      }}
      open={open}
      onClose={onClose}
      bar={false}
      
      dialogChildren={
        <div
          className="d-flex align-items-start justify-content-between bar"
          style={{
            background: `url(${BarBlue})`,
            borderBottom: '3px solid var(--warning)'
          }}
        >
  {/*##############################################CABEÇALHO####################################################################*/}
          <div className="pt-3 pb-1 pl-3 w-100">
            <div className="d-flex align-items-start">
              <h4 className="mr-3 id-ticket"></h4>
              <div className="w-100" style={{ zoom: 0.8 }} zoom={0.8}>
                <CommonForm
                  onRef={e=>setRefFormName(e)}
                  values={{name:form.name}}
                  fields={[
                    {
                      type: 'text',
                      name: 'name',
                      label: language.PROJECT_NAME,
                      className: 'project-name',
                      required:true
                    }
                  ]}
                  
                  onChangeField={(f, v) => setForm({name:v})}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="tech-title">{language.INVOLVED_TECH}:</span>
                <div className="list-tech-avatares d-flex align-items-center">
                  
                  {/*<Avatar
                    style={{ cursor: 'pointer' }}
                    className="avatar mx-1"
                    
                  >
                    {'Rodrigo'.substr(0, 2)}
                  </Avatar>*/}
                
                </div>
              </div>
              <div
                style={{
                  width: 275,
                  position: 'relative',
                  top: 7,
                  zoom: 0.8
                }}
                zoom={0.8}
              >
                <CommonForm
                  onRef={e=>setRefFormSponsor(e)}
                  values={{fk_id_sponsor:form.fk_id_sponsor}}
                  onChangeField={(f, v) => setForm({fk_id_sponsor:v})}
                  fields={[
                    {
                      span: 4,
                      type: 'select',
                      name: 'fk_id_sponsor',
                      label: language.RESPONSIBLE_FOR_APPROVAL,
                      className: 'sponsor',
                      options: solicitantOptions,
                      required:true
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      }
    >
  {/*##############################################CORPO####################################################################*/}
    {/*----------------------------------------------TIPO DE PROJETO--------------------------------------------------------------------*/}
      <div className="d-flex justify-content-center">
        <div>
          <span
            style={{
              fontSize: 10,
              color: 'var(--primary)',
              fontWeight: 'bold'
            }}
          >
            {language.TYPE_PROJECT}
          </span>
          <div className='d-flex align-items-center btn-group'>
            {[
              { value: 3, icon: 'fas fa-tools', label: language.CORRECTIVE },
              { value: 1, icon: 'fas fa-chess', label: language.STRATEGIC },
              { value: 4, icon: 'fas fa-chart-line', label: language.EVOLUTIONARY },
              { value: 2, icon: 'fas fa-chart-line', label: language.IMPROVEMENT },
              { value: 5, icon: 'fas fa-shield-alt', label: language.LEGAL_OBLIGATION },
            ].map(e=>(
              <Button 
                icon={e.icon} 
                label={e.label}
                className='btn-item' 
                outlined={form.type_project!=e.value} 
                onClick={()=>setForm({type_project:e.value})}
                color='primary' 
                variant='normal'
              />
            ))}
            
          </div>
        </div>
      </div>
      <hr />
    {/*----------------------------------------------FORMULÁRIO--------------------------------------------------------------------*/}
      <div style={{ zoom: 0.9 }} zoom={0.9}>
        <CommonForm
          spacing={16}
          alert={false}
          onRef={(r)=>setRefForm(r)}
          sensitive
          hiddenSubmit
          values={values}
          fields={[
            
            {
              col: 4,
              name: 'fk_id_company',
              label: language.COMPANY,
              type: 'select',
              options: companyOptions,
              required: true,
              // disabled: !!values
            },
            {
              col: 4,
              name: 'fk_id_contract',
              label: language.CONTRACT,
              type: 'select',
              options: contractOptions,
              required: true
            },

            {
              col: 4,
              name: 'user_cad',
              label: language.PROJECT_MANAGER,
              type: 'select',
              options: solicitantOptions,
              required: true
            },
            {
              col: 4,
              name: 'cust_project',
              label: language.EXPECTED_COST,
              outPut: value => FormatMoney(value, {endDigit:2}),
              InputProps:{
                
                inputComponent:MaskMoney
              },
              type: 'text',
              multiLine: true,
              required: true
            },
            {
              col: 4,
              name: 'realized_cost',
              label: language.REALIZED_COST,
              outPut: value => FormatMoney(value, {endDigit:2}),
              InputProps:{
                
                inputComponent:MaskMoney
              },
              type: 'text',
              multiLine: true,
              required: true
            },
            {
              col: 4,
              name: 'start_date',
              label: language.INITIAL_DATE,
              type: 'date',
              required: true
            },
            {
              col: 4,
              name: 'end_date',
              label: language.FINAL_DATE,
              type: 'date',
              required: true
            },
            
            {
              type:'element',
              col:8,
              content:''
            },
            {
              col: 6,
              name: 'fk_id_department',
              label: language.DEPARTMENT_INVOLVED,
              type: 'listCheckbox',
              options: departmentOptions,
              required: true
            },
            
            {
              col: 6,
              name: 'benefited_area_project',
              label: language.BENEFITED_AREA_PROJECT,
              type: 'listCheckbox',
              options: departmentOptions,
              required: true
            },
            {
              col: 6,
              name: 'tickets',
              type: 'listCheckbox',
              label: 'Tickets',
              options: ticketsList||[],
              filtrogmud: true,
              filterarray:  tickets
                .filter(g => g.stop_ticket === 4)
                .map(g => g.id_tickets)
            },
            {
              col: 6,
              name: 'users',
              type: 'listCheckbox',
              label: language.USERS_INVOLVED,
              options: solicitantOptions
            },
            
            {
              type:'element',
              content:(
                <div>
                  <hr/>
                  <div className='-mt-2 d-flex align-items-center justify-content-center'>
                    <div>
                      <span style={{fontSize:10, color:'var(--primary)', fontWeight:'bold'}}>Risco</span>
                      <div className='d-flex align-items-center btn-group'>
                        {[
                          {value:1,Icon:<SlowIcon style={{fill:form.type_risk==1?'white':'var(--primary)', width:14}}/>, label:'Baixo', color:'primary'},
                          {value:3,Icon:<HightIcon style={{fill:form.type_risk==3?'white':'var(--primary)', width:14}}/>, label:'Alto', color:'warning'},
                          {value:2,Icon:<CriticIcon style={{fill:form.type_risk==2?'white':'var(--primary)', width:14}}/>, label:'Crítico', color:'danger'},
                        ].map(e=>(
                          <Button 
                            icon={e.Icon /*--muda a cor do icone pra branco se o btn for selecionado--*/} 
                            label={e.label}
                            className='btn-item' 
                            outlined={form.type_risk!=e.value}
                            onClick={()=>setForm({type_risk:e.value})}
                            color={e.active&&e.color}
                            variant='normal'
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <hr/>
                </div>
              )
            },

            {
              col: 12,
              name: 'scope',
              label: language.DESCRIPTION,
              type: 'textEditor',
              placeholder: language.DESCRIPTION,
              multiLine: true,
              required: true
            },
            {
              col: 12,
              name: 'goal',
              label: language.GOAL,
              type: 'textEditor',
              placeholder: language.GOAL,
              multiLine: true,
              required: true
            },
            {
              col: 12,
              name: 'risk_project',
              label: language.RISKS,
              placeholder: language.RISKS,
              type: 'textEditor',
              multiLine: true,
              required: true
            },
          ]}
          onChangeField={onChangeField}
          _onSubmit={e=>{
            refFormSponsor.submit();
            refFormName.submit();
          }}
          onSubmit={onSubmit}
        />
      </div>
      <hr/>
      <Button
         
        icon="fas fa-save"
        variant="normal"
        color="success"
        fluid
        onClick={disableButton == false ? () => refForm.submit() : ''}
        style={{ height: 40 }}
        _loading={'post-' + APIEndpoints.PROJECT}
        label={language.SAVE}
      />
      { modalTicket == true ?
       <Dialog open={true} onClose={()=>setmodalTicket(false)}> 
       
       <div style={{textAlign:'center',color:'var(--primary)'}}>
       <h2>{language.THE_TICKET_WILL_BE_CLOSED_DO_YOU_REALLY_WANT_TO_JOIN}</h2>
       <h4>{language.USER_WILL_BE_INVOLVED_IN_THE_PROJECT.replace(/_user_/g, msn)} </h4>
       </div>
       </Dialog>:null
      }
    </Dialog>
  );
}
