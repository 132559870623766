import React, { Component } from 'react';
import { Grid, Paper, Button } from '@material-ui/core'; //comum
import { Checkbox, Select, FormControl, InputLabel, MenuItem, FilledInput, FormControlLabel, List, ListItem } from '@material-ui/core'; // controls
import { Toolbar, Typography } from '@material-ui/core'; //header
import TextField from '@material-ui/core/TextField';
import AppBar from '@common/AppBar';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import Dialog from '@common/Dialog';
import Pickers from '@common/Pickers';
import { Tooltip } from 'react-tippy';
import SimplesAlert from '@common/SimplesAlert';
import '@bClass';
import './styles.css';
import moment from 'moment';
import Store from '@data/Store';

const { language } = Store;

const theme = createTheme({
	palette: {
		primary: { main: '#009dc0' }
	},
});

class MySelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			select: {
				age: '',
			}
		}
	}

	handleChangeSelect = name => event => {
		//console.log(name)
		this.setState({
			select: {
				[name]: event.target.value
			}
		});
	};
	render() {

		return (
			<FormControl style={{ ...this.props.style }} className={this.props.className}>
				<InputLabel htmlFor="age">{this.props.label}</InputLabel>
				<Select
					value={this.state.select.age}
					onChange={this.handleChangeSelect('age')}
					inputProps={{
						name: 'age',
						id: 'device-simple',
					}}
				>
					<MenuItem value={0}><em>Nenhum</em></MenuItem>
					<MenuItem value={1}>Item_1</MenuItem>
					<MenuItem value={2}>Item_2</MenuItem>

				</Select>
			</FormControl>
		)
	}
}

class Title extends Component {
	render() {
		return (<Button variant="contained" size="small" color="primary" {...this.props}>{this.props.label}</Button>)
	}
}
class ListTeckSupport extends Component {
	render() {
		let {
			disabled,
			handleCheckbox,
			handleFrom,
			handleTo,
			e
		} = this.props;


		return (
			<ListItem className='listItem' dense button>
				<FormControlLabel
					style={{ marginTop: 12, marginLeft: 0 }}
					className="fieldCustom"
					control={<Checkbox disabled={disabled} value={e.id} onChange={handleCheckbox} />}
					label={e.label}
				/>
				<Pickers
					className="fieldCustom"
					label="De"
					type="TimePicker"
					style={{ width: 75 }}
					value={e.from}
					disabled={disabled}
					onChange={handleFrom}
				/>
				<Pickers
					className="fieldCustom"
					label="Às"
					type="TimePicker"
					style={{ width: 75 }}
					value={e.to}
					disabled={disabled}
					onChange={handleTo}
				/>
				<TextField className="fieldCustom" label="Valor" style={{ width: 75 }} />
			</ListItem>
		)
	}
}
function setHours(hour) {
	var m = moment();
	m.set({ hour: hour, minute: 0, second: 0, millisecond: 0 });
	return m;
}
let FieldsState = {
	type: '',
	name: '',
	id_contract: 0,
	value: '',
	date_start: moment(),
	month: 0,
	responsible: '',
	date_end: moment(),
	criticality: '',
	company: '',
	contact: '',
	index: '',
	support: [

		{ id: 7, label: language.WEEK, from: setHours(9), to: setHours(17), value: '' },
		{ id: 0, label: moment()._locale._weekdays[0], from: setHours(9), to: setHours(17), value: '' },
		{ id: 6, label: moment()._locale._weekdays[6], from: setHours(9), to: setHours(17), value: '' },
	],

}

export default class AddDevice extends CommonPage {
	constructor(props) {
		super(props);
		this.state = {
			select: {
				age: '',
				handleModalContractTime: false,
			},
			alert: { open: false, message: '' },
			form: { ...FieldsState }
		}
	}
	componentDidMount() {
		this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
		this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
		// console.log(this.store.user);

		//console.log(this.state)
		window._this = this;

	}
	handleModalContractTime = () => {
		this.setState((state) => ({ handleModalContractTime: !state.handleModalContractTime }))
	}

	handleCheckboxSupport = (c) => {

		var form = this.state.form;
		form.support = form.support.map(e =>
			e.id == c.target.value ?
				Object.assign({}, e, {
					checked: c.target.checked
				}) : e
		);

		this.setState({ form })
	}
	toggleAlert = (v, m = false) => {
		var alert = this.state.alert;
		alert.open = v;
		if (m) {
			alert.message = m;
		}
		this.setState({ alert })
	}
	addSupport = () => {
		var form = this.state.form;

		var findAll = form.support.find(e => (e.id == 7 && e.checked));

		if (findAll) {
			this.toggleAlert(true, 'Mensagem');
		} else {
		}
	}
	render() {
		let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
		let weeks = moment()._locale._weekdays;
		var companies = this.store.companies.map(c => (
			<MenuItem key={c.id_company} value={c.id_company} primaryText={c.name} >
				{c.name}
			</MenuItem>
		));

		var contracts_id = this.store.contracts.map(c => (
			<MenuItem key={c.id_contract} value={c.id_contract} primaryText={c.name} >
				{c.id_contract}
			</MenuItem>
		));

		var users = (this.store.user || []).map(c => (
			<MenuItem key={c.id_user} value={c.id_user} primaryText={c.name} >
				{c.name}
			</MenuItem>
		));


		return (
			<CommonPage
				title={
					<div
						className="logoWhite"
						onClick={() => this.redirect('/dash')}
					/>
				}
				showMenuIconButton={false}
				iconAlert={true}
			>
				<PageLayout
					icon={
						<div
							className="panelico"
							style={{ width: 40, height: 40, overflowY: true }}
						/>
					}
					title={'Cadastros de contratos'}
					subtitle={'Subtítulo AddDevice'}
					rightElements={[]}
				>
					<MuiThemeProvider theme={theme}>

						<div id="SubscribeContracts" className="dev">
							<SimplesAlert message={this.state.alert.message} variant='success' float open={this.state.alert.open} onClose={() => this.toggleAlert(false)} />
							<Grid container spacing={3}>
								<Grid item xs={12}>

									<Paper className="paper paper-list-custom">


										<div className="content">
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<fieldset className='fieldset-linearP'>
														<legend>
															<Title label="Dados gerais" />
														</legend>
														<div>
															<TextField className="fieldCustom" label="Tipo" style={{ margin: '6px 6px' }} />
															<TextField className="fieldCustom" label="Nome" style={{ margin: '6px 6px' }} />

															<TextField

																select
																label="Id do contrato"
																className='fieldCustom'
																style={{ margin: '6px 6px' }}

															>
																{contracts_id}
															</TextField>
															<TextField className="fieldCustom" label="Valor" style={{ margin: '6px 6px' }} />
															<Pickers
																className="fieldCustom"
																label="Data de início"
																type="DatePicker"
																style={{ margin: '6px 6px' }}
																onChange={(e) => console.log(e)}
															/>

															<TextField

																select
																label="Meses"
																className='fieldCustom'
																style={{ margin: '6px 6px' }}
															>
																{months.map(e => (
																	<MenuItem value={e} >
																		{e} Meses
																	</MenuItem>
																))}
															</TextField>

															<MySelect className="fieldCustom" label="Responsável" style={{ margin: '6px 6px' }} />
															<Pickers className="fieldCustom" label="Data faturamento" type="DatePicker" style={{ margin: '6px 6px' }} />
															<TextField className="fieldCustom" label="Criticidade" style={{ margin: '6px 6px' }} />
														</div>
													</fieldset>
												</Grid>
											</Grid>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<fieldset className='fieldset-linearP'>
														<legend>
															<Title label="Fornecedor" />
														</legend>
														<div>
															<Grid container spacing={3}>
																<Grid item xs={5}>
																	<div>

																		<TextField

																			select
																			label="Empresa"
																			className='fieldCustom'
																			style={{ margin: '6px 6px', width: 200 }}
																		>
																			{companies}
																		</TextField>
																	</div>
																	<div>
																		<TextField

																			select
																			label="Contato"
																			className='fieldCustom'
																			style={{ margin: '6px 6px', width: 200 }}
																		// disabled
																		>
																			{users}
																		</TextField>
																	</div>
																	<div>
																		<TextField className="fieldCustom" label="índice do reajuste" style={{ width: 200 }} />
																	</div>

																</Grid>

																<Grid item xs={7}>
																	<Paper style={{ width: '100%', margin: '10px 0', marginTop: 24, height: 'calc(100% - 34px)' }}>
																		<AppBar light style={{ width: '90%', top: -17 }} className='font'>
																			<div className='d-flex align-items-center justify-content-between'>
																				<div />
																				<span>Suporte contrato</span>
																				<Tooltip
																					html={
																						<div style={{ color: '#fff', fontSize: 15 }}>
																							<strong>Adicionar</strong>
																						</div>
																					}
																					position="left"
																					theme="dark"
																				>
																					<div className="bg-icon" onClick={this.addSupport}>
																						<div
																							className="openIcon"

																							style={{ width: '100%', height: '100%', cursor: 'pointer' }}
																						/>
																					</div>
																				</Tooltip>
																			</div>
																		</AppBar>
																		<List className='list listMin'>
																			{this.state.form.support.map((e, i) => (

																				<ListTeckSupport

																					e={e}
																					key={i}
																					handleCheckbox={this.handleCheckboxSupport}
																				/>
																			))}

																		</List>
																	</Paper>

																</Grid>
															</Grid>
														</div>
													</fieldset>
												</Grid>
											</Grid>

										</div>
									</Paper>
								</Grid>
							</Grid>
						</div>
					</MuiThemeProvider>
				</PageLayout>
			</CommonPage>
		)
	}
}