import RaisedButton from '../../common/RaisedButton';
import Button from '@common/Button';
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Toggle } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import { Radial } from 'sensrit-charts';
import { Chip } from '@material-ui/core';
import CommonPage from '@common/CommonPage';
import { filterObj } from '@common/Func';

@observer
export default class StockList extends CommonPage {
  constructor() {
    super();
    this.state = {
      companies: []

    }




    // responsibleOptions = users.filter(user => user.id_company == values.fk_id_out_company).map(u => ({ value: u.id_user, text: u.name }));
  }
  componentWillMount() {


    this.props.action.execute('get', this.props.APIEndpoints.COMPANY, 'companies').then(result => {
      this.setState({ companies: result.data })
    });

  }


  render() {
    let { language, handlePage, redirect, onDelete, store, companies } = this.props;
    let col = [
      {
        key: 'id_device',
        label: language.ID,
        style: { width: 1, paddingRight: 0 },
        thConfig: true
      },
      {
        key: 'name',
        label: language.DEVICE,
        style: { width: 20 }
      },
      {
        key: 'company',
        label: language.COMPANY,
        style: { width: 20 }
      },

      {
        key: 'model',
        label: language.STOCK_MODEL,
        style: { width: 10 }
      },
      {
        key: 'departament',
        label: language.DEPARTMENT,
        style: { width: 20 }
      },
      {
        key: 'qtdinput',
        label: language.REGISTRATIONS,
        style: { width: 20, textAlign: 'center' }
      },
      {
        key: 'cost',
        label: language.COST,
        style: { width: 20, textAlign: 'center' }
      },
      {
        key: 'totalcost',
        label: language.TOTAL_COST,
        style: { width: 20, textAlign: 'center' }
      },
      {
        key: 'type',
        label: language.TYPE,
        style: { width: 25, textAlign: 'center' }
      },
      {
        key: 'action',
        label: language.ACTIONS,
        style: { width: 60, textAlign: 'center' }
      }
    ];



    let data = this.props.stockin.map(obj => {
      let item = (
        <RadialGraph
          title="Semana"
          amount={Math.round(obj.qtdinput)}
          color="var(--primary)"
        />
      );

      const getNameCompany = (id) => {
        if (this.state.companies.length > 0) {
          let company = this.state.companies.filter(company => company.id_company == id)[0]
          return company.name
        } else {
          return ''
        }
      }

      return {
        id_device: obj.id_device,
        name: obj.name,
        company: obj.namecompany,
        model: obj.model === '' ? language.NOTHING_REGISTERED : obj.model,
        departament: obj.departament,
        qtdinput: item,
        cost: "R$" + obj.cost,
        totalcost: "R$" + obj.totalcost,
        type: (
          <div className="actionIcons">
            {obj.type === 'server' ? (
              <div
                className="serverIcon"
                data-tip={language.SERVER}
                style={{ height: 25, width: 25 }}
              />
            ) : (
              obj.type
            )}
            <ReactTooltip type="info" />
          </div>
        ),
        action: (
          <Chip
            className='mx-2 muit-chip'
            label={
              <div className='d-flex align-items-center justify-content-between'>
                <Button
                  variant='circle'
                  icon={
                    <img src={this.APIRoot + '/images/svg-icon/input.svg'} style={{ width: '62%' }} />
                  }
                  size={0.72}
                  label={language.INPUT}
                  color='warning'
                  onClick={() => handlePage('form', obj)}

                />

                <Button
                  variant='circle'
                  icon={
                    <img src={this.APIRoot + '/images/svg-icon/output.svg'} style={{ width: '62%' }} />
                  }
                  size={0.72}
                  label={language.OUTPUT}
                  onClick={() => handlePage('formout', obj)}
                  className='mx-2'
                  color='warning'
                />
                <Button
                  variant='circle'
                  icon={
                    <img src={this.APIRoot + '/images/svg-icon/historic.svg'} style={{ width: '75%' }} />
                  }
                  label={language.HISTORIC}
                  color='primaryGradient'
                  size={0.72}
                  onClick={() => redirect('/dash/stock/historic', filterObj(obj))}

                />
                <RaisedButton
                  circleButton
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21, marginLeft: 5 }}
                  onClick={() => handlePage('remove', obj)}
                />
              </div>
            }
          />

        )
      };
    });

    return (
      <PageLayout
        icon='stockIcon'
        title={language.STOCK_MANAGEMENT}
        subTitle={language.DASHBOARD_PAGE.DM + '/'}
        rightElements={[]}
      >
        <div style={{ marginTop: '-1rem' }}>
          <CommonTable
            title={''}
            language={language}
            columns={col}
            index='StockList'
            data={data}
            searchColumn orderColumn
            beforeBar={
              <RaisedButton
                circleButton
                icon='fas fa-plus'
                style={{ zoom: .8 }}
                zoom={.8}
                className='mr-3'
                color='primary'
                label={language.ADD_IN_STOCK}
                onClick={() => handlePage('form')}
              />
            }
          />
        </div>
      </PageLayout>
    );
  }
}

class RadialGraph extends Component {
  render() {
    return (
      <div className="radialGraphDefault">
        <Radial
          low={{ color: '#33ABA0' }}
          mid={{ color: '#FFC107', value: 10 }}
          high={{ color: this.props.color, value: 10 }}
          className="host-graph-perc"
          size={44}
          current={this.props.amount}
          total={100}
          thick={8}
        >
          <h4>{this.props.amount}</h4>
        </Radial>
      </div>
    );
  }
}
