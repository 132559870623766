import React, { Component } from 'react';
import ReactHighChart from 'react-highcharts';

export default class BarBasic extends Component {
  render() {
    let cat = [];
    let liberationItens = [];
    let Problem = [];
    let Incident = [];

    if (this.props.tick != undefined) {
      this.props.tick.map(check => {
        cat.push(check.tech);
        check.tickets != null
          ? check.tickets.map(t => {
              if (t.description === 'Liberation') {
                liberationItens.push(t.count);
              } else if (t.description === 'Problem') {
                Problem.push(t.count);
              } else {
                Incident.push(t.count);
              }
            })
          : null;
      });
    }

    let { value, language } = this.props;

    var config = {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: cat,
        title: {
          text: language.ANALYSTS
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: language.QUANTITY+' ('+language.CALLS+')',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -10,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: 'transparent',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'liberation',
          data: liberationItens,
          color: 'var(--secundary)'
        },
        { name: 'Problem', data: Problem, color: ' var(--primary)' },
        { name: 'Incident', data: Incident, color: ' var(--sucess)' }
      ]
    };
    return <ReactHighChart config={config} />;
  }
}
