import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Paper, Card } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import FaClose from 'react-icons/lib/fa/close';
import Dialog from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';
import { MaskNumber } from '@common/Mask';
let formRef = null;

@observer
export default class AttachSla extends Component {
  constructor() {
    super();
    this.state = {
      sla: '',
      time: 0
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(field, value) {
    if (field.name === 'id_sla') {
      let time = this.props.store.slas.find(x => x.id_sla == value).time_solution.toString().padStart(2, '0');
      formRef.handleValue(time, 'time');
    }
  }

  render() {
    let time = this.state.time.toString().padStart(2, '0');
    let { language, onFormSubmit, handlePage } = this.props;
    let inArray = this.props.contract.slas.map(s => s.id_sla);
    let slas = this.props.store.slas
      .filter(s => !inArray.includes(s.id_sla))
      .map(s => {
        return { value: s.id_sla, label: s.name };
      });

    let values = {
      time,
      sla: this.state.sla
    }

    //console.log('valores', values)

    //console.log('sei la', slas,  )
    let fields = [
      {

        name: 'id_sla',
        label: language.SLA,
        type: 'select',
        required: true,
        options: slas
      },
      {

        name: 'time',
        label: language.TIME,
        type: 'text',
        InputProps: {
          id: {
            format: "##h",
            mask: '_',
            placeholder: '00h'
          },
          inputComponent: MaskNumber
        },
        required: true
      }
    ];
    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: '20px 0' }
    };

    return (
      <Dialog
        modal={false}
        open={this.props.open}
        monitor_scroll
        maxWidth='xs'
        bodyStyle={{ padding: 0, overflowY: 'none' }}
        title={language.SLA_ATTACH}
        onClose={() => handlePage('list')}
      >
        <div style={{ width: "900px" }}></div>

        {slas.length ? (
          <CommonForm
            values={values}
            onRef={(ref) => formRef = ref}
            language={language}
            fields={fields}
            onChangeField={this.onChangeField}
            onSubmit={onFormSubmit}
            button={button}
          />
        ) : (
          <Row>
            <Col md={12} className="text-center" style={{ margin: 20 }}>
              {language.SLA_ATTACH_WARNING}
            </Col>
          </Row>
        )}

      </Dialog>
    );
  }
}
