import RaisedButton from '../../common/RaisedButton';
import React, {Fragment } from 'react';
import {Chip} from '@material-ui/core';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
@observer
export default class ExtensionList extends CommonPage {
 
  constructor() {
    super();
    this.state = {
      openTest:false
      
    }
     
    
    this.openmail = this.openmail.bind(this);
  }
   
  openmail(){
    this.setState({openTest:true})
  }
  render() {
    let { language, handlePage } = this.props;
     let col = [
   
      {
        key: 'extension',
        label: language.EXTENSION,
        thConfig:true,
        style: { width: 120 }
      },
      {
        key: 'action',
        label: language.ACTION,
        thConfig:true,
        style: { width: 100, textAlign: 'center' }
      }
    ];
    let data = (this.props.store.extensions||[]).map(obj => {
      return {
        extension: obj,
        action: (
          <React.Fragment>
            <Chip
              className='mx-2 muit-chip'
              label={
                <div className='d-flex align-items-center justify-content-between'>
                   <RaisedButton
                    circleButton
                    icon='fas fa-edit'
                    label={language.EDIT}
                    color='default'
                    style={{fontSize:12, width:21, height:21}}
                    className='mr-2'
                    onClick={() => handlePage('form', obj)}
                    />
                  <RaisedButton
                    circleButton
                    icon='far fa-trash-alt'
                    label={language.DELETE}
                    color='danger'
                    style={{fontSize:13, width:21, height:21}}
                    onClick={() => handlePage('remove', obj)}
                  />
               </div>
              }
            />
         
          </React.Fragment>
        )
      };
    });
    let button = { label: language.SAVE};
    let {configurationProps={}} = this.props;
    return (
      <Fragment>
        {this.state.openTest  ? 
        <Dialog monitor_scroll title={language.TYPE_YOUR_EMAIL} modal={false} open={true}    >
        
        <CommonForm
        
          fields={fieldstest}
          onSubmit={(e)=>this.testeMail(e)}
          button={button}
        />
      
    </Dialog> :null}

          <CommonTable
            title={''}
            language={language}
            columns={col}
            index='ExtensionList'
            data={data}
            searchColumn orderColumn
            width='100%'
            orderColumn
            paginationTop={false}
            searchColumn={true}
            beforeBar2={configurationProps.control}
            beforeBar={
              <RaisedButton
                circleButton
                icon='fas fa-plus'
                style={{zoom:.8}}
                zoom={.8}
                className='mr-3'
                color='primary'
                primary={true}
                label={language.ADD}
                onClick={() => handlePage('form')}
              />
            }
          />
        
      </Fragment>
    );
  }
}
