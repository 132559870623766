import CommonForm from '@common/CommonForm2';
import SimplesAlert from '@common/SimplesAlert';
import Constants from '@data/Constants';
import Store from '@data/Store';
import { Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { decrypt, encrypt, spFetch } from './helpers';
import { translateFromPtBR } from '@common/languages/Dictionary';

export default function ServicePortalCatalogForm({
  catalogSelected,
  task,
  formSchema,
  allowCustomizePriority,
  isIntegrationEnabled,
  enableLocationInOpeningTicket,
}) {
  const { language } = Store;
  const history = useHistory();

  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState,
  );
  const [values, setValues] = useState({});
  const [forms, setForms] = useState(null);
  const [isLoading, setIsLoading] = useReducer((v) => !v, false);
  const alertDefault = { opened: false, message: '', variant: '' };
  const [alert, setAlert] = useState(alertDefault);
  const [subdptName, setSubdptName] = useState('');
  const [dptName, setDptName] = useState('');
  const [chieftDptName, setChieftDptName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [allDptName, setAllDptName] = useState('');
  const [chiefSubDptName, setChiefSubDptName] = useState('');
  const [allChiefName, setAllChieftName] = useState('');
  const [locationOptions, setLocationOptions] = useState([]);
  const [multiTickets, setMultiTickets] = useState([]);

  useEffect(() => {
    if (catalogSelected.firstLevel && enableLocationInOpeningTicket) {
      const url = `${Constants.APIEndpoints.LOCATION}/company/${catalogSelected.firstLevel}`;
      spFetch(url, 'get').then((result) => {
        if (result.data.length > 0) {
          setLocationOptions(
            result.data.map((item) => ({
              label: item.name,
              value: item.id_location,
            })),
          );
        } else {
          setLocationOptions([]);
        }
      });
    }

    if (catalogSelected?.task) {
      spFetch(`${Constants.APIEndpoints.TASK}/getallmultiticket`, 'post', {
        task: catalogSelected.task,
      }).then((result) => {
        setMultiTickets(result.data);
      });
    }

    const item = formSchema.map((obj, index) => {
      const id = 'ID' + (index + 1);
      // const lastUpdateText = obj.user_up
      //   ? `Última Atualização: ${obj.user_up} - ${moment(obj.dt_up).format(
      //       'DD-MM-YY HH:mm:ss'
      //     )}`
      //   : null;

      return (
        <div className="contain" key={id}>
          <div className="itemTicketDetail">
            <p style={{ color: '#888080' }} id={id}>
              {formshtml(decrypt(obj.html))}
            </p>
          </div>
        </div>
      );
    });

    setForms(item[0]);
  }, [, formSchema.fk_id_catalog_task]);

  function formshtml(t) {
    let data = [];
    t.split('<div').map((i, key) => {
      if (i.indexOf('show-in-service-portal') == -1) {
        return '';
      }

      const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;

      if( addEmptyOption && i.indexOf('<select') > -1 ) {
        i.replace(/>Selecione</, function(match) {
          let originalText = match.slice(1, -1);
          let newText = translateFromPtBR(originalText, language);
          return `>${newText}<`;
        });
      }

      if (i.indexOf('companies_var') > -1) {
        data.push(
          <div id="companies_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: companyName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: companyName,
                  routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('allusers_var') > -1) {
        data.push(
          <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: `Usuário`,
                  textinit: 'Usuário',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Usuário',
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          nameField = textWithoutTags[1].split('|')[1];
        } catch (e) {}
        data.push(
          <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: 'Horas',
                  type: 'time',
                  format: '##:##',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = '';
        let name_field = '';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          name_field = textWithoutTags[1].split('|')[1];
          value_field = textWithoutTags[1].split('|')[2];
        } catch (e) {}
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`;
        data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('departments_var') > -1) {
        data.push(
          <div id="departments_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
                setDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  filterAll: { subdepartment: false },
                  fieldquery: 'search',
                  textinit: dptName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: dptName,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('subsdpt_var') > -1) {
        data.push(
          <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
                setSubdptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  filterAll: { subdepartment: true },
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('contracts_var') > -1) {
        data.push(
          <div id="contracts_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: ' *',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
                  textlabel: '',
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('chieftdpt_var') > -1) {
        data.push(
          <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_dpt',
                  label: '',
                  textlabel: ``,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { chief: true },
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: chieftDptName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chieftDptName,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('chiefsubdpt_var') > -1) {
        data.push(
          <div id="chiefsubdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chiefsubdpt_var: value });
                setChiefSubDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss_sub_dpt',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { chiefsub: true },
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: chiefSubDptName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: chiefSubDptName,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('chief_var') > -1) {
        data.push(
          <div id="chief_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chief_var: value });
                setAllChieftName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'all_boss_dpt',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  filterAll: { all_chief: true },
                  route: `${Constants.APIEndpoints.USER}/filter`,
                  routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: allChiefName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: allChiefName,
                  routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('departmentall_var') > -1) {
        data.push(
          <div id="departmentall_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departmentall_var: value });
                setAllDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'all_departments',
                  label: '',
                  textlabel: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: allDptName,
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: allDptName,
                  routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else {
        if (i.length > 0) {
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }
          data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
        }
      }
    });
    return data;
  }

  function handleChangeField(field, value) {
    let valueSwap = { ...values };
    valueSwap[field.name] = value;
    setValues(valueSwap);
  }

  function handleOpenAlert(message, variant = '') {
    setAlert({ opened: true, message: message, variant: variant });
  }

  function handleCloseAlert() {
    setAlert(alertDefault);
  }

  const onSubmit = () => {
    setIsLoading();

    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    let campossize = false;

    let dataSend = { ...values };

    if (!dataSend.description) {
      setIsLoading();
      return handleOpenAlert(language.DESCRIPTION_CANNOT_BE_BLANK, 'error');
    }

    const solicitationKeys = {
      chieft_var: 'Chefe',
      chiefsubdpt_var: 'Chefe de Subdepartamento',
      chieftdpt_var: 'Chefe de Departamento',
      companies_var: 'Empresa',
      departmentall_var: 'Departamento',
      departments_var: 'Departamentos',
      subdepartments_var: 'Subdepartamento',
    };

    let formsComplete = true;

    for (const [key, description] of Object.entries(solicitationKeys)) {
      if (
        json_template_solicitation[key] == '' ||
        json_template_solicitation[key] == description
      ) {
        formsComplete = false;
        break;
      }
    }

    if (!formsComplete) {
      setIsLoading();
      return handleOpenAlert(language.FORM_FIELDS_CANNOT_BE_BLANK, 'error');
    }

    dataSend.fk_id_category = catalogSelected.thirdLevel;
    dataSend.fk_id_catalog_service = catalogSelected.subcategory;
    dataSend.fk_id_catalog_task = catalogSelected.task;

    /////// validate filter task
    // if (!isFilterTask) {
    //     dataSend.fk_id_company = selectedDepartment.fk_id_company ? selectedDepartment.fk_id_company : selectedCompany.id_company;
    //     dataSend.fk_id_department = selectedDepartment.id_department;
    // }

    if (chieftDptName.length > 0) {
      dataSend.approver_name = chieftDptName;
    } else if (chiefSubDptName.length > 0) {
      dataSend.approver_name = chiefSubDptName;
    } else if (allChiefName.length > 0) {
      dataSend.approver_name = allChiefName;
    }

    if (subdptName.length > 0) {
      dataSend.departament_name = subdptName;
    } else if (dptName.length > 0) {
      dataSend.departament_name = dptName;
    } else if (allDptName.length > 0) {
      dataSend.departament_name = allDptName;
    }

    let fields = [];
    if ($('#template').html() != undefined) {
      $('#template textarea').each(function () {
        $(this).attr('value', $(this).val());
        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#template input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            let value = undefined;
            try {
              value = $(this).parent().children()[1].textContent;
            } catch (e) {}
            fields.push({
              name: $(this).attr('name'),
              value: value || $(this).val(),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#template  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).is(':checked')) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) {}
                  });
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({ name: $(this).attr('name'), value: $(this).val() });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) {}
                      }
                    } catch (e) {}
                  });
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });

          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${
                  min || 0
                } á ${siz || 0} caracteres</label>`,
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#template select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected',
        );
        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (!$(this).val() || $(this).val() == "") {
              camposobrigatorios = true;
              $(this).css('border-color', 'red');
            } else {
              $(this).css('border-color', 'var(--primary)');
            }
          }
        }
      });

      $('#template label').each(function () {
        try {
          let textArea = $(this)
            .parent()
            .parent()
            .nextAll()
            .slice(2, 3)
            .children()[0];
          if (
            textArea &&
            textArea.firstChild &&
            textArea.firstChild.tagName == 'P' &&
            textArea.firstChild.textContent.length > 0
          ) {
            fields.push({
              name: $(this).attr('for'),
              value: textArea.firstChild.textContent,
            });
          }

          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())
                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) {
          console.log('erro no label', e);
        }
      });
      dataSend.template = encrypt($('#template').html());
      dataSend.fields = fields;
    }

    if (
      campossize == false &&
      camposobrigatorios == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      if (dataSend.file && dataSend.file.length > 0) {
        const total = Array.from(dataSend.file).length;

        let loadFilesAsync = [];
        let fileReference = [];

        Array.from(dataSend.file).forEach((file) => {
          if (file.size >= 40000000) {
            handleOpenAlert(
              `${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`,
              'error',
            );
          } else {
            loadFilesAsync.push(
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  if (!!reader.result) {
                    resolve(reader.result);
                  } else {
                    handleOpenAlert(
                      `ERROR: ${language.FILE} ${file.name}`,
                      'error',
                    );
                    reject(Error('Failed converting to base64'));
                  }
                };
              }),
            );
            fileReference.push(file);
          }
        });

        if (loadFilesAsync.length > 0)
          Promise.all(loadFilesAsync).then((results) => {
            let _files = [];

            results.forEach((file, index) => {
              _files.push({
                file: file,
                type: fileReference[index].type,
                namefile: fileReference[index].name,
              });
            });

            if (_files.length == total) {
              dataSend.file = _files;
              onPostRequest(dataSend);
            }
          });
      } else {

        onPostRequest(dataSend);

      }
    } else {
      setIsLoading();

      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        handleOpenAlert(language.INFORM_REQUIRED_FIELDS, 'error');

      if (campossize) handleOpenAlert(language.CHECK_FIELD_SIZES, 'error');
    }
  };

  const onPostRequest = (data) => {
    spFetch(Constants.APIEndpoints.REQUEST, 'POST', data)
      .then((e) => {
        history.push(`/dash/service-portal/my-tickets`);
      })
      .catch((err) => {
        console.error('ServicePortalCatalogForm: ', err);
        handleOpenAlert(
          language.AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER,
          'error',
        );
      })
      .finally(() => setIsLoading());
  };

  const fields = [
    {
      col: 12,
      type: 'text',
      name: 'subject',
      label: language.REQUEST_TITLE,
      required: true,
    },
    {
      col: 12,
      type: isIntegrationEnabled ? 'simplesTextEditor' : 'textEditor',
      name: 'description',
      label: language.REQUEST_DESCRIPTION,
      required: true,
    },
    {
      col: 12,
      type: 'select',
      name: 'fk_id_urgency',
      label: language.URGENCY,
      options: [
        { value: 24, label: language.LOW },
        { value: 25, label: language.MEDIUM },
        { value: 26, label: language.HIGH },
        { value: 27, label: language.TICKET_PRIORITY.CRITICAL },
      ],
      visible: allowCustomizePriority,
    },
    {
      col: 8,
      type: 'autocomplete',
      name: 'devices',
      label: language.ASSET_INVOLVED,
      textlabel: language.ASSET_INVOLVED,
      method: 'POST',
      route: `${Constants.APIEndpoints.DEVICE}/filter/`,
      routeGetCount: `${Constants.APIEndpoints.DEVICE}/filter?count=0`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_device',
      fieldlabeldb: 'name',
      filterAll: { filterByUser: true },
      idinit: '',
      routeAll: `${Constants.APIEndpoints.DEVICE}/filter?`,
      methodGetAll: 'POST',
      detail: true,
      isMulti: true,
    },
    {
      col: 4,
      type: 'file',
      name: 'file',
      label: language.ATTACH,
      customPortalCss: true,
    },
    {
      col: 4,
      type: 'select',
      name: 'fk_id_location',
      label: language.LOCATION_TITLE,

      options: locationOptions || [],
      required: false,
      visible: enableLocationInOpeningTicket,
      className: 'mt-4',
    },
    {
      col: 12,
      type: 'listCheckbox',
      required: true,
      name: 'multi_tickets',
      label: language.MULTI_TICKETS,
      options: multiTickets.map((ticket) => ({
        value: ticket.id_rel_task_multi_ticket,
        label: ticket.name,
      })),
      visible: multiTickets.length > 0,
    },
  ];

  return (
    <div className="md:tw-container">
      {task.description && (
        <div className="tw-py-8 tw-px-4 tw-mb-4 tw-border tw-border-r-0 tw-border-l-0">
          <p dangerouslySetInnerHTML={{ __html: task.description }}></p>
        </div>
      )}
      <div className="divCommonForm tw-flex tw-flex-col tw-gap-6">
        {!task?.disable_fields && (
          <CommonForm
            fields={fields}
            values={{ ...values, ...{ fk_id_urgency: task.id_urgency } }}
            onChangeField={(field, value) => handleChangeField(field, value)}
          />
        )}

        {forms != null ? (
          <div id="template" className="template" style={{ marginTop: '50px' }}>
            {forms}
          </div>
        ) : null}

        {!task?.disable_fields && (
          <div>
            <hr />
            <div className="tw-flex tw-justify-end tw-mt-5">
              <Button
                variant="contained"
                className={!isLoading ? 'sp-button-action' : 'sp-button'}
                onClick={() => onSubmit()}
                disabled={isLoading}
              >
                {isLoading && (
                  <div class="sp-spinner-border" role="status">
                    {' '}
                  </div>
                )}
                {language.SAVE}
              </Button>
            </div>
          </div>
        )}
      </div>
      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
}
