import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import { Grid } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
export default function ({
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  closeContract,
  language
}) {
   language = store.language
  let [contracts, setContracts] = useState([]);
  let [contractsOptions, setContractsOptions] = useState([]);
  let [open, setOpen] = useState(false);
  const onSubmit = (v, values) => {
    values.fk_id_contract = v.fk_id_contract;

    action
      .execute('post', APIEndpoints.USER + '/upcontract', '', values)
      .then((c) => {
        setContracts({ fk_id_contract: c.data.map((b) => b.fk_id_contract) });
      });
    closeContract();
  };

  useEffect(() => {
    action
      .execute('post', APIEndpoints.CONTRACTS, 'contracts')
      .then((i) => {
        setContractsOptions(
          i.data.map((c) => ({ value: c.id_contract, label: c.name }))
        );
      });
    action
      .execute(
        'get',
        APIEndpoints.USER + '/contracts/' + values.id_user,
        ''
      )
      .then((c) => {
        setContracts({ fk_id_contract: c.data.map((b) => b.fk_id_contract) });
      });
  }, [values]);

  let form = [];
  return (
    <Dialog
      PaperProps={{
        id: '',
      }}
      title={
        values.role == 'tech'
          ? language.ASSOCIATE_TECH_CONTRACT
          : language.ASSOCIATE_USER_CONTRACT
      }
      open={true}
      onClose={closeContract}
    >
      <CommonForm
        values={contracts}
        fields={[
          {
            name: 'fk_id_contract',
            type: 'listCheckbox',
            options: contractsOptions,
          },
        ]}
        onSubmit={(v) => onSubmit(v, values)}
      />
    </Dialog>
  );
}
