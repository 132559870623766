import React, { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

import * as Styled from './closingGMUD.styles';
import {
  getCategoriesByCompanyIdService,
  getCatalogsByCategoryIdService,
  getTasksByCatalogIdService,
} from './closingGMUD.services';

import AppBar from '@common/AppBar2';
import CommonForm2 from '@common/CommonForm2';
import Store from '@data/Store';
import { formatSelectOptions } from '../../GeneralParametersConfig';

// ----------------------------------------------------------------------

export const ClosingGMUD = ({ control, onSubmit, values }) => {
  const {
    fk_id_company,
    fk_id_category_gmud_problem_ticket,
    fk_id_catalog_service_gmud_problem_ticket,
    fk_id_catalog_task_gmud_problem_ticket,
    open_ticket_when_gmud_fails,
  } = values;

  const [openTicketGMUDFails, setOpenTicketGMUDFails] = useState(false);
  const [categories, setCategories] = useState(null);
  const [categoryIdSelected, setCategoryIdSelected] = useState(null);
  const [catalogs, setCatalogs] = useState(null);
  const [catalogIdSelected, setCatalogIdSelected] = useState(null);
  const [taks, setTaks] = useState(null);
  const [taskIdSelected, setTaskIdSelected] = useState(null);

  const { language } = Store;

  const initialState = {
    open_ticket_when_gmud_fails: openTicketGMUDFails,
    fk_id_category_gmud_problem_ticket: categoryIdSelected,
    fk_id_catalog_service_gmud_problem_ticket: catalogIdSelected,
    fk_id_catalog_task_gmud_problem_ticket: taskIdSelected,
  };

  const resetCategory = () => {
    setCatalogs(null);
    setCatalogIdSelected(null);
    resetCatalog();
  };

  const resetCatalog = () => {
    setTaks(null);
    setTaskIdSelected(null);
  };

  useEffect(() => {
    setOpenTicketGMUDFails(open_ticket_when_gmud_fails);
    setCategoryIdSelected(fk_id_category_gmud_problem_ticket);
    setCatalogIdSelected(fk_id_catalog_service_gmud_problem_ticket);
    setTaskIdSelected(fk_id_catalog_task_gmud_problem_ticket);
  }, [
    fk_id_category_gmud_problem_ticket,
    fk_id_catalog_service_gmud_problem_ticket,
    fk_id_catalog_task_gmud_problem_ticket,
    open_ticket_when_gmud_fails,
  ]);

  useEffect(() => {
    const getCategories = async () => {
      await getCategoriesByCompanyIdService({
        companyId: fk_id_company,
      }).then((result) => {
        setCategories(formatSelectOptions(result, 'name', 'id_category'));
      });
    };

    const getCatalogs = async () => {
      await getCatalogsByCategoryIdService({
        companyId: fk_id_company,
        categoryId: categoryIdSelected,
      }).then((result) => {
        setCatalogs(formatSelectOptions(result, 'name', 'id_catalog_service'));
      });
    };

    const getTasks = async () => {
      await getTasksByCatalogIdService({
        catalogId: catalogIdSelected,
      }).then((result) => {
        setTaks(formatSelectOptions(result, 'name', 'id_catalog_task', true));
      });
    };

    fk_id_company && getCategories();
    categoryIdSelected && getCatalogs();
    catalogIdSelected && getTasks();
  }, [fk_id_company, categoryIdSelected, catalogIdSelected]);

  const fields = [
    {
      col: 12,
      label: language.open_ticket_when_gmud_fails,
      name: 'open_ticket_when_gmud_fails',
      type: 'switch',
    },
    {
      col: 12,
      label: language.CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY,
      name: 'fk_id_category_gmud_problem_ticket',
      type: 'select',
      options: categories,
      required: true,
    },
    {
      col: 12,
      label: language.CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_SECOND,
      name: 'fk_id_catalog_service_gmud_problem_ticket',
      type: 'select',
      options: catalogs,
      required: true,
    },
    {
      col: 12,
      label: language.CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_THIRD,
      name: 'fk_id_catalog_task_gmud_problem_ticket',
      type: 'select',
      options: taks,
      required: true,
    },
  ];

  return (
    <Styled.Container>
      <AppBar title={language.CONFIG_PARAM_GMUD_CLOSING_OF_GMUD}>
        {control}
      </AppBar>
      <Styled.CommonFormContainer>
        <CommonForm2
          values={initialState}
          fields={fields}
          onChangeField={(field, value) => {
            if (field.name === 'fk_id_category_gmud_problem_ticket') {
              resetCategory();
              setCategoryIdSelected(value);
            }
            if (field.name === 'fk_id_catalog_service_gmud_problem_ticket') {
              resetCatalog();
              setCatalogIdSelected(value);
            }
            if (field.name === 'fk_id_catalog_task_gmud_problem_ticket') {
              setTaskIdSelected(value);
            }
          }}
          onSubmit={onSubmit}
          button={{ label: language.SAVE }}
        />
      </Styled.CommonFormContainer>
    </Styled.Container>
  );
};
