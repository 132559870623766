import React, { Component } from 'react';
import { Chip, Avatar, Badge } from '@material-ui/core';
import prettyBytes from 'pretty-bytes';
import { Tooltip } from 'react-tippy';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from '@common/Button';
import './styles.css';
import { observer } from 'mobx-react';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import Store from '../../data/Store';
const crypto = require('crypto');

const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
});

@observer
class AttachFileItem extends React.Component {
  constructor() {
    super();
    this.action = new Actions();

    this.state = {
      parameters: '',
    };
  }

  typeFile = (type) => {
    var types = {
      png: 'fa-file-image',
      jpg: 'fa-file-image',
      jpeg: 'fa-file-image',
      gif: 'fa-file-image',
      tiff: 'fa-file-image',
      msg: 'fa-file-message',
      raw: 'fa-file-image',
      pdf: 'fa-file-pdf',
      html: 'fa-file-code',
      zip: 'fa-file-archive',
      word: 'fa-file-word',
      excel: 'fa-file-excel',
      powerpoint: 'fa-file-powerpoint',
      video: 'fa-file-video',
      audio: 'fa-file-audio',
    };
    var fa = types[type ? type.split('/')[1] : ''];
    return fa || 'fa-file';
  };

  componentDidMount = () => {
  };

  render() {
    var {
      type,
      name,
      label,
      customPortalCss,
      file = {},
      ...other
    } = this.props;

    label =
      label || file.name + (file.size ? ' - ' + prettyBytes(file.size) : '');
    return (
      <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{label}</strong>
          </div>
        }
        theme="dark"
        position={customPortalCss ? undefined : 'left'}
      >
        <Chip
          {...other}
          avatar={
            <Avatar>
              <i
                className={'far ' + this.typeFile(type || file.type)}
                style={{ width: 'auto', height: 'auto' }}
              ></i>
            </Avatar>
          }
          className={classnames('chip', this.props.className)}
          label={<div className="ellipsis">{label}</div>}
        />
      </Tooltip>
    );
  }
}

export default class AttachFile2 extends Component {
  constructor() {
    super();
    this.action = new Actions();
    this.APIRoot = Constants.APIRoot;

    this.state = {
      parameters: '',
    };
  }
  decryp(aMsg) {
    var aSecret = 'Orch3str0!@forever!';
    try {
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);

      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  componentWillMount() {
    if (sessionStorage.getItem('params')) {
      let iss = JSON.parse(this.decryp(sessionStorage.getItem('params')));
      this.setState({ parameters: iss[0].extension_file });
    } else {
      this.action
        .execute('get', this.APIRoot + '/public/api/generalparameters')
        .then((e) => {
          try {
            this.setState({ parameters: e.data.extension_file });
          } catch (e) { }
        })
        .catch((error) => { });
    }
  }

  render() {
    var {
      btnVisible,
      maxHeight,
      onClick,
      scrollProps = {},
      btnProps,
      files,
      btnBottom,
      label,
      subLabel,
      disabled,
      onDrop,
      itemProps,
      accept,
      maxFiles,
      className,
      teste2,
      customPortalCss,
      showFiles,
      error,
      ...other
    } = this.props;
    const onDropRejected = () => {
      return Store.toggleAlert(true, Store.language.ATTACH_FILE_ERROR, 'error')
    }

    const btn = (
      <Dropzone
        required
        // accept={".zip, .msg, .mp4, .htm, .7Z, .json, .VBS, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar , audio/*, image/*"}
        accept={accept ?? this.state.parameters}
        maxFiles={maxFiles ?? 0}
        maxSize={209715200}
        onDrop={(e) => onDrop(maxFiles ? e.slice(0, maxFiles) : e)}
        disabled={disabled}
        style={{ display: 'inline-block' }}
        className="dropzone-attach-file-2 w-100"
        onDropRejected={onDropRejected}
      >
        <Badge
          style={{ marginTop: customPortalCss ? 8 : 0 }}
          badgeContent={files.length}
          className="badge-button-attachFile2"
          invisible={!files.length}
        >
          <Button
            icon="fas fa-cloud-upload-alt test"
            variant="normal"
            color={error && !files.length ? "danger" : "success"}
            label={label}
            fluid
            style={{ height: customPortalCss ? 45 : 32 }}
            {...btnProps}
          />
        </Badge>
      </Dropzone>
    );
    return (
      <div className="test">
        {!btnVisible && !btnBottom && btn}
        <PerfectScrollbar
          style={{
            maxHeight: maxHeight || 300,
          }}
          {...scrollProps}
          className={classnames('scrollVisible', scrollProps.className)}
        >
          {' '}
          {files.length > 0 && showFiles !== false ? (
            <aside className="AttachFile2" style={{ zoom: 0.8 }} zoom={0.8}>
              <ul>
                {files.map((f) => (
                  <div style={{ margin: '5px 12px' }} className="ml-0">
                    <AttachFileItem
                      {...itemProps}
                      customPortalCss={customPortalCss}
                      onClick={() => onClick(f)}
                      file={f}
                      onDelete={
                        this.props.onRemove
                          ? () => this.props.onRemove(f)
                          : false
                      }
                    />
                  </div>
                ))}
              </ul>
            </aside>
          ) : null}
        </PerfectScrollbar>
        {btnBottom && btn}

        {error && !files.length && <div className="error">{Store.language.FILL_THE_FIELD}</div>}
      </div>
    );
  }
}
