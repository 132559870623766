import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import {
  BoxList,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TextList,
} from '../portal/SidebarCompanies';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import { Constants } from '../../data';

export const CustomExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    height: '40px !important',
    color: {},

    '&$expanded': {
      minHeight: 56,
      //padding: '15px 0  0 12px',
      paddingTop: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const CustomExpansionPanelDetails = withStyles({
  root: {
    // padding: theme.spacing(2),
    minHeight: 44,
    maxHeight: 46,
    width: '100%',
    backgroundColor: 'white',
    cursor: 'pointer',
    padding: '8px 16px',
  },
})(MuiExpansionPanelDetails);

const useCardStyles = makeStyles({
  root: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  description: {
    fontSize: 11,
  },
});

export default function ServicePortalCatalogItem({
  title,
  item,
  onSelectItem,
  titleRoot,
  itemRoot,
  showDescription = true,
}) {
  const classes = useCardStyles();

  return (
    <Card
      className={classNames('sp-catalog-category-item', classes.root)}
      onClick={() => onSelectItem(item)}
    >
      <CardContent>
        {titleRoot && (
          <Typography
            paragraph={false}
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {titleRoot}
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              <span className="tw-font-bold">{itemRoot.name}</span>
            </Typography>
          </Typography>
        )}

        <div className="tw-flex">
          {item.icon_url && (
            <div className="tw-flex tw-w-14 tw-items-center tw-justify-center">
              <img
                className="tw-h-12"
                src={Constants.BASE_URL_IMAGE + item.icon_url}
              />
            </div>
          )}
          {item.icon && (
            <div className="tw-flex tw-w-14 tw-items-center tw-justify-center">
              <Icon>{item.icon}</Icon>
            </div>
          )}
          <div className="tw-flex tw-flex-col">
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="h5" component="h2">
              {item.name}
            </Typography>

            {showDescription && (
              <Typography
                className={classes.description}
                variant="body2"
                component="p"
              >
                {item.description}
              </Typography>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
