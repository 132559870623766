import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import FaClose from 'react-icons/lib/fa/close';
import GoTerminal from 'react-icons/lib/go/terminal';
import { Card, Paper, TextField } from '@material-ui/core';

import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm';
import CommonTable from '../../common/CommonTable';

@observer
export default class AuditList extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      assessmentId: '',
      id_contest: '',
      description_tech: '',
      search: '',
    };
    this.onContestSubmit = this.onContestSubmit.bind(this);
    this.onResponseSubmit = this.onResponseSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.downloadPage = this.downloadPage.bind(this);
  }
  calculateTimeIn = (cur) => {
    let days,
      hours,
      minutes,
      seconds = 0;

    days = Math.floor(cur / 86400);
    cur -= days * 86400;
    hours = Math.floor(cur / 3600) % 24;
    cur -= hours * 3600;
    minutes = Math.floor(cur / 60) % 60;
    cur -= minutes * 60;
    seconds = Math.floor(cur) % 60;
    hours = days * 24 + hours;

    if (hours < 0) hours = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return hours + ':' + minutes + ':' + seconds;
  };
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ search: value });
  };

  downloadPage(values) {
    this.props.downloadPage(values);
  }

  onContestSubmit(values) {
    values.fk_id_assessment = this.state.assessmentId;
    values.id_contest = this.state.id_contest;
    this.setState({ modalOpen: false });
    this.props.onFormSubmit('contest', values);
  }

  onResponseSubmit(values) {
    this.setState({ modalOpen: false });
    this.props.onFormSubmit('response', values);
  }

  render() {
    let { language, handlePage, store, onFormSubmit, redirect, downloadPage } =
      this.props;
    let { modalOpen, assessmentId, id_contest, description_tech } = this.state;

    let col = [
      {
        key: 'str_path',
        label: language.FOLDER_ID,
        style: { textAlign: 'center', width: 50 },
      },
      {
        key: 'tech',
        label: language.TECH,
        style: { textAlign: 'center', width: 50 },
      },
      {
        key: 'device',
        label: language.DEVICE,
        style: { textAlign: 'center', width: 50 },
      },
      {
        key: 'start',
        label: language.INITIAL_DATE,
        style: { width: 50, textAlign: 'center' },
      },
      {
        key: 'end',
        label: language.END_DATE,
        style: { width: 50, textAlign: 'center' },
      },
      {
        key: 'sla',
        label: language.SLA,
        style: { width: 50, textAlign: 'center' },
      },
      {
        key: 'time',
        label: language.TIME,
        style: { width: 50, textAlign: 'center' },
      },
      { key: 'audit', label: language.AUDIT, style: { width: 50 } },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 100, textAlign: 'center' },
      },
    ];
    let assessments = this.props.contractList || store.assessments;

    if (this.state.search != '') {
      assessments = assessments.filter((item) => {
        return (
          item.device.name
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) != -1 ||
          item.tech.name
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) != -1
        );
      });
    }

    let data = assessments.map((obj) => {
      let h = moment(moment(obj.end_date).diff(moment(obj.start_date))).unix();

      return {
        str_path: obj.str_path,
        tech: obj.tech.name,
        device: obj.device.name,
        start: moment(obj.dt_cad).format('L'),
        end: obj.end_date ? moment(obj.end_date).format('L') : null,
        sla: obj.sla.status == -1 ? language.DELAYED : language.IN_TIME,
        time: this.calculateTimeIn(h),

        audit:
          obj.device.connection === 'rdp' ? (
            <div>
              <div
                onClick={() => handlePage('RDP', obj)}
                className="pointer rdpIcon cardIcon"
              />
            </div>
          ) : (
            <div
              onClick={() => handlePage('Terminal', obj)}
              className="pointer linuxIcon cardIcon"
            />
          ),
        action:
          obj.contest.state === null ? (
            <Button
              variant="normal"
              label={language.CONTEST}
              onClick={() =>
                this.setState({
                  modalOpen: true,
                  assessmentId: obj.id_assessment,
                  id_contest: null,
                })
              }
            />
          ) : obj.contest.state === 'open' ? (
            language.WAITING
          ) : obj.contest.state === 'justified' ? (
            <Button
              variant="normal"
              label={language.JUSTIFIED}
              onClick={() =>
                this.setState({
                  modalOpen: true,
                  assessmentId: null,
                  id_contest: obj.contest.id_contest,
                  description_tech: obj.contest.description_tech,
                })
              }
            />
          ) : obj.contest.state === 'refused' ? (
            language.REFUSED
          ) : (
            language.ACCEPTED
          ),
      };
    });

    let fields = [
      {
        span: 12,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
      },
    ];

    let button = {
      md: 12,
      label: language.SAVE,
      primary: true,
      style: { margin: '20px 0' },
    };

    return (
      <React.Fragment>
        <CommonTable title="" data={data} columns={col} language={language} />
        <Dialog
          open={modalOpen}
          contentStyle={{ height: '80%', maxWidth: 'none' }}
          fullWidth={true}
          maxWidth="lg"
          autoScrollBodyContent={true}
          onClose={() => this.setState({ modalOpen: false })}
          title={language.CONTEST}
        >
          <div className="container" style={{ padding: '20px 0' }}>
            <Card className="modalCard">
              <div>
                {assessmentId ? (
                  <CommonForm
                    language={language}
                    fields={fields}
                    onFormSubmit={this.onContestSubmit}
                    button={button}
                  />
                ) : (
                  <Row>
                    <Col md={10} offset={{ md: 1 }}>
                      {language.JUSTIFICATION}: {description_tech}
                    </Col>
                    <Col
                      md={4}
                      offset={{ md: 8 }}
                      style={{ padding: '10px 0' }}
                    >
                      <Button
                        variant="normal"
                        label={language.ACCEPT}
                        onClick={() =>
                          this.onResponseSubmit({
                            state: 'accept',
                            id_contest: id_contest,
                          })
                        }
                      />
                      <Button
                        variant="normal"
                        label={language.REFUSE}
                        onClick={() =>
                          this.onResponseSubmit({
                            state: 'refused',
                            id_contest: id_contest,
                          })
                        }
                        style={{ paddingLeft: 10 }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </Card>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
