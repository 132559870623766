
export function ProjectsItemGraph(valor) {

	return {
		chart: {
			type: 'solidgauge',
			height: 200,
			backgroundColor: 'transparent'

		},

		title: null,


		pane: {
			center: ['50%', '90%'],
			size: '100%',
			startAngle: -90,
			endAngle: 90,
			background: {
				backgroundColor: '#EEE',
				innerRadius: '60%',
				outerRadius: '100%',
				shape: 'arc'
			}
		},

		exporting: {
			enabled: false
		},

		tooltip: {
			enabled: false
		},

		// the value axis
		yAxis: {
			stops: [
				[0.1, 'var(--danger)'], // green
				[0.4, '#F6D068'], // yellow
				[0.8, '#92D050'] // red
			],
			lineWidth: 0,
			tickWidth: 0,
			minorTickInterval: null,
			tickAmount: 2,
			title: {
				y: -70
			},
			labels: {
				y: 16,
				enabled: false
			},
			min: 0,
			max: 100,
			title: null,
		},

		plotOptions: {
			solidgauge: {
				dataLabels: {
					y: 0,
					borderWidth: 0,
					useHTML: true
				}
			},

		},

		credits: {
			enabled: false
		},

		series: [{
			name: 'Speed',
			data: [valor > 0 ? parseInt(valor) : 0],
			dataLabels: {
				format: `
		            	<div style="text-align:center;">
		            		<span style="font-size:20px; color:var(--primary);">{y}%</span><br/>
		            	</div>
		            `
			},
			tooltip: null
		}]
	}
}
export function TeamAllocationGraph(props) {
	let { language, mes } = props;
	let colors = [
		'#019FC2',
		'#00CEF5',
		'#00F5DE',
		'#00F669',
		'#8EF500',
		'#E3F300',
		'#FAAF00',
		'#FF5100',
		'#FF0D83',
		'#AF26F9',
		'#00A1C6',
		'#00CEF5',
		'#019FC2',
		'#00CEF5',
		'#00F5DE',
		'#00F669',
		'#8EF500',
		'#E3F300',
		'#FAAF00',
		'#FF5100',
		'#FF0D83',
		'#AF26F9',
		'#00A1C6',
		'#00CEF5',
		'#019FC2',
		'#00CEF5',
		'#00F5DE',
		'#00F669',
		'#8EF500',
		'#E3F300',
		'#FAAF00',
		'#FF5100',
		'#FF0D83',
		'#AF26F9',
		'#00A1C6',
		'#00CEF5',
		'#019FC2',
		'#00CEF5',
		'#00F5DE',
		'#00F669',
		'#8EF500',
		'#E3F300',
		'#FAAF00',
		'#FF5100',
		'#FF0D83',
		'#AF26F9',
		'#00A1C6',
		'#00CEF5',
		'#019FC2',
		'#00CEF5',
		'#00F5DE',
		'#00F669',
		'#8EF500',
		'#E3F300',
		'#FAAF00',
		'#FF5100',
		'#FF0D83',
		'#AF26F9',
		'#00A1C6',
		'#00CEF5'
	]
	return {
		chart: {
			type: 'bar',
			fontFamily: 'Open Sans'
		},
		title: null,
		subtitle: null,
		xAxis: {
			categories: language.DATE_LANG.monthLongList,
			title: {
				text: null
			},
			labels: {
				style: {
					fontSize: 8,
					color: '#BABABA'
				}
			}
		},
		yAxis: {
			min: 0,
			title: null,
			labels: {
				overflow: 'justify',
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				},
				format: '{value}h'
			}
		},
		tooltip: {
			valueSuffix: 'h'
		},
		plotOptions: {
			bar: {
				dataLabels: {
					//enabled: true
				},
				borderRadius: 3
			},
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		series: [{
			name: null,
			data: mes.map((f, i) => ({ color: colors[i], y: f })) //---essa função só gera array aleatório
		}]
	}
}
export function ProjectDeliveryGraph(props) {
	let { total, delay, nodelay } = props;


	delay = parseInt((delay / total) * 100)


	nodelay = parseInt((nodelay / total) * 100)

	var categories = ['Projetos<br/>entregues'];
	return {
		chart: {
			type: 'bar',
			fontFamily: 'Open Sans',
			height: 125
		},
		title: null,
		subtitle: null,
		accessibility: {
			point: {
				valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
			}
		},
		xAxis: [{
			categories: categories,
			reversed: false,
			labels: {
				step: 1,
				style: {
					color: 'var(--primary)'
				}
			},
			accessibility: {
				description: 'Age (male)'
			}
		}, { // mirror axis on right side
			opposite: true,
			reversed: false,
			categories: categories,
			linkedTo: 0,
			labels: {
				step: 1,
				style: {
					color: 'var(--primary)',
					fontFamily: 'Open Sans',
				}
			},
			accessibility: {
				description: 'Age (female)'
			}
		}],
		yAxis: {
			max: 100,
			min: -100,
			title: null,
			labels: {
				formatter: function () {
					return Math.abs(this.value) + '%';
				},
				style: {
					color: 'var(--primary)',
					fontFamily: 'Open Sans',
				}
			},
			accessibility: {
				description: 'Percentual Project',
				rangeDescription: 'Range: 0 to 5%'
			}
		},
		legend: {
			itemStyle: {
				fontSize: 8,
				fontFamily: 'Open Sans',
				color: 'var(--primary)'
			}
		},
		plotOptions: {
			series: {
				stacking: 'normal'
			},
			bar: {
				pointWidth: 60,
				dataLabels: {
					formatter() {
						return Math.abs(this.y) + '%'
					},
					enabled: true,
					style: {
						fontFamily: 'Open Sans',
						fontSize: 17,
						color: 'white',
						textOutline: 0
					}
				},
				borderRadius: 3
			},
		},

		tooltip: {
			formatter: function () {
				return '<b>' + this.series.name + ', age ' + this.point.category + '</b><br/>' +
					'Population: ' + Highcharts.numberFormat(Math.abs(this.point.y), 1) + '%';
			}
		},
		credits: {
			enabled: false
		},

		series: [{
			name: 'Antes do Prazo',
			color: '#00A1C6',
			data: [nodelay]


		}, {
			name: 'Depois do Prazo',
			color: '#F09702',
			data: [delay]


		}]
	}
}
