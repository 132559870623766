import React from 'react';
import {Avatar} from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppBar from '@common/AppBar2';

export default function({scope, setScope, title, label}){
	return(
		<div className='Scope wrap pb-1'>
			<AppBar title={label}>
            <i className='fas fa-eye' style={{cursor:'pointer', color:'var(--primary)'}} onClick={() => setScope({title, body:scope})}/>
         </AppBar>
			<PerfectScrollbar
                className="scrollVisible -mt-2 px-3"
                style={{
                  maxHeight: 110,
                }}
                option={{
                  suppressScrollX:true
                }}
            >
            	<p 
            		className='text pl-1'
            		dangerouslySetInnerHTML={{ __html: scope }}
            	/>
            </PerfectScrollbar>
		</div>
	)
}