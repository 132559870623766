import React, { useState, Fragment } from 'react';
import { MoreVert } from '@material-ui/icons';
import { Menu, MenuItem, IconButton } from '@material-ui/core';

import Progress from '../../common/Progress';
import CommonTable from '../../common/CommonTable';

import moment from 'moment';

export default function ({
  bscItem,
  onEdit,
  deleteItem,
  onAdd,
  onView,
  projects,
  department_user,
  language,
}) {
  let [anchorEl, setAnchorEl] = useState(null);
  let [itemOpen, setItemOpen] = useState({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (!event) setItemOpen({});
  };

  var firstBsctialls;

  var data = bscItem.bsccorps.reduce((obj, e) => {
    for (const [idx, t] of e.bsctialls.entries()) {
      obj.push({
        actions: (
          <IconButton
            aria-label={language.MORE}
            aria-owns={anchorEl ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={(evt) => {
              handleClick(evt);
              setItemOpen({
                name: e.name,
                fk_id_bsc: e.fk_id_bsc,
                id_bsc_goal: e.id_bsc_goal,

                fk_id_bsc_goal: t.fk_id_bsc_goal,
                id_bsc_goal_ti: t.id_bsc_goal_ti,
                dt_meta: t.dt_meta,
                name_OTI: t.name,

                indicators: t.indicators,
                fk_id_project: parseInt(t.fk_id_project),
              });
            }}
            style={{ transform: 'rotate(90deg)' }}
          >
            <MoreVert style={{ color: 'var(--success)' }} />
          </IconButton>
        ),
        corporate_objective: e.name,
        it_objective: t.name,
        metas: moment(t.dt_meta).format('L'),
        project_name: t.fk_id_project
          ? !!projects &&
            !!projects.find((x) => x.id_project === parseInt(t.fk_id_project))
            ? projects.find((x) => x.id_project === parseInt(t.fk_id_project))
                .name
            : ''
          : '',
        dtinicio: t.fk_id_project
          ? !!projects &&
            !!projects.find((x) => x.id_project === parseInt(t.fk_id_project))
            ? moment(
                projects.find((x) => x.id_project === parseInt(t.fk_id_project))
                  .start_date
              ).format('L')
            : ''
          : '',
        dtfim: t.fk_id_project
          ? !!projects &&
            !!projects.find((x) => x.id_project === parseInt(t.fk_id_project))
            ? moment(
                projects.find((x) => x.id_project === parseInt(t.fk_id_project))
                  .end_date
              ).format('L')
            : ''
          : '',
        status: t.fk_id_project ? (
          <div className="d-flex align-items-center" style={{ width: 200 }}>
            <Progress
              current={
                !!projects &&
                !!projects.find((x) => x.id_project === parseInt(t.fk_id_project))
                ? projects.find((x) => x.id_project === parseInt(t.fk_id_project)).percent 
                : 0
              }
              hiddenText
              background="var(--danger)"
              style={{ width: '100%', height: 7 }}
            />
            <span className="mt-2">
              {!!projects &&
                !!projects.find((x) => x.id_project === parseInt(t.fk_id_project))
                ? projects.find((x) => x.id_project === parseInt(t.fk_id_project)).percent 
                : 0
              } %
            </span>
          </div>
        ) : (
          <div className="d-flex align-items-center" style={{ width: 200 }}>
            <Progress
              current={t.percent || 0}
              hiddenText
              background="var(--danger)"
              style={{ width: '100%', height: 7 }}
            />
            <span className="mt-2">{t.percent || 0}%</span>
          </div>
        ),
      });
    }
    return obj;
  }, []);

  return (
    <Fragment>
      <CommonTable
        bar={false}
        className="expanded_table"
        searchColumn
        paper={false}
        col={[
          {
            key: 'actions',
            label: language.ACTIONS,
            thConfig: true,
            style: { paddingLeft: 50, width: '1%' },
          },
          {
            key: 'corporate_objective',
            label: language.BSC_OB,
            thConfig: true,
            style: { width: '15%' },
          },
          {
            key: 'it_objective',
            label: `${language.OBJECTIVE} ${department_user.name ? `${language.OF} ${department_user.name}` : language.OF_THE_IT}`,
            thConfig: true,
            style: { width: '30%' },
          },
          {
            key: 'metas',
            label: language.BSC_METAS,
            thConfig: true,
            style: { width: '9%' },
          },
          {
            key: 'project_name',
            label: language.PROJECT,
            thConfig: true,
            style: { width: '15%' },
          },
          {
            key: 'dtinicio',
            label: language.INITIAL_DATE,
            thConfig: true,
            style: { width: '9%' },
          },
          {
            key: 'dtfim',
            label: language.FINAL_DATE,
            thConfig: true,
            style: { width: '9%' },
          },
          {
            key: 'status',
            label: language.STATUS,
            thConfig: true,
            style: { width: '12%' },
          },
        ]}
        data={data}
      />

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {[
          {
            icon: 'fas fa-plus-circle',
            label: language.BSC_TI,
            color: 'var(--warning)',
          },
          { icon: 'fas fa-eye', label: language.PROJECT, color: 'var(--primary)' },
          {
            icon: 'fas fa-pencil-alt',
            label: language.EDIT,
            color: 'var(--success)',
          },
          {
            icon: 'fas fa-trash-alt',
            label: language.DELETE,
            color: 'var(--danger)',
          },
        ].map(
          (e, i) =>
            (i == 1 ? !!itemOpen.fk_id_project : true) && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  if (i == 0) {
                    onAdd({
                      name: itemOpen.name,
                      fk_id_bsc: itemOpen.fk_id_bsc,
                      id_bsc_goal: itemOpen.id_bsc_goal,
                    });
                  } else if (i == 1) {
                    onView(itemOpen.fk_id_project);
                  } else if (i == 2) {
                    onEdit(itemOpen);
                  } else if (i == 3) {
                    deleteItem(itemOpen.id_bsc_goal_ti);
                  }
                }}
              >
                <i className={e.icon} style={{ color: e.color }} />
                <span
                  style={{ fontSize: 15, color: 'var(--primary)' }}
                  className="ml-2"
                >
                  {e.label}
                </span>
              </MenuItem>
            )
        )}
      </Menu>
    </Fragment>
  );
}
