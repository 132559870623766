import { useState, useEffect } from 'react';
import Store from '../../data/Store';
import Constants from '../../data/Constants';

const CtxVariables = () => {
  const [login, setLogin] = useState('');
  const [token, setToken] = useState('');
  const [list, setList] = useState([]);

  const tenant = Constants.Tenant;

  useEffect(() => {
    Store.socket.on(`onUser-${tenant}`, (data) => {
      //   console.log("user",data)
      setList(data.list);
    });
  }, [Store.socket]);

  const setCtxLogin = (value) => {
    setLogin(value);
  };
  const setCtxToken = (value) => {
    setToken(value);
  };

  const variables = {
    login,
    setCtxLogin,
    token,
    setCtxToken,
    list,
  };
  return { variables };
};

export { CtxVariables };
