import CommonForm from '@common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import Button from '@common/Button';
import RaisedButton from '../../common/RaisedButton';
import Dialog from '@common/Dialog';
import React, { useEffect, useState, Fragment } from 'react';
import { Chip } from '@material-ui/core';
import CommonDelete from '../../common/CommonDelete';

const action = new Actions();
const language = Store.language;
const ResolutionCodeContainer = ({}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState('');
  const [values, setValues] = useState({});
  const [global, setGlobal] = useState(false);
  const [hasGlobal, setHasGlobal] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    action
      .execute('get', Constants.APIEndpoints.RESOLUTION_CODE, '')
      .then((res) => {
        setHasGlobal(!!res.data.find((d) => d.fk_id_company == 0));
        setData(res.data);
      });
  };

  const handlePage = (page, obj = {}, global = false) => {
    setPage(page);
    setValues(obj);
    setGlobal(global);
  };

  const saveResolution = (values) => {
    values.is_global = global;
    const method = values.id_resolution_code ? 'put' : 'post';
    action
      .execute(method, Constants.APIEndpoints.RESOLUTION_CODE, '', values)
      .then((res) => {
        handlePage('list');
        getData();
      });
  };

  const deleteResolution = () => {
    action
      .execute(
        'delete',
        Constants.APIEndpoints.RESOLUTION_CODE +
          '/' +
          values.id_resolution_code,
        ''
      )
      .then((res) => {
        handlePage('list');
        getData();
      });
  };
  const cols = [
    { key: 'company', label: language.COMPANY },
    { key: 'active', label: language.ACTIVE },
    { key: 'type', label : language.TYPE},
    { key: 'code_resolution_name', label: language.CODE_RESOLUTION_NAME },
    { key: 'code_resolution_value', label: language.CODE_RESOLUTION_VALUE },
    { key: 'action', label: language.ACTION },
  ];

  const getType = [
    '',
    language.INCIDENT,
    language.REQUEST
  ]

  const dataTable = data.map((d) => {
    return {
      company: d.company ? d.company : 'Global',
      active : d.active ? language.YES : language.NOT,
      type: getType[d.type],
      code_resolution_name : d.code_resolution_name,
      code_resolution_value : d.code_resolution_value,
      action: (
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <RaisedButton
                  circleButton
                  icon="fas fa-edit"
                  label={language.EDIT}
                  color="default"
                  style={{ fontSize: 12, width: 21, height: 21 }}
                  className="mr-2"
                  onClick={() => handlePage('add', d, d.fk_id_company == 0 ? true : false)}
                />
                <RaisedButton
                  circleButton
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => handlePage('remove', d)}
                />
              </div>
            }
          />
        </React.Fragment>
      ),
    };
  });

  return (
    <div>
      <CommonTable
        beforeBar={
          <div>
            <Button
              variant="circle"
              icon="fas fa-plus"
              color="warning"
              label = {language.ADD}
              className="mr-3"
              onClick={() => handlePage('add', {})}
            />
            ,
            {hasGlobal == false && (
              <Button
                variant="circle"
                icon="fas fa-globe"
                label = {language.ADD_GLOBAL}
                color="warning"
                className="mr-3"
                onClick={() => handlePage('add', {}, true)}
              />
            )}
          </div>
        }
        data={dataTable}
        col={cols}
      />
      {page == 'add' && (
        <Dialog
          open={true}
          onClose={() => handlePage('list')}
          title={language.ADD}
        >
          <CommonForm
            fields={[
              {
                col: 6,
                type: 'autocomplete',
                name: 'fk_id_company',
                label: language.COMPANY,
                method: 'POST',
                route: `${Constants.APIEndpoints.COMPANY}/filter`,
                fieldquery: 'search',
                textlabel: language.COMPANY,
                textinit: values.company ? values.company : '',
                fieldvaluedb: 'id_company',
                fieldlabeldb: 'name',
                idinit: values.fk_id_company ? values.fk_id_company : 0,
                routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                required: global == true ? false : true,
                visible: global == true ? false : true,
              },
              {
                col: 2,
                label: language.ACTIVE,
                type: 'switch',
                name: 'active',
              },
              {
                col: 4,
                label: language.TYPE,
                type: 'select',
                name: 'type',
                required : true,
                options: [
                  {value: 1, label : language.INCIDENT},
                  {value: 2, label : language.REQUEST}
                ]
              },
              {
                col: 6,
                type: 'text',
                name: 'code_resolution_name',
                label: language.CODE_RESOLUTION_NAME,
              },
              {
                col: 6,
                type: 'text',
                name: 'code_resolution_value',
                label: language.CODE_RESOLUTION_VALUE,
              },
            ]}
            values={values}
            onSubmit={saveResolution}
          />
        </Dialog>
      )}
      {page === 'remove' ? (
        <CommonDelete
          language={language}
          closeModal={() => handlePage('list')}
          onDelete={deleteResolution}
          message={values.company}
        />
      ) : null}
    </div>
  );
};
export default ResolutionCodeContainer;
