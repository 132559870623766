import React, { Component } from 'react';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import {TableCell} from '@material-ui/core'; //table
import {Button, Paper, ClickAwayListener, Menu, MenuItem, Fade} from '@material-ui/core'; //menu
import '../../bClass.css';
class TD extends Component{
	constructor(props){
		super(props);
		this.state = {
			dropdown:false,
			
		}
	}
	toggleDropdown = ()=>{
   		this.setState((state)=>({
   			dropdown:!state.dropdown
   		}));
   	}
   	handleClose = event => {
	    if (this.anchorEl.contains(event.target)) {
	      return;
	    }

	    this.setState({ dropdown: false });
	};
   	activeModalRename = (val)=>{
   		this.props.toggleModalRename(val);
   	}
   	activeModalExtend = (val)=>{
   		this.props.toggleModalExtend(val);
   	}
	render(){
		var {tr_e, tr_i, td_e, td_i, i} = this.props;
		var dropdown = classnames({
			open:this.state.dropdown
	    });
	    var ModalData = {tbody:i, tr:tr_i, td:td_i, rowspan:td_e.rowspan}
		return(
			<TableCell typeTd={td_e.name.toLowerCase()} td-pos={JSON.stringify({tbody:i, tr:tr_i, td:td_i})} rowspan={td_e.rowspan}>
													
				<span className='my-dropdown pmd-dropdown'>
				    
				    {/*<a href="#" className='control' onClick={this.toggleDropdown}><i className="fas fa-cog"></i></a>*/}
				    <Button
			            buttonRef={node => {
			              this.anchorEl = node;
			            }}
			           	className='control'
			            onClick={this.toggleDropdown}
			        >
			            <i className="fas fa-cog"></i>
			        </Button>
				   
				    <Menu
				   		TransitionComponent={Fade}
				   		open={this.state.dropdown} 
				   		onClose={this.toggleDropdown}
				   		anchorEl={this.anchorEl}
				   	>
				   		<MenuItem className="py-4" onClick={()=>this.activeModalRename(ModalData)}>Renomear</MenuItem>
			            <MenuItem className="py-4" onClick={()=>this.activeModalExtend(ModalData)}>Extender</MenuItem>
				   	</Menu>
				</span>
				
				{td_e.name}
			</TableCell>
		)
	}
}
export default TD;
