import Store from '@data/Store';
import React, { useEffect, useReducer, useState } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import Constants from '../../data/Constants';
import ServicePortalApprovals from './ServicePortalApprovals';
import ServicePortalContainer from './ServicePortalContainer';
import ServicePortalTicketDetails from './ServicePortalTicketDetails';
import ServicePortalTickets from './ServicePortalTickets';
import { EmptyConfig, EmptyRoute } from './components/EmptyState';
import { ServicePortalHeader } from './components/ServicePortalHeader';
import { ServicePortalLoading } from './components/ServicePortalLoading';
import { ServicePortalLogo } from './components/ServicePortalLogo';
import { ServicePortalMenu } from './components/ServicePortalMenu';
import { appendStyle, encrypt, extractUrls, injectCSS, injectScript, parseJwt, spFetch, styleToString } from './helpers';

export default function ServicePortalApp() {

    const { language } = Store;
    const tokenData = parseJwt(sessionStorage.getItem('token'));
    
    const [isCompanyLoaded, setIsCompanyLoaded] = useReducer(v => !v, false);
    const [companyId, setCompanyId] = useState();
    const [params, setParams] = useState({});
    const [menuItems, setMenuItems] = useState([]);
    const [tabItems, setTabItems] = useState([]);
    
    let location = useLocation();

    useEffect(() => {

        getStructureData();

        document.body.classList.add('sp-content');

        return () => {
            document.body.classList.remove('sp-content');
            ['sp-base', 'sp-default', 'sp-customcss'].forEach(item => appendStyle(item, ''));

            sessionStorage.removeItem('urgencies');
            sessionStorage.removeItem('impacts');
            sessionStorage.removeItem('priorities');
            sessionStorage.removeItem('ticketParams');
        }

    }, []);

    function hasStructureData() {
        const urgencies = sessionStorage.getItem('urgencies');
        const impacts = sessionStorage.getItem('impacts');
        const priorities = sessionStorage.getItem('priorities');
        const ticketParams = sessionStorage.getItem('ticketParams');

        return urgencies && impacts && priorities && ticketParams;
    }

    function getStructureData() {

        if (hasStructureData())
            return getData();

        const urlUrgencies = `${Constants.APIEndpoints.TABLE_STRUCTURED}/40`;
        const urlImpacts = `${Constants.APIEndpoints.TABLE_STRUCTURED}/50`;
        const urlPriorities = `${Constants.APIEndpoints.TABLE_STRUCTURED}/60`;
        const urlTicketParams = `${Constants.APIEndpoints.TICKET}/params`;

        const fetchUrgencies = spFetch(urlUrgencies);
        const fetchImpacts = spFetch(urlImpacts);
        const fetchPriorities = spFetch(urlPriorities);
        const fetchTicketParams = spFetch(urlTicketParams);

        Promise
            .all([fetchUrgencies, fetchImpacts, fetchPriorities, fetchTicketParams])
            .then(results => {
                const resultUrgencies = results[0]?.data;
                const resultImpacts = results[1]?.data;
                const resultPriorities = results[2]?.data;
                const resultTicketParams = results[3]?.data;

                sessionStorage.setItem('urgencies', encrypt(JSON.stringify(resultUrgencies)));
                sessionStorage.setItem('impacts', encrypt(JSON.stringify(resultImpacts)));
                sessionStorage.setItem('priorities', encrypt(JSON.stringify(resultPriorities)));
                sessionStorage.setItem('ticketParams', encrypt(JSON.stringify(resultTicketParams)));
            })
            .catch((error) => { console.log(error); setIsCompanyLoaded(true)})
            .finally(() => getData());
    }

    function getData() {
        const urlPersonalization = `${Constants.APIEndpoints.COMPANY}/${tokenData.iss.company.id}/portal`;
        const urlGeneralParams = `${Constants.APIEndpoints.GENERAL_PARAMETERS}?parameters=mult_catalog,allow_customize_priority,categorization_automatic,integration,enable_location_in_opening_ticket`

        //const fetchPersonalization = fetch.execute('get', urlPersonalization, '');
        //const fetchGeneralParams = fetch.execute('get', urlGeneralParams, '');
        const fetchPersonalization = spFetch(urlPersonalization);
        const fetchGeneralParams = spFetch(urlGeneralParams);

        Promise
            .all([fetchPersonalization, fetchGeneralParams])
            .then(results => {

                const resultGeneralParams = results[1]?.data;
                setParams(resultGeneralParams);

                setCompanyId(tokenData.iss.company.id)

                const resultPersonalization = results[0]?.data;
                resultPersonalization && applyPersonalization(resultPersonalization);
                resultPersonalization && resultPersonalization.ivi_code && configureChatBotIvi(resultPersonalization.ivi_code);

            })
            .catch(() => setIsCompanyLoaded(true));
    }

    function applyPersonalization(data) {
        const styleForm = {
            spLogo: {},
            spMenu: {},
            spHeader: {},
            spHeaderContainer: {},
            spSubheader: {},
            spSubheaderContainer: {},
            spContainer: {},
            spCatalog: {},
            spCatalogItem: {},
            spCatalogSubitem: {},
            spButton: {},
            spButtonAction: {},
            twContainer: {},
            spWrapper: {}
        }

        if (data?.layout_fluid) {
            styleForm.twContainer = {
                maxWidth: 'unset !important',
                paddingRight: '1.5rem',
                paddingLeft: '1.5rem',
            }
        }

        styleForm.spWrapper = {
            backgroundImage: `url('${data.homepage_background_data}')`,
            backgroundColor: data.homepage_background_color,
            color: data.homepage_font_color,
            minHeight: '100vh',
            '--primary': '#000000de', //borda dos inputs nos forms do catálogo
            '--success': data.button_action_background_color, //background-color dos botões nos forms do catálogo
            '--primary-customer': data.button_action_background_color, //background-color dos botões nos forms do catálogo
        }

        styleForm.spLogo = {
            backgroundImage: `url('${data.logo_data}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            left: data.logo_position_left ? data.logo_position_left + '%' : data.logo_position_left,
            top: data.logo_position_top ? data.logo_position_top + '%' : data.logo_position_top,
            width: '180px',
            height: '60px',
            position: 'relative'
        }

        styleForm.spHeader = {
            backgroundColor: data.topbar_background_color,
            backgroundImage: `url('${data.topbar_background_data}')`,
            backgroundPosition: 'center',
            backgroundSize: '100% auto',
            color: data.topbar_font_color,
        }

        styleForm['sp-menu-item a'] = {
            color: data.topbar_font_color,
        }

        styleForm['sp-menu-item .sp-link'] = {
            color: data.topbar_font_color,
        }

        styleForm['sp-menu-item a:hover'] = {
            color: data.topbar_font_color_hover,
            cursor: 'pointer'
        }

        styleForm.spSubheader = {
            backgroundColor: data.topbar_sub_background_color,
            color: data.topbar_sub_font_color,
        }

        styleForm.spButton = {
            backgroundColor: data.button_default_background_color + ' !important',
            color: data.button_default_font_color + ' !important',
        }

        styleForm.spButtonAction = {
            backgroundColor: data.button_action_background_color + ' !important',
            color: data.button_action_font_color + ' !important',
        }

        styleForm.spCatalogItem = {
            fontSize: data.catalog_item_title_font_size + 'px',
            color: data.catalog_item_font_color
        }

        styleForm['spCatalogItem:hover'] = {
            backgroundColor: data.button_action_background_color,
            color: data.button_action_font_color,
        }

        styleForm.spCatalogSubitem = {
            color: data.homepage_font_color
        }

        styleForm['spCatalogSubitem:hover'] = {
            backgroundColor: data.button_action_background_color,
            color: data.button_action_font_color,
        }

        styleForm.spCatalogCategoryItem = {
            backgroundColor: data.catalog_item_background_color + ' !important',
            color: data.catalog_item_font_color,
            '-webkit-transition': '-webkit-transform 0.3s ease',
            '-moz-transition': '-moz-transform 0.3s ease',
            'transition': 'transform 0.3s ease'
        }

        styleForm['spCatalogCategoryItem:hover'] = {
            backgroundColor: data.catalog_item_background_color + ' !important',
            '-webkit-transform': 'scale(1.03)',
            '-moz-transform': 'scale(1.03)',
            '-o-transform': 'scale(1.03)',
            'transform': 'scale(1.03)'
        }

        styleForm['spCatalogCategoryItem p'] = {
            color: data.catalog_item_font_color
        }

        styleForm['spCatalogCategoryItem h2'] = {
            color: data.catalog_item_title_font_color,
            fontSize: data.catalog_item_title_font_size + 'px',
        }

        styleForm['spItemSelected:not(:hover)'] = {
            backgroundColor: data.button_action_background_color,
            color: data.button_action_font_color,
        }

        styleForm['spBorder'] = {
            border: `1px solid ${data.button_action_background_color}`,
        }

        const styles = Object.keys(styleForm).reduce((acc, key) => (
            acc + '.' + key.split(/(?=[A-Z])/).join('-').toLowerCase() + '{' + styleToString(styleForm[key]) + '}'
        ), '');

        const styleBase = `
            html, body{
                background-color: #ffffff !important;
            }
            body {
                font-size: 12px;
            }
            .sp-content {
                --primary: #000000de;
                --success: ${data.button_action_background_color};
                --primary-customer: ${data.button_action_background_color};
            }
            .sp-container {
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            }
            .sp-container .ql-toolbar.ql-snow, 
            .sp-container .template-dinamic label,
            .sp-container .CommonForm2 .label-text-editor { 
                font-size: 13px; 
                font-weight: normal; 
                font-family: sans-serif !important; 
            }

            .sp-container .quill.commonForm-react-quill {
                //border: 1px solid var(--primary);
                //border-radius: 4px;
            }

            .sp-container .ql-editor {
                font-size: 14px;
                color: currentColor;
                border: 1px solid var(--primary) !important;
                border-radius: 4px !important;
                margin-top: 2px;
            }

            .sp-container  .CommonForm2 .commonForm-react-quill .ql-container {
                /*border-color: var(--primary)*/
                border: 0;
            }

            .sp-container .ql-toolbar.ql-snow {
                /*border-color: var(--primary)*/
                border: 0;
                /*margin: 10px 10px 0;*/
                border-radius: 4px;
                background: #eaecec;
            }

            .sp-container .form-control.ql-container.ql-snow{
                display: none;
            }

            .sp-container .sp-spinner-border {
                --bs-spinner-width: 1.2rem;
                --bs-spinner-height: 1.2rem;
                --bs-spinner-vertical-align: -0.125em;
                --bs-spinner-border-width: 0.25em;
                --bs-spinner-animation-speed: 0.75s;
                --bs-spinner-animation-name: sp-spinner-border;
                border: var(--bs-spinner-border-width) solid currentcolor;
                border-right-color: transparent;
            }

            .sp-container .sp-spinner-border {
                display: inline-block;
                width: var(--bs-spinner-width);
                height: var(--bs-spinner-height);
                vertical-align: var(--bs-spinner-vertical-align);
                border-radius: 50%;
                margin-right: 6px;
                animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
            }
            
            @keyframes sp-spinner-border {
                to { transform: rotate(360deg); }
            }
            .sp-container textarea {
                width: 100%;
                border: 1px solid var(--primary);
                border-radius: 4px;
                padding: 8px;
            }

            .sp-container .CommonForm2 .divAutoComplete div {
                overflow-x: inherit;
                overflow-y: inherit;
            }

            .sp-container .table-23-sensr thead tr,
            .sp-container .CommonForm2 .darks>div
            {
                background-color: #eeeeee;
                border-radius: 4px;
            }

            .sp-content .MuiAutocomplete-tag {
                margin: 1px;
            }
            .sp-content .hr hr {
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
            }

            .sp-container .form-view .template-dinamic [class^="formbuilder-"] {
                color: #6b7280;
                font-size: 12px;
            }
            .sp-container .form-view .template-dinamic .form-control {
                border-color: rgba(0, 0, 0, 0.12);
                color: #6b7280;
                background-color: rgba(243,244,246,1);
            }
            
            @keyframes sp-slide-in {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
              
            .sp-content .slide-in {
                animation: sp-slide-in 0.5s ease-in-out forwards;
            }

            .sp-content .approval-container {
              width: 0px;
              overflow: hidden;
              transition: all 0.6s ease; 
            }
            
            .sp-content .approval-container.expanded {
              width: 230px; 
            }
        `;

        appendStyle('sp-base', styleBase)
        appendStyle(`sp-default`, styles);

        if (data.custom_css_text)
            appendStyle(`sp-customcss`, data.custom_css_text);

        if (data.menu) {
            const companyMenus = data.menu.filter(item => item.checked);
            setMenuItems(companyMenus.map(item => ({ label: item.lang_key, url: '', company_id: tokenData.iss.company.id, company_portal_id: data.id_company_portal })));
        }
        if (data.tabs) {
            const companyTabs = data.tabs.filter(item => item.checked);
            setTabItems(companyTabs.map(item => item.name))
        }
        setIsCompanyLoaded(true);
    }

    function configureChatBotIvi(dataCode) {
        const { css, js } = extractUrls(dataCode);
        css.forEach(href => injectCSS(href));
        js.forEach(src => injectScript(src));
        !window['ivi_init'] && setTimeout(() => {
            dispatchEvent(new Event('load'));
            window['ivi_init'] = true;
        }, 3000);
    }

    return (
        <div className='sp-wrapper tw-flex tw-flex-col'>

                {!isCompanyLoaded && <ServicePortalLoading />}

                {isCompanyLoaded && companyId > 0 &&
                    <>

                        <ServicePortalHeader>
                            <ServicePortalLogo></ServicePortalLogo>
                            <ServicePortalMenu
                                items={menuItems}
                                language={language}
                            ></ServicePortalMenu>
                        </ServicePortalHeader>

                        <Switch>
                            <Route exact path="/dash/service-portal">
                                <ServicePortalContainer
                                    language={language}
                                    key={companyId + (location?.key || '')}
                                    isMultiCatalog={params?.mult_catalog ?? false}
                                    isIntegrationEnabled={params?.integration ?? false}
                                    allowCustomizePriority={params?.allow_customize_priority ?? false}
                                    enableLocationInOpeningTicket={params?.enable_location_in_opening_ticket ?? false}
                                >
                                </ServicePortalContainer>
                            </Route>
                            <Route exact path="/dash/service-portal/my-approvals">
                                <ServicePortalApprovals params={{ automaticCategorization: params?.categorization_automatic ?? false }} />
                            </Route>
                            <Route exact path="/dash/service-portal/my-tickets">
                                <ServicePortalTickets params={{ automaticCategorization: params?.categorization_automatic ?? false }} />
                            </Route>
                            <Route path="/dash/service-portal/my-tickets/:id">
                                <ServicePortalTicketDetails params={{ automaticCategorization: params?.categorization_automatic ?? false }} tabItems={tabItems} />
                            </Route>
                            <Route path="/dash/service-portal/*">
                                <EmptyRoute />
                            </Route>
                        </Switch>
                    </>
                }

                {isCompanyLoaded && !companyId && (
                    <EmptyConfig language={language} />
                )}
        </div >
    )
}
