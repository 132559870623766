import React, { Component } from 'react';
import Terminal from 'xterm/dist/xterm.js';

export default class TerminalTab extends Component {
  constructor() {
    super();
    this.calcGeometry = this.calcGeometry.bind(this);
  }

  calcGeometry(term) {
    let parentElementStyle = window.getComputedStyle(
      term.element.parentElement
    ),
      parentElementHeight = parseInt(
        parentElementStyle.getPropertyValue('height')
      ),
      parentElementWidth = Math.max(
        0,
        parseInt(parentElementStyle.getPropertyValue('width')) - 17
      ),
      elementStyle = window.getComputedStyle(term.element),
      elementPaddingVer =
        parseInt(elementStyle.getPropertyValue('padding-top')) +
        parseInt(elementStyle.getPropertyValue('padding-bottom')),
      elementPaddingHor =
        parseInt(elementStyle.getPropertyValue('padding-right')) +
        parseInt(elementStyle.getPropertyValue('padding-left')),
      availableHeight = parentElementHeight - elementPaddingVer,
      availableWidth = parentElementWidth - elementPaddingHor,
      container = term.rowContainer,
      subjectRow = term.rowContainer.firstElementChild,
      contentBuffer = subjectRow.innerHTML,
      characterHeight,
      rows,
      characterWidth,
      cols,
      geometry;

    subjectRow.style.display = 'inline';
    subjectRow.innerHTML = 'W';
    characterWidth = subjectRow.getBoundingClientRect().width;
    subjectRow.style.display = '';
    characterHeight = subjectRow.getBoundingClientRect().height;
    subjectRow.innerHTML = contentBuffer;

    rows = parseInt(availableHeight / characterHeight);
    cols = parseInt(availableWidth / characterWidth);

    geometry = { cols: cols, rows: rows };
    return geometry;
  }

  componentDidMount() {
    let { io, path, store } = this.props;
    let term = new Terminal();

    term.open(this.refs[path]);

    let geometry = this.calcGeometry(term);
    term.resize(geometry.cols, geometry.rows);

    term.on('data', data => {
      io.emit(path, data);
    });

    io.on('connect', () => {
      io.emit(store.getToken(), {
        path: path,
        params: { cols: geometry.cols, rows: geometry.rows }
      });
    });

    io.on(path, data => {
      term.write(data);
    });

    term.textarea.onkeydown = e => {
      if (e.keyCode == 13) {
        let rows = Array.prototype.slice.call(
          term.element.getElementsByClassName('xterm-rows')[0].children
        );
        rows = rows
          .map(line => line.children[0].innerHTML.replace(/&nbsp;/g, ' '))
          .filter(line => /\S/g.test(line));
        io.emit(path + '_log', rows[rows.length - 1]);
      }
    };
  }

  render() {
    let { active, path } = this.props;
    return (
      <div
        style={
          active ? { height: '75vh' } : { height: '75vh', display: 'none' }
        }
        className="default-center"
        ref={path}
      />
    );
  }
}
