import React, { Component } from 'react';
import { observer } from 'mobx-react';

import TagList from './TagList';

import CommonPage from '../../common/CommonPage';

@observer
export default class TagContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list'
    };
  }

  handlePage(page) {
    this.setState({ page: page });
  }

  render() {
    let { language } = this.store;
    let { page, values, open } = this.state;
    return (
      <CommonPage
        title={
          <div
            className="logoWhite"
            onClick={() => this.redirect('/dash')}
          />
        }
        showMenuIconButton={false}
        iconAlert={true}
      >
        <TagList
          onFormSubmit={this.onFormSubmit}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          handlePage={this.handlePage}
          language={language}
          redirect={this.redirect}
          {...this.state}
        />
      </CommonPage>
    );
  }
}
