import { useState } from 'react';
import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import Button from '@common/Button';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '@common/CommonForm2';
import { formatCurrency } from '../../../../util';
import PageLayout from '../../../../common/PageLayout';
import moment from 'moment';
import Help from '../../../../common/components/Help';
import Store from '@data/Store';

import CommonTable from '../../../../common/CommonTable';
import Dialog from '@common/Dialog';

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ComposedChart,
  Line,
  Area,
} from 'recharts';

import { Card, TextField, MenuItem, Divider, Grid } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../graphs/SimplesBarGrap';
import ButtonControlYear from '../../../dashboard/block_5/ButtonControlYear';
import './tech.css';
import './TechGroup.css';
import Constants from '../../../../data/Constants';
import DownloadFiles from '../../../../util/DownloadFiles';
export default function TechGroup({
  language,
  handlePage,
  APIEndpoints,
  action,
}) {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedGroups, setSelectedGroups] = useState([]);
  const values = { year: selectedYear, month: selectedMonth };
  const [data, setData] = useState([]);

  const createReportExcel = () => {
    const values = {
      fk_id_group: selectedGroups,
      month: selectedMonth + 1,
      year: selectedYear,
      user: Store.getUserLoged(),
    };

    action
      .execute(
        'post',
        APIEndpoints.REPORTEXPORT + '/groupreportexcel',
        '',
        values,
        ''
      )
      .then((res) => {
        DownloadFiles(
          APIEndpoints.REPORTEXPORT +
            '/downloadgroupreport/' +
            Store.getUserLoged().id +
            '/' +
            res.data.file,
          res.data.file
        );
      });
  };

  const createReportSintetic = () => {
    const values = {
      fk_id_group: selectedGroups,
      month: selectedMonth + 1,
      year: selectedYear,
      user: Store.getUserLoged(),
    };
    action
      .execute(
        'post',
        APIEndpoints.REPORTEXPORT + '/groupreport',
        '',
        values,
        ''
      )
      .then((res) => {
        setData(res.data);
      });
  };
  return (
    <PageLayout
      icon={
        <div className="imgGcf" style={{ width: '44px', height: '44px' }} />
      }
      title={language.REPORTS}
      subtitle={language.REPORTS_FOR_TECH_GROUP}
      back={() => handlePage('dash')}
      style={{ zoom: 0.8, width: 1000 }}
      rightElements={[
        <div style={{ width: 1000, marginTop: 20, padding: 5 }}>
          <CommonForm
            values={values}
            onChangeField={(f, v) => {
              switch (f.name) {
                case 'fk_id_group':
                  setSelectedGroups(v);
                  break;
                case 'year':
                  setSelectedYear(v);
                  break;
                case 'month':
                  setSelectedMonth(v);
                  break;
                default:
                  break;
              }
            }}
            fields={[
              {
                col: 6,
                type: 'autocomplete',
                name: 'fk_id_group',
                label: 'Grupo',
                method: 'POST',
                route: `${APIEndpoints.GROUP}/filter`,
                fieldquery: 'search',
                textlabel: 'Grupo Técnico',
                textinit: 'Grupo técnico',
                fieldvaluedb: 'id_group_users',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${APIEndpoints.GROUP}/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${APIEndpoints.GROUP}/count`,
                detail: true,
                isMulti: true,
              },

              {
                col: 3,
                type: 'select',
                name: 'year',
                label: language.YEAR,
                options: [
                  {
                    value: moment().year() - 2,
                    label: moment().year() - 2,
                  },
                  {
                    value: moment().year() - 1,
                    label: moment().year() - 1,
                  },
                  { value: moment().year(), label: moment().year() },
                  {
                    value: moment().year() + 1,
                    label: moment().year() + 1,
                  },
                  {
                    value: moment().year() + 2,
                    label: moment().year() + 2,
                  },
                  {
                    value: moment().year() + 3,
                    label: moment().year() + 3,
                  },
                  {
                    value: moment().year() + 4,
                    label: moment().year() + 4,
                  },
                  {
                    value: moment().year() + 5,
                    label: moment().year() + 5,
                  },
                ],
              },
              {
                col: 3,
                type: 'select',
                name: 'month',
                label: language.MONTH,
                options: [
                  {
                    value: 0,
                    label: `01 ${language.DATE_LANG.monthLongList[0]}`,
                  },
                  {
                    value: 1,
                    label: `02 ${language.DATE_LANG.monthLongList[1]}`,
                  },
                  {
                    value: 2,
                    label: `03 ${language.DATE_LANG.monthLongList[2]}`,
                  },
                  {
                    value: 3,
                    label: `04 ${language.DATE_LANG.monthLongList[3]}`,
                  },
                  {
                    value: 4,
                    label: `05 ${language.DATE_LANG.monthLongList[4]}`,
                  },
                  {
                    value: 5,
                    label: `06 ${language.DATE_LANG.monthLongList[5]}`,
                  },
                  {
                    value: 6,
                    label: `07 ${language.DATE_LANG.monthLongList[6]}`,
                  },
                  {
                    value: 7,
                    label: `08 ${language.DATE_LANG.monthLongList[7]}`,
                  },
                  {
                    value: 8,
                    label: `09 ${language.DATE_LANG.monthLongList[8]}`,
                  },
                  {
                    value: 9,
                    label: `10 ${language.DATE_LANG.monthLongList[9]}`,
                  },
                  {
                    value: 10,
                    label: `11 ${language.DATE_LANG.monthLongList[10]}`,
                  },
                  {
                    value: 11,
                    label: `12 ${language.DATE_LANG.monthLongList[11]}`,
                  },
                ],
              },
            ]}
          ></CommonForm>
        </div>,
        <RaisedButton
          color="primary"
          label={language.GENERATE}
          onClick={() => createReportSintetic()}
          primary
        />,
        <RaisedButton
          color="primary"
          label={language.EXPORT}
          onClick={() => createReportExcel()}
          primary
        />,
      ]}
    >
      <div style={{ backgroundColor: 'white', height: '100vh', overflowY: 'auto' }}>
        {Object.keys(data).map((group) => (
          <div className="divByGroupTech">
            <div className="groupNameDiv">{`${language.TECH_GROUP}: ${group}`}</div>

            {Object.keys(data[group]).map((tech) => (
              <div>
                <div className="techNameDiv">{`${language.TECH}: ${tech}`}</div>
                {data[group][tech].hasWorked ? (
                  <div className="allTicketsTable">
                    <div className="typeTicketDiv">{language.WORKED_TICKETS}</div>
                    <table className="table-data" style={{ width: '100%' }}>
                      <thead className="ticketDiv">
                        <tr>
                          <th className={'ticketTh'}>{language.CALL}</th>
                          <th className={'ticketTh'}>{language.COMPANY}</th>
                          <th className={'ticketTh'}>{language.CONTRACT}</th>
                          <th className={'ticketTh'}>{language.SUBJECT}</th>
                          <th className={'ticketTh'}>{language.DATE}</th>
                        </tr>
                      </thead>
                      <tbody className="tableTicket">
                        {data[group][tech].worked.map((tickets) =>
                          tickets.map((t) => {
                            return (
                              <tr>
                                <td>{t.real_id}</td>
                                <td>{t.company_name}</td>
                                <td>{t.contract_name}</td>
                                <td>{t.subject}</td>
                                <td>
                                  {moment(t.time).format('DD/MM/YYYY HH:mm')}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : null}

                    {data[group][tech].hasCategorized ? (
                  <div className="allTicketsTable">
                    <div className="typeTicketDiv">TICKETS CATEGORIZADOS</div>
                    <table className="table-data" style={{ width: '100%' }}>
                      <thead className="ticketDiv">
                        <tr>
                          <th className={'ticketTh'}>{language.CALL}</th>
                          <th className={'ticketTh'}>{language.COMPANY}</th>
                          <th className={'ticketTh'}>{language.CONTRACT}</th>
                          <th className={'ticketTh'}>{language.SUBJECT}</th>
                          <th className={'ticketTh'}>{language.DATE}</th>
                        </tr>
                      </thead>
                      <tbody className="tableTicket">
                        {data[group][tech].categorized.map((teste) =>
                          teste.map((t) => {
                            return (
                              <tr>
                                <td>{t.real_id}</td>
                                <td>{t.company_name}</td>
                                <td>{t.contract_name}</td>
                                <td>{t.subject}</td>
                                <td>
                                  {moment(t.time).format('DD/MM/YYYY HH:mm')}
                                </td>
                              </tr>
                            );
                          })
                        )}{' '}
                      </tbody>
                    </table>
                  </div>
                ) : null}
                {data[group][tech].hasInitiated ? (
                  <div className="allTicketsTable">
                    <div className="typeTicketDiv">TICKETS INICIADOS</div>
                    <table className="table-data" style={{ width: '100%' }}>
                      <thead className="ticketDiv">
                        <tr>
                          <th className={'ticketTh'}>{language.CALL}</th>
                          <th className={'ticketTh'}>{language.COMPANY}</th>
                          <th className={'ticketTh'}>{language.CONTRACT}</th>
                          <th className={'ticketTh'}>{language.SUBJECT}</th>
                          <th className={'ticketTh'}>{language.DATE}</th>
                        </tr>
                      </thead>
                      <tbody className="tableTicket">
                        {data[group][tech].initiated.map((teste) =>
                          teste.map((t) => {
                            return (
                              <tr>
                                <td>{t.real_id}</td>
                                <td>{t.company_name}</td>
                                <td>{t.contract_name}</td>
                                <td>{t.subject}</td>
                                <td>
                                  {moment(t.time).format('DD/MM/YYYY HH:mm')}
                                </td>
                              </tr>
                            );
                          })
                        )}{' '}
                      </tbody>
                    </table>
                  </div>
                ) : null}
                {data[group][tech].hasFinalized ? (
                  <div className="allTicketsTable">
                    <div className="typeTicketDiv"> TICKETS FINALIZADOS</div>
                    <table className="table-data" style={{ width: '100%' }}>
                      <thead className="ticketDiv">
                        <tr>
                          <th className={'ticketTh'}>{language.CALL}</th>
                          <th className={'ticketTh'}>{language.COMPANY}</th>
                          <th className={'ticketTh'}>{language.CONTRACT}</th>
                          <th className={'ticketTh'}>{language.SUBJECT}</th>
                          <th className={'ticketTh'}>{language.DATE}</th>
                          <th className={'ticketTh'}>HORAS CONSUMIDAS</th>
                        </tr>
                      </thead>
                      <tbody className="tableTicket">
                        {data[group][tech].finalized.map((tickets) =>
                          tickets.map((t) => {
                            return (
                              <tr>
                                <td>{t.real_id}</td>
                                <td>{t.company_name}</td>
                                <td>{t.contract_name}</td>
                                <td>{t.subject}</td>
                                <td>
                                  {moment(t.time).format('DD/MM/YYYY HH:mm')}
                                </td>
                                <td>{t.hours_consumed}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    </PageLayout>
  );
}
