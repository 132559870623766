import React, { useState, useReducer, useEffect } from 'react';
import moment from 'moment';

import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm2';
import PageLayout from '../../common/PageLayout';

export default function ({ action, APIEndpoints, store, language }) {
  let [listchief, Setlistchief] = useState([]);
  let [users, SetUsers] = useState([]);
  let [chiefAlt, SetChiefAlt] = useState([]);

  let [opens, SetOpen] = useState(false);
  let [companies, SetCompanies] = useState([]);
  let [company, SetCompany] = useState(0);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const savechief = (values) => {
    action
      .execute('post', APIEndpoints.USER + '/chiefalt', '', values)
      .then((e) => {
        load();
        SetOpen(false);
      });
  };

  const DepartmentList = (id) => {
    action.execute('get', APIEndpoints.DEPARTMENT + '/' + id, '').then((v) => {
      let departments = v.data.map((d) => ({
        value: d.id_department,
        label: d.name,
      }));
      setDepartmentOptions(departments);
    });
  };

  const load = () => {
    action
      .execute('get', APIEndpoints.USER + '/chiefalternative', 'chiefs')
      .then((b) => {
        Setlistchief(b.data);
      });
  };
  useEffect(() => {
    action
      .execute('get', APIEndpoints.USER + '/chiefalternative', 'chiefs')
      .then((b) => {
        Setlistchief(b.data);
        
      });

    action.execute('get', APIEndpoints.COMPANY, 'companies').then((a) => {
      SetCompanies(a.data);
    });
  }, []);

  let companyOptions = companies.map((c) => ({
    value: c.id_company,
    label: c.name,
  }));

  let responsibleOptions = users.map((u) => ({
    value: u.id_user,
    label: u.name + ' - ' + u.email,
  }));
  
  let fields = [
    {
      col: 12,
      name: 'fk_id_company',
      label: language.COMPANY,
      type: 'select',
      options: companyOptions,
      required: true,
    },
    {
      col: 12,
      name: 'fk_id_department',
      label: language.DEPARTMENT,
      type: 'select',
      options: departmentOptions,
      required: true,
    },
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_user_chief',
      label: language.BOSS,
      method: 'POST',
      route: `${APIEndpoints.USER}/filter`,
      fieldquery: 'search',
      textlabel: language.BOSS,
      textinit: chiefAlt ? chiefAlt?.namechief+' - '+chiefAlt?.emailchief : '',
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      idinit: chiefAlt ? chiefAlt?.fk_id_user_chief : '',
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.USER}/active/count/all`,
      detail: true,
      visible: true,
      required: true,
      disablePortal: true,
      showEmail: true,
      idFieldToShow: 'id_user',
    },
    {
      col: 12,
      name: 'dt_start',
      label: language.INITIAL_DATE,
      type: 'date',

      required: true,
    },
    {
      col: 12,
      name: 'dt_end',
      label: language.FINAL_DATE,
      type: 'date',

      required: true,
    },
  ];
  return (
    <PageLayout
      title={language.ALTERNATE_BOSS}
      subTitle={''}
      back
      rightElements={[
        <div className="d-flex align-items-center _btn-group"></div>,
      ]}
    >
      {opens == true ? (
        <Dialog
          title={''}
          open={opens}
          maxWidth="xs"
          monitor_scroll
          onClose={() => SetOpen(false)}
        >
          <CommonForm
            values={chiefAlt}
            language={language}
            fields={fields}
            onSubmit={(v) => {
              savechief(v);
            }}
            onChangeField={(f, v) => {
              if (f.name == 'fk_id_company') {
                SetCompany(v);
                DepartmentList(v);
              }
            }}
            button={{
              md: 3,
              offset: { md: 9 },
              label: language.SAVE,
              primary: true,
              style: { margin: 5 },
            }}
          />
        </Dialog>
      ) : null}
      <CommonTable
        searchColumn
        AppBarProps={{
          dark: true,
          className: 'py-0 pr-0',
        }}
        print={false}
        paginationTop={false}
        beforeBar={
          <div>
            <Button
              variant="circle"
              icon="fas fa-plus"
              color="warning"
              className="mr-3"
              onClick={() => {
                SetChiefAlt(null);
                SetOpen(true);
              }}
            />
          </div>
        }
        col={[
          { key: 'chief', label: language.BOSS },
          { key: 'company', label: language.COMPANY },
          { key: 'department', label: language.DEPARTMENT },
          { key: 'dt_start', label: language.INITIAL_DATE },
          { key: 'dt_end', label: language.FINAL_DATE },
          { key: 'action', label: language.ACTION },
        ]}
        data={listchief.map((v) => {
          return {
            chief: v.namechief,
            company: v.namecompany,
            department: v.namedepartment,
            dt_start: moment(v.dt_start).format('DD/MM/YY'),
            dt_end: moment(v.dt_end).format('DD/MM/YY'),
            action: (
              <div>
                <Button
                  variant="circle"
                  icon="fas fa-edit"
                  color="warning"
                  style={{ fontSize: 15 }}
                  className="mr-3"
                  onClick={() => {
                    SetOpen(true);
                    SetCompany(v.fk_id_company);
                    SetChiefAlt(v);
                  }}
                />
                <Button
                  variant="circle"
                  icon=" far fa-trash-alt"
                  color="danger"
                  className="mr-3"
                  style={{ fontSize: 15 }}
                  onClick={() => {
                    v.deleted = true;
                    savechief(v);
                  }}
                />
              </div>
            ),
          };
        })}
      />
    </PageLayout>
  );
}
