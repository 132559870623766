import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  Avatar,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import ReactHighcharts from 'react-highcharts';
import { Tooltip } from 'react-tippy';

// ------------------------------------------------------------

import Emitter from './EventEmitter_';
import InventoryDetail from './InventoryDetail';
import './InventoryList.css';
import ContainerChart from './Inventory.graph.js';
import SearchForm from './SearchForm';
import Status from './Status';

import AttachFile from '@common/AttachFile';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import CommonPage from '@common/CommonPage';
import CommonTable from '@common/CommonTable';
import { flexBasis, filterObj } from '@common/Func';
import Header from '@common/Header';
import List from '@common/List';
import PageLayout from '@common/PageLayout';
import RaisedButton from '@common/RaisedButton';
import SwitchIos from '@common/SwitchIos';
import DownloadFiles from '../../util/DownloadFiles';

// ------------------------------------------------------------

@observer
export default class InventoryList extends CommonPage {
  constructor() {
    super();
    this.state = {
      company: '',
      department: '',
      page: '',
      pageT: 0,
      rowsPerPage: 5,
      mutation: false,
      anchorEl: null,
      viewInfo: true,
      d: [],
      totalLigado: 0,
      totalDesligado: 0,
      zerarempresa: true,
      advancedSearchModalOpen: false,
      monitor: [],
      itemgrafico: 1,
      monitorList: [],
      monitorando: null,
      load: true,
      devicesLigados: [],
      all: false,
    };

    this.handleValues = this.handleValues.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.changeempresa = this.changeempresa.bind(this);
    this.setMonitorList = this.setMonitorList.bind(this);
    this.sendvaluesall = this.sendvaluesall.bind(this);
  }
  setAdvancedSearchModalOpen = (advancedSearchModalOpen) => {
    this.setState({ advancedSearchModalOpen });
  };

  setMonitorList(itens, not, off) {
    this.setState({ monitorando: not, all: true });

    console.log(this.APIEndpoints.INVENTORY + '/1', 'devices', {
      devices: itens || [],
      valores: '',
    });
    if (not) {
      //online
    }
    this.action
      .execute('post', this.APIEndpoints.INVENTORY + '/1', 'devices', {
        devices: itens || [],
        valores: '',
        not: not || false,
      })
      .then((item) => {
        console.log(item);
      });
  }

  componentWillMount = () => {
    this.action
      .execute('get', this.APIEndpoints.MONITOR, 'monitor')
      .then((c) => {
        this.setState({ monitor: c.data });
      });
  };

  onSubmitAdvancedSearch = (v) => {
    if (v.type && v.search) {
      this.action
        .execute(
          'get',
          `${this.APIEndpoints.INVENTORY}/1/advancedSearch?type=${v.type}&search=${v.search}`,
          'devices',
          null,
          null,
          { btn_loading: 'search_advanced_search' }
        )
        .then((e) => {
          this.setState({ advancedSearchModalOpen: false });
        });
    }
  };

  changeempresa() {
    this.setState({ zerarempresa: false });
  }
  sendvalues(item) {
    this.props.setLoading(true);
    let values = {};

    this.setState({ dialog: false });
    this.store.loading = true;
    if (item) {
      values.excel = true;
    }

    this.action
      .execute(
        'get',
        `${this.APIEndpoints.INVENTORY}/${this.state.company || 0}/exportinventory`,
        ''
      )
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        if (item) {
          setTimeout(() => {
            DownloadFiles(
              this.APIEndpoints.REQUESTS +
                '/downloadinventoryexport/' +
                this.store.getUserLoged().id +
                '/' +
                res[0].file,
              res[0].file
            );
            this.props.setLoading(false);
          }, 1000);
        } else {
          this.setState({ tickets: res });
        }
      });
  }

  sendvaluesall(item) {
    this.store.loading = true;
    this.props.setLoading(true);

    this.action
      .execute(
        'get',
        `${this.APIEndpoints.INVENTORY}/${this.state.company || 0}/exportinventoryall`,
        ''
      )
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        setTimeout(() => {
          DownloadFiles(
            this.APIEndpoints.REQUESTS +
              '/downloadinventoryexport/' +
              this.store.getUserLoged().id +
              '/' +
              res[0].file,
            res[0].file
          );
          this.props.setLoading(false);
        }, 1000);
      });
  }

  handleValues(value, type) {
    this.setState({ zerarempresa: true });
    if (type == 'department') {
      this.setState({ department: value, totalLigado: 0, totalDesligado: 0 });
    } else {
      this.setState({ company: value, totalLigado: 0, totalDesligado: 0 });
    }
  }

  handleClickOpen(valor) {
    this.setState({ page: valor });
  }

  handlePage() {
    this.setState({ company: '', page: '' });
  }

  handleChangePage = (event, pageT) => {
    this.setState({ pageT });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (event, d) => {
    this.setState({ anchorEl: event.currentTarget, d: d });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  nameUserFuncs = (value) => {
    let v = '';

    try {
      v = value.split('[{"')[1];
      v = v.split('",')[0];
      v = v.split('":"')[1];
    } catch (r) {}
    return v;
  };

  ondonwFile = (id, file, type) => {
    DownloadFiles(
      this.APIEndpoints.REQUESTS + '/downloadfiledevice/' + id + '/' + file,
      file
    );
  };

  render() {
    let {
      language,
      devices,
      departments,
      handlePage,
      companies,
      values,
      redirect,
      ioServers,
      usercon_company,
      softwares,
      inventorydistros,
      inventories,
      history,
      setLoading,
      total_acompany_theme,
      onClearAllFilter,
    } = this.props;
    let { company, department, page } = this.state;

    if (company != '') {
      devices = devices.filter((g) => g.fk_id_company == company);
      inventories = inventories.filter((g) => g.fk_id_company == company);
    }

    if (this.store.getUserLoged().role == 'solicitant') {
      devices = devices.filter(
        (g) => g.fk_id_company == this.store.getUserLoged().id_company
      );
    }

    if (department != '') {
      devices = devices.filter((g) => g.fk_id_department == department);
    }

    let csvData = [];

    csvData.push([
      'id',
      'nome',
      'ip',
      'macadress',
      'manufacturer ',
      'brand',
      'vendor',
      'family',
      'model',
      'stepping',
      'revision',
      'voltage',
      'speed',
      'speedmin',
      'speedmax',
      'governor',
      'cores',
      'physicalCores',
      'processors',
      'socket',
      'cache',
      ' platform',
      'distro',
      ' release',
      ' codename',
      ' kernel',
      ' arch',
      ' hostname',
      ' codepage',
      ' logofile',
      ' serial',
      ' build',
      ' servicepack',
      ' uefi',
      'vendor',
      'model',
      'bus',
      'vram',
      'vramDynamic',
      'memory Total',
      'memory Free',
      'Disco',
      'Agente Version',
      'Usuário',
    ]);

    ///itemgrafico
    var distrosQtd = devices.reduce((obj, e) => {
      var c = this.state.itemgrafico == 1 ? e.inv_distro : e.inv_agent;
      var d = obj[c || 'Outros'];
      obj[c || 'Outros'] = d ? d + 1 : 1;
      return obj;
    }, {});

    let colors = [
      'var(--success)',
      'var(--warning)',
      'var(--danger)',
      '#8b3594',
      '#17a2b8',
      '#629DAA',
      '#6c757d',
      '#87CEEB',
      '6B8E23',
      '#BA55D3',
      '#8B008B',
      '#FFB6C1',
      '#B0E0E6',
      '#008000',
      '#708090',
    ];
    let array = [];
    let countColor = 1;
    var totalDistrosQtd = Object.values(distrosQtd).reduce((i, e) => i + e, 0);

    Object.keys(distrosQtd).map((e) => {
      array.push({
        y: distrosQtd[e],
        label: e,
        color: colors[countColor < 14 ? countColor++ : (countColor = 1)],
      });
    });
    let arrayG = [];
    countColor = 1;
    Object.keys(distrosQtd).map((e) => {
      arrayG.push({
        y: distrosQtd[e],
        label: (e / totalDistrosQtd) * 100,
        color: colors[countColor < 14 ? countColor++ : (countColor = 1)],
      });
    });

    array.push({ y: totalDistrosQtd });

    let col = [
      {
        key: 'check',
        label: '',
        thConfig: true,
        style: { width: 50 },
      },
      {
        key: 'icon',
        label: language.ACTIONS,
        thConfig: true,
        style: { width: 50 },
      },
      {
        key: 'id',
        label: 'ID',
        thConfig: true,
        style: { width: 60 },
      },
      {
        key: 'company',
        label: language.COMPANY,
        thConfig: true,
        style: { paddingRight: 0 },
      },
      {
        key: 'users',
        label: language.USERS,
        thConfig: true,
        style: { width: 130 },
      },
      {
        key: 'userslogin',
        label: language.LOGGED_IN_USER,
        thConfig: true,
        style: { width: 130 },
      },
      {
        key: 'device',
        label: language.DEVICE,
        thConfig: true,
        style: { width: 130 },
      },
      {
        key: 'os',
        label: language.OPERATIONAL_SYSTEM,
        thConfig: true,
        style: { paddingRight: 0 },
      },
      {
        key: 'agent',
        label: language.AGENT_VERSION,
        thConfig: true,
        style: { paddingRight: 0 },
      },
      {
        key: 'ip',
        label: language.IP,
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'mac',
        label: 'Mac',
        thConfig: true,
        style: { width: 100 },
      },
      {
        key: 'memory',
        label: language.MEMORY,
        thConfig: true,
        style: { width: 80 },
      },
      {
        key: 'type',
        label: language.TYPE,
        thConfig: true,
        style: { width: 80 },
      },
      {
        key: 'cost',
        label: language.COST,
        thConfig: true,
        style: { width: 60 },
      },
      {
        key: 'department',
        label: language.DEPARTMENT,
        thConfig: true,
        style: { width: 140 },
      },
      {
        key: 'dt_up',
        label: language.LAST_UPDATE,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'provider',
        label: language.PROVIDER,
        thConfig: true,
        style: { width: 120 },
      },
      {
        key: 'anexo',
        label: language.ATTACHMENT,
        thConfig: true,
        style: { width: 120 },
      },
    ];

    let data = [];
    let comp = '';

    let memory = 0;
    let departments_itens = [];
    window.devices = devices;

    let depart = devices.reduce((obj, e) => {
      var c = e.departament;
      var d = obj[c];
      obj[c] = d ? d + 1 : 1;
      return obj;
    }, {});
    let departArray = [];

    Object.keys(depart).map((e) => {
      departArray.push({
        y: depart[e],
        name: e,
      });
    });

    let csvDataTable = [
      [
        'status',
        'id',
        'company',
        'users',
        'device',
        'os',
        'ip',
        'memory',
        'type',
        'cost',
        'department',
        language.LAST_UPDATE,
      ],
    ].concat(
      devices.map((d) => {
        let comp = '';
        let info = csvData.filter((n) => n[0] == d.id_device)[0];
        try {
          comp = companies.filter((h) => h.id_company == d.fk_id_company)[0]
            .name;
        } catch (g) {}
        return [
          '-',
          d.id_device,
          comp,
          d.userdevice && d.userdevice[0],
          d.name,
          info && info[22],
          d.ip,
          info && info[39] + ' MB',
          d.type,
          d.cost,
          d.departament,
          d.dt_update == null
            ? '-'
            : moment(d.dt_update).format('DD/MM/YYYY HH:mm'),
        ];
      })
    );
    data =
      devices &&
      devices.map((d) => {
        let usersToolAc =
          d.userdevice &&
          d.userdevice.map((u, i) => (
            <div
              style={{ radius: 10, marginLeft: '5px', marginTop: '2px' }}
              className="d-flex align-items-center"
            >
              <Avatar
                className={classnames('avatar')}
                style={{
                  fontSize: '10px',
                  width: '17px',
                  height: '17px',
                  backgroundColor: 'orange',
                }}
              >
                {u.substr(0, 1).toString().toUpperCase()}
              </Avatar>
              <span style={{ color: 'black', marginLeft: '4px' }}>{u}</span>
            </div>
          ));

        let men = '0';
        try {
          let totalm = parseInt(parseFloat(d.inv_memory) / 1024 / 1024);
          if (d.inv_memory != null) {
            men = parseFloat(totalm / 1024);
            men = men.toFixed(2).toString() + ' Gb';
          } else {
            men = '';
          }
        } catch (e) {}

        try {
          comp = companies.filter((h) => h.id_company == d.fk_id_company)[0]
            .name;
        } catch (g) {}

        return {
          check: (
            <div className="d-flex align-items-center">
              {this.state.load == true && (
                <Status
                  device={d}
                  all={this.state.all}
                  monitorando={this.state.monitorando}
                  ioServers={ioServers}
                  Emitter={Emitter}
                />
              )}
            </div>
          ),
          icon: (
            <IconButton
              aria-label="More"
              aria-owns={true ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={(event) => this.handleClick(event, d)}
              style={{ transform: 'rotate(90deg)', color: 'var(--primary)' }}
            >
              <MoreVertIcon />
            </IconButton>
          ),
          id: d.id_device,
          company: comp,
          users: (
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 11 }}>{usersToolAc}</div>
              }
              className="h-100"
              position="left"
              theme="light"
              interactive
            >
              <div className="d-flex align-items-center">
                <i class="far fa-user mr-1" />
                {d.userdevice && d.userdevice[0]}
              </div>
            </Tooltip>
          ),
          userslogin: d.inv_users,
          device: d.name,
          os: d.inv_os,
          agent: d.inv_agent,
          ip: d.ip,
          mac: d.macadress,
          memory: men,
          type: d.type,
          cost: d.cost,
          department: d.departament,
          dt_up:
            d.dt_update == null
              ? 'Não Inventariado'
              : moment(d.dt_update).format('DD/MM/YYYY HH:mm'),
          provider: d.provider ? d.provider : '',
          anexo: (
            <div
              className="py-1"
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '200px',
                overflowX: 'auto',
                maxHeight: '100px',
              }}
            >
              {d.files != undefined
                ? d.files.map((f) => (
                    <AttachFile
                      label={f.name}
                      onClick={() => this.ondonwFile(d.id_device, f.name)}
                      style={{ maxWidth: '100%' }}
                    />
                  ))
                : null}
            </div>
          ),
        };
      });

    var renders;
    var visiblebtn;
    var visibleback;
    if (page === '') {
      visiblebtn = { visibility: 'visible' };
      visibleback = { marginRight: 10, display: 'none' };
    } else {
      visiblebtn = { visibility: 'hidden', width: 0 };
      visibleback = { marginRight: 10, display: 'block' };
    }
    const { rowsPerPage, pageT } = this.state;

    if (page === '') {
      renders = <React.Fragment></React.Fragment>;
    } else if (page === 'detail') {
      renders = <InventoryDetail language={language} className="cardDefault" />;
    }

    let { anchorEl, viewInfo } = this.state;
    let open = !!anchorEl;

    let i = departments.map((e) => ({
      value: e.id_department,
      label: e.name,
    }));

    i.push({ value: 0, label: language.ALL });

    return (
      <PageLayout
        icon="inventaryIcon"
        title={language.INVENTORY}
        subTitle={language.DASHBOARD_PAGE.DM + '/'}
        back={() => history.goBack()}
        rightElements={[
          <div
            style={{ width: 500, zoom: 0.8, marginTop: '1rem' }}
            zoom={0.8}
            className="d-flex align-items-center justify-content-end"
          >
            <div
              style={{ width: 200 }}
              className="mr-3"
              style={{ marginTop: '-5px' }}
            >
              <CommonForm
                values={{
                  company,
                }}
                fields={[
                  {
                    type: 'autocomplete',
                    name: 'company',
                    label: language.COMPANY,
                    method: 'POST',
                    route: `${this.APIEndpoints.COMPANY}/filter`,
                    fieldquery: 'search',
                    textlabel: language.COMPANY,
                    textinit: '',
                    fieldvaluedb: 'id_company',
                    fieldlabeldb: 'name',
                    idinit: 1,
                    routeAll: `${this.APIEndpoints.COMPANY}/all?`,
                    methodGetAll: 'POST',
                    routeGetCount: `${this.APIEndpoints.COMPANY}/count`,
                    detail: true,
                  },
                ]}
                onChangeField={(event, key, values) => {
                  this.handleValues(key, 'company');
                }}
              />
            </div>

            <div style={{ width: 200 }}>
              <CommonForm
                values={{
                  department,
                }}
                fields={[
                  {
                    type: 'select',
                    name: 'department',
                    label: language.DEPARTMENT,
                    options: i,
                  },
                ]}
                onChangeField={(event, key, values) => {
                  this.handleValues(key, 'department');
                }}
              />
            </div>
            <RaisedButton
              circleButton
              icon="fas fa-arrow-left"
              label={language.BACK}
              color="primaryGradient"
              style={visibleback}
              onClick={() => redirect('/dash/inventory')}
            />
          </div>,
        ]}
      >
        <SearchForm
          setAdvancedSearchModalOpen={this.setAdvancedSearchModalOpen}
          advancedSearchModalOpen={this.state.advancedSearchModalOpen}
          onSubmitAdvancedSearch={this.onSubmitAdvancedSearch}
          APIEndpoints={this.APIEndpoints}
          action={this.action}
        />
        <div id="InventoryList">
          <div id="content-infos" className="mb-3">
            <Header className="d-flex justify-content-between align-items-center px-4">
              <div className="d-flex align-items-center">
                <Button
                  variant="circle"
                  icon="fas fa-laptop-code"
                  label={language.EXPORT_SOFTWARE}
                  color="warning"
                  className="mr-3"
                  onClick={() => this.sendvalues({})}
                  style={{
                    fontSize: 12,
                  }}
                />
                <Button
                  variant="circle"
                  icon="fas fa-laptop-code"
                  label={language.EXPORT_DATE}
                  color="warning"
                  className="mr-3"
                  onClick={() => this.sendvaluesall({})}
                  style={{
                    fontSize: 12,
                  }}
                />
              </div>
              <SwitchIos
                checked={viewInfo}
                onChange={() => {
                  this.setState({ viewInfo: !viewInfo });
                }}
                label={
                  <span
                    style={{ color: 'white' }}
                  >{`+ ${language.DETAILS}`}</span>
                }
              />
            </Header>
            <Card
              className={classnames({
                'content-medium': viewInfo,
                notView: !viewInfo,
              })}
              id="body"
              style={{ '--total_acompany_theme': total_acompany_theme }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={4}
                  style={{ ...flexBasis(4) }}
                  className="grid-1"
                >
                  <Card style={{ height: 201 }} className="h-100">
                    <div style={{ display: 'flex' }}>
                      <h3
                        style={{ width: '80%' }}
                        className="title content-min pb-0"
                      >
                        {language.SEGMENTATION_OF_THE_OPERATING_SYSTEM}
                      </h3>
                      <div style={{ width: '20%', marginTop: 15 }}>
                        <CommonForm
                          values={{
                            company,
                          }}
                          fields={[
                            {
                              type: 'select',
                              name: 'pany',
                              label: language.FILTER,
                              options: [1, 2].map((e) => ({
                                value: e,
                                label: e == 1 ? 'Distro' : language.AGENT,
                              })),
                            },
                          ]}
                          onChangeField={(f, v) => {
                            this.setState({ itemgrafico: v });
                          }}
                        />
                      </div>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <div>
                          <ReactHighcharts
                            config={ContainerChart.setorInfo(arrayG)}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={7} className="pt-4">
                        <List
                          mh={152}
                          items={array.map((e, i) => ({
                            content: (
                              <div
                                key={i}
                                className="content-item-list d-flex align-items-center justify-content-between w-100"
                              >
                                {!e.label && !e.color ? (
                                  <span className="total">
                                    {language.TOTAL}
                                  </span>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="indicator-color"
                                      style={{ backgroundColor: e.color }}
                                    />
                                    <span
                                      className="ml-2"
                                      style={{ color: e.color }}
                                    >
                                      {e.label}
                                    </span>
                                  </div>
                                )}
                                <span>{e.y}</span>
                              </div>
                            ),
                          }))}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ ...flexBasis(4) }}
                  className="grid-2"
                >
                  <Card className="h-100">
                    <h3 className="title content-min pb-0">
                      {language.NUMBER_OF_DEVICES}
                    </h3>
                    <span className="sub-title content-min py-0">
                      ({language.BY_DEPARTMENT})
                    </span>
                    <List
                      mh={158}
                      items={departArray.map((e, i) => ({
                        className: 'py-1',
                        content: (
                          <div
                            key={i}
                            className="content-item-list d-flex align-items-center justify-content-between w-100"
                          >
                            <span>{e.name}</span>
                            <span>{e.y}</span>
                          </div>
                        ),
                      }))}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ ...flexBasis(2) }}
                  className="grid-3"
                >
                  <Card className="card h-100">
                    <div>
                      <h3 className="title content-min pb-0">
                        {language.TOTAL_BY_COMPANY}
                      </h3>

                      <div className="content-form content-medium"></div>
                    </div>
                    <h3 className="pl-3 value mb-3">{devices.length}</h3>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ ...flexBasis(2) }}
                  className="grid-4"
                >
                  <Card className="h-100">
                    <h3 className="title content-min pb-0">
                      {language.DEVICE_STATUS}
                    </h3>

                    <ListT
                      Emitter={Emitter}
                      company={this.state.company}
                      changeempresa={this.changeempresa}
                      setMonitorList={this.setMonitorList}
                      zerarempresa={this.state.zerarempresa}
                      devices={devices}
                      ioServers={ioServers}
                      all={this.state.all}
                      language={language}
                    ></ListT>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </div>
          <CommonTable
            mini
            AppBarProps={{
              dark: true,
              className: 'py-0',
            }}
            loadSearch={true}
            onClearAllFilter={onClearAllFilter}
            beforeBar={
              <div>
                <Button
                  variant="circle"
                  icon="fas fa-search"
                  color="warning"
                  label={language.ADVANCED_SEARCH}
                  className="mr-3"
                  onClick={() => this.setAdvancedSearchModalOpen(true)}
                />
                <CSVLink
                  style={{ textDecoration: 'none' }}
                  separator={';'}
                  filename={
                    'inventory_' + moment().format('MMDDYY').toString() + '.csv'
                  }
                  enclosingCharacter={`'`}
                  data={csvDataTable}
                >
                  <Button
                    variant="circle"
                    icon="fas fa-file-csv"
                    label={language.EXPORT_INVENTORY}
                    color="warning"
                    className="mr-3"
                    style={{
                      fontSize: 15,
                    }}
                  />
                </CSVLink>
              </div>
            }
            rowsPerPageOptions={[50, 100, 200, 400]}
            searchColumn
            col={col}
            data={data}
          />
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
          >
            {[
              {
                icon: 'fas fa-microchip',
                label: 'Hadware',
                color: '#82E4F5',
                send: () =>
                  redirect('/dash/inventory/mac', filterObj(this.state.d)),
              },
              {
                icon: 'fas fa-laptop-code',
                label: 'Software',
                color: '#F0B002',
                send: () =>
                  redirect('/dash/inventory/software', filterObj(this.state.d)),
              },
              {
                icon: 'fas fa-concierge-bell',
                label: language.SERVICE,
                color: '#33ACA0',
                send: () =>
                  redirect('/dash/inventory/service', filterObj(this.state.d)),
              },
              {
                icon: 'fas fa-times-circle',
                label: language.VULNERABILITY,
                color: '#E84F4F',
                send: () =>
                  redirect(
                    '/dash/inventory/vulnerability',
                    filterObj(this.state.d)
                  ),
              },
            ].map((e) => (
              <MenuItem onClick={e.send}>
                <i className={e.icon} style={{ color: e.color }} />
                <span
                  style={{ fontSize: 15, color: 'var(--primary)' }}
                  className="ml-2"
                >
                  {e.label}
                </span>
              </MenuItem>
            ))}
          </Menu>
          {renders}
        </div>
      </PageLayout>
    );
  }
}

class ListT extends Component {
  constructor() {
    super();
    this.state = {
      totalLigado: [],
      totalDesligado: [],
      hold: '',
      devicesLigados: [],
      time: null,
    };
  }

  componentDidMount() {

    this.props.ioServers.emit('status_online_all', this.props.company);
    this.props.ioServers.on('allDevicesOnline', (data) => {
      this.setState({
        totalLigado: data.status,
        devicesLigados: data.devices,
      });
    });


    this.interval = setInterval(() => {
      console.log('update ');
      this.props.ioServers.emit('status_online_all', this.props.company);
      this.props.ioServers.on('allDevicesOnline', (data) => {
        this.setState({
          totalLigado: data.status,
          devicesLigados: data.devices,
        });
      });
    }, 8000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let { language, setMonitorList, all } = this.props;
    function refreshPage() {
      window.location.reload();
    }
    return all == true ? (
      <div className="d-flex align-items-center">
        <span style={{ cursor: 'pointer' }} onClick={() => refreshPage()}>
          <Button
            label={'listar todos'}
            style={{marginLeft:'20px', fontSize: 18}}
            color="success"
            className="mr-3"
            onClick={() => refreshPage()}

          />
        </span>
      </div>
    ) : (
      <List
        items={[
          { name: 'OnLine', y: this.state.totalLigado },
          {
            name: 'OffLine',
            y:
              this.props.devices.length > 0
                ? this.props.devices.length - this.state.totalLigado
                : 0,
          },
          { y: this.props.devices.length },
        ].map((e, i) => ({
          className: 'py-1',
          className: classnames({ 'py-3': i < 2 }),
          content: (
            <div
              key={i}
              className="content-item-list d-flex align-items-center justify-content-between w-100"
              onClick={() =>
                setMonitorList(
                  [
                  this.state.devicesLigados
                  ],
                  i == 1?false: true
                )
              }
            >
              {i == 2 ? (
                <span onClick={() => setMonitorList([], null)}>
                  {language.TOTAL}
                </span>
              ) : (
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor:
                        i == 1 ? 'var(--danger)' : 'var(--primary)',
                    }}
                  ></div>
                  <span
                    className="ml-2"
                    style={{
                      color: i == 1 ? 'var(--danger)' : 'var(--primary)',
                    }}
                  >
                    {i == 1 ? '' : e.name}
                  </span>
                </div>
              )}
              <span>{e.y}</span>
            </div>
          ),
        }))}
      />
    );
  }
}
