import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import CompanyTimeList from './CompanyTimeList';
import CompanyTime from './CompanyTime';
import Animate from '@common/Animate';
@observer
export default class CompanyTimeContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: null,
      page: 'list',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute(
      'post',
      this.APIEndpoints.COMPANY_TIME + '/holiday',
      'holiday',
      { list: true }
    );
    this.action.execute(
      'get',
      this.APIEndpoints.COMPANY_TIME + '/' + 'fuso',
      'gmt'
    );
    this.action.execute('get', this.APIEndpoints.COMPANY_TIME, 'companytimes');
    this.action.execute('post', this.APIEndpoints.CONTRACT + '/list', 'contracts');
  }

  onFormSubmit(values) {
    let method = values.hasOwnProperty('id_company_time') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_company_time')
      ? this.APIEndpoints.COMPANY_TIME + '/' + values.id_company_time
      : this.APIEndpoints.COMPANY_TIME;
    this.action
      .execute(method, url, 'companytimes', values, 'id_company_time')
      .then((res) => {
        this.action.execute(
          'get',
          this.APIEndpoints.COMPANY_TIME,
          'companytimes'
        );
      });
    //this.redirect('/dash/configuration/companytime')
  }

  onDelete() {
    let { values } = this.state;
    this.action.execute(
      'delete',
      this.APIEndpoints.COMPANY_TIME +
        '/' +
        values.fk_id_company +
        '/' +
        values.tipo +
        '/' +
        values.fk_id_contract,
      'companytimes',
      null
    );

    this.redirect('/dash/configuration/companytime');
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    let { language, companytimes, companies, contracts, gmt } = this.store;
    window.companytime = companytimes;
    let { configurationProps = {} } = this.props;

    return (
      <Fragment>
        <Animate>
          <CompanyTimeList
            gmt={gmt}
            contracts={contracts}
            companytime={companytimes}
            companies={companies}
            redirect={this.redirect2}
            language={language}
            handlePage={this.handlePage}
            store={this.store}
            holiday={this.store.holiday}
            APIEndpoints={this.APIEndpoints}
            action={this.action}
            configurationProps={configurationProps}
          />
        </Animate>
        {page === 'form' ? (
          <CompanyTime
            gmt={gmt}
            contracts={contracts}
            holiday={this.store.holiday}
            companytime={companytimes}
            companies={companies}
            {...this.state}
            values={this.state.values}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            message={this.state.values.name}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
          />
        ) : null}
      </Fragment>
    );
  }
}
