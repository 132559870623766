import React, { useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import ReactHighcharts from 'react-highcharts';
import { Card, FormControlLabel, Switch } from '@material-ui/core';

import AppBar from '../../../../common/AppBar2';
import Store from '../../../../data/Store';

import { Graph_1 } from './Graph';

export default observer(
  ({ language, budgetcontrols, filterMonthsData, contracts, currentYear }) => {
    let [checked, setChecked] = useState(false);
    let data = (
      checked ? budgetcontrols.filter((b) => b.status == 2) : budgetcontrols
    ).reduce(
      (arrs, e) => {
        let contract = contracts.filter((c) => c.name == e.name)[0];
        filterMonthsData(e.months).forEach((a) => {
          arrs[1][a.month - 1] += a.realized_cost;
        });

        if (contract && contract.type == 'year') {
          arrs[0] = arrs[0].map((a, i) =>
            e.expected_value.filter(
              (b) => b.year == currentYear && contract.dt_fat == i + 1
            )[0]
              ? a +
                e.expected_value.filter(
                  (b) => b.year == currentYear && contract.dt_fat == i + 1
                )[0].value
              : a
          );
        } else if (contract == undefined) {
          arrs[0] = arrs[0].map((a, i) =>
            e.period && e.period.includes(i + 1) ? a + e.expected_cost : a
          );
        } else {
          arrs[0] = arrs[0].map((a, i) =>
            e.expected_value.filter(
              (b) => b.year == currentYear && b.month == i + 1
            )[0]
              ? a +
                e.expected_value.filter(
                  (b) => b.year == currentYear && b.month == i + 1
                )[0].value
              : a
          );
        }
        return arrs;
      },
      [
        Array.from(new Array(12), (_, i) => 0),
        Array.from(new Array(12), (_, i) => 0),
      ]
    );
    return (
      <Card>
        <AppBar
          title={`${language.FORESEEN} X ${language.REALIZED}`}
          subTitle={`(${language.MONTH})`}
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={() => setChecked((a) => !a)}
              />
            }
            label={language.PROVISIONED_ITEMS}
          />
        </AppBar>
        <div className="content-medium pt-0">
          <ReactHighcharts
            config={Graph_1({
              categories: language.DATE_LANG.monthLongList,
              data,
              language,
            })}
          />
        </div>
      </Card>
    );
  }
);
