import React, { Fragment, useState } from 'react';
import './index.css';
/*----------------------------------common----------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import List from '@common/List';
import Loading from '@common/Loading';
import ButtonControl from '@common/ButtonControl';
/*------------------------------material-ui----------------------------------*/
import { Grid, Card } from '@material-ui/core';
/*--------------------------------------------------------------------------*/
import { Department } from './Graph';

export default function ({
  data,
  openModalDetail,
  depart,
  top10company,
  language,
}) {
  let [value, setValue] = useState(1);
  return (
    <Card id="Department" className="h-100">
      <AppBar
        title={
          value == 1 ? language.TOP_10_DEPARTAMENT : language.TOP_10_COMPANIES
        }
      >
        <ButtonControl
          value={value}
          options={[
            { value: 1, label: language.TOP_10_DEPARTAMENTS },
            { value: 2, label: language.TOP_10_COMPANIES },
          ]}
          onChange={(v) => setValue(v)}
        />
      </AppBar>
      <div className="content pt-0">
        <Grid container spacing={16}>
          <Grid item xs={7}>
            <ReactHighcharts
              config={Department({
                data: (value == 1 ? data : top10company) || [],
              })}
            />
          </Grid>
          <Grid item xs={5}>
            <List
              className="list-category-items"
              items={((value == 1 ? data : top10company) || []).map((e) => ({
                className:
                  'd-flex align-items-center justify-content-between items py-1',
                content: (
                  <Fragment>
                    <span
                      className="label mr-2 ellipsis"
                      onClick={() => {
                        values == 1
                          ? openModalDetail('department', e.id_department)
                          : openModalDetail('company', e.fk_id_company);
                      }}
                    >
                      {e.name}
                    </span>
                    <span className="value">{e.value}</span>
                  </Fragment>
                ),
              }))}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
