import { Card, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';

// ------------------------------------------------------------

import './index.css';

import Button from '@common/Button';
import Dialog from '@common/Dialog';
import Header from '@common/Header';
import PageLayout from '@common/PageLayout';
import CommonForm from '@common/CommonForm2';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import Store from '@data/Store';

const { language_type } = Store;
// ------------------------------------------------------------

export default function ({
  language,
  store,
  redirect,
  APIEndpoints,
  action,
  theme,
}) {
  const [calendar, setCalendar] = useState([]);
  const calendarComponentRef = useRef();
  const [companie, setCompanie] = useState([]);
  const [user, setUser] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [eventClicked, setEventClicked] = useState([]);
  const [eventExentendedProps, setEventExtendedProps] = useState([]);
  const [ticketEvent, setTicketEvent] = useState([]);
  const [openHelp, setOpenHelp] = useState(false);
  const [date_, setDate_] = useState(moment());

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeHelp = () => {
    setOpenHelp(false);
  };

  useEffect(() => {
    if (date_) {
      let calendarApi = calendarComponentRef.current.getApi();
      let date = moment(date_).format('YYYY-MM-DD');
      calendarApi.gotoDate(date);
    }
  }, [date_]);

  useEffect(() => {
    action
      .execute('post', APIEndpoints.CALENDAR, 'calendar', {}, null, null, null)
      .then((h) => {
        setCalendar(h.data);
      });
    action.execute('get', APIEndpoints.COMPANY, 'companies').then((b) => {
      let item = b.data.map((c) => ({
        value: c.id_company,
        label: c.name,
      }));
      if (store.getUserLoged().role === 'solicitant') {
        item = b.data
          .filter((n) => n.id_company === store.getUserLoged().id_company)
          .map((c) => ({
            value: c.id_company,
            label: c.name,
          }));
      }

      setCompanie(item);
    });

    action.execute('get', APIEndpoints.USER + '/all/0', 'users').then((j) => {
      let userOptions = j.data.map((c) => ({
        value: c.id_user,
        label: c.name,
      }));
      setUser(userOptions);
    });
    
  }, []);
  

  const handleEventClick = ({ event }) => {
    if (event.extendedProps.type == 'ticket') {
      action
        .execute('get', APIEndpoints.TICKET + '/' + event.id, 'ticket')
        .then((g) => {
          setTicketEvent(g);
        });
    }
    setEventClicked(event);
    setEventExtendedProps(event.extendedProps);
    openModal();
  };

  const openTicketProject = () => {
    eventExentendedProps.type == 'project'
      ? redirect('/dash/gp/' + eventClicked.id)
      : redirect('/dash/ticketmanagement', { ticket: ticketEvent.data });
  };

  const searchItem = (v) => {
    store.loading = true;
    action.execute('post', APIEndpoints.CALENDAR, 'calendar', v).then((h) => {
      setCalendar(h.data);
      if (v.dt_ini) {
        let calendarApi = calendarComponentRef.current.getApi();
        let date = moment(v.dt_ini).format('YYYY-MM-DD');
        calendarApi.gotoDate(date);
      }
    });
  };

  let ticketTotalMonth = 0;
  let riskTotalMonth = 0;
  let activityTotalMonth = 0;
  let total = 0;

  let events = [];
  let regex = /(<([^>]+)>)/gi;
  ticketTotalMonth = calendar && calendar.tickets && calendar.tickets.length;
  riskTotalMonth = calendar && calendar.risk && calendar.risk.length;
  activityTotalMonth =
    calendar && calendar.activityProject && calendar.activityProject.length;
  total = ticketTotalMonth + riskTotalMonth + activityTotalMonth;
  calendar &&
    calendar.tickets &&
    calendar.tickets.map((h) => {
      events.push({
        title: h.subject,
        description: h.description.replace(regex, ''),
        type: 'ticket',
        real_id: h.real_id,
        date: h.dt_cad,
        id: h.id_tickets,
        status:
          h.status == 'In Progress'
            ? language.TICKET_STATUS.IN_PROGRESS
            : h.status == 'Resolved'
            ? language.TICKET_STATUS.RESOLVED
            : h.status == 'Reopened'
            ? language.TICKET_STATUS.REOPENED
            : h.status == 'Approve'
            ? 'Em aprovação'
            : h.status == 'Approved'
            ? language.APPROVED
            : h.status == 'Open'
            ? language.TICKET_STATUS.OPEN
            : h.status == 'Closed'
            ? 'Encerrado'
            : h.status == 'Cancelled'
            ? language.TICKET_STATUS.TICKET_STATUS
            : '',
      });
    });
  calendar &&
    calendar.tickets &&
    calendar.risk
      .filter((b) => b.execution_date != null && b.execution_date != 'null')
      .map((h) => {
        events.push({
          title: h.desc,
          date: h.execution_date,
          color: '#F05E02',
        });
      });

  calendar &&
    calendar.activityProject &&
    calendar.activityProject.map((h) => {
      events.push({
        title: h.name,
        description: h.description.replace(regex, ''),
        start: h.dt_start,
        end: h.dt_end,
        color: '#33ACA0',
        id: h.id_project,
        id_project_activity: h.id_project_activity,
        type: 'project',
      });
    });

  let projetos =
    calendar &&
    calendar.projetos &&
    calendar.projetos.map((c) => ({
      value: c.id_project,
      label: c.name,
    }));

  return (
    <PageLayout
      title={language.ALLOCATION}
      rightElements={[]}
      subTitle={language.PROJECT_MANAGEMENT + '/'}
      back
    >
      <div
        id="Calendar"
        style={{
          '--form': theme.form,
          '--card': theme.card,
          marginTop: '-1rem',
        }}
      >
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          maxWidth="x1"
          PaperProps={{
            style: {
              boxShadow: 'none',
              alignItems: 'center',
              width: '40%',
              height: '60%',
            },
          }}
          contentProps={{
            style: {
              border: 'none',
              alignItems: 'center',
              width: '60%',
              height: '60%',
            },
          }}
        >
          <Grid
            className="content-medium"
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
            }}
          >
            <h3 className="title">{eventClicked.title}</h3>
            <div>
              {[
                {
                  label: language.DESCRIPTION + ': ',
                  value: eventExentendedProps.description,
                },
                {
                  label: language.START_ + ': ',
                  value: moment(eventClicked.start).format('DD/MM/YYYY'),
                },
                eventExentendedProps.type == 'project'
                  ? {
                      label: language.END + ': ',
                      value: moment(eventClicked.end).format('DD/MM/YYYY'),
                    }
                  : {
                      label: language.STATUS + ': ',
                      value: eventExentendedProps.status,
                    },
              ].map((e, i) => (
                <div key={i}>
                  <span className="label-item">{e.label}</span>
                  <span className="value-item">{e.value}</span>
                </div>
              ))}
            </div>
            <Button
              label={language.GO_TO}
              className="buttonModal"
              onClick={() => openTicketProject()}
            />
          </Grid>
        </Dialog>

        <Header
          className="justify-content-between"
          style={{ top: 129, zIndex: 0 }}
        >
          <div className="d-flex align-items-end"></div>
        </Header>

        <Card className="content">
          <Grid container spacing={16} className="content-desc">
            <Grid item xs={9} id="content-grid-calendar" className="pt-0">
              <Grid container spacing={16} className="content-desc">
                <Grid item xs={3}>
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.DEMAND_MANAGEMENT}</h4>
                      <span>{`${language.NUMBER_OF_OPEN_ACTIVITIES} (${language.MONTH})`}</span>
                    </div>
                    <h2>{ticketTotalMonth}</h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.PROJECT_MANAGEMENT}</h4>
                      <span>{`${language.NUMBER_OF_OPEN_ACTIVITIES} (${language.MONTH})`}</span>
                    </div>
                    <h2>{activityTotalMonth}</h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.RISKS_MANAGEMENT}</h4>
                      <span>{`${language.NUMBER_OF_OPEN_ACTIVITIES} (${language.MONTH})`}</span>
                    </div>
                    <h2>{riskTotalMonth}</h2>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="content-medium content-cards-desc">
                    <div>
                      <h4>{language.TOTAL_ACTIVITIES}</h4>
                      <span>{`${language.NUMBER_OF_OPEN_ACTIVITIES} (${language.MONTH})`}</span>
                    </div>
                    <h2>{total}</h2>
                  </div>
                </Grid>
              </Grid>
              <div id="content-calendar" className="mt-4">
                <CommonForm
                  fields={[
                    {
                      col: 3,
                      name: 'date',
                      type: 'date',
                      label: language.GO_TO,
                    },
                  ]}
                  onChangeField={(f, v) => setDate_(v)}
                />
                <FullCalendar
                  ref={calendarComponentRef}
                  defaultView="dayGridMonth"
                  plugins={[dayGridPlugin]}
                  buttonText={{
                    today: language.TODAY,
                  }}
                  eventClick={handleEventClick}
                  events={events}
                  columnHeaderFormat={{ weekday: 'long' }}
                  columnHeaderText={(date) =>
                    moment.weekdays().map((e) => e.replace('-feira', ''))[
                      date.getDay()
                    ]
                  }
                  header={{
                    left: 'title',
                    center: '',
                    right: 'today prev,next',
                  }}
                  titleFormat={{ year: 'numeric', month: 'long' }}
              //    locale={language_type.toLowerCase()} 
                />
              </div>
            </Grid>
            <Grid item xs={3} id="content-grid-filter">
              <div className="content-medium pb-0">
                <h3 id="_title" className="mb-5 mt-1">
                  {`${language.FILTER_BY}:`}
                </h3>
                <CommonForm
                  fields={[
                    {
                      type: 'element',
                      content: (
                        <div className="c-header">
                          <h4>{language.PERIOD}</h4>
                          <hr />
                        </div>
                      ),
                    },
                    {
                      col: 6,
                      type: 'date',
                      name: 'dt_ini',
                      className: 'fields',
                      label: language.START_,
                    },
                    {
                      col: 6,
                      type: 'date',
                      name: 'dt_end',
                      className: 'fields',
                      label: language.END,
                    },
                    {
                      type: 'element',
                      content: (
                        <div className="c-header">
                          <h4>{language.TECH}</h4>
                          <hr />
                        </div>
                      ),
                    },
                    {
                      type: 'select',
                      name: 'techs',
                      label: language.TECH,
                      className: 'fields',
                      isMultiMin: true,
                      options: user,
                      disabled:
                        store.getUserLoged().role == 'client' ? false : true,
                    },
                    {
                      type: 'element',
                      content: (
                        <div className="c-header">
                          <h4>{language.COMPANY}</h4>
                          <hr />
                        </div>
                      ),
                    },
                    {
                      type: 'select',
                      name: 'company',
                      label: language.COMPANY,
                      className: 'fields',
                      options: companie,
                    },
                    {
                      type: 'element',
                      content: (
                        <div className="c-header">
                          <h4>{language.USER}</h4>
                          <hr />
                        </div>
                      ),
                    },
                    {
                      type: 'select',
                      name: 'user',
                      label: language.USER,
                      className: 'fields',
                      options: user,
                      disabled:
                        store.getUserLoged().role == 'client' ? false : true,
                    },

                    {
                      type: 'element',
                      content: (
                        <div className="c-header">
                          <h4>{language.PROJECT}</h4>
                          <hr />
                        </div>
                      ),
                    },
                    {
                      type: 'select',
                      name: 'project',
                      label: language.PROJECT,
                      className: 'fields',
                      options: projetos,
                    },
                  ]}
                  onSubmit={(v) => searchItem(v)}
                  button={{
                    label: language.SEARCH,
                    icon: 'fas fa-search',
                    color: 'success',
                  }}
                />
              </div>
              <div className="content-medium pt-0 d-flex align-items-center justify-content-end">
                {
                  <Button
                    label={language.CLEAR}
                    color="warning"
                    className="mr-3"
                    onClick={() => redirect('/dash/calendar')}
                  />
                }
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </PageLayout>
  );
}
