import React, { Component } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';


const renderActiveShape = (props, eProps={}) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 11;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const centerValue = eProps.centerValue;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} {...eProps.centerProps}>{centerValue?(percent * 100).toFixed(0)+'%':payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {!centerValue&&
        <React.Fragment>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          {payload.titleHover!=false &&
            <text style={{fontSize:13}} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey+(payload.subTitleHover===false?15:0)} textAnchor={textAnchor} fill="#333">
              {`${(typeof payload.titleHover) == 'string' ? payload.titleHover : 'Valor '}${value}`}
            </text>
          }
          {payload.subTitleHover!=false &&
            <text style={{fontSize:10}} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey-(payload.titleHover===false?15:0)} dy={18} textAnchor={textAnchor} fill="#999">
              {`(${(typeof payload.subTitleHover) == 'string' ? payload.subTitleHover : 'Taxa '}${(percent * 100).toFixed(0)}%)`}
            </text>
          }
        </React.Fragment>
      }
    </g>
  );
};
const StraightAngle = (props) => {
  
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload
  } = props;
 
  return (
    <g>
      <text x={cx} y={cy+10} dy={8} textAnchor="middle" fill="black">{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      
    </g>
  );
}
export default class radialGraph extends Component {

  state = {
    activeIndex: 0,
  };

  onPieEnter = (data, index) => {
    
    if(this.props.onPieEnter){
      this.props.onPieEnter(data)
    }
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    let {
      data,
      width,
      height,
      innerRadius,
      outerRadius,
      cx,
      cy,
      type,
      eProps,
      margin

    } = this.props;
    return (

      <PieChart style={{ margin:margin||''  }}   width={width || 400} height={height || 400}>
        {type == 'angle' ? (
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={(e)=>renderActiveShape(e, eProps)}
            data={data}
            cx={cx || (!!width ? (width / 2) : 200)}
            cy={cy || (!!height ? (height / 2) : 200)}
            startAngle={180}
            endAngle={0}
            innerRadius={innerRadius || 60}
            outerRadius={outerRadius || 80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            onMouseEnter={this.onPieEnter}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color || '#8884d8'} />)
            }
          </Pie>
        ) : type=='StraightAngle'?(
          <Pie 
            activeIndex={this.state.activeIndex}
            activeShape={StraightAngle}
            data={data} 
            cx={cx || (!!width ? (width / 2) : 200)}
            cy={cy || (!!height ? (height / 2) : 200)}
            startAngle={180} 
            endAngle={0} 
            outerRadius={outerRadius || 80}
            fill="#8884d8" 
            dataKey="value" 
            label 
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color || '#8884d8'} />)
            }
          </Pie>
        ):(
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={(e)=>renderActiveShape(e, eProps)}
            data={data}
            cx={cx || (!!width ? (width / 2) : 200)}
            cy={cy || (!!height ? (height / 2) : 200)}
            innerRadius={innerRadius || 60}
            outerRadius={outerRadius || 80}
            fill='#8884d8'
            dataKey="value"
            onMouseEnter={this.onPieEnter}
          >
            {data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color || '#8884d8'} />)}
          </Pie>
          )}
      </PieChart>

    );
  }
}
