import React, { useState, useReducer, useEffect, Fragment } from 'react';
import crypto from 'crypto';
import { Tooltip } from 'react-tippy';
import { Avatar, Grid } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommonDelete from '../../common/CommonDelete';
import Button from '../../common/Button';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Constants from '../../data/Constants';
import Dialog from '@common/Dialog';
import Store from '@data/Store'
import moment from 'moment';
import RaisedButton from '../../common/RaisedButton';
import {Chip} from '@material-ui/core';

export default function ({
  action,
  APIEndpoints,
}) {
    const { language } = Store
  /*#####################################################-STATES-#########################################################################*/


  const [totalRecurrences, setTotalRecurrences] = useState(25);
  const [recurrences, setRecurrences] = useState([]);
  const [values, setValues] = useState([])
  const [typeRecurrence, setTypeRecurrence] = useState(1)
    const [page, setPage] = useState('list')
    const handlePage = (page, obj) => {
        setPage(page)
        setValues(obj)
    }
  const onDelete = () => {
    action
      .execute('delete', `${APIEndpoints.DEVICE}/outagerecurrence/${values.id_outage_recurrence}`, '')
      .then((h) => {
        callPageSelectFilter()
        handlePage('list')
      });
  };

  useEffect(() => {
    callPageSelectFilter()
  }, [])

  useEffect(() => {
    if (values && values.type_recurrence){
      setTypeRecurrence(values.type_recurrence)
    }

  }, [values])

  const callPageSelectFilter = (page = 0, items = {}, rowPerPage = 75) => {
    let limit = rowPerPage;
    let offset = page * limit;
    action
      .execute(
        'post',
        `${APIEndpoints.DEVICE}/getalloutagerecurrence?offset=${offset}&limit=${limit}`,
        '',
        items
      )
      .then((h) => {
        setTotalRecurrences(h.data[0].total_count);
        setRecurrences(h.data);
      });
  };


  const saveOutage = (values) => {
    values.start_date = `${moment(values.start_date).format(
      'YYYY-MM-DD'
    )} ${moment(values.start_time).format('LTS')}`;

    values.end_date = `${moment(values.end_date).format('YYYY-MM-DD')} ${moment(
      values.end_time
    ).format('LTS')}`;

    const method = values.id_outage_recurrence ? 'put' : 'post'
    const url = values.id_outage_recurrence ? `${Constants.APIEndpoints.DEVICE}/outagerecurrence/${values.id_outage_recurrence}` : `${Constants.APIEndpoints.DEVICE}/outagerecurrence`
    action
      .execute(method, url , '', values)
      .then((data) => {
        callPageSelectFilter()
        handlePage('list')
      });
  };

  const importFiles = (values) => {
    let filesSend = [];
    let cont = 0;
    if (values.file && values.file.length > 0) {
      let total = Array.from(values.file).length;
      Array.from(values.file).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
            filesSend = [];
            cont = 0;
            setTimeout(() => {
              handlePage('list'), 1000;
            });
          }
          filesSend.push({
            file: result,
            type: file.type,
            namefile: file.name,
          });
          cont = cont + 1;
          if (total === cont) {
            values.file = filesSend;

            action
              .execute('post', `${Constants.APIEndpoints.DEVICE}/importoutage`, '', values)
              .then((data) => {
                setTimeout(() => {
                  callPageSelectFilter()
                  handlePage('list'), 1000;
                });
              });
          }
        });
      });
    }
  };

  const dailies = moment()._locale._weekdays.map(e =>  e)
  const data = recurrences.map((i) => ({
    name: i.ic,
    type_recurrence: i.type_recurrence == 1 ? language.DAILY : i.type_recurrence == 2 ? language.WEEKLY : language.MONTHLY,
    first_day_week : i.type_recurrence == 2 ? dailies[i.first_day_week] : '',
    start_date : i.type_recurrence == 3  ? moment(i.start_date).format('DD/MM/YYYY HH:mm:ss') : moment(i.start_date).format('HH:mm:ss'),
    last_day_week : i.type_recurrence == 2 ? dailies[i.last_day_week] : '',
    end_date : i.type_recurrence == 3 ? moment(i.end_date).format('DD/MM/YYYY HH:mm:ss') : moment(i.end_date).format('HH:mm:ss'),
    start_date_outage : moment(i.start_recurrence).format('DD/MM/YYYY HH:mm:ss'),
    end_date_outage : moment(i.end_recurrence).format('DD/MM/YYYY HH:mm:ss'),
    action: (
      <React.Fragment>
        <Chip
          className='mx-2 muit-chip'
          label={
            <div className='d-flex align-items-center justify-content-between'>
               <RaisedButton
                circleButton
                icon='fas fa-edit'
                label={language.EDIT}
                color='default'
                style={{fontSize:12, width:21, height:21}}
                className='mr-2'
                onClick={() => handlePage('add', i)}
                />
              <RaisedButton
                circleButton
                icon='far fa-trash-alt'
                label={language.DELETE}
                color='danger'
                style={{fontSize:13, width:21, height:21}}
                onClick={() => handlePage('remove', i)}
              />
           </div>
          }
        />

      </React.Fragment>
    )
  }))

  return (
      <div>
        <CommonTable
          paginationTop={false}
          countTotal={totalRecurrences}
          loadSearch={true}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          title={''}
          beforeBar={
            <div>
              <Button
                variant="circle"
                icon="fas fa-plus"
                color="warning"
                label={language.ADD}
                className="mr-3"
                onClick={() => handlePage('add', {})}
              />
              <Button
                variant="circle"
                icon="fas fa-file-excel"
                color="warning"
                label={language.IMPORT}
                className="mr-3"
                onClick={() => handlePage('import', {})}
              />
            </div>
          }
          columns={[
            {
              key: 'name',
              label: 'IC',
              style: { textAlign: 'center', width: 300 },
              thConfig: true,
            },
            {
              key: 'type_recurrence',
              label: language.TYPE,
              style: { textAlign: 'center', width:  100 },
              thConfig: true,
            },
            {
                key: 'first_day_week',
                label: language.DAY,
                style: { textAlign: 'center', width: 200 },
                thConfig: true,
            },
            {
              key: 'start_date',
              label: language.INIT_OUTAGE,
              style: { textAlign: 'center', width: 200 },
              thConfig: true,
            },
            {
                key: 'last_day_week',
                label: language.DAY,
                style: { textAlign: 'center', width: 200 },
                thConfig: true,
            },
            {
                key: 'end_date',
                label: language.END_OUTAGE,
                style: { textAlign: 'center', width: 200 },
                thConfig: true,
            },
            {
                key: 'start_date_outage',
                label: language.START_RECURRENCE,
                style: { textAlign: 'center', width: 200 },
                thConfig: true,
            },
            {
                key: 'end_date_outage',
                label: language.END_RECURRENCE,
                style: { textAlign: 'center', width: 200 },
                thConfig: true,
            },
            {
              key: 'actions',
              label: language.ACTIONS,
              style: { textAlign: 'center', width: 200 },
              thConfig: true,
           },
          ]}
          index="ChangeManagementList"
          data={data}
          //showHeaderToolbar={false}
          orderColumn
          searchColumn={true}
          searchColumnEvent={(v) => callPageSelectFilter(0, v, 75)}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          onSortItem={(item, sortByStatus) => callSortItem(item, sortByStatus)}
          rowsPerPageOptions={[75]}
        />

{page == 'add' && (
          <Dialog
          open = {true}
          onClose = {() => handlePage('list')}
          title = {language.ADD}
          >
          <CommonForm
           values = {values}
            onSubmit={(e) => {
              e.new = true;
              saveOutage(e);
            }}
            onChangeField  = {(f, v) => {
                if (f.name == 'type_recurrence'){
                    setTypeRecurrence(v)
                }
            }}
            fields={[
              {
                col:  8,
                type: 'autocomplete',
                name: 'id_device',
                textlabel: language.DEVICE,
                method: 'POST',
                route: `${APIEndpoints.DEVICE}/filter/`,
                routeGetCount: `${APIEndpoints.DEVICE}/filter?count=0`,
                fieldquery: 'search',
                textinit: values && values.ic ? values.ic : language.DEVICE,
                fieldvaluedb: 'id_device',
                fieldlabeldb: 'name',
                idinit: values && values.fk_id_device ? values.fk_id_device : 0,
                routeAll: `${APIEndpoints.DEVICE}/filter?`,
                methodGetAll: 'POST',
                required: true,
              },
              {
                col: 4,
                type: 'select',
                name: 'type_recurrence',
                options: [{ value: 1, label: language.DAILY }, {value : 2, label : language.WEEKLY}, {value: 3, label : language.MONTHLY}],
                label: language.SYSTEM,
                required: true,
              },
              {
                col: typeRecurrence == 1 ? 6 : 3,
                type: 'date',
                name: 'start_date',
                label: language.INIT_OUTAGE,
                required: true,
                visible : typeRecurrence == 3 ? true : false
              },
              {
                col : 3,
                type : 'select',
                name : 'first_day_week',
                label : language.DAY,
                options : moment()._locale._weekdays.map((e, i) => {
                      return {
                        value: i,
                        label: `${i + 1} / ${e}`
                      }
                  }),
               visible : typeRecurrence == 2 ? true : false,
               required : typeRecurrence == 2 ? true : false
              },
              {
                col: typeRecurrence == 1 ? 6 : 3,
                type: 'time',
                name: 'start_time',
                label: language.HOUR,
                required: true,
              },
              {
                col: 3,
                type: 'date',
                name: 'end_date',
                label: language.END_OUTAGE,
                required: true,
                visible : typeRecurrence ==3  ? true : false

              },
              {
                col:3,
                type : 'select',
                name : 'last_day_week',
                label : language.DAY,
                options : moment()._locale._weekdays.map((e, i) => {
                      return {
                        value: i,
                        label: `${i + 1} / ${e}`
                      }
                  }),
               visible : typeRecurrence == 2 ? true : false,
               required : typeRecurrence == 2 ? true : false
              },
              {
                col: typeRecurrence == 1 ? 6 : 3,
                type: 'time',
                name: 'end_time',
                label: language.HOUR,
                required: true,
              },
              {
                col: 6,
                type: 'date',
                name: 'start_recurrence',
                label: language.START_RECURRENCE,
                required: true,
              },
              {
                col: 6,
                type: 'date',
                name: 'end_recurrence',
                label: language.END_RECURRENCE,
                required: true,
              },

            ]}
          />
          </Dialog>
        )}

{page === 'import' && (
        <Dialog
          title={language.IMPORT}
          open={true}
          onClose={() => handlePage('list')}
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'file',
                label: language.ATTACH,
                type: 'file',
                customPortalCss: true,
              },

            ]}
            onSubmit={importFiles}
          />
        </Dialog>
      )}
      {page == 'remove' ? (
        <CommonDelete
          language={language}
          message={language.WARNING_DELETE_ITEM}
          onDelete={onDelete}
          closeModal={() => handlePage('list')}
        />
      ) : null}
  </div>
  )
}
