

export function percent(value, total) {
	return parseInt(Math.abs(((value * 100) / total).toFixed(0)));
}

export function net(value) {
	value = value / 1024;
	if (value >= 1024) return (value / 1024).toFixed(1) + 'Mbps';
	else if (value >= 1024 * 1024) return (value / (1024 * 1024)).toFixed(1) + 'Gbps';
	else return value.toFixed(1) + 'Kbps';
}

export function bytes(value) {
	value = value / 1024;
	if (value >= 1024) return (value / 1024).toFixed(1) + ' MB/s';
	else if (value >= 1024 * 1024) return (value / (1024 * 1024)).toFixed(1) + ' GB/s';
	else return value.toFixed(1) + ' KB/s';
}

export function mem(value) {
	return (((value / 1024) / 1024) / 1024).toFixed(1);
}

// convertMemory(value) {

// 	value = (value / 1024);
// 	let un = 'KB'
// 	if (value > 1024) {
// 		value = value / 1024;
// 		un = 'MB'

// 		if(value > 1024){
// 			value = value / 1024;
// 			un = 'GB'

// 			if(value > 1024){
// 				value = value / 1024;
// 				un = 'TB'
// 			}
// 		}

// 	}
// 	return value.toFixed(1)+''+un;
// },

export function convertMemory(value) {
	try {
		if (String(value).length > 5) {
			value = (value / 1024);
			let un = 'KB'
			if (value > 1024) {
				value = value / 1024;
				un = 'MB'

				if (value > 1024) {
					value = value / 1024;
					un = 'GB'

					if (value > 1024) {
						value = value / 1024;
						un = 'TB'
					}
				}
			}
			return value.toFixed(1) + '' + un;
		} else {
			if (value / 1024 >= 1024) {
				return (value / (1024 * 1024)).toFixed(1) + 'G'
			} else if (value >= 1024) {
				return (value / 1024).toFixed(1) + 'M'
			} else {
				return value.toFixed(1) + 'K'
			}
		}
	} catch (e) {
		return 0
	}

}

export function disk(value) {

	//console.log("tamanho do value");
	//console.log(String(value).length);

	let un;
	if (String(value).length > 8) {
		value = (value / 1024);
		un = 'KB'
	}

	if (value > 1024) {
		value = value / 1024;
		un = 'MB'

		if (value > 1024) {
			value = value / 1024;
			un = 'GB'

			if (value > 1024) {
				value = value / 1024;
				un = 'TB'
			}
		}
	}

	return value.toFixed(1) + '' + un;
}

export function uptime(data, language) {
	if (data) {
		let delta = data;
		let days = Math.floor(delta / 86400);
		delta -= days * 86400;
		let hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;
		let minutes = Math.floor(delta / 60) % 60;
		return (days + ' ' + language.DAYS + ', ' + hours + ' ' + language.HOURS + ' ' + minutes + ' ' + language.MINUTES)
	}
	else {
		return language.NOT_AVAILABLE;
	}
}

