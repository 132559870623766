import React, { useRef } from 'react';
import Button from '@common/Button';
import AliceCarousel from 'react-alice-carousel';
import { Card, Grid } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import { ProjectsItemGraph } from './Graphs';
import ReactHighcharts from 'react-highcharts';
import './ProjectsEvolution.css';
import Evolution from '../ProjectManagementList/Evolutionvalue'

function ProgressSetor({ items }) {

	return (
		<div className='progress-setor d-flex my-2'>
			{items.map((e, i) => (
				<div key={i} style={{ width: e.value + '%', backgroundColor: e.color }}></div>
			))}
		</div>
	)
}

function ProjectsItem({ project, language,action,APIEndpoints }) {
	let riskType = [language.RISKC.AC, language.RISKC.CRITICAL,
	language.RISKC.IN];
	let riskColor = ['var(--success)', 'var(--warning)',
		'var(--danger)'];
	return (
		<div className='px-2 ProjectsItem my-3'>
			<Card className='content px-2'>
				<h3 >{project.name}</h3>
				<ReactHighcharts config={ProjectsItemGraph(Evolution(project).percentual)} />
				<span style={{ backgroundColor: project.type_risk ? riskColor[project.type_risk - 1] : '' }}	>{project.type_risk ? riskType[project.type_risk - 1] : ''}</span>
				<ProgressSetor
					items={[
						{ value: project.statusproject.DONEPER, color: 'var(--primary)' },
						{ value: project.statusproject.TODOPER+project.statusproject.DOINGPER,  color: 'var(--warning)' },
						{ value: project.statusproject.STOPPEDPER, color: 'var(--danger)' },
					]}/**  STOPPEDPER: ((STOPPED.length/totalPer)*100),
					TODOPER:((TODO.length/totalPer)*100),
					DOINGPER:((DOING.length/totalPer)*100),
					DONEPER:((DONE.length/totalPer)*100) 
					
					PROJECT_STATUS: {
    TO_DO: 'TO DO',
    DOING: 'DOING',
    STOPPED: 'STOPPED',
    DONE: 'DONE'
  }
					*/
				/>
				<div>
					<Grid container spacing={2}>
						{[
							{ value: project.statusproject.DONEPER > 0? 
								parseInt(project.statusproject.DONEPER ):0
								
								, label: language.CON },
							{ value: (project.statusproject.TODOPER +project.statusproject.DOINGPER) >0
								?parseInt((project.statusproject.TODOPER +project.statusproject.DOINGPER)):0 ,
							 label: language.TICKET_STATUS.OPEN },
							{ value: project.statusproject.STOPPEDPER >0 ?
								parseInt(project.statusproject.STOPPEDPER):0, label: language.PROJECT_STATUS.STOPPED },
						].map((e, i) => (
							<Grid key={i} item xs={4}>
								<div
									className='card-items-info pb-2 px-2'
									style={{
										backgroundColor: (
											i == 0 ?
												'var(--primary)' :
												i == 1 ?
													'var(--warning)' :
													i == 2 ?
														'var(--danger)' :
														null
										)
									}}
								>
									<h3>{e.value}%</h3>
									<span>{e.label}</span>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
			</Card>
		</div>
	)
}






export default function (props) {
	let { language, projects,action,APIEndpoints } = props;

	const CarouselRef = useRef(null);
	var renderCarousel = projects.projetos.map((e, i) => (<ProjectsItem APIEndpoints={APIEndpoints} action={action} language={language} project={e} key={i} />))
	if (projects.projetos.length < 6) {
		for (var i = projects.projetos.length; i < 6; i++) {
			renderCarousel.push(<div></div>)
		}
	}
	return (
		<Card id='ProjectsEvolution' className='border-radius-10'>
			<AppBar title={language.EVOLUTION} dividerProps={{ className: 'mb-0' }}>
				<div style={{ minWidth: 250 }} className='pb-2'>
					<CommonForm
						individual
						fields={[
							{
								type: 'text',
								startIcon: 'fas fa-search',
								name: 'search',
								placeholder: language.SEARCH + '...',
								className: 'darks',
								contentProps: {
									className: '-mt-2'
								}
							}
						]}
						onChangeField={(f, v) => {

						}}
					/>
				</div>
			</AppBar>
			<div className='d-flex align-items-center justify-content-between content-project-carousel'>
				<Button
					icon='fas fa-angle-left'
					variant='circle'
					style={{ borderRadius: 0 }}
					className='button-control'
					onClick={() => CarouselRef.current.slidePrev()}
				/>
				<div className='carousel-content'>

					<AliceCarousel
						startIndex={0}
						ref={CarouselRef}
						dotsDisabled
						buttonsDisabled
						mouseTrackingEnabled
						fadeOutAnimation
						stagePadding={{
							// paddingRight : 18 
						}}
						responsive={{
							0: {
								items: 6
							}
						}}
						infinite={false}
					>

						{renderCarousel}

					</AliceCarousel>
				</div>
				<Button
					icon='fas fa-angle-right'
					variant='circle'
					style={{ borderRadius: 0 }}
					className='button-control'
					onClick={() => CarouselRef.current.slideNext()}
				/>
			</div>
		</Card>
	)
}