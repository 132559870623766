import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Tooltip } from 'react-tippy';
import { formatCurrency } from '../../../../util';
import Store from '@data/Store';
import Actions from '@data/Actions'
const action = new Actions();
import Constants from '@data/Constants';

const { language } = Store;

import ModalValuesRealized from './ModalValuesRealized'
import ModalValuesRealizedByContract from './ModalValuesRealizedByContract'

const Item = ({title, value})=>(
    <div className='item content-min'>
        <span>{title}</span>
        <span>{(value||0).toLocaleString('pt-BR', {style: 'currency',currency: 'BRL'})}</span>
    </div>
)
const Item2 = ({title, value:_value, id})=>{
   const [showValues, setShowValues] = useState(false)
   const [hasMoreThanOneContract, setHasMoreThanOneContract] = useState(false)
   const [otherCustData, setOtherCustData] = useState([]);
   
   useEffect(() => {
      action.execute('get', Constants.APIEndpoints.PROJECT + `/${id}/others-custs`)
            .then(response => response.data)
            .then(data => setOtherCustData(data))
            .catch(err => console.error(err))
   }, [id])
   let value = 0;

   useEffect(() => {
      findContractsFromProject().then((r) => {
         const hasMoreThanOneContract = r.length > 1;
         setHasMoreThanOneContract(hasMoreThanOneContract);
      });
   }, []);

   const findContractsFromProject = () => {
      return action.execute('get', Constants.APIEndpoints.PROJECTS + `/cost/contracts/${id}`)
         .then(response => response.data)
         .catch(err => console.error(err))
   }

   let techs = {}
   let users = _value.map(e=>{
      let [userName, valueConsumed, activity, timeActivity ] = e.split('**');
      
      if (!techs[userName]) {
         techs[userName] = {
            user: userName,
            total: parseFloat(valueConsumed),
            activities: [{ 
               valueConsumed: formatCurrency(parseFloat(valueConsumed) || 0, { locale: 'pt-br' }), 
               activity,
               timeActivity
            }]
         }
      } else {
         techs[userName].total += parseFloat(valueConsumed)
         techs[userName].activities.push({ 
            valueConsumed: formatCurrency(parseFloat(valueConsumed) || 0, { locale: 'pt-br' }), 
            activity,
            timeActivity
         })
      }
      value += parseInt(valueConsumed)||0

      return userName
   });

   users = users.filter((x, i) => users.indexOf(x) === i)
   let dataOther = []
   if (otherCustData.length > 0) {
      dataOther = otherCustData.map(ocd => {
         value += parseInt(ocd.value_cust);
         return {
            id_project_others_custs: ocd.id_project_others_custs,
            title: ocd.title,
            description: <div dangerouslySetInnerHTML={
               {__html: ocd.description}
            } />,
            value_cust: formatCurrency(ocd.value_cust, {
               locale: 'pt-br'
            })
         }
      })
   }

   const handleShowModal = useCallback(() => setShowValues(prev => !prev), [])
   return (
      <Fragment>
         {showValues && (hasMoreThanOneContract ? (
            <ModalValuesRealizedByContract
               open={showValues}
               projectId={id}
               onClose={handleShowModal}
               otherCusts={dataOther}
               techCusts={Object.values(techs)}
            />
         ) : (
            <ModalValuesRealized
               id={id}
               open={showValues}
               onClose={handleShowModal}
               otherCusts={dataOther}
               techCusts={Object.values(techs)}
            />
         ))}
      <Tooltip
         html={
            <ul style={{ color: 'var(--primary)', fontSize: 11 }}>
               {users.map(e=>(
                  <li key={e}>{e}</li>
               ))}
            </ul>
         }
         position="left"
         theme='light'
         interactive
         open={!value?false:undefined}
         arrow
      >
         <div className='item content-min item-2' onClick={handleShowModal}>
            <span>{hasMoreThanOneContract ? language.VIEW_COSTS : title}</span>
               {!hasMoreThanOneContract && (
                  <span>
                     {parseFloat(value.toString().replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </span>
               )}
         </div>
      </Tooltip>
      </Fragment>
   )
}

export default function ({ foreseen, realized, id }) {
   
	return(
		<div className='ForeseenVsRealized'>
         <Item title={language.EXPECTED_COST} value={foreseen}/>
         <Item2 title={language.REALIZED_COST} value={realized} id={id}/>
		</div>
	)
}