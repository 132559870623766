import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../../../common/RaisedButton';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonTable from '../../../../common/CommonTable';
import BarBasic from './gsdGraph/BarBasic';

// {/* - qtde chamados por analista (em tempo e qtde) */}
@observer
export default class AnalystCalls extends Component {
  render() {
    let { language, handlePage, users, tickets } = this.props;
    //let userAnalyst = users.map(u => u.id_user);
    let valuestickets = tickets;
    //items.map(d => data[0][d.name] = d.qtd);

    return (
      <PageLayout
        icon={
          <div className="imgGsd" style={{ width: '44px', height: '44px' }} />
        }
        back={()=>   handlePage('dash')}
        title={language.REPORTS}
        subtitle={language.ANALYST_CALLS}
        rightElements={[]}
      >
        <Card className="cardDefault">
          <div className="cardInternalDefault">
            <h2 className="titleGraph">{language.CALLS}</h2>
            <h2 className="subTitleGraph">{language.CALLED_BY_ANALYST}</h2>
          </div>
          <div className="titleGraphCalls">
            <Row md={10}>
              <Col md={12}>
                <div>
                  <BarBasic tick={valuestickets} language={language} />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </PageLayout>
    );
  }
}
