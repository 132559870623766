import React from 'react';

// ----------------------------------------------------------------------

import { deleteHolidayService } from '../../ListHolidays.services';

import CommonDelete from '@common/CommonDelete';

// ----------------------------------------------------------------------

export const DialogConfirmDelete = ({
  closeModal,
  language,
  holidayId,
  message,
  slaId,
}) => {
  const handleDeleteHoliday = () => {
    deleteHolidayService(slaId, holidayId);
    closeModal();
  };

  return (
    <CommonDelete
      language={language}
      open
      closeModal={closeModal}
      onDelete={handleDeleteHoliday}
      message={message}
    />
  );
};
