import React, { Component } from 'react';
import { Card, Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import FaClose from 'react-icons/lib/fa/close';

export default class Site extends Component {
  constructor() {
    super();
    this.state = {
      groupId: null,
      groupModal: false
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_group_black_list') {
      this.setState({
        groupId: value
      });
    }
  }

  render() {
    let {
      language,
      handlePage,
      onFormSubmit,
      device,
      group,
      blackLists
    } = this.props;
    let { groupId } = this.state;
    let values = '';

    if (groupId != null) {
      blackLists = blackLists
        .filter(c => c.fk_id_group_black_list == groupId)
        .map(c => {
          return c;
        });
    }

    let responsibleOptions = [];
    if (group != undefined) {
      responsibleOptions = group.map(c => ({
        value: c.id_group_black_list,
        text: c.name
      }));
    }

    values = [{ fk_id_group_black_list: 0, fk_id_device: device.id_device }];

    let devices = [{ value: device.id_device, text: device.name }];
    let fields = [
      {
        span: 3,
        name: 'fk_id_group_black_list',
        label: 'Group',
        type: 'select',
        options: responsibleOptions
      },
      {
        span: 3,
        name: 'fk_id_device',
        label: 'Device',
        type: 'select',
        options: devices,
        disabled: !!(values && values.id_device)
      }
    ];

    let button = { md: 12, label: language.SAVE, primary: true };

    return (
      <Dialog modal={false} open={true} onClose={() => handlePage('list')}>

        <p> {device.name + '#'} </p>
        <CommonForm
          values={values}
          language={language}
          fields={fields}
          onFormSubmit={onFormSubmit}
          button={button}
        />

      </Dialog>
    );
  }
}
