import { useMemo } from 'react';
import CommonTable from '@common/CommonTable';
import Store from '@data/Store';
import Button from '@common/Button';
import { formatCurrency } from '../../../../../util';

export const ContractCust = ({ contractCust, onDelete }) => { 
  const { language } = Store;
  const items = useMemo(() => {
    return contractCust.map((c) => {
      return {
        user_name: c.user_name,
        value_hour: formatCurrency(c.value_hour),
        actions: (
          <Button
            variant="circle"
            icon="far fa-trash-alt"
            label={language.DELETE}
            color="danger"
            style={{ fontSize: 13 }}
            size={0.7}
            onClick={() => onDelete(c.id)}
          />
        )
      }
    });
  });

  const contractName = useMemo(() => {
    return contractCust && contractCust[0] ? contractCust[0].contract_name || '' : '';
  }, []);
  
  return (
    <div style={{ marginBottom: 30 }}>
      <h2 className="title-cust">{contractName} - {language.ADDED_COSTS}</h2>
      <CommonTable
        className="mt-2"
        col={[
          {
            key: 'user_name',
            label: language.PARTICIPANT,
            style: { width: '45%' },
          },
          {
            key: 'value_hour',
            label: language.HOUR_VALUE,
            style: { width: '45%' },
          },
          {
            key: 'actions',
            label: language.ACTIONS,
            style: { width: '10%' },
          }
        ]}
        AppBarProps={{
          style: {
            borderRadius: '10px 10px 0 0',
          },
        }}
        data={items}
      />
    </div>
  );
}