import React, { useState, useEffect } from 'react';

let delayTimer;
export default function ({ _value, styleSearch, _handleValue, i,e }) {
  let [value, setValue] = useState('');

  function handleValue(val, i) {
    setValue(val);

    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
      _handleValue(val, i);
    }, 1000);
  }
  useEffect(() => {
    setValue(_value);
  }, [_value]);
  return (
    <input
      type="search"
      value={value ? value : ''}
      className="th_input_search"
      onChange={(evt) => handleValue(evt.target.value, e)}
      style={{ ...(styleSearch || {}) }}
    />
  );
}
