import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Card, Chip } from '@material-ui/core';
import { observer } from 'mobx-react';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
@observer
export default class DeviceICList extends Component {
  render() {
    let { language, handlePage, listDevicesIC } = this.props;

    let col = [
      {
        key: 'id_device_ic',
        label: language.ID,
        style: { width: 1 },
        thConfig: true,
      },
      {
        key: 'name',
        label: language.DESCRIPTION,
        style: { width: 900 },
        thConfig: true,
      },
      {
        key: 'validate',
        label: language.VALIDATE_RELATIONSHIP,
        style: { textAlign: 'center' },
        thConfig: true,
      },
      {
        key: 'dt_cad',
        label: language.DATE_CAD,
        style: { width: 120, textAlign: 'center' },
        thConfig: true,
      },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 40, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data = listDevicesIC.map((obj) => {
      return {
        id_device_ic: obj.id_device_ic,
        name: obj.name,
        validate: obj.validate ? 'Sim' : 'Não',
        dt_cad: moment(obj.dt_cad).format('L'),

        action: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <RaisedButton
                    circleButton
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    className="mr-2"
                    onClick={() => handlePage('form', obj)}
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index="DeviceICList"
          searchColumn
          orderColumn          
          paginationTop={false}          
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
