import { settings } from 'nprogress';
import React, { useState, useEffect } from 'react';
import moment from 'moment'
import Emmiter from './EventEmitter_';
import Constants from '@data/Constants';

const tenant = Constants.Tenant;

// --------------------------------icons----------------------------------
String.prototype.toHHMMSS = function () {
  var cur = parseInt(this, 10); // don't forget the second param

  let days = Math.floor(cur / 86400);
  cur -= days * 86400;
  let hours = Math.floor(cur / 3600) % 24;
  cur -= hours * 3600;
  let minutes = Math.floor(cur / 60) % 60;
  cur -= minutes * 60;
  let seconds = Math.floor(cur) % 60;

  var time =
    days.toString().padStart(2, '0') +
    ' Dias ' +
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0');

  return time;
};

export default function({device, ioServers,Emitter,items}) {
  let [color, Setcolor] = useState('default');
  let [update, setupdate] = useState(null);

  useEffect(()=>{

    ioServers.on(`_${tenant}_${device.id_device}`,
      (data) => {
        if(data.key=='sys-uptime'){
      
              let uptimeTemp = data.values;
                var uptimes = (uptimeTemp.uptime + '').toHHMMSS();
                setupdate(uptimes);
        }
  
      })


    ioServers.emit('lastmonitor',`_${tenant}_${device.id_device}`)


  },[])

    useEffect(() => {
      
      ioServers.on(`_${tenant}_${device.id_device}`,
        (data) => {
           
          if(data.offline==true ){
           Setcolor('red')
           setupdate(null)
           } else{
           Setcolor('#00ff00')
           }
        });
 
   // return () => clearInterval(intervalId); //This is important

 }, [device]);

  return <span><i className='fas fa-circle' style={{ fontSize: 13, color:  color,textAlign:'left' }} />
      <span style={{marginLeft:10}}>{update != null ?update :''}</span> </span>

}
