import { useEffect, useState } from 'react';
import Actions from '../../data/Actions';
import Constants from '../../data/Constants';
import Store from '../../data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';

const { language } = Store;

const fields = (cond, signal, options = {}) => {
  let APIRoot = Constants.APIRoot;
  let APIEndpoints = Constants.APIEndpoints;
  let action = new Actions();
  let [channel, setChannel] = useState([]);
  let [priority, setPriority] = useState([]);
  let [urgency, setUrgency] = useState([]);
  let [impact, setImpact] = useState([]);
  let [country, setCountry] = useState([]);
  let [states, setStates] = useState([]);
  let [cities, setCities] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [statusAwaiting, setStatusAwaiting] = useState([]);
  let [statusTickets, setStatusTicket] = useState([]);

  let [typeTicket, setTypeTicket] = useState([
    { value: 4, label: language.TICKET_TYPES.SOLICITATION },
    { value: 1, label: language.TICKET_TYPES.INCIDENT },
    { value: 2, label: language.TICKET_TYPES.PROBLEM },
  ])

  let [typeTechOptions, setTypeTechOptions] = useState([
    { value: 1, label: language.INTERNAL },
    { value: 2, label: language.EXTERNAL },
  ])

  useEffect(() => {
    action
      .execute('get', APIEndpoints.GENERAL_PARAMETERS)
      .then((h) => {
        const generalParams = h.data;
        const typeTicketUpdated = [];

        if (generalParams.enable_event_ticket_type) {
          typeTicketUpdated.unshift({ value: 5, label: language.TICKET_TYPES.EVENT });
        }
        if (generalParams.enable_crisis_ticket_type) {
          typeTicketUpdated.unshift({ value: 6, label: language.TICKET_TYPES.CRISIS });
        }
        if (generalParams.enable_critical_incident_ticket_type) {
          typeTicketUpdated.unshift({ value: 7, label: language.TICKET_TYPES.CRITICAL_INCIDENT });
        }
        setTypeTicket([...typeTicket, ...typeTicketUpdated]);
      });
  }, []);

  useEffect(() => {
    if (cond === 'status_awaiting') {
      action
        .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
        .then((res) => {
          setStatusAwaiting(res.data);
        });
    }

    if (cond === 'status_tickets') {
      action
        .execute('get', APIEndpoints.TABLE_STRUCTURED + '/10', 'status_tickets')
        .then((item) => {
          setStatusTicket(item.data);
        });
    }

    if (cond === 'priority') {
      action
        .execute('get', APIEndpoints.TABLE_STRUCTURED + '/60', 'priorities')
        .then((item) => {
          setPriority(item.data);
        });
    }

    if (cond === 'urgency') {
      action
        .execute('get', APIEndpoints.TABLE_STRUCTURED + '/40', 'urgencies')
        .then((item) => {
          setUrgency(item.data);
        });
    }

    if (cond === 'impact') {
      action
        .execute('get', APIEndpoints.TABLE_STRUCTURED + '/50', 'impacts')
        .then((item) => {
          setImpact(item.data);
        });
    }

    if (cond == 'location_country') {
      action.execute('get', APIEndpoints.COUNTRY, 'countries').then((h) => {
        let countryOptions = h.data.map((obj) => {
          return { value: obj.id_country, label: translateFromPtBR(obj.name, Store.language) };
        });
        setCountry(countryOptions);
      });
    }

    if (cond == 'location_city') {
      ///api/country/city/:id
      const countryId = options.countryId || 32;
      action
        .execute('get', APIEndpoints.COUNTRY + '/city/' + countryId, 'cities')
        .then((h) => {
          let cityOptions = h.data.map((obj) => {
            return { value: obj.id_city, label: obj.name };
          });
          setCities(cityOptions);
        });
    }

    if (cond == 'location_uf') {
      const countryId = options.countryId || 32;
      action
        .execute('get', APIEndpoints.COUNTRY + '/' + countryId, 'states')
        .then((h) => {
          let stateOptions = h.data.map((obj) => {
            return { value: obj.id_state, label: obj.name };
          });
          setStates(stateOptions);
        });
    }

    if (cond === 'channel') {
      action
        .execute('get', APIEndpoints.TABLE_STRUCTURED + '/120', 'channel')
        .then((item) => {
          setChannel(item.data);
        });
    }

    if (cond == 'form') {
      action
        .execute('get', `${Constants.APIEndpoints.TASK}/getforms?type=kv`)
        .then((res) => {
          setTemplates(res.data);
        });
    }

    if (cond == 'contract') {
      action
        .execute('get', `${Constants.APIEndpoints.TASK}/getforms?type=kv`)
        .then((res) => {
          setTemplates(res.data);
        });
    }
  }, [cond]);

  if (cond == 'created') {
    return {
      col: 4,
      type: 'date',
      name: 'created',
      label: language.CREATED
    }
  }

  if (cond == 'subject') {
    return {
      col: 4,
      name: 'subject',
      label: language.SUBJECT,
      type: 'text',
      required: true
    }
  }

  if (cond == 'type_tickets') {
    return {
      col: 4,
      type: 'select',
      name: 'type_tickets',
      options: typeTicket,
      label: language.CALLED_TYPE,
    };
  }

  if (cond == 'type_tech') {
    return {
      col: 4,
      type: 'select',
      name: 'type_tech',
      options: typeTechOptions,
      label: language.TECH_TYPE,
    };
  }

  if (cond == 'form') {
    return {
      col: 4,
      type: 'select',
      name: 'templateForm',
      options: templates.map((item) => ({
        value: item.fk_id_template_forms,
        label: item.name,
      })),
      label: language.FORMS,
    };
  }

  if (cond == 'status_awaiting') {
    return {
      col: 4,
      name: 'status_awaiting',
      label: language.STATE,
      type: 'select',
      required: true,
      options: statusAwaiting.map((status) => ({
        value: status.id_status.toString(),
        label: status.status,
      })),
    };
  }

  if (cond == 'status_tickets') {
    return {
      col: 4,
      name: 'status_tickets',
      label: language.TICKETS_STATUS,
      type: 'select',
      required: true,
      options: statusTickets.map((st) => ({
        value: st.description,
        label: st.description
      })),
    }
  }
  if (['isnull', 'isnotnull'].includes(signal)) {
    return {
      col: 4,
      type: 'text',
      name: cond,
      label: signal,
      visible: false,
      value: ''
    }
  }
  if (['equals', 'notequal'].includes(signal)) {
  } else {
    return {
      col: 4,
      type: 'text',
      name: cond,
      label: '',
    };
  }
  if (cond == 'user_vip') {
    return {
      col: 4,
      name: 'user_vip',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: [
        { value: "true", label: language.YES },
        { value: "false", label: language.NO },
      ],
    };
  }
  if (cond == 'location_country') {
    return {
      col: 4,
      name: 'location_country',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: country,
    };
  }

  if (cond == 'location_city') {
    return {
      col: 4,
      name: 'location_city',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: cities,
    };
  }

  if (cond == 'location_uf') {
    return {
      col: 4,
      name: 'location_uf',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: states,
    };
  }

  if (cond == 'location_contact') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'location_contact',
      label: language.CONTACT,
      method: 'POST',
      route: `${APIEndpoints.USER}/filter`,
      fieldquery: 'search',
      textlabel: language.CONTACT,
      textinit: language.CONTACT,
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.USER}/active/count/0`,
      filterAll: { search_by_email: true },
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond == 'channel') {
    return {
      col: 4,
      name: 'channel',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: channel.map((ch) => ({
        value: ch.id_table,
        label: ch.description,
      })),
    };
  }

  if (cond == 'grouptechfirst' || cond == 'grouptech') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: cond,
      textlabel: language.TECH_GROUP,
      className: 'tech-group-ticket',
      required: true,
      method: 'POST',
      route: `${APIEndpoints.GROUP}/filter`,
      fieldquery: 'search',
      textinit: '',
      idinit: 0,
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      useinit: true,
      routeAll: `${APIEndpoints.GROUP}/all?`,
      detail: true,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.GROUP}/count`,
      textinit: language.TECH_GROUP,
      textlabel: language.TECH_GROUP,
    };
  }

  if (cond == 'user_create') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'user_create',
      label: language.REQUESTER,
      textlabel: language.REQUESTER,
      method: 'POST',
      route: `${APIEndpoints.USER}/filter`,
      fieldquery: 'search',
      textinit: language.REQUESTER,
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      methodGetAll: 'POST',
      detail: true,
      showEmail: true,
    };
  }

  if (cond == 'dt_create') {
    return {
      col: 4,
      type: 'text',
      name: 'dt_create',
      label: language.DATE,
    };
  }

  if (cond == 'count_reopened') {
    return {
      col: 4,
      type: 'text',
      name: 'count_reopened',
      label: language.TICKET_STATUS.REOPENED,
    };
  }

  if (cond == 'task') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'task',
      label: language.CATALOG_TASK,
      textlabel: '',
      method: 'POST',
      route: `${APIEndpoints.TASK}/list`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_catalog_task',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.TASK}/list?`,
      methodGetAll: 'POST',
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond == 'task_complexity') {
    return {
      col: 4,
      name: 'task_complexity',
      label: language.COMPLEXITY,
      type: 'select',
      required: true,
      options: [
        { value: 'low', label: language.LOW },
        { value: 'medium', label: language.MEDIUM },
        { value: 'high', label: language.HIGH },
      ],
    };
  }

  if (cond === 'locality') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'company',
      label: language.COMPANY,
      method: 'POST',
      route: `${APIEndpoints.COMPANY}/filter`,
      fieldquery: 'search',
      textlabel: language.COMPANY,
      textinit: language.COMPANY,
      fieldvaluedb: 'id_company',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.COMPANY}/all?`,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.COMPANY}/count`,
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond === 'tech') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'tech',
      label: language.USER,
      method: 'POST',
      route: `${APIEndpoints.USER}/filter`,
      fieldquery: 'search',
      textlabel: language.USER,
      textinit: language.USER,
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.USER}/active/count/0`,
      filterAll: { search_by_email: true },
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond == 'company' || cond == 'contract') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'company',
      label: language.COMPANY,
      method: 'POST',
      route: `${APIEndpoints.COMPANY}/filter`,
      fieldquery: 'search',
      textlabel: language.COMPANY,
      textinit: language.COMPANY,
      fieldvaluedb: 'id_company',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.COMPANY}/all?`,
      methodGetAll: 'POST',
      routeGetCount: `${APIEndpoints.COMPANY}/count`,
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond == 'category') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'category',
      label: language.CATEGORY,
      textlabel: '',
      method: 'POST',
      route: `${APIEndpoints.CATEGORY}/list`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_category',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.CATEGORY}/list?`,
      methodGetAll: 'POST',
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond == 'priority') {
    return {
      col: 4,
      name: 'priority',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: priority.map((pri) => ({
        value: pri.id_table,
        label: pri.description,
      })),
    };
  }

  if (cond == 'urgency') {
    return {
      col: 4,
      name: 'urgency',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: urgency.map((pri) => ({
        value: pri.id_table,
        label: pri.description,
      })),
    };
  }

  if (cond == 'impact') {
    return {
      col: 4,
      name: 'impact',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: impact.map((pri) => ({
        value: pri.id_table,
        label: pri.description,
      })),
    };
  }

  if (cond == 'catalog') {
    return {
      col: 4,
      type: 'autocomplete',
      disablePortal: true,
      name: 'catalog',
      label: language.CATALOG,
      textlabel: '',
      method: 'POST',
      route: `${APIEndpoints.CATALOG}/all`,
      fieldquery: 'search',
      textinit: '',
      fieldvaluedb: 'id_catalog_service',
      fieldlabeldb: 'name',
      idinit: 0,
      routeAll: `${APIEndpoints.CATALOG}/all?`,
      methodGetAll: 'POST',
      detail: true,
      visible: true,
      required: true
    };
  }

  if (cond == 'department_critical_unit') {
    return {
      col: 4,
      name: 'department_critical_unit',
      label: language.VALUE,
      type: 'select',
      required: true,
      options: [
        { value: "true", label: language.YES },
        { value: "false", label: language.NO },
      ],
    };
  }

  return {
    col: 4,
    type: 'text',
    name: cond,
    label: '',
  };
};

export default fields;
