import './index.css';

import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@material-ui/core';

import Button from '../../common/Button';
import Animate from '../../common/Animate';
import Progress from '../../common/Progress';
import CommonPage from '../../common/CommonPage';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';

import Form from './Form';
import Expanded from './Expanded';
import { translateFromPtBR } from '../../common/languages/Dictionary';

@observer
export default class Bsc extends CommonPage {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      companyId: null,
      expandIdbsc: [],
      value: {},
      edit: false,
      stepIndex: 0,
      fk_id_department: null,
    };
  }
  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.DEPARTMENT + '/' + this.store.getUserLoged().id,
      'departments'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.BSC + '/department_user',
      'department_user'
    );

    try {
      if (this.props.location.state.idCompany != undefined) {
        this.setState({ companyId: this.props.location.state.idCompany });
      }
    } catch (e) {}
    this.action.execute(
      'get',
      this.APIEndpoints.DASHBOARD + 'bi' + `?month=${'12'}&year=${'2019'}`,
      'dashboard'
    );
    this.action.execute('get', this.APIEndpoints.BSC, 'bsc').then((e) => {
      this.setState({ expandIdbsc: e.data.map((e) => e.id_bsc) });
    });
    this.action.execute(
      'get',
      this.APIEndpoints.BSC + '/getgoalcorp',
      'bsccorps'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.BSC + '/getgoalitem',
      'bscitem'
    );
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute(
      'get',
      this.APIEndpoints.BSC + '/getgoaltiall',
      'bsctialls'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PROJECT + '/getDetailAll',
      'statusProject'
    );
    this.action.execute('get', this.APIEndpoints.PROJECT, 'projectsbsc');
  }
  handleExpand = (id) => {
    let { expandIdbsc } = this.state;
    if (expandIdbsc.indexOf(id) != -1) {
      expandIdbsc = expandIdbsc.filter((e) => e != id);
    } else {
      expandIdbsc.push(id);
    }
    this.setState({ expandIdbsc });
  };
  deleteItem = (id_bsc_goal_ti) => {
    this.action.execute(
      'delete',
      this.APIEndpoints.BSC + '/removeitem/' + id_bsc_goal_ti,
      'bsctialls',
      {},
      'id_bsc_goal_ti'
    );
  };
  onFormSubmit = (v) => {
    let { value, edit, stepIndex } = this.state;
    if (stepIndex == 0) {
      v.fk_id_company = (
        this.store.bsc.find((e) => e.id_bsc == v.fk_id_bsc) || {}
      ).fk_id_company;

      var method = 'post';
      var route = '/creategoal';
      if (value.fk_id_bsc && value.id_bsc_goal) {
        method = 'put';
        route = '/updategoal';
        v = { ...v, id_bsc_goal: value.id_bsc_goal };
      }

      this.action
        .execute(
          method,
          this.APIEndpoints.BSC + route,
          'bsccorps',
          v,
          'id_bsc_goal'
        )
        .then((e) => {
          this.setState({
            value: {
              ...value,
              name: e.data.name,
              fk_id_bsc: v.fk_id_bsc,
              id_bsc_goal: e.data.id_bsc_goal,
            },
          });
          this.setStepIndex(1);
        });
    } else if (stepIndex == 1) {
      var method = 'post';
      var route = '/creategoalti';
      v.id_bsc_goal = value.id_bsc_goal;

      if (value.fk_id_bsc && value.id_bsc_goal && value.id_bsc_goal_ti) {
        method = 'put';
        route = '/updategoalti';
        v = { ...v, id_bsc_goal_ti: value.id_bsc_goal_ti };
      }

      this.action
        .execute(
          method,
          this.APIEndpoints.BSC + route,
          'bsctialls',
          v,
          'id_bsc_goal_ti'
        )
        .then((e) => {
          this.setState({
            value: {
              ...value,
              name_OTI: e.data.name,
              fk_id_bsc_goal: e.data.fk_id_bsc_goal,
              id_bsc_goal_ti: e.data.id_bsc_goal_ti,
              dt_meta: e.data.dt_meta,
              name_OTI: e.data.name,
            },
          });
          this.setStepIndex(2);
        });
    } else if (stepIndex == 2) {
      this.action
        .execute(
          'put',
          this.APIEndpoints.BSC + '/updategoalti',
          'bsctialls',
          v,
          'id_bsc_goal_ti'
        )
        .then((e) => {
          this.setState({
            value: {
              ...value,
              indicators: v.indicators,
              fk_id_project: parseInt(v.fk_id_project),
            },
          });
          this.setStepIndex(3);
        });
    } else {
      this.onCloseForm();
    }
  };
  onCloseForm = () => {
    this.setState({ stepIndex: 0, dialog: false, edit: false, value: {} });
  };
  setStepIndex = (step) => {
    this.setState({ stepIndex: step });
  };
  onEdit = (v) => {
    this.setState({ value: v, edit: true, dialog: true });
  };
  onAdd = (v) => {
    this.setState({ value: v, edit: true, dialog: true, stepIndex: 1 });
  };
  onView = (fk_id_project) => {
    this.redirect2('/dash/gp/' + fk_id_project);
  };
  render() {
    let {
      bsc = [],
      bsccorps = [],
      bscitem,
      language,
      companies,
      projectsbsc,
      statusProject = [],
      dashboard,
      departments,
      department_user,
    } = this.store;

    let { dialog, expandIdbsc, value, edit, stepIndex, fk_id_department } =
      this.state;

    if (!statusProject) {
      return null;
    }
    let bsctialls = this.store.bsctialls.filter((e) => {
      let errors = [];

      if (fk_id_department) {
        errors.push(e.fk_id_department == fk_id_department);
      }
      return errors.every(Boolean);
    });

    var percent;
    var dataBsc = bsc.filter(
      (arr, index, self) =>
        index === self.findIndex((t) => t.id_bsc === arr.id_bsc)
    );

    var data = dataBsc.map((_bsc) => {
      var item = {
        ..._bsc,
        name: translateFromPtBR(_bsc.name, language),
        bsccorps: bsccorps
          .filter((_bsccorps) => _bsccorps.fk_id_bsc == _bsc.id_bsc)
          .map((_bsccorps) => ({
            ..._bsccorps,
            bsctialls: bsctialls
              .filter(
                (_bsctialls) =>
                  _bsctialls.id_bsc == _bsc.id_bsc &&
                  _bsctialls.fk_id_bsc_goal == _bsccorps.id_bsc_goal
              )
              .map((e) => ({
                ...e,
                percent:
                  projectsbsc.filter(
                    (x) => x.id_project === parseInt(e.fk_id_project)
                  )[0] &&
                  parseInt(
                    projectsbsc.filter(
                      (x) => x.id_project === parseInt(e.fk_id_project)
                    )[0].percent
                  ),
              })),
          })),
      };

      percent = item.bsccorps.reduce((arr, e) => {
        e.bsctialls.forEach((e) => {
          arr.push(e.percent || 0);
        });
        return arr;
      }, []);
      item.percent = (
        percent.reduce((i, e) => e + i, 0) / percent.length
      ).toFixed(1);
      return item;
    });

    data = data.reduce((obj, e, i) => {
      obj.push({
        ConfigCell: {
          id: 'primary-tr-' + (i + 1),
        },
        '#': (
          <Button
            icon={
              expandIdbsc.includes(e.id_bsc)
                ? 'fas fa-chevron-down'
                : 'fas fa-chevron-right'
            }
            label={expandIdbsc.includes(e.id_bsc) ? 'Minimizar' : 'Expandir'}
            variant="circle"
            onClick={() => this.handleExpand(e.id_bsc)}
            outlined
            color="light"
            style={{
              border: 'none',
            }}
          />
        ),
        perspective: (
          <div>
            <i className="fas fa-cash-register mr-2" style={{ fontSize: 12 }} />
            {e.name}
          </div>
        ),
        total_corporate_objectives: e.bsccorps.length,
        Total_it_Objectives: e.bsccorps.reduce(
          (count, e) => count + e.bsctialls.length,
          0
        ),
        perspective_overall_status: e.percent && (
          <div className="d-flex align-items-center" style={{ width: 200 }}>
            <Progress
              current={e.percent || 0}
              background="var(--danger)"
              hiddenText
              style={{ width: '100%', height: 7 }}
            />
            <span className="ml-2">{e.percent || 0}%</span>
          </div>
        ),
      });
      if (expandIdbsc.includes(e.id_bsc)) {
        obj.push({
          ConfigCell: {
            cols: {
              col_0: { colSpan: 5, style: { paddingLeft: 0, paddingRight: 0 } },
            },
          },
          '#': (
            <Expanded
              department_user={department_user}
              projects={projectsbsc}
              onView={this.onView}
              bscItem={e}
              onEdit={this.onEdit}
              deleteItem={this.deleteItem}
              onAdd={this.onAdd}
							language={language}
            />
          ),
        });
      }

      return obj;
    }, []);

    let i = departments.map((e) => ({
      value: e.id_department,
      label: e.name,
    }));

    i.push({ value: 0, label: 'TODOS' });

    return (
      <CommonPage loading={false}>
        <Animate>
          <PageLayout
            icon="bscIcon"
            title={language.BALANCE_SCORE_CARD}
            subTitle={language.CIO_COCKPIT + '/'}
            rightElements={[
              this.store.getUserLoged().role == 'client' ? (
                <div
                  style={{ width: 300, zoom: 0.8, marginTop: '1rem' }}
                  zoom={0.8}
                >
                  <CommonForm
                    fields={[
                      {
                        type: 'select',
                        name: 'department',
                        label: language.DEPARTMENT,
                        options: i,
                      },
                    ]}
                    onChangeField={(f, v) => {
                      this.setState({ fk_id_department: v });
                    }}
                  />
                </div>
              ) : (
                <div />
              ),
            ]}
          >
            <Form
              open={dialog}
              bsc={dataBsc}
              onFormSubmit={this.onFormSubmit}
              onClose={() => this.onCloseForm()}
              values={value}
              edit={edit}
              role={this.store.getUserLoged().role}
              stepIndex={stepIndex}
              setStepIndex={this.setStepIndex}
              bsctialls={bsctialls}
              projects={projectsbsc}
              departments={departments}
							language={language}
            />
            <div id="bsc">
              <CommonTable
                AppBarProps={{
                  dark: true,
                }}
                className="bg-white"
                searchColumn
                beforeBar={
                  <Button
                    icon="fas fa-plus"
                    label={language.ADD}
                    variant="circle"
                    onClick={() => {
                      this.setState({ dialog: true });
                    }}
                    color="warning"
                    className="mr-3"
                  />
                }
                title={
                  <Button
                    icon="fas fa-print"
                    label={language.PRINT}
                    color="warning"
                    className="ml-1"
                    onClick={() => window.print()}
                  />
                }
                col={[
                  {
                    key: '#',
                    label: '',
                    thConfig: true,
                    style: { width: '1%' },
                  },
                  {
                    key: 'perspective',
                    label: language.BSC_PERS,
                    thConfig: true,
                    style: { width: '24%' },
                  },
                  {
                    key: 'total_corporate_objectives',
                    label: language.TOTAL_CORPORATE_OBJECTIVES,
                    thConfig: true,
                    style: { width: '12.5%' },
                  },
                  {
                    key: 'Total_it_Objectives',
                    label: `${language.TOTAL_GOALS} ${department_user.name ? `${language.OF} ${department_user.name}` : language.OF_THE_IT}`,
                    thConfig: true,
                    style: { width: '12.5%' },
                  },
                  {
                    key: 'perspective_overall_status',
                    label: language.PERSPECTIVE_OVERALL_STATUS,
                    thConfig: true,
                    style: { width: '50%' },
                  },
                ]}
                data={data}
              />
            </div>
          </PageLayout>
        </Animate>
      </CommonPage>
    );
  }
}
