import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { Tooltip } from 'react-tippy';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import { testRoute } from '@common/Func';

@observer
export default class DepartmentForm extends Component {
  render() {
    let {
      language,
      handlePage,
      values,
      store,
      onFormSubmit,
      onChangeField,
      departments,
      department,
      route,
      APIEndpoints
    } = this.props;
    let departmentOptions = department
      .filter((d) => !d.fk_id_department)
      .map((depart) => ({
        value: parseInt(depart.id_department),
        label: depart.name,
      }));

    window._departmentOptions = departmentOptions;
    window._values = values;

    let fields = [
      {
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
        col: 6,
      },
      {
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        multiLine: true,
        required: true,
        col: 6,
      },
      {
        name: 'cost_center',
        label: language.COST_CENTER,
        type: 'text',
        multiLine: true,
        col: 6,
      },
      {
        name: 'fk_id_department',
        type: 'autocomplete',
        label: language.DEPARTMENT,
        textlabel: language.DEPARTMENT,
        method: 'POST',
        route: `${APIEndpoints.DEPARTMENT}/filter/`,
        routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
        filterAll: { subdepartment: false },
        fieldquery: 'search',
        textinit: values && values.name_sub ? values.name_sub : '',
        fieldvaluedb: 'id_department',
        fieldlabeldb: 'name',
        idinit: values && values.fk_id_department ? values.fk_id_department : 0,
        routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
        methodGetAll: 'POST',
        required: true,
        disablePortal: true,
        visible: route != 'department',
        col: 6,
      },
      {
        name: 'critical_unit',
        label: language.CRITICAL_UNIT,
        type: 'switch',
        col: 6,
      },
    ];

    return (
      <CommonForm
        values={values}
        fields={fields}
        onChangeField={this.onChangeField}
        onSubmit={onFormSubmit}
      />
    );
  }
}
