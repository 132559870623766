import React, { useState, memo, useEffect } from 'react';
import classnames from 'classnames';
import ReactQuill from 'react-quill';

let delayTimer;
function TagEditor(props) {
  const [isTagSelectorVisible, setTagSelectorVisible] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const tagStyle = {
    backgroundColor: '#f0f0f0',
    padding: '5px',
    border: '1px solid #ccc',
    margin: '5px',
    cursor: 'pointer',
    borderRadius: '10px',
    fontSize: '12px',
  };

  const {
    tags,
    f,
    statusError,
    _value,
    _handleValue,
    errors,
    onKeyDown,
    handleEmail_Chat
  } = props;

  const handleEditorChange = (value) => {
    setEditorContent(value);

    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      _handleValue(value, f.name);
    }, 400);
  };

  const handleKeyPress = (event) => {
    if (event.key === '$') {
      setTagSelectorVisible(true);
    }
    else {
      if (isTagSelectorVisible) setTagSelectorVisible(false);
    }
  };

  const handleTagClick = (tag) => {
    const indexOf = editorContent.lastIndexOf('</p>');
    if (indexOf !== -1) {
        var content = editorContent.substring(0, indexOf) + tag + editorContent.substring(indexOf);
        setEditorContent(content);
    }

    setSelectedTag(tag);
    setTagSelectorVisible(false);
  };

  useEffect(() => {
    setEditorContent(_value);
  }, [_value]);

  return (
    <div className="editor">
      {!!f.label && (
        <span
          className={classnames('font label-text-editor', {
            error: statusError[errors[f.name]],
          })}
        >
          {f.label + (f.required ? ' *' : '')}
        </span>
      )}

      <ReactQuill
        className={classnames(
          'commonForm-react-quill',
          {
            error: statusError[errors[f.name]],
            'not-label': !f.label,
          },
          f.className
        )}
        style={{
          maxHeight: f.scroll ? '200px' : '100%',
          overflowY: f.scroll ? 'scroll' : 'auto',
        }}
        value={editorContent || ""}
        onChange={handleEditorChange}
        onKeyPress={handleKeyPress}
        placeholder="Digite seu conteúdo aqui..."
        readOnly={f.disabled}
        modules={{
          toolbar: f.disabled
            ? null
            : [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                [{ align: [] }],
                ['link', 'image'],
                ['clean'],
                [{ color: [] }, { background: [] }],
              ],
        }}
      />
      {isTagSelectorVisible && (
        <div className="tag-selector">
          {Object.keys(tags).map((tag) => (
            <button
              key={tag}
              className="tag"
              style={tagStyle}
              onClick={() => handleTagClick(tag)}
            >
              {tags[tag]}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default TagEditor;
