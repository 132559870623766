import '../../../public/css/pages.css';

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';

import ExtensionList from './ExtensionsList';
import ExtensionForm from './ExtensionsForm';

@observer
export default class ExtensionContainer extends CommonPage {
    constructor() {
        super();
        this.state = {
            values: '',
            page: 'list',
            extensions: [],
            parameters : []
        }

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentWillMount() {
        // this.action.execute('get', this.APIEndpoints.ANSWER, 'answer');

        this.action.execute('get', this.APIRoot + '/api/generalparameters')
            .then((e) => {
                const extensionsFile = e.data.extension_file.split(',')
                this.setState({ extensions: extensionsFile })
                this.setState({parameters : e.data})
               })
            .catch((error) => {
                console.log(error)
            });
    }

    onFormSubmit(values) {

        
        if (this.state.values != undefined){
        const index = this.state.extensions.indexOf(this.state.values)
        this.state.extensions[index] = values.extension
        this.setState({extensions : this.state.extensions})
        this.setState({values : ''})
        const extensions = this.state.extensions.join(',')

        this.state.parameters.EXTENSION_FILE = extensions
     
    
    }else{
        const extensions = this.state.extensions.join(',') + "," + values.extension
        this.setState({extensions: extensions.split(',')})        
        this.state.parameters.EXTENSION_FILE = extensions

    }

        this.action.execute('put', this.APIRoot+'/api/generalparameters', 'general_parameters', this.state.parameters);
        this.handlePage('list');

    }

    onDelete() {
        const index = this.state.extensions.indexOf(this.state.values)
        this.state.extensions.splice(index, 1)
        const extensions = this.state.extensions.join(',')

        this.setState({extensions : this.state.extensions})
        this.state.parameters.EXTENSION_FILE = extensions
        this.action.execute('put', this.APIRoot+'/api/generalparameters', 'general_parameters', this.state.parameters);


        this.handlePage('list');

    }

    handlePage(page, edit) {
        this.setState({ page: page, values: edit });
    }

    render() {
        let { page, extensions } = this.state;

        let { language } = this.store;
        let { configurationProps = {} } = this.props;
        return (
            <Fragment>

                <ExtensionList
                    redirect={this.redirect2}
                    language={language}
                    handlePage={this.handlePage}
                    store={this.state}
                    configurationProps={configurationProps}
                />

                {page === 'form' ? (
                    <ExtensionForm
                        {...this.state.values}
                        language={language}
                        handlePage={this.handlePage}
                        onFormSubmit={this.onFormSubmit}
                        values = {this.state.values}
                    />
                ) : null}
                {page === 'remove' ? (
                    <CommonDelete
                        language={language}
                        closeModal={() => this.handlePage('list')}
                        onDelete={this.onDelete}
                        message={this.state.values}
                    />
                ) : null


                }
            </Fragment>
        );
    }
}
