import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import './style.css';

export default class ListColumn extends Component {
	render() {
		let { items, col } = this.props;
		return (
			<div className='ListColumn'>
				<Grid container spacing={3}>
					{items.map((e, i) => (
						[undefined, true].includes(e.visible) ? (
							<Grid item xs={e.col || col || 12} className="slp" key={i}>
								<div className="content-item content-itemS">
									<p {...(e.contentProps || {})}>
										{e.oneContent ? (
											e.oneContent
										) : (
											<Fragment>
												{(typeof e.label) == 'string' ? (e.tagLabel == false ?
													(e.label ? e.label + ': ' : null) :
													<span {...(e.labelProps || {})}>{e.label ? e.label + ': ' : null}</span>
												) : e.label}

												{e.tagValue == false ?
													e.value :
													<span {...(e.valueProps || {})}>{e.value}</span>
												}

											</Fragment>
										)}
									</p>
								</div>
							</Grid>
						) : null
					))}
				</Grid>
			</div>
		)
	}
}