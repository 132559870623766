import '../../../public/css/pages.css';
import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import CommonForm from '../../common/CommonForm2';
import MailList from './MailList';
import MailForm from './MailForm';
import MailConfig from './MailConfig';
import Animate from '@common/Animate';
import Dialog from '@common/Dialog';
@observer
export default class MailContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSubmitTesteMail = this.onSubmitTesteMail.bind(this);
  }

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.MAIL, 'mails');

    this.action.execute(
      'get',
      this.APIEndpoints.MAIL + '/getContracts',
      'mailContract'
    );
  }

  onFormSubmit(values) {
    let { spinner, toggleAlert, language } = this.store;
    spinner = true;
    if (values.fk_id_contract != undefined) {
      this.action
        .execute(
          'post',
          this.APIEndpoints.MAIL,
          'mailContract',
          values,
          'id_mail'
        )
        .then(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ALERT_INSERT_SUCCESS, 'success');
        })
        .catch(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ERROR_REQUEST, 'error');
        });
    } else {
      let method = values.hasOwnProperty('id_mail') ? 'put' : 'post';
      let url = values.hasOwnProperty('id_mail')
        ? this.APIEndpoints.MAIL + '/' + values.id_mail
        : this.APIEndpoints.MAIL;
      this.action
        .execute(method, url, 'mails', values, 'id_mail')
        .then(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(
            true,
            method === 'post'
              ? language.ALERT_INSERT_SUCCESS
              : language.ALERT_UPDATE_SUCCESS,
            'success'
          );
        })
        .catch(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ERROR_REQUEST, 'error');
        });
    }
  }

  onDelete() {
    let { values } = this.state;
    let { spinner, toggleAlert, language } = this.store;
    spinner = true;
    if (values.id_rel_mail_contract != undefined) {
      this.action
        .execute(
          'post',
          this.APIEndpoints.MAIL + '/' + 'removerelContract',
          'mailContract',
          values,
          'id_mail'
        )
        .then(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
        })
        .catch(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ERROR_REQUEST, 'error');
        });
    } else {
      this.action
        .execute(
          'delete',
          this.APIEndpoints.MAIL + '/' + values.id_mail,
          'mails',
          null,
          'id_mail'
        )
        .then(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ALERT_DELETE_SUCCESS, 'success');
        })
        .catch(() => {
          this.handlePage('list');
          spinner = false;
          toggleAlert(true, language.ERROR_REQUEST, 'error');
        });
    }
  }

  onSubmitTesteMail(values) {
    let { spinner, toggleAlert, language } = this.store;
    spinner = true;
    this.action
      .execute('post', this.APIEndpoints.MAIL + '/testmail', '', values)
      .then(() => {
        this.handlePage('list');
        spinner = false;
        toggleAlert(true, language.CHECK_YOUR_EMAIL_BOX, 'success');
      })
      .catch(() => {
        this.handlePage('list');
        spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values } = this.state;
    let { language, mailContract } = this.store;
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <Animate>
          <MailList
            redirect={this.redirect2}
            language={language}
            handlePage={this.handlePage}
            store={this.store}
            configurationProps={configurationProps}
          />
        </Animate>

        {page === 'form' && (
          <MailForm
            values={values}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        )}

        {page === 'remove' && (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={values.mail || values.contrato}
          />
        )}

        {page === 'config' && (
          <MailConfig
            mailContract={mailContract}
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        )}

        <Dialog
          monitor_scroll
          title={language.TYPE_YOUR_EMAIL}
          modal={false}
          open={page === 'testMail'}
        >
          <CommonForm
            fields={[
              {
                col: 10,
                name: 'email',
                label: language.MAIL_MAIL,
                type: 'text',
                required: true,
              },
            ]}
            onSubmit={this.onSubmitTesteMail}
            button={{ label: language.SAVE }}
          />
        </Dialog>
      </Fragment>
    );
  }
}
