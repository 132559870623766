import React, {useEffect, useState} from 'react';
import {DinamicGraphArea} from './Graphs';
import ReactHighcharts from 'react-highcharts';

export default function({ netStatsTx, netStats }){
	const [dataStats, setDataStats] = useState([0, 0, 0 ,0 ,0 ,0 ,0]);

	useEffect(() => {
		function load() {
			return new Promise((resolve) => {
				if (netStats) {
					const newDataStats = netStats[0].in.map(value => Number((value / 1024).toFixed(3)))
					resolve(newDataStats)
				}
			})
		}

		load().then(x => setDataStats(x));
	}, [netStats])
	return(
		<div className='d-flex align-items-center InfoMinimized'>
			<div style={{width:'60%'}}>
				<ReactHighcharts 
					config={DinamicGraphArea({
						
						color:'#019FC2',
						height:50,
						enabledLabels:false,
						name,
						data:dataStats
					})} 
				/>
			</div>
			<div className='legend-info mb-3' style={{width:'40%'}}>
				<i className="fas fa-arrow-up"/>
				<h2>{Number((netStatsTx / 1024).toFixed(2))}</h2>
				<span> kb/s </span>
			</div>
		</div>
	)
}