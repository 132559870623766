import React, { Component } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';

export default class TechEvaluationForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;

    let fields = [
      {
        col: 10,
        name: 'status',
        label: 'Status',
        type: 'text',
        required: true,
        placeholder: 'Escreva o nome do status',
      },
      {
        col: 10,
        name: 'description',
        label: 'Descrição',
        type: 'text',
        required: false,
        multiLine: true,
        placeholder: 'Escreva a descrição do status',
      },
    ];

    let button = { label: language.SAVE };

    return (
      <Dialog
        maxWidth="sm"
        monitor_scroll
        title={values ? language.EDIT : 'Adicionar'}
        modal={false}
        open={true}
        onClose={() => handlePage('list')}
      >
        <CommonForm
          values={values}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
        />
      </Dialog>
    );
  }
}
