const ContainerChart = {
  projectsCreated({DOING, ALERT, LATE, categories, language}){
    return {
      chart:{
        height:127
      },
      title: null,

      subtitle: null,

      yAxis: {
          title: {
            text: `(${language.QUANTITY})`,
            style:{
              fontSize:9,
              color:'var(--primary)'
            }
          },
          labels:{
            style:{
              fontSize:10,
              color:'var(--primary)'
            }
          }
      },

      xAxis: {
          accessibility: {
              rangeDescription: 'Range: 2010 to 2017'
          },
          title:{
            text: `(${language.DAYS_IN_MONTH})`,
            style:{
              fontSize:9,
              color:'var(--primary)'
            }
          },
          labels:{
            style:{
              fontSize:10,
              color:'var(--primary)'
            }
          },
          categories
      },

      plotOptions: {
          series: {
              label: {
                  connectorAllowed: false,
              },
              
          }
      },
      credits:{enabled:false},
      legend:{enabled:false},

      series: [{
          name: language.RUNNING,
          color:'var(--success)',
          data: DOING
      }, {
          name: language.ON_ALERT,
          color:'var(--warning)',
          data: ALERT
      }, {
          name: language.ON_LATE,
          color:'var(--danger)',
          data: LATE
      }],
    }
  },
  ticket(dias, openv_, PROGRESS_, RESOLVED_, REOPENED_, CLOSED_) {
    return {
      chart: {
        type: 'area',
        height: 153
      },
      credits: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        allowDecimals: false,
        labels: {
          // categories:Array.from({length:31-1+1},(v,k)=>k+1),
          style:{
            color:'#629DAA'
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function() {
            return this.value;
          },
          style:{
            color:'#629DAA'
          }
        },
        tickWidth: 1
      },
      tooltip: {
        // pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
      },
      plotOptions: {
        area: {
          pointStart: 1,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      series: [
        {
          name: 'Open',
          zIndex: 2,
          color: 'rgba(70, 204, 229, .7)', // color success
          data: Array.from({length: 31}, () => Math.floor(Math.random() * 40))
        },
        {
          name: 'In Progress',
          zIndex: 2,
          color: 'rgba(246, 208, 104,.7)',
          data: Array.from({length: 31}, () => Math.floor(Math.random() * 40))
        },
        {
          name: 'Resolved',
          zIndex: 2,
          color: 'rgba(95, 161, 162,.7)',
          data: Array.from({length: 31}, () => Math.floor(Math.random() * 40))
        },
        {
          name: 'Reopened',
          zIndex: 2,
          color: 'rgba(104, 159, 246,.7)',
          data: Array.from({length: 31}, () => Math.floor(Math.random() * 40))
        },
        {
          name: 'Closed',
          zIndex: 1,
          color: 'rgba(197, 124, 246,.7)',
          data: Array.from({length: 31}, () => Math.floor(Math.random() * 40))
        }
      ]
    };
  },
}

export default ContainerChart;