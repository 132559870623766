import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

// ----------------------------------------------------------------------

import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import Store from '@data/Store';

import { getChannelsService, getChannelByIdService } from '../Channel.services';
import { columns } from './channel.columns';

// ----------------------------------------------------------------------

export const ChannelList = ({
  page,
  setChannelSelected,
  setEditChannel,
  setPage,
}) => {
  const [channels, setChannels] = useState([]);
  const { language } = Store;

  const handleAddChannel = () => {
    setPage('add');
  };

  const handleEditChannel = ({ id }) => {
    const getChannel = async () => {
      const result = await getChannelByIdService({ id }).then((result) => {
        setEditChannel(result);
        setPage('edit');
      });
    };

    getChannel();
  };

  const handleDelete = ({ channelId, channelName }) => {
    setPage('delete');
    setChannelSelected({ channelId, channelName });
  };

  useEffect(() => {
    const getChannels = async () => {
      const result = await getChannelsService();
      const normalizedResult = result.map((channel) => ({
        ...channel,
        is_attachment_required: channel.is_attachment_required
          ? language.REQUIRED
          : language.OPTIONAL,
        action: (
          <Chip
            className="mx-2 muit-chip"
            label={
              <>
                <Button
                  variant="circle"
                  icon="fas fa-edit"
                  label={language.EDIT}
                  color="default"
                  style={{ fontSize: 12 }}
                  size={0.7}
                  className="mr-2"
                  onClick={() => handleEditChannel({ id: channel.id_channel })}
                />
                <Button
                  variant="circle"
                  icon="far fa-trash-alt"
                  label={language.DELETE}
                  color="danger"
                  style={{ fontSize: 13 }}
                  size={0.7}
                  onClick={() =>
                    handleDelete({
                      channelId: channel.id_channel,
                      channelName: channel.name,
                    })
                  }
                />
              </>
            }
          />
        ),
      }));

      setChannels(normalizedResult);
    };

    page === null && getChannels();
  }, [page]);

  return (
    <CommonTable
      paginationTop={false}
      beforeBar={
        <RaisedButton
          circleButton
          icon="fas fa-plus"
          style={{ zoom: 0.8 }}
          zoom={0.8}
          color="primary"
          className="mr-3"
          label={`${language.ADD} ${language.CHANNEL}`}
          onClick={handleAddChannel}
        />
      }
      col={columns}
      data={channels}
      index="channel-list"
      rowsPerPageOptions={[100, 250]}
      searchColumn
      orderColumn
    />
  );
};
