import React, { Fragment } from 'react';
import './index.css';
import Loading from '@common/Loading';
import ButtonControlYear from '../block_5/ButtonControlYear';
/*---------------------------------icons-------------------------------------*/
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const Loadings = ({ value }) => (
  <Loading
    msg={false}
    loading={false}
    size={15}
    color="var(--default)"
    className="py-0"
  >
    {value || '-'}
  </Loading>
);
export default function ({
  data = [],
  year = null,
  gridColumn,
  handleSelectYear,
  language,
}) {
  let totalData =
    data.length > 0
      ? data.reduce(
          (obj, e) => {
            obj.solicitation += e.solicitation;
            obj.incident += e.incident;
            obj.problem += e.problem;
            return obj;
          },
          { solicitation: null, incident: null, problem: null }
        )
      : [];
  return (
    <div
      className="grid-container GridStatusListB4B6"
      style={{ '--columns': gridColumn }}
    >
      <div className="grid-item label-header">
        <ButtonControlYear
          value={year}
          onChange={handleSelectYear}
          disabled={!year}
        />
      </div>
      <div className="grid-item solicitation-header">
        <div className="content-indicator-icon">
          <PersonAddIcon className="icon mb-1" />
          <div />
        </div>
      </div>
      <div className="grid-item incident-header">
        <div className="content-indicator-icon">
          <NotificationsActiveIcon className="icon mb-1" />
          <div />
        </div>
      </div>
      <div className="grid-item problem-header">
        <div className="content-indicator-icon">
          <ReportProblemIcon className="icon mb-1" />
          <div />
        </div>
      </div>

      {data.length > 0
        ? data.map((e) => (
            <Fragment>
              <div className="grid-item label">{e.label}</div>
              <div className="grid-item solicitation">
                <Loadings value={e.solicitation} />
              </div>
              <div className="grid-item incident">
                <Loadings value={e.incident} />
              </div>
              <div className="grid-item problem">
                <Loadings value={e.problem} />
              </div>
            </Fragment>
          ))
        : ''}

      <div className="grid-item totais">{language.TOTAL}</div>
      <div className="grid-item solicitation">{totalData.solicitation}</div>
      <div className="grid-item incident">{totalData.incident}</div>
      <div className="grid-item problem">{totalData.problem}</div>
    </div>
  );
}
