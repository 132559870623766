import RaisedButton from '../../common/RaisedButton';
import React, { Fragment } from 'react';
import { Chip } from '@material-ui/core';
import { observer } from 'mobx-react';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
@observer
export default class MailList extends CommonPage {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { language, handlePage } = this.props;
    let col = [
      {
        key: 'id_mail',
        label: language.ID,
        thConfig: true,
        style: { width: 1, textAlign: 'center', paddingRight: 0 },
      },
      {
        key: 'mail',
        label: language.MAIL_MAIL,
        thConfig: true,
        style: { width: 120 },
      },
      { key: 'pop', label: language.MAIL_POP, style: { textAlign: 'center' } },
      {
        key: 'pop_port',
        label: language.POP_PORT,
        style: {
          textAlign: 'center',
          paddingRight: 0,
          paddingLeft: 0,
          width: 50,
        },
        thConfig: true,
      },
      {
        key: 'smtp',
        label: language.MAIL_SMTP,
        style: {
          textAlign: 'center',
          width: 200,
          paddingRight: 0,
          paddingLeft: 0,
          width: 50,
        },
        thConfig: true,
      },
      {
        key: 'smtp_port',
        label: language.SMTP_PORT,
        style: {
          textAlign: 'center',
          paddingRight: 0,
          paddingLeft: 0,
          width: 50,
        },
        thConfig: true,
      },
      {
        key: 'tls',
        label: language.MAIL_TLS,
        style: { width: 100 },
        thConfig: true,
      },
      {
        key: 'action',
        label: language.ACTION,
        thConfig: true,
        style: { width: 100, textAlign: 'center' },
      },
    ];
    let data = (this.props.store.mails || []).map((obj) => {
      return {
        id_mail: obj.id_mail,
        mail: obj.mail,
        pop: obj.pop,
        pop_port: obj.pop_port,
        smtp: obj.smtp,
        smtp_port: obj.smtp_port,
        tls: obj.tls === 'S' ? language.YES : language.NO,

        action: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <RaisedButton
                    circleButton
                    icon="fas fa-plus"
                    label={language.ADD}
                    color="primaryGradient"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('config', obj)}
                  />
                  <RaisedButton
                    circleButton
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    className="mx-2"
                    onClick={() => handlePage('form', obj)}
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                    className="mr-2"
                  />

                  <RaisedButton
                    circleButton
                    icon="far fa-envelope"
                    label={language.PERFORM_TEST_REGISTERED_EMAIL}
                    color="default"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('testMail', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          index="MailList"
          data={data}
          orderColumn
          width="100%"
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
