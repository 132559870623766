import { Fragment } from 'react';
import { Chip } from '@material-ui/core';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';

export default function ChangeRiskList({ dataTable, language, handlePage }) {
  const col = [
    {
      key: 'order',
      label: language.ORDER,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'weight',
      label: language.WEIGHT,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'type',
      label: language.TYPE,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'required',
      label: language.REQUIRED,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 10, overflow: 'auto', textAlign: 'center' },
    },
  ];
  
  const data = dataTable.map((ccr) => ({
    order: ccr.ccr_order,
    name: ccr.ccr_name,
    weight: ccr.ccr_weight,
    type: ccr.type,
    required: ccr.ccr_required ? language.YES : language.NOT,
    action: (
      <Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <RaisedButton
                circleButton
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 12, width: 21, height: 21 }}
                className="mr-2"
                onClick={() => handlePage('edit', ccr)}
              />
              <RaisedButton
                circleButton
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 13, width: 21, height: 21 }}
                onClick={() => handlePage('delete', ccr)}
              />
            </div>
          }
        />
      </Fragment>
    ),
  }));

  return (
    <CommonTable
      data={data}
      columns={col}
      language={language}
      searchColumn
      orderColumn
      beforeBar={[
        <RaisedButton
          style={{ zoom: 0.8 }}
          zoom={0.8}
          className="mr-3"
          color="primary"
          key="02"
          circleButton
          icon="fas fa-plus"
          label={language.ADD}
          primary={true}
          onClick={() => {
            handlePage('create')
          }}
        />,
      ]}
    />
  );
}
