import { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { observer } from 'mobx-react';
import Constants from '@data/Constants';
import CommonForm from '../../common/CommonForm2';
import { MaskNumber } from '@common/Mask';
import Store from '@data/Store';

import { translateFromPtBR } from '@common/languages/Dictionary';

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: option[attributeName],
  }));

  return normalizedOptions;
};

const LocationForm = ({
  values,
  cities,
  states,
  language,
  onChangeField,
  onFormSubmit,
  action,
  APIEndpoints,
  countries,
}) => {
  const [states_, setStates_] = useState([]);
  const [cities_, setCities_] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  useEffect(() => {
    if (values) {
      action
        .execute(
          'get',
          APIEndpoints.COUNTRY + '/' + values.id_country,
          'states'
        )
        .then((res) => setStates_(res.data));
      action
        .execute('get', APIEndpoints.STATE + '/' + values.id_state, 'cities')
        .then((res) => setCities_(res.data));
    }

    action
      .execute('get', Constants.APIEndpoints.TIMEZONE, '')
      .then((response) => {
        const { data } = response;
        setTimezoneOptions(formatSelectOptions(data, 'name', 'id_timezone'));
      });
  }, []);

  useEffect(() => {
    setStates_(states);
  }, [states]);

  useEffect(() => {
    setCities_(cities);
  }, [cities]);

  const typeOptions = [
    { value: 1, label: language.FARM },
    { value: 2, label: language.OFFICE },
    { value: 3, label: language.FACTORY },
  ];

  const statusOptions = [
    { value: true, label: language.ACTIVE },
    { value: false, label: language.INACTIVE },
  ];

  const countryOptions = countries.map((obj) => {
    return { value: obj.id_country, label: translateFromPtBR(obj.name, Store.language) };
  });

  const stateOptions = states_.map((obj) => {
    return { value: obj.id_state, label: obj.name };
  });

  const cityOptions = cities_.map((obj) => {
    return { value: obj.id_city, label: obj.name };
  });

  const fields = [
    {
      col: 3,
      name: 'type',
      label: language.TYPE,
      type: 'select',
      options: typeOptions,
      required: true,
    },
    {
      col: 3,
      name: 'name',
      label: language.NAME,
      type: 'text',
      startIcon: 'fas fa-signature',
      required: true,
    },
    {
      col: 3,
      name: 'zipcode',
      label: language.POSTAL_CODE,
      type: 'text',
      startIcon: 'fas fa-map-marked-alt',
      InputProps: {
        id: {
          format: '#####-###',
          mask: '_',
          placeholder: '00000-000',
          change(values) {
            return values.formattedValue;
          },
        },
        inputComponent: MaskNumber,
      },
    },
    {
      col: 3,
      name: 'id_timezone',
      label: 'Timezone',
      type: 'select',
      options: timezoneOptions,
    },
    {
      col: 3,
      name: 'id_country',
      label: language.COUNTRY,
      type: 'select',
      startIcon: 'fas fa-flag',
      options: countryOptions,
      required: true,
    },
    {
      col: 3,
      name: 'id_state',
      startIcon: 'far fa-flag',
      label: language.DISTRICT,
      type: 'select',
      options: stateOptions,
      required: true,
    },
    {
      col: 3,
      name: 'id_city',
      startIcon: 'fas fa-city',
      label: language.CITY,
      type: 'select',
      options: cityOptions,
      required: true,
    },
    {
      col: 3,
      name: 'additional',
      label: language.ADDITIONALINFO,
      type: 'text',
      required: false,
    },
    {
      col: 3,
      name: 'street',
      label: language.ADDRESS,
      type: 'text',
      startIcon: 'fas fa-map-marker-alt',
      required: false,
    },

    {
      col: 3,
      name: 'status',
      label: language.STATUS,
      type: 'select',
      options: statusOptions,
    },
    {
      col: 3,
      name: 'description',
      label: language.DESCRIPTION,
      type: 'text',
      required: true,
    },
    {
      col: 3,
      type: 'autocomplete',
      name: 'fk_id_user',
      label: language.CONTACT,
      textlabel: language.CONTACT,
      method: 'POST',
      filter: '',
      route: `${APIEndpoints.USER}/filter`,
      routeGetCount: `${APIEndpoints.USER}/active/count/0`,

      fieldquery: 'search',

      textinit: values?.contact_name,
      idinit: values?.fk_id_user,

      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      filterAll: {
        //all_fk_id_company: 0,
        search_by_email: true,
      },
      methodGetAll: 'POST',
      detail: true,
      //visible: values.ticketArray.length > 1 ? false : true,
      //required: values.ticketArray.length > 1 ? false : true,
      //cleanValue: cleanValueSolicitant,
      showEmail: true,

    },
    {
      col: 3,
      name: 'criticality',
      label: language.CRITICALITY,
      type: 'text',
      required: false,
    },
    {
      col: 3,
      name: 'sla_type',
      label: language.SLA_TYPE,
      type: 'text',
      required: false,
    },
  ];

  return (
    <Card className="content" style={{ overflow: 'visible' }}>
      <CommonForm
        values={values}
        fields={fields}
        onChangeField={onChangeField}
        onSubmit={onFormSubmit}
      />
    </Card>
  );
};

export default LocationForm;
