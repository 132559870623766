import React, { useEffect, useState } from 'react';
import { DialogContent } from '@material-ui/core';

import Dialog from '@common/Dialog';
import Store from '../../data/Store';
import Constants from '../../data/Constants';
import CommonForm from '../../common/CommonForm2';

export default function DeviceContactSearch({ isOpen, onClose, onConfirm }) {

    const { language } = Store;
    const handleSubmit = (values) =>  onConfirm && onConfirm(values);

    const formFields = [
        {
            col: 12,
            type: 'autocomplete',
            name: 'id_user',
            isMulti: true,
            required: true,
            showSearchButtom: false,
            label: language.PEOPLE,
            textlabel: language.PEOPLE,
            className: 'tech-group-ticket',
            method: 'POST',
            route: `${Constants.APIEndpoints.USER}/filter`,
            routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
            filterAll: {},
            fieldquery: 'search',
            fieldvaluedb: 'id_user',
            fieldlabeldb: 'name',
            routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
            methodGetAll: 'POST',
        },
    ]

    return (

        <Dialog
            width={500}
            fullWidth={true}
            open={isOpen}
            onClose={onClose}
            //onConfirm={handleSubmit}
            title={language.ADD_USER}
        >
            <DialogContent>
                <div className='tw-w-full'>
                    <CommonForm

                        values={[]}
                        fields={formFields}
                        onSubmit={handleSubmit}
                        button={{
                            md: 2,
                            offset: { md: 10 },
                            label: language.ADD,
                            primary: true,
                            icon: 'fas fa-save',
                            style: { float: 'right', fontSize: 15 }
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}
