import React, { useEffect, useState, useCallback, memo } from 'react';
import moment from 'moment';
import './index.css';
/*------------------------------common----------------------------------*/
import { flexBasis } from '@common/Func';
/*------------------------------material-ui----------------------------------*/
import { Grid } from '@material-ui/core';
/*------------------------------pages----------------------------------*/
import Company from './Company';
import Call_x_Tech from './Call_x_Tech';
import TelemetryTicket from './TelemetryTicket';
import CommonTable from '@common/CommonTable';
import { set } from 'nprogress';
import getMonth from './Months';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions';

const actions = new Actions();

Array.prototype.groupBy = function (prop) {
  return this.reduce(function (groups, item) {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

export default ({
  categories,
  dataCompany,
  openModalDetail,
  action,
  APIEndpoints,
  id_tech,
  id_company,
  language,
  setIdTicket,
  idGroupTech,
}) => {
  const colors = [
    'var(--primary)',
    '#13CBF0',
    '#92D050',
    '#9E24F0',
    'var(--primary)',
    '#13CBF0',
    '#92D050',
    '#9E24F0',
  ];
  const [dataCompany_, setDataCompany] = useState('');
  const [hoursPerformed_, setHoursPerformed] = useState(0);
  const [hoursPerformed_2, setHoursPerformed2] = useState(0);
  const [hoursProduced, setHoursProduced] = useState(0);
  const [hoursProduced2, setHoursProduced2] = useState(0);
  const [dataGraph1, setDataGraph1] = useState([]);
  const [dataGraph2, setDataGraph2] = useState([]);
  const [date, setDate] = useState(moment().format('MM-YYYY'));
  const [dateMonth, setDateMonth] = useState(moment().format('M'));
  const [dateYear, setDateYear] = useState(moment().format('YYYY'));
  const [telemetryTicket, setTelemetryTicket] = useState([
    {
      name: language.TICKET_STATUS.OPENS,
      data: [0, 0, 0],
      color: '#13CBF0',
      stack: 'male',
    },
    {
      name: language.TICKET_STATUS.CLOSED,
      data: [0, 0, 0],
      color: '#9E24F0',
      stack: 'male',
    },
    {
      name: language.BACKLOG,
      data: [0, 0, 0],
      color: 'var(--primary)',
      stack: 'male',
    },
  ]);
  const [months, setMonths] = useState([]);
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [listTickets, setListTickets] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    try {
      console.log( dataCompany.arrayItem, "filhãoooooooooooooo")
      setDataCompany(dataCompany);
     } catch (e) {}
  }, [dataCompany, idGroupTech,id_tech, id_company]);

  useEffect(() => {
    async function loadTri() {
      actions
        .execute('get', APIEndpoints.DASHBOARD + 'BiGraph?tech=' + JSON.stringify(id_tech) + '&group=' + JSON.stringify(idGroupTech) + '&company=' + id_company, '')
        .then((data) => {
          if( data.data ) {
            data = data.data;
          }
          const meses = data.call3meses.arrayItem
            .map(({ name }) => getMonth(name))
            .reverse();
          setMonths(meses);
          const values = data.call3meses.arrayStatus;

          const { Incident, Problem, Solicitation } = values;

          setTelemetryTicket([
            {
              name: language.TICKET_STATUS.OPENS,
              data: Incident.length > 0 ? Incident.reverse() : [0, 0, 0],
              color: '#13CBF0',
              stack: 'male',
            },
            {
              name: language.TICKET_STATUS.CLOSED,
              data: Problem.length > 0 ? Problem.reverse() : [0, 0, 0],
              color: '#9E24F0',
              stack: 'male',
            },
            {
              name: language.BACKLOG,
              data:
                Solicitation.length > 0 ? Solicitation.reverse() : [0, 0, 0],
              color: 'var(--primary)',
              stack: 'male',
            },
          ]);
        });
    }

    loadAvCallCateg();
    loadTri();
  }, [id_tech, id_company, idGroupTech]);

  function toSeconds(time) {
    if (!time) return 0;
    const values = time.split(':');

    const seconds =
      parseInt(values[0]) * 60 * 60 +
      parseInt(values[1]) * 60 +
      parseInt(values[2]);

    return seconds;
  }

  const computedGraph = useCallback((data, date, value) => {
    let datas = [];
    if (value) {
      datas = data
        .filter((x) => x.label === value)
        .map((obj) => {
          return {
            name: obj.label,
            color: obj.color,
            data: Array.from(
              new Array(moment(date, 'MM-YY').daysInMonth()),
              (_, i) => {
                let day = (i + 1).toString().padStart(2, '0');

                let hour = obj.datas.reduce((current, next) => {
                  if (moment(next.data).format('DD') == day) {
                    return current + toSeconds(next.time_resolution);
                  }

                  return current;
                }, 0);

                hour = (hour / 60 / 60).toFixed(2);
                return isNaN(parseFloat(hour)) ? 0 : parseFloat(hour);
              }
            ),
          };
        });
    } else {
      datas = data.map((obj) => {
        return {
          name: obj.label,
          color: obj.color,
          data: Array.from(
            new Array(moment(date, 'MM-YY').daysInMonth()),
            (_, i) => {
              let day = (i + 1).toString().padStart(2, '0');

              let hour = obj.datas.reduce((current, next) => {
                if (moment(next.data).format('DD') == day) {
                  return current + toSeconds(next.time_resolution);
                }

                return current;
              }, 0);

              hour = (hour / 60 / 60).toFixed(2);
              return isNaN(parseFloat(hour)) ? 0 : parseFloat(hour);
            }
          ),
        };
      });
    }

    return datas;
  }, []);

  const loadAvCallCateg = async (dateSelect = '', type = '', filter) => {
    let date_ = dateSelect
      ? dateSelect.replace('-', '/')
      : date.replace('-', '/');

    let payload = {
      dtmy: date_,
      tech: id_tech,
      company: id_company,
      group: idGroupTech,
    };
    actions
      .execute('post', APIEndpoints.DASHBOARD + '/categorytime', '', payload)
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }

        if (type === 'group') {
          setHoursPerformed(data.hoursPerformed);
          setHoursProduced(data.hoursProduced);
          if (filter) {
            //filtrar grafico
            let datas = computedGraph(data.list, date_, filter);
            setDataGraph1(datas);
          } else {
            let datas = computedGraph(data.list, date_);
            setDataGraph1(datas);
          }

          setList(data.list);
        } else if (type === 'tech') {
          setHoursPerformed2(data.hoursPerformed);
          setHoursProduced2(data.hoursProduced);
          if (filter) {
            //filtrar grafico
            let datas = computedGraph(data.list2, date_, filter);
            setDataGraph2(datas);
          } else {
            let datas = computedGraph(data.list2, date_);
            setDataGraph2(datas);
          }

          setList2(data.list2);
        } else {
          let datas = computedGraph(data.list, date_);
          let datas2 = computedGraph(data.list2, date_);
          setHoursPerformed(data.hoursPerformed);
          setHoursProduced(data.hoursProduced);
          setHoursPerformed2(data.hoursPerformed);
          setHoursProduced2(data.hoursProduced);

          setList2(data.list2);
          setDataGraph1(datas);
          setDataGraph2(datas2);

          setList(data.list);
        }
      });
  };

  const OpenModal = (dia, tech) => {
    setShowModal(true);
    let dt = list2.filter((b) => b.label == tech)[0].datas;
    setListTickets(dt);
  };

  const OpenModalGroup = (dia, tech) => {
    setShowModal(true);
    let dt = list.filter((b) => b.label == tech)[0].datas;
    setListTickets(dt);
  };

  let CompanyProps = {
    data: [
      {
        name: language.REQUEST,
        y: dataCompany_
          ? dataCompany_.arrayStatus.Solicitation.reduce(
              (current, next) => (current += next),
              0
            )
          : 0,
        color: '#629daa',
      },
      {
        name: language.TICKET_TYPES.INCIDENT,
        y: dataCompany_
          ? dataCompany_.arrayStatus.Incident.reduce(
              (current, next) => (current += next),
              0
            )
          : 0,
        color: '#f0b002',
      },
      {
        name: language.TICKET_TYPES.PROBLEM,
        y: dataCompany_
          ? dataCompany_.arrayStatus.Problem.reduce(
              (current, next) => (current += next),
              0
            )
          : 0,
        color: '#f66868',
      },
    ],
    language,
  };

  list.sort(function (a, b) {
    if (!isNaN(a.hours) && !isNaN(b.hours)) {
      if (parseFloat(a.hours) > parseFloat(b.hours)) {
        return -1;
      }

      if (parseFloat(a.hours) < parseFloat(b.hours)) {
        return 1;
      }
    }
    return 0;
  });

  let Call_x_Tech_1_Props = {
    title: language.ATTENDANCE_AVERAGE,
    subTitle: `(${language.BY_TECHNICAL_GROUP_WITH_CONSUMED_HOURS})`,
    list: list && list.length > 0 ? list : [],
    productiveHours: hoursProduced,
    hoursPerformed: hoursPerformed_,
    dataGraph: list && list.length > 0 ? dataGraph1 : [],
    language,
  };
  list2.sort(function (a, b) {
    if (parseFloat(a.hours) > parseFloat(b.hours)) {
      return -1;
    }

    if (parseFloat(a.hours) < parseFloat(b.hours)) {
      return 1;
    }
    return 0;
  });

  let Call_x_Tech_2_Props = {
    title: language.ATTENDANCE_AVERAGE,
    subTitle: language.TECH,
    list: list2 && list2.length > 0 ? list2 : [],
    productiveHours: hoursProduced2,
    hoursPerformed: hoursPerformed_2,
    dataGraph: list2 && list2.length > 0 ? dataGraph2 : [],
    language,
  };

  let TelemetryTicketProps = {
    data: telemetryTicket,
  };
  return (
    <div id="dashboard-block-5" className="mb-2">
      {showModal == true ? (
        <Dialog
          title={''}
          open={showModal}
          maxWidth="xl"
          bar={false}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          contentProps={{
            style: {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          <CommonTable
            searchColumn
            paginationTop={false}
            maxHeight={500}
            AppBarProps={{
              style: {
                borderRadius: '10px 10px 0 0',
              },
            }}
            orderColumn
            beforeBar2={
              <Button
                icon="fas fa-times"
                color="light"
                variant="circle"
                outlined
                onClick={() => setShowModal(false)}
              />
            }
            language={language}
            columns={[
              {
                label: language.ACTION,
                key: 'detail',
                style: {
                  width: 1,
                  paddingRight: 5,
                  paddingLeft: 5,
                },
                thConfig: true,
              },

              {
                label: language.ID,
                key: 'id',
                style: { width: 1, paddingRight: 0, textAlign: 'left' },
                thConfig: true,
              },
              {
                label: language.TECH,
                key: 'tech',
                style: { width: 300, paddingRight: 0 },
                thConfig: true,
              },
              {
                label: language.GROUP_TECH,
                key: 'group_tech',
                style: { width: 300, paddingRight: 0 },
                thConfig: true,
              },
              {
                label: language.POSTED_HOURS,
                key: 'sla',
                style: { width: 10, paddingRight: 0, paddingLeft: 0 },
                thConfig: true,
              },
            ]}
            data={listTickets.map((f) => ({
              detail: (
                <i
                  class="fas fa-eye"
                  style={{ width: 10, height: 10, cursor: 'pointer' }}
                  onClick={() =>
                    setIdTicket({
                      id_tickets: f.id_tickets,
                      real_id: f.real_id,
                    })
                  }
                ></i>
              ),
              id: f.real_id,
              tech: f.tech,
              group_tech: f.grupotecnico,

              sla: f.time_resolution,
            }))}
          ></CommonTable>
        </Dialog>
      ) : null}

      <Grid container spacing={1}>
        <Grid item xs={2.5} style={{ ...flexBasis(2.5) }}>
          <Company {...CompanyProps} openModalDetail={openModalDetail} />
        </Grid>
        <Grid item xs={3.5} style={{ ...flexBasis(3.5) }}>
          <Call_x_Tech
            {...Call_x_Tech_1_Props}
            OpenModal={OpenModal}
            OpenModalGroup={OpenModalGroup}
            loadAvCallCateg={loadAvCallCateg}
            dateMonth={dateMonth}
            list2={list2}
            dateYear={dateYear}
            type="group"
          />
        </Grid>
        <Grid item xs={3.5} style={{ ...flexBasis(3.5) }}>
          <Call_x_Tech
            {...Call_x_Tech_2_Props}
            OpenModal={OpenModal}
            OpenModalGroup={OpenModalGroup}
            loadAvCallCateg={loadAvCallCateg}
            dateMonth={dateMonth}
            list2={list2}
            dateYear={dateYear}
            type="tech"
          />
        </Grid>
        <Grid item xs={2.5} style={{ ...flexBasis(2.5) }}>
          <TelemetryTicket {...TelemetryTicketProps} months={months} />
        </Grid>
      </Grid>
    </div>
  );
};
