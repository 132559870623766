import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import {
 
  Grid

   
} from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
var CryptoJS = require('crypto-js') 
const crypto = require('crypto');
export default function({
  
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  closeContract
  
}) {
  
  
  const  decryp=(aMsg='')=> {
    try{
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, "");
      let decipher, tRet;
      decipher = crypto.createDecipher("aes-256-cbc", aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
      tRet += decipher.final("utf8");
      return tRet;
    }catch(e){
      return {}
    }
  
  }
  
 let [companies,setCompanies]= useState([]);
 let [contractsOptions,setContractsOptions]= useState([]);
 let [open,setOpen]= useState(false);
 const [departmentOptions, setDepartmentOptions] = useState([]);
  const  onSubmit=(v,values)=>{
  
   
    values.items = v.fk_id_department;
    values.update='department';
 
    action.execute('post',  APIEndpoints.SOLUTION+'/association', '',values).then(c=>{
  
      setCompanies({fk_id_company:c.data.map(b=>b.fk_id_department)})
 
     })
     closeContract()
   // /api/user/upcontract
   }

  useEffect(() => {
 
  action.execute('get', APIEndpoints.DEPARTMENT , '').then(v=>{
    setDepartmentOptions(v.data.map(u => ({value : parseInt(u.id_department), label : u.name})))
  })
  
  action.execute('post',  APIEndpoints.SOLUTION + '/association/', '',
  {
    type:'department',id_solution:values.id_solution
    }
  ).then(c=>{
  setCompanies({fk_id_department:c.data.map(b=>b.fk_id_department)})

  }) ///api/company/user/:id
 
  },[values])
  return (
    <Dialog
    PaperProps={{
      id: ''
    }}   title={ 
     'Associar Departamento' 
    }
    open={true}
    onClose={closeContract}
    >
       
      <CommonForm
        values={companies}
        fields={[
           
          {
           
            name: 'fk_id_department',
            type: 'listCheckbox',
         
            options:departmentOptions
          }
        ]}
        onSubmit={v => onSubmit(v,values)}
      />
 </Dialog>
   
  );
}
