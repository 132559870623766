import Actions from '@data/Actions';
import Constants from '@data/Constants';

// ----------------------------------------------------------------------

export const getCategoriesByCompanyIdService = async ({ companyId }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute(
    'get',
    `${APIEndpoints}/category`,
    ''
  );

  return data;
};

export const getCatalogsByCategoryIdService = async ({
  companyId,
  categoryId,
}) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute(
    'post',
    `${APIEndpoints}/catalog/category/all`,
    '',
    {
      id_category: categoryId,
    }
  );

  return data;
};

export const getTasksByCatalogIdService = async ({ catalogId }) => {
  const action = new Actions();
  const APIEndpoints = Constants.APIEndpoints.ROOT_;
  const { data } = await action.execute(
    'post',
    `${APIEndpoints}/task/list`,
    '',
    {
      fk_id_catalog_service: catalogId,
    }
  );

  return data;
};
