import Store from '@data/Store';

const { language } = Store;

export function completed_activities({ categories, done, late }) {
	return {
		chart: {
			type: 'column',
			height: 260
		},
		title: null,
		xAxis: {
			categories: categories,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			}
		},
		yAxis: {
			min: 0,
			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			}

		},

		plotOptions: {
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: true,
					style: {
						textOutline: 0,
						color: 'white',
						fontSize: 10
					},
				}
			}
		},
		credits: { enabled: false },
		series: [{
			name: language.ACTIVITIES_IN_DONE,
			color: '#F09702',
			data: done
		}, {
			name: language.LATE_ACTIVITIES,
			color: '#F05E1D',
			data: late
		}]
	}
}
export function maturityLevel({ language, categories, PLANNED_MATURITY, CURRENT_MATURITY }) {
	return {
		chart: {
			polar: true,
			type: 'area',

			height: 355
		},
		credits: {
			enabled: false
		},
		accessibility: {
			description: ''
		},
		title: null,
		subtitle: null,
		xAxis: {
			lineWidth: 0,
			allowDecimals: false,
			tickmarkPlacement: 'on',
			categories,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			}
		},
		yAxis: {
			title: {
				text: ''
			},
			lineWidth: 0,
			min: 0,
			gridLineInterpolation: 'polygon',
			labels: {
				formatter: function () {
					return this.value;
				},
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
			tickWidth: 1
		},
		tooltip: {
			// pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			area: {
				fillOpacity: 0.4,
			}
		},
		legend: {
			enabled: false
		},
		credits: { enabled: false },
		series: [
			{
				name: language.PLANNED_MATURITY,
				zIndex: 2,
				color: '#F09702',
				data: PLANNED_MATURITY,
				pointPlacement: 'on',
			},
			{
				name: language.CURRENT_MATURITY,
				zIndex: 2,
				color: '#33ACA0',
				data: CURRENT_MATURITY,
				pointPlacement: 'on',
			},

		]
	};
}
export function testesEvolutions({ language, evolution, desired_maturity }) {
	var categories = language.DATE_LANG.monthLongList
	return {
		chart: {
			type: 'areaspline'
		},

		title: null,
		subtitle: null,
		xAxis: {
			allowDecimals: false,
			labels: {
				formatter: function () {
					return categories[this.value];
				},
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},

		},
		yAxis: {

			title: {
				text: `${language.PERCENTAGE_OF_MATURITY} %`,
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			}

		},
		tooltip: {
			pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			areaspline: {
				fillOpacity: 0.4,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			}
		},
		credits: { enabled: false },
		series: [{
			name: language.CURRENT_MATURITY,
			color: '#629DAA',
			data: evolution
		}, {
			name: language.DESIRED_MATURITY,
			type: 'line',
			color: '#F09702',
			data: desired_maturity
		}]
	}
}
