import React, { Component } from 'react';
import { Chip, Avatar } from '@material-ui/core';
import prettyBytes from 'pretty-bytes';
import { Tooltip } from 'react-tippy';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import './styles.css';
export default class AttachFile extends React.Component {

	typeFile = (type) => {
		var types = {
			png: 'fas fa-file-image',
			jpg: 'fas fa-file-image',
			jpeg: 'fas fa-file-image',
			gif: 'fas fa-file-image',
			tiff: 'fas fa-file-image',
			raw: 'fas fa-file-image',
			pdf: 'fas fa-file-pdf',
			html: 'fas fa-file-code',
			zip: 'fas fa-file-archive',
			word: 'fas fa-file-word',
			excel: 'fas fa-file-excel',
			powerpoint: 'fas fa-file-powerpoint',
			video: 'fas fa-file-video',
			audio: 'fas fa-file-audio'
		}
		var fa = types[(type ? type.split('/')[1] : '')];
		return fa || 'fas fa-paperclip';
	}
	render() {
		var { type, label, file = {}, ...other } = this.props;
		label = label ? label : (file.name + ' - ' + prettyBytes(file.size || 0));
		return (
			<Tooltip
				html={
					<div style={{ color: '#fff', fontSize: 15 }}>
						<strong>{label}</strong>
					</div>
				}
				position="left"
				theme="dark"
				arrow={true}

			>
				<Chip
					{...other}
					avatar={
						<Avatar>
							<i className={this.typeFile(type || file.type)} style={{ width: 'auto', height: 'auto' }}></i>
						</Avatar>
					}

					className={classnames('chip', this.props.className)}

					label={<div className="ellipsis name-item">{label}</div>}

				/>
			</Tooltip>
		)
	}
}