import React, {memo} from 'react';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';

const { language } = Store;

const MyHeader = ({
	title,
	subTitle,
	className
})=>(
	<AppBar
		contentProps={{
			className:'pl-0 '+className
		}}
		titleProps={{
			style:{color:'var(--primary)'}
		}}
		title={title}
		subTitle={subTitle}
	/>
)
export default memo(({
  
   values,
   setForm,
   h,
   
})=>(
   <div className='wrap mb-3'>
      <AppBar title={language.EVIDENCES} />
     
      <div className='content'>
         <CommonForm
               onRef={r=>setForm(r)}
               values={values}
            fields={[
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.DESCRIPTION}
                        subTitle={language.LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT}
                     />
                  ),
                  type:'textEditor',
                  name:'scope',
               },
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.BSC_METAS}
                        subTitle={language.DESCRIBE_THE_GOALS_OF_THIS_PROJECT}
                     />
                  ),
                  type:'textEditor',
                  name:'goal',
               },
               {
                     col:4,
                     required:true,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title={language.RISKS}
                        subTitle={language.DESCRIBE_THE_RISKS_INVOLVED_IN_THIS_PROJECT}
                     />
                  ),
                  type:'textEditor',
                  name:'risk_project',
               },
            ]}
            onChangeField={(f,v)=>{
               h(v, f.name);
            }}
         />
      </div>
   </div>
))