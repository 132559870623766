import React from 'react';
/*--------------------------common-------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts, { Highcharts } from 'react-highcharts';

/*--------------------------material-ui-------------------------*/
import { Card } from '@material-ui/core';

import { Company } from './Graph';

export default function ({ data = [], openModalDetail, language }) {
  return (
    <Card id="Company" className="h-100">
      <AppBar title={language.COMPANY} />
      <div className="content-medium">
        <ReactHighcharts
          config={Company({
            Highcharts,
            data,
            colors: data.map((e) => e.color),
            openModal: openModalDetail,
          })}
        />
      </div>
    </Card>
  );
}
