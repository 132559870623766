import React, { useState } from 'react';
import { Card, Avatar } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import classnames from 'classnames';
import Progress from '@common/Progress';
import './CardItem.css';
import moment from 'moment';
import { Draggable } from 'react-drag-and-drop';
import { Tooltip } from 'react-tippy';
import Constants from '@data/Constants'
export default function ({
  activity,
  onAdd,
  language,
  index,
  removeActivity,
  id_project,
  toggleAlert,
  id_user_log,
  gestor
}) {
  let colors = [
    'var(--success)',
    'var(--warning)',
    'var(--danger)',
    '#8b3594',
    '#17a2b8',
    '#629DAA',
    '#6c757d'
  ];
  const deleteAc = (del, id_project) => {
    if (del.percent > 0) {
      toggleAlert(
        true,
        'Atividade com progresso em %' +
        del.percent +
        ' , favor finalizar atividade!',
        'error'
      );
    } else {
      removeActivity(del, id_project);
      SetDel([]);
    }
  };

  const onAdd_ = (id, activity) => {
    if (
      activity.usersid.includes(id_user_log) ||
      parseInt(id_user_log) == parseInt(gestor)
    ) {
      onAdd(id, activity);
    } else {
      onAdd(3, activity); 
      toggleAlert(
        true,
        'Usuário não faz parte da atividade , permitido apenas  visualização!',
        'error'
      );
    }
  };

  let [del, SetDel] = useState([]);
  const onClosedModal = () => {
    SetDel([]);
  };

  if (del.name) {
    if (
      activity.usersid.includes(id_user_log) ||
      parseInt(id_user_log) == parseInt(gestor)
    ) {
    } else {
      SetDel([]);
      toggleAlert(
        true,
        'Usuário não faz parte da atividade , contate o gestor do projeto',
        'error'
      );
    }
  }
  return (
    <Draggable type="milestone" data={JSON.stringify(activity)}>
      <Card className="CardItem d-flex mb-3">
        <div className="content-avatares pt-4">
          {activity.users.slice(0, 2).map((u, i) => (
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{u}</strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              {' '}
              <Avatar
                src={activity.usersphoto[i].photo&&`${Constants.APIRoot}/public/users/${activity.usersphoto[i].id_user}/profileLogo.png`}
                style={{ backgroundColor: colors[i] }}
                className={classnames('avatar', { 'mt-2': i == 1 })}
              >
                {u
                  .substr(0, 1)
                  .toString()
                  .toUpperCase()}
              </Avatar>
            </Tooltip>
          ))}

          <Tooltip
            html={
              <div style={{ color: '#fff', fontSize: 11 }}>
                {activity.users.map((u, i) => (
                  <div
                    style={{ radius: 10, marginLeft: '5px', marginTop: '2px' }}
                    className="d-flex align-items-center"
                  >
                    <Avatar
                      src={activity.usersphoto[i].photo&&`${Constants.APIRoot}/public/users/${activity.usersphoto[i].id_user}/profileLogo.png`}
                      className={'avatar'}
                      style={{
                        fontSize: '10px',
                        width: '17px',
                        height: '17px',
                        backgroundColor: colors[i < 5 ? i++ : (i = 1)]
                      }}
                    >
                      {u
                        .substr(0, 1)
                        .toString()
                        .toUpperCase()}
                    </Avatar>
                    <span style={{ color: 'black', marginLeft: '4px' }}>
                      {u}
                    </span>
                  </div>
                ))}
              </div>
            }
            position="right"
            theme="light"
          >
            {activity.users.length > 2 && (
              <span style={{ cursor: 'pointer' }} className="mt-1">
                +{activity.users.length - 2}
              </span>
            )}
          </Tooltip>
        </div>
        <div className="content-medium" style={{ width: 'calc(100% - 40px)' }}>
          <header className="d-flex align-items-center justify-content-end">
            {activity.tagac &&
              activity.tagac.map((e, i) => (
                <span
                  key={i}
                  style={{
                    backgroundColor: e.color,
                    marginRight: i == 1 ? '20px' : '1px'
                  }}
                  className={classnames({ 'mx-2': i == 1 })}
                >
                  {e.name.length > 7 ? e.name.substr(0, 7) : e.name}
                </span>
              ))}
          </header>
          <section>
            <p className="mt-2 mb-2 ellipsis-line camp-2">{activity.id_project_activity} - {activity.name}</p>
            <div className='d-flex align-items-center'>
              {activity.activity_antecessor&&
                <Tooltip
                  interactive
                  html={
                    <div style={{minWidth:210}}>
                      <span>Atividades Antecessoras</span>
                      <hr className='my-1'/>
                      <ul>
                        
                        {activity.activity_antecessor.reduce((obj, e)=>{
                          if(!obj.find(o=>e.fk_id_activity_predecessor==o.fk_id_activity_predecessor)){
                            obj.push(e)
                          }
                          return obj;
                        }, []).map((e, i) => (
                          <li style={{fontSize: 11}}>{e.fk_id_activity_predecessor} - {e.name}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  position="right"
                  theme="light"
                >
                  <i className="mr-2 fab fa-product-hunt"/>
                </Tooltip>
              }
              <Progress current={activity.percent} className='w-100' background="var(--danger)" />
            </div>
          </section>
          <footer className='d-flex align-items-center'>
            <span className="mx-11 d-flex align-items-center">
              <i className="fas fa-calendar-alt mr-1" />
              <Tooltip
                interactive
                html={moment(activity.dt_start).format('L') +' á '+
                moment(activity.dt_end).format('L')}
                position="right"
                theme="dark"
              > 
                 {moment(activity.dt_start).format('L')}
              </Tooltip>
            </span>
            <span className="mx-11 d-flex align-items-center">
              <i className="far fa-comment-dots mr-1" />
              {activity.comments ? activity.comments.length : 0}
            </span>
            <span className="mx-11 d-flex align-items-center">
              <i className="fas fa-paperclip mr-1" />
              {activity.file ? activity.file.length : 0}
            </span>
            <span className="mx-11">
              <Tooltip
                interactive
                html={language.EDIT}
                position="right"
                theme="dark"
              >
                <i
                  alt="atividade"
                  onClick={() => onAdd_(2, activity)}
                  className="fas fa-edit mr-1"
                />
              </Tooltip>
            </span>
            <span className="mx-11">
              <Tooltip
                interactive
                html={language.STATUS_ACTIVITY}
                position="right"
                theme="dark"
              >
                <i
                  alt="atividade"
                  onClick={() => onAdd_(1, activity)}
                  className="fas fa-eye mr-1"
                />
              </Tooltip>
            </span>

            {index == 0 && (
              <span className="mx-11">
                <Button
                  icon="fas fa-trash"
                  variant="circle"
                  outlined
                  title={'Remover Atividade'}
                  style={{ border: 'none' }}
                  size={0.5}
                  onClick={() => SetDel(activity)}
                  color="warning"
                />
              </span>
            )}
          </footer>
        </div>
      </Card>

      {del.id_project_activity && (
        <Dialog
          title="Deseja Remover Atividade"
          open={true}
          onClose={onClosedModal}
          PaperProps={{
            id: 'DialogFormActivityProject'
          }}
          contentProps={{
            className: 'pt-4'
          }}
        >
          <h2 className="titleProject_"># {del.name}</h2>
          <br></br>
          <Button
            icon="far fa-clock"
            label="REMOVER"
            variant="normal"
            color="#3CD6C7"
            style={{
              backgroundColor: '#3CD6C7',
              color: 'white'
            }}
            onClick={() => deleteAc(del, id_project)}
          />
        </Dialog>
      )}
    </Draggable>
  );
}
