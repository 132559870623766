import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    modal: {
        margin: 'auto',
        borderRadius: '4px',
        overflowY: 'auto'
    },
    paper: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
        padding: '32px'
    },
    title: {
        fontSize: 22,
    },
}));

export default function ServicePortalModal({ subject, description, language }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Button
                size="small"
                variant="outlined"
                onClick={handleOpen}
            >
                {subject}
            </Button>
            <Dialog
                open={open}
                maxWidth='md'
                scroll='paper'
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{subject}</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        startIcon={<Close />}
                    >
                        {language.CLOSE}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
