import React, { Component } from 'react';
import CommonForm from '../../common/CommonForm2';
import { Container, Row, Col } from 'react-grid-system';
import RaisedButton from '../../common/Button';
import { Card, TextField, Dialog, Checkbox, Toggle } from '@material-ui/core';
import CommonLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import Store from '../../data/Store';
import ListColumn from '@common/ListColumn';
import { generateApproversTooltip } from './utils/approversTooltip';
import moment from 'moment';

import './RequestApproval.css';

import {
  FaInfo,
  FaPlusCircle,
  FaSearch,
  FaClose,
  FaQuestion,
} from 'react-icons/lib/fa';
import { applyTimezoneOnHTML } from '../../common/Timezone';

const crypto = require('crypto');
export default class RequestApproval extends Component {
  constructor() {
    super();
    this.state = {
      approval: true,
      justify: null,
    };
    this.handleApproval = this.handleApproval.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  decryp = (aMsg = '') => {
    try {
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');
      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  };

  handleApproval() {
    this.setState({
      approval: !this.state.approval,
    });
  }

  onSubmit(status) {
    let obj = {
      requestId: this.props.request.id_request || this.props.id,
      action: '',
      status: '',
      reason: this.state.justify,
    };
    if (!obj.reason || obj.reason === '<p><br></p>') {
      Store.toggleAlert(
        true,
        language.THE_JUSTIFICATION_FIELD_IS_REQUIRED_FOR_APPROVAL,
        'error'
      );
      return;
    }
    this.props.onFormSubmitAprove(obj);
  }

  render() {
    let {
      handlePage,
      language,
      request,
      redirect2,
      fromDash,
      fromDashApprove,
      onCloseApprove,
    } = this.props;
    let title = '';
    let category = request.namecategory_;
    let service = request.catalogservicename_;
    let terceironivel = request.catalogtaskname_;
    try {
      if (this.props.request.id_request) {
        let subject =
          this.props.request.subject.length > 0
            ? this.props.request.subject.substr(0, 30) + ' ...'
            : this.props.request.subject;
        title = 'R' + this.props.request.id_request + ' ' + subject;
      } else {
        title = 'R' + this.props.id;
      }
    } catch (r) {}
    return (
      <Dialog
        open={true}
        modal={true}
        style={{ overflowX: 'hidden' }}
        maxWidth="md"
        PaperProps={{
          id: 'RequestView',
        }}
        onRequestClose={() =>
          fromDash == true ? redirect2('/dash/gsd') : handlePage('list', '')
        }
      >
        <CommonLayout
          icon={
            <div className="approvalIcon" style={{ height: 40, width: 40 }} />
          }
          title={<div style={{ width: 600 }}>{title}</div>}
          subtitle={language.APPROVE_OR_REJECT}
          back={false}
          rightElements={[
            <FaClose
              fontSize={25}
              className="colorPrimary pointer"
              onClick={() =>
                onCloseApprove
                  ? onCloseApprove()
                  : fromDash == true
                  ? redirect2('/dash/gsd')
                  : window.location.href.indexOf('approve') > -1
                  ? redirect2('/dash/requestapprove')
                  : redirect2('/dash/request')
              }
            />,
          ]}
        >
          <div
            style={{
              maxHeight: 400,
              marginTop: 10,
              marginBottom: 100,
              marginLeft: 20,
            }}
          >
            <div className="content">
              <ListColumn
                col={6}
                items={[
                  {
                    label: language.STATUS,
                    value:
                      request.status == 'Approve'
                        ? language.APPROVE
                        : request.status,
                  },
                  {
                    label: language.APPROVER,
                    value: generateApproversTooltip(request.chiefname),
                  },
                  {
                    label: language.DATE_CAD,
                    value: moment(request.dt_cad).format('L'),
                  },
                  {
                    label: language.REGISTER_HOUR,
                    value: moment(request.dt_cad).format('LTS'),
                  },
                  {
                    label: language.COMPANY,
                    value: request.name_company_fk || request.name_company,
                  },
                  {
                    label: language.SOLICITANT,
                    value:
                      request.namerequest == null
                        ? request.name
                        : request.namerequest,
                  },
                  {
                    label: language.EMAIL,
                    value:
                      request.emailrequest == null
                        ? request.username
                        : request.emailrequest,
                  },
                  {
                    visible:
                      request.status == 'Cancelled' ||
                      request.status == 'Reject',
                    label: language.REASON,
                    value: (
                      <p
                        style={{ color: '#888080' }}
                        dangerouslySetInnerHTML={{
                          __html: request.reason,
                        }}
                      />
                    ),
                  },
                  {
                    label: language.ID_ACESS_REMOTE,
                    value: request.id_access_remote,
                  },
                  {
                    label: language.PHONE,
                    value: request.phone,
                  },
                  {
                    label: language.TELEPHONE_EXTENSION,
                    value: request.ramal,
                  },
                  {
                    label: language.CATEGORY,
                    value: category,
                  },
                  {
                    label: language.CATALOG,
                    value: service,
                  },
                  {
                    label: language.THIRDED_LEVEL,
                    value: terceironivel,
                  },
                  {
                    col: 12,
                    label: language.DESCRIPTION,
                    value: (
                      <p
                        style={{ color: '#888080' }}
                        dangerouslySetInnerHTML={{
                          __html: request.description,
                        }}
                      />
                    ),
                  },
                ].filter(Boolean)}
              />
            </div>
            <Container>
              <Col md={12}>
                <div>
                  {request.template != null && request.template.length > 5 ? (
                    <div className="template template-dinamic">
                      <p
                        style={{ color: '#888080', pointerEvents: 'none' }}
                        dangerouslySetInnerHTML={{
                          __html: applyTimezoneOnHTML(this.decryp(request.template)),
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </Col>
              <Row>
                <Col md={12}>
                  <CommonForm
                    hiddenSubmit
                    fields={[
                      {
                        col: 12,
                        name: 'reason',
                        label: language.JUSTIFICATION,
                        type: 'textEditor',
                        required: true,
                      },
                    ]}
                    onChangeField={(f, v) => {
                      this.setState({ justify: v });
                    }}
                    onSubmit={(_values) =>
                      this.onSubmit('Approved', {
                        id_request:
                          this.props.request.id_request || this.props.id,
                        values: _values,
                      })
                    }
                    button={{ label: language.SAVE }}
                  />
                </Col>
                <br></br>
              </Row>
              <Row>
                {
                  <Col md={6}>
                    <RaisedButton
                      label={language.REJECT}
                      primary
                      fluid
                      color="danger"
                      style={{
                        height: 40,
                      }}
                      variant="normal"
                      onClick={() =>
                        this.props.onFormSubmit('reject', {
                          id_request:
                            this.props.request.id_request || this.props.id,
                          values: { reason: this.state.justify },
                        })
                      }
                    />
                  </Col>
                }
                {
                  <Col md={6}>
                    <RaisedButton
                      label={language.APPROVAL}
                      primary
                      fluid
                      style={{
                        height: 40,
                      }}
                      color="success"
                      variant="normal"
                      disabled={
                        !this.state.justify ||
                        !this.state.justify == '<p><br></p>'
                      }
                      onClick={() =>
                        this.onSubmit({
                          id_request:
                            this.props.request.id_request || this.props.id,
                          values: { reason: this.state.justify },
                        })
                      }
                    />
                  </Col>
                }
              </Row>
              <br></br>
            </Container>
          </div>
        </CommonLayout>
      </Dialog>
    );
  }
}
