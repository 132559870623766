import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Constants from '@data/Constants';
import { NavLink as Link } from 'react-router-dom';
import { getFileFromBase64, spFetch, useServicePortalStyles } from '../helpers';

export const ServicePortalMenuLink = ({ item, language }) => {
  const styles = useServicePortalStyles();

  const showFile = (item) => {
    spFetch(
      `${Constants.APIEndpoints.COMPANY}/${item.company_id}/portal/${item.company_portal_id}/file`
    ).then(({ data }) => {
      const file = getFileFromBase64({
        fileType: data.type,
        fileBlob: data.blob,
      });

      window.open(file, '_blank');
    });
  };

  let linkProp = { to: '/dash/service-portal' };
  if (item.label === 'MENU_TICKET') linkProp.to += '/my-tickets';
  else if (item.label === 'MENU_APPROVAL_CENTER') linkProp.to += '/my-approvals';
  else if (item.label === 'MENU_FAQ') {
    //linkProp.to = `${window.location.origin}/api/company/${item.company_id}/portal/${item.company_portal_id}/file`;
    //linkProp.target = '_blank';
    linkProp.onClick = () => showFile(item);
  }

  return (
    <li key={item.label} className="sp-menu-item">
      <Link {...linkProp}>
        <Typography component="span" className={styles.text}>
          {language['SERVICE_PORTAL'][item.label]}
        </Typography>
      </Link>
    </li>
  );
};
