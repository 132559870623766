import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

@observer
export default class SimpleBarChartGraph extends Component {
  render() {
    let {language} = this.props;
    var Progress = language.PROGRESS;
    return (
      <ResponsiveContainer width="100%" height={this.props.height}>
        <BarChart
          data={this.props.dataServers}
          stackOffset="sign"
          layout="vertical"
          margin={{left:-50, top:0, right:5, bottom:0}}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            type="number"
            tick={{ fontSize: 12, fill: 'var(--primary)' }}
          />
          <YAxis type="category" dataKey="name" />
          <Tooltip />
          <Legend iconSize={10} wrapperStyle={{ fontSize: 12 }} />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey={Progress} stackId="a" barSize={100} fill="#009f8b" />
          {this.props.alert &&
            <Bar dataKey={language.IN_ARREARS} stackId="a" barSize={100} fill="var(--danger)" />
          }
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
