import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '@common/CommonPage';
import PerfilFormList from './PerfilFormList';
import PerfilList from './PerfilList';
import Animate from '@common/Animate';
import CommonDelete from '../../common/CommonDelete';
import Dialog from '@common/Dialog';
@observer
export default class PerfilContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page:'list',
      values:null,
      valuesdelete:null,
      perfil_:[],
      check:null
    };
    this.savePerfil =   this.savePerfil.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.setcheck = this.setcheck.bind(this);
  }
setcheck(v){
  //console.log(v)
  this.setState({check:v})  
  }
  handlePage = (page, values=null) => {
    this.setState({ page, values });
  }
  onDelete() {
 
    let { values } = this.state;
   // console.log(JSON.stringify(values))
    this.action.execute('delete', this.APIEndpoints.PERFIL+`deleteperfil/${values.perfil_id}`, 'perfil',{}, 'perfil_id');
    this.redirect('/dash/configuration/perfil')
  }
  


savePerfil(values){
    
  //console.log(values)
   
  
  this.action.execute('post', this.APIEndpoints.PERFIL+`saveperfil`, 'perfil',values);
//  this.setState({ page:'list'});
  this.redirect('/dash/configuration/perfil')
}
  


  componentDidMount(){
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute('get', this.APIEndpoints.PERFIL+`getperfils`, '').then(v=>{
     // console.log(v.data)
      this.setState({perfil_:v.data})
    })
 
  }

  render() {
    let {language, companies,perfil} = this.store;
    let {page,values,perfil_} = this.state;

    return (
      <Animate>
        <PerfilList  check={this.state.check} setcheck={this.setcheck}   APIEndpoints={this.APIEndpoints} perfil={perfil_} language={language} companies={companies} handlePage={this.handlePage}/>

        { page=='form' &&
          <Dialog
            title={language.MAIN_MODULES}
            open={true}
            onClose={()=>this.handlePage('list')}
            maxWidth='xl'
          >
            <PerfilFormList setcheck={this.setcheck}  check={this.state.check} values={values} companies={companies} savePerfil={this.savePerfil} language={language} companies={companies} handlePage={this.handlePage}/>
          </Dialog>
        }
         {page === 'remove' && (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={this.state.values.name}
          />
        )  }
        
      </Animate>
    );
  }
}
