import React, { useEffect, lazy, Suspense } from 'react';
import { observer } from 'mobx-react';
import Button from '@common/Button';
import MenuDefault from './default/index';
import { AppBar } from '@material-ui/core';
import CommonOverlayLoading from '@common/OverlayLoading';
import { Dialog } from '@material-ui/core';
import Dialogs from '@common/Dialog';
import { FaSpinner, FaCaretDown } from 'react-icons/lib/fa';
import MediaQuery from 'react-responsive';
import Store from '../data/Store';
import Constants from '../data/Constants';
import Actions from '../data/Actions';
import 'react-tippy/dist/tippy.css';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import history2 from '@common/history2';
import history from '@common/history';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from 'react-tippy';
import { Chip, Avatar } from '@material-ui/core';
import SimplesAlert from '@common/SimplesAlert';
import '../bClass.css';
import './styles.css';
import crypt from '../util/crypt';
import decrypt from '../util/decrypt';
import '../../node_modules/nprogress/nprogress.css';
import NProgress from '../../node_modules/nprogress/nprogress.js';
import MarketingStrategy from '@images/marketing-strategy.png';
import './CommonPage.css';
import FloatChat from '../components/FloatChat/FloatChat';
import Notification from '../components/Notification/Notification.js';
import Logo from '@images/newLogoSensr.png';
import Build from '../components/Build/Buildhome__';
import 'react-perfect-scrollbar/dist/css/styles.css';
import $ from 'jquery';
import FlagBr from '@images/flags_language/flag_brazil.gif';
import FlagEua from '@images/flags_language/flag_EUA.gif';
import FlagSpain from '@images/flags_language/Flag_of_Spain.gif';
import { testRoute } from '@common/Func';
import CommonTable from '@common/CommonTable';
import UsersLogin from './UsersOnline';
import { Badge } from '@material-ui/core';
import SearchInput from '@common/SearchInput';
import Popover from '@material-ui/core/Popover';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DownloadFiles from '../util/DownloadFiles';

import RequestApproval from '../components/request/RequestApproval';
import RequestView from '../components/request/RequestView';
import ApprovalCenterHistoric from '../components/ApprovalCenter/ApprovalCenterHistoric';
const TicketManagement = lazy(() => import('../components/TicketManagement'));
const DialogFormTicket = lazy(() =>
  import('../components/TicketManagement/DialogForm')
);
//import  Chatusers from '../components/ChatUsers/index'
NProgress.configure({ showSpinner: false });

history2.listen((location, action) => {
  window.localStorage.setItem('previous_url', location.pathname);
  window.scrollTo(0, 0);
});

function ProgressLoading({ loading }) {
  useEffect(() => {
    if (loading) {
      NProgress.set(0.3);
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [!!loading]);
  return null;
}

@observer
export default class CommonPage extends React.Component {
  constructor(props) {
    super(props);
    this.history2 = history2;
    this.history = history;
    this.store = Store;
    this.APIRoot = Constants.APIRoot;
    this.APIEndpoints = Constants.APIEndpoints;
    this.action = new Actions();

    this.state = {
      open: false,
      error: false,
      anchorEl: null,
      help: false,
      helps: [],
      helpDetail: false,
      pdf: '',
      loading: 0,
      openupdate: false,
      totalRequestApprove: 0,
      totalApprovalCenter : 0,
      totalTask: 0,
      notificationButton: {
        index: 0,
        anchorEl: null,
      },
      allTickets: [],
      showTickets: { index: 0, anchorEl: null },

      ticketToShow: undefined,
      requestToApprove: undefined,
      requestToCategorize: undefined,
      requestToView: undefined,
      params : [],
      sendFile: false,
      openApprovalCenterHistoric : false
    };
    this.msg = '';
    this.alertOptions = {
      offset: 14,
      position: 'top right',
      theme: 'light',
      time: 5000,
      transition: 'scale',
      type: 'info',
    };
    this.handleClick = this.handleClick.bind(this);

    this.redirect = this.redirect.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.searchRequestTicket = this.searchRequestTicket.bind(this);
    this.handleTicket = this.handleTicket.bind(this);
    this.onFormSubmitAproveRequest = this.onFormSubmitAproveRequest.bind(this);
    this.onFormSubmitRequest = this.onFormSubmitRequest.bind(this);
    this.ondonwFileRequest = this.ondonwFileRequest.bind(this);
    this.onFormSubmitTabRequest = this.onFormSubmitTabRequest.bind(this);

    this.themeGrayScale = localStorage.getItem('themeGrayScale');
  }
  clearHistory() {
    //console.log(history2);
    history2.replace();
    //console.log(history2, 'limpandoooo');
  }
  redirect(route, state) {
    window.history.pushState({ key: 'sensr' + route, state: state }, '', route);
    window.location.reload();
  }
  redirect2(route, state) {
    history2.push(route, state);
  }

  handleToggle = () => this.setState({ open: !this.state.open });

  onLogout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('users');
    sessionStorage.removeItem('times');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('priorities');
    sessionStorage.removeItem('complexities');
    sessionStorage.removeItem('urgencies');
    sessionStorage.removeItem('contractss');
    sessionStorage.removeItem('params');
    sessionStorage.removeItem('impacts');
    sessionStorage.removeItem('release');

    this.action.execute(
      'post',
      this.APIEndpoints.LOGOUT,
      'logout',
      this.store.getUserLoged()
    );
    this.redirect('/');
  }

  handleClick(event, v) {
    this.setState({ anchorEl: event.currentTarget, open: v });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  openDetailHelp = (url) => {
    this.setState({ url: url, helpDetail: true });
  };
  ondonwFileRequest = (id, file) => {
    DownloadFiles(
      this.APIEndpoints.REQUESTS + '/api/downloadrequest/' + id + '&' + file,
      file
    );
  };
  onFormSubmitItem_(values) {
    let filesSend = [];
    let cont = 0;

    let total = values.files.length;
    Array.from(values.files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (!!reader.result) {
            resolve(reader.result);
          } else {
            reject(Error('Failed converting to base64'));
          }
        };
      });
      promise.then((result) => {
        if (file.size >= 40000000) {
          alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_10MB);
          this.setState({ sendFile: false });
          filesSend = [];
          //this.state.files = [];
          cont = 0;
        }
        filesSend.push({
          file: result,
          type: file.type,
          namefile: file.name,
        });
        cont = cont + 1;
        if (total === cont) {
          values.file = filesSend;

          this.action
            .execute('post', `${this.APIEndpoints.TICKET}`, '', values)
            .then((data) => {
              window.location.reload();
            });
        }
      });
    });

    // this.redirect('/dash/request');
  }

  onFormSubmitTabRequest = (values) => {
    let contador = 0;
    let { id_request } = this.props;
    if (values.files.length > 0) {
      this.onFormSubmitItem_(values, id_request);
    } else {
      if (values.ticketArray != undefined && values.ticketArray.length > 1) {
        values.ticketArray.forEach((num) => {
          contador++;
          let item = values.req.filter((x) => x.id_request === num)[0];

          values.fk_id_request = num;
          values.description = item.description;
          values.subject = item.subject;
          values.fk_id_user = item.fk_id_user;
          values.manys = true;
          if (values.keepOriginalCatalog == true) {
            values.fk_id_category = item.fk_id_category;
            values.fk_id_catalog_service = item.fk_id_catalog_service;
            values.fk_id_catalog_task = item.fk_id_catalog_task;
            values.fk_id_priority = item.fk_id_priority;
            values.fk_id_urgency = item.fk_id_urgency;
            values.id_impact = item.id_impact;
          }
          this.action
            .execute('post', this.APIEndpoints.TICKET, 'requests', values)
            .then((e) => {
              this.setState({ requestToCategorize: undefined });
            });
        });
      } else {
        this.action
          .execute('post', this.APIEndpoints.TICKET, 'ticketss m', values)
          .then((h) => {
            this.setState({ requestToCategorize: undefined });
          });
      }
    }
  };

  onFormSubmitAproveRequest = (values, pach) => {
    values.action = 'approval';
    values.status = 'Approved';

    let { state } = this.history2.location;
    let fromDash = '';
    this.history2.location.state = null;
    this.history2.location.state = null;

    this.action
      .execute(
        'post',
        this.APIEndpoints.REQUEST + '/' + values.requestId,
        'requests',
        values
      )
      .then((e) => {
        this.action
          .execute('get', this.APIEndpoints.REQUEST + '/count/approve', '')
          .then((res) => {
            this.setState({ totalRequestApprove: res.data });
          });

        this.action
          .execute('get', this.APIEndpoints.TASKS + '/totalApprove', '')
          .then((res) => {
            this.setState({ totalTask: res.data });
          });
        this.setState({ requestToApprove: undefined, allTickets: [] });
        //        window.location.reload()
      });
  };

  onFormSubmitRequest = (type, values, general_parameters) => {
    let contador = 0;
    this.store.loading = true;
    values.values.action = 'approval';
    values.values.status = 'Rejected';
    if (values.id_request.length > 1) {
      values.id_request.forEach((num) => {
        contador++;
        this.store.loading++;

        this.action
          .execute(
            'post',
            this.APIEndpoints.REQUEST + '/' + num,
            'requests',
            values.values
          )
          .then((e) => {
            this.setState({ requestToApprove: undefined, allTickets: [] });
          });
        this.store.loading = false;
      });
    } else {
      if (id_request) {
        this.action
          .execute(
            'post',
            this.APIEndpoints.REQUEST + '/' + values.id_request,
            'requests',
            values.values
          )
          .then((e) => {
            this.setState({ requestToApprove: undefined, allTickets: [] });
          });
      } else {
        this.action
          .execute(
            'post',
            this.APIEndpoints.REQUEST + '/' + values.id_request,
            'requests',
            values.values
          )
          .then((e) => {
            this.setState({ requestToApprove: undefined, allTickets: [] });
          });
      }
    }
  };

  componentWillMount() {
    let { pathname } = this.history2.location;
    if (
      testRoute('/dash', pathname) &&
      window.localStorage.getItem('previous_url') == '/dash/login'
    ) {
      localStorage.clear();
      sessionStorage.clear();
    }

    this.store.profilePhoto =
      this.APIRoot +
      '/public/users/' +
      this.store.getUserLoged().id +
      '/profileLogo.png';

    let url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (token) {
      token = token.replaceAll('%20', '+');
      token = token.replaceAll(' ', '+');

      let valor = JSON.parse(decrypt(token));

      if (valor.type == 'REQUEST') {
        this.redirect(`/dash/request`, { id: valor.token });
      }
      if (valor.type == 'TICKET') {
        this.redirect2('/dash/ticketmanagement', {
          ticket: { id_tickets: valor.token },
        });
      }
      if (valor.type == 'CHANGE') {
        this.redirect2('/dash/changemanagement/analyze', valor.token);
      }
    }

    ///  sessionStorage.setItem("times", crypt(JSON.stringify(g.data)));

    if (window.location.href.indexOf('/dash/cio') > -1) {
      //console.log('CIO');
    }

    if (window.location.href.indexOf('/dash/dashboard') > -1) {
    } else {
      /* this.action.execute(
        'get',
        this.APIEndpoints.USER + '/techgroups',
        'groupstech'
      );*/
      this.action.execute(
        'get',
        this.APIEndpoints.TICKET + '/getcomments',
        'new_msg_notifications'
      );


    this.action
      .execute('get', this.APIEndpoints.REQUEST + '/count/approve', '')
      .then((res) => {
        this.setState({ totalRequestApprove: res.data });
      });

    this.action
      .execute('get', this.APIEndpoints.TICKET + '/gettotalapprovals', '')
      .then((res) => {
        this.setState({ totalApprovalCenter: res.data });
      }).catch((err) => {
        console.log('error', err);
      });

    this.action
      .execute('get', this.APIEndpoints.TASKS + '/totalApprove', '')
      .then((res) => {
        this.setState({ totalTask: res.data });
      });
      this.action
      .execute('get', this.APIEndpoints.TICKET + '/params', 'params')
      .then((b) => {
        this.setState({params : b.data[0]})
      });
    }
  }

  componentDidMount() {
    if (
      sessionStorage.getItem('priorities') == null ||
      sessionStorage.getItem('priorities') == 'null'
    ) {
      this.action
        .execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/60',
          'priorities'
        )
        .then((v) => {
          sessionStorage.setItem('priorities', crypt(JSON.stringify(v.data)));
        });
    }

    if (
      sessionStorage.getItem('complexities') == null ||
      sessionStorage.getItem('complexities') == 'null'
    ) {
      this.action
        .execute(
          'get',
          this.APIEndpoints.TABLE_STRUCTURED + '/130',
          'complexities'
        )
        .then((v) => {
          sessionStorage.setItem('complexities', crypt(JSON.stringify(v.data)));
        });
    }

    if (
      sessionStorage.getItem('urgencies') == null ||
      sessionStorage.getItem('urgencies') == 'null'
    ) {
      this.action
        .execute('get', this.APIEndpoints.TABLE_STRUCTURED + '/40', 'urgencies')
        .then((v) => {
          sessionStorage.setItem('urgencies', crypt(JSON.stringify(v.data)));
        });
    }
    if (
      sessionStorage.getItem('impacts') == null ||
      sessionStorage.getItem('impacts') == 'null'
    ) {
      this.action
        .execute('get', this.APIEndpoints.TABLE_STRUCTURED + '/50', '')
        .then((v) => {
          sessionStorage.setItem('impacts', crypt(JSON.stringify(v.data)));
        });
    }
    if (
      sessionStorage.getItem('awaiting') == null ||
      sessionStorage.getItem('awaiting') == 'null'
    ) {
      this.action
        .execute('get', this.APIEndpoints.STATUS_AWAITING, '')
        .then((b) => {
          sessionStorage.setItem('awaiting', crypt(JSON.stringify(b.data)));
        });
    }

    if (
      sessionStorage.getItem('params') == null ||
      sessionStorage.getItem('params') == 'null'
    ) {
      this.action
        .execute('get', this.APIEndpoints.TICKET + '/params', '')
        .then((b) => {
          sessionStorage.setItem('params', crypt(JSON.stringify(b.data)));
        });
    }

    // if (
    //   sessionStorage.getItem('release') == null ||
    //   sessionStorage.getItem('release') == 'null'
    // ) {
    //   this.action
    //     .execute('get', this.APIEndpoints.USER + '/release', '')
    //     .then((b) => {
    //       sessionStorage.setItem('release', b.data.release);
    //     });
    // }

    if (
      sessionStorage.getItem('times') == null ||
      sessionStorage.getItem('times') == 'null'
    ) {
      this.action
        .execute(
          'get',
          this.APIEndpoints.COMPANY_TIME + '/config',
          'companytime'
        )
        .then((g) => {
          sessionStorage.setItem('times', crypt(JSON.stringify(g.data)));
        });

      ///api/ticket/reset
    }

    $.cssNumber.zoom = true;
    if (!('zoom' in document.body.style)) {
      $.cssHooks.zoom = {
        get: function (elem, computed, extra) {
          var value = $(elem).data('zoom');
          return value != null ? value : 1;
        },
        set: function (elem, value) {
          var $elem = $(elem);
          var size = {
            // without margin
            width: $elem.outerWidth(),
            height: $elem.outerHeight(),
          };
          $elem.data('zoom', value);
          if (value != 1) {
            $elem.css({
              transform: 'scale(' + value + ')',
              marginLeft: (size.width * value - size.width) / 2,
              marginRight: (size.width * value - size.width) / 2,
              marginTop: (size.height * value - size.height) / 2,
              marginBottom: (size.height * value - size.height) / 2,
            });
          } else {
            $elem.css({
              transform: 'scale(1)',
              margin: 0,
            });
          }
        },
      };

      $('[zoom]').each((i, el) => {
        $(el).css({ zoom: $(el).attr('zoom') });
      });
    }
  }

  componentDidUpdate(nextState, nextProps) {
    /*if(this.store.loading==1 && this.state.loading!=this.store.loading){
      this.setState({loading:1})
      console.log(this.store.loading, nextState.loading)
      NProgress.start()
    }else if(this.store.loading==0 && this.state.loading!=this.store.loading){
      this.setState({loading:0})
      console.log(this.store.loading, nextState.loading)
      NProgress.done()
    }*/
  }

  async logoutSso(kcStorage) {
    const kcInfo = JSON.parse(kcStorage);
    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", process.env.KEYCLOAK_CLIENT);
    urlencoded.append("refresh_token", kcInfo.refreshToken);

    var headers = new Headers();
     headers.append("Content-Type", "application/x-www-form-urlencoded");
     headers.append("Authorization", "Bearer " + kcInfo.token);

    var requestOptions = {
       method: 'POST',
       headers: headers,
       body: urlencoded,
       redirect: 'follow'
     };

    fetch(`${process.env.KEYCLOAK_HOST}/realms/${process.env.KEYCLOAK_REALM}/protocol/openid-connect/logout`, requestOptions)
       .then(() => sessionStorage.removeItem('keycloak'))
       .catch(error => console.log('error', error));
  }

  async logoutApp() {
    const kcStorage = sessionStorage.getItem('keycloak');
    if (kcStorage) await this.logoutSso(kcStorage);

    this.action.execute(
      'post',
      this.APIEndpoints.LOGOUT,
      'logout',
      this.store.getUserLoged()
    );
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('users');
    sessionStorage.removeItem('times');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('priorities');
    sessionStorage.removeItem('complexities');
    sessionStorage.removeItem('impacts');
    sessionStorage.removeItem('urgencies');
    sessionStorage.removeItem('awaiting');
    sessionStorage.removeItem('params');
    sessionStorage.removeItem('username');
    const cookies = document.cookie.split(';');
    cookies.map((cookie) => {
      const cookiePosition = cookie.indexOf('=');
      const domain = document.domain;
      const cookieName =
        cookiePosition > -1 ? cookie.substring(0, cookiePosition) : cookie;
      document.cookie =
        cookieName +
        '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain =' +
        domain +
        '/';
    });

    //this.redirect('/');
  }

  // -----------------------------------abre a msg--------------------------------------------------
  openMsg = (t) => {
    this.action
      .execute('get', this.APIEndpoints.TICKET + '/' + t.id_tickets, '')
      .then((e) => {
        this.store.$emit(
          'remove-msg-of-list-notification',
          'new_msg_notifications',
          { id_comments: t.id_comments }
        );
        // this.action.execute('get', this.APIEndpoints.TICKET + '/' + 137, '').then(e=>{

        e.data.selectTypeMsg = t.type;
        if (
          !Store.tickets_chat_opens.find((e) => e.id_tickets == t.id_tickets)
        ) {
          Store.tickets_chat_opens.push(e.data);
        } else {
          Store.tickets_chat_opens = Store.tickets_chat_opens.map((tco) =>
            tco.id_tickets == t.id_tickets ? e.data : tco
          );
        }
      })
      .catch((error) => {
        console.error(error)
      });
  };

  searchRequestTicket = (evt, search) => {
    if (search.length > 1) {
      const values = {
        searchAllId: search,
        id_user: Store.getUserLoged().id,
        role: Store.getUserLoged().role,
      };
      this.action
        .execute(
          'post',
          this.APIEndpoints.REPORTEXPORT + '/single/ticket',
          '',
          values
        )
        .then((res) => {
          this.setState({
            allTickets: res.data,
            showTickets: { index: 1, anchorEl: evt.target },
          });

        })
        .catch((err) => console.log(err));
    }
    if (search == 0) {
      this.setState({
        allTickets: [],
        showTickets: { index: 0, anchorEl: null },
      });
    }
  };

  handleTicket = (ticket) => {
    if (ticket.type_ =='request' && ticket.status == 'Approve' ) {
      this.action
        .execute(
          'get',
          this.APIEndpoints.REQUEST + 'one' + '/' + ticket.id_tickets,
          'request'
        )
        .then((g) => {
          this.setState({ requestToApprove: g.data[0] });
        })
        .catch((error) => {
          console.error(error)
        });
    } else  if (ticket.type_ =='request' && ticket.status !== 'Approve')  {
      this.action
        .execute(
          'get',
          this.APIEndpoints.REQUEST + 'one' + '/' + ticket.id_tickets,
          'request'
        )
        .then((g) => {
          const request = g.data[0];
          if (ticket.status == 'Reject') {
            this.setState({ requestToView: request });
          } else {
            this.setState({ requestToCategorize: request });
          }
        })
        .catch((error) => {
          console.error(error)
        });
    } else {
      let ticket_ = {
        id_tickets: ticket.id_tickets,
        real_id: ticket.realid,
        historico: true,
      };
      this.setState({ ticketToShow: ticket_ });
    }
  };

  closeChat = (id_tickets) => {
    Store.tickets_chat_opens = Store.tickets_chat_opens.filter(
      (t) => t.id_tickets != id_tickets
    );
  };

  render() {
    let {
      language,
      language_type,
      profilePhoto,
      new_msg_notifications,
      tickets_chat_opens,
    } = this.store;
    const {params} = this.state
    let { COMMON_PAGE, type: theme_type } = this.store.theme;
    let alert = !!this.store.alerts && !this.state.error;
    let { anchorEl } = this.state;
    //const open = Boolean(anchorEl);
    const open = this.state.open;
    if (alert) {
      setTimeout(
        () => {
          this.store.alerts = '';
        },
        alert.time > 0 ? alert.time : 5000
      );
    }

    var patternList = {
      className: 'drawerIconSize custom-icon-button',
      anchorEl,
      onClose: () => this.handleClose(),
      menuProps: {
        onClick: () => this.handleClose(),
      },
    };
    let p = this.store.getUserLoged().perfil;
    let user = this.store.getUserLoged();

    if (window.location.hostname == 'localhost') {
      window._Store = Store;
    }
    return (
      <div
        id="CommonPage"
        style={{ backgroundColor: this.props.background || '' }}
      >
        { this.store.spinner && <CommonOverlayLoading/> }
        {' '}
        {theme_type == 'grey' && (
          <style>
            {
              "\
            :root{\
               --primary: #6c757d !important;\
               --success: #6c757d !important;\
            }\
            .logoWhite,\
            img[src='/newLogoSensr.png']{\
              filter: grayscale(1);\
            }\
            "
            }
          </style>
        )}
        <MediaQuery maxDeviceWidth={767}>
          {(matches) => (
            <div>
              <AppBar
                zdepth={1}
                titletyle={{
                  textAlign: !this.props.titleCenter ? 'left' : '',
                  height: 'auto',
                }}
                style={{
                  position: 'fixed',
                  top: 0,
                  backgroundColor: '#fff',
                  borderBottom: '2px solid',
                  height: 65,
                  display: 'flex',
                  alignItems: 'center',
                  zIndex: 10,
                  paddingRight: 0,
                  width: '100%',
                }}
                className="colorPrimary"
                id="header"
              >
                <div className="d-flex   justify-content-between">
                  <div className="d-flex justify-content-beween">
                    <div
                      style={{ width: '15%' }}
                      className="d-flex align-items-center"
                    >
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            <strong>{language.MY_EXPERIENCE}</strong>
                          </div>
                        }
                        position="right"
                        theme="dark"
                        arrow={true}
                      >
                        <i
                          className="fas fa-home fas fa-home mr-7 -ml-2 pl-3"
                          onClick={() => this.redirect2('/dash')}
                          style={{ cursor: 'pointer', fontSize: 31 }}
                        />
                      </Tooltip>
                    </div>
                    <div
                      style={{ width: '100%' }}
                      className="d-flex align-items-center"
                    >
                      <SearchInput
                        value={''}
                        onChange={(evt) =>
                          this.searchRequestTicket(evt, evt.target.value)
                        }
                        getAllEvt={true}
                        className="mx-3"
                        placeholder={language.SEARCH_BY_CALL}
                        clearSearch={() => this.searchRequestTicket(null, 0)}
                      />
                      <Popover
                        id="popover-notification-msg"
                        anchorEl={this.state.showTickets.anchorEl}
                        open={!!this.state.showTickets.index}
                        onClose={() =>
                          this.setState({
                            showTickets: { index: 0, anchorEl: null },
                          })
                        }
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        {this.state.showTickets.index == 1 && (
                          <ul>
                            <PerfectScrollbar
                              style={{
                                maxHeight: 400,
                              }}
                            >
                              {this.state.allTickets.map((ticket) => (
                                <li
                                  className="d-flex align-items-center pl-0 py-2"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.handleTicket(ticket)}
                                >
                                  <div className="w-100 ellipsis divTicketsSearch">
                                    <span className="ellipsis text-name">
                                      <strong>{ticket.realid}</strong> -{' '}
                                      {ticket.subject}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </PerfectScrollbar>
                          </ul>
                        )}
                      </Popover>
                    </div>
                  </div>
                  <img
                    src={sessionStorage.getItem('imagePreview')}
                    alt="logo"
                    style={{ height: 52, marginTop: 5, cursor: 'pointer' }}
                    onClick={() => this.redirect2('/dash/modules')}
                  />

                  <div
                    className="commonIconMenu d-flex align-items-center justify-content-end pr-4"
                    style={{ width: '37%' }}
                  >
                    {params.use_approval_center ? (
                      <>
                      <Button
                        variant="circle"
                          label={language.APPROVAL_CENTER}
                        icon={
                          <Badge
                            badgeContent={this.state.totalApprovalCenter}
                            invisible={this.state.totalApprovalCenter == 0}
                            className="badge-indicator-notifications"
                            max={999}
                          >
                            <i class="fas fa-check"></i>{' '}
                          </Badge>
                        }
                        style={{
                          borderRadius: 8,
                          width: 40,
                          marginRight: 4,
                          overflow: 'visible',
                        }}
                        iconProps={{
                          style: { fontSize: 17 },
                        }}
                        onClick={(e) => this.redirect('/dash/approvalcenter')}
                        color="primary"
                      />
                       <Button
                        variant="circle"
                        label={language.CONSULT_APPROVALS}
                        icon={'fas fa-search'}
                        style={{
                          borderRadius: 8,
                          width: 40,
                          marginRight: 4,
                          overflow: 'visible',
                        }}
                        iconProps={{
                          style: { fontSize: 17 },
                        }}
                        onClick={(e) => this.setState({openApprovalCenterHistoric : true})}
                        color="primary"
                      />
                      </>
                    ) : (
                      <>
                        <Button
                          variant="circle"
                            label={language.REQUESTS_TO_APPROVE}
                          icon={
                            <Badge
                              badgeContent={this.state.totalRequestApprove}
                              invisible={this.state.totalRequestApprove == 0}
                              className="badge-indicator-notifications"
                              max={999}
                            >
                              <i class="fas fa-check"></i>{' '}
                            </Badge>
                          }
                          style={{
                            borderRadius: 8,
                            width: 40,
                            marginRight: 4,
                            overflow: 'visible',
                          }}
                          iconProps={{
                            style: { fontSize: 17 },
                          }}
                          onClick={(e) => this.redirect('/dash/requestapprove')}
                          color="primary"
                        />
                        <Button
                          variant="circle"
                          label={language.TASKS_TO_APPROVE}
                          icon={
                            <Badge
                              badgeContent={this.state.totalTask}
                              invisible={this.state.totalTask == 0}
                              className="badge-indicator-notifications"
                              max={999}
                            >
                              <i class="fas fa-calendar-check"></i>{' '}
                            </Badge>
                          }
                          style={{
                            borderRadius: 8,
                            width: 40,
                            overflow: 'visible',
                            marginRight: 4,
                          }}
                          iconProps={{
                            style: { fontSize: 17 },
                          }}
                          onClick={(e) => this.redirect2('/dash/taskapprove')}
                          color="primary"
                        />
                      </>
                    )}
                    <Button
                      icon="fas fa-bell"
                      variant="normal"
                      color="primary"
                      onClick={(v) => this.setState({ openupdate: true })}
                      style={{ marginRight: '20px' }}
                    />
                    {/* {Store.getUserLoged().role == 'client' ? (
                      <UsersLogin
                        Constants={Constants}
                        id={Store.getUserLoged().id}
                        name={Store.getUserLoged().name}
                        socket={Store.socket}
                      ></UsersLogin>
                    ) : null} */}
                    <Button
                      icon="fas fa-exchange-alt"
                      label={
                        this.store.theme.type == 'grey'
                          ? language.SWITCH_TO_DEFAULT
                          : language.TOGGLE_TO_GRAY
                      }
                      style={{ marginLeft: '30px' }}
                      variant="normal"
                      color="primary"
                      className="mr-3"
                      onClick={() => {
                        if (this.store.theme.type == 'grey') {
                          this.store.handleTheme();
                        } else {
                          this.store.handleTheme('grey');
                        }
                      }}
                    />
                    {
                      <Notification
                        new_msg_notifications={new_msg_notifications}
                        openMsg={this.openMsg}
                        language={language}
                        role={user.role}
                        userid={user.id}
                      />
                    }
                    {/* <Tooltip
                      html={
                        <div style={{ color: '#fff', fontSize: 15 }}>
                          <strong>{language.LANGUAGE}</strong>
                        </div>
                      }
                      position="bottom"
                      theme="dark"
                      arrow={true}
                    >
                      <div
                        onClick={() =>
                          this.store.handleLanguages(
                            language_type === 'PT_BR'
                              ? 'EN'
                              : language_type === 'EN'
                              ? 'ES'
                              : language_type === 'ES'
                              ? 'PT_BR'
                              : 'PT_BR'
                          )
                        }
                        className="d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src={
                            language_type === 'PT_BR'
                              ? FlagBr
                              : language_type === 'EN'
                              ? FlagEua
                              : FlagSpain
                          }
                          alt={language_type}
                          style={{
                            width: 30,
                          }}
                        />
                      </div>
                    </Tooltip> */}
                    <IconButton
                      color="secondary"
                      style={{
                        fontSize: 15,
                        marginRight: 25,
                        backgroundColor: 'transparent',
                      }}
                      className="userIconMenu"
                      aria-owns={anchorEl ? 'USER' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => this.handleClick(event, 'USER')}
                      aria-label="Add an alarm"
                    >
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            <strong>{this.store.getUserLoged().name}</strong>
                          </div>
                        }
                        position="bottom"
                        theme="dark"
                        arrow={true}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              style={{
                                background: 'white',
                                border: '1.9px solid var(--primary)',
                                width: 40,
                                height: 40,
                                ...(profilePhoto ? { borderWidth: 0 } : {}),
                              }}
                              src={profilePhoto}
                            >
                              <div
                                className="clientIcon iconMenuClient"
                                style={{ width: '100%', height: '100%' }}
                              />
                            </Avatar>
                          }
                        />
                      </Tooltip>
                    </IconButton>
                    I
                    <Menu
                      id="USER"
                      anchorEl={anchorEl}
                      open={open == 'USER' ? true : false}
                      onClose={this.handleClose}
                    >
                      <MenuItem
                        className="py-4"
                        onClick={() => this.redirect2('/dash/changeData')}
                      >
                        <IconButton
                          className="changeDataIcon"
                          style={{ width: 30, height: 30, marginRight: 9 }}
                        />
                        {language.CHANGE_DATA}{' '}
                      </MenuItem>

                      <MenuItem
                        className="py-4"
                        onClick={() =>
                          this.redirect2('/dash/changePass', { role: 'out' })
                        }
                      >
                        <IconButton
                          className="changePassIcon"
                          style={{ width: 30, height: 30, marginRight: 9 }}
                        />
                        {language.CHANGE_PASSWORD}{' '}
                      </MenuItem>

                      <MenuItem
                        className="py-4"
                        onClick={() => this.logoutApp()}
                      >
                        <IconButton
                          className="logoutIcon"
                          style={{ width: 30, height: 30, marginRight: 9 }}
                        />
                        {language.LOGOFF}{' '}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </AppBar>
              <MenuDefault
                language={language}
                redirect={this.redirect}
                redirect2={this.redirect2}
                perfil={p}
                slideBarTheme={COMMON_PAGE.slideBar}
                user={user}
              >
                {/* {' '} */}
                <div
                  style={{
                    width: `calc(100% - ${matches ? 0 : 35}px`,
                    marginLeft: matches ? 0 : 'auto',
                    marginRight: matches ? 0 : 'auto',
                    paddingBottom: 10,
                    marginTop: 62,
                  }}
                >
                  {this.props.children}
                </div>
              </MenuDefault>
            </div>
          )}
        </MediaQuery>
        <ProgressLoading loading={this.store.loading} />
        {this.state.ticketToShow != undefined ? (
          <Dialogs
            title="Ticket"
            open={!!this.state.ticketToShow}
            maxWidth="xl"
            onClose={() => this.setState({ ticketToShow: undefined })}
          >
            <Suspense fallback={<div>{language.LOAD}</div>}>
              <TicketManagement
                detail={true}
                id_ticket_detail={this.state.ticketToShow}
              ></TicketManagement>
            </Suspense>
          </Dialogs>
        ) : null}
         {this.state.openApprovalCenterHistoric == true ? (
          <Dialogs
            title={language.APPROVAL_CENTER}
            open={true}
            maxWidth="xl"
            onClose={() => this.setState({ openApprovalCenterHistoric: false })}
          >
            <Suspense fallback={<div>{language.LOAD}</div>}>
              <ApprovalCenterHistoric single = {true} />
            </Suspense>
          </Dialogs>
        ) : null}
        {this.state.requestToApprove != undefined ? (
          <RequestApproval
            id={this.state.requestToApprove.id_request}
            request={this.state.requestToApprove}
            language={language}
            redirect2={this.redirect2}
            onFormSubmit={this.onFormSubmitRequest}
            onFormSubmitAprove={this.onFormSubmitAproveRequest}
            onCloseApprove={() =>
              this.setState({ requestToApprove: undefined })
            }
          />
        ) : null}
        {this.state.requestToView != undefined ? (
          <RequestView
            language={language}
            solicit={
              this.store.getUserLoged().role == 'solicitant' ? true : false
            }
            onClose={() => this.setState({ requestToView: undefined })}
            store={this.store}
            request={this.state.requestToView}
            redirect2={this.redirect2}
            ondonwFile={this.ondonwFileRequest}
          />
        ) : null}
        {this.state.requestToCategorize != undefined ? (
          <Suspense fallback={<div>{language.LOAD}</div>}>
            <DialogFormTicket
              store={this.store}
              onFormSubmitTab={this.onFormSubmitTabRequest}
              open={!!this.state.requestToCategorize}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              ondonwFile={this.ondonwFileRequest}
              values={this.state.requestToCategorize}
              onClose={() =>
                this.setState({
                  requestToCategorize: undefined,
                  allTickets: [],
                })
              }
            />
          </Suspense>
        ) : null}
        <Dialogs
          title={language.UPDATES}
          open={this.state.openupdate}
          maxWidth="md"
          onClose={() => this.setState({ openupdate: false })}
        >
          <Build action={this.action} APIEndpoints={this.APIEndpoints}></Build>
        </Dialogs>
        <Dialog
          PaperProps={{
            style: { backgroundColor: 'rgba(0, 0 , 0, 0)', boxShadow: 'none' },
            zdepth: 0,
          }}
          actions={false}
          modal={true}
          open={
            !!this.store.loading &&
            [undefined, true].includes(this.props.loading)
          }
        >
          <div className="loadGroup">
            <h3>
              <FaSpinner className="loading" />
            </h3>
            <h3 className="font" style={{ fontWeight: 100, fontSize: 20 }}>
              {this.props.msnloading || language.LOAD}
            </h3>
          </div>
        </Dialog>
        <SimplesAlert
          float
          open={this.store.alert.open}
          message={this.store.alert.message}
          variant={this.store.alert.variant}
          onClose={() => this.store.toggleAlert(false)}
        />
        {tickets_chat_opens
          .slice(
            tickets_chat_opens.length > 2 ? tickets_chat_opens.length - 2 : 0,
            tickets_chat_opens.length
          )
          .map((ticket, i) => (
            <FloatChat
              groupstech={this.store.groupstech}
              language={language}
              index={i}
              redirect2={this.redirect2}
              key={i}
              role={this.store.getUserLoged().role}
              ticket={ticket}
              selectTypeMsg={ticket.selectTypeMsg}
              comments={ticket.comments}
              loading={this.store.$btn_loading(
                'float-chat-' + ticket.id_tickets
              )}
              closeChat={this.closeChat}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              statusTicket={ticket.status}
              maxHeightChat={383}
              redirect={this.redirect}
              type_ticket={[
                null,
                this.store.getUserLoged().role == 'tech'
                  ? 'tech'
                  : 'solicitant',
              ]}
            />
          ))}
      </div>
    );
  }
}
