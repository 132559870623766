import React, {memo} from 'react';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import {Grid} from '@material-ui/core';
import { MaskMoney, MaskNumber } from '@common/Mask';
import {flexBasis} from '@common/Func';
import lodash from 'lodash';
import { Fragment } from 'react';
import Store from '@data/Store';

const { language } = Store;

const MyHeader = ({
	title,
	subTitle,
	className
})=>(
	<AppBar
		contentProps={{
			className:'pl-0 '+className
		}}
		titleProps={{
			style:{color:'var(--primary)'}
		}}
		title={title}
		subTitle={subTitle}
	/>
)
export default memo(({
   options,
   values,
   setForm,
   h,
   formValues
})=>(
   <div className='wrap mb-3'>
      
      <AppBar title={language.BASIC_INFO} />
      <div className='content'>
         <CommonForm
            onRef={r => setForm(r)}
            values={values}
            fields={[
               {
                  col:4,
                  type:'element',
                  name:'type_project',
                  required:true,
                  beforeContent:(
                     <MyHeader
                        title={language.TYPE_PROJECT}
                        subTitle={language.SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_PROJECT}
                     />
                  ),
                  content:(v, h, submit, error)=>(
                     <Fragment>
                        <Grid container spacing={2} className='content-button'>
                           {
                              [
                                 {
                                    value:3, 
                                    icon:'fas fa-tools', 
                                    label: language.CORRECTIVE,
                                    color:'var(--primary)'
                                 },
                                 {
                                    value:1, 
                                    icon:'fas fa-chess', 
                                    label: language.STRATEGIC,
                                    color:'var(--dark)'
                                 },
                                 {
                                    value:4, 
                                    icon:'fas fa-chart-line',
                                    label: language.EVOLUTIONARY,
                                    color:'var(--warning)'
                                 },
                                 {
                                    value:5, 
                                    icon:'fas fa-shield-alt',
                                    label: language.LEGAL_OBLIGATION,
                                    color:'var(--danger)'
                                 },

                                 {
                                    value:2, 
                                    icon:'fas fa-chart-line',
                                    label: language.IMPROVEMENT,
                                    color:'var(--purple)'
                                 },
                                 
                              ].map(e=>(
                                 <Grid 
                                    key={e.icon} 
                                    item xs={2.4} 
                                    style={{'--color':e.color, ...flexBasis(2.4)}} 
                                    className='content-item'
                                 >
                                    <div onClick={() => h(e.value, 'type_project')} className={v==e.value?'active':''}>
                                       <i className={e.icon}/>
                                       <span>{e.label}</span>
                                    </div>
                                 </Grid>
                              ))
                           }
                        </Grid>
                        {error?(
                           <span className='error-label ml-1'>{error}</span>
                        ):null}
                     </Fragment>
                  )
               },
               {
                  col:4,
                  required:true,
                  beforeContent:(
                     <MyHeader
                        title={language.COMPANY}
                        subTitle={language.SELECT_THE_COMPANY_INVOLVED_IN_THE_PROJECT}
                     />
                  ),
                  type:'select',
                  name:'fk_id_company',
                  label: language.COMPANY,
                  options:options.company()
               },
               {
                  col:4,
                  required:true,
                  beforeContent:(
                     <MyHeader
                        title={language.CONTRACT_MANAGEMENT}
                        subTitle={language.SELECT_WHICH_CONTRACT_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT}
                     />
                  ),
                  type:'select',
                  name:'fk_id_contract',
                  label: language.CONTRACT,
                  options:options.contract(e =>
                     formValues.fk_id_company ==1? 
                      
                     e.fk_id_company == formValues.fk_id_company:
                     e.fk_id_out_company == formValues.fk_id_company
                  )
               },
               
               /*{
                  col:4,
                  beforeContent:(
                     <MyHeader
                        className='-mt-3'
                        title='Custo REALIZADO'
                        subTitle='Informe o custo realizado neste projeto.'
                     />
                  ),
                  type:'text',
                  label:'Custo Realizado',
                  name:'realized',
               },*/
            ]}
            onChangeField={(f,v)=>{
               if(f.name=='cust_project'){
                  v = v.replace('.', ',')
               }
               h(v, f.name);
            }}
         />
      </div>
   </div>
), function(prev, next){
   
   let itemsPrev = {fk_id_contract:prev.formValues.fk_id_contract, fk_id_company:prev.formValues.fk_id_company};
   let itemsNext = {fk_id_contract:next.formValues.fk_id_contract, fk_id_company:next.formValues.fk_id_company};
   
   return lodash.isEqual(itemsPrev, itemsNext);
})