import React, {useState, useEffect} from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import classnames from 'classnames';
import Store from '@data/Store';

import './index.css'


const DialogAvaliation = ({
    values, 
    open, 
    onClose,
    action,
    APIEndpoints
}) => {
    const [title, setTitle] = useState("Carregando...")
    useEffect(() => {
        if (values.keys) {
            setTitle(values.keys)
        }
    }, [values])

    const handleSubmit = async (v) => {
        let { service_time, satisfaction } = v;

        let point = (service_time + satisfaction) / 2;
        point = parseInt(point);

        try {
            const response = await action.execute('put', APIEndpoints.OKR + `/results/${values.id}/avaliation`, null, { point }, null, 'Avaliação computada com sucesso');

            if (response.msg) {
                Store.toggleAlert(true, response.msg, 'info');
                onClose();
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <Dialog
        PaperProps={{
		    id: 'DialogFormAvaliation'
		  }}
		  open={open}
		  onClose={onClose}
		  title={`Key Result: ${title}`}
        >
            <CommonForm 
                fields={[
                    {
                        col:12,
                        type:'element',
                        name:'service_time',
                        required:true,
                        content:(v, handleValue)=>(
                            <div className='w-100 mb-3 mt-2'>
                                <span className='title-label d-block w-100' style={{ textAlign: 'center' }}>
                                    Tempo de atendimento foi adequado? Dê uma nota para ele.
                                </span>
                                <div className='content-star mt-1 d-flex align-items-center w-100 justify-content-center'>
                                    {[1,2,3,4,5].map(e=>(
                                        <i 
                                            className={classnames(e>(v||0)?'far fa-star':'fas fa-star', 'star-icon')} 
                                            onClick={()=>handleValue(e, 'service_time')}
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    },
                    {
                        col:12,
                        type:'element',
                        name:'satisfaction',
                        required:true,
                        content:(v, handleValue)=>(
                            <div className='w-100 mb-3'>
                                <span className='title-label d-block w-100' style={{textAlign:'center'}}>Qual sua satisfação sobre o resultado da entrega?</span>
                                <div className='content-item mt-1 d-flex align-items-center w-100 justify-content-center'>
                                    {[1,2,3,4,5].map(e=>(
                                        <i 
                                            className={classnames('fas fa-trophy', 'trophy-icon', {active:v>=e})} 
                                            onClick={()=>handleValue(e, 'satisfaction')}
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    },
                ]}

                onSubmit={handleSubmit}
            />
        </Dialog>
    )
}

export default DialogAvaliation