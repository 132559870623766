import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Store from '@data/Store';
import Constants from '@data/Constants';
import { randomId, spFetch, useServicePortalStyles } from '../helpers';
import moment from 'moment';
import { ChatMessage, MessageLeft } from './Messages';
import { Send } from './Search';
import { Grid, Typography } from '@material-ui/core';
import ServicePortalTicketSubTitle from './ServicePortalTicketSubTitle';
import { EmptyGrid, EmptyResult } from './EmptyState';

export default function ServicePortalChatMessages({ ticketData, filters, language, isReadonly }) {

    const styles = useServicePortalStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [cleanSendInput, setCleanSendInput] = useState();

    const scrollContainer = useRef(null);

    const socketUrl = 'chat-ticket-' + Store.getTenant;

    useEffect(() => {
        if (ticketData._isWorkable) {
            Store.socket.on(socketUrl, () => {
                reloadMessages(ticketData.id_tickets)
            });
        }

        setMessages(ticketData.comments || []);
    }, [, ticketData.comments])

    useEffect(() => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight ?? 0;
        }
    }, [messages])

    const reloadMessages = (ticketId) => {
        spFetch(Constants.APIEndpoints.TICKET + '/' + ticketId)
            .then(({ data }) => {
                setMessages(data.comments || []);
            });
    };

    const handleSendChatMessage = (message) => {
        setIsLoading(true);

        const data = {
            action: 'reply',
            id_ticket: ticketData.id_tickets,
            id_user_sponsor: ticketData.fk_id_user_sponsor,
            id_user: ticketData.fk_id_user,
            destino: ticketData.config[0].fk_id_tech,
            type: 'solicitant',
            description: message
        }

        Store
            .$emit(socketUrl, null, data)
            .then((data) => {
                const _messages = [...messages];
                _messages.push(data);
                setMessages(_messages);
                setCleanSendInput(randomId());
            })
            .catch((err) => console.error(err))
            .finally(() => setIsLoading(false));
    }


    const getMessages = () => {
        let _messages = [...messages];
        if (filters) {
            if (Array.isArray(filters.ticketTypeAllowed)) {
                _messages = _messages.filter((item) => filters.ticketTypeAllowed.includes(item.type))
            }
        }

        _messages = _messages.filter((item) => item.fk_id_tickets == ticketData.id_tickets);

        _messages.forEach(item => item.right = item.fk_id_user === ticketData.fk_id_user);

        return _messages ?? [];
    }

    const handleCheckLink = (value) => {
        const regex = /(http:\/\/|https:\/\/)(w{3}\.)?([a-zA-Z0-9]+)(.\w+)?(\.com)(.+)?/gm;
        const result = regex.exec(value);

        if (result) {
            let link = result[0].split(' ')[0].replace(/<\/p>|\s.rel+/g, '');

            if (link.includes('</a>')) {
                link = link.replace('</a>', '');
            }

            const newLink = `<a href="${link}" target="_blank">${link}</a>`;
            const newValue = value.replace(link, newLink);

            return newValue;
        }

        return value;
    };

    const handleCheckImage = (value) => {
        const result = value.search('<img');

        if (result == -1) {
            value = value.replace(/\\/g, '&#92;');
            value = value.replace(/["]+/g, '&#34;');
        }

        return value;
    }

    const handleSendMessage = (message) => {

        let messageSwap = handleCheckImage(message);
        messageSwap = handleCheckLink(messageSwap);

        handleSendChatMessage(messageSwap)
    }

    const exchangeMessages = getMessages();

    return (
        <>
            <ServicePortalTicketSubTitle label={language.COMMENT} iconText='fa-comment-dots' />
            <div className='mb-2 tw-border tw-rounded tw-p-2 tw-h-72'>
                <div className='tw-flex tw-flex-col tw-gap-2 tw-h-full'>
                    <div className='tw-flex-grow tw-overflow-y-auto' ref={scrollContainer} style={{ scrollBehavior: 'smooth' }}>
                        {exchangeMessages.map(message => {
                            return (
                                <ChatMessage
                                    key={moment(message.dt_cad).valueOf()}
                                    avatar={''}
                                    timestamp={moment(message.dt_cad).format('L LT')}
                                    displayName={message.name_create}
                                    side={message.right ? 'right' : 'left'}
                                    messages={message.description}
                                />
                            )
                        })}

                        {Array.isArray(exchangeMessages) && exchangeMessages.length === 0 && (
                            <EmptyResult language={language} />
                        )}
                    </div>
                    {!isReadonly && !ticketData._isFinished &&
                        <Send
                            label={language.SEND}
                            isLoading={isLoading}
                            cleanSendInput={cleanSendInput}
                            onClick={handleSendMessage}
                        />}

                </div>
            </div>
        </>
    )
}
