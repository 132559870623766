import React, { useState, useReducer, useEffect } from 'react';
import './index.css';
import { Card, Grid } from '@material-ui/core';
// --------------------common--------------------------
import PageLayout from '@common/PageLayout';
import Button from '@common/Button';
import PieChart from '@common/PieChart';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import Loading from '@common/Loading';
import moment from 'moment'
import Header from '@common/Header';
// ----------------------------------------------------
import { completed_activities, maturityLevel, testesEvolutions } from './Graphs';
import ReactHighcharts from 'react-highcharts';
import { translateFromPtBR } from '../../../common/languages/Dictionary';

export default function ({
	language,
	handleCompany,
	handlePage,
	companies,
	companyId,
	maturity,
	history,
	tests,
	classIcon,
	user_companies,
	UserLoged,
	redirect2,
	risk,
	action,
	APIEndpoints
}) {
	let companiesSelect = companies
		.filter(c => UserLoged.role == 'tech' ? user_companies[0].company.includes(c.id_company) : (c.type !== 2))
		.map(c => ({ value: c.id_company, label: c.name }));

	if (!tests.length) {
		return <Loading loading={true} />;
	}
	let [dash, setDash] = useState([]);
	let [dadosPeriodos, setdadosPeriodos] = useState([]);
	let [year, setYear] = useState(moment().format('YY'));

	useEffect(() => {
		action.execute(
			'post',
			APIEndpoints.RISK + '/dashrisk',
			'usercon_company',
			{ standardId: risk, companyId: companyId }, null, null, null
		).then(b => {
			setDash(b.data)
		})
		action.execute(
			'get',
			APIEndpoints.RISK + '/maturitymonth/' + companyId + '/stand/' + risk,
			'maturitys'
		).then(b => {

			setdadosPeriodos(b.data)
		})
	}, [companyId]);



	let mat = 0;
	let data = [];




	let sumTotalmat = 0;
	let sumlinha = 0;

	let dttt = [];
	dash = dash.filter(h => parseInt(moment(h.execution_date).format('YY')) == year);
	try {
		tests[0].dash.map((t, i) => {

			maturity
				.filter(v => v.id_section == t.id)
				.map(g => {
					sumlinha = sumlinha + parseInt(g.totallinha);
					if (g.current_maturity === null) {
						g.current_maturity = 0;
					}
					sumTotalmat = sumTotalmat + parseInt(g.current_maturity);

					dttt.push({
						subject: t.area,
						A: parseInt((g.current_maturity / g.total) * 100),
						B: parseInt((g.planned_maturity / g.total) * 100),
						fullMark: g.planned_maturity
					});
				});

		});


		sumlinha = (sumTotalmat / sumlinha).toFixed(1);


	} catch (e) { }

	let done = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	let late = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];



	[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((g, i) => {
		dash.map(h => {
			if (parseInt(moment(h.execution_date).format('M')) == g
				&& parseInt(moment(h.execution_date).format('YY')) == year
			) {
				if (h.progress == '100') {
					done[g - 1] = done[g - 1] + 1;
				} else {
					late[g - 1] = late[g - 1] + 1;
				}

			}


		})

	})

	let mp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	let ma = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((g, i) => {
		dadosPeriodos.filter(item => item.year == year).map(h => {
			if (parseInt(h.month) == g) {
				mp[i] = parseInt(h.mdesejada)
				ma[i] = parseInt(h.matual)

			}
		})

	})

	return (
		<PageLayout
			subTitle={`${language.RISKS_MANAGEMENT_COMPLIANCE} /`}
			title={translateFromPtBR(tests[0].name, language)}
			rightElements={[
				<div style={{ width: 300, zoom: 0.8, marginTop: '1rem' }} zoom={0.8}>
					<CommonForm
						value={{ Ano: year }}
						fields={[
							{
								col: 6,
								type: 'select',
								name: language.YEAR,
								label: language.YEAR,
								options: [
									{ value: moment().format('YY'), label:  moment().format('YYYY') },
									{ value: moment().subtract(1, 'year').format('YY'), label:  moment().subtract(1, 'year').format('YYYY') },
									{ value: moment().subtract(2, 'year').format('YY'), label:  moment().subtract(2, 'year').format('YYYY') },

								]
							},
							{
								col: 6,
								type: 'select',
								name: 'company',
								label: language.COMPANY,
								options: companiesSelect
							}

						]}
						values={{ company: companyId }}
						onChangeField={(f, v) => {
							if (f.name == 'company') {
								handleCompany(v)
							} else {
								setYear(v)
							}
						}}
					/>
				</div>
			]}
		>
			<div id='RiskTestDashboard'>
				<Header className='d-flex align-items-center content py-0'>
					<Button
						color="warning"
						icon='fas fa-print'
						label={language.PRINT}
						className='mr-2'
						onClick={() => window.print()}
					/>
					<Button
						color="primary"
						icon='fas fa-cogs'
						label={language.CONFIG}
						onClick={() => redirect2(history.location.pathname + '/config', { id_company: companyId, risk: risk, name: tests[0].name })}
					/>
				</Header>
				<Card className='mb-3 content'>
					<Grid container spacing={16} className='block-1'>
						<Grid item xs={4} className='content-grid-1'>
							<Card className='h-100'>
								<AppBar
									title={language.ACTIVITIES_IN_DONE}
								/>
								<ul className='my-5'>
									<li className='px-4 py-1 d-flex align-items-center justify-content-between'>
										<span>{language.TOTAL_ACTIVITIES_COMPLETED}</span>
										<div>
											<span>{dash.length}</span>/<span>{dash.filter(v => v.progress == '100').length}</span>
										</div>
									</li>
								</ul>
								<Grid container spacing={2} className='graphs content'>
									{[
										{ label: language.CONCLUDED, value: (((dash.filter(v => v.progress == '100').length / dash.length) * 100)).toFixed(0) },
										{ label: language.PLANNED, value: (((dash.filter(v => v.progress != '100').length / dash.length) * 100)).toFixed(0) },
									].map((e, i) => (
										<Grid item xs={6} key={e.label} className='content-graph pl-3'>
											<div className='d-flex align-items-end'>
												<div>
													<PieChart
														bgStrokeWidth={4}
														strokeWidth={3}
														color={i == 0 ? 'warning' : 'danger'}
														percent={e.value}
														text={false}
													/>
												</div>
												<div className='ml-2'>
													<h3>{isNaN(e.value) ? 0 : e.value}%</h3>
													<span style = {{'marginTop': 5}}>{e.label}</span>
												</div>
											</div>
										</Grid>
									))}
								</Grid>
							</Card>
						</Grid>
						<Grid item xs={8}>
							<Card>
								<AppBar
									title={language.ACTIVITIES_IN_DONE}
								/>

								<ReactHighcharts
									config={completed_activities({
										categories: language.DATE_LANG.monthLongList,
										done: done,
										late: late
									})}
								/>
							</Card>
						</Grid>
					</Grid>
				</Card>
				<Header />
				<Card className='content'>
					<Grid container spacing={16} className='block-2'>
						<Grid item xs={6} className='content-grid-1'>
							<Card className='h-100'>
								<AppBar
									title={language.MATURITY_LEVEL}
								/>
								<div className='h-100 d-flex aling-items-center justify-content-center polar-graph'>
									<ReactHighcharts
										config={maturityLevel({
											language,
											categories: dttt.map(e => e.subject),
											PLANNED_MATURITY: dttt.map(e => (!!e.B ? parseInt(e.B) : 0)),
											CURRENT_MATURITY: dttt.map(e => (!!e.A ? parseInt(e.A) : 0))
										})}
									/>
								</div>
							</Card>
						</Grid>
						<Grid item xs={6} className='content-grid-2'>
							<Card>
								<AppBar
									title={language.EVOLUTION_OF_TESTS}
								/>
								<ReactHighcharts
									config={testesEvolutions({
										language,
										evolution: ma,
										desired_maturity: mp
									})}
								/>
							</Card>
						</Grid>
					</Grid>
				</Card>
			</div>

		</PageLayout>
	)
}