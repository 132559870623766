import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonPage from '../../common/CommonPage';
import CommonTable from '../../common/CommonTable';
import { Radial } from 'sensrit-charts';
import { FaQuestionCircle, FaClose } from 'react-icons/lib/fa';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { filterObj } from '@common/Func';

@observer
export default class StockFormHistory extends CommonPage {
  constructor() {
    super();
    this.state = {
      open: false,
      id: 0,
      id_dev: 0,
      sponso: ''
    };
  }
  componentWillMount() {

    if (this.props.value) {
      this.action.execute('get', this.APIEndpoints.STOCKIN + "/stockinallItem/" + this.props.value.id_device, 'stockinlist');
      this.action.execute('put', this.APIEndpoints.STOCKOUT + "/sponso/" + this.props.value.id_device, 'spon', null, null, null, null);
    }
  }
  handleModalNew() {
    this.setState({ open: false });
  }

  onClose() {
    this.setState({
      sponso: ''
    });
  }

  handleModal(obj) {
    //console.log("Sponso "+obj.sponso);
    //console.log("Sponso "+this.props.id_user);
    if (parseInt(this.props.id_user) === parseInt(obj.sponso)) {
      this.setState({
        open: !this.state.open,
        id: obj.id_stockout,
        id_dev: obj.id_device
      });
    } else {
      this.setState({ sponso: 'error' });
    }
  }
  // http://localhost:3000/api/stockout/sponso/5

  onFormSubmitHistory(values) {
    //this.props.action.execute('get', this.props.APIEndpoints.DEVICE, 'devices');
    let data = { active: true };
    this.props.action.execute(
      'put',
      this.props.APIEndpoints.STOCKOUT + '/' + values
    );
    this.setState({ open: false });
    this.props.action.execute(
      'get',
      this.props.APIEndpoints.STOCKIN + '/stockinallItem/' + this.state.id_dev,
      'stockinlist'
    );
  }

  render() {
    let { language, handlePage, redirect, onDelete, id_user } = this.props;
    let { open, sponso } = this.state;

    let confirmationApproveDialog = (
      <Dialog open={open} onClose={() => { this.handleModal; this.handleModalNew() }}
        title={language.CONFIRMATION}
      >

        <h2>{language.WHANT_TO_APPROVE}</h2>
        <Row>
          <Col md={6}>
            <RaisedButton
              label={language.YES}
              onClick={() => this.onFormSubmitHistory(this.state.id)}
              primary
              fullWidth
            />
          </Col>
          <Col md={6}>
            <RaisedButton
              onClick={() => this.handleModalNew()}
              primary
              buttonStyle={{ background: 'var(--secundary)' }}
              label={language.CANCEL}
              fullWidth
            />
          </Col>
        </Row>
      </Dialog>
    );

    let col = [
      {
        key: 'name',
        label: language.NAME,
        style: { width: 10, textAlign: 'center' }
      },
      {
        key: 'model',
        label: language.MODEL,
        style: { width: 20, textAlign: 'center' }
      },
      {
        key: 'username',
        label: language.USER,
        style: { width: 30, textAlign: 'center' }
      },
      {
        key: 'dt_cad',
        label: language.DATE_CAD,
        style: { width: 30, textAlign: 'center' }
      },
      {
        key: 'qtdinput',
        label: language.QUANTITY,
        style: { width: 10, textAlign: 'center' }
      },
      {
        key: 'type',
        label: language.TYPE,
        style: { width: 10, textAlign: 'center' }
      },
      {
        key: 'additional_information',
        label: language.ADDITIONAL_INFORMATION,
        style: { width: 10 }
      },
      {
        key: 'movimentacao',
        label: language.MOVIMENT,
        style: { width: 10, textAlign: 'center' }
      },
      {
        key: 'responsavel',
        label: language.STOCK_SPONSO,
        style: { width: 10, textAlign: 'center' }
      },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 10, textAlign: 'center' }
      }
    ];

    let data = this.props.store.stockinlist.map(obj => {
      let item = (
        <RadialGraph
          title="Semana"
          amount={Math.round(obj.qtdinput)}
          color="var(--primary)"
        />
      );

      return {
        name: obj.name,
        model: obj.model === '' ? language.NOTHING_REGISTERED : obj.model,
        username: obj.username,
        dt_cad: moment(obj.dt_cad).format('L'),
        qtdinput: item,
        type: (
          <div className="actionIcons">
            {obj.type === 'server' ? (
              <div
                className="serverIcon"
                data-tip={language.SERVER}
                style={{ height: 25, width: 25 }}
              />
            ) : (
              obj.type
            )}
            <ReactTooltip type="info" />
          </div>
        ),

        additional_information: <span dangerouslySetInnerHTML={{ __html: obj.additional_information || obj.description }} />,
        movimentacao: (
          <div className="actionIcons">
            {obj.tpi === 'in' ? (
              <div
                data-tip={language.INPUT}
                className="upIcon"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              <div
                data-tip={language.OUTPUT}
                className="downIcon"
                style={{ width: 25, height: 25 }}
              />
            )}
            <ReactTooltip type="info" />
          </div>
        ),
        responsavel: obj.sponsoname,
        action: (
          <div className="actionIcons">
            <div
              onClick={() => redirect('/dash/stock/historic/detail', filterObj(obj))}
              className="detailIcon pointer"
              style={{ width: 25, height: 25 }}
              data-tip={language.DETAILS}
            />
            <div>
              {obj.tpi === 'out' &&
                obj.active === false &&
                obj.reason !== 'replacement' ? (
                <FaQuestionCircle
                  onClick={() => this.handleModal(obj)}
                  className="pointer"
                  color="var(--red)"
                  fontSize={21}
                  data-tip={language.APPROVE}
                  style={{ marginTop: 3 }}
                />
              ) : (
                <div
                  className="finishedStatusIcon"
                  style={{ width: 25, height: 25 }}
                  data-tip={language.APPROVED}
                />
              )}
              <ReactTooltip />
            </div>
            <ReactTooltip />
          </div>
        )
      };
    });

    return (
      <PageLayout
        icon={
          <div
            className="stockIcon"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.HISTORIC_LIST}
        subTitle='Gestão de demandas/gestão de estoque'
        rightElements={[]}
      >

        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index='StockFormHistory'
          searchColumn orderColumn
        />

        {this.state.sponso === '' ? (
          confirmationApproveDialog
        ) : (
          <Dialog modal={false} open={true}>
            <h3>
              {language.ALERT_CONTRACTOR_APROVE_REQUEST}
            </h3>

            <RaisedButton
              label={language.CLOSE}
              primary={true}
              style={{ marginRight: 10 }}
              onClick={() => this.onClose('')}
            />
          </Dialog>
        )}
      </PageLayout>
    );
  }
}

class RadialGraph extends Component {
  render() {
    return (
      <div className="radialGraphDefault">
        <Radial
          low={{ color: '#33ABA0' }}
          mid={{ color: '#FFC107', value: 10 }}
          high={{ color: this.props.color, value: 10 }}
          className="host-graph-perc"
          size={44}
          current={this.props.amount}
          total={100}
          thick={8}
        >
          <h4>{this.props.amount}</h4>
        </Radial>
      </div>
    );
  }
}
