import React,{useState,useEffect,useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@common/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
 import './index.css'
 import Avatar from '@material-ui/core/Avatar';
 import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import { SrContextApi } from '../../common/Context';
import InfiniteScroll from 'react-infinite-scroll-component';
import Constants from '../../data/Constants';
import Actions from '../../data/Actions'
const Action = new Actions()
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },

    },
  },
}))(MenuItem);

const UsersOnlineFunc=(props)=> {
  const [anchorEl, setAnchorEl] =  useState(null);
  let[list,setList]=useState([]);
  const [users, setUsers] = useState([])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const modalCtx =  useContext(SrContextApi);
  const handleClose = () => {
    setAnchorEl(null);
  };
useEffect(() => {
  Action.execute('get', Constants.APIEndpoints.USER + '/getAllUsersLogged', '').then(res => {
    setUsers(res.data)
  })
}, [])
  
/*
useEffect(()=>{
 
  setList(modalCtx.list)
   
 },[modalCtx.list])
*/
 
  return (
    <div id="UserOnline">
<Badge badgeContent={users.count} max={99999} color="primary">
   <Button
      icon="fas  fa-user"
      variant="normal"
      color="primary"
      /*onClick={handleClick} */
    />
</Badge>
     <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        /*onClose={handleClose}*/
      >
       <div></div>

        <StyledMenuItem>
        <div style={{display:'flex',width:'200px'}}>
         <Avatar alt="Remy Sharp" src={`${props.Constants.APIRoot}/public/users/${props.id}/profileLogo.png`} />
         <div  id="online_status_user"> {props.name} </div>
         <span id="status_span_on"></span>
         </div>
        </StyledMenuItem>
         
       {/*list.filter(v=>v.id !=props.id).slice(0,30).map(v=> <StyledMenuItem key={v.id}>
        <div style={{display:'flex',width:'200px' }}>
         <Avatar alt="Remy Sharp" src={`${props.Constants.APIRoot}/public/users/${v.id}/profileLogo.png`} />
         <div  id="online_status_user"> {v.name} </div>
         <span id="status_span_on"></span>
         </div>
        </StyledMenuItem>)
       */}
      
    </StyledMenu>
    </div>
  );
}
export default React.memo(UsersOnlineFunc);