import Actions from '../../../data/Actions';
import Constants from '../../../data/Constants';
import Store from '../../../data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';

const action = new Actions();
const { language } = Store;

let CACHE_STATUS_AWAITING = null;
export const getStatusAwaiting = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_STATUS_AWAITING) return resolve(CACHE_STATUS_AWAITING);

    action
      .execute('get', Constants.APIEndpoints.STATUS_AWAITING)
      .then((result) => {
        const data = (result?.data || []).map((item) => ({
          value: item.id_status,
          label: item.status,
        }));

        CACHE_STATUS_AWAITING = data;
        return resolve(CACHE_STATUS_AWAITING);
      });
  });
};

let CACHE_STATUS_TICKET = null;
export const getStatusTicket = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_STATUS_TICKET) return resolve(CACHE_STATUS_TICKET);

    action
      .execute('get', Constants.APIEndpoints.TABLE_STRUCTURED + '/10')
      .then((result) => {
        const data = (result?.data || []).map((item) => ({
          value: item.description,
          label: item.description,
        }));
        CACHE_STATUS_TICKET = data;
        return resolve(CACHE_STATUS_TICKET);
      });
  });
};

let CACHE_PRIORITIES = null;
export const getPriorities = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_PRIORITIES) return resolve(CACHE_PRIORITIES);

    action
      .execute('get', Constants.APIEndpoints.TABLE_STRUCTURED + '/60')
      .then((result) => {
        const data = (result?.data || []).map((item) => ({
          value: item.id_table,
          label: item.description,
        }));
        CACHE_PRIORITIES = data;
        return resolve(CACHE_PRIORITIES);
      });
  });
};

let CACHE_COUNTRIES = null;
export const getCountries = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_COUNTRIES) return resolve(CACHE_COUNTRIES);

    action.execute('get', Constants.APIEndpoints.COUNTRY).then((result) => {
      const data = (result?.data || []).map((item) => ({
        value: item.id_country,
        label: translateFromPtBR(item.name, Store.language),
      }));
      CACHE_COUNTRIES = data;
      return resolve(CACHE_COUNTRIES);
    });
  });
};

let CACHE_CITIES = null;
export const getCities = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_CITIES) return resolve(CACHE_CITIES);

    action
      .execute('get', Constants.APIEndpoints.COUNTRY + '/city/32')
      .then((result) => {
        const data = (result?.data || []).map((item) => ({
          value: item.id_city,
          label: item.name,
        }));
        CACHE_CITIES = data;
        return resolve(CACHE_CITIES);
      });
  });
};

let CACHE_STATES = null;
export const getStates = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_STATES) return resolve(CACHE_STATES);

    action
      .execute('get', Constants.APIEndpoints.COUNTRY + '/32')
      .then((result) => {
        const data = (result?.data || []).map((item) => ({
          value: item.id_state,
          label: item.name,
        }));
        CACHE_STATES = data;
        return resolve(CACHE_STATES);
      });
  });
};

let CACHE_CHANNELS = null;
export const getChannels = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_CHANNELS) return resolve(CACHE_CHANNELS);

    action
      .execute('get', Constants.APIEndpoints.TABLE_STRUCTURED + '/120')
      .then((result) => {
        const data = (result?.data || []).map((item) => ({
          value: item.id_table,
          label: item.description,
        }));
        CACHE_CHANNELS = data;
        return resolve(CACHE_CHANNELS);
      });
  });
};

let CACHE_FORMS = null;
export const getForms = async () => {
  return new Promise((resolve, reject) => {
    if (CACHE_FORMS) return resolve(CACHE_FORMS);

    action
      .execute('get', `${Constants.APIEndpoints.TASK}/getforms`, '')
      .then((result) => {
        if( result.data ) {
          result = result.data;
        }
        const data = (result?.data || []).map((item) => ({
          value: item.fk_id_template_forms,
          label: item.name,
        }));
        CACHE_FORMS = data;
        return resolve(CACHE_FORMS);
      });
  });
};

export const getTypeTickets = async () => {
  return Promise.resolve([
    { value: 1, label: language.TICKET_TYPES.INCIDENT },
    { value: 2, label: language.TICKET_TYPES.PROBLEM },
    { value: 4, label: language.TICKET_TYPES.SOLICITATION },
    { value: 5, label: language.TICKET_TYPES.EVENT },
    { value: 6, label: language.TICKET_TYPES.CRISIS },
    { value: 7, label: language.TICKET_TYPES.CRITICAL_INCIDENT },
  ]);
};

export const getTypeTechs = async () => {
  return Promise.resolve([
    { value: 1, label: language.INTERNAL },
    { value: 2, label: language.EXTERNAL },
  ]);
};

export const getTaskComplexity = async () => {
  return Promise.resolve([
    { value: 'low', label: language.LOW },
    { value: 'medium', label: language.MEDIUM },
    { value: 'high', label: language.HIGH },
  ]);
};

export const getUserVip = async () => {
  return Promise.resolve([
    { value: 'true', label: language.YES },
    { value: 'false', label: language.NO },
  ]);
};

export const getData = async (key) => {
  if (key === 'status_awaiting') return await getStatusAwaiting();
  else if (key === 'status_tickets') return await getStatusTicket();
  else if (key === 'priority') return await getPriorities();
  else if (key === 'location_country') return await getCountries();
  else if (key === 'location_uf') return await getStates();
  else if (key === 'location_city') return await getCities();
  else if (key === 'channel') return await getChannels();
  else if (key === 'form') return await getForms();
  else if (key === 'type_tickets') return await getTypeTickets();
  else if (key === 'type_tech') return await getTypeTechs();
  else if (key === 'task_complexity') return await getTaskComplexity();
  else if (['user_vip', 'department_critical_unit'].includes(key))
    return await getUserVip();
};

export const resolveConditionValue = async (key, value) => {
  if (key === 'status_awaiting') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'status_tickets') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'priority') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'location_country') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'location_uf') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'location_city') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'channel') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'form') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'type_tickets') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'type_tech') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (key === 'task_complexity') {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  } else if (['user_vip', 'department_critical_unit'].includes(key)) {
    const data = await getData(key);
    return data?.find((item) => item.value == value)?.label;
  }
};
