import React, { Component } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core'; //comum
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import './style.css';

const theme = createTheme({
	palette: {
		primary: { main: '#009dc0' }
	},
});

export default class AppBar extends Component {

	render() {
		const extend = this.props.extend;
		const min = this.props.min;
		const light = this.props.light;
		const outher = this.props;

		return (

			!this.props.simples ? (
				<div {...outher} className={classnames('AppBar-23-sensr', (extend ? 'extend' : ''), (min ? 'min' : ''), (light ? 'lightBar' : ''), this.props.className)} >
					{this.props.children}
				</div>
			) : (
				<MuiThemeProvider theme={theme}>
					<Button variant="contained" size="small" color="primary" {...this.props}>{this.props.children}</Button>
				</MuiThemeProvider>
			)
		)
	}
}