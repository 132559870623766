import React, { useState, useEffect, useContext, Fragment } from 'react'
import GI2Context from './GI2Context';
import MonitorUtils from '../../util/MonitorUtils';
import Emitter from './EventEmitter_'
import Constants from '@data/Constants';

import { Tooltip } from 'react-tippy';
 
const tenant = Constants.Tenant;

function PieChartIndicator({ icon, percent = -1, triggers = {}, device ,   companyId,name}) {
     
    
    let { danger, warning } = triggers;
     let [ic,setIc]=useState('default')
   if(icon==5){
     if(percent==-1){
       
        

     }
   }
    useEffect(() => {
if(warning != undefined && danger!= undefined &&percent>-1){
    
  let i=((percent >= danger.value) && (danger.value> 0))
    ? 'danger'
    : ((percent < danger.value) && (percent >= warning.value) && (warning.value> 0))
    ? 'warning'
    : percent  < warning.value  &&percent >-1 && warning.value>0  ?
     'successes':'default'

 
    setIc(i);


     //Emitter.emit("status",device.id_device)
  
} 

 
    },[percent,device,triggers])






 return (
        icon ==5 ?

        ic == 'warning' ?   <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{percent} </strong>
          </div>
        }
        position="right"
        theme="dark"
      ><i style={{color:'#f0b002',fontSize:15}} class="fas fa-exclamation-circle">{name||''}</i>  
       <span style={{marginLeft:5}}>{percent} </span>
      
      
      </Tooltip>:
        ic == 'successes' ?   <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{percent} </strong>
          </div>
        }
        position="right"
        theme="dark"
      ><i style={{color:'#00ff00',fontSize:15}}  class="fas fa-check-circle">{name||''}</i>
        <span style={{marginLeft:5}} className="fontstatus">{percent} </span>
      
      </Tooltip>:
        <span> 
        <span style={{marginLeft:5}}></span></span>

      


        :
        ic == 'danger' ?
       <Tooltip
       html={
         <div style={{ color: '#fff', fontSize: 15 }}>
           <strong>{percent} %</strong>
         </div>
       }
       position="right"
       theme="dark"
     >
         <i style={{color:'#F44336',fontSize:15}} class="fas fa-check-circle">{name||''}</i>
         <span style={{marginLeft:5}}>{percent} %</span>
     </Tooltip>
       
       
      :
        ic == 'warning' ?   <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{percent} %</strong>
          </div>
        }
        position="right"
        theme="dark"
      ><i style={{color:'#f0b002',fontSize:15}} class="fas fa-exclamation-circle">{name||''}</i>  
       <span style={{marginLeft:5}}>{percent} %</span>
      
      
      </Tooltip>:
        ic == 'successes' ?   <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            <strong>{percent} %</strong>
          </div>
        }
        position="right"
        theme="dark"
      ><i style={{color:'#00ff00',fontSize:15}}  class="fas fa-check-circle">{name||''}</i>
        <span style={{marginLeft:5}} className="fontstatus">{percent} %</span>
      
      </Tooltip>:
        <span> 
        <span style={{marginLeft:5}}></span></span>

      
         
        
       
    );
  }

export default function ({device,item,monitor,ioServers,items,devicelast,info_}) {
    const [cpuUsedDash, setCpuUsedDash] = useState(-1);
    const [memory, setMemory] = useState(0);
    const [memoryUsed, setMemoryUsed] = useState(0);
    const [memoryUsedDash, setMemoryUsedDash] = useState(-1);
    const [disk, setDisk] = useState([]);
    const [triggers, setTriggers] = useState({});
    const [deviceItem, setDeviceItem] = useState({})
    const [online, setOnline] = useState(false);
    const [dd, setDd] = useState(null);
    const [info, setInfo] = useState(null);
    const [temp, setTemp] = useState(-1);
  
    useEffect(() => {
     

    try{
       setCpuUsedDash(-1)
       setMemoryUsed(-1);
       setMemoryUsedDash(-1);
       setDisk([]);
      let x=   monitor.filter(m => m.id_device === device.id_device);
      
      if(x.length>0){
        x =x[0]
      const cpuTriggers = x.conf['cpu-stats'].triggers;
     const memoryTriggers = x.conf['mem-info'].triggers;
     const fsTriggers = x.conf['fs-list'].triggers;
    const values= x.values['sys-info']
    //setInfo(values)
     const obj = {
     cpuTriggers,
     memoryTriggers,
     fsTriggers,
     };

     setTriggers(obj);

     setDeviceItem(x);
    
        if (device && ioServers ) {
     
          try {
            ioServers.on(`_${tenant}_${device.id_device}`, (data) => {
              
              if (data.key == 'process') {
              
                setCpuUsedDash( data.values.cpu.usedPercent);
                setMemoryUsedDash(data.values.memory.usedPercent)
                setDisk(data.values.fs) 
                try{
                setTemp(data.values.temp.max)
                }catch(e){}

                 
 

              }

              if (data.key == 'sys-info') {
                setInfo(data.values)       
              }
      
            });
          } catch (e) {
             console.log(e)
          }
        } 
      }
      }catch(r){
        console.log(r)

      }
      },[device,monitor,devicelast]);


      
    return (
        <Fragment>

       {info_?

       <div>{info &&info.agentVersion||''}</div>
        :
        <div className={''/*items+'____'*/} style={{textAlign:'left'}}>
        {item==1?
         <PieChartIndicator
          icon={0}
          device={device.id_device}
          companyId={device.companyId}
          percent={parseInt(cpuUsedDash)}
          triggers={triggers.cpuTriggers}
          />
          :
         item==5?
          <PieChartIndicator
          icon={5}
          device={device.id_device}
          companyId={device.companyId}
          percent={parseInt(temp)}
          triggers={triggers.cpuTriggers}
          /> :
          item==2?     <PieChartIndicator
          device={device.id_device}
          companyId={device.companyId}
          icon={1}
          percent={parseInt(memoryUsedDash) }
          triggers={triggers.memoryTriggers}
          />
          : 
          
  
      <Tooltip
          html={
          disk.map(c=> 
              <PieChartIndicator
        device={device.id_device}
        companyId={device.companyId}
        name={c.fs}
        icon={2}
        percent={
           isNaN(parseInt(c.use))==true?0:(parseInt(c.use))
          }
        triggers={triggers.fsTriggers}
        /> ) 
          }
          position="right"
          theme="dark"
        > 
        {   (disk.length>1?disk.sort(function (a, b) {
             return b.use - a.use;
            }) : disk).slice(0,1).map(c=> 
              <PieChartIndicator
        device={device.id_device}
        companyId={device.companyId}
        name={c.fs}
        icon={2}
        percent={
          isNaN(parseInt(c.use))==true?0:(parseInt(c.use))
          }
        triggers={triggers.fsTriggers}
        /> ) }
        </Tooltip>
  
       
        }
          </div>
    
      }

         </Fragment>
  
        
    )
}