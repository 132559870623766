import Button from '@common/Button';
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import { Card, Grid, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { FaCircle, FaCaretDown } from 'react-icons/lib/fa';
import ReactTooltip from 'react-tooltip';
import { RadialBarChart, RadialBar } from 'recharts';
import CommonForm from '../../common/CommonForm2';
import moment from 'moment';
import PageLayout from '@common/PageLayout';
import Help from '@common/components/Help';
import CSSModules from 'react-css-modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import * as styles from './styles.scss';
import './ContractList.css';
import CommonTable from '@common/CommonTable';
import { Paper, List, ListItem } from '@material-ui/core'; //List
import dateFormat from 'dateformat';
import AttachFile from '@common/AttachFile';
import { filterObj } from '@common/Func';
import Li from '@common/List';
import '@bClass';
import CommonForm2 from '../../common/CommonForm2';
import ListColumn from '@common/ListColumn';

@observer
export default class ContractList extends Component {
  constructor() {
    super();
    this.state = {
      index: null,
      justify: false,
      tokenModal: null,
      token: null,
      search: '',
      valorConsumido: 0,
      horastotalConsumida: '00:00',
      dataGraph: [{ name: '', value: 0, fill: '#8DD1E1' }],
      page: null,
      values: {},
      contracts: [],
      totalcontracts: 0,
      currentPage: 0,
    };

    this.handleIndex = this.handleIndex.bind(this);
    this.tokenModalView = this.tokenModalView.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSelectContract = this.handleSelectContract.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    /*Conpass.init(
      {
        name: 'Thiago',
        email: 'thiago.santos@sensrit.com.br'
      },
      'GCF-Adicionar contrato'
    );
    Conpass.startFlow('TRYkOf7YOBN-');*/
  }
  handleFilterConsumed(obj, date) {
    let value =
      (obj.filter((v) => v.to_char === date).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHour).length > 0 &&
        obj.filter((v) => v.to_char === date).map(this.calcHour)[0]) ||
      0;
    return value;
  }
  calcHour(obj) {
    if (obj.sum) {
      let { hours = 0, minutes = 0, seconds = 0 } = obj.sum;

      return hours + minutes / 60 + seconds / 60 / 60;
    } else {
      return 0;
    }
  }

  onDelete(v = '', removeCascade = false) {
    let { values } = this.state;
    let { action, APIEndpoints, language } = this.props;
    let method = '';
    let url = '';
    let body = {};
    if (values.hasOwnProperty('detach')) {
      url = APIEndpoints.CONTRACT + '/' + values.detach;
      method = 'put';
      if (!values.hasOwnProperty('id_contract_file')) {
        body = { id_contract: values.id_contract, detach: true };
      } else {
        body = {
          id_contract: values.id_contract,
          detach: true,
          id_contract_file: values.id_contract_file,
        };
      }

      body['id_' + values.detach] = values.id;
      action.execute(method, url, '', body, 'id_contract').then((g) => {
        this.props.getTechContract(values.id_contract);
        this.props.getDeviceContract(values.id_contract);
        this.props.getFileContract(values.id_contract);
        this.props.getSlaContract(values.id_contract);
        this.handlePage('list');

        // this.handlePage('list')
        //  this.redirect('/dash/contracts')
      });
    } else {
      body = {
        justify: v,
        close: values.close,
        removeCascade,
      };
      method = values.close ? 'post' : 'delete';
      url = values.close
        ? `${APIEndpoints.CONTRACT}/enc/${values.id_contract}`
        : `${APIEndpoints.CONTRACT}/${values.id_contract}?removeCascade=${removeCascade}`;
      action.execute(method, url, '', body, 'id_contract').then((g) => {
        this.callPageSelectFilter();
        this.setState({ page: 'list' });
      });
    }
  }

  componentWillMount() {
    this.callPageSelectFilter();
  }
  callPageSelectFilter(page = 0, values, totalPerPage = 75) {
    this.setState({ currentPage: page });

    let limit = totalPerPage || 75;
    let pageCount = page + 1;
    let offset = page * limit;

    let { action, APIEndpoints } = this.props;

    action
      .execute('post', `${APIEndpoints.CONTRACT}/list?limit=${totalPerPage}&offset=${offset}`, '', values)
      .then((res) => {
        this.setState({
          contracts: res.data,
        });
        this.setState({
          totalcontracts: parseInt(res.data[0].total_count),
        });
      })
      .catch((error) => {
        //  this.store.toggleAlert(true, 'Aguarde estamos processando os dados', 'success');
      });
  }

  handleSelectContract(value) {
    let { action, APIEndpoints, language } = this.props;
    action
      .execute('post', APIEndpoints.CONTRACT + '/getContractConsumed', '', {
        id_contract: value,
      })
      .then((b) => {
        let contract = b.data;

        if (contract.contract[0].type === 'hour') {
          let dataGraph = [
            {
              name: language.HOURS_CONTRACTED,
              value: parseInt(
                contract.contract[0].hours * contract.contract[0].value
              ),
              fill: 'var(--primary)',
            },
          ];

          let mesAno = moment().format('MMYY'); //'1020'

          //edit 22/10/2020 - calculo horas consumidas * valor hora
          let v = (this.currentConsumed =
            this.handleFilterConsumed(contract.assessment, mesAno) +
            this.handleFilterConsumed(contract.changesConsumed, mesAno) +
            this.handleFilterConsumed(contract.os, mesAno) +
            this.handleFilterConsumed(contract.osconsumed, mesAno) +
            this.handleFilterConsumed(contract.projectConsumed, mesAno) +
            this.handleFilterConsumed(contract.realizado, mesAno) +
            this.handleFilterConsumed(contract.ticketconsumed, mesAno));
          v = parseInt(v);
          dataGraph.push({
            name: language.HOURS_CONSUMED,
            value: parseInt(v * contract.contract[0].value),
            fill: '#8DD1E1',
          });

          this.setState({
            valorConsumido: v * parseFloat(contract.contract[0].value),
            horastotalConsumida: v,
            dataGraph: dataGraph,
          });
        }
      });
  }

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ search: value });
  };

  dateformat = (timestamp) => {
    return dateFormat(parseInt(timestamp), 'dd/mm/yyyy');
  };
  handleIndex(index) {
    this.setState({
      index: this.state.index === index ? '' : index,
    });
  }
  handlePage(page, obj) {
    this.setState({ page: page, values: obj });
  }

  onClose() {
    this.setState({ tokenModal: null });
  }
  tokenModalView(obj) {
    this.setState({ token: obj.token_contract, tokenModal: true });
  }
  render() {
    let {
      language,
      handlePage,
      redirect,
      sub,
      load,
      getTechContract,
      getDeviceContract,
      getFileContract,
      getSlaContract,
    } = this.props;
    let { contracts } = this.state;

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.TERMINATE_CONTRACT,
      primary: true,
      style: { margin: 10, float: 'right' },
    };

    if (this.state.search != '') {
      //action={this.action} APIEndpoints={ this.APIEndpoints}
      contracts = contracts.filter((item) => {
        return (
          item.name.toUpperCase().indexOf(this.state.search.toUpperCase()) !==
            -1 ||
          item.company
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) !== -1
        );
      });
    }

    var data = contracts.reduce((arr, obj, idx) => {
      let diffSeconds = moment(obj.end_date).diff(moment(), 'seconds');

      let awesomeIcon =
        diffSeconds <= 0
          ? { icon: 'times', color: '#F44336' }
          : diffSeconds < 7776000
          ? { icon: 'exclamation', color: '#DAA520' }
          : { icon: 'check', color: '#33ABA0' };

      let diff =
        diffSeconds <= 0
          ? '#F44336'
          : diffSeconds < 7776000
          ? '#DAA520'
          : '#33ABA0';

      var value_realized = 0;
      var value_foreseen =
        obj.hours != 0
          ? parseInt(obj.hours) * parseFloat(obj.value) * 12
          : obj.year
          ? parseFloat(obj.value)
          : parseFloat(obj.value) * obj.month;
      try {
        value_realized =
          obj.contract_month &&
          obj.contract_month
            .filter(Boolean)
            .reduce((i, e) => (i += parseFloat(e.valor)), 0);
      } catch (e) {}
      return arr.concat([
        {
          id_contract: obj.id_contract,
          status: (
            <div
              className="statusCustomIcon"
              style={{ background: awesomeIcon.color }}
            >
              <i className={`fas fa-${awesomeIcon.icon}`}></i>
            </div>
          ),
          company: obj.company,
          contract: obj.name,
          contract_value:
            obj.value && obj.type == 'hour'
              ? 'R$ ' +
                (obj.value * obj.hours).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 'R$ ' +
                obj.value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          num: obj.number,
          fornecedor: obj.name_provider,
          type:
            obj.type == 'year'
              ? language.YEARLY
              : obj.type == 'month'
              ? language.MONTHLY
              : language.HOURLY,
          end_date: this.dateformat(new Date(obj.end_date).getTime()),
          global: obj.global == true ? language.YES : language.NO,
          actions: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() =>
                        sub
                          ? this.props.handlePage('edit', obj)
                          : redirect('/dash/contracts/edit', filterObj(obj))
                      }
                    />
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      className="mx-2"
                      style={{ fontSize: 13, width: 21, height: 21 }}
                      onClick={() => this.handlePage('remove', obj)}
                    />
                    {obj.closed_contract ? null : (
                      <Button
                        variant="circle"
                        icon="far fa-times-circle"
                        label={language.FINALIZE_CONTRACT}
                        color="danger"
                        style={{
                          fontSize: 13,
                          width: 21,
                          height: 21,
                          marginRight: 5,
                        }}
                        onClick={() => {
                          obj.close = true;
                          this.handlePage('remove', obj);
                        }}
                      />
                    )}
                    {obj.justify ? (
                      <Button
                        variant="circle"
                        icon="fas fa-info"
                        label={language.JUSTIFICATION}
                        color="light"
                        style={{ fontSize: 11, width: 21, height: 21 }}
                        onClick={() => this.setState({ justify: obj.justify })}
                      />
                    ) : null}
                    <Button
                      variant="circle"
                      icon={
                        'fas fa-angle-' +
                        (this.state.index === idx ? 'up' : 'down')
                      }
                      label={language.VISUALIZE}
                      color="primary"
                      style={{ fontSize: 18, width: 21, height: 21 }}
                      className="ml-2"
                      onClick={() => {
                        this.handleSelectContract(obj.id_contract);
                        getTechContract(obj.id_contract);
                        getDeviceContract(obj.id_contract);
                        getFileContract(obj.id_contract);
                        getSlaContract(obj.id_contract);
                        setTimeout(() => {
                          this.handleIndex(idx);
                        }, 2000);
                      }}
                    />
                  </div>
                }
              />
            </React.Fragment>
          ),
        },
        this.state.index === idx
          ? {
              ConfigCell: {
                className: 'vat',
                index: 'detail-table',
                cols: {
                  col_0: {
                    colSpan: 10,
                  },
                },
              },
              status: (
                <React.Fragment>
                  <Grid container spacing={2} className="pt-4 pb-2">
                    <Grid item xs={3} className="grid-radial-detail-contract">
                      {obj.type == 'year' || obj.type == 'month' ? (
                        <Paper
                          className="paper-list-custom mb-4"
                          style={{ height: 147 }}
                        >
                          <div className="appBarMin" position="static">
                            <span>{language.CONTRACT_VALUE}</span>
                          </div>
                          <div className="content">
                            <ListColumn
                              items={[
                                {
                                  labelProps: this.props.fromConfiguration
                                    ? { style: { fontSize: 13 } }
                                    : undefined,
                                  valueProps: this.props.fromConfiguration
                                    ? { style: { fontSize: 13 } }
                                    : undefined,
                                  label: language.FORESEEN,
                                  value:
                                    'R$ ' +
                                    value_foreseen.toLocaleString('pt-BR', {
                                      minimumFractionDigits: 2,
                                    }),
                                },
                                {
                                  labelProps: this.props.fromConfiguration
                                    ? { style: { fontSize: 13 } }
                                    : undefined,
                                  valueProps: this.props.fromConfiguration
                                    ? { style: { fontSize: 13 } }
                                    : undefined,
                                  label: language.REALIZED,
                                  value:
                                    'R$ ' +
                                    value_realized.toLocaleString('pt-BR', {
                                      minimumFractionDigits: 2,
                                    }),
                                },
                                {
                                  labelProps: this.props.fromConfiguration
                                    ? { style: { fontSize: 13 } }
                                    : undefined,
                                  valueProps: this.props.fromConfiguration
                                    ? { style: { fontSize: 13 } }
                                    : undefined,
                                  label: language.CONSUMED,
                                  value:
                                    'R$ ' +
                                    (
                                      value_foreseen - value_realized
                                    ).toLocaleString('pt-BR', {
                                      minimumFractionDigits: 2,
                                    }),
                                },
                              ]}
                            />
                          </div>
                        </Paper>
                      ) : (
                        <RadialBarChart
                          width={350}
                          height={200}
                          className="radial-detail-contract"
                          innerRadius="50%"
                          outerRadius="100%"
                          data={this.state.dataGraph}
                          barSize={100}
                          cx="50%"
                          cy="50%"
                          startAngle={90}
                          endAngle={-270}
                          style={{ margin: '0 auto' }}
                        >
                          <RadialBar background dataKey="value" />
                        </RadialBarChart>
                      )}
                      <Paper
                        className="paper-list-custom"
                        style={{ marginTop: 20 }}
                      >
                        <div className="appBarMin" position="static">
                          <span>
                            {obj.type == 'year'
                              ? language.YEAR + 's'
                              : obj.type == 'month'
                              ? language.MONTHS
                              : language.HOURS}
                          </span>
                        </div>
                        <List className="list listMin">
                          <ListItem className="listItem" dense button>
                            {obj.type == 'year'
                              ? obj.year +
                                ' ' +
                                language.YEAR +
                                's ' +
                                language.CONTRACTED +
                                's'
                              : obj.type == 'month'
                              ? obj.month +
                                ' ' +
                                language.MONTHS +
                                ' ' +
                                language.CONTRACTED +
                                's'
                              : obj.hours + 'h ' + language.HOURS_CONTRACTED}
                          </ListItem>

                          {obj.type == 'hour' && (
                            <ListItem className="listItem" dense button>
                              {this.state.horastotalConsumida}s{' '}
                              {language.HOURS_CONSUMED}
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </Grid>
                    <Grid item xs={3}>
                      <Paper
                        className="paper-list-custom"
                        style={{ height: '100%' }}
                      >
                        <div className="appBarMin" position="static">
                          <span>{language.GENERAL_INF}</span>
                        </div>
                        <Li
                          mh={285}
                          scrollProps={{
                            style: {
                              marginTop: -10,
                            },
                          }}
                          items={[
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {language.COMPANY}:
                                  </span>
                                  <span>{obj.company}</span>
                                </Fragment>
                              ),
                            },
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {language.SPONSOR}:
                                  </span>
                                  <span>{obj.sponsor_name}</span>
                                </Fragment>
                              ),
                            },
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {language.AMOUNT}:
                                  </span>
                                  <span>
                                    {obj.type == 'month'
                                      ? obj.value
                                      : (obj.value * obj.hours).toFixed(2)}
                                  </span>
                                </Fragment>
                              ),
                            },
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              visible: obj.type !== 'month',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {obj.type == 'year'
                                      ? language.YEAR_VALUE
                                      : obj.type == 'month'
                                      ? language.MONTH_VALUE
                                      : language.HOUR_VALUE}
                                    :
                                  </span>
                                  <span>
                                    {parseFloat(obj.value).toFixed(2)}
                                  </span>
                                </Fragment>
                              ),
                            },
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {obj.type == 'year'
                                      ? language.YEAR_VALUE +
                                        ' ' +
                                        language.EXCEEDED
                                      : obj.type == 'month'
                                      ? language.MONTH_VALUE +
                                        ' ' +
                                        language.EXCEEDED
                                      : language.HOUR_EXCEEDED_VALUE}
                                    :
                                  </span>
                                  <span>
                                    {parseFloat(obj.timeout_value).toFixed(2)}
                                  </span>
                                </Fragment>
                              ),
                            },
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {language.INITIAL_DATE}:
                                  </span>
                                  <span>
                                    {moment(obj.start_date).format('L')}
                                  </span>
                                </Fragment>
                              ),
                            },
                            {
                              className:
                                'listItem d-flex justify-content-between',
                              content: (
                                <Fragment>
                                  <span style={{ marginRight: 10 }}>
                                    {language.END_DATE}:
                                  </span>
                                  <span>
                                    {moment(obj.end_date).format('L')}
                                  </span>
                                </Fragment>
                              ),
                            },
                            ...this.props.files,
                          ]}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <Paper
                        className="paper-list-custom"
                        style={{ height: '100%' }}
                      >
                        <div
                          className="appBarMin d-flex justify-content-between align-items-center px-2"
                          position="static"
                        >
                          <span>SLA's</span>
                          <div className="bg-icon-plus">
                            <div
                              data-tip={language.SLA_ATTACH}
                              className="pointer addIcon cardIcon"
                              onClick={() => handlePage('attachSla', obj)}
                            />
                          </div>
                        </div>
                        <Li
                          mh={285}
                          scrollProps={{
                            style: {
                              marginTop: -10,
                            },
                          }}
                          items={this.props.slas}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <Paper
                        className="paper-list-custom"
                        style={{ height: '100%' }}
                      >
                        <div
                          className="appBarMin d-flex justify-content-between align-items-center px-2"
                          position="static"
                        >
                          <span>
                            {language.DEVICE_CONF +
                              ' ' +
                              this.props.countDevices}{' '}
                          </span>
                          <div className="bg-icon-plus">
                            <div
                              data-tip={language.DEVICE_ATTACH}
                              className="pointer addIcon cardIcon"
                              onClick={() => handlePage('attachDevice', obj)}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="content-search px-3 mt-2">
                            <CommonForm
                              fields={[
                                {
                                  startIcon: 'fas fa-search',
                                  type: 'text',
                                  name: 'search',
                                  placeholder: language.SEARCH,
                                },
                              ]}
                              onChangeField={
                                (f, v) => {
                                  setTimeout(() => {
                                    getDeviceContract(obj.id_contract, {
                                      search: v,
                                    });
                                  }, 2000);
                                }

                                /*setSearch(v)*/
                              }
                            />
                          </div>
                        </div>
                        <Li
                          mh={285}
                          scrollProps={{
                            style: {
                              marginTop: -10,
                            },
                          }}
                          items={this.props.devices}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <Paper
                        className="paper-list-custom"
                        style={{ height: '100%' }}
                      >
                        <div
                          className="appBarMin d-flex justify-content-between align-items-center px-2"
                          position="static"
                        >
                          <span>
                            {language.TECHS + ' ' + this.props.countTechs}{' '}
                          </span>
                          <div className="bg-icon-plus">
                            <div
                              data-tip={language.TECH_ATTACH}
                              className="pointer addIcon cardIcon"
                              onClick={() => handlePage('attachTech', obj)}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="content-search px-3 mt-2">
                            <CommonForm
                              fields={[
                                {
                                  startIcon: 'fas fa-search',
                                  type: 'text',
                                  name: 'search',
                                  placeholder: language.SEARCH,
                                },
                              ]}
                              onChangeField={
                                (f, v) => {
                                  setTimeout(() => {
                                    getTechContract(obj.id_contract, {
                                      search: v,
                                    });
                                  }, 2000);
                                } /*setSearch(v)*/
                              }
                            />
                          </div>
                        </div>
                        <Li
                          mh={285}
                          scrollProps={{
                            style: {
                              marginTop: -10,
                            },
                          }}
                          items={this.props.techs}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ),
            }
          : null,
      ]);
    }, []);

    data = data.filter(Boolean);
    var p = this.state.index === '' || this.state.index === null;
    let col = [
      {
        label: language.ID,
        key: 'id_contract',
        style: { width: 50, paddingRight: 0 },
        thConfig: true,
      },
      {
        label: language.STATUS,
        key: 'status',
        style: {
          width: 1,
          ...(p ? { paddingRight: 0 } : { paddingTop: 0, paddingBottom: 0 }),
        },
        thConfig: true,
      },
      {
        label: language.COMPANY,
        key: 'company',
        style: { width: 500, paddingRight: 0 },
        thConfig: true,
      },
      {
        label: language._CONTRACT,
        key: 'contract',
        style: { width: 500, paddingRight: 0 },
        thConfig: true,
      },
      {
        label: language.CONTRACT_VALUE,
        key: 'contract_value',
        thConfig: true,
        style: { width: 100 },
      },
      {
        label: language.N_CONTRACT,
        key: 'num',
        thConfig: true,
        style: { width: 100 },
      },
      {
        label: language.PROVIDER,
        key: 'fornecedor',
        thConfig: true,
        style: { width: 200 },
      },

      { label: language.TYPE, key: 'type', thConfig: true, style: { width: 100 } },
      { label: language.END_DATE, key: 'end_date' },

      {
        label: 'Global',
        key: 'global',
        thConfig: true,
        style: { width: 100 },
      },

      {
        label: language.ACTIONS,
        key: 'actions',
        thConfig: true,
        style: { width: 100, paddingLeft: 0, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let { configurationProps = {} } = this.props;
    let Container = !sub ? PageLayout : Fragment;

    const cleanFilters = () => {
      this.callPageSelectFilter();
    };
    return (
      <Container
        icon={<div className="contractIcon titleIconSize" />}
        title={sub ? configurationProps.title : language.CONTRACTS}
        subTitle={
          sub ? language.CONFIG + '/' : language.DASHBOARD_PAGE.GCF + '/'
        }
        rightElements={[
          <div style={{ width: 200, zoom: 0.8, marginTop: '1rem' }} zoom={0.8}>
            <CommonForm2
              style={{ width: '200px' }}
              onChangeField={(f, v) => {
                if (v == 1) {
                  this.callPageSelectFilter(0, { closed: false }, 75);
                } else {
                  this.callPageSelectFilter(0, { closed: true }, 75);
                }
              }}
              language={language}
              fields={[
                {
                  col: 12,
                  name: 'type',
                  label: language.CONTRACT_FILTER,
                  type: 'select',
                  options: [
                    { value: 1, label: language.ACTIVE_PLURAL },
                    { value: 2, label: language.CLOSED_PLURAL },
                  ],
                },
              ]}
            />
          </div>,
        ]}
      >
        <div className="Contract-List-Page w-100">
          <Dialog
            title={language.JUSTIFICATION}
            open={this.state.justify ? true : false}
            maxWidth="lg"
            onClose={() => this.setState({ justify: false })}
          >
            <p
              style={{ color: '#888080' }}
              dangerouslySetInnerHTML={{ __html: this.state.justify }}
            />
          </Dialog>

          <CommonTable
            data={[...data]}
            col={col}
            beginWithNoFilter={true}
            searchColumn={true}
            paginationTop={!sub}
            currentPage={this.state.currentPage}
            countTotal={this.state.totalcontracts}
            isAutomaticPagination={false}
            searchColumnEvent={(v) => {
              this.callPageSelectFilter(0, v, 75);
            }}
            callPageSelectFilter={(page, values, rowsPerPage, type) =>
              this.callPageSelectFilter(page, values, rowsPerPage, type)
            }
            beforeBar2={sub ? configurationProps.control : undefined}
            beforeBar={
              <Button
                variant="circle"
                label={language.ADD_CONTRACT}
                className="mr-3"
                icon="fas fa-plus"
                onClick={() =>
                  sub
                    ? this.props.handlePage('new')
                    : redirect('/dash/contracts/new')
                }
              />
            }
            onClearAllFilter={cleanFilters}
          />
        </div>
        <Dialog
          title="Token"
          open={this.state.tokenModal}
          maxWidth="xs"
          onClose={() => this.onClose()}
        >
          <h3>{this.state.token}</h3>
        </Dialog>

        {this.state.page === 'remove' && this.state.values.close == true ? (
          <Dialog
            title={language.TERMINATE_CONTRACT}
            open={true}
            maxWidth="lg"
            onClose={() => this.setState({ values: [], page: 'list' })}
          >
            <CommonForm2
              style={{ width: '200px' }}
              language={language}
              onChangeField={(f, v) => ''}
              fields={[
                {
                  col: 12,
                  name: 'justify',
                  label: language.REASON,
                  multiLine: true,
                  type: 'textEditor',
                  required: true,
                },
                {
                  col: 4,
                  type: 'check',
                  name: 'removeCascade',
                  label: language.ALSO_FINALIZE_THE_SUBCONTRACTS,
                },
              ]}
              onSubmit={(c) => this.onDelete(c.justify, c.removeCascade)}
              button={button}
            />
          </Dialog>
        ) : null}

        {this.state.page == 'remove' && this.state.values.close !== true ? (
          <Dialog
            title={
              this.state.values.detach
                ? language.CONFIRM_DETACH
                : language.CONFIRM_DELETE
            }
            open={true}
            maxWidth="lg"
            onClose={() => this.handlePage('list')}
          >
            <CommonForm2
              style={{ width: '200px' }}
              language={language}
              onChangeField={(f, v) => ''}
              fields={[
                {
                  col: 4,
                  type: 'check',
                  name: 'removeCascade',
                  label: language.ALSO_REMOVE_THE_SUBCONTRACTS,
                },
              ]}
              onSubmit={(c) => this.onDelete(c.justify, c.removeCascade)}
              button={button}
            />
          </Dialog>
        ) : null}
      </Container>
    );
  }
}

//export default CSSModules(ContractList, styles);
