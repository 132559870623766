import React, { memo, useState, Fragment, useEffect } from 'react';
import Store from '@data/Store';
import { observer } from 'mobx-react-lite';
import './index.css';
import { Tooltip } from 'react-tippy';

// -----------------------------------common-----------------------------------------------
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
// -----------------------------------material-ui------------------------------------------
import { Avatar, Checkbox, Grid } from '@material-ui/core';
// -----------------------------------components-body------------------------------------------
import Block_1 from './Body/Block_1';
import Block_2 from './Body/Block_2';
import Block_3 from './Body/Block_3';
import Block_4 from './Body/Block_4';
import Block_5 from './Body/Block_5';
import Block_6 from './Body/Block_6';
import Block_7 from './Body/Block_7';
import PredecessorActivities from './Body/PredecessorActivities';
// -----------------------------------components-nav------------------------------------------
import Nav from './Nav';
import { viewActivity } from '../jsonFormat';
// -----------------------------------components------------------------------------------
import FormSubactivities from '../FormSubactivities';
import FormEvolution from '../FormEvolution';
import FormRuntime from '../FormRuntime';
import moment, { lang } from 'moment';

export default memo(
  observer(
    ({
      open,
      onClose,
      data,
      users,
      onOpenForm: _onOpenForm,
      options,
      project,
      action,
      APIEndpoints,
      onSubmit,
      onSubmitActivitieAntecessor,
      onDownloadFile,
      ondeletefile,
      UpdateFiles,
      template,
      onUpdate = () => null,
    }) => {
      let [generalParams, setGeneralParams] = useState([]);

      let { language, subActivities } = Store;

      let status = {
        BACKLOG: language.BACKLOG,
        TODO: language.TO_DO,
        DOING: language.DOING,
        STOPPED: language.IMPEDITIVE,
        VALIDATION: language.VALIDATION,
        DONE: language.FINISHED,
      };

      useEffect(() => {
        action.execute(
          'get',
          `${APIEndpoints.PROJECT}/activity/subactivity?fk_id_activity=${data.id_project_activity}`,
          'subActivities'
        );
        action
          .execute('get', APIEndpoints.GENERAL_PARAMETERS, 'general_params')
          .then((res) => {
            setGeneralParams(res.data);
          })
          .catch((error) => {});
      }, []);
      let [FormSubactivitiesOpen, setFormSubactivitiesOpen] = useState(false);
      let [FormSubactivitiesValue, setFormSubactivitiesValue] = useState({});

      let [FormEvolutionOpen, setFormEvolutionOpen] = useState(false);
      let [formRuntime, setFormRuntime] = useState({});

      function onOpenForm() {
        _onOpenForm(data.id_project_activity);
      }

      useEffect(() => {
        action
          .execute('get', APIEndpoints.GENERAL_PARAMETERS, 'general_params')
          .then((res) => {
            setGeneralParams(res.data);
          })
          .catch((error) => {});
      }, []);

      function onActionBtn(_action, data) {
        if (_action == 'edit') {
          setFormSubactivitiesOpen(true);
          setFormSubactivitiesValue(data);
        } else if (_action == 'delete') {
          let SubActSelected = subActivities.filter(
            (e) => e.id_sub_activity != data.id_sub_activity && e.selected
          ).length;
          let SubActGeral = subActivities.filter(
            (e) => e.id_sub_activity != data.id_sub_activity
          ).length;

          let percent = parseInt((SubActSelected / SubActGeral) * 100) || 0;

          action.execute(
            'delete',
            `${APIEndpoints.PROJECT}/activity/subactivity/${data.id_sub_activity}/${data.fk_id_activity}/${project.id_project}/${percent}`,
            'subActivities',
            {},
            'id_sub_activity',
            { btn_loading: 'delete_subactivity_' + data.id_sub_activity }
          );
        } else {
          action.execute(
            'put',
            APIEndpoints.PROJECT + '/activity/subactivity',
            'subActivities',
            {
              initialized: !data.initialized,
              id_sub_activity: data.id_sub_activity,
            },
            'id_sub_activity',
            { btn_loading: 'subactivities' }
          );
        }
      }

      function submitSubActivity(values) {
        let dtafinal = moment(values.dt_end).format('YYMMDD');
        let dtpjinicial = moment(project.start_date).format('YYMMDD');
        let dtpjfinal = moment(project.end_date).format('YYMMDD');

        if (dtafinal < dtpjinicial || dtafinal > dtpjfinal) {
          Store.toggleAlert(
            true,
            `${language.WARNING_SUBACTIVITY_OUTSIDE_PROJECT_PERIOD} ${moment(
              project.start_date
            ).format('DD-MM-YY')} ${language.UNTIL} ${moment(project.end_date)
              .format('DD-MM-YY')}`,
            'error'
          );
        } else {
          let checkeds = subActivities.filter(
            (s) =>
              s.selected == true && s.fk_id_activity == values.fk_id_activity
          );
          let all = subActivities.filter(
            (s) => s.fk_id_activity == values.fk_id_activity
          );
          values.status = parseInt((checkeds.length * 100) / (all.length + 1));
          if (values.id_sub_activity) {
            action
              .execute(
                'put',
                APIEndpoints.PROJECT + '/activity/subactivity',
                'subActivities',
                values,
                'id_sub_activity',
                { btn_loading: 'subactivities' }
              )
              .then((e) => {
                window.$eventBus.emit('evtUpdateStatus', {
                  fk_id_project_activity: values.fk_id_activity,
                  pj: e[0].data,
                });
                setFormSubactivitiesOpen(false);
                setFormSubactivitiesValue({});
              });
          } else {
            action
              .execute(
                'post',
                APIEndpoints.PROJECT + '/activity/subactivity',
                '$post-project,$post-subActivities',
                values,
                null,
                { btn_loading: 'subactivities' }
              )
              .then((e) => {
                window.$eventBus.emit('evtUpdateStatus', {
                  fk_id_project_activity: values.fk_id_activity,
                  pj: e[0].data,
                });

                setFormSubactivitiesOpen(false);
                setFormSubactivitiesValue({});
              });
          }
        }
      }

      function onSubmitRuntime(v) {
        if (v.hours_in_days) {
          let hours = generalParams.hours_day;
          v.runtime = parseInt(v.runtime) * parseInt(hours);
          v.runtime = v.runtime + ':00';
        }

        let _formRuntime = { ...formRuntime, ...v };

        let sc = subActivities.map((e) => {
          if (e.id_sub_activity == formRuntime.id_sub_activity) {
            return _formRuntime;
          } else {
            return e;
          }
        });

        let checkeds = sc.filter((e) => e.selected);
        let percent = parseInt((checkeds.length * 100) / sc.length);
        let send = {
          ..._formRuntime,
          status: percent,
          id_project_activity: _formRuntime.fk_id_activity,
          id_project: _formRuntime.fk_id_project,
          timeextend: _formRuntime.runtime,
          isSubactivity: true,
        };

        action
          .execute(
            'post',
            APIEndpoints.PROJECT + '/activity/status',
            '$post-project,$post-subActivities',
            send,
            null,
            { btn_loading: 'runtime' }
          )
          .then((e) => {
            window.$eventBus.emit('evtUpdateStatus', {
              fk_id_project_activity: send.id_project_activity,
              pj: e[0].data,
            });
            setFormRuntime({});
            onUpdate(1);
          })
          .catch((err) => {
            if (err.data.code == 'E019') {
              Store.toggleAlert(true, language.ERROR_CODE.E019, 'error');
            }
          });
      }

      console.log('Data', data);
      let jsonFormat = viewActivity({
        pj: project,
        e: data,
        users,
        options,
        subActivities,
      });
      return (
        <Fragment>
          <FormSubactivities
            open={FormSubactivitiesOpen}
            onClose={() => {
              setFormSubactivitiesOpen(false);
              setFormSubactivitiesValue({});
            }}
            onSubmit={submitSubActivity}
            values={FormSubactivitiesValue}
            options={options}
            project={project}
            action={action}
            APIEndpoints={APIEndpoints}
            id_project_activity={data.id_project_activity}
            template={template}
          />

          <FormEvolution
            open={FormEvolutionOpen}
            onClose={() => {
              setFormEvolutionOpen(false);
            }}
            options={options}
            project={project}
            action={action}
            onUpdate={() => onUpdate(1)}
            APIEndpoints={APIEndpoints}
            id_project_activity={data.id_project_activity}
            percent={data.percent}
          />

          <FormRuntime
            open={!!Object.keys(formRuntime).length}
            onClose={() => {
              setFormRuntime({});
            }}
            onSubmit={onSubmitRuntime}
            options={options}
          />

          <Dialog
            maxWidth="md"
            open={open}
            onClose={onClose}
            miniVersion = {true}
            bar={false}
            PaperProps={{
              id: 'ViewActivity',
            }}
            contentProps={{
              style: {
                background: '#F5F5F5',
              },
            }}
          >
            <div className="d-flex justify-content-between">
              <h3 className="title mt-0">{data.name}</h3>
              <Button
                icon="fas fa-times"
                label={language.CLOSE}
                variant="circle"
                color="#A3A3A3"
                outlined
                style={{
                  color: '#A3A3A3',
                  border: 'none',
                  fontSize: 43,
                }}
                onClick={onClose}
              />
            </div>
            <div className="content-status">
              <span>{language.STATUS}: </span>
              <span>{status[data.stage || language.BACKLOG]}</span>
            </div>
            <div className="d-flex">
              <div id="body" className="pr-4">
                <Block_1
                  onOpenForm={onOpenForm}
                  percent={data.percent}
                  {...jsonFormat.Block1props}
                />
                <hr />
                <Block_2
                  onOpenForm={onOpenForm}
                  percent={data.percent}
                  {...jsonFormat.Block2props}
                />
                <Block_3
                  setFormRuntime={setFormRuntime}
                  onOpenForm={onOpenForm}
                  action={action}
                  APIEndpoints={APIEndpoints}
                  onActionBtn={onActionBtn}
                  {...jsonFormat.Block3props}
                />
                <Block_4
                  onDownload={onDownloadFile}
                  onOpenForm={onOpenForm}
                  {...jsonFormat.Block4props}
                />
                {data.percent != 100 && !template ? (
                  <PredecessorActivities
                    project={project}
                    options={options}
                    onSubmit={onSubmitActivitieAntecessor}
                    {...jsonFormat.PredecessorActivities}
                  />
                ) : null}
                <Block_5
                  onSubmit={onSubmit}
                  {...jsonFormat.Block5props}
                  project={project}
                />
                <Block_6 onOpenForm={onOpenForm} {...jsonFormat.Block6props} />
                {jsonFormat.Block_7 ? (
                  <Fragment>
                    <hr className="mb-4 mt-0" />
                    <Block_7 history={jsonFormat.Block_7} />
                  </Fragment>
                ) : null}
              </div>
              <div id="nav">
                <Nav
                  onOpenForm={onOpenForm}
                  _onOpenForm={_onOpenForm}
                  setFormSubactivitiesOpen={() =>
                    setFormSubactivitiesOpen(true)
                  }
                  setFormEvolutionOpen={() => setFormEvolutionOpen(true)}
                  subActivities={subActivities}
                  data={data}
                  onDownload={onDownloadFile}
                  UpdateFiles={UpdateFiles}
                  ondeletefile={ondeletefile}
                  percent={data.percent}
                  general_params={generalParams}
                  {...jsonFormat.nav}
                />
              </div>
            </div>
          </Dialog>
        </Fragment>
      );
    }
  )
);
