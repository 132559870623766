import React, {useCallback, useState} from 'react';
/*--------------------------------common-------------------------------------------*/
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
import DialogConfirm from '@common/DialogConfirm';
import { Tooltip } from 'react-tippy';

import Header from '@common/Header';
/*--------------------------------material-ui-------------------------------------------*/
import {Card, Grid} from '@material-ui/core';
/*--------------------------------components-------------------------------------------*/
import Nivel_1 from './Nivel_1';
import Form from '../Form';
import FormExpectedResult from '../Form/FormExpectedResult';
import FormSubActivities from '../Form/FormSubActivities';
import AssociateTicketTable from './AssociateTicketTable';
import AssociateActivityTable from './AssociateActivityTable';
import BtnOpen from './BtnOpen';
import DialogAvaliation from './DialogAvaliation';

const styleProgress = {width:'100%', height:9};
const styleContentProgress = {width:200};
import Store from '@data/Store';
let colors = [
	'var(--success)',
	'var(--primary)',
	'var(--warning)',
	'var(--purple)',
	'#0F9FC2',
	'#F0B002',
	'#9E24F0',
	'#019FC2',
];
let color = () => colors[Math.floor(Math.random() * colors.length)];

const { language } = Store;

export default function({
	data,
	departments=[],
	projectsList=[],
	projects,
	users=[],
	action,
	APIEndpoints,
	companyId,
	userOn
}){
	let [open, setOpen] = useState(null);
	let [openAssociate, setOpenAssociate] = useState(0);
	let [dialogForm, setDialogForm] = useState(false);
	let [dialogDeleteResult, setDialogDeleteResult] = useState(false);
	let [dialogDeleteSubactivity, setDialogDeleteSubactivity] = useState(false);
	let [dialog, setDialog] = useState(0);
	let [dialogAvaliation, setDialogAvaliation] = useState(false);
	let [departmentId, setDepartmentId] = useState(0);
	let [result, setResult] = useState({});
	let [subactivity, setSubactivity] = useState({});
	let [idResult, setIdResult] = useState(0);
	let [avaliationParams, setAvaliationParams] = useState({});
	let [idObjective, setIdObjective] = useState(0);
	
	const handleClickNewResult = useCallback((id) => {
		setDialog(1)
		setIdObjective(id);
	}, [])
	const handleOpenAvaliation = useCallback((params) => {
		setDialogAvaliation(true);
		setAvaliationParams(params);
	},[])
	const onChangeMenu = useCallback(async (value, h1, h2, h3) => {
		if (value.id_okr_results) {
			if (value.data.label === 'Associar Ticket') {
				setOpenAssociate(1);
				setIdResult(value.id_okr_results)

				return;
			}
			if (value.data.label === 'Associar a GP') {
				setOpenAssociate(2);
				setIdResult(value.id_okr_results)

				return;
			}
			if(value.data.label === 'Adicionar uma subatividade') {
				setIdResult(value.id_okr_results)
				setDialog(2)
				return;
			}
			
			try {
				const res = await action.execute('get', APIEndpoints.OKR + '/results/' + value.id_okr_results);
				setResult(res.data);
			
			} catch (error) {
				console.error(error)
				return;
			}

			if (value.data.label === 'Editar') {
				setDialog(1)
				return;
			}
			if (value.data.label === 'Excluir') {
				setDialogDeleteResult(true)
				return;
			}
		}

		if (value.id_okr_results_subactivies) {
			try {
				const res = await action.execute('get', APIEndpoints.OKR + '/subactivity/' + value.id_okr_results_subactivies);

				setSubactivity(res.data);
			
			} catch (error) {
				console.error(error)
				return;
			}
			if (value.data.label === 'Editar') {
				setDialog(2)
				return;
			}
			if (value.data.label === 'Excluir') {
				setDialogDeleteSubactivity(true)
				return;
			}
		}
		/*
			h1, h2 e h3 são index que representa os nívels da tabela, pois essa tabela tem 3 níveis de abertura.
			Eles mostram o index do array, 
			se h1 = 0; h2 = 3; h3 = undefined, significa que o usuário clicou na linha 3(h2) que está dentro da linha 0(h1) da tabela pai.
			
			se h1 = 3; h2 = 4; h3 = 9 significa que ele clicou no menu que está na linha 9(h3) da tabela de subatividades que está dentro da linha 4(h2) que está dentro da linha 3(h1)

			isso indica as cordenadas do click, mostra em qual menu estou trabalhando. Ou seja em qual linha da tabela o menu se encontra

			faça o teste, clique em um item do menu, onde tem os 3 pontinhos e veja o console

			podemos pegar os dados q queremos assim:

			data[h1].dataNivel[h2].dataNivel[h3] esse último (dataNivel[h3]) só se houver h3, ou seja, se h3 for diferente de undefined
		*/
	}, [])
	
	if (departmentId > 0) {
		data = data.filter(d => d.department == departmentId);
	}

	let _data = data.flatMap((e,i)=>[
		{
			ConfigCell:{
				className:'tr-primary',
				style:{backgroundColor:color()}
			},
			btn:<BtnOpen 
				open={open===e.id_okr_objective} 
				onClick={()=> handleOpen(e.id_okr_objective)}
			/>,
			action: 
			<Tooltip
				html={
				<span style={{ color: '#fff', fontSize: 15 }}>
					{language.ADD_A_NEW_RESULT}
				</span>
				}
				position="right"
				theme="dark"
		  	>
				<i
				onClick={() => handleClickNewResult(e.id_okr_objective)}
				className="fas fa-plus icon my-1"
				style={{
					color: '#fff',
					cursor: 'pointer',
					fontSize: 13
				}}
				/>
		 	</Tooltip>,
			objective:e.objective,
			keys_total:e.keys_total,
			status: (
				<div className='content-progress' style={styleContentProgress}>
					<Progress current={e.status} hiddenText style={styleProgress} background='var(--danger)'/>
					<span style={{color:'white'}}>{e.status}%</span>
				</div>
			) 
		},
		e.dataNivel&&open=== e.id_okr_objective?{
			ConfigCell:{
				cols:{
					col_0:{
						colSpan:5
					}
				}
			},
			btn:<Nivel_1 onChangeMenu={onChangeMenu} index={i} data={e.dataNivel} onOpenAvaliation={handleOpenAvaliation} userOn={userOn}/>
		}:null
	].filter(Boolean));

	const departmentList = [{label: '-------TODOS-------', value: 0}, ...departments];
	const handleCloseResultForm = () => {
		setDialog(0) 
		setResult({})
	}
	const handleOpen = useCallback((id) => {
		setOpen(prev=>{
			return prev=== id ? null : id
		})
	}, [])

	const handleCloseSubactivityForm = () => {
		setDialog(0) 
		setSubactivity({})
	}

	const handleSubmitResult = useCallback(async (v) => {

		try {
			const result = !v.id_okr_results 
				? await action.execute('post', APIEndpoints.OKR + '/results', null, v, null, 'Criado com sucesso', true)
				: await action.execute('put', APIEndpoints.OKR + '/results/' + v.id_okr_results, null, v, null, 'Atualizado com sucesso', true);

			
			if(result.msg) {
				Store.toggleAlert(true, result.msg, 'info');

				window.location.reload();
			}

		} catch (error) {
			console.log('error', error);
		}
	}, [])
	const handleSubmitSubactivity = useCallback(async (v) => {

		try {
			let subactivity = !v.id_okr_results_subactivies ? 
			await action.execute('post', APIEndpoints.OKR + '/subactivity/', null, v, null, 'Criado com sucesso', true)
			: await action.execute('put', APIEndpoints.OKR + '/subactivity/' + v.id_okr_results_subactivies, null, v, null, 'Atualizado com sucesso', true);

			if(subactivity.msg) {
				Store.toggleAlert(true, subactivity.msg, 'info');

				window.location.reload();
			}

		} catch (error) {
			console.log('error', error);
		}
	}, [])

	const handleDeleteResult = useCallback(async (id) => {
		try {
			const result = await action.execute('delete', APIEndpoints.OKR + '/results/' + id);

			if(result.data.ok) {
				Store.toggleAlert(true, language.SUCCESSFULLY_DELETED_RESULT, 'info');

				window.location.reload();
			}
		} catch (error) {
			console.log('error', error);
		}
	}, [])

	const handleDeleteSubactivity = useCallback(async (id) => {
		try {
			const subactivity = await action.execute('delete', APIEndpoints.OKR + '/subactivity/' + id);
			
			if(subactivity.data.ok) {
				Store.toggleAlert(true, language.SUB_ACTIVITY_DELETED_SUCCESSFULLY, 'info');
				window.location.reload();
			}
		} catch (error) {
			console.log('error', error);
		}
	}, [])

	const handleSubmitAssociate = useCallback(async (values) => {
		try {
			const res = await action.execute('post', APIEndpoints.OKR + `/results/${idResult}/associate`, null, values, null, 'Associação realizada com sucesso.', true)

			if(res.msg) {
				Store.toggleAlert(true, result.msg, 'info');
				setOpenAssociate(0)
				setIdResult(0)
				window.location.reload();
			}
		} catch (error) {
			console.log('error', error);
		}
	}, [idResult])

	return(
		<div id='Block_2'>
			<AssociateActivityTable 
				open={openAssociate === 2}
				onClose={() => setOpenAssociate(0)}
				projects={projects}
				users={users}
				action={action}
            	APIEndpoints={APIEndpoints}
				onSave={handleSubmitAssociate}
			/>
			<AssociateTicketTable 
				open={openAssociate === 1}
				onClose={() => setOpenAssociate(0)}
				action={action}
				APIEndpoints={APIEndpoints}
				onSave={handleSubmitAssociate}
			/>
			<DialogAvaliation 
				values={avaliationParams}
				open={dialogAvaliation}
				onClose={() => {
					setDialogAvaliation(false)
					setAvaliationParams({})
				}}
				action={action}
				APIEndpoints={APIEndpoints}
			/>
			<DialogConfirm
				open={dialogDeleteResult}
				onClose={() => {
				setDialogDeleteResult(false)
				setResult({})
				}}
				title={language.DO_YOU_WANT_TO_REMOVE_THE_EXPECTED_RESULT}
				confirmProps={{ color: 'danger', _loading: 'delete-result_' + `${result ? result.id_okr_results : ''}` }}
				message={`${result ? result.id_okr_results : ''}` + ' - ' + `${result ? result.result_expected : ''}`}
				onConfirm={() => handleDeleteResult(result.id_okr_results)}
         	/>
			<DialogConfirm
				open={dialogDeleteSubactivity}
				onClose={() => {
				setDialogDeleteSubactivity(false)
				setSubactivity({})
				}}
				title={language.DO_YOU_WANT_TO_REMOVE_SUB_ACTIVITY}
				confirmProps={{ color: 'danger', _loading: 'delete-subaactivity_' + `${subactivity ? subactivity.id_okr_results_subactivies : ''}` }}
				message={`${subactivity ? subactivity.id_okr_results_subactivies : ''}` + ' - ' + `${subactivity ? subactivity.description : ''}`}
				onConfirm={() => handleDeleteSubactivity(subactivity.id_okr_results_subactivies)}
         	/>
			<Form 
				open={dialogForm} 
				onClose={()=>setDialogForm(false)}  
				departments={departments} 
				projects={projectsList} 
				users={users} 
				action={action}
				APIEndpoints={APIEndpoints}
				companyId={companyId}
			/>
			<FormExpectedResult 
				open={dialog==1} 
				onClose={handleCloseResultForm} 
				users={users}
				values={result}
				onSubmit={handleSubmitResult} 
				idObjective={idObjective}
				companyId={companyId}
			/>
				
			<FormSubActivities 
				open={dialog==2} 
				onClose={handleCloseSubactivityForm}
				users={users}
				onSubmit={handleSubmitSubactivity}
				values={subactivity}
				idResult={idResult}
			/>
			<CommonTable
				paginationTop={false}
				col={[
					{key:'btn', label:''},
					{key:'action', label:'#'},
					{ key: 'objective', label: language.OBJECTIVE },
					{ key: 'keys_total', label: language.TOTAL_KEYS },
					{ key: 'status', label: language.GENERAL_STATUS_OF_THE_PROJECT },
				]}
				beforeBar={
					<Button
						icon='fas fa-plus'
						color='warning'
						label={language.ADD}
						variant='circle'
						className='mr-3'
						onClick={()=>setDialogForm(true)}
					/>
				}
				afterBar2={
					<div className='content-select-area' style={{width:200, zoom:.9}}>
						<CommonForm
							values={{
								area: departmentId
							}}
							individual
							fields={[
								{
									name:'area',
									textlabel: language.DEPARTMENTS,
									type: 'autocomplete',
									name: 'area',
									label: language.DEPARTMENTS,
									method: 'POST',
									route: `${APIEndpoints.DEPARTMENT}/filter`,
									routeGetCount: `${APIEndpoints.DEPARTMENT}/count?`,
									fieldquery: 'search',
									textinit: language.DEPARTMENTS,
									fieldvaluedb: 'id_department',
									fieldlabeldb: 'name',
									idinit: 0,
									routeAll: `${APIEndpoints.DEPARTMENT}/list?`,
									methodGetAll: 'POST',
									detail: true,
									required: true,
									showOptionAll : true
								}
							]}
							onChangeField={(f,v)=>setDepartmentId(v)}
						/>
					</div>
				}
				data={_data}
			/>
		</div>
	)
}