import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Divider, Checkbox } from '@material-ui/core';
import RaisedButton from '@common/RaisedButton';
import Dialog from '@common/Dialog';
import PageLayout from '@common/PageLayout';
import { FaSlideshare } from 'react-icons/lib/fa';
import CommonForm from '@common/CommonForm2';
import moment, { lang } from 'moment';
import CommonPage from '@common/CommonPage';
import Dropzone from 'react-dropzone';
import './index.css';
import ProjectComment from '../ProjectComment';
import { Paper, Card, Grid, Chip, Avatar } from '@material-ui/core';
import AppBar from '@common/AppBar';
import SimplesAlert from '@common/SimplesAlert';
import { Tooltip } from 'react-tippy';
import ListColumn from '@common/ListColumn';
import AttachFile from '@common/AttachFile';
import Button from '@common/Button';

function DetailItem({ label, tooltip, icon, src }) {
  return (
    <Tooltip
      html={
        <div style={{ color: '#fff', fontSize: 15 }}>
          <strong>{tooltip}</strong>
        </div>
      }
      arrow
      position="right"
      theme="dark"
      open={false}
    >
      <div>
        <Chip
          className="chip detail-item-label shadown"
          label={<div className="ellipsis">{tooltip}</div>}
        />
        <Chip
          avatar={
            <Avatar
              style={{
                backgroundColor: 'white',
                border: '2px solid var(--primary)'
              }}
              src={src}
            >
              <i
                className={icon}
                style={{
                  width: 'auto',
                  height: 'auto',
                  color: 'var(--warning)'
                }}
              />
            </Avatar>
          }
          className="chip detail-item"
          label={<div className="ellipsis">{label}</div>}
        />
      </div>
    </Tooltip>
  );
}
function DetailItemProject(label) {
  let pro = '';
  if (label.toString().length > 40) {
    pro = label.substr(0, 40) + '. . .';
  } else {
    pro = label;
  }
  return (
    <Tooltip
      html={
        <div style={{ color: '#fff', fontSize: 15 }}>
          <strong>{label}</strong>
        </div>
      }
      arrow
      position="right"
      theme="dark"
      open={false}
    >
      <div>{pro}</div>
    </Tooltip>
  );
}

@observer
export default class ProjectManagementDetail extends CommonPage {
  constructor() {
    super();
    this.onChangeField = this.onChangeField.bind(this);
    this.removeSubActivity = this.removeSubActivity.bind(this);
    this.state = {
      openSubActivity: 0,
      id_sub_activity_runtime: 0,
      edit_subactivity: undefined,
      formStatus: '',
      itemsSubAc: [],
      val: {},
      runtime: null,
      runtimeError: undefined
    };
  }

  toggleModalSubActivity = n => {
    //console.log(this.state.runtime, this.state.runtimeError);
    if (this.state.openSubActivity == 2) {
      if (this.state.runtime) {
        this.addRuntime({ runtime: this.state.runtime });
        this.setState({
          openSubActivity: n,
          runtimeError: undefined,
          runtime: null
        });
      } else {
        this.setState({ runtimeError: 1 });
      }
    } else {
      this.setState({ openSubActivity: n });
    }
  };
  onChangeFieldRuntime = (f, v) => {
    this.setState({ runtime: v });
  };
  onChangeField(field, value) { }

  onChangeCheckbox = (f, v, selected) => {
    if (f.name == 'Sub_activity_management') {
      if (v.indexOf(selected) != -1) {
        this.setState({ id_sub_activity_runtime: selected });
        this.toggleModalSubActivity(2);
      } else {
        var { itemsSubAc } = this.state;
        this.setState({ itemsSubAc: itemsSubAc.filter(e => e.id != selected) });
      }
    }
  };
  removeSubActivity(e) {
    ///api/project/activity/subactivity/?id
    let { action, APIEndpoints, activity } = this.props;

    action
      .execute(
        'delete',
        APIEndpoints.PROJECT +
        '/activity/subactivity/' +
        e.id_sub_activity +
        '&' +
        e.fk_id_activity,
        'subActivities'
      )
      .then(e => {
        this.setState({
          val: {
            Sub_activity_management: e.data
              .filter(e => e.selected)
              .map(e => e.id_sub_activity)
          }
        });
      });
  }
  editSubActivity = e => {
    this.setState({ edit_subactivity: e });
    this.toggleModalSubActivity(1);
  };
  onChangeFieldFormStatus = (f, v) => {
    if (f.name == 'descriptionhistory') {
      this.setState({ formStatus: v });
    }
  };
  percent(start) {
    /* let cont = start;
     let statusActivity ;
      while(cont < 100){
       
      statusActivity =[{ value:cont, text: cont+'%'}]
      cont = cont +10;
      
       } */
  }
  componentWillMount() {
    let { action, APIEndpoints, activity } = this.props;
    action
      .execute(
        'get',
        APIEndpoints.PROJECT +
        '/activity/subactivity?fk_id_activity=' +
        (activity.length ? activity[0].id_project_activity : 0),
        'subActivities'
      )
      .then(e => {
        this.setState({
          val: {
            Sub_activity_management: e.data
              .filter(e => e.selected)
              .map(e => e.id_sub_activity)
          }
        });
      });
  }
  closedDialogs = () => {
    this.setState({ openSubActivity: false });
  };

  saveSubActivity = values => {
    let { action, APIEndpoints } = this.props;

    if (values.id_sub_activity) {
      action.execute(
        'put',
        APIEndpoints.PROJECT + '/activity/subactivity',
        'subActivities',
        values,
        'id_sub_activity'
      );
    } else {
      action.execute(
        'post',
        APIEndpoints.PROJECT + '/activity/subactivity',
        'subActivities',
        values
      );
    }
    this.setState({ openSubActivity: false });
    // this.toggleModalSubActivity()
  };

  submitSubActivity = v => {
    let {
      activity,
      values,
      subActivities,
      onFormSubmitActivityStatus,
      toggleModalDetail
    } = this.props;

    var sam = subActivities.filter(e => e.selected).map(e => e.id_sub_activity);
    var p = activity[0];
    var data = {
      id_project_activity: p.id_project_activity,
      id_project: values,
      status: parseInt(
        (100 / subActivities.length) * v.Sub_activity_management.length
      ),
      timeextend: this.state.itemsSubAc.map(e => e.runtime),
      descriptionhistory: this.state.formStatus,
      Sub_activity_management: v.Sub_activity_management,
      In_Sub_activity_management: subActivities
        .filter(e => v.Sub_activity_management.indexOf(e.id_sub_activity) == -1)
        .map(e => e.id_sub_activity)
    };

    onFormSubmitActivityStatus(data);
    toggleModalDetail(false, values);
  };
  addRuntime = v => {
    var { itemsSubAc, id_sub_activity_runtime } = this.state;
    itemsSubAc.push({
      id: id_sub_activity_runtime,
      runtime: v.runtime
    });
    this.setState({ itemsSubAc });
    this.setState({
      openSubActivity: 0,
      runtime: null,
      runtimeError: undefined
    });
  };

  render() {
    let {
      language,
      handlePage,
      activity,
      values,
      onFormSubmitActivityStatus,
      valueStatus,
      handleModalGrant,
      ondonwFile,
      ondeletefile,
      UpdateFiles,
      valuesset,
      fileup,
      files,
      removeFile,
      disabled,
      onDrops,
      toggleModalDetail,
      milestone,
      usersproject,
      subActivities
    } = this.props;

    let statusActivity = [];

    let imcrement = activity.map(p => {
      return p.percent === null ? '10' : parseInt(p.percent) + 10;
    })[0];

    while (parseInt(imcrement) < 110) {
      statusActivity.push({ value: imcrement, label: imcrement + '%' });
      imcrement = parseInt(imcrement) + 10;
    }

    let fields = [
      {
        col: 0,
        name: 'id_project_activity',
        label: 'Id Atv.',
        type: 'text',
        className: 'd-none',
        disabled: true
      },
      {
        col: 0,
        name: 'id_project',
        label: 'Id Pro.',
        type: 'text',
        className: 'd-none',
        disabled: true
      },
      {
        col: 12,
        name: 'descriptionhistory',
        placeholder: language.ACTIVITY_SUMMARY,
        type: 'textEditor',
        contentProps: { style: { marginBottom: -20 } },
        required: true
      }
    ];

    if (!subActivities.length) {
      fields.splice(1, 0, {
        col: 6,
        name: 'status',
        label: language.STATUS_ACTIVITY,
        type: 'select',
        startIcon: 'fas fa-percent',
        required: true,
        options: statusActivity
      });
      fields.splice(2, 0, {
        col: 6,
        name: 'timeextend',
        label: language.TIME_SPENT,
        type: 'time',
        startIcon: 'far fa-clock'
      });
    }
    let button = { label: language.SAVE };

    let activitylist = activity.map(p => {
      var act = milestone.find(
        e => e.id_project_milestone == parseInt(p.id_project_activity)
      );
      return (
        <div>
          <Grid container spacing={16} style={{ marginTop: 25 }}>
            <Grid item xs={6} className="CARD_DETAILS">
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="detailIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span className="font ml-2">{language.DETAILS}</span>
                  </div>
                </AppBar>
                <div className="content">
                  <ListColumn
                    col={4}
                    items={[
                      {
                        value: (
                          <DetailItem
                            tooltip={language.RESPONSIBLE}
                            label={p.usersproject}
                            icon="far fa-user"
                            src={
                              act.photo
                                ? this.APIRoot +
                                '/public/users/' +
                                p.fk_id_user +
                                '/profileLogo.png'
                                : undefined
                            }
                          />
                        )
                      },
                      {
                        value: (
                          <DetailItem
                            tooltip={language.PERCENTAGE}
                            label={p.percent === null ? 0 : p.percent + '%'}
                            icon="fas fa-percent"
                          />
                        )
                      },
                      {
                        value: (
                          <DetailItem
                            tooltip={language.DATE_CAD}
                            label={moment(p.dt_cad).format('L')}
                            icon="fas fa-calendar-alt"
                          />
                        )
                      },

                      {
                        value: (
                          <DetailItem
                            tooltip={language.INITIAL_DATE}
                            label={moment(p.dt_start).format('L')}
                            icon="fas fa-hourglass-start"
                          />
                        )
                      },
                      {
                        value: (
                          <DetailItem
                            tooltip={language.FINAL_DATE}
                            label={moment(p.dt_end).format('L')}
                            icon="fas fa-hourglass-end"
                          />
                        )
                      }
                    ]}
                  />
                </div>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="detailIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span className="font ml-2">
                      {language.ACTIVITY_SUMMARY}
                    </span>
                  </div>
                </AppBar>
                <div className="content">
                  <p
                    style={{ marginLeft: 10 }}
                    dangerouslySetInnerHTML={{ __html: p.description }}
                    className="item-description fontAll"
                  />
                </div>
              </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 20 }}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar>
                  <div className="AppBarCustomContent d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="bg-icon">
                        <div
                          className="detailIcon"
                          style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                      <span className="font ml-2">{language.DESCRIPTION}</span>
                    </div>
                    {
                      <Button
                        label="Cadastrar Sub-atividades"
                        icon="fas fa-plus"
                        color="warning"
                        className="ml-2"
                        variant="circle"
                        size={0.7}
                        onClick={() => {
                          this.toggleModalSubActivity(1);
                          this.setState({ edit_subactivity: undefined });
                        }}
                      />
                    }
                  </div>
                </AppBar>
                <div>
                  <FormStatus
                    language={language}
                    activity={activity}
                    onFormSubmitActivityStatus={e => {
                      onFormSubmitActivityStatus(e);
                      toggleModalDetail(false, values);
                    }}
                    fields={fields}
                    values={values}
                    valueStatus={valueStatus}
                    button={button}
                    idprojectactivity={p.id_project_activity}
                    submit={!subActivities.length}
                    onChangeField={this.onChangeFieldFormStatus}
                  />
                  {subActivities.length ? (
                    <Fragment>
                      <hr />
                      <div className="content">
                        <CommonForm
                          values={this.state.val}
                          sensitive
                          fields={[
                            {
                              type: 'listCheckbox',
                              name: 'Sub_activity_management',
                              label: 'Gestão de Sub-atividades',
                              selectAll: false,
                              options: subActivities.map(e => ({
                                value: e.id_sub_activity,
                                label: DetailItemProject(
                                  e.name +
                                  ' - ' +
                                  e.responsible +
                                  ' - ' +
                                  moment(e.dt_end).format('L HH:mm:ss')
                                ),
                                beforeComponent: (
                                  <Fragment>
                                    <RaisedButton
                                      circleButton
                                      icon="far fa-trash-alt"
                                      label={language.DELETE}
                                      color="danger"
                                      style={{
                                        fontSize: 13,
                                        width: 21,
                                        height: 21,
                                        float: 'left',
                                        marginTop: 13,
                                        marginRight: 4
                                      }}
                                      onClick={() => this.removeSubActivity(e)}
                                    />
                                    <RaisedButton
                                      circleButton
                                      icon="far fa-edit"
                                      label={language.EDIT}
                                      color="default"
                                      className="mx-1 mr-2"
                                      style={{
                                        fontSize: 13,
                                        width: 21,
                                        height: 21,
                                        float: 'left',
                                        marginTop: 13,
                                        marginRight: 4
                                      }}
                                      onClick={() => this.editSubActivity(e)}
                                    />
                                    <RaisedButton
                                      circleButton
                                      icon="fas fa-info-circle"
                                      label={
                                        e.name +
                                        ' - ' +
                                        e.responsible +
                                        ' - ' +
                                        moment(e.dt_end).format('L HH:mm:ss')
                                      }
                                      color="default"
                                      style={{
                                        fontSize: 13,
                                        width: 21,
                                        height: 21,
                                        float: 'left',
                                        marginTop: 13,
                                        marginRight: 4
                                      }}
                                    ></RaisedButton>
                                  </Fragment>
                                )
                              }))
                            }
                          ]}
                          onChangeField={this.onChangeCheckbox}
                          button={button}
                          onSubmit={this.submitSubActivity}
                        />
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </Card>
            </Grid>

            <Grid
              item
              xs={6}
              className="NOTES_ACTIVITY"
              style={{ paddingTop: 20 }}
            >
              <Card
                style={{ height: '100%', overflow: 'visible' }}
                className="pb-2"
              >
                <AppBar style={{ fontFamily: 'GeosansLight' }}>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="commentIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span className="font ml-2">{language.NOTES_ACTIVITY}</span>
                  </div>
                </AppBar>

                <ProjectComment
                  language={language}
                  ticket={p}
                  onCommentTicket={this.props.onFormSubmitComments}
                  maxHeightChat={291}
                />
              </Card>
            </Grid>

            <Grid item xs={6} style={{ paddingTop: 20 }}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar>
                  <div className="AppBarCustomContent align-items-center">
                    <Dropzone
                      className="bg-icon"
                      required
                      onDrop={onDrops.bind(this)}
                      style={{
                        width: 24,
                        height: 24,
                        backgroundColor: 'transparent'
                      }}
                    >
                      <RaisedButton
                        circleButton
                        label={language.ATTACH_FILE}
                        color="warning"
                        style={{
                          width: 24,
                          height: 24,
                          fontSize: 15,
                          color: 'white'
                        }}
                        icon="far fa-file-alt"
                      />
                    </Dropzone>
                    <span className="font ml-2">{language.ATTACH}</span>

                    {this.props.files.length > 0 && (
                      <RaisedButton
                        circleButton
                        className="ml-2"
                        icon="fas fa-paper-plane"
                        label={language.SEND}
                        color="success"
                        onClick={() => this.props.UpdateFiles(p)}
                        style={{ fontSize: 18, paddingRight: 3 }}
                      />
                    )}
                  </div>
                </AppBar>
                <div className="content">
                  <Row>
                    <Col md={12}>
                      <div style={{ paddingTop: 10 }}>
                        <aside>
                          {this.props.files.map(f => (
                            <div className="py-1">
                              <AttachFile
                                file={f}
                                onDelete={() => this.props.removeFile(f)}
                                style={{ maxWidth: '100%' }}
                              />
                            </div>
                          ))}
                        </aside>

                        {p.file != undefined
                          ? p.file.map(f => (
                            <div className="py-1">
                              <AttachFile
                                label={f.file}
                                onClick={() =>
                                  this.props.ondonwFile(
                                    f.id_project_activity,
                                    f.file
                                  )
                                }
                                onDelete={() => this.props.ondeletefile(f)}
                                style={{ maxWidth: '100%' }}
                              />
                            </div>
                          ))
                          : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Grid>
            <Grid item xs={6} className="slp" style={{ marginTop: 12 }}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="detailIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span className="font ml-2">{language.HISTORIC}</span>
                  </div>
                </AppBar>
                <Row>
                  <Col md={12}>
                    <div style={{ overflowY: true }}>
                      <p
                        style={{ color: '#888080', fontSize: '14px' }}
                        dangerouslySetInnerHTML={{
                          __html: p.description_history
                        }}
                        className="historic-list fontAll"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Grid>
            <Grid item xs={12} className="slp" style={{ marginTop: 12 }}>
              <Card style={{ height: '100%', overflow: 'visible' }}>
                <AppBar>
                  <div className="AppBarCustomContent">
                    <div className="bg-icon">
                      <div
                        className="detailIcon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <span className="font ml-2">
                      {'Atividades Vinculadas '}
                    </span>
                  </div>
                </AppBar>

                <div style={{ overflowY: true }} className="content">
                  {p.activity_prd &&
                    p.activityprd &&
                    p.activityprd != undefined &&
                    p.activityprd
                      .filter(h =>
                        p.activity_prd.includes(h.id_project_activity)
                      )
                      .map(f => (
                        <li>
                          {f.id_project_activity}- %{f.percent} {f.name} -{' '}
                          {f.namegroup}
                        </li>
                      ))}
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      );
    });

    return (
      <Fragment>
        <Dialog
          modal={false}
          title={activity[0] ? activity[0].name : null}
          open={open}
          maxWidth="lg"
          contentProps={{
            style: { backgroundColor: '#f8f8f8' }
          }}
          PaperProps={{
            style: {
              height: '100%'
            }
          }}
          onClose={() =>
            /*handleModalGrant('closeModalStatus', values)*/ toggleModalDetail(
            false,
            values
          )
          }
        //           onClose={()=>/*handleModalGrant('closeModalStatus', values)*/toggleModalDetail(false, values)}
        >
          <div id="ProjectManagementDetail">

            {activitylist}
          </div>
        </Dialog>
        <Dialog
          modal={false}
          title={
            this.state.openSubActivity == 1 ? 'Cadastrar Sub-Atividade' : ''
          }
          open={this.state.openSubActivity}
          onClose={() => this.closedDialogs(false)}
          maxWidth={this.state.openSubActivity == 1 ? 'md' : 'xs'}
        >
          {this.state.openSubActivity == 1 ? (
            <CommonForm
              values={this.state.edit_subactivity}
              fixedValue={{
                fk_id_activity: activity.length
                  ? activity[0].id_project_activity
                  : 0,
                fk_id_project: this.props.values
              }}
              fields={[
                {
                  col: 4,
                  name: 'name',
                  label: 'Nome da atividade',
                  type: 'text',
                  required: true
                },
                {
                  col: 4,
                  name: 'fk_id_responsible',
                  label: language.RESPONSIBLE,
                  type: 'select',
                  options: usersproject
                    .filter(e => e.fk_id_project == this.props.values)
                    .map(e => ({ label: e.name, value: e.id_person })),
                  required: true,
                  disabled: !!this.state.edit_subactivity ? true : false
                },
                {
                  col: 4,
                  name: 'dt_end',
                  label: language.FINAL_DATE,
                  type: 'date',
                  required: true,
                  disabled: !!this.state.edit_subactivity ? true : false
                }
              ]}
              onSubmit={this.saveSubActivity}
              button={button}
            />
          ) : this.state.openSubActivity == 2 ? (
            <CommonForm
              values={undefined}
              fixedValue={{}}
              fields={[
                {
                  col: 12,
                  name: 'runtime',
                  label: 'Tempo de execução',
                  type: 'time',
                  required: true,
                  error: this.state.runtimeError
                }
              ]}
              onSubmit={this.addRuntime}
              onChangeField={this.onChangeFieldRuntime}
              button={button}
            />
          ) : null}
        </Dialog>
      </Fragment>
    );
  }
}

class FormStatus extends Component {
  render() {
    let {
      language,
      onFormSubmitActivityStatus,
      valueStatus,
      idprojectactivity,
      values,
      fields,
      button,
      submit,
      onChangeField
    } = this.props;
    valueStatus = {
      id_project_activity: idprojectactivity,
      id_project: values
    };

    return (
      <div className="content">
        <CommonForm
          values={valueStatus}
          fields={fields}
          onChangeField={onChangeField}
          onSubmit={submit ? onFormSubmitActivityStatus : undefined}
          button={button}
        />
      </div>
    );
  }
}
