import React, {memo, useState, useEffect} from 'react';
import classnames from 'classnames';
import Button from '@common/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Pickers from './Pickers';

let delayTimer;
export default memo(({
	f, 
	statusError,
	_value,
	_handleValue,
	errors,
	individual,
	classes,
	variant,
	margin,
	id
	 
})=>{
	let [value, setValue] = useState(null);

	f.endIcon = f.endIcon||'fas fa-calendar-alt';

	function handleValue(val, name, selected){
	 
		setValue(val);

		clearTimeout(delayTimer);

		_handleValue(val, f.name)
		
	}
	function handleValue__(val){
 
		if(val.target.value ==null || val.target.value.length <2){
			_handleValue('', f.name)
		}  
		
	}
	
	useEffect(()=>{
		setValue(_value||null);
	}, [_value])

	
	 
	return(
		<Pickers
			error={statusError[errors[f.name]]||statusError[f.error]}
			f={f}
			type={f.type}
			label={f.label}
			value={value}
			onChange={val => handleValue(val, f.name)}
			helperText={!individual?(statusError[errors[f.name]]||f.help||' '):undefined}
			variant={f.variant||variant||'outlined'}
			margin={f.margin||margin||'dense'}
			disabled={f.disabled}
			onKeyUp={v=>handleValue__(v)}
			id = {id}
			required={!!f.required}
			placeholder={f.placeholder}
			className={'input-min date-picker '+f.className}
			InputLabelProps={{
				classes:{
					root:classes.label
				}
			}}
			InputProps={{
				classes:{
					underline:classes.underline
				},
				startAdornment:(
					f.startIcon &&
					
					<InputAdornment position="start">
						{(typeof f.startIcon)=='string'?(
						  <i className={classnames(f.startIcon, 'color-icon adormentIcon')}></i>
						):f.startIcon}
					</InputAdornment>
				),
				/*endAdornment:(
					f.endIcon &&
					
					<InputAdornment position="start">
						{(typeof f.endIcon)=='string'?(
						  <i className={classnames(f.endIcon, 'color-icon adormentIcon')}></i>
						):f.endIcon}
					</InputAdornment>
				),*/
				...f.InputProps
			}}
		/>
	)
})