import React, { memo } from 'react';
import './index.css';
import { Tooltip } from 'react-tippy';
// -----------------------------------common-----------------------------------------------
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import Progress from '@common/Progress';
import CommonForm from '@common/CommonForm2';
import store from '../../../../data/Store';
// -----------------------------------material-ui------------------------------------------
import { Avatar, Checkbox, Grid } from '@material-ui/core';
// -----------------------------------components------------------------------------------
import Store from '@data/Store';

export default memo(
  ({
    open,
    onClose,
    values = {},
    options,
    project = {},
    action,
    APIEndpoints,
    id_project_activity,
    template,
    onSubmit,
  }) => {
    const { language } = Store;
console.log('project.users',project)
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
        title={language.RECORD_UNDERACTIVITY}
        PaperProps={{
          id: 'ViewActivity',
        }}
        contentProps={{
          style: {
            background: '#F5F5F5',
          },
        }}
      >
        <div>
          <CommonForm
            values={values}
            fixedValue={{
              fk_id_project: project.id_project,
              fk_id_activity: id_project_activity,
            }}
            fields={[
              {
                col: 12,
                name: 'name',
                label: language.ACTIVITY_NAME,
                type: 'text',
                required: true,
              },
              {
                col: 12,                
                type: 'autocomplete',
                name: 'fk_id_responsible',
                label: language.RESPONSIBLE,
                textlabel: language.RESPONSIBLE,
                method: "POST",
                route: `${APIEndpoints.USER}/filter`,
                routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                filterAll:{ fk_id_company : project.fk_id_company, deleted : false, status : true, current : true,fk_id_user:project.users},
                fieldquery: 'search',
                textinit:  values ? values.user_cad_name : language.RESPONSIBLE,
                fieldvaluedb: 'id_user',
                fieldlabeldb: 'name',
                idinit: values ? values.user_cad : 0, 
                routeAll: `${APIEndpoints.USER}/filter/all?`,
                detail: true,
                methodGetAll : 'POST',
                required:
                  template == true
                    ? false
                    : !Object.keys(values).length
                    ? true
                    : false,
              },
              {
                col: 12,
                name: 'dt_end',
                label: language.FINAL_DATE,
                type: 'date',
                required: !Object.keys(values).length ? true : false,
                //  disabled: !!Object.keys(values).length ? true : false
              },
            ]}
            loading="subactivities"
            onSubmit={onSubmit}
          />
        </div>
      </Dialog>
    );
  }
);
