import React, { useEffect, useState } from 'react';
import { Card, Grid, Button, Paper } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import lodash from 'lodash';
import PageLayout from '@common/PageLayout';
import CommonPage from '@common/CommonPage';
import Header from '@common/Header';
import AppBar from '@common/AppBar2';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';

import moment from 'moment';

import Constants from '@data/Constants';
import Store from '@data/Store';
import Actions from '@data/Actions';

const action = new Actions();

import { PeriodTask } from './PeriodTask';
import { TotalTaskChart } from './TotalTaskChart';
import { RankingTask } from './RankingTask';
import { FilterMenu } from './FilterMenu';

const useStyles = makeStyles((theme) => ({
  fullHeightCard: {
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #DEDEDE',
    height: '100%',
  },
}));

export default function DashboardTaskManagement() {
  let { language, host } = Store;
  const [totalTasks, setTotalTasks] = useState({});
  const [vencido, setVencido] = useState(0);
  const [prazo, setPrazo] = useState(0);
  const [taskPeriod, setTaskPeriod] = useState();
  const [tasks, setTasks] = useState([]);
  const classes = useStyles();

  const tasksFilter = (uniqueUsers) => {
    const dateFromChild = lodash.uniqBy(uniqueUsers, 'id_tasks');

    setTasks(dateFromChild);
    let totalVencido = 0;

    let totalPrazo = 0;
    const totalNotFinish = dateFromChild.filter(
      (r) =>
        r.status == 'AGUARDANDO' ||
        r.status == 'ANDAMENTO' ||
        r.status == 'INICIADO'
    );
    const completo = dateFromChild.filter((r) => r.status == 'CONCLUIDO');

    totalNotFinish.filter((r) => {
      const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
      const dateTime = moment(dtFormat)
        .add(r.start_time, 'hours')
        .format('YYYY-MM-DD HH:mm');
      const dateAddTime = moment(dateTime)
        .add(r.time_execution, 'hours')
        .format('YYYY-MM-DD HH:mm');

      const dateNow = moment().format('YYYY-MM-DD HH:mm');

      if (moment(dateNow).isAfter(moment(dateAddTime))) {
        totalVencido += 1;
      } else {
        totalPrazo += 1;
      }
    });

    setTotalTasks({
      totalPrazo,
      totalVencido,
      totalCompleto: completo.length,
    });
    // setPrazo(totalPrazo);
    // setVencido(totalVencido)

    // setCompleto(completo.length)

    const format = 'YYYY-MM-DD';
    const start = moment(moment().startOf('year'), format),
      end = moment(moment().endOf('year'), format);
    const months = [];
    while (start.isBefore(end)) {
      months.push({
        start: start.startOf('month').format(format),
        end: start.endOf('month').format(format),
      });
      start.add(1, 'month');
    }

    const monthTT = [];

    const jan = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[0].start && r.dt_start.split('T')[0] <= months[0].end
    );
    const fev = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[1].start && r.dt_start.split('T')[0] <= months[1].end
    );
    const mar = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[2].start && r.dt_start.split('T')[0] <= months[2].end
    );
    const abr = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[3].start && r.dt_start.split('T')[0] <= months[3].end
    );
    const mai = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[4].start && r.dt_start.split('T')[0] <= months[4].end
    );
    const jun = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[5].start && r.dt_start.split('T')[0] <= months[5].end
    );
    const jul = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[6].start && r.dt_start.split('T')[0] <= months[6].end
    );
    const ago = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[7].start && r.dt_start.split('T')[0] <= months[7].end
    );
    const set = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[8].start && r.dt_start.split('T')[0] <= months[8].end
    );
    const out = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[9].start && r.dt_start.split('T')[0] <= months[9].end
    );
    const nov = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[10].start && r.dt_start.split('T')[0] <= months[10].end
    );
    const dez = dateFromChild.filter(
      (r) => r.dt_start.split('T')[0] > months[11].start && r.dt_start.split('T')[0] <= months[11].end
    );

    monthTT.push(jan);
    monthTT.push(fev);
    monthTT.push(mar);
    monthTT.push(abr);
    monthTT.push(mai);
    monthTT.push(jun);
    monthTT.push(jul);
    monthTT.push(ago);
    monthTT.push(set);
    monthTT.push(out);
    monthTT.push(nov);
    monthTT.push(dez);

    setTimeout(() => {
      setTaskPeriod(monthTT);
    }, 1000);
  };

  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.TASKS}/all?type=status`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }

        setTasks(res);
        let totalVencido = 0;

        let totalPrazo = 0;
        const totalNotFinish = res.filter(
          (r) =>
            r.status == 'AGUARDANDO' ||
            r.status == 'ANDAMENTO' ||
            r.status == 'INICIADO'
        );
        const completo = res.filter((r) => r.status == 'CONCLUIDO');

        totalNotFinish.filter((r) => {
          const dtFormat = moment(r.dt_start).format('YYYY-MM-DD');
          const dateTime = moment(dtFormat)
            .add(r.start_time, 'hours')
            .format('YYYY-MM-DD HH:mm');
          const dateAddTime = moment(dateTime)
            .add(r.time_execution, 'hours')
            .format('YYYY-MM-DD HH:mm');

          const dateNow = moment().format('YYYY-MM-DD HH:mm');

          if (moment(dateNow).isAfter(moment(dateAddTime))) {
            totalVencido += 1;
          } else {
            totalPrazo += 1;
          }
        });

        setTotalTasks({
          totalPrazo,
          totalVencido,
          totalCompleto: completo.length,
        });
        // setPrazo(totalPrazo);
        // setVencido(totalVencido)

        // setCompleto(completo.length)

        const format = 'YYYY-MM-DD';
        const start = moment(moment().startOf('year'), format),
          end = moment(moment().endOf('year'), format);
        const months = [];
        while (start.isBefore(end)) {
          months.push({
            start: start.startOf('month').format(format),
            end: start.endOf('month').format(format),
          });
          start.add(1, 'month');
        }

        const monthTT = [];

        const jan = res.filter(
          (r) => r.dt_start > months[0].start && r.dt_start < months[0].end
        );
        const fev = res.filter(
          (r) => r.dt_start > months[1].start && r.dt_start < months[1].end
        );
        const mar = res.filter(
          (r) => r.dt_start > months[2].start && r.dt_start < months[2].end
        );
        const abr = res.filter(
          (r) => r.dt_start > months[3].start && r.dt_start < months[3].end
        );
        const mai = res.filter(
          (r) => r.dt_start > months[4].start && r.dt_start < months[4].end
        );
        const jun = res.filter(
          (r) => r.dt_start > months[5].start && r.dt_start < months[5].end
        );
        const jul = res.filter(
          (r) => r.dt_start > months[6].start && r.dt_start < months[6].end
        );
        const ago = res.filter(
          (r) => r.dt_start > months[7].start && r.dt_start < months[7].end
        );
        const set = res.filter(
          (r) => r.dt_start > months[8].start && r.dt_start < months[8].end
        );
        const out = res.filter(
          (r) => r.dt_start > months[9].start && r.dt_start < months[9].end
        );
        const nov = res.filter(
          (r) => r.dt_start > months[10].start && r.dt_start < months[10].end
        );
        const dez = res.filter(
          (r) => r.dt_start > months[11].start && r.dt_start < months[11].end
        );

        monthTT.push(jan);
        monthTT.push(fev);
        monthTT.push(mar);
        monthTT.push(abr);
        monthTT.push(mai);
        monthTT.push(jun);
        monthTT.push(jul);
        monthTT.push(ago);
        monthTT.push(set);
        monthTT.push(out);
        monthTT.push(nov);
        monthTT.push(dez);

        setTimeout(() => {
          setTaskPeriod(monthTT);
        }, 2000);
      });
  }, []);

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={language.DASHBOARD}
        subTitle={language.TASK_MANAGEMENT + '/'}
        rightElements={[<FilterMenu tasksFilter={tasksFilter} />]}
      >
        <Header />
        <Card className="content-min px-3">
          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card className="card-GP mt-2 border-radius-10" elevation={0}>
                <AppBar
                  title={language.TASKS_PER_PERIOD}
                  titleProps={{ style: { fontSize: '0.70em' } }}
                />
                <PeriodTask taskPeriod={taskPeriod} />
              </Card>
            </Grid>

            <Grid item={true} xs={6}>
              <Paper
                elevation={0}
                className={classes.fullHeightCard}
                className={classes.paper}
              >
                <Card className="card-GP mt-2 border-radius-10" elevation={0}>
                  <AppBar
                    title={language.TASK_TOTALS}
                    titleProps={{ style: { fontSize: '0.70em' } }}
                  />
                  <TotalTaskChart totalTasks={totalTasks} />
                </Card>
              </Paper>
            </Grid>

            <Grid item={true} xs={6}>
              <Paper
                elevation={0}
                className={classes.fullHeightCard}
                className={classes.paper}
              >
                <Card className="card-GP mt-2 " elevation={0}>
                  <AppBar
                    title={language.RANKING}
                    titleProps={{ style: { fontSize: '0.70em' } }}
                  />
                  <RankingTask tasks={tasks} />
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </PageLayout>
    </CommonPage>
  );
}
