import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';

@observer
export default class TicketNotes extends Component {
  percent(start) {
    let count = start;
    let statusActivity = [];
    if (count == 0) {
      count = 10;
    } else {
      count = count + 10;
    }
    while (count < 110) {
      statusActivity.push({ value: count, label: `${count}%` });
      count = count + 10;
    }

    return statusActivity;
  }

  render() {
    let {
      language,
      onNotesTicket,
      ticket,
      statusTicket,
      url,
      type_tickets
    } = this.props;
    let button = null;
    if (statusTicket == 'In Progress' ||statusTicket == 'Reopened' ) {
      button = {
       
        label: language.SAVE,
      };
    } else {
      button = {
        
        label: language.SAVE,
      };
    }


    let fields = [
      {
        col: 6,
        name: 'progress',
        label: language.STATUS_ACTIVITY,
        type: 'select',
        required: true,
        options: this.percent(ticket.config[0].progress),
        startIcon:'fas fa-percent'
      },
      {
        col: 6,
        name: 'time',
        label: language.TIME_SPENT,
        type: 'time',
        required: true,
        startIcon:'far fa-clock'
        
      },
      {
        col: 12,
        name: 'notes',
        label: language.DESCRIPTION,
        type: 'textEditor',
        required: true,
      }
    ];

    return (
      <div className='content'>
        <CommonForm
          language={language}
          fields={fields}
          button={button}
          onSubmit={
            
            statusTicket == 'In Progress' ||statusTicket == 'Reopened'  ?
            
            values =>
            onNotesTicket(ticket.id_tickets, values, url, type_tickets):
            null
          }
        />
      </div>
    );
  }
}
