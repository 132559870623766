import React, { useState, useEffect, useRef } from 'react';


import ReactHighcharts from 'react-highcharts';

import './index.css'

import ReactToPrint from 'react-to-print';
import moment from 'moment'
/* ----------------- common ------------------- */
import BarBlue from '@images/BarBlue.png';
import { formatCurrency } from '../../../../../util';
import { Grid } from '@material-ui/core';
import Logo from '../../../../../../public/images/newLogoSensrWhite.png'
export default function ({ gannt, pmos, dataGantt }) {

  const componentRef = useRef(null);
  useEffect(() => {
    try {


    } catch (e) {

      console.log(e)
    }
  }, []);

  let data = {
    logo: Logo,
    name: '',
    typeProject: 'improvement',
    listInfo: [
      {
        label: 'Mês:',
        value: '',
      },
      {
        label: 'Contrato:',
        value: '',
      },
      {
        label: 'Tipo:',
        value: '',
      },
      {
        label: '',
        value: '',
      },
      {
        label: '',
        value: '',
      },
      {
        label: '',
        value:
          ''

      },

      {
        label: 'Cliente:',
        value: '',
      },

    ],
    cardInfo: [
      {
        label: 'Quantidade de  Projetos',
        value: dataGantt.length
      }
    ]

  };

  return (
    <div id="Contract-report" ref={componentRef}>
      {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
      <header
        style={{ background: `url(${BarBlue})` }}
        className="content d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <img src={data.logo} alt="" />
          <div>
            <span>Gannt de Projetos</span>
            <h2>{''}</h2>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <h4>
            {moment().format('DD/MM/YYYY HH:mm')}
            {" - "}
            {``}
          </h4>
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.


              return <i id="btnimprimiros" class="fas fa-print"></i>;
            }
            }

            content={() => componentRef.current}
          />
        </div>
      </header>

      <div>
        {/*<div
            style={{
              backgroundColor: `var(--primary)`,
            }}
            className="content-contract-type content-medium"
          >
            <span> </span>
            <span>
             
            </span>
          </div> */}


        {/*------------------------------------------------------------------------------------------------------------------------------------------*/}
        {/*------------------------------------------------------------------------------------------------------------------------------------------*/}



        <Grid container spacing={2} className="px-3 mt-3">
          {data.cardInfo.map((e) => (
            <Grid item xs={3} key={e.label}>
              <div className="content-info-cards content">
                <span>{e.label}</span>
                <h4>{e.value}</h4>
              </div>
            </Grid>
          ))}
        </Grid>
        <hr />

        <ul className="content-contract-data px-3">

          <React.Fragment>


            {gannt}

          </React.Fragment>

        </ul>
      </div>
    </div>
  )
}