import RaisedButton from '../../../../../common/RaisedButton';
import React, { Component } from 'react';
import lodash from 'lodash';
import { Container, Row, Col } from 'react-grid-system';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDesktop,
  faMemory,
  faMicrochip,
  faHdd,
  faBroadcastTower
} from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  Dialog,
  Paper,
  SelectField,
  MenuItem,

  RadioButton,
  CircularProgress
} from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../../graphs/AreaSplineGraph.js';
import Boll from '@common/Boll';
import { Grid } from "@material-ui/core";
import './GraphMonitorDevice.css';

export default class GraphMonitorDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnSelected: 0,
      valueDate: []
    };
    this.change = this.change.bind(this);
  }

  componentDidMount() {
    let array = [];
    var dateSplit = '';

    this.props.data.map(dev => {
      if (dev.name == this.props.namegraph) {
        dateSplit = dev.date + '.';
        var dateSplitTemp = dateSplit.split('T')[0];
        var dataR = dateSplitTemp.substring(12, dateSplitTemp.indexOf('-'));
        array.push({ valor: dev.percent, date: dataR });
      }
    });
    this.arrayDesc = lodash.orderBy(array, ['type', 'date'], ['asc', 'desc']);

    var temp = this.arrayDesc.slice(0, 7);
    var temp2 = lodash.orderBy(temp, ['type', 'date'], ['asc', 'desc']);
    this.setState({ valueDate: temp2, colorBtn1: true });
  }

  change(number) {
    this.setState({
      btnSelected: number
    });

    var temp = this.arrayDesc.slice(0, number);
    var temp2 = lodash.orderBy(temp, ['type', 'date'], ['asc', 'desc']);
    this.setState({ valueDate: temp2 });
  }

  render() {
    switch (this.props.namegraph) {
      case 'CPU':
        this.name = 'Cpu';
        this.icon = <FontAwesomeIcon icon={faMicrochip} />;
        break;
      case 'MEMORIA':
        this.name = 'Memoria';
        this.icon = <FontAwesomeIcon icon={faMemory} />;
        break;
      case 'DISCO':
        this.name = 'Disco';
        this.icon = <FontAwesomeIcon icon={faHdd} />;
        break;
      case 'REDES':
        this.name = 'Redes';
        this.icon = <FontAwesomeIcon icon={faBroadcastTower} />;
        break;
      default:
    }

    var valueDate = this.state.valueDate.map(e => ({ name: e.date, y: e.valor })).sort(function (a, b) {

      var textA = a.name;
      var textB = b.name;

      return textA.localeCompare(textB);

    });

    return (
      <div id='GraphMonitorDevice'>

        <ReactHighcharts config={ContainerChart.container0({
          title: this.name,
          subtitle: '',
          yTitle: '',
          series: [
            {
              type: 'areaspline',
              name: this.name,
              data: valueDate
            }
          ],
        })}
        />

        <Grid container spacing={3} justify="center">
          {[7, 15, 30, 60, 90].map(e => (
            <Grid item xs={2}>
              <Boll
                descriptionProps={{
                  style: {
                    width: 45
                  }
                }}
                value={e}
                description='Dias'
                onClick={() => this.change(e)}
                color={this.state.btnSelected == e ? 'var(--success)' : 'var(--warning)'}
              />
            </Grid>
          ))}
        </Grid>

      </div>
    );
  }
}
