import React, {Fragment, useState, useEffect} from 'react';
import Store from '@data/Store';
import Button from '@common/Button';
import {Menu, MenuItem} from '@material-ui/core';
import { Tooltip } from 'react-tippy';

const { language } = Store;

export default function({
  onChange=()=>null,
  value,
  options=[]
}){
  let {language} = Store;
  let [_value, setValue] = useState(null);
  let [anchorEl, setAnchorEl] = useState(null);
  
  useEffect(()=>{
    if(!value&&!_value){
      setValue(options[0].value);
    }else if(value){
      setValue(value);
    }
  }, [options])

  return(
    <Fragment>
      <div className='d-flex align-items-center'>
        <Button
          icon='fas fa-chevron-left'
          label={language.PREVIOUS}
          color='success'
          variant='circle'
          outlined
          style={{
            border:'none',
            fontSize:12
          }}
          onClick={()=>{
            setValue(selected=>{
              var op = options.findIndex(e=>e.value==selected);
              var c = Math.max(op-1, 0);
              c = options[c].value;
              onChange(c)
              return c;
            });
          }}
        />
        <Tooltip
          html={
            <span style={{color:'white'}}>{(options.find(e=>e.value==_value)||{}).label}</span>
          }
          arrow={true}
          position='left'
          theme='dark'
          
        >
          <h3
            aria-owns={anchorEl ? 'long-menu' : undefined}
            onClick={evt => setAnchorEl(evt.currentTarget)}
            className='label-month-filter px-3 ellipsis'
            style={{width:113, textAlign:'center'}}
          >
            {(options.find(e=>e.value==_value)||{}).label}
          </h3>
        </Tooltip>
        <Button
          icon='fas fa-chevron-right'
          label={language.NEXT}
          color='success'
          variant='circle'
          outlined
          style={{
            border:'none',
            fontSize:12
          }}
          onClick={()=>{
            setValue(selected=>{
              var op = options.findIndex(e=>e.value==selected);
              var c = Math.min(op+1, options.length-1)
              
              c = options[c].value;
              onChange(c)
              return c;
            });
          }}
        />
      </div>
      <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={()=> setAnchorEl(null)}
          
        >
          {options.map((option,i) => (
            <MenuItem 
              key={option.label+'-'+i} 
              onClick={()=>{
                setAnchorEl(null);
                onChange(option.value)
                setMonthSelected(option.value)
              }}
              style={_value==option.value?{fontWeight:'bold'}:{}}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  )
}