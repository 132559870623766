import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Divider } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PageLayout from '../../common/PageLayout';
import './gestaoContratos.css';
import { observer } from 'mobx-react';
// -----------------------select-------------------------
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// -----------------------------------------------------
import ContainerChart from './gestaoContratosConfig.js'
import ReactHighcharts from 'react-highcharts';

import { SelectField, MenuItem } from '@material-ui/core';

import CommonForm from '../../common/CommonForm';

//1 comsumo deo contrato,
//2 tendencia
//3 consumo do contrato por por módulo
//4 fica em serviceorder / banco de dados tb_os / tb_contract

@observer
export default class GestaoContratos extends Component {
	state = {
		age: '',
		name: 'hai',
		labelWidth: 0,
		idx: 0
	};

	handleChange = event => {

		this.setState({ [event.target.name]: event.target.value });
	};
	componentWillMount() {
		// this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
	}
	nav = (idx) => {
		this.setState({
			idx: idx
		});
	}
	render() {
		let { language, contracts, handleCompany, companies } = this.props;
		let { idx } = this.state;
		/*let companiesSelect = companies.filter(c => c.type !== 2).map(c => (
					<MenuItem
						key={c.id_company}
						value={c.id_company}
						primaryText={c.name}
					/>
			));*/

		var companiesSelect = companies.filter(c => c.type !== 2).map(c => ({ value: c.id_company, text: c.name }));
		companiesSelect = [{
			span: 3,
			name: 'fk_id_priority',
			label: language.COMPANY,
			type: 'select',
			options: companiesSelect,
			// value:this.props.companyId,
		}]
		let button = {
			md: 2,
			offset: { md: 10 },
			label: language.SAVE,
			primary: true,
			style: { margin: 10 }
		};
		let contracts_ = contracts.map(c => (
			<Contract
				key={c.id_contract}
				{...this.state}
				language={language}
				contractsCount={contracts.length}
				nav={this.nav}
				contract={c}
			/>
		));
		return (
			<PageLayout
				icon={
					<div className="evolutionIcon" style={{ width: 44, height: 44 }} />
				}
				title={language.CONTRACTS_MANAGEMENT}
				subtitle={language.DASHBOARD}
				rightElements={[]}
			>
				{/*<div style={{marginLeft:'100%', width:400}}>
		        	<CommonForm
			            language={language}
			            button={button}
			            fields={companiesSelect}
			            onChange={(event, key, values) => handleCompany(values)}
			        />
		        </div>*/}
				<div className="content" id='gestaoContratos'>
					<Container id="content-body" fluid>


						{/*<div id='representation'>
							<Row id='block-2'>
								<Col sm={12} md={12} className='col'>
									<div id='form-control-empresa'>
										<FormControl style={{width:'250px'}} className='form-control'>
								          <InputLabel htmlFor="age-native-simple">Empresa</InputLabel>
								          <Select
								            native
								            value={this.state.age}
								            onChange={this.handleChange}
								            inputProps={{
								              name: 'age',
								              id:'age-native-simple'
								            }}
								          >
								            <option value="" />
								            <option value={1}>Empresa_1</option>
								            <option value={2}>Empresa_2</option>
								            <option value={3}>Empresa_3</option>
								          </Select>
								        </FormControl>
							        </div>
						        </Col>
							</Row>
						</div>*/}
						<Row>
							<Col sm={12} md={9}>
								<SwipeableViews index={idx}>{contracts_}</SwipeableViews>
							</Col>
							<Col md={3}>
								<Card className="cardDefault">
									<div className="titleCardCio">
										<div className="contractIcon cardIcon" />
										<h2>{language.CONTRACT_LIST}</h2>
									</div>
									<Container fluid style={{ maxHeight: 550, overflow: 'auto' }}>
										{contracts.map((c, k) => {
											return (
												<h3
													className="pointer"
													style={{
														display: 'flex',
														alignItems: 'center',
														width: '100%',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
														fontSize: 10,
														textAlign: 'left',
														opacity: k === idx ? 1 : 0.5
													}}
													key={k}
													onClick={() => this.nav(k)}
												>
													<div className="contractIcon cardIcon" />
													{c.name}
												</h3>
											);
										})}
									</Container>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</PageLayout>
		)
	}
}

class Contract extends Component {
	render() {
		let { language, contract } = this.props;
		return (
			<Row>
				<Col sm={12} md={6}>
					<div className='simple-card'>
						<ReactHighcharts config={ContainerChart.container0({ data: contract.trend, language, name: contract.name })}></ReactHighcharts>
					</div>
				</Col>
				{/*<Col sm={12} md={6}>
					<div className='simple-card'>
						<ReactHighcharts config = {ContainerChart.container1}></ReactHighcharts>
					</div>
				</Col>*/}
				<Col sm={12} md={6}>
					<div className='simple-card'>
						<ReactHighcharts config={ContainerChart.container3({ data: contract.trend, language, name: contract.name })}></ReactHighcharts>
					</div>
				</Col>
				<Col sm={12} md={6}>
					<div className='simple-card'>
						<ReactHighcharts config={ContainerChart.container2}></ReactHighcharts>
					</div>
				</Col>

				<Col sm={12} md={6}>
					<div className='simple-card'>
						<ReactHighcharts config={ContainerChart.container4}></ReactHighcharts>
					</div>
				</Col>
				<Col sm={12} md={6}>
					<div className='simple-card'>
						<ReactHighcharts config={ContainerChart.container5}></ReactHighcharts>
					</div>
				</Col>
			</Row>
		)
	}
}