import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonPage from '@common/CommonPage';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
const theme = createTheme({
	palette: {
		primary: { main: '#009dc0' }
	},

});

@observer
export default class Loading extends CommonPage {
	render() {
		var { size, loading, thickness, msg, color, image, style, ...other } = this.props;
		var msgLoading = false;
		if (loading !== undefined) {
			loading = loading && !!this.store.loading;
			msgLoading = this.props.loading;
		} else {
			loading = this.store.loading;
		}
		return (
			<Fragment>
				{loading ? (
					<div style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '20px 0',
						height: '100%',
						...style
					}}
						{...other}
					>

						<i className='fas fa-spinner fa-spin' style={{ fontSize: size || 70, color: color || 'var(--primary)' }} />

					</div>
				) : (
					<Fragment>
						{!msgLoading && this.props.children}
						{msgLoading && msg != false ?
							<div style={{
								height: '100%', width: '100',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								<div>
									{image &&
										<i className="far fa-folder-open color-lead" style={{ textAlign: 'center', color, display: 'block', fontSize: 43 }} />
									}
									<Typography
										variant="h6"
										className='font'
										style={{
											padding: '20px 0',
											fontWeight: 100,
											textAlign: 'center',
											color,
											...(image ? { fontSize: 15 } : {})
										}}
									>
										{msg || 'Nenhum registro encontrado'}
									</Typography>
								</div>
							</div>
							: msgLoading ?
								this.props.children : null
						}
					</Fragment>
				)}
			</Fragment>
		)
	}
}