import { useEffect, useRef } from 'react';

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

function GoogleAuth(props) {
  const googleButton = useRef(null);
  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';

    loadScript(src)
      .then(() => {
       
        google.accounts.id.initialize({
          client_id:process.env.GSUITE    ,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(
          googleButton.current, //this is a ref hook to the div in the official example
          { theme: 'outline', size: 'large' } // customization attributes
        );
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  //This is my main and only objective, to get the token...
  function handleCredentialResponse(response) {
    var tokens = response.credential.split('.');
    var payload = JSON.parse(atob(tokens[1]));
    props.onLogin({
      username: payload.email,
      auth: 'google',
      id: payload.aud,
    });
  }

  return <div ref={googleButton}></div>;
}

export default GoogleAuth;
