import React, { Component } from 'react';
import { Dialog, Card, Paper } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';

import Constants from '../../data/Constants';
import Help from '../../common/components/Help';
import PageLayout from '../../common/PageLayout';
import RaisedButton from '../../common/RaisedButton';

export default class AuditRdp extends Component {
  render() {
    let { language, handlePage, assessment, videos } = this.props;

    if (videos.length > 0) {
      return (
        <PageLayout
          icon={<div className="auditIcon" style={{ width: 44, height: 44 }} />}
          title={language.REPORTS}
          subTitle={language.DASHBOARD_PAGE.GCF + '/'}
          rightElements={[
            <RaisedButton
              label={language.BACK}
              primary={true}
              onClick={() => handlePage('contract')}
            />,
          ]}
        >
          <Container fluid>
            <Card className="cardDefault">
              <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
                {videos
                  .filter((c) => c.toString().indexOf('mp4') > -1)
                  .map((video, i) => (
                    <Col key={i} md={6} style={{ marginTop: 20 }}>
                      <video
                        className="videoAudit"
                        style={{ width: '100%' }}
                        src={`${Constants.APIRoot}/assessment/${assessment.str_path}.mp4`}
                        controls
                      />
                    </Col>
                  ))}
              </Row>
            </Card>
          </Container>
        </PageLayout>
      );
    } else {
      return (
        <PageLayout
          icon={<div className="auditIcon" style={{ width: 44, height: 44 }} />}
          title={language.REPORTS}
          subtitle={language.REPORTS_AUDIT}
          rightElements={[
            <RaisedButton
              label={language.BACK}
              primary={true}
              onClick={() => handlePage('contract')}
            />,
          ]}
        >
          <Container fluid>
            <Card className="cardDefault">
              <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Col md={6} style={{ marginTop: 20 }}>
                  <video
                    className="videoAudit"
                    style={{ width: '100%' }}
                    src={`${Constants.APIRoot}/assessment/${assessment.str_path}.mp4`}
                    controls
                  />
                </Col>
              </Row>
            </Card>
          </Container>
        </PageLayout>
      );
    }
  }
}
