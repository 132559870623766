import React from 'react';
import CommonForm from '../../../common/CommonForm2';
import Store from '@data/Store';
import Constants from '@data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const ThemeSacForm = ({ language, setForm, callPageSelectFilter, values_ }) => {
  const button = {
    label: language.SAVE,
  };

  const onFormSubmit = (values) => {
    const method = values.id_theme ? 'PUT' : 'POST';
    const url = values.id_theme
      ? `${Constants.APIEndpoints.SAC_THEMES}/updatetheme`
      : Constants.APIEndpoints.SAC_THEMES;

    action
      .execute(method, url, '', { ...values, user: Store.getUserLoged() })
      .then((res) => {
        setForm(false);
        callPageSelectFilter();
      })
      .catch((e) => {
        console.log('Error:', e);
      });
  };

  const fields = [
    {
      name: 'name',
      label: 'Tema',
      type: 'text',
      required: true,
      col: 12,
    },
    {
      col: 12,
      type: 'autocomplete',
      name: 'fk_id_group_theme',
      label: language.GROUP_TECH,
      className: 'tech-group-ticket',
      required: false,
      method: 'POST',
      route: `${Constants.APIEndpoints.GROUP}/filter`,
      filterAll: { fk_id_user: Store.getUserLoged().id },
      fieldquery: 'search',
      textinit: values_?.name_group_theme
        ? values_.name_group_theme
        : language.GROUP_TECH,
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      idinit: values_?.fk_id_group_theme ? values_.fk_id_group_theme : 0,
      routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
      detail: true,
      methodGetAll: 'POST',
      routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
    }
  ];

  return (
    <div>
      <CommonForm
        button={button}
        fields={fields}
        values={values_}
        onSubmit={onFormSubmit}
      />
    </div>
  );
};

export default ThemeSacForm;
