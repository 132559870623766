import * as SensritUtilsPk from '@sensrit/utils';
import React, { Component } from 'react';
import './passwordRequirements.css';

export default class PasswordRequirements extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { password, language, style } = this.props;

    return (
      <div className="passwordRules" style={style}>
        <label>{language.PASSWORD_RULE_LABEL}:</label>
        <ul>
          <li className={(password?.length >= 8) ? 'active' : ''}>8 {language.CHARACTERS}</li>
          <li className={SensritUtilsPk.checkIfStringContainsLetterLowerCaseFn(password) ? 'active' : ''}>{language.PASSWORD_RULE_LOWERCASE_CHAR}</li>
          <li className={SensritUtilsPk.checkIfStringContainsLetterUpperCaseFn(password) ? 'active' : ''}>{language.PASSWORD_RULE_UPPERCASE_CHAR}</li>
          <li className={SensritUtilsPk.checkIfStringContainsSpecialCharacterFn(password) ? 'active' : ''}>{language.PASSWORD_RULE_SPECIAL_CHAR}</li>
          <li className={SensritUtilsPk.checkIfStringContainsDigitFn(password) ? 'active' : ''}>{language.PASSWORD_RULE_NUMBER}</li>
        </ul>
      </div>
    );
  }
}
