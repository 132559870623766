

import React, { useState, useEffect } from 'react'
import CommonTable from '@common/CommonTable';
import CommonForm from '@common/CommonForm2';
import './Avaliation.css'
import Button from '@common/Button';
import Form from './Form'




const Avaliation=(props)=>{
   let {action,APIEndpoints,store}= props;

   let [tickets,setTickets]=useState([]);
   let [tickets_,setTickets_]=useState([]);

    useEffect(() => {
      action
      .execute(
        'post',
        APIEndpoints.TICKET + '/ticketFind',
        '',
        {avaliationticket:true},
        { },
        null,
        null
      )
      .then(g => {
        setTickets(g.data);
      });



    },[])

    const saveAvaliation=(json)=>{


      let array=tickets_;
      array.push(json.fk_id_ticket)
      setTickets_(array);

      action
      .execute(
      'post',
      APIEndpoints.REQUESTS + '/avaliation',
      'tick',
      json,
      { },
      null,
      null
    )
    .then(g => {
      store.toggleAlert(
        true,
        language.TICKET_SUCCESSFULLY_EVALUATED,
        'success'
      );
    }).catch(r=>{
      store.toggleAlert(
        true,
        language.UNABLE_TO_EVALUATE,
        'error'
      );

    })


    let t = tickets.filter(b=>tickets_.includes(b.id_tickets)==false);
    setTickets(t)

     }



    return(<div style={{minHeight:'500px'}} id="AvaliationMult">

<div><h3></h3></div>


      {tickets.map(ticket=>

    <Form ticket={ticket} store={store} saveAvaliation={saveAvaliation}></Form>

        )}



    </div>);

}

export default Avaliation
