import React, { useEffect, useState, useReducer } from 'react';
import moment from 'moment';
import CommonForm2 from '../../../common/CommonForm2';
import Dialog from '@common/Dialog';
import decrypt from '../../../util/decrypt';
import crypt from '../../../util/crypt';
import { formshtml } from '../utils/formshtml';
import '../../TicketManagement/Detail.css';
import Button from '../../../common/Button';
const FormAttendance = ({
  values_,
  language,
  Constants,
  action,
  setPage,
  Store,
  setSingleAttendance,
}) => {
  const [themeOptions, setThemeOptions] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [themeSelected, setThemeSelected] = useState(values_?.fk_id_theme ? values_?.fk_id_theme : 0);
  const [topicSelected, setTopicSelected] = useState(values_?.fk_id_topic ? values_?.fk_id_topic : 0);
  const [subjectSelected, setSubjectSelected] = useState(values_?.fk_id_subject ? values_?.fk_id_subject : 0);
  const [nameSubjectSelected, setNameSubjectSelected] = useState(values_?.name_subject ? values_?.name_subject : '');
  const [userSelectedData, setUserSelectedData] = useState({});
  const [typeSelected, setTypeSelected] = useState(values_?.fk_id_type ? values_?.fk_id_type : 0);
  const [values, setValues] = useState(values_);
  const [form, setForm] = useState(values_?.template);
  const [templateClose, setTemplateClose] = useState();
  const [techGroup, setTechGroup] = useState(values_?.fk_id_group_attend);
  const [nameGroup, setNameGroup] = useState(values_?.name_group_attend);
  const [cleanValue, setCleanValue] = useState(false)
  const [formRefs, setFormRefs] = useReducer((state, val) => {
    return [...state, val];
  }, []);
  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState
  );

  const cleanValues = () => {
    setThemeSelected(0)
    setTopicSelected(0)
    setSubjectSelected(0)
    setNameSubjectSelected('')
    setCleanValue(true)

    formRefs[0].getFields().map((e) => {
      if (e.name == 'fk_id_theme' || e.name == 'fk_id_topic' || e.name == 'fk_id_subject' ) {
        formRefs[0].handleValue(0, e.name, 0);
      }
    });
  };

  const getTopics = (fk_id_theme) => {
     action
      .execute('post', `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?`)
      .then((res) => {
        if (fk_id_theme) {
          setTopicOptions(
            res.data
              .filter((topic) => topic.fk_id_theme == fk_id_theme)
              .map((t) => ({
                value: t.id_topic,
                label: t.name,
                fk_id_theme: t.fk_id_theme,
              }))
          );
        } else {
          setTopicOptions(
            res.data.map((t) => ({
              value: t.id_topic,
              label: t.name,
              fk_id_theme: t.fk_id_theme,
            }))
          );
        }
      });
  };

  const getSubjects = () => {
    action
      .execute('post', `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`)
      .then((res) => {
        setSubjectOptions(res.data);
      });
  };

  async function getStateDesc(id_state) {
    const response = await action.execute('get', `${Constants.APIEndpoints.STATE + '/info/' + id_state}`, '');
    const data = await response.data.json()
    return data[0].name;
  };

  async function getCityDesc(id_city) {
    const response = await action.execute('get', `${Constants.APIEndpoints.CITY + '/' + id_city}`, '');
    const data = await response.data.json()
    return data[0].name;
  };

  useEffect(() => {
    async function getDataUser() {
      const data = getUserData(values_.fk_id_user)
      setUserSelectedData(data)
    }
    action
      .execute('post', `${Constants.APIEndpoints.SAC_THEMES}/getallthemes?`)
      .then((res) => {
        setThemeOptions(
          res.data.map((t) => ({
            value: t.id_theme, label: t.name,
            fk_id_group_theme: t.fk_id_group_theme,
            name_group_theme: t.name_group_theme,
            fk_id_theme: t.fk_id_theme
          }))
        );
      });
    action
      .execute('get', Constants.APIEndpoints.TABLE_STRUCTURED + '/140')
      .then((res) => {
        setChannelOptions(
          res.data.map((c) => ({ value: c.id_table, label: c.description }))
        );
        setValues({
          ...values,
          fk_id_channel: res.data.find((data) => data.item_table === 1)
            .id_table,
        });
      });
    action
      .execute('get', Constants.APIEndpoints.TABLE_STRUCTURED + '/130', 'type')
      .then((res) => {
        setTypeOptions(
          res.data.map((t) => ({ value: t.id_table, label: t.description }))
        );
      });
      action
        .execute('post', `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`)
        .then((res) => {
          setSubjectOptions(res.data);
        });

      getTopics();
      getDataUser()
  }, []);

  const getUserData = async (id_user) => {
    if (id_user > 0) {
      const user = await action.execute( 'post', `${Constants.APIEndpoints.SAC_CONSUMERS}?`, '', {id_user})
        .then((res) => {
          return res;
        });

      const desc_city = await getCityDesc(user.data[0].fk_id_city);
      const decs_state = await getStateDesc(user.data[0].fk_id_state);

      setUserSelectedData({...user[0], fk_id_city: desc_city, fk_id_state: decs_state});
    }
  };

  const getGroupTheme = (id_theme) => {
    if (id_theme > 0 && themeOptions.length > 0) {
      const group = themeOptions.find((c) => c.value == id_theme);
      setTechGroup(group.fk_id_group_theme);
      setNameGroup(group.name_group_theme)
    }
  };

  const onSubmit = (values) => {
    if (typeSelected == 0) {
      Store.toggleAlert(true, 'Informe o Tipo do atendimento', 'error');
      return;
    }
    let fields = [];
    let campossize = false;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    $('#template').html();
    if ($('#template').html() != undefined) {
      $('#template textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#template input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#template  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).attr('checked')) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) {}
                  });
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).attr('checked')) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) {}
                      }
                    } catch (e) {}
                  });
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment($(this).val()).tz(timezone).utc().toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${
                  min || 0
                } á ${siz || 0} caracteres</label>`
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#template select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected'
        );

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });
      });

      $('#template label').each(function () {
        try {
          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())

                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) {}
      });
    }

    setTimeout(function () {
      $('#formtemplabel__').fadeOut().empty();
      $('#template .form-control').css('border-color', '#019fc2');
    }, 9000);
    if ($('#template').html()) {
      values.template = crypt($('#template').html());
      values.json_template_solicitation = json_template_solicitation;
    }
    values.fields = fields;
    if (fields.length == 0) {
      values.template = ""
    }
    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      values.user = Store.getUserLoged();
      values.place = 'sac';
      values.templateClose = templateClose;
      const method = values.id_attendance_sac ? 'put' : 'post';
      const url = values.id_attendance_sac
        ? Constants.APIEndpoints.SAC_ATTENDANCE + '/update'
        : Constants.APIEndpoints.SAC_ATTENDANCE;
      let files = [];
      let cont = 0;
      if (values.files && values.files.length > 0) {
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            files.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              values.files = files;

              action
                .execute(method, url, '', values)
                .then(() => {
                  if (values.id_attendance_sac) {
                    action
                      .execute(
                        'get',
                        Constants.APIEndpoints.SAC_ATTENDANCE +
                          '/' +
                          values.id_attendance_sac
                      )
                      .then((res) => {
                        setSingleAttendance(res.data[0]);
                      });
                  }
                  setPage('list');
                });
            }
          });
        });
      } else {
        action
          .execute(method, url, '', values)
          .then(() => {
            if (values.id_attendance_sac) {
              action
                .execute(
                  'get',
                  Constants.APIEndpoints.SAC_ATTENDANCE +
                    '/' +
                    values.id_attendance_sac
                )
                .then((res) => {
                  setSingleAttendance(res.data[0]);
                });
            }
          });
        setPage('list');
      }
    } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        Store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');
      if (campossize)
        Store.toggleAlert(true, language.CHECK_FIELD_SIZES, 'error');
    }
  };

  const fields = [
    {
      col: 6,
      name: 'fk_id_user',
      textlabel: language.CLIENT,
      type: 'autocomplete',
      label: language.CLIENT,
      method: 'POST',
      route: `${Constants.APIEndpoints.SAC_CONSUMERS}?`,
      routeGetCount: `${Constants.APIEndpoints.SAC_CONSUMERS}/all?`,
      fieldquery: 'search',
      textinit: userSelectedData?.name  ? userSelectedData?.name : language.CLIENT,
      fieldvaluedb: 'id',
      fieldlabeldb: 'name_cnpj',
      idinit: userSelectedData?.id ? userSelectedData?.id : 0,
      routeAll: `${Constants.APIEndpoints.SAC_CONSUMERS}?`,
      methodGetAll: 'POST',
      required: true,
      detail: true,
      disabledTooltip: true
    },
    {
      col: 6,
      type: 'select',
      name: 'fk_id_channel',
      options: channelOptions,
      sortOptions: false,
      required: true,
      label: language.CHANNEL,
    },
    {
      col: 4,
      type: 'select',
      name: 'fk_id_theme',
      options: subjectSelected === 0 ? themeOptions : themeOptions.filter((theme) => theme.value === themeSelected),
      required: true,
      label: language.THEME,
    },
    {
      col: 4,
      type: 'select',
      name: 'fk_id_topic',
      options: subjectSelected === 0 ?
                  topicOptions.filter((topic) => topic.fk_id_theme === themeSelected) :
                  topicOptions.filter((topic) => topic.value === topicSelected),
      required: true,
      label: language.TOPIC,
    },
    {
      col: 3.6,
      name: 'fk_id_subject',
      textlabel: language.SUBJECT,
      type: 'autocomplete',
      label: language.SUBJECT,
      method: 'POST',
      route: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
      routeGetCount: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
      fieldquery: 'search',
      textinit: nameSubjectSelected !== '' ? nameSubjectSelected : language.SUBJECT,
      fieldvaluedb: 'id_sac_subject',
      fieldlabeldb: 'name',
      idinit: subjectSelected !== 0 ? subjectSelected : 0,
      routeAll: `${Constants.APIEndpoints.SAC_SUBJECTS}/getAllSubjects?`,
      methodGetAll: 'POST',
      filterAll: { id_topic: topicSelected },
      required: true,
      detail: true,
      disabledTooltip: true
    },
    {
      col: 0.4,
      type: 'element',
      content: (
        <Button
          style={{ marginTop: 20}}
          variant="circle"
          icon="fas fa-eraser"
          color="success"
          label={language.CLEAR}
          onClick={() => {
            cleanValues()
          }}
        />
      )

    },
    {
      col: 6,
      type: 'autocomplete',
      name: 'fk_id_group_attend',
      label: language.TECH_GROUP,
      className: 'tech-group-ticket',
      required: true,
      method: 'POST',
      route: `${Constants.APIEndpoints.GROUP}/filter`,
      filterAll: { fk_id_user: Store.getUserLoged().id },
      fieldquery: 'search',
      textlabel: language.TECH_GROUP,
      textinit: nameGroup ? nameGroup : language.TECH_GROUP,
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      idinit: techGroup ? techGroup : 0,
      routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
      detail: true,
      methodGetAll: 'POST',
      routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
      disabledTooltip: true,
      cleanValue: cleanValue
    },
    {
      col: 6,
      type: 'select',
      name: 'fk_id_type',
      options: values.fk_id_type ? typeOptions.filter((type) => type.value === values.fk_id_type) : typeOptions,
      required: true,
      label: language.TYPE,
    },
    {
      col: 12,
      type: 'textEditor',
      name: 'instructions',
      label: language.INSTRUCTIONS,
      disabled: true,
    },
    {
      col: 10,
      type: 'textEditor',
      name: 'description',
      type: 'simplesTextEditor',
      label: language.DESCRIPTION,
      required: true,
    },
    {
      col: 2,
      type: 'file',
      name: 'files',
      label: language.ATTACH_FILE,
    },
    {
      type: 'element',
      col: 12,
      visible: Object.keys(userSelectedData).length > 0,
      content: Object.keys(userSelectedData).length > 0 && (
        <div className="wrap mb-3">
          <h3 className="title" style={{ textTransform: 'none' }}>
            {language.CUSTOMER_DATA}
          </h3>
          <CommonForm2
            values={userSelectedData}
            fields={[
              {
                col: 3,
                label: language.NAME,
                name: 'name',
                type: 'text',
                disabled: true,
              },
              {
                col: 3,
                label: language.EMAIL,
                name: 'email',
                type: 'text',
                disabled: true,
              },
              {
                col: 3,
                label: language.PHONE,
                name: 'phone',
                type: 'text',
                disabled: true,
              },
              {
                col: 3,
                label: language.TYPE,
                name: 'type',
                type: 'text',
                disabled: true,
              },
              {
                col: 3,
                label: language.ADDRESS,
                name: 'address',
                type: 'text',
                disabled: true,
              },
              {
                name: 'neighborhood',
                label: language.NEIGHBORHOOD,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'fk_id_city',
                label: language.CITY,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'zip_code',
                label: language.POSTAL_CODE,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'fk_id_state',
                label: language.STATE,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'number_rn',
                label: 'RN',
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'manager',
                label: language.MANAGER_COORDINATOR,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'director',
                label: language.DIRECTOR,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'base',
                label: language.BASE,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'segment',
                label: language.SEGMENT,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'flag',
                label: language.FLAG,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              },
              {
                name: 'operator',
                label: language.ATTENDANT,
                type: 'text',
                required: false,
                col: 3,
                disabled: true,
              }
            ]}
            updateStateForm
          />
        </div>
      ),
    },
    {
      type: 'element',
      col: 12,
      content: form?.length > 0 && (
        <>
          <h3 className="title" style={{ textTransform: 'none' }}>
            Template abertura
          </h3>
          <div className="contain">
            <div className="itemTicketDetail">
              <p style={{ color: '#888080' }} id={'template'}>
                {formshtml(decrypt(form), updateState)}
              </p>
            </div>
            <div style={{ marginTop: '80px' }} />
            <hr />
          </div>
        </>

      ),
    }
  ];

  const changeValues = (subjectSelected) => {
    const classification = subjectOptions.filter(
      (s) => s.id_sac_subject == subjectSelected)[0]?.classification

      if (classification) {
        if (values_.fk_id_type) {
          if (classification === values_.fk_id_type) {
            setTypeSelected(classification)
          } else {
            setTypeSelected(0)
          }
        } else if (typeSelected == 0) {
          setTypeSelected(classification)
        } else if (classification === typeSelected) {
          setTypeSelected(classification)
        } else {
          setTypeSelected(0)
        }
      }
  };

  return (
    <Dialog
      open={true}
      onClose={() => setPage('list')}
      title={Object.keys(values_).length > 0 ? language.RECLASSIFY_ATTENDANCE : language.CREATE_ATTENDANCE}
      maxWidth="xl"
    >
      <CommonForm2
      // updateStateForm
        fields={fields}
        onSubmit={onSubmit}
        onRef={(ref) => setFormRefs(ref)}
        values={{ ...values, files: null }}
        onChangeField={ async (f, v) => {
          if (v !== 0) {
            if (f.name == 'fk_id_theme') {
              getTopics();
              getGroupTheme(v)
              setThemeSelected(v)
              if (subjectSelected === 0) {
                setNameSubjectSelected('')
                setCleanValue(true)
                setTopicSelected(
                  topicOptions.filter((data) => data.fk_id_theme == v)[0]
                    ?.value
                );
              }
            }
            if (f.name == 'fk_id_topic') {
              setTopicSelected(v)
              if (subjectSelected !== 0) {
                setThemeSelected(
                  topicOptions.filter((data) => data.value == v)[0]
                    ?.fk_id_theme
                );
              } else {
                setNameSubjectSelected('')
                setCleanValue(true)
              }
            }
            if (f.name == 'fk_id_subject') {
              if (v !== 0) {
                changeValues(v);
                setCleanValue(false)
                getSubjects();
                if (themeSelected == 0 || themeSelected == undefined) {
                  getTopics();
                  setTopicSelected(subjectOptions.filter((data) => data.id_sac_subject == v)[0]?.id_topic);
                  setThemeSelected(0);
                }

                setValues({
                  ...values,
                  fk_id_type: subjectOptions.filter((s) => s.id_sac_subject == v)[0]?.classification,
                  instructions: subjectOptions.filter((s) => s.id_sac_subject == v)[0]?.instruction
                });
                setSubjectSelected(v)

                if (subjectOptions.length > 0) {
                  setNameSubjectSelected(subjectOptions.filter((s) => s.id_sac_subject == v)[0]?.name)
                  const template = subjectOptions.filter((s) => s.id_sac_subject == subjectSelected)[0]?.id_template;
                  const newTemplate = subjectOptions.filter((s) => s.id_sac_subject == v)[0]?.id_template;
                  if (subjectSelected !== 0 && (subjectSelected == undefined || newTemplate !== template)) {
                    setForm(subjectOptions.filter((s) => s.id_sac_subject == v)[0]?.template);
                  }
                  setTemplateClose(subjectOptions.filter((s) => s.id_sac_subject == v)[0]?.template_close);
                }
              }
            }
            if (f.name == 'fk_id_user') {
              getUserData(v);
            }
            if (f.name == 'fk_id_type') {
              setTypeSelected(v);
            }
          }
        }}
      />
    </Dialog>
  );
};

export default FormAttendance;
