import React, { useState, useReducer, useEffect, Fragment } from 'react';
import crypto from 'crypto';
import { Tooltip } from 'react-tippy';
import { Avatar, Grid } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommonDelete from '../../../common/CommonDelete';
import Button from '../../../common/Button';
import { Switch } from '@material-ui/core';
import AppBar from '../../../common/AppBar2';
import CommonForm from '../../../common/CommonForm2';
import AttachFileImage from '@images/attachFile.png';
import DownloadFiles from '../../../util/DownloadFiles';
import CommonTable from '@common/CommonTable';
import Constants from '../../../data/Constants';
import { concatDateHour, moment } from '../../../util/dateTime';
export default function ({
  open,
  onClose,
  onSave,
  values,
  companies,
  page,
  users,
  tickets,
  store,
  action,
  APIEndpoints,
  devices,
  changeitem,
  language,
  actionsOptions,
  enable,
  stand,
  onSaveS,
  ticketparam,
  params,
  toggleAlert,
  ondeletefile,
  createToTemplate,
  setStepIndex,
  typeTemp,
  createToTemplate_fields,
  all,
}) {
  /*#####################################################-STATES-#########################################################################*/

  let [company, SetCompany] = useState(0);
  let [contract, SetContract] = useState({});
  let [typechange, Settypechange] = useState(1);

  let [changesCount, SetchangesCount] = useState(25);
  let [devices_, setDevices_] = useState([]);
  let [remove, setRemove] = useState(false);
  let [idOutage, setIdOutage] = useState('');
  const callPagination = () => {};
  const callDataFilter = (items = {}) => {};

  const onDelete = () => {
    action
      .execute('put', `${Constants.APIEndpoints.CHANGE}/outage/${idOutage}`, '')
      .then((h) => {});
  };

  const callPageSelectFilter = (page = 0, items = {}, rowPerPage = 75) => {
    let limit = rowPerPage;

    let offset = page * limit;

    action
      .execute(
        'post',
        `${Constants.APIEndpoints.CHANGE}/outage/getall?offset=${offset}&limit=${limit}`,
        '',
        items
      )
      .then((h) => {
        SetchangesCount(h.data[0].total_count);
        setDevices_(h.data);
      });
  };

  useEffect(() => {
    Ics(changeitem.id_change);
  }, [changeitem.id_change]);

  const Ics = (id) => {
    if (all) {
      action
        .execute(
          'post',
          `${Constants.APIEndpoints.CHANGE}/outage/getall?offset=0&limit=25`,
          ''
        )
        .then((h) => {
          setDevices_(h.data);
        });
    } else {
      action
        .execute('post', `${Constants.APIEndpoints.CHANGE}/outage/getall`, '', {
          id_change: id,
        })
        .then((h) => {
          setDevices_(h.data);
        });
    }
  };

  const saveOutage = (value) => {
    value.start_date = concatDateHour(value.start_date, value.start_time);
    value.end_date = concatDateHour(value.end_date, value.end_time);
    if (all == true) {
    } else {
      if (moment(value.start_date).format('YYMMDDHHmm') > moment(value.end_date).format('YYMMDDHHmm')){
        toggleAlert(true, language.START_DATE_CANNOT_BE_GREATER_THAN_END_DATE, `error`);
        return;
      }else if (
        moment(value.start_date).format('YYMMDDHHmm') >=
          moment(changeitem.start_date).format('YYMMDDHHmm') &&
        moment(value.end_date).format('YYMMDDHHmm') <=
          moment(changeitem.end_date).format('YYMMDDHHmm')
      ) {
      } else {
        toggleAlert(true, language.OUTAGE_OUTSIDE_GMUD_RANGE, `error`);
        return;
      }
    }

    action
      .execute('post', `${Constants.APIEndpoints.CHANGE}/outage/`, '', value)
      .then((data) => {
        toggleAlert(true, language.SUCCESSFULLY_UPDATED, 'success');
        Ics(changeitem.id_change);
      });
  };

  const handleStatusOutage = (outage) => {
    const values = {
      type: 'remove',
      id_outage_ic: outage.id_outage_ic,
    };
    action
      .execute('post', `${Constants.APIEndpoints.CHANGE}/outage/`, '', values)
      .then((data) => {
        toggleAlert(true, language.SUCCESSFULLY_UPDATED, 'success');
        Ics(changeitem.id_change);
      });
  };
  return (
    <Fragment>
      <div
        id={'changeform'}
        style={{
          zoom: 0.9,
          marginTop: '5px',

          minHeight: '500px',
        }}
        zoom={0.9}
      >
        {all && (
          <CommonForm
            individual
            hiddenSubmit
            onSubmit={(e) => {
              e.new = true;
              saveOutage(e);
            }}
            fields={[
              {
                col: 2,

                type: 'autocomplete',
                name: 'id_device',
                textlabel: '',
                method: 'POST',
                route: `${APIEndpoints.DEVICE}/filter/`,
                routeGetCount: `${APIEndpoints.DEVICE}/filter?count=0`,
                fieldquery: 'search',
                textinit: '',
                fieldvaluedb: 'id_device',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${APIEndpoints.DEVICE}/filter?`,
                methodGetAll: 'POST',
                required: true,
                isMulti: false,
                showEmail: false,
                disabled: false,
              },
              {
                col: 2,
                type: 'date',
                name: 'start_date',
                label: language.INIT_OUTAGE,
                required: true,
              },
              {
                col: 2,
                type: 'time',
                name: 'start_time',
                label: '',
                required: true,
              },
              {
                col: 2,
                type: 'date',
                name: 'end_date',
                label: language.END_OUTAGE,
                required: true,
              },
              {
                col: 2,
                type: 'time',
                name: 'end_time',
                label: '',
                required: true,
              },
              {
                col: 1,
                type: 'select',
                name: 'recorrence',
                options: [{ value: 1, label: 'Diária' }],
                label: language.SYSTEM,
                required: true,
              },

              {
                col: 0.5,
                type: 'element',
                content: (v, h, submit) => (
                  <Fragment>
                    <Button
                      color="primary"
                      label={language.ADD}
                      onClick={() => submit(v)}
                      style={{ marginTop: 15 }}
                    />
                  </Fragment>
                ),
              },
            ]}
          />
        )}
        <br></br>
        <br></br>

        <CommonTable
          paginationTop={false}
          countTotal={changesCount}
          loadSearch={true}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          title={''}
          columns={[
            {
              key: 'name',
              label: 'IC',
              style: { textAlign: 'center', width: all ? 300 : 200 },
              thConfig: true,
            },
            {
              key: 'gmud',
              label: language.ORIGIN,
              style: { textAlign: 'center', width: all ? 100 : 300 },
              thConfig: true,
            },
            {
              key: 'company',
              label: language.COMPANY,
              style: { textAlign: 'center', width: all ? 200 : 300 },
              thConfig: true,
            },

            {
              key: 'dates',
              label: language.PERIOD,
              thConfig: true,
            },
          ]}
          index="ChangeManagementList"
          data={devices_.map((i) => ({
            name: i.name,
            gmud: '#' + i.fk_id_change,
            company: i.namecompany,
            dates: (
              <CommonForm
                individual
                hiddenSubmit
                values={i}
                onSubmit={(e) => {
                  e.id_change = changeitem.id_change;
                  e.id_device = i.id_device;
                  saveOutage(e);
                }}
                fields={[
                  {
                    col: 3,
                    type: 'date',
                    name: 'start_date',
                    label: language.INIT_OUTAGE,
                    required: true,
                  },
                  {
                    col: 2,
                    type: 'time',
                    name: 'start_time',
                    label: '',
                    required: true,
                  },
                  {
                    col: 3,
                    type: 'date',
                    name: 'end_date',
                    label: language.END_OUTAGE,
                    required: true,
                  },
                  {
                    col: 2,
                    type: 'time',
                    name: 'end_time',
                    label: '',
                    required: true,
                  },

                  {
                    col: 2,
                    type: 'element',
                    content: (v, h, submit) => (
                      <Fragment>
                        <Button
                          color="primary"
                          circleButton
                          icon="fas fa-save"
                          onClick={() => submit(v)}
                          style={{ marginTop: 20, marginBottom : 20 }}
                        />
                        ,
                        <Button
                          circleButton
                          icon="far fa-trash-alt"
                          color="danger"
                          onClick={() =>handleStatusOutage(i)}
                          style={{ marginTop: 20, marginBottom : 20 }}
                        />
                        {all ? (
                          <Button
                            color="danger"
                            onClick={() => {
                              setRemove(true);
                              setIdOutage(v.id_outage_ic);
                            }}
                            style={{ marginTop: 15 }}
                            circleButton
                            icon="fas fa-trash-alt"
                          />
                        ) : null}
                      </Fragment>
                    ),
                  },
                ]}
              />
            ),
          }))}
          //showHeaderToolbar={false}
          orderColumn
          searchColumn={true}
          searchColumnEvent={(v) => callPageSelectFilter(0, v, 75)}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          onSortItem={(item, sortByStatus) => callSortItem(item, sortByStatus)}
          rowsPerPageOptions={[75]}
        />

        <br></br>
      </div>
      {devices_.filter((t) => t.start_date == null).length == 0 ? (
        <div style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            label={language.NEXT}
            onClick={() => setStepIndex(2)}
            style={{ marginTop: 15 }}
          />
        </div>
      ) : null}

      {remove ? (
        <CommonDelete
          language={language}
          message={language.WARNING_DELETE_ITEM}
          onDelete={onDelete}
        />
      ) : null}
    </Fragment>
  );
}
