import React, { useEffect, useReducer, useState } from 'react';
import { Card, Checkbox } from '@material-ui/core';
import moment from 'moment';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';
import CommonForm2 from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import OverlayLoading from '@common/OverlayLoading';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

const action = new Actions();

const index = () => {
  const [ticketsArray, setTicketsArray] = useState([]);
  const [ticketsIdArray, setTicketsIdArray] = useState([]);
  const [valuesTickets, setValuesTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [dtinicial, setDtinicial] = useState(0);
  const [dtfinal, setDtfinal] = useState(0);
  const [fk_id_company, setFk_id_company] = useState(0);
  const [slaId, setSlaId] = useState(0);
  const [ids, setIds] = useState(0);
  const [slaStatus, setSlaStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [slaList, setSlaList] = useState([]);
  const [page, setPage] = useState('list');
  const [cleanValueCompany, setCleanValueCompany] = useState(false);
  const [successReprocess, setSuccessProcess] = useState([]);
  const [failureReprocess, setFailureProcess] = useState([]);
  const [formRefs, setFormRefs] = useReducer((state, val) => {
    return [...state, val];
  }, []);
  
  let user = Store.getUserLoged();

  const cleanValues = () => {
    formRefs[0].getFields().map((e) => {
      formRefs[0].handleValue(0, e.name, 0);
    });
  };

  const clearFilter = () => {
    setDtinicial(0);
    setDtfinal(0);
    setFk_id_company(0);
    setIds(0);
    cleanValues();
    setCleanValueCompany(true)
  };

  const getTickets = (values = {}, page = 0, totalPerPage = 75, type) => {
    Store.spinner = true;
    setTicketsArray([]);
    setTicketsIdArray([]);
    setCurrentPage(page);
    let filters = {
      dtinicial: !slaStatus ? dtinicial : 0,
      dtfinal: !slaStatus ? dtfinal : 0,
      fk_id_company: fk_id_company,
      sla_id: slaId,
      ids: ids,
      without_sla: slaStatus,
      values: values,
    };

    let limit = totalPerPage || 75;
    let offset = page * limit;

    action
      .execute('post', `${Constants.APIEndpoints.TICKET}/sla/list?limit=${totalPerPage}&offset=${offset}`, '', filters)
      .then((res) => {
        Store.spinner = false;
        setValuesTickets(res.data || []);
        setTotalTickets(parseInt(res.data[0].total_count) || 0);
      }).catch((err) => {
        console.error(err);
        Store.spinner = false;
      });
  };

  const data =
    valuesTickets?.length > 0 &&
    valuesTickets?.map((ticket) => {
      return {
        check_: (
          <Checkbox
            checked={ticketsArray.includes(ticket.real_id ? ticket.real_id : ticket.id_tickets)}
            onClick={(e) => {
              setTicketsArray((oldArray) =>
                ticketsArray.includes((ticket.real_id ? ticket.real_id : ticket.id_ticket))
                  ? ticketsArray.filter((item) => item !== (ticket.real_id ? ticket.real_id : ticket.id_tickets))
                  : [...oldArray, (ticket.real_id ? ticket.real_id : ticket.id_tickets)]
              );
              setTicketsIdArray((oldArray) =>
                ticketsArray.includes(ticket.id_tickets)
                  ? ticketsArray.filter((item) => item !== ticket.id_tickets)
                  : [...oldArray, ticket.id_tickets]
              );
            }}
          />
        ),
        real_id: ticket.real_id ? ticket.real_id : ticket.id_tickets,
        name: ticket.name,
        dt_cad: ticket.dt_cad
          ? moment(ticket.dt_cad).format('DD/MM/YYYY HH:mm')
          : '',
        dt_up: ticket.dt_up
          ? moment(ticket.dt_up).format('DD/MM/YYYY HH:mm')
          : '',
        sh_prev: ticket.sh_prev
          ? moment(ticket.sh_prev).format('DD/MM/YYYY HH:mm')
          : '',
        sh_stopped: ticket.sh_stopped
          ? moment(ticket.sh_stopped).format('DD/MM/YYYY HH:mm')
          : '',
        sla_name: ticket.sla_name ? ticket.sla_name.map((sla) => sla.sla_name).join(' ** ') : ''
      };
    });
  
  let completedRequests = 0;
  const handleCompletion = () => {
    completedRequests++;
    if (completedRequests === ticketsArray.length) {
      setPage('result-sla');
      getTickets();
      setTicketsArray([]);
      setTicketsIdArray([]);
      Store.toggleAlert(true, language.FINISHED, 'success');
      Store.spinner = false;
    }
  };
  const ticketError = (error) => {
    let errorMessage = language.ERROR_REQUEST;

    if( error.error === "E404" ) {
      errorMessage = language.SETTINGS_NOT_FOUND;
    } else if ( error.id_error ) {
      switch( error.id_error ) {
        case '430c5b38-1c90-40ac-bf90-633596fa72a5':
          errorMessage = language.TICKET_NOT_FOUND;
          break;
        case '30ac734f-48a9-4d5e-905b-ed9ac0fb57f3':
          errorMessage = language.TICKET_CONTAINS_RULES;
          break;
        case '44014f88-dea7-428c-8ca3-05931d1d3a1c':
          errorMessage = language.ERROR_PROCESSING;
          break;
        case '6334adca-a96a-43d8-9e42-4a454ce15511':
          errorMessage = language.TICKET_STATE_NOT_ALLOWED;
          break;
        case '8dc0789d-99c3-4a7d-85fe-4ebf1966fee9':
          errorMessage = language.SLA_GLOBAL_LIST_ERROR;
          break;
        case '8a399a6f-4131-412d-a2af-87d0891cea40':
          errorMessage = language.SLA_RULES_NO_MATCH;
          break;
        case '78f766b1-e09f-4f9f-ad86-368b191d5ce5':
          errorMessage = language.TICKET_NOT_FOUND;
          break;
        case 'b0fe64fe-ceb8-4501-81ae-0b84649b841d':
          errorMessage = language.TICKET_DOES_NOT_CONTAIN_RULES;
          break;
        case '647e4f64-12cb-461d-a786-61ac74bec0a3':
          errorMessage = language.TICKET_STATE_NOT_ALLOWED;
          break;
        case 'd13fc8d3-b1ef-409e-a41e-29a67b6a9acc':
          errorMessage = language.SLA_GLOBAL_LIST_ERROR;
          break;
        case '4b6c4433-3a5b-45fa-9309-447e636ba80d':
          errorMessage = language.SLA_PARAM_NOT_FOUND;
          break;
        case '781d6d45-bef3-47ba-9773-4f9a7847fc49':
          errorMessage = language.TICKET_STATE_NOT_ALLOWED;
          break;
        case 'e58d3eee-1474-43ef-bb6a-a5856b3e09a7':
          errorMessage = language.SLA_RULE_INCOMPATIBLE;
          break;
      }
    }

    return errorMessage;
  };
  const onFormSubmitReprocess = (values) => {
    let payload = {
      justification: values.justification
    }

    if ( !slaStatus && values.sla_id ) {
      payload.id_slaparam = values.sla_id
    }

    Store.spinner = true;
    completedRequests = 0;
    setSuccessProcess([]);
    setFailureProcess([]);

    const endpointType = !slaStatus ?
      `${Constants.APIEndpoints.SLA_WORK}/reprocess` :
      `${Constants.APIEndpoints.SLA_WORK}/reprocess/match`;

    for (let i = 0; i < values.tickets.length; i++) {
      action
        .execute('post', `${endpointType}/${values.tickets[i]}`, '', payload)
        .then((res) => {
          setSuccessProcess(prevSuccess => [...prevSuccess, `${ticketsArray[i]}`]);
          handleCompletion();
        })
        .catch((err) => {
          console.error(err.data);
          setFailureProcess(prevFailure => [...prevFailure, `${ticketsArray[i]} - ${ticketError(err.data)}`]);
          handleCompletion();
        });
    }
  };

  const getSlaList = () => {
    action
      .execute('get', `${Constants.APIEndpoints.SLA_WORK}/slaparam`)
      .then((res) => {
        setSlaList(res.data.map((v) => ({ value: v.id_slaparam, label: v.sla_name })));
      })
      .catch(() => {
        Store.toggleAlert(true, language.ERROR_CODE.E500, 'error');
      });
  }

  const setAllTickets = () => {
    valuesTickets?.map((ticket) => {
      setTicketsArray((oldArray) => {
        if (!oldArray.includes((ticket.real_id ? ticket.real_id : ticket.id_ticket))) {
          return [...oldArray, (ticket.real_id ? ticket.real_id : ticket.id_tickets)];
        }
        return oldArray;
      });
  
      setTicketsIdArray((oldArray) => {
        if (!oldArray.includes(ticket.id_tickets)) {
          return [...oldArray, ticket.id_tickets];
        }
        return oldArray;
      });
    });
  };

  useEffect(() => {
    getTickets();
    setTicketsArray([]);
    setTicketsIdArray([])
  }, [slaStatus]);

  useEffect(() => {
    if( user.id === 1 && user.email === "admin@sensrit.com.br" ) {
      getSlaList();
    } else {
      window.location.href = window.location.origin + '/dash/configuration';
    }
  }, []);

  const language = Store.language;

  const cols = [
    {
      key: 'check_',
      label: '#1',
      thConfig: true,
      style: { width: 10 },
      search: false,
    },
    {
      key: 'real_id',
      label: language.ID,
      thConfig: true,
    },
    {
      key: 'name',
      label: language.COMPANY,
      thConfig: true,
      style: { width: 150 },
    },
    {
      key: 'dt_cad',
      label: language.REGISTRATION_DATE,
      thConfig: true,
    },
    {
      key: 'dt_up',
      label: language.DATE_LAST_CHANGE,
      thConfig: true,
    },
    {
      key: 'sh_prev',
      label: language.DATE_TARGET,
      thConfig: true,
    },
    {
      key: 'sh_stopped',
      label: language.END_DATE,
      thConfig: true,
    },
    {
      key: 'sla_name',
      label: language.RULE_SERVICE_LEVEL,
      thConfig: true,
    },
  ];

  return (
    <Card className="content">
      {isLoading && <OverlayLoading />}
      <CommonForm
        spacing={2}
        onRef={(ref) => setFormRefs(ref)}
        hiddenSubmit
        onSubmit={() => {
          setCleanValueCompany(false)
          setCurrentPage(0);
          getTickets({}, 0);
        }}
        onChangeField={(f, fk_id) => {
          if (f.name == 'ids') {
            setIds(fk_id);
          } else if (f.name == 'fk_id_company') {
            setFk_id_company(fk_id);
          } else if (f.name == 'sla_id') {
            setSlaId(fk_id);
          } else if (f.name == 'dtinicial') {
            setDtinicial(fk_id);
          } else if (f.name == 'dtfinal') {
            setDtfinal(fk_id);
          } else if (f.name == 'without_sla') {
            setSlaStatus(fk_id);
          }
        }}
        fields={[
          {
            col: 1,
            name: 'ids',
            type: 'chipInput',
            label: language.IDS,
            placeholder: 'T123,T456,...',
          },
          {
            col: 2,
            type: 'autocomplete',
            name: 'fk_id_company',
            label: language.COMPANY,
            method: 'POST',
            route: `${Constants.APIEndpoints.COMPANY}/filter`,
            fieldquery: 'search',
            textlabel: language.COMPANY,
            textinit: language.COMPANY,
            fieldvaluedb: 'id_company',
            fieldlabeldb: 'name',
            idinit: 0,
            routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
            methodGetAll: 'POST',
            routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
            detail: true,
            cleanValue: cleanValueCompany,
          },
          {
            col: 1.5,
            type: 'select',
            name: 'sla_id',
            label: language.SLA,
            type: 'select',
            options: slaList,
            visible: !slaStatus
            //type: 'autocomplete',
            //name: 'sla_id',
            //label: language.SLA,
            //method: 'GET',
            //route: `${Constants.APIEndpoints.SLA_WORK}/slaparam`,
            //fieldquery: 'search',
            //textlabel: language.SLA,
            //textinit: language.SLA,
            //fieldvaluedb: 'id_slaparam',
            //fieldlabeldb: 'sla_name',
            //idinit: 0,
            //routeAll: `${Constants.APIEndpoints.SLA_WORK}/slaparam?`,
            //methodGetAll: 'GET',
            //routeGetCount: `${Constants.APIEndpoints.SLA_WORK}/slaparam`,
            //showSearchButtom: false,
            //cleanValue: cleanValueCompany,
          },
          {
            col: 1.5,
            name: 'dtinicial',
            type: 'date',
            label: language.START_DATE,
            endIcon: 'fas fa-calendar-alt',
            visible: !slaStatus
          },
          {
            col: 1.5,
            name: 'dtfinal',
            type: 'date',
            label: language.FINAL_DATE,
            endIcon: 'fas fa-calendar-alt',
            visible: !slaStatus
          },
          {
            col: 1.5,
            name: 'without_sla',
            type: 'check',
            label: language.TICKET_WITHOUT_SLA,
            style: { marginTop: 6 },
          },
          {
            col: 1,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                icon="fas fa-search"
                color="success"
                variant="normal"
                label={language.SEARCH_FOR}
                style={{
                  marginTop: 13,
                }}
                onClick={() => submit()}
              />
            ),
          },
          {
            col: 0.5,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                onClick={() => clearFilter()}
                variant="circle"
                icon="fas fa-eraser"
                color="success"
                label={language.CLEAR_FILTER}
                style={{
                  fontSize: 14,
                  //marginLeft: 6,
                  marginTop: 15,
                }}
              />
            ),
          },
          {
            col: 1.5,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                onClick={() => setPage('reprocess-sla')}
                variant="normal"
                color="primary"
                label={language.REPROCESS_SLA}
                style={{
                  //fontSize: 14,
                  //marginLeft: -15,
                  marginTop: 15,
                }}
              />
            ),
          },
          {
            col: 12,
            type: 'element',
            content: (v, h, submit) => (
              <Button
                onClick={() => setAllTickets()}
                variant="normal"
                color="primary"
                label={language.SELECT_ALL}
                style={{
                  marginBottom: 30,
                }}
              />
            ),
          },
        ]}
      />
      <CommonTable
        title={''}
        language={language}
        col={cols}
        data={data}
        isAutomaticPagination={false}
        paginationTop={false}
        width="100%"
        searchColumn
        currentPage={currentPage}
        countTotal={totalTickets}
        searchColumnEvent={(v) => {
          getTickets(v);
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          getTickets(values, page, rowsPerPage, type)
        }
      />

      {page == 'reprocess-sla' && (
        <Dialog
          open={true}
          onClose={() => setPage('list')}
          title={language.REPROCESS_SLA}
          maxWidth="lg"
        >
          <CommonForm2
            values={{
              notes: ticketsArray
                .map((ticket) => {
                  const foundTicket = valuesTickets.find((t) => (t.real_id ? t.real_id : t.id_tickets) == ticket);
                  const displayTicketId = foundTicket ? (foundTicket.real_id ?? foundTicket.id_tickets) : ticket;
                  return `${displayTicketId} - ${foundTicket?.subject || 'No subject'} </br>`;
                })
                .join(''),
              tickets: ticketsArray.map(item => valuesTickets.find((t) => (t.real_id ? t.real_id : t.id_tickets) == item)?.id_tickets)
            }}
            fields={[
              {
                name: 'tickets',
                visible: false,
              },
              {
                col: 12,
                type: 'textEditor',
                name: 'notes',
                label: language.ALLOWANCE_SELECTED,
                disabled: true,
                style: { height: 70 },
              },
              {
                span: 12,
                type: 'select',
                name: 'sla_id',
                label: language.SLA,
                type: 'select',
                options: slaList,
                //required: !slaStatus,
                visible: !slaStatus
              },
              {
                col: 12,
                type: 'simplesTextEditor',
                name: 'justification',
                label: language.JUSTIFICATION,
                required: true,
              },
            ]}
            onSubmit={onFormSubmitReprocess}
          />
        </Dialog>
      )}

      {page == 'result-sla' && (
        <Dialog
          open={true}
          onClose={() => setPage('list')}
          title={language.REPROCESS_SLA}
          maxWidth="lg"
        >
          <CommonForm2
            values={{
              tickes_success: successReprocess
                .map((ticket) => {
                  return `${ticket}</br>`;
                })
                .join(''),
              tickes_failure: failureReprocess
                .map((ticket) => {
                  return `${ticket}</br>`;
                })
                .join(''),
            }}
            fields={[
              {
                col: 12,
                type: 'textEditor',
                name: 'tickes_success',
                label: language.SUCCESS,
                disabled: true,
                style: { height: 70 },
              },
              {
                col: 12,
                type: 'textEditor',
                name: 'tickes_failure',
                label: language.FAILURE,
                disabled: true,
                style: { height: 70 },
              },
            ]}
          />
        </Dialog>
      )}
    </Card>
  );
};

export default index;
