import React, {useRef, useCallback, Fragment, useState, useEffect} from 'react';
import moment from 'moment';
import Store from '@data/Store';
import Constants from '@data/Constants';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Card} from '@material-ui/core';
/*-----------------------common----------------------------------*/
import Header from './Header';
import DownloadFiles from '../../../../util/DownloadFiles';
/*-----------------------components----------------------------------*/
import Column from './Column';
import ViewActivity from '../ViewActivity';
import Form from '../Form';
import ModalOtherCust from './ModalOtherCust';

/*
	-------------------------formato----------------------------
	let data = {
	  id:3240, //importante colocar o id da atividade aqui, é a key do loop e o id do drag-drop
	  avatares:[
	    {name:'Rodrigo', src:undefined},
	    {name:'Carlos', src:undefined},
	    {name:'Marcos', src:undefined},
	    {name:'Platão', src:undefined},
	    {name:'João', src:undefined},
	    {name:'Magno', src:undefined},
	  ],
	  tags:[
	    {name:'Sprint 4', color:'#13CBF0'},
	    {name:'Lead Up', color:'#F0B02B'},
	    {name:'UX/UI', color:'#F05E02'},
	    {name:'UX/UI 2', color:'var(--primary)'},
	  ],
	  name:'Lorem ipsum dolor sit ambt almee deluradem ipsum dolor sit ambt almee delurad.',
	  dt:'12/03/2012',
	  commentsTotal:12,
	  filesTotal:3,
	  percent:60,
	  percentColor:'var(--danger)',
	  
	};
*/

let stage = [
   'BACKLOG',
   'TODO',
   'DOING',
   'STOPPED',
   'VALIDATION',
   'DONE'
]
let act = (e) => [...e.utils.DONE, ...e.utils.BACKLOG, ...e.utils.DOING, ...e.utils.STOPPED, ...e.utils.VALIDATION, ...e.utils.TODO];

export default function({
   data=[],
   options,
   project,
   idProject,
   onSubmit,
   onDelete:_onDelete,
   users,
   action,
   APIEndpoints,
   subActivities,
   columnTheme,
   state={},
   ticket,
   clearHistory,
   updateProject
}){
	let [viewOpen, setViewOpen] = useState({});
   let [formOpen, setFormOpen] = useState(false);
   let [clear,setclear]= useState(false);
   const [isDuplicate, setIsDuplicate] = useState(false);
   const [modalCustOpen, setModalCustOpen] = useState(false);
   let [formValue, setFormValue] = useState({})
	let refAliceCarousel = useRef(null);
   let [generalParams, setGeneralParams] = useState([])   
     
   useEffect(()=>{
      let {id, pj, openView} = state;
      if(id && openView){
         Store.project = pj;
         onView(id, pj);
      }
      if(ticket){
        
         setTimeout(() => {
            
        
          setFormOpen(true)
       }, 4000);
       }

       action.execute('get', APIEndpoints.GENERAL_PARAMETERS, 'general_params').then(res => {
         setGeneralParams(res.data)
       }).catch(error =>{
       })

   }, [])
   
   
	let onView = (id, pj) => {
      
      let _act = act(pj||project).find(e => e.id_project_activity==id)||{};
      if((_act.usersid||[]).includes(Store.getUserLoged().id)||
         project.fk_id_sponsor == Store.getUserLoged().id ||
         Store.getUserLoged().role =='client' ||
         _act.user_cad == Store.getUserLoged().id
      ){
         setViewOpen({..._act});
      }else{
         Store.toggleAlert(true, 'Você não tem acesso a essa atividade', 'error');
      }
   };
   
   function onDrop(drop, index){
     
      let data = JSON.parse(drop.milestone);
      
      if(data.percent<100 && index==5){
         onView(data.id, data.project);
      }else{
         let values = {
            fk_id_milestone: parseInt(data.id),
            status: stage[index],
            description: '',
            id_project: idProject
         };
         
         action.execute('post', APIEndpoints.PROJECT + '/createStMil', 'project', values).then(res => {
           updateProject()
         })
      }
      
   };

   let onEdit = (id, duplicate)=>{
      if (duplicate) {
         setIsDuplicate(true);
      }
      setFormOpen(true);
      setFormValue(act(project).find(e => e.id_project_activity==id)||{});

   };
   

   function onDelete(id){
      let activity = data.flatMap(e => e.data).find(e => e.id_project_activity==id)||{}
      if( activity.percent ==100 ){
         Store.toggleAlert(
            true,
            `Atividade Finalizada!`,
            'error'
         );
         }
      
      else{
         _onDelete(id);
      }
   }
   function onOpenForm(id){
      setFormOpen(true);
      setFormValue(act(project).find(e => e.id_project_activity==id)||{});
   }

   useEffect(() => {
      let evtUpdateStatus = window.$eventBus.on('evtUpdateStatus', function(data){
         if(!!Object.keys(viewOpen).length){
            onView(data.fk_id_project_activity, data.pj);
         }
      });

      return function(){
         evtUpdateStatus.unSubscribe();
      }
   }, [viewOpen]);

   useEffect(() => {
      let evtActionActivity = window.$eventBus.on('actionActivity', function(data){
         if(data.edit){
            setFormOpen(true);
            setFormValue(act(data.project).find(e => e.id_project_activity==data.id)||{});
         }else if(data.delete){
            onDelete(data.id);
         }else{
            setFormOpen(true);
         }
      });

      return function(){
         evtActionActivity.unSubscribe();
         
      }
   }, [])
   // --------------------------------------------------view-activities-----------------------------------------------------------------
   //--bloco 5
   function onSubmitComent(values){
      action.execute(
         'post',
         APIEndpoints.PROJECT + '/comments',
         'project',
         values,
         null,
         {btn_loading:'comments'}
      ).then(e=>{
         
         onView(values.fk_id_project_activity, e.data)
      })
   }

   //--bloco 3
   
   function onSubmitActivitieAntecessor(values){
      action.execute(
         'put',
         Constants.APIRoot + '/api/project/activityantecessor',
         'project',
         values,
         'id_project'
      ).then(e=>{
         onView(values.id_project_activity, e.data);
      });
   }

  
   function onDownloadFile(id_project_activity, name){
      DownloadFiles(APIEndpoints.REQUESTS + '/api/downloadgp/' +id_project_activity + '&' + name, name) 
   }

   function ondeletefile(f){
      action.execute('get', `${APIEndpoints.REQUESTS}/deletefileproject/${f.id_project_activity}&${f.id_gp_file}`)
   }

   function UpdateFiles(files, data) {
      Array.from(files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {  
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
  
  let upload = []
  
  promise.then(
   (result) => {
             upload.push({
              idActivity: data.id_project_activity,
              id_project_activity : data.id_project_activity,
              file: result,
              data: files,
              name: file.name,
              key: file.type
            });
            action.execute(
              'post',
              APIEndpoints.PROJECT + '/uploadActivity',
              'project',
              upload
            );
         },
         (err) => {}
       );
      });
    };

   if(clear){
      ticket = null;
   }
   
	return(
		<Fragment>
         {!!Object.keys(viewOpen).length &&
            <ViewActivity 
               open={!!Object.keys(viewOpen).length} 
               data={viewOpen}
               onOpenForm={onOpenForm}
               onClose={() => {setViewOpen({}), updateProject()}}
               users={users}
               options={options}
               project={project}
               action={action}
               APIEndpoints={APIEndpoints}
               subActivities={subActivities}
               onSubmit={onSubmitComent}
               onSubmitActivitieAntecessor={onSubmitActivitieAntecessor}
               onDownloadFile={onDownloadFile}
               UpdateFiles={UpdateFiles}
               ondeletefile={ondeletefile}
            />
         }
         {formOpen &&
            <Form 
               generalParams={generalParams}
               open={formOpen} 
               onClose={() => {
                  setFormOpen(false);
                  setFormValue({});
                  setIsDuplicate(false);
                
               }}
               ticket={ticket}
               action={action}
               APIEndpoints={APIEndpoints}
               values={formValue}
               options={options}
               project={project}
               idProject={idProject}
                 Store={Store}
               onOpenForm={onOpenForm}
               clearHistory={clearHistory}
               onSubmit={onSubmit}
               onDownloadFile={onDownloadFile}
               ondeletefile={ondeletefile}
               isDuplicate={isDuplicate}
               updateProject = {updateProject}
            />
         } 
         {modalCustOpen &&
            <ModalOtherCust
               open={modalCustOpen}
               onClose={() => {
                  setModalCustOpen(false)
               }}
               idProject={idProject}
               action={action}
               APIEndpoints={APIEndpoints}
            />
         }

			<div id='Kanban'>
				<Header
               tags={project.tagpj||[]}
               action={action}
               APIEndpoints={APIEndpoints}
               refAliceCarousel={refAliceCarousel}
               setFormOpen={setFormOpen}
               clear={setclear}
               columnTheme={columnTheme}
               setModalCustOpen={setModalCustOpen}
               project={project}
            />
				<Card className='content'>
					<AliceCarousel
					  startIndex={0}
             
                 ref={refAliceCarousel}
					  dotsDisabled
					  buttonsDisabled
					  fadeOutAnimation
					  stagePadding={
					    {
					      // paddingRight : 18
					    }
					  }
					  responsive={{
					    0: {
					      items: 5
					    }
					  }}
					  infinite={false}
					>
						{data.map((e, i)=>(
                     <Column 
                        {...e} 
                        onView={onView} 
                        onEdit={(id, duplicate) => onEdit(id, duplicate)}
                        onDelete={id => onDelete(id, i)}
                        onDrop={d=>onDrop(d, i)} 
                        key={e.title} 
                        index={i}
                        project={project}
                        columnTheme={columnTheme}
                        
                     />
						))}
					</AliceCarousel>
				</Card>
			</div>
		</Fragment>
	)
}