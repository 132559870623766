export const getBackStatus = (status) => {
  if (status == 'Pending') {
    return 'rgb(240 31 31 / 19%)';
  }
  if (status == 'Open') {
    return '#f0971f30';
  }

  if (status == 'In Progress') {
    return '#f0971f30';
  } else {
    return 'rgb(52, 172, 160, 0.35)';
  }
};

export const getColorStatus = (status) => {
  if (status == 'Pending') {
    return 'rgb(240 31 31)';
  }
  if (status == 'Open') {
    return 'rgb(240, 151, 31)';
  }

  if (status == 'In Progress') {
    return 'rgb(240, 151, 31)';
  } else {
    return 'rgba(52, 172, 160)';
  }
};