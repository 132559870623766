import React from 'react';
/*-------------------------------common--------------------------------------------*/
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';

/*-------------------------------material-ui--------------------------------------------*/
import { Grid } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { Tooltip } from 'react-tippy';

import { SlaAnalysisTrimestral } from './Graphs';

/*
	--------------------formato--------------------------------
	let data = [
		[50, 60, 70],
		[90, 80, 20],
		[55, 11, 67],
	];

	let monthPosition = 6;
*/

export default function ({ language, data, monthPosition }) {
  let month = language.DATE_LANG.monthLongList;

  let prevDiffConsumed = data[0][0] - data[0][2];
  prevDiffConsumed = new Intl.NumberFormat(language.LOCATION_LANGUAGE, {
    maximumFractionDigits: 2,
  }).format(prevDiffConsumed);

  let prevDiffConsumed1 = data[1][0] - data[1][2];
  prevDiffConsumed1 = new Intl.NumberFormat(language.LOCATION_LANGUAGE, {
    maximumFractionDigits: 2,
  }).format(prevDiffConsumed1);

  let prevDiffConsumed2 = data[2][0] - data[2][2];
  prevDiffConsumed2 = new Intl.NumberFormat(language.LOCATION_LANGUAGE, {
    maximumFractionDigits: 2,
  }).format(prevDiffConsumed2);

  let prevMonth2 = month[monthPosition - 2];
  let prevMonth = month[monthPosition - 1];
  let currentMonth = month[monthPosition];

  let checkIndex = month.indexOf(month[monthPosition]);

  if (checkIndex === 0) {
    prevMonth = month[month.length - 1];
    prevMonth2 = month[month.length - 2];
  } else if (checkIndex === 1) {
    prevMonth2 = month[month.length - 1];
  }

  return (
    <Card id="SlaAnalysisTrimestral" className="h-100">
      <AppBar
        title={language.FINANCIAL_ANALYSIS_OF_CONTRACT}
        subTitle={language.IN_THE_LAST_SEMESTER}
      >
        <span className="current-month-label">{language.MONTH_SELECTED}: </span>
        <span className="current-month-value">{currentMonth}</span>
      </AppBar>
      <div className="content-medium pt-0">
        <Grid container spacing={16}>
          <Grid item xs={4}>
            <ReactHighcharts
              config={SlaAnalysisTrimestral(...data[0], prevMonth2)}
            />
            {
              <Tooltip
                html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>{language.DIFFERENCE_BETWEEN_EXPECTED_AND_CONSUMED}</strong>
                  </div>
                }
                position="bottom"
                theme="dark"
                arrow={true}
              >
                <p class="prev-vs-con">
                  {language.FORESEEN} - {language.CONSUMED} = <span>{prevDiffConsumed}</span>
                </p>
              </Tooltip>
            }
          </Grid>
          <Grid item xs={4}>
            <ReactHighcharts
              config={SlaAnalysisTrimestral(...data[1], prevMonth)}
            />
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.DIFFERENCE_BETWEEN_EXPECTED_AND_CONSUMED}</strong>
                </div>
              }
              position="bottom"
              theme="dark"
              arrow={true}
            >
              <p class="prev-vs-con">
                {language.FORESEEN} - {language.CONSUMED} = <span>{prevDiffConsumed1}</span>
              </p>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <ReactHighcharts
              config={SlaAnalysisTrimestral(...data[2], currentMonth)}
            />
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.DIFFERENCE_BETWEEN_EXPECTED_AND_CONSUMED}</strong>
                </div>
              }
              position="bottom"
              theme="dark"
              arrow={true}
            >
              <p class="prev-vs-con">
                {language.FORESEEN} - {language.CONSUMED} = <span>{prevDiffConsumed2}</span>
              </p>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
