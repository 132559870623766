import Store from '@data/Store';

// ----------------------------------------------------------------------

const { language } = Store;

// ----------------------------------------------------------------------

export const columns = [
  {
    key: 'id_channel',
    label: language.ID,
    style: { width: 30, textAlign: 'left' },
  },
  {
    key: 'name',
    label: language.CHANNEL,
    style: { width: 140, textAlign: 'left' },
  },
  {
    key: 'is_attachment_required',
    label: language.ATTACHMENT,
    style: { width: 30, textAlign: 'left' },
  },
  {
    key: 'action',
    label: language.ACTIONS,
    style: { width: 30, textAlign: 'right' },
  },
];
