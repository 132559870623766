import React, {useState, Fragment, useEffect, useReducer, useCallback} from 'react';
import './index.css';
import Constants from '@data/Constants';
/*-----------------------------common--------------------------*/
import CommonForm from '@common/CommonForm2';
import Dialog from '@common/Dialog';
import BarBlue from '@images/BarBlue.png';
import AppBar from '@common/AppBar2';
/*--------------------------material-ui--------------------------*/
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/*--------------------------pages--------------------------*/
import Block_1 from './Blocks/Block_1';
import Block_2 from './Blocks/Block_2';
import Block_3 from './Blocks/Block_3';
import Block_4 from './Blocks/Block_4';

import Store from '@data/Store';

const { language } = Store;

export default function({
  open, 
  onClose,
  values,
  options,
  execute,
  APIEndpoints,
  onUpdate,
  ticketparam,
  setTickets,
  redirect,
  user
}){
  /*-----------------------------------states------------------------------------------------*/
  
  let [form, setForm] = useReducer((state, v)=> [...state, v], []);
  let [formValues, setFormValues] = useState({});
  let [formPrimary, setFormPrimary] = useState(null);
  let [OnlyOneCommit, setOnlyOneCommit] = useState(false);

  /*-----------------------------------useEffects------------------------------------------------*/
  useEffect(()=>{
    if (values){
      values.name_users = Array.from(values && values.users_name ? values.users_name : [])
      values.fk_id_department_name_ = Array.from(values && values.fk_id_department_name ? values.fk_id_department_name : [])
      values.benefited_area_project_name_ = Array.from(values && values.benefited_area_project_name ? values.benefited_area_project_name : [])
   }
    setFormValues(values || {});

    if(values){
      onChangeField({name:'fk_id_contract'}, values.fk_id_contract);
    }
  }, [values]);

  /*-----------------------------------functions------------------------------------------------*/
  
   const onSubmit = (v) => {
    //setOnlyOneCommit(true)
      if(v.id_project && !v.isTemplate){
        execute('put', Constants.APIRoot + '/api/project', 'projects', v, 'id_project', {btn_loading:'saveProject'}).then(e => {onClose(); onUpdate()});
      }else{
        execute('post', Constants.APIRoot + '/api/create/project', 'projects', v, null, { btn_loading: 'saveProject' }).then(e => {
          const idProject = e.data.newProjectId;
          redirect('/dash/gp/' + idProject + '?open-costs');
          onClose();
        });
      } 
    };

   const onChangeField = (f, v) => {
      setFormValues(state => ({...state, [f.name]:v}));
      if(f.name=='fk_id_contract'){
        execute('post', APIEndpoints.REQUESTS + '/api/ticketchange', 'tickets', { id_contract: v }, null, null, false).then(res => {
          setTickets(res.data)
        });
      }
   };

   if (ticketparam){
     values =[]
     values.tickets = [ticketparam.id_tickets]
     values.users = [ticketparam.user_solicit[0].id_user]
   }
  
   return(
    <Dialog
      PaperProps={{
        id: 'DialogProjectForm'
      }}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      contentTitle={{
        style:{width:'100%'}
      }}
      titleContentProps={{
        style:{
          width:'calc(100% - 60px)'
        }
      }}
      title={
        <div className="w-100 bar" style={{ zoom: 0.8 }} zoom={0.8}>
          <CommonForm
            onRef={r => setForm(r)}
            values={{
              name:formValues.name
            }}
            individual
            alert={false}
            fields={[
              {
                type: 'text',
                name: 'name',
                label: language.PROJECT_NAME,
                className: 'subject-ticket',
                required:true
              }
            ]}
            onRef={(r) => setForm(r)}
            onChangeField={(f, v) => {
                formPrimary.handleValue(v, f.name)
            }}
          />
        </div>
      }
      
      contentProps={{
        style:{
          background:'white'
        }
      }}
    >
    	<CommonForm
    		childFields={[
                ...form.flatMap(e => e.getFields().map(e=>({ name:e.name, type:e.type, required:e.required})))
         ]}
         values={values}
         onChangeField={onChangeField}
         hiddenSubmit={OnlyOneCommit}
         _onSubmit={() => {
            form.forEach(e=>{
               e.submit();
            })
         }}
         loading='saveProject'
         onSubmit={values?.role ? (values.role!= "tech" || values.fk_id_sponsor == user.id || values.user_cad == user.id ? onSubmit : '') : onSubmit} 
         onRef={r => setFormPrimary(r)}
    		fields={[
    			{
    				/*########################################-Informações Báscias-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
    					<Block_1
                     options={options}
                     ticketparam={ticketparam}
                     values={values}
                     setForm={setForm}
                     h={h}
                     formValues={formValues}
                  />
    				),
    			},
    			{
    				/*########################################-Envolvidos no projeto-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
                  <Block_2
                     options={options}
                     values={values}
                     setForm={setForm}
                     h={h}
                    formValues={formValues}
                     />
    				),
    			},
    			{
    				/*########################################-Período-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
                  <Block_3
                     setForm={setForm}
                     values={values}
                     h={h}
                  />
    				),
    			},
    			{
    				/*########################################-Evidências-#####################################################*/
    				type:'element',
    				content:(v,h)=>(
                  <Block_4
                     setForm={setForm}
                     values={values}
                     h={h}
                  />
    				)
    			}
          ]}
          
    	/>
    </Dialog>
  )
}