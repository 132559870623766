import React, {useState, useEffect} from 'react';
import Button from '@common/Button';
import Popover from '@material-ui/core/Popover';
import {Badge, Avatar} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {truncate} from '@common/Func';
import './Notification.css';


function stripHtml(html){
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export default function(props){
	let [notificationButton, setNotificationButton] = useState({index: 0, anchorEl: null});

	let {new_msg_notifications, openMsg, language, role,userid} = props;

	function handleNotificationClick (index, evt) {
	 
	  setNotificationButton({ index, anchorEl:evt.currentTarget });
	};

	function handleNotificationClose () {
	  
	  setNotificationButton({ index:0, anchorEl:null });
	};
	 
	 
	new_msg_notifications = new_msg_notifications.filter(e=>  
		e.recipient==userid && parseInt(e.user_cad) !== parseInt(userid))

	let FDate = moment.locale()=='pt-br'?'DD/MM/YYYY':'YYYY/MM/DD';
	return(
		<div style={{marginLeft:'10px'}} className={`d-flex align-items-center align-items-center mr-3 ${new_msg_notifications.length > 0 ? 'fa-blink' : ''}`} id='Notification'>
		  
		  <Button 
		    variant='circle'
		    icon={
		      <Badge badgeContent={new_msg_notifications.length} invisible={!new_msg_notifications.length} className='badge-indicator-notifications'>
		        <i className='fas fa-comments'/>
		      </Badge>
		    }
		    style={{
		      borderRadius:8,
		      width:40,
		      overflow:'visible'
		    }}
		    iconProps={{
		      style:{fontSize:17}
		    }}
		    onClick={(e)=>handleNotificationClick(1, e)}
		    color='primary'
		  />

		    <Popover
		      id="popover-notification-msg"
		      anchorEl={notificationButton.anchorEl}
		      open={!!notificationButton.index}
		      onClose={handleNotificationClose}
		      anchorOrigin={{
		        vertical: 'bottom',
		        horizontal: 'center',
		      }}
		      transformOrigin={{
		        vertical: 'top',
		        horizontal: 'center',
		      }}
		      
		    >
		    	{notificationButton.index==1 &&

			    	<ul id='list-msg-notification' className='my-0'>
			    		<PerfectScrollbar
			    		  style={{
			    		    maxHeight:400
			    		  }}
			    		>
			  	  		{
								
							
								new_msg_notifications.filter(e=>  
								      e.recipient==userid  
								     ).map(e=>(
									
									       

			  	  		    <li className='d-flex align-items-center pl-0 py-2' onClick={()=>{openMsg(e); handleNotificationClose()}}>
			  	  		      
			  	  		      	<Avatar className='mx-2' style={{textTransform:'capitalize'}}>{!!e.name?e.name[0]:'?'}</Avatar>
			  	  			    <div className='w-100 ellipsis'>
			  	  			      	<span className='ellipsis text-name'>{e.name} - Ticket {e.realid}</span>
			  	  			      	<span className='ellipsis text-date'>{moment(e.dt_cad).format(FDate+" - HH:mm:ss")}</span>
			  	  			      	{!['tech', 'solicitant'].includes(role) &&
				  	  			      	<span className='ellipsis text-type'>
					  	  			      	{
					  	  			      		e.type=='tech'?language.TECH:
					  	  			      		e.type=='solicitant'?language.SOLICITANT: 'Admin'
					  	  			      	}
				  	  			      	</span>
				  	  			    }
			  	  			      	
		  	  			      		<p className='text-msg my-0 mt-2'>
		                            	{stripHtml(e.description)}
		                            </p>
		  	  			      	
			  	  			    </div>
			  	  		    </li>
			  	  		))}
			  	  		{!new_msg_notifications.length &&
			  	  			<h2 className='color-lead px-3 not-register'>{language.ALERT_NO_RESULT_FOUND}</h2>
			  	  		}
			    		</PerfectScrollbar>
			    	</ul>
			    }

			    {notificationButton.index==2 &&
			    	<h2 className='color-lead px-3 not-register'>{language.ALERT_NO_RESULT_FOUND}</h2>
			    }
		      
		    </Popover>
		    
		</div>
	)
}