import CommonForm from '../../common/CommonForm2';

export default function ChangeRiskForm({
  values,
  store,
  action,
  APIEndpoints,
  onSuccess,
}) {
  let { toggleAlert, language } = store;

  function onSubmit(values) {
    if (values.ccr_order < 1 || values.ccr_weight < 1) {
      toggleAlert(true, language.ERROR_CODE['E020'], 'error');
      return;
    }

    const method = values?.ccr_id ? 'put' : 'post';
    const url = values?.ccr_id
      ? APIEndpoints.CHANGE_CAB_RISK + '/' + values?.ccr_id
      : APIEndpoints.CHANGE_CAB_RISK;

    store.spinner = true;
    action
      .execute(method, url, '', values)
      .then(() => {
        store.spinner = false;
        toggleAlert(
          true,
          method === 'post'
            ? language.ALERT_INSERT_SUCCESS
            : language.ALERT_UPDATE_SUCCESS,
          'success',
        );
        onSuccess();
      })
      .catch(() => {
        store.spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  return (
    <div className="dialogForm">
      <CommonForm
        sensitive
        spacing={8}
        values={values}
        fields={[
          {
            col: 6,
            name: 'type',
            label: language.TYPE,
            type: 'select',
            options: [
              { value: 'custom', label: 'custom' },
              { value: 'global', label: 'global' },
            ],
            required: true,
          },
          {
            col: 6,
            name: 'ccr_name',
            label: language.TITLE,
            type: 'text',
            required: true,
          },
          {
            col: 6,
            name: 'ccr_order',
            label: language.ORDER,
            type: 'number',
            required: true,
          },
          {
            col: 6,
            name: 'ccr_weight',
            label: language.WEIGHT,
            type: 'number',
            required: true,
          },
          {
            col: 6,
            name: 'ccr_required',
            label: language.REQUIRED,
            type: 'switch',
          },
        ]}
        onSubmit={onSubmit}
      />
    </div>
  );
}
