import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Chip, Avatar } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonPage from '../../common/CommonPage';
import { FaExternalLink, FaSearch } from 'react-icons/lib/fa';
import classnames from 'classnames';

@observer
export default class DashboardReports extends CommonPage {
  constructor() {
    super();
    this.state = {
      dash: ''
    };
    this.handleMenu = this.handleMenu.bind(this);
  }

  handleMenu(dash) {
    this.setState({ dash: dash });
  }

  render() {
    let { language, handlePage } = this.props;
    let { dash } = this.state;
    let reportsDash =
      dash === 'gcf' ? (
        <GcfDash language={language} handlePage={handlePage} />
      ) : dash === 'gi' ? (
        <GiDash language={language} handlePage={handlePage} />
      ) : dash === 'gsd' ? (
        <GsdDash language={language} handlePage={handlePage} />
      ) : dash === 'gr' ? (
        <GrDash language={language} handlePage={handlePage} />
      ) : dash === 'gp' ? (
        <GpDash />
      ) : (
        <AllDash language={language} handlePage={handlePage} />
      );
    let active = '#f3f3f3';
    let activeBorder = '1px solid var(--lightGreen)';
    let activeBorderRightColor = 'var(--lightGreen)';

     let p = this.store.getUserLoged().perfil;
     let user = this.store.getUserLoged();
    return (
      <PageLayout
        icon="reportsIcon"
        title={language.REPORTS}
        subtitle={language.REPORTS}
        rightElements={[]}
      >
        <div>
          <Row>
            <Col md={2.5}>
              <Card className="cardDefaultReport" style={{height:731}}>
                <InternalLeftCardMenu
                  status="pointer"
                  border={dash === 'all' ? activeBorder : '#fff'}
                  borderRightColor={
                    dash === 'all' ? activeBorderRightColor : '#fff'
                  }
                  bg={dash === 'all' ? active : '#fff'}
                  cardFunction={() => this.handleMenu('all')}
                  icon="allIcon"
                  titleCard={language.ALL}
                  subtitleCard={language.ACCESS_OPTION_VIEW_REPORTS}
                />
                <InternalLeftCardMenu
                  status="pointer"
                  border={dash === 'gcf' ? activeBorder : '#fff'}
                  borderRightColor={
                    dash === 'gcf' ? activeBorderRightColor : '#fff'
                  }
                  bg={dash === 'gcf' ? active : '#fff'}
                  cardFunction={() => this.handleMenu('gcf')}
                  icon="imgGcf"
                  titleCard='GCF'
                  subtitleCard={language.ACCESS_OPTION_VIEW_REPORTS}
                />
               
                
              </Card>
            </Col>
            <Col md={9.5}>
              <Card className="cardDefaultReport" style={{overflowY:'auto', height:731}}>
                <Row>
                  {reportsDash}
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </PageLayout>
    );
  }
}

class InternalLeftCardMenu extends Component {
  render() {
    return (
      <Card
        style={{
          background: this.props.bg,
          border: this.props.border,
          borderRightColor: this.props.borderRightColor,
          overflow:'visible',
          padding:10,
        }}
        className={classnames(this.props.status, 'my-2')}
        onClick={this.props.cardFunction}
      >
       
          <Chip
            avatar={
              <Avatar
                style={{
                  border: '1.5px solid var(--primary)',
                  background: 'white',
                  width:36,
                  height:36
                }}
              >
                <div
                  className={this.props.icon}
                  style={{ width: '95%', height: '95%' }}
                />
              </Avatar>
            }
            label={this.props.titleCard}
            className='shadown'
            
            
          />
          <hr style={{marginTop:'0.9em', marginBottom:'0.5em'}}/>
          <div>
            {this.props.subtitleCard}
          </div>
          
      </Card>
    );
  }
}

class InternalRightDashMenu extends Component {
  render() {
    return (
      <Card className="internalDashCardDefaultReport pointer">
        <Row>
          <Col md={2}>
            <Avatar
              style={{
                border: '1.5px solid var(--primary)',
                background: 'white',
                width:61,
                height:61
              }}
            >
              <div
                className={this.props.icon}
                style={{ width: '95%', height: '95%' }}
              />
            </Avatar>
          </Col>
          <Col md={10}>
            {this.props.titleCard}
            {this.props.subtitle}
            {this.props.description}
          </Col>
        </Row>
      </Card>
    );
  }
}

class AllDash extends Component {
  render() {
    let { language, handlePage } = this.props;
    return (
      <Fragment>
        <GcfDash language={language} handlePage={handlePage} />
        <GiDash language={language} handlePage={handlePage} />
        <GsdDash language={language} handlePage={handlePage} />
        <GrDash language={language} handlePage={handlePage} />
      </Fragment>
    );
  }
}

class GcfDash extends CommonPage {
  render() {
    let { language, handlePage } = this.props;
    let p =this.store.getUserLoged().perfil;
    let user =this.store.getUserLoged();
    return (
      <Fragment>
        
        {((p.report_tech  ==null &&  
          user.role == 'client' )|| p.report_tech )&&
        <Col md={6} onClick={() => handlePage('tech')}>
          <InternalRightDashMenu
            icon="imgGcf"
            titleCard={<h2>{language.TECH}</h2>}
            subtitle={<p>Gcf</p>}
            description={<div>{language.REPORTS_FOR_TECH}</div>}
          />
        </Col>
        }     
        {((p.report_tech  ==null &&  
          user.role == 'client' )|| p.report_group_tech )&&
        <Col md={6} onClick={() => handlePage('group_tech')}>
          <InternalRightDashMenu
            icon="imgGcf"
            titleCard={<h2>{language.TECH_GROUP}</h2>}
            subtitle={<p>Gcf</p>}
            description={<div>{language.REPORTS_FOR_TECH_GROUP}</div>}
          />
        </Col>
        }
      </Fragment>
    );
  }
}

class GiDash extends CommonPage {
  render() {
    let p =this.store.getUserLoged().perfil;
    let user =this.store.getUserLoged();
    let { language, handlePage } = this.props;
    return (
      <Fragment>

      </Fragment>
    );
  }
}

class GsdDash extends CommonPage {
  render() {
    let p =this.store.getUserLoged().perfil;
    let user =this.store.getUserLoged();
    let { language, handlePage } = this.props;
    return (
      <Fragment>

      </Fragment>
    );
  }
}

class GrDash extends CommonPage {
  render() {
    let p =this.store.getUserLoged().perfil;
    let user =this.store.getUserLoged();
    let { language, handlePage } = this.props;
    return (
      <Fragment>
   
      </Fragment>
    );
  }
}

class GpDash extends Component {
  render() {
    let { language } = this.props;
    return <Card />;
  }
}
