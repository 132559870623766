import React from 'react';
/*---------------------------common----------------------*/
import Progress from '@common/Progress';
import Loading from '@common/Loading';
/*---------------------------material-ui----------------------*/
import { Card } from '@material-ui/core';
/*---------------------------material-ui-icons----------------------*/
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SyncIcon from '@material-ui/icons/Sync';

let icons = [
  <NewReleasesIcon className="icon" />,
  <PersonAddIcon className="icon" />,
  <NotificationsActiveIcon className="icon" />,
  <ReportProblemIcon className="icon" />,
  <SyncIcon className="icon" />,
];

export default function ({
  iconIndex = 0,
  label,
  current,
  opened,
  closed,
  analyse,
  create,
  cancelled,
  rollback,
  inprogress,
  openModalDetail,
  language,
}) {
  return (
    <Card className="card content-medium">
      <h2 className="mb-2">
        {icons[iconIndex]}
        {label}
      </h2>
      <div className="d-flex align-items-center content-progress">
        {label == language.GMUD ? (
          ''
        ) : (
          <Progress
            padding
            current={current}
            hiddenText
            style={{ width: '100%', height: 10 }}
          />
        )}
        {label == language.GMUD ? (
          ''
        ) : (
          <span style={{ color: 'white', fontSize: 11 }} className="ml-1">
            {current}%
          </span>
        )}
      </div>
      <hr />
      <div className="content-info d-flex align-items-center">
        {iconIndex == 4 ? (
          <div className="info-item pr-2" style={{ cursor: 'pointer' }}>
            <span>{language.TICKET_STATUS.OPEN}</span>
            <span>{(opened && opened.length) || 0}</span>
          </div>
        ) : (
          <div className="info-item pr-2">
            <span>{language.TICKET_STATUS.OPEN}</span>
            <span>{opened || 0}</span>
          </div>
        )}

        {analyse && analyse.length >= 0 ? (
          <div
            onClick={() => openModalDetail('gmud', analyse)}
            style={{ cursor: 'pointer' }}
            className="info-item pr-2"
          >
            <span>{language.ANALYSIS}</span>
            <span>{analyse.length || 0}</span>
          </div>
        ) : null}

        {create && create.length >= 0 ? (
          <div
            onClick={() => openModalDetail('gmud', create)}
            style={{ cursor: 'pointer' }}
            className="info-item pr-2"
          >
            <span>{language.CREATE}</span>
            <span>{create.length || 0}</span>
          </div>
        ) : null}
        {inprogress && inprogress.length >= 0 ? (
          <div
            onClick={() => openModalDetail('gmud', inprogress)}
            style={{ cursor: 'pointer' }}
            className="info-item pr-2"
          >
            <span>{language.PROGRESS}</span>
            <span>{inprogress.length || 0}</span>
          </div>
        ) : null}
        {closed && closed.length > 0 ? (
          <div
            onClick={() => openModalDetail('gmud', closed)}
            style={{ cursor: 'pointer' }}
            className="info-item pr-2"
          >
            <span>{language.TICKET_STATUS.CLOSED}</span>
            <span>{closed.length || 0}</span>
          </div>
        ) : null}

        {cancelled && cancelled.length >= 0 ? (
          <div
            onClick={() => openModalDetail('gmud', cancelled)}
            style={{ cursor: 'pointer' }}
            className="info-item pr-2"
          >
            <span>{language.TICKET_STATUS.CANCELLED}</span>
            <span>{cancelled.length || 0}</span>
          </div>
        ) : null}
        {rollback && rollback.length >= 0 ? (
          <div
            onClick={() => openModalDetail('gmud', rollback)}
            style={{ cursor: 'pointer' }}
            className="info-item pr-2"
          >
            <span>{language.ROLLBACK}</span>
            <span>{rollback.length || 0}</span>
          </div>
        ) : null}
      </div>
    </Card>
  );
}
