import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  Paper,
  MenuItem,
  SelectField,
  Button,
  RadioButton,
  RadioButtonGroup,
} from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';

import './AvaliationPage.css';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Card } from '@material-ui/core';
import Constants from '../../data/Constants';
import Actions from '../../data/Actions';
import Store from '../../data/Store';
const styles = {
  block: {
    maxWidth: 250,
  },
  radioButton: {
    marginBottom: 16,
  },
  customWidth: {
    width: 150,
  },
};

var token = '';
var avaliationObj = '';

@observer
export default class AvaliationPage extends Component {
  constructor() {
    super();
    this.sendAvaliation = this.sendAvaliation.bind(this);
    this.clickRadio = this.clickRadio.bind(this);

    this.state = {
      valueCall: 1,
      msgSend: false,

      valueResolved: null,
      avaliation: null,
      experience: null,
      suggestions: null,
      check:true,
      logo : ''
    };
    this.handleChange = this.handleChange.bind(this);

    this.APIRoot = Constants.APIRoot;
    this.APIEndpoints = Constants.APIEndpoints;
    this.action = new Actions();
  }

  componentWillMount = () => {

    let url = new URL(window.location.href);
    token = url.searchParams.get('t');
    this.action.execute('get',this.APIRoot + '/picturePreview', '').then(res => {
      this.setState({logo : res.data})
    })

  };
  handleChange(valor) {
    this.setState({ valueCall: valor });
  }

  sendAvaliation = () => {
    let { valueResolved, avaliation, experience, suggestions } = this.state;

    var body = {
      id_tech: avaliationObj.id_tech,
      id_solicitant: avaliationObj.solicit.id_user,
      fk_id_ticket: avaliationObj.id_ticktes,

      resolved: valueResolved,
      avaliation_level: avaliation,
      experience,
      suggestions,
    };

    this.action.execute(
      'post',
      this.APIEndpoints.AVALIATION,
      'avaliation',
      body
    ).then(v=>{

  this.setState({check:true})
    this.setState({ msgSend: true });
    })
    .catch(g=>{
      this.setState({check:false})
      this.setState({ msgSend: true });
    })


  };

  clickRadio = (status) => {
    this.setState({ valueResolved: status });
  };

  render() {
    let { handlePage, language, onOpenSolicitation } = this.props;
    let { avaliation, valueResolved, experience, logo } = this.state;

    if (token) {
      token = token.replaceAll('%20', '+');
      token = token.replaceAll(' ', '+');
      var decoded = atob(token);
      avaliationObj = JSON.parse(decoded);

    }
    return (
      <div fluid id="AvaliationPage">
        <div className="my-4 d-flex align-items-center justify-content-center w-100">
          <img
            src={logo}
            alt=""
            style={{
              width: '20%',
              minWidth: 250,
            }}
          />
        </div>

        {this.state.msgSend == false ? (
          <Card style={{ marginBottom: 20, width: 503, margin: '0 auto' }}>
            <h2
              style={{
                padding: 20,
                color: '#D79900',
                textAlign: 'center',
                marginBottom: 0,
                color: 'var(--primary)',
              }}
            >
              {language.AVALIATION_TICKET}
            </h2>
            <Row style={{ paddingLeft: '2em', paddingBottom: '2em' }}>
              <Col md={12}>
                <p>
                  <strong style={{ color: 'var(--primary)' }}>
                    {language.SOLICITANT}:
                  </strong>{' '}
                  <span style={{ color: 'var(--warning)' }}>
                    {avaliationObj.solicit.name_person}
                  </span>
                </p>
                <p>
                  <strong style={{ color: 'var(--primary)' }}>
                    {language.COMPANY}:
                  </strong>{' '}
                  <span style={{ color: 'var(--warning)' }}>
                    {avaliationObj.company.name_company}
                  </span>
                </p>
                <p>
                  <strong style={{ color: 'var(--primary)' }}>{language.TECH}:</strong>{' '}
                  <span style={{ color: 'var(--warning)' }}>
                    {avaliationObj.tech}
                  </span>
                </p>
                <p>
                  <strong style={{ color: 'var(--primary)' }}>
                    {language.DEVICE_NAME}:{' '}
                  </strong>{' '}
                  <span style={{ color: 'var(--warning)' }}>
                    {avaliationObj.device_name}
                  </span>
                </p>
                <hr style={{ marginLeft: -32 }} />
                <h3 style={{ color: 'var(--primary)' }} className="pr-3">
                  {language.AVALIATION_TICKET_RESOLUTION_QUALITY}
                </h3>
                <div className="d-flex align-items-center content-avaliation-number">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                    <div
                      style={{ marginTop: valueResolved == e ? -15 : 0 }}
                      onClick={() => {
                        this.setState({ valueResolved: e });
                      }}
                    >
                      <div key={i}>{e}</div>
                    </div>
                  ))}
                </div>

                <h3 style={{ color: 'var(--primary)' }}>
                  {language.AVALIATION_TICKET_RESOLUTION_TIME}
                </h3>
                <div className="d-flex align-items-center content-avaliation-number">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                    <div
                      style={{ marginTop: avaliation == e ? -15 : 0 }}
                      onClick={() => {
                        this.setState({ avaliation: e });
                      }}
                    >
                      <div key={i}>{e}</div>
                    </div>
                  ))}
                </div>

                <h3 style={{ color: 'var(--primary)' }}>
                  {language.AVALIATION_TICKET_TEAM}
                </h3>
                <div className="d-flex align-items-center content-avaliation-number">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                    <div
                      style={{ marginTop: experience == e ? -15 : 0 }}
                      onClick={() => {
                        this.setState({ experience: e });
                      }}
                    >
                      <div key={i}>{e}</div>
                    </div>
                  ))}
                </div>

                <h3 style={{ color: 'var(--primary)' }}>{language.SUGGESTIONS}?</h3>
                <textarea
                  rows="7"
                  placeholder="Escreva sua sugestão"
                  onChange={(e) => {
                    this.setState({ suggestions: e.target.value });
                  }}
                />

                <div className="d-flex justify-content-end mt-3 mr-4 pr-2">
                  <Button onClick={() => this.sendAvaliation()} color="primary">
                    {language.SEND}
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        ) :  this.state.check == true ? (
          <div>
            <Row>
              <p
                style={{
                  background: 'rgb(131, 199, 137)',
                  padding: '1em',
                  color: '#fff',
                }}
              >
                {language.AVALIATION_SENT}
              </p>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <p style={{ background: 'red', padding: '1em', color: '#fff' }}>
                Obrigado, Operação não foi realizada, avaliação já foi efetuada!
              </p>
            </Row>
          </div>
        )}
        <br />
      </div>
    );
  }
}
