import React, {useReducer, memo, useState, useEffect} from 'react';
import CommonTable from '@common/CommonTable';
import Porgress from '@common/Progress';
import MenuTooltip from '@common/MenuTooltip';
import Avatares from './Avatares.js';
import Store from '@data/Store';

const { language } = Store;
 
const Table = memo(({data, onAction})=>{
	return <CommonTable
		pagination={false}
		bar={false}
		data={data.map(e=>({
			actions:(
				<MenuTooltip
					options={[
						{value:1, id:e.id, icon:'fas fa-pencil-alt', label:'Editar', color:'var(--primary)'},
                  {value:2, id:e.id, icon:'fas fa-trash-alt', label:'Excluir', color:'var(--danger)'},
					]}
					onChange={onAction}
				/>
			),
			id:e.id,
			title:e.title,
			predecessors_activitys:e.predecessors_activitys.join(', '),
			sponsor:<Avatares data={e.sponsor}/>,
			evolution:(
				<div className='d-flex align-items-center content-progress-column'>
					<Porgress current={e.evolution} hiddenText background='var(--warning)' className='w-100'/>
					<span>{e.evolution}%</span>
				</div>
			),
			planned_start:e.planned_start,
			realized_start:e.realized_start,
			planned_end:e.planned_end,
			realized_end:e.realized_end,
			planned_duration:e.planned_duration,
			realized_duration:e.realized_duration,
		}))}
		col={[
			{key:'actions', label:'Ações'},
			{key:'id', label:'ID'},
			{key:'title', label:'Título da tarefa'},
			{key:'predecessors_activitys', label:'Atividade predecessora'},
			{key:'sponsor', label:'Responsável'},
			{key:'evolution', label:'Percentual de evolução'},
			{key:'planned_start', label:'início (Planejada)'},
			{key:'realized_start', label:'início (Realizado)'},
			{key:'planned_end', label:'Término (Planejada)'},
			{key:'realized_end', label:'Término (Realizado)'},
			{key:'planned_duration', label:'Duração Prevista'},
			{key:'realized_duration', label:'Duração Realizada'},
		]}
		orderColumn
	/>
	})

export default function({data=[], project}){
	let [open, setOpen] = useReducer((state, v) => state===v?null:v, null);
   function onAction(e){
      window.$eventBus.emit('actionActivity', e.value==1 ? {edit:true, id:e.id, project} : {delete:true, id:e.id, project});
   }

	return(
		<CommonTable
			pagination={false}
			bar={false}
			className='Table'
			col={[
				{key:'btn', label:'', thConfig:true, style:{width:'5%'}},
				{ key: 'activities', label: language.ACTIVITY_GROUP_NAME, thConfig:true, style:{width:'5%'}},
				{ key: 'name', label: language.NUMBER_OF_ACTIVITIES, thConfig:true, style:{width:'60%'}},
				{ key: 'evolution', label: language.EVOLUTION_PERCENTAGE, thConfig:true, style:{width:'30%'}},
				
			]}
			
			data={data.flatMap((e, i)=>[
				{
					ConfigCell:{
						style:{backgroundColor:e.color},
						className:'line-primary',
						onClick(){ setOpen(e.id_project_tag)},
						__order_activities:e.data.length,
						__order_name:e.name,
						__order_evolution:parseInt(e.data.reduce((n,e)=>n+parseInt(e.evolution), 0) / (e.data.length||1))
					},
					btn:(
						<div className='d-flex align-items-center content-btn-control'>
							<i 
								className={open===e.id_project_tag?'fas fa-chevron-down':'fas fa-chevron-right'} 
								
							/>
						</div>
					),
					activities:(
						<div className='content-indicator-qtd'>
							{e.data.length}
						</div>
					),
					name:e.name,
					evolution:(
						<div className='d-flex align-items-center content-progress-column'>
							<Porgress current={parseInt(e.data.reduce((n,e)=>n+parseInt(e.evolution), 0) / (e.data.length||1))} hiddenText background='var(--warning)' className='w-100'/>
							<span className='_white'>{parseInt(e.data.reduce((n,e)=>n+parseInt(e.evolution), 0) / (e.data.length||1))}%</span>
						</div>
					),
					
				},
				open===e.id_project_tag?{
					
					ConfigCell:{
						cols:{
							col_0:{colSpan:4}
						},
						__order_activities:e.data.length,
						__order_name:e.name,
						__order_evolution:parseInt(e.data.reduce((n,e)=>n+parseInt(e.evolution), 0) / (e.data.length||1))
					},
					btn:<Table data={e.data} onAction={onAction}/>
				}:null

			]).filter(Boolean)}
		/>
	)
}

 