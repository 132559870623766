import React, { Fragment, useState, memo } from 'react';
import { Tooltip } from 'react-tippy';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import List from '@common/List';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';

import ListDetail_1 from '../Dialog/ListDetail_1';
import moment from 'moment'
import withLanguage from '../../../../common/languages/withLanguage';

export default memo(withLanguage(
  ({ page, 
    data = [], 
    setidTicket,
    language,
    dataDialog = [] 
  }) => {

    let [open, setOpen] = useState(false);

   return (
      <Fragment>
        <ListDetail_1
          open={open}
          onClose={() => setOpen(false)}
          data={data}
          type = 'Calls'
        />

        <Card id="Calls">
         <AppBar title={language.CALLS} subTitle={`(${language.TOP_TEN_RECENTE})`}>
            <i
              className="fas fa-eye btn-view-detail"
              onClick={() => setOpen(true)}
            />
          </AppBar>
          <hr className="my-0 pb-0 pt-3" />
          <div className="-mt-2">
            <List
              hover={false}
              h={450}
              items={data
                .map((e) => ({
                  className: 'content d-block py-2 li',
                  content: (
                    <Fragment>
                      <Tooltip
                        className="tooptip ellipsis title d-block"
                        title={e.subject + ' #' + e.real_id}
                        arrow={true}
                        position="right"
                        theme="dark"
                        onClick={() => 
                          setidTicket({ id_tickets: e.id_tickets, real_id: e.real_id })
                        }
                      >
                        <span
                          onClick={() =>
                            setidTicket({ id_tickets: e.id_tickets, real_id: e.real_id })
                          }
                          style={{
                            background:
                              e.type_tickets == 4
                                ? 'var(--primary)'
                                : e.type_tickets == 2
                                ? 'var(--warning)'
                                : 'var(--warning)',
                          }}
                          className="recent_t"
                        >
                          {e.real_id}
                        </span>
                        {e.subject}
                      </Tooltip>
                      <Tooltip
                        className="tooptip d-block"
                        title={e.subject + ' #' + e.real_id}
                        arrow={true}
                        position="right"
                        theme="dark"
                        onClick={() =>
                          setidTicket({ id_tickets: e.id_tickets, real_id: e.real_id })
                        }
                      >
                        <p
                          onClick={() =>
                            setidTicket({ id_tickets: e.id_tickets, real_id: e.real_id })
                          }
                          className="ellipsis-line camp-2 description my-0"
                          dangerouslySetInnerHTML={{ __html: e.description }}
                        ></p>
                      </Tooltip>
                      <span className="date">{moment(e.dt_cad).format('DD/MM/YYYY hh:mm')}</span>
                      <i className="fas fa-chevron-right chevron" />
                    </Fragment>
                  ),
                }))}
            />
          </div>
        </Card>
        
      </Fragment>
    );
  },
  function (prev, next) {
    let omit = (obj) => _.omitBy(obj, (val) => typeof val == 'function');
    return _.isEqual(omit(prev), omit(next));
  }
));
