import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Container, Row, Col } from 'react-grid-system';
import FaClose from 'react-icons/lib/fa/close';
import {Card ,Paper} from '@material-ui/core';
import  Dialog  from '@common/Dialog';
import CommonForm from '../../common/CommonForm2';

@observer
export default class AttachTech extends Component {
  render() {
    let { language, onFormSubmit, handlePage,APIEndpoints } = this.props;
     

    let fields = [
      {
          col: 12,
          name: 'id_user',
          label: language.USER,
          type: 'autocomplete',
          name: 'id_user',
          label: ' Técnico',
          className: 'tech-group-ticket',
          required: true,
          method: 'POST',
          route: `${APIEndpoints.USER}/filter/notsolicitant`,
          routeGetCount: `${APIEndpoints.USER}/active/count/notsolicitant`,
      
          fieldquery: 'search',
          textinit:  ' ',
          fieldvaluedb: 'id_user',
          fieldlabeldb: 'name',
          idinit: '',
          routeAll: `${APIEndpoints.USER}/filter/notsolicitant?`,
          detail: true,
          methodGetAll: 'POST',
          isMulti: true
         
      }
    ];
    let button = {label: language.SAVE};

    return (
      <Dialog
       onClose={() => handlePage('list')}
        open={this.props.open}
        title={language.TECH_ATTACH}
        monitor_scroll
      > 
        
      
          <CommonForm
            fields={fields}
            onSubmit={onFormSubmit}
            button={button}
          />
        
          
      </Dialog>
    );
  }
}
