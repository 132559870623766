import React, { Component, createRef } from 'react';
import { Card, TextField, Chip, Grid, FormControl } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
import DialogConfirm from '../../common/DialogConfirm';
import './index.css';
import { withStyles, styled } from '@material-ui/core/styles';
import DownloadFiles from '../../util/DownloadFiles';

import 'react-quill/dist/quill.snow.css';
import { Tooltip } from 'react-tippy';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Store from '@data/Store';
import moment from 'moment';
import 'moment/locale/pt-br';

import Workflow from './Workflow';

import $ from 'jquery';
import Constants from '../../data/Constants';
import AttachFile from '@common/AttachFile';

import CommonForm from '../../common/CommonForm2';
moment.locale('pt-br');
window.jQuery = $;
window.$ = $;

require('jquery-ui-sortable');
require('formBuilder');

const formData = [
  {
    type: 'header',
    subtype: 'h1',
    label: 'formBuilder in React',
  },
  {
    type: 'paragraph',
    label: 'This is a demonstration of formBuilder running in a React project.',
  },
];

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiSelect-select': {
    borderColor: '#039FC0',
    height: 18,
  },
});

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#039FC0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#039FC0',
    },
    '& .MuiOutlinedInput-root': {
      height: 41,

      '& fieldset': {
        borderColor: '#039FC0',
      },
      '&:hover fieldset': {
        borderColor: '#039FC0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#039FC0',
      },
    },
  },
})(TextField);

export default class TaskForm extends CommonPage {
  constructor() {
    super();

    this.state = {
      page: 'list',
      modal: false,
      edit: false,
      nameTemp: '',
      valuesTaskManagement: {},
      groups: [],
      taskName: '',
      taskTimeExecution: '',
      taskTechGroup: null,
      taskDescription: '',
      tasksTemplate: [],
      taskAssociate: {},
      taskDelete: {},
      openDeleteTask: false,
      openAssociateTask: false,
      taskWorkflow: false,
      idTaskEdit: null,
      groupApprove: false,
      usersGroup: [],
      valuesAssocient: [],
      avaliation: false,
      templateform: false,
      id_template_task: 0,
      valuesRelForm: [],
      valuesList: [],
      saveOff: false,
      delay: false,
    };

    this.openModalTaskManagement = this.openModalTaskManagement.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.closemodal = this.closemodal.bind(this);
    this.saveNewTask = this.saveNewTask.bind(this);
    this.associateTask = this.associateTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.submitAssociateTasks = this.submitAssociateTasks.bind(this);
    this.submitDeleteTask = this.submitDeleteTask.bind(this);
    this.handleDeleteTask = this.handleDeleteTask.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.setWorkflow = this.setWorkflow.bind(this);
    this.setWorkflow = this.setWorkflow.bind(this);
    this.saveOff = this.saveOff.bind(this);
    this.getUsersByGroup = this.getUsersByGroup.bind(this);
    this.submitTask = this.submitTask.bind(this);
    this.onDownFile = this.onDownFile.bind(this);
    this.removeFileTask = this.removeFileTask.bind(this)
    this.getData = this.getData.bind(this)
  }

  closemodal() {
    this.setState({ modal: false, nameTemp: '' });
  }

  saveOff() {
    this.setState({ saveOff: false });
  }

  clickTemplate() {
    $('#load').trigger('click');
  }

  openModalTaskManagement(open, v) {
    this.setState({ openAssociateTask: false, valuesTaskManagement: [] });

    if (v) {
      if (v.id_group_approve > 0) {
        this.setState({ groupApprove: true });
      }

      this.setState({
        valuesTaskManagement: {
          taskName: v && v.name,
          description: v && v.description,
          taskTimeExecution: v && v.time_execution,
          taskTechGroupIds: v && v.groupsTechs.map((t) => parseInt(t.value)),
          taskTechGroupNames: v && v.groupsTechs.map((t) => t.label),
          taskTechGroup: v && v.groupsTechs.map((t) => parseInt(t.value)),
          fk_id_tech: v && v.fk_id_tech,
          deadline_date: v && v.deadline_date,
          deadline_time: v && v.deadline_time,
          idTaskEdit: v && v.id_template_task,
          id_group_approve: v.id_group_approve,
          group_approve: v.group_approve,
          openDeleteTask: false,
          openAssociateTask: false,
          filestasks: v.filestasks,
        },
      });
      this.setState({
        modalTaskManagement: open,
        taskName: v && v.name,
        taskDescription: v && v.description,
        taskTimeExecution: v && v.time_execution,
        taskTechGroup: v && v.groupsTechs,
        idTaskEdit: v && v.id_template_task,
        id_group_approve: v.id_group_approve,
        group_approve: v.group_approve,
        fk_id_tech: v && v.fk_id_tech,
        deadline_date: v && v.deadline_date,
        deadline_time: v && v.deadline_time,

        // taskTechGroup: v && v.groupsTechs,
        openDeleteTask: false,
        openAssociateTask: false,
      });
    } else {
      this.setState({
        modalTaskManagement: open,
        taskName: null,
        taskDescription: null,
        taskTimeExecution: null,
        taskTechGroup: null,
        idTaskEdit: null,
      });
    }
  }

  getUsersByGroup(v) {
    this.action
      .execute(
        'post',
        `${Constants.APIEndpoints.GROUP}/getAllUsersFromGroup`,
        '',
        { fk_id_group_users: v }
      )
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            usersGroup: res.data.map((d) => ({
              value: d.id_user,
              label: d.nome,
            })),
          });
        }
      });
  }

  associateTask(v) {
    this.action
      .execute(
        'post',
        `${Constants.APIEndpoints.TASK_TEMPLATE}/${v.id_template_task}/associate`,
        '',
        { id_template_task: v.id_template_task },
        {},
        null,
        null
      )
      .then((g) => {
        this.setState({
          taskAssociate: v,
          valuesAssocient: {
            fk_id_template_assoc: g.data.map((g) => g.id_template_task_assoc),
          },
          openAssociateTask: true,
          openDeleteTask: false,
          modal: false,
        });
      });
  }

  setWorkflow(v) {
    this.setState({
      taskAssociate: v,
      taskWorkflow: true,
    });
  }

  deleteTask(v) {
    this.setState({
      taskDelete: v,
      openDeleteTask: true,
    });
  }

  handleDeleteTask(v) {
    this.setState({
      taskDelete: {},
      openDeleteTask: false,
    });
  }

  submitAssociateTasks(val) {
    this.action
      .execute('post', `${Constants.APIEndpoints.TASK_TEMPLATE}/${this.state.taskAssociate.id_template_task}/associate`, '', { tasks: val.fk_id_template_assoc })
      .then(() => {
        this.setState({
          openAssociateTask: false,
          taskAssociate: {},
        });

        if (this.props.id_catalog_task) {
          this.action
            .execute('get', `${Constants.APIRoot}/api/tasksTemplate/${this.props.id_catalog_task}&undefined`, '')
            .then((res) => {
              this.setState({
                tasksTemplate: res.data,
              });
            });
        }
      })
      .catch((err) => console.log(err));
  }

  submitDeleteTask() {
    this.action
      .execute('delete', `${Constants.APIRoot}/api/tasksTemplate/${this.state.taskDelete.id_template_task}`, '')
      .then((e) =>
        this.setState({
          openDeleteTask: false,
          tasksTemplate: this.state.tasksTemplate.filter((it) => {
            if (
              it.id_template_task !== this.state.taskDelete.id_template_task
            ) {
              return it;
            }
          }),
        })
      )
      .catch((err) => console.log(err));
  }

  submitTask(values, url, method) {
    this.action
      .execute(method, url, '', values)
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }

        if (this.props.id_catalog_task) {
          this.action
            .execute('get', `${Constants.APIRoot}/api/tasksTemplate/${this.props.id_catalog_task}&${this.props.id_tickets}`, '')
            .then((res) => {
              if( res.data ) {
                res = res.data;
              }
              const newTasks = [];

              res.map(async (t) => {
                const exist = newTasks.findIndex(
                  (task) => task.id_template_task === t.id_template_task
                );

                if (exist > -1) {
                  newTasks[exist].groupsTechs.push({
                    value: t.id_group,
                    label: t.group_name,
                  });
                  newTasks[exist].groupsName += `,${t.group_name}`;
                } else {
                  newTasks.push({
                    ...t,
                    groupsTechs: [
                      {
                        value: t.id_group,
                        label: t.group_name,
                      },
                    ],
                    groupsName: t.group_name,
                  });
                }
              });

              this.setState({
                tasksTemplate: newTasks,
              });

              this.setState({
                modalTaskManagement: false,
              });

              this.props.setReloadTicket(!this.props.reloadTicket);
            })
            .catch((err) => {
              console.error(err)
            });
        }
      })
      .catch((err) => {
        console.error(err)
        if( err.id_error === "acd5bcb7-ba1d-4b0d-beac-8998ad56305c" ) {
          this.store.toggleAlert(true, language.TECH_HAS_ALREADY_CLOSED_TASK, 'error');
        }
      });
  }

  saveNewTask(values, language) {
    this.setState({ saveOff: true });
    let filesSend = [];
    let cont = 0;
    const data = {
      name: values.taskName,
      time_execution: values.taskTimeExecution,
      description: values.description,
      taskTechGroup: values.taskTechGroup,
      fk_id_catalog_task: this.props.id_catalog_task,
      fk_id_group_approve:
        this.state.groupApprove == true ? values.fk_id_group_approve : null,
      fk_id_tickets: this.props.id_tickets,
    };

    if (this.props.task_resp) {
      data.fk_id_tech = values.fk_id_tech;
      data.deadline =
        moment(values.deadline_date).format('YYYY-MM-DD') +
        ' ' +
        moment(values.deadline_time).format('HH:mm');
    }

    let error = false;
    for (const character of values.taskTimeExecution) {
      if (character == '_') {
        error = true;
      }
    }
    if (error == true) {
      this.store.toggleAlert(true, language.ENTER_VALID_TIME_VALUE, 'error');
    } else {
      const url = this.state.idTaskEdit
        ? `${Constants.APIRoot}/api/tasksTemplate/${this.state.idTaskEdit}`
        : `${Constants.APIRoot}/api/tasksTemplate/`;

      const method = this.state.idTaskEdit ? 'PUT' : 'POST';
      if (values.files && values.files.length > 0) {
        let total = Array.from(values.files).length;
        Array.from(values.files).forEach((file) => {
          const promise = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (!!reader.result) {
                resolve(reader.result);
              } else {
                reject(Error('Failed converting to base64'));
              }
            };
          });
          promise.then((result) => {
            if (file.size >= 40000000) {
              alert(`${language.FILE} ${file.name} ${language.MORE_THAN_40MB}`);
              filesSend = [];
              cont = 0;
              setTimeout(
                function () {
                  this.redirect('/dash/gsd');
                }.bind(this),
                1000
              );
            }
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              data.files = filesSend;
              this.submitTask(data, url, method);
            }
          });
        });
      } else {
        this.submitTask(data, url, method);
      }
    }
  }

  updateOrder(id, order) {
    this.action
      .execute(
        'post',
        '${Constants.APIRoot}/api/tasksTemplate',
        'tasks',
        { order, id_task_template: id },
        'id_catalog_task'
      )
      .then((e) => {});
  }

  saveForm(html, data, dt) {
    this.setState({ modal: false });
    this.props.saveForm(
      html,
      this.props.values,
      data,
      dt,
      $('#nameTemp').val()
    );
  }

  getData(){
    this.action
      .execute('get', `${Constants.APIRoot}/api/tasksTemplate/${this.props.id_catalog_task}&${this.props.id_tickets}`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }

        const newTasks = [];

        res.map(async (t) => {
          const exist = newTasks.findIndex(
            (task) => task.id_template_task === t.id_template_task
          );

          if (exist > -1) {
            newTasks[exist].groupsTechs.push({
              value: t.id_group,
              label: t.group_name,
            });
            newTasks[exist].groupsName += `,${t.group_name}`;
          } else {
            newTasks.push({
              ...t,
              groupsTechs: [
                {
                  value: t.id_group,
                  label: t.group_name,
                },
              ],
              groupsName: t.group_name,
            });
          }
        });

        this.setState({
          tasksTemplate: newTasks,
        });
      });

  }
  componentWillMount() {
    const groups = Store.groups;
    const usercon_company = Store.usercon_company;

    const groupMap =
      Store.getUserLoged().role == 'tech'
        ? groups
            .filter((x) =>
              (
                (usercon_company &&
                  usercon_company.length > 0 &&
                  usercon_company[0].group_user) ||
                []
              ).includes(x.id_group_users)
            )
            .map((e) => ({
              value: e.id_group_users,
              label: e.name,
            }))
        : groups.map((e) => ({
            value: e.id_group_users,
            label: e.name,
          }));

    setTimeout(() => {
      this.setState({
        groups: groupMap,
      });
    }, 1000);

    if (this.props.id_catalog_task) {
      this.getData()
    }
  }

  saveRelForm(v) {
    const { avaliation, templateform, id_template_task } = this.state;
    this.action
      .execute('post', this.APIEndpoints.TASK + '/formssaverel', null, {
        ...v,
        id_catalog_task: this.state.id_catalog_task,
        avaliation,
        templateform,
        id_template_task,
        id_tickets: this.props.id_tickets,
      })
      .then((g) => {
        this.setState({
          valuesRelForm: g.data.map((b) => b.fk_id_catalog_task),
          open: false,
        });
      });
  }

  openTemaplateTask(id) {
    this.action
      .execute('get', this.APIEndpoints.TASK + '/getform', 'formstemplates')
      .then((g) => {
        const itens = g.data.map((h) => h.id_template_forms);
        this.setState({
          valuesList: g.data,
          avaliation: false,
          templateform: true,
        });
      });

    this.action
      .execute('post', this.APIEndpoints.TASK + '/formsrel', null, {
        id_template_task: id,
        templateform: true,
      })
      .then((g) => {
        // console.log(g)
        this.setState({
          valuesRelForm: g.data.map((b) => b.fk_id_template_forms),
          open: true,
        });
      });

    this.setState({ id_template_task: id, openAssociateTask: false });
  }
  onDownFile(id, file) {
    DownloadFiles(
      this.APIEndpoints.REQUESTS +
        '/api/downloadtemplatetask/' +
        id +
        '&' +
        file,
      file
    );
  }

  removeFileTask(file){
    const data = {
      fk_id_tickets : this.props.id_tickets,
      fk_id_template_task : this.state.valuesTaskManagement.idTaskEdit,
      id_template_task_files : file.id_template_task_files,
      filename : file.name
    }
    this.action.execute('post', this.APIEndpoints.TASK_TEMPLATE + '/file', '', data).then(res => {
      let values = this.state.valuesTaskManagement
      values.filestasks = values.filestasks.filter(f => f.id_template_task_files !== file.id_template_task_files)
      this.setState({valuesTaskManagement : values})
    })

}
render() {
    const { language, openTemaplateTask, saveRelForm } = this.props;
    const { valuesTaskManagement } = this.state;

    const data = [];
    let dataTaskTemplate = [];
    const sv = true;

    const col = [
      { key: 'nome', label: language.ID, style: { width: 90 } },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 2, textAlign: 'left' },
        thConfig: true,
        jc: 'center',
      },
    ];

    const colTask = [
      { key: 'id_template_task', label: language.ID, style: { width: 90 } },
      { key: 'nome', label: language.NAME, style: { width: 90 } },
      { key: 'description', label: language.DESCRIPTION, style: { width: 90 } },
      { key: 'group_name', label: language.TECH_GROUP, style: { width: 90 } },
      {
        key: 'group_approve',
        label: language.APPROVAL_GROUP,
        style: { width: 90 },
      },
      {
        key: 'time_execution',
        label: language.RUNTIME,
        style: { width: 90 },
      },
      {
        key: 'deadline',
        label: language.TERM,
        style: { width: 90 },
      },
      {
        key: 'associated_tasks',
        label: language.ASSOCIATED_TASKS,
        style: { width: 90 },
      },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 2, textAlign: 'left' },
        thConfig: true,
        jc: 'center',
      },
    ];

    const button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: 10 },
    };

    dataTaskTemplate = (
      (this.state.tasksTemplate && this.state.tasksTemplate) ||
      []
    )
      .filter((c) => c.fk_id_catalog_task == this.props.id_catalog_task)
      .map((v) => {
        return {
          id_template_task: v.id_template_task,
          nome:
            v.fk_id_tickets > 1 ? (
              <Tooltip
                interactive
                html={
                  <li>
                    {language.USER_REGISTERED}: {v.nameuser}
                  </li>
                }
                arrow={true}
                position="left"
                theme="light"
              >
                <div style={{ background: '#FFDEAD', borderRadius: 5 }}>
                  {v.name}
                </div>
              </Tooltip>
            ) : (
              v.name
            ),
          description: v.description
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace('&nbsp;', ''),
          group_name: v.groupsName,
          group_approve: v.group_approve,
          time_execution: v.time_execution,
          deadline: v.deadline
            ? moment(v.deadline).format('DD/MM/YYYY HH:mm')
            : '',
          associated_tasks: v.assoc.map((g) => g),
          actions: (
            <React.Fragment>
              <div className="d-flex align-items-center justify-content-center">
                {this.props.id_tickets > 0 && v.fk_id_tickets == null ? null : (
                  <Chip
                    className="mx-2 muit-chip"
                    label={
                      <div className="d-flex align-items-center justify-content-between">
                        {this.props.id_tickets > 0 &&
                        v.status_ == 'AGUARDANDO' ? (
                          <RaisedButton
                            circleButton
                            icon="fas fa-plus"
                            label={language.ADD_REQUEST_FORM}
                            color="blue"
                            className="mr-2"
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            onClick={() =>
                              this.openTemaplateTask(v.id_template_task)
                            }
                          />
                        ) : v.fk_id_tickets == null ? (
                          <RaisedButton
                            circleButton
                            icon="fas fa-plus"
                            label={language.ADD_REQUEST_FORM}
                            color="blue"
                            className="mr-2"
                            style={{ fontSize: 13, width: 21, height: 21 }}
                            onClick={() =>
                              this.openTemaplateTask(v.id_template_task)
                            }
                          />
                        ) : (
                          ''
                        )}

                        <RaisedButton
                          circleButton
                          icon="fas fa-plus"
                          label={language.ASSOCIATE_PREDECESSOR_TASKS}
                          color="blue"
                          className="mr-2"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => this.associateTask(v)}
                        />

                        <RaisedButton
                          circleButton
                          icon="fas fa-plus"
                          label={language.CREATE_WORKFLOW}
                          color="blue"
                          className="mr-2"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          onClick={() => this.setWorkflow(v)}
                        />

                        <RaisedButton
                          circleButton
                          icon="fas fa-edit"
                          label={language.EDIT}
                          color="default"
                          style={{ fontSize: 12, width: 21, height: 21 }}
                          className="mr-2"
                          onClick={() => this.openModalTaskManagement(true, v)}
                        />

                        <RaisedButton
                          circleButton
                          icon="far fa-trash-alt"
                          label={language.DELETE}
                          color="danger"
                          style={{ fontSize: 13, width: 21, height: 21 }}
                          className="mr-2"
                          onClick={() => this.deleteTask(v)}
                        />
                      </div>
                    }
                  />
                )}
              </div>
            </React.Fragment>
          ),
        };
      });

    return (
      <div id="TaskForm">
        <Dialog
          title={
            this.state.idTaskEdit
              ? language.EDIT_NEW_TASK
              : language.ADD_ASSIGNMENT
          }
          open={this.state.modalTaskManagement}
          contentProps={{
            style: { background: '#ebebeb' },
          }}
          maxWidth="xl"
          onClose={() => this.setState({ modalTaskManagement: false })}
        >
          <CommonForm
            values={valuesTaskManagement}
            onChangeField={(field, value) => {
              if (field.name === 'group_approve') {
                this.setState({ groupApprove: value });
              }
              if (field.name === 'taskTechGroup') {
                this.getUsersByGroup(value);
              }
            }}
            fields={[
              {
                col: 6,
                label: language.TASK_NAME,
                name: 'taskName',
                type: 'text',
                required: true,
              },
              {
                col: 6,
                name: 'taskTimeExecution',
                label: language.TIME_SPENT,
                type: 'text',
                required: true,
                format: '##:##',
                change(v) {
                  return v.formattedValue;
                },
              },
              {
                col: 6,
                name: 'deadline_date',
                label: `${language.TERM} - ${language.DATE}`,
                type: 'date',
                visible: !!this.props.task_resp,
                required: !!this.props.task_resp,
              },
              {
                col: 6,
                name: 'deadline_time',
                label: `${language.TERM} - ${language.HOUR}`,
                type: 'time',
                visible: !!this.props.task_resp,
                required: !!this.props.task_resp,
              },
              {
                col: 6,
                type: 'autocomplete',
                name: 'taskTechGroup',
                textlabel: language.TECH_GROUP,
                className: 'tech-group-ticket',
                required: true,
                method: 'POST',
                route: `${Constants.APIEndpoints.GROUP}/filter`,
                fieldquery: 'search',
                textinit: '',
                idinit: valuesTaskManagement
                  ? valuesTaskManagement.taskTechGroupIds
                  : 0,
                fieldvaluedb: 'id_group_users',
                fieldlabeldb: 'name',
                useinit: true,
                routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                detail: true,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                textinit: valuesTaskManagement
                  ? valuesTaskManagement.taskTechGroupNames
                  : language.TECH_GROUP,
                required: true,
              },
              {
                col: 6,
                name: 'fk_id_tech',
                label: language.DESIGNATED_ANALYST,
                type: 'select',
                options: this.state.usersGroup,
                visible: !!this.props.task_resp,
                required: !!this.props.task_resp,
              },
              {
                col: 6,
                type: 'file',
                name: 'files',
                label: language.ATTACH,
                onClick: (f) =>
                  this.onDownFile(f.id_template_task_files, f.name),
              },
              {
                col: 4,
                type: 'element',
                content: (
                  <div>
                    {valuesTaskManagement.filestasks &&
                      valuesTaskManagement.filestasks.map((file) => (
                        <div>
                          <AttachFile
                            onClick={() =>
                              this.onDownFile(file.id_template_task_files, file.name)
                            }
                            file={file}
                            onDelete={() => this.removeFileTask(file)}
                            label= {file.name}
                          />
                        </div>
                      ))}
                  </div>
                ),
              },
              {
                col: 12,
                name: 'group_approve',
                label: language.APPROVAL_GROUP,
                type: 'switch',
              },
              {
                type: 'autocomplete',
                name: 'fk_id_group_approve',
                textlabel: language.APPROVAL_GROUP,
                className: 'tech-group-ticket',
                filter: { type_search: 3 },
                filterAll: { type_search: 3 },
                method: 'POST',
                route: `${Constants.APIEndpoints.GROUP}/filter`,
                fieldquery: 'search',
                textinit: '',
                idinit: valuesTaskManagement
                  ? valuesTaskManagement.id_group_approve
                  : 0,
                fieldvaluedb: 'id_group_users',
                fieldlabeldb: 'name',
                useinit: true,
                routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
                detail: true,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
                textinit: valuesTaskManagement
                  ? valuesTaskManagement.group_approve
                  : language.APPROVAL_GROUP,
                visible: this.state.groupApprove,
                required: this.state.groupApprove,
              },

              {
                col: 12,
                label: language.DESCRIPTION,
                name: 'description',
                type: 'textArea',
                required: true,
              },
            ]}
            onSubmit={(v) => {
              if (this.state.delay == false) {
                this.setState({ delay: true });
                setTimeout(() => {
                  this.setState({ delay: false });
                  this.setState({ saveOff: false });
                }, 4000);
                this.saveNewTask(v, language);
              }
            }}
          />
        </Dialog>

        <Dialog
          title={
            <TextField
              id="nameTemp"
              name="nameTemp"
              value={this.state.nameTemp}
              onChange={(evt) => {
                this.setState({ nameTemp: evt.target.value });
              }}
              label={language.NAME_FORM}
              margin="dense"
              variant="outlined"
              style={{ width: 300 }}
            />
          }
          open={this.state.modal}
          contentProps={{
            style: { background: '#ebebeb' },
          }}
          maxWidth="xl"
          onClose={() => this.closemodal(false)}
        >
          <div id="fb-editor" />
          <form id="fb-render" />
          <div>
            <div
              className="d-flex align-items-center justify-content-center my-2"
              style={{ float: 'right' }}
            >
              {this.state.edit && (
                <RaisedButton
                  circleButton
                  color="primaryGradient"
                  id="load"
                  icon="fas fa-spinner"
                  style={{ fontSize: 18 }}
                  label={language.TO_CHARGE}
                />
              )}
              <RaisedButton
                circleButton
                color="danger"
                id="clearField"
                icon="fas fa-eraser"
                style={{ fontSize: 18 }}
                label={language.CLEAR}
                className="mx-2"
              />
              <RaisedButton
                circleButton
                color="primaryGradient"
                icon="fas fa-eye"
                id="showData"
                style={{ fontSize: 18 }}
                label={language.VISUALIZE}
                className="mr-2"
              />
              <RaisedButton
                circleButton
                color="success"
                id="send"
                icon="fas fa-save"
                style={{ fontSize: 18 }}
                label={language.SAVE}
                onClick={this.handleModal}
              />
            </div>
          </div>
        </Dialog>

        <DialogConfirm
          open={this.state.openDeleteTask}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={this.submitDeleteTask}
          onClose={this.handleDeleteTask}
          title={language.CONFIRM_DELETE}
          message={this.state.taskDelete.name}
        />

        <Dialog
          open={this.state.openAssociateTask}
          title={language.ASSOCIATE_TASK_TEMPLATE}
        >
          <CommonForm
            values={this.state.valuesAssocient}
            fields={[
              {
                name: 'fk_id_template_assoc',
                type: 'listCheckbox',
                options: (
                  (this.state.tasksTemplate && this.state.tasksTemplate) ||
                  []
                )
                  .filter(
                    (c) =>
                      c.fk_id_catalog_task == this.props.id_catalog_task &&
                      c.id_template_task !=
                        this.state.taskAssociate.id_template_task
                  )
                  .map((v) => ({
                    label: v.name,
                    value: parseInt(v.id_template_task),
                  })),
              },
            ]}
            onSubmit={(val) => this.submitAssociateTasks(val)}
          />
        </Dialog>

        {this.state.open == true ? (
          <Dialog
            PaperProps={{
              style: {
                height: '120%',
              },
            }}
            open={true}
            title={language.TEMPLATE_S_CATALOG}
            onClose={() => this.setState({ open: false })}
            maxWidth="md"
          >
            <Card className="content">
              <CommonForm
                values={{ id_template_forms: this.state.valuesRelForm[0] }}
                fields={[
                  {
                    col: 12,
                    name: 'id_template_forms',
                    label: language.TEMPLATES,
                    type: 'select',

                    options: this.state.valuesList.map((i) => ({
                      value: i.id_template_forms,
                      label: i.name,
                    })),
                  },
                ]}
                onSubmit={(v) => {
                  this.saveRelForm(v);
                }}
              />
            </Card>
          </Dialog>
        ) : null}

        <Dialog
          open={this.state.taskWorkflow}
          title={language.WORKFLOW}
          onClose={() => this.setState({ taskWorkflow: false })}
        >
          <Workflow
            action={this.action}
            endpoints={Constants.APIEndpoints}
            language={language}
            taskAssociate={this.state.taskAssociate}
          />
        </Dialog>

        <div style={{ minHeight: '400px' }}>
          <CommonTable
            style={{ minHeight: '400px' }}
            beforeBar={
              sv == true ? (
                <RaisedButton
                  circleButton
                  style={{ zoom: 0.8 }}
                  zoom={0.8}
                  className="mr-3"
                  color="primary"
                  icon="fas fa-plus"
                  label={language.NEW_TASK_TEMPLATE}
                  onClick={() => this.openModalTaskManagement(true, null)}
                />
              ) : null
            }
            columns={colTask}
            data={dataTaskTemplate}
            showHeaderToolbar
          />
        </div>
      </div>
    );
  }
}
