import React, {Fragment, useState} from 'react';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import moment from 'moment';

export default function({data, onAdd}){
	let [openList, setOpenList] = useState(null);
	return(
		<Fragment>
			<span className='title-label'>Resultado esperado</span>
			<CommonTable
				className='table-items'
				col={[
					{key:'#', label:''},
					{key:'key_result', label:'Key'},
					{key:'result_expected', label:'Resultado esperado'},
				]}
				beforeBar={
					<Button
						icon='fas fa-plus'
						color='warning'
						label='Adicionar'
						variant='circle'
						className='mr-3'
						onClick={()=>onAdd()}
					/>
				}
				data={data.flatMap((e,i)=>[
					{
						btn:(
							<div className='d-flex align-items-center'>
								<i 
									className={`${openList===i?'fas fa-chevron-down':'fas fa-chevron-right'} mr-2 icon_btn_open_close`} 
									onClick={()=>setOpenList(open=>open===i?null:i)}
								/>
								<i 
									className="fas fa-plus-circle mr-2 icon_btn_add" 
									onClick={()=>onAdd(i)}
								/>
							</div>
						),
						key_result:e.key_result,
						result_expected:e.result_expected,
					},
					e.subactivities&&openList===i?{
						ConfigCell:{
							cols:{
								col_0:{
									colSpan:2
								}
							}
						},
						btn:(
							<CommonTable 
								bar={false}
								pagination={false}
								col={[
									{key:'id_okr_results_subactivies', label:'ID'},
									{key:'description', label:'Nome subatividade'},
									{key:'deadline_subactivity', label:'Prazo'},
								]}
								data={e.subactivities.map((e, i) => ({
									id_okr_results_subactivities: e.id_okr_results_subactivities || 0,
									description: e.description,
									deadline_subactivity: moment(e.deadline_subactivity).format('DD/MM/yyyy')
								}))}
							/>
						)
					}:null
				].filter(Boolean))}
			/>
		</Fragment>
	)
}