import React, { useEffect } from 'react';
import Store from '@data/Store';
import { EmptyGrid } from './EmptyState';
import { useServicePortalStyles } from '../helpers';
import {
  ServicePortalTableCell,
  ServicePortalTableHeadCell,
} from './ServicePortalTableCell';
import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

export default function ServicePortalTableWrapper({
  rows,
  columns,
  isLoading,
  size = 'medium',
  pagination = {
    page: 0,
    count: 0,
    rowsPerPage: 20,
    enabled: false,
  },
  selectionEnabled = false,
  selectionKey = '',
  onPageChange,
  onRowsPerPageChange,
  onSelectChange,
}) {
  const { language } = Store;
  const styles = useServicePortalStyles();
  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    console.log('selected', selected);
    onSelectChange?.(selected);
  }, [selected]);

  const isSelected = (key) => selected.indexOf(key) !== -1;

  const handleSelectAll = (event) => {
    if (!selectionEnabled) return;

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[selectionKey]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, key) => {
    if (!selectionEnabled) return;

    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const rowCountPage = rows.length;
  const numSelected = selected.length;

  return (
    <>
      <TableContainer>
        <Table size={size} className={styles.table}>
          <TableHead>
            <TableRow>
              {selectionEnabled && (
                <ServicePortalTableHeadCell padding="checkbox">
                  <Checkbox
                    color="default"
                    indeterminate={
                      numSelected > 0 && numSelected < rowCountPage
                    }
                    checked={rowCountPage > 0 && numSelected === rowCountPage}
                    onChange={handleSelectAll}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </ServicePortalTableHeadCell>
              )}

              {columns
                .filter((item) => !item.hidden)
                .map((column, columnIndex) => (
                  <ServicePortalTableHeadCell
                    key={`col${columnIndex}_${column.id}`}
                  >
                    {column.label}
                  </ServicePortalTableHeadCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              rows.map((row, rowIndex) => {
                const isItemSelected = isSelected(row[selectionKey]);
                const labelId = `service-portal-table-checkbox-${rowIndex}`;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`${row.identifier}_${rowIndex}`}
                    aria-checked={isItemSelected}
                    className={isItemSelected ? styles.tableSelected : ''}
                  >
                    {selectionEnabled && (
                      <ServicePortalTableCell padding="checkbox">
                        <Checkbox
                          color="default"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) =>
                            handleSelect(event, row[selectionKey])
                          }
                        />
                      </ServicePortalTableCell>
                    )}
                    {columns
                      .filter((item) => !item.hidden)
                      .map((columnItem, columnIndex) => (
                        <ServicePortalTableCell
                          key={`row${rowIndex}_col${columnIndex}`}
                          {...(columnItem.cellProps || {})}
                        >
                          {columnItem.render
                            ? columnItem.render({
                                row: row,
                                col: columnItem.id,
                                value: row[columnItem.id],
                              })
                            : row[columnItem.id]}
                        </ServicePortalTableCell>
                      ))}
                  </TableRow>
                );
              })}

            {isLoading && (
              <TableRow style={{ height: 53 * 5 }}>
                <TableCell
                  colSpan={
                    columns.filter((col) => !col.hidden).length +
                    +selectionEnabled
                  }
                  align="center"
                >
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            )}

            {!isLoading && rows.length === 0 && (
              <TableRow style={{ height: 53 * 5 }}>
                <TableCell
                  colSpan={
                    columns.filter((col) => !col.hidden).length +
                    +selectionEnabled
                  }
                  align="center"
                >
                  <EmptyGrid>
                    <h2 className="tw-text-xl tw-font-bold">
                      {language['SERVICE_PORTAL']['PAGE_NOT_RESULTS_FOUND']}
                    </h2>
                  </EmptyGrid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination.enabled && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${language.OF} ${
              count !== -1 ? count : `more than ${to}`
            }`
          }
        />
      )}
    </>
  );
}
