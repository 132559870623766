import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import ServiceOrderList from './ServiceOrderList';
import ServiceOrderForm from './ServiceOrderForm';
import ServiceOrderView from './ServiceOrderView';
import { testRoute } from '@common/Func';
import CommonDelete from '../../common/CommonDelete';
import Animate from '@common/Animate';
import { concatDateHour, moment } from '../../util/dateTime';
const crypto = require('crypto');
@observer
export default class ServiceOrderContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      open: false,
      openThirdLevel: false,
      osremove: null,
      tickets: null,
      clone: false,
      serviceOrders: [],
      filter: {},
      generalParams: [],
    };
    this.handlePage = this.handlePage.bind(this);
    this.onAnalyze = this.onAnalyze.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormTaskSubmit = this.onFormTaskSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.onTaskSubmit = this.onTaskSubmit.bind(this);
    this.handleModalThirdLevel = this.handleModalThirdLevel.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.finalizeOS = this.finalizeOS.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.saveFiles = this.saveFiles.bind(this);
    this.searchOS = this.searchOS.bind(this);
    this.clear = this.clear.bind(this);
  }
  clear() {
    this.setState({ tickets: null });
  }
  decryp(aMsg) {
    var aSecret = 'Orch3str0!@forever!';
    try {
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);

      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  checkBoxSelect(t, id_os) {
    t.finalize = true;
    t.id_os = id_os;
    this.action.execute(
      'post',
      `${this.APIEndpoints.SERVICE_ORDER}/task`,
      'serviceOrder',
      t
    );
  }
  finalizeOS(values) {
    let meth = 'post';
    let path = this.APIEndpoints.SERVICE_ORDER;

    let header = {
      id_order: values.id_os,
      solicitant: values.solicitant_name,
      company: values.company_name,
      tech: values.tech_name,
      objective: values.objective,
      fk_id_solicitant: values.fk_id_solicitant,
      fk_id_user_tech: values.fk_id_user_tech,
    };

    header = JSON.stringify(header);
    header = btoa(header);

    let token = '/dash/serviceorder/avaliation?t=' + header;
    let email_approver = values.aprovador_email;
    let email_solicitant = values.solicitant_email;

    values.email_approver = email_approver;
    values.email_solicitant = email_solicitant;

    values.token = token;

    values.finalize = true;
    if (!values.closed) {
      this.action.execute(meth, path, 'serviceOrder', values);
    }
  }
  componentWillMount() {
    this.setData();
    try {
      this.setState({ tickets: this.props.location.state.ticket });
      this.getData();
      this.action
        .execute('get', APIEndpoints.GENERAL_PARAMETERS, '')
        .then((res) => {
          this.setState({ generalParams: res.data });
        });
    } catch (t) {}
  }
  componentDidUpdate(prevProps) {
    try {
      if (prevProps.location.pathname != this.props.location.pathname) {
        this.setData();
      }
    } catch (e) {}
  }

  setData = () => {
    let { state, pathname } = this.history2.location;
    if (testRoute('/dash/serviceorder', pathname)) this.getData();

    if (
      testRoute(
        ['/dash/serviceorder/view', '/dash/serviceorder/edit'],
        pathname
      )
    ) {
      if (state.clone == true) {
        this.setState({ clone: true });
      }
      this.action.execute(
        'get',
        this.APIEndpoints.SERVICE_ORDER + '/' + state.id_os,
        'serviceOrder'
      );
    }
    if (testRoute('/dash/serviceorder/new', pathname))
      this.store.serviceOrder = '';
  };

  getData() {
    this.action
      .execute('get', this.APIEndpoints.COMPANY, 'companies')
      .then((item) => {
        this.store.companies = item.data;
      });

    this.action
      .execute('get', this.APIEndpoints.SERVICE_ORDER, 'serviceOrders')
      .then((result) => {
        this.setState({ serviceOrders: result.data.reverse() });
      });
    this.action.execute('get', this.APIEndpoints.TASK, 'tasks');

    try {
      let users = JSON.parse(this.decryp(sessionStorage.getItem('users')));
      this.store.users = users;
    } catch (e) {
      this.store.users = [];
    }

    this.action
      .execute('get', this.APIEndpoints.CONTRACT + 'low', 'contracts')
      .then((item) => {
        this.store.contracts = item.data;
      });

    this.action.execute(
      'get',
      `${this.APIEndpoints.TABLE_STRUCTURED}/50`,
      'impacts'
    );
    this.action.execute(
      'get',
      `${this.APIEndpoints.TABLE_STRUCTURED}/60`,
      'priorities'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TABLE_STRUCTURED + '/40',
      'urgencies'
    );
    this.action
      .execute('get', this.APIEndpoints.GENERAL_PARAMETERS, '')
      .then((res) => {
        this.setState({ generalParams: res.data });
      });
  }

  handlePage(page, obj) {
  
    if (page == 'remove') {
      this.setState({ page: page, osremove: obj });
    } else if (page == 'edit' || page == 'view') {
      if (['edit', 'view'].includes(page))
        this.action
          .execute(
            'get',
            this.APIEndpoints.SERVICE_ORDER + '/' + obj.id_os,
            'serviceOrder'
          )
          .then((item) => {
            this.setState({ serviceOrder: item.data, page: 'edit' });
          });
    } else if (page === 'list') {
      this.getData();
    } else if (page === 'form') {
      this.store.serviceOrder = '';
    }
     
  }

  onFormSubmit(values, osId) {
    if (this.state.tickets != null) {
      values.ticket = this.state.tickets;
      this.setState({ tickets: null });
    }

    values.start_date = concatDateHour(values.start_date, values.start_time);

    if (values.end_date && values.end_time)
      values.end_date = concatDateHour(values.end_date, values.end_time);

    if (
      values.break_initial_date &&
      values.break_end_date &&
      values.break_initial_time &&
      values.break_end_time
    ) {
      values.ini_break = concatDateHour(values.break_initial_date, values.break_initial_time);
      values.end_break = concatDateHour(values.break_end_date, values.break_end_time);
    }

    delete values.start_time;
    delete values.end_time;
    delete values.break_initial_date;
    delete values.break_initial_date;
    delete values.break_end_date;
    delete values.break_end_time;

    let meth = 'post';
    let path = this.APIEndpoints.SERVICE_ORDER;
    var btn_loading = 'service-order-post';
    if (osId) {
      meth = 'put';
      values.action = 'update';
      path = path.concat(`/${osId}`);
      btn_loading = 'service-order-put';
    }
    this.action.execute(meth, path, 'serviceOrder', values, null, {
      btn_loading,
    });
  }

  saveFiles(values, osId) {
    var btn_loading = 'service-order-post';
    values.action = 'update';
    this.action.execute(
      'put',
      this.APIEndpoints.SERVICE_ORDER + '/' + osId,
      'serviceOrder',
      values,
      null,
      { btn_loading }
    );
  }

  onDelete() {
    let { osremove } = this.state;
    this.action.execute(
      'delete',
      this.APIEndpoints.SERVICE_ORDER + '/' + osremove.id_os,
      'serviceOrders',
      osremove,
      'id_os',
      { btn_loading: 'delete-os-item-' + osremove.id_os }
    )
    .then((g) => {
      this.getData();
      this.setState({ page: 'list' });
    });
  }

  onFormTaskSubmit(values) {
    values.fk_id_catalog_task
      ? this.action.execute(
          'post',
          `${this.APIEndpoints.SERVICE_ORDER}/task`,
          'serviceOrder',
          values
        )
      : this.handleModal();

    // values.fk_id_catalog_task ? this.redirect('/dash/ServiceOrder') : null;
  }

  onAnalyze(action) {
    let { id_os } = this.store.serviceOrder;
    this.action
      .execute(
        'put',
        `${this.APIEndpoints.SERVICE_ORDER}/${id_os}`,
        'serviceOrders',
        { action: action },
        'id_os'
      )
      .then((e) => this.redirect('/dash/serviceorder'));
  }

  handleModalThirdLevel() {
    this.setState({ openThirdLevel: !this.state.openThirdLevel });
  }

  handleModal() {
    this.setState({ open: !this.state.open });
  }

  onTaskSubmit(values) {
    let method = values.hasOwnProperty('id_catalog_task') ? 'put' : 'post';
    let url = values.hasOwnProperty('id_catalog_task')
      ? this.APIEndpoints.TASK + '/' + values.id_catalog_task
      : this.APIEndpoints.TASK;
    this.action.execute(method, url, 'tasks', values, 'id_catalog_task');
    this.setState({ open: values });
    this.handleModalThirdLevel();
  }

  searchOS = (values) => {
    this.setState({ filter: values });
  };

  render() {
    let {
      language,

      contracts,
      companies,
      users,
      serviceOrder,
      tasks,
      impacts,
      priorities,
      urgencies,
    } = this.store;

    let { serviceOrders } = this.state;

    let history = this.history2;
    let redirect = this.redirect2;
    let redirect_ = this.redirect;
    let { page, openThirdLevel, open } = this.state;

    if (this.state.filter.companies) {
      serviceOrders = serviceOrders.filter((order) =>
        this.state.filter.companies.includes(order.fk_id_company)
      );
    }
    if (this.state.filter.techs) {
      serviceOrders = serviceOrders.filter((order) =>
        this.state.filter.techs.includes(order.fk_id_user_tech)
      );
    }

    if (this.state.filter.dtini && this.state.filter.dtend) {
      serviceOrders = serviceOrders.filter(
        (order) =>
          moment(order.start_date).format('YYYYMMDD') >=
            moment(this.state.filter.dtini).format('YYYYMMDD') &&
          moment(order.start_date).format('YYYYMMDD') <=
            moment(this.state.filter.dtend).format('YYYYMMDD')
      );
    }

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        iconAlert={true}
        loading={false}
      >
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={
              'Desejar excluir o item #' + this.state.osremove.id_os + ''
            }
          />
        ) : null} 

      
      
        {(testRoute(
          ['/dash/serviceorder/new', '/dash/serviceorder/edit'],
          history.location.pathname
        ) ||
          page == 'edit') && (
          <Animate>
            <ServiceOrderForm
              handleModal={this.handleModal}
              generalParams={this.state.generalParams}
              redirect={redirect}
              open={open}
              tickets={this.state.tickets}
              history={history}
              handleModalThirdLevel={this.handleModalThirdLevel}
              openThirdLevel={openThirdLevel}
              clone={this.state.clone}
              onTaskSubmit={this.onTaskSubmit}
              urgencies={urgencies}
              priorities={priorities}
              impacts={impacts}
              tasks={tasks}
              serviceOrder={
                history.location.pathname == '/dash/serviceorder/new'
                  ? ''
                  : serviceOrder
              }
              handlePage={this.handlePage}
              user={this.store.getUserLoged()}
              language={language}
              onFormSubmit={this.onFormSubmit}
              onFormTaskSubmit={this.onFormTaskSubmit}
              contracts={contracts}
              companies={companies}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              users={users}
            />
          </Animate>
        )}
  {testRoute('/dash/serviceorder', history.location.pathname) &&
          page !== 'edit' && (
            <Animate>
              <ServiceOrderList
                clear={this.clear}
                handlePage={this.handlePage}
                redirect={redirect}
                language={language}
                redirect_={redirect_}
                serviceOrders={serviceOrders}
                searchOS={this.searchOS}
              />
            </Animate>
          )}
        {testRoute('/dash/serviceorder/view', history.location.pathname) && (
          <Animate>
            <ServiceOrderView
              users={users}
              history={history}
              store={this.store}
              onFormSubmit={this.onFormSubmit}
              redirect={redirect}
              checkBoxSelect={this.checkBoxSelect}
              serviceOrder={serviceOrder}
              handlePage={this.handlePage}
              language={language}
              saveFiles={this.saveFiles}
              finalizeOS={this.finalizeOS}
              APIEndpoints={this.APIEndpoints}
              onAnalyze={this.onAnalyze}
            />
          </Animate>
        )}
      </CommonPage>
    );
  }
}
