import React, {memo} from 'react';

/* ---------------------common------------------ */
/* ---------------------material-ui------------------ */
import {Card} from '@material-ui/core';

import Kanban from './Kanban';
import jsonFormat from '../jsonFormat';

export default memo(({tickets, projects, users, gmuds, os, redirect})=>{
   //----as regras de negócio de cada módulo fazer dentro do jsonFormat----
   let data = jsonFormat.kanban({tickets, projects, users, gmuds, os});
   return(
      <Card id='view1' className='content px-2'>
         <Kanban
            data={data}
            redirect={redirect}
         />
      </Card>
   )
}, function(prev, next){
   let omit = obj => JSON.parse(JSON.stringify(_.omitBy(obj, val => typeof val=='function')));
   return _.isEqual(omit(prev), omit(next));
})