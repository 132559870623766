import React, {useCallback, useState, memo} from 'react';
import Store from '@data/Store';
import {observer} from 'mobx-react-lite'
/*--------------------common---------------------*/
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
/*--------------------material-ui---------------------*/
import {Card} from '@material-ui/core';
/*-------------------components----------------------*/
import MyCard from './Card';
/*--------------------------------------------------*/

import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from "react-window";
import {AutoSizer} from 'react-virtualized';
import { Droppable } from 'react-drag-and-drop';


const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
  const refSetter = useCallback(scrollbarsRef => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  return (
    <PerfectScrollbar
      	ref={refSetter}
        style={{ ...style, overflow: "hidden" }}
        className='pb-2'
        onScroll={onScroll}
        options={{
	        minScrollbarLength:30
	    }}
    >
      
      {children}
      
    </PerfectScrollbar>
  );
};
/*
	---------------------------------formato---------------------------------------------
	{
		title:'A Fazer',
		data:[
			{
				avatares:[
					{name:'Rodrigo'},
					{name:'Carlos'},
					{name:'Marcos'},
					{name:'Platão'},
					{name:'João'},
					{name:'Magno'},
				],
				tags:[
					{name:'Sprint 4', color:'#13CBF0'},
					{name:'Lead Up', color:'#F0B02B'},
					{name:'UX/UI', color:'#F05E02'},
					{name:'UX/UI 2', color:'#F05E02'},
				],
				name:'Lorem ipsum dolor sit ambt almee deluradem ipsum dolor sit ambt almee delurad.',
				dt:'12/03/2012',
				commentsTotal:12,
				filesTotal:3,
				percent:60,
				percentColor:'var(--danger)',
				
			}
		]
	}
*/
export default memo(observer(({title, data, onDrop=()=>null, onEdit, onDelete, onView, project, columnTheme, redirect})=>{
  let [search, setSearch] = useState('');
	let { PROJECT } = Store.theme;
	let { language } = Store;

	data = data.filter(e => 
		JSON.stringify({
			id:e.id,
			avatares:e.avatares,
			tags:(e.tags||[]).map(e=>e.label).join(' '),
			name:e.name,
			dt:e.dt,
			commentsTotal:e.commentsTotal,
			filesTotal:e.filesTotal,
			percent:e.percent,
		}).toLowerCase().indexOf(search) != -1,
	);
	
	return(

		<Droppable types={['milestone']} onDrop={onDrop} className='border-radius-10 KanbanCardColumn pb-2' style={{'--kanbanColor':PROJECT.column}}>
			<AppBar title={title} titleProps={{style:{color:'white'}}} childrenContentProps={{style:{height:0}}}>
				<div className='qtd-number -mt-3'>
					{(data && data.length)}
				</div>
			</AppBar>
			<hr className='my-0 py-0 mt-1' style={{borderColor:'white', opacity:.4}}/>
			<div className='content-search px-3 mt-2'>
				<CommonForm
					fields={[
						{
							startIcon:'fas fa-search',
							type:'text',
							name:'search',
							placeholder: language.SEARCH_FOR
						}
					]}
					onChangeField={(f, v) => setSearch(v)}
				/>
			</div>
			<div style={{height:'66vh'}} className='content-body'>
	      		{data.length?(
		      		<AutoSizer style={{height:'auto', width:'auto'}}>
		      		    {({height, width})=>(
		      			  	<FixedSizeList
		      		  				
		      	  				height={height}
		      	  		      	itemCount={data.length}
		      	  		      	itemSize={120}
		      	  		      	width={width}
		      	  				
		      	  				outerElementType={CustomScrollbarsVirtualList}
		      	  				
		      	  			>
		      	  				{({ index, style })=>(
		      	  					<div style={style} key={data[index].id} className='content-medium py-0 d-flex align-items-center justify-content-center'>
		      	  						<MyCard {...data[index]} redirect={redirect} project={project} key={data[index].id} onEdit={onEdit} onDelete={onDelete} onView={onView}/>
		      	  					</div>
		      	  				)}
		      			  		
		      			  	</FixedSizeList>
		      	  		)}
		      		</AutoSizer>
	      		):(
		      		<h3
		      		  style={{ color: 'white', fontWeight: 100, fontSize: 16, textAlign:'center' }}
		      		  className="mt-2"
		      		>
								{language.NOTHING_REGISTERED}
		      		</h3>
	      		)}
			</div>
		</Droppable>
	)
}, function(prev, next){
	return  _.isEqual([prev.data, prev.columnTheme], [next.data, next.columnTheme])
}))