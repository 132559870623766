import React, { memo, Fragment, useEffect, useState } from 'react';
import AppBar from '@common/AppBar2';
import CommonForm from '@common/CommonForm2';
import { Grid } from '@material-ui/core';
import { MaskMoney, MaskNumber } from '@common/Mask';
import { flexBasis } from '@common/Func';
import Constants from '@data/Constants'
import Actions from '@data/Actions'
import lodash from 'lodash';
import Store from '@data/Store';

const MyHeader = ({
   title,
   subTitle,
   className,
   isAutocompleteField
}) => (
   <AppBar
      contentProps={{
         className: 'pl-0 ' + className
      }}
      titleProps={{
         style: { color: 'var(--primary)', marginTop: isAutocompleteField ? 15 : 0 }
      }}
      title={title}
      subTitle={subTitle}
   />
)
export default memo(({
   options,
   values,
   setForm,
   h,
   formValues,
   ticketparam
}) => {
   let [values_, setValues_] = useState({ ...values, fk_id_contract: null });

   let { language } = Store;
   const [companySelected, setCompanySelected] = useState(0)
   useEffect(() => {
      const action = new Actions()
      action.execute('get', Constants.APIEndpoints.GENERAL_PARAMETERS)
         .then((res) => {
            if (res.data.id_company_default && !values_) {
               values_ = {}
               values_.fk_id_company = res.data.id_company_default
               setValues_(values_)
               h(res.data.id_company_default, 'fk_id_company')
            }
         })

      action.execute('post', Constants.APIEndpoints.PROJECTS + '/contracts/' + formValues.id_project).then((res) => {
         let fk_id_contract = res.data.map(item => item.id);
         if (!fk_id_contract.length && values.fk_id_contract) {
            fk_id_contract = [values.fk_id_contract];
         }
         setValues_({ ...values_, fk_id_contract });
         h(fk_id_contract, 'fk_id_contract');
      });
   }, []);

   return (
      <div className='wrap mb-3'>

         <AppBar title={language.BASIC_INFO} />
         <div className='content'>
            <CommonForm
               onRef={r => setForm(r)}
               values={values_}
               fields={[
                  {
                     col: 4,
                     type: 'element',
                     name: 'type_project',
                     required: true,
                     beforeContent: (
                        <MyHeader
                           title={language.TYPE_PROJECT}
                           subTitle={language.SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_PROJECT}
                        />
                     ),
                     content: (v, h, submit, error) => (
                        <Fragment>
                           <Grid container spacing={2} className='content-button'>
                              {
                                 [
                                    {
                                       value: 3,
                                       icon: 'fas fa-tools',
                                       label: language.CORRECTIVE,
                                       color: 'var(--primary)'
                                    },
                                    {
                                       value: 1,
                                       icon: 'fas fa-chess',
                                       label: language.STRATEGIC,
                                       color: 'var(--dark)'
                                    },
                                    {
                                       value: 4,
                                       icon: 'fas fa-chart-line',
                                       label: language.EVOLUTIONARY,
                                       color: 'var(--warning)'
                                    },
                                    {
                                       value: 5,
                                       icon: 'fas fa-shield-alt',
                                       label: language.LEGAL_OBLIGATION,
                                       color: 'var(--danger)'
                                    },

                                    {
                                       value: 2,
                                       icon: 'fas fa-chart-line',
                                       label: language.IMPROVEMENT,
                                       color: 'var(--purple)'
                                    },

                                 ].map(e => (
                                    <Grid
                                       key={e.icon}
                                       item xs={2.4}
                                       style={{ '--color': e.color, ...flexBasis(2.4) }}
                                       className='content-item'
                                    >
                                       <div onClick={() => h(e.value, 'type_project')} className={v == e.value ? 'active' : ''}>
                                          <i className={e.icon} />
                                          <span>{e.label}</span>
                                       </div>
                                    </Grid>
                                 ))
                              }
                           </Grid>
                           {error ? (
                              <span className='error-label ml-1'>{error}</span>
                           ) : null}
                        </Fragment>
                     )
                  },
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.EXPECTED_COST}
                           subTitle={language.REPORT_OR_COST_PROVIDED_FOR_THE_REALIZATION_OF_THIS_PROJECT}
                           isAutocompleteField={true}
                        />
                     ),
                     type: 'text',
                     label: language.EXPECTED_COST,
                     name: 'cust_project',
                     InputProps: {
                        inputComponent: MaskMoney
                     },
                  },
                  {
                     col: 2,
                     name: 'pause_project',
                     label: language.PAUSE_PROJECT,
                     type: 'check',
                   },
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           title={language.COMPANY}
                           subTitle={language.SELECT_THE_COMPANY_INVOLVED_IN_THE_PROJECT}
                        />
                     ),
                     type: 'select',
                     name: 'fk_id_company',
                     label: language.COMPANY,
                     options: options.company()
                  },
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           title={language.CONTRACT_MANAGEMENT}
                           subTitle={language.SELECT_WHICH_CONTRACT_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT}
                        />
                     ),
                     type: 'select',
                     name: 'fk_id_contract',
                     label: language.CONTRACT,
                     options: options.contract(e =>
                        formValues.fk_id_company == 1 ?
                           e.fk_id_company == formValues.fk_id_company :
                           e.fk_id_out_company == formValues.fk_id_company
                     ),
                     isMulti: true
                  },
                  {
                     col: 4,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.DEPARTMENT}
                           subTitle={language.SELECT_OR_DEPARTMENT}
                           isAutocompleteField = {true}
                        />
                     ),
                     type: 'autocomplete',
                     name: 'all_departments',
                     label: `${language.DEPARTMENTS} *`,
                     textlabel: `${language.DEPARTMENTS} *`,
                     method: 'POST',
                     route: !companySelected || companySelected === 0 ? null : `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                     filter: {fk_id_company : companySelected},
                     routeAll: !companySelected || companySelected === 0 ? null : `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                     filterAll: {fk_id_company : companySelected},
                     routeGetCount: !companySelected || companySelected === 0 ? null : `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                     fieldquery: 'search',
                     textinit: formValues.all_departments ? formValues.all_departments_name : '',
                     fieldvaluedb: 'id_department',
                     fieldlabeldb: 'name',
                     idinit: formValues.all_departments ? formValues.all_departments  : "",
                     methodGetAll: 'POST',
                     disablePortal: true,
                     disabled: !companySelected || companySelected === 0
                   },
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.AREA_BENEFITED_FROM_THE_PROJECT}
                           subTitle={language.SELECT_ALL_THE_AREAS_THAT_WILL_BE_BENEFITED_BY_THIS_PROJECT}
                        />
                     ),
                     type: 'autocomplete',
                     name: 'benefited_area_project',
                     // label: 'Departamentos *',
                     // textlabel: 'Departamentos *',
                     method: 'POST',
                     route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                     routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                     fieldquery: 'search',
                     textinit: formValues.benefited_area_project_name_ ? formValues.benefited_area_project_name_ : '' ,
                     fieldvaluedb: 'id_department',
                     fieldlabeldb: 'name',
                     idinit: formValues.benefited_area_project ? Array.from(formValues.benefited_area_project)  : "",
                     routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                     methodGetAll: 'POST',
                     disablePortal: true,
                     isMulti: true
                     // type: 'listCheckbox',
                     // name: 'benefited_area_project', benefited_area_project_name
                     // options: options.department(formValues.fk_id_company)
                  },
                  {
                     col: 4,
                     required: true,
                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title={language.INVOLVED_AREAS}
                           subTitle={language.SELECT_ALL_THE_AREAS_THAT_ARE_INVOLVED_IN_THIS_MOVE}
                        />
                     ),
                     type: 'autocomplete',
                     name: 'fk_id_department',
                     // label: 'Departamentos *',
                     // textlabel: 'Departamentos *',
                     method: 'POST',
                     route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                     filterAll : {fk_id_company : companySelected},
                     routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                     fieldquery: 'search',
                     textinit: formValues.fk_id_department_name_ ? formValues.fk_id_department_name_ : "",
                     fieldvaluedb: 'id_department',
                     fieldlabeldb: 'name',
                     idinit: formValues.fk_id_department ? Array.from(formValues.fk_id_department)  : "",
                     routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                     methodGetAll: 'POST',
                     disablePortal: true,
                     isMulti: true,
                     // type: 'listCheckbox',
                     // name: 'fk_id_department',fk_id_department_name
                     // options: options.department(formValues.fk_id_company)
                  },
                  {
                     col: 4,

                     beforeContent: (
                        <MyHeader
                           className='-mt-3'
                           title='Tickets'
                           subTitle={language.SELECT_THE_TICKETS_THAT_YOU_WANT_TO_ASSOCIATE_WITH_THIS_MOVE}
                        />
                     ),
                     type: 'listCheckbox',
                     name: 'tickets',
                     options:
                        ticketparam ? [{ value: ticketparam.id_tickets, label: ticketparam.subject }] :
                           options.ticket()
                  },

                  /*{
                     col:4,
                     beforeContent:(
                        <MyHeader
                           className='-mt-3'
                           title='Custo REALIZADO'
                           subTitle='Informe o custo realizado neste projeto.'
                        />
                     ),
                     type:'text',
                     label:'Custo Realizado',
                     name:'realized',
                  },*/
               ]}
               onChangeField={(f, v) => {
                  if (f.name == 'cust_project') {
                     v = v.replace('.', ',')
                  }else if (f.name == 'fk_id_company'){
                     setCompanySelected(v)
                  }
                  h(v, f.name);
               }}
            />
         </div>
      </div>
   )
})
