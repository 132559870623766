import { Container } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export function EmptyGrid({ children }) {
    return (
        <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4'>
            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7">
                    </ellipse>
                    <g fillRule="nonzero" stroke="#d9d9d9">
                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z">
                        </path>
                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa">
                        </path>
                    </g>
                </g>
            </svg>
            <div>
                {children}
            </div>
        </div>
    )
}

export function EmptyData({ children }) {
    return (
        <div className='tw-flex tw-justify-center tw-items-start tw-gap-4'>
            <ErrorOutline fontSize='large' />
            <div>
                {children}
            </div>
        </div>
    )
}


export function EmptyConfig({ language }) {

    return (
        <div className='tw-w-screen tw-h-screen tw-flex tw-flex-col tw-justify-center'>
            <Container maxWidth="md">
                <EmptyData>
                    <h2 className='tw-text-2xl tw-font-bold'>
                        {language['SERVICE_PORTAL']['PAGE_SETTINGS_NOT_FOUND']}
                    </h2>
                    <div className='tw-flex tw-flex-col tw-gap-5'>
                        <p>
                            {language['SERVICE_PORTAL']['PAGE_SETTINGS_NOT_FOUND_DETAILS']}
                        </p>
                        <Link
                            variant="contained"
                            className='sp-button-action'
                            to='/'
                            onClick={()=> window.location.href = '/'}
                        >
                            {language.LOGOFF}
                        </Link>
                    </div>
                </EmptyData>
            </Container>
        </div>
    )
}

export function EmptyRoute() {
    return (
        <div className='tw-w-screen tw-h-screen tw-flex tw-flex-col tw-justify-center'>
            <Container maxWidth="md">
                <EmptyData>
                    <h2 className='tw-text-2xl tw-font-bold'>
                        404: Not Found
                    </h2>
                    <div className='tw-flex tw-flex-col tw-gap-5'>
                        <p>
                        </p>
                    </div>
                </EmptyData>
            </Container>
        </div>
    )
}

export function EmptyResult({ language }) {
    return (

        <div className='tw-h-full tw-flex tw-justify-center tw-items-center'>
            <div className='tw-flex-grow'>
                <EmptyGrid>
                    <h2 className='tw-text-sm tw-text-gray-400'>
                        {language['SERVICE_PORTAL']['PAGE_NOT_RESULTS_FOUND']}
                    </h2>
                </EmptyGrid>
            </div>
        </div>
    )
}