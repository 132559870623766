import { useState, useEffect, Fragment } from 'react';
import CommonForm2 from '../../common/CommonForm2';
import Dialog from '@common/Dialog';
import Button from '../../common/Button';
import RaisedButton from '../../common/RaisedButton';
import { Chip, Container } from '@material-ui/core';
import CommonDelete from '../../common/CommonDelete';

import './ResolutionCategoryContainer.css';
import TableTree from './TableTree';
const RelationshipTree = ({ action, Constants, language }) => {
  const [n1Options, setN1Options] = useState([]);
  const [n2Options, setN2Options] = useState([]);
  const [n3Options, setN3Options] = useState([]);
  const [n4Options, setN4Options] = useState([]);

  const [allN2, setAllN2] = useState([]);
  const [allN3, setAllN3] = useState([]);
  const [allN4, setAllN4] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedType, setSelectedType] = useState(0)
  const [levelSelected, setLevelSelected] = useState(1);

  const [selectedN1, setSelectedN1] = useState(0);
  const [selectedN2, setSelectedN2] = useState(0);
  const [selectedN3, setSelectedN3] = useState(0);
  const [selectedN4, setSelectedN4] = useState(0);
  const [itemSelected, setItemSelected] = useState([]);
  const [pageTree, setPageTree] = useState('list');

  const [removeDialog, setRemoveDialog] = useState(false);
  const [page, setPage] = useState('level1');
  useEffect(() => {
    action
      .execute(
        'get',
        Constants.APIEndpoints.RESOLUTION_CATEGORY +
          '/bycompany/' +
          selectedCompany +
          '/1/'
          + selectedType,
        ''
      )
      .then((res) => {
        setN1Options(res.data);
      });
    action
      .execute(
        'get',
        Constants.APIEndpoints.RESOLUTION_CATEGORY +
          '/bycompany/' +
          selectedCompany +
          '/2/'
          + selectedType,
          ''
      )
      .then((res) => {
        setAllN2(res.data);
      });
    action
      .execute(
        'get',
        Constants.APIEndpoints.RESOLUTION_CATEGORY +
          '/bycompany/' +
          selectedCompany +
          '/3/'
          + selectedType,
        ''
      )
      .then((res) => {
        setAllN3(res.data);
      });
    action
      .execute(
        'get',
        Constants.APIEndpoints.RESOLUTION_CATEGORY +
          '/bycompany/' +
          selectedCompany +
          '/4/'
          + selectedType,
        ''
      )
      .then((res) => {
        setAllN4(res.data);
      });
  }, [selectedType]);

  useEffect(() => {
    if (selectedN1 > 1) {
      action
        .execute(
          'post',
          Constants.APIEndpoints.RESOLUTION_CATEGORY +
            '/byresolutioncategory/' +
            selectedCompany +
            '/1',
          '',
          {
            n1: selectedN1,
          }
        )
        .then((res) => {
          setN2Options(res.data);
        });
    }
  }, [selectedN1]);

  const handlePage = (page, data, level) => {
    if (page == 'add') {
      setLevelSelected(level);
      if (level) {
        if (level == 1) {
          setPage('level1');
        } else if (level == 2) {
          setPage('level2');
          getNOptions(level, selectedN1, data.id_resolution_category);
          setSelectedN2(data.id_resolution_category);
        } else if (level == 3) {
          setPage('level3');
          getNOptions(
            level,
            selectedN1,
            selectedN2,
            data.id_resolution_category
          );
          setSelectedN3(data.id_resolution_category);
        }
      }
    } else if (page == 'remove') {
      setItemSelected(data);
      setRemoveDialog(true);
      setLevelSelected(level);
      if (level) {
        level == 1
          ? setSelectedN2(data.id_resolution_category)
          : level == 2
          ? setSelectedN3(data.id_resolution_category)
          : setSelectedN4(data.id_resolution_category);
      }
    }
  };

  const getNOptions = (level, n1_level = 0, n2_level = 0, n3_level = 0) => {
    const data = {
      n1: n1_level,
      n2: n2_level,
      n3: n3_level,
      n4: selectedN4,
    };

    action
      .execute(
        'post',
        Constants.APIEndpoints.RESOLUTION_CATEGORY +
          '/byresolutioncategory/' +
          selectedCompany +
          '/' +
          level,
        '',
        data
      )
      .then((res) => {
        if (level == 1) {
          setN2Options(res.data);
        } else if (level == 2) {
          setN3Options(res.data);
        } else {
          setN4Options(res.data);
        }
      });
  };

  const saveItens = (values, level, nSelected, n1_level = 0, n2_level = 0) => {
    const data = {
      itens: values.itens,
      level: level,
      nSelected: nSelected,
      n2_level: n2_level,
      n1_level: n1_level,
    };
    action
      .execute(
        'post',
        Constants.APIEndpoints.RESOLUTION_CATEGORY + '/savetree',
        '',
        data
      )
      .then((res) => {
        if (level == 1) {
          setPage('setPage');
          getNOptions(level, nSelected);
        } else if (level == 2) {
          setPage('level2');
          getNOptions(level, selectedN1, nSelected);
          setSelectedN2(nSelected);
        } else if (level == 3) {
          setPage('level3');
          getNOptions(level, selectedN1, selectedN2, nSelected);
          setSelectedN3(nSelected);
        }
        setPageTree('list');
      });
  };

  const removeResolution = () => {
    const data = {
      item:
        levelSelected == 1
          ? selectedN2
          : levelSelected == 2
          ? selectedN3
          : selectedN4,
      n1: selectedN1,
      n2: selectedN2,
      n3: selectedN3,
      n4: selectedN4,
      level: levelSelected,
    };
    action
      .execute(
        'post',
        Constants.APIEndpoints.RESOLUTION_CATEGORY + '/removeItemTree',
        '',
        data
      )
      .then((res) => {
        levelSelected == 1
          ? getNOptions(levelSelected, selectedN1)
          : levelSelected == 2
          ? getNOptions(levelSelected, selectedN1, selectedN2)
          : getNOptions(levelSelected, selectedN1, selectedN2, selectedN3);

        setRemoveDialog(false);
      });
  };

  let cols = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: language.NAME },
    { key: 'actions', label: language.ACTIONS },
  ];
  const data = (
    levelSelected == 1 ? n2Options : levelSelected == 2 ? n3Options : n4Options
  ).map((item) => ({
    id: item.id_resolution_category,
    name: item.name,
    actions: (
      <Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              {levelSelected < 3 && (
                <RaisedButton
                  circleButton
                  icon="fas fa-plus"
                  label={language.ADD}
                  color="default"
                  style={{ fontSize: 12, width: 21, height: 21 }}
                  className="mr-2"
                  onClick={() =>
                    handlePage(
                      'add',
                      item,
                      levelSelected == 1 ? 2 : levelSelected == 2 ? 3 : 4
                    )
                  }
                />
              )}
              <RaisedButton
                circleButton
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 13, width: 21, height: 21 }}
                onClick={() => handlePage('remove', item, levelSelected)}
              />
            </div>
          }
        />
      </Fragment>
    ),
  }));

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '90%' }}>
          <CommonForm2
            fields={[
              {
                col: 6,
                type: 'autocomplete',
                name: 'fk_id_company',
                label: language.COMPANY,
                method: 'POST',
                route: `${Constants.APIEndpoints.COMPANY}/filter`,
                fieldquery: 'search',
                textlabel: language.COMPANY,
                textinit: '',
                fieldvaluedb: 'id_company',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
              },
              {
                col: 2,
                label: language.TYPE,
                type: 'select',
                name: 'type',
                required : true,
                options: [
                  {value: 1, label : language.INCIDENT},
                  {value: 2, label : language.REQUEST}
                ]
              },
              {
                col: 4,
                name: 'n1',
                type: 'select',
                label: language.N1_NAME,
                options: n1Options.map((n) => ({
                  value: n.id_resolution_category,
                  label: n.name,
                })),
              },
            ]}
            onChangeField={(f, v) => {
              if (f.name == 'fk_id_company') {
                setSelectedCompany(v);
              }
              if (f.name == 'type'){
                setSelectedType(v)
              }

              if (f.name == 'n1') {
                setSelectedN1(v);
              }
            }}
          />
        </div>
        <Button
          variant="normal"
          icon="fas fa-trash"
          label={language.DELETE}
          color="danger"
          style={{ width: 100, height: 40, margin: '-8px 0px 0px 10px' }}
          className="mr-2"
          onClick={() => setRemoveDialog(true)}
        />

      </div>
      <div id="treeWrapper">
        <TableTree
          col={cols}
          data={data}
          values={n2Options.map((n) => n.id_resolution_category)}
          language={language}
          options={allN2}
          saveItens={(values) => saveItens(values, 1, selectedN1)}
          pageTree={pageTree}
          setPageTree={setPageTree}
        />
        {page == 'level2' && (
          <Dialog
            title={language.ADD + ' N3'}
            open={true}
            onClose={() => handlePage('add', {}, 1)}
          >
            <TableTree
              col={cols}
              data={data}
              language={language}
              options={allN3}
              saveItens={(values) =>
                saveItens(values, 2, selectedN2, selectedN1)
              }
              values={n3Options.map((n) => n.id_resolution_category)}
              pageTree={pageTree}
              setPageTree={setPageTree}
            />
          </Dialog>
        )}
        {page == 'level3' && (
          <Dialog
            title={language.ADD + ' N4'}
            open={true}
            onClose={() =>
              handlePage('add', { id_resolution_category: selectedN2 }, 2)
            }
          >
            <TableTree
              col={cols}
              data={data}
              language={language}
              options={allN4}
              saveItens={(values) =>
                saveItens(values, 3, selectedN3, selectedN1, selectedN2)
              }
              values={n4Options.map((n) => n.id_resolution_category)}
              pageTree={pageTree}
              setPageTree={setPageTree}
            />
          </Dialog>
        )}

        {removeDialog === true ? (
          <CommonDelete
            language={language}
            closeModal={() => setRemoveDialog(false)}
            onDelete={removeResolution}
            message={itemSelected.name}
          />
        ) : null}
      </div>
    </div>
  );
};
export default RelationshipTree;