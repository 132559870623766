import React, { useCallback, useState, memo, useEffect } from 'react';
import Store from '@data/Store';
import Actions from '@data/Actions';

import Constants from '@data/Constants';
/*--------------------common---------------------*/
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
/*--------------------material-ui---------------------*/
import { Card } from '@material-ui/core';
/*-------------------components----------------------*/
import MyCard from './Card';
/*--------------------------------------------------*/
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList } from "react-window";
import { AutoSizer } from 'react-virtualized';
import { Droppable } from 'react-drag-and-drop';
import ViewActivity from '../../../Projects/Project/ViewActivity'
import Options from '../../../Projects/Project/options';
import DownloadFiles from '../../../../util/DownloadFiles';

const { language } = Store;

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
	<CustomScrollbars {...props} forwardedRef={ref} />
));
let action = new Actions();

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
	const refSetter = useCallback(scrollbarsRef => {
		if (scrollbarsRef) {
			forwardedRef(scrollbarsRef.view);
		} else {
			forwardedRef(null);
		}
	}, []);

	return (
		<PerfectScrollbar
			ref={refSetter}
			style={{ ...style, overflow: "hidden" }}
			className='pb-2'
			onScroll={onScroll}
			options={{
				minScrollbarLength: 30
			}}
		>

			{children}

		</PerfectScrollbar>
	);
};

let statusIcon = {
	true: 'fas fa-chevron-down',
	false: 'fas fa-chevron-up',
	null: 'fas fa-sort',
}

let _orderIndex = {
	0: 'id_project_activity',
	1: 'dt_start',
	null: 'id_project_activity'
}
export default memo(({ title, data, onEdit, onDelete, onView, project, columnTheme, setTagOpen, setFormOpen, onClose, users, companies, handleModal, handleEdit, handleDelete }) => {
	let [search, setSearch] = useState('');
	let [orderIndex, setOrderIndex] = useState(null);
	let [orderPosition, setOrderPosition] = useState(null);
	let [viewOpen, setViewOpen] = useState({});
	let { APIEndpoints } = Constants;
	let [sprints, setSprints] = useState([]);
	let DONE = [];
	let DOING = [];
	let STOPPED = [];
	let TODO = [];
	let VALIDATION = [];

	useEffect(() => {
		action.execute('post', APIEndpoints.PROJECT + '/sprint/gettemplate', '', project).then(res => {
			setSprints(res.data)
		})

	}, [])

	function onUpdate(type) {
		action
			.execute('post', APIEndpoints.PROJECT + '/sprint/kaban', '', {
				id_project_sprint: id_project_sprint
			}, null, null, false)
			.then(v => {

				//  setSprintKanban({  sprintkaban: [v.data] });

				if (type == 1) {
					setViewOpen(vo => ({
						project: vo.project,
						activity: v.data.ac.find(e => e.id_project_activity == vo.activity.id_project_activity)
					}))
				}
			});
	}

	function onDownloadFile(id_project_activity, name) {
		DownloadFiles(APIEndpoints.REQUESTS + '/downloadgp/' + id_project_activity + '&' + name, name);
	}

	function onSubmitComent(values) {
		action.execute(
			'post',
			APIEndpoints.PROJECT + '/comments',
			'project',
			values,
			null,
			{ btn_loading: 'comments' }
		).then(e => {

			onView(values.fk_id_project_activity, e.data)
		})
	}

	function onSubmitActivitieAntecessor(values) {
		action.execute(
			'put',
			Constants.APIRoot + '/api/project/activityantecessor',
			'project',
			values,
			'id_project'
		).then(e => {
			onView(values.id_project_activity, e.data);
		});
	}

	function onOpenForm(act) {
		setFormOpen({ activity: (viewOpen || formOpen).activity, project: (viewOpen || formOpen).project });
	}

	let options2 = Options({
		users,
		// activities_predecessor:(utils.TODO||[]),
		activities_predecessor: [
			...(TODO || []),
			...(DOING || []),
			...(STOPPED || []),
			...(VALIDATION || []),
		],
		companies,
	});

	let colors = [
		['#01C2B3', '#019FC2']
	];

	colors = colors[Math.floor(Math.random() * colors.length)];
	data = data.filter(e =>
		JSON.stringify({
			id: e.id,
			avatares: e.avatares,
			tags: (e.tags || []).map(e => e.label).join(' '),
			name: e.name,
			dt: e.dt,
			commentsTotal: e.commentsTotal,
			filesTotal: e.filesTotal,
			percent: e.percent,
		}).toLowerCase().indexOf(search) != -1,
	).sort((a, b) => {
		if (orderIndex === 1) {
			return new Date(([null, true].includes(orderPosition) ? a : b)[_orderIndex[orderIndex]]) - new Date(([null, true].includes(orderPosition) ? b : a)[_orderIndex[orderIndex]])
		} else {
			return ([null, true].includes(orderPosition) ? a : b)[_orderIndex[orderIndex]] - ([null, true].includes(orderPosition) ? b : a)[_orderIndex[orderIndex]]
		}
	});

	return (

		<div className='border-radius-10 KanbanCardColumn pb-2' style={{ '--kanbanColor': columnTheme }}>
			<AppBar
				title={title}
				titleProps={{ style: { color: 'white' } }}
				childrenContentProps={{
					style: { height: 0, alignSelf: 'flex-start' },
					//className:'d-flex align-items-center'
				}}
				contentProps={{
					className: 'pb-2'
				}}
			>
				<Button
					icon='fas fa-times'
					color='light'
					label={language.CLOSE}
					variant='circle'
					outlined
					onClick={onClose}
					style={{
						position: 'relative',
						top: -7,
						left: 6
					}}
				/>
			</AppBar>
			<hr className='my-0 py-0 mt-1' style={{ borderColor: 'white', opacity: .4 }} />
			<AppBar
				title={language.ACTIVITIES}
				titleProps={{ style: { color: 'white' } }}
				childrenContentProps={{
					style: { height: 0 },
					//className:'d-flex align-items-center'
				}}
			>
				<div className='qtd-number -mt-3'>
					{(data && data.length)}
				</div>
			</AppBar>
			<div className='d-flex align-items-center btn-group -mt-3 ml-3 mb-2'>
				<Button
					icon='fas fa-plus'
					label={language.ADD}
					outlined
					onClick={() => setFormOpen(true)}
					color='light'
					variant='normal'
					size={.6}
				/>
				<Button
					icon='fas fa-tags'
					label={language.TAG}
					outlined
					onClick={() => setTagOpen(true)}
					color='light'
					variant='normal'
					size={.6}
				/>
			</div>

			<hr className='my-0 py-0 mt-1' style={{ borderColor: 'white', opacity: .4 }} />
			<AppBar
				title='Sprints'
				titleProps={{ style: { color: 'white' } }}
				childrenContentProps={{
					style: { height: 0 },
					//className:'d-flex align-items-center'
				}}
			>
				<div className='qtd-number -mt-3'>
					{(sprints && sprints.length)}
				</div>
			</AppBar>
			<div className='d-flex align-items-center btn-group -mt-3 ml-3 mb-2'>
				<Button
					icon='fas fa-plus'
					label={language.ADD}
					outlined
					onClick={() => handleModal(project)}
					color='light'
					variant='normal'
					size={.6}
				/>
			</div>

			<hr className='my-0 py-0 mt-1' style={{ borderColor: 'white', opacity: .4 }} />
			<div className='content-search px-3 mt-2'>
				<CommonForm
					fields={[
						{
							startIcon: 'fas fa-search',
							type: 'text',
							name: 'search',
							placeholder: language.SEARCH
						}
					]}
					onChangeField={(f, v) => setSearch(v)}
				/>
			</div>
			<div style={{ height: '50vh' }} className='content-body'>

				{data.length ? (
					<AutoSizer style={{ height: `${data.length > 0 ? data.length * 17 : 20}vh`, maxHeight: '50vh', width: 'auto' }}>
						{({ height, width }) => (
							<FixedSizeList

								height={height}
								itemCount={data.length}
								itemSize={130}
								width={width}

								outerElementType={CustomScrollbarsVirtualList}

							>
								{({ index, style }) => (
									<div style={style} key={data[index].id} className='content-medium py-0 d-flex align-items-center justify-content-center'>
										<MyCard
											{...data[index]}
											project={project}
											key={data[index].id}
											onEdit={onEdit}
											onDelete={onDelete}
											onView={onView}
											setViewOpen={setViewOpen}
										/>
									</div>
								)}

							</FixedSizeList>
						)}

					</AutoSizer>
				) : (
					<h3
						style={{ color: 'white', fontWeight: 100, fontSize: 16, textAlign: 'center' }}
						className="mt-2"
					>
							{language.NOTHING_REGISTERED}
					</h3>
				)}

				<div style={{ backgroundColor: '#019FC2', height: '30vh' }}>
					{sprints.map(s => (
						<Card className='content-medium py-0 d-flex align-items-center justify-content-center' style={{ backgroundColor: 'white', height: 60, margin: 5, marginLeft: '3%', width: '94%' }}>
							<div style={{
								borderRadius: '10px',
								padding: '4px', marginBottom: '3px', fontFamily: 'Open Sans', fontSize: '14px',
								color: '#87868a'
							}} >

								<div className='content-info -mb-1 d-flex alig-items-center justify-content-between'>
									<span style={{ width: '30vh' }}>
										{s.name}

									</span>
									<div className='d-flex alig-items-center' >

										<Button
											icon='fas fa-edit'
											label={language.EDIT}
											outlined
											onClick={() => handleEdit(project, s)}
											color='blue'
											variant='circle'
											size={.6}
											style={{margin: 5}}
										/><Button
											icon='fas fa-trash'
											label={language.REMOVE}
											outlined
											onClick={() => handleDelete(s)}
											color='red'
											variant='circle'
											size={.6}
											style={{margin: 5}}

										/>
									</div>
								</div>

							</div>
						</Card>

					))}
				</div>

				{!!Object.keys(viewOpen).length && (
					<ViewActivity
						open={!!Object.keys(viewOpen).length}
						data={viewOpen.activity}
						onOpenForm={onOpenForm}
						onClose={() => setViewOpen({})}
						users={users}
						options={options2}
						project={viewOpen.project}
						action={action}
						APIEndpoints={APIEndpoints}
						subActivities={Store.subActivities}
						onSubmit={onSubmitComent}
						onSubmitActivitieAntecessor={onSubmitActivitieAntecessor}
						onDownloadFile={onDownloadFile}
						onUpdate={onUpdate}
						template={true}
					/>
				)}

			</div>
		</div>
	)
}, function (prev, next) {
	return _.isEqual([prev.data, prev.columnTheme], [next.data, next.columnTheme])
})