/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import { FixedSizeList } from 'react-window';
import { Tooltip } from 'react-tippy';
import { Tooltip as TooltipMaterial } from '@material-ui/core';
import { FormControlLabel, InputAdornment, Checkbox } from '@material-ui/core';

const replaceSpecialChars = (str) => {
  str = str.replace(/[àáâãäå]/, 'a');
  str = str.replace(/[eéèëê]/, 'e');
  str = str.replace(/[iíìïî]/, 'i');
  str = str.replace(/[oóòõöô]/, 'o');
  str = str.replace(/[uúùüû]/, 'u');

  str = str.replace(/[ç]/, 'c');

  // o resto

  return str.replace(/[^a-z0-9]/gi, '');
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
    padding: 10,
  },
  input: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  input2: {
    display: 'flex',
    paddingBottom: 9.5,
    paddingTop: 9.5,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${8 / 2}px ${8 / 4}px`,
  },

  noOptionsMessage: {
    padding: `${8}px ${8 * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    padding: 10,
  },
  paper: {
    position: 'absolute',
    zIndex: 30,
    top: 50,
    left: 0,
    right: 0,
    padding: 10,
  },
  divider: {
    height: 8 * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  var { input2, startIcon, endIcon, InputProps, ...other } =
    props.selectProps.textFieldProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        startAdornment: startIcon && (
          <InputAdornment position="start">
            {typeof startIcon == 'string' ? (
              <i
                className={classnames(startIcon, 'color-icon adormentIcon')}
              ></i>
            ) : (
              startIcon
            )}
          </InputAdornment>
        ),
        endAdornment: endIcon && (
          <InputAdornment position="start">
            {typeof endIcon == 'string' ? (
              <i className={classnames(endIcon, 'color-icon adormentIcon')}></i>
            ) : (
              endIcon
            )}
          </InputAdornment>
        ),
        inputProps: {
          className: !input2
            ? props.selectProps.classes.input
            : props.selectProps.classes.input2,
          inputRef: props.innerRef,
          children: props.children,

          ...props.innerProps,
          ...props.selectProps.textFieldProps.inputProps,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

/* function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
} */

function handleValue({ data, index, isAll, isSelected }) {
  if (isSelected) {
    data.setValue(
      data.getValue().filter((e) => e.value != data.options[index].value)
    );
  } else {
    data.setValue(data.getValue().concat(data.options[index]));
  }
}

function Option({ index, data, style }) {
  var isSelected = data
    .getValue()
    .map((e) => e.value)
    .includes(data.options[index].value);
  let isAll = data.getValue().length == data.options.length - 1;
  let activeCheckbox = false;

  return (
    <MenuItem
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        ...style,
        ...(index == 0
          ? { borderBottom: '1px solid rgb(108 117 125 / .3)', fontWeight: 500 }
          : {}),
      }}
      onClick={() => handleValue({ data, index, isAll, isSelected })}
    >
      <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            {data.options[index].label}
          </div>
        }
        position="left"
        theme="dark"
      >
        <span className="d-flex align-items-center">
          <i
            className={
              (activeCheckbox
                ? 'fas fa-check-square selected'
                : 'far fa-square') + ' pr-2 checkbox-fake'
            }
          />
          {data.options[index].label}
        </span>
      </Tooltip>
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Tooltip title={props.children} arrow style={{ maxWidth: '96%' }}>
      <Chip
        tabIndex={-1}
        label={<span className="ellipsis d-block">{props.children}</span>}
        className={classNames(
          'chip-select chip-multi',
          props.selectProps.classes.chip,
          {
            [props.selectProps.classes.chipFocused]: props.isFocused,
          }
        )}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    </Tooltip>
  );
}

/* function Menu(props) {
   console.log(props);
   return (
     <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
       {props.children}
     </Paper>
   );
 } */
function Menu(props) {
  let maxItemLength = (props.options || []).reduce((n, e) => {
    if(e.label)
    if (e.label.length > n) n = e.label.length;
    return n;
  }, 0);

  return props.options.length ? (
    <FixedSizeList
      height={props.options.length <= 6 ? props.options.length * 40 : 250}
      itemCount={props.options.length}
      itemSize={40}
      width={'100%'}
      itemData={props}
      style={{
        position: 'absolute',
        zIndex: 30,
        top: 50,
        left: 0,
        right: 0,
        minWidth: 11 * maxItemLength,
      }}
      outerElementType={({ children, ...other }) => (
        <Paper square {...props.innerProps} {...other}>
          {children}
        </Paper>
      )}
    >
      {Option}
    </FixedSizeList>
  ) : (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      <NoOptionsMessage
        innerProps={props.innerProps}
        selectProps={props.selectProps}
      />
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  state = {
    single: null,
    multi: null,
    shrink: false,
    valueInput: '',
  };

  handleChange = (name) => (value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    var {
      classes,
      theme,
      label,
      options,
      menuProps,
      textFieldProps,
      readOnly,
      value: _value = [],
      isMulti,
      sortOptions,
      ...other
    } = this.props;
    let {
      disabled,
      variant,
      startIcon,
      endIcon,
      className,
      InputLabelProps,
      ...textFieldPropsOther
    } = textFieldProps;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    let value = options.filter((e) =>_value &&  _value.includes(e.value));

    options = options.filter(
      (evt) =>
        replaceSpecialChars(evt.label.toLowerCase()).indexOf(
          replaceSpecialChars(this.state.valueInput).toLowerCase()
        ) != -1
    );

    let _this = this;
    return (
      <div className={classes.root}>
        <NoSsr>
          <div className={classes.divider} />
          <Select
            classes={classes}
            options={options}
            components={components}
            value={value}
            isDisabled={disabled || readOnly}
            placeholder="Select multiple countries"
            isMulti
            styles={selectStyles}
            textFieldProps={{
              variant,
              className: classnames(className, {
                selectField: variant != 'outlined',
              }),
              label: label,
              input2: variant == 'outlined',
              startIcon,
              endIcon,
              InputLabelProps: {
                shrink: !!startIcon || value.length || this.state.shrink,
                ...InputLabelProps,
              },
              onFocus: () => _this.setState({ shrink: true }),
              onBlur: () => _this.setState({ shrink: false }),
              ...textFieldPropsOther,
            }}
            isClearable={true}
            placeholder=""
            {...other}
            onInputChange={(valueInput) => {
              this.setState({ valueInput });
            }}
            styles={{ ...selectStyles, ...this.props.styles }}
          />
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
