import React, {memo} from 'react';
import classnames from 'classnames';
/* ----------------------------common------------------------------ */
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import MenuTooltip from '@common/MenuTooltip';
import Progress from '@common/Progress';
/* ----------------------------material------------------------------ */

import Avatares from '../Avatares';
import Store from '@data/Store';
import _ from 'loadsh';
const { language } = Store;

export default memo(({open, onClose, data=[], redirect})=>{
   return(
      <Dialog
         open={open}
         onClose={onClose}
         maxWidth='xl'
         bar={false}

			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
      >
         <CommonTable
            AppBarProps={{
               style:{
                  borderRadius: '10px 10px 0 0'
               }
            }}
            className='Home-View1-Activities'
            paginationTop={false}
            maxHeight={500}
            afterBar2={
               <Button
                  icon='fas fa-times'
                  variant='circle'
                  outlined
                  color='light'
                  label={language.CLOSE}
                  onClick={onClose}
               />
            }
            col={[
               { key: '', label: language.ACTIONS },
               { key: 'id', label: language.PROJECT_ID },
               {key:'name', label: language.PROJECT },
               {key:'activity', label: language.ACTIVITY},
               {key:'sponsors', label: language.RESPONSIBLES},
               {key:'evolution', label: language.EVOLUTION},
               {key:'status', label: language.STATE},
               {key:'dt_i', label: language.START_DATE},
               { key: 'dt_f', label: language.END_DATE },

            ]}
            data={data.map(e=>({
               actions:(
                  <i
                     className='fas fa-eye'
                     style={{color:'var(--primary)', fontSize:18, cursor:'pointer'}}
                     onClick={() => {
                        redirect('/dash/gp/'+e.id_project, {
                           id:e.id,
                           openView:true,
                           pj:e.pj
                        })
                     }}
                  />
               ),
               id:e.id_project,
               name:e.name,
               activity:e.id+' - '+e.activity,
               sponsors:(
                  <Avatares
                     data={e.sponsors}
                  />
               ),
               evolution:(

                  <div className='d-flex align-items-center content-progress' style={{width:75}}>
                     <Progress current={parseInt(e.evolution)} hiddenText className='w-100' style={{height:7}} background='var(--warning)'/>
                     <span className='ml-2'>{parseInt(e.evolution)}%</span>
                  </div>

               ),
               status:(
                  <div className='status'>
                     {e.status}
                  </div>
               ),
               dt_i:e.dt_i,
               dt_f:e.dt_f
            }))}
         />
      </Dialog>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
})
