import Store from '@data/Store';

const { language } = Store;

export function foreseen_vs_realized(y1, y2) {

	return {
		chart: {
			type: 'column',
			inverted: true,
			polar: true,
			background: 'transparent',

			height: 200

		},
		title: null,
		tooltip: {
			outside: true
		},
		pane: {
			size: '85%',
			innerSize: '20%',
			endAngle: 270
		},
		xAxis: {
			tickInterval: 1,
			labels: {
				align: 'right',
				useHTML: true,
				allowOverlap: true,
				step: 1,
				y: 3,
				style: {
					fontSize: '11px',
					color: '#A3A3A3'
				}
			},
			lineWidth: 0,
			categories: [language.FORESEEN, language.CONSUMED]
		},
		yAxis: {
			crosshair: {
				enabled: true,
				color: '#333'
			},
			labels: {
				style: {
					fontSize: 8
				}
			},
			lineWidth: 0,
			tickInterval: 25,
			reversedStacks: false,
			endOnTick: true,
			showLastLabel: true
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0.15
			}
		},
		credits: { enabled: false },
		legend: {
			enabled: false

		},
		series: [{
			name: 'Valor',
			color: 'var(--primary)',
			data: [{ y: y1, color: 'var(--warning)' }, { y: y2, color: 'var(--primary)' }]
		}]
	}
}
