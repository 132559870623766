import Store from '@data/Store';
const { language } = Store;

export function CallMonth({ categories, data = {} }) {
	return {
		chart: {
			type: 'areaspline',
			height: 350
		},
		title: null,

		xAxis: {
			categories,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			}

		},
		yAxis: {
			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			}
		},
		tooltip: {
			shared: true,
			valueSuffix: ' units'
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			areaspline: {
				fillOpacity: 0.4
			}
		},
		legend: {
			itemStyle: {
				fontSize: 10,
				color: 'var(--primary)'
			}
		},
		series: [
			{
				name: language.TICKET_TYPES.SOLICITATION,
				color: '#02CBF0',
				data: data.solicitations ? data.solicitations : []
			},
			{
				name: language.TICKET_TYPES.INCIDENT,
				color: '#f0b002',
				data: data.incidents ? data.incidents : []
			},
			{
				name: language.TICKET_TYPES.PROBLEM,
				color: '#f66868',
				data: data.problems ? data.problems : []
			}
		]
	}
}
export function NpsItens({ categories, data, currentMonth }) {
	return {
		chart: {
			type: 'line',
			height: 200
		},
		title: null,

		subtitle: null,

		yAxis: {
			title: {
				text: language.HOURS,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},

		xAxis: {
			categories,
			title: {
				text: `${language.PERIOD} (${currentMonth})`,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
		},

		legend: {
			enabled: false
		},

		plotOptions: {
			series: {
				label: {
					connectorAllowed: false
				}
			}
		},
		credits: { enabled: false },
		series: data,
	}
}
export function npsVelocimeter(value) {
	return {
		chart: {
			type: 'gauge',
			plotBackgroundColor: null,
			plotBackgroundImage: null,
			plotBorderWidth: 0,
			plotShadow: false,
			backgroundColor: 'transparent',
			height: 150,
			margin: 0
		},
		credits: {
			enabled: false
		},
		title: {
			text: ''
		},

		pane: {
			size: 150,
			startAngle: -90,
			endAngle: 90,
			background: [
				{
					borderWidth: 0,
					backgroundColor: 'transparent'
				},
				{
					borderWidth: 0,
					backgroundColor: 'transparent'
				}
			]
		},

		// the value axis
		yAxis: {
			min: 0,
			max: 100,

			minorTickInterval: 'auto',
			minorTickWidth: 1,
			minorTickLength: 10,
			minorTickPosition: 'inside',
			minorTickColor: 'white',

			tickPixelInterval: 30,
			tickWidth: 2,
			tickPosition: 'inside',
			tickLength: 10,
			tickColor: 'white',
			labels: {
				step: 2,
				rotation: 'auto'
			},
			title: {
				text: 'NPS',
				y: 10
			},
			plotBands: [
				{
					from: 0,
					to: 60,
					color: 'var(--danger)'
				},
				{
					from: 61,
					to: 80,
					color: 'var(--warning)'
				},
				{
					from: 81,
					to: 100,
					color: 'var(--success)'
				}
			]
		},

		series: [
			{
				name: 'nps ',
				data: [Math.abs(parseInt(value))],
				tooltip: {
					valueSuffix: '%'
				}
			}
		]
	};
}
export function Categories({ data = [] }) {

	return {
		chart: {
			type: 'column',
			inverted: true,
			polar: true,
			background: 'transparent',

			height: 300

		},
		title: null,
		tooltip: {
			outside: true
		},
		pane: {
			size: '85%',
			innerSize: '20%',
			endAngle: 270
		},
		xAxis: {
			tickInterval: 1,
			labels: {
				align: 'right',
				useHTML: true,
				allowOverlap: true,
				step: 1,
				y: 3,
				style: {
					fontSize: '11px',
					color: '#A3A3A3'
				}
			},
			lineWidth: 0,
			categories: data.map(e => e.label)
		},
		yAxis: {
			crosshair: {
				enabled: true,
				color: '#333'
			},
			labels: {
				style: {
					fontSize: 8
				}
			},
			lineWidth: 0,
			tickInterval: 25,
			reversedStacks: false,
			endOnTick: true,
			showLastLabel: true
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0.15
			}
		},
		credits: { enabled: false },
		legend: {
			// enabled: false
			itemStyle: {
				fontSize: 10,
				color: '#A3A3A3'
			}
		},
		series: [{
			name: language.TICKET_TYPES.SOLICITATION,
			color: 'var(--primary)',
			data: data.map(e => e.solicitation)
		}, {
			name: language.TICKET_TYPES.INCIDENT,
			color: 'var(--warning)',
			data: data.map(e => e.incident)
		}, {
			name: language.TICKET_TYPES.PROBLEM,
			color: 'var(--danger)',
			data: data.map(e => e.problem)
		}]
	}
}
export function SubCategories(data) {
	return {
		chart: {
			type: 'column',
			height: 300

		},
		title: null,

		xAxis: {
			type: 'category',
			crosshair: true,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		yAxis: {
			min: 0,
			title: null,
			labels: {
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>{point.y} </b></td></tr>',
			footerFormat: '</table>',
			shared: true,
			useHTML: true
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0
			},
			series: {
				borderRadius: 3,

				// borderWidth: 0,
				borderColor: 'red',
				dataLabels: {
					enabled: true,
					format: '{point.y:.0f}',
					color: 'var(--primary)',
					style: {
						fontSize: 8,
						color: 'var(--primary)'
					}
				},
				point: {
					events: {
						click:
							(e) => {
								let id = 0;

							}
					}
				}

			}
		},
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		series: [{
			name: '',
			data: data
		}]
	}
}
export function TelemetryTicket(data, months) {

	return {
		chart: {
			type: 'column',
			height: 300
		},
		title: null,
		xAxis: {
			categories: months,
			crosshair: true,
			labels: {
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				}
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: language.VALUES,
				style: {
					fontSize: 10,
					color: '#A3A3A3'
				}
			},
			stackLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					color: 'var(--primary)',
				}
			},
			labels: {
				style: {
					fontSize: 8,
					color: 'var(--primary)'
				}
			}
		},

		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			pointFormat: '{series.name}: {point.y} <br/>Total: {point.stackTotal} '
		},
		credits: { enabled: false },
		plotOptions: {
			column: {


				/** column: {
												pointPadding: 0.2,
												borderWidth: 0
										}, */

				stacking: 'normal',
				dataLabels: {
					enabled: true,
					format: '{point.y:,.0f}',
					style: {
						textOutline: 0,
						color: 'white',
						fontSize: 10
					},
				}
			},
			series: {
				borderRadius: 5,

				point: {
					events: {
						click: (e) => {

						}
					}
				},

				marker: {
					lineColor: '#333'
				}
			},
		},
		legend: {
			enabled: false
		},
		series: data
	}
}
