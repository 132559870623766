import React, { Component } from 'react';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import {Button} from '@material-ui/core';
import {TextField} from '@material-ui/core'; //inputs
import {Dialog, DialogContent} from '@material-ui/core'; //digalog
class ModalRename extends Component{

	constructor(props){
		super(props);
		this.state = {
			...props.state,
			ModalRenameData:{cord:{}, el:{name:''}},
			val:''
		}
	}

	inputChange = (ev)=>{
		
		this.setState({
			val:ev.target.value
		});
	}
	save = ()=>{
        var st = this.state.tbody
        var cord = this.state.ModalRenameData.cord;
        var val = this.state.val;
       
        st[cord.tbody].item[cord.tr][cord.td].name = val;
       
        this.props.toggleModalRename(false);
    }
	toggleModalRename = ()=>{
		this.props.toggleModalRename(false)
	}
	componentWillReceiveProps(nextProps){
		
		if(nextProps.visible){
			this.setState({
				...nextProps.state,
				val:nextProps.state.ModalRenameData.el.name
			});
		}
	}

	render(){
		return (
	       
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.props.visible}>
	            <DialogContent style={{width:'300px'}}>
	                <div className='Modal-content'>
	                	<div className="form-group">
		                  
		                  <TextField className="w-100 mb-3" id="rename" label="Renomear" onChange={this.inputChange} value={this.state.val}/>
		                   <div className="w-100 d-flex justify-content-end">
						    	<Button variant="contained" onClick={this.toggleModalRename} color="secondary" className="mr-3">Cancelar</Button>
						    	<Button variant="contained" onClick={this.save} color="primary">Salvar</Button>
		                	</div>
		                </div>
	                </div>
	            </DialogContent>
            </Dialog>
	       
	    );
	}
}
export default ModalRename;