const ContainerChart = {

  container0(e){
    let {title, subTitle, data, size} = e;
    return {
      chart: {
        type: 'pie'
      },
      credits:{
        enabled:false
      },
      title: {
          text: title||'',
          style:{
            color: '#616161',
            fontFamily: 'sans-serif'
          }  
      },
      subtitle: {
        text: subTitle||''
      },
      
      plotOptions: {
          series: {
              dataLabels: {
                  enabled: true,
                  format: '{point.name}: {point.y:.1f}%'
              }
          },
          pie: {
              size: size||257
          }
      },

      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },

      series: [
          {
              name: "",
              colorByPoint: true,
              data:data||[]
          }
      ]
    }
  },
}
export default ContainerChart;