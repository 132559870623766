import React, { useState, useEffect } from 'react';
import Dialog from '@common/Dialog';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import { Avatar, Grid } from '@material-ui/core';
import { flexBasis } from '@common/Func';
import classnames from 'classnames';
import './DialogForm.css';
import moment from 'moment'
import Tag from '@common/Tag';

export default function ({
  open,
  onClose,
  project,
  users,
  saveActivity,
  activity,
  toggleAlert
}) {

  let [tag, SetTags] = useState([]);
  
  let [color, SetColor] = useState([]);
  let [name, Setname] = useState('');
  let [tagpj, SetTagpj] = useState( project.tagpj||[]);
  let reponsable = [];
  let tagActiviry = [];
  let usersActivities = [];
  let userResp = [];
  if (activity) {
    tagActiviry =
      activity.tagac &&
      activity.tagac.map(u => {
        return u.id_project_tag;
      });

      if(activity.hours <=0){
        activity.hours="0"
      }
  if(activity.minutes <=0){
    activity.minutes="0"
  }

    if (activity.activityprd != null) {
      // activity.activityprd =   
      let acprd = [];
      activity.activityprd.map(g => {
        if (typeof g === 'object') {
          acprd.push(g.id_project_activity)
        }

      })

      activity.activity_prd = acprd
    }



    userResp = activity.usersid == null ? [] : activity.usersid
    if (tagActiviry == null) {
      tagActiviry = [];

    }
  }

  users
    .filter(u => project.users.includes(u.id_user))
    .map(p => {
      reponsable.push({ value: p.id_user, label: p.name });
    });

  const onClosedModal = () => {
    SetResp([]);
    SetTags([]);
    SetTags([]);
    SetTagpj([]);
     
    onClose();
  };
 
  useEffect(() => {

    SetTags(tagActiviry)
    SetResp(userResp)
    
  }, [activity])



  const saveActivity_ = (e, tag, resp) => {
    let tnew = [];
 
   if(resp.length == 0){
       
    toggleAlert(true, 'Informe o Reponsável!', 'error')
       
    }else{

    let dtafinal = moment(e.dt_end).format('YYMMDD')
    let dtainicial = moment(e.dt_start).format('YYMMDD')
    let dtpjinicial = moment(project.start_date).format('YYMMDD')
    let dtpjfinal = moment(project.end_date).format('YYMMDD');


    if (tag.length == 0/* || resp.length == 0 || e.dt_end == undefined
      || e.name == undefined || e.dt_start == undefined*/) {
      toggleAlert(true, 'Selecione uma tag', 'error')

    } else
      if (dtafinal < dtainicial) {
        toggleAlert(true, 'Período da atividade inválido', 'error')
      } else if (dtainicial < dtpjinicial || dtainicial > dtpjfinal) {

        toggleAlert(true, 'Ativade fora do período do projeto ' +
          moment(project.start_date).format('DD-MM-YY') + ' á ' +
          moment(project.end_date).format('DD-MM-YY')
          , 'error')

      } else {
 
        tagpj &&
          tagpj.map(h => {
            if (h.fake) {
              if (tag.includes(h.id_project_tag)) {
                tnew.push(h);
                tag = tag.filter(function (element) {
                  return element !== h.id_project_tag;
                });
              }
            }
          });
        e.tag = tag;
        e.tagnew = tnew;
        e.user_cad = resp;
        e.fk_id_project = project.id_project;

        saveActivity(e);
        SetTagpj([]);
        SetTags([]);
        onClosedModal();
      }
    }
  };

  let [resp, SetResp] = useState(usersActivities);

  let Activities = project.statusproject.TODO.filter(e=>e.id_project_activity!=(activity||{}).id_project_activity).map(p =>
    ({ value: p.id_project_activity, label: "#" + p.id_project_activity + "-" + p.name }))

  users
    .filter(u => project.users.includes(u.id_user))
    .map(p => {
      reponsable.push({ value: p.id_user, label: p.name });
    });
 const names=(e)=>{
   try{
 return  users.filter(v=>v.id_user ==e).map(v=>v.name.substring(0,1))
   }catch(e){
     return "";
   }
 }

  //console.log('projeto', project)
  return (
    <Dialog
      title="REGISTRAR ATIVIDADE"
      open={open}
      onClose={onClosedModal}
      PaperProps={{
        id: 'DialogFormActivityProject'
      }}
      contentProps={{
        className: 'pt-4'
      }}
    >
      <CommonForm
        sensitive
        fields={[
          {
            col: 6,
            name: 'name',
            type: 'text',
            label: 'Nome da atividade',
            required: true
          },
          {
            col: 3,
            name: 'hours',
            type: 'number',
            label: 'Horas',
            required: true 
            
          },
          {
            col: 3,
            name: 'minutes',
            type: 'number',
            label: 'Minutos',
            required: true,
           
          },
          {
            col: 12,
            name: 'description',
            type: 'textEditor',
            label: 'Descrição',
            rows: 3,
            required: true
          },
          {
            col: 6,
            name: 'responsible',
            type: 'select',
            label: 'Adicionar Responsável',
            options: reponsable
             
          },
          {
            col: 6,
            type: 'element',
            content: (
              <div
                id="resp"
                className="d-flex align-items-center content-avatares"
              >
                {resp.map((e, i) => (
                  <div style={{ position: 'relative', width: 30, height: 30 }} className={classnames({ 'ml-2': i != 0 })}>
                    <i
                      className='fas fa-times-circle'
                      style={{
                        color: 'var(--danger)',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        position: 'absolute',
                        zIndex: 2,
                        right: -5,
                        top: -5,
                        cursor: 'pointer'
                      }}
                      onClick={() => SetResp(
                        resp.filter(function (element) {
                          return element !== e;
                        })
                      )}
                    />
                    <Avatar
                      style={{ backgroundColor: 'orange' }}
                      className={classnames('avatar')}
                    >
                    {names(e)}
                     

                    </Avatar>
                  </div>
                ))}
              </div>
            )
          },
          {
            col: 6,
            name: 'dt_start',
            type: 'date',
            label: 'Período de execução - Data inicial',
            required: true
          },
          {
            col: 6,
            name: 'dt_end',
            type: 'date',
            label: 'Período de execução - Data final',
            required: true
          },

          {
            col: 6,
            type: 'element',
            content: (v, handleValue) => (
              <div className="content-tags mt-2 pt-1">
                <CommonForm
                  fields={[
                    {
                      name: 'tag',
                      type: 'text',
                      label: 'Criar Tag',
                    }
                  ]}
                  onChangeField={(f, v) => {
                    Setname(v);
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  className="content-colors-tags mb-2 -mt-2"
                >
                  {[
                    '#9E24F0',
                    '#F00D82',
                    '#F05E02',
                    '#F0B002',
                    '#92D050',
                    '#02F0DE',
                    '#02CBF0',
                    '#00008B',
                    '#708090',
                    '#2F4F4F',
                    '#8B4513',
                    '#8B0000',
                    '#D8BFD8',
                    '#4682B4'].map((e, i) => (
                    <Grid
                      key={i}
                      item
                      xs={1.71428571}
                      style={{ ...flexBasis(1.71428571) }}
                    >
                      <div
                        onClick={() => SetColor(e)}
                        className="item"
                        style={{ backgroundColor: e, cursor: 'pointer' }}
                      >
                        {color.includes(e) && (
                          <i
                            style={{ color: 'white', marginLeft: '3px' }}
                            class="fas fa-check"
                          ></i>
                        )}
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  icon="fas fa-tags"
                  label="Criar"
                  variant="normal"
                  color="success"
                  fluid
                  onClick={() =>
                    tagpj.filter(g => g.name == name).length == 0 ?
                      SetTagpj([
                        ...tagpj,
                        {
                          id_project_tag: Math.floor(Math.random() * 100),
                          color: color,
                          name: name,
                          fake: true
                        }
                      ]) :
                      toggleAlert(true, 'Tag já cadastrada!', 'error')
                  }
                />

                <div className="tags" style={{ maxHeight: '120px', overflowY: 'auto' }}>
                  {tagpj &&
                    tagpj.map((e, i) => (
                      <div style={{ minWidth: '150px' }}>
                        <Tag
                          label={e.name}
                          color={e.color}
                          onClick={() => !tag.includes(e.id_project_tag) && SetTags([...tag, e.id_project_tag])}
                          onDelete={tag.includes(e.id_project_tag) ?
                            () => SetTags(
                              tag.filter(function (element) {
                                return element !== e.id_project_tag;
                              })
                            ) : undefined
                          }
                        />
                      </div>
                    ))}
                </div>
              </div>
            )
          }, {
            col: 6,
            name: 'activity_prd',
            type: 'listCheckbox',
            label: 'Atividades Antecessoras',
            options: Activities
            
          }
        ]}

        //Activities
        onSubmit={e => saveActivity_(e, tag, resp)}
        onChangeField={(f, v) => {
          if (f && f['name'] && f.name === 'responsible') {
            if (!resp.includes(v))
              SetResp([...resp, v]);
          }
        }}
        values={activity}
      />
    </Dialog>
  );
}


/**"activityprd":[{"name" : "teste", "id_project_activity" :
 * 196, "percent" : "0"}]},{"id_project_activity":159,
 * "fk_id_project_milestone":28,"fk_id_project_stage":10,
 * "fk_id_user":3,"name":"Migração de Storage","description":"
 * descrição Migração de Storage","time_estimate":null,"time_spent":null
 * ,"deleted":false,"user_cad":"3","dt_cad":"2020-04-1 */