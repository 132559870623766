import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import newLogoSensrWhite from '@images/newLogoSensrWhite.png';
import AppBar from '@material-ui/core/AppBar';
import { Chip, Avatar } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CommonPage from '@common/CommonPage';
import wwwIcon from '@images/wwwIcon.png';
import './style.css';

const theme = createTheme({
	palette: {
		primary: { main: '#009dc0' }
	},
});

@observer
export default class HeaderPrint extends CommonPage {
	componentWillMount() {
		this.action.execute('get', this.APIEndpoints.COMPANY + '/getlogo', 'logo');
	}
	render() {
		let { logo } = this.store;

		return (
			<MuiThemeProvider theme={theme}>
				<div className='HeaderPrint'>
					<AppBar position="static">
						<div className='d-flex align-items-center justify-content-between'>
							<div className='print-logo'>
								<img src={newLogoSensrWhite} alt={newLogoSensrWhite} />
							</div>

							<Typography variant="h4" className='font' style={{ color: 'white', paddingTop: 0, fontWeight: 100, marginLeft: 10 }}>Relatórios</Typography>

							<div className='print-logo'>
								{!!logo &&
									<img src={'data:image/png;base64,' + logo} alt='Company Logo' />
								}
							</div>
						</div>
					</AppBar>
				</div>
			</MuiThemeProvider>
		)
	}
}