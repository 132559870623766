import CommonForm from '../../common/CommonForm2';

export default function ChangeCabForm({
  values,
  store,
  action,
  APIEndpoints,
  onSuccess,
}) {
  let { toggleAlert, language } = store;

  function onSubmit(values) {
    if (values.cc_weight < 0) {
      toggleAlert(true, language.ERROR_CODE['E020'], 'error');
      return;
    }

    const method = values?.cc_id ? 'put' : 'post';
    const url = values?.cc_id
      ? APIEndpoints.CHANGE_CAB + '/' + values?.cc_id
      : APIEndpoints.CHANGE_CAB;

    store.spinner = true;
    action
      .execute(method, url, '', values)
      .then(() => {
        store.spinner = false;
        toggleAlert(
          true,
          method === 'post'
            ? language.ALERT_INSERT_SUCCESS
            : language.ALERT_UPDATE_SUCCESS,
          'success',
        );
        onSuccess();
      })
      .catch(() => {
        store.spinner = false;
        toggleAlert(true, language.ERROR_REQUEST, 'error');
      });
  }

  return (
    <div className="dialogForm">
      <CommonForm
        sensitive
        spacing={8}
        values={values}
        fields={[
          {
            col: 3,
            name: 'type',
            label: language.TYPE,
            type: 'select',
            options: [
              { value: 'custom', label: 'custom' },
              { value: 'global', label: 'global' },
            ],
            required: true,
          },
          {
            col: 6,
            name: 'cc_weight',
            label: language.WEIGHT,
            type: 'number',
            required: true,
          },
          {
            col: 6,
            type: 'autocomplete',
            textlabel: language.APPROVAL_GROUP,
            name: 'cc_group_id',
            method: 'POST',
            route: `${APIEndpoints.GROUP}/all?type=3&`,
            routeAll: `${APIEndpoints.GROUP}/all?type=3&`,
            fieldquery: 'search',
            filter: { type_search: 3 },
            filterAll: { type_search: 3 },
            textinit: values.cc_group_label || '',
            idinit: values.cc_group_id || 0,
            fieldvaluedb: 'id_group_users',
            fieldlabeldb: 'name',
            methodGetAll: 'POST',
            required: true,
          },
        ]}
        onSubmit={onSubmit}
      />
    </div>
  );
}
