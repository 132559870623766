import Store from '@data/Store';

// ----------------------------------------------------------------------

const { language } = Store;

// ----------------------------------------------------------------------

export const columns = [
  {
    key: 'id_coin',
    label: language.ID,
    style: { textAlign: 'left' },
  },
  {
    key: 'code',
    label: language.CODE,
    style: { textAlign: 'left' },
  },
  {
    key: 'name',
    label: language.COIN,
    style: { textAlign: 'left' },
  },
  {
    key: 'decimal_places',
    label: language.DECIMAL_PLACES,
    style: { textAlign: 'left' },
  },
  {
    key: 'action',
    label: language.ACTIONS,
    style: { textAlign: 'right' },
  },
];
