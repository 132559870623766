import Store from '@data/Store';

const { language } = Store;

export function SlaAnalysisTrimestral(v1 = 0, v2 = 0, v3 = 0, month) {
	return {
		chart: {
			type: 'column',
			inverted: true,
			polar: true,
			height: 200
		},
		title: {
			text: month,
			x: -50,
			y: 40,
			style: {
				color: '#A3A3A3',
				fontSize: 14,
				fontWeight: 'bold',
				textTransform: 'uppercase'
			}
		},
		tooltip: {
			outside: true
		},
		pane: {
			size: '80%',
			innerSize: '50%',
			endAngle: 270
		},
		xAxis: {
			tickInterval: 1,
			labels: {
				align: 'right',
				useHTML: true,
				allowOverlap: true,
				step: 1,
				y: 3,
				style: {
					color: '#BABABA',
					fontSize: '8px'
				}
			},
			lineWidth: 0,
			categories: [
				language.FORESEEN,
				language.REALIZED,
				language.CONSUMED
			]
		},
		yAxis: {
			min: 0,
			labels: {
				formatter() {
					return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2, style: 'currency', currency: 'BRL' }).format(this.value);
				},
				style: {
					color: '#BABABA',
					fontSize: '8px'
				}
			},
			crosshair: {
				enabled: true,
				color: '#333'
			},
			lineWidth: 0,
			tickInterval: 5,
			reversedStacks: false,
			endOnTick: true,
			showLastLabel: true
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0.15
			}
		},
		credits: { enabled: false },
		legend: { enabled: false },
		series: [{
			name: 'Valor',
			data: [
				{
					name: language.FORESEEN,
					y: v1,
					color: 'var(--primary)',
				},
				{
					name: language.REALIZED,
					y: v2,
					color: 'var(--warning)'
				},
				{
					name: language.CONSUMED,
					y: v3,
					color: 'var(--danger)'
				}
			]
		}]
	}
}
export function NewContracts_({ categories, consumed, realized, foreseen }) {
	return {
		chart: {
			type: 'area',
			zoomType: 'xy'
		},

		title: null,
		subtitle: null,
		xAxis: [{
			categories,
			crosshair: true,
			labels: {
				style: {
					color: '#BABABA',
					fontSize: 8
				}
			}
		}],
		yAxis: [{ // Primary yAxis
			labels: {
				formatter() {
					return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
				},
				style: {
					color: '#FFA000'
				}
			},
			title: {
				text: language.FORESEEN,
				style: {
					color: '#FFA000'
				}
			},
			opposite: true

		}, { // Secondary yAxis
			gridLineWidth: 0,
			title: {
				text: language.CONSUMED,
				style: {
					color: '#629DAA'
				}
			},
			labels: {
				formatter() {
					return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
				},
				style: {
					color: '#629DAA'
				}
			}

		}, { // Tertiary yAxis
			gridLineWidth: 0,
			title: {
				text: language.REALIZED,
				style: {
					color: 'black'
				}
			},
			labels: {
				formatter() {
					return this.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
				},
				style: {
					color: 'black'
				}
			},
			opposite: true
		}],
		tooltip: {
			shared: true
		},
		credits: { enabled: false },

		series: [{
			name: language.CONSUMED,
			color: '#629DAA',
			type: 'column',
			yAxis: 1,
			data: consumed,
			tooltip: {
				valuePrefix: 'R$ '
			}

		}, {
			name: language.REALIZED,
			type: 'spline',
			color: 'black',
			yAxis: 2,
			data: realized,
			marker: {
				enabled: false
			},
			dashStyle: 'shortdot',
			tooltip: {
				valuePrefix: 'R$ '
			}

		}, {
			name: language.FORESEEN,
			type: 'spline',
			color: '#FFA000',
			data: foreseen,
			tooltip: {
				valuePrefix: 'R$ '
			}
		}],
	}
}


export function NewContracts({ categories, consumed, realized, foreseen }) {
	return {
		chart: {
			type: 'area',
			// height: 275
		},
		credits: {
			enabled: false
		},
		accessibility: {
			description: ''
		},
		title: {
			text: ''
		},
		subtitle: {
			text: ''
		},
		xAxis: {
			categories,
			allowDecimals: false,
			title: {
				text: language.MONTHS,
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			}
		},
		yAxis: {
			title: {
				text: language.VALUES,
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			labels: {
				formatter: function () {
					return this.value;
				},
				style: {
					color: 'var(--primary)',
					fontSize: 8
				}
			},
			tickWidth: 1
		},
		tooltip: {
			// pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			area: {
				pointStart: 0,
				fillOpacity: 0.5,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			}
		},
		legend: {
			// enabled: false
			itemStyle: {
				fontSize: 10,
				color: '#A3A3A3'
			}
		},
		series: [{
			name: language.CONSUMED,
			color: '#33ABA0',
			data: consumed,
		},
		{
			name: language.REALIZED,
			color: '#8b3594',
			data: realized,
		},
		{
			name: language.FORESEEN,
			color: '#F0B002',
			type: 'spline',
			data: foreseen
		}
	]};
}







/** */
