import React, { memo } from 'react';
import './index.css';

import View1 from './View1';
import View2 from './View2';

export default function Block_1({
  page,
  tickets,
  projects,
  gmuds,
  os,
  users,
  execute,
  APIRoot,
  year,
  priorities,
  requests,
  redirect,
  action,
  risks,
}) {
  return (
    <div id="Block_1" className="mb-3">
      {page == 3 ? (
        <View2
          risks={risks}
          action={action}
          users={users}
          redirect={redirect}
          requests={requests}
        />
      ) : (
        <View1
          priorities={priorities}
          year={year}
          execute={execute}
          requests={requests}
          APIRoot={APIRoot}
          tickets={tickets}
          projects={projects}
          gmuds={gmuds}
          os={os}
        />
      )}
    </div>
  );
}
