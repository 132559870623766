import React from 'react';
/*------------------------common----------------------------------*/
import AppBar2 from '@common/AppBar2';
 
/*------------------------material-ui----------------------------------*/
import {Card, Grid} from '@material-ui/core';
/*-------------------------------------------------------------------*/
import ProgressSla from './ProgressSla';

const Indicator = ({label, value, i, ticket, openModalDetail, opentc, opent, opentr, time, status})=>{
return(
	<Grid 
		item xs={4} 
		className='indicator-item'
		style={{cursor: 'pointer'}}
		onClick={() => openModalDetail('','','', status, time)
		}
	>
		<div className='content-min'>
			<span>{label}</span>
			<span>
                 {value}
            
         </span>
		</div>
	</Grid>
)}
export default function({
	total,
	data,
	current,
	label,
	openModalDetail,
	ticket
}){
	return(
		<Card className='CardOpenTicket h-100'>
		  <AppBar2 title={label} childrenContentProps={{style:{position:'relative'}}}>
		  	<div className='total'>{total}</div>
		  </AppBar2>
		  <div className='content-medium pt-0'>
		  	<Grid container spacing={2} className='content-indicator'>
				  {data.map((e, i)=><Indicator 
					{...e}
					i={i} 
					ticket={ticket} 
					openModalDetail={openModalDetail}
				/>)}
		  	</Grid>
		  	<ProgressSla current={current} isClosed={true}/>
		  </div>
		</Card>
	)
}