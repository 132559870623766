import React from 'react';
import classnames from 'classnames';
import './styles.css';

export const Title = props => <h5 zoom={props.size||1} {...props} style={{zoom:(props.size||1), ...props.style}} className={classnames('AppBar2-title', props.className)}>{props.children}</h5>
export const SubTitle = props => <h6 zoom={props.size||1} {...props} style={{zoom:(props.size||1), ...props.style}} className={classnames('AppBar2-sub-title', props.className)}>{props.children}</h6>

export default function(props){
	let {title, subTitle, contentTitle={}, titleSize, titleProps={}, subTitleProps={}, subTitleSize, beforeTitle, afterTitle, titleContentProps={}, childrenContentProps={}, contentProps={}, dividerProps={}} = props;
	 
	return(
		<div {...contentProps} className={classnames('AppBar2 content d-flex align-items-center justify-content-between content-AppBar2', contentProps.className)}>
			{(title||subTitle) &&
				<div {...titleContentProps} className={classnames('d-flex align-items-center', titleContentProps.className)}>
					{beforeTitle}
					<div {...contentTitle}>
						<Title size={titleSize}  {...titleProps}>{title}</Title>
						{subTitle &&
							<SubTitle size={subTitleSize} {...subTitleProps}>{subTitle}</SubTitle>
						}
					</div>
					{afterTitle}
				</div>
			}
			<div {...childrenContentProps} className={classnames('children', childrenContentProps.className)}>
				{props.children}
			</div>			
		</div>
	)
}