import React, { memo } from 'react';
import AppBar from '@common/AppBar2';
import PieChart from '@common/PieChart';
import { Card } from '@material-ui/core';
import Progress from '@common/Progress';
import Store from '@data/Store';

const { language } = Store;

export default memo(({
	activitiesTotal,
	completedActivities,
	home
}) => {

	return (
		home == true ?
			<div style={{ height: '10px' }} className='d-flex align-items-center justify-content-between'>
				<h5 style={{ color: 'white' }}>({completedActivities} / {activitiesTotal})</h5>
				<div style={{ width: '25%', marginBottom: '20%' }}>
					<PieChart
						percent={parseInt((completedActivities / activitiesTotal) * 100)}
						color='orange'
					/>
				</div>
			</div>

			:
			<Card className='h-100'>
				<AppBar
					title={language.TOTAL_ACTIVITIES_COMPLETED}
					subTitle={`(${completedActivities} / ${activitiesTotal})`}
					subTitleProps={{
						style: {
							fontSize: 20,
							fontWeight: 'bold'
						}
					}}
				/>
				<div className='content'>
					<div className='content-pie-chart'>
						<PieChart
							percent={parseInt((completedActivities / activitiesTotal) * 100)}
							color='orange'
						/>
					</div>
				</div>


			</Card>
	)
})