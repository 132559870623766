import React, {memo} from 'react';
import Store from '@data/Store';
import {observer} from 'mobx-react-lite';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
// ------------------------material-ui------------------------------------
import {Card, Grid} from '@material-ui/core';

import { ItemsWaitingApprovals } from '../../Graphs';

/* ---------------format-----------------------

let data = {
      avaliationPendent:[5, 3, 4],
      aprovationPendent:[2, 2, 3]
   }
   
*/
export default memo(observer(({ page, avaliationPendent = [], aprovationPendent = [] })=>{
   
   let { type } = Store.theme;
   let { language } = Store;
   return(
      <Card className='h-100'>
         <AppBar title={language.ITEMS_WAITING_FOR_APPROVALS_AND_EVALUATIONS} />
         <div className='content-medium pt-0'>
            <ReactHighcharts 
               config={ItemsWaitingApprovals({
                  data:[
                     {
                        name: language.PENDING_APPROVALS,
                        color:type=='grey'?'var(--default)':'#92D050',
                        data: aprovationPendent
                     }
                  ],
                  categories: [language.DEMANDS, language.RISKSS]
               })}
            />
         </div>
      </Card>
   )
}, function(prev, next){
   let omit = obj => _.omitBy(obj, val => typeof val=='function');
   return _.isEqual(omit(prev), omit(next));
}))