import React, { useState, useReducer, useEffect, Fragment } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import { Avatar, Grid } from '@material-ui/core';
import Button from '@common/Button';

import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import classnames from 'classnames';
import { Tooltip } from 'react-tippy';
import AttachFile from '@common/AttachFile';
import moment from 'moment';
import { ActivityGroup } from './CComplete';
import { toBase64 } from '@common/Func';
// ------------------------------icons-----------------------------------------
import SlowIcon from '@images/svg-icon/SlowIcon';
import HightIcon from '@images/svg-icon/HightIcon';
import CriticIcon from '@images/svg-icon/CriticIcon';
// ---------------------controla o status dos botões abaixo----------------------------------
function status(selected) {
  return {
    fill: selected ? 'white' : 'var(--primary)',
    color: selected ? 'warning' : 'primary',
    active: !selected,
  };
}

function btn(i) {
  return [
    {
      icon: (e) => (
        <SlowIcon style={{ fill: status(e.selected).fill, width: 50 }} />
      ),
      colorActive: 'primary',
    },
    {
      icon: (e) => (
        <HightIcon style={{ fill: status(e.selected).fill, width: 50 }} />
      ),
      colorActive: 'warning',
    },
    {
      icon: (e) => (
        <CriticIcon style={{ fill: status(e.selected).fill, width: 50 }} />
      ),
      colorActive: 'danger',
    },
  ][i];
}
// --------------------------------------COMPONENTE DESTINADO À LISTA DE RISCOS---------------------------------------------------------------------------

export default function ({
  change,
  open,
  onClose,
  onSave,
  values,
  companies,
  contracts,
  users,
  tickets,
  store,
  action,
  APIEndpoints,
  devices,
  language,
  actionsOptions,
  stepIndex,
  onSaveS,
  setReload,
  typeTemp,
  params,
  createToTemplate
}) {
  /*#####################################################-STATES-#########################################################################*/
  let [company, SetCompany] = useState(0);
  let [idx, setIdx] = useState(null); //controla a abertura de um item da lista de risco
  let [idx2, setIdx2] = useState(null);
  let [activities, setActivities] = useState([]);
  /*##########################################-CHAMADA INICIAL DO COMPONENTE-##############################################################*/
  useEffect(() => {
    action
      .execute(
        'get',
        `${APIEndpoints.CHANGE}/activities_milestone/${change.id_change}`,

        'activities',
        null,
        null,
        { btn_loading: 'change_risks' }
      )
      .then((g) => {
        setActivities(g.data);
      });

    SetCompany(change.fk_id_company);

    action.execute(
      'post',
      APIEndpoints.REQUESTS + '/api/ticketchange',
      'tickets',
      { id_contract: change.fk_id_contract },
      null,
      false,
      false
    );
    ['key_users', 'manager', 'outs', 'solicitant', 'techs'].forEach((d) => {
      // change[d] = change.users[d];
    });
  }, []);
  /*##########################################-OPÇÕES PARA O FORMULÁRIO-##############################################################*/
  var options = {
    actionsOptions: () => actionsOptions,
    companyOptions: () =>
      companies.map((obj) => ({ value: obj.id_company, label: obj.name })),
    contractOptions: () =>
      contracts
        .filter(
          (c) =>
            c[company == 1 ? 'fk_id_company' : 'fk_id_out_company'] === company
        )
        .map((ctr) => ({ value: ctr.id_contract, label: ctr.name })),

    involvedAreasOptions: () =>
      company
        ? companies
            .find((c) => c.id_company === company)
            .department.map((u) => ({ value: u.id_department, label: u.name }))
        : [],
    solicitantOptions: () =>
      users
        .filter((c) => c.id_company === company && c.deleted == false)
        .map((u) => ({ value: u.id_user, label: u.name })),
    techOptions: () =>
      users
        .filter((c) => c.role != 'solicitant' && c.deleted == false)
        .map((u) => ({ value: u.id_user, label: u.name })),
    ticketsList() {
      try {
        return tickets.map((u) => ({
          value: u.id_tickets,
          label: u.realid + ' - ' + u.subject,
        }));
      } catch (g) {
        return [];
      }
    },
    deviceOptions: () =>
      devices.map((d) => ({
        value: d.d.id_device,
        label: d.name + ' - ' + d.namecompany,
      })),
  };

  function findOption(key, option, array) {
    try {
      if (!array) {
        return (
          (options[option]().find((e) => e.value == change[key]) || {}).label ||
          null
        );
      } else {
        return options[option]().filter((e) => change[key].includes(e.value));
      }
    } catch (r) {}
  }

  const onDeleteActivity = (id) => {
    action.execute(
      'post',
      APIEndpoints.CHANGE + '/' + 'deletemilestoitem/' + id,
      'activities',
      { fk_id_change: change.id_change }
    ).then(res => {
      setActivities(res.data);
    })
  };

  const onSaveActivity = async (values) => {
    let itens = [];
    try {
      let files = await toBase64(values.file);

      itens = files.map((h) => {
        return {
          file: h.base64,
          namefile: h.file.name,
          type: h.file.type,
        };
      });
    } catch (e) {}

    values.fk_id_change = change.id_change;
    values.fk_id_milestone = 2;
    values.file = itens;
    //values.time_estimate = moment(values.time_estimate);
    setReload();
    action
      .execute(
        'post',
        `${APIEndpoints.CHANGE}/activity_milestone`,
        'activities',
        values,
        'id_change_activity',
        null,
        null
      )
      .then((g) => {
        action
          .execute(
            'get',

            `${APIEndpoints.CHANGE}/activities_milestone/${change.id_change}`,

            'activities',
            null,
            null,
            { btn_loading: 'change_risks' }
          )
          .then((g) => {
            setActivities(g.data);
          });
      });
  };

  return (
    <Fragment>
      <hr />
      {/*##########################################-GRUPO DE RISCO-##############################################################*/}
      {stepIndex == 3 && (
        <ActivityGroup
          options={options}
          createToTemplate={createToTemplate}
          setIdx={setIdx}
          store={store}
          typeTemp={typeTemp}
          users={users}
          idx={idx}
          language={language}
          change={change}
          onDeleteActivity={onDeleteActivity}
          activities={activities}
          onSaveActivity={onSaveActivity}
          params = {params}
        />
      )}
      <hr />
      {/*##########################################-LISTAGEM DOS ITEMS PREENCHIDOS-##############################################################*/}
      <Grid container spacing={16}>
        {[
          {
            label: language.TYPE_ACTION,
            value: findOption('type_action', 'actionsOptions'),
          },
          {
            label: `${language.COMPANY}*`,
            value: change && change.company ? change.company : '',
          },
          {
            label: `${language.CONTRACT}*`,
            value: change && change.name_contract ? change.name_contract : '',
          },
          {
            label: `${language.MANAGER_GMUD}*`,
            value: change && change.manager_text ? change.manager_text : '',
          },
          {
            label: language.REQUESTER,
            value: change && change.solicitants ? change.solicitants : '',
          },
          {
            label: language.START_DATE_AND_TIME,
            value: moment.utc(change.start_date).format('DD/MM/YYYY - HH:mm:ss'),
          },
          { col: 6, label: null, value: null },
          {
            col: 4,
            label: 'Tickets',
            value: (
              <ul className="p-0">
                <li>{change.ticket_text}</li>
              </ul>
            ),
          },
          {
            col: 4,
            label: language.INVOLVED_AREAS,
            value: (
              <ul className="p-0">
                <li>{change.depart_text}</li>
              </ul>
            ),
          },
          {
            col: 4,
            label: language.DEVICES,
            value: (
              <ul className="p-0">
                <li>{change.device_text}</li>
              </ul>
            ),
          },

          {
            col: 12,
            label: language.JUSTIFICATION,
            value: <span dangerouslySetInnerHTML={{ __html: change.reason }} />,
          },
          {
            col: 12,
            label: language.DESCRIPTION,
            value: (
              <span dangerouslySetInnerHTML={{ __html: change.benefits }} />
            ),
          },
        ].map((e, i) => (
          <Grid
            key={i}
            item
            xs={e.col || 3}
            className="list-items-fields-content"
          >
            <span>{e.label}</span>
            <span>{e.value}</span>
          </Grid>
        ))}
      </Grid>
      <hr />
      <Button
        icon={stepIndex == 3 ? 'fas fa-thumbs-up' : 'fas fa-arrow-right'}
        variant="normal"
        color="success"
        fluid
        style={{ height: 40 }}
        label={stepIndex == 3 ? 'Ok' : language.NEXT}
        onClick={() => onSaveS()}
      />
    </Fragment>
  );
}
