import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import moment from 'moment';
import { groupBy, sortBy } from 'loadsh';
import CommonTable from '../../common/CommonTable';
import Dialog from '@common/Dialog'
import './styles.css';
import Button from '@common/Button';
import CalendarTask from './Calendar/Calendar';
import Constants from '@data/Constants';
import Store from '@data/Store';
import Actions from '@data/Actions';

const action = new Actions();

const { language } = Store;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export function RankingTask() {
  const [techUser, setTechUser] = React.useState('');
  const [usersOption, setUsersOptions] = React.useState([]);
  const [groupUsers, setGroupUsers] = useState();
  const [originRankingUser, setOriginRankingUser] = useState([]);
  const [allGroupUsersRanking, setAllGroupUsersRanking] = useState([]);

  const [dialogTask, setDialogTask] = useState(false)
  const [task, setTask] = useState([])

  const [tasksToDialog, setTasksToDialog] = useState([])
  const classes = useStyles();

  let { users, getUserLoged } = Store;

  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.TASKS}/ranking`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        setOriginRankingUser(res);
        const group = groupBy(res, 'username');

        const groupedItems = sortBy(group).reverse();

        const limitGroup = groupedItems.slice(0, 6);

        const itemGroupArr = [];

        limitGroup.map((gr) => {
          const finish = gr.filter((item) => item.status === 'CONCLUIDO');
          const expired = gr
            .filter(
              (item) =>
                item.status == 'AGUARDANDO' ||
                item.status == 'ANDAMENTO' ||
                item.status == 'INICIADO'
            )
            .filter((item) => {
              const dtFormat = moment(item.dt_start).format('YYYY-MM-DD');
              const dateTime = moment(dtFormat)
                .add(item.start_time, 'hours')
                .format('YYYY-MM-DD HH:mm');
              const dateAddTime = moment(dateTime)
                .add(item.time_execution, 'hours')
                .format('YYYY-MM-DD HH:mm');

              const dateNow = moment().format('YYYY-MM-DD HH:mm');

              return moment(dateNow).isBefore(moment(dateAddTime));
            });
          const inDay = gr
            .filter(
              (item) =>
                item.status == 'AGUARDANDO' ||
                item.status == 'ANDAMENTO' ||
                item.status == 'INICIADO'
            )
            .filter((item) => {
              const dtFormat = moment(item.dt_start).format('YYYY-MM-DD');
              const dateTime = moment(dtFormat)
                .add(item.start_time, 'hours')
                .format('YYYY-MM-DD HH:mm');
              const dateAddTime = moment(dateTime)
                .add(item.time_execution, 'hours')
                .format('YYYY-MM-DD HH:mm');

              const dateNow = moment().format('YYYY-MM-DD HH:mm');

              return moment(dateNow).isAfter(moment(dateAddTime));
            });

          itemGroupArr.push({
            name: gr[0].nameuser,
            username: gr[0].username,

            inDay: inDay,
            finish: finish,
            expired: expired,
          });

          setTimeout(() => {
            setGroupUsers(itemGroupArr);
            setAllGroupUsersRanking(itemGroupArr);
          }, 1000);
        });
      });
  }, []);

  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.USER}/allByRole`, '')
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        let userMap = [];

        res.map((userFind) => {
          userMap.push({
            value: userFind.name,
            id: userFind.id_user,
          });
        });

        setUsersOptions(userMap);
      })
      .catch((err) => console.log('erro', err));
  }, []);

  const handleChange = (event) => {
    let userFind;

    const id = event.target.value;
    if (id == -1) {
      setGroupUsers(allGroupUsersRanking);
      return;
    } else {
      userFind = originRankingUser.filter((item) => item.fk_id_user == id);
    }
    setTechUser(event.target.value);

    if (!userFind || userFind.length == 0) {
      setGroupUsers([]);
      return;
    }

    const finish = userFind.filter((item) => item.status == 'CONCLUIDO');
    const expired = userFind
      .filter(
        (item) =>
          item.status == 'AGUARDANDO' ||
          item.status == 'ANDAMENTO' ||
          item.status == 'INICIADO'
      )
      .filter((item) => {
        const dtFormat = moment(item.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(item.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(item.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        return moment(dateNow).isBefore(moment(dateAddTime));
      });
    const inDay = userFind
      .filter(
        (item) =>
          item.status == 'AGUARDANDO' ||
          item.status == 'ANDAMENTO' ||
          item.status == 'INICIADO'
      )
      .filter((item) => {
        const dtFormat = moment(item.dt_start).format('YYYY-MM-DD');
        const dateTime = moment(dtFormat)
          .add(item.start_time, 'hours')
          .format('YYYY-MM-DD HH:mm');
        const dateAddTime = moment(dateTime)
          .add(item.time_execution, 'hours')
          .format('YYYY-MM-DD HH:mm');

        const dateNow = moment().format('YYYY-MM-DD HH:mm');

        return moment(dateNow).isAfter(moment(dateAddTime));
      });

    setGroupUsers([
      {
        name: userFind[0].nameuser,
        username: userFind[0].username,

        inDay: inDay,
        finish: finish,
        expired: expired,
      },
    ]);
  };

  const handleDialogTask = (state, items) => {
    let tasks = items.map(i => ({
      action: (
        <Button
          icon="fas fa-eye"
          label={language.VISUALIZE}
          color="success"
          variant="normal"
          onClick={() => setTask([i])}
        />
      ),
      id_task : i.id_tasks,
      name : i.name,
      status : i.status,
      dt_start: moment(i.dt_start).format('L'),
      dt_end: i.dt_end ? moment(i.date_end).format('L') : '-',
      user : i.nameuser,
      time_execution : i.time_execution
    }))
    setTasksToDialog(tasks)
    setDialogTask(state)

  }

  return (
    <>

      <Dialog
          title={language.VISUALIZATION_OF_TASKS}
          open={dialogTask}
          maxWidth="lg"
          onClose={() => setDialogTask(false)}
        >
       <CommonTable
							col={[
								{key:'action', label:''},
								{key:'id_task', label:'ID'},
                {key:'name', label: language.NAME},
                {key : 'status', label : 'Status'},
								{key:'dt_start', label: language.START_DATE},
                { key: 'dt_end', label:language.END_DATE },
								{key:'user', label: language.USER},
                { key: 'time_execution', label: language.RUNTIME },
							]}
							data= {tasksToDialog}
						/>
        </Dialog>
        <Dialog
          title={language.VISUALIZATION_OF_TASKS}
          open={task.length> 0}
          maxWidth="lg"
          onClose={() => setTask([])}
        >
        <CalendarTask type = 'timeGridDay' eventTask = {null} reload = {false} task = {task}/>
        </Dialog>

      <Grid justifyContent="space-between" container>
        <Grid item={true} xs={4}></Grid>
        <Grid item={true} xs={8} align="right">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              style={{ margin: '0 114px' }}
              id="demo-simple-select-outlined-label"
            >
              {language.TECH}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={techUser}
              onChange={handleChange}
              label={language.TECH }
            >
              <MenuItem value={-1} key={-1}>
                {language.ALL}
              </MenuItem>
              {usersOption.map((user) => {
                return (
                  <MenuItem key={user.id} value={user.id}>
                    {user.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid style={{ margin: 10 }} container>
        <Grid xs={6}>
          <span>{language.TECH}</span>
        </Grid>
        <Grid xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="green square"></div>
          <span>{language.CONCLUDED}</span>
        </Grid>
        <Grid xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="yellow square"></div>
          <span>{language.ON_TIME}</span>
        </Grid>
        <Grid xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="red square"></div>
          <span>{language.EXPIRED}</span>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          style={{
            margin: ' 0 10px',
            padding: '5px',
          }}
          container
          variant="outlined"
        >
          {groupUsers &&
            groupUsers.map((item, index) => (
              <Grid
                style={{
                  border: '1px solid #EEE',
                  padding: '5px',
                }}
                container
                variant="outlined"
              >
                <Grid xs={6} style={{ display: 'flex' }}>
                  <span className="orange placing">{index + 1}</span>
                  <div className="user_name">{item.name} </div>
                </Grid>
                <Grid xs={2} className="grid" onClick = {() => handleDialogTask(true, item.finish)}>
                  <div className="green square"></div>
                  <span>{item.finish.length}</span>
                </Grid>
                <Grid xs={2} className="grid"  onClick = {() => handleDialogTask(true, item.inDay)}>
                  <div className="yellow square"></div>
                  <span>{item.inDay.length}</span>
                </Grid>
                <Grid xs={2} className="grid"  onClick = {() => handleDialogTask(true, item.expired)}>
                  <div className="red square"></div>
                  <span>{item.expired.length}</span>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
}
