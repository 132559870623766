
export function percentualIc(warning, danger) {
	return {
		chart: {
			type: 'column',
			inverted: true,
			polar: true,
			height: 200
		},
		title: null,
		tooltip: {
			outside: true
		},
		pane: {
			size: '85%',
			innerSize: '20%',
			endAngle: 270
		},
		xAxis: {

			tickInterval: 1,
			labels: {

				align: 'right',
				useHTML: true,
				allowOverlap: true,
				step: 1,
				y: 3,
				style: {
					fontSize: '10px'
				}
			},
			lineWidth: 0,
			categories: ['Warning', 'Danger']
		},
		yAxis: {
			min: 0,
			max: 100,
			crosshair: {
				enabled: true,
				color: '#333'
			},
			labels: {

				style: {
					fontSize: '10px'
				}
			},
			lineWidth: 0,
			tickInterval: 25,
			reversedStacks: false,
			endOnTick: true,
			showLastLabel: true
		},
		legend: { enabled: false },
		credits: { enabled: false },
		plotOptions: {
			column: {

				stacking: 'normal',
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0.15
			}
		},

		series: [{
			name: 'Valor',
			data: [{ y: warning, color: 'var(--warning)' }, { y: danger, color: 'var(--danger)' }]
		}]
	}
}
export function Capacity({ categories = [], data = {}, height = 307, filter }) {

	return {
		chart: {

			type: 'spline',
			zoomType: 'x',
			panning: true,
			panKey: 'shift'
		},
		credits: {
			enabled: false
		},
		accessibility: {
			description: ''
		},
		title: {
			text: ''
		},
		subtitle: {
			text: ''
		},
		xAxis: {
			type: "datetime",
			categories,
			title: {
				text: '',
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			labels: {
				formatter: function () {
					return this.value;
				},
			}
		},
		yAxis: {
			title: {
				text: 'Valores',
				style: {
					color: 'var(--primary)',
					fontSize: 12
				}
			},
			labels: {
				formatter: function () {
					return this.value;
				},
				style: {
					color: 'var(--primary)',
					fontSize: 10
				}
			},
			tickWidth: 1
		},
		tooltip: {
			// pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
		},
		plotOptions: {
			area: {
				pointStart: 1,
				fillOpacity: 0.5,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
						hover: {
							enabled: true
						}
					}
				}
			}
		},
		legend: {
			// enabled: false
			itemStyle: {
				fontSize: 10,
				color: '#A3A3A3'
			}
		},
		series: data
	};
}






/**Capacity({categories=[], data={}, height=307,filter}){

		 return {
			chart: {
				type: 'line',
				// height: 275
			},
			credits: {
				enabled: false
			},
			accessibility: {
				description: ''
			},
			title: {
				text: ''
			},
			subtitle: {
				text: ''
			},
			xAxis: {
				type: "datetime",
				categories,
				title:{
					text:'',
					style:{
					color:'var(--primary)',
					fontSize:10
					}
				},
				labels: {
				categories,
				style:{
					color:'var(--primary)',
					fontSize:8
				}
				}
			},
			yAxis: {
				title: {
				text: 'Valores',
				style:{
					color:'var(--primary)',
					fontSize:10
				}
				},
				labels: {
				formatter: function() {
					return this.value;
				},
				style:{
					color:'var(--primary)',
					fontSize:8
				}
				},
				tickWidth: 1
			},
			tooltip: {
				// pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
			},
			plotOptions: {
				area: {
				pointStart: 1,
				fillOpacity: 0.5,
				marker: {
					enabled: false,
					symbol: 'circle',
					radius: 2,
					states: {
					hover: {
						enabled: true
					}
					}
				}
				}
			},
			legend: {
				// enabled: false
				itemStyle:{
					fontSize:10,
					color:'#A3A3A3'
				}
			},
			series: data
			};
	}*/