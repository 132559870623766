import Constants from '@data/Constants';
import moment from 'moment';
import { minutesToSumHour, hourToMinutes, addTimes } from '@common/Func';

import Store from '@data/Store';

const { language } = Store;

let status = {
   'TODO': language.PROJECT_STATUS.TO_DO,
   'DOING': language.PROJECT_STATUS.DOING,
   'STOPPED': language.PROJECT_STATUS.STOPPED,
   'DONE': language.PROJECT_STATUS.DONE
}

function photo(e, users) {
   let _users = (e.usersphoto || []).map(e => {

      let photo = e.photo && `${Constants.APIRoot}/public/users/${e.id_user}/profileLogo.png`;

      return {
         id: e.id_user,
         src: photo || undefined,
         name: e.name_user || ''
      }
   });

   return _users;
}

function subtractHours(h1, h2) {
   h1 = hourToMinutes(h1);
   h2 = hourToMinutes(h2);

   return minutesToSumHour(h2 <= h1 ? 0 : h2 - h1);
}

export function activitiesList({ a, users, pj }) {
   let time_realized = (pj.hours_consumed || [])
      .filter(e => e.fk_id_activity == a.id_project_activity)
      .map(e => e.hours_consumed)
      .reduce((n, e) => addTimes(n, e), '00:00');
   return {
      id: a.id_project_activity,
      activity: a.name,
      sponsors: photo(a, users).filter(Boolean),
      evolution: parseInt(a.percent),
      status: status[a.stage || 'TODO'],
      dt_i: moment(a.dt_start).format('L'),
      dt_f: moment(a.dt_end).format('L'),
      time_prev: (a.hours || 0).toString().padStart(2, 0) + ':' + (a.minutes || 0).toString().padStart(2, 0),
      time_realized
   }
}
export function projectListFormat({ options, companyOptions, users, e, actDetail }) {
   let fileFormat = (f) => ({
      label: f.name,
      size: f.size,
      id_project_file: f.id_project_file,
      id_project: f.id_project
   });
   let _photo = photo(e, users);
   return {
      id: e.id_project,
      real_id: e.real_id,
      pause_project: e.pause_project,
      closed: e.closed,
      company: e.company_name,
      name: e.name,
      type: e.type_project,
      ListAvatars: [_photo.find(p => p.id == e.user_cad)].concat(_photo.filter(p => p.id != e.user_cad)).filter(Boolean),
      user_cad: e.user_cad,
      Scope: e.scope,
      EvolutionKPI: [
         {
            title: 'Backlog',
            key: language.BACKLOG,
            value: e.utils.BACKLOG.length,
            data: (e.utils.BACKLOG || []).map(a => activitiesList({ a, users, pj: e }))
         },
         {
            title: language.PROJECT_STATUS.TO_DO,
            key: 'TODO',
            value: e.utils.TODO.length,
            data: (e.utils.TODO || []).map(a => activitiesList({ a, users, pj: e }))
         },
         {
            title: language.PROJECT_STATUS.DOING,
            key: 'DOING',
            value: e.utils.DOING.length,
            data: (e.utils.DOING || []).map(a => activitiesList({ a, users, pj: e }))
         },
         {
            title: language.PROJECT_STATUS.STOPPED,
            key: 'STOPPED',
            value: e.utils.STOPPED.length,
            data: (e.utils.STOPPED || []).map(a => activitiesList({ a, users, pj: e }))
         },
         {
            title: language.PROJECT_STATUS.VALIDATION,
            key: 'VALIDATION',
            value: e.utils.VALIDATION.length,
            data: (e.utils.VALIDATION || []).map(a => activitiesList({ a, users, pj: e }))
         },
         {
            title: language.PROJECT_STATUS.DONE,
            key: 'DONE',
            value: e.utils.DONE.length,
            data: (e.utils.DONE || []).map(a => activitiesList({ a, users, pj: e }))
         },
      ],
      ForeseenVsRealized: {
         foreseen: parseFloat(e.cust_project.toString().replace(',', '.')),
         realized: e.valoractivity
      },
      EvolutionProject: {
         percent: parseInt(e.percent) || 0,
         dt_i: moment(e.start_date).format('L'),
         dt_f: moment(e.end_date).format('L'),
         planned_hours: e.horas_planejadas,
         realized_hour: e.hour_consumida,
         balance: subtractHours(e.hour_consumida || '00:00:00', e.horas_planejadas || '00:00:00'),
         exceeded_hours: e.hour_excedida,
         color: e.utils.isDelay ? 'var(--danger)' : 'var(--success)',
         pause_project: e.pause_project,
         canceled : e.canceled
      },
      FileList: [
         {
            label: language.TERM_OPENING,
            files: (e.files || []).filter(e => e.key == 'ta').map(fileFormat)
         },
         {
            label: language.TERM_CHANGE,
            files: (e.files || []).filter(e => e.key == 'tm').map(fileFormat)
         },
         {
            label: language.TERM_CLOSURE,
            files: (e.files || []).filter(e => e.key == 'te').map(fileFormat)
         },
         {
            label: language.ATTACHMENT,
            files: (e.files || []).filter(e => e.key == 'at').map(fileFormat)
         },
         {
            label: language.SCHEDULE,
            files: (e.files || []).filter(e => e.key == 'pa').map(fileFormat)
         },
         {
            label: 'Links',
            files: []
         },
      ],
      actDetail
   }
}
