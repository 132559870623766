import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import CommonPage from '../../common/CommonPage';

import DashboardReports from './DashboardReports';
import ContractedHours from './reportpages/gcf/ContractedHours';
import Tech from './reportpages/gcf/Tech';
import DeviceTrend from './reportpages/gi/DeviceTrend';
import GSDInformations from './reportpages/gsd/GSDInformations';
import NumberCalls from './reportpages/gsd/NumberCalls';
import AnalystCalls from './reportpages/gsd/AnalystCalls';
import AnalystReportCalls from './reportpages/gsd/AnalystReportCalls';
import ReportDevicesHardwares from './reportpages/gsd/ReportDevicesHardwares';
import RiskManagement from './reportpages/gr/RiskManagement';
import GestaoDeProjetos from './GestaoDeProjetos';
import TechGroup from './reportpages/gcf/TechGroup';
// import Atendimentos from './atendimentos/atendimentos';
import Animate from '@common/Animate';
const crypto = require('crypto');
 

const decryp = (aMsg) => {
  try{
    var aSecret = 'Orch3str0!@forever!';
    aMsg = aMsg.replace(/\s/g, "");
    let decipher, tRet;
    decipher = crypto.createDecipher("aes-256-cbc", aSecret);
    tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
    tRet += decipher.final("utf8");
    return tRet;
  }catch(e){
    return {}
  }
};
@observer
export default class ReportContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'dash',
      value: null
    };
    this.handlePage = this.handlePage.bind(this);
    this.onGenerateReport = this.onGenerateReport.bind(this);
  }

  handleChange = (event, index, value) => this.setState({ value: event.target.value });

  handlePage(page) {
    this.setState({ page: page });
    //console.log(page);
  }

  onGenerateReport(report, values) {
    this.action.execute(
      'post',
      this.APIEndpoints.REPORT + '/' + report,
      'reports',
      values
    );
    return this.action.execute('get', this.APIEndpoints.CONTRACT+`low`, 'contracts');
  }

  componentWillMount() {
    let numberCalls = { id_company: 1 };

  //  this.action.execute('get', this.APIEndpoints.CONTRACT, 'contracts');
       
        
        
       this.action.execute('get',  this.APIEndpoints.CONTRACT+'low').then(item=>{

        this.store.contracts= item.data;
       })

    
    this.action.execute(
      'get',
      this.APIEndpoints.REQUESTS + '/api/reportInventoryReport',
      'deviceReport'
    );
   // this.action.execute('post', this.APIEndpoints.REPORT + '/8', 'tickets', null, null, null);
    this.action.execute(
      'get',
      this.APIEndpoints.SERVICE_ORDER,
      'serviceOrders'
    );
    
    this.action.execute('post', this.APIEndpoints.REPORT + '/12', 'reportCall', null, null, null);
  }

  render() {
    let {
      language,
      contracts,
      companies,
      reportCall,
      devices,
      users,
      tickets,
      numberCalls,
      numberCallsSla,
      serviceOrders,

      deviceReport
    } = this.store;
    let { page, values, value } = this.state;

    return (
      <CommonPage
        title={
          <div
            className="logoWhite"
            onClick={() => this.redirect('/dash')}
          />
        }
        showMenuIconButton={true}
        iconAlert={true}
        loading={false}
      >
        {page === 'contractedHour' &&
          <Animate>
            <ContractedHours
              value={value}
              handleChange={this.handleChange}
              devices={devices}
              companies={companies}
              contracts={contracts}
              language={language}
              handlePage={this.handlePage}
            />
          </Animate>
        }
        {page === 'tech' &&
          <Animate>
            <Tech
              contracts={contracts}
              value={value}
              handleChange={this.handleChange}
              users={users}
              companies={companies}
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        }
                  {page === 'group_tech' &&
          <Animate>
            <TechGroup
                  contracts={contracts}
                  value={value}
                  handleChange={this.handleChange}
                  users={users}
                  companies={companies}
                  language={language}
                  handlePage={this.handlePage}
                  action={this.action}
                  APIEndpoints={this.APIEndpoints}
                />
          </Animate>
        }
        {page === 'deviceTrend' &&
          <Animate>
            <DeviceTrend
              devices={devices}
              deviceReport={deviceReport}
              contracts={contracts}
              companies={companies}
              language={language}
              handlePage={this.handlePage}
            />
          </Animate>
        }
        {page === 'gsdInformations' &&
          <Animate>
            <GSDInformations
              serviceOrders={serviceOrders}
              tickets={tickets}
              language={language}
              handlePage={this.handlePage}
            />
          </Animate>
        }
        {page === 'numberCalls' &&
          <Animate>
            <NumberCalls
              language={language}
              handlePage={this.handlePage}
              numberCallsSla={numberCallsSla}
              numberCalls={numberCalls}
            />
          </Animate>
        }
        {page === 'analystCalls' &&
          <Animate>
            <AnalystCalls
              users={users}
              language={language}
              handlePage={this.handlePage}
              tickets={tickets}
            />
          </Animate>
        }
        {page === 'analystReportCalls' &&
          <Animate>
            <AnalystReportCalls
              users={users}
              language={language}
              companies={companies}
              contracts={contracts}
              reportCall={reportCall}
              handlePage={this.handlePage}
              tickets={tickets}
            />
          </Animate>
        }
        {page === 'reportDevicesHardwares' &&
          <Animate>
            <ReportDevicesHardwares
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        }
        {page === 'grManagementRisk' &&
          <Animate>
            <RiskManagement
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        }
        {page === 'GestaoDeProjetos' &&
          <Animate>
            <GestaoDeProjetos
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        }

        {page === 'servicedesk' &&
          <Animate>
            {/* <Atendimentos
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            /> */}
          </Animate>
        }

        {page === 'dash' &&
          <Animate>
            <DashboardReports language={language} handlePage={this.handlePage} />
          </Animate>
        }
      </CommonPage>
    );
  }
}
