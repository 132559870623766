import React, { useEffect, useState } from 'react';
import moment from 'moment';
/*--------------------------------common-------------------------------------------*/

import Header from '@common/Header';
import {flexBasis} from '@common/Func';
/*--------------------------------material-ui-------------------------------------------*/
import {Card, Grid} from '@material-ui/core';
/*--------------------------------components-------------------------------------------*/
import OKREvolution from './OKREvolution';
import AlertCenter from './AlertCenter';
import Rank from './Rank';

export default function({ action, APIEndpoints, companyId}){
	const [okrEvolutions, setOkrEvolutions] = useState([]);
	const [alertResult, setAlertResult] = useState([]);
	const [rank, setRank] = useState([]);

	useEffect(() => {
		if (companyId) {
			action.execute('get', APIEndpoints.OKR + `/results/finisheds`)
				.then(result => {
					let okrsFinisheds = {}
					for (const okr of result.data) {
						if (!!okrsFinisheds[okr.department]) {
							okrsFinisheds[okr.department].y += okr.results ? okr.results.length : 0;
						} else {
							okrsFinisheds[okr.department] = {
								name: okr.department,
								y: okr.results ? okr.results.length : 0
							}
						}
					}

					let data = Object.values(okrsFinisheds);
					setOkrEvolutions(data);
				})
				.catch(error => console.error(error));

			action.execute('get', APIEndpoints.OKR + `/results/notifications/results?id_company=${companyId}`)
				.then(result => {

					let data = result.data.map(x => {
						let date = moment(x.date_finished).format('DD/MM/YYYY')
						let hours = moment(x.date_finished).format('HH:mm:ss')

						return {
							title: x.key_result,
							name: x.name,
							date: `${date} às ${hours}`,
							points: x.point,
							lost: x.point < 0,
							d: x.date_finished
						}
					});

					setAlertResult(prev => [...prev, ...data]);
				})
				.catch(error => console.error(error));
			
			action.execute('get', APIEndpoints.OKR + `/results/notifications/avaliations?id_company=${companyId}`)
				.then(result => {
					let data = result.data.map(x => {
						let date = moment(x.date_avaliation).format('DD/MM/YYYY')
						let hours = moment(x.date_avaliation).format('HH:mm:ss')

						return {
							title: `${x.key_result} - Avaliação`,
							name: x.name,
							date: `${date} às ${hours}`,
							points: x.points_avaliation,
							d: x.date_avaliation
						}
					})

					setAlertResult(prev => [...prev, ...data]);
				})
				.catch(error => console.error(error));
			
			action.execute('get', APIEndpoints.OKR + `/results/ranks?id_company=${companyId}`)
				.then(result => {
					let data = result.data.map(r => {
						let media = r.total_user_activities/r.total_activities
						let trophy = media > 0 && media < 1 && r.total > 0 ? 1 : Math.round(media);
						//console.log('testetetete', media, trophy);
						return {
							name: r.name,
							points: r.total,
							trophy
						}
					})
					setRank(data);
				})
				.catch(error => console.error(error));
		}
	}, [companyId])
	/*------------------------------------------DADOS E PROPS DO COMPONENTE OKREvolution---------------------------------------------------------*/

	let OKREvolution_props = {
		data: okrEvolutions
	}
	/*------------------------------------------DADOS E PROPS DO COMPONENTE AlertCenter---------------------------------------------------------*/
	let AlertCenter_props = {
		data: alertResult.sort((a,b) => new Date(b.d) - new Date(a.d))
	}
	/*------------------------------------------DADOS E PROPS DO COMPONENTE Rank---------------------------------------------------------*/
	let Rank_props = {
		data: rank
	}
	return(
		<div id='Block_1' className='mb-3'>
			<Header/>
			<Card className='content'>
				<Grid container spacing={2} className='pt-0'>
					
				  	<Grid item xs={5}>
						<OKREvolution {...OKREvolution_props}/>
					</Grid>
					<Grid item xs={3.5} style={{...flexBasis(3.5)}}>
						<AlertCenter {...AlertCenter_props}/>
					</Grid>
					<Grid item xs={3.5} style={{...flexBasis(3.5)}}>
						<Rank {...Rank_props}/>
					</Grid>
					
				</Grid>
			</Card>
		</div>
	)
}