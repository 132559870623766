import React, { Component, useState } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Chip,
} from '@material-ui/core';

import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import { FaClockO } from 'react-icons/lib/fa';
import moment from 'moment';
import classnames from 'classnames';
import AppBar from '@common/AppBar';
import './ServiceOrderView.css';
import ListColumn from '@common/ListColumn';
import ReactToPrint from 'react-to-print';
import CommonForm from '@common/CommonForm2';
import ReactHighcharts from 'react-highcharts';
import BarBlue from '@images/BarBlue.png';
import { SlaAnalysisTrimestral } from '../GCF/Graphs';
import MenuTooltip from '@common/MenuTooltip';
import Header from '@common/Header';
import { filterObj } from '@common/Func';
import CommonPage from '../../common/CommonPage';

export default class ServiceOrderView extends CommonPage {
  constructor() {
    super();
    this.state = {
      modalJustify: false,
      justify: '',
      description: '',
      os: '',
      id_os: 0,
      modalOs: false,
      page: 0,
      text: false,
      solutionHistoric: []
    };
    this.getHistoric = this.getHistoric.bind(this);
    this.handleMenu = this.handleMenu.bind(this);

  }



  getHistoric(id) {
    this.action.execute('get', this.APIEndpoints.SOLUTION + `hist?id=${id}`, '').then(vv => {

      this.setState({ solutionHistoric: vv.data })
    })
  }

  handleMenu(index, object) {
    const { handlePage, redirect } = this.props;
    if (index === 0) {
      this.redirect('/dash/solution/view', filterObj(object))
    } else if (index === 1) {
      this.redirect('/dash/solution/edit', filterObj(object))
    }
  }

  render() {
    let {
      language,
      values,
      ondonwFile
    } = this.props;

    let options = [
      { icon: 'fas fa-eye', label: language.VISUALIZE, color: 'var(--primary)' }
    ];


    let data = [];

    return (
      <Dialog
        PaperProps={{
          id: 'task_list'
        }}
        maxWidth="lg"
        open={true}
        bar={true}
        dialogChildren={
          /*##################################################-CABEÇALHO-############################################################*/
          <div
            className="d-flex align-items-start justify-content-between bar"
            style={{
              background: `url(${BarBlue})`,
              borderBottom: '3px solid var(--warning)'
            }}
          ></div>

        }
      >
        <div id="ServiceOrderView" ref={(el) => (this.componentRef = el)}>



          <div className="content">
            <div
              style={{
                backgroundColor: `var(--primary)`,
              }}
              className="content-contract-type content-medium"
            >
              <span>{language.DETAILS}</span>
            </div>
            <div className="content-medium content-infos">
              <Grid container spacing={12}>
                {/*------------------------------------------------------DETALHES----------------------------------------------------------------*/}

                <Grid item xs={5}>
                  <ul className="content-info-list">
                    <li>
                      <div className="label">{language.TITLE}:</div>
                      <div className="value ml-1">{values.subject}</div>
                    </li>

                    <li >
                      <div className="label">{language.COMPANY}:</div>
                      <div className="value ml-1">{values.name_company}</div>
                    </li>
                    <li >
                      <div className="label">{language.DEPARTMENT}:</div>
                      <div className="value ml-1">{values.name_department}</div>
                    </li>
                    <li >
                      <div className="label">{language.TYPE}:</div>
                      <div className="value ml-1">{values.type}</div>
                    </li>
                    <li>
                      <div className="label">{language.DATE_REGISTER}:</div>
                      <div className="value ml-1">{moment(values.dt_cad).format('DD/MM/YYYY HH:mm')}
                      </div>
                    </li>
                    <li>
                      <div className="label">{language.CADASTRE_TIME}</div>
                      <div className="value ml-1">{moment(values.dt_cad).format('HH:mm')}
                      </div>
                    </li>

                  </ul>
                </Grid>

                <Grid item xs={5} className="content-period">
                  <ul className="content-info-list">

                    <li >
                      <div className="label">{language.CATEGORY}:</div>
                      <div className="value ml-1">{values.category}</div>
                    </li>
                    <li>
                      <div className="label">{language.AUTHOR}:</div>
                      <div className="value ml-1">{values.author}</div>
                    </li>
                    <li>
                      <div className="label">{language.CADASTRE_MADE}:</div>
                      <div className="value ml-1">{values.nameuser}</div>
                    </li>
                    <li>
                      <div className="label">{language.SUBCATEGORY}:</div>
                      <div className="value ml-1">{values.subcategory}</div>
                    </li>
                    <li>
                      <div className="label">{language.REVIEW}:</div>
                      <div className="value ml-1">{values.revision <= 0 ? ' ' : values.revision}</div>
                    </li>
                    <li >
                      <div className="label">{language.LAST_UPDATE}:</div>
                      <div className="value ml-1">{values.dt_up != null ? moment(values.dt_up).format('DD/MM/YYYY HH:mm') : ''} {values.changeuser}</div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={2} className="content-period">
                  <br></br>
                  {<CommonForm
                    individual
                    spacing={2}
                    btnVisible={false}
                    btnBottom={false}
                    gridProps={{
                      alignItems: 'center',
                    }}

                    values={{ files: values.files }}
                    fields={[
                      {
                        col: 12,
                        type: 'file',
                        name: 'files',
                        label: language.FILES,
                        btnVisible: false,
                        btnBottom: false,
                        onClick: (f) =>
                          ondonwFile(values.id_solution, f.name),
                      },
                    ]}

                    button={{
                      fluid: true,
                      style: { height: 40, width: 100 },
                      className: '-mt-4',
                    }}
                  />}
                </Grid>
              </Grid>
              {/*------------------------------------------------------JUSTIFICATIVA----------------------------------------------------------------*/}
              <div
                style={{
                  backgroundColor: `var(--primary)`,
                  width: '100%',
                }}
                className="content-contract-type content-medium"
              >
                <span>{language.DESCRIPTION}</span>

              </div>
              <Grid container spacing={16}>
                <Grid items xs={10}>


                  <div
                    style={{ marginLeft: 20, height: 'auto', marginBottom: 20 }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.description,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {/*------------------------------------------------------Anexos----------------------------------------------------------------*/}

              <div
                style={{
                  backgroundColor: `var(--primary)`,
                  width: '100%',
                  marginBottom: 20,
                }}
                className="content-contract-type content-medium"
              >

              </div>

              <Grid container spacing={16}>
                <Grid items xs={6}>
                  <div>
                    {language._ANALYZE}
                  </div>


                  <div
                    style={{ marginLeft: 20, height: 'auto', marginBottom: 20 }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.problem_identification,
                      }}
                    />
                  </div>
                </Grid>
                <Grid items xs={6}>
                <div>
                    {language.CORRECTION}
                  </div>


                  <div
                    style={{ marginLeft: 20, height: 'auto', marginBottom: 20 }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.analyse,
                      }}
                    />
                  </div>
                </Grid>

              </Grid>
              <Grid container spacing={16}>
                <Grid items xs={6}>
                  <div>
                    {language.RESOLUTION}
                  </div>


                  <div
                    style={{ marginLeft: 20, height: 'auto', marginBottom: 20 }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.text_resolution,
                      }}
                    />
                  </div>
                </Grid>
                <Grid items xs={6}>
                <div>
                    {language.EFFICIENCY}
                  </div>


                  <div
                    style={{ marginLeft: 20, height: 'auto', marginBottom: 20 }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.test,
                      }}
                    />
                  </div>
                </Grid>

              </Grid>
            </div>
          </div>

        </div>

      </Dialog>




    );
  }
}
