import React, {useReducer} from 'react';
import List from '@common/List';
import AttachFile from '@common/AttachFile';
import Dropzone from 'react-dropzone';
import {Badge} from '@material-ui/core'
import Button from '@common/Button';

const Item = ({title, date})=>(
	<div>
		<span>{title}</span>
		<span>
			<i className="fas fa-calendar-day"/>
			{date}
		</span>
	</div>
)

export default function({data=[], onAdd, onRemoveFile, id, onDownload, saveLinks, deleteLinks, openLinks}){
	let [open, setOpen] = useReducer((state, v) => state===v?null:v, null);
	return(
		<div className='FileList'>
			<List
				mh={162}
				items={data.flatMap((e, i)=>[
					{
                  onClick:() => setOpen(i),
                  className:'pl-1 pr-2',
						content:(
							<div className='item'>
								<div>
									<i className={(open===i?'fas fa-chevron-down':'fas fa-chevron-right')+" control-open"}/>
									<span className='label ml-1'>{e.label}</span>
								</div>
								<div className='d-flex align-itsm-center'>
									{e.files.length?(
										<Badge
										  badgeContent={e.files.length}
										  className="badge-total-file mr-2"
										/>
									):null}
								{e.label == "Links" ? ( 
									<Button
									icon='fas fa-plus'
									variant='circle'
									color='warning'
									size={.55}
									onClick={() =>openLinks(id)}
									/>
   
):(
									<Dropzone
									  accept=".zip, .7z, .json, .vbs, .sql , .html, .CSV, .tif, .pdf,.doc, .docx, .xls, .xlsx, .xml, .ppt, .csv, .txt , .zip, .rar, .msg, audio/*, image/*"
									  required
									  onDrop={a => onAdd(a, i, id)}
									  style={{
									    border: '1px solid transparent'
									  }}
									>
                              <Button
                                 icon='fas fa-plus'
                                 variant='circle'
                                 color='warning'
                                 size={.55}
                                 _loading={'post-file-project-'+id+'-'+i}
                              />
									</Dropzone>
								)}
	</div>
							</div>
						)
					},
					open===i?{
                  className:'d-block',
						content: e.files.map((f,i)=>(
							<div key={f.label+'-'+i} className='content-file py-1 d-block'>
								<AttachFile {...f} onClick={() => onDownload(f)} onDelete={() => onRemoveFile(f, i, id)} />
							</div>	
						))
						
					}:null
				]).filter(Boolean)}
			/>
		</div>
	)
}