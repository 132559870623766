import { Button, ButtonGroup, Chip } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import CustomButton from '../../common/Button';
import CommonDelete from '../../common/CommonDelete';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { Constants, Store } from '../../data';
import GenericActions from '../../data/Actions';
import { useDebounce } from '../ServicePortal/helpers';

const PasswordQuestionList = ({ forceReload, onChangePageType, onAlert }) => {
  const { language } = Store;
  const randomId = () => new Date().getTime().toString(20);

  const [reload, setReload] = useState(randomId());
  const [dataList, setDataList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const [filterLang, setFilterLang] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const filterTextSearch = useDebounce(textSearch, 300);

  const [deleteModal, setDeleteModal] = useState({ opened: false, id: null });

  useEffect(() => {
    getData();
  }, [currentPage, rowsPerPage, filterTextSearch, filterLang, reload]);

  useEffect(() => {
    forceReload && handleReload();
  }, [forceReload]);

  const getData = async () => {
    const offset = currentPage * rowsPerPage;
    const pagination = `?limit=${rowsPerPage}&offset=${offset}`;
    const filters = `&search=${filterTextSearch}&lang=${filterLang}`;

    const fetch = new GenericActions();
    fetch
      .execute(
        'GET',
        `${Constants.APIEndpoints.PASSWORD_QUESTIONS}${pagination}${filters}`
      )
      .then((response) => {
        const result = response.data;

        const dataResult = result.map((item, index) => {
          return {
            id: item.id_password_question,
            question_text: item.question_text,
            lang: item.lang,
            lang_label: language.LANG[item.lang.toUpperCase()],
            actions: (
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="tw-flex tw-items-center tw-justify-items-center tw-gap-2">
                    <RaisedButton
                      circleButton
                      color="default"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() => handleEdit(item)}
                    />
                    <RaisedButton
                      circleButton
                      color="danger"
                      icon="fas fa-trash-alt"
                      label={language.REMOVE}
                      style={{ fontSize: 12, width: 21, height: 21 }}
                      onClick={() =>
                        setDeleteModal({
                          opened: true,
                          id: item.id_password_question,
                        })
                      }
                    />
                  </div>
                }
              />
            ),
          };
        });

        setDataList(dataResult);
        setTotalDataCount(response.data?.[0]?.count ?? 0);
      })
      .catch((error) => {
        setDataList([]);
        setTotalDataCount(0);
      });
  };

  const handleSearch = (text, call) => {
    setTextSearch(text && typeof text === 'object' ? '' : text ?? '');
    setTotalDataCount(0);
  };

  const handleFilterLang = (lang) => {
    filterLang === lang ? setFilterLang('') : setFilterLang(lang);
    setTotalDataCount(0);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleReload = () => setReload(randomId());

  const handleChangePageType = (data) => {
    onChangePageType({ type: 'form', data });
  };

  const handleDelete = (item) => {
    const { id } = deleteModal;
    const fetch = new GenericActions();
    fetch
      .execute('delete', `${Constants.APIEndpoints.PASSWORD_QUESTIONS}/${id}`)
      .then((response) => {
        setDeleteModal({ opened: false, id: null });
        handleReload();
      })
      .catch((error) => {
        onAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const handleEdit = (item) => {
    handleChangePageType(item);
  };

  const cols = [
    { key: 'id', label: language.ID, style: { width: 20 } },
    { key: 'question_text', label: language.ASK },
    { key: 'lang_label', label: language.LANGUAGE, noOrder: true },
    {
      key: 'actions',
      noOrder: true,
      label: language.ACTIONS,
      style: { width: 90, textAlign: 'center' },
    },
  ];

  const dataRows = useMemo(() => {
    return dataList.map((item) => ({
      id: item.id,
      question_text: item.question_text,
      lang_label: item.lang_label,
      actions: item.actions,
    }));
  }, [dataList]);

  return (
    <div>
      <CommonTable
        title={''}
        language={language}
        columns={cols}
        data={dataRows}
        countTotal={totalDataCount}
        searchColumnEvent={(value) => {
          handleSearch(value?.search, 'searchColumnEvent');
        }}
        onClearAllFilter={() => {
          handleSearch('', 'onClearAllFilter');
        }}
        callPageSelectFilter={(page, values, rowsPerPage, type) => {}}
        isAutomaticPagination={false}
        hideFooterToolbar
        paginationTop={false}
        currentPage={currentPage}
        rowsPerPageProp={rowsPerPage}
        callPageSelect={handlePageChange}
        beforeBar={
          <>
            <CustomButton
              variant="circle"
              icon="fas fa-plus"
              zoom={0.8}
              style={{ zoom: 0.8 }}
              className="mr-3"
              color="primary"
              primary={true}
              label={`${language.NEW} ${language.ASK}`}
              onClick={() => handleChangePageType()}
            />
          </>
        }
        beforeBar2={
          <div className="tw-flex tw-items-center tw-gap-3">
            <ButtonGroup
              size="small"
              disableRipple={true}
              disableElevation={true}
              disableFocusRipple={true}
            >
              {Object.keys(language.LANG).map((flagKey) => (
                <Button
                  size="small"
                  key={flagKey}
                  className="tw-text-white"
                  onClick={() => {
                    handleFilterLang(flagKey.toLocaleLowerCase());
                  }}
                  color={
                    filterLang === flagKey.toLocaleLowerCase()
                      ? 'sensrWarning'
                      : ''
                  }
                  variant={
                    filterLang === flagKey.toLocaleLowerCase()
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  {language.LANG[flagKey]}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        }
      />

      {deleteModal.opened && (
        <CommonDelete
          language={language}
          onDelete={handleDelete}
          closeModal={() => setDeleteModal({ opened: false, id: null })}
          message={language.CONFIRM_DELETE}
        />
      )}
    </div>
  );
};

export default PasswordQuestionList;
