import React, { useState, useEffect } from 'react';
import Constants from '@data/Constants';

const tenant = Constants.Tenant;

// --------------------------------icons----------------------------------
 

export default function({device, ioServers,Emitter,monitorando,all}) {
  let [color, Setcolor] = useState('red');


  useEffect(() => {
    
    if(monitorando==true){
      Setcolor('#00ff00')
    }else if(monitorando ==false){
      Setcolor('red')
    }
 
  
    ioServers.on(`_${tenant}_${device.id_device}`,
      (data) => {
         
        if(data.offline==true ){
         Setcolor('red')
       
         } else{
         Setcolor('#00ff00')
       }
      });
  
 
  
 
   },[device,all]);

  return <i className='fas fa-circle' style={{ fontSize: 13, color:  color }} />

}
