import React, {Fragment, useEffect, useState} from 'react';
import Header from '@common/Header';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Store from '@data/Store';

const { language } = Store;

export default function({
   refAliceCarousel,
   setFormOpen,
   tags,
   APIEndpoints,
   action,
   setModalCustOpen,
   clear,
   project
}){
   let [openModalTags, setOpenModalTags] = useState(false);
   let colors = [
      '#9E24F0',
      '#F00D82',
      '#F05E02',
      '#F0B002',
      '#92D050',
      '#02F0DE',
      '#02CBF0',
      '#00008B',
      '#708090',
      '#2F4F4F',
      '#8B4513',
      '#8B0000',
      '#D8BFD8',
      '#4682B4'
   ]
   function onSubmit(values){
      action.execute('put',  `${APIEndpoints.PROJECT}/tag/update`, 'project', values, null, {btn_loading:'submit_'+values.id_project_tag}).then(e=>{
         setOpenModalTags(false);
      })
   }
   function onDelete(values){
      action.execute('put',  `${APIEndpoints.PROJECT}/tag/update`, 'project', values, null, {btn_loading:'delete_'+values.id_project_tag}).then(e=>{
         setOpenModalTags(false);
      })
   }

   useEffect(() => { 
      const openCosts = window.location.href.includes('open-costs');
      setModalCustOpen(openCosts);
   }, [])
   
   return (
      <Fragment>
         <Dialog
            title='Tags'
            open={openModalTags}
            onClose={() => setOpenModalTags(false)}
            monitor_scroll
         >
            {tags.map(e=>(
               <CommonForm
                  fixedValue={e}
                  values={{
                     name:e.name,
                     color:e.color
                  }}
                  onSubmit={onSubmit}
                  hiddenSubmit
                  fields={[
                     {
                        col:6,
                        type:'text',
                        name:'name',
                        label: language.NAME,
                        required:true,
                     },
                     {
                        col:2,
                        type:'select',
                        name:'color',
                        label: language.COLOR,
                        sortOptions:false,
                        required:true,
                        options:colors.map(e=>({value:e, label:<div style={{backgroundColor:e, width:20, height:20}}/>})),
                     },
                     {
                        col:4,
                        type:'element',
                        content:(v, h, submit)=>(
                           <div className='d-flex align-items-center mt-3'>
                              <Button
                                 icon='fas fa-pencil-alt'
                                 label={language.SAVE_CHANGES}
                                 color='success'
                                 variant='normal'
                                 className='mr-3'
                                 onClick={() => {
                                    submit();
                                    window.location.reload();
                                 }}
                                 _loading={'submit_'+e.id_project_tag}
                              />
                              <Button
                                 icon='fas fa-trash-alt'
                                 label={language.DELETE}
                                 color='danger'
                                 variant='circle'
                                 outlined
                                 style={{fontSize:16}}
                                 onClick={() => onDelete({...e, color:undefined, deleted:true})}
                                 _loading={'delete_'+e.id_project_tag}
                              />
                           </div>
                        )
                     }
                  ]}
               />
            ))}
         </Dialog>
         <Header className='justify-content-start justify-content-between'>
            <div>
            <Button
               icon='fas fa-dollar-sign'
               label={language.ADD_OTHER_COSTS}
               color='warning'
               variant='circle'
               className='mx-2'
               onClick={() => setModalCustOpen(true)}
            />
            <Button
               icon='fas fa-plus'
               label={language.ADD}
               disabled={project.pause_project}
               color='warning'
               variant='circle'
               className='mx-2'
               onClick={() => {setFormOpen(true) ; clear(true) }}
            />
            <Button
               icon='fas fa-print'
               label={language.PRINT}
               color='warning'
               className='mx-2'
               onClick={() => window.print()}
            />
            <Button
               icon='fas fa-tags'
               label='Tags'
               color='warning'
               onClick={() => setOpenModalTags(true)}
            />
            </div>
            <div className='d-flex align-items-center'>
            <Button
               variant='circle'
               icon='fas fa-chevron-left'
               label={language.BACK}
               color='light'
               outlined
               className='mr-4'
               onClick={() => refAliceCarousel.current.slidePrev()}
            />
            <Button
               variant='circle'
               icon='fas fa-chevron-right'
               label={language.ADVANCE}
               color='light'
               outlined
               onClick={() => refAliceCarousel.current.slideNext()}
            />
            </div>
         </Header>
      </Fragment>
   )
}