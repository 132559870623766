import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';

@observer
export default class CioPageGraph extends CommonPage {
  constructor() {
    super();
    this.state = {};
  }

    
  render() {
    //if(this.store.getUserLoged().role!='client'){
      //this.redirect('/dash/gsd')
    //}

    return (
      <CommonPage
       loading={false}
        title={
          <div
            className="logoWhite"
            onClick={() => this.store.getUserLoged().role=='client'&&this.redirect('/dash')}
          />
        }
        background={'#EAF3F4'}
        showMenuIconButton={false}
        iconAlert={true}
      >
        <style>
        {"\
          body{\
            background:white;\
          }\
        "}
        </style>
        <div>
          <div
            className="logIconDiagrama"
            style={{ width: 1024, height: 700, margin: 'auto' }}

          >
            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                height: 140,
              
                width: 180,
                top: 140,
                left: 425
              }}
              onClick={() =>this.store.getUserLoged().role=='client'&& this.redirect('/dash/cio')}
            />

            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                height: 140,
                
                width: 180,
                left: 420,
                top: 400
              }}
              onClick={() => this.store.getUserLoged().role=='client'&&this.redirect('/dash/gp/list')}
            />

            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
               
                height: 120,
                width: 200,
                  left:200,
                top: 150
              }}
              onClick={() =>
                this.store.getUserLoged().role=='client'&&this.redirect('/dash/riskmanagement', { risk: 0 })
              }
            />

<div
              style={{
                position: 'relative',
                cursor: 'pointer',
                
                height: 120,
                width: 200,
                  left:625,
                top: -130
              }}
              onClick={() =>
                this.store.getUserLoged().role=='client'&&   this.redirect('/dash/gsd', { risk: 0 })
              }
            />

            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                height: 140,
                width: 200,
                top: -275,
                left: 200
              }}
              onClick={() => this.store.getUserLoged().role=='client'&&this.redirect('/dash/gcf')}
            />

            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
                height: 80,
                width:250,
                top: -175,
               
                left: 600
              }}
              onClick={() => this.store.getUserLoged().role=='client'&&this.redirect('/dash/task')}
            />

          </div>
        </div>
      </CommonPage>
    );
  }
}
