import React, { Component } from 'react';
import AppBar from '@common/AppBar';

class Label extends Component {
  render() {
    let { title, w, h, width, icon } = this.props;
    return (
      <AppBar min style={{ top: 0, margin: 0, width: width || 145, left: 0 }}>
        <div className="d-flex align-items-center">
          <div className="bg-icon">
            <div
              className={icon}
              style={{ width: (w || 90) + '%', height: (h || 90) + '%' }}
            />
          </div>
          <span className="ml-2" style={{ fontWeight: 600 }}>
            {title}
          </span>
        </div>
      </AppBar>
    );
  }
}
export default function Fields(e, listCompanies, but) {
  let { language } = e;
  return {
    form1: [
      // ----------------------------------------------------------cio----------------------------------------------------------------

      {
        span: 3,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true,
      },
      {
        span: 3,
        name: 'fk_id_company',
        label: language.COMPANY,
        type: 'select',
        options: listCompanies,
        /* required:true*/
      },
      {
        span: 3,
        label: but,
        type: 'tab',
      },
      {
        span: 12,
        label: (
          <Label
            icon="cioIcon"
            title={language.GENERAL_SETTINGS}
            w={300}
            h={80}
            width={360}
          />
        ),
        type: 'tab',
      },
      {
        label: language.PROFILE,
        span: 2,
        name: 'perfil',
        type: 'check',
      },
      {
        label: language.USERS_CREATION,
        span: 2,
        name: 'user_create',
        type: 'check',
      },
      {
        label: language.PARAMETERS,
        span: 2,
        name: 'parameters',
        type: 'check',
      },
      {
        label: language.NOTIFICATION_CENTER,
        span: 2,
        name: 'notifications_center',
        type: 'check',
      },
      {
        label: language.PASSWORD_QUESTION,
        span: 2,
        name: 'password_questions',
        type: 'check',
      },

      {
        label: language.AUTOMATION,
        span: 2,
        name: 'automation',
        type: 'check',
      },
      {
        label: language.ALTERNATE_BOSS,
        span: 2,
        name: 'chief_alt',
        type: 'check',
      },
      {
        label: language.HELP_DESK,
        span: 2,
        name: 'central_help',
        type: 'check',
      },
      {
        label: language.POSITIONS,
        span: 2,
        name: 'positions_users',
        type: 'check',
      },
      {
        label: language.PASSWORD_VAULT,
        span: 2,
        name: 'password_vault',
        type: 'check',
      },
      {
        label: language.AUTOMATIC_ANSWER,
        span: 2,
        name: 'gsd_answer',
        type: 'check',
      },
      {
        label: language.CHANNELS_MANAGEMENT,
        span: 2,
        name: 'channel_management',
        type: 'check',
      },
      {
        label: language.LOCATION,
        span: 2,
        name: 'location',
        type: 'check',
      },
      {
        label: language.RESOLUTION_CATEGORY,
        span: 2,
        name: 'resolution_category',
        type: 'check',
      },
      {
        label: language.RESOLUTION_CODE,
        span: 2,
        name: 'resolution_code',
        type: 'check',
      },
      {
        label: language.OUTAGE,
        span: 2,
        name: 'outage',
        type: 'check',
      },
      {
        label: language.CAB_EVENTS,
        span: 2,
        name: 'cab_events',
        type: 'check',
      },
      {
        label: language.APPROVALS,
        span: 2,
        name: 'approvals',
        type: 'check',
      },
      {
        label: language.FILE_EXTENSIONS,
        span: 2,
        name: 'file_extensions',
        type: 'check',
      },
      {
        label: language.SPRINT_ACTIVITY_EFFORT,
        span: 2,
        name: 'sprint_activity_effort',
        type: 'check',
      },
      {
        span: 12,
        label: (
          <Label icon="cioIcon" title={language.CIO_COCKPIT} w={300} h={80} />
        ),
        type: 'tab',
      },
      {
        label: language.CIO_COCKPIT,
        span: 2,
        name: 'cio_cio_cockpit',
        type: 'check',
      },
      {
        label: language.PANEL_DASH,
        span: 2,
        name: 'cio_panel',
        type: 'check',
      },
      {
        label: language.BSC,
        span: 2,
        name: 'cio_bsc',
        type: 'check',
      },
      {
        label: language.CANVAS_,
        span: 2,
        name: 'cio_canvas',
        type: 'check',
      },
      // ----------------------------------------------------------GCF----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            icon="imgGcf"
            title={language.CONTRACTS_MANAGEMENT}
            width={300}
          />
        ),
        type: 'tab',
      },
      {
        label: language.DASHBOARD,
        span: 2,
        name: 'gcf_dasboard',
        type: 'check',
      },
      {
        label: language.CONTRACT,
        span: 2,
        name: 'gcf_contract',
        type: 'check',
      },
      {
        label: language.SERVICE_ORDER,
        span: 2,
        name: 'gcf_service_order',
        type: 'check',
      },
      {
        label: language.AUDIT,
        span: 2,
        name: 'gcf_audit',
        type: 'check',
      },
      {
        label: language.ASSESSMENT,
        span: 2,
        name: 'gcf_assessment',
        type: 'check',
      },
      {
        label: language.IT_BUDGET_CONTROL,
        span: 2,
        name: 'gcf_it_budget_control',
        type: 'check',
      },
      // ----------------------------------------------------------GI----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            icon="imgGi"
            title={language.AVAILABILITY_MANAGEMENT}
            width={300}
          />
        ),
        type: 'tab',
      },
      {
        label: language.DASHBOARD,
        span: 2,
        name: 'gi_dashboard',
        type: 'check',
      },
      {
        label: language.MONITOR,
        span: 2,
        name: 'gi_monitor',
        type: 'check',
      },
      {
        label: language.AGENT_DOWNLOAD,
        span: 2,
        name: 'gi_agent_download',
        type: 'check',
      },
      {
        label: language.CAPACITY_PLAN,
        span: 2,
        name: 'gi_capacity_plain',
        type: 'check',
      },
      {
        label: language.DISCOVERY,
        span: 2,
        name: 'gi_discovery',
        type: 'check',
      },
      // ----------------------------------------------------------GSD----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label icon="imgGsd" title={language.TICKET_MANAGEMENT} width={300} />
        ),
        type: 'tab',
      },
      {
        label: language.DASHBOARD,
        span: 2,
        name: 'gsd_dashboard',
        type: 'check',
      },
      {
        label: language.MANAGEMENT_DASHBOARD,
        span: 2,
        name: 'gsd_dash_ge',
        type: 'check',
      },
      {
        label: language.MANAGEMENT_TICKET,
        span: 2,
        name: 'gestao_ticket',
        type: 'check',
      },
      {
        label: language.DASHBOARD_PAGE.DM,
        span: 2,
        name: 'gsd_dm',
        type: 'check',
      },
      {
        label: language.CATEGORIZATION,
        span: 2,
        name: 'gsd_categorization',
        type: 'check',
      },

      {
        label: language.CHANGE_MANAGEMENT,
        span: 2,
        name: 'gsd_change_management',
        type: 'check',
      },
      {
        label: language.STOCK_MANAGEMENT,
        span: 2,
        name: 'gsd_stock_management',
        type: 'check',
      },
      {
        label: language.INVENTORY,
        span: 2,
        name: 'gsd_inventory',
        type: 'check',
      },
      {
        label: language.KNOWLEDGE_BASE,
        span: 2,
        name: 'gsd_knowledge_base',
        type: 'check',
      },
      {
        label: language.CONSULT_HISTORIC,
        span: 2,
        name: 'gsd_consult_historic',
        type: 'check',
      },
      {
        label: language.CMDB_,
        span: 2,
        name: 'gsd_cmdb',
        type: 'check',
      },
      {
        label: language.CHANGE_REGISTER_MANAGER,
        span: 2,
        name: 'gsd_is_change_manager',
        type: 'check',
      },
      {
        label: language.CHANGE_MANAGER,
        span: 2,
        name: 'gsd_is_register_manager',
        type: 'check',
      },
      {
        label: language.RISK_CHANGE,
        span: 2,
        name: 'gsd_changerisk',
        type: 'check',
      },
      {
        label: language.SLA_PARAMS,
        span: 2,
        name: 'gsd_slaparam',
        type: 'check',
      },
      {
        label: language.SLA_WORKDAY,
        span: 2,
        name: 'gsd_workday',
        type: 'check',
      },
      {
        span: 12,
        label: (
          <Label
            icon="imgGsd"
            title={language.TASK_MANAGEMENT}
            width={300}
          />
        ),
        type: 'tab',
      },
      {
        label: language.DASHBOARD,
        span: 2,
        name: 'task_dashboard',
        type: 'check',
      },
      {
        label: language.TASK_LIST,
        span: 2,
        name: 'task_list',
        type: 'check',
      },
      {
        label: language.APPROVALS,
        span: 2,
        name: 'task_approve',
        type: 'check',
      },
      {
        label: language.CALENDAR,
        span: 2,
        name: 'task_calendar',
        type: 'check',
      },
      {
        label: language.GMUD_FLOW,
        span: 2,
        name: 'change_management_workflow',
        type: 'check',
      },
      {
        label: language.GROUP_GMUD,
        span: 2,
        name: 'change_management_group',
        type: 'check',
      },
      {
        label: language.CHANGE_TEMPLATE,
        span: 2,
        name: 'change_template',
        type: 'check',
      },
      {
        label: language.RISK_CHANGE,
        span: 2,
        name: 'risk_change',
        type: 'check',
      },

      // ----------------------------------------------------------GRC----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label icon="imgGr" title={language.RISKS_MANAGEMENT} width={300} />
        ),
        type: 'tab',
      },
      {
        label: language.DASHBOARD,
        span: 2,
        name: 'grc_dashboard',
        type: 'check',
      },
      {
        label: language.SIDE_DISH,
        span: 2,
        name: 'grc_side_dish',
        type: 'check',
      },
      {
        label: language.ISO_27001,
        span: 2,
        name: 'grc_iso_27001',
        type: 'check',
      },
      {
        label: language.ISO_20000,
        span: 2,
        name: 'grc_iso_20000',
        type: 'check',
      },
      {
        label: language.ISO_9001,
        span: 2,
        name: 'grc_iso_9001',
        type: 'check',
      },
      {
        label: language.ISO_37001,
        span: 2,
        name: 'grc_iso_37001',
        type: 'check',
      },
      {
        label: language.COBIT,
        span: 2,
        name: 'grc_cobit',
        type: 'check',
      },
      {
        label: language.BACKUP_MANAGEMENT,
        span: 2,
        name: 'grc_backup_management',
        type: 'check',
      },
      {
        label: language.IT_EVOLUTION_MANAGEMENT,
        span: 2,
        name: 'grc_it_evolution_management',
        type: 'check',
      },
      {
        label: language.LGPD,
        span: 2,
        name: 'grc_lgpd',
        type: 'check',
      },
      {
        label: language.RISK_MATRIX,
        span: 2,
        name: 'grc_risk_matrix',
        type: 'check',
      },
      // ----------------------------------------------------------GP----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            icon="projectManIcon"
            title={language.PROJECT_MANAGEMENT}
            width={300}
            h={100}
          />
        ),
        type: 'tab',
      },
      {
        label: language.PANEL_PMO,
        span: 2,
        name: 'gp_panel_pmo',
        type: 'check',
      },
      {
        label: language.PROJECTS,
        span: 2,
        name: 'gp_projects',
        type: 'check',
      },
      {
        label: language.SPRINT,
        span: 2,
        name: 'sprint',
        type: 'check',
      },
      {
        label: language.ALLOCATION,
        span: 2,
        name: 'gp_allocation',
        type: 'check',
      },
      {
        label: language.OBJECT_PRIORITIZATION,
        span: 2,
        name: 'gp_object_priorization',
        type: 'check',
      },
      {
        label: language.PROJECT_TEMPLATE,
        span: 2,
        name: 'gp_template',
        type: 'check',
      },
      {
        label: language.COIN_MANAGEMENT,
        span: 2,
        name: 'coin_management',
        type: 'check',
      },
      // ----------------------------------------------------------REPORT----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            icon="reportsIcon"
            title={language.REPORTS}
            width={100}
            h={100}
          />
        ),
        type: 'tab',
      },

      {
        label: language.REPORTS_FOR_TECH,
        span: 3,
        name: 'report_tech',
        type: 'check',
      },
      {
        label: language.REPORTS_FOR_TECH_GROUP,
        span: 3,
        name: 'report_group_tech',
        type: 'check',
      },

      // ----------------------------------------------------------COMPANY----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            icon="companyIcon"
            title={language.COMPANY}
            width={100}
            h={100}
          />
        ),
        type: 'tab',
      },
      {
        label: language.COMPANY_LIST,
        span: 2,
        name: 'company_company_list',
        type: 'check',
      },
      {
        label: language.DEPARTMENT,
        span: 2,
        name: 'company_department',
        type: 'check',
      },
      {
        label: 'Sub' + language.DEPARTMENT.toLowerCase(),
        span: 2,
        name: 'company_subdepartment',
        type: 'check',
      },
      {
        label: language.MAIL_POLICY,
        span: 2,
        name: 'company_mail_policy',
        type: 'check',
      },
      {
        label: language.COMPANY_HOUR,
        span: 4,
        name: 'company_company_hour',
        type: 'check',
      },
      {
        label: language.LDPA,
        span: 2,
        name: 'company_ldpa',
        type: 'check',
      },
      {
        label: language.COMPANY_LOGO,
        span: 2,
        name: 'company_company_logo',
        type: 'check',
      },
      // ----------------------------------------------------------Gestão de infraestrutura----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            title={language.DASHBOARD_PAGE.GI}
            icon="imgGi"
            w={100}
            h={100}
            width={360}
          />
        ),
        type: 'tab',
      },
      {
        label: language.DEVICE,
        span: 2,
        name: 'gi_device',
        type: 'check',
      },
      {
        label: language.SNMP,
        span: 2,
        name: 'gi_snmp',
        type: 'check',
      },
      {
        label: language.DEVICE_TYPE,
        span: 2,
        name: 'gi_device_type',
        type: 'check',
      },
      {
        label: language.OUTSOURCED,
        span: 2,
        name: 'gi_outsourced',
        type: 'check',
      },
      {
        label: language.DISCOVERY,
        span: 2,
        name: 'gi_discovery',
        type: 'check',
      },
      {
        label: language.IC_RELATIONSHIP,
        span: 2,
        name: 'ic_relationship',
        type: 'check',
      },
      // ----------------------------------------------------------Gestão de contratos e fornecedores----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            title={language.DASHBOARD_PAGE.GCF}
            icon="imgGcf"
            w={100}
            h={100}
            width={360}
          />
        ),
        type: 'tab',
      },
      {
        label: language.CONTRACT,
        span: 2,
        name: 'gcf_contract',
        type: 'check',
      },
      {
        label: language.SPONSOR,
        span: 2,
        name: 'gcf_sponsor',
        type: 'check',
      },
      {
        label: language.SLA_POLICY,
        span: 2,
        name: 'gcf_sla_policy',
        type: 'check',
      },
      {
        label: language.ALLOWANCE_SLA,
        span: 2,
        name: 'gcf_sla_policy_allowance',
        type: 'check',
      },
      // ----------------------------------------------------------Gestão de contratos e fornecedores----------------------------------------------------------------
      {
        span: 12,
        label: (
          <Label
            title={language.SERVICE_DESK}
            icon="imgGsd"
            w={100}
            h={100}
            width={360}
          />
        ),
        type: 'tab',
      },
      {
        label: language.TASK,
        span: 3,
        name: 'gsd_task',
        type: 'check',
      },
      {
        label: language.SERVICE_CATALOG,
        span: 1.8,
        name: 'gsd_service_catalog',
        type: 'check',
      },
      {
        label: language.CATEGORY,
        span: 1.8,
        name: 'gsd_category',
        type: 'check',
      },
      {
        label: language.REQUESTER,
        span: 1.8,
        name: 'gsd_requester',
        type: 'check',
      },
      {
        label: language.TECH,
        span: 1.8,
        name: 'gsd_tech',
        type: 'check',
      },
      {
        label: language.GROUP,
        span: 1.8,
        name: 'gsd_group',
        type: 'check',
      },
      {
        label: language.TEMPLATE,
        span: 12,
        name: 'gsd_template',
        type: 'check',
      },
      //---------------------------------------------FALE------------------------------
      {
        span: 12,
        label: (
          <Label
            icon="cioIcon"
            title={language.SPEAK}
            w={80}
            h={80}
            width={360}
          />
        ),
        type: 'tab',
      },
      {
        label: language.SPEAK,
        span: 2,
        name: 'fale',
        type: 'check',
      },
      {
        span: 12,
        label: (
          <Label
            icon="cioIcon"
            title={language.GENERAL_SETTINGS}
            w={80}
            h={80}
            width={360}
          />
        ),
        type: 'tab',
      },
      {
        label: language.ALLOW_EDITING_OF_TICKET_REQUESTER,
        span: 2,
        name: 'edit_ticket_requester',
        type: 'check',
      },
    ],
  };
}
