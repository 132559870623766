import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Switch } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';

import { Radial } from 'sensrit-charts';
import { Select } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactTooltip from 'react-tooltip';
import MdFilterList from 'react-icons/lib/md/filter-list';
import moment from 'moment';
import vendors from '../../util/vendors';
import crypt from '../../util/crypt';
import MonitorUtils from '../../util/MonitorUtils';
import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import { MenuItem, Menu, IconButton } from '@material-ui/core';
import RaisedButton from '@common/RaisedButton';
import './Server.css';




export default class Server extends CommonPage {

  constructor() {
    super();
    this.state = {
      available: false,
      mount: true,
      hold: '',
      aliveHold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disk: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      removeloading: true,
      dateUnavailableServer: '',
      open: ''


    };

  }

  componentWillMount() {
  }


  render() {




    let graphs = {
      circle: <Graph_circle

        icon={<span className='flaticon-cpu5' style={{ marginRight: 5 }} />}
        subTitle={Number(2).toFixed(0)}
        current={parseInt(1)}
        title='CPU'
        mid={{ value: parseInt(11) }}
        high={{ value: parseInt(2) }}
      />,

      sparkline: <div className='host-graph-net'>
        <Sparklines data={[5, 10, 5, 20, 8, 15]}>
          <SparklinesLine color='#33ABA0' />
          <SparklinesSpots />
        </Sparklines>
        <span className='text-status'><i className='fa fa-caret-up text-green' /> {Number((12 / 1024).toFixed(3)) + ' Kbps'}</span>
      </div>
    }


    return (

      <React.Fragment>
        <div className='item-title d-flex align-items-center justify-content-between'>
          <span className='company' ></span>
          <div>

            <span>{9}</span>

          </div>
        </div>
        <hr />
        <div className='d-flex align-items-center justify-content-between last-item-group'>
          <div>
            <a href="#" className='device-ip'> 8 </a>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='server-icon'>
                5
              </div>
              <div>
                <Fragment>
                  <h4>6</h4>
                  <h4>7</h4>
                </Fragment>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-end'>

            <Graph_circle

              icon={<span className='flaticon-random' style={{ marginRight: 5 }} />}
              subTitle={Number(10).toFixed(0)}
              title='1'
              mid={{ value: parseInt(10) }}
              high={{ value: parseInt(1) }}
              current={parseInt(1)}
            />

            <Graph_circle

              icon={<span className='flaticon-random' style={{ marginRight: 5 }} />}
              subTitle={Number(10).toFixed(0)}
              title='2'
              mid={{ value: parseInt(10) }}
              high={{ value: parseInt(1) }}
              current={parseInt(1)}
            />
            <Graph_circle

              icon={<span className='flaticon-data-storage4' style={{ marginRight: 5 }} />}
              subTitle={Number(1).toFixed(0)}
              title='3'
              mid={{ value: parseInt(1) }}
              high={{ value: parseInt(1) }}
              current={parseInt(1)}
            />
            <Graph_circle

              icon={<span className='flaticon-data-storage4' style={{ marginRight: 5 }} />}
              subTitle={Number(1).toFixed(0)}
              title='4'
              mid={{ value: parseInt(1) }}
              high={{ value: parseInt(1) }}
              current={parseInt(1)}
            />
            <div className='title' style={{
              display: 'flex', justifyContent: 'space-between',
              alignItems: 'center'
            }}>

            </div>

          </div>
        </div>
      </React.Fragment>
    )
  }
}
function Graph_circle(props) {
  return (

    <div className='graphs-item'>
      <h4 className='title'>{props.title}</h4>
      <Radial
        low={{ color: '#33ABA0' }} mid={{ color: '#FFC107', value: props.mid.value }}
        high={{ color: '#F44336', value: props.high.value }}
        className="host-graph-perc radial-graph-item-of-group"
        size={70}
        current={props.current}
        total={100}
        thick={8}
      >
        <span className='d-flex align-items-center'>
          {(typeof props.icon) == 'string' ? (
            <i className={props.icon}></i>
          ) : props.icon}
          {props.current}%
        </span>
      </Radial>
      <h4 className='sub-title'>{props.subTitle}</h4>
    </div>

  )
}