import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card } from '@material-ui/core';
import Button from '../../common/Button';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '@common/CommonTable';
import CommonPage from '@common/CommonPage';
import MenuTooltip from '@common/MenuTooltip';
import { filterObj } from '@common/Func';
import moment from 'moment';

import CompanyUser from './CompanyUser';
import UserSolution from './UserSolution';
import DepartmentSolution from './DepartmentSolution';
import SolutionDetail from './SolutionDetail';
import Dialog from '@common/Dialog';
import Store from '@data/Store';
import axios from 'axios';
import CommonDelete from '../../common/CommonDelete';

const { language } = Store;

@observer
export default class SolutionList extends CommonPage {
  constructor() {
    super();
    this.state = {
      solutionHistoric: [],
      userSolution: false,
      userCompany: false,
      departmentSolution: false,
      valuesedit: [],
      openDetail: false,
      solution: [],
      currentPage: 0,
      totalSolutions: 0,
      solutions: [],
      search: '',
      groupUserApprove: false,
      pageApprove: false,
      general_params: {},
      page: 'list',
    };
    this.handleMenu = this.handleMenu.bind(this);
    this.closeContract = this.closeContract.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.searchColumnEvent = this.searchColumnEvent.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    this.loadSolution = this.loadSolution.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }


  loadSolution(approve = true) {
    Store.spinner = true;
    this.setState({pageApprove: approve});

    let payload = {
      id_subcategory: this.props.subcategory || '',
      id_company: this.props.company || '',
      approve
    };

    this.action
      .execute('post', `${this.APIEndpoints.SOLUTION}/list`, '', payload)
      .then((result) => {
        Store.spinner = false;
        this.setState({
          solutions: result.data,
          totalSolutions: result.data.length > 0 && result.data[0].total_count,
        });
      });
    }

  cleanFilters() {
    this.action
      .execute('get', this.APIEndpoints.SOLUTION, 'solutions')
      .then((g) => {
        this.setState({
          solutions: g.data,
          totalSolutions: g.data[0].total_count,
          currentPage: 0,
        });

        //total_count
      });
  }

  componentWillMount() {
    this.loadSolution();

    this.action
      .execute('get', `${this.APIEndpoints.SOLUTION}/group`, 'solutions')
      .then((result) => {
        this.setState({ groupUserApprove: result.data[0].total_group > 0});
      });

    this.action
      .execute('get', `${this.APIEndpoints.GENERAL_PARAMETERS}`, '')
      .then((result) => {
        this.setState({ general_params: result.data});
      });
  }

  closeContract() {
    this.setState({
      userCompany: false,
      userSolution: false,
      departmentSolution: false,
    });
  }

  callPageSelectFilter(page, values, totalPerPage) {
    this.setState({ currentPage: page });

    let payload = {
      ...values,
      id_subcategory: this.props.subcategory || '',
      id_company: this.props.company || '',
      approve: this.state.pageApprove
    };

    this.action
      .execute('post', `${this.APIEndpoints.SOLUTION}/list?limit=${totalPerPage}&offset=${page}`, '', payload)
      .then((res) => {
        this.setState({
          solutions: res.data,
          totalSolutions: parseInt(res.data[0].total_count),
        });
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          this.props.language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }

  onDelete() {
    let { values } = this.state;
    Store.spinner = true;
    this.action.execute(
      'delete',
      this.APIEndpoints.SOLUTION + '/' + values.id_solution,
      'solutions',
      null,
      'id_solution',
      { btn_loading: 'delete-solution-item-' + values.id_solution }
    ).then(() => {
      this.handlePage('list');
      this.loadSolution();
      if (!this.state.pageApprove) {
        this.loadSolution(false)
      }
    });
  }

  handlePage(page, solution) {
    this.setState({ page: page, values: solution});
  }

  handleMenu(index, object) {
    this.action
      .execute(
        'get',
        this.APIEndpoints.SOLUTION + `detail?id=${object.id_solution}`,
        ''
      )
      .then((vv) => {
        object.description = vv.data[0].description;

        if (index === 0) {
          // this.redirect('/dash/solution/view', filterObj(object))
          this.setState({ solution: filterObj(object) });
          this.setState({ openDetail: true });
        } else if (index === 1) {
          this.redirect('/dash/solution/edit', filterObj(object));
        } else if (index === 2) {
          this.handlePage('remove', object);
        } else if (index === 3) {
          this.setState({
            userCompany: true,
            userSolution: false,
            departmentSolution: false,
            valuesedit: object,
          });
        } else if (index === 4) {
          this.setState({
            userSolution: true,
            departmentSolution: false,
            userCompany: false,
            valuesedit: object,
          });
        } else if (index === 5) {
          this.setState({
            departmentSolution: true,
            userSolution: false,
            userCompany: false,
            valuesedit: object,
          });
        } else if (index === 6) {
          this.action
            .execute('post', `${this.APIEndpoints.SOLUTION}/approve`, '', {id: object.id_solution})
            .then(() => this.loadSolution(false));
        }
      });
  }

  searchColumnEvent({ search }) {
    this.setState({ search, currentPage: 0 });

    this.action
      .execute('post', `${this.APIEndpoints.SOLUTION}/list`, '', {search: search})
      .then((res) => {
        this.setState({
          solutions: res.data,
          totalSolutions: res.data[0]?.total_count?parseInt(res.data[0].total_count): 0,
        });
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          this.props.language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }

  render() {
    let { ondonwFile } = this.props;
    const { company } = this.store;
    let { openDetail, solution, solutions } = this.state;
    let options = [
      {
        icon: 'fas fa-eye',
        label: language.VISUALIZE,
        color: 'var(--primary)',
        visible: true,
      },
      {
        icon: 'fas fa-pencil-alt',
        label: language.EDIT,
        color: 'var(--success)',
        visible: this.store.getUserLoged().role != 'solicitant',
      },
      {
        icon: 'fas fa-trash-alt',
        label: language.REMOVE,
        color: 'var(--danger)',
        visible: this.store.getUserLoged().role != 'solicitant',
      },
      {
        icon: 'fas fa-eye',
        label: language.ASSOCIATE_COMPANY,
        color: 'var(--success)',
        visible: this.store.getUserLoged().role != 'solicitant',
      },
      {
        icon: 'fas fa-eye',
        label: language.ASSOCIATE_USER,
        color: 'var(--success)',
        visible: this.store.getUserLoged().role != 'solicitant',
      },
      {
        icon: 'fas fa-eye',
        label: language.ASSOCIATE_DEPARTMENT,
        color: 'var(--success)',
        visible: this.store.getUserLoged().role != 'solicitant',
      },
      {
        icon: 'fas fa-check',
        label: language.APPROVE,
        color: 'var(--primary)',
        visible: this.state.general_params.enabled_approve_knowledge &&
                  this.state.groupUserApprove &&
                  !this.state.pageApprove,
      }
    ];

    let data = [];

    let filterSolutions = [];
    filterSolutions = [...solutions];

    filterSolutions.map((solution) => {
      let resume = solution.description || '';
      let regex = /(<\w+>)([\D]+)(<\/\w+>)/;
      let result = regex.exec(resume);
      let res = '';
      try {
        res = `${result[1]}${result[2]}...${result[3]}`;
      } catch (error) {
        res = '';
      }

      if (this.store.getUserLoged().role == 'solicitant') {
        if (solution.type != 'Tech')
          data.push({
            actions: (
              <MenuTooltip
                options={options.filter((v) => v.visible == true)}
                onChange={(data, index) => this.handleMenu(index, solution)}
              />
            ),
            id: solution.id_solution,
            dt: moment(solution.dt_cad).format('DD/MM/YYYY'),
            hour: moment(solution.dt_cad).format('HH:mm'),
            company: solution.name_company || language.ALL,
            revision: solution.revision,
            user: solution.nameuser,
            author: solution.author,
            department: solution.name_department,
            type:
              solution.type == 'Tech'
                ? language.TECH
                : solution.type == 'Solicitant'
                ? language.SOLICITANT
                : '',

            subcategory: solution.subcategory,
            subject: solution.subject,
            type_origin:
              solution.type_origin == 1
                ? language.KNOWLEDGE_BASE
                : solution.type_origin == 2
                ? language.KNOWN_ERROR
                : '',
          });
      } else {
        data.push({
          actions: (
            <MenuTooltip
              options={options.filter((v) => v.visible == true)}
              onChange={(data, index) => this.handleMenu(index, solution)}
            />
          ),
          id: solution.id_solution,
          dt: moment(solution.dt_cad).format('DD/MM/YYYY'),
          hour: moment(solution.dt_cad).format('HH:mm'),
          company: solution.name_company || language.ALL,
          revision: solution.revision,
          user: solution.nameuser,
          author: solution.author,
          department: solution.name_department,
          type:
            solution.type == 'Tech'
              ? language.TECH
              : solution.type == 'Solicitant'
              ? language.SOLICITANT
              : '',

          subcategory: solution.subcategory,
          subject: solution.subject,
          type_origin:
            solution.type_origin == 1
              ? language.KNOWLEDGE_BASE
              : solution.type_origin == 2
              ? language.KNOWN_ERROR
              : '',
        });
      }
    });

    return (
      <PageLayout
        icon={
          <div
            className="knowledgeBaseIcon"
            style={{ width: 44, height: 44 }}
          />
        }
        back={this.props.openticket == true ? false : true}
        title={language.KNOWLEDGE_BASE}
        subTitle={language.DASHBOARD_PAGE.DM + '/'}
        rightElements={[]}
      >
        <Dialog
          title={language.KNOWLEDGE_BASE}
          open={openDetail}
          maxWidth="xl"
          //  bar = {true}
          // fullScreen = {true}
          onClose={() => this.setState({ openDetail: false })}
        >
          <SolutionDetail
            values={solution}
            ondonwFile={ondonwFile}
            language={language}
          />
        </Dialog>

        {this.state.page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={this.state.values.subject}
          />
        ) : null}

        <div style={{ marginTop: '-1rem' }}>
          {this.state.userSolution == true ? (
            <UserSolution
              closeContract={this.closeContract}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              values={this.state.valuesedit}
            ></UserSolution>
          ) : null}
          {this.state.userCompany == true ? (
            <CompanyUser
              closeContract={this.closeContract}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              values={this.state.valuesedit}
            ></CompanyUser>
          ) : null}
          {this.state.departmentSolution == true ? (
            <DepartmentSolution
              closeContract={this.closeContract}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              values={this.state.valuesedit}
            ></DepartmentSolution>
          ) : null}

          <br></br>
          {
            <CommonTable
              searchColumnEvent={this.searchColumnEvent}
              orderColumn={true}
              onClearAllFilter={this.cleanFilters}
              currentPage={this.state.currentPage}
              isAutomaticPagination={false}
              countTotal={this.state.totalSolutions}
              callPageSelectFilter={(page, values, rowsPerPage, type) =>
                this.callPageSelectFilter(page, values, rowsPerPage, type)
              }
              print
              col={[
                { key: 'actions', label: language.ACTION },
                {
                  key: 'id',
                  label: 'ID',
                  style: { width: 1, textAlign: 'left' },
                },
                { key: 'dt', label: language.DATE },
                { key: 'hour', label: language.HOURS },
                { key: 'company', label: language.COMPANY },
                { key: 'revision', label: language.REVIEW },
                { key: 'user', label: language.USER },
                { key: 'author', label: language.AUTHOR },
                { key: 'department', label: language.DEPARTMENT },
                { key: 'type', label: language.TYPE },
                { key: 'subcategory', label: language.SUBCATEGORY },
                { key: 'subject', label: language.TITLE },
                { key: 'type_origin', label: language.ORIGIN },

                /* {key:'approver', label:'Aprovador'},*/
              ]}
              loadSearch={true}
              data={data}
              searchColumn
              beforeBar={
                this.store.getUserLoged().role != 'solicitant' ? (
                  <>
                    <Button
                      variant="circle"
                      icon="fas fa-plus"
                      color="warning"
                      style={{ zoom: 0.8 }}
                      className="mr-3"
                      zoom={0.8}
                      label={language.ADD_KNOWLEDGE_BASE}
                      onClick={() => this.redirect('/dash/solution/new')}
                    />
                    {this.state.general_params.enabled_approve_knowledge &&
                    this.state.groupUserApprove && (
                      <>
                        <Button
                          variant="circle"
                          icon="fas fa-check"
                          style={{ zoom: 0.8 }}
                          zoom={0.8}
                          className="mr-3"
                          color="primary"
                          primary={true}
                          label={language.APPROVE}
                          onClick={() => this.loadSolution(false)}
                        />
                        <Button
                          variant="circle"
                          icon="fas fa-newspaper"
                          style={{ zoom: 0.8 }}
                          zoom={0.8}
                          className="mr-3"
                          color="primary"
                          label={language.LIST}
                          onClick={() => this.loadSolution(true)}
                        />
                      </>
                    )}
                </>
                ) : (
                  ''
                )
              }
            />
          }
        </div>
      </PageLayout>
    );
  }
}
