import '../../../public/css/pages.css';

import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import SiteList from './SiteList';
import Site from './Site';
import SiteGroup from './SiteGroup';

@observer
export default class SiteContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      value: '',
      page: 'list',
      device: ''
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormSubmit_ = this.onFormSubmit_.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillMount() {
    try {
      this.props.location.state.device;

      this.action.execute('get', this.APIEndpoints.SITE, 'site');
      this.action.execute('get', this.APIEndpoints.SITE + '/group/', 'groups');
      this.action.execute(
        'get',
        this.APIEndpoints.SITE + '/blackList/',
        'blackLists'
      );
      this.action.execute('get', this.APIEndpoints.SITE + '/rel/', 'rels');
      this.action.execute(
        'get',
        this.APIEndpoints.SITE +
          '/contract/' +
          this.props.location.state.device.id_device,
        'contract'
      );

      this.setState({ device: this.props.location.state.device });
      setTimeout(
        function() {
          this.setState({ page: 'list' });
        }.bind(this),
        1000
      );
    } catch (e) {
      this.action.execute('get', this.APIEndpoints.SITE, 'site');
      this.action.execute('get', this.APIEndpoints.SITE + '/group/', 'groups');
      this.action.execute(
        'get',
        this.APIEndpoints.SITE + '/blackList/',
        'blackLists'
      );
      this.action.execute('get', this.APIEndpoints.SITE + '/rel/', 'rels');

      setTimeout(
        function() {
          this.setState({ page: 'group' });
        }.bind(this),
        1000
      );
    }
  }

  onFormSubmit(values) {
    try {
      let data = {
        fk_id_contract: null,
        site_name: values.site_name,
        site_url: values.site_url,
        fk_id_device: this.props.location.state.device.id_device
      };
      this.action.execute('post', this.APIEndpoints.SITE, data, data, data).then(e=>{
        this.redirect('/dash/site', {
          device: this.props.location.state.device
        });
      });
     
    } catch (e) {
      this.action.execute(
        'post',
        this.APIEndpoints.SITE,
        'blackLists',
        values,
        values
      ).then(e=>{
        if (values.site_name != undefined) {
          //this.action.execute('get', this.APIEndpoints.SITE+'/blackList/', 'blackLists');
          this.setState({ page: 'group' });
        } else {
          setTimeout(
            function() {
              this.redirect('/dash/site');
            }.bind(this),
            1000
          );
        }
      });
      
    }
  }

  onFormSubmit_(values) {
    let data = {
      fk_id_group_black_list: values.fk_id_group_black_list,
      fk_id_device: this.props.location.state.device.id_device,
      id_contract: values.id_contract
    };

    this.action.execute('post', this.APIEndpoints.SITE, 'rels', data, data).then(e=>this.redirect('/dash/site', { device: this.props.location.state.device }));
    
  }

  onDelete() {
    let { values } = this.state;
    if (values.id_black_list != undefined) {
      
      this.action.execute(
        'get',
        this.APIEndpoints.SITE + '/delete/' + values.id_group_black_list,
        'rels',
        values.id_rel_group_black_list,
        'url'
      ).then(e=>this.redirect('/dash/site'));
      
    } else {
      this.action.execute(
        'delete',
        this.APIEndpoints.SITE + '/' + values.id_rel_group_black_list,
        'rels',
        values.id_rel_group_black_list,
        'url'
      ).then(e=>this.redirect('/dash/site', { device: this.props.location.state.device }));
      
    }

    // this.redirect('/dash/site',{device :this.props.location.state.device})
    /* 
        let { values } = this.state;
    this.action.execute('delete', this.APIEndpoints.SITE + '/site' + values.id_rel_group_black_list, 'rels',  values.id_rel_group_black_list, 'url');
    this.redirect('/dash/site',{device :this.props.location.state.device})
     */
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  render() {
    let { page, values, device } = this.state;
    let { language, site, groups, blackLists, rels, contract } = this.store;
    
    return (
      <CommonPage
        title={
          <div
            className="logoWhite"
            onClick={() => this.redirect('/dash')}
          />
        }
        showMenuIconButton={true}
        iconAlert={true}
      >
        {page === 'list' ? (
          <SiteList
            contracts={contract}
            onFormSubmit={this.onFormSubmit_}
            rel={rels}
            group={groups}
            site={site}
            device={device}
            redirect={this.redirect}
            language={language}
            handlePage={this.handlePage}
            store={this.store}
          />
        ) : null}
        {page === 'form' ? (
          <Site
            blackLists={blackLists}
            group={groups}
            device={device}
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
        {page === 'remove' ? (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
          />
        ) : null}
        {page === 'group' ? (
          <SiteGroup
            device={device}
            blackLists={blackLists}
            group={groups}
            {...this.state}
            language={language}
            handlePage={this.handlePage}
            onFormSubmit={this.onFormSubmit}
          />
        ) : null}
      </CommonPage>
    );
  }
}
