import React, { Component, useState } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Chip,
} from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import { FaClockO } from 'react-icons/lib/fa';
import moment from 'moment';
import classnames from 'classnames';
import AppBar from '@common/AppBar';
import './ServiceOrderView.css';
import ListColumn from '@common/ListColumn';
import ReactToPrint from 'react-to-print';
import CommonForm from '@common/CommonForm2';
import ReactHighcharts from 'react-highcharts';
import BarBlue from '@images/BarBlue.png';
import { SlaAnalysisTrimestral } from '../../components/GCF/Graphs';
import Logo from '../../../public/images/logo.png';

import DownloadFiles from '../../util/DownloadFiles';

export default class ServiceOrderView extends Component {
  constructor() {
    super();
    this.state = {
      modalJustify: false,
      justify: '',
      description: '',
      os: '',
      id_os: 0,
      modalOs: false,
      text: false,
    };
    this.saveActivity = this.saveActivity.bind(this);
    this.onFormSubmit_ = this.onFormSubmit_.bind(this);
  }

  ondonwFile(id, file, type) {
    DownloadFiles(this.props.APIEndpoints.REQUESTS + '/downloadOs/' + id + '&' + file, file);
  }

  saveActivity(v) {
    this.state.os.justify = v.justify;
    this.state.os.time = v.time;
    this.props.checkBoxSelect(this.state.os, this.state.id_os);

    this.setState({ modalJustify: false });
  }

  onFormSubmit_(values) {
    let {
      id_os,
      fk_id_user_tech,
      start_date,
      start_time,
      end_date,
      end_time,
    } = this.props.serviceOrder;
    values.finalize = true;
    let dt1 = parseInt(
      moment(start_date).format('YYYYMMDD') + moment(start_time).format('HH')
    );
    let dt2 = parseInt(
      moment(values.end_date).format('YYYYMMDD') +
      moment(values.end_time).format('HH')
    );

    if (dt1 > dt2 && values.end_time != null) {
      this.props.store.toggleAlert(
        true,
        'Data e hora não podem ser inferior ao inicio da OS',
        'error'
      );
    } else if (this.props.store.getUserLoged().id != fk_id_user_tech && this.props.store.getUserLoged().role != 'client') {
      /* if(this.props.serviceOrder.tasks.filter(v=>v.checked ==false).length>0){
    this.props.store.toggleAlert(true, 'Existem atividades pendentes!', 'error');

  } else  */
      this.props.store.toggleAlert(
        true,
        'Apenas o Técnico responsável pela OS ou admin podem finalizar!',
        'error'
      );
    } else {
      if (
        moment(values.end_date).isValid() &&
        values.end_time != null &&
        values.end_time != ''
      ) {
        this.props.onFormSubmit(values, id_os);
        this.props.finalizeOS(this.props.serviceOrder);
        this.setState({ modalJustify: false, modalOs: false });
      } else {
        this.props.store.toggleAlert(
          true,
          'Informe data e o horário de finalização da OS ',
          'error'
        );
        /**/
      }
    }
  }
  render() {
    let {
      language,
      store,
      onFormSubmit,
      handlePage,
      redirect,
      checkBoxSelect,
      serviceOrder,
      onAnalyze,
      users,
      finalizeOS,
      saveFiles,
    } = this.props;

    if (!serviceOrder) return <div />;

    //interval time
    let initialBreakTime = moment(serviceOrder.break_initial_time);
    let endBreakTime = moment(serviceOrder.break_end_time);
    let interval = moment(serviceOrder.interval);
    let startTime = moment(serviceOrder.start_time);
    let endTime = moment(serviceOrder.end_time);
    let a = moment(endTime).diff(moment(startTime));

    let g = moment(a).diff(interval);

    let c = moment(a).diff(interval);
    //spent time
    let fields = [
      {
        col: 2,
        name: 'end_date',
        label: language.END_DATE,
        type: 'date',
        required: false,
      },
      {
        col: 2,
        name: 'end_time',
        label: language.END_TIME,
        type: 'time',
        required: false,
      },

      {
        col: 2,
        name: 'transferred',
        label: language.TRANSFERRED,
        type: 'text',
        required: false,
        format: '##:##',
         },

      {
        col: 12,
        name: 'justify',
        label: language.DESCRIPTION,
        type: 'textEditor',
        required: false,
      },
    ];

    let timeSpent = serviceOrder.intervalo;

    let data = {
      logo: Logo,
      name: serviceOrder.company_name,
      typeProject: 'improvement',
      details: [
        {
          label: language.COMPANY,
          value: serviceOrder.company_name,
        },
        {
          label: language.CONTRACT,
          value: serviceOrder.contract_name,
        },
        {
          label: language.TECH,
          value: serviceOrder.tech_name,
        },
        {
          label: 'Solicitante',
          value: serviceOrder.solicitant_name,
        },
        {
          label: language.APPROVER,
          value: serviceOrder.aprovador,
        },
        {
          label: language.OBJECTIVE_OS,
          value: serviceOrder.objective,
        },
      ],
      dates: [
        {
          label: language.INITIAL_DATE,
          value: moment(serviceOrder.start_date).format('L'),
        },
        {
          label: language.END_DATE,
          value: moment(serviceOrder.end_date).format('L'),
        },
        {
          label: language.INITIAL_HOUR,
          value: moment(serviceOrder.start_date).format('LTS'),
        },
        {
          label: language.END_HOUR,
          value:
            serviceOrder.end_date === null
              ? ''
              : moment(serviceOrder.end_date).format('LTS'),
        },
        {
          label: language.TRANSFERRED,
          value:  serviceOrder.transferred ? serviceOrder.transferred : ''
        },
        {
          label: language.INTERVAL,
          value: !serviceOrder.interval ? '00:00' : interval.format('HH:mm:ss'),
        },
        {
          label: language.TIME_SPENT,
          value: timeSpent === 'Invalid date' ? '00:00' : timeSpent,
        },

        /*   {
          label: 'Justificativa',
          value: serviceOrder.justify ? (
            <i
              class="far fa-eye"
              style={{
                color: '#F0B002',
                fontSize: 18,
                marginRight: 10,
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ text: serviceOrder.justify })}
            ></i>
          ) : null,
        },
      */
      ],
    };






    let header = {
      id_order: serviceOrder.id_os,
      solicitant: serviceOrder.solicitant_name,
      company: serviceOrder.company_name,
      tech: serviceOrder.tech_name,
      objective: serviceOrder.objective,
      fk_id_solicitant: serviceOrder.fk_id_solicitant,
      fk_id_user_tech: serviceOrder.fk_id_user_tech,
      fk_id_approver: serviceOrder.approver
    }

    header = JSON.stringify(header);
//    header = btoa(header);
    header = btoa(unescape(encodeURIComponent(header)))

    let token = "/dash/serviceorder/avaliation?t=" + header;

    return (
      <PageLayout
        icon={
          <div className="serviceOrderIcon" style={{ width: 30, height: 30 }} />
        }
        subtitle={language.SERVICE_ORDER_DETAIL}
        title={'Visualização - #' + serviceOrder.id_os}
        subTitle={
          language.DASHBOARD_PAGE.GCF + '/' + language.SERVICE_ORDER + '/'
        }
        rightElements={[
          <div className="d-flex align-items-center justify-content-end py-3">

            {(serviceOrder.approver == this.props.store.getUserLoged().id ||
              serviceOrder.fk_id_solicitant == this.props.store.getUserLoged().id ||
              this.props.store.getUserLoged().role == 'client') &&
              (serviceOrder.closed == true||serviceOrder.status==5) ? (

              <Button
                icon="fas  fa-user-tag"
                variant="normal"
                color="success"
                label={'Avaliar Ordem de Serviço'}
                size={0.9}
                zoom={0.9}
                style={{ fontSize: 14, marginLeft: 5 }}
                onClick={() =>
                  window.open(token, '_blank')
                }
              ></Button>
            ) : <div></div>
            }
            {(serviceOrder.approver == this.props.store.getUserLoged().id ||
              this.props.store.getUserLoged().role == 'client') ? (


              <Chip
                style={{ height: 35 }}
                className="shadown muit-chip muit-chip-order-service"
                label={
                  <div className="d-flex align-items-center justify-content-between">

                    {(serviceOrder._status === 'Finished' ||
                      serviceOrder.status == 1) &&
                      [
                        {
                          action: 'cancel',
                          label: 'CANCEL',
                          icon: 'fas fa-times',
                          color: 'default',
                        },
                        {
                          action: 'close',
                          label: 'APPROVE',
                          icon: 'fas fa-thumbs-up',
                          color: 'success',
                        },
                        {
                          action: 'reopen',
                          label: 'REJECT',
                          icon: 'fas fa-thumbs-down',
                          color: 'danger',
                        },
                      ].map((a, i) => (
                        <div key={i}>
                          <Button
                            className={classnames({ 'mx-1': i == 1 })}
                            size={0.87}
                            style={{ fontSize: i == 0 ? 15 : 14 }}
                            label={language[a.label]}
                            icon={a.icon}
                            color={a.color}
                            onClick={() => onAnalyze(a.action)}
                          />
                        </div>
                      ))}
                  </div>
                }
              />
            ) : (
              <div></div>
            )}
          </div>,
          (serviceOrder.closed == true||serviceOrder.status ==5) ? (
            <Button label="#OS Finalizada" color="success" />
          ) : (
            <Button
              label="Finalizar OS"
              onClick={() => {
                this.setState({ modalOs: true });
                /* if(serviceOrder.end_date != null){
                  //finalizeOS(serviceOrder)
                }else{
                  store.toggleAlert(
                    true,
                    'Favor Editar OS, em seguida informar a data final',
                    'error'
                  );
                }*/
              }}
              variant="normal"
            />
          ),
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.

              return <i id="btnimprimiros" class="fas fa-print"></i>;
            }}
            content={() => this.componentRef}
          />,
        ]}
      >
        <div id="ServiceOrderView" ref={(el) => (this.componentRef = el)}>
          <div className="content">
            <div
              style={{
                backgroundColor: `var(--primary)`,
              }}
              className="content-contract-type content-medium"
            >
              <span>DETALHES</span>
            </div>
            <div className="content-medium content-infos">
              <Grid container spacing={12}>
                {/*------------------------------------------------------DETALHES----------------------------------------------------------------*/}

                <Grid item xs={6}>
                  <ul className="content-info-list">
                    {data.details.map((e) => (
                      <li key={e.label}>
                        <div className="label">{e.label}</div>
                        <div className="value ml-1">{e.value}</div>
                      </li>
                    ))}
                  </ul>
                </Grid>

                <Grid item xs={6} className="content-period">
                  <ul className="content-info-list">
                    {data.dates.map((e) => (
                      <li key={e.label}>
                        <div className="label">{e.label}</div>
                        <div className="value ml-1">{e.value}</div>
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
              {/*------------------------------------------------------JUSTIFICATIVA----------------------------------------------------------------*/}
              <div
                style={{
                  backgroundColor: `var(--primary)`,
                  width: '100%',
                }}
                className="content-contract-type content-medium"
              >
                <span>{language.JUSTIFICATION}</span>
              </div>

              <Grid container spacing={16}>
                <Grid items xs={10}>
                  <i
                    class="far fa-eye"
                    style={{
                      color: '#F0B002',
                      fontSize: 18,
                      marginLeft: 10,
                      marginTop: 10,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      this.setState({ text: serviceOrder.justify })
                    }
                  ></i>

                  <div
                    style={{ marginLeft: 20, height: 'auto', marginBottom: 20 }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: serviceOrder.justify,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {/*------------------------------------------------------DESCRIÇÃO----------------------------------------------------------------*/}
              <div
                style={{
                  backgroundColor: `var(--purple)`,
                  width: '100%',
                }}
                className="content-contract-type content-medium"
              >
                <span>DESCRIÇÃO</span>
              </div>

              <Grid container spacing={16}>
                <Grid items xs={10}>
                  <div style={{ marginLeft: 20, height: 'auto' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: serviceOrder.description,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {/*------------------------------------------------------Atividades----------------------------------------------------------------*/}
              <div
                style={{
                  backgroundColor: `var(--danger)`,
                  width: '100%',
                }}
                className="content-contract-type content-medium"
              >
                <span>ATIVIDADES</span>
              </div>

              <Grid container spacing={16}>
                <Grid items xs={10}>
                  <div
                    style={{
                      width: '90%',
                      alignItems: 'flex-start',
                      marginLeft: 20,
                    }}
                  >
                    {serviceOrder.tasks
                      ? serviceOrder.tasks.map((t, i) => (
                        <div key={i}>
                          {t.justify ? (
                            <i
                              class="far fa-eye"
                              style={{
                                color: '#F0B002',
                                fontSize: 18,
                                marginRight: 10,
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                this.setState({ text: t.justify })
                              }
                            ></i>
                          ) : null}
                          <FormControlLabel
                            control={
                              <Checkbox
                                onClick={
                                  () =>
                                    this.props.store.getUserLoged().id ==
                                      serviceOrder.fk_id_user_tech
                                      ? this.setState({
                                        modalJustify: true,
                                        os: t,
                                        id_os: serviceOrder.id_os,
                                      })
                                      : this.props.store.toggleAlert(
                                        true,
                                        'Apenas o técnico da OS pode acessar essa rotina!',
                                        'error'
                                      )

                                  /* checkBoxSelect(t,serviceOrder.id_os)*/
                                }
                                disabled={t.checked}
                                checked={t.checked}
                              />
                            }
                            label={
                              t.name +
                              '  ' +
                              (t.time ? ` - Tempo Gasto: ${t.time}` : '')
                            }
                          />
                          <div style={{ height: 'auto' }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t.justify,
                              }}
                            />
                          </div>
                        </div>
                      ))
                      : null}
                  </div>
                </Grid>
              </Grid>

              {/*------------------------------------------------------Anexos----------------------------------------------------------------*/}

              <div
                style={{
                  backgroundColor: `var(--primary)`,
                  width: '100%',
                  marginBottom: 20,
                }}
                className="content-contract-type content-medium"
              >
                <span>ANEXOS</span>
              </div>

              <Grid container spacing={16}>
                <Grid items xs={2}>
                  <div>
                    <CommonForm
                      individual
                      spacing={2}
                      gridProps={{
                        alignItems: 'center',
                      }}
                      values={{ files: serviceOrder.attachment }}
                      fields={[
                        {
                          col: 12,
                          type: 'file',
                          name: 'files',
                          label: 'Arquivos',
                          onClick: (f) =>
                            this.ondonwFile(serviceOrder.id_os, f.namefile),
                        },
                      ]}
                      onChangeField={(f, v) => {
                        let files = [];
                        Array.from(v).forEach((file) => {
                          if (file.preview != undefined) {
                            const promise = new Promise((resolve, reject) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = () => {
                                if (!!reader.result) {
                                  resolve(reader.result);
                                } else {
                                  reject(Error('Failed converting to base64'));
                                }
                              };
                            });

                            promise.then((result) => {
                              files.push({
                                file: result,
                                type: file.type,
                                namefile: file.name,
                                size: file.size,
                              });
                            });
                          } else {
                            files.push(file);
                          }
                        });

                        setTimeout(() => {
                          saveFiles({ files: files }, serviceOrder.id_os);
                        }, 900);
                      }}
                      button={{
                        fluid: true,
                        style: { height: 40, width: 100 },
                        className: '-mt-4',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        {this.state.modalJustify == true ? (
          <Dialog
            maxWidth="xs"
            title={language.JUSTIFICATION}
            miniVersion ={true}
            modal={false}
            open={true}
            onClose={() => {
              this.setState({ modalJustify: false });
            }}
          >
            <div>
              <CommonForm
                language={language}
                fields={[
                  {
                    name: 'justify',
                    label: 'Motivo',
                    type: 'textEditor',
                    required: true,
                  },
                  {
                    name: 'time',
                    label: 'Tempo Gasto',
                    type: 'text',
                    required: true,
                    format: '##:##',
                    change(v) {
                      return v.formattedValue;
                    },
                  },
                ]}
                onSubmit={(v) => {
                  this.saveActivity(v);
                }}
                button={{
                  md: 3,
                  offset: { md: 9 },
                  label: language.SAVE,
                  primary: true,
                  style: { margin: 5 },
                }}
              />
            </div>
          </Dialog>
        ) : null}

        {this.state.text != false ? (
          <Dialog
            maxWidth="xl"
            title={language.JUSTIFICATION}
            miniVersion = {true}
            modal={false}
            open={true}
            onClose={() => {
              this.setState({ text: false });
            }}
          >
            <div>
              <CommonForm
                language={language}
                maxWidth="xl"
                values={{ text: this.state.text }}
                fields={[
                  {
                    name: 'text',
                    label: 'Motivo',
                    type: 'textEditor',
                    col: 12,
                    disabled: true,
                    required: true,
                  },
                ]}
              />
            </div>
          </Dialog>
        ) : null}

        {this.state.modalOs == true ? (
          <Dialog
            maxWidth="xl"
            title="Finalizar"
            modal={false}
            miniVersion = {true}
            open={true}
            onClose={() => {
              this.setState({ modalOs: false });
            }}
          >
            <CommonForm
              values={serviceOrder}
              fields={fields}
              onSubmit={this.onFormSubmit_}
            />
          </Dialog>
        ) : null}
      </PageLayout>
    );
  }
}
