import React, { Component, useState } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import DialogModalDetail from './SolutionDetailModal';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Chip,
} from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import { FaClockO } from 'react-icons/lib/fa';
import moment from 'moment';
import classnames from 'classnames';
import AppBar from '@common/AppBar';
import './ServiceOrderView.css';
import ListColumn from '@common/ListColumn';
import ReactToPrint from 'react-to-print';
import CommonForm from '@common/CommonForm2';
import ReactHighcharts from 'react-highcharts';
import BarBlue from '@images/BarBlue.png';
import { SlaAnalysisTrimestral } from '../GCF/Graphs';
import MenuTooltip from '@common/MenuTooltip';
import Header from '@common/Header';
import { filterObj } from '@common/Func';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';

export default class ServiceOrderView extends CommonPage {
  constructor() {
    super();
    this.state = {
      modalJustify: false,
      justify: '',
      description: '',
      os: '',
      id_os: 0,
      modalOs: false,
      page: 0,
      text: false,
      solutionHistoric: [],
      edit: null,
      pageRemove: '',
      idHistoric: 0,
      values: [],
    };
    this.getHistoric = this.getHistoric.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.removeHistoric = this.removeHistoric.bind(this);
  }

  getHistoric(id) {
    this.action
      .execute('get', this.APIEndpoints.SOLUTION + `hist?id=${id}`, '')
      .then((vv) => {
        this.setState({ solutionHistoric: vv.data });
      });
  }
  removeHistoric(solution) {
    this.action.execute(
      'post',
      this.APIEndpoints.SOLUTION + `hist`,
      'solution_historic',
      this.state.values
    );
    this.getHistoric(this.state.values.id_solution);
    this.setState({ pageRemove: '' });
  }

  handleMenu(object) {
    this.setState({ edit: object });
  }

  render() {
    let { language, values, ondonwFile } = this.props;

    let options = [
      { icon: 'fas fa-eye', label: 'Visualizar', color: 'var(--primary)' },
      { icon: 'fas fa-trash', label: 'Remover', color: 'var(--danger)' },
    ];

    let data = [];
    if (this.state.page == 1) {
      this.state.solutionHistoric.map((solution) => {
        data.push({
          actions: (
            <MenuTooltip
              options={options}
              onChange={(data, index) => {
                if (index == 0) {
                  this.handleMenu(solution);
                }
                if (index == 1) {
                  //    this.removeHistoric(solution)
                  this.setState({ pageRemove: 'remove', values: solution });
                }
              }}
            />
          ),
          id: solution.id_solution,
          dt: moment(solution.dt_up).format('DD/MM/YYYY'),
          hour: moment(solution.dt_up).format('HH:mm'),
          company: solution.name_company,
          revision: solution.revision,
          user: solution.changeuser,
          department: solution.name_department,
          type:
            solution.type == 'Tech'
              ? language.TECH
              : solution.type == 'Solicitant'
              ? language.SOLICITANT
              : '',

          subcategory: solution.subcategory,
          subject: solution.subject,
          description: <p dangerouslySetInnerHTML={{ __html: '' }}></p>,
        });
      });
    }

    return (
      <PageLayout
        icon={
          <div className="serviceOrderIcon" style={{ width: 30, height: 30 }} />
        }
        subtitle={''}
        title={language.SOLUTION_DETAIL + ' - #' + values.id_solution}
        back={true}
      >
        <div id="ServiceOrderView" ref={(el) => (this.componentRef = el)}>
          {this.state.edit != null ? (
            <DialogModalDetail
              values={this.state.edit}
              language={language}
            ></DialogModalDetail>
          ) : null}
          <Header
            active={this.state.page}
            btnFilter={[language.KNOWLEDGE_BASE, language.REVIEW]}
            onChange={(e) => {
              if (e == 1) {
                this.getHistoric(values.id_solution);
              }
              this.setState({ page: e });
            }}
          />

          {this.state.page == 0 ? (
            <div className="content">
              <div
                style={{
                  backgroundColor: `var(--primary)`,
                }}
                className="content-contract-type content-medium"
              >
                <span>{language.DETAILS}</span>
              </div>
              <div className="content-medium content-infos">
                <Grid container spacing={12}>
                  {/*------------------------------------------------------DETALHES----------------------------------------------------------------*/}

                  <Grid item xs={5}>
                    <ul className="content-info-list">
                      <li>
                        <div className="label">{language.TITLE}:</div>
                        <div className="value ml-1">{values.subject}</div>
                      </li>
                      <li>
                        <div className="label">{language.COMPANY}:</div>
                        <div className="value ml-1">{values.name_company}</div>
                      </li>
                      <li>
                        <div className="label">{language.DEPARTMENT}:</div>
                        <div className="value ml-1">
                          {values.name_department}
                        </div>
                      </li>
                      <li>
                        <div className="label">{language.TYPE}:</div>
                        <div className="value ml-1">
                          {values.type == 'Solicitant'
                            ? language.SOLICITANT
                            : language.TECH}
                        </div>
                      </li>
                      <li>
                        <div className="label">{language.DATE_REGISTER}:</div>
                        <div className="value ml-1">
                          {moment(values.dt_cad).format('DD/MM/YYYY')}
                        </div>
                      </li>
                      <li>
                        <div className="label">{language.CADASTRE_TIME}:</div>
                        <div className="value ml-1">
                          {moment(values.dt_cad).format('HH:mm')}
                        </div>
                      </li>
                    </ul>
                  </Grid>

                  <Grid item xs={5} className="content-period">
                    <ul className="content-info-list">
                      <li>
                        <div className="label">{language.CATEGORY}:</div>
                        <div className="value ml-1">{values.category}</div>
                      </li>
                      <li>
                        <div className="label">{language.AUTHOR}:</div>
                        <div className="value ml-1">{values.author}</div>
                      </li>
                      <li>
                        <div className="label">{language.CADASTRE_MADE}:</div>
                        <div className="value ml-1">{values.nameuser}</div>
                      </li>{' '}
                      <li>
                        <div className="label">{language.SUBCATEGORY}:</div>
                        <div className="value ml-1">{values.subcategory}</div>
                      </li>
                      <li>
                        <div className="label">{language.REVIEW}:</div>
                        <div className="value ml-1">
                          {values.revision <= 0 ? ' ' : values.revision}
                        </div>
                      </li>
                      <li>
                        <div className="label">{language.LAST_UPDATE}:</div>
                        <div className="value ml-1">
                          {values.dt_up != null
                            ? moment(values.dt_up).format('DD/MM/YYYY HH:mm')
                            : ''}{' '}
                          {values.changeuser}
                        </div>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={2} className="content-period">
                    <br></br>
                    {this.store.getUserLoged().role != 'solicitant' ? (
                      <CommonForm
                        individual
                        spacing={2}
                        btnVisible={false}
                        btnBottom={false}
                        gridProps={{
                          alignItems: 'center',
                        }}
                        values={{ files: values.files }}
                        fields={[
                          {
                            col: 12,
                            type: 'file',
                            name: 'files',
                            label: language.FILES,
                            btnVisible: false,
                            btnBottom: false,
                            onClick: (f) => ondonwFile(f.id_attach, f.name),
                          },
                        ]}
                        button={{
                          fluid: true,
                          style: { height: 40, width: 100 },
                          className: '-mt-4',
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
                {/*------------------------------------------------------JUSTIFICATIVA----------------------------------------------------------------*/}
                <div
                  style={{
                    backgroundColor: `var(--primary)`,
                    width: '100%',
                  }}
                  className="content-contract-type content-medium"
                >
                  <span>{language.DESCRIPTION}</span>
                </div>
                <Grid container spacing={16}>
                  <Grid items xs={10}>
                    <div
                      style={{
                        marginLeft: 20,
                        height: 'auto',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: values.description,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                {/*------------------------------------------------------Anexos----------------------------------------------------------------*/}

                <Grid container spacing={16}>
                  <Grid items xs={6}>
                    <div
                      style={{
                        backgroundColor: `var(--primary)`,
                        width: '100%',
                      }}
                      className="content-contract-type content-medium"
                    >
                      <span>{language._ANALYZE}</span>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        height: 'auto',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: values.text_analyse,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid items xs={6}>
                    <div
                      style={{
                        backgroundColor: `var(--primary)`,
                        width: '100%',
                      }}
                      className="content-contract-type content-medium"
                    >
                      <span>{language.CORRECTION}</span>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        height: 'auto',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: values.text_correction,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={16}>
                  <Grid items xs={6}>
                    <div
                      style={{
                        backgroundColor: `var(--primary)`,
                        width: '100%',
                      }}
                      className="content-contract-type content-medium"
                    >
                      <span>{language.RESOLUTION}</span>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        height: 'auto',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: values.text_resolution,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid items xs={6}>
                    <div
                      style={{
                        backgroundColor: `var(--primary)`,
                        width: '100%',
                      }}
                      className="content-contract-type content-medium"
                    >
                      <span>{language.EFFICIENCY}</span>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        height: 'auto',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: values.text_efficiency,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <div className="content">
              {this.state.pageRemove === 'remove' ? (
                <CommonDelete
                  language={language}
                  closeModal={() => this.setState({ pageRemove: '' })}
                  onDelete={this.removeHistoric}
                  message={language.REVIEW + ' ' + this.state.values.revision}
                />
              ) : null}

              {
                <CommonTable
                  print
                  col={[
                    { key: 'actions', label: language.ACTION },
                    {
                      key: 'id',
                      label: 'ID',
                      style: { width: 1, textAlign: 'left' },
                    },
                    { key: 'dt', label: language.LAST_UPDATE },
                    { key: 'hour', label: language.HOUR },
                    { key: 'company', label: language.COMPANY },
                    { key: 'revision', label: language.REVIEW },
                    { key: 'user', label: language.USER },
                    { key: 'department', label: language.DEPARTMENT },
                    { key: 'type', label: language.TYPE },
                    { key: 'subcategory', label: language.SUBCATEGORY },
                    { key: 'subject', label: language.TITLE },
                    { key: 'description', label: language.DESCRIPTION },

                    /* {key:'approver', label:'Aprovador'},*/
                  ]}
                  data={data}
                  searchColumn
                  orderColumn
                />
              }
            </div>
          )}
        </div>
      </PageLayout>
    );
  }
}
