import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Card, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import CommonPage from '../../common/CommonPage';
import { withStyles } from '@material-ui/core/styles';
import CommonForm from '../../common/CommonForm2';
import { Container } from 'react-grid-system';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import { FaClone } from 'react-icons/lib/fa';
import crypt from '../../util/crypt';
import { filterObj } from '@common/Func';
import DownloadFiles from '../../util/DownloadFiles';

const styles = (theme) => ({
  dialogPaperAlert: {
    minHeight: '20vh',
    maxHeight: '20vh',
    minWidth: '400px',
  },
  dialogPaperAlert_: {
    minHeight: '80vh',
    maxHeight: '20vh',
    minWidth: '400px',
  },
});
let deviceid = 0;
@observer
class DeviceList extends CommonPage {
  constructor() {
    super();
    this.state = {
      modal: false,
      modalsnmp: false,
      token: '',
      listOid: [],
      deviceChecked: [],
      load: true,
      devices: [],
      currentPage: 0,
      page: 0,
      totaldevices: 0,
    };
    this.openModal = this.openModal.bind(this);
    this.closemodal = this.closemodal.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.exportDevices = this.exportDevices.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
  }

  componentWillMount() {
    this.callPageSelectFilter();
  }

  // componentDidMount() {
  //   this.callPageSelectFilter();
  // }

  componentWillReceiveProps(props) {
    const { page } = this.props;
    if (props.page !== page) {
      this.callPageSelectFilter();
    }
  }

  openModal(id) {
    this.setState({ token: crypt(id.toString()), modal: true });
  }

  exportDevices() {
    this.action
      .execute('get', this.APIEndpoints.DEVICE + '?all=excel', 'de')
      .then((b) => {
        let item = b.data;
        setTimeout(() => {
          this.store.loading = false;
          DownloadFiles(
            this.APIEndpoints.REQUESTS +
            '/exportdevice/' +
            this.store.getUserLoged().id +
            '/' +
            item[0].file,
            item[0].file
          );
        }, 3000);
      });
  }

  onStatusChange(id, obj) {
    this.action
      .execute(
        'get',
        `${this.APIEndpoints.DEVICE}/updatestatusdevice/${id}`,
        ''
      )
      .then((res) => {
        obj.status = !obj.status;
      });
  }

  openModalSnmp(id) {
    deviceid = id;

    this.action
      .execute(
        'get',
        `${this.APIEndpoints.SNMP}/devices/${deviceid}`,
        ''
      )
      .then((res) => {
        if ( res.data ) {
          res = res.data;
        }

        let itens = [];
        if (res.length > 0) {
          res.map((f) => {
            itens.push({
              oid: f.device_oids,
              description: f.oid_description,

              trash: (
                <RaisedButton
                  circleButton
                  icon="far fa-trash-alt"
                  label={'remove'}
                  color="danger"
                  style={{ fontSize: 13, width: 21, height: 21 }}
                  onClick={() => this.remove(f.device_oids)}
                />
              ),
            });
          });
        }

        this.setState({
          modalsnmp: true,
          listOid: itens,
        });
      });
  }
  add(values) {
    let listOid = this.state.listOid;

    listOid.push(values);

    this.setState({ listOid: listOid });
  }

  remove(id) {
    let listOid = this.state.listOid;
    listOid = listOid.filter((item) => item.oid !== id);
    this.setState({ listOid });
  }

  callPageSelectFilter(page = 0, values, totalPerPage = 75) {
    this.setState({ currentPage: page });
    let limit = totalPerPage || 75;
    let pageCount = page + 1;
    let offset = page * limit;

    this.action
      .execute(
        'post',
        `${this.APIEndpoints.DEVICE}/list?limit=${totalPerPage}&offset=${offset}&all=true`,
        '',
        values
      )
      .then((res) => {
        this.setState({
          devices: res.data,
          totaldevices: parseInt(res.data[0].total_count),
        });
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          this.store.language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA,
          'success'
        );
      });
  }

  sendValues() {
    let itens = { device: deviceid, itens: this.state.listOid };
    /**
     * Nova url correta para envio é  this.APIEndpoints.SNMP + '/'+ deviceid, no qual por algum motivo a lib snmp-native esta quebrando
     */
    this.action.execute(
      'post',
      this.APIEndpoints.SNMP + 'Create',
      'snmp',
      itens
    );

    this.setState({ modal: false, modalsnmp: false });
  }

  closemodal() {
    this.setState({ modal: false, modalsnmp: false });
  }

  //  let token = crypt((_device.id_device).toString());
  render() {
    let {
      language,
      handlePage,
      classes,
      redirect,

      companies,
      usercon_company,
    } = this.props;

    let { devices } = this.state;

    let col = [
      { key: 'statusdeleted', label: '', style: { width: 1 }, thConfig: true },

      { key: 'id', label: language.ID, style: { width: 1 }, thConfig: true },
      { key: 'name', label: language.DEVICE },
      { key: 'user', label: language.USER },
      { key: 'ip', label: language.IP, style: { width: 10 } },
      { key: 'company', label: language.COMPANY },
      { key: 'patrimony', label: language.PATRIMONY },

      { key: 'dt_cad', label: language.DATE_CAD },
      {
        key: 'type',
        label: language.TYPE,
      },
      {
        key: 'serial_number',
        label: language.SERIAL,
      },
      { key: 'address', label: language.ADDRESS },
      { key: 'install_agent', label: language.INSTALLATION_TYPE },
      { key: 'ci_status', label: language.CMDB_CI_STATUS },
      { key: 'ci_base_oper_status', label: language.CMDB_CI_BASE_OPER_STATUS },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 200, textAlign: 'center' },
      },
    ];

    let colsnmp = [
      { key: 'oid', label: language.ID, style: { width: 20 }, thConfig: true },
      { key: 'description', label: language.DEVICE },
      {
        col: 2,
        key: 'trash',
        label: language.ACTION,
        type: 'text',
        required: true,
        style: { width: 1 },
      },
    ];

    let fields = [
      {
        col: 3,
        name: 'oid',
        label: 'Oid',
        type: 'text',
        required: true,
      },
      {
        col: 5,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        required: true,
      },
    ];

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      icon: 'fas fa-save',
      style: { float: 'right', fontSize: 15 },
    };

    let data = devices.map((obj) => {
      return {
        statusdeleted: (
          <Switch
            checked={obj.status}
            onChange={() => this.onStatusChange(obj.id_device, obj)}
            value="checkedB"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        ),
        id: obj.id_device,
        name: obj.name,
        user: obj.username,
        ip: obj.ip,
        // company: companies.find(company => company.id_company == obj.fk_id_company).name,
        company: obj.namecompany,
        patrimony: obj.patrimony,
        dt_cad: moment(obj.dt_cad).format('LL'),
        type: obj.namedevicetype,
        serial_number: obj.serial_number,
        address: obj.address,
        install_agent: obj.install_agent == true ? 'Agent' : 'Manual',
        ci_status: obj.ci_status,
        ci_base_oper_status: obj.ci_base_oper_status,
        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <RaisedButton
                    circleButton
                    icon="fas fa-network-wired"
                    label={language.SNMP}
                    color="default"
                    style={{
                      fontSize: 12,
                      width: 21,
                      height: 21,
                      marginRight: 10,
                    }}
                    onClick={() => this.openModalSnmp(obj.id_device)}
                  />
                  <RaisedButton
                    circleButton
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    onClick={() => handlePage('edit', obj)}
                  />
                  <RaisedButton
                    circleButton
                    icon="fas fa-info"
                    label={language.TOKEN}
                    color="light"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    className="mx-2"
                    onClick={() => this.openModal(obj.uuid_device)}
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-clone"
                    label={language.CLONE}
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    className="mr-2"
                    color="primaryGradient"
                    onClick={() => handlePage('clone', obj, true)}
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
            {/*<div className="actionIcons">
              <div
                data-tip={language.EDIT}
                className="editIcon pointer"
                style={{ width: 25, height: 25 }}
                onClick={() => handlePage('form', obj)}
              />
              <FaClone
                onClick={() => handlePage('form', obj, 'clone')}
                data-tip={language.CLONE}
                fontSize={24}
                color="var(--secundary)"
                className="pointer"
                style={{ marginRight: 5,  width: 20, height: 20, marginLeft: 5, marginTop: 2 }}
              />
              <div
                data-tip={language.REMOVE}
                className="trashIcon pointer"
                style={{ width: 25, height: 25 }}
                onClick={() => handlePage('remove', obj)}
              />
               <div
                  data-tip="TOKEN"
                  className="infoIcon pointer cardIcon"
                  onClick={() => this.openModal(obj.id_device)}
                />
              <ReactTooltip />
            </div>*/}
          </React.Fragment>
        ),
      };
    });
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          searchColumnEvent={this.callPageSelectFilter}
          columns={col}
          data={data}
          showHeaderToolbar
          index="DeviceList"
          orderColumn
          currentPage={this.state.currentPage}
          isAutomaticPagination={false}
          countTotal={this.state.totaldevices}
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          onClearAllFilter={() => this.callPageSelectFilter()}

          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.callPageSelectFilter(page, values, rowsPerPage, type)
          }
          beforeBar={[
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.ADD_NEW_DEVICE}
              primary={true}
              onClick={() => handlePage('new')}
            />,
            <RaisedButton
              circleButton
              icon="fas fa-file-csv"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.EXPORT_DEVICES}
              primary={true}
              onClick={() => this.exportDevices()}
            />,
          ]}
        />

        <Dialog
          open={this.state.modal}
          PaperProps={{ classes: { root: classes.dialogPaperAlert_ } }}
          onClose={() => this.closemodal()}
          maxWidth="600xs"
          title={language.TOKEN}
        >
          <span
            className="font color-lead"
            style={{ fontSize: '1.5em', fontWeight: 100 }}
          >
            {this.state.token}
          </span>
        </Dialog>

        <Dialog
          open={this.state.modalsnmp}
          onClose={() => this.closemodal()}
          maxWidth="600xs"
          title={language.SNMP_LIST}
        >
          <div style={{ height: '600px' }}>
            <Card>
              {' '}
              <CommonForm fields={fields} onSubmit={this.add} button={button} />
            </Card>
            <br></br>

            <CommonTable
              title={''}
              language={language}
              columns={colsnmp}
              data={this.state.listOid}
              showHeaderToolbar
              index="DeviceList"
              searchColumn
              orderColumn
            />
            <RaisedButton
              circleButton
              icon="fas fa-edit"
              label={language.SAVE}
              color="default"
              style={{ fontSize: 12, width: 21, height: 21 }}
              onClick={() => this.sendValues()}
            />
          </div>
        </Dialog>
      </Fragment>
    );
  }
}
DeviceList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DeviceList);
