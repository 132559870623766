import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card, Chip } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonTable from '../../common/CommonTable';
import Store from '@data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';

@observer
export default class GeneralPermissionList extends Component {
  render() {
    let {
      language,
      handlePage,
      handleOpen,
      handleClose,
      redirect,
      departments,
      users,
      callPageSelectFilter,
      totaldepartment,
      currentPage,
    } = this.props;

    let col = [
      { key: 'role', label: language.ROLE },
      { key: 'company_name', label: language.COMPANY },
      { key: 'country_name', label: language.COUNTRIES },
      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40 },
        jc: 'center',
      },
    ];

    let data = departments.map((obj) => {
      obj.country_name = obj.country_name.map((country) => {
        return translateFromPtBR(country, Store.language);
      });
      const countryNames = obj.country_name.join(', '); // Une os nomes dos países com vírgula
      return {
        role: obj.role === 'country' ? language.COUNTRY : obj.role === 'tech' ? language.TECH : obj.role === 'solicitant' ? language.SOLICITANT : obj.role === 'scm' ? language.SCM: obj.role,
        company_name: obj.company_name,
        country_name: countryNames,
        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <RaisedButton
                    circleButton
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    onClick={() => handlePage('edit', obj)}
                    className="mr-2"
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });
    let { configurationProps = {} } = this.props;

    return (
      <Fragment>
        <CommonTable
          columns={col}
          data={data}
          index="DepartmentList"
          orderColumn
          searchColumnEvent={callPageSelectFilter}
          countTotal={totaldepartment}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          language={language}
          paginationTop={false}
          searchColumn={true}
          loadSearch={true}
          currentPage={currentPage}
          beforeBar2={configurationProps.control}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page)
          }
          beforeBar={
            <RaisedButton
              circleButton
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              onClick={() => handlePage('new')}
            />
          }
        />
      </Fragment>
    );
  }
}
