import React, {Fragment, useEffect, useState} from 'react';
import Store from '@data/Store';
import Button from '@common/Button';
import classnames from 'classnames';
import {Menu, MenuItem} from '@material-ui/core';
import moment from 'moment';

export default function({
  onChange=()=>null,
  value=parseInt(moment().format('M')),
  disabled=false,
  disableArrows=false
}){
  function giveMarginLeftMonth(res) {
    parseInt(res)
    switch(true){
      case res <= 1366:
        return -240
      case res <= 1920:
        return -320
      case res <= 2560:
        return -490
      default:
        return -500
      
    }
  }
  function giveMarginTopMonth(res) {
    parseInt(res)
    switch(true){
      case res <= 800:
        return 30
      case res <= 1080:
        return 30
      default:
        return 40
    }
                
  }

  let {language} = Store;
  let [monthSelected, setMonthSelected] = useState('');
  let [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    if (value) {
      setMonthSelected(value);
    }
  }, [value])

  //console.log('carregando month', monthSelected);

  return(
    <Fragment>
      <div className='d-flex align-items-center'>
        { !disableArrows && <Button
          icon='fas fa-chevron-left'
          label={language.PREVIOUS}
          color='success'
          variant='circle'
          outlined
          style={{
            border:'none',
            fontSize:12
          }}
          onClick={()=>{
            
            setMonthSelected(monthSelected => {
              //console.log('testeeee', monthSelected - 1);
              if (monthSelected == 0) {
                return;
              }
              onChange(monthSelected-1)
              return monthSelected - 1
            })
          }}
          disabled={disabled}
        />}
        <h3
          aria-owns={anchorEl ? 'long-menu' : undefined}
          onClick={evt => setAnchorEl(evt.currentTarget)}
          className='label-month-filter px-3'
          style={{width:113, textAlign:'center'}}
        >
          { monthSelected ? 
            moment(`01-${monthSelected.toString().padStart(2, '0')}`, 'dd-MM').format('MMMM') :
            moment().format('MMMM')
          }
        </h3>
        { !disableArrows && <Button
          icon='fas fa-chevron-right'
          label={language.NEXT}
          color='success'
          variant='circle'
          outlined
          style={{
            border:'none',
            fontSize:12
          }}
          onClick={()=>{
            setMonthSelected(monthSelected=>{
              if (monthSelected == 11) {
                return;
              }
              onChange(monthSelected+1)
              return monthSelected+1;
            });

          }}
          disabled={disabled}
        />}
      </div>
      <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={!!anchorEl && !disabled}
          onClose={()=> setAnchorEl(null)}
          style={{marginLeft:giveMarginLeftMonth(window.screen.width),marginTop:giveMarginTopMonth(window.screen.height)}}
            
        >
          {language.DATE_LANG.monthLongList.map((option,i) => (
            <MenuItem 
              key={option} 
              onClick={()=>{
                setAnchorEl(null);
                onChange(i+1)
                setMonthSelected(i+1)
              }}
              style={
                monthSelected==(i+1)?{fontWeight:'bold'}:{}
              }
            >
              {option}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  )
}