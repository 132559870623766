import React from 'react';
import ReactHighcharts from 'react-highcharts';
/*---------------------------common-------------------------------*/
import AppBar from '@common/AppBar2';
/*---------------------------material-ui-------------------------------*/
import {Card} from '@material-ui/core';
/*---------------------------components-------------------------------*/
import { OKREvolution } from './Graph';
import Store from '@data/Store';

export default function ({ data = [] }){
	/*
		------------------------formato---------------------------
		data:[
			{name:'Area 1', y:34},
			{name:'Area 2', y:76},
			{name:'Area 3', y:23},
			{name:'Area 4', y:11},
			{name:'Area 5', y:56},
			{name:'Area 6', y:32},
			{name:'Area 7', y:90},
		]
	*/
	const { language } = Store;
	return(
		<Card id='OKREvolution' className='h-100'>
			<AppBar title={language.OKR_EVOLUTION} subTitle={`(${language.BY_AREA})`} />
			<div className='content-medium pt-0'>
				<ReactHighcharts config={OKREvolution({data})}/>
			</div>
		</Card>
	)
}