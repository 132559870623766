import React, { memo } from 'react';
import classnames from 'classnames';
import { TableRow, TableCell } from '@material-ui/core';
// import lodash from 'lodash';

export default memo(
  ({ e, col, i, columnsSelectedToSee, allowSelectColumns }) => {
    if (allowSelectColumns) {
      let e_aux = {};
      if (e) {
        columnsSelectedToSee.map((c) => {
          e_aux[c] = e[c];
        });
        e = e_aux;
      }
    }

    return (
      <TableRow key={'tbody-' + i} {...e.ConfigCell}>
        {Array.isArray(e)
          ? e.map((e) => (
              <TableCell {...e} className={classnames('td', e.className || '')}>
                {e.label}
              </TableCell>
            ))
          : Object.values(e)
              .slice(!!e.ConfigCell ? 1 : 0)
              .map((label, i) => (
                <TableCell
                  className="td"
                  key={'TableCell-' + i}
                  style={
                    col[i] != undefined && (col[i].thConfig || col[i].tdStyle)
                      ? {
                          ...col[i].style,
                          ...col[i].tdStyle,
                        }
                      : {}
                  }
                  {...((e.ConfigCell || {}).cols
                    ? e.ConfigCell.cols['col_' + i] || {}
                    : {})}
                >
                  {label}
                </TableCell>
              ))}
      </TableRow>
    );
  } /*, function(prev, next){
	console.log(prev.e, next.e);

	console.log(lodash.isEqual({actions:prev.e.actions}, {actions:next.e.actions}));

	console.log(lodash.isEqual({
	 	actions:prev.e.status,
		company:prev.e.company, 
		contract:prev.e.contract, 
		contract_value:prev.e.contract_value, 
		end_date:prev.e.end_date, 
		num:prev.e.num, 
		type:prev.e.type, 
	}, {
		actions:next.e.status,
		company:next.e.company, 
		contract:next.e.contract, 
		contract_value:next.e.contract_value, 
		end_date:next.e.end_date, 
		num:next.e.num, 
		type:next.e.type, 
	}))
	console.log(lodash.isEqual(prev.e, next.e))
}*/
);
