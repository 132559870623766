import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import RaisedButton from '../../common/RaisedButton';
import CommonForm from '../../common/CommonForm';
import CommonTable from '../../common/CommonTable';
import { FaClose } from 'react-icons/lib/fa';
import Dialog from '@common/Dialog';
import {Typography} from '@material-ui/core';
import CommonPage from '../../common/CommonPage';

@observer
export default class AddActivitiesForm extends CommonPage {
  constructor() {
    super();
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormTaskSubmit = this.onFormTaskSubmit.bind(this);
    this.state = {
      sev:null
    };
  }

  onFormSubmit(values) {
    this.props.onFormSubmit(
      Object.assign(values, { id_os: this.props.serviceOrder.id_os })
    );
    
  }

  componentWillMount(){
    this.action.execute(
      'get',
      this.APIEndpoints.SERVICE_ORDER + '/' + this.props.serviceOrder.id_os,
      's'
    ).then(v=>{
     this.setState({sev:v.data})
    })
   }
  
  onFormTaskSubmit(values) {
    
         values.id_os= this.props.serviceOrder.id_os;
          this.action.execute(
          'post',
          `${this.APIEndpoints.SERVICE_ORDER}/task`,
          'ser',
            values).then(v=>{
              this.setState({sev:v.data})
             })
             this.props.handleModal();
   
  }


  render() {
    let {
      handleModalThirdLevel,
      handleModal,
      open,
      openThirdLevel,
      onTaskSubmit,
      urgencies,
      priorities,
      impacts,
      handlePage,
      language,
      serviceOrder,
      tasks
    } = this.props;

    if (!serviceOrder) return <div />;

    let col = [
      { key: 'fk_id_catalog_task', label: language.ID, style: { width: 10 } },
      { key: 'name', label: language.NAME }
    ];
    
    let data =(this.state.sev&&this.state.sev.tasks.map(e=>({fk_id_catalog_task:e.fk_id_catalog_task, name:e.name}))||[]);

    let fields = [
      {
        span: 12,
        name: 'activities',
        label: '',
        visibleTitle:false,
        type: 'addItem',
        tableList: (
          <CommonTable
           
            data={data}
            columns={col}
            title={
              <div className='d-flex align-items-center'>
                <RaisedButton
                  circleButton
                  label={language.ADD_ACTIVITIES}
                  icon='fas fa-plus'
                  onClick={handleModal}
                  className='mr-2'
                />
                <Typography variant="h6" className='font' style={{color:'white', paddingTop:0, fontWeight:100, marginLeft:10}}>{language.ADD_ACTIVITY}</Typography>
              </div>
            }
          />
        )
      }
    ];

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true
    };

    let taskOptions = tasks
      .filter(
        t =>
          !data
            .map(tk => tk.fk_id_catalog_task)
            .includes(parseInt(t.id_catalog_task))
      )
      .map(t => ({ value: t.id_catalog_task, text: t.name }));

    let fieldsTask = [
      {
        span: 12,
        name: 'fk_id_catalog_task',
        type: 'select',
        label: language.SELECT_ACTIVITIES,
        options: taskOptions
      }
    ];

    let buttonTask = {
      md: 12,
      label: language.ADD_ACTIVITIES,
      icon:'fas fa-save',
      style:{fontSize:18},
      color:'success'
    };

    let activitiesDialog = (
      <Dialog
        open={open}
        onClose={()=>handleModal()}
        miniVersion = {true}
        title={language.ADD_THIRD_LEVEL_CATEGORY}
        monitor_scroll
      >
        
        <CommonForm
          language={language}
          fields={fieldsTask}
          button={buttonTask}
          onFormSubmit={this.onFormTaskSubmit}
          buttonProps={
            <RaisedButton
              circleButton
              color='primaryGradient'
              icon='fas fa-plus'
              className='mx-2'
              onClick={this.props.handleModalThirdLevel}
              label={language.REGISTER_THIRD_LEVEL_CATEGORY}
            />
          }
        />
      </Dialog>
    );

    //task form
    let approvalOptions = [
      { value: true, text: language.YES },
      { value: false, text: language.NOT }
    ];

    let impactOptions = impacts.map(i => ({
      value: i.id_table,
      text: i.description
    }));

    let countryComplexity = [
      { value: 'high', text: language.TICKET_PRIORITY.HIGH },
      { value: 'medium', text: language.TICKET_PRIORITY.MEDIUM },
      { value: 'low', text: language.TICKET_PRIORITY.SMALL }
    ];

    let priorityOptions = priorities.map(p => ({
      value: p.id_table,
      text: p.description
    }));

    let urgencyOptions = urgencies.map(u => ({
      value: u.id_table,
      text: u.description
    }));

    let fieldsRegisterTask = [
      {
        span: 4,
        name: 'name',
        label: language.NAME,
        type: 'text',
        required: true
      },
      {
        span: 4,
        name: 'time_sla',
        label: language.SLA_TIME,
        type: 'number',
        required: true
      },
      {
        span: 4,
        name: 'liberation',
        label: language.NEEDS_APPROVAL,
        type: 'select',
        options: approvalOptions
      },
      {
        span: 4,
        name: 'id_impact',
        label: language.IMPACT,
        type: 'select',
        options: impactOptions,
        required: true
      },
      {
        span: 4,
        name: 'complexity',
        label: language.COMPLEXITY,
        type: 'select',
        options: countryComplexity,
        required: true
      },
      {
        span: 4,
        name: 'id_priority',
        label: language.PRIORITY,
        type: 'select',
        options: priorityOptions,
        required: true
      },
      {
        span: 4,
        name: 'id_urgency',
        label: language.URGENCY,
        type: 'select',
        options: urgencyOptions,
        required: true
      },
      {
        span: 8,
        name: 'description',
        label: language.DESCRIPTION,
        type: 'text',
        multiLine: true,
        required: true
      }
    ];

    let buttonRegisterTask = {
      md: 12,
      label: language.SAVE,
    };

    let thirdLevelDialog = (
      <Dialog
        open={openThirdLevel}
        onClose={()=>handleModalThirdLevel()}
        title={language.REGISTER_THIRD_LEVEL_CATEGORY}
        maxWidth='md'
        monitor_scroll
      >
        <CommonForm
          language={language}
          button={buttonRegisterTask}
          onFormSubmit={onTaskSubmit}
          fields={fieldsRegisterTask}
        />
      </Dialog>
    );

    return (
      <div>
        <CommonForm
          language={language}
          fields={fields}
          button={button}
          loading={['service-order-put', 'service-order-post']}
          onFormSubmit={() => this.props.redirect('/dash/serviceorder')}
        />
        
        {activitiesDialog}
        {thirdLevelDialog}
      </div>
    );
  }
}
