import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Switch } from '@material-ui/core';
import { Card, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
@observer
export default class EffortList extends CommonPage {

  constructor() {
    super();
    this.state = {
      openTest: false

    }


    this.openmail = this.openmail.bind(this);
  }

  openmail() {
    this.setState({ openTest: true })
  }
  render() {
    let { language, handlePage, redirect, onDelete } = this.props;


    let col = [
      {
        key: 'id_effort_list',
        label: language.ID,
        thConfig: true,
        style: { width: 1, textAlign: 'center', paddingRight: 0 }
      },
      {
        key: 'value',
        label: language.VALUE,
        thConfig: true,
        style: { width: 120 }
      },
      {
        key: 'hour',
        label: language.AMOUNT_OF_HOURS,
        thConfig: true,
        style: { width: 120 }
      },
      {
        key: 'action',
        label: language.ACTION,
        thConfig: true,
        style: { width: 100, textAlign: 'center' }
      }
    ];
    let data = (this.props.effort_value || []).map(obj => {
      return {
        id_effort_value: obj.id_effort_value,
        value: obj.value,
        hour: obj.hour.substring(0, 2) + ":" + obj.hour.substring(2, 4),
        action: (
          <React.Fragment>
            <Chip
              className='mx-2 muit-chip'
              label={
                <div className='d-flex align-items-center justify-content-between'>
                  <RaisedButton
                    circleButton
                    icon='fas fa-edit'
                    label={language.EDIT}
                    color='default'
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    className='mr-2'
                    onClick={() => handlePage('form', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        )
      };
    });
    let button = { label: language.SAVE };
    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        {this.state.openTest ?
          <Dialog monitor_scroll title='Informe seu Email' modal={false} open={true}    >

            <CommonForm

              fields={fieldstest}
              onSubmit={(e) => this.testeMail(e)}
              button={button}
            />

          </Dialog> : null}

        <CommonTable
          title={''}
          language={language}
          columns={col}
          index='MailList'
          data={data}
          searchColumn orderColumn
          width='100%'
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}

        />

      </Fragment>
    );
  }
}
