import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';
import moment from 'moment';
import Store from '@data/Store';
import Constants from '../../data/Constants';
export default class CompanyTime extends Component {
  constructor() {
    super();
    this.state = {
      companyId: null,
      valores: null,
      tiporequest: false,
      timeinicio: null,
      timefim: null

    };
    this.onChangeField = this.onChangeField.bind(this);
  }
  componentDidMount() {
    let { values } = this.props;
    try {
      this.setState({ timeinicio: values.timeinicio })
    } catch (t) { }
  }

  onChangeField(field, value) {
    if (['timeinicio', 'timefim'].includes(field.name)) {
      this.setState({ [field.name]: value });
    }
    if (field.name === 'id_company') {
      this.setState({
        companyId: value
      });
    } else if (field.name === 'tipo') {
      if (value == 'REQUEST') {
        this.setState({
          tiporequest: true
        });
      } else {
        this.setState({
          tiporequest: false
        });
      }

    }
  }
  onFormSubmit = (v) => {
    try {
      let { timeinicio, timefim } = this.state;
      let { onFormSubmit, handlePage } = this.props;
      var ti = parseInt(moment(timeinicio).format('H'));
      var tf = parseInt(moment(timefim).format('H'));


      if ((Math.abs(tf - ti) >= 1) || !timeinicio || !timefim) {
        onFormSubmit(v);
        handlePage('list');
      } else {
        Store.toggleAlert(true, 'Corrija o horário final', 'error');
      }
    } catch (e) { }
  }

  render() {
    let {
      language,
      handlePage,
      onFormSubmit,
      values,
      companies,
      contracts,
      gmt,
      holiday
    } = this.props;

    let { valores } = this.state;


    let companyId = values ? values.fk_id_company : this.state.companyId;

    let listsemana = [
      { value: 'domingo', label: language.DATE_LANG.dayList[0] },
      { value: 'segunda', label: language.DATE_LANG.dayList[1] },
      { value: 'terca', label: language.DATE_LANG.dayList[2] },
      { value: 'quarta', label: language.DATE_LANG.dayList[3] },
      { value: 'quinta', label: language.DATE_LANG.dayList[4] },
      { value: 'sexta', label: language.DATE_LANG.dayList[5] },
      { value: 'sabado', label: language.DATE_LANG.dayList[6] }
    ];

    let listferiados = holiday.map(h => ({ value: h.id_holiday, label: h.name + ' - ' + moment(h.data).format('DD-MM-YYYY') }))


    let listmodulo = [
      { value: 'GP', label: language.GP },
      { value: 'GSD', label: language.GSD },
      { value: 'REQUEST', label: language.REQUESTERSDASH }
    ];

    let listCompanies = companies.map(obj => ({
      value: obj.id_company,
      label: obj.name
    }));

    let listGmt = gmt.map(obj => ({
      value: obj.id_gmt,
      label: obj.city + '-' + obj.time
    }));

    let listContract = [];
    if (companyId) {
      if (companyId == 1) {
        listContract = contracts
          .filter(c => c.fk_id_company === companyId)
          .map(ctr => ({ value: ctr.id_contract, label: ctr.name }));
      } else {
        listContract = contracts
          .filter(c => c.fk_id_out_company === companyId)
          .map(ctr => ({ value: ctr.id_contract, label: ctr.name }));

      }
    }

    try {
      values.timefim = new Date(moment().format('YYYY-MM-DD') + 'T' + this.props.values.hr_fim + "")
      values.timeinicio = new Date(moment().format('YYYY-MM-DD') + 'T' + this.props.values.hr_start + "")
    } catch (e) { }


    let fields = [];
    if (values) {
      if (values.tipo === "REQUEST") {
        this.state.tiporequest = values.tipo
      }
    }

    fields = !this.state.tiporequest ? [
      {
        col: 4,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        required: true,
        options: listCompanies,
        disabled: values ? true : false
      },
      {
        col: 2,
        name: 'tipo',
        label: language.MODULE,
        type: 'select',
        required: true,
        options: listmodulo,
        disabled: values ? true : false
      },
      {
        col: 4,
        type: 'autocomplete',
        name: 'fk_id_contract',
        label: language.CONTRACTS,
        method: 'POST',
        route: `${Constants.APIEndpoints.CONTRACT}/filter`,
        filterAll: { fk_id_out_company: companyId },
        fieldquery: 'search',
        textlabel: `Contrato`,
        textinit: values ? values.contract_name : 'Contrato',
        fieldvaluedb: 'id_contract',
        fieldlabeldb: 'name',
        idinit: values ? values.fk_id_contract : 0,
        routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
        textlabel: language.CONTRACTS,
        disabled: values || !companyId ? true : false
      },
      {
        col: 3,
        name: 'timeinicio',
        label: language.INITIAL_HOUR,
        type: 'time',
        required: true
      },
      {
        col: 3,
        name: 'timefim',
        label: language.END_TIME,
        type: 'time',
        required: true
      },

      {
        col: 3,
        name: 'fk_id_gmt',
        label: language.TIMEZONE,
        type: 'select',
        required: true,
        options: listGmt
      },
      {
        col: 6,
        name: 'semana',
        label: language.WEEK,
        type: 'listCheckbox',
        required: true,
        options: listsemana
      },
      {
        col: 6,
        name: 'feriados',
        label: language.HOLIDAYS,
        type: 'listCheckbox',
        required: false,
        options: listferiados
      }
    ] :
      [
        {
          col: 6,
          name: 'id_company',
          label: language.COMPANY,
          type: 'select',
          required: true,
          options: listCompanies
          , disabled: values ? true : false
        },
        {
          col: 6,
          name: 'tipo',
          label: language.MODULE,
          type: 'select',
          required: true,
          options: listmodulo
          , disabled: values ? true : false

        }, {
          col: 3,
          name: 'timeinicio',
          label: language.INITIAL_HOUR,
          type: 'time',
          required: true
        },
        {
          col: 3,
          name: 'timefim',
          label: language.END_TIME,
          type: 'time',
          required: true
        },

        {
          col: 3,
          name: 'fk_id_gmt',
          label: language.TIMEZONE,
          type: 'select',
          required: true,
          options: listGmt
        },
        {
          col: 6,
          name: 'semana',
          label: language.WEEK,
          type: 'listCheckbox',
          required: true,
          options: listsemana
        },
        {
          col: 6,
          name: 'feriados',
          label: language.HOLIDAYS,
          type: 'listCheckbox',
          required: false,
          options: listferiados
        }
      ];

    let button = { label: language.SAVE };

    return (
      <Dialog
        modal={false}
        open={true}

        onClose={() => handlePage('list')}
        title={values ? language.EDIT : language.ADD}
        maxWidth='lg'
      >

        <Card className='content'>
          <CommonForm
            sensitive
            values={values ? Object.assign(values || {}, { semana: (values || {}).semanadetail.split(',') }) : undefined}
            fields={fields}
            onSubmit={(v) => this.onFormSubmit(v)}
            onChangeField={this.onChangeField}
            button={button}
          />
        </Card>

      </Dialog>
    );
  }
}
