 
import styled from 'styled-components';
import PropTypes from 'prop-types';
// const drawerWidth = 240;
import { withStyles } from '@material-ui/core/styles';
import importantDevices from 'material-ui/svg-icons/action/important-devices';
  
export const useStyles = theme => ({
  root: {
    //display: 'flex',
    //position:'relative',
   // overflow:'hidden'
    
    
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#FFFFFF',
    minHeight: '42px !important',
  },
  toolbarIcon: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: '40px !important',
  },
  
 
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 26,
  },
  drawerPaper_top: {
    position: 'relative',
    whiteSpace: 'nowrap',
    marginTop:'0px',
    // width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'linear-gradient(180deg, #01C2B3 0%, #019FC2 100%)',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    marginTop:'64px',
    // width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'var(--slideBarTheme)',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width:60,
    width:52,
    [theme.breakpoints.up('sm')]: {
      width:52,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
  listItem: {
    padding: 0,
  },
  icon: {
    color: '#019EC2',
    marginTop: 10,
    marginRight: 10,
  },
  itemText: {
    textTransform: 'uppercase',
    color: '#FFF',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  subitemText: {
    textTransform: 'uppercase',
    color: 'var(--colorIconButton)',
    fontSize: '10px',
    // paddingTop: 10,
  },
  iconButton: {
    backgroundColor: '#FFF',
    marginLeft: '5px',
    marginRight: '20px',

    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
});



export const BoxBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content : flex-start;
`;

export const ImgBox = styled.div`
  background-color: #fff;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

export const BoxList = styled.div`
  width: 168px;
  display: flex;
  align-items: center;
`;

export const TextList = styled.p`
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  white-space: normal;
`;
