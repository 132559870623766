import React, { useState } from 'react';
import Store from '@data/Store';
import { TextField } from '@material-ui/core';
import ServicePortalModalWrapper from './ServicePortalModalWrapper';

export default function ServicePortalModalReopenTicket({ isOpen, language, onClose, onConfirm }) {

    const [value, setValue] = useState();
    const [error, setError] = useState(false);

    const handleConfirm = () => {
        if (value) {
            onConfirm && onConfirm(value);
            setError(false);
            setValue('');
        }
        else {
            setError(true);
            Store.toggleAlert(true, language.INFORM_REASON, 'error');
        }
    }

    return (
        <ServicePortalModalWrapper
            isOpen={isOpen}
            title={language.REOPEN_TICKET}
            onClose={onClose}
            onConfirm={handleConfirm}
            buttonCloseLabel={language.CLOSE}
            buttonConfirmLabel={language.SAVE}
        >
            <div className='tw-w-full'>
                <TextField
                    autoFocus
                    fullWidth
                    multiline
                    minRows={4}
                    type="text"
                    variant="outlined"
                    error={error}
                    label={language.JUSTIFICATION}
                    InputLabelProps={{ shrink: true }}

                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                />
            </div>
        </ServicePortalModalWrapper>
    )
}
