import React from 'react';
import Dialog from '@common/Dialog';
import Table from '../Table';
import {projectList} from '../jsonFormat';

export default function({open, onClose, data:_data=[], users,  type, redirect}){
  
   let data = _data.map(e=> projectList({e, activitiesTags:[], users}));
 
   return(
      <Dialog
         open={open}
         onClose={onClose}
         maxWidth='xl'
         bar={false}
			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
         }}
         
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
      >

         <Table 
            data={data} 
             isBlock_1
             type
             onClose={onClose}
            redirect={id => {
               redirect('/dash/gp/'+id)
            }}
         />
      </Dialog>
   )
}