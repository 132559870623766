import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Toggle } from '@material-ui/core';
import { Crad, Chip } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import Switch from '@material-ui/core/Switch';
import ReactTooltip from 'react-tooltip';
import CommonTable from '@common/CommonTable';
import { FaUserSecret, FaWrench, FaUser } from 'react-icons/lib/fa';
import Button from '@common/Button';
import { filterObj } from '@common/Func';
import { CSVLink } from 'react-csv';
import moment from 'moment';

@observer
export default class UserList extends Component {
  constructor() {
    super();
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onStatusChange(toChange) {
    toChange.status = !toChange.status;
    this.props.onFormSubmit(toChange);
  }

  render() {
    let { language, handlePage, role, redirect, APIEndpoints } = this.props;
    let csvData = [];

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 5, textAlign: 'center' },
      },
      {
        key: 'status',
        label: language.STATUS,
        style: { width: 10, textAlign: 'center' },
      },
      {
        key: 'name',
        label: language.NAME,
        style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
      },
      {
        key: 'email',
        label: language.EMAIL,
        style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' },
      },
      {
        key: 'phone',
        label: language.PHONE,
        style: { width: 180, textAlign: 'center' },
      },
      {
        key: 'company',
        label: language.COMPANY,
        style: { maxWidth: 150, textAlign: 'center' },
      },

      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data = [];
    
    if (role === 'tech') {
      col.splice(6, 0, {
        key: 'type',
        label: language.TYPE,
        style: { width: 40, textAlign: 'center' },
      });
      csvData = this.props.store.users.map((obj) => [
        obj.id_user,
        obj.status ? 'Ativo' : 'Inativo',
        obj.name,
        obj.email,
        obj.phone,
        obj.company,
        obj.type === 1 ? language.TECH : language.INTERNAL,
        '-',
      ]);

      data = this.props.store.users.map((obj) => {
        return {
          id: obj.id_user,
          status: (
            <Switch
              checked={obj.status}
              disabled={obj.status === 'new'}
              onChange={() => this.onStatusChange(obj)}
              value="checkedB"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          ),

          name: obj.name,
          email: obj.email,
          phone: obj.phone,
          company: obj.company,
          type: obj.type === 1 ? language.TECH : language.INTERNAL,
          actions: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12 }}
                      size={0.7}
                      className="mr-2"
                      onClick={() =>
                        redirect('/dash/user/outsourced/edit', filterObj(obj))
                      }
                    />
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13 }}
                      size={0.7}
                      onClick={() => handlePage('remove', obj)}
                    />
                  </div>
                }
              />
            </React.Fragment>
          ),
        };
      });
    } else if (role === 'solicitant') {
      csvData = this.props.store.users.map((obj) => [
        obj.id_user,
        obj.status ? 'Ativo' : 'Inativo',
        obj.name,
        obj.email,
        obj.phone,
        obj.company,
        '-',
      ]);
      data = this.props.store.users.map((obj) => {
        return {
          id: obj.id_user,
          status: (
            <Switch
              checked={obj.status}
              disabled={obj.status === 'new'}
              onChange={() => this.onStatusChange(obj)}
              value="checkedB"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          ),

          name: obj.name,
          email: obj.email,
          phone: obj.phone,
          company: obj.company,
          actions: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12 }}
                      size={0.7}
                      className="mr-2"
                      onClick={() =>
                        redirect('/dash/user/outsourced/edit', filterObj(obj))
                      }
                    />
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13 }}
                      size={0.7}
                      onClick={() => handlePage('remove', obj)}
                    />
                  </div>
                }
              />
            </React.Fragment>
          ),
        };
      });
    } else {
      col.splice(3, 0, {
        key: 'userame',
        label: language.USERNAME,
        style: { width: 40, textAlign: 'center' },
      });

      csvData = this.props.store.users.map((obj) => [
        obj.id_user,
        obj.status ? 'Ativo' : 'Inativo',
        obj.name,
        obj.username,
        obj.email,
        obj.phone,
        obj.company,
        '-',
      ]);
      data = this.props.store.users.map((obj) => {
        return {
          id: obj.id_user,
          status: (
            <Switch
              checked={obj.status}
              disabled={obj.status === 'new'}
              onChange={() => this.onStatusChange(obj)}
              value="checkedB"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          ),
          name: obj.name,
          username: obj.username,
          email: obj.email,
          phone: obj.phone,
          company: obj.company,
          actions: (
            <React.Fragment>
              <Chip
                className="mx-2 muit-chip"
                label={
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      variant="circle"
                      icon="fas fa-edit"
                      label={language.EDIT}
                      color="default"
                      style={{ fontSize: 12 }}
                      size={0.7}
                      className="mr-2"
                      onClick={() =>
                        redirect('/dash/user/outsourced/edit', filterObj(obj))
                      }
                    />
                    <Button
                      variant="circle"
                      icon="far fa-trash-alt"
                      label={language.DELETE}
                      color="danger"
                      style={{ fontSize: 13 }}
                      size={0.7}
                      onClick={() => handlePage('remove', obj)}
                    />
                  </div>
                }
              />
            </React.Fragment>
          ),
        };
      });
    }

    return (
      <PageLayout
        icon={
          role === 'solicitant' ? (
            <div className="solicitantIcon" style={{ width: 40, height: 40 }} />
          ) : role === 'tech' ? (
            <div className="techIcon" style={{ width: 40, height: 40 }} />
          ) : role === 'sponsor' ? (
            <div className="clientIcon" style={{ width: 40, height: 40 }} />
          ) : (
            <div className="outsourcedIcon" style={{ width: 40, height: 40 }} />
          )
        }
        title={
          role === 'solicitant'
            ? language.REQUESTER
            : role === 'tech'
            ? language.TECH
            : role === 'sponsor'
            ? language.SPONSOR
            : language.OUTSOURCED
        }
        rightElements={[]}
      >
        <CommonTable
          beforeBar={
            <CSVLink
              style={{ textDecoration: 'none' }}
              separator={';'}
              filename={
                'users_' + moment().format('MMDDYY').toString() + '.csv'
              }
              enclosingCharacter={`'`}
              data={[col.map((e) => e.label)].concat(csvData)}
            >
              <Button
                variant="circle"
                icon="fas fa-microchip"
                label={'Export Excel'}
                color="warning"
                style={{
                  fontSize: 15,
                }}
              />
            </CSVLink>
          }
          title={
            role === 'solicitant'
              ? language.REQUESTER
              : role === 'tech'
              ? language.TECH
              : role === 'sponsor'
              ? language.SPONSOR
              : language.OUTSOURCED
          }
          index="UserList"
          data={data}
          columns={col}
          language={language}
          searchColumn
          orderColumn
          beforeBar={
            <RaisedButton
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              key="02"
              circleButton
              icon="fas fa-plus"
              label={
                role === 'solicitant'
                  ? language.NEW_REQUESTER
                  : role === 'tech'
                  ? language.NEW_TECH
                  : role === 'sponsor'
                  ? language.NEW_SPONSOR
                  : language.NEW_OUTSOURCED
              }
              primary={true}
              onClick={() => redirect('/dash/user/outsourced/new')}
            />
          }
        />
      </PageLayout>
    );
  }
}
