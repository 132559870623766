import React, {Fragment, memo, useState, useEffect} from 'react';
import './index.css';
import TicketUtils from '../../../../util/TicketUtils';
import Store from '@data/Store';
//--------------------------common----------------------------
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Progress from '@common/Progress';
import Button from '@common/Button';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import Tag from '@common/Tag';
import {addTimes} from '@common/Func';
import moment from 'moment';
//--------------------------material-ui----------------------------
import {Checkbox} from '@material-ui/core';
import { Tooltip } from 'react-tippy';
const styleIcons = [
	{fontSize:11, color:'var(--primary)'},
	{fontSize:11, color:'var(--warning)'},
	{fontSize:11, color:'var(--purple)'},
]
//---------------------ícones a serem usados, está na ordem do layout no adob------------------------------
const icons = [
	<i className='fas fa-user-plus' style={styleIcons[0]}/>,
	<i className='fas fa-bell' style={styleIcons[1]}/>,
	<i className='fas fa-exclamation-triangle' style={styleIcons[2]}/>
]

const { language } = Store;

let arrayPriority = [language.LOW, language.MEDIUM, language.HIGH, language.CRITICAL];
let arrayPriorityColor = [
	'var(--primary)',
	'var(--warning)',
	'var(--danger)',
	'var(--danger)'
];
export default memo(({
	open,
	onClose,
	itens,
	addItem,
	users,
	setId,
	selectedTickets,
	ticketsChecked,
	ticketsSprint
})=>{
	//------- fazer as requisições dos dados abaixo nessa página
	const getAsSolicitant=(users)=>{
	  try{
let n=	users.filter(v => v.id_user == g.fk_id_user) && users.filter(v=>v.id_user  == g.fk_id_user)[0].name

return n;
	  }catch(e){
		  return "";
	  }
  }


const checkTicketIsSelected = (ticket) => {
	let index = ticketsChecked.indexOf(ticket)

	if ( index == -1){
		return false
	}else{
		return true
	}
}

let ids_selecteds = selectedTickets.map(s => s.id_tickets)
let ids_sprint = ticketsSprint.map(s => s.fk_id_tickets)
let all_ids = [...ids_selecteds, ...ids_sprint]

	let data = itens &&  itens.tickets&& itens.tickets.map(g=>{
		if ( !all_ids.includes(g.id_tickets)){

		let tc = TicketUtils.calcSla(g, 'ticket', Store.language, [], g.sla_task, 0);
	    let solicit="";
try{
	solicit=	users.filter(v => v.id_user == g.fk_id_user) && users.filter(v=>v.id_user  == g.fk_id_user)[0].name
	}catch(e){}

		let u=""
;		try{
		  u =users.filter(v=>v.id_user  == g.fk_id_tech)[0].name
		}catch(e){}
		return {


			view: (
				<i
				   class="fas fa-eye"
				   style={{ width: 10, height: 10, cursor: 'pointer' }}
				   onClick={() =>
					  setId({ id_tickets: g.id_tickets, real_id: g.real_id })
				   }
				></i>
			 ),
		check: <Checkbox checked = {checkTicketIsSelected(g)}  onChange={()=>addItem(g)} />,
		status: (
			<div className='d-flex align-items-center'>

				{g.type_tickets == 4 ? (
            <Tooltip
              html={
							<span style={{ color: '#fff', fontSize: 15 }}>{language.REQUEST}</span>
              }
              position="right"
              theme="dark"
            >
              <i

                className="fas fa-bullhorn icon my-1"
                style={{
                  color: 'var(--primary)',
                  cursor: 'pointer',
                  fontSize: 13
                }}
              />
            </Tooltip>
          ) : g.type_tickets == 2 ? (
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
                  <strong>{language.PROBLEM}</strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <ErrorIcon

                style={{
                  color: ' var(--danger)',
                  cursor: 'pointer',
                  fontSize: 18
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              html={
                <div style={{ color: '#fff', fontSize: 15 }}>
										<strong>{language.INCIDENT}</strong>
                </div>
              }
              position="right"
              theme="dark"
            >
              <WarningIcon

                style={{
                  color: 'var(--warning)',
                  cursor: 'pointer',
                  fontSize: 17
                }}
              />
            </Tooltip>
          )}


				<div className='ml-3 d-flex align-items-center content-progress-table'>
					<Progress background='var(--warning)' current={g.progress} hiddenText style={{height:7, width:'100%'}}/>
					<span>{g.progress}</span>
				</div>
			</div>
		),
		id:g.real_id ,
		name_company : g.namecompany,
		solicitant:getAsSolicitant(users),
		dt:moment(g.dt_cad).format("DD-MM-YY"),
		hour:moment(g.dt_cad).format("HH:mm"),
		subject: g.subject,
		sponsor:u,
		sla:g.sla_task,
		priority:(
			<Tag
              label={
                arrayPriority[
                  g.fk_id_priority == 31
                    ? 0
                    : g.fk_id_priority == 32
                    ? 1
                    : g.fk_id_priority == 33
                    ? 2
                    : 3
                ]
              }
              color={
                arrayPriorityColor[
                  g.fk_id_priority == 31
                    ? 0
                    : g.fk_id_priority == 32
                    ? 1
                    : g.fk_id_priority == 33
                    ? 2
                    : 3
                ]
              }
            />
		),
		time_sla:<span style={{color:tc.color}}>{tc.time}</span>,
		name_company : g.namecompany,
		solicitant:solicit
			}
	}
})

data = data.filter(function(element) {
	return element != undefined
})

	return(
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth='xl'
			bar={false}
			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
		>
			<CommonTable
				className='AssociateTicketTable'
				maxHeight={500}
				searchColumn orderColumn
				searchColumn ={true}

				paginationTop={false}
				afterBar2={
					<Button
						label={language.CLOSE}
						icon='fas fa-times'
						color='#ffffff'
						variant='circle'
						outlined
						style={{
							border:'none',
							color:'#ffffff'
						}}
						onClick={onClose}
					/>
				}
				AppBarProps={{
					style:{
						borderRadius:'10px 10px 0 0'
					}
				}}
				col={[
					{key: 'view', label: language.VISUALIZE},
					{key:'check', label:'#'},
					{key:'status', label:language.STATE},
					{key:'id', label:language.ID},
					{key: 'name_company', label: language.COMPANY},
					{ key: 'solicitant', label: language.REQUESTER },
					{key:'dt', label: language.DATE},
					{ key: 'hour', label: language.HOUR},
					{key:'subject', label:language.SUBJECT},
					{key:'sponsor', label:language.RESPONSIBLE},
					{key:'sla', label:language.SLA},
					{key:'priority', label:language.PRIORITY},
					{ key: 'time_sla', label: language.SLA_TIME },
					]}
				data={data}
				footer={
					<Fragment>
						<hr/>
						<div className='d-flex align-items-center justify-content-end pb-3 pr-3'>
							<Button
								label={language.CANCEL}
								color='danger'
								variant='normal'
								className='mr-3'
								onClick={onClose}
							/>
							<Button
								label={language.SAVE}
								color='success'
								variant='normal'
								onClick={onClose}
							/>
						</div>
					</Fragment>
				}
			/>

		</Dialog>
	)
})
