import moment from 'moment';
import React, { useState } from 'react';

// ----------------------------------------------------------------------

import * as Styled from '../../ListHolidays.styles';

import Button from '@common/Button';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';

// ----------------------------------------------------------------------

const columns = (language) => [
  { key: 'id', label: '#', style: { width: 20 } },
  { key: 'name', label: language.NAME },
  { key: 'date', label: language.DATE },
  { key: 'action', label: language.ACTION },
];

export const DialogListHolidays = (props) => {
  const {
    language,
    handlePage,
    holidaysBySlaId,
    setDialogAddHolidaySlaOpen,
    setMessageConfirm,
    setDeleteHolidayId,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(75);

  const rows = holidaysBySlaId.map((row) => ({
    ...row,
    data: moment(row.data).format('DD/MM/YYYY'),
    action: (
      <Styled.ButtonDelete
        label={language.DELETE}
        onClick={() => {
          setMessageConfirm(row.name);
          setDeleteHolidayId(row.id_holiday);
        }}
      />
    ),
  }));

  return (
    <Dialog
      title={language.HOLIDAYS}
      modal={false}
      open
      onClose={() => handlePage('list')}
    >
      <CommonTable
        title=""
        language={language}
        columns={columns(language)}
        data={rows}
        searchColumnEvent={(valueSearch) =>
          console.log(page, valueSearch, rowsPerPage, 'filter')
        }
        callPageSelectFilter={({ page, values, rowsPerPage, type }) => {
          setPage(page);
          setRowsPerPage(rowsPerPage);
        }}
        countTotal={holidaysBySlaId.length}
        isAutomaticPagination={false}
        beginWithNoFilter
        loadSearch
        currentPage={page}
        hideFooterToolbar
        paginationTop={false}
        beforeBar={
          <Button
            variant="circle"
            icon="fas fa-plus"
            className="mr-3"
            color="primary"
            primary={true}
            label={language.ADD}
            onClick={() => setDialogAddHolidaySlaOpen(true)}
          />
        }
      />
    </Dialog>
  );
};
