import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import { Tooltip } from 'react-tippy';
import AttachFile from '@common/AttachFile';
import Pickers from '@common/Pickers';
import Button from '@common/Button';
import classnames from 'classnames'; //suporte pra adicionar classes aos elementos
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Chip,
  Avatar
} from '@material-ui/core'; //table
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FilledInput,
  FormControlLabel,
  Switch
} from '@material-ui/core'; // controls
import CommonTable from '@common/CommonTable';
import { Checkbox } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import $ from 'jquery';
import './gestaoRisco.css';

import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../common/PageLayout';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import DemandModal from '../demandmanagement/DemandModal';
import AttachFile2 from '@common/AttachFile2';
import { List, ListItem, AppBar, Card, Dialog } from '@material-ui/core';
import RaisedButton from '@common/RaisedButton';
import DDialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import {
  FaFileTextO,
  FaQuestionCircle,
  FaExclamation,
  FaQuestion,
  FaClose
} from 'react-icons/lib/fa';
import Progress from '@common/Progress';
import Loading from '@common/Loading';

const theme = createTheme({
  palette: {
    primary: { main: '#2196f3' }
  }
});

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    labelPlacement: 'top'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  buttonprimary: {
    margin: theme.spacing(1),
    backgroundColor: '#009dc0',
    color: 'white',
    size: '14px',
    weight: 500
  },
  TimePickerStyle: {
    marginTop: 2,
    size: '12'
  },
  DatePickerStyle: {
    marginTop: 10,
    size: '12'
  }
});

const objectttachment = [];
var idcheckselect = '';
var dataCreateListItem2 = [];

@observer
class RiskManagementConfiguration extends CommonPage {
  constructor() {
    super();
    this.state = {
      checkedA: true,
      checkedB: true,
      select: {
        age: ''
      },
      open: [],
      openDialog: false,
      evidenceDialog: false,
      accepted: [],
      accepted2: [],
      disableButton: false,
      textDesc: '',
      idsecproject: '',
      itemproject: '',
      idcheckselectItem: 0,
      modal: false,
      valores: [],
      idactivity: 0,
      idsecp: null,
      idsecdata: null,
      modaldata: false,
      modalactivity: false,
      idsec_user: 0,
      companyId: 1,
      id_sec_u: 0,
      date: '',
      files: [],
      tech: [],
      applicable: [],

      project_activity: [],

      execution_date: [],

      // applicable  :[]
      current_maturity: [],
      planned_maturity: [],
      adherent: [],
      load: true
    };
    // this.createListItem = this.createListItem.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.save = this.save.bind(this);
    this.attachmentUp = this.attachmentUp.bind(this);
    this.updateItens = this.updateItens.bind(this);

    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.checkBoxSelectSw = this.checkBoxSelectSw.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalClose = this.openModalClose.bind(this);
    this.projectstatusf = this.projectstatusf.bind(this);
    this.openModaldate = this.openModaldate.bind(this);
    this.updataItenGr = this.updataItenGr.bind(this);
    this.handleValuesProject = this.handleValuesProject.bind(this);
    this.handleValuesActivity = this.handleValuesActivity.bind(this);
    this.savetech = this.savetech.bind(this);
    this.projecnameActivity = this.projecnameActivity.bind(this);
  }

  save() {
    let Switch = [];
    var checked = document.querySelectorAll('input[type=checkbox]:checked');
    checked.forEach(c => {
      if (c.type == 'checkbox' && c.value != 'checkedB') {
        Switch.push(parseInt(c.value));
      }
    });

    this.props.saveTest(
      Switch,
      objectttachment,
      this.state.tech,
      this.state.project_activity,
      this.state.execution_date,
      this.state.current_maturity,
      this.state.planned_maturity,
      this.state.adherent,
      this.state.applicable
    );

    if (this.props.risk == 1) {
      this.props.redirect2('/dash/riskmanagement/iso_27001', {
        risk: 1,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 2) {
      this.props.redirect2('/dash/riskmanagement/iso_27000', {
        risk: 2,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 3) {
      this.props.redirect2('/dash/riskmanagement/cobit', {
        risk: 3,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 4) {
      this.props.redirect2('/dash/riskmanagement/backup_management', {
        risk: 4,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 5) {
      this.props.redirect2('/dash/riskmanagement/it_evolution_management', {
        risk: 5,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 6) {
      this.props.redirect2('/dash/riskmanagement/lgpd', {
        risk: 6,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 7) {
      this.props.redirect2('/dash/riskmanagement/iso_9001', {
        risk: 7,
        id_company: this.props.idcompany
      });
    } else if (this.props.risk == 8) {
      this.props.redirect2('/dash/riskmanagement/iso_37001', {
        risk: 8,
        id_company: this.props.idcompany
      });
    }
    this.props.redirect('/dash/riskmanagement');

  }
  savetech() {
    let tech = this.state.tech;
    tech[this.state.id_sec_u] = this.state.idsec_user;
    this.setState({ tech, modalactivity: false, load: false });

    // this.props.update(this.state.id_sec_u, 'fk_id_user', this.state.idsec_user);
  }

  onChange = (id, valor) => {
    this.props.verifyActivitTech(valor);
    this.setState({ modalactivity: true, idsec_user: valor, id_sec_u: id });

    //  this.props.update(name,'fk_id_user',value)
    //alterei aqui
    // this.setState({ [name]: value });
  };

  onChange_ = value => {
    this.props.update(this.state.idsecproject, 'fk_id_project', value);

    // this.props.update(name,'fk_id_user',value)
    // this.setState({ [name]: value });
  };

  openModaldate(id, item) {
    this.setState({ idsecdata: id, modaldata: true });
  }
  openModal(id, item) {
    this.setState({
      idsecp: id,
      itemproject: item,
      modal: true,
      modalactivity: false
    });
  }
  openModalClose() {
    this.setState({ modal: false, modaldata: false });
  }

  checkBoxSelect(values) {
    this.props.update(id, item, values);
  }

  componentWillMount() {
    this.action.execute(
      'get',
      this.APIEndpoints.RISK +
      '/' +
      this.props.riskType +
      '/company/' +
      this.props.idcompany,
      'risks'
    );
  }

  checkBoxSelectSw(values) {
    let applicable = this.state.applicable;

    if (applicable[values]) {
      applicable[values] = false;
    } else {
      applicable[values] = true;
    }

    this.setState({ applicable, load: false });

    //this.props.update(values, 'applicable', true);
  }

  attachmentUp = (descFile) => {
    let { accepted2 } = this.state;
    this.setState({ disableButton: true });
    var tempObj = (accepted2.find(e => e.idcheckselect == idcheckselect) || {}).files.map(e => (
      { idcheck: idcheckselect, file: e, desc: descFile }
    ));

    objectttachment.push(this.props.onUploadFile(tempObj));

    idcheckselect = '';
    this.setState({ evidenceDialog: !this.state.evidenceDialog });
  };

  handleModal = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  handleModalEvidence = idcheck => {
    idcheckselect = idcheck;
    this.setState({ idcheckselectItem: idcheck });
    // this.setState({ evidenceDialog: this.state.evidenceDialog });

    let adherent = this.state.adherent;
    adherent[idcheck] = true;

    this.setState({
      disableButton: false,
      accepted: [],
      adherent,
      moda: false
    });
  };
  handleModalEvidence2 = (modal, item) => {
    idcheckselect = item;
    this.setState({ idcheckselectItem: item });
    this.setState({ files: item });
    this.setState({ evidenceDialog: modal });
    this.setState({ disableButton: false });
    this.setState({ moda: false });
  };
  updataItenGr(value, id) {
    let execution_date = this.state.execution_date;
    execution_date[id] = value;
    this.setState({ execution_date, load: false });

    // var name = this.state.date;
    // name = moment(name).format();
    //  this.updateItens(this.state.idsecdata, '', name);
    // this.setState({ modaldata: false });
  }
  updateItens(id, item, value) {
    //current_maturity
    /**sec.id,
                                      'planned_maturity',
                                      c */
    if (item == 'current_maturity') {
      let current_maturity = this.state.current_maturity;
      current_maturity[id] = value;
      this.setState({ current_maturity, load: false });
    } else if (item == 'planned_maturity') {
      let planned_maturity = this.state.planned_maturity;
      planned_maturity[id] = value;
      this.setState({ planned_maturity, load: false });
    }

    // this.props.update(id, item, value);
  }

  projectstatusf(fk_id_project) {
    if (fk_id_project == 28) {
    }
    let per = null;
    if (fk_id_project != null) {
      try {
        let percentProject = this.props.activity
          .filter(
            x => parseInt(x.id_project_activity) === parseInt(fk_id_project)
          )
          .map(c => {
            return c.percent;
          });
        per = parseInt(percentProject[0]);
      } catch (e) { }
    }
    return per;
  }
  projecnameActivity(fk_id_project) {
    let per = [];
    if (fk_id_project != null) {
      try {
        let percentProject = this.props.activity
          .filter(
            x => parseInt(x.id_project_activity) === parseInt(fk_id_project)
          )
          .map(c => {
            return c.project + ' - ' + c.name;
          });
        per = percentProject[0];
      } catch (e) { }
    }
    return per;
  }
  handleFields = (value, key) => {
    this.setState({ [key]: value });
  };

  createListItem2 = (section, language) => {
    let { open } = this.state;
    let { users } = this.props;

    let list = users
      .filter(v => v.role === 'tech' || v.role === 'client')
      .map(tk => {
        return (
          <MenuItem key={tk.id_user} value={tk.id_user}>
            {tk.name}
          </MenuItem>
        );
      });

    const { classes } = this.props;
    var dataCreateListItem2 = [];
    var s2s = s2.bind(this);
    var countAtualFiles;
    function s2(section) {
      let item = 0;

      section.map(sec => {

        if (sec.items) {
          dataCreateListItem2.push({
            ConfigCell: {
              className: 'header-title',
              cols: {
                col_0: {
                  colSpan: 9
                }
              }
            },
            applicable: sec.desc
          });

          s2s(sec.items);
        } else {


          if (
            this.state.adherent[sec.id] != null &&
            this.state.adherent[sec.id] != undefined
          ) {
            ad = this.state.adherent[sec.id];
          } else {
            ad = sec.adherent;
          }

          if (
            this.state.applicable[sec.id] != null &&
            this.state.applicable[sec.id] != undefined
          ) {
            ap = this.state.applicable[sec.id];
          } else {
            ap = sec.applicable;
          }



          let countAttach = this.handleModalEvidence2Count(sec.id);


          // countAtualFiles = (this.state.accepted2.find(e=>e.idcheckselect==sec.id)||{files:''}).files.length;
          countAtualFiles = this.props.upload.reduce((c, e) => e.idchecked == sec.id ? c + 1 : c, 0);
          dataCreateListItem2.push({
            applicable: (
              <Switch
                checked={ap}
                onChange={() => this.checkBoxSelectSw(sec.id)}
                value="checkedB"
                color="primary"
              />
            ),
            adherent: (
              <Checkbox
                defaultChecked={ad}
                disabled={!(this.state.applicable[sec.id] || sec.applicable)}
                value={sec.id}
                ref={'check_' + sec.id}
                onClick={() => this.handleModalEvidence(sec.id)}
                color="primary"
              />
            ),
            attachment:
              countAttach > 0 || countAtualFiles > 0 ? (
                <RaisedButton
                  circleButton
                  icon={countAttach + countAtualFiles}
                  label={language.ATTACHMENT}
                  color={'success'}
                  onClick={() => this.handleModalEvidence2(true, sec.id)}
                  style={{ width: 25, height: 25, fontSize: 14 }}
                />
              ) : (
                <RaisedButton
                  circleButton
                  icon={'fas fa-paperclip'}
                  label={language.ATTACHMENT}
                  onClick={() => this.handleModalEvidence2(true, sec.id)}
                  style={{ width: 25, height: 25, fontSize: 14 }}
                />
              ),
            question: <div style={{ width: 350 }}>{sec.desc}</div>,
            maturity: (
              <div className="maturidade">
                {!(this.state.applicable[sec.id] || sec.applicable)
                  ? [1, 2, 3, 4, 5].map(c => (
                    <Tooltip
                      html={
                        <div style={{ color: '#fff', fontSize: 15 }}>
                          {language.DATAMAT.mat[c]}
                        </div>
                      }
                      position="right"
                      theme="dark"
                      arrow={true}
                    >
                      <div className="item_Maturidade"></div>
                    </Tooltip>
                  ))
                  : [1, 2, 3, 4, 5].map(c =>
                    c <=
                      (this.state.current_maturity[sec.id] ||
                        sec.current_maturity) ? (
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            {language.DATAMAT.mat[c]}
                          </div>
                        }
                        position="right"
                        theme="dark"
                        arrow={true}
                      >
                        {' '}
                        <div
                          className="item_Maturidade checado"
                          onClick={
                            !(this.state.applicable[sec.id] || sec.applicable)
                              ? ''
                              : () =>
                                this.updateItens(
                                  sec.id,
                                  'current_maturity',
                                  c
                                )
                          }
                        ></div>{' '}
                      </Tooltip>
                    ) : (
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            {language.DATAMAT.mat[c]}
                          </div>
                        }
                        position="right"
                        theme="dark"
                        arrow={true}
                      >
                        {' '}
                        <div
                          className="item_Maturidade"
                          onClick={
                            !(this.state.applicable[sec.id] || sec.applicable)
                              ? ''
                              : () =>
                                this.updateItens(
                                  sec.id,
                                  'current_maturity',
                                  c
                                )
                          }
                        ></div>{' '}
                      </Tooltip>
                    )
                  )}
              </div>
            ),
            desired_maturity: (
              <div className="maturidade2">
                {!(this.state.applicable[sec.id] || sec.applicable)
                  ? [1, 2, 3, 4, 5].map(c => (
                    <Tooltip
                      html={
                        <div style={{ color: '#fff', fontSize: 15 }}>
                          {language.DATAMAT.mat[c]}
                        </div>
                      }
                      position="right"
                      theme="dark"
                      arrow={true}
                    >
                      <div className="item_Maturidade"></div>
                    </Tooltip>
                  ))
                  : [1, 2, 3, 4, 5].map(c =>
                    c <=
                      (this.state.planned_maturity[sec.id] ||
                        sec.planned_maturity) ? (
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            {language.DATAMAT.mat[c]}
                          </div>
                        }
                        position="right"
                        theme="dark"
                        arrow={true}
                      >
                        <div
                          className="item_Maturidade checado"
                          onClick={
                            !(this.state.applicable[sec.id] || sec.applicable)
                              ? ''
                              : () =>
                                this.updateItens(
                                  sec.id,
                                  'planned_maturity',
                                  c
                                )
                          }
                        ></div>{' '}
                      </Tooltip>
                    ) : (
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            {language.DATAMAT.mat[c]}
                          </div>
                        }
                        position="right"
                        theme="dark"
                        arrow={true}
                      >
                        <div
                          className="item_Maturidade"
                          onClick={
                            !(this.state.applicable[sec.id] || sec.applicable)
                              ? ''
                              : () =>
                                this.updateItens(
                                  sec.id,
                                  'planned_maturity',
                                  c
                                )
                          }
                        ></div>
                      </Tooltip>
                    )
                  )}
              </div>
            ),
            responsible: (
              <TextField
                select
                //label={language.RESPONSIBLE}
                style={{ width: 200 }}
                value={this.state.tech[sec.id] || sec.fk_id_user}
                onChange={(event, key, values) =>
                  this.onChange(sec.id, event.target.value)
                }
                margin="dense"
                className="input-min mx-2"
                variant="outlined"
              >
                {list}
              </TextField>
            ),
            date: (
              <Pickers
                type="date"
                variant="outlined"
                margin="dense"
                clearable
                style={{ width: 200 }}
                label="Data"
                value={
                  (this.state.execution_date[sec.id] || sec.execution_date) ==
                    'null'
                    ? null
                    : this.state.execution_date[sec.id] || sec.execution_date
                }
                onChange={value => this.updataItenGr(value, sec.id)}
                className="mr-2"
              />
            ),

            activity:
              (this.state.project_activity[sec.id] || sec.fk_id_project) !=
                null ? (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>
                        {' '}
                        {this.projecnameActivity(sec.fk_id_project)}{' '}
                      </strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                  arrow={true}
                >
                  <div
                    style={{
                      width: 150,
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    {this.projecnameActivity(
                      this.state.project_activity[sec.id] || sec.fk_id_project
                    ) &&
                      this.projecnameActivity(
                        this.state.project_activity[sec.id] || sec.fk_id_project
                      ).length >= 25
                      ? this.projecnameActivity(
                        this.state.project_activity[sec.id] ||
                        sec.fk_id_project
                      ).substring(0, 25) + '...'
                      : this.projecnameActivity(
                        this.state.project_activity[sec.id]
                      )}

                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-100">
                        <Progress
                          emptyColor
                          onClick={() => this.openModal(sec.id, '')}
                          current={
                            this.projectstatusf(
                              this.state.project_activity[sec.id] ||
                              sec.fk_id_project
                            ) || 0
                          }
                          color={
                            this.projectstatusf(
                              this.state.project_activity[sec.id] ||
                              sec.fk_id_project
                            ) > 66
                              ? 'var(--success)'
                              : this.projectstatusf(
                                this.state.project_activity[sec.id] ||
                                sec.fk_id_project
                              ) > 33
                                ? 'var(--warning)'
                                : 'var(--danger)'
                          }
                        />
                      </div>
                      <div style={{ float: 'right' }}>
                        <RaisedButton
                          circleButton
                          color="default"
                          label={language.EDIT}
                          icon="fas fa-edit"
                          onClick={() => this.openModal(sec.id, '')}
                          style={{ width: 25, height: 25, fontSize: 12 }}
                          className="ml-2"
                        />
                      </div>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>
                        {language.ASSOCIATION_ITEM_TO_PROJECT_ACTIVITY}
                      </strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                    {' '}
                    <div
                      style={{ width: 27, height: 27 }}
                      className="projectManIcon"
                      onClick={
                        !(this.state.applicable[sec.id] || sec.applicable)
                          ? ''
                          : () => this.openModal(sec.id, '')
                      }
                    >
                      {' '}
                    </div>{' '}
                  </div>{' '}
                </Tooltip>
              )
          });
        }
      });
    }
    s2s(section);
    return dataCreateListItem2;
  };
  handleModal = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  handleModalEvidence2Count = id => {
    let count = 0;
    let itens = [];
    this.props.attachment.map(t => {
      t.attachment.attachments
        .filter(h => parseInt(h.check_select) === parseInt(id))
        .map(j => {
          if (itens.filter(g => g == j.namefile).length == 0) {
            itens.push(j.namefile);
            count++;
          }
        });
    });

    return count;
  };
  // ---------------------------------------------------------(COMPONENTE - gestaoRisco)---------------------------------------------------------------------------

  handleValuesProject(value) {
    this.setState({ idsecproject: value });
  }
  handleValuesActivity(value) {
    let project_activity = this.state.project_activity;
    project_activity[this.state.idsecp] = value;

    this.setState({
      project_activity,
      idactivity: value,
      modal: false,
      load: false
    });

    //this.props.update(this.state.idsecp, 'fk_id_project', value);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeSelect = name => event => {
    this.setState({
      select: {
        [name]: event.target.value
      }
    });
  };

  render() {
    let {
      language,
      handlePage,
      risks,
      handleModal,
      risk,
      tests,
      users,
      projects,
      statusProject,
      classIcon
    } = this.props;
    let { open, openDialog, evidenceDialog } = this.state;

    let list = users
      .filter(v => v.role === 'tech')
      .map(tk => {
        return (
          <MenuItem key={tk.id_user} value={tk.id_user}>
            {tk.name}
          </MenuItem>
        );
      });

    let datasp = projects.map(c => {
      return (
        <MenuItem
          style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
          key={0}
          value={c.id_project}
          primaryText={c.name}
        >
          {c.name}
        </MenuItem>
      );
    });

    // onClick={()=>this.onChange_(c.id_project)} size="small" color="primary">
    let files = [];
    let itens = [];

    let evidenceDlg = (
      <DDialog
        open={evidenceDialog}
        onClose={() => this.handleModalEvidence2(false)}
        title={language.ATTACH_EVIDENCE}
      >
        <section>
          {this.props.attachment.map(t => {
            t.attachment.attachments
              .filter(
                h =>
                  parseInt(h.check_select) ===
                  parseInt(this.state.idcheckselectItem)
              )
              .map(j => {
                if (itens.filter(g => g == j.namefile).length == 0) {
                  itens.push(j.namefile);
                  files.push(
                    <AttachFile
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.props.ondonwFile(1, j.namefile)}
                      file={{
                        file: j.namefile,
                        id_project: 6,
                        id_project_file: 1,
                        key: 'te',
                        name: j.namefile,
                        size: 0,
                        type: 'te'
                      }}
                      style={{ maxWidth: '100%' }}
                      className="my-1"
                    />
                  );
                }
              });
          })

            /*
           attachment: Object
  attachments: ObservableArray
  0: Object
  check_select: "1393"
  descr: "teste"
  namefile: "Beach_by_Samuel_Scrimshaw(1).jpg"
            files.filter(c => c.key == e.key.toString())
                                .map(v => (
                                  <AttachFile
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.ondonwFile(v.id_project, v.name)
                                    }
                                    file={v}
                                    style={{ maxWidth: '100%' }}
                                    className="my-1"
                                    onDelete={() =>
                                      this.removeFile(
                                        v.id_project_file,
                                        v.id_project_file
                                      )
                                    }
                                  />
                                )) */
          }
          {files}
        </section>

        <section className='mt-3'>
          <CommonForm
            individual
            values={{ file: (this.state.accepted2.find(e => e.idcheckselect == idcheckselect) || {}).files }}
            fields={[
              {
                type: 'file',
                name: 'file',
                label: language.ATTACH,
                onClick: f => this.props.ondonwFile(1, f.namefile)
              },
              {
                type: 'text',
                name: 'description',
                label: language.DESCRIPTION
              }
            ]}
            onChangeField={(f, v) => {
              let { accepted2 } = this.state;
              if (f.name == 'file') {

                var files = accepted2.map(e => e.idcheckselect == idcheckselect ? ({ idcheckselect, files: v }) : e);
                var findAc = accepted2.find(e => e.idcheckselect == idcheckselect);

                this.setState({ accepted2: findAc ? files : [...accepted2, { idcheckselect, files: v }] });

              } else {
                this.handleFields(v, 'description')
              }
            }}
            onSubmit={v => {
              this.attachmentUp(this.state.description);
              this.handleFields('', 'description');
            }}
            button={{
              disabled: this.state.disableButton
            }}
          />
        </section>

        <br />
        {this.state.disableButton ? (
          <div>
            <span style={{ color: '#33ABA0' }}>
              {language.ALERT_ATTACH_SAVE_CHECK}
            </span>
          </div>
        ) : (
          ''
        )}
      </DDialog>
    );
    let resultConfDialog = (
      <DDialog
        open={openDialog}
        onClose={() => this.handleModal}
        title={language.CONFIRMATION}
      >
        <RaisedButton
          label={language.YES}
          onClick={() => this.save()}
          primary
          fullWidth
        />
      </DDialog>
    );

    let listActivity = [];

    if (this.state.idsecproject != '') {
      this.props.activity
        .filter(g => g.id_project === this.state.idsecproject)
        .map(c => {
          listActivity.push(
            <MenuItem
              style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
              key={0}
              value={c.id_project_activity}
              primaryText={c.name}
            >
              {c.name}
            </MenuItem>
          );
        });
    }
    const configCols = {
      thConfig: true,
      style: {
        paddingLeft: 10,
        paddingRight: 10
      }
    };

    if (!tests.length) return <Loading Loading={true} />;

    return (
      <PageLayout
        icon={
          <div
            className={classIcon[tests[0].name]}
            style={{ width: 44, height: 44 }}
          />
        }
        title={tests[0].name + ' -> ' + language.CONFIG}
        subTitle={language.RISKS_MANAGEMENT_COMPLIANCE + '/'}
      >
        <MuiThemeProvider theme={theme}>
          <div id="gestaoRisco" ref={el => (this.componentRef = el)}>
            <CommonTable
              col={[
                {
                  key: 'applicable',
                  label: language.APPLICABLE,
                  ...configCols,
                  style: {
                    paddingRight: 0,
                    paddingRight: 0,
                    width: 1
                  }
                },
                {
                  key: 'adherent',
                  label: language.ADHERENT,
                  ...configCols,
                  style: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    width: 1
                  }
                },
                {
                  key: 'attachment',
                  label: language.ATTACHMENT,
                  ...configCols,
                  style: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    width: 1
                  }
                },
                { key: 'question', label: language.QUESTION, ...configCols },
                { key: 'maturity', label: language.MATURITY, ...configCols },
                {
                  key: 'desired_maturity',
                  label: language.DESIRED_MATURITY,
                  ...configCols
                },
                {
                  key: 'responsible',
                  label: language.RESPONSIBLE,
                  ...configCols
                },
                { key: 'date', label: language.DATE, ...configCols },
                {
                  key: 'activity',
                  label: language.ACTIVITY,
                  ...configCols,
                  style: {
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: 150
                  }
                }
              ]}
              /*data={[
                {
                  ConfigCell:{
                    className:'title'
                  },
                  applicable:'Applicable'
                }
              ]}*/

              data={this.createListItem2(risks, language)}
              beforeTitle={[]}
            />

            <Row>
              <Col md={12} className="mt-2">
                <div style={{ float: 'right' }}>
                  <Button
                    onClick={this.handleModal}
                    style={{ marginBottom: 20 }}
                    label={language.SAVE}
                    icon="fas fa-save"
                  />
                </div>
              </Col>
            </Row>
          </div>

          {resultConfDialog}
          {evidenceDlg}


          <DDialog
            open={this.state.modal}
            onClose={() => this.openModalClose()}
            title="Project"
          >
            <div className="d-flex align-items-center">
              <TextField
                id="outlined-select-currency"
                select
                label={'Project'}
                style={{ minWidth: 200, marginRight: '1em' }}
                value={this.state.idsecproject}
                onChange={(event, key, values) =>
                  this.handleValuesProject(event.target.value)
                }
                margin="dense"
                variant="outlined"
              >
                {datasp}
              </TextField>

              <TextField
                id="outlined-select-currency"
                select
                label={language.ACTIVITY}
                style={{ minWidth: 200, marginRight: '1em' }}
                value={this.state.idactivity}
                onChange={(event, key, values) =>
                  this.handleValuesActivity(event.target.value)
                }
                margin="dense"
                variant="outlined"
              >
                {listActivity}
              </TextField>
            </div>
          </DDialog>

          <DDialog
            open={this.state.modaldata}
            onClose={() => this.openModalClose()}
            title="Informe a data"
            maxWidth="xs"
          >
            <div className="d-flex align-items-center">
              <Pickers
                type="DatePicker"
                variant="outlined"
                margin="dense"
                value={this.state.date || moment()}
                onChange={value => this.handleFields(value, 'date')}
                className="mr-2"
              />
              <RaisedButton
                circleButton
                icon="fas fa-save"
                label={language.SAVE}
                color="success"
                onClick={() => this.updataItenGr()}
              />
            </div>
          </DDialog>
        </MuiThemeProvider>
      </PageLayout>
    );
  }
}

RiskManagementConfiguration.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RiskManagementConfiguration);
