import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Toggle, Card } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import { FaUserSecret, FaWrench, FaUser } from 'react-icons/lib/fa';

@observer
export default class UserList extends Component {
  constructor() {
    super();
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onStatusChange(toChange) {
    toChange.status = !toChange.status;
    this.props.onFormSubmit(toChange);
  }

  render() {
    let {
      language,
      handlePage,
      role,
      redirect,
      contracts,
      contract
    } = this.props;

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 5, textAlign: 'center' }
      },
      {
        key: 'company',
        label: language.COMPANY,
        style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' }
      },
      {
        key: 'contract',
        label: language.CONTRACT,
        style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' }
      },
      {
        key: 'responsavel',
        label: language.SPONSOR,
        style: { maxWidth: 100, overflow: 'auto', textAlign: 'center' }
      },
      {
        key: 'phone',
        label: language.PHONE,
        style: { width: 80, textAlign: 'center' }
      },
      {
        key: 'email',
        label: language.EMAIL,
        style: { maxWidth: 60, overflow: 'auto', textAlign: 'center' }
      },

      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' }
      }
    ];

    // console.log(JSON.stringify(contracts));
    //id_contract	contract	responsavel	phone	email	company
    let data = contract.map(obj => {
      try {

        //  console.log(item[0]);
        return {
          id: obj.id_contract,
          company: obj.company,
          contract: obj.contract,

          responsavel: obj.responsavel,
          phone: obj.phone,
          email: obj.email,

          actions: (
            <RaisedButton
              circleButton
              icon='fas fa-edit'
              label={language.EDIT}
              color='default'
              style={{ fontSize: 12, width: 21, height: 21 }}
              onClick={() => redirect('/dash/contracts', { id_contract: obj.id_contract, ulc: true })}
            />

          )
        };
      } catch (e) { }
    });
    let { configurationProps = {} } = this.props;
    return (
      <PageLayout
        title={configurationProps.title}
        subTitle="Configurações/"
        rightElements={[]}
      >
        <CommonTable title="" data={data} columns={col} language={language} searchColumn orderColumn
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              key="02"
              circleButton
              icon='fas fa-plus'
              style={{ zoom: .8 }}
              zoom={.8}
              className='mr-3'
              color='primary'
              label={
                role === 'solicitant' ? language.NEW_REQUESTER :
                  role === 'tech' ? language.NEW_TECH :
                    role === 'sponsor' ? language.NEW_SPONSOR : language.NEW_OUTSOURCED
              }
              primary={true}

              onClick={() => handlePage('sponsor/new')}
            />
          }
        />
      </PageLayout>
    );
  }
}
