import React, { Component, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';
import Actions from '@data/Actions';
import Constants from '@data/Constants';

const action = new Actions()

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: option[attributeName],
  }));

  return normalizedOptions;
};

@observer
export default class PermissionsForm extends Component {
  constructor() {
    super();
    this.onChangeField = this.onChangeField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      companySelected: 0,
      categoriesList: [],
      subcategoriesList: [],
      subCategoriesCompleteList: [],
      subcategoriesValue: [],
      thirdcategoriesList: [],
    };
  }

  async getCategories(companyId) {
    this.setState( { companySelected: companyId }, () => {
      action
        .execute('get', `${Constants.APIEndpoints.CATEGORY}/company/${this.state.companySelected}`, '')
        .then((res) => {
          this.setState( { categoriesList: formatSelectOptions(res.data, 'name', 'id_category') } );
        })
    });
  }

  async getSubcategories(categoriesId) {
    const fetchPromises = categoriesId.map(categoryId => {
      return action
        .execute('get', `${Constants.APIEndpoints.CATALOG}/company/${this.state.companySelected}/${categoryId}`, '')
        .then((res) => {
          return res.data;
        });
    });

    const responses = await Promise.all(fetchPromises);

    const concatenatedResponses = responses.reduce((acc, response) => {
      const catalogServiceFiltered = response
      return acc.concat(catalogServiceFiltered);
    }, []);

    this.setState({
      subcategoriesList: formatSelectOptions(concatenatedResponses, 'name', 'id_catalog_service'),
      subCategoriesCompleteList: concatenatedResponses
    }, () => {
      this.getThirdLevelCategories();
    });
  }

  getThirdLevelCategories() {
    const filteredSubcategoriesValue = this.state.subcategoriesValue.filter((value) =>
      this.state.subcategoriesList.some((option) => option.value === value)
    );

    if (this.state.subcategoriesValue.length > 0 && filteredSubcategoriesValue.length > 0) {
      if (this.state.subcategoriesList.length > 0) {
        let tasksOptions = [];

        this.state.subcategoriesValue.forEach((catalog) => {
          this.state.subCategoriesCompleteList.forEach((catalogItem) => {
            if (catalogItem.id_catalog_service == catalog) {
              catalogItem.task.forEach((t) => {
                tasksOptions.push({
                  value: t.id_catalog_task,
                  label:
                    t.name +
                    ' - ' +
                    t.description.replace(/<\/?[^>]+(>|$)/g, ''),
                });
              });
            }
          });
        });
        this.setState({
          thirdcategoriesList: tasksOptions,
        });
      } else {
        this.setState({
          thirdcategoriesList: [],
        });
      }
    } else {
      this.setState({
        thirdcategoriesList: [],
      });
    }
  }

  onChangeField(field, value) {
    switch (field.name) {
      case 'id_company':
        this.getCategories(value);
        break;
      case 'categories':
        this.getSubcategories(value);
        break;
      case 'subcategories':
        this.setState({ subcategoriesValue: value }, () => {
          this.getThirdLevelCategories();
        });
        break;
    }
  }

  onSubmit(values) {
    if (values.hasOwnProperty("undefined")) {
      delete values.undefined;
    }

    values.allowed_tasks = values.allowed_tasks.filter((value) =>
      this.state.thirdcategoriesList.some((option) => option.value === value)
    );

    if ( !values.hasOwnProperty("id_user_catalog") ) {
      action
        .execute('post', `${Constants.APIEndpoints.USER_CATALOG}`, '', values)
        .then((res) => {
          window.location.reload();
        });
    } else {
      action
        .execute('put', `${Constants.APIEndpoints.USER_CATALOG}/${values.id_user_catalog}`, '', values)
        .then((res) => {
          window.location.reload();
        });
    }
  }

  componentDidMount() {
    const { values } = this.props;
    if ( values ) {
      this.getCategories(values.id_company);
      this.setState( { companySelected: values.id_company }, () => {
        this.getSubcategories(values.categories);
      });
      this.setState({ subcategoriesValue: values.subcategories }, () => {
        this.getThirdLevelCategories();
      });
    }
  }

  render() {
    let {
      language,
      handlePage,
      values,
      store,
      onFormSubmit,
      onChangeField,
      companies,
      countries,
      categories,
      route,
      APIEndpoints
    } = this.props;

    values?.categories?.sort((a, b) => a - b);
    values?.subcategories?.sort((a, b) => a - b);
    values?.allowed_tasks?.sort((a, b) => a - b);

    let fields = [
      {
        col: 4,
        name: 'role',
        label: language.ACCESS,
        startIcon: 'fas fa-users-cog',
        type: 'select',
        options:[
          { value: 'tech', label: language.TECH },
          { value: 'solicitant', label: language.SOLICITANT },
          { value: 'scm', label: language.SCM },
          { value: 'country', label: language.COUNTRY },
        ],
        required: true,
      },
      {
        col: 4,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        required: true,
        options: companies,
      },
      {
        col: 4,
        name: 'id_country',
        label: language.COUNTRY,
        type: 'select',
        startIcon: 'fas fa-flag',
        options: countries,
        required: true,
        isMulti: true,
      },
      {
        col: 6,
        name: 'categories',
        label: language.CATEGORIES,
        type: 'select',
        startIcon: 'fas fa-tasks',
        options: this.state.categoriesList,
        required: true,
        isMulti: true,
        disabled: (this.state.categoriesList.length === 0)
      },
      {
        col: 6,
        name: 'subcategories',
        label: language.SUBCATEGORIES,
        type: 'select',
        startIcon: 'fas fa-tasks',
        options: this.state.subcategoriesList,
        required: true,
        isMulti: true,
        disabled: (this.state.subcategoriesList.length === 0)
      },
      {
        col: 12,
        name: 'allowed_tasks',
        label: language.THIRD_LEVEL_CATEGORIES,
        type: 'select',
        startIcon: 'fas fa-tasks',
        options: this.state.thirdcategoriesList,
        required: true,
        isMulti: true,
        disabled: (this.state.thirdcategoriesList.length === 0)
      },
    ];

    return (
      <CommonForm
        values={values}
        fields={fields}
        onChangeField={this.onChangeField}
        onSubmit={this.onSubmit}
      />
    );
  }
}
