import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  memo,
  useMemo
} from 'react';
import Button from '@common/Button';
import {
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FilledInput,
  FormControlLabel,
} from '@material-ui/core';
const crypto = require('crypto');
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import Store from '@data/Store';

const action = new Actions();
const { language } = Store;

//public\images\loading.gif

const ticketReport = (props) => {
  let [sistema, setsistema]=useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      action
        .execute('get', `${Constants.APIEndpoints.TICKET}/report/${props.id_user}`, '')
        .then((res) => {
          setsistema(res.data)
        }).catch((error) => {
          console.error(error);
        });
    }, 6000);

    return () => clearInterval(intervalId); //This is important
  }, []);

  let t='';

  try {
    t= Constants.APIEndpoints.REPORTEXPORT + '/' + props.id_user+'/' +sistema[0].name
  } catch(e) {
    console.error(e);
  }

  return <div>
    <br></br>
    {sistema[0] ?
    sistema[0].process == true ?
    <b style={{color:'#019fc2',marginRight:'30px'}}>Gerando Relatório... {sistema[0].log}   </b>
       :
        <a id="anchorID" href={t} target="_blank"><b style={{ color: '#019fc2', marginRight: '30px' }}>
          {language.DOWNLOAD} {sistema[0].name}
        </b></a>
     : null}
   </div>
}

export default ticketReport
