import { Card, Grid } from '@material-ui/core';
import React, { Fragment, memo, useState } from 'react';

// ------------------------------------------------------------

import ListDetail_1 from '../Dialog/ListDetail_1';

import AppBar from '@common/AppBar2';
import List from '@common/List';
import Store from '@data/Store';

// ------------------------------------------------------------

export default memo(
  ({ demandListData: _demandListData, requests, totalTicketsTotalCalls }) => {
    const [open, setOpen] = useState(0);
    const [typeTickets, setTypeTickets] = useState(0);
    const { language } = Store;
    const totalTicket =
      parseInt(totalTicketsTotalCalls.totalIncident || 0) +
      parseInt(totalTicketsTotalCalls.totalProblem || 0) +
      parseInt(totalTicketsTotalCalls.totalSolicitation || 0) +
      parseInt(totalTicketsTotalCalls.totalEvent || 0) +
      parseInt(totalTicketsTotalCalls.totalCrisis || 0);
    const data = [
      {
        name: language.REQUESTERS,
        y: totalTicketsTotalCalls.totalSolicitation || 0,
        color: 'var(--primary)',
        typeTickets: 4,
      },
      {
        name: language.INCIDENTS,
        y: totalTicketsTotalCalls.totalIncident || 0,
        color: 'var(--warning)',
        typeTickets: 1,
      },
      {
        name: language.PROBLEMS,
        y: totalTicketsTotalCalls.totalProblem || 0,
        color: 'var(--danger)',
        typeTickets: 2,
      },
      {
        name: language.EVENTS,
        y: totalTicketsTotalCalls.totalEvent || 0,
        color: 'var(--primary)',
        typeTickets: 5,
      },
      {
        name: language.CRISIS_PLURAL,
        y: totalTicketsTotalCalls.totalCrisis || 0,
        color: 'var(--warning)',
        typeTickets: 6,
      },
      {
        name: language.CATEGORIZATIONS,
        y: requests.length,
        color: 'var(--green)',
        typeTickets: 0,
      },
    ];

    return (
      <Fragment>
        <ListDetail_1
          open={open != 0}
          onClose={() => setOpen(0)}
          data={requests}
          type="TotalCalls"
          typeTickets={typeTickets}
        />
        <Card id="TotalCalls">
          <AppBar title={language.TOTAL_CALLS_CURRENT_MONTH} />
          <div className="content-medium pt-0">
            <Grid container spacing={2}>
              <Grid
                item
                xs={5}
                className="d-flex align-items-center justify-content-center content-total"
              >
                <i className="fas fa-comment-alt" />
                <span>{totalTicket}</span>
              </Grid>
              <Grid item xs={7}>
                <List
                  className="list"
                  hover={false}
                  items={data.map((e, i) => ({
                    className: 'd-flex justify-content-between',
                    style: { '--color': e.color, padding: 8 },
                    content: (
                      <Fragment>
                        <i
                          className="fas fa-eye btn-view-detail mr-2"
                          onClick={() => {
                            setOpen(e.typeTickets);
                            setTypeTickets(e.typeTickets);
                          }}
                          style={{ color: e.color }}
                        />
                        <div className="indicator" />
                        <span className="label">{e.name}</span>
                        <span className="value">{e.y}</span>
                      </Fragment>
                    ),
                  }))}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
      </Fragment>
    );
  },
  function (prev, next) {
    let omit = (obj) => _.omitBy(obj, (val) => typeof val == 'function');
    return _.isEqual(omit(prev), omit(next));
  }
);
