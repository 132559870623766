/* eslint-disable react/prop-types */
import RaisedButton from '../../../common/RaisedButton';
import React, { Fragment } from 'react';
import { Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import CommonTable from '../../../common/CommonTable';
import Button from '../../../common/Button';
import Store from '@data/Store';
import { formatDeadline } from '../utils/formatDeadline';

const TaskList = ({ language, currentPage, handlePage, listTasks, isStep }) => {
  let col = [
    {
      key: 'order',
      label: Store.language.ORDER,
      style: { textAlign: 'center', width: 40 },
      thConfig: true,
      visible: isStep
    },
    {
      key: 'stage',
      label: Store.language.STATE,
      style: { textAlign: 'center' },
      thConfig: true,
      visible: isStep
    },
    {
      key: 'deadline',
      label: 'Prazo Máximo',
      style: { textAlign: 'center' },
      format: '###:##',
      thConfig: true,
    },
    {
      key: 'name',
      label: Store.language.STEP,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'name_department',
      label: Store.language.DEPARTMENT,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'responsible',
      label: Store.language.RESPONSIBLE,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'actions',
      label: Store.language.ACTIONS,
      style: { textAlign: 'left' },
      thConfig: false,
    },
  ];

  const data = listTasks?.map((obj) => {
    let columns = {
      order: obj.order,
      stage:  obj.stage == 'Pending'
      ? 'Pendente'
      : obj.stage == 'In Progress'
      ? 'Em atendimento'
      : obj.stage == 'Conclued'
      ? 'Resolvido'
      : obj.stage == 'Closed'
      ? 'Fechado'
      : obj.stage == 'Canceled'
      ? 'Cancelado'
      : obj.stage,
      deadline: formatDeadline(obj.deadline),
      name: obj.name,
      name_department: obj.name_department,
      responsible: obj.responsible,
      actions:
        (obj.stage !== 'Canceled' &&
         obj.stage !== 'Conclued' &&
         obj.stage !== 'In Progress') &&(
        <React.Fragment>
          <Chip
            className="mx-2 muit-chip"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="circle"
                  icon="fas fa-edit"
                  label={Store.language.EDIT}
                  color="default"
                  style={{ fontSize: 12 }}
                  size={0.7}
                  className="mr-2"
                  onClick={() => handlePage('edit_task', obj)}
                />
                <Button
                  variant="circle"
                  icon="far fa-trash-alt"
                  label={Store.language.DELETE}
                  color="danger"
                  style={{ fontSize: 13 }}
                  size={0.7}
                  onClick={() => handlePage('remove', obj)}
                />
              </div>
            }
          />
        </React.Fragment>
        )
      }

      if (!isStep) {
        delete columns.order;
        delete columns.stage
      }

      return columns
  });

  if (!isStep) {
    col = col.filter((item) => item.key !== 'order')
    col = col.filter((item) => item.key !== 'stage')
  }

  return (
    <Dialog
      maxWidth="md"
      title={'Adicionar Etapa'}
      open={true}
      miniVersion
      onClose={() => handlePage('list')}
    >
      <CommonTable
        title={''}
        language={language}
        currentPage={currentPage}
        columns={col}
        index="ExtensionList"
        data={data}
        searchColumn
        orderColumn
        width="100%"
        paginationTop={false}
        beforeBar={
          <RaisedButton
            circleButton
            icon="fas fa-plus"
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            primary={true}
            label={language.ADD_TASK}
            onClick={() => handlePage('form_task')}
          />
        }
      />
    </Dialog>
  );
};

export default TaskList;
