import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import * as Icons from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Constants } from '@data';
import Actions from '@data/Actions';

const action = new Actions();

const transformToSnakeCase = (value) => {
  return value.replace(/[A-Z]/g, (letter, index) =>
    index === 0 ? letter.toLowerCase() : '_' + letter.toLowerCase()
  );
};

export default function DialogIcon({ onChange }) {
  const [typeValue, setTypeValue] = useState('icon');
  const [images, setImages] = useState([]);

  useEffect(() => {
    action
      .execute('get', `${Constants.APIEndpoints.ROOT_}/image-gallery`)
      .then((response) => {
        if( response.data ) {
          response = response.data;
        }
        const { data } = response;
        const images = data.map((file) => {
          return {
            label: file.label,
            icon: (
              <img
                className="tw-h-14"
                src={`${Constants.BASE_URL_IMAGE}${file.url}`}
              />
            ),
            url: file.url,
          };
        });
        setImages(images);
      });
  }, []);

  const iconsFilter = [
    'outlined',
    'rounded',
    'sharp',
    'twotone',
    'forward',
    'extra',
    'reduced',
  ];
  const icons = Object.keys(Icons)
    .filter(
      (iconkey) =>
        !iconsFilter.some((filter) =>
          iconkey.toString().toLocaleLowerCase().includes(filter)
        )
    )
    .map((iconkey) => ({
      label: iconkey,
      icon: <Icon>{transformToSnakeCase(iconkey)}</Icon>,
      iconString: transformToSnakeCase(iconkey),
    }));

  const handleIconChange = (icon) => {
    onChange &&
      onChange({
        iconString: typeValue === 'image' ? icon.url : icon.iconString,
        type: typeValue,
      });
  };

  const handleTypeChange = (event) => {
    setTypeValue(event.target.value);
  };

  const items = typeValue === 'icon' ? icons : images;

  return (
    <div className="tw-max-w-3xl">
      <Paper elevation={2} className=" tw-p-6">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <RadioGroup
              name="type"
              aria-label="type"
              value={typeValue}
              onChange={handleTypeChange}
            >
              <FormControlLabel
                value="icon"
                control={<Radio />}
                label="Ícones"
              />
              <FormControlLabel
                value="image"
                control={<Radio />}
                label="Imagens"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <Search label="" onChange={() => {}} />
                <div className="mt-1 mb-2">{icons.length} results</div>
              </Grid> */}
              {items.map((item, index) => (
                <Grid key={index} item xs={3}>
                  <Paper
                    index={index}
                    elevation={1}
                    variant="outlined"
                    className="tw-px-2 tw-py-4 !tw-bg-gray-100 tw-text-center hover:tw-bg-gray-200 tw-cursor-pointer"
                    onClick={() => handleIconChange(item)}
                  >
                    <div className="tw-flex tw-flex-col tw-items-center tw-mb-2">
                      {item.icon}
                    </div>
                    <p
                      className="tw-text-xs tw-overflow-ellipsis tw-overflow-hidden"
                      title={item.label}
                    >
                      {item.label}
                    </p>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
