import React, { Component, useState } from 'react';
import { Paper, Card } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import CommonForm from '../../common/CommonForm2';
import PasswordRequirements from './components/PasswordRequirements';
import Help from '../../common/components/Help';
import { FaUser } from 'react-icons/lib/fa';
import * as SensritUtilsPk from '@sensrit/utils';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
    };
  }

  render() {
    let { language, onFormSubmit, redirect } = this.props;
    const { password, confirmPassword } = this.state;

    const onChangeField = (field, value) => {
      if( field.name === "password" ) {
        this.setState({ password: value });
      }

      if( field.name === "confirmation" ) {
        this.setState({ confirmPassword: value });
      }
    }

    const onSubmit = (values) => {
      if( SensritUtilsPk.checkIfStringHasValidPasswordFn(values.password) && (values.password.length >= 8) && values.password === values.confirmation ) {
        onFormSubmit(values, 'changePass');
      }
    }

    let fields = [
      {
        col: 4,
        name: 'oldpassword',
        label: language.OLD_PASSWORD,
        type: 'password',
        required: true
      },
      {
        col: 8,
        type: 'element',
      },
      {
        col: 4,
        name: 'password',
        label: language.NEW_PASSWORD,
        type: 'password',
        required: true
      },
      {
        col: 4,
        name: 'confirmation',
        label: language.CONFIRM_PASSWORD,
        type: 'password',
        required: true
      },
      {
        col: 4,
        type: 'element',
      },
      {
        col: 4,
        type: 'element',
        content: (v, h, submit) => (
          <>
            <PasswordRequirements
              language={language}
              password={this.state.password}
            />
          </>
        ),
      }
    ];

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { margin: '10px auto' }
    };

    return (
      <PageLayout
        icon={
          <div className="changePassIcon" style={{ height: 40, width: 40 }} />
        }
        title={language.USER + ' - ' + language.CHANGE_PASSWORD}
        subtitle={language.CHANGE_PASSWORD}
        rightElements={[]}
      >
        <Card className="cardContainer">
          <Paper zdepth={0}>
            <div className="changePassword">
              <CommonForm
                language={language}
                fields={fields}
                onChangeField={onChangeField}
                button={
                  {
                    icon: 'fas fa-save',
                    label: language.SAVE,
                    disabled: !(SensritUtilsPk.checkIfStringHasValidPasswordFn(this.state.password) && this.state.password === this.state.confirmPassword)
                  }
                }
                onSubmit={(v) => onSubmit(v)}
              />
            </div>
          </Paper>
        </Card>
      </PageLayout>
    );
  }
}
