import React from 'react'
import useSWR from 'swr';
import axios from 'axios';
import changeStore from './changeStore';

let expo = {
    getConf(config){
        return this.config = {...(this.config||{}), ...config} || config;
    },
    // ----------------------------------------------------------
    create(config={}){
        let _expo = Object.assign({}, this);
        _expo.config = config;
        return _expo;
    },
    storeSet:{},
    fetch(method='', url, model, body, key, config={}, isSwr){
        this.getConf(config);
        if(this.config.store){
            this.config.store.keyLoading = this.config.store.keyLoading||[];
            this.config.store.loading = this.config.store.loading||0;
            var storeSet = changeStore.init({
                url,
                method,
                store:this.config.store,
                keyLoading:this.config.keyLoading
            });
            this.storeSet = storeSet;
            storeSet.start();
        }else{
            var storeSet = {success(){}, error(){}};
        }
        
        let headers = this.config.headers || {
            'Accept': method == 'csv' ? 'text/csv' : 'application/json',
            'Content-Type': 'application/json',
            
        };

        
        if(this.config.token){
            headers['X-Access-Token'] = this.config.token
        }

        return axios({
            url,
            method,
            baseURL: this.config.baseURL,
            headers,
            data:body
        }).then(result=>{
            
            let senData = {
                method,
                url,
                body,
                model,
                key,
                config,
                data: result.data
            }
            storeSet.success(senData);
            if(this.config.onSuccess){
                this.config.onSuccess({...result, model, key});
            }
            if(isSwr) return result.data;
            return result;
        }).catch((error) => {
            storeSet.error(error);

            if(this.config.onError){
                this.config.onError(error);
            }
            return error;
        });
    },
    $fetch(method='', target, model, body, key, config={}){
        let token = this.getConf(config).token;
        let {data, error, isValidating, mutate} = useSWR([target, token], url => this.fetch(method, url, model, body, key, config, true), {
            //revalidateOnFocus:false,
            revalidateOnReconnect:true,
            shouldRetryOnError:true,
            errorRetryCount:10,
            onSuccess(data, key){
                
            },
            onError(err, key){
            }
        });
        return {data, error, isValidating, mutate};
    },
    get(target, model, config={}){
        return this.fetch('get', target, model, null, null, config)
    },
    post(target, model, body, config={}){
        return this.fetch('post', target, model, body, null, config)
    },
    put(target, model, body, key, config={}){
        return this.fetch('put', target, model, body, key, config)
    },
    delete(target, model, key, config={}){
        return this.fetch('delete', target, model, null, key, config)
    },

    $get(target, model, config={}){
        return this.$fetch('get', target, model, null, null, config)
    },
    $post(target, model, body, config={}){
        return this.$fetch('post', target, model, body, null, config)
    },
    $put(target, model, body, key, config={}){
        return this.$fetch('put', target, model, body, key, config)
    },
    $delete(target, model, key, config={}){
        return this.$fetch('delete', target, model, null, key, config)
    }
}

export default expo;