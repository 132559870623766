import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';

import OccupationList from './OccupationList';
import OccupationForm from './OccupationForm';
import { testRoute } from '@common/Func';
import Dialog from '@common/Dialog';
import Animate from '@common/Animate';

@observer
export default class OccupationContainer extends CommonPage {
    constructor() {
        super();
        this.state = {
            // page: 'list',// possivelmente comentar
            occupations: [],
            load: true,
            totaloccupation : 0,
            currentPage:0,
            page:0
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.callPageSelectFilter = this.callPageSelectFilter.bind(this);

    }

    componentWillMount() {
        // this.action.execute('get', this.APIEndpoints.OCCUPATION, 'occupations')
        //     .then(occupation => {
        //         this.setState({ occupations: occupation.data })
        //     })

        this.callPageSelectFilter();
    }

    callPageSelectFilter(page=0, values, totalPerPage=75) {
        this.setState({currentPage:page});
        let limit = totalPerPage || 75;
        let pageCount = page + 1;
        let offset = page * limit;

        this.action
          .execute('post', `${this.APIEndpoints.OCCUPATION}/list?limit=${totalPerPage}&offset=${offset}`, '', values, '')
          .then((res) => {
            this.setState({
              occupations: res.data,
              totaloccupation:parseInt(res.data[0].total_count)
            });
            return res.data.json();
          }).catch((error) => {
            this.store.toggleAlert(true, this.store.language.PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA, 'success');
          });
      }

    onFormSubmit(values) {
        let method = values.hasOwnProperty('id_occupation') ? 'put' : 'post';
        let url = values.hasOwnProperty('id_occupation') ? this.APIEndpoints.OCCUPATION + '/' + values.id_occupation : this.APIEndpoints.OCCUPATION;
        this.action.execute(method, url, 'occupations', values, 'id_occupation')
            .then(e => {
                this.handlePage('list')
                this.setState({ occupations: e.data })
            });
    }

    handlePage(page, edit) {
        this.setState({ page: page, values: edit });
    }

    onDelete() {
        let { values } = this.state;
        this.action.execute('post', this.APIEndpoints.OCCUPATION + '/' + 'removeoccupation',
            'occupations', values, 'id_occupation')
            .then(e => {
                this.handlePage('list')
                this.setState({ occupations: e.data })
            });
    }

    render() {
        let { page, values, occupations, totaloccupation } = this.state;

        let { language } = this.store;
        let { configurationProps = {} } = this.props;

        let route = this.props.type_config;
        let history = this.history2;
        let redirect = this.redirect2;
        return (
            <Fragment>
                <Animate>
                    <OccupationList
                        handleOpen={this.handleOpen}
                        handleClose={this.handleClose}
                        onFormSubmit={this.onFormSubmit}
                        handlePage={this.handlePage}
                        language={language}
                        history={history}
                        redirect={redirect}
                        configurationProps={configurationProps}
                        occupations={this.state.occupations}
                        route={route}
                        store={this.store}
                        totaloccupation = {totaloccupation}
                        callPagination={this.callPagination}
                        callDataFilter={this.callDataFilter}
                        callPageSelectFilter={this.callPageSelectFilter}
                    />
                </Animate>
                {['new', 'edit'].includes(page) &&
                    <Dialog
                        title={
                            values
                                ? language.EDIT_POSITION
                                : language.NEW_POSITION
                        }
                        open={true}
                        onClose={() => this.handlePage('list')}
                        maxWidth='xs'
                        miniVersion = {true}
                        checkScroll
                    >
                        <OccupationForm
                            handleOpen={this.handleOpen}
                            handleClose={this.handleClose}
                            onFormSubmit={this.onFormSubmit}
                            handlePage={this.handlePage}
                            language={language}
                            store={this.store}
                            history={history}
                            values={values}
                            redirect={redirect}
                            occupations={occupations}
                            route={route}
                        />
                    </Dialog>
                }

                {page === 'remove' ? (
                    <CommonDelete
                        language={language}
                        closeModal={() => this.handlePage('list')}
                        onDelete={this.onDelete}
                        message={this.state.values.name}
                    />
                ) : null}
            </Fragment>
        );
    }
}
