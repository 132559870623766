import React, { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

import { getHolidaysBySlaIdService } from './ListHolidays.services';

import { DialogConfirmDelete } from './components/DialogConfirmDelete';
import { DialogAddHolidaySla } from './components/DialogAddHolidaySla';
import { DialogListHolidays } from './components/DialogListHolidays';

// ----------------------------------------------------------------------

export const ListHolidays = (props) => {
  const {
    language,
    handlePage,
    values: { id_sla },
  } = props;
  const [deleteHolidayId, setDeleteHolidayId] = useState(null);
  const [messageConfirm, setMessageConfirm] = useState('');
  const [holidaysBySlaId, setHolidaysBySlaId] = useState([]);
  const [dialogAddHolidaySlaOpen, setDialogAddHolidaySlaOpen] = useState(false);

  const commonDialogConfirmDeleteProps = {
    closeModal: () => {
      setMessageConfirm('');
      setDeleteHolidayId(null);
      setDialogAddHolidaySlaOpen(false);
    },
    language,
  };

  useEffect(() => {
    const getHolidaysBySlaId = async () => {
      const result = await getHolidaysBySlaIdService(id_sla);
      setHolidaysBySlaId(result);
    };

    getHolidaysBySlaId();
  }, [deleteHolidayId, dialogAddHolidaySlaOpen]);

  return (
    <>
      {deleteHolidayId !== null && (
        <DialogConfirmDelete
          {...commonDialogConfirmDeleteProps}
          holidayId={deleteHolidayId}
          message={messageConfirm}
          slaId={id_sla}
        />
      )}

      {dialogAddHolidaySlaOpen && (
        <DialogAddHolidaySla
          {...commonDialogConfirmDeleteProps}
          slaId={id_sla}
          holidaysInSla={holidaysBySlaId.map((i) => i.id_holiday)}
          setDialogAddHolidaySlaOpen={setDialogAddHolidaySlaOpen}
        />
      )}

      <DialogListHolidays
        language={language}
        handlePage={handlePage}
        holidaysBySlaId={holidaysBySlaId}
        setMessageConfirm={setMessageConfirm}
        setDialogAddHolidaySlaOpen={setDialogAddHolidaySlaOpen}
        setDeleteHolidayId={setDeleteHolidayId}
      />
    </>
  );
};
