import React, { Fragment, useState, useEffect } from 'react';
import { Card, Avatar, Grid } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import Button from '@common/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommonForm from '@common/CommonForm2';
import classnames from 'classnames';
import Progress from '@common/Progress';
import Dialog from '@common/Dialog';
import List from '@common/List';
import './DialogDetail.css';
import moment from 'moment';
import AttachFile from '@common/AttachFile';
import { Tooltip } from 'react-tippy';
import Store from '@data/Store';
import { observer } from 'mobx-react';
import {MaskNumber} from '@common/Mask';

const addRuntime = v => {
  var { itemsSubAc, id_sub_activity_runtime } = this.state;
  itemsSubAc.push({
    id: id_sub_activity_runtime,
    runtime: v.runtime
  });
  this.setState({ itemsSubAc });
  this.setState({ openSubActivity: 0, runtime: null, runtimeError: undefined });
};
const toggleModalSubActivity = n => {
  if (openSubActivity == 2) {
    if (this.state.runtime) {
      this.addRuntime({ runtime: this.state.runtime });
      this.setState({
        openSubActivity: n,
        runtimeError: undefined,
        runtime: null
      });
    } else {
      this.setState({ runtimeError: 1 });
    }
  } else {
    this.setState({ openSubActivity: n });
  }
};

export default function DialogDetail({
  open,
  onClose,
  project,
  ondonwFile,
  saveActivity,
  activity,
  onFormSubmitComments,
  ondeletefile,
  onFormSubmitActivityStatus,
  UpdateFiles,
  action,
  APIEndpoints,
  toggleAlert,
  // ---------------store-------
  subActivities,
  users,
  language
}) {
  let [openSubActivity, SetOpenSubActivity] = useState(0);
  let [edit_subactivity, set_edit_subactivity] = useState({});
  let [stateSubActivities, setStateSubActivities] = useState([]);
  let [SubActSelected, setSubActSelected] = useState(0);
  let [local, SetLocal] = useState(1);
  // let [runtimeError, setRuntimeError] = useState(undefined)
  let [runtime, setRuntime] = useState(null);
  let [sensitive, setSensitive] = useState(false);

  useEffect(() => {
    action
      .execute(
        'get',
        APIEndpoints.PROJECT +
          '/activity/subactivity?fk_id_activity=' +
          activity.id_project_activity,
        'subActivities'
      )
      .then(e => {
        setSensitive(true);
      });
  }, []);
//console.log(activity)
  const createSubActivity = values => {
    if (values.id_sub_activity) {
      action
        .execute(
          'put',
          APIEndpoints.PROJECT + '/activity/subactivity',
          'subActivities',
          values,
          'id_sub_activity'
        )
        .then(e => {
          SetOpenSubActivity(0);
          set_edit_subactivity({});
        });
    } else {
      action
        .execute(
          'post',
          APIEndpoints.PROJECT + '/activity/subactivity',
          'subActivities',
          values
        )
        .then(e => {

          SetOpenSubActivity(0);
          set_edit_subactivity({});
        });
    }
  };
  const deleteSubActivity = (idSA, idA)=>{

    var SubActSelected = subActivities.filter(e=>e.id_sub_activity!=idSA&&e.selected).length;
    var SubActGeral = subActivities.filter(e=>e.id_sub_activity!=idSA).length;

    var percent = parseInt((SubActSelected/SubActGeral)*100)||0;

    action
      .execute(
      'delete',
      `${APIEndpoints.PROJECT}/activity/subactivity/${idSA}/${idA}/${project.id_project}/${percent}`,
      // '$delete-subActivities|',
      'subActivities',
      {},
      'id_sub_activity',
      {btn_loading:'delete_subactivity_'+idSA}
    ).then(data => {

      /*pmo.projetos = pmo.projetos.map(e=>e.id_project==data[0].data.pg.id_project?data.data.pg:e);
      Store.pmo = pmo;*/
      SetOpenSubActivity(0);
      set_edit_subactivity({});
    });
  }
  const sendStatus = values => {
    values.id_project_activity = activity.id_project_activity;
    values.id_project = project.id_project;

    if (!values.status) {
      var st = subActivities;
      var ss = values.sub_activity.filter(Boolean);

      values.local = local;
      values.status = parseInt((ss.length / st.length) * 100);
      values.descriptionhistory = language.EDITION_OF_SUB_ACTIVITY_STATUS;
      values.Sub_activity_management = values.sub_activity.filter(Boolean);
      values.In_Sub_activity_management = st
        .filter(
          e => !values.Sub_activity_management.includes(e.id_sub_activity)
        )
        .map(e => e.id_sub_activity);
      values.timeextend = stateSubActivities.map(e => e.runtime);
    }

    onClose();
    onFormSubmitActivityStatus(values);
  };
  const sendFiles = v => {
    v.id_project_activity = activity.id_project_activity;

    UpdateFiles(v);
  };
  const removeFile = () => {};
  const replay = values => {
    values.fk_id_project_activity = activity.id_project_activity;
    values.fk_id_project = project.id_project;
    onClose();

    onFormSubmitComments(values);
  };
  /*let hours = 0;
  project.hours_consumed &&
    project.hours_consumed
      .filter(y => y.fk_id_activity == activity.id_project_activity)
      .map(g => {
        hours += moment.duration(g.hours_consumed).asSeconds();
      });*/
      const toSecond = (hms) => {
        try {
          //hms = '02:04:33';   // your input string
          var a = hms.split(':'); // split it at the colons

          // minutes are worth 60 seconds. Hours are worth 60 minutes.
          var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          return seconds;
        } catch (r) {
          return 0;
        }
      }
      const calculateTimeInSeconds=(totalSeconds)=>{
        let days, hours, minutes, seconds = 0;
        hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        minutes = Math.floor(totalSeconds / 60);
        seconds = totalSeconds % 60;

        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0')
      }

    const addTimes = function(timeMap) {
        var total=0;

        // First simply adding all of it together, total hours and total minutes

        for (let x =0; x < timeMap.length; x++) {
            total=total+  toSecond(timeMap[x])

        }

        return  calculateTimeInSeconds(total);
    }


  let valorHour = undefined;
  try{
    valorHour= addTimes(project.hours_consumed.filter(e=>e.fk_id_activity==activity.id_project_activity).map(e=>e.hours_consumed));
  }catch(t){

  }
  if (valorHour == undefined) {
    valorHour = '00:00:00';
  }
  let [openstatus, SetOpenStatus] = useState(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        title={activity.name}
        maxWidth="md"
        PaperProps={{
          id: 'DialogDetail',
          beforeTitle: (
            <h3 id="id_activity" className="mr-4">
              {activity.id_project_activity}
            </h3>
          )
        }}
      >
        <div className="pt-3">
          <div id="Description_Activity">
            <p dangerouslySetInnerHTML={{ __html: activity.description}} />
          </div>
          <div className="content-form-sibactivity">

            <CommonForm
              sensitive={sensitive}
              values={{
                sub_activity: subActivities
                  .filter(e => e.selected)
                  .map(e => e.id_sub_activity)
              }}
              fields={[
                {
                  type: 'listCheckbox',
                  name: 'sub_activity',
                  header: false,
                  label: (
                    <span
                      className="-mb-1 -ml-3"
                      style={{ fontSize: 12, display: 'block' }}
                    >
                      {language.SUBACTIVITIES}
                    </span>
                  ),
                  label: (
                    <div className="-mb-1 -ml-3 d-flex align-items-center">
                      <span style={{ fontSize: 12 }} className="mr-2">
                        {language.SUBACTIVITIES}
                      </span>
                      <Button
                        icon="fas fa-plus"
                        variant="circle"
                        color="#3CD6C7"
                        style={{
                          backgroundColor: '#3CD6C7',
                          color: 'white'
                        }}
                        size={0.5}
                        zoom={0.5}
                        onClick={() => SetOpenSubActivity(1)}
                      />
                    </div>
                  ),
                  options: subActivities.map(e => ({
                    value: e.id_sub_activity,
                    checkbox: { style: { width: 0, height: 0 }, disabled:e.selected },
                    label: (
                      <span style={{ fontSize: 14 }}>
                        {e.responsible + ' - ' + e.name}
                      </span>
                    ),
                    contentProps:{
                      className:'d-flex align-items-center'
                    },
                    afterComponent: (
                      <div className='d-flex align-items-center'>
                        {!e.selected&&
                          <Button
                            icon="fas fa-pencil-alt"
                            variant="circle"
                            color="light"
                            outlined
                            size={0.6}
                            zoom={0.6}
                            className='mr-3'
                            style={{ border: 'none' }}
                            onClick={() => {
                              SetOpenSubActivity(1);
                              set_edit_subactivity(e);
                            }}
                          />
                        }
                        <Button
                          icon="fas fa-trash-alt"
                          variant="circle"
                          color="danger"
                          outlined
                          size={0.6}
                          zoom={0.6}
                          _loading={'delete-delete_subactivity_'+e.id_sub_activity}
                          style={{ border: 'none' }}
                          onClick={()=>deleteSubActivity(e.id_sub_activity, activity.id_project_activity)}
                        />
                      </div>
                    )
                  }))
                }
              ]}
              button={{
                label: stateSubActivities.length > 0 ? language.SAVE : '',
                icon: stateSubActivities.length > 0 ? 'fas fa-tasks' : '',
                color: '#3CD6C7',
                className: '-mt-5',
                disabled: stateSubActivities.length == 0 ? true : false,
                style: {
                  backgroundColor: '#3CD6C7',
                  color: 'white',
                  width: stateSubActivities.length > 0 ? '100px' : '0px'
                }
              }}
              onChangeField={(f, v, selected) => {
                if (v.includes(selected)) {
                  SetOpenSubActivity(2);
                  setSubActSelected(selected);
                } else {
                  setStateSubActivities(
                    stateSubActivities.filter(e => e.id != selected)
                  );
                }
              }}
              onSubmit={f => sendStatus(f)}
            />
          </div>
          <br />
          <Grid container spacing={16} className="block-1">
            <Grid item xs={6}>
              <div className="content py-0 px-4 content-border-r h-100">
                {/*activity.file &&
                  activity.file.map(f => (
                    <div style={{ margin: '5px 0' }}>
                      <AttachFile
                        label={f.file}
                        style={{ fontSize: '7px' }}
                        onClick={() => ondonwFile(f.id_gp_file, f.file)}
                        onDelete={() => ondeletefile(f.id_gp_file)}
                        style={{ maxWidth: '100%' }}
                      />
                    </div>
                  ))*/}
                <CommonForm
                  values={{
                    files: !activity.file
                      ? []
                      : activity.file.map(f => ({
                          name: f.file,
                          size: f.size,
                          type: f.type,
                          id_gp_file: f.id_gp_file,
                          id_project_activity: f.id_project_activity
                        }))
                  }}
                  fields={[
                    {
                      name: 'files',
                      type: 'file',
                      label: language.ATTACH,
                      scrollProps: {
                        style: {
                          height: 152
                        }
                      },
                      btnBottom: true,
                      maxHeight: 250,
                      removeFile: f => ondeletefile(f),
                      onClick: f => ondonwFile(f.id_project_activity, f.name),
                      btnProps: {
                        color: '',
                        style: {
                          backgroundColor: '#3CD6C7',
                          color: 'white'
                        }
                      }
                    }
                  ]}
                  onChangeField={(f, v) => {
                    sendFiles(v);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="content-description px-3t">
                <span>{language.PROGRESS_}</span>
                <div className="content-date d-flex align-items-center justify-content-between mt-2">
                  <div>
                    <span className="d-flex">
                      <i className="fas fa-calendar-alt mr-1" />
                      {language.START_}
                    </span>
                    <span>
                      {moment(activity.dt_start).format('DD/MM/YYYY')} -
                      <b>{moment(activity.dt_start).format('HH:mm')}</b>
                    </span>
                  </div>
                  <div>
                    <span className="d-flex">
                      <i className="fas fa-calendar-alt mr-1" />
                      {language.END_}
                    </span>
                    <span>
                      {moment(activity.dt_end).format('DD/MM/YYYY')} -
                      <b>{moment(activity.dt_end).format('HH:mm')}</b>
                    </span>
                  </div>
                </div>
                <div className="content-progress d-flex align-items-center justify-content-between mt-3">
                  <Progress
                    hiddenText
                    current={activity.percent}
                    background="var(--danger)"
                    style={{ width: '100%', borderRadius: 10 }}
                  />
                  <span className="ml-2">{activity.percent}%</span>
                </div>
                <div className="content-consumed d-flex align-items-end justify-content-between mt-4 mb-3">
                  <div className="content-desc">
                    <span>{language.CONSUMED}</span>
                    <span>{valorHour}</span>
                  </div>
                  {subActivities.length == 0 && (
                    <Button
                      icon="far fa-clock"
                      label={language.REGISTER_EVOLUTION}
                      variant="normal"
                      color="#3CD6C7"
                      style={{
                        backgroundColor: '#3CD6C7',
                        color: 'white'
                      }}
                      onClick={() => SetOpenStatus(true)}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <hr className="content-border-t mt-1" />
          <Grid container spacing={16} className="block-2">
            <Grid item xs={6}>
              <div className="content-responsibles content-border-r h-100">
                <span>{language.RESPONSIBLES}</span>
                <div className="d-flex align-items-center content-avatares">
                  {activity.users &&
                    activity.users.map((e, i) => (
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            <strong>{e}</strong>
                          </div>
                        }
                        position="right"
                        theme="dark"
                      >
                        <div>
                          <Avatar key={i} className="avatar">
                            {e.substr(0, 1)}
                          </Avatar>
                        </div>
                      </Tooltip>
                    ))}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="content-tags px-3">
                <span>Tags</span>
                <div className="tags d-flex align-items-center justify-content-end mt-1">
                  {activity.tagac &&
                    activity.tagac.map((e, i) => (
                      <span style={{ backgroundColor: e.color }}>{e.name}</span>
                    ))}
                </div>
              </div>
            </Grid>

            {activity.activityprd && (
              <Grid item xs={12}>
                <div className="content-responsibles content-border-r h-100">
                  <span>{language.PENDING_ACTIVITIES}</span>
                  <div className="">
                    {activity.activityprd.map((e, i) => (
                      <Tooltip
                        html={
                          <div style={{ color: '#fff', fontSize: 15 }}>
                            <strong>{e.name}</strong>
                          </div>
                        }
                        position="right"
                        theme="dark"
                      >
                        <div
                          style={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: 600
                          }}
                        >
                          #
                          {e.id_project_activity +
                            ' ' +
                            e.name +
                            ' - ' +
                            e.percent +
                            '%'}
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
          <hr className="content-border-t" />
          <div>
            <CommonForm
              btnContentProps={{
                className: '-mt-4'
              }}
              fields={[
                {
                  name: 'description',
                  type: 'simplesTextEditor',
                  label: (
                    <Fragment>
                      <i className="fas fa-comment-dots mr-2" />
                      <span style={{ color: 'white' }}>{language.ACTIVITIES}</span>
                    </Fragment>
                  ),
                  className: 'detail_activity',
                  rows: 3,
                  placeholder: language.ADD_A_COMMENT,
                  readOnly: true,
                  btnEdit: true
                }
              ]}
              button={{
                label: language.SAVE,
                color: '#3CD6C7',
                style: {
                  backgroundColor: '#3CD6C7',
                  color: 'white'
                }
              }}
              onSubmit={e => replay(e)}
            />
          </div>
          <div>
            <p
              style={{
                fontSize: '12px',
                color: 'white',
                fontWeight: '600'
              }}
              dangerouslySetInnerHTML={{ __html: activity.description_history }}
            />
          </div>
          <List
            className="List"
            scale={false}
            mh={100000}
            items={
              activity.comments != null
                ? activity.comments.map(e => ({
                    className: 'px-2',
                    style: {
                      borderBottom: 'none'
                    },
                    content: (
                      <div className="content-item d-flex">
                        <Tooltip
                          html={
                            <div style={{ color: '#fff', fontSize: 15 }}>
                              <strong>{e.name_create}</strong>
                            </div>
                          }
                          position="right"
                          theme="dark"
                        >
                          <div>
                            <Avatar className="avatar">
                              {e.name_create.substr(0, 1)}
                            </Avatar>
                          </div>
                        </Tooltip>

                        <div className="content-description ml-2 pt-1">
                          <span>{e.name_create}</span>
                          <span className="ml-2">
                            {moment(e.dt_cad).format('DD/MM/YYYY')},{' '}
                            {moment(e.dt_cad).format('HH:mm')} am
                          </span>
                          <p>{e.description}</p>
                        </div>
                      </div>
                    )
                  }))
                : []
            }
          />
        </div>
      </Dialog>

      <Dialog
        open={openstatus}
        onClose={() => SetOpenStatus(false)}
        title={language.ACTIVITY_STATUS}
        PaperProps={{
          id: 'DialogDetailSub',
          beforeTitle: (
            <h3 id="id_activity" className="mr-4">
              {language.REPORT_OR_VALUE_CONSUMED}
            </h3>
          )
        }}
      >
        <Grid container spacing={16} className="block-1">
          <Grid item xs={12}>
            <div className="content py-0 px-4 content-border-r h-100">
              <CommonForm
              values={{local:local}}
                fields={[
                  {
                    col: 6,
                    name: 'timeextend',
                    label: language.TIME_SPENT,
                    type: 'text',
                    required: true,
                    className: 'detail_activity',
                    InputProps:{
                      id:{
                        format:'##:##',
                        mask:'_',
                        placeholder:'xx:xx',
                        change(v){

                          return v.formattedValue;
                        }
                      },
                      inputComponent:MaskNumber
                    },
                    output(e){
                      return e;
                      try{
                        /*let dt = moment();
                        let e_split = e.split(':');

                        let hour = parseInt(e_split[0]);
                        let minute = parseInt(e_split[1]);

                        dt.set('hour', hour)
                        dt.set('minute', minute);*/
                        return e;
                      }catch(e){
                        return moment();
                      }

                    }
                  },
                  {
                    col: 6,
                    name: 'status',
                    label: language.STATE,
                    type: 'select',
                    className: 'detail_activity',
                    options: Array.from(Array(20), (_, i) => (i+1)*5)
                      .filter(p => p > activity.percent)
                      .map(y => {
                        return { value: y, label: y + '%' };
                      }),

                    required: true
                  },
                  {
                    col: 12,
                    name: 'descriptionhistory',
                    label: language.DESCRIPTION,
                    type: 'simplesTextEditor',
                    className: 'detail_activity',
                    required: true
                  },
                  {
                    col: 6,
                    name: 'local',
                    label: language.TYPE_OF_ACTIVITY,
                    type: 'select',
                    className: 'detail_activity',
                    options: [{ value: 1, label: language.FACE_TO_FACE },
                      { value: 2, label: language.REMOTE }],
                    required: false
                  }
                ]}
                button={{
                  label: language.SAVE,
                  color: '#3CD6C7',
                  style: {
                    backgroundColor: '#3CD6C7',
                    color: 'white'
                  }
                }}
                onChangeField={(f, v) => {

                  if(f.name ==='local')
                  SetLocal(v)
                }}
                onSubmit={e => sendStatus(e)}
              />
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        modal={false}
        title={
          openSubActivity == 1 && !Object.keys(edit_subactivity).length
            ? language.REGISTER_SUB_ACTIVITIES
            : openSubActivity == 1 && !!Object.keys(edit_subactivity).length
              ? language.EDIT_SUB_ACTIVITIES
            : ''
        }
        open={openSubActivity}
        onClose={() => {
          SetOpenSubActivity(0);
          set_edit_subactivity({});
        }}
        maxWidth="xs"
      >
        {openSubActivity == 1 ? (
          <CommonForm
            values={edit_subactivity}
            fixedValue={{
              fk_id_activity: activity.id_project_activity,
              fk_id_project: activity.fk_id_project_milestone
            }}
            fields={[
              {
                col: 12,
                name: 'name',
                label: language.ACTIVITY_NAME,
                type: 'text',
                required: true
              },
              {
                col: 12,
                name: 'fk_id_responsible',
                label: language.RESPONSIBLE,
                type: 'select',
                options: users
                  .filter(e => project.users.includes(e.id_user))
                  .map(e => ({ label: e.name, value: e.id_user })),
                required: true,
                disabled: !!Object.keys(edit_subactivity).length ? true : false
              },

              {
                col: 12,
                name: 'dt_end',
                label: language.FINAL_DATE,
                type: 'date',
                required: true,
                disabled: !!Object.keys(edit_subactivity).length ? true : false
              }
            ]}
            button={{
              className: '-mt-5'
            }}
            loading={['post-'+APIEndpoints.PROJECT + '/activity/subactivity', 'put-'+APIEndpoints.PROJECT + '/activity/subactivity']}
            onSubmit={createSubActivity}
          />
        ) : openSubActivity == 2 ? (
          <CommonForm
            values={undefined}
            fixedValue={{}}
            values={{local:local}}
            fields={[
              {
                col: 12,
                name: 'runtime',
                label: language.RUNTIME,
                type: 'text',
                required: true,
                className: 'detail_activity',

                InputProps:{
                  id:{
                    format:'##:##',
                    mask:'_',
                    placeholder:'xx:xx',
                    change(v){

                      return v.formattedValue;
                    }
                  },
                  inputComponent:MaskNumber
                }
              },
              /*{
                col: 12,
                name: 'runtime',
                label: 'Tempo de execução',
                type: 'time',
                required: true
                // error:runtimeError
              },*/
              {
                col: 6,
                name: 'local',
                label: language.TYPE_OF_ACTIVITY,
                type: 'select',
                className: 'detail_activity',
                options: [{ value: 1, label: language.FACE_TO_FACE },
                { value: 2, label: language.REMOTE }],
                required: false
              }
            ]}



            onSubmit={v => {
              setStateSubActivities([
                ...stateSubActivities,
                { id: SubActSelected, runtime: v.runtime }
              ]);
              SetOpenSubActivity(0)
            }}
            onChangeField={(f, v) => {
              if(f.name ==='local'){
              SetLocal(v)
              }

            }}
            button={{
              className: '-mt-5',
              label: language.ADD
            }}
          />
        ) : null}
      </Dialog>
    </div>
  );
}
