import React, {memo, useEffect} from 'react';
import classnames from 'classnames';
import Button from '@common/Button';

export default memo(({
	f, 
	statusError,
	_value,
	_handleValue,
	errors,
	setReadOnly,
	individual,
	readOnly
})=>{
	
	var myError = statusError[errors[f.name]];

	useEffect(()=>{
		
	}, [_value])
	
	return(
		<div className='simplesTextEditorContent mb-1'>
			{f.label&&<label className={classnames('ml-1', {error:myError})} htmlFor={f.name}>{f.label+(f.required?'*':'')}</label>}
			{f.readOnly&&f.btnEdit&&
				<div className='content-button-edit'>
					<Button
						icon='fas fa-pencil-alt'
						variant='circle'
						outlined
						style={{border:'none', fontSize:15}}
						color='#ffffff'
						onClick={() => setReadOnly(f.name, !readOnly[f.name])}
					/>
				</div>
			}
			<textarea 
				{...f}
				name={f.name}
				className={classnames('simplesTextEditor', f.className, {error:myError})} 
				rows={f.rows||5}
				readOnly={readOnly[f.name]}
				onChange={evt => _handleValue(evt.target.value, f.name)}
				value={_value ? _value : ''}
			/>
			{!individual&&
				<span className={classnames({error:myError}, 'span-helper ml-2')}>{!individual?(statusError[errors[f.name]]||f.help||' '):undefined}</span>
			}
		</div>
	)
})