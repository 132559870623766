import React, { memo, Fragment, useState } from 'react';
import classnames from 'classnames';
import Store from '@data/Store';
import { observer } from 'mobx-react-lite';
/*------------------------common------------------------*/
import Button from '@common/Button';
import DialogConfirm from '@common/DialogConfirm';
import Dialog from '@common/Dialog';
/*------------------------material-ui------------------------*/
import { Card, Grid, Checkbox } from '@material-ui/core';
/*------------------------pages------------------------*/
import Scope from './Scope';

/*-----------------------------------------------------*/
import ActivitiesDialog from './ActivitiesDialog';
import lodash from 'lodash';
import DialogTableActivities from './DialogTableActivities';

const { language } = Store;

let btnStatus = {
   3: {
      icon: 'fas fa-wrench',
      color: 'primary',
      label: language.CORRECTIVE
   },
   1: {
      icon: 'fas fa-compass',
      color: 'info',
      label: language.STRATEGIC
   },
   5: {
      icon: 'fas fa-gavel',
      color: 'danger',
      label: language.LEGAL_OBLIGATION
   },
   4: {
      icon: 'fas fa-chart-line',
      color: 'success',
      label: language.EVOLUTIONARY
   },
   2: {
      icon: 'fas fa-dumbbell',
      color: 'purple',
      label: language.IMPROVEMENT
   }
}
let titleAct = {
   'BACKLOG': language.ACTIVITIES_IN_BACKLOG,
   'TODO': language.ACTIVITIES_IN_TODO,
   'DOING': language.ACTIVITIES_IN_DOING,
   'STOPPED': language.ACTIVITIES_IN_STOPPED,
   'VALIDATION': language.ACTIVITIES_IN_VALIDATION,
   'DONE': language.ACTIVITIES_IN_DONE,
}

/*------------------------------------------FORMATO-----------------------------------------------------*/
/*
   let data = {
      id:58,
      company: 'Sensr.IT',
      name:'Projeto Teste',
      type:'corrective',
      ListAvatars:[
         {name:'Rodrigo'},
         {name:'Carla'},
         {name:'Fábio'},
         {name:'Platão'},
         {name:'Júlio'},
      ],
      Scope:'Lorem ipsum dolor sit, amet consectetur adipisicing, elit. Perspiciatis dolorum soluta dolore similique velit alias, inventore eligendi debitis sapiente sint iste quas officia excepturi, voluptatem, beatae dignissimos facilis necessitatibus, tempore!',
      EvolutionKPI:[
         {title:'Finalizado', value:4},
         {title:'Finalizado', value:4},
         {title:'Finalizado', value:4},
         {title:'Finalizado', value:4},
      ],
      ForeseenVsRealized:{
         foresseen:2009,
         realized:29100
      },
      EvolutionProject:{
         percent:50,
         dt_i:'26/03/2020',
         dt_f:'30/03/2020'
      },
      FileList:[
         {
            label:'Termo de abertura',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[
               {
                 label:'Nome do file',
                 size:9978,
                 type:'image/png'
               }
            ]
         },
         {
            label:'Termo de mudança',
            onAdd:()=>null,
            files:[]
         },
         {
            label:'Termo de encerramento',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[]
         },
         {
            label:'Anexo',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[]
         },
         {
            label:'Pauta',
            onAdd:()=>null,
            onRemoveFile:(f)=>null,
            files:[]
         },
      ]
   }
*/
/*-----------------------------------------------------------------------------------------------------*/

export default memo(observer(({ data = {}, handleModal, handleEdit, onAdd, onRemoveFile, onDownload, redirect, onEdit, removeProject, onClose, user, action, handleDelete }) => {

   let { users, companies } = Store;
   let [removeProjectConfirm, setRemoveProjectConfirm] = useState(false);
   let [open, setOpen] = useState(null);
   let [openAct, setOpenAct] = useState({});
   let [scope, setScope] = useState({});
   let [activitiesDialogOpen, setActivitiesDialogOpen] = useState(false);

   let title = {
      planned_hours: language.ACTIVITIES_PLANNED_HOURS,
      realized_hour: language.ACTIVITIES_HOURS_PERFORMED,
      exceeded_hours: language.ACTIVITIES_EXCEEDED_HOURS
   }

   return (
      <Fragment>
         <DialogConfirm
            open={removeProjectConfirm}
            onClose={() => setRemoveProjectConfirm(false)}
            title={language.WARNING_WANT_REMOVE_PROJECT}
            confirmProps={{ color: 'danger', _loading: 'delete-project_' + data.id }}
            message={data.id + ' - ' + data.name}
            onConfirm={() => removeProject({ id_project: data.id, user_cad: data.user_cad })}
         />
         <DialogTableActivities
            open={open}
            data={data.actDetail[open] || []}
            title={title[open] || ''}
            onClose={() => setOpen()}
            language
         />
         <DialogTableActivities
            open={!!Object.keys(openAct).length}
            data={openAct.data}
            title={titleAct[openAct.key]}
            onClose={() => setOpenAct({})}
            language
         />
         <Dialog
            open={!!Object.keys(scope).length}
            onClose={() => setScope({})}
            title={scope.title}
         >
            <p className='text' dangerouslySetInnerHTML={{ __html: scope.body }} />
         </Dialog>
         {!!activitiesDialogOpen &&
            <ActivitiesDialog
               open={true}
               onClose={() => setActivitiesDialogOpen(false)}
               id={activitiesDialogOpen}
               users={users}
               companies={companies}
               action={action}
               handleModal={handleModal}
               handleDelete={handleDelete}
               handleEdit={handleEdit}
            />
         }
         <li className='mb-3'>
            <Card className='content-medium'>
               <div className='d-flex align-items-center justify-content-between content-info'>
                  <div onClick={() => setActivitiesDialogOpen(data.id)} className='d-flex align-items-center'>
                     <div style={{ width: 37 }} className='d-flex align-items-center justify-content-center'>
                        <i
                           className='fas fa-check'
                           style={{ color: data.closed ? 'var(--success)' : 'var(--danger)' }}
                           title={data.closed ? language.CLOSED_PROJECT : language.OPEN_PROJECT}
                        />
                     </div>
                     <span className='id mx-2'>#{data.id}</span>
                     <Button
                        {...(btnStatus[data.type])}
                        variant='normal'
                        size={.7}
                     />
                     <span className='company-name mx-2'>{data.company} |</span>
                     <span className='project-name'>{data.name}</span>
                  </div>
                  <div className='d-flex align-items-center pt-3'>
                     <Button
                        icon='fas fa-pencil-alt'
                        color='primary'
                        outlined
                        style={{
                           border: 'none'
                        }}
                        variant='circle'
                        size={.7}
                        className={classnames({ 'mr-2': data.EvolutionProject.percent < 100 })}
                        onClick={() => onEdit(data.id)}
                     />
                     <Button
                        onClick={() => redirect('/dash/gp/report', { id_project: data.id })}
                        icon="fas fa-print"
                        className={classnames({ 'mr-2': data.EvolutionProject.percent < 100 })}
                        color="success"
                        outlined
                        variant="circle"
                        size={.7}
                        style={{
                           border: 'none'
                        }}
                     />
                     <Button
                        icon='fas fa-trash-alt'
                        color='danger'
                        outlined
                        style={{
                           border: 'none'
                        }}
                        className={classnames({ 'mx-2': data.EvolutionProject.percent == 100 })}
                        className=''
                        variant='circle'
                        onClick={() => setRemoveProjectConfirm(true)}
                        size={.7}
                     />
                     {data.EvolutionProject.percent == 100 ? (
                        <Button
                           icon='fas fa-check'
                           color='success'
                           label={language.PROJECT_CLOSE}
                           outlined
                           style={{
                              border: 'none'
                           }}
                           variant='circle'
                           _loading={'project_close_' + data.id}
                           onClick={() => onClose(data.id)}
                           size={.7}
                        />
                     ) : null}
                  </div>
               </div>
               <Grid container spacing={2}>
                  <Grid item xs={4}>
                     <Scope scope={data.Scope} label={language.SCOPE} title={data.name} setScope={setScope} />
                  </Grid>
                  <Grid item xs={4}>
                     <Scope scope={data.goal} label={language.GOAL} title={data.name} setScope={setScope} />
                  </Grid>
                  <Grid item xs={4}>
                     <Scope scope={data.risk} label={language.RISKS} title={data.name} setScope={setScope} />
                  </Grid>
               </Grid>
            </Card>
         </li>
      </Fragment>
   )
}, function (prev, next) {

   return lodash.isEqual(prev.data, next.data)
}))