import React, { useState } from 'react';
import Animate from '@common/Animate';
import { SquadList } from './SquadList';
import { SquadDelete } from './SquadDelete';
import { SquadAddOrEdit } from './SquadAddOrEdit';

export const SquadManagementList = () => {
  const [page, setPage] = useState(null);
  const [editSquad, setEditSquad] = useState(null);
  const initialSquadState = {
    id_business_line: null,
    label: '',
  };
  const [squadSelected, setSquadSelected] = useState(initialSquadState);

  return (
    <Animate>
      <SquadList
        setSquadSelected={setSquadSelected}
        setEditSquad={setEditSquad}
        setPage={setPage}
        page={page}
      />
      {page === 'delete' && (
        <SquadDelete
          closeModal={() => {
            setPage(null);
            setSquadSelected(initialSquadState);
          }}
          id={squadSelected?.id_business_line}
          message={squadSelected?.label}
        />
      )}

      {(page === 'add' || page === 'edit') && (
        <SquadAddOrEdit
          closeModal={() => {
            setSquadSelected(initialSquadState);
            setPage(null);
          }}
          page={page}
          editSquad={editSquad}
        />
      )}
    </Animate>
  );
};
