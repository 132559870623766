import Store from '@data/Store';

const { language } = Store;

const ContainerChart = {
  container0(category,  
    baixo,
    medio,
    alto,language ){
    return {
      chart: {
          type: 'column'
      },
      credits:{
        enabled:false
      },
      title: {
          text: ''
      },
      xAxis: {
        categories: 
          category
         ,
      },
      yAxis: {
          min: 0,
          title: {
              text: ''
          },
          stackLabels: {
              enabled: true,
              style: {
                  fontWeight: 'bold',
                  color: 'gray'
              }
          }
      },
     
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true,
                  color: 'white'
              }
          }
      },
      series: [
        {
          name: language.TICKET_PRIORITY.MEDIUM,
          data: medio,
          color: '#DAA520'
        },
        {
          name: language.TICKET_PRIORITY.SMALL,
          data:baixo,
          color: '#F44336'
        },
        {
          name: language.TICKET_PRIORITY.HIGH,
          data: alto,
          color: '#33ABA0'
        }
      ]
    }
  }, 
  container1(
    baixomes,
    mediomes,
     altomes,language){
    return {
      
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      credits: {
          enabled: false
      },
      xAxis: {
        
        categories: [
          language.DATE_LANG.monthList[0],
          language.DATE_LANG.monthList[1],
          language.DATE_LANG.monthList[2],
          language.DATE_LANG.monthList[3],
          language.DATE_LANG.monthList[4],
          language.DATE_LANG.monthList[5],
          language.DATE_LANG.monthList[6],
          language.DATE_LANG.monthList[7],
          language.DATE_LANG.monthList[8],
          language.DATE_LANG.monthList[9],
          language.DATE_LANG.monthList[10],
          language.DATE_LANG.monthList[11]
        ],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
              text: ''
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0;color:{series.color};"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          type: 'column',
          name: language.TICKET_PRIORITY.MEDIUM,
          data:  mediomes,
          color: '#DAA520',
          dataLabels: {
            enabled: true,
              rotation: -90,
              color: 'gray', //white
              align: 'right',
              format: '{point.y:.1f}', // one decimal
              y: -34, // 10
              style: {
                  fontSize: '11px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }
        },
        {
          type: 'column',
          name: language.TICKET_PRIORITY.HIGH,
          data: altomes,
          color: '#F44336',
          dataLabels: {
            enabled: true,
              rotation: -90,
              color: 'gray', //white
              align: 'right',
              format: '{point.y:.1f}', // one decimal
              y: -34, // 10
              style: {
                  fontSize: '11px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }
        },
        {
          type: 'line',
          name:language.TICKET_PRIORITY.HIGH,
          dashStyle: 'shortdot',
          data: altomes ,
          color: '#33ABA0'
        },
        {
          type: 'column',
          name: language.TICKET_PRIORITY.SMALL,
          data: baixomes,
          color: '#33ABA0',
          dataLabels: {
            enabled: true,
              rotation: -90,
              color: 'gray', //white
              align: 'right',
              format: '{point.y:.1f}', // one decimal
              y: -34, // 10
              style: {
                  fontSize: '11px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }
        }
      ]
    }
  },
  container2(  baixograph2,
    mediograph2,
    altograph2,language){
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      credits:{
        enabled:false
      },
      title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          y: 40
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
          pie: {
              dataLabels: {
                  enabled: true,
                  distance: -50,
                  style: {
                      fontWeight: 'bold',
                      color: 'white'
                  }
              },
              startAngle: -90,
              endAngle: 90,
              center: ['50%', '75%'],
              size: '110%'
          }
      },
      series: [{
          type: 'pie',
          name: 'Browser share',
          innerSize: '50%',
          data: [
              {
                  name: language.HIGH,
                  y: altograph2,
                  color: '#33ABA0'
              },
              {
                  name: language.MEDIUM,
                  y: mediograph2,
                  color: '#DAA520'
              },
              {
                  name: language.SMALL,
                  y: baixograph2,
                  color: '#F44336'
              }
          ]
      }]
    }
  },  
  container3({
    language,
    array1,array2,array3
  }){

    return{
      chart: {
          fontFamily:'Open Sans',
          height:220
      },
      title: null,
      credits:{
        enabled:false
      },
      subtitle: null,
      xAxis: {
          categories: language.DATE_LANG.monthLongList,
          crosshair: true,
          labels:{
            style:{
              color:'var(--primary)',
              fontSize:8
            }
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: language.VALUE,
              style:{
                color:'var(--primary)',
                fontFamily:'Open Sans'
              }
          },
          labels:{
            style:{
              color:'var(--primary)',
              fontSize:8
            }
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          },
          legend:{
            itemStyle:{
              fontSize:8
            }
          },
          bar:{
            borderRadius:3
          }
      },
      series: [{
          name: language.LOW,
          type: 'column',
          color:'var(--success)',
          data: array1

      }, {
          name: language.MEDIUM,
          type: 'column',
          color:'var(--warning)',
          data: array2

      }, {
          name: language.HIGH,
          type: 'column',
          color:'var(--danger)',
          data: array3

      }, {
          name: language.HIGH,
          type: 'line',
          color:'black',
          data: array3

      }]
    }
  },
  container4({
    days,
    language,
    array1 ,
    array2 ,
    array3 
  }){
    return{
      chart: {
          type: 'areaspline',
          height:280
      },
      title: null,
      legend: {
          itemStyle:{
            fontSize:8,
            color:'var(--primary)'
          }
      },
      xAxis: {
          categories:days,
          labels:{
            style:{
              fontSize:8,
              color:'var(--primary)'
            }
          }
      },
      yAxis: {
          title: {
              text: language.VALUE,
              style:{
                fontSize:8,
                color:'var(--primary)'
              }
          },
          labels:{
            style:{
              fontSize:8,
              color:'var(--primary)'
            }
          }
      },
      tooltip: {
          shared: true,
          valueSuffix: ' units'
      },
      credits: {
          enabled: false
      },
      plotOptions: {
          areaspline: {
              fillOpacity: 0.3
          }
      },
      series: [
        {
            name: language.LOW,
            color:'#33aba0',
            data: array1
        }, 
        {
            name: language.MEDIUM,
            color: '#f0b002',
            data: array2
        },
        {
            name: language.HIGH,
            color:'#f66868',
            data: array3
        }
      ]
    }
  },
  container5(){
    return{
      chart: {
          type: 'pie',
          width:240,
          height:240
      },
      title: null,
      credits:{
        enabled:false
      },
      accessibility: {
          announceNewData: {
              enabled: true
          },
          point: {
              valueSuffix: '%'
          }
      },

      plotOptions: {
          series: {
              dataLabels: {
                  enabled: true,
                  format: '{point.y:.1f}%',
                  style:{
                    fontSize:8,
                    color:'var(--primary)',
                    fontFamily:'Open Sans'
                  }
              }
          },
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false,
                  distance: -30,
                  style:{
                    fontSize:10
                  }
              },
              showInLegend: false
          }
      },

      tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },

      series: [
          {
              name: "Status",
              colorByPoint: true,
              data: [
                  {
                      color:'var(--danger)',
                      name: language.HIGH,
                      y: 62,
                  },
                  {
                      color:'var(--warning)',
                      name: language.MEDIUM,
                      y: 10,
                  },
                  {
                      color:'var(--primary)',
                    name: language.LOW,
                      y: 28,
                  },
                  
              ]
          }
      ],
      
    }
  }
}
export default ContainerChart;