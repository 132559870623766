import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Checkbox, Switch } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import {
  FaInfo,
  FaPlusCircle,
  FaSearch,
  FaClose,
  FaQuestion
} from 'react-icons/lib/fa';
import CommonTable from '../../common/CommonTable';
import CommonPage from '../../common/CommonPage';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

@observer
export default class ServicesList extends CommonPage {
  constructor() {
    super();
    this.state = {
      service: '',
      open: false,
      startConfirmation: false,
      stopConfirmation: false,
      available: false,
      values: {},
      servicesx: []
    };
    this.handleModal = this.handleModal.bind(this);
    this.handleModalConfirmation = this.handleModalConfirmation.bind(this);
  }

  componentWillMount() {
    action
      .execute('get', `${Constants.APIRoot}/api/inventory/${this.props._device.id_device}/service`, '')
      .then(data => {
        this.setState({ servicesx: data.data });
      })
      .catch(err => console.log('Error', err));
  }

  handleModal = (id, obj) => {
    if (id) this.props.setService(id, obj);
    this.setState({ open: !this.state.open });
  };

  handleModalConfirmation = (service, status) => {
    if (service) {
      let stats = status
        ? { service: service, stopConfirmation: true }
        : { service: service, startConfirmation: true };
      this.setState(stats);
    } else {
      this.setState({
        service: '',
        startConfirmation: false,
        stopConfirmation: false
      });
    }
  };

  renderList() {
    let {
      language,
      handlePage,
      services,
      setService,
      _device,
      serviceCmd
    } = this.props;
    let {
      values,
      available,
      service,
      servicesx,
      open,
      startConfirmation,
      stopConfirmation
    } = this.state;
    if (!_device) return <div />;
    let { id_device } = _device;

    let servicesStats = values['service-list'];

    let columns = [
      {
        Header: language.NAME,
        accessor: 'name' // String-based value accessors!
      },
      {
        Header: language.LEGEND,
        accessor: 'caption',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        Header: language.TYPE,
        accessor: 'type',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        Header: language.START_MODE,
        accessor: 'start_mode',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        Header: language.START_NAME,
        accessor: 'start_name',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        Header: language.STARTED,
        accessor: 'started',
        Cell: props => <span className="number">{props.value}</span>
      },
      {
        Header: language.STATE,
        accessor: 'state',
        Cell: props => <span className="number">{props.value}</span>
      }
    ];

    let colServicesListDialog = [
      { key: 'name', label: language._SERVICE },
      { key: 'add', label: language.ADD, style: { width: 30 } }
    ];

    let dataServicesListDialog = services
      .filter(service => !service.status)
      .map(service => ({
        name: service.name,
        add: (
          <FaPlusCircle
            className="pointer colorPrimary"
            fontSize={20}
            onClick={() =>
              this.handleModal(service.id_rel_device_service, {
                status: true,
                device: id_device
              })
            }
          />
        )
      }));

    let col = [
      { key: 'name', label: language._SERVICE },
      { key: 'control', label: language.CONTROL, style: { width: 30 } },
      { key: 'remove', label: language.REMOVE, style: { width: 30 } }
    ];

    let data = values['service-list']
      ? services
        .filter(service => service.status)
        .map(service => {
          let status = !/inactive/i.test(
            values['service-list'][service.name]
          );
          return {
            name: service.name,
            control: (
              <Switch
                toggled={status}
                onClick={() =>
                  this.handleModalConfirmation(service, status)
                }
              />
            ),
            remove: (
              <FaClose
                className="pointer colorPrimary"
                fontSize={20}
                onClick={() =>
                  setService(service.id_rel_device_service, {
                    status: false,
                    device: device.id_device
                  })
                }
              />
            )
          };
        })
      : [];

    let listServicesDialog = (
      <Dialog

        open={open}
        onClose={() => this.handleModal()}
        title={language.SERVICE}
      >


        <CommonTable
          hideFooterToolbar
          title={''}
          language={language}
          columns={colServicesListDialog}
          data={dataServicesListDialog}
        />

      </Dialog>
    );

    let startDialog = (
      <Dialog

        open={startConfirmation}
        onClose={() => this.handleModalConfirmation()}
        title={language.CONFIRMATION}
      >


        <Container>
          <Row>
            <Col md={12}>
              <h2>{language.CONFIRM_START_SERVICE}: {service.name}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <RaisedButton
                label={language.YES}
                primary
                fullWidth
                onClick={() => {
                  serviceCmd('start', service.id_rel_device_service);
                  this.handleModalConfirmation();
                }}
              />
            </Col>
            <Col md={6}>
              <RaisedButton
                label={language.CANCEL}
                primary
                fullWidth
                onClick={() => this.handleModalConfirmation()}
              />
            </Col>
          </Row>
        </Container>

      </Dialog>
    );

    let stopDialog = (
      <Dialog

        open={stopConfirmation}
        onClose={() => this.handleModalConfirmation()}
        title={language.CONFIRMATION}
      >


        <Container>
          <Row>
            <Col md={12}>
              <h2>Deseja parar este Serviço: {service.name}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <RaisedButton
                label={language.YES}
                primary
                fullWidth
                onClick={() => {
                  serviceCmd('stop', service.id_rel_device_service);
                  this.handleModalConfirmation();
                }}
              />
            </Col>
            <Col md={6}>
              <RaisedButton
                label={language.CANCEL}
                primary
                fullWidth
                onClick={() => this.handleModalConfirmation()}
              />
            </Col>
          </Row>
        </Container>

      </Dialog>
    );

    return (
      <PageLayout
        icon={<div className="monitorIcon" style={{ width: 44, height: 44 }} />}
        title={language.SERVICE}
        subtitle={language.SERVICE_LIST}
        rightElements={[
          <RaisedButton
            label={language.BACK}
            circleButton
            icon='fas fa-arrow-left'
            color='primaryGradient'
            primary={true}
            onClick={() => window.location.reload()}
          />
        ]}
      >
        <Container fluid style={{ marginTop: 10 }}>
          <Card>
            {/* <CommonTable title={''} language={language} columns={col} data={data} />*/}

            <ReactTable data={servicesx} columns={columns} />
          </Card>
        </Container>
        {listServicesDialog}
        {startDialog}
        {stopDialog}
      </PageLayout>
    );
  }

  render() {
    if (this.state.servicesx.length) return this.renderList();
    else {
      let { language, handlePage, _device, serviceDiscovery } = this.props;
      if (!_device) return <div />;
      let { id_device } = _device;

      return (
        <PageLayout
          icon={
            <div className="monitorIcon" style={{ width: 44, height: 44 }} />
          }
          title={language.SERVICE}
          subtitle={language.SERVICE_LIST}
          rightElements={[
            <RaisedButton
              label={language.BACK}
              primary={true}
              circleButton
              icon='fas fa-arrow-left'
              color='primaryGradient'
              onClick={() => handlePage('monitor')}
            />
          ]}
        >
          <Card className="cardDefault">
            <h2>{language.SERVICE_DISCOVERY}</h2>
          </Card>
        </PageLayout>
      );
    }
  }
}
