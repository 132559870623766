import './index.css';
import React, { useState } from 'react';
import { Card, Grid } from '@material-ui/core';

import Header from '../../../common/Header';

import Totals from './Contracts/Totals';
import ContractValue from './Contracts/ContractValue';
import ContractBilling from './Contracts/ContractBilling';

import CAPEX_X_OPEX from './Predicted_VS_Accomplished/CAPEX_X_OPEX';
import AccumulatedTotal from './Predicted_VS_Accomplished/AccumulatedTotal';
import Predicted_VS_Accomplished from './Predicted_VS_Accomplished/Predicted_VS_Accomplished';

export default function ({
  language,
  contracts,
  ContractBilling_data,
  ContractValue_data,
  ContractValue_data_ago,
  Totals_data,
  budgetcontrols,
  filterMonthsData,
  AccumulatedTotal_data,
  CAPEX_X_OPEX_data,
  total_acumulatedTheme,
  currentYear,
  action,
  APIEndpoints,
}) {
  let [aba, setAba] = useState(0);
  return (
    <div id="Block_1" className="mb-3">
      <Header
        btnFilter={[language.CONTRACTS, `${language.FORESEEN} X ${language.REALIZED}`]}
        onChange={(i) => setAba(i)}
        active={aba}
      />
      <Card className="content">
        {aba === 0 ? (
          <Grid container spacing={16}>
            <Grid item xs={5}>
              <ContractBilling
                data={ContractBilling_data}
                language={language}
              />
            </Grid>

            <Grid item xs={4}>
              <ContractValue
                data={ContractValue_data}
                language={language}
                type="vigor"
              />
            </Grid>

            <Grid
              item
              xs={3}
              style={{ '--total_acumulatedTheme': total_acumulatedTheme }}
            >
              <Totals {...Totals_data} language={language} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Predicted_VS_Accomplished
                currentYear={currentYear}
                filterMonthsData={filterMonthsData}
                budgetcontrols={budgetcontrols}
                language={language}
                contracts={contracts}
              />
            </Grid>

            <Grid item xs={3}>
              <AccumulatedTotal
                {...AccumulatedTotal_data}
                language={language}
              />
            </Grid>

            <Grid item xs={3}>
              <CAPEX_X_OPEX {...CAPEX_X_OPEX_data} language={language} />
            </Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
}
