import React from 'react';
import { Card } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';

import { Graph_1 } from './Graph';

import AppBar from '../../../../common/AppBar2';

export default function ({ language, data = [] }) {
  return (
    <Card>
      <AppBar title={language.CONTRACTUAL_BILLING} subTitle={`(${language.PER} ${language.MONTH})`} />
      <div className="content-medium pt-0">
        <ReactHighcharts
          config={Graph_1({
            categories: language.DATE_LANG.monthLongList,
            data,
            language
          })}
        />
      </div>
    </Card>
  );
}
