'use strict';
let eventBus = class{
	constructor(){
		this.callbackLists = {};
		this.id = 0;
	}

    emit = (eventName, data) => {
        let callbackList = this.callbackLists[eventName]
        if (!callbackList) {
            return
        }
        
        Object.keys(callbackList).forEach(key => callbackList[key](data));
    }

    on = (eventName, callback) => {
    	this.id+=1;
        if (!this.callbackLists[eventName]) {
            this.callbackLists[eventName] = {}
        }
        this.callbackLists[eventName][this.id] = callback;

        return { 
            unSubscribe: () => this.unSubscribe(eventName, this.id),
            eventName,
            id:this.id
        }
    }

    unSubscribe = (eventName, id, unsubscribeThisAll=false)=>{
		if(this.callbackLists[eventName]){
			if(this.callbackLists[eventName][id]){
		        delete this.callbackLists[eventName][id]
		        if(Object.keys(this.callbackLists[eventName]).length === 0) delete this.callbackLists[eventName];
			}else{
				if(unsubscribeThisAll){
					delete this.callbackLists[eventName];
				}
			}
		}
    }

    unSubscribeAll = ()=>{
    	this.callbackLists = {};
    }
}

if(!window.$eventBus){
    
    window.$eventBus = new eventBus();
}

export default eventBus;