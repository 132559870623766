import React, { useEffect, useState } from 'react';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../../../common/CommonForm2';
import CommentAttendance from '../../components/CommentAttendance';
import { formshtml } from '../../utils/formshtml';
import decrypt from '../../../../util/decrypt';
import crypt from '../../../../util/crypt';
import { Grid } from '@material-ui/core';
import Button from '../../../../common/Button';
import AttachFile from '@common/AttachFile';
import { findUserGroup } from '../../utils/findUserGroup';
import moment from 'moment';

const StepAttendance = ({
  APIEndpoints,
  setOpenStepDialog,
  Attendance,
  Store,
  updateAttendance,
  stepToShow,
  language,
  action,
  onDownFileStep,
  updateState,
  approveParecer,
  cancelParecer,
  startParecer,
  json_template_solicitation,
  setPageStep,
}) => {
  const [form, setForm] = useState(null);
  const [currentStep, setCurrentStep] = useState();
  const [dialogExtraTime, setDialogExtraTime] = useState();

  const persistStep = (values, closeModal = true) => {
    let url = '/updateModalStepsAttendance'
    if (approveParecer) {
      url = '/resolved/' + values.id_sac_steps
    } else if (cancelParecer) {
      url = '/canceled'
    }

    values.id_attendance_sac = Attendance.id_attendance_sac;
    let tokenApi = Store.getToken();
    if (startParecer) {
      values.stage = 'In Progress'
    }
    values.place = 'sac_step';
    values.user = Store.getUserLoged();

    action
      .execute('put', APIEndpoints.SAC_STEPS + url, '', values)
      .then(() => {
        setPageStep('');
        updateAttendance();
      });
    if (approveParecer || cancelParecer) {
      setOpenStepDialog(false);
    }
  };

  const startStep = (closeModal = true) => {
    startParecer = true;
    persistStep({id_sac_steps: currentStep.id_sac_task, template: currentStep?.template}, closeModal)
  }

  useEffect(() => {
    if (Attendance?.steps && Attendance?.steps.length > 0) {
      Attendance.steps.map((step) => {
        if (stepToShow.id_sac_task == step.id_sac_task) {
          setCurrentStep(step)
        }
      })
    } else {
      setPageStep('');
      updateAttendance();
      setOpenStepDialog(false);
    }

  }, [Attendance])

  const updateStep_ = (values) => {
    let fields = [];
    let campossize = false;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;

    if (!values.files) {
      $('#formavaliation').html();
      if ($('#formavaliation').html() != undefined) {
        $('#formavaliation textarea').each(function () {
          $(this).attr('value', $(this).val());

          $(this).html($(this).val());
          fields.push({ name: $(this).attr('name'), value: $(this).val() });
        });

        $('#formavaliation input').each(function () {
          if ($(this).attr('type') == 'radio') {
            if (
              $('input[name=' + $(this).attr('name') + ']:checked').val() ==
              $(this).val()
            ) {
              $(this).attr('checked', true);
              fields.push({
                name: $(this).attr('name'),
                value: $(this).attr('value'),
              });
            } else {
              $(this).removeAttr('checked');
            }
            let ip = $(this);

            $('#formavaliation  label').each(function () {
              var for_ = '';
              try {
                for_ = $(this).attr('for');
              } catch (e) {}

              if (for_ === ip.attr('name')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatoriosradio = true;
                    $('[name=' + for_ + ']').each(function () {
                      try {
                        if ($(this).attr('checked')) {
                          camposobrigatoriosradio = false;
                        }
                      } catch (e) {}
                    });
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'checkbox') {
            if (
              $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
              $(this).attr('id')
            ) {
              $(this).attr('checked', true);
              fields.push({
                name: $(this).attr('name'),
                value: $(this).attr('value'),
              });
            } else {
              $(this).removeAttr('checked');
            }
            let ip = $(this);
            $('#formavaliation  label').each(function () {
              var for_ = ' ';
              try {
                for_ = $(this).attr('for');
              } catch (e) {}

              if (ip.attr('name').indexOf(for_) > -1) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    camposobrigatorioscheck = true;
                    $('#formavaliation  input').each(function () {
                      try {
                        if ($(this).attr('name').indexOf(for_) > -1) {
                          try {
                            if ($(this).attr('checked')) {
                              camposobrigatorioscheck = false;
                            }
                          } catch (e) {}
                        }
                      } catch (e) {}
                    });
                  }
                }
              }
            });
          } else if ($(this).attr('type') == 'datetime-local') {
            const userLogin = Store.getUserLoged();
            const timezone = userLogin?.timezone?.value;

            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: moment($(this).val()).tz(timezone).utc().toISOString(),
            });
            $(this).attr('value', $(this).val());
          } else {
            $(this).attr('value', $(this).val());
            fields.push({
              name: $(this).attr('name') || $(this).attr('id'),
              value: $(this).attr('value'),
            });
            let siz = $(this).attr('max');
            let min = $(this).attr('min');
            if (siz) {
              if (
                parseInt($(this).val().length) > parseInt(siz) ||
                parseInt($(this).val().length) < parseInt(min)
              ) {
                $(this).css('border-color', 'red');
                $(this).before(
                  `<label  id="formtemplabel__" style='color:red'>  ${
                    min || 0
                  } á ${siz || 0} caracteres</label>`
                );

                campossize = true;
              }
            }

            let ip = $(this);
            $('#formavaliation  label').each(function () {
              if ($(this).attr('for') === ip.attr('id')) {
                if ($(this).text().split('*')[1] != undefined) {
                  if ($(this).text().split('*')[1].length >= 0) {
                    if (ip.attr('value').length == 0) {
                      camposobrigatorios = true;
                    }
                  }
                }
              }
            });
          }
        });

        $('#formavaliation select').each(function () {
          $('#' + $(this).children('option:selected').attr('id')).attr(
            'selected',
            'selected'
          );

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).find(':selected').text(),
          });
        });

        $('#formavaliation label').each(function () {
          try {
            if ($(this).attr('for').indexOf('textarea') > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  // alert($('#'+$(this).attr('for')).text())

                  let node_ = $(this)
                    .parent()
                    .parent()
                    .nextAll()
                    .slice(2, 3)
                    .children()[0];
                  if (
                    (node_ &&
                      node_.firstChild.tagName == 'P' &&
                      node_.firstChild.textContent.length == 0) ||
                    (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                  ) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          } catch (e) {}
        });
      }

      setTimeout(function () {
        $('#formtemplabel__').fadeOut().empty();
        $('#formavaliation .form-control').css('border-color', '#019fc2');
      }, 1000);
      if (Object.keys(crypt($('#formavaliation').html())).length > 0) {
        values.template = crypt($('#formavaliation').html());
        values.json_template_solicitation = json_template_solicitation;
        values.fields = fields;
      }
    }

    if (
      camposobrigatorios == false &&
      campossize == false &&
      camposobrigatorioscheck == false &&
      camposobrigatoriosradio == false
    ) {
      values.user = Store.getUserLoged();
      values.id_sac_steps = currentStep.id_sac_task;
      values.place = 'sac_step';
      let files = [];
      let cont = 0;

      if (Array.isArray(values.files)) {
        Array.from(values.files).forEach((file) => {
          let total = Array.from(values.files).length;
            const promise = new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                if (!!reader.result) {
                  resolve(reader.result);
                } else {
                  reject(Error('Failed converting to base64'));
                }
              };
            });

            promise.then((result) => {
              if (file.size >= 40000000) {
                alert(language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB);
                filesSend = [];
                cont = 0;
                setTimeout(
                  function () {
                    //8this.redirect('/dash/gsd');
                  }.bind(this),
                  1000
                );
              }
              files.push({
                file: result,
                type: file.type,
                namefile: file.name,
              });
              cont = cont + 1;
              if (total === cont) {
                values.files = files;
                persistStep(values);
              }
          });
        })
      } else {
        persistStep(values);
      }
    } else {
      if (
        camposobrigatorios ||
        camposobrigatoriosradio ||
        camposobrigatorioscheck
      )
        Store.toggleAlert(true, 'Informe dos campos obrigatórios', 'error');

      if (campossize)
        Store.toggleAlert(true, 'Verifique o tamanho dos campos', 'error');
    }
  };

  const onRemoveFileAttendance = (id_attachment_attendance_sac, user_cad) => {
    if (user_cad !== Store.getUserLoged().id) {
      Store.toggleAlert(true, 'Não é permitido excluir anexo de outro usuário', 'error');
    } else {
      action
      .execute('put', APIEndpoints.SAC_ATTENDANCE + '/attachment', '', {
        user: Store.getUserLoged(),
        id_attachment_attendance_sac: id_attachment_attendance_sac,
      })
      .then(() => updateAttendance());
    }
  };

  const handleSetExtraTime = (values) => {
    values.user = Store.getUserLoged();
    values.id_sac_step = currentStep.id_sac_task;
    values.id_attendance_sac = Attendance.id_attendance_sac;
    values.fk_id_user = currentStep.fk_id_user;
    values.name = currentStep.name;
    action
      .execute('post', APIEndpoints.SAC_STEPS + '/extratime', '', values)
      .then(() => {
        updateAttendance();
        setDialogExtraTime(false);
      });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        updateAttendance();
        setOpenStepDialog(false)
      }}
      title={currentStep?.name}
      miniVersion
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '100%',
        },
      }}
    >
      <div>
        {currentStep?.template && (
          <>
            <p style={{ color: '#888080' }} id={'formavaliation'}>
              {formshtml(decrypt(currentStep.template), updateState)}
            </p>
            {findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) &&
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginRight: '-8px'}} >
              <Button
                variant="normal"
                label="Salvar Formulário"
                color="primary"
                style={{ fontSize: 15, marginRight: 10 }}
                onClick={() => {
                  updateStep_({template: currentStep?.template, stage: currentStep?.stage})
                }}
              />
            </div>
            }
          </>
        )}
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <Grid item xs={12}>
            <div className="mb-2 d-flex align-items-center">
              <i
                className="fas fa-comments mr-2"
                style={{
                  fontSize: 14,
                  color: 'var(--warning)',
                }}
              />
              <h3 className="title-aba">Parecer</h3>
            </div>
            <CommentAttendance
              updateAttendance={updateAttendance}
              language={language}
              ticket={Attendance}
              APIEndpoints={APIEndpoints}
              step={currentStep}
              Attendance={Attendance}
              statusTicket={Attendance.status}
              maxHeightChat={35}
              startStep={startStep}
            />
          </Grid>
          <CommonForm2
            onRef={(r) => setForm(r)}
            beforeButtonElement={
              findUserGroup(Attendance.info_user_loged, Attendance.fk_id_group_attend) &&
              currentStep?.stage == 'In Progress' && Attendance?.status !== 'Resolved' && (
                <>
                  <Button
                    variant="normal"
                    color="danger"
                    label="Adicionar tempo"
                    style={{ fontSize: 15, marginRight: 10 }}
                    onClick={() => setDialogExtraTime(true)}
                  />
                  <Button
                    variant="normal"
                    color="warning"
                    label="Cancelar Parecer"
                    style={{ fontSize: 15, marginRight: 10 }}
                    onClick={() => {
                      cancelParecer = true;
                      persistStep({id_sac_steps: currentStep.id_sac_task, template: currentStep?.template})
                    }}
                  />
                  <Button
                    variant="normal"
                    label="Aprovar Parecer"
                    color="primary"
                    style={{ fontSize: 15, marginRight: 10 }}
                    onClick={() => {
                      approveParecer = true;
                      persistStep({id_sac_steps: stepToShow.id_sac_task, template: stepToShow?.template})
                    }}
                  />
                </>
              )
            }
            onChangeField={(f, v) => {
              if (currentStep?.stage !== 'Conclued' ||
                currentStep?.stage !== 'Canceled') {
                updateStep_({files: v, stage: currentStep?.stage, template: currentStep?.template})
                form.clean();
              }
            }}
            fields={[
              {
                col: 12,
                name: 'files',
                label: language.ATTACH_FILE,
                type: 'file',
                visible: currentStep?.stage !== 'Canceled'
              },
            ]}
          />
        </div>
      </div>
      <h3 className="title" style={{ textTransform: 'none' }}>
        Anexos
      </h3>
      {Attendance?.files_steps != undefined
        ? Attendance.files_steps.map((f) => (
            <AttachFile
              label={f.file}
              onClick={() =>
                onDownFileStep(f.id_attachment_attendance_file, f.file)
              }
              onDelete={() =>
                onRemoveFileAttendance(f.id_attachment_attendance_file, f.user_cad)
              }
              style={{ maxWidth: '100%' }}
            />
          ))
        : null}
        {dialogExtraTime && (
         <Dialog
         open={true}
         onClose={() => {
           setDialogExtraTime(false)
         }}
         title="Adicionar tempo extra para o responsável atual"
         miniVersion
         maxWidth="sm"
        >
          <CommonForm2
            fields={[
              {
                col: 12,
                name: 'extra_time',
                format: '###:##',
                label: 'Tempo Extra',
                type: 'text',
              },
            ]}
            onSubmit={handleSetExtraTime}
          />
        </Dialog>
        )}
    </Dialog>
  );
};

export default StepAttendance;
