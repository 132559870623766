import { Card, Paper } from '@material-ui/core';
import AppBar from '@common/AppBar2';
import CommonForm2 from '../../common/CommonForm2';
import Store from '@data/Store';
import { useEffect, useState } from 'react';
import Constants from '../../data/Constants';
import Actions from '@data/Actions';

const action = new Actions();

const SlaStatusAllowance = () => {
  const { language } = Store;

  const [values, setValues] = useState()

  const loadParam = () => {
    action
      .execute('get', Constants.APIEndpoints.GENERAL_PARAMETERS, '')
      .then((res) => {
        setValues({ TICKET_ALLOWANCE_STATUS:  res.data.ticket_allowance_status });
      });
  };

  useEffect(() => {
    loadParam();
  }, []);

  const onFormSubmit = (data) => {
    const filters = { TICKET_ALLOWANCE_STATUS: data.TICKET_ALLOWANCE_STATUS };
    
    action
      .execute('put', Constants.APIRoot + '/api/generalparameters', '', filters)
      .then(() => {
        Store.toggleAlert(true, language.REGISTER_SAVE, 'success');
      })
      .catch(() => {
        Store.toggleAlert(true, language.ERROR_CODE.E500, 'error');
      })
  };

  const ticketAllowanceStatus = [
    {
      col: 4,
      type: 'select',
      name: 'TICKET_ALLOWANCE_STATUS',
      options: [
        { value: 0, label: language.TICKET_STATUS.RESOLVED },
        { value: 1, label: language.TICKET_STATUS.CLOSED },
        { value: 2, label: language.ALL },
      ],
      label: language.STATUS,
    },
  ];

  return (
    <Card className="content">
      <AppBar title={language.STATUS_TICKET_ALLOWANCE} />
      <div style={{ marginLeft: 20, marginRight: 20, height: 175 }}>
        <CommonForm2
          values={values}
          fields={ticketAllowanceStatus}
          onSubmit={onFormSubmit}
          button={{ label: language.SAVE }}
        />
      </div>
    </Card>
  );
};

export default SlaStatusAllowance;
