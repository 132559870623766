import CommonForm from '@common/CommonForm2';
import Tag from '@common/Tag';
import Constants from '@data/Constants';
import Store from '@data/Store';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import DownloadFiles from '../../util/DownloadFiles';
import Sla from '../TicketManagement/Sla';
import ServicePortalWrapper from './ServicePortalWrapper';
import { EmptyGrid } from './components/EmptyState';
import { GridLineItem } from './components/GridUtils';
import LinearProgressWithLabel from './components/LinearProgressWithLabel';
import ServicePortalChatMessages from './components/ServicePortalChatMessages';
import ServicePortalModalObservant from './components/ServicePortalModalObservant';
import { ServicePortalSubHeader } from './components/ServicePortalSubHeader';
import ServicePortalTicketDescription from './components/ServicePortalTicketDescription';
import ServicePortalTicketDesignationHistory from './components/ServicePortalTicketDesignationHistory';
import ServicePortalTicketFormView from './components/ServicePortalTicketFormView';
import ServicePortalTicketHeader from './components/ServicePortalTicketHeader';
import ServicePortalTicketHistory from './components/ServicePortalTicketHistory';
import ServicePortalTicketICS from './components/ServicePortalTicketICS';
import ServicePortalTicketRelations from './components/ServicePortalTicketRelations';
import ServicePortalTicketSlaHistory from './components/ServicePortalTicketSlaHistory';
import ServicePortalTicketSubTitle from './components/ServicePortalTicketSubTitle';
import ServicePortalTicketTasks from './components/ServicePortalTicketTasks';
import {
  PRIORITY_COLOR_MAP,
  decrypt,
  getIndexByProperty,
  getPriorityMap,
  getStatusMap,
  getTicketStatusValues,
  mergeDeep,
  randomId,
  spFetch,
  useServicePortalStyles
} from './helpers';
import Progress from '../../common/Progress';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  );
}

function getSlaColor(sla) { 
  let slaColor = 'green';
  if (sla.progress > 99) {
    slaColor = 'red';
  } else if (sla.progress > 69) { 
    slaColor = 'yellow';
  }
  return slaColor;
}

export default function ServicePortalTicketDetails({
  tabItems,
  forceTicketId,
}) {
  const { language } = Store;
  const styles = useServicePortalStyles();
  const { id } = useParams();
  const userLogged = Store.getUserLoged();

  const isReadonly = !!forceTicketId;

  const PRIORITY_MAP = getPriorityMap(language);
  const STATUS_MAP = getStatusMap(language);

  const tabsIdentifier = {
    DETAILS: 'DETAILS',
    HISTORIC_CALL: 'HISTORIC_CALL',
    HISTORIC_SLA: 'HISTORIC_SLA',
    SUB_CALLS: 'SUB_CALLS',
    DEVICE_CONF: 'DEVICE_CONF',
    DESIGNATION_HISTORY: 'DESIGNATION_HISTORY',
    RELATION_TICKETS: 'RELATION_TICKETS',
  };

  const [urgencies, setUrgencies] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [ticketParams, setTicketParams] = useState();
  const [statusAwaiting, setStatusAwaiting] = useState([]);
  const [hasStructureData, setHasStructureData] = useState(false);
  const [sla, setSla] = useState([]);

  const [isLoading, setIsLoading] = useReducer((s, v) => v ?? !s, false);
  const [isDataLoaded, setIsDataLoaded] = useReducer((s, v) => v ?? !s, false);
  const [ticketData, setTicketData] = useState({});

  const [isOpenModalObservant, setIsOpenModalObservant] = useState(false);

  const [tabActive, setTabActive] = useState(tabsIdentifier.DETAILS);

  const [tabs, setTabs] = useState([
    { id: tabsIdentifier.DETAILS, label: language.DETAILS, name: 'DETAIL' },
    {
      id: tabsIdentifier.HISTORIC_SLA,
      label: language.HISTORIC_SLA,
      name: 'SLA_HISTORY',
    },
    {
      id: tabsIdentifier.DEVICE_CONF,
      label: language.DEVICE_CONF,
      name: 'CONFIGURATION_ITEMS',
    },
    {
      id: tabsIdentifier.DESIGNATION_HISTORY,
      label: language.DESIGNATION_HISTORY,
      name: 'DESIGNATION_HISTORY',
    },
    {
      id: tabsIdentifier.RELATION_TICKETS,
      label: language.RELATION_TICKETS,
      name: 'RELATION_TYPE',
    },
  ]);

  useEffect(() => {
    const urgencies = sessionStorage.getItem('urgencies');
    const impacts = sessionStorage.getItem('impacts');
    const priorities = sessionStorage.getItem('priorities');
    const ticketParams = sessionStorage.getItem('ticketParams');

    setUrgencies(JSON.parse(decrypt(urgencies)));
    setImpacts(JSON.parse(decrypt(impacts)));
    setPriorities(JSON.parse(decrypt(priorities)));

    const _ticketParams = JSON.parse(decrypt(ticketParams));
    setTicketParams(
      Array.isArray(_ticketParams) ? _ticketParams[0] : _ticketParams
    );

    setHasStructureData(true);
  }, []);

  useEffect(() => {
    hasStructureData && getData();
  }, [hasStructureData]);

  function getData() {
    if (isNaN(id)) {
      setTicketData(null);
      setIsLoading(false);
      setIsDataLoaded(true);

      return;
    }

    setIsLoading(true);
    const fetchTicket = spFetch(`${Constants.APIEndpoints.TICKET}/${id}`);
    const fetchAwaiting = spFetch(Constants.APIEndpoints.STATUS_AWAITING);
    const fetchTicketTask = spFetch(
      `${Constants.APIEndpoints.TASKS}/ticket/${id}`
    );
    const fetchTicketFormValue = spFetch(
      `${Constants.APIEndpoints.TICKET}/template/html/${id}`
    );
    const fetchTicketSla = spFetch(
      `${Constants.APIEndpoints.TICKET}/sla/${id}`
    );

    const requests = [
      fetchTicket,
      fetchAwaiting,
      fetchTicketTask,
      fetchTicketFormValue,
      fetchTicketSla
    ];

    if (ticketParams.notes_tickets_as_table) {
      const fetchTicketNotes = spFetch(
        `${Constants.APIEndpoints.TICKET}/getnotes/${id}`
      );
      requests.push(fetchTicketNotes);
    }

    Promise.all(requests)
      .then((results) => {
        const resultTicket = results[0]?.data ?? {};
        const resultAwaiting = results[1]?.data ?? [];
        const resultTasks = results[2]?.data ?? [];
        resultTicket._tasks = resultTasks;

        const resultFormValue = results[3]?.data;

        if (resultFormValue?.html_values)
          resultTicket._formValue = JSON.parse(resultFormValue?.html_values);

        let resultSla = results[4]?.data ?? [];
        resultSla = resultSla?.find(s => s.sh_active && !s.sh_stop) || {};

        setSla(resultSla);
        processResultTicketData(resultTicket, resultAwaiting, resultSla);

        ['Open', 'Reopened', 'In Progress'].includes(resultTicket.status) &&
          handleKeepUpdate(resultTicket.config[0].real_id);
      })
      .catch((e) => {
        console.error(e);
        setTicketData(null);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDataLoaded(true);
      });
  }

  function getTicketData() {
    spFetch(`${Constants.APIEndpoints.TICKET}/${id}`).then(({ data }) => {
      updateTicketData(data);
    });
  }

  function handleKeepUpdate(id) {
    Store.socket.on(id, (data) => {
      if (data.usernotrefresh != Store.getUserLoged().id) {
        getTicketData();
        Store.toggleAlert(
          true,
          `${data.usernamerefresh} ${language.UPDATED_CALL}`,
          'success'
        );
      }
    });
  }

  function processResultTicketData(resultTicket, resultAwaiting, sla) {
    if (Object.keys(resultTicket).length > 0) {
      resultTicket._updateControl = randomId();
      //resultTicket.___updateControl = randomId();
      const [config] = resultTicket.config;
      resultTicket.forms = config.forms;

      resultTicket._subject = [config.real_id, resultTicket.subject].join(': ');

      const _priorityIndex = getIndexByProperty(
        resultTicket,
        priorities,
        'fk_id_priority'
      );
      resultTicket._priorityLabel = PRIORITY_MAP[_priorityIndex];
      resultTicket._priorityColor = PRIORITY_COLOR_MAP[_priorityIndex];

      const _urgencyIndex = getIndexByProperty(
        resultTicket,
        urgencies,
        'fk_id_urgency'
      );
      resultTicket._urgencyLabel = PRIORITY_MAP[_urgencyIndex];
      resultTicket._urgencyColor = PRIORITY_COLOR_MAP[_urgencyIndex];

      const _impactIndex = getIndexByProperty(
        resultTicket,
        impacts,
        'fk_id_impact'
      );
      resultTicket._impactLabel = PRIORITY_MAP[_impactIndex];
      resultTicket._impactColor = PRIORITY_COLOR_MAP[_impactIndex];

      resultTicket._ticketType = getTicketType(resultTicket);

      const statusValues = getTicketStatusValues(
        language,
        resultTicket.status,
        config.stop_ticket
      );
      resultTicket._ticketStatus = statusValues.label;
      resultTicket._ticketStatusColor = statusValues.color;

      resultTicket._ticketStatusReason = getStatusReason(
        resultTicket,
        resultAwaiting
      );

      resultTicket._isFinished = ['Closed', 'Resolved', 'Cancelled'].includes(
        resultTicket.status
      );
      resultTicket._isClosedOrResolved = ['Closed', 'Resolved'].includes(
        resultTicket.status
      );
      resultTicket._isWorkable = ['Open', 'Reopened', 'In Progress'].includes(
        resultTicket.status
      );

      const icon =
        resultTicket.type_tickets == 'Solicitation'
          ? 'fa-user-plus'
          : resultTicket.type_tickets == 'Problem'
          ? 'fa-exclamation-triangle'
          : 'fa-bell';
      resultTicket._ticketTypeIcon = icon;

      resultTicket._isOwner = userLogged.id == resultTicket.fk_id_user;
      resultTicket._hasIntegration = ticketParams.integration;

      resultTicket._sla = config.arraypr.sla_name_param
        ? config.arraypr.sla_name_param
        : config.sla_task + `h`;

      resultTicket._observers = config.observers || [];
      resultTicket._observers.forEach((item) => {
        item.props = {};
        if (resultTicket._isWorkable)
          item.props.onDelete = () =>
            setRelationObserver(
              resultTicket.id_tickets,
              [item.fk_id_user],
              true
            );
      });

      //## Files
      const filesrequest = config.filesrequest || [];
      const files = config.files || [];
      const fileItems = [...filesrequest, ...files].map((fileItem) =>
        formatFileItem(fileItem, resultTicket)
      );
      resultTicket._files = fileItems;

      //## Tasks
      if (resultTicket._tasks?.length > 0) {
        const taskItems = resultTicket._tasks;
        const totalTasks = taskItems.length;

        const totalFinishTasks = taskItems.filter(
          (taskItem) => taskItem.tasks[0].date_end !== null
        ).length;
        const progress = (totalFinishTasks * 100) / totalTasks;

        let hasPendingTasks = false;

        const ticketTasks = taskItems.map((ticketTask, index) => {
          const existOpenPredecessors =
            ticketTask.predecessors.length &&
            !['CONCLUIDO', 'CANCELADO', 'FALHA', 'APROVADO'].includes(
              ticketTask.predecessors[0].status
            );

          if (
            ['PENDENTE APROVAÇÃO'].includes(ticketTask.tasks[0].status) &&
            ticketParams.block_ticket_with_tasks_pendent == true
          ) {
            hasPendingTasks = true;
          }

          return {
            ...ticketTask,
            existOpenPredecessors,
          };
        });

        resultTicket._tasksProgress = progress;
        resultTicket._tasksProgressLabel = parseFloat(progress).toFixed(2);
        resultTicket._hasPendingTasks = progress;
        resultTicket._ticketTasks = ticketTasks;
      } else {
        resultTicket._ticketTasks = [];
      }

      resultTicket._requestBlock = [
        {
          label: 'ID',
          text: config.real_id,
        },
        {
          label: language.STATUS,
          content: (
            <Tag
              label={resultTicket._ticketStatus}
              color={resultTicket._ticketStatusColor}
              style={{ fontSize: 'inherit' }}
            />
          ),
        },
        {
          label: language.PROGRESS,
          content: (
            <LinearProgressWithLabel
              variant="determinate"
              value={config.progress ?? 0}
            />
          ),
        },
        {
          label: language.REGISTRATION_DATE,
          text: moment(resultTicket.dt_cad).format('L LT'),
          icon: 'fa-calendar',
        },
        {
          label: resultTicket.dt_approver_request
            ? language.APPROVAL_DATE
            : language.CATEGORIZATION_DATE,
          text: moment(
            resultTicket.dt_approver_request || resultTicket.dt_cad
          ).format('L LT'),
          icon: 'fa-calendar',
        },
        {
          label: language.TICKET_TIME,
          content:
            ticketParams.sla_params && config.arraypr.sla_date_hr_param ? (
              <>
                <i className={`far fa-clock tw-mr-2`} />
                <Typography component="span" className={styles.textEmphasis}>
                  {config.arraypr.sla_date_hr_param}
                </Typography>
              </>
            ) : (
              getSlaOutput(resultTicket, resultAwaiting)
            ),
        },
        {
          label: language.TOTAL_TICKET_TIME,
          text: config.time_total,
          isHidden: !resultTicket._isClosedOrResolved,
        },

        {
          label: language.ID_CORRELATION,
          text: config.arraypr.correlation_id ?? '--',
          isHidden: !resultTicket._hasIntegration,
        },
        {
          label: language.ID_DISPLAY,
          text: config.arraypr.correlation_display ?? '--',
          isHidden: !resultTicket._hasIntegration,
        },
      ];

      const slaColor = getSlaColor(sla);
      resultTicket._categorizationBlock = [
        {
          label: language['SERVICE_PORTAL']['CATALOG'],
          text: resultTicket.company?.[0]?.name_company,
          icon: 'fa-building',
        },
        {
          label: language['SERVICE_PORTAL']['CATEGORY'],
          text: resultTicket.name_cat,
        },
        {
          label: language['SERVICE_PORTAL']['SUBCATEGORY'],
          text: resultTicket.name_service,
        },
        {
          label: language['SERVICE_PORTAL']['TASK'],
          text: config.name_catalog_task,
        },
        {
          label: language.SLA,
          text: resultTicket._sla,
        },
        {
          label: `${language.PROGRESS_} SLA`,
          text: (
            <div className="d-flex align-items-center" style={{ width: 120 }}>
              <Progress
                current={sla.progress || 0}
                background={slaColor}
                hiddenText
                style={{ width: '100%', height: 7 }}
              />
              <span className="ml-2">{sla.progress || 0}%</span>
            </div>
          )
        }
      ];

      resultTicket._responsibleBlock = [
        {
          label: language.TECH_GROUP,
          content: (
            <Badge
              color="primary"
              classes={{ badge: styles.badgeText }}
              badgeContent={config.countgroup > 1 ? config.countgroup : 0}
              onClick={() => {
                config.countgroup > 1 &&
                  setTabActive(tabsIdentifier.DESIGNATION_HISTORY);
              }}
              style={{ cursor: config.countgroup > 1 ? 'pointer' : 'auto' }}
            >
              <Typography component="span" className={styles.textEmphasis}>
                {config.grouptech ?? '--'}
              </Typography>
            </Badge>
          ),
        },
        {
          label: language.TECH,
          text: resultTicket.tech_start || '--',
        },
        {
          label: language.STATUS,
          text: resultTicket._ticketStatus,
        },
        {
          label: language.REASON_STATUS,
          text: resultTicket._ticketStatusReason || '--',
          isHidden: resultTicket._isFinished,
        },
        {
          label: language.OBSERVER,
          content: (
            <div className="tw-flex tw-gap-1 tw-flex-col">
              {resultTicket._observers.map((person) => (
                <div key={person.name} title={person.name}>
                  <Chip
                    size="small"
                    label={
                      person.name.length > 15
                        ? person.name.substring(0, 15) + '...'
                        : person.name
                    }
                    avatar={<Avatar alt={person.name} />}
                    {...person.props}
                  />
                </div>
              ))}

              {!isReadonly && resultTicket._isWorkable && (
                <div className="">
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleModalObservant(true);
                    }}
                    style={{ color: 'var(--success)' }}
                  >
                    <i className="fas fa-plus tw-mr-1" />
                    {language.ADD}
                  </Link>
                </div>
              )}
            </div>
          ),
        },
      ];
    }

    setTicketData(resultTicket);
    setStatusAwaiting(resultAwaiting);
  }

  function updateTicketData(data) {
    const dataSwap = mergeDeep(ticketData, data);
    processResultTicketData(dataSwap, statusAwaiting, sla);
  }

  const getStatus = (ticketData) => {
    if (
      ticketData.config[0].stop_ticket > 1 &&
      ['In Progress', 'Reopened'].includes(ticketData.status)
    ) {
      return language.WAITING;
    }
    return STATUS_MAP[ticketData.status] || ticketData.status;
  };

  function getTicketType(ticketData) {
    if (ticketData.type_tickets == 'Incident') {
      return language.TICKET_TYPES.INCIDENT;
    } else if (ticketData.type_tickets == 'Problem') {
      return language.TICKET_TYPES.PROBLEM;
    } else if (ticketData.type_tickets == 'Solicitation') {
      return language.TICKET_TYPES.SOLICITATION;
    }
    return ticketData.type_tickets;
  }

  const getStatusReason = (ticketData, statusAwaiting) => {
    const [{ stop_ticket, nameprovider }] = ticketData.config;
    if (['Em Atendimento', 'In Progress'].includes(ticketData.status))
      return '';

    const waiting =
      statusAwaiting.find((waiting) => waiting.id_status === stop_ticket)
        ?.status || '';

    const provider = !!nameprovider ? `- ${nameprovider}` : '';
    const timePauseRestart = ticketData?.time_pause_restart
      ? ` - ${moment(ticketData.time_pause_restart).format('L LT')}`
      : '';
    return waiting + provider + timePauseRestart;
  };

  const formatFileItem = (fileItem, ticket) => ({
    name: `${moment(fileItem.dt_cad).format('L LT')} - ${
      fileItem.file.length > 16
        ? fileItem.file.substr(0, 6) + '...' + fileItem.file.substr(-7)
        : fileItem.file
    }`,
    size: fileItem.size,
    type: fileItem.type,
    nn: fileItem.file,
    type: fileItem.id_request_file
      ? 'request'
      : fileItem.id_tickets_file
      ? 'ticket'
      : '',
    id_file: fileItem.id_request_file || fileItem.id_tickets_file,
    id_tickets: fileItem.id_request || fileItem.id_tickets,
    user_cad: fileItem.dt_cad,
    id_user: fileItem.id_request ? ticket.fk_id_user : fileItem.id_user,
    fk_id_pai: fileItem.fk_id_pai,
  });

  function handleReload() {
    getData();
  }

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  function handleCloseTicket() {
    const url = `${Constants.APIEndpoints.TICKET}/${ticketData.id_tickets}`;
    const payload = { action: 'closed' };

    spFetch(url, 'POST', payload)
      .then(({ data }) => {
        Store.toggleAlert(true, language.DATA_SENT_SUCCESSFULLY, 'success');
        handleReload();
        // updateTicketData(data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }

  function handleReopenTicket(justification) {
    const url = `${Constants.APIEndpoints.TICKET}/${ticketData.id_tickets}`;
    const payload = { action: 'reopen', justify: justification };

    spFetch(url, 'POST', payload)
      .then(({ data }) => {
        // updateTicketData(data);
        Store.toggleAlert(true, language.DATA_SENT_SUCCESSFULLY, 'success');
        handleReload()
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {});
  }

  function getSlaOutput(ticketData, statusAwaiting) {
    const stopTicket = ticketData.config[0].stop_ticket;
    const statusAwaitingList = statusAwaiting.find(
      (status) => status.id_status_awaiting === stopTicket
    );

    return (
      <Sla
        not={true}
        statusAwaiting={statusAwaitingList}
        t={ticketData}
        stopSlaParams={ticketParams.stop_sla}
        horas={undefined}
        req={[
          {
            dt_up: ticketData.config[0].dt_cad,
            dt_cad: ticketData.config[0].dt_up,
            id_request: ticketData.config[0].id_request,
          },
        ]}
        language={language}
      />
    );
  }

  function setRelationObserver(ticketId, observantPeople, remove = false) {
    if (observantPeople.length > 20) {
      Store.toggleAlert(
        true,
        language.OPERATION_NOT_PERFORMED_MAX_20_USERS,
        'error'
      );
    } else {
      const payload = {
        users: observantPeople,
        id_tickets: ticketId,
        delete: remove,
      };

      spFetch(
        `${Constants.APIEndpoints.TICKET}/relationObserver`,
        'POST',
        payload
      )
        .then(({ data }) => {
          let _ticketData = Object.assign({}, ticketData);
          _ticketData.config[0].observers = data.observers;
          processResultTicketData(_ticketData, statusAwaiting, sla);
          Store.toggleAlert(
            true,
            language.OBSERVER_ADDED_SUCCESSFULLY,
            'success'
          );
        })
        .catch((e) => console.error(e))
        .finally(() => {});
    }
  }

  function handleDeleteFile(item) {
    if (!ticketData._isClosedOrResolved) {
      spFetch(
        `${Constants.APIEndpoints.REQUESTS}/api/deletefile${item.type}/${item.id_tickets}&${item.id_file}&${userLogged.id}`
      ).then(({ data }) => {
        updateTicketData(data);
      });
    }
  }

  function handleDownloadFile(item) {
    DownloadFiles(
      `${Constants.APIEndpoints.REQUESTS}/api/download${item.type}/${item.id_file}&${item.nn}`,
      item.nn
    );
  }

  function handleUpdateFile(files, ticket) {
    Array.from(files).forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          !!reader.result
            ? resolve(reader.result)
            : reject(Error('Failed converting to base64'));
      });

      promise.then(
        (result) => {
          const uploadPayload = {
            id_tickets: ticket.id_tickets,
            deleted: false,
            file: result,
            type: file.type,
            namefile: file.name,
            fk_id_company: ticket.id_company,
          };

          spFetch(
            `${Constants.APIEndpoints.TICKET}/upload`,
            'POST',
            uploadPayload
          ).then(({ data }) => {
            updateTicketData(data);
          });
        },
        (err) => {}
      );
    });
  }

  function handleToggleModalObservant(state = false) {
    setIsOpenModalObservant(state);
  }

  function handleConfirmModalObservant(users) {
    const usersId = users.map((item) => item.id_user);
    setRelationObserver(ticketData.id_tickets, usersId);
    handleToggleModalObservant();
  }

  return (
    <div>
      <ServicePortalSubHeader
        breadcrumbs={[
          <Typography component="span" className={styles.text}>
            {language['SERVICE_PORTAL']['MENU_TICKET']}
          </Typography>,
          <Typography component="span" className={styles.text}>
            {ticketData?._subject}
          </Typography>,
        ]}
      />
      <ServicePortalWrapper>
        <div className="tw-w-full">
          <Paper
            variant="outlined"
            className={classNames(styles.paper, 'tw-p-6 tw-rounded-lg')}
            style={{ borderWidth: 2 }}
          >
            {isDataLoaded && ticketData && (
              <div className="tw-flex">
                <main className="tw-flex-1 tw-overflow-x-hidden tw-pr-4">
                  <ServicePortalTicketHeader
                    language={language}
                    ticketData={ticketData}
                    ticketParams={ticketParams}
                    onReload={handleReload}
                    onCloseTicket={handleCloseTicket}
                    onReopenTicket={handleReopenTicket}
                  />

                  <div className="tw-flex-grow tw-w-full">
                    <AppBar
                      position="static"
                      color="inherit"
                      className={styles.appBar}
                    >
                      <Tabs
                        value={tabActive}
                        onChange={(event, item) => {
                          setTabActive(item);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant="scrollable"
                        className={styles.appBarTabs}
                        classes={{
                          indicator: styles.appBarTabIndicator,
                        }}
                      >
                        {tabs
                          .filter((item) => tabItems.includes(item.name))
                          .map((item) => (
                            <Tab
                              key={item.id}
                              value={item.id}
                              label={item.label}
                              disableRipple={true}
                              {...a11yProps('tab_' + item.id)}
                              className={styles.appBarTab}
                            />
                          ))}
                      </Tabs>
                    </AppBar>
                    <Divider />

                    <TabPanel
                      value={tabActive}
                      index={tabsIdentifier.DETAILS}
                      className="pb-0"
                    >
                      <div className="tw-flex tw-gap-4">
                        <div className="tw-w-8/12">
                          <ServicePortalTicketDescription
                            language={language}
                            ticketId={ticketData.id_tickets}
                          />
                        </div>
                        <div className="tw-w-4/12">
                          <ServicePortalChatMessages
                            language={language}
                            ticketData={ticketData}
                            isReadonly={isReadonly}
                            filters={{
                              ticketTypeAllowed: [null, 'solicitant'],
                            }}
                          />
                        </div>
                      </div>

                      <div className="tw-w-full tw-mt-2">
                        <ServicePortalTicketTasks
                          language={language}
                          ticketData={ticketData}
                        />
                      </div>

                      {ticketData?.forms?.length > 0 && (
                        <div className="tw-w-full tw-mt-2">
                          <ServicePortalTicketFormView
                            language={language}
                            ticketData={ticketData}
                          />
                        </div>
                      )}

                      <div className="tw-w-full tw-mt-2">
                        <ServicePortalTicketHistory
                          language={language}
                          ticketData={ticketData}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel
                      value={tabActive}
                      index={tabsIdentifier.HISTORIC_SLA}
                    >
                      <ServicePortalTicketSlaHistory
                        language={language}
                        ticketData={ticketData}
                      />
                    </TabPanel>
                    <TabPanel
                      value={tabActive}
                      index={tabsIdentifier.DEVICE_CONF}
                    >
                      <ServicePortalTicketICS
                        language={language}
                        ticketData={ticketData}
                      />
                    </TabPanel>
                    <TabPanel
                      value={tabActive}
                      index={tabsIdentifier.DESIGNATION_HISTORY}
                    >
                      <ServicePortalTicketDesignationHistory
                        language={language}
                        ticketData={ticketData}
                      />
                    </TabPanel>
                    <TabPanel
                      value={tabActive}
                      index={tabsIdentifier.RELATION_TICKETS}
                    >
                      <ServicePortalTicketRelations
                        language={language}
                        ticketData={ticketData}
                      />
                    </TabPanel>
                  </div>
                </main>
                <aside
                  className="tw-flex tw-flex-col tw--overflow-y-auto tw-p-3 tw-rounded tw-border tw-bg-gray-100"
                  style={{ flex: '0 0 20rem' }}
                >
                  <Grid container spacing={2}>
                    <ServicePortalTicketSubTitle
                      label={ticketData._ticketType}
                      iconText={ticketData._ticketTypeIcon}
                    />
                    {ticketData._requestBlock.map((item, index) => (
                      <GridLineItem
                        key={`requestBlock-${index}`}
                        label={item.label}
                        text={item.text}
                        icon={item.icon}
                        content={item.content}
                        isHidden={item.isHidden}
                      />
                    ))}

                    <Grid item sm={12}>
                      <Divider />
                    </Grid>

                    <ServicePortalTicketSubTitle
                      label={language.REQUESTERSDASH}
                      iconText="fa-laptop"
                    />
                    {ticketData._categorizationBlock.map((item, index) => (
                      <GridLineItem
                        key={`categorizationBlock-${index}`}
                        label={item.label}
                        text={item.text}
                        icon={item.icon}
                        content={item.content}
                        isHidden={item.isHidden}
                      />
                    ))}

                    <Grid item sm={12}>
                      <Divider />
                    </Grid>

                    <ServicePortalTicketSubTitle
                      label={language.RESPONSIBLE}
                      iconText="fa-user"
                    />
                    {ticketData._responsibleBlock.map((item, index) => (
                      <GridLineItem
                        key={`responsibleBlock-${index}`}
                        label={item.label}
                        text={item.text}
                        icon={item.icon}
                        content={item.content}
                        isHidden={item.isHidden}
                      />
                    ))}

                    <Grid item sm={12}>
                      <Divider />
                    </Grid>

                    <ServicePortalTicketSubTitle
                      label={language.ATTACHMENTS}
                      iconText="fa-paperclip"
                    />
                    <Grid item sm={12}>
                      {ticketData._files && (
                        <div>
                          <CommonForm
                            values={{
                              files: ticketData._files,
                            }}
                            clean
                            fields={[
                              {
                                type: 'file',
                                name: 'files',
                                label: language.ATTACH,

                                btnProps: {
                                  disabled: ticketData._isFinished,
                                },
                                disabled: isReadonly || ticketData._isFinished,
                                removeFile: (fileItem) =>
                                  handleDeleteFile(fileItem),
                                onClick: (f) => handleDownloadFile(f),
                              },
                            ]}
                            onChangeField={(f, v) => {
                              handleUpdateFile(v, ticketData);
                              //setLocalFiles(v);
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </aside>
              </div>
            )}

            {isDataLoaded && !ticketData && (
              <EmptyGrid>
                <h2 className="tw-text-xl tw-font-bold">
                  {language['SERVICE_PORTAL']['PAGE_NOT_RESULTS_FOUND']}
                </h2>
                <div className="tw-flex tw-flex-col tw-gap-5">
                  <p style={{ color: 'var(--success)' }}></p>
                </div>
              </EmptyGrid>
            )}

            {isLoading && (
              <div className="tw-flex tw-justify-center tw-items-center tw-h-24">
                <CircularProgress color="inherit" />
              </div>
            )}
          </Paper>
        </div>

        <ServicePortalModalObservant
          language={language}
          isOpen={isOpenModalObservant}
          ticketData={ticketData}
          onClose={handleToggleModalObservant}
          onConfirm={handleConfirmModalObservant}
        />
      </ServicePortalWrapper>
    </div>
  );
}
