import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';

@observer
export default class PieChartGraph extends Component {
  render() {
    const data = [
      { name: 'tickets', value: this.props.qtdTickets },
      { name: 'os', value: this.props.qtdOs },
      { name: 'assessments', value: this.props.qtdAssessment }
    ];

    const COLORS = ['var(--primary)', 'var(--secundary)', 'var(--green)'];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (
      <ResponsiveContainer width="100%" height={200}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            data={data}
            dataKey="value"
            cx={100}
            cy={100}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
