import React, {Fragment, useEffect, useState} from 'react';
import Store from '@data/Store';
import Button from '@common/Button';
import classnames from 'classnames';
import {Menu, MenuItem} from '@material-ui/core';
import moment from 'moment';
const { language } = Store;

export default function({
  onChange,
  value,
  disabled=false
}){
  let {language} = Store;
  let [monthSelected, setMonthSelected] = useState(0);
  let [anchorEl, setAnchorEl] = useState(null);
  let array = [language.EVALUTION, language.EXPERIENCE, language.SOLVED];
  useEffect(() => {
    if (value) {
      setMonthSelected(value);
    }
  }, [value])

  //console.log('carregando month', monthSelected);

  return(
    <Fragment>
      <div className='d-flex align-items-center'>
      <Button
          icon='fas fa-chevron-left'
          label={language.PREVIOUS}
          color='success'
          variant='circle'
          outlined
          style={{
            border:'none',
            fontSize:12
          }}
          onClick={()=>{
             setMonthSelected(monthSelected=>{
              onChange(monthSelected <=0 ? 0:monthSelected-1)
              return monthSelected <=0 ? 0:monthSelected-1
            });
         
          }}
          disabled={disabled}
        />

        <h3
          aria-owns={anchorEl ? 'long-menu' : undefined}
          onClick={evt => setAnchorEl(evt.currentTarget)}
          className='label-month-filter px-3'
          style={{width:113, textAlign:'center'}}
        >
          {  
           array[monthSelected] 
        
          }
        </h3>
        <Button
          icon='fas fa-chevron-right'
          label={language.NEXT}
          color='success'
          variant='circle'
          outlined
          style={{
            border:'none',
            fontSize:12
          }}
          onClick={()=>{
            setMonthSelected(monthSelected=>{
              onChange(monthSelected >=2? 2:monthSelected+1)
              return monthSelected >=2? 2:monthSelected+1
            });

          }}
          disabled={disabled}
        />
      </div>
      <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={!!anchorEl && !disabled}
          onClose={()=> setAnchorEl(null)}
          
        >
         
        {[language.EVALUTION, language.EXPERIENCE, language.SOLVED].map((option,i) => (
            <MenuItem 
              key={option} 
              onClick={()=>{
                setAnchorEl(null);
                onChange(i)
                setMonthSelected(i)
              }}
              style={monthSelected==(i+1)?{fontWeight:'bold'}:{}}
            >
              {option}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  )
}