import React, { useState } from 'react';
import CommonForm from '@common/CommonForm2';
import BarBlue from '@images/BarBlue.png';
import Dialog from '@common/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './Form.css';
// ------------------------------icons-----------------------------------------
import SlowIcon from '@images/svg-icon/SlowIcon';
import HightIcon from '@images/svg-icon/HightIcon';
import CriticIcon from '@images/svg-icon/CriticIcon';
import Store from '@data/Store';

const { language } = Store;

export default function Form({ open, onClose }) {
  let [form1, setForm1] = useState(null);
  let [form2, setForm2] = useState(null);
  return (
    <Dialog
      PaperProps={{
        id: 'RiskForm'
      }}
      open={open}
      onClose={onClose}
      btnCloseProps={{
        style:{
          marginTop:-88
        }
      }}
      contentTitle={{
        style:{width:'100%'}
        }}
        titleContentProps={{
        style:{
          width:'calc(100% - 60px)'
        }
        }}
        title={
          <div className="w-100 bar">
            <div className="d-flex align-items-start">
              <div className="w-100" style={{ zoom: 0.8 }} zoom={0.8}>
                <CommonForm
                  values={{
                    subject: undefined
                  }}
                  alert={false}
                  fields={[
                    {
                      type: 'text',
                      name: 'subject',
                      label: language.NEW_STANDARD_ITEM,
                      className: 'subject-ticket',
                      required: true
                    }
                  ]}
                  onRef={r => setForm1(r)}
                  onChangeField={(f, v) => null}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <div
                style={{
                  width: 275,
                  position: 'relative',
                  top: 7,
                  zoom: 0.8
                }}
                zoom={0.8}
              >
                <CommonForm
                  values={{
                    sponsor: undefined
                  }}
                  onRef={r => setForm2(r)}
                  onChangeField={(f, v) => null}
                  fields={[
                    {
                      span: 4,
                      type: 'select',
                      name: 'sponsor',
                      label: language.RESPONSIBLE,
                      className: 'tech-group-ticket',
                      options: [],
                      required: true
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        }
    >
      <CommonForm
        fields={[
          {
            type: 'number',
            name: 'standardSection',
            label: language.SECTION_OF_THE_STANDARD
          },
          {
            type: 'element',
            content: <hr />
          },
          {
            col: 4,
            type: 'listCheckbox',
            name: 'approver',
            label: language.APPROVER,
            options: []
          },
          {
            col: 4,
            type: 'listCheckbox',
            name: 'consulted',
            label: language.CONSULTED,
            options: []
          },
          {
            col: 4,
            type: 'listCheckbox',
            name: 'informed',
            label: language.INFORMED,
            options: []
          },
          {
            type: 'element',
            content: <hr />
          },
          {
            type: 'btnGroup',
            name: 'priority',
            label: language.PRIORITY,

            options: [
              {
                icon: ({ selected }) => (
                  <SlowIcon
                    style={{
                      fill: selected ? 'white' : 'var(--primary)',
                      width: 50
                    }}
                  />
                ),
                value: 1,
                label: language.LOW,
                colorActive: 'primary'
              },
              {
                icon: ({ selected }) => (
                  <SlowIcon
                    style={{
                      fill: selected ? 'white' : 'var(--primary)',
                      width: 50
                    }}
                  />
                ),
                value: 2,
                label: language.MEDIUM,
                colorActive: 'warning'
              },
              {
                icon: ({ selected }) => (
                  <SlowIcon
                    style={{
                      fill: selected ? 'white' : 'var(--primary)',
                      width: 50
                    }}
                  />
                ),
                value: 3,
                label: language.HIGH,
                colorActive: 'danger'
              }
            ]
          },
          {
            type: 'element',
            content: <hr />
          },
          {
            col: 4,
            type: 'select',
            name: 'deadline',
            label: language.DEADLINE
          },
          {
            col: 4,
            type: 'select',
            name: 'reviewPeriod',
            label: language.REVIEW_PERIOD
          },
          {
            col: 4,
            type: 'select',
            name: 'reviewDate',
            label: language.REVISION_DATE
          },
          {
            type: 'element',
            content: <hr />
          }
        ]}
        onSubmit={e => null}
        button={{
          fluid: true,
          style: { height: 40 },
          className: '-mt-4'
        }}
      />
    </Dialog>
  );
}
