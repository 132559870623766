import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';

import styled from 'styled-components';

export const useStyles = (theme) => ({
  listItem: {
    padding: 0,
    width: 218,
  },
  iconButton: {
    backgroundColor: '#FFF',
    marginLeft: '-13px',

    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  itemIcon: {
    width: 29,
  },
  itemText: {
    textTransform: 'uppercase',
    color: '#FFF',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  subitemText: {
    textTransform: 'uppercase',
    color: 'var(--colorIconButton)',
    fontSize: '10px',
    // paddingTop: 10,
  },
});

export const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    padding: '0 12px 0 12px',
    backgroundColor: 'transparent',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: '40px !important',

    '&$expanded': {
      // minHeight: 56,
      padding: '15px 0  0 12px',
      paddingTop: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles({
  root: {
    // padding: theme.spacing(2),
    height: 38,
    width: '100vh',
    backgroundColor: 'var(--slideBarThemeIconButton)',
    paddingTop: 10,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'var(--iconButtonHover) !important',
      width: '100vh',
    },
  },
})(MuiExpansionPanelDetails);

export const ImgBox = styled.div`
  background-color: #fff;
  width: 26px;
  height: 26px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 100%;
`;

export const BoxList = styled.div`
  width: 168px;
  display: flex;
  align-items: center;
`;

export const TextList = styled.p`
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  white-space: normal;
`;
