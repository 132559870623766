 
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import Build from './Buildhome__'
import CommonPage from '@common/CommonPage';
@observer
export default class Index extends CommonPage {
  constructor() {
    super();

    this.state = {
      index: 0,
      page: 'list',
      Page:'list',
      openNew: true,
      pageDelete:{
        open:false,
        values:undefined
      }
    };

    
  }
   
  render() {
  return (
    
   
    <Build action={this.action} APIEndpoints={this.APIEndpoints}  ></Build>
    
     
    );
  }
}
