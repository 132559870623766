import RaisedButton from '../../common/RaisedButton';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Switch } from '@material-ui/core';
import { Card, Chip } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
@observer
export default class DeviceTypeList extends Component {
  render() {
    let { language, handlePage, redirect, onDelete, devicetype } = this.props;

    let col = [
      {
        key: 'id_device_type',
        label: language.ID,
        style: { width: 1 }, thConfig: true
      },
      {
        key: 'name',
        label: language.DESCRIPTION,
        style: { width: 900 }, thConfig: true
      },
      {
        key: 'dt_cad',
        label: language.DATE_CAD,
        style: { width: 120, textAlign: 'center' }
      },
      {
        key: 'action',
        label: language.ACTION,
        style: { width: 40, textAlign: 'center' },
        jc: 'center'
      }
    ];

    let data = this.props.store.devicetypes.map(obj => {
      return {
        id_device_type: obj.id_device_type,
        name: obj.name,
        dt_cad: moment(obj.dt_cad).format('L'),

        action: (
          <React.Fragment>
            <Chip
              className='mx-2 muit-chip'
              label={
                <div className='d-flex align-items-center justify-content-between'>

                  <RaisedButton
                    circleButton
                    icon='fas fa-edit'
                    label={language.EDIT}
                    color='default'
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    className='mr-2'
                    onClick={() => handlePage('form', obj)}

                  />
                  <RaisedButton
                    circleButton
                    icon='far fa-trash-alt'
                    label={language.DELETE}
                    color='danger'
                    style={{ fontSize: 13, width: 21, height: 21 }}
                    onClick={() => handlePage('remove', obj)}
                  />

                </div>
              }
            />
            {/*<Row>
              <Col md={4}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.EDIT}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="editIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('form', obj)}
                  />
                </Tooltip>
              </Col>
              <Col md={4}>
                <Tooltip
                  html={
                    <div style={{ color: '#fff', fontSize: 15 }}>
                      <strong>{language.REMOVE}</strong>
                    </div>
                  }
                  position="right"
                  theme="dark"
                >
                  <div
                    className="trashIcon pointer"
                    style={{ width: 25, height: 25 }}
                    onClick={() => handlePage('remove', obj)}
                  />
                </Tooltip>
              </Col>
            </Row>*/}
          </React.Fragment>
        )
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>

        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index='DeviceTypeList'
          searchColumn orderColumn
          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={configurationProps.control}
          beforeBar={
            <RaisedButton
              circleButton
              icon='fas fa-plus'
              style={{ zoom: .8 }}
              zoom={.8}
              className='mr-3'
              color='primary'
              primary={true}
              label={language.ADD}
              onClick={() => handlePage('form')}
            />
          }
        />
      </Fragment>
    );
  }
}
