import React from 'react';
import { Card } from '@material-ui/core';
import ReactHighcharts from 'react-highcharts';

import AppBar from '../../../../common/AppBar2';

import { Graph_2 } from './Graph';

export default function ({ foreseen, realized, language }) {
  return (
    <Card>
      <AppBar
        title={language.ACCUMULATED_VALUE}
        subTitle={`${language.YEAR})`}
      />
      <div className="content-medium pt-0">
        <ReactHighcharts config={Graph_2({ foreseen, realized, language })} />
      </div>
    </Card>
  );
}
