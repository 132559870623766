import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import InventoryList from './InventoryList';
import InventoryConfigDevice from './InventoryConfigDevice';
import InventoryListSoftware from './InventoryListSoftware';
import InventoryListHardware from './InventoryListHardware';
import InventoryListService from './InventoryListService';
import DeviceSolicitation from './DeviceSolicitation';
import InventoryMacbookList from './InventoryMacbookList';
import Constants from '../../data/Constants';
import GV from './GV'
import io from 'socket.io-client';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
const crypto = require('crypto');
const socket = columnName => eventName => (ioServers) => (value, deviceId) =>
  ioServers.emit(eventName, { [columnName]: value, deviceId }, console.log)

const socketService = socket('name')
const socketSoftware = socket('pschildname')

@observer
export default class InventoryContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      companyId: 1,
      values: '',
      ioServers: io.connect(Constants.AGENT_SERVERS, {
        path: '/server',
        query: 'user=' + this.store.getUserLoged().id + '&token=' + this.store.getToken()
      }),
      ioService: null,
      totalLigado: [],
      totalDesligado: [],
      loading:false,
      companies:[]

    };
    this.handlePage = this.handlePage.bind(this);
    this.onFormSubmitSolc = this.onFormSubmitSolc.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.setLoading =  this.setLoading.bind(this);
    
  }
  setLoading(b){
  this.setState({loading:b})
  }
     decryp=(aMsg='')=> {
    try{
    var aSecret = 'Orch3str0!@forever!';
  
    aMsg = aMsg.replace(/\s/g, '');
    let decipher, tRet;
    decipher = crypto.createDecipher('aes-256-cbc', aSecret);
    tRet = decipher.update((aMsg||'').replace(/\s/g, '+'), 'base64', 'utf8');
    tRet += decipher.final('utf8');
  
    return tRet;
    }catch(e){
      return {}
    }
  }

  componentDidMount() {
    // this.action.execute('get', this.APIEndpoints.INVENTORY, 'inventory');
    //  this.action.execute('get', this.APIEndpoints.DEVICE=', 'devices');
    this.action.execute('get', this.APIEndpoints.DEPARTMENT, 'departments');
    //this.action.execute('get', this.APIEndpoints.COMPANY , 'companies');
    // this.action.execute('get',  this.APIEndpoints.COMPANY ).then(h=>{

       // this.setState({companies: h.data})
    //  })
  
  

        
    
  
  



    this.action.execute('post', this.APIEndpoints.INVENTORY + '/1', 'devices', null, null, null, null);

    this.action.execute(
      'get',
      `${this.APIEndpoints.INVENTORY}/${
      0
      }/hardware`,
      'hardwares'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.REQUESTS + '/SolicitationAll',
      'solicitations'
    );
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id }, null, null, null
    )
  }



  onFormSubmitSolc(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.DEVICE + '/createSolicitation',
      'solicitations',
      values,
      'id_device_solicitation'
    );
  }
  updateStatus(values) {
    this.action.execute(
      'post',
      this.APIEndpoints.DEVICE + '/UpdateStatus',
      'solicitations',
      values,
      'id_device_solicitation'
    );
  }

  handlePage(page, edit) {

  }

  onClearAllFilter = ()=>{
    this.action.execute('post', this.APIEndpoints.INVENTORY + '/1', 'devices', null, null, null, null);
  }

  stopService = socketService('stop service')
  startService = socketService('start service')
  restartService = socketService('restart service')

  uninstallSoftware = socketSoftware('uninstall software')

  render() {
    let {
      language,
      companies,
      devices,
      departments,

      softwares,
      hardwares,
      services,
      users,
      contracts,
      solicitations,
      usercon_company,

      inventories
    } = this.store;
    let {INVENTORY} = this.store.theme;
    let { page, ioServers } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    let { state, pathname } = history.location;
    var values;

    if (state && testRoute(/\/dash\/inventory\/(software|hardware|service|vulnerability|mac)/, pathname)) {
      values = state;
    } else if (!state && testRoute(/\/dash\/inventory\/(software|hardware|service|vulnerability)/, pathname)) {
      history.goBack();
      return null;
    }

    return (
      <CommonPage
        title={<div className="logoWhite" />}
        showMenuIconButton={false}
        iconAlert={true}
        loading={this.state.loading}
      >

        {testRoute('/dash/inventory', pathname) &&
          <Animate>
            <InventoryList
              language={language}
              devices={devices}
              hardwares={hardwares}
              departments={departments}
              ioServers={ioServers}
              history={history}
              setLoading={this.setLoading}
              handlePage={this.handlePage}
              companies={this.state.companies}
              handleChange={this.handleChange}
              values={values}
              onClearAllFilter={this.onClearAllFilter}
              ioServers={this.state.ioServers}
              softwares={softwares}
              redirect={redirect}
              action={this.action}
              usercon_company={usercon_company}
              APIEndpoints={this.APIEndpoints}
              total_acompany_theme={INVENTORY.total_acompany}
              inventories={inventories}
            />
          </Animate>
        }
        {testRoute('/dash/inventory/mac', pathname) &&
          <Animate>
            <InventoryMacbookList
              language={language}
              devices={devices}
              hardwares={hardwares}
              departments={departments}
              handlePage={this.handlePage}
              companies={this.state.companies}
              handleChange={this.handleChange}
              values={values}
              ioServers={this.state.ioServers}
              redirect={redirect}
              action={this.action}
              usercon_company={usercon_company}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        }


        {testRoute('/dash/inventory/software', pathname) &&
          <Animate>
            <InventoryListSoftware
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              device={values}
              values={values}
              socketEvents={{
                uninstallSoftware: this.uninstallSoftware(ioServers)
              }}
              ioServers={this.state.ioServers}
              softwares={softwares}
            />
          </Animate>
        }

        {testRoute('/dash/inventory/hardware', pathname) &&
          <Animate>
            <InventoryListHardware
              language={language}
              values={values}
              handlePage={this.handlePage}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
              device={values}
              hardwares={hardwares}
            />
          </Animate>
        }

        {testRoute('/dash/inventory/service', pathname) &&
          <Animate>
            <InventoryListService
              language={language}
              handlePage={this.handlePage}
              action={this.action}
              values={values}
              socketEvents={{
                stopService: this.stopService(ioServers),
                startService: this.startService(ioServers),
                restartService: this.restartService(ioServers)
              }}
              ioService={this.state.ioService}
              APIEndpoints={this.APIEndpoints}
              device={values}
              ioServers={this.state.ioServers}
              services={services}
            />
          </Animate>
        }

        {testRoute('/dash/inventory/vulnerability', pathname) &&
          <Animate>
            <GV
              handlePage={this.handlePage}
              language={language}
              values={values}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              itens={this.store.bsc}
              device={values}
            />
          </Animate>
        }

        {1 == 2 &&
          <Animate>
            <InventoryMacbookList />
          </Animate>
        }

        {page === 'config' ? (
          <InventoryConfigDevice
            language={language}
            handlePage={this.handlePage}
            values={values}
          />
        ) : page == 'solicitation' ? (
          <DeviceSolicitation
            language={language}
            handlePage={this.handlePage}
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            values={this.state.values}
            users={users}
            id_user={this.store.getUserLoged().id}
            updateStatus={this.updateStatus}
            solicitations={solicitations}
            onFormSubmitSolc={this.onFormSubmitSolc}
            contracts={contracts}
            services={services}
          />
        ) : (
              ''
            )}
            

      </CommonPage>
    );
  }
}
