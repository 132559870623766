import React from 'react';
// ------------------------------common-----------------------------------------
import CommonTable from '@common/CommonTable';
import MenuTooltip from '@common/MenuTooltip';
import Button from '@common/Button';
import Progress from '@common/Progress';
import Dialog from '@common/Dialog';
import Store from '@data/Store';

const { language } = Store;
// ------------------------------material-ui-------------------------------------
// ------------------------------components-------------------------------------
import Avatares from './Avatares';

export default function({data=[], open, onClose, title}){
   
	return(
      <Dialog
         open={open}
         onClose={onClose}
         maxWidth='xl'
         bar={false}
         
			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
      >
         <CommonTable
            
            className='table-primary-pj'
            title={title}
            paginationTop={false}
            maxHeight={500}
            AppBarProps={{
               style:{
                  borderRadius: '10px 10px 0 0'
               }
            }}
            col={[
               { key:'activity', label: language.ACTIVITY},
               { key:'sponsors', label: language.RESPONSIBLES},
               { key:'evolution', label: language.EVOLUTION},
               { key: 'status', label: language.STATUS },
               { key:'dt_i', label: language.START_DATE},
               { key: 'dt_f', label: language.END_DATE },
               { key: 'time_prev', label: language.FORECAST_TIME },
               { key: 'time_realized', label: language.REALIZED_TIME }, 
            ]}
            beforeBar2={
               <Button
                  icon='fas fa-times'
                  color='light'
                  variant='circle'
                  outlined
                  onClick={onClose}
               />
            }
            data={data.map(e=>({
               
               
               activity:e.id+' - '+e.activity,
               sponsors:(
                  <Avatares
                     data={e.sponsors}
                  />
               ),
               evolution:(

                  <div className='d-flex align-items-center content-progress'>
                     <Progress current={parseInt(e.evolution)} hiddenText className='w-100' background='var(--warning)'/>
                     <span className='ml-2'>{parseInt(e.evolution)}%</span>
                  </div>
                  
               ),
               status:(
                  <div className='status'>
                     {e.status}
                  </div>
               ),		
               dt_i:e.dt_i,
               dt_f:e.dt_f,
               time_prev:e.time_prev,
               time_realized:e.time_realized
            }))}
         />
      </Dialog>
	)
}