import React, { memo, useEffect, useState } from 'react';
/* ----------------------------------common----------------------------------- */
/* ----------------------------------material-ui----------------------------------- */
import { Card, Grid } from '@material-ui/core';

/* ----------------------------------pages----------------------------------- */
import TotalOpenTickets from './TotalOpenTickets';
import TypePrecedence from './TypePrecedence';
import Expenses from './Expenses';
import AverageSla from './AverageSla';

import {
  TotalOpenTickets as TotalOpenTicketsJson,
  TypePrecedence as TypePrecedenceJson,
  Expenses as ExpensesJson,
  AverageSla as AverageSlaJson,
} from '../jsonFormat';

export default function View1({
  tickets,
  projects,
  gmuds,
  os,
  execute,
  APIRoot,
  year,
  priorities,
}) {
  //----as regras de negócio de cada módulo fazer dentro do jsonFormat----

  const [totalOpenTickets, setTotalOpenTickets] = useState(null);
  const [typePrecedence, setTypePrecedence] = useState(null);
  const [expenses, setExpenses] = useState(null);
  const [averageSla, setAverageSla] = useState(null);
  const [render, setRender] = useState(false);

  React.useEffect(() => {
    load();
  }, []);

  async function load() {
    const resultAverage = await AverageSlaJson({ priorities });
    setAverageSla(resultAverage);
    const resultTotalOpen = await TotalOpenTicketsJson({ tickets });
    const resultTypePrecedence = await TypePrecedenceJson({
      gmuds,
      tickets,
      projects,
      os,
    });
    const resultExpense = await ExpensesJson({ tickets });

    setTotalOpenTickets(resultTotalOpen);
    setTypePrecedence(resultTypePrecedence);
    setExpenses(resultExpense);
    setRender(true);
  }

  return (
    <Card id="view1" className="content">
      {render && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TotalOpenTickets {...totalOpenTickets} />
          </Grid>
          <Grid item xs={4}>
            <TypePrecedence data={typePrecedence} />
          </Grid>
          <Grid item xs={2}>
            <Expenses data={expenses} />
          </Grid>
          <Grid item xs={3}>
            <AverageSla
              APIRoot={APIRoot}
              execute={execute}
              year={year}
              tickets={tickets}
              data={averageSla}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  );
}
//   function (prev, next) {
//     let omit = (obj) =>
//       JSON.parse(
//         JSON.stringify(_.omitBy(obj, (val) => typeof val == 'function'))
//       );
//     return _.isEqual(omit(prev), omit(next));
//   }
