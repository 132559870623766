export function Unavailability({
  categories = [],
  data = [],
  z1 = 0,
  z2 = 0,
  height = 200,
	language
}) {
  return {
    chart: {
      type: 'line',
      height,
    },
    title: null,

    subtitle: null,

    yAxis: {
      title: null,
      labels: {
        style: {
          fontSize: 9,
        },
      },
    },

    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: 9,
        },
      },
    },
    credits: { enabled: false },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      enabled: false,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: [
      {
        name: language.AVAILABILITY,
        data,
        zoneAxis: 'x',
        zones: [
          {
            value: z1,
            color: '#019FC2',
          },
          {
            value: z2,
            color: 'var(--warning)',
          },
        ],
      },
    ],
  };
}
export function Capacity({ categories = [], data = {}, height = 307 }) {
  return {
    chart: {
      type: 'line',
      height,
    },
    title: null,

    subtitle: null,

    yAxis: {
      title: null,
      labels: {
        style: {
          fontSize: 9,
        },
      },
    },

    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: 9,
        },
      },
    },
    credits: { enabled: false },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      enabled: true,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: data,
  };
}
