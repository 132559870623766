import React, { Component } from 'react';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default class StatusForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values, UploadFiles } = this.props;
   
    values == undefined ?
       "" : values.url = "";

    let fields = [
      {
        col: 12,
        name: 'title',
        label: 'Título',
        type: 'text',
        required: true,
        placeholder : 'Escreva o título'
      },
      {
        col: 12,
        name: 'description',
        label: 'Descrição',
        type: 'text',
        required: false,
        multiLine: true,
        placeholder: 'Escreva a descrição'
      },
      {
        col: 12,
        name: 'module',
        label: 'Escreva o módulo',
        type: 'text',
        required: false,
      },
      {
        col: 12,
        name: 'url',
        label:'Escolha o arquivo',
        type: 'file',
        required: false,
      }
       
    ];
  
    let button = { label: language.SAVE};
    //console.log('values ', values)
    values == undefined ? "" : values.id_help == -1;
    return (
      <Dialog maxWidth="sm" monitor_scroll title={values?language.EDIT:'Adicionar'} modal={false} open={true} onClose={() => handlePage('list')}>
        
          <CommonForm
            values={values}
            fields={fields}
            onSubmit={onFormSubmit}
            button={button}
            onChangeField={(field, value) => {
              if (values == undefined){
                if (field.name == "url"){
                  UploadFiles(value, -1)
                }     
              }else{

                if (field.name == "url"){
                  UploadFiles(value, values.id_help)
                }
                

              }

            }
          }
            />
        
      </Dialog>
    );
  }
}
