import { create } from 'dinamicfetch';
import Store from '@data/Store';
import Constants from '@data/Constants';

let execute = create({
  swr: {
    compare(a, b) {
      if (a != b) {
        Store.update(b.dispatch);
      }
    },
  },
  axios: {
    baseURL: Constants.APIRoot,
    headers: {
      'X-Access-Token': Store.getToken(),
    },
  },
  store: Store,
  onStart({ config = {}, method, url }) {
    Store.update({
      loading: Store.loading + 1,
      btn_loading: [config.btn_loading, method + '-' + url].concat(
        Store.btn_loading
      ),
    });
  },
  onSuccess({ dispatch, config = {}, method, url }) {
    Store.update({
      ...dispatch,
      loading: Store.loading - 1,
      btn_loading: Store.btn_loading.filter(
        (e) => ![config.btn_loading, method + '-' + url].includes(e)
      ),
    });

    if (config.method == 'post') {
      Store.toggleAlert(true, 'Dados inseridos com sucesso', 'success');
    } else if (config.method == 'put') {
      Store.toggleAlert(true, 'Dados atualizados com sucesso', 'success');
    } else if (config.method == 'delete') {
      Store.toggleAlert(true, 'Dados removidos com sucesso', 'success');
    }
  },
  onError({ error, config, method, url }) {
    Store.update({
      loading: Store.loading - 1,
      btn_loading: Store.btn_loading.filter(
        (e) => ![config.btn_loading, method + '-' + url].includes(e)
      ),
    });
    if (error.msg) {
      Store.toggleAlert(true, 'error', error.msg);
    }
  },
});

export let fetch = execute.fetch;
export let $fetch = execute.$fetch;
export let get = execute.get;
export let post = execute.post;
export let put = execute.put;
export let remove = execute.remove;
export let $get = execute.$get;
export let $post = execute.$post;
export let $put = execute.$put;
export let $remove = execute.$delete;

export default execute;
