import React, { Component } from 'react';
import lodash from 'lodash';
import { Container, Row, Col } from 'react-grid-system';
import ListColumn from '@common/ListColumn';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../../graphs/SimplesBarGrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const styleH2 = {
  textAlign: 'left'
};

export default class ResolutionReports extends Component {
  constructor() {
    super();
    this.toSecond = this.toSecond.bind(this);
    this.toHour = this.toHour.bind(this);
  }

  toSecond(hms) {
    //hms = '02:04:33';   // your input string
    var a = hms.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    return seconds;
  }

  toHour(seconds) {
    var d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';

    var hourComplete = hDisplay,
      mDisplay,
      sDisplay;
    return hourComplete;
  }

  totalCost(valueHour, hour_resolution) {
    var mHour_resolution = this.toSecond(hour_resolution);
    var mValue_hour = valueHour / 60;
    return Math.floor(Number(mHour_resolution) / 60) * mValue_hour;
  }

  render() {
    let { reportCall, resultContract, language } = this.props;

    var objReport = this.props.reportCall;
    var contractValue = this.props.resultContract;

    // Pega todos os servirços que foi prestadado para esse contrato;
    // Tempo medio de atendimento.
    var totalTicketsFilter = [];
    var totalSeconds = 0;
    var ticketsWithTime = 0;
    objReport.map(countT => {
      if (countT.fk_id_contract == contractValue) {
        if (countT.time_resolution != null) {
          totalSeconds = totalSeconds + this.toSecond(countT.time_resolution);
          ticketsWithTime = ticketsWithTime + 1;
        }
      }
    });

    var averageTime = this.toHour(totalSeconds / ticketsWithTime);

    // Custo total de atendimentos.
    var totalCostCalls = 0;
    objReport.map(countT => {
      if (countT.fk_id_contract == contractValue) {
        if (countT.time_resolution != null) {
          totalCostCalls =
            totalCostCalls +
            this.totalCost(countT.hour_value, countT.time_resolution);
        }
      }
    });

    // Todos os techs
    var uniqueTechArray = lodash.uniqBy(objReport, 'tech_username');

    var barCharTechs = [];
    var countTickets = 0;
    var countCostTech = 0;
    var tempName = '';
    uniqueTechArray.map(uniqueTech => {
      if (uniqueTech.tech_username != null) {
        countTickets = 0;
        countCostTech = 0;
        objReport.map(totalTickets => {
          if (totalTickets.fk_id_contract == contractValue) {
            if (
              totalTickets.time_resolution != null &&
              totalTickets.tech_username == uniqueTech.tech_username
            ) {
              countTickets = countTickets + 1;
              countCostTech =
                countCostTech +
                this.totalCost(
                  uniqueTech.hour_value,
                  totalTickets.time_resolution
                );
            }
          }
        });
        tempName = uniqueTech.tech_username;
        barCharTechs.push({
          name: tempName.substring(0, tempName.indexOf('@')),
          costcall: countCostTech,
          Lengthcalls: countTickets
        });
      }
    });
    // const compactObject = _.compact(barCharTechs);
    // console.log(compactObject);
    //
    var anyString = 'marcos.rodrigues@sensrit.com.br';

    
    // Mostra "Moz"
   
    var roundupTotalCost = totalCostCalls.toFixed(2);
   
    
    var lCall = barCharTechs.map(e=>({name:e.name, y:e.Lengthcalls}));
    var cCall = barCharTechs.map(e=>({name:e.name, y:e.costcall}));
   
    return (
      <React.Fragment>
        <div className='content'>
          <ListColumn
            col={6}
            items={[
              {
                label:language.AVERAGE_SERVICE_TIME,
                value:'1 hour'
              },
              {
                label:language.TOTAL_COST_CARE,
                value:'R$ 0.00'
              }
            ]}
          />
        </div>
        <ReactHighcharts config = {ContainerChart.container0({
            title:'',
            subtitle:'',
            yTitle:'',
            series: [
              {
                type: 'column',
                name: 'Atendimento',
                data: lCall,
                color:'var(--primary)'
              },
              {
                type: 'column',
                name: 'Custo',
                data: cCall,
                color:'var(--warning)'
              },
            ],
          })} 
        />
      </React.Fragment>
    )
    /*return (
      <div>
        <div style={{ paddingLeft: '4em', paddingTop: '1em' }}>
          <h2 style={styleH2}>{language.AVERAGE_SERVICE_TIME}:</h2>
          <strong>
            {language.AVERAGE_TIME}
            <span style={{ fontSize: '25px' }}> {averageTime} </span>{' '}
          </strong>
          <br />
          <br />
          <h2 style={styleH2}>{language.TOTAL_COST_CARE}:</h2>
          <strong>
            {' '}
            {language.TOTAL_COST}: R${' '}
            <span style={{ fontSize: '25px' }}>{roundupTotalCost}</span>{' '}
          </strong>
        </div>
        <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <br />
          <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
            <BarChart width={500} height={200} data={barCharTechs}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="costcall" fill="#8884d8" />
              <Bar yAxisId="right" dataKey="Lengthcalls" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );*/
  }
}
