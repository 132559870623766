const ContainerChart = {
  medium_time(){
    return{
      chart: {
          inverted: false,
          width:80,
          backgroundColor:'transparent',
          type: 'bullet',
      },
      title: {
          text: null
      },
      legend: {
          enabled: false
      },
      
      plotOptions: {
        series: {
            pointPadding: .25,
            borderWidth: 0,
            color: '#629DAA',
            targetOptions: {
                width: '200%'
            }
        },
        bullet:{
           
          dataLabels: {
              enabled: true,
              y:20,
              x:-25,
              formatter() {
                return `<span style='font-size:20px'>${this.point.y}</span><br>HORAS`;
              },
              style: {
                textOutline: 0,
                color:'#A3A3A3'
              },
          }
        }
      },
     
      xAxis: {
         categories: ['']
      },
      yAxis: {
         plotBands: [
            {
                from: 0,
                to: 1400,
                color: '#92D050'
            }, {
                from: 1400,
                to: 2000,
                color: '#F0B002'
            }, {
                from: 2000,
                to: 9e9,
                color: '#F66868'
            }
         ],
         labels: {
             format: '<span style="color:#629DAA; font-family:\'Open Sans\'; font-size:10px">{value}</span>'
         },
         title: null,
         gridLineWidth: 0
      },
      series: [{
         data: [{
             y:2100,
             target:2100,
             color:'var(--primary)',
         }]
      }],
      tooltip: {
         pointFormat: '<b>Tempo médio {point.y} </b>)'
      },
      credits: {
         enabled: false
      }
    }
  },
  ticket(dias, openv_, PROGRESS_, RESOLVED_, REOPENED_, CLOSED_) {
    return {
      chart: {
        type: 'area',
        height: 265
      },
      credits: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        allowDecimals: false,
        labels: {
          dias,
          style:{
            color:'var(--primary)',
            fontSize:8
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function() {
            return this.value;
          },
          style:{
            color:'var(--primary)',
            fontSize:8
          }
        },
        tickWidth: 1
      },
      tooltip: {
        // pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
      },
      plotOptions: {
        area: {
          pointStart: 1,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      series: [
        {
          name: 'Open',
          zIndex: 2,
          color: 'rgba(70, 204, 229, .7)', // color success
          data: openv_
        },
        {
          name: 'In Progress',
          zIndex: 2,
          color: 'rgba(246, 208, 104,.7)',
          data: PROGRESS_
        },
        {
          name: 'Resolved',
          zIndex: 2,
          color: 'rgba(95, 161, 162,.7)',
          data: RESOLVED_
        },
        {
          name: 'Reopened',
          zIndex: 2,
          color: 'rgba(104, 159, 246,.7)',
          data: REOPENED_
        },
        {
          name: 'Closed',
          zIndex: 1,
          color: 'rgba(197, 124, 246,.7)',
          data: CLOSED_
        }
      ]
    };
  },
  velocimeter(e) {
    let { suffix, value, label, size, height, maxvl, seriesname } = e;
    return {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: 'transparent',
        height: height || 200
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },

      pane: {
        size: size || 150,
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            borderWidth: 0,
            backgroundColor: 'transparent'
          },
          {
            borderWidth: 0,
            backgroundColor: 'transparent'
          }
        ]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: maxvl || 200,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          rotation: 'auto'
        },
        title: {
          text: label
        },
        plotBands: [
          {
            from: 0,
            to: value,
            color: 'var(--success)' // green
          }
        ]
      },

      series: [
        {
          name: seriesname || 'hours ',
          data: [value],
          tooltip: {
            valueSuffix: suffix || ''
          }
        }
      ]
    };
  },
  container0(data, languege) {
    data = data.filter(e=> !['GESTÃO DE BACKUP', 'GESTÃO DE EVOLUÇÃO DE TI'].includes(e.name));
    return {
      chart: {
        polar: true,
        type: 'area',

        backgroundColor: 'transparent',
        style: {
          fontFamily: "'Unica One', sans-serif"
        },
        plotBorderColor: '#606063',
        height:270,
        margin:0
      },
      title: {
        text: ''
        /*x: -100,
        margin: 30*/
      },
      credits: {
        enabled: false
      },
      subtitle: {
        text: ''
      },
      pane: {
        size: '60%'
      },

      xAxis: {
        categories: data.map(e => e.name),
        tickmarkPlacement: 'on',
        lineWidth: 0,

        crosshair: false,
        labels:{
          style:{
            fontSize:'8px'
          }
        }
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },
      legend: {
        itemMarginTop: 30,
        backgroundColor: 'transparent',
        itemStyle:{
          fontSize:'8px'
        }
      },

      tooltip: {
        shared: true,
        valueSuffix: '%'
      },

      series: [
        {
          name: languege.PLANNED_MATURITY,
          data: data.map(e => parseInt((e.planned_maturity / e.total) * 100)),
          pointPlacement: 'on',
          color: '#02CBF0'
        },
        {
          name: languege.CURRENT_MATURITY,
          data: data.map(e =>
            parseInt((e.current_maturity / e.planned_maturity) * 100)
          ),
          pointPlacement: 'on',
          color: '#A7F002'
        }
      ]
    };
  },
  container1(data) {
    return {
      title: {
        text: 'Título do Gráfico / deixa em branco '
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ['Aberto', 'Em atividade', 'Resolvido']
      },
      series: [
        {
          type: 'column',
          name: 'Fabrício',
          color: '#DAA520',
          data: [3, 2, 1]
        },
        {
          type: 'column',
          name: 'Thiago',
          color: '#F44336',
          data: [2, 3, 5]
        },
        {
          type: 'column',
          name: 'Fábio',
          color: '#33ABA0',
          data: [4, 3, 3]
        }
      ]
    };
  },
  container2(data) {
    return {
      chart: {
        height: 300,
        backgroundColor: 'transparent',
        style: {
          fontFamily: "'Unica One', sans-serif"
        },
        plotBorderColor: '#606063'
      },
      credits: {
        enabled: false
      },
      title: {
        text: '',
        style: {
          color: '#616161',
          fontFamily: 'sans-serif'
        }
      },
      subtitle: {
        text: '',
        style: {
          color: '#616161',
          fontFamily: 'sans-serif'
        }
      },
      xAxis: {
        type: 'category',
        gridLineColor: 'rgba(250,250,250,.5)',
        labels: {
          style: {
            color: 'white'
          }
        },
        lineColor: 'rgba(250,250,250,.5)',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
          style: {
            color: '#A0A0A3'
          }
        }
      },

      yAxis: {
        title: {
          text: '',
          style: {
            color: '#616161',
            fontFamily: 'sans-serif'
          }
        },

        gridLineColor: 'rgba(250,250,250,.5)',
        labels: {
          style: {
            color: 'white'
          }
        },
        lineColor: 'rgba(250,250,250,.5)',
        minorGridLineColor: '#505053',
        tickColor: 'rgba(250,250,250,.5)',
        tickWidth: 1
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}',
            color: '#F0F0F3',
            style: {
              fontSize: '13px',
              textOutline: 0
            }
          },
          marker: {
            lineColor: '#333'
          }
        },

        boxplot: {
          fillColor: '#505053'
        },
        candlestick: {
          lineColor: 'white'
        },
        errorbar: {
          color: 'white'
        }
      },
      legend: {
        /*title: {
          text: 'Chamado',
          style: {
            fontFamily: 'sans-serif',
            textDecoratin: 'upercase',
            color: '#C0C0C0',
            fontSize: '15px'
          }
        },
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemStyle: {
          fontSize: '14px',
          fontFamily: 'sans-serif',
          color: '#E0E0E3'
        },

        backgroundColor: 'rgba(0, 0, 0, 0.5)',
       
        itemHoverStyle: {
            color: '#FFF'
        },
        itemHiddenStyle: {
            color: '#606063'
        },*/
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>'
      },

      series: data
    };
  },
  valueConsume(category, baixo, medio, alto, language) {
    return {
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: category
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'gray'
          }
        }
      },

      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: 'white'
          }
        }
      },
      series: [
        {
          name: language.TICKET_PRIORITY.MEDIUM,
          data: medio,
          color: '#DAA520'
        },
        {
          name: language.TICKET_PRIORITY.SMALL,
          data: baixo,
          color: '#F44336'
        },
        {
          name: language.TICKET_PRIORITY.HIGH,
          data: alto,
          color: '#33ABA0'
        }
      ]
    };
  },
  bullet(data, y, target){
    return{
      chart: {
          inverted: true,
          height:80,
          type: 'bullet'
      },
      title: {
          text: null
      },
      legend: {
          enabled: false
      },
      
      plotOptions: {
          series: {
              pointPadding: 0.25,
              borderWidth: 0,
              color: '#629DAA',
              targetOptions: {
                  width: '200%'
              }
          }
      },
     
      xAxis: {
         categories: ['<span style="font-weight:600;color:#629DAA; font-size:8px; font-family:\'Open Sans\'">Tempo</span><br/><span style="color:#629DAA; font-size:8px; font-family:\'Open Sans\'">(min)</span>']
      },
      yAxis: {
         plotBands: data,
         labels: {
             format: '<span style="color:#629DAA; font-family:\'Open Sans\'; font-size:10px">{value}</span>'
         },
         title: null,
         gridLineWidth: 0
      },
      series: [{
         data: [{
             y,
             target
         }]
      }],
      tooltip: {
         pointFormat: '<b>Tempo médio {point.y} </b>)'
      },
      credits: {
         enabled: false
      }
    }
  },
  risk_matrix(data){
    return {
      chart:{
        height:270,
        // margin:0
      },
      title: {
          text: null
      },
      
      xAxis: {
          categories: [
              'Feb',
              'Mar',
              'April',
              
          ],
          crosshair: true,
          labels:{
            style:{
              color:'var(--primary)',
              fontFamily:'Open Sans',
              fontSize:8
            }
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: null
          },
          labels:{
            style:{
              color:'var(--primary)',
              fontFamily:'Open Sans',
              fontSize:8
            }
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          },
          series:{
            borderRadius:3,
          }
      },
      credits:{
        enabled:false
      },
      legend:{
        itemStyle:{
          color:'var(--primary)',
          fontSize:'8px',
          fontFamily:'Open Sans'
        }
      },
      series: data
    }
  }
};
export default ContainerChart;
