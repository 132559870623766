import RaisedButton from '@common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import CommonPage from '@common/CommonPage';
import Dialog from '@common/Dialog';
import { Container, Row, Col } from 'react-grid-system';
import { Card } from '@material-ui/core';
import Constants from '@data/Constants';
import { Chip, Avatar, List, ListItem, Paper, Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import PageLayout from '@common/PageLayout';
import Help from '@common/components/Help';
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Cell
} from 'recharts';
import { Tooltip } from 'react-tippy';
import { FaAngleDoubleRight } from 'react-icons/lib/fa';
import SimpleBarChartGraph from '../TicketsGraph/SimpleBarChartGraph';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'recharts';
import ReactTooltip from 'react-tooltip';
import './styles.css';
import RadialGraph from '@common/RadialGraph';
import AppBar from '@common/AppBar2';
import DialogConfirm from '@common/DialogConfirm';
import { toBase64 } from '@common/Func';

// ------------------ departament control---------------------------------------
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { OutlinedInput } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import ProjectItem from './ProjectItem.js';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';
import DownloadFiles from '../../../util/DownloadFiles';

import Store from '@data/Store';

const { language } = Store;

window.moment = moment;

@observer
export default class ProjectManagementList extends CommonPage {
  constructor() {
    super();
    this.state = {
      search: '',
      milestone: [],
      status: 0,
      percentual: 0,
      idpartment: 0,
      dt_ini: null,
      dt_end: null,
      filterDt: false,
      company: 0,
      gestor: 0,
      loadFilter: true,
      selectGestor: [],
      selectDepartment: [],
      valuesFilter: [],
      removeProjectConfirm: null,
      confirmOption: false,
      template: false,
      status: 0

    };

    this.handleValues = this.handleValues.bind(this);
    this.handleValuesPerc = this.handleValuesPerc.bind(this);

    this.removeFile = this.removeFile.bind(this);
    this.ondonwFile = this.ondonwFile.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.removeProject = this.removeProject.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
  }

  handleValues(values) {
    this.setState({ status: values });
  }

  setRemoveProjectConfirm = (p) => {
    this.setState({ removeProjectConfirm: p })
  }
  removeProject() {
    var p = this.state.removeProjectConfirm;
    if ((parseInt(this.store.getUserLoged().id) == parseInt(p.user_cad)) ||
      this.store.getUserLoged().role === 'client') {
      this.action.execute(
        'delete',
        this.APIEndpoints.PROJECT +
        '/deleteProject/' + p.id_project,
        '',
        {},
        'id_project',
        { btn_loading: 'project_' + p.id_project }
      ).then(data => {

        let { pmo } = this.store;

        pmo.projetos = pmo.projetos.filter(e => e.id_project != data.data.id_project);

        this.store.pmo = pmo;
        this.setRemoveProjectConfirm(null);
      });
    } else {
      this.store.toggleAlert(true, language.ONLY_THE_PROJECT_MANAGER_CAN_PERFORM_THIS_ACTION, 'error')
    }



  }


  removeFile = (item, key) => {
    this.action
      .execute(
        'get',
        this.APIEndpoints.REQUESTS + '/deletefileproject/' + 0 + '&' + item,
        'projects'
      )
      .then(p => {
        this.action.execute(
          'post',
          this.APIEndpoints.PROJECT + '/projectlist',
          'pmo',
          this.props.valuesFilter
        );
      });
  };

  ondonwFile = (id, file) => {
    DownloadFiles(this.APIEndpoints.REQUESTS + '/downloadgpdoc/' + id + '&' + file, file)
  };

  onDrop = (files, key, id_project, position) => {

    if (files.length == 0) {
      alert(
        `${language.ALLOWED_EXTENSIONS} .pdf .doc .docx .xls .xlsx .ppt .csv .txt .zip, .rar, .msg, audio`
      );
    }
    let tokenApi = this.store.getToken();
    let count = 1;
    let countFile = files.length;
    let host = Constants.APIRoot;

    // ------------------------ler os arquivos e envia pro server--------------------------------------------------
    toBase64(files, (result) => {

      let upload = {
        fk_id_project: id_project,
        deleted: false,
        file: result.base64,
        type: key,
        namefile: result.file.name,
        fk_id_company: 1,
        key: key
      };


      this.action.execute('post', `${host}/api/project/uploaddoc`, null, upload, null, { btn_loading: 'post-file-project-' + id_project + '-' + position }).then(results => {
        if (count == countFile) {

          this.action.execute(
            'post',
            this.APIEndpoints.PROJECT + '/projectlist',
            'pmo',
            this.props.valuesFilter,
            null,
            { btn_loading: 'post-file-project-' + id_project + '-' + position }

          );
          // setTimeout(function () {this.redirect('/user/request')}.bind(this), 1000);
        }

        count++;
      })

    }).then(e => {
      this.state.files = [];
    })
  };

  handleValuesPerc(values) {
    this.setState({ percentual: values });
  }

  Item(p, projectSelect) {
    let check = false;
    projectSelect.split(',').forEach(function (item) {
      if (p.id_project === parseInt(item)) {
        check = true;
      }
    });
    return check;
  }
  selectTemplate() {
    this.action.execute('post', this.APIEndpoints.PROJECT +
      '/projectlist', 'project_stages', { template: true }).then(h => {
        this.setState({ template: true })
      })

  }

  componentWillMount() { }

  replaceSpecialChars(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
    str = str.replace(/[àáâãäå]/, 'a');
    str = str.replace(/[ÈÉÊË]/, 'E');
    str = str.replace(/[Ç]/, 'C');
    str = str.replace(/[ç]/, 'c');
    return str.replace(/[^a-z0-9]/gi, '');
  }

  render() {
    let {
      language,
      handlePage,
      statusProject,
      projectSelect,
      pmoList,
      pmo,
      redirect,
      users,
      filterObj,
      usercon_company,
      companies,
      searchPmo,
      departments
    } = this.props;
    let projectsList = [];
    let filterProjects = [];

    filterProjects = pmo.projetos;
    // console.log('projetos', filterProjects);
    if (this.state.status == 1) {

      filterProjects = pmo.execution

    }
    if (this.state.status == 2) {
      filterProjects = pmo.finalized;

    }

    if (this.props.emp >= 1) {
      filterProjects = filterProjects.filter(
        project => project.fk_id_company === this.props.emp
      );
    }

    if (this.state.gestor >= 1) {
      filterProjects = filterProjects.filter(
        project => project.user_cad === this.state.gestor
      );
    }

    if (this.state.idpartment >= 1) {
      filterProjects = filterProjects.filter(project =>
        project.fk_id_department.includes(parseInt(this.state.idpartment))
      );
    }

    if (this.state.percentual == 1 && this.state.status != 2) {
      filterProjects = filterProjects.filter(
        g =>
          g.statusproject.percentConcluido >= 0 &&
          g.statusproject.percentConcluido <= 30
      );
    } else if (this.state.percentual == 2 && this.state.status != 2) {
      filterProjects = filterProjects.filter(
        g =>
          g.statusproject.percentConcluido >= 31 &&
          g.statusproject.percentConcluido <= 60
      );
    } else if (this.state.percentual == 3 && this.state.status != 2) {
      filterProjects = filterProjects.filter(
        g =>
          g.statusproject.percentConcluido >= 61 &&
          g.statusproject.percentConcluido <= 100
      );
    }
    let selectItens = [];

    companies.map(g => {
      selectItens.push({ value: g.id_company, label: g.name });
    });

    if (this.state.search != '') {
      filterProjects = filterProjects.filter(project => {
        let item = project.name.toUpperCase();
        return (
          item.indexOf(this.state.search.toUpperCase()) !== -1 ||
          this.replaceSpecialChars(item).indexOf(
            this.state.search.toUpperCase()
          ) !== -1 ||
          project.id_project.toString() == this.state.search
        );
      });
    }

    let usersGestorProject = [];
    let departmentsProject = [];
    if (filterProjects.length > 0) {
      projectsList = filterProjects.map((p, i) => {
        if (p != undefined) {
          if (p.fk_id_department && this.state.loadFilter) {
            p.fk_id_department.map(g => {
              departmentsProject.push(g);
            });

            usersGestorProject.push(p.user_cad);
          }

          let companyname = companies.filter(g => g.id_company == p.fk_id_company)

          return (
            <ProjectItem
              p={p}
              nameTitleScope={language.SCOPE}
              idproject={p.id_project}
              companyname={companyname}
              statusProject={statusProject}
              addStage={() => this.props.handlePage('edit-project', p)}
              key={i}
              index={i}
              iduser={this.store.getUserLoged().id}
              role={this.store.getUserLoged().role}
              label={language.REGISTER_PHASES}
              levelTitle={language.PHASES}
              chartItemTitle={language.ACTIVITIES}
              route={() =>
                redirect('/dash/gp/list/detail', {
                  id_project: p.id_project,
                  projectSelect: p
                })
              }
              toggleAlert={this.store.toggleAlert}
              groupId={p.id_project}
              groupName={p.name}
              language={this.props.language}
              redirect={redirect}
              users={users}
              store={this.store}
              APIEndpoints={this.APIEndpoints}
              action={this.action}
              onDrop={this.onDrop}
              ondonwFile={this.ondonwFile}
              removeFile={this.removeFile}
              getMilestone={this.getMilestone}
              getMilestoneSetor={this.getMilestoneSetor}
              setRemoveProjectConfirm={this.setRemoveProjectConfirm}
            />
          );
        }
      });
    }

    if (this.state.loadFilter == true) {
      departments
        .filter(h => departmentsProject.includes(parseInt(h.id_department)))
        .map(h => {
          this.state.selectDepartment.push({
            value: h.id_department,
            label: h.name
          });
        });
      this.state.selectDepartment.push({
        value: 0,
        label: language.ALL
      });

      users
        .filter(h => usersGestorProject.includes(h.id_user))
        .map(h => {
          this.state.selectGestor.push({
            value: h.id_user,
            label: h.name
          });
        });
      this.state.selectGestor.push({
        value: 0,
        label: language.ALL
      });
    }

    let button = {
      md: 2,
      offset: { md: 10 },
      icon: 'fas fa-check-circle ',
      variant: 'circle',
      circle: true,
      label: language.SEARCH,
      primary: true,

      style: { marginTop: '-70px', left: '30px' }
    };
    let { removeProjectConfirm } = this.state;
    return (
      <PageLayout
        icon={
          <div
            className="projectManIcon"
            style={{ width: '40px', height: '40px', margin: 0, left: 6 }}
          />
        }
        title={language.PROJECTS}
        subTitle={language.PROJECT_MANAGEMENT + '/'}
        rightElements={[
          <div style={{ marginLeft: '20px', zoom: 0.8 }} zoom={0.8} className="d-flex align-items-center">
            <div
              style={{ minWidth: 1100 }}

              className="mr-3"
            >
              <CommonForm
                individual
                spacing={2}
                onSubmit={e => searchPmo(e)}
                hiddenSubmit
                gridProps={{
                  alignItems: 'center'
                }}
                fields={[
                  {
                    col: 2,
                    type: 'select',
                    name: 'gestor',
                    label: language.PROJECT_MANAGER,
                    options: this.state.selectGestor
                  },
                  {
                    col: 2,
                    type: 'select',
                    name: 'deparment',
                    label: language.DEPARTMENT,
                    options: this.state.selectDepartment
                  },
                  {
                    col: 1.50,
                    type: 'select',
                    name: 'status',
                    label: language.STATE,
                    options: [
                      { value: 0, label: language.ALL },
                      { value: 1, label: language.ON_PROGRESS },
                      { value: 2, label: language.FINISHED }

                      /*{ value: 3, label: 'Excluidos' }*/
                    ],
                    contentProps: {
                      className: 'pl-3'
                    }
                  },
                  {
                    col: 1,
                    type: 'select',
                    name: 'percentual',
                    label: '%',
                    options: [
                      { value: 0, label: language.ALL },
                      { value: 1, label: '00% - 30%' },
                      { value: 2, label: '31% - 60%' },
                      { value: 3, label: '61% - 100%' }
                    ],
                    contentProps: {
                      className: 'pl-3'
                    }
                  },
                  {
                    col: 1.50,
                    type: 'date',
                    name: 'dt_ini',
                    label: language.INITIAL_DATE,
                    endIcon: 'fas fa-calendar-alt'
                  },
                  {
                    col: 1.50,
                    type: 'date',
                    name: 'dt_end',
                    label: language.FINAL_DATE,
                    endIcon: 'fas fa-calendar-alt'
                  },
                  {
                    col: 2,
                    type: 'select',
                    name: 'company',
                    label: language.COMPANY,
                    options: selectItens
                  },
                  {
                    col: 0.5,
                    type: 'element',
                    content: (v, h, submit) => (
                      <Button
                        icon="fas fa-search"
                        variant="circle"
                        color="success"
                        onClick={() => submit()}
                      />
                    )
                  }
                ]}
                onChangeField={(f, v) => {
                  if (f.name == 'percentual') {
                    this.handleValuesPerc(v);
                  } else if (f.name == 'deparment') {
                    this.setState({ idpartment: v, loadFilter: false });
                  } else if (f.name == 'gestor') {
                    this.setState({ gestor: v, loadFilter: false });
                  } else if (f.name == 'status') {
                    this.setState({ status: v, loadFilter: false });
                  }
                }}
              />
            </div>

            <Button
              icon="fas fa-sync-alt"
              variant="circle"
              onClick={() => this.redirect('/dash/gp')}
            />
            <div style={{ minWidth: 200 }} className="mx-3">
              <CommonForm
                individual
                fields={[
                  {
                    type: 'text',
                    startIcon: 'fas fa-search',
                    name: 'search',
                    placeholder: language.SEARCH + '...',
                    className: 'darks',
                    contentProps: {
                      className: '-mt-2'
                    }
                  }
                ]}
                onChangeField={(f, v) => {
                  this.setState({ search: v });
                }}
              />
            </div>
            <Button
              icon="fas fa-plus"
              variant="circle"
              style={{ borderRadius: 5 }}
              onClick={() => this.setState({ confirmOption: true })

                /*this.props.handlePage('new-project')*/}
            />
          </div>
        ]}
      >
        {removeProjectConfirm &&
          <DialogConfirm
            open={true}
            onClose={() => this.setRemoveProjectConfirm(null)}
            title={language.WARNING_WANT_REMOVE_PROJECT}
            confirmProps={{ color: 'danger', _loading: 'delete-project_' + removeProjectConfirm.id_project }}
            message={removeProjectConfirm.id_project + ' - ' + removeProjectConfirm.name}
            onConfirm={this.removeProject}
          />
        }

        {this.state.confirmOption && <DialogConfirm
          open={true}
          maxWidth="xs"
          onClose={() => this.setState({ confirmOption: false })}
          title={language.NEW_PROJECT}
          message={language.DO_YOU_WANT_TO_CREATE_THE_PROJECT_FROM_A_TEMPLATE}
          confirmLabel={language.YES}
          cancelLabel={language.NO}
          miniVersion = {true}
          cancelProps={{
            color: 'primary'
          }}
          eventClose
          onConfirm={() => {
            this.selectTemplate({ template: true })
            this.setState({ stand: false })
          }}
          onCancel={() => this.props.handlePage('new-project')
          }></DialogConfirm>}

        {this.state.template &&
          <Dialog
            modal={false}
            title={language.SELECT_TEMPLATE}
            open={open}
            maxWidth="lg"
            contentProps={{
              style: { backgroundColor: '#f8f8f8' }
            }}
            PaperProps={{
              style: {
                height: '50%'
              }
            }}
            onClose={() =>
            /*handleModalGrant('closeModalStatus', values)*/ toggleModalDetail(
              false,
              values
            )
            }  >
            <CommonForm
              individual
              spacing={2}
              hiddenSubmit
              gridProps={{
                alignItems: 'center'
              }}
              fields={[
                {
                  col: 12,
                  type: 'select',
                  name: 'gestor',
                  label: language.PROJECT_TEMPLATE,
                  options: this.store.project_stages && this.store.project_stages[0].projetos.map(h => ({
                    label: h.id_project + ' - ' + h.name, value: h.id_project
                  }))

                }]}
              onChangeField={(f, v) => {
                try {
                  let project = this.store.project_stages && this.store.project_stages[0].projetos.filter(b => b.id_project == v)[0]
                  // delete project.id_project;
                  project.template_project = true
                  this.props.handlePage('edit-project', project)
                  this.setState({ template: false })
                } catch (e) {

                }
              }}
            />

          </Dialog>
        }





        <div id="ProjectManagmentList">
          {
            <Card className="border-radius-10">
              <AppBar title={language.PROJECTS}>
                <Button
                  icon='fas fa-exchange-alt'
                  label={language.SWITCH_TO_NEW_SCREEN}
                  variant='normal'
                  color='warning'
                  className='mr-2'
                  onClick={() => {
                    localStorage.removeItem('view_past_project');
                    window.location.reload();
                  }}
                />
              </AppBar>

              <div style={{ minHeight: '300px' }} className="content">
                {projectsList}
              </div>
            </Card>
          }
        </div>


      </PageLayout>
    );
  }
}
