import '../../../public/css/pages.css';
import '../../../public/css/terminal.css';

import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Dialog from '../../common/Dialog';
import Constants from '../../data/Constants';
import { testRoute } from '../../common/Func';
import CommonPage from '../../common/CommonPage';
import RaisedButton from '../../common/RaisedButton';
import DialogConfirm from '../../common/DialogConfirm';

import GuacamoleView from '../guaca/index';
import AssessmentPage from './AssessmentPage';
import AssessmentTerminal from './AssessmentTerminal';
import AssessmentList from './AssessmentList.original.js';

@observer
export default class AssessmentContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'new',
      showFrame: false,
      ticketsSelected: [],
      connection: '',
      openDialog: false,
      device: null,
      user: null,
      password: null,
      domain: null,
      keyboard: 0,
      alertModal: true,
    };

    this.handlePage = this.handlePage.bind(this);
    this.startAssessment = this.startAssessment.bind(this);
    this.onChangeDevice = this.onChangeDevice.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.finishAssessment = this.finishAssessment.bind(this);
    this.endAssessment = this.endAssessment.bind(this);
    this.updateText = this.updateText.bind(this);
  }

  componentWillMount() {
    this.action.execute(
      'post',
      this.APIEndpoints.CONTRACT + '/list',
      'contracts'
    );
  }

  updateText(text, type) {
    if (type == 'keyboard') {
      this.setState({ keyBoard: text });
    } else if (type == 'user') {
      this.setState({ user: text });
    } else if (type == 'pass') {
      this.setState({ password: text });
    } else {
      this.setState({ domain: text });
    }
  }

  handlePage(page) {
    this.setState({ page: page });
  }

  onChangeDevice(device, ip) {
    this.deviceAssess = device;
    this.deviceSelectedIp = ip;
    let url = this.APIEndpoints.TICKET;
    url += '?device=' + device;
    url += '&status=JOb';
  }

  startAssessment(
    contract,
    device,
    tickets,
    connection,
    keyboarde,
    user,
    password,
    domain
  ) {
    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT,
      'assessment',
      {
        device: device.id_device,
        tickets: tickets,
        contract: contract,
      },
      null,
      'Waiting for remote connection'
    );

    this.setState({
      ticketsSelected: tickets,
      connection: connection,
      device: device,
      keyboard: keyboarde,
      user: user,
      password: password,
      domain: domain,
    });

    this.handlePage('terminal');
  }

  endAssessment() {
    this.setState({ showFrame: false, openDialog: false });
  }

  finishAssessment(contract, note, tickets, device, gua) {
    try {
      gua.disconnect();
    } catch (e) {}

    try {
      let { assessment } = this.store;

      if (device == undefined) {
        let { assessment } = this.store;
        this.action.execute(
          'post',
          this.APIEndpoints.ASSESSMENT,
          'assessment',
          {
            tickets: tickets,
            path: assessment[0].path,
            note: note,
            connection: true,
          },
          null,
          'Finished the remote connection'
        );
        this.redirect('/dash/assessment');
      } else {
        if (device == null) {
          device = this.state.device;
        }
        let host = Constants.APIRoot;
        let tokenApi = this.store.getToken();

        let payload = {
          tickets: tickets,
          path: assessment[0].path,
          note: note,
          id_device: device.id_device,
        };

        this.action
          .execute('post', `${Constants.APIRoot}/api/assessment/`, '', payload)
          .then((res) => {
            setTimeout(
              function () {
                this.redirect('/dash/assessment');
              }.bind(this),
              1000
            );
          });
      }
    } catch (e) {}
  }

  onFormSubmit(values) {
    values.date = moment().format('YYYY-MM');
    this.action.execute(
      'post',
      this.APIEndpoints.ASSESSMENT + '/justify',
      'assessments',
      values
    );
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  render() {
    let { language, contracts, tickets, assessment, usercon_company } =
      this.store;
    let { page, ticketsSelected, connection, alertModal } = this.state;
    let { ASSESSMENT } = this.store.theme;
    let { form } = ASSESSMENT;
    let { pathname } = this.history2.location;

    return (
      <div>
        <CommonPage
          title={
            <div
              className="logoWhite"
              onTouchTap={() => this.redirect('/dash')}
            />
          }
          loading={false}
          iconAlert={true}
        >
          {testRoute('/dash/assessment', pathname) && (
            <style>
              {
                '\
               html{\
                  zoom: 1;\
               }\
               '
              }
            </style>
          )}
          {page === 'new' && (
            <AssessmentPage
              conection={connection}
              assessment={assessment}
              contracts={contracts}
              language={language}
              tickets={tickets}
              action={this.action}
              onChangeDevice={this.onChangeDevice}
              APIEndpoints={this.APIEndpoints}
              startAssessment={this.startAssessment}
              redirect={this.redirect}
              user={this.state.user}
              usercon_company={usercon_company}
              users={this.store.getUserLoged()}
              password={this.state.password}
              domain={this.state.domain}
              updateText={this.updateText}
              keyboard={this.state.keyboard}
              formColor={form}
            />
          )}

          {page === 'terminal' &&
          assessment != undefined &&
          assessment.length > 0 ? (
            connection == 'ssh' ? (
              <AssessmentTerminal
                language={language}
                store={this.store}
                ticketsSelected={ticketsSelected}
                finishAssessment={this.finishAssessment}
                connection={connection}
              />
            ) : (
              <GuacamoleView
                language={language}
                user={this.state.user}
                AppBarSet={this.AppBarSet}
                password={this.state.password}
                domain={this.state.domain}
                keyboard={this.state.keyboard}
                path={assessment[0].path}
                finishAssessment={this.finishAssessment}
                device={this.state.device}
              ></GuacamoleView>
            )
          ) : null}
        </CommonPage>
        <DialogConfirm
          open={alertModal}
          maxWidth="xs"
          onClose={() => this.setState({ alertModal: false })}
          title={language.WARNING}
          message={language.THIS_ACTIVITY_RECORDED_AUDITED}
          confirmLabel="OK"
          eventClose={false}
          onConfirm={() => this.setState({ alertModal: false })}
          showCancel={false}
        />
      </div>
    );
  }
}
