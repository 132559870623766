import React, { Component } from 'react';
import { Card, Avatar } from '@material-ui/core';
import Button from '@common/Button';
import classnames from 'classnames';

function Item({ active, title, body, onClick = () => null }) {
  return (
    <Card onClick={onClick} className={classnames('content-medium py-2 border-radius-10 card-item', { active })}>
      <h5 className='title'>{title}</h5>
      <p>{body}</p>
      <i className='btn-next fas fa-arrow-right' />
    </Card>
  )
}

export default function ({ icon, title, items }) {
  return (
    <div className='ConfigurationGroupOption content'>
      <header className='d-flex align-items-center mb-4'>
        <Avatar className='avatar'>
          {icon}
        </Avatar>
        <h3 className='title ml-2'>{title}</h3>
      </header>
      <section>
        {items.filter(e => [true, undefined].includes(e.visible)).map((e, i) => (
          <Item active={e.active} key={i} title={e.title} body={e.body} onClick={e.onClick} />
        ))}
      </section>
    </div>
  )
}