export const formatCurrency = (value, options = {}) => {
    if (!options.locale) {
        options.locale = 'pt-br'
    }
    let locale = options.locale.toLowerCase()
    let currency 
    let style = 'currency'

    if (locale === 'pt-br') {
        currency = 'BRL'
    } else {
        currency = 'USD'
    }
    return Intl.NumberFormat(locale, {
        currency,
        style
    }).format(value)
}