import React from 'react';
import './index.css';
// -------------------------common----------------------------------------
import CommonPage from '@common/CommonPage';
import Header from '@common/Header';
//----------------------------material-ui/core----------------------------
import { Card, Grid } from '@material-ui/core';
import AppBar from '@common/AppBar2';
//----------------------------pages--------------------------------------
import CP from '../CP';
import MyCard from './Card';
import ConfigPrioritizationContext from './ConfigPrioritizationContext';
import { flexBasis } from '@common/Func';

export default class extends CommonPage {
  constructor(props) {
    super(props);

    this.state = {
      prioritizationTotal: 100,
      total: 0,
    };
    this.setTotal = this.setTotal.bind(this);
  }

  setTotal(y) {
    this.setState({ total: y });
  }

  componentDidMount() {
    this.action
      .execute(
        'get',
        this.APIEndpoints.PROJECT + '/prioritization',
        'prioritization'
      )
      .then((x) => {
        let total_ = 0;
        let data = x.data.filter((y) => y.id_scale === 6)[0];
        const {
          benefit_amount = 0,
          complexity = 0,
          desire_directors = 0,
          financial_feedback = 0,
          generates_costs = 0,
          involved_areas = 0,
          processes_improvement = 0,
          risk_if_not_done = 0,
          strategic_alignment = 0,
          urgency = 0,
          innovation = 0,
        } = data;

        if (benefit_amount) {
          total_ += parseInt(benefit_amount);
        }
        if (complexity) {
          total_ += parseInt(complexity);
        }
        if (desire_directors) {
          total_ += parseInt(desire_directors);
        }
        if (financial_feedback) {
          total_ += parseInt(financial_feedback);
        }
        if (generates_costs) {
          total_ += parseInt(generates_costs);
        }
        if (involved_areas) {
          total_ += parseInt(involved_areas);
        }
        if (processes_improvement) {
          total_ += parseInt(processes_improvement);
        }
        if (risk_if_not_done) {
          total_ += parseInt(risk_if_not_done);
        }
        if (strategic_alignment) {
          total_ += parseInt(strategic_alignment);
        }
        if (urgency) {
          total_ += parseInt(urgency);
        }
        if (innovation) {
          total_ += parseInt(innovation);
        }
        this.setState({
          total: total_,
        });
      });
  }

  render() {
    const { prioritizationTotal, total } = this.state;
    const { language } = this.store;

    return (
      <CP
        title={language.CONFIG}
        subTitle={`${language.PROJECT_MANAGEMENT} / ${language.PROJECT_PRIORITIZATION_MAP} / ${language.EVALUTION}`}
      >
        <Header />
        <div id="PrioritizationConfiguration">
          <ConfigPrioritizationContext.Provider
            value={{
              APIEndpoints: this.APIEndpoints,
              action: this.action,
              prioritizationTotal,
              total,
              setTotal: this.setTotal,
            }}
          >
            <Card>
              <AppBar title={language.BENEFITS} />
              <div className="content pt-0">
                <Grid container spacing={16}>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.BOARD_DESIRE}
                      col="desire_directors"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.STRATEGIC_ALIGMENT}
                      col="strategic_alignment"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.VALUE_OF_BENEFITS}
                      col="benefit_amount"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.PROCESSES_IMPROVEMENT}
                      col="processes_improvement"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.FINANCIAL_FEEDBACK}
                      col="financial_feedback"
                      language={language}
                    />
                  </Grid>
                </Grid>
              </div>
              <hr />

              <AppBar title={language.COMPLEXITY_AND_COST} />
              <div className="content pt-0">
                <Grid container spacing={16}>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.GENERATES_COSTS}
                      col="generates_costs"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.RISK_IF_NOT_DONE}
                      col="risk_if_not_done"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.URGENCY}
                      col="urgency"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.COMPLEXITY}
                      col="complexity"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.INVOLVED_AREAS.substring(1)}
                      col="involved_areas"
                      language={language}
                    />
                  </Grid>
                  <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                    <MyCard
                      toggleAlert={this.store.toggleAlert}
                      title={language.INNOVATION}
                      col="innovation"
                      language={language}
                    />
                  </Grid>
                </Grid>
              </div>
            </Card>
          </ConfigPrioritizationContext.Provider>
        </div>
      </CP>
    );
  }
}
