import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Switch } from '@material-ui/core';
import { Menu as IconMenu } from '@material-ui/icons';

import { Radial } from 'sensrit-charts';
import { Select } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import ReactTooltip from 'react-tooltip';
import MdFilterList from 'react-icons/lib/md/filter-list';
import moment from 'moment';
import vendors from '../../util/vendors';
import crypt from '../../util/crypt';
import { percent } from '../../util/MonitorUtils';
import CommonPage from '../../common/CommonPage';
import Constants from '../../data/Constants';
import { MenuItem, Menu, IconButton } from '@material-ui/core';
import RaisedButton from '@common/RaisedButton';
import './Server.css';
import io from 'socket.io-client';
let host = '';
let tokenApi = '';
var getConnection = '';
String.prototype.toHHMMSS = function () {

  var cur = parseInt(this, 10); // don't forget the second param


  let days = Math.floor(cur / 86400);
  cur -= days * 86400;
  let hours = Math.floor(cur / 3600) % 24;
  cur -= hours * 3600;
  let minutes = Math.floor(cur / 60) % 60;
  cur -= minutes * 60;
  let seconds = Math.floor(cur) % 60;


  var time = days + ' Dias ' + hours + ' Horas ' + minutes + ' Minutos ';
  return time;
}



export default class Server extends CommonPage {

  constructor() {
    super();
    this.state = {
      position_7: 0,
      position_6: 0,
      position_5: 0,
      position_4: 0,
      position_3: 0,
      position_2: 0,
      position_1: 0,

      available: false,
      mount: true,
      hold: '',
      aliveHold: '',
      values: {},
      diskIndex: 0,
      netIndex: 0,
      memory: [],
      memoryUsed: [],
      memoryUsedPercent: [],
      memoryUsedDash: 0,
      cpuUsedDash: 0,
      cors: [],
      uptime: [],
      cpuPerc: [],
      procStats: [],
      disk: [],
      netStats: [],
      netStatsTx: [],
      netStatsRx: [],
      removeloading: true,
      dateUnavailableServer: '',
      open: '',
      oisnmp: io.connect(Constants.DISCOVERY, {
        path: '/snmp',
        query: 'user=' + this.store.getUserLoged().id + '&token=' + this.store.getToken()
      })


    };
    this.handleDisk = this.handleDisk.bind(this);
    this.handleNet = this.handleNet.bind(this);


    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {

    try {


      this.store.loading = true;


      this.state.oisnmp.on(this.props._device.id_device, (data) => {

        clearTimeout(this.state.hold);
        if (data.position == 6) {
          this.setState({ position_6: data.value })
        }
        if (data.position == 1) {
          this.setState({ position_1: data.value })
        }
        if (data.position == 2) {
          this.setState({ position_2: data.value })
        }
        if (data.position == 3) {
          this.setState({ position_3: data.value })
        }
        if (data.position == 4) {
          this.setState({ position_4: data.value })
        }
        if (data.position == 5) {
          this.setState({ position_5: data.value })
        }
        if (data.position == 7) {
          this.setState({ position_7: data.value })
        }
        if (data.position == 8) {
          this.setState({ position_8: data.value })
        }
        if (data.position == 9) {
          this.setState({ position_9: data.value })
        }


        // {id_device: "3", item: "cpu-stats", data: {…}}data: {idle: 9700, total: 9900, usedPercent: 97.97979797979798}id_device: "3"item: "cpu-stats"__proto__: Object
        let hold = setTimeout(() => (1000));
        this.setState({ available: false, hold: hold });

      });


    } catch (e) { }

  }
  handleClick(item) {
    this.setState({ open: item })
  }
  handleClose() {
    this.setState({ open: '' })
  }

  componentWillUnmount() {
    this.state.mount = false;
    clearTimeout(this.state.hold);

    window.clearInterval(window.myInterval);
  }


  graphs(item, value) {
    if (item == 'circle') {
      return <Graph_circle

        icon={<span className='flaticon-cpu5' style={{ marginRight: 5 }} />}
        subTitle={Number(value).toFixed(0)}
        current={parseInt(value)}
        title='CPU'
        mid={10}
        high={30}
      />
    } else {
      return <div className='host-graph-net'>
        <Sparklines data={[5, 10, 5, 20, 8, 15]}>
          <SparklinesLine color='#33ABA0' />
          <SparklinesSpots />
        </Sparklines>
        <span className='text-status'><i className='fa fa-caret-up text-green' /> {Number((value / 1024).toFixed(3)) + ' Kbps'}</span>
      </div>
    }

  }




  handleDisk(event, index) {


    this.setState({ diskIndex: index.key });
  }
  handleNet(event, index) { this.setState({ netIndex: index.key }); }

  render() {

    let { handlePage, onRestartAgent, language, _device, down, handleModal, onDettachDevice, connection } = this.props;
    let { values,
      available,
      diskIndex,
      netIndex,
      memory,
      memoryUsed,
      memoryUsedPercent,
      memoryUsedDash,
      cpuUsedDash,
      cors,
      uptime,
      cpuPerc,
      procStats,
      disk,
      netStats,
      netStatsRx,
      netStatsTx,
      removeloading,
      dateUnavailableServer } = this.state;

    //  console.log(this.props.snmpgraph.filter(g=>g.position==1))





    if (!_device.values) {

      return (

        <Row style={{ minHeight: '170px' }} className='row flipContainer down'>
          <div className='offline'>
            <Row className='row infos'>
              <Col md={6.5} xs={6}>{_device.name.toString()}</Col>
              <Col md={5} xs={6}>{_device.ips}</Col>
              <Col md={0.5} xs={1} className='text-right' style={{ padding: 0 }}>

                <IconMenu iconButtonElement={

                  <RaisedButton
                    circleButton
                    icon='fas fa-cog'
                    style={{ width: 25, height: 25, fontSize: 15 }}

                    color='primaryGradient'
                    onClick={() => handlePage('deviceConf', _device)}
                  />
                }>
                  <MenuItem primaryText={language.EDIT_CONFIGURATION} leftIcon={<div className="editIcon"
                    style={{ width: 30, height: 30, margin: '5px 12px' }} />}
                    onClick={() => handlePage('deviceConf', _device)} />
                </IconMenu>
              </Col>
            </Row>
            <Row className='row graphs'>
              <Col md={4} xs={12} style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                <div className='incidentManagementIcon' style={{ width: 80, height: 80 }} />
              </Col>
              <Col md={8} xs={12} style={{ padding: 0 }}>
                <h2>{language.UNAVAILABLE}</h2>
              </Col>
            </Row>
          </div>
        </Row>
      )
      this.store.loading = false;
    }



    //console.log(JSON.stringify(_device));

    let sysInfo = values['sys-info'] ? values['sys-info'] : _device.values['sys-info'] ? _device.values['sys-info'] : {};
    let servicesStats = values['service-list']
      ? Object.keys(values['service-list']).map(service =>
        <Switch
          toggled={!/inactive/i.test(values['service-list'][service])}
          trackSwitchedStyle={{ backgroundColor: '#B5FFB9' }}
          thumbSwitchedStyle={{ backgroundColor: '#0DFFB8' }}
          trackStyle={{ backgroundColor: '#FF9B7D' }}
          thumbStyle={{ backgroundColor: '#F44336' }}
          label={service}
          labelPosition='left'
          key={service} />)
      : '';
    let conf = values.conf ? values.conf : _device.conf;
    let diskAlert = false;

    disk = disk.sort((a, b) => percent(b.values.used, b.values.total) - percent(a.values.used, a.values.total));

    let netOptions = netStats.map((n, i) => <MenuItem value={i} key={i} primaryText={n.name}>{n.name}</MenuItem>);

    let diskOptions = disk.map((d, i) => {

      d.values.used = parseInt(d.values.used);
      d.values.total = parseInt(d.values.total);
      let percent = percent(d.values.used, d.values.total);


      let icon = <span className='text-green'>{percent}%</span>;

      if (percent >= conf['fs-list'].triggers.warning.value) { icon = <span className='text-yellow'>{percent}%</span>; diskAlert = diskAlert !== 'danger' ? 'warning' : diskAlert }
      if (percent >= conf['fs-list'].triggers.danger.value) { icon = <span className='text-red'>{percent}%</span>; diskAlert = 'danger' }

      return <MenuItem value={i} key={i} primaryText={d.name} rightIcon={icon}>{d.name}</MenuItem>;
    });

    let diskPercent = disk[diskIndex] ? percent(disk[diskIndex].values.used, disk[diskIndex].values.total) : 1;
    let dateUnavailable = values['agent-info'] ? values['agent-info'] : _device.values['agent-info'];

    let token = crypt((_device.id_device).toString());
    if (connection) {
      if (connection === 'ssh') {
        token = '{"token": "' + token + '", "url": "' + window.location.origin + '"}';
      }
      else if (connection === 'rdp') {
        token = (
          <div>
            <div>Token: {token}</div>
            <div>URL: {window.location.origin}</div>
          </div>
        );
      }
    }


    if (!removeloading) {
      this.store.loading = true;
    } else {
      this.store.loading = false;
    }

    let dangerMem = 70;
    let warningMem = 90;
    let dangerCpu = 70;
    let warningCpu = 90;
    let dangerList = 70;
    let warningList = 90;
    for (var k in _device.conf) {

      if (k === 'mem-info') {
        try {
          dangerMem = _device.conf[k].triggers.danger.value
          warningMem = _device.conf[k].triggers.warning.value
        } catch (e) {
          dangerMem = 70;
          warningMem = 90;
        }
      }
      if (k === 'cpu-stats') {
        try {
          dangerCpu = _device.conf[k].triggers.danger.value
          warningCpu = _device.conf[k].triggers.warning.value
        } catch (e) {
          dangerCpu = 70;
          warningCpu = 90;
        }
      }

      if (k === 'fs-list') {
        try {
          dangerList = _device.conf[k].triggers.danger.value
          warningList = _device.conf[k].triggers.warning.value

        } catch (e) {
          warningList = 70;
          dangerList = 90;
        }
      }

    }

    return (
      <React.Fragment>
        <div className='item-title d-flex align-items-center justify-content-between'>
          <span className='company' onClick={down ? () => { } : () => handlePage('summary', props)}>{(this.state.position_6 || _device.name).toUpperCase()}</span>
          <div>
            {!!uptime.length &&
              <span>{'Uptime: ' + uptime}</span>
            }
          </div>
        </div>
        <hr />

        <div className='d-flex align-items-center justify-content-between last-item-group'>
          <div>
            <a href="#" className='device-ip'>{_device.ip}{_device.port ? ':' + _device.port : ''}</a>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='server-icon'>
                {vendors.getIcon(sysInfo.vendor)}
              </div>
              <div>
                {
                  servicesStats
                    ?

                    { servicesStats }

                    : (
                      <Fragment>
                        <h4>{cors ? 'CPU: ' + cors + ' Cors' : ''}</h4>
                        <h4>{memory ? 'Mem: ' + memory + 'GB' : ''}</h4>
                      </Fragment>
                    )
                }

              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-end'>

            {this.graphs([this.props.snmpgraph.filter(g => g.position == 1).map(g => g.graph)[0]], this.state.position_1)

            }
            {this.graphs([this.props.snmpgraph.filter(g => g.position == 2).map(g => g.graph)[0]], this.state.position_2)

            }
            {this.graphs([this.props.snmpgraph.filter(g => g.position == 3).map(g => g.graph)[0]], this.state.position_3)

            }
            {this.graphs([this.props.snmpgraph.filter(g => g.position == 4).map(g => g.graph)[0]], this.state.position_4)

            }






          </div>
        </div>
      </React.Fragment>
    )
  }
}
function Graph_circle(props) {
  return (

    <div className='graphs-item'>
      <h4 className='title'>{props.title}</h4>
      <Radial
        low={{ color: '#33ABA0' }} mid={{ color: '#FFC107', value: props.mid.value }}
        high={{ color: '#F44336', value: props.high.value }}
        className="host-graph-perc radial-graph-item-of-group"
        size={70}
        current={props.current}
        total={100}
        thick={8}
      >
        <span className='d-flex align-items-center'>
          {(typeof props.icon) == 'string' ? (
            <i className={props.icon}></i>
          ) : props.icon}
          {props.current}%
        </span>
      </Radial>
      <h4 className='sub-title'>{props.subTitle}</h4>
    </div>

  )
}