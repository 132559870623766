import CommonTable from '../../common/CommonTable';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import CommonForm2 from '../../common/CommonForm2';
import { useState } from 'react';
const TableTree = ({ col, data, language, options, saveItens, values, pageTree, setPageTree}) => {

  return (
    <div>
      <CommonTable
        col={col}
        data={data}
        paginationTop={false}
        beforeBar={
          <div>
            <Button
              variant="circle"
              icon="fas fa-plus"
              color="warning"
              label={language.ADD}
              className="mr-3"
              onClick={() => setPageTree('add')}
            />
          </div>
        }
      />
      {pageTree == 'add' && (
        <Dialog
          title={language.ADD}
          onClose={() => setPageTree('list')}
          miniVersion={true}
          maxWidth="md"
          open={true}
        >
          <CommonForm2
            fields={[
              {
                col: 12,
                name: 'itens',
                label: language.CHOOSE_ITENS,
                required: true,
                type: 'listCheckbox',
                options: options.map(o => ({value : o.id_resolution_category, label : o.name})),
              },
            ]}
            values={{itens :values}}
            onSubmit={saveItens}
          />
        </Dialog>
      )}
    </div>
  );
};

export default TableTree;
