import RaisedButton from '../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import lodash from 'lodash';
import { Container, Row, Col } from 'react-grid-system';
import PageLayout from '../../../../common/PageLayout';
import Help from '../../../../common/components/Help';
import CommonPage from '../../../../common/CommonPage';
import areIntlLocalesSupported from 'intl-locales-supported';
import {

  Dialog,

  SelectField,

  RadioButton,

  CircularProgress,
  DatePicker
} from '@material-ui/core';
import Pickers from '@common/Pickers';
import { Card, TextField, MenuItem, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faUser,
  faPaperclip,
  faClock
} from '@fortawesome/free-solid-svg-icons';
import CardSections from './components/CardSections';
import AppBar from '@common/AppBar';

const styleH2 = {
  textAlign: 'left'
};

const subtitle = {
  color: '#009dc0'
};
const iconsS = {
  color: '#009dc0',
  marginRigth: '5px'
};

const mouthI = new Date().getMonth();
const mouthF = new Date().getMonth() + 1;
var arrayReport = [];
var arraySections = [];
var finalChecksids = [];
var passResult = [];
var showCardStandarts = 0;
var timeout = false;

let DateTimeFormat;
if (areIntlLocalesSupported(['pt', 'pt-BR'])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} else {
  const IntlPolyfill = require('intl');
  DateTimeFormat = IntlPolyfill.DateTimeFormat;
  require('intl/locale-data/jsonp/pt');
  require('intl/locale-data/jsonp/pt-BR');
}

@observer
export default class RiskManagement extends CommonPage {
  constructor(props) {
    super(props);

    const minDate = new Date();
    const maxDate = new Date();
    minDate.setMonth(minDate.getMonth() - 1);
    minDate.setHours(0, 0, 0, 0);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setHours(0, 0, 0, 0);

    this.state = {
      valueSelect2: '',
      valueCompanie: '',
      minDate: minDate,
      maxDate: maxDate,
      CirucularLoadingGraph: false,
      loadingGraph: false
    };
    this.generateReportBtn = this.generateReportBtn.bind(this);
    this.openFilec = this.openFilec.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.setTime = this.setTime.bind(this);
    this.cleatTime = this.cleatTime.bind(this);
  }

  componentWillMount() {
    this.props.action.execute(
      'get',
      `${this.props.APIEndpoints.COMPANY}`,
      'companies'
    );
  }

  handleChangeMinDate = (date) => {
    this.setState({
      minDate: new Date(date)
    });
  };

  handleChangeMaxDate = (date) => {
    this.setState({
      maxDate: new Date(date)
    });
  };

  changeCompanies = (evt) => {
    this.setState({ valueCompanie: evt.target.value });
    this.resultCompanie = evt.target.value;
    this.deviceItem = this.store.devices.map(device => {
      if (device.fk_id_company == evt.target.value)
        return (
          <MenuItem
            key={device.id_device}
            value={device.id_device}
            primaryText={device.name}
          />
        );
    });
  };

  changeSelect2 = (evt) => {
    this.setState({ valueSelect2: evt.target.value });
    this.resultDevice = evt.target.value;
  };

  setTime = () => {
    var stimeoutF = setTimeout(
      function () {
        this.setState({ loadingGraph: true, CirucularLoadingGraph: false });
        this.generateReportBtn();
        timeout = true;
      }.bind(this),
      5000
    );
    return stimeoutF;
  };

  cleatTime = (timeoutt, timetrue) => {
    if (timetrue) {
      clearTimeout(timeoutt);
      timeout = false;
    }
    return timeout;
  };

  generateReportBtn = () => {
    if (this.state.valueCompanie != null && this.state.valueSelect2 != null) {
      var arrayFilter = {
        company: this.state.valueCompanie,
        standards: this.state.valueSelect2,
        minDate: this.state.minDate,
        maxDate: this.state.maxDate
      };
      this.props.action.execute(
        'post',
        `${this.props.APIEndpoints.REPORT}` + '/14',
        'repor_risks_test',
        arrayFilter
      );
      this.props.action.execute(
        'get',
        `${this.props.APIEndpoints.RISK}/section/${this.state.valueSelect2}`,
        'sections'
      );

      showCardStandarts = this.state.valueSelect2;
      passResult = this.generateReport();
      this.setState({ CirucularLoadingGraph: true });
      var timeOutint = this.setTime();
      this.cleatTime(timeOutint, timeout);
    }
  };

  generateReport = () => {
    arrayReport.map(report => {
      if (report.attachment != null) {
        report.attachment.attachments.map(att => {
          finalChecksids.push({
            idcheck: att.check_select,
            usrsponsor: report.name,
            namefile: att.namefile,
            desc: att.descr,
            data: report.dt_cad
          });
        });
      }
    });
    return finalChecksids;
  };

  openFilec = namefile => {
    this.props.action.execute(
      'get',
      `${this.props.APIEndpoints.RISK}/read/upload/${this.state.valueCompanie
      }/${namefile}`,
      'companies'
    );
  };

  render() {
    let { companies, sections } = this.store;
    let { handlePage, language } = this.props;
    this.companyItem = companies.map(co => (
      <MenuItem
        key={co.id_company}
        value={co.id_company}
        primaryText={co.name}
      >
        {co.name}
      </MenuItem>
    ));
    arrayReport = this.store.repor_risks_test;
    arraySections = this.store.sections;

    return (
      <PageLayout
        icon={
          <div className="imgGr" style={{ width: '44px', height: '44px' }} />
        }
        back={()=>   handlePage('dash')}
        title={language.RISKS_MANAGEMENT}
        subtitle={language.RISK_M_ANALYSIS}
        rightElements={[]}
      >
        <div>
          <div>
            <Paper className="pb-2">
              <AppBar light>
                <span className='font titlePattern'>{language.COMPANY_FILTER_STANDARDS}</span>
              </AppBar>
              <Container fluid>

                <Row>
                  <Col md={12}>
                    <div>

                      <h3 className='titlePattern' style={{ color: '#616161cf', textAlign: 'left' }}>
                        {language.SELECT_COMPANY_STANDARD_REPORT}
                      </h3>
                    </div>
                  </Col>
                </Row>
                <div className='d-flex align-items-center'>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label={language.COMPANY}
                    style={{ minWidth: 200 }}
                    value={this.state.valueCompanie}
                    onChange={this.changeCompanies}
                    margin="dense"
                    variant="outlined"
                  >
                    {this.companyItem}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label={language.STANDARDS}
                    style={{ minWidth: 200 }}
                    value={this.state.valueSelect2}
                    onChange={this.changeSelect2}
                    margin="dense"
                    variant="outlined"
                    className='mx-3'
                  >
                    <MenuItem key={1} value={'1'} > ISO 27001 </MenuItem>
                    <MenuItem key={2} value={'2'} > ISO 20000 </MenuItem>
                    <MenuItem key={3} value={'3'} > COBIT </MenuItem>
                    <MenuItem key={4} value={'4'} > LGPD </MenuItem>
                    <MenuItem key={8} value={'8'} > ISO 37001 </MenuItem>
                  </TextField>
                  <Pickers
                    type='DatePicker'
                    label={language.INITIAL_DATE}
                    locale='pt-br'
                    value={this.state.minDate}
                    onChange={(e) => this.handleChangeMinDate(e)}
                    variant='outlined'
                    margin='dense'
                  />
                  <Pickers
                    type='DatePicker'
                    label={language.END_DATE}
                    locale='pt-br'
                    value={this.state.maxDate}
                    onChange={(e) => this.handleChangeMaxDate(e)}
                    variant='outlined'
                    margin='dense'
                    className='mx-3'
                  />
                  <div>
                    <RaisedButton
                      circleButton
                      icon='fas fa-file-signature'
                      color='primaryGradient'

                      style={{ fontSize: 15, paddingLeft: 3.5 }}
                      ref={input => (this.btnger = input)}
                      onClick={() => this.generateReportBtn()}
                      label={language.GENERATE_REPORT}

                    />
                  </div>

                </div>


              </Container>
            </Paper>

            {this.state.loadingGraph == true ? (
              showCardStandarts == 1 ? (
                <div>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Política de segurança'}
                        openFilec={this.openFilec}
                        numsection={1}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Organização da segurança'}
                        openFilec={this.openFilec}
                        numsection={8}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Administração de ativos'}
                        openFilec={this.openFilec}
                        numsection={17}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Segurança no RH'}
                        openFilec={this.openFilec}
                        numsection={24}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Segurança Física e Meio Ambiente'}
                        openFilec={this.openFilec}
                        numsection={34}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Gestão de Comunicações e de Operações'}
                        openFilec={this.openFilec}
                        numsection={24}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Controle de Acesso'}
                        openFilec={this.openFilec}
                        numsection={70}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Desenvolvimento e Manutenção de Sistemas'}
                        openFilec={this.openFilec}
                        numsection={89}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Gerenciamento de Continuidade de Negócios'}
                        openFilec={this.openFilec}
                        numsection={103}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Complemento'}
                        openFilec={this.openFilec}
                        numsection={109}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                </div>
              ) : showCardStandarts == 2 ? (
                <div>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Section 4'}
                        openFilec={this.openFilec}
                        numsection={115}
                        numsection2={155}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Section 5'}
                        openFilec={this.openFilec}
                        numsection={176}
                        numsection2={187}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Section 6'}
                        openFilec={this.openFilec}
                        numsection={190}
                        numsection2={220}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Section 7'}
                        openFilec={this.openFilec}
                        numsection={239}
                        numsection2={248}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'Section 8'}
                        openFilec={this.openFilec}
                        numsection={256}
                        numsection2={262}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'Section 9'}
                        openFilec={this.openFilec}
                        numsection={268}
                        numsection2={278}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                  </Row>
                </div>
              ) : showCardStandarts == 3 ? (
                <div>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM01.01'}
                        openFilec={this.openFilec}
                        numsection={283}
                        numsection2={285}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={true}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM01.02'}
                        openFilec={this.openFilec}
                        numsection={294}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM01.03'}
                        openFilec={this.openFilec}
                        numsection={301}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM02.01'}
                        openFilec={this.openFilec}
                        numsection={309}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM02.02'}
                        openFilec={this.openFilec}
                        numsection={318}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM02.03'}
                        openFilec={this.openFilec}
                        numsection={326}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM03.01'}
                        openFilec={this.openFilec}
                        numsection={333}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM03.02'}
                        openFilec={this.openFilec}
                        numsection={340}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM03.03'}
                        openFilec={this.openFilec}
                        numsection={347}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM04.01'}
                        openFilec={this.openFilec}
                        numsection={353}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM04.02'}
                        openFilec={this.openFilec}
                        numsection={359}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM04.03'}
                        openFilec={this.openFilec}
                        numsection={365}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM05.01'}
                        openFilec={this.openFilec}
                        numsection={370}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM05.02'}
                        openFilec={this.openFilec}
                        numsection={374}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'EDM05.03'}
                        openFilec={this.openFilec}
                        numsection={379}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'DISPOSIÇÕES PRELIMIRARES'}
                        openFilec={this.openFilec}
                        numsection={382}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'TRATAMENTO DE DADOS PESSOAIS'}
                        openFilec={this.openFilec}
                        numsection={388}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'DIREITOS DO TITULAR'}
                        openFilec={this.openFilec}
                        numsection={392}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'TRANSFERÊNCIA INTERNACIONAL DE DADOS'}
                        openFilec={this.openFilec}
                        numsection={398}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'AGENTES DE TRATAMENTO DE DADOS PESSOAIS'}
                        openFilec={this.openFilec}
                        numsection={402}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'SEGURANÇA E DAS BOAS PRÁTICAS'}
                        openFilec={this.openFilec}
                        numsection={408}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={'FISCALIZAÇÃO'}
                        openFilec={this.openFilec}
                        numsection={412}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardSections
                        namecard={
                          'AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS (ANPD)'
                        }
                        openFilec={this.openFilec}
                        numsection={418}
                        numsection2={0}
                        arraysections={arraySections}
                        attachs={passResult}
                        noexact={false}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {this.state.CirucularLoadingGraph == true ? (
              <div>
                <Row style={{ textAlign: 'center' }}>
                  <br />
                  <CircularProgress />
                </Row>
              </div>
            ) : (
              ''
            )}

            <br />
            <br />
          </div>
        </div>
      </PageLayout>
    );
  }
}
