import CommonForm2 from '../../../common/CommonForm2';
import Constants from '../../../data/Constants';
import Store from '../../../data/Store';

export const formshtml = (t, updateState) => {
  let data = [];

  t.split('<div').map((i, key) => {
    if (i.indexOf('companies_var') > -1) {
      data.push(
        data.push(
          <div id="companies_var" style={{ margin: '10px 0px' }}>
            <CommonForm2
              onChangeField={(field, value) => {
                updateState({ companies_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'companies_var',
                  label: '',
                  method: 'POST',
                  route: `${Constants.APIEndpoints.COMPANY}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: 'Empresa',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
                  detail: true,
                  visible: true,
                  required: true,
                  disablePortal: true,
                  showId: true,
                  idFieldToShow: 'id_company',
                },
              ]}
            />
          </div>
        )
      );
    } else if (i.indexOf('allusers_var') > -1) {
      data.push(
        <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
          <CommonForm2
            onChangeField={(field, value) => {
              updateState({ allusers_var: value });
            }}
            fields={[
              {
                col: 12,
                type: 'autocomplete',
                name: 'allusers_var',
                label: '',
                method: 'POST',
                route: `${Constants.APIEndpoints.USER}/filter`,
                fieldquery: 'search',
                textlabel: `Usuário`,
                textinit: 'Usuário',
                fieldvaluedb: 'name',
                fieldlabeldb: 'name',
                idinit: 'Usuário',
                routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                detail: true,
                visible: true,
                required: true,
                disablePortal: true,
                showId: true,
                idFieldToShow: 'id_user',
              },
            ]}
          />
        </div>
      );
    } else if (i.indexOf('hours') > -1) {
      data.push(
        <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
          <CommonForm2
            onChangeField={(field, value) => {
              updateState({ hours: value });
            }}
            fields={[
              {
                col: 12,
                name: 'hours_var',
                label: 'Horas',
                type: 'time',
                format: '##:##',
              },
            ]}
          />
        </div>
      );
    } else if (i.indexOf('subsdpt_var') > -1) {
      data.push(
        <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
          <CommonForm2
            onChangeField={(field, value) => {
              updateState({ subdepartments_var: value });
            }}
            fields={[
              {
                col: 12,
                type: 'autocomplete',
                name: 'subdepartments',
                label: '',
                textlabel: '',
                method: 'POST',
                filterAll: { fk_id_department: 1 },
                filter: { fk_id_department: 1 },
                route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                fieldquery: 'search',
                textinit: 'Subdepartamento',
                fieldvaluedb: 'name',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                methodGetAll: 'POST',
                required: true,
                disablePortal: true,
                showId: true,
                idFieldToShow: 'id_department',
              },
            ]}
          />
        </div>
      );
    } else if (i.indexOf('departments_var') > -1) {
      data.push(
        <div id="departments_var" style={{ margin: '10px 0px' }}>
          <CommonForm2
            onChangeField={(field, value) => {
              updateState({ departments_var: value });
            }}
            fields={[
              {
                col: 12,
                type: 'autocomplete',
                name: 'departments',
                label: '',
                textlabel: '',
                method: 'POST',
                route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
                routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
                fieldquery: 'search',
                textinit: 'Departamentos',
                fieldvaluedb: 'name',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
                methodGetAll: 'POST',
                required: true,
                disablePortal: true,
                showId: true,
                idFieldToShow: 'id_department',
              },
            ]}
          />
        </div>
      );
    } else if (i.indexOf('contracts_var') > -1) {
      data.push(
        <div>
          <CommonForm2
            onChangeField={(field, value) => {
              updateState({ contracts_var: value });
            }}
            fields={[
              {
                col: 12,
                type: 'autocomplete',
                name: 'contracts_var',
                label: Store.language.CONTRACTS,
                method: 'POST',
                route: `${Constants.APIEndpoints.CONTRACT}/filter`,
                fieldquery: 'search',
                textlabel: ``,
                textinit: 'Contrato',
                fieldvaluedb: 'id_contract',
                fieldlabeldb: 'name',
                idinit: 0,
                routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
                methodGetAll: 'POST',
                routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
                textlabel: Store.language.CONTRACTS,
                showId: true,
                idFieldToShow: 'id_contract',
              },
            ]}
          />
        </div>
      );
    } else if (i.indexOf('chieftdpt_var') > -1) {
      data.push(
        <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
          <CommonForm2
            onChangeField={(field, value) => {
              updateState({ chieftdpt_var: value });
            }}
            fields={[
              {
                col: 12,
                type: 'autocomplete',
                name: 'boss',
                label: '',
                textlabel: ``,
                method: 'POST',
                filter: { fk_id_company: 1 },
                route: `${Constants.APIEndpoints.USER}/filter`,
                routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
                fieldquery: 'search',
                textinit: 'Chefe de Departamento',
                fieldvaluedb: 'name',
                fieldlabeldb: 'name',
                idinit: 'Chefe de Departamento',
                routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
                filterAll: 0,
                methodGetAll: 'POST',
                detail: true,
                visible: true,
                required: true,
                disablePortal: true,
              },
            ]}
          />
        </div>
      );
    } else {
      if (i.length > 0) {
        let g = '';
        if (i.indexOf('ql-tooltip ql-hidden') > -1) {
          g = `<div style = "display : none" ${i}`;
        } else if (
          i.indexOf('ql-preview') > -1 ||
          i.indexOf('ql-clipboard') > -1 ||
          i.indexOf('ql-snow') > -1
        ) {
          g = `<div  ${i}`;
        } else if (i.indexOf('ql-editor') > -1) {
          g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
        } else {
          g = `<div class = "template-dinamic" ${i}`;
        }

        data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
      }
    }
  });
  return data;
};
