import React, { memo, useState, useEffect } from 'react';
import ListCheckbox from './ListCheckbox';

let delayTimer;
export default memo(
  ({
    f,
    statusError,
    _value,
    _handleValue,
    _handleFilter,
    errors,
    language,
    classes,
    single = false,
  }) => {
    let [value, setValue] = useState([]);

    function handleValue(val, name, selected) {
      setValue(val);

      clearTimeout(delayTimer);

      //delayTimer = setTimeout(() => {
      _handleValue(val, f.name, selected);
      //}, 400);
    }

    useEffect(() => {
      setValue(_value);
    }, [_value]);

    return (
      <ListCheckbox
        field={f}
        values={value}
        errors={statusError[errors[f.name]]}
        handleValue={(val, selected) => handleValue(val, f.name, selected)}
        handleFilter={_handleFilter}
        spacing={f.spacing}
        col={f.colOptions}
        card={f.card}
        search={f.search}
        language={language}
        selectAll={f.selectAll}
        classes={classes}
        single={single}
      />
    );
  }
);
