import React, { Component } from 'react';
import { Card, Paper } from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import FaClose from 'react-icons/lib/fa/close';

export default class EffortForm extends Component {
  render() {
    let { language, handlePage, onFormSubmit, values } = this.props;


    //(idmail, mail, password, pop, smtp, host, port, tls, host_value, port_value, tls_value, deleted)
    let fields = [
      {
        col: 12,
        name: 'value',
        label: `${language.VALUE} *`,
        type: 'text',
        required: true,
        disabled: true,
      },
      {
        col: 12,
        name: 'hour',
        label: `${language.AMOUNT_OF_HOURS} *`,
        type: 'text',
        required: true,
        multiLine: true,
        format: '##:##'
      }

    ];

    let button = { label: language.SAVE };

    return (
      <Dialog maxWidth="xs" miniVersion = {true} monitor_scroll title={values ? language.EDIT : 'Adicionar'} modal={false} open={true} onClose={() => handlePage('list')}>

        <CommonForm
          values={values}
          fields={fields}
          onSubmit={onFormSubmit}
          button={button}
        />

      </Dialog>
    );
  }
}
