import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CommonPage from '../../common/CommonPage';

import TypeContractList from './TypeContractList';
import TypeContractForm from './TypeContractForm';

@observer
export default class TypeContractContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'listTypeContracts',
      open: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
  }

  // componentDidMount() {
  //   this.action.execute('get', this.APIEndpoints.TYPE_CONTRACTS, 'typeContracts');
  // }

  onFormSubmit() {}

  handlePage(page) {
    this.setState({ page: page });
  }

  render() {
    let { language } = this.store;
    let { page, values, open } = this.state;
    return (
      <CommonPage
        title={
          <div
            className="logoWhite"
            onClick={() => this.redirect('/dash')}
          />
        }
        showMenuIconButton={false}
        iconAlert={true}
      >
        {page === 'listTypeContracts' ? (
          <TypeContractList
            redirect={this.redirect}
            handlePage={this.handlePage}
            language={language}
            {...this.state}
          />
        ) : page === 'typeContractsForm' ? (
          <TypeContractForm
            redirect={this.redirect}
            handlePage={this.handlePage}
            language={language}
            onFormSubmit={this.onFormSubmit}
            {...this.state}
          />
        ) : (
          ''
        )}
      </CommonPage>
    );
  }
}
