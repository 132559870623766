import { useEffect, useState, Fragment } from "react"
import '../../../public/css/pages.css';
import Store from '@data/Store'
import CommonDelete from '../../common/CommonDelete';
import LocationForm from './LocationForm';
import LocationList from './LocationList';
import Dialog from '@common/Dialog';
import Actions from '@data/Actions'
const action = new Actions()
import Constants from "../../data/Constants";

const LocationContainer = () => {
  const [page, setPage] = useState('list')
  const [values, setValues] = useState([])
  const [locations, setLocations] = useState([])
  const [totalLocations, setTotalLocations] = useState(0)
  const [currentPage, setCurrentPage] = useState()
  const [filter, setFilter] = useState({})
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [countries, setCountries] = useState([])

  const onFormSubmit = (values) => {
    const method = values.hasOwnProperty('id_location') ? 'put' : 'post';
    const url = values.hasOwnProperty('id_location')
      ? Constants.APIEndpoints.LOCATION + '/' + values.id_location
      : Constants.APIEndpoints.LOCATION;
    action
      .execute(method, url, '', values, '')
      .then((v) => {
        handlePage('list')
        callPageSelectFilter(currentPage, filter, 75)
      }).catch(e => console.log(e))
  }

  const onChangeField = (field, value) => {
    if (field.name === 'id_country') {
      action.execute(
        'get',
        Constants.APIEndpoints.COUNTRY + '/' + value,
        ''
      ).then(res => {
        setStates(res.data)
        setCities([])
      })
    } else if (field.name === 'id_state') {
      action.execute(
        'get',
        Constants.APIEndpoints.STATE + '/' + value,
        ''
      ).then(res => {
        setCities(res.data)
      })
    }
  }

  useEffect(() => {
    callPageSelectFilter()
    action.execute('get', Constants.APIEndpoints.COUNTRY, 'countries').then(res => {
      setCountries(res.data)
    })
  }, [])

  const callPageSelectFilter = (page = 0, values = {}, totalPerPage = 75) => {
    setCurrentPage(page)
    setFilter(values)
    let limit = totalPerPage || 75;
    let offset = page * limit;

    action
      .execute(
        'post',
        `${Constants.APIEndpoints.LOCATION}/filter?limit=${totalPerPage}&offset=${offset}`,
        '',
        values
      )
      .then((res) => {
        setLocations(res.data)
        setTotalLocations(parseInt(res.data[0].count))
      })
      .catch((error) => {
        //this.store.toggleAlert(true, 'Aguarde estamos processando os dados', 'success');
      });
  }

  const handlePage = (page, edit) => {
    setPage(page)
    setValues(edit)
  }

  const onDelete = () => {
    action
      .execute(
        'delete',
        `${Constants.APIEndpoints.LOCATION}/${values.id_location}`,
        ''
      )
      .then((res) => {
        // callPageSelectFilter(currentPage, filter, 75)
        // handlePage('list')
        window.location.reload();

      });
  }


  return (
    <div>
      <LocationList
        language={Store.language}
        locations={locations}
        handlePage={handlePage}
        currentPage={currentPage}
        totalLocations={totalLocations}
        callPageSelectFilter={callPageSelectFilter}
      />

      {['new', 'edit'].includes(page) && (
        <Dialog
          title={
            page == 'new' ? `${Store.language.TO_REGISTER} ${Store.language.NEW_LOCATION}` : `${Store.language.EDIT} ${Store.language.LOCATION}`
          }
          open={true}
          onClose={() => handlePage('list')}
          maxWidth="xl"
        >
          <LocationForm
            values={values}
            cities={cities}
            states={states}
            countries={countries}
            action={action}
            language={Store.language}
            onChangeField={onChangeField}
            onFormSubmit={onFormSubmit}
            APIEndpoints={Constants.APIEndpoints}
          />
        </Dialog>
      )}

      {page == 'remove' && (
        <CommonDelete
          language={Store.language}
          closeModal={() => handlePage('list')}
          onDelete={onDelete}
          message={
            Store.language.WARNING_DELETE_LOCATION +
            values.name
          }
        />
      )}
    </div>
  );
}
export default LocationContainer
