import React, {memo, useEffect, useState} from 'react';
import moment from 'moment';
// ----------------------------common--------------------------------------------

import {flexBasis} from '@common/Func';
import Header from '@common/Header';
import SwitchIos from '@common/SwitchIos';
// ----------------------------material-ui--------------------------------------------
import {Card, Grid} from '@material-ui/core';
// ----------------------------components--------------------------------------------
import ReactHighcharts from 'react-highcharts';
import {ActivityConcluded} from './Graph';
import CommonForm from '@common/CommonForm2';
import AppBar from '@common/AppBar2';
import CompletedActivitiesMonthTech from './CompletedActivitiesMonthTech'
import Store from '@data/Store';

const { language } = Store;

 export default memo(({
	totalUnplaned ,totalPlanned,
 
	  sprintkaban ,
	users,
	sprints,
	action,
	APIEndpoints
})=>{ 


//sprinthometotalhour
let [dados,setDados] =useState([]);

useEffect(() => {
	let i =sprints.map(b=>b.id_project_sprint)
	setDados([])
	  if(i.length>0){
	action
	  .execute('post', APIEndpoints.PROJECT+'/sprint/sprinthometotalhour','',
	    {sprints:i})
	  .then((e) => {
		setDados(e.data);
	  })
	  .catch((error) => {
		console.log(error);
	  });
	}
  }, [sprints]);
 

 
    let techs= dados.reduce(function( acc, curr) {
     let g=parseInt(curr.hours_consumed.split(':')[0]) 
 
	  acc[curr.fk_id_user] = acc[curr.fk_id_user] || 0;
	  acc[curr.fk_id_user] = parseInt( acc[curr.fk_id_user])+ g
	  return acc;
	},[]);

 
	  let a=[];
	  let b=[];
	 
		  techs.map((v,i)=>{
			let name="";
			try{
			   name= users.filter(c=>c.id_user == i)[0].name
			}catch(e){

			}
			if(name != ""){
		    a.push(name) 
			  
			b.push(techs[i])
			}
		  })


  return ( <div>
<Header className='justify-content-end'>
   
 
</Header>
<Card className='content block1_cad'>
<Grid container spacing={12}>

 <Grid item xs={3} style={{...flexBasis(3)}}>
 <Card className='card-activity'>
						<AppBar title={language.UNPLANNED_ACTIVITIES} titleProps={{style:{color:'white'}}} subTitle='(total)' subTitleProps={{style:{color:'white'}}}/>
				<div className='content-medium content-info pt-0'>
					<h2 className='mr-3'>{totalUnplaned}</h2>
							<span>{language.ACTIVITIES}</span>
				</div>
	 </Card>
	 </Grid>
 <Grid item xs={3} style={{...flexBasis(3)}}>
	 <Card className='card-activity card_red_ '>
				<AppBar title={language.PLANNED_ACTIVITIES} titleProps={{style:{color:'white'}}} subTitle='(total)' subTitleProps={{style:{color:'white'}}}/>
				<div className='content-medium content-info pt-0'>
					<h2 className='mr-3'>{totalPlanned}</h2>
							<span>{language.ACTIVITIES}</span>
	 </div>
 </Card></Grid>

 

 <Grid item xs={6} style={{...flexBasis(6)}}>
 <Card  >
						<AppBar title={language.HOURS_PER_ANALYST}>
  		   </AppBar>
  <div  >

				<ReactHighcharts
				  config={ActivityConcluded({
				  	categories:a,
				  	data:b, //isso é um lorem, que gera 12 itens aleatórios
	  })}
				/>
   	</div>
  		</Card>

</Grid>


</Grid>
</Card>
</div>
)


} )