import React, { Component } from 'react';
import classnames from 'classnames';
import AppBar from '@common/AppBar';
import './style.css';

export default class Boll extends Component{
	render(){
		var {el, color, contentProps={}, valueProps={}, descriptionProps={}, value, description, className, ...other} = this.props;
		
		return (
			<div {...contentProps} className={classnames('content-boll', contentProps.className)} style={{'--content--color': color, ...contentProps.style}}>
			  {el||(
				  <div className={classnames("boll", className)} {...other}>
				    <div className="body-boll">
				      <span {...valueProps} className={classnames("font", valueProps.className)}>{value}</span>
				    </div>
				  </div>
			  	)
			  }
			  <AppBar {...descriptionProps} className={classnames("tooltip", descriptionProps.className)} >{description}</AppBar>
			</div>
		)
	}
} 