import Constants from "@data/Constants";
import Actions from '@data/Actions';

const action = new Actions();

export const getTokenSuperset = async () => {
  try {
    return action
      .execute('get', `${Constants.APIEndpoints.USER}/loginSupersetToken`)
      .then((res) => {
        return res.data;
      });
  } catch (e) {
    console.log('Error:', e);
  }
};
