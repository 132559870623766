import React from 'react';
import CommonPage from '@common/CommonPage';
import { observer } from 'mobx-react';
import moment from 'moment';
import './style.css';
import Constants from '@data/Constants';
import Dashboard from './Dashboard';
import OverlayLoading from '@common/OverlayLoading';

let saveInternval = null;
@observer
export default class DashboardContent extends CommonPage {
  constructor() {
    super();
    this.state = {
      companies: [],
      dashboard: [],
      filterValues: [],
      isLoading: false,
    };

    this.searchProject = this.searchProject.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  componentWillUnmount() {
    clearInterval(saveInternval);
  }

  componentWillMount() {
    this.action
      .execute('get', Constants.APIRoot + '/api/generalparameters')
      .then((e) => {
        if (e.data.time_refresh_visao > 0) {
          saveInternval = setInterval(() => {
            const dtini = moment().startOf('month').format('DD/MM/YYYY');
            const dtend = moment().endOf('month').format('DD/MM/YYYY');
            const filters = this.state.filterValues || { dtini, dtend };
            this.searchProject(filters);
          }, e.data.time_refresh_visao * 60000);
        }
      });

    this.action.execute(
      'get',
      Constants.APIEndpoints.TABLE_STRUCTURED + '/20',
      'type_solution'
    );
    this.action.execute('get', Constants.APIEndpoints.ANSWER, 'answer');
    this.action.execute(
      'get',
      Constants.APIEndpoints.TICKET + '/params',
      'params'
    );
    this.action.execute(
      'post',
      this.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: this.store.getUserLoged().id },
      null,
      null,
      false
    );
    this.action.execute(
      'get',
      this.APIEndpoints.CONTRACT + '/listall',
      'contracts'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.DASHBOARD + '/groups',
      'categories'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.TICKET + '/avaliation',
      'avaliation'
    );

    this.action.execute('get', Constants.APIEndpoints.COMPANY).then((h) => {
      this.setState({ companies: h.data });
    });

    const dtini = moment().startOf('month').format('DD/MM/YYYY');
    const dtend = moment().endOf('month').format('DD/MM/YYYY');
    const filters = this.state.filterValues || { dtini, dtend };
    this.searchProject(filters);
  }

  async searchProject(filters) {
    this.setState({ isLoading: true });

    let dtini = moment().format('YYYYMM01');
    let dtend = moment().format(
      'YYYYMM' + moment().endOf('month').format('DD')
    );
    let month;
    let year;

    if (filters?.dtini && filters?.dtend) {
      dtini = moment(filters?.dtini).format('YYYYMMDD');
      dtend = moment(filters?.dtend).format('YYYYMMDD'); //moment(filters?.dtend, 'DD/MM/YYYY').format('YYYYMMDD');
      month = moment(dtini, 'YYYYMMDD').format('MM');
      year = moment(dtini, 'YYYYMMDD').format('YYYY');
    } else {
      dtini = moment().format('YYYYMM01');
      dtend = moment().endOf('month').format('YYYYMMDD'); //moment(filters?.dtend, 'DD/MM/YYYY').format('YYYYMMDD');
      month = moment().format('MM');
      year = moment().format('YYYY');
    }
    let contracts = [];
    let company = 0;
    let tech = 0;
    let group = 0;
    let type_date = 0;

    if (filters?.type_date) {
      type_date = filters.type_date;
    }
    if (filters?.fc) {
      contracts = { fc: filters?.fc };
    }

    if (filters?.tech) {
      tech = filters.tech;
    }
    if (filters?.group) {
      group = filters.group;
    }
    if (filters?.companys) {
      company = filters.companys;
    }

    try {
      await this.setState({ filterValues: filters });
      await this.action.execute(
        'get',
        `${this.APIEndpoints.DASHBOARD}/top10/company/?dt_i=${dtini}&dt_f=${dtend}`,
        'top10company'
      );

      let query = '';
      query += `?month=${month}`;
      query += `&year=${year}`;
      query += `&periodo=${dtini}|${dtend}`;
      query += `&itens=${JSON.stringify(contracts)}`;
      query += `&company=${JSON.stringify(company)}`;
      query += `&tech=${JSON.stringify(tech)}`;
      query += `&group=${JSON.stringify(group)}`;
      query += `&visonManeger=true`;
      query += `&BiCIO=false`;
      query += `&type_date=${type_date}`;

      await this.action
        .execute('get', this.APIEndpoints.DASHBOARD + 'bi' + query, 'dashboard')
        .then((r) => {
          this.setState({ dashboard: r.data });
        });
      this.setState({ isLoading: false });
    } catch (error) {
      console.error({ error });
      this.setState({ isLoading: false });
    }
  }

  setLoading(isLoading) {
    this.setState({ isLoading: !!isLoading });
    this.store.loading = !!isLoading;
  }

  render() {
    let {
      language,
      contracts,
      categories,
      usercon_company,
      avaliation,
      groups,
      top10company,
      users,
      params,
      getUserLoged,
    } = this.store;
    let { companies, dashboard, isLoading } = this.state;
    let { DASHBOARD } = this.store.theme;
    let { greenTheme } = DASHBOARD;
    return (
      <>
        {isLoading && <OverlayLoading />}
        <CommonPage
          title={
            <div className="logoWhite" onClick={() => this.redirect('/dash')} />
          }
          showMenuIconButton={false}
          iconAlert={true}
          loading={false}
        >
          {dashboard.ticketsGSD ? (
            <Dashboard
              dashboard={dashboard}
              language={language}
              companies={companies}
              gsdCount={dashboard}
              categories={categories}
              usercon_company={usercon_company}
              redirect={this.redirect}
              contracts={contracts}
              users={users}
              searchProject={this.searchProject}
              avaliation={avaliation}
              action={this.action}
              groups={groups}
              alert={this.store.toggleAlert}
              user={getUserLoged()}
              APIEndpoints={this.APIEndpoints}
              greenTheme={greenTheme}
              filterValues={this.state.filterValues}
              top10company={top10company}
              params={params}
            />
          ) : null}
        </CommonPage>
      </>
    );
  }
}
