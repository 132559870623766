import '../../../public/css/pages.css';

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import StockList from './StockList';
import StockForm from './StockForm';
import StockFormOut from './StockFormOut';
import StockFormHistory from './StockFormHistory';
import StockDetail from './StockDetail';
import { testRoute } from '@common/Func';
import Animate from '@common/Animate';
@observer
export default class StockContainer extends CommonPage {
  
  constructor() {
    super();
    this.state = {
      value: '',
      valueIn:'',
      valuesNew:'',
      page: 'list',
      valuesOut : '',
      id_user :'',
      id_company : '',
      companies : []
       
    }

     
     this.handlePage = this.handlePage.bind(this);
     this.onDelete = this.onDelete.bind(this);
     this.onFormSubmitIn = this.onFormSubmitIn.bind(this);
     this.onFormSubmitOut = this.onFormSubmitOut.bind(this);
    this.deleteStock = this.deleteStock.bind(this);

     // responsibleOptions = users.filter(user => user.id_company == values.fk_id_out_company).map(u => ({ value: u.id_user, text: u.name }));
  }

  componentWillMount() {
 
      
    this.action.execute('get', this.APIEndpoints.STOCKIN+"/stockinall",'stockins');
      this.setState({id_user: this.store.getUserLoged().id});
      this.setState({id_company: this.store.getUserLoged().id_company});
  }

 

  onFormSubmitIn(values) {
    let {valueIn={}} = this.state;
   
    let method = valueIn.id_device ? 'put' : 'post';

    this.action.execute(method, this.APIEndpoints.STOCKIN, 'stockins', values, 'id_device', {btn_loading:'request-stock'}).then(e => this.handlePage('list'));

  }

  onFormSubmitOut(values) {
    
    this.action.execute('put', this.APIEndpoints.STOCKOUT, 'stockins', values, 'id_device', {btn_loading:'request-stock'}).then(e => this.handlePage('list'));
 
    

  }
 
  onDelete() {

    let { values } = this.state;
    
    this.action.execute('delete', this.APIEndpoints.MAIL + '/' + values.id_mail, 'mail', null, 'id_mail');
    this.handlePage('list','');
    
  }

  handlePage(page, edit) {
  
       this.setState({ page: page, value: edit });
       
       if(page==='form' || page==='detail'){
        this.setState({ page: page, valueIn: edit });
      }   

      if(page==='formout' || page==='detail'){
        this.setState({ page: page, valueOut: edit });
      }

      if(page==='formhs'){
      /*this.action.execute('get', this.APIEndpoints.STOCKIN+"/stockinallItem/"+edit.id_device,'stockinlist');
      this.action.execute('put', this.APIEndpoints.STOCKOUT+"/sponso/"+edit.id_device,'spon');*/
      }
      if (page === "remove"){
        this.setState({page : page, values : edit})
      }
      
  }

  deleteStock (){
    let { values } = this.state;

    this.action.execute('post', this.APIEndpoints.STOCKIN  + '/' +'deletestockin',
     'stock_in', values, 'id_stock_in')
     .then ((e) => {
      window.location.reload()
     })




    this.handlePage('list');
  }


  render() {
    let { page, valueIn, valuesOuts,id_user,id_company } = this.state;
    let { language ,stockinlist, users} = this.store;
    let history = this.history2;
    let redirect = this.redirect2;
    let {state, pathname} = history.location;


    var value;

    if(state && testRoute(['/dash/stock/historic/detail', '/dash/stock/historic'], pathname)){
      value = state
    }else if(!state && testRoute(['/dash/stock/historic/detail', '/dash/stock/historic'], pathname)){
      history.goBack();
      return null;
    }
    
    return (
    
      <CommonPage loading={false} title={<div className="logoWhite" onClick={() => this.redirect('/dash')} />} showMenuIconButton={true} iconAlert={true}>
        {testRoute('/dash/stock', pathname) &&
          <Animate>
            <StockList  stockin={this.store.stockins}  redirect={redirect} language={language} handlePage={this.handlePage} store={this.store} action = {this.action} APIEndpoints = {this.APIEndpoints} />
          </Animate>
        }

        {testRoute('/dash/stock/historic', pathname) && 
          <Animate>
            <StockFormHistory redirect={redirect} id_user={id_user} action={this.action} APIEndpoints={this.APIEndpoints} {...this.state} value={value} store={this.store} language={language} handlePage={this.handlePage} />
          </Animate>
        }

        {testRoute('/dash/stock/historic/detail', pathname) && 
          <Animate>
            <StockDetail value={state} redirect={redirect} language={language} handlePage={this.handlePage}/>
          </Animate>
        }

        {page === 'form' ? <StockForm {...this.state}value={value} store={this.store}  language={language} handlePage={this.handlePage} onFormSubmitIn={this.onFormSubmitIn} /> : null}
        {page === 'formout' ? <StockFormOut id_user={id_user} {...this.state} value={value}  store={this.store}  language={language} handlePage={this.handlePage} onFormSubmitOut={this.onFormSubmitOut} action = {this.action} APIEndpoints = {this.APIEndpoints} /> : null}
        {page === 'remove' ? <CommonDelete language={language} closeModal={() => this.handlePage('list')} onDelete={this.deleteStock} message = {this.state.values.name} /> : null}
      </CommonPage>
    );
  } 
}