import React from 'react';
import { observer } from 'mobx-react';
import Constants from '@data/Constants';
import { Card } from '@material-ui/core';
import CommonPage from '../../common/CommonPage';
import CommonForm from '../../common/CommonForm2';
import { MaskNumber, MaskTel } from '../../common/Mask';
import { translateFromPtBR } from '@common/languages/Dictionary';

import wwwIcon from '@images/wwwIcon.png';
import neighborhood from '@images/neighborhoodIcon.png';

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: option[attributeName],
  }));

  return normalizedOptions;
};

@observer
export default class CompanyForm extends CommonPage {
  constructor() {
    super();
    this.state = {
      group_tech: {},
      listCompanies: {},
      changeCabOptions: []
    };
    this.loadChangeCabOptions = this.loadChangeCabOptions.bind(this);
  }

  componentWillMount() {
    let { values } = this.props;

    this.loadChangeCabOptions();

    if (values) {

      if (values.fk_id_group_tech) {
        this.action
          .execute(
            'get',
            this.APIEndpoints.GROUP + '/' + values.fk_id_group_tech
          )
          .then((res) => {
            this.setState({
              group_tech: res.data,
            });
          });
      }
    }
  }

  componentDidMount() {
    this.action
      .execute('get', Constants.APIEndpoints.TIMEZONE, '')
      .then((response) => {
        const { data } = response;
        this.setState({ timezoneOptions: formatSelectOptions(data, 'name', 'id_timezone') });
      });

    this.action
      .execute('get', this.APIEndpoints.COMPANY, 'companies')
      .then((item) => {
        let listCompanies = (item.data || []).map((itemc) => ({
          value: itemc.id_company,
          label: itemc.name,
        }));

        this.setState({
          listCompanies: listCompanies,
        });
      });
  }

  loadChangeCabOptions(){
    this.action
      .execute('get', Constants.APIEndpoints.CHANGE_CAB+'/all?type=custom', '')
        .then((response) => {
          this.setState({ changeCabOptions: response.data.map((obj) => ({ value: obj.cc_id, label: `${obj.cc_weight} - ${obj.cc_group_label}` })) });
        }).catch(()=> {
          this.setState({ changeCabOptions: { value: 0, label: this.props.language.SELECT } });
        });
  }

  render() {
    let {
      language,
      values,
      onFormSubmit,
      onChangeField,
      showRelatedWith,
      countries,
      states,
      cities,
      params,
      use_resolution_category
    } = this.props;

    let { timezoneOptions } = this.state;
    let typeOptions = [
      { value: 1, label: language.CLIENT },
      { value: 2, label: language.OUTSOURCED },
    ];

    let listCompanies = this.state.listCompanies;

    let countryOptions = countries.map((obj) => {
      return { value: obj.id_country, label: translateFromPtBR(obj.name, language) };
    });

    let stateOptions = states.map((obj) => {
      return { value: obj.id_state, label: obj.name };
    });

    let cityOptions = cities.map((obj) => {
      return { value: obj.id_city, label: obj.name };
    });

    let fields = [
      {
        col: 3,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        options: typeOptions,
        required: true,
        disabled: !!(values && values.id_company == 1)
      },
      {
        col: 3,
        name: 'name',
        label: language.NAME,
        type: 'text',
        startIcon: 'fas fa-signature',
        required: true,
      },
      {
        col: 3,
        name: 'phone',
        label: language.PHONE,
        type: 'text',
        startIcon: 'fas fa-phone',
        InputProps: {
          inputComponent: MaskTel,
        },
      },
      {
        col: 3,
        name: 'domain',
        label: language.DOMAIN,
        type: 'text',
        startIcon: (
          <img
            src={wwwIcon}
            className="desaturate"
            style={{ width: 21, marginLeft: -2, marginRight: -2 }}
          />
        ),
        required: true,
      },
      {
        col: 3,
        name: 'email',
        label: language.EMAIL,
        type: 'text',
        startIcon: 'fas fa-envelope',
      },

      {
        col: 3,
        name: 'cnpj',
        label: language.TIN,
        type: 'text',
        startIcon: 'far fa-address-card',
        InputProps: {
          id: {
            format: '##.###.###/####-##',
            mask: '_',
            placeholder: '00.000.000/0000-00',
            change(values) {
              return values.formattedValue;
            },
          },
          inputComponent: MaskNumber,
        },
        required: true,
      },
      {
        col: 3,
        name: 'zipcode',
        label: language.POSTAL_CODE,
        type: 'text',
        startIcon: 'fas fa-map-marked-alt',
        InputProps: {
          id: {
            format: '#####-###',
            mask: '_',
            placeholder: '00000-000',
            change(values) {
              return values.formattedValue;
            },
          },
          inputComponent: MaskNumber,
        },
      },
      {
        col: 3,
        name: 'id_country',
        label: language.COUNTRY,
        type: 'select',
        startIcon: 'fas fa-flag',
        options: countryOptions,
        required: true,
      },
      {
        col: 3,
        name: 'id_state',
        startIcon: 'far fa-flag',
        label: language.DISTRICT,
        type: 'select',
        options: stateOptions,
      },
      {
        col: 3,
        name: 'id_city',
        startIcon: 'fas fa-city',
        label: language.CITY,
        type: 'select',
        options: cityOptions,
      },
      {
        col: 3,
        name: 'neighborhood',
        label: language.NEIGHBORHOOD,
        type: 'text',
        startIcon: (
          <img
            src={neighborhood}
            className="desaturate"
            style={{ width: 21 }}
          />
        ),
      },
      {
        col: 3,
        name: 'address',
        label: language.ADDRESS,
        type: 'text',
        startIcon: 'fas fa-map-marker-alt',
      },
      {
        col: 3,
        name: 'id_timezone',
        startIcon: 'fas fa-city',
        label: 'Timezone',
        type: 'select',
        options: timezoneOptions,
        required: true,
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_group_tech',
        className: 'tech-group-ticket',
        required: false,
        filter: { type_search: 1 },
        filterAll: { type_search: 1 },
        method: 'POST',
        route: `${this.APIEndpoints.GROUP}/filter`,
        fieldquery: 'search',
        fieldvaluedb: 'id_group_users',
        fieldlabeldb: 'name',
        useinit: true,
        textinit: values ? values.group_tech : '',
        idinit: values ? parseInt(values.fk_id_group_tech) : 0,
        routeAll: `${this.APIEndpoints.GROUP}/all?type=1`,
        detail: true,
        methodGetAll: 'POST',
        routeGetCount: `${this.APIEndpoints.GROUP}/count`,
        textlabel: language.GROUP_TECH,
      },
      {
        col: 3,
        name: 'sys_id',
        label: 'Sys Id',
        type: 'text',
        required: false,
      },
      {
        col: 3,
        name: 'idp',
        label: 'Identity Provider (IDP)',
        type: 'text',
        required: false,
      },
      {
        col: 6,
        name: 'sso',
        label: language.ENABLE_SSO,
        type: 'switch'
      },
      {
        col: 6,
        name: 'sendmail',
        label: language.ALLOW_SENDING_EMAIL,
        type: 'switch'
      },

      {
        col: 3,
        name: 'use_resolution_category',
        label: language.USE_RESOLUTION_CATEGORY,
        type: 'switch',
        visible : params.use_resolution_category == true ? true : false
      },

      {
        col: 3,
        name: 'resolution_category_level',
        label: language.NUMBERS_OF_LEVELS,
        type: 'numer',
        visible : use_resolution_category == true ? true : false,
        required : use_resolution_category == true ? true : false

      },

      {
        col: 12,
        name: 'relationship_companies',
        label: language.RELATIONSHIP_WITH,
        type: 'select',
        isMulti: true,
        options: listCompanies,
        required: false,
        visible: showRelatedWith
      },

      {
        col: 6,
        type : 'check',
        name : 'use_custom_form_for_rfc_cab',
        label: language.USE_CUSTOM_FORM_FOR_CHANGE_MANAGEMENT_CAB
      },

      {
        col: 3,
        name: 'fk_id_change_cab',
        label: language.WEIGHT_CAB,
        type: 'select',
        startIcon: 'fas fa-flag',
        options: this.state.changeCabOptions,
        visible: values?.use_custom_form_for_rfc_cab ? true : false
      },

      {
        col: 6,
        type : 'check',
        name : 'cab_global',
        label: language.COMPANY_CAB_GLOBAL
      },

      {
         col: 6,
         type : 'check',
         name : 'mail_default',
         label: language.MAIL_DEFAULT
       },
       {
        col: 6,
        type : 'check',
        name : 'enable_catalog_grouping',
        label: language.ENABLE_COMPANY_CATALOG_GROUPING
      },

       {
         col: 12,
         type : 'check',
         name : 'ticket_status_chat',
         label: language.RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_CHAT,
         visible : params.enabled_ticket_change_status
       },
       {
         col: 12,
         type : 'check',
         name : 'ticket_status_attachment',
         label: language.RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_ATTACHMENT,
         visible : params.enabled_ticket_change_status
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_account_manager',
        textlabel: language.ACCOUNT_MANAGER,
        method: 'POST',
        route: `${Constants.APIEndpoints.USER}/filter`,
        routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
        fieldquery: 'search',
        textinit: values?.account_manager,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: '',
        routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
        loadOnFocus: true,
        detail: true,
        methodGetAll: 'POST',
      }, 
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_service_delivery_manager',
        textlabel: language.DELIVERY_MANAGER,
        method: 'POST',
        route: `${Constants.APIEndpoints.USER}/filter`,
        routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
        fieldquery: 'search',
        textinit: values?.service_delivery_manager,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: '',
        routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
        loadOnFocus: true,
        detail: true,
        methodGetAll: 'POST',
        required: true
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_technical_account_manager',
        textlabel: language.TECHNICAL_ACCOUNT_MANAGER,
        method: 'POST',
        route: `${Constants.APIEndpoints.USER}/filter`,
        routeGetCount: `${Constants.APIEndpoints.USER}/active/count/all`,
        fieldquery: 'search',
        textinit: values?.technical_account_manager,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit: '',
        routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
        loadOnFocus: true,
        detail: true,
        methodGetAll: 'POST',
      },
      {
        col: 3,
        name: 'code_pep',
        label: language.CODE_PEP,
        type: 'text',
      },
      {
        col: 3,
        name: 'code_sap',
        label: language.CODE_SAP,
        type: 'text',
      },
      {
        col: 3,
        name: 'ticket_creation_request',
        label: language.ID_TICKET_CREATION_REQUEST,
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'type_client',
        label: language.TYPE_CLIENT,
        type: 'select',
        options: [{ value: 'strategic', label: language.STRATEGIC }, { value: 'normal', label: 'Normal' }, { value: 'representative', label: language.REPRESENTATIVE }],
      },
      {
        col: 3,
        name: 'type_provider',
        label: language.TYPE_PROVIDER,
        type: 'select',
        options: [{ value: 'provider_client', label: language.PROVIDER_CLIENT }, { value: 'provider_tivit', label: language.PROVIDER_TIVIT }, { value: 'na', label: 'N/A' }],
      },
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_business_line',
        textlabel: 'Squad',
        method: 'POST',
        route: `${Constants.APIEndpoints.BUSINESS_LINE}/list?`,
        routeGetCount: `${Constants.APIEndpoints.BUSINESS_LINE}/list?`,
        routeAll: `${Constants.APIEndpoints.BUSINESS_LINE}/list?`,
        fieldquery: 'search',
        textinit: values?.squad,
        fieldvaluedb: 'id_business_line',
        fieldlabeldb: 'label',
        idinit: '',
        loadOnFocus: true,
        detail: true,
        methodGetAll: 'POST',
      },
    ];

    if (!values) {
      values = {};
      values["sendmail"] = true;
    }

    let button = {
      md: 2,
      offset: { md: 10 },
      label: language.SAVE,
      primary: true,
      style: { marginBottom: 10, float: 'right' },
    };

    return (
      <Card className="content" style={{ overflow: 'visible' }}>
        <CommonForm
          values={values}
          loading={[
            'post-/dash/configuration/company',
            'put-/dash/configuration/company',
          ]}
          fields={fields}
          onChangeField={onChangeField}
          onSubmit={onFormSubmit}
          button={button}
        />
      </Card>
    );
  }
}
