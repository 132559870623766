import classNames from 'classnames';
import React from 'react';
import Tag from '@common/Tag';

export default function Flag({ label, flagLabel, flagColor, orientation = 'vertical' }) {

    const classOrientation = orientation === 'vertical' ? 'tw-flex-col tw-gap-0' : 'tw-flex-row tw-gap-1';

    return (
        <div className={classNames('tw-flex tw-items-center', classOrientation)}>
            {label}
            <Tag label={flagLabel} color={flagColor} style={{ fontSize: 'inherit' }} />
        </div>
    )
}
