import React, {Fragment, memo, useEffect, useState} from 'react';
import './index.css';
//--------------------------common----------------------------
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Progress from '@common/Progress';
import Button from '@common/Button';
import Store from '@data/Store';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
//--------------------------material-ui----------------------------
import {Checkbox} from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import moment from 'moment';
const language = Store.language;

export default memo(({
	open,
	onClose,
	onSave,
  action,
  APIEndpoints
})=>{
  const [tickets, setTickets] = useState([]);
	const [checked, setChecked] = useState(0);
	//------- fazer as requisições dos dados abaixo nessa página
  useEffect(() => {
    action.execute('post',APIEndpoints.TICKET + '/ticketFind','tickets',[],null,null,null)
      .then(g => {
        setTickets(g.data);
    	});
  }, []);

	let data = tickets.length > 0 ? tickets.filter(t => t.status === "Open").map(g=>{
		return {
			check: <Checkbox onChange={(v, c)=> setChecked(prev => prev === g.id_tickets ? 0 : g.id_tickets)} checked={checked === g.id_tickets}  />,
			status: (
				<div className='d-flex align-items-center'>
					{g.type_tickets == 4 ? (
	            <Tooltip
	              html={
	            		<span style={{ color: '#fff', fontSize: 15 }}>{'Solicitação'}</span>
	              }
	              position="right"
	              theme="dark"
	            >
	              <i
	                className="fas fa-bullhorn icon my-1"
	                style={{
	                  color: 'var(--primary)',
	                  cursor: 'pointer',
	                  fontSize: 13
	                }}
	              />
	            </Tooltip>
	          ) : g.type_tickets == 2 ? (
	            <Tooltip
	              html={
	                <div style={{ color: '#fff', fontSize: 15 }}>
	                  <strong>{language.PROBLEM}</strong>
	                </div>
	              }
	              position="right"
	              theme="dark"
	            >
	              <ErrorIcon

	                style={{
	                  color: 'var(--danger)',
	                  cursor: 'pointer',
	                  fontSize: 18
	                }}
	              />
	            </Tooltip>
	          ) : (
	            <Tooltip
	              html={
	                <div style={{ color: '#fff', fontSize: 15 }}>
	                  <strong>{language.INCIDENT}</strong>
	                </div>
	              }
	              position="right"
	              theme="dark"
	            >
	              <WarningIcon

	                style={{
	                  color: 'var(--warning)',
	                  cursor: 'pointer',
	                  fontSize: 17
	                }}
	              />
	            </Tooltip>
	          )}
					<div className='ml-3 d-flex align-items-center content-progress-table'>
						<Progress background='var(--warning)' current={g.progress} hiddenText style={{height:7, width:'100%'}}/>
						<span>{g.progress}</span>
					</div>
				</div>
			),
			id:g.real_id ,
			dt:moment(g.dt_cad).format("DD-MM-YY"),
			hour:moment(g.dt_cad).format("HH:mm"),
			subject: g.subject,
			sla:g.sla_task
		}
	}) : []

	return(
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth='lg'
			bar={false}
			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
		>
			<CommonTable
				className='AssociateTicketTable'
				maxHeight={500}
				paginationTop={false}
				afterBar2={
					<Button
						label={language.CLOSE}
						icon='fas fa-times'
						color='#ffffff'
						variant='circle'
						outlined
						style={{
							border:'none',
							color:'#ffffff'
						}}
						onClick={onClose}
					/>
				}
				AppBarProps={{
					style:{
						borderRadius:'10px 10px 0 0'
					}
				}}
				col={[
					{key:'check', label:'#'},
					{key:'status', label:language.STATE},
					{key:'id', label:language.ID},
					{key:'dt', label:language.DATE},
					{key:'hour', label:language.HOUR},
					{key:'subject', label:language.SUBJECT},
					{key:'sla', label:language.SLA},
				]}
				data={data}
				footer={
					<Fragment>
						<hr/>
						<div className='d-flex align-items-center justify-content-end pb-3 pr-3'>
							<Button
								label={language.CANCEL}
								color='danger'
								variant='normal'
								className='mr-3'
								onClick={onClose}
							/>
							<Button
								label={language.SAVE}
								color='success'
								variant='normal'
								onClick={() => onSave({
									value: checked,
									type: 'tickets'
								})}
								disabled={checked === 0}
							/>
						</div>
					</Fragment>
				}
			/>
		</Dialog>
	)
})
