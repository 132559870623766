import RaisedButton from '../../common/RaisedButton';
import Button from '../../common/Button';
import React, { Component, Fragment } from 'react';
import { Chip } from '@material-ui/core';
import { observer } from 'mobx-react';
import CommonTable from '../../common/CommonTable';
import { Constants, Actions, Store } from '../../data';

@observer
export default class FormBuilderList extends Component {
  constructor() {
    super();
    this.action = new Actions();

    this.state = {
      departments: [],
      open: false,
      category: null,
      catalog: null,
      catalog_task: null,
      openNew: false,
      listTask: [],
      categories: [],
    };
  }
  componentWillMount() {
    this.action.execute(
      'get',
      Constants.APIEndpoints.CONTRACT + 'painel',
      'contracts'
    );

    this.action.execute('get', Constants.APIEndpoints.GROUP, 'groups');

    this.action.execute(
      'post',
      Constants.APIEndpoints.USER + '/techcontracts',
      'usercon_company',
      { id_user: Store.getUserLoged().id },
      null,
      null,
      null
    );
  }

  render() {
    let {
      language,
      handlePage,
      redirect,
      tasks,
      impacts,
      priorities,
      urgencies,
      cloneTask,
    } = this.props;

    let col = [
      {
        key: 'id',
        label: language.ID,
        style: { width: 10, textAlign: 'center' },
      },
      { key: 'name', label: language.NAME, style: { textAlign: 'center' } },

      {
        key: 'actions',
        label: language.ACTIONS,
        style: { width: 40, textAlign: 'center' },
        jc: 'center',
      },
    ];

    let data = tasks.map((obj) => {
      return {
        id: obj.id_template_forms,
        name: obj.name,

        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="circle"
                    icon="fas fa-clone"
                    label={language.CLONE2}
                    color="purple"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => cloneTask(obj.id_template_forms)}
                    _loading={'clone-' + obj.id_template_forms}
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => handlePage('edit', obj)}
                  />
                  <RaisedButton
                    circleButton
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13, width: 21, height: 21  }}
                    _loading={'delete-template-item-' + obj.id_template_forms}
                    size={0.7}
                    onClick={() => handlePage('remove', obj)}
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });

    let { configurationProps = {} } = this.props;
    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          index="t_TaskList"
          showHeaderToolbar
          searchColumn
          orderColumn
          paginationTop={false}
          beforeBar2={configurationProps.control}
          beforeBar={
            <Button
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.ADD_FORM}
              primary={true}
              onClick={() => handlePage('new')}
            />
          }
        />
      </Fragment>
    );
  }
}
