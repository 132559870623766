import React from 'react';
import {Avatar} from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppBar from '@common/AppBar2';
import Store from '@data/Store';

const { language } = Store;

export default function({scope, setScope, title}){
	return(
		<div className='Scope wrap pb-1'>
      <AppBar title={language.SCOPE}>
            <i className='fas fa-eye' style={{cursor:'pointer', color:'var(--primary)'}} onClick={() => setScope({title, body:scope})}/>
         </AppBar>
			    <PerfectScrollbar
              className="scrollVisible -mt-2 px-3"
              style={{
                maxHeight: 110,
              }}
              option={{
                suppressScrollX:true
              }}
          >
            <p 
              className='text pl-1'
              dangerouslySetInnerHTML={{ __html: scope }}
            />
          </PerfectScrollbar>
		</div>
	)
}