import RaisedButton from '@common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '@common/CommonForm2';
import CommonForm2 from '@common/CommonForm2';
import Button from '@common/Button';
import Dialog from '@common/Dialog';
import { FaCircle, FaCaretDown } from 'react-icons/lib/fa';
import {
  Card,
  Paper,
  TextField,
  List,
  ListItem,
  Grid
} from '@material-ui/core';
import AppBar from '@common/AppBar';
import Progress from '@common/Progress';
import './index.css';
import Pickers from '@common/Pickers';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import PageLayout from '@common/PageLayout';
import Help from '@common/components/Help';
import { FaQuestionCircle, FaClose } from 'react-icons/lib/fa';
import $ from 'jquery';
import '@bClass';
import {
  FaSlideshare,
  FaExchange,
  FaAngleDoubleRight,
  FaStopCircle,
  FaThumbsOUp
} from 'react-icons/lib/fa';
import { Draggable, Droppable } from 'react-drag-and-drop';
import ReactGantt, { GanttRow } from 'react-gantt';

import Dropzone from 'react-dropzone';
import ProjectManagementDetail from '../ProjectManagementDetail';
import moment from 'moment';
import { Chip, Avatar } from '@material-ui/core';
import { Tooltip } from 'react-tippy';
import ContainerChart from '../ProjectManagementPage.graph.js';
import { Chart } from 'react-google-charts';
import classnames from 'classnames';
import CommonPage from '@common/CommonPage';
import Project from '../Project';
import GridCards from './GridCards';
import CardItem from './CardItem';
import DialogDetail from './DialogDetail';
import DialogDetail_ from './DialogDetail_';
import DialogForm from './DialogForm';
import Gantt from '@common/Gantt';
import BarRight from './BarRight';
const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' }
  }
});
@observer
export default class ProjectManagementPage extends CommonPage {
  constructor() {
    super();
    this.state = {
      openItem: true,
      openModalAc: false,
      index: null,
      ids: null,
      idprojectM: null,
      modalstatus: true,
      search: null,
      searchitem: null,
      openToolTip: false,
      searchActivity: '',
      atividade: null,
      modalDetail: { open: false, values: null, typeFilter: '' },
      date: {
        ini: new Date().setMonth(0, 1),
        end: new Date().setMonth(11, 30)
      },

      dialogIndex: 0,
      editTag: false,
      activity: [],
      removeModal: false,
      removeitem: null
    };

    this.handleIndex = this.handleIndex.bind(this);
    this.handleModalGrant = this.handleModalGrant.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addActivity = this.addActivity.bind(this);
    this.handleGant = this.handleGant.bind(this);
    this.handleOrderBy = this.handleOrderBy.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.handleChangeActivity = this.handleChangeActivity.bind(this);
    this.onDropitem = this.onDropitem.bind(this);
    this.onDrop_ = this.onDrop_.bind(this);
    this.searchProject = this.searchProject.bind(this);
    this.loadPmo = this.loadPmo.bind(this);
    this.removeActivity = this.removeActivity.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.removeTag = this.removeTag.bind(this);
  }
  removeActivity(values, idproject) {
    this.action
      .execute(
        'get',
        this.APIEndpoints.PROJECT +
        '/deleteactivity/' +
        values.id_project_activity,
        ''
      )
      .then(e => {
        this.action.execute(
          'post',
          this.APIEndpoints.PROJECT + '/projectlist',
          'pmo',
          { idproject: idproject }
        );
      });
  }

  changeDate = (type, value) => {
    this.setState({ modal: false, typeFilter: '' });
    var date = this.state.date;
    date[type] = value;

    this.setState({ date });
  };
  handleOrderBy(type) {
    this.setState({ typeFilter: type });
  }
  handleDialog = (dialogIndex = 0, atividade, last) => {
    this.setState({ dialogIndex, activity: atividade });
  };
  componentWillMount() {
    if (this.store.pmo == undefined) {
    }
  }
  handleIndex(index) {
    this.setState({
      index: this.state.index === index ? '' : index
    });
  }
  handleGant() {
    let item = !this.state.openToolTip;

    this.setState({
      openToolTip: item
    });
  }

  handleModalNew() {
    this.setState({ openItem: false });
  }
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  handleChangeActivity = event => {
    const {
      target: { name, value }
    } = event;

    this.setState({ searchActivity: value, typeFilter: 'text' });
  };

  handleChange = event => {
    const {
      target: { name, value }
    } = event;
    this.setState({ search: value, searchitem: name });
  };

  handleModalGrant(id, idproject) {
    if (id === 'closeModalStatus') {
      this.props.handlePage('dash', idproject);
    } else {
      this.setState({ openModalAc: true, idprojectM: id });
    }
  }

  handleModalClose() {
    this.setState({ modalstatus: false });

    this.setState({ openModalAc: false, idprojectM: undefined });
  }

  addActivity() {
    this.props.handleNext(this.props.listmilestoneActivity[0].id_project);
  }
  updateTags(v) {

    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/updatetag',
      'pmos',
      v
    );


  }
  removeTag() {
    let i = this.state.removeitem;
    i.deleted = true
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/updatetag',
      'pmos',
      i
    ).then(h => {
      this.loadPmo()
    })
    this.setState({ removeModal: false })


  }
  onFormSubmitAcitivityDesc(valueOut) { }

  onDropitem(e, type, id_project, gestor) {
    let ac = JSON.parse(e.milestone);
    if (ac.usersid.includes(this.store.getUserLoged().id) ||
      parseInt(this.store.getUserLoged().id) == parseInt(gestor)
      || this.store.getUserLoged().role == "client"
    ) {



      if (parseInt(ac.percent) < 100)
        this.props.onDrop(ac.id_project_activity, type, id_project);

    } else {
      this.store.toggleAlert(true, `Usuário não faz parte da atividade 
     , contate o gestor do projeto`, 'error')

    }





  }
  searchProject(values) {
    /**{user: 30, search: "ee", dtini: Wed Apr 01 2020 12:30:00 GMT-0400
     * (Horário Padrão do Amazonas), dtend: Sat Apr 25 2020 12:30:00 GMT-0400
     * (Horário Padrão do Amazonas)} */


  }
  onDrop_(e, type, id_project, gestor) {
    let ac = JSON.parse(e.milestone);


    if (ac.usersid.includes(this.store.getUserLoged().id) ||
      parseInt(this.store.getUserLoged().id) == parseInt(gestor)) {
      if (parseInt(ac.percent) < 100) {
        this.setState({ activity: ac, dialogIndex: 1 });
      } else {
        this.props.onDrop(ac.id_project_activity, type, id_project);
      }
    } else {
      this.store.toggleAlert(true, `Usuário não faz parte da atividade 
    , contate o gestor do projeto`, 'error')
    }
  }

  filter(project, user, data, filter) {
    if (user)
      ['TODO', 'DONE', 'DOING', 'STOPPED'].map(h => {
        project.statusproject[h] = project.statusproject[h].filter(g =>
          g.users.includes(user)
        );
      });

    if (data) {
      ['TODO', 'DONE', 'DOING', 'STOPPED'].map(h => {
        project.statusproject[h] = project.statusproject[h].filter(
          g => g.name.indexOf(filter) > -1
        );
      });
    }
    if (filter) {
      ['TODO', 'DONE', 'DOING', 'STOPPED'].map(h => {
        project.statusproject[h] = project.statusproject[h].filter(
          g =>
            data.inicial >= moment(g.dt_start).format('YYMMDD') &&
            data.final <= moment(g.dt_end).format('YYMMDD')
        );
      });
    }

    return project;
  }
  replaceSpecialChars(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
    str = str.replace(/[àáâãäå]/, 'a');
    str = str.replace(/[ÈÉÊË]/, 'E');
    str = str.replace(/[Ç]/, 'C');
    str = str.replace(/[ç]/, 'c');
    return str.replace(/[^a-z0-9]/gi, '');
  }

  loadPmo() {
    this.action.execute(
      'post',
      this.APIEndpoints.PROJECT + '/projectlist',
      'pmo'
    );
  }
  gant(DONE, DOING, STOPPED, TODO) {
    let colors = {
      TODO: '#009dc0',
      DOING: '#efa824',
      STOPPED: '#f44336',
      DONE: '#33aba0'
    };
    let atividades = [];
    let arrayGroup = [];
    DONE.forEach(h => {
      atividades.push(h);
    });
    DOING.forEach(h => {
      atividades.push(h);
    });
    STOPPED.forEach(h => {
      atividades.push(h);
    });
    TODO.forEach(h => {
      atividades.push(h);
    });

    atividades
      .filter(y => y.tagac != null)
      .forEach(a => {
        let item = a.tagac && a.tagac.map(a => a.name).join(',');

        if (arrayGroup.filter(g => g.tags_ == item).length == 0) {
          arrayGroup.push({
            tags_: item,
            tags: a.tagac.map(t => {
              return {
                id: t.id_project_tag,
                color: t.color,
                label: t.name
              };
            }),
            activities: [
              {
                label: a.name,
                dt_i: a.dt_start,
                dt_f: a.dt_end,
                color: colors[a.stage],
                tags: a.tagac.map(a => a.id_project_tag)
              }
            ],
            avatares: a.users
          });
        } else {
          let obj = arrayGroup.filter(h => h.tags_ == item)[0];
          obj.activities = [
            ...obj.activities,
            {
              label: a.name,
              dt_i: a.dt_start,
              dt_f: a.dt_end,
              color: colors[a.stage],
              tags: a.tagac.map(a => a.id_project_tag)
            }
          ];

          let index = arrayGroup.findIndex(x => x.tags === item);

          arrayGroup[index] = obj;
        }
      });

    return arrayGroup;
  }

  render() {
    let {
      language,
      onFormSubmitAcitivityDesc,
      onFormSubmitComments,
      values,
      users,
      valueOut,
      onDrop,
      open,
      pmo,
      ondonwFile,
      projectSelect,
      saveActivity,
      ondeletefile,
      UpdateFiles,
      onFormSubmitActivityStatus,
      subActivities
    } = this.props;
    let { openItem, search, dialogIndex } = this.state;

    let fields = [
      {
        span: 12,
        name: 'observacao',
        label: language.COMPL_INFO,
        type: 'textEditor',
        required: true
      }
    ];

    let button = {
      md: 12,
      label: language.SAVE,
      primary: true,
      style: { marginTop: 5 }
    };


    projectSelect = this.store.pmo.projetos.find(
      g => parseInt(g.id_project) == parseInt(values)
    );

    if (!projectSelect) {
      this.props.redirect('/dash/gp');
      return null;
    }

    let DONE = projectSelect.statusproject.DONE;
    let DOING = projectSelect.statusproject.DOING;
    let STOPPED = projectSelect.statusproject.STOPPED;
    let TODO = projectSelect.statusproject.TODO;

    if (search) {

      if (search['user']) {
        DONE = DONE.filter(g => g.users.includes(search['user']));
        DOING = DOING.filter(g => g.users.includes(search['user']));
        STOPPED = STOPPED.filter(g => g.users.includes(search['user']));
        TODO = TODO.filter(g => g.users.includes(search['user']));
      }
      if (search['type']) {

        DONE = DONE.filter(g => g.local.includes(search['type']));
        DOING = DOING.filter(g => g.local.includes(search['type']));
        STOPPED = STOPPED.filter(g => g.local.includes(search['type']));
        TODO = TODO.filter(g => g.local.includes(search['type']));
      }


      if (search['dtini'] && search['dtend']) {
        DONE = DONE.filter(
          g =>
            moment(search['dtini']).format('YYMMDD') >=
            moment(g.dt_start).format('YYMMDD') &&
            moment(search['dtend']).format('YYMMDD') <=
            moment(g.dt_end).format('YYMMDD')
        );
        DOING = DOING.filter(
          g =>
            moment(search['dtini']).format('YYMMDD') >=
            moment(g.dt_start).format('YYMMDD') &&
            moment(search['dtend']).format('YYMMDD') <=
            moment(g.dt_end).format('YYMMDD')
        );
        STOPPED = STOPPED.filter(
          g =>
            moment(search['dtini']).format('YYMMDD') >=
            moment(g.dt_start).format('YYMMDD') &&
            moment(search['dtend']).format('YYMMDD') <=
            moment(g.dt_end).format('YYMMDD')
        );
        TODO = TODO.filter(
          g =>
            moment(search['dtini']).format('YYMMDD') >=
            moment(g.dt_start).format('YYMMDD') &&
            moment(search['dtend']).format('YYMMDD') <=
            moment(g.dt_end).format('YYMMDD')
        );
      }

      if (search['search']) {
        //console.log('TODO', TODO, search['search']);
        DONE = DONE.filter(
          g => this.replaceSpecialChars(g.name.toLowerCase()).indexOf(search['search'].toLowerCase()) > -1 || g.id_project_activity == search['search']
        );
        DOING = DOING.filter(
          g => this.replaceSpecialChars(g.name.toLowerCase()).indexOf(search['search'].toLowerCase()) > -1 || g.id_project_activity == search['search']
        );
        STOPPED = STOPPED.filter(
          g => this.replaceSpecialChars(g.name.toLowerCase()).indexOf(search['search'].toLowerCase()) > -1 || g.id_project_activity == search['search']
        );
        TODO = TODO.filter(
          g => this.replaceSpecialChars(g.name.toLowerCase()).indexOf(search['search'].toLowerCase()) > -1 || g.id_project_activity == search['search']
        );
      }
    }

    let valuesGant = this.gant(DONE, DOING, STOPPED, TODO);

    let usersEnvolved = [];

    users
      .filter(
        u => projectSelect.users && projectSelect.users.includes(u.id_user)
      )
      .map(p => {
        usersEnvolved.push({ value: p.name, label: p.name });
      });

    /*--------------------------------esse trexo é um lorem pra exibr o gant, remover depois------------------------------------------------*/
    var enumerateDaysBetweenDates = function (startDate, endDate) {
      startDate = moment(new Date(startDate));
      endDate = moment(new Date(endDate));
      var now = startDate.clone(),
        dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(new Date(now));
        now.add(1, 'days');
      }
      return dates;
    };

    var rangeDate = enumerateDaysBetweenDates(
      moment().add(-4, 'days'),
      moment().add(23, 'days')
    );
    /*----------------------------------------------------------------------------------------------------------------------------------------*/
    let gestor = "";
    try {
      gestor = users && users.filter(j => parseInt(j.id_user) == parseInt(projectSelect.user_cad))[0].name
    } catch (t) {

    }
    return (
      <PageLayout
        icon={
          <div
            className="projectManIcon"
            style={{ width: '40px', height: '40px', margin: 0, left: 6 }}
          />
        }
        subTitle={language.PROJECT_MANAGEMENT + '/'}
        title={language.PROJECTS}
        back
        subHeader={{
          title: ''
        }}
        rightElements={[
          <div style={{ width: 900 }} className="mr-4">
            <CommonForm
              individual
              spacing={2}
              onSubmit={e => this.setState({ search: e })}
              hiddenSubmit
              gridProps={{
                alignItems: 'center'
              }}
              fields={[
                {
                  col: 2,
                  type: 'select',
                  name: 'type',
                  label: 'Tipo',
                  options: [
                    { value: 0, label: 'Todas' },
                    { value: 1, label: 'Presencial' }, { value: 2, label: 'Remoto' }]
                },
                {
                  col: 2,
                  type: 'select',
                  name: 'user',
                  label: 'Atividade por usuário',
                  options: usersEnvolved
                },
                {
                  col: 2,
                  name: 'search',
                  placeholder: language.SEARCH + '...',
                  type: 'text',
                  className: 'darks',
                  startIcon: 'fas fa-search'
                },
                {
                  col: 2,
                  type: 'date',
                  name: 'dtini',
                  label: 'Inicial'
                },
                {
                  col: 2,
                  type: 'date',
                  name: 'dtend',
                  label: 'Final'
                },

                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-search"
                      variant="circle"
                      color="success"
                      onClick={() => submit()}
                    />
                  )
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-sync-alt"
                      variant="circle"
                      color="success"
                      onClick={() =>
                        this.redirect('/dash/gp/list/detail', {
                          id_project: projectSelect.id_project,
                          projectSelect: projectSelect
                        })
                      }
                    />
                  )
                },
                {
                  col: 0.5,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      icon="fas fa-arrow-left"
                      variant="circle"
                      color="success"
                      onClick={() => this.redirect('/dash/gp/list')}
                    />
                  )
                }
              ]}
            />
          </div>
        ]}
      >
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: '5px' }}
        >
          <i
            class="fas fa-chart-pie"
            style={{ color: 'rgb(239, 168, 36)' }}
          ></i>
          <h5
            style={{ marginLeft: '4px' }}
            className="titleProject_"  >
            {projectSelect.id_project + '-' + projectSelect.name}
          </h5>

          <i
            class="fas fa-user-cog"
            style={{ color: 'rgb(239, 168, 36)', marginLeft: '30px' }}
          ></i>
          <h5 className="titleProject_"
            style={{ marginLeft: '4px' }}
          >
            {language.MANAGE + ' - ' + gestor}
          </h5>


          <i class="fas fa-comment"
            onClick={() => this.setState({ editTag: true })}
            style={{ color: 'rgb(239, 168, 36)', marginLeft: '30px', cursor: 'pointer' }}
          ></i>
          <h5 className="titleProject_"
            onClick={() => this.setState({ editTag: true })}
            style={{ marginLeft: '4px', cursor: 'pointer' }}
          > {'Tags'}
          </h5>


        </div>
        <Dialog
          open={open === true && openItem === true}
          onClose={() => this.props.closeOndrop()}
          title="Informe o motivo"
        >
          <div className="scroll">
            <CommonForm
              values={valueOut}
              language={language}
              fields={fields}
              onFormSubmit={onFormSubmitAcitivityDesc}
              button={button}
            />
          </div>
        </Dialog>
        <BarRight
          action={this.action}
          APIEndpoints={this.APIEndpoints}
          idproject={projectSelect.id_project} />
        <div id="ProjectManagementPage" className='pr-5'>
          <Gantt
            group={valuesGant}
            startDate={projectSelect.start_date}
            endDate={projectSelect.end_date}
          />



          <Grid container spacing={16} className='mt-2'>
            <Grid item xs={3}>
              <GridCards
                onDrop={e => this.onDropitem(e, 'TODO', projectSelect.id_project, projectSelect.user_cad)}
                language={language}
                title='A fazer'
                index={0}
                onAdd={() => this.handleDialog(2)}
                toggleAlert={this.store.toggleAlert}
                gestor={projectSelect.user_cad}
                end_date={projectSelect.end_date}
                id_user_log={this.store.getUserLoged().id}
                language={language}

                handleDialog={this.handleDialog}
                removeActivity={this.removeActivity}
                data={TODO}
              />
            </Grid>
            <Grid item xs={3}>
              <GridCards
                onDrop={e => this.onDropitem(e, 'DOING', projectSelect.id_project, projectSelect.user_cad)}
                language={language}
                title='Fazendo'
                index={1}

                onAdd={() => this.handleDialog(2)}
                toggleAlert={this.store.toggleAlert}
                gestor={projectSelect.user_cad}
                id_user_log={this.store.getUserLoged().id}
                language={language}

                handleDialog={this.handleDialog}
                removeActivity={this.removeActivity}
                data={DOING}
              />
            </Grid>
            <Grid item xs={3}>
              <GridCards
                onDrop={e => this.onDropitem(e, 'STOPPED', projectSelect.id_project, projectSelect.user_cad)}
                language={language}
                title='Impeditivo'
                index={2}

                onAdd={() => this.handleDialog(2)}
                toggleAlert={this.store.toggleAlert}
                gestor={projectSelect.user_cad}
                id_user_log={this.store.getUserLoged().id}
                language={language}

                handleDialog={this.handleDialog}
                removeActivity={this.removeActivity}
                data={STOPPED}
              />
            </Grid>
            <Grid item xs={3}>
              <GridCards
                onDrop={e => this.onDrop_(e, 'DONE', projectSelect.id_project, projectSelect.user_cad)}
                language={language}
                title='Finalizado'
                index={3}

                onAdd={() => this.handleDialog(2)}
                toggleAlert={this.store.toggleAlert}
                gestor={projectSelect.user_cad}
                id_user_log={this.store.getUserLoged().id}
                language={language}

                handleDialog={this.handleDialog}
                removeActivity={this.removeActivity}
                data={DONE}
              />
            </Grid>
          </Grid>

        </div>

        {this.state.activity && dialogIndex == 1 && (
          <DialogDetail
            toggleAlert={this.store.toggleAlert}
            subActivities={subActivities}
            users={users}
            language={language}
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            ondonwFile={ondonwFile}
            UpdateFiles={UpdateFiles}
            onFormSubmitActivityStatus={onFormSubmitActivityStatus}
            project={projectSelect}
            onFormSubmitComments={onFormSubmitComments}
            ondeletefile={ondeletefile}
            open={dialogIndex == 1}
            onClose={() => this.handleDialog()}
            activity={this.state.activity}
          />
        )}
        {this.state.activity && dialogIndex == 3 && (
          <DialogDetail_
            toggleAlert={this.store.toggleAlert}
            subActivities={subActivities}
            users={users}
            language={language}
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            ondonwFile={ondonwFile}
            UpdateFiles={UpdateFiles}
            onFormSubmitActivityStatus={onFormSubmitActivityStatus}
            project={projectSelect}
            onFormSubmitComments={onFormSubmitComments}
            ondeletefile={ondeletefile}
            open={dialogIndex == 3}
            onClose={() => this.handleDialog()}
            activity={this.state.activity}
          />
        )}
        {this.state.editTag && (
          <Dialog
            open={true}
            onClose={() => {

              this.loadPmo();
              this.setState({ editTag: false });


            }}
            title="Tags"
          >

            {projectSelect.tagpj && projectSelect.tagpj.map(g => (
              <CommonForm
                onSubmit={(v) => this.updateTags(v)}
                hiddenSubmit
                values={{ name: g.name, color: g.color, id_project_tag: g.id_project_tag }}
                fields={[
                  {
                    col: 6,
                    type: 'text',
                    name: 'name',
                    label: 'Tag',
                    required: true
                  },
                  {
                    col: 2,
                    type: 'select',
                    name: 'color',
                    label: 'Cor',
                    required: true,
                    sortOptions: false,
                    options: [
                      { value: '#9E24F0', label: <div style={{ backgroundColor: '#9E24F0', width: 20, height: 20 }}></div> },
                      { value: '#F00D82', label: <div style={{ backgroundColor: '#F00D82', width: 20, height: 20 }}></div> },
                      { value: '#F05E02', label: <div style={{ backgroundColor: '#F05E02', width: 20, height: 20 }}></div> },
                      { value: '#F0B002', label: <div style={{ backgroundColor: '#F0B002', width: 20, height: 20 }}></div> },
                      { value: '#92D050', label: <div style={{ backgroundColor: '#92D050', width: 20, height: 20 }}></div> },
                      { value: '#02F0DE', label: <div style={{ backgroundColor: '#02F0DE', width: 20, height: 20 }}></div> },
                      { value: '#02CBF0', label: <div style={{ backgroundColor: '#02CBF0', width: 20, height: 20 }}></div> },
                      { value: '#00008B', label: <div style={{ backgroundColor: '#00008B', width: 20, height: 20 }}></div> },
                      { value: '#708090', label: <div style={{ backgroundColor: '#708090', width: 20, height: 20 }}></div> },
                      { value: '#2F4F4F', label: <div style={{ backgroundColor: '#2F4F4F', width: 20, height: 20 }}></div> },
                      { value: '#8B4513', label: <div style={{ backgroundColor: '#8B4513', width: 20, height: 20 }}></div> },
                      { value: '#8B0000', label: <div style={{ backgroundColor: '#8B0000', width: 20, height: 20 }}></div> },
                      { value: '#D8BFD8', label: <div style={{ backgroundColor: '#D8BFD8', width: 20, height: 20 }}></div> },
                      { value: '#4682B4', label: <div style={{ backgroundColor: '#4682B4', width: 20, height: 20 }}></div> }
                    ]
                  },

                  {
                    col: 1,
                    type: 'element',
                    content: (v, h, submit) => (
                      <Button
                        style={{
                          marginTop: '15px', marginLeft: '-10px'
                        }}
                        icon='fas fa-edit'
                        color='success'
                        variant='circle'
                        onClick={(v) => submit(v)}
                      />

                    )
                  },
                  {
                    col: 1,
                    type: 'element',
                    content: (v, h, submit) => (
                      <Button
                        style={{
                          marginTop: '15px', marginLeft: '-15px'
                        }}
                        icon='fas fa-trash'
                        color='danger'
                        variant='circle'
                        onClick={() => this.setState({
                          removeitem: { deleted: true, id_project_tag: g.id_project_tag, name: g.name },
                          removeModal: true
                        })}

                      />

                    )
                  },


                ]} >

              </CommonForm>
            ))}
          </Dialog>)}

        {this.state.removeModal && (
          <Dialog
            open={true}
            onClose={() => this.setState({ removeModal: false })}
            title="Excluir Item"
          >
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: '5px' }}
            >
              <h3>{this.state.removeitem.name}</h3>
              <Button
                style={{ marginLeft: '10px' }}
                icon='fas fa-trash'
                color='danger'
                variant='circle'
                onClick={() => this.removeTag()}
              />
            </div>



          </Dialog>
        )}



        {dialogIndex == 2 ?
          <DialogForm
            toggleAlert={this.store.toggleAlert}
            open={dialogIndex == 2}
            saveActivity={saveActivity}
            activity={this.state.activity}
            users={users}
            project={projectSelect}
            onClose={() => this.handleDialog()}
          /> : null}

      </PageLayout>
    );
  }
}
