import React from 'react';
import {Chip} from '@material-ui/core';
import './Tag.css';

/*export default ({label, style={}, color, ...other}) => (
	<span 
		{...other}
		style={{
			backgroundColor: color||'#02CBF0',
		    fontSize: 8,
		    color: 'white',
		    borderRadius: 6,
		    padding: '1px 12px',
		    margin: '5px 0.25rem',
		    ...style
		}} 
	>
		{label}
	</span>
)*/

export default ({className='', style={}, color, ...other}) => (
	<Chip
	    className={'tag-chip '+className}
	    style={{
	    	backgroundColor:color||'var(--primary)',
    		height: 15,
    	    fontSize: 8,
    	    color: 'white',
	    	...style
	    }}
	    {...other}
	/>
)