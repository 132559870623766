import React, {Fragment, useState, memo} from 'react';
import './index.css';
//--------------------------common---------------------------- 
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
import Progress from '@common/Progress';
import Button from '@common/Button';
//--------------------------material-ui---------------------------- 
import {Checkbox} from '@material-ui/core';
//--------------------------pages---------------------------- 
import TableListProjects from './TableListProjects';
import TableListActivities from './TableListActivities';

export default memo(({
	open,
	onClose,
	itens,
	addItem,
	users,
	isBacklog,
	setIsBacklog
})=>{
	
	let [viewProjectsId, setViewProjectsId] = useState(0);
	
	return(
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth='lg'
			bar={false}
			PaperProps={{
			  style:{
			  	backgroundColor:'transparent',
			  	boxShadow:'none'
			  }
			}}
			contentProps={{
				style:{
					background:'transparent',
					border:'none'
				}
			}}
		>
			{isBacklog?(
				itens&&itens.actitivies!= undefined?<TableListActivities
					addItem={addItem}
					itens={itens} 
					isBacklog={isBacklog}
					viewProjectsId={viewProjectsId} 
					onClose={onClose} 
					setViewProjectsId={setViewProjectsId}
					setIsBacklog={setIsBacklog}
				/>:<div>Carregando</div>
			):(
				<Fragment>
					{!viewProjectsId?(
						<TableListProjects 
							itens={itens} 
							onClose={onClose} 
							setViewProjectsId={setViewProjectsId}
						/>
					):(
						itens&&itens.actitivies!= undefined?<TableListActivities
						addItem={addItem}
						itens={itens} viewProjectsId={viewProjectsId} onClose={onClose} setViewProjectsId={setViewProjectsId}/>:
						<div>Carregando</div>
					)}
				</Fragment>
			)}
		</Dialog>
	)
})