import React, { Component } from 'react';
import { Card, Avatar, Chip } from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import moment from 'moment';
import Progress from '@common/Progress';
import { Draggable, Droppable } from 'react-drag-and-drop';
import Loading from '@common/Loading';
export default class Project extends Component{
	checkDeadline = (dtend, r1, r2)=>{
	  return moment(dtend).isAfter(moment())?r1:r2;
	}
	render(){
		let {
			name, 
			dt_end, 
			sponsor, 
			dt_start,
			color, 
			gpname, 
			percent, 
			id_project_milestone, 
			visibleStatus,
			loadingOpenModal,
			photo,
			toggleModalDetail=(a,b)=>null
		} = this.props;

		return(
			<Draggable type="milestone" data={id_project_milestone}>
			    <Card
			        style={{ borderLeft: '3px solid '+color, height:'auto', position:'relative' }}
			        className="cardGpCard  pointer"
			        onClick={() => toggleModalDetail(true, id_project_milestone)}
			    >
			    	{loadingOpenModal==id_project_milestone &&
				    	<div style={{
				    		width: 20,
			    		    height: 20,
			    		    position: 'absolute',
			    		    top: 1,
			    		    left: 5
				    	}}>
				    		<Loading size={20} thickness={6} loading={loadingOpenModal==id_project_milestone} msg={false} style={{padding:0}}/>
				    	</div>
			    	}
			    	{visibleStatus &&
			    	  <Tooltip
			    	    html={
			    	      <div style={{ color: '#fff', fontSize: 15 }}>
			    	        <strong>{'Status: '+this.checkDeadline(dt_end, 'No Prazo', 'Atrasado')}</strong>
			    	      </div>
			    	    }
			    	    position="right"
			    	    theme="dark"
			    	    
			    	    style={{
			    	      position:'absolute',
			    	      right:0,
			    	      top:0
			    	    }}
			    	  >
			    	    <div 
			    	      className='indicator-status-projects' 
			    	      style={{
			    	        width: 40,
			    	        height: 40,
			    	        backgroundColor: this.checkDeadline(dt_end, 'var(--success)', 'var(--danger)'),
			    	        position: 'absolute',
			    	        top: -19,
			    	        right: -19,
			    	        borderRadius: '50%',
			    	      }}
			    	    />
			    	  </Tooltip>
			    	}
			        <Row>
			          <Col md={12}>
			            <Row>
			              <Col md={12} className="font" style={{color:'#616161', fontSize:16, marginBottom:10}}>
			                <Tooltip
			                  html={
			                    <div style={{ color: '#fff', fontSize: 15 }}>
			                      <strong>{name}</strong>
			                    </div>
			                  }
			                  position="left"
			                  theme="dark"
			                >
			                  <p className="ellipsis" style={{color:'#616161', fontSize:15}}>{name}</p>
			                </Tooltip>
			              </Col>
			            </Row>
			            <Row>
			              <Col md={6}>
			                <Tooltip
			                  html={
			                    <div style={{ color: '#fff', fontSize: 15 }}>
			                      <strong>{sponsor}</strong>
			                    </div>
			                  }
			                  position="left"
			                  theme="dark"
			                >
			                  <Chip
			                    style={{cursor:'pointer', margin:'5px 0', maxWidth:'100%'}}
			                    className="chip"
			                    
			                    avatar={<Avatar
			                       size={32}
			                       style={{backgroundColor:'white', border:'2px solid '+color}}
			                       className="d-flex align-items-center justify-content-center"
			                       src={photo}
			                        
			                      >
			                         <div
			                          className="imgUserProject"
			                          style={{ width: 20, height: 20 }}
			                        />
			                      </Avatar>
			                    }
			                    label={<div className="ellipsis">{sponsor}</div>}
			                  />
			                </Tooltip>
			              </Col>
			              <Col md={6}>
			               
			                <Chip
			                  style={{cursor:'pointer', margin:'5px 0'}}
			                  
			                  avatar={<Avatar
			                     size={32}
			                     style={{
			                        backgroundColor:'white', 
			                        border:'2px solid '+color
			                      }}
			                     className="d-flex align-items-center justify-content-center"
			                      
			                    >
			                      <div
			                        className="imgDateProject"
			                        style={{ width: '95%', height: '95%' }}
			                      />
			                    </Avatar>
			                  }
			                  label={moment(dt_start).format('L')}
			                />

			              </Col>
			              
			            </Row>
			            <Row>
			              
						    <Col md={6}>
			               
			                <Chip
			                  style={{cursor:'pointer', marginTop:5}}
			                  
			                  avatar={<Avatar
			                     size={32}
			                     style={{
			                        backgroundColor:'white', 
			                        border:'2px solid '+color
			                      }}
			                     className="d-flex align-items-center justify-content-center"
			                      
			                    >
			                      <div
			                        className="imgDateProject"
			                        style={{ width: '95%', height: '95%' }}
			                      />
			                    </Avatar>
			                  }
			                  label={moment(dt_end).format('L')}
			                />

			              </Col>
			              <Col md={6} className='d-flex align-items-center justify-content-end'>
			              	
			                <Tooltip
			                  html={
			                    <div style={{ color: '#fff', fontSize: 15 }}>
			                      <strong>{gpname}</strong>
			                    </div>
			                  }
			                  position="left"
			                  theme="dark"
			                >
			                  <Chip
			                    className="chip"
			                    style={{cursor:'pointer', margin:'5px 0', maxWidth:'100%', borderColor:color}}
			                    variant="outlined"
								label={<div className="ellipsis">{gpname.length >15 ?
									gpname.substr(0,15):gpname }</div>}
			                  />
			                </Tooltip>
			              	
			              </Col>
			              <Col md={12}>
			                <Progress 
			                  style={{marginTop:10}}
			                  current={percent} 
			                  background={
			                    percent > 66
			                      ? 'var(--success)'
			                      : percent > 33
			                      ? 'var(--warning)'
			                      : 'var(--danger)'
			                  }
			                />
			                
			              </Col>
			            </Row>{' '}
			          </Col>
			        </Row>
			    </Card>
		    </Draggable>
		)
	}
}