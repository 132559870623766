import React, { useState } from 'react';
import CommonForm from '../../common/CommonForm2';
import { AutomationActionForm, fieldsRequiredActionForm } from './AutomationActionForm';
import AutomationLogicalConditionsForm from './AutomationLogicalConditionsForm';

export default function AutomationFormMain({
  values,
  setValue,
  onSubmit,
  options,
  execute,
  spinner,
  toggleAlert,
  language,
  contracts,
}) {
  
  let [formReference, setFormReference] = useState(null);

  return (
    <CommonForm
      values={values}
      sensitive
      onChangeField={(f, v) => {
        if (f && f.name === 'rule') {
          setValue('rule', v);
        }
      }}
      childFields={
        values.type_category
          ? fieldsRequiredActionForm[values.type_category]
          : fieldsRequiredActionForm['default']
      }
      fields={[
        {
          col: 6,
          type: 'select',
          name: 'rule',
          label: language.RULE,
          required: true,
          options: options.rules(),
        },
        {
          col: 6,
          type: 'text',
          name: 'description',
          label: language.DESCRIPTION,
          required: true,
        },
        {
          type: 'element',
          name: 'actions_automation',
          content: (value, handleValue) => (
            <>
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginBottom: '16px',
                }}
              >
                {language.LOGICAL_CONDITIONS}
              </div>
              <AutomationLogicalConditionsForm
                language={language}
                options={options}
                handleValue={handleValue}
                actionsAutomation={values.actions_automation}
                setValue={setValue}
                execute={execute}
                spinner={spinner}
                toggleAlert={toggleAlert}
              />
            </>
          ),
        },
        {
          type: 'element',
          content: (value, handleValue) => {
            if (
              values.rule === 'mail' &&
              values.type_category === 'categorize'
            ) {
              return (
                <AutomationActionForm.Categorize
                  language={language}
                  values={values}
                  options={options}
                  contracts={contracts}
                  setFormReference={setFormReference}
                  handleValue={handleValue}
                  setValue={setValue}
                />
              );
            } else if (
              values.rule === 'mail' &&
              values.type_category === 'close'
            ) {
              return (
                <AutomationActionForm.Close
                  language={language}
                  values={values}
                  options={options}
                  contracts={contracts}
                  setFormReference={setFormReference}
                  handleValue={handleValue}
                  setValue={setValue}
                />
              );
            } else if (
              values.rule === 'mail' &&
              values.type_category === 'comment'
            ) {
              return (
                <AutomationActionForm.Comment
                  language={language}
                  values={values}
                  options={options}
                  contracts={contracts}
                  setFormReference={setFormReference}
                  handleValue={handleValue}
                  setValue={setValue}
                />
              );
            } else if (
              values.rule === 'mail' &&
              values.type_category === 'remove'
            ) {
              return (
                <AutomationActionForm.Remove
                  language={language}
                  values={values}
                  options={options}
                  setFormReference={setFormReference}
                  handleValue={handleValue}
                  setValue={setValue}
                />
              );
            } else if (
              values.rule === 'mail' &&
              values.type_category === 'resolve'
            ) {
              return (
                <AutomationActionForm.Resolve
                  language={language}
                  values={values}
                  options={options}
                  contracts={contracts}
                  setFormReference={setFormReference}
                  handleValue={handleValue}
                  setValue={setValue}
                />
              );
            } else {
              return (
                <AutomationActionForm.Default
                  language={language}
                  values={values}
                  options={options}
                  contracts={contracts}
                  setFormReference={setFormReference}
                  handleValue={handleValue}
                  setValue={setValue}
                />
              );
            }
          },
        },
      ]}
      _onSubmit={() => {
        formReference.submit();
      }}
      onSubmit={onSubmit}
    />
  );
}
