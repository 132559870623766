import React, {useState, memo} from 'react';
import './index.css';
import Header from '@common/Header';

import View1 from './View1';
import View2 from './View2';
import Store from '@data/Store';

export default memo(({tickets, projects, users, gmuds, os, redirect})=>{
   let [viewTable, setViewTable] = useState(false);
   const { language } = Store;
   return(
      <div id='Block_2'>
         <Header
            btnFilterProps={{
               style:{width:180}
            }}
            btnFilter={['Kanban', language.LIST]}
            onChange={e => {
               setViewTable(!!e);
            }}
         />
         {viewTable?(
            <View2 redirect={redirect} gmuds={gmuds} os={os} tickets={tickets} users={users} projects={projects}/>
         ):(
            <View1 redirect={redirect} gmuds={gmuds} os={os} tickets={tickets} users={users} projects={projects}/>
         )}
      </div>
   )
}, function(prev, next){
   let omit = obj => JSON.parse(JSON.stringify(_.omitBy(obj, val => typeof val=='function')));
   return _.isEqual(omit(prev), omit(next));
})