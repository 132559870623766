import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Container, Row, Col } from 'react-grid-system';

import Rdp from './rdp';

class RdpPage extends Component {
  componentDidMount() {
    let { io, path, store } = this.props;
    let rdpBox = ReactDOM.findDOMNode(this.refs.box);
    let canvas = ReactDOM.findDOMNode(this.refs[path]);

    canvas.width = rdpBox.scrollWidth;
    canvas.height = rdpBox.scrollHeight;

    io.on('connect', () => {
      io.emit(store.getToken(), {
        path: path,
        params: {
          screen: {
            width: canvas.width,
            height: canvas.height,
          },
          locale: window.navigator.userLanguage || window.navigator.language,
        },
      });
    });

    new Rdp(canvas, io, path, () => this.props.close());
  }

  render() {
    let { path } = this.props;
    let { height, width } = window.screen;
    return (
      <div
        ref="box"
        style={{
          height: '100%',
          width: '100%',
          margin: 0,
          position: 'fixed',
          top: 0,
          left: -10,

          zIndex: 1200,
        }}
      >
        <canvas ref={path} />
      </div>
    );
  }
}

export default RdpPage;
