import CommonTable from '../../common/CommonTable';
import CommonForm from '../../common/CommonForm2';
import Dialog from '@common/Dialog';
import Button from '../../common/Button';
import { useState, useEffect, Fragment } from 'react';
import Constants from '@data/Constants';
import Actions from '@data/Actions';
import { Divider, Paper } from '@material-ui/core';
import { Card, Chip, Switch } from '@material-ui/core';
import CommonDelete from '../../common/CommonDelete';

const action = new Actions();

const WorkflowChange = ({ company, language, params, Store }) => {
  const [page, setPage] = useState('list');
  const [values, setValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(0)
  const [filters, setFilters] = useState({})
  const [totalData, setTotalData] = useState(0)
  const [data, setData] = useState([])
  const [sortElement, setSortElement] = useState('')
  const [sortOrder, setSortOrder] = useState(null)
  const handlePage = (page, obj) => {
    setPage(page);
    setValues(obj);
  };

  const sortBy = (item, order ) =>{
    setSortElement(item)
    setSortOrder(order)
    setTimeout(() => {
      callPageSelectFilter(currentPage, filters, 75)
    }, 50)
  }

  useEffect(() => {
    callPageSelectFilter()
  }, [])

  const callPageSelectFilter = (page = 0, items = {}, totalPerPage = 75) => {
    setCurrentPage(page)
    setFilters(items)
    let sortBy = {item : sortElement, sort :sortOrder} 
    items.sortBy = sortBy
    items.fk_id_company = company.id_company
    let offset = page * totalPerPage;
        action
      .execute('post', `${Constants.APIEndpoints.CHANGE}/getworkflows?limit=${totalPerPage}&offset=${offset}` , '', items)
      .then((res) => {
        setTotalData(res.data[0] ? res.data[0].total_count : 0)
        setData(res.data);
      });
  };


  const onSubmit = (values) => {
    values.fk_id_company = company.id_company;
    const endpoint =  values.id_workflow_approve_change ? '/editworkflow/' + values.id_workflow_approve_change : '/createworkflow'
    const method = values.id_workflow_approve_change ? 'put' : 'post'
    if (values.level1 == values.level2 || values.level1 == values.level3 || values.level2 == values.level3){
      Store.toggleAlert(
        true,
        language.DUPLICATE_FIELDS_WORKFLOW,
        'error'
      );

    }else{
    action
      .execute(
        method,
        Constants.APIEndpoints.CHANGE + endpoint,
        '',
        values,
      )
      .then((res) => {
        handlePage('list')
        callPageSelectFilter()
      }).catch(e => {
        Store.toggleAlert(
          true,
          language[e.data.message],
          'error'
        );      
      });
    }
  };

  const onDelete = () => {
    action
      .execute(
        'post',
        Constants.APIEndpoints.CHANGE + '/deleteworkflow',
        '',
        values,
      )
      .then((res) => {
        console.log('res', res)
        handlePage('list')
        callPageSelectFilter()
      }).catch(e => {
        Store.toggleAlert(
          true,
          language[e.data.message],
          'error'
        );      
      });  
  }

  const optionsLevel = [
    {
      value: 1,
      label: language.APPROVERS,
    },
    {
      value: 2,
      label: language.APPROVERS_GROUPS,
    },
    {
      value: 3,
      label: language.TECH_GROUP_COMPANY,
    },
  ];

  if (params.change_group_tech_approve){
    optionsLevel.push({
        value: 4,
        label : language.TECHNICAL_GROUPS
    })
  }

  let fields = [
    {
      col: 6,
      name: 'type_change',
      label: language.TYPE,
      type: 'select',
      options: [
        {
          value: 1,
          label: language.COMPLETE,
        },
        {
          value: 2,
          label: language.STANDARD,
        },
      ],
    },
    {
      col: 6,
      name: 'type_action',
      label: language.TYPE_ACTION,
      type: 'select',
      options: [
        {
          value: 1,
          label: language.CORRECTIVE,
        },
        {
          value: 2,
          label: language.EMERGENCY,
        },
        {
          value: 3,
          label: language.NORMAL,
        },
        {
          value: 4,
          label: language.DEFAULT,
        },
      ],
    },
    {
      col: 12,
      type: 'select',
      name: 'level1',
      label: language.LEVEL + ' 1',
      options: optionsLevel,
    },
    {
      col: 12,
      type: 'select',
      name: 'level2',
      label: language.LEVEL + ' 2',
      options: optionsLevel,
    },
    {
      col: 12,
      type: 'select',
      name: 'level3',
      label: language.LEVEL + ' 3',
      options: optionsLevel,
    },
  ];

  const typeChangeValues = [
    '',
    language.COMPLETE,
    language.STANDARD
  ]
  const typeActionValus = [
    '',
    language.CORRECTIVE,
    language.EMERGENCY,
    language.NORMAL,
    language.DEFAULT
  ]
  const dataTable = data.map(d => {
    return {
        type_change : typeChangeValues[d.type_change],
        type_action: typeActionValus[d.type_action],
        level1 : optionsLevel.find(o => o.value == d.level1).label,
        level2 : optionsLevel.find(o => o.value == d.level2).label,
        level3 : optionsLevel.find(o => o.value == d.level3).label,
        action : (
            <Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="circle"
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    size={0.9}
                    onClick={() => handlePage('add', d)}
                  />
                 <Button
                    variant="circle"
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 12, width: 21, height: 21 }}
                    size={0.9}
                    className="mx-2"
                    onClick={() => handlePage('remove', d)}
               />
                </div>
              }
            />
          </Fragment>
        )
    }
  })

  return (
    <div>
      <CommonTable
        data={dataTable}
        countTotal={totalData}
        isAutomaticPagination={false}
        callPageSelectFilter={(page, values, rowsPerPage, type) =>
          callPageSelectFilter(page, values, rowsPerPage, type)
        }
        onClearAllFilter={() => callPageSelectFilter()}
        loadSearch={true}
        searchColumn={true}
        noFilter = {true}
        orderColumn
        onSortItem={(item, sortByStatus) => {
          sortBy(item, sortByStatus);
        }}
        col={[
            { key: 'type_change', label: language.TYPE }, 
            { key: 'type_action', label: language.TYPE_ACTION }, 
            { key: 'level1', label: language.LEVEL + ' 1' }, 
            { key: 'level2', label: language.LEVEL + ' 2' }, 
            { key: 'level3', label: language.LEVEL + ' 3' }, 
            { key : 'action', label : language.ACTION}
        ]}
        beforeBar={
          <div>
            <Button
              variant="circle"
              icon="fas fa-plus"
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              label={language.CREATE_WORKFLOW}
              primary={true}
              onClick={() => handlePage('add')}
            />
          </div>
        }
      />

      {page == 'add' && (
        <Dialog
          title={language.CREATE_WORKFLOW}
          open={true}
          onClose={() => handlePage('list')}
        >
          <CommonForm onSubmit={onSubmit} fields={fields} values = {values}/>
        </Dialog>
      )}
      {page == 'remove' && (
        <CommonDelete
          onConfirm={onDelete}
          language={language}
          closeModal={() => handlePage('list')}
          onDelete={onDelete}
          message={
          values.name
          }
          />
        )}
    </div>
  );
};

export default WorkflowChange;
