import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Store from '../../../data/Store';
import Button from '../../../common/Button';
import CommonForm from '../../../common/CommonForm2';
import DialogConfirm from '../../../common/DialogConfirm';
import './formCab.css';
const { language } = Store;

const YesOrNot = {
  yes: language.YES,
  not: language.NO,
};

const MessageEmpty = ({ message }) => <div>{message}</div>;

const FormRiskCAB = ({ action, APIEndpoints, language, change, onSaves }) => {
  const [events, setEvents] = useState([]);
  const [cab, setCab] = useState('');
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    action
      .execute('get', `${APIEndpoints.CHANGE_CAB}/company/${change.fk_id_company}`)
      .then((res) => {
        if (res.data && res.data.cc_group_id) {
          setCab(res.data);
        }
      })
      .then(() => {
        action.execute('get', `${APIEndpoints.CHANGE_CAB_RISK}/company/${change.fk_id_company}`).then((res) => {
          if (res.data.length) {
            setQuestions(res.data);
          }
          setLoading(false);
        });
      });
  }, [loading]);

  const onChangeField = (field, value) => {
    if (field.name === 'cab_date') {
      setEvents([]);
      action
        .execute(
          'get',
          `${APIEndpoints.EVENT_CAB}/filter?fk_id_company=${change.fk_id_company}&date=${moment(value).format(
            'YYYY-MM-DD'
          )}`
        )
        .then((res) => {
          setEvents(res.data);
        });
    }
  };

  const getQuestionValue = (label, id) => {
    const question = questions.find((q) => q.ccr_id === id);
    try {
      return question[label];
    } catch (e) {
      return ''
    }
  };

  const onSave = (values) => {
    const cabDate = values.cab_date;
    const cabEvents = values.events || [];
    const answers = Object.keys(values)
      .filter((key) => key !== 'cab_date' && key !== 'events')
      .map((key) => ({
        id: key,
        answer: values[key],
        name: getQuestionValue('ccr_name', key),
        order: getQuestionValue('ccr_order', key),
        weight: getQuestionValue('ccr_weight', key),
      }));

    if (cabDate) {
      if (!cabEvents.length) {
        Store.toggleAlert(
          true,
          language.REPORT_AT_LEAST_ONE_EVENT_TO_THE_CAB,
          'error'
        );
        return;
      }
      action
        .execute('post', `${APIEndpoints.CHANGE}/${change.id_change}/cab`, '', {
          cab: {
            id: cab.cc_id,
            weight: cab.cc_weight,
            group: cab.cc_group_id,
            date: cabDate,
          },
          answers: answers,
          events: cabEvents,
        })
        .then((res) => {
          Store.toggleAlert(
            true,
            language.GMUD_AND_CAB_RISKS_SUCCESSFULLY_REGISTERED,
            'success'
          );
          onSaves();
        });
      return;
    }

    const weightSum = answers
      .filter((item) => item.answer === 'yes')
      .map((item) => item.weight)
      .reduce((prev, cur) => prev + cur, 0);

    if (weightSum < cab.cc_weight) {
      action
        .execute('post', `${APIEndpoints.CHANGE}/${change.id_change}/cab`, '', {
          answers: answers,
        })
        .then((res) => {
          Store.toggleAlert(
            true,
            language.GMUD_RISKS_SUCCESSFULLY_REGISTERED,
            'success'
          );
          onSaves();
        });
      return;
    }

    if (weightSum >= cab.cc_weight) {
      Store.toggleAlert(
        true,
        language.THIS_GMUD_WILL_OBLIGATORILY_GO_TO_THE_CAB_FOR_APPROVAL,
        'error'
      );
      return;
    }
  };

  if (loading)
    return (
      <>
        <hr />
        <MessageEmpty message={language.LOAD} />
        <hr />
      </>
    );

  if (!questions.length || !cab) {
    return (
      <>
        <hr />
        <MessageEmpty message={language.GLOBAL_DATA_ON_RISKS_OF_CHANGE_NOT_REGISTERED} />
        <hr />
      </>
    );
  }

  const fields = questions.map((question) => ({
    col: 12,
    type: 'listCheckboxSingle',
    name: question.ccr_id,
    label: question.ccr_name,
    options: [
      {
        label: language.YES,
        value: 'yes',
      },
      {
        label: language.NOT,
        value: 'not',
      },
    ],
    required: question.ccr_required,
  }));

  fields.push({
    col: 6,
    type: 'element',
    content: (
      <div style={{ color: 'var(--primary)', padding: '15px 0' }}>
        Peso CAB: {cab.cc_weight}
      </div>
    ),
  });

  fields.push({
    col: 6,
    type: 'element',
    content: (
      <div style={{ color: 'var(--primary)', padding: '15px 0' }}>
        {language.APPROVAL_GROUP}: {cab.cc_group_label}
      </div>
    ),
  });

  fields.push({
    col: 6,
    type: 'date',
    name: 'cab_date',
    label: language.DATE_CAB,
  });

  if (events.length) {
    fields.push({
      col: 3,
      name: 'events',
      label: language.EVENTS,
      type: 'select',
      isMulti: true,
      options: events.map((i) => ({
        value: i.ec_id,
        label: i.ec_name,
      })),
    });
  }

  return (
    <>
      <hr />
      <div id="changeRiskForm">
        <CommonForm
          fields={fields}
          onSubmit={onSave}
          onChangeField={onChangeField}
        />
      </div>
      <hr />
    </>
  );
};

const RiskCAB = ({ cab, events, language, onDelete, onSaves, risks }) => {
  const [alertModal, setModal] = useState(false);
  return (
    <>
      <DialogConfirm
        open={alertModal}
        maxWidth="xs"
        onClose={() => setModal(false)}
        title={language.WARNING}
        message={language.THIS_ACTION_WILL_ERASE_ALL_PREVIOUS_RECORDS_TO_RE_ANALYZE_THE_RISKS_OF_THIS_CHANGE}
        confirmLabel={language.CONFIRM}
        eventClose={false}
        onConfirm={onDelete}
        showCancel={false}
      />
      <hr />
      {risks.map((risk) => (
        <div style={{ color: 'var(--primary)', padding: '15px 0' }}>
          {`${risk.order} - ${risk.name}: ${YesOrNot[risk.answer]}`}
        </div>
      ))}
      {!cab ? (
        <hr />
      ) : (
        <>
          <div style={{ color: 'var(--primary)', padding: '15px 0' }}>
            {language.APPROVAL_GROUP}: {cab.group}
          </div>
          <div style={{ color: 'var(--primary)', padding: '15px 0' }}>
            {language.CAB_SCHEDULING}: {moment(cab.date).format('DD/MM/YYYY')}
          </div>
          <div style={{ color: 'var(--primary)', padding: '15px 0' }}>
            {language.EVENTS}: {events?.map((c) => c.ec_name).join(', ')}
          </div>
          <hr />
        </>
      )}
      <Button
        icon="fas fa-repeat"
        variant="normal"
        color="danger"
        fluid
        style={{ height: 40 }}
        label={language.REDO_RISK_ANALYSIS}
        onClick={() => {
          setModal(true);
        }}
      />
      <hr />
      <Button
        icon="fas fa-thumbs-up"
        variant="normal"
        color="success"
        fluid
        style={{ height: 40 }}
        label={language.NEXT}
        onClick={onSaves}
      />
    </>
  );
};

const ChangeRiskCAB = (props) => {
  const [loading, setLoading] = useState(true);
  const [changeCab, setChangeCab] = useState({});

  const { change, onSaveS, action, APIEndpoints, language } = props;

  const onDelete = () => {
    action
      .execute(
        'delete',
        `${APIEndpoints.CHANGE}/${change.id_change}/cab`,
        '',
        null,
        null
      )
      .then((res) => {
        setChangeCab({});
        setLoading(true);
      });
  };

  useEffect(() => {
    action
      .execute(
        'get',
        `${APIEndpoints.CHANGE}/${change.id_change}/cab`,
        '',
        null,
        null
      )
      .then((res) => {
        if (res.data && res.data.risks.length) {
          setChangeCab(res.data);
        }
        setLoading(false);
      });
  }, [loading]);

  if (loading) {
    return (
      <>
        <hr />
        <MessageEmpty message={language.LOAD} />
        <hr />
      </>
    );
  }

  if (changeCab.risks && changeCab.risks.length) {
    return (
      <RiskCAB
        {...changeCab}
        onDelete={onDelete}
        onSaves={onSaveS}
        language={language}
      />
    );
  }

  return (
    <FormRiskCAB
      action={action}
      APIEndpoints={APIEndpoints}
      language={language}
      change={change}
      onSaves={onSaveS}
    />
  );
};

export default ChangeRiskCAB;
