import React from 'react';
import Constants from '@data/Constants';
import Dialog from '@common/Dialog';
import CommonForm from '@common/CommonForm2';
import Button from '@common/Button';

let colors = [
   '#9E24F0',
   '#F00D82',
   '#F05E02',
   '#F0B002',
   '#92D050',
   '#02F0DE',
   '#02CBF0',
   '#00008B',
   '#708090',
   '#2F4F4F',
   '#8B4513',
   '#8B0000',
   '#D8BFD8',
   '#4682B4'
]

export default function ({ open, onClose, tags, action, language }) {
   let { APIEndpoints } = Constants;

   function onSubmit(values) {
      values.template = true;
      action.execute('put', `${APIEndpoints.PROJECT}/tag/update`, 'projects', values, 'id_project', { btn_loading: 'submit_' + values.id_project_tag }).then(e => {
         onClose();
      })
   }
   function onDelete(values) {
      values.template = true;
      action.execute('put', `${APIEndpoints.PROJECT}/tag/update`, 'projects', values, 'id_project', { btn_loading: 'delete_' + values.id_project_tag }).then(e => {
         onClose();
      })
   }

   return (
      <Dialog
         title='Tags'
         open={open}
         maxWidth='md'
         onClose={onClose}
         monitor_scroll
      >
         {tags.map(e => (
            <CommonForm
               fixedValue={e}
               values={{
                  name: e.name,
                  color: e.color
               }}
               onSubmit={onSubmit}
               hiddenSubmit
               fields={[
                  {
                     col: 5,
                     type: 'text',
                     name: 'name',
                     label: language.NAME,
                     required: true,
                  },
                  {
                     col: 4,
                     type: 'select',
                     name: 'color',
                     label: language.COLOR,
                     sortOptions: false,
                     required: true,
                     options: colors.map(e => ({ value: e, label: <div style={{ display: 'flex' }}><div style={{ backgroundColor: e, width: 20, height: 20, marginRight: '10' }} />{e}</div> })),
                  },
                  {
                     col: 3,
                     type: 'element',
                     content: (v, h, submit) => (
                        <div className='d-flex align-items-center mt-3'>
                           <Button
                              icon='fas fa-pencil-alt'
                              label={language.SAVE_CHANGES}
                              color='success'
                              variant='normal'
                              className='mr-3'
                              onClick={submit}
                              _loading={'submit_' + e.id_project_tag}
                           />
                           <Button
                              icon='fas fa-trash-alt'
                              label={language.DELETE2}
                              color='danger'
                              variant='circle'
                              outlined
                              style={{ fontSize: 16 }}
                              onClick={() => onDelete({ ...e, color: undefined, deleted: true })}
                              _loading={'delete_' + e.id_project_tag}
                           />
                        </div>
                     )
                  }
               ]}
            />
         ))}
      </Dialog>
   )
}