import React, { Component, Fragment, useEffect, useState } from 'react';

import decrypt from '../../../../util/decrypt';
import Header from '../../../../common/Header';
import Button from '../../../../common/Button';
import PageLayout from '../../../../common/PageLayout';
import CommonPage from '../../../../common/CommonPage';
import CommonForm from '../../../../common/CommonForm2';
import CommonTable from '../../../../common/CommonTable';
import Constants from '../../../../data/Constants';
import Actions from '@data/Actions';
import './index.css';
import Store from '@data/Store';
const action = new Actions();
const { language_type } = Store;

const index = (props) => {
  const { language } = props;
  const [data, setData] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [showConditions, setShowConditions] = useState(true);
  const [showUsersList, setShowUsersList] = useState(true);
  const [showGroupsList, setShowGroupsList] = useState(false);
  const [conditions, setConditions] = useState([
    {
      label: language.GMUD_TYPE,
      value: 'type_gmud'
    },
    {
      label: language.TYPE_ACTION,
      value: 'type_action'
    },
    {
      label: language.OUTAGE,
      value: 'outage'
    },
    {
      label: language.CONTRACT,
      value: 'id_contract'
    },
    {
      label: language.CHANGE_MANAGER,
      value: 'id_change_manager'
    },
    {
      label: language.REQUESTER,
      value: 'id_solicit'
    },
    {
      label: language.INITIAL_DATE,
      value: 'startdate'
    },
    {
      label: language.FINAL_DATE,
      value: 'enddate'
    },
    {
      label: language.DEPARTMENTS,
      value: 'departments'
    },
    {
      label: language.DEVICES,
      value: 'devices'
    },
    {
      label: language.TECHNICAL_GROUPS,
      value: 'tech_groups'
    },
    {
      label: language.APPROVERS_GROUPS,
      value: 'sponsor_groups'
    },
    {
      label: language.TASK_TEMP,
      value: 'template_forms'
    },
    {
      label: language.FORM_FIELD,
      value: 'template_forms_fields'
    },
    {
      label: language.TITLE,
      value: 'reason'
    },
    {
      label: language.DESCRIPTION,
      value: 'benefits'
    }
  ]);
  const [selectedCompany, setCompany] = useState(null);

  const [logicalConditionsType, setLogicalConditionsType] = useState('equals');
  const [logicalConditionsEqualsOnly, setLogicalConditionsEqualsOnly] = useState([
    {
      label: language.EQUAL_TO,
      value: 'equals'
    }
  ]);
  const [logicalConditionsEquals, setLogicalConditionsEquals] = useState([
    {
      label: language.EQUAL_TO,
      value: 'equals'
    },
    {
      label: language.DIFFERENT_FROM,
      value: 'not_equals'
    }
  ]);
  const [logicalConditionsTime, setLogicalConditionsTime] = useState([
    {
      label: language.BEFORE,
      value: 'before'
    },
    {
      label: language.AFTER,
      value: 'after'
    }
  ]);
  const [logicalConditionsList, setLogicalConditionsList] = useState([
    {
      label: language.IN_LIST,
      value: 'in_list'
    },
    {
      label: language.OFF_THE_LIST,
      value: 'not_in_list'
    }
  ]);
  const [logicalConditionsContains, setLogicalConditionsContains] = useState([
    {
      label: language.CONTAIN,
      value: 'contains'
    },
    {
      label: language.DONT_COUNT,
      value: 'does_not_contains'
    }
  ]);

  const [conditionValueType, setConditionValueType] = useState('text');
  const [conditionValues, setConditionValues] = useState(null);
  const [valuesEndpoint, setValuesEndpoint] = useState({
    route: null,
    routeGetCount: null,
    routeAll: null,
    filter: null,
    filterAll: null,
    fieldvaluedb: null,
    fieldlabeldb: null,
    showEmail: false,
    isMulti: false
  });
  const [showHour, setShowHour] = useState(false);
  const [showFormFields, setShowFormFields] = useState(false);
  const [valuesApproverUsersEndpoint, setValuesApproverUsersEndpoint] = useState({
    route: null,
    routeGetCount: null,
    routeAll: null,
    filter: null,
    filterAll: null,
    fieldvaluedb: null,
    fieldlabeldb: null,
    showEmail: false,
    isMulti: false
  });
  const [valuesApproverGroupsEndpoint, setValuesApproverGroupsEndpoint] = useState({
    route: null,
    routeGetCount: null,
    routeAll: null,
    filter: null,
    filterAll: null,
    fieldvaluedb: null,
    fieldlabeldb: null,
    showEmail: false,
    isMulti: false
  });
  const [conditionsList, setConditionsList] = useState([]);
  const [approversList, setApproversList] = useState([]);
  const [approversListFirst, setApproversListFirst] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [contractsList, setContractsList] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [templateFormData, setTemplatesData] = useState([]);
  const [selectFieldFormData, setselectFieldFormData] = useState([]);
  const [value_form, setValue_form] = useState(null);
  const [techGroupsList, setTechGroupsList] = useState([]);
  const [fullApproversList, setFullApproversList] = useState([]);
  const [errorFormCondition, setErrorFormFieldCondition] = useState(false);
  const [errorNoConditions, setErrorNoConditions] = useState(false);
  const [errorNoApprovers, setErrorNoApprovers] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [tags, setTags] = useState([]);


  const loadWorflowData = (id) => {
    action.
      execute('get', `${Constants.APIEndpoints.CHANGE}/approval/rules/${id}`, '').then((item) => {
        let workflow = {
          name: item.data[0].name,
          description: item.data[0].description,
          id_company: Array.isArray(item.data[0].id_company) ? item.data[0].id_company : [item.data[0].id_company]
        };

        setData(workflow);

        setCompany(item.data[0].id_company);

        let hasIdContract = false;
        let hasIdChangeManager = false;
        let hasIdSolicit = false;
        let hasDepartments = false;
        let hasDevices = false;
        let hasTechGroups = false;
        let hasSponsorGroups = false;
        let hasTemplateForms = false;
        let hasTemplateFormsFields = false;
        workflow.conditions = item.data[0].conditions.map(condition => {
          hasIdContract = (hasIdContract || condition.condition === "id_contract");
          hasIdChangeManager = (hasIdChangeManager || condition.condition === "id_change_manager");
          hasIdSolicit = (hasIdSolicit || condition.condition === "id_solicit");
          hasDepartments = (hasDepartments || condition.condition === "departments");
          hasDevices = (hasDevices || condition.condition === "devices");
          hasTechGroups = (hasTechGroups || condition.condition === "tech_groups");
          hasSponsorGroups = (hasSponsorGroups || condition.condition === "sponsor_groups");
          hasTemplateForms = (hasTemplateForms || condition.condition === "template_forms");
          hasTemplateFormsFields = (hasTemplateFormsFields || condition.condition === "template_forms_fields");

          const clonedCondition = { ...condition };
          clonedCondition.conditional_id = clonedCondition.condition;

          let conditionalFiltered = conditions.filter(val => {
            return val.value === clonedCondition.conditional_id;
          });
          clonedCondition.conditional_label = conditionalFiltered[0].label;

          if (!isNaN(clonedCondition.value) && typeof clonedCondition.value !== 'object') {
            clonedCondition.value = parseInt(clonedCondition.value);
          } else {
            try {
              const array = JSON.parse(clonedCondition.value);
              if (Array.isArray(array)) {
                clonedCondition.value = array;
              }
            } catch (error) {
              clonedCondition.value = clonedCondition.value;
            }
          }

          switch (clonedCondition.condition) {
            case "reason":
            case "benefits":
              clonedCondition.value_label = clonedCondition.value;
              break;
            case 'type_gmud':
              let typeValues = [
                {
                  label: language.COMPLETE,
                  value: 2
                },
                {
                  label: language.EMERGENCY,
                  value: 1
                }
              ];
              let filteredType = typeValues.filter(val => {
                return val.value === clonedCondition.value;
              });
              clonedCondition.value_label = filteredType[0].label;
              break;
            case "type_action":
              let actionValues = [
                {
                  label: language.CORRECTIVE,
                  value: 1
                },
                {
                  label: language.EMERGENCY,
                  value: 2
                },
                {
                  label: language.NORMAL,
                  value: 3
                },
                {
                  label: language.DEFAULT,
                  value: 4
                }
              ];
              let filteredAction = actionValues.filter(val => {
                return val.value === clonedCondition.value;
              });
              clonedCondition.value_label = filteredAction[0].label;
              break;
            case "outage":
              let outageValues = [
                {
                  label: language.YES,
                  value: 1
                },
                {
                  label: language.NO,
                  value: 0
                }
              ];
              let filteredOutage = outageValues.filter(val => {
                return val.value === clonedCondition.value;
              });
              clonedCondition.value_label = filteredOutage[0]?.label;
              break;
            case 'startdate':
            case 'enddate':
              const dataFormatada = new Date(clonedCondition.value).toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              });
              clonedCondition.value_label = dataFormatada;
              break;
          }

          delete clonedCondition.fk_id_change_approval_rules;
          delete clonedCondition.condition;
          return clonedCondition;
        });

        action.execute('post', Constants.APIEndpoints.CONTRACT + 'companylow/filter', '', {fk_id_companies: selectedCompany}).then((item) => {
          if( hasIdContract ) {
            workflow.conditions.map(condition => {
              if( condition.conditional_id === "id_contract" ) {
                let filteredItem = item.data.filter(val => {
                  return parseInt(val.id_contract) === parseInt(condition.value);
                });
                condition.value_label = filteredItem[0]?.name;
              }
            });
          }

          action.execute('post', `${Constants.APIEndpoints.USER}/filter/all`, '', {fk_id_companies: selectedCompany}).then((item) => {
            if( hasIdChangeManager || hasIdSolicit ) {
              workflow.conditions.map(condition => {
                if( condition.conditional_id === "id_change_manager" || condition.conditional_id === "id_solicit" ) {
                  let filteredItem = item.data.filter(val => {
                    return parseInt(val.id_user) === parseInt(condition.value);
                  });
                  condition.value_label = filteredItem[0]?.name;
                }
              });
            }

            action.execute('post', `${Constants.APIEndpoints.DEPARTMENT}/filter`, '', {fk_id_companies: selectedCompany}).then((item) => {
              if( hasDepartments ) {
                workflow.conditions.map(condition => {
                  if( condition.conditional_id === "departments" ) {
                    let conditionalDepartmentFiltered = item.data.filter(val => {
                      return condition.value.includes(parseInt(val.id_department));
                    });
                    if (conditionalDepartmentFiltered.length > 0) {
                      const names = conditionalDepartmentFiltered.map(item => item?.name);
                      condition.value_label = names.slice(0, -1).join(', ');

                      if (names.length > 1) {
                        condition.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                      } else {
                        condition.value_label += names[0];
                      }
                    }
                  }
                });
              }

              action.execute('post', `${Constants.APIEndpoints.DEVICE}/filter`, '', {fk_id_companies: selectedCompany}).then((item) => {
                if( hasDevices ) {
                  workflow.conditions.map(condition => {
                    if( condition.conditional_id === "devices" ) {
                      let conditionalDevicesFiltered = item.data.filter(val => {
                        return condition.value.includes(parseInt(val.id_device));
                      });
                      if (conditionalDevicesFiltered.length > 0) {
                        const names = conditionalDevicesFiltered.map(item => item?.name);
                        condition.value_label = names.slice(0, -1).join(', ');

                        if (names.length > 1) {
                          condition.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                        } else {
                          condition.value_label += names[0];
                        }
                      }
                    }
                  });
                }

                action.execute('post', `${Constants.APIEndpoints.GROUP}/all?type=1`, '', {type_search: 1}).then((item) => {
                  if( hasTechGroups ) {
                    workflow.conditions.map(condition => {
                      if( condition.conditional_id === "tech_groups" ) {
                        let conditionalTechGroupsFiltered = item.data.filter(val => {
                          return condition.value.includes(parseInt(val.id_group_users));
                        });
                        if (conditionalTechGroupsFiltered.length > 0) {
                          const names = conditionalTechGroupsFiltered.map(item => item?.name);
                          condition.value_label = names.slice(0, -1).join(', ');

                          if (names.length > 1) {
                            condition.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                          } else {
                            condition.value_label += names[0];
                          }
                        }
                      }
                    });
                  }

                  action.execute('post', `${Constants.APIEndpoints.GROUP}/all?type=3`, '', {type_search: 3}).then((item) => {
                    if( hasSponsorGroups ) {
                      workflow.conditions.map(condition => {
                        if( condition.conditional_id === "sponsor_groups" ) {
                          let conditionalSponsorGroupsFiltered = item.data.filter(val => {
                            return condition.value.includes(parseInt(val.id_group_users));
                          });
                          if (conditionalSponsorGroupsFiltered.length > 0) {
                            const names = conditionalSponsorGroupsFiltered.map(item => item?.name);
                            condition.value_label = names.slice(0, -1).join(', ');

                            if (names.length > 1) {
                              condition.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                            } else {
                              condition.value_label += names[0];
                            }
                          }
                        }
                      });
                    }

                    action.execute('get', Constants.APIEndpoints.TASK + '/getform/').then((item) => {
                      if( hasTemplateForms ) {
                        workflow.conditions.map(condition => {
                          if( condition.conditional_id === "template_forms" ) {
                            let filteredItem = item.data.filter(val => {
                              return parseInt(val.id_template_forms) === parseInt(condition.value);
                            });
                            condition.value_label = filteredItem[0]?.name;
                          }
                        });
                      }

                      if( hasTemplateFormsFields ) {
                        workflow.conditions.map(condition => {
                          if( condition.conditional_id === "template_forms_fields" ) {
                            let filteredItem = item.data.filter(val => {
                              return parseInt(val.id_template_forms) === parseInt(condition.value);
                            });
                            condition.value_label = filteredItem[0]?.name;

                            let formData = JSON.parse(
                              decrypt(filteredItem[0]?.data)
                            )
                            condition.form_field_label = formData[parseInt(condition.form_field)].label;
                          }
                        });
                      }

                      setConditionsList(workflow.conditions);
                    });
                  });
                });
              });
            });
          });
        });

        let hasUserApprover = false;
        let hasGroupApprover = false;
        workflow.approvers = item.data[0].approvers.map(approver => {
          hasUserApprover = (hasUserApprover || approver.type === "user");
          hasGroupApprover = (hasGroupApprover || approver.type !== "user");
          const clonedApprover = { ...approver };
          clonedApprover.approver_type = approver.type;
          clonedApprover.approver_type_label = approver.type === "user" ? language.APPROVER : approver.type === "group" ? language.APPROVERS_GROUP : language.TECH_GROUP;
          clonedApprover.approver_value = approver.type_id;
          clonedApprover.order = approver.position;
          delete clonedApprover.fk_id_change_approval_rules;
          delete clonedApprover.position;
          delete clonedApprover.type;
          delete clonedApprover.type_id;
          return clonedApprover;
        });

        const payloadUser = {search: "", fk_id_companies: selectedCompany};
        action.
          execute('post', `${Constants.APIEndpoints.USER}/filter/all`, '', payloadUser).then((item) => {
            if( hasUserApprover ) {
              workflow.approvers.map(approver => {
                if( approver.approver_type === "user" ) {
                  let filteredItem = item.data.filter(val => {
                    return parseInt(val.id_user) === parseInt(approver.approver_value);
                  });
                  approver.approver_label = filteredItem[0]?.name;
                }
              });
            }

            const payloadGroup = {search: "", type_search: 3};
            action.
              execute('post', `${Constants.APIEndpoints.GROUP}/all?type=3&`, '', payloadGroup).then((item) => {
                if( hasGroupApprover ) {
                  workflow.approvers.map(approver => {
                    if( approver.approver_type === "group" ) {
                      let filteredItem = item.data.filter(val => {
                        return parseInt(val.id_group_users) === parseInt(approver.approver_value);
                      });
                      approver.approver_label = filteredItem[0]?.name;
                    }
                  });
                }
                setApproversList(workflow.approvers);
              });
          });
      });
  }

  const loadCompanies = () => {
    action
      .execute('get', Constants.APIEndpoints.COMPANY, '').then((item) => {
        let companiesOptions = item.data.map((obj) => ({
          value: obj.id_company,
          label: obj.name,
          type: obj.type,
        }));
        setCompanies(companiesOptions);
      });
  }

  useEffect(() => {
    loadCompanies();
    if ( props.value?.id_change_approval_rules ) {
      loadWorflowData(props.value.id_change_approval_rules);
    }
  }, [props.value]);

  const loadContracts = () => {
    const payload = {
      fk_id_companies: selectedCompany
    };
    action
      .execute('post', Constants.APIEndpoints.CONTRACT + 'companylow/filter', '', payload).then((item) => {
        let contractsOptions = item.data.map((obj) => ({
          value: obj.id_contract,
          label: obj.name,
        }));
        setContractsList(contractsOptions);
      });
  }

  const loadUsers = () => {
    const payload = {
      fk_id_companies: selectedCompany
    };
    action.
      execute('post', `${Constants.APIEndpoints.USER}/filter/all`, '', payload).then((item) => {
        setUsersList(item.data);
      });
  }

  const loadDepartments = () => {
    const payload = {
      fk_id_companies: selectedCompany
    };
    action.
      execute('post', `${Constants.APIEndpoints.DEPARTMENT}/filter`, '', payload).then((item) => {
        setDepartmentsList(item.data);
      });
  }

  const loadDevices = () => {
    const payload = {
      fk_id_companies: selectedCompany
    };
    action.
      execute('post', `${Constants.APIEndpoints.DEVICE}/filter`, '', payload).then((item) => {
        setDevicesList(item.data);
      });
  }

  const loadTechGroups = (type) => {
    const payload = {
      type_search: type
    };
    action.
      execute('post', `${Constants.APIEndpoints.GROUP}/all?type=${type}`, '', payload)
      .then((item) => {
        setTechGroupsList(item.data);
      });
  }

  const loadTemplates = () => {
    action.
      execute('get', Constants.APIEndpoints.TASK + '/getform/').then((item) => {
        let contractsOptions = item.data.map((obj) => ({
          value: obj.id_template_forms,
          label: obj.name,
        }));
        setConditionValues(contractsOptions);
      });
  }

  const loadFormsTemplates = (value) => {
    action.
      execute('get', `${Constants.APIEndpoints.TASK}/getforms?type=kv`, '')
      .then((res) => {
        setTemplatesData(
          JSON.parse(
            decrypt(
              res.data.filter((item) => item.fk_id_template_forms == value)[0].data
            )
          )
        )
      })
      .catch((error) => {
        console.error(error);
      })
  }

  const getFieldForm = (field) => {
    if (field) {
      let array = [];

      if (field.type == 'select') {
        array = {
          col: 12,
          type: 'select',
          name: 'value_form',
          label: (field.label ? field.label : language.VALUE),
          options: field.values.map((item) => ({
            value: item.label,
            label: item.label
          })),
        }
      }

      if (field.type == 'checkbox-group') {
        array = {
          col: 12,
          name: 'value_form',
          type: 'listCheckbox',
          label: (field.label ? field.label : language.VALUE),
          options: field.values.map((item) => ({
            value: item.label,
            label: item.label
          })),
        };
      }

      if (field.type == 'radio-group') {
        array = {
          col: 12,
          name: 'value_form',
          type: 'listCheckboxSingle',
          label: (field.label ? field.label : language.VALUE),
          options: field.values.map((item) => ({
            value: item.label,
            label: item.label,
          }))
        }
      }

      if (field.type.indexOf('text') > -1) {
        array = {
          col: 12,
          name: 'value_form',
          type: 'text',
          label: (field.label ? field.label : language.VALUE)
        };
      }

      if (field.type == 'date') {
        array = {
          col: 12,
          name: 'value_form',
          type: 'date',
          label: (field.label ? field.label : language.VALUE)
        };
      }

      if (field.type == 'time') {
        array = {
          col: 12,
          name: 'value_form',
          type: 'time',
          label: (field.label ? field.label : language.VALUE)
        };
      }

      return (
        <div style={{ marginTop: (field.type.indexOf('text') > -1 || field.type == 'select' || field.type == 'date' || field.type == 'time') ? '16px' : '8px', marginBottom: '20px' }}>
          <CommonForm
            onChangeField={(field, value) => {
              setValue_form(value)
            }}
            fields={[array]}
          ></CommonForm>
        </div>
      )
    }
  }

  const loadApproverUserList = () => {
    const payload = {search: "", fk_id_companies: selectedCompany}
    action.
      execute('post', `${Constants.APIEndpoints.USER}/filter/all`, '', payload).then((item) => {
        setFullApproversList(item.data);
      });
  }

  const loadApproverGroupList = () => {
    const payload = {
      search: "",
      type_search: 3
    };
    action.
      execute('post', `${Constants.APIEndpoints.GROUP}/all?type=3&`, '', payload).then((item) => {
        setFullApproversList(item.data);
      });
  }

  const onChangeField = (field, value) => {
    switch (field.name) {
      case 'id_company':
        setCompany(value);
        setShowConditions(false);
        setConditionValueType('text');
        setShowHour(false);
        setFullApproversList([]);
        setselectFieldFormData([]);
        setTemplatesData([]);
        setValue_form(null);
        setTimeout(() => {
          setShowConditions(true);
        }, 50);
        break;

      case "conditional_id":
        setConditionValueType('text');
        setselectFieldFormData([]);
        setValue_form(null);
        switch (value) {
          case 'type_gmud':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('select');
            }, 50);
            setConditionValues([
              {
                label: language.COMPLETE,
                value: 2
              },
              {
                label: language.EMERGENCY,
                value: 1
              }
            ]);
            setLogicalConditionsType('equals_only');
            break;
          case 'type_action':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('select');
            }, 50);
            setConditionValues([
              {
                label: language.CORRECTIVE,
                value: 1
              },
              {
                label: language.EMERGENCY,
                value: 2
              },
              {
                label: language.NORMAL,
                value: 3
              },
              {
                label: language.DEFAULT,
                value: 4
              }
            ]);
            setLogicalConditionsType('equals_only');
            break;
          case 'outage':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('select');
            }, 50);
            setConditionValues([
              {
                label: language.YES,
                value: 1
              },
              {
                label: language.NO,
                value: 0
              }
            ]);
            setLogicalConditionsType('equals');
            break;
          case 'id_contract':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('autocomplete');
            }, 50);
            loadContracts();
            setValuesEndpoint({
              route: `${Constants.APIEndpoints.CONTRACT}companylow/filter?`,
              routeGetCount: `${Constants.APIEndpoints.CONTRACT}companylow/filter?`,
              routeAll: `${Constants.APIEndpoints.CONTRACT}companylow/filter?`,
              filterAll: { fk_id_companies: selectedCompany || 0 },
              filter: { fk_id_companies: selectedCompany || 0 },
              fieldvaluedb: 'id_contract',
              fieldlabeldb: 'name',
              showEmail: false,
              isMulti: false
            });
            setLogicalConditionsType('equals');
            break;
          case 'id_change_manager':
          case 'id_solicit':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('autocomplete');
            }, 500);
            loadUsers();
            setValuesEndpoint({
              route: `${Constants.APIEndpoints.USER}/filter/all`,
              routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${selectedCompany || 0}`,
              routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
              filterAll: { fk_id_companies: selectedCompany || 0 },
              filter: { fk_id_companies: selectedCompany || 0 },
              fieldvaluedb: 'id_user',
              fieldlabeldb: 'name',
              showEmail: true,
              isMulti: false
            });
            setLogicalConditionsType('equals');
            break;
          case 'startdate':
          case 'enddate':
            setShowHour(true);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('date');
            }, 500);
            setLogicalConditionsType('time');
            break;
          case 'departments':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('autocomplete');
            }, 500);
            loadDepartments();
            setValuesEndpoint({
              route: `${Constants.APIEndpoints.DEPARTMENT}/filter/`,
              routeGetCount: `${Constants.APIEndpoints.DEPARTMENT}/filter?count=0`,
              routeAll: `${Constants.APIEndpoints.DEPARTMENT}/filter?`,
              filterAll: { fk_id_companies: selectedCompany || 0 },
              filter: { fk_id_companies: selectedCompany || 0 },
              fieldvaluedb: 'id_department',
              fieldlabeldb: 'name',
              showEmail: false,
              isMulti: true
            });
            setLogicalConditionsType('list');
            break;
          case 'devices':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('autocomplete');
            }, 500);
            loadDevices();
            setValuesEndpoint({
              route: `${Constants.APIEndpoints.DEVICE}/filter?`,
              routeGetCount: `${Constants.APIEndpoints.DEVICE}/filter?count=0`,
              routeAll: `${Constants.APIEndpoints.DEVICE}/filter?`,
              filterAll: { fk_id_companies: selectedCompany || 0 },
              filter: { fk_id_companies: selectedCompany || 0 },
              fieldvaluedb: 'id_device',
              fieldlabeldb: 'name',
              showEmail: false,
              isMulti: true
            });
            setLogicalConditionsType('list');
            break;
          case 'tech_groups':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('autocomplete');
            }, 500);
            loadTechGroups(1);
            setValuesEndpoint({
              route: `${Constants.APIEndpoints.GROUP}/all?type=1&`,
              routeGetCount: `${Constants.APIEndpoints.GROUP}/all?type=1&`,
              routeAll: `${Constants.APIEndpoints.GROUP}/all?type=1&`,
              filterAll: { type_search: 1 },
              filter: { type_search: 1 },
              fieldvaluedb: 'id_group_users',
              fieldlabeldb: 'name',
              showEmail: false,
              isMulti: true
            });
            setLogicalConditionsType('list');
            break;
          case 'sponsor_groups':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('autocomplete');
            }, 500);
            loadTechGroups(3);
            setValuesEndpoint({
              route: `${Constants.APIEndpoints.GROUP}/all?type=3&`,
              routeGetCount: `${Constants.APIEndpoints.GROUP}/all?type=3&`,
              routeAll: `${Constants.APIEndpoints.GROUP}/all?type=3&`,
              filterAll: { type_search: 3 },
              filter: { type_search: 3 },
              fieldvaluedb: 'id_group_users',
              fieldlabeldb: 'name',
              showEmail: false,
              isMulti: true
            });
            setLogicalConditionsType('list');
            break;
          case 'template_forms':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('select');
            }, 500);
            loadTemplates();
            setLogicalConditionsType('equals');
            break;
          case 'template_forms_fields':
            setShowHour(false);
            setShowFormFields(true);
            setTimeout(() => {
              setConditionValueType('select');
            }, 500);
            loadTemplates();
            setLogicalConditionsType('equals');
            break;
          case 'reason':
          case 'benefits':
            setShowHour(false);
            setShowFormFields(false);
            setTimeout(() => {
              setConditionValueType('text');
            }, 500);
            setLogicalConditionsType('contains');
            break;
        }
        break;

      case "value":
        setValue_form(null);
        if( showFormFields ) {
          loadFormsTemplates(value);
        }
        break;

      case "form_field":
        setselectFieldFormData(value);
        setValue_form(null);
        break;

      case "approver_type":
        switch (value) {
          case "user":
            setShowGroupsList(false);
            setTimeout(() => {
              setShowUsersList(true);
            }, 50);
            loadApproverUserList();
            setValuesApproverUsersEndpoint({
              route: `${Constants.APIEndpoints.USER}/filter/all`,
              routeGetCount: `${Constants.APIEndpoints.USER}/active/count/${selectedCompany || 0}`,
              routeAll: `${Constants.APIEndpoints.USER}/filter/all?`,
              filterAll: { fk_id_companies: selectedCompany || 0 },
              filter: { fk_id_companies: selectedCompany || 0 },
              fieldvaluedb: 'id_user',
              fieldlabeldb: 'name',
              showEmail: true,
              isMulti: false
            });
            break;
          case "group":
            setShowUsersList(false);
            setTimeout(() => {
              setShowGroupsList(true);
            }, 50);
            loadApproverGroupList();
            setValuesApproverGroupsEndpoint({
              route: `${Constants.APIEndpoints.GROUP}/all?type=3&`,
              routeGetCount: `${Constants.APIEndpoints.GROUP}/all?type=3&`,
              routeAll: `${Constants.APIEndpoints.GROUP}/all?type=3&`,
              filterAll: { type_search: 3 },
              filter: { type_search: 3 },
              fieldvaluedb: 'id_group_users',
              fieldlabeldb: 'name',
              showEmail: false,
              isMulti: false
            });
            break;
          case "tech_group":
            setShowUsersList(false);
            setShowGroupsList(false);
            break;
        }
        break;
    }
  }

  const saveCondition = (cond) => {
    if( cond.conditional_id === "template_forms_fields" && !value_form ) {
      setErrorFormFieldCondition(true);
    } else {
      setErrorFormFieldCondition(false);

      let conditionalFiltered = conditions.filter(val => {
        return val.value === cond.conditional_id;
      });
      cond.conditional_label = conditionalFiltered[0].label;

      switch (logicalConditionsType) {
        case 'equals_only':
          cond.operator = cond.operator_equals_only;
          break;
        case 'equals':
          cond.operator = cond.operator_equals;
          break;
        case 'time':
          cond.operator = cond.operator_time;
          break;
        case 'list':
          cond.operator = cond.operator_list;
          break;
        case 'contains':
          cond.operator = cond.operator_contains;
          break;
      }

      switch (conditionValueType) {
        case 'select':
          let conditionalValueFiltered = conditionValues.filter(val => {
            return val.value === cond.value;
          });
          cond.value_label = conditionalValueFiltered[0].label;
          break;
        case 'date':
          const dataFormatada = cond.conditional_hour.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });

          cond.value = cond.conditional_hour.getTime();
          cond.value_label = dataFormatada;
          break;
        case 'autocomplete':
          switch (cond.conditional_id) {
            case 'id_contract':
              let contractsFilters = contractsList.filter(val => {
                return val.value === cond.value;
              });
              cond.value_label = contractsFilters[0]?.label;
              break;
            case 'id_change_manager':
            case 'id_solicit':
              let userConditionalFiltered = usersList.filter(val => {
                return val.id_user === cond.value;
              });
              cond.value_label = userConditionalFiltered[0]?.name;
              break;
            case 'departments':
              let conditionalDepartmentFiltered = departmentsList.filter(val => {
                return cond.value.includes(val.id_department);
              });
              if (conditionalDepartmentFiltered.length > 0) {
                const names = conditionalDepartmentFiltered.map(item => item?.name);
                cond.value_label = names.slice(0, -1).join(', ');

                if (names.length > 1) {
                  cond.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                } else {
                  cond.value_label += names[0];
                }
              } else {
                cond.value_label = '';
              }
              break;
            case 'devices':
              let conditionalDeviceFiltered = devicesList.filter(val => {
                return cond.value.includes(val.id_device);
              });
              if (conditionalDeviceFiltered.length > 0) {
                const names = conditionalDeviceFiltered.map(item => item?.name);
                cond.value_label = names.slice(0, -1).join(', ');

                if (names.length > 1) {
                  cond.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                } else {
                  cond.value_label += names[0];
                }
              } else {
                cond.value_label = '';
              }
              break;
            case 'tech_groups':
            case 'sponsor_groups':
              let conditionalTechGroupsFiltered = techGroupsList.filter(val => {
                return cond.value.includes(val.id_group_users);
              });
              if (conditionalTechGroupsFiltered.length > 0) {
                const names = conditionalTechGroupsFiltered.map(item => item?.name);
                cond.value_label = names.slice(0, -1).join(', ');

                if (names.length > 1) {
                  cond.value_label += ` ${language.AND} ${names[names.length - 1]}`;
                } else {
                  cond.value_label += names[0];
                }
              } else {
                cond.value_label = '';
              }
              break;
          }
          break;
        default:
          cond.value_label = cond.value;
          break;
      }

      if( cond.conditional_id === "template_forms_fields" ) {
        let templates = templateFormData.map((g, index) => ({
          label: g.label,
          value: index
        }));

        cond.form_field_label = templates.filter(templatesData => templatesData.value === cond.form_field)[0].label;

        cond.form_value = value_form;
      }

      setConditionsList([...conditionsList, cond]);
    }
  };

  const handleConditionDelete = (itemToDelete) => {
    const updatedConditionsList = conditionsList.filter((item) => item !== itemToDelete);
    setConditionsList(updatedConditionsList);
  }

  const saveApprover = (approver) => {
    approver.approver_type_label = approver.approver_type === "user" ? language.APPROVER : approver.approver_type === "group" ? language.APPROVERS_GROUP : language.TECH_GROUP;

    switch (approver.approver_type) {
      case "user":
        let userConditionalFiltered = fullApproversList.filter(val => {
          return val.id_user === approver.approver_value_user;
        });
        approver.approver_value = approver.approver_value_user;
        approver.approver_label = userConditionalFiltered[0]?.name;
        break;
      case "group":
        let groupConditionalFiltered = fullApproversList.filter(val => {
          return val.id_group_users === approver.approver_value_group;
        });
        approver.approver_value = approver.approver_value_group;
        approver.approver_label = groupConditionalFiltered[0]?.name;
        break;
    }

    setApproversList([...approversList, approver].sort((a, b) => a.order - b.order));
  };

  const handleApproverDelete = (itemToDelete) => {
    const updatedApproversList = approversList.filter((item) => item !== itemToDelete);
    setApproversList(updatedApproversList);
  }

  const saveWorkflow = (obj) => {
    let payload = {
      "name": obj.name,
      "description": obj.description,
      "id_company": obj.id_company,
    }

    setErrorMessage(null);

    if (conditionsList.length > 0) {
      payload.conditions = conditionsList.map(cond => {
        const clonedCond = { ...cond };
        clonedCond.condition = clonedCond.conditional_id,
        clonedCond.value = Array.isArray(clonedCond.value) ? '[' + clonedCond.value.toString() + ']' : clonedCond.value
        delete clonedCond.conditional_id;
        delete clonedCond.conditional_label;
        delete clonedCond.value_label;
        delete clonedCond.form_field_label;
        return clonedCond;
      });
    } else {
      setErrorNoConditions(true);
    }

    if( approversList.length > 0 ) {
      payload.approvers = approversList.map((approver, i) => {
        const clonedApprover = { ...approver };
        clonedApprover.position = parseInt(clonedApprover.order);
        clonedApprover.type = clonedApprover.approver_type;
        clonedApprover.type_id = clonedApprover.type !== 'tech_group' ? clonedApprover.approver_value : 0;
        delete clonedApprover.approver_label;
        delete clonedApprover.approver_type;
        delete clonedApprover.approver_type_label;
        delete clonedApprover.approver_value;
        delete clonedApprover.order;
        return clonedApprover;
      });
    } else {
      setErrorNoApprovers(true);
    }

    if( conditionsList.length > 0 && approversList.length > 0 ) {
      setErrorNoConditions(false);
      setErrorNoApprovers(false);

      let requestType = 'post';
      let endpoint = `${Constants.APIEndpoints.CHANGE}/approval/rules`;

      if ( props.value?.id_change_approval_rules ) {
        requestType = 'put';
        endpoint = `${Constants.APIEndpoints.CHANGE}/approval/rules/${props.value.id_change_approval_rules}`;
      }

      action
        .execute(
          requestType,
          endpoint,
          '',
          payload
        ).then((res) => {
          Store.toggleAlert(
            true,
            language.ALERT_INSERT_SUCCESS,
            'success'
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }, (error) => {
          let messageError = language.ERROR_REQUEST;
          if( error.data?.message === "E500" ) {
            messageError = language.ERROR_CODE.E500;
          }
          Store.toggleAlert(
            true,
            messageError,
            'error'
          );
        });
    }
  };

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <CommonForm
        tags={tags}
        values={data}
        fields={[
          {
            col: 6,
            name: 'id_company',
            label: language.COMPANY,
            type: 'select',
            isMulti: true,
            options: companies,
            required: true,
          },
          {
            col: 6,
            name: 'name',
            label: language.NAME,
            type: 'text',
            required: true,
          },
          {
            col: 12,
            type: 'element',
            content: (v, h, submit) => (
              <div>
                {showConditions && (
                  <div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '16px' }}>{language.LOGICAL_CONDITIONS}</div>
                    <CommonForm
                      values={[]}
                      fields={[
                        {
                          col: 4,
                          name: 'conditional_id',
                          label: language.FILTER_CONDITION,
                          type: 'select',
                          options: conditions,
                          required: true,
                          disabled: !selectedCompany
                        },
                        {
                          col: 4,
                          name: 'operator_equals_only',
                          label: language.LOGICAL_CONDITIONS,
                          type: 'select',
                          options: logicalConditionsEqualsOnly,
                          required: (logicalConditionsType === "equals_only"),
                          visible: (logicalConditionsType === "equals_only")
                        },
                        {
                          col: 4,
                          name: 'operator_equals',
                          label: language.LOGICAL_CONDITIONS,
                          type: 'select',
                          options: logicalConditionsEquals,
                          required: (logicalConditionsType === "equals"),
                          visible: (logicalConditionsType === "equals")
                        },
                        {
                          col: 4,
                          name: 'operator_time',
                          label: language.LOGICAL_CONDITIONS,
                          type: 'select',
                          options: logicalConditionsTime,
                          required: (logicalConditionsType === "time"),
                          visible: (logicalConditionsType === "time")
                        },
                        {
                          col: 4,
                          name: 'operator_list',
                          label: language.LOGICAL_CONDITIONS,
                          type: 'select',
                          options: logicalConditionsList,
                          required: (logicalConditionsType === "list"),
                          visible: (logicalConditionsType === "list")
                        },
                        {
                          col: 4,
                          name: 'operator_contains',
                          label: language.LOGICAL_CONDITIONS,
                          type: 'select',
                          options: logicalConditionsContains,
                          required: (logicalConditionsType === "contains"),
                          visible: (logicalConditionsType === "contains")
                        },
                        {
                          col: 4,
                          name: 'value',
                          label: language.VALUE,
                          type: conditionValueType,
                          required: true,
                          options: conditionValues,
                          disabled: !selectedCompany,

                          textinit: language.VALUE,
                          textlabel: language.VALUE,
                          fieldquery: 'search',
                          method: 'POST',
                          methodGetAll: 'POST',
                          route: valuesEndpoint.route,
                          routeAll: valuesEndpoint.routeAll,
                          routeGetCount: valuesEndpoint.routeGetCount,
                          filter: valuesEndpoint.filter,
                          filterAll: valuesEndpoint.filterAll,
                          fieldvaluedb: valuesEndpoint.fieldvaluedb,
                          fieldlabeldb: valuesEndpoint.fieldlabeldb,
                          showEmail: valuesEndpoint.showEmail,
                          isMulti: conditionValueType === 'autocomplete' ? valuesEndpoint.isMulti : false,
                          detail: true,
                        },
                        {
                          col: 4,
                          name: 'conditional_hour',
                          label: language.HOUR,
                          type: 'time',
                          required: showHour,
                          visible: showHour
                        },
                        {
                          col: 4,
                          name: 'form_field',
                          label: language.FORM_FIELD,
                          type: 'select',
                          options: templateFormData.map((g, index) => ({
                            label: g.label,
                            value: index
                          })),
                          required: (showFormFields),
                          visible: (showFormFields)
                        },
                        {
                          col: templateFormData.length > 0 ? 4 : 0,
                          type: 'element',
                          name: 'value_form',
                          content: (v, h, submit) =>
                            getFieldForm(templateFormData[selectFieldFormData]),
                        },
                        {
                          col: 12,
                          type: 'element',
                          content: (v, h, submit) => (
                            <div style={
                              {
                                display: 'flex',
                                marginBottom: '32px',
                                marginTop: '-20px'
                              }
                            }>
                              {
                                <Button
                                  label={language.ADD}
                                  variant="normal"
                                  style={{ marginTop: '15px' }}
                                  color="success"
                                  onClick={() => {
                                    setTimeout(() => {
                                      submit();
                                    }, 1000);
                                  }}
                                />
                              }
                            </div>
                          ),
                        },
                      ]}
                      onChangeField={onChangeField}
                      hiddenSubmit={true}
                      button={
                        {
                          icon: 'fas fa-plus',
                          label: language.ADD
                        }
                      }
                      onSubmit={(v) => {
                        saveCondition(v);
                      }}
                    />
                    {errorFormCondition && (
                      <div>
                        <p style={{fontSize: '0.75rem', color: '#f44336', marginLeft: '14px', marginRight: '14px' }}>{language.FILL_THE_FIELD}</p>
                      </div>
                    )}
                    {conditionsList.length > 0 && (
                      <div>
                        <div className="slaRule" style={
                          {
                            marginBottom: '8px'
                          }
                        }>
                          {conditionsList.map((r, i) => (
                            <div
                              style={
                                {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  borderBottom: i+1 !== conditionsList.length ? '1px solid #fff' : 'none',
                                  width: '100%',
                                  alignItems: 'center',
                                  background: '',
                                  padding: i+1 !== conditionsList.length ? '1px 1px 8px' : '1px 1px 0px',
                                  marginBottom: i+1 !== conditionsList.length ? '8px' : '0px'
                                }
                              }
                            >
                              <div style={{ width: '100%', display: 'flex' }}>
                                <span style={{ width: '98%' }}>
                                  <div>
                                    {r.conditional_label}
                                    {r.conditional_id === "template_forms_fields" ? ' ' + r.value_label + ' "' + r.form_field_label + '"' : null}
                                    {" "}
                                    <strong>{
                                      r.operator === "equals" ? language.EQUAL_TO.toLowerCase() :
                                      r.operator === "not_equals" ? language.DIFFERENT_FROM.toLowerCase() :
                                      r.operator === "in_list" ? language.IN_LIST.toLowerCase() :
                                      r.operator === "not_in_list" ? language.OFF_THE_LIST.toLowerCase() :
                                      r.operator === "contains" ? language.CONTAIN.toLowerCase() :
                                      r.operator === "does_not_contains" ? language.DONT_COUNT.toLowerCase() :
                                      r.operator === "before" ? language.BEFORE.toLowerCase() :
                                      language.AFTER.toLowerCase()
                                    }</strong>
                                    {" "}
                                    {r.conditional_id === "template_forms_fields" ? r.form_value : r.value_label}
                                  </div>
                                </span>

                                <span style={{ width: '2%' }}>
                                  <Button
                                    variant="circle"
                                    icon="fas fa-trash-alt"
                                    color="danger"
                                    label={language.DELETE}
                                    onClick={() => handleConditionDelete(r)}
                                  />{' '}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {errorNoConditions && (
                      <div>
                        <p style={{fontSize: '0.75rem', color: '#f44336', marginLeft: '14px', marginRight: '14px' }}>{language.FILL_THE_FIELD}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
          },
          {
            col: 12,
            type: 'element',
            content: (v, h, submit) => (
              <div>
                {showConditions && (
                  <div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '16px' }}>{language.APPROVERS}</div>
                    <CommonForm
                      values={[]}
                      fields={[
                        {
                          col: 4,
                          name: 'order',
                          label: language.ORDER,
                          type: 'number',
                          required: true,
                          disabled: !selectedCompany
                        },
                        {
                          col: 4,
                          name: 'approver_type',
                          label: language.TYPE_OF_APPROVER,
                          type: 'select',
                          options: [
                            {
                              label: language.APPROVER,
                              value: 'user'
                            },
                            {
                              label: language.APPROVERS_GROUP,
                              value: 'group'
                            },
                            {
                              label: language.TECH_GROUP,
                              value: 'tech_group'
                            }
                          ],
                          required: true,
                          disabled: !selectedCompany
                        },
                        {
                          col: 4,
                          name: 'approver_value_user',
                          label: language.VALUE,
                          type: 'autocomplete',
                          required: showUsersList,
                          disabled: !selectedCompany,
                          visible: showUsersList,

                          textinit: language.VALUE,
                          textlabel: language.VALUE,
                          fieldquery: 'search',
                          method: 'POST',
                          methodGetAll: 'POST',
                          route: valuesApproverUsersEndpoint.route,
                          routeAll: valuesApproverUsersEndpoint.routeAll,
                          routeGetCount: valuesApproverUsersEndpoint.routeGetCount,
                          filter: valuesApproverUsersEndpoint.filter,
                          filterAll: valuesApproverUsersEndpoint.filterAll,
                          fieldvaluedb: valuesApproverUsersEndpoint.fieldvaluedb,
                          fieldlabeldb: valuesApproverUsersEndpoint.fieldlabeldb,
                          showEmail: valuesApproverUsersEndpoint.showEmail,
                          isMulti: valuesApproverUsersEndpoint.isMulti,
                          detail: true,
                        },
                        {
                          col: 4,
                          name: 'approver_value_group',
                          label: language.VALUE,
                          type: 'autocomplete',
                          required: showGroupsList,
                          disabled: !selectedCompany,
                          visible: showGroupsList,

                          textinit: language.VALUE,
                          textlabel: language.VALUE,
                          fieldquery: 'search',
                          method: 'POST',
                          methodGetAll: 'POST',
                          route: valuesApproverGroupsEndpoint.route,
                          routeAll: valuesApproverGroupsEndpoint.routeAll,
                          routeGetCount: valuesApproverGroupsEndpoint.routeGetCount,
                          filter: valuesApproverGroupsEndpoint.filter,
                          filterAll: valuesApproverGroupsEndpoint.filterAll,
                          fieldvaluedb: valuesApproverGroupsEndpoint.fieldvaluedb,
                          fieldlabeldb: valuesApproverGroupsEndpoint.fieldlabeldb,
                          showEmail: valuesApproverGroupsEndpoint.showEmail,
                          isMulti: valuesApproverGroupsEndpoint.isMulti,
                          detail: true,
                        },
                        {
                          col: 12,
                          type: 'element',
                          content: (v, h, submit) => (
                            <div style={
                              {
                                display: 'flex',
                                marginBottom: '32px',
                                marginTop: '-20px'
                              }
                            }>
                              {
                                <Button
                                  label={language.ADD}
                                  variant="normal"
                                  style={{ marginTop: '15px' }}
                                  color="success"
                                  onClick={() => {
                                    setTimeout(() => {
                                      submit();
                                    }, 1000);
                                  }}
                                />
                              }
                            </div>
                          ),
                        },
                      ]}
                      onChangeField={onChangeField}
                      hiddenSubmit={true}
                      button={
                        {
                          icon: 'fas fa-plus',
                          label: language.ADD
                        }
                      }
                      onSubmit={(v) => {
                        saveApprover(v);
                      }}
                    />
                    {approversList.length > 0 && (
                      <div>
                        <div className="slaRule" style={
                          {
                            marginBottom: '8px'
                          }
                        }>
                          {approversList.map((r, i) => (
                            <div
                              style={
                                {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  borderBottom: i+1 !== approversList.length ? '1px solid #fff' : 'none',
                                  width: '100%',
                                  alignItems: 'center',
                                  background: '',
                                  padding: i+1 !== approversList.length ? '1px 1px 8px' : '1px 1px 0px',
                                  marginBottom: i+1 !== approversList.length ? '8px' : '0px'
                                }
                              }
                            >
                              <div style={{ width: '100%', display: 'flex' }}>
                                <span style={{ width: '98%' }}>
                                  <div>
                                    {r.order}{" - "}{r.approver_type_label}{" "}
                                    {r.approver_type !== "tech_group" && (
                                    <>
                                      <strong>{language.EQUAL_TO.toLowerCase()}</strong> {r.approver_label}
                                    </>
                                    )}
                                  </div>
                                </span>

                                <span style={{ width: '2%' }}>
                                  <Button
                                    variant="circle"
                                    icon="fas fa-trash-alt"
                                    color="danger"
                                    label={language.DELETE}
                                    onClick={() => handleApproverDelete(r)}
                                  />{' '}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {errorNoApprovers && (
                      <div>
                        <p style={{fontSize: '0.75rem', color: '#f44336', marginLeft: '14px', marginRight: '14px' }}>{language.FILL_THE_FIELD}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
          },
          {
            col: 12,
            name: 'description',
            label: language.DESCRIPTION,
            type: 'text',
            required: true,
          },
          {
            col: 4,
            type: 'element',
            visible: errorMessage,
            content: (v, h, submit) => (
              <div>
                <p style={{fontSize: '0.75rem', color: '#f44336', marginLeft: '14px', marginRight: '14px' }}>{errorMessage}</p>
              </div>
            )
          }
        ]}
        onChangeField={onChangeField}
        onSubmit={(v) => saveWorkflow(v)}
      />
    </CommonPage>
  );
};

export default index;
