import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import { Card } from '@material-ui/core';
import Help from '../../common/components/Help';

export default class InventoryConfigDevice extends Component {
  render() {
    let { handlePage, language, values } = this.props;

    return (
      <PageLayout
        icon={
          <div className="inventaryIcon" style={{ width: 40, height: 40 }} />
        }
        title={language.INVENTORY}
        subtitle={language.DEVICE_CONFIG}
        rightElements={[
          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            label={language.RETURN}
            color='primaryGradient'
            onClick={() => handlePage('list')}
          />
        ]}
      >
        <Card>
          <h2>
            {values.id_device}-{values.name}
          </h2>
        </Card>
      </PageLayout>
    );
  }
}
