import Store from '@data/Store';
const { language } = Store;

export default {
  perspective: () => [
    { icon: 'fas fa-cash-register', value: 1, label: language.FINANCIAL },
    { icon: 'fas fa-user', value: 2, label: language.CLIENT },
    { icon: 'fas fa-chart-line', value: 3, label: language.PROCESS },
    { icon: 'fas fa-brain', value: 4, label: language.LEARNING },
  ],
  bscGoalTi: (fk_id_bsc, bsctialls) => {
    return bsctialls
      .filter((bsctialls) => bsctialls.id_bsc == fk_id_bsc)
      .map((e) => ({
        value: e.id_bsc_goal_ti,
        label: e.name,
      }));
  },
  projects: (projects) => {
    return projects
      .filter(
        (c) =>
          c.percent < 100 &&
          c.deleted != true &&
          c.canceled != true
      )
      .map((e) => ({ value: e.id_project, label: e.name }))},
};
