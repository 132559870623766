import React, {memo} from 'react';
import CommonTable from '@common/CommonTable';
import classnames from 'classnames';
import Button from '@common/Button'; 
const crypto = require('crypto');

import Store from '@data/Store';

const { language } = Store;

export default memo(({onOpenModal, data=[]})=>{

	
	return(
		<div id="table1_sprint" >
		<CommonTable
			paginationTop={false}
			beforeBar={
				<Button
					icon='fas fa-plus'
					label={language.ADD}
					color='warning'
					variant='circle'
					className='mr-3'
					onClick={onOpenModal}
				/>
			}
			col={[
				{key:'id', label:'ID'},
				{key:'subject', label:language.SUBJECT},
				{key:'tech', label: language.REQUESTER},
				{ key: 'effort', label: language.ACTIVITY_TIME },
				
				{ key: 'effort_value', label: language.ACTIVITY_EFFORT, noOrder: true},
				{key:'priority', label: language.PRIORITY},
				{ key: 'delivery_date', label: language.DELIVERY_DATE, noOrder: true},
				{key:'tech_sprint', label: language.TECH, noOrder: true},
				{key: 'company_name', label: language.COMPANY},
				{key:'del', label:''},
			]}
			data={data}
		/>
		</div>
	)
})