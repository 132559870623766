import React, { useEffect, useState, useReducer ,useCallback} from 'react';
import moment from 'moment';
import MemoryIcon from '@material-ui/icons/Memory';
import CommonTable from '@common/CommonTable';
import DeviceConf from '../monitor/DeviceConf';
import Progress from '@common/Progress';
import Button from '@common/Button';
import ReactHighcharts from 'react-highcharts';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CommonForm from '@common/CommonForm2';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Details from './Details';
import Link from './LinkGI'
import './index.css'
import CommonDelete from '../../common/CommonDelete';
import DeviceForm from '../device/DeviceForm'
const crypto = require('crypto')
import { observer } from 'mobx-react-lite';
import Dialog from '@common/Dialog';
import { company } from '../dashboard/Graph';
import { Capacity } from './Graph';
import Status from './Status'
import DownloadFiles from '../../util/DownloadFiles';
var CryptoJS = require('crypto-js')
const TableType = observer(({
  countOk,
  countYellow,
  countRed,
  monitorGroups,
  companyId,
  countTotal,
  devices,
  ioLinks,
  action,
  language,
  APIEndpoints,
  companies,
  ioServers,
  page,
  devicelast,
  refresh_,
  store,
  startAssessment,
  finishAssessment,
  idgroup
}) => {
  const [groupType, setGroupType] = useState([]);
  const [monitor, setMonitores] = useState([]);
  const [devicea, setDevicea] = useState([]);
  const [groups, setGroups] = useState([]);
  let [filter, setFilter] = useState([0]);
  const [grupos, setGrupos] = useState([]);
  const [remove, setRemove] = useState(null);
  const [modalOptions, setModalOptions] = useState({});
  const [edit, setEdit] = useState(null);
  const [valueEdit, setValueEdit] = useState(null)
  const [editDevice, setEditDevice] = useState(null);
  const [arrayRemove, setarrayRemove] = useState([]);
  const [companiess, setCompaniess] = useState([]);
  const [hist, setModalHist] = useState([]);
  const [dataHistorico, setDataHistorico] = useState([]);
  const [filterB, setFilterB] = useState(1);
  const [d, setD] = useState(0);
  const [allDevices, setAllDevices] = useState([]);
  const [di, setDi] = useState(moment());
  const [df, setDf] = useState(moment());
  const [type, setType] = useState('');
  const [anchorEl, setanchorEl] = useState(null);
  const [device_, setdevice_] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const [indexOpen, setIndexOpen] = useReducer((state, value) => (
    state.includes(value) ? state.filter(e => e != value) : [value]
  ), [])

  const handleModal = useCallback((opts) => {
    setModalOpen(!modalOpen);
    setModalOptions(opts)

  }, []);
  const handleClick = (event, d) => {
    setdevice_(d);
    setanchorEl(event.currentTarget)

    //  this.setState({ anchorEl: event.currentTarget, d: d });
  };


  const handleClose = () => {

    setanchorEl(null)
    // this.setState({ : nulanchorEll });
  };
  const getHistoric = (id, t, excel) => {
    if (id) {
      setD(id);
    }
    if (t) {
      setType(t);
    }
    ///api/monitor/historic/:id


    let da = { fk_id_device: id || d, di: moment(di).format('YYMMDD'), df: moment(df).format('YYMMDD') }

    if(excel){
      da.excel = true
    }


    action.execute('post', APIEndpoints.MONITOR + '/historic', '', da).then(c => {

    if(!excel){
      if ((t || type) != 'link') {

        let colorsBar =
          [
            '#02F0DE',
            '#02F079',
            '#A7F002',
            '#E7F002',
            '#F0B002',
            '#F05E02',
            '#EE0D81', '#019FC2', '#E7F002']



        let cat = t == 'link' ? c.data.map(v => moment(v.dt_cad).format('DD-MM HH:mm')) : c.data.filter(v => v.type == 'cpu-stats').map(v => moment(v.dt_cad).format('DD-MM HH:mm'))


        let ids = c.data.filter(v => v.type == 'fs-list').map(o => o.unidade)

        let unique = ids.filter((item, i, ar) => ar.indexOf(item) === i);




        let data = [

          {
            name: language.PROCESSOR,
            zIndex: 2,
            color: '#019FC2',
            data: c.data.filter(v => v.type == 'cpu-stats').map(v => parseInt(v.percent || 0))
          },
          {
            name: language.MEMORY,
            zIndex: 2,
            color: '#F0B002',
            data: c.data.filter(v => v.type == 'mem-info').map(v => parseInt(v.percent || 0))
          }



        ]

        unique.map((uni, i) => {

          data.push(
            {
              name: `${language.DISK} - uni`,
              zIndex: 2,
              color: colorsBar[i],
              data: c.data.filter(b => String(b.unidade) == String(uni)).map(v => parseInt(v.percent || 0))
            }

          )
        })





        setDataHistorico({
          categories: cat, //lorem
          data: data


        }


        )

      } else {




        let colorsBar =
          [
            '#02F0DE',
            '#02F079',
            '#A7F002',
            '#E7F002',
            '#F0B002',
            '#F05E02',
            '#EE0D81', '#019FC2', '#E7F002']
        let cat = c.data.map(v => moment(v.dt_cad).format('DD/MM HH:mm'))


        let data = [

          {
            name: 'Link',
            zIndex: 2,
            color: '#019FC2',
            data: c.data.map(v => parseInt(v.used || 0))
          }]

        setDataHistorico({
          categories: cat, //lorem
          data: data
        }
        )






      }
    } else {
      setTimeout(() => {
        DownloadFiles(
          APIEndpoints.REQUESTS + '/downloadmonitorexport/' + store.getUserLoged().id + '/' + c.data[0].file,
          c.data[0].file
        );
      }, 3000);
    }
  })

  setModalHist(true)

  }

  const editDevice_ = (v) => {
    action.execute(
      'get',
      `${APIEndpoints.DEVICE + '/' + v.id_device}`,
      ''
    )
      .then(res => {
        setValueEdit(res.data[0]);

      })
  }
  const getUsers = (device) => {
    action.execute(
      'get',
      APIEndpoints.MONITOR + '/' + device + '/users',
      'users'
    );
  }

  const onConfSubmit = (conf, confTrigger, users, edit) => {

    action.execute(
      'post',
      APIEndpoints.MONITOR + '/device',
      'monitor',
      { conf: conf, confTrigger: confTrigger, users: users, edit: edit }
    ).then(c => {

      let index = monitor.findIndex(f => f.id_device == c.data.id_device)
      monitor[index] = c.data
        setMonitores(monitor)

    })
    setEdit(null)
    //this.handlePage('dash');

  }
  const onDelete = () => {
    let v = arrayRemove;
    action.execute('get', APIEndpoints.MONITOR + '/remove/' + remove.id_rel_monitor_group_device, '').then(c => {

      v.push(remove.id_rel_monitor_group_device)
      setarrayRemove(v)
      loadTable()
      setRemove(null)
    })
  }

  const getcompany = (comp, id) => {

    try {
      return comp.filter(v => v.id_company == id)[0].name
    } catch (e) {
      return ""

    }

  }


  const decryp = (aMsg = '') => {
    try{
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, "");
      let decipher, tRet;
      decipher = crypto.createDecipher("aes-256-cbc", aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
      tRet += decipher.final("utf8");

        return tRet;
    }catch(e){
      return {}
    }

  }
  useEffect(() => {

    action.execute('get', APIEndpoints.MONITOR, 'monitor').then(c => {
      setMonitores(c.data)
    })




     action.execute('get',  APIEndpoints.COMPANY ).then(h=>{

      setCompaniess( h.data)
    })
    action.execute('get', APIEndpoints.DEVICETYPE, 'devicetype');
    action.execute('get', APIEndpoints.CONTRACT + 'table', 'contracts');

  }, [])


  const onFormSubmit = (values) => {

    if (values.user_cad.length == 0 || values.contracts.length == 0) {
      store.toggleAlert(true, language.INFORM_REQUIRED_FIELDS, 'error');
    } else {
      let method = 'put'
      let url =
        APIEndpoints.DEVICE + '/' + values.id_device

      action.execute(method, url, ' ', values, 'id_device').then(
        d => {
          setValueEdit(null)
        }
      )

    }




  }


  function loadTable(type, devicesalert,idgroup) {

    if (!type) {
      let list = monitorGroups
        .filter(mg => companyId > 0 ? mg.id_company === companyId : mg.id_company > 0)
        .reduce((arr, i) => {


          i.groups.filter(g=> idgroup >0 ?g.id_group ==  idgroup :g.id_group> 0 ).map((e) => {
            e.devices.filter(v => (v.type == 'link' || v.type == 'server') &&
              arrayRemove.includes(v.id_rel_monitor_group_device) == false
            ).map((e) => {

              arr.push({ companyId: e.fk_id_company, ...e });
            });
          });

          return arr;
        }, [])
      const obj = filterGroup(list, 'server');


      setGroupType([obj]);
    }
    else {
      let grupos = [];
      let list = monitorGroups
        .filter(mg => companyId > 0 ? mg.id_company === companyId : mg.id_company > 0)
        .reduce((arr, i) => {

          i.groups.filter(g=> idgroup >0 ?g.id_group ==  idgroup :g.id_group> 0 ).map((gro) => {
            grupos.push(gro.name_group)
            gro.devices.filter(v => (v.type == 'link' || v.type == 'server') &&
              arrayRemove.includes(v.id_rel_monitor_group_device) == false
            ).map((e) => {

              arr[gro.name_group] = arr[gro.name_group] || [];
              arr[gro.name_group].push({ namegroup: gro.name_group, companyId: e.fk_id_company, ...e });


            });
          });




          return arr;
        }, [])

      //   const obj = filterGroup(list, 'server');

      setGrupos(grupos)
      setGroupType(list);
    }
  }

  function filterGroup(list, type) {
    let ok = 0;
    let warning = 0;
    let danger = 0;
    let total = 0;
    let percent = 0;




    return { devices: list };

  }


  const totaldevices = (grupo, item) => {
    try {
      return grupo[item].length
    } catch (e) {
      return 0
    }
  }


  useEffect(() => {

    let devices = [...countRed, ...countYellow].map(v => v.id_device)
    setDevicea(devices)
    loadTable(undefined, devices,idgroup)

  }, [monitorGroups, page,idgroup])

  window._groupType = groupType;

  // console.log("XUXAX",monitor);
  var compIcon = [
    'Cpu',
    language.MEMORY,
    language.DISK,
  ];

  const getAgent = (id) => {
    try {
      let device = monitor.filter((monitor) => monitor.id_device == id)[0]
      if (device['sys-info']) {
        return device['sys-info'].agentVersion
      } else {
        return ''
      }
    } catch (e) {
      return ''
    }
  }

  const getOSVersion = (id) => {
    try {
      let device = monitor.filter((monitor) => monitor.id_device == id)[0]
      if (device['sys-info']) {
        return device['sys-info'].version
      } else {
        return ''
      }
    } catch (e) {
      return ''
    }
  }


  const getOSName = (id) => {
    try {
      let device = monitor.filter((monitor) => monitor.id_device == id)[0]
      if (device['sys-info']) {
        return device['sys-info'].description
      } else {
        return ''
      }
    } catch (e) { }
  }


  let [show, setShow] = useReducer((state, v) => state === v ? null : v, null);
  let open = !!anchorEl;

  let menus =
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {[
        {
          icon: 'fas fa-history',
          label: language.HISTORIC,
          color: 'var(--warning)',
          send: () => {

            getHistoric(device_.id_device, device_.type)
            handleClose()
          },
          visible: true
        },
        {
          icon: 'far fa-trash-alt',
          label: language.DELETE,
          color: 'var(--danger)',
          send: () => { setRemove(device_); handleClose() },
          visible: store.getUserLoged().role != 'solicitant'

        },
        {
          icon: 'far fa-edit',
          label: language.EDIT_CONFIGURATION,
          color: 'var(--primary)',
          visible: store.getUserLoged().role != 'solicitant',
          send: () => { setEdit(device_); handleClose() }
        },
        {
          icon: 'fas fa-cloud-download-alt',
          label: language.UPDATE_AGENT,
          color: 'var(--primary)',
          visible: store.getUserLoged().role != 'solicitant',
          send: () =>
            handleModal({
              msg: language.CONFIRM_UPDATE_AGENT, confirm: () => {   ioServers.emit("updateag",device_.id_device) ; setModalOpen(false);  }
            })

        }
        ,

        {
          icon: 'fas fa-times-circle',
          label: language.EDIT_DEVICE,
          color: 'var(--primary)',
          send: () => { editDevice_(device_); handleClose() },
          visible: store.getUserLoged().role != 'solicitant'
        }
      ].filter(v => v.visible == true).map(e => (
        <MenuItem onClick={e.send}>
          <i className={e.icon} style={{ color: e.color }} />
          <span
            style={{ fontSize: 15, color: 'var(--primary)' }}
            className="ml-2"
          >
            {e.label}
          </span>
        </MenuItem>
      ))}
    </Menu>;


  return (

    <div className="Item d-flex align-items-center justify-content-start content-min m-auto">

      {filter == 1 ?


        <CommonTable
          pagination={true}
          bar={true}

          loadSearch={true}
          beforeBar2={
            <div className=" d-flex align-items-center">
              {[
                language.LIST_BY_DEVICES,
                language.LIST_BY_GROUPS
              ].map((e, i) => (
                <a
                  href="#"
                  className={'Gi2cssTableMonitor'}
                  style={{
                    backgroundColor:
                      filter.includes(i) ? 'var(--warning)' : 'transparent'
                  }}
                  onClick={() => {

                    setFilter([i])
                    if (i == 1) {
                      loadTable(i, devicea)
                    } else {
                      loadTable(undefined, devicea)
                    }
                  }}>
                  <span>{e}</span>
                </a>))
              }</div>}
          className='mt-2 table-tec'
          col={[
            { key: 'btn', label: '', thConfig: true, style: { width: '5%' } },
            { key: 'user', label: language.GROUP, thConfig: true, style: { width: '45%' } },
            { key: 'total', label: language.TOTAL, thConfig: true, style: { width: '50%' } }
          ]}
          AppBarProps={{
            dark: true,
            className: 'py-0 pr-0',
            style: {
              borderRadius: '10px 10px 0 0'
            }
          }}

          data={grupos.flatMap((e, i) => [
            {
              btn: (
                <div className='btn' onClick={() => setShow(i)}>
                  <i className={show === i ? 'fas fa-chevron-down' : 'fas fa-chevron-right'} />

                </div>
              ),
              user: e,
              total: totaldevices(groupType, e)
            },
            show === i ? {
              ConfigCell: {
                cols: {
                  col_0: { colSpan: 5, style: { paddingLeft: 30, paddingRight: 30 } }
                }
              },
              'btn':
                <CommonTable

                  rowsPerPageOptions={[40, 80, 100, 150]}
                  onPosition={refresh_}
                  col={[

                    {
                      key: 'action',
                      label: language.ACTION,
                      thConfig: true,
                      style: { width: 10 }
                    },
                    {
                      key: 'nome',
                      label: language.NAME,
                      thConfig: true,
                      style: { width: 100 }
                    },
                    {
                      key: 'alias',
                      label: 'Alias',
                      thConfig: true,
                      style: { width: 150 }
                    },
                    {
                      key: 'company',
                      label: language.COMPANY,
                      thConfig: true,
                      style: { width: 170 }
                    },

                    {
                      key: 'remote',
                      label: language.ACCESS,
                      thConfig: true,
                      style: { width: 5 }
                    },
                    {
                      key: 'ip',
                      label: 'Ip',
                      thConfig: true,
                      style: { width: 100 }
                    },
                    {
                      key: 'info',
                      label: language.VERSION,
                      thConfig: true,
                      style: { width: 100 }
                    },
                    {
                      key: 'status',
                      label: language.STATE

                      ,
                      thConfig: true,
                      style: { width: 5, textAlign: 'left' }
                    },
                    {
                      key: 'temp',
                      label: language.TEMPERATURE
                      ,
                      thConfig: true,
                      style: { width: 5, textAlign: 'left' }
                    },
                    {
                      key: 'proc',
                      label: compIcon[0],
                      thConfig: true,
                      style: { width: 60, textAlign: 'left' }

                    },
                    {
                      key: 'mem',
                      label: compIcon[1],
                      thConfig: true,
                      style: { width: 30, textAlign: 'left' }

                    },


                    {
                      key: 'disk',
                      label: compIcon[2],
                      thConfig: true,
                      style: { width: 70, textAlign: 'left' }

                    },
                    {
                      key: 'osVersion',
                      label: language.OS_VERSION,
                      thConfig: true,
                      style: { width: 5, textAlign: 'center' }

                    },
                    {
                      key: 'osOSName',
                      label: language.OS_RELEASE,
                      thConfig: true,
                      style: { width: 5, textAlign: 'center' }

                    }
                  ]}


                  //      let = {console.log("XXXXXX", groupType)}

                  data={(groupType[e] ? groupType[e] : []).map(g => ({


                    action:


                      <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={event => handleClick(event, g)}
                        style={{ transform: 'rotate(90deg)', color: 'var(--primary)' }}
                      >
                        <MoreVertIcon />


                      </IconButton>,




                    nome: g.name,
                    alias: g.alias,
                    company: g.companyname,
                    remote:

                    store.getUserLoged().role !='Solicitant' ?
                    <Button
                      icon="fas fa-play"
                      variant="circle"
                      color="success"
                      size={0.6}
                      zoom={0.6}
                      onClick={() => startAssessment(devices.filter(v => v.id_device == g.id_device)[0])}
                      style={{ fontSize: 14 }}

                      outlined
                    />:null,

                    ip: g.ip,
                    info: getAgent(g.id_device),
                    status: g.type == 'link' ?
                      <Link
                        device={g}
                        setId={() => alert('')}
                        setDevice={() => aler('')}
                        handlePage={() => alert('')}
                        language={language}
                        anchorEl={() => alert('')}
                        setAnchorEl={() => alert('')}
                        ioLinks={ioLinks}
                        onDettachDevice={() => alert('')}
                        handleModal={() => alert('')}
                      /> : <Status device={g} ioServers={ioServers} items={g.id_device.toString()} ></Status>,
                    temp: <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={5} monitor={monitor} device={g} ></Details>,
                    proc: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={1} monitor={monitor} device={g} ></Details>,
                    mem: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={2} monitor={monitor} device={g} ></Details>,
                    os: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={4} monitor={monitor} device={g} ></Details>,
                    disk: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={3} monitor={monitor} device={g} ></Details>
                    , osversion: getOSVersion(g.id_device),
                    osname: getOSName(g.id_device),

                  }))}
                />



            } : null
          ]).filter(Boolean)}></CommonTable>

        :

        <CommonTable

          AppBarProps={{
            dark: true,
            className: 'py-0'
          }}
          loadSearch={true}
          onPosition={refresh_}
          beforeBar2={

            <div className=" d-flex align-items-center">
              {[
                language.LIST_BY_DEVICES,
                language.LIST_BY_GROUPS,
              ].map((e, i) => (
                <a
                  href="#"
                  className={'Gi2cssTableMonitor'}
                  style={{
                    backgroundColor:
                      filter.includes(i) ? 'var(--warning)' : 'transparent'
                  }}
                  onClick={() => {

                    setFilter([i])
                    if (i == 1) {
                      loadTable(i)
                    } else {
                      loadTable()
                    }
                  }}>
                  <span>{e}</span>
                </a>))
              }</div>}



          rowsPerPageOptions={[40, 80, 100, 150]}
          searchColumn
          col={[
            {
              key: 'action',
              label: language.ACTIONS,
              thConfig: true,
              style: { width: 10 }
            },

            {
              key: 'nome',
              label: language.NAME,
              thConfig: true,
              style: { width: 100 }
            },
            {
              key: 'alias',
              label: 'Alias',
              thConfig: true,
              style: { width: 150 }
            },
            {
              key: 'company',
              label: language.COMPANY,
              thConfig: true,
              style: { width: 100 }
            },
            {
              key: 'remote',
              label: language.ACCESS,
              thConfig: true,
              style: { width: 5 }
            },
            {
              key: 'ip',
              label: 'Ip',
              thConfig: true,
              style: { width: 100 }
            },
            {
              key: 'info',
              label: language.VERSION,
              thConfig: true,
              style: { width: 100 }
            },
            {
              key: 'status',
              label: language.STATE
              ,
              thConfig: true,
              style: { width: 5, textAlign: 'left' }
            },
            {
              key: 'temp',
              label: language.TEMPERATURE
              ,
              thConfig: true,
              style: { width: 5, textAlign: 'left' }
            },
            {
              key: 'proc',
              label: compIcon[0],
              thConfig: true,
              style: { width: 60, textAlign: 'left' }

            },


            {
              key: 'mem',
              label: compIcon[1],
              thConfig: true,
              style: { width: 30, textAlign: 'left' }

            },
            {
              key: 'disk',
              label: compIcon[2],
              thConfig: true,
              style: { width: 70, textAlign: 'left' }
            },
            {
              key: 'osVersion',
              label: language.OS_VERSION,
              thConfig: true,
              style: { width: 5, textAlign: 'center' }

            },
            {
              key: 'osOSName',
              label: language.OS_NAME,
              thConfig: true,
              style: { width: 5, textAlign: 'center' }

            },
          ]}

          data={groupType.length > 0 && groupType[0].devices.map(g => ({
            action: (<span>
              <IconButton
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={event => handleClick(event, g)}
                style={{ transform: 'rotate(90deg)', color: 'var(--primary)' }}
              >
                <MoreVertIcon />


              </IconButton>

            </span>


            ),
            nome: g.name,
            alias: g.alias,
            company: g.companyname,
            remote: store.getUserLoged().role !='Solicitant' ? <Button
              icon="fas fa-play"
              variant="circle"
              color="success"
              size={0.6}
              zoom={0.6}
              onClick={() => startAssessment(devices.filter(v => v.id_device == g.id_device)[0])}
              style={{ fontSize: 14 }}
              outlined
            />:null,
            ip: g.ip,
            info: getAgent(g.id_device),


            status: g.type == 'link' ?
              <Link
                device={g}
                setId={() => alert('')}
                setDevice={() => aler('')}
                handlePage={() => alert('')}
                language={language}
                anchorEl={() => alert('')}
                setAnchorEl={() => alert('')}
                ioLinks={ioLinks}
                onDettachDevice={() => alert('')}
                handleModal={() => alert('')}
              /> :

              <Status
                items={g.id_device.toString()}
                device={g} ioServers={ioServers}  ></Status>

            ,
            temp: <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={5} monitor={monitor} device={g} ></Details>,
            proc: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={1} monitor={monitor} device={g} ></Details>,
            mem: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={2} monitor={monitor} device={g} ></Details>,
            disk: g.type == 'link' ? '' : <Details devicelast={devicelast} items={g.id_device.toString()} ioServers={ioServers} item={3} monitor={monitor} device={g} ></Details>,
            osversion: getOSVersion(g.id_device),
            osname: getOSName(g.id_device),



          }))}
        />
      }

      {remove != null ?
        <CommonDelete
          language={language}

          onDelete={() => onDelete()}
          closeModal={() => setRemove(null)}
          message={language.DO_YOU_WANT_REMOVE_THIS_ITEM}
        />

        : null
      }

      {edit != null ?
        <Dialog
          open={true}
          maxWidth="lg"
          bodyStyle={{ padding: 0, overflowY: 'none' }}
          title={
            language.DEVICE_CONFIGURATION
          }
          onClose={() => setEdit(null)}
        >

          <DeviceConf
            key="deviceConf"
            language={language}
            handlePage={() => setEdit(null)}
            onConfSubmit={onConfSubmit}
            devices={[]}
            editConf={monitor.filter(f => f.id_device == edit.id_device)[0]}
            store={store}

            getUsers={getUsers}
          />
        </Dialog>
        : null
      }


{modalOpen ==true ?  (
          <Dialog
            open={modalOpen}
            bodyStyle={{ padding: 0, overflowY: 'none' }}
            title={
           language.CONFIRMATION
            }
            onClose={() => handleModal()}
          >
            <h2 style={{ color: '#616161', fontWeight: 100 }}>
              {modalOptions.msg}
            </h2>
            {modalOptions.confirm ? (
              <div style={{ float: 'right' }}>
                <Button
                  primary={true}
                  label={language.IM_SURE}
                  onClick={modalOptions.confirm}
                />
              </div>
            ) : null}
          </Dialog>
        ) : null}


      {valueEdit != null ?
        <Dialog
          open={true}
          maxWidth="lg"
          bodyStyle={{ padding: 0, overflowY: 'none' }}
          title={
            language.DEVICE_CONFIGURATION
          }
          onClose={() => setValueEdit(null)}
        >

          <DeviceForm
            language={language}
            handlePage={() => alert('')}
            onFormSubmit={onFormSubmit}
            values={valueEdit}
            companies={companiess}
            contracts={store.contracts}
            usercon_company={store.usercon_company}
            users={store.users}
            user={store.getUserLoged()}
            devicetype={store.devicetype}
            clone={''}
            action={action}
            APIEndpoints={APIEndpoints}
            redirect={() => alert('')}
          />
        </Dialog>
        : null
      }


      {hist == true ?
        <Dialog
          open={true}
          maxWidth="lg"
          bodyStyle={{ padding: 0, overflowY: 'none' }}
          title={
            language.COLLECTION_HISTORY
          }
          onClose={() => setModalHist(null)}
        >
          <div className="div_report_box">


            <CommonForm
              style={{ marginLeft: '20px' }}
              spacing={2}
              individual

              onChangeField={(f, v) => {
                if (f.name == 'di') {
                  setDi(v)
                } else {
                  setDf(v)
                }

              }}
              values={{ di: di, df: df }}

              fields={[
                {
                  col: 5.5,
                  type: 'date',
                  name: 'di',
                  label: language.INITIAL_DATE,
                },
                {
                  col: 5.5,
                  type: 'date',
                  name: 'df',
                  label: language.FINAL_DATE,
                },
                {
                  col: 1,
                  type: 'element',
                  content: (
                   <React.Fragment>
                     <div style={{display:'flex'}}>
                    <Button
                      icon='fas fa-search'
                      variant='circle'
                          label={language.CONFIG}
                      className='mt-3'
                      onClick={v => getHistoric()}
                    />
                    <Button
                    icon='far fa-file-excel'
                    variant='circle'
                    label={language.EXPORT_TO_EXCEL}
                    style={{marginLeft:'5px'}}
                    className='mt-3'
                    onClick={v => getHistoric(undefined,undefined,`export`)}
                  />
                  </div>
                  </React.Fragment>
                  )
                }
              ]}
            />
          </div>
          <div style={{ width: '100%', height: '500px' }}>
            <ReactHighcharts config={Capacity({
              categories: dataHistorico.categories,
              height: 300,
              data: dataHistorico.data
            })} />
          </div>


        </Dialog>
        : null
      }

      {menus}
    </div>


  );
});

export default TableType;
