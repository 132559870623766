import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonForm from '../../common/CommonForm2';

@observer
export default class TicketDescription extends Component {
  render() {
    let { language, onDescriptionTicket, ticket } = this.props;

    let fields = [
      {
        col: 12,
        name: 'description',
        label: '',
        type: 'textEditor',
        multiLine: true
      }
    ];


    let button = {
      
      label: language.SAVE,
     
    };

    return (
      <div className='content px-0'>
        
        <CommonForm
          values={{ description: ticket.description }}
          
          fields={fields}
          button={button}
          onSubmit={ this.props.role ? values => onDescriptionTicket(ticket.id_tickets, values) :undefined}
        />
      </div>
    );
  }
}
