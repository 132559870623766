import React, { useState, useEffect } from 'react';
import { Card, Avatar } from '@material-ui/core';
import Progress from '@common/Progress';
import { observer } from 'mobx-react';
import Store from '@data/Store';
import './AttendanceGADCard.css';
import { Tooltip } from 'react-tippy';

export default observer((props) => {
  let {
    attendance,
    title,
    date,
    version,
    current,
    progressColor,
    onClick,
    name_user,
    user,
  } = props;
  
  let aprovadores = '';

  return (
    <Card
      className="GSDCard content-min w-100"
      onClick={onClick}
      style={{
        borderColor: 'var(--warning)',
        background: user == attendance.fk_id_user ? '#d2d9db !important;' : '',
      }}
    >
      <div className="d-flex align-items-start justify-content-between">
        <p className="ellipsis-line camp-2">{title}</p>        
      </div>
      <div>
        <div className="d-flex justify-content-end">
          {attendance.approval == true ? (
            <Tooltip
              html={'Aprovador ' + aprovadores}
              position="left"
              theme="dark"
              arrow
            >
              <i
                class="fas fa-user-tie"
                style={{
                  marginRight: '8px',
                  color: 'var(--primary)',
                }}
              ></i>
            </Tooltip>
          ) : null}
          {user == attendance.fk_id_user ? (
            <Tooltip
              html={'Você é o solicitante do chamado!'}
              position="left"
              theme="dark"
              arrow
            >
              <i
                class="fas fa-user-check"
                style={{ color: 'rgb(250, 150, 51)' }}
              ></i>
            </Tooltip>
          ) : null}
          {(attendance.observers || []).includes(user) ? (
            <Tooltip
              html={'Você foi incluído como observador!'}
              position="left"
              theme="dark"
              arrow
            >
              <i
                class="fas fa-binoculars"
                style={{ color: 'rgb(250, 150, 51)' }}
              ></i>
            </Tooltip>
          ) : null}
          
        </div>
        <Progress current={current} background={progressColor} />
        <div className="content-info d-flex align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center item-1">
            <Tooltip
              html={
                  <div style={{ color: '#fff', fontSize: 15 }}>
                    <strong>Solicitante: {attendance.name_user}</strong>
                  </div>
                }
              position="left"
              theme="dark"
              arrow
            >
              <Avatar className="avatar mr-1">
                {(name_user || '').slice(0, 2)}
              </Avatar>
            </Tooltip>
            <span className="ellipsis">{name_user}</span>
          </div>
          <div className="d-flex align-items-center item-2">
            <i className="fas fa-calendar-day mr-1" />
            <span>{date}</span>
          </div>
          <span>{'#' + version}</span>
        </div>
      </div>
    </Card>
  );
});
