import RaisedButton from '../../../../../common/RaisedButton';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col } from 'react-grid-system';
import lodash from 'lodash';
import FontIcon from 'material-ui/FontIcon';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import {



  SelectField,
  MenuItem,

  RadioButton,
  Button,
  CircularProgress
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import IconLocationOn from 'material-ui/svg-icons/communication/location-on';
import ReactHighcharts from 'react-highcharts';
import ContainerChart from '../../../graphs/SimplesBarGrap';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Grid, Card, Paper } from '@material-ui/core';
import CommonTable from '@common/CommonTable';
import Dialog from '@common/Dialog';
const nearbyIcon = <LocationOnIcon />;

const customContentStyle = {
  width: '80%',
  maxWidth: 'none'
};

export default class BarTableTech extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      valueCompanie: null,
      stripedRows: false,
      showRowHover: true,
      enableSelectAll: false,
      height: '300px',
      inputLinkClicked: false,
      inputLinkClickedDetails: false,
      open: false,
      showResultsReport: false,
      showCheckboxes: false,
      selectedIndex: 0,
      nameGraph: 'status'
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.select = this.select.bind(this);
  }

  registerUrgency(urgency_cod) {
    var temp = '';
    switch (urgency_cod) {
      case 25:
        temp = 'Small';
        break;
      case 26:
        temp = 'Medium';
        break;
      case 27:
        temp = 'High';
        break;
    }
    return temp;
  }

  openModal(nameService) {
    var objReportDialog = this.props.reportCall;
    var contractValue = this.props.resultContract;

    var tableDetailList = [];
    Object.keys(objReportDialog).forEach(function (key) {
      if (
        objReportDialog[key].tech_username == nameService &&
        objReportDialog[key].fk_id_contract == contractValue
      ) {
        tableDetailList.push(objReportDialog[key]);
      }
    });
    //console.log(tableDetailList);

    this.tableDetailList = tableDetailList;

    // console.log(tableDetailList);
    this.setState({ open: true, inputLinkClickedDetails: true });
  }

  closeModal() {
    this.setState({ open: false, inputLinkClickedDetails: false });
  }

  select(index, nameGraph = '') {
    this.setState({ selectedIndex: index, nameGraph: nameGraph });
  }

  render() {
    var objReport = this.props.reportCall;
    var contractValue = this.props.resultContract;

    // Pega todos os servirços que foi prestadado para esse contrato;
    var tableDetailList = [];
    Object.keys(objReport).forEach(function (key) {
      if (objReport[key].fk_id_contract == contractValue) {
        tableDetailList.push(objReport[key]);
      }
    });

    // Pegando referencia de cada tipo de serviço;
    var tableDetailUniqueService = lodash.uniqBy(
      tableDetailList,
      'tech_username'
    );
    this.allServiceContract = tableDetailList;
    this.detailUniqueService = tableDetailUniqueService;

    // { name: 'Page A', Resolved: 4000, Closed: 2400, Open: 2400, InProgress: 2400 },
    // Array quantidade de status Resolved
    var arrayResolvedLength = [];
    var arrayClosed = [];
    var arrayOpen = [];
    var arrayProgress = [];
    var resolvedVar = 0;
    var closedVar = 0;
    var openVar = 0;
    var inProgressVar = 0;
    Object.keys(tableDetailUniqueService).forEach(function (key) {
      resolvedVar = 0;
      closedVar = 0;
      openVar = 0;
      inProgressVar = 0;
      Object.keys(tableDetailList).forEach(function (key2) {
        if (
          tableDetailUniqueService[key].tech_username ==
          tableDetailList[key2].tech_username
        ) {
          if (tableDetailList[key2].status == 'Resolved') {
            arrayResolvedLength.push({
              name: tableDetailUniqueService[key].tech_username,
              Resolved: (resolvedVar = resolvedVar + 1)
            });
          } else {
            arrayResolvedLength.push({
              name: tableDetailUniqueService[key].tech_username,
              Resolved: 0
            });
          }
          if (tableDetailList[key2].status == 'Closed') {
            arrayClosed.push({
              name: tableDetailUniqueService[key].tech_username,
              Closed: (closedVar = closedVar + 1)
            });
          } else {
            arrayClosed.push({
              name: tableDetailUniqueService[key].tech_username,
              Closed: 0
            });
          }
          if (tableDetailList[key2].status == 'Open') {
            arrayOpen.push({
              name: tableDetailUniqueService[key].tech_username,
              Open: (openVar = openVar + 1)
            });
          } else {
            arrayOpen.push({
              name: tableDetailUniqueService[key].tech_username,
              Open: 0
            });
          }
          if (tableDetailList[key2].status == 'In Progress') {
            arrayProgress.push({
              name: tableDetailUniqueService[key].tech_username,
              InProgress: (inProgressVar = inProgressVar + 1)
            });
          } else {
            arrayProgress.push({
              name: tableDetailUniqueService[key].tech_username,
              InProgress: 0
            });
          }
        }
      });
    });

    var arrayResolvedSum = lodash.uniqBy(
      lodash.orderBy(
        arrayResolvedLength,
        ['name', 'Resolved'],
        ['asc', 'desc']
      ),
      'name'
    );
    var arrayClosedSum = lodash.uniqBy(
      lodash.orderBy(arrayClosed, ['name', 'Closed'], ['asc', 'desc']),
      'name'
    );
    var arrayOpenSum = lodash.uniqBy(
      lodash.orderBy(arrayOpen, ['name', 'Open'], ['asc', 'desc']),
      'name'
    );
    var arrayProgressSum = lodash.uniqBy(
      lodash.orderBy(arrayProgress, ['name', 'InProgress'], ['asc', 'desc']),
      'name'
    );

    var arrayFinal = [];
    var tempSliceName = '';

    tableDetailUniqueService.map(tableDetailUniqueService => {
      if (tableDetailUniqueService.tech_username != null) {
        arrayResolvedSum.map(arrayResolvedSum => {
          arrayClosedSum.map(arrayClosedSum => {
            arrayOpenSum.map(arrayOpenSum => {
              arrayProgressSum.map(arrayProgressSum => {
                if (
                  tableDetailUniqueService.tech_username ==
                  arrayResolvedSum.name &&
                  tableDetailUniqueService.tech_username ==
                  arrayClosedSum.name &&
                  tableDetailUniqueService.tech_username == arrayOpenSum.name &&
                  tableDetailUniqueService.tech_username ==
                  arrayProgressSum.name
                ) {
                  tempSliceName = tableDetailUniqueService.tech_username;
                  arrayFinal.push({
                    name: tempSliceName.substring(
                      0,
                      tempSliceName.indexOf('@')
                    ),
                    Resolved: arrayResolvedSum.Resolved,
                    Closed: arrayClosedSum.Closed,
                    Open: arrayOpenSum.Open,
                    InProgress: arrayProgressSum.InProgress
                  });
                }
              });
            });
          });
        });
      }
    });

    //console.log(tableDetailUniqueService);
    this.dataGrph = arrayFinal;

    // Dados do grafico de urgência
    // { name: 'Page A', Resolved: 4000, Closed: 2400, Open: 2400, InProgress: 2400 },
    var dataUrgency = [];
    var small;
    var medium;
    var height;
    tableDetailUniqueService.map(tableDetailUniqueService => {
      small = 0;
      medium = 0;
      height = 0;
      if (tableDetailUniqueService.tech_username != null) {
        tableDetailList.map(urgencyDetails => {
          if (
            tableDetailUniqueService.tech_username ==
            urgencyDetails.tech_username
          ) {
            if (urgencyDetails.fk_id_urgency == 25) {
              small = small + 1;
            }
            if (urgencyDetails.fk_id_urgency == 26) {
              medium = medium + 1;
            }
            if (urgencyDetails.fk_id_urgency == 27) {
              height = height + 1;
            }
          }
        });
        tempSliceName = tableDetailUniqueService.tech_username;
        dataUrgency.push({
          name: tempSliceName.substring(0, tempSliceName.indexOf('@')),
          Small: small,
          Medium: medium,
          Height: height
        });
      }
    });
    this.dataGrapUrgency = dataUrgency;

    // Dados do grafico de prioridade
    // { name: 'Page A', Resolved: 4000, Closed: 2400, Open: 2400, InProgress: 2400 },
    var dataPriority = [];
    var smallp;
    var mediump;
    var heightp;
    var criticalp;
    tableDetailUniqueService.map(tableDetailUniqueService => {
      smallp = 0;
      mediump = 0;
      heightp = 0;
      criticalp = 0;
      if (tableDetailUniqueService.tech_username != null) {
        tableDetailList.map(priorityDetails => {
          if (
            tableDetailUniqueService.tech_username ==
            priorityDetails.tech_username
          ) {
            if (priorityDetails.fk_id_priority == 31) {
              smallp = smallp + 1;
            }
            if (priorityDetails.fk_id_priority == 32) {
              mediump = mediump + 1;
            }
            if (priorityDetails.fk_id_priority == 33) {
              heightp = heightp + 1;
            }
            if (priorityDetails.fk_id_priority == 34) {
              heightp = heightp + 1;
            }
          }
        });
        tempSliceName = tableDetailUniqueService.tech_username;
        dataPriority.push({
          name: tempSliceName.substring(0, tempSliceName.indexOf('@')),
          Small: smallp,
          Medium: mediump,
          Height: heightp,
          Critical: criticalp
        });
      }
    });
    this.dataGrapPriority = dataPriority;

    // Dados do grafico de impacto
    // { name: 'Page A', Resolved: 4000, Closed: 2400, Open: 2400, InProgress: 2400 },
    var dataImpact = [];
    var smalli;
    var mediumi;
    var heighti;
    tableDetailUniqueService.map(tableDetailUniqueService => {
      smalli = 0;
      mediumi = 0;
      heighti = 0;
      if (tableDetailUniqueService.tech_username != null) {
        tableDetailList.map(impactDetails => {
          if (
            tableDetailUniqueService.tech_username ==
            impactDetails.tech_username
          ) {
            if (impactDetails.fk_id_impact == 28) {
              smalli = smalli + 1;
            }
            if (impactDetails.fk_id_impact == 29) {
              mediumi = mediumi + 1;
            }
            if (impactDetails.fk_id_impact == 30) {
              heighti = heighti + 1;
            }
          }
        });
        tempSliceName = tableDetailUniqueService.tech_username;
        dataImpact.push({
          name: tempSliceName.substring(0, tempSliceName.indexOf('@')),
          Small: smalli,
          Medium: mediumi,
          Height: heighti
        });
      }
    });
    this.dataGrapImpact = dataImpact;

    // console.log(tableDetailUniqueService);
    // console.log(tableDetailList);
    //console.log(this.dataGrapUrgency);

    const actions = [
      <Button label="Fechar" primary={true} onClick={this.closeModal} />
    ];
    let { language } = this.props;

    var dataGrph = {
      r: this.dataGrph.map(e => ({ name: e.name, y: e.Resolved })),
      c: this.dataGrph.map(e => ({ name: e.name, y: e.Closed })),
      o: this.dataGrph.map(e => ({ name: e.name, y: e.Open })),
      i: this.dataGrph.map(e => ({ name: e.name, y: e.InProgress })),
    }

    var dataGrphOther = {
      urgency: this.dataGrapUrgency,
      impact: this.dataGrapImpact,
      priority: this.dataGrapPriority
    }

    dataGrphOther = {
      s: {
        legend: language.TICKET_PRIORITY.SMALL,
        data: (dataGrphOther[this.state.nameGraph] || []).map(e => ({ name: e.name, y: e.Small })),
      },
      m: {
        legend: language.TICKET_PRIORITY.MEDIUM,
        data: (dataGrphOther[this.state.nameGraph] || []).map(e => ({ name: e.name, y: e.Medium })),
      },
      h: {
        legend: language.TICKET_PRIORITY.HIGH,
        data: (dataGrphOther[this.state.nameGraph] || []).map(e => ({ name: e.name, y: e.Height })),
      },
      c: {
        legend: language.TICKET_PRIORITY.CRITICAL,
        data: (dataGrphOther[this.state.nameGraph] || []).map(e => ({ name: e.name, y: e.Critical })),
      },
    }


    var dataGrphOtherTitle = {
      urgency: language.EMERGENCI_CHART_TECH,
      impact: language.IMPACT_CHART_TECH,
      priority: language.TECH_TABLE
    }
    return (
      <div>
        <Card className="px-4">
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ paddingTop: 40 }}>
              <Paper className='mb-2'>
                <BottomNavigation showLabels value={this.state.selectedIndex}>
                  <BottomNavigationAction
                    label="Status"
                    value={0}
                    icon={nearbyIcon}
                    onClick={() => this.select(0, 'status')}
                  />
                  <BottomNavigationAction
                    label="Urgency"
                    icon={nearbyIcon}
                    value={1}
                    onClick={() => this.select(1, 'urgency')}
                  />
                  <BottomNavigationAction
                    label="Impact"
                    icon={nearbyIcon}
                    value={2}
                    onClick={() => this.select(2, 'impact')}
                  />
                  <BottomNavigationAction
                    label="Priority"
                    icon={nearbyIcon}
                    value={3}
                    onClick={() => this.select(3, 'priority')}
                  />
                </BottomNavigation>
              </Paper>

              {this.state.nameGraph == 'status' &&
                <div>
                  <ReactHighcharts config={ContainerChart.container0({
                    title: language.STATUS_CHART_BY_TECH,
                    subtitle: language.HOVER_OVER_COLUMN,
                    yTitle: '',
                    series: [
                      {
                        type: 'column',
                        name: language.TICKET_STATUS.RESOLVED,
                        data: dataGrph.r,
                        color: 'var(--success)'
                      },
                      {
                        type: 'column',
                        name: language.TICKET_STATUS.CLOSED,
                        data: dataGrph.c,
                        color: 'var(--danger)'
                      },
                      {
                        type: 'column',
                        name: language.TICKET_STATUS.OPEN,
                        data: dataGrph.o,
                        color: 'var(--primary)'
                      },
                      {
                        type: 'column',
                        name: language.TICKET_STATUS.IN_PROGRESS,
                        data: dataGrph.i,
                        color: 'var(--warning)'
                      },
                    ],
                  })}
                  />
                </div>
              }

              {['urgency', 'impact', 'priority'].includes(this.state.nameGraph) &&
                <div>
                  <Grap
                    language={language}
                    data={dataGrphOther}
                    title={dataGrphOtherTitle[this.state.nameGraph]}
                    subtitle={language.HOVER_OVER_COLUMN}
                  />
                </div>
              }
            </Grid>
            <Grid item xs={6} className='pb-4'>
              <CommonTable
                fluid
                maxHeight={300}
                col={[
                  { key: 'name', label: language.NAME },
                  { key: 'details', label: language.DETAILS },
                ]}
                title={language.TECH_TABLE}
                data={
                  this.props.inputLinkClicked ? (
                    this.detailUniqueService.map((row, index) => ({
                      name: row.tech_username,
                      details: (
                        <RaisedButton
                          circleButton
                          icon='fas fa-info'
                          color='primaryGradient'
                          label={language.CLICK_ICON_SEE_ATTENDANCE}
                          onClick={() =>
                            this.openModal(row.tech_username)
                          }
                          style={{
                            width: 23,
                            height: 23,
                            fontSize: 15
                          }}
                        />
                      )
                    }))
                  ) : (
                    [{
                      name: language.DEVICE_NAME, details: (
                        <div
                          className="contractIcon"
                          style={{
                            width: 25,
                            height: 25,
                            cursorPointer: true
                          }}
                        />
                      )
                    }]
                  )
                }
              />
            </Grid>
          </Grid>

        </Card>

        <Dialog

          open={this.state.open}
          onClose={() => this.closeModal()}
          title={language.DETAILS_OF_THE_CALLS_MADE}
          maxWidth='xl'

        >
          <CommonTable
            col={[
              {
                key: 'name',
                label: language.DEPARTMENT_NAME
              },
              {
                key: 'subject',
                label: language.SUBJECT_OF_THE_CALL
              },
              {
                key: 'device',
                label: language.DEVICE_TYPE
              },
              {
                key: 'status',
                label: language.STATE
              },
              {
                key: 'resolution_time',
                label: language.RESOLUTION_TIME
              },
            ]}

            data={
              this.state.inputLinkClickedDetails ? (
                this.tableDetailList.map((row, index) => ({
                  name: row.tech_username,
                  subject: row.subject,
                  type: row.type,
                  status: row.status,
                  time_resolution: row.time_resolution
                }))
              ) : []
            }
          />
        </Dialog>
      </div>
    );
  }
}

class Grap extends Component {
  render() {
    let { language, data, title, subtitle } = this.props;
    var dataSeries = [
      {
        type: 'column',
        name: data.s.legend,
        data: data.s.data,
        color: 'var(--success)'
      },
      {
        type: 'column',
        name: data.m.legend,
        data: data.m.data,
        color: 'var(--warning)'
      },
      {
        type: 'column',
        name: data.h.legend,
        data: data.h.data,
        color: 'var(--primary)'
      },
      (!!data.c.data.length && data.c.data.find(e => e.y !== undefined)) ? (
        {
          type: 'column',
          name: data.c.legend,
          data: data.c.data,
          color: 'var(--danger)'
        }
      ) : (
        null
      )
    ]
    dataSeries = dataSeries.filter(Boolean);
    return (
      <ReactHighcharts config={ContainerChart.container0({
        title: title,
        subtitle: subtitle,
        yTitle: '',
        series: dataSeries
      })}
      />
    )
  }
}
