import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import CommonPage from '../../common/CommonPage';
import CommonDelete from '../../common/CommonDelete';
import GeneralPermissionList from './GeneralPermissionList';
import PermissionsForm from './PermissionsForm';
import Dialog from '@common/Dialog';
import Animate from '@common/Animate';
import Store from '@data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';
import { getCategoriesByCompanyIdService } from '../GeneralParameters/components/ClosingGMUD/closingGMUD.services';

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: option[attributeName],
  }));

  return normalizedOptions;
};

@observer
export default class UserPermissionsContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'list',
      department: [],
      load: true,
      totaldepartment: 0,
      currentPage: 0,
      page: 0,
      general_params: [],
      companies: [],
      categories: [],
      departments: [],
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.callPagination = this.callPagination.bind(this);
    this.callDataFilter = this.callDataFilter.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
  }

  componentWillMount() {
    this.action
      .execute('get', this.APIRoot + '/api/generalparameters', 'general_parameters')
      .then((result) => {
        this.setState({ general_params: result.data });
      });

    this.action
      .execute('get', this.APIEndpoints.USER_CATALOGS, '')
      .then((item) => {
        this.setState({ department: item.data });
      });

    this.action
      .execute('get', this.APIEndpoints.COMPANY, '').then((item) => {
        let companiesOptions = item.data.map((obj) => ({
          value: obj.id_company,
          label: obj.name,
          type: obj.type,
        }));
        this.setState({
          companies: companiesOptions,
        });
      });

    this.action
      .execute('get', this.APIEndpoints.COUNTRY, '').then((item) => {
        let countriesOptions = item.data.map((obj) => ({
          value: obj.id_country,
          label: translateFromPtBR(obj.name, Store.language)
        }));
        this.setState({
          countries: countriesOptions,
        });
      });;
    this.callPageSelectFilter();
  }

  componentDidMount() {
    this.getCategories();
  }

  async getCategories() {
    try {
      const result = await getCategoriesByCompanyIdService({
        companyId: this.state.general_params.fk_id_company,
      });
      this.setState( { categories: formatSelectOptions(result, 'name', 'id_category') } );
    } catch (error) {
      console.error(error);
    }
  }

  callDataFilter(items = {}) {}

  callPagination(page) {}

  callPageSelectFilter(page = 0, totalPerPage = 75) {
    this.setState({ currentPage: !isNaN(page) ? page : 0 });
    let limit = totalPerPage || 75;
    let offset = !isNaN(page) ? page * limit : 0 * limit;

    this.action
      .execute('get', `${this.APIEndpoints.USER_CATALOGS}?limit=${totalPerPage}&offset=${offset}`, '', null, '')
      .then((res) => {
        this.setState({ departments: res.data });
      })
      .catch((error) => {
        this.store.toggleAlert(
          true,
          'Aguarde estamos processando os dados',
          'success'
        );
      });
  }

  onFormSubmit(values) {
    if (values.hasOwnProperty("undefined")) {
      delete values.undefined;
    }
  }

  handlePage(page, edit) {
    this.setState({ page: page, values: edit });
  }

  onDelete() {
    let { values } = this.state;
    this.action
      .execute('delete', this.APIEndpoints.USER_CATALOG + '/' + values.id_user_catalog, '', null, '')
      .then((result) => {
        this.callPageSelectFilter(this.state.currentPage);
        this.handlePage('list');
      });
  }

  render() {
    let { language } = this.store;
    let { page, open, values, totaldepartment, departments, department } = this.state;
    let history = this.history2;
    let redirect = this.redirect2;
    let PAGE = history.location.pathname;
    let state = history.location.state;
    let { configurationProps = {} } = this.props;

    return (
      <Fragment>
        <Animate>
          <GeneralPermissionList
            handleOpen={this.handleOpen}
            handleClose={this.handleClose}
            onFormSubmit={this.onFormSubmit}
            handlePage={this.handlePage}
            language={language}
            history={history}
            redirect={redirect}
            configurationProps={configurationProps}
            departments={departments}
            department={department}
            totaldepartment={totaldepartment}
            callPagination={this.callPagination}
            callDataFilter={this.callDataFilter}
            callPageSelectFilter={this.callPageSelectFilter}
            APIEndpoints={this.APIEndpoints}
          />
        </Animate>
        {['new', 'edit'].includes(page) && (
          <Dialog
            title={
              values
              ? language.GENERAL_USER_PERMISSIONS_EDITION
              : language.GENERAL_USER_PERMISSIONS_CREATION
            }
            open={true}
            onClose={() => this.handlePage('list')}
            maxWidth="xs"
            checkScroll
          >
            <PermissionsForm
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              onFormSubmit={this.onFormSubmit}
              handlePage={this.handlePage}
              language={language}
              store={this.store}
              history={history}
              values={values}
              redirect={redirect}
              companies={this.state.companies}
              countries={this.state.countries}
              categories={this.state.categories}
              APIEndpoints = {this.APIEndpoints}
            />
          </Dialog>
        )}
        {page === 'remove' && (
          <CommonDelete
            language={language}
            closeModal={() => this.handlePage('list')}
            onDelete={this.onDelete}
            message={
              (
                this.state.values.role === 'tech' ?
                  language.TECH :
                this.state.values.role === 'solicitant' ?
                  language.SOLICITANT :
                this.state.values.role === 'scm' ?
                  language.SCM :
                  this.state.values.role
              )  + ' - ' + this.state.values.company_name
            }
          />
        )}
      </Fragment>
    );
  }
}
