import React, { Component } from 'react';

import { Tooltip } from 'react-tippy';
import { TextField, MenuItem} from '@material-ui/core';
import { observer } from 'mobx-react';

import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';

import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import moment from 'moment';
import './Demand.css';
import CommonPage from '../../common/CommonPage';
@observer
export default class DemandModal extends Component {

  constructor() {
    super();
    this.state = {
      module:''
    };
    this.handleRedirect = this.handleRedirect.bind(this);

  }

  changeModule = (evt)=>{
    this.setState({module:evt.target.value});
  }



  handleRedirect(value) {
    if (value != '' && value != null) {
      this.setState({ page: 'list', projectSelect: value, route: '' });
    }
  }
  render() {
    let { language } = this.props;
    let listModules = ['GCF', 'GI', 'GSD', 'GR', 'GP'].map((e,i)=>(
      <MenuItem value={e} key={i}>
        {e}
      </MenuItem>
    ))
    let data =[];
    let col = [
      {key:'module', label:language.MODULE,
      style: { width: 50 }
      },
      {key:'action', label:language.ACTION,
      style: { width: 30 }},
      {key:'status', label:language.STATE,
      style: { width: 30 }},

      {key:'subject', label:language.DESCRIPTION,
      style:{ width: 700 }},


    ]
       this.props.tickets.map(c=>{
    data.push({module:  <div

    className="imgGsd"
    style={{ width: 25, height: 25 }}
  />,
action: <div>
<Tooltip
  html={
    <div style={{ color: '#fff', fontSize: 15 }}>
      <strong>{language.TICKET_DETAIL}</strong>
    </div>
  }
  position="right"
  theme="dark"
>

</Tooltip>
</div>

 ,
status:   <div className="orderdivpro w-100 ml-1" id={c.progress}>
<div style={{ textAlign: 'center' }}>{c.progress}%</div>
<div
  style={{
    width: `${c.progress}%`,
    height: '7px',
    backgroundColor:
      c.progress > 66
        ? '#85cc00'
        : c.progress> 33
        ? '#ffbf00'
        : '#ff2e00',
    borderRadius: '2px',
    transition: 'all .2s ease-out'
  }}
/>
</div>,

subject:<div><div><b>Tickets: </b> {c.realid}</div>
<div><b>Subject: </b>  {c.subject}


</div></div>



    })})

  this.props.activities.map(c=>{
      data.push({module:<div

        className="projectManIcon pointer"
        style={{ width: 25, height: 25 }}
      />,
    action: <div><Tooltip
    html={
      <div style={{ color: '#fff', fontSize: 15 }}>
        <strong>{'Ir para Atividade'}</strong>
      </div>
    }
    position="right"
    theme="dark"
  >




  </Tooltip>
  </div>,
    status:   <div className="orderdivpro w-100 ml-1" id={c.percent}>
    <div style={{ textAlign: 'center' }}>{c.percent}%</div>
    <div
      style={{
        width: `${c.percent}%`,
        height: '7px',
        backgroundColor:
          c.percent > 66
            ? '#85cc00'
            : c.percent> 33
            ? '#ffbf00'
            : '#ff2e00',
        borderRadius: '2px',
        transition: 'all .2s ease-out'
      }}
    />
  </div>,

    subject:

  <div><div><b>Project: </b> {c.project}</div>
    <div><b>Activity: </b>  {c.name}</div></div>  ,

    time_spent:''

        })}






    )
    return (



        <div id="DemandmanagementList">
        <RaisedButton

     color="primary"


     style={{ fontSize: 15, paddingTop: 1, margin: '0 3px' }}
     label={'Confirmar técnico como responsável da atividade'}
     onClick={() =>
       this.props.savetech()
     }> </RaisedButton>
          <CommonTable
            beforeTitle={
              <RaisedButton
                circleButton
                icon='fas fa-plus'
                label={language.ADD}
              />
            }
            col={col}
            data={data}
            rowsPerPageOptions={[100, 250]}
            searchColumn orderColumn
            index='DemandModal'
          />
        </div>


    );
  }
}
