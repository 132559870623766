import React, { Component } from 'react';
import { Card} from '@material-ui/core';
import Dialog from '@common/Dialog';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm2';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CommonTable from  '../../common/CommonTable';
import { Tooltip } from 'react-tippy';
import FaClose from 'react-icons/lib/fa/close';
import RaisedButton from '@common/RaisedButton';
import { Container, Row, Col } from 'react-grid-system';
import Constants from '../../data/Constants';
const styles = theme => ({
	dialogPaper: {
		minHeight: '90vh',
		maxHeight: '90vh',
		minWidth: '1100px'
	}, dialogPaperAlert: {
		minHeight: '90vh',
		maxHeight: '90vh',
		minWidth: '1100px'
	},
	dialogPaper_: {
		minHeight: '80vh',
		maxHeight: '80vh',
		minWidth: '2000px'
	}
});
  class MailConfig extends Component {

  constructor() {
    super();
    this.state = {
      company:0
    }

    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(field, value) {
    if (field.name === 'fk_id_company') {
      this.setState({
        company: value
      });

    }
  }
  render() {

    let {  language, handlePage, onFormSubmit, values ,mailContract,classes} = this.props;

    let{company} = this.state;


    let tlsOptions = [
      { value: 'S', label: language.YES},,
      { value: 'N', label: language.NOT}
    ];
 ;



//(idmail, mail, password, pop, smtp, host, port, tls, host_value, port_value, tls_value, deleted)
//console.log('values', values)
let fields = [

      { col: 3, name: 'mail', label: language.MAIL_MAIL, type: 'text', required: true,disabled:true },

      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_company',
        label: language.COMPANY,
        method: 'POST',
        route: `${Constants.APIEndpoints.COMPANY}/filter`,
        fieldquery: 'search',
        textlabel: language.COMPANY,
        textinit: language.COMPANY,
        fieldvaluedb: 'id_company',
        fieldlabeldb: 'name',
        idinit: 0,
        routeAll: `${Constants.APIEndpoints.COMPANY}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.COMPANY}/count`,
        detail: true,
        required: true,
       },
       {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_contract',
        label: language.CONTRACT,
        method: 'POST',
        route: `${Constants.APIEndpoints.CONTRACT}/filter`,
        filterAll : {fk_id_out_company : company},
        fieldquery: 'search',
        textlabel: language.CONTRACT,
        textinit: language.CONTRACT,
        fieldvaluedb: 'id_contract',
        fieldlabeldb: 'name',
        idinit: 0,
        routeAll: `${Constants.APIEndpoints.CONTRACT}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.CONTRACT}/count?`,
        detail: true,
        required: true,
				disabled: !company ? true : false
       },

      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_group',
        label: 'Grupo',
        method: "POST",
        route: `${Constants.APIEndpoints.GROUP}/filter`,
        fieldquery: 'search',
        textlabel: language.TECH_GROUP,
        textinit: language.TECH_GROUP,
        fieldvaluedb: 'id_group_users',
        fieldlabeldb: 'name',
        idinit: 0,
        routeAll: `${Constants.APIEndpoints.GROUP}/all?`,
        methodGetAll: 'POST',
        routeGetCount: `${Constants.APIEndpoints.GROUP}/count`,
        detail: true,
        required: true,

      }

    ];

    let col = [
      { key: 'id_rel_mail_contract', label: language.ID, style: { width: 10, textAlign:'center' } },
      { key: 'contract', label: language.CONTRACT, style: { width: 120, textAlign:'center' }},
      { key: 'group', label: language.GROUP_TECH, style:{textAlign:'center'} },
      { key: 'company', label: language.COMPANY, style:{textAlign:'center'}} ,
      { key: 'action', label: language.ACTION, style: {width:40, textAlign:'center'} }

    ];

/**      tb_rel_mail_contract.id_rel_mail_contract ,
      tb_person."name" company,
      tb_contract."name" contrato,
      tb_group_users."name" groupusers */

    let data= mailContract.filter(v=>v.id_mail == values.id_mail).map(v=>{

       return{
        id_rel_mail_contract: v.id_rel_mail_contract,
        contract: v.contrato,
        group: v.groupusers,
        company: v.company,
        action: (
          <RaisedButton
            circleButton
            icon='far fa-trash-alt'
            label={language.DELETE}
            color='danger'

            style={{fontSize:13, width:21, height:21}}
            onClick={() => handlePage('remove', v)}
          />

        )

       }


    })


    let button = { md: 12, label: language.SAVE, primary: true, };

    return (
      <Dialog
       open={true}
       PaperProps={{ classes: { root: classes.dialogPaperAlert } }}
       onClose={()=>handlePage('list')}
       title={language.ADD}
       maxWidth='md'
      >

        <Card className='content mb-3' style={{overflow:'visible'}}>
          <CommonForm
            values={values}
            fields={fields}
            onChangeField={this.onChangeField}
            onSubmit={onFormSubmit}
            button={button}
          />
        </Card>
        <CommonTable title={''} language={language} columns={col} data={data} />

      </Dialog>
    );
  }
}
MailConfig.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MailConfig);
