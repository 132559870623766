import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';

import Button from '../../common/Button';
import Dialog from '../../common/Dialog';
import CommonForm from '../../common/CommonForm2';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import DialogConfirm from '../../common/DialogConfirm';

import './Workflow.css';

const WorkflowForm = ({
  endpoints,
  language,
  onSubmit,
  statusTasks,
  statusTickets,
  statusAwaiting,
  workflow,
}) => {
  const statusTaskOptions = Object.keys(statusTasks)
    .filter((s) => !workflow.map((w) => w.task_status).includes(statusTasks[s]))
    .map((i) => ({
      value: i,
      label: statusTasks[i],
    }));

  const statusTicketOptions = Object.keys(statusTickets).map((i) => ({
    value: i,
    label: statusTickets[i],
  }));

  const statusWaitingOptions = statusAwaiting.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const fields = [
    {
      col: 12,
      name: 'task_status',
      label: language.STATUS_OF_TASK,
      type: 'select',
      options: statusTaskOptions,
      required: true,
    },
    {
      col: 12,
      name: 'ticket_status',
      label: language.STATUS_OF_TICKET,
      type: 'select',
      options: statusTicketOptions,
    },
    {
      col: 12,
      name: 'status_awaiting',
      label: language.STATUS_OF_WAITING,
      type: 'select',
      options: statusWaitingOptions,
    },
    {
      col: 12,
      type: 'autocomplete',
      name: 'id_group_tech',
      textlabel: language.TECH_GROUP,
      className: 'tech-group-ticket',
      method: 'POST',
      route: `${endpoints.GROUP}/filter`,
      fieldquery: 'search',
      fieldvaluedb: 'id_group_users',
      fieldlabeldb: 'name',
      routeAll: `${endpoints.GROUP}/all?`,
      detail: true,
      methodGetAll: 'POST',
      routeGetCount: `${endpoints.GROUP}/count`,
    },
  ];

  return (
    <CommonForm
      button={{ label: language.SAVE }}
      fields={fields}
      onSubmit={onSubmit}
    />
  );
};

const Workflow = ({ action, endpoints, language, taskAssociate }) => {
  const defaultDelete = { open: false, status: null };
  const [workflow, setWorkflow] = useState([]);
  const [statusAwaiting, setStatusAwaiting] = useState([]);
  const [pageDelete, setPageDelete] = useState(defaultDelete);
  const [openWokflowForm, setOpenWokflowForm] = useState(false);

  const statusTasks = {
    AGUARDANDO: language.TASK_STATUS.WAITING,
    INICIADO: language.TASK_STATUS.STARTED,
    ANDAMENTO: language.TASK_STATUS.IN_PROGRESS,
    CONCLUIDO: language.TASK_STATUS.DONE,
    'PENDENTE APROVAÇÃO': language.TASK_STATUS.PENDING,
    REJEITADO: language.TASK_STATUS.REJECTED,
    APROVADO: language.TASK_STATUS.APPROVED,
  };

  const statusTickets = {
    Canceled: language.TICKET_STATUS.CANCELLED,
    Closed: language.TICKET_STATUS.CLOSED,
    'In Progress': language.TICKET_STATUS.IN_PROGRESS,
    Open: language.TICKET_STATUS.OPEN,
    Resolved: language.TICKET_STATUS.RESOLVED,
    Reopened: language.TICKET_STATUS.REOPENED,
  };

  const findAwaiting = (id) => {
    const data = statusAwaiting.find((s) => s.id == id);
    return data ? data.name : '';
  };

  const handleDelete = (data) => {
    setPageDelete(data);
  };

  const handleWorkFlowForm = (open) => {
    setOpenWokflowForm(open);
  };

  const addWorkflow = (data) => {
    action
      .execute('post', `${endpoints.TASK_TEMPLATE}/${taskAssociate.id_template_task}/workflow/attach`, '', data)
      .then((e) => {
        getWorkflow();
        setOpenWokflowForm(false);
      });
  };

  const onDelete = () => {
    action
      .execute('post', `${endpoints.TASK_TEMPLATE}/${taskAssociate.id_template_task}/workflow/detach`, '', { task_status: pageDelete.status })
      .then((e) => {
        handleDelete(defaultDelete);
        setWorkflow([]);
        getWorkflow();
      });
  };

  const getWorkflow = () => {
    action
      .execute('get', `${endpoints.TASK_TEMPLATE}/${taskAssociate.id_template_task}/workflow`, '')
      .then((data) => {
        if ( data.data ) {
          data = data.data;
        }
        if (data.length) {
          setWorkflow(
            data.map((d) => {
              const task_status = d.task_status;
              return {
                task_status: statusTasks[d.task_status],
                ticket_status: statusTickets[d.ticket_status],
                status_awaiting: findAwaiting(d.status_awaiting),
                id_group_tech: d.id_group_tech,
                delete: (
                  <Button
                    variant="circle"
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    size={0.7}
                    style={{ fontSize: 14 }}
                    onClick={() =>
                      handleDelete({ open: true, status: task_status })
                    }
                  />
                ),
              };
            })
          );
        }
      });
  };

  useEffect(() => {
    action.execute('get', endpoints.STATUS_AWAITING).then((e) => {
      setStatusAwaiting(
        e.data.map((d) => ({ id: d.id_status_awaiting, name: d.status }))
      );
    });
  }, []);

  useEffect(() => {
    getWorkflow();
  }, [statusAwaiting]);

  const col = [
    { key: 'task_status', label: language.STATUS_OF_TASK },
    { key: 'ticket_status', label: language.STATUS_OF_TICKET },
    { key: 'status_awaiting', label: language.STATUS_OF_WAITING },
    { key: 'id_group_tech', label: language.TECH_GROUP },
    { key: 'delete', label: language.DELETE },
  ];

  return (
    <>
      <CommonTable
        clearFilters={false}
        columns={col}
        data={workflow}
        language={language}
        orderColumn={false}
        pagination={false}
        search={false}
      />
      <RaisedButton
        circleButton
        style={{ float: 'right', marginTop: 10 }}
        zoom={0.8}
        className="mr-3"
        color="primary"
        icon="fas fa-plus"
        onClick={() => handleWorkFlowForm(true)}
      />
      <DialogConfirm
        open={pageDelete.open}
        language={language}
        confirmProps={{ color: 'danger' }}
        onConfirm={() => onDelete()}
        onClose={() => handleDelete(defaultDelete)}
        title={language.CONFIRM_DELETE}
      />
      <Dialog open={openWokflowForm} onClose={() => handleWorkFlowForm(false)}>
        <div className="workform">
          <WorkflowForm
            endpoints={endpoints}
            language={language}
            onSubmit={addWorkflow}
            statusTasks={statusTasks}
            statusTickets={statusTickets}
            statusAwaiting={statusAwaiting}
            workflow={workflow}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Workflow;
