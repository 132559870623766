import { Card, Grid } from '@material-ui/core';
import React, { memo } from 'react';
import ReactHighcharts from 'react-highcharts';

// ------------------------------------------------------------

import { radial } from './Graphs';

import AppBar from '@common/AppBar2';
import withLanguage from '../../../../common/languages/withLanguage';

// ------------------------------------------------------------

export default memo(
  withLanguage(({ slaNotOk, slaOk, sla, averageTime, user, language }) => {
    return (
      <Card id="CallsAnswered2">
        <AppBar
          title={
            user.role == 'solicitant'
              ? language.CALLS
              : language.ANSWERED_CALLS_RESOLVED_AND_CLOSED
          }
          subTitle={`(${language.TOTAL_MONTH})`}
        />
        <div className="content-medium pt-0">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <ReactHighcharts
                config={radial({
                  name: language.CALLS,
                  data: [
                    {
                      name: language.EXPIRED_SLA,
                      y: slaNotOk,
                      color: 'var(--danger)',
                    },
                    { name: 'SLA Ok', y: slaOk, color: 'var(--success)' },
                    { name: language.TICKET_STATUS.IN_PROGRESS, y: sla, color: 'var(--primary)' },
                  ],
                  title: {
                    content: slaNotOk + slaOk + sla,
                    style: 'color:#A3A3A3',
                  },
                  subTitle: { content: language.CALLS },
                  width: 150,
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <div id="card-time-medium">
                <span>{language.SERVICE_TIME}</span>
                <h2>{averageTime}</h2>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  },
  function (prev, next) {
    let omit = (obj) => _.omitBy(obj, (val) => typeof val == 'function');
    return _.isEqual(omit(prev), omit(next));
  }
));
