import React from 'react';
import Dialog from '@common/Dialog';
import TicketManagement from '../../../TicketManagement';

export default function ({ open, onClose, idTicket }) {
  return (
    <Dialog
      title={'Visualização de ticket'}
      open={open}
      maxWidth="xl"
      onClose={onClose}
    >
      <TicketManagement
        detail={true}
        id_ticket_detail={{ id_tickets: idTicket }}
      />
    </Dialog>
  );
}
