import React, { Fragment, useState, useEffect, useRef } from 'react';

import CommonTable from '@common/CommonTable';
import moment from 'moment';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Store from '@data/Store';

const { language } = Store;

export default function HistoricSub({
  action,
  APIEndpoints,
  handleOpenHistory,
  fk_id_user,
  fk_id_device,
  real_id,
  paramSub
}) {
  let [tickets, setTickets] = useState([]);
  let [reload, setReload] = useState(true);
  const isMountedRef = useRef(null);

  useEffect(() => {

    setTimeout(() => {
      setReload(false)
      getTickets();
    }, 4000);


    //getTickets();
    return () => {
      setTickets([]);
    };


  }, []);

  function getTickets() {
    /*if implementado pois mesmo para tickets que não tinham subchamados aparecia o ticket pai
      então como solução passei o parametro paramSub que da tela anteriror e o verifico se esta ativo aqui
    */
    if(paramSub == true){
    action
      .execute(
        'post',
        APIEndpoints.TICKET + '/ticketFind',
        //   'tickets',
        null,
        { idsub: real_id.split('.')[real_id.split('.').length -1] ,subCalls : true },
        null,
        null,
        null
      )
      .then((g) => {
        setTickets(g.data);
      });
      }else{
        setTickets([]);
      }
  }

  let datas = tickets.map((obj) => {
    return {
      action: (
        <i
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenHistory(obj)}
          className="fas fa-eye"
          style={{ color: 'var(--primary)' }}
        />
      ),

      id: obj.real_id,
      solicitant: obj.grid_user,
      solicitanttech: obj.techsol,
      status: obj.grid_waiting,
      contract: obj.namecontract,
      group_users: obj.grid_tech_group,
      grid_category: obj.grid_category,
      catalogservice: obj.catalogservice,
      grid_catalog_task: obj.grid_catalog_task,
      department: obj.department,
      dt: moment(obj.dt_cad_request || obj.dt_cad).format('DD/MM/YYYY'),
      hour: moment(obj.dt_cad_request || obj.dt_cad).format('HH:mm'),

      subject: obj.subject,
    };
  });


  /**  group_users: obj.grid_tech_group,
      grid_category: obj.grid_category,
      catalogservice: obj.catalogservice,
      grid_catalog_task: obj.grid_catalog_task, */
  return (
     reload ? <div style={{textAlign:"center"}}><CircularProgress color="secondary" /></div>:
    <CommonTable
      bar={false}
      index="ticket-detail-history-call"
      searchColumn
      paper={false}
      mini
      col={[
        { key: 'action', label: language.ACTION },
        { key: 'id', label: language.ID },
        { key: 'solicitant', label: language.SOLICITANT },
        { key: 'solicitanttech', label: `${language.SOLICITANT} Tec.` },
        { key: 'status', label: language.STATE },
        { key: 'contract', label: language.CONTRACT },
        { key: 'group_users', label: language.TECH_GROUP },
        { key: 'grid_category', label: language.CATEGORY },
        { key: 'catalogservice', label: language.SUB_CATEGORY },
        { key: 'grid_catalog_task', label: language.THIRD_LEVEL },
        { key: 'department', label: language.DEPARTMENT },
        { key: 'dt', label: language.DATE },
        { key: 'hour', label: language.HOUR },
        { key: 'subject', label: language.SUBJECT },
      ]}
      data={datas}
    />
  );
}
