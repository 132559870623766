import React from 'react';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import CommonForm from '@common/CommonForm2';
import Progress from '@common/Progress';
import { Card, Avatar } from '@material-ui/core';
import './ProjectPortfolio.css';
import Dialog from '@common/Dialog';
import moment from 'moment';
export default function (props) {
	let { language, activity,redirect } = props;

	let riskColor = ['var(--success)', 'var(--warning)',
		'var(--danger)'];
	//let riskType = ['Aceitável', 'Crítico','Intolerável'];
	let riskType = ['Baixo', 'Médio', 'Alto'];
	let data =
		activity.map(g => (

			{  
				'#': (
					<Button
						onClick={() =>
							redirect('/dash/gp/list/detail', {
								id_project: g.id_pr,
								projectSelect: g,
								router: 'pmo'
							})}
						icon='fas fa-eye'
						variant='circle'
						outlined
						style={{ border: 'none' }}
					/>
				),
				id_project:g.id_pr,
				projeto: g.nameprojeto,
				atividade:'#'+g.id_project_activity+' - '+g.name,
				progress: (
					<div className='d-flex align-items-center' style={{ width: 80 }}>
						<Progress hiddenText className='w-100' style={{ height: 6 }} current={g.percent} />
						<span className='ml-2'>{g.percent}%</span>
					</div>
				),
				dt_ini: moment(g.dt_start).format('L'),
				dt_end: moment(g.dt_end).format('L')

			}


		))
		data =data.sort((a, b) => { return a.id_pr > b.id_pr; });

	return (
		<Dialog
			title='Projetos'
			open={true}
			onClose={() => this.setOpen(false)}
			maxWidth={'xl'}
			contentProps={{
				className: 'pt-4'
			}}
		>
			<div id='ProjectPortfolio'>
				<CommonTable
					title='Portfólio do Projeto'
					searchWidth={200}
					paginationTop={false}
					mini
					col={[
						{ key: '#', label: '',style: { paddingRight: 5 } },
				
						{key: 'id_project', label: 'Id Projeto' },
						{ key: 'projeto', label: 'Projeto' },
						{ key: 'atividade', label: 'Atividade' },
						{ key: 'progress', label: language.PERCENTAGE },
						{ key: 'dt_ini', label: language.INITIAL_DATE },
						{ key: 'dt_end', label: language.END_DATE }
					]}
					data={data}
				/>
			</div>
		</Dialog>
	)

}