import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { observer } from 'mobx-react';
import { Card } from '@material-ui/core';
import { Radial } from 'sensrit-charts';

@observer
export default class PieChartGraph extends Component {
  render() {
    let { language, nAnswers, nAsks } = this.props;
    let data01 = [{ name: 'Answers', value: nAnswers }, { name: 'Asks', value: nAsks }];

    let color = 'var(--red)';
    let perc = (nAnswers * 100) / nAsks;

    if (perc > 85) color = '#33ABA0';
    else if (perc > 60) color = '#EFA824';
    else if (perc > 35) color = 'var(--yellow)';

    return (
      <Card>
        <h2 style={{ padding: 2, fontSize: 12, textAlign: 'left', height: 30 }}>{this.props.title}</h2>
        <Row>
          <Col md={12}>
            <Radial color={color} className='dash-contracts-radial' size={90} current={perc} total={100} thick={30} />
          </Col>
          <Col md={12} style={{ height: 'auto' }}>
            <div className='footerCardGr'>
              <h3>{this.props.answers}:</h3> <h2>{this.props.n1}</h2>
              <h3>{this.props.asks}:</h3> <h2>{this.props.n2}</h2>
            </div>
          </Col>
        </Row>
      </Card>
    )
  }
}
