import moment from 'moment';
import Constants from '@data/Constants';
import Store from '@data/Store';

const { language } = Store;

let status = {
  TODO: language.TO_DO,
  DOING: language.DOING,
  STOPPED: language.STOPPED,
  BACKLOG: 'Backlog',
  VALIDATION: language.VALIDATION,
  DONE: language.DONE,
};

let risks = {
  1: {
    label: language.LOW,
    color: 'var(--primary)',
  },
  2: {
    label: language.MEDIUM,
    color: 'var(--warning)',
  },
  3: {
    label: language.HIGH,
    color: 'var(--danger)',
  },
};

function photo(e, users) {
  let _users = (e.usersphoto || []).map(e => {

     let photo = e.photo && `${Constants.APIRoot}/public/users/${e.id_user}/profileLogo.png`;

     return {
        id: e.id_user,
        src: photo || undefined,
        name: e.name_user || ''
     }
  });

  return _users;
}

export function activitiesList({ e, a, users }) {

  return {
    id_project: e.id_project,
    name: e.name,

    id: a.id_project_activity,
    activity: a.name,
    sponsors: photo(a, users).filter(Boolean),
    evolution: parseInt(a.percent),
    status: status[a.stage || 'BACKLOG'],
    dt_i: moment(a.dt_start).format('L'),
    dt_f: moment(a.dt_end).format('L'),
    dt_end_realized : a.dt_end_realized ? moment(a.dt_end_realized).format('L') : ''
  };
}

export function projectList({ e, activitiesTags = [], users }) {
  if (e) {

    let realized_cost = 0;
    (e.valoractivity || []).map(e=>{
      let [userName, valueConsumed, activity, timeActivity ] = e.split('**');

     realized_cost +=parseFloat(valueConsumed)||0
   });


    return {
      id: e.id_project,
      real_id: e.real_id,
      company: e.fk_id_company,
      color: e.utils.isDelay ? 'var(--danger)' : 'var(--primary)',
      project: e.name,
      manager: e.usercadname,
      project_type: e.type_project,
      priority: risks[e.type_risk], //7.22
      status: e.pause_project == true ? language.IN_PAUSE :
        e.utils.awaitExecution && !e.deleted
          ? language.AWAITING_EXECUTION
          : e.utils.inExecution && !e.deleted && e.percent != 100
            ? language.RUNNING
          : e.utils.isDelay && !e.deleted
              ? language.DELAYED
          : e.utils.concluded || e.percent == 100
                ? language.CONCLUDED
          : e.utils.concludedDelay
                  ? language.COMPLETED_LATE
          : (e.utils.isDelay ||
              e.utils.inExecution ||
              e.utils.awaitExecution) &&
            e.deleted
          ? language.CANCELED
          : null,
      percent: parseInt(e.percent) || 0,
      expected_start: moment(e.start_date).format('L'),
      expected_end: moment(e.end_date).format('L'),
      realized_start: e.utils.start_dt_realized
        ? moment(e.utils.start_dt_realized * 1000).format('L')
        : '',
      realized_end: e.utils.end_dt_realized
        ? moment(e.utils.end_dt_realized * 1000).format('L')
        : '',
      expected_cost: parseFloat(e.cust_project),
      realized_cost: parseFloat(e.realized_cost2)+realized_cost,
      goal: e.goal,
      activities: [
        { label: language.PROJECT_STATUS.TO_DO, value: e.utils.TODO.length + e.utils.BACKLOG.length },
        { label: language.PROJECT_STATUS.DOING, value: e.utils.DOING.length },
        { label: language.PROJECT_STATUS.STOPPED, value: e.utils.STOPPED.length },
        { label: language.PROJECT_STATUS.VALIDATION, value: e.utils.VALIDATION.length },
        { label: language.PROJECT_STATUS.DONE, value: e.utils.DONE.length },
      ],
      data: activitiesTags,
    };
  }
}
