import React, { useEffect } from 'react';
import Store from '@data/Store';

var Highcharts = require('highcharts');

const { language } = Store;

export function TotalTaskChart({ totalTasks }) {
  let totalVencido = totalTasks.totalVencido || 0;
  let totalPrazo = totalTasks.totalPrazo || 0;
  let totalCompleto = totalTasks.totalCompleto || 0;

  var chart;
  var config = {
    chart: {
      type: 'bar',
    },
    credits: { enabled: false },

    plotOptions: {
      bar: {
        colorByPoint: true,
      },
    },
    colors: ['#E9C3C3', '#E9DFC3', '#C8E9C3'],

    title: {
      text: '',
    },
    xAxis: {
      categories: [language.EXPIRED, language.ON_TIME, language.CONCLUDED],
    },
    yAxis: {
      title: {
        text: language.VALUES
      }
    },

    series: [
      {
        type: 'bar',
        data: [totalVencido, 0, 0],
        name: language.EXPIRED,
        color: '#E9C3C3',
      },
      {
        type: 'bar',
        data: [0, totalPrazo, 0],
        name: language.ON_TIME,
        color: '#E9DFC3',
      },
      {
        type: 'bar',
        data: [0, 0, totalCompleto],
        name: language.CONCLUDED,
        color: '#C8E9C3',
      },

      // {
      //   data: [
      //     [totalTasks.totalVencido],
      //     [totalTasks.totalPrazo],
      //     [totalTasks.totalCompleto],
      //   ],
      // },
    ],
  };

  useEffect(() => {
    if (chart) {
      if (window.Highcharts) {
        chart = window.Highcharts.chart(chart, config);
      }
    }

    return function () {
      if (chart) {
        chart.destroy();
      }
    };
  }, [config]);

  return <div ref={(ref) => (chart = ref)} />;
}
