import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import React, {useEffect} from 'react';
import { render } from 'react-dom';
import './main.css';
import Routes from './Routes';
import SrContext from './common/Context/index';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ReportHandler } from 'web-vitals';
/*import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";*/
import './root.css';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      //useLocation,
      //useNavigationType,
      //createRoutesFromChildren,
      //matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.02,
  replaysOnErrorSampleRate: 0.02,
  tracesSampleRate: 1.0,
  environment: process.env.SENTRY_ENVIRONMENT,
});


if (!global._babelPolyfill) {
  require('babel-polyfill');
}

const muiTheme = createTheme({
  fontFamily: 'GeosansLight',
  datePicker: {
    selectColor: '#009dc0',
  },
  palette: {
    primary1Color: '#009dc0',
    accent1Color: '#009dc0',
    pickerHeaderColor: '#009dc0',
    textColor: '#000',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#009dc0',
        },
        '&:hover $notchedOutline': {
          borderColor: '#009dc0',
        },
        '&$focused $notchedOutline': {
          borderColor: '#009dc0',
        },
      },
    },
  },
});

render(
  <MuiThemeProvider theme={muiTheme}>
    <SrContext>
      <Routes />
    </SrContext>
  </MuiThemeProvider>,
  document.getElementById('content')
);

if (module.hot) {
  module.hot.accept('./Routes', () => {
    const NextApp = require('./Routes').default;
    render(
      <MuiThemeProvider muiTheme={muiTheme}>
        <SrContext>
          <NextApp />
        </SrContext>
      </MuiThemeProvider>,
      document.getElementById('content')
    );
  });
}
