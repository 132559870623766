import React, { Component, Fragment, useCallback } from 'react';

import {
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Switch,
  Card,
  Slider
} from '@material-ui/core';
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from '@material-ui/core/styles';
import classnames from 'classnames';

import { observer } from 'mobx-react';
import Store from '../../data/Store';
import './style.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import SimplesAlert from '@common/SimplesAlert';
import AttachFile2 from '@common/AttachFile2';
import RaisedButton from '@common/RaisedButton';
import Button from '@common/Button';
import AppBar from '@common/AppBar';
import { toBase64 } from '@common/Func';
import Tooltip from '@material-ui/core/Tooltip';

// ----------------------------------------pages-----------------------------------------------
import MyTextField from './TextField';
import ListCheckbox from './ListCheckbox';
import TextEditor from './TextEditor';
import SimplesTextEditor from './SimplesTextEditor';
import MyPickers from './MyPickers';
import MySelect from './MySelect';
import BtnGroup from './BtnGroup';
import TextArea from './TextArea';
import AutoComplete from './AutoComplete';
import TagEditor from './TagEditor';
import Color from './Color'
const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'var(--primary)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'var(--primary)',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            // borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: 'var(--primary)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'var(--primary)',
        },
      },
    },
  },
});

const styles = {
  //   underline: {
  //     '&:not($error):before': {
  //       borderBottomColor: 'var(--primary) !important',
  //     },
  //     /*'&:not($error):after': {
  // 			borderBottomColor:'var(--primary) !important'
  // 		}*/
  //   },
  label: {
    '&:not($error)': {
      color: 'var(--primary)',
    },
  },
  rootSearchListCheckbox: {
    '&:not($error):not($hover) fieldset': {
      borderColor: '#629daa80 !important',
    },
    '&:not($error):hover fieldset': {
      borderColor: 'var(--primary) !important',
    },
  },
};
@observer
class CommonForm2 extends Component {
  constructor(props) {
    super(props);
    this.store = Store;
    this.state = {
      values: {},
      errors: {},
      seted: false,
      emails_chat: [],
      alert: {
        open: false,
        variant: '',
        message: '',
      },
      readOnly: {},
      isMobile: false,
      isSubmitting: false
    };
    this.statusError = {
      1: this.store.language.FILL_THE_FIELD,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    let { onRef = () => null } = this.props;
    onRef(this);
    this.setValuesRender(this.props);
    window.addEventListener("resize", this.handleResize())
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.values && !this.state.seted || this.props.forceUpdate) {
      this.setValuesRender(nextProps);
      if (this.props.sensitive) {
        this.setState({ seted: true });
      }
    }

    if ((this.state.seted && Object.keys(this.state.values).length == 0)) {
      this.setValuesRender(nextProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    if (window.innerWidth < 720) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }


  setValuesRender = (props) => {
    let { fields, errors: _errors } = props;
    let errors = Object.assign(this.state.errors, _errors);

    fields = fields.filter((e) => e.visible != false);

    var values = Object.assign({}, props.values);

    fields.forEach((e) => {
      if (e.error) {
        errors[e.name] = e.error;
      }
    });

    //this.setState({ errors });
    if (values) {
      let this_values = Object.assign({}, this.state.values);

      if (this.props.updateStateForm) {
        this_values = Object.assign(this.state.values, values);
      }

      fields.forEach((e) => {
        if (e.name) {
          if (e.input) {
            values[e.name] = e.input(values[e.name]);
          }
        }
      });

      Object.keys(values).forEach((k) => {
        if (k == 'file' || k == 'files') {
          if (values[k]) {
            this_values[k] = values[k];
          }
        } else {
          if ((values[k] !== undefined && this_values[k] == undefined) || fields.find(f => f.name == k)?.dependsOn ) {
            this_values[k] = values[k];
            this_values[fields.find(f => f.name == k)?.dependsOn] = fields.find(f => f.name == k)?.value
          }
        }
      });
      this.setState({ values: this_values });
    }
    fields.forEach((e) => {
      if (e.name) {
        if (e.readOnly && e.type == 'simplesTextEditor') {
          this.setReadOnly(e.name, true, false);
        }
      }
    });
    if (props.fixedValue) {
      this.setState((state) => ({
        values: { ...state.values, ...props.fixedValue },
      }));
    }
  };
  toggleAlert = (open, message = '', variant = this.state.alert.variant) => {
    var { alert } = this.state;
    alert.open = open;
    alert.message = message;
    alert.variant = variant;
    this.setState({ alert });
  };
  handleValue = (val, name, selected) => {
    let { errors, values } = this.state;
    let {
      onChangeField = () => null,
      fields: fd = [],
      childFields = [],
    } = this.props;

    let fields = fd
      .filter((e) => e.visible != false)
      .concat(childFields.filter((e) => e.visible != false));
    values[name] = val;

    if (val && errors[name] == 1) errors[name] = undefined;
    this.setState({ values, errors }, () => {
      onChangeField(
        fields.filter((e) => e.visible != false).find((e) => e.name == name),
        val,
        selected,
        this.handleValue,
        fields,
        Object.assign({}, values),
      );
    });
  };
  setReadOnly = (name, v, alert = true) => {
    let readOnly = Object.assign({}, this.state.readOnly);
    readOnly[name] = v;
    this.setState({ readOnly });
    if (alert) {
      this.toggleAlert(
        true,
        `${this.store.language.EDITION} ${v ? this.store.language.DISABLED : this.store.language.ENABLED
        }`,
        'warning'
      );
    }
  };
  handleFile = (val, name) => {
    let { values } = this.state;
    var file = values[name] || [];
    values[name] = file.concat(val);

    this.handleValue(values[name], name);
  };
  handleEmail_Chat = (email) => {
    let { emails_chat } = this.state;

    emails_chat.push(email);
    this.setState({ emails_chat });
  };

  removeFile = (val, name) => {
    let { values } = this.state;
    var file = values[name] || [];
    values[name] = file.filter((e) => e != val);

    this.handleValue(values[name], name);
  };
  getFields = () => {
    let { fields, childFields = [] } = this.props;
    return fields
      .filter((e) => e.visible != false)
      .concat(childFields.filter((e) => e.visible != false));
  };
  submit = async () => {
    let {
      onSubmit = () => null,
      _onSubmit = () => null,
      fields,
      childFields = [],
      alert = true,
      clean,
    } = this.props;

    let { values, errors: _errors } = this.state;
    let errors = Object.assign({}, _errors);
    // -------------------------TRATAMENTO DE ERROS-----------------------------
    var msgError = this.store.language.FILL_REQUIRED;
    var fd = fields
      .filter((e) => e.visible != false)
      .concat(childFields.filter((e) => e.visible != false));

    var checkRequired = fd
      .filter(
        (e) => {
          const r = e.required &&
            (typeof values[e.name] == 'string'
              ? !values[e.name].replace(/<\/?[^>]+(>|$)/g, '')
              : Array.isArray(values[e.name])
                ? !values[e.name].length
                : !values[e.name] && typeof values[e.name] !== "boolean");
          return r;
        })
      .map((e) => {
        errors[e.name] = 1;
        if (e.type == 'file') {
          msgError = this.store.language.SELECT_A_FILE;
        }
        return e.name;
      });

    // -------------------------------------------------------------------------

    var _values = Object.assign({}, values);
    var outPutField = fields
      .filter((e) => e.output && e.visible != false)
      .map((e) => {
        _values[e.name] = e.output(values[e.name]);
      });

    for (const [idx, e] of fd.filter((e) => e.type == 'file').entries()) {
      if (e.toBase64 && values[e.name]) {
        _values[e.name] = await toBase64(
          values[e.name].filter((e) => e instanceof File)
        );
      }
    }

    // _values.email_chat  = this.state.emails_chat;
    _onSubmit(_values);

    if (!!checkRequired.length) {
      this.setState({ errors });
      this.toggleAlert(alert, msgError, 'error');
      return false;
    } else {
      if (!fields.some((e) => e.error) && !this.state.isSubmitting) {
        if (clean) {
          this.clean();
        }

        this.setState({ isSubmitting: true }, () => {
          onSubmit(_values);

          setTimeout(() => {
            this.setState({ isSubmitting: false });
          }, 2000);
        });

        return true;
      }
    }
  };
  clean = () => {
    let { fields, childFields = [] } = this.props;
    let fd = fields
      .filter((e) => e.visible != false)
      .concat(childFields.filter((e) => e.visible != false));

    this.setState({
      values: fd.reduce((obj, e) => {
        obj[e.name] = undefined;
        return obj;
      }, {}),
    });
  };
  render() {
    var {
      spacing,
      margin,
      variant,
      beforeButtonElement,
      afterButtonElement,
      button = {},
      tags,
      individual,
      classes,
      comp,
      white,
      btnContentProps = {},
      hiddenSubmit,
      childFields = [],
    } = this.props;

    //classes = !individual?classes:{}
    let { language } = this.store;
    let { values, errors, readOnly } = this.state;

    const getGridCol = (f) => this.state.isMobile && f.colMobile ? f.colMobile : f.col || 12;

    let fields = this.props.fields
      .filter((e) => e.visible != false)
      .map((f, index) => {
        var component = null;
        switch (f.type) {
          case 'element':
            component =
              typeof f.content == 'function'
                ? f.content(
                  values[f.name],
                  this.handleValue,
                  this.submit,
                  this.statusError[errors[f.name]]
                )
                : <Fragment key={index}>{f.content}</Fragment>;
            break;
          case 'tab':
            let { AppBarProps = {} } = f;
            component = (
              <fieldset
                key={index}
                {...f.fieldsetProps}
                className={classnames(
                  'common-tab',
                  (f.fieldsetProps || {}).className
                )}
              >
                <legend {...f.legendProps}>
                  {typeof f.label == 'string' ? (
                    <AppBar
                      light
                      {...AppBarProps}
                      style={{
                        top: 0,
                        margin: 0,
                        width: 135,
                        textTransform: 'uppercase',
                        ...AppBarProps.style,
                      }}
                    >
                      <span className="font-roboto">{f.label}</span>
                    </AppBar>
                  ) : (
                    f.label
                  )}
                </legend>
              </fieldset>
            );
            break;

          case 'btnGroup':
            component = (
              <BtnGroup
                f={f}
                key={index}
                statusError={this.statusError}
                individual={individual}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
              />
            );
            break;

          case 'select':
            component = (
              <MySelect
                f={f}
                key={index}
                classes={classes}
                statusError={this.statusError}
                individual={individual}
                variant={variant}
                margin={margin}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
                language={language}
                isMulti={f.isMulti}
                isMultiMin={f.isMultiMin}
              />
            );
            break;
          case 'autocomplete': {
            component = (
              <AutoComplete
                key={index}
                disabledTooltip={f.disabledTooltip || false}
                f={f}
                route={f.route}
                fieldquery={f.fieldquery}
                fieldvaluedb={f.fieldvaluedb}
                fieldlabeldb={f.fieldlabeldb}
                textlabel={f.textlabel}
                showEmptyFilter={f.showEmptyFilter}
                method={f.method}
                idinit={f.idinit}
                useinit={f.useinit}
                filter={f.filter}
                textinit={f.textinit}
                resetValue={f.resetValue}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                routeAll={f.routeAll}
                methodGetAll={f.methodGetAll}
                routeGetCount={f.routeGetCount}
                filterAll={f.filterAll}
                showOptionAll={f.showOptionAll}
                errors={errors}
                showvip={f.showvip}
              />
            );
            break;
          }
          case 'tagTextEditor':
            component = (
              <TagEditor
                key={index}
                tags={tags}
                f={f}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                handleEmail_Chat={this.handleEmail_Chat}
                errors={errors}
                onKeyDown={f.onKeyDown || (() => null)}
              />
            );
            break;
          case 'file':
            component = (
              <AttachFile2
                key={index}
                files={Array.from(values[f.name] || [])}
                label={f.label + (f.required ? '*' : '')}
                onRemove={(val) => {
                  if (f.maxFiles > 0 || f.disabled != true) {
                    this.removeFile(val, f.name);
                    f.removeFile ? f.removeFile(val) : null;
                  }
                }}
                accept={f.accept}
                maxFiles={f.maxFiles}
                onDrop={(val) => this.handleFile(val, f.name)}
                btnBottom={f.btnBottom}
                btnVisible={f.btn}
                btnProps={f.btnProps}
                customPortalCss={f.customPortalCss}
                scrollProps={f.scrollProps}
                onClick={(v) => (f.onClick ? f.onClick(v) : null)}
                disabled={f.disabled}
                showFiles={f.showFiles}
                error={f.errorAttach}
              />
            );
            break;
          case 'switch':
            f.tooltip
              ? (component = (
                <Tooltip title={f.tooltip}>
                  <FormControlLabel
                    key={index}
                    control={
                      <Switch
                        checked={!!values[f.name]}
                        onChange={(evt) => {
                          this.handleValue(evt.target.checked, f.name)
                        }
                        }
                      />
                    }
                    label={f.label}
                  />
                </Tooltip>
              ))
              : (component = (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={!!values[f.name]}
                      onChange={(evt) => {
                        this.handleValue(evt.target.checked, f.name)
                      }
                      }
                      disabled={f.disabled ?? false}
                    />
                  }
                  label={f.label}
                />
              ));
            break;
          case 'check':
            component = (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={!!values[f.name]}
                    onChange={(evt) =>
                      this.handleValue(evt.target.checked, f.name)
                    }
                  />
                }
                label={f.label}
              />
            );
            break;            
          case 'date':
          case 'time':
            component = (
              <MyPickers
                f={f}
                id={f.name}
                key={index}
                classes={classes}
                statusError={this.statusError}
                individual={individual}
                variant={variant}
                margin={margin}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
              />
            );
            break;
          case 'simplesTextEditor':
            component = (
              <SimplesTextEditor
                f={f}
                key={index}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
                readOnly={readOnly}
                setReadOnly={this.setReadOnly}
                individual={individual}
              />
            );
            break;
          case 'textEditor':
            component = (
              <TextEditor
                f={f}
                key={index}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                handleEmail_Chat={this.handleEmail_Chat}
                errors={errors}
                onKeyDown={f.onKeyDown || (() => null)}
              />
            );
            break;
          case 'textArea':
            component = (
              <TextArea
                f={f}
                key={index}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
                onKeyDown={f.onKeyDown || (() => null)}
              />
            );
            break;

          case 'listCheckbox':
            component = (
              <ListCheckbox
                f={f}
                key={index}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                _handleFilter={f.handleFilter}
                errors={errors}
                language={language}
                classes={classes}
              />
            );
            break;
          case 'listCheckboxSingle':
            component = (
              <ListCheckbox
                f={f}
                key={index}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
                language={language}
                single={true}
                theme={theme}
              />
            );
            break;

          case 'color':
            component = (
              <Color
                f={f}
                key={index}
                statusError={this.statusError}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
                language={language}
                single={true}
                theme={theme}
              />
            );
            break;
          case 'html5-color':
            const fieldSwap = { ...f };
            fieldSwap.type = 'color';
            component = (
              <MyTextField
                f={fieldSwap}
                key={index}
                classes={classes}
                statusError={this.statusError}
                individual={individual}
                variant={variant}
                margin={margin}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
              />
            );
            break;
          default:
            component = (
              <MyTextField
                f={f}
                key={index}
                classes={classes}
                statusError={this.statusError}
                individual={individual}
                variant={variant}
                margin={margin}
                _value={values[f.name]}
                _handleValue={this.handleValue}
                errors={errors}
              />
            );
            break;
        }

        return (
          <Grid
            item
            xs={getGridCol(f)}
            {...f.contentProps}
            style={{
              maxWidth: 8.33333333 * (getGridCol(f)) + '%',
              flexBasis: 8.33333333 * (getGridCol(f)) + '%',
              ...(!['listCheckbox', 'textEditor', 'listCheckboxSingle'].includes(f.type)
                ? {
                  paddingTop: 0,
                  paddingBottom: 0,
                }
                : {}),
              ...(f.contentProps || {}).style,
            }}
            className={classnames(
              'form-field',
              (f.contentProps || {}).className
            )}
          >
            {f.beforeContent}
            {component}
            {f.afterContent}
          </Grid>
        );
      });

    /*let fd = this.props.fields.filter(e=>e.visible!=false).concat(childFields.filter(e=>e.visible!=false));
    let checkRequired = fd.filter(e=>
      (e.required &&

      ((typeof values[e.name]=='string') ?

        !values[e.name].replace(/<\/?[^>]+(>|$)/g, ""):

      Array.isArray(values[e.name])?
         !values[e.name].length:

      !values[e.name]))

    ).map(e=> e.name);*/
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classnames('CommonForm2', { white })}>
          <Grid
            container
            alignItems="flex-start"
            spacing={spacing || 4}
            {...this.props.gridProps}
          >
            {fields}
          </Grid>
          {!hiddenSubmit &&
            (beforeButtonElement ||
              this.props.onSubmit ||
              afterButtonElement) && (
              <Grid
                container
                alignItems="flex-end"
                spacing={spacing || 4}
                {...btnContentProps}
                style={{
                  marginTop: '1.5rem',
                  ...(btnContentProps.style ? btnContentProps.style : {}),
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  {beforeButtonElement}
                  {this.props.onSubmit && (
                    <Button
                      variant="normal"
                      icon={button.icon || 'fas fa-save'}
                      color={button.color || 'success'}
                      disabled={button.disabled}
                      // loading={!!this.store.loading}
                      onClick={() => (!button.disabled ? this.submit() : null)}
                      label={button.label || language.SAVE}
                      loading={Store.$btn_loading(this.props.loading)}
                      {...button}
                      style={{
                        ...button.style,
                      }}
                    />
                  )}
                  {afterButtonElement}
                </Grid>
              </Grid>
            )}
          <SimplesAlert
            float
            open={this.state.alert.open}
            message={this.state.alert.message}
            variant={this.state.alert.variant}
            onClose={() => this.toggleAlert(false)}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(CommonForm2);
