import React, { memo, useState } from 'react';
// ------------------------commmon------------------------------------
import AppBar from '@common/AppBar2';
import ReactHighcharts from 'react-highcharts';
import ButtonControl from '../ButtonControl';
// ------------------------material-ui------------------------------------
import { Card, Grid } from '@material-ui/core';
import { radial } from './Graphs';
import withLanguage from '../../../../common/languages/withLanguage';
import Store from '@data/Store';

export default memo(
  withLanguage(({ page, data: _data, OpenNPSTickets, language }) => {
    let [question, setQuestion] = useState(1);
    let data = _data[question];
    let colors = ['var(--success)', 'var(--warning)', 'var(--danger)'];
    let total = data.reduce((n, e) => n + e, 0);

    return (
      <Card id="NPS">
        <AppBar title={language.YOUR_POINT_AVERAGE_NPS} subTitle={`(${language.TOTAL_MONTH})`} />
        <div className="content-medium pt-0">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ReactHighcharts
                config={radial({
                  name: 'NPS',
                  titlePosition: 25,
                  data: [
                    { name: language.PROMOTERS, y: data[0], color: colors[0] },
                    { name: language.PASSIVATORS, y: data[1], color: colors[1] },
                    { name: language.DETRACTORS, y: data[2], color: colors[2] },
                  ],
                  title: { content: total, style: 'color:var(--default)' },
                  subTitle: { content: language.ASSESSMENTS },
                  width: 150,
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li
               //   onClick={() => OpenNPSTickets('promotores', question)}
                  // style={{ cursor: 'pointer' }}
                >
                  <i className="far fa-grin-beam" />
                  <span className="number">
                    {parseInt((data[0] * 100) / total) || 0}%
                  </span>
                  <span className="text">{language.PROMOTERS}</span>
                </li>
                <li
               //   onClick={() => OpenNPSTickets('passivadores', question)}
                  // style={{ cursor: 'pointer' }}
                >
                  <i className="far fa-smile" />
                  <span className="number">
                    {parseInt((data[1] * 100) / total) || 0}%
                  </span>
                  <span className="text">{language.PASSIVATORS}</span>
                </li>
                <li
                //  onClick={() =>  OpenNPSTickets('detratores', question)}
                  // style={{ cursor: 'pointer' }}
                >
                  <i className="far fa-frown" />
                  <span className="number">
                    {parseInt((data[2] * 100) / total) || 0}%
                  </span>
                  <span className="text">{language.DETRACTORS}</span>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <div className="content-medium -mt-4">
          <ButtonControl
            value={question}
            options={[
              {
                value: 1,
                label:
                  `(1) ${language.EVALUATION_REGARDING_CALL_RESOLUTION_TIME}`,
              },
              {
                value: 2,
                label:
                  `(2) ${language.EVALUATION_REGARDING_CALL_RESOLUTION_QUALITY}`,
              },
              {
                value: 3,
                label:
                  `(3) ${language.EVALUATION_REGARDING_ATTENDANCE_TEAM}`,
              },
            ]}
            onChange={(e) => setQuestion(e)}
          />
        </div>
      </Card>
    );
  },
  function (prev, next) {
    let omit = (obj) => _.omitBy(obj, (val) => typeof val == 'function');
    return _.isEqual(omit(prev), omit(next));
  }
));
