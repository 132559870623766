const ContainerChart = {

  container0(e){
    let {title, subtitle, yTitle, data, size, series} = e;
    return {
     
      chart: {
          type: 'areaspline'
      },
      title: {
          text: title||'',
          style:{
            color: '#616161',
            
            fontFamily: 'sans-serif'
          }  
      },
      subtitle: {
        text: subtitle||''
      },
      xAxis: {
          type: 'category'
      },
      yAxis: {
          title: {
              text: yTitle||''
          }
      },
      tooltip: {
          shared: true,
          valueSuffix: ' units'
      },
      credits: {
          enabled: false
      },
      plotOptions: {
          areaspline: {
              fillOpacity: 0.5
          }
      },
      series
    }
  },
}
export default ContainerChart;