import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Search from './Search';
import ServicePortalTableWrapper from './ServicePortalTableWrapper';
import ServicePortalTicketSubTitle from './ServicePortalTicketSubTitle';

export default function ServicePortalTicketHistory({ language, ticketData }) {

    const [searchText, setSearchText] = useState('');
    const [rowItems, setRowItems] = useState([]);
    const [isTableView, setIsTableView] = useState(false);

    useEffect(() => {
        if (ticketData.notesAsTable) {
            setIsTableView(true);
            if (Array.isArray(ticketData.notesAsTable) && ticketData.notesAsTable.length > 0) {
                setRowItems(
                    ticketData.notesAsTable.map(item => ({
                        user: item.user_name,
                        date: moment(item.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
                        action: item.data
                    }))
                );
            }
            else
                setRowItems([]);

        } else
            setRowItems(
                ticketData.notes.split('<hr/>').map(item => ({
                    description: item,
                }))
            );

    }, [ticketData.notes, ticketData.notesAsTable])

    const rows =
        rowItems.filter((item) => (isTableView ? item.action : item.description)?.toLocaleLowerCase().indexOf(searchText) > -1)

    const columns = isTableView ? [
        {
            id: 'user',
            label: language.USER
        },
        {
            id: 'date',
            label: language.DATE
        },
        {
            id: 'action',
            label: language.ACTION,
            render: ({ value }) => <p dangerouslySetInnerHTML={{ __html: value }} />
        },
    ] : [
        {
            id: 'description',
            label: language.HISTORIC,
            render: ({ value }) => <p dangerouslySetInnerHTML={{ __html: value }} />
        }
    ]

    return (
        <div>
            <ServicePortalTicketSubTitle label={language.HISTORIC} iconText='fa-history' />
            <div className='mb-2 tw-border tw-rounded tw-p-2 hr' style={{ minHeight: '18rem' }}>

                <div className='tw-mb-2 tw-rounded'>
                    <Grid container spacing={1}>
                        <Grid container item sm={3}>
                            <Search label={language.SEARCH} onChange={(event) => setSearchText(event.target.value?.toLocaleLowerCase())} />
                        </Grid>
                    </Grid>
                </div>

                <ServicePortalTableWrapper
                    size='small'
                    rows={rows}
                    columns={columns}
                    isLoading={false}
                />

            </div>
        </div>
    )
}
