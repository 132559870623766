import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import { Grid } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Store from '@data/Store';

const { language } = Store;

export default function ({
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  closeContract,
}) {
  let [companies, setCompanies] = useState([]);
  let [contractsOptions, setContractsOptions] = useState([]);
  let [open, setOpen] = useState(false);
  const onSubmit = (v, values) => {
    values.fk_id_company = v.fk_id_company;

    action
      .execute('post', APIEndpoints.USER + '/updatecompany', '', values)
      .then((c) => {
        setCompanies({ fk_id_company: c.data.map((b) => b.fk_id_company) });
      });
    closeContract();
  };

  useEffect(() => {
    action.execute('get', APIEndpoints.COMPANY, '').then((c) => {
      let array = c.data;

      setContractsOptions(
        array
          .filter((h) => h.id_company != values.id_company)
          .map((c) => ({ value: c.id_company, label: c.name }))
      );

      action
        .execute(
          'get',
          APIEndpoints.COMPANY + '/user/' + values.id_user,
          '',
          values
        )
        .then((c) => {
          setCompanies({ fk_id_company: c.data.map((b) => b.fk_id_company) });
        });
    });
  }, [values]);

  let form = [];
  return (
    <Dialog
      PaperProps={{
        id: '',
      }}
      title={
        values.role == 'tech'
          ? language.ASSOCIATE_TECH_X_COMPANY
          : language.ASSOCIATE_USER_X_COMPANY
      }
      open={true}
      onClose={closeContract}
    >
      <CommonForm
        values={companies}
        fields={[
          {
            name: 'fk_id_company',
            type: 'listCheckbox',

            options: contractsOptions,
          },
        ]}
        onSubmit={(v) => onSubmit(v, values)}
      />
    </Dialog>
  );
}
