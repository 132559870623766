import Button from '../../common/Button';
import React, { Component, Fragment } from 'react';
import { Card, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '../../common/RaisedButton';
import PropTypes from 'prop-types';
import { Container } from 'react-grid-system';
import { observer } from 'mobx-react';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import ReactTooltip from 'react-tooltip';
import CommonTable from '../../common/CommonTable';
import CommonForm from '@common/CommonForm2';
import moment from 'moment';
import { FaClone } from 'react-icons/lib/fa';
import crypt from '../../util/crypt';
import { filterObj } from '@common/Func';



export default class SnmpList extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      dialog: {
        title: '',
        open: false,
        type: 0
      },
      fk_id_manufacture: 0,
      disabled: {
        brand: true,
        brand2: true
      },
      token: '',
      oids: {
        id_brands: 0,
        itens: []
      },
      device: {
        id_device: 0,
        itens: []
      },
      values: {},

    };
  }


  toggleModal = (open, id) => {
    var data = { modal: open };
    if (id) {
      data.token = crypt(id.toString());
    }
    this.setState(data);
  }
  onChangeField = (f, v) => {
    let { disabled, dialog } = this.state;
    if (f.name == 'fk_id_manufacture' && dialog.type == 2) {
      disabled.brand = false;
      this.setState({ disabled })
    } else if (f.name == 'fk_id_manufacture' && dialog.type == 3) {
      disabled.brand2 = false;
      this.setState({ disabled, fk_id_manufacture: v })
    } else if (f.name == 'id_brands' && dialog.type == 3) {
      this.setState({
        oids: {
          id_brands: v,
          itens: []
        }
      })
    }
  }
  handleDialog = (open = false, title = '', type = 0) => {
    var { dialog, disabled } = this.state;
    dialog.open = open;
    dialog.title = title;
    dialog.type = type;

    disabled.brand = true;
    disabled.brand2 = true;
    this.setState({ dialog, disabled });
  }

  submitOid = (v) => {
    var { oids, device } = this.state;
    let { id_brands, id_device } = this.props;

    if (id_brands && id_device) {
      device.id_device = id_device;
      device.itens.push({ device_oids: v.oid, oid_description: v.description, time: v.time });
      this.setState({ device });
    } else {
      oids.itens.push({ device_oids: v.oid, oid_description: v.description })
      this.setState({ oids });
    }

  }

  render() {
    let { language, handlePage, redirect, companies, onFormSubmit, requestLoading, manufactures, oidFormSubmit, snmps, brands, submitSnmpEdit, id_brands, id_device } = this.props;
    let { dialog, values } = this.state;


    let col = [
      { key: 'id', label: language.ID, style: { width: 1 }, thConfig: true },
      { key: 'modelo', label: language.MODEL },
      { key: 'device_oids', label: 'OID' },
      { key: 'oid_description', label: language.DESCRIPTION },
      { key: 'dt_cad', label: language.DATE_CAD },
      {
        jc: 'flex-end',
        key: 'actions',
        label: language.ACTIONS,

      }
    ];


    let data = snmps.map(e => {
      return {
        id: e.id,
        modelo: e.name,
        device_oids: e.device_oids,
        oid_description: e.oid_description,
        dt_cad: moment(e.created_at).format('LL'),
        actions: (
          <div className='d-flex justify-content-end'>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant='circle'
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 15 }}
                    size={.72}
                    onClick={() => {
                      this.setState({ values: e });
                      this.handleDialog(true, `${language.EDIT} ${language.SNMP}`, 4)
                    }}
                    className='mr-2'
                  />

                  <Button
                    variant='circle'
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 15 }}
                    size={.72}
                    onClick={() => handlePage('remove', e)}
                  />
                </div>
              }
            />

          </div>
        )
      };
    });

    var manufacturesOptions = manufactures.map(e => ({ value: e.id, label: e.name }));

    var brandsOptions = brands.filter(e => e.tb_manufacturer_id == this.state.fk_id_manufacture).map(e => ({
      value: e.id_brand,
      label: e.name
    }))

    var listsnmp = snmps.filter(e => e.fk_id_brands == this.state.oids.id_brands);

    return (
      <Fragment>
        <CommonTable
          title={''}
          language={language}
          columns={col}
          data={data}
          showHeaderToolbar
          index="SnmpList"

          orderColumn
          paginationTop={false}
          searchColumn={true}
          beforeBar2={
            <div className='d-flex align-items-center' style={{ zoom: .8 }}>
              <Button
                label={`${language.ADD} ${language.MANUFACTURER}`}
                icon='fas fa-plus'
                variant='normal'
                color='warning'
                onClick={() => this.handleDialog(true, `${language.TO_REGISTER} ${language.MANUFACTURER}`, 1)}
                loading={requestLoading}
              />,
              <Button
                label={`${language.ADD} ${language.MODEL}`}
                icon='fas fa-plus'
                variant='normal'
                color='warning'
                className='mx-2'
                onClick={() => this.handleDialog(true, `${language.TO_REGISTER} ${language.MODEL}`, 2)}
                loading={requestLoading}
              />,
              <Button
                label={language.ADD + ' OIDs'}
                icon='fas fa-plus'
                variant='normal'
                color='warning'
                onClick={() => this.handleDialog(true, `${language.TO_REGISTER} OID`, 3)}
                className='mr-2'
                loading={requestLoading}
              />
            </div>
          }

          searchColumn
          orderColumn
          beforeBar={
            <RaisedButton
              circleButton
              icon='fas fa-plus'
              style={{ zoom: .8 }}
              zoom={.8}
              className='mr-3'
              color='primary'
              label={language.ADD_NEW_DEVICE}
              primary={true}
              onClick={() => {
                /* configurationProps.setIdx(0); 
                configurationProps.setBrand(true); */

                this.handleDialog(true, `${language.ADD} ${language.SNPM}`, 1)
              }}
            />
          }


        />

        <Dialog
          open={dialog.open}
          onClose={() => this.handleDialog()}
          title={dialog.title}
          maxWidth={[1, 2].includes(dialog.type) ? 'sm' : 'md'}
          monitor_scroll
        >
          {dialog.type == 1 ? (
            <Fragment>
              <CommonForm
                fields={[
                  {
                    col: 12,
                    name: 'name',
                    type: 'text',
                    label: language.MANUFACTURER,
                    required: true
                  }
                ]}
                onSubmit={(v) => { onFormSubmit(v, 1); this.handleDialog() }}
              />
            </Fragment>
          ) : dialog.type == 2 ? (
            <Fragment>
              <CommonForm
                fields={[
                  {
                    col: 6,
                    name: 'fk_id_manufacture',
                    type: 'select',
                    label: language.MANUFACTURER,
                    required: true,
                    options: manufacturesOptions
                  },
                  {
                    col: 6,
                    name: 'name',
                    type: 'text',
                    label: language.MODEL,
                    required: true,
                    disabled: this.state.disabled.brand
                  }
                ]}
                onChangeField={this.onChangeField}
                onSubmit={(v) => { onFormSubmit(v, 2); this.handleDialog() }}
              />
            </Fragment>
          ) : dialog.type == 3 ? (
            <Fragment>
              <Card className='content mb-3'>
                <CommonForm
                  fields={[
                    (!id_brands && !id_device ?
                      {
                        col: 4,
                        name: 'fk_id_manufacture',
                        type: 'select',
                        label: language.MANUFACTURER,
                        required: true,
                        options: manufacturesOptions,
                      } : null
                    ),
                    (!id_brands && !id_device ?
                      {
                        col: 4,
                        name: 'id_brands',
                        type: 'select',
                        label: language.MODEL,
                        required: true,
                        options: brandsOptions,
                        disabled: this.state.disabled.brand2
                      } : null
                    ),
                    {
                      col: (id_brands && id_device ? 6 : 4),
                      name: 'oid',
                      type: 'text',
                      label: 'OID',
                      required: true
                    },
                    (id_brands && id_device ? {
                      col: 6,
                      name: 'time',
                      type: 'time',
                      label: language.TIME,
                      required: true
                    } : null),
                    {
                      col: 12,
                      name: 'description',
                      type: 'textEditor',
                      label: language.DESCRIPTION,
                      required: true
                    }
                  ].filter(Boolean)}
                  onChangeField={this.onChangeField}
                  onSubmit={(v) => { this.submitOid(v); this.handleDialog() }}
                />
              </Card>
              <CommonTable
                col={[
                  { key: 'oid', label: 'OID' },
                  { key: 'description', label: language.DESCRIPTION }
                ]}
                data={listsnmp.concat(!id_brands && !id_device ? this.state.oids.itens : this.state.device.itens).map(e => ({
                  oid: e.device_oids,
                  description: e.oid_description
                }))}
              />
              <div className='d-flex align-items-center justify-content-end mt-2'>
                <Button
                  label={language.SAVE}
                  icon='fas fa-save'
                  variant='normal'
                  loading={requestLoading}
                  onClick={() => oidFormSubmit(this.state.oids)}
                />
              </div>
            </Fragment>

          ) : dialog.type == 4 ? (
            <CommonForm
              values={values}
              fields={[
                {
                  col: 3,
                  name: 'id',
                  type: 'text',
                  label: 'id',
                  disabled: true
                },
                {
                  col: 9,
                  name: 'device_oids',
                  type: 'text',
                  label: 'OID',
                  required: true,
                },
                {
                  col: 12,
                  name: 'oid_description',
                  type: 'textEditor',
                  label: language.DESCRIPTION,
                  required: true
                },
              ]}

              onSubmit={(v) => { submitSnmpEdit(v); this.handleDialog() }}
            />
          ) : null}
        </Dialog>
      </Fragment>
    )
  }
}
