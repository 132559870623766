import React, { memo, Fragment, useState, useEffect } from 'react';
import moment from 'moment';
// ----------------------------common--------------------------------------------
import Button from '@common/Button';
import { flexBasis } from '@common/Func';
import Header from '@common/Header';
import SearchInput from '@common/SearchInput';
// ----------------------------material-ui--------------------------------------------
import { Card, Grid } from '@material-ui/core';
// ----------------------------components--------------------------------------------
import Column from './Column';
import DialogForm from '../../DialogForm';
import { Droppable } from 'react-drag-and-drop';
import search from 'material-ui/svg-icons/action/search';
import Options from '../../../../Projects/Project/options';


export default memo(
  ({
    language,
    handleModalId,
    sprintkaban,
    store,
    onDropitem,
    onAdd_,
    sprintpjs,
    users,
    action,
    APIEndpoints,
    redirect,
    redirect2,
    sprintpj,
    companies,
    setSprintKanban,
    id_project_sprint,
    export_,
  }) => {
    let DONE = [];
    let DOING = [];
    let STOPPED = [];
    let TODO = [];
    let VALIDATION = [];
    let [dialogFormOpen, setDialogFormOpen] = useState(false);
    let [dialogFo, setDialogFo] = useState(false);
    let [search, SetSearch] = useState('');
    let [statusAwaiting, setStatusAwaiting] = useState([]);

    let atividadesAss = [];
    let ticketsAss = [];
    useEffect(() => {
      setTimeout(() => {
        setDialogFo(10);
      }, 5000);
    }, [sprintpj]);

    useEffect(() => {
      action
        .execute('get', APIEndpoints.STATUS_AWAITING, 'status_awaiting')
        .then((e) => {
          setStatusAwaiting(e.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    const getPriority = (id) => {
      let item = 0;
      sprintpj.map((g) => {
        item = g.activities.filter((v) => v.fk_id_activity == id)[0].priority;
      });
      return item;
    };
    const getPriorityHour = (id) => {
      let item = 0;
      sprintpj.map((g) => {
        item = g.activities.filter((v) => v.fk_id_activity == id)[0].hours;
      });
      return item;
    };
    const getPriorityExecution = (id) => {
      let item = 0;
      sprintpj.map((g) => {
        item = g.activities.filter((v) => v.fk_id_activity == id)[0]
          .dt_execution;
        item = moment(item).format('DD/MM/YYYY');
      });
      return item;
    };
    const getPlaned = (id) => {
      let item = 0;
      try{
      sprintpj.map((g) => {
        item = g.activities.filter((v) => v.fk_id_activity == id)[0].unplanned;
      });
    }catch(e){}
      return item;
    };
    const getPlanedTk = (id) => {
      let item = 0;
      try{
      sprintpj.map((g) => {
        item = g.tickets.filter((v) => v.fk_id_tickets == id)[0].unplanned;
      });
    }catch(e){}

      return item;
    };

    const getPriorityTicket = (id) => {
      let item = 0;
      try{
      sprintpj.map((g) => {
        item = g.tickets.filter((v) => v.fk_id_tickets == id)[0].priority;
      });
    }catch(e){}

      return item;
    };

    window._sprintkaban = sprintkaban;

    //Se o status for em andamento, ele verifica se ficará em impeditivo ou validação
    const columnTicket = (t, p) => {
      const fk_id_status_kanban =
        statusAwaiting.find((waiting) => waiting.id_status === t.stop_ticket)
          ?.fk_id_status_kanban || '';
      if (fk_id_status_kanban && fk_id_status_kanban == 1)
        return 'STOPPED'
      else if (fk_id_status_kanban && fk_id_status_kanban == 2)
        return 'VALIDATION'
      else if (fk_id_status_kanban && fk_id_status_kanban == 3)
        return 'DOING'
      else if (fk_id_status_kanban && fk_id_status_kanban == 4)
        return 'TODO'
      return undefined    

    };

    sprintkaban.map((g) => {
      g.projetos.projetos &&
        g.projetos.projetos.map((c) => {
          c.statusproject.DONE.map((z) => {
            DONE.push({
              ...z,
              avatares: z.users.map((t) => ({ name: t, src: undefined })),
              stars: getPriority(z.id_project_activity),
              pj: c,
              days: '',
              description: z.description,
              desc: z.description,
              date: getPriorityExecution(z.id_project_activity),
              hour: getPriorityHour(z.id_project_activity),
              color:
                getPlaned(z.id_project_activity) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          });
          c.statusproject.VALIDATION.map((z) => {
            VALIDATION.push({
              ...z,
              avatares: z.users.map((t) => ({ name: t, src: undefined })),
              stars: getPriority(z.id_project_activity),
              pj: c,
              days: '',
              description: z.description,
              desc: z.description,
              date: getPriorityExecution(z.id_project_activity),
              hour: getPriorityHour(z.id_project_activity),
              color:
                getPlaned(z.id_project_activity) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          });
          c.statusproject.DOING.map((z) => {
            (z.percent==100?DONE:DOING).push({
              ...z,
              avatares: z.users.map((t) => ({ name: t, src: undefined })),
              stars: getPriority(z.id_project_activity),
              pj: c,
              days: '',
              description: z.description,
              desc: z.description,
              date: getPriorityExecution(z.id_project_activity),
              hour: getPriorityHour(z.id_project_activity),
              color:
                getPlaned(z.id_project_activity) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          });
          c.statusproject.STOPPED.map((z) => {
            STOPPED.push({
              ...z,
              avatares: z.users.map((t) => ({ name: t, src: undefined })),
              stars: getPriority(z.id_project_activity),
              pj: c,
              days: '',
              description: z.description,
              desc: z.description,
              date: getPriorityExecution(z.id_project_activity),
              hour: getPriorityHour(z.id_project_activity),
              color:
                getPlaned(z.id_project_activity) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          });
          c.statusproject.TODO.map((z) => {
             (z.percent==100?DONE:TODO).push({
              ...z,
              avatares: z.users.map((t) => ({ name: t, src: undefined })),
              stars: getPriority(z.id_project_activity),
              days: '',
              pj: c,
              description: z.description,
              desc: z.description,
              date: getPriorityExecution(z.id_project_activity),
              hour: getPriorityHour(z.id_project_activity),
              color:
                getPlaned(z.id_project_activity) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          });
        });

      g.tickets &&
        g.tickets.map((b) => {
          let key = columnTicket(b);
          if (b.status === 'Open') {
            (key == 'VALIDATION'
              ? VALIDATION
              : key == 'STOPPED'
              ? STOPPED
              : TODO
            ).push({
              ...b,
              avatares: [{ name: b.tech_start || '?', src: undefined }],
              stars: getPriorityTicket(b.id_tickets),
              ticket: b,
              company_name : b.company_name,
              days: '',
              description: b.real_id + ' - ' + b.subject,
              date: moment(b.dt_cad).format('DD/MM/YYYY'),
              hour: b.sla_task,

              color:
                getPlanedTk(b.id_tickets) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          } else if (b.status === 'In Progress' || b.status === 'Reopened') {
            (key == 'VALIDATION'
              ? VALIDATION
              : key == 'STOPPED'
              ? STOPPED
              : key == 'DOING'
              ? DOING
              : TODO
            ).push({
              ...b,
              avatares: [{ name: b.tech_start || '', src: undefined }],
              stars: getPriorityTicket(b.id_tickets),
              ticket: b,
              company_name : b.company_name,
              days: '',
              description: b.real_id + ' - ' + b.subject,
              date: moment(b.dt_cad).format('DD/MM/YYYY'),
              hour: b.sla_task,
              color:
                getPlanedTk(b.id_tickets) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          } else {
            (key == 'VALIDATION'
              ? VALIDATION
              : key == 'STOPPED'
              ? STOPPED
              : DONE
            ).push({
              ...b,
              avatares: [{ name: b.tech_start || '', src: undefined }],
              stars: getPriorityTicket(b.id_tickets),
              ticket: b,
              days: '',
              company_name : b.company_name,
              description: b.real_id + ' - ' + b.subject,
              date: moment(b.dt_cad).format('DD/MM/YYYY'),
              hour: b.sla_task,
              color:
                getPlanedTk(b.id_tickets) == true
                  ? 'var(--danger)'
                  : 'var(--warning)',
            });
          }
        });
    });

    let options = Options({
      users,
      // activities_predecessor:(utils.TODO||[]),
      activities_predecessor: [
        ...(TODO || []),
        ...(DOING || []),
        ...(STOPPED || []),
        ...(VALIDATION || []),
      ],
      companies,
    });

    return (
      <Fragment>
        {dialogFormOpen && (
          <DialogForm
            sprintkaban={sprintkaban}
            sprintpjs={sprintpjs}
            users={users}
            action={action}
            redirect={redirect}
            APIEndpoints={APIEndpoints}
            result={true}
            open={dialogFormOpen}
            onClose={() => setDialogFormOpen(false)}
          />
        )}
        <div id="Block_2" className="mb-3">
          <Header>
            {moment().format('YYMMDD') <=
            moment(sprintkaban[0].sprint.dt_end).format('YYMMDD') ? (
              <Button
                icon="fas fa-plus"
                color="warning"
                label={language.ADD}
                variant="circle"
                className="mr-3"
                onClick={() => setDialogFormOpen(true)}
              />
            ) : null}
            <SearchInput value = {search} onChange={(evt) => SetSearch(evt.target.value)}   clearSeach={() => {SetSearch('')}}/>
             {export_} 
             </Header>
          <Card className="content pt-0">
           <div style = {{height : 10}}></div>
            <Grid container spacing={2}>
              <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                <Column
                  redirect2={redirect2}
                  setSprintKanban={setSprintKanban}
                  id_project_sprint={id_project_sprint}
                  onDrop={(e) =>
                    onDropitem(
                      e,
                      'TODO',
                      'projectSelect.id_project',
                      'projectSelect.user_cad'
                    )
                  }
                  options={options}
                  users={users}
                  handleModalId={handleModalId}
                  data={TODO.filter(
                    (e) =>
                      JSON.stringify({
                        description: e.description,
                        avatares: e.avatares,
                        company_name : e.company_name
                      })
                        .toLocaleLowerCase()
                        .indexOf(search.toLocaleLowerCase()) != -1
                  )}
                  language={language}
                  onAdd_={onAdd_}
                  title={language.TO_DO.toUpperCase()}
                  onClickStar={(index, v) => console.log(data[index], v)} // aqui eu retorno o index do item do array e o número de estrelas clicado
                  onDelete={(index) => console.log(data[index])} // aqui eu retorno o index do item do item clicado, por favor usar assim, pegar referência no array data
                />
              </Grid>

              <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                <Droppable
                  types={['milestone']} // <= allowed drop types
                  onDrop={(e) =>
                    onDropitem(
                      e,
                      'DOING',
                      'projectSelect.id_project',
                      'projectSelect.user_cad'
                    )
                  }
                >
                  <Column
                    redirect2={redirect2}
                    setSprintKanban={setSprintKanban}
                    id_project_sprint={id_project_sprint}
                    options={options}
                    handleModalId={handleModalId}
                    data={DOING.filter(
                      (e) =>
                        JSON.stringify({
                          description: e.description,
                          avatares: e.avatares,
                          company_name : e.company_name
                        })
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) != -1
                    )}
                    onAdd_={onAdd_}
                    language={language}
                    title={language.DOING.toUpperCase()}
                    users={users}
                    onClickStar={(index, v) => console.log(data[index], v)} // aqui eu retorno o index do item do array e o número de estrelas clicado
                    onDelete={(index) => console.log(data[index])} // aqui eu retorno o index do item do item clicado, por favor usar assim, pegar referência no array data
                  />
                </Droppable>
              </Grid>

              <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                <Droppable
                  types={['milestone']} // <= allowed drop types
                  onDrop={(e) =>
                    onDropitem(
                      e,
                      'STOPPED',
                      'projectSelect.id_project',
                      'projectSelect.user_cad'
                    )
                  }
                >
                  <Column
                    redirect2={redirect2}
                    setSprintKanban={setSprintKanban}
                    id_project_sprint={id_project_sprint}
                    options={options}
                    handleModalId={handleModalId}
                    data={STOPPED.filter(
                      (e) =>
                        JSON.stringify({
                          description: e.description,
                          avatares: e.avatares,
                          company_name : e.company_name
                        })
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) != -1
                    )}
                    users={users}
                    onAdd_={onAdd_}
                    language={language}
                    title={language.IMPEDITIVE.toUpperCase()}
                    onClickStar={(index, v) => console.log(data[index], v)} // aqui eu retorno o index do item do array e o número de estrelas clicado
                    onDelete={(index) => console.log(data[index])} // aqui eu retorno o index do item do item clicado, por favor usar assim, pegar referência no array data
                  />
                </Droppable>
              </Grid>

              <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                <Droppable
                  types={['milestone']} // <= allowed drop types
                  onDrop={(e) =>
                    onDropitem(
                      e,
                      'VALIDATION',
                      'projectSelect.id_project',
                      'projectSelect.user_cad'
                    )
                  }
                >
                  <Column
                    redirect2={redirect2}
                    setSprintKanban={setSprintKanban}
                    id_project_sprint={id_project_sprint}
                    options={options}
                    handleModalId={handleModalId}
                    data={VALIDATION.filter(
                      (e) =>
                        JSON.stringify({
                          description: e.description,
                          avatares: e.avatares,
                          company_name : e.company_name
                        })
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) != -1
                    )}
                    users={users}
                    onAdd_={onAdd_}
                    language={language}
                    title={language.VALIDATION.toUpperCase()}
                    onClickStar={(index, v) => console.log(data[index], v)} // aqui eu retorno o index do item do array e o número de estrelas clicado
                    onDelete={(index) => console.log(data[index])} // aqui eu retorno o index do item do item clicado, por favor usar assim, pegar referência no array data
                  />
                </Droppable>
              </Grid>

              <Grid item xs={2.4} style={{ ...flexBasis(2.4) }}>
                <Droppable
                  types={['milestone']} // <= allowed drop types
                  onDrop={(e) =>
                    onDropitem(
                      e,
                      'DONE',
                      'projectSelect.id_project',
                      'projectSelect.user_cad'
                    )
                  }
                >
                  <Column
                    redirect2={redirect2}
                    setSprintKanban={setSprintKanban}
                    id_project_sprint={id_project_sprint}
                    options={options}
                    handleModalId={handleModalId}
                    data={DONE.filter(
                      (e) =>
                        JSON.stringify({
                          description: e.description,
                          avatares: e.avatares,
                          company_name : e.company_name
                        })
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) != -1
                    )}
                    users={users}
                    onAdd_={onAdd_}
                    language={language}
                    title={language.FINISHED.toUpperCase()}
                    onClickStar={(index, v) => console.log(data[index], v)} // aqui eu retorno o index do item do array e o número de estrelas clicado
                    onDelete={(index) => console.log(data[index])} // aqui eu retorno o index do item do item clicado, por favor usar assim, pegar referência no array data
                  />
                </Droppable>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Fragment>
    );
  }
);
