import React, { Component } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  iOSSwitchBase: {
    color: '#c6c6c6',
    '&$checked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#52d869',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
  switchBase: {
    color: '#52d869',
    '&$checked': {
      color: '#52d869',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
});

const SwitchComponent = withStyles({
  switchBase: {
    color: '#FFF',
    '&$checked': {
      color: '#52d869',
    },
    '&$checked + $track': {
      backgroundColor: '#52d869',
    },
  },
  checked: {},
  track: {},
})(Switch);

class SwitchIos extends Component {
  render() {
    let { label, classes, ...other } = this.props;
    let MySwitch = <SwitchComponent disableRipple {...other} />;
    return label ? (
      <FormControlLabel
        labelPlacement="start"
        control={MySwitch}
        label={label}
      />
    ) : (
      MySwitch
    );
  }
}

SwitchIos.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SwitchIos);
