import RaisedButton from '../../common/RaisedButton';
import React, { Component } from 'react';
import PageLayout from '@common/PageLayout';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import './DeviceConf.css';
import {
  SelectField,

} from '@material-ui/core';
import {
  Step,
  Stepper,
  StepLabel,
  Switch,
  FormControlLabel,
  TextField,
  MenuItem
} from '@material-ui/core';
import { Container, Row, Col } from 'react-grid-system';
import CommonForm from '../../common/CommonForm';
import CommonTable from '../../common/CommonTable';
import CommonPageNotLoad from '../../common/CommonPageNotLoad';
const theme = createTheme({
  palette: {
    primary: { main: '#009dc0' }
  },
});
export default class DeviceConf extends CommonPageNotLoad {
  constructor() {
    super();
    this.state = {
      value: '',
      finished: false,
      stepIndex: 0,
      type: '',
      conf: '',
      confTriggers: '',
      removeloading: true
    };

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleDevice = this.handleDevice.bind(this);
    this.handleConf = this.handleConf.bind(this);
    this.handleTriggerConf = this.handleTriggerConf.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
  }

  componentDidMount() {
    this.store.loading = false;
    let { editConf, store } = this.props;

    if (editConf) {
      let index,
        items = [];
      if (store.monitor.map(d => d.id_device).includes(editConf.id_device)) {
        items = store.monitor.map((_d, k) => {
          if (_d.id_device == editConf.id_device) index = k;
          return (
            <MenuItem
              key={_d.id_device}
              type={_d.type}
              value={_d.id_device}
              primaryText={_d.name}
            />
          );
        });
      } else {

        items = [
          <MenuItem
            key={editConf.id_device}
            type={editConf.type}
            value={editConf.id_device}
            primaryText={editConf.name}
          />
        ];
        index = 0;
      }
      this.handleDevice(items, index);
    }
  }

  handleNext = () => {
    let { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 2
    });
  };

  handlePrev = () => {
    let { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  handleDevice(items, index) {
    let { editConf, store } = this.props;
    let conf = [];
    let confTriggers = [];

    store.items
      .filter(d => d.type === items[index].props.type)
      .map(obj => {
        let confTime =
          editConf && editConf.conf && editConf.conf[obj.key]
            ? editConf.conf[obj.key].item_time
            : obj.time.split(',')[0];
        let confSend =
          editConf && editConf.conf && editConf.conf[obj.key]
            ? editConf.conf[obj.key].send == 'true'
            : true;

        conf.push({
          fk_id_item: obj.id_item,
          fk_id_device: items[index].props.value,
          time: confTime,
          send: confSend
        });

        obj.triggers.map(t => {
          let triggerValue = 0;
          let triggerSend = 0;
          try {
            triggerValue =
              editConf && editConf.conf
                ? editConf.conf[obj.key].triggers[t.name_trigger.toLowerCase()]
                  .value
                : 0;
            triggerSend =
              editConf && editConf.conf
                ? editConf.conf[obj.key].triggers[t.name_trigger.toLowerCase()]
                  .send
                : false;
          } catch (e) { }
          confTriggers.push({
            fk_id_trigger: t.id_trigger,
            fk_id_item: obj.id_item,
            fk_id_device: items[index].props.value,
            send: triggerSend,
            value: triggerValue
          });
        });
      });

    this.setState({
      value: items[index].props.value,
      type: items[index].props.type,
      conf: conf,
      confTriggers: confTriggers
    });
    this.props.getUsers(items[index].props.value);
  }

  handleUsers(userId, value) {
    this.props.store.users.map(user => {
      if (user.id_user == userId) user.notification = value;
      return user;
    });
  }

  handleConf(id_item, key, value) {

    let conf = this.state.conf.map(c => {
      if (c.fk_id_item == id_item) {
        c[key] = value;
      }
      return c;
    });
    this.setState({ conf: conf });
  }

  handleTriggerConf(id_trigger, id_item, key, value) {
    let confTriggers = this.state.confTriggers.map(c => {
      if (c.fk_id_trigger == id_trigger && c.fk_id_item == id_item) {
        c[key] = value;
      }
      return c;
    });
    this.setState({ confTriggers: confTriggers });
  }

  render() {
    let {
      language,
      handlePage,
      devices,
      onConfSubmit,
      editConf,
      store,
      users
    } = this.props;
    let { stepIndex, finished, value, type, conf, confTriggers } = this.state;


    let colConfigItc = [
      { key: 'key', label: language.KEY },
      { key: 'name_item', label: language.DESCRIPTION },
      { key: 'time', label: language.TIME }
    ];


    let dataTriggrer = [];
    //console.log(store.items)
    let dataConfig = store.items
      .filter(d => d.type === type)
      .map(obj => {
        let time = obj.time
          .split(',')
          .map(_t => (
            <MenuItem key={_t} value={_t} primaryText={_t + language.SECONDS} >
              {_t + language.SECONDS}
            </MenuItem>
          ));
        let itemConf = conf.filter(c => c.fk_id_item == obj.id_item)[0];

        obj.triggers.map(trigger => {
          let itemConfTriggers = confTriggers.filter(
            c =>
              c.fk_id_trigger == trigger.id_trigger &&
              c.fk_id_item == obj.id_item
          )[0];

          dataTriggrer.push({
            name_item: obj.name_item,
            triggers: trigger.name_trigger,
            value: (
              <TextField

                value={itemConfTriggers.value}
                onChange={(event) =>
                  this.handleTriggerConf(
                    trigger.id_trigger,
                    obj.id_item,
                    'value',
                    event.target.value
                  )
                }
              />
            ),
            sms: (

              <FormControlLabel
                control={
                  <Switch
                    checked={itemConfTriggers.send}
                    onChange={(event, checked) =>
                      this.handleTriggerConf(
                        trigger.id_trigger,
                        obj.id_item,
                        'send',
                        checked
                      )
                    }
                    color="primary"
                  />
                }
              />

            )
          });
        });

        return {
          key: (

            <FormControlLabel
              control={
                <Switch
                  checked={itemConf.send}
                  onChange={(event, checked) =>
                    this.handleConf(obj.id_item, 'send', checked)
                  }
                  color="primary"
                />
              }

              label={obj.key}
            />
          ),
          name_item: obj.name_item,
          time: (
            <TextField
              select

              style={{ minWidth: 200 }}
              value={itemConf.time}
              onChange={(event, key, values) => this.handleConf(obj.id_item, 'time', key.key)}
              margin="dense"
            >
              {time}
            </TextField>

          )
        };
      });

    let colTriggrerItc = [
      { key: 'name_item', label: language.NAME },
      { key: 'triggers', label: language.TRIGGRER },
      { key: 'value', label: language.VALUE },
      { key: 'sms', label: language.SMS }
    ];

    let colConfigUser = [
      { key: 'user', label: language.USER },
      { key: 'notification', label: language.NOTIFICATION }
    ];

    let dataUsers = (users || store.users).map(user => ({
      user: user.name,
      notification: (
        <FormControlLabel
          control={
            <Switch
              checked={user.notification}
              onChange={(event, checked) =>
                this.handleUsers(user.id_user, checked)
              }
              color="primary"
            />
          }
        />
      )
    }));

    return (
      <PageLayout
        icon='configurationsIcon'
        title={language.CONFIG}
        rightElements={[

          <RaisedButton
            circleButton
            icon='fas fa-arrow-left'
            onClick={() => handlePage('dash')}
            label={language.RETURN}
            color='primaryGradient'
          />
        ]}
        back={false}
      >
        <Container fluid id='DeviceConf'>

          <MuiThemeProvider theme={theme}>
            <CommonTable
              noFilter={true}

              title={
                <Stepper activeStep={stepIndex} style={{ width: '75%', padding: '10px 24px', background: 'transparent' }}>
                  <Step color='secondary'>
                    <StepLabel><span style={{ color: 'white' }}>{language.CONFIG}</span></StepLabel>
                  </Step>
                  <Step>
                    <StepLabel><span style={{ color: 'white' }}>{language.TRIGGRER}</span></StepLabel>
                  </Step>
                  <Step>
                    <StepLabel><span style={{ color: 'white' }}>{language.USERS}</span></StepLabel>
                  </Step>
                </Stepper>
              }
              col={
                stepIndex === 0 ?
                  colConfigItc
                  : stepIndex === 1 ?
                    colTriggrerItc
                    : stepIndex === 2 ?
                      colConfigUser
                      : []
              }
              data={
                stepIndex === 0 ?
                  dataConfig
                  : stepIndex === 1 ?
                    dataTriggrer
                    : stepIndex === 2 ?
                      dataUsers
                      : []
              }
            />
          </MuiThemeProvider>
          <div className='d-flex justify-content-end mt-2'>
            {stepIndex >= 1 &&
              <RaisedButton
                circleButton
                className='ml-1'
                label={language.RETURN}

                icon='fas fa-arrow-left'
                color='primaryGradient'

                onClick={this.handlePrev}

              />
            }
            <RaisedButton
              circleButton
              className='ml-2'
              label={
                stepIndex < 2 ?
                  language.NEXT
                  : language.SAVE
              }

              icon={stepIndex < 2 ? 'fas fa-arrow-right' : 'fas fa-save'}
              color='primaryGradient'

              onClick={() =>
                stepIndex < 2 ?
                  this.handleNext()
                  :
                  onConfSubmit(
                    conf,
                    confTriggers,
                    store.users,
                    !!editConf
                  )
              }

            />
          </div>

        </Container>
      </PageLayout>
    );
  }
}
