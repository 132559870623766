import React, { Component, Fragment, useEffect, useState } from 'react';
import { redirect } from "react-router-dom";
import { Chip, Switch } from '@material-ui/core';

import Button from '@common/Button';
import Dialog from '@common/Dialog';
import CommonTable from '@common/CommonTable';
import RaisedButton from '@common/RaisedButton';
import DialogConfirm from '@common/DialogConfirm';
import { Actions, Store, Constants } from '@data';

const action = new Actions();

import TagForm from './tagForm';
import AssociateEventsForm from './associateEventsForm';

import './index.css';

const defaultPageDelete = { open: false, values: {} };

const index = () => {
  const { language, language_type } = Store;
  const [page, setPage] = useState('list');
  const [value, setValue] = useState(null);
  const [tagsList, setTagsList] = useState([]);
  const [pageDelete, setPageDelete] = useState(defaultPageDelete);
  let user = Store.getUserLoged();

  const loadTags = () => {
    action
      .execute('get', `${Constants.APIEndpoints.NOTIFICATION}/tags`
      )
      .then((res) => {
        if( res.data.data ) {
          res.data = res.data.data;
        }
        setTagsList(res.data);
      });
  };

  useEffect(() => {
    if( user.id === 1 && user.email === "admin@sensrit.com.br" ) {
      loadTags();
    } else {
      window.location.href = window.location.origin + '/dash/configuration';
    }
  }, []);

  const handlePageDelete = (data) => {
    setPageDelete(data);
  };

  const deleteNotification = () => {
    console.log(pageDelete);
    Store.spinner = true;
    action
      .execute(
        'delete',
        `${Constants.APIEndpoints.NOTIFICATION}/tag/${pageDelete.values.id}`
      )
      .then(() => {
        loadTags();
        handlePageDelete(defaultPageDelete);
        Store.spinner = false;
        Store.toggleAlert(
          true,
          language.REGISTER_DELETED,
          'success'
        );
      })
      .catch(() => {
        Store.toggleAlert(
          true,
          language.ERROR_REQUEST,
          'error'
        );
        Store.spinner = false;
      });
  }

  let col = [
    {
      key: 'name',
      label: language.NAME,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'description',
      label: language.DESCRIPTION,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
    },
    {
      key: 'action',
      label: language.ACTIONS,
      style: { maxWidth: 100, overflow: 'auto', textAlign: 'left' },
      search: false
    },
  ];

  const data = tagsList.map((item) => ({
    name: item.name,
    description: language_type === 'EN' ? item.en_description : language_type === 'ES' ? item.es_description : item.description,
    action: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
                icon="fas fa-plus"
                label={language.EVENTS}
                color="primary"
                style={{
                  fontSize: 13,
                  width: 21,
                  height: 21,
                  marginRight: 10,
                }}
                onClick={() => {
                  setValue(item);
                  setPage('associateTagEvents');
                }}
                size={0.9}
              />
              <Button
                variant="circle"
                icon="fas fa-edit"
                label={language.EDIT}
                color="default"
                style={{ fontSize: 13 }}
                size={0.7}
                className="mr-2"
                onClick={() => {
                  setValue(item);
                  setPage('editTag');
                }}
              />
              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={language.DELETE}
                color="danger"
                style={{ fontSize: 14 }}
                size={0.7}
                onClick={() => handlePageDelete({ open: true, values: item })}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  return (
    <div>
      <CommonTable
        title=""
        index=""
        data={data}
        columns={col}
        language={language}
        searchColumn
        orderColumn
        beforeBar={
          <RaisedButton
            style={{ zoom: 0.8 }}
            zoom={0.8}
            className="mr-3"
            color="primary"
            key="02"
            circleButton
            icon="fas fa-plus"
            label={language.ADD}
            primary={true}
            onClick={() => {
              setValue(null);
              setPage('registerTag');
            }}
          />
        }
      />
      {page !== 'list' ? (
        <Dialog
          title={
            page === 'editTag' ?
              language.EDIT_TAG :
            page === 'registerTag' ?
              language.REGISTER_TAG :
              language.EVENTS
          }
          open={true}
          onClose={() => setPage('list')}
          maxWidth={page === 'associateTagEvents' ? "xl" : "md"}
          miniVersion = {true}
        >
          {page === 'registerTag' || page === 'editTag' ? (
            <TagForm
              loadTags={loadTags}
              language={language}
              value={value}
              onClose={() => setPage('list')}
            />
          ) : (
            <AssociateEventsForm
              loadTags={loadTags}
              language={language}
              value={value}
              onClose={() => setPage('list')}
            />
          )}
        </Dialog>
      ) : null}
      {pageDelete.open && (
        <DialogConfirm
          open={pageDelete.open}
          language={language}
          confirmProps={{ color: 'danger' }}
          onConfirm={deleteNotification}
          onClose={() => handlePageDelete(defaultPageDelete)}
          title={language.CONFIRM_DELETE}
          message={pageDelete.values.name}
        />
      )}
    </div>
  );
};

export default index;
