import Button from '@common/Button';
import { TextField } from '@material-ui/core';
import { CheckCircleOutline, Info } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState } from 'react';
import SimplesAlert from '../../common/SimplesAlert';
import { Constants, Store } from '../../data';
import GenericActions from '../../data/Actions';

export const RecoveryPasswordHeader = ({ children }) => {
  const { language } = Store;
  return (
    <div class="tw-relative tw-my-4">
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center">
        <span class="tw-w-full tw-border-t"></span>
      </div>
      <div class="tw-relative tw-flex tw-justify-center tw-text-xs tw-uppercase">
        <span class="tw-px-2 default-color tw-bg-white tw-font-bold">
          {children ?? language.REDEFINE_PASS}
        </span>
      </div>
    </div>
  );
};

export const StepButtons = ({
  onPrev,
  onNext,
  disablePrevButton = false,
  disableNextButton = false,
  showPrev = true,
  showNext = true,
}) => {
  const { language } = Store;
  return (
    <>
      <div className="tw-flex tw-justify-between tw-mt-6">
        <div>
          {showPrev && (
            <Button
              outlined={true}
              label={
                <span className="btn--label">
                  <i className="fas fa-arrow-left mr-2" />
                  {language.RETURN}
                </span>
              }
              // color="success"
              style={{ borderRadius: '4px !important' }}
              onClick={onPrev}
              disabled={disablePrevButton}
            />
          )}
        </div>
        <div>
          {showNext && (
            <Button
              primary={true}
              label={
                <span className="btn--label">
                  {language.NEXT}
                  <i className="fas fa-arrow-right ml-2" />
                </span>
              }
              color="success"
              style={{ borderRadius: '4px !important' }}
              onClick={onNext}
              disabled={disableNextButton}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const RecoveryBox = ({
  selected = false,
  enableHover = true,
  onRecoveryClick = () => null,
  children,
}) => {
  return (
    <div
      className={classNames(
        'tw-flex tw-items-center tw-border-2 tw-border-gray-300 tw-rounded tw-p-4',
        {
          'tw-transition tw-duration-300 tw-ease-in-out': true,
          'hover:tw-bg-gray-100 tw-cursor-pointer': enableHover,
          'tw-bg-gray-100 default-border': selected,
        }
      )}
      style={{ minHeight: '5rem' }}
      onClick={onRecoveryClick}
    >
      {children}
    </div>
  );
};

export default function LoginRecoveryPassword({ onGoBack }) {
  const { language } = Store;
  const RECOVERY_TYPE = {
    EMAIL: 'email',
    CHALLENGE: 'challenge',
  };

  const RECOVERY_TYPE_LABEL = {
    email: language.RECOVERY_PASSWORD.EMAIL_RECOVERY,
    challenge: language.RECOVERY_PASSWORD.CHALLENGE_RECOVERY,
  };

  const alertDefault = { opened: false, message: '', variant: 'success' };
  const [alert, setAlert] = useState(alertDefault);
  const [recoveryType, setRecoveryType] = useState();
  const [step, setStep] = useState(1);

  const [questionsNotFound, setQuestionsNotFound] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);

  const [email, setEmail] = useState();
  const [passwordQuestions, setPasswordQuestions] = useState();

  const handleRecoveryType = (type) => {
    setRecoveryType(type);
  };

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  const handleOpenAlert = (message, variant = 'success') => {
    setAlert({ opened: true, message: message, variant: variant });
  };

  const handleToggleStep = (goto) => {
    setQuestionsNotFound(false);
    setStep(goto);
  };

  const handleReplyQuestion = (reply, indexItem) => {
    const swapItems = [...passwordQuestions];
    swapItems[indexItem].reply = reply;
    setPasswordQuestions(swapItems);
  };

  const handleSubmitReplyQuestion = () => {
    const url = Constants.APIEndpoints.MY_REPLIES;
    const replies = passwordQuestions.map((item) => ({
      id_password_question: item.id_password_question,
      reply: item.reply,
    }));
    const payload = {
      email: email,
      replies,
      url: window.location.origin,
    };
    const fetch = new GenericActions();
    fetch
      .execute('post', url, null, payload)
      .then((response) => {
        const { data } = response;
        if (data) {
          if (data.isRecoveryAllowed) {
            window.location.href = data.redirectUrl;
          } else {
            handleOpenAlert(
              language.RECOVERY_PASSWORD.INCORRECT_ANSWERS,
              'error'
            );
          }
        }
      })
      .catch((error) => {
        handleOpenAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const getRecovery = () => {
    recoveryType === RECOVERY_TYPE.EMAIL
      ? getRecoveryEmail()
      : getRecoveryQuestions();
  };

  const getRecoveryEmail = () => {
    const url = Constants.APIEndpoints.FORGOT;
    const fetch = new GenericActions();
    fetch
      .execute('post', url, null, { email, url: window.location.origin })
      .finally(() => {
        handleToggleStep(3);
      });
  };

  const getRecoveryQuestions = () => {
    const url = Constants.APIEndpoints.MY_QUESTIONS;
    const payload = { email: email };
    const fetch = new GenericActions();
    fetch
      .execute('post', url, null, payload)
      .then((response) => {
        const { data } = response;
        if (data && data.length > 0) {
          setPasswordQuestions(data);
          handleToggleStep(4);
        } else {
          setQuestionsNotFound(true);
        }
      })
      .catch((error) => {
        handleOpenAlert(language.RECOVERY_PASSWORD.NO_QUESTIONS_FOUND, 'info');
      });
  };

  return (
    <div className="default-color">
      <div className="mb-4">
        <RecoveryPasswordHeader />
      </div>
      {step === 1 && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-sm">
          <p className="tw-mb-4 tw-text-sm tw-leading-5">
            {language.RECOVERY_PASSWORD.CHOOSE_RECOVERY_METHOD}
          </p>
          <RecoveryBox
            selected={recoveryType === RECOVERY_TYPE.EMAIL}
            onRecoveryClick={() => handleRecoveryType(RECOVERY_TYPE.EMAIL)}
          >
            {RECOVERY_TYPE_LABEL[RECOVERY_TYPE.EMAIL]}
          </RecoveryBox>
          <RecoveryBox
            selected={recoveryType === RECOVERY_TYPE.CHALLENGE}
            onRecoveryClick={() => handleRecoveryType(RECOVERY_TYPE.CHALLENGE)}
          >
            {RECOVERY_TYPE_LABEL[RECOVERY_TYPE.CHALLENGE]}
          </RecoveryBox>

          <StepButtons
            onPrev={onGoBack}
            onNext={() => handleToggleStep(2)}
            disableNextButton={!recoveryType}
          />
        </div>
      )}
      {step === 2 && (
        <>
          <p className="tw-mb-6 tw-text-sm tw-leading-5">
            {RECOVERY_TYPE_LABEL[recoveryType]}
          </p>
          <TextField
            autoFocus
            fullWidth
            type="text"
            variant="outlined"
            // error={email && !EMAIL_REGEX.test(email)}
            label={
              recoveryType === RECOVERY_TYPE.EMAIL
                ? language.USERNAME + ' / ' + language.ALTERNATIVE_EMAIL
                : language.USERNAME
            }
            InputLabelProps={{ shrink: true }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {(!questionsNotFound || !userNotFound) && (
            <div className="tw-h-10"></div>
          )}
          {questionsNotFound && (
            <div className="tw-my-6 tw-bg-gray-100 tw-p-4 tw-rounded tw-flex tw-items-center">
              <Info className="tw-mr-2 tw-text-yellow-500" />
              <p className="tw-text-sm tw-text-gray-700">
                {language.RECOVERY_PASSWORD.NO_QUESTIONS_FOUND}
              </p>
            </div>
          )}
          {userNotFound && (
            <div className="tw-my-6 tw-bg-gray-100 tw-p-4 tw-rounded tw-flex tw-items-center">
              <CheckCircleOutline className="tw-mr-2 tw-text-green-500" />
              <p className="tw-text-sm tw-text-gray-700">
                {language.RECOVERY_PASSWORD.NO_QUESTIONS_FOUND}
              </p>
            </div>
          )}
          <StepButtons
            onPrev={() => handleToggleStep(1)}
            onNext={getRecovery}
            disableNextButton={!email || userNotFound}
          />
        </>
      )}
      {step === 3 && (
        <>
          <p className="tw-mb-6">{language.RECOVERY_PASSWORD.THANK_YOU}</p>
          <Button
            outlined={true}
            label={
              <span className="btn--label">
                <i className="fas fa-arrow-left mr-2" />
                {language.RETURN}
              </span>
            }
            style={{ borderRadius: '4px !important' }}
            onClick={onGoBack}
          />
        </>
      )}
      {step === 4 && (
        <>
          <p className="tw-text-sm">
            {language.RECOVERY_PASSWORD.PASSWORD_RECOVERY_QUESTIONS}
          </p>
          {passwordQuestions.length > 0 && (
            <>
              {passwordQuestions.map((question, index) => (
                <div key={index} className="tw-mt-4">
                  <RecoveryBox enableHover={false}>
                    <div className="tw-w-full">
                      <p className="tw-mb-4 tw-leading-5 tw-text-sm">
                        {question.question_text}
                      </p>
                      <TextField
                        fullWidth
                        autoFocus={index === 0}
                        type="text"
                        variant="outlined"
                        error={!passwordQuestions[index].reply}
                        label={language.ANSWER}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) =>
                          handleReplyQuestion(event.target.value, index)
                        }
                      />
                    </div>
                  </RecoveryBox>
                </div>
              ))}

              <StepButtons
                onPrev={() => handleToggleStep(2)}
                onNext={handleSubmitReplyQuestion}
                disableNextButton={
                  !!passwordQuestions.some((item) => !item.reply)
                }
              />
            </>
          )}
        </>
      )}

      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
}
