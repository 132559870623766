import React from "react";

import CommonPage from "../../common/CommonPage";
import WelcomePage from "./WelcomePage";

export default class WelcomeContainer extends React.Component {
  render() {
    return (
      <CommonPage
        title={<div className="logoWhite" onClick={() => this.redirect('/dash')} />}
        showMenuIconButton={false}
        iconAlert={true}
      >
        <WelcomePage />
      </CommonPage>
    );
  }
}
