import './styles.css';
import '../../../public/css/pages.css';

import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import Animate from '../../common/Animate';
import CommonPage from '../../common/CommonPage';
import PageLayout from '../../common/PageLayout';
import RaisedButton from '../../common/RaisedButton';
import DownloadFiles from '../../util/DownloadFiles';

import AuditRdp from './AuditRdp';
import AuditList from './AuditList';
import ContractList from './ContractList';
import AuditTerminal from './AuditTerminal';

@observer
export default class AuditContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      page: 'contract',
      values: '',
      msn: '',
    };
    this.handlePage = this.handlePage.bind(this);
    this.downloadPage = this.downloadPage.bind(this);
  }

  componentWillMount() {
    let item = window.location.href.replace(window.location.origin + '/', '');

    if (item === 'dash/auditcontract') {
      this.state.page = 'contract';
      let idCon = this.props.location.state.id_contract;
      this.handlePage('assessments', idCon);
    } else {
    }
    this.action.execute(
      'post',
      this.APIEndpoints.CONTRACT + '/list',
      'contracts'
    );
  }

  downloadPage(value) {
    DownloadFiles(
      this.APIEndpoints.REQUESTS +
        '/assessmentdown' +
        '/videos/' +
        value.str_path,
      value.str_path + '.mp4'
    );
  }

  handlePage(page, value) {
    if (page === 'RDP') {
      this.setState({ msn: true });
    }
    if (page === 'assessments') {
      let url = this.APIEndpoints.ASSESSMENT;
      url += '?date=' + moment().format('YYYY-MM');
      url += '&contract=' + value;

      this.action.execute('get', url, 'assessments');
    } else if (page === 'Terminal') {
      let url = this.APIEndpoints.ASSESSMENT + '/audit/' + value.id_assessment;
      this.action.execute('get', url, 'audit');
    } else if (page === 'RDP') {
      // value.id_assessment = 907
      this.action
        .execute('post', this.APIEndpoints.ASSESSMENT + '/create', 'eos', value)
        .then((g) => {
          setTimeout(() => {
            this.store.videos = [];
            let url =
              this.APIEndpoints.ASSESSMENT + '/videos/' + value.str_path;
            this.action.execute('get', url, 'videos');
            this.setState({ page: page, values: value });
          }, 2000);
        });
    }
    if (page != 'RDP') {
      this.setState({ page: page, values: value });
    }
  }

  render() {
    let { audit, language, videos } = this.store;
    let { page, values } = this.state;

    return (
      <CommonPage
        title={
          <div className="logoWhite" onClick={() => this.redirect('/dash')} />
        }
        iconAlert={true}
        loading={false}
        msnloading={
          this.state.msn == true
            ? language.LOAD_VIDEO
            : undefined
        }
      >
        {page == 'contract' ? (
          <Animate>
            <ContractList
              language={language}
              handlePage={this.handlePage}
              downloadPage={this.downloadPage}
              store={this.store}
              redirect={this.redirect}
              action={this.action}
              APIEndpoints={this.APIEndpoints}
            />
          </Animate>
        ) : page == 'RDP' ? (
          <Animate>
            <AuditRdp
              language={language}
              downloadPage={this.downloadPage}
              handlePage={this.handlePage}
              assessment={values}
              videos={videos}
            />
          </Animate>
        ) : page == 'Terminal' ? (
          <Animate>
            <AuditTerminal
              language={language}
              handlePage={this.handlePage}
              audit={audit}
              downloadPage={this.downloadPage}
              assessment={values}
            />
          </Animate>
        ) : (
          <PageLayout
            icon="auditIcon titleIconSize"
            title={language.AUDIT}
            subTitle={language.DASHBOARD_PAGE.GCF + '/'}
            rightElements={[
              <RaisedButton
                label={language.BACK}
                primary={true}
                onClick={() => this.redirect('/dash/audit')}
              />,
            ]}
          >
            <Animate>
              <AuditList
                redirect={this.redirect}
                language={language}
                downloadPage={this.downloadPage}
                handlePage={this.handlePage}
                store={this.store}
              />
            </Animate>
          </PageLayout>
        )}
      </CommonPage>
    );
  }
}
