/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import { InputAdornment } from '@material-ui/core';
import { FixedSizeList } from 'react-window';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  input2: {
    display: 'flex',
    paddingBottom: 9.5,
    paddingTop: 9.5,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${8 / 2}px ${8 / 4}px`,
  },

  noOptionsMessage: {
    padding: `${8}px ${8 * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 30,
    top: 50,
    left: 0,
    right: 0,
  },
  divider: {
    height: 8 * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.selectProps.language.NO_OPTIONS}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  var { input2, startIcon, endIcon, InputProps, ...other } =
    props.selectProps.textFieldProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        startAdornment: startIcon && (
          <InputAdornment position="start">
            {typeof startIcon == 'string' ? (
              <i
                className={classnames(startIcon, 'color-icon adormentIcon')}
              ></i>
            ) : (
              startIcon
            )}
          </InputAdornment>
        ),
        endAdornment: endIcon && (
          <InputAdornment position="start">
            {typeof endIcon == 'string' ? (
              <i className={classnames(endIcon, 'color-icon adormentIcon')}></i>
            ) : (
              endIcon
            )}
          </InputAdornment>
        ),
        inputProps: {
          className: !input2
            ? props.selectProps.classes.input
            : props.selectProps.classes.input2,
          inputRef: props.innerRef,
          children: props.children,

          ...props.innerProps,
          ...props.selectProps.textFieldProps.inputProps,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function Option({ index, data, style }) {
  var isSelected = data
    .getValue()
    .map((e) => e.value)
    .includes(data.options[index].value);
  return (
    <MenuItem
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        padding: 10,
        ...style,
      }}
      onClick={() => data.setValue(data.options[index])}
    >
      <Tooltip
        html={
          <div style={{ color: '#fff', fontSize: 15 }}>
            {data.options[index].label}
          </div>
        }
        position="left"
        theme="dark"
      >
        {data.options[index].label}
      </Tooltip>
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
    />
  );
}

function Menu(props) {
  let maxItemLength = (props.options || []).reduce((n, e) => {
    if(e.label)
    if (e.label.length > n) n = e.label.length;
    return n;
  }, 0);
  return props.options.length ? (
    <FixedSizeList
      height={props.options.length <= 6 ? props.options.length * 40 : 250}
      itemCount={props.options.length}
      itemSize={40}
      width={'100%'}
      itemData={props}
      style={{
        position: 'absolute',
        zIndex: 30,
        top: 50,
        left: 0,
        right: 0,
        minWidth: 11 * maxItemLength,
      }}
      outerElementType={({ children, ...other }) => (
        <Paper square {...props.innerProps} {...other}>
          {children}
        </Paper>
      )}
    >
      {Option}
    </FixedSizeList>
  ) : (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      <NoOptionsMessage
        innerProps={props.innerProps}
        selectProps={props.selectProps}
      />
    </Paper>
  );
}
/*function Menu(props) {
  console.log(props, props.children);
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      {...props.selectProps.menuProps}

    >
      {props.children}
    </Paper>
  );
}*/

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  constructor() {
    super();
    this.state = {
      shrink: false,
      valueInput: '',
    };
  }
  sortLabel = (arr) => {
    return arr.sort(function (a, b) {
      try {
        if (a.label && b.label) {
          var textA = a.label;
          var textB = b.label;
          if (parseInt(textA) && parseInt(textB)) {
            textA = parseInt(textA);
            textB = parseInt(textB);

            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else {
            return textA.localeCompare(textB);
          }
        }
      } catch (e) {}
    });
  };

  render() {
    var {
      classes,
      theme,
      label,
      options,
      menuProps,
      textFieldProps,
      readOnly,
      value,
      isMulti,
      sortOptions,
      ...other
    } = this.props;
    let {
      disabled,
      variant,
      startIcon,
      endIcon,
      className,
      InputLabelProps,
      ...textFieldPropsOther
    } = textFieldProps;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    const replaceSpecialChars = (str) => {
      str = str.replace(/[àáâãäå]/, 'a');
      str = str.replace(/[eéèëê]/, 'e');
      str = str.replace(/[iíìïî]/, 'i');
      str = str.replace(/[oóòõöô]/, 'o');
      str = str.replace(/[uúùüû]/, 'u');

      str = str.replace(/[ç]/, 'c');

      // o resto

      return str.replace(/[^a-z0-9]/gi, '');
    };
    options = options.filter((e) => e.value != undefined);
    options = options.reduce((e, i) => {
      if (!e.find((e) => e.value == i.value)) e.push(i);
      return e;
    }, []);

    options = sortOptions != false ? this.sortLabel(options) : options;
    try {
      options = options.filter(
        (evt) =>
        evt.field ?
        replaceSpecialChars(evt.field.toString().toLowerCase()).indexOf(
          replaceSpecialChars(this.state.valueInput).toLowerCase()
        ) != -1
        :
        replaceSpecialChars(evt.label.toString().toLowerCase()).indexOf(
          replaceSpecialChars(this.state.valueInput).toLowerCase()
        ) != -1
      );
    } catch (e) {}
    if (!isMulti) {
      var opt = options.find((e) => e.value === value);
    } else {
      var opt = options.filter((e) => value.indexOf(e.value) != -1);
    }
    let _this = this;
    return (
      <NoSsr>
        <Select
          classes={classes}
          options={options}
          components={components}
          value={opt || ''}
          isDisabled={disabled || readOnly}
          menuProps={{
            ...menuProps,
          }}
          isMulti={isMulti}
          textFieldProps={{
            variant,
            className: classnames(className, {
              selectField: variant != 'outlined',
            }),
            label: label,
            input2: variant == 'outlined',
            startIcon,
            endIcon,
            InputLabelProps: {
              shrink:
                (!!startIcon && startIcon.indexOf('no-shrink') == -1) ||
                (Array.isArray(opt) ? opt.length : opt !== undefined) ||
                this.state.shrink,
              ...InputLabelProps,
            },
            onFocus: () => _this.setState({ shrink: true }),
            onBlur: () => _this.setState({ shrink: false }),
            ...textFieldPropsOther,
          }}
          isClearable={false}
          placeholder=""
          {...other}
          onInputChange={(valueInput) => {
            this.setState({ valueInput });
          }}
          styles={{ ...selectStyles, ...this.props.styles }}
        />
      </NoSsr>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
