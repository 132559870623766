import Keycloak from "keycloak-js";
const keycloak = (process.env.KEYCLOAK_REALM && process.env.KEYCLOAK_HOST && process.env.KEYCLOAK_CLIENT) ? new Keycloak({
  "realm": process.env.KEYCLOAK_REALM,
  "auth-server-url": process.env.KEYCLOAK_HOST,
  "url": process.env.KEYCLOAK_HOST,
  "ssl-required": "external",
  "resource": process.env.KEYCLOAK_CLIENT,
  "public-client": true,
  "confidential-port": 0,
  "clientId": process.env.KEYCLOAK_CLIENT
}) : null;

export default keycloak;