import jwt from 'jwt-simple';
const clientOptions = {
  cypher: 'AES-256-CBC',
  key: 'OuSuperSecretKeyForParamsFtken30',
};
const crypto = require('crypto');
const secret = 'GFDJUPASSDEVICESHS3NSR1T32O17';

const encrypt = (value) => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(clientOptions.cypher, clientOptions.key, iv);

  let crypted = cipher.update(JSON.stringify(value), 'utf8', 'base64');
  crypted += cipher.final('base64');

  const data = {
      iv: iv.toString('base64'),
      value: crypted
  };

  return new Buffer(JSON.stringify(data)).toString('base64');
};


export default (value) => {
  let json = null;
  if (value.connection == 'rdp') {
    if (value.keyboard != undefined) {
      json = {
        connection: {
          type: 'rdp',
          settings: {
            hostname: value.ip,
            username: value.username,
            password:
              value.nodecode != undefined
                ? value.password
                : jwt.decode(value.password, secret).toString(),
            'server-layout': value.keyboard,
            domain: value.domain,
            port: value.port,
            'ignore-cert': true,
            'enable-wallpaper': false,
            'create-recording-path': true,
            'recording-path': '/opt/sensr/videos',
            'recording-name': value.vd
          },
        },
      };
    } else {
      json = {
        connection: {
          type: 'rdp',
          settings: {
            hostname: value.ip,
            username: value.username,
            password:
              value.nodecode != undefined
                ? value.password
                : jwt.decode(value.password, secret).toString(),
            domain: value.domain,
            port: value.port,
            security: 'any',
            'ignore-cert': true,
            'enable-wallpaper': false,
            'create-recording-path': true,
            'recording-path': '/opt/sensr/videos',
            'recording-name': value.vd
          },
        },
      };
    }
  }

  return encrypt(json);

};
