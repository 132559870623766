import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Tooltip } from 'react-tippy';
import FaClose from 'react-icons/lib/fa/close';
import FaTrash from 'react-icons/lib/fa/trash';
import FaListUl from 'react-icons/lib/fa/list-ul';
import { Container, Row, Col } from 'react-grid-system';
import { Checkbox, Paper, Card, TextField, Switch } from '@material-ui/core';
import DialogConfirm from '../../common/DialogConfirm';
import AppBar from '../../common/AppBar';
import Dialog from '../../common/Dialog';
import Button from '../../common/Button';
import Constants from '../../data/Constants';
import Help from '../../common/components/Help';
import CommonForm from '../../common/CommonForm';
import PageLayout from '../../common/PageLayout';
import CommonTable from '../../common/CommonTable';
import RaisedButton from '../../common/RaisedButton';
import { set } from 'nprogress';

@observer
export default class CategoryAddCatalogForm extends Component {
  constructor() {
    super();
    this.state = {
      checkedCatalogs: [],
      catalogs: [],
      totalcatalogs: 0,
      currentPage: 0,
      open: false,
      all: false,
      listCatalogs:[],
      deleted:false,
      valueDelete:{},
      refresh:false
    };
    this.handleModal = this.handleModal.bind(this);
    this.checkBoxSelect = this.checkBoxSelect.bind(this);
    this.checkBoxSelectAll = this.checkBoxSelectAll.bind(this);
    this.addCatalogInCategory = this.addCatalogInCategory.bind(this);
    this.callPageSelectFilter = this.callPageSelectFilter.bind(this);
    this.removeCatalogOfCategory = this.removeCatalogOfCategory.bind(this);
  }

  componentWillMount() {
    const category = this.props.values;
    this.setState({
      category: category,
    });
    this.props.action
    .execute('get', this.props.APIEndpoints.CATALOG + '/company/' + 0 + '/' + this.props.values.id_category, '')
    .then((b) => {
      this.setState({listCatalogs:b.data});
    });

  }

  componentWillUnmount() {
    if (this.state.modified) {
      this.props.refreshCategory(this.state.category);




    }


  }

  handleModal = () => {
    this.props.action
    .execute('get', this.props.APIEndpoints.CATALOG + '/company/' + 0 + '/' + this.props.values.id_category, '')
    .then((b) => {
      console.log(b.data)
      this.setState({listCatalogs:b.data});
    });
    this.callPageSelectFilter(0, {});
    this.setState({ open: !this.state.open });



  };

  checkBoxSelectAll() {
    const { catalogs, all } = this.state;

    if (all) {
      const updatedCatalogs = catalogs.map((catalog) => ({ ...catalog, checked: false }));
      this.setState({ catalogs: updatedCatalogs, checkedCatalogs: [], all: false });
    } else {
      const allCatalogIds = catalogs.map((catalog) => catalog.id_catalog_service);
      const updatedCatalogs = catalogs.map((catalog) => ({ ...catalog, checked: true }));
      this.setState({ catalogs: updatedCatalogs, checkedCatalogs: allCatalogIds, all: true });
    }
  }


  checkBoxSelect(id_catalog_service) {
    let { catalogs, checkedCatalogs } = this.state;
    const index = catalogs.findIndex((catalog) => catalog.id_catalog_service === id_catalog_service);

    if (index !== -1) {
      catalogs[index].checked = !catalogs[index].checked;
      checkedCatalogs = catalogs.filter((catalog) => catalog.checked).map((catalog) => catalog.id_catalog_service);
      this.setState({ catalogs, checkedCatalogs, all: false });
    }
  }

  addCatalogInCategory() {
    let { category, checkedCatalogs } = this.state;
    if (checkedCatalogs.length) {
      let payload = {
        catalogs: checkedCatalogs,
        id_category: category.id_category,
      };

      this.props.action
        .execute('put', `${Constants.APIEndpoints.CATEGORY}/catalog`, '', payload)
        .then((res) => {
          this.setState({
            checkedCatalogs: [],
            category: res.data,
            modified: true
          });
        });
      this.handleModal();
    }

    this.props.action
    .execute('get', this.props.APIEndpoints.CATALOG + '/company/' + 0 + '/' + this.props.values.id_category, '')
    .then((b) => {
      this.setState({listCatalogs:b.data});
    });

  }

  removeCatalogOfCategory(values) {
    this.props.action
      .execute('put', `${Constants.APIEndpoints.CATEGORY}/catalog`, '', values)
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }

        this.setState({
          checkedCatalogs: [],
          category: res,
          modified: true,
        });

        this.props.action
          .execute('get', this.props.APIEndpoints.CATALOG + '/company/' + 0 + '/' + this.props.values.id_category, '')
          .then((b) => {
            this.setState({listCatalogs:b.data});
            this.setState({deleted:false})
          });
      });
  }

  callPageSelectFilter(page = 0, values, totalPerPage = 75, type) {
    this.setState({ currentPage: page });

    if (type === 'filter') {
      this.setState({ catalogs: [], all: false });
    }

    this.props.action
      .execute('post', `${Constants.APIEndpoints.CATALOG}/list?limit=${totalPerPage}&offset=${page * totalPerPage}`, '', values)
      .then((res) => {
        if( res.data ) {
          res = res.data;
        }
        const { checkedCatalogs } = this.state;
        const updatedCatalogs = res.map((catalog) => {
          const isChecked = checkedCatalogs.includes(catalog.id_catalog_service);
          return { ...catalog, checked: isChecked };
        });
        this.setState({
          catalogs: updatedCatalogs,
          totalcatalogs: parseInt(res[0].total_count),
        });
      });
  }

  render() {
    let { language, handlePage, values, redirect } = this.props;
    let {
      open,
      category,
      catalogs,
      checkedCatalogs,
      totalcatalogs,
      currentPage,
    } = this.state;

    if (!category) return null;

    let col = [
      { key: 'id', label: language.ID, style: { width: 20 } },
      { key: 'name', label: language.NAME },
      { key: 'description', label: language.DESCRIPTION },
      { key: 'remove', label: language.REMOVE, style: { width: 20 } },
    ];


    //category catalog

    console.log('catalogosssss')
    console.log(this.state.listCatalogs)
    let data =

    this.state.listCatalogs.map((obj) => ({
          id: obj.id_catalog_service,
          name: obj.name,
          description: obj.description,
          remove: (
            <Button
              variant="circle"
              size={0.7}
              icon="far fa-trash-alt"
              label={language.DELETE}
              color="danger"
              _loading={
                'del-category-item-' +
                obj.id_catalog_service +
                '-' +
                category.id_category
              }
              style={{ fontSize: 14 }}
              onClick={() =>{

               this.setState({valueDelete:{
                  id_catalog_service: obj.id_catalog_service,
                  id_category: category.id_category,
                  name: obj.name,
                  detach: true,
                }})
                this.setState({deleted:true})
              }

              }
            />
          ),
        }));

    let colCatalog = [
      {
        key: 'check',
        label: (
          <Checkbox
            checked={this.state.all}
            onChange={() => this.checkBoxSelectAll()}
          />
        ),
      },
      { key: 'id', label: language.ID },
      { key: 'name', label: language.NAME },
      { key: 'description', label: language.DESCRIPTION },
    ];

    let groupCatalog = !category.catalog
      ? []
      : category.catalog.map((catalog) => catalog.id_catalog_service);

    let dataCatolog = catalogs
      .filter((c) => !groupCatalog.includes(parseInt(c.id_catalog_service)))
      .map((obj) => {
        return {
          check: (
            <Checkbox
              checked={checkedCatalogs.indexOf(obj.id_catalog_service) != -1}
              onChange={() => this.checkBoxSelect(obj.id_catalog_service)}
            />
          ),
          id: obj.id_catalog_service,
          name: obj.name,
          description: obj.description,
        };
      });
    console.log(dataCatolog);
    let addCatalogDialog = (
      <Dialog
        modal={false}
        open={open}
        onClose={() => this.handleModal()}
        maxWidth="xl"
        title={language.ADD_CATALOG_TO_CATEGORY}
      >
        <CommonTable
          title={''}
          language={language}
          columns={colCatalog}
          data={dataCatolog}
          searchColumnEvent={(v) =>
            this.callPageSelectFilter(0, v, 75, 'filter')
          }
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            this.callPageSelectFilter(page, values, rowsPerPage, type)
          }
          countTotal={totalcatalogs}
          isAutomaticPagination={false}
          beginWithNoFilter={true}
          loadSearch={true}
          currentPage={currentPage}
          hideFooterToolbar
          paginationTop={false}
        />

        <Row style={{ marginTop: 10 }}>
          <Col>
            <div style={{ float: 'right' }}>
              <RaisedButton
                circleButton
                icon="fas fa-plus"
                onClick={this.addCatalogInCategory}
                label={language.ADD}
                primary={true}
                fullWidth
              />
            </div>
          </Col>
        </Row>
      </Dialog>
    );

    return (
      <div>
        <Paper className="pb-3 mb-3">
          <Container fluid className="pt-2">
            <Row>
              <Col md={2}>
                <TextField
                  label={language.NAME}
                  disabled={true}
                  fullWidth
                  value={category.name}
                />
              </Col>
              <Col md={4}>
                <TextField
                  label={language.DATE_CAD}
                  disabled={true}
                  fullWidth
                  value={moment(category.dt_cad).format('LL')}
                />
              </Col>

              <Col md={6}>
                <TextField
                  label={language.DESCRIPTION}
                  multiLine={true}
                  fullWidth={true}
                  disabled={true}
                  value={category.description}
                />
              </Col>
            </Row>
          </Container>
        </Paper>
     {!this.state.refresh &&
        <CommonTable
          title={'#' + category.id_category}
          language={language}
          columns={col}
          data={data}
          beforeBar={
            <RaisedButton
              style={{ zoom: 0.8 }}
              zoom={0.8}
              className="mr-3"
              color="primary"
              circleButton
              icon="fas fa-plus"
              label={language.ADD_CATALOG_TO_CATEGORY}
              onClick={this.handleModal}
              primary
              fullWidth
            />
          }
        />
         }
          { this.state.deleted  && (
          <DialogConfirm
          maxWidth="md"
            open={this.state.deleted}
            language={language}
            confirmProps={{ color: 'danger' }}
            onConfirm={() => this.removeCatalogOfCategory({
               id_catalog_service: this.state.valueDelete.id_catalog_service,
               id_category: this.state.valueDelete.id_category,
               name: this.state.valueDelete.name,
               detach: true,
             })}
            onClose={()=>this.setState({deleted:false})}
            title={language.CONFIRM_DELETE}
            message={this.state.valueDelete.name}
          />
        )}

        {addCatalogDialog}
      </div>
    );
  }
}
