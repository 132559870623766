import Button from '@common/Button';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import SimplesAlert from '../../../common/SimplesAlert';
import { Constants } from '../../../data';
import { spFetch } from '../helpers';
import { TitleSlash } from './TitleSlash';

const useStyles = makeStyles((theme) => ({
  menuItemSmall: {
    fontSize: 12,
    paddingTop: theme.spacing(0.85),
    paddingBottom: theme.spacing(0.85),
  },
  font12: {
    fontSize: 12,
  },
  font13: {
    fontSize: 13,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export const ServicePortalAccountLocation = ({ language, userData }) => {
  const classes = useStyles();

  const langOptions = [
    { value: 'en', label: language.LANG.EN },
    { value: 'es', label: language.LANG.ES },
    { value: 'pt_br', label: language.LANG.PT_BR },
  ];

  const alertDefault = { opened: false, message: '', variant: 'success' };
  const [alert, setAlert] = useState(alertDefault);

  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const [langSelected, setLangSelected] = useState(userData.id_language);
  const [timezoneSelected, setTimezoneSelected] = useState();

  useEffect(() => {
    spFetch(Constants.APIEndpoints.TIMEZONE).then((response) => {
      const { data } = response;
      const options = data.map((item) => ({
        value: item.id_timezone,
        label: item.name,
      }));
      const itemSelected =
        options.find((item) => item.value === userData.timezone.id) || null;
      setTimezoneSelected(itemSelected);
      setTimezoneOptions(options);
    });
  }, []);

  const handleSubmit = async () => {
    const payload = {
      id_timezone: timezoneSelected.value,
      id_language: langSelected,
    };
    const url = Constants.APIEndpoints.CHANGE_DATA_LOCATION;
    spFetch(url, 'post', payload)
      .then((result) => {
        handleOpenAlert(language.REGISTER_SAVE);
      })
      .catch((error) => {
        handleOpenAlert(language.ERROR_CODE.E500, 'error');
      });
  };

  const handleCloseAlert = () => {
    setAlert(alertDefault);
  };

  const handleOpenAlert = (message, variant = 'default') => {
    setAlert({ opened: true, message: message, variant: variant });
  };

  return (
    <div className="tw-px-4" style={{ minHeight: 280 }}>
      <TitleSlash>{language.LOCATION}</TitleSlash>
      <div className="tw-mt-8">
        <div className="tw-mb-6 tw-bg-gray-100 tw-p-4 tw-flex tw-items-center tw-gap-2 tw-rounded">
          <Info className="tw-mr-2" />
          {language.LANGUAGE_TIMEZONE_CHANGES_NOTICE}
        </div>

        <FormControl size="small" variant="outlined" className="tw-w-full mb-4">
          <InputLabel id="language">{language.LANGUAGE}</InputLabel>
          <Select
            displayEmpty
            className={classes.font12}
            labelId="language"
            label={language.LANGUAGE}
            value={langSelected}
            onChange={(event) => setLangSelected(event.target.value)}
          >
            {langOptions.map((item, index) => (
              <MenuItem
                key={index}
                dense={true}
                value={item.value}
                className={classes.menuItemSmall}
                component={'div'}
                style={{
                  paddingTop: '1rem !important',
                  paddingBottom: '1rem !important',
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          getOptionLabel={(option) => option.label}
          classes={{
            option: classes.menuItemSmall,
            input: classes.font12,
          }}
          onChange={(event, newValue) => {
            setTimezoneSelected(newValue);
          }}
          options={timezoneOptions}
          value={timezoneSelected || null}
          ListboxComponent="div"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={language.TIMEZONE}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </div>

      <div className="tw-flex tw-justify-end tw-mt-6">
        <Button
          primary={true}
          label={
            <span className="btn--label">
              {language.NEXT}
              <i className="fas fa-arrow-right ml-2" />
            </span>
          }
          style={{ borderRadius: '4px !important' }}
          onClick={handleSubmit}
          disabled={!langSelected || !timezoneSelected}
        />
      </div>

      <SimplesAlert
        float
        open={alert.opened}
        message={alert.message}
        variant={alert.variant}
        onClose={() => handleCloseAlert()}
      />
    </div>
  );
};
