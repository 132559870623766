import Dispatcher from './Dispatcher';
import Constants from './Constants';
import Store from './Store';
import history2 from '@common/history2';
import 'whatwg-fetch';
const crypto = require('crypto');

const cache = {};

function generateIdScript(string) {
  return crypto.createHash('sha256').update(string).digest('hex');
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default class GenericActions {
  async execute(method, target, model, obj, primaryKey, msg, alert = true, removeInstantCache) {
    method = method.toLowerCase();

    let history = history2;
    let { pathname } = history.location;
    let cacheTimeout = !removeInstantCache ? 5 : 0;

    if (method === 'post' && !obj) {
      alert = false;
    }

    function status(response) {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      } else {
        return response.text().then(text => {
          return Promise.reject(text === "REDIRECT" || text === "LOGOUT" ? text : isJson(text) ? JSON.parse(text) : response);
        });
      }
    }

    let send = {
      method: method === 'csv' ? 'post' : method,
      headers: {
        Accept: method === 'csv' ? 'text/csv' : 'application/json',
        'Content-Type': method === 'csv' ? 'text/csv' : 'application/json',
        'X-Access-Token': Store.getToken(),
      },
    };

    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    if (method === 'post' || method === 'put' || method === 'delete' && obj) {
      send.body = JSON.stringify(obj, getCircularReplacer());
    } else if (obj === null) {
      obj = {};
    }

    var modelSP = (model || '').split(',');
    var newSenData = [];
    if (modelSP.length > 1) {
      modelSP.forEach((e) => {
        Store.loading++;
      });
    } else {
      Store.loading++;
    }

    Store.btn_loading = Store.btn_loading.concat(method + '-' + pathname);
    if (typeof msg === 'object' && msg !== null) {
      if (msg.btn_loading) {
        Store.btn_loading = Store.btn_loading.concat(msg.btn_loading);
      } else {
        Store.btn_loading = Store.btn_loading.concat(method + '-' + target);
      }
    } else {
      Store.btn_loading = Store.btn_loading.concat(method + '-' + target);
    }

    const headersString = JSON.stringify(send.headers, getCircularReplacer());
    const cacheKey = `${method}-${target}-${headersString}`;
    const payloadKey = obj ? JSON.stringify(obj, getCircularReplacer()) : '-';
    const generatedCacheKey = generateIdScript(`${cacheKey}-${payloadKey}`);

    if (cache[generatedCacheKey]) {
      const cachedResult = await cache[generatedCacheKey];
      if (modelSP.length > 1) {
        for (var i = 0; i < modelSP.length; i++) {
          cachedResult.model = modelSP[i];
          cachedResult.primaryKey = (primaryKey || '').split(',')[i];
          if (cachedResult.model) {
            Dispatcher.dispatch(cachedResult);
          }
        }
      } else if (model && !(modelSP.length > 1)) {
        cachedResult.model = modelSP[0];
        Dispatcher.dispatch(cachedResult);
      }
      return cachedResult;
    }

    try {
      const fetchPromise = fetch(target, send)
        .then(status)
        .then(response => response.text().then(data => ({ response, data })))
        .then(({ response, data }) => {
          const totalCount = response.headers.get('x-total-count');
          return { data, totalCount };
        })
        .then(({ data, totalCount }) => {
          var senData = {
            type: method,
            target,
            model,
            data: method === 'csv' ? data : data ? JSON.parse(data) : null,
            primaryKey: primaryKey,
            config: {},
          };

          if (totalCount) {
            senData.totalCount = totalCount;
          }

          if (typeof msg === 'object' && msg !== null) {
            senData.config = msg;
            senData.msg = msg.msg;
            senData.alert = msg.alert;
          } else {
            senData.msg = msg;
            senData.alert = alert;
          }

          if (modelSP.length > 1) {
            for (var i = 0; i < modelSP.length; i++) {
              senData = Object.assign({}, senData, {
                type: modelSP[i].split('-')[0].replace('$', ''),
                model: modelSP[i].split('-')[1],
                data: (isJson(data) ? JSON.parse(data) : {})[
                  modelSP[i].split('-')[1]
                ],
                primaryKey: (primaryKey || '').split(',')[i],
              });

              if (senData.model) {
                Dispatcher.dispatch(senData);
              }
              newSenData.push(senData);
            }
          }

          if (model && !(modelSP.length > 1)) {
            Dispatcher.dispatch(senData);
          }

          if (!model) {
            Store.loading--;
          }

          Store.btn_loading = Store.btn_loading.filter(
            (e) => ![method + '-' + pathname, method + '-' + target].includes(e)
          );
          if (typeof msg === 'object' && msg !== null) {
            if (msg.btn_loading) {
              Store.btn_loading = Store.btn_loading.filter(
                (e) => e !== msg.btn_loading
              );
            }
          }

          return modelSP.length > 1 ? newSenData : senData;
        })
        .catch((err) => {
          Store.btn_loading = Store.btn_loading.filter(
            (e) => ![method + '-' + pathname, method + '-' + target].includes(e)
          );
          if (typeof msg == 'object' && msg !== null) {
            if (msg.btn_loading) {
              Store.btn_loading = Store.btn_loading.filter(
                (e) => e != msg.btn_loading
              );
            }
          }

          if (typeof err === 'object' && err.then instanceof Function) {
            err.then((result) => {
              var senData = {
                model: 'errors',
                data: isJson(result) ? JSON.parse(result) : result,
                message: isJson(result) ? JSON.parse(result) : result,
              };

              Dispatcher.dispatch(senData);
              return Promise.reject(senData);
            });
          } else {
            var senData = {
              model: 'errors',
              data: isJson(err) ? JSON.parse(err) : err,
              message: isJson(err) ? JSON.parse(err) : err,
            };

            Dispatcher.dispatch(senData);
            return Promise.reject(senData);
          }
        })
        .finally(() => {
          setTimeout(() => {
            delete cache[generatedCacheKey];
          }, 1000 * cacheTimeout);
        });

      cache[generatedCacheKey] = fetchPromise;
      return await fetchPromise;
    } catch (error) {
      delete cache[generatedCacheKey];
      throw error;
    }
  }
}
