import React, { useState, useReducer, useEffect } from 'react';
import Dialog from '@common/Dialog';
import {
 
  Grid

   
} from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import { Avatar, TextFields } from '@material-ui/core';
import Button from '@common/Button';
import BarBlue from '@images/BarBlue.png';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachFileImage from '@images/attachFile.png';
import { Tooltip } from 'react-tippy';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
var CryptoJS = require('crypto-js') 
const crypto = require('crypto');
export default function({
  
  onClose,
  store,
  action,
  APIEndpoints,
  values,
  closeContract
  
}) {
  
  
  const  decryp=(aMsg='')=> {
    try{
      var aSecret = 'Orch3str0!@forever!';
      aMsg = aMsg.replace(/\s/g, "");
      let decipher, tRet;
      decipher = crypto.createDecipher("aes-256-cbc", aSecret);
      tRet = decipher.update(aMsg.replace(/\s/g, "+"), "base64", "utf8");
      tRet += decipher.final("utf8");
      return tRet;
    }catch(e){
      return {}
    }
  
  }
  
 let [companies,setCompanies]= useState([]);
 let [companies_labels,setCompaniesLabels]= useState([]);
 let [contractsOptions,setContractsOptions]= useState([]);
 let [open,setOpen]= useState(false);
  const  onSubmit=(v,values)=>{
  
   
    values.items = v.fk_id_company
    values.update='company';
 
    action.execute('post',  APIEndpoints.SOLUTION+'/association', '',values).then(c=>{
      setCompanies({fk_id_company:c.data.map(b=>b.fk_id_company)})
 
     })
     closeContract()
   // /api/user/upcontract
   }

  useEffect(() => {
 
 
   
   action.execute('post',  APIEndpoints.SOLUTION + '/association/', '',
  {
    type:'company',id_solution:values.id_solution
    }
  ).then(c=>{
    
  setCompanies(c.data.map(b=>b.fk_id_company))
  setCompaniesLabels(c.data.map(b=>b.company))

  }) ///api/company/user/:id
 
  },[values])
 
 let form=[];
  return (
    <Dialog
    PaperProps={{
      id: ''
    }}   title={ 
     'Associar Técnico x Empresa' 
    }
    open={true}
    onClose={closeContract}
    >
       
      <CommonForm
        values={companies}
        fields={[
         
           
          {
           
            type: 'autocomplete',
            name: 'fk_id_company',
            label: 'Empresa',
            method: 'POST',
            route: `${APIEndpoints.COMPANY}/filter`,
            fieldquery: 'search',
            textlabel: `Empresa`,
            textinit: companies_labels.length>0 ?companies_labels: 'Empresa',
            fieldvaluedb: 'id_company',
            fieldlabeldb: 'name',
            idinit:  companies  ,
            routeAll: `${APIEndpoints.COMPANY}/all?`,
            methodGetAll: 'POST',
            routeGetCount: `${APIEndpoints.COMPANY}/count`,
           
            required: true,
            showEmail: true,
            isMulti: true

          }
        ]}
        onSubmit={v => onSubmit(v,values)}
      />
 </Dialog>
   
  );
}
