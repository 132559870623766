import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { spFetch, useServicePortalStyles } from '../helpers';
import classNames from 'classnames';
import ServicePortalTicketSubTitle from './ServicePortalTicketSubTitle';
import Constants from '@data/Constants';

export default function ServicePortalTicketDescription({ ticketId, language }) {

    const styles = useServicePortalStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [ticketDescription, setTicketDescription] = useState();

    useEffect(() => {
        getData()
    }, [])

    const getData = (all = false) => {
        setIsLoading(true);
        const urlTicketDetails = `${Constants.APIEndpoints.TICKET}/detail`;
        spFetch(urlTicketDetails, 'post', { id_tickets: ticketId, all })
            .then(({ data }) => setTicketDescription(data.description))
            .finally(() => setIsLoading(false));
    }


    return (
        <div className=''>
            <ServicePortalTicketSubTitle label={language.DESCRIPTION} iconText='fa-align-left' />
            <div className='mb-2 tw-border tw-rounded tw-p-2 tw-h-72'>
                <div className='tw-h-full tw-overflow-y-auto'>
                    {ticketDescription &&
                        <div className=''>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: ticketDescription,
                                }}
                            />

                            {ticketDescription.length === 900 &&
                                <Button size="small" onClick={() => getData(true)} className='tw-capitalize'>
                                    {language.LOAD_MORE}
                                    {isLoading && <CircularProgress color='inherit' size='1rem' className='ml-2' />}
                                </Button>}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
