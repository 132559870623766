import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Tooltip } from 'react-tippy';
import { Card } from '@material-ui/core';
import RaisedButton from '../../common/RaisedButton';
import PageLayout from '../../common/PageLayout';
import Help from '../../common/components/Help';
import withLanguage from '../../common/languages/withLanguage';

import CommonTable from '../../common/CommonTable';

import FaFileTextO from 'react-icons/lib/fa/file-text-o';
import FaEdit from 'react-icons/lib/fa/edit';
import FaTrashO from 'react-icons/lib/fa/trash-o';

export default class AssetManagerContractList extends Component {
  render() {
    let { language, handlePage, redirect } = this.props;

    let col = [
      { key: 'id', label: language.ID, style: { width: 80 } },
      { key: 'name', label: language.NAME },
      { key: 'description', label: language.DESCRIPTION },
      { key: 'edit', label: language.EDIT, style: { width: 20 } },
      { key: 'remove', label: language.REMOVE, style: { width: 20 } }
    ];

    let data = [
      {
        id: '01',
        name: 'Capul-Suporte Banco de Dados',
        description: language.DESCRIPTION_OF_CONTRACT,
        edit: (
          <FaEdit
            fontSize={22}
            className="colorPrimary pointer"
            onClick={() => handlePage('typeContractsForm')}
          />
        ),
        remove: <FaTrashO fontSize={22} className="colorPrimary pointer" />
      }
    ];

    return (
      <PageLayout
        icon={<FaFileTextO fontSize={30} className="colorPrimary" />}
        title={language.CONTRACT_TYPE}
        subtitle={language.CONTRACT_TYPE_LIST}
        rightElements={[
          <RaisedButton
            label={language.BACK}
            primary={true}
            style={{ marginRight: 10 }}
            onClick={() => redirect('/dash/configuration')}
          />,
          <RaisedButton
            label={language.NEW_CONTRACT}
            primary={true}
            style={{ marginRight: 10 }}
            onClick={() => handlePage('typeContractsForm')}
          />,
          <Help>help</Help>
        ]}
      >
        <Card style={{ marginTop: 10 }}>
          <Row>
            <CommonTable
              title={''}
              language={language}
              columns={col}
              data={data}
              showHeaderToolbar
            />
          </Row>
        </Card>
      </PageLayout>
    );
  }
}
