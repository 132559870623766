import React from 'react';
import Store from '../../../data/Store';
import Constants from '../../../data/Constants';
import AttendanceTasks from './AttendanceTasks';
import CommonPage from '../../../common/CommonPage';
import PageLayout from '../../../common/PageLayout';

export default function ListTasksAttendance() {
  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title={Store.language.TASKS_LIST}
        subTitle={Store.language.TASK_MANAGEMENT + '/'}
      >
        <AttendanceTasks
          APIEndpoints={Constants.APIEndpoints}
          language={Store.language}
        />
      </PageLayout>
    </CommonPage>
  );
}
