import React, { useState, useEffect, useCallback } from 'react';
import Store from '@data/Store';
import Actions from '@data/Actions';
import Constants from '@data/Constants';
/*-------------------------common----------------------*/
import { flexBasis } from '@common/Func';
import ReactHighcharts from 'react-highcharts';
import AppBar from '@common/AppBar2';
/*-------------------------material-ui----------------------*/
import { Grid, Card } from '@material-ui/core';
/*-------------------------pages----------------------*/
import { CallMonth } from './Graph';
import GridStatusListB4B6 from '../GridStatusListB4B6';
import moment from 'moment';

export default function ({ dataMonth, id_tech, idGroupTech }) {
  const [yearType, setYearType] = useState([]);
  const [yearSelect, setYearSelect] = useState(moment().format('YYYY'));
  useEffect(() => {
    loadYearType();

    return () => setYearType([]);
  }, [id_tech, idGroupTech]);

  const loadYearType = async (date) => {
    let selectDate = date ? parseInt(date) : moment().format('YYYY');
    const action = new Actions();
    //JSON.stringify(id_tech)+'&group='+JSON.stringify(idGroupTech)
    try {
      await action
        .execute(
          'get',
          Constants.APIEndpoints.DASHBOARD +
            '-yeartype' +
            `?year=${selectDate}&tech=${JSON.stringify(
              id_tech
            )}&group=${JSON.stringify(idGroupTech)}
        
        `,
          'yeartype'
        )
        .then((result) => {
          setYearType(result.data.yeartype);
        });
    } catch (error) {}
  };

  const handleSelectYear = useCallback(async (year) => {
    try {
      setYearSelect(year);
      loadYearType(year);
    } catch (error) {}
  }, []);

  let { language } = Store;
  const currentMonthDates = new Array(moment().daysInMonth())
    .fill(null)
    .map((x, i) =>
      parseInt(moment().startOf('month').add(i, 'days').format('DD'))
    );
  let data = [];
  
  console.log(yearType)
  if (yearType.length > 0) {
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((g) => {
      let valor = yearType.filter((h) => h.month == g && h.type_tickets == 4);
      let valor1 = yearType.filter((h) => h.month == g && h.type_tickets == 1);
      let valor3 = yearType.filter((h) => h.month == g && h.type_tickets == 2);

      data.push({
        solicitation: valor.length > 0 ? parseInt(valor[0].qtd) : 0,
        incident: valor1.length > 0 ? parseInt(valor1[0].qtd) : 0,
        problem: valor3.length > 0 ? parseInt(valor3[0].qtd) : 0,
      });
    });

  }
  data = language.DATE_LANG.monthLongList.map((e, i) => ({
    label: e,
    solicitation: (data[i] || { solicitation: 0 }).solicitation,
    incident: (data[i] || { incident: 0 }).incident,
    problem: (data[i] || { problem: 0 }).problem,
  }));
 
  let data2 = {
    solicitations: dataMonth.solicitations,
    incidents: dataMonth.incidents,
    problems: dataMonth.problems,
  };

  return (
    <Card id="CallMonth" className="h-100">
      <AppBar title={language.CALLS_OF_MONTH} />

      <div className="content-medium pt-0">
        <Grid container spacing={16}>
          <Grid item xs={8}>
            <ReactHighcharts
              config={CallMonth({
                categories: currentMonthDates,
                data: data2,
              })}
            />
          </Grid>
          <Grid item xs={4}>
            <GridStatusListB4B6
              gridColumn="35.5% 21.5% 21.5% 21.5%"
              data={data}
              id_tech={id_tech}
              idGroupTech={idGroupTech}
              year={yearSelect}
              handleSelectYear={handleSelectYear}
              language={language}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
