import React, { Component } from 'react';
import '../public/css/common.css';
import '../public/css/font-mfizz.css';
import '../public/css/flaticon.css';
import { Router, Switch, Route, Redirect } from 'react-router';
import { BrowserTracing } from '@sentry/tracing';
import createBrowserHistory from '@common/history2';
import Store from './data/Store';
import ChiefAlternative from './components/chiefAlternative/ChiefContainer';
import RiskManagementContainer from './components/riskmanagement/RiskManagementContainer';
import ConfigurationContainer from './components/configuration/ConfigurationContainer';
import SideDishContainer from './components/riskmanagement/SideDish/SideDishContainer';
import ContractContainer from './components/contract/ContractContainer';
import AssessmentContainer from './components/assessment/AssessmentContainer';
import Bsc from './components/bsc2';
import CapacityPlanContainer from './components/CapacityPlan2';

import SlaForm from './components/slaForm';

import Slawork from './components/slaWork';

// import CatalogContainer from './components/catalog/CatalogContainer';

import Build from './components/Build/Index';
import BuildHome from './components/Build/Buildhome';
import AnswerContainer from './components/answer/AnswerContainer';
import AuditContainer from './components/audit/AuditContainer';
import AuditContainerNew from './components/audit/AuditContainer';
// import CompanyContainer from './components/company/CompanyContainer';
// import DeviceContainer from './components/device/DeviceContainer';
import DeviceContainerInventory from './components/device/DeviceContainer';
import MonitorContainer from './components/monitor2';
import OKR from './components/OKR';
import LoginContainer from './components/login/LoginContainer';
import Avaliation from './components/avaliation/AvaliationPage';
import TicketContainer from './components/tickets/TicketContainer';
// import SlaContainer from './components/sla/SlaContainer';
import UserContainer from './components/user/UserContainer';

import ReportContainer from './components/reports/ReportContainer';

import AnalystReportCalls from './components/reports/reportpages/gsd/AnalystReportCalls';
import WelcomeContainer from './components/welcome/WelcomeContainer';
// import CategoryContainer from './components/category/CategoryContainer';
import TagContainer from './components/tags/TagContainer';

// import GroupContainer from './components/group/GroupContainer';

// import DepartmentContainer from './components/department/DepartmentContainer';
import TypeContractContainer from './components/typecontracts/TypeContractContainer';
// import TaskContainer from './components/task/TaskContainer';
import ServiceOrderContainer from './components/serviceorder/ServiceOrderContainer';
import ServiceOrderContainerForm from './components/serviceorder/ServiceOrderContainer';
import GuacamoleView from './components/guaca/index';
import RequestContainer from './components/request/RequestContainer';
import CIOContainer from './components/dashboards/CIOContainer';
import CioPageGraph from './components/dashboards/CioPageGraph';

// import GCFContainer from './components/dashboards/GCFContainer'; //antigo
import GCFContainer from './components/GCF'; // atual

import GIContainer from './components/GI2'; // atual
// import GIContainer from './components/dashboards/GIContainer' //antigo
import GSDContainer from './components/dashboards/GSDContainer';

import GSDContainerSac from './components/sac/AttendanceGADContainer';

import SolutionContainer from './components/solution/SolutionContainer';
import ProjectManagementContainer from './components/projectmanagement/ProjectManagementContainer';
import CalendarioActivities from './components/Calendario/CalendarioContainer';
import CalendarioChangeActivities from './components/changemanagement/Calendario/CalendarioContainer';
import DownloadAgent from './components/monitor/DownloadAgent';
import ChangeManagementContainer from './components/changemanagement/ChangeManagementContainer';
import InventoryContainer from './components/inventory/InventoryContainer';
// import MailContainer from './components/mail/MailContainer';
// import CompanyTimeContainer from './components/companytime/CompanyTimeContainer';
import StockContainer from './components/stock/StockContainer';
// import DeviceType from './components/devicetype/DeviceTypeContainer';
import Site from './components/site/SiteContainer';
import Panel from './components/panel/PanelContainer';
import saveContract from './components/contract/ContractContainer';
// import ad from './components/ad/AdContainer';
// import Discovery from './components/discovery/DiscoveryContainer';
import DeviceConf from './components/monitor/MonitorContainer';
import TabelaRisco from './components/gi/TabelaRisco';
import SubscribeContracts from './components/SubscribeContracts';
import GV from './components/GV/GV';
import TicketHistoric from './components/tickets/TicketHistoric';
import DemandmanagementContainer from './components/demandmanagement/DemandmanagementContainer.js';
// import PerfilContainer from './components/perfil/PerfilContainer.js'
import BudgetControlContainer2 from './components/BudgetControl2';
// import GeneralParametersContainer from './components/GeneralParameters/GeneralParametersContainer';
import ModelCanvasContainer from './components/ModelCanvas/ModelCanvasContainer';
import DashboardContent from './components/dashboard/DashboardContent';
// import SnmpContainer from './components/snmp/snmpContainer';

import TicketManagement from './components/TicketManagement';
/*------------------------------------------projects-------------------------------------------------------------------*/
import ProjectManagementList from './components/Projects/ProjectsList';
import ProjectPage from './components/Projects/Project';
import ProjectManagementPmo from './components/Projects/PMO';
import ProjectManagementReport from './components/Projects/Report';
/*------------------------------------------Prioritization-------------------------------------------------------------------*/
import PrioritizationHome from './components/projectmanagement/Prioritization/Home';
import PrioritizationAvaliaton from './components/projectmanagement/Prioritization/Avaliation';
import PrioritizationConfiguration from './components/projectmanagement/Prioritization/Configuration';
/*------------------------------------------Sprint-------------------------------------------------------------------*/
import SprintHome from './components/projectmanagement/Sprint/Home';
import SprintKanban from './components/projectmanagement/Sprint/Kanban';
/*------------------------------------------OKR-------------------------------------------------------------------*/
//import OKR from './components/OKR';
/*------------------------------------------HOME-------------------------------------------------------------------*/
import Home from './components/Home';

import AvaliationOrderContainer from './components/serviceorder/AvaliationOrder';
import DashboardTaskManagement from './components/taskmanagement/Dashboard';
import ApproveTask from './components/taskmanagement/ApproveTask';
import TechTask from './components/taskmanagement/TechTask';

import TaskCalendar from './components/taskmanagement/Calendar/TaskCalendar';
import TicketTask from './components/taskmanagement/Ticket';

import PortalContainer from './components/portal/PortalContainer';

import SacConsumerBase from './components/sac/consumer/SacConsumerBase';
import SacThemeList from './components/sac/theme/SacThemeList';
import TopicList from './components/sac/topic/TopicList';

import SubjectContainer from './components/sac/subject/SubjectContainer';
import AttendanceContainer from './components/sac/attendance/AttendanceContainer';
import ListTasksAttendance from './components/sac/listtask';

import ServicePortal from './components/ServicePortal';
import ApprovalCenterContainer from './components/ApprovalCenter/ApprovalCenterContainer';
import Highcharts from 'highcharts';
import moment from 'moment';

const { language, language_type } = Store;

Highcharts.setOptions({
  lang: {
    downloadCSV: language.DOWNLOAD_CSV,
    downloadJPEG: language.DOWNLOAD_JPEG,
    downloadMIDI: language.DOWNLOAD_MIDI,
    downloadPDF: language.DOWNLOAD_PDF,
    downloadPNG: language.DOWNLOAD_PNG,
    downloadSVG: language.DOWNLOAD_SVG,
    downloadXLS: language.DOWNLOAD_XLS,
    viewFullscreen: language.VIEW_FULL_SCREEN,
    printChart: language.PRINT_CHART,
  }
});

moment.locale(language_type);

// console.log({ language_type });


const PrivateRoute = ({ component, componentProps, ...rest }) => {
  if (Store.getToken()) {
    const user = Store.getUserLoged();
    if (user.use_service_portal_only && !rest.location.pathname.startsWith('/dash/service-portal')) {
      return <Redirect push to='/dash/service-portal' />
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          React.createElement(component, { ...props, ...componentProps })
        }
      />
    );
  } else {
    const url = new URL(window.location.href);
    const returnUrl = url.pathname ? '?returnUrl=' + encodeURI(url.href.replace(url.origin, '')) : '';
    window.location.href = window.location.origin + '/login/' + returnUrl
  }
};

export default class Routes extends React.Component {
  render() {
    return (
      <Router history={createBrowserHistory}>
        <Switch>
          {/* ---------------------------------------OKR----------------------------------------- */}
          <PrivateRoute exact path="/dash/okr" component={OKR} />
          {/** ----------LOGIN--------- */}
          <Route exact path="/" component={LoginContainer} />
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/redefine" component={LoginContainer} />
          <Route exact path="/expired" component={LoginContainer} />
          <Route exact path="/avaliation" component={Avaliation} />
          {/* ---------------------------------------Sprint----------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/projectmanagement/sprint"
            component={SprintHome}
          />
          <PrivateRoute
            exact
            path="/dash/projectmanagement/sprint/:id/kanban"
            component={SprintKanban}
          />
          {/* ------------------------------------------------------------------------------------- */}
          <PrivateRoute
            path="/dash/service-portal"
            component={ServicePortal}
          />
          <PrivateRoute exact path="/welcome" component={WelcomeContainer} />
          <PrivateRoute exact path="/dash" component={Home} />
          <PrivateRoute exact path="/dash/modules" component={CioPageGraph} />
          <PrivateRoute exact path="/dash/cio" component={CIOContainer} />
          <PrivateRoute
            exact
            path="/dash/SubscribeContracts"
            component={SubscribeContracts}
          />
          <PrivateRoute exact path="/dash/gv" component={GV} />
          <PrivateRoute
            exact
            path="/dash/demandmanagement"
            component={DemandmanagementContainer}
          />
          {/* <PrivateRoute exact path="/dash/perfil" component={PerfilContainer} /> */}
          <PrivateRoute
            exact
            path="/dash/budgetcontrol2"
            component={BudgetControlContainer2}
          />
          {/* <PrivateRoute exact path="/dash/generalparameters" component={GeneralParametersContainer} /> */}
          <PrivateRoute
            exact
            path="/dash/searchTickets"
            component={TicketHistoric}
          />
          <PrivateRoute
            exact
            path="/dash/ticketmanagement"
            component={TicketManagement}
          />
          <PrivateRoute
            exact
            path="/dash/assessment"
            component={AssessmentContainer}
          />
          <PrivateRoute
            exact
            path="/dash/guacamoleview"
            component={GuacamoleView}
          />
          <PrivateRoute exact path="/dash/audit" component={AuditContainer} />
          <PrivateRoute
            exact
            path="/dash/capacityPlan"
            component={CapacityPlanContainer}
          />
          {/* --------------------------------------SNMP--------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={['/dash/snmp', /\/dash\/snmp\/(new|edit|clone)/]}
              component={SnmpContainer}
            /> */}
          {/* --------------------------------------Dashboard--------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/approvalcenter"
            component={ApprovalCenterContainer}
          />
          <PrivateRoute
            exact
            path="/dash/dashboard"
            component={DashboardContent}
          />
          {/* --------------------------------------Canvas--------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/canvas"
            component={ModelCanvasContainer}
          />
          {/* --------------------------------------User--------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/changeData',
              '/dash/generaluser',
              '/dash/changePass',
              '/dash/user',
              '/dash/user/sponsor',
              '/dash/user/sponsor/new',
              '/dash/user/outsourced',
              '/dash/user/outsourced/new',
              '/dash/user/outsourced/edit',
            ]}
            component={UserContainer}
          />
          {/* --------------------------------------Company--------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/company",
                "/dash/company/new",
                "/dash/company/edit",
                "/dash/company/new/task",
                "/dash/company/new/department",
              ]}
              component={CompanyContainer}
            /> */}
          {/* --------------------------------------Matriz de risco--------------------------------------------------- */}
          <PrivateRoute exact path="/dash/gri" component={TabelaRisco} />
          <PrivateRoute exact path="/dash/sla" component={SlaForm} />
          {/* --------------------------------------contracts--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/contracts',
              '/dash/contracts/new',
              '/dash/contracts/edit',
            ]}
            component={ContractContainer}
          />
          {/* --------------------------------------serviceorder--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/serviceorder',
              '/dash/serviceorder/view',
              '/dash/serviceorder/new',
              '/dash/serviceorder/edit',
            ]}
            component={ServiceOrderContainer}
          />
          <PrivateRoute
            exact
            path={['/dash/serviceorder/avaliation']}
            component={AvaliationOrderContainer}
          />
          {/* --------------------------------------Stock--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/stock"
            // eslint-disable-next-line react/jsx-no-duplicate-props
            path={[
              '/dash/stock',
              '/dash/stock/historic',
              '/dash/stock/historic/detail',
            ]}
            component={StockContainer}
          />
          {/* --------------------------------------Inventory--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/inventory',
              '/dash/inventory/software',
              '/dash/inventory/mac',
              '/dash/inventory/hardware',
              '/dash/inventory/service',
              '/dash/inventory/vulnerability',
            ]}
            component={InventoryContainer}
          />
          {/* --------------------------------------Solution--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/solution',
              '/dash/solution/new',
              '/dash/solution/edit',
              '/dash/solution/view',
            ]}
            component={SolutionContainer}
          />
          {/* --------------------------------------Riskmanagement--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/riskmanagement"
            componentProps={{ risk: 0 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_27001"
            componentProps={{ risk: 1 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_20000"
            componentProps={{ risk: 2 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/cobit"
            componentProps={{ risk: 3 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/backup_management"
            componentProps={{ risk: 4 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/it_evolution_management"
            componentProps={{ risk: 5 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/lgpd"
            componentProps={{ risk: 6 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_9001"
            componentProps={{ risk: 7 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_37001"
            componentProps={{ risk: 8 }}
            component={RiskManagementContainer}
          />

          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_27001/config"
            componentProps={{ risk: 1 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_20000/config"
            componentProps={{ risk: 2 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/cobit/config"
            componentProps={{ risk: 3 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/backup_management/config"
            componentProps={{ risk: 4 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/it_evolution_management/config"
            componentProps={{ risk: 5 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/lgpd/config"
            componentProps={{ risk: 6 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_9001/config"
            componentProps={{ risk: 7 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_37001/config"
            componentProps={{ risk: 8 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute exact path="/dash/build" component={Build} />
          <PrivateRoute exact path="/dash/update" component={BuildHome} />
          <PrivateRoute
            exact
            path="/dash/sideDish"
            component={SideDishContainer}
          />
          {/* ----------------------------------------Department----------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/department",
                "/dash/department/new",
                "/dash/department/edit",

                "/dash/subdepartment",
                "/dash/subdepartment/new",
                "/dash/subdepartment/edit",
              ]}
              component={DepartmentContainer}
            /> */}
          {/* -----------------------------------------------Device----------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/device",
                "/dash/device/new",
                "/dash/device/edit",
                "/dash/device/clone",
                "/dash/deviceInventory"
              ]}
              component={DeviceContainer}
            /> */}
          {/* --------------------------------------------Task--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/task",
                "/dash/task/new",
                "/dash/task/edit",
              ]}
              component={TaskContainer}
            /> */}
          {/* --------------------------------------------Catalog--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                '/dash/catalog',
                '/dash/catalog/new',
                '/dash/catalog/add/assigment',
                '/dash/catalog/edit',
              ]}
              component={CatalogContainer}
            /> */}
          {/* --------------------------------------------Category--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                '/dash/category',
                '/dash/category/new',
                '/dash/category/edit',
                '/dash/category/add/catalog'
              ]}
              component={CategoryContainer}
            /> */}
          {/* --------------------------------------------Group--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                '/dash/group',
                '/dash/group/new',
                '/dash/group/edit',
                '/dash/group/add/user'
              ]}
              component={GroupContainer}
            /> */}
          {/* --------------------------------------------Projectmanagement--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              localStorage.getItem('view_past_project')
                ? '/dash/gp'
                : undefined,
              '/dash/gp/list',
              '/dash/gp/list/detail',
              '/dash/gp/list/edit',
              '/dash/gp/list/form',
              '/dash/gp/list/new',
              // '/dash/gp/prioritization'
              //  '/dash/gp/pmo',
              // '/dash/gp/prioritization/list',
            ].filter(Boolean)}
            component={ProjectManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/gp/prioritization"
            component={PrioritizationHome}
          />
          <PrivateRoute
            exact
            path="/dash/gp/prioritization/avaliation"
            component={PrioritizationAvaliaton}
          />
          <PrivateRoute
            exact
            path="/dash/gp/prioritization/avaliation/configuration"
            component={PrioritizationConfiguration}
          />
          <PrivateRoute
            exact
            path={['/dash/gp/report']}
            component={ProjectManagementReport}
          />
          <PrivateRoute
            exact
            path={['/dash/gp/pmo']}
            component={ProjectManagementPmo}
          />
          <PrivateRoute
            exact
            path={['/dash/gp/']}
            component={ProjectManagementList}
          />
          <PrivateRoute exact path={['/dash/gp/:id']} component={ProjectPage} />
          <PrivateRoute
            exact
            path={['/dash/calendar']}
            component={CalendarioActivities}
          />
          {/* --------------------------------------------Changemanagement--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/changemanagement',
              '/dash/changemanagement/new',
              '/dash/changemanagement/edit',
              '/dash/changemanagement/view',
              '/dash/changemanagement/analyze',
              '/dash/changemanagement/risk_milestone',
            ]}
            component={ChangeManagementContainer}
          />
          <PrivateRoute
            exact
            path={['/dash/changemanagement/calendar']}
            component={CalendarioChangeActivities}
          />
          {/* --------------------------------------------Request container--------------------------------------------------------------- */}
          {/* --------------------------------------------Configuration--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/request',
              '/dash/requestapprove',
              '/dash/request/new',
              '/dash/request/view',
              '/dash/request/categorize',
            ]}
            component={RequestContainer}
          />
          {/* --------------------------------------------Configuration--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              // ------------------CONFIGURAÇÕES GERAIS-----------------------------
              '/dash/configuration',
              '/dash/configuration/perfil',
              '/dash/configuration/outagerecurrence',
              '/dash/configuration/users',
              '/dash/configuration/userspermissions',
              '/dash/configuration/generalparameters',
              '/dash/configuration/extensionsfile',
              '/dash/configuration/centralhelp',
              '/dash/configuration/passwordquestions',
              // ------------------CENTRAL DE NOTIFICAÇÕES-----------------------------
              '/dash/configuration/notificationcenter',
              '/dash/configuration/notificationcentertags',
              // -------------------------GESTÃO DE SLA-----------------------------
              '/dash/configuration/slawork',
              '/dash/configuration/slaconfig',
              '/dash/configuration/ticketallawanse',
              '/dash/configuration/statusticketallawanse',
              '/dash/configuration/slareprocessing',
              '/dash/configuration/slaholidays',
              // -------------------------EMPRESAS----------------------------------
              '/dash/configuration/company',
              '/dash/configuration/department',
              '/dash/configuration/subdepartment',
              '/dash/configuration/occupation',
              '/dash/configuration/ldap',
              '/dash/configuration/companytime',
              '/dash/configuration/location',
              '/dash/configuration/email',
              '/dash/configuration/squad',
              // -------------------------GESTÃO DE CONTRATO----------------------------------
              '/dash/configuration/contracts',
              '/dash/configuration/sla',
              '/dash/configuration/slaform',
              '/dash/configuration/templateemail',
              // -------------------------GESTÃO DE DISPONIBILIDADE----------------------------------
              '/dash/configuration/device',
              '/dash/configuration/snmp',
              '/dash/configuration/devicetype',
              '/dash/configuration/deviceIC',
              // -------------------------GESTÃO DE DEMANDA----------------------------------
              '/dash/configuration/automation',
              '/dash/configuration/task',
              '/dash/configuration/subcategories',
              '/dash/configuration/categories',
              '/dash/configuration/techgroup',
              '/dash/configuration/template',
              '/dash/configuration/changecab',
              '/dash/configuration/changerisk',
              '/dash/configuration/eventcab',
              '/dash/configuration/answer',
              '/dash/configuration/statusawaiting',
              '/dash/configuration/techEvaluationRegistration',
              '/dash/configuration/passwordsafe',
              '/dash/configuration/changemanagement',
              '/dash/configuration/changemanagementworkflow',
              '/dash/configuration/changemanagementgroup',
              '/dash/configuration/approvalshistoric',
              '/dash/configuration/resolutioncategory',
              '/dash/configuration/resolutioncode',
              '/dash/configuration/channel-management',
              // -------------------------GESTÃO DE PRJETOS----------------------------------
              '/dash/configuration/project',
              '/dash/configuration/projectstatus',
              '/dash/configuration/coin-management',
              // -------------------------Usuário Alternativo----------------------------------
              '/dash/configuration/cheifAlternative',
              '/dash/configuration/effortvalue',
              '/dash/configuration/location',
              // -------------------------Auditoria----------------------------------
              '/dash/audit/users',
              '/dash/audit/company',
              '/dash/audit/catalog',
              '/dash/audit/form',
              '/dash/audit/flow-approve-gmud',
            ]}
            component={ConfigurationContainer}
          />
          <PrivateRoute
            exact
            path="/dash/monitor"
            component={MonitorContainer}
          />
          <PrivateRoute exact path="/dash/answer" component={AnswerContainer} />
          <PrivateRoute
            exact
            path="/dash/tickets"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/tickets/detail"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/tickets/create"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/tickets/edit"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/incident"
            component={TicketContainer}
          />
          {/* <PrivateRoute exact path="/dash/sla" component={SlaContainer} /> */}
          <PrivateRoute
            exact
            path="/dash/reports"
            component={ReportContainer}
          />
          <PrivateRoute
            exact
            path="/dash/reports/analystreportcall"
            component={AnalystReportCalls}
          />
          <PrivateRoute exact path="/dash/gcf" component={GCFContainer} />
          <PrivateRoute exact path="/dash/gi" component={GIContainer} />
          <PrivateRoute exact path="/dash/gsd" component={GSDContainer} />

          {/* <PrivateRoute
              exact
              path="/dash/subdepartment"
              component={DepartmentContainer}
            /> */}
          <PrivateRoute exact path="/dash/tag" component={TagContainer} />
          <PrivateRoute
            exact
            path="/dash/typecontracts"
            component={TypeContractContainer}
          />
          <PrivateRoute exact path="/dash/download" component={DownloadAgent} />
          {/* <PrivateRoute exact path="/dash/mail" component={MailContainer} /> */}
          {/* <PrivateRoute
              exact
              path="/dash/companytime"
              component={CompanyTimeContainer}
            /> */}
          {/* <PrivateRoute exact path="/dash/devicetype" component={DeviceType} /> */}
          <PrivateRoute exact path="/dash/site" component={Site} />
          <PrivateRoute exact path="/dash/panel" component={Panel} />
          <PrivateRoute exact path="/dash/bsc" component={Bsc} />
          <PrivateRoute
            exact
            path="/dash/newServiceOrder"
            component={ServiceOrderContainerForm}
          />
          <PrivateRoute
            exact
            path="/dash/auditcontract"
            component={AuditContainerNew}
          />
          {/* <PrivateRoute exact path="/dash/ad" component={ad} /> */}
          {/* <PrivateRoute exact path="/dash/Discovery" component={Discovery} /> */}
          <PrivateRoute exact path="/dash/deviceConf" component={DeviceConf} />
          {/* --------------------------------------Taskmanagement------------------------------------------ */}
          <PrivateRoute
            exact
            path="/dash/task"
            component={DashboardTaskManagement}
          />
          <PrivateRoute exact path="/dash/techtask" component={TechTask} />
          <PrivateRoute
            exact
            path="/dash/taskapprove"
            component={ApproveTask}
          />
          <PrivateRoute
            exact
            path="/dash/task/calendar"
            component={TaskCalendar}
          />
          <PrivateRoute
            exact
            path="/dash/task/ticket/:id"
            component={TicketTask}
          />
          <PrivateRoute exact path="/dash/portal" component={PortalContainer} />

          {/* ---------------SAC ROUTES----------------- */}

          {/* Cadastro de clientes */}
          <PrivateRoute
            exact
            path="/dash/sac/consumer-base"
            component={SacConsumerBase}
          />

          {/* Gestão de atendimentos*/}
          <PrivateRoute
            exact
            path="/dash/sac/dashboard"
            component={GSDContainerSac}
          />

          {/* LIsta de Tarefas*/}
          <PrivateRoute
            exact
            path="/dash/sac/listtasks"
            component={ListTasksAttendance}
          />

          {/* Cadastro de atendimento */}
          <PrivateRoute
            exact
            path="/dash/sac/service-registration"
            component={AttendanceContainer}
          />

          {/* -----Cadastro de temas---------- */}

          <PrivateRoute
            exact
            path="/dash/sac/SacThemeList"
            component={SacThemeList}
          />

          {/* -----Cadastro de topicos---------- */}

          <PrivateRoute
            exact
            path="/dash/sac/SacTopicList"
            component={TopicList}
          />

          {/* -----Cadastro de topicos---------- */}

          <PrivateRoute
            exact
            path="/dash/sac/SubjecList"
            component={SubjectContainer}
          />

          {/* -----Cadastro de Tarefas---------- */}
          {/* -------
          <PrivateRoute
            exact
            path='/dash/sac/SacTaskList'
            component={TaskContainer}
          />
          -------- */}
        </Switch>
      </Router>
    );
  }
}