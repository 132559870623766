import 'react-quill/dist/quill.snow.css';

import React, { Component, Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Container, Row, Col } from 'react-grid-system';
import ReactQuill from 'react-quill';
import moment from 'moment';
import CommonForm from '../../common/CommonForm2';
import classnames from 'classnames';
import './TicketComment.css';
import { Avatar } from '@material-ui/core';
import Store from '../../data/Store.js';

function OnScrollBottom({ comments, callback }) {
  useEffect(() => {
    callback();
  }, [comments]);
  return null;
}
@observer
export default class TicketComment extends Component {
  constructor(props) {
    super(props);
    this.msgContainter = React.createRef();
    this.state = {
      description: '',
    };
  }
  setScrollBottom = () => {
    var el = this.msgContainter.current;
    var scrollP = el.scrollHeight - el.scrollTop - el.offsetHeight;

    if (scrollP <= 300) {
      el.scrollTo(0, 1000000);
    }
  };
  componentWillMount() {
    if (!this.props.isMultipleSelection) {
      Store.socket.on('chat-ticket-'+Store.getTenant, () => {
        this.props.handleDetail(this.props.ticket.id_tickets)
        this.setScrollBottom();
      });
    } else {
      Store.socket.on('chat-ticket-'+Store.getTenant);
    }
  }
  onChangeField = (f, v) => {
    this.setState({ description: v });
  };
  handleCheckIsLink = (value) => {
    const regex =
      /(http:\/\/|https:\/\/)(w{3}\.)?([a-zA-Z0-9]+)(.\w+)?(\.com)(.+)?/gm;
    const result = regex.exec(value);

    if (result) {
      let link = result[0].split(' ')[0].replace(/<\/p>|\s.rel+/g, '');
      if (link.includes('</a>')) {
        link = link.replace('</a>', '');
      }

      let newLink = `<a href="${link}" target="_blank">${link}</a>`;
      let newValue = value.replace(link, newLink);

      return {
        description: newValue,
      };
    }

    return {
      description: value,
    };
  };
  onCommentTicketSub(id_tickets, values,changecomponent) {
    this.setState({ description: '' });
    const result = values.description.search('<img');
    if (result == -1) {
      values.description = values.description.replace(/\\/g, '&#92;');
      values.description = values.description.replace(/["]+/g, '&#34;');
    }
    values.description = changecomponent ? values.description.replace(/<.*?>/g, '') : values.description
    let obj = this.handleCheckIsLink(values.description);
    obj.email_chat = values.email_chat;

    this.props.onCommentTicket(id_tickets, obj);
  }
  render() {
    let randomSeed = Math.floor(Math.random() * 1000);

    let {
      language,
      onFormSubmit,
      ticket,
      onCommentTicket,
      hideNewComment,
      type_ticket,
      loading,
      setRefComments,
      changecomponent,
      hasTasksPending
    } = this.props;
    let fields = [
      {
        col: 12,
        name:  'description',
        label: language.MESSAGE,
        required: true,
        type: changecomponent ?'simplesTextEditor': 'textEditor',
        filter:this.props.filter
      },
    ];
    let button = {
      label: language.SEND,
      loading,
      disabled:
        (ticket?.status == 'Resolved' || ticket?.status == 'Closed') || hasTasksPending ? true : false,
    };

    let user = Store.getUserLoged();

    let commentsNodes = [];
    let duplicate = [];
    if (!this.props?.isMultipleSelection) {
      ticket.comments
      .filter(
        (e) =>
          type_ticket.includes(e.type) && e.fk_id_tickets == ticket.id_tickets
      )
      .map((c, k) => {
        if (duplicate.filter((n) => n === c.id_comments).length == 0) {
          duplicate.push(c.id_comments);
          commentsNodes.push(
            <Comment
              user={user}
              randomSeed={randomSeed}
              key={k}
              language={language}
              {...c}
              myComment={c.fk_id_user === ticket.fk_id_user}
            />
          );
        }
      });
    }

    return (
      <div id="TicketComment">
        <OnScrollBottom
          comments={(ticket && ticket.comments.length) || 0}
          callback={() => {
            !this.props?.isMultipleSelection && this.setScrollBottom();
          }}
        />
        <Container>
          {!this.props?.isMultipleSelection && (
          <Row>
            <Col md={12} style={{ height: '100%' }}>
              <ul
                className="msg-containter"
                ref={this.msgContainter}
                style={
                  !!this.props.maxHeightChat
                    ? { height: this.props.maxHeightChat }
                    : {}
                }
              >
                {commentsNodes}
              </ul>
            </Col>
          </Row>
          )}

          {!hideNewComment && (
            <div className="content px-0 pt-0" style={{marginTop:changecomponent ?'10px':'0px'}}  >
              {this.props?.statusTicket === 'Closed' ||
              ((this.props?.isReadonly &&
                this.props?.statusTicket !== 'Resolved') || hasTasksPending) ? null : (
                <CommonForm
                  onRef={setRefComments}
                  fields={fields}
                  button={ button}
                  clean
                  onSubmit={(values) => {
                    if (this.props?.isMultipleSelection) {
                      this.onCommentTicketSub(this.props.ticketsArray, values, changecomponent)
                      } else {
                        this.onCommentTicketSub(ticket.id_tickets, values, changecomponent)
                      }
                    }                   
                  }
                  onChangeField={this.onChangeField}
                />
              )}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

class Comment extends Component {
  render() {
    let {
      language,
      randomSeed,
      myComment,
      name_create,
      description,
      dt_cad,
      ticket,
      name_device,
      subject,
      sla,
    
      role,
      user,
    } = this.props;

    let FDate = moment.locale() == 'pt-br' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';
    myComment = !(user.id == this.props.fk_id_user ? true : false);

    description = description.replace(/\&#34;/g, '"');

    description = description.replace(
      '<ol',
      '<ol class="show-number-list-style"'
    );
    description = description.replace(
      '<ul',
      '<ul class="show-bullet-list-style"'
    );

    return (
      <li className={classnames(myComment ? 'received' : 'sended', 'msg')}>
        <div className="msg-box shadown">
          {ticket && 1 != 1 ? (
            <div>
              <Row>
                {name_device ? (
                  <Col md={6}>
                    <h4>
                      {language.DEVICE}: {name_device}
                    </h4>
                  </Col>
                ) : null}
                <Col md={6}>
                  <h4>
                    {language.SLA}: {sla}
                  </h4>
                </Col>
              </Row>
              <h4>
                {language.SUBJECT}: {subject}
              </h4>
              <hr />

               
              <ReactQuill
                value={description}
                modules={{ toolbar: null }}
                
                readOnly={true}
                style={{ border: 'none' }}
              />
            </div>
          ) : (
            <Fragment>
              <Avatar className="avatar">{name_create.substr(0, 1)}</Avatar>
              <div className="box-data">
                <div className="title-content">
                  <small className="name">{name_create}</small>
                  <small className="date">
                    {moment(dt_cad).format(FDate + ' - HH:mm:ss')}
                  </small>
                </div>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </Fragment>
          )}
        </div>
      </li>
    );
  }
}
