import React, { useEffect, useState } from 'react';
import { Chip, Card } from '@material-ui/core';
import Button from '../../../common/Button';
import Dialog from '../../../common/Dialog';
import TopicSacForm from './TopicSacForm';
import CommonDelete from '../../../common/CommonDelete';
import CommonTable from '../../../common/CommonTable';
import Constants from '../../../data/Constants';
import CommonPage from '../../../common/CommonPage';
import PageLayout from '../../../common/PageLayout';
import CommonForm from '../../../common/CommonForm2';
import Store from '@data/Store';
import Actions from '@data/Actions';

const action = new Actions();

const TopicList = () => {
  const [formAddTopic, setFormAddTopic] = useState(false);
  const [page, setPage] = useState('list');
  const [topics, setTopics] = useState([]);
  const [themes, setThemes] = useState([]);
  const [countTopics, setCountTopics] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [topicSelected, setTopicSelected] = useState([]);

  const themesSelected = [];

  const callPageSelectFilter = (page = 0, values = {}, limit = 75) => {
    setCurrentPage(page);

    action
      .execute('post', `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics?limit=${limit}&offset=${page * limit}`, '', values)
      .then((data) => {
        if( data.data ) {
          data = data.data;
        }
        setTopics(data);
        setCountTopics(data[0] ? data[0].total_count : 0);
      })
      .catch(console.log);
  };

  const handlePage = (page, obj) => {
    setPage(page);

    if (page === 'editTopic') {
      setFormAddTopic(true);
      setTopicSelected(obj);
    }

    if (page === 'remove') {
      setTopicSelected(obj);
    }
  };

  const getAllThemesForTopics = (themes) => {
    action
      .execute('post', `${Constants.APIEndpoints.SAC_TOPICS}/getalltopics`, '', themes)
      .then((data_themes) => {
        setThemes(data_themes.data);
      });
  };

  useEffect(() => {
    callPageSelectFilter();
    getAllThemesForTopics();
  }, []);

  const deleteTopic = (id_topic) => {
    const values = { id_topic, user: Store.getUserLoged() };

    action
      .execute('put', `${Constants.APIEndpoints.SAC_TOPICS}/deletetopic`, '', values)
      .then((data) => {
        callPageSelectFilter();
        setPage('list');
        setTopicSelected([]);
      });
  };

  const editTopic = (id_topic) => {
    const values = { id_topic, user: Store.getUserLoged() };
    action
      .execute('put', `${Constants.APIEndpoints.SAC_TOPICS}/updatetopic`, '', values)
      .then((res) => {
        setFormAddTopic(false);
        setTopicSelected([]);
      });
  };

  const data = topics.map((obj) => ({
    topic: obj.name,
    theme: obj.theme,
    actions: (
      <React.Fragment>
        <Chip
          className="mx-2 muit-chip"
          label={
            <div className="d-flex align-items-center justify-content-between">
              <Button
                variant="circle"
                icon="fas fa-edit"
                label={Store.language.EDIT}
                color="default"
                style={{ fontSize: 12 }}
                size={0.7}
                className="mr-2"
                onClick={() => handlePage('editTopic', obj)}
              />

              <Button
                variant="circle"
                icon="far fa-trash-alt"
                label={Store.language.DELETE}
                color="danger"
                style={{ fontSize: 13 }}
                size={0.7}
                onClick={() => handlePage('remove', obj)}
              />
            </div>
          }
        />
      </React.Fragment>
    ),
  }));

  const col = [
    {
      key: 'topic',
      label: Store.language.TOPIC,
      style: { textAlign: 'center' },
      thConfig: true,
    },
    {
      key: 'theme',
      label: Store.language.THEME,
      style: { textAlign: 'center' },
      type: 'select',
      options: [
        { value: 'active', label: Store.language.ACTIVE },
        { value: 'inactive', label: Store.language.INACTIVE },
      ],
      thConfig: true,
    },
    {
      key: 'actions',
      label: Store.language.ACTIONS,
      style: { textAlign: 'left' },
      thConfig: false,
    },
  ];

  return (
    <CommonPage showMenuIconButton={false} iconAlert={true} loading={false}>
      <PageLayout
        icon={
          <div
            className="panelico"
            style={{ width: 40, height: 40, overflowY: true }}
          />
        }
        title="Lista de Topicos"
        subTitle={Store.language.CONSUMER_SERVICE_SYSTEM}
      >
        <Card className="content-min px-3">
          <Dialog
            miniVersion
            title="Adicionar Topico"
            open={formAddTopic}
            onClose={() => {
              setFormAddTopic(false);
              setTopicSelected([]);
            }}
            maxWidth="sm"
          >
            <TopicSacForm
              APIEndpoints={Constants.APIEndpoints}
              language={Store.language}
              setForm={setFormAddTopic}
              values_={topicSelected}
              callPageSelectFilter={() => callPageSelectFilter()}
            />
          </Dialog>

          <CommonTable
            title={''}
            language={Store.language}
            columns={col}
            data={data}
            index="SacTopicList"
            showHeaderToolbar={false}
            orderColumn
            countTotal={countTopics}
            isAutomaticPagination={false}
            beginWithNoFilter={true}
            paginationTop={false}
            searchColumn={true}
            loadSearch={true}
            currentPage={currentPage}
            searchColumnEvent={(v) => {}}
            callPageSelectFilter={(page, values, rowsPerPage, type) =>
              callPageSelectFilter(page, values, rowsPerPage, type)
            }
            beforeBar={
              <Button
                variant="circle"
                icon="fas fa-plus"
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                label="Adicionar Tópico"
                primary={true}
                onClick={() => {
                  setTopicSelected([]);
                  setFormAddTopic(true);
                }}
              />
            }
          />

          {page === 'remove' ? (
            <CommonDelete
              miniVersion
              language={Store.language}
              closeModal={() => setPage('list')}
              onDelete={() => deleteTopic(topicSelected.id_topic)}
              onEdit={() => editTopic(topicSelected.id_topic)}
              message={''}
            />
          ) : null}
        </Card>
      </PageLayout>
    </CommonPage>
  );
};

export default TopicList;
