import React from 'react';
// --------------------------------common----------------------------------------------------
import CommonPage from '@common/CommonPage';
import PageLayout from '@common/PageLayout';
import { observer } from 'mobx-react';

@observer
export default class extends CommonPage {
	render(){
		let {children, title, subTitle} = this.props;
		return(
			<CommonPage
			  showMenuIconButton={false}
			  iconAlert={true}
			  loading={false}
			>
				<PageLayout
				  title={title}
				  subTitle={subTitle}
				  
				>
					{children}
				</PageLayout>
			</CommonPage>
		)
	}
}