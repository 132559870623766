import React, { useEffect, useState, useReducer } from 'react';
import moment from 'moment';
import MemoryIcon from '@material-ui/icons/Memory';
import CommonTable from '@common/CommonTable';
import Progress from '@common/Progress';
import Button from '@common/Button';
import Details from './DetailsAlerts';
import Link from './LinkGI'
 import './index.css'
import { Checkbox } from '@material-ui/core';

import { company } from '../dashboard/Graph';
import Status from './Status';
import Constants from '@data/Constants';

const tenant = Constants.Tenant;

const TableType = ({
    countOk,
    countYellow,
    countRed,
    monitorGroups,

    countTotal,
    devices,
    ioLinks,
    action ,
    language,
    APIEndpoints ,
    companies,
    ioServers,
    alerts,
    page,
    refresh_,
    companyId,
    startAssessment,
    listdevice
}) => {
    const [groupType, setGroupType] = useState([]);
    const  [monitor,setMonitores] =useState([]);
    const  [devicea,setDevicea] =useState([]);
    const  [groups,setGroups] =useState([]);
    let [filter, setFilter] = useState([0]);
    const [grupos,setGrupos]=useState([]);
    const [dataAlert,setdataAlert]=useState([]);
    const [valor,setValor]=useState([]);
    const [c,setc]=useState(0);




useEffect(() => {




let cont=c;

  alerts.on(`${tenant}_alerts`, (data) => {
    //: {key: "mem-info", id_device: 2, id_company: 1}
    let vv=[];


     cont++

// if(cont==2||cont==1){
      // if(cont==2){
      //   setc(0)
      // }
  data.alertsall.countYellow.filter(c=>companyId >0 ?c.id_company== companyId : c.id_company >0 &&

     listdevice != null ? listdevice.includes(c.id_device) ==true : c.id_device>0

    ).map(c=>{
    try{
    let d = devices.filter(v=>v.id_device ==c.id_device)

    vv.push({
      nome:d[0].name,
      alias:d[0].alias,
      ip:d[0].ip,
      company:d[0].namecompany,
      remote: <Button
     icon="fas fa-play"
     variant="circle"
     color="success"
     size={0.6}
     zoom={0.6}
     onClick={() => startAssessment(d[0])}
     style={{ fontSize: 14 }}
     outlined
   />,
      dt:moment().format('DD/MM/YY HH:mm:ss'),
      status:  <Details items={1} ic={'warning'} percent={c.percent} fs={c.fs} item={c.key=="mem-info"?2:c.key=="cpu-stats"?1:
      c.key=="fs-list"?3:'' }
      ></Details>
      })
    }catch(e){}
  })
  data.alertsall.countRed.filter(c=>companyId >0 ?c.id_company== companyId : c.id_company >0
    &&
    listdevice != null ? listdevice.includes(c.id_device) : c.id_device>0

    ).map(c=>{
    try{
       let d = devices.filter(v=>v.id_device ==c.id_device)

          vv.push({
          nome:d[0].name,
          alias:d[0].alias,
          ip:d[0].ip,
          company:d[0].namecompany,
          remote: <Button
          icon="fas fa-play"
          variant="circle"
          color="success"
          size={0.6}
          zoom={0.6}
          onClick={() => startAssessment(d[0])}
          style={{ fontSize: 14 }}
          outlined
        />,

           dt:moment().format('DD/MM/YY HH:mm:ss'),
          status:  <Details items={1} ic={'danger'} percent={c.percent} fs={c.fs}   item={c.key=="mem-info"?2:c.key=="cpu-stats"?1:
          c.key=="fs-list"?3:''
              }
          ></Details>
          })
        }catch(e){}
        })

        setdataAlert(
          <CommonTable

          AppBarProps={{
             dark: true,
             className: 'py-0'
           }}
           onPosition={refresh_}

           rowsPerPageOptions={[1000]}
           searchColumn
           col={[{
            key: 'nome',
            label: language.NAME,
            thConfig: true,
            style: { width: 100  }
           },
           {
            key: 'alias',
            label: language.ALIAS,
            thConfig: true,
            style: { width: 100  }
           },
           {
            key: 'ip',
            label: language.IP,
            thConfig: true,
            style: { width: 100  }
           },
           {
            key: 'company',
            label: language.COMPANY,
            thConfig: true,
            style: { width: 100  }
           },
           {
            key: 'remote',
            label: language.ACCESS,
            thConfig: true,
            style: { width: 5  }
           },


            {
              key: 'dt',
              label: language.DATE,
              thConfig: true,
              style: { width: 100 }},
            {
              key: 'status',
              label: language.STATE,
              thConfig: true,
              style: { width:5 , textAlign: 'left' }
             }

         ]}

           data={vv}

        ></CommonTable>
        )

      // } // fim

   /*nome:g.name,
   company: getcompany(companies,g.companyId)  ,
   ip:g.ip,
    status:  <Details  items={g.id_device.toString()} ic={'danger'}  item={1}
    monitor={monitor} device={g} ></Details>

  }*/  })


},[alerts,devices,companyId,listdevice])





  window._groupType = groupType;





  return (

 <div className="Item d-flex align-items-center justify-content-start content-min m-auto">

{dataAlert}


</div>)
}

export default TableType;
