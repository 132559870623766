import React, { Component, Fragment } from 'react';
import {Menu, MenuItem, IconButton, Fade} from '@material-ui/core';
import classnames from 'classnames';
import { Tooltip } from 'react-tippy';
import '@bClass';

class CommonPageMenu extends Component{
	render(){
		let {items, menuProps={}} = this.props;
		return (
			items.map((item,i)=>(
				[undefined, true].includes(item.visible)?
				<Fragment key={i}>
					<Tooltip
					  html={
					    <div style={{ color: '#fff', fontSize: 15 }}>
					      <strong>{item.label}</strong>
					    </div>
					  }
					  position="right"
					  theme="dark"
					  arrow={true}
					  open={!!item.label?undefined:false}
					  
					>
					  <IconButton
					  	{...item}
					    color="secondary"
					    className={classnames(item.className, item.icon)}
					    aria-haspopup="true"
					    aria-label={item.label}
					  />
					</Tooltip>

					{item.options && !!item.options.length &&
						<Menu
						  {...menuProps}
						  {...item.menuProps}
						  anchorEl={item.anchorEl}
						  open={!!item.open}
						  onClose={item.onClose || null}
						  TransitionComponent={Fade}
						>
							{item.options.filter(f=>f.visible != false).map((e,i)=>(
							  <MenuItem className="py-4" {...e} key={i}>
							    <IconButton
							      {...e.iconProps}
							      className={classnames(e.className, e.icon)}
							      style={{ width: 30, height: 30, marginRight: 9, ...((e.iconProps||{}).style||{}) }}
							    />
							    {e.label}
							  </MenuItem>
						  	))}
						</Menu>
					}
				</Fragment>
				:null
			))
		)
	}
} 

CommonPageMenu.defaultProps = {
  items:[]
}

export default CommonPageMenu;